import {toMonthName} from '../productivity-summary/month-formatter';
import {
  formatNumberToWholeNumber,
  formatToCurrency,
  formatToPercentage,
  getCssClass,
  multiplyToPercentRoundedToNearestTenthOrDash,
  multiplyToPercentRoundedToNearestTenthOrZero,
  roundCollectionsToNumber,
  roundTo,
  roundToNearestTenthOrDash,
  roundToNearestTenthOrZero,
  roundToNumber,
  roundToWithCommasSeparation,
  roundToWithPercentage
} from '../productivity-summary/number-formatter';
import {ColumnType, CptViewType} from './enums';
import {checkForNulls, hasValue, undefinedIfZero} from './null-helpers';
import {TableCell} from './models';
import {
  CollectionSummaryData,
  MatchedCollectionsByMultiLevelByNodePath,
  PayerByMultiLevelCollectionsByNodePath,
  PayerCollections,
  SnapshotMatchedCollections
} from '../collections/Collection';
import {
  CfpByMultilevel,
  ClinicalSummaryCode,
  ClinicalSummaryFamily,
  ClinicalSummaryRange
} from '../clinical-fingerprint/ClinicalSummary';
import {BaseColumn} from '../store/IAppState';
import {
  CFTE_ADJ_WRVU_RELATED_COLUMN_TYPES,
  departmentNodeColumnForWrvu,
  providerNodeColumnForWrvu,
  specialtyNodeColumnForWrvu
} from './BenchmarkColumns';
import {ProductivitySnapshot} from '../productivity-summary/services/ProviderProductivity';
import {
  NpvSummaryData
} from '../new-patient-visits/components/new-patient-visits-summary-data-table/new-patient-visits-summary-data-table.component';
import {
  ProductivitySummaryData
} from '../productivity-summary/wrvu-summary-data-table/wrvu-summary-data-table.component';
import {
  DenialCptCodeByReasonEntity,
  DenialCptCodeEntity,
  DenialPayerEntity,
  DenialsEntity,
  DenialsEntityWithReason,
  DenialsMultiLevelEntity
} from '../denials/denials-models';

export const columnsForWRVUtrend: DataTableColumns[] = [
  {
    columnDef: 'year', header: 'Year',
    dataName: (row: any) => `${row.year.toString()}`,
    primaryColumn: true,
    columnType: ColumnType.PRIMARY_DATE
  },
  {
    columnDef: 'month', header: 'Month',
    dataName: (row: any) => `${toMonthName(row.month.toString())}`,
    primaryColumn: true
  },
  {
    columnDef: 'cfteAdjustedWRVUs', header: 'cFTE Adj. wRVUs',
    dataName: (row: any) => `${formatNumberToWholeNumber(row.cfteAdjustedWRVUs)}`,
    hoverMessage: 'Raw wRVUs produced by individual clinicians indexed to a 1.0 CFTE level. ' +
      'This calculation is used to compare clinician productivity to CPSC productivity benchmarks, ' +
      'which are published on a “per 1.0 CFTE” basis.',
    showHoverMessage: false
  },
  {
    columnDef: 'benchmark', header: 'wRVU Benchmark',
    dataName: (row: any) => `${formatNumberToWholeNumber(row.benchmark)}`,
    hoverMessage: 'Work RVU productivity benchmarks are calculated annually and released on the first of the ' +
      'calendar year. Only providers with validated clinical effort (cFTE) values are included. The focus of the ' +
      'selection for inclusion is to identify clinically active providers (typically greater than 0.6 cFTE). ' +
      'Productivity outliers are trimmed.',
    showHoverMessage: false,
    columnType: ColumnType.BENCHMARK
  },
  {
    columnDef: 'wRVUs', header: 'Actual wRVUs',
    dataName: (row: any) => `${formatNumberToWholeNumber(row.wRVUs)}`,
    hoverMessage: 'A unit of measure used to express the amount of effort (time, intensity of effort, technical skills) ' +
      'required by a provider to perform a given service relative to other services.',
    showHoverMessage: false
  },
  {
    columnDef: 'cfte', header: 'cFTE',
    dataName: (row: any) => `${roundToWithCommasSeparation(row.cfte, 2)}`,
    hoverMessage: 'Represents the portion of the provider’s time spent in patient-facing clinical care' +
      ' and the follow-up and documentation required for that care.',
    showHoverMessage: false,
    columnType: ColumnType.CFTE
  },
  {
    columnDef: 'charges', header: 'Charges',
    dataName: (row: any) => `$${formatNumberToWholeNumber(row.charges)}`,
    columnType: ColumnType.CHARGES
  },
  {
    columnDef: 'variance',
    header: 'Variance from Benchmark ',
    dataName: (row: any) => `${formatNumberToWholeNumber(row.variance)}`,
    columnType: ColumnType.VARIANCE,
    group: 'group-last'
  },
  {
    columnDef: 'previousCfteAdjustedWRVUs', header: 'Previous Date Range cFTE Adj. wRVUs',
    dataName: (row: any) => `${formatNumberToWholeNumber(row.previousCfteAdjustedWRVUs)}`,
    group: 'group-first'
  },
  {
    columnDef: 'difference', header: 'cFTE Adj. wRVUs Difference from Previous Date Range',
    dataName: (row: any) =>
      `${formatNumberToWholeNumber(row.difference)}`,
    class: (row: any) => `${getCssClass(checkForNulls(row.difference))}`
  }
];

export const specialtyPerformanceColumns: DataTableColumns[] = [
  providerNodeColumnForWrvu,
  specialtyNodeColumnForWrvu(false),
  departmentNodeColumnForWrvu(false),
  {
    columnDef: 'cfte',
    header: 'cFTE',
    dataName: (row: ProductivitySnapshot) => `${roundToWithCommasSeparation(row.cfte, 2)}`,
    hoverMessage: 'Represents the portion of the provider’s time spent in patient-facing clinical care' +
      ' and the follow-up and documentation required for that care.',
    showHoverMessage: false,
    columnType: ColumnType.CFTE
  },
  {
    columnDef: 'cfteAdjustedWRVUs',
    header: 'cFTE Adj. wRVUs',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(checkForNulls(row.cfteAdjustedWRVUs))}`,
    hoverMessage: 'Raw wRVUs produced by individual clinicians indexed to a 1.0 CFTE level. ' +
      'This calculation is used to compare clinician productivity to CPSC productivity benchmarks, ' +
      'which are published on a “per 1.0 CFTE” basis.',
    showHoverMessage: false
  },
  {
    columnDef: 'wRVUs',
    header: 'Actual wRVUs',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(checkForNulls(row.wRVUs))}`,
    hoverMessage: 'A unit of measure used to express the amount of effort (time, intensity of effort, technical skills) ' +
      'required by a provider to perform a given service relative to other services.',
    showHoverMessage: false
  },
  {
    columnDef: 'specialtyPerformanceActualWrvus', header: 'Specialty Performance Actual wRVUs',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(row.specialtyPerformanceActualWrvus)}`,
  },
  {
    columnDef: 'specialtyPerformanceCfteAdjWrvus', header: 'Specialty Performance cFTE adj. wRVUs',
    dataName: (row: ProductivitySnapshot) => `${!row.cfte ? '-' : formatNumberToWholeNumber(row.specialtyPerformanceCfteAdjWrvus)}`,
  },
  // TODO: Revisit ? Product design want this column hidden but maybe bring it back in the future??
  // {
  //   columnDef: 'specialtyPerformanceActualWrvuspVariance', header: 'Specialty Performance Actual wRVUs Variance',
  //   dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(row.specialtyPerformanceActualWrvuspVariance)}`,
  // },
  {
    columnDef: 'specialtyPerformanceCfteAdjWrvuspVariance', header: 'Specialty Performance cFTE adj. wRVUs Variance',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(row.specialtyPerformanceCfteAdjWrvuspVariance)}`,
  },
  {
    columnDef: 'benchmarkMean',
    header: 'Academic wRVU Benchmark (Mean)',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(checkForNulls(row.benchmarkMean))}`,
    hoverMessage: 'Work RVU productivity benchmarks are calculated annually and released on the first of the ' +
      'calendar year. Only providers with validated clinical effort (cFTE) values are included. The focus of the ' +
      'selection for inclusion is to identify clinically active providers (typically greater than 0.6 cFTE). ' +
      'Productivity outliers are trimmed.',
    showHoverMessage: false,
    columnType: ColumnType.BENCHMARK
  },
  {
    columnDef: 'charges',
    header: 'Charges',
    dataName: (row: ProductivitySnapshot) => `$${formatNumberToWholeNumber(checkForNulls(row.charges))}`,
    columnType: ColumnType.CHARGES
  }
];

export const columnsForWrvuSummary: DataTableColumns[] = [
  {
    columnDef: 'metric',
    header: 'Metric',
    dataName: (row: ProductivitySummaryData) => `${row.metric}`,
    primaryColumn: true,
    class: 'headerMetric bold'
  },
  {
    columnDef: 'metricSelectedDateRange',
    header: 'Selected Date Range',
    dataName: (row: ProductivitySummaryData) => `${row.metricSelectedDateRange}`,
    class: 'headerSelectedDateRange'
  },
  {
    columnDef: 'metricPreviousYearSelectedDateRange',
    header: 'Previous Year\n Selected Date Range',
    dataName: (row: ProductivitySummaryData) => `${row.metricPreviousYearSelectedDateRange}`,
    class: 'headerPreviousYearSelectedDateRange'
  },
  {
    columnDef: 'metricYearToDate',
    header: 'YTD',
    dataName: (row: ProductivitySummaryData) => `${row.metricYearToDate}`,
    class: 'headerYearToDate'
  },
  {
    columnDef: 'metricPreviousYearToDate',
    header: 'Previous Year YTD',
    dataName: (row: ProductivitySummaryData) => `${row.metricPreviousYearToDate}`,
    class: 'headerPreviousYearToDate'
  },
  {
    columnDef: 'metricRecentMonth',
    header: 'Recent Month',
    dataName: (row: ProductivitySummaryData) => `${row.metricRecentMonth}`,
    class: 'headerRecentMonth'
  },
  {
    columnDef: 'metricPreviousYearRecentMonth',
    header: 'Previous Year\n Recent Month',
    dataName: (row: ProductivitySummaryData) => `${row.metricPreviousYearRecentMonth}`,
    class: 'headerPreviousYearRecentMonth'
  }
];

export const npvSummaryColumns: DataTableColumns[] = [
  {
    header: 'Metric',
    columnDef: 'metric',
    dataName: (row: NpvSummaryData) => `${row.metric}`,
    primaryColumn: true
  },
  {
    header: 'Selected Date Range',
    columnDef: 'metricSelectedDateRange',
    dataName: (row: NpvSummaryData) => `${row.metricSelectedDateRange}`
  },
  {
    header: 'Previous Year\nSelected Date Range',
    columnDef: 'metricPreviousYearSelectedDateRange',
    dataName: (row: NpvSummaryData) => `${row.metricPreviousYearSelectedDateRange}`
  },
  {
    header: 'YTD',
    columnDef: 'metricRecentMonth',
    dataName: (row: NpvSummaryData) => `${row.metricRecentMonth}`
  },
  {
    header: 'Previous Year YTD',
    columnDef: 'metricPreviousYearToDate',
    dataName: (row: NpvSummaryData) => `${row.metricPreviousYearRecentMonth}`
  },
  {
    header: 'Recent Month',
    columnDef: 'metricYearToDate',
    dataName: (row: NpvSummaryData) => `${row.metricYearToDate}`
  },
  {
    header: 'Previous Year\nRecent Month',
    columnDef: 'metricPreviousYearRecentMonth',
    dataName: (row: NpvSummaryData) => `${row.metricPreviousYearToDate}`
  }
];

// TODO: Per product design the data table should not include Recent Month or Prev Recent Month Columns
export const collectionSummaryColumns: DataTableColumns[] = [
  {
    columnDef: 'metric',
    header: 'Metric',
    dataName: (row: CollectionSummaryData) => `${row.metric}`
  },
  {
    columnDef: 'metricSelectedDateRange',
    header: 'Selected Date Range',
    dataName: (row: CollectionSummaryData) => `${row.metricSelectedDateRange}`
  },
  {
    columnDef: 'metricPreviousYearSelectedDateRange',
    header: 'Previous Year\nSelected Date Range',
    dataName: (row: CollectionSummaryData) => `${row.metricPreviousYearSelectedDateRange}`
  },
  {
    columnDef: 'metricYearToDate',
    header: 'YTD',
    dataName: (row: CollectionSummaryData) => `${row.metricYearToDate}`
  },
  {
    columnDef: 'metricPreviousYearToDate',
    header: 'Previous Year YTD',
    dataName: (row: CollectionSummaryData) => `${row.metricPreviousYearToDate}`
  }
];

export const columnsForPayerCollections: DataTableColumns[] = [
  {
    columnDef: 'payerCategoryDescription', header: 'Payer',
    dataName: (row: PayerCollections) => `${row.payerCategoryDescription}`,
    primaryColumn: true
  },
  {
    columnDef: 'chargeAmount', header: 'Charges',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.chargeAmount, 2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'primaryAndOtherPayerContractuals', header: 'Primary Contractuals',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.primaryAndOtherPayerContractuals, 2), 0))}`
  },
  {
    columnDef: 'charityCareAmount', header: 'Charity Care',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.charityCareAmount, 2), 0))}`
  },
  {
    columnDef: 'discountAmount', header: 'Discounts',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.discountAmount, 2), 0))}`
  },
  {
    columnDef: 'expectedPayments', header: 'Expected Payments',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.expectedPayments, 2), 0))}`,
    isDefault: true,
    group: 'group-last'
  },
  {
    columnDef: 'primaryAndOtherInsurancePayments', header: 'Insurance Payments',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(
      roundToNumber(row.primaryAndOtherInsurancePayments, 2), 0))}`,
    group: 'group-first',
    isDefault: true
  },
  {
    columnDef: 'patientPaymentAmount', header: 'Patient Payments',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.patientPaymentAmount, 2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'totalPayments', header: 'Total Payments',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.totalPayments, 2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'controllableAllowance', header: 'Controllable Allowances',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.controllableAllowance, 2), 0))}`
  },
  {
    columnDef: 'smallBalanceWriteOffs', header: 'Small Balance Write-offs',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.smallBalanceWriteOffs, 2), 0))}`
  },
  {
    columnDef: 'patientRelatedBadDebt', header: 'Patient-Related Bad Debt',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.patientRelatedBadDebt, 2), 0))}`
  },
  {
    columnDef: 'outstandingAR', header: 'Outstanding A/R',
    dataName: (row: PayerCollections) => `$${(roundToWithCommasSeparation(roundToNumber(row.outstandingAR, 2), 0))}`
  },
  {
    columnDef: 'outstandingARAsPctOfCharges', header: 'Outstanding AR as % of Charges',
    dataName: (row: PayerCollections) => `${roundToNumber(row.outstandingARAsPctOfCharges, 1) + '%'}`,
    group: 'group-last'
  },
  {
    columnDef: 'netCollectionRate', header: 'Net Collection Rate',
    dataName: (row: PayerCollections) => `${roundToNumber(row.netCollectionRate, 2) + '%'}`,
    group: 'group-first',
    isDefault: true
  },
  {
    columnDef: 'benchmark25th', header: 'Net Collection Rate Benchmark (25th)',
    dataName: (row: PayerCollections) => `${roundToNumber(row.benchmark25th, 0) + '%'}`,
    columnType: ColumnType.BENCHMARK,
    isDefault: true
  },
  {
    columnDef: 'variance25th', header: 'Variance',
    dataName: (row: PayerCollections) => `${roundToNumber(row.variance25th, 0) + '%'}`,
    columnType: ColumnType.VARIANCE,
    isDefault: true
  }
];

export const columnsForPayerByMultiLevelCollections: DataTableColumns[] = [
  {
    columnDef: 'chargeAmount',
    header: 'Charges',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.chargeAmount, 2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'expectedPayments',
    header: 'Expected Payments',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => `${'$' +
    (roundToWithCommasSeparation(roundToNumber(row.expectedPayments, 2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'totalPayments',
    header: 'Total Payments',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.totalPayments, 2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'netCollectionRate',
    header: 'Net Collection Rate',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => `${roundToNumber(row.netCollectionRate, 2) + '%'}`,
    isDefault: true
  },
  {
    columnDef: 'benchmark25th',
    header: 'Net Collection Rate Benchmark (25th)',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => `${roundCollectionsToNumber(row.benchmark25th, 1)}`,
    columnType: ColumnType.BENCHMARK,
    isDefault: true
  },
  {
    columnDef: 'variance25th',
    header: 'Variance (25th)',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) =>
      `${roundCollectionsToNumber(row.variance25th, 1)}`,
    columnType: ColumnType.VARIANCE,
    isDefault: true
  }
];

export const columnsForDenials: DataTableColumns[] = [
  {
    columnDef: 'denialRate',
    header: 'Denial Rate',
    dataName: (row: DenialsEntity) => `${roundToNearestTenthOrZero(row.denialRate)}` + '%',
    isDefault: true
  },
  {
    columnDef: 'denialRatePer50th',
    header: 'CPSC Denial Rate 50th Percentile',
    dataName: (row: DenialsEntity) => `${roundToNearestTenthOrZero(row.denialRatePer50th)}%`,
    isDefault: true,
    columnType: ColumnType.BENCHMARK
  },
  {
    columnDef: 'numberDenied',
    header: '# of CPT Codes Denied',
    dataName: (row: DenialsEntity) => `${roundTo(row.numberDenied, 0)}`,
    isDefault: true
  },
  {
    columnDef: 'numberBilled',
    header: '# of CPT Codes Billed',
    dataName: (row: DenialsEntity) => `${roundTo(row.numberBilled, 0)}`,
    isDefault: true
  },
  {
    columnDef: 'deniedPaidRate',
    header: '% of Denied CPTs Paid',
    dataName: (row: DenialsEntity) => `${roundToNearestTenthOrZero(row.deniedPaidRate)}%`,
    isDefault: true
  },
  {
    columnDef: 'deniedPaidPer50th',
    header: 'CPSC Denied CPTs Paid Benchmark 50th Percentile',
    dataName: (row: DenialsEntity) => `${roundToNearestTenthOrZero(row.deniedPaidPer50th)}%`,
    columnType: ColumnType.BENCHMARK,
    isDefault: true
  },
  {
    columnDef: 'chargeAmount',
    header: 'Total Charges',
    dataName: (row: DenialsEntity) => `$${roundToWithCommasSeparation(row.chargeAmount, 0)}`,
    isDefault: true,
    hoverMessage: 'These are the total charges for everything that was billed.'
  }
];

export const columnsForMultiLevelDenials: DataTableColumns[] = [
  {
    columnDef: 'numberBilled',
    header: '# of CPT Codes Billed',
    dataName: (row: DenialsMultiLevelEntity) => `${roundTo(row.numberBilled, 0)}`,
    isDefault: true
  },
  {
    columnDef: 'numberDenied',
    header: '# of CPT Codes Denied',
    dataName: (row: DenialsMultiLevelEntity) => `${roundTo(row.numberDenied, 0)}`,
    isDefault: true
  },
  {
    columnDef: 'denialRate',
    header: 'Denial Rate',
    dataName: (row: DenialsMultiLevelEntity) => `${roundToNearestTenthOrDash(row.denialRate)}%`,
    isDefault: true
  },
  {
    columnDef: 'denialRatePer50th',
    header: 'CPSC Denial Rate Benchmark 50th Percentile',
    dataName: (row: DenialsMultiLevelEntity) => roundToWithPercentage(row.denialRatePer50th, 1),
    isDefault: true,
    columnType: ColumnType.BENCHMARK
  },
  {
    columnDef: 'variancePer50th',
    header: 'Variance From Denial Rate Benchmark (50th)',
    dataName: (row: DenialsMultiLevelEntity) => row.variancePer25th ? `${roundToNearestTenthOrZero(row.variancePer50th)}%` : '-',
    columnType: ColumnType.VARIANCE,
    isDefault: true
  },
  {
    columnDef: 'deniedPaidRate',
    header: '% of Denied CPTs Paid',
    dataName: (row: DenialsMultiLevelEntity) => `${roundToNearestTenthOrDash(row.deniedPaidRate)}%`,
    isDefault: true
  },
  {
    columnDef: 'deniedPaidPer50th',
    header: 'CPSC Denied CPTs Paid Benchmark 50th Percentile',
    dataName: (row: DenialsMultiLevelEntity) => `${roundToNearestTenthOrDash(row.deniedPaidPer50th)}%`,
    columnType: ColumnType.DENIED_PAID_BENCHMARK,
    isDefault: true
  },
  {
    columnDef: 'terminalDenialRate',
    header: 'Terminal Denial Rate',
    dataName: (row: DenialsMultiLevelEntity) => formatToPercentage(row.terminalDenialRate, 1),
    isDefault: true
  },
  {
    columnDef: 'terminalDenialRatePer50th',
    header: 'CPSC Terminal Denial Rate Benchmark 50th Percentile',
    dataName: (row: DenialsMultiLevelEntity) => row.terminalDenialRatePer50th ?
      roundToNearestTenthOrZero(row.terminalDenialRatePer50th) : '-',
    isDefault: true,
    columnType: ColumnType.TERMINAL_DENIAL_BENCHMARK
  },
  {
    columnDef: 'chargeAmount',
    header: 'Total Charges',
    dataName: (row: DenialsMultiLevelEntity) => `$${roundToWithCommasSeparation(row.chargeAmount, 0)}`,
    isDefault: true,
  },
  {
    columnDef: 'deniedChargedAmount',
    header: 'Charges Denied',
    dataName: (row: DenialsMultiLevelEntity) => `$${(roundToWithCommasSeparation(roundToNumber(row.deniedChargedAmount,
      2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'chargesDeniedPercentage',
    header: '% of Charges Denied',
    dataName: (row: DenialsMultiLevelEntity) => `${roundToNearestTenthOrZero(row.chargesDeniedPercentage * 100)}%`,
    isDefault: true
  },
  {
    columnDef: 'chargesTerminallyDenied',
    header: 'Charges Terminally Denied',
    dataName: (row: DenialsMultiLevelEntity) => `$${roundToWithCommasSeparation(roundToNumber(row.chargesTerminallyDenied, 0))}`,
    isDefault: true
  },
  {
    columnDef: 'terminalChargesDeniedPercentage',
    header: '% of Charges Terminally Denied',
    dataName: (row: DenialsMultiLevelEntity) => `${multiplyToPercentRoundedToNearestTenthOrZero(row.terminalChargesDeniedPercentage)}%`,
    isDefault: true
  }
];

export const getTerminalDenialRateColumns = (columns: DataTableColumns[]) => {
  columns.forEach(c => {
    if (c.columnDef.includes('terminalDenialRatePer')) {
      c.showTerminalDenialRateColumn = true;
    }
  });
  return columns;
};

export const viewCPTsDeniedColumn: DataTableColumns = {
  columnDef: 'viewCPTsDenied',
  header: '',
  dataName: () => 'CPTs Denied',
  isDefault: true,
  showDenials: true,
  columnType: ColumnType.OPEN_WINDOW,
  sticky: true
};

export const columnsForMultiLevelDenialsDepartment: DataTableColumns[] = [
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: DenialsMultiLevelEntity) => row.departmentNodeName,
    isDefault: true,
    primaryColumn: true,
    sticky: true,
  },
  ...columnsForMultiLevelDenials
];

export const columnsForMultiLevelDenialsSpecialty: DataTableColumns[] = [
  {
    header: 'Specialty',
    columnDef: 'specialtyNodeName',
    dataName: (row: DenialsMultiLevelEntity) => row.specialtyNodeName,
    isDefault: true,
    primaryColumn: true,
    sticky: true
  },
  ...columnsForMultiLevelDenialsDepartment
];

export const columnsForMultiLevelDenialsProvider: DataTableColumns[] = [

  {
    header: 'Provider',
    columnDef: 'providerNodeName',
    dataName: (row: DenialsMultiLevelEntity) => row.providerNodeName,
    isDefault: true,
    primaryColumn: true,
    sticky: true
  },
  ...columnsForMultiLevelDenialsSpecialty
];

export const columnsForCptsDenied: DataTableColumns[] = [
  {
    columnDef: 'cptCode',
    header: 'CPT Code',
    dataName: (row: DenialCptCodeEntity) => row.cptCode,
    isDefault: true
  },
  ...columnsForDenials
];

export const columnsForDenialReasons: DataTableColumns[] = [
  {
    columnDef: 'denialReason',
    header: 'Denial Reasons',
    dataName: (row: DenialsEntityWithReason) => `${getReasonText(row)}`,
    isDefault: true,
    hoverMessage: 'Expand CPT Range Denied for more information about Denial Reason.'
  },
  ...columnsForDenials
];

export function columnsForDenialCptCodeByReason(): DataTableColumns[] {
  const cols: DataTableColumns[] = [];
  cols.push(...[
    {
      columnDef: 'cptCode',
      header: 'CPT Code Denied',
      dataName: (row: DenialCptCodeByReasonEntity) => row.cptCode,
      isDefault: true,
      primaryColumn: true
    },
    {
      columnDef: 'denialReason',
      header: 'Denial Reasons',
      dataName: (row: DenialCptCodeByReasonEntity) => `${getReasonText(row)}`,
      completeDataName: (row: DenialCptCodeByReasonEntity) => row.children ? `${row.children[0]?.denialReason || ''}` : '',
      upperDataName: (row: DenialCptCodeByReasonEntity) => `${row.denialReason}`,
      isDefault: true,
      hoverMessage: 'Expand CPT Code Denied for more information about Denial Reason.'
    },
    {
      columnDef: 'denialRate',
      header: 'Denial Rate',
      dataName: (row: DenialCptCodeByReasonEntity) => `${roundToNearestTenthOrDash(row.denialRate)}%`,
      isDefault: true
    },
    {
      columnDef: 'denialRatePer50th',
      header: 'CPSC Denial Rate 50th Percentile',
      dataName: (row: DenialCptCodeByReasonEntity) => row.denialRatePer50th ? `${roundToNearestTenthOrDash(row.denialRatePer50th)}%` : '-',
      isDefault: true,
      hoverMessage: 'This value corresponds to the CPT code only.',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'deniedMean',
      header: 'CPSC Denial Rate Mean',
      dataName: (row: DenialCptCodeByReasonEntity) => row.denialRateMean ?
        `${multiplyToPercentRoundedToNearestTenthOrDash(row.denialRateMean)}%` : '-',
      columnType: ColumnType.BENCHMARK,
      hoverMessage: 'This value corresponds to the denial reason only.',
      isDefault: true
    },
    {
      columnDef: 'numberDenied',
      header: '# of CPT Codes Denied',
      dataName: (row: DenialCptCodeByReasonEntity) => `${roundTo(row.numberDenied, 0)}`,
      isDefault: true
    },
    {
      columnDef: 'numberBilled',
      header: '# of CPT Codes Billed',
      dataName: (row: DenialCptCodeByReasonEntity) => `${roundTo(row.numberBilled, 0)}`,
      isDefault: true
    },
    {
      columnDef: 'deniedPaidRate',
      header: '% of Denied CPTs Paid',
      dataName: (row: DenialCptCodeByReasonEntity) => `${roundToNearestTenthOrDash(row.deniedPaidRate)}%`,
      isDefault: true
    },
    {
      columnDef: 'deniedPaidPer50th',
      header: 'CPSC Denied CPTs Paid Benchmark 50th Percentile',
      dataName: (row: DenialCptCodeByReasonEntity) => row.deniedPaidPer50th ? `${multiplyToPercentRoundedToNearestTenthOrDash(row.deniedPaidPer50th)}%` : '-',
      columnType: ColumnType.BENCHMARK,
      hoverMessage: 'This value corresponds to the CPT code only.',
      isDefault: true
    },
    {
      columnDef: 'deniedPaidMean',
      header: 'CPSC Denied CPTs Paid Benchmark Mean',
      dataName: (row: DenialCptCodeByReasonEntity) => row.deniedPaidRateMean ?
        `${multiplyToPercentRoundedToNearestTenthOrDash(row.deniedPaidRateMean)}%` : '-',
      columnType: ColumnType.BENCHMARK,
      hoverMessage: 'This value corresponds to the denial reason only.',
      isDefault: true
    },
    {
      columnDef: 'chargeAmount',
      header: 'Total Charges',
      dataName: (row: DenialCptCodeByReasonEntity) => `$${roundToWithCommasSeparation(row.chargeAmount, 0)}`,
      isDefault: true,
      hoverMessage: 'These are the total charges for everything that was billed.'
    },
    {
      columnDef: 'chargesDenied',
      header: 'Charges Denied',
      dataName: (row: DenialCptCodeByReasonEntity) => `${formatToCurrency(row.chargesDenied, 0)}`,
      isDefault: true
    },
    {
      columnDef: 'chargesDeniedPercentage',
      header: '% of Charges Denied',
      dataName: (row: DenialCptCodeByReasonEntity) => `${multiplyToPercentRoundedToNearestTenthOrDash(row.chargesDeniedPercentage)}%`,
      isDefault: true
    }, {
      columnDef: 'terminalDenialRate',
      header: 'Terminal Denial Rate',
      dataName: (row: DenialCptCodeByReasonEntity) => `${multiplyToPercentRoundedToNearestTenthOrDash(row.terminalDenialRate)}%`,
      isDefault: true
    }, {
      columnDef: 'terminalDenialRatePer50th',
      header: 'Terminal Denial Rate Benchmark 50th Percentile',
      dataName: (row: DenialCptCodeByReasonEntity) => row.terminalDenialRatePer50th ? `${multiplyToPercentRoundedToNearestTenthOrDash(row.terminalDenialRatePer50th)}%` : '-',
      hoverMessage: 'This value corresponds to the CPT code only.',
      isDefault: true,
      columnType: ColumnType.BENCHMARK
    }, {
      columnDef: 'chargesTerminallyDenied',
      header: 'Charges Terminally Denied',
      dataName: (row: DenialCptCodeByReasonEntity) => `${formatToCurrency(row.chargesTerminallyDenied, 0)}`,
      isDefault: true
    }, {
      columnDef: 'terminalChargesDeniedPercentage',
      header: '% of Charges Terminally Denied',
      dataName: (row: DenialCptCodeByReasonEntity) => row.terminalChargesDeniedPercentage ? `${multiplyToPercentRoundedToNearestTenthOrZero(row.terminalChargesDeniedPercentage)}%` : '-',
      isDefault: true
    }]);
  return cols;
}

export const maxDenialReasonChars = 20;

export function getReasonText(row: DenialsEntityWithReason): string {

  let reasonText = '';
  return row.children?.length ? getReasonPreviewForParentRow(row) || reasonText : getReasonTextForChildRow();

  function getReasonTextForChildRow() {
    reasonText = row.denialReason;
    const actualLength = hasValue(reasonText) ? reasonText.length : 0;
    return actualLength >= maxDenialReasonChars && !row.isExpanded ? reasonText.substring(0,
      maxDenialReasonChars).concat('...') : reasonText;
  }

  function getReasonPreviewForParentRow(parent: DenialsEntityWithReason): string {
    if (parent.children) {
      const maxChars = parent.children.length > 1 ? 10 : 20;
      if (parent.children && parent.children.length > 1) {
        reasonText = parent.children[0].denialReason + '';
      } else {
        reasonText = parent.children[0].denialReason + '';
      }
      const actualLength = hasValue(reasonText) ? reasonText.length : 0;
      return actualLength >= maxChars && !parent.isExpanded ? reasonText.substring(0, maxChars).concat('...') : reasonText;
    }
    return '';
  }
}

export const columnsForDenialsRate: DataTableColumns[] = [
  {
    header: 'Payer Categories',
    columnDef: 'payerCategoryDesc',
    dataName: (row: DenialPayerEntity) => row.payerCategoryDesc,
    isDefault: true
  },
  ...columnsForDenials
];

export const columnsForDenialsByPayer: DataTableColumns[] = [
  {
    header: 'Payer',
    columnDef: 'payerCategoryDesc',
    dataName: (row: DenialPayerEntity) => row.payerCategoryDesc,
    isDefault: true,
    primaryColumn: true, sticky: true
  },
  {
    columnDef: 'numberBilled',
    header: '# of CPT Codes Billed',
    dataName: (row: DenialPayerEntity) => `${roundTo(row.numberBilled,
      0)}`,
    isDefault: true
  },
  {
    columnDef: 'numberDenied',
    header: '# of CPT Codes Denied',
    dataName: (row: DenialPayerEntity) => `${roundTo(row.numberDenied,
      0)}`,
    isDefault: true,
  },
  {
    columnDef: 'denialRate',
    header: 'Denial Rate',
    dataName: (row: DenialPayerEntity) => `${roundToNearestTenthOrDash(row.denialRate)}%`,
    isDefault: true,
    primaryColumn: true
  },
  {
    columnDef: 'denialRatePer50th',
    header: 'CPSC Denial Rate 50th Percentile',
    dataName: (row: DenialPayerEntity) => row.denialRatePer50th ? `${roundToNearestTenthOrZero(row.denialRatePer50th)}%` : '-',
    isDefault: true,
    columnType: ColumnType.BENCHMARK
  },
  {
    columnDef: 'variancePer50th',
    header: 'Variance From Denial Rate (50th)',
    dataName: (row: DenialPayerEntity) => `${roundToNearestTenthOrDash(row.variancePer50th)}%`,
    columnType: ColumnType.VARIANCE,
    isDefault: true
  },
  {
    columnDef: 'deniedPaidRate',
    header: '% of Denied CPTs Paid',
    dataName: (row: DenialPayerEntity) => `${roundToNearestTenthOrDash(row.deniedPaidRate)}%`,
    isDefault: true,
  },
  {
    columnDef: 'deniedPaidPer50th',
    header: 'CPSC Denied CPTs Paid Benchmark 50th Percentile',
    dataName: (row: DenialPayerEntity) => row.deniedPaidPer50th ?
      `${multiplyToPercentRoundedToNearestTenthOrZero(row.deniedPaidPer50th)}%` : '-',
    columnType: ColumnType.DENIED_PAID_BENCHMARK,
    isDefault: true,
  },
  {
    columnDef: 'terminalDenialRate',
    header: 'Terminal Denial Rate',
    dataName: (row: DenialPayerEntity) => `${formatToPercentage(row.terminalDenialRate, 1)}`,
    isDefault: true
  },
  {
    columnDef: 'terminalDenialRateBenchmark50th',
    header: 'Terminal Denial Rate Benchmark 50th Percentile',
    dataName: (row: DenialPayerEntity) => `${roundTo(row.terminalDenialRatePer50th, 0)}`,
    isDefault: true,
    columnType: ColumnType.TERMINAL_DENIAL_BENCHMARK
  },
  {
    columnDef: 'chargeAmount',
    header: 'Total Charges',
    dataName: (row: DenialPayerEntity) => `$${(roundToWithCommasSeparation(roundToNumber(row.chargeAmount, 2), 0))}`,
    isDefault: true,
  },
  {
    columnDef: 'deniedChargedAmount',
    header: 'Charges Denied',
    dataName: (row: DenialPayerEntity) => `$${(roundToWithCommasSeparation(roundToNumber(row.deniedChargedAmount, 2), 0))}`,
    isDefault: true
  },
  {
    columnDef: 'chargesDeniedPercentage',
    header: '% of Charges Denied',
    dataName: (row: DenialPayerEntity) => `${multiplyToPercentRoundedToNearestTenthOrZero(row.chargesDeniedPercentage)}%`,
    isDefault: true
  },
  {
    columnDef: 'chargesTerminallyDenied',
    header: 'Charges Terminally Denied',
    dataName: (row: DenialPayerEntity) => `$${roundToWithCommasSeparation(row.chargesTerminallyDenied, 0)}`,
    isDefault: true
  },
  {
    columnDef: 'terminalChargesDeniedPercentage',
    header: '% of Charges Terminally Denied',
    dataName: (row: DenialPayerEntity) => `${multiplyToPercentRoundedToNearestTenthOrZero(row.terminalChargesDeniedPercentage)}%`,
    isDefault: true
  }
];

export const columnsForMultiLevelSnapshotCollections: DataTableColumns[] = [
  {
    header: 'Charges',
    columnDef: 'chargeAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) =>
      `$${(roundToWithCommasSeparation(roundToNumber(row.chargeAmount, 2), 0))}`,
    columnType: ColumnType.CHARGES,
    isDefault: true
  },
  {
    header: 'Charity Care Amount',
    columnDef: 'charityCareAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) =>
      `$${(roundToWithCommasSeparation(roundToNumber(row.charityCareAmount, 0)))}`
  },
  {
    header: 'Discount Amount',
    columnDef: 'discountAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.discountAmount, 0)))}`
  },
  {
    header: 'Patient Payment Amount',
    columnDef: 'patientPaymentAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.patientPaymentAmount, 0)))}`

  },
  {
    header: 'Primary Payer Payment Amount',
    columnDef: 'primaryPayerPaymentAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.primaryPayerPaymentAmount, 0)))}`

  },
  {
    header: 'Other Payer Payment Amount',
    columnDef: 'otherPayerPaymentAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.otherPayerPaymentAmount, 0)))}`

  },
  {
    header: 'Primary Payer Adj. Amount',
    columnDef: 'primaryPayerAdjAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.primaryPayerAdjAmount, 0)))}`

  },
  {
    header: 'Other Payer Adj. Amount',
    columnDef: 'otherPayerAdjAmount',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.otherPayerAdjAmount, 0)))}`

  },
  {
    header: 'Primary and Other Payer Contractuals',
    columnDef: 'primaryAndOtherPayerContractuals',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) =>
      `$${(roundToWithCommasSeparation(roundToNumber(row.primaryAndOtherPayerContractuals, 0)))}`

  },
  {
    header: 'Primary and Other Insurance Payments',
    columnDef: 'primaryAndOtherInsurancePayments',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) =>
      `$${(roundToWithCommasSeparation(roundToNumber(row.primaryAndOtherInsurancePayments, 0)))}`,

    isDefault: true
  },
  {
    header: 'Net Collection Rate',
    columnDef: 'netCollectionRate',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `${roundToNumber(row.netCollectionRate, 2) + '%'}`,

    isDefault: true
  },
  {
    header: 'Expected Payments',
    columnDef: 'expectedPayments',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `${'$' +
    (roundToWithCommasSeparation(roundToNumber(row.expectedPayments, 2), 0))}`,

    isDefault: true
  },
  {
    header: 'Total Payments',
    columnDef: 'totalPayments',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) =>
      `$${(roundToWithCommasSeparation(roundToNumber(row.totalPayments, 2), 0))}`,

    isDefault: true
  },
  {
    header: 'Controllable Allowance',
    columnDef: 'controllableAllowance',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.controllableAllowance, 0)))}`
  },
  {
    header: 'Patient Related Bad Debt',
    columnDef: 'patientRelatedBadDebt',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.patientRelatedBadDebt, 0)))}`

  },
  {
    header: 'Small Balance Write Offs',
    columnDef: 'smallBalanceWriteOffs',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.smallBalanceWriteOffs, 0)))}`

  },
  {
    header: 'Outstanding AR',
    columnDef: 'outstandingAR',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `$${(roundToWithCommasSeparation(roundToNumber(row.outstandingAR, 0)))}`
  },
  {
    header: 'Outstanding AR as Pct of Charges',
    columnDef: 'outstandingARAsPctOfCharges',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `${roundToNumber(row.outstandingARAsPctOfCharges, 1) + '%'}`
  },
  {
    header: 'Net Collection Rate Benchmark (25th)',
    columnDef: 'benchmark25th',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => `${roundCollectionsToNumber(row.benchmark25th, 1)}`,
    columnType: ColumnType.BENCHMARK,
    isDefault: true
  },
  {
    header: 'Variance (25th)',
    columnDef: 'variance25th',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) =>
      `${roundCollectionsToNumber(row.variance25th, 1)}`,
    columnType: ColumnType.VARIANCE,
    isDefault: true
  }
];

export const columnsForMultiLevelCollectionsDepartment: DataTableColumns[] = [
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => row.departmentNodeName,
    isDefault: true,
    primaryColumn: true

  },
  ...columnsForMultiLevelSnapshotCollections
];

export const columnsForMultiLevelCollectionsSpecialty: DataTableColumns[] = [
  {
    header: 'Specialty',
    columnDef: 'specialtyNodeName',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => row.specialtyNodeName,
    isDefault: true,
    primaryColumn: true
  },
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => row.departmentNodeName,
    isDefault: true,
    primaryColumn: true

  },
  ...columnsForMultiLevelSnapshotCollections

];

export const columnsForMultiLevelCollectionsProvider: DataTableColumns[] = [

  {
    header: 'Provider',
    columnDef: 'providerNodeName',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => row.providerNodeName,
    isDefault: true,
    primaryColumn: true
  },
  {
    header: 'Specialty',
    columnDef: 'specialtyNodeName',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => row.specialtyNodeName,
    isDefault: true,
    primaryColumn: true
  },
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: MatchedCollectionsByMultiLevelByNodePath) => row.departmentNodeName,
    isDefault: true,
    primaryColumn: true
  },
  ...columnsForMultiLevelSnapshotCollections

];

export const columnsForMultiLevelPayerCollectionsDepartment: DataTableColumns[] = [
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => row.departmentNodeName,
    isDefault: true,
    primaryColumn: true

  },
  ...columnsForPayerByMultiLevelCollections.slice()
];

export const columnsForMultiLevelPayerCollectionsSpecialty: DataTableColumns[] = [
  {
    header: 'Specialty',
    columnDef: 'specialtyNodeName',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => row.specialtyNodeName,
    isDefault: true,
    primaryColumn: true
  },
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => row.departmentNodeName,
    isDefault: true,
    primaryColumn: true

  },
  ...columnsForPayerByMultiLevelCollections.slice()

];

export const columnsForMultiLevelPayerCollectionsProvider: DataTableColumns[] = [
  {
    header: 'Provider',
    columnDef: 'providerNodeName',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => row.providerNodeName,
    isDefault: true,
    primaryColumn: true
  },
  {
    header: 'Specialty',
    columnDef: 'specialtyNodeName',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => row.specialtyNodeName,
    isDefault: true,
    primaryColumn: true
  },
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: PayerByMultiLevelCollectionsByNodePath) => row.departmentNodeName,
    isDefault: true,
    primaryColumn: true
  },
  ...columnsForPayerByMultiLevelCollections.slice()

];

export const columnsForSnapshotCollections: DataTableColumns[] = [
  {
    columnDef: 'nodeName',
    header: '',
    dataName: (row: SnapshotMatchedCollections) => `${row.nodeName}`,
    primaryColumn: true
  },
  {
    columnDef: 'chargeAmount',
    header: 'Charges',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.chargeAmount, 0))}`,
    isDefault: true
  },
  {
    columnDef: 'discountAmount',
    header: 'Discounts',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.discountAmount, 0))}`
  },
  {
    columnDef: 'charityCareAmount',
    header: 'Charity',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.charityCareAmount, 0))}`
  },
  {
    columnDef: 'primaryAndOtherPayerContractuals',
    header: 'Primary Contractuals',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.primaryAndOtherPayerContractuals, 0))}`
  },
  {
    columnDef: 'expectedPayments',
    header: 'Expected Payments',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.expectedPayments, 0))}`,
    isDefault: true
  },
  {
    columnDef: 'patientRelatedBadDebt',
    header: 'Patient-Related Bad Debt',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.patientRelatedBadDebt, 0))}`
  },
  {
    columnDef: 'smallBalanceWriteOffs',
    header: 'Small Balance Write-offs',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.smallBalanceWriteOffs, 0))}`
  },
  {
    columnDef: 'controllableAllowance',
    header: 'Controllable Allowances',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.controllableAllowance, 0))}`
  },
  {
    columnDef: 'outstandingAR',
    header: 'Outstanding A/R',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.outstandingAR, 0))}`
  },
  {
    columnDef: 'outstandingARAsPctOfCharges',
    header: 'Outstanding AR as % of Charges',
    dataName: (row: SnapshotMatchedCollections) => `${roundToNumber(row.outstandingARAsPctOfCharges, 1) + '%'}`,
    group: 'group-last'
  },
  {
    columnDef: 'primaryAndOtherInsurancePayments',
    header: 'Insurance Payments',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.primaryAndOtherInsurancePayments, 0))}`,
    group: 'group-first',
    isDefault: true
  },
  {
    columnDef: 'patientPaymentAmount',
    header: 'Patient Payments',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.patientPaymentAmount, 0))}`,
    isDefault: true
  },
  {
    columnDef: 'totalPayments',
    header: 'Total Payments',
    dataName: (row: SnapshotMatchedCollections) => `$${(roundToWithCommasSeparation(row.totalPayments, 0))}`,
    isDefault: true,
    group: 'group-last'
  },
  {
    columnDef: 'netCollectionRate',
    header: 'Net Collection Rate',
    dataName: (row: SnapshotMatchedCollections) => `${roundToNumber(row.netCollectionRate, 1) + '%'}`,
    group: 'group-first',
    isDefault: true
  },
  {
    columnDef: 'benchmark25th',
    header: 'Net Collection Rate Benchmark',
    dataName: (row: SnapshotMatchedCollections) => `${roundToNumber(row.benchmark25th, 0) + '%'}`,
    columnType: ColumnType.BENCHMARK,
    isDefault: true
  },
  {
    columnDef: 'variance',
    header: 'Variance',
    dataName: (row: SnapshotMatchedCollections) => `${roundToNumber(row.variance25th, 0) + '%'}`,
    columnType: ColumnType.VARIANCE,
    isDefault: true
  }
];

// TODO: Per product design the data table should not include Recent Month or Prev Recent Month Columns
export const columnsForSummaryCollections: DataTableColumns[] = [
  {
    header: 'Metric',
    columnDef: 'metric',
    dataName: (row: CollectionSummaryData) => row.metric
  },
  {
    header: 'Selected Date Range',
    columnDef: 'metricSelectedDateRange',
    dataName: (row: CollectionSummaryData) => row.metricSelectedDateRange
  },
  {
    upperHeader: 'Previous Year',
    header: 'Selected Date Range',
    columnDef: 'metricPreviousYearSelectedDateRange',
    dataName: (row: CollectionSummaryData) => row.metricPreviousYearSelectedDateRange
  },
  {
    header: 'YTD',
    columnDef: 'metricYearToDate',
    dataName: (row: CollectionSummaryData) => row.metricYearToDate
  },
  {
    header: 'Previous Year YTD',
    columnDef: 'metricPreviousYearToDate',
    dataName: (row: CollectionSummaryData) => row.metricPreviousYearToDate
  }
];

export const tableCellsForCollectionsSummary: TableCell[] = [
  {label: 'Charges', field: 'chargeAmount'},
  {label: 'Payer Contractual', field: 'primaryAndOtherPayerContractuals'},
  {label: 'Discounts', field: 'discountAmount'},
  {label: 'Charity', field: 'charityCareAmount'},
  {label: 'Expected Payments', field: 'expectedPayments'},
  {label: 'Controllable Allowances', field: 'controllableAllowance'},
  {label: 'Outstanding A/R', field: 'outstandingAR'},
  {label: 'Patient-Related Bad Debt', field: 'patientRelatedBadDebt'},
  {label: 'Small Balance Write-Offs', field: 'smallBalanceWriteOffs'},
  {label: 'Total Payments', field: 'totalPayments'},
  {label: 'Insurance Payments', field: 'primaryAndOtherInsurancePayments'},
  {label: 'Patient Payments', field: 'patientPaymentAmount'}];

export const cptCodeColumns: DataTableColumns[] = [
  {
    columnDef: 'cptDesc', header: 'CPTs',
    dataName: (row: ClinicalSummaryCode) => `${row.cptCode} ${row.cptDesc}`,
    primaryColumn: true
  },
  {
    columnDef: 'cfteAdjustedFrequency', header: 'cFTE adj. Count',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.cfteAdjustedFrequency),
      0)}`,
    aboveHeaderText: 'Provider Performance',
    hoverMessage: 'CPT Units billed, adjusted to a 1.0 cFTE. Calculation: (Frequency/Reported cFTE)'
  },
  {
    columnDef: 'cfteAdjustedWRVUs', header: 'cFTE adj. wRVUs',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.cfteAdjustedWRVUs), 0)}`,
    hoverMessage: 'Work RVUs generated, adjusted to a 1.0 cFTE. Calculation: wRVUs/Reported cFTE'
  },
  {
    columnDef: 'frequencyBenchmark', header: 'cFTE adj. Count Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.frequencyBenchmark), 0)}`,
    hoverMessage: 'National benchmark value for CPT Units billed per 1.0 cFTE'
  },
  {
    columnDef: 'cFTEAdjustedFrequencyVariance',
    header: 'cFTE adj. Count Variance From Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.cFTEAdjustedFrequencyVariance), 0)}`,
    aboveHeaderText: 'CPSC Academic Benchmark',
    hoverMessage: 'cFTE Adjusted Count  minus the “Selected” Benchmark Percentile',
    tornado: 'countTornado'
  },
  {
    columnDef: 'workRvuBenchmark', header: 'cFTE adj. wRVUs Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.workRvuBenchmark), 0)}`,
    hoverMessage: 'National benchmark value for Work RVUs generated per 1.0 cFTE'
  },
  {
    columnDef: 'cFTEAdjustedWrvusVariance', header: 'cFTE adj. wRVUs Variance From Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(
      row.cFTEAdjustedWrvusVariance), 0)}`,
    hoverMessage: 'cFTE Adjusted wRVUs minus the “Selected” Benchmark Percentile',
    tornado: 'wrvuTornado'
  }
];

export function getSharedCptColumns(viewCommunityBenchmarks: boolean, showAdditionalCFPColumns: boolean,
                                    showCfteWrvus: boolean, showCfteCount: boolean): DataTableColumns[] {

  let sharedColumns: DataTableColumns[] = [{
    columnDef: 'wRVUs',
    header: 'Actual wRVUs',
    dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(checkForNulls(row.wRVUs)),
    showAdditionalCFPColumns: showAdditionalCFPColumns,
    group: showAdditionalCFPColumns ? 'group-first' : '',
    classNames: 'align-right'
  }];

  if (showCfteWrvus) {
    sharedColumns = sharedColumns.concat([{
      columnDef: 'cfteAdjustedWRVUs',
      header: 'cFTE adj. wRVUs',
      dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(checkForNulls(row.cfteAdjustedWRVUs)),
      classNames: 'align-right'
    }, {
      columnDef: viewCommunityBenchmarks ? 'communityWorkRvuBenchmark' : 'workRvuBenchmark',
      header: viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' :
        'cFTE adj. wRVUs Academic Benchmark Mean',
      dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(undefinedIfZero(
        viewCommunityBenchmarks ? row.communityWorkRvuBenchmark : row.workRvuBenchmark)),
      columnType: ColumnType.BENCHMARK,
      classNames: 'benchmark align-right'
    },
      {
        columnDef: 'cFTEAdjustedWrvusVariance',
        header: 'cFTE adj. wRVUs Variance',
        dataName: (row: ClinicalSummaryCode) => undefinedIfZero(row.workRvuBenchmark)
          ? formatNumberToWholeNumber(viewCommunityBenchmarks ? row.communityCfteAdjustedWrvusVariance : row.cFTEAdjustedWrvusVariance)
          : '-',
        columnType: ColumnType.VARIANCE,
        classNames: 'variance align-right',
        class: (row: ClinicalSummaryCode) => row.workRvuBenchmark
          ? getCssClass(parseFloat(formatNumberToWholeNumber(checkForNulls(
            viewCommunityBenchmarks ? row.communityCfteAdjustedWrvusVariance : row.cFTEAdjustedWrvusVariance))))
          : 'null'
      }]);
  }
  sharedColumns.push(
    {
      columnDef: 'frequency',
      header: 'Count',
      dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(checkForNulls(row.frequency)),
      showAdditionalCFPColumns: showAdditionalCFPColumns,
      classNames: 'align-right',
      group: 'group-first'
    });
  if (showCfteCount) {
    sharedColumns = sharedColumns.concat([{
      columnDef: 'cfteAdjustedFrequency',
      header: 'cFTE adj. Count',
      classNames: 'align-right',
      dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(checkForNulls(row.cfteAdjustedFrequency))
    }, {
      columnDef: 'frequencyBenchmark',
      header: viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' :
        'cFTE adj. Count Academic Benchmark Mean',
      classNames: 'align-right',
      dataName: (row: ClinicalSummaryCode) =>
        formatNumberToWholeNumber(undefinedIfZero(viewCommunityBenchmarks ? row.communityFrequencyBenchmark : row.frequencyBenchmark))
    },
      {
        columnDef: 'cFTEAdjustedFrequencyVariance',
        header: 'cFTE adj. Count Variance',
        classNames: 'align-right',
        dataName: (row: ClinicalSummaryCode) => undefinedIfZero(row.frequencyBenchmark)
          ? formatNumberToWholeNumber(checkForNulls(
            viewCommunityBenchmarks ? row.communityCfteAdjustedFrequencyVariance : row.cFTEAdjustedFrequencyVariance))
          : '-',
        class: (row: ClinicalSummaryCode) => undefinedIfZero(row.frequencyBenchmark)
          ? getCssClass(parseFloat(formatNumberToWholeNumber(checkForNulls(row.cFTEAdjustedFrequencyVariance))))
          : 'null'
      }]);
  }
  sharedColumns.push(
    {
      columnDef: 'charges',
      header: 'Charges',
      classNames: 'align-right',
      dataName: (row: ClinicalSummaryCode) => `$${formatNumberToWholeNumber(checkForNulls(row.charges))}`,
      showAdditionalCFPColumns: showAdditionalCFPColumns
    });
  return sharedColumns;
}

export function getProviderColumnForCFP(viewType: CptViewType, viewCommunity: boolean, showAdditionalColumns: boolean,
                                        showCfteWrvus: boolean, showCfteCount: boolean):
  DataTableColumns[] {
  return [
    {
      columnDef: 'provider',
      header: 'Provider /\nSpecialty /\nDepartment',
      dataName: (row: CfpByMultilevel) => `${row.providerNodeName} /\n${row.specialtyNodeName} /\n${row.departmentNodeName}`,
      primaryColumn: true,
      classNames: 'align-left'
    },
    ...getColumnsForCfp(viewType, viewCommunity, showAdditionalColumns, showCfteWrvus, showCfteCount)
  ];
}

export function getSpecialtyColumnForCFP(viewType: CptViewType, viewCommunity: boolean, showAdditionalColumns: boolean,
                                         showCfteWrvus: boolean, showCfteCount: boolean):
  DataTableColumns[] {
  return [
    {
      columnDef: 'specialty',
      header: 'Specialty /\nDepartment',
      dataName: (row: CfpByMultilevel) => `${row.specialtyNodeName} /\n${row.departmentNodeName}`,
      primaryColumn: true,
      classNames: 'align-left'
    },
    ...getColumnsForCfp(viewType, viewCommunity, showAdditionalColumns, showCfteWrvus, showCfteCount)
  ];
}

export function getDepartmentColumnForCFP(viewType: CptViewType, viewCommunity: boolean, showAdditionalColumns: boolean,
                                          showCfteWrvus: boolean, showCfteCount: boolean):
  DataTableColumns[] {
  return [
    {
      columnDef: 'department',
      header: 'Department',
      dataName: (row: CfpByMultilevel) => row.departmentNodeName,
      primaryColumn: true,
      classNames: 'align-left'
    },
    ...getColumnsForCfp(viewType, viewCommunity, showAdditionalColumns, showCfteWrvus, showCfteCount)
  ];
}

export function getColumnsForCfp(viewType: CptViewType, viewCommunityBenchmarks: boolean, showAdditionalColumns: boolean,
                                 showCfteWrvus: boolean, showCfteCount: boolean):
  DataTableColumns[] {
  let result: DataTableColumns[] = [];
  switch (viewType) {
    case CptViewType.CptCode:
      result = [
        {
          columnDef: 'cptCode',
          header: 'CPT Code',
          upperDataName: (row: ClinicalSummaryCode) => row.cptCode,
          dataName: (row: ClinicalSummaryCode) => row.cptDesc,
          classNames: 'align-left',
          primaryColumn: true
        },
        {
          columnDef: 'cptRangeLow',
          header: 'CPT Range',
          upperDataName: (row: ClinicalSummaryCode) => `${row.cptRangeLow} - ${row.cptRangeHigh}`,
          dataName: (row: ClinicalSummaryCode) => row.cptRangeDesc,
          dataNameList: (row: ClinicalSummaryRange) => [`${row.cptRangeLow} - ${row.cptRangeHigh}`,
            row.cptRangeDesc],
          classNames: 'align-left',
          primaryColumn: true
        },
        {
          columnDef: 'cptFamilyDesc',
          header: 'CPT Family',
          dataName: (row: ClinicalSummaryCode) => row.cptFamilyDesc,
          classNames: 'align-left',
          primaryColumn: true
        }];
      break;
    case CptViewType.CptRange:
      result = [
        {
          columnDef: 'cptRangeLow',
          header: 'CPT Range',
          upperDataName: (row: ClinicalSummaryRange) => `${row.cptRangeLow} - ${row.cptRangeHigh}`,
          dataName: (row: ClinicalSummaryRange) => row.cptRangeDesc,
          dataNameList: (row: ClinicalSummaryRange) => [`${row.cptRangeLow} - ${row.cptRangeHigh}`,
            row.cptRangeDesc],
          classNames: 'align-left',
          primaryColumn: true
        },
        {
          columnDef: 'cptFamilyDesc',
          header: 'CPT Family',
          dataName: (row: ClinicalSummaryRange) => row.cptFamilyDesc,
          classNames: 'align-left',
          primaryColumn: true
        }];
      break;
    case CptViewType.CptFamily:
      result = [
        {
          columnDef: 'cptFamilyDesc',
          header: 'CPT Family',
          dataName: (row: ClinicalSummaryFamily) => row.cptFamilyDesc,
          classNames: 'align-left',
          primaryColumn: true
        }];
      break;
    default:
      return [];
  }
  return result.concat(getSharedCptColumns(viewCommunityBenchmarks, showAdditionalColumns, showCfteWrvus, showCfteCount));
}

export function filterOutCfteAdjWrvuRelatedColumns(columns: any[]) {
  return columns.filter(col => !CFTE_ADJ_WRVU_RELATED_COLUMN_TYPES.includes(col.columnType));
}

export const cptCodeCommunityColumns: DataTableColumns[] = [
  {
    columnDef: 'cptDesc', header: 'CPTs',
    dataName: (row: ClinicalSummaryCode) => `${row.cptCode} ${row.cptDesc}`,
    primaryColumn: true
  },
  {
    columnDef: 'cfteAdjustedFrequency', header: 'cFTE adj. Count',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.cfteAdjustedFrequency),
      0)}`,
    aboveHeaderText: 'Provider Performance',
    hoverMessage: 'CPT Units billed, adjusted to a 1.0 cFTE. Calculation: (Frequency/Reported cFTE)'
  },
  {
    columnDef: 'cfteAdjustedWRVUs', header: 'cFTE adj. wRVUs',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.cfteAdjustedWRVUs), 0)}`,
    hoverMessage: 'Work RVUs generated, adjusted to a 1.0 cFTE. Calculation: wRVUs/Reported cFTE'
  },
  {
    columnDef: 'communityFrequencyBenchmark', header: 'cFTE adj. Count Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.communityFrequencyBenchmark), 0)}`,
    hoverMessage: 'National benchmark value for CPT Units billed per 1.0 cFTE'
  },
  {
    columnDef: 'communityCfteAdjustedFrequencyVariance',
    header: 'cFTE adj. Count Variance From Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.communityCfteAdjustedFrequencyVariance), 0)}`,
    aboveHeaderText: 'CPSC Community Benchmark',
    hoverMessage: 'cFTE Adjusted Count  minus the “Selected” Benchmark Percentile',
    tornado: 'countTornado'
  },
  {
    columnDef: 'communityWorkRvuBenchmark', header: 'cFTE adj. wRVUs Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(row.communityWorkRvuBenchmark), 0)}`,
    hoverMessage: 'National benchmark value for Work RVUs generated per 1.0 cFTE'
  },
  {
    columnDef: 'communityCfteAdjustedWrvusVariance', header: 'cFTE adj. wRVUs Variance From Benchmark',
    dataName: (row: ClinicalSummaryCode) => `${roundToNumber(checkForNulls(
      row.communityCfteAdjustedWrvusVariance), 0)}`,
    hoverMessage: 'cFTE Adjusted wRVUs minus the “Selected” Benchmark Percentile',
    tornado: 'wrvuTornado'
  }
];

export interface SelectableColumn extends BaseColumn {
  selected: boolean;
}

export interface DataTableColumns extends BaseColumn {
  dataName: any;
  completeDataName?: any;
  upperDataName?: any;
  upperHeader?: string;
  dataNameList?: any;
  primaryColumn?: boolean;
  isDefault?: boolean;
  showCpt?: boolean;
  showHoverMessage?: boolean;
  showDates?: boolean;
  group?: string;
  tornado?: string;
  hoverMessage?: string;
  aboveHeaderText?: string;
  class?: any;
  range?: any;
  nodePath?: any;
  nodeName?: any;
  showDenials?: boolean;
  showCPTsDenied?: boolean;
  showAdditionalCFPColumns?: boolean;
  showTerminalDenialRateColumn?: boolean;
  classNames?: string;
  isTotal?: boolean;
  sticky?: boolean;
}

export interface ExcelTableColumn extends DataTableColumns {
  isLastInGroup: boolean;
}
