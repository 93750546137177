import {IAppState} from '../store/IAppState';
import {communityBenchmarkAlertChangedTo} from '../store/actions';
import {NgRedux} from '@angular-redux/store';


export function updateCommunityBenchmarkAlertStatus(
  hasCommunityBenchmarks: boolean,
  hasAcademicBenchmarks: boolean,
  ngRedux: NgRedux<IAppState>,
  showProgressBar?: boolean
  ) {
  if (!hasCommunityBenchmarks && hasAcademicBenchmarks && !ngRedux.getState().display.communityBenchmarkAlert) {
    ngRedux.dispatch(communityBenchmarkAlertChangedTo(!showProgressBar));
  }

  if (hasCommunityBenchmarks && ngRedux.getState().display.communityBenchmarkAlert) {
    ngRedux.dispatch(communityBenchmarkAlertChangedTo(false));
  }
}

