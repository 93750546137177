import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {
  AppAction,
  multilevelTabChangedTo,
  npvLocationDepartmentColumnsChangedTo,
  npvLocationProviderColumnsChangedTo,
  npvLocationSpecialtyColumnsChangedTo,
  npvLocationViewByNodeChangedTo
} from '../../../store/actions';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {
  BaseColumn,
  IAppState,
  INITIAL_STATE,
  NewPatientVisitsLocationVariables,
  SummaryData
} from '../../../store/IAppState';
import {getPreviousYearDateRange, getSingularOntologyLevelName, Page, pluralizeText} from '../../../shared/helpers';
import {BenchmarkOption, ColumnType, MultilevelTab, SortingOrder} from '../../../shared/enums';
import {TabNavigationElement} from '../../../tab-navigation/tab-helper';
import * as _ from 'lodash';
import {LOCATION_DEPARTMENT_TAB, LOCATION_PROVIDER_TAB, LOCATION_SPECIALTY_TAB} from '../../../shared/constants';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedNpvLocationAggregatedByNode,
  MergedProviderNewPatientVisitMultiLevelData,
  NpvByLocationForChart,
  NpvLocationAggregationAndZeroSuppressionCount,
  npvLocationDepartmentColumns,
  npvLocationProviderColumns,
  npvLocationSpecialtyColumns,
  NpvLocationWithSnapshotEntries
} from '../npv-models';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../../shared/benchmark-types';
import {DateRange, NewPatientVisitSummary} from '../../../shared/models';
import {aDefaultNewPatientVisitsLocationVariables} from '../npv-models.spec';
import {
  convertNpvBenchmarkDependentColumnToSelectedBenchmark,
  getDesignatedNpvBenchmarkObject,
  getLevelSpecificNpvLocationTableData,
  sortSnapshotTableEntries
} from '../npv-helpers';
import {getValueFromObjectWithStringIndexing} from '../../../shared/object-helpers';
import {distinctUntilChanged} from 'rxjs/operators';
import {ColumnWithMatSort} from './location-npv-table/npv-location-table-header/npv-location-table-header.component';
import {hasValue} from '../../../shared/null-helpers';

@Component({
  selector: 'app-page-new-patient-visits-location',
  templateUrl: './page-new-patient-visits-location.component.html',
  styleUrls: ['./page-new-patient-visits-location.component.scss']
})
export class PageNewPatientVisitsLocationComponent implements OnChanges, OnInit, OnDestroy {

  levelTabs: TabNavigationElement[] = [
    _.cloneDeep(LOCATION_DEPARTMENT_TAB),
    _.cloneDeep(LOCATION_SPECIALTY_TAB),
    _.cloneDeep(LOCATION_PROVIDER_TAB),
  ];
  readonly multilevelTabChangedTo = multilevelTabChangedTo;
  reducerAction: (columns: BaseColumn[]) => AppAction;
  variables: NewPatientVisitsLocationVariables = aDefaultNewPatientVisitsLocationVariables;
  ontologyText = '';
  originalColumns: DataTableColumns[] = [];
  displayedColumns: DataTableColumns[] = [];
  levelSpecificChartData: NpvByLocationForChart[] = [];
  inverseChartData: NpvByLocationForChart[] = [];
  levelSpecificTableData: NpvLocationWithSnapshotEntries[] = [];
  mergedNpvByLocationByMultilevelData: MergedNpvLocationAggregatedByNode[];
  newPatientVisitMultilevelData: MergedProviderNewPatientVisitMultiLevelData;
  countOfSuppressedEntries: number;
  snapshotEntryName: string;
  showPatientVisitCountsOnGraph = false;
  summaryData: SummaryData<NewPatientVisitSummary>;
  tableTitle = '';
  showLocationLoader = true;
  showSnapshotLoader = true;
  referToSnapshotLoader = false;
  locationColumnIsSorted = false;
  currentPage: Page;

  @select(['display', 'newPatientVisitsLocationVariables'])
  private readonly newPatientVisitsLocationVariables$: Observable<NewPatientVisitsLocationVariables>;
  @select(['data', 'summaryNewPatientVisitData'])
  private readonly summaryNewPatientVisitData$: Observable<SummaryData<NewPatientVisitSummary>>;
  @select(['display', 'patientVisitCounts'])
  private readonly patientVisitCounts$: Observable<boolean>;
  @select(['data', 'mergedNpvLocationAggregatedByNode'])
  private readonly mergedNpvLocationAggregatedByNode$: Observable<MergedNpvLocationAggregatedByNode[]>;
  @select(['data', 'newPatientVisitMultilevelData'])
  private readonly newPatientVisitMultilevelData$: Observable<MergedProviderNewPatientVisitMultiLevelData>;
  @select(['filters', 'dateRange'])
  private readonly dateRange$: Observable<DateRange>;
  @Input() selectedLevelTab: MultilevelTab = MultilevelTab.LOCATION_DEPARTMENT;
  @Input() benchmarkPercentile: BenchmarkPercentile = BenchmarkPercentile.Mean;
  @Input() benchmarkOption: BenchmarkOption = BenchmarkOption.Academic;
  @Input() isZeroSuppressionChecked = false;

  private subscription: Subscription;
  previousDateText = '';

  private static addLocationChartDataEntry(chartData: NpvByLocationForChart[], entry: MergedNpvLocationAggregatedByNode,
                         matchingNode: MergedNewPatientVisitSnapshotEntry, viewByNode: boolean, tabName: string): void {
    chartData.push({
      countOfNewPatientVisits: matchingNode.countOfNewPatientVisits,
      countOfTotalPatientVisits: matchingNode.countOfTotalPatientVisits,
      newPatientVisitsPercentage: matchingNode.newPatientVisitsPercentage,
      countOfExistingPatientVisits: matchingNode.countOfExistingPatientVisits,
      name: entry.memberLocationName,
      identity: `${entry.memberLocationKey}`,
      benchmarkValue: -1,
      drillDown: viewByNode ? matchingNode[`${tabName.toLowerCase()}NodePath`] : undefined
    });
  }

  private static generateLocationChartEntries(mergedNpvByLocationByMultilevelData: MergedNpvLocationAggregatedByNode[],
                                              viewByNode: boolean, tabName: string):
    NpvByLocationForChart[] {
    return mergedNpvByLocationByMultilevelData.map(entry => {
      return {
        countOfNewPatientVisits: entry.countOfNewPatientVisits,
        countOfTotalPatientVisits: entry.countOfTotalPatientVisits,
        newPatientVisitsPercentage: entry.newPatientVisitsPercentage,
        countOfExistingPatientVisits: entry.countOfExistingPatientVisits,
        name: entry.memberLocationName,
        identity: `${entry.memberLocationKey}`,
        benchmarkValue: -1,
        drillDown: viewByNode ? getValueFromObjectWithStringIndexing(entry, `${tabName.toLowerCase()}NodePath`) : undefined
      };
    });
  }

  static generateSnapshotChartEntries(snapshotEntries: MergedNewPatientVisitSnapshotEntry[],
                                              tabName: string, viewByNode: boolean,
                                              benchmarkOption: BenchmarkOption,
                                              benchmarkPercentile: BenchmarkPercentile): NpvByLocationForChart[] {
    return snapshotEntries.map(entry => {
      return {
        countOfNewPatientVisits: entry.countOfNewPatientVisits,
        countOfTotalPatientVisits: entry.countOfTotalPatientVisits,
        newPatientVisitsPercentage: entry.newPatientVisitsPercentage,
        countOfExistingPatientVisits: entry.countOfExistingPatientVisits,
        name: entry[`${tabName.toLowerCase()}NodeName`],
        identity: `${entry[`${tabName.toLowerCase()}NodePath`]}`,
        benchmarkValue: 100 * entry[getDesignatedNpvBenchmarkObject(benchmarkOption)][`benchmark${readableNameOfColumnDef(benchmarkPercentile)}`],
        drillDown: viewByNode ? getValueFromObjectWithStringIndexing(entry, `${tabName.toLowerCase()}NodePath`) : undefined
      };
    });
  }

  private static generateFilteredSnapshotChartEntries(designatedSnapshotDimension: MergedNpvLocationAggregatedByNode[],
                                                      isZeroSuppressionChecked: boolean,
                                                      tabName: string,
                                                      benchmarkOption: BenchmarkOption,
                                                      benchmarkPercentile: BenchmarkPercentile,
                                                      viewByNode: boolean):
    NpvByLocationForChart[] {
    const snapshotChartData: NpvByLocationForChart[] = [];
    designatedSnapshotDimension.forEach(dimension => {
      if (!isZeroSuppressionChecked || dimension.countOfTotalPatientVisits > 0) {
        snapshotChartData.push({
          countOfNewPatientVisits: dimension.countOfNewPatientVisits,
          countOfTotalPatientVisits: dimension.countOfTotalPatientVisits,
          newPatientVisitsPercentage: dimension.newPatientVisitsPercentage,
          countOfExistingPatientVisits: dimension.countOfExistingPatientVisits,
          name: getValueFromObjectWithStringIndexing(dimension, `${tabName.toLowerCase()}NodeName`),
          identity: getValueFromObjectWithStringIndexing(dimension, `${tabName.toLowerCase()}NodePath`),
          benchmarkValue: 100 * getValueFromObjectWithStringIndexing(dimension, getDesignatedNpvBenchmarkObject(
            benchmarkOption), `benchmark${readableNameOfColumnDef(benchmarkPercentile)}`),
          drillDown: viewByNode ? getValueFromObjectWithStringIndexing(dimension,
            `${tabName.toLowerCase()}NodePath`) : undefined
        });
      }
    });
    return snapshotChartData;
  }

  constructor(private ngRedux: NgRedux<IAppState>) { }

  ngOnInit(): void {
    this.subscription = combineLatest([this.newPatientVisitsLocationVariables$.pipe(distinctUntilChanged(
      (a, b) => _.isEqual(a, b))),
      this.mergedNpvLocationAggregatedByNode$.pipe(distinctUntilChanged(
        (a, b) => _.isEqual(a, b))),
      this.newPatientVisitMultilevelData$,
      this.summaryNewPatientVisitData$,
      this.patientVisitCounts$, this.dateRange$]).subscribe(([variables,
       mergedNpvByLocationByMultilevelData, newPatientVisitMultilevelData, summaryNewPatientVisitData,
       showPatientVisitCountsOnGraph, dateRange]: [NewPatientVisitsLocationVariables, MergedNpvLocationAggregatedByNode[],
      MergedProviderNewPatientVisitMultiLevelData, SummaryData<NewPatientVisitSummary>, boolean, DateRange]) => {
      this.variables = variables;
      this.mergedNpvByLocationByMultilevelData = mergedNpvByLocationByMultilevelData;
      this.newPatientVisitMultilevelData = newPatientVisitMultilevelData;
      this.summaryData = summaryNewPatientVisitData;
      this.showPatientVisitCountsOnGraph = showPatientVisitCountsOnGraph;
      this.showLocationLoader = _.isEqual(INITIAL_STATE.data.mergedNpvLocationAggregatedByNode,
        mergedNpvByLocationByMultilevelData);
      this.showSnapshotLoader = _.isEqual(INITIAL_STATE.data.newPatientVisitMultilevelData,
        newPatientVisitMultilevelData);
      this.referToSnapshotLoader = !variables.currentSelectedLocationNode && variables.viewByNode;
      this.ensureDataSourcesAreProperlySorted(variables);
      this.ngOnChanges();
      this.previousDateText = getPreviousYearDateRange(dateRange);
    });
  }

  ngOnChanges(): void {
    this.ontologyText = getSingularOntologyLevelName(this.selectedLevelTab);
    this.setChildComponentInputValues(this.mergedNpvByLocationByMultilevelData, this.newPatientVisitMultilevelData,
      this.selectedLevelTab, this.variables);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private getTieBreaker = (item: MergedNewPatientVisitSnapshotEntry) => {
    const ontologyName = `${getSingularOntologyLevelName(this.selectedLevelTab).toLowerCase()}NodeName`;
    return getValueFromObjectWithStringIndexing(item, ontologyName);
  };

  private ensureDataSourcesAreProperlySorted(variables: NewPatientVisitsLocationVariables): void {
    const sortDirection = variables.columnForSortingTheData.sort || '';
    const sortAscending = sortDirection === 'asc';
    if (variables.columnForSortingTheData.column.columnType === ColumnType.LOCATION) {
      this.mergedNpvByLocationByMultilevelData = this.mergedNpvByLocationByMultilevelData.sort((a, b) => {
        return sortAscending ? a.memberLocationName.localeCompare(b.memberLocationName) :
          b.memberLocationName.localeCompare(a.memberLocationName);
      });
      return;
    }
    this.sortTheDataSources(variables.columnForSortingTheData, sortAscending);
  }

  private sortTheDataSources(columnWithMatSort: ColumnWithMatSort, sortAscending: boolean): void {
    this.mergedNpvByLocationByMultilevelData.forEach(x => {
      x.departmentNpvSnapshotData = sortSnapshotTableEntries(x.departmentNpvSnapshotData, columnWithMatSort.column, this.getTieBreaker,
        sortAscending);
      x.specialtyNpvSnapshotData = sortSnapshotTableEntries(x.specialtyNpvSnapshotData, columnWithMatSort.column, this.getTieBreaker,
        sortAscending);
      x.providerNpvSnapshotData = sortSnapshotTableEntries(x.providerNpvSnapshotData, columnWithMatSort.column, this.getTieBreaker,
        sortAscending);
    });
  }

  private setChildComponentInputValues(mergedNpvByLocationByMultilevelData: MergedNpvLocationAggregatedByNode[],
                                       newPatientVisitMultilevelData: MergedProviderNewPatientVisitMultiLevelData,
                                       tab: MultilevelTab, variables: NewPatientVisitsLocationVariables): void {
    switch (tab) {
      case MultilevelTab.LOCATION_DEPARTMENT:
        this.setChildComponentLevelSpecificInputValues(mergedNpvByLocationByMultilevelData,
          newPatientVisitMultilevelData?.departmentNpvSnapshotData.slice() || [], tab, variables);
        this.originalColumns = npvLocationDepartmentColumns();
        this.updateBenchmarkDependentColumns();
        this.displayedColumns = this.updateColumnFilter(variables.displayedDepartmentColumns, this.originalColumns);
        this.reducerAction = npvLocationDepartmentColumnsChangedTo;
        this.currentPage = Page.NPVLocationDepartment;
        break;
      case MultilevelTab.LOCATION_SPECIALTY:
        this.setChildComponentLevelSpecificInputValues(mergedNpvByLocationByMultilevelData,
          newPatientVisitMultilevelData?.specialtyNpvSnapshotData.slice() || [], tab, variables);
        this.originalColumns = npvLocationSpecialtyColumns();
        this.updateBenchmarkDependentColumns();
        this.displayedColumns = this.updateColumnFilter(variables.displayedSpecialtyColumns, this.originalColumns);
        this.reducerAction = npvLocationSpecialtyColumnsChangedTo;
        this.currentPage = Page.NPVLocationSpecialty;
        break;
      case MultilevelTab.LOCATION_PROVIDER:
        this.setChildComponentLevelSpecificInputValues(mergedNpvByLocationByMultilevelData,
          newPatientVisitMultilevelData?.providerNpvSnapshotData.slice() || [], tab, variables);
        this.originalColumns = npvLocationProviderColumns();
        this.updateBenchmarkDependentColumns();
        this.displayedColumns = this.updateColumnFilter(variables.displayedProviderColumns, this.originalColumns);
        this.reducerAction = npvLocationProviderColumnsChangedTo;
        this.currentPage = Page.NPVLocationProvider;
    }
  }

  private setChildComponentLevelSpecificInputValues(
    mergedNpvByLocationByMultilevelData: MergedNpvLocationAggregatedByNode[], snapshotEntries:
      MergedNewPatientVisitSnapshotEntry[], tab: MultilevelTab, variables: NewPatientVisitsLocationVariables) {
    this.sortTheGraphSources(mergedNpvByLocationByMultilevelData, snapshotEntries, variables);
    const tabName: string = getSingularOntologyLevelName(tab);
    this.tableTitle = `NPV by Location by ${tabName}`;
    this.snapshotEntryName = pluralizeText(tabName);
    let unfilteredLocationChartData: NpvByLocationForChart[] = [];
    let locationChartData: NpvByLocationForChart[] = [];
    if (variables.currentSelectedOntologyNode) {
      locationChartData = [];
      mergedNpvByLocationByMultilevelData.forEach(entry => {
        const designatedNodeList: MergedNewPatientVisitSnapshotEntry[] = getValueFromObjectWithStringIndexing(
          entry, `${tabName.toLowerCase()}NpvSnapshotData`);
        const matchingNode: MergedNewPatientVisitSnapshotEntry | undefined = designatedNodeList.find(node =>
          (!this.isZeroSuppressionChecked || node.countOfTotalPatientVisits > 0) &&
          node[`${tabName.toLowerCase()}NodePath`].localeCompare(variables.currentSelectedOntologyNode?.identity || '') === 0);
        if (matchingNode) {
          PageNewPatientVisitsLocationComponent.addLocationChartDataEntry(locationChartData, entry, matchingNode,
            variables.viewByNode, tabName);
        }
        designatedNodeList.forEach(unfilteredMatchingNode => {
          PageNewPatientVisitsLocationComponent.addLocationChartDataEntry(unfilteredLocationChartData, entry,
            unfilteredMatchingNode, variables.viewByNode, tabName);
        });
      });
    } else {
      locationChartData = PageNewPatientVisitsLocationComponent
        .generateLocationChartEntries(mergedNpvByLocationByMultilevelData || [], variables.viewByNode, tabName);
      unfilteredLocationChartData = locationChartData.slice();
    }
    const unfilteredSnapshotChartData: NpvByLocationForChart[] = PageNewPatientVisitsLocationComponent
      .generateSnapshotChartEntries(snapshotEntries || [], tabName, variables.viewByNode, this.benchmarkOption, this.benchmarkPercentile);
    let snapshotChartData: NpvByLocationForChart[];
    if (variables.currentSelectedLocationNode) {
      const matchingEntry = mergedNpvByLocationByMultilevelData.find(entry => entry.memberLocationKey ===
        +(variables.currentSelectedLocationNode?.identity || 0));
      const designatedSnapshotDimension: MergedNpvLocationAggregatedByNode[] = matchingEntry ?
        getValueFromObjectWithStringIndexing(matchingEntry, `${tabName.toLowerCase()}NpvSnapshotData`) : [];
      snapshotChartData = PageNewPatientVisitsLocationComponent.generateFilteredSnapshotChartEntries(designatedSnapshotDimension,
        this.isZeroSuppressionChecked, tabName, this.benchmarkOption, this.benchmarkPercentile, variables.viewByNode);
    } else {
      snapshotChartData = unfilteredSnapshotChartData.filter(x => !this.isZeroSuppressionChecked || x.countOfTotalPatientVisits > 0);
    }
    if (variables.viewByNode) {
      this.levelSpecificChartData = snapshotChartData;
      this.inverseChartData = unfilteredLocationChartData;
    } else {
      this.levelSpecificChartData = locationChartData;
      this.inverseChartData = unfilteredSnapshotChartData;
    }
    const aggregationAndZeroSuppressionCount: NpvLocationAggregationAndZeroSuppressionCount =
      getLevelSpecificNpvLocationTableData(true, tab, this.isZeroSuppressionChecked,
        mergedNpvByLocationByMultilevelData);
    this.levelSpecificTableData = aggregationAndZeroSuppressionCount.entries;
    this.countOfSuppressedEntries = aggregationAndZeroSuppressionCount.countOfZeroSuppressedEntries;
  }

  private sortTheGraphSources(mergedNpvByLocationByMultilevelData: MergedNpvLocationAggregatedByNode[],
                              snapshotEntries: MergedNewPatientVisitSnapshotEntry[],
                              variables: NewPatientVisitsLocationVariables): void {
    let sortAscending: boolean | undefined = variables.columnForSortingTheData.sort === SortingOrder.ASCENDING;
    switch (variables.columnForSortingTheData.sort) {
      case SortingOrder.ASCENDING:
        sortAscending = true;
        break;
      case SortingOrder.DESCENDING:
        sortAscending = false;
        break;
      default:
        sortAscending = undefined;
    }
    if (variables.columnForSortingTheData.column.columnType === ColumnType.LOCATION) {
      mergedNpvByLocationByMultilevelData?.sort(
        (a: MergedNpvLocationAggregatedByNode, b: MergedNpvLocationAggregatedByNode) => {
          return sortAscending ? a.memberLocationName.localeCompare(b.memberLocationName) :
            b.memberLocationName.localeCompare(a.memberLocationName);
        });
      this.locationColumnIsSorted = hasValue(sortAscending);
    } else if (!this.locationColumnIsSorted) {
      mergedNpvByLocationByMultilevelData?.sort((a: MergedNpvLocationAggregatedByNode, b: MergedNpvLocationAggregatedByNode) => {
        return b.newPatientVisitsPercentage - a.newPatientVisitsPercentage;
      });
    } else {
      this.locationColumnIsSorted = false;
    }
    if (!variables.currentSelectedLocationNode) {
      sortSnapshotTableEntries(snapshotEntries, variables.columnForSortingTheData.column, this.getTieBreaker, !!sortAscending);
    } else {
      mergedNpvByLocationByMultilevelData.forEach(locationEntry => {
        locationEntry.departmentNpvSnapshotData = sortSnapshotTableEntries(locationEntry.departmentNpvSnapshotData,
          variables.columnForSortingTheData.column, this.getTieBreaker, !!sortAscending);
        locationEntry.specialtyNpvSnapshotData = sortSnapshotTableEntries(locationEntry.specialtyNpvSnapshotData,
          variables.columnForSortingTheData.column, this.getTieBreaker, !!sortAscending);
        locationEntry.providerNpvSnapshotData = sortSnapshotTableEntries(locationEntry.providerNpvSnapshotData,
          variables.columnForSortingTheData.column, this.getTieBreaker, !!sortAscending);
      });
    }
  }

  private updateBenchmarkDependentColumns() {
    for (let x = 0; x < this.originalColumns.length; x++) {
      switch (this.originalColumns[x].columnType) {
        case ColumnType.BENCHMARK:
          convertNpvBenchmarkDependentColumnToSelectedBenchmark(
            this.originalColumns[x], this.benchmarkPercentile, this.benchmarkOption, false);
          break;
        case ColumnType.VARIANCE:
          convertNpvBenchmarkDependentColumnToSelectedBenchmark(
            this.originalColumns[x], this.benchmarkPercentile, this.benchmarkOption, true);
      }
    }
  }

  toggleViewByNode(viewBy: boolean): void {
    this.ngRedux.dispatch(npvLocationViewByNodeChangedTo(viewBy));
  }

  // TODO: Can this be a shared function?
  private updateColumnFilter(displayedColumns: BaseColumn[], columns: DataTableColumns[]): DataTableColumns[] {
    const displayedColumnDefs: string[] = displayedColumns.map(col => {
      switch (col.columnType) {
        case ColumnType.BENCHMARK:
          const benchmarkColumn = columns.find(c => c.columnType === ColumnType.BENCHMARK);
          return benchmarkColumn?.columnDef || col.columnDef;
        case ColumnType.VARIANCE:
          const varianceColumn = columns.find(c => c.columnType === ColumnType.VARIANCE);
          return varianceColumn?.columnDef || col.columnDef;
        default:
          return col.columnDef;
      }
    });
    return columns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef)).slice();
  }
}
