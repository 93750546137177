<div class="data-table-container-wrapper">
  <div class="data-table-container">
    <div class="summaryHeader bold">New Patient Visits Summary Metrics</div>
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="metric">
        <mat-header-cell *matHeaderCellDef class="headerMetric bold">Metric</mat-header-cell>
        <mat-cell *matCellDef="let row" class="metric">{{row.metric}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricSelectedDateRange">
        <mat-header-cell *matHeaderCellDef class="headerSelectedDateRange">
          <span><b>Selected Date Range</b></span>
          <span class="date">{{dateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="selectedDateRange">{{row.metricSelectedDateRange}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricPreviousYearSelectedDateRange">
        <mat-header-cell *matHeaderCellDef class="headerPreviousYearSelectedDateRange">
          <span><b>Previous Year<br> Selected Date Range</b></span>
          <span class="date">{{previousDateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="previousYearSelectedDateRange">{{row.metricPreviousYearSelectedDateRange}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricRecentMonth">
        <mat-header-cell *matHeaderCellDef class="headerRecentMonth">
          <span><b>Recent Month</b></span>
          <span class="date">{{recentMonthDate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="recentMonth">{{row.metricRecentMonth}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricPreviousYearRecentMonth">
        <mat-header-cell *matHeaderCellDef class="headerPreviousYearRecentMonth">
          <span><b>Previous Year<br> Recent Month</b></span>
          <span class="date">{{previousYearRecentMonthDate}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="previousYearRecentMonth">{{row.metricPreviousYearRecentMonth}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricYearToDate">
        <mat-header-cell *matHeaderCellDef class="headerYearToDate">
          <span><b>YTD</b></span>
          <span class="date">{{ytdDateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="yearToDate">{{row.metricYearToDate}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricPreviousYearToDate">
        <mat-header-cell *matHeaderCellDef class="headerPreviousYearToDate">
          <span><b>Previous Year YTD</b></span>
          <span class="date">{{previousYtdDateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="previousYearToDate">{{row.metricPreviousYearToDate}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <app-loader  [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
</div>
