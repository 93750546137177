import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ClinicalSummaryFamily} from '../../../ClinicalSummary';
import {isItemAbleToShow, ScenarioForDisplayability} from '../../../../filter-banner/search-payer/PayerSetupHelper';
import {RelatableSelectableItem} from '../../../../filter-banner/filter-banner-helper';
import {MIN_SEARCH_CHARS} from '../../../../shared/constants';

@Component({
  selector: 'app-search-cpt-family',
  templateUrl: './search-cpt-family.component.html',
  styleUrls: ['./search-cpt-family.component.scss']
})
export class SearchCptFamilyComponent implements OnChanges, OnInit {

  @Input() cptFamilies: RelatableSelectableItem<ClinicalSummaryFamily>[] = [];
  @Input() whenToggled: (open: boolean) => void;
  @Input() rangesAlsoShown = false;
  @Output() afterCptFamiliesUpdated = new EventEmitter<number>();
  readonly DEFAULT_FAMILIES = 'All Families';
  readonly minSearchChars = MIN_SEARCH_CHARS;
  toggleLabel = this.DEFAULT_FAMILIES;
  additionalLabel = '';
  additionalSelected = false;
  toggleInfo: BehaviorSubject<boolean>;
  searchText = '';
  showCptFamily = false;
  displayedSelectedFamilies: RelatableSelectableItem<ClinicalSummaryFamily>[] = [];
  displayedUnselectedFamilies: RelatableSelectableItem<ClinicalSummaryFamily>[] = [];
  selectedFamilies: RelatableSelectableItem<ClinicalSummaryFamily>[] = [];
  unSelectedFamilies: RelatableSelectableItem<ClinicalSummaryFamily>[] = [];

  constructor() {
    this.toggleInfo = new BehaviorSubject<boolean>(false);
    this.getToggleInfo().subscribe((showCptFamily: boolean) => {
      this.showCptFamily = showCptFamily;
      if (showCptFamily) {
        this.filterDisplayedCptFamilies();
      }
      if (this.whenToggled) {
        this.whenToggled(showCptFamily);
      }
    });
  }

  ngOnInit(): void {
    this.filterFamilies();
    this.setToggleLabel();
  }

  ngOnChanges() {
    if (this.rangesAlsoShown) {
      this.toggleInfo.next(false);
    }
  }
  private filterFamilies(): void {
    this.selectedFamilies = this.cptFamilies.filter(d => d.item.selected);
    this.unSelectedFamilies = this.cptFamilies.filter(d => !d.item.selected);
  }

  private filterDisplayedCptFamilies(): void {
    this.displayedSelectedFamilies = this.selectedFamilies.slice();
    this.displayedUnselectedFamilies = this.unSelectedFamilies.slice();
    this.displayedSelectedFamilies = this.displayedSelectedFamilies.filter(d =>
      isItemAbleToShow(d, ScenarioForDisplayability.Standard));
    this.displayedUnselectedFamilies = this.displayedUnselectedFamilies.filter(d =>
      isItemAbleToShow(d, ScenarioForDisplayability.Standard));
  }

  getToggleInfo(): Observable<boolean> {
    return this.toggleInfo.asObservable();
  }

  toggleCptFamilies(): void {
    if (!this.toggleInfo.value) {
      this.displayedSelectedFamilies = this.selectedFamilies.slice();
      this.displayedUnselectedFamilies = this.unSelectedFamilies.slice();
    }
    this.toggleInfo.next(!this.toggleInfo.value);
  }

  onSearchTextChanged(): void {
    this.cptFamilies.forEach(family => {
      family.item.matchesSearchText = this.searchText.length < this.minSearchChars ||
        !!family.item.item.cptFamilyDesc?.toLowerCase().includes(this.searchText.toLowerCase());
    });
    this.filterDisplayedCptFamilies();
  }

  selectAllCptFamilies(event: MouseEvent): void {
    event.preventDefault();
    if (this.displayedSelectedFamilies.length && !this.displayedUnselectedFamilies.length) {
      this.displayedSelectedFamilies.forEach(family => {
        family.item.selected = false;
      });
      this.filterFamilies();
      this.filterDisplayedCptFamilies();
    } else {
      this.displayedUnselectedFamilies.forEach(family => {
        family.item.selected = true;
      });
      this.filterFamilies();
      this.filterDisplayedCptFamilies();
    }
  }

  whenCptFamilyClicked(family: RelatableSelectableItem<ClinicalSummaryFamily>, event: MouseEvent): void {
    event.preventDefault();
    family.item.selected = !family.item.selected;
    this.filterFamilies();
    this.filterDisplayedCptFamilies();
  }

  private setToggleLabel(): void {
    if (this.selectedFamilies.length === 0 || this.unSelectedFamilies.length === 0) {
      this.toggleLabel = this.DEFAULT_FAMILIES;
      this.additionalSelected = false;
    } else {
      this.toggleLabel = this.selectedFamilies[0].item.item.cptFamilyDesc || this.toggleLabel;
      this.additionalLabel = '{+ ' + (this.selectedFamilies.length - 1) + ' More}';
      this.additionalSelected = this.selectedFamilies.length >= 2 && !!this.unSelectedFamilies.length;
    }
  }

  cancel(): void {
    this.cptFamilies.forEach(x => {
      x.item.selected = !!x.item.originallySelected;
    });
    this.filterFamilies();
    this.toggleInfo.next(false);
    this.searchText = '';
  }

  apply(): void {
    this.cptFamilies.forEach(x => {
      x.item.originallySelected = x.item.selected;
    });
    this.toggleInfo.next(false);
    this.filterFamilies();
    this.afterCptFamiliesUpdated.emit(this.selectedFamilies.length);
    this.setToggleLabel();
  }
}
