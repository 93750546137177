<div class="zip-code-tile-wrapper">
  <div class=tile>
    <div class="zip-table-wrapper">
      <span class=title>Top New Patient Visit Zip Codes {{level}}</span>
      <app-data-table id="zipcode-table"
                      [receivedData]="gmapMarkerData"
                      [displayedColumns]="zipCodeColumns"
                      [paginate]="false"
                      [showLevel]="false"
                      [tableCssClass]="'zip-data-table-container'"
                      [fromPdfExport] = "fromPdfExport"
      ></app-data-table>
    </div>
    <div class="zip-map-wrapper">
      <app-zipcode-map *ngIf="!canShowBubbleIndicators"
        [mapData]="gmapMarkerData"
        [fromPdf]="fromPdfExport"
      ></app-zipcode-map>
      <app-zip-code-bubble-map *ngIf="canShowBubbleIndicators"
        [mapData]="gmapMarkerData"
        [fromPdf]="fromPdfExport"
      ></app-zip-code-bubble-map>
    </div>
    <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
</div>
