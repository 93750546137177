import {Component, Input, OnChanges} from '@angular/core';
import {
  CptCategories,
  CptSummary,
  EmDimension,
  EvaluationManagementMultilevel,
  Legend,
  ProviderMultilevelCptCounts
} from '../../../../shared/models';
import {
  EvaluationManagementEyeExamMultilevelRow,
  getDefaultCptSummary,
  getEmSnapshotColumnsWithExtendedBenchmarkOptions,
  getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions,
} from '../../../../evaluation-management/em-helpers';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {BenchmarkOption, MultilevelTab} from '../../../../shared/enums';
import {DataTableColumns} from '../../../../shared/data-table-columns';
import {MetricType} from '../../../../shared/metric-types';
import {getEmLegends} from '../../../../evaluation-management/em-chart-helpers';

@Component({
  selector: 'app-em-eye-exam-batch-export',
  templateUrl: './em-eye-exam-batch-export.component.html',
  styleUrls: ['./em-eye-exam-batch-export.component.scss']
})
export class EmEyeExamBatchExportComponent implements OnChanges {

  @Input() totalCptCounts?: CptCategories;
  @Input() specialtyCptCounts?: CptCategories;
  @Input() showProgressBar: boolean;
  @Input() chartHeight: number;
  @Input() overviewPageView: boolean;
  @Input() chartWidth: number;
  @Input() isSingleProviderSelected: boolean;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() receivedTableData: EvaluationManagementEyeExamMultilevelRow[];
  @Input() fromPdf: boolean;
  @Input() showTable = false;
  @Input() batchMultilevelEMData: EvaluationManagementMultilevel | undefined = undefined;
  columns: DataTableColumns[] = [];
  tableData: ProviderMultilevelCptCounts[] = [];
  MultilevelTab = MultilevelTab;

  @select(['display', 'isProviderSelected'])
  private readonly isProviderSelected$: Observable<boolean>;

  readonly metricType = MetricType.EandMOpthalmology;

  newPatientCategories = ['92002', '92004'];
  establishedPatientCategories = ['92012', '92014'];
  totalNewPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  totalEstablishedPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  specialtyTotalNewPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  specialtyTotalEstablishedPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  newPatientEyeExamCptMaxValue = 0;
  establishedPatientEyeExamCptMaxValue = 0;
  specialtyNewPatientEyeExamCptMaxValue = 0;
  specialtyEstablishedPatientEyeExamCptMaxValue = 0;
  maxNewPatientEyeExamYAxisValue: number;
  maxEstablishedPatientEyeExamYAxisValue: number;
  legends: Legend[];

  constructor() {
  }

  ngOnChanges(): void {
    this.legends = getEmLegends(this.foundNodeName, this.isSingleProviderSelected);
    this.setUpTable();
    this.chartHeight = this.overviewPageView ? 250 : this.chartHeight;

    if (!this.totalCptCounts) {
      this.totalNewPatientEyeExamCptSummaries = [];
      this.totalEstablishedPatientEyeExamCptSummaries = [];

      this.newPatientCategories.forEach(x => {
        this.totalNewPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.establishedPatientCategories.forEach(x => {
        this.totalEstablishedPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.newPatientEyeExamCptMaxValue = 0;
      this.establishedPatientEyeExamCptMaxValue = 0;
      return;
    }


    this.specialtyTotalNewPatientEyeExamCptSummaries = [];
    this.totalEstablishedPatientEyeExamCptSummaries = [];
    this.specialtyTotalEstablishedPatientEyeExamCptSummaries = [];

    if (!this.specialtyCptCounts) {
      this.specialtyTotalNewPatientEyeExamCptSummaries = [];
      this.totalEstablishedPatientEyeExamCptSummaries = [];

      this.newPatientCategories.forEach(x => {
        this.specialtyTotalNewPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.establishedPatientCategories.forEach(x => {
        this.specialtyTotalEstablishedPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.specialtyNewPatientEyeExamCptMaxValue = 0;
      this.specialtyEstablishedPatientEyeExamCptMaxValue = 0;
    }

    if (this.totalCptCounts.eyeExamNewPatient) {
      this.totalNewPatientEyeExamCptSummaries = [
        this.totalCptCounts.eyeExamNewPatient.cpt92002,
        this.totalCptCounts.eyeExamNewPatient.cpt92004,
      ];
      this.newPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalNewPatientEyeExamCptSummaries, this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.eyeExamNewPatient) {
      this.specialtyTotalNewPatientEyeExamCptSummaries = [
        this.specialtyCptCounts.eyeExamNewPatient.cpt92002,
        this.specialtyCptCounts.eyeExamNewPatient.cpt92004,
      ];
      this.specialtyNewPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalNewPatientEyeExamCptSummaries, this.benchmarkOption);
    }

    if (this.totalCptCounts.eyeExamEstablishedPatient) {
      this.totalEstablishedPatientEyeExamCptSummaries = [
        this.totalCptCounts.eyeExamEstablishedPatient.cpt92012,
        this.totalCptCounts.eyeExamEstablishedPatient.cpt92014,
      ];
      this.establishedPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalEstablishedPatientEyeExamCptSummaries, this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.eyeExamEstablishedPatient) {
      this.specialtyTotalEstablishedPatientEyeExamCptSummaries = [
        this.specialtyCptCounts.eyeExamEstablishedPatient.cpt92012,
        this.specialtyCptCounts.eyeExamEstablishedPatient.cpt92014,
      ];
      this.specialtyEstablishedPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalEstablishedPatientEyeExamCptSummaries,
          this.benchmarkOption);
    }

    this.maxNewPatientEyeExamYAxisValue = Math.max(this.newPatientEyeExamCptMaxValue,
      this.specialtyNewPatientEyeExamCptMaxValue);
    this.maxEstablishedPatientEyeExamYAxisValue = Math.max(this.establishedPatientEyeExamCptMaxValue,
      this.specialtyEstablishedPatientEyeExamCptMaxValue);
  }

  private setUpTable(): void {
    this.columns =
      getEmSnapshotColumnsWithExtendedBenchmarkOptions(EmDimension.Ophthalmology, this.benchmarkOption, MultilevelTab.BY_PROVIDER);
    this.tableData = this.batchMultilevelEMData?.evaluationManagementData.providerEvaluationManagementSnapshotData
      .nodes || [];
  }
}
