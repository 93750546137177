import {BenchmarkOption} from './enums';

export enum BenchmarkPercentile {
  All = 0,
  Percentile25th = 1,
  Percentile50th = 2,
  Percentile75th = 3,
  Percentile90th = 4,
  Mean = 5,
  Percentile65th = 6,
}

export class BenchmarkHelper {
  public static toWrvusLabel(benchmarkPercentile: BenchmarkPercentile, viewCommunityBenchmark: boolean = false) {
    const benchmarkLabel = viewCommunityBenchmark ? 'Community' : 'Academic';
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Mean:
        return benchmarkLabel + ' wRVUs Mean Benchmark';
      case BenchmarkPercentile.Percentile25th:
        return benchmarkLabel + ' wRVUs 25th Percentile Benchmark';
      case BenchmarkPercentile.Percentile50th:
        return benchmarkLabel + ' wRVUs 50th Percentile Benchmark';
      case BenchmarkPercentile.Percentile65th:
        return benchmarkLabel + ' wRVUs 65th Percentile Benchmark';
      case BenchmarkPercentile.Percentile75th:
        return benchmarkLabel + ' wRVUs 75th Percentile Benchmark';
      case BenchmarkPercentile.Percentile90th:
        return benchmarkLabel + ' wRVUs 90th Percentile Benchmark';
      default:
        return benchmarkLabel + ' wRVUs Mean Benchmark';
    }
  }
}

export function readableNameOf(benchmark: BenchmarkPercentile, unit: string = ''): string {
  let name: string;
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      name = '25th';
      break;
    case BenchmarkPercentile.Percentile50th:
      name = '50th';
      break;
    case BenchmarkPercentile.Percentile75th:
      name = '75th';
      break;
    case BenchmarkPercentile.Percentile90th:
      name = '90th';
      break;
    case BenchmarkPercentile.Mean:
      return 'Mean';
    case BenchmarkPercentile.Percentile65th:
      name = '65th';
      break;
    default:
      name = '';
      break;
  }

  return name + unit;
}

export function readableNameOfColumnDefWithVisitTypeBenchmark(benchmark: BenchmarkPercentile, benchmarkOption: BenchmarkOption): string {
  let benchmarkOptionLabel;
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthCommunity:
      benchmarkOptionLabel = 'telehealthCommunityBenchmark';
      break;
    case BenchmarkOption.TelehealthAcademic:
      benchmarkOptionLabel = 'telehealthAcademic';
      break;
    case BenchmarkOption.InPersonCommunity:
      benchmarkOptionLabel = 'inPersonCommunity';
      break;
    case BenchmarkOption.InPersonAcademic:
      benchmarkOptionLabel = 'inPersonAcademic';
      break;
    case BenchmarkOption.Community:
      benchmarkOptionLabel = 'bothCommunity';
      break;
    case BenchmarkOption.Academic:
      benchmarkOptionLabel = 'bothAcademic';
  }
  return `${benchmarkOptionLabel}Benchmark${readableNameOf(benchmark)}`;
}

export function translateNumberToBenchmarkPercentile(benchmarkPercentileValue: number) {
  switch (benchmarkPercentileValue) {
    case BenchmarkPercentile.Mean.valueOf():
      return BenchmarkPercentile.Mean;
    case BenchmarkPercentile.Percentile25th.valueOf():
      return BenchmarkPercentile.Percentile25th;
    case BenchmarkPercentile.Percentile50th.valueOf():
      return BenchmarkPercentile.Percentile50th;
    case BenchmarkPercentile.Percentile65th.valueOf():
      return BenchmarkPercentile.Percentile65th;
    case BenchmarkPercentile.Percentile75th.valueOf():
      return BenchmarkPercentile.Percentile75th;
    case BenchmarkPercentile.Percentile90th.valueOf():
      return BenchmarkPercentile.Percentile90th;
    default:
      return BenchmarkPercentile.Mean;
  }
}

export function translateNumberToBenchmarkOption(benchmarkOptionValue: number) {
  switch (benchmarkOptionValue) {
    case BenchmarkOption.Academic.valueOf():
      return BenchmarkOption.Academic;
    case BenchmarkOption.Community.valueOf():
      return BenchmarkOption.Community;
    case BenchmarkOption.TelehealthAcademic.valueOf():
      return BenchmarkOption.TelehealthAcademic;
    case BenchmarkOption.TelehealthCommunity.valueOf():
      return BenchmarkOption.TelehealthCommunity;
    case BenchmarkOption.InPersonAcademic.valueOf():
      return BenchmarkOption.InPersonAcademic;
    case BenchmarkOption.InPersonCommunity.valueOf():
      return BenchmarkOption.InPersonCommunity;
    default:
      return BenchmarkOption.Academic;
  }
}

export function binaryBenchmarkEquivalentOf(benchmarkOption: BenchmarkOption): BenchmarkOption {
  switch (benchmarkOption) {
    case BenchmarkOption.Academic:
    case BenchmarkOption.InPersonAcademic:
    case BenchmarkOption.TelehealthAcademic:
      return BenchmarkOption.Academic;
    case BenchmarkOption.Community:
    case BenchmarkOption.TelehealthCommunity:
    case BenchmarkOption.InPersonCommunity:
      return BenchmarkOption.Community;
    default:
      return BenchmarkOption.Academic;
  }
}

export function readableNameOfColumnDef(benchmark: BenchmarkPercentile): string {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return '25thPercentile';
    case BenchmarkPercentile.Percentile50th:
      return '50thPercentile';
    case BenchmarkPercentile.Percentile75th:
      return '75thPercentile';
    case BenchmarkPercentile.Percentile90th:
      return '90thPercentile';
    case BenchmarkPercentile.Mean:
      return 'Mean';
    case BenchmarkPercentile.Percentile65th:
      return '65thPercentile';
    default:
      return '';
  }
}

export function readableNpvSummaryNameOfColumnDef(benchmark: BenchmarkPercentile, community: boolean): string {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return community ? 'communityPer25th' : 'per25th';
    case BenchmarkPercentile.Percentile50th:
      return community ? 'communityPer50th' : 'per50th';
    case BenchmarkPercentile.Percentile75th:
      return community ? 'communityPer75th' : 'per75th';
    case BenchmarkPercentile.Percentile90th:
      return community ? 'communityPer90th' : 'per90th';
    case BenchmarkPercentile.Mean:
      return community ? 'communityMean' : 'mean';
    case BenchmarkPercentile.Percentile65th:
      return community ? 'communityPer65th' : 'per65th';
    default:
      return '';
  }
}

export function readableWrvuSummaryNameOfColumnDef(benchmark: BenchmarkPercentile, community: boolean): string {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return community ? 'communityBenchmark25thPercentile' : 'benchmark25thPercentile';
    case BenchmarkPercentile.Percentile50th:
      return community ? 'communityBenchmark50thPercentile' : 'benchmark50thPercentile';
    case BenchmarkPercentile.Percentile75th:
      return community ? 'communityBenchmark75thPercentile' : 'benchmark75thPercentile';
    case BenchmarkPercentile.Percentile90th:
      return community ? 'communityBenchmark90thPercentile' : 'benchmark90thPercentile';
    case BenchmarkPercentile.Mean:
      return community ? 'communityBenchmarkMean' : 'benchmarkMean';
    case BenchmarkPercentile.Percentile65th:
      return community ? 'communityBenchmark65thPercentile' : 'benchmark65thPercentile';
    default:
      return '';
  }
}

export function readableVarianceSummaryNameOfColumnDef(benchmark: BenchmarkPercentile, community: boolean): string {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return community ? 'communityVariance25thPercentile' : 'variance25thPercentile';
    case BenchmarkPercentile.Percentile50th:
      return community ? 'communityVariance50thPercentile' : 'variance50thPercentile';
    case BenchmarkPercentile.Percentile75th:
      return community ? 'communityVariance75thPercentile' : 'variance75thPercentile';
    case BenchmarkPercentile.Percentile90th:
      return community ? 'communityVariance90thPercentile' : 'variance90thPercentile';
    case BenchmarkPercentile.Mean:
      return community ? 'communityVarianceMean' : 'varianceMean';
    case BenchmarkPercentile.Percentile65th:
      return community ? 'communityVariance65thPercentile' : 'variance65thPercentile';
    default:
      return '';
  }
}

export function getBenchmarkLabel(benchmark: BenchmarkPercentile): string {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return '25th percentile benchmark';
    case BenchmarkPercentile.Percentile50th:
      return '50th percentile benchmark';
    case BenchmarkPercentile.Percentile75th:
      return '75th percentile benchmark';
    case BenchmarkPercentile.Percentile90th:
      return '90th percentile benchmark';
    case BenchmarkPercentile.Mean:
      return 'benchmark mean';
    case BenchmarkPercentile.Percentile65th:
      return '65th percentile benchmark';
    default:
      return '';
  }
}

export interface BenchmarkHelperObject {
  value: BenchmarkOption;
  name: string;
  field: string;
}

export interface TelehealthBenchmarkHelperObject extends BenchmarkHelperObject {
  benchmarkPrefix: string;
  variancePrefix: string;
  percentilePrefix: string;
  meanField: string;
}

export const originalBenchmarkOptions: BenchmarkHelperObject[] = [
  {value: BenchmarkOption.Academic, name: 'Academic', field: 'benchmarkPercentage'},
  {value: BenchmarkOption.Community, name: 'Community', field: 'communityBenchmarkPercentage'}
];

export const extendedBenchmarkOptions: TelehealthBenchmarkHelperObject[] = [
  {
    value: BenchmarkOption.Academic,
    name: 'All Visit Types - Academic',
    field: 'benchmarkPercentage',
    benchmarkPrefix: 'benchmark',
    variancePrefix: 'variance',
    percentilePrefix: 'per',
    meanField: 'mean'
  },
  {
    value: BenchmarkOption.Community,
    name: 'All Visit Types - Community',
    field: 'communityBenchmarkPercentage',
    benchmarkPrefix: 'communityBenchmark',
    variancePrefix: 'communityVariance',
    percentilePrefix: 'communityPer',
    meanField: 'communityMean'
  },
  {
    value: BenchmarkOption.TelehealthAcademic,
    name: 'Telehealth - Academic',
    field: 'telehealthBenchmarkPercentage',
    benchmarkPrefix: 'telehealthBenchmark',
    variancePrefix: 'telehealthAcademicVariance',
    percentilePrefix: 'telehealthAcademicPer',
    meanField: 'telehealthAcademicMean'
  },
  {
    value: BenchmarkOption.TelehealthCommunity,
    name: 'Telehealth - Community',
    field: 'communityTelehealthBenchmarkPercentage',
    benchmarkPrefix: 'communityTelehealthBenchmark',
    variancePrefix: 'telehealthCommunityVariance',
    percentilePrefix: 'telehealthCommunityPer',
    meanField: 'telehealthCommunityMean'
  },
  {
    value: BenchmarkOption.InPersonAcademic,
    name: 'In-person - Academic',
    field: 'inPersonBenchmarkPercentage',
    benchmarkPrefix: 'inPersonBenchmark',
    variancePrefix: 'inpersonAcademicVariance',
    percentilePrefix: 'inpersonAcademicPer',
    meanField: 'inpersonAcademicMean'
  },
  {
    value: BenchmarkOption.InPersonCommunity,
    name: 'In-person - Community',
    field: 'communityInPersonBenchmarkPercentage',
    benchmarkPrefix: 'communityInPersonBenchmark',
    variancePrefix: 'inpersonCommunityVariance',
    percentilePrefix: 'inpersonCommunityPer',
    meanField: 'inpersonCommunityMean'
  }
];
