<div class="zip-code-tile-wrapper">
  <div class=tile>
    <div class="zip-table-wrapper">
      <span class=title>Top New Patient Visit Zip Codes {{level}}</span>
      <app-data-table id="zipcode-table"
                      [receivedData]="gmapMarkerData"
                      [displayedColumns]="zipCodeColumns"
                      [paginate]="false"
                      [showLevel]="false"
                      [tableCssClass]="'zip-data-table-container'"
                      [fromPdfExport] = "true"
      ></app-data-table>
    </div>
    <div class="zip-map-wrapper">
      <app-zipcode-map-batch-export
        [mapData]="gmapMarkerData"
        [fromPdf]="true"
      ></app-zipcode-map-batch-export>
    </div>
  </div>
</div>
