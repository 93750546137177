import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BenchmarkOption, ColumnType, MultilevelTab} from '../../../../shared/enums';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, FilterCriteria, IAppState} from '../../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {SortingCriterion} from '../../../../shared/models';
import {
  BenchmarkPercentile,
  readableNameOfColumnDef,
  readableNameOfColumnDefWithVisitTypeBenchmark
} from '../../../../shared/benchmark-types';
import * as _ from 'lodash';
import {distinctUntilChanged} from 'rxjs/operators';
import {DataTableColumns} from '../../../../shared/data-table-columns';
import {checkForNulls} from '../../../../shared/null-helpers';
import {multilevelTabChangedTo} from '../../../../store/actions';
import {
  locationTabEquivalentOf,
  replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor,
  replaceNpvSnapshotVarianceColumnWithBenchmarkFor
} from '../../npv-helpers';
import {combineFilter, FilterType, isFeatureEnabled} from '../../../../shared/helpers';
import {FilterSelectorService, FilterSelectorServiceToken} from '../../../../services/filter-selector.service';
import {ApiServiceImpl, ApiServiceToken} from '../../../../services/api.service';
import {NewPatientVisitsDepartmentPageComponent} from '../page-department/new-patient-visits-department-page.component';
import {NewPatientVisitsSpecialtyPageComponent} from '../page-specialty/new-patient-visits-specialty-page.component';
import {NewPatientVisitsProviderPageComponent} from '../page-provider/new-patient-visits-provider-page.component';
import {FeatureToggleEntries} from '../../../../shared/feature-toggle-settings-enum';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedProviderNewPatientVisitMultiLevelData,
  multilevelNpvDeptColumns,
  multilevelNpvProvColumns,
  multilevelNpvSpecColumns,
  npvTabs
} from '../../npv-models';
import {TabNavigationElement} from '../../../../tab-navigation/tab-helper';
import {LOCATION_TAB} from '../../../../shared/constants';

@Component({
  selector: 'app-npv-multilevel',
  templateUrl: './npv-multilevel.component.html',
  styleUrls: ['./npv-multilevel.component.scss']
})
export class NpvMultilevelComponent implements OnInit, OnDestroy, AfterViewChecked {

  @select(['data', 'newPatientVisitMultilevelData'])
  private readonly newPatientVisitMultilevelData$: Observable<MergedProviderNewPatientVisitMultiLevelData>;
  @select(['display', 'isCustom'])
  private readonly isCustom$: Observable<boolean>;
  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;
  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;
  @select(['display', 'zeroSuppression'])
  private readonly zeroSuppression$: Observable<boolean>;
  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;
  @select(['display', 'emNpvBenchmarkOption'])
  private readonly benchmarkOption$: Observable<BenchmarkOption>;
  // Sorting subscribes
  @select(['display', 'sortingCriteria', 'npvSnapshotSortingCriteria'])
  private readonly npvSnapshotSortingCriteria$: Observable<SortingCriterion | undefined>;
  @select(['display', 'varianceToggle', 'npvVariance'])
  private readonly npvVarianceToggle$: Observable<boolean>;
  // columns subscribe
  @select(['display', 'displayedNPVDepartmentColumns'])
  private readonly displayedNPVDepartmentColumns$: Observable<BaseColumn[]>;
  @select(['display', 'displayedNPVSpecialtyColumns'])
  private readonly displayedNPVSpecialtyColumns$: Observable<BaseColumn[]>;
  @select(['display', 'displayedNPVProviderColumns'])
  private readonly displayedNPVProviderColumns$: Observable<BaseColumn[]>;
  @select(['display', 'multilevelTab'])
  private readonly multilevelTab$: Observable<MultilevelTab>;

  @ViewChild(NewPatientVisitsDepartmentPageComponent) departmentPage: NewPatientVisitsDepartmentPageComponent;
  @ViewChild(NewPatientVisitsSpecialtyPageComponent) specialtyPage: NewPatientVisitsSpecialtyPageComponent;
  @ViewChild(NewPatientVisitsProviderPageComponent) providerPage: NewPatientVisitsProviderPageComponent;

  departmentNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[] = [];
  specialtyNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[] = [];
  providerNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[] = [];
  activeVariance = false;
  npvSnapshotSortingCriteria: SortingCriterion | undefined;
  zeroSuppression = false;
  suppressedDepartments = 0;
  suppressedSpecialties = 0;
  suppressedProviders = 0;
  isCustom = false;
  benchmarkPercentile: BenchmarkPercentile;
  benchmarkOption: BenchmarkOption;
  nodePath: string;
  viewCommunityBenchmarks: boolean;
  chosenTab: MultilevelTab = MultilevelTab.NONE;
  readonly locationTabs = [MultilevelTab.LOCATION_DEPARTMENT, MultilevelTab.LOCATION_SPECIALTY, MultilevelTab.LOCATION_PROVIDER];
  MultiLevelTab = MultilevelTab;
  multilevelTabChangedTo = multilevelTabChangedTo;

  departmentColumns: DataTableColumns[] = multilevelNpvDeptColumns();
  displayedDepartmentColumns: DataTableColumns[] = multilevelNpvDeptColumns();
  departmentColumnHeaders: BaseColumn[] = [];
  specialtyColumns: DataTableColumns[] = multilevelNpvSpecColumns();
  displayedSpecialtyColumns: DataTableColumns[] = multilevelNpvSpecColumns();
  specialtyColumnHeaders: BaseColumn[] = [];
  providerColumns: DataTableColumns[] = multilevelNpvProvColumns();
  displayedProviderColumns: DataTableColumns[] = multilevelNpvProvColumns();
  providerColumnHeaders: BaseColumn[] = [];
  dataSubscription: Subscription;
  columnSubscription: Subscription;
  multilevelTabSubscription: Subscription;
  filterSubscription: Subscription;

  tabs: TabNavigationElement[] = [];

  constructor(private ngRedux: NgRedux<IAppState>,
              private cdr: ChangeDetectorRef,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {
    this.chosenTab = MultilevelTab.BY_DEPARTMENT;

    this.filterSubscription = this.filterService.getFilters()
      .subscribe((filter: FilterCriteria) => {
        const previousYearFilter = combineFilter(filter, FilterType.PreviousYear);
        const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
        const locationEnabled = isFeatureEnabled(FeatureToggleEntries.NPV_BY_LOCATION, featureToggleSettings, userProfile);
        if (!!filter.nodePath) {
          this.apiService.getNewPatientTrendData(filter);
          this.apiService.getNewPatientSnapshotData(filter, previousYearFilter);
          this.apiService.getNewPatientSummaryData(filter);
          if (locationEnabled) {
            this.apiService.getNewPatientVisitLocationData(filter, previousYearFilter);
          }
        }
      });
  }

  ngOnInit(): void {
    const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
    const locationTabEnabled = isFeatureEnabled(FeatureToggleEntries.NPV_BY_LOCATION, featureToggleSettings, userProfile);
    this.multilevelTabSubscription = this.multilevelTab$.subscribe((tab: MultilevelTab) => {
      this.chosenTab = tab === MultilevelTab.SPECIALTY_PERFORMANCE ? MultilevelTab.BY_PROVIDER : tab;
      this.tabs = locationTabEnabled ? npvTabs.concat([{...LOCATION_TAB, dimension: locationTabEquivalentOf(this.chosenTab)}]) : npvTabs;
    });

    this.dataSubscription = combineLatest([
      this.newPatientVisitMultilevelData$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.isCustom$,
      this.benchmarkPercentile$,
      this.benchmarkOption$,
      this.nodePath$,
      this.viewCommunityBenchmarks$,
      this.zeroSuppression$])
      .subscribe((
        [newPatientVisitMultilevelData, isCustom, benchmarkPercentile, benchmarkOption, nodePath, viewCommunityBenchmarks, zeroSuppression]:
          [MergedProviderNewPatientVisitMultiLevelData, boolean, BenchmarkPercentile, BenchmarkOption, string, boolean, boolean]) => {
        this.isCustom = isCustom;
        this.benchmarkPercentile = benchmarkPercentile === BenchmarkPercentile.Percentile65th
          ? BenchmarkPercentile.Mean : benchmarkPercentile;
        this.benchmarkOption = benchmarkOption || (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
        this.nodePath = nodePath;
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.zeroSuppression = zeroSuppression;
        this.clearAllZeroSuppressionCounts();
        this.initializeLocalSnapshotsToCorrespondingReduxValues(newPatientVisitMultilevelData);
        this.suppressZeroesForAllLevels(zeroSuppression);
        this.calculateDifferencesForAllLevels();
        this.assignSnapshotsToTheirRespectiveLevelPages();
        combineLatest([this.npvVarianceToggle$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
          this.npvSnapshotSortingCriteria$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))])
          .subscribe(([activeVariance, npvSnapshotSortingCriteria]: [boolean, SortingCriterion | undefined]) => {
            this.activeVariance = activeVariance;
            this.npvSnapshotSortingCriteria = npvSnapshotSortingCriteria;
          });
        this.columnSubscription = combineLatest([
          this.displayedNPVDepartmentColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
          this.displayedNPVSpecialtyColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
          this.displayedNPVProviderColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
        ]).subscribe(([displayedDepartmentColumns, displayedSpecialtyColumns, displayedProviderColumns]:
                        [BaseColumn[], BaseColumn[], BaseColumn[]]) => {
          this.replaceBenchmarkDependenciesForAllLevels();
          this.updateColumnDisplay(displayedDepartmentColumns, displayedSpecialtyColumns, displayedProviderColumns);
        });
        this.cdr.detectChanges();
      });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.columnSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
    this.multilevelTabSubscription?.unsubscribe();
  }

  private initializeLocalSnapshotsToCorrespondingReduxValues(newPatientVisitMultilevelData: MergedProviderNewPatientVisitMultiLevelData) {
    this.departmentNpvSnapshotData = newPatientVisitMultilevelData.departmentNpvSnapshotData;
    this.specialtyNpvSnapshotData = newPatientVisitMultilevelData.specialtyNpvSnapshotData;
    this.providerNpvSnapshotData = newPatientVisitMultilevelData.providerNpvSnapshotData;
  }

  private clearAllZeroSuppressionCounts() {
    this.suppressedDepartments = 0;
    this.suppressedSpecialties = 0;
    this.suppressedProviders = 0;
  }

  private updateColumnDisplay(displayedDepartmentColumns: BaseColumn[],
                              displayedSpecialtyColumns: BaseColumn[], displayedProviderColumns: BaseColumn[]) {
    this.displayedDepartmentColumns = this.updateColumnFilter(displayedDepartmentColumns, this.departmentColumns);
    this.displayedDepartmentColumns = this.displayedDepartmentColumns.slice();
    this.departmentColumnHeaders = this.displayedDepartmentColumns.map(x => {
      return {
        columnDef: x.columnDef,
        header: x.header
      };
    });
    this.displayedSpecialtyColumns = this.updateColumnFilter(displayedSpecialtyColumns, this.specialtyColumns);
    this.displayedSpecialtyColumns = this.displayedSpecialtyColumns.slice();
    this.specialtyColumnHeaders = this.displayedSpecialtyColumns.map(x => {
      return {
        columnDef: x.columnDef,
        header: x.header
      };
    });
    this.displayedProviderColumns = this.updateColumnFilter(displayedProviderColumns, this.providerColumns);
    this.displayedProviderColumns = this.displayedProviderColumns.slice();
    this.providerColumnHeaders = this.displayedProviderColumns.map(x => {
      return {
        columnDef: x.columnDef,
        header: x.header
      };
    });
  }

  private replaceBenchmarkDependenciesForAllLevels() {
    this.replaceBenchmarkDependencies(this.departmentColumns);
    this.replaceBenchmarkDependencies(this.specialtyColumns);
    this.replaceBenchmarkDependencies(this.providerColumns);
  }

  private assignSnapshotsToTheirRespectiveLevelPages() {
    if (this.departmentPage) {
      this.departmentPage.newPatientVisits = this.departmentNpvSnapshotData;
    }
    if (this.specialtyPage) {
      this.specialtyPage.newPatientVisits = this.specialtyNpvSnapshotData;
    }
    if (this.providerPage) {
      this.providerPage.newPatientVisits = this.providerNpvSnapshotData;
    }
  }

  private calculateDifferencesForAllLevels() {
    this.departmentNpvSnapshotData.forEach(datum => {
      datum.difference = checkForNulls(datum.newPatientVisitsPercentage) - checkForNulls(datum.previous.newPatientVisitsPercentage);
    });
    this.specialtyNpvSnapshotData.forEach(datum => {
      datum.difference = checkForNulls(datum.newPatientVisitsPercentage) - checkForNulls(datum.previous.newPatientVisitsPercentage);
    });
    this.providerNpvSnapshotData.forEach(datum => {
      datum.difference = checkForNulls(datum.newPatientVisitsPercentage) - checkForNulls(datum.previous.newPatientVisitsPercentage);
    });
  }

  private suppressZeroesForAllLevels(zeroSuppression: boolean) {
    this.departmentNpvSnapshotData.forEach(x => {
      x.isHidden = zeroSuppression && !x.countOfTotalPatientVisits;
      if (x.isHidden) {
        this.suppressedDepartments++;
      }
    });
    this.specialtyNpvSnapshotData.forEach(x => {
      x.isHidden = zeroSuppression && !x.countOfTotalPatientVisits;
      if (x.isHidden) {
        this.suppressedSpecialties++;
      }
    });
    this.providerNpvSnapshotData.forEach(x => {
      x.isHidden = zeroSuppression && !x.countOfTotalPatientVisits;
      if (x.isHidden) {
        this.suppressedProviders++;
      }
    });
  }


  replaceBenchmarkDependencies(columns: DataTableColumns[]): void {
    const benchmarkIndex = columns.findIndex(c => c.columnType === ColumnType.BENCHMARK);
    if (benchmarkIndex >= 0) {
      columns[benchmarkIndex] =
        replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor(this.benchmarkPercentile, this.benchmarkOption, false);
    }
    const varianceIndex = columns.findIndex(c => c.columnType === ColumnType.VARIANCE);
    if (varianceIndex >= 0) {
      columns[varianceIndex] =
        replaceNpvSnapshotVarianceColumnWithBenchmarkFor(this.benchmarkPercentile, this.benchmarkOption, false);
    }
  }

  updateColumnFilter(displayedColumns: BaseColumn[], columns: DataTableColumns[]) {
    const displayedColumnDefs: string[] = displayedColumns.map(col => {
      switch (col.columnType) {
        case ColumnType.BENCHMARK:
          return `${readableNameOfColumnDefWithVisitTypeBenchmark(this.benchmarkPercentile, this.benchmarkOption)}`;
        case ColumnType.VARIANCE:
          return `variance${readableNameOfColumnDef(this.benchmarkPercentile)}`;
        default:
          return col.columnDef;
      }
    });
    return columns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef));
  }
}
