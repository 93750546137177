import * as _ from 'lodash';
import {checkForNulls, hasValue, undefinedIfZero} from '../shared/null-helpers';

export function formatNumberToWholeNumber(number?: number | null): string {
  if (number === undefined || number === null) {
    return '-';
  }
  const digits = number > 0 ? Math.floor(number) : Math.ceil(number);
  const fraction = number % 1;
  return addThousandSeparatorTo(digits + roundDecimalToNearestWholeNumber(fraction));
}

export function formatToWholeNumberOrDashIfZero(number?: number): string {
  number = undefinedIfZero(number);
  return formatNumberToWholeNumber(number);
}

export function formatToWholeNumberWithoutNulls(number?: number): string {
  return formatNumberToWholeNumber(checkForNulls(number));
}

export function getCssClass(number: number | null | undefined): string {
  return !number ? 'null' : number >= 0 ? 'positive' : 'negative';
}

export function abbreviateNumber(number: number): string {
  if (number < 1000) {
    return roundDecimalToNearestWholeNumber(number) + '';
  } else if (number < 1000000) {
    return roundDecimalToNearestWholeNumber(number / 1000) + 'k';
  } else if (number < 1000000000) {
    return roundTo(number / 1000000, 1) + 'm';
  } else if (number < 1000000000000) {
    return roundTo(number / 1000000000, 1) + 'b';
  }
  return number + '';
}

export function abbreviateAxisValue(number: number): string {
  if (number < 1000) {
    return roundDecimalToNearestWholeNumber(number) + '';
  } else if (number < 1000000) {
    return number % 1000 === 0 ? roundDecimalToNearestWholeNumber(number / 1000) + 'k' :
      roundTo(number / 1000, 1) + 'k';
  } else if (number < 1000000000) {
    return roundTo(number / 1000000, 1) + 'm';
  } else if (number < 1000000000000) {
    return roundTo(number / 1000000000, 1) + 'b';
  }
  return number + '';
}

export function abbeviateNumberAfterRounding(number: number, fractionDigits: number): string {
  if (number < 1000) {
    return (+number).toFixed(fractionDigits);
  } else if (number < 1000000) {
    return (+(number / 1000)).toFixed(fractionDigits) + 'k';
  } else if (number < 1000000000) {
    return (+(number / 1000000)).toFixed(fractionDigits) + 'm';
  } else if (number < 1000000000000) {
    return (+(number / 1000000000)).toFixed(fractionDigits) + 'b';
  }
  return number + '';
}

export function trimTrailingZeroes(value: string, howMany: number): string {
  const charAt = value.charAt(value.length - 1);
  if (charAt === '.') {
    return value.substring(0, value.length - 1);
  } else if (howMany === 0) {
    return value;
  }
  if (charAt === '0') {
    return trimTrailingZeroes(value.substring(0, value.length - 1), howMany - 1);
  } else if (!'123456789'.split('').find(dig => dig === charAt)) {
    return trimTrailingZeroes(value.substring(0, value.length - 1), howMany) + charAt;
  }
  return value;
}

export function roundTo(number: number | null | undefined, fractionDigits: number): string {
  if (number === undefined || number === null) {
    return '-';
  }
  // the prefix plus below lops off trailing zeros in the decimal place
  return (+number).toFixed(fractionDigits);
}

export function roundToWithPercentage(number: number | null | undefined, fractionDigits: number): string {
  if (number === undefined || number === null) {
    return '-';
  }
  // the prefix plus below lops off trailing zeros in the decimal place
  return (+number).toFixed(fractionDigits) + '%';
}

export function roundToWithPercentageOrElseNull(number: number | null | undefined, fractionDigits: number): string {
  if (number === 0 || number === undefined || number === null) {
    return '-';
  }
  // the prefix plus below lops off trailing zeros in the decimal place
  return (+number).toFixed(fractionDigits) + '%';
}

export function roundToWithCommasSeparation(number: number | null | undefined, fractionDigits: number = 2): string {
  if (number === undefined || number === null) {
    return '-';
  }

  return number.toLocaleString(undefined, {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  });
}

export function formatToCurrency(number: number | null | undefined, fractionDigits: number = 2): string {
  if (number === undefined || number === null) {
    return '-';
  }
  return '$' + roundToWithCommasSeparation(number, fractionDigits);
}

export function roundToNumber(number: number | undefined | null, fractionDigits: number): number {
  // the prefix plus below lops off trailing zeros in the decimal place
  if (number === undefined || number === null) {
    return 0;
  }
  return Number((+number).toFixed(fractionDigits));
}

export function roundCollectionsToNumber(number: number | undefined | null, fractionDigits: number): number | string {
  // the prefix plus below lops off trailing zeros in the decimal place
  if (number === undefined || number === null || number === 0) {
    return '-';
  }
  return Number((+number).toFixed(fractionDigits)) + '%';
}

export function formatToPercentage(number: number | undefined | null, fractionDigits: number = 1): string {
  // the prefix plus below lops off trailing zeros in the decimal place
  if (number === undefined || number === null) {
    return '0%';
  }
  return (+number * 100.0).toFixed(fractionDigits) + '%';
}

export function formatToPercentageOrElseNull(number: number | undefined | null, fractionDigits: number = 1): string {
  return (number === 0 || number === undefined || number === null) ? '-' :
    (+number * 100.0).toFixed(fractionDigits) + '%';
}

export function formatToPercentageAllowZero(number: number | undefined | null, fractionDigits: number = 1): string {
  return (number === undefined || number === null) ? '-' :
    (+number * 100.0).toFixed(fractionDigits) + '%';
}

export function getPercentage(number: number | undefined | null, fractionDigits: number): string {
  // the prefix plus below lops off trailing zeros in the decimal place
  if (number === undefined || number === null) {
    return '0%';
  }
  const result: number = (number * 100);
  return (+result).toFixed(fractionDigits) + '%';
}

export function roundCollectionsToWithCommasSeparation(number: number | null | undefined): string {
  if (number === undefined || number === null) {
    return '-';
  }
  number = Math.round(number);

  return number.toLocaleString();

}

function roundDecimalToNearestWholeNumber(number: number): number {
  return Math.round(number);
}

export function addThousandSeparatorTo(number: number): string {
  const prefix = number < 0 ? '-' : '';
  return prefix + addThousandSeparatorToPositiveNumber(Math.abs(number));
}

export function addThousandSeparatorToPositiveNumber(number: number): string {
  if (number < 0) {
    throw new Error('this should not be used for negative numbers');
  }
  const digitsArray = number.toString().split('');
  const arrayToString = <T>(array: T[]) => array.join('');
  return reverseChunk(digitsArray, 3, arrayToString)
    .join(',');
}

export function safelyDivide(x: number | undefined, y: number): number {
  if (x && y) {
    return x / y;
  }
  return 0;
}

export function getOrdinal(x: number | null | undefined): string {
  if (!hasValue(x)) {
    return '-';
  }
  x = x || 0;
  if (x >= 11 && x <= 13) {
    return x + 'th';
  }
  switch (x % 10) {
    case 1:
      return x + 'st';
    case 2:
      return x + 'nd';
    case 3:
      return x + 'rd';
    default:
      return x + 'th';
  }
}

function reverseChunk<T>(characters: T[], size: number, joiner: (items: T[]) => T): T[] {
  return _.chunk(characters.reverse(), size)
    .map((chunk) => joiner(chunk.reverse()))
    .reverse();
}

export function roundToNearestTenthOrDash(num: number): number | string {
  if (isNaN(num)) {
    return '-';
  }
  return Math.round(num * 10) / 10;
}
export function roundToNearestTenthOrZero(num: number): number {
  if (isNaN(num)) {
    return 0;
  }
  return Math.round(num * 10) / 10;
}

export function multiplyToPercentRoundedToNearestTenthOrDash(num: number): number | string {
  if (isNaN(num)) {
    return '-';
  }
  const percentage = num * 100;
  return Math.round(percentage * 10) / 10;
}
export function multiplyToPercentRoundedToNearestTenthOrZero(num: number): number  {
  if (isNaN(num)) {
    return 0;
  }
  const percentage = num * 100;
  return Math.round(percentage * 10) / 10;
}
