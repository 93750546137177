import {
  BatchExportDetailCriteria,
  BatchExportReportDetailsResponseEntity,
  BatchFilterCriteria,
  DateRange,
  FeatureToggleSetting,
  NewPatientVisitSummary
} from '../shared/models';
import {BatchExportStatus, BenchmarkOption, ColumnType, FrontEndTelehealthFlag} from '../shared/enums';
import {
  FilterType,
  getFlagCorrespondingToBenchmark,
  getOppositeNodeExpansionState,
  getPrettyDateFromLocalDateTime,
  getPreviousYearDateRange,
  getPreviousYtdDateRange,
  getRecentMonthDate,
  getSelectedDateRange,
  getYtdDateRange,
  nodeExpansionIsActive
} from '../shared/helpers';
import {SummaryData} from '../store/IAppState';
import {DataTableColumns} from '../shared/data-table-columns';
import {CptRangeMap, metricsClass} from '../evaluation-management/em-helpers';
import {
  BatchPdfOption,
  emOverviewText,
  emPageHierarchy,
  EMPageOption,
  EMPagePdfOption,
  emTrendHierarchy,
  EMTrendOption,
  EMTrendPdfOption,
  ExpandableExportOption,
  npvOverviewText,
  NPVPageOption,
  npvPdfHierarchy,
  overviewPageHierarchy,
  OverviewPageOption,
  OverviewPagePdfOption,
  wrvuOverviewText,
  wrvuTrendHierarchy
} from '../shared/export/export';
import {MetricType} from '../shared/metric-types';
import * as _ from 'lodash';
import {
  BatchExportScheduleCriteria,
  BatchExportScheduleExistingReportChild,
  BatchExportScheduleExistingReportParent,
  BenchmarkCombo,
  getBenchmarkComboDefault,
  MultiProviderScheduleExistingReport
} from './multi-provider-export-redesign/mpe-dialog-helper';
import {
  BenchmarkPercentile,
  translateNumberToBenchmarkOption,
  translateNumberToBenchmarkPercentile
} from '../shared/benchmark-types';
import {hasValue} from '../shared/null-helpers';

export enum BatchExportReportScheduleType {
  ONE_TIME,
  MONTHLY,
  QUARTERLY
}

export const batchExportReportScheduleTypes =
  [{
    value: BatchExportReportScheduleType.ONE_TIME,
    displayTextForTable: 'One Time Report'
  }, {
    value: BatchExportReportScheduleType.MONTHLY,
    displayTextForTable: 'Scheduled Report (Monthly)'
  }, {
    value: BatchExportReportScheduleType.QUARTERLY,
    displayTextForTable: 'Scheduled Report (Quarterly)'
  }];

export interface ExportedReportsDialogColumn {
  id: string;
  columnDef: string;
  headerText: string;
  tableText: (row: BatchExportReportDetailsResponseEntity) => string;
  hoverMessage?: string;
  icon?: string;
}

export interface ScheduledReportsDialogColumn extends DataTableColumns {
  icon?: string;
  id?: string;
}

function getColumnsForExportedReports():
  ExportedReportsDialogColumn[] {
  return [
    {
      id: 'report-name',
      columnDef: 'reportName',
      headerText: 'Report Name',
      tableText: (row: BatchExportReportDetailsResponseEntity) => row.reportName
    }, {
      id: 'status',
      columnDef: 'status',
      headerText: 'Status',
      tableText: (row: BatchExportReportDetailsResponseEntity) => convertStatus(row.reportStatus)
    }, {
      id: 'export-date',
      columnDef: 'exportDate',
      headerText: 'Export Date',
      tableText: (row: BatchExportReportDetailsResponseEntity) => getPrettyDateFromLocalDateTime(row.exportDate)
    }, {
      id: 'num-departments',
      columnDef: 'numDepartments',
      headerText: '# of Departments',
      tableText: (row: BatchExportReportDetailsResponseEntity) => `${row.numberOfDepartments}`

    }, {
      id: 'num-specialties',
      columnDef: 'numSpecialties',
      headerText: '# of Specialties',
      tableText: (row: BatchExportReportDetailsResponseEntity) => `${row.numberOfSpecialties}`

    }, {
      id: 'num-providers',
      columnDef: 'numProviders',
      headerText: '# of Providers',
      tableText: (row: BatchExportReportDetailsResponseEntity) => `${row.numberOfProviders}`
    }
  ];
}

export function getColumnsForExportedOneTimeReportsDialog():
  ExportedReportsDialogColumn[] {
  const columns: ExportedReportsDialogColumn[] = getColumnsForExportedReports();
  columns.push(getDeleteColumnForExportedReportsDialog());
  return columns;
}

function getDeleteColumnForExportedReportsDialog(): ExportedReportsDialogColumn {
  return {
    id: 'delete',
    columnDef: 'delete',
    headerText: 'Delete',
    tableText: (row: BatchExportReportDetailsResponseEntity) => '',
    icon: 'delete'
  };
}

export function convertStatus(status: BatchExportStatus): string {
  switch (status) {
    case BatchExportStatus.ACKNOWLEDGED:
      return 'Acknowledged';
    case BatchExportStatus.SUBMITTED:
      return 'Submitted';
    case BatchExportStatus.FAILED:
      return 'Failed';
    case BatchExportStatus.COMPLETED:
      return 'Completed';
    case BatchExportStatus.PROCESSING:
      return 'Processing';
    default:
      return 'Unknown';
  }
}

export function getHeaderFromKey(key: string): string {
  switch (key) {
    case 'selectedDateRange':
      return 'Selected Date Range';
    case 'previousYearSelectedDateRange':
      return 'Previous Year \n Selected Date Range';
    case 'yearToDate':
      return 'YTD';
    case 'previousYearToDate':
      return 'Previous Year YTD';
    case 'recentMonth':
      return 'Recent Month';
    case 'previousYearRecentMonth':
      return 'Previous Year \n Recent Month';
    default:
      return 'Metric';
  }
}

export function getDateRangeTextFromKey(key: string, dateRange: DateRange, npvSummary: SummaryData<NewPatientVisitSummary>): string {
  switch (key) {
    case 'selectedDateRange':
      return getSelectedDateRange(dateRange);
    case 'previousYearSelectedDateRange':
      return getPreviousYearDateRange(dateRange);
    case 'yearToDate':
      return getYtdDateRange(npvSummary.yearToDate);
    case 'previousYearToDate':
      return getPreviousYtdDateRange(npvSummary.previousYearToDate);
    case 'recentMonth':
      return getRecentMonthDate(npvSummary.recentMonth);
    case 'previousYearRecentMonth':
      return getRecentMonthDate(npvSummary.previousYearRecentMonth);
    default:
      return '';
  }
}

export function addClassesToColumns(initialColumns: DataTableColumns[], cptCategoryMap: CptRangeMap,
                                    firstRange: boolean): DataTableColumns[] {
  return initialColumns.map(c => {
    return {
      ...c, class: c.columnType === ColumnType.METRICS ? firstRange ? 'pre-range' : metricsClass :
        cptCategoryMap.codes.includes(c.columnDef) ? 'in-range' : cptCategoryMap.range
          .localeCompare(c.columnDef) === 0 ? 'total-range' : 'out-range'
    };
  });
}

export function closeSelectionHierarchy(options: ExpandableExportOption<any>[]): void {
  options.forEach(o => {
    o.selected = false;
    if (nodeExpansionIsActive(o.nodeExpansionState)) {
      o.nodeExpansionState = getOppositeNodeExpansionState(o.nodeExpansionState);
    }
    closeSelectionHierarchy(o.children);
  });
}

export function anyBatchPdfHierarchicalItemSelected(options: BatchPdfOption[]): boolean {
  for (let x = 0; x < options.length; x++) {
    if (options[x].selected || anyBatchPdfHierarchicalItemSelected(options[x].children)) {
      return true;
    }
  }
  return false;
}

export function anyEmTrendHierarchySelected(emTrendOptions: EMTrendOption[]): EMTrendPdfOption[] {
  let foundOptions: EMTrendPdfOption[] = [];
  for (let x = 0; x < emTrendOptions.length; x++) {
    if (emTrendOptions[x].selected) {
      foundOptions.push(emTrendOptions[x].id);
    }
    foundOptions = foundOptions.concat(anyEmTrendHierarchySelected(emTrendOptions[x].children));
  }
  return foundOptions;
}

export function combineBatchFilter(filter: BatchFilterCriteria, summaryType: FilterType): BatchFilterCriteria {
  const newFilter = _.cloneDeep(filter);
  switch (summaryType) {
    case FilterType.Specialty:
      const lastIndex = filter.nodePath.lastIndexOf('\\');
      const specialtyPath = filter.nodePath.substring(0, lastIndex);
      newFilter.nodePath = specialtyPath;
      break;
  }
  return newFilter;
}

export interface BatchExportMetricSection {
  text: string;
  metric: MetricType;
  selected: boolean;
  isOverviewTile: boolean;
  options: BatchPdfOption[];
  id: string;
  benchmarksExtended?: boolean;
  benchmarkCombo?: BenchmarkCombo;
  metricLabels?: MultiProviderExportMetricLabel[];
  hoverMessage?: MultiProviderExportMetricLabel;
}

export type HasMetricAndBenchmarkCombo = BatchExportMetricSection | BatchPdfOption;

export const overViewId = 'overview-title';
export const emPageId = 'batch-dialog-em-page';
export const emTrendId = 'batch-dialog-em-trend';
export const npvId = 'batch-dialog-npv';
export const wrvuTrendId = 'batch-dialog-wrvu-trend';

function overviewMetricLabel(): MultiProviderExportMetricLabel[] {
  return [{
    tokens: [{
      token: 'The export will include the overview page with the ',
      bolded: false,
      breakAfter: false
    }, {
      token: 'cFTE adj. wRVUS Trend, % New Patients Trend, Evaluation and Management ',
      bolded: true,
      breakAfter: false
    }, {
      token: 'and ',
      bolded: false,
      breakAfter: false
    }, {
      token: 'Top New Patient Zip Code metrics. ',
      bolded: true,
      breakAfter: false
    }, {
      token: 'For the E&M export, please select the code range you\'d like to export.',
      bolded: false,
      breakAfter: false
    }]
  }
  ];
}

function emTrendMetricLabel(): MultiProviderExportMetricLabel[] {
  return [{
    tokens: [{
      token: 'This export includes graphs and data table for E&M Trend.',
      bolded: false,
      breakAfter: false
    }]
  }];
}

export function getOverviewMetricSection(telehealthFlag: FrontEndTelehealthFlag): BatchExportMetricSection {
  return {
    text: 'Overview',
    metric: MetricType.Overview,
    selected: false,
    isOverviewTile: false,
    options: overviewPageHierarchy(telehealthFlag),
    id: overViewId,
    benchmarkCombo: getBenchmarkComboDefault(false, telehealthFlag),
    metricLabels: overviewMetricLabel()
  };
}

export function getOriginalBatchExportMetricSections(canShowNpvLocation: boolean, canShowWrvuTrend: boolean,
                                                     telehealthFlag: FrontEndTelehealthFlag): BatchExportMetricSection[] {
  const batchExportSections: BatchExportMetricSection[] = [{
    text: 'Overview', metric: MetricType.Overview, selected: false, isOverviewTile: false,
    options: [], id: overViewId, benchmarkCombo: getBenchmarkComboDefault(false, telehealthFlag)
  }, {
    text: wrvuOverviewText, metric: MetricType.OverviewWrvu, selected: false, isOverviewTile: true,
    options: [], id: '', benchmarkCombo: getBenchmarkComboDefault(false, telehealthFlag)
  }, {
    text: npvOverviewText, metric: MetricType.OverviewNpv, selected: false, isOverviewTile: true,
    options: [], id: '', benchmarkCombo: getBenchmarkComboDefault(true, telehealthFlag),
    benchmarksExtended: true
  }, {
    text: emOverviewText, metric: MetricType.OverviewEandM, selected: false, isOverviewTile: true,
    options: [], id: '', benchmarkCombo: getBenchmarkComboDefault(true, telehealthFlag),
    benchmarksExtended: true
  }, {
    text: 'Evaluation and Management Page', metric: MetricType.EandM, selected: false,
    isOverviewTile: false, options: emPageHierarchy(), id: emPageId, benchmarkCombo: getBenchmarkComboDefault(
      true, telehealthFlag),
    hoverMessage: {
      tokens: [{
        token: 'Includes graphs and data tables for the ',
        bolded: false,
        breakAfter: false
      }, {
        token: 'Evaluation ',
        bolded: true,
        breakAfter: true
      }, {
        token: 'and Management Page',
        bolded: true,
        breakAfter: true
      }, {
        token: '',
        bolded: false,
        breakAfter: true
      }, {
        token: 'Please select the code range(s) you\'d like to export.',
        bolded: false,
        breakAfter: false
      }]
    },
    benchmarksExtended: true
  }, {
    text: 'Evaluation and Management Trend', metric: MetricType.EandMTrend, selected: false,
    isOverviewTile: false, options: emTrendHierarchy(), id: emTrendId, benchmarkCombo: getBenchmarkComboDefault(
      true, telehealthFlag),
    metricLabels: emTrendMetricLabel(),
    hoverMessage: {
      tokens: [{
        token: 'Includes graphs and data tables for the ',
        bolded: false,
        breakAfter: false
      }, {
        token: 'Evaluation ',
        bolded: true,
        breakAfter: true
      }, {
        token: 'and Management Trend',
        bolded: true,
        breakAfter: true
      }, {
        token: '',
        bolded: false,
        breakAfter: true
      }, {
        token: 'Please select the code range(s) you\'d like to export.',
        bolded: false,
        breakAfter: false
      }]
    },
    benchmarksExtended: true
  }, {
    text: 'NPV Trend', metric: MetricType.NewPatientVisits, selected: false,
    isOverviewTile: false, options: npvPdfHierarchy(), id: npvId,
    benchmarkCombo: getBenchmarkComboDefault(true, telehealthFlag),
    hoverMessage: {
      tokens: [{
        token: 'Includes graphs and data tables for the ',
        bolded: false,
        breakAfter: false
      }, {
        token: 'New Patient ',
        bolded: true,
        breakAfter: true
      }, {
        token: 'Visits Page ',
        bolded: true,
        breakAfter: false
      }, {
        token: 'and the ',
        bolded: false,
        breakAfter: false
      }, {
        token: 'Top New Patient Visits Zip ',
        bolded: true,
        breakAfter: true,
      }, {
        token: 'Code Map',
        bolded: true,
        breakAfter: false
      }
      ]
    },
    benchmarksExtended: true
  }];
  if (canShowWrvuTrend) {
    const wrvuTrendSection: BatchExportMetricSection = {
      text: 'WRVU Trend', metric: MetricType.WorkRVUs, selected: false,
      isOverviewTile: false, options: wrvuTrendHierarchy(), id: wrvuTrendId,
      benchmarkCombo: getBenchmarkComboDefault(false, telehealthFlag)
    };
    batchExportSections.push(wrvuTrendSection);
  }
  return batchExportSections;
}

export function batchExportMetricSectionHasAnOptionSelected(batchPdfOptions: BatchPdfOption[]): boolean {
  for (let i = 0; i < batchPdfOptions.length; i++) {
    if (batchPdfOptions[i].selected || batchExportMetricSectionHasAnOptionSelected(batchPdfOptions[i].children)) {
      return true;
    }
  }
  return false;
}

interface MultiProviderExportToken {
  token: string;
  bolded: boolean;
  breakAfter: boolean;
}

export interface MultiProviderExportMetricLabel {
  tokens: MultiProviderExportToken[];
}

export function setSelectedScheduleOverviewOptionValues(schedule: BatchExportDetailCriteria,
                                                        options: OverviewPageOption[]): BatchExportDetailCriteria {
  for (let x = 0; x < options.length; x++) {
    if (options[x].selected) {
      switch (options[x].id) {
        case OverviewPagePdfOption.OVERVIEW_OUTPATIENT:
          schedule.isOverviewRequired = true;
          schedule.isOutpatientRequired = true;
          break;
        case OverviewPagePdfOption.OVERVIEW_INPATIENT:
          schedule.isOverviewRequired = true;
          schedule.isInPatientRequired = true;
          break;
        case OverviewPagePdfOption.OVERVIEW_OPHTHALMOLOGY:
          schedule.isOverviewRequired = true;
          schedule.isOpthalmologyRequired = true;
          break;
        case OverviewPagePdfOption.OVERVIEW_EMERGENCY:
          schedule.isOverviewRequired = true;
          schedule.isEmergencyMedicineRequired = true;
          break;
        case OverviewPagePdfOption.OVERVIEW_NPV:
        case OverviewPagePdfOption.OVERVIEW_WRVU:
          schedule.isOverviewRequired = true;
          break;
      }
    }
    setSelectedScheduleOverviewOptionValues(schedule, options[x].children);
  }
  return schedule;
}

export function setSelectedScheduleEmPageOptionValues(schedule: BatchExportDetailCriteria,
                                                      options: EMPageOption[]): BatchExportDetailCriteria {
  for (let x = 0; x < options.length; x++) {
    if (options[x].selected) {
      switch (options[x].id) {
        case EMPagePdfOption.OUTPATIENT:
          schedule.isEmPageRequired = true;
          schedule.isEmPageOutpatientRequired = true;
          break;
        case EMPagePdfOption.INPATIENT:
          schedule.isEmPageRequired = true;
          schedule.isEmPageInPatientRequired = true;
          break;
        case EMPagePdfOption.EMERGENCY_MEDICINE:
          schedule.isEmPageRequired = true;
          schedule.isEmPageEmergencyMedicineRequired = true;
          break;
        case EMPagePdfOption.OPHTHALMOLOGY:
          schedule.isEmPageRequired = true;
          schedule.isEmPageOpthalmologyRequired = true;
          break;
      }
    }
    setSelectedScheduleEmPageOptionValues(schedule, options[x].children);
  }
  return schedule;
}

export function setSelectedScheduleEmTrendOptionValues(schedule: BatchExportDetailCriteria,
                                                       options: EMTrendOption[]): BatchExportDetailCriteria {
  for (let x = 0; x < options.length; x++) {
    if (options[x].selected) {
      switch (options[x].id) {
        case EMTrendPdfOption.EM_OUTPATIENT_TREND_NEW_PATIENT_VISIT:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeNewPatientVisit = true;
          break;
        case EMTrendPdfOption.EM_OUTPATIENT_TREND_ESTABLISHED_PATIENT:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeEstablishedPatientVisit = true;
          break;
        case EMTrendPdfOption.EM_OUTPATIENT_TREND_CONSULTATION:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeConsultation = true;
          break;
        case EMTrendPdfOption.EM_INPATIENT_TREND_INITIAL_HOSPITAL_CARE:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeInitialHospital = true;
          break;
        case EMTrendPdfOption.EM_INPATIENT_TREND_SUBSEQUENT_HOSPITAL_CARE:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeSubsequentHospital = true;
          break;
        case EMTrendPdfOption.EM_INPATIENT_TREND_HOSPITAL_DISCHARGE:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeHospitalDischarge = true;
          break;
        case EMTrendPdfOption.EM_EMERGENCY_TREND_MEDICINE:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeEmergencyMedicine = true;
          break;
        case EMTrendPdfOption.EM_EYE_EXAM_TREND_NEW_PATIENT_EYE_EXAM:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeNpvEyeExam = true;
          break;
        case EMTrendPdfOption.EM_EYE_EXAM_TREND_ESTABLISHED_PATIENT_EYE_EXAM:
          schedule.isEmTrendPageRequired = true;
          schedule.emTrendRangeEstablishedEyeExam = true;
          break;
      }
    }
    setSelectedScheduleEmTrendOptionValues(schedule, options[x].children);
  }
  return schedule;
}

export function setSelectedScheduleNpvOptionValues(schedule: BatchExportDetailCriteria,
                                                   options: NPVPageOption[]): BatchExportDetailCriteria {
  if (options[0].selected) {
    schedule.isNewPatientVisitsTrendPageRequired = true;
  }
  return schedule;
}

export function convertBatchExportCriteriaToScheduleObject(batchExportCriteria: BatchExportDetailCriteria,
                                                           isMonthly: boolean): BatchExportScheduleCriteria {
  return {
    reportName: batchExportCriteria.reportName,
    nodePath: batchExportCriteria.nodePath,
    dateRangeOption: 0,
    scheduleType: isMonthly ? BatchExportReportScheduleType.MONTHLY : BatchExportReportScheduleType.ONE_TIME,
    startDateInMonths: batchExportCriteria.startDateInMonths,
    endDateInMonths: batchExportCriteria.endDateInMonths,
    isMarkedForDelete: false,
    payerCategoryKey: batchExportCriteria.payerCategoryKey,
    payerKey: batchExportCriteria.payerKey,
    payerType: batchExportCriteria.payerType,
    lagKey: batchExportCriteria.lagKey,
    invoiceStatus: batchExportCriteria.invoiceStatus,
    isOutpatientRequired: batchExportCriteria.isOutpatientRequired,
    isInPatientRequired: batchExportCriteria.isInPatientRequired,
    isEmergencyMedicineRequired: batchExportCriteria.isEmergencyMedicineRequired,
    isOpthalmologyRequired: batchExportCriteria.isOpthalmologyRequired,
    billingAreaKey: batchExportCriteria.memberBillingAreaKey,
    telehealthFlag: batchExportCriteria.telehealthFlag || 0,
    isOverviewRequired: batchExportCriteria.isOverviewRequired,
    isEmPageRequired: batchExportCriteria.isEmPageRequired,
    isEmPageOutpatientRequired: batchExportCriteria.isEmPageOutpatientRequired,
    isEmPageInPatientRequired: batchExportCriteria.isEmPageInPatientRequired,
    isEmPageEmergencyMedicineRequired: batchExportCriteria.isEmPageEmergencyMedicineRequired,
    isEmPageOpthalmologyRequired: batchExportCriteria.isEmPageOpthalmologyRequired,
    isNewPatientVisitsTrendPageRequired: batchExportCriteria.isNewPatientVisitsTrendPageRequired,
    memberLocationKeys: batchExportCriteria.memberLocationKeys,
    isEmTrendPageRequired: batchExportCriteria.isEmTrendPageRequired,
    emTrendRangeNewPatientVisit: batchExportCriteria.emTrendRangeNewPatientVisit,
    emTrendRangeEstablishedPatientVisit: batchExportCriteria.emTrendRangeEstablishedPatientVisit,
    emTrendRangeConsultation: batchExportCriteria.emTrendRangeConsultation,
    emTrendRangeInitialHospital: batchExportCriteria.emTrendRangeInitialHospital,
    emTrendRangeSubsequentHospital: batchExportCriteria.emTrendRangeSubsequentHospital,
    emTrendRangeHospitalDischarge: batchExportCriteria.emTrendRangeHospitalDischarge,
    emTrendRangeEmergencyMedicine: batchExportCriteria.emTrendRangeEmergencyMedicine,
    emTrendRangeNpvEyeExam: batchExportCriteria.emTrendRangeNpvEyeExam,
    emTrendRangeEstablishedEyeExam: batchExportCriteria.emTrendRangeEstablishedEyeExam,
    npvTileBenchmarkType: batchExportCriteria.npvTileBenchmarkType ?? BenchmarkOption.Academic,
    npvTileBenchmarkPercentile: batchExportCriteria.npvTileBenchmarkPercentile ?? BenchmarkPercentile.Percentile50th,
    wrvuTileBenchmarkType: batchExportCriteria.wrvuTileBenchmarkType ?? BenchmarkOption.Academic,
    wrvuTileBenchmarkPercentile: batchExportCriteria.wrvuTileBenchmarkPercentile ?? BenchmarkPercentile.Percentile50th,
    emTileBenchmarkType: batchExportCriteria.emTileBenchmarkType ?? BenchmarkOption.Academic,
    emTrendBenchmarkType: batchExportCriteria.emTrendBenchmarkType ?? BenchmarkOption.Academic,
    emPageBenchmarkType: batchExportCriteria.emPageBenchmarkType ?? BenchmarkOption.Academic,
    npvPageBenchmarkType: batchExportCriteria.npvPageBenchmarkType ?? BenchmarkOption.Academic,
    npvPageBenchmarkPercentile: batchExportCriteria.npvPageBenchmarkPercentile ?? BenchmarkPercentile.Percentile50th
  };
}

function sharedColumnsForScheduleTable(showCancelDelete: boolean, numberOfProvidersAndSpecialtiesVisible: boolean):
  ScheduledReportsDialogColumn[] {
  const columns: ScheduledReportsDialogColumn[] = [{
    columnDef: 'status',
    header: 'Status',
    dataName: (row: MultiProviderScheduleExistingReport) => visualBatchExportStatus(row.status)
  }, {
    columnDef: 'exportDate',
    header: 'Last Exported On',
    dataName: (row: MultiProviderScheduleExistingReport) => getPrettyDateFromLocalDateTime(row.exportDate)
  }, {
    columnDef: 'numberOfSpecialties',
    header: '# of specialties',
    dataName: (row: MultiProviderScheduleExistingReport) => numberOfProvidersAndSpecialtiesVisible ?
      row.numberOfSpecialties : ''
  }, {
    columnDef: 'numberOfProviders',
    header: '# of providers',
    dataName: (row: MultiProviderScheduleExistingReport) => numberOfProvidersAndSpecialtiesVisible ?
      row.numberOfProviders : ''
  }];
  const cancelAndDelete: ScheduledReportsDialogColumn[] = [{
    columnDef: 'cancel',
    header: 'Cancel Scheduled Export',
    columnType: ColumnType.CANCEL,
    dataName: (row: MultiProviderScheduleExistingReport) => ''
  }, {
    columnDef: 'delete',
    header: 'Delete',
    columnType: ColumnType.DELETE,
    dataName: (row: MultiProviderScheduleExistingReport) => ''
  }];
  return columns.concat(showCancelDelete ? cancelAndDelete : []);
}

const sharedTableModificationColumn: ScheduledReportsDialogColumn = {
  columnDef: 'modification',
  header: '',
  columnType: ColumnType.TABLE_MODIFICATION,
  dataName: () => ''
};

export function parentColumnsForScheduledTable(showCancelDelete: boolean): ScheduledReportsDialogColumn[] {
  return [sharedTableModificationColumn, {
    columnDef: 'scheduledReportName',
    header: 'Report Name',
    columnType: ColumnType.REPORT_NAME,
    dataName: (row: BatchExportScheduleExistingReportParent) => row.scheduledReportName
  }, ...sharedColumnsForScheduleTable(showCancelDelete, false)];
}

export function childColumnsForScheduledTable(showCancelDelete: boolean): ScheduledReportsDialogColumn[] {
  return [sharedTableModificationColumn, {
    columnDef: 'instanceReportName',
    header: 'Report Name',
    columnType: ColumnType.REPORT_NAME,
    dataName: (row: BatchExportScheduleExistingReportChild) => row.instanceReportName
  }, ...sharedColumnsForScheduleTable(showCancelDelete, true)];
}

export function visualBatchExportStatus(status: number): string {
  switch (status) {
    case BatchExportStatus.SUBMITTED:
      return 'Submitted';
    case BatchExportStatus.PROCESSING:
      return 'Processing';
    case BatchExportStatus.COMPLETED:
      return 'Complete';
    case BatchExportStatus.FAILED:
      return 'Failed';
    case BatchExportStatus.ACKNOWLEDGED:
      return 'Acknowledged';
    default:
      return '';
  }
}

export const deleteReportTitle = 'Delete Report';

export function isFeatureEnabledForBatchExport(featureName: String, settings: FeatureToggleSetting[], emailParam: string) {
  const internalUser = (emailParam || '').trim().toLowerCase().endsWith('@vizientinc.com');

  if (settings && settings.length > 0) {
    const setting: FeatureToggleSetting | undefined =
      settings.find((s: FeatureToggleSetting) => s.featureName?.toLowerCase() === featureName.toLowerCase());

    if ((setting?.enabled) || (internalUser && setting?.enabledInternal)) {
      return true;
    }
  }
  return false;
}

export function setUpBenchmarkPercentile(benchmarkPercentile: number | undefined): BenchmarkPercentile {
  // tslint:disable-next-line:no-non-null-assertion
  return hasValue(benchmarkPercentile) ? translateNumberToBenchmarkPercentile(benchmarkPercentile!) : BenchmarkPercentile.Mean;
}

export function setUpBenchmarkOption(benchmarkOption: number | undefined): BenchmarkOption {
  // tslint:disable-next-line:no-non-null-assertion
  return hasValue(benchmarkOption) ? translateNumberToBenchmarkOption(benchmarkOption!) : BenchmarkOption.Academic;
}

export function updateFilterCriteriaWithTelehealthFlag(canShowExtendedBenchmark: boolean, benchmarkOption: BenchmarkOption,
                                                       originalFilterCriteria: BatchFilterCriteria): BatchFilterCriteria {
  if (canShowExtendedBenchmark) {
    return {...originalFilterCriteria, telehealthFlag: getFlagCorrespondingToBenchmark(benchmarkOption)};
  }
  return originalFilterCriteria;
}

export function benchmarkOptionIsCommunity(option: BenchmarkOption): boolean {
  switch (option) {
    case BenchmarkOption.TelehealthCommunity:
    case BenchmarkOption.Community:
    case BenchmarkOption.InPersonCommunity:
      return true;
    case BenchmarkOption.Academic:
    case BenchmarkOption.TelehealthAcademic:
    case BenchmarkOption.InPersonAcademic:
      return false;
  }
}
