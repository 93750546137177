import {ElementRef, Injectable, InjectionToken} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AlertDialogComponent} from '../alert-dialog/alert-dialog.component';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {getAppConfigValue} from '../shared/helpers';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {HttpClient} from '@angular/common/http';
import {isNumeric} from 'rxjs/internal-compatibility';

export const AlertsServiceToken = new InjectionToken<HttpAlertsService>('AlertsService');

export interface AlertsService {
  getCount$(): BehaviorSubject<number>;
  initCount(): void;
  handleMessage(event: MessageEvent): void;
  openDialog(relativeElement: ElementRef<HTMLElement>): void;
  closeDialog(): void;
}

@Injectable({
  providedIn: 'root'
})
export class HttpAlertsService implements AlertsService {
  count$ = new BehaviorSubject(0);
  httpCancel$ = new Subject<boolean>();
  dialogRef: MatDialogRef<AlertDialogComponent>;
  notificationsAlertCountUrl: string;
  alertsModalUrl: string;

  getCount$(): BehaviorSubject<number> { return this.count$; }

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private readonly ngRedux: NgRedux<IAppState>,
  ) {
    const state = this.ngRedux.getState();
    this.notificationsAlertCountUrl = getAppConfigValue(AppConfigEntries.NOTIFICATION_ALERT_COUNT_URL,
      state.data.applicationConfigurationSettings);
    this.alertsModalUrl = getAppConfigValue(AppConfigEntries.NOTIFICATION_ALERT_URL,
      state.data.applicationConfigurationSettings);
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  initCount() {
    const user = this.ngRedux.getState().data.userProfile;
    if (user && user.email) {
      const settings = this.ngRedux.getState().data.applicationConfigurationSettings;
      this.notificationsAlertCountUrl = getAppConfigValue(AppConfigEntries.NOTIFICATION_ALERT_COUNT_URL, settings);
      const alertCountUrl = this.notificationsAlertCountUrl.replace(/\/[^/?]*\?/gi, '/' + user.email + '?');
      const xmlReq = new XMLHttpRequest();
      xmlReq.addEventListener('load', () => {
        if (xmlReq.responseText && isNumeric(xmlReq.responseText)) {
          this.count$.next(Number(xmlReq.responseText));
        } else {
          console.log('Alert Service returned error. Check if notificationAlertCountUrl is correctly set in App Config table.');
        }
      });
      xmlReq.addEventListener('error', (e) => {
        console.log('Alert Service returned error. Check if notificationAlertCountUrl is ' +
          `correctly set in App Config table. [${JSON.stringify(e)}].`);
      });
      xmlReq.open('GET', alertCountUrl);
      xmlReq.send();
    }
  }

  handleMessage(event: MessageEvent): void {
    const {data} = event;
    // if (this.alertsModalUrl.includes(origin) && data && typeof data.action === 'string') {
    if (data && typeof data.action === 'string') {
      this.httpCancel$.next(true);
      switch (data.action) {
        case 'update':
          if (typeof data.value === 'number') {
            this.count$.next(data.value);
          }
          break;
        case 'closeContainer':
          this.closeDialog();
          break;
        default:
          break;
      }
    }
  }

  openDialog(relativeElement: ElementRef<HTMLElement>): void {
    this.dialogRef = this.dialog.open(AlertDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      height: '440px',
      width: '440px',
      data: {positionRelativeToElement: relativeElement}
    });
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
