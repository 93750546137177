<div id="denials-graph-wrapper">
  <div id="chart-heading">
    <span class="denials-title-wrapper">
      <h2 class="graph-title">Denial Rate {{level}}</h2>
    </span>
    <span class="right-header-wrapper">
      <app-variable-container
        [variables]="variables"
        [showVariableMenu]="showVariableMenu"
        [page]="page"
        [disabled]="activeVarianceToggle"
         ></app-variable-container>
      <app-variance-toggler  [varianceToggleAction]="varianceToggle">
      </app-variance-toggler>
      </span>
  </div>
  <div [chart]="chartObject" id="denials-graph"></div>
  <app-chart-legend [legends]="legends"
                    [hasBenchmarks]="hasBenchmarks"
                    [defaultBenchmarkPercentile]="defaultBenchmarkPercentile"
                    [benchmarksToExclude]="benchmarkToExclude">
  </app-chart-legend>
  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
</div>
