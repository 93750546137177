import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.local';
import {FilterCriteria, OntologyData} from '../store/IAppState';
import {OntologyListEntity} from '../updated-filter-banner/FilterBannerModels';
import {getRequestBodyFromFilter} from '../shared/helpers';

export const OntologyHierarchyServiceToken =
  new InjectionToken<OntologyHierarchyService>('Ontology Hierarchy Service');

export interface OntologyHierarchyService {
  getOntologyHierarchies(filter: FilterCriteria): Observable<OntologyData>;
  getOntologyList(filter: FilterCriteria): Observable<OntologyListEntity[]>;
}

@Injectable()
export class OntologyHierarchyServiceImpl implements OntologyHierarchyService {

  constructor(private readonly httpClient: HttpClient) { }

  getOntologyHierarchies(filter: FilterCriteria): Observable<OntologyData> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/ontologyHierarchyData`;
    return this.httpClient.post<OntologyData>(
      url,
      requestBody,
      { params: new HttpParams().set('memberKey', filter.memberKey.toString()) },
    );
  }

  getOntologyList(filter: FilterCriteria): Observable<OntologyListEntity[]> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/ontologyList`;
    return this.httpClient.post<OntologyListEntity[]>(
      url,
      requestBody,
      { params: new HttpParams().set('memberKey', filter.memberKey.toString()) },
    );
  }
}
