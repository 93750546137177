import {Component, Input, OnChanges} from '@angular/core';
import {MetricType} from '../../../shared/metric-types';
import {CptCategories, EmDimension, EvaluationManagementMultilevel} from '../../../shared/models';
import {BenchmarkOption} from '../../../shared/enums';

@Component({
  selector: 'app-evaluation-management-tile-batch-export',
  templateUrl: './evaluation-management-tile-batch-export.component.html',
  styleUrls: ['./evaluation-management-tile-batch-export.component.scss']
})
export class EvaluationManagementTileBatchExportComponent implements OnChanges {

  @Input() nodePath: string;
  @Input() nodeName: string;
  @Input() selectedDateRange: string;
  @Input() batchMultilevelEMData: EvaluationManagementMultilevel | undefined = undefined;
  @Input() batchMultilevelEMSpecialtySummaryData: EvaluationManagementMultilevel | undefined = undefined;
  @Input() emMetrics: EmDimension[] = [];
  @Input() benchmarkOption: BenchmarkOption = BenchmarkOption.Academic;
  title = 'Evaluation & Management';
  showOutpatient = false;
  showInpatient = false;
  showEmergencyMedicine = false;
  showOphthalmology = false;
  totalCptCounts: CptCategories;
  splCptCounts: CptCategories;
  metricType = MetricType.EandM;

  constructor() { }

  ngOnChanges(): void {
    this.showOutpatient = false;
    this.showInpatient = false;
    this.showEmergencyMedicine = false;
    this.showOphthalmology = false;
    this.emMetrics.forEach(metric => {
      switch (metric) {
        case EmDimension.Outpatient:
          this.showOutpatient = true;
          break;
        case EmDimension.InPatient:
          this.showInpatient = true;
          break;
        case EmDimension.EmergencyMedicine:
          this.showEmergencyMedicine = true;
          break;
        case EmDimension.Ophthalmology:
          this.showOphthalmology = true;
          break;
      }
    });
    if (this.batchMultilevelEMData) {
      this.totalCptCounts = this.batchMultilevelEMData.evaluationManagementData
        .providerEvaluationManagementSnapshotData.totals;
    }
    if (this.batchMultilevelEMSpecialtySummaryData) {
      this.splCptCounts = this.batchMultilevelEMSpecialtySummaryData.evaluationManagementData
        .providerEvaluationManagementSnapshotData.totals;
    }
  }
}
