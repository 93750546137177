import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {
  CollectionsByPayerGroupedByLevel,
  CollectionsData,
  MatchedCollectionsByMultiLevelByNodePath,
  PayerByMultiLevelCollectionsByNodePath,
  PayerCollections,
  PayerDrilldownCriteria,
  PayerMatchedCollectionsMultiLevelNodePathData
} from '../../Collection';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {getLevel, LevelType, Page} from '../../../shared/helpers';
import {
  AppAction,
  collectionsChargeDisplayedChangedTo,
  expectedPaymentsDisplayedChangedTo,
  filterCriteriaChangedTo,
  multiLevelByNodePathPayerCollectionsColumnsChangedTo,
  multilevelPayerCollectionsChangedTo,
  payerByLevelCollectionsPayerColumnsChangedTo,
  payerCollectionsDataChangedTo,
  snapshotPayerCollectionsColumnsChangedTo
} from '../../../store/actions';
import {
  columnsForMultiLevelPayerCollectionsDepartment,
  columnsForMultiLevelPayerCollectionsProvider,
  columnsForMultiLevelPayerCollectionsSpecialty,
  columnsForPayerByMultiLevelCollections,
  columnsForPayerCollections,
  DataTableColumns
} from '../../../shared/data-table-columns';
import * as _ from 'lodash';
import {ColumnType, MultilevelTabCollections, PayerType, SortingOrder} from '../../../shared/enums';
import {Router} from '@angular/router';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {
  createCollectionsVarianceColumnFor,
  createNCRBenchmarkColumnFor,
  createPayerNCRBenchmarkColumnFor
} from '../../../shared/BenchmarkColumns';
import {DisplayField, Variable} from '../../../variable-container/variable-container.component';
import {SortingCriterion} from '../../../shared/models';

@Component({
  selector: 'app-payer-collections-multilevel',
  templateUrl: './payer-collections-multilevel.component.html',
  styleUrls: ['./payer-collections-multilevel.component.scss']
})
export class PayerCollectionsMultilevelComponent implements OnInit, OnChanges, OnDestroy {

  @select(['data', 'payerCollectionsData', 'payerMatchedCollections'])
  private readonly payerCollectionsData$: Observable<PayerCollections[]>;

  @select(['data', 'matchedCollectionsByPayerByMultiLevelByNodePathData'])
  private readonly payerByMultiLevelCollectionsData$: Observable<PayerMatchedCollectionsMultiLevelNodePathData>;

  @select(['display', 'payerByLevelCollectionsPayerColumns'])
  private readonly payerByLevelPayerColumns$: Observable<BaseColumn[]>;

  @select(['display', 'multiLevelByNodePathPayerCollectionsColumns'])
  private readonly multiLevelByNodePathPayerCollectionsColumns$: Observable<BaseColumn[]>;

  @select(['display', 'payerByLevelCollectionsStaticColumns'])
  private readonly payerByLevelStaticColumns$: Observable<BaseColumn[]>;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['display', 'displayedPayerCollectionsColumns'])
  private readonly displayedPayerCollectionsColumns$: Observable<BaseColumn[]>;

  @select(['display', 'zeroSuppression'])
  private readonly zeroSuppression$: Observable<boolean>;

  subscription: Subscription;
  collectionsData: CollectionsData[];
  payerCollectionsData: PayerCollections[] = [];
  payerByMultiLevelCollectionsData: PayerMatchedCollectionsMultiLevelNodePathData = {
    departmentCollections: [], specialtyCollections: [], providerCollections: []
  };
  departmentPayerCollectionsData: MatchedCollectionsByMultiLevelByNodePath[] = [];
  specialtyPayerCollectionsData: MatchedCollectionsByMultiLevelByNodePath[] = [];
  providerPayerCollectionsData: MatchedCollectionsByMultiLevelByNodePath [] = [];
  level: string;
  defaultSortColumn = 'netCollectionRate';
  isProvider = false;
  showProgressBar: boolean;
  showProgressBarMultiLevel: boolean;
  columnHeaders: BaseColumn[];
  viewCommunityBenchmarks: boolean;
  defaultBenchmarkPercentile = BenchmarkPercentile.Percentile50th;
  benchmarkPercentile: BenchmarkPercentile = this.defaultBenchmarkPercentile;
  currentPage = Page.CollectionsByPayer;
  payerLevel: string;
  hasPayers = false;
  isZeroSuppressed = false;
  howManyDepartmentsSuppressed = 0;
  howManySpecialtiesSuppressed = 0;
  howManyProvidersSuppressed = 0;
  readonly zeroSuppressTooltip = 'reported collections data';
  snapshotPayerCollectionsColumnsChangedTo = snapshotPayerCollectionsColumnsChangedTo;
  openWindowColumns: DataTableColumns[] = [];
  scrollableColumns: DataTableColumns[] = [];
  scrollableMultiLevelColumns: DataTableColumns[] = [];
  payerStaticColumns: BaseColumn[];
  payerByLevelPayerColumns: BaseColumn[];
  providerColumns = _.cloneDeep(columnsForMultiLevelPayerCollectionsProvider);
  displayedProviderColumns: DataTableColumns[] = [];
  specialtyColumns = _.cloneDeep(columnsForMultiLevelPayerCollectionsSpecialty);
  displayedSpecialtyColumns: DataTableColumns[] = [];
  departmentColumns = _.cloneDeep(columnsForMultiLevelPayerCollectionsDepartment);
  displayedDepartmentColumns: DataTableColumns[] = [];
  columnSubscription: Subscription;
  chosenTab: MultilevelTabCollections;
  MultilevelTabCollections = MultilevelTabCollections;
  originalByLevelColumns: DataTableColumns[] = [];
  nodePath: string;

  varianceToggleSortingCriterion: SortingCriterion;
  varianceKey = '';
  sortDirection = 'desc';
  sortingDataAccessor: any;

  displayedByLevelColumns: DataTableColumns[] = [];

  variables: Variable[] = [
    {
      name: 'Charges',
      display: false,
      reducerField: DisplayField.CollectionsCharge,
      dispatchAction(display: boolean): AppAction {
        return collectionsChargeDisplayedChangedTo(display);
      }
    },
    {
      name: 'Expected Payments',
      display: false,
      reducerField: DisplayField.ExpectedPayments,
      dispatchAction(display: boolean): AppAction {
        return expectedPaymentsDisplayedChangedTo(display);
      }
    }
  ];
  tabLevel: LevelType = LevelType.none;
  columnsMultiLevel: any[] = columnsForPayerByMultiLevelCollections.slice();
  columns: any[] = columnsForPayerCollections.slice();
  displayedColumns: any[] = columnsForPayerCollections.slice().filter(col => col.isDefault || col.primaryColumn);
  reducerAction: (multiLevelByNodePathPayerCollectionsColumns: BaseColumn[]) => AppAction =
    multiLevelByNodePathPayerCollectionsColumnsChangedTo;
  @Output() levelChange = new EventEmitter();
  @Input() isMultiLevel = true;
  @Input() activeVarianceToggle: boolean;

  @Input() collectionsSortingCriteria: SortingCriterion | undefined = undefined;

  static isAPayerByMultilevelZeroSuppressible(entry: PayerByMultiLevelCollectionsByNodePath): boolean {
    return entry.chargeAmount === 0 && entry.primaryAndOtherPayerContractuals === 0 && entry.charityCareAmount === 0
      && entry.discountAmount === 0 && entry.expectedPayments === 0 && entry.primaryAndOtherInsurancePayments === 0 &&
      entry.patientPaymentAmount === 0 && entry.totalPayments === 0 && entry.controllableAllowance === 0 &&
      entry.smallBalanceWriteOffs === 0 && entry.patientRelatedBadDebt === 0 && entry.outstandingAR === 0;
  }

  constructor(private ngRedux: NgRedux<IAppState>, private _changeDetectorRef: ChangeDetectorRef, private router: Router,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.ngRedux.dispatch(payerByLevelCollectionsPayerColumnsChangedTo([]));
    this.subscription = combineLatest([
      this.payerCollectionsData$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.payerByMultiLevelCollectionsData$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.payerByLevelPayerColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.payerByLevelStaticColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.benchmarkPercentile$,
      this.zeroSuppression$,
      this.displayedPayerCollectionsColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
    ]).subscribe(
      ([payerCollectionsData, payerByMultiLevelCollectionsData, payerByLevelPayerColumns,
         payerByLevelStaticColumns, benchmark, zeroSuppression, displayedPayerCollectionsColumns]:
         [PayerCollections[], PayerMatchedCollectionsMultiLevelNodePathData,
           BaseColumn[], BaseColumn[], BenchmarkPercentile, boolean, BaseColumn[]]) => {
        this.isZeroSuppressed = zeroSuppression;
        this.benchmarkPercentile = benchmark;
        const payerType = this.ngRedux.getState().filters.payerType;
        switch (payerType) {
          case PayerType.ALL:
            this.payerLevel = 'Payer Category';
            break;
          case PayerType.CATEGORY:
            this.payerLevel = 'National Payer';
            break;
          case PayerType.NATIONAL:
          case PayerType.MEMBER:
          default:
            this.payerLevel = 'Member Payer';
            break;
        }
        this.payerByLevelPayerColumns = payerByLevelPayerColumns;

        this.payerCollectionsData = payerCollectionsData;

        this.providerColumns[7] = createNCRBenchmarkColumnFor(benchmark);
        this.providerColumns[8] = createCollectionsVarianceColumnFor(benchmark);
        this.specialtyColumns[6] = createNCRBenchmarkColumnFor(benchmark);
        this.specialtyColumns[7] = createCollectionsVarianceColumnFor(benchmark);
        this.departmentColumns[5] = createNCRBenchmarkColumnFor(benchmark);
        this.departmentColumns[6] = createCollectionsVarianceColumnFor(benchmark);

        this.setUpPayerByCollectionsByLevelData(payerByMultiLevelCollectionsData);

        this.originalByLevelColumns = columnsForPayerByMultiLevelCollections.slice();
        this.showProgressBar = this.getShowProgressBarStatus();
        this.showProgressBarMultiLevel = this.getShowProgressBarStatusMultiLevel();

        const state = this.ngRedux.getState();
        this.nodePath = state.filters.nodePath;
        const isCustom: boolean = state.display.isCustom;
        const multipleProviders = state.display.multipleProviders;
        const multipleSpecialties = state.display.multipleSpecialties;
        const multipleDepartments = state.display.multipleDepartments;

        this.level = getLevel(isCustom, this.nodePath, multipleProviders, multipleSpecialties, multipleDepartments);
        this.isProvider = state.display.isProviderSelected;
        this.columns[15] = createPayerNCRBenchmarkColumnFor(benchmark);
        if (benchmark === BenchmarkPercentile.Mean) {
          benchmark = BenchmarkPercentile.Percentile50th;
        }
        this.columns[16] = createCollectionsVarianceColumnFor(benchmark);
        this.columns = this.columns.slice();
        this.updateColumnFilter(displayedPayerCollectionsColumns);
        switch (this.chosenTab) {
          case MultilevelTabCollections.BY_PROVIDER:
            this.columnsMultiLevel = _.cloneDeep(this.providerColumns);
            break;
          case MultilevelTabCollections.BY_SPECIALTY:
            this.columnsMultiLevel = _.cloneDeep(this.specialtyColumns);
            break;
          case MultilevelTabCollections.BY_DEPARTMENT:
            this.columnsMultiLevel = _.cloneDeep(this.departmentColumns);
            break;
        }


        this.displayedDepartmentColumns = this.departmentColumns.slice();
        this.displayedSpecialtyColumns = this.specialtyColumns.slice();
        this.displayedProviderColumns = this.providerColumns.slice();

        [this.departmentColumns, this.specialtyColumns, this.providerColumns].forEach(multiLevelCol => {
          multiLevelCol[0].primaryColumn = true;
          createNCRBenchmarkColumnFor(this.benchmarkPercentile);
        });

        this.originalByLevelColumns[5] = createNCRBenchmarkColumnFor(benchmark);
        this.originalByLevelColumns[6] = createCollectionsVarianceColumnFor(benchmark);
        this.payerStaticColumns = payerByLevelStaticColumns;
        const staticColumnDefs = this.payerStaticColumns.map(col => this.getUpdatedColumnDef(col, this.benchmarkPercentile));

        this.getMultiLevelPayerScrollableColumns();

        const staticColumns: DataTableColumns[] = this.originalByLevelColumns
          .filter(ogColumn => ogColumn.primaryColumn || staticColumnDefs.includes(ogColumn.columnDef));

        const primaryColumn = staticColumns.find(col => col.primaryColumn);
        if (this.level && primaryColumn) {
          primaryColumn.header = this.level.split(' ')[1];
        }

        this.displayedByLevelColumns = [...staticColumns];
        if (this.displayedByLevelColumns.length > 7) {
          this.hasPayers = true;
        }
        this.ngOnChanges();
      });

    this.columnSubscription = this.multiLevelByNodePathPayerCollectionsColumns$
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(displayedColumns => {
        this.displayedDepartmentColumns =
          this.updateMultiLevelColumnFilter(displayedColumns, this.departmentColumns);
        this.displayedSpecialtyColumns =
          this.updateMultiLevelColumnFilter(displayedColumns, this.specialtyColumns);
        displayedColumns = displayedColumns.concat(
          this.providerColumns.filter(col => col.columnType === ColumnType.OPEN_WINDOW));
        this.displayedProviderColumns =
          this.updateMultiLevelColumnFilter(displayedColumns, this.providerColumns);
      });

    switch (this.ngRedux.getState().display.collectionsChosenTab) {
      case MultilevelTabCollections.BY_DEPARTMENT:
        this.chosenTab = MultilevelTabCollections.BY_DEPARTMENT;
        break;
      case MultilevelTabCollections.BY_SPECIALTY:
        this.chosenTab = MultilevelTabCollections.BY_SPECIALTY;
        break;
      case MultilevelTabCollections.BY_PROVIDER:
        this.chosenTab = MultilevelTabCollections.BY_PROVIDER;
        break;
      default:
        this.chosenTab = MultilevelTabCollections.BY_DEPARTMENT;
        break;
    }
  }

  ngOnChanges() {
    this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      return (sortHeaderId === 'netCollectionRate') ? data[this.defaultSortColumn] : data[sortHeaderId];
    };
    const varianceColumn = this.columns.find((col: BaseColumn) => col.columnType === ColumnType.VARIANCE);
    this.varianceKey = varianceColumn ? varianceColumn.columnDef : 'varianceMean';
    const collectionsSortColumn = 'netCollectionRate';
    if (this.collectionsSortingCriteria && this.collectionsSortingCriteria.columnDef && this.collectionsSortingCriteria.sortingOrder) {
      const requiredSortingCriteria: SortingCriterion = this.collectionsSortingCriteria.columnType === ColumnType.VARIANCE ? {
        ...this.collectionsSortingCriteria,
        columnDef: this.varianceKey
      } : this.collectionsSortingCriteria;
      this.defaultSortColumn = requiredSortingCriteria.columnDef;
      this.sortDirection = this.collectionsSortingCriteria.sortingOrder === SortingOrder.DESCENDING ? 'desc' : 'asc';
    } else {
      this.defaultSortColumn = collectionsSortColumn;
      this.sortDirection = 'desc';
    }
    if (!this.activeVarianceToggle) {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: this.varianceKey,
        columnType: ColumnType.VARIANCE
      };
    } else {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: collectionsSortColumn
      };
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.columnSubscription?.unsubscribe();
    this._changeDetectorRef.detach();
  }

  private setUpPayerByCollectionsByLevelData(payerByMultiLevelCollectionsData: PayerMatchedCollectionsMultiLevelNodePathData): void {
    this.payerByMultiLevelCollectionsData = payerByMultiLevelCollectionsData;
    this.departmentPayerCollectionsData = this.isZeroSuppressed ? payerByMultiLevelCollectionsData.departmentCollections
        .filter(entry => !PayerCollectionsMultilevelComponent.isAPayerByMultilevelZeroSuppressible(entry)).slice() :
      payerByMultiLevelCollectionsData.departmentCollections.slice();
    this.specialtyPayerCollectionsData = this.isZeroSuppressed ? payerByMultiLevelCollectionsData.specialtyCollections
        .filter(entry => !PayerCollectionsMultilevelComponent.isAPayerByMultilevelZeroSuppressible(entry)).slice() :
      payerByMultiLevelCollectionsData.specialtyCollections.slice();
    this.providerPayerCollectionsData = this.isZeroSuppressed ? payerByMultiLevelCollectionsData.providerCollections
        .filter(entry => !PayerCollectionsMultilevelComponent.isAPayerByMultilevelZeroSuppressible(entry)).slice() :
      payerByMultiLevelCollectionsData.providerCollections.slice();
    this.howManyDepartmentsSuppressed = payerByMultiLevelCollectionsData.departmentCollections.length -
      this.departmentPayerCollectionsData.length;
    this.howManySpecialtiesSuppressed = payerByMultiLevelCollectionsData.specialtyCollections.length -
      this.specialtyPayerCollectionsData.length;
    this.howManyProvidersSuppressed = payerByMultiLevelCollectionsData.providerCollections.length -
      this.providerPayerCollectionsData.length;
  }

  getMultiLevelPayerScrollableColumns(): void {

    let payerColumns: DataTableColumns[] = this.payerByLevelPayerColumns.map(payerColumn => ({
      columnDef: payerColumn.columnDef,
      header: payerColumn.header,
      dataName: (row: CollectionsByPayerGroupedByLevel) => `${row[payerColumn.columnDef] || '-'}`,
      columnType: ColumnType.PAYER
    }));

    payerColumns = payerColumns.filter(col => col.columnDef !== '');
    if (payerColumns.length >= 1) {
      payerColumns[0].group = 'group-first';
    }

    // @ts-ignore
    const staticColumnDefs = this.payerStaticColumns.map(col => this.getUpdatedColumnDef(col, this.benchmarkPercentile));

    const staticColumns: DataTableColumns[] = this.originalByLevelColumns
      .filter(ogColumn => ogColumn.primaryColumn || staticColumnDefs.includes(ogColumn.columnDef));

    const primaryColumn = staticColumns.find(col => col.primaryColumn);
    if (this.level && primaryColumn) {
      primaryColumn.header = this.level.split(' ')[1];
    }

    this.scrollableMultiLevelColumns = payerColumns;

    this.openWindowColumns = [{
      columnDef: 'viewDenials',
      header: '',
      dataName: () => 'View Denials',
      isDefault: true,
      class: () => 'viewDenials',
      showDenials: true,
      columnType: ColumnType.OPEN_WINDOW,
      nodePath: (row: PayerByMultiLevelCollectionsByNodePath) => this.calculateDenialsNodePath(row, this.chosenTab),
      nodeName: (row: PayerByMultiLevelCollectionsByNodePath) => this.chosenTab ===
      MultilevelTabCollections.BY_PROVIDER ? row.departmentNodeName + ', ' + row.specialtyNodeName + ', ' +
        row.providerNodeName : row.departmentNodeName + ', ' + row.specialtyNodeName
    }];
  }

  calculateDenialsNodePath(row: PayerByMultiLevelCollectionsByNodePath, chosenTab: MultilevelTabCollections): string {
    let result;
    if (chosenTab === MultilevelTabCollections.BY_PROVIDER || chosenTab === MultilevelTabCollections.BY_PAYER_BY_PROVIDER) {
      result = row.providerNodePath;
    } else if (chosenTab === MultilevelTabCollections.BY_SPECIALTY || chosenTab === MultilevelTabCollections.BY_PAYER_BY_SPECIALTY) {
      result = this.getNodePathCompatibleWithFilterCriteria(row.specialtyNodePath, this.nodePath);
    } else {
      result = this.getNodePathCompatibleWithFilterCriteria(row.departmentNodePath, this.nodePath);
    }
    return result;
  }

  // Filter Node Path:
  //  1. Single Department / Multiple Departments
  //  2. Single Specialty / Multiple Specialties
  //  3. Single Provider / Multiple providers
  private getNodePathCompatibleWithFilterCriteria(originalNodePath: string, filtersNodePath: string): string {
    const compatibleNodePaths = filtersNodePath.split('|').filter(path =>
      path.includes(originalNodePath));
    return compatibleNodePaths.length === 0 ? originalNodePath : compatibleNodePaths.join('|');
  }

  getUpdatedColumnDef(displayedColumn: BaseColumn, benchmarkPercentile: BenchmarkPercentile): string {
    switch (displayedColumn.columnType) {
      case ColumnType.BENCHMARK:
        return createNCRBenchmarkColumnFor(benchmarkPercentile).columnDef;
      case ColumnType.VARIANCE:
        return createCollectionsVarianceColumnFor(benchmarkPercentile).columnDef;
      default:
        return displayedColumn.columnDef;
    }
  }

  updateMultiLevelColumnFilter(displayedColumns: BaseColumn[], tableColumns: DataTableColumns[]) {
    const displayedColumnDefs: string[] = displayedColumns.map(col => {
      switch (col.columnType) {
        case ColumnType.BENCHMARK:
          return createNCRBenchmarkColumnFor(this.benchmarkPercentile).columnDef;
        case ColumnType.VARIANCE:
          return createCollectionsVarianceColumnFor(this.benchmarkPercentile).columnDef;
        default:
          return col.columnDef;
      }
    });


    return tableColumns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef)).slice();
  }

  getShowProgressBarStatus(): boolean {
    return _.isEqual(this.payerCollectionsData, INITIAL_STATE.data.payerCollectionsData.payerMatchedCollections);
  }

  getShowProgressBarStatusMultiLevel(): boolean {
    return _.isEqual(this.payerByMultiLevelCollectionsData,
      INITIAL_STATE.data.matchedCollectionsByPayerByMultiLevelByNodePathData);
  }

  whenSortChanged = (payerCollectionsData: any) => {
    this.ngRedux.dispatch(payerCollectionsDataChangedTo({payerMatchedCollections: payerCollectionsData}));
  };

  payerSelectionCallBack = (payerDrilldownCriteria: PayerDrilldownCriteria) => {
    const originalFilters = this.ngRedux.getState().filters;
    const payerType = originalFilters.payerType;
    const criteriaType = payerDrilldownCriteria.type;
    if (payerType !== criteriaType) {
      this.ngRedux.dispatch(filterCriteriaChangedTo({
        ...originalFilters,
        payerCategory: payerDrilldownCriteria.category,
        payerType: payerDrilldownCriteria.type,
        payerKey: payerDrilldownCriteria.key,
        nationalPayerDescription: criteriaType === PayerType.NATIONAL ? payerDrilldownCriteria.description
          : originalFilters.nationalPayerDescription,
        nationalPayerKey: criteriaType === PayerType.NATIONAL ? payerDrilldownCriteria.key
          : originalFilters.nationalPayerKey,
        memberPayerDescription: criteriaType === PayerType.MEMBER ? payerDrilldownCriteria.description
          : originalFilters.memberPayerDescription,
        memberPayerKey: criteriaType === PayerType.MEMBER ? payerDrilldownCriteria.key
          : originalFilters.memberPayerKey, chosenByUser: true
      }));
    }
  };

  chooseTab(tab: MultilevelTabCollections): void {
    if (this.chosenTab !== tab) {
      this.ngRedux.dispatch(payerByLevelCollectionsPayerColumnsChangedTo([]));
    }
    this.chosenTab = tab;
    this._changeDetectorRef.detectChanges();
  }

  updateColumnFilter(displayedColumns: BaseColumn[]) {
    const displayedColumnDefs: string[] = displayedColumns.map(col => this.getUpdatedColumnDef(col, this.benchmarkPercentile));

    this.displayedColumns = this.columns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef));
  }
}
