import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RelatableSelectableItem, ScenarioForDisplayability, UpdatedFilterTab} from '../FilterBannerModels';
import {MemberLocation} from '../../shared/models';
import {determineAllSelected, isItemAbleToShow} from '../UpdatedFilterBannerHelpers';
import {DEFAULT_MEMBER_LOCATION} from '../../store/DefaultValues';

@Component({
  selector: 'app-multi-search-location',
  templateUrl: './multi-search-location.component.html',
  styleUrls: ['./multi-search-location.component.scss']
})
export class MultiSearchLocationComponent implements OnInit {

  @Input() locations: RelatableSelectableItem<MemberLocation>[];
  @Input() onLocationChanged: (tab?: UpdatedFilterTab, item?: RelatableSelectableItem<MemberLocation>) => void;
  @Input() showLoadingIndicator = true;
  @Input() filterTab: UpdatedFilterTab;
  allSelected = true;
  displayedLocations: RelatableSelectableItem<MemberLocation>[] = [];
  readonly DEFAULT_MEMBER_LOCATION = DEFAULT_MEMBER_LOCATION;
  searchText = '';

  constructor(protected cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.showLocations();
    this.allSelected = determineAllSelected(this.locations || []);
  }

  private showLocations(): void {
    this.displayedLocations = this.locations?.filter(l => isItemAbleToShow(l, ScenarioForDisplayability.Standard));
  }

  onSearchTextChanged(): void {
    this.locations.forEach(location => {
      location.item.matchesSearchText = this.searchText.length < 3 ||
        !!location.item.displayText?.toLowerCase().includes(this.searchText.toLowerCase());
    });
    this.showLocations();
  }

  selectAllLocations(event: MouseEvent): void {
    event.preventDefault();
    if (!this.allSelected) {
      let wasSelected: boolean;
      this.locations.forEach(location => {
        wasSelected = location.item.selected;
        location.item.selected = false;
        if (this.filterTab.newSelections && wasSelected) {
          if (location.item.originallySelected) {
            this.filterTab.newSelections.deselections++;
          } else {
            this.filterTab.newSelections.selections--;
          }
        }
      });
      this.allSelected = true;
      this.onLocationChanged(this.filterTab);
    }
  }

  whenLocationClicked(event: MouseEvent, location: RelatableSelectableItem<MemberLocation>): void {
    event.preventDefault();
    const shouldBeSelected = !location.item.selected;
    const wasSelected = location.item.selected;
    location.item.selected = shouldBeSelected;
    if (shouldBeSelected && this.filterTab.newSelections) {
      if (!location.item.originallySelected) {
        this.filterTab.newSelections.selections++;
      } else {
        this.filterTab.newSelections.deselections--;
      }
    } else if (!shouldBeSelected && this.filterTab.newSelections && wasSelected) {
      if (location.item.originallySelected) {
        this.filterTab.newSelections.deselections++;
      } else {
        this.filterTab.newSelections.selections--;
      }
    }
    this.allSelected = determineAllSelected(this.locations || []);
    this.onLocationChanged(this.filterTab, location);
    this.cdr.detectChanges();
  }
}
