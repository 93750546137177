import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../../store/IAppState';
import {BatchExportServiceToken, HttpBatchExportService} from '../../../services/batch-export.service';
import {BatchExportReportDetailsResponseEntity, DialogData} from '../../../../shared/models';
import {deleteReportTitle, ExportedReportsDialogColumn} from '../../../batch-export-helper';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {BatchExportStatus} from '../../../../shared/enums';
import {take} from 'rxjs/operators';
import {batchExportReportDetailsChangedTo} from '../../../../store/actions';
import {isEllipsisActive} from '../../../../shared/ui-helpers';

@Component({
  selector: 'app-redesigned-one-time-exports-table',
  templateUrl: './redesigned-one-time-exports-table.component.html',
  styleUrls: ['./redesigned-one-time-exports-table.component.scss']
})
export class RedesignedOneTimeExportsTableComponent implements OnInit, AfterViewChecked {

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              public dialog: MatDialog, private cdr: ChangeDetectorRef) {
  }

  @Input() dataSource: BatchExportReportDetailsResponseEntity[] = [];
  @Input() columns: ExportedReportsDialogColumn[] = [];
  @Input() stringColumnsForRowDef: string[] = [];
  @Output() downloadRecord = new EventEmitter<BatchExportReportDetailsResponseEntity>();

  private deleteDialogRef: MatDialogRef<ConfirmDialogComponent>;
  readonly BatchExportStatus = BatchExportStatus;
  readonly reportNameFlexRatio = 3;

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  delete(reportId: number) {
    let dialog = this.deleteDialogRef;
    const service = this.batchExportService;
    const data = this.dataSource;
    const redux = this.ngRedux;
    const row = data.find(e => e.reportId === reportId);
    const reportName = (row && row.reportName.trim()) ? '"' + row.reportName.trim() + '"' : '';
    const dialogData: DialogData = {
      question: `Are you sure you want to delete ${reportName}?`,
      title: deleteReportTitle,
      confirmButtonAltText: 'Yes',
      cancelText: 'No',
      buttonWrapperClass: 'mpe-reports-buttons',
      confirmCallback() {
        service.deleteBatchExportInformation(reportId).pipe(take(1)).subscribe(status => {
          const newData = data.filter(e => e.reportId !== reportId);
          redux.dispatch(batchExportReportDetailsChangedTo(newData));
          dialog.close();
        });
      }
    };
    this.deleteDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    dialog = this.deleteDialogRef;
  }

  canShowTooltip(refEl: HTMLElement) {
    return isEllipsisActive(refEl);
  }

}
