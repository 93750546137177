import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState, UserPreference} from '../../../../../store/IAppState';
import {monthNames} from '../../../../helpers';
import {MatDialogRef} from '@angular/material/dialog';
import {userPreferencesLoadedTo} from '../../../../../store/actions';
import {UserPreferenceService, UserPreferenceServiceToken} from '../../../../../services/user-preference.service';

@Component({
  selector: 'app-change-fiscal-year',
  templateUrl: './change-fiscal-year.component.html',
  styleUrls: ['./change-fiscal-year.component.scss']
})
export class ChangeFiscalYearComponent implements OnInit, OnDestroy {

  selectedFiscalStartMonth = 'January';
  selectedFiscalEndMonth = 'December';
  userSettings: UserPreference;
  monthNames = monthNames;
  showMonthNames = false;
  isDialogCancelled = true;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              private dialogRef: MatDialogRef<ChangeFiscalYearComponent>,
              @Inject(UserPreferenceServiceToken) private readonly userPreferenceService: UserPreferenceService) {
  }

  ngOnInit(): void {
    this.userSettings = this.ngRedux.getState().userPreferences;
    this.selectedFiscalStartMonth = monthNames[this.userSettings.fiscalStartMonth - 1];
    this.selectedFiscalEndMonth = monthNames[(this.userSettings.fiscalStartMonth + 10) % 12];
  }

  ngOnDestroy(): void {
    this.dialogRef.close({
      event: 1 + monthNames.indexOf(this.selectedFiscalStartMonth),
      isDialogCancelled: this.isDialogCancelled
    });
  }

  apply(): void {
    const newUserPreferences: UserPreference = {
      ...this.userSettings,
      fiscalStartMonth: 1 + monthNames.indexOf(this.selectedFiscalStartMonth)
    };

    this.userPreferenceService.saveUserPreferences(newUserPreferences)
      .subscribe((userSetting: UserPreference) => {
        this.ngRedux.dispatch(userPreferencesLoadedTo(userSetting));
        this.dialogRef.close();
        this.isDialogCancelled = false;
      });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  toggleShowMonthNames(): void {
    this.showMonthNames = !this.showMonthNames;
  }

  setFiscalMonth(event: MouseEvent, monthName: string): void {
    event.stopPropagation();
    this.selectedFiscalStartMonth = monthName;
    const monthIndex = monthNames.indexOf(monthName);
    this.selectedFiscalEndMonth = monthNames[(monthIndex + 11) % 12];
    this.showMonthNames = false;
  }
}
