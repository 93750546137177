import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ApplicationConfigurationSetting} from '../../shared/models';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {skipWhile} from 'rxjs/operators';
import {IAppState} from '../../store/IAppState';
import {
  ApplicationConfigurationServiceToken,
  HttpApplicationConfigurationService
} from '../../services/app-configuration.service';
import {MatDialog} from '@angular/material/dialog';
import {applicationConfigurationSettingsLoadedTo} from '../../store/actions';
import {showSuccessDialog} from '../control-panel-helper';

@Component({
  selector: 'app-app-config-control-panel',
  templateUrl: './app-config-control-panel.component.html',
  styleUrls: ['./app-config-control-panel.component.scss']
})
export class AppConfigControlPanelComponent implements OnInit, OnDestroy {
  appConfigDesc = 'appConfigDesc';
  appConfigName = 'appConfigName';
  appConfigDisplayedColumns: string[] = ['appConfigName', 'appConfigDescription'];
  appConfigDataSource: MatTableDataSource<ApplicationConfigurationSetting> =
    new MatTableDataSource<ApplicationConfigurationSetting>([]);
  @select(['data', 'applicationConfigurationSettings'])
  private readonly applicationConfigurationSettings$: Observable<ApplicationConfigurationSetting[]>;
  appConfigFormGroup: UntypedFormGroup;
  appConfigUpdateSubscription: Subscription;
  addNewConfigSubscription: Subscription;
  @ViewChild('formDirective') private formDirective: NgForm;
  subscription: Subscription;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(ApplicationConfigurationServiceToken)
              private readonly appConfigService: HttpApplicationConfigurationService,
              private fb: UntypedFormBuilder,
              public dialog: MatDialog) {
    this.initializeFormGroup();
  }

  ngOnDestroy(): void {
    this.appConfigUpdateSubscription?.unsubscribe();
    this.addNewConfigSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = combineLatest([this.applicationConfigurationSettings$]
    ).pipe(skipWhile(([applicationSettings]:
                        [ApplicationConfigurationSetting[]]) =>
      !applicationSettings?.length))
      .subscribe(([applicationSettings]) => {
        this.appConfigDataSource.data = applicationSettings;
        this.initializeFormGroup();
      });
  }

  onSubmitAppConfigClicked(): void {
    if (this.appConfigFormGroup.valid) {
      this.addNewAppConfigSettings();
      this.formDirective.resetForm();
      showSuccessDialog('App Config', this.dialog);
    } else {
      this.appConfigFormGroup.reset();
    }
  }


  addNewAppConfigSettings() {
    this.addNewConfigSubscription = this.appConfigService
      .addNewConfiguration(this.appConfigFormGroup.value).subscribe(res => {
        this.appConfigDataSource.data = [...this.appConfigDataSource.data, res];
        this.ngRedux.dispatch(applicationConfigurationSettingsLoadedTo(this.appConfigDataSource.data));
      });
  }

  onAppConfigInputBlur(appConfigSetting: ApplicationConfigurationSetting, inputVal: string) {
    const key = 'appConfigDesc';
    if ('' !== inputVal?.trim() && appConfigSetting[key] !== inputVal) {
      appConfigSetting[key] = inputVal;
      this.updateAppConfigSettings(appConfigSetting);
    }
  }

  private updateAppConfigSettings(appConfigSetting: ApplicationConfigurationSetting) {
    const dataSource = this.appConfigDataSource.data.find(ds => ds.appConfigId === appConfigSetting.appConfigId);
    if (!dataSource) {
      return;
    }
    this.appConfigUpdateSubscription = this.appConfigService.updateApplicationConfiguration(appConfigSetting).subscribe(res => {
      dataSource.appConfigName = appConfigSetting.appConfigName;
      dataSource.appConfigDesc = appConfigSetting.appConfigDesc;
      this.appConfigDataSource.data = [...this.appConfigDataSource.data];
      this.ngRedux.dispatch(applicationConfigurationSettingsLoadedTo(this.appConfigDataSource.data));
    });
  }

  forbiddenAppConfigNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let foundMatch = false;
      this.appConfigDataSource?.data?.forEach(d => {
        if (d.appConfigName.trim().toLowerCase() === control.value?.trim().toLowerCase()) {
          foundMatch = true;
        }
      });
      return foundMatch ? {forbiddenAppConfigName: {value: control.value}} : null;
    };
  }

  private initializeFormGroup() {
    this.appConfigFormGroup = this.fb.group(
      {
        appConfigName: [null, [Validators.required,
          this.forbiddenAppConfigNameValidator()]],
        appConfigDesc: [null, Validators.required]
      }, {updateOn: 'change'}
    );
  }
}
