import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() fromPdf: boolean;
  public copyRightYear: any;
  public windowWidth: number;

  ngOnInit() {
    this.copyRightYear = new Date().getFullYear();
    this.windowWidth = window.innerWidth;
  }
}
