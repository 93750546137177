<app-pdf-header *ngIf="data && !canHideEMExportOptions"
                [communityBenchmarkAlert]="communityBenchmarkAlert"
                [communityBenchmarkAlertText]="communityBenchmarkAlertText"
                [locationSelection]="locationSelection"
                [payerSelection]="payerSelection"
                [visitTypeSelection]="visitTypeSelection"
                [selectedDate]="selectedDate"
                [nodePathSelection]="nodePathSelection"
                [maxLocations]="maxLocations"
                [currentLocations]="currentLocations">
</app-pdf-header>
<div *ngIf="data && !canHideEMExportOptions" id="options-export-pdf">
  <div id="options-body">
    <div id="options-main-content">
      <div [ngSwitch]="currentView">
        <app-overview-page
          *ngSwitchCase="pdfExportView.OVERVIEW_WRVU_NPV"
          fromPdfExport="true"
          [includeWrvuNpvTile]="true">
        </app-overview-page>

        <app-overview-page
          *ngSwitchCase="pdfExportView.OVERVIEW_EM"
          fromPdfExport="true"
          [includeEMTile]="true"
          [emMetrics]="emDimensions">
        </app-overview-page>

        <app-overview-page
          *ngSwitchCase="pdfExportView.OVERVIEW_ZIP"
          fromPdfExport="true"
          [includeZipTile]="true">
        </app-overview-page>
      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="data && !canHideEMExportOptions && !content" id="pdf-footer" [fromPdf]="true"></app-footer>

<div *ngIf="data && hideEm" id="export-pdf">
  <app-header [isExport]="true"></app-header>
  <div class="border"></div>
  <div id="body">
    <div id="main-content">
      <div id="location" class="filter-data">Location: {{locationSelection.memberLocationName}}</div>
      <div id="payer" class="filter-data">Payer Category: {{payerSelection.payerCategoryDescription}}</div>
      <div id="pdf-daterange" class="filter-data">{{selectedDate}}</div>
      <div class="community-benchmark-alert" *ngIf="communityBenchmarkAlert">{{communityBenchmarkAlertText}}</div>
      <app-overview-page
        *ngIf="data.overview"
        fromPdfExport="true"
        [includeWrvuNpvTile]="true"
        [includeEMTile]="false"
        [includeZipTile]="true">
      </app-overview-page>
    </div>
  </div>
  <app-footer [fromPdf]="true"></app-footer>
</div>

<div *ngIf="data" id="empty-space"></div>
