<div class=tiles-wrapper>
  <app-overview-tile *ngIf="!fromPdfExport || includeWrvuNpvTile"
                     id="wRVUTrendOverview"
                     [title]="wrvuTitle"
                     [selectedDateRange]="selectedDateRange"
                     [previousDateRange]="previousSelectedDateRange"
                     [comparedTo]="wrvuComparedTo"
                     [legendData]="wrvuLegendData"
                     [overviewChartOptions]="wrvuTrendChartOptions"
                     [metricType]="wrvuMetricType"
                     [isWrvus]="true"
                     [showProgressBar]="wrvuShowProgressBar"
                     [varianceSubtractFrom]="wrvuVarianceSubtractFrom"
                     [varianceSubtract]="wrvuVarianceSubtract"
                     [variance]="wrvuVarianceText"
                     [varianceClass]="wrvuVarianceClass"
                     [previousYearDifference]="wrvuCfteText"
                     [previousYearDifferenceClass]="wrvuCfteClass"
                     [isCfteAdjusted]="isCfteAdjusted"
                     [fromPdfExport]="fromPdfExport"
                     [benchmarkLabel]="benchmarkPlaceholder"
                     [hasCommunityBenchmarks]="hasWrvuCommunityBenchmarks"
                     [hasAcademicBenchmarks]="hasWrvuAcademicBenchmarks"
                     [telehealthBenchmarksEnabled]="true"
  ></app-overview-tile>

  <app-overview-tile *ngIf="!fromPdfExport || includeWrvuNpvTile"
                     id="npvTrendOverview"
                     [title]="npvTitle"
                     [selectedDateRange]="selectedDateRange"
                     [previousDateRange]="previousSelectedDateRange"
                     [comparedTo]="npvComparedTo"
                     [legendData]="npvLegendData"
                     [overviewChartOptions]="npvTrendChartOptions"
                     [metricType]="npvMetricType"
                     [showProgressBar]="npvShowProgressBar"
                     [varianceSubtractFrom]="npvVarianceSubtractFrom"
                     [varianceSubtract]="npvVarianceSubtract"
                     [variance]="npvVarianceText"
                     [varianceClass]="npvVarianceClass"
                     [previousYearDifference]="npvPercentageText"
                     [previousYearDifferenceClass]="npvPercentageClass"
                     [fromPdfExport]="fromPdfExport"
                     [benchmarkLabel]="npvBenchmarkPlaceholder"
                     [hasCommunityBenchmarks]="hasNpvCommunityBenchmarks"
                     [hasAcademicBenchmarks]="hasNpvAcademicBenchmarks"
                     [telehealthBenchmarksEnabled]="true"
  ></app-overview-tile>

  <app-overview-tile *ngIf="!fromPdfExport || includeEMTile"
                     id="emOverview"
                     [ngClass]="fromPdfExport ? 'export-zip' : ''"
                     [title]="emTitle"
                     [selectedDateRange]="selectedDateRange"
                     [showEmOverviewGraph]="true"
                     [metricType]="emMetricType"
                     [fromPdfExport]="fromPdfExport"
                     [emMetrics]="emMetrics"
                     [telehealthBenchmarksEnabled]="true"
  ></app-overview-tile>

  <app-zip-code-overview-tile
    *ngIf="!fromPdfExport || includeZipTile"
    [fromPdfExport]="fromPdfExport"
    id="zipCodeTile"
  ></app-zip-code-overview-tile>
</div>
