<div class="data-table-container-wrapper" (mouseleave)="deactivateHoverMessages()">
  <div [ngClass]="tableCssClass? tableCssClass : 'data-table-container'">
    <div *ngIf="!showViewCpts" class="table-header-wrapper">
      <div *ngIf="tableTitle" class="summaryHeader">{{tableTitle}} {{multiLevel ? '' : level}}
        <div *ngIf="multiLevel" class="payer-multiLevel-collections-wrapper">
        <span class="payer-multiLevel-collections">
              <a id="byDepartmentTab" [ngClass]="{active: ![6, 7].includes(chosen)}" (click)="chooseTab(MultilevelTabCollections.BY_PAYER_BY_DEPARTMENT)">By Department&nbsp;&nbsp;&nbsp;</a>
              <a id="bySpecialtyTab" [ngClass]="{active: chosen === 6}" (click)="chooseTab(MultilevelTabCollections.BY_PAYER_BY_SPECIALTY)">By Specialty&nbsp;&nbsp;&nbsp;</a>
              <a id="byProviderTab" [ngClass]="{active: chosen === 7}" (click)="chooseTab(MultilevelTabCollections.BY_PAYER_BY_PROVIDER)">By Provider&nbsp;&nbsp;&nbsp;</a>
        </span>
        </div>
    </div>
      <span class="right-table-header">
        <app-zero-suppression-component
        *ngIf="displayZeroSuppression"
        [countOfSuppressedEntries]="countOfSuppressedEntries"
        [snapshotEntryName]="zeroSuppressionSnapshotEntry"
        [isZeroSuppressionChecked]="isZeroSuppressionChecked"
        [condition]="zeroSuppressionCondition"
        [multiLevelBreakdown]="chosen"
      >
      </app-zero-suppression-component>
      <app-payer-selector *ngIf="showPayerOptions" id="payer-selector"></app-payer-selector>
      <app-column-selection *ngIf="addRemoveOption"
                            [originalColumns]="originalColumns"
                            [displayedColumnDefs]="displayedColumnDefs"
                            [currentPage]="currentPage"
                            [reducerAction]="reducerAction"
                            [showBenchmarks]="canShowBenchmarks"
                            [showCfteAdjWrvus]="canShowCfteAdjWrvus"
      ></app-column-selection>
      </span>
    </div>
    <div class="payer-header-text-wrapper" *ngIf="scrollableColumns && showPayerOptions">
    <div class="payer-header-text">Net Collection Rate</div>
    </div>
    <div class="table-body-wrapper" id="{{tableCssClass? tableCssClass + '-container' : 'multiple-table-container'}}">
      <div *ngIf="showViewCpts" id="view-cpt-outer-wrapper">
        <div id="view-cpt-wrapper">
          <app-view-cpt [parentNodeNames]="parentNodeNames" [nodeName]="viewCptNodeName" [nodePath]="viewCptNodePath"
                        (closed)="deactivateCptDescription($event)"></app-view-cpt>
        </div>
      </div>
      <div cdkDrag *ngIf="showDenials" id="view-denials-outer-wrapper">
        <div id="view-denials-wrapper">
          <app-denials-modal [parentNodeNames]="parentNodeNames" [nodeName]="viewCptNodeName" [nodePath]="viewCptNodePath"
                             [row]="denialRow"
                        (closed)="deactivateDenialsModal($event)"></app-denials-modal>
        </div>
      </div>
      <div *ngIf="displayAboveHeaders" class="top-headers">
        <ng-container *ngFor="let column of dataTableDisplayedColumns" [matColumnDef]="column.columnDef">
            <span class="above-header {{column.columnDef}}" *ngIf="column.aboveHeaderText">
                              {{column.aboveHeaderText}}</span>
        </ng-container>
      </div>
      <mat-table [dataSource]="dataSource"
                 id="{{tableCssClass? tableCssClass + '-first-table' : 'first-table-in-container'}}"
                 [ngClass]="scrollableColumns.length >= 1 ? 'standard-table' : ''"
                 matSort
                 matSortDirection="{{sortDirection}}"
                 matSortActive="{{defaultSortColumn}}"
      >
        <ng-container
          *ngFor="let column of dataTableDisplayedColumns"
          [matColumnDef]="column.columnDef"
          [stickyEnd]="column.columnDef === 'viewCpt'">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                             id="{{column.columnDef}}"
                             class="{{column.columnDef}}Header
                                {{column.tornado && 'tornado'}}
                                {{column.group ? column.group + 'Header' : ''}}
                                {{column.primaryColumn ? 'primaryHeader' : ''}}
                                {{column.columnDef === defaultSortColumn && mandatoryOpaqueSortingArrow &&
                                 !(sort.direction === '') ? 'isWhatTableIsSortedBy' : ''}}"
                             (mouseenter)="toggleShowHoverMessage(true, column)"
                             (mouseleave)="toggleShowHoverMessage(false, column)"
            >
              <br/>
              <span class="header-text-wrapper">
                <span class="header-text">
                  {{column.header}}</span>
                <span id="date-range" *ngIf="column.showDates">{{previousDate}}</span>
              </span>
              <span class="hover-display" *ngIf="column.showHoverMessage">
                    {{column.hoverMessage}}<br/>
                  <a id="methodologyDocument-link" (mousedown)="openMethodologyDocument()">View More</a>
                  </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row"
                      class="{{column.columnDef}}
                      {{column.tornado ? 'tornado' : ''}}
                      {{column.group || ''}}
                      {{column.primaryColumn ? 'primaryCell' : ''}}
                      {{getCssClassForColumnType(column)}}">
              <ng-container *ngIf="!column.showCpt">
                <div class="{{column.class ? column.class(row) : 'data-text'}} {{column.primaryColumn && 'primaryText'}}"
                  *ngIf="!column.dataNameList">
                  {{ column.dataName(row) }}
                </div>
                <span *ngIf="column.dataNameList" class="data-name-list">
                  <div *ngFor="let item of column.dataNameList(row)" class="data-name-item"> {{item}} </div>
                </span>
                <span *ngIf="column.tornado"
                      [style.left.px]="row[column.tornado].positive ? 12 : 14 - row[column.tornado].borderWidth"
                      class="{{row[column.tornado].positive ? 'tornado-wrapper-positive' : 'tornado-wrapper-negative'}}">
                  <span *ngIf="row[column.tornado].positive" class="flagpole" style="margin-left: 2px;"></span>
                    <span class="tornado-bar" style="height: 24px; border-right: solid; opacity: 0.4;"
                          [style.border-right-width.px]="row[column.tornado].borderWidth"
                          [style.color]="row[column.tornado].color">
                    </span>
                  <span class="flagpole" *ngIf="!row[column.tornado].positive"></span>
                </span>
              </ng-container>
              <div *ngIf="column.showCpt" class="{{column.class}}"
                   (click)="activateCptDescription($event, column.nodePath(row), column.nodeName(row))">
                View CPT
              </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="dataTableDisplayedColumnDefs; sticky: tableCssClass==='wrvu-snapshot-table'" class="{{tableCssClass}}-header-row"></mat-header-row>
        <ng-container *ngIf="!fromPdfExport">
          <mat-row (click)="selectRow(row)" (mouseenter)="deactivateHoverMessages()" [class.hidden]="row.isHidden"
                   *matRowDef="let row; columns: dataTableDisplayedColumnDefs"
                   class="{{rowSelectionCallback ? 'selectable' : ''}}
                     {{row.cptActive ? 'cptActive' : ''}}">
          </mat-row>
        </ng-container>
        <ng-container *ngIf="fromPdfExport">
          <mat-row *matRowDef="let row; columns: dataTableDisplayedColumnDefs;" class="pdf-row"></mat-row>
        </ng-container>
      </mat-table>
      <mat-table [ngClass]="!multiLevel ? 'scrollable-table' : 'scrollable-multilevel'"
        *ngIf="scrollableColumns.length >= 1"
        [dataSource]="dataSource"
                 matSort
                 matSortDirection="{{sortDirection}}"
                 matSortActive="{{defaultSortColumn}}"
      >
        <ng-container *ngFor="let column of scrollableColumns" [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef
                           id="{{column.columnDef}}"
                           class="{{column.columnDef}}Header
                              {{column.tornado && 'tornado'}}
                              {{column.group ? column.group + 'Header' : ''}}
                              {{column.primaryColumn ? 'primaryHeader' : ''}}
                              {{column.columnDef === defaultSortColumn && mandatoryOpaqueSortingArrow &&
                               !(sort.direction === '') ? 'isWhatTableIsSortedBy' : ''}}"
                           (mouseenter)="toggleShowHoverMessage(true, column)"
                           (mouseleave)="toggleShowHoverMessage(false, column)"
          >
            <div class="hover-display"
                 *ngIf="column.showHoverMessage">
              {{column.hoverMessage}}
              <br/>
              <br/>
              <a id="methodologyDocument-link"
                 (mousedown)="openMethodologyDocument()">View More</a>
            </div>
            <br/>
            <span class="header-text-wrapper">
              <span class="header-text">{{column.header}}</span>
              <span id="date-range" *ngIf="column.showDates">{{previousDate}}</span>
            </span>
          </mat-header-cell>

          <mat-cell *matCellDef="let row"
                    class="{{column.columnDef}}
                    {{column.tornado && 'tornado'}}
                    {{column.group || ''}}
                    {{column.primaryColumn && 'primaryCell'}}
                    {{getCssClassForColumnType(column)}}">
            <ng-container *ngIf="!column.showCpt">
              <div class="{{column.class ? column.class(row) : 'data-text'}} {{column.primaryColumn && 'primaryText'}}"
                   *ngIf="!column.dataNameList">
                {{ column.dataName(row) }}
              </div>
              <span *ngIf="column.dataNameList" class="data-name-list">
                <div *ngFor="let item of column.dataNameList(row)" class="data-name-item"> {{item}} </div>
              </span>
              <span *ngIf="column.tornado"
                    [style.left.px]="row[column.tornado].positive ? 12 : 14 - row[column.tornado].borderWidth"
                    class="{{row[column.tornado].positive ? 'tornado-wrapper-positive' : 'tornado-wrapper-negative'}}">
                <span *ngIf="row[column.tornado].positive" class="flagpole" style="margin-left: 2px;"></span>
                  <span class="tornado-bar" style="height: 24px; border-right: solid; opacity: 0.4;"
                        [style.border-right-width.px]="row[column.tornado].borderWidth"
                        [style.color]="row[column.tornado].color">
                  </span>
                <span class="flagpole" *ngIf="!row[column.tornado].positive"></span>
              </span>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedScrollableColumns" class="{{tableCssClass}}-header-row"></mat-header-row>
        <ng-container *ngIf="!fromPdfExport">
          <mat-row (click)="selectRow(row)" (mouseenter)="deactivateHoverMessages()"
                   *matRowDef="let row; columns: displayedScrollableColumns"
                   class="{{rowSelectionCallback ? 'selectable' : ''}}
                     {{row.cptActive ? 'cptActive' : ''}}">
          </mat-row>
        </ng-container>
      </mat-table>
      <mat-table *ngIf="openWindowColumns.length >= 1 && !isSpecialtyPerformance" class="open-window-table" [dataSource]="dataSource">
        <ng-container *ngFor="let column of openWindowColumns" [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef
                           class="{{column.columnDef}}Header
                              {{column.group ? column.group + 'Header' : ''}}
                              {{column.primaryColumn ? 'primaryHeader' : ''}}
                              {{column.columnDef === defaultSortColumn && mandatoryOpaqueSortingArrow &&
                               !(sort.direction === '') ? 'isWhatTableIsSortedBy' : ''}}"
          >
            <br/>
            <span class="header-text-wrapper">
              <span class="header-text">{{column.header}}</span>
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row"
                    class="{{column.columnDef}}
                    {{column.group || ''}}
                    {{column.primaryColumn && 'primaryCell'}}
                    {{getCssClassForColumnType(column)}}">
            <ng-container *ngIf="!column.showCpt">
              <div class="{{column.class ? column.class(row) : 'data-text'}} {{column.primaryColumn && 'primaryText'}}"
                   *ngIf="!column.dataNameList" (click)="activateDenialsModal($event, column.nodePath(row), column.nodeName(row),
                   row)">
                {{ column.dataName(row) }}
              </div>
              <span *ngIf="column.dataNameList" class="data-name-list">
                <div *ngFor="let item of column.dataNameList(row)" class="data-name-item"> {{item}} </div>
              </span>
            </ng-container>
            <ng-container *ngIf="column.showCpt">
              <div class="{{column.class ? column.class(row) : 'data-text'}} {{column.primaryColumn && 'primaryText'}}"
                   *ngIf="!column.dataNameList" (click)="activateCptDescription($event, column.nodePath(row), column.nodeName(row))">
                {{ column.dataName(row) }}
              </div>
              <span *ngIf="column.dataNameList" class="data-name-list">
                <div *ngFor="let item of column.dataNameList(row)" class="data-name-item"> {{item}} </div>
              </span>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedOpenWindowColumns" class="{{tableCssClass}}-header-row"></mat-header-row>
        <ng-container *ngIf="!fromPdfExport">
          <mat-row *matRowDef="let row; columns: displayedOpenWindowColumns">
          </mat-row>
        </ng-container>
      </mat-table>
    </div>
    <mat-paginator [class.hide-paginator]="!paginate"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   [showFirstLastButtons]="true"
                   (page)="updateDataTable($event)"></mat-paginator>

    <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
</div>
