import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {FilterCriteria, IAppState} from '../store/IAppState';
import {ClinicalSummaryConsolidation} from '../clinical-fingerprint/ClinicalSummary';
import * as _ from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {columnsForCptsDenied, columnsForDenialReasons, columnsForDenialsRate} from '../shared/data-table-columns';
import {DenialReasonTab, DenialsTabs, SortingOrder, TopCPTsTab} from '../shared/enums';
import {getDenialsDetailExcelData, getDenialsRateExcelData} from '../shared/export/export-excel-helper';
import {Export, ExportNew} from '../shared/export/export';
import {canHide, getSelectedDateRange, lagPeriods} from '../shared/helpers';
import {ExcelService, ExcelServiceToken} from '../services/excel.service';
import {calculateBreadcrumbs} from '../shared/export/export-helper';
import {CSV, CSVToken} from '../services/csv.service';
import {DenialServiceToken, DenialsService} from '../denials/services/denials-service';
import {Breadcrumb, SortingCriterion} from '../shared/models';
import {
  collectionsByPayerDenialsChangedTo,
  collectionsByPayerDenialsTopCptsByChargesChangedTo,
  collectionsByPayerDenialsTopCptsByCptsDeniedChangedTo,
  collectionsByPayerDenialsTopCptsChangedTo,
  collectionsByPayerDenialsTopReasonsByChargesChangedTo,
  collectionsByPayerDenialsTopReasonsByCptsDeniedChangedTo,
  collectionsByPayerDenialsTopReasonsChangedTo
} from '../store/actions';
import {MatchedCollectionsByMultiLevelByNodePath} from '../collections/Collection';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {formatCurrency, formatPercent} from '@angular/common';
import {getDenialsExportData} from '../shared/export/denials-export-helper';
import {
  DenialCptCodeEntity,
  DenialPayerEntity,
  DenialReasonEntity,
  DenialsData,
  DenialsEntity,
  DenialsPayerData
} from '../denials/denials-models';

@Component({
  selector: 'app-denials-modal',
  templateUrl: './denials-modal.component.html',
  styleUrls: ['./denials-modal.component.scss']
})
export class DenialsModalComponent implements OnChanges, OnInit, OnDestroy {

  @Input() providerName: string;
  @Input() specialtyName = '';
  @Input() departmentName = '';

  @Input() nodeName = '';
  @Input() nodePath = '';
  @Input() parentNodeNames = '';
  @Input() row: MatchedCollectionsByMultiLevelByNodePath;
  @Output() closed = new EventEmitter<boolean>();
  provider: Breadcrumb[] = [];
  location: string;
  showExportDropdown: boolean;
  cfteSummary: number | null;
  clinicalSummary: ClinicalSummaryConsolidation = {
    cptClinicalSummaries: [],
    rangeClinicalSummaries: [],
    familyClinicalSummaries: []
  };
  showProgressBar = false;

  summaryHeaders = [
    {
      header: '% Denial Rate:',
      value: 'denialRate'
    },
    {
      header: '% Denial Rate Benchmark 50th Percentile:',
      value: 'denialRateBenchmark50th'
    },
    {
      header: '% Net Collection Rate:',
      value: 'netCollectionRate'
    },
    {
      header: '% Net Collection Benchmark 50th Percentile:',
      value: 'netCollectionRateBenchmark50th'
    },
    {
      header: 'Total Charges:',
      value: 'totalCharges'
    },
    {
      header: 'Expected Payments:',
      value: 'expectedPayments'
    },
    {
      header: 'Total Payments:',
      value: 'totalPayments'
    }
  ];
  denialRateSortColumn = '';
  denialRateSortDirection = 'desc';
  reasonsSortColumn = '';
  reasonsSortDirection = 'desc';
  topCptsDeniedSortColumn = '';
  topCptsSortDirection = 'desc';

  canHideDenialReasons = true;
  showTotalChargesInfoMessage = false;

  @select(['data', 'denialsData'])
  private readonly denials$: Observable<DenialPayerEntity[]>;

  @select(['data', 'denialsTopReasonsData'])
  private readonly reasons$: Observable<DenialReasonEntity[]>;

  @select(['data', 'denialsTopReasonsByCptsDeniedData'])
  private readonly reasonsByCptsDenied$: Observable<DenialReasonEntity[]>;

  @select(['data', 'denialsTopReasonsByChargesData'])
  private readonly reasonsByCharges$: Observable<DenialReasonEntity[]>;

  @select(['data', 'denialsTopCptsData'])
  private readonly cptsDenied$: Observable<DenialCptCodeEntity[]>;

  @select(['data', 'denialsTopCptsByCptsDeniedData'])
  private readonly cptsDeniedByCptsDenied$: Observable<DenialCptCodeEntity[]>;

  @select(['data', 'denialsTopCptsByChargesData'])
  private readonly cptsDeniedByCharges$: Observable<DenialCptCodeEntity[]>;

  denials: DenialPayerEntity[];
  reasons: DenialReasonEntity[];
  reasonsByCptsDenied: DenialReasonEntity[];
  reasonsByCharges: DenialReasonEntity[];
  cptsDenied: DenialCptCodeEntity[];
  cptsDeniedByCptsDenied: DenialCptCodeEntity[];
  cptsDeniedByCharges: DenialCptCodeEntity[];
  currentDenialsReasonsData: DenialReasonEntity[];
  currentDenialsCptsData: DenialCptCodeEntity[];
  currentDisplayedDenialsData: DenialsEntity[];

  viewCommunityBenchmarks: boolean;

  summaries = {
    denialRate: '0',
    denialRateBenchmark50th: '0',
    netCollectionRate: '0',
    netCollectionRateBenchmark50th: '0',
    totalCharges: '0',
    expectedPayments: '0',
    totalPayments: '0',
  };
  chosen = DenialsTabs.DENIAL_RATE;
  denialReasonTabSelected: DenialReasonTab = DenialReasonTab.BY_DENIAL_RATE;
  topCPTsTabSelected: TopCPTsTab = TopCPTsTab.BY_DENIAL_RATE;

  DenialsTabs = DenialsTabs;
  DenialReasonTab = DenialReasonTab;
  TopCPTsTab = TopCPTsTab;

  denialsColumns = _.cloneDeep(columnsForDenialsRate);
  denialRateHeader = 0;
  averageDenialRate = 0;
  reasonsColumns = _.cloneDeep(columnsForDenialReasons);
  cptsDeniedColumn = _.cloneDeep(columnsForCptsDenied);
  copyrightYear = new Date().getFullYear() - 1;
  denialsExport: ExportNew;
  filters: FilterCriteria;
  sortingDataAccessor: any;
  private subscription: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>,
              @Inject(ExcelServiceToken) private readonly excelService: ExcelService,
              @Inject(DenialServiceToken) private readonly denialService: DenialsService,
              @Inject(CSVToken) private readonly csv: CSV) {
  }

  ngOnInit(): void {
    this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      return data[sortHeaderId];
    };

    const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
    this.canHideDenialReasons = canHide(FeatureToggleEntries.DENIALS_MODAL_REASONS, featureToggleSettings, userProfile);
    this.getDefaultDenialData();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  getPayerHeaderText(type: number): string {
    switch (type) {
      case 1:
        return 'National Payer';
      case 2:
      case 3:
        return 'Member Payer';
    }
    return 'Payer Categories';
  }

  ngOnChanges() {
    this.filters = {
      ...this.ngRedux.getState().filters,
      nodePath: this.nodePath
    };
    this.denialsColumns = _.cloneDeep(columnsForDenialsRate);
    this.denialsColumns[0].header = this.getPayerHeaderText(this.filters.payerType);
  }

  close(): void {
    this.closed.emit(true);
  }

  chooseTab(tab: DenialsTabs) {
    this.chosen = tab;
    if (this.chosen === DenialsTabs.TOP_TEN_DENIED_REASONS) {
      this.chooseReasonTab(this.denialReasonTabSelected);
    } else if (this.chosen === DenialsTabs.TOP_TEN_CPTS) {
      this.chooseTopCTP_Tab(this.topCPTsTabSelected);
    } else {
      this.getDefaultDenialData();
    }
  }

  toggleExportDropdown() {
    if (this.showProgressBar) {
      return;
    }
    this.showExportDropdown = !this.showExportDropdown;
  }

  closeExportDropdown() {
    this.showExportDropdown = false;
  }

  chooseReasonTab(tab: DenialReasonTab): void {
    this.denialReasonTabSelected = tab;
    this.getTopReasonsData(tab);
  }

  chooseTopCTP_Tab(tab: TopCPTsTab): void {
    this.topCPTsTabSelected = tab;
    this.getTopCptsData(tab);
  }

  getDefaultDenialData(): void {
    this.showProgressBar = true;
    this.denialRateSortColumn = 'denialRate';
    this.denialRateSortDirection = 'desc';
    if (this.denials !== undefined) {
      this.showProgressBar = false;
      this.currentDisplayedDenialsData = this.denials;
      return;
    }
    this.denialService.getDenialsData(this.filters).subscribe((data: DenialsPayerData) => {
      this.denials = data.denialRates;
      const sumOfBilling = this.denials.map(den => den.numberBilled).reduce((a, b) => a + b, 0);
      const sumOfDenied = this.denials.map(den => den.numberDenied).reduce((a, b) => a + b, 0);
      this.denialRateHeader = sumOfBilling > 0 ? sumOfDenied / sumOfBilling : 0;
      const sumOfPercentiles = this.denials.map(den => den.denialRatePer50th).reduce((a, b) => a + b, 0);
      this.averageDenialRate = this.denials.length > 0 ? sumOfPercentiles / this.denials.length : 0;
      this.summaries = {
        denialRate: formatPercent(this.denialRateHeader, 'en-US', '1.1-1'),
        denialRateBenchmark50th: formatPercent(this.averageDenialRate / 100, 'en-US', '1.1-1'),
        netCollectionRate: formatPercent(this.row.netCollectionRate / 100, 'en-US', '1.1-1'),
        netCollectionRateBenchmark50th: formatPercent(this.row.benchmark50th / 100, 'en-US', '1.1-1'),
        totalCharges: formatCurrency(this.row.chargeAmount, 'en-US', '$', 'USD', '1.0-0'),
        expectedPayments: formatCurrency(this.row.expectedPayments, 'en-US', '$', 'USD', '1.2-2'),
        totalPayments: formatCurrency(this.row.totalPayments, 'en-US', '$', 'USD', '1.2-2')
      };
      this.ngRedux.dispatch(collectionsByPayerDenialsChangedTo(this.denials));
      this.currentDisplayedDenialsData = this.denials;
      this.showProgressBar = false;
    });
  }

  getTopReasonsData(tab: DenialReasonTab): void {
    let sortOrderIndex = 100;
    this.showProgressBar = true;
    switch (tab) {
      case DenialReasonTab.BY_DENIAL_RATE:
        this.reasonsSortColumn = 'denialRate';
        this.reasonsSortDirection = 'desc';
        sortOrderIndex = 100;
        if (this.reasons !== undefined) {
          this.showProgressBar = false;
          this.currentDenialsReasonsData = this.reasons;
          return;
        }
        this.denialService.getDenialsDataByReason(this.filters, sortOrderIndex).subscribe((data: DenialsData<DenialReasonEntity>) => {
          this.reasons = data.denials;
          this.ngRedux.dispatch(collectionsByPayerDenialsTopReasonsChangedTo(this.reasons));
          this.currentDenialsReasonsData = this.reasons;
          this.showProgressBar = false;
        });
        break;
      case DenialReasonTab.BY_CHARGES:
        this.reasonsSortColumn = 'chargeAmount';
        this.reasonsSortDirection = 'desc';
        sortOrderIndex = 6;
        if (this.reasonsByCharges !== undefined) {
          this.showProgressBar = false;
          this.currentDenialsReasonsData = this.reasonsByCharges;
          return;
        }
        this.denialService.getDenialsDataByReason(this.filters, sortOrderIndex).subscribe((data: DenialsData<DenialReasonEntity>) => {
          this.reasonsByCharges = data.denials;
          this.ngRedux.dispatch(collectionsByPayerDenialsTopReasonsByChargesChangedTo(this.reasonsByCharges));
          this.currentDenialsReasonsData = this.reasonsByCharges;
          this.showProgressBar = false;
        });
        break;
      case DenialReasonTab.BY_CPT_CODES_DENIED:
        this.reasonsSortColumn = 'numberDenied';
        this.reasonsSortDirection = 'desc';
        sortOrderIndex = 8;
        if (this.reasonsByCptsDenied !== undefined) {
          this.showProgressBar = false;
          this.currentDenialsReasonsData = this.reasonsByCptsDenied;
          return;
        }
        this.denialService.getDenialsDataByReason(this.filters, sortOrderIndex).subscribe((data: DenialsData<DenialReasonEntity>) => {
          this.reasonsByCptsDenied = data.denials;
          this.ngRedux.dispatch(collectionsByPayerDenialsTopReasonsByCptsDeniedChangedTo(this.reasonsByCptsDenied));
          this.currentDenialsReasonsData = this.reasonsByCptsDenied;
          this.showProgressBar = false;
        });
        break;
      default:
        this.showProgressBar = false;
    }
  }

  getTopCptsData(tab: TopCPTsTab): void {
    let sortOrderIndex = 100;
    this.showProgressBar = true;
    switch (tab) {
      case TopCPTsTab.BY_DENIAL_RATE:
        this.topCptsDeniedSortColumn = 'denialRate';
        this.topCptsSortDirection = 'desc';
        if (this.cptsDenied !== undefined) {
          this.showProgressBar = false;
          this.currentDenialsCptsData = this.cptsDenied;
          return;
        }
        sortOrderIndex = 100;
        this.denialService.getDenialsDataByCpt(this.filters, sortOrderIndex).subscribe((data: DenialsData<DenialCptCodeEntity>) => {
          this.cptsDenied = data.denials;
          this.ngRedux.dispatch(collectionsByPayerDenialsTopCptsChangedTo(this.cptsDenied));
          this.currentDenialsCptsData = this.cptsDenied;
          this.showProgressBar = false;
        });
        break;
      case TopCPTsTab.BY_CHARGES:
        this.topCptsDeniedSortColumn = 'chargeAmount';
        this.topCptsSortDirection = 'desc';
        if (this.cptsDeniedByCharges !== undefined) {
          this.showProgressBar = false;
          this.currentDenialsCptsData = this.cptsDeniedByCharges;
          return;
        }
        sortOrderIndex = 6;
        this.denialService.getDenialsDataByCpt(this.filters, sortOrderIndex).subscribe((data: DenialsData<DenialCptCodeEntity>) => {
          this.cptsDeniedByCharges = data.denials;
          this.ngRedux.dispatch(collectionsByPayerDenialsTopCptsByChargesChangedTo(this.cptsDeniedByCharges));
          this.currentDenialsCptsData = this.cptsDeniedByCharges;
          this.showProgressBar = false;
        });
        break;
      case TopCPTsTab.BY_CPT_CODES_DENIED:
        this.topCptsDeniedSortColumn = 'numberDenied';
        this.topCptsSortDirection = 'desc';
        if (this.cptsDeniedByCptsDenied !== undefined) {
          this.showProgressBar = false;
          this.currentDenialsCptsData = this.cptsDeniedByCptsDenied;
          return;
        }
        sortOrderIndex = 8;
        this.denialService.getDenialsDataByCpt(this.filters, sortOrderIndex).subscribe((data: DenialsData<DenialCptCodeEntity>) => {
          this.cptsDeniedByCptsDenied = data.denials;
          this.ngRedux.dispatch(collectionsByPayerDenialsTopCptsByCptsDeniedChangedTo(this.cptsDeniedByCptsDenied));
          this.currentDenialsCptsData = this.cptsDeniedByCptsDenied;
          this.showProgressBar = false;
        });
        break;
      default:
        this.showProgressBar = false;
    }
  }

  updateDenialRateSortingCriteria = (sortingCriteria: SortingCriterion) => {
    this.denialRateSortColumn = sortingCriteria.columnDef;
    this.denialRateSortDirection = sortingCriteria.sortingOrder === SortingOrder.ASCENDING ? 'asc' : 'desc';
  };

  whenDenialRateSortChanged = (data: DenialsEntity[]) => {
    const columnDef = this.denialRateSortColumn;
    const sortingDirection = this.denialRateSortDirection;
    data.sort(function (a: DenialsEntity, b: DenialsEntity) {
      if (typeof(a[columnDef]) === 'number') {
        // @ts-ignore
        return sortingDirection === 'asc' ? a[columnDef] - b[columnDef] : b[columnDef] - a[columnDef];
      }
      const val = sortingDirection === 'asc' ? a[columnDef]?.toString().localeCompare(b[columnDef]?.toString() || '')
        : b[columnDef]?.toString().localeCompare(a[columnDef]?.toString() || '');
      return val || 0;
    });
  };

  updateReasonsSortingCriteria = (sortingCriteria: SortingCriterion) => {
    this.reasonsSortColumn = sortingCriteria.columnDef;
    this.reasonsSortDirection = sortingCriteria.sortingOrder === SortingOrder.ASCENDING ? 'asc' : 'desc';
  };

  whenReasonsSortChanged = (data: DenialsEntity[]) => {
    const columnDef = this.reasonsSortColumn;
    const sortingDirection = this.reasonsSortDirection;
    data.sort(function (a: DenialsEntity, b: DenialsEntity) {
      if (typeof(a[columnDef]) === 'number') {
        // @ts-ignore
        return sortingDirection === 'asc' ? a[columnDef] - b[columnDef] : b[columnDef] - a[columnDef];
      }
      const val = sortingDirection === 'asc' ? a[columnDef]?.toString().localeCompare(b[columnDef]?.toString() || '')
        : b[columnDef]?.toString().localeCompare(a[columnDef]?.toString() || '');
      return val || 0;
    });
  };

  updateTopCptsSortingCriteria = (sortingCriteria: SortingCriterion) => {
    this.topCptsDeniedSortColumn = sortingCriteria.columnDef;
    this.topCptsSortDirection = sortingCriteria.sortingOrder === SortingOrder.ASCENDING ? 'asc' : 'desc';
  };

  whenTopCptsSortChanged = (data: DenialsEntity[]) => {
    const columnDef = this.topCptsDeniedSortColumn;
    const sortingDirection = this.topCptsSortDirection;
    data.sort(function (a: DenialsEntity, b: DenialsEntity) {
      if (typeof(a[columnDef]) === 'number') {
        // @ts-ignore
        return sortingDirection === 'asc' ? a[columnDef] - b[columnDef] : b[columnDef] - a[columnDef];
      }
      const val = sortingDirection === 'asc' ? a[columnDef]?.toString().localeCompare(b[columnDef]?.toString() || '')
        : b[columnDef]?.toString().localeCompare(a[columnDef]?.toString() || '');
      return val || 0;
    });
  };

  openExcelFile() {
    const lagPeriod = lagPeriods[this.filters.lagKey - 1];
    const billingAreaDescription = this.filters.memberBillingArea.memberBillingAreaDescription || 'All Billing Areas';
    let payerDescription = this.filters.payerCategory.payerCategoryDescription || 'All Payers';
    const {nationalPayerDescription, memberPayerDescription} = this.filters;
    payerDescription = payerDescription.concat(nationalPayerDescription ? ' : ' + nationalPayerDescription : '');
    payerDescription = payerDescription.concat(memberPayerDescription ? ' : ' + memberPayerDescription : '');

    this.provider.push({
      nodeName: this.nodeName,
      nodePath: this.nodePath
    });
    const sheets: string[] = [];
    const denialsExportData = [];
    if (this.currentDisplayedDenialsData && this.currentDisplayedDenialsData.length >= 1) {
      sheets.push('Denial Rate');
      denialsExportData.push(getDenialsRateExcelData(
        this.currentDisplayedDenialsData,
        this.denialsColumns,
        this.filters.dateRange,
        this.filters.nodePath,
        this.filters.memberLocation.memberLocationName,
        lagPeriod.name,
        billingAreaDescription,
        payerDescription));
    }
    if (!this.canHideDenialReasons && this.currentDenialsReasonsData && this.currentDenialsReasonsData.length >= 1) {
      sheets.push('Top 10 Denial Reasons');
      denialsExportData.push(getDenialsDetailExcelData(
        this.currentDenialsReasonsData,
        this.reasonsColumns,
        this.filters.dateRange,
        this.filters.nodePath,
        this.filters.memberLocation.memberLocationName,
        lagPeriod.name,
        billingAreaDescription,
        payerDescription));
    }
    if (this.currentDenialsCptsData && this.currentDenialsCptsData.length >= 1) {
      sheets.push('Top 10 CPTs Denied');
      denialsExportData.push(getDenialsDetailExcelData(
        this.currentDenialsCptsData,
        this.cptsDeniedColumn,
        this.filters.dateRange,
        this.filters.nodePath,
        this.filters.memberLocation.memberLocationName,
        lagPeriod.name,
        billingAreaDescription,
        payerDescription));
    }
    if (denialsExportData.length >= 1) {
      this.denialsExport = {
        data: denialsExportData,
        sheets: sheets,
        fileName: this.getExcelFileName(),
      };
      this.excelService.exportAsExcelFile(this.denialsExport, false);
    }
  }

  getExcelFileName(): string {
    const member = this.ngRedux.getState().data.members.members.find(
      x => x.memberKey === this.ngRedux.getState().filters.memberKey);
    return member ? member.memberDesc : 'cpsc';
  }

  openCsvFile() {
    const { payerCategory, nationalPayerDescription, memberPayerDescription } = this.filters;
    let payerDescription = payerCategory.payerCategoryDescription ?
      'Payer Category: ' + payerCategory.payerCategoryDescription : 'All Payers';
    payerDescription = payerDescription.concat(nationalPayerDescription ? ' : ' + nationalPayerDescription : '');
    payerDescription = payerDescription.concat(memberPayerDescription ? ' : ' + memberPayerDescription : '');
    this.provider.push({
      nodeName: this.nodeName,
      nodePath: this.nodePath
    });
    const denialsCsvData = [];
    if (this.currentDisplayedDenialsData && this.currentDisplayedDenialsData.length >= 1) {
      denialsCsvData.push(getDenialsExportData(
        this.currentDisplayedDenialsData, 'Denial Rate',
        'Clinical Practice Solutions Center ' +
        'Denials; CPT® only © ' + this.copyrightYear + ' American Medical Association. All Rights Reserved '
        + new Date().toLocaleString() + ';',
        this.denialsColumns
      ));
    }
    if (!this.canHideDenialReasons && this.currentDenialsReasonsData && this.currentDenialsReasonsData.length >= 1) {
      denialsCsvData.push(getDenialsExportData(
        this.currentDenialsReasonsData, 'Top 10 Denial Reasons',
        'Clinical Practice Solutions Center ' +
        'Denials; CPT® only © ' + this.copyrightYear + ' American Medical Association. All Rights Reserved '
        + new Date().toLocaleString() + ';',
        this.reasonsColumns
      ));
    }
    if (this.currentDenialsCptsData && this.currentDenialsCptsData.length >= 1) {
      denialsCsvData.push(getDenialsExportData(
        this.currentDenialsCptsData, 'Top 10 CPTs Denied',
        'Clinical Practice Solutions Center ' +
        'Denials; CPT® only © ' + this.copyrightYear + ' American Medical Association. All Rights Reserved '
        + new Date().toLocaleString() + ';',
        this.cptsDeniedColumn
      ));
    }
    const breadCrumb = calculateBreadcrumbs(this.nodePath);

    denialsCsvData.forEach((csvData: Export) => {
      const lagPeriod = lagPeriods.find(l => l.key === this.filters.lagKey);
      const lagName = lagPeriod ? lagPeriod.name : '';
      const benchMarkText = this.viewCommunityBenchmarks ? 'Community Benchmark' : 'Academic Benchmark';
      this.csv.export(csvData.data,
        csvData.fileName,
        {
          fieldSeparator: '|',
          quoteStrings: '',
          headers: '',
          title: csvData.title +
            '\n' + this.ngRedux.getState().data.selectedMemberData.memberDesc +
            '\n' + breadCrumb +
            '\n' + 'Selected Date Range (' + getSelectedDateRange(this.ngRedux.getState().filters.dateRange) + ')' +
            '\n' + 'Benchmark: ' + benchMarkText +
            '\n' + 'Location Filters: ' + this.filters.memberLocation.memberLocationName +
            (csvData.whatFilters?.showLag ? '\n' + 'Lag Period: ' + lagName : '') +
            (csvData.whatFilters?.showBilling ? '\n' + 'Billing Area: ' + this.filters.memberBillingArea.memberBillingAreaDescription
            : '') + (csvData.whatFilters?.showPayer ? '\n' + payerDescription : ''),
          showTitle: (csvData.title !== undefined),
        });
    });
  }

  toggleTotalChargesInfoMessage() {
    this.showTotalChargesInfoMessage = !this.showTotalChargesInfoMessage;
  }
}
