import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {batchExportErrorRoute, noAccessRoute, unauthorizedErrorRoute} from '../routing';
import {catchError, mergeMap} from 'rxjs/operators';
import {OktaAuth} from '@okta/okta-auth-js';

export const JwtExpirationServiceToken = new InjectionToken<JwtExpirationService>('Jwt Expiration Service');

export interface JwtExpirationService {
  isTokenExpired(token?: string, offsetSeconds?: number): boolean;
}

type callbackFunction = (error: any) => any;

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: OktaAuth,
    private router: Router,
    @Inject(JwtExpirationServiceToken) private jwtExpirationService: JwtExpirationService,
  ) {}

  private static addAuthorizationHeaderTo(
    request: HttpRequest<any>,
    accessToken: string | undefined,
  ): HttpRequest<any> {
    if (accessToken === undefined) {
      return request;
    }
    return request.clone({
      headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` }),
    });
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // noinspection UnnecessaryLocalVariableJS to prevent test error log
    let accessToken: any;
    const interceptor: Observable<any> = of(this.authService.getAccessToken()).pipe(
      mergeMap((token) => {
        accessToken = token;
        if (accessToken) {
          if (this.jwtExpirationService.isTokenExpired(accessToken, 0.2)) {
            this.authService.handleLoginRedirect(accessToken, this.router.url);
            return this.observableThatPreventsTheRequest();
          }
        }
        return next.handle(BearerTokenInterceptor.addAuthorizationHeaderTo(request, accessToken));
      }))
      .pipe(catchError(this.andRethrowAfter(this.redirectingAll401s())));
    return interceptor;
  }

  private andRethrowAfter(callback: callbackFunction): callbackFunction {
    return error => {
      callback(error);
      throw error;
    };
  }

  private redirectingAll401s(): callbackFunction {
    const ignoreErrorAPI: string[]  = ['batchExport/',
      'batchPdfExport/',
      'batchPdfEmExport/',
      'batchPdfNpvExport/',
      'batchPdfEmTrendExport/',
      'batchExport/getBatchExportReportDetailsByUser',
      'notificationAlertCountUrl',
      'notifications',
      'unreadAlertCount',
      'alerts', '/getSubproducts'];

    let redirect = true;
    return error => {
      if (error.status === 404) {
        console.log('Error is : ', error);
      } else if (error.status === 401) {
        if (error.url.includes('batch')) {
          this.router.navigateByUrl(batchExportErrorRoute);
        } else {
        this.router.navigateByUrl(noAccessRoute);
        }
      } else {
        ignoreErrorAPI.forEach((url: string) => {
          if (redirect && error.url.includes(url)) {
            console.log('Error is : ', error);
            redirect = false;
          }
        });
        if (redirect) {
          console.log('Error is : ', error);
          this.router.navigateByUrl(unauthorizedErrorRoute);
        }
      }
    };
  }

  private observableThatPreventsTheRequest() {
    return Observable.create(function () {
      return null;
    });
  }
}
