<app-loader *ngIf="showLoadingIndicator"></app-loader>
<div id="cpts-denied-wrapper">
  <div id="cpts-denied-header">
    <div id="cpts-denied-upper-header">
      <div id="left-side-upper-header">
        <div id="label-payer-name">{{this?.selectedPayer?.payerCategoryDesc}} CPT Denied</div>
        <div id="label-node-path-name">{{nodePathName}}</div>
      </div>
      <span id="x-out" (click)="closeModal()"><span class="material-icons">close</span></span>
    </div>
    <div id="cpts-denied-lower-header">
      <span class="numberLabel">Net Collection Rate: <span class="numberLabel_amt"
                                                           id="ncr-label">{{netCollectionRate}}</span>
      </span>
      <span class="numberLabel">Net Collection Rate Benchmark <span class="numberLabel_amt" id="ncr-benchmark-label">
        {{netCollectionRateBenchmark}}</span>
      </span>
      <span class="numberLabel">Charges: <span class="numberLabel_amt" id="charges-label">{{charges}}</span>
      </span>
      <span class="numberLabel">Expected Payments: <span class="numberLabel_amt"
                                                         id="expectedPayments-label">{{expectedPayments}}</span>
      </span>
    </div>
    <button class="exportButton" mat-raised-button (click)="toggleExportOptions()">Export</button>
    <div *ngIf="showExportOptions" id="export-options">
      <div class="export-option" (click)="exportToExcel()" id="excel-option">Excel</div>
      <div class="export-option" (click)="exportToCsv()" id="csv-option">CSV</div>
    </div>

  </div>
  <div id="cpts-denied-table">
    <mat-table [dataSource]="dataSource"
               matSort
               matSortDirection="{{sortDirection}}"
               matSortActive="{{sortColumn}}">

      <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef"
                    [sticky]="column.columnDef==='denialReason' || column.columnDef==='cptCode'">
        <mat-header-cell *matHeaderCellDef mat-sort-header
                         [disabled]="column.columnDef==='deniedPaidMean' || column.columnDef==='deniedMean'"
                         id="{{column.columnDef}}-header">
          <span class="header-text">{{column.header}}</span>
          <span *ngIf="column.hoverMessage" [matTooltip]="column.hoverMessage" class="material-icons" id="info">info</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" id="{{column.columnDef}}">
          <div class="upper-cell">
            <span class="data-text"
                  *ngIf="!(column.columnDef === 'denialReason' && row.isExpanded)"
                  [matTooltip]="column.completeDataName ? column.completeDataName(row) : ''"
                  [matTooltipDisabled]="column.columnDef !== 'denialReason' || row.children?.length > 1 ||
                column.completeDataName(row)?.length < maxDenialReasonChars"
                  [matTooltipPosition]="'below'"
            >{{row[column.columnDef]}}</span>
            <span class="chevron-container">
               <i class="fa fa-chevron-down" *ngIf="column.primaryColumn && !row.isExpanded && row.children?.length > 1"
                  (click)="expandOrCollapseRow(row)"></i>
            <i class="fa fa-chevron-up" *ngIf="column.primaryColumn && row.isExpanded && row.children?.length > 1"
               (click)="expandOrCollapseRow(row)"></i>
            </span>

            <span class="additional-reasons" *ngIf="column.columnDef === 'denialReason' && !row.isExpanded
           && row.children.length > 1">& <span class="how-many-more-reasons">{{row.children.length - 1}} more</span>
          </span>
            <span class="how-many-total-reasons" *ngIf="column.columnDef === 'denialReason' && row.isExpanded &&
           row.children.length > 1">{{row.children.length}} Reasons</span>
          </div>
          <div *ngIf="row.isExpanded">
            <div class="lower-cell" *ngFor="let child of row.children || []">
              <span class="child-data-text" *ngIf="!column.primaryColumn" [matTooltip]="column.upperDataName ?
               column.upperDataName(child) : ''" [matTooltipDisabled]="column.columnDef !== 'denialReason' ||
                column.dataName(child).length < maxDenialReasonChars" [matTooltipPosition]="'below'">
                {{column.dataName(child)}}</span>
              <span class="child-data-text" *ngIf="column.primaryColumn">  </span>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
  </div>
  <div id="gray-line"></div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="defaultPageSize"
                 [showFirstLastButtons]="true">
  </mat-paginator>


</div>
