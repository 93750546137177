import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {formatToCurrency, roundToNearestTenthOrDash} from '../../productivity-summary/number-formatter';
import {NgRedux, select} from '@angular-redux/store';
import {FilterCriteria, IAppState} from '../../store/IAppState';
import {MatTableDataSource} from '@angular/material/table';
import {DenialServiceToken, DenialsService} from '../services/denials-service';
import {MatPaginator} from '@angular/material/paginator';
import {columnsForDenialCptCodeByReason, DataTableColumns, maxDenialReasonChars} from '../../shared/data-table-columns';
import {MatSort} from '@angular/material/sort';
import {hasValue} from '../../shared/null-helpers';
import {SortingOrder} from '../../shared/enums';
import {ExcelService, ExcelServiceToken} from '../../services/excel.service';
import {
  composeCurrentLocationFiltersForCsvExport,
  composeCurrentLocationFiltersForExcelExport
} from '../../shared/export/export-helper';
import {CSV, CSVToken} from '../../services/csv.service';
import {ExportType, getAppConfigValue, getSelectedDateRange, lagPeriods} from '../../shared/helpers';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ApiServiceImpl, ApiServiceToken} from '../../services/api.service';
import {
  MatchedCollectionsByMultiLevelByNodePath,
  MatchedCollectionsMultiLevelNodePathData,
  PayerCollections
} from '../../collections/Collection';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {getCptDeniedExportDataForCsv, getCptDeniedModalExcelData} from '../../shared/export/denials-export-helper';
import {DenialBaseEntity, DenialCptCodeByReasonEntity, DenialPayerEntity, DenialsData} from '../denials-models';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';

@Component({
  selector: 'app-cpts-denied-modal',
  templateUrl: './cpts-denied-modal.component.html',
  styleUrls: ['./cpts-denied-modal.component.scss']
})
export class CptsDeniedModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedPayer: DenialPayerEntity;
  @Input() nodePathName = '';
  @Input() filters: FilterCriteria;

  @Output() whenModalClosed = new EventEmitter<boolean>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @select(['data', 'matchedCollectionsByMultiLevelByNodePathData'])
  private readonly matchedCollectionsByMultiLevelByNodePathData$: Observable<MatchedCollectionsMultiLevelNodePathData>;

  @select(['data', 'payerCollectionsData', 'payerMatchedCollections'])
  private readonly payerCollectionsData$: Observable<PayerCollections[]>;

  netCollectionRate = '0.0%';
  netCollectionRateBenchmark = '0.0%';
  charges = '$0.00';
  expectedPayments = '$0.00';
  dataSource: MatTableDataSource<DenialBaseEntity<DenialCptCodeByReasonEntity>>;
  sortColumn = 'denialRate';
  sortDirection: SortingOrder | string = SortingOrder.DESCENDING;
  columns: DataTableColumns[] = columnsForDenialCptCodeByReason().slice();
  denialRowHierarchy: DenialCptCodeByReasonEntity[] = [];
  displayedColumns: string[] = this.columns.map(c => c.columnDef);
  showExportOptions: boolean;
  breadcrumbString = '';
  memberLocationName = '';
  payerCategoryDescription = '';
  copyRight = 'Clinical Practice Solutions CPT® Denied © 2020 American Medical Association. All Rights Reserved';
  reasonSubscription: Subscription;
  currentlyExpandedRow: DenialCptCodeByReasonEntity | undefined;
  showLoadingIndicator = true;
  maxDenialReasonChars = maxDenialReasonChars;
  private collectionsDataSubscription: Subscription;
  private payerCollectionsDataSubscription: Subscription;
  private multiLevelCollectionsData: MatchedCollectionsMultiLevelNodePathData;
  private payerCollectionsData: PayerCollections[];
  readonly defaultPageSize = 25;
  readonly pageSizeOptions = [25, 50, 75, 100];

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(DenialServiceToken) private readonly denialService: DenialsService,
              @Inject(ExcelServiceToken) private readonly excelService: ExcelService,
              @Inject(CSVToken) private readonly csv: CSV,
              private cdr: ChangeDetectorRef,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {
  }

  ngOnInit() {
    this.apiService.getPayerCollectionsData(this.ngRedux.getState().filters);
    this.apiService.getMatchedCollectionsByMultiLevelByNodePathData(this.ngRedux.getState().filters);

    this.payerCollectionsDataSubscription = this.payerCollectionsData$
      .pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
      .subscribe((payerCollectionsData) => {
        this.payerCollectionsData = payerCollectionsData;
        if (this.selectedPayer && this.selectedPayer.payerCategoryDesc && this.payerCollectionsData) {
          const matchingCollectionsPayer = this.payerCollectionsData
            .find(payer => payer.payerCategoryDescription === this.selectedPayer.payerCategoryDesc);
          if (matchingCollectionsPayer) {
            this.netCollectionRate = `${roundToNearestTenthOrDash(matchingCollectionsPayer.netCollectionRate)}%`;
            this.netCollectionRateBenchmark = `${roundToNearestTenthOrDash(matchingCollectionsPayer.benchmark50th)}%`;
            this.expectedPayments = formatToCurrency(matchingCollectionsPayer.expectedPayments, 0);
          }
        }
      });

    this.collectionsDataSubscription = this.matchedCollectionsByMultiLevelByNodePathData$
      .pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
      .subscribe((matchedCollectionsByMultiLevelByNodePathData: MatchedCollectionsMultiLevelNodePathData) => {
        this.multiLevelCollectionsData = matchedCollectionsByMultiLevelByNodePathData;
        if (this.selectedPayer && !this.selectedPayer.payerCategoryDesc && this.multiLevelCollectionsData) {
          const nodePathNameArray = this.nodePathName.split(' : ');
          let department = '';
          let specialty = '';
          let provider = '';
          let rowData: MatchedCollectionsByMultiLevelByNodePath | undefined;
          if (nodePathNameArray.length >= 1) {
            department = nodePathNameArray[0];
            if (nodePathNameArray.length === 1) { // department
              rowData = this.multiLevelCollectionsData.departmentCollections.find(row =>
                row.departmentNodeName === department);
            }
          }
          if (nodePathNameArray.length >= 2) {
            specialty = nodePathNameArray[1];
            if (nodePathNameArray.length === 2) { // specialty
              rowData = this.multiLevelCollectionsData.specialtyCollections.find(row =>
                row.departmentNodeName === department && row.specialtyNodeName === specialty);
            }
          }
          if (nodePathNameArray.length >= 3) {
            provider = nodePathNameArray[2];
            if (nodePathNameArray.length === 3) { // provider
              rowData = this.multiLevelCollectionsData.providerCollections.find(row =>
                row.departmentNodeName === department && row.specialtyNodeName === specialty && row.providerNodeName === provider);
            }
          }
          if (rowData) {
            this.netCollectionRate = `${roundToNearestTenthOrDash(rowData.netCollectionRate)}%`;
            this.netCollectionRateBenchmark = `${roundToNearestTenthOrDash(rowData.benchmark50th)}%`;
            this.expectedPayments = formatToCurrency(rowData.expectedPayments, 0);
          }
        }
      });

    if (!this.selectedPayer.payerType) {
      this.selectedPayer = {
        ...this.selectedPayer,
        payerType: this.ngRedux.getState().filters.payerType,
        payerKey: this.ngRedux.getState().filters.payerKey,
        payerCategoryDesc: this.ngRedux.getState().filters.payerCategory?.payerCategoryDescription
      };
    }
    const filterCriteriaForSelectedPayer = this.getFilterCriteriaForSelectedPayer();
    this.reasonSubscription = combineLatest([
      this.denialService.getDenialsDataByCptCodeByReason(filterCriteriaForSelectedPayer),
      this.denialService.getDenialsDataByCptCodeByReasonAgg(filterCriteriaForSelectedPayer)
    ]).subscribe(([cptReasons, cptAggregates]: [DenialsData<DenialCptCodeByReasonEntity>, DenialsData<DenialCptCodeByReasonEntity>]) => {
      this.createHierarchy(cptReasons, cptAggregates);
      if (this.denialRowHierarchy) {
        this.createDataSource(this.denialRowHierarchy);
      }
      this.createSortData();
      this.showLoadingIndicator = false;
    });
    this.columns = columnsForDenialCptCodeByReason().slice();
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }

  ngOnDestroy() {
    this.collectionsDataSubscription?.unsubscribe();
    this.payerCollectionsDataSubscription?.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.nodePathName.length === 0) {
      this.nodePathName = 'All Departments';
    }
    if (this.selectedPayer?.chargeAmount) {
      this.charges = formatToCurrency(this.selectedPayer.chargeAmount, 0);
    }
  }

  createHierarchy(children: DenialsData<DenialCptCodeByReasonEntity>, parents: DenialsData<DenialCptCodeByReasonEntity>): void {
    this.denialRowHierarchy = parents.denials;
    this.denialRowHierarchy.forEach(parent => {
      const matchingChildren = children.denials.filter(val => val.cptCode === parent.cptCode);
      if (matchingChildren) {
        parent.isExpanded = false;
        parent.children = matchingChildren;
      }
    });
  }

  createDataSource(data: DenialCptCodeByReasonEntity[]): void {
    this.dataSource = new MatTableDataSource<any>(data.map((denial: DenialCptCodeByReasonEntity) => {
      const formattedDenialData: { [key: string]: string | DenialCptCodeByReasonEntity[] | boolean | undefined } = {};
      const singleChild = denial.children?.length === 1;
      this.columns.forEach(col => {
        if ((col.columnDef === 'deniedMean' || col.columnDef === 'deniedPaidMean') && singleChild) {
          // @ts-ignore
          formattedDenialData[col.columnDef] = col.dataName(denial.children[0]);
        } else {
          formattedDenialData[col.columnDef] = col.dataName(denial);
        }
      });
      formattedDenialData['children'] = denial.children;
      formattedDenialData['isExpanded'] = denial.isExpanded;
      return formattedDenialData;
    }));
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.length = data.length;
    this.cdr.detectChanges();
    this.dataSource.sort.sortChange.subscribe(() => {
      this.collapsePreviouslyExpandedRow();
      this.currentlyExpandedRow = undefined;
    });
  }

  createSortData(): void {
    this.dataSource.sortData = (data: any[], sort: MatSort): any[] => {
      const active = sort.active;
      const direction = sort.direction;
      if (!active || direction === '') {
        return data;
      }
      return data.sort((a, b) => {
        return this.compare(a, b, active, direction === 'asc');
      });
    };
  }

  expandOrCollapseRow = (row: DenialCptCodeByReasonEntity) => {
    if (hasValue(row.isExpanded)) {
      row.isExpanded = !row.isExpanded;
    }
    this.collapsePreviouslyExpandedRow();
    this.currentlyExpandedRow = row.isExpanded ? row : undefined;
  };

  sortingDataAccessor(data: DenialCptCodeByReasonEntity, sortHeaderId: string) {
    return (sortHeaderId === 'cptCode' || sortHeaderId === 'denialReason') ?
      data[sortHeaderId] : Number((data[sortHeaderId] + '').replace(/[^0-9.-]/g, ''));
  }

  toggleExportOptions() {
    this.showExportOptions = !this.showExportOptions;
  }

  closeModal(): void {
    this.whenModalClosed.emit(false);
  }

  exportToExcel(): void {
    const dataIncludingChildren: DenialCptCodeByReasonEntity[] = [];
    this.denialRowHierarchy.forEach(parent => {
      if (parent.children && parent.children.length >= 1) {
        dataIncludingChildren.push(parent);
        parent.children.forEach(child => {
          dataIncludingChildren.push(<DenialCptCodeByReasonEntity>child);
        });
      } else {
        dataIncludingChildren.push(parent);
      }
    });
    let payerDescription = this.selectedPayer?.payerCategoryDesc;
    const {nationalPayerDescription, memberPayerDescription} = this.filters;
    payerDescription = payerDescription.concat(nationalPayerDescription ? ' : ' + nationalPayerDescription : '');
    payerDescription = payerDescription.concat(memberPayerDescription ? ' : ' + memberPayerDescription : '');
    const exportData = this.filters ? getCptDeniedModalExcelData(dataIncludingChildren, this.columns,
        this.copyRight, this.nodePathName,
        'Selected Date Range: (' + getSelectedDateRange(this.filters.dateRange) + ')',
        this.getLocationFiltersForExport(ExportType.excel),
        'Lag Period: ' + lagPeriods.find(lP => lP.key === this.filters.lagKey)?.name || 'All Activity',
        'Billing Area: ' + (this.filters.memberBillingArea.memberBillingAreaDescription || 'All Billing Areas'),
        'Payer: ' + payerDescription) :
      getCptDeniedModalExcelData(dataIncludingChildren, this.columns, this.copyRight, this.nodePathName);
    this.excelService.exportAsExcelFile({
      data: [exportData],
      sheets: ['CPTs Denied'],
      fileName: 'CPTs Denied'
    });
  }

  exportToCsv(): void {
    const dataIncludingChildren: DenialCptCodeByReasonEntity[] = [];
    this.denialRowHierarchy.forEach(parent => {
      if (parent.children && parent.children.length >= 1) {
        dataIncludingChildren.push(parent);
        parent.children.forEach(child => {
          dataIncludingChildren.push(<DenialCptCodeByReasonEntity>child);
        });
      } else {
        dataIncludingChildren.push(parent);
      }
    });
    const csvDatum = getCptDeniedExportDataForCsv(dataIncludingChildren,
      this.columns,
      'CPTs Denied',
      this.copyRight);
    let payerDescription = this.selectedPayer?.payerCategoryDesc;
    const {nationalPayerDescription, memberPayerDescription} = this.filters;
    payerDescription = payerDescription.concat(nationalPayerDescription ? ' : ' + nationalPayerDescription : '');
    payerDescription = payerDescription.concat(memberPayerDescription ? ' : ' + memberPayerDescription : '');
    this.csv.export(
      csvDatum.data,
      csvDatum.fileName,
      {
        fieldSeparator: '|',
        quoteStrings: '',
        headers: csvDatum.headers,
        title: csvDatum.title +
          '\n' + this.ngRedux.getState().data.selectedMemberData.memberDesc +
          '\n' + this.nodePathName +
          '\n' + 'Selected Date Range (' + getSelectedDateRange(this.ngRedux.getState().filters.dateRange) + ')' +
          '\n' + this.getLocationFiltersForExport(ExportType.csv) +
          '\n' + 'Billing Area: ' + (this.filters.memberBillingArea.memberBillingAreaDescription || 'All Billing Areas') +
          '\n' + 'Lag Period: ' + lagPeriods.find(l => l.key === this.filters.lagKey)?.name +
          (csvDatum.whatFilters?.showPayer ? '\n' + 'Payer: ' +
            payerDescription : ''),
        showTitle: (csvDatum.title !== undefined)
      });
  }

  private getLocationFiltersForExport(exportType: ExportType) {
    const {data, filters} = this.ngRedux.getState();

    const maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT, data.applicationConfigurationSettings) || 5;
    const selectedLocations = data.memberLocations.filter(loc => loc.currentlySelected);
    const additionalLocations = selectedLocations.length - maxLocations;
    const isSingleLocation = !selectedLocations.length;
    switch (exportType) {
      case ExportType.csv:
        return composeCurrentLocationFiltersForCsvExport(isSingleLocation, selectedLocations, filters);
      case ExportType.excel:
        return composeCurrentLocationFiltersForExcelExport(isSingleLocation, selectedLocations,
          maxLocations, additionalLocations, filters);
    }
  }

  private getFilterCriteriaForSelectedPayer() {
    return {
      ...this.ngRedux.getState().filters,
      nodePath: '' + this.getSelectedPayerNodePath(this.selectedPayer, this.ngRedux.getState().filters.nodePath),
      payerCategory: this.selectedPayer.payerType === 1
        ? {
          payerCategoryKey: this.selectedPayer.payerKey,
          payerCategoryCode: '',
          payerCategoryDescription: this.selectedPayer.payerCategoryDesc
        }
        : this.ngRedux.getState().filters.payerCategory,
      payerKey: this.selectedPayer.payerKey,
      payerType: this.selectedPayer.payerType
    };
  }

  private compare(a: any, b: any, sortHeaderId: string, sortAscending: boolean): number {
    const valueA = this.dataSource.sortingDataAccessor(a, sortHeaderId);
    const valueB = this.dataSource.sortingDataAccessor(b, sortHeaderId);
    const multiplier = sortAscending ? 1 : -1;
    if (valueA > valueB) {
      return multiplier;
    }
    if (valueA < valueB) {
      return -1 * multiplier;
    }
    return 0;
  }

  private collapsePreviouslyExpandedRow() {
    if (this.currentlyExpandedRow) {
      this.currentlyExpandedRow.isExpanded = false;
    }
  }

  private getSelectedPayerNodePath(selectedPayer: DenialPayerEntity, defaultNodePath: string) {
    const nodePathSegments = defaultNodePath.split('|');
    if (selectedPayer.providerNodePath) {
      return selectedPayer.providerNodePath;
    } else if (selectedPayer.specialtyNodePath) {
      const chosenSegments = nodePathSegments.filter(segment => segment.includes('' + selectedPayer.specialtyNodePath));
      return chosenSegments.length === 0 ? selectedPayer.specialtyNodePath : chosenSegments.join('|');
    } else if (selectedPayer.departmentNodePath) {
      const chosenSegments = nodePathSegments.filter(segment => segment.includes('' + selectedPayer.departmentNodePath));
      return chosenSegments.length === 0 ? selectedPayer.departmentNodePath : chosenSegments.join('|');
    }
    return defaultNodePath;
  }
}
