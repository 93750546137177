<div class="header-wrapper">
  <app-header [isExport]="true"></app-header>
  <div class="border"></div>
</div>
<div class="filter-wrapper">
 <div class="filter-data" id="node-name">{{nodePathSelection}}</div>
  <div class="filter-data">{{selectedDate}}</div>
  <div class="filter-data" id="payer">{{payerText}} </div>
  <div class="filter-data" id="visitType"> {{visitTypeText}} </div>
  <div class="filter-data" id="location"> {{locationText}} </div>
  <div *ngIf="communityBenchmarkAlert" class="community-benchmark-alert" >{{communityBenchmarkAlertText}}</div>
</div>
