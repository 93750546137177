export function isEllipsisActive (e: HTMLElement): boolean {
  return (e.offsetWidth < e.scrollWidth);
}

export enum TextPosition {
  STANDARD,
  SUB,
  SUPER
}

export interface StyledTextToken {
  text: string;
  textPosition: TextPosition;
}
