/* tslint:disable:max-line-length */
import {ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {BenchmarkOption, MetricLoadKeys, MultilevelTab} from '../../../shared/enums';
import {
  ApplicationConfigurationSetting,
  CptCategories,
  CustomGroupsDataAllFilters,
  EmDimension,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  FeatureToggleSetting,
  OntologyNode,
  ProviderMultilevelCptCounts
} from '../../../shared/models';
import {NgRedux, select} from '@angular-redux/store';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {getLevelTypeNodePath, LevelType} from '../../../shared/helpers';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {
  buildNodePath,
  CptRangeMap,
  EmergencyMedicineRanges,
  EyeExamRanges,
  getEmSnapshotColumnsWithExtendedBenchmarkOptions,
  InpatientRanges,
  OutpatientRanges
} from '../../em-helpers';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {
  emDimensionChangedTo,
  isProviderSelectedChangedTo,
  multilevelTabChangedTo,
  selectedNodesChangedTo
} from '../../../store/actions';
import {getOntologyData} from '../../../shared/localStoragehelper';
import {FilterSelectorService, FilterSelectorServiceToken} from '../../../services/filter-selector.service';
import {ApiServiceImpl, ApiServiceToken} from '../../../services/api.service';
import {DrillDownService} from '../../../services/drilldown.service';
import {
  DEPARTMENT_TAB,
  EM_EMERGENCY_TAB,
  EM_INPATIENT_TAB,
  EM_OPHTHALMOLOGY_TAB,
  EM_OUTPATIENT_TAB,
  PROVIDER_TAB,
  SPECIALTY_TAB,
  TREND_TAB
} from '../../../shared/constants';
import {filterCriteriaFromSelectedCustomGroup} from '../../../filter-banner/filter-banner-helper';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {AppConfigEntries} from '../../../shared/app-config-settings-enum';
import {getSelectedNodeCountsFromNodePath} from '../../../shared/ontology-helpers';
import {updateCommunityBenchmarkAlertStatus} from '../../../shared/reducer-helper';
import {ontologyTabEquivalentOfLocationTab, TabNavigationElement} from '../../../tab-navigation/tab-helper';
import {undefinedIfInvalid} from '../../../shared/null-helpers';
import {extendedBenchmarkOptions} from '../../../shared/benchmark-types';

@Component({
  selector: 'app-em-page-multilevel',
  templateUrl: './em-page-multilevel.component.html',
  styleUrls: ['./em-page-multilevel.component.scss']
})
export class EmPageMultilevelComponent implements OnInit, OnChanges, OnDestroy {

  @select(['data', 'customGroupsData'])
  private readonly customGroupsData$: Observable<CustomGroupsDataAllFilters[]>;

  @select(['data', 'evaluationManagementMultilevelData'])
  private readonly evaluationManagementMultilevel$: Observable<EvaluationManagementMultilevel>;

  @select(['data', 'summaryEvaluationManagementData'])
  private readonly providerEvaluationManagementSummaryData$: Observable<EvaluationManagementSummary>;

  @select(['data', 'specialtySummaryEvaluationManagementData'])
  private readonly providerEvaluationManagementSpecialtySummaryData$: Observable<EvaluationManagementSummary>;

  @select(['display', 'isProviderSelected'])
  private readonly isProviderSelected$: Observable<boolean>;

  @select(['display', 'multilevelTab'])
  private readonly multilevelTab$: Observable<MultilevelTab>;

  @select(['display', 'emDimension'])
  private readonly emDimension$: Observable<EmDimension>;

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['display', 'zeroSuppression'])
  private readonly zeroSuppression$: Observable<boolean>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly emBenchmarkOption$: Observable<BenchmarkOption>;
  @select(['data', 'applicationConfigurationSettings'])
  applicationConfigurationSettings$: Observable<ApplicationConfigurationSetting[]>;
  @select(['display', 'selectedProviders'])
  selectedProviders$: Observable<number>;
  @select(['display', 'selectedSpecialties'])
  selectedSpecialties$: Observable<number>;
  @select(['ontologyLoaded'])
  ontologyLoaded$: Observable<boolean>;
  @Input() overviewPageView: boolean;
  @Input() overviewHeight = 250;
  @Input() EandMHeight = 302;
  @Input() emMetrics: EmDimension[];
  @Input() fromPdf: boolean;
  @Input() batchMultilevelEMData: EvaluationManagementMultilevel | undefined = undefined;
  @Input() batchExportNodePath: string;
  providerEvaluationManagementMultilevelData: EvaluationManagementMultilevel;

  EmDimension = EmDimension;

  totalCptCounts?: CptCategories;
  specialtyCptCounts?: CptCategories;
  memberKey: number;
  tableTitle = '';
  showProgressBar: boolean;
  zeroSuppressionCondition = 'at least one encounter in a given CPT Range';

  isSingleProviderSelected = false;
  isZeroSuppressionChecked = false;

  numberOfSuppressions = 0;

  outpatientHasAcademic: boolean;
  outpatientHasCommunity: boolean;
  inpatientHasAcademic: boolean;
  inpatientHasCommunity: boolean;
  eyeExamHasAcademic: boolean;
  eyeExamHasCommunity: boolean;
  emergencyRoomHasAcademic: boolean;
  emergencyRoomHasCommunity: boolean;
  hasAcademic: boolean;
  hasCommunity: boolean;

  subscription: Subscription;
  nestedSubscription: Subscription;
  drilldownSubscription: Subscription;

  foundNodeName = '';
  nodePath: string;
  benchmarkPlaceholder: string;

  MultiLevelTab = MultilevelTab;
  selectedLevelTab: MultilevelTab = MultilevelTab.BY_DEPARTMENT;
  selectedDimensionTab: EmDimension = EmDimension.Outpatient;
  totalCptCountsDepartment: CptCategories;
  totalCptCountsSpecialty: CptCategories;
  totalCptCountsProvider: CptCategories;
  benchmarkOption: BenchmarkOption;
  multilevelTabChangedTo = multilevelTabChangedTo;
  emDimensionChangedTo = emDimensionChangedTo;
  filterSubscription: Subscription;
  featureToggleSubscription: Subscription;
  filters: FilterCriteria;

  levelTabs: TabNavigationElement[] = [];
  allLevelTabs: TabNavigationElement[] = [
    _.cloneDeep(DEPARTMENT_TAB),
    _.cloneDeep(SPECIALTY_TAB),
    _.cloneDeep(PROVIDER_TAB),
    _.cloneDeep(TREND_TAB)
  ];
  dimensionTabs: TabNavigationElement[] = [
    _.cloneDeep(EM_OUTPATIENT_TAB),
    _.cloneDeep(EM_INPATIENT_TAB),
    _.cloneDeep(EM_EMERGENCY_TAB),
    _.cloneDeep(EM_OPHTHALMOLOGY_TAB)
  ];
  snapshotColumns: DataTableColumns[] = [];
  snapshotData: ProviderMultilevelCptCounts[];

  constructor(private ngRedux: NgRedux<IAppState>,
              private _changeDetectorRef: ChangeDetectorRef,
              private drillDownService: DrillDownService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {
    this.filterSubscription = combineLatest([this.filterService.getFilters(), this.customGroupsData$,
      this.applicationConfigurationSettings$, this.selectedProviders$, this.selectedSpecialties$, this.ontologyLoaded$]).subscribe(
      ([filter, cg, appConfig, providers, specialties, ontologyLoaded]:
         [FilterCriteria, CustomGroupsDataAllFilters[], ApplicationConfigurationSetting[], number, number, boolean]) => {
        this.filters = filter;
        if (!!filter.nodePath) {
          const allPaths: string[] = filter.nodePath.split('|');
          const singleSpecialty = allPaths.length === 1 && allPaths[0].split('\\').length === 4;
          const singleProvider = allPaths.length === 1 && allPaths[0].split('\\').length === 5;
          let currentSpecialtyTag: string | undefined;
          let providersLimitedBySpecialty = !!allPaths.length;
          for (let i = 0; i < allPaths.length; i++) {
            const subPath = allPaths[i].split('\\');
            if (subPath.length < 4 || subPath[3] && currentSpecialtyTag && !(subPath[3] === currentSpecialtyTag)) {
              providersLimitedBySpecialty = false;
              break;
            }
            currentSpecialtyTag = subPath[3];
          }
          const specialtyNodePath = singleSpecialty || (providersLimitedBySpecialty && !singleProvider) ?
            this.filters.nodePath : singleProvider ? buildNodePath(allPaths[0].split('\\'), 0, 4)
              : undefined;
          const theDefaultGroup = cg.find(g => g.isDefault);
          const shouldBlockCalls = !filter.chosenByUser && theDefaultGroup && !_.isEqual(filterCriteriaFromSelectedCustomGroup(
            <CustomGroupsDataAllFilters>theDefaultGroup, ngRedux.getState()), filter);
          if (!shouldBlockCalls) {
            if (providers === 0 || specialties === 0 || !ontologyLoaded ||
              !appConfig.find(entry => entry.appConfigName.localeCompare(AppConfigEntries.MAX_PROVIDER_PATHS) === 0) ||
              !appConfig.find(entry => entry.appConfigName.localeCompare(AppConfigEntries.MAX_SPECIALTY_PATHS) === 0)) {
              localStorage.removeItem(MetricLoadKeys.IS_EM_SNAPSHOT_LOADED);
              const {selectedProviders, selectedSpecialties} = getSelectedNodeCountsFromNodePath(filter.nodePath);
              this.ngRedux.dispatch(selectedNodesChangedTo(selectedProviders, selectedSpecialties));
            } else {
              const specialtyFilter: FilterCriteria = {...filter, nodePath: specialtyNodePath || filter.nodePath};
              this.apiService.getEvaluationManagementData(filter, specialtyFilter);
            }
          }
        }
      });
  }

  ngOnInit() {
    this.featureToggleSubscription = this.featureToggleSettings$
      .subscribe((featureSettings: FeatureToggleSetting[]) => {
        if (!_.isEqual(featureSettings, INITIAL_STATE.data.featureToggleSettings)) {
          this.levelTabs = this.allLevelTabs;
          this._changeDetectorRef.detectChanges();
        }
        return combineLatest([this.emBenchmarkOption$]);
      });

    if (!this.emMetrics || this.emMetrics.length === 0) {
      this.emMetrics = [this.ngRedux.getState().display.emDimension];
    }

    this.subscription = combineLatest([
      this.evaluationManagementMultilevel$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.providerEvaluationManagementSummaryData$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.isProviderSelected$.pipe(distinctUntilChanged()),
      this.nodePath$.pipe(distinctUntilChanged()),
      this.providerEvaluationManagementSpecialtySummaryData$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.multilevelTab$.pipe(distinctUntilChanged()), this.emDimension$.pipe(distinctUntilChanged())
    ]).subscribe(
      ([providerEvaluationManagementMultilevelData,
         providerEvaluationManagementSummaryData,
         providerSelected,
         nodePath,
         providerEvaluationManagementSpecialtySummaryData,
         levelTab,
         dimensionTab]:
         [EvaluationManagementMultilevel, EvaluationManagementSummary, boolean, string, EvaluationManagementSummary, MultilevelTab, EmDimension]) => {
        levelTab = this.showProviderPageIfReduxLevelIsSpecialtyPerformance(levelTab);
        this.selectedLevelTab = levelTab;
        this.selectedDimensionTab = dimensionTab;
        this.nodePath = nodePath;
        this.isSingleProviderSelected = (getLevelTypeNodePath(nodePath) === LevelType.singleProvider);
        this.providerEvaluationManagementMultilevelData = providerEvaluationManagementMultilevelData;
        this.processEmMultiLevel(providerEvaluationManagementMultilevelData,
          providerEvaluationManagementSummaryData, providerSelected && this.isSingleProviderSelected, nodePath,
          providerEvaluationManagementSpecialtySummaryData);
      });
  }

  private showProviderPageIfReduxLevelIsSpecialtyPerformance(levelTab: MultilevelTab): MultilevelTab {
    return levelTab === MultilevelTab.SPECIALTY_PERFORMANCE ? MultilevelTab.BY_PROVIDER : ontologyTabEquivalentOfLocationTab(levelTab);
  }

  private findPlaceholderFromExtendedBenchmarkOptions(benchmarkOption: BenchmarkOption) {
    return extendedBenchmarkOptions.find(b => b.value === benchmarkOption)?.name ?? '';
  }

  ngOnChanges() {
    if (this.batchMultilevelEMData && this.batchExportNodePath) {
      this.processEmMultiLevel(this.batchMultilevelEMData, INITIAL_STATE.data.summaryEvaluationManagementData,
        this.isSingleProviderSelected, this.batchExportNodePath, INITIAL_STATE.data.summaryEvaluationManagementData);
    }
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    this.drilldownSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
    this.nestedSubscription?.unsubscribe();
    this.featureToggleSubscription?.unsubscribe();
  }

  processEmMultiLevel(providerEvaluationManagementMultilevelData: EvaluationManagementMultilevel,
                      providerEvaluationManagementSummaryData: EvaluationManagementSummary,
                      singleProviderSelected: boolean,
                      nodePath: string,
                      providerEvaluationManagementSpecialtySummaryData: EvaluationManagementSummary) {
    switch (this.selectedLevelTab) {
      case MultilevelTab.BY_SPECIALTY:
        this.hasBenchmarks(providerEvaluationManagementMultilevelData.evaluationManagementData
          .specialtyEvaluationManagementSnapshotData.nodes);
        this.snapshotData = this.providerEvaluationManagementMultilevelData.evaluationManagementData
          .specialtyEvaluationManagementSnapshotData.nodes.slice();
        break;
      case MultilevelTab.BY_PROVIDER:
        this.hasBenchmarks(providerEvaluationManagementMultilevelData.evaluationManagementData
          .providerEvaluationManagementSnapshotData.nodes);
        this.snapshotData = this.providerEvaluationManagementMultilevelData.evaluationManagementData
          .providerEvaluationManagementSnapshotData.nodes.slice();
        break;
      default:
        if (providerEvaluationManagementMultilevelData && providerEvaluationManagementMultilevelData.evaluationManagementData) {
          this.hasBenchmarks(providerEvaluationManagementMultilevelData.evaluationManagementData
            .departmentEvaluationManagementSnapshotData.nodes);
          this.snapshotData = this.providerEvaluationManagementMultilevelData.evaluationManagementData
            .departmentEvaluationManagementSnapshotData.nodes.slice();
        }
    }
    this.isSingleProviderSelected = singleProviderSelected;
    this._changeDetectorRef.detectChanges();

    this.foundNodeName = '';
    let singleSpecialtySelected = true;
    const individualNodePaths = this.nodePath.split('|').map(x => x.split('\\').slice(1, 4));
    individualNodePaths.forEach(path => {
      if (path.length < 3 || !_.isEqual(individualNodePaths[0], path)) {
        singleSpecialtySelected = false;
      }
    });

    if (singleSpecialtySelected || singleProviderSelected) {
      const ontologyHierarchy = getOntologyData().ontologyHierarchy;
      const specialtyPath = this.nodePath.split('\\').slice(1, 4);
      const foundNode = this.findNodeInOntologyHierarchyByNodePath(specialtyPath, ontologyHierarchy);
      if (foundNode) {
        this.foundNodeName = foundNode.nodeName;
      }
    }

    this.showProgressBar = _.isEqual(providerEvaluationManagementMultilevelData,
      INITIAL_STATE.data.evaluationManagementMultilevelData);
    if (providerEvaluationManagementMultilevelData &&
      providerEvaluationManagementMultilevelData.evaluationManagementData) {
      this.totalCptCountsDepartment = providerEvaluationManagementMultilevelData.evaluationManagementData
        .departmentEvaluationManagementSnapshotData.totals;
      this.totalCptCountsSpecialty = providerEvaluationManagementMultilevelData.evaluationManagementData
        .specialtyEvaluationManagementSnapshotData.totals;
      this.totalCptCountsProvider = providerEvaluationManagementMultilevelData.evaluationManagementData
        .providerEvaluationManagementSnapshotData.totals;
      this.specialtyCptCounts = providerEvaluationManagementSpecialtySummaryData.totals;
      this.nestedSubscription?.unsubscribe();
      this.nestedSubscription = combineLatest([this.zeroSuppression$, this.emBenchmarkOption$])
        .subscribe(([zeroSuppression, benchmarkOption]: [boolean, BenchmarkOption]) => {
          this.isZeroSuppressionChecked = zeroSuppression;
          this.benchmarkOption = benchmarkOption;
          this.benchmarkPlaceholder =
            this.findPlaceholderFromExtendedBenchmarkOptions(benchmarkOption);
          this.setColumns();
          this.analyticsService.handleGoogleAnalytics('E&M Snapshot',
            this.benchmarkPlaceholder, 'Toggling Benchmark');
        });
    }
  }

  setColumns() {
    this.snapshotColumns =
      getEmSnapshotColumnsWithExtendedBenchmarkOptions(this.selectedDimensionTab, this.benchmarkOption, this.selectedLevelTab);
  }

  public hasBenchmarks(evaluationData: ProviderMultilevelCptCounts[]) {
    this.outpatientHasAcademic = this.hasBenchmarkForCategory(evaluationData, OutpatientRanges, false);
    this.outpatientHasCommunity = this.hasBenchmarkForCategory(evaluationData, OutpatientRanges, true);
    this.inpatientHasAcademic = this.hasBenchmarkForCategory(evaluationData, InpatientRanges, false);
    this.inpatientHasCommunity = this.hasBenchmarkForCategory(evaluationData, InpatientRanges, true);
    this.eyeExamHasAcademic = this.hasBenchmarkForCategory(evaluationData, EyeExamRanges, false);
    this.eyeExamHasCommunity = this.hasBenchmarkForCategory(evaluationData, EyeExamRanges, true);
    this.emergencyRoomHasAcademic = this.hasBenchmarkForCategory(evaluationData, EmergencyMedicineRanges, false);
    this.emergencyRoomHasCommunity = this.hasBenchmarkForCategory(evaluationData, EmergencyMedicineRanges, true);

    this.hasAcademic = this.outpatientHasAcademic || this.inpatientHasAcademic || this.eyeExamHasAcademic || this.emergencyRoomHasAcademic;
    this.hasCommunity = this.outpatientHasCommunity || this.inpatientHasCommunity || this.eyeExamHasCommunity || this.emergencyRoomHasCommunity;

    updateCommunityBenchmarkAlertStatus(
      this.hasCommunity,
      this.hasAcademic,
      this.ngRedux,
      this.showProgressBar
    );
  }

  whenRowSelectedDepartment = (row: ProviderMultilevelCptCounts) => {
    this.analyticsService.handleGoogleAnalytics('Evaluation Management', row.departmentNodeName, 'Drill down on Table');
    this.updateSingleProviderSelection(false);
    this.drillDownService.drillDownIntoNode(row.departmentNodePath, this.nodePath, MultilevelTab.BY_DEPARTMENT);
    this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.BY_SPECIALTY));
  };

  whenRowSelectedSpecialty = (row: ProviderMultilevelCptCounts) => {
    this.analyticsService.handleGoogleAnalytics('Evaluation Management', row.specialtyNodeName || '', 'Drill down on Table');
    this.updateSingleProviderSelection(false);
    this.drillDownService.drillDownIntoNode(row.specialtyNodePath || '', this.nodePath, MultilevelTab.BY_SPECIALTY);
    this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.BY_PROVIDER));
  };

  whenRowSelectedProvider = (row: ProviderMultilevelCptCounts) => {
    this.analyticsService.handleGoogleAnalytics('Evaluation Management', row.providerNodeName || '', 'Drill down on Table');
    this.updateSingleProviderSelection(true);
    this.drillDownService.drillDownIntoNode(row.providerNodePath || '', this.nodePath, MultilevelTab.BY_PROVIDER);
    this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.TREND));
  };

  private updateSingleProviderSelection(isSingleProviderSelected: boolean) {
    this.isSingleProviderSelected = isSingleProviderSelected;
    this.ngRedux.dispatch(isProviderSelectedChangedTo(isSingleProviderSelected));
  }

  private findNodeInOntologyHierarchyByNodePath(selectedTagIds: string[], ontology: OntologyNode[]): OntologyNode | undefined {
    if (!selectedTagIds.length) {
      return undefined;
    }
    const currentNode = ontology.find(node => {
      return this.matchesMostGranularTagId(node, selectedTagIds);
    });
    return this.hasNoMoreNodesToTraverse(selectedTagIds, currentNode) ?
      currentNode : this.findNodeInOntologyHierarchyByNodePath(selectedTagIds.slice(1), currentNode?.children ?? []);
  }

  private hasNoMoreNodesToTraverse(selectedTagIds: string[], currentNode: OntologyNode | undefined) {
    return selectedTagIds.length === 1 || !currentNode;
  }

  private matchesMostGranularTagId(node: OntologyNode, selectedTagIds: string[]) {
    const currentNodeTagIds: string[] = node.nodePath.split('\\');
    return currentNodeTagIds[currentNodeTagIds.length - 1] === selectedTagIds[0];
  }

  private hasBenchmarkForCategory(emData: ProviderMultilevelCptCounts[], categoryRanges: CptRangeMap[], isCommunity: boolean): boolean {
    let hasBenchmarks = true;
    emData.find(datum => {
      categoryRanges.find(range => {
        range.codes.find(code => {
          const summaryForCode = undefinedIfInvalid(datum.cptCategories, range.range, 'cpt' + code);
          if (summaryForCode) {
            hasBenchmarks = !!summaryForCode[isCommunity ? 'communityBenchmarkPercentage' : 'benchmarkPercentage'];
            if (hasBenchmarks) {
              return true;
            }
          } else {
            hasBenchmarks = true;
            return true;
          }
        });
        if (hasBenchmarks) {
          return true;
        }
      });
      if (hasBenchmarks) {
        return true;
      }
    });

    return hasBenchmarks;
  }

  whenZeroSuppressed(countOfEntries: number): void {
    this.numberOfSuppressions = countOfEntries;
  }
}
