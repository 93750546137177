import {Legend} from '../shared/models';
import {LegendColor, LegendStyle} from '../shared/enums';
import {MetricType} from '../shared/metric-types';
import {deepCopyOf} from '../shared/helpers';


export function getEmLegends(specialtyName: string, isSingleProvider?: boolean): Legend[] {

  const legends = deepCopyOf(emLegends);
  if (isSingleProvider) {
    addCodingDistributionLegend(legends, specialtyName);
  }
  return legends;
}

function addCodingDistributionLegend(legendArray: Legend[], specialtyName: string) {
  legendArray.push({
    text: `% Distribution ${specialtyName}`,
    color: LegendColor.GREEN,
    style: LegendStyle.DIAMOND,
    metric: MetricType.EandM,
    showBenchmarkOptionControl: false,
    showPercentileControl: false,
    isAddOnVariable: false
  });
}

const emLegends = [{
  text: '% Coding Distribution',
  color: LegendColor.BLUE,
  style: LegendStyle.SQUARE,
  metric: MetricType.EandM,
  showBenchmarkOptionControl: false,
  showPercentileControl: false,
  isAddOnVariable: false
}, {
  text: 'Benchmark Mean',
  color: LegendColor.GREY,
  style: LegendStyle.SQUARE,
  metric: MetricType.EandM,
  showBenchmarkOptionControl: true,
  showPercentileControl: false,
  isAddOnVariable: false
}];
