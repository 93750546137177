export enum MetricType {
  None = 0,
  WorkRVUs = 1,
  EandM = 2,
  NewPatientVisits = 3,
  Overview = 4,
  ClinicalFingerprint = 5,
  Collections = 6,
  EandMOutpatient = 7,
  EandMInpatient = 8,
  EandMEmergency = 9,
  EandMOpthalmology = 10,
  Denials = 11,
  ProcedureSummary = 12,
  OverviewEandM = 13,
  OverviewNpv = 14,
  OverviewWrvu = 15,
  EandMTrend = 16
}

interface ModifierTab {
  name: string;
  active: boolean;
}

export class ModifierTabSeries {
  tabs: ModifierTab[];

  constructor(tabs: ModifierTab[]) {
    this.tabs = tabs;
    tabs.forEach(x => {
      if (x.active) {
        this.disableOthers(x);
      }
    });
  }

  activateTab(tab: ModifierTab) {
    tab.active = true;
    this.disableOthers(tab);
  }

  private disableOthers(tab: ModifierTab): void {
    this.tabs?.filter(tb => tb.name !== tab.name).forEach(x => {
      x.active = false;
    });
  }
}
