import {filter, map, mergeMap} from 'rxjs/operators';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {Title} from '@angular/platform-browser';
import {MatSidenav} from '@angular/material/sidenav';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from './store/IAppState';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('appDrawer', {static: true}) appDrawer: MatSidenav;
  routeSubscription: Subscription;
  activeRouteSubscription: Subscription;
  appDrawerCloseSubscription: Subscription;
  appDrawerOpenSubscription: Subscription;

  constructor(
    // @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
    private router: Router,
    private ngRedux: NgRedux<IAppState>,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    // this.routeSubscription = this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.analyticsService.sendPageView(event.urlAfterRedirects);
    //   }
    // });
  }

  ngOnInit(): void {
    Highcharts.setOptions({lang: {thousandsSep: ','}, chart: {marginRight: 50}});
    (function (H) {
      // @ts-ignore
      H.wrap(H.Axis.prototype, 'drawCrosshair', function (proceed) {
        const axis = this;
        const wasRendered = !!axis.cross;
        proceed.apply(axis, Array.prototype.slice.call(arguments, 1));

        if (!wasRendered && axis.cross && axis.options.clickOnCrosshair) {
          axis.cross.on('click', function (e: any) {
            const point = axis.chart.hoverPoint;
            axis.options.clickOnCrosshair(e, point);
            e.stopPropagation();
          });
          axis.cross.css({
            pointerEvents: 'auto',
            cursor: 'pointer',
          });
        }
      });
    })(Highcharts);

    this.activeRouteSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => this.titleService.setTitle(event['title']));
  }

  ngAfterViewInit() {
    this.appDrawerCloseSubscription = this.appDrawer.closedStart.subscribe(() => {
      const appBody = document.getElementById('app-body');
      if (appBody != null) {
        appBody.style.overflow = 'scroll';
      }
    });

    this.appDrawerOpenSubscription = this.appDrawer.openedStart.subscribe(() => {
      const appBody = document.getElementById('app-body');
      if (appBody != null) {
        appBody.style.overflow = 'hidden';
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.activeRouteSubscription?.unsubscribe();
    this.appDrawerCloseSubscription?.unsubscribe();
    this.appDrawerOpenSubscription?.unsubscribe();
  }
}
