<div id="pane-header">
  <div id="pane-title">Multi-Provider Export Summary</div>
  <span id="header-info-wrapper">
    <span id="report-name-wrapper">
      <span id="report-name">{{schedulePaneInfo?.reportName}}</span>
      <span id="generated-monthly" *ngIf="schedulePaneInfo?.isMonthly">Generated monthly</span>
    </span>
    <span id="number-of-files">
      {{numberOfFiles}} files for {{numberOfProviders}} providers
    </span>
  </span>
  <app-mpe-summary-pane-row [multiProviderExportPreview]="multiProviderExportMetricPreviews[0]" [isFirstRow]="true">
  </app-mpe-summary-pane-row>
</div>
<div id="pane-body">
  <div id="main-section-rows-wrapper">
    <div id="summary-pane-overview-rows-wrapper">
      <app-mpe-summary-pane-row *ngFor="let preview of multiProviderExportOverviewPreviews"
                              [multiProviderExportPreview]="preview" [isFirstRow]="false">
      </app-mpe-summary-pane-row>
    </div>
    <div id="summary-pane-metric-rows-wrapper">
      <app-mpe-summary-pane-row *ngFor="let preview of multiProviderExportMetricPreviews.slice(1)"
                              [multiProviderExportPreview]="preview" [isFirstRow]="false">
      </app-mpe-summary-pane-row>
    </div>
  </div>
</div>
