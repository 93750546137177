import {Component, Input} from '@angular/core';
import {SchedulePane} from '../mpe-dialog-helper';

@Component({
  selector: 'app-mpe-success-page',
  templateUrl: './mpe-success-page.component.html',
  styleUrls: ['./mpe-success-page.component.scss']
})
export class MpeSuccessPageComponent {
  @Input() schedulePaneInfo: SchedulePane;
  readonly reportInfoItemClass = 'report-info-item';
  readonly topMessage = 'You will receive an email when your export is ready for download. ';
  readonly bottomMessage = 'Depending on the size of your export, please allow 24 hours for processing.' +
    ' Most exports will complete within 2 hours.';
  readonly monthlyAddition = 'Your export will be generated now and every month when data load happens.';
  constructor() { }
}
