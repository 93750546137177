import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExternalRequestDetail} from '../shared/models';
import {environment} from '../../environments/environment.local';
import {of} from 'rxjs';
import {Observable} from 'rxjs';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs/operators';
import {catchError} from 'rxjs/operators';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {requestIdLoadedTo} from '../store/actions';

@Injectable({
  providedIn: 'root'
})
export class ExternalRequestService {

  constructor(private readonly httpClient: HttpClient, private router: Router, private ngRedux: NgRedux<IAppState>) {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationStart && event.url?.includes('requestId')))
      .subscribe(() => {
        const requestId = +this.router.getCurrentNavigation()?.extractedUrl.queryParams.requestId;
        if (requestId) {
          this.ngRedux.dispatch(requestIdLoadedTo(requestId));
        }
      });
  }

  initializeRequestDetails(requestId: number): Observable<ExternalRequestDetail | null> {
    return this.httpClient
      .get<ExternalRequestDetail>(`${environment.api}/api/externalRequestDetails?requestId=${requestId}`)
      .pipe(catchError((error: Error) => {
        return of(null);
      }));
  }
}
