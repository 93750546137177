import {BaseColumn, SummaryData} from '../../store/IAppState';
import {ProductivitySnapshot, ProductivitySummary} from '../../productivity-summary/services/ProviderProductivity';
import {
  deepCopyOf,
  getDifferenceFrom,
  getDifferencePercentage,
  getGroupName,
  getSelectedDateRange,
  getSingularOntologyLevelName,
  toTitleCase
} from '../helpers';
import {
  formatNumberToWholeNumber,
  formatToPercentage,
  roundTo,
  roundToWithCommasSeparation
} from '../../productivity-summary/number-formatter';
import {checkForNulls, isValidOrElse} from '../null-helpers';
import {
  BenchmarkPercentilesForNpv,
  filterOutBenchmarkRelatedColumns,
  imputedCFTEFor,
  varianceForWrvuTrend,
  varianceType
} from '../BenchmarkColumns';
import {
  EvaluationManagementEmergencyRoomRow,
  EvaluationManagementEyeExamRow,
  EvaluationManagementInpatientRow,
  EvaluationManagementOutpatientRow,
  Export,
  ExportMetadata
} from './export';
import {
  DateRange,
  EmDimension,
  EvaluationManagement,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  MergedProductivityTrendEntry,
  NewPatientVisitSummary,
  ProviderMultilevelCptCounts
} from '../models';
import {
  BenchmarkPercentile,
  extendedBenchmarkOptions,
  originalBenchmarkOptions,
  readableNameOf
} from '../benchmark-types';
import {
  calculateBreadcrumbs,
  getFilterInfo,
  getRowForCPTAnalysisExport,
  getVarianceFromTypeForNpvSnapshot
} from './export-helper';
import {toMonthName} from '../../productivity-summary/month-formatter';
import {BenchmarkOption, ColumnType, MultilevelTab} from '../enums';
import {ClinicalSummaryCode} from '../../clinical-fingerprint/ClinicalSummary';
import {
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkFieldFromOriginalBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromOriginalBenchmarkOptions,
  getEmergencyByDepartmentTableRowsFrom,
  getEmergencyByProviderTableRowsFrom,
  getEmergencyBySpecialtyTableRowsFrom,
  getEmergencyTableRowsFrom,
  getEmTrendColumnsWithExtendedBenchmarkOptions,
  getEyeExamByDepartmentTableRowsFrom,
  getEyeExamByProviderTableRowsFrom,
  getEyeExamBySpecialtyTableRowsFrom,
  getEyeExamTableRowsFrom,
  getInpatientByDepartmentTableRowsFrom,
  getInpatientByProviderTableRowsFrom,
  getInpatientBySpecialtyTableRowsFrom,
  getInpatientTableRowsFrom,
  getOutpatientByDepartmentTableRowsFrom,
  getOutpatientByProviderTableRowsFrom,
  getOutpatientBySpecialtyTableRowsFrom,
  getOutpatientTableRowsFrom,
  isAllowedToShow,
  parametersForZeroSuppression,
  valueFromOrElse
} from '../../evaluation-management/em-helpers';
import {DataTableColumns} from '../data-table-columns';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedNpvLocationAggregatedByNode,
  NewPatientVisitTrendEntry,
  NpvLocationWithSnapshotEntries
} from '../../new-patient-visits/components/npv-models';
import {
  getDesignatedNpvBenchmarkObject,
  getDesignatedNpvVarianceObject,
  getLevelSpecificNpvLocationTableData,
  replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor,
  replaceNpvSnapshotVarianceColumnWithBenchmarkFor
} from '../../new-patient-visits/components/npv-helpers';
import {getRowForWRVUTrendExport, nullifyWrvuVarianceForTrend} from './wrvu-export-helper';
import {
  getBenchmarkPercentileForExport,
  getBenchMarkSummaryPercentileForExport,
  getExportBenchmarkPercentile,
  getExportSummaryBenchmarkPercentile,
  getRowForNpvSnapshotExport,
  getRowForNpvTrendExport,
  lookupVarianceValue
} from './npv-export-helper';
import {getRowForDenialsExport, getRowForDenialsRateExport} from './denials-export-helper';
import {DenialsEntity} from '../../denials/denials-models';
import {
  NpvSummaryData,
  populateSummaryDataForAllBenchmarksForExcel
} from '../../new-patient-visits/components/new-patient-visits-summary-data-table/new-patient-visits-summary-data-table.component';
import {BehaviorSubject} from 'rxjs';
import {
  npvByLocationByDepartmentExcelData$,
  npvByLocationByProviderExcelData$,
  npvByLocationBySpecialtyExcelData$
} from '../../export-dialog-multilevel/export-subscribers';

export function getWrvuSnapshotExportSummaryData(
  summaryData: SummaryData<ProductivitySummary>,
  viewCommunityBenchmarks: boolean
): string[][] {
  const result: string[][] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      result.push([
        getGroupName(summaryData[dateRange], dateRange),
        formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].cfteAdjustedWRVUs)),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmarkMean)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmarkMean),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark25thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark25thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark50thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark50thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark65thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark65thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark75thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark75thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark90thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark90thPercentile),
        formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].wRVUs)),
        roundToWithCommasSeparation(summaryData[dateRange].cfte, 2),
        '-',
        '-',
        '-',
        '-',
        '-',
        '-',
        formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].charges)),
        '-',
        '-',
        '-',
        '-',
        '-',
        '-',
        '-',
        '-'
      ]);
    }
  }

  return result;
}

export function getWrvuSnapshotExportDetailData(
  providerData: ProductivitySnapshot[],
  viewCommunityBenchmarks: boolean
): string[][] {
  const result: string[][] = [];
  providerData.forEach((providerProductivity: ProductivitySnapshot) => {
    result.push([providerProductivity.nodeName,
      formatNumberToWholeNumber(checkForNulls(providerProductivity.cfteAdjustedWRVUs)),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(providerProductivity.communityBenchmarkMean)
        : formatNumberToWholeNumber(providerProductivity.benchmarkMean),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(providerProductivity.communityBenchmark25thPercentile)
        : formatNumberToWholeNumber(providerProductivity.benchmark25thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(providerProductivity.communityBenchmark50thPercentile)
        : formatNumberToWholeNumber(providerProductivity.benchmark50thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(providerProductivity.communityBenchmark65thPercentile)
        : formatNumberToWholeNumber(providerProductivity.benchmark65thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(providerProductivity.communityBenchmark75thPercentile)
        : formatNumberToWholeNumber(providerProductivity.benchmark75thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(providerProductivity.communityBenchmark90thPercentile)
        : formatNumberToWholeNumber(providerProductivity.benchmark90thPercentile),
      formatNumberToWholeNumber(checkForNulls(providerProductivity.wRVUs)),
      roundToWithCommasSeparation(providerProductivity.cfte, 2),
      formatToPercentage(imputedCFTEFor(providerProductivity, BenchmarkPercentile.Mean, viewCommunityBenchmarks), 1),
      formatToPercentage(imputedCFTEFor(providerProductivity,
        BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks), 1),
      formatToPercentage(imputedCFTEFor(providerProductivity,
        BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks), 1),
      formatToPercentage(imputedCFTEFor(providerProductivity,
        BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks), 1),
      formatToPercentage(imputedCFTEFor(providerProductivity,
        BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks), 1),
      formatToPercentage(imputedCFTEFor(providerProductivity,
        BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks), 1),
      formatNumberToWholeNumber(checkForNulls(providerProductivity.charges)),
      formatNumberToWholeNumber(varianceType(providerProductivity,
        BenchmarkPercentile.Mean, viewCommunityBenchmarks)),
      formatNumberToWholeNumber(varianceType(providerProductivity,
        BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks)),
      formatNumberToWholeNumber(varianceType(providerProductivity,
        BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks)),
      formatNumberToWholeNumber(varianceType(providerProductivity,
        BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks)),
      formatNumberToWholeNumber(varianceType(providerProductivity,
        BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks)),
      formatNumberToWholeNumber(varianceType(providerProductivity,
        BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks)),
      formatNumberToWholeNumber(checkForNulls(providerProductivity.previousCfteAdjustedWRVUs)),
      formatNumberToWholeNumber(providerProductivity.difference)]);
  });
  return result;
}

export function getWrvuSnapshotData(summaryData: SummaryData<ProductivitySummary>,
                                    providerData: ProductivitySnapshot[],
                                    level: string,
                                    viewCommunityBenchmarks: boolean,
                                    nodePath?: string,
                                    dateRange?: any,
                                    memberLocationName?: any
): ExportMetadata {
  const copyRight = 'Clinical Practice Solutions Center Productivity Summary ' + new Date().toLocaleString() + ';';
  let summaryHeaders = [''];
  let detailHeaders = [''];
  if (viewCommunityBenchmarks) {
    summaryHeaders = ['Time Range',
      'cfteAdjustedWRVU',
      'communityBenchmarkMean',
      'communityBenchmark25th',
      'communityBenchmark50th',
      'communityBenchmark65th',
      'communityBenchmark75th',
      'communityBenchmark90th',
      'wRVUs',
      'cfte',
      'imputedReportedCfteMean',
      'imputedReportedCfte25th',
      'imputedReportedCfte50th',
      'imputedReportedCfte65th',
      'imputedReportedCfte75th',
      'imputedReportedCfte90th',
      'charges',
      'communityVarianceMean',
      'communityVariance25th',
      'communityVariance50th',
      'communityVariance65th',
      'communityVariance75th',
      'communityVariance90th',
      'previousCfteAdjustedWRVU',
      'difference'];
    detailHeaders = [toTitleCase(level),
      'cfteAdjustedWRVU',
      'communityBenchmarkMean',
      'communityBenchmark25th',
      'communityBenchmark50th',
      'communityBenchmark65th',
      'communityBenchmark75th',
      'communityBenchmark90th',
      'wRVUs',
      'cfte',
      'imputedReportedCfteMean',
      'imputedReportedCfte25th',
      'imputedReportedCfte50th',
      'imputedReportedCfte65th',
      'imputedReportedCfte75th',
      'imputedReportedCfte90th',
      'charges',
      'communityVarianceMean',
      'communityVariance25th',
      'communityVariance50th',
      'communityVariance65th',
      'communityVariance75th',
      'communityVariance90th',
      'previousCfteAdjustedWRVU',
      'difference'];
  } else {
    summaryHeaders = ['Time Range',
      'cfteAdjustedWRVU',
      'benchmarkMean',
      'benchmark25th',
      'benchmark50th',
      'benchmark65th',
      'benchmark75th',
      'benchmark90th',
      'wRVUs',
      'cfte',
      'imputedReportedCfteMean',
      'imputedReportedCfte25th',
      'imputedReportedCfte50th',
      'imputedReportedCfte65th',
      'imputedReportedCfte75th',
      'imputedReportedCfte90th',
      'charges',
      'varianceMean',
      'variance25th',
      'variance50th',
      'variance65th',
      'variance75th',
      'variance90th',
      'previousCfteAdjustedWRVU',
      'difference'];
    detailHeaders = [toTitleCase(level),
      'cfteAdjustedWRVU',
      'benchmarkMean',
      'benchmark25th',
      'benchmark50th',
      'benchmark65th',
      'benchmark75th',
      'benchmark90th',
      'wRVUs',
      'cfte',
      'imputedReportedCfteMean',
      'imputedReportedCfte25th',
      'imputedReportedCfte50th',
      'imputedReportedCfte65th',
      'imputedReportedCfte75th',
      'imputedReportedCfte90th',
      'charges',
      'varianceMean',
      'variance25th',
      'variance50th',
      'variance65th',
      'variance75th',
      'variance90th',
      'previousCfteAdjustedWRVU',
      'difference'];
  }

  return {
    summaryHeaders: summaryHeaders,
    summaryData: getWrvuSnapshotExportSummaryData(summaryData, viewCommunityBenchmarks),
    detailHeaders: detailHeaders,
    detailData: getWrvuSnapshotExportDetailData(providerData, viewCommunityBenchmarks),
    fileName: ('Wrvu ' + level),
    page: 'Wrvu ' + level,
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getWrvuTrendExportSummaryData(summaryData: SummaryData<ProductivitySummary>,
                                              viewCommunityBenchmarks: boolean)
  : string[][] {

  const result: string[][] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      result.push([
        getGroupName(summaryData[dateRange], dateRange),
        formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].cfteAdjustedWRVUs)),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmarkMean)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmarkMean),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark25thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark25thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark50thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark50thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark65thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark65thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark75thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark75thPercentile),
        viewCommunityBenchmarks ? formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark90thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].benchmark90thPercentile),
        formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].wRVUs)),
        roundToWithCommasSeparation(summaryData[dateRange].cfte, 2),
        formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].charges))
      ]);
    }
  }
  return result;
}

export function getWrvuTrendExportDetailData(monthData: MergedProductivityTrendEntry[],
                                             displayedColumns: BaseColumn[])
  : string[][] {

  const result: string[][] = [];
  monthData.forEach(x => {
    result.push(getRowForWRVUTrendExport(x, displayedColumns));
  });
  return result;
}


export function getWrvuTrendDataForDisplayedColumns(summaryData: SummaryData<ProductivitySummary>,
                                                    trendData: MergedProductivityTrendEntry[],
                                                    displayedColumns: BaseColumn[],
                                                    copyright: string,
                                                    viewCommunityBenchmarks: boolean,
                                                    nodePath?: string,
                                                    dateRange?: any,
                                                    memberLocationName?: any
): ExportMetadata {
  trendData = nullifyWrvuVarianceForTrend(trendData);

  displayedColumns = displayedColumns.filter(x => x.columnDef !== 'month');

  const summaryHeaders = !viewCommunityBenchmarks ?
    ['Time Range', 'cFTE Adj. wRVUs', `Academic wRVU Benchmark (Mean)`, `Academic wRVU Benchmark (25th)`,
      `Academic wRVU Benchmark (50th)`, `Academic wRVU Benchmark (65th)`, `Academic wRVU Benchmark (75th)`,
      `Academic wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges'
    ] : ['Time Range', 'cFTE Adj. wRVUs', `Community wRVU Benchmark (Mean)`, `Community wRVU Benchmark (25th)`,
      `Community wRVU Benchmark (50th)`, `Community wRVU Benchmark (65th)`, `Community wRVU Benchmark (75th)`,
      `Community wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges'
    ];

  let headers = ['Date'];

  const benchmarkHeaders = !viewCommunityBenchmarks ? ['Academic wRVU Benchmark (Mean)', 'Academic wRVU Benchmark (25th)',
      'Academic wRVU Benchmark (50th)', 'Academic wRVU Benchmark (65th)',
      'Academic wRVU Benchmark (75th)', 'Academic wRVU Benchmark (90th)'] :
    ['Community wRVU Benchmark (Mean)', 'Community wRVU Benchmark (25th)',
      'Community wRVU Benchmark (50th)', 'Community wRVU Benchmark (65th)',
      'Community wRVU Benchmark (75th)', 'Community wRVU Benchmark (90th)'];
  const varianceHeaders = !viewCommunityBenchmarks ? ['Academic Variance from Benchmark (Mean)',
      'Academic Variance from Benchmark (25th)',
      'Academic Variance from Benchmark (50th)', 'Academic Variance from Benchmark (65th)',
      'Academic Variance from Benchmark (75th)', 'Academic Variance from Benchmark (90th)'] :
    ['Community Variance from Benchmark (Mean)',
      'Community Variance from Benchmark (25th)',
      'Community Variance from Benchmark (50th)', 'Community Variance from Benchmark (65th)',
      'Community Variance from Benchmark (75th)', 'Community Variance from Benchmark (90th)'];


  const benchmarkData: BaseColumn[] = !viewCommunityBenchmarks ? [
    {
      columnDef: 'benchmarkMean',
      header: 'Academic wRVU Benchmark (Mean)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark25thPercentile',
      header: 'Academic wRVU Benchmark (25th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark50thPercentile',
      header: 'Academic wRVU Benchmark (50th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark65thPercentile',
      header: 'Academic wRVU Benchmark (65th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark75thPercentile',
      header: 'Academic wRVU Benchmark (75th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark90thPercentile',
      header: 'Academic wRVU Benchmark (90th)',
      columnType: ColumnType.BENCHMARK
    }
  ] : [
    {
      columnDef: 'communityBenchmarkMean',
      header: 'Community wRVU Benchmark (Mean)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'communityBenchmark25thPercentile',
      header: 'Community wRVU Benchmark (25th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'communityBenchmark50thPercentile',
      header: 'Community wRVU Benchmark (50th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'communityBenchmark65thPercentile',
      header: 'Community wRVU Benchmark (65th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'communityBenchmark75thPercentile',
      header: 'Community wRVU Benchmark (75th)',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'communityBenchmark90thPercentile',
      header: 'Community wRVU Benchmark (90th)',
      columnType: ColumnType.BENCHMARK
    }
  ];
  const varianceData: BaseColumn[] = !viewCommunityBenchmarks ? [
    {
      columnDef: 'varianceMean',
      header: 'Academic Variance (Mean)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance25thPercentile',
      header: 'Academic Variance (25th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance50thPercentile',
      header: 'Academic Variance (50th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance65thPercentile',
      header: 'Academic Variance (65th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance75thPercentile',
      header: 'Academic Variance (75th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance90thPercentile',
      header: 'Academic Variance (90th)',
      columnType: ColumnType.VARIANCE
    }
  ] : [
    {
      columnDef: 'communityVarianceMean',
      header: 'Community Variance (Mean)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'communityVariance25thPercentile',
      header: 'Community Variance (25th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'communityVariance50thPercentile',
      header: 'Community Variance (50th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'communityVariance65thPercentile',
      header: 'Community Variance (65th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'communityVariance75thPercentile',
      header: 'Community Variance (75th)',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'communityVariance90thPercentile',
      header: 'Community Variance (90th)',
      columnType: ColumnType.VARIANCE
    }
  ];

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = filterOutBenchmarkRelatedColumns(displayedColumns);

  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }
  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  return {
    summaryHeaders: summaryHeaders,
    summaryData: getWrvuTrendExportSummaryData(summaryData, viewCommunityBenchmarks),
    detailHeaders: headers,
    detailData: getWrvuTrendExportDetailData(trendData, remainingColumns),
    fileName: ('wRVUbyMonths'),
    page: 'Wrvu Trend',
    title: copyright,
    copyright: copyright,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getNpvTrendExportSummaryData(summaryData: SummaryData<NewPatientVisitSummary>,
                                             viewCommunityBenchmarks: boolean, benchmarkOption: BenchmarkOption | undefined): string[][] {
  const result: string[][] = [];

  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      const npvPercentage = roundTo(summaryData[dateRange].newPatientVisitsPercentage, 1);
      result.push([
        getGroupName(summaryData[dateRange], dateRange),
        npvPercentage === '-' ? npvPercentage : npvPercentage + '%',
        formatNumberToWholeNumber(summaryData[dateRange].countOfNewPatientVisits),
        formatNumberToWholeNumber(summaryData[dateRange].countOfTotalPatientVisits),
        getExportSummaryBenchmarkPercentile(summaryData[dateRange], BenchmarkPercentile.Mean, viewCommunityBenchmarks, benchmarkOption),
        getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks, benchmarkOption),
        getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks, benchmarkOption),
        getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks, benchmarkOption),
        getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks, benchmarkOption),
        '-',
        '-',
        '-',
        '-',
        '-',
        '-',
        '-'
      ]);
    }
  }
  return result;
}

export function getCPTAnalysisExcelData(cptClinicalDetail: ClinicalSummaryCode[],
                                        copyRight: string, cptCodeColumns: any[], dateRange: any,
                                        viewCommunityBenchmarks: boolean,
                                        nodePath?: string,
                                        location?: string
) {

  const headers: string[] = [];
  const date = getSelectedDateRange(dateRange, false);
  const displayedColumns = deepCopyOf(cptCodeColumns);
  for (let i = 0; i < displayedColumns.length; i++) {
    headers.push(displayedColumns[i].header);
  }
  return {
    detailData: getCPTAnalysisDetailData(cptClinicalDetail, displayedColumns),
    detailHeaders: headers,
    copyright: copyRight,
    page: 'CPT Analysis',
    fileName: 'CPT Analysis',
    isBlankRowAfterSummary: false,
    filterInfo: getFilterInfo(nodePath, date, location)
      .concat(viewCommunityBenchmarks ? 'Community Benchmark' : 'Academic Benchmark')
  };
}

export function getCPTAnalysisDetailData(cptClinicalDetail: ClinicalSummaryCode[],
                                         displayedColumns: any[]): string[][] {

  const result: string[][] = [];
  cptClinicalDetail.forEach(x => {
    result.push(getRowForCPTAnalysisExport(x, displayedColumns));
  });
  return result;
}

export function getDenialsExcelData(
  denialsDataCallback: (data: DenialsEntity[], columns: DataTableColumns[]) => string[][],
  denials: DenialsEntity[],
  denialsColumns: DataTableColumns[],
  dateRange: DateRange,
  nodePath: string,
  locationName?: string,
  lagPeriodName?: string,
  billingAreaDescription?: string,
  payerDescription?: string
) {
  const copyrightYear = new Date().getFullYear() - 1;
  const copyRight = 'Clinical Practice Solutions Center Denials; CPT® only © ' + copyrightYear +
    ' American Medical Association. All Rights Reserved ' + new Date().toLocaleString() + ';';
  const location = 'Location Filters: ' + locationName;
  const lagPeriod = 'Lag Period: ' + lagPeriodName;
  const memberBillingArea = 'Billing Area: ' + billingAreaDescription;
  const payerCategory = 'Payer Info: ' + payerDescription;

  const headers: string[] = [];
  const date = 'Selected Date Range (' + getSelectedDateRange(dateRange, false) + ')';
  for (let i = 0; i < denialsColumns.length; i++) {
    headers.push(denialsColumns[i].header);
  }
  return {
    detailData: denialsDataCallback(denials, denialsColumns),
    detailHeaders: headers,
    copyright: copyRight,
    page: 'CPT Analysis',
    fileName: 'CPT Analysis',
    isBlankRowAfterSummary: true,
    filterInfo: getFilterInfo(nodePath, date, location,
      lagPeriod, memberBillingArea, payerCategory)
  };
}


export function getDenialsDetailExcelData(denials: DenialsEntity[],
                                          denialsColumns: DataTableColumns[],
                                          dateRange: DateRange,
                                          nodePath: string,
                                          locationName?: string,
                                          lagPeriodName?: string,
                                          billingAreaDescription?: string,
                                          payerDescription?: string
) {
  return getDenialsExcelData(
    getDenialsDetailData,
    denials,
    denialsColumns,
    dateRange,
    nodePath,
    locationName,
    lagPeriodName,
    billingAreaDescription,
    payerDescription);
}

export function getDenialsRateExcelData(denials: DenialsEntity[],
                                        denialsColumns: DataTableColumns[],
                                        dateRange: any,
                                        nodePath: string,
                                        locationName?: string,
                                        lagPeriodName?: string,
                                        billingAreaDescription?: string,
                                        payerDescription?: string
) {
  return getDenialsExcelData(
    getDenialsRateDetailData,
    denials,
    denialsColumns,
    dateRange,
    nodePath,
    locationName,
    lagPeriodName,
    billingAreaDescription,
    payerDescription);
}

export function getDenialsDetailData(denials: DenialsEntity[],
                                     displayedColumns: DataTableColumns[]): string[][] {

  const result: string[][] = [];
  if (denials) {
    denials.forEach(x => {
      result.push(getRowForDenialsExport(x, displayedColumns));
    });
  }
  return result;
}

export function getDenialsRateDetailData(denials: DenialsEntity[],
                                         displayedColumns: DataTableColumns[]): string[][] {

  const result: string[][] = [];
  denials.forEach(x => {
    result.push(getRowForDenialsRateExport(x, displayedColumns));
  });
  return result;
}

function getBenchmarkOptionName(benchmarkOption: BenchmarkOption, telehealthEnabled = false) {
  const benchmarkArray = telehealthEnabled ? extendedBenchmarkOptions : originalBenchmarkOptions;
  const senary = benchmarkArray.find(o => o.value === benchmarkOption);
  return ` ${senary ? senary.name : benchmarkArray[0]}`;
}

function getNpvBenchmarkColumns(benchmarkOption: BenchmarkOption, benchmarkText: string) {
  const benchmarkData: BaseColumn[] = [
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).benchmarkPrefix}Mean`,
      header: '% New Patients' + benchmarkText + ' Benchmark Mean',
      columnType: ColumnType.BENCHMARK
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).benchmarkPrefix}25thPercentile`,
      header: '% New Patients' + benchmarkText + ' Benchmark 25th',
      columnType: ColumnType.BENCHMARK
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).benchmarkPrefix}50thPercentile`,
      header: '% New Patients' + benchmarkText + ' Benchmark 50th',
      columnType: ColumnType.BENCHMARK
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).benchmarkPrefix}75thPercentile`,
      header: '% New Patients' + benchmarkText + ' Benchmark 75th',
      columnType: ColumnType.BENCHMARK
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).benchmarkPrefix}90thPercentile`,
      header: '% New Patients' + benchmarkText + ' Benchmark 90th',
      columnType: ColumnType.BENCHMARK
    }
  ];
  return benchmarkData;
}

function getNpvVarianceColumns(benchmarkOption: BenchmarkOption) {
  const varianceData: BaseColumn[] = [
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).variancePrefix}Mean`,
      header: 'Variance Mean',
      columnType: ColumnType.VARIANCE
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).variancePrefix}25thPercentile`,
      header: 'Variance 25th',
      columnType: ColumnType.VARIANCE
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).variancePrefix}50thPercentile`,
      header: 'Variance 50th',
      columnType: ColumnType.VARIANCE
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).variancePrefix}75thPercentile`,
      header: 'Variance 75th',
      columnType: ColumnType.VARIANCE
    },
    {
      // @ts-ignore
      columnDef: `${extendedBenchmarkOptions.find(o => o.value === benchmarkOption).variancePrefix}90thPercentile`,
      header: 'Variance 90th',
      columnType: ColumnType.VARIANCE
    }
  ];
  return varianceData;
}

export function getExcelNpvTrendDataForDisplayedColumns(
  summaryData: SummaryData<NewPatientVisitSummary>,
  trendData: NewPatientVisitTrendEntry[],
  displayedColumns: BaseColumn[],
  copyright: string,
  viewCommunityBenchmarks: boolean, telehealthEnabled: boolean,
  benchmarkOption: BenchmarkOption | undefined,
  nodePath?: string,
  dateRange?: any,
  memberLocationName?: any,
  payerCategory?: any,
  telehealthFlag?: string
): ExportMetadata {
  let summaryHeaders;
  displayedColumns = displayedColumns.filter(x => !(x.columnDef === 'month'));
  benchmarkOption = benchmarkOption || (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  const benchmarkText = getBenchmarkOptionName(benchmarkOption, telehealthEnabled);
  summaryHeaders = ['Time Range',
    '% New Patient Visits',
    '# of New Patients',
    '# of Total Patients',
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
    'Previous Dates % New Patients',
    '% New Patient Visits Difference from Previous Date Range'];
  let headers = ['By Month'];
  const benchmarkHeaders = ['% New Patients' + benchmarkText + ' Benchmark Mean',
    '% New Patients' + benchmarkText + ' Benchmark 25th',
    '% New Patients' + benchmarkText + ' Benchmark 50th',
    '% New Patients' + benchmarkText + ' Benchmark 75th',
    '% New Patients' + benchmarkText + ' Benchmark 90th'];
  const varianceHeaders = ['Variance Mean', 'Variance 25th', 'Variance 50th',
    'Variance 75th', 'Variance 90th'];
  const benchmarkData: BaseColumn[] = getNpvBenchmarkColumns(benchmarkOption, benchmarkText);
  const varianceData: BaseColumn[] = getNpvVarianceColumns(benchmarkOption);
  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);
  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);
  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));
  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }
  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  return {
    summaryHeaders: summaryHeaders,
    summaryData: getNpvTrendExportSummaryData(summaryData, viewCommunityBenchmarks, benchmarkOption),
    detailHeaders: headers,
    detailData: trendData.map(npv => {
      npv.difference = getDifferenceFrom(npv);
      return getRowForNpvTrendExport(npv, remainingColumns, getDesignatedNpvBenchmarkObject(benchmarkOption ?
          benchmarkOption : viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic),
        getDesignatedNpvVarianceObject(benchmarkOption ?
          benchmarkOption : viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic));
    }),
    fileName: 'npvByMonths',
    page: 'Npv Trend',
    title: copyright,
    copyright: copyright,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, payerCategory, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

// TODO: delete the following after the updated exported has been released to production.
function insertAllBenchmarkDependantColumnsForNpv(
  benchmarkPercentiles: BenchmarkPercentile[],
  columns: DataTableColumns[],
  viewCommunityBenchmarks: boolean,
  benchmarkOption: BenchmarkOption,
  telehealthEnabled: boolean,
  replaceBenchmark: (arg0: BenchmarkPercentile, arg1: BenchmarkOption, arg2: boolean) => DataTableColumns,
  replaceVariance: (arg0: BenchmarkPercentile, arg1: BenchmarkOption, arg2: boolean) => DataTableColumns): DataTableColumns[] {
  // TODO fix benchmark dependencies
  const benchmarkColumns: DataTableColumns[] = [];
  const varianceColumns: DataTableColumns[] = [];
  benchmarkOption = benchmarkOption || (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  benchmarkPercentiles.forEach(bcm => {
    benchmarkColumns.push(replaceBenchmark(bcm, benchmarkOption, false));
    varianceColumns.push(replaceVariance(bcm, benchmarkOption, false));
  });
  const hasBenchmark = columns.find(col => col.columnType === ColumnType.BENCHMARK);
  if (hasBenchmark) {
    columns = columns.filter(col => col.columnType !== ColumnType.BENCHMARK);
    benchmarkColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasVariance = columns.find(col => col.columnType === ColumnType.VARIANCE);
  if (hasVariance) {
    columns = columns.filter(col => col.columnType !== ColumnType.VARIANCE);
    varianceColumns.forEach(varCol => {
      columns.push(varCol);
    });
  }
  return columns;
}

function generateRowsForNpvLocation(providerLocationEntry: NpvLocationWithSnapshotEntries,
                                    displayedColumns: DataTableColumns[]): string[][] {
  const rows: string[][] = [];
  const nodeColumns: DataTableColumns[] = displayedColumns.slice(1);
  const nodeList: MergedNewPatientVisitSnapshotEntry[] = displayedColumns[1].dataNameList(providerLocationEntry);
  nodeList.forEach(item => {
    const row: string[] = [displayedColumns[0].dataName(providerLocationEntry)];
    nodeColumns.forEach(col => {
      row.push(col.dataName(item));
    });
    rows.push(row);
  });
  return rows;
}

function generateRowForNpvSummary(column: DataTableColumns, summary: NpvSummaryData[]): string[] {
  const row: string[] = [''];
  summary.forEach(entry => {
    row.push(column.dataName(entry));
  });
  return row;
}

export function generateOriginalExcelNpvLocationByDimensionDataForDisplayedColumns(
  summaryData: SummaryData<NewPatientVisitSummary>,
  providerLocationData: MergedNpvLocationAggregatedByNode[],
  displayedDetailColumns: BaseColumn[],
  detailColumns: DataTableColumns[],
  summaryColumns: DataTableColumns[],
  viewCommunityBenchmarks: boolean,
  tab: MultilevelTab,
  benchmarkOption: BenchmarkOption,
  telehealthEnabled: boolean,
  suppressZeroes: boolean,
  nodePath?: string,
  dateRange?: any,
  memberLocationName?: any,
  payerCategory?: any,
  telehealthFlag?: string): ExportMetadata {
  detailColumns = detailColumns
    .filter(detailColumn => displayedDetailColumns
      .find(displayedColumn => displayedColumn.columnDef === detailColumn.columnDef
        || (displayedColumn.columnType === detailColumn.columnType && displayedColumn.columnType !== undefined)))
    .slice();
  const summary = populateSummaryDataForAllBenchmarksForExcel(summaryData, viewCommunityBenchmarks, telehealthEnabled, benchmarkOption);
  detailColumns = insertAllBenchmarkDependantColumnsForNpv(BenchmarkPercentilesForNpv, detailColumns, viewCommunityBenchmarks,
    benchmarkOption, telehealthEnabled, replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor,
    replaceNpvSnapshotVarianceColumnWithBenchmarkFor);
  const copyRight = 'Clinical Practice Solutions Center New Patient Visits ' + new Date().toLocaleString() + ';';
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (tab) {
    case MultilevelTab.LOCATION_DEPARTMENT:
      designatedObservable = npvByLocationByDepartmentExcelData$;
      break;
    case MultilevelTab.LOCATION_SPECIALTY:
      designatedObservable = npvByLocationBySpecialtyExcelData$;
      break;
    case MultilevelTab.LOCATION_PROVIDER:
      designatedObservable = npvByLocationByProviderExcelData$;
  }
  const detailData: string[][] = [];
  const levelSpecificData: NpvLocationWithSnapshotEntries[] = getLevelSpecificNpvLocationTableData(
    false, tab, suppressZeroes, providerLocationData).entries;
  levelSpecificData.forEach(pLD => {
    const rows: string[][] = generateRowsForNpvLocation(pLD, detailColumns);
    rows.forEach(row => {
      detailData.push(row);
    });
  });
  const level: string = getSingularOntologyLevelName(tab).toLowerCase();
  return {
    summaryHeaders: ['Time Range', ''].concat(summary.map(s => s.metric)),
    summaryData: summaryColumns.splice(1).map(col => {
      return [col.header].concat(generateRowForNpvSummary(col, summary));
    }),
    detailHeaders: ['By ' + detailColumns[0].header].concat(detailColumns.slice(1).map(c => c.header)),
    detailData: detailData,
    fileName: ('Npv Location by ' + level),
    page: 'Npv Location by ' + level,
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, payerCategory, telehealthFlag),
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showVisitType: true
    },
    sheetName: 'NpvBy' + toTitleCase(level)
  };
}

export function getNpvSnapshotExportSummaryData(summaryData: SummaryData<NewPatientVisitSummary>,
                                                viewCommunityBenchmarks: boolean,
                                                benchmarkOption: BenchmarkOption | undefined): string[][] {
  const result: string[][] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      const npVisitPercentage = roundTo(summaryData[dateRange].newPatientVisitsPercentage, 1);
      result.push([
        getGroupName(summaryData[dateRange], dateRange),
        npVisitPercentage === '-' ? npVisitPercentage : npVisitPercentage + '%',
        formatNumberToWholeNumber(summaryData[dateRange].countOfNewPatientVisits),
        formatNumberToWholeNumber(summaryData[dateRange].countOfTotalPatientVisits),
        getBenchMarkSummaryPercentileForExport(summaryData[dateRange], BenchmarkPercentile.Mean, viewCommunityBenchmarks, benchmarkOption),
        getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile25th,
          viewCommunityBenchmarks,
          benchmarkOption
        ),
        getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile50th,
          viewCommunityBenchmarks,
          benchmarkOption
        ),
        getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile75th,
          viewCommunityBenchmarks,
          benchmarkOption
        ),
        getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile90th,
          viewCommunityBenchmarks,
          benchmarkOption
        ),
        '-',
        '-',
        '-',
        '-',
        '-',
        '-',
        '-'
      ]);
    }
  }
  return result;
}

export function getExcelNpvSnapshotDataForDisplayedColumns(
  summaryData: SummaryData<NewPatientVisitSummary>,
  providerData: MergedNewPatientVisitSnapshotEntry[],
  displayedColumns: BaseColumn[],
  level: string,
  viewCommunityBenchmarks: boolean,
  benchmarkOption: BenchmarkOption | undefined,
  suppressZeroes: boolean,
  nodePath?: string,
  dateRange?: any,
  memberLocationName?: any,
  payerCategory?: any,
  telehealthFlag?: string
): ExportMetadata {

  benchmarkOption = benchmarkOption || (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  const benchmarkText = getBenchmarkOptionName(benchmarkOption, true);
  const summaryHeaders = [
    'Time Range',
    '% New Patient Visits',
    '# of New Patients',
    '# of Total Patients',
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
    `% New Patients${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
    `Variance from${benchmarkText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
    'Previous Dates % New Patients',
    '% New Patient Visits Difference from Previous Date Range'
  ];

  let headers = ['Groups'];
  const benchmarkHeaders = [
    '% New Patients' + benchmarkText + ' Benchmark Mean',
    '% New Patients' + benchmarkText + ' Benchmark 25th',
    '% New Patients' + benchmarkText + ' Benchmark 50th',
    '% New Patients' + benchmarkText + ' Benchmark 75th',
    '% New Patients' + benchmarkText + ' Benchmark 90th'
  ];
  const varianceHeaders = [
    'Variance Mean',
    'Variance 25th',
    'Variance 50th',
    'Variance 75th',
    'Variance 90th'
  ];

  const benchmarkData: BaseColumn[] = getNpvBenchmarkColumns(benchmarkOption, benchmarkText);
  const varianceData: BaseColumn[] = getNpvVarianceColumns(benchmarkOption);

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => x.columnType !== ColumnType.BENCHMARK && x.columnType !== ColumnType.VARIANCE);

  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  const copyRight = 'Clinical Practice Solutions Center Productivity Summary ' + new Date().toLocaleString() + ';';
  return {
    summaryHeaders: summaryHeaders,
    summaryData: getNpvSnapshotExportSummaryData(summaryData, viewCommunityBenchmarks, benchmarkOption),
    detailHeaders: headers,
    detailData: providerData.filter(x => !suppressZeroes || x.countOfTotalPatientVisits !== 0)
      .map(npv => {
        npv.difference = getDifferenceFrom(npv);
        return getRowForNpvSnapshotExport(npv, remainingColumns,
          getDesignatedNpvBenchmarkObject(benchmarkOption ? benchmarkOption : viewCommunityBenchmarks ?
            BenchmarkOption.Community : BenchmarkOption.Academic),
          getDesignatedNpvVarianceObject(benchmarkOption ? benchmarkOption : viewCommunityBenchmarks ?
            BenchmarkOption.Community : BenchmarkOption.Academic));
    }),
    fileName: ('Npv ' + level),
    page: 'Npv ' + level,
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, payerCategory, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEmOutpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData: EvaluationManagementSummary,
                                                                             benchmarkOption: BenchmarkOption
): string[][] {
  const result: string[][] = [];
  result.push(getSummaryOutpatientEncounterRowForExcelExport(summaryData));
  result.push(getSummaryOutpatientPercentageRowForExcelExport(summaryData));
  result.push(getSummaryOutpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  return result;
}

export function getSummaryOutpatientEncounterRowForExcelExport(eAndmOutpatientSummary: EvaluationManagementSummary)
  : string[] {
  if (!eAndmOutpatientSummary.totals) {
    return ['', '# of Encounters'];
  }
  return [
    getGroupName(eAndmOutpatientSummary, 'selectedDateRange'),
    '# of Encounters',
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99201', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99202', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99203', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99204', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99205', 'count', ''),
    valueFromOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'count', '', 0),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99211', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99212', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99213', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99214', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99215', 'count', ''),
    valueFromOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'count', '', 0),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99241', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99242', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99243', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99244', 'count', ''),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99245', 'count', ''),
    valueFromOrElse(eAndmOutpatientSummary.totals.consultation, 'count', '', 0)
  ];
}

export function getSummaryOutpatientPercentageRowForExcelExport(eAndmOutpatientSummary: EvaluationManagementSummary)
  : string[] {
  if (!eAndmOutpatientSummary.totals) {
    return ['', 'Coding Distribution'];
  }
  return [
    getGroupName(eAndmOutpatientSummary, 'selectedDateRange'),
    'Coding Distribution',
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99201', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99202', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99203', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99204', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99205', 'percentage', '', '%'),
    valueFromOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'percentage', '%', '0%', true),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99211', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99212', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99213', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99214', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99215', 'percentage', '', '%'),
    valueFromOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'percentage', '%', '0%', true),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99241', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99242', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99243', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99244', 'percentage', '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99245', 'percentage', '', '%'),
    valueFromOrElse(eAndmOutpatientSummary.totals.consultation, 'percentage', '%', '0%', true)
  ];
}

function getEmSummaryRowWithHeadersAndBenchmarks(eAndmOutpatientSummary: EvaluationManagementSummary,
                                                 headerString: string, benchmarkString: string) {
  return [
    getGroupName(eAndmOutpatientSummary, 'selectedDateRange'),
    headerString,
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99201', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99202', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99203', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99204', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99205', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99211', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99212', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99213', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99214', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99215', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99241', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99242', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99243', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99244', benchmarkString, '', '%'),
    isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99245', benchmarkString, '', '%'),
    ''
  ];
}

export function getSummaryOutpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(
  eAndmOutpatientSummary: EvaluationManagementSummary, benchmarkOption: BenchmarkOption)
  : string[] {
  if (!eAndmOutpatientSummary.totals) {
    return ['', 'CPSC Mean'];
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmSummaryRowWithHeadersAndBenchmarks(eAndmOutpatientSummary, headerString, benchmarkString);
}

export function getOutpatientCodingDistributionRowForExcelExport(eAndMOutpatient: EvaluationManagementOutpatientRow)
  : string[] {
  return [
    eAndMOutpatient.rowName,
    'Coding Distribution',
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99201', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99202', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99203', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99204', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99205', 'percentage', '', '%'),
    valueFromOrElse(eAndMOutpatient.newPatientVisit, 'percentage', '%', 0, true),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99211', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99212', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99213', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99214', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99215', 'percentage', '', '%'),
    valueFromOrElse(eAndMOutpatient.establishedPatientVisit, 'percentage', '%', 0, true),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99241', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99242', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99243', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99244', 'percentage', '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99245', 'percentage', '', '%'),
    valueFromOrElse(eAndMOutpatient.consultation, 'percentage', '%', 0, true)
  ];
}

export function getOutpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(eAndMOutpatient: EvaluationManagementOutpatientRow,
                                                                                   benchmarkOption: BenchmarkOption)
  : string[] {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return [
    eAndMOutpatient.rowName,
    headerString,
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99201', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99202', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99203', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99204', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99205', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99211', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99212', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99213', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99214', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99215', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99241', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99242', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99243', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99244', benchmarkString, '', '%'),
    isValidOrElse(eAndMOutpatient.consultation, 'cpt99245', benchmarkString, '', '%'),
    ''];
}

export function getOutpatientEncounterRowForExcelExport(eAndmOutpatient: EvaluationManagementOutpatientRow)
  : string[] {
  if (!eAndmOutpatient) {
    return ['', '# of Encounters'];
  }
  return [
    eAndmOutpatient.rowName,
    '# of Encounters',
    isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99201', 'count', ''),
    isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99202', 'count', ''),
    isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99203', 'count', ''),
    isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99204', 'count', ''),
    isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99205', 'count', ''),
    valueFromOrElse(eAndmOutpatient.newPatientVisit, 'count', '', 0),
    isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99211', 'count', ''),
    isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99212', 'count', ''),
    isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99213', 'count', ''),
    isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99214', 'count', ''),
    isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99215', 'count', ''),
    valueFromOrElse(eAndmOutpatient.establishedPatientVisit, 'count', '', 0),
    isValidOrElse(eAndmOutpatient.consultation, 'cpt99241', 'count', ''),
    isValidOrElse(eAndmOutpatient.consultation, 'cpt99242', 'count', ''),
    isValidOrElse(eAndmOutpatient.consultation, 'cpt99243', 'count', ''),
    isValidOrElse(eAndmOutpatient.consultation, 'cpt99244', 'count', ''),
    isValidOrElse(eAndmOutpatient.consultation, 'cpt99245', 'count', ''),
    valueFromOrElse(eAndmOutpatient.consultation, 'count', '', 0)
  ];
}

export function getEmOutpatientExportDetailDataWithExtendedBenchmarks(evaluationManagementData: EvaluationManagement,
                                                                      isSingleProviderSelected: boolean, benchmarkOption: BenchmarkOption
): string[][] {
  const result: string[][] = [];
  const outPatientRows: EvaluationManagementOutpatientRow[] =
    getOutpatientTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);

  outPatientRows.forEach((element) => {
    result.push(getOutpatientEncounterRowForExcelExport(element));
    result.push(getOutpatientCodingDistributionRowForExcelExport(element));
    result.push(getOutpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmOutpatientExportByDepartmentDetailDataWithExtendedBanchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean
): string[][] {
  const result: string[][] = [];
  const outPatientRows: EvaluationManagementOutpatientRow[] =
    getOutpatientByDepartmentTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Outpatient);

  outPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getOutpatientEncounterRowForExcelExport(element));
      result.push(getOutpatientCodingDistributionRowForExcelExport(element));
      result.push(getOutpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmOutpatientExportBySpecialtyDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean
): string[][] {
  const result: string[][] = [];
  const outPatientRows: EvaluationManagementOutpatientRow[] =
    getOutpatientBySpecialtyTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Outpatient);

  outPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getOutpatientEncounterRowForExcelExport(element));
      result.push(getOutpatientCodingDistributionRowForExcelExport(element));
      result.push(getOutpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmOutpatientExportByProviderDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean
): string[][] {
  const result: string[][] = [];
  const outPatientRows: EvaluationManagementOutpatientRow[] =
    getOutpatientByProviderTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Outpatient);

  outPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getOutpatientEncounterRowForExcelExport(element));
      result.push(getOutpatientCodingDistributionRowForExcelExport(element));
      result.push(getOutpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}


export function getEMOutpatientDataWithExtendedBenchmarkOptions(summaryData: EvaluationManagementSummary,
                                                                evaluationManagementData: EvaluationManagement,
                                                                copyRight: string,
                                                                isProviderSelected: boolean,
                                                                level: string,
                                                                benchmarkOption: BenchmarkOption,
                                                                nodePath?: string,
                                                                dateRange?: any,
                                                                memberLocationName?: any): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    summaryData: getEmOutpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: [isProviderSelected ? 'By Month' : toTitleCase(level),
      'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    detailData: getEmOutpatientExportDetailDataWithExtendedBenchmarks(evaluationManagementData, isProviderSelected, benchmarkOption),
    fileName: 'outpatientEandM',
    page: 'EM Outpatient',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getEmOutpatientByDepartmentExportDataWithExtendedBenchmarkOptions(evaluationManagement: EvaluationManagementMultilevel,
                                                                                  summaryData: EvaluationManagementSummary,
                                                                                  copyRight: string,
                                                                                  benchmarkOption: BenchmarkOption,
                                                                                  suppressZeroes: boolean,
                                                                                  nodePath?: string,
                                                                                  dateRange?: any,
                                                                                  memberLocationName?: any,
                                                                                  telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    summaryData: getEmOutpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Department',
      'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    detailData: getEmOutpatientExportByDepartmentDetailDataWithExtendedBanchmarkOptions(evaluationManagement,
      benchmarkOption, suppressZeroes),
    fileName: 'outpatientEandM',
    page: 'EM Outpatient By Department',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEmOutpatientBySpecialtyExportDataWithExtendedBenchmarkOptions(evaluationManagement: EvaluationManagementMultilevel,
                                                                                 summaryData: EvaluationManagementSummary,
                                                                                 copyRight: string,
                                                                                 benchmarkOption: BenchmarkOption,
                                                                                 suppressZeroes: boolean,
                                                                                 nodePath?: string,
                                                                                 dateRange?: any,
                                                                                 memberLocationName?: any,
                                                                                 telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    summaryData: getEmOutpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Specialty/Department',
      'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    detailData: getEmOutpatientExportBySpecialtyDetailDataWithExtendedBenchmarkOptions(evaluationManagement,
      benchmarkOption, suppressZeroes),
    fileName: 'outpatientEandM',
    page: 'EM Outpatient By Specialty',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEmOutpatientByProviderExportDataWithExtendedBenchmarkOptions(evaluationManagement: EvaluationManagementMultilevel,
                                                                                summaryData: EvaluationManagementSummary,
                                                                                copyRight: string,
                                                                                benchmarkOption: BenchmarkOption,
                                                                                suppressZeroes: boolean,
                                                                                nodePath?: string,
                                                                                dateRange?: any,
                                                                                memberLocationName?: any,
                                                                                telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    summaryData: getEmOutpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Provider/Specialty/Department',
      'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    detailData: getEmOutpatientExportByProviderDetailDataWithExtendedBenchmarkOptions(evaluationManagement,
      benchmarkOption, suppressZeroes),
    fileName: 'outpatientEandM',
    page: 'EM Outpatient By Provider',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEmInpatientExportSummaryDataWithExtendedBenchmarkOptions(
  summaryData: EvaluationManagementSummary, benchmarkOption: BenchmarkOption
): string[][] {
  const result: string[][] = [];
  result.push(getInpatientSummaryEncounterRowForExcelExport(summaryData));
  result.push(getInpatientSummaryCodingDistributionRowForExcelExport(summaryData));
  result.push(getInpatientSummaryFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  return result;
}

export function getEmInpatientExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                           isSingleProviderSelected: boolean,
                                                                           benchmarkOption: BenchmarkOption): string[][] {
  const result: string[][] = [];
  const inPatientRows =
    getInpatientTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);
  inPatientRows.forEach((element) => {
    result.push(getInpatientEncounterRowForExcelExport(element));
    result.push(getInpatientCodingDistributionRowForExcelExport(element));
    result.push(getInpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });
  return result;
}

export function getEmInpatientByDepartmentExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const inPatientRows =
    getInpatientByDepartmentTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.InPatient);

  inPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getInpatientEncounterRowForExcelExport(element));
      result.push(getInpatientCodingDistributionRowForExcelExport(element));
      result.push(getInpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });
  return result;
}

export function getEmInpatientBySpecialtyExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const inPatientRows =
    getInpatientBySpecialtyTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.InPatient);

  inPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getInpatientEncounterRowForExcelExport(element));
      result.push(getInpatientCodingDistributionRowForExcelExport(element));
      result.push(getInpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });
  return result;
}

export function getEmInpatientByProviderExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const inPatientRows =
    getInpatientByProviderTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.InPatient);

  inPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getInpatientEncounterRowForExcelExport(element));
      result.push(getInpatientCodingDistributionRowForExcelExport(element));
      result.push(getInpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });
  return result;
}

export function getInpatientSummaryEncounterRowForExcelExport(eAndmInpatientSummary: EvaluationManagementSummary)
  : string[] {
  if (!eAndmInpatientSummary.totals) {
    return ['', '# of Encounters'];
  }
  return [
    getGroupName(eAndmInpatientSummary, 'selectedDateRange'),
    '# of Encounters',
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99221', 'count', ''),
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99222', 'count', ''),
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99223', 'count', ''),
    valueFromOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'count', '', 0),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99231', 'count', ''),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99232', 'count', ''),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99233', 'count', ''),
    valueFromOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'count', '', 0),
    isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99238', 'count', ''),
    isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99239', 'count', ''),
    valueFromOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'count', '', 0)
  ];
}

export function getInpatientSummaryCodingDistributionRowForExcelExport(eAndmInpatientSummary: EvaluationManagementSummary)
  : string[] {
  if (!eAndmInpatientSummary.totals) {
    return ['', 'Coding Distribution'];
  }
  return [
    getGroupName(eAndmInpatientSummary, 'selectedDateRange'),
    'Coding Distribution',
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99221', 'percentage', '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99222', 'percentage', '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99223', 'percentage', '', '%'),
    valueFromOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'percentage', '%', 0, true),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99231', 'percentage', '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99232', 'percentage', '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99233', 'percentage', '', '%'),
    valueFromOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'percentage', '%', 0, true),
    isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99238', 'percentage', '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99239', 'percentage', '', '%'),
    valueFromOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'percentage', '%', 0, true)
  ];
}

export function getInpatientSummaryFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(
  eAndmInpatientSummary: EvaluationManagementSummary, benchmarkOption: BenchmarkOption): string[] {
  if (!eAndmInpatientSummary.totals) {
    return ['', 'Coding Distribution'];
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return [
    getGroupName(eAndmInpatientSummary, 'selectedDateRange'),
    headerString,
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99221', benchmarkString, '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99222', benchmarkString, '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99223', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99231', benchmarkString, '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99232', benchmarkString, '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99233', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99238', benchmarkString, '', '%'),
    isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99239', benchmarkString, '', '%'),
    ''
  ];
}

export function getInpatientEncounterRowForExcelExport(eAndMInpatient: EvaluationManagementInpatientRow)
  : string[] {
  return [
    eAndMInpatient.rowName,
    '# of Encounters',
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99221', 'count', ''),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99222', 'count', ''),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99223', 'count', ''),
    valueFromOrElse(eAndMInpatient.initialHospitalCare, 'count', '', 0),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99231', 'count', ''),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99232', 'count', ''),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99233', 'count', ''),
    valueFromOrElse(eAndMInpatient.subsequentHospitalCare, 'count', '', 0),
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99238', 'count', ''),
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99239', 'count', ''),
    valueFromOrElse(eAndMInpatient.hospitalDischargeDay, 'count', '', 0)
  ];
}

export function getInpatientCodingDistributionRowForExcelExport(eAndMInpatient: EvaluationManagementInpatientRow)
  : string[] {
  return [
    eAndMInpatient.rowName,
    'Coding Distribution',
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99221', 'percentage', '', '%'),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99222', 'percentage', '', '%'),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99223', 'percentage', '', '%'),
    valueFromOrElse(eAndMInpatient.initialHospitalCare, 'percentage', '%', 0, true),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99231', 'percentage', '', '%'),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99232', 'percentage', '', '%'),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99233', 'percentage', '', '%'),
    valueFromOrElse(eAndMInpatient.subsequentHospitalCare, 'percentage', '%', 0, true),
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99238', 'percentage', '', '%'),
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99239', 'percentage', '', '%'),
    valueFromOrElse(eAndMInpatient.hospitalDischargeDay, 'percentage', '%', 0, true)
  ];
}

export function getInpatientFpscMeanRowForExcelExportWithOriginalBenchmarks(eAndMInpatient: EvaluationManagementInpatientRow,
                                                                            viewCommunityBenchmarks: boolean)
  : string[] {
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return [
    eAndMInpatient.rowName,
    headerString,
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99221', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99222', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99223', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99231', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99232', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99233', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99238', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99239', benchmarkString, '', '%'),
    ''
  ];
}

export function getInpatientFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(eAndMInpatient: EvaluationManagementInpatientRow,
                                                                                  benchmarkOption: BenchmarkOption)
  : string[] {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return [
    eAndMInpatient.rowName,
    headerString,
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99221', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99222', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99223', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99231', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99232', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99233', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99238', benchmarkString, '', '%'),
    isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99239', benchmarkString, '', '%'),
    ''
  ];
}

export function getEMInpatientDataWithExtendedBenchmarkOptions(summaryData: EvaluationManagementSummary,
                                                               evaluationManagementData: EvaluationManagement,
                                                               copyRight: string,
                                                               isProviderSelected: boolean,
                                                               level: string,
                                                               benchmarkOption: BenchmarkOption,
                                                               nodePath?: string,
                                                               dateRange?: any,
                                                               memberLocationName?: any): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    summaryData: getEmInpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: [isProviderSelected ? 'By Month' : toTitleCase(level), 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    detailData: getEmInpatientExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData, isProviderSelected, benchmarkOption),
    fileName: 'inpatientEandM',
    page: 'EM Inpatient',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getEMInpatientByDepartmentExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                                 summaryData: EvaluationManagementSummary,
                                                                                 copyRight: string,
                                                                                 benchmarkOption: BenchmarkOption,
                                                                                 suppressZeroes: boolean,
                                                                                 nodePath?: string,
                                                                                 dateRange?: any,
                                                                                 memberLocationName?: any,
                                                                                 telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    summaryData: getEmInpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Department', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    detailData: getEmInpatientByDepartmentExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'inpatientEandM',
    page: 'EM Inpatient',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEMInpatientBySpecialtyExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                                summaryData: EvaluationManagementSummary,
                                                                                copyRight: string,
                                                                                benchmarkOption: BenchmarkOption,
                                                                                suppressZeroes: boolean,
                                                                                nodePath?: string,
                                                                                dateRange?: any,
                                                                                memberLocationName?: any,
                                                                                telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    summaryData: getEmInpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Specialty/Department', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    detailData: getEmInpatientBySpecialtyExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'inpatientEandM',
    page: 'EM Inpatient',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEMInpatientByProviderExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                               summaryData: EvaluationManagementSummary,
                                                                               copyRight: string,
                                                                               benchmarkOption: BenchmarkOption,
                                                                               suppressZeroes: boolean,
                                                                               nodePath?: string,
                                                                               dateRange?: any,
                                                                               memberLocationName?: any,
                                                                               telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    summaryData: getEmInpatientExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Provider/Specialty/Department', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    detailData: getEmInpatientByProviderExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'inpatientEandM',
    page: 'EM Inpatient',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEmEmergencyExportSummaryDataWithExtendedBenchmarkOptions(summaryData: EvaluationManagementSummary,
                                                                            benchmarkOption: BenchmarkOption): string[][] {
  const result: string[][] = [];
  result.push(getEmergencyRoomSummaryEncounterRowForExport(summaryData));
  result.push(getEmergencyRoomSummaryCodingDistributionRowForExport(summaryData));
  result.push(getEmergencyRoomSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  return result;
}

export function getEmergencyRoomSummaryEncounterRowForExport(eAndMEmergencyRoom: EvaluationManagementSummary)
  : string[] {
  if (!eAndMEmergencyRoom.totals) {
    return ['', '# of Encounters'];
  }
  return [
    getGroupName(eAndMEmergencyRoom, 'selectedDateRange'),
    '# of Encounters',
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99281', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99282', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99283', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99284', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99285', 'count', ''),
    valueFromOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'count', '', 0)
  ];
}

export function getEmergencyRoomSummaryCodingDistributionRowForExport(eAndMEmergencyRoom: EvaluationManagementSummary)
  : string[] {
  if (!eAndMEmergencyRoom.totals) {
    return ['', 'Coding Distribution'];
  }
  return [
    getGroupName(eAndMEmergencyRoom, 'selectedDateRange'),
    'Coding Distribution',
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99281', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99282', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99283', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99284', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99285', 'percentage', '', '%'),
    valueFromOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'percentage', '%', 0, true)
  ];
}

export function getEmergencyRoomSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMEmergencyRoom: EvaluationManagementSummary,
                                                                                        benchmarkOption: BenchmarkOption)
  : string[] {
  if (!eAndMEmergencyRoom.totals) {
    return ['', 'CPSC Mean'];
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return [
    getGroupName(eAndMEmergencyRoom, 'selectedDateRange'),
    headerString,
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99281', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99282', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99283', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99284', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99285', benchmarkString, '', '%'),
    ''
  ];
}

export function getEmergencyRoomEncounterRowForExport(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow)
  : string[] {
  return [
    eAndMEmergencyRoom.rowName,
    '# of Encounters',
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99281', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99282', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99283', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99284', 'count', ''),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99285', 'count', ''),
    valueFromOrElse(eAndMEmergencyRoom.emergencyMedicine, 'count', '', 0)
  ];
}

export function getEmergencyRoomCodingDistributionRowForExport(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow)
  : string[] {
  return [
    eAndMEmergencyRoom.rowName,
    'Coding Distribution',
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99281', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99282', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99283', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99284', 'percentage', '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99285', 'percentage', '', '%'),
    valueFromOrElse(eAndMEmergencyRoom.emergencyMedicine, 'percentage', '%', 0, true)
  ];
}

export function getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow,
                                                                                 benchmarkOption: BenchmarkOption)
  : string[] {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return [
    eAndMEmergencyRoom.rowName,
    headerString,
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99281', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99282', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99283', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99284', benchmarkString, '', '%'),
    isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99285', benchmarkString, '', '%'),
    ''
  ];
}

export function getEmEmergencyExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                           isSingleProviderSelected: boolean,
                                                                           benchmarkOption: BenchmarkOption): string[][] {
  const result: string[][] = [];
  const eyeEmergencyRows =
    getEmergencyTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);

  eyeEmergencyRows.forEach((element) => {
    result.push(getEmergencyRoomEncounterRowForExport(element));
    result.push(getEmergencyRoomCodingDistributionRowForExport(element));
    result.push(getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmEmergencyByDepartmentExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const eyeEmergencyRows =
    getEmergencyByDepartmentTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.EmergencyMedicine);

  eyeEmergencyRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getEmergencyRoomEncounterRowForExport(element));
      result.push(getEmergencyRoomCodingDistributionRowForExport(element));
      result.push(getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmEmergencyBySpecialtyExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const eyeEmergencyRows =
    getEmergencyBySpecialtyTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.EmergencyMedicine);

  eyeEmergencyRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getEmergencyRoomEncounterRowForExport(element));
      result.push(getEmergencyRoomCodingDistributionRowForExport(element));
      result.push(getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmEmergencyByProviderExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const eyeEmergencyRows =
    getEmergencyByProviderTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.EmergencyMedicine);

  eyeEmergencyRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getEmergencyRoomEncounterRowForExport(element));
      result.push(getEmergencyRoomCodingDistributionRowForExport(element));
      result.push(getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEMEmergencyDataWithExtendedBenchmarkOptions(summaryData: EvaluationManagementSummary,
                                                               evaluationManagementData: EvaluationManagement,
                                                               copyRight: string,
                                                               isProviderSelected: boolean,
                                                               level: string,
                                                               benchmarkOption: BenchmarkOption,
                                                               nodePath?: string,
                                                               dateRange?: any,
                                                               memberLocationName?: any): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99281', '99282', '99283', '99284', '99285', 'Total'],
    summaryData: getEmEmergencyExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: [isProviderSelected ? 'By Month' : toTitleCase(level), 'Metrics', '99281',
      '99282', '99283', '99284', '99285', 'Total'],
    detailData: getEmEmergencyExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData, isProviderSelected,
      benchmarkOption),
    fileName: 'emergencyRoomEandM',
    page: 'EM EmergencyRoom',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getEMEmergencyByDepartmentExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                                 summaryData: EvaluationManagementSummary,
                                                                                 copyRight: string,
                                                                                 benchmarkOption: BenchmarkOption,
                                                                                 suppressZeroes: boolean,
                                                                                 nodePath?: string,
                                                                                 dateRange?: any,
                                                                                 memberLocationName?: any,
                                                                                 telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99281', '99282', '99283', '99284', '99285', 'Total'],
    summaryData: getEmEmergencyExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Department', 'Metrics', '99281',
      '99282', '99283', '99284', '99285', 'Total'],
    detailData: getEmEmergencyByDepartmentExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'emergencyRoomEandM',
    page: 'EM EmergencyRoom',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEMEmergencyBySpecialtyExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                                summaryData: EvaluationManagementSummary,
                                                                                copyRight: string,
                                                                                benchmarkOption: BenchmarkOption,
                                                                                suppressZeroes: boolean,
                                                                                nodePath?: string,
                                                                                dateRange?: any,
                                                                                memberLocationName?: any,
                                                                                telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99281', '99282', '99283', '99284', '99285', 'Total'],
    summaryData: getEmEmergencyExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Specialty/Department', 'Metrics', '99281',
      '99282', '99283', '99284', '99285', 'Total'],
    detailData: getEmEmergencyBySpecialtyExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'emergencyRoomEandM',
    page: 'EM EmergencyRoom',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEMEmergencyByProviderExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                               summaryData: EvaluationManagementSummary,
                                                                               copyRight: string,
                                                                               benchmarkOption: BenchmarkOption,
                                                                               suppressZeroes: boolean,
                                                                               nodePath?: string,
                                                                               dateRange?: any,
                                                                               memberLocationName?: any,
                                                                               telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '99281', '99282', '99283', '99284', '99285', 'Total'],
    summaryData: getEmEmergencyExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Provider/Specialty/Department', 'Metrics', '99281',
      '99282', '99283', '99284', '99285', 'Total'],
    detailData: getEmEmergencyByProviderExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'emergencyRoomEandM',
    page: 'EM EmergencyRoom',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEmEyeExamExportSummaryDataWithExtendedBenchmarkOptions(summaryData: EvaluationManagementSummary,
                                                                          benchmarkOption: BenchmarkOption): string[][] {
  const result: string[][] = [];

  result.push(getEyeExamSummaryEncounterRowForExcelExport(summaryData));
  result.push(getEyeExamSummaryCodingDistributionRowForExcelExport(summaryData));
  result.push(getEyeExamSummaryFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  return result;
}

export function getEyeExamSummaryEncounterRowForExcelExport(eAndMEyeExam: EvaluationManagementSummary)
  : string[] {
  if (!eAndMEyeExam.totals) {
    return ['', '# of Encounters'];
  }
  return [
    getGroupName(eAndMEyeExam, 'selectedDateRange'),
    '# of Encounters',
    isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92002', 'count', ''),
    isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92004', 'count', ''),
    valueFromOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'count', '', 0),
    isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92012', 'count', ''),
    isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92014', 'count', ''),
    valueFromOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'count', '', 0)
  ];
}

export function getEyeExamSummaryCodingDistributionRowForExcelExport(eAndMEyeExam: EvaluationManagementSummary)
  : string[] {
  if (!eAndMEyeExam.totals) {
    return ['', 'Coding Distribution'];
  }
  return [
    getGroupName(eAndMEyeExam, 'selectedDateRange'),
    'Coding Distribution',
    isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92002', 'percentage', '', '%'),
    isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92004', 'percentage', '', '%'),
    valueFromOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'percentage', '%', 0, true),
    isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92012', 'percentage', '', '%'),
    isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92014', 'percentage', '', '%'),
    valueFromOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'percentage', '%', 0, true)
  ];
}

export function getEyeExamSummaryFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(eAndMEyeExam: EvaluationManagementSummary,
                                                                                       benchmarkOption: BenchmarkOption)
  : string[] {
  if (!eAndMEyeExam.totals) {
    return ['', 'CPSC Mean'];
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return [
    getGroupName(eAndMEyeExam, 'selectedDateRange'),
    headerString,
    isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92002', benchmarkString, '', '%'),
    isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92004', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92012', benchmarkString, '', '%'),
    isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92014', benchmarkString, '', '%'),
    ''
  ];
}

export function getEyeExamEncounterRowForExcelExport(eAndMEyeExam: EvaluationManagementEyeExamRow)
  : string[] {
  return [
    eAndMEyeExam.rowName,
    '# of Encounters',
    isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92002', 'count', ''),
    isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92004', 'count', ''),
    valueFromOrElse(eAndMEyeExam.eyeExamNewPatient, 'count', '', 0),
    isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92012', 'count', ''),
    isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92014', 'count', ''),
    valueFromOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'count', '', 0)
  ];
}

export function getEyeExamCodingDistributionRowForExcelExport(eAndMEyeExam: EvaluationManagementEyeExamRow)
  : string[] {
  return [
    eAndMEyeExam.rowName,
    'Coding Distribution',
    isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92002', 'percentage', '', '%'),
    isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92004', 'percentage', '', '%'),
    valueFromOrElse(eAndMEyeExam.eyeExamNewPatient, 'percentage', '%', 0, true),
    isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92012', 'percentage', '', '%'),
    isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92014', 'percentage', '', '%'),
    valueFromOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'percentage', '%', 0, true)
  ];
}

export function getEyeExamFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(eAndMEyeExam: EvaluationManagementEyeExamRow,
                                                                                benchmarkOption: BenchmarkOption)
  : string[] {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return [
    eAndMEyeExam.rowName,
    headerString,
    isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92002', benchmarkString, '', '%'),
    isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92004', benchmarkString, '', '%'),
    '',
    isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92012', benchmarkString, '', '%'),
    isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92014', benchmarkString, '', '%'),
    ''
  ];
}

export function getEmEyeExamExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                         isSingleProviderSelected: boolean,
                                                                         benchmarkOption: BenchmarkOption): string[][] {
  const result: string[][] = [];

  const eyeExamRows =
    getEyeExamTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);

  eyeExamRows.forEach((element) => {
    result.push(getEyeExamEncounterRowForExcelExport(element));
    result.push(getEyeExamCodingDistributionRowForExcelExport(element));
    result.push(getEyeExamFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });
  return result;
}

export function getEmEyeExamByDepartmentExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const eyeEyeExamRows =
    getEyeExamByDepartmentTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Ophthalmology);

  eyeEyeExamRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getEyeExamEncounterRowForExcelExport(element));
      result.push(getEyeExamCodingDistributionRowForExcelExport(element));
      result.push(getEyeExamFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmEyeExamBySpecialtyExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const eyeEyeExamRows =
    getEyeExamBySpecialtyTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Ophthalmology);

  eyeEyeExamRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getEyeExamEncounterRowForExcelExport(element));
      result.push(getEyeExamCodingDistributionRowForExcelExport(element));
      result.push(getEyeExamFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEmEyeExamByProviderExportDetailDataWithExtendedBenchmarkOptions(
  evaluationManagementData: EvaluationManagementMultilevel, benchmarkOption: BenchmarkOption, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  const eyeEyeExamRows =
    getEyeExamByProviderTableRowsFrom(evaluationManagementData.evaluationManagementData);

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Ophthalmology);

  eyeEyeExamRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      result.push(getEyeExamEncounterRowForExcelExport(element));
      result.push(getEyeExamCodingDistributionRowForExcelExport(element));
      result.push(getEyeExamFpscMeanRowForExcelExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return result;
}

export function getEMEyeExamByDepartmentExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                               summaryData: EvaluationManagementSummary,
                                                                               copyRight: string,
                                                                               benchmarkOption: BenchmarkOption,
                                                                               suppressZeroes: boolean,
                                                                               nodePath?: string,
                                                                               dateRange?: any,
                                                                               memberLocationName?: any,
                                                                               telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    summaryData: getEmEyeExamExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Department', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    detailData: getEmEyeExamByDepartmentExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'eyeExamEandM',
    page: 'EM Eyeexam',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEMEyeExamBySpecialtyExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                              summaryData: EvaluationManagementSummary,
                                                                              copyRight: string,
                                                                              benchmarkOption: BenchmarkOption,
                                                                              suppressZeroes: boolean,
                                                                              nodePath?: string,
                                                                              dateRange?: any,
                                                                              memberLocationName?: any,
                                                                              telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    summaryData: getEmEyeExamExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Specialty/Department', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    detailData: getEmEyeExamBySpecialtyExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'eyeExamEandM',
    page: 'EM Eyeexam',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}

export function getEMEyeExamByProviderExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagementMultilevel,
                                                                             summaryData: EvaluationManagementSummary,
                                                                             copyRight: string,
                                                                             benchmarkOption: BenchmarkOption,
                                                                             suppressZeroes: boolean,
                                                                             nodePath?: string,
                                                                             dateRange?: any,
                                                                             memberLocationName?: any,
                                                                             telehealthFlag?: string): ExportMetadata {
  return {
    summaryHeaders: ['Time Range', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    summaryData: getEmEyeExamExportSummaryDataWithExtendedBenchmarkOptions(summaryData, benchmarkOption),
    detailHeaders: ['Provider/Specialty/Department', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    detailData: getEmEyeExamByProviderExportDetailDataWithExtendedBenchmarkOptions(evaluationManagementData,
      benchmarkOption, suppressZeroes),
    fileName: 'eyeExamEandM',
    page: 'EM Eyeexam',
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, telehealthFlag),
    isBlankRowAfterSummary: true
  };
}


export function getWrvuTrendExcelExportDetailData(monthData: MergedProductivityTrendEntry[],
                                                  viewCommunityBenchmarks: boolean)
  : string[][] {

  const result: string[][] = [];
  monthData.forEach((monthProductivity: MergedProductivityTrendEntry) => {
    result.push([monthProductivity.year.toString() + ' ' + toMonthName(monthProductivity.month.toString()),
      formatNumberToWholeNumber(monthProductivity.cfteAdjustedWRVUs),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(monthProductivity.communityBenchmarkMean)
        : formatNumberToWholeNumber(monthProductivity.benchmarkMean),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(monthProductivity.communityBenchmark25thPercentile)
        : formatNumberToWholeNumber(monthProductivity.benchmark25thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(monthProductivity.communityBenchmark50thPercentile)
        : formatNumberToWholeNumber(monthProductivity.benchmark50thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(monthProductivity.communityBenchmark65thPercentile)
        : formatNumberToWholeNumber(monthProductivity.benchmark65thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(monthProductivity.communityBenchmark75thPercentile)
        : formatNumberToWholeNumber(monthProductivity.benchmark75thPercentile),
      viewCommunityBenchmarks ? formatNumberToWholeNumber(monthProductivity.communityBenchmark90thPercentile)
        : formatNumberToWholeNumber(monthProductivity.benchmark90thPercentile),
      formatNumberToWholeNumber(monthProductivity.wRVUs),
      roundToWithCommasSeparation(monthProductivity.cfte, 2),
      formatNumberToWholeNumber(monthProductivity.charges),
      formatNumberToWholeNumber(varianceForWrvuTrend(monthProductivity, BenchmarkPercentile.Mean, viewCommunityBenchmarks)),
      formatNumberToWholeNumber(varianceForWrvuTrend(
        monthProductivity,
        BenchmarkPercentile.Percentile25th,
        viewCommunityBenchmarks
      )),
      formatNumberToWholeNumber(varianceForWrvuTrend(
        monthProductivity,
        BenchmarkPercentile.Percentile50th,
        viewCommunityBenchmarks
      )),
      formatNumberToWholeNumber(varianceForWrvuTrend(
        monthProductivity,
        BenchmarkPercentile.Percentile65th,
        viewCommunityBenchmarks
      )),
      formatNumberToWholeNumber(varianceForWrvuTrend(
        monthProductivity,
        BenchmarkPercentile.Percentile75th,
        viewCommunityBenchmarks
      )),
      formatNumberToWholeNumber(varianceForWrvuTrend(
        monthProductivity,
        BenchmarkPercentile.Percentile90th,
        viewCommunityBenchmarks
      )),
      formatNumberToWholeNumber(monthProductivity.previousCfteAdjustedWRVUs),
      formatNumberToWholeNumber(monthProductivity.difference)]);
  });

  return result;
}


export function getWrvuTrendData(summaryData: SummaryData<ProductivitySummary>,
                                 trendData: MergedProductivityTrendEntry[],
                                 copyright: string,
                                 viewCommunityBenchmarks: boolean,
                                 nodePath?: string,
                                 dateRange?: any,
                                 memberLocationName?: any
): ExportMetadata {
  let summaryHeaders = [''];
  let detailHeaders = [''];
  if (viewCommunityBenchmarks) {
    summaryHeaders = [
      'Time Range', 'cFTE Adj. wRVUs', `Community wRVU Benchmark (Mean)`, `Community wRVU Benchmark (25th)`,
      `Community wRVU Benchmark (50th)`, `Community wRVU Benchmark (65th)`, `Community wRVU Benchmark (75th)`,
      `Community wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges',
      `Variance from Community Benchmark (Mean)`,
      `Variance from Community Benchmark (25th)`,
      `Variance from Community Benchmark (50th)`,
      `Variance from Community Benchmark (65th)`,
      `Variance from Community Benchmark (75th)`,
      `Variance from Community Benchmark (90th)`,
      'Previous Date Range cFTE Adj. wRVUs',
      'cFTE Adj. wRVUs Difference from Previous Date Range'
    ];
    detailHeaders = [
      'By Month', 'cFTE Adj. wRVUs', `Community wRVU Benchmark (Mean)`, `Community wRVU Benchmark (25th)`,
      `Community wRVU Benchmark (50th)`, `Community wRVU Benchmark (65th)`, `Community wRVU Benchmark (75th)`,
      `Community wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges',
      `Variance from Community Benchmark (Mean)`,
      `Variance from Community Benchmark (25th)`,
      `Variance from Community Benchmark (50th)`,
      `Variance from Community Benchmark (65th)`,
      `Variance from Community Benchmark (75th)`,
      `Variance from Community Benchmark (90th)`,
      'Previous Date Range cFTE Adj. wRVUs',
      'cFTE Adj. wRVUs Difference from Previous Date Range'
    ];
  } else {
    summaryHeaders = [
      'Time Range', 'cFTE Adj. wRVUs', `wRVU Benchmark (Mean)`, `wRVU Benchmark (25th)`,
      `wRVU Benchmark (50th)`, `wRVU Benchmark (65th)`, `wRVU Benchmark (75th)`,
      `wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges',
      `Variance from Benchmark (Mean)`,
      `Variance from Benchmark (25th)`,
      `Variance from Benchmark (50th)`,
      `Variance from Benchmark (65th)`,
      `Variance from Benchmark (75th)`,
      `Variance from Benchmark (90th)`,
      'Previous Date Range cFTE Adj. wRVUs',
      'cFTE Adj. wRVUs Difference from Previous Date Range'
    ];
    detailHeaders = [
      'By Month', 'cFTE Adj. wRVUs', `wRVU Benchmark (Mean)`, `wRVU Benchmark (25th)`,
      `wRVU Benchmark (50th)`, `wRVU Benchmark (65th)`, `wRVU Benchmark (75th)`,
      `wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges',
      `Variance from Benchmark (Mean)`,
      `Variance from Benchmark (25th)`,
      `Variance from Benchmark (50th)`,
      `Variance from Benchmark (65th)`,
      `Variance from Benchmark (75th)`,
      `Variance from Benchmark (90th)`,
      'Previous Date Range cFTE Adj. wRVUs',
      'cFTE Adj. wRVUs Difference from Previous Date Range'
    ];
  }

  return {
    summaryHeaders: summaryHeaders,
    summaryData: getWrvuTrendExportSummaryData(summaryData, viewCommunityBenchmarks),
    detailHeaders: detailHeaders,
    detailData: getWrvuTrendExcelExportDetailData(trendData, viewCommunityBenchmarks),
    fileName: ('wRVUbyMonths'),
    page: 'Wrvu Trend',
    title: copyright,
    copyright: copyright,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getNpvTrendExcelExportDetailData(monthData: NewPatientVisitTrendEntry[],
                                                 viewCommunityBenchmarks: boolean): string[][] {
  const result: string[][] = [];

  monthData.forEach((monthNPV: NewPatientVisitTrendEntry) => {
    const npvPercentage = roundTo(monthNPV.newPatientVisitsPercentage, 1);
    const preNpvPercentage = roundTo(monthNPV.previousNewPatientVisitsPercentage, 1);
    // @ts-ignore
    result.push([monthNPV.year.toString() + ' ' + toMonthName(monthNPV.month.toString()),
      npvPercentage === '-' ? npvPercentage : npvPercentage + '%',
      formatNumberToWholeNumber(monthNPV.countOfNewPatientVisits),
      formatNumberToWholeNumber(monthNPV.countOfTotalPatientVisits),
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Mean, viewCommunityBenchmarks),
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks),
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks),
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks),
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks),
      roundTo(lookupVarianceValue(BenchmarkPercentile.Mean, monthNPV, viewCommunityBenchmarks), 1) + '%',
      roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile25th, monthNPV, viewCommunityBenchmarks), 1) + '%',
      roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile50th, monthNPV, viewCommunityBenchmarks), 1) + '%',
      roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile75th, monthNPV, viewCommunityBenchmarks), 1) + '%',
      roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile90th, monthNPV, viewCommunityBenchmarks), 1) + '%',
      preNpvPercentage === '-' ? preNpvPercentage : preNpvPercentage + '%',
      getDifferencePercentage(monthNPV)]);
  });
  return result;
}

export function getNpvSnapshotExcelExportDetailData(newPatientVisits: MergedNewPatientVisitSnapshotEntry[],
                                                    viewCommunityBenchmarks: boolean): string[][] {
  const result: string[][] = [];
  newPatientVisits.forEach((newPatientVisit: MergedNewPatientVisitSnapshotEntry) => {
    const npVisitPercentage = roundTo(newPatientVisit.newPatientVisitsPercentage, 1);
    const preNpVisitPercentage = roundTo(newPatientVisit.previousNewPatientVisitsPercentage, 1);
    result.push([newPatientVisit.nodeName,
      npVisitPercentage === '-' ? npVisitPercentage : npVisitPercentage + '%',
      formatNumberToWholeNumber(newPatientVisit.countOfNewPatientVisits),
      formatNumberToWholeNumber(newPatientVisit.countOfTotalPatientVisits),
      getBenchmarkPercentileForExport(newPatientVisit, BenchmarkPercentile.Mean, viewCommunityBenchmarks),
      getBenchmarkPercentileForExport(newPatientVisit, BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks),
      getBenchmarkPercentileForExport(newPatientVisit, BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks),
      getBenchmarkPercentileForExport(newPatientVisit, BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks),
      getBenchmarkPercentileForExport(newPatientVisit, BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks),
      roundTo((checkForNulls(getVarianceFromTypeForNpvSnapshot(newPatientVisit, BenchmarkPercentile.Mean,
        viewCommunityBenchmarks))), 1) + '%',
      roundTo((checkForNulls(getVarianceFromTypeForNpvSnapshot(newPatientVisit, BenchmarkPercentile.Percentile25th,
        viewCommunityBenchmarks))), 1) + '%',
      roundTo((checkForNulls(getVarianceFromTypeForNpvSnapshot(newPatientVisit, BenchmarkPercentile.Percentile50th,
        viewCommunityBenchmarks))), 1) + '%',
      roundTo((checkForNulls(getVarianceFromTypeForNpvSnapshot(newPatientVisit, BenchmarkPercentile.Percentile75th,
        viewCommunityBenchmarks))), 1) + '%',
      roundTo((checkForNulls(getVarianceFromTypeForNpvSnapshot(newPatientVisit, BenchmarkPercentile.Percentile90th,
        viewCommunityBenchmarks))), 1) + '%',
      preNpVisitPercentage === '-' ? preNpVisitPercentage : preNpVisitPercentage + '%',
      getDifferencePercentage(newPatientVisit)]);
  });
  return result;
}

export function getExcelNpvSnapshotData(summaryData: SummaryData<NewPatientVisitSummary>,
                                        providerData: MergedNewPatientVisitSnapshotEntry[],
                                        level: string,
                                        viewCommunityBenchmarks: boolean,
                                        benchmarkOption: BenchmarkOption | undefined,
                                        nodePath?: string,
                                        dateRange?: any,
                                        memberLocationName?: any,
                                        payerCategory?: any
): ExportMetadata {

  const communityText = viewCommunityBenchmarks ? ' Community' : '';
  const summaryHeaders = [
    'Time Range',
    '% New Patient Visits',
    '# of New Patients',
    '# of Total Patients',
    `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
    `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
    `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
    `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
    `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
    `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
    `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
    `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
    `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
    `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
    'Previous Dates % New Patients',
    '% New Patient Visits Difference from Previous Date Range'];
  const detailHeaders =
    [toTitleCase(level),
      '% New Patient Visits',
      '# of New Patients',
      '# of Total Patients',
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
      'Previous Dates % New Patients',
      '% New Patient Visits Difference from Previous Date Range'];
  const copyRight = 'Clinical Practice Solutions Center Productivity Summary ' + new Date().toLocaleString() + ';';
  return {
    summaryHeaders: summaryHeaders,
    summaryData: getNpvSnapshotExportSummaryData(summaryData, viewCommunityBenchmarks, benchmarkOption),
    detailHeaders: detailHeaders,
    detailData: getNpvSnapshotExcelExportDetailData(providerData, viewCommunityBenchmarks),
    fileName: ('Npv ' + level),
    page: 'Npv ' + level,
    title: copyRight,
    copyright: copyRight,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, payerCategory),
    isBlankRowAfterSummary: true
  };
}

export function getEmTrendExcelDataWithExtendedBenchmarkOptions(dimension: EmDimension,
                                                                nodes: ProviderMultilevelCptCounts[],
                                                                emSummary: EvaluationManagementSummary,
                                                                copyRight: string, memberName: string, nodePath: string, dateRange: string,
                                                                location: string, payer: string, patientType: string,
                                                                visitType: string, benchmarkOption: BenchmarkOption): ExportMetadata {
  const emColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(dimension, benchmarkOption);
  const detailData: string[][] = [];
  nodes.forEach(node => {
    const dataNames: any[] = [];
    let rowsEach = 0;
    emColumns.forEach(col => {
      const dataName = col.dataName(node);
      if (dataName.length > rowsEach) {
        rowsEach = dataName.length;
      }
      dataNames.push(dataName);
    });
    const rows: string[][] = [];
    for (let i = 0; i < rowsEach; i++) {
      rows.push([]);
    }
    dataNames[1][0] = dataNames[0][0] + ' ' + dataNames[1][0];
    dataNames.slice(1).forEach(dN => {
      for (let j = 0; j < dN.length; j++) {
        rows[j].push(dN[j]);
      }
    });
    rows.forEach(row => {
      detailData.push(row);
    });
  });
  let summaryData;
  let fileName;
  switch (dimension) {
    case EmDimension.Outpatient:
      summaryData = getEmOutpatientExportSummaryDataWithExtendedBenchmarkOptions(emSummary, benchmarkOption);
      fileName = 'EM Outpatient Trend';
      break;
    case EmDimension.InPatient:
      summaryData = getEmInpatientExportSummaryDataWithExtendedBenchmarkOptions(emSummary, benchmarkOption);
      fileName = 'EM Inpatient Trend';
      break;
    case EmDimension.EmergencyMedicine:
      summaryData = getEmEmergencyExportSummaryDataWithExtendedBenchmarkOptions(emSummary, benchmarkOption);
      fileName = 'EM Emergency Medicine Trend';
      break;
    case EmDimension.Ophthalmology:
      summaryData = getEmEyeExamExportSummaryDataWithExtendedBenchmarkOptions(emSummary, benchmarkOption);
      fileName = 'EM Ophthalmology Trend';
  }
  const detailHeaders = emColumns.map(col => col.header);
  return {
    summaryData: summaryData,
    detailData: detailData,
    copyright: copyRight,
    summaryHeaders: ['Time Range'].concat(detailHeaders.slice(2)),
    detailHeaders: ['Year Month'].concat(detailHeaders.slice(2)),
    fileName: fileName,
    page: fileName,
    title: copyRight,
    filterInfo: [calculateBreadcrumbs(nodePath), dateRange, location, 'Patient Type: ' + patientType, visitType],
    isBlankRowAfterSummary: true
  };
}

export function getEmTrendCsvDataWithExtendedBenchmarkOptions(dimension: EmDimension,
                                                              nodes: ProviderMultilevelCptCounts[], emSummary: EvaluationManagementSummary,
                                                              copyRight: string, memberName: string, nodePath: string, dateRange: string,
                                                              location: string, payer: string, patientType: string,
                                                              visitType: string, benchmarkOption: BenchmarkOption): Export {
  const asExcel = getEmTrendExcelDataWithExtendedBenchmarkOptions(dimension, nodes, emSummary,
    copyRight, memberName, nodePath, dateRange, location, payer, patientType, visitType, benchmarkOption);
  return {
    data: asExcel.summaryData.concat([[]]).concat(asExcel.detailData),
    headers: asExcel.detailHeaders || [],
    fileName: asExcel.fileName,
    page: asExcel.page,
    title: asExcel.title,
    whatFilters: {
      showPayer: false,
      showLag: false,
      showBilling: false
    }
  };
}

