import {MonthNumber} from './helpers';
import {
  BatchExportStatus,
  ColumnType,
  DateRangeOption,
  DefaultPage,
  FilterBannerTab,
  FrontEndTelehealthFlag,
  LegendColor,
  LegendStyle,
  MultilevelTabCollections,
  NodeExpansionState,
  OntologyLevel,
  PayerType,
  SortingOrder
} from './enums';
import {MetricType} from './metric-types';
import {BatchExportReportScheduleType} from '../batch-export/batch-export-helper';
import {GroupDialogEvent, ManageableGroup} from '../updated-filter-banner/manage-groups-helper';
import {FilterCriteria} from '../store/IAppState';
import {BehaviorSubject} from 'rxjs';
import {NewPatientVisitsBase} from '../new-patient-visits/components/npv-models';

export interface OntologyNode {
  nodeName: string;
  nodePath: string;
  children: OntologyNode[];
  isCustom: boolean;
  selected?: boolean;
}

export interface MultilevelNode {
  departmentNodeId?: number;
  departmentNodeName: string;
  departmentNodePath: string;
  specialtyNodeId?: number;
  specialtyNodeName: string;
  specialtyNodePath: string;
  providerNodeId?: number;
  providerNodeName: string;
  providerNodePath: string;
}

export interface OptionalMultilevelNode {
  departmentNodeId?: number;
  departmentNodeName: string;
  departmentNodePath: string;
  specialtyNodeId?: number;
  specialtyNodeName?: string;
  specialtyNodePath?: string;
  providerNodeId?: number;
  providerNodeName?: string;
  providerNodePath?: string;
}

export interface Breadcrumb {
  nodeName: string;
  nodePath: string;
}

export interface DateRange {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  selectedDateRangeOption?: DateRangeOption;
  fiscalYear?: number;
}

export interface CustomGroupsDataAllFilters extends ManageableGroup {
  description: string;
  nodePath: string;
  preload: boolean;
  payerCategoryKey: number;
  payerCategoryDescription?: string;
  nationalPayerKey?: number;
  nationalPayerDescription?: string;
  memberPayerKey?: number;
  memberPayerDescription?: string;
  payerType: PayerType;
  memberLocationKey: number;
  memberLocationDescription?: string;
  memberBillingAreaKey: number;
  memberBillingAreaDescription?: string;
  invoiceStatus: number;
  lagKey: number;
  startDateInMonths: number;
  endDateInMonths: number;
  dateRangeOption?: DateRangeOption;
  telehealthFlag: number | null;
  memberLocationKeys: string;
  cptFamilyDesc?: string;
  cptRangeDesc?: string;
  dataSet?: number;
}

export interface SelectableNode {
  node: OntologyNode;
  selected: boolean;
  matchesFilterSearchText: boolean;
  belongs: boolean;
  level: OntologyLevel;
}

export interface HierarchicalSelectableNode {
  selectableNode: SelectableNode;
  ancestors: Map<string, SelectableNode[]>;
  descendants: Map<string, SelectableNode[]>;
}

export interface DefaultPageObject {
  name: string;
  type: DefaultPage;
}

export interface LagPeriod {
  key: number;
  name: string;
}

export interface InvoiceStatus {
  statusId: number;
  statusText: string;
  all?: boolean;
}

export interface VisitType {
  key: FrontEndTelehealthFlag;
  text: string;
  all?: boolean;
}

export interface UserSecurity {
  email: string;
  memberKey: number;
  departmentKey: number;
  specialtyKey: number;
}

export interface UserMemberData {
  memberKey: number;
  memberDesc: string;
  nodePath: string;
  recentMonth: number;
}

export interface MemberBillingArea {
  memberBillingAreaKey: number;
  memberBillingAreaDescription: string;
  memberBillingAreaCode: string;
}

export interface SelectableMemberBillingArea {
  memberBillingArea: MemberBillingArea;
  matchesSearchText: boolean;
  selected: boolean;
}

export interface PdfExportData {
  wrvuTrend: boolean;
  wrvuSnapshot: boolean;
  npvTrend: boolean;
  npvSnapshot: boolean;
  emOutpatient: boolean;
  emInpatient: boolean;
  emEmergencyMedicine: boolean;
  emOphthalmology: boolean;
  overview: boolean;
  collections: boolean;
}

export interface TableCell {
  label: string;
  field: string;
}

export interface SortingCriterion {
  sortingOrder: SortingOrder;
  columnDef: string;
  columnType?: ColumnType;
}

export interface SelectionCounts {
  providers: number;
  specialties: number;
  departments: number;
}

export interface FilterBannerSelectableTab {
  tab: FilterBannerTab;
  available: boolean;
}

export interface SelectedHierarchicalNodes {
  selectedFilteredProviders: HierarchicalSelectableNode[];
  listOfAllDepartments: HierarchicalSelectableNode[];
  listOfAllSpecialties: HierarchicalSelectableNode[];
  listOfAllProviders: HierarchicalSelectableNode[];
  allDepartmentsNodePath: string;
  group?: CustomGroupsDataAllFilters;
  groupIsEdited?: boolean;
  askParentToNotCloseEditPopup?: boolean;
  selectedProvidersAtTimeOfGroupSelection?: HierarchicalSelectableNode[];
}

export interface BatchExportBase {
  reportName: string;
  startDateInMonths: number;
  endDateInMonths: number;
  payerCategoryKey: number;
  payerKey: number;
  payerType: number;
  lagKey: number;
  invoiceStatus: number;
  nodePath: string;
  isOutpatientRequired: boolean;
  isInPatientRequired: boolean;
  isEmergencyMedicineRequired: boolean;
  isOpthalmologyRequired: boolean;
  telehealthFlag: number | null;
  isOverviewRequired: boolean;
  isEmPageRequired: boolean;
  isEmPageOutpatientRequired: boolean;
  isEmPageInPatientRequired: boolean;
  isEmPageEmergencyMedicineRequired: boolean;
  isEmPageOpthalmologyRequired: boolean;
  isNewPatientVisitsTrendPageRequired: boolean;
  memberLocationKeys: string;
  isEmTrendPageRequired: boolean;
  emTrendRangeNewPatientVisit: boolean;
  emTrendRangeEstablishedPatientVisit: boolean;
  emTrendRangeConsultation: boolean;
  emTrendRangeInitialHospital: boolean;
  emTrendRangeSubsequentHospital: boolean;
  emTrendRangeHospitalDischarge: boolean;
  emTrendRangeEmergencyMedicine: boolean;
  emTrendRangeNpvEyeExam: boolean;
  emTrendRangeEstablishedEyeExam: boolean;
}

export interface BatchExportDetailBase extends BatchExportBase {
  memberLocationKey: number;
  memberLocationName: string;
  memberBillingAreaKey: number;
  billingArea: string;
  memberName: string;
  multiLocationMessage: string;
}

export interface BatchExportCriteria extends BatchExportBase {
  npvTileBenchmarkType?: number;
  npvTileBenchmarkPercentile?: number;
  wrvuTileBenchmarkType?: number;
  wrvuTileBenchmarkPercentile?: number;
  emTileBenchmarkType?: number;
  emTrendBenchmarkType?: number;
  emPageBenchmarkType?: number;
  npvPageBenchmarkType?: number;
  npvPageBenchmarkPercentile?: number;
}

export interface BatchExportDetailCriteria extends BatchExportCriteria, BatchExportDetailBase {
  payerCategoryDescription: string;
  getBatchExportReportDetailsByUser: boolean;
}

export interface BatchExportDetailsResponse {
  batchExportDetails: BatchExportDetails[];
}

export interface BatchExportSummaryResponse {
  batchExportDetails: BatchExportHeader[];
}

export interface BatchExportDetails extends BatchExportBase {
  id: number;
  headerId: number;
  memberKey: number;
  email: string;
  headerStatus: number;
  detailId: number;
  memberLocationKey: number;
  memberBillingAreaKey: number;
  detailStatus: number;
  path: string;
}

export interface BatchExportHeader {
  id: number;
  reportName: string;
  memberKey: number;
  email: string;
  nodePath: string;
  status: BatchExportStatus;
  path: string;
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  numDepartments?: number;
  numSpecialties?: number;
  numProviders?: number;
  statusDesc?: string;
}

export interface BatchExportReportDetailsResponseEntity {
  id: number;
  reportId: number;
  reportName: string;
  reportStatus: number;
  exportDate: string;
  readStatus: boolean;
  path: string;
  numberOfDepartments: number;
  numberOfSpecialties: number;
  numberOfProviders: number;
  reportScheduleType?: BatchExportReportScheduleType;
  // todo remove ?s once scheduling feature is released
  isPaused?: boolean;
}

export interface BatchExportReportDetailsResponse {
  batchExportReportDetails: BatchExportReportDetailsResponseEntity[];
}

export interface DialogData {
  component?: any;
  filters?: FilterCriteria;
  title?: string;
  question: string;
  cancelText?: string;
  buttonWrapperClass?: string;
  confirmButtonAltText?: string;
  confirmCallback?: (component?: any, filters?: FilterCriteria) => void;
  cancelCallback?: (component?: any, filters?: FilterCriteria) => void;
  dialogActionListener?: BehaviorSubject<GroupDialogEvent>;
}

export interface BatchFilterCriteria {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  memberKey: number;
  nodePath: string;
  memberLocationKey: number;
  payerCategoryKey: number;
  payerKey: number;
  payerType: number;
  lagKey: number;
  cptFamilyFilter?: string;
  cptRangeFilter?: string;
  memberBillingAreaKey: number;
  invoiceStatus: number;
  email: string;
  guid: string;
  headerId: number;
  detailId: number;
  telehealthFlag: number | null;
  memberLocationKeys: string;
}

export interface DropdownOption {
  value: any;
  name: string;
}

export interface NewPatientVisitSummary extends ExtendedBenchmarkOptions, NewPatientVisitsBase {
  startMonth: number;
  endMonth: number;
  startYear: number;
  endYear: number;
  summaryType: number;

  [key: string]: number | undefined;
}

export interface ZipCodeNewPatientVisits {
  lat: string;
  lng: string;
  zipCode: string;
  newPatientVisitCount: number;
  newPatientVisitPercentage: number;
  totalPatientVisitCount: number;
}

export interface GmapMarker {
  id: number;
  lat: number;
  lng: number;
  zipCode: string;
  newPatientVisitCount: number;
  newPatientVisitPercentage: string;
  totalPatientVisitCount: number;
}

export interface ProviderNewPatientVisitsExport {
  providerName: string;
  newPatientVisitsPercentage: string;
  countOfNewPatientVisits: string;
  countOfTotalNewPatientVisits: string;
  benchmark25thPercentile?: string;
  benchmark50thPercentile?: string;
  benchmark75thPercentile?: string;
  benchmark90thPercentile?: string;
  benchmarkMean?: string;
  varianceMean?: string;
  variance25thPercentile?: string;
  variance50thPercentile?: string;
  variance75thPercentile?: string;
  variance90thPercentile?: string;
  communityBenchmark25thPercentile?: string;
  communityBenchmark50thPercentile?: string;
  communityBenchmark75thPercentile?: string;
  communityBenchmark90thPercentile?: string;
  communityBenchmarkMean?: string;
  communityVarianceMean?: string;
  communityVariance25thPercentile?: string;
  communityVariance50thPercentile?: string;
  communityVariance75thPercentile?: string;
  communityVariance90thPercentile?: string;
  previousNewPatientVisitsPercentage: string;
  difference: string;

  [key: string]: any;

}

export interface MonthNewPatientVisitsExport {
  date: string;
  benchmarkMean?: string;
  benchmark25thPercentile?: string;
  benchmark50thPercentile?: string;
  benchmark75thPercentile?: string;
  benchmark90thPercentile?: string;
  communityBenchmarkMean?: string;
  communityBenchmark25thPercentile?: string;
  communityBenchmark50thPercentile?: string;
  communityBenchmark65thPercentile?: string;
  communityBenchmark75thPercentile?: string;
  communityBenchmark90thPercentile?: string;
  newPatientVisitsPercentage: string;
  countOfNewPatientVisits: string;
  countOfTotalPatientVisits: string;
  varianceMean?: string;
  variance25thPercentile?: string;
  variance50thPercentile?: string;
  variance75thPercentile?: string;
  variance90thPercentile?: string;
  communityVarianceMean?: string;
  communityVariance25thPercentile?: string;
  communityVariance50thPercentile?: string;
  communityVariance65thPercentile?: string;
  communityVariance75thPercentile?: string;
  communityVariance90thPercentile?: string;
  previousNewPatientVisitsPercentage: string;
  difference: string;
}

export interface MonthProductivityEntry extends HasBenchmarks, Productivity {
  year: number;
  month: MonthNumber;
}

export interface MergedProductivityTrendEntry extends MonthProductivityEntry, PreviousBenchmarks, PreviousProductivity {
  [key: string]: number | undefined | string | null;
}

export interface MonthProductivity {
  monthProductivities: MonthProductivityEntry[];
}

export interface MonthProductivityData {
  monthProductivityData: MonthProductivity;
}

export interface MonthProductivityExport {
  'Time Range': string;
  wRVUs: string;
  charges: string;
  cFTEAdjustedwRVUs: string;
  benchmarkMean: string;
  benchmark25th: string;
  benchmark50th: string;
  benchmark65th: string;
  benchmark75th: string;
  benchmark90th: string;
  cfte: string;
  varianceMean: string;
  variance25th: string;
  variance50th: string;
  variance65th: string;
  variance75th: string;
  variance90th: string;
  previousCfteAdjustedWRVUs: string;
  difference: string;
}

export class CptSummary {
  count: number;
  benchmarkCptUnits: number;
  benchmarkRangeUnits: number;
  totalCount: number;
  communityBenchmarkCptUnits: number;
  communityBenchmarkRangeUnits: number;
  telehealthBenchmarkCptUnits: number;
  telehealthBenchmarkRangeUnits: number;
  communityTelehealthBenchmarkCptUnits: number;
  communityTelehealthBenchmarkRangeUnits: number;
  inPersonBenchmarkCptUnits: number;
  inPersonBenchmarkRangeUnits: number;
  communityInPersonBenchmarkCptUnits: number;
  communityInPersonBenchmarkRangeUnits: number;
  percentage: number;
  benchmarkPercentage: number;
  communityBenchmarkPercentage: number;
  telehealthBenchmarkPercentage?: number;
  communityTelehealthBenchmarkPercentage?: number;
  inPersonBenchmarkPercentage?: number;
  communityInPersonBenchmarkPercentage?: number;

  [key: string]: number | undefined;
}

export class CptRange {
  [key: string]: CptSummary | undefined;
}

export class NewPatientVisit extends CptRange {
  cpt99201?: CptSummary;
  cpt99202?: CptSummary;
  cpt99203?: CptSummary;
  cpt99204?: CptSummary;
  cpt99205?: CptSummary;

  [key: string]: CptSummary | undefined;
}

export class EstablishedPatientVisit extends CptRange {
  cpt99211?: CptSummary;
  cpt99212?: CptSummary;
  cpt99213?: CptSummary;
  cpt99214?: CptSummary;
  cpt99215?: CptSummary;

  [key: string]: CptSummary | undefined;
}

export class Consultation extends CptRange {
  cpt99241?: CptSummary;
  cpt99242?: CptSummary;
  cpt99243?: CptSummary;
  cpt99244?: CptSummary;
  cpt99245?: CptSummary;
}

export class SubsequentHospitalCare extends CptRange {
  cpt99231?: CptSummary;
  cpt99232?: CptSummary;
  cpt99233?: CptSummary;
}

export class ProviderCptCounts {
  nodeName: string;
  nodeId: number;
  nodePath: string;
  cptCategories: CptCategories;

  [key: string]: CptCategories | number | string;
}

export class ProviderMultilevelCptCounts {
  departmentNodeName: string;
  departmentNodeId: number;
  departmentNodePath: string;
  specialtyNodeName: string;
  specialtyNodeId: number;
  specialtyNodePath: string;
  providerNodeName: string;
  providerNodeId: number;
  providerNodePath: string;
  cptCategories: CptCategories;

  [key: string]: CptCategories | number | string;
}

export class InitialHospitalCare extends CptRange {
  cpt99221?: CptSummary;
  cpt99222?: CptSummary;
  cpt99223?: CptSummary;
}

export class HospitalDischargeDay extends CptRange {
  cpt99238?: CptSummary;
  cpt99239?: CptSummary;
}

export class EmergencyMedicine extends CptRange {
  cpt99281?: CptSummary;
  cpt99282?: CptSummary;
  cpt99283?: CptSummary;
  cpt99284?: CptSummary;
  cpt99285?: CptSummary;
}

export class EyeExamNewPatient extends CptRange {
  cpt92002?: CptSummary;
  cpt92004?: CptSummary;
}

export class EyeExamEstablishedPatient extends CptRange {
  cpt92012?: CptSummary;
  cpt92014?: CptSummary;
}

export class CptCategories {
  newPatientVisit: NewPatientVisit;
  establishedPatientVisit: EstablishedPatientVisit;
  consultation: Consultation;
  subsequentHospitalCare: SubsequentHospitalCare;
  initialHospitalCare: InitialHospitalCare;
  hospitalDischargeDay: HospitalDischargeDay;
  emergencyMedicine: EmergencyMedicine;
  eyeExamNewPatient: EyeExamNewPatient;
  eyeExamEstablishedPatient: EyeExamEstablishedPatient;

  [key: string]: CptRange;
}

export class ProviderEvaluationManagement {
  totals: CptCategories;
  nodes: ProviderCptCounts[];
}

export class ProviderMultilevelEvaluationManagement {
  departmentEvaluationManagementSnapshotData: { nodes: ProviderMultilevelCptCounts[], totals: CptCategories };
  specialtyEvaluationManagementSnapshotData: { nodes: ProviderMultilevelCptCounts[], totals: CptCategories };
  providerEvaluationManagementSnapshotData: { nodes: ProviderMultilevelCptCounts[], totals: CptCategories };
  providerEvaluationManagementSnapshotDataMonthly?: { nodes: ProviderMultilevelCptCounts[], totals: CptCategories };
}

export class EvaluationManagement {
  providerEvaluationManagement: ProviderEvaluationManagement;
}

export class EvaluationManagementMultilevel {
  evaluationManagementData: ProviderMultilevelEvaluationManagement;
}

export class EvaluationManagementSummary {
  startYear: number;
  endYear: number;
  startMonth: number;
  endMonth: number;
  totals: CptCategories;
}

export enum EmDimension {
  Outpatient = 0,
  InPatient = 1,
  EmergencyMedicine = 2,
  Ophthalmology = 3,
}

export interface NpvBenchmarkData {
  benchmarkValue: number;
  totalNPV: number;
}

export interface HasBenchmarks {
  benchmark25thPercentile: number;
  benchmark50thPercentile: number;
  benchmark65thPercentile: number;
  benchmark75thPercentile: number;
  benchmark90thPercentile: number;
  benchmarkMean: number;
  communityBenchmark25thPercentile: number;
  communityBenchmark50thPercentile: number;
  communityBenchmark65thPercentile: number;
  communityBenchmark75thPercentile: number;
  communityBenchmark90thPercentile: number;
  communityBenchmarkMean: number;
}

export interface PreviousBenchmarks {
  previousBenchmark25thPercentile?: number;
  previousBenchmark50thPercentile?: number;
  previousBenchmark65thPercentile?: number;
  previousBenchmark75thPercentile?: number;
  previousBenchmark90thPercentile?: number;
  previousBenchmarkMean?: number;
  previousCommunityBenchmark25thPercentile?: number;
  previousCommunityBenchmark50thPercentile?: number;
  previousCommunityBenchmark65thPercentile?: number;
  previousCommunityBenchmark75thPercentile?: number;
  previousCommunityBenchmark90thPercentile?: number;
  previousCommunityBenchmarkMean?: number;
  previousTelehealthCommunityBenchmark25thPercentile?: number;
  previousTelehealthCommunityBenchmark50thPercentile?: number;
  previousTelehealthCommunityBenchmark65thPercentile?: number;
  previousTelehealthCommunityBenchmark75thPercentile?: number;
  previousTelehealthCommunityBenchmark90thPercentile?: number;
  previousTelehealthCommunityBenchmarkMean?: number;
  previousTelehealthAcademicBenchmark25thPercentile?: number;
  previousTelehealthAcademicBenchmark50thPercentile?: number;
  previousTelehealthAcademicBenchmark65thPercentile?: number;
  previousTelehealthAcademicBenchmark75thPercentile?: number;
  previousTelehealthAcademicBenchmark90thPercentile?: number;
  previousTelehealthAcademicBenchmarkMean?: number;
}

export interface Productivity {
  wRVUs: number;
  cfteAdjustedWRVUs: number;
  cfte: number;
  charges: number;
  varianceMean: number | null;
  variance25thPercentile: number | null;
  variance50thPercentile: number | null;
  variance65thPercentile: number | null;
  variance75thPercentile: number | null;
  variance90thPercentile: number | null;
  communityVarianceMean: number | null;
  communityVariance25thPercentile: number | null;
  communityVariance50thPercentile: number | null;
  communityVariance65thPercentile: number | null;
  communityVariance75thPercentile: number | null;
  communityVariance90thPercentile: number | null;
  telehealthCommunityVariance25thPercentile?: number;
  telehealthCommunityVariance50thPercentile?: number;
  telehealthCommunityVariance65thPercentile?: number;
  telehealthCommunityVariance75thPercentile?: number;
  telehealthCommunityVariance90thPercentile?: number;
  telehealthCommunityVarianceMean?: number;
  telehealthAcademicVariance25thPercentile?: number;
  telehealthAcademicVariance50thPercentile?: number;
  telehealthAcademicVariance65thPercentile?: number;
  telehealthAcademicVariance75thPercentile?: number;
  telehealthAcademicVariance90thPercentile?: number;
  telehealthAcademicVarianceMean?: number;
  specialtyPerformanceActualWrvus?: number;
  specialtyPerformanceCfteAdjWrvus?: number;
  specialtyPerformanceActualWrvuspVariance?: number;
  specialtyPerformanceCfteAdjWrvuspVariance?: number;
}

export interface PreviousProductivity {
  previousWrvus?: number;
  previousCfteAdjustedWRVUs?: number;
  previousCfte?: number;
  previousCharges?: number;
  difference?: number;
}

export interface MemberLocation {
  memberLocationKey: number;
  memberLocationName: string;
  memberLocationCode: string;
}

export interface CurrentMemberLocation extends MemberLocation {
  currentlySelected: boolean;
}

export interface MemberLocations {
  locations: MemberLocation[];
}

export interface MemberData {
  memberKey: number;
  memberDesc: string;
}

export interface UserProfile {
  userName: string;
  lastName: string;
  firstName: string;
  email: string;
  isEmployee: boolean;
  city: string;
  userTrackingKey: string;
  jobTitle: string;
}

export interface Members {
  members: MemberData[];
}

export interface FeatureToggleSetting {
  featureId: number;
  featureName: string;
  enabled: boolean;
  enabledInternal?: boolean;
  createdOn?: string;
  createdBy?: string;
  lastUpdatedOn?: string;
  lastUpdatedBy?: string;
}

export interface ApplicationConfigurationSetting {
  appConfigId: number;
  appConfigName: string;
  appConfigDesc: string;
}

export interface PayerCategory {
  payerCategoryKey: number;
  payerCategoryDescription: string;
  payerCategoryCode: string;

  [key: string]: number | string | undefined;
}

export interface PayerObject {
  level: PayerType;
  key: number;
  description?: string;
  payerCriteria: PayerFilterCriteria;
}

export interface PayerFilterCriteria {
  payerCategoryKey: number;
  payerCategoryDesc: string;
  nationalPayerKey?: number;
  nationalPayerDesc?: string;
  memberPayerKey?: number;
  memberPayerDesc?: string;
}

export interface PayerCategoryLevel {
  payerCategoryKey: number;
  payerCategoryDesc: string;
  nationalPayers: NationalPayerLevel[];
}

export interface NationalPayerLevel {
  nationalPayerKey: number;
  nationalPayerDesc: string;
  memberPayers: MemberPayerLevel[];
}

export interface MemberPayerLevel {
  memberPayerKey: number;
  memberPayerDesc: string;
}

export interface HierarchicalPayerSelection {
  key: number;
  payerNodePath: number[];
  description: string;
  perceivedExpansionState: ListItemExpansion;
  payerType: PayerType;
  hidden: boolean;
  matchesFilterSearchText: boolean;
  dropdownOptions: HierarchicalPayerSelection[];
}

export interface SelectableLocation {
  location: MemberLocation;
  selected: boolean;
  eliminated: boolean;
  matchesFilterSearchText: boolean;
}

export interface ListItemExpansion {
  state: NodeExpansionState;
  depth: number;
}

export interface ChangedFilterCriteriaParameters {
  isCollectionOnly?: boolean;
  isNewPatientVisitOnly?: boolean;
  isEvaluationManagementOnly?: boolean;
  isDenialsOnly?: boolean;
}

export const aDefaultChangedFilterCriteriaParameters = function (): ChangedFilterCriteriaParameters {
  return {
    isCollectionOnly: false,
    isNewPatientVisitOnly: false,
    isEvaluationManagementOnly: false,
    isDenialsOnly: false
  };
};

export interface BatchExportDetailResponse {
  batchExportDetails: BatchExportDetail[];
}

export interface BatchExportDetail extends BatchExportDetailBase, BatchExportCriteria {
  id: number;
  headerId: number;
  memberKey: number;
  email: string;
  headerStatus: number;
  detailId: number;
  payerCategoryName: string;
  detailStatus: number;
  path: string;
  departmentName: string;
  specialtyName: string;
  providerName: string;
}

export interface Expandable<T> {
  children?: T[];
  isExpanded?: boolean;
}

export interface ExtendedBenchmarkOptions {
  per25th: number;
  per50th: number;
  per75th: number;
  per90th: number;
  mean: number;
  communityPer25th?: number;
  communityPer50th?: number;
  communityPer75th?: number;
  communityPer90th?: number;
  communityMean?: number;
  telehealthPer25th?: number;
  telehealthPer50th?: number;
  telehealthPer75th?: number;
  telehealthPer90th?: number;
  telehealthMean?: number;
  telehealthCommunityPer25th?: number;
  telehealthCommunityPer50th?: number;
  telehealthCommunityPer75th?: number;
  telehealthCommunityPer90th?: number;
  telehealthCommunityMean?: number;
  inPersonPer25th?: number;
  inPersonPer50th?: number;
  inPersonPer75th?: number;
  inPersonPer90th?: number;
  inPersonMean?: number;
  inPersonCommunityPer25th?: number;
  inPersonCommunityPer50th?: number;
  inPersonCommunityPer75th?: number;
  inPersonCommunityPer90th?: number;
  inPersonCommunityMean?: number;
  variance25thPercentile: number;
  variance50thPercentile: number;
  variance75thPercentile: number;
  variance90thPercentile: number;
  varianceMean: number;
  communityVariance25thPercentile?: number;
  communityVariance50thPercentile?: number;
  communityVariance75thPercentile?: number;
  communityVariance90thPercentile?: number;
  communityVarianceMean?: number;
  telehealthVariance25thPercentile?: number;
  telehealthVariance50thPercentile?: number;
  telehealthVariance75thPercentile?: number;
  telehealthVariance90thPercentile?: number;
  telehealthVarianceMean?: number;
  telehealthCommunityVariance25thPercentile?: number;
  telehealthCommunityVariance50thPercentile?: number;
  telehealthCommunityVariance75thPercentile?: number;
  telehealthCommunityVariance90thPercentile?: number;
  telehealthCommunityVarianceMean?: number;
  inPersonVariance25thPercentile?: number;
  inPersonVariance50thPercentile?: number;
  inPersonVariance75thPercentile?: number;
  inPersonVariance90thPercentile?: number;
  inPersonVarianceMean?: number;
  inPersonCommunityVariance25thPercentile?: number;
  inPersonCommunityVariance50thPercentile?: number;
  inPersonCommunityVariance75thPercentile?: number;
  inPersonCommunityVariance90thPercentile?: number;
  inPersonCommunityVarianceMean?: number;
}

export interface ReturnedNewPatientVisitSummary extends ExtendedBenchmarkOptions {
  newPatientVisitsCount: number;
  newPatientVisitsPercentage: number;
  totalPatientVisitsCount: number;
  startMonth: number;
  endMonth: number;
  startYear: number;
  endYear: number;
  summaryType: number;

  [key: string]: number | undefined;
}

export interface FiscalQuarterOption {
  text: string;
  option: DateRangeOption;
  selected: boolean;
}

export interface ExternalRequestDetail {
  requestId: number;
  memberKey: number;
  emailId: string;
  nodePath: string;
  startDateInMonths: number;
  endDateInMonths: number;
  metricPageKey: MetricType;
  tabDimensionKey: MultilevelTabCollections;
}

export interface Legend {
  text: string;
  color: LegendColor;
  style: LegendStyle;
  metric: MetricType;
  showBenchmarkOptionControl?: boolean;
  showPercentileControl?: boolean;
  isAddOnVariable?: boolean;
}

export interface Subproduct {
  subproduct_id: number;
  subproduct_name: string;
  access_url: string;
  category_id: number;
}

export interface MemberLocationInfoBase {
  memberLocationName: string;
  memberLocationKey: number;
}
