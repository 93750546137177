import {OntologyNode} from './models';
import {getOntologyData} from './localStoragehelper';
import {lastIndexOf} from 'lodash';
import {MultilevelTab} from './enums';

export function getSelectedNodeCountsFromNodePath(nodePath: string) {
  let specialtyCount = 0, providerCount = 0;
  const ontology: OntologyNode = getOntologyData().ontologyHierarchy[0];
  if (ontology) {
    const departments: OntologyNode[] = ontology.children;
    const specialties: OntologyNode[] = departments
      .reduce((specialtyArray: OntologyNode[], nextDepartment: OntologyNode) => specialtyArray.concat(...nextDepartment.children), []);
    const providerSpecialties: Set<string> = new Set<string>();
    const individualNodePaths: string[] = nodePath.split('|');
    individualNodePaths.forEach(path => {
      const level: number = path.split('\\').length - 2;  // 0 = all, 1 = dep, 2 = spec, 3 = prov
      if (level === 0) {
        specialtyCount = specialties.length;
        providerCount = specialties
          .reduce((providers: OntologyNode[], nextSpecialty: OntologyNode) => providers.concat(...nextSpecialty.children), []).length;
      } else if (level === 1) {
        const currentDepartment: OntologyNode | undefined = departments.find(node => node.nodePath === path);
        const specialtiesForTheDepartment: OntologyNode[] | undefined = currentDepartment?.children;
        specialtyCount += specialtiesForTheDepartment?.length || 0;
        specialtiesForTheDepartment?.forEach(spec => providerCount += spec.children.length);
      } else if (level === 2) {
        const currentSpecialty: OntologyNode | undefined = specialties.find(node => node.nodePath === path);
        specialtyCount++;
        providerCount += currentSpecialty?.children.length || 0;
      } else if (level === 3) {
        providerCount++;
        const specialtyPath = path.slice(0, lastIndexOf(path, '\\'));
        providerSpecialties.add(specialtyPath);
      }
    });
    specialtyCount += providerSpecialties.size;
  }

  return {
    selectedProviders: providerCount,
    selectedSpecialties: specialtyCount
  };
}

export function nextGranularOntologyTab(tab: MultilevelTab): MultilevelTab {
  switch (tab) {
    case MultilevelTab.BY_DEPARTMENT:
      return MultilevelTab.BY_SPECIALTY;
    case MultilevelTab.LOCATION_DEPARTMENT:
      return MultilevelTab.LOCATION_SPECIALTY;
    case MultilevelTab.BY_SPECIALTY:
      return MultilevelTab.BY_PROVIDER;
    case MultilevelTab.LOCATION_SPECIALTY:
      return MultilevelTab.LOCATION_PROVIDER;
    case MultilevelTab.BY_PROVIDER:
    case MultilevelTab.SPECIALTY_PERFORMANCE:
      return MultilevelTab.TREND;
    default:
      return tab;
  }
}

export function getDepartmentTagId(nodePath: string): string {
  return nodePath.split('\\')[2];
}
