<section class="tab-container">
  <form [formGroup]="appConfigFormGroup" #formDirective="ngForm" (ngSubmit)="onSubmitAppConfigClicked()"
        class="form-container" data-testid="appConfigForm">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" hideRequiredMarker>
      <mat-label>New App Config Name</mat-label>
      <input matInput formControlName="appConfigName" placeholder="New Config Name"
             data-testid="inputConfigName">
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" hideRequiredMarker>
      <mat-label>App Config Description</mat-label>
      <input matInput formControlName="appConfigDesc" placeholder="Config Description"
             data-testid="inputConfigDesc">
    </mat-form-field>
    <button mat-button type="submit" data-testid="appConfigSubmitBtn" class="btn"
            [disabled]="!appConfigFormGroup.valid">Submit
    </button>
  </form>
  <br><br>

  <table mat-table [dataSource]="appConfigDataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="appConfigName">
      <th mat-header-cell *matHeaderCellDef> App Configuration Name</th>
      <td mat-cell *matCellDef="let element">
        <span id="config-name-{{element.appConfigId}}">
          {{element.appConfigName}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="appConfigDescription">
      <th mat-header-cell *matHeaderCellDef> App Configuration Description</th>
      <td mat-cell *matCellDef="let element">
        <input matInput placeholder="Configuration Description" #inputId
               id="input-desc-{{element.appConfigId}}"
               value="{{element.appConfigDesc}}"
               (blur)="onAppConfigInputBlur(element,inputId.value)"
               data-testid="input-{{element.appConfigId}}">
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="appConfigDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: appConfigDisplayedColumns;"></tr>
  </table>
</section>
