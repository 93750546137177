import { Observable } from 'rxjs';
import { FilterCriteria } from '../../store/IAppState';

export interface GraphEntry {
  label: string;
  value: number;
}

export interface Benchmark {
  title?: string;
  graph: Graph;
}

export interface Graph {
  entries: GraphEntry[];
}

export interface GraphService<TYPE> {
  getGraph(type: TYPE, filterCriteria: FilterCriteria): Observable<Graph>;
}

export enum GraphType {
  Provider = 'provider',
  Month = 'month',
  Department = 'department',
  Specialty = 'specialty',
}
