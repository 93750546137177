<div class="graph-container">
  <app-wrvu-multilevel-chart
    [isDepartment]="false"
    [isSpecialty]="false"
    [isProvider]="true"
    [showVariableMenu]="true"
    [level]="'By Provider'"
    [page]="currentPage"
    [showProgressBar]="showProgressBar"
    [variables]="variables"
    [activeVarianceToggle]="activeVarianceToggle"
    [zeroSuppression]="zeroSuppression"
    [benchmarkPercentile]="benchmarkPercentile"
    [viewCommunityBenchmarks]="viewCommunityBenchmarks"
    [wRVUtype]="wRVUtype"
    [selectionCounts]="selectionCounts"
    [varianceKey]="varianceKey"
    [sortingCriteria]="varianceToggleSortingCriterion"
    [columnSelectionCallback]="whenProviderSelected"
    [productivitySnapshotData]="wrvuSnapshotData"
    [isSpecialtyPerformance]="isSpecialtyPerformance"
  ></app-wrvu-multilevel-chart>
</div>

<app-wrvu-summary-data-table></app-wrvu-summary-data-table>

<app-data-table id="wrvu-provider-snapshot-table"
                [isSpecialtyPerformance]="isSpecialtyPerformance"
                [tableTitle]="'wRVUs by Provider'"
                [tableCssClass]="'wrvu-snapshot-table'"
                [showLevel]="false"
                [addRemoveOption]="true"
                [displayZeroSuppression]="true"
                [receivedData]="wrvuSnapshotData"
                [displayedColumns]="displayedColumns"
                [showProgressBar]="showProgressBar"
                [defaultSortColumn]="defaultSortColumn"
                [sortDirection]="sortDirection"
                [tieBreakerProperty]="tieBreakerProperty"
                [rowSelectionCallback]="whenProviderRowSelected"
                [updateSortingCriteria]="updateSortingCriteria"
                [whenSortChanged]="whenSortChanged"
                [isZeroSuppressionChecked]="zeroSuppression"
                [countOfSuppressedEntries]="countOfSuppressedEntries"
                [zeroSuppressionCondition]="zeroSuppressionCondition"
                [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                [currentPage]="currentPage"
                [originalColumns]="columns"
                [reducerAction]="reducerAction"
                [chosen]="MultilevelTab.BY_PROVIDER"
                [initialPageSize]=10
>
</app-data-table>
