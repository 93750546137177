import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {monthNames} from '../../../../shared/helpers';
import {isValidOrElse} from '../../../../shared/null-helpers';
import {MultilevelTab} from '../../../../shared/enums';
import {
    EmergencyMedicineCodes,
    EvaluationManagementEmergencyMultilevelRow,
    valueFromOrElse
} from '../../../../evaluation-management/em-helpers';

@Component({
  selector: 'app-em-batch-emergency-multilevel-table',
  templateUrl: './em-batch-emergency-multilevel-table.component.html',
  styleUrls: ['./em-batch-emergency-multilevel-table.component.scss']
})
export class EmBatchEmergencyMultilevelTableComponent implements OnChanges {
  @Input() receivedData: EvaluationManagementEmergencyMultilevelRow[];
  @Input() viewCommunityBenchmarks = false;
  EmergencyMedicineCodes = EmergencyMedicineCodes;

  MultilevelTab = MultilevelTab;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @select(['display', 'isCustom'])
  private readonly isCustom$: Observable<boolean>;

  dataSource: MatTableDataSource<EvaluationManagementEmergencyMultilevelRow>;
  displayedColumns = [ 'departmentNodeName', 'encounter',
    'countOf99281', 'countOf99282', 'countOf99283', 'countOf99284', 'countOf99285', 'totalEmergencyMedicine'];
  pageIndex = 0;
  pageSize = 25;
  isValidOrElse = isValidOrElse;
  valueFromOrElse = valueFromOrElse;
  monthNames = monthNames;
  benchmarkString = 'benchmarkPercentage';
  benchmarkDisplay: string;
  departmentHeading: 'Department';

  constructor() {
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<EvaluationManagementEmergencyMultilevelRow>(this.receivedData);
    if (this.viewCommunityBenchmarks) {
      this.benchmarkString = 'communityBenchmarkPercentage';
      this.benchmarkDisplay = 'Community';
    } else {
      this.benchmarkString = 'benchmarkPercentage';
      this.benchmarkDisplay = 'Academic';
    }
  }
}
