import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CptCategories, EvaluationManagementSummary} from '../../../shared/models';
import {MatTableDataSource} from '@angular/material/table';
import {select} from '@angular-redux/store';
import {INITIAL_STATE} from '../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {isValidOrElse} from '../../../shared/null-helpers';
import {
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  isValid,
  valueFrom,
  valueFromOrDash
} from '../../em-helpers';
import {BenchmarkOption} from '../../../shared/enums';

@Component({
  selector: 'app-em-outpatient-summary-table',
  templateUrl: './em-outpatient-summary-table.component.html',
  styleUrls: ['./em-outpatient-summary-table.component.scss']
})

export class EmOutpatientSummaryTableComponent implements OnInit, OnDestroy {

  displayedData: EvaluationManagementOutpatientSummary[];
  dataSource: MatTableDataSource<EvaluationManagementOutpatientSummary>;
  displayedColumns: string[];
  showProgressBar = true;
  isValidOrElse = isValidOrElse;
  benchmarkString = 'benchmarkPercentage';

  @Input() showDataTable: boolean;

  @select(['data', 'summaryEvaluationManagementData'])
  private readonly summaryEvaluationManagementData$: Observable<EvaluationManagementSummary>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly emBenchmarkOption$: Observable<BenchmarkOption>;

  emOutpatientDataSubscription: Subscription;
  private benchmarkDisplay: string;

  constructor() {
  }

  ngOnInit() {
    this.emOutpatientDataSubscription = combineLatest(
      [this.summaryEvaluationManagementData$, this.emBenchmarkOption$]).subscribe
    (([summaryEvaluationManagementData, benchmarkOption]:
        [EvaluationManagementSummary, BenchmarkOption]) => {
      this.showProgressBar = _.isEqual(summaryEvaluationManagementData, INITIAL_STATE.data.summaryEvaluationManagementData);
      this.benchmarkDisplay = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
      this.benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
      this.displayedData = this.outpatientSummaryFrom(summaryEvaluationManagementData.totals);
      this.dataSource = new MatTableDataSource<EvaluationManagementOutpatientSummary>(this.displayedData);
      this.displayedColumns = ['encounter'];

      if (this.displayedData[0].newPatients) {
        this.displayedColumns.push('countOf99201', 'countOf99202', 'countOf99203', 'countOf99204', 'countOf99205',
          'totalNewPatients');
      }

      if (this.displayedData[0].establishedPatients) {
        this.displayedColumns.push('countOf99211', 'countOf99212', 'countOf99213', 'countOf99214', 'countOf99215',
          'totalEstablishedPatients');
      }

      if (this.displayedData[0].consultation) {
        this.displayedColumns.push('countOf99241', 'countOf99242', 'countOf99243', 'countOf99244', 'countOf99245',
          'totalConsultations');
      }
    });
  }

  ngOnDestroy(): void {
    this.emOutpatientDataSubscription?.unsubscribe();
  }

  outpatientSummaryFrom(totalCptCounts: CptCategories): EvaluationManagementOutpatientSummary[] {
    let emOutpatientSummary: EvaluationManagementOutpatientSummary[];
    if (!isValid(totalCptCounts)) {
      emOutpatientSummary = [
        {
          encounterMetric: '# of Encounters',
          newPatients: emptyNewPatientVisit(),
          establishedPatients: emptyEstablishedPatientVisit(),
          consultation: emptyConsultation()
        },
        {
          encounterMetric: '% Coding Distribution',
          newPatients: emptyNewPatientVisit(),
          establishedPatients: emptyEstablishedPatientVisit(),
          consultation: emptyConsultation()
        }, {
          encounterMetric: `${this.benchmarkDisplay}`,
          newPatients: emptyNewPatientVisit(),
          establishedPatients: emptyEstablishedPatientVisit(),
          consultation: emptyConsultation()
        },
      ];
    } else {
      emOutpatientSummary = [
        {
          encounterMetric: '# of Encounters',
          newPatients: isValid(totalCptCounts.newPatientVisit) ?
            valueFrom(totalCptCounts.newPatientVisit, 'count') : emptyNewPatientVisit(),
          establishedPatients: isValid(totalCptCounts.establishedPatientVisit) ?
            valueFrom(totalCptCounts.establishedPatientVisit, 'count') : emptyEstablishedPatientVisit(),
          consultation: isValid(totalCptCounts.consultation) ?
            valueFrom(totalCptCounts.consultation, 'count') : emptyConsultation()
        },
        {
          encounterMetric: '% Coding Distribution',
          newPatients: isValid(totalCptCounts.newPatientVisit) ?
            valueFrom(totalCptCounts.newPatientVisit, 'percentage', '%', true) : emptyNewPatientVisit(),
          establishedPatients: isValid(totalCptCounts.establishedPatientVisit) ?
            valueFrom(totalCptCounts.establishedPatientVisit, 'percentage', '%', true) : emptyEstablishedPatientVisit(),
          consultation: isValid(totalCptCounts.consultation) ?
            valueFrom(totalCptCounts.consultation, 'percentage', '%', true) : emptyConsultation()
        },
        {
          encounterMetric: `${this.benchmarkDisplay}`,
          newPatients: isValid(totalCptCounts.newPatientVisit) ?
            valueFromOrDash(totalCptCounts.newPatientVisit, this.benchmarkString, '%', true) : emptyNewPatientVisit(),
          establishedPatients: isValid(totalCptCounts.establishedPatientVisit) ?
            valueFromOrDash(totalCptCounts.establishedPatientVisit, this.benchmarkString, '%', true) : emptyEstablishedPatientVisit(),
          consultation: isValid(totalCptCounts.consultation) ?
            valueFromOrDash(totalCptCounts.consultation, this.benchmarkString, '%', true) : emptyConsultation()
        },
      ];
    }
    return emOutpatientSummary;
  }
}

function emptyNewPatientVisit() {
  return {
    cpt99201: '-',
    cpt99202: '-',
    cpt99203: '-',
    cpt99204: '-',
    cpt99205: '-',
    total: '-',
  };
}

function emptyEstablishedPatientVisit() {
  return {
    cpt99211: '-',
    cpt99212: '-',
    cpt99213: '-',
    cpt99214: '-',
    cpt99215: '-',
    total: '-'
  };
}

function emptyConsultation() {
  return {
    cpt99241: '-',
    cpt99242: '-',
    cpt99243: '-',
    cpt99244: '-',
    cpt99245: '-',
    total: '-'
  };
}

export interface EvaluationManagementOutpatientSummary {
  encounterMetric: string;
  newPatients: any;
  establishedPatients: any;
  consultation: any;
}
