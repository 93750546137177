<div class="procedure-summary-data-table-wrapper">
  <div class="procedure-summary-data-table-container">
    <div id="procedure-summary-table-header">
      <div id="procedure-summary-title" class="left-table-header">{{tableTitle}}</div>
      <div class="right-table-header">
        <span *ngIf="cfFilterEnabled" id="cpt-filter-area">
          <app-button-cpt-groupings [currentSelectedGroup]="selectedGroup" [cptGroups]="cptGroups"
                                    (selectGroup)="selectGroup($event)"></app-button-cpt-groupings>
          <button mat-raised-button id="filter-button" (click)="openProcedureSummaryFilter()">
            <i class="fa fa-light fa-filter"></i>Filter</button>
        </span>
      </div>
    </div>
    <mat-table matSort [dataSource]="dataSource">
      <ng-container *ngFor="let column of columns; let columnIndex = index;">
        <ng-container *ngIf="columnIndex === 0" [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef class="provider-header align-left">{{column.header}}</mat-header-cell>
        </ng-container>
        <ng-container *ngIf="columnIndex > 0" [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="whenHeaderSortSelected()"
                           id="{{column.columnDef}}"
                           class="{{column.columnDef}}-header
                                {{column.group ? column.group + '-header' : ''}}
                                {{column.classNames || ''}}
                                {{column.primaryColumn ? 'primaryHeader' : ''}}"><br/>
            <span class="header-text">{{column.header}}</span>
          </mat-header-cell>
        </ng-container>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns" id="header-row" [ngClass]="headerStickyClass"></mat-header-row>
    </mat-table>
    <span *ngFor="let source of dataSources; let i = index">
      <mat-table class="sub-table" [dataSource]="source.dataSource"
               (scroll)="whenRowScrolled(source.original, i)">
      <ng-container *ngFor="let column of columns; let columnIndex = index;">
        <ng-container *ngIf="!columnIndex" [matColumnDef]="column.columnDef">
         <mat-cell *matCellDef="let row" class="provider-cell " [ngClass]="{departmentColumn: departmentColumnClass(column.dataName(row))}">
           {{column.dataName(row)}}
         </mat-cell>
        </ng-container>
        <ng-container *ngIf="columnIndex" [matColumnDef]="column.columnDef">
          <mat-cell *matCellDef="let row;" class="data-cell {{column.columnDef}} {{column.group || ''}} {{column.classNames || ''}}
            {{canDrill(column) ? 'drillable' : ''}}">
            <div *ngFor="let datum of row.data" class="data-row-cell" (click)="whenCptSelected(datum, column.columnDef)">
              <div *ngIf="column.upperDataName" class="upper-data-text">{{ column.upperDataName(datum) }}</div>
              <div #refEl class="data-text {{column.class ? column.class(datum) : ''}}"
                 matTooltipClass="cfp-multilevel-tooltip"
                 [matTooltipDisabled]="refEl.scrollWidth <= refEl.offsetWidth"
                 [matTooltip]="column.dataName(datum)">{{ column.dataName(datum) }}</div>
              </div>
            </mat-cell>
          </ng-container>
        </ng-container>
        <ng-container><mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </ng-container>
      </mat-table>
    </span>
    <mat-paginator [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   [showFirstLastButtons]="true"
                   (page)="updateDataTable($event)"></mat-paginator>
    <app-loader id="loader" *ngIf="showProgressBar"></app-loader>
  </div>
</div>
