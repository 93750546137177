<div class="no-access-page" *ngIf="!showPdfPage">
  <div class="no-access-page-body">
    <img src="assets/cloud.png"/>
    <p class="if-you-have-question">Looks like You are not authorized to see this page!</p>
  </div>
</div>
<div *ngIf="showPdfPage">
  <table id="the-table">
    <thead>
    <app-batch-export-pdf-header [batchExportDetails]="detailData"
                                 [headerClass]="'batch-export-em-header-wrapper'"
                                 [showPayer]="false">
    </app-batch-export-pdf-header>
    </thead>
    <tbody>
    <div id="batch-export-wrapper">
      <div id="em-wrapper" *ngIf="emMetrics.length >= 1">
        <app-evaluation-management-ml-tile-batch-export
          [level]='"by Provider"'
          [batchMultilevelEMData]="emData"
          [nodePath]="nodePath"
          [selectedDateRange]="selectedDateRange"
          [batchMultilevelEMSpecialtySummaryData]="emSpecialtyData"
          [nodeName]="splNodeName"
          [emMetrics]="emMetrics"
          [benchmarkOption]="benchmarkOption"
        ></app-evaluation-management-ml-tile-batch-export>
      </div>
    </div>
    </tbody>
    <tfoot>
    <tr>
      <td>
        <div class="footer-space">&nbsp;
          <app-footer [fromPdf]="true"></app-footer>
        </div>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
