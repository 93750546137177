<div class="data-table-container">
  <div id="em-snapshot-table-header">{{header}}</div>
  <mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef id="{{column.columnDef}}Header">
        <span *ngIf="columnsWithHeader > 1 && column.aboveHeaderText" class="range-header">{{column.aboveHeaderText}}</span>
        <span *ngIf="!column.dataNameList" class="header-text">
          {{column.header}}</span>
        <span *ngIf="column.dataNameList">
          <div class="list-item" *ngFor="let x of column.dataNameList">{{x}}</div>
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" id="{{column.columnDef}}" [ngClass]="column.isTotal ? 'total-cell' : ''">
        <div *ngFor="let item of column.dataName(row); let i = index"
             [ngClass]="i < column.dataName(row).length - (fromPdf ? 2 : 1) ? 'item-container' : 'last-item-container'">
          <span class="item-text">{{item}}</span></div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <ng-container *ngIf="dataRendered"><mat-row *matRowDef="let row; columns: displayedColumns"
      (click)="whenRowSelected(row)"></mat-row>
    </ng-container>
  </mat-table>
</div>
<mat-paginator #paginator [pageSizeOptions]="pageSizeOptions"
[pageSize]="pageSize"
[showFirstLastButtons]="true"
*ngIf="!fromPdf">
</mat-paginator>
