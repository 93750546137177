import {Component, Inject, OnInit} from '@angular/core';
import {overviewPageRoute} from '../shared/routes';
import {Router} from '@angular/router';
import {HttpLegalService, LegalServiceToken} from '../services/legal.service';

@Component({
  selector: 'app-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: ['./legal-disclaimer.component.scss']
})
export class LegalDisclaimerComponent implements OnInit {

  constructor(private router: Router,
              @Inject(LegalServiceToken) public legalService: HttpLegalService) { }

  public copyRightYear: number;
  ngOnInit() {
    this.copyRightYear = new Date().getFullYear() - 1;
    this.legalService.getLegal().subscribe((value: any) => {
    });
  }

  accept() {
    this.router.navigate([overviewPageRoute]);
  }

  decline() {
    document.location.href = 'https://vizientinc.com';
  }
}
