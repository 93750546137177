import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Benchmark} from '../../services/graph';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {IAppState} from '../../../store/IAppState';
import {NgRedux, select} from '@angular-redux/store';
import {ProductivityMultiLevelSnapshot, ProductivitySnapshot} from '../../services/ProviderProductivity';
import {deepCopyOf, Page, toTitleCase, WrvuViewType} from '../../../shared/helpers';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {Variable} from '../../../variable-container/variable-container.component';
import {Chart} from 'angular-highcharts';
import {Options} from 'highcharts/highstock';
import {updateCommunityBenchmarkAlertStatus} from '../../../shared/reducer-helper';
import {VarianceToggleActions, VarianceToggleField} from '../../../variance-toggler/variance-toggler.component';
import {AppAction, wrvuSnapshotSortingCriteriaChangedTo, wrvuVarianceToggleChangedTo} from '../../../store/actions';
import {checkForNulls, hasValue} from '../../../shared/null-helpers';
import {BenchmarkHelper, BenchmarkPercentile, readableNameOfColumnDef} from '../../../shared/benchmark-types';
import {ColumnType, HighChartColorIndex, SortingOrder} from '../../../shared/enums';
import {HasBenchmarks, Legend, Productivity, SelectionCounts, SortingCriterion} from '../../../shared/models';
import {AxisLabelsFormatterContextObject, PlotColumnOptions, PointClickEventObject, YAxisOptions} from 'highcharts';
import {IndividualSeriesOptions, OptionsUpdated} from '../../../shared/highcharts-mappings';
import {concat} from '../../../shared/ourLodash';
import {GraphColors} from '../../colors';
import {abbreviateAxisValue, roundToNumber} from '../../number-formatter';
import {CHART_HEIGHT, previousTimePeriodLegend} from '../../productivity-graph-component';
import {
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  maxOfSeries
} from '../../../shared/highcharts-helpers';
import {
  actualWrvuLegend,
  cfteWrvuLegend,
  chargesLegendForWrvus,
  doesWrvuDataHaveValidAcademicBenchmarkValue,
  doesWrvuDataHaveValidCommunityBenchmarkValue,
  specialtyPerformanceActualWrvuLegend,
  specialtyPerformanceCfteAdjWrvuLegend,
  varianceGraphLegendsForWrvus,
  wrvuBenchmarkLegend
} from './wrvu-multilevel-chart-helper';

@Component({
  selector: 'app-wrvu-multilevel-chart',
  templateUrl: './wrvu-multilevel-chart.component.html',
  styleUrls: ['./wrvu-multilevel-chart.component.scss']
})

export class WrvuMultilevelChartComponent implements OnInit, OnChanges, OnDestroy {
  private readonly CFTE_ADJ_STRING = 'cFTE Adj. wRVUs';
  private readonly ACTUAL_WRVU_STRING = 'Actual wRVUs';
  private readonly COLUMN_GRAPH_TYPE = 'column';
  private readonly MAX_NUMBER_OF_DATAPOINTS_FOR_SCROLLBAR = 20;
  private readonly SNAPSHOT_BAR_COUNT = 20;
  private readonly CHART_WIDTH = calculateChartWidth(DEFAULT_POINT_WIDTH, this.SNAPSHOT_BAR_COUNT, DEFAULT_POINT_PADDING);
  private readonly BAR_CHART_SERIES: PlotColumnOptions = {
    cropThreshold: 100,
    pointPadding: DEFAULT_POINT_PADDING,
    minPointLength: 2
  };

  @select(['display', 'charges'])
  private readonly showChargeGraph$: Observable<boolean>;
  private showChargeGraph: boolean;
  @select(['display', 'previousTimePeriod'])
  private readonly showPreviousTimePeriod$: Observable<boolean>;
  private showPreviousTimePeriod: boolean;

  @Input() columnSelectionCallback: (snapshot: ProductivityMultiLevelSnapshot) => void;
  @Input() showProgressBar = false;
  @Input() level: string;
  @Input() page: string;
  @Input() showVariableMenu: boolean;
  @Input() variables: Variable[];
  @Input() isSpecialty: boolean;
  @Input() isDepartment: boolean;
  @Input() isProvider: boolean;
  @Input() activeVarianceToggle = false;
  @Input() zeroSuppression: boolean;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() canShowBenchmarks = true;
  @Input() canShowCfteAdjWrvus = true;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() wRVUtype: WrvuViewType;
  @Input() varianceKey = '';
  @Input() sortingCriteria: SortingCriterion | undefined = undefined;
  @Input() selectionCounts: SelectionCounts;
  @Input() productivitySnapshotData: ProductivityMultiLevelSnapshot[] = [];
  @Input() isSpecialtyPerformance = false;

  chartObject: Chart;
  options: Options;

  viewingCfteAdjustedWrvu: boolean;
  wRVUTitle: string;
  graphData: ProductivityMultiLevelSnapshot[];
  hasCommunityBenchmarks: boolean;
  hasAcademicBenchmarks: boolean;
  hasBenchmarks = true;
  legends: Legend[];
  metricPage = Page.WrvuSnapshot;
  subscription: Subscription;
  varianceToggle: VarianceToggleActions = {
    display: false,
    reducerField: VarianceToggleField.WrvuVarianceToggle,
    sortingCriterion: {
      sortingOrder: SortingOrder.DESCENDING,
      columnDef: 'varianceMean',
      columnType: ColumnType.VARIANCE
    },
    dispatchAction(display: boolean): AppAction {
      return wrvuVarianceToggleChangedTo(display);
    },
    sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction {
      return wrvuSnapshotSortingCriteriaChangedTo(sortingCriteria);
    }
  };

  constructor(@Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
              private ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit(): void {
    this.subscription = combineLatest([this.showChargeGraph$, this.showPreviousTimePeriod$])
      .subscribe(([showChargeGraph, showPreviousTimePeriod]: [boolean, boolean]) => {
        this.showChargeGraph = showChargeGraph;
        this.showPreviousTimePeriod = showPreviousTimePeriod;
        this.ngOnChanges();
      });
  }

  ngOnChanges(): void {
    if (!this.productivitySnapshotData) {
      return;
    }
    this.viewingCfteAdjustedWrvu = this.canShowCfteAdjWrvus && this.wRVUtype === WrvuViewType.CfteAdjusted;
    this.wRVUTitle = this.viewingCfteAdjustedWrvu ? this.CFTE_ADJ_STRING : this.ACTUAL_WRVU_STRING;
    this.hasAcademicBenchmarks = doesWrvuDataHaveValidAcademicBenchmarkValue(this.productivitySnapshotData);
    this.hasCommunityBenchmarks = doesWrvuDataHaveValidCommunityBenchmarkValue(this.productivitySnapshotData);
    this.hasBenchmarks = this.viewCommunityBenchmarks ? this.hasCommunityBenchmarks : this.hasAcademicBenchmarks;
    updateCommunityBenchmarkAlertStatus(this.hasCommunityBenchmarks, this.hasAcademicBenchmarks, this.ngRedux, this.showProgressBar);

    this.setUpSortingCriteria();
    this.drawGraph();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private drawGraph(): void {
    this.graphData = this.getGraphData(this.productivitySnapshotData, this.zeroSuppression);
    if (this.isSpecialtyPerformance) {
      this.varianceKey = 'specialtyPerformanceCfteAdjWrvuspVariance';
    }
    this.options = this.activeVarianceToggle
      ? this.getOptionsforVarianceWrvuSnapshotGraph(
        this.graphData.filter(node => hasValue(node[this.varianceKey])),
        this.columnSelectionCallback, this.benchmarkPercentile, this.viewCommunityBenchmarks, this.MAX_NUMBER_OF_DATAPOINTS_FOR_SCROLLBAR)
      : this.getOptionsForSnapshotGraph(this.showChargeGraph, this.showPreviousTimePeriod, this.viewingCfteAdjustedWrvu,
        this.benchmarkPercentile, (data: ProductivityMultiLevelSnapshot) => toTitleCase(data.nodeName),
        this.graphData, this.columnSelectionCallback, this.MAX_NUMBER_OF_DATAPOINTS_FOR_SCROLLBAR,
        this.COLUMN_GRAPH_TYPE, this.viewCommunityBenchmarks);
    this.chartObject = new Chart(this.options);
  }

  private getGraphData(snapshots: ProductivityMultiLevelSnapshot[], suppressZeroes: boolean):
    ProductivityMultiLevelSnapshot[] {
    return suppressZeroes ? this.getZeroSuppressedProductivityMultiLevelSnapshots(snapshots) : snapshots;
  }

  private setUpSortingCriteria(): void {
    if (this.sortingCriteria) {
      this.varianceToggle.sortingCriterion = this.sortingCriteria;
    }
  }

  private getZeroSuppressedProductivityMultiLevelSnapshots(snapshots: ProductivityMultiLevelSnapshot[]): ProductivityMultiLevelSnapshot[] {
    return snapshots.filter(x => !((x.cfte === 0 || !x.cfte) && (x.wRVUs === 0 || !x.wRVUs)));
  }

  getOptionsForSnapshotGraph<T extends ProductivityMultiLevelSnapshot>(
    showChargeGraph: boolean,
    showPreviousTimePeriod: boolean,
    isCfteAdjustedWrvus: boolean,
    benchmarkPercentile: BenchmarkPercentile,
    makeGraphLabel: (value: ProductivityMultiLevelSnapshot) => string,
    productivities: ProductivityMultiLevelSnapshot[],
    onCategorySelected: (snapshot: ProductivityMultiLevelSnapshot) => void,
    maxNumberOfDataPointsForScrollBar: number,
    graphType: string,
    viewCommunityBenchmarks: boolean
  ): OptionsUpdated {
    this.setUpLegendsForStandardGraph();
    const categories: string[] = createEmptyLabels(
      productivities.map((productivity) => makeGraphLabel(productivity)),
      maxNumberOfDataPointsForScrollBar
    );
    const wrvuTitle: string = isCfteAdjustedWrvus ? this.CFTE_ADJ_STRING : this.ACTUAL_WRVU_STRING;
    const isScrolled: boolean = categories.length > maxNumberOfDataPointsForScrollBar;
    const wrvusData = this.wRVUsData(productivities, isCfteAdjustedWrvus, onCategorySelected, maxNumberOfDataPointsForScrollBar);
    const wrvusSpecialtyPerformanceData = this.isSpecialtyPerformance
      ? this.wRVUsSpecialtyPerformanceData(productivities, isCfteAdjustedWrvus, onCategorySelected, maxNumberOfDataPointsForScrollBar)
      : [];

    const leftSeries: IndividualSeriesOptions[] = isCfteAdjustedWrvus && this.canShowBenchmarks
      ? concat(
        wrvusData,
        wrvusSpecialtyPerformanceData,
        this.benchmarkData(
          this.getSnapshotBenchmarkFrom(benchmarkPercentile, productivities, makeGraphLabel, viewCommunityBenchmarks),
          categories,
          benchmarkPercentile,
          viewCommunityBenchmarks
        ),
        showPreviousTimePeriod ? this.previousTimePeriodData(productivities) : []
      )
      : concat(wrvusData, wrvusSpecialtyPerformanceData);

    const rightSeries: IndividualSeriesOptions[] = showChargeGraph ? this.snapshotChargeData(productivities) : [];

    return {
      legend: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 0
      },
      exporting: {
        enabled: false
      },
      chart: {
        type: graphType,
        backgroundColor: 'transparent',
        width: this.CHART_WIDTH,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: categories,
        // @ts-ignore
        clickOnCrosshair: function (e: any, p: any) {
          onCategorySelected(productivities[p.x]);
        },
        crosshair: {
          color: GraphColors.hoverBackground
        },
        gridLineWidth: 0,
        min: 0,
        max: isScrolled ? maxNumberOfDataPointsForScrollBar : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            let ret = '';
            const tokenized = this.value.toLocaleString().split(' ');
            for (let i = 0; i < tokenized.length; i++) {
              ret = ret.concat(tokenized[i] + ' ');
              if (i === roundToNumber(tokenized.length / 2, 0) - 1) {
                ret = ret.concat('<br/>');
              }
            }
            return ret;
          },
          rotation: 45
        }
      },
      yAxis: concat(
        this.axis(wrvuTitle, leftSeries),
        this.axis('Charges', rightSeries, true)
      ),
      plotOptions: {
        column: this.BAR_CHART_SERIES
      },
      scrollbar: {
        enabled: isScrolled,
        showFull: false
      },
      series: concat(leftSeries, rightSeries)
    };
  }

  getOptionsforVarianceWrvuSnapshotGraph(
    data: ProductivitySnapshot[],
    onCategorySelected: (snapshot: Productivity) => void,
    benchmarkPercentile: BenchmarkPercentile,
    viewCommunityBenchmarks: boolean,
    maxNumberScrollBars: number): OptionsUpdated {
    this.legends = deepCopyOf(varianceGraphLegendsForWrvus);
    const varianceData: any[] = [];
    let max = 0;
    let min = 0;
    if (this.isSpecialtyPerformance) {
      data.forEach(x => {
        const varianceValue = x.specialtyPerformanceCfteAdjWrvuspVariance
          || 0;
        if (varianceValue !== 0 && varianceValue > max) {
          max = varianceValue;
        } else if (varianceValue !== 0 && varianceValue < min) {
          min = varianceValue;
        }
        varianceData.push(this.getVarianceDatumProperties(x, varianceValue));
      });
    } else {
      data.forEach(x => {
        const varianceValue = this.getVarianceForVarianceGraph(x, benchmarkPercentile, viewCommunityBenchmarks)
          || 0;
        if (varianceValue !== 0 && varianceValue > max) {
          max = varianceValue;
        } else if (varianceValue !== 0 && varianceValue < min) {
          min = varianceValue;
        }
        varianceData.push(this.getVarianceDatumProperties(x, varianceValue));
      });
    }

    const categories = createEmptyLabels(
      data.map((x) => x.nodeName),
      maxNumberScrollBars
    );
    const absoluteMax = roundToNumber(Math.max(...[Math.abs(max), Math.abs(min)]), 0);

    const isScrolled: boolean = categories.length > maxNumberScrollBars;
    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: 'Variance'
        },
        max: absoluteMax,
        min: -1 * absoluteMax,
        tickInterval: absoluteMax / 10,
        labels: {
          formatter: function () {
            return roundToNumber(this.value, 0) + '';
          }
        },
        plotLines: [{
          color: '#000000',
          width: 200,
          value: 0,
          dashStyle: 'Solid',
          zIndex: 999
        }]
      }
    ];
    const varianceSeries: IndividualSeriesOptions[] = [
      {
        name: this.isSpecialtyPerformance ? 'Variance from Specialty Performance' :
          'Variance from benchmark ' + readableNameOfColumnDef(benchmarkPercentile).toLowerCase(),
        yAxis: 0,
        type: ChartType.COLUMN,
        data: varianceData.length < maxNumberScrollBars ?
          createEmptyValues(varianceData, maxNumberScrollBars) :
          varianceData,
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(data[event.point.x]);
          }
        },
        pointWidth: DEFAULT_POINT_WIDTH,
        stacking: 'normal',
        stack: 0,
        turboThreshold: 0,
        zIndex: 0
      }
    ];

    return {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: varianceSeries,
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 0
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: 1304.8,
        styledMode: true,
        height: CHART_HEIGHT
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: categories,
        max: isScrolled ? maxNumberScrollBars - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return toTitleCase(this.value.toString());
          },
          rotation: 45
        }
      },
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull: false
      },
      plotOptions: {
        column: {
          ...this.BAR_CHART_SERIES
        }
      }
    };
  }

  private getVarianceDatumProperties(productivitySnapshot: ProductivitySnapshot, varianceValue: number | string) {
    return {
      name: this.getTooltipName(productivitySnapshot),
      y: varianceValue,
      colorIndex: varianceValue >= 0 ? HighChartColorIndex.GREEN : HighChartColorIndex.RED,
      tooltip: varianceValue >= 0 ? {
        pointFormat: '<span class="highcharts-color-12">\u25CF</span> ' +
          '{series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      } : {
        pointFormat: '<span class="highcharts-color-22">\u25CF</span> ' +
          '{series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };
  }

  private wRVUsData<T extends Productivity>(
    productivities: T[],
    isCfteAdjustedWrvus: boolean,
    onCategorySelected: (snapshot: T) => void,
    maxNumberOfDataPointsForScrollBar: number
  ): any[] {
    const seriesData: ([string, number] | number)[] =
      isCfteAdjustedWrvus
        ? productivities.map((productivity) => [this.getTooltipName(productivity), productivity.cfteAdjustedWRVUs || 0])
        : productivities.map((productivity) => [this.getTooltipName(productivity), productivity.wRVUs || 0]);

    return [{
      name: isCfteAdjustedWrvus ? this.CFTE_ADJ_STRING : this.ACTUAL_WRVU_STRING,
      colorIndex: HighChartColorIndex.TEAL,
      yAxis: 0,
      data: seriesData.length < maxNumberOfDataPointsForScrollBar ?
        this.createEmptyValues(seriesData, maxNumberOfDataPointsForScrollBar) :
        seriesData,
      events: {
        click: (event: PointClickEventObject) => {
          onCategorySelected(productivities[event.point.x]);
        }
      },
      pointWidth: DEFAULT_POINT_WIDTH,
      tooltip: {
        pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      },
      turboThreshold: 0
    }];
  }

  private wRVUsSpecialtyPerformanceData<T extends Productivity>(
    productivities: T[],
    showCfteAdjustedWrvus: boolean,
    onCategorySelected: (snapshot: T) => void,
    maxNumberOfDataPointsForScrollBar: number
  ): any[] {
    const seriesData: ([string, number] | number)[] =
      showCfteAdjustedWrvus
        ? productivities.map((productivity) => [this.getTooltipName(productivity), productivity.specialtyPerformanceCfteAdjWrvus || 0])
        : productivities.map((productivity) => [this.getTooltipName(productivity), productivity.specialtyPerformanceActualWrvus || 0]);

    return [{
      name: showCfteAdjustedWrvus ? 'Specialty Performance cFTE Adj. wRVUs' : 'Specialty Performance Actual wRVUs',
      colorIndex: showCfteAdjustedWrvus ? HighChartColorIndex.BLUE : HighChartColorIndex.PURPLE,
      yAxis: 0,
      type: ChartType.LINE,
      data: seriesData.length < maxNumberOfDataPointsForScrollBar ?
        this.createEmptyValues(seriesData, maxNumberOfDataPointsForScrollBar) :
        seriesData,
      events: {
        click: (event: PointClickEventObject) => {
          onCategorySelected(productivities[event.point.x]);
        }
      },
      pointWidth: DEFAULT_POINT_WIDTH,
      tooltip: {
        pointFormat: showCfteAdjustedWrvus ? '<span class="highcharts-color-17">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>'
          : '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      },
      turboThreshold: 0
    }];
  }

  private getVarianceForVarianceGraph(ps: ProductivitySnapshot, benchmarkPercentile: BenchmarkPercentile,
                                      viewCommunityBenchmarks: boolean): number | undefined | null {
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Mean:
        return viewCommunityBenchmarks ? ps.communityVarianceMean : ps.varianceMean;
      case BenchmarkPercentile.Percentile25th:
        return viewCommunityBenchmarks ? ps.communityVariance25thPercentile : ps.variance25thPercentile;
      case BenchmarkPercentile.Percentile50th:
        return viewCommunityBenchmarks ? ps.communityVariance50thPercentile : ps.variance50thPercentile;
      case BenchmarkPercentile.Percentile65th:
        return viewCommunityBenchmarks ? ps.communityVariance65thPercentile : ps.variance65thPercentile;
      case BenchmarkPercentile.Percentile75th:
        return viewCommunityBenchmarks ? ps.communityVariance75thPercentile : ps.variance75thPercentile;
      case BenchmarkPercentile.Percentile90th:
        return viewCommunityBenchmarks ? ps.communityVariance90thPercentile : ps.variance90thPercentile;
      default:
        return undefined;
    }
  }

  private benchmarkData(
    benchmarkGraph: Benchmark,
    categories: string[],
    benchmarkPercentile: BenchmarkPercentile,
    viewCommunityBenchmarks: boolean
  ): any[] {
    const filteredBenchmarkGraph = benchmarkGraph.graph.entries.filter(entry => categories.indexOf(entry.label) > -1);
    // const sortedBenchmarkGraph = sortByCategory(filteredBenchmarkGraph, categories);
    // const benchmarkData = sortedBenchmarkGraph.map(entry => entry.value);
    const benchmarkData = filteredBenchmarkGraph.map(entry => entry.value);

    return [
      {
        name: BenchmarkHelper.toWrvusLabel(benchmarkPercentile, viewCommunityBenchmarks) || '',
        colorIndex: HighChartColorIndex.GREY,
        type: ChartType.LINE,
        yAxis: 0,
        data: benchmarkData,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      }
    ];
  }

  private getSnapshotBenchmarkFrom<T extends HasBenchmarks>(
    benchmarkPercentile: BenchmarkPercentile,
    benchmarks: T[],
    makeLabelFrom: (productivity: T) => string,
    viewCommunityBenchmarks: boolean
  ): Benchmark {
    return {
      title: BenchmarkHelper.toWrvusLabel(benchmarkPercentile),
      graph: {
        entries: benchmarks.map(productivity => {
          let benchMarkValue = 0;
          switch (benchmarkPercentile) {
            case BenchmarkPercentile.Mean:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmarkMean
                : productivity.benchmarkMean;
              break;
            case BenchmarkPercentile.Percentile25th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark25thPercentile
                : productivity.benchmark25thPercentile;
              break;
            case BenchmarkPercentile.Percentile50th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark50thPercentile
                : productivity.benchmark50thPercentile;
              break;
            case BenchmarkPercentile.Percentile65th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark65thPercentile
                : productivity.benchmark65thPercentile;
              break;
            case BenchmarkPercentile.Percentile75th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark75thPercentile
                : productivity.benchmark75thPercentile;
              break;
            case BenchmarkPercentile.Percentile90th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark90thPercentile
                : productivity.benchmark90thPercentile;
              break;
          }
          return {
            label: makeLabelFrom(productivity),
            value: checkForNulls(benchMarkValue)
          };
        })
      }
    };
  }

  private snapshotChargeData(productivities: ProductivitySnapshot[]): any[] {
    return [
      {
        name: 'Charges($)',
        colorIndex: HighChartColorIndex.ORANGE,
        type: ChartType.LINE,
        yAxis: 1,
        data: productivities.map(productivity => productivity.charges ? productivity.charges : 0),
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-15">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      }
    ];
  }

  private previousTimePeriodData(productivities: ProductivitySnapshot[]): any[] {
    return [
      {
        name: 'Previous Date Range cFTE adj. wRVUs',
        colorIndex: HighChartColorIndex.PURPLE,
        type: ChartType.LINE,
        yAxis: 0,
        marker: {
          symbol: ChartMarkerSymbol.CIRCLE
        },
        data: productivities.map(productivity => productivity.previousCfteAdjustedWRVUs ? productivity.previousCfteAdjustedWRVUs : 0),
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      }
    ];
  }

  private createEmptyValues(seriesData: any[], maxNumberOfDataPointsForScrollBar: number) {
    for (let i = seriesData.length; i < maxNumberOfDataPointsForScrollBar; i++) {
      seriesData.push(['', null]);
    }
    return seriesData;
  }

  private getTooltipName(productivity: any) {
    return (productivity.providerNodeName ? productivity.providerNodeName + '<br/>' : '')
      + (productivity.specialtyNodeName ? productivity.specialtyNodeName + '<br/>' : '')
      + (productivity.departmentNodeName || productivity.nodeName);
  }

  private axis(title: string, series: any[], opposite: boolean = false): YAxisOptions[] {
    if (series.length > 0) {
      return [
        {
          title: {
            text: title
          },
          opposite: opposite,
          min: 0,
          max: maxOfSeries(series),
          labels: {
            formatter: function (this: AxisLabelsFormatterContextObject<any>) {
              return abbreviateAxisValue(this.value);

            }
          }
        }
      ];
    }
    return [];
  }

  private setUpLegendsForStandardGraph(): void {
    this.legends = this.viewingCfteAdjustedWrvu ? [cfteWrvuLegend] : [actualWrvuLegend];

    if (this.isSpecialtyPerformance) {
      this.addSpecialtyPerformanceLegend();
    }
    if (this.viewingCfteAdjustedWrvu && this.canShowBenchmarks) {
      this.addLegend(wrvuBenchmarkLegend);
    }
    if (this.showChargeGraph) {
      this.addLegend(chargesLegendForWrvus);
    }
    if (this.showPreviousTimePeriod) {
      this.addLegend(previousTimePeriodLegend(this.ngRedux.getState().filters));
    }
  }

  private addSpecialtyPerformanceLegend(): void {
    const specialtyPerformanceLegend = this.viewingCfteAdjustedWrvu
      ? specialtyPerformanceCfteAdjWrvuLegend : specialtyPerformanceActualWrvuLegend;
    this.addLegend(specialtyPerformanceLegend);
  }

  private addLegend(newLegend: Legend): void {
    this.legends.push(newLegend);
  }
}
