<mat-dialog-content>
  <button mat-button
          id="close-button"
          (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div id="report-dialog-header-wrapper">
    <span id="report-dialog-header">Multi-Provider Exports</span>
    <span id="report-subheader">Exports will only be saved in the archive for 6 months</span>
  </div>
  <mat-tab-group mat-stretch-tabs="false"
                 mat-align-tabs="start"
                 id="one-time-scheduled-wrapper"
                 [animationDuration]="'0'"
                 (selectedTabChange)="tabChanged($event)">
    <mat-tab id="one-time-tab"
             [label]="oneTimeExportsLabel">
      <ng-template matTabLabel>
        <span id="unread-one-time-count"
              class="{{generalTabClass}}"
              matBadge="{{unreadOneTimeReportsCount}}"
              matBadgeOverlap="false"
              matBadgeColor="accent"
              matBadgeSize="small"
              [matBadgeHidden]="unreadOneTimeReportsCount < 1">{{oneTimeExportsLabel}}</span>
      </ng-template>
      <div class="tab-content">
        <app-redesigned-one-time-exports-table [dataSource]="dataSource"
                                    [columns]="columnsForOneTimeTab"
                                    [stringColumnsForRowDef]="stringColumnsForRowDefForOneTimeTab"
                                    (downloadRecord)="getReportFileAndUpdateReadStatus($event, false)"
        ></app-redesigned-one-time-exports-table>
      </div>
    </mat-tab>
    <mat-tab id="automated-tab"
             [label]="scheduledExportsLabel">
      <ng-template matTabLabel>
        <span id="unread-scheduled-count"
              class="{{generalTabClass}}"
              matBadge="{{unreadScheduledReportsCount > 0 ? unreadScheduledReportsCount : undefined}}"
              matBadgeOverlap="false"
              matBadgeColor="accent"
              matBadgeSize="small"
              [matBadgeHidden]="unreadScheduledReportsCount < 1">{{scheduledExportsLabel}}</span>
      </ng-template>
      <div class="tab-content">
        <app-scheduled-exports-table [existingReportsResponse]="scheduledTabDataSource"
                                     [parentColumns]="parentColumnsForScheduledTable"
                                     [childColumns]="childColumnsForScheduledTable"
                                     [stringColumnsForParentRowDef]="stringColumnsForParentRowDefForScheduledTab"
                                     [stringColumnsForChildRowDef]="stringColumnsForChildRowDefForScheduledTab"
                                     (downloadRecord)="getReportFileAndUpdateReadStatus($event, true)"
        ></app-scheduled-exports-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
