import {Legend} from '../../../../shared/models';
import {LegendColor, LegendStyle} from '../../../../shared/enums';
import {MetricType} from '../../../../shared/metric-types';

export const countLegendsForNpvTrend: Legend[] = [
  {
    text: '# New Patients',
    color: LegendColor.TEAL,
    metric: MetricType.NewPatientVisits,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false,
  },
  {
    text: '# Total Patients',
    color: LegendColor.PURPLE,
    metric: MetricType.NewPatientVisits,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false,
  },
];


export function getPercentNpvLegendForTrend(): Legend {
  return {
    text: '% New Patients',
    color: LegendColor.GREEN,
    metric: MetricType.NewPatientVisits,
    style: LegendStyle.CIRCLE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  };
}

export function getPreviousDateRangeLegendForNpvTrend(dateRangeText: string): Legend {
  return {
    text: 'Previous Year Date Range % New Patients\n' + dateRangeText,
    color: LegendColor.PURPLE,
    metric: MetricType.NewPatientVisits,
    style: LegendStyle.CIRCLE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false,
  };
}
