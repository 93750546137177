<mat-dialog-content>
  <div id="message-header">Your export is on the way!</div>
  <div id="message-body">
  <span class="message-body-item">Your Export will generate {{numberOfFiles}} files.
    You will receive an email when your export is ready for download. Depending on the size of your export,
    please allow 24 hours for processing. Most exports will complete within 2 hours. </span>
  </div>
  <div id="buttons-wrapper">
    <button (click)="confirm()" id="btn-ok">Ok</button>
  </div>
</mat-dialog-content>
