<span id="cpt-button-wrapper">
  <span *ngIf="cptGroups === undefined" id="groups-loading">Loading your groups</span>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" (click)="whenOptionsOpened()">
    <mat-select [value]="currentSelectedGroup" id="my-groupings" hideSingleSelectionIndicator
              [placeholder]="currentSelectedGroup && currentSelectedGroup.name && currentSelectedGroup.name.length ?
               currentSelectedGroup.name : 'My Groupings'" [matTooltip]="!currentSelectedGroup ?
                'Select a previously saved grouping.' : currentSelectedGroup.name"
              [matTooltipPosition]="'right'"
              [matTooltipDisabled]="currentSelectedGroup && currentSelectedGroup.name?.length < maxCharsForGroupInDropdown"
              (selectionChange)="onGroupSelected($event.value)">
      <mat-select-trigger>{{currentSelectedGroup?.name}}</mat-select-trigger>
      <mat-option id="{{cptGroups?.length > 0 ? 'manage-groupings' : 'no-cpt-selected'}}" class="custom-group-item" [disabled]="true" (click)="manageGroupings()"
        [style]="'top: ' + stickyOptionScroll + 'px'"><span class="manage-groupings">Manage Groupings</span>
      </mat-option>
      <mat-option *ngFor="let group of cptGroups" class="custom-group-item"
                [value]="group" [matTooltip]="group.name"
                [matTooltipDisabled]="group.name.length < maxCharsForGroupInDropdown">
        <span id="{{group.name}}" class="group-name">{{group.name}}</span>
        <span *ngIf="group.isDefault" class="only-default-group">Default Grouping</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</span>
