import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CustomGroupsDataAllFilters, DateRange} from '../shared/models';
import {customGroupsDataChangedTo, filterCriteriaChangedTo} from '../store/actions';
import {filterCriteriaFromSelectedCustomGroup} from '../updated-filter-banner/UpdatedFilterBannerHelpers';
import {CustomGroupsService, CustomGroupsServiceToken} from '../filter-banner/services/custom-groups-service';
import {NgRedux} from '@angular-redux/store';
import {FilterCriteria, IAppState} from '../store/IAppState';
import {
  UpdatedManageGroupsComponent
} from '../updated-filter-banner/updated-manage-groups/updated-manage-groups.component';
import {
  ChangeableCustomGroup,
  convertFilterGroupsToChangeableGroups,
  GroupDialogEvent,
  hasManageableFilterChanges,
  ManageableGroup,
  ManageGroupingsInfo
} from '../updated-filter-banner/manage-groups-helper';
import {getOntologyData} from '../shared/localStoragehelper';
import {DateRangeOption, GoogleAnalyticCategories, PayerType} from '../shared/enums';
import {getDateRangeBySelectedOption} from '../shared/helpers';
import {
  DEFAULT_CUSTOM_GROUP_ID,
  DEFAULT_INVOICE_STATUS,
  DEFAULT_LAG_KEY,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_MEMBER_LOCATION_KEYS,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_TELEHEALTH_FLAG
} from '../store/DefaultValues';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';

@Component({
  selector: 'app-custom-group-select',
  templateUrl: './custom-group-select.component.html',
  styleUrls: ['./custom-group-select.component.scss']
})
export class CustomGroupSelectComponent implements OnChanges, OnDestroy {
  readonly maxCharsForGroupInDropdown = 30;
  @Input() customGroups: CustomGroupsDataAllFilters[] = [];
  @Output() selectionChange = new EventEmitter<CustomGroupsDataAllFilters>();
  @Input() currentSelectedGroup: CustomGroupsDataAllFilters | undefined;
  deletedGroup: ManageableGroup;
  groupsAfterSave: ChangeableCustomGroup[];
  private manageGroupsRef: MatDialogRef<any>;
  dialogActionListener: BehaviorSubject<GroupDialogEvent>;
  private dialogActionSubscription: Subscription;
  private originalDefault: CustomGroupsDataAllFilters | undefined;
  private readonly analyticsLabel = 'Groupings';

  constructor(public dialog: MatDialog, private readonly ngRedux: NgRedux<IAppState>,
              @Inject(CustomGroupsServiceToken) private customGroupService: CustomGroupsService,
              @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService) {
  }

  ngOnChanges(): void {
    this.originalDefault = this.customGroups?.find(g => g.isDefault);
    this.dialogActionListener = new BehaviorSubject<GroupDialogEvent>(GroupDialogEvent.INITIAL);
    this.listenForDialogActions();
  }

  ngOnDestroy(): void {
    this.dialogActionSubscription?.unsubscribe();
  }

  private getDialogActionListener(): Observable<GroupDialogEvent> {
    return this.dialogActionListener.asObservable();
  }

  private listenForDialogActions(): void {
    this.dialogActionSubscription = this.getDialogActionListener().subscribe((value: GroupDialogEvent) => {
      switch (value) {
        case GroupDialogEvent.DELETE:
          this.confirmDeletionForManageGroupings();
          break;
        case GroupDialogEvent.MANAGE_SAVE:
          this.manageGroupsSave();
          break;
        case GroupDialogEvent.DELETE_CURRENTLY_SELECTED:
          this.handleDeletionOfCurrentSelection();
      }
    });
  }

  manageGroupings(): void {
    const data: ManageGroupingsInfo = {
      changeableCustomGroups: convertFilterGroupsToChangeableGroups(this.customGroups || []),
      hasManageableChanges: hasManageableFilterChanges,
      component: this,
      preloadable: true,
      dialogActionListener: this.dialogActionListener
    };
    this.manageGroupsRef = this.dialog.open(UpdatedManageGroupsComponent, {
      data: data
    });
  }

  private revertToDefaultFilter(memberKey: number): void {
    const defaultFilter: FilterCriteria = {
      dateRange: this.findDateRangeForReversion(memberKey),
      memberKey: memberKey,
      nodePath: getOntologyData().ontologyHierarchy[0].nodePath,
      memberLocation: DEFAULT_MEMBER_LOCATION,
      payerCategory: DEFAULT_PAYER_CATEGORY,
      payerKey: DEFAULT_PAYER_CATEGORY.payerCategoryKey,
      payerType: PayerType.ALL,
      lagKey: DEFAULT_LAG_KEY,
      memberBillingArea: DEFAULT_MEMBER_BILLING_AREA,
      invoiceStatus: DEFAULT_INVOICE_STATUS,
      telehealthFlag: DEFAULT_TELEHEALTH_FLAG.key,
      customGroupId: DEFAULT_CUSTOM_GROUP_ID,
      memberLocationKeys: DEFAULT_MEMBER_LOCATION_KEYS
    };
    this.ngRedux.dispatch(filterCriteriaChangedTo(defaultFilter));
  }

  private findDateRangeForReversion(memberKey: number): DateRange {
    const { userPreferenceData, userMemberData } = this.ngRedux.getState().data;
    const existingUserMemberData = userMemberData.find(d => d.memberKey === memberKey);
    const existingPreferences = userPreferenceData.find(upd => upd.memberKey === memberKey);
    const defaultDateOption: DateRangeOption = existingPreferences?.dateRangeOption || DateRangeOption.Past12MonthsOfData;
    return getDateRangeBySelectedOption(defaultDateOption,
      existingUserMemberData?.recentMonth || -1, existingPreferences?.fiscalStartMonth);
  }

  private confirmDeletionForManageGroupings(): void {
    if (this.deletedGroup) {
      this.customGroupService.deleteCustomGroup(this.deletedGroup.id, this.deletedGroup.memberKey)
        .subscribe(() => {
          this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS, this.analyticsLabel,
            'Custom Groupings Deletion');
          this.customGroupService.getCustomGroups(this.deletedGroup.memberKey)
            .subscribe((groups: CustomGroupsDataAllFilters[]) => {
              const groupsAfterDeletion: ChangeableCustomGroup[] = convertFilterGroupsToChangeableGroups(groups);
              this.sendUpdatedGroupsToApi(groupsAfterDeletion);
              this.ngRedux.dispatch(customGroupsDataChangedTo(groups));
            });
        });
    }
  }

  private manageGroupsSave(): void {
    if (!this.groupsAfterSave.find(g => g.newDefault)) {
      this.revertToDefaultFilter(this.ngRedux.getState().filters.memberKey);
    }
    const changedGroups = this.groupsAfterSave.filter(group =>
      group.newName !== group.original.name ||
      group.newDefault !== group.original.isDefault || group.newPreload !== (group.original as CustomGroupsDataAllFilters).preload);
    let updatingCount = 0;
    changedGroups.forEach(changedGroup => {
      if (changedGroup.newDefault !== changedGroup.original.isDefault) {
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
          this.analyticsLabel, 'Set Default Custom Grouping');
      } else if (changedGroup.newPreload !== (changedGroup.original as CustomGroupsDataAllFilters).preload) {
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
          this.analyticsLabel, 'Pre-calculated Groups');
      }
      this.customGroupService.updateCustomGroup({
        ...changedGroup.original,
        name: changedGroup.newName,
        isDefault: changedGroup.newDefault,
        preload: changedGroup.newPreload
      } as CustomGroupsDataAllFilters, changedGroup.original.memberKey, false).subscribe(() => {
        if (updatingCount++ === changedGroups.length - 1) {
          this.customGroupService.getCustomGroups(this.ngRedux.getState().filters.memberKey)
            .subscribe((groups: CustomGroupsDataAllFilters[]) => {
              this.ngRedux.dispatch(customGroupsDataChangedTo(groups));
              const theDefault = groups.find(g => g.isDefault);
              if (theDefault && theDefault.id !== this.originalDefault?.id) {
                this.ngRedux.dispatch(filterCriteriaChangedTo({...filterCriteriaFromSelectedCustomGroup(theDefault,
                    this.ngRedux.getState()), customGroupId: theDefault.id}));
              }
            });
        }
      });
    });
  }

  private sendUpdatedGroupsToApi(groupsAfterDeletion: ChangeableCustomGroup[]) {
    groupsAfterDeletion.forEach(g => {
      g.original.isDefault = g.newDefault;
      (g.original as CustomGroupsDataAllFilters).preload = g.newPreload;
      this.customGroupService.updateCustomGroup(g.original as CustomGroupsDataAllFilters, g.original.memberKey,
        false).subscribe();
    });
  }

  private handleDeletionOfCurrentSelection(): void {
    const theDefault = this.customGroups.find(g => g.isDefault);
    if (theDefault && this.deletedGroup && theDefault.id === this.deletedGroup.id) {
      this.revertToDefaultFilter(this.ngRedux.getState().filters.memberKey);
    } else if (theDefault) {
      this.ngRedux.dispatch(filterCriteriaChangedTo(filterCriteriaFromSelectedCustomGroup(theDefault,
        this.ngRedux.getState())));
    }
  }

  selectGroup(group: CustomGroupsDataAllFilters) {
    if (group) {
      this.selectionChange.emit(group);
      this.currentSelectedGroup = group;
    }
  }
}
