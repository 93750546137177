export enum AppConfigEntries {
  CLINICAL_ACTIVITY_WEBSITE = 'clinicalActivityWebsite',
  LOGOUT_WEBSITE = 'logoutWebsite',
  CPSC_WEBSITE = 'cpscWebsite',
  VIZIENT_WEBSITE = 'vizientWebsite',
  OPPDASHBOARD_WEBSITE = 'opportunityDashboardWebsite',
  AAMC_WEBSITE = 'aamcWebsite',
  METHODOLOGY_DOCUMENT = 'methodologyDocument',
  SUPPORT_EMAIL = 'supportEmail',
  ACCESS_AND_THROUGHPUT = 'accessAndThroughputWebsite',
  GLOSSARY = 'glossary',
  OPEN_QUICK_START_GUIDE = 'openQuickStartGuide',
  MAX_PROVIDERS = 'maxProviders',
  MAX_PROVIDER_PATHS = 'maxProviderPaths',
  MAX_SPECIALTIES = 'maxSpecialties',
  MAX_SPECIALTY_PATHS = 'maxSpecialtyPaths',
  NEW_FEATURES = 'newFeatures',
  APP_REPORT = 'appReportWebsite',
  ATT_REPORT = 'attReportWebsite',
  PMT_PER_RVU_REPORT = 'paymentPerRVUReportWebsite',
  RECOVERY_DASHBOARD = 'recoveryDashboard',
  POLLING_INTERVAL = 'pollingInterval',
  NOTIFICATION_MODAL_URL = 'notificationModalUrl',
  NOTIFICATION_HISTORY_URL = 'notificationHistoryUrl',
  NOTIFICATION_ALERT_URL = 'notificationAlertUrl',
  NOTIFICATION_ALERT_COUNT_URL = 'notificationAlertCountUrl',
  MAX_PRELOADED_GROUPS = 'maxPreloadedGroups',
  LOCATIONS_TO_SHOW_IN_EXPORT = 'locationsToShowInExport',
  ARCHIVE_YEAR_OFFSET = 'archiveYearOffset',
  ENCOUNTER_REPORTS = 'encounterReports',
  THIRD_PARTY_DATA_USE = 'thirdPartyDataUse',
  PROVIDER_LIMIT_IN_MPE = 'providerLimitInMpe'
}
