import {Component, Input, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState, VarianceToggle} from '../store/IAppState';
import {Observable} from 'rxjs';
import {AppAction} from '../store/actions';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {SortingCriterion} from '../shared/models';

@Component({
  selector: 'app-variance-toggler',
  templateUrl: './variance-toggler.component.html',
  styleUrls: ['./variance-toggler.component.scss']
})
export class VarianceTogglerComponent implements OnInit {

  @select(['display', 'varianceToggle'])
  private readonly varianceToggle$: Observable<VarianceToggle>;

  @Input() varianceToggleAction: VarianceToggleActions | undefined;

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit() {
    this.varianceToggle$.pipe(distinctUntilChanged((varianceToggle1, varianceToggle2) =>
      _.isEqual(varianceToggle1, varianceToggle2)))
      .subscribe((varianceToggle: VarianceToggle) => {
        if (this.varianceToggleAction) {
          const toggleDisplayValue = varianceToggle[this.varianceToggleAction.reducerField];
          if (toggleDisplayValue !== null) {
            this.varianceToggleAction.display = toggleDisplayValue;
          }
        }
      });
  }

  toggleVariance(varianceToggle: VarianceToggleActions) {
    this.ngRedux.dispatch(varianceToggle.dispatchAction(!varianceToggle.display));
    if (varianceToggle.sortingCriterion) {
      this.ngRedux.dispatch(varianceToggle.sortingCriteriaAction(varianceToggle.sortingCriterion));
    }
  }
}

export interface VarianceToggleActions {
  display: boolean;
  sortingCriterion?: SortingCriterion;
  reducerField: VarianceToggleField;
  dispatchAction(display: boolean): AppAction;
  sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction;
}

export enum VarianceToggleField {
  NpvVarianceToggle = 'npvVariance',
  ClinicalFingerprintVarianceToggle = 'cfpVariance',
  WrvuVarianceToggle = 'wrvuVariance',
  CollectionsSnapshotVarianceToggle = 'collectionSnapshotVariance',
  DenialsVarianceToggle = 'denialsVariance',
}
