import {Component, Input, OnInit} from '@angular/core';
import {MultilevelExportOption, MultilevelPdfExportMetrics} from '../export-dialog-helper';

@Component({
  selector: 'app-select-pdf-export',
  templateUrl: './select-pdf-export.component.html',
  styleUrls: ['./select-pdf-export.component.scss']
})
export class SelectPdfExportComponent implements OnInit {

  @Input() afterOptionsSelected: (options: MultilevelExportOption[]) => void;
  readonly multilevelPdfExportMetrics = MultilevelPdfExportMetrics();
  displayedMetrics: MultilevelExportOption[];

  constructor() { }

  ngOnInit(): void {
    this.displayedMetrics = [];
    this.setDisplayedExportMetrics(this.multilevelPdfExportMetrics);
  }

  private setDisplayedExportMetrics(options: MultilevelExportOption[]): void {
    options.filter(o => o.displayed).forEach(option => {
      this.displayedMetrics.push(option);
      this.setDisplayedExportMetrics(option.children);
    });
  }

  whenOptionSelected(option: MultilevelExportOption, event: MouseEvent): void {
    event.preventDefault();
    option.selected = option.mandatory || !option.selected;
    this.afterOptionsSelected(this.multilevelPdfExportMetrics);
  }
}
