<!--suppress HtmlUnknownTarget -->
<header [ngClass]="isExport? 'export-wrapper':'header-wrapper'">
  <div *ngIf="communityBenchmarkAlert && !isExport" class="community-benchmark-alert-wrapper">
    <span class="community-benchmark-alert">No Community Benchmarks available for selected time period
    </span>
    <i id="community-exit" class="fa fa-times cross-button" (click)="closeBenchmarkAlert()"></i>
  </div>
  <div *ngIf="lagKeyAlert && !isExport && isCollections" class="lag-key-alert-wrapper">
    <span class="lag-key-alert">The NCR benchmarks represent a calendar year.
      To assess your performance to these benchmarks, we recommend you look at invoices that have aged at least 6 months.
    </span>
    <i id="lag-key-exit" class="fa fa-times cross-button" (click)="closeLagKeyAlert()"></i>
  </div>
  <div id="top-header">
    <div class="left-side">
      <img *ngIf="!isExport" alt="CPSC logo" src="assets/CPSC_logo.svg" (click)="openCpscWebsite()" class="cpsc-logo"/>
      <img *ngIf="isExport" alt="CPSC logo" src="assets/CPSC_logo.png" (click)="openCpscWebsite()" class="cpsc-logo"/>
    </div>

    <div class="right-side">
      <div #alert class="notification-wrapper" *ngIf="!isExport"
           (click)="openAlertDialog()">
        <mat-icon [matBadge]="alertCount$ | async" matBadgePosition="above after"
                  [matBadgeHidden]="!(alertCount$ | async)" class="notification-badge" matBadgeColor="accent"
                  matBadgeSize="small">notifications
        </mat-icon>
      </div>
      <div *ngIf="!isExport"
           class="help-icon-wrapper"
           [class.help-selected]="showHelp"
           (mouseenter)="toggleHelpDropdown()"
           (mouseleave)="closeHelpDropdown()"
           tabindex="0">
        <img alt="help icon" src="assets/help-icon.svg" id="help-icon"/>
        <span *ngIf="showHelp" class="other-dropdown-content">
          <mat-option *ngFor="let item of helpItems" id="{{item.id}}"
                      (click)="onHelpOptionClick(item.appConfigName, item.eventName)">
            <span *ngIf="!item.styledText">{{item.displayText}}</span>
            <app-styled-text-container *ngIf="item.styledText" [styledText]="item.styledText">
            </app-styled-text-container>
          </mat-option>
        </span>
      </div>

      <div *ngIf="!isExport"
           (mouseenter)="toggleAppWaffleDropdown()"
           (mouseleave)="closeAppWaffleDropdown()"
           id="app-waffle-wrapper"
           [class.waffle-selected]="isWaffleOpen"
           tabindex="1">
        <i class="fa fa-th waffle"></i>
        <span *ngIf="isWaffleOpen && subproducts && subproducts.length" class="other-dropdown-content" id="app-waffle-dropdown">
          <mat-option *ngFor="let s of subproducts" class="subproduct-option"
                      (click)="openUrlInNewTab(s.access_url)">
            {{s.subproduct_name}}
          </mat-option>
        </span>
      </div>

      <div id="person-icon-wrapper" *ngIf="!isExport"
           (mouseenter)="togglePersonDropdown()"
           (mouseleave)="closePersonDropDown($event)">
        <i id="person-icon" class="fa fa-user" tabindex="2"></i>
        <div *ngIf="showPersonDropdown" class="other-dropdown-content">
          <div id="show-name" *ngIf="userProfile">
            <div class="user-name" id="logged-in-as">Logged in as:</div>
            <div>{{userProfile.userName}}</div>
          </div>
          <mat-option id="settings" (mousedown)="clickSettings()">Settings</mat-option>
          <mat-option *ngIf="showControlPanelLink" id="feature-config-link" tabindex='3'
                      (mousedown)="openFeatureConfigurationLink()">Control Panel
          </mat-option>
          <mat-option id="logout" tabindex='0' (mousedown)="logout()">Log Out</mat-option>
        </div>
      </div>
      <div *ngIf="!isExport"
           id="members"
           class="user-controls"
           tabindex="1"
           (mouseenter)="toggleOrganizationDropdown()"
           (mouseleave)="closeOrganizationDropDown($event)">
        <div class="member-name" *ngIf="selectedMemberData" disabled>
          <div id="member-name-text">{{selectedMemberData.memberDesc}}</div>
          <i *ngIf="!shouldHideCaretDropdown && !isExport" class="dropdown-arrow fa fa-caret-down"></i>
        </div>

        <div *ngIf="showOrganizations" class="other-dropdown-content">
          <div class="organizations" (blur)="closeOrganizationDropDown($event)">
            <span class="member-condition" *ngIf="members.members.length > 1">
            <mat-option class="members" *ngFor="let member of members.members"
                        (mousedown)="updateMember(member)">{{member.memberDesc}}</mat-option>
            </span>
          </div>
        </div>
      </div>
      <span *ngIf="isExport && selectedMemberData"
            class="export-member-name"
            disabled>{{selectedMemberData.memberDesc}}</span>
      <span *ngIf="!isExport" class="viz-aamc-logo-wrapper">
          <img alt="vizient/AAMC logo" src="assets/vzt_aamc_logo_2color_rgb_pos.svg"
               class="vizient-aamc-logo" usemap="#vizient-aamc"/>
          <map name="vizient-aamc">
            <area coords="0,0,75,34" alt="aamc" class="aamc-logo" (click)="openAAMCWebsite()">
            <area coords="76,0,140,34" alt="vizient" class="vizient-logo" (click)="openVizientWebsite()">
          </map>
      </span>
      <span *ngIf="isExport" class="viz-aamc-logo-wrapper">
          <img alt="vizient/AAMC logo" src="assets/vzt_aamc_logo_2color_rgb_pos.png"
               class="vizient-aamc-logo" usemap="#vizient-aamc"/>
          <map name="vizient-aamc">
            <area coords="0,0,75,34" alt="aamc" class="aamc-logo">
            <area coords="76,0,140,34" alt="vizient" class="vizient-logo">
          </map>
      </span>
    </div>
  </div>
  <div [hidden]="!notificationsShown">
    <iframe id="notificationsIFrame" [src]="notificationModalUrl | safe"
            style="position: fixed; top: 0; left: 0; height: 100%; width: 100%; border: none; z-index: 999"
    ></iframe>
  </div>
</header>
