<div [ngClass]="editMode ? 'filter-banner-wrapper-active' : 'filter-banner-wrapper-inactive'">
  <div id="filter-banner-inner-wrapper">
    <span id="selection-tab-wrapper">
      <div id="selection-tab-header">
        <span>
          <div id="metric-label">{{pageLabel}}</div>
          <span id="provider-and-grouping-wrapper">
            <div id="node-path-loading" *ngIf="!currentNodePathLabel">Loading</div>
            <div id="breadcrumb-wrapper">
              <i *ngIf="isDrillDown"
                 class="fa fa-arrow-left {{editMode? 'back-button-disabled':'back-button'}}"
                 id="back-button" alt="Back Symbol" (click)="navigateBack()"></i>
              <div id="current-node-label" *ngIf="currentNodePathLabel" [matTooltip]="currentNodePathLabel"
                   [matTooltipDisabled]="currentNodePathLabel.length < maxCharsForNodePath"
                   (click)="toggleHierarchy(!showHierarchySearch)">{{currentNodePathLabel}}
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <app-custom-group-select *ngIf="allFiltersEnabled"
                                     [customGroups]="customGroups"
                                     [currentSelectedGroup]="selectedCustomGroup"
                                     (selectionChange)="selectExistingCustomGroup($event, true)">
            </app-custom-group-select>
          </span>
        </span>
        <span id="buttons-wrapper" *ngIf="editMode">
          <button *ngIf="allFiltersEnabled" class="cancel-button" id="btn-clear-selections"
                  (click)="onClearAllSelections()">Clear All Selections</button>
          <button *ngIf="allFiltersEnabled"
                  [ngClass]="customGroupDefault ? 'cancel-button' : 'cancel-button cancel-button-non-selectable'"
                  (click)="onRestoreDefault()" id="btn-restore-default">Restore Default</button>
          <button *ngIf="allFiltersEnabled" (click)="saveGroup()"
                  id="btn-save-grouping">Save Grouping and Filters</button>
          <button class="cancel-button" id="btn-cancel-selections" (click)="cancel($event)">Cancel</button>
          <button [ngClass]="unsavedData ? 'apply-button' : 'apply-button disabled'" (click)="apply()"
                  [disabled]="!unsavedData">Apply</button>
        </span>
        <div *ngIf="!editMode" id="export-buttons">
          <app-export-button (afterPdfExport)="exportToPdf($event)"></app-export-button>
        </div>
      </div>
      <div id="selection-tab-inner-wrapper">
        <span class="filter-tab" id="time-selection" [ngClass]="getCssClassForTab(0)" (click)="setTab(0)"
              (mouseenter)="activateHoverMessage(0)" (mouseleave)="deactivateHoverMessage()">
          <div [ngClass]="getCssClassForTabTitle(0)">
            <div class="hover-message" *ngIf="hoverIndex === 0">
              <span>This filter is not applicable on this Metric</span><i class="fa fa-caret-down hover-caret"></i>
            </div><span id="time-frame" [ngClass]="getCssClassForFilterLabel(0)">Time Frame</span></div>
          <div id="current-date-range"
               [ngClass]="getCssClassForSelectedCriteria(0)">{{selectedDateRange | dateRangeText}}</div>
        </span>
        <span class="filter-tab" id="multi-location-selection"
              [ngClass]="getCssClassForTab(7)" (click)="setTab(7)" (mouseenter)="activateHoverMessage(7)"
              (mouseleave)="deactivateHoverMessage()">
          <div [ngClass]="getCssClassForTabTitle(7)">
            <div class="hover-message" *ngIf="hoverIndex === 7">
              <span>This filter is not applicable on this Metric</span><i class="fa fa-caret-down hover-caret"></i>
            </div><span [ngClass]="getCssClassForFilterLabel(7)">Locations</span></div>
          <div id="current-multiple-locations"
               [ngClass]="getCssClassForSelectedCriteria(7)">{{multiSelectedMemberLocations.length === 0
            || multiSelectedMemberLocations.length === memberLocationsForTable.length
            ? "All" : multiSelectedMemberLocations.length}}
            {{multiSelectedMemberLocations.length === 1 ? " Location" : " Locations"}}
          </div>
        </span>
        <span class="filter-tab" id="payer-selection" [ngClass]="getCssClassForTab(2)" (click)="setTab(2)"
              (mouseenter)="activateHoverMessage(2)" (mouseleave)="deactivateHoverMessage()">
          <div [ngClass]="getCssClassForTabTitle(2)">
            <div class="hover-message" *ngIf="hoverIndex === 2">
              <span>This filter is not applicable on this Metric</span><i class="fa fa-caret-down hover-caret"></i>
            </div><span [ngClass]="getCssClassForFilterLabel(2)">Payers</span></div>
          <div id="current-payer" [ngClass]="getCssClassForSelectedCriteria(2)">{{selectedPayerMessage}}</div>
        </span>
        <span class="filter-tab" id="billingArea-selection" [ngClass]="getCssClassForTab(3)" (click)="setTab(3)"
              (mouseenter)="activateHoverMessage(3)" (mouseleave)="deactivateHoverMessage()">
          <div [ngClass]="getCssClassForTabTitle(3)">
            <div class="hover-message" *ngIf="hoverIndex === 3">
              <span>This filter is not applicable on this Metric</span><i class="fa fa-caret-down hover-caret"></i>
            </div><span [ngClass]="getCssClassForFilterLabel(3)">Billing Area</span></div>
          <div id="current-billing-area"
               [ngClass]="getCssClassForSelectedCriteria(3)">{{selectedMemberBillingArea && selectedMemberBillingArea.memberBillingAreaDescription.length > 0 ? selectedMemberBillingArea.memberBillingAreaDescription : 'All Billing Areas'}}</div>
        </span>
        <span class="filter-tab" id="lag-selection" [ngClass]="getCssClassForTab(4)" (click)="setTab(4)"
              (mouseenter)="activateHoverMessage(4)" (mouseleave)="deactivateHoverMessage()">
          <div [ngClass]="getCssClassForTabTitle(4)">
            <div class="hover-message" *ngIf="hoverIndex === 4">
              <span>This filter is not applicable on this Metric</span><i class="fa fa-caret-down hover-caret"></i>
            </div><span [ngClass]="getCssClassForFilterLabel(4)">Lag Period</span></div>
          <div id="current-lag" [ngClass]="getCssClassForSelectedCriteria(4)">{{selectedLagPeriod.name}}</div>
        </span>
        <span class="filter-tab" id="invoiceStatus-selection" [ngClass]="getCssClassForTab(5)" (click)="setTab(5)"
              (mouseenter)="activateHoverMessage(5)" (mouseleave)="deactivateHoverMessage()">
          <div [ngClass]="getCssClassForTabTitle(5)">
            <div class="hover-message" *ngIf="hoverIndex === 5">
              <span>This filter is not applicable on this Metric</span><i class="fa fa-caret-down hover-caret"></i>
            </div><span [ngClass]="getCssClassForFilterLabel(5)">Invoice Status</span></div>
          <div id="current-invoiceStatus"
               [ngClass]="getCssClassForSelectedCriteria(5)">{{selectedInvoiceStatus?.statusText}}</div>
        </span>
        <span id="visitType-selection" [ngClass]="getCssClassForTab(6)" (click)="setTab(6)"
              (mouseenter)="activateHoverMessage(6)" (mouseleave)="deactivateHoverMessage()">
          <div [ngClass]="getCssClassForTabTitle(6)">
            <div class="hover-message" *ngIf="hoverIndex === 6">
              <span>This filter is not applicable on this Metric</span><i class="fa fa-caret-down hover-caret"></i>
            </div><span [ngClass]="getCssClassForFilterLabel(6)">Visit Type</span></div>
          <div id="current-visitType" [ngClass]="getCssClassForSelectedCriteria(6)">{{selectedVisitType.text}}</div>
        </span>
      </div>
    </span>
  </div>
</div>

<div id="edit-window" *ngIf="editMode">
  <div id="edit-wrapper">
    <div id="select-date-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 0 &&
     !showHierarchySearch">
      <app-viz-date-range-picker (dateChange)="onDateChange($event)"
                                 [rangeMode]="true" typeMode="month"
                                 [date]="selectedDateForRangeCalendar" [filterBannerFlag]="true">
      </app-viz-date-range-picker>
    </div>

    <div id="select-nodepath-wrapper" *ngIf="showHierarchySearch">
      <app-advanced-navigation (nodePathEmit)="updateSelectedHierarchicalPath($event)"
                               [selectedGroup]="selectedCustomGroup"
                               [selectedHierarchicalNodes]="selectedHierarchicalNodes">
      </app-advanced-navigation>
    </div>

    <div id="select-location-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 1
      && !showHierarchySearch">
      <app-select-location [memberLocations]="memberLocations"
                           [memberLocationsForTable]="memberLocationsForTable"
                           [selectedMemberLocation]="selectedMemberLocation"
                           (memberLocationEmit)="updateSelectedMemberLocation($event)"
      ></app-select-location>
    </div>

    <div id="select-payer-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 2
     && (payerTabPermitted || editMode) && !showHierarchySearch">
      <app-search-payer
        [filterCriteria]="filterCriteria"
        [shouldPayersBeGranular]="shouldPayersBeGranular"
        [payerSelection]="selectedPayer"
        (payerSelectionChanged)="payerSelectionChanged($event)"
        (payerHierarchyLoaded)="payerHierarchyLoaded($event)"
      ></app-search-payer>
    </div>

    <div id="select-billingArea-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 3
     && (billingAreaTabPermitted || editMode) && !showHierarchySearch">
      <app-select-billing-area
        (billingAreaKeyEmit)="updateSelectedMemberBillingArea($event)"
        (billingAreasLoaded)="billingAreasLoaded($event)"
        [selectedMemberBillingArea]="selectedMemberBillingArea"
      >
      </app-select-billing-area>
    </div>

    <div id="select-lag-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 4
     && (lagKeyTabPermitted || editMode)">
      <app-select-lag-key (lagKeyEmit)="updateSelectedLagPeriodFromKey($event)"
                          [lagPeriod]="selectedLagPeriod"></app-select-lag-key>
    </div>

    <div id="select-invoiceStatus-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 5
     && (invoiceStatusTabPermitted || editMode) && !showHierarchySearch">
      <app-select-invoice-status (invoiceStatusEmit)="updateSelectedInvoiceStatus($event)"
                                 [selectedInvoiceStatus]="selectedInvoiceStatus.statusId"></app-select-invoice-status>
    </div>

    <div id="visitType-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 6
     && (visitTypeTabPermitted || editMode) && !showHierarchySearch">
      <app-select-visit-type (visitTypeEmit)="updateVisitType($event)"
                             [selectedVisitType]="selectedVisitType"></app-select-visit-type>
    </div>
    <div id="multi-location-wrapper" *ngIf="availableTabs[currentTabIndex] && availableTabs[currentTabIndex].tab === 7
     && editMode && !showHierarchySearch">
      <app-search-multiple-locations [memberLocations]="memberLocations"
                                     (locationsEmit)="updatedMultiSelectedMemberLocation($event, true)"></app-search-multiple-locations>
    </div>
  </div>
</div>
<div id="pdf-content">
  <app-export-pdf-dialog #pdfContent></app-export-pdf-dialog>
</div>
