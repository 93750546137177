<span id="benchmark-option-dropdown-wrapper" class="{{originClass}}">
  <mat-select *ngIf="!fromPdf"
              class="benchmarkOption {{benchmarkClass}}"
              id="benchmark-option-dropdown"
              [placeholder]="benchmarkPlaceholder"
              [(value)]="selectedBenchmarkOption"
              (selectionChange)="updateBenchmarkOption($event.value)"
              hideSingleSelectionIndicator>
    <mat-option *ngFor="let benchmarkOption of benchmarkOptions"
                [disabled]="before2020 &&
                  benchmarkOption.value !== BenchmarkOption.Academic &&
                  benchmarkOption.value !== BenchmarkOption.Community"
                [value]="benchmarkOption.value">
      {{benchmarkOption.name}}
    </mat-option>
  </mat-select>
  <span *ngIf="fromPdf" class="benchmarkOption {{benchmarkClass}}" id="benchmark-plain-text">{{selectedBenchmarkOptionValue}}</span>
</span>
