<span id="benchmark-percentile-option-dropdown-wrapper"
      class="{{originClass}}">
  <mat-select *ngIf="!fromPdf"
              class="benchmark-type"
              placeholder="Benchmark Scale"
              id="benchmark-scale-dropdown"
              [(value)]="benchmarkPercentile"
              (selectionChange)="updateBenchmarkPercentile($event.value)"
              hideSingleSelectionIndicator>
    <mat-option *ngFor="let benchmark of benchmarkPercentiles" [value]="benchmark.value">{{benchmark.name}}</mat-option>
  </mat-select>
  <span *ngIf="fromPdf" id="benchmark-plain-text">{{benchmarkLabel}}</span>
</span>
