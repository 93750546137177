<div id="visit-list-wrapper">
  <span id="header">Visit Type</span>
  <div class="note">Visit Type is only applicable to the New Patient Visits and Evaluation and Management metrics.
    <br>Visit Type data is available for date ranges after May 2020</div>
  <mat-radio-group id="visit-list">
    <mat-radio-button [checked]="allSelected" [class.active]="allSelected" [value]="DEFAULT_TELEHEALTH_FLAG.text"
                      (click)="selectAllActivity($event)" class="all-option">{{DEFAULT_TELEHEALTH_FLAG.text}}
    </mat-radio-button>
    <mat-radio-button *ngFor="let visit of visitTypes" [checked]="!allSelected && visit.item.selected"
                      [class.active]="!allSelected && visit.item.selected" [value]="visit.item.displayText"
                      (click)="whenVisitTypeClicked(visit, $event)" class="option">{{visit.item.displayText}}
    </mat-radio-button>
  </mat-radio-group>
</div>
