<mat-table matSort [dataSource]="dataSource" id="header-location">
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
    <mat-header-cell *matHeaderCellDef mat-sort-header id="{{column.columnDef}}Header"
                     class="{{column.columnType !== ColumnType.NODE_NAME && column.columnType !== ColumnType.LOCATION
                      ? 'numeric' : 'node'}} {{column.group ? column.group : ''}}" (click)="onHeaderSortSelected(column)">
      <span class="header-text">
        <span class="header-primary-text">{{column.header}}</span>
        <span *ngIf="column.columnType === ColumnType.PREVIOUS" class="previous-date-text">{{previousDateText}}</span>
      </span>
    </mat-header-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
</mat-table>
