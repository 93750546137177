import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IAppState, INITIAL_STATE} from '../../store/IAppState';
import {CfpVariableViewType} from '../../shared/helpers';
import {NgRedux} from '@angular-redux/store';
import {cfpVariableViewTypeChangedTo} from '../../store/actions';

@Component({
  selector: 'app-cfpt-toggle-variables',
  templateUrl: './cfp-toggle-variables.component.html',
  styleUrls: ['./cfp-toggle-variables.component.scss']
})
export class CfpToggleVariablesComponent implements OnChanges {

  @Input() cfpVariableViewType: CfpVariableViewType;
  @Input() hideCfteWrvuButton = false;
  @Input() hideCfteCountButton = false;

  showCFTEAdjwRVU = INITIAL_STATE.display.cfpVariableViewType === CfpVariableViewType.CfteAdjustedwRVU;
  showActualCount = INITIAL_STATE.display.cfpVariableViewType === CfpVariableViewType.ActualCount;
  showAdjCount = INITIAL_STATE.display.cfpVariableViewType === CfpVariableViewType.CfteAdjustedCount;
  showActualwRVU = INITIAL_STATE.display.cfpVariableViewType === CfpVariableViewType.ActualwRVU;

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  ngOnChanges(changes?: SimpleChanges) {
    switch (this.cfpVariableViewType) {
      case CfpVariableViewType.CfteAdjustedwRVU:
        this.showCFTEAdjwRVU = !this.hideCfteWrvuButton;
        this.showActualCount = false;
        this.showAdjCount = false;
        this.showActualwRVU = this.hideCfteWrvuButton;
        break;

      case CfpVariableViewType.ActualCount:
        this.showCFTEAdjwRVU = false;
        this.showActualCount = true;
        this.showAdjCount = false;
        this.showActualwRVU = false;
        break;

      case CfpVariableViewType.CfteAdjustedCount:
        this.showCFTEAdjwRVU = false;
        this.showActualCount = this.hideCfteCountButton;
        this.showAdjCount = !this.hideCfteCountButton;
        this.showActualwRVU = false;
        break;

      case CfpVariableViewType.ActualwRVU:
        this.showCFTEAdjwRVU = false;
        this.showActualCount = false;
        this.showAdjCount = false;
        this.showActualwRVU = true;
        break;
    }
  }

  showActualWrvuGraph() {
    this.ngRedux.dispatch(cfpVariableViewTypeChangedTo(CfpVariableViewType.ActualwRVU));
  }

  showCfteAdjustedWrvuGraph() {
    this.ngRedux.dispatch(cfpVariableViewTypeChangedTo(CfpVariableViewType.CfteAdjustedwRVU));
  }

  showCfteAdjustedCountGraph() {
    this.ngRedux.dispatch(cfpVariableViewTypeChangedTo(CfpVariableViewType.CfteAdjustedCount));
  }

  showActualCountGraph() {
    this.ngRedux.dispatch(cfpVariableViewTypeChangedTo(CfpVariableViewType.ActualCount));
  }
}
