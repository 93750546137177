import {Component, Input} from '@angular/core';
import {SelectableCpt} from '../../../../em-helpers';

@Component({
  selector: 'app-em-trend-cpt-code-select',
  templateUrl: './em-trend-cpt-code-select.component.html',
  styleUrls: ['./em-trend-cpt-code-select.component.scss']
})
export class EmTrendCptCodeSelectComponent {
  @Input() selectedCpt: SelectableCpt;
  @Input() cptCodeSelections: SelectableCpt[];
  @Input() onCptSelected: (cpt: SelectableCpt) => void;
  showCptDropdown = false;

  constructor() { }
}
