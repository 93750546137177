<div class="inpatient-summary-table" *ngIf="displayedData">
  <div class="table-header-wrapper">
    <div class="summaryHeader bold">Inpatient E&M Summary</div>
  </div>
  <div class = "header-name">
        <span class = "inpatient-header1">
          Initial Hospital Care 99221 - 99223
        </span>
  <span class = border1>
  </span>
    <span class = "inpatient-header2">
          Subsequent Hospital Care 99231 - 99233
        </span >
    <span class = border2>
  </span>
    <span class = "inpatient-header3">
          Hospital Discharge Day 99238 - 99239
        </span>
  </div>

  <mat-table [dataSource]="dataSource">
    <ng-container  matColumnDef="encounter">
      <mat-header-cell *matHeaderCellDef class="headerEncounter">Metrics</mat-header-cell>
      <mat-cell *matCellDef="let row" class="encounter">{{row.encounterMetric}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99221">
      <mat-header-cell *matHeaderCellDef class="header99221">99221</mat-header-cell>
      <mat-cell *matCellDef="let row"  class="countOf99221">{{isValidOrElse(row, 'initialHospitalCare', 'cpt99221', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99222">
      <mat-header-cell *matHeaderCellDef class="header99222">99222</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99222">{{isValidOrElse(row, 'initialHospitalCare', 'cpt99222', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99223">
      <mat-header-cell *matHeaderCellDef class="header99223">99223</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99223">{{isValidOrElse(row, 'initialHospitalCare', 'cpt99223', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalInitialHospitalCare">
      <mat-header-cell *matHeaderCellDef class="headerTotalInitialHospitalCare">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalInitialHospitalCare">{{isValidOrElse(row, 'initialHospitalCare', 'total', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container  matColumnDef="countOf99231">
      <mat-header-cell *matHeaderCellDef class="header99231">99231</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99231">{{isValidOrElse(row, 'subsequentHospitalCare', 'cpt99231', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container  matColumnDef="countOf99232">
      <mat-header-cell *matHeaderCellDef class="header99232">99232</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99232">{{isValidOrElse(row, 'subsequentHospitalCare', 'cpt99232', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99233">
      <mat-header-cell *matHeaderCellDef class="header99233">99233</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99233">{{isValidOrElse(row, 'subsequentHospitalCare', 'cpt99233', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalSubsequentHospitalCare">
      <mat-header-cell *matHeaderCellDef class="headerTotalSubsequentHospitalCare">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalSubsequentHospitalCare">{{isValidOrElse(row, 'subsequentHospitalCare', 'total', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99238">
      <mat-header-cell *matHeaderCellDef class="header99238">99238</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99238">{{isValidOrElse(row, 'hospitalDischargeDay', 'cpt99238', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99239">
      <mat-header-cell *matHeaderCellDef class="header99239">99239</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99239">{{isValidOrElse(row, 'hospitalDischargeDay', 'cpt99239', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalHospitalDischarge">
      <mat-header-cell *matHeaderCellDef class="headerTotalHospitalDischarge">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalHospitalDischarge">{{isValidOrElse(row, 'hospitalDischargeDay', 'total', '-')}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row  *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>

</div>

