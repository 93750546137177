import {assertAllCasesCovered, MonthNumber} from '../shared/helpers';

export function toMonthName(monthNumber: string): string {
  switch (monthNumber) {
    case '1':
      return 'January';
    case '2':
      return 'February';
    case '3':
      return 'March';
    case '4':
      return 'April';
    case '5':
      return 'May';
    case '6':
      return 'June';
    case '7':
      return 'July';
    case '8':
      return 'August';
    case '9':
      return 'September';
    case '10':
      return 'October';
    case '11':
      return 'November';
    case '12':
      return 'December';
    default:
      return assertAllCasesCovered(monthNumber);
  }
}

export function toAbbreviatedMonthName(monthNumber: string): string {
  return toMonthName(monthNumber).slice(0, 3);
}

export function abbreviatedDateLabel(month: MonthNumber, year: number): string {
  return toAbbreviatedMonthName(month.toString()) + ' ' + year.toString().slice(2);
}
