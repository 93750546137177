<div id="billingArea-component-wrapper">
  <div id="select-billingArea-header">
    <div id="upper-header">Billing Area</div>
    <div class="note">Note: Billing area is only applicable to <b>Collections</b> and <b>Denials</b>.</div>
  </div>
  <div id="select-billingArea-body">

    <div id="input-billingArea-search-wrapper">
    <input type="search" placeholder="Search..." id="search-for-billingArea"
           (input)="onBillingAreaTextChanged($event)" [ngModel]="billingAreaText"
    >
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>

    <div id="billingArea-options">
      <mat-radio-group>
        <mat-radio-button class="all-billingAreas-option"
                          (click)="selectDefaultMemberBillingArea($event)"
                          [value]="DEFAULT_MEMBER_BILLING_AREA"
                          [checked]="selectedMemberBillingArea.memberBillingAreaKey === DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaKey"
                          [class.active]="selectedMemberBillingArea === DEFAULT_MEMBER_BILLING_AREA"
                          id="Select-all-billing-areas" [ngClass]="'allActivity-option'">Select All Billing Areas
        </mat-radio-button>
        <span id="scrollable-options"><mat-radio-button class="billingArea-option"
                               *ngFor="let billingArea of shownMemberBillingAreas"
                               (click)="selectMemberBillingArea(billingArea, $event)"
                               [value]="billingArea.memberBillingArea"
                               [checked]="billingArea.memberBillingArea === selectedMemberBillingArea"
                               [class.active]="billingArea.memberBillingArea === selectedMemberBillingArea"
                               [ngClass]="'allActivity-option'" id="{{billingArea.memberBillingArea.memberBillingAreaDescription}}">
          {{billingArea.memberBillingArea.memberBillingAreaDescription}}
        </mat-radio-button></span>

      </mat-radio-group>
    </div>
  </div>
</div>
