import {Component, Input, OnChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ProviderMultilevelCptCounts} from '../../../../shared/models';
import {DataTableColumns} from '../../../../shared/data-table-columns';
import {ColumnType} from '../../../../shared/enums';

@Component({
  selector: 'app-em-trend-table',
  templateUrl: './em-trend-table.component.html',
  styleUrls: ['./em-trend-table.component.scss']
})
export class EmTrendTableComponent implements OnChanges {
  @Input() receivedData: ProviderMultilevelCptCounts[];
  @Input() columns: DataTableColumns[];
  @Input() header: string;
  @Input() showLoadingIndicator: boolean;
  @Input() tableCssClass: string;
  @Input() rangeIndex = -1;
  columnsWithHeader: DataTableColumns[];
  dataSource: MatTableDataSource<ProviderMultilevelCptCounts>;
  displayedColumns: string[] = [];
  shouldRender = true;
  ColumnType = ColumnType;

  constructor() { }

  ngOnChanges() {
    this.columnsWithHeader = this.columns?.filter(c => c.aboveHeaderText);
    this.setTable();
  }

  private setTable(): void {
    this.dataSource = new MatTableDataSource<any>((this.receivedData || []).slice());
    this.displayedColumns = this.columns.map(x => x.columnDef);
  }
}
