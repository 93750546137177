import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {getNodeDataFromHierarchy, NodeData} from './ontology-data-setup-helper';
import {
  CustomGroupsDataAllFilters,
  HierarchicalSelectableNode,
  OntologyNode,
  SelectableNode,
  SelectedHierarchicalNodes,
  SortingCriterion
} from '../../shared/models';
import {IAppState} from '../../store/IAppState';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {getAppConfigValue} from '../../shared/helpers';
import {FilterCriteriaTableView, OntologyLevel} from '../../shared/enums';
import {MatDialog} from '@angular/material/dialog';
import {distinctUntilChanged} from 'rxjs/operators';
import {getOntologyData} from '../../shared/localStoragehelper';
import {getSelectedNodePath} from '../filter-banner-helper';
import {AnalyticsService, AnalyticsServiceToken} from '../../analytics/analytics.service';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';

@Component({
  selector: 'app-advanced-navigation',
  templateUrl: './advanced-navigation.component.html',
  styleUrls: ['./advanced-navigation.component.scss']
})
export class AdvancedNavigationComponent implements OnInit, OnDestroy {
  readonly MIN_SEARCH_LENGTH = 3;

  providerColumnsSelected: any[] = [
    {
      columnDef: 'providers', header: 'Providers',
      dataName: (row: HierarchicalSelectableNode) => `${row.selectableNode.node.nodeName}`,
      selected: () => true,
      displayCheckbox: true,
      allowSort: true
    },
    {
      columnDef: 'specialties', header: 'Specialty',
      dataName: (row: HierarchicalSelectableNode) => `${this.specialtyStringList(row)}`,
      selected: () => true,
      allowSort: true
    },
    {
      columnDef: 'departments', header: 'Department',
      dataName: (row: HierarchicalSelectableNode) => `${this.departmentStringList(row)}`,
      selected: () => true,
      allowSort: true
    }
  ];
  providerColumnsUnselected: any[] = [
    {
      columnDef: 'providers', header: 'Providers',
      dataName: (row: HierarchicalSelectableNode) => `${row.selectableNode.node.nodeName}`,
      selected: () => false,
      displayCheckbox: true,
      allowSort: true
    },
    {
      columnDef: 'specialties', header: 'Specialty',
      dataName: (row: HierarchicalSelectableNode) => `${this.specialtyStringList(row)}`,
      selected: () => false,
      allowSort: true
    },
    {
      columnDef: 'departments', header: 'Department',
      dataName: (row: HierarchicalSelectableNode) => `${this.departmentStringList(row)}`,
      selected: () => false,
      allowSort: true
    }
  ];
  initialOntology: OntologyNode[];
  nodePath: string;
  @Output() nodePathEmit = new EventEmitter<SelectedHierarchicalNodes>();
  @Input() selectedHierarchicalNodes: SelectedHierarchicalNodes | undefined = undefined;
  @Input() selectedGroup: CustomGroupsDataAllFilters | undefined;

  @select(['ontologyLoaded'])
  private readonly ontologyLoaded$: Observable<boolean>;
  @select(['display', 'showHierarchySearch'])
  private readonly showHierarchySearch$: Observable<boolean>;
  @select(['data', 'customGroupsData'])
  private readonly customGroupsData$: Observable<CustomGroupsDataAllFilters[]>;
  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  subscription: Subscription;
  customGroupSubscription: Subscription;

  // Departments
  listOfAllDepartments: HierarchicalSelectableNode[] = [];
  unSelectedDepartments: HierarchicalSelectableNode[] = [];
  selectedDepartments: HierarchicalSelectableNode[] = [];
  tempUnselectedDepartments: HierarchicalSelectableNode[] = [];
  tempSelectedDepartments: HierarchicalSelectableNode[] = [];
  excludedTempUnselectedDepartments: HierarchicalSelectableNode[] = []; // Excluded due to search filter
  excludedTempSelectedDepartments: HierarchicalSelectableNode[] = []; // Excluded due to search filter
  selectedDepartmentLabel = 'All Departments';
  additionalDepartmentsSelectedLabel: string | undefined;
  showDepartments = false;
  departmentSearchText: string;
  allDepartmentsNodePath = '';
  // Specialties
  listOfAllSpecialties: HierarchicalSelectableNode[] = [];
  unSelectedFilteredSpecialties: HierarchicalSelectableNode[] = [];
  selectedFilteredSpecialties: HierarchicalSelectableNode[] = [];
  tempUnselectedFilteredSpecialties: HierarchicalSelectableNode[] = [];
  tempSelectedFilteredSpecialties: HierarchicalSelectableNode[] = [];
  excludedTempUnselectedSpecialties: HierarchicalSelectableNode[] = []; // Excluded due to search filter
  excludedTempSelectedSpecialties: HierarchicalSelectableNode[] = []; // Excluded due to search filter
  selectedSpecialtyLabel = 'All Specialties';
  additionalSpecialtiesSelectedLabel: string | undefined;
  showSpecialties = false;
  specialtySearchText = '';
  // Providers
  listOfAllProviders: HierarchicalSelectableNode[] = [];
  unselectedFilteredProviders: HierarchicalSelectableNode[] = [];
  selectedFilteredProviders: HierarchicalSelectableNode[] = [];
  excludedUnselectedProviders: HierarchicalSelectableNode[] = [];
  providerSearchText = '';
  emptinessIndicationMessageLeft: string | undefined = undefined;
  emptinessIndicationMessageRight: string | undefined = undefined;
  selectionLimit = 0;
  showLimitMessage = false;
  // Custom groups
  customGroups: CustomGroupsDataAllFilters[] = [];
  selectedProvidersAtTimeOfGroupSelection: HierarchicalSelectableNode[] = [];
  showEditIndicator = false;
  customGroupDefault?: CustomGroupsDataAllFilters;
  private askParentToNotCloseEditPopup = false;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              public dialog: MatDialog,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.selectedProvidersAtTimeOfGroupSelection = this.selectedHierarchicalNodes?.selectedProvidersAtTimeOfGroupSelection ?
      this.selectedHierarchicalNodes.selectedProvidersAtTimeOfGroupSelection : [];
    this.subscription = combineLatest([this.ontologyLoaded$, this.showHierarchySearch$, this.nodePath$])
      .subscribe(([ontologyLoaded, showHierarchySearch, nodePath]: [boolean, boolean, string]) => {
        if (ontologyLoaded) {
          const ontologyHierarchy = getOntologyData().ontologyHierarchy;
          if (showHierarchySearch && ontologyHierarchy.length > 0) {
            if (!_.isEqual(ontologyHierarchy, this.initialOntology) || !_.isEqual(nodePath, this.nodePath)) {
              this.initialOntology = ontologyHierarchy;
              this.nodePath = nodePath;
              this.setUp(ontologyHierarchy, nodePath);
              this.selectionLimit = Number(getAppConfigValue(AppConfigEntries.MAX_PROVIDERS,
                this.ngRedux.getState().data.applicationConfigurationSettings));
            }
          }
        }
      });
    this.customGroupSubscription = this.customGroupsData$.pipe(distinctUntilChanged(
      (data1, data2) => _.isEqual(data1, data2))).subscribe((customGroups: CustomGroupsDataAllFilters[]) => {
      this.customGroups = customGroups;
      this.nodePath = getSelectedNodePath(this.selectedFilteredProviders, this.listOfAllDepartments,
        this.listOfAllSpecialties, this.listOfAllProviders, this.allDepartmentsNodePath);
      this.customGroupDefault = this.customGroups.find(group =>
        group.isDefault);
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.customGroupSubscription?.unsubscribe();
  }

  private setUp(ontologyHierarchy: OntologyNode[], nodePath: string): void {
    this.allDepartmentsNodePath = ontologyHierarchy[0].nodePath;
    const nodeData: NodeData = (this.selectedHierarchicalNodes?.listOfAllDepartments.length) ?
      {
        listOfAllDepartments: this.selectedHierarchicalNodes.listOfAllDepartments,
        listOfAllSpecialties: this.selectedHierarchicalNodes.listOfAllSpecialties,
        listOfAllProviders: this.selectedHierarchicalNodes.listOfAllProviders
      }
      : getNodeDataFromHierarchy(ontologyHierarchy[0].children);
    this.listOfAllDepartments = nodeData.listOfAllDepartments;
    this.listOfAllSpecialties = nodeData.listOfAllSpecialties;
    this.listOfAllProviders = nodeData.listOfAllProviders;

    if (this.selectedHierarchicalNodes) {
      if (this.selectedHierarchicalNodes.group && !this.selectedHierarchicalNodes.groupIsEdited) {
        this.chooseGroup(this.selectedHierarchicalNodes.group);
      } else {
        this.updateAllLists();
        this.selectedGroup = this.selectedHierarchicalNodes.group;
        this.showEditIndicator = true;
      }
    } else {
      this.showEditIndicator = false;
      this.setHierarchyBasedOnNodePaths(nodePath);
      this.selectedProvidersAtTimeOfGroupSelection = this.selectedFilteredProviders.slice();
    }
    this.setEmptinessIndicationMessage();
  }

  setHierarchyBasedOnAllDepartmentsNodePaths() {
    this.selectedDepartments = [];
    this.tempSelectedDepartments = [];
    this.unSelectedDepartments = this.listOfAllDepartments.slice();
    this.tempUnselectedDepartments = this.unSelectedDepartments.slice();

    this.selectedFilteredSpecialties = [];
    this.tempSelectedFilteredSpecialties = [];
    this.unSelectedFilteredSpecialties = this.listOfAllSpecialties.slice();
    this.tempUnselectedFilteredSpecialties = this.unSelectedFilteredSpecialties.slice();

    this.selectedFilteredProviders = [];
    this.unselectedFilteredProviders = [];
    this.excludedUnselectedProviders = this.listOfAllProviders.slice();

    this.showDepartments = false;
    this.showSpecialties = false;
    this.departmentSearchText = '';
    this.specialtySearchText = '';
    this.providerSearchText = '';
    this.setSpecialtyLabels();
    this.setDepartmentLabels();
  }

  setSelectedHierarchyBasedOnOneDepartment(depPath: string) {
    const foundDepartment = this.listOfAllDepartments.find(department => department.selectableNode
      .node.nodePath === depPath);
    if (foundDepartment) {
      foundDepartment.selectableNode.selected = true;

      this.listOfAllSpecialties.forEach(spl => {
        if (spl.ancestors.get(depPath.split('\\')[2])) {
          spl.selectableNode.selected = true;
        }
      });
      this.listOfAllProviders.forEach(pro => {
        if (pro.selectableNode.node.nodePath.split('\\')[2] === depPath.split('\\')[2]) {
          pro.selectableNode.selected = true;
        }
      });
    }
  }

  setSelectedHierarchyBasedOnOneSpecialty(depPath: string, splPath: string) {
    const foundDepartment = this.listOfAllDepartments.find(department => department.selectableNode
      .node.nodePath === depPath);
    const foundSpecialty = this.listOfAllSpecialties.find(spl =>
      spl.selectableNode.node.nodePath.split('\\')[3] === splPath.split('\\')[3]);
    if (foundDepartment && foundSpecialty) {
      foundDepartment.selectableNode.selected = true;
      foundSpecialty.selectableNode.selected = true;
      this.listOfAllProviders.forEach(pro => {
        if (pro.selectableNode.node.nodePath.startsWith(splPath)) {
          pro.selectableNode.selected = true;
        }
      });
    }
  }

  setSelectedHierarchyBasedOnOneProvider(depPath: string, splPath: string, provPath: string) {
    const foundDepartment = this.listOfAllDepartments.find(department => department.selectableNode
      .node.nodePath === depPath);
    if (foundDepartment) {
      foundDepartment.selectableNode.selected = true;
    }
    const foundSpecialty = this.listOfAllSpecialties.find(spl =>
      spl.selectableNode.node.nodePath.split('\\')[3] === splPath.split('\\')[3]);
    if (foundSpecialty) {
      foundSpecialty.selectableNode.selected = true;
    }
    const foundProvider = this.listOfAllProviders.find(pro => pro.selectableNode
      .node.nodePath === provPath);
    if (foundProvider) {
      foundProvider.selectableNode.selected = true;
    }
  }

  setHierarchyBasedOnNodePaths(nodePaths: string): void {
    const nodePathArray = nodePaths.split('|').sort();
    nodePathArray.forEach((path: string) => {
      const pathArr = path.split('\\');
      switch (pathArr.length) {
        case 2:
          this.setHierarchyBasedOnAllDepartmentsNodePaths();
          return; // Everything is already included so no point working on the rest of the node path
        case 3:
          this.setSelectedHierarchyBasedOnOneDepartment(path);
          break;
        case 4:
          const deptPath = `\\${pathArr[1]}\\${pathArr[2]}`;
          this.setSelectedHierarchyBasedOnOneSpecialty(deptPath, path);
          break;
        case 5:
          const splPath = `\\${pathArr[1]}\\${pathArr[2]}\\${pathArr[3]}`;
          const depPath = `\\${pathArr[1]}\\${pathArr[2]}`;
          this.setSelectedHierarchyBasedOnOneProvider(depPath, splPath, path);
          break;
      }
    });
    this.updateAllLists();
  }

  toggleLimitMessage(show: boolean): void {
    this.showLimitMessage = show;
  }

  private updateAllLists(): void {
    this.selectedDepartments = this.listOfAllDepartments.filter(department => department.selectableNode.selected);
    this.unSelectedDepartments = this.listOfAllDepartments.filter(department => !department.selectableNode.selected);

    this.selectedFilteredSpecialties = this.listOfAllSpecialties.filter(spl => spl.selectableNode.selected);
    this.unSelectedFilteredSpecialties = this.listOfAllSpecialties.filter(spl => !spl.selectableNode.selected);

    this.excludedUnselectedProviders = [];
    this.selectedFilteredProviders = this.listOfAllProviders.filter(prov => prov.selectableNode.selected);

    this.unselectedFilteredProviders = this.listOfAllProviders.filter(prov => !prov.selectableNode.selected);
    this.filterUnrelatedProviders();
    this.filterProviderList(false);
    this.filterUnrelatedSpecialties();

    this.setDepartmentLabels();
    this.setSpecialtyLabels();
  }

  specialtyStringList(node: HierarchicalSelectableNode): string {
    let commaSeparatedList = '';
    const specialtyNames: string[] = [];
    Array.from(node.ancestors.values()).forEach(ancestor => {
      ancestor.filter(value => value.level === OntologyLevel.Specialty).forEach(specialty => {
        specialtyNames.push(specialty.node.nodeName);
      });
    });
    for (let i = 0; i < specialtyNames.length; i++) {
      commaSeparatedList = commaSeparatedList.concat(specialtyNames[i]);
      if (i < specialtyNames.length - 1) {
        commaSeparatedList = commaSeparatedList.concat(', ');
      }
    }
    return commaSeparatedList;
  }

  departmentStringList(node: HierarchicalSelectableNode): string {
    let commaSeparatedList = '';
    const departmentNames: string[] = [];
    Array.from(node.ancestors.values()).forEach(ancestor => {
      ancestor.filter(value => value.level === OntologyLevel.Department).forEach(department => {
        departmentNames.push(department.node.nodeName);
      });
    });
    for (let i = 0; i < departmentNames.length; i++) {
      commaSeparatedList = commaSeparatedList.concat(departmentNames[i]);
      if (i < departmentNames.length - 1) {
        commaSeparatedList = commaSeparatedList.concat(', ');
      }
    }
    return commaSeparatedList;
  }

  setEmptinessIndicationMessage(): void {
    this.emptinessIndicationMessageLeft = this.unselectedFilteredProviders.length >= 1 ? undefined : this.selectedFilteredProviders
      .length >= 1 && this.excludedUnselectedProviders.concat(this.unselectedFilteredProviders).length === 0 ?
      'All providers selected' : 'Select a Department/Specialty to view providers';
    this.emptinessIndicationMessageRight = this.selectedFilteredProviders.length >= 1 ? undefined :
      'Select ' + 'from provider list';
  }

  chooseGroup(group: CustomGroupsDataAllFilters): void {
    this.selectedGroup = group;
    this.setHierarchyBasedOnNodePaths(group.nodePath);
    this.selectedProvidersAtTimeOfGroupSelection = this.selectedFilteredProviders.slice();
    this.showEditIndicator = false;
    this.emitNodePaths();
  }

  onClearAllSelections(): void {
    this.selectedGroup = undefined;
    this.toggleAllSelectedProviders(false, this.selectedFilteredProviders);
    this.setHierarchyBasedOnAllDepartmentsNodePaths();
    this.showEditIndicator = this.determineEditIndicator();
    this.emitNodePaths();
  }

  private toggleAllSelectedProviders(selected: boolean, listOfProviders: HierarchicalSelectableNode[]) {
    listOfProviders.forEach(prov => prov.selectableNode.selected = selected);
  }

  private toggleProvider(selected: boolean, node: HierarchicalSelectableNode) {
    const providerNode = this.listOfAllProviders
      .find(prov => prov.selectableNode.node.nodePath === node.selectableNode.node.nodePath);
    if (providerNode) {
      providerNode.selectableNode.selected = selected;
    }
  }

  toggleShowDepartments(): void {
    this.initializeDepartmentSelectionSets();
    this.showDepartments = !this.showDepartments;
    if (this.showDepartments) {
      this.showSpecialties = false;
    }
  }

  toggleShowSpecialties(): void {
    this.initializeSpecialtySelectionSets();
    this.filterSpecialtyList();
    this.showSpecialties = !this.showSpecialties;
    if (this.showSpecialties) {
      this.showDepartments = false;
    }
  }

  updateDepartmentSelection = (node: HierarchicalSelectableNode, selected: boolean, event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    node.selectableNode.selected = selected;
  };

  whenDepartmentSelected = (node: HierarchicalSelectableNode, selected: boolean, event?: MouseEvent) => {
    this.updateDepartmentSelection(node, selected, event);
    this.updateShownDepartmentLists(node, selected);
  };

  updateShownDepartmentLists(node: HierarchicalSelectableNode, selected: boolean): void {
    _.remove(selected ? this.tempUnselectedDepartments : this.tempSelectedDepartments, node);
    (selected ? this.tempSelectedDepartments : this.tempUnselectedDepartments).push(node);
  }

  applyDepartment(autoSelect: boolean): void {
    this.unSelectedDepartments = this.tempUnselectedDepartments.concat(this.excludedTempUnselectedDepartments);
    this.excludedTempUnselectedDepartments = [];
    this.tempUnselectedDepartments = [];

    this.selectedDepartments = this.tempSelectedDepartments.concat(this.excludedTempSelectedDepartments);
    this.excludedTempSelectedDepartments = [];
    this.tempSelectedDepartments = [];

    this.setDepartmentLabels();
    if (autoSelect) {
      this.autoSelectSpecialties();
      this.setSpecialtyLabels();
    }
    this.filterUnrelatedProviders();
    this.filterUnrelatedSpecialties();
    this.showDepartments = false;
    this.setEmptinessIndicationMessage();
    this.showEditIndicator = true;
    this.emitNodePaths();
  }

  cancelDepartment(): void {
    this.tempUnselectedDepartments = this.unSelectedDepartments.slice();
    this.tempSelectedDepartments = this.selectedDepartments.slice();
    this.showDepartments = false;
    this.departmentSearchText = '';
    this.excludedTempSelectedDepartments = [];
    this.excludedTempUnselectedDepartments = [];
  }

  setDepartmentLabels(): void {
    this.selectedDepartmentLabel = 'All Departments';
    this.additionalDepartmentsSelectedLabel = undefined;
    if (this.selectedDepartments.length > 0 && this.unSelectedDepartments.length > 0) {
      this.selectedDepartmentLabel = this.selectedDepartments[0].selectableNode.node.nodeName || 'All Departments';
      this.additionalDepartmentsSelectedLabel = this.selectedDepartments.length > 1 ?
        '{' + `+${(this.selectedDepartments.length - 1)} More` + '}' : undefined;
    }
  }

  updateAllDepartments(selected: boolean, event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }

    if (selected) {
      this.tempUnselectedDepartments.forEach(department => {
        department.selectableNode.selected = true;
        this.tempSelectedDepartments.push(department);
      });
      this.tempUnselectedDepartments = [];
    } else {
      this.tempSelectedDepartments.forEach(department => {
        department.selectableNode.selected = false;
        this.tempUnselectedDepartments.push(department);
      });
      this.tempSelectedDepartments = [];
    }
  }

  onDepartmentSearchTextChanged(): void {
    const tempUnselected = this.tempUnselectedDepartments.concat(this.excludedTempUnselectedDepartments);
    this.excludedTempUnselectedDepartments = [];
    this.tempUnselectedDepartments = [];
    tempUnselected.forEach(d => {
      if (this.departmentSearchText.length >= this.MIN_SEARCH_LENGTH &&
        !d.selectableNode.node.nodeName.toLowerCase().includes(this.departmentSearchText.toLowerCase())) {
        this.excludedTempUnselectedDepartments.push(d);
      } else {
        d.selectableNode.matchesFilterSearchText = true;
        this.tempUnselectedDepartments.push(d);
      }
    });

    const tempSelected = this.tempSelectedDepartments.concat(this.excludedTempSelectedDepartments);
    this.excludedTempSelectedDepartments = [];
    this.tempSelectedDepartments = [];
    tempSelected.forEach(d => {
      if (this.departmentSearchText.length >= this.MIN_SEARCH_LENGTH &&
        !d.selectableNode.node.nodeName.toLowerCase().includes(this.departmentSearchText.toLowerCase())) {
        this.excludedTempSelectedDepartments.push(d);
      } else {
        d.selectableNode.matchesFilterSearchText = true;
        this.tempSelectedDepartments.push(d);
      }
    });
  }

  onSpecialtySearchTextChanged(): void { // TODO: Need to reexamine the logic
    this.setFlagsToIndicateMatch(this.listOfAllSpecialties, this.specialtySearchText);
    this.filterSpecialtyList();
  }

  onProviderSearchTextChanged(): void {
    this.setFlagsToIndicateMatch(this.listOfAllProviders, this.providerSearchText);
    this.filterProviderList(this.providerSearchText.length >= this.MIN_SEARCH_LENGTH);
  }

  setFlagsToIndicateMatch(list: HierarchicalSelectableNode[], searchString: string): void {
    list.forEach(element => { // everything is shown if search string is 2 chars or smaller
      element.selectableNode.matchesFilterSearchText = searchString.length < this.MIN_SEARCH_LENGTH ? true
        : element.selectableNode.node.nodeName.toLowerCase().includes(searchString.toLowerCase());
    });
  }

  updateSpecialtySelection = (node: HierarchicalSelectableNode, selected: boolean, event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    node.selectableNode.selected = selected;
  };

  whenSpecialtySelected = (node: HierarchicalSelectableNode, selected: boolean, event?: MouseEvent) => {
    this.updateSpecialtySelection(node, selected, event);
    this.updateShownSpecialtyLists(node, selected);
  };

  updateShownSpecialtyLists(node: HierarchicalSelectableNode, selected: boolean): void {
    _.remove(selected ? this.tempUnselectedFilteredSpecialties : this.tempSelectedFilteredSpecialties, node);
    (selected ? this.tempSelectedFilteredSpecialties : this.tempUnselectedFilteredSpecialties).push(node);
  }

  applySpecialty(): void {
    this.setSpecialtyLabels(true);

    this.unSelectedFilteredSpecialties = this.tempUnselectedFilteredSpecialties
      .concat(this.excludedTempUnselectedSpecialties);
    this.tempUnselectedFilteredSpecialties = [];
    this.excludedTempUnselectedSpecialties = [];

    this.selectedFilteredSpecialties = this.tempSelectedFilteredSpecialties
      .concat(this.excludedTempSelectedSpecialties);
    this.tempSelectedFilteredSpecialties = [];
    this.excludedTempSelectedSpecialties = [];

    this.filterUnrelatedProviders();
    this.showSpecialties = false;
    this.setEmptinessIndicationMessage();
    this.specialtySearchText = '';
    this.onSpecialtySearchTextChanged();
    this.showEditIndicator = true;
    this.emitNodePaths();
  }

  cancelSpecialty(): void {
    this.tempUnselectedFilteredSpecialties = this.unSelectedFilteredSpecialties.slice();
    this.tempSelectedFilteredSpecialties = this.selectedFilteredSpecialties.slice();
    this.showSpecialties = false;
    this.excludedTempSelectedSpecialties = [];
    this.excludedTempUnselectedSpecialties = [];
    this.specialtySearchText = '';
    this.onSpecialtySearchTextChanged();
  }

  setSpecialtyLabels(temp = false): void {
    this.selectedSpecialtyLabel = 'All Specialties';
    this.additionalSpecialtiesSelectedLabel = undefined;
    const selectedSpecialties = temp ? this.tempSelectedFilteredSpecialties : this.selectedFilteredSpecialties;
    const unSelectedSpecialties = temp ? this.tempUnselectedFilteredSpecialties : this.unSelectedFilteredSpecialties;
    if (selectedSpecialties.length > 0 && unSelectedSpecialties.length > 0) {
      this.selectedSpecialtyLabel = selectedSpecialties[0].selectableNode.node.nodeName
        || 'Select Specialty(s)';
      this.additionalSpecialtiesSelectedLabel = selectedSpecialties.length > 1 ?
        '{' + `+${(selectedSpecialties.length - 1)} More` + '}' : undefined;
    }
  }

  updateAllSpecialties(selected: boolean, event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }
    if (selected) {
      this.tempUnselectedFilteredSpecialties.forEach(specialty => {
        specialty.selectableNode.selected = true;
        this.tempSelectedFilteredSpecialties.push(specialty);
      });
      this.tempUnselectedFilteredSpecialties = [];
    } else {
      this.tempSelectedFilteredSpecialties.forEach(specialty => {
        specialty.selectableNode.selected = false;
        this.tempUnselectedFilteredSpecialties.push(specialty);
      });
      this.tempSelectedFilteredSpecialties = [];
    }
  }

  whenUnselectedProviderRowClicked = (node: HierarchicalSelectableNode, event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    this.unselectedFilteredProviders = this.unselectedFilteredProviders.filter(p => p !== node);
    this.selectedFilteredProviders = this.selectedFilteredProviders.concat(node);
    this.toggleProvider(true, node);
    this.showEditIndicator = this.determineEditIndicator();
    if (!this.showEditIndicator) {
      this.askParentToNotCloseEditPopup = true;
    }
    this.emitNodePaths();
  };

  whenSelectedProviderRowClicked = (node: HierarchicalSelectableNode, event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    this.selectedFilteredProviders = this.selectedFilteredProviders.filter(p => p !== node);
    this.unselectedFilteredProviders = this.unselectedFilteredProviders.concat(node);
    this.toggleProvider(false, node);
    this.showEditIndicator = this.determineEditIndicator();
    if (!this.showEditIndicator) {
      this.askParentToNotCloseEditPopup = true;
    }
    this.emitNodePaths();
  };

  determineEditIndicator(): boolean {
    if ((this.selectedFilteredProviders.length === this.listOfAllProviders.length
        || this.selectedFilteredProviders.length === 0) &&
      (this.selectedProvidersAtTimeOfGroupSelection.length === this.listOfAllProviders.length
        || this.selectedProvidersAtTimeOfGroupSelection.length === 0)) {
      return false;
    }
    const removedProviders = this.selectedProvidersAtTimeOfGroupSelection.filter(p => this.selectedFilteredProviders
      .indexOf(p) < 0);
    if (removedProviders.length >= 1) {
      return true;
    }
    const addedProviders = this.selectedFilteredProviders.filter(p => this.selectedProvidersAtTimeOfGroupSelection
      .indexOf(p) < 0);
    return addedProviders.length >= 1;
  }

  updateAllProviders = (selected: boolean, event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (selected) {
      this.selectedFilteredProviders = this.selectedFilteredProviders.concat(this.unselectedFilteredProviders);
      this.unselectedFilteredProviders = [];
    } else {
      this.unselectedFilteredProviders = this.unselectedFilteredProviders.concat(...this.selectedFilteredProviders);
      this.selectedFilteredProviders = [];
    }

    this.toggleAllSelectedProviders(selected, selected ? this.selectedFilteredProviders : this.unselectedFilteredProviders);
    this.showEditIndicator = this.determineEditIndicator();
    this.setEmptinessIndicationMessage();

    if (!this.showEditIndicator) {
      this.askParentToNotCloseEditPopup = true;
    }
    this.emitNodePaths();
  };

  filterUnrelatedProviders(): void {
    this.listOfAllProviders.forEach(provider => {
      provider.selectableNode.belongs = false;
    });
    const providersOfDepartments: SelectableNode[] = [];
    const providersOfSpecialties: SelectableNode[] = [];
    this.selectedDepartments.forEach(department => {
      Array.from(department.descendants.values()).forEach(descendant => {
        descendant.forEach(value => {
          if (value.level === OntologyLevel.Provider) {
            providersOfDepartments.push(value);
          }
        });
      });
    });
    this.selectedFilteredSpecialties.forEach(specialty => {
      Array.from(specialty.descendants.values()).forEach(descendant => {
        descendant.forEach(value => {
          if (value.level === OntologyLevel.Provider) {
            providersOfSpecialties.push(value);
          }
        });
      });
    });
    if (providersOfDepartments.length > 0 && providersOfSpecialties.length > 0) {
      providersOfDepartments.filter(provider => providersOfSpecialties.includes(provider))
        .forEach(provider => provider.belongs = true);
    } else if (providersOfDepartments.length > 0) {
      providersOfDepartments.forEach(provider => provider.belongs = true);
    } else if (providersOfSpecialties.length > 0) {
      providersOfSpecialties.forEach(provider => provider.belongs = true);
    }
    this.filterProviderList(this.providerSearchText.length >= this.MIN_SEARCH_LENGTH);
  }

  filterProviderList(showEvenIfItDoesNotBelong: boolean): void {
    const allUnselectedProviders = this.unselectedFilteredProviders.concat(this.excludedUnselectedProviders);
    this.unselectedFilteredProviders = allUnselectedProviders.filter(node =>
      node.selectableNode.matchesFilterSearchText && (node.selectableNode.belongs || showEvenIfItDoesNotBelong));
    this.excludedUnselectedProviders = allUnselectedProviders.filter(node =>
      !(node.selectableNode.matchesFilterSearchText && (node.selectableNode.belongs || showEvenIfItDoesNotBelong)));
  }

  filterUnrelatedSpecialties(): void {
    if (this.selectedDepartments.length >= 1) {
      const relatedSpecialties: SelectableNode[] = [];
      this.listOfAllSpecialties.forEach(specialty => {
        specialty.selectableNode.belongs = false;
      });
      this.selectedDepartments.forEach(department => {
        Array.from(department.descendants.values()).forEach(descendants => {
          descendants.forEach(value => {
            if (value.level === OntologyLevel.Specialty) {
              value.belongs = true;
              relatedSpecialties.push(value);
            }
          });
        });
      });
      this.populateUnselectedSpecialtiesList(relatedSpecialties);
    } else {
      this.listOfAllSpecialties.forEach(specialty => {
        specialty.selectableNode.belongs = true;
      });
    }
    this.filterSpecialtyList();
  }

  private populateUnselectedSpecialtiesList(relatedSpecialties: SelectableNode[]) {
    this.unSelectedFilteredSpecialties = [];
    const unSelectedSpecialties = relatedSpecialties.filter(spec => !spec.selected);
    this.listOfAllSpecialties.filter(specialty => {
      unSelectedSpecialties.forEach(unSelectedSpecialty => {
        if (specialty.selectableNode.node.nodePath === unSelectedSpecialty.node.nodePath) {
          this.unSelectedFilteredSpecialties.push(specialty);
        }
      });
    });
  }

  compareWithExactMatchOnTop = (a: string, b: string, searchText: string) => {
    const aStartsWithSearchText = a.toLowerCase().startsWith(searchText.toLowerCase());
    const bStartsWithSearchText = b.toLowerCase().startsWith(searchText.toLowerCase());
    return (aStartsWithSearchText && !bStartsWithSearchText) ? -1
      : ((bStartsWithSearchText && !aStartsWithSearchText) ? 1 : a.localeCompare(b));
  };

  filterSpecialtyList(): void {
    const allUnselectedSpecialties = this.tempUnselectedFilteredSpecialties
      .concat(this.excludedTempUnselectedSpecialties).sort((a, b) =>
        this.compareWithExactMatchOnTop(a.selectableNode.node.nodeName, b.selectableNode.node.nodeName, this.specialtySearchText));
    const allSelectedSpecialties = this.tempSelectedFilteredSpecialties.concat(this.excludedTempSelectedSpecialties)
      .sort((a, b) =>
        this.compareWithExactMatchOnTop(a.selectableNode.node.nodeName, b.selectableNode.node.nodeName, this.specialtySearchText));
    this.tempUnselectedFilteredSpecialties = allUnselectedSpecialties.filter(specialty =>
      specialty.selectableNode.matchesFilterSearchText && specialty.selectableNode.belongs);
    this.excludedTempUnselectedSpecialties = allUnselectedSpecialties.filter(specialty =>
      !(specialty.selectableNode.matchesFilterSearchText && specialty.selectableNode.belongs));
    this.tempSelectedFilteredSpecialties = allSelectedSpecialties.filter(specialty =>
      specialty.selectableNode.matchesFilterSearchText && specialty.selectableNode.belongs);
    this.excludedTempSelectedSpecialties = allSelectedSpecialties.filter(specialty =>
      !(specialty.selectableNode.matchesFilterSearchText && specialty.selectableNode.belongs));
  }

  autoSelectSpecialties(): void {
    this.listOfAllSpecialties.forEach(spec => spec.selectableNode.selected = false);
    this.selectedFilteredSpecialties = [];
    this.unSelectedFilteredSpecialties = [];
    const arrSplNodePaths: string[] = [];
    this.selectedDepartments.forEach(department => {
      Array.from(department.descendants.values()).forEach(descendants => {
        descendants.filter(value => value.level === OntologyLevel.Specialty).forEach(specialty => {
          specialty.selected = true;
          const selectedFilterSpecialty = this.listOfAllSpecialties.find(listSpecialty =>
            listSpecialty.selectableNode.node.nodePath === specialty.node.nodePath);
          if (selectedFilterSpecialty && !arrSplNodePaths.includes(selectedFilterSpecialty.selectableNode.node.nodePath)) {
            arrSplNodePaths.push(selectedFilterSpecialty.selectableNode.node.nodePath);
            this.selectedFilteredSpecialties.push(selectedFilterSpecialty);
          }
        });
      });
    });
  }

  private emitNodePaths(): void {
    this.nodePathEmit.emit({
      selectedFilteredProviders: this.selectedFilteredProviders,
      listOfAllDepartments: this.listOfAllDepartments,
      listOfAllSpecialties: this.listOfAllSpecialties,
      listOfAllProviders: this.listOfAllProviders,
      allDepartmentsNodePath: this.allDepartmentsNodePath,
      group: this.selectedGroup,
      groupIsEdited: this.showEditIndicator,
      askParentToNotCloseEditPopup: this.askParentToNotCloseEditPopup,
      selectedProvidersAtTimeOfGroupSelection: this.selectedProvidersAtTimeOfGroupSelection
    });
    this.askParentToNotCloseEditPopup = false;
  }

  initializeDepartmentSelectionSets(): void {
    this.tempSelectedDepartments = this.selectedDepartments.slice();
    this.tempSelectedDepartments.forEach(e => {
      e.selectableNode.selected = true;
    });
    this.tempUnselectedDepartments = this.unSelectedDepartments.slice();
    this.unSelectedDepartments.forEach(e => {
      e.selectableNode.selected = false;
    });
  }

  initializeSpecialtySelectionSets(): void {
    this.tempSelectedFilteredSpecialties = this.selectedFilteredSpecialties ?
      this.selectedFilteredSpecialties.slice() : [];
    this.tempSelectedFilteredSpecialties.forEach(e => {
      e.selectableNode.selected = true;
    });
    this.tempUnselectedFilteredSpecialties = this.unSelectedFilteredSpecialties ?
      this.unSelectedFilteredSpecialties.slice() : [];
    this.tempUnselectedFilteredSpecialties.forEach(e => {
      e.selectableNode.selected = false;
    });
  }

  sortProviderListBy(event: [SortingCriterion, FilterCriteriaTableView]): void {
    switch (event[0].columnDef) {
      case 'providers':
        switch (event[1]) {
          case FilterCriteriaTableView.SELECTED:
            this.selectedFilteredProviders.sort(function (a: HierarchicalSelectableNode, b: HierarchicalSelectableNode) {
              return event[0].sortingOrder === 'asc' ? a.selectableNode.node.nodeName.localeCompare(
                b.selectableNode.node.nodeName) : event[0].sortingOrder === 'desc' ? -1 * a.selectableNode.node.nodeName
                .localeCompare(b.selectableNode.node.nodeName) : 0;
            });
            this.selectedFilteredProviders = this.selectedFilteredProviders.slice();
            break;
          case FilterCriteriaTableView.NONSELECTED:
            this.unselectedFilteredProviders.sort(function (a: HierarchicalSelectableNode, b: HierarchicalSelectableNode) {
              return event[0].sortingOrder === 'asc' ? a.selectableNode.node.nodeName.localeCompare(
                b.selectableNode.node.nodeName) : event[0].sortingOrder === 'desc' ? -1 * a.selectableNode.node.nodeName
                .localeCompare(b.selectableNode.node.nodeName) : 0;
            });
            this.unselectedFilteredProviders = this.unselectedFilteredProviders.slice();
            break;
        }
        break;
      case 'specialties':
        switch (event[1]) {
          case FilterCriteriaTableView.SELECTED:
            this.selectedFilteredProviders.sort(function (a: HierarchicalSelectableNode, b: HierarchicalSelectableNode) {
              let specialtyA: SelectableNode | undefined;
              Array.from(a.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Specialty) {
                    specialtyA = value;
                  }
                });
              });
              const specialtyNameA = specialtyA ? specialtyA.node.nodeName : '';
              let specialtyB: SelectableNode | undefined;
              Array.from(b.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Specialty) {
                    specialtyB = value;
                  }
                });
              });
              const specialtyNameB = specialtyB ? specialtyB.node.nodeName : '';
              return event[0].sortingOrder === 'asc' ? specialtyNameA.localeCompare(specialtyNameB) : event[0].sortingOrder === 'desc' ?
                -1 * specialtyNameA.localeCompare(specialtyNameB) : 0;
            });
            this.selectedFilteredProviders = this.selectedFilteredProviders.slice();
            break;
          case FilterCriteriaTableView.NONSELECTED:
            this.unselectedFilteredProviders.sort(function (a: HierarchicalSelectableNode, b: HierarchicalSelectableNode) {
              let specialtyA: SelectableNode | undefined;
              Array.from(a.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Specialty) {
                    specialtyA = value;
                  }
                });
              });
              const specialtyNameA = specialtyA ? specialtyA.node.nodeName : '';
              let specialtyB: SelectableNode | undefined;
              Array.from(b.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Specialty) {
                    specialtyB = value;
                  }
                });
              });
              const specialtyNameB = specialtyB ? specialtyB.node.nodeName : '';
              return event[0].sortingOrder === 'asc' ? specialtyNameA.localeCompare(specialtyNameB) : event[0].sortingOrder === 'desc' ?
                -1 * specialtyNameA.localeCompare(specialtyNameB) : 0;
            });
            this.unselectedFilteredProviders = this.unselectedFilteredProviders.slice();
            break;
        }
        break;
      case 'departments':
        switch (event[1]) {
          case FilterCriteriaTableView.SELECTED:
            this.selectedFilteredProviders.sort(function (a: HierarchicalSelectableNode, b: HierarchicalSelectableNode) {
              let departmentA: SelectableNode | undefined;
              Array.from(a.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Department) {
                    departmentA = value;
                  }
                });
              });
              const departmentNameA = departmentA ? departmentA.node.nodeName : '';
              let departmentB: SelectableNode | undefined;
              Array.from(b.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Department) {
                    departmentB = value;
                  }
                });
              });
              const departmentNameB = departmentB ? departmentB.node.nodeName : '';
              return event[0].sortingOrder === 'asc' ? departmentNameA.localeCompare(departmentNameB) : event[0].sortingOrder === 'desc' ?
                -1 * departmentNameA.localeCompare(departmentNameB) : 0;
            });
            this.selectedFilteredProviders = this.selectedFilteredProviders.slice();
            break;
          case FilterCriteriaTableView.NONSELECTED:
            this.unselectedFilteredProviders.sort(function (a: HierarchicalSelectableNode, b: HierarchicalSelectableNode) {
              let departmentA: SelectableNode | undefined;
              Array.from(a.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Department) {
                    departmentA = value;
                  }
                });
              });
              const departmentNameA = departmentA ? departmentA.node.nodeName : '';
              let departmentB: SelectableNode | undefined;
              Array.from(b.ancestors.values()).forEach(ancestor => {
                ancestor.forEach(value => {
                  if (value.level === OntologyLevel.Department) {
                    departmentB = value;
                  }
                });
              });
              const departmentNameB = departmentB ? departmentB.node.nodeName : '';
              return event[0].sortingOrder === 'asc' ? departmentNameA.localeCompare(departmentNameB) : event[0].sortingOrder === 'desc' ?
                -1 * departmentNameA.localeCompare(departmentNameB) : 0;
            });
            this.unselectedFilteredProviders = this.unselectedFilteredProviders.slice();
            break;
        }
        break;
    }
  }

  clearSearch() {
    this.providerSearchText = '';
    this.onProviderSearchTextChanged();
  }
}
