<div id="clinicalsummary-graph-wrapper">

  <div [chart]="chartObject" id="clinical-summary-graph"></div>

  <app-chart-legend
    [legends]="legends"
    [hasBenchmarks]="hasBenchmarks"
    [activeVarianceToggle]="activeVarianceToggle"
  ></app-chart-legend>
  <app-loader *ngIf="showProgressBar"></app-loader>
</div>
