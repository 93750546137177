<div class="application-dropdown">
  <form class="date-range-picker vizient-input" [formGroup]="form">
    <mat-form-field class="{{filterBannerFlag ? 'filter-banner' : ''}}" [class.cdk-visually-hidden]="true">
      <input matInput
             [placeholder]="placeholder"
             [matRangeDatepicker]="resultPicker"
             formControlName="date"
             (ngModelChange)="onInputDate($event)"
             (dateInput)="dateInput.emit($event)"
             (dateChange)="dateChange.emit($event)"
             [typeMode]="typeMode"
             readonly

      >
      <mat-range-datepicker
        #resultPicker [rangeMode]="rangeMode" (closed)="this.onClose($event)" [typeMode]="typeMode" (opened)="open = true">
      </mat-range-datepicker>
      <mat-range-datepicker-toggle matSuffix [for]="resultPicker" [filterBannerFlag]="filterBannerFlag">
        <mat-icon matDatepickerToggleIcon class="date-picker-text">
          <span *ngIf="!filterBannerFlag">Edit</span>
          <img *ngIf="filterBannerFlag" src="../assets/caret-down.svg" id="caret-down"/>
        </mat-icon>
      </mat-range-datepicker-toggle>
    </mat-form-field>
  </form>
</div>
