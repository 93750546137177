import {Component, EventEmitter, Inject, Input, OnChanges, Output} from '@angular/core';
import {
  ClinicalSummaryServiceToken,
  HttpClinicalSummaryService
} from '../clinical-fingerprint/services/clinical-finger-print.service';
import {NgRedux, select} from '@angular-redux/store';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../store/IAppState';
import {ClinicalSummaryConsolidation, TornadoStyles} from '../clinical-fingerprint/ClinicalSummary';
import * as _ from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {canHide, getAppConfigValue, getSelectedDateRange} from '../shared/helpers';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {
  ApplicationConfigurationSetting,
  Breadcrumb,
  CurrentMemberLocation,
  FeatureToggleSetting
} from '../shared/models';
import {Export, ExportMetadata, ExportNew} from '../shared/export/export';
import {ExcelService, ExcelServiceToken} from '../services/excel.service';
import {getCPTAnalysisExcelData} from '../shared/export/export-excel-helper';
import {cptCodeColumns, cptCodeCommunityColumns} from '../shared/data-table-columns';
import {roundToNumber} from '../productivity-summary/number-formatter';
import {checkForNulls} from '../shared/null-helpers';
import {
  calculateBreadcrumbs,
  composeCurrentLocationFiltersForExcelExport,
  getCptAnalysisExportData
} from '../shared/export/export-helper';
import {CSV, CSVToken} from '../services/csv.service';
import {
  ProductivitySummaryData
} from '../productivity-summary/wrvu-summary-data-table/wrvu-summary-data-table.component';
import {AppConfigEntries} from '../shared/app-config-settings-enum';

@Component({
  selector: 'app-view-cpt',
  templateUrl: './view-cpt.component.html',
  styleUrls: ['./view-cpt.component.scss']
})
export class ViewCptComponent implements OnChanges {

  @Input() nodeName = '';
  @Input() nodePath = '';
  @Input() parentNodeNames = '';
  @Input() cfte: ProductivitySummaryData;
  @Output() closed = new EventEmitter<boolean>();
  cptCodeColumns = cptCodeColumns.slice();
  cptCodeCommunityColumns = cptCodeCommunityColumns.slice();
  canHideAnalysisExport = true;
  cptExport: ExportNew;
  cptExportData: ExportMetadata[];
  provider: Breadcrumb[] = [];
  location: string;
  cfExportTitle: string;
  showExportDropdown: boolean;
  cfteSummary: number | null;
  clinicalSummary: ClinicalSummaryConsolidation = {
    cptClinicalSummaries: [],
    rangeClinicalSummaries: [],
    familyClinicalSummaries: []
  };
  showProgressBar = true;
  initialFilters: FilterCriteria;
  clinicalSummarySubscription: Subscription;
  filterSubscription: Subscription;
  featureToggleSubscription: Subscription;
  private applicationConfigurationSettings: ApplicationConfigurationSetting[];
  private memberLocations: CurrentMemberLocation[];
  copyrightYear = new Date().getFullYear() - 1;

  @select(['filters'])
  private readonly filterCriteria$: Observable<FilterCriteria>;

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  viewCommunityBenchmarks: boolean;

  constructor(@Inject(ClinicalSummaryServiceToken) private clinicalSummaryService: HttpClinicalSummaryService,
              private ngRedux: NgRedux<IAppState>, @Inject(ExcelServiceToken) private readonly excelService: ExcelService,
              @Inject(CSVToken) private readonly csv: CSV) {
  }

  ngOnChanges() {
    this.initialFilters = this.ngRedux.getState().filters;
    const nextFilter: FilterCriteria = {
      ...this.initialFilters,
      nodePath: this.nodePath
    };

    [this.clinicalSummarySubscription, this.filterSubscription, this.featureToggleSubscription].forEach(sub => sub && sub.unsubscribe());

    this.clinicalSummarySubscription = this.clinicalSummaryService.getClinicalSummaryConsolidation(nextFilter, 2)
      .subscribe((clinicalSummary: ClinicalSummaryConsolidation) => {
        this.viewCommunityBenchmarks = this.ngRedux.getState().display.viewCommunityBenchmarks;
        this.clinicalSummary = clinicalSummary;
        this.filterCptDisplays();
        let frequencyMax = 0, wrvuMax = 0;

        this.clinicalSummary.cptClinicalSummaries.forEach(summary => {
          if (this.viewCommunityBenchmarks) {
            frequencyMax = Math.max(frequencyMax, Math.abs(checkForNulls(summary.communityCfteAdjustedFrequencyVariance)));
            wrvuMax = Math.max(wrvuMax, Math.abs(summary.communityCfteAdjustedWrvusVariance || 0));
          } else {
            frequencyMax = Math.max(frequencyMax, Math.abs(checkForNulls(summary.cFTEAdjustedFrequencyVariance)));
            wrvuMax = Math.max(wrvuMax, Math.abs(summary.cFTEAdjustedWrvusVariance || 0));
          }
        });

        this.clinicalSummary.cptClinicalSummaries.forEach(summary => {
          const frequencyVariance = this.viewCommunityBenchmarks ?
            roundToNumber(checkForNulls(summary.communityCfteAdjustedFrequencyVariance), 2) :
            roundToNumber(checkForNulls(summary.cFTEAdjustedFrequencyVariance), 2);
          const wrvuVariance = this.viewCommunityBenchmarks ?
            roundToNumber(checkForNulls(summary.communityCfteAdjustedWrvusVariance), 2) :
            roundToNumber(checkForNulls(summary.cFTEAdjustedWrvusVariance), 2);
          summary.cfteAdjustedWRVUs = roundToNumber(checkForNulls(summary.cfteAdjustedWRVUs), 2);
          summary.cfteAdjustedFrequency = roundToNumber(checkForNulls(summary.cfteAdjustedFrequency), 2);
          summary.frequency = roundToNumber(checkForNulls(summary.frequency), 2);
          summary.countTornado = getTornadoStyle(frequencyVariance, frequencyMax);
          summary.wrvuTornado = getTornadoStyle(wrvuVariance, wrvuMax);
        });
        this.showProgressBar = false;
      });

    this.filterSubscription = this.filterCriteria$.subscribe((filterCriteria: FilterCriteria) => {
      if (!_.isEqual(this.initialFilters, filterCriteria)) {
        this.close();
      }
    });
    this.featureToggleSubscription = this.featureToggleSettings$.subscribe((featureSettings: FeatureToggleSetting[]) => {
      if (!_.isEqual(featureSettings, INITIAL_STATE.data.featureToggleSettings)) {
        const { applicationConfigurationSettings, memberLocations, userProfile } = this.ngRedux.getState().data;
        this.applicationConfigurationSettings = applicationConfigurationSettings;
        this.memberLocations = memberLocations;
        this.canHideAnalysisExport =
          canHide(FeatureToggleEntries.CPT_ANALYSIS_EXPORT, featureSettings, userProfile);
      }
    });
  }

  private filterCptDisplays(): void {
    this.clinicalSummary.cptClinicalSummaries = this.clinicalSummary.cptClinicalSummaries
      .filter(code => code.cfteAdjustedWRVUs !== 0);
    this.clinicalSummary.cptClinicalSummaries = _.orderBy(this.clinicalSummary.cptClinicalSummaries,
      ['cfteAdjustedWRVUs'], ['desc'])
      .slice(0, 25);
  }

  openExcelFile() {
    const cptClinicalDetail = this.clinicalSummary.cptClinicalSummaries;
    const dateRange = this.initialFilters.dateRange;
    const maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT, this.applicationConfigurationSettings) || 5;
    const selectedLocations = this.memberLocations.filter(loc => loc.currentlySelected);
    const additionalLocations = selectedLocations.length - maxLocations;
    const isSingleLocation = !selectedLocations.length;
    this.location = composeCurrentLocationFiltersForExcelExport(isSingleLocation, selectedLocations,
      maxLocations, additionalLocations, this.initialFilters);
    this.cfteSummary = this.ngRedux.getState().data.productivitySummaryData.selectedDateRange.cfte;
    this.provider.push({
      nodeName: this.nodeName,
      nodePath: this.nodePath
    });
    this.cptExportData = [];
    this.cptExportData.push(getCPTAnalysisExcelData(cptClinicalDetail,
      'Clinical Practice Solutions Center ' +
      'Clinical Fingerprint CPT® only © ' + this.copyrightYear +
      ' American Medical Association. All Rights Reserved ' + new Date().toLocaleString() + ';',
      this.viewCommunityBenchmarks ? this.cptCodeCommunityColumns : this.cptCodeColumns,
      dateRange, this.viewCommunityBenchmarks,
            this.nodePath,
      this.location));
    this.cptExport = {
      data: this.cptExportData,
      sheets: ['CPT Analysis'],
      fileName: this.getExcelFileName(),
    };

    this.excelService.exportAsExcelFile(this.cptExport, true);
  }

  openCSVFile() {
    const exportData: Export[] = [];
    const breadCrumb = calculateBreadcrumbs(this.nodePath);
    const cptClinicalDetail = this.clinicalSummary.cptClinicalSummaries;
    const maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT, this.applicationConfigurationSettings) || 5;
    const selectedLocations = this.memberLocations.filter(loc => loc.currentlySelected);
    const additionalLocations = selectedLocations.length - maxLocations;
    const isSingleLocation = !selectedLocations.length;
    this.location = composeCurrentLocationFiltersForExcelExport(isSingleLocation, selectedLocations, maxLocations,
      additionalLocations, this.initialFilters);
    this.provider.push({
      nodeName: this.nodeName,
      nodePath: this.nodePath
    });
    this.cfExportTitle = 'Clinical Practice Solutions Center ' +
      'Clinical Fingerprint CPT® only © ' + this.copyrightYear + ' American Medical Association. All Rights Reserved';
    const columnHeaders: string[] = [];
    if (this.viewCommunityBenchmarks) {
      this.cptCodeCommunityColumns.forEach(colz => {
        columnHeaders.push(colz.header);
      });
    } else {
      this.cptCodeColumns.forEach(colz => {
        columnHeaders.push(colz.header);
      });
    }

    exportData.push(getCptAnalysisExportData(
      cptClinicalDetail,
      this.cfExportTitle + ' ' + new Date().toLocaleString() + ';',
      columnHeaders,
      this.viewCommunityBenchmarks));

    exportData.forEach((csvData: Export) => {
      const benchMarkText = this.viewCommunityBenchmarks ? 'Community Benchmark' : 'Academic Benchmark';
      this.csv.export(csvData.data,
        csvData.fileName,
        {
          fieldSeparator: '|',
          quoteStrings: '',
          headers: '',
          title: csvData.title +
            '\n' + this.ngRedux.getState().data.selectedMemberData.memberDesc +
            '\n' + breadCrumb +
            '\n' + 'Selected Date Range (' + getSelectedDateRange(this.ngRedux.getState().filters.dateRange) + ')' +
            '\n' + this.location +
            (csvData.whatFilters?.showPayer ? '\n' + 'Payer Category: ' +
              this.ngRedux.getState().filters.payerCategory.payerCategoryDescription : '') +
            '\n' + 'Benchmark: ' + benchMarkText,
          showTitle: (csvData.title !== undefined),
        });
    });
  }

  getExcelFileName(): string {
    const member = this.ngRedux.getState().data.members.members.find(
      x => x.memberKey === this.ngRedux.getState().filters.memberKey);
    return member ? member.memberDesc : 'cpsc';
  }

  isPageLoading(): boolean {
    let isDone = (_.isEqual(this.clinicalSummary, INITIAL_STATE.data.clinicalSummaryConsolidationData));
    if (!this.canHideAnalysisExport) {
      isDone = isDone || _.isEqual(this.clinicalSummary, INITIAL_STATE.data.clinicalSummaryConsolidationData);
    }
    return isDone;
  }

  toggleExportDropdown() {
    this.showExportDropdown = !this.showExportDropdown;
  }

  closeExportDropdown() {
    this.showExportDropdown = false;
  }

  close(): void {
    this.closed.emit(true);
  }
}

export function getTornadoStyle(value: number, max: number): TornadoStyles {
  const borderWidth = 2 + Math.round(12 * (Math.abs(value) / max));
  const positive = value >= 0;
  return {
    borderWidth: borderWidth,
    color: value >= 0 ? '#82B606' : '#F55E64',
    positive: positive
  };
}
