import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LagPeriod} from '../../shared/models';
import {lagPeriods} from '../../shared/helpers';
import {DEFAULT_LAG_KEY} from '../../store/DefaultValues';

@Component({
  selector: 'app-select-lag-key',
  templateUrl: './select-lag-key.component.html',
  styleUrls: ['./select-lag-key.component.scss']
})
export class SelectLagKeyComponent {

  @Output() lagKeyEmit = new EventEmitter<number>();
  @Input() lagPeriod: LagPeriod;
  readonly allActivity = lagPeriods[6];
  lagPeriods = lagPeriods.filter(lag => lag.key !== DEFAULT_LAG_KEY);

  constructor() {
  }

  selectLagPeriod(lagPeriod: LagPeriod, event: MouseEvent) {
    this.lagKeyEmit.emit(lagPeriod.key);
    this.lagPeriod = lagPeriod;
  }

}
