<div class="wrapper">
  <div [id]="overviewPageView? 'overview-patient-visit-charts' : 'patient-visit-charts'">
    <div class="patient-visit-chart">
      <app-evaluation-management-chart
        chartId="initial-hospital-care"
        chartTitle="Initial Hospital Care"
        [fromPdf]="fromPdf"
        [categories]="initialHospitalCareCategories"
        [maxYAxisValue]="maxInitialHospitalCareYAxisValue"
        [totalCptSummaries]="totalInitialHospitalCareCptSummaries"
        [chartHeight]="chartHeight"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [specialtyCptSummaries]="specialtyTotalInitialHospitalCareCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-chart
        chartId="subsequent-hospital-care"
        chartTitle="Subsequent Hospital Care"
        [fromPdf]="fromPdf"
        [categories]="subsequentHospitalCareCategories"
        [maxYAxisValue]="maxSubsequentHospitalCareYAxisValue"
        [totalCptSummaries]="totalSubsequentHospitalCareCptSummaries"
        [chartHeight]="chartHeight"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [specialtyCptSummaries]="specialtyTotalSubsequentHospitalCareCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-chart
        chartId="hospital-discharge-day"
        [categories]="hospitalDischargeDayCategories"
        [maxYAxisValue]="maxHospitalDischargeDayYAxisValue"
        chartTitle="Hospital Discharge Day"
        [totalCptSummaries]="totalHospitalDischargeDayCptSummaries"
        [chartHeight]="chartHeight"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [specialtyCptSummaries]="specialtyTotalHospitalDischargeDayCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
    </div>
    <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
  <app-chart-legend [ngClass]="fromPdf? 'pdf-legend': ''" [fromPdf]="fromPdf" [legends]="legends" [metricType]="metricType"></app-chart-legend>
</div>
