import {FilterCriteria} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.local';
import {
  CollectionsSummaryForAllTypes,
  MatchedCollectionsMultiLevelNodePathData,
  PayerCollectionsData,
  PayerMatchedCollectionsMultiLevelNodePathData,
} from '../Collection';
import {DatasetType} from '../../shared/enums';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const CollectionServiceToken = new InjectionToken<CollectionsService>(
  'Collection Service');

export interface CollectionsService {

  getPayerCollectionsData(filterCriteria: FilterCriteria): Observable<PayerCollectionsData>;

  getSummaryMatchedCollectionsData(filterCriteria: FilterCriteria, summaryType: number):
    Observable<CollectionsSummaryForAllTypes[]>;

  getMatchedCollectionsByMultiLevelByNodePathData(filterCriteria: FilterCriteria, dataset: DatasetType):
    Observable<MatchedCollectionsMultiLevelNodePathData>;

  getMatchedCollectionsByPayerByMultiLevelData(filterCriteria: FilterCriteria): Observable<PayerMatchedCollectionsMultiLevelNodePathData>;
}

@Injectable()
export class HttpCollectionsService implements CollectionsService {
  constructor(private httpClient: HttpClient) {
  }

  getPayerCollectionsData(filterCriteria: FilterCriteria): Observable<PayerCollectionsData> {
    const baseUrl = `${environment.api}/api/matchedCollectionsByPayer`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<PayerCollectionsData>(
      baseUrl,
      postBody,
      {params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())},
    );
  }

  getMatchedCollectionsByMultiLevelByNodePathData(filterCriteria: FilterCriteria, dataset: DatasetType):
    Observable<MatchedCollectionsMultiLevelNodePathData> {
    const baseUrl = `${environment.api}/api/matchedCollectionsByMultiLevelNodePath`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<MatchedCollectionsMultiLevelNodePathData>(
      baseUrl,
      postBody,
      {params: new HttpParams()
          .set('memberKey', filterCriteria.memberKey.toString())
          .set('dataset', dataset.toString())},
    );
  }

  getSummaryMatchedCollectionsData(filterCriteria: FilterCriteria, summaryType: number):
    Observable<CollectionsSummaryForAllTypes[]> {
    const baseUrl = `${environment.api}/api/matchedCollectionsSummary`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<CollectionsSummaryForAllTypes[]>(
      baseUrl,
      postBody,
      {
        params: new HttpParams()
          .set('memberKey', filterCriteria.memberKey.toString())
          .set('summaryType', summaryType.toString())
      },
    );
  }

  getMatchedCollectionsByPayerByMultiLevelData(filterCriteria: FilterCriteria): Observable<PayerMatchedCollectionsMultiLevelNodePathData> {
    const baseUrl = `${environment.api}/api/matchedCollectionsByPayerByMultiLevelNodePath`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<PayerMatchedCollectionsMultiLevelNodePathData>(
      baseUrl,
      postBody,
      {params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())},
    );
  }
}
