<div>
  <div [ngClass]="(filterBannerEdit$ | async) ? 'topnav' : scrollY > 0 ? 'topnav-no-header' : 'topnav-and-header'">
    <app-filter-banner [showSticky]="false"
                       [payerTabPermitted]="payerTabPermitted"
                       [lagKeyTabPermitted]="lagKeyTabPermitted"
                       [billingAreaTabPermitted]="billingAreaTabPermitted"
                       [invoiceStatusTabPermitted]="invoiceStatusTabPermitted"
                       [shouldPayersBeGranular]="shouldPayersBeGranular"
                       [visitTypeTabPermitted]="visitTypeTabPermitted"
                       [page]="page"
    ></app-filter-banner>
  <div class="dashboard-button-container"><app-metric-nav></app-metric-nav>
  </div>
  <div id="pdf-content"><app-export-pdf-dialog #pdfContent></app-export-pdf-dialog>
  </div>
  </div>
</div>
