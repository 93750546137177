import {Component, Input, OnChanges} from '@angular/core';
import {CptSummary, Legend} from '../../../../shared/models';
import {Chart} from 'angular-highcharts';
import {Options, SeriesOptionsType} from 'highcharts';
import {getDefaultCptSummary} from '../../../../evaluation-management/em-helpers';
import * as _ from 'lodash';
import {GraphColors} from '../../../../productivity-summary/colors';
import {
  ChartMarkerSymbol,
  ChartType,
  roundToNearestMultiple,
  setTickValue
} from '../../../../shared/highcharts-helpers';
import {BenchmarkOption, HighChartColorIndex} from '../../../../shared/enums';
import {getEmLegends} from '../../../../evaluation-management/em-chart-helpers';
import {extendedBenchmarkOptions, originalBenchmarkOptions} from '../../../../shared/benchmark-types';

@Component({
  selector: 'app-evaluation-management-batch-export-chart',
  templateUrl: './evaluation-management-batch-export-chart.component.html',
  styleUrls: ['./evaluation-management-batch-export-chart.component.scss']
})
export class EvaluationManagementBatchExportChartComponent implements OnChanges {
  @Input() chartClassName: string;
  @Input() chartId: string;
  @Input() categories: string[];
  @Input() chartTitle: string;
  @Input() totalCptSummaries: CptSummary[];
  @Input() specialtyCptSummaries: CptSummary[];
  @Input() maxYAxisValue?: number;
  @Input() chartHeight: number;
  @Input() chartWidth: number;
  @Input() isSingleProviderSelected: boolean;
  @Input() foundNodeName: string;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() showExtendedBenchmarkOptions: boolean;
  @Input() emBenchmarkOption: BenchmarkOption;

  chartObject: Chart;
  private GRAY_DASHED_LINE_COLOR_INDEX = HighChartColorIndex.GREY;

  private CHART_HEIGHT = 302;
  private CHART_WIDTH = 425;

  private initialOptions: Options;
  options: Options;
  legends: Legend[];

  constructor() {
  }

  ngOnChanges(): void {
    this.legends = getEmLegends(this.foundNodeName, this.isSingleProviderSelected);

    if (!this.totalCptSummaries) {
      this.totalCptSummaries = [];
      this.categories = this.categories || [];
      this.categories.forEach(x => {
        this.totalCptSummaries.push(getDefaultCptSummary());
      });
    }

    if (!this.specialtyCptSummaries) {
      this.specialtyCptSummaries = [];
      this.categories = this.categories || [];
      this.categories.forEach(x => {
        this.specialtyCptSummaries.push(getDefaultCptSummary());
      });
    }

    if (this.chartHeight && this.chartHeight > 0) {
      this.CHART_HEIGHT = this.chartHeight;
    }

    if (this.chartWidth && this.chartWidth > 0) {
      this.CHART_WIDTH = this.chartWidth;
    }

    this.options = this.optionsFor(
      this.getPercentage(),
      this.categories,
      this.chartTitle,
      this.getBenchmark(),
      this.getSpecialtyPercentage(),
      this.foundNodeName
    );

    if (!_.isEqual(this.options, this.initialOptions)) {
      this.initialOptions = this.options;
      this.chartObject = this.getChartObject();
    }
  }

  private getChartObject(): Chart {
    return new Chart(this.options);
  }

  private getBenchmark() {
    return this.totalCptSummaries.map(summary => {
      switch (this.emBenchmarkOption) {
        case BenchmarkOption.Academic:
          return summary && summary.benchmarkPercentage || 0;
        case BenchmarkOption.Community:
          return summary && summary.communityBenchmarkPercentage || 0;
        case BenchmarkOption.TelehealthAcademic:
          return summary && summary.telehealthBenchmarkPercentage || 0;
        case BenchmarkOption.TelehealthCommunity:
          return summary && summary.communityTelehealthBenchmarkPercentage || 0;
        case BenchmarkOption.InPersonAcademic:
          return summary && summary.inPersonBenchmarkPercentage || 0;
        case BenchmarkOption.InPersonCommunity:
          return summary && summary.communityInPersonBenchmarkPercentage || 0;
        default:
          return summary ? (this.viewCommunityBenchmarks ? summary.communityBenchmarkPercentage :
            summary.benchmarkPercentage) : 0;
      }
    });
  }

  private getPercentage() {
    return this.totalCptSummaries.map(summary => summary && summary.percentage || 0);
  }

  private getSpecialtyPercentage() {
    return this.specialtyCptSummaries.map(summary => summary && summary.percentage || 0);
  }

  private getSeries(data: number[], benchmarkData: number[], specialtyData?: number[], foundNodeName?: string): Array<SeriesOptionsType> {
    const encounterDistributionSeries: SeriesOptionsType = {
      name: '% Encounter Distribution',
      colorIndex: HighChartColorIndex.BLUE,
      yAxis: 0,
      data: data,
      type: ChartType.LINE,
      tooltip: {
        pointFormat: '<span class="highcharts-color-17">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 2
      },
      animation: false
    };

    const benchmarkHelperObject = this.showExtendedBenchmarkOptions ?
      extendedBenchmarkOptions.find(b => b.value === this.emBenchmarkOption)
      : originalBenchmarkOptions.find(b => b.value === this.emBenchmarkOption);
    const benchmarkName = `% CPSC ${benchmarkHelperObject?.name} Benchmark Mean`;
    const benchmarkSeries: SeriesOptionsType = {
      name: benchmarkName,
      colorIndex: this.GRAY_DASHED_LINE_COLOR_INDEX,
      yAxis: 0,
      data: benchmarkData,
      type: ChartType.LINE,
      tooltip: {
        pointFormat: '<span class="highcharts-color-' + this.GRAY_DASHED_LINE_COLOR_INDEX +
          '">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      },
      animation: false
    };

    if (!this.isSingleProviderSelected) {
      return [
        encounterDistributionSeries,
        benchmarkSeries,
      ];
    } else {
      return [
        encounterDistributionSeries,
        benchmarkSeries,
        {
          name: '% ' + foundNodeName,
          yAxis: 0,
          data: specialtyData,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.GREEN,
          marker: {
            symbol: ChartMarkerSymbol.DIAMOND
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-12">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
            valueDecimals: 0
          },
          animation: false
        },
      ];
    }
  }

  private optionsFor(data: number[], categories: string[], title: string, benchmarkData: number[],
                     specialtyData?: number[], foundNodeName?: string): Options {
    return {
      title: {
        text: title,
        align: 'left',
      },
      exporting: {
        enabled: false,
      },
      chart: {
        height: this.CHART_HEIGHT,
        width: this.CHART_WIDTH,
        styledMode: true,
        animation: false
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 0,
      },
      series: this.getSeries(data, benchmarkData, specialtyData, foundNodeName),
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground,
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        endOnTick: false,
        tickInterval: setTickValue(this.maxYAxisValue || 100),
        max: roundToNearestMultiple(this.maxYAxisValue || 100, 10),
        min: 0,
        // labels: {
        //   formatter: function () {
        //     return this.value + '%';
        //   },
        // },
      }
    };
  }
}
