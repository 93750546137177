<div id="data-table-container" [ngClass]="tableCssClass">
  <div id="em-trend-table-header">{{header}}</div>
  <mat-table [dataSource]="dataSource" [ngClass]="rangeIndex > 0 ? 'subsequent-range-table' : rangeIndex === 0 ?
   'first-range-table' : ''">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef id="{{column.columnDef}}Header" class="{{column.columnType === ColumnType.CPT_RANGE_TOTAL ?
                'total-cellHeader '+ column.class+'Header' : (column.columnType === ColumnType.CPT_CODE ?
                 'cpt-cellHeader ' + column.class+'Header' : '')}} {{column.class}}Header">
        <span
          [ngClass]="column.primaryColumn ? 'header-text header' : 'bold-header-text header'">{{column.header}}</span>
        <span *ngIf="column.aboveHeaderText" class="above-header-text-wrapper">
          <span class="above-header-text">{{column.aboveHeaderText}}</span>
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" id="{{column.columnDef}}"
                [ngClass]="column.class + ' ' + (column.columnType === ColumnType.CPT_RANGE_TOTAL ?
                'total-cell':(column.columnType === ColumnType.CPT_CODE ? 'cpt-cell' : ''))">
        <div *ngFor="let item of column.dataName(row); let i = index"
             [ngClass]="i < column.dataName(row).length - 1 ? 'item-container' : 'last-item-container'">
          <div
            class="item-text">{{item}}</div>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </ng-container>
  </mat-table>
  <app-loader *ngIf="showLoadingIndicator"></app-loader>
</div>
