import {Component, Input} from '@angular/core';
import {CptRangeViewType} from '../../../../em-helpers';

@Component({
  selector: 'app-em-trend-cpt-range-select',
  templateUrl: './em-trend-cpt-range-select.component.html',
  styleUrls: ['./em-trend-cpt-range-select.component.scss']
})
export class EmTrendCptRangeSelectComponent {
  @Input() viewType: CptRangeViewType;
  @Input() viewTypeList: CptRangeViewType[] = [];
  @Input() onViewTypeSelected: (vt: CptRangeViewType) => void;
  @Input() fromPdf = false;
  showViewTypes = false;
  constructor() { }
}
