import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {ApplicationConfigurationSetting} from '../shared/models';

export const ApplicationConfigurationServiceToken = new InjectionToken<ApplicationConfigurationService>('ApplicationConfigurationService');

export interface ApplicationConfigurationService {
  getApplicationConfigurationSettings(): Observable<ApplicationConfigurationSetting[]>;
  updateApplicationConfiguration(appConfigSetting: ApplicationConfigurationSetting): Observable<ApplicationConfigurationSetting>;
  addNewConfiguration(appConfigSetting: ApplicationConfigurationSetting): Observable<ApplicationConfigurationSetting>;
  grantAccessToConfigSettings(userEmail: string): Observable<boolean>;
}

@Injectable()
export class HttpApplicationConfigurationService implements ApplicationConfigurationService {

  constructor(private readonly httpClient: HttpClient) { }

  public getApplicationConfigurationSettings(): Observable<ApplicationConfigurationSetting[]> {
    return this.httpClient.get<ApplicationConfigurationSetting[]>(`${environment.api}/api/appConfigSettings`);
  }

  public updateApplicationConfiguration(appConfigSetting: ApplicationConfigurationSetting): Observable<ApplicationConfigurationSetting> {
    return this.httpClient.put<ApplicationConfigurationSetting>(
      `${environment.api}/api/appConfigSettings`, appConfigSetting);
  }

  public addNewConfiguration(appConfigSetting: ApplicationConfigurationSetting): Observable<ApplicationConfigurationSetting> {
    return this.httpClient.post<ApplicationConfigurationSetting>(
      `${environment.api}/api/appConfigSettings`, appConfigSetting);
  }

   grantAccessToConfigSettings(userEmail: string): Observable<boolean> {
    return  this.httpClient.get<boolean>(
      `${environment.api}/api/appConfigSettings/access?userEmail=${userEmail}`);
  }

}
