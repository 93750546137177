<div class=tile>
  <div class="title-wrapper">
    <div class=title>{{title}}</div>
  </div>
  <div class=em-sub-header>
      <span class="selectedDateRange"
            *ngIf="selectedDateRange">{{selectedDateRange}}</span>
  </div>
  <app-em-outpatient-batch-export *ngIf="showOutpatient" class="em-graph"
                                  [totalCptCounts]="totalCptCounts"
                                  [chartHeight]="250"
                                  [chartWidth]="400"
                                  [foundNodeName]="nodeName"
                                  [specialtyCptCounts]="splCptCounts"
                                  [receivedTableData]="outpatientRows"
                                  [batchMultilevelEMData]="batchMultilevelEMData"
                                  [showTable]="true"
                                  [benchmarkOption]="benchmarkOption"
  ></app-em-outpatient-batch-export>
  <app-em-inpatient-batch-export *ngIf="showInpatient"
                                 [totalCptCounts]="totalCptCounts"
                                 [chartHeight]="250"
                                 [chartWidth]="400"
                                 [foundNodeName]="nodeName"
                                 [receivedTableData]=inpatientRows
                                 class="app-percentage-chart em-graph"
                                 [specialtyCptCounts]="splCptCounts"
                                 [batchMultilevelEMData]="batchMultilevelEMData"
                                 [benchmarkOption]="benchmarkOption"
                                 [showTable]="true"
  ></app-em-inpatient-batch-export>
  <app-em-emergency-medicine-batch-export *ngIf="showEmergencyMedicine"
                                          [isSingleProviderSelected]="true"
                                          [totalCptCounts]="totalCptCounts"
                                          [chartHeight]="250"
                                          [chartWidth]="1250"
                                          [foundNodeName]="nodeName"
                                          [specialtyCptCounts]="splCptCounts"
                                          [batchMultilevelEMData]="batchMultilevelEMData"
                                          [receivedTableData]="emergencyRows"
                                          [showTable]="true"
                                          [benchmarkOption]="benchmarkOption"
                                          class="app-percentage-chart em-graph"
  ></app-em-emergency-medicine-batch-export>
  <app-em-eye-exam-batch-export *ngIf="showOphthalmology"
                                [isSingleProviderSelected]="true"
                                [totalCptCounts]="totalCptCounts"
                                [chartHeight]="250"
                                [chartWidth]="400"
                                [foundNodeName]="nodeName"
                                [batchMultilevelEMData]="batchMultilevelEMData"
                                [specialtyCptCounts]="splCptCounts"
                                [receivedTableData]="eyeExamRows"
                                [benchmarkOption]="benchmarkOption"
                                [showTable]="true"
                                class="app-percentage-chart em-graph"
  ></app-em-eye-exam-batch-export>
  <app-overview-tile-footer [fromPdfExport]="true" [metricType]="metricType"></app-overview-tile-footer>
</div>
