import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {INITIAL_STATE} from '../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FeatureToggleSetting, GmapMarker, UserProfile, ZipCodeNewPatientVisits} from '../../../shared/models';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {isFeatureEnabled} from '../../../shared/helpers';
import {FeatureToggleEntries} from '../../../shared/feature-toggle-settings-enum';

@Component({
  selector: 'app-zip-code-overview-tile',
  templateUrl: './zip-code-overview-tile.component.html',
  styleUrls: ['./zip-code-overview-tile.component.scss']
})
export class ZipCodeOverviewTileComponent implements OnInit, OnDestroy, AfterViewInit {

  showProgressBar = false;
  zipCodeNewPatientVisits: ZipCodeNewPatientVisits[] = [];
  gmapMarkerData: GmapMarker[] = [];
  level: string;
  tableTitle = 'Top New Patient Visit Zip Codes';
  zipCodeColumns: any[] = [
    {
      columnDef: 'id', header: '#',
      dataName: (row: any) => `${(row != null && row.id != null) ? row.id.toString() : ''}`
    },
    {
      columnDef: 'zipCode', header: 'Zip Code',
      dataName: (row: any) => `${(row != null && row.zipCode != null) ? row.zipCode.toString() : ''}`
    },
    {
      columnDef: 'newPatientVisitPercentage', header: '% New Patient Visits',
      dataName: (row: any) => `${(row != null && row.newPatientVisitPercentage != null)
        ? row.newPatientVisitPercentage.toString() : ''}`
    },
    {
      columnDef: 'newPatientVisitCount', header: '# of New Patients',
      dataName: (row: any) => `${(row != null && row.newPatientVisitCount != null) ?
        row.newPatientVisitCount.toString() : ''}`
    },
    {
      columnDef: 'totalPatientVisitCount', header: '# of Total Patients',
      dataName: (row: any) => `${(row != null && row.totalPatientVisitCount != null) ?
        row.totalPatientVisitCount.toString() : ''}`
    }
  ];
  canShowBubbleIndicators = false;

  @select(['data', 'zipCodeNewPatientVisits'])
  private readonly zipCodeNewPatientVisits$: Observable<ZipCodeNewPatientVisits[]>;

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @select(['data', 'userProfile'])
  private readonly userProfile$: Observable<UserProfile>;

  @Input() fromPdfExport = false;
  private dataSubscription: Subscription;
  private featureToggleSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.dataSubscription = this.zipCodeNewPatientVisits$.pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2)))
      .subscribe((zipCodeNewPatientVisits: ZipCodeNewPatientVisits[]) => {
        this.zipCodeNewPatientVisits = _.take(zipCodeNewPatientVisits, 10);
        this.gmapMarkerData = [];
        this.level = '';
        let index = 1;
        this.zipCodeNewPatientVisits.forEach(x => {
          this.gmapMarkerData.push({
            id: index ++,
            lat: +x.lat,
            lng: +x.lng,
            zipCode: x.zipCode,
            newPatientVisitCount: x.newPatientVisitCount,
            newPatientVisitPercentage: x.newPatientVisitPercentage + '%',
            totalPatientVisitCount: x.totalPatientVisitCount
          });
        });
        this.showProgressBar = _.isEqual(zipCodeNewPatientVisits, INITIAL_STATE.data.zipCodeNewPatientVisits);
        this.cdr.detectChanges();
      });
    this.featureToggleSubscription = combineLatest([this.featureToggleSettings$, this.userProfile$])
      .subscribe(
      ([featureToggleSettings, userProfile]: [FeatureToggleSetting[], UserProfile]) => {
        this.canShowBubbleIndicators = isFeatureEnabled(FeatureToggleEntries.ZIP_CODE_BUBBLES, featureToggleSettings,
          userProfile);
      });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
    this.featureToggleSubscription?.unsubscribe();
  }
}
