<app-tab-navigation
  [tabs]="tabs"
  [chosenTab]="chosenTab"
  [tabUpdateAction]="multilevelTabChangedTo"
></app-tab-navigation>

<div [ngSwitch]="chosenTab">
  <div *ngSwitchCase="MultilevelTab.BY_DEPARTMENT">
    <app-wrvu-multilevel-department-page
      [wrvuSnapshotData]="departmentSnapshot"
      [benchmarkPercentile]="benchmarkPercentile"
      [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      [nodePath]="nodePath"
      [zeroSuppression]="zeroSuppression"
      [countOfSuppressedEntries]="countOfSuppressedDeptEntries"
      [columns]="departmentColumns"
      [activeVarianceToggle]="activeVarianceToggle"
      [wRVUtype]="wRVUtype"
      [selectionCounts]="selectionCounts"
      [varianceToggleSortingCriterion]="varianceToggleSortingCriterion"
      [varianceKey]="varianceKey"
      [variables]="variables"
      [defaultSortColumn]="defaultSortColumn"
      [sortDirection]="sortDirection"
      [updateSortingCriteria]="updateSortingCriteria"
      [zeroSuppressionCondition]="zeroSuppressionCondition"
      [reducerAction]="reducerAction"
      [displayedColumns]="displayedDepartmentColumns"
    ></app-wrvu-multilevel-department-page>
  </div>
  <div *ngSwitchCase="MultilevelTab.BY_SPECIALTY">
    <app-wrvu-multilevel-specialty-page
      [wrvuSnapshotData]="specialtySnapshot"
      [benchmarkPercentile]="benchmarkPercentile"
      [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      [nodePath]="nodePath"
      [zeroSuppression]="zeroSuppression"
      [countOfSuppressedEntries]="countOfSuppressedSpecEntries"
      [columns]="specialtyColumns"
      [activeVarianceToggle]="activeVarianceToggle"
      [wRVUtype]="wRVUtype"
      [selectionCounts]="selectionCounts"
      [varianceToggleSortingCriterion]="varianceToggleSortingCriterion"
      [varianceKey]="varianceKey"
      [variables]="variables"
      [defaultSortColumn]="defaultSortColumn"
      [sortDirection]="sortDirection"
      [updateSortingCriteria]="updateSortingCriteria"
      [zeroSuppressionCondition]="zeroSuppressionCondition"
      [reducerAction]="reducerAction"
      [displayedColumns]="displayedSpecialtyColumns"
    ></app-wrvu-multilevel-specialty-page>
  </div>
  <div *ngSwitchCase="MultilevelTab.BY_PROVIDER">
    <app-wrvu-multilevel-provider-page
      [isSpecialtyPerformance]="false"
      [wrvuSnapshotData]="providerSnapshot"
      [benchmarkPercentile]="benchmarkPercentile"
      [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      [nodePath]="nodePath"
      [zeroSuppression]="zeroSuppression"
      [countOfSuppressedEntries]="countOfSuppressedProviderEntries"
      [columns]="providerColumns"
      [activeVarianceToggle]="activeVarianceToggle"
      [wRVUtype]="wRVUtype"
      [selectionCounts]="selectionCounts"
      [varianceToggleSortingCriterion]="varianceToggleSortingCriterion"
      [varianceKey]="varianceKey"
      [variables]="variables"
      [defaultSortColumn]="defaultSortColumn"
      [sortDirection]="sortDirection"
      [updateSortingCriteria]="updateSortingCriteria"
      [zeroSuppressionCondition]="zeroSuppressionCondition"
      [reducerAction]="reducerActionProvider"
      [displayedColumns]="displayedProviderColumns"
    ></app-wrvu-multilevel-provider-page>
  </div>
  <div *ngSwitchCase="MultilevelTab.SPECIALTY_PERFORMANCE">
    <app-wrvu-multilevel-provider-page
      [isSpecialtyPerformance]="true"
      [wrvuSnapshotData]="specialtyPerformanceSnapshot"
      [benchmarkPercentile]="benchmarkPercentile"
      [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      [nodePath]="nodePath"
      [zeroSuppression]="zeroSuppression"
      [countOfSuppressedEntries]="countOfSuppressedSpecPerformanceEntries"
      [columns]="specialtyPerformanceColumns"
      [activeVarianceToggle]="activeVarianceToggle"
      [wRVUtype]="wRVUtype"
      [selectionCounts]="selectionCounts"
      [varianceToggleSortingCriterion]="varianceToggleSortingCriterion"
      [varianceKey]="varianceKey"
      [variables]="variables"
      [defaultSortColumn]="defaultSortColumn"
      [sortDirection]="sortDirection"
      [updateSortingCriteria]="updateSortingCriteria"
      [zeroSuppressionCondition]="zeroSuppressionCondition"
      [reducerAction]="reducerActionSpecialtyPerformance"
      [displayedColumns]="displayedSpecialtyPerformanceColumns"
    ></app-wrvu-multilevel-provider-page>
  </div>
  <div *ngSwitchCase="MultilevelTab.TREND">
    <app-productivity-month-page
      [wrvuViewType] = "wRVUtype"
    ></app-productivity-month-page>
  </div>
</div>
