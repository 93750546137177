<app-tab-navigation
  [tabs]="tabs"
  [chosenTab]="chosenTab"
  [tabUpdateAction]="multilevelTabChangedTo"
></app-tab-navigation>
<app-tab-navigation
  [tabs]="cptTabs"
  [isClinicalTab]="true"
  [chosenTab]="cptViewType"
  [tabUpdateAction]="cptViewTypeChangedTo"
></app-tab-navigation>
<span class="cfp-head-wrapper">
  <span class="cpt-head-inner-wrapper">
    <span id="left-side-of-head">
      <i class="fa fa-arrow-left" *ngIf="cptDrills"
         (click)="drillBack()" id="drill-back"> </i>
      <span class="show-drill-text" *ngIf="cptDrills">{{drillText}}</span>
    </span>
  </span>
</span>
<app-procedure-summary-graph [cptViewType]="cptViewType" [cfpVariableViewType]="cfpVariableViewType"
                             [showAdditionalCFPColumns]="showAdditionalCFPColumns" (drillInto)="drillIntoCpt($event)"
                             [cfpData]="cfpDataForSelectedDimension" [showProgressBar]="showProgressBar"
                             [currentNodePath]="currentNodePath" [multilevelTab]="chosenTab"
                             [viewCommunityBenchmarks]="viewCommunityBenchmarks"
                             [procedureSummaryVariables]="procedureSummaryVariables"
                             [hideCfteWrvu]="!showCfteWrvus"
                             [hideCfteCount]="!showCfteCount">
</app-procedure-summary-graph>
<app-summary-procedure-summary [receivedData]="cfpDataForSelectedDimension"
                               [cptViewType]="cptViewType"
                               [viewCommunityBenchmarks]="viewCommunityBenchmarks"
                               [showProgressBar]="showProgressBar"
                               [showCfteWrvus]="showCfteWrvus"
                               [showCfteCount]="showCfteCount">
</app-summary-procedure-summary>
<div id="cpt-{{cptViewTypeLabel.toLowerCase()}}">
  <app-cfp-multilevel-table id="cpt-{{cptViewTypeLabel.toLowerCase()}}-clinical-data-table"
                            tableTitle="Procedure Summary by CPT {{cptViewTypeLabel}}"
                            [receivedData]="cfpDataForSelectedDimension"
                            [columns]="columns" [originalCfpDataForSearchModal]="originalCfpDataForSearchModal"
                            [cptViewType]="cptViewType"
                            [tab]="chosenTab" [cptGroups]="cptGroupings"
                            [selectedGroup]="selectedGroup"
                            (drillInto)="drillIntoCpt($event)"
                            [showProgressBar]="showProgressBar">
  </app-cfp-multilevel-table>
</div>
