import {ClinicalSummaryCode} from '../ClinicalSummary';
import {ManageableGroup} from '../../updated-filter-banner/manage-groups-helper';
import {SelectableItem} from '../../updated-filter-banner/FilterBannerModels';
import {CptViewType} from '../../shared/enums';

export interface CptGroup extends ManageableGroup {
  emailId: string;
  entries: CptEntry[];
  breadcrumb: string;
}

export interface CptEntry {
  cptDesc: string;
  cptViewType: number;
}

export interface CptMappingMaker {
  familyDesc: string;
  rangeDesc: string;
  code: string;
}

export function getCptGroupFromSelectedAndUnselectedCodes(selectedCodes: SelectableItem<ClinicalSummaryCode>[],
                                                          unselectedCodes: SelectableItem<ClinicalSummaryCode>[],
                                                          email: string, memberKey: number,
                                                          groupName: string, isDefault: boolean, applicableRangeDescs: string[],
                                                          existingGroup?: CptGroup): CptGroup {
  if (existingGroup) {
    return existingGroup;
  }
  const selectedTuple = selectedCodes.map(a => [a.item.cptFamilyDesc, a.item.cptRangeDesc,
    a.item.cptCode]);
  const unselectedTuple = unselectedCodes.map(c => [c.item.cptFamilyDesc,
    c.item.cptRangeDesc, c.item.cptCode]);
  const wholeFamilyMapper: CptMappingMaker[] = selectedTuple.filter(b => !unselectedTuple.find(d => d[0] === b[0])).map(e => {
    return {
      familyDesc: e[0], rangeDesc: e[1], code: e[2]
    };
  });
  const wholeRangeMapper: CptMappingMaker[] = selectedTuple.filter(b => !unselectedTuple.find(d => d[1] === b[1]) &&
    !wholeFamilyMapper.map(e => e.rangeDesc).includes(b[1])).map(f => {
    return {
      familyDesc: f[0], rangeDesc: f[1], code: f[2]
    };
  });
  const wholeCodeMapper: CptMappingMaker[] = selectedTuple.filter(b => !unselectedTuple.find(d => d[2] === b[2]) &&
    !wholeFamilyMapper.map(e => e.code).includes(b[2]) && !wholeRangeMapper.map(f => f.code).includes(b[2])).map(g => {
    return {
      familyDesc: g[0], rangeDesc: g[1], code: g[2]
    };
  });
  const distinctFamilies = wholeFamilyMapper.reduce((a: any, b) => {
    a[b.familyDesc] = a[b.familyDesc] ? a[b.familyDesc] : {
      cptDesc: b.familyDesc, cptViewType: CptViewType.CptFamily
    };
    return a;
  }, {});
  const wholeFamilies: CptEntry[] = [];
  for (const key in distinctFamilies) {
    if (distinctFamilies[key]) {
      wholeFamilies.push({cptDesc: key, cptViewType: CptViewType.CptFamily});
    }
  }
  const distinctRanges = wholeRangeMapper.reduce((a: any, b) => {
    a[b.rangeDesc] = a[b.rangeDesc] ? a[b.rangeDesc] : {
      cptDesc: b.rangeDesc, cptViewType: CptViewType.CptRange
    };
    return a;
  }, {});
  const wholeRanges: CptEntry[] = [];
  for (const key in distinctRanges) {
    if (distinctRanges[key]) {
      wholeRanges.push({cptDesc: key, cptViewType: CptViewType.CptRange});
    }
  }
  const distinctCodes = wholeCodeMapper.reduce((a: any, b) => {
    a[b.code] = a[b.code] ? a[b.code] : {
      cptDesc: b.code, cptViewType: CptViewType.CptCode
    };
    return a;
  }, {});
  const wholeCodes: CptEntry[] = [];
  for (const key in distinctCodes) {
    if (distinctCodes[key]) {
      wholeCodes.push({cptDesc: key, cptViewType: CptViewType.CptCode});
    }
  }
  const excludedFamilies = new Set(unselectedCodes.map(c => c.item.cptFamilyDesc));
  const excludedRanges = new Set(unselectedCodes.map(c => c.item.cptRangeDesc));
  const excludedCodes = new Set(unselectedCodes.map(c => c.item.cptCode));
  const includedFamilies = new Set(selectedCodes.map(c => c.item.cptFamilyDesc));
  const includedRanges = new Set(selectedCodes.map(c => `${c.item.cptRangeDesc} ${c.item.cptRangeLow} - ${c.item.cptRangeHigh}`));
  const includedCodes = new Set(selectedCodes.map(c => c.item.cptCode));
  const familyBreadcrumb = !(includedFamilies.size * excludedFamilies.size) ? 'All Families' : includedFamilies.size === 1
    ? includedFamilies.values().next().value : `${includedFamilies.size} Families`;
  const rangeBreadcrumb = !(includedRanges.size * excludedRanges.size) ? 'All Ranges' : includedRanges.size === 1
    ? includedRanges.values().next().value : `${includedRanges.size} Ranges`;
  const codeBreadcrumb = !(includedCodes.size * excludedCodes.size) ? 'All Codes' : includedCodes.size === 1
    ? includedCodes.values().next().value : `${includedCodes.size} Codes`;
  return {
    id: -1, emailId: email, breadcrumb: `${familyBreadcrumb} / ${rangeBreadcrumb} / ${codeBreadcrumb}`,
    memberKey: memberKey, name: groupName, isDefault: isDefault,
    entries: wholeFamilies.concat(wholeRanges).concat(wholeCodes)};
}
