<span id="title">Multi Provider Export</span>
<span id="heading">
  <span id="note">Data is based on selected filtered criteria</span>
  <span *ngIf="!somethingSelected" id="selection-error-message">
      Please make at least 1 selection to generate a report.</span>
</span>
<span id="option-selections">
  <span id="{{overviewId}}">
    <app-mpe-hierarchical-selector
      [hierarchyOptions]="overviewMetricSection.options"
      [isOverView]="true"
      [topOptions]="[overviewMetricSection.options[0]]"
      [isFirstOption]="true"
      [canShowBenchmarkSelector]="canShowBenchmarkSelector"
      [whenHierarchicalOptionSelected]="onHierarchicalOptionSelected"
      [batchExportMetricSection]="overviewMetricSection"
      [overviewSubMetricSections]="overviewSubMetricSections"
      (whenBenchmarkOptionChosen)="chooseBenchmarkOption($event, overviewMetricSection.benchmarkCombo)"
      (whenPercentileChosen)="choosePercentile($event, overviewMetricSection?.benchmarkCombo || undefined)">
    </app-mpe-hierarchical-selector>
  </span>
  <span *ngFor="let metric of displayedBatchExportMetricSections">
    <app-mpe-hierarchical-selector id="{{metric.id}}"
                                            [showSubMetricBenchmarkSelectors]="true"
                                            [hierarchyOptions]="metric.options"
                                            [topOptions]="[metric.options[0]]"
                                            [isFirstOption]="true"
                                            [canShowBenchmarkSelector]="canShowBenchmarkSelector"
                                            [whenHierarchicalOptionSelected]="onHierarchicalOptionSelected"
                                            [batchExportMetricSection]="metric"
                                            (whenBenchmarkOptionChosen)="chooseBenchmarkOption($event, metric.benchmarkCombo)"
                                            (whenPercentileChosen)="choosePercentile($event, metric.benchmarkCombo)">
    </app-mpe-hierarchical-selector>
  </span>
</span>
