import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { matRangeDatepickerRangeValue } from './datepicker';
import { DateAdapter } from './datetime';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-viz-date-range-picker',
  templateUrl: './viz-date-range-picker.component.html',
  styleUrls: ['./viz-date-range-picker.component.scss'],
})
export class VizDateRangePickerComponent implements OnInit, AfterViewInit {
  @Input() placeholder: string;
  @Input() filterBannerFlag = false;
  open: boolean;

  @Input()
  date: matRangeDatepickerRangeValue<Date>;

  lastDateInput: matRangeDatepickerRangeValue<Date> | null;
  lastDateChange: matRangeDatepickerRangeValue<Date> | null;
  dateShot: matRangeDatepickerRangeValue<Date> | null;

  @Input()  typeMode: string;
  @Output() apply = new EventEmitter<any>();
  @Output() dateChange = new EventEmitter<any>();
  @Output() dateInput = new EventEmitter<any>();

  constructor(
    private _dateAdapter: DateAdapter<Date>,
  ) {}

  private _rangeMode = true;

  get rangeMode(): boolean {
    return this._rangeMode;
  }

  @Input()
  set rangeMode(value: boolean) {
    this._rangeMode = coerceBooleanProperty(value);
  }

  public form: UntypedFormGroup = new UntypedFormGroup({
    date: new UntypedFormControl(''),
  });

  ngOnInit() {
    this.dateShot = this.date;
    this.form.setValue({date: this.date});
  }

  ngAfterViewInit() {
  }

  onInputDate($event: any) {
    this.date = $event;
  }

  onClose(event: any) {
    this.open = false;
    this.apply.emit(this.date);
    if (!this.dateShot || !this.date) { return; }
    if (
      this._dateAdapter.sameDate(this.dateShot.begin, this.date.begin)
      &&
      this._dateAdapter.sameDate(this.dateShot.end, this.date.end)
    ) {

    } else {
      this.dateShot = this.date;
      this.apply.emit(this.date);
    }
  }
}
