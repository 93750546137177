export enum CptViewType {
  CptFamily = 0,
  CptRange = 1,
  CptCode = 2
}

export enum NodeExpansionState {
  EXPANDED = 0,
  COLLAPSED = 1,
  LEAF = 2,
  LEAF_ACTIVE = 3
}

export enum FilterBannerTab {
  PROVIDER = -1,
  DATE = 0,
  LOCATION = 1,
  PAYER = 2,
  BILLING = 3,
  LAG = 4,
  INVOICE_STATUS = 5,
  VISIT_TYPE = 6,
  MULTI_LOCATION = 7
}

export enum OntologyLevel {
  AllDepartments = 0,
  Department = 1,
  Specialty = 2,
  Provider = 3
}

export enum ColumnType {
  NONE = -1,
  BENCHMARK = 0,
  VARIANCE = 1,
  IMPUTED = 2,
  CHARGES = 3,
  CFTE = 4,
  PRIMARY_DATE = 5,
  NODE_NAME = 6,
  PREVIOUS = 7,
  DIFFERENCE = 8,
  PAYER = 9,
  OPEN_WINDOW = 10,
  WRVU = 11,
  PERCENTILE = 12,
  DENIED_PAID_BENCHMARK = 13,
  CPT_CODE = 14,
  CPT_RANGE_TOTAL = 15,
  FREQUENCY_BENCHMARK = 16,
  TERMINAL_DENIAL_BENCHMARK = 17,
  METRICS = 18,
  CFTE_ADJ_WRVU = 19,
  LOCATION = 20,
  AGGREGATION = 21,
  CANCEL,
  DELETE,
  REPORT_NAME,
  TABLE_MODIFICATION
}

export enum PayerType {
  ALL = 0,
  CATEGORY = 1,
  NATIONAL = 2,
  MEMBER = 3
}

export enum PDFExportView {
  OVERVIEW_HEADER = 0,
  OVERVIEW_WRVU_NPV = 1,
  OVERVIEW_EM = 2,
  OVERVIEW_ZIP = 3,
}

export enum SortingOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum FilterCriteriaTableView {
  ALL = 0,
  SELECTED = 1,
  NONSELECTED = 2
}

export enum MultilevelTab {
  NONE = -1,
  BY_DEPARTMENT = 0,
  BY_SPECIALTY = 1,
  BY_PROVIDER = 2,
  TREND = 3,
  SPECIALTY_PERFORMANCE = 4,
  LOCATION_DEPARTMENT = 5,
  LOCATION_SPECIALTY = 6,
  LOCATION_PROVIDER = 7
}

export enum MultilevelTabCollections {
  SUMMARY = 0,
  BY_DEPARTMENT = 1,
  BY_SPECIALTY = 2,
  BY_PROVIDER = 3,
  BY_PAYER = 4,
  BY_PAYER_BY_DEPARTMENT = 5,
  BY_PAYER_BY_SPECIALTY = 6,
  BY_PAYER_BY_PROVIDER = 7
}

export enum DenialsTabs {
  DENIAL_RATE = 0,
  TOP_TEN_DENIED_REASONS = 1,
  TOP_TEN_CPTS = 2,
}

export enum DenialReasonTab {
  BY_DENIAL_RATE = 0,
  BY_CPT_CODES_DENIED = 1,
  BY_CHARGES = 2,
}

export enum TopCPTsTab {
  BY_DENIAL_RATE = 0,
  BY_CPT_CODES_DENIED = 1,
  BY_CHARGES = 2,
}

export enum BatchExportStatus {
  SUBMITTED = 0,
  PROCESSING = 1,
  COMPLETED = 2,
  FAILED = 3,
  ACKNOWLEDGED = 4
}

export enum MetricLoadKeys {
  IS_NPV_ZIP_LOADED = 'is_npv_zip_loaded',
  IS_WRVU_SNAPSHOT_LOADED = 'is_wrvu_snapshot_loaded',
  IS_WRVU_TREND_LOADED = 'is_wrvu_trend_loaded',
  IS_WRVU_SUMMARY_LOADED = 'is_wrvu_summary_loaded',
  IS_NPV_TREND_LOADED = 'is_npv_trend_loaded',
  IS_NPV_LOCATION_LOADED = 'is_npv_location_loaded',
  IS_NPV_SNAPSHOT_LOADED = 'is_npv_snapshot_loaded',
  IS_NPV_SUMMARY_LOADED = 'is_npv_summary_loaded',
  IS_EM_SNAPSHOT_LOADED = 'is_em_snapshot_loaded',
  IS_CFP_LOADED = 'is_cfp_loaded',
  IS_CFP_MULTILEVEL_LOADED = 'is_cfp_multilevel_loaded',
  IS_COLLECTION_SNAPSHOT_LOADED = 'is_collection_snapshot_loaded',
  IS_COLLECTION_PAYER_LOADED = 'is_collection_payer_loaded',
  IS_COLLECTION_PAYERBYLEVEL_LOADED = 'is_collection_payerbylevel_loaded',
  IS_COLLECTION_SUMMARY_LOADED = 'is_collection_summary_loaded',
  IS_DENIALS_LOADED = 'is_denials_loaded',
  IS_DENIALS_PAYER_LOADED = 'is_denials_payer_loaded',
}

export enum DateRangeOption {
  Past12Months = 0,
  YearToDate = 2,
  RecentMonth = 4,
  Past12MonthsOfData = 6,
  FiscalYearToDate = 8,
  FiscalYearQ1 = 10,
  FiscalYearQ2 = 11,
  FiscalYearQ3 = 12,
  FiscalYearQ4 = 13,
}

export enum DefaultPage {
  Overview = 0,
  Wrvus = 1,
  NPV = 2,
  EandM = 3,
  CFP = 4,
  Collections = 5,
  Denials = 6
}

export enum DatasetType {
  DEPARTMENT_ONLY = 1,
  DEPARTMENT_SPECIALTY = 2,
  ALL = 3
}

export enum FrontEndTelehealthFlag {
  ALL_VISIT_TYPES = 0,
  IN_PERSON = 1,
  TELEHEALTH = 2
}

export enum BackEndTelehealthFlag {
  // all visit types is null, which is not a valid value for an enum
  IN_PERSON = 0,
  TELEHEALTH = 1
}

export enum BenchmarkOption {
  Academic = 1,
  Community = 2,
  TelehealthAcademic = 3,
  TelehealthCommunity = 4,
  InPersonAcademic = 5,
  InPersonCommunity = 6
}

export enum LegendColor {
  TEAL = 'teal',
  PURPLE = 'purple',
  GREEN = 'green',
  GREY = 'grey',
  RED = 'red',
  BLUE = 'blue',
  ORANGE = 'orange'
}

export enum LegendStyle {
  SQUARE = 'square',
  CIRCLE = 'circle',
  DIAMOND = 'diamond',
}

export enum HighChartColorIndex {
  TRANSPARENT = 0,
  GREY = 2,
  TEAL = 10,
  GREEN = 12,
  PURPLE = 13,
  LIGHT_PURPLE = 14,
  ORANGE = 15,
  LIGHT_ORANGE = 16,
  BLUE = 17,
  RED = 22,
  DARK_BLUE_STACK = 51,
  LIGHT_BLUE_STACK = 52,
  LIGHT_ORANGE_STACK = 53,
  MEDIUM_ORANGE_STACK = 54,
  DARK_ORANGE_STACK = 55,
  WHITE = 56,
}

export enum GoogleAnalyticCategories {
  FILTERS = 'Filters',
  MultiProviderExport = 'Multi Provider Export',
  MyReports = 'MyReports',
  ProcedureSummarySnapshot = 'Procedure Summary Snapshot',
  ExportData = 'Export Data'
}

export enum GoogleAnalyticsActions {
  CustomGroup = 'Custom Groupings',
  MultiProviderExports = 'Multi-Provider Exports',
  ExportDownload = 'Export Download'
}

export enum GoogleAnalyticsLabels {
  Groupings = 'Groupings',
  MyReportsClick = 'MyReports Click',
  ExportClick = 'Export Click',
  CptDrilldown = 'CPT Drilldown',
  MultiProviderExport = 'Multi Provider Export'
}
