import {Injectable, InjectionToken} from '@angular/core';
import {FilterCriteria} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.local';
import {HttpClient, HttpParams} from '@angular/common/http';
import {getRequestBodyFromFilter, SummaryType} from '../../shared/helpers';
import {MonthNewPatientVisitData} from '../components/npv-models';

export const MonthNewPatientVisitServiceToken =
  new InjectionToken<MonthNewPatientVisitService>('Month New Patient Visit Service');

export interface MonthNewPatientVisitService {
  getMonthNewPatientVisitData(filter: FilterCriteria, summaryType?: SummaryType): Observable<MonthNewPatientVisitData>;
}

@Injectable()
export class HttpMonthNewPatientVisitService implements MonthNewPatientVisitService {
  constructor(private httpClient: HttpClient) {
  }

  getMonthNewPatientVisitData(filter: FilterCriteria, summaryType: SummaryType = SummaryType.SELECTED_PERIOD):
    Observable<MonthNewPatientVisitData> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/monthNewPatientVisitsByNodePath`;
    return this.httpClient.post<MonthNewPatientVisitData>(
      url,
      requestBody,
      {
        params: new HttpParams()
          .set('memberKey', filter.memberKey.toString())
          .set('npvViewType', summaryType.toString())
      }
    );
  }
}
