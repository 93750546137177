import {MergedNewPatientVisitSnapshotEntry, NewPatientVisitTrendEntry} from '../../new-patient-visits/components/npv-models';
import {NewPatientVisitsBase} from '../../new-patient-visits/components/npv-models';
import {BaseColumn, SummaryData} from '../../store/IAppState';
import {MonthNewPatientVisitsExport, NewPatientVisitSummary} from '../models';
import {getDifferencePercentage, getGroupName} from '../helpers';
import {BenchmarkOption, ColumnType} from '../enums';
import {MultilevelTab} from '../enums';
import {Export} from './export';
import {
  formatNumberToWholeNumber,
  formatToPercentageOrElseNull,
  roundTo,
  roundToWithPercentage,
  roundToWithPercentageOrElseNull
} from '../../productivity-summary/number-formatter';
import {
  BenchmarkPercentile,
  extendedBenchmarkOptions,
  originalBenchmarkOptions,
  readableNameOf,
  readableNameOfColumnDef
} from '../benchmark-types';
import {checkForNulls, undefinedIfZero} from '../null-helpers';
import {getDesignatedNpvBenchmarkObject, getDesignatedNpvVarianceObject} from '../../new-patient-visits/components/npv-helpers';
import {toMonthName} from '../../productivity-summary/month-formatter';
import {aBlankMonthNewPatientVisitsExport} from '../test/helper-functions.spec';
import {getBenchmarkFromTypeForNpvSnapshot, getVarianceFromTypeForNpvSnapshot} from './export-helper';

export function getNpvSnapshotCsvExportRow<T extends NewPatientVisitsBase>(
  data: T,
  tab: MultilevelTab,
  nodeData: {
    departmentNodeName?: string,
    specialtyNodeName?: string,
    providerNodeName?: string,
    memberLocationName?: string,
    nodeName: string
  },
  viewCommunityBenchmarks: boolean,
  benchmarkOption: BenchmarkOption,
  benchmarkExtractor: (datum: T, type: BenchmarkPercentile, viewCommunity: boolean, option: BenchmarkOption) => string,
  varianceExtractor?: (datum: T, type: BenchmarkPercentile, viewCommunity: boolean, option: BenchmarkOption) => string) {

  const getBenchmark = (percentile: BenchmarkPercentile) => benchmarkExtractor(data, percentile, viewCommunityBenchmarks, benchmarkOption);
  const getVariance = (percentile: BenchmarkPercentile) => varianceExtractor
    ? varianceExtractor(data, percentile, viewCommunityBenchmarks, benchmarkOption)
    : '-';

  let levelObject: any;
  switch (tab) {
    case MultilevelTab.BY_DEPARTMENT:
      levelObject = {
        departmentNodeName: nodeData.departmentNodeName || nodeData.nodeName
      };
      break;
    case MultilevelTab.BY_SPECIALTY:
      levelObject = {
        specialtyNodeName: nodeData.specialtyNodeName || nodeData.nodeName,
        departmentNodeName: nodeData.departmentNodeName || ' '
      };
      break;
    case MultilevelTab.BY_PROVIDER:
      levelObject = {
        providerNodeName: nodeData.providerNodeName || nodeData.nodeName,
        specialtyNodeName: nodeData.specialtyNodeName || ' ',
        departmentNodeName: nodeData.departmentNodeName || ' '
      };
      break;
    case MultilevelTab.LOCATION_DEPARTMENT:
    case MultilevelTab.LOCATION_SPECIALTY:
    case MultilevelTab.LOCATION_PROVIDER:
      levelObject = {
        memberLocationName: nodeData.memberLocationName || nodeData.nodeName,
        providerNodeName: nodeData.providerNodeName || ' ',
        specialtyNodeName: nodeData.specialtyNodeName || ' ',
        departmentNodeName: nodeData.departmentNodeName || ' '
      };
      break;
    default:
      levelObject = {
        nodeName: nodeData.nodeName
      };
      break;
  }

  levelObject = {
    ...levelObject,
    newPatientVisitsPercentage: roundToWithPercentage(data.newPatientVisitsPercentage, 1),
    countOfNewPatientVisits: formatNumberToWholeNumber(data.countOfNewPatientVisits),
    countOfTotalPatientVisits: formatNumberToWholeNumber(data.countOfTotalPatientVisits),
    benchmarkMean: getBenchmark(BenchmarkPercentile.Mean),
    benchmark25thPercentile: getBenchmark(BenchmarkPercentile.Percentile25th),
    benchmark50thPercentile: getBenchmark(BenchmarkPercentile.Percentile50th),
    benchmark75thPercentile: getBenchmark(BenchmarkPercentile.Percentile75th),
    benchmark90thPercentile: getBenchmark(BenchmarkPercentile.Percentile90th),
    varianceMean: getVariance(BenchmarkPercentile.Mean),
    variance25thPercentile: getVariance(BenchmarkPercentile.Percentile25th),
    variance50thPercentile: getVariance(BenchmarkPercentile.Percentile50th),
    variance75thPercentile: getVariance(BenchmarkPercentile.Percentile75th),
    variance90thPercentile: getVariance(BenchmarkPercentile.Percentile90th)
  };

  return levelObject;
}

export function getNpvSnapshotCsvExportSummaryData(summaryData: SummaryData<NewPatientVisitSummary>,
                                                   viewCommunityBenchmarks: boolean,
                                                   levelTab: MultilevelTab,
                                                   benchmarkOption: BenchmarkOption
): any[] {
  const result: any[] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      const summaryDataForCurrentDateRange = summaryData[dateRange];
      const groupName = getGroupName(summaryDataForCurrentDateRange, dateRange);
      let levelObject = getNpvSnapshotCsvExportRow(
        summaryDataForCurrentDateRange,
        levelTab,
        {nodeName: groupName},
        viewCommunityBenchmarks,
        benchmarkOption,
        getBenchMarkSummaryPercentileForExport
      );
      levelObject = {
        ...levelObject,
        previousNewPatientVisitsPercentage: '-',
        difference: '-'
      };
      result.push(levelObject);
    }
  }
  result.push({
    nodeName: ' ',
    memberLocationName: ' ',
    departmentNodeName: ' ',
    specialtyNodeName: ' ',
    providerNodeName: ' ',
    newPatientVisitsPercentage: ' ',
    countOfNewPatientVisits: ' ',
    countOfTotalPatientVisits: ' ',
    benchmarkMean: ' ',
    benchmark25thPercentile: ' ',
    benchmark50thPercentile: ' ',
    benchmark75thPercentile: ' ',
    benchmark90thPercentile: ' ',
    varianceMean: ' ',
    variance25thPercentile: ' ',
    variance50thPercentile: ' ',
    variance75thPercentile: ' ',
    variance90thPercentile: ' ',
    previousNewPatientVisitsPercentage: ' ',
    difference: ' '
  });
  return result;
}

export function getAcademicPercentileFromNpvSummary(benchmarkPercentile: BenchmarkPercentile, monthData: NewPatientVisitSummary) {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th :
      return monthData.per25th;
    case BenchmarkPercentile.Percentile50th:
      return monthData.per50th;
    case BenchmarkPercentile.Percentile75th:
      return monthData.per75th;
    case BenchmarkPercentile.Percentile90th:
      return monthData.per90th;
    case BenchmarkPercentile.Mean:
      return monthData.mean;
    default:
      return 0;
  }
}

export function getCommunityPercentileFromNpvSummary(benchmarkPercentile: BenchmarkPercentile, monthData: NewPatientVisitSummary) {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th:
      return monthData.communityPer25th;
    case BenchmarkPercentile.Percentile50th:
      return monthData.communityPer50th;
    case BenchmarkPercentile.Percentile75th:
      return monthData.communityPer75th;
    case BenchmarkPercentile.Percentile90th:
      return monthData.communityPer90th;
    case BenchmarkPercentile.Mean:
      return monthData.communityMean;
    case BenchmarkPercentile.Percentile65th:
      return monthData.communityPer65th;
    default:
      return 0;
  }
}

export function getTelehealthAcademicPercentileFromNpvSummary(benchmarkPercentile: BenchmarkPercentile, monthData: NewPatientVisitSummary) {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th:
      return monthData.telehealthPer25th;
    case BenchmarkPercentile.Percentile50th:
      return monthData.telehealthPer50th;
    case BenchmarkPercentile.Percentile75th:
      return monthData.telehealthPer75th;
    case BenchmarkPercentile.Percentile90th:
      return monthData.telehealthPer90th;
    case BenchmarkPercentile.Mean:
      return monthData.telehealthMean;
    default:
      return 0;
  }
}

export function getTelehealthCommunityPercentileFromNpvSummary(
  benchmarkPercentile: BenchmarkPercentile,
  monthData: NewPatientVisitSummary) {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th:
      return monthData.telehealthCommunityPer25th;
    case BenchmarkPercentile.Percentile50th:
      return monthData.telehealthCommunityPer50th;
    case BenchmarkPercentile.Percentile75th:
      return monthData.telehealthCommunityPer75th;
    case BenchmarkPercentile.Percentile90th:
      return monthData.telehealthCommunityPer90th;
    case BenchmarkPercentile.Mean:
      return monthData.telehealthCommunityMean;
    default:
      return 0;
  }
}

export function getInPersonAcademicPercentileFromNpvSummary(benchmarkPercentile: BenchmarkPercentile, monthData: NewPatientVisitSummary) {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th:
      return monthData.inPersonPer25th;
    case BenchmarkPercentile.Percentile50th:
      return monthData.inPersonPer50th;
    case BenchmarkPercentile.Percentile75th:
      return monthData.inPersonPer75th;
    case BenchmarkPercentile.Percentile90th:
      return monthData.inPersonPer90th;
    case BenchmarkPercentile.Mean:
      return monthData.inPersonMean;
    default:
      return 0;
  }
}

export function getInPersonCommunityPercentileFromNpvSummary(benchmarkPercentile: BenchmarkPercentile, monthData: NewPatientVisitSummary) {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th:
      return monthData.inPersonCommunityPer25th;
    case BenchmarkPercentile.Percentile50th:
      return monthData.inPersonCommunityPer50th;
    case BenchmarkPercentile.Percentile75th:
      return monthData.inPersonCommunityPer75th;
    case BenchmarkPercentile.Percentile90th:
      return monthData.inPersonCommunityPer90th;
    case BenchmarkPercentile.Mean:
      return monthData.inPersonCommunityMean;
    default:
      return 0;
  }
}

export function lookupSummaryBenchmarkValue(benchmarkPercentile: BenchmarkPercentile,
                                            monthData: NewPatientVisitSummary,
                                            viewCommunityBenchmarks: boolean,
                                            benchmarkOption?: BenchmarkOption | undefined): number | undefined {
  benchmarkOption = benchmarkOption || (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);

  switch (benchmarkOption) {
    case BenchmarkOption.Academic:
      return getAcademicPercentileFromNpvSummary(benchmarkPercentile, monthData);
    case BenchmarkOption.Community:
      return getCommunityPercentileFromNpvSummary(benchmarkPercentile, monthData);
    case BenchmarkOption.TelehealthAcademic:
      return getTelehealthAcademicPercentileFromNpvSummary(benchmarkPercentile, monthData);
    case BenchmarkOption.TelehealthCommunity:
      return getTelehealthCommunityPercentileFromNpvSummary(benchmarkPercentile, monthData);
    case BenchmarkOption.InPersonAcademic:
      return getInPersonAcademicPercentileFromNpvSummary(benchmarkPercentile, monthData);
    case BenchmarkOption.InPersonCommunity:
      return getInPersonCommunityPercentileFromNpvSummary(benchmarkPercentile, monthData);
  }
}

export function getBenchmarkPercentileForExport(
  newPatientVisit: MergedNewPatientVisitSnapshotEntry,
  benchmarkPercentile: BenchmarkPercentile,
  viewCommunityBenchmarks = false,
  benchmarkOption?: BenchmarkOption
): string {
  const value = getBenchmarkFromTypeForNpvSnapshot(newPatientVisit, benchmarkPercentile, viewCommunityBenchmarks, benchmarkOption);
  return !undefinedIfZero(value) ? '-' : roundToWithPercentage((value && (value * 100)) || value, 1);
}

export function getExportSummaryBenchmarkPercentile(monthData: NewPatientVisitSummary,
                                                    benchmarkPercentile: BenchmarkPercentile, viewCommunityBenchmarks = false,
                                                    benchmarkOption?: BenchmarkOption | undefined): string {
  const value = lookupSummaryBenchmarkValue(benchmarkPercentile, monthData, viewCommunityBenchmarks, benchmarkOption);
  return (value === undefined || value === null) ? '-' :
    (roundTo((value || 0) * 100, 1)) + '%';
}

export function lookupVarianceValue(benchmarkPercentile: BenchmarkPercentile, monthData: NewPatientVisitTrendEntry
  | NewPatientVisitSummary, viewCommunityBenchmarks = false, benchmarkOption?: BenchmarkOption | undefined): number | undefined {

  benchmarkOption = benchmarkOption ?? (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);

  return getNpvDataByBenchmarkOptionAndType(monthData, benchmarkPercentile, benchmarkOption);
}

export function lookupBenchmarkValue(benchmarkPercentile: BenchmarkPercentile,
                                     monthData: NewPatientVisitTrendEntry,
                                     viewCommunityBenchmarks: boolean, benchmarkOption: BenchmarkOption | undefined): number | undefined {
  benchmarkOption = benchmarkOption ?? (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  return getBenchmarkPercentileValue(monthData, benchmarkPercentile, benchmarkOption);
}

function getBenchmarkPercentileValue(
  monthData: NewPatientVisitTrendEntry,
  benchmarkPercentile: BenchmarkPercentile,
  benchmarkOption: BenchmarkOption): number | undefined {
  return monthData[getDesignatedNpvBenchmarkObject(benchmarkOption)][`benchmark${readableNameOfColumnDef(benchmarkPercentile)}`];
}

export function getExportBenchmarkPercentile(monthNPV: NewPatientVisitTrendEntry,
                                             benchmarkPercentile: BenchmarkPercentile, viewCommunityBenchmarks = false,
                                             benchmarkOption?: BenchmarkOption | undefined): string {
  const benchmarkValue = lookupBenchmarkValue(benchmarkPercentile, monthNPV, viewCommunityBenchmarks, benchmarkOption);
  return !benchmarkValue || (benchmarkValue === 0) ? '-' :
    (roundTo((lookupBenchmarkValue(benchmarkPercentile, monthNPV, viewCommunityBenchmarks, benchmarkOption) || 0) * 100, 1)) + '%';
}

export function getBenchMarkSummaryPercentileForExport(
  monthData: NewPatientVisitSummary,
  benchmarkPercentile: BenchmarkPercentile,
  viewCommunityBenchmarks: boolean, benchmarkOption?: BenchmarkOption | undefined
): string {
  const value = lookupSummaryBenchmarkValue(benchmarkPercentile, monthData, viewCommunityBenchmarks, benchmarkOption);
  return value === undefined || value === null ? '-' :
    (roundTo((value || 0) * 100, 1)) + '%';
}

export function getNpvDataByBenchmarkOptionAndType(monthData: NewPatientVisitTrendEntry
                                                     | NewPatientVisitSummary,
                                                   benchmarkPercentile: BenchmarkPercentile,
                                                   benchmarkOption: BenchmarkOption): number | undefined {
  return undefinedIfZero(monthData[getDesignatedNpvBenchmarkObject(benchmarkOption)]
    [`benchmark${readableNameOfColumnDef(benchmarkPercentile)}`]) ? monthData[getDesignatedNpvVarianceObject(benchmarkOption)]
    [`variance${readableNameOfColumnDef(benchmarkPercentile)}`] : undefined;
}

export function getRowForNpvExport(newPatientVisits: any[], displayedColumns: BaseColumn[]): string[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
    // @ts-ignore
    if (!newPatientVisits[x.columnDef]) {
      data.push('-');
    } else if (x.columnType === ColumnType.VARIANCE || x.header.includes('%')) {
      // @ts-ignore
      data.push(newPatientVisits[x.columnDef]);
    } else {
      // @ts-ignore
      data.push(newPatientVisits[x.columnDef] + '');
    }
  });
  return data;
}

export function getRowForNpvSnapshotExport(newPatientVisits: any, displayedColumns: BaseColumn[],
                                           benchmarkObject: string, varianceObject: string): string[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
    if (x.columnDef === 'previousNewPatientVisitsPercentage') {
      data.push(`${newPatientVisits['previous']['newPatientVisitsPercentage']}%`);
    } else if (!newPatientVisits[x.columnDef] && !(x.columnType === ColumnType.VARIANCE || x.columnType === ColumnType.BENCHMARK)) {
      data.push('-');
    } else if (x.columnType === ColumnType.BENCHMARK) {
      data.push(formatToPercentageOrElseNull(newPatientVisits[benchmarkObject][`benchmark${readableNameOfColumnDef(
        getBenchmarkPercentileFromColumnDef(x.columnDef))}`], 1));
    } else if (x.header.includes('%')) {
      data.push(roundToWithPercentageOrElseNull(+newPatientVisits[x.columnDef], 1));
    } else if (x.columnType === ColumnType.VARIANCE) {
      data.push(roundToWithPercentageOrElseNull(+newPatientVisits[varianceObject][`variance${readableNameOfColumnDef(
        getBenchmarkPercentileFromColumnDef(x.columnDef))}`], 1));
    } else {
      data.push(newPatientVisits[x.columnDef] + '');
    }
  });
  return data;
}

export function getRowForNpvTrendExport(newPatientVisits: NewPatientVisitTrendEntry, displayedColumns: BaseColumn[],
                                        benchmarkObject: string, varianceObject: string): string[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
    if (x.columnDef === 'previousNewPatientVisitsPercentage') {
      data.push(`${newPatientVisits['previous']['newPatientVisitsPercentage']}%`);
    } else if (!newPatientVisits[x.columnDef] && !(x.columnType === ColumnType.VARIANCE || x.columnType === ColumnType.BENCHMARK)) {
      data.push('-');
    } else if (x.columnType === ColumnType.BENCHMARK) {
      data.push(formatToPercentageOrElseNull(newPatientVisits[benchmarkObject][`benchmark${readableNameOfColumnDef(
        getBenchmarkPercentileFromColumnDef(x.columnDef))}`], 1));
    } else if (x.header.includes('%')) {
      data.push(roundToWithPercentageOrElseNull(+newPatientVisits[x.columnDef], 1));
    } else if (x.columnType === ColumnType.VARIANCE) {
      data.push(roundToWithPercentageOrElseNull(+newPatientVisits[varianceObject][`variance${readableNameOfColumnDef(
        getBenchmarkPercentileFromColumnDef(x.columnDef))}`], 1));
    } else if (x.header.includes('Year')) {
      data.push(newPatientVisits.year + ' ' + toMonthName(newPatientVisits.month.toString()));
    } else {
      data.push(newPatientVisits[x.columnDef] + '');
    }
  });
  return data;
}

export function getBenchmarkPercentileFromColumnDef(columnDef: string): BenchmarkPercentile {
  if (columnDef.includes('25')) {
    return BenchmarkPercentile.Percentile25th;
  } else if (columnDef.includes('50')) {
    return BenchmarkPercentile.Percentile50th;
  } else if (columnDef.includes('75')) {
    return BenchmarkPercentile.Percentile75th;
  } else if (columnDef.includes('90')) {
    return BenchmarkPercentile.Percentile90th;
  }
  return BenchmarkPercentile.Mean;
}

export function getCsvNpvTrendDataForDisplayedColumns(monthData: NewPatientVisitTrendEntry[],
                                                      summaryData: SummaryData<NewPatientVisitSummary>,
                                                      displayedColumns: BaseColumn[],
                                                      viewCommunityBenchmarks: boolean,
                                                      benchmarkOption: BenchmarkOption | undefined
): Export {
  displayedColumns = displayedColumns.filter(x => x.columnDef !== 'month');
  displayedColumns.splice(0, 1, {
    columnDef: 'date', header: 'Date'
  });
  benchmarkOption = benchmarkOption ?? (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  const benchmarkText = extendedBenchmarkOptions.find(o => o.value === benchmarkOption)?.name;
  const newSummary = getMatchingNPVSummaryColumns(summaryData);
  const result = getNpvTrendExportSummaryData(newSummary, viewCommunityBenchmarks, benchmarkOption);
  result.push(...monthData.map((monthNPV: NewPatientVisitTrendEntry) => ({
    date: (monthNPV.year + ' ' + toMonthName(monthNPV.month.toString())),
    newPatientVisitsPercentage: roundToWithPercentage(monthNPV.newPatientVisitsPercentage, 1),
    countOfNewPatientVisits: formatNumberToWholeNumber(monthNPV.countOfNewPatientVisits),
    countOfTotalPatientVisits: formatNumberToWholeNumber(monthNPV.countOfTotalPatientVisits),
    benchmarkMean: getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Mean, viewCommunityBenchmarks, benchmarkOption),
    benchmark25thPercentile:
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks, benchmarkOption),
    benchmark50thPercentile:
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks, benchmarkOption),
    benchmark75thPercentile:
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks, benchmarkOption),
    benchmark90thPercentile:
      getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks, benchmarkOption),
    varianceMean: roundToWithPercentage((undefinedIfZero(checkForNulls(lookupVarianceValue(BenchmarkPercentile.Mean, monthNPV,
      viewCommunityBenchmarks, benchmarkOption)))), 1),
    variance25thPercentile: roundToWithPercentage((undefinedIfZero(checkForNulls(lookupVarianceValue(
      BenchmarkPercentile.Percentile25th,
      monthNPV, viewCommunityBenchmarks, benchmarkOption
    )))), 1),
    variance50thPercentile: roundToWithPercentage((undefinedIfZero(checkForNulls(lookupVarianceValue(
      BenchmarkPercentile.Percentile25th,
      monthNPV, viewCommunityBenchmarks, benchmarkOption
    )))), 1),
    variance75thPercentile: roundToWithPercentage((undefinedIfZero(checkForNulls(lookupVarianceValue(BenchmarkPercentile.Percentile75th,
      monthNPV, viewCommunityBenchmarks, benchmarkOption
    )))), 1),
    variance90thPercentile: roundToWithPercentage((undefinedIfZero(checkForNulls(lookupVarianceValue(BenchmarkPercentile.Percentile90th,
      monthNPV, viewCommunityBenchmarks, benchmarkOption
    )))), 1),
    previousNewPatientVisitsPercentage: roundToWithPercentage(monthNPV.previous.newPatientVisitsPercentage, 1),
    difference: getDifferencePercentage(monthNPV)
  })));

  let headers = ['Date'];
  const benchmarkHeaders = ['% New Patients ' + benchmarkText + ' Benchmark Mean',
    '% New Patients ' + benchmarkText + ' Benchmark 25th',
    '% New Patients ' + benchmarkText + ' Benchmark 50th',
    '% New Patients ' + benchmarkText + ' Benchmark 75th',
    '% New Patients ' + benchmarkText + ' Benchmark 90th'];
  const varianceHeaders = ['Variance Mean', 'Variance 25th', 'Variance 50th',
    'Variance 75th', 'Variance 90th'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmarkMean',
      header: '% New Patients ' + benchmarkText + ' Benchmark Mean'
    },
    {
      columnDef: 'benchmark25thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark Mean'
    },
    {
      columnDef: 'benchmark50thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark 50th'
    },
    {
      columnDef: 'benchmark75thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark 75th'
    },
    {
      columnDef: 'benchmark90thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark 90th'
    }
  ];
  const varianceData: BaseColumn[] = [
    {
      columnDef: 'varianceMean',
      header: 'Variance Mean'
    },
    {
      columnDef: 'variance25thPercentile',
      header: 'Variance 25th'
    },
    {
      columnDef: 'variance50thPercentile',
      header: 'Variance 50th'
    },
    {
      columnDef: 'variance75thPercentile',
      header: 'Variance 75th'
    },
    {
      columnDef: 'variance90thPercentile',
      header: 'Variance 90th'
    }
  ];

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));

  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }

  return {
    fileName: ('Npv by Month'),
    data: result.map(npv => getRowForNpvExport(npv, remainingColumns)),
    headers: headers,
    page: 'Npv by Month',
    title: 'Clinical Practice Solutions Center New Patient Visits ' + new Date().toLocaleString() + ';',
    whatFilters: {
      showPayer: true
    }
  };
}

export function getCsvNpvSnapshotDataForDisplayedColumns(
  newPatientVisits: MergedNewPatientVisitSnapshotEntry[],
  summaryData: SummaryData<NewPatientVisitSummary>,
  displayedColumns: BaseColumn[],
  level: string,
  viewCommunityBenchmarks: boolean,
  levelTab: MultilevelTab,
  benchmarkOption: BenchmarkOption,
  suppressZeroes: boolean): Export {
  const benchmarkText = extendedBenchmarkOptions.find(o => o.value === benchmarkOption)?.name;
  const newSummary = getMatchingNPVSummaryColumns(summaryData);
  const result = getNpvSnapshotCsvExportSummaryData(newSummary, viewCommunityBenchmarks, levelTab, benchmarkOption);
  result.push(...newPatientVisits.filter(x => !suppressZeroes || x.countOfTotalPatientVisits !== 0)
    .map((newPatientVisit: MergedNewPatientVisitSnapshotEntry) => {
      const {departmentNodeName, specialtyNodeName, providerNodeName, memberLocationName} = newPatientVisit;
      const getVarianceForProvidedParameters =
        (datum: MergedNewPatientVisitSnapshotEntry, type: BenchmarkPercentile, viewCommunity: boolean, option?: BenchmarkOption) =>
          roundToWithPercentage((undefinedIfZero(checkForNulls(getVarianceFromTypeForNpvSnapshot(datum, type, viewCommunity, option)))), 1);
      let levelObject = getNpvSnapshotCsvExportRow(
        newPatientVisit,
        levelTab,
        {departmentNodeName, specialtyNodeName, providerNodeName, memberLocationName, nodeName: ''},
        viewCommunityBenchmarks,
        benchmarkOption,
        getBenchmarkPercentileForExport,
        getVarianceForProvidedParameters
      );
      levelObject = {
        ...levelObject,
        previousNewPatientVisitsPercentage: roundToWithPercentage(newPatientVisit.previous.newPatientVisitsPercentage, 1),
        difference: getDifferencePercentage(newPatientVisit)
      };
      return levelObject;
  }));

  let headers = ['Groups'];
  const benchmarkHeaders = ['% New Patients ' + benchmarkText + ' Benchmark Mean',
    '% New Patients ' + benchmarkText + ' Benchmark 25th',
    '% New Patients ' + benchmarkText + ' Benchmark 50th',
    '% New Patients ' + benchmarkText + ' Benchmark 75th',
    '% New Patients ' + benchmarkText + ' Benchmark 90th'];
  const varianceHeaders = ['Variance Mean', 'Variance 25th', 'Variance 50th', 'Variance 75th', 'Variance 90th'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmarkMean',
      header: 'Benchmark Mean'
    },
    {
      columnDef: 'benchmark25thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark Mean'
    },
    {
      columnDef: 'benchmark50thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark 50th'
    },
    {
      columnDef: 'benchmark75thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark 75th'
    },
    {
      columnDef: 'benchmark90thPercentile',
      header: '% New Patients ' + benchmarkText + ' Benchmark 90th'
    }
  ];
  const varianceData: BaseColumn[] = [
    {
      columnDef: 'varianceMean',
      header: 'Variance Mean'
    },
    {
      columnDef: 'variance25thPercentile',
      header: 'Variance 25th'
    },
    {
      columnDef: 'variance50thPercentile',
      header: 'Variance 50th'
    },
    {
      columnDef: 'variance75thPercentile',
      header: 'Variance 75th'
    },
    {
      columnDef: 'variance90thPercentile',
      header: 'Variance 90th'
    }
  ];

  const hasBenchmarks = displayedColumns.filter(x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter(x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter(x => !(x.columnType === ColumnType.BENCHMARK || x.columnType === ColumnType.VARIANCE));

  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  return {
    fileName: ('Npv ' + level),
    data: result.map(npv => getRowForNpvExport(npv, remainingColumns)),
    headers: headers,
    page: 'Npv ' + level,
    title: 'Clinical Practice Solutions Center New Patient Visits ' + new Date().toLocaleString() + ';',
    whatFilters: {
      showPayer: true
    }
  };
}

export function getMatchingNPVSummaryColumns(summaryData: SummaryData<NewPatientVisitSummary>): SummaryData<NewPatientVisitSummary> {
  if (summaryData.selectedDateRange.totalPatientVisitsCount
    && summaryData.selectedDateRange.newPatientVisitsCount) {
    summaryData.selectedDateRange.countOfTotalPatientVisits
      = summaryData.selectedDateRange.totalPatientVisitsCount;
    summaryData.selectedDateRange.countOfNewPatientVisits
      = summaryData.selectedDateRange.newPatientVisitsCount;
  }


  if (summaryData.previousYearRecentMonth.totalPatientVisitsCount
    && summaryData.previousYearRecentMonth.newPatientVisitsCount) {
    summaryData.previousYearRecentMonth.countOfTotalPatientVisits
      = summaryData.previousYearRecentMonth.totalPatientVisitsCount;
    summaryData.previousYearRecentMonth.countOfNewPatientVisits
      = summaryData.previousYearRecentMonth.newPatientVisitsCount;
  }


  if (summaryData.previousYearSelectedDateRange.totalPatientVisitsCount
    && summaryData.previousYearSelectedDateRange.newPatientVisitsCount) {
    summaryData.previousYearSelectedDateRange.countOfTotalPatientVisits
      = summaryData.previousYearSelectedDateRange.totalPatientVisitsCount;
    summaryData.previousYearSelectedDateRange.countOfNewPatientVisits
      = summaryData.previousYearSelectedDateRange.newPatientVisitsCount;
  }

  if (summaryData.previousYearToDate.totalPatientVisitsCount
    && summaryData.previousYearToDate.newPatientVisitsCount) {
    summaryData.previousYearToDate.countOfTotalPatientVisits
      = summaryData.previousYearToDate.totalPatientVisitsCount;
    summaryData.previousYearToDate.countOfNewPatientVisits
      = summaryData.previousYearToDate.newPatientVisitsCount;
  }

  if (summaryData.recentMonth.totalPatientVisitsCount
    && summaryData.recentMonth.newPatientVisitsCount) {
    summaryData.recentMonth.countOfTotalPatientVisits
      = summaryData.recentMonth.totalPatientVisitsCount;
    summaryData.recentMonth.countOfNewPatientVisits
      = summaryData.recentMonth.newPatientVisitsCount;
  }

  if (summaryData.yearToDate.totalPatientVisitsCount
    && summaryData.yearToDate.newPatientVisitsCount) {
    summaryData.yearToDate.countOfTotalPatientVisits
      = summaryData.yearToDate.totalPatientVisitsCount;
    summaryData.yearToDate.countOfNewPatientVisits
      = summaryData.yearToDate.newPatientVisitsCount;
  }

  return summaryData;

}

export function getNpvTrendExportSummaryData(summaryData: SummaryData<NewPatientVisitSummary>,
                                             viewCommunityBenchmarks: boolean, benchmarkOption?: BenchmarkOption | undefined): any[] {
  const result: any[] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      result.push({
        date: getGroupName(summaryData[dateRange], dateRange),
        newPatientVisitsPercentage: roundToWithPercentage(summaryData[dateRange].newPatientVisitsPercentage, 1),
        countOfNewPatientVisits: formatNumberToWholeNumber(summaryData[dateRange].countOfNewPatientVisits),
        countOfTotalPatientVisits: formatNumberToWholeNumber(summaryData[dateRange].countOfTotalPatientVisits),
        benchmarkMean: getBenchMarkSummaryPercentileForExport(summaryData[dateRange], BenchmarkPercentile.Mean,
          viewCommunityBenchmarks, benchmarkOption),
        benchmark25thPercentile: getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile25th,
          viewCommunityBenchmarks, benchmarkOption
        ),
        benchmark50thPercentile: getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile50th,
          viewCommunityBenchmarks, benchmarkOption
        ),
        benchmark75thPercentile: getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile75th,
          viewCommunityBenchmarks, benchmarkOption
        ),
        benchmark90thPercentile: getBenchMarkSummaryPercentileForExport(
          summaryData[dateRange],
          BenchmarkPercentile.Percentile90th,
          viewCommunityBenchmarks, benchmarkOption
        ),
        varianceMean: '-',
        variance25thPercentile: '-',
        variance50thPercentile: '-',
        variance75thPercentile: '-',
        variance90thPercentile: '-',
        previousNewPatientVisitsPercentage: '-',
        difference: '-'
      });
    }
  }
  result.push({
    date: ' ',
    newPatientVisitsPercentage: ' ',
    countOfNewPatientVisits: ' ',
    countOfTotalPatientVisits: ' ',
    benchmarkMean: ' ',
    benchmark25thPercentile: ' ',
    benchmark50thPercentile: ' ',
    benchmark75thPercentile: ' ',
    benchmark90thPercentile: ' ',
    varianceMean: ' ',
    variance25thPercentile: ' ',
    variance50thPercentile: ' ',
    variance75thPercentile: ' ',
    variance90thPercentile: ' ',
    previousNewPatientVisitsPercentage: ' ',
    difference: ' '
  });
  return result;
}

export function getCsvNpvTrendData(monthData: NewPatientVisitTrendEntry[],
                                   summaryData: SummaryData<NewPatientVisitSummary>,
                                   viewCommunityBenchmarks: boolean, benchmarkOption: BenchmarkOption | undefined
): Export {
  const result: MonthNewPatientVisitsExport[] = [];

  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      result.push({
        date: getGroupName(summaryData[dateRange], dateRange),
        newPatientVisitsPercentage: roundToWithPercentage(summaryData[dateRange].newPatientVisitsPercentage, 1),
        countOfNewPatientVisits: formatNumberToWholeNumber(summaryData[dateRange].countOfNewPatientVisits),
        countOfTotalPatientVisits: formatNumberToWholeNumber(summaryData[dateRange].countOfTotalPatientVisits),
        benchmarkMean: getExportSummaryBenchmarkPercentile(summaryData[dateRange], BenchmarkPercentile.Mean, viewCommunityBenchmarks),
        benchmark25thPercentile: getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks),
        benchmark50thPercentile: getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks),
        benchmark75thPercentile: getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks),
        benchmark90thPercentile: getExportSummaryBenchmarkPercentile(summaryData[dateRange],
          BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks),
        varianceMean: '-',
        variance25thPercentile: '-',
        variance50thPercentile: '-',
        variance75thPercentile: '-',
        variance90thPercentile: '-',
        previousNewPatientVisitsPercentage: '-',
        difference: '-'
      });
    }
  }
  result.push(aBlankMonthNewPatientVisitsExport());

  result.push(...monthData.map((monthNPV: NewPatientVisitTrendEntry) => (
      {
        date: monthNPV.year.toString() + ' ' + toMonthName(monthNPV.month.toString()),
        newPatientVisitsPercentage: roundTo(monthNPV.newPatientVisitsPercentage, 1) + '%',
        countOfNewPatientVisits: formatNumberToWholeNumber(monthNPV.countOfNewPatientVisits),
        countOfTotalPatientVisits: formatNumberToWholeNumber(monthNPV.countOfTotalPatientVisits),
        benchmarkMean: getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Mean, viewCommunityBenchmarks, benchmarkOption),
        benchmark25thPercentile: getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile25th,
          viewCommunityBenchmarks, benchmarkOption),
        benchmark50thPercentile: getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile50th,
          viewCommunityBenchmarks, benchmarkOption),
        benchmark75thPercentile: getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile75th,
          viewCommunityBenchmarks, benchmarkOption),
        benchmark90thPercentile: getExportBenchmarkPercentile(monthNPV, BenchmarkPercentile.Percentile90th,
          viewCommunityBenchmarks, benchmarkOption),
        varianceMean: roundTo(lookupVarianceValue(BenchmarkPercentile.Mean, monthNPV, viewCommunityBenchmarks, benchmarkOption), 1) + '%',
        variance25thPercentile: roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile25th, monthNPV,
          viewCommunityBenchmarks, benchmarkOption), 1) + '%',
        variance50thPercentile: roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile50th, monthNPV,
          viewCommunityBenchmarks, benchmarkOption), 1) + '%',
        variance75thPercentile: roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile75th, monthNPV,
          viewCommunityBenchmarks, benchmarkOption), 1) + '%',
        variance90thPercentile: roundTo(lookupVarianceValue(BenchmarkPercentile.Percentile90th, monthNPV,
          viewCommunityBenchmarks, benchmarkOption), 1) + '%',
        previousNewPatientVisitsPercentage: roundTo(monthNPV.previousNewPatientVisitsPercentage, 1) + '%',
        difference: getDifferencePercentage(monthNPV)
      }
    )
  ));
  const communityText = viewCommunityBenchmarks ? ' Community' : '';
  return {
    data: result,
    headers: ['Date',
      '% New Patient Visits',
      '# of New Patients',
      '# of Total Patients',
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
      `% New Patients${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Mean)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile25th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile50th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile75th)})`,
      `Variance from${communityText} Benchmark (${readableNameOf(BenchmarkPercentile.Percentile90th)})`,
      'Previous Dates % New Patients',
      '% New Patient Visits Difference from Previous Date Range'],
    whatFilters: {
      showPayer: true
    },
    fileName: 'npvByMonths',
    page: 'Npv Trend',
    title: 'Clinical Practice Solutions Center New Patient Visits ' + new Date().toLocaleString() + ';'
  };
}
