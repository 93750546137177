import {BenchmarkPercentile, readableNameOf, readableNameOfColumnDef} from './benchmark-types';
import {
  ProductivityMultiLevelSnapshot,
  ProductivitySnapshot,
  ProductivitySummary,
  ProviderProductivity
} from '../productivity-summary/services/ProviderProductivity';
import {
  formatNumberToWholeNumber,
  formatToPercentage,
  getCssClass,
  getOrdinal,
  roundCollectionsToNumber,
  roundTo,
  roundToNearestTenthOrZero,
  roundToNumber,
  roundToWithCommasSeparation
} from '../productivity-summary/number-formatter';
import {toTitleCase} from './helpers';
import {checkForNulls, hasValue, undefinedIfZero} from './null-helpers';
import {PayerCollections, SnapshotMatchedCollections} from '../collections/Collection';
import {BenchmarkOption, ColumnType} from './enums';
import {DataTableColumns} from './data-table-columns';
import {BaseColumn} from '../store/IAppState';
import {MergedProductivityTrendEntry} from './models';
import {NewPatientVisitTrendEntry} from '../new-patient-visits/components/npv-models';
import {DenialEntityWithTerminalDenials, DenialsEntity, DenialsEntityWithVariance} from '../denials/denials-models';

export function createWRVUBenchmarkColumnFor(
  benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean): any {
  return {
    columnDef: benchmarkColumnDefFor(benchmark, viewCommunityBenchmarks),
    header: wRVUBenchmarkHeaderFor(benchmark, viewCommunityBenchmarks),
    dataName: (productivity: ProductivitySnapshot) => {
      return `${formatNumberToWholeNumber(benchmarkForWrvu(productivity, benchmark, viewCommunityBenchmarks))}`;
    },
    hoverMessage: 'Work RVU productivity benchmarks are calculated annually and released on the first of the ' +
      'calendar year. Only providers with validated clinical effort (cFTE) values are included. The focus of the ' +
      'selection for inclusion is to identify clinically active providers (typically greater than 0.6 cFTE). ' +
      'Productivity outliers are trimmed.',
    showHoverMessage: false,
    columnType: ColumnType.BENCHMARK
  };
}

export function benchmarkColumnDefFor(benchmark: BenchmarkPercentile,
                                      viewCommunityBenchmarks: boolean, benchmarkOption?: BenchmarkOption): string {
  if (!!benchmarkOption) {
    return benchmarkColumnDefForBenchmarkOption(benchmarkOption, benchmark);
  }
  if (viewCommunityBenchmarks) {
    return `communityBenchmark${readableNameOfColumnDef(benchmark)}`;
  }
  return `benchmark${readableNameOfColumnDef(benchmark)}`;
}

const benchmarkColumnDefForBenchmarkOption = (benchmarkOption:
                                                BenchmarkOption, benchmark: BenchmarkPercentile): string => {
  if (benchmarkOption === BenchmarkOption.Community) {
    return `communityBenchmark${readableNameOfColumnDef(benchmark)}`;
  } else if (benchmarkOption === BenchmarkOption.Academic) {
    return `benchmark${readableNameOfColumnDef(benchmark)}`;
  } else if (benchmarkOption === BenchmarkOption.TelehealthCommunity) {
    return `telehealthCommunityBenchmark${readableNameOfColumnDef(benchmark)}`;
  } else if (benchmarkOption === BenchmarkOption.TelehealthAcademic) {
    return `telehealthAcademicBenchmark${readableNameOfColumnDef(benchmark)}`;
  } else if (benchmarkOption === BenchmarkOption.InPersonCommunity) {
    return `inpersonCommunityBenchmark${readableNameOfColumnDef(benchmark)}`;
  } else if (benchmarkOption === BenchmarkOption.InPersonAcademic) {
    return `inpersonAcademicBenchmark${readableNameOfColumnDef(benchmark)}`;
  }
  return `benchmark${readableNameOfColumnDef(benchmark)}`;
};

const varianceColumnDef = (viewCommunityBenchmarks: boolean, percentile: BenchmarkPercentile, benchmarkOption?: BenchmarkOption) => {
  if (!!benchmarkOption) {
    return varianceColumnDefForBenchmarkOption(benchmarkOption, percentile);
  }
  if (viewCommunityBenchmarks) {
    return `communityVariance${readableNameOfColumnDef(percentile)}`;
  }
  return `variance${readableNameOfColumnDef(percentile)}`;
};

const varianceColumnDefForBenchmarkOption = (benchmarkOption: BenchmarkOption, percentile: BenchmarkPercentile): string => {
  if (benchmarkOption === BenchmarkOption.Community) {
    return `communityVariance${readableNameOfColumnDef(percentile)}`;
  } else if (benchmarkOption === BenchmarkOption.Academic) {
    return `variance${readableNameOfColumnDef(percentile)}`;
  } else if (benchmarkOption === BenchmarkOption.TelehealthCommunity) {
    return `telehealthCommunityVariance${readableNameOfColumnDef(percentile)}`;
  } else if (benchmarkOption === BenchmarkOption.TelehealthAcademic) {
    return `telehealthAcademicVariance${readableNameOfColumnDef(percentile)}`;
  }
  return `variance${readableNameOfColumnDef(percentile)}`;
};

export function wRVUBenchmarkHeaderFor(benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean): string {
  if (viewCommunityBenchmarks) {
    return `Community wRVU Benchmark (${readableNameOf(benchmark)})`;
  }
  return `Academic wRVU Benchmark (${readableNameOf(benchmark)})`;
}

export function createWRVUTrendBenchmarkColumnFor(benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean): any {
  if (!viewCommunityBenchmarks) {
    return {
      columnDef: `benchmark${readableNameOfColumnDef(benchmark)}`,
      header: `Academic wRVU Benchmark (${readableNameOf(benchmark)})`,
      dataName: (row: MergedProductivityTrendEntry) =>
        `${formatNumberToWholeNumber(benchmarkForWrvuTrend(row, benchmark, false))}`,
      hoverMessage: 'Work RVU productivity benchmarks are calculated annually and released on the first of the ' +
        'calendar year. Only providers with validated clinical effort (cFTE) values are included. The focus of the ' +
        'selection for inclusion is to identify clinically active providers (typically greater than 0.6 cFTE). ' +
        'Productivity outliers are trimmed.',
      showHoverMessage: false,
      columnType: ColumnType.BENCHMARK
    };
  } else {
    return {
      columnDef: `communityBenchmark${readableNameOfColumnDef(benchmark)}`,
      header: `Community wRVU Benchmark (${readableNameOf(benchmark)})`,
      dataName: (row: MergedProductivityTrendEntry) =>
        `${formatNumberToWholeNumber(benchmarkForWrvuTrend(row, benchmark, true))}`,
      hoverMessage: 'Work RVU productivity benchmarks are calculated annually and released on the first of the ' +
        'calendar year. Only providers with validated clinical effort (cFTE) values are included. The focus of the ' +
        'selection for inclusion is to identify clinically active providers (typically greater than 0.6 cFTE). ' +
        'Productivity outliers are trimmed.',
      showHoverMessage: false,
      columnType: ColumnType.BENCHMARK
    };
  }
}

export function createWRVUTrendVarianceColumnFor(benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean): any {

  if (!viewCommunityBenchmarks) {
    return {
      columnDef: `variance${readableNameOfColumnDef(benchmark)}`,
      header: `Variance from Benchmark (${readableNameOf(benchmark)})`,
      dataName: (row: MergedProductivityTrendEntry) =>
        `${formatNumberToWholeNumber(varianceForWrvuTrend(row, benchmark, false))}`,
      class: (row: MergedProductivityTrendEntry) => `${getCssClass(
        varianceForWrvuTrend(row, benchmark, false))}`,
      columnType: ColumnType.VARIANCE
    };
  } else {
    return {
      columnDef: `communityVariance${readableNameOfColumnDef(benchmark)}`,
      header: `Variance from Benchmark (${readableNameOf(benchmark)})`,
      dataName: (row: MergedProductivityTrendEntry) =>
        `${formatNumberToWholeNumber(varianceForWrvuTrend(row, benchmark, true))}`,
      class: (row: MergedProductivityTrendEntry) => `${getCssClass(varianceForWrvuTrend(row, benchmark, true))}`,
      columnType: ColumnType.VARIANCE
    };

  }
}


export function createImputedReportedCfteBenchmarkColumnFor(benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean,
                                                            previous: boolean = false): any {
  if (!viewCommunityBenchmarks) {
    return {
      columnDef: previous ? `previousImputedReportedcFTE${readableNameOfColumnDef(benchmark)}` :
        `imputedReportedcFTE${readableNameOfColumnDef(benchmark)}`,
      header: previous ? `Previous Imputed/ Reported cFTE (${readableNameOf(benchmark)})` :
        `Imputed/ Reported cFTE (${readableNameOf(benchmark)})`,
      dataName: (row: ProductivitySnapshot) => `${formatToPercentage(
        imputedCFTEFor(row, benchmark, viewCommunityBenchmarks, previous))}`,
      columnType: ColumnType.IMPUTED
    };
  } else {
    return {
      columnDef: `communityImputedReportedcFTE${readableNameOfColumnDef(benchmark)}`,
      header: `Imputed/ Reported cFTE (${readableNameOf(benchmark)})`,
      dataName: (row: ProductivitySnapshot) => `${formatToPercentage(
        imputedCFTEFor(row, benchmark, viewCommunityBenchmarks, previous))}`,
      columnType: ColumnType.IMPUTED
    };
  }
}

export function createVarianceBenchmarkColumnFor(benchmark: BenchmarkPercentile, viewCommunity: boolean, previous: boolean = false):
  any {
  return {
    columnDef: previous ? `previousVariance${readableNameOfColumnDef(benchmark)}` : !viewCommunity ?
      `variance${readableNameOfColumnDef(benchmark)}` : `communityVariance${readableNameOfColumnDef(benchmark)}`,
    header: previous ? `Variance from Previous Year Benchmark (${readableNameOf(benchmark)})` :
      `Variance from Benchmark (${readableNameOf(benchmark)})`,
    dataName: (row: ProductivitySnapshot) => {
      return `${formatNumberToWholeNumber(varianceType(row, benchmark, viewCommunity))}`;
    },
    class: (row: ProductivitySnapshot) => `${getCssClass(varianceType(row, benchmark, viewCommunity))}`,
    columnType: ColumnType.VARIANCE
  };
}

export function createDenialsRateBenchmarkColumnFor(benchmark: BenchmarkPercentile): any {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return {
        columnDef: 'denialRatePer25th',
        header: 'CPSC Denial Rate Benchmark 25th Percentile',
        dataName: (row: DenialsEntity) => hasValue(row.denialRatePer25th) ? `${roundToNearestTenthOrZero(row.denialRatePer25th)}%` : '-',
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile75th:
      return {
        columnDef: 'denialRatePer75th',
        header: 'CPSC Denial Rate Benchmark 75th Percentile',
        dataName: (row: DenialsEntity) => hasValue(row.denialRatePer25th) ? `${roundToNearestTenthOrZero(row.denialRatePer75th)}%` : '-',
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile50th:
    default:
      return {
        columnDef: 'denialRatePer50th',
        header: 'CPSC Denial Rate Benchmark 50th Percentile',
        dataName: (row: DenialsEntity) => hasValue(row.denialRatePer25th) ? `${roundToNearestTenthOrZero(row.denialRatePer50th)}%` : '-',
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
  }
}

export function createTerminalDenialsRateBenchmarkColumnFor(benchmark: BenchmarkPercentile): DataTableColumns {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return {
        columnDef: 'terminalDenialRatePer25th',
        header: 'CPSC Terminal Denial Rate Benchmark 25th Percentile',
        dataName: (row: DenialEntityWithTerminalDenials) => hasValue(row.terminalDenialRatePer25th) ?
          `${roundToNearestTenthOrZero(row.terminalDenialRatePer25th)}%` : '-',
        columnType: ColumnType.TERMINAL_DENIAL_BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile75th:
      return {
        columnDef: 'terminalDenialRatePer75th',
        header: 'CPSC Terminal Denial Rate Benchmark 75th Percentile',
        dataName: (row: DenialEntityWithTerminalDenials) => hasValue(row.terminalDenialRatePer75th) ?
          `${roundToNearestTenthOrZero(row.terminalDenialRatePer75th)}%` : '-',
        columnType: ColumnType.TERMINAL_DENIAL_BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile50th:
    default:
      return {
        columnDef: 'terminalDenialRatePer50th',
        header: 'CPSC Terminal Denial Rate Benchmark 50th Percentile',
        dataName: (row: DenialEntityWithTerminalDenials) => hasValue(row.terminalDenialRatePer50th) ?
          `${roundToNearestTenthOrZero(row.terminalDenialRatePer50th)}%` : '-',
        columnType: ColumnType.TERMINAL_DENIAL_BENCHMARK,
        isDefault: true
      };
  }
}

export function createDenialsPaidBenchmarkColumnFor(benchmark: BenchmarkPercentile): any {
  {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return {
          columnDef: 'deniedPaidPer25th',
          header: 'CPSC Denied CPTs Paid Benchmark 25th Percentile',
          dataName: (row: DenialsEntity) => hasValue(row.denialRatePer25th) ? `${roundToNearestTenthOrZero(row.deniedPaidPer25th)}%` : '-',
          columnType: ColumnType.DENIED_PAID_BENCHMARK,
          isDefault: true
        };
      case BenchmarkPercentile.Percentile75th:
        return {
          columnDef: 'deniedPaidPer75th',
          header: 'CPSC Denied CPTs Paid Benchmark 75th Percentile',
          dataName: (row: DenialsEntity) => hasValue(row.denialRatePer75th) ? `${roundToNearestTenthOrZero(row.deniedPaidPer75th)}%` : '-',
          columnType: ColumnType.DENIED_PAID_BENCHMARK,
          isDefault: true
        };
      case BenchmarkPercentile.Percentile50th:
      default:
        return {
          columnDef: 'deniedPaidPer50th',
          header: 'CPSC Denied CPTs Paid Benchmark 50th Percentile',
          dataName: (row: DenialsEntity) => hasValue(row.deniedPaidPer50th) ? `${roundToNearestTenthOrZero(row.deniedPaidPer50th)}%` : '-',
          columnType: ColumnType.DENIED_PAID_BENCHMARK,
          isDefault: true
        };
    }
  }
}

export function createDenialsVarianceColumnFor(benchmark: BenchmarkPercentile): any {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return {
        columnDef: 'variancePer25th', header: 'Variance From Denial Rate Benchmark (25th)',
        dataName: (row: DenialsEntityWithVariance) => hasValue(row.variancePer25th) ?
          `${roundToNumber(row.variancePer25th, 0)}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: DenialsEntityWithVariance) =>
          `${getCssClass(row.variancePer25th)}`,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile50th:
      return {
        columnDef: 'variancePer50th', header: 'Variance From Denial Rate Benchmark (50th)',
        dataName: (row: DenialsEntityWithVariance) => hasValue(row.variancePer50th) ?
          `${roundToNumber(row.variancePer50th, 0)}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: DenialsEntityWithVariance) =>
          `${getCssClass(row.variancePer50th)}`,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile75th:
      return {
        columnDef: 'variancePer75th', header: 'Variance From Denial Rate Benchmark (75th)',
        dataName: (row: DenialsEntityWithVariance) => hasValue(row.variancePer75th) ?
          `${roundToNumber(row.variancePer75th, 0)}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: DenialsEntityWithVariance) =>
          `${getCssClass(row.variancePer75th)}`,
        isDefault: true
      };
    default:
      return {
        columnDef: 'variancePer50th', header: 'Variance From Denial Rate Benchmark (50th)',
        dataName: (row: DenialsEntityWithVariance) => hasValue(row.variancePer50th) ?
          `${roundToNumber(row.variancePer50th, 1) + '%'}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: DenialsEntityWithVariance) =>
          `${getCssClass(row.variancePer50th)}`,
        isDefault: true
      };
  }
}

export function createNCRBenchmarkColumnFor(benchmark: BenchmarkPercentile): any {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return {
        columnDef: 'benchmark25th', header: 'Net Collection Rate Benchmark (25th)',
        dataName: (row: SnapshotMatchedCollections) => `${roundCollectionsToNumber(row.benchmark25th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile50th:
      return {
        columnDef: 'benchmark50th', header: 'Net Collection Rate Benchmark (50th)',
        dataName: (row: SnapshotMatchedCollections) => `${roundCollectionsToNumber(row.benchmark50th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile75th:
      return {
        columnDef: 'benchmark75th', header: 'Net Collection Rate Benchmark (75th)',
        dataName: (row: SnapshotMatchedCollections) => `${roundCollectionsToNumber(row.benchmark75th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    default:
      return {
        columnDef: 'benchmark50th', header: 'Net Collection Rate Benchmark (50th)',
        dataName: (row: SnapshotMatchedCollections) => `${roundCollectionsToNumber(row.benchmark50th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
  }
}

export function createCollectionsVarianceColumnFor(benchmark: BenchmarkPercentile): any {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return {
        columnDef: 'variance25th', header: 'Variance (25th)',
        dataName: (row: SnapshotMatchedCollections) => hasValue(row.variance25th) ?
          `${roundTo(row.variance25th, 1) + '%'}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: SnapshotMatchedCollections) =>
          `${getCssClass(row.variance25th)}`,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile50th:
      return {
        columnDef: 'variance50th', header: 'Variance (50th)',
        dataName: (row: SnapshotMatchedCollections) => hasValue(row.variance50th) ?
          `${roundTo(row.variance50th, 1) + '%'}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: SnapshotMatchedCollections) =>
          `${getCssClass(row.variance50th)}`,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile75th:
      return {
        columnDef: 'variance75th', header: 'Variance (75th)',
        dataName: (row: SnapshotMatchedCollections) => hasValue(row.variance75th) ?
          `${roundTo(row.variance75th, 1) + '%'}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: SnapshotMatchedCollections) =>
          `${getCssClass(row.variance75th)}`,
        isDefault: true
      };
    default:
      return {
        columnDef: 'variance50th', header: 'Variance (50th)',
        dataName: (row: SnapshotMatchedCollections) => hasValue(row.variance50th) ?
          `${roundTo(row.variance50th, 1) + '%'}` : '-',
        columnType: ColumnType.VARIANCE,
        class: (row: SnapshotMatchedCollections) =>
          `${getCssClass(row.variance50th)}`,
        isDefault: true
      };
  }
}

export function createPayerNCRBenchmarkColumnFor(benchmark: BenchmarkPercentile): any {
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return {
        columnDef: 'benchmark25th', header: 'Net Collection Rate Benchmark (25th)',
        dataName: (row: PayerCollections) => `${roundCollectionsToNumber(row.benchmark25th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile50th:
      return {
        columnDef: 'benchmark50th', header: 'Net Collection Rate Benchmark (50th)',
        dataName: (row: PayerCollections) => `${roundCollectionsToNumber(row.benchmark50th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    case BenchmarkPercentile.Percentile75th:
      return {
        columnDef: 'benchmark75th', header: 'Net Collection Rate Benchmark (75th)',
        dataName: (row: PayerCollections) => `${roundCollectionsToNumber(row.benchmark75th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
    default:
      return {
        columnDef: 'benchmark50th', header: 'Net Collection Rate Benchmark (50th)',
        dataName: (row: PayerCollections) => `${roundCollectionsToNumber(row.benchmark50th, 1)}`,
        columnType: ColumnType.BENCHMARK,
        isDefault: true
      };
  }
}

export function benchmarkForWrvu(
  productivity: ProductivitySnapshot | ProductivitySummary,
  percentile: BenchmarkPercentile,
  viewCommunity: boolean): number
  | null {

  if (!viewCommunity) {
    switch (percentile) {
      case 1:
        return productivity.benchmark25thPercentile === 0 ? null : productivity.benchmark25thPercentile;
      case 2:
        return productivity.benchmark50thPercentile === 0 ? null : productivity.benchmark50thPercentile;
      case 3:
        return productivity.benchmark75thPercentile === 0 ? null : productivity.benchmark75thPercentile;
      case 4:
        return productivity.benchmark90thPercentile === 0 ? null : productivity.benchmark90thPercentile;
      case 5:
        return productivity.benchmarkMean === 0 ? null : productivity.benchmarkMean;
      case 6:
        return productivity.benchmark65thPercentile === 0 ? null : productivity.benchmark65thPercentile;
      default:
        return null;
    }
  } else {
    switch (percentile) {
      case 1:
        return productivity.communityBenchmark25thPercentile === 0 ? null :
          productivity.communityBenchmark25thPercentile;
      case 2:
        return productivity.communityBenchmark50thPercentile === 0 ? null :
          productivity.communityBenchmark50thPercentile;
      case 3:
        return productivity.communityBenchmark75thPercentile === 0 ? null :
          productivity.communityBenchmark75thPercentile;
      case 4:
        return productivity.communityBenchmark90thPercentile === 0 ? null :
          productivity.communityBenchmark90thPercentile;
      case 5:
        return productivity.communityBenchmarkMean === 0 ? null :
          productivity.communityBenchmarkMean;
      case 6:
        return productivity.communityBenchmark65thPercentile === 0 ? null :
          productivity.communityBenchmark65thPercentile;
      default:
        return null;
    }
  }
}

export function benchmarkForWrvuTrend(productivity: MergedProductivityTrendEntry,
                                      benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean): number | null
  | undefined {
  if (!viewCommunityBenchmarks) {
    switch (benchmark) {
      case 1:
        return productivity.benchmark25thPercentile === 0 ? null :
          productivity.benchmark25thPercentile;
      case 2:
        return productivity.benchmark50thPercentile === 0 ? null :
          productivity.benchmark50thPercentile;
      case 3:
        return productivity.benchmark75thPercentile === 0 ? null :
          productivity.benchmark75thPercentile;
      case 4:
        return productivity.benchmark90thPercentile === 0 ? null :
          productivity.benchmark90thPercentile;
      case 5:
        return productivity.benchmarkMean === 0 ? null :
          productivity.benchmarkMean;
      case 6:
        return productivity.benchmark65thPercentile === 0 ? null :
          productivity.benchmark65thPercentile;
      default:
        return null;
    }
  } else {
    switch (benchmark) {
      case 1:
        return productivity.communityBenchmark25thPercentile === 0 ? null :
          productivity.communityBenchmark25thPercentile;
      case 2:
        return productivity.communityBenchmark50thPercentile === 0 ? null :
          productivity.communityBenchmark50thPercentile;
      case 3:
        return productivity.communityBenchmark75thPercentile === 0 ? null :
          productivity.communityBenchmark75thPercentile === 0 ? null :
            productivity.communityBenchmark75thPercentile;
      case 4:
        return productivity.communityBenchmark90thPercentile === 0 ? null :
          productivity.communityBenchmark90thPercentile;
      case 5:
        return productivity.communityBenchmarkMean === 0 ? null :
          productivity.communityBenchmarkMean;
      case 6:
        return productivity.communityBenchmark65thPercentile === 0 ? null :
          productivity.communityBenchmark65thPercentile;
      default:
        return null;
    }
  }
}

export function varianceForWrvuTrend(productivity: MergedProductivityTrendEntry,
                                     benchmark: BenchmarkPercentile, viewCommunityBenchmark: boolean): number
  | null {
  if (!viewCommunityBenchmark) {
    switch (benchmark) {
      case 1:
        return !undefinedIfZero(productivity.benchmark25thPercentile) ? null :
          productivity.variance25thPercentile;
      case 2:
        return !undefinedIfZero(productivity.benchmark50thPercentile) ? null :
          productivity.variance50thPercentile;
      case 3:
        return !undefinedIfZero(productivity.benchmark75thPercentile) ? null :
          productivity.variance75thPercentile;
      case 4:
        return !undefinedIfZero(productivity.benchmark90thPercentile) ? null :
          productivity.variance90thPercentile;
      case 5:
        return !undefinedIfZero(productivity.benchmarkMean) ? null :
          productivity.varianceMean;
      case 6:
        return !undefinedIfZero(productivity.benchmark65thPercentile) ? null :
          productivity.variance65thPercentile;
      default:
        return null;
    }
  } else {
    switch (benchmark) {
      case 1:
        return !undefinedIfZero(productivity.communityBenchmark25thPercentile) ? null :
          productivity.communityVariance25thPercentile;
      case 2:
        return !undefinedIfZero(productivity.communityBenchmark50thPercentile) ? null :
          productivity.communityVariance50thPercentile;
      case 3:
        return !undefinedIfZero(productivity.communityBenchmark75thPercentile) ? null :
          productivity.communityVariance75thPercentile;
      case 4:
        return !undefinedIfZero(productivity.communityBenchmark90thPercentile) ? null :
          productivity.communityVariance90thPercentile;
      case 5:
        return !undefinedIfZero(productivity.communityBenchmarkMean) ? null :
          productivity.communityVarianceMean;
      case 6:
        return !undefinedIfZero(productivity.communityBenchmark65thPercentile) ? null :
          productivity.communityVariance65thPercentile;
      default:
        return null;
    }
  }
}

export function imputedCFTEFor(productivitySnapshot: ProductivitySnapshot,
                               benchmarkPercentile: BenchmarkPercentile,
                               viewCommunityBenchmarks: boolean,
                               previous: boolean = false) {
  if (!viewCommunityBenchmarks) {
    switch (benchmarkPercentile) {
      case 1:
        return previous ? checkForNulls(productivitySnapshot.previousImputedReportedcFTE25thPercentile) :
          checkForNulls(productivitySnapshot.imputedReportedcFTE25thPercentile);
      case 2:
        return previous ? checkForNulls(productivitySnapshot.previousImputedReportedcFTE50thPercentile) :
          checkForNulls(productivitySnapshot.imputedReportedcFTE50thPercentile);
      case 3:
        return previous ? checkForNulls(productivitySnapshot.previousImputedReportedcFTE75thPercentile) :
          checkForNulls(productivitySnapshot.imputedReportedcFTE75thPercentile);
      case 4:
        return previous ? checkForNulls(productivitySnapshot.previousImputedReportedcFTE90thPercentile) :
          checkForNulls(productivitySnapshot.imputedReportedcFTE90thPercentile);
      case 5:
        return previous ? checkForNulls(productivitySnapshot.previousImputedReportedcFTEMean) :
          checkForNulls(productivitySnapshot.imputedReportedcFTEMean);
      case 6:
        return previous ? checkForNulls(productivitySnapshot.previousImputedReportedcFTE65thPercentile) :
          checkForNulls(productivitySnapshot.imputedReportedcFTE65thPercentile);
      default:
        return 0;
    }
  } else {
    switch (benchmarkPercentile) {
      case 1:
        return checkForNulls(productivitySnapshot.communityImputedReportedcFTE25thPercentile);
      case 2:
        return checkForNulls(productivitySnapshot.communityImputedReportedcFTE50thPercentile);
      case 3:
        return checkForNulls(productivitySnapshot.communityImputedReportedcFTE75thPercentile);
      case 4:
        return checkForNulls(productivitySnapshot.communityImputedReportedcFTE90thPercentile);
      case 5:
        return checkForNulls(productivitySnapshot.communityImputedReportedcFTEMean);
      case 6:
        return checkForNulls(productivitySnapshot.communityImputedReportedcFTE65thPercentile);
      default:
        return 0;
    }
  }
}

export function imputedCFTEAsAPercentage(productivitySnapshot: ProductivitySnapshot,
                                         benchmarkPercentile: BenchmarkPercentile,
                                         viewCommunityBenchmarks: boolean,
                                         previous: boolean = false) {
  return formatToPercentage(imputedCFTEFor(productivitySnapshot, benchmarkPercentile, viewCommunityBenchmarks, previous));
}

export function varianceType(productivitySnapshot: ProviderProductivity, percentile: BenchmarkPercentile, viewCommunity: boolean) {

  if (!viewCommunity) {
    switch (percentile) {
      case 1:
        return productivitySnapshot.variance25thPercentile;
      case 2:
        return productivitySnapshot.variance50thPercentile;
      case 6:
        return productivitySnapshot.variance65thPercentile;
      case 3:
        return productivitySnapshot.variance75thPercentile;
      case 4:
        return productivitySnapshot.variance90thPercentile;
      case 5:
      default:
        return productivitySnapshot.varianceMean;
    }
  } else {
    switch (percentile) {
      case 1:
        return productivitySnapshot.communityVariance25thPercentile;
      case 2:
        return productivitySnapshot.communityVariance50thPercentile;
      case 6:
        return productivitySnapshot.communityVariance65thPercentile;
      case 3:
        return productivitySnapshot.communityVariance75thPercentile;
      case 4:
        return productivitySnapshot.communityVariance90thPercentile;
      case 5:
      default:
        return productivitySnapshot.communityVarianceMean;
    }
  }
}

export function departmentNodeColumnForWrvu(primaryColumn: boolean): DataTableColumns {
  return {
    columnDef: 'departmentNodeName',
    header: 'Department',
    dataName: (row: ProductivityMultiLevelSnapshot) => `${toTitleCase(row.departmentNodeName)}`,
    primaryColumn: primaryColumn,
    columnType: ColumnType.NODE_NAME
  };
}

export function multiLevelWrvuDeptColumns(primaryColumn: boolean): DataTableColumns[] {
  return [
    departmentNodeColumnForWrvu(primaryColumn),
    ...commonWrvuColumns
  ];
}

export function specialtyNodeColumnForWrvu(primaryColumn: boolean): DataTableColumns {
  return {
    columnDef: 'specialtyNodeName',
    header: 'Specialty',
    dataName: (row: ProductivityMultiLevelSnapshot) => `${toTitleCase(row.specialtyNodeName || '')}`,
    primaryColumn: primaryColumn,
    columnType: ColumnType.NODE_NAME
  };
}

export function multiLevelWrvuSpecColumns(primaryColumn: boolean): DataTableColumns[] {
  return [
    specialtyNodeColumnForWrvu(primaryColumn),
    ...multiLevelWrvuDeptColumns(false)
  ];
}

export function benchmarkForNpv(row: NewPatientVisitTrendEntry,
                                benchmark: BenchmarkPercentile,
                                benchmarkOption: BenchmarkOption): number {
  if (benchmarkOption === BenchmarkOption.Academic) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return row.benchmark25thPercentile || 0;
      case BenchmarkPercentile.Percentile50th:
        return row.benchmark50thPercentile || 0;
      case BenchmarkPercentile.Percentile75th:
        return row.benchmark75thPercentile || 0;
      case BenchmarkPercentile.Percentile90th:
        return row.benchmark90thPercentile || 0;
      case BenchmarkPercentile.Mean:
        return row.benchmarkMean || 0;
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.Community) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return row.communityBenchmark25thPercentile || 0;
      case BenchmarkPercentile.Percentile50th:
        return row.communityBenchmark50thPercentile || 0;
      case BenchmarkPercentile.Percentile75th:
        return row.communityBenchmark75thPercentile || 0;
      case BenchmarkPercentile.Percentile90th:
        return row.communityBenchmark90thPercentile || 0;
      case BenchmarkPercentile.Mean:
        return row.communityBenchmarkMean || 0;
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.TelehealthAcademic) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return row.telehealthAcademicBenchmark25thPercentile || 0;
      case BenchmarkPercentile.Percentile50th:
        return row.telehealthAcademicBenchmark50thPercentile || 0;
      case BenchmarkPercentile.Percentile75th:
        return row.telehealthAcademicBenchmark75thPercentile || 0;
      case BenchmarkPercentile.Percentile90th:
        return row.telehealthAcademicBenchmark90thPercentile || 0;
      case BenchmarkPercentile.Mean:
        return row.telehealthAcademicBenchmarkMean || 0;
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.TelehealthCommunity) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return row.telehealthCommunityBenchmark25thPercentile || 0;
      case BenchmarkPercentile.Percentile50th:
        return row.telehealthCommunityBenchmark50thPercentile || 0;
      case BenchmarkPercentile.Percentile75th:
        return row.telehealthCommunityBenchmark75thPercentile || 0;
      case BenchmarkPercentile.Percentile90th:
        return row.telehealthCommunityBenchmark90thPercentile || 0;
      case BenchmarkPercentile.Mean:
        return row.telehealthCommunityBenchmarkMean || 0;
      default:
        return 0;
    }
  }
  return 0;
}

export function createNpvTrendVarianceColumnFor(benchmark: BenchmarkPercentile, benchmarkOption: BenchmarkOption): any {
  let columnDef: string;

  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthAcademic:
      columnDef = 'telehealthAcademicVariance';
      break;
    case BenchmarkOption.TelehealthCommunity:
      columnDef = 'telehealthCommunityVariance';
      break;
    case BenchmarkOption.InPersonAcademic:
      columnDef = 'inpersonAcademicVariance';
      break;
    case BenchmarkOption.InPersonCommunity:
      columnDef = 'inpersonCommunityVariance';
      break;
    case BenchmarkOption.Community:
      columnDef = 'communityVariance';
      break;
    case BenchmarkOption.Academic:
    default:
      columnDef = 'variance';
      break;
  }
  return {
    columnDef: columnDef + readableNameOfColumnDef(benchmark),
    header: `Variance from Benchmark ${readableNameOf(benchmark)}`,
    dataName: (row: NewPatientVisitTrendEntry) => benchmarkForNpv(row, benchmark, benchmarkOption)
      ? roundTo(varianceForNpvTrend(row, benchmark, benchmarkOption), 1) + '%'
      : '-',
    class: (row: NewPatientVisitTrendEntry) => getCssClass(varianceForNpvTrend(row, benchmark, benchmarkOption)),
    columnType: ColumnType.VARIANCE
  };
}

export function varianceForNpvTrend(row: NewPatientVisitTrendEntry,
                                    benchmark: BenchmarkPercentile,
                                    benchmarkOption: BenchmarkOption): number {

  if (benchmarkOption === BenchmarkOption.Academic) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return checkForNulls(row.variance25thPercentile);
      case BenchmarkPercentile.Percentile50th:
        return checkForNulls(row.variance50thPercentile);
      case BenchmarkPercentile.Percentile75th:
        return checkForNulls(row.variance75thPercentile);
      case BenchmarkPercentile.Percentile90th:
        return checkForNulls(row.variance90thPercentile);
      case BenchmarkPercentile.Mean:
        return checkForNulls(row.varianceMean);
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.Community) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return checkForNulls(row.communityVariance25thPercentile);
      case BenchmarkPercentile.Percentile50th:
        return checkForNulls(row.communityVariance50thPercentile);
      case BenchmarkPercentile.Percentile75th:
        return checkForNulls(row.communityVariance75thPercentile);
      case BenchmarkPercentile.Percentile90th:
        return checkForNulls(row.communityVariance90thPercentile);
      case BenchmarkPercentile.Mean:
        return checkForNulls(row.communityVarianceMean);
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.TelehealthAcademic) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return checkForNulls(row.telehealthAcademicVariance25thPercentile);
      case BenchmarkPercentile.Percentile50th:
        return checkForNulls(row.telehealthAcademicVariance50thPercentile);
      case BenchmarkPercentile.Percentile75th:
        return checkForNulls(row.telehealthAcademicVariance75thPercentile);
      case BenchmarkPercentile.Percentile90th:
        return checkForNulls(row.telehealthAcademicVariance90thPercentile);
      case BenchmarkPercentile.Mean:
        return checkForNulls(row.telehealthAcademicVarianceMean);
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.TelehealthCommunity) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return checkForNulls(row.telehealthCommunityVariance25thPercentile);
      case BenchmarkPercentile.Percentile50th:
        return checkForNulls(row.telehealthCommunityVariance50thPercentile);
      case BenchmarkPercentile.Percentile75th:
        return checkForNulls(row.telehealthCommunityVariance75thPercentile);
      case BenchmarkPercentile.Percentile90th:
        return checkForNulls(row.telehealthCommunityVariance90thPercentile);
      case BenchmarkPercentile.Mean:
        return checkForNulls(row.telehealthCommunityVarianceMean);
      default:
        return 0;
    }
  }
  return 0;
}

export function adjustBenchmarkDependentColumns(
  columns: DataTableColumns[],
  benchmarkPercentile: BenchmarkPercentile,
  viewCommunityBenchmarks: boolean): void {
  columns.forEach((column, index) => {
    switch (column.columnType) {
      case ColumnType.BENCHMARK:
        column = createWRVUBenchmarkColumnFor(benchmarkPercentile, viewCommunityBenchmarks);
        break;
      case ColumnType.IMPUTED:
        column = createImputedReportedCfteBenchmarkColumnFor(benchmarkPercentile, viewCommunityBenchmarks);
        break;
      case ColumnType.VARIANCE:
        column = createVarianceBenchmarkColumnFor(benchmarkPercentile, viewCommunityBenchmarks);
        break;
    }
    columns[index] = column;
  });
}

export function updateColumnFilter(
  displayedColumns: BaseColumn[],
  tableColumns: DataTableColumns[],
  benchmarkPercentile: BenchmarkPercentile,
  viewCommunityBenchmarks: boolean, benchmarkOption?: BenchmarkOption): DataTableColumns[] {

  const displayedColumnDefs = displayedColumns.map(col => {
    switch (col.columnType) {
      case ColumnType.BENCHMARK:
        return benchmarkColumnDefFor(benchmarkPercentile, viewCommunityBenchmarks, benchmarkOption);
      case ColumnType.VARIANCE:
        return varianceColumnDef(viewCommunityBenchmarks, benchmarkPercentile, benchmarkOption);
      case ColumnType.IMPUTED:
        return viewCommunityBenchmarks ?
          `communityImputedReportedcFTE${readableNameOfColumnDef(benchmarkPercentile)}` :
          `imputedReportedcFTE${readableNameOfColumnDef(benchmarkPercentile)}`;
      default:
        return col.columnDef;
    }
  });
  return tableColumns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef)).slice();
}

export function filterOutBenchmarkRelatedColumns(columns: any[]) {
  // @ts-ignore
  return columns.filter(col => !WRVU_BENCHMARK_RELATED_COLUMN_TYPES.includes(col.columnType));
}

export const BenchmarkPercentilesForWrvu =
  [BenchmarkPercentile.Mean, BenchmarkPercentile.Percentile25th, BenchmarkPercentile.Percentile50th,
    BenchmarkPercentile.Percentile65th, BenchmarkPercentile.Percentile75th, BenchmarkPercentile.Percentile90th];

export const BenchmarkPercentilesForNpv = [BenchmarkPercentile.Mean, BenchmarkPercentile.Percentile25th, BenchmarkPercentile.Percentile50th,
  BenchmarkPercentile.Percentile75th, BenchmarkPercentile.Percentile90th];

export const BenchmarkPercentilesForCollection =
  [BenchmarkPercentile.Percentile25th, BenchmarkPercentile.Percentile50th, BenchmarkPercentile.Percentile75th];

export const BenchmarkPercentilesForDenials = BenchmarkPercentilesForCollection.slice();

export const IMPUTED_COLUMNS = [
  {
    columnDef: 'imputedReportedcFTEMean',
    header: 'Imputed/ Reported cFTE (Mean)',
    columnType: ColumnType.IMPUTED
  },
  {
    columnDef: 'imputedReportedcFTE25thPercentile',
    header: 'Imputed/ Reported cFTE (25th)',
    columnType: ColumnType.IMPUTED
  },
  {
    columnDef: 'imputedReportedcFTE50thPercentile',
    header: 'Imputed/ Reported cFTE (50th)',
    columnType: ColumnType.IMPUTED
  },
  {
    columnDef: 'imputedReportedcFTE65thPercentile',
    header: 'Imputed/ Reported cFTE (65th)',
    columnType: ColumnType.IMPUTED
  },
  {
    columnDef: 'imputedReportedcFTE75thPercentile',
    header: 'Imputed/ Reported cFTE (75th)',
    columnType: ColumnType.IMPUTED
  },
  {
    columnDef: 'imputedReportedcFTE90thPercentile',
    header: 'Imputed/ Reported cFTE (90th)',
    columnType: ColumnType.IMPUTED
  }
];

export const commonWrvuColumns: DataTableColumns[] = [
  {
    columnDef: 'cfteAdjustedWRVUs',
    header: 'cFTE Adj. wRVUs',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(checkForNulls(row.cfteAdjustedWRVUs))}`,
    hoverMessage: 'Raw wRVUs produced by individual clinicians indexed to a 1.0 CFTE level. ' +
      'This calculation is used to compare clinician productivity to CPSC productivity benchmarks, ' +
      'which are published on a “per 1.0 CFTE” basis.',
    showHoverMessage: false,
    columnType: ColumnType.CFTE_ADJ_WRVU
  },
  {
    columnDef: 'benchmarkMean',
    header: 'wRVU Benchmark (Mean)',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(checkForNulls(row.benchmarkMean))}`,
    hoverMessage: 'Work RVU productivity benchmarks are calculated annually and released on the first of the ' +
      'calendar year. Only providers with validated clinical effort (cFTE) values are included. The focus of the ' +
      'selection for inclusion is to identify clinically active providers (typically greater than 0.6 cFTE). ' +
      'Productivity outliers are trimmed.',
    showHoverMessage: false,
    columnType: ColumnType.BENCHMARK
  },
  {
    columnDef: 'wRVUs',
    header: 'Actual wRVUs',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(checkForNulls(row.wRVUs))}`,
    hoverMessage: 'A unit of measure used to express the amount of effort (time, intensity of effort, technical skills) ' +
      'required by a provider to perform a given service relative to other services.',
    showHoverMessage: false
  },
  {
    columnDef: 'cfte',
    header: 'cFTE',
    dataName: (row: ProductivitySnapshot) => `${roundToWithCommasSeparation(row.cfte, 2)}`,
    hoverMessage: 'Represents the portion of the provider’s time spent in patient-facing clinical care' +
      ' and the follow-up and documentation required for that care.',
    showHoverMessage: false,
    columnType: ColumnType.CFTE
  },
  {
    columnDef: 'imputedReportedcFTEMean',
    header: 'Imputed/ Reported cFTE (Mean)',
    dataName: (row: ProductivitySnapshot) =>
      `${formatToPercentage((checkForNulls(row.imputedReportedcFTEMean)), 1)}`,
    columnType: ColumnType.IMPUTED
  },
  {
    columnDef: 'charges',
    header: 'Charges',
    dataName: (row: ProductivitySnapshot) => `$${formatNumberToWholeNumber(checkForNulls(row.charges))}`,
    columnType: ColumnType.CHARGES
  },
  {
    columnDef: 'varianceMean',
    header: 'Variance',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(row.varianceMean)}`,
    columnType: ColumnType.VARIANCE,
    group: 'group-last'
  },
  {
    columnDef: 'previousCfteAdjustedWRVUs',
    header: 'Previous Date Range cFTE Adj. wRVUs',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(checkForNulls(row.previousCfteAdjustedWRVUs))}`,
    group: 'group-first',
    columnType: ColumnType.PREVIOUS
  },
  {
    columnDef: 'difference',
    header: 'cFTE Adj. wRVUs Difference from Previous Date Range',
    dataName: (row: ProductivitySnapshot) => `${formatNumberToWholeNumber(row.difference)}`,
    class: (row: ProductivitySnapshot) => `${getCssClass(checkForNulls(row.difference))}`,
    columnType: ColumnType.DIFFERENCE
  }
];

export const providerNodeColumnForWrvu: DataTableColumns = {
  columnDef: 'providerNodeName',
  header: 'Provider',
  dataName: (row: ProductivityMultiLevelSnapshot) => `${toTitleCase(row.providerNodeName || '')}`,
  primaryColumn: true,
  columnType: ColumnType.NODE_NAME
};

export const cptDef = 'viewCpt';

export const multiLevelWrvuProviderColumns: DataTableColumns[] = [
  providerNodeColumnForWrvu,
  ...multiLevelWrvuSpecColumns(false)
];

export const providerPercentileColumn: DataTableColumns = {
  columnDef: 'benchmarkPercentileRank',
  header: 'cFTE adj. wRVU Provider Percentile',
  dataName: (row: ProductivitySnapshot) => `${getOrdinal(row.benchmarkPercentileRank)}`,
  hoverMessage: 'Based on Providers that have a cFTE of 0.6 or more.',
  showHoverMessage: true,
  columnType: ColumnType.PERCENTILE
};

export const providerPercentileCommunityColumn: DataTableColumns = {
  columnDef: 'communityBenchmarkPercentileRank',
  header: 'cFTE adj. wRVU Provider Percentile',
  dataName: (row: ProductivitySnapshot) => `${getOrdinal(row.communityBenchmarkPercentileRank)}`,
  hoverMessage: 'Based on Providers that have a cFTE of 0.6 or more.',
  showHoverMessage: true,
  columnType: ColumnType.PERCENTILE
};

export const WRVU_BENCHMARK_RELATED_COLUMN_TYPES = [ColumnType.BENCHMARK, ColumnType.VARIANCE, ColumnType.IMPUTED];
export const CFTE_ADJ_WRVU_RELATED_COLUMN_TYPES = [ColumnType.CFTE_ADJ_WRVU, ColumnType.PREVIOUS, ColumnType.DIFFERENCE];
