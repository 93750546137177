import {Component, Input, OnChanges} from '@angular/core';
import {EvaluationManagementSummary} from '../../../shared/models';
import {MatTableDataSource} from '@angular/material/table';
import {DataTableColumns} from '../../../shared/data-table-columns';

@Component({
  selector: 'app-trend-em-batch-summary',
  templateUrl: './trend-em-batch-summary.component.html',
  styleUrls: ['./trend-em-batch-summary.component.scss']
})
export class TrendEmBatchSummaryComponent implements OnChanges {
  @Input() summaryData: EvaluationManagementSummary;
  @Input() columns: DataTableColumns[];
  @Input() title = '';
  dataSource: MatTableDataSource<EvaluationManagementSummary>;
  displayedColumns: string[] = [];
  constructor() { }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource<EvaluationManagementSummary>(this.summaryData ? [this.summaryData].slice() : []);
    this.displayedColumns = this.columns.map(x => x.columnDef);
  }

  getColumnHeaderClass(column: DataTableColumns): string {
    return column.class ? `${column.class}Header` : '';
  }

  getColumnClass(column: DataTableColumns): string {
    return column.class ? `${column.class}` : column.isTotal ? 'total' : '';
  }
}
