<div id="wrvu-multilevel-graph-wrapper">
  <div class="chart-header">
    <div class="head">
      <span class="head-title">{{wRVUTitle}} {{level}}</span><br>
    </div>
    <span class="right-header-wrapper">
      <app-toggle-cfte-actual-wrvus
        [selectionCounts]="selectionCounts"
        [wrvuViewType]="wRVUtype"
        [page]="metricPage"
        [canShowCfteAdjustedWrvus]="canShowCfteAdjWrvus"
        [activeVarianceToggle]="activeVarianceToggle"
      ></app-toggle-cfte-actual-wrvus>
      <app-variable-container
        [showVariableMenu]="showVariableMenu"
        [variables]="variables"
        [disabled]="activeVarianceToggle"
        [page]="page">
      </app-variable-container>
      <app-variance-toggler *ngIf="canShowBenchmarks" [varianceToggleAction]="varianceToggle"></app-variance-toggler>
    </span>
  </div>
  <div [chart]="chartObject" id="provider-graph"></div>
  <app-chart-legend
    [activeVarianceToggle]="activeVarianceToggle"
    [legends]="legends"
    [hasBenchmarks]="hasBenchmarks"
    [warningText]="isDepartment && !canShowBenchmarks ? 'No Benchmarks Available at the Department Level' : ''"
    [isSpecialtyPerformance]="isSpecialtyPerformance">
  </app-chart-legend>

  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>

</div>
