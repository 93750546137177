import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../models';
import {GroupDialogEvent} from '../../../updated-filter-banner/manage-groups-helper';

@Component({
  selector: 'app-save-apply-custom-group',
  templateUrl: './save-apply-dialog.component.html',
  styleUrls: ['./save-apply-dialog.component.scss']
})
export class SaveApplyDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<SaveApplyDialogComponent>) {
  }

  ngOnInit(): void { }

  saveApply(): void {
    if (this.data.confirmCallback) {
      this.data.confirmCallback();
    }
    this.data.dialogActionListener?.next(GroupDialogEvent.TENTATIVE_SAVE);
    this.dialogRef.close(true);
  }

  applyOnly(): void {
    if (this.data.cancelCallback) {
      this.data.cancelCallback();
    }
    this.data.dialogActionListener?.next(GroupDialogEvent.APPLY);
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.data.dialogActionListener?.next(GroupDialogEvent.INITIAL);
    this.dialogRef.close(false);
  }
}
