import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export const LegalServiceToken = new InjectionToken<LegalService>('LegalService');

export interface LegalService {
  getLegal(): Observable<any>;
}

@Injectable()
export class HttpLegalService implements LegalService {
  constructor(private readonly httpClient: HttpClient) { }

  getLegal(): Observable<any> {
    return this.httpClient.get<any>(`../../assets/legal.json`);
  }

}
