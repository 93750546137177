import {Component, EventEmitter, Inject, Input, OnChanges, Output} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {BatchExportServiceToken, HttpBatchExportService} from '../../services/batch-export.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  BatchExportReportScheduleType,
  deleteReportTitle,
  ScheduledReportsDialogColumn
} from '../../batch-export-helper';
import {BatchExportStatus, ColumnType, NodeExpansionState} from '../../../shared/enums';
import {
  BatchExportScheduleExistingReportChild,
  BatchExportScheduleExistingReportParent,
  BatchExportScheduleExistingReportResponseEntity,
  ScheduledReportTableEntry
} from '../../multi-provider-export-redesign/mpe-dialog-helper';
import {MatTableDataSource} from '@angular/material/table';
import {compareDateValues, getOppositeNodeExpansionState} from '../../../shared/helpers';
import {ConfirmDialogComponent} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {BatchExportReportDetailsResponseEntity, DialogData} from '../../../shared/models';
import {isEllipsisActive} from '../../../shared/ui-helpers';

@Component({
  selector: 'app-scheduled-exports-table',
  templateUrl: './scheduled-exports-table.component.html',
  styleUrls: ['./scheduled-exports-table.component.scss']
})
export class ScheduledExportsTableComponent implements OnChanges {

  public static scheduledMappings: Map<number, (string | undefined)>;
  public static instanceMappings: Map<number, (string | undefined)>;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              public dialog: MatDialog) { }

  @Input() existingReportsResponse: BatchExportScheduleExistingReportResponseEntity[][] = [];
  @Input() parentColumns: ScheduledReportsDialogColumn[] = [];
  @Input() childColumns: ScheduledReportsDialogColumn[] = [];
  @Input() stringColumnsForParentRowDef: string[] = [];
  @Input() stringColumnsForChildRowDef: string[] = [];
  @Output() downloadRecord = new EventEmitter<BatchExportReportDetailsResponseEntity>();

  tableSources: ScheduledReportTableEntry[];
  readonly BatchExportStatus = BatchExportStatus;
  readonly ColumnType = ColumnType;
  readonly NodeExpansionState = NodeExpansionState;
  readonly scheduleTableClass = 'exported-reports-summary-table';
  readonly scheduledElementIdPrefix = 'srd';
  readonly instanceElementIdPrefix = 'ird';
  readonly lesserFlex = 0.3;
  readonly greaterFlex = 5 * this.lesserFlex;
  private deleteDialogRef: MatDialogRef<ConfirmDialogComponent>;

  private static getScheduledTooltip(id: number): string | undefined {
    return ScheduledExportsTableComponent.scheduledMappings.get(id);
  }

  private static getInstanceTooltip(id: number): string | undefined {
    return ScheduledExportsTableComponent.instanceMappings.get(id);
  }

  ngOnChanges(): void {
    ScheduledExportsTableComponent.scheduledMappings = new Map<number, (string | undefined)>();
    ScheduledExportsTableComponent.instanceMappings = new Map<number, (string | undefined)>();
    this.createTableSources();
  }

  createTableSources(): void {
    this.tableSources = [];
    for (let x = 0; x < (this.existingReportsResponse || []).length; x++) {
      if (this.existingReportsResponse[x].length === 0) {
        continue;
      }
      const parent: BatchExportScheduleExistingReportParent = {
        scheduledReportId: this.existingReportsResponse[x][0].scheduledReportId,
        scheduledReportName: this.existingReportsResponse[x][0].scheduledReportName,
        status: this.existingReportsResponse[x][0].status,
        exportDate: this.existingReportsResponse[x][0].exportDate,
        filePath: this.existingReportsResponse[x][0].filePath,
        readStatus: this.existingReportsResponse[x][0].readStatus,
        numberOfSpecialties: this.existingReportsResponse[x][0].numberOfSpecialties,
        numberOfProviders: this.existingReportsResponse[x][0].numberOfProviders,
        createdDate: this.existingReportsResponse[x][0].createdDate,
        hoverMessage: ScheduledExportsTableComponent.getScheduledTooltip,
        instanceReports: new MatTableDataSource(this.existingReportsResponse[x].map(y => {
          return {
            ...y,
            instanceReportId: y.instanceReportId,
            instanceReportName: y.instanceReportName,
            hoverMessage: ScheduledExportsTableComponent.getInstanceTooltip
          };
        }).sort((a: BatchExportScheduleExistingReportResponseEntity, b: BatchExportScheduleExistingReportResponseEntity ) => {
          return compareDateValues(a.exportDate, b.exportDate);
        })),
        hasUnreadInstanceReport: !!this.existingReportsResponse[x].find(m => !m.readStatus && m.status === BatchExportStatus.COMPLETED)
      };
      parent.exportDate = parent.instanceReports.data[0].exportDate;
      this.tableSources.push({
        source: new MatTableDataSource([parent]),
        state: NodeExpansionState.COLLAPSED
      });
    }
    this.tableSources = this.tableSources.sort((a: ScheduledReportTableEntry, b: ScheduledReportTableEntry) => {
      return compareDateValues(a.source.data[0].exportDate, b.source.data[0].exportDate);
    });
  }

  expandOrCollapseRow(row: ScheduledReportTableEntry): void {
    row.state = getOppositeNodeExpansionState(row.state);
  }

  activateScheduledReportTooltip(id: number, name: string): void {
    if (!ScheduledExportsTableComponent.scheduledMappings.get(id)) {
      const scheduledCell = document.getElementById(`${this.scheduledElementIdPrefix}${id}`);
      if (scheduledCell && isEllipsisActive(scheduledCell)) {
        ScheduledExportsTableComponent.scheduledMappings.set(id, name);
      } else {
        ScheduledExportsTableComponent.scheduledMappings.delete(id);
      }
    }
  }

  activateInstanceReportTooltip(id: number, name: string): void {
    if (!ScheduledExportsTableComponent.instanceMappings.get(id)) {
      const instanceCell = document.getElementById(`${this.instanceElementIdPrefix}${id}`);
      if (instanceCell && isEllipsisActive(instanceCell)) {
        ScheduledExportsTableComponent.instanceMappings.set(id, name);
      } else {
        ScheduledExportsTableComponent.instanceMappings.delete(id);
      }
    }
  }

  private findMatchingEntryName(reportId: number, useInstance: boolean): string {
    for (let x = 0; x < this.existingReportsResponse.length; x++) {
      for (let y = 0; y < this.existingReportsResponse[y].length; y++) {
        if (useInstance) {
          if (this.existingReportsResponse[x][y].instanceReportId === reportId) {
            return this.existingReportsResponse[x][y].instanceReportName;
          }
        } else if (this.existingReportsResponse[x][y].scheduledReportId === reportId) {
          return this.existingReportsResponse[x][y].scheduledReportName;
        }
      }
    }
    return '';
  }

  deleteItem(reportId: number, instanceReport: boolean): void {
    const reportName = '"' + this.findMatchingEntryName(reportId, instanceReport) + '"';
    const dialogData: DialogData = {
      question: `Are you sure you want to delete ${reportName}?`,
      title: deleteReportTitle,
      confirmButtonAltText: 'Yes',
      cancelText: 'No',
      buttonWrapperClass: 'mpe-reports-buttons',
      confirmCallback() {
        console.log('TODO');
      }
    };
    this.deleteDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
  }

  downloadInstance(entry: BatchExportScheduleExistingReportChild): void {
    this.downloadRecord.emit(
    {
      id: entry.instanceReportId,
      reportId: entry.instanceReportId,
      reportName: entry.instanceReportName,
      reportStatus: entry.status,
      exportDate: entry.exportDate,
      readStatus: entry.readStatus,
      path: entry.filePath,
      numberOfDepartments: 1,
      numberOfSpecialties: entry.numberOfSpecialties,
      numberOfProviders: entry.numberOfProviders,
      reportScheduleType: BatchExportReportScheduleType.MONTHLY
    });
  }
}
