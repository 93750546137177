<table class="mat-calendar-table">
  <thead class="mat-calendar-table-header">
    <tr>
      <th class="mat-calendar-table-header-divider" colspan="4"></th>
    </tr>
  </thead>
  <tbody sat-calendar-body
         allowDisabledSelection="false"
         [rows]="_years"
         [todayValue]="_todayYear"
         [selectedValue]="_selectedYear"
         [numCols]="4"
         [cellAspectRatio]="4 / 7"
         [activeCell]="_getActiveCell()"
         (selectedValueChange)="_yearSelected($event)">
  </tbody>
</table>
