<app-collections-multilevel-chart
  [showProgressBar]="showProgressBar"
  [variables]="variables"
  [showVariableMenu]="true"
  [page]="currentPage"
  [data]="collectionsMultiLevel"
  [benchmarkPercentile]="benchmarkPercentile"
  [activeVarianceToggle]="activeVarianceToggle"
  [sortingCriteria]="varianceToggleSortingCriterion"
  [tabLevel]="1"
  [columnSelectionCallback]="whenDepartmentSelected">
></app-collections-multilevel-chart>
<app-data-table id="collections-data-table"
                [rowSelectionCallback]="whenDepartmentSelected"
                [showProgressBar]="showProgressBar"
                [sortingDataAccessor]="sortingDataAccessor"
                [receivedData]="collectionsMultiLevel"
                [tableCssClass]="'collection-table'"
                [displayedColumns]="displayedColumns"
                [defaultSortColumn]="defaultSortColumn"
                [showLevel]="false"
                [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                [sortDirection]="sortDirection"
                tableTitle="Collection Analysis By Department"
                [whenSortChanged]="levelSpecificHandleSortChange"
                [collectionsTab]="true"
                [originalColumns]="columns"
                [displayZeroSuppression]="true"
                [zeroSuppressionCondition]="zeroSuppressCondition"
                [isZeroSuppressionChecked]="isZeroSuppressed"
                [zeroSuppressionSnapshotEntry]="countOfSuppressedEntries > 1 || countOfSuppressedEntries === 0 ?
                 'Departments' : 'Department'"
                [countOfSuppressedEntries]="countOfSuppressedEntries"
                [currentPage]="currentPage"
                [updateSortingCriteria]="updateSortingCriteria"
                [addRemoveOption]="true"
                [reducerAction]="reducerAction"
>
</app-data-table>
