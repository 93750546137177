import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MemberBillingArea, SelectableMemberBillingArea} from '../../shared/models';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {DEFAULT_MEMBER_BILLING_AREA} from '../../store/DefaultValues';
import {isMemberBillingAreaAbleToDisplay} from '../filter-banner-helper';

@Component({
  selector: 'app-select-billing-area',
  templateUrl: './select-billing-area.component.html',
  styleUrls: ['./select-billing-area.component.scss']
})
export class SelectBillingAreaComponent implements OnInit, OnChanges {

  @Output() billingAreaKeyEmit = new EventEmitter<MemberBillingArea>();
  @Output() billingAreasLoaded = new EventEmitter<MemberBillingArea[]>();
  @Input() selectedMemberBillingArea: MemberBillingArea = DEFAULT_MEMBER_BILLING_AREA;
  memberBillingAreas: SelectableMemberBillingArea[] = [];
  shownMemberBillingAreas: SelectableMemberBillingArea[] = [];

  @select(['data', 'memberBillingAreaData'])
  private readonly memberBillingArea$: Observable<MemberBillingArea[]>;
  DEFAULT_MEMBER_BILLING_AREA = DEFAULT_MEMBER_BILLING_AREA;

  billingAreaText = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedMemberBillingArea && !changes.selectedMemberBillingArea.isFirstChange()) {
      this.selectedMemberBillingArea = changes.selectedMemberBillingArea.currentValue;
      this.filterShownMemberBillingAreas();
    }
  }

  ngOnInit() {
    this.memberBillingArea$.subscribe((resp: MemberBillingArea[]) => {
      this.billingAreasLoaded.emit(resp);
      this.memberBillingAreas = [];
      resp.forEach(billingArea => {
        this.memberBillingAreas.push({
          memberBillingArea: billingArea,
          matchesSearchText: true,
          selected: false
        });
      });
      this.filterShownMemberBillingAreas();
    });
  }

  selectDefaultMemberBillingArea(event: MouseEvent): void {
    this.billingAreaKeyEmit.emit(this.DEFAULT_MEMBER_BILLING_AREA);
    this.filterShownMemberBillingAreas();
  }

  selectMemberBillingArea = (billingArea: SelectableMemberBillingArea, event?: MouseEvent) => {
    this.billingAreaKeyEmit.emit(billingArea.memberBillingArea);
    this.filterShownMemberBillingAreas();
  };

  filterShownMemberBillingAreas(): void {
    this.shownMemberBillingAreas = this.memberBillingAreas.filter(mBA =>
      isMemberBillingAreaAbleToDisplay(mBA));
  }

  onBillingAreaTextChanged(event: any): void {
    this.billingAreaText = event.target.value;
    if (this.billingAreaText.length <= 1) {
      this.memberBillingAreas.forEach(mBA => {
        mBA.matchesSearchText = true;
      });
      this.filterShownMemberBillingAreas();
      return;
    }
    this.memberBillingAreas.forEach(memberBillingArea => {
      if (this.billingAreaText.length <= memberBillingArea.memberBillingArea.memberBillingAreaDescription.length) {
        const shouldUse = memberBillingArea.memberBillingArea.memberBillingAreaDescription.toLowerCase()
          .includes(this.billingAreaText.toLowerCase());
        memberBillingArea.matchesSearchText = shouldUse;
      } else {
        memberBillingArea.matchesSearchText = false;
      }
    });
    this.filterShownMemberBillingAreas();
  }
}
