<span *ngFor="let item of (!onlyIncludeTopRows ? column.dataNameList(row).slice(CUT_OFF) :
      column.dataNameList(row).slice(0, CUT_OFF)); let i = index"
      class="{{item.isTotal ? 'first-item ' : 'node-list-item '}}
      {{!item.isTotal && (!onlyIncludeTopRows ? CUT_OFF : 0) + i === hoverIndex ? 'hovered-row ' : ''}}
      {{i < column.dataNameList(row).length - 1 ? 'bordered-item' : ''}}"
      [style]="'height: ' + (item.height || DEFAULT_DATA_ROW_HEIGHT) + 'px;'"
      (click)="!item.isTotal && isDrillable ? whenNodeSelected(item) : undefined"
      (mouseenter)="changeHoverIndex(isDrillable ? (!onlyIncludeTopRows ? CUT_OFF : 0) + i : DEFAULT_HOVER_INDEX)"
      (mouseleave)="changeHoverIndex(DEFAULT_HOVER_INDEX)"
      [matTooltip]="tooltipMessage" [matTooltipDisabled]="!item.isTotal || !tooltipMessage"
      [matTooltipPosition]="'above'" [matTooltipClass]="'custom-tooltip'">
  <span class="{{'data-text'}} {{column.class ? column.class(item) : 'regular-text '}}
      {{column.columnType === ColumnType.NODE_NAME ? 'node-data' : 'numeric-data'}}"
      [style]="'-webkit-line-clamp: ' + numberOfDataRowLines + '; line-height: ' + TOKEN_HEIGHT + 'px;'"
      [matTooltip]="item.columnsWithTooltips ? item.columnsWithTooltips.get(column.columnDef) : ''"
      [matTooltipDisabled]="false" [matTooltipPosition]="'after'"
      id="{{item.isTotal ? totalColumnId : nodeIndexer.length > 0 ? item[nodeIndexer] ? item[nodeIndexer].toString() : '' : ''}}">
    {{(excludedColumnTypesInTotals.includes(column.columnType) && item.isTotal) ? '-' : column.dataName(item)}}
  </span>
</span>
