import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BatchExportServiceToken, HttpBatchExportService} from '../services/batch-export.service';
import {
  BatchExportDetail,
  BatchExportDetailResponse,
  BatchFilterCriteria,
  DateRange,
  EmDimension,
  EvaluationManagementMultilevel,
  FeatureToggleSetting
} from '../../shared/models';
import {getBatchFilterFromExportDetail} from '../../shared/test/helper-functions.spec';
import {forkJoin, Subscription} from 'rxjs';
import {FilterType, getPreviousYearDateRange, getSelectedDateRange} from '../../shared/helpers';
import {translateNumberToBenchmarkOption} from '../../shared/benchmark-types';
import {roundToNumber} from '../../productivity-summary/number-formatter';
import {FeatureToggleServiceToken, HttpFeatureToggleService} from '../../services/feature-toggle.service';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {combineBatchFilter} from '../batch-export-helper';
import {BenchmarkOption} from '../../shared/enums';

@Component({
  selector: 'app-batch-export-ml-pdf',
  templateUrl: './batch-export-ml-pdf.component.html',
  styleUrls: ['./batch-export-ml-pdf.component.scss']
})

export class BatchExportMlPdfComponent implements OnInit, OnDestroy {

  guid = '';
  detailReportId: number;
  detailData: BatchExportDetail;
  dateRange: DateRange;
  selectedDateRange: string;
  previousSelectedDateRange: string;
  viewCommunityBenchmarks: boolean;
  benchmarkOption: BenchmarkOption;
  nodePath: string;
  nodeName = '';
  splNodeName = '';
  // E and M
  emData: EvaluationManagementMultilevel;
  emSpecialtyData: EvaluationManagementMultilevel;
  emMetrics: EmDimension[] = [];
  showPdfPage = false;
  ftService: Subscription;
  batchService: Subscription;
  batchFilterCriteria: BatchFilterCriteria;
  previousBatchFilterCriteria: BatchFilterCriteria;

  constructor(private router: ActivatedRoute,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(FeatureToggleServiceToken) private readonly featureToggleService: HttpFeatureToggleService) {
  }

  ngOnInit() {
    this.ftService = this.featureToggleService.getBatchExportFeatureToggleSettings()
      .subscribe((featureSettings: FeatureToggleSetting[]) => {
        let setting: FeatureToggleSetting | undefined;
        if (featureSettings && featureSettings.length > 0) {
          setting = featureSettings.find((s: FeatureToggleSetting) => s.featureName.toLowerCase() ===
            FeatureToggleEntries.ENABLE_MULTIPROVIDER_SECURITY.toLowerCase());
        }
        if (setting && setting.enabled) {
          const reportHeaderIdParam = this.router.snapshot.paramMap.get('reportHeaderId');
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          const emailParam = this.router.snapshot.paramMap.get('email');
          const guidParam = this.router.snapshot.paramMap.get('guid');
          if (reportIdParam && !isNaN(+reportIdParam) && reportHeaderIdParam && !isNaN(+reportHeaderIdParam) && emailParam && guidParam) {
            this.batchService = this.batchExportService.isValidBatchRequest(+reportHeaderIdParam, emailParam, guidParam)
              .subscribe((resp: boolean) => {
                this.showPdfPage = resp;
                if (resp) {
                  this.guid = guidParam;
                  this.detailReportId = +reportIdParam;
                  this.generatePdf(this.detailReportId);
                }
              });
          }
        } else {
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          if (reportIdParam && !isNaN(+reportIdParam)) {
            this.detailReportId = +reportIdParam;
            this.showPdfPage = true;
            this.generatePdf(this.detailReportId);
          }
        }
      });
  }

  ngOnDestroy() {
    this.ftService?.unsubscribe();
    this.batchService?.unsubscribe();
  }

  generatePdf(detailReportId: number) {
    this.batchExportService.getBatchExportDetailsByDetailId(detailReportId).subscribe((res: BatchExportDetailResponse) => {
      if (res && res.batchExportDetails && res.batchExportDetails.length > 0) {
        this.detailData = res.batchExportDetails[0];
        this.emMetrics = [];
        if (res.batchExportDetails[0].isEmPageOutpatientRequired) {
          this.emMetrics.push(EmDimension.Outpatient);
        }
        if (res.batchExportDetails[0].isEmPageInPatientRequired) {
          this.emMetrics.push(EmDimension.InPatient);
        }
        if (res.batchExportDetails[0].isEmPageEmergencyMedicineRequired) {
          this.emMetrics.push(EmDimension.EmergencyMedicine);
        }
        if (res.batchExportDetails[0].isEmPageOpthalmologyRequired) {
          this.emMetrics.push(EmDimension.Ophthalmology);
        }
        this.batchFilterCriteria = getBatchFilterFromExportDetail(this.detailData, this.guid);
        this.previousBatchFilterCriteria = getBatchFilterFromExportDetail(this.detailData, this.guid, true);
        this.nodePath = this.batchFilterCriteria.nodePath;
        this.nodeName = this.detailData.providerName;
        this.splNodeName = this.detailData.specialtyName;
        this.benchmarkOption = translateNumberToBenchmarkOption(this.detailData.emPageBenchmarkType ?? BenchmarkOption.Academic.valueOf());
        const specialtyBatchFilter = combineBatchFilter(this.batchFilterCriteria, FilterType.Specialty);
        forkJoin({
          emData: this.batchExportService.getEmData(this.batchFilterCriteria),
          emSpecialtySummaryData: this.batchExportService.getEmData(specialtyBatchFilter)
        }).subscribe(({emData, emSpecialtySummaryData}) => {
          this.emData = emData;
          this.emSpecialtyData = emSpecialtySummaryData;
          this.dateRange = {
            startMonth: roundToNumber(this.batchFilterCriteria.startMonth, 0),
            startYear: roundToNumber(this.batchFilterCriteria.startYear, 0),
            endMonth: roundToNumber(this.batchFilterCriteria.endMonth, 0),
            endYear: roundToNumber(this.batchFilterCriteria.endYear, 0)
          };
          this.selectedDateRange = getSelectedDateRange(this.dateRange, true);
          this.previousSelectedDateRange = getPreviousYearDateRange(this.dateRange, true);
        });
      }
    });
  }
}
