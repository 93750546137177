<div id="invoiceStatus-component-wrapper">
  <div id="select-invoiceStatus-header">
    <div id="upper-header">Invoice Status</div>
    <div class="note">Note: Invoice Status is only applicable to <b>Collections</b>.</div>
  </div>
  <div id="select-invoiceStatus-body">
    <mat-radio-group id="invoiceStatus-options">
      <mat-radio-button value="0"
                        (click)="selectInvoiceStatus(invoiceStatusTypes[0].statusId, $event)"
                        [ngClass]="'invoiceStatus-selectAll'"
                        [class.active]="selectedInvoiceStatus===invoiceStatusTypes[0].statusId"
                        [checked]="selectedInvoiceStatus===invoiceStatusTypes[0].statusId"
      >
        {{invoiceStatusTypes[0].statusText}}</mat-radio-button>

      <mat-radio-button value="1" id="{{invoiceStatusTypes[1].statusText}}"
                        (click)="selectInvoiceStatus(invoiceStatusTypes[1].statusId, $event)"
                        [checked]="selectedInvoiceStatus===invoiceStatusTypes[1].statusId"
                        [ngClass]="'invoiceStatus-option'"
                        [class.active]="selectedInvoiceStatus===invoiceStatusTypes[1].statusId"

      >{{invoiceStatusTypes[1].statusText}}</mat-radio-button>

      <mat-radio-button value="2" id="{{invoiceStatusTypes[2].statusText}}"
                        (click)="selectInvoiceStatus(invoiceStatusTypes[2].statusId, $event)"
                        [checked]="selectedInvoiceStatus===invoiceStatusTypes[2].statusId"
                        [ngClass]="'invoiceStatus-option'"
                        [class.active]="selectedInvoiceStatus===invoiceStatusTypes[2].statusId"
      >{{invoiceStatusTypes[2].statusText}}</mat-radio-button>

    </mat-radio-group>

  </div>
</div>
