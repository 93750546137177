<mat-dialog-content>
  <div id="change-fiscal-year-wrapper">
    <div id="change-fiscal-year-header">Change Fiscal Year</div>
    <div id="change-fiscal-year-subheader">Pick a start month of your fiscal year</div>
    <div id="date-range">
  <span id="start-month" (click)="toggleShowMonthNames()">
    <span id="start-month-label">{{selectedFiscalStartMonth}}</span>
    <i class="fa fa-caret-down"></i>
    <mat-list *ngIf="showMonthNames" id="month-names-list">
      <mat-list-item *ngFor="let month of monthNames" (click)="setFiscalMonth($event, month)"
                     class="month-name">{{month}}</mat-list-item>
    </mat-list>
  </span>
      <span id="end-month">to {{selectedFiscalEndMonth}}</span>
    </div>
    <div id="buttons-wrapper">
      <button id="apply-button" (click)="apply()">Apply</button>
      <button id="cancel-button" (click)="cancel()">Cancel</button>
    </div>
  </div>
</mat-dialog-content>
