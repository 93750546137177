<div id="payer-wrapper">
  <div id="header">
    <div>Payer</div>
    <div class="note">Note: Payer selections only applicable to Collections, New Patient Visits, and Denials.
      <br>New Patient Visits only uses the highest level of payer.
    </div>
  </div>
  <div id="payer-list">
    <app-loader *ngIf="showLoadingIndicator"></app-loader>
    <div id="search-wrapper">
      <input type="text" placeholder="Search..." id="search-for"
             (input)="onSearchTextChanged()" [(ngModel)]="searchText">
      <i class="fa fa-search"></i>
    </div>
    <mat-radio-group>
      <mat-radio-button id="all-payers"
                        (click)="selectAllPayers()"
                        [checked]="allSelected"
                        [value]="DEFAULT_PAYER_SELECTION.item.item">
        {{DEFAULT_PAYER_SELECTION.item.displayText}}
      </mat-radio-button>
      <div id="scroll-area">
        <div class="button-label" *ngFor="let payer of displayedPayers">
          <mat-radio-button (click)="whenPayerClicked(payer)"
                            [checked]="payer.item.selected"
                            [value]="payer.item.item"
                            class="option payer-type-{{payer.item.item.level.toString()}}">
            <div class="payer-name">{{payer.item.displayText}}</div>
          </mat-radio-button>
          <i class="{{getCaretClass(payer.item.expansion)}} caret" (click)="expandOrCollapsePayer(payer)"></i>
        </div>
      </div>
    </mat-radio-group>
  </div>
</div>
