<mat-dialog-content>
  <div id="export-options">
    <div id="export-header"><h3>Export</h3></div>
    <div id="export-content">
      <p id="question-export-format">What format would you like to export?</p>
      <nav id="tab-container">
        <a *ngIf="pdfPermitted" class="pdfTab" [class.active]="showPdf" (click)="setExportTab(ExportType.pdf)">
          <img id="imgPdf" src="assets/pdf.svg" class="downloadImage" (click)="setExportTab(ExportType.pdf)"/>
          PDF
        </a>
        <a class="excelTab" [class.active]="showExcel" (click)="setExportTab(ExportType.excel)">
          <img id="imgExcel" src="assets/excel.svg" class="downloadImage" (click)="setExportTab(ExportType.excel)"/>
          Excel
        </a>
        <a class="csvTab" [class.active]="showCsv" (click)="setExportTab(ExportType.csv)">
          <img id="imgCsv" src="assets/csv.svg" class="downloadImage" (click)="setExportTab(ExportType.csv)"/>
          CSV
        </a>
      </nav>
      <div id="lineDiv">
        <hr id="separator">
      </div>
      <div id="csv-and-excel" *ngIf="showCsv || showExcel">
        <p class="question-inclusions">What would you like to include?</p>

        <mat-checkbox id="chk{{allExcelMetric.id}}"
                      [checked]="allExcelMetric.selected"
                      (click)="updateExcelExportTypes(allExcelMetric, $event)">
          {{allExcelMetric.text}}
        </mat-checkbox>

        <app-recursive-hierarchical-export-option
          [exportOptions]="exportMetricOntology"
          [initiallyExpanded]="currentlyExpanded"
          (selectedExportOption)="updateExcelExportTypes($event)"
        ></app-recursive-hierarchical-export-option>
      </div>

      <div id="pdf" *ngIf="showPdf && !isOverviewLoading">
        <div>
        <span (click)="$event.preventDefault()">
          <mat-checkbox id="chkPdf{{pdfExportMetrics[0].id}}"
                        class="check-metric"
                        [disableRipple]="true"
                        [checked]="true">{{pdfExportMetrics[0].text}}
          </mat-checkbox>
        </span>
        </div>

        <div id="em-option-wrapper">
          <div id="select-em">Please select the E&M code range that you would like to export</div>
          <div *ngFor="let type of pdfExportMetrics"
               id="metric-options-overview-em"
               class="metric-wrapper check-metric"
               (click)="$event.stopPropagation()">
            <mat-checkbox class="metric-heading em-metric-heading"
                          [checked]="pdfExportMetrics[type.id].selected"
                          id="chkPdf{{type.id}}"
                          (click)="updatePdfExportTypes(type,  $event)"
                          *ngIf="type.type === MetricType.Overview"
                          checkboxPosition="before">{{type.text}}
            </mat-checkbox>
          </div>
        </div>
        <br/>
      </div>
    </div>
  </div>

  <hr id="bottomLine">

  <div id="export-buttons">
    <button id="cancel-button" mat-flat-button (click)="closeExportOptions()">Cancel</button>
    <button [ngClass]="(showPdf && isExporting) || !shouldEnableButton ? 'grey-button' : 'export-button'"
            mat-raised-button
            (click)="export()"
            [disabled]="(showPdf && isExporting) || !shouldEnableButton">Export
    </button>
    <span id="wait-for-export" *ngIf="isExporting">please wait...</span>
  </div>
</mat-dialog-content>
