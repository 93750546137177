<mat-table [dataSource]="dataSource" id="exported-reports-summary-table">
  <ng-container *ngFor="let col of columns; let i = index" matColumnDef="{{col.columnDef}}" id="{{col.id}}">
    <mat-header-cell *matHeaderCellDef [style]="i === 0 ? 'flex: ' + reportNameFlexRatio : ''">
      {{col.headerText}}
      <mat-icon *ngIf="col.hoverMessage" [matTooltip]="col.hoverMessage" [matTooltipClass]="'custom-tooltip'"
                id="{{col.id}}-info" class="info">info
      </mat-icon>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="{{col.columnDef}}-cell" [style]="i === 0 ? 'flex: ' + reportNameFlexRatio : ''"
              (click)="col.columnDef==='delete' && delete(row.reportId)">
      <span id="unread-report-status" *ngIf= "i===0 && row.reportStatus===2 && !row.readStatus">
        <i class="fa fa-circle"></i>
      </span>
      <a *ngIf="col.columnDef === 'reportName' && row.reportStatus === BatchExportStatus.COMPLETED" class="report-link"
         #refEl
         [matTooltip]="col.tableText(row)"
         [matTooltipDisabled]="col.columnDef !== 'reportName' || !canShowTooltip(refEl)"
         (click)="downloadRecord.emit(row)">{{col.tableText(row)}}</a>
      <span *ngIf="col.columnDef !== 'reportName' || row.reportStatus !== BatchExportStatus.COMPLETED"
            class="cell-text"
            #refEl
            [matTooltip]="col.tableText(row)"
            [matTooltipDisabled]="col.columnDef !== 'reportName' || !canShowTooltip(refEl)">
        {{col.tableText(row)}}
      </span>
      <mat-icon *ngIf=col.icon class="{{col.icon}}">{{col.icon}}</mat-icon>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="stringColumnsForRowDef"></mat-header-row>
  <mat-row *matRowDef="let row; columns: stringColumnsForRowDef;"></mat-row>
</mat-table>
