import {Component, Inject, OnInit} from '@angular/core';
import {getAppConfigValue} from '../shared/helpers';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {ApplicationConfigurationSetting, MemberData} from '../shared/models';
import {ApplicationConfigurationService, ApplicationConfigurationServiceToken} from '../services/app-configuration.service';
import {Observable, Subscription} from 'rxjs';
import {select} from '@angular-redux/store';
import {OktaAuth} from '@okta/okta-auth-js';

@Component({
  selector: 'app-unauthorized-error-page',
  templateUrl: './unauthorized-error-page.component.html',
  styleUrls: ['./unauthorized-error-page.component.scss'],
})
export class UnauthorizedErrorPageComponent implements OnInit {
  // appConfigSettings: ApplicationConfigurationSetting[];
  // private applicationConfigurationSubscription: Subscription;
  //
  // @select(['data', 'selectedMemberData'])
  // private readonly memberData$: Observable<MemberData>;
  // private memberDataSubscription: Subscription;


  constructor(
    private readonly authService: OktaAuth,
    // @Inject(ApplicationConfigurationServiceToken) private readonly applicationConfigurationService: ApplicationConfigurationService,
  ) { }

  ngOnInit() {
  }

  // logout() {
  //   this.memberDataSubscription?.unsubscribe();
  //   this.memberDataSubscription = this.memberData$.subscribe(memberData => {
  //     this.applicationConfigurationSubscription?.unsubscribe();
  //     this.applicationConfigurationSubscription =
  //       this.applicationConfigurationService.getApplicationConfigurationSettings()
  //         .subscribe((settings: ApplicationConfigurationSetting[]) => {
  //           if (settings) {
  //             this.appConfigSettings = settings;
  //           }
  //         });
  //
  //     this.authService.signOut({postLogoutRedirectUri: getAppConfigValue(AppConfigEntries.LOGOUT_WEBSITE, this.appConfigSettings)
  //     }).then(() => {
  //       window.open(getAppConfigValue(AppConfigEntries.LOGOUT_WEBSITE, this.appConfigSettings), '_self');
  //     });
  //   });
  // }
}
