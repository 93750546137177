import {Component, Input, OnChanges} from '@angular/core';
import {MetricType} from '../../../shared/metric-types';
import {CptCategories, EmDimension, EvaluationManagementMultilevel} from '../../../shared/models';
import {
  EvaluationManagementEmergencyMultilevelRow,
  EvaluationManagementEyeExamMultilevelRow, EvaluationManagementInpatientMultilevelRow,
  EvaluationManagementOutpatientMultilevelRow,
  getEmergencyTableRowsFromML,
  getEyeExamTableRowsFromML,
  getInpatientTableRowsFromML,
  getOutpatientTableRowsFromML
} from '../../../evaluation-management/em-helpers';
import {BenchmarkOption, MultilevelTab} from '../../../shared/enums';

@Component({
  selector: 'app-evaluation-management-ml-tile-batch-export',
  templateUrl: './evaluation-management-ml-tile-batch-export.component.html',
  styleUrls: ['./evaluation-management-ml-tile-batch-export.component.scss']
})
export class EvaluationManagementMlTileBatchExportComponent implements OnChanges {

  @Input() level: string;
  @Input() nodePath: string;
  @Input() nodeName: string;
  @Input() selectedDateRange: string;
  @Input() batchMultilevelEMData: EvaluationManagementMultilevel | undefined = undefined;
  @Input() batchMultilevelEMSpecialtySummaryData: EvaluationManagementMultilevel | undefined = undefined;
  @Input() emMetrics: EmDimension[] = [];
  @Input() benchmarkOption: BenchmarkOption;
  title = 'Evaluation & Management';
  titlePrefix = 'Evaluation & Management ';
  showOutpatient = false;
  showInpatient = false;
  showEmergencyMedicine = false;
  showOphthalmology = false;
  totalCptCounts: CptCategories;
  splCptCounts: CptCategories;
  metricType = MetricType.EandM;
  outpatientRows: EvaluationManagementOutpatientMultilevelRow[] = [];
  inpatientRows: EvaluationManagementInpatientMultilevelRow[] = [];
  emergencyRows: EvaluationManagementEmergencyMultilevelRow[] = [];
  eyeExamRows: EvaluationManagementEyeExamMultilevelRow[] = [];

  constructor() { }

  ngOnChanges(): void {
    this.title = this.titlePrefix + this.level;
    this.showOutpatient = false;
    this.showInpatient = false;
    this.showEmergencyMedicine = false;
    this.showOphthalmology = false;
    this.emMetrics.forEach(metric => {
      switch (metric) {
        case EmDimension.Outpatient:
          this.showOutpatient = true;
          if (this.batchMultilevelEMData) {
            this.outpatientRows = getOutpatientTableRowsFromML(MultilevelTab.BY_PROVIDER,
              this.batchMultilevelEMData.evaluationManagementData, false);
          }
          break;
        case EmDimension.InPatient:
          this.showInpatient = true;
          if (this.batchMultilevelEMData) {
            this.inpatientRows = getInpatientTableRowsFromML(MultilevelTab.BY_PROVIDER,
              this.batchMultilevelEMData.evaluationManagementData, false);
          }
          break;
        case EmDimension.EmergencyMedicine:
          this.showEmergencyMedicine = true;
          if (this.batchMultilevelEMData) {
            this.emergencyRows = getEmergencyTableRowsFromML(MultilevelTab.BY_PROVIDER,
              this.batchMultilevelEMData.evaluationManagementData, false);
          }
          break;
        case EmDimension.Ophthalmology:
          this.showOphthalmology = true;
          if (this.batchMultilevelEMData) {
            this.eyeExamRows = getEyeExamTableRowsFromML(MultilevelTab.BY_PROVIDER,
              this.batchMultilevelEMData.evaluationManagementData, false);
          }
          break;
      }
    });
    if (this.batchMultilevelEMData) {
      this.totalCptCounts = this.batchMultilevelEMData.evaluationManagementData
        .providerEvaluationManagementSnapshotData.totals;
    }
    if (this.batchMultilevelEMSpecialtySummaryData) {
      this.splCptCounts = this.batchMultilevelEMSpecialtySummaryData.evaluationManagementData
        .providerEvaluationManagementSnapshotData.totals;
    }
  }
}
