import {Injectable, InjectionToken} from '@angular/core';
import {AngularCsv} from '../shared/libs/angular-csv/angular-csv';

export const CSVToken =
  new InjectionToken<CSV>('CSV token');

export interface CSV {
  export(data: any, filename: string, options?: any): void;
}

@Injectable()
export class AngularCSV implements CSV {
  export(data: any, filename: string, options?: any): void {
    // tslint:disable-next-line
    new AngularCsv(data, filename, options);
  }
}
