import {Component, Input, OnChanges} from '@angular/core';
import {
  CptCategories,
  CptSummary,
  EmDimension,
  EvaluationManagementMultilevel,
  Legend,
  ProviderMultilevelCptCounts
} from '../../../../shared/models';
import {
  EvaluationManagementInpatientMultilevelRow,
  getDefaultCptSummary,
  getEmSnapshotColumnsWithExtendedBenchmarkOptions,
  getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions,
} from '../../../../evaluation-management/em-helpers';
import {BenchmarkOption, MultilevelTab} from '../../../../shared/enums';
import {DataTableColumns} from '../../../../shared/data-table-columns';
import {getEmLegends} from '../../../../evaluation-management/em-chart-helpers';
import {MetricType} from '../../../../shared/metric-types';

@Component({
  selector: 'app-em-inpatient-batch-export',
  templateUrl: './em-inpatient-batch-export.component.html',
  styleUrls: ['./em-inpatient-batch-export.component.scss']
})
export class EmInpatientBatchExportComponent implements OnChanges {

  @Input() totalCptCounts?: CptCategories;
  @Input() showProgressBar: boolean;
  @Input() chartHeight: number;
  @Input() overviewPageView: boolean;
  @Input() chartWidth: number;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() fromPdf: boolean;
  @Input() receivedTableData: EvaluationManagementInpatientMultilevelRow[];
  @Input() specialtyCptCounts?: CptCategories;
  @Input() batchMultilevelEMData: EvaluationManagementMultilevel | undefined = undefined;
  @Input() showTable = false;
  columns: DataTableColumns[] = [];
  tableData: ProviderMultilevelCptCounts[] = [];
  MultilevelTab = MultilevelTab;

  initialHospitalCareCategories = ['99221', '99222', '99223'];
  subsequentHospitalCareCategories = ['99231', '99232', '99233'];
  hospitalDischargeDayCategories = ['99238', '99239'];
  totalInitialHospitalCareCptSummaries: (CptSummary | undefined)[];
  totalSubsequentHospitalCareCptSummaries: (CptSummary | undefined)[];
  totalHospitalDischargeDayCptSummaries: (CptSummary | undefined)[];
  specialtyTotalInitialHospitalCareCptSummaries: (CptSummary | undefined)[];
  specialtyTotalSubsequentHospitalCareCptSummaries: (CptSummary | undefined)[];
  specialtyTotalHospitalDischargeDayCptSummaries: (CptSummary | undefined)[];
  initialHospitalCareCptMaxValue = 0;
  subsequentHospitalCareCptMaxValue = 0;
  hospitalDischargeDayCptMaxValue = 0;
  specialtyInitialHospitalCareCptMaxValue = 0;
  specialtySubsequentHospitalCareCptMaxValue = 0;
  specialtyHospitalDischargeDayCptMaxValue = 0;
  maxInitialHospitalCareYAxisValue: number;
  maxSubsequentHospitalCareYAxisValue: number;
  maxHospitalDischargeDayYAxisValue: number;
  legends: Legend[];
  metricType = MetricType.EandMInpatient;

  ngOnChanges(): void {
    this.legends = getEmLegends(this.foundNodeName, true);
    this.setUpTable();
    this.chartHeight = this.overviewPageView ? 250 : this.chartHeight;

    if (!this.totalCptCounts) {
      this.totalInitialHospitalCareCptSummaries = [];
      this.totalSubsequentHospitalCareCptSummaries = [];
      this.totalHospitalDischargeDayCptSummaries = [];
      this.initialHospitalCareCategories.forEach(_ => {
        this.totalInitialHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.subsequentHospitalCareCategories.forEach(_ => {
        this.totalSubsequentHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.hospitalDischargeDayCategories.forEach(_ => {
        this.totalHospitalDischargeDayCptSummaries.push(getDefaultCptSummary());
      });

      this.initialHospitalCareCptMaxValue = 0;
      this.subsequentHospitalCareCptMaxValue = 0;
      this.hospitalDischargeDayCptMaxValue = 0;
      return;
    }

    if (!this.specialtyCptCounts) {
      this.specialtyTotalHospitalDischargeDayCptSummaries = [];
      this.specialtyTotalInitialHospitalCareCptSummaries = [];
      this.specialtyTotalSubsequentHospitalCareCptSummaries = [];
      this.initialHospitalCareCategories.forEach(_ => {
        this.specialtyTotalHospitalDischargeDayCptSummaries.push(getDefaultCptSummary());
      });

      this.subsequentHospitalCareCategories.forEach(_ => {
        this.specialtyTotalSubsequentHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.hospitalDischargeDayCategories.forEach(_ => {
        this.specialtyTotalHospitalDischargeDayCptSummaries.push(getDefaultCptSummary());
      });

      this.initialHospitalCareCategories.forEach(_ => {
        this.specialtyTotalInitialHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.initialHospitalCareCptMaxValue = 0;
      this.subsequentHospitalCareCptMaxValue = 0;
      this.hospitalDischargeDayCptMaxValue = 0;
    }

    if (this.totalCptCounts.initialHospitalCare) {
      this.totalInitialHospitalCareCptSummaries = [
        this.totalCptCounts.initialHospitalCare.cpt99221,
        this.totalCptCounts.initialHospitalCare.cpt99222,
        this.totalCptCounts.initialHospitalCare.cpt99223,
      ];

      this.initialHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalInitialHospitalCareCptSummaries, this.benchmarkOption);
    }

    if (this.totalCptCounts.subsequentHospitalCare) {
      this.totalSubsequentHospitalCareCptSummaries = [
        this.totalCptCounts.subsequentHospitalCare.cpt99231,
        this.totalCptCounts.subsequentHospitalCare.cpt99232,
        this.totalCptCounts.subsequentHospitalCare.cpt99233,
      ];

      this.subsequentHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalSubsequentHospitalCareCptSummaries, this.benchmarkOption);
    }

    if (this.totalCptCounts.hospitalDischargeDay) {
      this.totalHospitalDischargeDayCptSummaries = [
        this.totalCptCounts.hospitalDischargeDay.cpt99238,
        this.totalCptCounts.hospitalDischargeDay.cpt99239,
      ];

      this.hospitalDischargeDayCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalHospitalDischargeDayCptSummaries, this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.initialHospitalCare) {
      this.specialtyTotalInitialHospitalCareCptSummaries = [
        this.specialtyCptCounts.initialHospitalCare.cpt99221,
        this.specialtyCptCounts.initialHospitalCare.cpt99222,
        this.specialtyCptCounts.initialHospitalCare.cpt99223,
      ];
      this.specialtyInitialHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalInitialHospitalCareCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.subsequentHospitalCare) {
      this.specialtyTotalSubsequentHospitalCareCptSummaries = [
        this.specialtyCptCounts.subsequentHospitalCare.cpt99231,
        this.specialtyCptCounts.subsequentHospitalCare.cpt99232,
        this.specialtyCptCounts.subsequentHospitalCare.cpt99233,
      ];

      this.specialtySubsequentHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalSubsequentHospitalCareCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.hospitalDischargeDay) {
      this.specialtyTotalHospitalDischargeDayCptSummaries = [
        this.specialtyCptCounts.hospitalDischargeDay.cpt99238,
        this.specialtyCptCounts.hospitalDischargeDay.cpt99239,
      ];

      this.specialtyHospitalDischargeDayCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalHospitalDischargeDayCptSummaries,
          this.benchmarkOption);
    }

    this.maxInitialHospitalCareYAxisValue = Math.max(this.initialHospitalCareCptMaxValue,
      this.specialtyInitialHospitalCareCptMaxValue);

    this.maxSubsequentHospitalCareYAxisValue = Math.max(this.subsequentHospitalCareCptMaxValue,
      this.specialtySubsequentHospitalCareCptMaxValue);

    this.maxHospitalDischargeDayYAxisValue = Math.max(
      this.hospitalDischargeDayCptMaxValue,
      this.specialtyHospitalDischargeDayCptMaxValue);
  }

  private setUpTable(): void {
    this.columns =
      getEmSnapshotColumnsWithExtendedBenchmarkOptions(EmDimension.InPatient, this.benchmarkOption, MultilevelTab.BY_PROVIDER);
    this.tableData = this.batchMultilevelEMData?.evaluationManagementData.providerEvaluationManagementSnapshotData
      .nodes || [];
  }
}
