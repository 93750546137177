<app-filter-listener-injector></app-filter-listener-injector>
<div id="container">
  <mat-sidenav-container id="body">
    <!--todo: are we using side nav?-->
    <mat-sidenav #appDrawer id="mat-side-nav" mode="over"></mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
