import {CptViewType, MultilevelTab, MultilevelTabCollections} from '../shared/enums';
import {EmDimension} from '../shared/models';

export type TabNavigationElementDimension = MultilevelTab | MultilevelTabCollections | CptViewType | EmDimension;

export interface TabNavigationElement {
  dimension: TabNavigationElementDimension;
  disabled?: boolean;
  label: string;
  selector: string;
  tooltipMessage?: string;
}

export function ontologyTabEquivalentOfLocationTab(tab: MultilevelTab): MultilevelTab {
  switch (tab) {
    case MultilevelTab.LOCATION_DEPARTMENT:
      return MultilevelTab.BY_DEPARTMENT;
    case MultilevelTab.LOCATION_SPECIALTY:
      return MultilevelTab.BY_SPECIALTY;
    case MultilevelTab.LOCATION_PROVIDER:
      return MultilevelTab.BY_PROVIDER;
    default:
      return tab;
  }
}
