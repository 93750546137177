<app-batch-export-pdf-header
  [batchExportDetails]="detailData"
  [headerClass]="'batch-export-npv-header-wrapper'"
  [showPayer]="true"
></app-batch-export-pdf-header>
<app-npv-month-batch-pdf
  [data]="npvData"
  [benchmarkOption]="benchmarkOption"
  [benchmarkPercentile]="benchmarkPercentile"
></app-npv-month-batch-pdf>
<app-npv-summary-batch-pdf
  [summaryData]="npvSummaryData"
  [dateRange]="dateRange"
  [benchmarkPercentile]="benchmarkPercentile"
  [benchmarkOption]="benchmarkOption"
></app-npv-summary-batch-pdf>
<app-npv-trend-table-batch-pdf
  [newPatientVisitTrendEntries]="mergedNpvTrendData"
  [benchmarkPercentile]="benchmarkPercentile"
  [benchmarkOption]="benchmarkOption"
></app-npv-trend-table-batch-pdf>
<span id="zip-wrapper">
  <app-zip-code-npv-batch-export [zipCodeNewPatientVisits]="zipNpvData"></app-zip-code-npv-batch-export>
</span>
<app-footer [fromPdf]="true"></app-footer>
