import {MonthNumber, toTitleCase} from '../../shared/helpers';
import {TabNavigationElement} from '../../tab-navigation/tab-helper';
import * as _ from 'lodash';
import {DEPARTMENT_TAB, PROVIDER_TAB, SPECIALTY_TAB, TREND_TAB} from '../../shared/constants';
import {formatNumberToWholeNumber, getCssClass, roundTo} from '../../productivity-summary/number-formatter';
import {ColumnType, MultilevelTab} from '../../shared/enums';
import {DataTableColumns} from '../../shared/data-table-columns';
import {MemberLocationInfoBase, MultilevelNode} from '../../shared/models';
import {toMonthName} from '../../productivity-summary/month-formatter';
import {convertSnapshotColumnsForLocationAggregation} from './npv-helpers';
import {StyleableEntry} from '../../shared/style-models';

export interface NewPatientVisitsPercentageBenchmarks {
  benchmarkMean: number;
  benchmark25thPercentile: number;
  benchmark50thPercentile: number;
  benchmark75thPercentile: number;
  benchmark90thPercentile: number;

  [key: string]: number;
}

export interface NewPatientVisitsVariance {
  varianceMean: number;
  variance25thPercentile: number;
  variance50thPercentile: number;
  variance75thPercentile: number;
  variance90thPercentile: number;

  [key: string]: number;
}

export interface NewPatientVisitsBase {
  countOfNewPatientVisits: number;
  countOfTotalPatientVisits: number;
  newPatientVisitsPercentage: number;
  countOfExistingPatientVisits?: number;
}

export interface NewPatientVisitsData extends NewPatientVisitsBase {
  difference: number;
  newPatientVisitsPercentageBenchmarks: NewPatientVisitsPercentageBenchmarks;
  newPatientVisitsCommunityPercentageBenchmarks: NewPatientVisitsPercentageBenchmarks;
  newPatientVisitsTelehealthPercentageBenchmarks: NewPatientVisitsPercentageBenchmarks;
  newPatientVisitsInPersonPercentageBenchmarks: NewPatientVisitsPercentageBenchmarks;
  newPatientVisitsCommunityTelehealthPercentageBenchmarks: NewPatientVisitsPercentageBenchmarks;
  newPatientVisitsCommunityInPersonPercentageBenchmarks: NewPatientVisitsPercentageBenchmarks;
  newPatientVisitsVariance: NewPatientVisitsVariance;
  newPatientVisitsCommunityVariance: NewPatientVisitsVariance;
  newPatientVisitsTelehealthVariance: NewPatientVisitsVariance;
  newPatientVisitsInPersonVariance: NewPatientVisitsVariance;
  newPatientVisitsCommunityTelehealthVariance: NewPatientVisitsVariance;
  newPatientVisitsCommunityInPersonVariance: NewPatientVisitsVariance;

  [key: string]: number | NewPatientVisitsPercentageBenchmarks | NewPatientVisitsVariance | MonthNumber
    | string | MonthNewPatientVisitEntry | NewPatientVisitSnapshotMultiLevel | boolean | undefined;
}

export interface MonthNewPatientVisitData {
  monthNewPatientVisitCounts: MonthNewPatientVisitEntry[];
}

export interface MonthNewPatientVisitEntry extends NewPatientVisitsData {
  month: MonthNumber;
  year: number;

  [key: string]: any;
}

export interface NewPatientVisitTrendEntry extends MonthNewPatientVisitEntry {
  previous: MonthNewPatientVisitEntry;
}

export interface NewPatientVisitSnapshotMultiLevel extends NewPatientVisitsData, MultilevelNode {
  nodeId: number;
  nodeName: string;
  nodePath: string;

  [key: string]: any;
}

export interface MergedNewPatientVisitSnapshotEntry extends NewPatientVisitSnapshotMultiLevel {
  previous: NewPatientVisitSnapshotMultiLevel;
  isHidden: boolean;

  [key: string]: any;
}

export type NpvDataWithPreviousEntry = MergedNewPatientVisitSnapshotEntry | NewPatientVisitTrendEntry;

export interface ProviderNewPatientVisitMultiLevelData {
  departmentNpvSnapshotData: NewPatientVisitSnapshotMultiLevel[];
  specialtyNpvSnapshotData: NewPatientVisitSnapshotMultiLevel[];
  providerNpvSnapshotData: NewPatientVisitSnapshotMultiLevel[];
}

export interface NpvSnapshotResponse {
  newPatientVisitsSnapshotData: ProviderNewPatientVisitMultiLevelData;
}

export interface MergedProviderNewPatientVisitMultiLevelData {
  departmentNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[];
  specialtyNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[];
  providerNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[];
}

export const npvTabs: TabNavigationElement[] = [
  _.cloneDeep(DEPARTMENT_TAB),
  _.cloneDeep(SPECIALTY_TAB),
  _.cloneDeep(PROVIDER_TAB),
  _.cloneDeep(TREND_TAB)
];

function commonNpvColumns(): DataTableColumns[] {
  return [{
    columnDef: 'newPatientVisitsPercentage', header: '% New Patient Visits',
    dataName: (row: NpvDataWithPreviousEntry) => `${roundTo(row.newPatientVisitsPercentage, 1)}%`
  }, {
    columnDef: 'countOfNewPatientVisits',
    header: '# of New Patients',
    dataName: (row: NpvDataWithPreviousEntry) => `${formatNumberToWholeNumber(row.countOfNewPatientVisits)}`
  },
    {
      columnDef: 'countOfTotalPatientVisits',
      header: 'Total # of Patients',
      dataName: (row: NpvDataWithPreviousEntry) => `${formatNumberToWholeNumber(row.countOfTotalPatientVisits)}`
    },
    {
      columnDef: 'benchmarkMean',
      header: '% New Patients Benchmark Mean',
      dataName: (row: NpvDataWithPreviousEntry) => `${roundTo(
        row.newPatientVisitsPercentageBenchmarks.benchmarkMean, 1)}%`,
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'varianceMean',
      header: 'NPV Variance Mean',
      dataName: (row: NpvDataWithPreviousEntry) => `${roundTo(
        row.newPatientVisitsVariance.varianceMean, 1)}%`,
      columnType: ColumnType.VARIANCE,
      group: 'group-last'
    },
    {
      columnDef: 'previousNewPatientVisitsPercentage',
      header: 'Previous Dates % New Patients',
      showDates: true,
      dataName: (row: NpvDataWithPreviousEntry) => `${roundTo(row.previous.newPatientVisitsPercentage, 1)}%`,
      columnType: ColumnType.PREVIOUS,
      group: 'group-first'
    },
    {
      columnDef: 'difference',
      header: '% New Patient Visits Difference from Previous Date Range',
      dataName: (row: NpvDataWithPreviousEntry) => `${roundTo(row.difference, 1)}%`,
      class: (row: NpvDataWithPreviousEntry) => `${getCssClass(row.difference)}`
    }];
}

export function multilevelNpvDeptColumns(): DataTableColumns[] {
  return [
    {
      columnDef: 'departmentNodeName',
      header: 'Department',
      dataName: (row: MergedNewPatientVisitSnapshotEntry) => `${toTitleCase(row.departmentNodeName)}`,
      primaryColumn: true,
      columnType: ColumnType.NODE_NAME
    }, ...commonNpvColumns()
  ];
}

function specialtyNodeColumnForNpv(): DataTableColumns {
    return {
      columnDef: 'specialtyNodeName',
      header: 'Specialty',
      dataName: (row: MergedNewPatientVisitSnapshotEntry) => `${toTitleCase(row.specialtyNodeName)}`,
      primaryColumn: true,
      columnType: ColumnType.NODE_NAME
  };
}

export function multilevelNpvSpecColumns(): DataTableColumns[] {
  return [
    specialtyNodeColumnForNpv(), ...multilevelNpvDeptColumns()
  ];
}

function providerNodeColumnForNpv(): DataTableColumns {
  return {
    columnDef: 'providerNodeName',
    header: 'Provider',
    dataName: (row: MergedNewPatientVisitSnapshotEntry) => `${toTitleCase(row.providerNodeName)}`,
    primaryColumn: true,
    columnType: ColumnType.NODE_NAME
  };
}

export function multilevelNpvProvColumns(): DataTableColumns[] {
  return [
    providerNodeColumnForNpv(),
    ...multilevelNpvSpecColumns()
  ];
}

export function columnsForNPVtrend(): DataTableColumns[] {
  return [{
    columnDef: 'year', header: 'Year', dataName: (row: NewPatientVisitTrendEntry) => `${row.year.toString()}`,
    primaryColumn: true
  }, {
    columnDef: 'month',
    header: 'Month',
    dataName: (row: NewPatientVisitTrendEntry) => `${toMonthName(row.month.toString())}`,
    primaryColumn: true
  },
    ...commonNpvColumns()];
}

function npvLocationColumn(): DataTableColumns {
    return {
    columnDef: 'memberLocationName', header: 'Location', columnType: ColumnType.LOCATION,
    primaryColumn: true,
    dataName: (row: NpvLocationWithSnapshotEntries) => row.memberLocationName
  };
}

export function npvLocationDepartmentColumns(): DataTableColumns[] {
  return [
    npvLocationColumn(),
    ...convertSnapshotColumnsForLocationAggregation(multilevelNpvDeptColumns())
  ];
}

export function npvLocationSpecialtyColumns(): DataTableColumns[] {
  return [
    npvLocationColumn(),
    ...convertSnapshotColumnsForLocationAggregation(multilevelNpvSpecColumns())
  ];
}

export function npvLocationProviderColumns(): DataTableColumns[] {
  return [
    npvLocationColumn(),
    ...convertSnapshotColumnsForLocationAggregation(multilevelNpvProvColumns())
  ];
}

export interface NpvByLocation extends NewPatientVisitsBase {
  memberLocationKey: number;
  memberLocationName: string;
}

export interface NpvLocationAggregatedByNode extends NpvByLocation {
  departmentNpvSnapshotData: NewPatientVisitSnapshotMultiLevel[];
  specialtyNpvSnapshotData: NewPatientVisitSnapshotMultiLevel[];
  providerNpvSnapshotData: NewPatientVisitSnapshotMultiLevel[];

  [key: string]: NewPatientVisitSnapshotMultiLevel[] | string | number | undefined;
}

export interface MergedNpvLocationAggregatedByNode extends NpvByLocation {
  departmentNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[];
  specialtyNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[];
  providerNpvSnapshotData: MergedNewPatientVisitSnapshotEntry[];
  previousPercentage: number;
}

export interface MergedNewPatientVisitSnapshotEntryInSingleLocation extends MergedNewPatientVisitSnapshotEntry,
  StyleableEntry {
  isTotal?: boolean;
}

export interface NpvLocationWithSnapshotEntries extends MemberLocationInfoBase {
  snapshotEntries: MergedNewPatientVisitSnapshotEntryInSingleLocation[];
}

export interface NpvByLocationForChart extends NewPatientVisitsBase {
  name: string;
  identity: string;
  benchmarkValue: number;
  drillDown?: string;
}

export interface NpvLocationResponse {
  npvLocationAggregatedByNodes: NpvLocationAggregatedByNode[];
}

export interface NpvLocationGraphData {
  npvPercentages: NpvDrillDown[];
  npvExistingCounts: NpvDrillDown[];
  npvNewCounts: NpvDrillDown[];
  npvTotalCounts: NpvDrillDown[];
  benchmarks: NpvDrillDown[];
  categories: string[];
}

export interface NpvDrillDown {
  y: number;
  drillDown?: string;
}

export interface NpvLocationViewByOption {
  identity: string;
  display: string;
  hidden: boolean;
  level?: MultilevelTab;
  all?: boolean;

  [key: string]: string | boolean | MultilevelTab | undefined;
}

export interface NpvLocationAggregationAndZeroSuppressionCount {
  entries: NpvLocationWithSnapshotEntries[];
  countOfZeroSuppressedEntries: number;
}

export const AddNpvCountsVariableText = '# New and Total Patients';

export const totalNpvLocationColumnId = 'total';
