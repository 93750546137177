<span *ngFor="let option of displayedHierarchyOptions" class="{{itemClass}} {{isFirstOption ? 'first' : ''}}">
  <span [ngClass]="isFirstOption ? 'first-option' : 'secondary-option'">
    <span class="option-wrapper">
      <span class="option-label-section">
        <span class="option-label">
          <span class="checkbox-container">
            <i [ngClass]="'fa fa-angle-'.concat(option.nodeExpansionState === NodeExpansionState.EXPANDED ? 'down' :
              option.nodeExpansionState === NodeExpansionState.COLLAPSED ? 'right' : '')"
               (click)=whenOptionExpanded(option)>
            </i>
            <mat-checkbox [checked]="option.selected" [indeterminate]="option.selected && option.anyUnselectedDescendants"
                          (change)="whenOptionSelected(option)"></mat-checkbox>
            <span class="option-text">{{option.text}}</span>
            <span *ngIf="isFirstOption && hoverMessage" id="info-icon" (mouseenter)="showHoverMessage = true"
              (mouseleave)="showHoverMessage=false">i
              <span id="info-tooltip" *ngIf="showHoverMessage">
                <span *ngFor="let token of hoverMessage.tokens" [ngClass]="token.bolded ?
                 'tooltip-bold' : 'tooltip-regular'">{{token.token}}<br *ngIf="token.breakAfter"/>
                </span>
              </span>
            </span>
          </span>
          <span *ngIf="isFirstOption && batchExportMetricSection && batchExportMetricSection.metricLabels">
            <span class="metric-label-wrapper" *ngFor="let label of batchExportMetricSection.metricLabels">
              <span *ngFor="let token of label.tokens" class="token">
                <span [ngClass]="token.bolded ? 'bolded' : ''">{{token.token}}</span>
              </span>
            </span>
          </span>
        </span>
        <app-batch-export-benchmark-selector *ngIf="isFirstOption && canShowBenchmarkSelector && !isOverView"
                                             [selected]="batchExportMetricSection && batchExportMetricSection.selected"
                                             [batchExportMetricSection]="batchExportMetricSection"
                                             (whenBenchmarkOptionChosen)="chooseBenchmarkOption($event)"
                                             (whenPercentileChosen)="choosePercentile($event)">
        </app-batch-export-benchmark-selector>
      </span>
    </span>
  </span>
  <app-mpe-hierarchical-selector
    *ngIf="((option.children.length) > 0) && nodeExpansionIsActive(option.nodeExpansionState)"
    [hierarchyOptions]="option.children"
    [topOptions]="topOptions"
    [isFirstOption]="false"
    [whenHierarchicalOptionSelected]="whenHierarchicalOptionSelected"
    [batchExportMetricSection]="batchExportMetricSection"
    [overviewSubMetricSections]="overviewSubMetricSections"
    [canShowBenchmarkSelector]="canShowBenchmarkSelector"
    [showSubMetricBenchmarkSelectors]="option.hasSubMetric"
    (whenBenchmarkOptionChosen)="chooseBenchmarkOption($event)"
    (whenPercentileChosen)="choosePercentile($event)">
  </app-mpe-hierarchical-selector>
</span>
<span *ngIf="showSubMetricBenchmarkSelectors" class="option-wrapper">
  <span class="sub-metric" *ngFor="let subMetric of overviewSubMetricSections">
    <span [ngClass]="itemClass">{{subMetric.text}}</span>
    <app-batch-export-benchmark-selector *ngIf="canShowBenchmarkSelector"
                                         class="sub-metric-benchmark-selector"
                                         [selected]="batchExportMetricSection.selected"
                                         [batchExportMetricSection]="subMetric"
                                         (whenBenchmarkOptionChosen)="subMetric.benchmarkCombo.option = $event"
                                         (whenPercentileChosen)="subMetric.benchmarkCombo.percentile = $event">
    </app-batch-export-benchmark-selector>
  </span>
</span>
