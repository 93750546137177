import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-no-access-dialog',
  templateUrl: './no-access-dialog.component.html',
  styleUrls: ['./no-access-dialog.component.scss'],
})
export class NoAccessDialogComponent implements OnInit {
  @Input() memberName: string;

  constructor(
    public dialogRef: MatDialogRef<NoAccessDialogComponent>) { }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close();
  }

  email() {
    window.open('mailto:CPSCSupport@vizientinc.com', '_self');
  }

}
