import {NgModule} from '@angular/core';
import {
  VizBreadcrumbModule,
  VizContainerModule,
  VizDrawerModule,
  VizFeedbackIndicatorModule,
  VizFileUploadModule,
  VizFooterModule,
  VizHeaderModule,
  VizHttpStatusModule,
  VizNodataModule
} from 'vizient-toolkit';
import {CommonModule} from '@angular/common';

const vizList = [
  VizBreadcrumbModule,
  VizDrawerModule,
  VizFeedbackIndicatorModule,
  VizFileUploadModule,
  VizFooterModule,
  VizHeaderModule,
  VizHttpStatusModule,
  VizNodataModule,
  VizContainerModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...vizList
  ],
  exports: [
    ...vizList
  ]
})
export class AppToolkitModule { }
