import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {deepCopyOf, getSingularOntologyLevelName, pluralizeText} from '../../../../shared/helpers';
import {CfpViewByOption, getCptLevelKeyword, getCptRangeText, getCptViewText} from '../procedure-summary-graph-helper';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {CptViewType, MultilevelTab} from '../../../../shared/enums';
import {CfpByMultilevel} from '../../../ClinicalSummary';
import {MIN_SEARCH_CHARS} from '../../../../shared/constants';

@Component({
  selector: 'app-procedure-summary-graph-filter-selector',
  templateUrl: './procedure-summary-graph-filter-selector.component.html',
  styleUrls: ['./procedure-summary-graph-filter-selector.component.scss']
})
export class ProcedureSummaryGraphFilterSelectorComponent implements OnInit, OnChanges {
  @Output() selectedCptNodeChange = new EventEmitter<CfpViewByOption>();
  @Output() nodeTextChange = new EventEmitter<string>();
  @Output() cptViewTextChange = new EventEmitter<string>();
  @Input() viewByNode: boolean;
  @Input() cptViewText: string;
  @Input() nodeText: string;
  @Input() cptViewType: CptViewType;
  @Input() multilevelTab: MultilevelTab;
  @Input() cfpData: CfpByMultilevel[] = [];
  @Input() selectedCptNode: CfpViewByOption | undefined;
  @Input() currentSelectedOntologyCptNode: CfpViewByOption | undefined;

  // @ts-ignore
  autocompleteControl = new UntypedFormControl<string>('');
  cptNodeOptions: CfpViewByOption[] = [];
  filteredOptions: CfpViewByOption[];
  searchText = '';
  searchOpen = false;
  canDisplayClearSelections = false;
  private readonly DEFAULT_GRAPH_BY_TEXT = 'Select';
  selectGraphByText = this.DEFAULT_GRAPH_BY_TEXT;

  autocompleteViewportHeight = '8px';
  readonly DEFAULT_VIEWPORT_HEIGHT = 200;
  readonly OPTION_HEIGHT = 50;
  readonly MAX_OPTIONS_IN_VIEWPORT = 4;
  readonly minSearchChars = MIN_SEARCH_CHARS;
  @ViewChild('input') autoCompleteInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  private static matchesSearchText(optionDisplay: string, copyString: string): boolean {
    const tokens = optionDisplay.toLowerCase().split(' ');
    let matches = false;
    tokens.forEach(token => {
      if (token.startsWith(copyString)) {
        matches = true;
      }
    });
    return matches;
  }

  constructor() {}

  ngOnInit(): void {
    this.autocompleteControl.valueChanges.pipe(startWith(''),
      map(value => {
        if (value) {
          value = typeof value === 'string' ? value : value.display ?? '';
          if (value.length >= 3) {
            this.filterOptionsBySearchText(value);
          } else {
            this.clearFilters();
          }
        } else {
          this.clearFilters();
        }
        this.recomputeViewportHeight();
      })
    ).subscribe();
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes && (changes.cfpData || changes.multilevelTab || changes.cptViewType || changes.viewByNode)) {
      this.updateAndEmitCptText();
      this.updateAndEmitNodeText();
      this.generateFilterByOptions();
      this.clearSearchText();
      this.recomputeViewportHeight();
    }
    this.getSelectGraphByText();
    this.setCanDisplayClearSelections();
    if (this.viewByNode && this.selectedCptNode && this.autoCompleteInput) {
      this.autoCompleteInput.nativeElement.value = this.selectedCptNode.display;
      this.searchText = this.selectedCptNode.display;
    } else if (this.currentSelectedOntologyCptNode && this.autoCompleteInput) {
      this.autoCompleteInput.nativeElement.value = this.currentSelectedOntologyCptNode.display;
      this.searchText = this.currentSelectedOntologyCptNode.display;
    }
    this.inputAutoComplete?.openPanel();
  }

  async toggleSearchPanel() {
    this.clearSearchText();
    this.searchOpen = !this.searchOpen;
    await pause();
    function pause() {
      return new Promise(
        (resolve) => {
          setTimeout(resolve, 0);
        }
      );
    }
    this.inputAutoComplete?.openPanel();
  }

  whenInput(event: any): void {
    this.searchText = event.target.value;
  }

  closeDropdown() {
    this.searchOpen = false;
  }

  clearSearchText($event?: MouseEvent): void {
    if ($event) {
      $event?.stopPropagation();
      this.inputAutoComplete.openPanel();
    }
    if (this.autoCompleteInput) {
      this.autoCompleteInput.nativeElement.value = '';
    }
    this.searchText = '';
    this.clearFilters();
    this.recomputeViewportHeight();
  }

  applyViewBy($event: MatAutocompleteSelectedEvent) {
    this.searchOpen = false;
    if ($event.option.value) {
      this.autoCompleteInput.nativeElement.value = $event.option.value.display;
      this.selectedCptNodeChange.emit($event.option.value);
    }
  }

  getFilterTypeText() {
    return this.viewByNode ? this.cptViewText : this.nodeText;
  }

  generateFilterByOptions(): void {
    const identities: any = {};
    if (this.viewByNode) {
      this.generateCptFilterOptions(identities);
    } else {
      this.generateOntologyFilterOptions(identities);
    }
  }

  clearTheSelections(): void {
    if (!this.canDisplayClearSelections) {
      return;
    }
    this.searchText = '';
    if (this.viewByNode) {
      this.setDefaultCptViewGraphByOption(true);
    } else {
      this.setFilterGraphByToAllDepartmentsSpecialtiesOrProviders(true);
    }
  }

  setDefaultCptViewGraphByOption(fromClear: boolean) {
    if (fromClear || !this.selectedCptNode || !this.selectedCptNode.identity.length) {
      this.selectedCptNodeChange.emit(this.cptNodeOptions[0]);
      this.selectedCptNode = this.cptNodeOptions[0];
      if (this.autoCompleteInput?.nativeElement) {
        this.autoCompleteInput.nativeElement.value = this.cptNodeOptions && this.cptNodeOptions[0] ? this.cptNodeOptions[0].display : '';
      }
    }
  }

  setFilterGraphByToAllDepartmentsSpecialtiesOrProviders(fromClear: boolean) {
    const allOption = this.cptNodeOptions.find(o => o.identity === '') ??
      this.getViewAllOntologyOptionForAutocompleteList();
    if (fromClear || !this.currentSelectedOntologyCptNode || !this.currentSelectedOntologyCptNode.identity.length) {
      this.selectedCptNodeChange.emit(allOption);
    }
  }

  private getSelectGraphByText(): void {
    if (this.viewByNode && this.selectedCptNode && this.cptNodeOptions.length) {
      this.selectGraphByText = this.selectedCptNode.display;
    } else if (!this.viewByNode && this.currentSelectedOntologyCptNode && this.cptNodeOptions.length) {
      this.selectGraphByText = this.currentSelectedOntologyCptNode.display;
    } else {
      this.selectGraphByText = this.DEFAULT_GRAPH_BY_TEXT;
    }
  }

  private clearFilters() {
    this.filteredOptions = this.cptNodeOptions;
  }

  private filterOptionsBySearchText(searchText: string) {
    const copyString = deepCopyOf(searchText).toLowerCase();
    this.filteredOptions = this.cptNodeOptions.filter(option => option.identity.length > 0 &&
      ProcedureSummaryGraphFilterSelectorComponent.matchesSearchText(option.display, copyString));
    this.filteredOptions.sort((a, b) => a.display.localeCompare(b.display));
  }

  private recomputeViewportHeight() {
    this.autocompleteViewportHeight = `${this.filteredOptions.length < this.MAX_OPTIONS_IN_VIEWPORT ?
      this.filteredOptions.length * this.OPTION_HEIGHT : this.DEFAULT_VIEWPORT_HEIGHT}px`;
  }

  private updateAndEmitCptText() {
    this.cptViewText = getCptViewText(this.cptViewType);
    this.cptViewTextChange.emit(this.cptViewText);
  }

  private updateAndEmitNodeText() {
    this.nodeText = getSingularOntologyLevelName(this.multilevelTab);
    this.nodeTextChange.emit(this.nodeText);
  }

  private generateOntologyFilterOptions(identities: any) {
    const nodeFieldPrefix = this.nodeText.toLowerCase();
    this.cptNodeOptions = [];
    this.cfpData.forEach(datum => {
      const identity = `${datum[`${nodeFieldPrefix}NodePath`]}`;
      if (!identities[identity]) {
        this.cptNodeOptions.push({
          identity: `${datum[`${nodeFieldPrefix}NodePath`]}`,
          display: `${datum[`${nodeFieldPrefix}NodeName`]}`,
          hidden: false, level: this.multilevelTab
        });
        identities[identity] = {
          identity: identity,
          display: `${datum[`${nodeFieldPrefix}NodeName`]}`,
          hidden: false, level: this.multilevelTab
        };
      }
    });
    this.addOptionForAllDepartmentsSpecialtiesOrProviders();
    this.setFilterGraphByToAllDepartmentsSpecialtiesOrProviders(false);
  }

  private generateCptFilterOptions(identities: any) {
    const cptLevelDescField = this.cptViewType === CptViewType.CptCode ? 'cptCode' :
      `cpt${getCptLevelKeyword(this.cptViewType)}Desc`;
    this.cptNodeOptions = [];
    this.cfpData.forEach(datum => {
      const identity = `${datum[cptLevelDescField]}`;
      if (!identities[identity]) {
        this.cptNodeOptions.push({
          identity: identity,
          display: this.cptViewType === CptViewType.CptRange ?
            getCptRangeText(datum) : `${datum[cptLevelDescField]}`,
          hidden: false, level: this.cptViewType
        });
        identities[identity] = {
          identity: identity,
          display: `${datum[cptLevelDescField]}`,
          hidden: false, level: this.cptViewType
        };
      }
    });
    this.setDefaultCptViewGraphByOption(false);
  }

  private setCanDisplayClearSelections(): void {
    this.canDisplayClearSelections = (this.viewByNode && !!this.selectedCptNode && this.cptNodeOptions.length &&
      this.selectedCptNode.identity.localeCompare(this.cptNodeOptions[0].identity) !== 0) || (!this.viewByNode &&
      !!this.currentSelectedOntologyCptNode && !!this.cptNodeOptions.length && this.currentSelectedOntologyCptNode.identity
        .localeCompare(this.cptNodeOptions[0].identity) !== 0);
  }

  private addOptionForAllDepartmentsSpecialtiesOrProviders() {
    this.cptNodeOptions.splice(0, 0, this.getViewAllOntologyOptionForAutocompleteList());
  }

  private getViewAllOntologyOptionForAutocompleteList(): CfpViewByOption {
    return {
      identity: '',
      display: `All ${pluralizeText(getSingularOntologyLevelName(this.multilevelTab))}`,
      hidden: false, level: this.multilevelTab
    };
  }
}
