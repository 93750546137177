import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {DrillDown, FilterCriteria, IAppState} from '../store/IAppState';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {
  collectionsPagesLabel,
  denialsPagesLabel,
  getAppConfigValue,
  getArbitraryDateRangeFromDatesInMonths,
  getDateRangeBySelectedOption,
  lagPeriods,
  MONTH_MAPPING,
  npvPagesLabel,
  Page,
  shallowCopyOf,
  updateCurrentNodePathLabel,
  wrvuPagesLabel
} from '../shared/helpers';
import {
  customGroupIdChangedTo,
  customGroupsDataChangedTo,
  emNpvBenchmarkOptionChangedTo,
  filterBannerEditChangedTo,
  filterCriteriaChangedTo,
  memberLocationsChangedTo,
  nodePathChangedTo,
  showHierarchySearchChangedTo,
  undoDrillDownHistory
} from '../store/actions';
import {PayerCategoryService, PayerCategoryServiceToken} from '../services/payer-category.service';
import {MemberLocationService, MemberLocationServiceToken} from '../services/member-location.service';
import * as _ from 'lodash';
import {
  DEFAULT_INVOICE_STATUS,
  DEFAULT_INVOICE_TEXT,
  DEFAULT_LAG_KEY,
  DEFAULT_LAG_NAME,
  DEFAULT_MAX_LOCATIONS,
  DEFAULT_MAX_NODE_PATHS,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_KEY,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_PAGE_LABEL,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_PAYER_FILTER_CRITERIA,
  DEFAULT_TELEHEALTH_FLAG
} from '../store/DefaultValues';
import {
  BenchmarkOption,
  FilterBannerTab,
  GoogleAnalyticCategories,
  GoogleAnalyticsActions,
  GoogleAnalyticsLabels,
  MultilevelTab,
  MultilevelTabCollections,
  PayerType
} from '../shared/enums';
import {
  ApplicationConfigurationSetting,
  CurrentMemberLocation,
  CustomGroupsDataAllFilters,
  DateRange,
  EmDimension,
  FeatureToggleSetting,
  FilterBannerSelectableTab,
  InvoiceStatus,
  LagPeriod,
  MemberBillingArea,
  MemberLocation,
  PayerCategoryLevel,
  PayerFilterCriteria,
  PdfExportData,
  SelectableLocation,
  SelectedHierarchicalNodes,
  UserProfile,
  UserSecurity,
  VisitType
} from '../shared/models';
import {ExportPdfDialogComponent} from '../export-pdf-dialog/export-pdf-dialog.component';
import {
  constructPayerString,
  customGroupDataChanged,
  filterCriteriaFromSelectedCustomGroup,
  getDefaultHierarchicalNodes,
  getSelectedDate,
  getSelectedNodePath
} from './filter-banner-helper';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {MatDialog} from '@angular/material/dialog';
import {
  WarningLimitExceededComponent
} from './advanced-navigation/warning-limit-exceeded/warning-limit-exceeded.component';
import {checkForNulls, hasValue, valuesAreDifferent} from '../shared/null-helpers';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {setOntologyLevelFromNodepath} from '../shared/test/ontology-helper';
import {SaveApplyDialogComponent} from '../shared/components/save-apply-dialog/save-apply-dialog.component';
import {AdvancedNavigationComponent} from './advanced-navigation/advanced-navigation.component';
import {Router} from '@angular/router';
import {DrillDownService} from '../services/drilldown.service';
import {invoiceStatusTypes, visitTypes} from '../shared/constants';
import {
  UpdatedSaveGroupingComponent
} from '../updated-filter-banner/updated-save-grouping/updated-save-grouping.component';
import {CustomGroupsService, CustomGroupsServiceToken} from './services/custom-groups-service';
import {determineAllowedDataset} from '../shared/compare-helpers';
import {getSelectedNodeCountsFromNodePath} from '../shared/ontology-helpers';
import {createNewCustomGroupFromFilter, GroupDialogEvent} from '../updated-filter-banner/manage-groups-helper';
import {SearchMultipleLocationsComponent} from './search-multiple-locations/search-multiple-locations.component';
import {getOntologyData} from '../shared/localStoragehelper';

@Component({
  selector: 'app-filter-banner',
  templateUrl: './filter-banner.component.html',
  styleUrls: ['./filter-banner.component.scss']
})
export class FilterBannerComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(PayerCategoryServiceToken) private readonly payerCategoryService: PayerCategoryService,
              @Inject(MemberLocationServiceToken) private readonly memberLocationService: MemberLocationService,
              @Inject(CustomGroupsServiceToken) private readonly customGroupsService: CustomGroupsService,
              @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
              public dialog: MatDialog,
              private drillDownService: DrillDownService,
              public router: Router,
              private cdr: ChangeDetectorRef
  ) {
  }

  @ViewChild(AdvancedNavigationComponent) advancedNavigationComponent: AdvancedNavigationComponent;
  @ViewChild(SearchMultipleLocationsComponent) searchMultipleLocationsComponent: SearchMultipleLocationsComponent;
  @ViewChild('pdfContent', {static: true}) pdfContent: ExportPdfDialogComponent;

  customGroupDefault?: CustomGroupsDataAllFilters;
  editMode = false;
  currentTabIndex: number;
  availableTabs: FilterBannerSelectableTab[] = [
    {
      tab: FilterBannerTab.DATE,
      available: true
    },
    {
      tab: FilterBannerTab.LOCATION,
      available: true
    },
    {
      tab: FilterBannerTab.PAYER,
      available: true
    },
    {
      tab: FilterBannerTab.BILLING,
      available: true
    },
    {
      tab: FilterBannerTab.LAG,
      available: true
    },
    {
      tab: FilterBannerTab.INVOICE_STATUS,
      available: true
    },
    {
      tab: FilterBannerTab.VISIT_TYPE,
      available: true
    },
    {
      tab: FilterBannerTab.MULTI_LOCATION,
      available: true
    }
  ];
  pageLabel = DEFAULT_PAGE_LABEL;
  hoverIndex = -1;
  isDrillDown = false;
  tab: MultilevelTab | MultilevelTabCollections;

  ontologyLoaded = false;
  numberOfNodePaths = 0;
  drillDownHistory: DrillDown[] = [];

  selectedNodePath = '';
  currentNodePathLabel = '';
  showHierarchySearch = false;
  selectedHierarchicalNodes?: SelectedHierarchicalNodes;

  selectedCustomGroup: CustomGroupsDataAllFilters | undefined;
  private newlyProposedCustomGroup: CustomGroupsDataAllFilters;
  allFiltersEnabled = true;
  customGroups: CustomGroupsDataAllFilters[] = [];
  unsavedData = false;

  selectedDateForRangeCalendar: Object;

  locationNodePath: string;
  initialMemberLocations: MemberLocation[] = [];
  memberLocations: SelectableLocation[] = [];
  memberLocationsForTable: SelectableLocation[] = [];
  displayLocationLoadingIndicator = true;

  selectedDateRange: DateRange;
  selectedMemberLocation: MemberLocation = DEFAULT_MEMBER_LOCATION;
  multiSelectedMemberLocations: number[] = [];
  unfilteredMultiSelectedMemberLocations: number[] = [];

  selectedMemberBillingArea: MemberBillingArea = DEFAULT_MEMBER_BILLING_AREA;

  selectedLagPeriod: LagPeriod = lagPeriods[DEFAULT_LAG_KEY - 1];

  selectedInvoiceStatus: InvoiceStatus = invoiceStatusTypes[DEFAULT_INVOICE_STATUS];

  selectedVisitType: VisitType = DEFAULT_TELEHEALTH_FLAG;

  defaultFilterCriteria = this.ngRedux.getState().filters;

  nodePathSubscription: Subscription;
  editSubscription: Subscription;
  hierarchySubscription: Subscription;
  drillDownSubscription: Subscription;
  customGroupSubscription: Subscription;

  readonly maxCharsForNodePath = 60;

  @select(['filters'])
  private readonly currentFilters$: Observable<FilterCriteria>;

  @select(['display', 'showHierarchySearch'])
  private readonly showHierarchySearch$: Observable<boolean>;

  @select(['display', 'drillDown'])
  private readonly drillDown$: Observable<DrillDown>;

  @select(['filters', 'customGroupId'])
  private readonly id$: Observable<number>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['ontologyLoaded'])
  private readonly ontologyLoaded$: Observable<boolean>;

  @select(['data', 'featureToggleSettings'])
  featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @select(['data', 'userProfile'])
  userProfile$: Observable<UserProfile>;

  @select(['data', 'userSecurityData'])
  userSecurityData$: Observable<UserSecurity[]>;

  @select(['display', 'drillDownHistory'])
  private readonly drillDownHistory$: Observable<DrillDown[]>;

  @select(['data', 'applicationConfigurationSettings'])
  private readonly applicationConfigurationSettings$: Observable<ApplicationConfigurationSetting[]>;

  @select(['data', 'customGroupsData'])
  private readonly customGroups$: Observable<CustomGroupsDataAllFilters[]>;

  @select(['data', 'memberLocations'])
  private readonly currentMemberLocations$: Observable<CurrentMemberLocation[]>;

  @Input() showSticky = false;
  @Input() page: Page;
  @Input() shouldPayersBeGranular = true;
  @Input() payerTabPermitted = false;
  @Input() billingAreaTabPermitted = true;
  @Input() lagKeyTabPermitted = false;
  @Input() invoiceStatusTabPermitted = true;
  @Input() visitTypeTabPermitted = true;

  filterCriteria: FilterCriteria;
  protected originalFilters: FilterCriteria = this.ngRedux.getState().filters;

  selectedPayer: PayerFilterCriteria = DEFAULT_PAYER_FILTER_CRITERIA;
  selectedPayerMessage: string = DEFAULT_PAYER_CATEGORY.payerCategoryDescription;
  dialogActionListener: BehaviorSubject<GroupDialogEvent>;
  private dialogActionSubscription: Subscription;
  private applicationConfigurationSettings: ApplicationConfigurationSetting[];
  private billingAreas: MemberBillingArea[] = [];
  private payerHierarchy: PayerCategoryLevel[] = [];

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.nodePathSubscription = combineLatest([this.nodePath$, this.ontologyLoaded$])
      .subscribe(([nodePath, ontologyLoaded]: [string, boolean]) => {
        if (!!nodePath && ontologyLoaded) {
          this.selectedNodePath = nodePath;
          this.currentNodePathLabel = updateCurrentNodePathLabel(nodePath);
          setOntologyLevelFromNodepath(this.ngRedux, nodePath);
        } else {
          this.selectedNodePath = '';
          this.currentNodePathLabel = '';
        }
      });

    combineLatest([this.ontologyLoaded$, this.applicationConfigurationSettings$])
      .pipe(filter(([ontologyLoaded, applicationSettings]: [boolean, ApplicationConfigurationSetting[]]) =>
        ontologyLoaded && !!applicationSettings?.length))
      .subscribe(([ontologyLoaded, applicationSettings]: [boolean, ApplicationConfigurationSetting[]]) => {
        this.applicationConfigurationSettings = applicationSettings;
        this.customGroupSubscription?.unsubscribe();
        this.customGroupSubscription = combineLatest([this.customGroups$, this.id$])
          .subscribe(([customGroups, id]: [CustomGroupsDataAllFilters[], number]) => {
            this.dialogActionListener = new BehaviorSubject<GroupDialogEvent>(GroupDialogEvent.INITIAL);
            this.listenForDialogActions();
            this.customGroups = customGroups;
            this.customGroupDefault = this.customGroups.find(g => g.isDefault);
            this.selectedCustomGroup = this.customGroups.find(group => group.id === id);
            if (this.customGroupDefault) {
              this.selectedHierarchicalNodes = undefined;
              this.editAvailableTabs();
            }
          });
      });

    this.drillDownSubscription = this.drillDownHistory$.subscribe((drillDownHistory: DrillDown[]) => {
        this.isDrillDown = !!drillDownHistory.length;
      }
    );

    this.editSubscription = combineLatest([this.currentFilters$.pipe(
      distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.currentMemberLocations$])
      .subscribe(([filterCriteria, currentMemberLocations]: [FilterCriteria, CurrentMemberLocation[]]) => {
          currentMemberLocations.forEach(cL => {
            cL.currentlySelected = !!filterCriteria.memberLocationKeys.split('|').find(key => cL.memberLocationKey
              === +key);
          });
          if (!_.isEqual(this.filterCriteria, filterCriteria) && filterCriteria.memberKey !== DEFAULT_MEMBER_KEY) {
            this.updateMemberLocations(filterCriteria);
          }
          this.filterCriteria = filterCriteria;
          this.originalFilters = shallowCopyOf<FilterCriteria>(filterCriteria);
          this.updateSelectedLagPeriodFromKey(filterCriteria.lagKey || DEFAULT_LAG_KEY);
          this.updateSelectedMemberBillingArea(filterCriteria.memberBillingArea || DEFAULT_MEMBER_BILLING_AREA);
          this.updateSelectedInvoiceStatus(filterCriteria.invoiceStatus || DEFAULT_INVOICE_STATUS);
          this.updateVisitType(visitTypes[filterCriteria.telehealthFlag ?? 0] || DEFAULT_TELEHEALTH_FLAG);
          this.updateSelectedMemberLocation(filterCriteria.memberLocation);
          this.updatedMultiSelectedMemberLocation(filterCriteria.memberLocationKeys.split('|').map(key => +key),
            false);
          this.editAvailableTabs();
          if (filterCriteria.payerCategory) {
            this.selectedPayer = {
              payerCategoryKey: filterCriteria.payerCategory.payerCategoryKey,
              payerCategoryDesc: filterCriteria.payerCategory.payerCategoryDescription
            };
            if (filterCriteria.nationalPayerKey) {
              this.selectedPayer.nationalPayerKey = filterCriteria.nationalPayerKey;
              this.selectedPayer.nationalPayerDesc = filterCriteria.nationalPayerDescription;
              if (filterCriteria.memberPayerKey) {
                this.selectedPayer.memberPayerKey = filterCriteria.memberPayerKey;
                this.selectedPayer.memberPayerDesc = filterCriteria.memberPayerDescription;
              }
            }
          } else {
            this.selectedPayer = {payerCategoryKey: 0, payerCategoryDesc: 'All Payers'};
          }
          this.selectedPayerMessage = this.getPayerMessage();
          this.displayLocationLoadingIndicator = true;
          if (!this.editMode) {
            this.selectedDateRange = filterCriteria.dateRange;
            this.selectedDateForRangeCalendar = getSelectedDate(filterCriteria.dateRange);
          }
        }
      );

    this.hierarchySubscription = this.showHierarchySearch$.subscribe((showHierarchySearch: boolean) => {
      this.showHierarchySearch = showHierarchySearch;
    });
  }

  private getDialogActionListener(): Observable<GroupDialogEvent> {
    return this.dialogActionListener.asObservable();
  }

  private listenForDialogActions(): void {
    this.dialogActionSubscription = this.getDialogActionListener().subscribe((value: GroupDialogEvent) => {
      switch (value) {
        case GroupDialogEvent.APPLY:
          this.applyWithoutSavingGroup();
          break;
        case GroupDialogEvent.TENTATIVE_SAVE:
          this.saveGroup();
          break;
        case GroupDialogEvent.DEFINITE_SAVE:
          this.onConfirmSaveGroup(this.newlyProposedCustomGroup, false);
          break;
        case GroupDialogEvent.UPDATE:
          this.onConfirmSaveGroup(this.newlyProposedCustomGroup, true);
      }
    });
  }

  billingAreasLoaded(billingAreas: MemberBillingArea[]): void {
    this.billingAreas = billingAreas;
  }

  payerHierarchyLoaded(payerHierarchy: PayerCategoryLevel[]): void {
    this.payerHierarchy = payerHierarchy;
  }

  getPayerMessage(): string {
    if (!this.shouldPayersBeGranular) {
      return (this.selectedPayer && this.selectedPayer.payerCategoryKey) ?
        this.selectedPayer.payerCategoryDesc : 'All Payers';
    }
    return this.selectedPayer ?
      (this.selectedPayer.memberPayerDesc || this.selectedPayer.nationalPayerDesc || this.selectedPayer.payerCategoryDesc)
      : 'All Payers';
  }

  getSelectedPayerType(): PayerType {
    return this.selectedPayer ?
      (this.selectedPayer.memberPayerKey ? PayerType.MEMBER :
        (this.selectedPayer.nationalPayerKey ? PayerType.NATIONAL :
          (this.selectedPayer.payerCategoryKey ? PayerType.CATEGORY : PayerType.ALL)))
      : PayerType.ALL;
  }

  getSelectedPayerKey(): number {
    return this.selectedPayer ?
      (this.selectedPayer.memberPayerKey ? this.selectedPayer.memberPayerKey :
        (this.selectedPayer.nationalPayerKey ? this.selectedPayer.nationalPayerKey :
          (this.selectedPayer.payerCategoryKey ? this.selectedPayer.payerCategoryKey : 0)))
      : 0;
  }

  ngOnChanges() {
    this.setPageLabel();
    this.editAvailableTabs();
    this.selectedPayerMessage = this.getPayerMessage();
  }

  ngOnDestroy(): void {
    this.nodePathSubscription?.unsubscribe();
    this.editSubscription?.unsubscribe();
    this.hierarchySubscription?.unsubscribe();
    this.drillDownSubscription?.unsubscribe();
    this.customGroupSubscription?.unsubscribe();
    this.dialogActionSubscription?.unsubscribe();
  }

  selectExistingCustomGroup(group: any, apply: boolean): void {
    this.ngRedux.dispatch(undoDrillDownHistory(true));
    this.resetPayer();
    this.selectedHierarchicalNodes = undefined;
    if (group) {
      this.unsavedData = false;
      this.applyExistingCustomGroupSelection(apply, group);
    }
  }

  activateHoverMessage(tab: FilterBannerTab): void {
    const hoveredTab = this.availableTabs.find(filterTab => !filterTab.available &&
      filterTab.tab === tab);
    this.hoverIndex = hoveredTab ? hoveredTab.tab : -1;
  }

  deactivateHoverMessage(): void {
    this.hoverIndex = -1;
  }

  setPageLabel(): void {
    this.pageLabel = getPageLabel(this.page);
  }

  deactivateLocationLoadingIndicator(event?: boolean): void {
    if (event) {
      setTimeout(() => {
        this.displayLocationLoadingIndicator = false;
      }, 0);
    } else if (event === undefined) {
      this.displayLocationLoadingIndicator = false;
    }
  }

  updateMemberLocations(filterCriteria: FilterCriteria): void {
    if (!this.locationNodePath || (this.selectedNodePath && this.selectedNodePath !== this.locationNodePath)) {
      this.locationNodePath = this.selectedNodePath || filterCriteria.nodePath;
      this.memberLocationService.getMemberLocations({...filterCriteria, nodePath: this.locationNodePath})
        .subscribe((memberLocations: MemberLocation[]) => {
          this.multiSelectedMemberLocations = this.unfilteredMultiSelectedMemberLocations.filter(l => memberLocations
            .find(x => x.memberLocationKey === l));
          this.ngRedux.dispatch(memberLocationsChangedTo(memberLocations.map(x => {
            return {
              ...x,
              currentlySelected: !!filterCriteria.memberLocationKeys.split('|').find(key => x.memberLocationKey === +key)
            };
          })));
          if (!(_.isEqual(memberLocations, this.initialMemberLocations))) {
            this.memberLocations = [];
            if (memberLocations) {
              memberLocations.forEach(m => {
                this.memberLocations.push({
                  location: m, selected: this.multiSelectedMemberLocations.includes(m.memberLocationKey),
                  matchesFilterSearchText: true, eliminated: false
                });
              });
            }
            this.initialMemberLocations = memberLocations;
            this.memberLocationsForTable = this.memberLocations;
            const selectedLocationItems = this.memberLocations.filter(x => this.ngRedux.getState().filters
              .memberLocationKeys.split('|').includes(x.location.memberLocationKey + ''));
            this.multiSelectedMemberLocations = selectedLocationItems.map(y => y.location.memberLocationKey);
          }
        });
    } else {
      if (!this.memberLocationsForTable) {
        this.memberLocationsForTable = [];
      }
    }
    this.deactivateLocationLoadingIndicator();
  }

  private editAvailableTabs(): void {
    const payerTab = this.availableTabs.find(tab => tab.tab === FilterBannerTab.PAYER);
    if (payerTab) {
      payerTab.available = this.payerTabPermitted || this.editMode;
    }
    const lagTab = this.availableTabs.find(tab => tab.tab === FilterBannerTab.LAG);
    if (lagTab) {
      lagTab.available = this.lagKeyTabPermitted || this.editMode;
    }
    const billingAreaTab = this.availableTabs.find(tab => tab.tab === FilterBannerTab.BILLING);
    if (billingAreaTab) {
      billingAreaTab.available = this.billingAreaTabPermitted || this.editMode;
    }
    const invoiceTab = this.availableTabs.find(tab => tab.tab === FilterBannerTab.INVOICE_STATUS);
    if (invoiceTab) {
      invoiceTab.available = this.invoiceStatusTabPermitted || this.editMode;
    }
    const visitTypeTab = this.availableTabs.find(tab => tab.tab === FilterBannerTab.VISIT_TYPE);
    if (visitTypeTab) {
      visitTypeTab.available = this.visitTypeTabPermitted || this.editMode;
    }
  }

  setTab(index: number) {
    if (!this.availableTabs[index].available) {
      return;
    }
    this.setEditMode(true);
    this.editAvailableTabs();
    this.ngRedux.dispatch(showHierarchySearchChangedTo(false));
    this.currentTabIndex = index;
    switch (index) {
      case FilterBannerTab.LOCATION:
      case FilterBannerTab.MULTI_LOCATION:
        this.updateMemberLocations(this.filterCriteria);
        break;
    }
  }

  private setEditMode(isOpen: boolean) {
    this.ngRedux.dispatch(filterBannerEditChangedTo(isOpen));
    this.editMode = isOpen;
  }

  toggleHierarchy(show: boolean): void {
    if (show) {
      this.setEditMode(true);
      this.editAvailableTabs();
      this.currentTabIndex = -1;
    } else if (!(this.availableTabs[this.currentTabIndex])) {
      this.cancel();
    }
    this.ngRedux.dispatch(showHierarchySearchChangedTo(show));
  }

  getCssClassForTab(index: number): string {
    if (this.editMode) {
      if (this.availableTabs[index]) {
        if (!this.availableTabs[index].available) {
          return 'disabled-tab';
        }
        if (this.currentTabIndex === index && !this.showHierarchySearch) {
          return 'active-selection-tab';
        }
        return 'selection-tab';
      }
    }
    if (this.availableTabs[index]) {
      if (!this.availableTabs[index].available) {
        return 'disabled-tab';
      }
    }
    return 'non-edit-selection-tab';
  }

  getCssClassForTabTitle(index: number): string {
    if (this.availableTabs[index]) {
      if (!this.availableTabs[index].available) {
        return 'tab-title-disabled';
      }
    }
    return 'tab-title';
  }

  getCssClassForSelectedCriteria(index: number): string {
    if (this.availableTabs[index]) {
      if (!this.availableTabs[index].available) {
        return 'selected-criteria-disabled';
      }
    }
    return 'selected-criteria';
  }

  getCssClassForFilterLabel(index: number): string {
    if (this.availableTabs[index]) {
      if (!this.availableTabs[index].available) {
        return 'filter-label-disabled';
      }
    }
    return 'filter-label';
  }

  cancel(event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.unsavedData = false;
    this.selectedHierarchicalNodes = undefined;
    this.selectedNodePath = this.ngRedux.getState().filters.nodePath;
    this.selectedDateRange = this.ngRedux.getState().filters.dateRange;
    this.selectedDateForRangeCalendar = getSelectedDate(this.selectedDateRange);
    this.selectedMemberLocation = this.ngRedux.getState().filters.memberLocation;
    this.currentNodePathLabel = updateCurrentNodePathLabel(this.selectedNodePath);

    if (this.ngRedux.getState().filters.payerCategory) {
      this.selectedPayer.payerCategoryKey = this.ngRedux.getState().filters.payerCategory.payerCategoryKey;
      this.selectedPayer.payerCategoryDesc = this.ngRedux.getState().filters.payerCategory.payerCategoryDescription;
      if (this.ngRedux.getState().filters.nationalPayerKey) {
        this.selectedPayer.nationalPayerKey = this.ngRedux.getState().filters.nationalPayerKey;
        this.selectedPayer.nationalPayerDesc = this.ngRedux.getState().filters.nationalPayerDescription;
        if (this.ngRedux.getState().filters.memberPayerKey) {
          this.selectedPayer.memberPayerKey = this.ngRedux.getState().filters.memberPayerKey;
          this.selectedPayer.memberPayerDesc = this.ngRedux.getState().filters.memberPayerDescription;
        }
      }
    } else {
      this.selectedPayer = {payerCategoryKey: 0, payerCategoryDesc: 'All Payers'};
    }
    this.selectedPayerMessage = this.getPayerMessage();

    this.selectedMemberBillingArea = this.ngRedux.getState().filters.memberBillingArea;
    this.selectedLagPeriod = lagPeriods[this.ngRedux.getState().filters.lagKey - 1];
    this.selectedInvoiceStatus = invoiceStatusTypes[this.ngRedux.getState().filters.invoiceStatus];
    this.selectedVisitType = visitTypes[this.ngRedux.getState().filters.telehealthFlag ?? 0];
    const selectedLocationItems = this.memberLocations.filter(x => this.ngRedux.getState().filters
      .memberLocationKeys.split('|').includes(x.location.memberLocationKey + ''));
    this.multiSelectedMemberLocations = selectedLocationItems.map(y =>
      y.location.memberLocationKey);
    this.memberLocations = this.memberLocations.map(loc => {
      return {...loc, selected: this.multiSelectedMemberLocations.includes(loc.location.memberLocationKey)};
    });
    this.setEditMode(false);
    this.ngRedux.dispatch(showHierarchySearchChangedTo(false));
    this.editAvailableTabs();
    this.currentTabIndex = -1;
  }

  apply(): void {
    if (!this.unsavedData) {
      return;
    }
    if (this.allFiltersEnabled) {
      this.dialog.open(SaveApplyDialogComponent, {
        data: {
          title: 'Save changes?',
          question: 'Would you like to save the selected providers' + (this.allFiltersEnabled ? ' and filters' : '')
            + ' for faster loading?',
          confirmButtonAltText: 'Save',
          dialogActionListener: this.dialogActionListener
        }
      });
    } else {
      this.applyWithoutSavingGroup();
    }
  }

  private changeBenchmarkIfSelectedDateEndsBefore2020() {
    if (this.selectedDateRange?.endYear < 2020 && this.reduxStateHasATelehealthDependentBenchmarkOption()) {
      this.resetBenchmarkOptionBasedOnViewCommunityBoolean();
    }
  }

  applyWithoutSavingGroup = () => {
    this.ngRedux.dispatch(undoDrillDownHistory(true));
    this.handleNodePaths();
    const newFilterCriteria = shallowCopyOf(this.ngRedux.getState().filters);
    newFilterCriteria.customGroupId = 0;

    this.numberOfNodePaths = this.selectedNodePath.split('|').length;
    if (this.isWithinNodePathLimit()) {
      this.selectedHierarchicalNodes = undefined;
      this.ngRedux.dispatch(showHierarchySearchChangedTo(false));
      this.setEditMode(false);
      this.editAvailableTabs();
      this.updateFilterCriteriaWithLatestValues(newFilterCriteria);
      if (!(_.isEqual(this.ngRedux.getState().filters.telehealthFlag, newFilterCriteria.telehealthFlag))) {
        this.analyticsService.handleGoogleAnalytics('Telehealth Flag', 'Telehealth Flag',
          'Changed Telehealth Filter');
      }
      if (!(_.isEqual(this.ngRedux.getState().filters.lagKey, newFilterCriteria.lagKey))) {
        const newLagPeriod = lagPeriods.find(l => l.key === newFilterCriteria.lagKey);
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
          'Lag Period', newLagPeriod?.name || DEFAULT_LAG_NAME);
      }
      if (!(_.isEqual(this.ngRedux.getState().filters.invoiceStatus, newFilterCriteria.invoiceStatus))) {
        const newInvoice = invoiceStatusTypes.find(i => i.statusId === newFilterCriteria.invoiceStatus);
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS, 'Invoice Status',
          'Changed Invoice Status ' + (newInvoice?.statusText || DEFAULT_INVOICE_TEXT));
      }
      this.ngRedux.dispatch(filterCriteriaChangedTo({...newFilterCriteria, chosenByUser: true}));
      const currentLocations = this.ngRedux.getState().data.memberLocations || [];
      currentLocations.forEach(loc => {
        loc.currentlySelected = !!newFilterCriteria.memberLocationKeys.split('|').find(key => loc.memberLocationKey === +key);
      });
      this.ngRedux.dispatch(memberLocationsChangedTo(currentLocations));
      this.ngRedux.dispatch(undoDrillDownHistory(true));
      this.unsavedData = false;
      this.changeBenchmarkIfSelectedDateEndsBefore2020();
      this.resetEmNpvBenchmarkOptionBasedOnVisitTypeSelection();
    } else {
      this.failedApply(this.getMaxNodePaths());
    }
  }

  private isWithinNodePathLimit() {
    return this.numberOfNodePaths <= this.getMaxNodePaths();
  }

  resetEmNpvBenchmarkOptionBasedOnVisitTypeSelection() {
    const endYear = this.selectedDateRange?.endYear;
    if (endYear > 2019) {
      const viewCommunity: boolean = this.ngRedux.getState()?.display?.viewCommunityBenchmarks;
      let newBenchmarkOption: BenchmarkOption;
      switch (this.selectedVisitType.text) {
        case 'Telehealth':
          newBenchmarkOption = viewCommunity ? BenchmarkOption.TelehealthCommunity : BenchmarkOption.TelehealthAcademic;
          break;
        case 'In-person':
          newBenchmarkOption = viewCommunity ? BenchmarkOption.InPersonCommunity : BenchmarkOption.InPersonAcademic;
          break;
        case 'All Visit Types':
        default:
          newBenchmarkOption = viewCommunity ? BenchmarkOption.Community : BenchmarkOption.Academic;
          break;
      }
      if (newBenchmarkOption && (newBenchmarkOption !== this.ngRedux.getState()?.display?.emNpvBenchmarkOption)) {
        this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo(newBenchmarkOption));
      }
    }
  }

  resetEmNpvBenchmarkOptionBasedOnVisitTypeInCustomGroup(telehealthFlag: number) {
    const viewCommunity: boolean = this.ngRedux.getState().display.viewCommunityBenchmarks;
    let newBenchmarkOption: BenchmarkOption;
    const visitType = visitTypes.find(vT => vT.key === telehealthFlag);
    switch (visitType?.text) {
      case 'Telehealth':
        newBenchmarkOption = viewCommunity ? BenchmarkOption.TelehealthCommunity : BenchmarkOption.TelehealthAcademic;
        break;
      case 'In-person':
        newBenchmarkOption = viewCommunity ? BenchmarkOption.InPersonCommunity : BenchmarkOption.InPersonAcademic;
        break;
      case 'All Visit Types':
      default:
        newBenchmarkOption = viewCommunity ? BenchmarkOption.Community : BenchmarkOption.Academic;
        break;
    }
    if (newBenchmarkOption && (newBenchmarkOption !== this.ngRedux.getState().display.emNpvBenchmarkOption)) {
      this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo(newBenchmarkOption));
    }
  }

  applyExistingCustomGroupSelection(apply: boolean, customGroup: CustomGroupsDataAllFilters | undefined): void {
    const newFilterCriteria = customGroup ? filterCriteriaFromSelectedCustomGroup(customGroup, this.ngRedux.getState()) :
      this.defaultFilterCriteria;
    const nodePath = customGroup?.nodePath || '';
    this.numberOfNodePaths = hasValue(nodePath) ? nodePath.split('|').length : 0;
    if (this.isWithinNodePathLimit()) {
      this.selectedNodePath = nodePath;
      if (this.allFiltersEnabled && apply) {
        this.selectedHierarchicalNodes = undefined;
        this.ngRedux.dispatch(showHierarchySearchChangedTo(false));
        this.setEditMode(false);
      }
      this.editAvailableTabs();
      this.currentTabIndex = -1;
      if (this.allFiltersEnabled) {
        this.ngRedux.dispatch(filterCriteriaChangedTo({...newFilterCriteria, chosenByUser: true}));
      }
      this.ngRedux.dispatch(nodePathChangedTo(this.selectedNodePath ? this.selectedNodePath : ''));
      this.resetEmNpvBenchmarkOptionBasedOnVisitTypeInCustomGroup(newFilterCriteria.telehealthFlag ?? 0);
    } else {
      this.failedApply(this.getMaxNodePaths());
    }
  }

  private updateFilterCriteriaWithLatestValues(newFilterCriteria: FilterCriteria) {
    this.currentTabIndex = -1;
    if (this.selectedNodePath && this.selectedNodePath.includes('\\')) {
      newFilterCriteria.nodePath = this.selectedNodePath;
    }

    if (this.selectedDateRange) {
      newFilterCriteria.dateRange = this.selectedDateRange;
    }

    if (this.selectedLagPeriod) {
      newFilterCriteria.lagKey = this.selectedLagPeriod.key;
    }

    if (this.selectedMemberBillingArea) {
      newFilterCriteria.memberBillingArea = this.selectedMemberBillingArea;
    }

    if (hasValue(this.selectedInvoiceStatus)) {
      newFilterCriteria.invoiceStatus = checkForNulls(this.selectedInvoiceStatus.statusId);
    }

    if (hasValue(this.selectedVisitType)) {
      newFilterCriteria.telehealthFlag = checkForNulls(this.selectedVisitType?.key);
    }

    if (this.selectedPayer && hasValue(this.selectedPayer.payerCategoryKey)) {
      newFilterCriteria.payerCategory = {
        payerCategoryKey: this.selectedPayer.payerCategoryKey,
        payerCategoryDescription: this.selectedPayer.payerCategoryDesc,
        payerCategoryCode: ''
      };
    }

    if (this.selectedPayer) {
      newFilterCriteria.payerKey = this.selectedPayer.memberPayerKey || this.selectedPayer.nationalPayerKey
        || this.selectedPayer.payerCategoryKey || 0;
      newFilterCriteria.payerType = this.getSelectedPayerType();
      newFilterCriteria.memberPayerKey = this.selectedPayer.memberPayerKey;
      newFilterCriteria.memberPayerDescription = this.selectedPayer.memberPayerDesc;
      newFilterCriteria.nationalPayerKey = this.selectedPayer.nationalPayerKey;
      newFilterCriteria.nationalPayerDescription = this.selectedPayer.nationalPayerDesc;
    }

    if (this.selectedMemberLocation) {
      newFilterCriteria.memberLocation = this.selectedMemberLocation;
    }
    if (this.multiSelectedMemberLocations) {
      newFilterCriteria.memberLocationKeys = this.multiSelectedMemberLocations.sort((a, b) => {
        return a - b;
      }).map(x => '' + x).join('|');
    }
  }

  failedApply(maxNodePaths: number): void {
    this.dialog.open(WarningLimitExceededComponent, {
      data: {
        maxNodePaths: maxNodePaths
      }
    });
  }

  updateSelectedLagPeriodFromKey(lagKey: number) {
    this.selectedLagPeriod = lagKey > 0 && lagKey <= lagPeriods.length ? lagPeriods[lagKey - 1] : lagPeriods[DEFAULT_LAG_KEY - 1];
    this.determineIfFilterChanged();
  }

  determineIfFilterChanged() {
    const oldDateRange = this.ngRedux.getState().filters.dateRange;
    this.unsavedData =
      (!!this.selectedHierarchicalNodes?.groupIsEdited) ||                      // node path is changed
      !(oldDateRange.startMonth === this.selectedDateRange?.startMonth &&        // date range is changed
        oldDateRange.startYear === this.selectedDateRange?.startYear &&
        oldDateRange.endMonth === this.selectedDateRange?.endMonth &&
        oldDateRange.endYear === this.selectedDateRange?.endYear) ||
      (this.ngRedux.getState().filters.memberLocation.memberLocationName !== this.selectedMemberLocation?.memberLocationName) ||
      !(this.ngRedux.getState().filters.payerType === this.getSelectedPayerType() &&
        this.ngRedux.getState().filters.payerCategory.payerCategoryKey === this.selectedPayer?.payerCategoryKey &&
        this.ngRedux.getState().filters.payerKey === this.getSelectedPayerKey()) ||
      (this.ngRedux.getState().filters.memberBillingArea.memberBillingAreaDescription !==
        this.selectedMemberBillingArea?.memberBillingAreaDescription) ||
      (this.ngRedux.getState().filters.lagKey !== this.selectedLagPeriod.key) ||
      (this.ngRedux.getState().filters.invoiceStatus !== this.selectedInvoiceStatus.statusId) ||
      (this.ngRedux.getState().filters.telehealthFlag !== this.selectedVisitType.key) ||
      (!_.isEqual(this.ngRedux.getState().filters.memberLocationKeys, this.multiSelectedMemberLocations));
  }

  onDateChange(inputDateRange: any): void {
    if (inputDateRange) {
      this.selectedDateRange = {
        startMonth: inputDateRange.value.begin.getMonth() + 1,
        startYear: inputDateRange.value.begin.getFullYear(),
        endMonth: inputDateRange.value.end.getMonth() + 1,
        endYear: inputDateRange.value.end.getFullYear(),
        selectedDateRangeOption: inputDateRange.value.selectedDateRangeOption,
        fiscalYear: inputDateRange.value.fiscalYear
      };
      this.selectedDateForRangeCalendar = getSelectedDate(this.selectedDateRange);

      this.determineIfFilterChanged();
    }
  }

  private resetBenchmarkOptionBasedOnViewCommunityBoolean() {
    this.ngRedux.getState().display.viewCommunityBenchmarks ?
      this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo(BenchmarkOption.Community)) :
      this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo(BenchmarkOption.Academic));
  }

  private reduxStateHasATelehealthDependentBenchmarkOption() {
    return !(this.ngRedux.getState().display.emNpvBenchmarkOption === BenchmarkOption.Community ||
      this.ngRedux.getState().display.emNpvBenchmarkOption === BenchmarkOption.Academic);
  }

  resetDateFromUserPreferences(): void {
    const memberKey = this.ngRedux.getState().filters.memberKey || DEFAULT_MEMBER_KEY;
    this.selectedDateRange = getDateRangeBySelectedOption(
      this.ngRedux.getState().userPreferences?.dateRangeOption,
      this.ngRedux.getState().data.userMemberData.find(x => x.memberKey === memberKey)?.recentMonth
    );
    if (this.currentTabIndex === FilterBannerTab.DATE) {
      this.currentTabIndex = -1;
      setTimeout(() => {
        this.currentTabIndex = FilterBannerTab.DATE;
      }, 5);
    }
    this.selectedDateForRangeCalendar = getSelectedDate(this.selectedDateRange);
  }

  updateSelectedHierarchicalPath(event: SelectedHierarchicalNodes): void {
    this.selectedHierarchicalNodes = event;
    this.determineIfFilterChanged();
    this.selectedNodePath = getSelectedNodePath(
      this.selectedHierarchicalNodes.selectedFilteredProviders,
      this.selectedHierarchicalNodes.listOfAllDepartments,
      this.selectedHierarchicalNodes.listOfAllSpecialties,
      this.selectedHierarchicalNodes.listOfAllProviders,
      this.selectedHierarchicalNodes.allDepartmentsNodePath);
    this.currentNodePathLabel = updateCurrentNodePathLabel(this.selectedNodePath);
    if (this.selectedHierarchicalNodes.group && !this.selectedHierarchicalNodes.groupIsEdited) {
      const doNotCloseEditPopup = this.selectedHierarchicalNodes.askParentToNotCloseEditPopup;
      // we need to dispatch node path changed.  Adv Nav is saying that a new group is selected.
      this.selectExistingCustomGroup(this.selectedHierarchicalNodes.group, false);
      if (!doNotCloseEditPopup) {
        this.setEditMode(false);
        this.ngRedux.dispatch(showHierarchySearchChangedTo(false));
        this.editAvailableTabs();
        this.currentTabIndex = -1;
      }
    }
  }

  updateSelectedInvoiceStatus(event: number): void {
    this.selectedInvoiceStatus = invoiceStatusTypes[event];
    this.determineIfFilterChanged();
  }

  updateVisitType(event: VisitType) {
    this.selectedVisitType = event;
    this.determineIfFilterChanged();
  }

  updateSelectedMemberBillingArea(event: MemberBillingArea): void {
    this.selectedMemberBillingArea = event;
    this.determineIfFilterChanged();
  }

  updateSelectedMemberLocation(event: MemberLocation): void {
    this.selectedMemberLocation = event;
    this.determineIfFilterChanged();
  }

  updatedMultiSelectedMemberLocation(event: number[], selected: boolean): void {
    this.multiSelectedMemberLocations = event.length === 1 && event[0] === 0 ? [] : event;
    if (selected) {
      this.unfilteredMultiSelectedMemberLocations = this.multiSelectedMemberLocations.slice();
    }
    this.determineIfFilterChanged();
  }

  exportToPdf(event: [PdfExportData, EmDimension[], boolean]): void {
    if (!event[1].length) {
      this.pdfContent.export(event[0], event[2]);
    } else {
      this.pdfContent.exportWithPageBreaks(...event);
    }
  }

  navigateBack() {
    if (this.editMode) {
      return;
    }
    this.drillDownService.drillUp();
  }

  saveGroup(): void {
    this.handleNodePaths();
    this.numberOfNodePaths = this.selectedNodePath.split('|').length;
    if (this.isWithinNodePathLimit()) {
      this.openSaveGroupDialogWithNewlyGeneratedGroup();
    } else {
      this.failedApply(this.getMaxNodePaths());
    }
  }

  private openSaveGroupDialogWithNewlyGeneratedGroup() {
    const state = this.ngRedux.getState();
    const newFilterCriteria = shallowCopyOf(state.filters);
    this.updateFilterCriteriaWithLatestValues(newFilterCriteria);
    this.selectedMemberLocation = this.selectedMemberLocation || DEFAULT_MEMBER_LOCATION;
    const selectionCounts = getSelectedNodeCountsFromNodePath(newFilterCriteria.nodePath || state.filters.nodePath);
    const dataSet = determineAllowedDataset({
      ...state,
      display: {
        ...state.display,
        selectedProviders: selectionCounts.selectedProviders,
        selectedSpecialties: selectionCounts.selectedSpecialties
      }
    });
    this.newlyProposedCustomGroup =
      createNewCustomGroupFromFilter(newFilterCriteria, false, false, this.selectedCustomGroup?.name || '', dataSet);
    this.dialog.open(UpdatedSaveGroupingComponent, {
      data: {
        group: this.newlyProposedCustomGroup,
        existingGroups: this.customGroups,
        dialogActionListener: this.dialogActionListener
      }
    });
    this.changeBenchmarkIfSelectedDateEndsBefore2020();
  }

  onConfirmSaveGroup(group: CustomGroupsDataAllFilters, isEditingExistingGroup: boolean): void {
    const newFilterCriteria = shallowCopyOf(this.ngRedux.getState().filters);
    this.updateFilterCriteriaWithLatestValues(newFilterCriteria);
    this.updateGroupList(group, isEditingExistingGroup, newFilterCriteria);
    this.resetEmNpvBenchmarkOptionBasedOnVisitTypeInCustomGroup(newFilterCriteria.telehealthFlag ?? 0);
    this.ngRedux.dispatch(filterCriteriaChangedTo(newFilterCriteria));
    this.ngRedux.dispatch(undoDrillDownHistory(true));
    this.setEditMode(false);
  }

  private updateGroupList(group: CustomGroupsDataAllFilters, isEditingExistingGroup: boolean, newFilterCriteria: FilterCriteria) {
    if (isEditingExistingGroup) {
      this.saveUpdateToExistingGroup(group, newFilterCriteria);
    } else {
      this.saveNewGroup(group, newFilterCriteria);
    }
  }

  private saveNewGroup(group: CustomGroupsDataAllFilters, newFilterCriteria: FilterCriteria) {
    this.customGroupsService.saveCustomGroup({
      ...group, preload: group.isDefault,
      isDefault: group.isDefault
    }, group.memberKey)
      .subscribe((cg: CustomGroupsDataAllFilters) => {
        this.ngRedux.dispatch(customGroupIdChangedTo(cg.id));
        this.getCustomGroupsAfterASave(newFilterCriteria.memberKey);
      });
  }

  saveUpdateToExistingGroup(
    group: CustomGroupsDataAllFilters,
    newFilterCriteria: FilterCriteria
  ) {
    const editedGroup = this.customGroups.find(cg => cg.name.toLowerCase().localeCompare(group.name.toLowerCase()) === 0);
    if (editedGroup) {
      if (valuesAreDifferent(editedGroup.startDateInMonths, group.startDateInMonths)
          || valuesAreDifferent(editedGroup.endDateInMonths, group.endDateInMonths)) {
        this.handleTimeFrameUpdatingAnalyticsForCustomGroup(group);
      }
      if (editedGroup.memberLocationKeys.localeCompare(group.memberLocationKeys) !== 0) {
        this.handleLocationUpdatingAnalyticsForCustomGroup(group);
      }
      if (valuesAreDifferent(editedGroup.lagKey, group.lagKey)) {
        this.handleLagPeriodUpdatingAnalyticsForCustomGroup(group);
      }
      if (valuesAreDifferent(editedGroup.memberBillingAreaKey, group.memberBillingAreaKey)) {
        this.handleBillingAreaUpdatingAnalyticsForCustomGroup(group);
      }
      if (valuesAreDifferent(editedGroup.payerType, group.payerType) ||
        valuesAreDifferent(editedGroup.payerCategoryKey, group.payerCategoryKey) ||
        valuesAreDifferent(editedGroup.nationalPayerKey, group.nationalPayerKey) ||
        valuesAreDifferent(editedGroup.memberPayerKey, group.memberPayerKey)) {
        this.handlePayerUpdatingAnalyticsForCustomGroup(group);
      }
      if (valuesAreDifferent(editedGroup.invoiceStatus, group.invoiceStatus)) {
        this.handleInvoiceStatusUpdatingAnalyticsForCustomGroup(group);
      }
      if (valuesAreDifferent(editedGroup.telehealthFlag, group.telehealthFlag)) {
        this.handleVisitTypeUpdatingAnalyticsForCustomGroup(group);
      }
      this.customGroupsService.updateCustomGroup({...group, id: editedGroup.id,
        preload: group.isDefault, isDefault: group.isDefault}, newFilterCriteria.memberKey,
        customGroupDataChanged(editedGroup, newFilterCriteria)).subscribe(() => {
        this.getCustomGroupsAfterASave(newFilterCriteria.memberKey);
      });
    }
  }

  private getCustomGroupsAfterASave(memberKey: number): void {
    this.customGroupsService.getCustomGroups(memberKey)?.subscribe((groups: CustomGroupsDataAllFilters[]) => {
      this.ngRedux.dispatch(customGroupsDataChangedTo(groups));
    });
  }

  private getMaxNodePaths(): number {
    const value_providers = getAppConfigValue(AppConfigEntries.MAX_PROVIDERS,
      this.ngRedux.getState().data.applicationConfigurationSettings);
    return value_providers && value_providers.length > 0 ? Number(value_providers) : DEFAULT_MAX_NODE_PATHS;
  }

  private handleTimeFrameUpdatingAnalyticsForCustomGroup(group: CustomGroupsDataAllFilters): void {
    const newDateRange: DateRange = getArbitraryDateRangeFromDatesInMonths(group.startDateInMonths,
      group.endDateInMonths);
    const newlySelectedTimeFrameLabel = MONTH_MAPPING[newDateRange.startMonth] + ', ' + newDateRange.startYear +
      ' - ' + MONTH_MAPPING[newDateRange.endMonth] + ', ' + newDateRange.endYear;
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
      `Time Frame ${GoogleAnalyticsLabels.Groupings}`,
      `${newlySelectedTimeFrameLabel} ${GoogleAnalyticsActions.CustomGroup}`);
  }

  private handleLagPeriodUpdatingAnalyticsForCustomGroup(group: CustomGroupsDataAllFilters): void {
    const newLag = lagPeriods.find(l => l.key === group.lagKey);
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
      `Lag Period ${GoogleAnalyticsLabels.Groupings}`,
      `${newLag?.name || DEFAULT_LAG_NAME} ${GoogleAnalyticsActions.CustomGroup}`);
  }

  private handleLocationUpdatingAnalyticsForCustomGroup(group: CustomGroupsDataAllFilters): void {
    const newLocationKeys: number[] = group.memberLocationKeys.split('|').map(k => +k);
    const newlyIncludedLocationNames: string[] = this.memberLocations.filter(loc =>
      newLocationKeys.includes(loc.location.memberLocationKey)).map(mL => mL.location.memberLocationName);
    const maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT,
      this.applicationConfigurationSettings) || DEFAULT_MAX_LOCATIONS;
    let locationText = `Location: ${DEFAULT_MEMBER_LOCATION.memberLocationName}`;
    if (newlyIncludedLocationNames.length > 0 && newlyIncludedLocationNames.length < this.memberLocations.length) {
      const additionalLocations = newlyIncludedLocationNames.length - maxLocations;
      locationText = 'Location: ' + newlyIncludedLocationNames.slice(0, maxLocations).join(', ') +
        (additionalLocations > 0 ? (' + ' + additionalLocations + ' more') : '');
    }
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
      `Location ${GoogleAnalyticsLabels.Groupings}`,
      `${locationText} ${GoogleAnalyticsActions.CustomGroup}`);
  }

  private handleBillingAreaUpdatingAnalyticsForCustomGroup(group: CustomGroupsDataAllFilters): void {
    const newBillingArea = this.billingAreas.find(b => b.memberBillingAreaKey === group.memberBillingAreaKey);
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
      `Billing ${GoogleAnalyticsLabels.Groupings}`,
      `${newBillingArea?.memberBillingAreaDescription || DEFAULT_MEMBER_BILLING_AREA} ${GoogleAnalyticsActions.CustomGroup}`);
  }

  private handlePayerUpdatingAnalyticsForCustomGroup(group: CustomGroupsDataAllFilters): void {
    const newPayer = constructPayerString(this.payerHierarchy, group.payerCategoryKey, group.nationalPayerKey,
      group.memberPayerKey);
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
      `Payer ${GoogleAnalyticsLabels.Groupings}`,
      `${newPayer} ${GoogleAnalyticsActions.CustomGroup}`);
  }

  private handleInvoiceStatusUpdatingAnalyticsForCustomGroup(group: CustomGroupsDataAllFilters): void {
    const newInvoiceStatus = invoiceStatusTypes.find(i => i.statusId === group.invoiceStatus);
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
      `Invoice ${GoogleAnalyticsLabels.Groupings}`,
      `${newInvoiceStatus?.statusText || DEFAULT_INVOICE_STATUS} ${GoogleAnalyticsActions.CustomGroup}`);
  }

  private handleVisitTypeUpdatingAnalyticsForCustomGroup(group: CustomGroupsDataAllFilters): void {
    const newVisitType = visitTypes.find(v => v.key === group.telehealthFlag);
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.FILTERS,
      `Visit Type ${GoogleAnalyticsLabels.Groupings}`,
      `${newVisitType?.text || DEFAULT_TELEHEALTH_FLAG.text} ${GoogleAnalyticsActions.CustomGroup}`);
  }

  handleNodePaths(): void {
    if (this.selectedHierarchicalNodes) {
      if (this.selectedHierarchicalNodes.group && !this.selectedHierarchicalNodes.groupIsEdited) {
        this.selectedNodePath = this.selectedHierarchicalNodes.group.nodePath;
      } else {
        this.selectedNodePath = getSelectedNodePath(
          this.selectedHierarchicalNodes.selectedFilteredProviders,
          this.selectedHierarchicalNodes.listOfAllDepartments,
          this.selectedHierarchicalNodes.listOfAllSpecialties,
          this.selectedHierarchicalNodes.listOfAllProviders,
          this.selectedHierarchicalNodes.allDepartmentsNodePath);
      }
    }
  }

  onRestoreDefault() {
    if (!this.customGroupDefault) {
      return;
    }
    let noChange = false;
    if (this.selectedCustomGroup === this.customGroupDefault) {
      noChange = true;
    }
    this.selectExistingCustomGroup(this.customGroupDefault, true);
    this.unsavedData = !noChange;
  }

  onClearAllSelections() {
    this.resetDateFromUserPreferences();

    this.selectedMemberLocation = DEFAULT_MEMBER_LOCATION;
    this.multiSelectedMemberLocations = [];
    this.memberLocations.forEach(mL => {
      mL.selected = false;
    });
    this.searchMultipleLocationsComponent?.ngOnChanges();
    this.resetPayer();

    this.selectedMemberBillingArea = DEFAULT_MEMBER_BILLING_AREA;

    this.selectedLagPeriod = lagPeriods[DEFAULT_LAG_KEY - 1];
    this.selectedInvoiceStatus = invoiceStatusTypes[DEFAULT_INVOICE_STATUS];
    this.selectedVisitType = DEFAULT_TELEHEALTH_FLAG;

    this.clearOntology();
    this.determineIfFilterChanged();
  }

  private clearOntology() {
    const allDepartmentsNode = getOntologyData().ontologyHierarchy[0].nodePath;
    this.advancedNavigationComponent?.onClearAllSelections();
    this.selectedNodePath = allDepartmentsNode;
    this.selectedHierarchicalNodes = getDefaultHierarchicalNodes(allDepartmentsNode);
    this.currentNodePathLabel = updateCurrentNodePathLabel(allDepartmentsNode);
  }

  private resetPayerVariables() {
    this.selectedPayer = {
      payerCategoryKey: DEFAULT_PAYER_CATEGORY.payerCategoryKey,
      payerCategoryDesc: DEFAULT_PAYER_CATEGORY.payerCategoryDescription
    };
    this.selectedPayerMessage = this.getPayerMessage();
  }

  private resetPayer() {
    this.resetPayerVariables();
  }

  payerSelectionChanged(selectedPayer: PayerFilterCriteria) {
    this.selectedPayer = selectedPayer;
    this.selectedPayerMessage = this.getPayerMessage();
    this.determineIfFilterChanged();
  }
}

function getPageLabel(page: Page): string {
  switch (page) {
    case Page.Overview:
      return Page.Overview.valueOf();
    case Page.WrvuSnapshot:
    case Page.WrvuTrend:
    case Page.WrvuProvider:
    case Page.WrvuSpecialty:
    case Page.WrvuDepartment:
      return wrvuPagesLabel;
    case Page.NPVSnapshot:
    case Page.NPVTrend:
    case Page.NPVDepartment:
    case Page.NPVSpecialty:
    case Page.NPVProvider:
      return npvPagesLabel;
    case Page.EandM:
      return Page.EandM.valueOf();
    case Page.CFP:
      return Page.CFP.valueOf();
    case Page.CollectionsSnapshot:
    case Page.CollectionsByPayer:
    case Page.CollectionsSummary:
      return collectionsPagesLabel;
    case Page.Denials:
    case Page.DenialsDepartment:
    case Page.DenialsSpecialty:
    case Page.DenialsProvider:
    case Page.DenialsByPayer:
      return denialsPagesLabel;
    case Page.ProcedureSummary:
      return Page.ProcedureSummary.valueOf();
    default:
      return DEFAULT_PAGE_LABEL;
  }
}
