import {Component, Inject, Input, OnChanges, OnDestroy} from '@angular/core';
import {LegendData, OverviewChartOptions} from '../../models/overview-models';
import {MetricType} from '../../../shared/metric-types';
import {benchmarkChangedTo} from '../../../store/actions';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {Observable, Subscription} from 'rxjs';
import {DropdownOption, EmDimension} from '../../../shared/models';
import {updateCommunityBenchmarkAlertStatus} from '../../../shared/reducer-helper';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';

@Component({
  selector: 'app-overview-tile',
  templateUrl: './overview-tile.component.html',
  styleUrls: ['./overview-tile.component.scss']
})
export class OverviewTileComponent implements OnChanges, OnDestroy {
  @Input() metricType: MetricType;
  @Input() title: string;
  @Input() selectedDateRange: string;
  @Input() previousDateRange: string;
  @Input() comparedTo: string;
  @Input() legendData: LegendData[];
  @Input() overviewChartOptions: OverviewChartOptions;
  @Input() showEmOverviewGraph: boolean;
  @Input() showOverviewZipTile: boolean;
  @Input() showProgressBar: boolean;
  @Input() varianceSubtractFrom: string;
  @Input() varianceSubtract: string;
  @Input() variance: string;
  @Input() varianceClass: string;
  @Input() previousYearDifference: string;
  @Input() previousYearDifferenceClass: string;
  @Input() isWrvus: boolean;
  @Input() isCfteAdjusted: boolean;
  @Input() fromPdfExport = false;
  @Input() emMetrics: EmDimension[];
  @Input() benchmarkLabel: string;
  @Input() hasCommunityBenchmarks: boolean;
  @Input() hasAcademicBenchmarks: boolean;
  @Input() telehealthBenchmarksEnabled = false;

  hasBenchmarks = true;
  benchmarkPercentile: BenchmarkPercentile;
  benchMarkDropDownClass: string;
  legendDataTop?: LegendData[];
  legendDataBottom?: LegendData[];
  MetricType = MetricType;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  // noinspection JSUnusedLocalSymbols (this actually is used in the html)
  benchmarkPercentiles: DropdownOption[] = [
    {value: BenchmarkPercentile.Mean, name: 'benchmark mean'},
    {value: BenchmarkPercentile.Percentile25th, name: '25th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile50th, name: '50th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile65th, name: '65th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile75th, name: '75th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile90th, name: '90th percentile benchmark'}
  ];
  private subscription: Subscription;

  constructor(
    @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
    private ngRedux: NgRedux<IAppState>
  ) {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.benchmarkPercentile$.subscribe((percentileFromStore: BenchmarkPercentile) => {
      this.benchmarkPercentile =
        this.metricType === MetricType.NewPatientVisits && percentileFromStore === BenchmarkPercentile.Percentile65th ?
          BenchmarkPercentile.Mean : percentileFromStore;
      this.setBenchmarkDropDownClass(this.benchmarkPercentile);
      if (this.legendData) {
        this.legendDataBottom = [];
        this.legendDataTop = [];
        for (let i = 0; i < this.legendData.length; i++) {
          if (i < 2) {
            this.legendDataTop.push(this.legendData[i]);
          } else {
            this.legendDataBottom.push(this.legendData[i]);
          }
        }
      }
    });
    if (this.metricType === MetricType.NewPatientVisits) {
      this.benchmarkPercentiles =
        this.benchmarkPercentiles.filter(option => option.value !== BenchmarkPercentile.Percentile65th);
    }

    this.hasBenchmarks = this.ngRedux.getState().display.viewCommunityBenchmarks ? this.hasCommunityBenchmarks : this.hasAcademicBenchmarks;

    updateCommunityBenchmarkAlertStatus(
      this.hasCommunityBenchmarks,
      this.hasAcademicBenchmarks,
      this.ngRedux,
      this.showProgressBar
    );
  }

  getBenchmarkHeaderClass(legend: LegendData): string {
    let headerClass = legend.benchmark ? 'legend-bchmk-header' : '';
    switch (this.metricType) {
      case MetricType.NewPatientVisits:
        headerClass = headerClass.concat(' npv-legend-header');
        break;
    }
    return headerClass;
  }

  updateBenchmarkPercentile(value: BenchmarkPercentile): void {
    this.setBenchmarkDropDownClass(value);
    this.ngRedux.dispatch(benchmarkChangedTo(value));
    this.analyticsService.handleGoogleAnalytics('Overview Benchmark', 'Benchmark Change', BenchmarkPercentile[value]);
  }

  setBenchmarkDropDownClass(benchmark: BenchmarkPercentile) {
    this.benchMarkDropDownClass = 'benchmark-mean';
    if (benchmark !== BenchmarkPercentile.Mean) {
      this.benchMarkDropDownClass = 'benchmark-percentile';
    }
  }
}
