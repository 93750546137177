import {MergedProviderProductivityMultiLevelData, ProductivitySummary} from '../productivity-summary/services/ProviderProductivity';
import {BaseColumn, FilterCriteria, NewFeatures, OntologyData, SummaryData, UserPreference} from './IAppState';
import {CfpVariableViewType, WrvuViewType} from '../shared/helpers';
import {
  CfpMultiLevelData,
  ClinicalSummaryCode,
  ClinicalSummaryConsolidation,
  ClinicalSummaryFamily,
  ClinicalSummaryRange,
  ProcedureSummaryDrillImpl
} from '../clinical-fingerprint/ClinicalSummary';
import {BenchmarkOption, CptViewType, MultilevelTab, MultilevelTabCollections} from '../shared/enums';
import {
  CollectionsByPayerGroupedByLevel,
  CollectionsData,
  CollectionsSummaryForAllTypes,
  MatchedCollectionsMultiLevelNodePathData,
  PayerCollections,
  PayerCollectionsData,
  PayerMatchedCollectionsMultiLevelNodePathData,
  SnapshotMatchedCollections
} from '../collections/Collection';
import {
  ApplicationConfigurationSetting,
  BatchExportHeader,
  BatchExportReportDetailsResponseEntity,
  CurrentMemberLocation,
  CustomGroupsDataAllFilters,
  DateRange,
  EmDimension,
  EvaluationManagement,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  ExternalRequestDetail,
  FeatureToggleSetting,
  MemberBillingArea,
  MemberData,
  MemberLocation,
  Members,
  MergedProductivityTrendEntry,
  NewPatientVisitSummary,
  PayerCategory,
  SortingCriterion,
  UserMemberData,
  UserProfile,
  UserSecurity,
  ZipCodeNewPatientVisits
} from '../shared/models';
import {BenchmarkPercentile} from '../shared/benchmark-types';
import {AnyAction} from 'redux';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedNpvLocationAggregatedByNode,
  MergedProviderNewPatientVisitMultiLevelData,
  NewPatientVisitTrendEntry,
  NpvLocationViewByOption
} from '../new-patient-visits/components/npv-models';
import {CptGroup} from '../clinical-fingerprint/procedure-summary/ProcedureSummaryModels';
import {CfpViewByOption} from '../clinical-fingerprint/procedure-summary/procedure-summary-graph/procedure-summary-graph-helper';
import {
  DenialCptCodeEntity,
  DenialPayerEntity,
  DenialReasonEntity,
  DenialsMultiLevelData,
  DenialsPayerData
} from '../denials/denials-models';
import {ColumnWithMatSort} from '../new-patient-visits/components/page-new-patient-visits-location/location-npv-table/npv-location-table-header/npv-location-table-header.component';
import {BatchExportScheduleExistingReportResponseEntity} from '../batch-export/multi-provider-export-redesign/mpe-dialog-helper';

export enum ActionType {
  BenchmarkChanged = 'BenchmarkChanged',
  OntologyLoaded = 'OntologyLoaded',
  CptGroupingsChanged = 'CptGroupingsChanged',
  MemberSelected = 'MemberSelected',
  MultilevelProviderProductivitiesChanged = 'MultilevelProviderProductivitiesChanged',
  MultilevelNewPatientVisitsChanged = 'MultilevelNewPatientVisitsChanged',
  MergedNpvByLocationDataChanged = 'MergedNpvByLocationDataChanged',
  ProductivitySummaryChanged = 'ProductivitySummaryChanged',
  DateRangeChanged = 'DateRangeChanged',
  MergedProductivityTrendDataChanged = 'MergedProductivityTrendDataChanged',
  OktaTokenChanged = 'OktaTokenChanged',
  ChargeDisplayChanged = 'ChargeDisplayChanged',
  ProcedureSummaryChargeDisplayChanged = 'ProcedureSummaryChargeDisplayChanged',
  PreviousTimePeriodDisplayChanged = 'PreviousTimePeriodDisplayChanged',
  MultipleProvidersChanged = 'MultipleProvidersChanged',
  MultipleSpecialtiesChanged = 'MultipleSpecialtiesChanged',
  MultipleDepartmentsChanged = 'MultipleDepartmentsChanged',
  PatientVisitsCountsDisplayChanged = 'PatientVisitsCountsDisplayChanged',
  PreviousPatientVisitsCountsDisplayChanged = 'PreviousPatientVisitsCountsDisplayChanged',
  IsCustomDisplayChanged = 'IsCustomDisplayChanged',
  IsProviderSelectedDisplayChanged = 'IsProviderSelectedDisplayChanged',
  EvaluationManagementChanged = 'EvaluationManagementChanged',
  EvaluationManagementMultilevelChanged = 'EvaluationManagementMultilevelChanged',
  SpecialtySummaryEvaluationManagementChanged = 'SpecialtySummaryEvaluationManagementChanged',
  EvaluationManagementSummaryChanged = 'EvaluationManagementSummaryChanged',
  NewPatientVisitSummaryChanged = 'NewPatientVisitSummaryChanged',
  MergedNewPatientVisitEntriesChanged = 'MergedNewPatientVisitEntriesChanged',
  NewPatientVisitTrendEntriesChanged = 'NewPatientVisitTrendEntriesChanged',
  NodePathChanged = 'NodePathChanged',
  CptFamilyFilterChanged = 'CptFamilyFilterChanged',
  CptRangeFilterChanged = 'CptRangeFilterChanged',
  UserProfileLoaded = 'UserProfileLoaded',
  MembersLoaded = 'MembersLoaded',
  FeatureToggleSettingsLoaded = 'FeatureToggleSettingsLoaded',
  MemberLocationChanged = 'MemberLocationChanged',
  LagKeyChanged = 'LagKeyChanged',
  PayerCategoryChanged = 'PayerCategoryChanged',
  ZipCodeNewPatientVisitsChanged = 'ZipCodeNewPatientVisitsChanged',
  WrvuViewTypeChanged = 'WrvuViewTypeChanged',
  CfpVariableViewTypeChanged = 'CfpVariableViewTypeChanged',
  ApplicationConfigurationSettingsLoaded = 'ApplicationConfigurationSettingsLoaded',
  UserPreferencesLoaded = 'UserPreferencesLoaded',
  RecentMonthLoaded = 'RecentMonthLoaded',
  SelectedNodesChanged = 'SelectedNodesChanged',
  ShowHierarchySearchChanged = 'ShowHierarchySearchChanged',
  ZeroSuppressionChanged = 'ZeroSuppressionChanged',
  FilterBannerEditChanged = 'FilterBannerEditChanged',
  FilterChosenByUserChanged = 'FilterChosenByUserChanged',
  NewProviderExportsChanged = 'NewProviderExportsChanged',
  BatchExportReportsChanged = 'BatchExportReportsChanged',
  NpvVarianceToggleChanged = 'NpvVarianceToggleChanged',
  WrvuVarianceToggleChanged = 'WrvuVarianceToggleChanged',
  CollectionsSnapshotVarianceToggleChanged = 'CollectionsSnapshotVarianceToggleChanged',
  WrvuSnapshotSortingCriteriaChanged = 'WrvuSnapshotSortingCriteriaChanged',
  NpvSnapshotSortingCriteriaChanged = 'NpvSnapshotSortingCriteriaChanged',
  CfpSortingCriteriaChanged = 'CfpSortingCriteriaChanged',
  CollectionsSnapshotSortingCriteriaChanged = 'CollectionsSnapshotSortingCriteriaChanged',
  CollectionsChargeDisplayedChanged = 'CollectionsChargeDisplayedChanged',
  ExpectedPaymentsDisplayedChanged = 'ExpectedPaymentsDisplayedChanged',
  ClinicalSummaryConsolidationChanged = 'ClinicalSummaryConsolidationChanged',
  ClinicalFingerprintMultilevelDataChanged = 'ClinicalFingerprintMultilevelDataChanged',
  SnapshotCollectionsDataChanged = 'SnapshotCollectionsDataChanged',
  CustomGroupsDataChanged = 'CustomGroupsDataChanged',
  CptViewTypeChanged = 'CptViewTypeChanged',
  NewFeaturesLoaded = 'NewFeaturesLoaded',
  MemberDataFormatLoaded = 'MemberDataFormatLoaded',
  ViewCommunityBenchmarksChanged = 'ViewCommunityBenchmarksChanged',
  CommunityBenchmarkAlertChanged = 'CommunityBenchmarkAlertChanged',
  PayerCollectionsDataChanged = 'PayerCollectionsDataChanged',
  PayerByLevelCollectionsDataChanged = 'PayerByLevelCollectionsDataChanged',
  SummaryCollectionsDataChanged = 'SummaryCollectionsDataChanged',
  EmDimensionChanged = 'EmDimensionChanged',
  NpvLocationViewByNodeChanged = 'NpvLocationViewByNodeChanged',
  EmNpvBenchmarkOptionChanged = 'EmNpvBenchmarkOptionChanged',
  ProcedureSummaryDrillChanged = 'ProcedureSummaryDrillChanged',
  SelectedCptGroupChanged = 'SelectedCptGroupChanged',
  ViewPSGraphByNodeChanged = 'ViewPSGraphByNodeChanged',
  FilterPSGraphByOntologyChanged = 'FilterPSGraphByOntologyChanged',
  FilterPSGraphByChanged = 'FilterPSGraphByChanged',
  FilterNpvLocationGraphByOntologyChanged = 'FilterNpvLocationGraphByOntologyChanged',
  NpvLocationSortingChanged = 'NpvLocationSortingChanged',
  FilterNpvLocationGraphByLocationChanged = 'FilterNpvLocationGraphByLocationChanged',
  NpvLocationDepartmentColumnsChanged = 'NpvLocationDepartmentColumnsChanged',
  NpvLocationSpecialtyColumnsChanged = 'NpvLocationSpecialtyColumnsChanged',
  NpvLocationProviderColumnsChanged = 'NpvLocationProviderColumnsChanged',
  SnapshotCollectionsColumnsChanged = 'SnapshotCollectionsColumnsChanged',
  NpvByDepartmentColumnsChanged = 'NpvByDepartmentColumnsChanged',
  NpvBySpecialtyColumnsChanged = 'NpvBySpecialtyColumnsChanged',
  NpvByProviderColumnsChanged = 'NpvByProviderColumnsChanged',
  TrendNPVColumnsChanged = 'TrendNPVColumnsChanged',
  SnapshotWrvuColumnsChanged = 'SnapshotWrvuColumnsChanged',
  ProviderWrvuColumnsChanged = 'ProviderWrvuColumnsChanged',
  SpecialtyPerformanceWrvuColumnsChanged = 'SpecialtyPerformanceWrvuColumnsChanged',
  TrendWrvuColumnsChanged = 'TrendWrvuColumnsChanged',
  SnapshotPayerCollectionsColumnsChanged = 'SnapshotPayerCollectionsColumnsChanged',
  PayerByLevelCollectionsPayerColumnsChanged = 'PayerByLevelCollectionsPayerColumnsChanged',
  PayerByLevelCollectionsStaticColumnsChanged = 'PayerByLevelCollectionsStaticColumnsChanged',
  UserSecurityLoaded = 'UserSecurityLoaded',
  AllUserPreferenceLoaded = 'AllUserPreferenceLoaded',
  UserMemberLoaded = 'UserMemberLoaded',
  MemberBillingAreaLoaded = 'MemberBillingAreaLoaded',
  MemberBillingAreaSelected = 'MemberBillingAreaSelected',
  InvoiceStatusChanged = 'InvoiceStatusChanged',
  TelehealthFlagChanged = 'TelehealthFlagChanged',
  FilterCriteriaChanged = 'FilterCriteriaChanged',
  MemberLocationsChanged = 'MemberLocationsChanged',
  CfpVarianceToggleChanged = 'CfpVarianceToggleChanged',
  ExportingStatusChanged = 'ExportingStatusChanged',
  LagKeyLessThanChanged = 'LagKeyLessThanChanged',
  DrillDownChanged = 'DrillDownChanged',
  DrillDownHistoryAppended = 'DrillDownHistoryAppended',
  UndoDrillDownHistory = 'UndoDrillDownHistory',
  CfteAdjustedCountDisplayChanged = 'CfteAdjustedCountDisplayChanged',
  CfpChargesDisplayChanged = 'CfpChargesDisplayChanged',
  MultiLevelCollectionsColumnsChanged = 'MultiLevelCollectionsColumnsChanged',
  MultiLevelCollectionsChanged = 'MultiLevelCollectionsChanged',
  MultiLevelPayerCollectionsChanged = 'MultiLevelPayerCollectionsChanged',
  MultiLevelPayerCollectionsColumnsChanged = 'MultiLevelPayerCollectionsColumnsChanged',
  CollectionsChosenTabChanged = 'CollectionsChosenTabChanged',
  MultilevelTabChanged = 'MultilevelTabChanged',
  CollectionsByPayerDenialsChanged = 'CollectionsByPayerDenialsChanged',
  CollectionsByPayerDenialsTopReasonsChanged = 'CollectionsByPayerDenialsTopReasonsChanged',
  CollectionsByPayerDenialsTopReasonsByCptsDeniedChanged = 'CollectionsByPayerDenialsTopReasonsChanged',
  CollectionsByPayerDenialsTopReasonsByChargesChanged = 'CollectionsByPayerDenialsTopReasonsChanged',
  CollectionsByPayerDenialsTopCptsChanged = 'CollectionsByPayerDenialsTopCptsChanged',
  CollectionsByPayerDenialsTopCptsByCptsDeniedChanged = 'CollectionsByPayerDenialsTopCptsChanged',
  CollectionsByPayerDenialsTopCptsByChargesChanged = 'CollectionsByPayerDenialsTopCptsChanged',
  DenialsChanged = 'DenialsChanged',
  DenialsSortingCriteriaChanged = 'DenialsSortingCriteriaChanged',
  DenialsChargesChanged = 'DenialsChargesChanged',
  ChargesTerminallyDeniedChanged = 'ChargesTerminallyDeniedChanged',
  DeniedCPTsPaidChanged = 'DeniedCPTsPaidChanged',
  TerminalDenialRateChanged = 'TerminalDenialRateChanged',
  DenialsColumnsChanged = 'DenialsColumnsChanged',
  DenialsChosenTabChanged = 'DenialsChosenTabChanged',
  MultiLevelDenialsColumnsChanged = 'MultiLevelDenialsColumnsChanged',
  MultiLevelDenialsChanged = 'MultiLevelDenialsChanged',
  BatchExportReportDetailsChanged = 'BatchExportReportDetailsChanged',
  BatchExportScheduleReportDetailsChanged = 'BatchExportScheduleReportDetailsChanged',
  DenialsVarianceToggleChanged = 'DenialsVarianceToggleChanged',
  CustomGroupIdChanged = 'CustomGroupIdChanged',
  RequestIdLoaded = 'RequestIdLoaded',
  RequestDetailLoaded = 'RequestDetailLoaded',
  RequestDetailsCleared = 'RequestDetailsCleared',
}

export interface OktaTokenChanged extends AnyAction {
  token: string;
}

export function isOktaTokenChanged(action: AppAction): action is OktaTokenChanged {
  return action.type === ActionType.OktaTokenChanged;
}

export function oktaTokenLoadedTo(oktaToken: string) {
  return {
    token: oktaToken,
    type: ActionType.OktaTokenChanged
  };
}

export interface MemberSelected extends AnyAction {
  member: MemberData;
}

export function isMemberSelected(action: AppAction): action is MemberSelected {
  return action.type === ActionType.MemberSelected;
}

export function memberSelectedTo(member: MemberData) {
  return {
    member,
    type: ActionType.MemberSelected
  };
}

export interface MembersLoaded extends AnyAction {
  members: Members;
}

export function isMembersLoaded(action: AppAction): action is MembersLoaded {
  return action.type === ActionType.MembersLoaded;
}

export function membersLoadedTo(members: Members) {
  return {
    members,
    type: ActionType.MembersLoaded
  };
}

export interface UserProfileLoaded extends AnyAction {
  userProfile: UserProfile;
}

export function isUserProfileLoaded(action: AppAction): action is UserProfileLoaded {
  return action.type === ActionType.UserProfileLoaded;
}

export function userProfileLoadedTo(userProfile: UserProfile) {
  return {
    userProfile,
    type: ActionType.UserProfileLoaded
  };
}

export interface FeatureToggleSettingsLoaded extends AnyAction {
  featureToggleSettings: FeatureToggleSetting[];
}

export function isFeatureToggleSettingsLoaded(action: AppAction): action is FeatureToggleSettingsLoaded {
  return action.type === ActionType.FeatureToggleSettingsLoaded;
}

export function featureToggleSettingsLoadedTo(featureToggleSettings: FeatureToggleSetting[]) {
  return {
    featureToggleSettings,
    type: ActionType.FeatureToggleSettingsLoaded
  };
}

export interface OntologyLoaded extends AnyAction {
  ontologyData: OntologyData;
}

export function isOntologyLoaded(action: AppAction): action is OntologyLoaded {
  return action.type === ActionType.OntologyLoaded;
}

export function ontologyLoadedTo(ontologyData: OntologyData): OntologyLoaded {
  return {
    ontologyData: ontologyData,
    type: ActionType.OntologyLoaded
  };
}

export interface CptGroupingsChanged extends AnyAction {
  cptGroups?: CptGroup[];
}

export function isCptGroupingsChanged(action: AppAction): action is CptGroupingsChanged {
  return action.type === ActionType.CptGroupingsChanged;
}

export function cptGroupingsChangedTo(cptGroups?: CptGroup[]): CptGroupingsChanged {
  return {
    cptGroups: cptGroups,
    type: ActionType.CptGroupingsChanged
  };
}

export interface BenchmarkChanged extends AnyAction {
  benchmarkPercentile: BenchmarkPercentile;
}

export function isBenchmarkChanged(action: AppAction): action is BenchmarkChanged {
  return action.type === ActionType.BenchmarkChanged;
}

export function benchmarkChangedTo(benchmarkPercentile: BenchmarkPercentile): BenchmarkChanged {
  return {
    benchmarkPercentile: benchmarkPercentile,
    type: ActionType.BenchmarkChanged
  };
}

export interface DateRangeChanged extends AnyAction {
  dateRange: DateRange;
}

export function isDateRangeChanged(action: AppAction): action is DateRangeChanged {
  return action.type === ActionType.DateRangeChanged;
}

export function dateRangeChangedTo(
  dateRange: DateRange
): DateRangeChanged {
  return {
    dateRange: dateRange,
    type: ActionType.DateRangeChanged
  };
}

export interface NodePathChanged extends AnyAction {
  nodePath: string;
}

export function isNodePathChanged(action: AppAction): action is NodePathChanged {
  return action.type === ActionType.NodePathChanged;
}

export function nodePathChangedTo(nodePath: string): NodePathChanged {
  return {
    nodePath: nodePath,
    type: ActionType.NodePathChanged
  };
}

export interface CptFamilyFilterChanged extends AnyAction {
  cptFamilyFilter?: string;
}

export function isCptFamilyFilterChanged(action: AppAction): action is CptFamilyFilterChanged {
  return action.type === ActionType.CptFamilyFilterChanged;
}

export function cptFamilyFilterChangedTo(cptFamilyFilter?: string): CptFamilyFilterChanged {
  return {
    cptFamilyFilter: cptFamilyFilter,
    type: ActionType.CptFamilyFilterChanged
  };
}

export interface CptRangeFilterChanged extends AnyAction {
  cptRangeFilter?: string;
}

export function isCptRangeFilterChanged(action: AppAction): action is CptRangeFilterChanged {
  return action.type === ActionType.CptRangeFilterChanged;
}

export function cptRangeFilterChangedTo(cptRangeFilter?: string): CptRangeFilterChanged {
  return {
    cptRangeFilter: cptRangeFilter,
    type: ActionType.CptRangeFilterChanged
  };
}

export interface ProductivitySummaryChanged extends AnyAction {
  productivitySummaryData: SummaryData<ProductivitySummary>;
}

export function isProductivitySummaryChanged(action: AppAction): action is ProductivitySummaryChanged {
  return action.type === ActionType.ProductivitySummaryChanged;
}

export function productivitySummaryChangedTo(productivitySummaryData: SummaryData<ProductivitySummary>):
  ProductivitySummaryChanged {
  return {
    productivitySummaryData: productivitySummaryData,
    type: ActionType.ProductivitySummaryChanged
  };
}

export interface MultilevelProviderProductivitiesChanged extends AnyAction {
  providerProductivityMultilevelData: MergedProviderProductivityMultiLevelData;
}

export function isMultilevelProviderProductivityChanged(action: AppAction): action is MultilevelProviderProductivitiesChanged {
  return action.type === ActionType.MultilevelProviderProductivitiesChanged;
}

export function multilevelProviderProductivitiesChangedTo(multilevelProviderProductivityData: MergedProviderProductivityMultiLevelData) {
  return {
    providerProductivityMultilevelData: multilevelProviderProductivityData,
    type: ActionType.MultilevelProviderProductivitiesChanged
  };
}

export interface MultilevelNewPatientVisitsChanged extends AnyAction {
  multilevelNewPatientVisitsData: MergedProviderNewPatientVisitMultiLevelData;
}

export function isMultilevelNewPatientVisitsChanged(action: AppAction): action is MultilevelNewPatientVisitsChanged {
  return action.type === ActionType.MultilevelNewPatientVisitsChanged;
}

export function multilevelNewPatientVisitsChangedTo(multilevelNewPatientVisitsData: MergedProviderNewPatientVisitMultiLevelData) {
  return {
    multilevelNewPatientVisitsData: multilevelNewPatientVisitsData,
    type: ActionType.MultilevelNewPatientVisitsChanged
  };
}

export interface MergedNpvByLocationDataChanged extends AnyAction {
  mergedNpvLocationAggregatedByNode: MergedNpvLocationAggregatedByNode[];
}

export function isMergedNpvByLocationDataChanged(action: AppAction): action is MergedNpvByLocationDataChanged {
  return action.type === ActionType.MergedNpvByLocationDataChanged;
}

export function mergedNpvByLocationDataChangedTo(mergedNpvLocationAggregatedByNode: MergedNpvLocationAggregatedByNode[]) {
  return {
    mergedNpvLocationAggregatedByNode: mergedNpvLocationAggregatedByNode,
    type: ActionType.MergedNpvByLocationDataChanged
  };
}

export interface ClinicalSummaryConsolidationChanged extends AnyAction {
  cptClinicalSummaries: ClinicalSummaryCode[];
  familyClinicalSummaries: ClinicalSummaryFamily[];
  rangeClinicalSummaries: ClinicalSummaryRange[];
}

export function isClinicalSummaryConsolidationChanged(action: AppAction): action is ClinicalSummaryConsolidationChanged {
  return action.type === ActionType.ClinicalSummaryConsolidationChanged;
}

export function clinicalSummaryConsolidationChangedTo(clinicalSummaryConsolidationData: ClinicalSummaryConsolidation) {
  return {
    cptClinicalSummaries: clinicalSummaryConsolidationData.cptClinicalSummaries,
    familyClinicalSummaries: clinicalSummaryConsolidationData.familyClinicalSummaries,
    rangeClinicalSummaries: clinicalSummaryConsolidationData.rangeClinicalSummaries,
    type: ActionType.ClinicalSummaryConsolidationChanged
  };
}

export interface ClinicalFingerprintMultilevelDataChanged extends AnyAction {
  clinicalFingerprintMultilevelData: CfpMultiLevelData;
}

export function isClinicalFingerprintMultilevelDataChanged(action: AppAction): action is ClinicalFingerprintMultilevelDataChanged {
  return action.type === ActionType.ClinicalFingerprintMultilevelDataChanged;
}

export function clinicalFingerprintMultilevelDataChangedTo(clinicalFingerprintMultilevelData: CfpMultiLevelData) {
  return {
    clinicalFingerprintMultilevelData,
    // clinicalFingerprintMultilevelData: aCfpHierarchy(),
    type: ActionType.ClinicalFingerprintMultilevelDataChanged
  };
}

export interface SnapshotCollectionsDataChanged extends AnyAction {
  snapshotMatchedCollections: SnapshotMatchedCollections[];
}

export function isSnapshotCollectionsDataChanged(action: AppAction): action is SnapshotCollectionsDataChanged {
  return action.type === ActionType.SnapshotCollectionsDataChanged;
}

export function snapshotCollectionsDataChangedTo(collectionsData: CollectionsData) {
  return {
    snapshotMatchedCollections: collectionsData.snapshotMatchedCollections,
    type: ActionType.SnapshotCollectionsDataChanged
  };
}

export interface SnapshotCollectionsColumnsChanged extends AnyAction {
  displayedCollectionsColumns: BaseColumn[];
}

export function isSnapshotCollectionsColumnsChanged(action: AppAction): action is SnapshotCollectionsColumnsChanged {
  return action.type === ActionType.SnapshotCollectionsColumnsChanged;
}

export function snapshotCollectionsColumnsChangedTo(displayedCollectionsColumns: BaseColumn[]) {
  return {
    displayedCollectionsColumns: displayedCollectionsColumns,
    type: ActionType.SnapshotCollectionsColumnsChanged
  };
}

export interface NPVByDepartmentColumnsChanged extends AnyAction {
  displayedNPVByDepartmentColumns: BaseColumn[];
}

export function isNPVByDepartmentColumnsChanged(action: AppAction): action is NPVByDepartmentColumnsChanged {
  return action.type === ActionType.NpvByDepartmentColumnsChanged;
}

export function NPVByDepartmentColumnsChangedTo(displayedNPVByDepartmentColumns: BaseColumn[]) {
  return {
    displayedNPVByDepartmentColumns: displayedNPVByDepartmentColumns,
    type: ActionType.NpvByDepartmentColumnsChanged
  };
}

export interface NPVBySpecialtyColumnsChanged extends AnyAction {
  displayedNPVBySpecialtyColumns: BaseColumn[];
}

export function isNPVBySpecialtyColumnsChanged(action: AppAction): action is NPVBySpecialtyColumnsChanged {
  return action.type === ActionType.NpvBySpecialtyColumnsChanged;
}

export function NPVBySpecialtyColumnsChangedTo(displayedNPVBySpecialtyColumns: BaseColumn[]) {
  return {
    displayedNPVBySpecialtyColumns: displayedNPVBySpecialtyColumns,
    type: ActionType.NpvBySpecialtyColumnsChanged
  };
}

export interface NPVByProviderColumnsChanged extends AnyAction {
  displayedNPVByProviderColumns: BaseColumn[];
}

export function isNPVByProviderColumnsChanged(action: AppAction): action is NPVByProviderColumnsChanged {
  return action.type === ActionType.NpvByProviderColumnsChanged;
}

export function NPVByProviderColumnsChangedTo(displayedNPVByProviderColumns: BaseColumn[]) {
  return {
    displayedNPVByProviderColumns: displayedNPVByProviderColumns,
    type: ActionType.NpvByProviderColumnsChanged
  };
}


export interface TrendNPVColumnsChanged extends AnyAction {
  displayedNPVTrendColumns: BaseColumn[];
}

export function isTrendNPVColumnsChanged(action: AppAction): action is TrendNPVColumnsChanged {
  return action.type === ActionType.TrendNPVColumnsChanged;
}

export function trendNPVColumnsChangedTo(displayedNPVTrendColumns: BaseColumn[]) {
  return {
    displayedNPVTrendColumns: displayedNPVTrendColumns,
    type: ActionType.TrendNPVColumnsChanged
  };
}

export interface PayerCollectionsDataChanged extends AnyAction {
  payerCollections: PayerCollections[];
}

export function isPayerCollectionsDataChanged(action: AppAction): action is PayerCollectionsDataChanged {
  return action.type === ActionType.PayerCollectionsDataChanged;
}

export function payerCollectionsDataChangedTo(payerCollectionsData: PayerCollectionsData) {
  return {
    payerCollections: payerCollectionsData.payerMatchedCollections,
    type: ActionType.PayerCollectionsDataChanged
  };
}


export interface PayerByLevelCollectionsDataChanged extends AnyAction {
  payerByLevelCollectionsData: CollectionsByPayerGroupedByLevel[];
}

export function isPayerByLevelCollectionsDataChanged(action: AppAction): action is PayerByLevelCollectionsDataChanged {
  return action.type === ActionType.PayerByLevelCollectionsDataChanged;
}

export function payerByLevelCollectionsDataChangedTo(payerByLevelCollectionsData: CollectionsByPayerGroupedByLevel[]) {
  return {
    payerByLevelCollectionsData: payerByLevelCollectionsData,
    type: ActionType.PayerByLevelCollectionsDataChanged
  };
}

export interface PayerByLevelCollectionsPayerColumnsChanged extends AnyAction {
  payerByLevelCollectionsPayerColumns: BaseColumn[];
}

export function isPayerByLevelCollectionsPayerColumnsChanged(action: AppAction): action is PayerByLevelCollectionsPayerColumnsChanged {
  return action.type === ActionType.PayerByLevelCollectionsPayerColumnsChanged;
}

export function payerByLevelCollectionsPayerColumnsChangedTo(payerByLevelCollectionsPayerColumns: BaseColumn[]) {
  return {
    payerByLevelCollectionsPayerColumns: payerByLevelCollectionsPayerColumns,
    type: ActionType.PayerByLevelCollectionsPayerColumnsChanged
  };
}


export interface PayerByLevelCollectionsStaticColumnsChanged extends AnyAction {
  payerByLevelCollectionsStaticColumns: BaseColumn[];
}

export function isPayerByLevelCollectionsStaticColumnsChanged(action: AppAction): action is PayerByLevelCollectionsStaticColumnsChanged {
  return action.type === ActionType.PayerByLevelCollectionsStaticColumnsChanged;
}

export function payerByLevelCollectionsStaticColumnsChangedTo(payerByLevelCollectionsStaticColumns: BaseColumn[]) {
  return {
    payerByLevelCollectionsStaticColumns: payerByLevelCollectionsStaticColumns,
    type: ActionType.PayerByLevelCollectionsStaticColumnsChanged
  };
}

export interface SnapshotPayerCollectionsColumnsChanged extends AnyAction {
  displayedPayerCollectionsColumns: BaseColumn[];
}

export function isSnapshotPayerCollectionsColumnsChanged(action: AppAction): action is SnapshotPayerCollectionsColumnsChanged {
  return action.type === ActionType.SnapshotPayerCollectionsColumnsChanged;
}

export function snapshotPayerCollectionsColumnsChangedTo(displayedPayerCollectionsColumns: BaseColumn[]) {
  return {
    displayedPayerCollectionsColumns: displayedPayerCollectionsColumns,
    type: ActionType.SnapshotPayerCollectionsColumnsChanged
  };
}

export interface MultiLevelPayerCollectionsColumnsChanged extends AnyAction {
  multiLevelByNodePathPayerCollectionsColumns: BaseColumn[];
}

export function isMultiLevelByNodePathPayerCollectionsColumnsChanged(action: AppAction):
  action is MultiLevelPayerCollectionsColumnsChanged {
  return action.type === ActionType.MultiLevelPayerCollectionsColumnsChanged;
}

export function multiLevelByNodePathPayerCollectionsColumnsChangedTo(multiLevelByNodePathPayerCollectionsColumns:
                                                                       BaseColumn[]) {
  return {
    multiLevelByNodePathPayerCollectionsColumns: multiLevelByNodePathPayerCollectionsColumns,
    type: ActionType.MultiLevelPayerCollectionsColumnsChanged
  };
}

export interface MultiLevelPayerCollectionsChanged extends AnyAction {
  multilevelPayerCollectionsData: PayerMatchedCollectionsMultiLevelNodePathData;
}

export function isMultilevelPayerCollectionsChanged(action: AppAction): action is MultiLevelPayerCollectionsChanged {
  return action.type === ActionType.MultiLevelPayerCollectionsChanged;
}

export function multilevelPayerCollectionsChangedTo(multilevelPayerCollectionsData: PayerMatchedCollectionsMultiLevelNodePathData) {
  return {
    multilevelPayerCollectionsData: multilevelPayerCollectionsData,
    type: ActionType.MultiLevelPayerCollectionsChanged
  };
}

export interface CollectionsByPayerDenialsChanged extends AnyAction {
  denialsData: DenialPayerEntity[];
}

export function isCollectionsByPayerDenialsChanged(action: AppAction): action is CollectionsByPayerDenialsChanged {
  return action.type === ActionType.CollectionsByPayerDenialsChanged;
}

export function collectionsByPayerDenialsChangedTo(denialsData: DenialPayerEntity[] | undefined) {
  return {
    denialsData: denialsData,
    type: ActionType.CollectionsByPayerDenialsChanged
  };
}

export interface CollectionsByPayerDenialsTopReasonsChanged extends AnyAction {
  denialsTopReasonsData: DenialReasonEntity[];
}

export function isCollectionsByPayerDenialsTopReasonsChanged(action: AppAction): action is CollectionsByPayerDenialsTopReasonsChanged {
  return action.type === ActionType.CollectionsByPayerDenialsTopReasonsChanged;
}

export function collectionsByPayerDenialsTopReasonsChangedTo(denialsTopReasonsData: DenialReasonEntity[] | undefined) {
  return {
    denialsTopReasonsData: denialsTopReasonsData,
    type: ActionType.CollectionsByPayerDenialsTopReasonsChanged
  };
}

export interface CollectionsByPayerDenialsTopReasonsByCptsDeniedChanged extends AnyAction {
  denialsTopReasonsData: DenialReasonEntity[];
}

export function isCollectionsByPayerDenialsTopReasonsByCptsDeniedChanged(action: AppAction):
  action is CollectionsByPayerDenialsTopReasonsByCptsDeniedChanged {
  return action.type === ActionType.CollectionsByPayerDenialsTopReasonsByCptsDeniedChanged;
}

export function collectionsByPayerDenialsTopReasonsByCptsDeniedChangedTo(denialsTopReasonsData: DenialReasonEntity[] | undefined) {
  return {
    denialsTopReasonsData: denialsTopReasonsData,
    type: ActionType.CollectionsByPayerDenialsTopReasonsByCptsDeniedChanged
  };
}

export interface CollectionsByPayerDenialsTopReasonsByChargesChanged extends AnyAction {
  denialsTopReasonsData: DenialReasonEntity[];
}

export function isCollectionsByPayerDenialsTopReasonsByChargesChanged(action: AppAction):
  action is CollectionsByPayerDenialsTopReasonsByChargesChanged {
  return action.type === ActionType.CollectionsByPayerDenialsTopReasonsByChargesChanged;
}

export function collectionsByPayerDenialsTopReasonsByChargesChangedTo(denialsTopReasonsData: DenialReasonEntity[] | undefined) {
  return {
    denialsTopReasonsData: denialsTopReasonsData,
    type: ActionType.CollectionsByPayerDenialsTopReasonsByChargesChanged
  };
}

export interface CollectionsByPayerDenialsTopCptsChanged extends AnyAction {
  denialsTopCptsData: DenialCptCodeEntity[];
}

export function isCollectionsByPayerDenialsTopCptsChanged(action: AppAction): action is CollectionsByPayerDenialsTopCptsChanged {
  return action.type === ActionType.CollectionsByPayerDenialsTopCptsChanged;
}

export function collectionsByPayerDenialsTopCptsChangedTo(denialsTopCptsData: DenialCptCodeEntity[] | undefined) {
  return {
    denialsTopCptsData: denialsTopCptsData,
    type: ActionType.CollectionsByPayerDenialsTopCptsChanged
  };
}

export interface CollectionsByPayerDenialsTopCptsByCptsDeniedChanged extends AnyAction {
  denialsTopCptsData: DenialCptCodeEntity[];
}

export function isCollectionsByPayerDenialsTopCptsByCptsDeniedChanged(action: AppAction):
  action is CollectionsByPayerDenialsTopCptsByCptsDeniedChanged {
  return action.type === ActionType.CollectionsByPayerDenialsTopCptsByCptsDeniedChanged;
}

export function collectionsByPayerDenialsTopCptsByCptsDeniedChangedTo(denialsTopCptsData: DenialCptCodeEntity[] | undefined) {
  return {
    denialsTopCptsData: denialsTopCptsData,
    type: ActionType.CollectionsByPayerDenialsTopCptsByCptsDeniedChanged
  };
}

export interface CollectionsByPayerDenialsTopCptsByChargesChanged extends AnyAction {
  denialsTopCptsData: DenialCptCodeEntity[];
}

export function isCollectionsByPayerDenialsTopCptsByChargesChanged(action: AppAction):
  action is CollectionsByPayerDenialsTopCptsByChargesChanged {
  return action.type === ActionType.CollectionsByPayerDenialsTopCptsByChargesChanged;
}

export function collectionsByPayerDenialsTopCptsByChargesChangedTo(denialsTopCptsData: DenialCptCodeEntity[] | undefined) {
  return {
    denialsTopCptsData: denialsTopCptsData,
    type: ActionType.CollectionsByPayerDenialsTopCptsByChargesChanged
  };
}

export interface MultiLevelCollectionsColumnsChanged extends AnyAction {
  multiLevelByNodePathCollectionsColumns: BaseColumn[];
}

export function isMultiLevelByNodePathCollectionsColumnsChanged(action: AppAction): action is MultiLevelCollectionsColumnsChanged {
  return action.type === ActionType.MultiLevelCollectionsColumnsChanged;
}

export function multiLevelByNodePathCollectionsColumnsChangedTo(multiLevelByNodePathCollectionsColumns: BaseColumn[]) {
  return {
    multiLevelByNodePathCollectionsColumns: multiLevelByNodePathCollectionsColumns,
    type: ActionType.MultiLevelCollectionsColumnsChanged
  };
}

export interface MultiLevelCollectionsChanged extends AnyAction {
  multilevelCollectionsData: MatchedCollectionsMultiLevelNodePathData;
}

export function isMultilevelCollectionsChanged(action: AppAction): action is MultiLevelCollectionsChanged {
  return action.type === ActionType.MultiLevelCollectionsChanged;
}

export function multilevelCollectionsChangedTo(multilevelCollectionsData: MatchedCollectionsMultiLevelNodePathData) {
  return {
    multilevelCollectionsData: multilevelCollectionsData,
    type: ActionType.MultiLevelCollectionsChanged
  };
}

export interface CollectionsChosenTabChanged extends AnyAction {
  collectionsChosenTab: MultilevelTabCollections;
}

export function isCollectionsChosenTabChanged(action: AppAction): action is CollectionsChosenTabChanged {
  return action.type === ActionType.CollectionsChosenTabChanged;
}

export function collectionsChosenTabChangedTo(collectionsChosenTab: MultilevelTabCollections) {
  return {
    collectionsChosenTab: collectionsChosenTab,
    type: ActionType.CollectionsChosenTabChanged
  };
}

export interface CustomGroupsDataChanged extends AnyAction {
  customGroupsData: CustomGroupsDataAllFilters[];
}

export interface MultilevelTabChanged extends AnyAction {
  multilevelTab: MultilevelTab;
}

export function isMultilevelTabChanged(action: AppAction): action is MultilevelTabChanged {
  return action.type === ActionType.MultilevelTabChanged;
}

export function multilevelTabChangedTo(multilevelTab: MultilevelTab) {
  return {
    multilevelTab,
    type: ActionType.MultilevelTabChanged
  };
}

export interface CustomGroupsDataChanged extends AnyAction {
  customGroupsData: CustomGroupsDataAllFilters[];
}

export function isCustomGroupsDataChanged(action: AppAction): action is CustomGroupsDataChanged {
  return action.type === ActionType.CustomGroupsDataChanged;
}

export function customGroupsDataChangedTo(customGroupsData: CustomGroupsDataAllFilters[]) {
  return {
    customGroupsData: customGroupsData,
    type: ActionType.CustomGroupsDataChanged
  };
}

export interface CustomGroupIdChanged extends AnyAction {
  customGroupId: number;
}

export function isCustomGroupIdChanged(action: AppAction): action is CustomGroupIdChanged {
  return action.type === ActionType.CustomGroupIdChanged;
}

export function customGroupIdChangedTo(id: number) {
  return {
    customGroupId: id,
    type: ActionType.CustomGroupIdChanged
  };
}

export interface RequestIdLoaded extends AnyAction {
  requestId: number;
}

export function isRequestIdLoaded(action: AppAction): action is RequestIdLoaded {
  return action.type === ActionType.RequestIdLoaded;
}

export function requestIdLoadedTo(id: number) {
  return {
    requestId: id,
    type: ActionType.RequestIdLoaded
  };
}

export interface RequestDetailLoaded extends AnyAction {
  requestDetail: ExternalRequestDetail;
}

export function isRequestDetailLoaded(action: AppAction): action is RequestDetailLoaded {
  return action.type === ActionType.RequestDetailLoaded;
}

export function requestDetailLoadedTo(requestDetail: ExternalRequestDetail) {
  return {
    requestDetail,
    type: ActionType.RequestDetailLoaded
  };
}

export function isRequestDetailsCleared(action: AppAction): boolean {
  return action.type === ActionType.RequestDetailsCleared;
}

export function clearRequestDetails() {
  return {
    type: ActionType.RequestDetailsCleared
  };
}

export interface MergedProductivityTrendDataChanged extends AnyAction {
  mergedProductivityTrend: MergedProductivityTrendEntry[];
}

export function isMergedProductivityTrendDataChanged(action: AppAction): action is MergedProductivityTrendDataChanged {
  return action.type === ActionType.MergedProductivityTrendDataChanged;
}

export function mergedProductivityTrendDataChangedTo(mergedProductivityTrend: MergedProductivityTrendEntry[]):
  MergedProductivityTrendDataChanged {
  return {
    mergedProductivityTrend: mergedProductivityTrend,
    type: ActionType.MergedProductivityTrendDataChanged
  };
}


export interface EvaluationManagementChanged extends AnyAction {
  providerEvaluationManagements: EvaluationManagement;
}

export function isEvaluationManagementChanged(action: AppAction): action is EvaluationManagementChanged {
  return action.type === ActionType.EvaluationManagementChanged;
}

export function evaluationManagementChangedTo(providerEvaluationManagements: EvaluationManagement):
  EvaluationManagementChanged {
  return {
    providerEvaluationManagements: providerEvaluationManagements,
    type: ActionType.EvaluationManagementChanged
  };
}

export interface EvaluationManagementMultilevelChanged extends AnyAction {
  providerEvaluationManagementMultilevel: EvaluationManagementMultilevel;
}

export function isEvaluationManagementMultilevelChanged(action: AppAction): action is EvaluationManagementMultilevelChanged {
  return action.type === ActionType.EvaluationManagementMultilevelChanged;
}

export function evaluationManagementMultilevelChangedTo(providerEvaluationManagements: EvaluationManagementMultilevel):
  EvaluationManagementMultilevelChanged {
  return {
    providerEvaluationManagementMultilevel: providerEvaluationManagements,
    type: ActionType.EvaluationManagementMultilevelChanged
  };
}

export interface SpecialtySummaryEvaluationManagementChanged extends AnyAction {
  specialtyEvaluationManagementSummary: EvaluationManagementSummary;
}

export function isSpecialtySummaryEvaluationManagementChanged(action: AppAction): action is SpecialtySummaryEvaluationManagementChanged {
  return action.type === ActionType.SpecialtySummaryEvaluationManagementChanged;
}

export function specialtyEvaluationManagementSummaryChangedTo(specialtyEvaluationManagementSummary: EvaluationManagementSummary):
  SpecialtySummaryEvaluationManagementChanged {
  return {
    specialtyEvaluationManagementSummary: specialtyEvaluationManagementSummary,
    type: ActionType.SpecialtySummaryEvaluationManagementChanged
  };
}


export interface EvaluationManagementSummaryChanged extends AnyAction {
  evaluationManagementSummary: EvaluationManagementSummary;
}

export function isEvaluationManagementSummaryChanged(action: AppAction): action is EvaluationManagementSummaryChanged {
  return action.type === ActionType.EvaluationManagementSummaryChanged;
}

export function evaluationManagementSummaryChangedTo(evaluationManagementSummary: EvaluationManagementSummary):
  EvaluationManagementSummaryChanged {
  return {
    evaluationManagementSummary: evaluationManagementSummary,
    type: ActionType.EvaluationManagementSummaryChanged
  };
}


export interface NewPatientVisitSummaryChanged extends AnyAction {
  summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>;
}

export function isNewPatientVisitSummaryChanged(action: AppAction): action is NewPatientVisitSummaryChanged {
  return action.type === ActionType.NewPatientVisitSummaryChanged;
}

export function newPatientVisitSummaryChangedTo(summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>):
  NewPatientVisitSummaryChanged {
  return {
    summaryNewPatientVisitData: summaryNewPatientVisitData,
    type: ActionType.NewPatientVisitSummaryChanged
  };
}

export interface SummaryCollectionsDataChanged extends AnyAction {
  summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>;
}

export function isSummaryCollectionsChanged(action: AppAction): action is SummaryCollectionsDataChanged {
  return action.type === ActionType.SummaryCollectionsDataChanged;
}

export function summaryCollectionsChangedTo(summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>):
  SummaryCollectionsDataChanged {
  return {
    summaryCollectionsData: summaryCollectionsData,
    type: ActionType.SummaryCollectionsDataChanged
  };
}

export interface MergedNewPatientVisitEntriesChanged extends AnyAction {
  mergedNewPatientVisitSnapshotData: MergedNewPatientVisitSnapshotEntry[];
}

export function isMergedNewPatientVisitEntriesChanged(action: AppAction): action is MergedNewPatientVisitEntriesChanged {
  return action.type === ActionType.MergedNewPatientVisitEntriesChanged;
}

export function mergedNewPatientVisitEntriesChangedTo(mergedNewPatientVisitSnapshotData: MergedNewPatientVisitSnapshotEntry[]):
  MergedNewPatientVisitEntriesChanged {
  return {
    mergedNewPatientVisitSnapshotData: mergedNewPatientVisitSnapshotData,
    type: ActionType.MergedNewPatientVisitEntriesChanged
  };
}

export interface NewPatientVisitTrendEntriesChanged extends AnyAction {
  newPatientVisitTrendData: NewPatientVisitTrendEntry[];
}

export function isNewPatientVisitTrendEntriesChanged(action: AppAction): action is NewPatientVisitTrendEntriesChanged {
  return action.type === ActionType.NewPatientVisitTrendEntriesChanged;
}

export function newPatientVisitTrendEntriesChangedTo(newPatientVisitTrendData: NewPatientVisitTrendEntry[]):
  NewPatientVisitTrendEntriesChanged {
  return {
    newPatientVisitTrendData: newPatientVisitTrendData,
    type: ActionType.NewPatientVisitTrendEntriesChanged
  };
}

export interface ChargeDisplayChanged extends AnyAction {
  displayChargeGraph: boolean;
}

export function isChargeDisplayChanged(action: AppAction): action is ChargeDisplayChanged {
  return action.type === ActionType.ChargeDisplayChanged;
}

export function chargeDisplayChangedTo(displayChargeGraph: boolean): ChargeDisplayChanged {
  return {
    displayChargeGraph: displayChargeGraph,
    type: ActionType.ChargeDisplayChanged
  };
}

export interface ProcedureSummaryChargeDisplayChanged extends AnyAction {
  procedureSummaryChargeDisplayed: boolean;
}

export function isProcedureSummaryChargeDisplayChanged(action: AppAction): action is ProcedureSummaryChargeDisplayChanged {
  return action.type === ActionType.ProcedureSummaryChargeDisplayChanged;
}

export function procedureSummaryChargeDisplayChangedTo(procedureSummaryChargeDisplayed: boolean): ProcedureSummaryChargeDisplayChanged {
  return {
    procedureSummaryChargeDisplayed: procedureSummaryChargeDisplayed,
    type: ActionType.ProcedureSummaryChargeDisplayChanged
  };
}

export interface CfteAdjustedCountDisplayChanged extends AnyAction {
  cFTEAdjustedCount: boolean;
}

export function isCfteAdjustedCountChanged(action: AppAction): action is CfteAdjustedCountDisplayChanged {
  return action.type === ActionType.CfteAdjustedCountDisplayChanged;
}

export function cFTEAdjustedCountDisplayChangedTo(cFTEAdjustedCountDisplayChanged: boolean): CfteAdjustedCountDisplayChanged {
  return {
    cFTEAdjustedCount: cFTEAdjustedCountDisplayChanged,
    type: ActionType.CfteAdjustedCountDisplayChanged
  };
}

export interface CfpChargesDisplayChanged extends AnyAction {
  cFPCharges: boolean;
}

export function isCfpChargesChanged(action: AppAction): action is CfpChargesDisplayChanged {
  return action.type === ActionType.CfpChargesDisplayChanged;
}

export function cFPChargesDisplayChangedTo(cFPChargesDisplayChanged: boolean): CfpChargesDisplayChanged {
  return {
    cFPCharges: cFPChargesDisplayChanged,
    type: ActionType.CfpChargesDisplayChanged
  };
}

export interface PreviousTimePeriodDisplayChanged extends AnyAction {
  displayPreviousTimePeriodGraph: boolean;
}

export function isPreviousTimePeriodDisplayChanged(action: AppAction): action is PreviousTimePeriodDisplayChanged {
  return action.type === ActionType.PreviousTimePeriodDisplayChanged;
}

export function previousTimePeriodDisplayChanged(displayPreviousTimePeriodGraph: boolean): PreviousTimePeriodDisplayChanged {
  return {
    displayPreviousTimePeriodGraph: displayPreviousTimePeriodGraph,
    type: ActionType.PreviousTimePeriodDisplayChanged
  };
}

export interface MultipleProvidersChanged extends AnyAction {
  multipleProviders: boolean;
}

export function isMultipleProvidersChanged(action: AppAction): action is MultipleProvidersChanged {
  return action.type === ActionType.MultipleProvidersChanged;
}

export function multipleProvidersChangedTo(multipleProviders: boolean): MultipleProvidersChanged {
  return {
    multipleProviders: multipleProviders,
    type: ActionType.MultipleProvidersChanged
  };
}

export interface MultipleSpecialtiesChanged extends AnyAction {
  multipleSpecialties: boolean;
}

export function isMultipleSpecialtiesChanged(action: AppAction): action is MultipleSpecialtiesChanged {
  return action.type === ActionType.MultipleSpecialtiesChanged;
}

export function multipleSpecialtiesChangedTo(multipleSpecialties: boolean): MultipleSpecialtiesChanged {
  return {
    multipleSpecialties: multipleSpecialties,
    type: ActionType.MultipleSpecialtiesChanged
  };
}


export interface CommunityBenchmarkAlertChanged extends AnyAction {
  communityBenchmarkAlert: boolean;
}

export function isCommunityBenchmarkAlertChanged(action: AppAction): action is CommunityBenchmarkAlertChanged {
  return action.type === ActionType.CommunityBenchmarkAlertChanged;
}

export function communityBenchmarkAlertChangedTo(communityBenchmarkAlert: boolean): CommunityBenchmarkAlertChanged {
  return {
    communityBenchmarkAlert: communityBenchmarkAlert,
    type: ActionType.CommunityBenchmarkAlertChanged
  };
}

export interface MultipleDepartmentsChanged extends AnyAction {
  multipleDepartments: boolean;
}

export function isMultipleDepartmentsChanged(action: AppAction): action is MultipleDepartmentsChanged {
  return action.type === ActionType.MultipleDepartmentsChanged;
}

export function multipleDepartmentsChangedTo(multipleDepartments: boolean): MultipleDepartmentsChanged {
  return {
    multipleDepartments: multipleDepartments,
    type: ActionType.MultipleDepartmentsChanged
  };
}

export interface ShowHierarchySearchChanged extends AnyAction {
  showHierarchySearch: boolean;
}

export function isShowHierarchySearchChanged(action: AppAction): action is ShowHierarchySearchChanged {
  return action.type === ActionType.ShowHierarchySearchChanged;
}

export function showHierarchySearchChangedTo(showHierarchySearch: boolean): ShowHierarchySearchChanged {
  return {
    showHierarchySearch: showHierarchySearch,
    type: ActionType.ShowHierarchySearchChanged
  };
}

export interface ZeroSuppressionChanged extends AnyAction {
  zeroSuppression: boolean;
}

export function isZeroSuppressionChanged(action: AppAction): action is ZeroSuppressionChanged {
  return action.type === ActionType.ZeroSuppressionChanged;
}

export function zeroSuppressionChangedTo(zeroSuppression: boolean): ZeroSuppressionChanged {
  return {
    zeroSuppression: zeroSuppression,
    type: ActionType.ZeroSuppressionChanged
  };
}

export interface FilterBannerEditChanged extends AnyAction {
  filterBannerEdit: boolean;
}

export function isFilterBannerEditChanged(action: AppAction): action is FilterBannerEditChanged {
  return action.type === ActionType.FilterBannerEditChanged;
}

export function filterBannerEditChangedTo(filterBannerEdit: boolean): FilterBannerEditChanged {
  return {
    filterBannerEdit: filterBannerEdit,
    type: ActionType.FilterBannerEditChanged
  };
}

export interface FilterChosenByUserChanged extends AnyAction {
  filterChosenByUser?: boolean;
}

export function isFilterChosenByUserChanged(action: AppAction): action is FilterChosenByUserChanged {
  return action.type === ActionType.FilterChosenByUserChanged;
}

export function filterChosenByUserChangedTo(filterChosenByUser?: boolean): FilterChosenByUserChanged {
  return {
    filterChosenByUser: filterChosenByUser,
    type: ActionType.FilterChosenByUserChanged
  };
}

export interface NewProviderExportsChanged extends AnyAction {
  newProviderExports: number;
}

export function isNewProviderExportsChanged(action: AppAction): action is NewProviderExportsChanged {
  return action.type === ActionType.NewProviderExportsChanged;
}

export function newProviderExportsChangedTo(newProviderExports: number): NewProviderExportsChanged {
  return {
    newProviderExports: newProviderExports,
    type: ActionType.NewProviderExportsChanged
  };
}

export interface BatchExportReportsChanged extends AnyAction {
  batchExportReports: BatchExportHeader[];
}

export function isBatchExportReportsChanged(action: AppAction): action is BatchExportReportsChanged {
  return action.type === ActionType.BatchExportReportsChanged;
}

export function batchExportReportsChangedTo(batchExportReports: BatchExportHeader[]): BatchExportReportsChanged {
  return {
    batchExportReports,
    type: ActionType.BatchExportReportsChanged
  };
}

export interface NpvVarianceToggleChanged extends AnyAction {
  npvVarianceToggle: boolean;
}

export function isNpvVarianceToggleChanged(action: AppAction): action is NpvVarianceToggleChanged {
  return action.type === ActionType.NpvVarianceToggleChanged;
}

export function npvVarianceToggleChangedTo(npvVarianceToggle: boolean): NpvVarianceToggleChanged {
  return {
    npvVarianceToggle: npvVarianceToggle,
    type: ActionType.NpvVarianceToggleChanged
  };
}

export interface WrvuVarianceToggleChanged extends AnyAction {
  wrvuVarianceToggle: boolean;
}

export function isWrvuVarianceToggleChanged(action: AppAction): action is WrvuVarianceToggleChanged {
  return action.type === ActionType.WrvuVarianceToggleChanged;
}

export function wrvuVarianceToggleChangedTo(wrvuVarianceToggle: boolean): WrvuVarianceToggleChanged {
  return {
    wrvuVarianceToggle: wrvuVarianceToggle,
    type: ActionType.WrvuVarianceToggleChanged
  };
}

export interface CollectionsSnapshotVarianceToggleChanged extends AnyAction {
  collectionsSnapshotVarianceToggle: boolean;
}

export function isCollectionsSnapshotVarianceToggleChanged(action: AppAction):
  action is CollectionsSnapshotVarianceToggleChanged {
  return action.type === ActionType.CollectionsSnapshotVarianceToggleChanged;
}

export function collectionsSnapshotVarianceToggleChangedTo(collectionsSnapshotVarianceToggle: boolean)
  : CollectionsSnapshotVarianceToggleChanged {
  return {
    collectionsSnapshotVarianceToggle: collectionsSnapshotVarianceToggle,
    type: ActionType.CollectionsSnapshotVarianceToggleChanged
  };
}

export interface CfpVarianceToggleChanged extends AnyAction {
  cfpVarianceToggle: boolean;
}

export function isCfpVarianceToggleChanged(action: AppAction): action is CfpVarianceToggleChanged {
  return action.type === ActionType.CfpVarianceToggleChanged;
}

export function cfpVarianceToggleChangedTo(cfpVarianceToggle: boolean): CfpVarianceToggleChanged {
  return {
    cfpVarianceToggle: cfpVarianceToggle,
    type: ActionType.CfpVarianceToggleChanged
  };
}

export interface WrvuSnapshotSortingCriteriaChanged extends AnyAction {
  wrvuSnapshotSortingCriteria: SortingCriterion | undefined;
}

export function isWrvuSnapshotSortingCriteriaChanged(action: AppAction): action is WrvuSnapshotSortingCriteriaChanged {
  return action.type === ActionType.WrvuSnapshotSortingCriteriaChanged;
}

export function wrvuSnapshotSortingCriteriaChangedTo(wrvuSnapshotSortingCriteria: SortingCriterion | undefined)
  : WrvuSnapshotSortingCriteriaChanged {
  return {
    wrvuSnapshotSortingCriteria: wrvuSnapshotSortingCriteria,
    type: ActionType.WrvuSnapshotSortingCriteriaChanged
  };
}

export interface NpvSnapshotSortingCriteriaChanged extends AnyAction {
  npvSnapshotSortingCriteria: SortingCriterion | undefined;
}

export function isNpvSnapshotSortingCriteriaChanged(action: AppAction): action is NpvSnapshotSortingCriteriaChanged {
  return action.type === ActionType.NpvSnapshotSortingCriteriaChanged;
}

export function npvSnapshotSortingCriteriaChangedTo(npvSnapshotSortingCriteria: SortingCriterion | undefined)
  : NpvSnapshotSortingCriteriaChanged {
  return {
    npvSnapshotSortingCriteria: npvSnapshotSortingCriteria,
    type: ActionType.NpvSnapshotSortingCriteriaChanged
  };
}

export interface CfpSortingCriteriaChanged extends AnyAction {
  cfpSortingCriteria: SortingCriterion | undefined;
}

export function isCfpSortingCriteriaChanged(action: AppAction): action is CfpSortingCriteriaChanged {
  return action.type === ActionType.CfpSortingCriteriaChanged;
}

export function cfpSortingCriteriaChangedTo(cfpSortingCriteria: SortingCriterion | undefined)
  : CfpSortingCriteriaChanged {
  return {
    cfpSortingCriteria: cfpSortingCriteria,
    type: ActionType.CfpSortingCriteriaChanged
  };
}

export interface CollectionsSnapshotSortingCriteriaChanged extends AnyAction {
  collectionsSnapshotSortingCriteria: SortingCriterion | undefined;
}

export function isCollectionsSnapshotSortingCriteriaChanged(action: AppAction): action is CollectionsSnapshotSortingCriteriaChanged {
  return action.type === ActionType.CollectionsSnapshotSortingCriteriaChanged;
}

export function collectionsSnapshotSortingCriteriaChangedTo(collectionsSnapshotSortingCriteria: SortingCriterion | undefined)
  : CollectionsSnapshotSortingCriteriaChanged {
  return {
    collectionsSnapshotSortingCriteria: collectionsSnapshotSortingCriteria,
    type: ActionType.CollectionsSnapshotSortingCriteriaChanged
  };
}

export interface CollectionsChargeDisplayedChanged extends AnyAction {
  collectionsChargeDisplayed: boolean;
}

export function isCollectionsChargeDisplayedChanged(action: AppAction): action is CollectionsChargeDisplayedChanged {
  return action.type === ActionType.CollectionsChargeDisplayedChanged;
}

export function collectionsChargeDisplayedChangedTo(collectionsChargeDisplayed: boolean): CollectionsChargeDisplayedChanged {
  return {
    collectionsChargeDisplayed: collectionsChargeDisplayed,
    type: ActionType.CollectionsChargeDisplayedChanged
  };
}

export interface DenialsColumnsChanged extends AnyAction {
  displayedDenialsColumns: BaseColumn[];
}

export function isDenialsColumnsChanged(action: AppAction): action is DenialsColumnsChanged {
  return action.type === ActionType.DenialsColumnsChanged;
}

export function denialsColumnsChangedTo(displayedDenialsColumns: BaseColumn[]) {
  return {
    displayedDenialsColumns: displayedDenialsColumns,
    type: ActionType.DenialsColumnsChanged
  };
}

export interface DenialsChosenTabChanged extends AnyAction {
  denialsChosenTab: MultilevelTabCollections;
}

export function isDenialsChosenTabChanged(action: AppAction): action is DenialsChosenTabChanged {
  return action.type === ActionType.DenialsChosenTabChanged;
}

export function denialsChosenTabChangedTo(denialsChosenTab: MultilevelTabCollections) {
  return {
    denialsChosenTab: denialsChosenTab,
    type: ActionType.DenialsChosenTabChanged
  };
}

export interface DenialsChargesChanged extends AnyAction {
  denialsChargesDisplayed: boolean;
}

export function isDenialsChargeDisplayedChanged(action: AppAction): action is DenialsChargesChanged {
  return action.type === ActionType.DenialsChargesChanged;
}

export function denialsChargesDisplayedChangedTo(denialsChargesDisplayed: boolean): DenialsChargesChanged {
  return {
    denialsChargesDisplayed: denialsChargesDisplayed,
    type: ActionType.DenialsChargesChanged
  };
}

export interface ChargesTerminallyDeniedChanged extends AnyAction {
  chargesTerminallyDeniedDisplayed: boolean;
}

export function isChargesTerminallyDeniedChanged(action: AppAction): action is ChargesTerminallyDeniedChanged {
  return action.type === ActionType.ChargesTerminallyDeniedChanged;
}

export function chargesTerminallyDeniedChangedTo(chargesTerminallyDeniedDisplayed: boolean):
  ChargesTerminallyDeniedChanged {
  return {
    chargesTerminallyDeniedDisplayed: chargesTerminallyDeniedDisplayed,
    type: ActionType.ChargesTerminallyDeniedChanged
  };
}

export interface DeniedCPTsPaidChanged extends AnyAction {
  deniedCPTsPaidDisplayed: boolean;
}

export function isDeniedCPTsPaidChanged(action: AppAction): action is DeniedCPTsPaidChanged {
  return action.type === ActionType.DeniedCPTsPaidChanged;
}

export function deniedCPTsPaidChangedTo(deniedCPTsPaidDisplayed: boolean): DeniedCPTsPaidChanged {
  return {
    deniedCPTsPaidDisplayed: deniedCPTsPaidDisplayed,
    type: ActionType.DeniedCPTsPaidChanged
  };
}

export interface TerminalDenialRateChanged extends AnyAction {
  terminalDenialRateDisplayed: boolean;
}

export function isTerminalDenialRateChanged(action: AppAction): action is TerminalDenialRateChanged {
  return action.type === ActionType.TerminalDenialRateChanged;
}

export function terminalDenialRateChangedTo(terminalDenialRateDisplayed: boolean): TerminalDenialRateChanged {
  return {
    terminalDenialRateDisplayed: terminalDenialRateDisplayed,
    type: ActionType.TerminalDenialRateChanged
  };
}

export interface ExpectedPaymentsDisplayedChanged extends AnyAction {
  expectedPaymentsDisplayed: boolean;
}

export function isExpectedPaymentsDisplayedChanged(action: AppAction): action is ExpectedPaymentsDisplayedChanged {
  return action.type === ActionType.ExpectedPaymentsDisplayedChanged;
}

export function expectedPaymentsDisplayedChangedTo(expectedPaymentsDisplayed: boolean): ExpectedPaymentsDisplayedChanged {
  return {
    expectedPaymentsDisplayed: expectedPaymentsDisplayed,
    type: ActionType.ExpectedPaymentsDisplayedChanged
  };

}

export interface WrvuViewTypeChanged extends AnyAction {
  wrvuViewType: WrvuViewType;
}

export function isWrvuViewTypeChanged(action: AppAction): action is WrvuViewTypeChanged {
  return action.type === ActionType.WrvuViewTypeChanged;
}

export function wrvuViewTypeChangedChangedTo(wrvuViewType: WrvuViewType): WrvuViewTypeChanged {
  return {
    wrvuViewType: wrvuViewType,
    type: ActionType.WrvuViewTypeChanged
  };
}

export interface CfpVariableViewTypeChanged extends AnyAction {
  cfpVariableViewType: CfpVariableViewType;
}

export function isCfpVariableViewTypeChanged(action: AppAction): action is CfpVariableViewTypeChanged {
  return action.type === ActionType.CfpVariableViewTypeChanged;
}

export function cfpVariableViewTypeChangedTo(cfpVariableViewType: CfpVariableViewType): CfpVariableViewTypeChanged {
  return {
    cfpVariableViewType: cfpVariableViewType,
    type: ActionType.CfpVariableViewTypeChanged
  };
}


export interface PatientVisitsCountsDisplayChanged extends AnyAction {
  displayPatientVisitsCountsGraph: boolean;
}

export function isPatientVisitsCountsDisplayChanged(action: AppAction): action is PatientVisitsCountsDisplayChanged {
  return action.type === ActionType.PatientVisitsCountsDisplayChanged;
}

export function patientVisitsCountsDisplayChangedTo(displayPatientVisitsCountsGraph: boolean): PatientVisitsCountsDisplayChanged {
  return {
    displayPatientVisitsCountsGraph: displayPatientVisitsCountsGraph,
    type: ActionType.PatientVisitsCountsDisplayChanged
  };
}

export interface PreviousPatientVisitsCountsDisplayChanged extends AnyAction {
  displayPreviousPatientVisitsCountsGraph: boolean;
}

export function isPreviousPatientVisitsCountsDisplayChanged(action: AppAction):
  action is PreviousPatientVisitsCountsDisplayChanged {
  return action.type === ActionType.PreviousPatientVisitsCountsDisplayChanged;
}

export function previousPatientVisitsCountsDisplayChangedTo(displayPreviousPatientVisitsCountsGraph: boolean):
  PreviousPatientVisitsCountsDisplayChanged {
  return {
    displayPreviousPatientVisitsCountsGraph: displayPreviousPatientVisitsCountsGraph,
    type: ActionType.PreviousPatientVisitsCountsDisplayChanged
  };
}


export interface IsCustomDisplayChanged extends AnyAction {
  isCustom: boolean;
}

export function isCustomFlagChanged(action: AppAction): action is IsCustomDisplayChanged {
  return action.type === ActionType.IsCustomDisplayChanged;
}

export function isCustomChangedTo(isCustom: boolean): IsCustomDisplayChanged {
  return {
    isCustom: isCustom,
    type: ActionType.IsCustomDisplayChanged
  };
}

export interface IsProviderSelectedDisplayChanged extends AnyAction {
  isProviderSelected: boolean;
}

export function isProviderSelectedFlagChanged(action: AppAction): action is IsProviderSelectedDisplayChanged {
  return action.type === ActionType.IsProviderSelectedDisplayChanged;
}

export function isProviderSelectedChangedTo(isProviderSelected: boolean): IsProviderSelectedDisplayChanged {
  return {
    isProviderSelected: isProviderSelected,
    type: ActionType.IsProviderSelectedDisplayChanged
  };
}

export interface MemberLocationChanged extends AnyAction {
  memberLocation: MemberLocation;
}

export function isMemberLocationChanged(action: AppAction): action is MemberLocationChanged {
  return action.type === ActionType.MemberLocationChanged;
}

export function memberLocationChangedTo(memberLocation: MemberLocation) {
  return {
    memberLocation,
    type: ActionType.MemberLocationChanged
  };
}

export interface LagKeyChanged extends AnyAction {
  lagKey: number;
}

export function isLagKeyChanged(action: AppAction): action is LagKeyChanged {
  return action.type === ActionType.LagKeyChanged;
}

export function lagKeyChangedTo(lagKey: number) {
  return {
    lagKey,
    type: ActionType.LagKeyChanged
  };
}

export interface PayerCategoryChanged extends AnyAction {
  payerCategory: PayerCategory;
}

export function isPayerCategoryChanged(action: AppAction): action is PayerCategoryChanged {
  return action.type === ActionType.PayerCategoryChanged;
}

export function payerCategoryChangedTo(payerCategory: PayerCategory) {
  return {
    payerCategory,
    type: ActionType.PayerCategoryChanged
  };
}


export interface ZipCodeNewPatientVisitsChanged extends AnyAction {
  zipCodeNewPatientVisits: ZipCodeNewPatientVisits[];
}

export function isZipCodeNewPatientVisitsChanged(action: AppAction): action is ZipCodeNewPatientVisitsChanged {
  return action.type === ActionType.ZipCodeNewPatientVisitsChanged;
}

export function zipCodeNewPatientVisitsChangedTo(zipCodeNewPatientVisits: ZipCodeNewPatientVisits[]): ZipCodeNewPatientVisitsChanged {
  return {
    zipCodeNewPatientVisits: zipCodeNewPatientVisits,
    type: ActionType.ZipCodeNewPatientVisitsChanged
  };
}

export interface ApplicationConfigurationSettingsLoaded extends AnyAction {
  applicationConfigurationSettings: ApplicationConfigurationSetting[];
}

export function isApplicationConfigurationSettingsLoaded(action: AppAction): action is ApplicationConfigurationSettingsLoaded {
  return action.type === ActionType.ApplicationConfigurationSettingsLoaded;
}

export function applicationConfigurationSettingsLoadedTo(applicationConfigurationSettings: ApplicationConfigurationSetting[]) {
  return {
    applicationConfigurationSettings,
    type: ActionType.ApplicationConfigurationSettingsLoaded
  };
}

export interface UserPreferencesLoaded extends AnyAction {
  userPreferences: UserPreference;
}

export function isUserPreferencesLoaded(action: AppAction): action is UserPreferencesLoaded {
  return action.type === ActionType.UserPreferencesLoaded;
}

export function userPreferencesLoadedTo(userPreferences?: UserPreference) {
  return {
    userPreferences,
    type: ActionType.UserPreferencesLoaded
  };
}


export interface NewFeaturesLoaded extends AnyAction {
  newFeatures: NewFeatures;
}

export function isNewFeaturesLoaded(action: AppAction): action is NewFeaturesLoaded {
  return action.type === ActionType.NewFeaturesLoaded;
}

export function newFeaturesLoadedTo(newFeatures?: NewFeatures) {
  return {
    newFeatures,
    type: ActionType.NewFeaturesLoaded
  };
}

export interface RecentMonthLoaded extends AnyAction {
  recentMonth: number;
}

export function isRecentMonthLoaded(action: AppAction): action is RecentMonthLoaded {
  return action.type === ActionType.RecentMonthLoaded;
}

export function recentMonthLoadedTo(recentMonth: number) {
  return {
    recentMonth,
    type: ActionType.RecentMonthLoaded
  };
}

export interface SelectedNodesChanged extends AnyAction {
  selectedProviders: number;
  selectedSpecialties: number;
}

export function isSelectedNodesChanged(action: AppAction): action is SelectedNodesChanged {
  return action.type === ActionType.SelectedNodesChanged;
}

export function selectedNodesChangedTo(selectedProviders: number, selectedSpecialties: number) {
  return {
    selectedProviders,
    selectedSpecialties,
    type: ActionType.SelectedNodesChanged
  };
}

export interface CptViewTypeChanged extends AnyAction {
  cptViewType: CptViewType;
}

export function isCptViewTypeChanged(action: AppAction): action is CptViewTypeChanged {
  return action.type === ActionType.CptViewTypeChanged;
}

export function cptViewTypeChangedTo(cptViewType: CptViewType) {
  return {
    cptViewType: cptViewType,
    type: ActionType.CptViewTypeChanged
  };
}

export interface ViewCommunityBenchmarksChanged extends AnyAction {
  viewCommunityBenchmarks: boolean;
}

export function isViewCommunityBenchmarksChanged(action: AppAction): action is ViewCommunityBenchmarksChanged {
  return action.type === ActionType.ViewCommunityBenchmarksChanged;
}

export function viewCommunityBenchmarksChangedTo(viewCommunityBenchmarks: boolean) {
  return {
    viewCommunityBenchmarks: viewCommunityBenchmarks,
    type: ActionType.ViewCommunityBenchmarksChanged
  };
}

export interface MemberDataFormatLoaded extends AnyAction {
  isRevenueMember: boolean;
}

export function isMemberDataFormatLoaded(action: AppAction): action is MemberDataFormatLoaded {
  return action.type === ActionType.MemberDataFormatLoaded;
}

export function memberDataFormatLoadedTo(isRevenueMember: boolean) {
  return {
    isRevenueMember,
    type: ActionType.MemberDataFormatLoaded
  };
}

export interface EmDimensionChanged extends AnyAction {
  emDimension: EmDimension;
}

export function isEmDimensionChanged(action: AppAction): action is EmDimensionChanged {
  return action.type === ActionType.EmDimensionChanged;
}

export function emDimensionChangedTo(emDimension: EmDimension) {
  return {
    emDimension,
    type: ActionType.EmDimensionChanged
  };
}

export interface NpvLocationViewByNodeChanged extends AnyAction {
  npvLocationViewByNode: boolean;
}

export function isNpvLocationViewByNodeChanged(action: AppAction): action is NpvLocationViewByNodeChanged {
  return action.type === ActionType.NpvLocationViewByNodeChanged;
}

export function npvLocationViewByNodeChangedTo(npvLocationViewByNode: boolean) {
  return {
    npvLocationViewByNode,
    type: ActionType.NpvLocationViewByNodeChanged
  };
}

export interface EmNpvBenchmarkOptionChanged extends AnyAction {
  emNpvBenchmarkOption: BenchmarkOption;
}

export function isEmNpvBenchmarkOptionChanged(action: AppAction): action is EmNpvBenchmarkOptionChanged {
  return action.type === ActionType.EmNpvBenchmarkOptionChanged;
}

export function emNpvBenchmarkOptionChangedTo(emNpvBenchmarkOption: BenchmarkOption) {
  return {
    emNpvBenchmarkOption,
    type: ActionType.EmNpvBenchmarkOptionChanged
  };
}

export interface ProcedureSummaryDrillChanged extends AnyAction {
  procedureSummaryDrill: ProcedureSummaryDrillImpl;
}

export function isProcedureSummaryDrillChanged(action: AppAction): action is ProcedureSummaryDrillChanged {
  return action.type === ActionType.ProcedureSummaryDrillChanged;
}

export function procedureSummaryDrillChangedTo(procedureSummaryDrill?: ProcedureSummaryDrillImpl) {
  return {
    procedureSummaryDrill,
    type: ActionType.ProcedureSummaryDrillChanged
  };
}

export interface SelectedCptGroupChanged extends AnyAction {
  selectedCptGroup: CptGroup;
}

export function isSelectedCptGroupChanged(action: AppAction): action is SelectedCptGroupChanged {
  return action.type === ActionType.SelectedCptGroupChanged;
}

export function selectedCptGroupChangedTo(selectedCptGroup?: CptGroup) {
  return {
    selectedCptGroup,
    type: ActionType.SelectedCptGroupChanged
  };
}

export interface ViewPSGraphByNodeChanged extends AnyAction {
  viewByNode: boolean;
}

export function isViewPSGraphByNodeChanged(action: AppAction): action is ViewPSGraphByNodeChanged {
  return action.type === ActionType.ViewPSGraphByNodeChanged;
}

export function viewPSGraphByNodeChangedTo(viewByNode: boolean) {
  return {
    viewByNode,
    type: ActionType.ViewPSGraphByNodeChanged
  };
}

export interface FilterPSGraphByOntologyChanged extends AnyAction {
  currentSelectedCptNode: CfpViewByOption;
}

export function isFilterPSGraphByOntologyChanged(action: AppAction): action is FilterPSGraphByOntologyChanged {
  return action.type === ActionType.FilterPSGraphByOntologyChanged;
}

export function filterPSGraphByOntologyChangedTo(currentSelectedCptNode?: CfpViewByOption) {
  return {
    currentSelectedCptNode,
    type: ActionType.FilterPSGraphByOntologyChanged
  };
}

export interface FilterNpvLocationGraphByOntologyChanged extends AnyAction {
  currentSelectedNode: NpvLocationViewByOption;
}

export function isFilterNpvLocationGraphByOntologyChanged(action: AppAction): action is FilterNpvLocationGraphByOntologyChanged {
  return action.type === ActionType.FilterNpvLocationGraphByOntologyChanged;
}

export function filterNpvLocationGraphByOntologyChangedTo(currentSelectedNode?: NpvLocationViewByOption) {
  return {
    currentSelectedNode,
    type: ActionType.FilterNpvLocationGraphByOntologyChanged
  };
}

export interface NpvLocationSortingChanged extends AnyAction {
  columnWithMatSort: ColumnWithMatSort;
}

export function isNpvLocationSortingChanged(action: AppAction): action is NpvLocationSortingChanged {
  return action.type === ActionType.NpvLocationSortingChanged;
}

export function npvLocationSortingChangedTo(columnWithMatSort: ColumnWithMatSort) {
  return {
    columnWithMatSort,
    type: ActionType.NpvLocationSortingChanged
  };
}

export interface FilterNpvLocationGraphByLocationChanged extends AnyAction {
  currentSelectedNode: NpvLocationViewByOption;
}

export function isFilterNpvLocationGraphByLocationChanged(action: AppAction): action is FilterNpvLocationGraphByLocationChanged {
  return action.type === ActionType.FilterNpvLocationGraphByLocationChanged;
}

export function filterNpvLocationGraphByLocationChangedTo(currentSelectedNode?: NpvLocationViewByOption) {
  return {
    currentSelectedNode,
    type: ActionType.FilterNpvLocationGraphByLocationChanged
  };
}

export interface NpvLocationDepartmentColumnsChanged extends AnyAction {
  displayedDepartmentColumns: BaseColumn[];
}

export function isNpvLocationDepartmentColumnsChanged(action: AppAction): action is NpvLocationDepartmentColumnsChanged {
  return action.type === ActionType.NpvLocationDepartmentColumnsChanged;
}

export function npvLocationDepartmentColumnsChangedTo(displayedDepartmentColumns: BaseColumn[]) {
  return {
    displayedDepartmentColumns,
    type: ActionType.NpvLocationDepartmentColumnsChanged
  };
}

export interface NpvLocationSpecialtyColumnsChanged extends AnyAction {
  displayedSpecialtyColumns: BaseColumn[];
}

export function isNpvLocationSpecialtyColumnsChanged(action: AppAction): action is NpvLocationSpecialtyColumnsChanged {
  return action.type === ActionType.NpvLocationSpecialtyColumnsChanged;
}

export function npvLocationSpecialtyColumnsChangedTo(displayedSpecialtyColumns: BaseColumn[]) {
  return {
    displayedSpecialtyColumns,
    type: ActionType.NpvLocationSpecialtyColumnsChanged
  };
}

export interface NpvLocationProviderColumnsChanged extends AnyAction {
  displayedProviderColumns: BaseColumn[];
}

export function isNpvLocationProviderColumnsChanged(action: AppAction): action is NpvLocationProviderColumnsChanged {
  return action.type === ActionType.NpvLocationProviderColumnsChanged;
}

export function npvLocationProviderColumnsChangedTo(displayedProviderColumns: BaseColumn[]) {
  return {
    displayedProviderColumns,
    type: ActionType.NpvLocationProviderColumnsChanged
  };
}

export interface FilterPSGraphByChanged extends AnyAction {
  currentSelectedOntologyCptNode: CfpViewByOption;
}

export function isFilterPSGraphByChanged(action: AppAction): action is FilterPSGraphByChanged {
  return action.type === ActionType.FilterPSGraphByChanged;
}

export function filterPSGraphByChangedTo(currentSelectedOntologyCptNode?: CfpViewByOption) {
  return {
    currentSelectedOntologyCptNode,
    type: ActionType.FilterPSGraphByChanged
  };
}

export interface SnapshotWrvuColumnsChanged extends AnyAction {
  displayedSnapshotWrvuColumns: BaseColumn[];
}

export function isSnapshotWrvuColumnsChanged(action: AppAction): action is SnapshotWrvuColumnsChanged {
  return action.type === ActionType.SnapshotWrvuColumnsChanged;
}

export function snapshotWrvuColumnsChangedTo(displayedWrvuSnapshotColumns: BaseColumn[]) {
  return {
    displayedSnapshotWrvuColumns: displayedWrvuSnapshotColumns,
    type: ActionType.SnapshotWrvuColumnsChanged
  };
}

export interface ProviderWrvuColumnsChanged extends AnyAction {
  displayedProviderWrvuColumns: BaseColumn[];
}

export function isProviderWrvuColumnsChanged(action: AppAction): action is ProviderWrvuColumnsChanged {
  return action.type === ActionType.ProviderWrvuColumnsChanged;
}

export function providerWrvuColumnsChangedTo(displayedWrvuProviderColumns: BaseColumn[]) {
  return {
    displayedProviderWrvuColumns: displayedWrvuProviderColumns,
    type: ActionType.ProviderWrvuColumnsChanged
  };
}

export interface SpecialtyPerformanceWrvuColumnsChanged extends AnyAction {
  displayedSpecialtyPerformanceWrvuColumns: BaseColumn[];
}

export function isSpecialtyPerformanceWrvuColumnsChanged(action: AppAction): action is SpecialtyPerformanceWrvuColumnsChanged {
  return action.type === ActionType.SpecialtyPerformanceWrvuColumnsChanged;
}

export function specialtyPerformanceWrvuColumnsChangedTo(displayedWrvuSpecialtyPerformanceColumns: BaseColumn[]) {
  return {
    displayedSpecialtyPerformanceWrvuColumns: displayedWrvuSpecialtyPerformanceColumns,
    type: ActionType.SpecialtyPerformanceWrvuColumnsChanged
  };
}

export interface TrendWrvuColumnsChanged extends AnyAction {
  displayedTrendWrvuColumns: BaseColumn[];
}

export function isTrendWrvuColumnsChanged(action: AppAction): action is TrendWrvuColumnsChanged {
  return action.type === ActionType.TrendWrvuColumnsChanged;
}

export function trendWrvuColumnsChangedTo(displayedWrvuTrendColumns: BaseColumn[]) {
  return {
    displayedTrendWrvuColumns: displayedWrvuTrendColumns,
    type: ActionType.TrendWrvuColumnsChanged
  };
}


export interface UserSecurityLoaded extends AnyAction {
  userSecurity: UserSecurity[];
}

export function isUserSecurityLoaded(action: AppAction): action is UserSecurityLoaded {
  return action.type === ActionType.UserSecurityLoaded;
}

export function userSecurityLoadedTo(userSecurity: UserSecurity[]) {
  return {
    userSecurity,
    type: ActionType.UserSecurityLoaded
  };
}

export interface AllUserPreferenceLoaded extends AnyAction {
  userPreferences: UserPreference[];
}

export function isAllUserPreferenceLoaded(action: AppAction): action is AllUserPreferenceLoaded {
  return action.type === ActionType.AllUserPreferenceLoaded;
}

export function allUserPreferenceLoadedTo(userPreferences: UserPreference[]) {
  return {
    userPreferences,
    type: ActionType.AllUserPreferenceLoaded
  };
}

export interface UserMemberLoaded extends AnyAction {
  userMemberData: UserMemberData[];
}

export function isUserMemberLoaded(action: AppAction): action is UserMemberLoaded {
  return action.type === ActionType.UserMemberLoaded;
}

export function userMemberLoadedTo(userMemberData: UserMemberData[]) {
  return {
    userMemberData,
    type: ActionType.UserMemberLoaded
  };
}

export interface MemberBillingAreaLoaded extends AnyAction {
  memberBillingAreas: MemberBillingArea[];
}

export function isMemberBillingAreaLoaded(action: AppAction): action is MemberBillingAreaLoaded {
  return action.type === ActionType.MemberBillingAreaLoaded;
}

export function memberBillingAreaLoadedTo(memberBillingAreas: MemberBillingArea[]) {
  return {
    memberBillingAreas,
    type: ActionType.MemberBillingAreaLoaded
  };
}

export interface MemberBillingAreaSelected extends AnyAction {
  memberBillingArea: MemberBillingArea;
}

export function isMemberBillingAreaSelected(action: AppAction): action is MemberBillingAreaSelected {
  return action.type === ActionType.MemberBillingAreaSelected;
}

export function memberBillingAreaSelectedTo(memberBillingArea: MemberBillingArea) {
  return {
    memberBillingArea,
    type: ActionType.MemberBillingAreaSelected
  };
}

export interface InvoiceStatusChanged extends AnyAction {
  invoiceStatus: number;
}

export function isInvoiceStatusChanged(action: AppAction): action is InvoiceStatusChanged {
  return action.type === ActionType.InvoiceStatusChanged;
}

export function invoiceStatusChangedTo(invoiceStatus: number) {
  return {
    invoiceStatus,
    type: ActionType.InvoiceStatusChanged
  };
}

export interface TelehealthFlagChanged extends AnyAction {
  telehealthFlag: number;
}

export function isTelehealthFlagChanged(action: AppAction): action is TelehealthFlagChanged {
  return action.type === ActionType.TelehealthFlagChanged;
}

export function telehealthFlagChangedTo(telehealthFlag: number) {
  return {
    telehealthFlag,
    type: ActionType.TelehealthFlagChanged
  };
}

export interface LagKeyLessThanChanged extends AnyAction {
  isLessThanLagPeriod: boolean;
}

export function isLagKeyLessThanChanged(action: AppAction): action is LagKeyLessThanChanged {
  return action.type === ActionType.LagKeyLessThanChanged;
}

export function LagKeyLessThanChangedTo(isLessThanLagPeriod: boolean) {
  return {
    isLessThanLagPeriod,
    type: ActionType.LagKeyLessThanChanged
  };
}

export interface DrillDownChanged extends AnyAction {
  isDrillDown: boolean;
  nodePath: string;
  tab: MultilevelTabCollections | MultilevelTab;
}

export function isDrillDownChanged(action: AppAction): action is DrillDownChanged {
  return action.type === ActionType.DrillDownChanged;
}

export function drillDownChangedTo(isDrillDown: boolean, nodePath: string, tab: MultilevelTab | MultilevelTabCollections) {
  return {
    isDrillDown,
    nodePath,
    tab,
    type: ActionType.DrillDownChanged
  };
}


export interface DrillDownHistoryAppended extends AnyAction {
  nodePath: string;
  tab: MultilevelTabCollections | MultilevelTab;
  customGroupId: number;
}

export function isDrillDownHistoryAppended(action: AppAction): action is DrillDownHistoryAppended {
  return action.type === ActionType.DrillDownHistoryAppended;
}

export function drillDownHistoryAppendedWith(nodePath: string, tab: MultilevelTabCollections | MultilevelTab, customGroupId: number) {
  return {
    nodePath,
    tab,
    customGroupId,
    type: ActionType.DrillDownHistoryAppended
  };
}

export interface UndoDrillDownHistory extends AnyAction {
  resetHistory?: boolean;
}

export function isDrillDownHistoryUndone(action: AppAction): action is UndoDrillDownHistory {
  return action.type === ActionType.UndoDrillDownHistory;
}

export function undoDrillDownHistory(resetHistory?: boolean) {
  return {
    resetHistory: resetHistory,
    type: ActionType.UndoDrillDownHistory
  };
}

export interface FilterCriteriaChanged extends AnyAction {
  filterCriteria: FilterCriteria;
}

export function isFilterCriteriaChanged(action: AppAction): action is FilterCriteriaChanged {
  return action.type === ActionType.FilterCriteriaChanged;
}

export function filterCriteriaChangedTo(filterCriteria: FilterCriteria) {
  return {
    filterCriteria,
    type: ActionType.FilterCriteriaChanged
  };
}

export interface MemberLocationsChanged extends AnyAction {
  memberLocations: CurrentMemberLocation[];
}

export function isMemberLocationsChanged(action: AppAction): action is MemberLocationsChanged {
  return action.type === ActionType.MemberLocationsChanged;
}

export function memberLocationsChangedTo(memberLocations: CurrentMemberLocation[]) {
  return {
    memberLocations,
    type: ActionType.MemberLocationsChanged
  };
}

export interface ExportingStatusChanged extends AnyAction {
  isExporting: boolean;
}

export function isExportingStatusChanged(action: AppAction): action is ExportingStatusChanged {
  return action.type === ActionType.ExportingStatusChanged;
}

export function exportingStatusChangedTo(isExporting: boolean) {
  return {
    isExporting,
    type: ActionType.ExportingStatusChanged
  };
}

export interface BatchExportReportDetailsChanged extends AnyAction {
  batchExportReportDetails: BatchExportReportDetailsResponseEntity[];
}

export function isBatchExportReportDetailsChanged(action: AppAction): action is BatchExportReportDetailsChanged {
  return action.type === ActionType.BatchExportReportDetailsChanged;
}

export function batchExportReportDetailsChangedTo(batchExportReportDetails: BatchExportReportDetailsResponseEntity[]):
  BatchExportReportDetailsChanged {
  return {
    batchExportReportDetails,
    type: ActionType.BatchExportReportDetailsChanged
  };
}

export interface BatchExportScheduleReportDetailsChanged extends AnyAction {
  batchExportScheduleReportDetails: BatchExportScheduleExistingReportResponseEntity[][];
}

export function isBatchExportScheduleReportDetailsChanged(action: AppAction): action is BatchExportReportDetailsChanged {
  return action.type === ActionType.BatchExportScheduleReportDetailsChanged;
}

export function batchExportScheduleReportDetailsChangedTo(batchExportScheduleReportDetails:
                                                            BatchExportScheduleExistingReportResponseEntity[][]):
  BatchExportScheduleReportDetailsChanged {
  return {
    batchExportScheduleReportDetails,
    type: ActionType.BatchExportScheduleReportDetailsChanged
  };
}

export interface DenialsSortingCriteriaChanged extends AnyAction {
  denialsSortingCriteria: SortingCriterion | undefined;
}

export function isDenialsSortingCriteriaChangedTo(action: AppAction): action is DenialsSortingCriteriaChanged {
  return action.type === ActionType.DenialsSortingCriteriaChanged;
}

export function denialsSortingCriteriaChangedTo(denialsSortingCriteria: SortingCriterion | undefined)
  : DenialsSortingCriteriaChanged {
  return {
    denialsSortingCriteria: denialsSortingCriteria,
    type: ActionType.DenialsSortingCriteriaChanged
  };
}

export interface DenialsChanged extends AnyAction {
  denialsData: DenialPayerEntity[];
}

export function isDenialsChanged(action: AppAction): action is DenialsChanged {
  return action.type === ActionType.DenialsChanged;
}

export function denialsChangedTo(denialsData: DenialsPayerData) {
  return {
    denialsData: denialsData,
    type: ActionType.DenialsChanged
  };
}

export interface MultiLevelDenialsColumnsChanged extends AnyAction {
  multiLevelByNodePathDenialsColumns: BaseColumn[];
}

export function isMultiLevelByNodePathDenialsColumnsChanged(action: AppAction): action is MultiLevelDenialsColumnsChanged {
  return action.type === ActionType.MultiLevelDenialsColumnsChanged;
}

export function multiLevelByNodePathDenialsColumnsChangedTo(multiLevelByNodePathDenialsColumns: BaseColumn[]) {
  return {
    multiLevelByNodePathDenialsColumns: multiLevelByNodePathDenialsColumns,
    type: ActionType.MultiLevelDenialsColumnsChanged
  };
}

export interface MultiLevelDenialsChanged extends AnyAction {
  multilevelDenialsData: DenialsMultiLevelData;
}

export function isMultiLevelDenialsChanged(action: AppAction): action is MultiLevelDenialsChanged {
  return action.type === ActionType.MultiLevelDenialsChanged;
}

export function multiLevelDenialsChangedTo(multiLevelDenialsData: DenialsMultiLevelData) {
  return {
    multiLevelDenialsData: multiLevelDenialsData,
    type: ActionType.MultiLevelDenialsChanged
  };
}

export interface DenialsVarianceToggleChanged extends AnyAction {
  denialsVarianceToggle: boolean;
}

export function isDenialsVarianceToggleChanged(action: AppAction): action is DenialsVarianceToggleChanged {
  return action.type === ActionType.DenialsVarianceToggleChanged;
}

export function denialsVarianceToggleChangedTo(denialsVarianceToggle: boolean): DenialsVarianceToggleChanged {
  return {
    denialsVarianceToggle: denialsVarianceToggle,
    type: ActionType.DenialsVarianceToggleChanged
  };
}

export type AppAction =
  BenchmarkChanged
  | OntologyLoaded
  | CptGroupingsChanged
  | MemberSelected
  | MultilevelProviderProductivitiesChanged
  | MultilevelNewPatientVisitsChanged
  | MergedNpvByLocationDataChanged
  | ProductivitySummaryChanged
  | DateRangeChanged
  | MergedProductivityTrendDataChanged
  | OktaTokenChanged
  | ChargeDisplayChanged
  | ProcedureSummaryChargeDisplayChanged
  | PreviousTimePeriodDisplayChanged
  | MultipleProvidersChanged
  | MultipleSpecialtiesChanged
  | MultipleDepartmentsChanged
  | PatientVisitsCountsDisplayChanged
  | PreviousPatientVisitsCountsDisplayChanged
  | IsCustomDisplayChanged
  | IsProviderSelectedDisplayChanged
  | EvaluationManagementChanged
  | SpecialtySummaryEvaluationManagementChanged
  | EvaluationManagementSummaryChanged
  | NewPatientVisitSummaryChanged
  | MergedNewPatientVisitEntriesChanged
  | NewPatientVisitTrendEntriesChanged
  | NodePathChanged
  | CptFamilyFilterChanged
  | CptRangeFilterChanged
  | UserProfileLoaded
  | MembersLoaded
  | FeatureToggleSettingsLoaded
  | MemberLocationChanged
  | LagKeyChanged
  | PayerCategoryChanged
  | ZipCodeNewPatientVisitsChanged
  | WrvuViewTypeChanged
  | ApplicationConfigurationSettingsLoaded
  | UserPreferencesLoaded
  | RecentMonthLoaded
  | SelectedNodesChanged
  | ShowHierarchySearchChanged
  | ZeroSuppressionChanged
  | FilterBannerEditChanged
  | FilterChosenByUserChanged
  | NewProviderExportsChanged
  | BatchExportReportsChanged
  | NpvVarianceToggleChanged
  | WrvuVarianceToggleChanged
  | CollectionsSnapshotVarianceToggleChanged
  | WrvuSnapshotSortingCriteriaChanged
  | NpvSnapshotSortingCriteriaChanged
  | CfpSortingCriteriaChanged
  | CollectionsSnapshotSortingCriteriaChanged
  | ExpectedPaymentsDisplayedChanged
  | CollectionsChargeDisplayedChanged
  | ClinicalSummaryConsolidationChanged
  | SnapshotCollectionsDataChanged
  | CustomGroupsDataChanged
  | NewFeaturesLoaded
  | MemberDataFormatLoaded
  | ViewCommunityBenchmarksChanged
  | PayerCollectionsDataChanged
  | PayerByLevelCollectionsDataChanged
  | MultiLevelPayerCollectionsChanged
  | SummaryCollectionsDataChanged
  | EmDimensionChanged
  | NpvLocationViewByNodeChanged
  | EmNpvBenchmarkOptionChanged
  | PayerByLevelCollectionsStaticColumnsChanged
  | PayerByLevelCollectionsPayerColumnsChanged
  | MultiLevelPayerCollectionsColumnsChanged
  | MultiLevelCollectionsColumnsChanged
  | SnapshotCollectionsColumnsChanged
  | SnapshotWrvuColumnsChanged
  | ProviderWrvuColumnsChanged
  | TrendWrvuColumnsChanged
  | UserSecurityLoaded
  | NPVByDepartmentColumnsChanged
  | NPVBySpecialtyColumnsChanged
  | NPVByProviderColumnsChanged
  | TrendNPVColumnsChanged
  | AllUserPreferenceLoaded
  | UserMemberLoaded
  | MemberBillingAreaLoaded
  | MemberBillingAreaSelected
  | InvoiceStatusChanged
  | TelehealthFlagChanged
  | FilterCriteriaChanged
  | MemberLocationsChanged
  | ExportingStatusChanged
  | CommunityBenchmarkAlertChanged
  | CfpVarianceToggleChanged
  | LagKeyLessThanChanged
  | DrillDownChanged
  | DrillDownHistoryAppended
  | UndoDrillDownHistory
  | CfteAdjustedCountDisplayChanged
  | MultiLevelCollectionsChanged
  | EvaluationManagementMultilevelChanged
  | CollectionsChosenTabChanged
  | MultilevelTabChanged
  | DenialsColumnsChanged
  | DenialsVarianceToggleChanged
  | DenialsChosenTabChanged
  | ChargesTerminallyDeniedChanged
  | CustomGroupIdChanged
  | RequestIdLoaded
  | RequestDetailLoaded;
