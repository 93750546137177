<div id="export-now">Export Now</div>
<div class="input-section">
  <mat-form-field id="report-name" subscriptSizing="dynamic">
    <mat-progress-spinner matSuffix [mode]="'indeterminate'" diameter="20" *ngIf="reportName.pending"></mat-progress-spinner>
    <mat-label>Report Name</mat-label>
    <input id="report-name-input" matInput required [formControl]="reportName" (blur)="updateReportName()">
    <mat-error *ngIf="reportName.errors?.required">This is a required field</mat-error>
    <mat-error *ngIf="reportName.errors?.duplicateReportName">{{reportName.value}} already exists.
      <br>Please enter a new report name.</mat-error>
  </mat-form-field>
</div>
<div class="input-section">
  <div class="helper-text">Check the box below to automatically export this report every month when your new data is
    loaded.
  </div>
  <div>
    <mat-checkbox id="generate-monthly" (change)="updateIsGeneratedMonthly()" [checked]="schedulePaneInfo.isMonthly">
      Generate this report monthly
    </mat-checkbox>
  </div>
</div>
