import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {DateRange, SortingCriterion} from '../../shared/models';
import {BaseColumn, FilterCriteria, IAppState, INITIAL_STATE, SummaryData} from '../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {
  CollectionsSummaryForAllTypes,
  MatchedCollectionsByMultiLevelByNodePath,
  MatchedCollectionsByMultiLevelByNodePathData,
  MatchedCollectionsMultiLevelNodePathData
} from '../Collection';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {ColumnType, MultilevelTab, MultilevelTabCollections, SortingOrder} from '../../shared/enums';
import {
  columnsForMultiLevelCollectionsDepartment,
  columnsForMultiLevelCollectionsProvider,
  columnsForMultiLevelCollectionsSpecialty,
  columnsForMultiLevelSnapshotCollections,
  DataTableColumns
} from '../../shared/data-table-columns';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {
  AppAction,
  collectionsChosenTabChangedTo,
  collectionsSnapshotSortingCriteriaChangedTo,
  multiLevelByNodePathCollectionsColumnsChangedTo,
  snapshotCollectionsDataChangedTo
} from '../../store/actions';
import {getAppConfigValue, isSortingCriterionValid} from '../../shared/helpers';
import {createCollectionsVarianceColumnFor, createNCRBenchmarkColumnFor} from '../../shared/BenchmarkColumns';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';
import {FilterSelectorService, FilterSelectorServiceToken} from '../../services/filter-selector.service';
import {ApiServiceImpl, ApiServiceToken} from '../../services/api.service';
import {hasNonZeroValue} from '../../shared/null-helpers';
import {CollectionsProviderPageComponent} from '../page-provider/collections-provider.component';
import {CollectionsDepartmentPageComponent} from '../page-department/collections-department.component';
import {CollectionsSpecialtyPageComponent} from '../page-specialty/collections-specialty.component';

@Component({
  selector: 'app-collections-multilevel',
  templateUrl: './collections-multilevel.component.html',
  styleUrls: ['./collections-multilevel.component.scss']
})
export class CollectionsMultilevelComponent implements OnInit, AfterViewChecked, OnDestroy {

  @select(['data', 'matchedCollectionsByMultiLevelByNodePathData'])
  private readonly matchedCollectionsByMultiLevelByNodePathData$: Observable<MatchedCollectionsMultiLevelNodePathData>;
  @select(['display', 'isCustom'])
  private readonly isCustom$: Observable<boolean>;
  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;
  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;
  @select(['display', 'sortingCriteria', 'collectionsSnapshotSortingCriteria'])
  private readonly collectionsSnapshotSortingCriteria$: Observable<SortingCriterion | undefined>;
  @select(['display', 'varianceToggle', 'collectionSnapshotVariance'])
  private readonly collectionSnapshotVarianceToggle$: Observable<boolean>;
  @select(['display', 'multiLevelByNodePathCollectionsColumns'])
  private readonly multiLevelByNodePathCollectionsColumns$: Observable<BaseColumn[]>;
  @select(['data', 'summaryCollectionsData'])
  private readonly summaryCollectionsData$: Observable<SummaryData<CollectionsSummaryForAllTypes>>;
  @select(['filters', 'dateRange'])
  private readonly dateRange$: Observable<DateRange>;
  @select(['display', 'collectionsChosenTab'])
  private readonly collectionsChosenTab$: Observable<MultilevelTabCollections>;
  @select(['display', 'zeroSuppression'])
  private readonly zeroSuppression$: Observable<boolean>;

  departmentCollectionsSnapshotData: MatchedCollectionsByMultiLevelByNodePath[] = [];
  specialtyCollectionsSnapshotData: MatchedCollectionsByMultiLevelByNodePath[] = [];
  providerCollectionsSnapshotData: MatchedCollectionsByMultiLevelByNodePath [] = [];
  multiLevelCollectionsData: MatchedCollectionsMultiLevelNodePathData;
  collectionsDataMultiLevel: MatchedCollectionsByMultiLevelByNodePathData [] = [];
  summaryData: SummaryData<CollectionsSummaryForAllTypes>;
  activeVariance = false;
  isZeroSuppressed = true;
  countOfSuppressedProviderEntries = 0;
  countOfSuppressedDepartmentEntries = 0;
  countOfSuppressedSpecialtiesEntries = 0;
  collectionsSnapshotSortingCriteria: SortingCriterion | undefined;
  isCustom = false;
  benchmarkPercentile: BenchmarkPercentile;
  nodePath: string;
  chosenTab: MultilevelTabCollections | MultilevelTab;
  columns: any[] = columnsForMultiLevelSnapshotCollections;
  defaultSortColumn = 'netCollectionRate';
  varianceToggleSortingCriterion: SortingCriterion;
  displayedColumns = columnsForMultiLevelSnapshotCollections;
  columnHeaders: BaseColumn[] = [];
  dateRange: DateRange;
  loadingIndicator = true;
  providerColumns = _.cloneDeep(columnsForMultiLevelCollectionsProvider);
  displayedProviderColumns: DataTableColumns[] = [];
  specialtyColumns = _.cloneDeep(columnsForMultiLevelCollectionsSpecialty);
  displayedSpecialtyColumns: DataTableColumns[] = [];
  departmentColumns = _.cloneDeep(columnsForMultiLevelCollectionsDepartment);
  displayedDepartmentColumns: DataTableColumns[] = [];
  hasAcademicBenchmarks = false;
  dataSubscription: Subscription;
  columnSubscription: Subscription;
  tabSubscription: Subscription;
  isDepartment: boolean;
  reducerAction: (multiLevelByNodePathCollectionsColumns: BaseColumn[]) => AppAction =
    multiLevelByNodePathCollectionsColumnsChangedTo;
  private sortingDataAccessor: any;
  private varianceKey = '';
  private activeVarianceToggle: boolean;
  sortDirection = 'desc';
  level: string;
  moreThanMaxProvidersSelected = false;
  moreThanMaxSpecialtiesSelected = false;
  maxProviders = 500;
  maxSpecialties = 500;
  maxProvidersMessage = `Please select fewer than ${this.maxProviders} providers in the navigation drop down`;
  maxSpecialtiesMessage = `Please select fewer than ${this.maxSpecialties} specialties in the navigation drop down`;
  specialtyPermitted = true;
  providerPermitted = true;
  filterSubscription: Subscription;
  @ViewChild(CollectionsProviderPageComponent) providerPage: CollectionsProviderPageComponent;
  @ViewChild(CollectionsDepartmentPageComponent) departmentPage: CollectionsDepartmentPageComponent;
  @ViewChild(CollectionsSpecialtyPageComponent) specialtyPage: CollectionsSpecialtyPageComponent;

  public static isCollectionEntrySuppressible(entry: MatchedCollectionsByMultiLevelByNodePath): boolean {
    return !hasNonZeroValue(entry.chargeAmount) && !hasNonZeroValue(entry.primaryAndOtherPayerContractuals) &&
      !hasNonZeroValue(entry.charityCareAmount) && !hasNonZeroValue(entry.discountAmount) &&
      !hasNonZeroValue(entry.expectedPayments) && !hasNonZeroValue(entry.primaryAndOtherInsurancePayments) &&
      !hasNonZeroValue(entry.patientPaymentAmount) && !hasNonZeroValue(entry.totalPayments) &&
      !hasNonZeroValue(entry.controllableAllowance) && !hasNonZeroValue(entry.smallBalanceWriteOffs) &&
      !hasNonZeroValue(entry.patientRelatedBadDebt) && !hasNonZeroValue(entry.outstandingAR);
  }

  constructor(private ngRedux: NgRedux<IAppState>,
              private cdr: ChangeDetectorRef,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {
    this.filterSubscription = this.filterService.getFilters()
      .subscribe((filter: FilterCriteria) => {
        this.getCollectionData(filter);
      });
  }

  ngOnInit(): void {
    this.dataSubscription = combineLatest([this.matchedCollectionsByMultiLevelByNodePathData$
      .pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.summaryCollectionsData$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.benchmarkPercentile$,
      this.isCustom$,
      this.nodePath$,
      this.collectionsSnapshotSortingCriteria$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.collectionSnapshotVarianceToggle$, this.zeroSuppression$
    ])
      .subscribe(([matchedCollectionsByMultiLevelByNodePathData, summaryData, benchmarkPercentile, isCustom, nodePath,
                    collectionsSnapshotSortingCriteria, collectionVarianceToggle, zeroSuppression]:
                    [MatchedCollectionsMultiLevelNodePathData, SummaryData<CollectionsSummaryForAllTypes>, BenchmarkPercentile,
                      boolean, string, SortingCriterion | undefined, boolean, boolean]) => {
        this.isZeroSuppressed = zeroSuppression;
        this.isDepartment = nodePath.split('\\').length === 2;
        this.isCustom = isCustom;
        this.benchmarkPercentile = benchmarkPercentile;
        this.nodePath = nodePath;
        this.hasAcademicBenchmarks = this.determineIfHasAcademicBenchmarks();
        this.summaryData = summaryData;
        this.collectionsSnapshotSortingCriteria = collectionsSnapshotSortingCriteria;
        this.loadingIndicator = _.isEqual(summaryData, INITIAL_STATE.data.summaryCollectionsData);
        this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
          return (sortHeaderId === 'netCollectionRate') ? data[this.defaultSortColumn] : data[sortHeaderId];
        };

        this.providerColumns[21] = createNCRBenchmarkColumnFor(this.benchmarkPercentile);
        this.providerColumns[22] = createCollectionsVarianceColumnFor(this.benchmarkPercentile);
        this.specialtyColumns[20] = createNCRBenchmarkColumnFor(this.benchmarkPercentile);
        this.specialtyColumns[21] = createCollectionsVarianceColumnFor(this.benchmarkPercentile);
        this.departmentColumns[19] = createNCRBenchmarkColumnFor(this.benchmarkPercentile);
        this.departmentColumns[20] = createCollectionsVarianceColumnFor(this.benchmarkPercentile);

        for (let i = 0; i < this.displayedDepartmentColumns.length; i++) {
          if (this.displayedDepartmentColumns[i].columnType === ColumnType.BENCHMARK) {
            this.displayedDepartmentColumns[i] = createNCRBenchmarkColumnFor(this.benchmarkPercentile);
          }
          if (this.displayedDepartmentColumns[i].columnType === ColumnType.VARIANCE) {
            this.displayedDepartmentColumns[i] = createCollectionsVarianceColumnFor(this.benchmarkPercentile);
          }
        }

        for (let i = 0; i < this.displayedSpecialtyColumns.length; i++) {
          if (this.displayedSpecialtyColumns[i].columnType === ColumnType.BENCHMARK) {
            this.displayedSpecialtyColumns[i] = createNCRBenchmarkColumnFor(this.benchmarkPercentile);
          }
          if (this.displayedSpecialtyColumns[i].columnType === ColumnType.VARIANCE) {
            this.displayedSpecialtyColumns[i] = createCollectionsVarianceColumnFor(this.benchmarkPercentile);
          }
        }

        for (let i = 0; i < this.displayedProviderColumns.length; i++) {
          if (this.displayedProviderColumns[i].columnType === ColumnType.BENCHMARK) {
            this.displayedProviderColumns[i] = createNCRBenchmarkColumnFor(this.benchmarkPercentile);
          }
          if (this.displayedProviderColumns[i].columnType === ColumnType.VARIANCE) {
            this.displayedProviderColumns[i] = createCollectionsVarianceColumnFor(this.benchmarkPercentile);
          }
        }
        this.multiLevelCollectionsData = matchedCollectionsByMultiLevelByNodePathData;
        this.departmentCollectionsSnapshotData = matchedCollectionsByMultiLevelByNodePathData.departmentCollections;
        this.specialtyCollectionsSnapshotData = matchedCollectionsByMultiLevelByNodePathData.specialtyCollections;
        this.providerCollectionsSnapshotData = matchedCollectionsByMultiLevelByNodePathData.providerCollections;
        this.suppressProviderEntries();
        this.suppressSpecialtyEntries();
        this.suppressDepartmentEntries();
        this.setTabProperties();
        switch (this.chosenTab) {
          case MultilevelTabCollections.BY_PROVIDER:
            this.columns = _.cloneDeep(this.providerColumns);
            break;
          case MultilevelTabCollections.BY_SPECIALTY:
            this.columns = _.cloneDeep(this.specialtyColumns);
            break;
          case MultilevelTabCollections.BY_DEPARTMENT:
            this.columns = _.cloneDeep(this.departmentColumns);
            break;
        }

        this.columns[0].primaryColumn = true;

        this.adjustSortingVariables(collectionsSnapshotSortingCriteria, collectionVarianceToggle);

      });


    combineLatest([this.collectionSnapshotVarianceToggle$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.collectionsSnapshotSortingCriteria$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))])
      .subscribe(([activeVariance, collectionsSnapshotSortingCriteria]: [boolean, SortingCriterion | undefined]) => {
        this.activeVariance = activeVariance;
        this.collectionsSnapshotSortingCriteria = collectionsSnapshotSortingCriteria;
      });

    this.tabSubscription = this.collectionsChosenTab$.subscribe((tab: MultilevelTabCollections) => this.chosenTab = tab);

    this.displayedDepartmentColumns = this.departmentColumns.slice();
    this.displayedSpecialtyColumns = this.specialtyColumns.slice();
    this.displayedProviderColumns = this.providerColumns.slice();

    [this.departmentColumns, this.specialtyColumns, this.providerColumns].forEach(multiLevelCol => {
      multiLevelCol[0].primaryColumn = true;
      createNCRBenchmarkColumnFor(this.benchmarkPercentile);
    });

    this.columnSubscription = this.multiLevelByNodePathCollectionsColumns$
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(displayedColumns => {
        this.displayedDepartmentColumns =
          this.updateColumnFilter(displayedColumns, this.departmentColumns);
        this.displayedSpecialtyColumns =
          this.updateColumnFilter(displayedColumns, this.specialtyColumns);
        displayedColumns = displayedColumns.concat(
          this.providerColumns.filter(col => col.columnType === ColumnType.OPEN_WINDOW));
        this.displayedProviderColumns =
          this.updateColumnFilter(displayedColumns, this.providerColumns);
      });

  }

  getCollectionData(filter?: FilterCriteria): void {
    const state = this.ngRedux.getState();
    if (state && state.data) {
      const appConfigSettings = state.data.applicationConfigurationSettings;
      const maxProviders = getAppConfigValue(AppConfigEntries.MAX_PROVIDER_PATHS, appConfigSettings);
      this.maxProviders = maxProviders && maxProviders.length > 0 ? Number(maxProviders) : this.maxProviders;
      this.maxProvidersMessage
        = `Please select fewer than ${this.maxProviders} "Selected Providers" in the navigation drop down`;
      const maxSpecialties = getAppConfigValue(AppConfigEntries.MAX_SPECIALTY_PATHS, appConfigSettings);
      this.maxSpecialties = maxSpecialties && maxSpecialties.length > 0 ? Number(maxSpecialties) : this.maxSpecialties;
      this.maxSpecialtiesMessage
        = `Please select fewer than ${this.maxSpecialties} specialties in the navigation drop down`;
    }
    if (filter && !!filter.nodePath) {
      this.apiService.getMatchedCollectionsByMultiLevelByNodePathData(filter);
      this.apiService.getPayerCollectionsData(filter);
      this.apiService.getMatchedCollectionsByPayerByMultiLevelData(filter);
      this.apiService.getCollectionSummaryData(filter);
    }
  }

  public setTabProperties() {
    this.moreThanMaxProvidersSelected = this.providerCollectionsSnapshotData &&
      this.providerCollectionsSnapshotData.length > this.maxProviders || this.providerCollectionsSnapshotData.length === 0
      && this.departmentCollectionsSnapshotData.length >= 1;
    this.moreThanMaxSpecialtiesSelected = this.specialtyCollectionsSnapshotData &&
      this.specialtyCollectionsSnapshotData.length > this.maxSpecialties || this.specialtyCollectionsSnapshotData.length === 0
      && this.departmentCollectionsSnapshotData.length >= 1;
    if (this.moreThanMaxProvidersSelected) {
      this.providerPermitted = false;
      if (this.chosenTab === MultilevelTabCollections.BY_PROVIDER) {
        this.chooseTab(MultilevelTabCollections.BY_DEPARTMENT);
      }
    } else {
      this.providerPermitted = true;
    }
    if (this.moreThanMaxSpecialtiesSelected) {
      this.specialtyPermitted = false;
      if (this.chosenTab === MultilevelTabCollections.BY_SPECIALTY) {
        this.chooseTab(MultilevelTabCollections.BY_DEPARTMENT);
      }
    } else {
      this.specialtyPermitted = true;
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.columnSubscription.unsubscribe();
    this.tabSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
    this.cdr.detach();
  }

  adjustSortingVariables(collectionSortingCriteria: SortingCriterion | undefined, collectionVarianceToggle: boolean):
    void {

    const varianceColumn = this.columns.find(col => col.columnType === ColumnType.VARIANCE);
    this.varianceKey = varianceColumn ? varianceColumn.columnDef : 'variance50th';
    this.activeVarianceToggle = collectionVarianceToggle;
    const collectionSortColumn = 'netCollectionRate';
    if (collectionSortingCriteria && collectionSortingCriteria.columnDef && collectionSortingCriteria.sortingOrder) {
      const requiredSortingCriteria: SortingCriterion = collectionSortingCriteria.columnType === ColumnType.VARIANCE ? {
        ...collectionSortingCriteria,
        columnDef: this.varianceKey
      } : collectionSortingCriteria;
      this.defaultSortColumn = requiredSortingCriteria.columnDef;
      this.sortDirection = requiredSortingCriteria.sortingOrder === SortingOrder.DESCENDING ? 'desc' : 'asc';
    } else {
      this.defaultSortColumn = collectionSortColumn;
      this.sortDirection = 'desc';
    }
    if (!collectionVarianceToggle) {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: this.varianceKey,
        columnType: ColumnType.VARIANCE
      };
    } else {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: collectionSortColumn
      };
    }

  }

  determineIfHasAcademicBenchmarks(): boolean {
    for (let i = 0; i < this.collectionsDataMultiLevel.length; i++) {
      if (this.collectionsDataMultiLevel[i].matchedCollectionsByMultiLevelByNodePath.benchmark25th !== 0
        || this.collectionsDataMultiLevel[i].matchedCollectionsByMultiLevelByNodePath.benchmark50th ||
        this.collectionsDataMultiLevel[i].matchedCollectionsByMultiLevelByNodePath.benchmark75th) {
        return true;
      }
    }
    return false;
  }

  updateSortingCriteria = (sortingCriteria: SortingCriterion | undefined) => {
    if (!isSortingCriterionValid(sortingCriteria)) {
      return;
    }
    this.ngRedux.dispatch(collectionsSnapshotSortingCriteriaChangedTo(sortingCriteria));

  };

  getShowProgressBarStatus(): boolean {
    return _.isEqual(this.multiLevelCollectionsData, INITIAL_STATE.data.matchedCollectionsByMultiLevelByNodePathData);
  }

  whenSortChanged = (multiLevelCollections: any) => {
    this.ngRedux.dispatch(snapshotCollectionsDataChangedTo({snapshotMatchedCollections: multiLevelCollections}));
  };

  updateColumnFilter(displayedColumns: BaseColumn[], tableColumns: DataTableColumns[]) {
    const displayedColumnDefs: string[] = displayedColumns.map(col => {
      switch (col.columnType) {
        case ColumnType.BENCHMARK:
          return createNCRBenchmarkColumnFor(this.benchmarkPercentile).columnDef;
        case ColumnType.VARIANCE:
          return createCollectionsVarianceColumnFor(this.benchmarkPercentile).columnDef;
        default:
          return col.columnDef;
      }
    });


    const primaryColumn = this.columns.find(col => col.primaryColumn) || this.columns[0];
    const primaryDisplayedColumn = this.displayedColumns.find(col => col.primaryColumn) || this.displayedColumns[0];
    if (this.level) {
      primaryColumn.header = this.level.split(' ')[1];
      primaryDisplayedColumn.header = this.level.split(' ')[1];
    }

    return tableColumns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef)).slice();
  }

  chooseTab(tab: MultilevelTabCollections): void {
    switch (tab) {
      case MultilevelTabCollections.BY_SPECIALTY:
        if (!this.specialtyPermitted) {
          return;
        }
        break;
      case MultilevelTabCollections.BY_PROVIDER:
        if (!this.providerPermitted) {
          return;
        }
        break;
    }
    this.ngRedux.dispatch(collectionsChosenTabChangedTo(tab));
  }

  toolTipMessage(tab: number) {
    switch (tab) {
      case 1:
        return this.moreThanMaxSpecialtiesSelected ? this.maxSpecialtiesMessage
          : '';
      case 2:
        return this.moreThanMaxProvidersSelected ? this.maxProvidersMessage
          : '';
      default:
        break;
    }
  }

  private suppressProviderEntries() {
    this.countOfSuppressedProviderEntries = 0;
    this.providerCollectionsSnapshotData.forEach(x => {
      x.isHidden = this.isZeroSuppressed && CollectionsMultilevelComponent.isCollectionEntrySuppressible(x);
      if (x.isHidden) {
        this.countOfSuppressedProviderEntries++;
      }
    });
    if (this.providerPage) {
      this.providerPage.collectionsMultiLevel = this.providerCollectionsSnapshotData;
    }
  }

  private suppressDepartmentEntries() {
    this.countOfSuppressedDepartmentEntries = 0;
    this.departmentCollectionsSnapshotData.forEach(x => {
      x.isHidden = this.isZeroSuppressed && CollectionsMultilevelComponent.isCollectionEntrySuppressible(x);
      if (x.isHidden) {
        this.countOfSuppressedDepartmentEntries++;
      }
    });
    if (this.departmentPage) {
      this.departmentPage.collectionsMultiLevel = this.departmentCollectionsSnapshotData;
    }
  }

  private suppressSpecialtyEntries() {
    this.countOfSuppressedSpecialtiesEntries = 0;
    this.specialtyCollectionsSnapshotData.forEach(x => {
      x.isHidden = this.isZeroSuppressed && CollectionsMultilevelComponent.isCollectionEntrySuppressible(x);
      if (x.isHidden) {
        this.countOfSuppressedSpecialtiesEntries++;
      }
    });
    if (this.specialtyPage) {
      this.specialtyPage.collectionsMultiLevel = this.specialtyCollectionsSnapshotData;
    }
  }
}
