import {Observable} from 'rxjs';
import {Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.local';
import {CustomGroupsDataAllFilters} from '../../shared/models';
import {map} from 'rxjs/operators';
import {translateTelehealthFlagForBackEnd, translateTelehealthFlagForFrontEnd} from '../../shared/helpers';

export const CustomGroupsServiceToken = new InjectionToken<CustomGroupsService>(
  'Custom Groups Service');

export interface CustomGroupsService {

  getCustomGroups(memberKey: number): Observable<CustomGroupsDataAllFilters[]>;

  saveCustomGroup(customGroup: CustomGroupsDataAllFilters, memberKey: number): Observable<CustomGroupsDataAllFilters>;

  updateCustomGroup(customGroup: CustomGroupsDataAllFilters, memberKey: number, clearCachedData: boolean):
    Observable<CustomGroupsDataAllFilters>;

  updateCustomGroupClearCacheFlag(cgId: number, memberKey: number, emailId: string, clearCachedData: boolean):
    Observable<boolean>;

  deleteCustomGroup(id: number, memberKey: number): Observable<boolean>;
}

@Injectable()
export class HttpCustomGroupsService implements CustomGroupsService {
  constructor(private httpClient: HttpClient) {
  }

  getCustomGroups(memberKey: number): Observable<CustomGroupsDataAllFilters[]> {
    const baseUrl = `${environment.api}/api/customGroups?memberKey=${memberKey}`;
    const response = this.httpClient.get<CustomGroupsDataAllFilters[]>(
      baseUrl
    );
    return response.pipe(map((data) => {
      data.forEach((customGroup: CustomGroupsDataAllFilters) => {
        customGroup.memberKey = memberKey;
        customGroup.telehealthFlag = translateTelehealthFlagForFrontEnd(customGroup.telehealthFlag);
      });
      return data;
    }));
  }

  saveCustomGroup(customGroup: CustomGroupsDataAllFilters, memberKey: number): Observable<CustomGroupsDataAllFilters> {
    return this.httpClient.post<CustomGroupsDataAllFilters>(`${environment.api}/api/saveCustomGroup?memberKey=${memberKey}`,
      {...customGroup, telehealthFlag: translateTelehealthFlagForBackEnd(customGroup.telehealthFlag)});
  }

  updateCustomGroup(customGroup: CustomGroupsDataAllFilters, memberKey: number, clearCachedData: boolean):
    Observable<CustomGroupsDataAllFilters> {
    const clearDataFlag = clearCachedData ? 1 : 0;
    const url = `${environment.api}/api/updateCustomGroup?memberKey=${memberKey}&clearCachedData=${clearDataFlag}`;
    return this.httpClient.post<CustomGroupsDataAllFilters>(url,
      {...customGroup, telehealthFlag: translateTelehealthFlagForBackEnd(customGroup.telehealthFlag)});
  }

  deleteCustomGroup(id: number, memberKey: number): Observable<boolean> {
    const url = `${environment.api}/api/deleteCustomGroup`;
    return this.httpClient.post<boolean>(
      url,
      '',
      {
        params: new HttpParams()
          .set('memberKey', memberKey.toString())
          .set('id', id.toString())
      }
    );
  }

  updateCustomGroupClearCacheFlag(cgId: number, memberKey: number, emailId: string, clearCachedData: boolean):
    Observable<boolean> {
    console.log('updateCustomGroup service clearCache ', cgId);
    const url = `${environment.api}/api/updateCustomGroupReloadFlag?memberKey=${memberKey}&clearCachedData=${clearCachedData}&id=${cgId}`;
    return this.httpClient.post<boolean>(url, emailId);
  }
}
