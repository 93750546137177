import {BehaviorSubject} from 'rxjs';
import {ExportMetadata} from '../shared/export/export';

export const wrvuByTrendExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const wrvuByDepartmentExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const wrvuBySpecialtyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const wrvuByProviderExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const wrvuBySpecialtyPerformanceExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const npvByTrendExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const npvByDepartmentExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const npvBySpecialtyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const npvByProviderExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const npvByLocationByDepartmentExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const npvByLocationBySpecialtyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const npvByLocationByProviderExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const cptFamilyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const cptRangeExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const cptCodeExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOutpatientByDepartmentExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOutpatientBySpecialtyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOutpatientByProviderExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOutpatientByTrendExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementInpatientByDepartmentExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementInpatientBySpecialtyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementInpatientByProviderExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementInpatientByTrendExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOphthalmologyByDepartmentExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOphthalmologyBySpecialtyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOphthalmologyByProviderExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementOphthalmologyByTrendExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementEmergencyByDepartmentExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementEmergencyBySpecialtyExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementEmergencyByProviderExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const evaluationManagementEmergencyByTrendExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionSummaryDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionByDepartmentDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionBySpecialtyDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionByProviderDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionByPayerDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionByPayerByDepartmentDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionByPayerBySpecialtyDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const collectionByPayerByProviderDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const denialsByDepartmentDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const denialsBySpecialtyDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const denialsByProviderDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const denialsByPayerDataExcelData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryByDepartmentByFamilyData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryByDepartmentByRangeData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryByDepartmentByCodeData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryBySpecialtyByFamilyData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryBySpecialtyByRangeData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryBySpecialtyByCodeData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryByProviderByFamilyData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryByProviderByRangeData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
export const procedureSummaryByProviderByCodeData$: BehaviorSubject<ExportMetadata | boolean> =
  new BehaviorSubject<ExportMetadata | boolean>(false);
