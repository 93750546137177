<app-collections-multilevel-chart
  [showProgressBar]="showProgressBar"
  [variables]="variables"
  [showVariableMenu]="true"
  [data]="collectionsMultiLevel"
  [benchmarkPercentile]="benchmarkPercentile"
  [activeVarianceToggle]="activeVarianceToggle"
  [sortingCriteria]="varianceToggleSortingCriterion"
  [page]="currentPage"
  [tabLevel]="2"
  [columnSelectionCallback]="whenSpecialtySelected">
></app-collections-multilevel-chart>
<app-data-table id="collections-data-table"
                [rowSelectionCallback]="whenSpecialtySelected"
                [showProgressBar]="showProgressBar"
                [receivedData]="collectionsMultiLevel"
                [tableCssClass]="'collection-table'"
                [originalColumns]="columns"
                [defaultSortColumn]="defaultSortColumn"
                [showLevel]="false"
                [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                [sortingDataAccessor]="sortingDataAccessor"
                [sortDirection]="sortDirection"
                tableTitle="Collection Analysis By Specialty"
                [whenSortChanged]="levelSpecificHandleSortChange"
                [collectionsTab]="true"
                [displayZeroSuppression]="true"
                [zeroSuppressionCondition]="zeroSuppressCondition"
                [isZeroSuppressionChecked]="isZeroSuppressed"
                [zeroSuppressionSnapshotEntry]="countOfSuppressedEntries > 1 || countOfSuppressedEntries === 0 ?
                 'Specialties' : 'Specialty'"
                [countOfSuppressedEntries]="countOfSuppressedEntries"
                [displayedColumns]="displayedColumns"
                [currentPage]="currentPage"
                [updateSortingCriteria]="updateSortingCriteria"
                [addRemoveOption]="true"
                [reducerAction]="reducerAction"
>
</app-data-table>
