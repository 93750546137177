import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {select} from '@angular-redux/store';
import {INITIAL_STATE} from '../../../store/IAppState';
import {combineLatest, Observable} from 'rxjs';
import * as _ from 'lodash';
import {isValidOrElse} from '../../../shared/null-helpers';
import {CptCategories, EvaluationManagementSummary} from '../../../shared/models';
import {
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  isValid,
  valueFrom,
  valueFromOrDash
} from '../../em-helpers';
import {BenchmarkOption} from '../../../shared/enums';

@Component({
  selector: 'app-em-emergency-room-summary-table',
  templateUrl: './em-emergency-room-summary-table.component.html',
  styleUrls: ['./em-emergency-room-summary-table.component.scss']
})

export class EmEmergencyRoomSummaryTableComponent implements OnInit {

  displayedData: EvaluationManagementEmergencyRoomSummary[];
  dataSource: MatTableDataSource<EvaluationManagementEmergencyRoomSummary>;
  displayedColumns: string[];
  showProgressBar = true;
  isValidOrElse = isValidOrElse;
  benchmarkString = 'benchmarkPercentage';
  benchmarkDisplay: string;

  @Input() showDataTable: boolean;

  @select(['data', 'summaryEvaluationManagementData'])
  private readonly summaryEvaluationManagementData$: Observable<EvaluationManagementSummary>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly emBenchmarkOption$: Observable<BenchmarkOption>;

  constructor() {
  }

  ngOnInit() {
    combineLatest([this.summaryEvaluationManagementData$, this.emBenchmarkOption$])
      .subscribe(([summaryEvaluationManagementData, benchmarkOption]:
                    [EvaluationManagementSummary, BenchmarkOption]) => {
        this.showProgressBar = _.isEqual(summaryEvaluationManagementData, INITIAL_STATE.data.summaryEvaluationManagementData);
        this.benchmarkDisplay = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
        this.benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
        this.displayedData = this.outpatientSummaryFrom(summaryEvaluationManagementData.totals);

        this.dataSource = new MatTableDataSource<EvaluationManagementEmergencyRoomSummary>(this.displayedData);
        this.displayedColumns = ['encounter'];

        this.displayedColumns.push('countOf99281', 'countOf99282', 'countOf99283', 'countOf99284', 'countOf99285',
          'totalEmergencyMedicine');
      });
  }

  outpatientSummaryFrom(totalCptCounts: CptCategories): EvaluationManagementEmergencyRoomSummary[] {
    let emEmergencyRoomSummary: EvaluationManagementEmergencyRoomSummary[];
    if (!isValid(totalCptCounts)) {
      emEmergencyRoomSummary = [
        {
          encounterMetric: '# of Encounters',
          emergencyRoomPatients: emptyEmergencyRoomVisit(),
        },
        {
          encounterMetric: '% Coding Distribution',
          emergencyRoomPatients: emptyEmergencyRoomVisit(),
        },
        {
          encounterMetric: `${this.benchmarkDisplay}`,
          emergencyRoomPatients: emptyEmergencyRoomVisit(),
        },
      ];
    } else {

      emEmergencyRoomSummary = [
        {
          encounterMetric: '# of Encounters',
          emergencyRoomPatients: isValid(totalCptCounts.emergencyMedicine) ?
            valueFrom(totalCptCounts.emergencyMedicine, 'count') : emptyEmergencyRoomVisit(),
        },
        {
          encounterMetric: '% Coding Distribution',
          emergencyRoomPatients: isValid(totalCptCounts.emergencyMedicine) ?
            valueFrom(totalCptCounts.emergencyMedicine, 'percentage', '%', true) : emptyEmergencyRoomVisit(),
        },
        {
          encounterMetric: `${this.benchmarkDisplay}`,
          emergencyRoomPatients: isValid(totalCptCounts.emergencyMedicine) ?
            valueFromOrDash(totalCptCounts.emergencyMedicine, this.benchmarkString, '%') : emptyEmergencyRoomVisit(),
        },
      ];
    }
    return emEmergencyRoomSummary;
  }
}

function emptyEmergencyRoomVisit() {
  return {
    cpt99281: '-',
    cpt99282: '-',
    cpt99283: '-',
    cpt99284: '-',
    cpt99285: '-',
    total: '-'
  };
}

export interface EvaluationManagementEmergencyRoomSummary {
  encounterMetric: string;
  emergencyRoomPatients: any;
}
