import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {BatchPdfOption} from '../../../shared/export/export';
import {getOppositeNodeExpansionState, nodeExpansionIsActive} from '../../../shared/helpers';
import {selectMandatoryChildren, selectOrDeselectParents} from '../../../export-dialog-multilevel/export-dialog-helper';
import {BenchmarkOption, NodeExpansionState} from '../../../shared/enums';
import {MetricType} from '../../../shared/metric-types';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {
  BatchExportMetricSection,
  batchExportMetricSectionHasAnOptionSelected,
  MultiProviderExportMetricLabel
} from '../../batch-export-helper';

@Component({
  selector: 'app-mpe-hierarchical-selector',
  templateUrl: './mpe-hierarchical-selector.component.html',
  styleUrls: ['./mpe-hierarchical-selector.component.scss']
})
export class MpeHierarchicalSelectorComponent implements OnChanges {
  @Input() hierarchyOptions: BatchPdfOption[];
  @Input() topOptions: BatchPdfOption[] = [];
  @Input() isFirstOption: boolean;
  @Input() canShowBenchmarkSelector = false;
  @Input() isOverView = false;
  @Input() whenHierarchicalOptionSelected: () => void;
  @Input() batchExportMetricSection?: BatchExportMetricSection;
  @Input() overviewSubMetricSections: BatchExportMetricSection[] = [];
  @Input() showSubMetricBenchmarkSelectors = false;
  @Output() whenBenchmarkOptionChosen = new EventEmitter<BenchmarkOption>();
  @Output() whenPercentileChosen = new EventEmitter<BenchmarkPercentile>();
  displayedHierarchyOptions: BatchPdfOption[];
  nonDisplayedHierarchyOptions: BatchPdfOption[];
  hoverMessage: MultiProviderExportMetricLabel | undefined;
  showHoverMessage = false;

  readonly nodeExpansionIsActive = nodeExpansionIsActive;
  readonly MetricType = MetricType;
  readonly NodeExpansionState = NodeExpansionState;
  readonly itemClass = 'item';

  constructor() { }
  ngOnChanges(): void {
    this.hoverMessage = this.batchExportMetricSection?.hoverMessage;
    this.displayedHierarchyOptions = this.hierarchyOptions.filter(o => o.displayed);
    this.nonDisplayedHierarchyOptions = this.hierarchyOptions.filter(o => !o.displayed);
  }

  whenOptionSelected(option: BatchPdfOption): void {
    option.selected = !option.selected;
    this.selectOrDeselectChildren(option);
    selectOrDeselectParents(this.topOptions);
    selectMandatoryChildren(this.topOptions, this.topOptions[0].selected);
    if (this.batchExportMetricSection) {
      this.batchExportMetricSection.selected = option.selected || batchExportMetricSectionHasAnOptionSelected(
        this.batchExportMetricSection.options);
    }
    this.whenHierarchicalOptionSelected();
  }

  private selectOrDeselectChildren(option: BatchPdfOption): void {
    option.children.forEach((child: BatchPdfOption) => {
      child.selected = option.selected;
      this.selectOrDeselectChildren(child);
    });
  }

  whenOptionExpanded(option: BatchPdfOption): void {
    option.nodeExpansionState = getOppositeNodeExpansionState(option.nodeExpansionState);
  }

  chooseBenchmarkOption(benchmarkOption: BenchmarkOption): void {
    this.whenBenchmarkOptionChosen.emit(benchmarkOption);
  }

  choosePercentile(percentile: BenchmarkPercentile): void {
    this.whenPercentileChosen.emit(percentile);
  }
}
