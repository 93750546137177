import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../models';

@Component({
  selector: 'app-confirm-delete-custom-group',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  ngOnInit(): void { }

  confirm(): void {
    if (this.data.confirmCallback) {
      this.data.confirmCallback();
    }
  }

  cancel(): void {
    this.dialogRef.close();
    if (this.data.cancelCallback) {
      this.data.cancelCallback();
    }
  }
}
