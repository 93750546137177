<div class="wrapper">
  <div id="overview-patient-visit-charts">
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart
        chartId="new-patient-eye-exam"
        [categories]="newPatientCategories"
        [maxYAxisValue]="maxNewPatientEyeExamYAxisValue"
        chartTitle="New Patient Eye Exam"
        [chartHeight]="chartHeight"
        [totalCptSummaries]="totalNewPatientEyeExamCptSummaries"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="true"
        [specialtyCptSummaries]="specialtyTotalNewPatientEyeExamCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart
        chartId="established-patient-eye-exam"
        [categories]="establishedPatientCategories"
        [maxYAxisValue]="maxEstablishedPatientEyeExamYAxisValue"
        chartTitle="Established Patient Eye Exam"
        [chartHeight]="chartHeight"
        [totalCptSummaries]="totalEstablishedPatientEyeExamCptSummaries"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="true"
        [specialtyCptSummaries]="specialtyTotalEstablishedPatientEyeExamCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
  </div>
  <app-chart-legend
    [fromPdf]="true"
    [metricType]="metricType"
    [legends]="legends"
    [showExtendedBenchmarkOptions]="true"
    [benchmarkOption]="benchmarkOption"
  ></app-chart-legend>
  <div id="table-container" *ngIf="showTable">
    <app-em-snapshot-table [receivedData]="tableData" [columns]="columns" [fromPdf]="true"></app-em-snapshot-table>
  </div>
</div>
