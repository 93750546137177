<app-tab-navigation id="level-nav"
                    [tabs]="levelTabs"
                    [chosenTab]="selectedLevelTab"
                    [tabUpdateAction]="multilevelTabChangedTo"
></app-tab-navigation>
<span id="npv-location-outer-wrapper">
  <span id="npv-location-inner-wrapper">
    <span id="toggle-container">
      <mat-button-toggle-group id="toggle-node-location">
        <mat-button-toggle [checked]="!variables.viewByNode"
                           (click)="toggleViewByNode(false)"
                           id="view-by-location-toggle">
          By Location</mat-button-toggle>
        <mat-button-toggle [checked]="variables.viewByNode"
                           (click)="toggleViewByNode(true)"
                           id="view-by-node-toggle">
          By {{ontologyText}}</mat-button-toggle>
      </mat-button-toggle-group>
    </span>
    <app-npv-location-chart [benchmarkPercentile]="benchmarkPercentile"
                            [benchmarkOption]="benchmarkOption"
                            [npvLocationChartData]="levelSpecificChartData"
                            [inverseChartData]="inverseChartData"
                            [npvLocationVariables]="variables"
                            [selectedLevelTab]="selectedLevelTab"
                            [showPatientVisitCounts]="showPatientVisitCountsOnGraph"
                            [showLoader]="referToSnapshotLoader ? showSnapshotLoader : showLocationLoader"
    ></app-npv-location-chart>
    <app-new-patient-visits-summary-data-table [showExtendedBenchmarkOptions]="true">
    </app-new-patient-visits-summary-data-table>
    <app-location-npv-table [receivedData]="levelSpecificTableData"
                            [originalColumns]="originalColumns"
                            [benchmarkOption]="benchmarkOption"
                            [multilevelTab]="selectedLevelTab"
                            [tableTitle]="tableTitle"
                            [columns]="displayedColumns"
                            [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                            [countOfSuppressedEntries]="countOfSuppressedEntries"
                            [showLoader]="showLocationLoader"
                            [snapshotEntryName]="snapshotEntryName"
                            [currentPage]="currentPage"
                            [previousDateText]="previousDateText"
                            [reducerAction]="reducerAction">
    </app-location-npv-table>
  </span>
</span>
