import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DEFAULT_TELEHEALTH_FLAG} from '../../store/DefaultValues';
import {VisitType} from '../../shared/models';
import {visitTypes} from '../../shared/constants';

@Component({
  selector: 'app-select-visit-type',
  templateUrl: './select-visit-type.component.html',
  styleUrls: ['./select-visit-type.component.scss']
})
export class SelectVisitTypeComponent implements OnInit {

  @Output() visitTypeEmit = new EventEmitter<VisitType>();
  @Input() selectedVisitType: VisitType = DEFAULT_TELEHEALTH_FLAG;
  visitTypeOptions: VisitType[] = visitTypes;

  constructor() { }

  ngOnInit(): void {}

  selectVisitType(visitType: VisitType,  event: MouseEvent) {
    this.visitTypeEmit.emit(visitType);
  }

}
