import { Component, Inject } from '@angular/core';
import { FilterListenerService, FilterListenerServiceToken } from '../services/filter-listener.service';
import {
  DepartmentSpecialtyListenerService,
  DepartmentSpecialtyListenerServiceToken,
} from '../services/department-specialty-listener.service';
import { MemberListenerService, MemberListenerServiceToken } from '../services/member-listener.service';
import {ExternalRequestService} from '../services/external-request.service';

@Component({
  selector: 'app-filter-listener-injector',
  templateUrl: './listener-injector.component.html',
})
export class ListenerInjectorComponent {
  constructor(
    @Inject(FilterListenerServiceToken) private filterListener: FilterListenerService,
    @Inject(DepartmentSpecialtyListenerServiceToken) private departmentSpecialityListener: DepartmentSpecialtyListenerService,
    @Inject(MemberListenerServiceToken) private memberListener: MemberListenerService,
    private externalRequestService: ExternalRequestService
  ) { }
}
