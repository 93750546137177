import {Component, Inject, Input, OnInit} from '@angular/core';
import {MetricType} from '../../../shared/metric-types';
import {emPageRoute, npvMultiLevelTrendPageRoute, wRVUMultiLevelTrendPageRoute} from '../../../shared/routes';
import {Router} from '@angular/router';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {previousPatientVisitsCountsDisplayChangedTo, previousTimePeriodDisplayChanged} from '../../../store/actions';
import {multilevelTabChangedTo} from '../../../store/actions';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {MultilevelTab} from '../../../shared/enums';

@Component({
  selector: 'app-overview-tile-footer',
  templateUrl: './overview-tile-footer.component.html',
  styleUrls: ['./overview-tile-footer.component.scss']
})
export class OverviewTileFooterComponent implements OnInit {

  @Input() metricType: MetricType;
  @Input() fromPdfExport = false;

  constructor(
    private router: Router,
    private ngRedux: NgRedux<IAppState>,
    @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
  }

  viewDataReport(metricType: MetricType) {
    switch (metricType) {
      case MetricType.EandM :
        this.router.navigateByUrl(emPageRoute);
        break;
      case MetricType.NewPatientVisits :
        this.ngRedux.dispatch(previousPatientVisitsCountsDisplayChangedTo(true));
        this.analyticsService.handleGoogleAnalytics('Npv trend tile', 'Drill down', 'View data report');
        this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.TREND));
        this.router.navigateByUrl(npvMultiLevelTrendPageRoute);
        break;
      case MetricType.WorkRVUs :
        this.ngRedux.dispatch(previousTimePeriodDisplayChanged(true));
        this.analyticsService.handleGoogleAnalytics('wRVU trend tile', 'Drill down', 'View data report');
        this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.TREND));
        this.router.navigateByUrl(wRVUMultiLevelTrendPageRoute);
        break;
    }
  }

}
