<app-header fromBaseComponent="true"></app-header>
<div id="body">
  <div class="topnav-wrapper" [ngClass]="(filterBannerEdit$ | async) ? 'topnav' : scrollY > 0 ? 'topnav-no-header' : 'topnav-and-header'">
    <app-navigation-bar></app-navigation-bar>
  </div>
  <div id="main-content" class="{{scrollY > 0 ? 'main-content-higher' : 'main-content'}}">
    <div *ngIf="filterBannerEdit$ | async" id="disable-background"></div>
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer [fromPdf]="false"></app-footer>
