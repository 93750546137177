import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {benchmarkPercentileSeriesName} from '../../../../shared/helpers';
import {MetricType} from '../../../../shared/metric-types';
import {BenchmarkHelperObject, BenchmarkPercentile} from '../../../../shared/benchmark-types';
import {BenchmarkPercentilesForNpv, BenchmarkPercentilesForWrvu} from '../../../../shared/BenchmarkColumns';
import {determineBenchmarkOptions} from '../../mpe-dialog-helper';
import {BenchmarkOption} from '../../../../shared/enums';
import {BatchExportMetricSection} from '../../../batch-export-helper';

@Component({
  selector: 'app-batch-export-benchmark-selector',
  templateUrl: './batch-export-benchmark-selector.component.html',
  styleUrls: ['./batch-export-benchmark-selector.component.scss']
})
export class BatchExportBenchmarkSelectorComponent implements OnChanges {

  @Input() batchExportMetricSection: BatchExportMetricSection;
  @Input() selected = false;
  @Output() whenBenchmarkOptionChosen = new EventEmitter<BenchmarkOption>();
  @Output() whenPercentileChosen = new EventEmitter<BenchmarkPercentile>();
  benchmarkOptions: BenchmarkHelperObject[] = [];
  percentiles: SelectablePercentile[] = [];
  hasMultiplePercentiles = false;
  percentileSelectorClass = '';
  private readonly defaultPercentile = BenchmarkPercentile.Mean;
  readonly defaultPercentileName = benchmarkPercentileSeriesName(this.defaultPercentile);
  readonly benchmarkPercentileSeriesName = benchmarkPercentileSeriesName;

  constructor() { }

  ngOnChanges(): void {
    this.benchmarkOptions = determineBenchmarkOptions(this.batchExportMetricSection ?
      this.batchExportMetricSection.metric : MetricType.None);
    this.determinePercentiles();
    this.hasMultiplePercentiles = this.percentiles.length > 1;
    this.percentileSelectorClass = this.generatePercentileSelectorClass();
  }

  private generatePercentileSelectorClass(): string {
    const percentileSelectorClass = 'benchmark-selector';
    let singlePercentileClass = 'single-percentile';
    let multiplePercentileClass = 'multiple-percentile';
    const getActivityClass = (multiplePercentile: boolean) => {
      const active = 'active';
      const inactive = 'inactive';
      return `${multiplePercentile ? ' ' : '-'}${this.selected ? active : inactive}`;
    };
    singlePercentileClass = `${singlePercentileClass} ${singlePercentileClass}${getActivityClass(false)}`;
    multiplePercentileClass = `${multiplePercentileClass} ${multiplePercentileClass}${getActivityClass(true)}`;
    return `${percentileSelectorClass} ${this.hasMultiplePercentiles ? multiplePercentileClass : singlePercentileClass}`;
  }

  private determinePercentiles(): void {
    switch (this.batchExportMetricSection ? this.batchExportMetricSection.metric : MetricType.None) {
      case MetricType.WorkRVUs:
      case MetricType.OverviewWrvu:
        this.percentiles = BenchmarkPercentilesForWrvu.map(benchmark => {
          return {
            percentile: benchmark, text: benchmarkPercentileSeriesName(benchmark)
          };
        });
        break;
      case MetricType.NewPatientVisits:
      case MetricType.OverviewNpv:
        this.percentiles = BenchmarkPercentilesForNpv.map(benchmark => {
          return {
            percentile: benchmark, text: benchmarkPercentileSeriesName(benchmark)
          };
        });
        break;
      default:
        this.percentiles = [{
          percentile: this.defaultPercentile,
          text: this.defaultPercentileName
        }];
    }
  }

  chooseBenchmarkOption(benchmarkOption: BenchmarkOption): void {
    this.whenBenchmarkOptionChosen.emit(benchmarkOption);
  }

  choosePercentile(percentile: BenchmarkPercentile): void {
    this.whenPercentileChosen.emit(percentile);
  }
}

interface SelectablePercentile {
  percentile: BenchmarkPercentile;
  text: string;
}
