import {Legend} from '../../shared/models';
import {LegendColor, LegendStyle} from '../../shared/enums';
import {MetricType} from '../../shared/metric-types';
import {CfpVariableViewType} from '../../shared/helpers';
import {getDisplayTextForCfpVariableTypeWithBenchmarkOption} from '../clinical-fingerprint-helpers';
import {isBenchmarkRequired} from '../clinical-fingerprint-helpers';

export const chargesLegendForCfp: Legend = {
  text: 'Charges',
  color: LegendColor.ORANGE,
  style: LegendStyle.CIRCLE,
  metric: MetricType.ClinicalFingerprint,
  showBenchmarkOptionControl: false,
  showPercentileControl: false
};

export function getCFPBenchmarkLegend(cfpVariableViewType: CfpVariableViewType, viewCommunity: boolean): Legend {
  return {
    text: getDisplayTextForCfpVariableTypeWithBenchmarkOption(cfpVariableViewType, viewCommunity),
    color: LegendColor.GREY,
    style: LegendStyle.SQUARE,
    metric: MetricType.ClinicalFingerprint,
    showBenchmarkOptionControl: true,
    showPercentileControl: false
  };
}

export function getCFPVarianceLegends(cfpVariableViewType: CfpVariableViewType, viewCommunity: boolean) {
  const variableLegends: Legend[] = [
    {
      text: 'Above Benchmark',
      color: LegendColor.GREEN,
      metric: MetricType.ClinicalFingerprint,
      style: LegendStyle.SQUARE,
      showBenchmarkOptionControl: false,
      showPercentileControl: false
    },
    {
      text: 'Below Benchmark',
      color: LegendColor.RED,
      metric: MetricType.ClinicalFingerprint,
      style: LegendStyle.SQUARE,
      showBenchmarkOptionControl: false,
      showPercentileControl: false
    }
  ];
  if (isBenchmarkRequired(cfpVariableViewType)) {
    variableLegends.push(getCFPBenchmarkLegend(cfpVariableViewType, viewCommunity));
  }
  return variableLegends;
}

export const cftAdjCountLegendForCfp: Legend = {
  text: 'cFTE adj. Count',
  color: LegendColor.TEAL,
  metric: MetricType.ClinicalFingerprint,
  style: LegendStyle.SQUARE,
  showPercentileControl: false,
  showBenchmarkOptionControl: false
};

export const actualWrvuLegendForCfp: Legend = {
  text: 'Actual wRVUs',
  color: LegendColor.TEAL,
  metric: MetricType.ClinicalFingerprint,
  style: LegendStyle.SQUARE,
  showPercentileControl: false
};

export const cfteWrvuLegendForCfp: Legend = {
  text: 'cFTE adj. wRVUs',
  color: LegendColor.TEAL,
  metric: MetricType.ClinicalFingerprint,
  style: LegendStyle.SQUARE,
  showPercentileControl: false
};

export const countLegendForCfp: Legend = {
  text: 'Count',
  color: LegendColor.TEAL,
  metric: MetricType.ClinicalFingerprint,
  style: LegendStyle.SQUARE,
  showPercentileControl: false,
  showBenchmarkOptionControl: false
};
