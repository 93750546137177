import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  BenchmarkCombo,
  FormControlValidationStatus,
  getBenchmarkComboDefault,
  MpeDialogPage,
  SchedulePane
} from './mpe-dialog-helper';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  BatchExportMetricSection,
  closeSelectionHierarchy,
  convertBatchExportCriteriaToScheduleObject,
  getOriginalBatchExportMetricSections,
  getOverviewMetricSection,
  HasMetricAndBenchmarkCombo,
  overViewId,
  setSelectedScheduleEmPageOptionValues,
  setSelectedScheduleEmTrendOptionValues,
  setSelectedScheduleNpvOptionValues,
  setSelectedScheduleOverviewOptionValues
} from '../batch-export-helper';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../../store/IAppState';
import {NgRedux, select} from '@angular-redux/store';
import {getAppConfigValue, isFeatureEnabled} from '../../shared/helpers';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {BatchExportDetailCriteria, FeatureToggleSetting, UserProfile} from '../../shared/models';
import * as _ from 'lodash';
import {distinctUntilChanged} from 'rxjs/operators';
import {BatchExportServiceToken, HttpBatchExportService} from '../services/batch-export.service';
import {MetricType} from '../../shared/metric-types';
import {
  BatchPdfOption,
  EMPageOption,
  EMTrendOption,
  NPVPageOption,
  OverviewPageOption
} from '../../shared/export/export';
import {FrontEndTelehealthFlag, GoogleAnalyticCategories, GoogleAnalyticsLabels} from '../../shared/enums';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';
import {FormControlStatus} from '@angular/forms';
import {AnalyticsService, AnalyticsServiceToken} from '../../analytics/analytics.service';
import {generateExportEventTextForMetric, generateExportEventTextForOverview} from './export-event-helpers';

@Component({
  selector: 'app-multi-provider-export-redesign',
  templateUrl: './multi-provider-export-redesign.component.html',
  styleUrls: ['./multi-provider-export-redesign.component.scss']
})
export class MultiProviderExportRedesignComponent implements OnInit, OnDestroy, AfterViewChecked {

  private static wrvuBenchmarkCombo: BenchmarkCombo;
  private static npvBenchmarkCombo: BenchmarkCombo;
  private static emBenchmarkCombo: BenchmarkCombo;

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;
  @select(['data', 'userProfile'])
  private readonly userProfile$: Observable<UserProfile>;
  @select(['display', 'selectedProviders'])
  private readonly selectedProviders$: Observable<number>;
  @select(['filters'])
  private readonly filters$: Observable<FilterCriteria>;
  private filters: FilterCriteria;
  currentPage: MpeDialogPage;
  MpeDialogPage = MpeDialogPage;
  nextButtonText: string;
  backButtonText: string;
  nextButtonValue: MpeDialogPage;
  backButtonValue: MpeDialogPage;
  schedulePaneInfo: SchedulePane = {
    reportName: '',
    isMonthly: false
  };
  originalBatchExportMetricSections: BatchExportMetricSection[] = [];
  currentBatchExportMetricSections: BatchExportMetricSection[] = [];
  readonly overViewId = overViewId;
  overviewMetricSection: BatchExportMetricSection;
  numberOfProviders = 0;
  somethingSelected = false;
  permittedToGoToSummary = true;
  showErrorMessage = false;
  validationStatus: FormControlStatus = FormControlValidationStatus.VALID;
  // Feature toggles
  canShowNpv = false;
  canShowWrvuTrend = false;
  canShowBenchmarkSelector = false;
  canShowNpvLocation = false;
  private featureToggleSubscription: Subscription;

  private static linkBenchmarkCombo(item: HasMetricAndBenchmarkCombo): void {
    switch (item.metric) {
      case MetricType.OverviewWrvu:
        item.benchmarkCombo = MultiProviderExportRedesignComponent.wrvuBenchmarkCombo;
        break;
      case MetricType.OverviewNpv:
        item.benchmarkCombo = MultiProviderExportRedesignComponent.npvBenchmarkCombo;
        break;
      case MetricType.OverviewEandM:
        item.benchmarkCombo = MultiProviderExportRedesignComponent.emBenchmarkCombo;
    }
  }

  private static setLinkedBenchmarkCombos(originalBatchExportMetricSections: BatchExportMetricSection[],
                                          overviewOptions: BatchPdfOption[]): void {
    MultiProviderExportRedesignComponent.setLinkedBenchmarkCombosForMetricSections(originalBatchExportMetricSections);
    MultiProviderExportRedesignComponent.setLinkedBenchmarkCombosForPdfOptions(overviewOptions);
  }

  private static setLinkedBenchmarkCombosForMetricSections(batchExportMetricSections: BatchExportMetricSection[]): void {
    batchExportMetricSections.forEach(s => {
      MultiProviderExportRedesignComponent.linkBenchmarkCombo(s);
    });
  }

  private static setLinkedBenchmarkCombosForPdfOptions(options: BatchPdfOption[]): void {
    options.forEach(o => {
      MultiProviderExportRedesignComponent.linkBenchmarkCombo(o);
      MultiProviderExportRedesignComponent.setLinkedBenchmarkCombosForPdfOptions(o.children);
    });
  }

  private static setStaticBenchmarkCombosBasesOnTelehealthFlag(telehealthFlag: FrontEndTelehealthFlag): void {
    MultiProviderExportRedesignComponent.wrvuBenchmarkCombo = getBenchmarkComboDefault(false,
      telehealthFlag);
    MultiProviderExportRedesignComponent.npvBenchmarkCombo = getBenchmarkComboDefault(true,
      telehealthFlag);
    MultiProviderExportRedesignComponent.emBenchmarkCombo = getBenchmarkComboDefault(true,
      telehealthFlag);
  }

  constructor(public dialogRef: MatDialogRef<MultiProviderExportRedesignComponent>,
              public dialog: MatDialog,
              private cdr: ChangeDetectorRef,
              private ngRedux: NgRedux<IAppState>,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.updatePage(MpeDialogPage.SETUP);
    this.featureToggleSubscription = combineLatest([
      this.featureToggleSettings$.pipe(distinctUntilChanged((type1, type2) =>
        _.isEqual(type1, type2))),
      this.userProfile$.pipe(distinctUntilChanged((type1, type2) =>
        _.isEqual(type1, type2))),
      this.selectedProviders$.pipe(distinctUntilChanged((type1, type2) =>
        _.isEqual(type1, type2))),
      this.filters$.pipe(distinctUntilChanged((type1, type2) =>
        _.isEqual(type1, type2)))
    ]).subscribe(([featureSettings, userProfile, selectedProviders, filters]: [FeatureToggleSetting[],
      UserProfile, number, FilterCriteria]) => {
      if (!_.isEqual(featureSettings, INITIAL_STATE.data.featureToggleSettings)) {
        this.canShowNpv = isFeatureEnabled(FeatureToggleEntries.NPV_MULTI_PROVIDER_EXPORT, featureSettings, userProfile);
        this.canShowNpvLocation = isFeatureEnabled(FeatureToggleEntries.MPE_NPV_BY_LOCATION, featureSettings, userProfile);
        this.canShowWrvuTrend = isFeatureEnabled(FeatureToggleEntries.BATCH_EXPORT_WRVU_TREND, featureSettings, userProfile);
        this.canShowBenchmarkSelector = isFeatureEnabled(FeatureToggleEntries.MULTI_PROVIDER_BENCHMARK_SELECTORS,
          featureSettings, userProfile);
        this.originalBatchExportMetricSections = getOriginalBatchExportMetricSections(this.canShowNpvLocation,
          this.canShowWrvuTrend, filters.telehealthFlag ?? FrontEndTelehealthFlag.ALL_VISIT_TYPES);
        this.overviewMetricSection = getOverviewMetricSection(filters.telehealthFlag ?? FrontEndTelehealthFlag.ALL_VISIT_TYPES);
        MultiProviderExportRedesignComponent.setStaticBenchmarkCombosBasesOnTelehealthFlag(filters.telehealthFlag ??
          FrontEndTelehealthFlag.ALL_VISIT_TYPES);
        MultiProviderExportRedesignComponent.setLinkedBenchmarkCombos(this.originalBatchExportMetricSections,
          this.overviewMetricSection.options);
        this.currentBatchExportMetricSections = this.originalBatchExportMetricSections.slice();
        this.numberOfProviders = selectedProviders;
        this.filters = filters;
      }
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.featureToggleSubscription?.unsubscribe();
    this.clearValues();
  }

  private clearValues(): void {
    this.currentBatchExportMetricSections.concat([this.overviewMetricSection]).forEach(b => {
      closeSelectionHierarchy(b.options);
    });
  }

  onSchedulePaneInfoChange(): void {
    this.permittedToGoToSummary = this.isPermittedToGoToSummary();
  }

  onValidationStatusChange(event: FormControlStatus): void {
    this.validationStatus = event;
    this.permittedToGoToSummary = this.isPermittedToGoToSummary();
  }

  updatePage(page?: MpeDialogPage): void {
    this.cdr.detectChanges();
    if (page) {
      this.permittedToGoToSummary = this.isPermittedToGoToSummary();
      this.currentPage = page;
      switch (this.currentPage) {
        case MpeDialogPage.SETUP:
          this.setUpNextButton('Next', MpeDialogPage.SUMMARY);
          this.setUpBackButton('Cancel', MpeDialogPage.CLOSED);
          break;
        case MpeDialogPage.SUMMARY:
          this.setUpNextButton('Export', MpeDialogPage.EXPORTED);
          this.setUpBackButton('Edit', MpeDialogPage.SETUP);
          break;
        case MpeDialogPage.EXPORTED:
          this.export();
          this.setUpBackButton('', MpeDialogPage.NONE);
          this.setUpNextButton('Done', MpeDialogPage.CLOSED);
          break;
        case MpeDialogPage.CLOSED:
          this.dialogRef.close();
      }
    }
  }

  private setUpNextButton(text: string, value: MpeDialogPage) {
    this.nextButtonText = text;
    this.nextButtonValue = value;
  }

  private setUpBackButton(text: string, value: MpeDialogPage) {
    this.backButtonText = text;
    this.backButtonValue = value;
  }

  isPermittedToGoToSummary(): boolean {
    return this.somethingSelected && this.schedulePaneInfo.reportName.length > 0
      && (this.validationStatus !== FormControlValidationStatus.INVALID && this.validationStatus !== FormControlValidationStatus.PENDING);
  }

  onItemSelected(somethingSelected: boolean): void {
    this.somethingSelected = somethingSelected;
    this.permittedToGoToSummary = this.isPermittedToGoToSummary();
  }

  onBatchExportOptionsSubmitted(batchExportMetricSections: BatchExportMetricSection[]): void {
    this.currentBatchExportMetricSections = batchExportMetricSections;
  }

  private export() {
    const selections = this.getBatchExportScheduleInfoFromUserInput(this.currentBatchExportMetricSections, this.filters);
    if (this.schedulePaneInfo.isMonthly) {
      this.batchExportService.saveBatchExportScheduleInformation(convertBatchExportCriteriaToScheduleObject(selections,
        true), this.filters.memberKey).subscribe();
    } else {
      this.batchExportService.saveBatchExportInformation(selections, this.filters.memberKey).subscribe();
    }
    this.logExportToGoogleAnalytics();
  }

  private logExportToGoogleAnalytics() {
    const gaPrefix = this.schedulePaneInfo.isMonthly ? 'Scheduled' : 'One-Time';
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.MultiProviderExport,
      GoogleAnalyticCategories.MultiProviderExport, `${gaPrefix} ${GoogleAnalyticCategories.MultiProviderExport}`);
    let allPdfOptions: BatchPdfOption[] = this.overviewMetricSection.options;
    this.currentBatchExportMetricSections.forEach(cbems => {
      allPdfOptions = allPdfOptions.concat(cbems.options);
    });
    const eventName = this.generateExportSectionsEventName();
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.MultiProviderExport,
      `${GoogleAnalyticsLabels.MultiProviderExport} Metric Selection`, eventName);
  }

  private generateExportSectionsEventName(): string {
    let eventName = '';
    if (this.overviewMetricSection.selected) {
      eventName = generateExportEventTextForOverview(this.overviewMetricSection);
    }
    this.currentBatchExportMetricSections.forEach(cbems => {
      if (cbems.selected) {
        eventName = `${eventName} ${generateExportEventTextForMetric(cbems.options[0],
          true, !!cbems.benchmarksExtended, cbems.benchmarkCombo)}\n\n`;
      }
    });
    return eventName;
  }

  private getBatchExportScheduleInfoFromUserInput(batchExportMetricSections: BatchExportMetricSection[],
                                                  filters: FilterCriteria): BatchExportDetailCriteria {
    const maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT,
      this.ngRedux.getState().data.applicationConfigurationSettings) || 5;
    let locationText = 'All Locations';
    const selectedLocations = this.ngRedux.getState().data.memberLocations.filter(loc => loc.currentlySelected);
    if (selectedLocations.length > 0) {
      const additionalLocations = selectedLocations.length - maxLocations;
      locationText = selectedLocations.slice(0, maxLocations).map(x => x.memberLocationName).join(', ') +
        (additionalLocations > 0 ? (' + ' + additionalLocations + ' more') : '');
    }
    const emPageSection = batchExportMetricSections.find(s => s.metric === MetricType.EandM);
    const emTrendSection = batchExportMetricSections.find(s => s.metric === MetricType.EandMTrend);
    const npvSection = batchExportMetricSections.find(s => s.metric === MetricType.NewPatientVisits);
    const initialSchedule: BatchExportDetailCriteria = {
      reportName: this.schedulePaneInfo.reportName,
      startDateInMonths: (filters.dateRange.startYear * 12) + filters.dateRange.startMonth,
      endDateInMonths: (filters.dateRange.endYear * 12) + filters.dateRange.endMonth,
      memberLocationKey: filters.memberLocation.memberLocationKey,
      payerCategoryKey: filters.payerCategory.payerCategoryKey,
      payerKey: filters.payerKey,
      payerType: filters.payerType,
      lagKey: filters.lagKey,
      memberBillingAreaKey: filters.memberBillingArea.memberBillingAreaKey,
      invoiceStatus: filters.invoiceStatus,
      nodePath: filters.nodePath,
      isOutpatientRequired: false,
      isInPatientRequired: false,
      isEmergencyMedicineRequired: false,
      isOpthalmologyRequired: false,
      payerCategoryDescription: filters.payerCategory.payerCategoryDescription,
      memberLocationName: filters.memberLocation.memberLocationName,
      memberName: this.ngRedux.getState().data.selectedMemberData.memberDesc,
      billingArea: filters.memberBillingArea.memberBillingAreaDescription,
      telehealthFlag: filters.telehealthFlag,
      isOverviewRequired: false,
      isEmPageRequired: false,
      isEmPageOutpatientRequired: false,
      isEmPageInPatientRequired: false,
      isEmPageEmergencyMedicineRequired: false,
      isEmPageOpthalmologyRequired: false,
      isNewPatientVisitsTrendPageRequired: false,
      getBatchExportReportDetailsByUser: false,
      memberLocationKeys: filters.memberLocationKeys,
      multiLocationMessage: locationText,
      isEmTrendPageRequired: false,
      emTrendRangeNewPatientVisit: false,
      emTrendRangeEstablishedPatientVisit: false,
      emTrendRangeConsultation: false,
      emTrendRangeInitialHospital: false,
      emTrendRangeSubsequentHospital: false,
      emTrendRangeHospitalDischarge: false,
      emTrendRangeEmergencyMedicine: false,
      emTrendRangeNpvEyeExam: false,
      emTrendRangeEstablishedEyeExam: false,
      npvTileBenchmarkType: MultiProviderExportRedesignComponent.npvBenchmarkCombo.option,
      npvTileBenchmarkPercentile: MultiProviderExportRedesignComponent.npvBenchmarkCombo.percentile,
      wrvuTileBenchmarkType: MultiProviderExportRedesignComponent.wrvuBenchmarkCombo.option,
      wrvuTileBenchmarkPercentile: MultiProviderExportRedesignComponent.wrvuBenchmarkCombo.percentile,
      emTileBenchmarkType: MultiProviderExportRedesignComponent.emBenchmarkCombo.option,
      emTrendBenchmarkType: emTrendSection?.benchmarkCombo?.option,
      emPageBenchmarkType: emPageSection?.benchmarkCombo?.option,
      npvPageBenchmarkType: npvSection?.benchmarkCombo?.option,
      npvPageBenchmarkPercentile: npvSection?.benchmarkCombo?.percentile
    };
    setSelectedScheduleOverviewOptionValues(initialSchedule, this.overviewMetricSection.options.map(o => <OverviewPageOption>o));
    if (emPageSection) {
      setSelectedScheduleEmPageOptionValues(initialSchedule, emPageSection.options.map(o => <EMPageOption>o));
    }
    if (emTrendSection) {
      setSelectedScheduleEmTrendOptionValues(initialSchedule, emTrendSection.options.map(o => <EMTrendOption>o));
    }
    if (npvSection) {
      setSelectedScheduleNpvOptionValues(initialSchedule, npvSection.options.map(o => <NPVPageOption>o));
    }
    return initialSchedule;
  }
}
