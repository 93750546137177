import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import {AxisLabelsFormatterContextObject, Options, PlotColumnOptions, PointClickEventObject, YAxisOptions} from 'highcharts/highstock';
import {
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  StyledChart
} from '../../shared/highcharts-helpers';
import {CHART_HEIGHT} from '../../productivity-summary/productivity-graph-component';
import {GraphColors} from '../../productivity-summary/colors';
import {
  abbeviateNumberAfterRounding,
  abbreviateAxisValue,
  roundToNumber,
  trimTrailingZeroes
} from '../../productivity-summary/number-formatter';
import {concat} from '../../shared/ourLodash';
import {CptViewType, HighChartColorIndex} from '../../shared/enums';
import {getCptDescription} from '../../evaluation-management/em-helpers';
import {Chart} from 'angular-highcharts';
import {CfpVariableViewType, deepCopyOf, toTitleCase} from '../../shared/helpers';
import {select} from '@angular-redux/store';
import {Legend, SortingCriterion} from '../../shared/models';
import {Observable, Subscription} from 'rxjs';
import {hasNonZeroValue} from '../../shared/null-helpers';
import {
  actualWrvuLegendForCfp,
  cftAdjCountLegendForCfp,
  cfteWrvuLegendForCfp,
  chargesLegendForCfp,
  countLegendForCfp
} from './cfp-chart-helper';
import {getCFPBenchmarkLegend} from './cfp-chart-helper';
import {getCFPVarianceLegends} from './cfp-chart-helper';
import {getDisplayTextForCfpVariableTypeWithBenchmarkOption, sortByDefaultTheData} from '../clinical-fingerprint-helpers';
import {isBenchmarkRequired} from '../clinical-fingerprint-helpers';
import {getBenchmarkData} from '../clinical-fingerprint-helpers';

@Component({
  selector: 'app-clinical-fingerprint-chart',
  templateUrl: './clinical-fingerprint-chart.component.html',
  styleUrls: ['./clinical-fingerprint-chart.component.scss']
})
export class ClinicalFingerprintChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input() barSelectionCallBack: (viewType: CptViewType, category: string, componentOrigination: string) => void;
  @Input() clinicalSummaryData: any[];
  @Input() showProgressBar: boolean;
  @Input() page: string;
  @Input() cptViewType: CptViewType;
  @Input() activeVarianceToggle = false;
  @Input() viewCommunityBenchmarks = false;
  @Input() showVariableMenu: boolean;
  @Input() showAdditionalCfpColumns: boolean;
  @Input() cfpVariableViewType: CfpVariableViewType;
  @Input() varianceColumn = 'cFTEAdjustedWrvusVariance';
  @Input() benchmarkColumn = 'workRvuBenchmark';
  @Input() sortingCriteria: SortingCriterion | undefined = undefined;

  @select(['display', 'cfpChargesDisplayed'])
  private readonly cfpChargesDisplayed$: Observable<boolean>;
  private cfpChargesDisplayed: boolean;

  @ViewChild('chartClinicalSummaryTarget', {static: true})
  public chartTarget: ElementRef;
  options: Options;
  chartObject: Chart;
  public legends: Legend[] = [];
  private varianceData: any[];

  private SNAPSHOT_BAR_COUNT = 20;
  private POINT_WIDTH = 28;
  private GROUP_PADDING = 0.33;
  private POINT_PADDING = 1.0;
  private CHART_WIDTH = (this.POINT_WIDTH * this.SNAPSHOT_BAR_COUNT) * (1 + this.GROUP_PADDING + this.POINT_PADDING);

  private barChartSeries: PlotColumnOptions = {
    cropThreshold: 100,
    pointPadding: this.POINT_PADDING,
    stacking: 'normal'
  };

  hasBenchmarks = false;
  showCfteAdjusted = false;
  showActualwRVU = false;
  showActualCount = false;
  showCfteAdjustedCount = false;
  cfpTitle: string;
  multipleProviders = false;
  multipleSpecialties = false;
  varianceSeries: any[];
  cfpChartDataSubscription: Subscription;

  constructor() {
  }

  private static lookForRepeatedAxisValues(arr: any[], fractionDigits: number, abbreviation:
    (value: number, fractionDigits: number) => string): boolean {
    for (let i = 0; i < arr.length - 1; i++) {
      if (abbreviation(arr[i], fractionDigits) === abbreviation(arr[i + 1], fractionDigits)) {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    this.cfpChartDataSubscription = this.cfpChargesDisplayed$.subscribe((cfpCharges: boolean) => {
      this.cfpChargesDisplayed = cfpCharges;
      this.ngOnChanges();
    });
  }

  ngOnDestroy(): void {
    this.cfpChartDataSubscription?.unsubscribe();
  }

  ngOnChanges(): void {
    switch (this.cfpVariableViewType) {
      case CfpVariableViewType.CfteAdjustedwRVU:
        this.showCfteAdjustedCount = false;
        this.showCfteAdjusted = true;
        this.showActualwRVU = false;
        this.showActualCount = false;
        this.cfpTitle = 'cFTE adj. wRVUs';
        this.legends = [deepCopyOf(cfteWrvuLegendForCfp), getCFPBenchmarkLegend(this.cfpVariableViewType, this.viewCommunityBenchmarks)];
        break;
      case CfpVariableViewType.ActualwRVU:
        this.showCfteAdjustedCount = false;
        this.showCfteAdjusted = false;
        this.showActualwRVU = true;
        this.showActualCount = false;
        this.cfpTitle = 'Actual wRVUs';
        this.legends = [deepCopyOf(actualWrvuLegendForCfp)];
        break;
      case CfpVariableViewType.ActualCount:
        this.showCfteAdjustedCount = false;
        this.showCfteAdjusted = false;
        this.showActualwRVU = false;
        this.showActualCount = true;
        this.cfpTitle = 'Count';
        this.legends = [deepCopyOf(countLegendForCfp)];
        break;
      case CfpVariableViewType.CfteAdjustedCount:
        this.showCfteAdjustedCount = true;
        this.showCfteAdjusted = false;
        this.showActualwRVU = false;
        this.showActualCount = false;
        this.cfpTitle = 'cFTE adj. Count';
        this.legends = [deepCopyOf(cftAdjCountLegendForCfp), getCFPBenchmarkLegend(this.cfpVariableViewType, this.viewCommunityBenchmarks)];
        break;
    }
    if (this.cfpChargesDisplayed) {
      this.legends = this.legends.concat([chargesLegendForCfp]);
    }
    if (this.activeVarianceToggle) {
      this.drawVarianceGraph(this.barSelectionCallBack);
    } else {
      this.drawGraph(this.barSelectionCallBack);
    }
    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  drawGraph(onCategorySelected: (viewType: CptViewType, category: string, componentOrigination: string) => void) {
    if (!this.clinicalSummaryData) {
      return;
    }
    const data: any[] = sortByDefaultTheData(this.clinicalSummaryData.slice(), this.cfpVariableViewType);

    const viewType: CptViewType = this.cptViewType;

    const clinicalSummaryCfteAdjustedWrvus = data.map(clinicalData => roundToNumber(clinicalData.cfteAdjustedWRVUs, 1) || 0);
    const cfteAdjustedCounts = data.map(clinicalData => roundToNumber(clinicalData.cfteAdjustedFrequency, 1) || 0);
    const cfpCharges = data.map(clinicalData => roundToNumber(clinicalData.charges, 1) || 0);
    const cfpCount = data.map(clinicalData => roundToNumber(clinicalData.wRVUs, 1) || 0);
    const cfteActualCount = data.map(clinicalData => roundToNumber(clinicalData.frequency, 1) || 0);
    const clinicalSummaryBenchmarks = getBenchmarkData(data, this.viewCommunityBenchmarks, this.cfpVariableViewType, true);

    this.hasBenchmarks = !!clinicalSummaryBenchmarks.find(benchmark => hasNonZeroValue(benchmark));

    const categories = createEmptyLabels(
      this.getLabels(data),
      DEFAULT_MAX_NUMBER_SCROLLBARS
    );

    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;
    const cfpChargesDisplayed = this.cfpChargesDisplayed;

    const interval = getInterval(clinicalSummaryCfteAdjustedWrvus, clinicalSummaryBenchmarks);
    const max = getMaximum(clinicalSummaryCfteAdjustedWrvus, clinicalSummaryBenchmarks);
    let hasRepeatedLabels = false;
    let roundDigits = 0;
    do {
      const axisNumbers = [];
      for (let x = 0; x < max / interval; x++) {
        axisNumbers.push((x + 1) * interval);
      }
      hasRepeatedLabels = ClinicalFingerprintChartComponent
        .lookForRepeatedAxisValues(axisNumbers, roundDigits, abbeviateNumberAfterRounding);
      if (hasRepeatedLabels) {
        ++roundDigits;
      }
    } while (hasRepeatedLabels && roundDigits <= 3);

    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: this.cfpTitle
        },
        max: max,
        min: 0,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return roundDigits === 0 ? abbreviateAxisValue(this.value) :
              trimTrailingZeroes(abbeviateNumberAfterRounding(this.value, roundDigits), (this.value + '').length);
          }
        },
        tickInterval: interval
      }
    ];

    let maxRight, minRight;
    if (cfpCharges.length > 0) {
      maxRight = Math.max(...cfpCharges) + 1;
      minRight = Math.min(...cfpCharges) + 1;
      if (this.cfpChargesDisplayed) {
        if (maxRight < 0) {
          maxRight *= -1;
        }
      }
    } else {
      maxRight = minRight = 0;
    }

    const rightSideYAxis: YAxisOptions[] = this.cfpChargesDisplayed ? [{
      title: {
        text: 'Charges'
      },
      max: maxRight,
      min: minRight > 0 ? 0 : minRight,
      opposite: true,
      labels: {
        formatter: function (this: AxisLabelsFormatterContextObject<any>) {
          return cfpChargesDisplayed ? abbreviateAxisValue(this.value) : '' +
            abbreviateAxisValue(this.value);
        }
      }
    }] : [];
    // @ts-ignore
    const cFTEAdjustedCountSeriesOptions: Highcharts.BarChartSeriesOptions[] = this.cFTEAdjustedCountDisplayed ||
    this.showCfteAdjustedCount ?
      [{
        name: 'cFTE adj. Count',
        yAxis: this.showAdditionalCfpColumns ? 0 : 1,
        type: this.showAdditionalCfpColumns ? ChartType.COLUMN : ChartType.LINE,
        data: cfteAdjustedCounts.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(cfteAdjustedCounts, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          cfteAdjustedCounts,
        stacking: 'normal',
        pointWidth: this.showAdditionalCfpColumns ? this.POINT_WIDTH : undefined,
        colorIndex: HighChartColorIndex.TEAL,
        events: {
          click: (event: PointClickEventObject) => {
            this.showAdditionalCfpColumns ?
              onCategorySelected(this.cptViewType, event.point.category.toString(), 'graph') :
              // @ts-ignore
              this.barSelectionCallback(event.point.drilldown);
          }
        },
        tooltip: {
          pointFormat: this.showAdditionalCfpColumns ? '<span class="highcharts-color-10">\u25CF</span> {series.name}:' +
            ' <b>{point.y:,.0f}</b><br/>' : '<span class="highcharts-color-15">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          }
        }
      }] : [];

    // @ts-ignore
    const cFTEActualCountSeriesOptions: Highcharts.BarChartSeriesOptions[] = this.showActualCount ?
      [{
        name: 'Count',
        yAxis: 0,
        type: ChartType.COLUMN,
        data: cfteActualCount.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(cfteActualCount, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          cfteActualCount,
        stacking: 'normal',
        colorIndex: HighChartColorIndex.TEAL,
        pointWidth: this.POINT_WIDTH,
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(this.cptViewType, event.point.category.toString(), 'graph');
          }
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      }] : [];

    // @ts-ignore
    const cfpChargesSeriesOptions: Highcharts.BarChartSeriesOptions[] = this.cfpChargesDisplayed && this.showAdditionalCfpColumns ?
      [{
        name: 'Charges',
        yAxis: 1,
        type: ChartType.LINE,
        data: cfpCharges.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(cfpCharges, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          cfpCharges,
        stacking: 'normal',
        colorIndex: HighChartColorIndex.ORANGE,
        events: {
          click: (event: PointClickEventObject) => {
            // @ts-ignore
            this.barSelectionCallback(event.point.drilldown);
          }
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-15">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      }] : [];

    // @ts-ignore
    const benchmarkSeriesOptions: Highcharts.BarChartSeriesOptions[] = [
      {
        name: getDisplayTextForCfpVariableTypeWithBenchmarkOption(this.cfpVariableViewType, this.viewCommunityBenchmarks),
        yAxis: 0,
        data: createEmptyValues(clinicalSummaryBenchmarks, DEFAULT_MAX_NUMBER_SCROLLBARS),
        type: ChartType.LINE,
        colorIndex: HighChartColorIndex.GREY,
        pointWidth: this.POINT_WIDTH,
        cursor: this.cptViewType !== CptViewType.CptCode ? 'pointer' : 'default',
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(viewType, getCptDescription(viewType, data[event.point.index]), 'graph');
          }
        },
        stack: 0,
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:,.1f}</b><br/>'
        },
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        }
      }
    ];

    // @ts-ignore
    const cfteAdjustedWrvusSeriesOptions: Highcharts.BarChartSeriesOptions[] = this.showCfteAdjusted ? [
      {
        name: 'cFTE adj. wRVUs',
        yAxis: 0,
        data: clinicalSummaryCfteAdjustedWrvus.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(clinicalSummaryCfteAdjustedWrvus, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          clinicalSummaryCfteAdjustedWrvus,
        type: ChartType.COLUMN,
        colorIndex: HighChartColorIndex.TEAL,
        pointWidth: this.POINT_WIDTH,
        cursor: this.cptViewType !== CptViewType.CptCode ? 'pointer' : 'default',
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(viewType, getCptDescription(viewType, data[event.point.index]), 'graph');
          }
        },
        stack: 0,
        zIndex: 0,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        },
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        }
      }
    ] : [];

    // @ts-ignore
    const cfteActualWrvusSeriesOptions: Highcharts.BarChartSeriesOptions[] = this.showActualwRVU ? [
      {
        name: 'Actual wRVUs',
        yAxis: 0,
        data: cfpCount.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(cfpCount, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          cfpCount,
        type: ChartType.COLUMN,
        colorIndex: HighChartColorIndex.TEAL,
        pointWidth: this.POINT_WIDTH,
        cursor: this.cptViewType !== CptViewType.CptCode ? 'pointer' : 'default',
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(viewType, getCptDescription(viewType, data[event.point.index]), 'graph');
          }
        },
        stack: 0,
        zIndex: 0,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        },
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        }
      }
    ] : [];

    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: concat(
        cfteAdjustedWrvusSeriesOptions,
        cfteActualWrvusSeriesOptions,
        this.showAdditionalCfpColumns ? cFTEAdjustedCountSeriesOptions : [],
        cFTEActualCountSeriesOptions,
        isBenchmarkRequired(this.cfpVariableViewType) ? benchmarkSeriesOptions : [],
        !this.showAdditionalCfpColumns ? cFTEAdjustedCountSeriesOptions : cfpChargesSeriesOptions
      ),
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 1
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.CHART_WIDTH,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground
          // cursor: this.cptViewType !== CptViewType.CptCode ? 'pointer' : 'default'
        },
        // @ts-ignore
        clickOnCrosshair: function (e: any, p: any) {
          onCategorySelected(viewType, getCptDescription(viewType, data[p.index]), 'graph');
        },
        max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS : categories.length - 1,
        min: 0,
        labels: {
          rotation: 45
        }
      },
      yAxis: leftSideYAxis.concat(rightSideYAxis),
      scrollbar: {
        // @ts-ignore
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries,
          tooltip: {
            pointFormat: ''
          }
        }
      }
    };

    if (this.cptViewType === CptViewType.CptCode && this.options.xAxis) {
      // @ts-ignore
      delete this.options.xAxis.clickOnCrosshair;
    }
  }

  getLabels(data: any[]): string[] {

    if (this.cptViewType === CptViewType.CptCode) {
      return data.map((clinicalData) => clinicalData.cptCode + ' ' + clinicalData.cptDesc);
    }

    if (this.cptViewType === CptViewType.CptRange) {
      return data.map((clinicalData) => clinicalData.cptRangeLow + '-' + clinicalData.cptRangeHigh +
        ' ' + clinicalData.cptRangeDesc);
    }

    return data.map((clinicalData) => clinicalData.cptFamilyDesc);
  }

  drawVarianceGraph(onCategorySelected: (viewType: CptViewType, category: string, componentOrigination: string) => void) {
    let max = 0;
    let min = 0;
    this.varianceData = this.clinicalSummaryData.slice().filter(datum => hasNonZeroValue(datum[this.benchmarkColumn]));
    this.hasBenchmarks = !!this.varianceData.length;
    if (!this.varianceData) {
      return;
    }

    const varianceData: any[] = [];
    let categories: string[];

    this.varianceData.forEach(cfp => {
      const varianceValue = roundToNumber(this.getVarianceGraphData(cfp), 0);
      if (varianceValue && varianceValue !== 0 && varianceValue > max) {
        max = varianceValue;
      } else if (varianceValue && varianceValue !== 0 && varianceValue < min) {
        min = varianceValue;
      }
      varianceData.push({
        name: this.getCfpVarianceGraphName(cfp),
        y: varianceValue,
        colorIndex: varianceValue ? (varianceValue > 0 ? HighChartColorIndex.GREEN : HighChartColorIndex.RED)
          : HighChartColorIndex.TRANSPARENT,
        tooltip: varianceValue ? varianceValue > 0 ? {
          pointFormat: '<span class="highcharts-color-12">\u25CF</span> ' +
            '{series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        } : {
          pointFormat: '<span class="highcharts-color-22">\u25CF</span> ' +
            '{series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        } : HighChartColorIndex.TRANSPARENT
      });
    });

    categories = createEmptyLabels(
      this.getLabels(this.varianceData),
      DEFAULT_MAX_NUMBER_SCROLLBARS
    );

    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;

    this.legends = getCFPVarianceLegends(this.cfpVariableViewType, this.viewCommunityBenchmarks);
    const absoluteMax = roundToNumber(Math.max(...[Math.abs(max), Math.abs(min)]), 0);
    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: 'cFTE adj. wRVUs Variance'
        },
        max: absoluteMax,
        min: -1 * absoluteMax,
        tickInterval: absoluteMax / 5,
        labels: {
          formatter: function () {
            return roundToNumber(this.value, 0) + '';
          }
        }
      }
    ];

    this.varianceSeries = [
      {
        name: 'cFTE adj. wRVUs Variance',
        yAxis: 0,
        type: ChartType.COLUMN,
        data: varianceData.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(varianceData, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          varianceData,
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(this.cptViewType, event.point.category.toString(), 'graph');
          }
        },
        pointWidth: this.POINT_WIDTH,
        stacking: 'normal',
        stack: 0,
        zIndex: 0,
        turboThreshold: varianceData.length < 1000 ? 1000 : varianceData.length
      }
    ];

    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: this.varianceSeries,
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 0
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        width: 1304.8,
        styledMode: true,
        height: CHART_HEIGHT
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: categories,
        max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return toTitleCase(this.value.toString());
          },
          rotation: 45
        }
      },
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries,
          minPointLength: 1
        }
      }
    };
  }

  getCfpVarianceGraphName(cfp: any): string {
    switch (this.cptViewType) {
      case CptViewType.CptFamily:
        return cfp.cptFamilyDesc;
      case CptViewType.CptRange:
        return cfp.cptRangeLow + '-' + cfp.cptRangeHigh +
          ' ' + cfp.cptRangeDesc;
      case CptViewType.CptCode:
        return cfp.cptCode + ' ' + cfp.cptDesc;
      default:
        return '';
    }
  }

  getVarianceGraphData(cfp: any): number | undefined {
    return this.viewCommunityBenchmarks ? cfp.communityCfteAdjustedWrvusVariance : cfp.cFTEAdjustedWrvusVariance;
  }
}

function getInterval(arr1: any[], arr2: any[]) {
  const max = Math.max(...arr1, ...arr2);
  return Math.round(max / 9);
}

function getMaximum(arr1: any[], arr2: any[]) {
  return Math.max(...arr1, ...arr2);
}
