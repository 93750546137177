<app-payer-collections-chart
  [showProgressBar]="showProgressBar"
  [showVariableMenu]="true"
  [payerSelectionCallBack]="payerSelectionCallBack"
  [page]="level"
  [activeVarianceToggle]="activeVarianceToggle"
  [sortingCriteria]="varianceToggleSortingCriterion"
  [variables]="variables"
></app-payer-collections-chart>

<app-data-table id="payer-collections-data-table"
                [showProgressBar]="showProgressBar"
                [receivedData]="payerCollectionsData"
                [displayedColumns]="displayedColumns"
                [defaultSortColumn]="defaultSortColumn"
                [sortingDataAccessor]="sortingDataAccessor"
                [tableCssClass]="'payer-collection-table'"
                [showLevel]="false"
                [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                [sortDirection]="sortDirection"
                tableTitle="Collection Analysis by {{payerLevel}}"
                [whenSortChanged]="whenSortChanged"
                [collectionsTab]="true"
                [originalColumns]="columns"
                [currentPage]="currentPage"
                [showPayerOptions]="false"
                [addRemoveOption]="true"
                [reducerAction]="snapshotPayerCollectionsColumnsChangedTo"
>
</app-data-table>

<div [ngSwitch]="chosenTab">
  <div *ngSwitchDefault>
    <app-data-table
      id="payer-by-level-collections-department-data-table"
      tableTitle="Collection Analysis by {{payerLevel}} by Department"
      [receivedData]="departmentPayerCollectionsData"
      [displayedColumns]="displayedDepartmentColumns"
      [originalColumns]="departmentColumns"
      [defaultSortColumn]="defaultSortColumn"
      [tableCssClass]="'payer-by-level-collection-table'"
      sortDirection="desc"
      [showPayerOptions]="true"
      [addRemoveOption]="true"
      [shouldPutNullsAtTheBottom]="true"
      [scrollableColumns]="scrollableMultiLevelColumns"
      [showProgressBar]="showProgressBarMultiLevel"
      [multiLevel]="true"
      [reducerAction]="reducerAction"
      [chosen]="chosenTab"
      [displayZeroSuppression]="true"
      [zeroSuppressionCondition]="zeroSuppressTooltip"
      [countOfSuppressedEntries]="howManyDepartmentsSuppressed"
      [isZeroSuppressionChecked]="isZeroSuppressed"
      [zeroSuppressionSnapshotEntry]="howManyDepartmentsSuppressed != 1 ? 'departments' : 'department'"
      (currentTab)="chooseTab($event)"
      [initialPageSize]=10
    ></app-data-table>
  </div>
  <div *ngSwitchCase="MultilevelTabCollections.BY_PAYER_BY_SPECIALTY">
    <app-data-table
      id="payer-by-level-collections-specialty-data-table"
      tableTitle="Collection Analysis by {{payerLevel}} by Specialty"
      [receivedData]="specialtyPayerCollectionsData"
      [displayedColumns]="displayedSpecialtyColumns"
      [showProgressBar]="showProgressBarMultiLevel"
      [originalColumns]="specialtyColumns"
      [openWindowColumns]="openWindowColumns"
      [defaultSortColumn]="defaultSortColumn"
      [tableCssClass]="'payer-by-level-collection-table'"
      sortDirection="desc"
      [showPayerOptions]="true"
      [addRemoveOption]="true"
      [shouldPutNullsAtTheBottom]="true"
      [scrollableColumns]="scrollableMultiLevelColumns"
      [reducerAction]="reducerAction"
      [multiLevel]="true"
      [chosen]="chosenTab"
      [displayZeroSuppression]="true"
      [zeroSuppressionCondition]="zeroSuppressTooltip"
      [countOfSuppressedEntries]="howManySpecialtiesSuppressed"
      [isZeroSuppressionChecked]="isZeroSuppressed"
      [zeroSuppressionSnapshotEntry]="howManySpecialtiesSuppressed != 1 ? 'specialties' : 'specialty'"
      (currentTab)="chooseTab($event)"
      [initialPageSize]=10
    ></app-data-table>
  </div>
  <div *ngSwitchCase="MultilevelTabCollections.BY_PAYER_BY_PROVIDER">
    <app-data-table
      id="payer-by-level-collections-provider-data-table"
      tableTitle="Collection Analysis by {{payerLevel}} by Provider"
      [receivedData]="providerPayerCollectionsData"
      [showProgressBar]="showProgressBarMultiLevel"
      [displayedColumns]="displayedProviderColumns"
      [originalColumns]="providerColumns"
      [openWindowColumns]="openWindowColumns"
      [defaultSortColumn]="defaultSortColumn"
      [tableCssClass]="'payer-by-level-collection-table'"
      sortDirection="desc"
      [showPayerOptions]="true"
      [addRemoveOption]="true"
      [shouldPutNullsAtTheBottom]="true"
      [scrollableColumns]="scrollableMultiLevelColumns"
      [reducerAction]="reducerAction"
      [multiLevel]="true"
      [chosen]="chosenTab"
      [displayZeroSuppression]="true"
      [zeroSuppressionCondition]="zeroSuppressTooltip"
      [countOfSuppressedEntries]="howManyProvidersSuppressed"
      [isZeroSuppressionChecked]="isZeroSuppressed"
      [zeroSuppressionSnapshotEntry]="howManyProvidersSuppressed != 1 ? 'providers' : 'provider'"
      (currentTab)="chooseTab($event)"
      [initialPageSize]=10
    ></app-data-table>
  </div>
</div>
