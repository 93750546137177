import {
  AxisLabelsFormatterContextObject,
  ChartOptions,
  LangOptions,
  LegendOptions,
  Options,
  OptionsStackingValue,
  PlotColumnOptions,
  PlotOptions,
  TitleOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts/highstock';
import {concat, flatMap} from './ourLodash';
import {GraphColors} from '../productivity-summary/colors';
import {toTitleCase} from './helpers';
import {abbreviateAxisValue, roundToNumber} from '../productivity-summary/number-formatter';
import {BarChartSeriesOptions, IndividualSeriesOptions} from './highcharts-mappings';
import {Chart} from 'angular-highcharts';
import {CreditsOptions} from 'highcharts';

export class StyledChart extends Chart {

  constructor(options: Options) {
    super({
      ...options,
      chart: {
        ...options.chart, styledMode: true
      }
    });
  }
}

export function createEmptyValues(seriesData: any[], maxNumberOfDataPointsForScrollBar: number, emptyValue: any = null) {
  for (let i = seriesData.length; i < maxNumberOfDataPointsForScrollBar; i++) {
    seriesData.push(emptyValue);
  }
  return seriesData;
}

export function createEmptyLabels(seriesLabel: string[], maxNumberOfDataPointsForScrollBar: number) {
  for (let i = seriesLabel.length; i < maxNumberOfDataPointsForScrollBar; i++) {
    seriesLabel.push('');
  }
  return seriesLabel;
}

export function maxOfSeries(series: IndividualSeriesOptions[]): number {
  return Math.max(...flatMap(series, (theSeries) => {
    // @ts-ignore
    return theSeries.data?.map((x: any[]) => typeof x === 'number' ? x : ((x && x[1]) || 0)) as number[];
  }));
}

export function roundToNearestMultiple(value: number, multiple: number): number {
  let result = multiple * Math.ceil(value / multiple);

  if (result === 0) {
    result = multiple;
  }
  return result;
}

export function setTickValue(value: number): number {
  let result = Math.ceil(value / 5);
  if (value === 0) {
    result = 20;
  }
  return result;
}

export function getTitleOptions(text: string): TitleOptions {
  return {
    text: text,
  };
}

export function getTooltipOptions(shared: boolean,
                                  valueDecimals: number,
                                  pointFormat: string): TooltipOptions {
  return {
    shared: shared,
    valueDecimals: valueDecimals,
    pointFormat: '',
  };
}

export function getChartOptions(type: chartType,
                                bgColor: backgroundColor,
                                width: number,
                                height: number): ChartOptions {
  return {
    type: type,
    backgroundColor: bgColor,
    width: width,
    height: height
  };
}

export function getXaxisOptions(categories: any,
                                isScrolled: boolean,
                                maxNumberScrollbars: number): XAxisOptions {
  return {
    categories: categories,
    crosshair: {
      color: GraphColors.hoverBackground,
    },
    max: isScrolled ? maxNumberScrollbars - 1 : categories.length - 1,
    labels: {
      formatter: function (this: AxisLabelsFormatterContextObject<any>) {
        return toTitleCase(this.value.toString());
      },
      rotation: 45,
    },
  };
}

export function getYaxisOptions(leftSideYAxis: YAxisOptions[], rightSideYAxis: YAxisOptions[]): YAxisOptions[] {
  return concat(leftSideYAxis, rightSideYAxis);
}

export enum FormatOption {
  Percentage = 'percentage',
  Number = 'number'
}

export function getYAxisOption(title: string, maxValue: number, format: FormatOption): YAxisOptions[] {
  return [
    {
      title: {
        text: title,
      },
      max: maxValue,
      labels: {
        formatter: function () {
          return format === FormatOption.Percentage ? this.value + '%' :
            abbreviateAxisValue(this.value);
        },
      }
    },
  ];
}

export function getRightSideYAxisOptions(title: string,
                                         opposite: boolean,
                                         maxValue: number,
): YAxisOptions[] {
  return [{
    title: {
      text: title,
    },
    opposite: opposite,
    max: maxValue,
    labels: {
      formatter: function () {
        return abbreviateAxisValue(this.value);
      },
    }
  }];
}

export function getLeftSideYAxisOptions(title: string,
                                        maxValue: number,
                                        minValue: number,
                                        tickInterval: number,
                                        tickIntervalDefault: number): YAxisOptions[] {
  return [
    {
      title: {
        text: title,
      },
      max: maxValue,
      min: minValue,
      tickInterval: tickInterval || tickIntervalDefault,
      labels: {
        formatter: function () {
          return this.value + '%';
        },
      }
    },
  ];
}

export function getPlotOptions(barChartSeries: PlotColumnOptions, tooltipOptions: TooltipOptions): PlotOptions {
  return {
    column: {
      ...barChartSeries,
      tooltip: tooltipOptions
    }
  };
}

export function getBarChartSeries(cropThreshold: number, pointPadding: number, stacking: OptionsStackingValue): PlotColumnOptions {
  return {
    cropThreshold: cropThreshold,
    pointPadding: pointPadding,
    stacking: stacking
  };
}

export const DEFAULT_LANG_OBJECT: LangOptions = {
  decimalPoint: '.',
  thousandsSep: ','
};
export const BLANK_TITLE_OBJECT: TitleOptions = {text: ''};
export const DEFAULT_TOOLTIP_OBJECT: TooltipOptions = {
  shared: true,
  valueDecimals: 1,
};

export const DEFAULT_CREDITS_OBJECT: CreditsOptions = {
  enabled: false
};


export function getBarchartSeriesOption(barOptions: BarChartOptions, animation = true): BarChartSeriesOptions {
  return {
    animation: animation,
    name: barOptions.name,
    yAxis: barOptions.yAxis,
    data: barOptions.data,
    type: barOptions.type,
    colorIndex: barOptions.colorIndex,
    pointWidth: barOptions.pointWidth,
    stack: barOptions.stack,
    zIndex: barOptions.zIndex,
    tooltip: barOptions.tooltip,
    marker: {
      symbol: barOptions.marker.symbol
    }
  };
}

export function getLegendOptions(enable: boolean): LegendOptions {
  return {
    enabled: enable,
  };
}

export enum chartType {
  Line = 'line',
  Column = 'column'
}

export enum backgroundColor {
  Transparent = 'transparent',
}

export interface BarChartOptions {
  name: string;
  yAxis: number;
  type: chartType;
  data: any;
  colorIndex: number;
  pointWidth: number;
  stack: number;
  zIndex: number;
  tooltip?: TooltipOptions;
  marker?: any;
}

export const xAxisLabels = {
  formatter: function (this: AxisLabelsFormatterContextObject<any>) {
    let ret = '';
    const tokenized = this.value.toLocaleString().split(' ');
    for (let i = 0; i < tokenized.length; i++) {
      ret = ret.concat(tokenized[i] + ' ');
      if (i === roundToNumber(tokenized.length / 2, 0) - 1) {
        ret = ret.concat('<br/>');
      }
    }
    return ret;
  },
  rotation: 45,
};

export const DEFAULT_POINT_WIDTH = 28;
export const DEFAULT_POINT_PADDING = 1.0;
export const DEFAULT_GROUP_PADDING = 0.33;

export function calculateChartWidth(pointWidth: number, snapshotBarCount: number, pointPadding: number) {
  return (pointWidth * snapshotBarCount) * (pointPadding + DEFAULT_GROUP_PADDING + 1);
}

export const DEFAULT_MAX_NUMBER_SCROLLBARS = 20;

export enum ChartMarkerSymbol {
  CIRCLE = 'circle',
  SQUARE = 'square',
  DIAMOND = 'diamond'
}

export enum ChartType {
  LINE = 'line',
  COLUMN = 'column',
  WATERFALL = 'waterfall'
}

