import {DisplayField, Variable} from '../variable-container/variable-container.component';
import {AppAction, cFPChargesDisplayChangedTo, cFTEAdjustedCountDisplayChangedTo} from '../store/actions';
import {EmDimension, InvoiceStatus, VisitType} from './models';
import {CptViewType, FrontEndTelehealthFlag, MultilevelTab, MultilevelTabCollections} from './enums';
import {ProductivityMultiLevelSnapshot} from '../productivity-summary/services/ProviderProductivity';
import {TabNavigationElement} from '../tab-navigation/tab-helper';

export const INITIAL_PRODUCTIVITY_MULTILEVEL_SNAPSHOT: ProductivityMultiLevelSnapshot = {
  id: -1,
  nodeId: 0,
  nodeName: '',
  nodePath: '',
  providerNodeId: 0,
  providerNodeName: '',
  providerNodePath: '',
  specialtyNodeId: 0,
  specialtyNodeName: '',
  specialtyNodePath: '',
  departmentNodeId: 0,
  departmentNodeName: '',
  departmentNodePath: '',
  cfte: 0,
  charges: 0,
  wRVUs: 0,
  cfteAdjustedWRVUs: 0,
  benchmark25thPercentile: 0,
  benchmark50thPercentile: 0,
  benchmark75thPercentile: 0,
  benchmark90thPercentile: 0,
  benchmarkMean: 0,
  benchmark65thPercentile: 0,
  benchmarkPercentile: 0,
  communityBenchmark25thPercentile: 0,
  communityBenchmark50thPercentile: 0,
  communityBenchmark75thPercentile: 0,
  communityBenchmark90thPercentile: 0,
  communityBenchmarkMean: 0,
  communityBenchmark65thPercentile: 0,
  imputedReportedcFTE25thPercentile: 0,
  imputedReportedcFTE50thPercentile: 0,
  imputedReportedcFTE65thPercentile: 0,
  imputedReportedcFTE75thPercentile: 0,
  imputedReportedcFTEMean: 0,
  imputedReportedcFTE90thPercentile: 0,
  varianceMean: 0,
  variance25thPercentile: 0,
  variance50thPercentile: 0,
  variance65thPercentile: 0,
  variance75thPercentile: 0,
  variance90thPercentile: 0,
  communityVarianceMean: 0,
  communityVariance25thPercentile: 0,
  communityVariance50thPercentile: 0,
  communityVariance65thPercentile: 0,
  communityVariance75thPercentile: 0,
  communityVariance90thPercentile: 0,
  communityImputedReportedcFTE25thPercentile: 0,
  communityImputedReportedcFTE50thPercentile: 0,
  communityImputedReportedcFTE65thPercentile: 0,
  communityImputedReportedcFTE75thPercentile: 0,
  communityImputedReportedcFTEMean: 0,
  communityImputedReportedcFTE90thPercentile: 0,
  specialtyPerformanceActualWrvus: 77,
  specialtyPerformanceCfteAdjWrvus: 8888,
  specialtyPerformanceActualWrvuspVariance: 2022,
  specialtyPerformanceCfteAdjWrvuspVariance: 4487
};

export const CFP_VARIABLES: Variable[] = [{
  name: 'cFTE adj. Count',
  display: false,
  reducerField: DisplayField.CFTEAdjustedCount,
  dispatchAction(display: boolean): AppAction {
    return cFTEAdjustedCountDisplayChangedTo(display);
  }
},
  {
    name: 'Charges',
    display: false,
    reducerField: DisplayField.CFPCharges,
    dispatchAction(display: boolean): AppAction {
      return cFPChargesDisplayChangedTo(display);
    }
  }];

export const DEPARTMENT_TAB: TabNavigationElement = {
  dimension: MultilevelTab.BY_DEPARTMENT,
  selector: 'by-department-tab',
  label: 'By  Department'
};

export const SPECIALTY_TAB: TabNavigationElement = {
  dimension: MultilevelTab.BY_SPECIALTY,
  selector: 'by-specialty-tab',
  label: 'By  Specialty',
  disabled: false
};

export const PROVIDER_TAB: TabNavigationElement = {
  dimension: MultilevelTab.BY_PROVIDER,
  selector: 'by-provider-tab',
  label: 'By  Provider',
  disabled: true
};

export const LOCATION_DEPARTMENT_TAB: TabNavigationElement = {
  dimension: MultilevelTab.LOCATION_DEPARTMENT,
  selector: 'by-location-department-tab',
  label: 'Location by Department'
};

export const LOCATION_SPECIALTY_TAB: TabNavigationElement = {
  dimension: MultilevelTab.LOCATION_SPECIALTY,
  selector: 'by-location-specialty-tab',
  label: 'Location by Specialty',
  disabled: false
};

export const LOCATION_PROVIDER_TAB: TabNavigationElement = {
  dimension: MultilevelTab.LOCATION_PROVIDER,
  selector: 'by-location-provider-tab',
  label: 'Location by Provider',
  disabled: false
};

export const FAMILY_TAB: TabNavigationElement = {
  dimension: CptViewType.CptFamily,
  selector: 'by-family-tab',
  label: 'By CPT Family'
};

export const RANGE_TAB: TabNavigationElement = {
  dimension: CptViewType.CptRange,
  selector: 'by-range-tab',
  label: 'By CPT Range',
  disabled: false
};

export const CODE_TAB: TabNavigationElement = {
  dimension: CptViewType.CptCode,
  selector: 'by-code-tab',
  label: 'By CPT Code',
  disabled: false
};

// seperate
export const DEPARTMENT_TAB_COLLECTION: TabNavigationElement = {
  dimension: MultilevelTabCollections.BY_DEPARTMENT,
  selector: 'by-department-tab',
  label: 'By  Department'
};

export const SPECIALTY_TAB_COLLECTION: TabNavigationElement = {
  dimension: MultilevelTabCollections.BY_SPECIALTY,
  selector: 'by-specialty-tab',
  label: 'By  Specialty',
  disabled: false
};

export const PROVIDER_TAB_COLLECTION: TabNavigationElement = {
  dimension: MultilevelTabCollections.BY_PROVIDER,
  selector: 'by-provider-tab',
  label: 'By  Provider',
  disabled: true
};
export const EM_OUTPATIENT_TAB: TabNavigationElement = {
  dimension: EmDimension.Outpatient,
  selector: 'outpatient-tab',
  label: 'Outpatient',
  disabled: false
};
export const EM_INPATIENT_TAB: TabNavigationElement = {
  dimension: EmDimension.InPatient,
  selector: 'inpatient-tab',
  label: 'Inpatient',
  disabled: false
};
export const EM_EMERGENCY_TAB: TabNavigationElement = {
  dimension: EmDimension.EmergencyMedicine,
  selector: 'emergency-tab',
  label: 'Emergency Medicine',
  disabled: false
};
export const EM_OPHTHALMOLOGY_TAB: TabNavigationElement = {
  dimension: EmDimension.Ophthalmology,
  selector: 'ophthalmology-tab',
  label: 'Ophthalmology',
  disabled: false
};

export const SPECIALTY_PERFORMANCE_TAB: TabNavigationElement = {
  dimension: MultilevelTab.SPECIALTY_PERFORMANCE,
  selector: 'specialty-performance-tab',
  label: 'Specialty Performance',
  disabled: false
};

export const TREND_TAB: TabNavigationElement = {
  dimension: MultilevelTab.TREND,
  selector: 'trend-tab',
  label: 'Trend',
  disabled: false
};

export const LOCATION_TAB: TabNavigationElement = {
  dimension: MultilevelTab.LOCATION_DEPARTMENT,
  selector: 'location-tab',
  label: 'Location',
  disabled: false
};

export const PAYER_TAB: TabNavigationElement = {
  dimension: MultilevelTabCollections.BY_PAYER,
  selector: 'byPayerTab',
  label: 'By Payer',
  disabled: false
};

export const invoiceStatusTypes: InvoiceStatus[] = [
  {
    statusId: 0,
    statusText: 'All Invoices',
    all: true
  },
  {
    statusId: 1,
    statusText: 'Open'
  },
  {
    statusId: 2,
    statusText: 'Closed'
  }
];

export const visitTypes: VisitType[] = [
  {
    key: FrontEndTelehealthFlag.ALL_VISIT_TYPES,
    text: 'All Visit Types',
    all: true
  },
  {
    key: FrontEndTelehealthFlag.IN_PERSON,
    text: 'In-person'
  },
  {
    key: FrontEndTelehealthFlag.TELEHEALTH,
    text: 'Telehealth'
  }
];

export const MIN_SEARCH_CHARS = 3;

export function getDefaultHoverIndex(): number {
  return -1;
}

