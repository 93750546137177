import {Injectable, InjectionToken} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {Subproduct} from '../shared/models';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

export const SubproductServiceToken = new InjectionToken<SubproductService>('Subproduct Service');

export interface SubproductService {
  getSubproducts(): Observable<Subproduct[]>;
}

@Injectable()
export class SubproductServiceImpl implements SubproductService {

  constructor(private readonly httpClient: HttpClient) {}

  getSubproducts(): Observable<Subproduct[]> {
      const url = `${environment.api}/api/getSubproducts`;
      return this.httpClient.get<Subproduct[]>(url, {}).pipe(catchError(error => of(error)));
  }
}

