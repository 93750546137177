import {MockNgRedux} from '@angular-redux/store/testing';
import {
  CurrentSelections,
  DrillDown,
  FilterCriteria,
  IAppState,
  OntologyData,
  SummaryData,
  UserPreference
} from '../../store/IAppState';
import {
  CfpVariableViewType,
  getMonthFromNumberOfMonths,
  getYearFromNumberOfMonths,
  MonthNumber,
  WrvuViewType
} from '../helpers';
import {HttpRequest} from '@angular/common/http';
import {
  ProductivityMultiLevelSnapshot,
  ProductivitySnapshot,
  ProductivitySummary,
  ProviderProductivity,
  ProviderProductivityExport,
  ProviderProductivityMultiLevelData,
  ProviderProductivityMultiLevelResponse
} from '../../productivity-summary/services/ProviderProductivity';
import {
  ApplicationConfigurationSetting,
  BatchExportDetail,
  BatchExportDetailCriteria,
  BatchExportDetailResponse,
  BatchExportDetails,
  BatchExportHeader,
  BatchExportReportDetailsResponseEntity,
  BatchFilterCriteria,
  Consultation,
  CptCategories,
  CptSummary,
  CustomGroupsDataAllFilters,
  EmDimension,
  EmergencyMedicine,
  EstablishedPatientVisit,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  EyeExamEstablishedPatient,
  EyeExamNewPatient,
  FeatureToggleSetting,
  HierarchicalSelectableNode,
  HospitalDischargeDay,
  InitialHospitalCare,
  MemberBillingArea,
  MemberLocation,
  MemberPayerLevel,
  Members,
  MergedProductivityTrendEntry,
  MonthNewPatientVisitsExport,
  MonthProductivity,
  MonthProductivityData,
  MonthProductivityEntry,
  MonthProductivityExport,
  NationalPayerLevel,
  NewPatientVisit,
  NewPatientVisitSummary,
  OntologyNode,
  PayerCategory,
  PayerCategoryLevel,
  SelectableMemberBillingArea,
  SelectableNode,
  SubsequentHospitalCare,
  UserProfile,
  ZipCodeNewPatientVisits
} from '../models';
import {
  DEFAULT_CUSTOM_GROUP_ID,
  DEFAULT_INVOICE_STATUS,
  DEFAULT_LAG_KEY,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_KEY,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_PAYER_TYPE,
  DEFAULT_TELEHEALTH_FLAG,
  DEFAULT_USER_PREFERENCES,
  DEFAULT_YEAR
} from '../../store/DefaultValues';
import {OverviewChartOptions} from '../../overview/models/overview-models';
import {
  CfpByMultilevel,
  ClinicalSummaryCode,
  ClinicalSummaryFamily,
  ClinicalSummaryRange
} from '../../clinical-fingerprint/ClinicalSummary';
import {
  BatchExportStatus,
  BenchmarkOption,
  CptViewType,
  DateRangeOption,
  MultilevelTab,
  MultilevelTabCollections,
  OntologyLevel,
  PayerType,
  SortingOrder
} from '../enums';
import {
  CollectionsByPayerGroupedByLevel,
  CollectionsSummaryForAllTypes,
  MatchedCollectionsByMultiLevelByNodePath,
  MatchedCollectionsMultiLevelNodePathData,
  PayerByMultiLevelCollectionsByNodePath,
  PayerCollections,
  PayerCollectionsData,
  PayerMatchedCollectionsMultiLevelNodePathData
} from '../../collections/Collection';
import {
  getCollectionsColumns,
  getDenialsColumns,
  getDepartmentMultiLevelByPayerCollectionsColumns,
  getDepartmentMultiLevelCollectionsColumns,
  getNpvByDepartmentColumns,
  getNpvByProviderColumns,
  getNpvBySpecialtyColumns,
  getNpvSnapshotColumns,
  getNpvTrendColumns,
  getProviderMultiLevelByPayerCollectionsColumns,
  getProviderMultiLevelCollectionsColumns,
  getSpecialtyMultiLevelByPayerCollectionsColumns,
  getSpecialtyMultiLevelCollectionsColumns,
  getWRVUProviderColumns,
  getWRVUSnapshotColumns,
  getWRVUSpecialtyPerformanceColumns,
  getWRVUTrendColumns
} from '../../column-selection/DisplayedColumnsHelper';
import {BenchmarkPercentile} from '../benchmark-types';
import {Type} from '@angular/core';
import {By} from '@angular/platform-browser';
import {ComponentFixture} from '@angular/core/testing';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedProviderNewPatientVisitMultiLevelData,
  MonthNewPatientVisitData,
  MonthNewPatientVisitEntry,
  NewPatientVisitTrendEntry
} from '../../new-patient-visits/components/npv-models';
import {ChartType} from '../highcharts-helpers';
import {CptGroup} from '../../clinical-fingerprint/procedure-summary/ProcedureSummaryModels';
import {
  DenialCptCodeByReasonEntity,
  DenialPayerEntity,
  DenialReasonEntity,
  DenialsData,
  DenialsEntity,
  DenialsMultiLevelData,
  DenialsMultiLevelEntity,
  DenialsPayerData
} from '../../denials/denials-models';
import {aDefaultNewPatientVisitsLocationVariables} from '../../new-patient-visits/components/npv-models.spec';

export function getOntologyDataFromOntologyHierarchy(ontologyHierarchy: OntologyNode[]): OntologyData {
  const departmentTags: Set<string> = new Set<string>();
  const specialtyTags: Set<string> = new Set<string>();
  const providerTags: Set<string> = new Set<string>();
  if (ontologyHierarchy.length >= 1) {
    ontologyHierarchy[0].children.forEach(d => {
      departmentTags.add(d.nodePath.split('\\')[2]);
      d.children.forEach(s => {
        specialtyTags.add(s.nodePath.split('\\')[3]);
        s.children.forEach(p => {
          providerTags.add(p.nodePath.split('\\')[4]);
        });
      });
    });
  }
  return {
    ontologyHierarchy: ontologyHierarchy,
    numberOfDepartments: departmentTags.size,
    numberOfProviders: providerTags.size,
    numberOfSpecialties: specialtyTags.size
  };
}

export const getComponents = function (fixture: ComponentFixture<any>, component: Type<any>): any[] {
  return fixture.debugElement.queryAll(By.directive(component)).map(x => x.componentInstance);
};

export const setFilterInRedux = function (filter: FilterCriteria) {
  MockNgRedux.getSelectorStub(['filters']).next(filter);
  return filter;
};

export const setBenchmarkPercentileState = function (benchmark: BenchmarkPercentile = BenchmarkPercentile.Mean) {
  MockNgRedux.getSelectorStub(['benchmark']).next(benchmark);
};

export const getRandomInt = function (max: number) {
  return Math.floor(Math.random() * Math.floor(max));
};

export const aMemberLocation = function (): MemberLocation {
  return {
    memberLocationKey: 1,
    memberLocationCode: 'hardCoded',
    memberLocationName: 'hardCoded1'
  };
};

export const aPayerCategory = function (): PayerCategory {
  return {
    payerCategoryKey: 2,
    payerCategoryCode: 'hardCoded2',
    payerCategoryDescription: 'hardCoded3'
  };
};

export const aPayerType = function (): PayerType {
  switch (getRandomInt(4)) {
    case 1:
      return PayerType.CATEGORY;
    case 2:
      return PayerType.NATIONAL;
    case 3:
      return PayerType.MEMBER;
    default:
      return PayerType.ALL;
  }
};

export const aLagKey = function (): number {
  return getRandomInt(7) + 1;
};

export const aDrillDown = function (): DrillDown {
  return {
    nodePath: 'node path',
    tab: MultilevelTab.BY_DEPARTMENT
  };
};

export const aFilterCriteria = function (): FilterCriteria {
  return {
    memberKey: 5,
    dateRange: {
      startYear: 2020,
      endYear: 2021,
      startMonth: 2,
      endMonth: 2
    },
    nodePath: 'nodepath',
    memberLocation: {
      memberLocationKey: 16,
      memberLocationCode: 'a location code',
      memberLocationName: 'a location name'
    },
    payerCategory: {
      payerCategoryKey: 18,
      payerCategoryCode: 'not a random string',
      payerCategoryDescription: 'pcd'
    },
    payerKey: 543,
    payerType: PayerType.MEMBER,
    lagKey: DEFAULT_LAG_KEY,
    memberBillingArea: {
      memberBillingAreaKey: 300,
      memberBillingAreaDescription: 'mbad',
      memberBillingAreaCode: 'mbac'
    },
    invoiceStatus: DEFAULT_INVOICE_STATUS,
    telehealthFlag: DEFAULT_TELEHEALTH_FLAG.key,
    customGroupId: DEFAULT_CUSTOM_GROUP_ID, memberLocationKeys: '0'
  };
};

export const aRandomFilterCriteria = function (): FilterCriteria {

  return {
    memberKey: 7,
    dateRange: {
      startYear: 66,
      endYear: 63,
      startMonth: 62,
      endMonth: 61
    },
    nodePath: 'hardCoded4',
    memberLocation: {
      memberLocationKey: 64,
      memberLocationCode: 'hardCoded5',
      memberLocationName: 'hardCoded6'
    },
    payerCategory: {
      payerCategoryKey: 62,
      payerCategoryCode: 'hardCoded7',
      payerCategoryDescription: 'hardCoded8'
    },
    payerKey: 61,
    payerType: PayerType.MEMBER,
    lagKey: 63,
    memberBillingArea: {
      memberBillingAreaKey: 68,
      memberBillingAreaDescription: 'hardCoded9',
      memberBillingAreaCode: 'hardCoded10'
    },
    invoiceStatus: DEFAULT_INVOICE_STATUS,
    telehealthFlag: DEFAULT_TELEHEALTH_FLAG.key,
    customGroupId: 75, memberLocationKeys: '0'
  };
};

export const aCurrentSelections = function (): CurrentSelections {
  const charges = true;
  const collectionsChargeDisplayed = true;
  const expectedPaymentsDisplayed = true;
  const chargesTerminallyDeniedDisplayed = true;
  const deniedCPTsPaidDisplayed = true;
  const denialsChargesDisplayed = true;
  const cFTEAdjustedCountDisplayed = true;
  const previousTimePeriod = true;
  const patientVisitCounts = true;
  const previousPatientVisitCounts = true;
  const isCustom = true;
  const wrvuViewType: WrvuViewType = getRandomInt(2) === 1 ? WrvuViewType.Actual : WrvuViewType.CfteAdjusted;
  const isProviderSelected = true;
  const multipleProviders = true;
  const multipleSpecialties = true;
  const multipleDepartments = true;
  const showHierarchySearch = true;
  const zeroSuppression = true;
  const filterBannerEdit = true;
  let cptViewType: CptViewType;
  switch (getRandomInt(3)) {
    case 0:
      cptViewType = CptViewType.CptCode;
      break;
    case 1:
      cptViewType = CptViewType.CptRange;
      break;
    case 2:
      cptViewType = CptViewType.CptFamily;
  }
  const isRevenueMember = true;
  const viewCommunityBenchmarks = true;
  const communityBenchmarkAlert = true;
  let emDimension: EmDimension;
  switch (getRandomInt(4)) {
    case 0:
      emDimension = EmDimension.Outpatient;
      break;
    case 1:
      emDimension = EmDimension.InPatient;
      break;
    case 2:
      emDimension = EmDimension.Ophthalmology;
      break;
    case 3:
      emDimension = EmDimension.EmergencyMedicine;
  }
  const isExporting = getRandomInt(2) === 1;
  const isLessThanLagPeriod = getRandomInt(2) === 1;
  return {
    ...aState().display,
    charges: charges,
    collectionsChargeDisplayed: collectionsChargeDisplayed,
    expectedPaymentsDisplayed: expectedPaymentsDisplayed,
    chargesTerminallyDeniedDisplayed: chargesTerminallyDeniedDisplayed,
    deniedCPTsPaidDisplayed: deniedCPTsPaidDisplayed,
    denialsChargesDisplayed: denialsChargesDisplayed,
    cFTEAdjustedCountDisplayed: cFTEAdjustedCountDisplayed,
    previousTimePeriod: previousTimePeriod,
    navigationNodePath: 'hardCoded11',
    patientVisitCounts: patientVisitCounts,
    previousPatientVisitCounts: previousPatientVisitCounts,
    isCustom: isCustom,
    wrvuViewType: wrvuViewType,
    isProviderSelected: isProviderSelected,
    multipleProviders: multipleProviders,
    multipleSpecialties: multipleSpecialties,
    multipleDepartments: multipleDepartments,
    showHierarchySearch: showHierarchySearch,
    zeroSuppression: zeroSuppression,
    filterBannerEdit: filterBannerEdit,
    isExporting: isExporting,
    isLessThanLagPeriod: isLessThanLagPeriod,
    isRevenueMember: isRevenueMember,
    viewCommunityBenchmarks: viewCommunityBenchmarks,
    communityBenchmarkAlert: communityBenchmarkAlert
  };
};

export const aProviderProductivity = function (): ProviderProductivity {
  return {
    nodeName: 'hardCoded12',
    nodeId: 556,
    nodePath: 'hardCoded12',
    benchmarkPercentile: 1,
    imputedReportedcFTE25thPercentile: 12,
    imputedReportedcFTE50thPercentile: 13,
    imputedReportedcFTE65thPercentile: 14,
    imputedReportedcFTE75thPercentile: 15,
    imputedReportedcFTEMean: 16,
    imputedReportedcFTE90thPercentile: 1,
    benchmark25thPercentile: 557,
    benchmark50thPercentile: 558,
    benchmark65thPercentile: 559,
    benchmark75thPercentile: 551,
    benchmark90thPercentile: 552,
    benchmarkMean: 610,
    wRVUs: 611,
    cfteAdjustedWRVUs: 612,
    cfte: 613,
    charges: 614,
    varianceMean: 615,
    variance25thPercentile: 121,
    variance50thPercentile: 122,
    variance65thPercentile: 123,
    variance75thPercentile: 124,
    variance90thPercentile: 125,
    communityVarianceMean: 0,
    communityVariance25thPercentile: 0,
    communityVariance50thPercentile: 0,
    communityVariance65thPercentile: 0,
    communityVariance75thPercentile: 0,
    communityVariance90thPercentile: 0,
    communityImputedReportedcFTE25thPercentile: 0,
    communityImputedReportedcFTE50thPercentile: 0,
    communityImputedReportedcFTE65thPercentile: 0,
    communityImputedReportedcFTE75thPercentile: 0,
    communityImputedReportedcFTEMean: 0,
    communityImputedReportedcFTE90thPercentile: 0,
    communityBenchmark25thPercentile: 0,
    communityBenchmark50thPercentile: 0,
    communityBenchmark65thPercentile: 0,
    communityBenchmark75thPercentile: 0,
    communityBenchmark90thPercentile: 0,
    communityBenchmarkMean: 0
  };
};

export const aProductivityMultiLevelSnapshot = function (): ProductivityMultiLevelSnapshot {
  return {
    id: 66,
    nodeId: 163,
    nodeName: 'hardCoded13',
    nodePath: 'hardCoded14',
    providerNodeId: 162,
    providerNodeName: 'hardCoded15',
    providerNodePath: 'hardCoded16',
    specialtyNodeId: 90,
    specialtyNodeName: 'hardCoded17',
    specialtyNodePath: 'hardCoded18',
    departmentNodeId: 91,
    departmentNodeName: 'hardCoded19',
    departmentNodePath: 'hardCoded20',
    cfte: 777,
    charges: 778,
    wRVUs: 779,
    cfteAdjustedWRVUs: 771,
    benchmark25thPercentile: 772,
    benchmark50thPercentile: 773,
    benchmark75thPercentile: 774,
    benchmark90thPercentile: 775,
    benchmarkMean: 776,
    benchmark65thPercentile: 777,
    benchmarkPercentile: 778,
    communityBenchmark25thPercentile: 712,
    communityBenchmark50thPercentile: 713,
    communityBenchmark75thPercentile: 714,
    communityBenchmark90thPercentile: 715,
    communityBenchmarkMean: 716,
    communityBenchmark65thPercentile: 717,
    imputedReportedcFTE25thPercentile: 0,
    imputedReportedcFTE50thPercentile: 0,
    imputedReportedcFTE65thPercentile: 0,
    imputedReportedcFTE75thPercentile: 0,
    imputedReportedcFTEMean: 0,
    imputedReportedcFTE90thPercentile: 0,
    varianceMean: 0,
    variance25thPercentile: 0,
    variance50thPercentile: 0,
    variance65thPercentile: 0,
    variance75thPercentile: 0,
    variance90thPercentile: 0,
    communityVarianceMean: 0,
    communityVariance25thPercentile: 0,
    communityVariance50thPercentile: 0,
    communityVariance65thPercentile: 0,
    communityVariance75thPercentile: 0,
    communityVariance90thPercentile: 0,
    communityImputedReportedcFTE25thPercentile: 0,
    communityImputedReportedcFTE50thPercentile: 0,
    communityImputedReportedcFTE65thPercentile: 0,
    communityImputedReportedcFTE75thPercentile: 0,
    communityImputedReportedcFTEMean: 0,
    communityImputedReportedcFTE90thPercentile: 0,
    specialtyPerformanceActualWrvus: 77,
    specialtyPerformanceCfteAdjWrvus: 8888,
    specialtyPerformanceActualWrvuspVariance: 2022,
    specialtyPerformanceCfteAdjWrvuspVariance: 4487
  };
};

export const aMultiLevelProductivity = function (): ProviderProductivityMultiLevelData {
  return {
    providerProductivities: [
      {
        id: 92,
        nodeId: 166,
        nodeName: 'hardCoded21',
        nodePath: 'hardCoded22',
        providerNodeId: 167,
        providerNodeName: 'hardCoded23',
        providerNodePath: 'hardCoded24',
        specialtyNodeId: 93,
        specialtyNodeName: 'hardCoded25',
        specialtyNodePath: 'hardCoded26',
        departmentNodeId: 94,
        departmentNodeName: 'hardCoded27',
        departmentNodePath: 'hardCoded28',
        cfte: 717,
        charges: 715,
        wRVUs: 713,
        cfteAdjustedWRVUs: 714,
        benchmark25thPercentile: 715,
        benchmark50thPercentile: 716,
        benchmark75thPercentile: 717,
        benchmark90thPercentile: 718,
        benchmarkMean: 719,
        benchmark65thPercentile: 710,
        benchmarkPercentile: 123,
        communityBenchmark25thPercentile: 124,
        communityBenchmark50thPercentile: 125,
        communityBenchmark75thPercentile: 126,
        communityBenchmark90thPercentile: 127,
        communityBenchmarkMean: 128,
        communityBenchmark65thPercentile: 129,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0
      },
      {
        id: 95,
        nodeId: 168,
        nodeName: 'hardCoded29',
        nodePath: 'hardCoded30',
        providerNodeId: 169,
        providerNodeName: 'hardCoded31',
        providerNodePath: 'hardCoded32',
        specialtyNodeId: 97,
        specialtyNodeName: 'hardCoded33',
        specialtyNodePath: 'hardCoded34',
        departmentNodeId: 98,
        departmentNodeName: 'hardCoded35',
        departmentNodePath: 'hardCoded36',
        cfte: 120,
        charges: 120,
        wRVUs: 121,
        cfteAdjustedWRVUs: 122,
        benchmark25thPercentile: 123,
        benchmark50thPercentile: 124,
        benchmark75thPercentile: 125,
        benchmark90thPercentile: 126,
        benchmarkMean: 127,
        benchmark65thPercentile: 128,
        benchmarkPercentile: 129,
        communityBenchmark25thPercentile: 130,
        communityBenchmark50thPercentile: 131,
        communityBenchmark75thPercentile: 132,
        communityBenchmark90thPercentile: 133,
        communityBenchmarkMean: 134,
        communityBenchmark65thPercentile: 130,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0
      },
      {
        id: 99,
        nodeId: 163,
        nodeName: 'hardCoded37',
        nodePath: 'hardCoded38',
        providerNodeId: 165,
        providerNodeName: 'hardCoded39',
        providerNodePath: 'hardCoded40',
        specialtyNodeId: 91,
        specialtyNodeName: 'hardCoded41',
        specialtyNodePath: 'hardCoded42',
        departmentNodeId: 92,
        departmentNodeName: 'hardCoded42',
        departmentNodePath: 'hardCoded43',
        cfte: 130,
        charges: 131,
        wRVUs: 133,
        cfteAdjustedWRVUs: 134,
        benchmark25thPercentile: 135,
        benchmark50thPercentile: 136,
        benchmark75thPercentile: 137,
        benchmark90thPercentile: 1387,
        benchmarkMean: 1234,
        benchmark65thPercentile: 1232,
        benchmarkPercentile: 1233,
        communityBenchmark25thPercentile: 1231,
        communityBenchmark50thPercentile: 1236,
        communityBenchmark75thPercentile: 1277,
        communityBenchmark90thPercentile: 1255,
        communityBenchmarkMean: 1254,
        communityBenchmark65thPercentile: 1256,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0
      }
    ],
    specialtyProductivities: [
      {
        id: 93,
        nodeId: 165,
        nodeName: 'hardCoded43',
        nodePath: 'hardCoded44',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: 94,
        specialtyNodeName: 'hardCoded45',
        specialtyNodePath: 'hardCoded46',
        departmentNodeId: 95,
        departmentNodeName: 'hardCoded47',
        departmentNodePath: 'hardCoded48',
        cfte: 1211,
        charges: 121,
        wRVUs: 1222,
        cfteAdjustedWRVUs: 1221,
        benchmark25thPercentile: 1226,
        benchmark50thPercentile: 1227,
        benchmark75thPercentile: 1228,
        benchmark90thPercentile: 1226,
        benchmarkMean: 1229,
        benchmark65thPercentile: 1223,
        benchmarkPercentile: 1223,
        communityBenchmark25thPercentile: 1222,
        communityBenchmark50thPercentile: 1221,
        communityBenchmark75thPercentile: 1228,
        communityBenchmark90thPercentile: 1225,
        communityBenchmarkMean: 1223,
        communityBenchmark65thPercentile: 1222,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0
      },
      {
        id: 96,
        nodeId: 162,
        nodeName: 'hardCoded49',
        nodePath: 'hardCoded50',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: 97,
        specialtyNodeName: 'hardCoded51',
        specialtyNodePath: 'hardCoded52',
        departmentNodeId: 98,
        departmentNodeName: 'hardCoded53',
        departmentNodePath: 'hardCoded54',
        cfte: 1211,
        charges: 121,
        wRVUs: 122,
        cfteAdjustedWRVUs: 1221,
        benchmark25thPercentile: 1229,
        benchmark50thPercentile: 1225,
        benchmark75thPercentile: 1223,
        benchmark90thPercentile: 1224,
        benchmarkMean: 1226,
        benchmark65thPercentile: 1223,
        benchmarkPercentile: 1228,
        communityBenchmark25thPercentile: 1255,
        communityBenchmark50thPercentile: 1233,
        communityBenchmark75thPercentile: 123,
        communityBenchmark90thPercentile: 123,
        communityBenchmarkMean: 127,
        communityBenchmark65thPercentile: 1244,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0
      }
    ],
    departmentProductivities: [
      {
        id: 99,
        nodeId: 161,
        nodeName: 'hardCoded55',
        nodePath: 'hardCoded56',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: undefined,
        specialtyNodeName: undefined,
        specialtyNodePath: undefined,
        departmentNodeId: 93,
        departmentNodeName: 'hardCoded57',
        departmentNodePath: 'hardCoded58',
        cfte: 1242,
        charges: 1244,
        wRVUs: 124,
        cfteAdjustedWRVUs: 1241,
        benchmark25thPercentile: 1242,
        benchmark50thPercentile: 1243,
        benchmark75thPercentile: 1244,
        benchmark90thPercentile: 1245,
        benchmarkMean: 1246,
        benchmark65thPercentile: 1245,
        benchmarkPercentile: 1246,
        communityBenchmark25thPercentile: 1247,
        communityBenchmark50thPercentile: 1248,
        communityBenchmark75thPercentile: 1249,
        communityBenchmark90thPercentile: 1248,
        communityBenchmarkMean: 124,
        communityBenchmark65thPercentile: 124,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0
      },
      {
        id: 92,
        nodeId: 169,
        nodeName: 'hardCoded59',
        nodePath: 'hardCoded60',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: undefined,
        specialtyNodeName: undefined,
        specialtyNodePath: undefined,
        departmentNodeId: 88,
        departmentNodeName: 'hardCoded61',
        departmentNodePath: 'hardCoded62',
        cfte: 1233,
        charges: 1231,
        wRVUs: 1233,
        cfteAdjustedWRVUs: 1234,
        benchmark25thPercentile: 1235,
        benchmark50thPercentile: 1236,
        benchmark75thPercentile: 1237,
        benchmark90thPercentile: 1236,
        benchmarkMean: 1234,
        benchmark65thPercentile: 2222,
        benchmarkPercentile: 2224,
        communityBenchmark25thPercentile: 2223,
        communityBenchmark50thPercentile: 2221,
        communityBenchmark75thPercentile: 2220,
        communityBenchmark90thPercentile: 2228,
        communityBenchmarkMean: 2226,
        communityBenchmark65thPercentile: 2224,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0
      }
    ]
  };
};

export const aMultiLevelProductivityWithAZeroSpecPerformEntry = function (): ProviderProductivityMultiLevelData {
  return {
    providerProductivities: [
      {
        id: 11,
        nodeId: 222,
        nodeName: 'name',
        nodePath: 'path',
        providerNodeId: 999,
        providerNodeName: 'Red',
        providerNodePath: 'other path',
        specialtyNodeId: 33,
        specialtyNodeName: 'other name',
        specialtyNodePath: 'specialty path',
        departmentNodeId: 44,
        departmentNodeName: 'dept name',
        departmentNodePath: 'dept patj',
        cfte: 555,
        charges: 666,
        wRVUs: 777,
        cfteAdjustedWRVUs: 888,
        benchmark25thPercentile: 999,
        benchmark50thPercentile: 1111,
        benchmark75thPercentile: 2222,
        benchmark90thPercentile: 3333,
        benchmarkMean: 4444,
        benchmark65thPercentile: 5555,
        benchmarkPercentile: 6666,
        communityBenchmark25thPercentile: 7777,
        communityBenchmark50thPercentile: 8888,
        communityBenchmark75thPercentile: 9999,
        communityBenchmark90thPercentile: 1234,
        communityBenchmarkMean: 5678,
        communityBenchmark65thPercentile: 910,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0
      },
      {
        id: 77,
        nodeId: 99,
        nodeName: 'a string',
        nodePath: 'a string again',
        providerNodeId: 88,
        providerNodeName: 'Blue',
        providerNodePath: 'qwertyui1',
        specialtyNodeId: 91,
        specialtyNodeName: 'qwertyui12',
        specialtyNodePath: 'qwertyui13',
        departmentNodeId: 97,
        departmentNodeName: 'qwertyui14',
        departmentNodePath: 'qwertyui15',
        cfte: 0,
        charges: 21,
        wRVUs: 0,
        cfteAdjustedWRVUs: 21,
        benchmark25thPercentile: 21,
        benchmark50thPercentile: 21,
        benchmark75thPercentile: 21,
        benchmark90thPercentile: 21,
        benchmarkMean: 21,
        benchmark65thPercentile: 21,
        benchmarkPercentile: 21,
        communityBenchmark25thPercentile: 214,
        communityBenchmark50thPercentile: 251,
        communityBenchmark75thPercentile: 216,
        communityBenchmark90thPercentile: 217,
        communityBenchmarkMean: 281,
        communityBenchmark65thPercentile: 88,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 777,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0
      },
      {
        id: 66,
        nodeId: 77,
        nodeName: 'UniqueHardcodedValue1',
        nodePath: 'np',
        providerNodeId: 78,
        providerNodeName: 'Green',
        providerNodePath: 'asdfghj',
        specialtyNodeId: 79,
        specialtyNodeName: 'UniqueHardcodedValue2',
        specialtyNodePath: 'UniqueHardcodedValue3',
        departmentNodeId: 80,
        departmentNodeName: 'UniqueHardcodedValue4',
        departmentNodePath: 'UniqueHardcodedValue5',
        cfte: 0,
        charges: 0,
        wRVUs: 0,
        cfteAdjustedWRVUs: 0,
        benchmark25thPercentile: 7778,
        benchmark50thPercentile: 5433,
        benchmark75thPercentile: 2222,
        benchmark90thPercentile: 8765,
        benchmarkMean: 1111,
        benchmark65thPercentile: 9876,
        benchmarkPercentile: 5555,
        communityBenchmark25thPercentile: 3333,
        communityBenchmark50thPercentile: 4444,
        communityBenchmark75thPercentile: 6666,
        communityBenchmark90thPercentile: 7777,
        communityBenchmarkMean: 8888,
        communityBenchmark65thPercentile: 9999,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0
      }],
    specialtyProductivities: [
      {
        id: 42,
        nodeId: 1001,
        nodeName: 'HardCodedString1',
        nodePath: 'HardCodedString2',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: 75,
        specialtyNodeName: 'HardCodedString3',
        specialtyNodePath: 'HardCodedString4',
        departmentNodeId: 58,
        departmentNodeName: 'HardCodedString5',
        departmentNodePath: 'HardCodedString6',
        cfte: 456,
        charges: 789,
        wRVUs: 123,
        cfteAdjustedWRVUs: 567,
        benchmark25thPercentile: 111,
        benchmark50thPercentile: 222,
        benchmark75thPercentile: 333,
        benchmark90thPercentile: 444,
        benchmarkMean: 555,
        benchmark65thPercentile: 666,
        benchmarkPercentile: 777,
        communityBenchmark25thPercentile: 888,
        communityBenchmark50thPercentile: 999,
        communityBenchmark75thPercentile: 1111,
        communityBenchmark90thPercentile: 2222,
        communityBenchmarkMean: 3333,
        communityBenchmark65thPercentile: 4444,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0
      },
      {
        id: 42,
        nodeId: 999,
        nodeName: 'CodeMaster',
        nodePath: 'nerdy/code/puns',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: 24,
        specialtyNodeName: 'BugSlayer',
        specialtyNodePath: 'nerdy/bugs/puns',
        departmentNodeId: 88,
        departmentNodeName: 'FunctionFactory',
        departmentNodePath: 'nerdy/functions/puns',
        cfte: 888,
        charges: 777,
        wRVUs: 666,
        cfteAdjustedWRVUs: 555,
        benchmark25thPercentile: 444,
        benchmark50thPercentile: 333,
        benchmark75thPercentile: 222,
        benchmark90thPercentile: 111,
        benchmarkMean: 999,
        benchmark65thPercentile: 888,
        benchmarkPercentile: 777,
        communityBenchmark25thPercentile: 666,
        communityBenchmark50thPercentile: 555,
        communityBenchmark75thPercentile: 444,
        communityBenchmark90thPercentile: 333,
        communityBenchmarkMean: 222,
        communityBenchmark65thPercentile: 111,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0
      }
    ],
    departmentProductivities: [
      {
        id: 42,
        nodeId: 777,
        nodeName: 'exampleNodeName',
        nodePath: 'exampleNodePath',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: undefined,
        specialtyNodeName: undefined,
        specialtyNodePath: undefined,
        departmentNodeId: 13,
        departmentNodeName: 'exampleDepartmentName',
        departmentNodePath: 'exampleDepartmentPath',
        cfte: 1234,
        charges: 5678,
        wRVUs: 9012,
        cfteAdjustedWRVUs: 3456,
        benchmark25thPercentile: 7890,
        benchmark50thPercentile: 1234,
        benchmark75thPercentile: 5678,
        benchmark90thPercentile: 9012,
        benchmarkMean: 3456,
        benchmark65thPercentile: 7890,
        benchmarkPercentile: 1234,
        communityBenchmark25thPercentile: 5678,
        communityBenchmark50thPercentile: 9012,
        communityBenchmark75thPercentile: 3456,
        communityBenchmark90thPercentile: 7890,
        communityBenchmarkMean: 1234,
        communityBenchmark65thPercentile: 5678,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0
      },
      {
        id: 99,
        nodeId: 999,
        nodeName: 'string name',
        nodePath: 'path/path',
        providerNodeId: undefined,
        providerNodeName: undefined,
        providerNodePath: undefined,
        specialtyNodeId: undefined,
        specialtyNodeName: undefined,
        specialtyNodePath: undefined,
        departmentNodeId: 9,
        departmentNodeName: 'asdfgghji',
        departmentNodePath: 'mlkjgu',
        cfte: 1,
        charges: 333,
        wRVUs: 456,
        cfteAdjustedWRVUs: 457,
        benchmark25thPercentile: 459,
        benchmark50thPercentile: 458,
        benchmark75thPercentile: 476,
        benchmark90thPercentile: 466,
        benchmarkMean: 426,
        benchmark65thPercentile: 422,
        benchmarkPercentile: 406,
        communityBenchmark25thPercentile: 123,
        communityBenchmark50thPercentile: 124,
        communityBenchmark75thPercentile: 125,
        communityBenchmark90thPercentile: 126,
        communityBenchmarkMean: 991,
        communityBenchmark65thPercentile: 992,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        varianceMean: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0
      }
    ]
  };
};

export function getRandomString() {
  return 'a' + getRandomInt(10000) + 'z';
}

export const aNewPatientVisitSnapshotMultilevel = function (): MergedNewPatientVisitSnapshotEntry {
  return {
    isHidden: false,
    previous: {
      isHidden: false,
      countOfNewPatientVisits: 6,
      countOfTotalPatientVisits: 40,
      newPatientVisitsPercentage: 41,
      countOfExistingPatientVisits: 42,
      difference: 44,
      nodeId: 4,
      nodeName: 'getRandomString()',
      nodePath: 'ab34\\cd56',
      departmentNodeId: 5,
      departmentNodeName: 'hgjk',
      departmentNodePath: 'ab34\\cd56',
      specialtyNodeId: 6,
      specialtyNodeName: 'qwert',
      specialtyNodePath: 'ab34\\cd56\\ef78',
      providerNodeId: 7,
      providerNodeName: getRandomString(),
      providerNodePath: 'ab34\\cd56\\ef78\\gh10',
      newPatientVisitsPercentageBenchmarks: {
        benchmark25thPercentile: 16,
        benchmark50thPercentile: 17,
        benchmark75thPercentile: 18,
        benchmark90thPercentile: 19,
        benchmarkMean: 10
      },
      newPatientVisitsCommunityPercentageBenchmarks: {
        benchmark25thPercentile: 11,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 31,
        benchmark90thPercentile: 32,
        benchmarkMean: 33
      },
      newPatientVisitsTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 34,
        benchmark50thPercentile: 35,
        benchmark75thPercentile: 36,
        benchmark90thPercentile: 37,
        benchmarkMean: 38
      },
      newPatientVisitsInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 39,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 31,
        benchmark90thPercentile: 99,
        benchmarkMean: 41
      },
      newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 42,
        benchmark50thPercentile: 43,
        benchmark75thPercentile: 44,
        benchmark90thPercentile: 45,
        benchmarkMean: 46
      },
      newPatientVisitsCommunityInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 47,
        benchmark50thPercentile: 48,
        benchmark75thPercentile: 49,
        benchmark90thPercentile: 41,
        benchmarkMean: 42
      },
      newPatientVisitsVariance: {
        variance25thPercentile: 43,
        variance50thPercentile: 90,
        variance75thPercentile: 92,
        variance90thPercentile: 93,
        varianceMean: 93
      },
      newPatientVisitsCommunityVariance: {
        variance25thPercentile: 95,
        variance50thPercentile: 96,
        variance75thPercentile: 97,
        variance90thPercentile: 98,
        varianceMean: 99
      },
      newPatientVisitsTelehealthVariance: {
        variance25thPercentile: 90,
        variance50thPercentile: 91,
        variance75thPercentile: 92,
        variance90thPercentile: 93,
        varianceMean: 94
      },
      newPatientVisitsInPersonVariance: {
        variance25thPercentile: 95,
        variance50thPercentile: 96,
        variance75thPercentile: 97,
        variance90thPercentile: 98,
        varianceMean: 99
      },
      newPatientVisitsCommunityTelehealthVariance: {
        variance25thPercentile: 10,
        variance50thPercentile: 11,
        variance75thPercentile: 12,
        variance90thPercentile: 13,
        varianceMean: 14
      },
      newPatientVisitsCommunityInPersonVariance: {
        variance25thPercentile: 15,
        variance50thPercentile: 16,
        variance75thPercentile: 17,
        variance90thPercentile: 18,
        varianceMean: 19
      },
    },
    countOfNewPatientVisits: 28,
    countOfTotalPatientVisits: 29,
    newPatientVisitsPercentage: 22,
    countOfExistingPatientVisits: 26,
    difference: 27,
    nodeId: 7,
    nodeName: 'hardCoded78',
    nodePath: 'hardCoded79' + '\\' + 'hardCoded80',
    departmentNodeId: 6,
    departmentNodeName: 'hardCoded81',
    departmentNodePath: 'hardCoded82' + '\\' + 'hardCoded83',
    specialtyNodeId: 4,
    specialtyNodeName: 'hardCoded84',
    specialtyNodePath: 'hardCoded85' + '\\' + 'hardCoded86' + '\\' + 'hardCoded87',
    providerNodeId: 2,
    providerNodeName: 'hardCoded88',
    providerNodePath: 'hardCoded89' + '\\' + 'hardCoded90' + '\\' + 'hardCoded91' + '\\' + 'hardCoded92',
    newPatientVisitsPercentageBenchmarks: {
      benchmark25thPercentile: 20,
      benchmark50thPercentile: 21,
      benchmark75thPercentile: 22,
      benchmark90thPercentile: 23,
      benchmarkMean: 24
    },
    newPatientVisitsCommunityPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 26,
      benchmark75thPercentile: 27,
      benchmark90thPercentile: 28,
      benchmarkMean: 29
    },
    newPatientVisitsTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 21,
      benchmark50thPercentile: 22,
      benchmark75thPercentile: 23,
      benchmark90thPercentile: 24,
      benchmarkMean: 25
    },
    newPatientVisitsInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 26,
      benchmark50thPercentile: 27,
      benchmark75thPercentile: 28,
      benchmark90thPercentile: 29,
      benchmarkMean: 21
    },
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 22,
      benchmark50thPercentile: 25,
      benchmark75thPercentile: 23,
      benchmark90thPercentile: 24,
      benchmarkMean: 25
    },
    newPatientVisitsCommunityInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 26,
      benchmark50thPercentile: 27,
      benchmark75thPercentile: 11,
      benchmark90thPercentile: 11,
      benchmarkMean: 12
    },
    newPatientVisitsVariance: {
      variance25thPercentile: 13,
      variance50thPercentile: 14,
      variance75thPercentile: 15,
      variance90thPercentile: 16,
      varianceMean: 17
    },
    newPatientVisitsCommunityVariance: {
      variance25thPercentile: 18,
      variance50thPercentile: 19,
      variance75thPercentile: 11,
      variance90thPercentile: 12,
      varianceMean: 13
    },
    newPatientVisitsTelehealthVariance: {
      variance25thPercentile: 14,
      variance50thPercentile: 16,
      variance75thPercentile: 17,
      variance90thPercentile: 18,
      varianceMean: 19
    },
    newPatientVisitsInPersonVariance: {
      variance25thPercentile: 11,
      variance50thPercentile: 10,
      variance75thPercentile: 11,
      variance90thPercentile: 12,
      varianceMean: 13
    },
    newPatientVisitsCommunityTelehealthVariance: {
      variance25thPercentile: 14,
      variance50thPercentile: 15,
      variance75thPercentile: 16,
      variance90thPercentile: 74,
      varianceMean: 73
    },
    newPatientVisitsCommunityInPersonVariance: {
      variance25thPercentile: 72,
      variance50thPercentile: 71,
      variance75thPercentile: 10,
      variance90thPercentile: 11,
      varianceMean: 12
    },
  };
};
export const aNonRandomNewPatientVisitSnapshotMultilevel = function (): MergedNewPatientVisitSnapshotEntry {
  return {
    nodeId: 1,
    nodeName: 'not rand',
    nodePath: 'not rand\\not random',
    previous: {
      nodeId: 1,
      nodeName: 'not rand',
      nodePath: 'not rand\\not random',
      isHidden: false,
      difference: 5,
      countOfNewPatientVisits: 5,
      countOfTotalPatientVisits: 5,
      newPatientVisitsPercentage: 5,
      countOfExistingPatientVisits: 5,
      departmentNodeId: 8,
      departmentNodeName: 'name',
      departmentNodePath: 'path\\path',
      specialtyNodeId: 9,
      specialtyNodeName: 'spec',
      specialtyNodePath: 'alldeps\\dep\\spec',
      providerNodeId: 7,
      providerNodeName: 'nj98g',
      providerNodePath: 'ad\\d\\s\\qp',
      newPatientVisitsPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsCommunityPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsCommunityInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsCommunityVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsTelehealthVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsInPersonVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsCommunityTelehealthVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsCommunityInPersonVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
    },
    isHidden: false,
    difference: 5,
    countOfNewPatientVisits: 5,
    countOfTotalPatientVisits: 5,
    newPatientVisitsPercentage: 5,
    countOfExistingPatientVisits: 5,
    departmentNodeId: 8,
    departmentNodeName: 'name',
    departmentNodePath: 'path\\path',
    specialtyNodeId: 9,
    specialtyNodeName: 'spec',
    specialtyNodePath: 'alldeps\\dep\\spec',
    providerNodeId: 7,
    providerNodeName: 'nj98g',
    providerNodePath: 'ad\\d\\s\\qp',
    newPatientVisitsPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
  };
};

export const aMultilevelNewPatientVisitsSnapshot = function (): MergedProviderNewPatientVisitMultiLevelData {
  return {
    providerNpvSnapshotData: [
      aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel(),
      aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel()
    ],
    specialtyNpvSnapshotData: [
      aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel(),
      aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel()
    ],
    departmentNpvSnapshotData: [
      aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel(),
      aNewPatientVisitSnapshotMultilevel(), aNewPatientVisitSnapshotMultilevel()
    ]
  };
};

export const aNonRandomMultilevelNewPatientVisitsSnapshot = function (): MergedProviderNewPatientVisitMultiLevelData {
  return {
    providerNpvSnapshotData: [
      aNonRandomNewPatientVisitSnapshotMultilevel(), aNonRandomNewPatientVisitSnapshotMultilevel(),
      aNonRandomNewPatientVisitSnapshotMultilevel(), aNonRandomNewPatientVisitSnapshotMultilevel(),
      aNonRandomNewPatientVisitSnapshotMultilevel()
    ],
    specialtyNpvSnapshotData: [
      aNonRandomNewPatientVisitSnapshotMultilevel(), aNonRandomNewPatientVisitSnapshotMultilevel(),
      aNonRandomNewPatientVisitSnapshotMultilevel(), aNonRandomNewPatientVisitSnapshotMultilevel(),
      aNonRandomNewPatientVisitSnapshotMultilevel()
    ],
    departmentNpvSnapshotData: [
      aNonRandomNewPatientVisitSnapshotMultilevel(), aNonRandomNewPatientVisitSnapshotMultilevel(),
      aNonRandomNewPatientVisitSnapshotMultilevel(), aNonRandomNewPatientVisitSnapshotMultilevel(),
      aNonRandomNewPatientVisitSnapshotMultilevel()
    ]
  };
};

export const aMonthNewPatientVisitEntry = function (): MonthNewPatientVisitEntry {
  return {
    month: '1',
    year: 2016,
    countOfNewPatientVisits: 12,
    countOfTotalPatientVisits: 13,
    newPatientVisitsPercentage: 14,
    countOfExistingPatientVisits: 15,
    difference: 2,
    newPatientVisitsPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
  };
};

export const aMonthNewPatientVisitEntryWithSpecifics = function (): MonthNewPatientVisitEntry {
  return {
    month: '1',
    year: 2016,
    countOfNewPatientVisits: 88,
    countOfTotalPatientVisits: 22,
    newPatientVisitsPercentage: 43,
    countOfExistingPatientVisits: 71,
    difference: 2,
    newPatientVisitsPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
  };
};

export function aMonthNewPatientVisitData(): MonthNewPatientVisitData {
  return {
    monthNewPatientVisitCounts: [aMonthNewPatientVisitEntry(), aMonthNewPatientVisitEntry()]
  };
}

export function aMonthNewPatientVisitDataWithSpecifics(): MonthNewPatientVisitData {
  return {
    monthNewPatientVisitCounts: [aMonthNewPatientVisitEntryWithSpecifics(), aMonthNewPatientVisitEntryWithSpecifics()]
  };
}

export const aDenialsPayerDefault = function (): DenialPayerEntity {
  return {
    id: 0,
    payerCategoryDesc: '',
    payerKey: 0,
    payerType: 0,
    chargeAmount: 0,
    numberBilled: 0,
    numberDenied: 0,
    denialRate: 0,
    deniedPaidRate: 0,
    numberDeniedPaid: 0,
    denialRatePer25th: 0,
    denialRatePer50th: 0,
    denialRatePer75th: 0,
    variancePer25th: 0,
    variancePer50th: 0,
    variancePer75th: 0,
    deniedPaidPer25th: 0,
    deniedPaidPer50th: 0,
    deniedPaidPer75th: 0,
    expectedDenialRatePer25th: 0,
    expectedDenialRatePer50th: 0,
    expectedDenialRatePer75th: 0,
    expected25NumberBilled: 0,
    expected50NumberBilled: 0,
    expected75NumberBilled: 0,
    deniedChargedAmount: 0,
    deniedChargedAmountPaid: 0,
    terminalDenialRate: 0,
    terminalChargesDeniedPercentage: 0,
    chargesTerminallyDenied: 0,
    chargesDeniedPercentage: 0,
    terminalDenialRatePer25th: 0,
    terminalDenialRatePer50th: 0,
    terminalDenialRatePer75th: 0,
    terminalDenialRateBenchmark50th: 0
  };
};

export const aDenialsPayerDataDefault = function (): DenialsPayerData {
  return {
    denialRates: [
      {
        id: 0,
        chargeAmount: 0,
        numberBilled: 0,
        numberDenied: 0,
        denialRate: 0,
        deniedPaidRate: 0,
        numberDeniedPaid: 0,
        denialRatePer50th: 0,
        deniedPaidPer50th: 0,
        denialRatePer25th: 0,
        deniedPaidPer25th: 0,
        denialRatePer75th: 0,
        deniedPaidPer75th: 0,
        variancePer25th: 0,
        variancePer50th: 0,
        variancePer75th: 0,
        payerCategoryDesc: '',
        payerKey: 0,
        payerType: 0,
        deniedChargedAmount: 0,
        chargesDeniedPercentage: 0,
        deniedChargedAmountPaid: 0,
        terminalChargesDeniedPercentage: 0,
        terminalDenialRate: 0,
        chargesTerminallyDenied: 0,
        terminalDenialRatePer25th: 0,
        terminalDenialRatePer50th: 0,
        terminalDenialRatePer75th: 0,
        terminalDenialRateBenchmark50th: 0
      }]
  };
};

export function aNewPatientVisitSummary(summaryType: number): NewPatientVisitSummary {
  return {
    countOfNewPatientVisits: 66,
    newPatientVisitsPercentage: 77,
    countOfTotalPatientVisits: 88,
    per25th: 99,
    per50th: 99,
    per75th: 99,
    per90th: 99,
    mean: 88,
    startMonth: 1,
    endMonth: 12,
    startYear: 2016,
    endYear: 2016,
    summaryType: summaryType,
    variance25thPercentile: 18,
    variance50thPercentile: 18,
    variance75thPercentile: 18,
    variance90thPercentile: 18,
    varianceMean: 19,
    communityPer25th: 20,
    communityPer50th: 20,
    communityPer65th: 20,
    communityPer75th: 20,
    communityPer90th: 20,
    communityMean: 21,
    communityVariance25thPercentile: 22,
    communityVariance50thPercentile: 22,
    communityVariance65thPercentile: 22,
    communityVariance75thPercentile: 22,
    communityVariance90thPercentile: 22,
    communityVarianceMean: 23
  };
}

export function aZipCodeNewPatientVisitDefault(): ZipCodeNewPatientVisits[] {
  return [
    {
      lat: '0',
      lng: '0',
      zipCode: '12345',
      newPatientVisitCount: 0,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }
  ];
}

export function aBigZipCodeNewPatientVisitDefault(): ZipCodeNewPatientVisits[] {
  return [
    {
      lat: '0',
      lng: '0',
      zipCode: '12345',
      newPatientVisitCount: 10,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '34543',
      newPatientVisitCount: 9,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '324646',
      newPatientVisitCount: 8,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '23455',
      newPatientVisitCount: 7,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '23453',
      newPatientVisitCount: 6,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '24354',
      newPatientVisitCount: 5,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '67898',
      newPatientVisitCount: 4,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '59769',
      newPatientVisitCount: 3,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '46885',
      newPatientVisitCount: 2,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '34557',
      newPatientVisitCount: 1,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }, {
      lat: '0',
      lng: '0',
      zipCode: '08000',
      newPatientVisitCount: 0,
      newPatientVisitPercentage: 0,
      totalPatientVisitCount: 0
    }
  ];
}

export function aNewPatientVisitSummaryData(): SummaryData<NewPatientVisitSummary> {
  return {
    selectedDateRange: aNewPatientVisitSummary(0),
    previousYearSelectedDateRange: aNewPatientVisitSummary(1),
    yearToDate: aNewPatientVisitSummary(2),
    previousYearToDate: aNewPatientVisitSummary(3),
    recentMonth: aNewPatientVisitSummary(4),
    previousYearRecentMonth: aNewPatientVisitSummary(5)
  };
}

export function getRandomNumber(max: number) {
  return getRandomInt(max) + (getRandomInt(1000)) / 1000;
}

export const ofAnyRequest = function (ignored: HttpRequest<any>) {
  return true;
};

export function getCptSummary(
  count: number,
  percentage: number,
  benchmarkPercentage: number = 0,
  totalCount: number = 0
): CptSummary {
  return {
    count: count,
    benchmarkCptUnits: 0,
    benchmarkRangeUnits: 0,
    totalCount: totalCount,
    percentage: percentage,
    benchmarkPercentage: benchmarkPercentage,
    communityBenchmarkPercentage: benchmarkPercentage + 1,
    telehealthBenchmarkPercentage: benchmarkPercentage + 2,
    communityTelehealthBenchmarkPercentage: benchmarkPercentage + 3,
    inPersonBenchmarkPercentage: benchmarkPercentage + 2,
    communityInPersonBenchmarkPercentage: benchmarkPercentage + 3,
    communityBenchmarkCptUnits: count + 7,
    telehealthBenchmarkRangeUnits: count + 8,
    communityTelehealthBenchmarkCptUnits: count + 4,
    communityTelehealthBenchmarkRangeUnits: count + 5,
    inPersonBenchmarkCptUnits: 0,
    inPersonBenchmarkRangeUnits: 0,
    communityInPersonBenchmarkCptUnits: 0,
    communityInPersonBenchmarkRangeUnits: 0,
    communityBenchmarkRangeUnits: 0,
    telehealthBenchmarkCptUnits: 0
  };
}

export function aCptSummary(randomize: boolean = true): CptSummary {
  return {
    count: randomize ? getRandomNumber(100) : 99,
    benchmarkCptUnits: randomize ? getRandomNumber(100) : 99,
    benchmarkRangeUnits: randomize ? getRandomNumber(100) : 99,
    totalCount: randomize ? getRandomNumber(100) : 99,
    percentage: randomize ? getRandomNumber(100) : 99,
    benchmarkPercentage: randomize ? getRandomNumber(100) : 99,
    communityBenchmarkPercentage: randomize ? getRandomNumber(100) : 100,
    telehealthBenchmarkPercentage: randomize ? getRandomNumber(100) : 100,
    communityTelehealthBenchmarkPercentage: randomize ? getRandomNumber(100) : 100,
    inPersonBenchmarkPercentage: randomize ? getRandomNumber(100) : 100,
    communityInPersonBenchmarkPercentage: randomize ? getRandomNumber(100) : 100,
    communityBenchmarkCptUnits: 0,
    telehealthBenchmarkRangeUnits: 0,
    communityTelehealthBenchmarkCptUnits: 0,
    communityTelehealthBenchmarkRangeUnits: 0,
    inPersonBenchmarkCptUnits: 0,
    inPersonBenchmarkRangeUnits: 0,
    communityInPersonBenchmarkCptUnits: 0,
    communityInPersonBenchmarkRangeUnits: 0,
    communityBenchmarkRangeUnits: 0,
    telehealthBenchmarkCptUnits: 0
  };
}

export function aSubsequentHospitalCare(randomize: boolean = true): SubsequentHospitalCare {
  return {
    cpt99231: aCptSummary(randomize),
    cpt99232: aCptSummary(randomize),
    cpt99233: aCptSummary(randomize)
  };
}

export function anInitialHospitalCare(randomize: boolean = true): InitialHospitalCare {
  return {
    cpt99221: aCptSummary(randomize),
    cpt99222: aCptSummary(randomize),
    cpt99223: aCptSummary(randomize)
  };
}

export function aHospitalDischargeDay(randomize: boolean = true): HospitalDischargeDay {
  return {
    cpt99238: aCptSummary(randomize),
    cpt99239: aCptSummary(randomize)
  };
}

export function aConsultation(randomize: boolean = true): Consultation {
  return {
    cpt99241: aCptSummary(randomize),
    cpt99242: aCptSummary(randomize),
    cpt99243: aCptSummary(randomize),
    cpt99244: aCptSummary(randomize),
    cpt99245: aCptSummary(randomize)
  };
}

export function aNewPatientVisit(randomize: boolean = true): NewPatientVisit {
  return {
    cpt99201: aCptSummary(randomize),
    cpt99202: aCptSummary(randomize),
    cpt99203: aCptSummary(randomize),
    cpt99204: aCptSummary(randomize),
    cpt99205: aCptSummary(randomize)
  };
}

export function anEstablishedPatientVisit(randomize: boolean = true): EstablishedPatientVisit {
  return {
    cpt99211: aCptSummary(randomize),
    cpt99212: aCptSummary(randomize),
    cpt99213: aCptSummary(randomize),
    cpt99214: aCptSummary(randomize),
    cpt99215: aCptSummary(randomize)
  };
}

export function anEmergencyMedicine(randomize: boolean = true): EmergencyMedicine {
  return {
    cpt99281: aCptSummary(randomize),
    cpt99282: aCptSummary(randomize),
    cpt99283: aCptSummary(randomize),
    cpt99284: aCptSummary(randomize),
    cpt99285: aCptSummary(randomize)
  };
}

export function anEyeExamNewPatient(randomize: boolean = true): EyeExamNewPatient {
  return {
    cpt92002: aCptSummary(randomize),
    cpt92004: aCptSummary(randomize)
  };
}

export function anEyeExamEstablishedPatient(randomize: boolean = true): EyeExamEstablishedPatient {
  return {
    cpt92012: aCptSummary(randomize),
    cpt92014: aCptSummary(randomize)
  };
}

export function anEvaluationManagementSummary(randomize: boolean = true): EvaluationManagementSummary {
  return {
    startMonth: randomize ? Math.floor(Math.random()) * 12 : 1,
    startYear: randomize ? Math.random() * 9999 : 2000,
    endMonth: randomize ? Math.floor(Math.random()) * 12 : 1,
    endYear: randomize ? Math.random() * 9999 : 2001,
    totals: {
      newPatientVisit: aNewPatientVisit(randomize),
      establishedPatientVisit: anEstablishedPatientVisit(randomize),
      consultation: aConsultation(randomize),
      subsequentHospitalCare: aSubsequentHospitalCare(randomize),
      initialHospitalCare: anInitialHospitalCare(randomize),
      hospitalDischargeDay: aHospitalDischargeDay(randomize),
      emergencyMedicine: anEmergencyMedicine(randomize),
      eyeExamNewPatient: anEyeExamNewPatient(randomize),
      eyeExamEstablishedPatient: anEyeExamEstablishedPatient(randomize)
    }
  };
}

export function anEvaluationManagementMultilevel(): EvaluationManagementMultilevel {
  const nodes = [{
    departmentNodeName: '//sss//string',
    departmentNodeId: 1,
    departmentNodePath: 'string',
    specialtyNodeName: 'string',
    specialtyNodeId: 2,
    specialtyNodePath: '//kk//ll//string',
    providerNodeName: 'string',
    providerNodeId: 3,
    providerNodePath: '//string//jh//gf//ds',
    cptCategories: aCptCategories()
  }];
  const totals = {
    newPatientVisit: {
      cpt99201: getCptSummary(0, 10),
      cpt99202: getCptSummary(0, 20),
      cpt99203: getCptSummary(0, 30),
      cpt99204: getCptSummary(0, 40),
      cpt99205: getCptSummary(0, 50)
    },
    establishedPatientVisit: {
      cpt99211: getCptSummary(0, 11),
      cpt99212: getCptSummary(0, 21),
      cpt99213: getCptSummary(0, 31),
      cpt99214: getCptSummary(0, 41),
      cpt99215: getCptSummary(0, 50)
    },
    consultation: {
      cpt99241: getCptSummary(3, 14),
      cpt99242: getCptSummary(3, 24),
      cpt99243: getCptSummary(3, 34),
      cpt99244: getCptSummary(3, 44),
      cpt99245: getCptSummary(3, 54)
    },
    subsequentHospitalCare: {
      cpt99231: getCptSummary(4, 12),
      cpt99232: getCptSummary(4, 22),
      cpt99233: getCptSummary(4, 32)
    },
    initialHospitalCare: {
      cpt99221: getCptSummary(5, 13, 45),
      cpt99222: getCptSummary(15, 23, 25),
      cpt99223: getCptSummary(25, 33, 55)
    },
    hospitalDischargeDay: {
      cpt99238: getCptSummary(30, 45, 67),
      cpt99239: getCptSummary(40, 46, 68)
    },
    emergencyMedicine: {
      cpt99281: getCptSummary(0, 0, 0),
      cpt99282: getCptSummary(0, 0, 0),
      cpt99283: getCptSummary(0, 0, 0),
      cpt99284: getCptSummary(0, 0, 0),
      cpt99285: getCptSummary(0, 0, 0)
    },
    eyeExamNewPatient: {
      cpt92002: getCptSummary(0, 0, 0),
      cpt92004: getCptSummary(0, 0, 0)
    },
    eyeExamEstablishedPatient: {
      cpt92012: getCptSummary(0, 0, 0),
      cpt92014: getCptSummary(0, 0, 0)
    }
  };
  return {
    evaluationManagementData: {
      departmentEvaluationManagementSnapshotData: {
        nodes: nodes, totals: totals
      },
      specialtyEvaluationManagementSnapshotData: {
        nodes: nodes, totals: totals
      },
      providerEvaluationManagementSnapshotData: {
        nodes: nodes, totals: totals
      },
      providerEvaluationManagementSnapshotDataMonthly: {
        nodes: nodes, totals: totals
      }
    }
  };
}

export function aProductivitySummary(): ProductivitySummary {
  return {
    startYear: DEFAULT_YEAR,
    startMonth: 1,
    endYear: DEFAULT_YEAR,
    endMonth: 12,
    benchmark25thPercentile: 2.4,
    benchmark50thPercentile: 2.6,
    benchmark65thPercentile: 2.5,
    benchmark75thPercentile: 2.3,
    benchmark90thPercentile: 2.1,
    benchmarkMean: 3.0,
    cfte: 33.4,
    cfteAdjustedWRVUs: 123.0,
    charges: 123.09,
    summaryType: 1,
    wRVUs: 123.07,
    variance25thPercentile: 3.01,
    variance50thPercentile: 3.02,
    variance65thPercentile: 3.03,
    variance75thPercentile: 3.04,
    variance90thPercentile: 3.05,
    varianceMean: 9.7,
    communityVarianceMean: 0,
    communityVariance25thPercentile: 0,
    communityVariance50thPercentile: 0,
    communityVariance65thPercentile: 0,
    communityVariance75thPercentile: 0,
    communityVariance90thPercentile: 0,
    communityImputedReportedcFTE25thPercentile: 0,
    communityImputedReportedcFTE50thPercentile: 0,
    communityImputedReportedcFTE65thPercentile: 0,
    communityImputedReportedcFTE75thPercentile: 0,
    communityImputedReportedcFTEMean: 0,
    communityImputedReportedcFTE90thPercentile: 0,
    communityBenchmark25thPercentile: 0,
    communityBenchmark50thPercentile: 0,
    communityBenchmark65thPercentile: 0,
    communityBenchmark75thPercentile: 0,
    communityBenchmark90thPercentile: 0,
    communityBenchmarkMean: 0
  };
}

export function aClinicalSummary(): ClinicalSummaryCode {
  return {
    cptCode: 'hardCoded93',
    cptDesc: 'hardCoded94',
    cptRangeDesc: 'hardCoded95',
    cptFamilyDesc: 'hardCoded96',
    cptRangeLow: 'hardCoded97',
    cptRangeHigh: 'hardCoded97',
    charges: 13.0,
    cfte: 13.01,
    cfteAdjustedWRVUs: 13.02,
    frequency: 13.03,
    totalWorkRVUs: 13.04,
    workRvuBenchmark: 13.05,
    totalWorkRvuBenchmark: 13.06,
    frequencyBenchmark: 13.07,
    cfteAdjustedTotalWRVUs: 13.08,
    cfteAdjustedFrequency: 13.09,
    cFTEAdjustedFrequencyVariance: 14.0,
    cFTEAdjustedWrvusVariance: 13.03,
    cFTEAdjustedTotalWrvusVariance: 13.05,
    communityWorkRvuBenchmark: 13.04,
    communityTotalWorkRvuBenchmark: 13.05,
    communityFrequencyBenchmark: 13.03,
    communityCfteAdjustedFrequencyVariance: 13.02,
    communityCfteAdjustedWrvusVariance: 13.09,
    communityCfteAdjustedTotalWrvusVariance: 13.10,
    wRVUs: 87.2,
    countTornado: {
      positive: true
    },
    wrvuTornado: {
      positive: true
    }
  };
}

export function aClinicalSummaryWithNoBenchmarks(): ClinicalSummaryCode {
  return {
    cptCode: 'code',
    cptDesc: 'desc',
    cptRangeDesc: 'crd',
    cptFamilyDesc: 'cfd',
    cptRangeLow: 'crl',
    cptRangeHigh: 'crh',
    charges: 22,
    cfte: 20,
    cfteAdjustedWRVUs: 10,
    frequency: 34,
    totalWorkRVUs: 35,
    workRvuBenchmark: 0,
    totalWorkRvuBenchmark: 0,
    frequencyBenchmark: 0,
    cfteAdjustedTotalWRVUs: 36,
    cfteAdjustedFrequency: 54,
    cFTEAdjustedFrequencyVariance: 72,
    cFTEAdjustedWrvusVariance: 90,
    cFTEAdjustedTotalWrvusVariance: 100,
    communityWorkRvuBenchmark: 99,
    communityTotalWorkRvuBenchmark: 98,
    communityFrequencyBenchmark: 97,
    communityCfteAdjustedFrequencyVariance: 96,
    communityCfteAdjustedWrvusVariance: 95,
    communityCfteAdjustedTotalWrvusVariance: 94,
    wRVUs: 93,
    countTornado: {
      positive: true
    },
    wrvuTornado: {
      positive: true
    }
  };
}

export function aClinicalSummaryRange(): ClinicalSummaryRange {
  return {
    cptRangeDesc: 'not rand',
    cptFamilyDesc: 'not a random string',
    cptRangeLow: 'low',
    cptRangeHigh: 'high',
    charges: 1,
    cfte: 0,
    cfteAdjustedWRVUs: 2,
    frequency: 3,
    totalWorkRVUs: 4,
    workRvuBenchmark: 5,
    totalWorkRvuBenchmark: 6,
    frequencyBenchmark: 7,
    cfteAdjustedTotalWRVUs: 8,
    cfteAdjustedFrequency: 9,
    cFTEAdjustedFrequencyVariance: 10,
    cFTEAdjustedWrvusVariance: 11,
    cFTEAdjustedTotalWrvusVariance: 12,
    wRVUs: 13,
    communityWorkRvuBenchmark: 14, // Community cFTE Adj. wRVUs Benchmark
    communityTotalWorkRvuBenchmark: 15,
    communityFrequencyBenchmark: 16, // Community CPSC cFTE Adj. Count
    communityCfteAdjustedFrequencyVariance: 17, // Community cFTE Adj. Count Variance from Benchmark
    communityCfteAdjustedWrvusVariance: 18, // Community cFTE Adj. wRVUs Variance from Benchmark
    communityCfteAdjustedTotalWrvusVariance: 19
  };
}

export function aClinicalSummaryRangeWithNoBenchmarks(): ClinicalSummaryRange {
  return {
    cptRangeDesc: 'not a random',
    cptFamilyDesc: 'desc',
    cptRangeLow: 'low',
    cptRangeHigh: 'high',
    charges: 12,
    cfte: 13,
    cfteAdjustedWRVUs: 14,
    frequency: 15,
    totalWorkRVUs: 16,
    workRvuBenchmark: 0,
    totalWorkRvuBenchmark: 0,
    frequencyBenchmark: 0,
    cfteAdjustedTotalWRVUs: 20,
    cfteAdjustedFrequency: 21,
    cFTEAdjustedFrequencyVariance: 22,
    cFTEAdjustedWrvusVariance: 23,
    cFTEAdjustedTotalWrvusVariance: 24,
    communityWorkRvuBenchmark: 25, // Community cFTE Adj. wRVUs Benchmark
    communityTotalWorkRvuBenchmark: 26,
    communityFrequencyBenchmark: 27, // Community CPSC cFTE Adj. Count
    communityCfteAdjustedFrequencyVariance: 28, // Community cFTE Adj. Count Variance from Benchmark
    communityCfteAdjustedWrvusVariance: 29, // Community cFTE Adj. wRVUs Variance from Benchmark
    communityCfteAdjustedTotalWrvusVariance: 30,
    wRVUs: 31
  };
}

export function aClinicalSummaryFamily(): ClinicalSummaryFamily {
  return {
    cptFamilyDesc: 'fam',
    charges: 73,
    cfte: 0,
    cfteAdjustedWRVUs: 34,
    frequency: 34,
    totalWorkRVUs: 34,
    workRvuBenchmark: 34,
    totalWorkRvuBenchmark: 65,
    frequencyBenchmark: 65,
    cfteAdjustedTotalWRVUs: 65,
    cfteAdjustedFrequency: 65,
    cFTEAdjustedFrequencyVariance: 99,
    cFTEAdjustedWrvusVariance: 99,
    cFTEAdjustedTotalWrvusVariance: 99,
    communityWorkRvuBenchmark: 32, // Community cFTE Adj. wRVUs Benchmark
    communityTotalWorkRvuBenchmark: 90,
    communityFrequencyBenchmark: 55, // Community CPSC cFTE Adj. Count
    communityCfteAdjustedFrequencyVariance: 77, // Community cFTE Adj. Count Variance from Benchmark
    communityCfteAdjustedWrvusVariance: 44, // Community cFTE Adj. wRVUs Variance from Benchmark
    communityCfteAdjustedTotalWrvusVariance: 33,
    wRVUs: 22
  };
}

export function aClinicalSummaryFamilyWithNoBenchmarks(): ClinicalSummaryFamily {
  return {
    cptFamilyDesc: 'hardCoded98',
    charges: 22.0,
    cfte: 0,
    cfteAdjustedWRVUs: 22.01,
    frequency: 22.02,
    totalWorkRVUs: 22.03,
    workRvuBenchmark: 0,
    totalWorkRvuBenchmark: 0,
    frequencyBenchmark: 0,
    cfteAdjustedTotalWRVUs: 77.01,
    cfteAdjustedFrequency: 77.02,
    cFTEAdjustedFrequencyVariance: 77.03,
    cFTEAdjustedWrvusVariance: 77.04,
    cFTEAdjustedTotalWrvusVariance: 77.05,
    communityWorkRvuBenchmark: 0, // Community cFTE Adj. wRVUs Benchmark
    communityTotalWorkRvuBenchmark: 0,
    communityFrequencyBenchmark: 0, // Community CPSC cFTE Adj. Count
    communityCfteAdjustedFrequencyVariance: 99.7, // Community cFTE Adj. Count Variance from Benchmark
    communityCfteAdjustedWrvusVariance: 44.7, // Community cFTE Adj. wRVUs Variance from Benchmark
    communityCfteAdjustedTotalWrvusVariance: 11.2,
    wRVUs: 133.4
  };
}

export function oneHundredCptCodes(): ClinicalSummaryCode[] {
  const aHundredCptCodes: ClinicalSummaryCode[] = [];
  for (let i = 0; i < 100; i++) {
    aHundredCptCodes.push(aClinicalSummary());
  }
  return aHundredCptCodes;
}

export function aBlankProductivitySummaryExport(): ProviderProductivityExport {
  return {
    'Time Ranges': ' ',
    'benchmarkMean': ' ',
    'benchmark25th': ' ',
    'benchmark50th': ' ',
    'benchmark65th': ' ',
    'benchmark75th': ' ',
    'benchmark90th': ' ',
    'wRVUs': ' ',
    'cfte': ' ',
    'charges': ' ',
    'cfteAdjustedWRVU': ' ',
    'imputedReportedCfteMean': ' ',
    'imputedReportedCfte25th': ' ',
    'imputedReportedCfte50th': ' ',
    'imputedReportedCfte65th': ' ',
    'imputedReportedCfte75th': ' ',
    'imputedReportedCfte90th': ' ',
    'varianceMean': ' ',
    'variance25th': ' ',
    'variance50th': ' ',
    'variance65th': ' ',
    'variance75th': ' ',
    'variance90th': ' ',
    'previousCfteAdjustedWRVU': ' ',
    'difference': ' '
  };
}

export function aBlankMonthProductivityExport(): MonthProductivityExport {
  return {
    'Time Range': ' ',
    cFTEAdjustedwRVUs: ' ',
    benchmarkMean: ' ',
    benchmark25th: ' ',
    benchmark50th: ' ',
    benchmark65th: ' ',
    benchmark75th: ' ',
    benchmark90th: ' ',
    wRVUs: ' ',
    cfte: ' ',
    charges: ' ',
    varianceMean: ' ',
    variance25th: ' ',
    variance50th: ' ',
    variance65th: ' ',
    variance75th: ' ',
    variance90th: ' ',
    previousCfteAdjustedWRVUs: ' ',
    difference: ' '
  };
}

export function aBlankMonthNewPatientVisitsExport(): MonthNewPatientVisitsExport {

  return {
    date: ' ',
    newPatientVisitsPercentage: ' ',
    countOfNewPatientVisits: ' ',
    countOfTotalPatientVisits: ' ',
    benchmarkMean: ' ',
    benchmark25thPercentile: ' ',
    benchmark50thPercentile: ' ',
    benchmark75thPercentile: ' ',
    benchmark90thPercentile: ' ',
    varianceMean: ' ',
    variance25thPercentile: ' ',
    variance50thPercentile: ' ',
    variance75thPercentile: ' ',
    variance90thPercentile: ' ',
    previousNewPatientVisitsPercentage: ' ',
    difference: ' '
  };
}

export function aProductivitySummaryIntialized(): ProductivitySummary {
  return {
    startYear: 0,
    startMonth: 0,
    endYear: 0,
    endMonth: 0,
    benchmark25thPercentile: 0,
    benchmark50thPercentile: 0,
    benchmark65thPercentile: 0,
    benchmark75thPercentile: 0,
    benchmark90thPercentile: 0,
    benchmarkMean: 0,
    variance25thPercentile: 0,
    variance50thPercentile: 0,
    variance65thPercentile: 0,
    variance75thPercentile: 0,
    variance90thPercentile: 0,
    varianceMean: 0,
    cfte: 0,
    cfteAdjustedWRVUs: 0,
    charges: 0,
    summaryType: 0,
    wRVUs: 0,
    communityVarianceMean: 0,
    communityVariance25thPercentile: 0,
    communityVariance50thPercentile: 0,
    communityVariance65thPercentile: 0,
    communityVariance75thPercentile: 0,
    communityVariance90thPercentile: 0,
    communityImputedReportedcFTE25thPercentile: 0,
    communityImputedReportedcFTE50thPercentile: 0,
    communityImputedReportedcFTE65thPercentile: 0,
    communityImputedReportedcFTE75thPercentile: 0,
    communityImputedReportedcFTEMean: 0,
    communityImputedReportedcFTE90thPercentile: 0,
    communityBenchmark25thPercentile: 0,
    communityBenchmark50thPercentile: 0,
    communityBenchmark65thPercentile: 0,
    communityBenchmark75thPercentile: 0,
    communityBenchmark90thPercentile: 0,
    communityBenchmarkMean: 0
  };
}

export function aUserPreference(): UserPreference {
  return {
    ...DEFAULT_USER_PREFERENCES,
    id: 1,
    emailId: 'user@email.com',
    memberKey: DEFAULT_MEMBER_KEY,
    isDefault: false
  };
}

export function aMembersDefault(): Members {
  return {
    members: [
      {memberKey: -1, memberDesc: ''}
    ]
  };
}

export function aMembers(): Members {
  return {
    members: [
      {memberKey: 512, memberDesc: 'Member Name1'},
      {memberKey: 513, memberDesc: 'Member Name2'},
      {memberKey: 514, memberDesc: 'Member Name3'}
    ]
  };
}

export function aMemberLocations(): MemberLocation[] {
  return [
    {memberLocationKey: 991, memberLocationName: 'Location Name1', memberLocationCode: 'LN1'},
    {memberLocationKey: 992, memberLocationName: 'Location Name2', memberLocationCode: 'LN2'},
    {memberLocationKey: 993, memberLocationName: 'Location Name3', memberLocationCode: 'LN3'}
  ];
}

export function aPayerCategories(): PayerCategory[] {
  return [
    {payerCategoryKey: 770, payerCategoryDescription: 'Payer Category 1', payerCategoryCode: 'PC1'},
    {payerCategoryKey: 771, payerCategoryDescription: 'Payer Category 2', payerCategoryCode: 'PC2'},
    {payerCategoryKey: 772, payerCategoryDescription: 'Payer Category 3', payerCategoryCode: 'PC3'}
  ];
}

export function aPayerHierarchy(): PayerCategoryLevel[] {
  return [aPayerCategoryObject(), aPayerCategoryObject(), aPayerCategoryObject()];
}

export function aPayerCategoryObject(): PayerCategoryLevel {
  return {
    payerCategoryDesc: 'hardCoded99',
    payerCategoryKey: 13,
    nationalPayers: [aNationalPayer(), aNationalPayer(), aNationalPayer()]
  };
}

export function aNationalPayer(): NationalPayerLevel {
  return {
    nationalPayerDesc: 'hardCoded100',
    nationalPayerKey: 14,
    memberPayers: [aMemberPayer(), aMemberPayer(), aMemberPayer(), aMemberPayer()]
  };
}

export function aMemberPayer(): MemberPayerLevel {
  return {
    memberPayerDesc: 'hardCoded101',
    memberPayerKey: 15
  };
}

export function aFeatureToggleSettings(): FeatureToggleSetting[] {
  return [
    {featureId: 1, featureName: 'F1', enabled: false},
    {featureId: 2, featureName: 'F2', enabled: false},
    {featureId: 3, featureName: 'F3', enabled: false},
    {featureId: 4, featureName: 'F4', enabled: true}
  ];
}

export function aApplicationConfigurationSettings(): ApplicationConfigurationSetting[] {
  return [
    {
      appConfigId: 1,
      appConfigName: '',
      appConfigDesc: ''
    },
    {
      appConfigId: 2,
      appConfigName: '',
      appConfigDesc: ''
    },
    {
      appConfigId: 3,
      appConfigName: '',
      appConfigDesc: ''
    }
  ];
}

export function aFeatureToggleSettingsDefault(): FeatureToggleSetting[] {
  return [];
}

export function aUserProfile(): UserProfile {
  return {
    userName: 'aUserName',
    lastName: 'LastName',
    firstName: 'John',
    email: 'email@email.com',
    city: 'Chicago',
    isEmployee: false,
    userTrackingKey: '',
    jobTitle: 'Admin'
  };
}

export function aUserProfileDefault(): UserProfile {
  return {
    userName: '',
    lastName: '',
    firstName: '',
    email: '',
    city: '',
    isEmployee: false,
    userTrackingKey: '',
    jobTitle: ''
  };
}

export function aMonthProductivity(month?: MonthNumber, previous = false): MonthProductivityEntry {
  return {
    year: previous ? 2015 : 2016,
    month: month || '1',
    wRVUs: 10,
    charges: 0,
    cfteAdjustedWRVUs: 0,
    cfte: 0,
    benchmark25thPercentile: 25.0,
    benchmark50thPercentile: 50.0,
    benchmark65thPercentile: 65.0,
    benchmark75thPercentile: 75.0,
    benchmark90thPercentile: 90.0,
    benchmarkMean: 51.0,
    varianceMean: 0,
    variance25thPercentile: 0,
    variance50thPercentile: 0,
    variance65thPercentile: 0,
    variance75thPercentile: 0,
    variance90thPercentile: 0,
    communityBenchmark25thPercentile: 0.0,
    communityBenchmark50thPercentile: 0.0,
    communityBenchmark65thPercentile: 0.0,
    communityBenchmark75thPercentile: 0.0,
    communityBenchmark90thPercentile: 0.0,
    communityBenchmarkMean: 0.0,
    communityVarianceMean: 0,
    communityVariance25thPercentile: 0,
    communityVariance50thPercentile: 0,
    communityVariance65thPercentile: 0,
    communityVariance75thPercentile: 0,
    communityVariance90thPercentile: 0
  };
}

export function aMergedProductivityTrendData(): MergedProductivityTrendEntry[] {
  return [
    {
      year: 2016,
      month: '1',
      wRVUs: 10,
      cfteAdjustedWRVUs: 0,
      cfte: 0,
      charges: 888,
      variance25thPercentile: 888,
      variance50thPercentile: 888,
      variance65thPercentile: 888,
      variance75thPercentile: 888,
      variance90thPercentile: 888,
      varianceMean: 888,
      benchmark25thPercentile: 888,
      benchmark50thPercentile: 888,
      benchmark65thPercentile: 888,
      benchmark75thPercentile: 888,
      benchmark90thPercentile: 888,
      communityBenchmark25thPercentile: 666,
      communityBenchmark50thPercentile: 666,
      communityBenchmark65thPercentile: 666,
      communityBenchmark75thPercentile: 666,
      communityBenchmark90thPercentile: 666,
      communityBenchmarkMean: 111,
      communityVarianceMean: 111,
      communityVariance25thPercentile: 111,
      communityVariance50thPercentile: 111,
      communityVariance65thPercentile: 444,
      communityVariance75thPercentile: 444,
      communityVariance90thPercentile: 444,
      benchmarkMean: 2222,
      previousWrvus: 2222,
      previousCfteAdjustedWRVUs: 2222,
      previousCfte: 123,
      previousCharges: 123,
      previousBenchmark25thPercentile: 123,
      previousBenchmark50thPercentile: 7777,
      previousBenchmark65thPercentile: 7777,
      previousBenchmark75thPercentile: 7777,
      previousBenchmark90thPercentile: 7777,
      previousBenchmarkMean: 456
    }];
}

export function aMonthProductivityData(previous = false): MonthProductivity {
  return {
    monthProductivities: [aMonthProductivity('1', previous), aMonthProductivity('2', previous)]
  };
}

export function aMonthProductivityDataExample(): MonthProductivityData {
  return {
    monthProductivityData: aMonthProductivityData()
  };
}

export function aMergedProductivityTrendEntryDefault(): MergedProductivityTrendEntry {
  return {
    year: 0,
    month: '1',
    wRVUs: 0,
    cfteAdjustedWRVUs: 0,
    cfte: 0,
    charges: 0,
    variance25thPercentile: 0,
    variance50thPercentile: 0,
    variance65thPercentile: 0,
    variance75thPercentile: 0,
    variance90thPercentile: 0,
    varianceMean: 0,
    difference: 0,
    benchmark25thPercentile: 0.0,
    benchmark50thPercentile: 0.0,
    benchmark65thPercentile: 0.0,
    benchmark75thPercentile: 0.0,
    benchmark90thPercentile: 0.0,
    benchmarkMean: 0.0,
    communityBenchmark25thPercentile: 0.0,
    communityBenchmark50thPercentile: 0.0,
    communityBenchmark65thPercentile: 0.0,
    communityBenchmark75thPercentile: 0.0,
    communityBenchmark90thPercentile: 0.0,
    communityBenchmarkMean: 0.0,
    communityVarianceMean: 0,
    communityVariance25thPercentile: 0,
    communityVariance50thPercentile: 0,
    communityVariance65thPercentile: 0,
    communityVariance75thPercentile: 0,
    communityVariance90thPercentile: 0,
    previousWrvus: 0,
    previousCfteAdjustedWRVUs: 0,
    previousCfte: 0,
    previousCharges: 0,
    previousBenchmark25thPercentile: 0.0,
    previousBenchmark50thPercentile: 0.0,
    previousBenchmark65thPercentile: 0.0,
    previousBenchmark75thPercentile: 0.0,
    previousBenchmark90thPercentile: 0.0,
    previousBenchmarkMean: 0.0,
    previousCommunityBenchmark25thPercentile: 0.0,
    previousCommunityBenchmark50thPercentile: 0.0,
    previousCommunityBenchmark65thPercentile: 0.0,
    previousCommunityBenchmark75thPercentile: 0.0,
    previousCommunityBenchmark90thPercentile: 0.0,
    previousCommunityBenchmarkMean: 0.0
  };
}

export function aMergedProductivityTrendEntry(): MergedProductivityTrendEntry {
  return {
    year: 2002,
    month: '1',
    wRVUs: 123,
    cfteAdjustedWRVUs: 600,
    cfte: 6,
    charges: 2155,
    variance25thPercentile: 1112,
    variance50thPercentile: 1113,
    variance65thPercentile: 1114,
    variance75thPercentile: 1115,
    variance90thPercentile: 1116,
    varianceMean: 1023,
    benchmark25thPercentile: 1121,
    benchmark50thPercentile: 1122,
    benchmark65thPercentile: 1123,
    benchmark75thPercentile: 1124,
    benchmark90thPercentile: 1125,
    communityBenchmark25thPercentile: 999,
    communityBenchmark50thPercentile: 878,
    communityBenchmark65thPercentile: 989,
    communityBenchmark75thPercentile: 556,
    communityBenchmark90thPercentile: 6656,
    communityBenchmarkMean: 7777,
    communityVarianceMean: 4466,
    communityVariance25thPercentile: 5544,
    communityVariance50thPercentile: 22,
    communityVariance65thPercentile: 223,
    communityVariance75thPercentile: 224,
    communityVariance90thPercentile: 225,
    benchmarkMean: 22,
    previousWrvus: 223,
    previousCfteAdjustedWRVUs: 2234,
    previousCfte: 221,
    previousCharges: 2213,
    previousBenchmark25thPercentile: 2255,
    previousBenchmark50thPercentile: 225,
    previousBenchmark65thPercentile: 2111,
    previousBenchmark75thPercentile: 2144,
    previousBenchmark90thPercentile: 2142,
    previousBenchmarkMean: 2149
  };
}

export function aNewPatientVisitTrendDataDefault(): NewPatientVisitTrendEntry {
  return {
    month: '1',
    year: 0,
    countOfNewPatientVisits: 0.0,
    countOfTotalPatientVisits: 0.0,
    newPatientVisitsPercentage: 0.0,
    countOfExistingPatientVisits: 0.0,
    isHidden: false,
    previous: {
      month: '1',
      year: 0,
      countOfNewPatientVisits: 0.0,
      countOfTotalPatientVisits: 0.0,
      newPatientVisitsPercentage: 0.0,
      countOfExistingPatientVisits: 0.0,
      isHidden: false,
      difference: 0,
      newPatientVisitsPercentageBenchmarks: {
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0
      },
      newPatientVisitsCommunityPercentageBenchmarks: {
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0
      },
      newPatientVisitsTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0
      },
      newPatientVisitsInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0
      },
      newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0
      },
      newPatientVisitsCommunityInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0
      },
      newPatientVisitsVariance: {
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0
      },
      newPatientVisitsCommunityVariance: {
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0
      },
      newPatientVisitsTelehealthVariance: {
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0
      },
      newPatientVisitsInPersonVariance: {
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0
      },
      newPatientVisitsCommunityTelehealthVariance: {
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0
      },
      newPatientVisitsCommunityInPersonVariance: {
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0
      },
    },
    difference: 0,
    newPatientVisitsPercentageBenchmarks: {
      benchmark25thPercentile: 0,
      benchmark50thPercentile: 0,
      benchmark75thPercentile: 0,
      benchmark90thPercentile: 0,
      benchmarkMean: 0
    },
    newPatientVisitsCommunityPercentageBenchmarks: {
      benchmark25thPercentile: 0,
      benchmark50thPercentile: 0,
      benchmark75thPercentile: 0,
      benchmark90thPercentile: 0,
      benchmarkMean: 0
    },
    newPatientVisitsTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 0,
      benchmark50thPercentile: 0,
      benchmark75thPercentile: 0,
      benchmark90thPercentile: 0,
      benchmarkMean: 0
    },
    newPatientVisitsInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 0,
      benchmark50thPercentile: 0,
      benchmark75thPercentile: 0,
      benchmark90thPercentile: 0,
      benchmarkMean: 0
    },
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 0,
      benchmark50thPercentile: 0,
      benchmark75thPercentile: 0,
      benchmark90thPercentile: 0,
      benchmarkMean: 0
    },
    newPatientVisitsCommunityInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 0,
      benchmark50thPercentile: 0,
      benchmark75thPercentile: 0,
      benchmark90thPercentile: 0,
      benchmarkMean: 0
    },
    newPatientVisitsVariance: {
      variance25thPercentile: 0,
      variance50thPercentile: 0,
      variance75thPercentile: 0,
      variance90thPercentile: 0,
      varianceMean: 0
    },
    newPatientVisitsCommunityVariance: {
      variance25thPercentile: 0,
      variance50thPercentile: 0,
      variance75thPercentile: 0,
      variance90thPercentile: 0,
      varianceMean: 0
    },
    newPatientVisitsTelehealthVariance: {
      variance25thPercentile: 0,
      variance50thPercentile: 0,
      variance75thPercentile: 0,
      variance90thPercentile: 0,
      varianceMean: 0
    },
    newPatientVisitsInPersonVariance: {
      variance25thPercentile: 0,
      variance50thPercentile: 0,
      variance75thPercentile: 0,
      variance90thPercentile: 0,
      varianceMean: 0
    },
    newPatientVisitsCommunityTelehealthVariance: {
      variance25thPercentile: 0,
      variance50thPercentile: 0,
      variance75thPercentile: 0,
      variance90thPercentile: 0,
      varianceMean: 0
    },
    newPatientVisitsCommunityInPersonVariance: {
      variance25thPercentile: 0,
      variance50thPercentile: 0,
      variance75thPercentile: 0,
      variance90thPercentile: 0,
      varianceMean: 0
    },
  };
}

export function aNewPatientVisitTrendData(month?: MonthNumber): NewPatientVisitTrendEntry {
  return {
    month: month || '1',
    year: 2016,
    countOfNewPatientVisits: 1,
    countOfTotalPatientVisits: 1,
    newPatientVisitsPercentage: 1,
    previous: {
      month: month || '1',
      year: 2016,
      countOfNewPatientVisits: 1,
      countOfTotalPatientVisits: 1,
      newPatientVisitsPercentage: 1,
      countOfExistingPatientVisits: 4,
      isHidden: false,
      difference: 2,
      newPatientVisitsPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsCommunityPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsCommunityInPersonPercentageBenchmarks: {
        benchmark25thPercentile: 25,
        benchmark50thPercentile: 30,
        benchmark75thPercentile: 35,
        benchmark90thPercentile: 40,
        benchmarkMean: 45
      },
      newPatientVisitsVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsCommunityVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsTelehealthVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsInPersonVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsCommunityTelehealthVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
      newPatientVisitsCommunityInPersonVariance: {
        variance25thPercentile: 88,
        variance50thPercentile: 88,
        variance75thPercentile: 88,
        variance90thPercentile: 88,
        varianceMean: 88
      },
    },
    countOfExistingPatientVisits: 4,
    isHidden: false,
    difference: 2,
    newPatientVisitsPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsCommunityInPersonPercentageBenchmarks: {
      benchmark25thPercentile: 25,
      benchmark50thPercentile: 30,
      benchmark75thPercentile: 35,
      benchmark90thPercentile: 40,
      benchmarkMean: 45
    },
    newPatientVisitsVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityTelehealthVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
    newPatientVisitsCommunityInPersonVariance: {
      variance25thPercentile: 88,
      variance50thPercentile: 88,
      variance75thPercentile: 88,
      variance90thPercentile: 88,
      varianceMean: 88
    },
  };
}

export const aMultilevelProviderProductivityDataDefault = function (): ProviderProductivityMultiLevelData {
  return {
    providerProductivities: [
      {
        id: 0,
        nodeId: 0,
        nodeName: '',
        nodePath: '',
        providerNodeId: 0,
        providerNodeName: '',
        providerNodePath: '',
        specialtyNodeId: 0,
        specialtyNodeName: '',
        specialtyNodePath: '',
        departmentNodeId: 0,
        departmentNodeName: '',
        departmentNodePath: '',
        cfte: 0,
        charges: 0,
        wRVUs: 0,
        cfteAdjustedWRVUs: 0,
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0,
        benchmark65thPercentile: 0,
        benchmarkPercentile: 0,
        communityBenchmark25thPercentile: 0,
        communityBenchmark50thPercentile: 0,
        communityBenchmark75thPercentile: 0,
        communityBenchmark90thPercentile: 0,
        communityBenchmarkMean: 0,
        communityBenchmark65thPercentile: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        varianceMean: 0,
        difference: 0,
        benchmarkPercentileRank: 0,
        communityBenchmarkPercentileRank: 0
      }
    ],
    specialtyProductivities: [
      {
        id: 0,
        nodeId: 0,
        nodeName: '',
        nodePath: '',
        providerNodeId: 0,
        providerNodeName: '',
        providerNodePath: '',
        specialtyNodeId: 0,
        specialtyNodeName: '',
        specialtyNodePath: '',
        departmentNodeId: 0,
        departmentNodeName: '',
        departmentNodePath: '',
        cfte: 0,
        charges: 0,
        wRVUs: 0,
        cfteAdjustedWRVUs: 0,
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0,
        benchmark65thPercentile: 0,
        benchmarkPercentile: 0,
        communityBenchmark25thPercentile: 0,
        communityBenchmark50thPercentile: 0,
        communityBenchmark75thPercentile: 0,
        communityBenchmark90thPercentile: 0,
        communityBenchmarkMean: 0,
        communityBenchmark65thPercentile: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        difference: 0
      }
    ],
    departmentProductivities: [
      {
        id: 0,
        nodeId: 0,
        nodeName: '',
        nodePath: '',
        providerNodeId: 0,
        providerNodeName: '',
        providerNodePath: '',
        specialtyNodeId: 0,
        specialtyNodeName: '',
        specialtyNodePath: '',
        departmentNodeId: 0,
        departmentNodeName: '',
        departmentNodePath: '',
        cfte: 0,
        charges: 0,
        wRVUs: 0,
        cfteAdjustedWRVUs: 0,
        benchmark25thPercentile: 0,
        benchmark50thPercentile: 0,
        benchmark75thPercentile: 0,
        benchmark90thPercentile: 0,
        benchmarkMean: 0,
        benchmark65thPercentile: 0,
        benchmarkPercentile: 0,
        communityBenchmark25thPercentile: 0,
        communityBenchmark50thPercentile: 0,
        communityBenchmark75thPercentile: 0,
        communityBenchmark90thPercentile: 0,
        communityBenchmarkMean: 0,
        communityBenchmark65thPercentile: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance65thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        communityImputedReportedcFTE25thPercentile: 0,
        communityImputedReportedcFTE50thPercentile: 0,
        communityImputedReportedcFTE65thPercentile: 0,
        communityImputedReportedcFTE75thPercentile: 0,
        communityImputedReportedcFTEMean: 0,
        communityImputedReportedcFTE90thPercentile: 0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        imputedReportedcFTE50thPercentile: 0,
        imputedReportedcFTE65thPercentile: 0,
        imputedReportedcFTE75thPercentile: 0,
        imputedReportedcFTEMean: 0,
        imputedReportedcFTE90thPercentile: 0,
        imputedReportedcFTE25thPercentile: 0,
        varianceMean: 0,
        difference: 0
      }
    ]
  };
};

export const aProviderProductivityMultiLevelResponseDefault = function (): ProviderProductivityMultiLevelResponse {
  return {
    providerProductivitiesData: aMultilevelProviderProductivityDataDefault()
  };
};

export const anOverviewChartOptions = function (): OverviewChartOptions {
  return {
    title: {
      text: ''
    },
    series: [
      {
        name: '% New Patients',
        yAxis: 0,
        data: [
          4.2,
          4.6
        ],
        type: ChartType.LINE,
        colorIndex: 3,
        'stack': 0,
        'zIndex': 1
      },
      {
        name: '% New Patients',
        yAxis: 0,
        data: [
          25,
          50,
          75,
          100
        ],
        type: ChartType.LINE,
        colorIndex: 4,
        stack: 0,
        zIndex: 1
      }
    ],
    legend: {
      enabled: false
    },
    tooltip: {
      shared: true,
      valueDecimals: 1,
      pointFormat: ''
    },
    chart: {
      type: ChartType.LINE,
      backgroundColor: 'transparent',
      width: 616,
      height: 450
    },
    xAxis: [
      {
        categories: [
          'Dec 2016',
          'Nov 2016'
        ],
        crosshair: {
          color: 'ccd6eb'
        },
        max: 11,
        labels: {
          rotation: 45
        }
      }
    ],
    yAxis: [
      {
        title: {
          text: ''
        },
        max: 5.6,
        labels: {}
      }
    ],
    showFullScrollbar: false,
    enableScrollbar: false,
    legends: []
  };
};

export const aProductivitySnapshot = function (): ProductivitySnapshot {
  const negative = 69 % 2 === 0;
  return {
    nodeName: 'hardCoded102',
    nodeId: 69,
    nodePath: 'hardCoded103',
    benchmark25thPercentile: 61,
    benchmark50thPercentile: 92,
    benchmark65thPercentile: 69,
    benchmark75thPercentile: 61,
    benchmark90thPercentile: 61,
    benchmarkMean: 63,
    benchmarkPercentile: 0,
    wRVUs: 63,
    cfteAdjustedWRVUs: 65,
    cfte: 66,
    charges: 65,
    variance25thPercentile: negative ? -77 : 64,
    variance50thPercentile: negative ? -77 : 64,
    variance65thPercentile: negative ? -77 : 64,
    variance75thPercentile: negative ? -77 : 64,
    variance90thPercentile: negative ? -77 : 64,
    varianceMean: negative ? -99 : 99,
    imputedReportedcFTE25thPercentile: 99,
    imputedReportedcFTE50thPercentile: 99,
    imputedReportedcFTE65thPercentile: 99,
    imputedReportedcFTE75thPercentile: 96,
    imputedReportedcFTEMean: 96,
    imputedReportedcFTE90thPercentile: 96,
    previousBenchmark25thPercentile: 64,
    previousBenchmark50thPercentile: 64,
    previousBenchmark65thPercentile: 64,
    previousBenchmark75thPercentile: 64,
    previousBenchmark90thPercentile: 88,
    previousBenchmarkMean: 88,
    previousWrvus: 88,
    previousCfteAdjustedWRVUs: 88,
    previousCfte: 64,
    previousCharges: 65,
    previousImputedReportedcFTE25thPercentile: 65,
    previousImputedReportedcFTE50thPercentile: 62,
    previousImputedReportedcFTE65thPercentile: 63,
    previousImputedReportedcFTE75thPercentile: 61,
    previousImputedReportedcFTEMean: 60,
    previousImputedReportedcFTE90thPercentile: 12,
    communityVarianceMean: negative ? 13 * -1 : 14,
    communityVariance25thPercentile: negative ? -88 : 77,
    communityVariance50thPercentile: negative ? -88 : 77,
    communityVariance65thPercentile: negative ? -88 : 77,
    communityVariance75thPercentile: negative ? -88 : 77,
    communityVariance90thPercentile: negative ? -88 : 77,
    communityImputedReportedcFTE25thPercentile: 0,
    communityImputedReportedcFTE50thPercentile: 0,
    communityImputedReportedcFTE65thPercentile: 0,
    communityImputedReportedcFTE75thPercentile: 0,
    communityImputedReportedcFTEMean: 0,
    communityImputedReportedcFTE90thPercentile: 0,
    communityBenchmark25thPercentile: 65,
    communityBenchmark50thPercentile: 65,
    communityBenchmark65thPercentile: 65,
    communityBenchmark75thPercentile: 33,
    communityBenchmark90thPercentile: 33,
    communityBenchmarkMean: 33
  };
};

export const aMatchedCollectionsByMultiLevelByNodePath = function (): MatchedCollectionsByMultiLevelByNodePath {
  return {
    departmentNodeId: -1,
    departmentNodeName: '',
    departmentNodePath: '',
    specialtyNodeId: -1,
    specialtyNodeName: '',
    specialtyNodePath: '',
    providerNodeId: -1,
    providerNodeName: '',
    providerNodePath: '',
    chargeAmount: -1,
    charityCareAmount: -1,
    discountAmount: -1,
    patientPaymentAmount: -1,
    primaryPayerPaymentAmount: -1,
    otherPayerPaymentAmount: -1,
    primaryPayerAdjAmount: -1,
    otherPayerAdjAmount: -1,
    primaryAndOtherPayerContractuals: -1,
    primaryAndOtherInsurancePayments: -1,
    expectedPayments: -1,
    totalPayments: -1,
    netCollectionRate: -1,
    benchmark25th: -1,
    benchmark50th: -1,
    benchmark75th: -1,
    controllableAllowance: -1,
    patientRelatedBadDebt: -1,
    smallBalanceWriteOffs: -1,
    paymentAtBenchmark25th: -1,
    paymentAtBenchmark50th: -1,
    paymentAtBenchmark75th: -1,
    variance25th: -1,
    variance50th: -1,
    variance75th: -1,
    outstandingAR: -1,
    outstandingARAsPctOfCharges: -1
  };
};

export const aPayerCollectionsDefault = function (): PayerCollections {
  return {
    payerCategoryKey: 0,
    payerCategoryDescription: '',
    chargeAmount: 0,
    charityCareAmount: 0,
    discountAmount: 0,
    patientPaymentAmount: 0,
    primaryPayerPaymentAmount: 0,
    otherPayerPaymentAmount: 0,
    primaryPayerAdjAmount: 0,
    otherPayerAdjAmount: 0,
    primaryAndOtherPayerContractuals: 0,
    primaryAndOtherInsurancePayments: 0,
    expectedPayments: 0,
    totalPayments: 0,
    netCollectionRate: 0,
    benchmark25th: 0,
    benchmark50th: 0,
    benchmark75th: 0,
    variance25th: 0,
    variance50th: 0,
    variance75th: 0,
    outstandingAR: 0,
    outstandingARAsPctOfCharges: 0,
    controllableAllowance: 0,
    patientRelatedBadDebt: 0,
    smallBalanceWriteOffs: 0
  };
};

export const aPayerByMultiLevelCollectionsDefault = function (): PayerByMultiLevelCollectionsByNodePath {
  return {
    payerCategoryKey: 0,
    payerCategoryDescription: '',
    departmentNodeId: 0,
    departmentNodeName: '',
    departmentNodePath: '',
    specialtyNodeId: 0,
    specialtyNodeName: '',
    specialtyNodePath: '',
    providerNodeId: 0,
    providerNodeName: '',
    providerNodePath: '',
    chargeAmount: 0,
    charityCareAmount: 0,
    discountAmount: 0,
    patientPaymentAmount: 0,
    primaryPayerPaymentAmount: 0,
    otherPayerPaymentAmount: 0,
    primaryPayerAdjAmount: 0,
    otherPayerAdjAmount: 0,
    primaryAndOtherPayerContractuals: 0,
    primaryAndOtherInsurancePayments: 0,
    expectedPayments: 0,
    totalPayments: 0,
    netCollectionRate: 0,
    benchmark25th: 0,
    benchmark50th: 0,
    benchmark75th: 0,
    variance25th: 0,
    variance50th: 0,
    variance75th: 0,
    outstandingAR: 0,
    outstandingARAsPctOfCharges: 0,
    controllableAllowance: 0,
    patientRelatedBadDebt: 0,
    smallBalanceWriteOffs: 0,
    paymentAtBenchmark25th: 0,
    paymentAtBenchmark50th: 0,
    paymentAtBenchmark75th: 0,
    expected25thPayments: 0,
    expected50thPayments: 0,
    expected75thPayments: 0
  };
};

export const aPayerByMultilevelCollectionsWithAboveZeroes = function (): PayerByMultiLevelCollectionsByNodePath {
  return {
    payerCategoryKey: 2,
    payerCategoryDescription: 'country financial',
    departmentNodeId: 77,
    departmentNodeName: 'Krinlisov',
    departmentNodePath: '\\77',
    specialtyNodeId: 8888,
    specialtyNodeName: 'Georgiy',
    specialtyNodePath: '\\77\\8888',
    providerNodeId: 3,
    providerNodeName: 'ice',
    providerNodePath: '\\77\\8888\\3',
    chargeAmount: 18,
    charityCareAmount: 24,
    discountAmount: 19,
    patientPaymentAmount: 25,
    primaryPayerPaymentAmount: 22,
    otherPayerPaymentAmount: 29,
    primaryPayerAdjAmount: 21,
    otherPayerAdjAmount: 44,
    primaryAndOtherPayerContractuals: 67,
    primaryAndOtherInsurancePayments: 99,
    expectedPayments: 11,
    totalPayments: 13,
    netCollectionRate: 12,
    benchmark25th: 42,
    benchmark50th: 55,
    benchmark75th: 61,
    variance25th: 17,
    variance50th: 19,
    variance75th: 26,
    outstandingAR: 33,
    outstandingARAsPctOfCharges: 14,
    controllableAllowance: 92,
    patientRelatedBadDebt: 88,
    smallBalanceWriteOffs: 64,
    paymentAtBenchmark25th: 57,
    paymentAtBenchmark50th: 16,
    paymentAtBenchmark75th: 5,
    expected25thPayments: 87,
    expected50thPayments: 71,
    expected75thPayments: 58
  };
};

export const aPayerByLevelCollectionsDefault = function (): CollectionsByPayerGroupedByLevel {
  return {
    nodeId: 0,
    nodeName: '',
    nodePath: '',
    chargeAmount: 0,
    charityCareAmount: 0,
    discountAmount: 0,
    patientPaymentAmount: 0,
    primaryPayerPaymentAmount: 0,
    otherPayerPaymentAmount: 0,
    primaryPayerAdjAmount: 0,
    otherPayerAdjAmount: 0,
    primaryAndOtherPayerContractuals: 0,
    primaryAndOtherInsurancePayments: 0,
    expectedPayments: 0,
    totalPayments: 0,
    netCollectionRate: 0,
    benchmark25th: 0,
    benchmark50th: 0,
    benchmark75th: 0,
    variance25th: 0,
    variance50th: 0,
    variance75th: 0,
    outstandingAR: 0,
    outstandingARAsPctOfCharges: 0,
    controllableAllowance: 0,
    patientRelatedBadDebt: 0,
    smallBalanceWriteOffs: 0,
    paymentAtBenchmark25th: 0,
    paymentAtBenchmark50th: 0,
    paymentAtBenchmark75th: 0,
    expected25thPayments: 0,
    expected50thPayments: 0,
    expected75thPayments: 0
  };
};

export const aPayerByLevelCollections = function (): CollectionsByPayerGroupedByLevel {
  return {
    nodeId: 12,
    nodeName: '',
    nodePath: '',
    chargeAmount: 13,
    charityCareAmount: 1,
    discountAmount: 66,
    patientPaymentAmount: 6,
    primaryPayerPaymentAmount: 65,
    otherPayerPaymentAmount: 66,
    primaryPayerAdjAmount: 62,
    otherPayerAdjAmount: 60,
    primaryAndOtherPayerContractuals: 10,
    primaryAndOtherInsurancePayments: 12,
    expectedPayments: 13,
    totalPayments: 13,
    netCollectionRate: 14,
    benchmark25th: 11,
    benchmark50th: 1,
    benchmark75th: 2,
    variance25th: 33,
    variance50th: 34,
    variance75th: 35,
    outstandingAR: 36,
    outstandingARAsPctOfCharges: 37,
    controllableAllowance: 36,
    patientRelatedBadDebt: 38,
    smallBalanceWriteOffs: 99,
    'payerA': 39,
    paymentAtBenchmark25th: 0,
    paymentAtBenchmark50th: 0,
    paymentAtBenchmark75th: 0,
    expected25thPayments: 0,
    expected50thPayments: 0,
    expected75thPayments: 0
  };
};

export const aPayerCollections = function (): PayerCollections {
  return {
    payerCategoryKey: 3,
    payerCategoryDescription: 'hardCoded104',
    chargeAmount: 34,
    charityCareAmount: 32,
    discountAmount: 31,
    patientPaymentAmount: 33,
    primaryPayerPaymentAmount: 35,
    otherPayerPaymentAmount: 36,
    primaryPayerAdjAmount: 37,
    otherPayerAdjAmount: 38,
    primaryAndOtherPayerContractuals: 39,
    primaryAndOtherInsurancePayments: 99,
    expectedPayments: 98,
    totalPayments: 94,
    netCollectionRate: 93,
    benchmark25th: 77,
    benchmark50th: 78,
    benchmark75th: 79,
    variance25th: 72,
    variance50th: 73,
    variance75th: 74,
    outstandingAR: 93,
    outstandingARAsPctOfCharges: 91,
    controllableAllowance: 96,
    patientRelatedBadDebt: 22,
    smallBalanceWriteOffs: 21
  };
};

export const aDenialsWithNoBenchmarks = function (): DenialsPayerData {
  return {
    denialRates: [
      {
        ...aDenialPayerEntity(),
        denialRatePer50th: 0,
        deniedPaidPer50th: 0,
        payerCategoryDesc: '',
        payerKey: 0,
        payerType: 0,
        deniedChargedAmount: 0,
        chargesDeniedPercentage: 0,
        deniedChargedAmountPaid: 0,
        terminalChargesDeniedPercentage: 0,
        terminalDenialRate: 0,
        chargesTerminallyDenied: 0
      }]
  };
};

export const aPayerCollectionsWithNoBenchmarks = function (): PayerCollections {
  return {
    payerCategoryKey: 21,
    payerCategoryDescription: 'hardCoded105',
    chargeAmount: 44,
    charityCareAmount: 41,
    discountAmount: 42,
    patientPaymentAmount: 44,
    primaryPayerPaymentAmount: 45,
    otherPayerPaymentAmount: 46,
    primaryPayerAdjAmount: 47,
    otherPayerAdjAmount: 48,
    primaryAndOtherPayerContractuals: 49,
    primaryAndOtherInsurancePayments: 43,
    expectedPayments: 11,
    totalPayments: 12,
    netCollectionRate: 13,
    benchmark25th: 0,
    benchmark50th: 0,
    benchmark75th: 0,
    variance25th: 88,
    variance50th: 78,
    variance75th: 68,
    outstandingAR: 14,
    outstandingARAsPctOfCharges: 15,
    controllableAllowance: 16,
    patientRelatedBadDebt: 17,
    smallBalanceWriteOffs: 18
  };
};

export const aSummaryCollections = function (summaryType: number): CollectionsSummaryForAllTypes {
  const startYear = 5555;
  return {
    summaryType: summaryType,
    chargeAmount: 122,
    charityCareAmount: 123,
    discountAmount: 124,
    patientPaymentAmount: 125,
    primaryPayerPaymentAmount: 126,
    otherPayerPaymentAmount: 127,
    primaryPayerAdjAmount: 128,
    otherPayerAdjAmount: 129,
    expectedPayments: 120,
    totalPayments: 131,
    controllableAllowance: 132,
    smallBalanceWriteOffs: 133,
    patientRelatedBadDebt: 134,
    primaryAndOtherPayerContractuals: 135,
    primaryAndOtherInsurancePayments: 136,
    netCollectionRate: 137,
    benchmark25th: 138,
    benchmark50th: 139,
    benchmark75th: 140,
    variance25th: 141,
    variance50th: 142,
    variance75th: 143,
    outstandingAR: 144,
    outstandingARAsPctOfCharges: 145,
    startYear: startYear,
    startMonth: 5,
    endYear: startYear + 146,
    endMonth: 7
  };
};

export const aSummaryCollectionsData = function (): SummaryData<CollectionsSummaryForAllTypes> {
  return {
    selectedDateRange: aSummaryCollections(0),
    previousYearSelectedDateRange: aSummaryCollections(1),
    yearToDate: aSummaryCollections(2),
    previousYearToDate: aSummaryCollections(3),
    recentMonth: aSummaryCollections(4),
    previousYearRecentMonth: aSummaryCollections(5)
  };
};

export const aPayerCollectionsWithoutAcademicBenchmarks = function (): PayerCollections {
  return {
    payerCategoryKey: 19,
    payerCategoryDescription: 'hardCoded106',
    chargeAmount: 20,
    charityCareAmount: 21,
    discountAmount: 22,
    patientPaymentAmount: 23,
    primaryPayerPaymentAmount: 24,
    otherPayerPaymentAmount: 25,
    primaryPayerAdjAmount: 26,
    otherPayerAdjAmount: 27,
    primaryAndOtherPayerContractuals: 28,
    primaryAndOtherInsurancePayments: 29,
    expectedPayments: 30,
    totalPayments: 31,
    netCollectionRate: 32,
    benchmark25th: 0,
    benchmark50th: 0,
    benchmark75th: 0,
    variance25th: 0,
    variance50th: 0,
    variance75th: 0,
    outstandingAR: 33,
    outstandingARAsPctOfCharges: 34,
    controllableAllowance: 35,
    patientRelatedBadDebt: 36,
    smallBalanceWriteOffs: 37
  };
};

export const aPayerCollectionsDataDefault = function (): PayerCollectionsData {
  return {
    payerMatchedCollections: [
      aPayerCollectionsDefault()
    ]
  };
};

export const aCollectionsByMultiLevelByNodePathDataDefault = function (): MatchedCollectionsMultiLevelNodePathData {
  return {
    providerCollections: [
      {
        benchmark25th: 0,
        benchmark50th: 0,
        benchmark75th: 0,
        chargeAmount: 0,
        charityCareAmount: 0,
        controllableAllowance: 0,
        departmentNodeId: 0,
        departmentNodeName: '',
        departmentNodePath: '',
        discountAmount: 0,
        expectedPayments: 0,
        netCollectionRate: 0,
        otherPayerAdjAmount: 0,
        otherPayerPaymentAmount: 0,
        patientPaymentAmount: 0,
        patientRelatedBadDebt: 0,
        paymentAtBenchmark25th: 0,
        paymentAtBenchmark50th: 0,
        paymentAtBenchmark75th: 0,
        primaryAndOtherInsurancePayments: 0,
        primaryAndOtherPayerContractuals: 0,
        primaryPayerAdjAmount: 0,
        primaryPayerPaymentAmount: 0,
        providerNodeId: 0,
        providerNodeName: '',
        providerNodePath: '',
        smallBalanceWriteOffs: 0,
        specialtyNodeId: 0,
        specialtyNodeName: '',
        specialtyNodePath: '',
        totalPayments: 0,
        variance25th: 0,
        variance50th: 0,
        variance75th: 0,
        outstandingAR: 0,
        outstandingARAsPctOfCharges: 0
      }],
    departmentCollections: [{
      benchmark25th: 0,
      benchmark50th: 0,
      benchmark75th: 0,
      chargeAmount: 0,
      charityCareAmount: 0,
      controllableAllowance: 0,
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: '',
      discountAmount: 0,
      expectedPayments: 0,
      netCollectionRate: 0,
      otherPayerAdjAmount: 0,
      otherPayerPaymentAmount: 0,
      patientPaymentAmount: 0,
      patientRelatedBadDebt: 0,
      paymentAtBenchmark25th: 0,
      paymentAtBenchmark50th: 0,
      paymentAtBenchmark75th: 0,
      primaryAndOtherInsurancePayments: 0,
      primaryAndOtherPayerContractuals: 0,
      primaryPayerAdjAmount: 0,
      primaryPayerPaymentAmount: 0,
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      smallBalanceWriteOffs: 0,
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      totalPayments: 0,
      variance25th: 0,
      variance50th: 0,
      variance75th: 0,
      outstandingAR: 0,
      outstandingARAsPctOfCharges: 0
    }],
    specialtyCollections: [{
      benchmark25th: 0,
      benchmark50th: 0,
      benchmark75th: 0,
      chargeAmount: 0,
      charityCareAmount: 0,
      controllableAllowance: 0,
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: '',
      discountAmount: 0,
      expectedPayments: 0,
      netCollectionRate: 0,
      otherPayerAdjAmount: 0,
      otherPayerPaymentAmount: 0,
      patientPaymentAmount: 0,
      patientRelatedBadDebt: 0,
      paymentAtBenchmark25th: 0,
      paymentAtBenchmark50th: 0,
      paymentAtBenchmark75th: 0,
      primaryAndOtherInsurancePayments: 0,
      primaryAndOtherPayerContractuals: 0,
      primaryPayerAdjAmount: 0,
      primaryPayerPaymentAmount: 0,
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      smallBalanceWriteOffs: 0,
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      totalPayments: 0,
      variance25th: 0,
      variance50th: 0,
      variance75th: 0,
      outstandingAR: 0,
      outstandingARAsPctOfCharges: 0
    }]
  };
};

export const aCollectionsByMultiLevelByNodePathData = function (): MatchedCollectionsMultiLevelNodePathData {
  return {
    providerCollections: [
      {
        benchmark25th: 38,
        benchmark50th: 39,
        benchmark75th: 30,
        chargeAmount: 31,
        charityCareAmount: 32,
        controllableAllowance: 33,
        departmentNodeId: 34,
        departmentNodeName: '',
        departmentNodePath: '',
        discountAmount: 35,
        expectedPayments: 35,
        netCollectionRate: 36,
        otherPayerAdjAmount: 37,
        otherPayerPaymentAmount: 38,
        patientPaymentAmount: 39,
        patientRelatedBadDebt: 11,
        paymentAtBenchmark25th: 12,
        paymentAtBenchmark50th: 13,
        paymentAtBenchmark75th: 14,
        primaryAndOtherInsurancePayments: 15,
        primaryAndOtherPayerContractuals: 16,
        primaryPayerAdjAmount: 17,
        primaryPayerPaymentAmount: 18,
        providerNodeId: 19,
        providerNodeName: '',
        providerNodePath: '',
        smallBalanceWriteOffs: 20,
        specialtyNodeId: 21,
        specialtyNodeName: '',
        specialtyNodePath: '',
        totalPayments: 22,
        variance25th: 23,
        variance50th: 24,
        variance75th: 25,
        outstandingAR: 26,
        outstandingARAsPctOfCharges: 0
      }],
    departmentCollections: [{
      benchmark25th: 27,
      benchmark50th: 28,
      benchmark75th: 29,
      chargeAmount: 30,
      charityCareAmount: 31,
      controllableAllowance: 32,
      departmentNodeId: 33,
      departmentNodeName: '',
      departmentNodePath: '',
      discountAmount: 34,
      expectedPayments: 35,
      netCollectionRate: 36,
      otherPayerAdjAmount: 37,
      otherPayerPaymentAmount: 38,
      patientPaymentAmount: 39,
      patientRelatedBadDebt: 30,
      paymentAtBenchmark25th: 31,
      paymentAtBenchmark50th: 32,
      paymentAtBenchmark75th: 33,
      primaryAndOtherInsurancePayments: 34,
      primaryAndOtherPayerContractuals: 35,
      primaryPayerAdjAmount: 36,
      primaryPayerPaymentAmount: 37,
      providerNodeId: 38,
      providerNodeName: '',
      providerNodePath: '',
      smallBalanceWriteOffs: 39,
      specialtyNodeId: 40,
      specialtyNodeName: '',
      specialtyNodePath: '',
      totalPayments: 41,
      variance25th: 42,
      variance50th: 43,
      variance75th: 44,
      outstandingAR: 45,
      outstandingARAsPctOfCharges: 0
    }],
    specialtyCollections: [{
      benchmark25th: 46,
      benchmark50th: 47,
      benchmark75th: 48,
      chargeAmount: 49,
      charityCareAmount: 50,
      controllableAllowance: 51,
      departmentNodeId: 52,
      departmentNodeName: '',
      departmentNodePath: '',
      discountAmount: 53,
      expectedPayments: 54,
      netCollectionRate: 55,
      otherPayerAdjAmount: 56,
      otherPayerPaymentAmount: 57,
      patientPaymentAmount: 58,
      patientRelatedBadDebt: 59,
      paymentAtBenchmark25th: 60,
      paymentAtBenchmark50th: 61,
      paymentAtBenchmark75th: 62,
      primaryAndOtherInsurancePayments: 63,
      primaryAndOtherPayerContractuals: 64,
      primaryPayerAdjAmount: 65,
      primaryPayerPaymentAmount: 66,
      providerNodeId: 67,
      providerNodeName: '',
      providerNodePath: '',
      smallBalanceWriteOffs: 68,
      specialtyNodeId: 69,
      specialtyNodeName: '',
      specialtyNodePath: '',
      totalPayments: 70,
      variance25th: 71,
      variance50th: 72,
      variance75th: 73,
      outstandingAR: 74,
      outstandingARAsPctOfCharges: 0
    }]
  };
};

export const aCollectionsByMultiLevelByNodeDefault = function (): MatchedCollectionsMultiLevelNodePathData {
  return {
    providerCollections: [
      {
        benchmark25th: 0,
        benchmark50th: 0,
        benchmark75th: 0,
        chargeAmount: 0,
        charityCareAmount: 0,
        controllableAllowance: 0,
        departmentNodeId: 0,
        departmentNodeName: '',
        departmentNodePath: '',
        discountAmount: 0,
        expectedPayments: 0,
        netCollectionRate: 0,
        otherPayerAdjAmount: 0,
        otherPayerPaymentAmount: 0,
        patientPaymentAmount: 0,
        patientRelatedBadDebt: 0,
        paymentAtBenchmark25th: 0,
        paymentAtBenchmark50th: 0,
        paymentAtBenchmark75th: 0,
        primaryAndOtherInsurancePayments: 0,
        primaryAndOtherPayerContractuals: 0,
        primaryPayerAdjAmount: 0,
        primaryPayerPaymentAmount: 0,
        providerNodeId: 0,
        providerNodeName: '',
        providerNodePath: '',
        smallBalanceWriteOffs: 0,
        specialtyNodeId: 0,
        specialtyNodeName: '',
        specialtyNodePath: '',
        totalPayments: 0,
        variance25th: 0,
        variance50th: 0,
        variance75th: 0,
        outstandingAR: 0,
        outstandingARAsPctOfCharges: 0
      }],
    departmentCollections: [{
      benchmark25th: 0,
      benchmark50th: 0,
      benchmark75th: 0,
      chargeAmount: 0,
      charityCareAmount: 0,
      controllableAllowance: 0,
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: '',
      discountAmount: 0,
      expectedPayments: 0,
      netCollectionRate: 0,
      otherPayerAdjAmount: 0,
      otherPayerPaymentAmount: 0,
      patientPaymentAmount: 0,
      patientRelatedBadDebt: 0,
      paymentAtBenchmark25th: 0,
      paymentAtBenchmark50th: 0,
      paymentAtBenchmark75th: 0,
      primaryAndOtherInsurancePayments: 0,
      primaryAndOtherPayerContractuals: 0,
      primaryPayerAdjAmount: 0,
      primaryPayerPaymentAmount: 0,
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      smallBalanceWriteOffs: 0,
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      totalPayments: 0,
      variance25th: 0,
      variance50th: 0,
      variance75th: 0,
      outstandingAR: 0,
      outstandingARAsPctOfCharges: 0
    }],
    specialtyCollections: [{
      benchmark25th: 0,
      benchmark50th: 0,
      benchmark75th: 0,
      chargeAmount: 0,
      charityCareAmount: 0,
      controllableAllowance: 0,
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: '',
      discountAmount: 0,
      expectedPayments: 0,
      netCollectionRate: 0,
      otherPayerAdjAmount: 0,
      otherPayerPaymentAmount: 0,
      patientPaymentAmount: 0,
      patientRelatedBadDebt: 0,
      paymentAtBenchmark25th: 0,
      paymentAtBenchmark50th: 0,
      paymentAtBenchmark75th: 0,
      primaryAndOtherInsurancePayments: 0,
      primaryAndOtherPayerContractuals: 0,
      primaryPayerAdjAmount: 0,
      primaryPayerPaymentAmount: 0,
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      smallBalanceWriteOffs: 0,
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      totalPayments: 0,
      variance25th: 0,
      variance50th: 0,
      variance75th: 0,
      outstandingAR: 0,
      outstandingARAsPctOfCharges: 0
    }]
  };
};

export const aPayerCollectionsByMultiLevelByNodeDefault = function (): PayerMatchedCollectionsMultiLevelNodePathData {
  return {
    providerCollections: [
      {
        payerCategoryKey: 0,
        payerCategoryDescription: '',
        departmentNodeId: 0,
        departmentNodeName: '',
        departmentNodePath: '',
        specialtyNodeId: 0,
        specialtyNodeName: '',
        specialtyNodePath: '',
        providerNodeId: 0,
        providerNodeName: '',
        providerNodePath: '',
        benchmark25th: 0,
        benchmark50th: 0,
        benchmark75th: 0,
        chargeAmount: 0,
        charityCareAmount: 0,
        controllableAllowance: 0,
        discountAmount: 0,
        expected25thPayments: 0,
        expected50thPayments: 0,
        expected75thPayments: 0,
        expectedPayments: 0,
        netCollectionRate: 0,
        otherPayerAdjAmount: 0,
        otherPayerPaymentAmount: 0,
        outstandingAR: 0,
        outstandingARAsPctOfCharges: 0,
        patientPaymentAmount: 0,
        patientRelatedBadDebt: 0,
        paymentAtBenchmark25th: 0,
        paymentAtBenchmark50th: 0,
        paymentAtBenchmark75th: 0,
        primaryAndOtherInsurancePayments: 0,
        primaryAndOtherPayerContractuals: 0,
        primaryPayerAdjAmount: 0,
        primaryPayerPaymentAmount: 0,
        smallBalanceWriteOffs: 0,
        totalPayments: 0,
        variance25th: 0,
        variance50th: 0,
        variance75th: 0
      }],
    departmentCollections: [{
      payerCategoryKey: 0,
      payerCategoryDescription: '',
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: '',
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      benchmark25th: 0,
      benchmark50th: 0,
      benchmark75th: 0,
      chargeAmount: 0,
      charityCareAmount: 0,
      controllableAllowance: 0,
      discountAmount: 0,
      expected25thPayments: 0,
      expected50thPayments: 0,
      expected75thPayments: 0,
      expectedPayments: 0,
      netCollectionRate: 0,
      otherPayerAdjAmount: 0,
      otherPayerPaymentAmount: 0,
      outstandingAR: 0,
      outstandingARAsPctOfCharges: 0,
      patientPaymentAmount: 0,
      patientRelatedBadDebt: 0,
      paymentAtBenchmark25th: 0,
      paymentAtBenchmark50th: 0,
      paymentAtBenchmark75th: 0,
      primaryAndOtherInsurancePayments: 0,
      primaryAndOtherPayerContractuals: 0,
      primaryPayerAdjAmount: 0,
      primaryPayerPaymentAmount: 0,
      smallBalanceWriteOffs: 0,
      totalPayments: 0,
      variance25th: 0,
      variance50th: 0,
      variance75th: 0
    }],
    specialtyCollections: [{
      payerCategoryKey: 0,
      payerCategoryDescription: '',
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: '',
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      benchmark25th: 0,
      benchmark50th: 0,
      benchmark75th: 0,
      chargeAmount: 0,
      charityCareAmount: 0,
      controllableAllowance: 0,
      discountAmount: 0,
      expected25thPayments: 0,
      expected50thPayments: 0,
      expected75thPayments: 0,
      expectedPayments: 0,
      netCollectionRate: 0,
      otherPayerAdjAmount: 0,
      otherPayerPaymentAmount: 0,
      outstandingAR: 0,
      outstandingARAsPctOfCharges: 0,
      patientPaymentAmount: 0,
      patientRelatedBadDebt: 0,
      paymentAtBenchmark25th: 0,
      paymentAtBenchmark50th: 0,
      paymentAtBenchmark75th: 0,
      primaryAndOtherInsurancePayments: 0,
      primaryAndOtherPayerContractuals: 0,
      primaryPayerAdjAmount: 0,
      primaryPayerPaymentAmount: 0,
      smallBalanceWriteOffs: 0,
      totalPayments: 0,
      variance25th: 0,
      variance50th: 0,
      variance75th: 0
    }]
  };
};

export const aCollectionsByMultiLevelByNodePathDefault = function (): MatchedCollectionsByMultiLevelByNodePath {
  return {
    benchmark25th: 0,
    benchmark50th: 0,
    benchmark75th: 0,
    chargeAmount: 0,
    charityCareAmount: 0,
    controllableAllowance: 0,
    departmentNodeId: 0,
    departmentNodeName: '',
    departmentNodePath: '',
    discountAmount: 0,
    expectedPayments: 0,
    netCollectionRate: 0,
    otherPayerAdjAmount: 0,
    otherPayerPaymentAmount: 0,
    patientPaymentAmount: 0,
    patientRelatedBadDebt: 0,
    paymentAtBenchmark25th: 0,
    paymentAtBenchmark50th: 0,
    paymentAtBenchmark75th: 0,
    primaryAndOtherInsurancePayments: 0,
    primaryAndOtherPayerContractuals: 0,
    primaryPayerAdjAmount: 0,
    primaryPayerPaymentAmount: 0,
    providerNodeId: 0,
    providerNodeName: '',
    providerNodePath: '',
    smallBalanceWriteOffs: 0,
    specialtyNodeId: 0,
    specialtyNodeName: '',
    specialtyNodePath: '',
    totalPayments: 0,
    variance25th: 0,
    variance50th: 0,
    variance75th: 0,
    outstandingAR: 0,
    outstandingARAsPctOfCharges: 0
  };
};

export const aCollectionsByMultiLevelByNodePathWithData = function (benchmark?: number): MatchedCollectionsByMultiLevelByNodePath {
  return {
    benchmark25th: benchmark || 3,
    benchmark50th: benchmark || 9,
    benchmark75th: benchmark || 17,
    chargeAmount: 777,
    charityCareAmount: 779,
    controllableAllowance: 776,
    departmentNodeId: 666,
    departmentNodeName: 'departmentDepartment',
    departmentNodePath: 'hardCoded107' + '\\' + 'hardCoded108',
    discountAmount: 123,
    expectedPayments: 456,
    netCollectionRate: 789,
    otherPayerAdjAmount: 222,
    otherPayerPaymentAmount: 223,
    patientPaymentAmount: 224,
    patientRelatedBadDebt: 225,
    paymentAtBenchmark25th: 77,
    paymentAtBenchmark50th: 66,
    paymentAtBenchmark75th: 33,
    primaryAndOtherInsurancePayments: 1001,
    primaryAndOtherPayerContractuals: 888,
    primaryPayerAdjAmount: 666,
    primaryPayerPaymentAmount: 333,
    providerNodeId: 999,
    providerNodeName: 'providerProvider',
    providerNodePath: 'hardCoded109' + '\\' + 'hardCoded110',
    smallBalanceWriteOffs: 444,
    specialtyNodeId: 345,
    specialtyNodeName: 'specialSpecialty',
    specialtyNodePath: 'hardCoded110' + '\\' + 'hardCoded111' + '\\' + 'hardCoded112',
    totalPayments: 678,
    variance25th: 33,
    variance50th: 39,
    variance75th: 77,
    outstandingAR: 234,
    outstandingARAsPctOfCharges: 676
  };
};

export const aDenialsByMultLevelByNodePathDefault = function (): DenialsMultiLevelEntity {
  return {
    id: 0,
    chargeAmount: 0,
    numberBilled: 0,
    numberDenied: 0,
    denialRate: 0,
    deniedPaidRate: 0,
    numberDeniedPaid: 0,
    denialRatePer25th: 0,
    denialRatePer50th: 0,
    denialRatePer75th: 0,
    variancePer25th: 0,
    variancePer50th: 0,
    variancePer75th: 0,
    deniedPaidPer25th: 0,
    deniedPaidPer50th: 0,
    deniedPaidPer75th: 0,
    expectedDenialRatePer25th: 0,
    expectedDenialRatePer50th: 0,
    expectedDenialRatePer75th: 0,
    expected25NumberBilled: 0,
    expected50NumberBilled: 0,
    expected75NumberBilled: 0,
    chargesTerminallyDenied: 0,
    deniedChargedAmount: 0,
    deniedChargedAmountPaid: 0,
    terminalDenialRate: 0,
    chargesDeniedPercentage: 0,
    chargesDenied: 0,
    terminalChargesDeniedPercentage: 0,
    providerNodeId: 0,
    providerNodeName: '',
    providerNodePath: '',
    specialtyNodeId: 0,
    specialtyNodeName: '',
    specialtyNodePath: '',
    departmentNodeId: 0,
    departmentNodeName: '',
    departmentNodePath: '',
    terminalDenialRatePer25th: 0,
    terminalDenialRatePer50th: 0,
    terminalDenialRatePer75th: 0
  };
};

export const aDenialReasonEntity = function (): DenialReasonEntity {
  return {
    id: 0,
    chargeAmount: 24,
    numberBilled: 7,
    numberDenied: 8,
    numberDeniedPaid: 5,
    denialRate: 0.71,
    deniedPaidRate: 0.65,
    deniedPaidPer25th: 0.25,
    deniedPaidPer50th: 0.26,
    deniedPaidPer75th: 0.27,
    denialRatePer25th: 0.41,
    denialRatePer50th: 0.42,
    denialRatePer75th: 0.43,
    denialReason: 'HOMEGROWN'
  };
};

export const aDenialEntityWithSpecifics = function (): DenialsEntity {
  return {
    id: 4,
    nodeId: 70,
    nodeName: 'Department of rock n roll',
    nodePath: 'hardCoded112' + '\\' + 'hardCoded113',
    chargeAmount: 16.15,
    numberBilled: 10,
    numberDenied: 70,
    denialRate: 89.65,
    deniedPaidRate: 55.98,
    numberDeniedPaid: 999.22,
    denialReason: 'i have chosen a champion who will run this castle!',
    payerCategoryDesc: 'i am a leprechaun!',
    denialRatePer25th: 124.67,
    denialRatePer50th: 79.54,
    denialRatePer75th: 800.88,
    variancePer25th: 454.43,
    variancePer50th: 544.56,
    variancePer75th: 43.27,
    deniedPaidPer25th: 85.76,
    deniedPaidPer50th: 32.34,
    deniedPaidPer75th: 73.49,
    cptCode: 'A95-bb',
    chargesDenied: 46,
    chargesDeniedRate: 1000000.17,
    terminalDenialRate: 12.00,
    chargesTerminallyDenied: 14,
    chargesTerminalDeniedRate: 18.35,
    terminalDenialRatePer50th: 16,
    terminalDenialRatePer25th: 16,
    terminalDenialRatePer75th: 16
  };
};

export const aDenialPayerEntity = function (): DenialPayerEntity {
  return {
    id: 2,
    payerCategoryDesc: 'dude my payer is ' + 'hardCoded114',
    payerKey: 9,
    payerType: 1,
    chargeAmount: 2.2,
    numberBilled: 3.7,
    numberDenied: 8.6,
    denialRate: 9.2,
    deniedPaidRate: 20.8,
    numberDeniedPaid: 15.5,
    denialRatePer25th: 5.8,
    denialRatePer50th: 5.2,
    denialRatePer75th: 8.1,
    variancePer25th: 5.2,
    variancePer50th: 6.7,
    variancePer75th: 4.3,
    deniedPaidPer25th: 6.8,
    deniedPaidPer50th: 17.8,
    deniedPaidPer75th: 25.8,
    expectedDenialRatePer25th: 18.1,
    expectedDenialRatePer50th: 20.2,
    expectedDenialRatePer75th: 22.4,
    expected25NumberBilled: 24.6,
    expected50NumberBilled: 23.5,
    expected75NumberBilled: 21.8,
    chargesTerminallyDenied: 14,
    deniedChargedAmount: 18.35,
    deniedChargedAmountPaid: 12.43,
    terminalDenialRate: 3.20,
    chargesDeniedPercentage: 8.8,
    terminalChargesDeniedPercentage: 9.1,
    terminalDenialRatePer25th: 0,
    terminalDenialRatePer50th: 0,
    terminalDenialRatePer75th: 0,
    terminalDenialRateBenchmark50th: 349
  };
};

export const aDenialCptCodeByReasonEntity = function (): DenialCptCodeByReasonEntity {
  return {
    cptCode: '99201',
    chargesDenied: 14,
    chargesDeniedPaid: 77,
    chargeAmountAgg: 88,
    expected25NumberDenied: 25,
    expected50NumberDenied: 52,
    expected75NumberDenied: 57,
    expected25NumberDeniedPaid: 26,
    expected50NumberDeniedPaid: 51,
    expected75NumberDeniedPaid: 72,
    expected50NumberTerminallyDenied: 80,
    denialRateMean: 53,
    deniedPaidRateMean: 54,
    denialReason: 'the reason',
    terminalDenialRate: 86,
    terminalDenialRatePer25th: 52,
    terminalDenialRatePer50th: 5,
    terminalDenialRatePer75th: 57,
    terminalChargesDeniedPercentage: 22,
    chargesTerminallyDenied: 24,
    chargesDeniedPercentage: 30,
    variancePer25th: 45,
    variancePer50th: 70,
    variancePer75th: 95,
    id: 0,
    chargeAmount: 23,
    numberBilled: 42,
    numberDenied: 17,
    numberDeniedPaid: 81,
    denialRate: 65,
    deniedPaidRate: 59,
    deniedPaidPer25th: 31,
    deniedPaidPer50th: 57,
    deniedPaidPer75th: 82,
    denialRatePer25th: 24,
    denialRatePer50th: 49,
    denialRatePer75th: 74
  };
};

export const aDenialsByMultiLevelDataEntity = function (): DenialsMultiLevelData {
  return {
    departmentDenials: [
      {
        id: 0,
        chargeAmount: 2.2,
        numberBilled: 3.7,
        numberDenied: 8.6,
        denialRate: 9.2,
        deniedPaidRate: 20.8,
        numberDeniedPaid: 15.5,
        denialRatePer25th: 5.8,
        denialRatePer50th: 5.2,
        denialRatePer75th: 8.1,
        variancePer25th: 5.2,
        variancePer50th: 6.7,
        variancePer75th: 4.3,
        deniedPaidPer25th: 6.8,
        deniedPaidPer50th: 17.8,
        deniedPaidPer75th: 25.8,
        expectedDenialRatePer25th: 18.1,
        expectedDenialRatePer50th: 20.2,
        expectedDenialRatePer75th: 22.4,
        expected25NumberBilled: 24.6,
        expected50NumberBilled: 23.5,
        expected75NumberBilled: 21.8,
        chargesTerminallyDenied: 14,
        deniedChargedAmount: 18.35,
        deniedChargedAmountPaid: 12.43,
        terminalDenialRate: 3.20,
        terminalDenialRatePer25th: 0,
        terminalDenialRatePer50th: 0,
        terminalDenialRatePer75th: 0,
        chargesDeniedPercentage: 8.8,
        chargesDenied: 0,
        terminalChargesDeniedPercentage: 9.1,
        providerNodeId: 3,
        providerNodeName: 'prov NotRandom',
        providerNodePath: 'NotRandom\\NotRandom',
        specialtyNodeId: 2,
        specialtyNodeName: 'spec NotRandom',
        specialtyNodePath: 'NotRandom\\NotRandom',
        departmentNodeId: 1,
        departmentNodeName: 'dept NotRandom',
        departmentNodePath: 'NotRandom\\NotRandom'
      }],
    specialtyDenials: [{
      id: 1,
      chargeAmount: 2.2,
      numberBilled: 3.7,
      numberDenied: 8.6,
      denialRate: 9.2,
      deniedPaidRate: 20.8,
      numberDeniedPaid: 15.5,
      denialRatePer25th: 5.8,
      denialRatePer50th: 5.2,
      denialRatePer75th: 8.1,
      variancePer25th: 5.2,
      variancePer50th: 6.7,
      variancePer75th: 4.3,
      deniedPaidPer25th: 6.8,
      deniedPaidPer50th: 17.8,
      deniedPaidPer75th: 25.8,
      expectedDenialRatePer25th: 18.1,
      expectedDenialRatePer50th: 20.2,
      expectedDenialRatePer75th: 22.4,
      expected25NumberBilled: 24.6,
      expected50NumberBilled: 23.5,
      expected75NumberBilled: 21.8,
      chargesTerminallyDenied: 14,
      deniedChargedAmount: 18.35,
      deniedChargedAmountPaid: 12.43,
      terminalDenialRate: 3.20,
      terminalDenialRatePer25th: 0,
      terminalDenialRatePer50th: 0,
      terminalDenialRatePer75th: 0,
      chargesDeniedPercentage: 8.8,
      chargesDenied: 0,
      terminalChargesDeniedPercentage: 9.1,
      providerNodeId: 3,
      providerNodeName: 'provsdfe',
      providerNodePath: '12345\\678910',
      specialtyNodeId: 2,
      specialtyNodeName: 'spec qwertyu',
      specialtyNodePath: 'zxcv\\bnm',
      departmentNodeId: 1,
      departmentNodeName: 'dept lkjh',
      departmentNodePath: 'qazwsxedc\\ikmnjuyhb'
    }],
    providerDenials: [{
      id: 2,
      chargeAmount: 2.2,
      numberBilled: 3.7,
      numberDenied: 8.6,
      denialRate: 9.2,
      deniedPaidRate: 20.8,
      numberDeniedPaid: 15.5,
      denialRatePer25th: 5.8,
      denialRatePer50th: 5.2,
      denialRatePer75th: 8.1,
      variancePer25th: 5.2,
      variancePer50th: 6.7,
      variancePer75th: 4.3,
      deniedPaidPer25th: 6.8,
      deniedPaidPer50th: 17.8,
      deniedPaidPer75th: 25.8,
      expectedDenialRatePer25th: 18.1,
      expectedDenialRatePer50th: 20.2,
      expectedDenialRatePer75th: 22.4,
      expected25NumberBilled: 24.6,
      expected50NumberBilled: 23.5,
      expected75NumberBilled: 21.8,
      chargesTerminallyDenied: 14,
      deniedChargedAmount: 18.35,
      deniedChargedAmountPaid: 12.43,
      terminalDenialRate: 3.20,
      terminalDenialRatePer25th: 0,
      terminalDenialRatePer50th: 0,
      terminalDenialRatePer75th: 0,
      chargesDeniedPercentage: 8.8,
      chargesDenied: 0,
      terminalChargesDeniedPercentage: 9.1,
      providerNodeId: 3,
      providerNodeName: 'prov 123456',
      providerNodePath: 'qqqq\\wwww',
      specialtyNodeId: 2,
      specialtyNodeName: 'spec tgbnh',
      specialtyNodePath: 'bbbb\\aaaa',
      departmentNodeId: 1,
      departmentNodeName: 'dept h',
      departmentNodePath: 'abc\\def'
    }]
  };
};

export const aDenialsByMultiLevelDataDefault = function (): DenialsMultiLevelData {
  return {
    departmentDenials: [
      {
        id: 0,
        chargeAmount: 0,
        numberBilled: 0,
        numberDenied: 0,
        denialRate: 0,
        deniedPaidRate: 0,
        numberDeniedPaid: 0,
        denialRatePer25th: 0,
        denialRatePer50th: 0,
        denialRatePer75th: 0,
        variancePer25th: 0,
        variancePer50th: 0,
        variancePer75th: 0,
        deniedPaidPer25th: 0,
        deniedPaidPer50th: 0,
        deniedPaidPer75th: 0,
        expectedDenialRatePer25th: 0,
        expectedDenialRatePer50th: 0,
        expectedDenialRatePer75th: 0,
        expected25NumberBilled: 0,
        expected50NumberBilled: 0,
        expected75NumberBilled: 0,
        chargesTerminallyDenied: 0,
        deniedChargedAmount: 0,
        deniedChargedAmountPaid: 0,
        terminalDenialRate: 0,
        terminalDenialRatePer25th: 0,
        terminalDenialRatePer50th: 0,
        terminalDenialRatePer75th: 0,
        chargesDeniedPercentage: 0,
        chargesDenied: 0,
        terminalChargesDeniedPercentage: 0,
        providerNodeId: 0,
        providerNodeName: '',
        providerNodePath: '',
        specialtyNodeId: 0,
        specialtyNodeName: '',
        specialtyNodePath: '',
        departmentNodeId: 0,
        departmentNodeName: '',
        departmentNodePath: ''
      }],
    specialtyDenials: [{
      id: 0,
      chargeAmount: 0,
      numberBilled: 0,
      numberDenied: 0,
      denialRate: 0,
      deniedPaidRate: 0,
      numberDeniedPaid: 0,
      denialRatePer25th: 0,
      denialRatePer50th: 0,
      denialRatePer75th: 0,
      variancePer25th: 0,
      variancePer50th: 0,
      variancePer75th: 0,
      deniedPaidPer25th: 0,
      deniedPaidPer50th: 0,
      deniedPaidPer75th: 0,
      expectedDenialRatePer25th: 0,
      expectedDenialRatePer50th: 0,
      expectedDenialRatePer75th: 0,
      expected25NumberBilled: 0,
      expected50NumberBilled: 0,
      expected75NumberBilled: 0,
      chargesTerminallyDenied: 0,
      deniedChargedAmount: 0,
      deniedChargedAmountPaid: 0,
      terminalDenialRate: 0,
      terminalDenialRatePer25th: 0,
      terminalDenialRatePer50th: 0,
      terminalDenialRatePer75th: 0,
      chargesDeniedPercentage: 0,
      chargesDenied: 0,
      terminalChargesDeniedPercentage: 0,
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: ''
    }],
    providerDenials: [{
      id: 0,
      chargeAmount: 0,
      numberBilled: 0,
      numberDenied: 0,
      denialRate: 0,
      deniedPaidRate: 0,
      numberDeniedPaid: 0,
      denialRatePer25th: 0,
      denialRatePer50th: 0,
      denialRatePer75th: 0,
      variancePer25th: 0,
      variancePer50th: 0,
      variancePer75th: 0,
      deniedPaidPer25th: 0,
      deniedPaidPer50th: 0,
      deniedPaidPer75th: 0,
      expectedDenialRatePer25th: 0,
      expectedDenialRatePer50th: 0,
      expectedDenialRatePer75th: 0,
      expected25NumberBilled: 0,
      expected50NumberBilled: 0,
      expected75NumberBilled: 0,
      chargesTerminallyDenied: 0,
      deniedChargedAmount: 0,
      deniedChargedAmountPaid: 0,
      terminalDenialRate: 0,
      terminalDenialRatePer25th: 0,
      terminalDenialRatePer50th: 0,
      terminalDenialRatePer75th: 0,
      chargesDeniedPercentage: 0,
      chargesDenied: 0,
      terminalChargesDeniedPercentage: 0,
      providerNodeId: 0,
      providerNodeName: '',
      providerNodePath: '',
      specialtyNodeId: 0,
      specialtyNodeName: '',
      specialtyNodePath: '',
      departmentNodeId: 0,
      departmentNodeName: '',
      departmentNodePath: ''
    }]
  };
};

export const aDenialsRateData = function (): DenialsPayerData {
  return {
    denialRates: [
      {
        ...aDenialPayerEntity(),
        denialRate: 6,
        numberDenied: 44,
        numberBilled: 22,
        numberDeniedPaid: 5,
        denialRatePer50th: 51,
        deniedPaidPer50th: 33,
        chargeAmount: 11,
        payerCategoryDesc: 'some payer description',
        payerKey: 1,
        payerType: 1,
        deniedChargedAmount: 45,
        chargesDeniedPercentage: 90,
        deniedChargedAmountPaid: 89,
        terminalChargesDeniedPercentage: 23,
        terminalDenialRate: 42,
        chargesTerminallyDenied: 70
      },
      {
        ...aDenialPayerEntity(),
        denialRate: 1,
        numberDenied: 99,
        numberBilled: 49.5,
        numberDeniedPaid: 11,
        denialRatePer50th: 51,
        deniedPaidPer50th: 17,
        chargeAmount: 55,
        payerCategoryDesc: 'another payer',
        payerKey: 2,
        payerType: 3,
        deniedChargedAmount: 83,
        chargesDeniedPercentage: 29,
        deniedChargedAmountPaid: 22,
        terminalChargesDeniedPercentage: 3,
        terminalDenialRate: 48,
        chargesTerminallyDenied: 32
      }
    ]
  };
};

export const aDenialsDataOrganizedByReasonCriteria = function (): DenialsData<DenialReasonEntity> {
  return {
    denials: [
      {
        ...aDenialReasonEntity(),
        denialRate: 6,
        numberDenied: 44,
        numberBilled: 22,
        denialRatePer50th: 51,
        chargeAmount: 11,
        denialReason: 'six'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 1,
        numberDenied: 99,
        numberBilled: 49.5,
        denialRatePer50th: 51,
        chargeAmount: 55,
        denialReason: 'one'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 3,
        numberDenied: 77,
        numberBilled: 38.5,
        denialRatePer50th: 51,
        chargeAmount: 77,
        denialReason: 'three'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 9,
        numberDenied: 11,
        numberBilled: 5.5,
        denialRatePer50th: 51,
        chargeAmount: 33,
        denialReason: 'nine'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 5,
        numberDenied: 55,
        numberBilled: 27.5,
        denialRatePer50th: 51,
        chargeAmount: 99,
        denialReason: 'five'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 11,
        numberDenied: 88,
        numberBilled: 44,
        denialRatePer50th: 50,
        chargeAmount: 54,
        denialReason: 'eleven'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 2,
        numberDenied: 87,
        numberBilled: 43.5,
        denialRatePer50th: 49,
        chargeAmount: 66,
        denialReason: 'two'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 4,
        numberDenied: 66,
        numberBilled: 33,
        denialRatePer50th: 49,
        chargeAmount: 88,
        denialReason: 'four'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 10,
        numberDenied: 98,
        numberBilled: 49,
        denialRatePer50th: 49,
        chargeAmount: 44,
        denialReason: 'ten'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 8,
        numberDenied: 22,
        numberBilled: 11,
        denialRatePer50th: 49,
        chargeAmount: 32,
        denialReason: 'eight'
      },
      {
        ...aDenialReasonEntity(),
        denialRate: 7,
        numberDenied: 33,
        numberBilled: 16.5,
        denialRatePer50th: 49,
        chargeAmount: 22,
        denialReason: 'seven'
      }
    ]
  };
};

export const aSummaryCollectionsDataDefault = function (summaryType: number): CollectionsSummaryForAllTypes {
  return {
    summaryType: summaryType,
    chargeAmount: 0,
    charityCareAmount: 0,
    discountAmount: 0,
    patientPaymentAmount: 0,
    primaryPayerPaymentAmount: 0,
    otherPayerPaymentAmount: 0,
    primaryPayerAdjAmount: 0,
    otherPayerAdjAmount: 0,
    expectedPayments: 0,
    totalPayments: 0,
    controllableAllowance: 0,
    smallBalanceWriteOffs: 0,
    patientRelatedBadDebt: 0,
    primaryAndOtherPayerContractuals: 0,
    primaryAndOtherInsurancePayments: 0,
    netCollectionRate: 0,
    benchmark25th: 0,
    benchmark50th: 0,
    benchmark75th: 0,
    variance25th: 0,
    variance50th: 0,
    variance75th: 0,
    outstandingAR: 0,
    outstandingARAsPctOfCharges: 0,
    startYear: 0,
    startMonth: 0,
    endYear: 0,
    endMonth: 0
  };
};

export const aCustomGroupAllFiltersDefault = function (): CustomGroupsDataAllFilters {
  return {
    id: -1,
    name: 'test',
    description: 'this is a group',
    nodePath: '\\',
    isDefault: false,
    preload: false,
    memberKey: -1,
    payerCategoryKey: -1,
    payerCategoryDescription: '',
    nationalPayerKey: -1,
    nationalPayerDescription: '',
    memberPayerKey: -1,
    memberPayerDescription: '',
    payerType: PayerType.MEMBER,
    memberLocationKey: -1,
    memberLocationKeys: '',
    memberLocationDescription: '',
    memberBillingAreaKey: -1,
    memberBillingAreaDescription: '',
    invoiceStatus: -1,
    lagKey: -1,
    startDateInMonths: -1,
    endDateInMonths: -1,
    dateRangeOption: DateRangeOption.YearToDate,
    telehealthFlag: -1
  };
};

export const anOntologyNode = function (): OntologyNode {
  return {
    nodeName: 'hardCoded115',
    nodePath: 'hardCoded116' + '\\' + 'hardCoded117' + '\\' + 'hardCoded118',
    children: [],
    isCustom: false
  };
};

export function anOntologyLevel(): OntologyLevel {
  switch (getRandomInt(99) % 4) {
    case 0:
      return OntologyLevel.AllDepartments;
    case 1:
      return OntologyLevel.Department;
    case 2:
      return OntologyLevel.Specialty;
    case 3:
      return OntologyLevel.Provider;
  }
  return OntologyLevel.AllDepartments;
}

export function aState(): IAppState {
  return {
    filters: {
      memberKey: DEFAULT_MEMBER_KEY,
      nodePath: '\\path',
      dateRange: {
        startMonth: 13,
        endMonth: 14,
        startYear: DEFAULT_YEAR,
        endYear: DEFAULT_YEAR
      },
      payerCategory: {...DEFAULT_PAYER_CATEGORY},
      payerKey: DEFAULT_PAYER_CATEGORY.payerCategoryKey,
      payerType: DEFAULT_PAYER_TYPE,
      memberLocation: DEFAULT_MEMBER_LOCATION,
      memberBillingArea: DEFAULT_MEMBER_BILLING_AREA,
      lagKey: DEFAULT_LAG_KEY,
      invoiceStatus: DEFAULT_INVOICE_STATUS,
      telehealthFlag: DEFAULT_TELEHEALTH_FLAG.key,
      customGroupId: DEFAULT_CUSTOM_GROUP_ID, memberLocationKeys: '0'
    },
    data: {
      memberBillingAreaData: [],
      memberLocations: [],
      userMemberData: [],
      userPreferenceData: [],
      userSecurityData: [],
      batchExportReports: [],
      providerProductivityMultilevelData: {
        providerProductivities: [
          {
            id: 0,
            nodeId: 0,
            nodeName: '',
            nodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            cfte: 0,
            charges: 0,
            wRVUs: 0,
            cfteAdjustedWRVUs: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0,
            benchmarkMean: 0,
            benchmark65thPercentile: 0,
            benchmarkPercentile: 0,
            communityBenchmark25thPercentile: 0,
            communityBenchmark50thPercentile: 0,
            communityBenchmark75thPercentile: 0,
            communityBenchmark90thPercentile: 0,
            communityBenchmarkMean: 0,
            communityBenchmark65thPercentile: 0,
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance65thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            communityImputedReportedcFTE25thPercentile: 0,
            communityImputedReportedcFTE50thPercentile: 0,
            communityImputedReportedcFTE65thPercentile: 0,
            communityImputedReportedcFTE75thPercentile: 0,
            communityImputedReportedcFTEMean: 0,
            communityImputedReportedcFTE90thPercentile: 0,
            communityVarianceMean: 0,
            communityVariance25thPercentile: 0,
            communityVariance50thPercentile: 0,
            communityVariance65thPercentile: 0,
            communityVariance75thPercentile: 0,
            communityVariance90thPercentile: 0,
            imputedReportedcFTE50thPercentile: 0,
            imputedReportedcFTE65thPercentile: 0,
            imputedReportedcFTE75thPercentile: 0,
            imputedReportedcFTEMean: 0,
            imputedReportedcFTE90thPercentile: 0,
            imputedReportedcFTE25thPercentile: 0,
            varianceMean: 0,
            difference: 0,
            benchmarkPercentileRank: 0,
            communityBenchmarkPercentileRank: 0

          }
        ],
        specialtyProductivities: [
          {
            id: 0,
            nodeId: 0,
            nodeName: '',
            nodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            cfte: 0,
            charges: 0,
            wRVUs: 0,
            cfteAdjustedWRVUs: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0,
            benchmarkMean: 0,
            benchmark65thPercentile: 0,
            benchmarkPercentile: 0,
            communityBenchmark25thPercentile: 0,
            communityBenchmark50thPercentile: 0,
            communityBenchmark75thPercentile: 0,
            communityBenchmark90thPercentile: 0,
            communityBenchmarkMean: 0,
            communityBenchmark65thPercentile: 0,
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance65thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            communityImputedReportedcFTE25thPercentile: 0,
            communityImputedReportedcFTE50thPercentile: 0,
            communityImputedReportedcFTE65thPercentile: 0,
            communityImputedReportedcFTE75thPercentile: 0,
            communityImputedReportedcFTEMean: 0,
            communityImputedReportedcFTE90thPercentile: 0,
            communityVarianceMean: 0,
            communityVariance25thPercentile: 0,
            communityVariance50thPercentile: 0,
            communityVariance65thPercentile: 0,
            communityVariance75thPercentile: 0,
            communityVariance90thPercentile: 0,
            imputedReportedcFTE50thPercentile: 0,
            imputedReportedcFTE65thPercentile: 0,
            imputedReportedcFTE75thPercentile: 0,
            imputedReportedcFTEMean: 0,
            imputedReportedcFTE90thPercentile: 0,
            imputedReportedcFTE25thPercentile: 0,
            varianceMean: 0,
            difference: 0
          }
        ],
        departmentProductivities: [
          {
            id: 0,
            nodeId: 0,
            nodeName: '',
            nodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            cfte: 0,
            charges: 0,
            wRVUs: 0,
            cfteAdjustedWRVUs: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0,
            benchmarkMean: 0,
            benchmark65thPercentile: 0,
            benchmarkPercentile: 0,
            communityBenchmark25thPercentile: 0,
            communityBenchmark50thPercentile: 0,
            communityBenchmark75thPercentile: 0,
            communityBenchmark90thPercentile: 0,
            communityBenchmarkMean: 0,
            communityBenchmark65thPercentile: 0,
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance65thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            communityImputedReportedcFTE25thPercentile: 0,
            communityImputedReportedcFTE50thPercentile: 0,
            communityImputedReportedcFTE65thPercentile: 0,
            communityImputedReportedcFTE75thPercentile: 0,
            communityImputedReportedcFTEMean: 0,
            communityImputedReportedcFTE90thPercentile: 0,
            communityVarianceMean: 0,
            communityVariance25thPercentile: 0,
            communityVariance50thPercentile: 0,
            communityVariance65thPercentile: 0,
            communityVariance75thPercentile: 0,
            communityVariance90thPercentile: 0,
            imputedReportedcFTE50thPercentile: 0,
            imputedReportedcFTE65thPercentile: 0,
            imputedReportedcFTE75thPercentile: 0,
            imputedReportedcFTEMean: 0,
            imputedReportedcFTE90thPercentile: 0,
            imputedReportedcFTE25thPercentile: 0,
            varianceMean: 0,
            difference: 0
          }
        ]
      },
      mergedProductivityTrend: [{
        year: 2016,
        month: '1',
        wRVUs: 100,
        cfteAdjustedWRVUs: 999,
        cfte: 80,
        charges: 999,
        variance25thPercentile: 999,
        variance50thPercentile: 999,
        variance65thPercentile: 999,
        variance75thPercentile: 999,
        variance90thPercentile: 999,
        varianceMean: 999,
        benchmark25thPercentile: 999,
        benchmark50thPercentile: 999,
        benchmark65thPercentile: 999,
        benchmark75thPercentile: 999,
        benchmark90thPercentile: 999,
        benchmarkMean: 999,
        previousWrvus: 999,
        previousCfteAdjustedWRVUs: 999,
        previousCfte: 999,
        previousCharges: 999,
        previousBenchmark25thPercentile: 999,
        previousBenchmark50thPercentile: 999,
        previousBenchmark65thPercentile: 999,
        previousBenchmark75thPercentile: 999,
        previousBenchmark90thPercentile: 999,
        previousBenchmarkMean: 999,
        communityBenchmark25thPercentile: 0.0,
        communityBenchmark50thPercentile: 0.0,
        communityBenchmark65thPercentile: 0.0,
        communityBenchmark75thPercentile: 0.0,
        communityBenchmark90thPercentile: 0.0,
        communityBenchmarkMean: 0.0,
        communityVarianceMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0
      }],
      evaluationManagementData: {
        providerEvaluationManagement: {
          totals: {
            newPatientVisit: {
              cpt99201: getCptSummary(0, 10),
              cpt99202: getCptSummary(0, 20),
              cpt99203: getCptSummary(0, 30),
              cpt99204: getCptSummary(0, 40),
              cpt99205: getCptSummary(0, 50)
            },
            establishedPatientVisit: {
              cpt99211: getCptSummary(0, 11),
              cpt99212: getCptSummary(0, 21),
              cpt99213: getCptSummary(0, 31),
              cpt99214: getCptSummary(0, 41),
              cpt99215: getCptSummary(0, 50)
            },
            consultation: {
              cpt99241: getCptSummary(1, 12),
              cpt99242: getCptSummary(1, 22),
              cpt99243: getCptSummary(1, 32),
              cpt99244: getCptSummary(1, 42),
              cpt99245: getCptSummary(1, 52)
            },
            subsequentHospitalCare: {
              cpt99231: getCptSummary(1, 12),
              cpt99232: getCptSummary(1, 22),
              cpt99233: getCptSummary(1, 32)
            },
            initialHospitalCare: {
              cpt99221: getCptSummary(6, 14, 46),
              cpt99222: getCptSummary(7, 24, 26),
              cpt99223: getCptSummary(8, 34, 56)
            },
            hospitalDischargeDay: {
              cpt99238: getCptSummary(9, 35, 57),
              cpt99239: getCptSummary(10, 36, 58)
            },
            emergencyMedicine: {
              cpt99281: getCptSummary(0, 0, 0),
              cpt99282: getCptSummary(0, 0, 0),
              cpt99283: getCptSummary(0, 0, 0),
              cpt99284: getCptSummary(0, 0, 0),
              cpt99285: getCptSummary(0, 0, 0)
            },
            eyeExamNewPatient: {
              cpt92002: getCptSummary(0, 0, 0),
              cpt92004: getCptSummary(0, 0, 0)
            },
            eyeExamEstablishedPatient: {
              cpt92012: getCptSummary(0, 0, 0),
              cpt92014: getCptSummary(0, 0, 0)
            }
          },
          nodes: [
            {
              nodeName: 'Kara Ramos',
              nodeId: 1234,
              nodePath: '\\1',
              cptCategories: {
                newPatientVisit: {
                  cpt99201: getCptSummary(0, 10),
                  cpt99202: getCptSummary(0, 20),
                  cpt99203: getCptSummary(0, 30),
                  cpt99204: getCptSummary(0, 40),
                  cpt99205: getCptSummary(0, 50)
                },
                establishedPatientVisit: {
                  cpt99211: getCptSummary(0, 11),
                  cpt99212: getCptSummary(0, 21),
                  cpt99213: getCptSummary(0, 31),
                  cpt99214: getCptSummary(0, 41),
                  cpt99215: getCptSummary(0, 50)
                },
                consultation: {
                  cpt99241: getCptSummary(3, 14),
                  cpt99242: getCptSummary(3, 24),
                  cpt99243: getCptSummary(3, 34),
                  cpt99244: getCptSummary(3, 44),
                  cpt99245: getCptSummary(3, 54)
                },
                subsequentHospitalCare: {
                  cpt99231: getCptSummary(4, 12),
                  cpt99232: getCptSummary(4, 22),
                  cpt99233: getCptSummary(4, 32)
                },
                initialHospitalCare: {
                  cpt99221: getCptSummary(5, 13, 45),
                  cpt99222: getCptSummary(15, 23, 25),
                  cpt99223: getCptSummary(25, 33, 55)
                },
                hospitalDischargeDay: {
                  cpt99238: getCptSummary(30, 45, 67),
                  cpt99239: getCptSummary(40, 46, 68)
                },
                emergencyMedicine: {
                  cpt99281: getCptSummary(0, 0, 0),
                  cpt99282: getCptSummary(0, 0, 0),
                  cpt99283: getCptSummary(0, 0, 0),
                  cpt99284: getCptSummary(0, 0, 0),
                  cpt99285: getCptSummary(0, 0, 0)
                },
                eyeExamNewPatient: {
                  cpt92002: getCptSummary(0, 0, 0),
                  cpt92004: getCptSummary(0, 0, 0)
                },
                eyeExamEstablishedPatient: {
                  cpt92012: getCptSummary(0, 0, 0),
                  cpt92014: getCptSummary(0, 0, 0)
                }
              }
            }
          ]
        }
      },
      evaluationManagementMultilevelData: {
        evaluationManagementData: {
          departmentEvaluationManagementSnapshotData: {
            nodes: [],
            totals: {
              hospitalDischargeDay: {
                cpt99238: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99239: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              initialHospitalCare: {
                cpt99223: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99221: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99222: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              eyeExamNewPatient: {
                cpt92002: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt92004: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              establishedPatientVisit: {
                cpt99214: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99215: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99212: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99213: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99211: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              emergencyMedicine: {
                cpt99285: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99283: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99284: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99281: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99282: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              consultation: {
                cpt99245: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99243: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99244: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99241: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99242: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              subsequentHospitalCare: {
                cpt99232: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99233: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99231: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              newPatientVisit: {
                cpt99203: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99204: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99201: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99202: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99205: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              eyeExamEstablishedPatient: {
                cpt92014: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt92012: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              }
            }
          },
          specialtyEvaluationManagementSnapshotData: {
            nodes: [],
            totals: {
              hospitalDischargeDay: {
                cpt99238: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99239: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              initialHospitalCare: {
                cpt99223: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99221: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99222: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              eyeExamNewPatient: {
                cpt92002: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt92004: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              establishedPatientVisit: {
                cpt99214: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99215: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99212: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99213: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99211: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              emergencyMedicine: {
                cpt99285: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99283: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99284: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99281: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99282: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              consultation: {
                cpt99245: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99243: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99244: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99241: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99242: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              subsequentHospitalCare: {
                cpt99232: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99233: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99231: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              newPatientVisit: {
                cpt99203: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99204: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99201: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99202: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99205: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              eyeExamEstablishedPatient: {
                cpt92014: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt92012: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              }
            }
          },
          providerEvaluationManagementSnapshotData: {
            nodes: [],
            totals: {
              hospitalDischargeDay: {
                cpt99238: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99239: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              initialHospitalCare: {
                cpt99223: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99221: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99222: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              eyeExamNewPatient: {
                cpt92002: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt92004: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              establishedPatientVisit: {
                cpt99214: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99215: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99212: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99213: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99211: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              emergencyMedicine: {
                cpt99285: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99283: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99284: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99281: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99282: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              consultation: {
                cpt99245: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99243: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99244: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99241: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99242: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              subsequentHospitalCare: {
                cpt99232: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99233: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99231: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              newPatientVisit: {
                cpt99203: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99204: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99201: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99202: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt99205: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              },
              eyeExamEstablishedPatient: {
                cpt92014: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                },
                cpt92012: {
                  count: 0,
                  benchmarkCptUnits: 0,
                  benchmarkRangeUnits: 0,
                  totalCount: 0,
                  benchmarkPercentage: 0,
                  communityBenchmarkPercentage: 0,
                  percentage: 0,
                  communityTelehealthBenchmarkPercentage: 0,
                  telehealthBenchmarkPercentage: 0,
                  communityInPersonBenchmarkPercentage: 0,
                  inPersonBenchmarkPercentage: 0,
                  communityBenchmarkCptUnits: 0,
                  telehealthBenchmarkRangeUnits: 0,
                  communityTelehealthBenchmarkCptUnits: 0,
                  communityTelehealthBenchmarkRangeUnits: 0,
                  inPersonBenchmarkCptUnits: 0,
                  inPersonBenchmarkRangeUnits: 0,
                  communityInPersonBenchmarkCptUnits: 0,
                  communityInPersonBenchmarkRangeUnits: 0,
                  communityBenchmarkRangeUnits: 0,
                  telehealthBenchmarkCptUnits: 0
                }
              }
            }
          }
        }
      },
      summaryEvaluationManagementData: {
        startYear: 0,
        endYear: 0,
        startMonth: 0,
        endMonth: 1111,
        totals: {
          hospitalDischargeDay: {
            cpt99238: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99239: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          initialHospitalCare: {
            cpt99223: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99221: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99222: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          eyeExamNewPatient: {
            cpt92002: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt92004: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          establishedPatientVisit: {
            cpt99214: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99215: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99212: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99213: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99211: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          emergencyMedicine: {
            cpt99285: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99283: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99284: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99281: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99282: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          consultation: {
            cpt99245: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99243: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99244: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99241: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99242: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          subsequentHospitalCare: {
            cpt99232: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99233: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99231: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          newPatientVisit: {
            cpt99203: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99204: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99201: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99202: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99205: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          eyeExamEstablishedPatient: {
            cpt92014: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt92012: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          }

        }
      },
      specialtySummaryEvaluationManagementData: {
        startYear: 0,
        endYear: 2222,
        startMonth: 0,
        endMonth: 0,
        totals: {
          hospitalDischargeDay: {
            cpt99238: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99239: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          initialHospitalCare: {
            cpt99223: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99221: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99222: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          eyeExamNewPatient: {
            cpt92002: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt92004: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          establishedPatientVisit: {
            cpt99214: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99215: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99212: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99213: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99211: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          emergencyMedicine: {
            cpt99285: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99283: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99284: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99281: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99282: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          consultation: {
            cpt99245: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99243: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99244: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99241: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99242: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          subsequentHospitalCare: {
            cpt99232: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99233: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99231: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          newPatientVisit: {
            cpt99203: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99204: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99201: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99202: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99205: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          eyeExamEstablishedPatient: {
            cpt92014: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt92012: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          }

        }
      },
      newPatientVisitMultilevelData: {
        providerNpvSnapshotData: [
          {
            nodeId: 0,
            nodeName: '',
            nodePath: '',
            previous: {
              nodeId: 0,
              nodeName: '',
              nodePath: '',
              isHidden: false,
              countOfNewPatientVisits: 0,
              countOfTotalPatientVisits: 0,
              newPatientVisitsPercentage: 0,
              countOfExistingPatientVisits: 0,
              difference: 0,
              departmentNodeId: 0,
              departmentNodeName: '',
              departmentNodePath: '',
              specialtyNodeId: 0,
              specialtyNodeName: '',
              specialtyNodePath: '',
              providerNodeId: 0,
              providerNodeName: '',
              providerNodePath: '',
              newPatientVisitsPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsTelehealthPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsInPersonPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityInPersonPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsTelehealthVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsInPersonVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityTelehealthVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityInPersonVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
            },
            isHidden: false,
            countOfNewPatientVisits: 0,
            countOfTotalPatientVisits: 0,
            newPatientVisitsPercentage: 0,
            countOfExistingPatientVisits: 0,
            difference: 0,
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            newPatientVisitsPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsTelehealthPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsInPersonPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityInPersonPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsTelehealthVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsInPersonVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityTelehealthVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityInPersonVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
          }
        ],
        specialtyNpvSnapshotData: [
          {
            nodeId: 0,
            nodeName: '',
            nodePath: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            isHidden: false,
            difference: 0,
            previous: {
              nodeId: 0,
              nodeName: '',
              nodePath: '',
              isHidden: false,
              countOfNewPatientVisits: 0,
              countOfTotalPatientVisits: 0,
              newPatientVisitsPercentage: 0,
              countOfExistingPatientVisits: 0,
              difference: 0,
              departmentNodeId: 0,
              departmentNodeName: '',
              departmentNodePath: '',
              specialtyNodeId: 0,
              specialtyNodeName: '',
              specialtyNodePath: '',
              providerNodeId: 0,
              providerNodeName: '',
              providerNodePath: '',
              newPatientVisitsPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsTelehealthPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsInPersonPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityInPersonPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsTelehealthVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsInPersonVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityTelehealthVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityInPersonVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
            },
            countOfNewPatientVisits: 0,
            countOfTotalPatientVisits: 0,
            newPatientVisitsPercentage: 0,
            countOfExistingPatientVisits: 0,
            newPatientVisitsPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsTelehealthPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsInPersonPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityInPersonPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsTelehealthVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsInPersonVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityTelehealthVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityInPersonVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
          }
        ],
        departmentNpvSnapshotData: [
          {
            nodeId: 0,
            nodeName: '',
            nodePath: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            previous: {
              nodeId: 0,
              nodeName: '',
              nodePath: '',
              isHidden: false,
              countOfNewPatientVisits: 0,
              countOfTotalPatientVisits: 0,
              newPatientVisitsPercentage: 0,
              countOfExistingPatientVisits: 0,
              difference: 0,
              departmentNodeId: 0,
              departmentNodeName: '',
              departmentNodePath: '',
              specialtyNodeId: 0,
              specialtyNodeName: '',
              specialtyNodePath: '',
              providerNodeId: 0,
              providerNodeName: '',
              providerNodePath: '',
              newPatientVisitsPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsTelehealthPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsInPersonPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsCommunityInPersonPercentageBenchmarks: {
                benchmark25thPercentile: 25,
                benchmark50thPercentile: 30,
                benchmark75thPercentile: 35,
                benchmark90thPercentile: 40,
                benchmarkMean: 45
              },
              newPatientVisitsVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsTelehealthVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsInPersonVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityTelehealthVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
              newPatientVisitsCommunityInPersonVariance: {
                variance25thPercentile: 88,
                variance50thPercentile: 88,
                variance75thPercentile: 88,
                variance90thPercentile: 88,
                varianceMean: 88
              },
            },
            countOfNewPatientVisits: 0,
            countOfTotalPatientVisits: 0,
            newPatientVisitsPercentage: 0,
            countOfExistingPatientVisits: 0,
            difference: 0,
            isHidden: false,
            newPatientVisitsPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsTelehealthPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsInPersonPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsCommunityInPersonPercentageBenchmarks: {
              benchmark25thPercentile: 25,
              benchmark50thPercentile: 30,
              benchmark75thPercentile: 35,
              benchmark90thPercentile: 40,
              benchmarkMean: 45
            },
            newPatientVisitsVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsTelehealthVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsInPersonVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityTelehealthVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
            newPatientVisitsCommunityInPersonVariance: {
              variance25thPercentile: 88,
              variance50thPercentile: 88,
              variance75thPercentile: 88,
              variance90thPercentile: 88,
              varianceMean: 88
            },
          }
        ]
      },
      mergedNpvLocationAggregatedByNode: [{
        countOfNewPatientVisits: -1, countOfTotalPatientVisits: -1, countOfExistingPatientVisits: -1,
        newPatientVisitsPercentage: -1,
        memberLocationName: DEFAULT_MEMBER_LOCATION.memberLocationName,
        memberLocationKey: DEFAULT_MEMBER_LOCATION.memberLocationKey,
        departmentNpvSnapshotData: [],
        specialtyNpvSnapshotData: [],
        providerNpvSnapshotData: [], previousPercentage: -1,
      }],
      newPatientVisitTrendData: [aNewPatientVisitTrendData()],
      summaryNewPatientVisitData: {
        selectedDateRange:
          {
            countOfNewPatientVisits: 1,
            newPatientVisitsPercentage: 1,
            countOfTotalPatientVisits: 1,
            per25th: 1,
            per50th: 1,
            per75th: 1,
            per90th: 1,
            mean: 1,
            communityPer25th: -1,
            communityPer50th: -1,
            communityPer75th: -1,
            communityPer90th: -1,
            communityMean: -1,
            startMonth: 0,
            endMonth: 0,
            startYear: 0,
            endYear: 0,
            summaryType: 0,
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
            communityVariance25thPercentile: -1,
            communityVariance50thPercentile: -1,
            communityVariance75thPercentile: -1,
            communityVariance90thPercentile: -1,
            communityVarianceMean: -1
          },
        previousYearSelectedDateRange: {
          countOfNewPatientVisits: 0,
          newPatientVisitsPercentage: 0,
          countOfTotalPatientVisits: 0,
          per25th: 0,
          per50th: 0,
          per75th: 0,
          per90th: 0,
          mean: 0,
          communityPer25th: -1,
          communityPer50th: -1,
          communityPer75th: -1,
          communityPer90th: -1,
          communityMean: -1,
          startMonth: 0,
          endMonth: 0,
          startYear: 0,
          endYear: 0,
          summaryType: 1,
          variance25thPercentile: 0,
          variance50thPercentile: 0,
          variance75thPercentile: 0,
          variance90thPercentile: 0,
          varianceMean: 0,
          communityVariance25thPercentile: -1,
          communityVariance50thPercentile: -1,
          communityVariance75thPercentile: -1,
          communityVariance90thPercentile: -1,
          communityVarianceMean: -1
        },
        yearToDate: {
          countOfNewPatientVisits: 0,
          newPatientVisitsPercentage: 0,
          countOfTotalPatientVisits: 0,
          per25th: 0,
          per50th: 0,
          per75th: 0,
          per90th: 0,
          mean: 0,
          communityPer25th: -1,
          communityPer50th: -1,
          communityPer75th: -1,
          communityPer90th: -1,
          communityMean: -1,
          startMonth: 0,
          endMonth: 0,
          startYear: 0,
          endYear: 0,
          summaryType: 2,
          variance25thPercentile: 0,
          variance50thPercentile: 0,
          variance75thPercentile: 0,
          variance90thPercentile: 0,
          varianceMean: 0,
          communityVariance25thPercentile: -1,
          communityVariance50thPercentile: -1,
          communityVariance75thPercentile: -1,
          communityVariance90thPercentile: -1,
          communityVarianceMean: -1
        },
        previousYearToDate: {
          countOfNewPatientVisits: 0,
          newPatientVisitsPercentage: 0,
          countOfTotalPatientVisits: 0,
          per25th: 0,
          per50th: 0,
          per75th: 0,
          per90th: 0,
          mean: 0,
          communityPer25th: -1,
          communityPer50th: -1,
          communityPer75th: -1,
          communityPer90th: -1,
          communityMean: -1,
          startMonth: 0,
          endMonth: 0,
          startYear: 0,
          endYear: 0,
          summaryType: 3,
          variance25thPercentile: 0,
          variance50thPercentile: 0,
          variance75thPercentile: 0,
          variance90thPercentile: 0,
          varianceMean: 0,
          communityVariance25thPercentile: -1,
          communityVariance50thPercentile: -1,
          communityVariance75thPercentile: -1,
          communityVariance90thPercentile: -1,
          communityVarianceMean: -1
        },
        recentMonth: {
          countOfNewPatientVisits: 0,
          newPatientVisitsPercentage: 0,
          countOfTotalPatientVisits: 0,
          per25th: 0,
          per50th: 0,
          per75th: 0,
          per90th: 0,
          mean: 0,
          communityPer25th: -1,
          communityPer50th: -1,
          communityPer75th: -1,
          communityPer90th: -1,
          communityMean: -1,
          startMonth: 0,
          endMonth: 0,
          startYear: 0,
          endYear: 0,
          summaryType: 4,
          variance25thPercentile: 0,
          variance50thPercentile: 0,
          variance75thPercentile: 0,
          variance90thPercentile: 0,
          varianceMean: 0,
          communityVariance25thPercentile: -1,
          communityVariance50thPercentile: -1,
          communityVariance75thPercentile: -1,
          communityVariance90thPercentile: -1,
          communityVarianceMean: -1
        },
        previousYearRecentMonth: {
          countOfNewPatientVisits: 0,
          newPatientVisitsPercentage: 0,
          countOfTotalPatientVisits: 0,
          per25th: 0,
          per50th: 0,
          per75th: 0,
          per90th: 0,
          mean: 0,
          communityPer25th: -1,
          communityPer50th: -1,
          communityPer75th: -1,
          communityPer90th: -1,
          communityMean: -1,
          startMonth: 0,
          endMonth: 0,
          startYear: 0,
          endYear: 0,
          summaryType: 5,
          variance25thPercentile: 0,
          variance50thPercentile: 0,
          variance75thPercentile: 0,
          variance90thPercentile: 0,
          varianceMean: 0,
          communityVariance25thPercentile: -1,
          communityVariance50thPercentile: -1,
          communityVariance75thPercentile: -1,
          communityVariance90thPercentile: -1,
          communityVarianceMean: -1
        }
      },
      zipCodeNewPatientVisits: [
        {
          lat: '11',
          lng: '11',
          zipCode: '12345',
          newPatientVisitCount: 0,
          newPatientVisitPercentage: 0,
          totalPatientVisitCount: 0
        }
      ],
      selectedMemberData: {
        memberKey: DEFAULT_MEMBER_KEY,
        memberDesc: 'some member'
      },
      userProfile: aUserProfileDefault(),
      members: aMembersDefault(),
      productivitySummaryData: {
        selectedDateRange:
          {
            startYear: 5,
            startMonth: 5,
            endYear: 5,
            endMonth: 5,
            benchmark25thPercentile: 5,
            benchmark50thPercentile: 5,
            benchmark65thPercentile: 5,
            benchmark75thPercentile: 5,
            benchmark90thPercentile: 5,
            benchmarkMean: 5,
            variance25thPercentile: 5,
            variance50thPercentile: 5,
            variance65thPercentile: 5,
            variance75thPercentile: 5,
            variance90thPercentile: 5,
            varianceMean: 5,
            cfte: 5,
            cfteAdjustedWRVUs: 5,
            charges: 5,
            summaryType: 5,
            wRVUs: 5,
            communityVarianceMean: 0,
            communityVariance25thPercentile: 0,
            communityVariance50thPercentile: 0,
            communityVariance65thPercentile: 0,
            communityVariance75thPercentile: 0,
            communityVariance90thPercentile: 0,
            communityImputedReportedcFTE25thPercentile: 0,
            communityImputedReportedcFTE50thPercentile: 0,
            communityImputedReportedcFTE65thPercentile: 0,
            communityImputedReportedcFTE75thPercentile: 0,
            communityImputedReportedcFTEMean: 0,
            communityImputedReportedcFTE90thPercentile: 0,
            communityBenchmark25thPercentile: 0,
            communityBenchmark50thPercentile: 0,
            communityBenchmark65thPercentile: 0,
            communityBenchmark75thPercentile: 0,
            communityBenchmark90thPercentile: 0,
            communityBenchmarkMean: 0
          },
        previousYearSelectedDateRange: aProductivitySummaryIntialized(),
        yearToDate: aProductivitySummaryIntialized(),
        previousYearToDate: aProductivitySummaryIntialized(),
        recentMonth: aProductivitySummaryIntialized(),
        previousYearRecentMonth: aProductivitySummaryIntialized()
      },
      featureToggleSettings: aFeatureToggleSettings(),
      applicationConfigurationSettings: aApplicationConfigurationSettings(),
      clinicalSummaryConsolidationData: {
        familyClinicalSummaries: [{
          cptFamilyDesc: 'test',
          charges: 1.11,
          cfte: 0,
          cfteAdjustedWRVUs: 1.11,
          frequency: 1.11,
          totalWorkRVUs: 1.11,
          workRvuBenchmark: 1.11,
          totalWorkRvuBenchmark: 1.11,
          frequencyBenchmark: 1.11,
          cfteAdjustedTotalWRVUs: 1.11,
          cfteAdjustedFrequency: 1.11,
          cFTEAdjustedFrequencyVariance: 1.11,
          cFTEAdjustedWrvusVariance: 1.11,
          cFTEAdjustedTotalWrvusVariance: 1.11,
          wRVUs: 1.11,
          communityWorkRvuBenchmark: 1.11, // Community cFTE Adj. wRVUs Benchmark
          communityTotalWorkRvuBenchmark: 1.11,
          communityFrequencyBenchmark: 1.11, // Community CPSC cFTE Adj. Count
          communityCfteAdjustedFrequencyVariance: 1.11, // Community cFTE Adj. Count Variance from Benchmark
          communityCfteAdjustedWrvusVariance: 1.11, // Community cFTE Adj. wRVUs Variance from Benchmark
          communityCfteAdjustedTotalWrvusVariance: 1.11
        }],
        rangeClinicalSummaries: [{
          cptRangeDesc: 'test',
          cptFamilyDesc: 'test',
          cptRangeLow: 'test',
          cptRangeHigh: 'test',
          charges: 1.11,
          cfte: 0,
          cfteAdjustedWRVUs: 1.11,
          frequency: 1.11,
          totalWorkRVUs: 1.11,
          workRvuBenchmark: 1.11,
          totalWorkRvuBenchmark: 1.11,
          frequencyBenchmark: 1.11,
          cfteAdjustedTotalWRVUs: 1.11,
          cfteAdjustedFrequency: 1.11,
          cFTEAdjustedFrequencyVariance: 1.11,
          cFTEAdjustedWrvusVariance: 1.11,
          cFTEAdjustedTotalWrvusVariance: 1.11,
          wRVUs: 1.11,
          communityWorkRvuBenchmark: 1.11, // Community cFTE Adj. wRVUs Benchmark
          communityTotalWorkRvuBenchmark: 1.11,
          communityFrequencyBenchmark: 1.11, // Community CPSC cFTE Adj. Count
          communityCfteAdjustedFrequencyVariance: 1.11, // Community cFTE Adj. Count Variance from Benchmark
          communityCfteAdjustedWrvusVariance: 1.11, // Community cFTE Adj. wRVUs Variance from Benchmark
          communityCfteAdjustedTotalWrvusVariance: 1.11
        }],
        cptClinicalSummaries: [{
          cptCode: 'test',
          cptDesc: 'test',
          cptRangeDesc: 'test',
          cptFamilyDesc: 'test',
          cptRangeLow: 'test',
          cptRangeHigh: 'test',
          charges: 1.11,
          cfte: 0,
          cfteAdjustedWRVUs: 1.11,
          frequency: 1.11,
          totalWorkRVUs: 1.11,
          workRvuBenchmark: 1.11,
          totalWorkRvuBenchmark: 1.11,
          frequencyBenchmark: 1.11,
          cfteAdjustedTotalWRVUs: 1.11,
          cfteAdjustedFrequency: 1.11,
          cFTEAdjustedFrequencyVariance: 1.11,
          cFTEAdjustedWrvusVariance: 1.11,
          cFTEAdjustedTotalWrvusVariance: 1.11,
          communityWorkRvuBenchmark: 1.11,
          communityTotalWorkRvuBenchmark: 1.11,
          communityFrequencyBenchmark: 1.11,
          communityCfteAdjustedFrequencyVariance: 1.11,
          communityCfteAdjustedWrvusVariance: 1.11,
          communityCfteAdjustedTotalWrvusVariance: 1.11,
          wRVUs: 1.11
        }]
      },
      clinicalFingerprintMultilevelData: {
        cfpByProvider: [],
        cfpBySpecialty: [],
        cfpByDepartment: []
      },
      denials: {
        denialRates: [{
          payerCategoryDesc: '',
          payerKey: 0,
          payerType: 0,
          deniedChargedAmount: 0,
          deniedChargedAmountPaid: 0,
          chargesDeniedPercentage: 0,
          terminalDenialRate: 0,
          terminalDenialRatePer25th: 0,
          terminalDenialRatePer50th: 0,
          terminalDenialRatePer75th: 0,
          chargesTerminallyDenied: 0,
          terminalChargesDeniedPercentage: 0,
          terminalDenialRateBenchmark50th: 0,
          id: 0,
          chargeAmount: 0,
          numberBilled: 0,
          numberDenied: 0,
          numberDeniedPaid: 0,
          deniedPaidPer25th: 0,
          deniedPaidPer50th: 0,
          deniedPaidPer75th: 0,
          denialRate: 0,
          deniedPaidRate: 0,
          denialRatePer25th: 0,
          denialRatePer50th: 0,
          denialRatePer75th: 0,
          variancePer25th: 0,
          variancePer50th: 0,
          variancePer75th: 0
        }]
      },
      denialsByMultiLevelData: aDenialsByMultiLevelDataDefault(),
      payerCollectionsData:
        {
          payerMatchedCollections: [{
            payerCategoryKey: 0,
            payerCategoryDescription: '',
            chargeAmount: 0,
            charityCareAmount: 0,
            discountAmount: 0,
            patientPaymentAmount: 0,
            primaryPayerPaymentAmount: 0,
            otherPayerPaymentAmount: 0,
            primaryPayerAdjAmount: 0,
            otherPayerAdjAmount: 0,
            primaryAndOtherPayerContractuals: 0,
            primaryAndOtherInsurancePayments: 0,
            expectedPayments: 0,
            totalPayments: 0,
            netCollectionRate: 0,
            benchmark25th: 0,
            benchmark50th: 0,
            benchmark75th: 0,
            variance25th: 0,
            variance50th: 0,
            variance75th: 0,
            outstandingAR: 0,
            outstandingARAsPctOfCharges: 0,
            controllableAllowance: 0,
            patientRelatedBadDebt: 0,
            smallBalanceWriteOffs: 0
          }]
        },
      matchedCollectionsByPayerByMultiLevelByNodePathData:
        {
          providerCollections: [
            {
              payerCategoryKey: 0,
              payerCategoryDescription: '',
              departmentNodeId: 0,
              departmentNodeName: '',
              departmentNodePath: '',
              specialtyNodeId: 0,
              specialtyNodeName: '',
              specialtyNodePath: '',
              providerNodeId: 0,
              providerNodeName: '',
              providerNodePath: '',
              benchmark25th: 0,
              benchmark50th: 0,
              benchmark75th: 0,
              chargeAmount: 0,
              charityCareAmount: 0,
              controllableAllowance: 0,
              discountAmount: 0,
              expected25thPayments: 0,
              expected50thPayments: 0,
              expected75thPayments: 0,
              expectedPayments: 0,
              netCollectionRate: 0,
              otherPayerAdjAmount: 0,
              otherPayerPaymentAmount: 0,
              outstandingAR: 0,
              outstandingARAsPctOfCharges: 0,
              patientPaymentAmount: 0,
              patientRelatedBadDebt: 0,
              paymentAtBenchmark25th: 0,
              paymentAtBenchmark50th: 0,
              paymentAtBenchmark75th: 0,
              primaryAndOtherInsurancePayments: 0,
              primaryAndOtherPayerContractuals: 0,
              primaryPayerAdjAmount: 0,
              primaryPayerPaymentAmount: 0,
              smallBalanceWriteOffs: 0,
              totalPayments: 0,
              variance25th: 0,
              variance50th: 0,
              variance75th: 0
            }],
          departmentCollections: [{
            payerCategoryKey: 0,
            payerCategoryDescription: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            benchmark25th: 0,
            benchmark50th: 0,
            benchmark75th: 0,
            chargeAmount: 0,
            charityCareAmount: 0,
            controllableAllowance: 0,
            discountAmount: 0,
            expected25thPayments: 0,
            expected50thPayments: 0,
            expected75thPayments: 0,
            expectedPayments: 0,
            netCollectionRate: 0,
            otherPayerAdjAmount: 0,
            otherPayerPaymentAmount: 0,
            outstandingAR: 0,
            outstandingARAsPctOfCharges: 0,
            patientPaymentAmount: 0,
            patientRelatedBadDebt: 0,
            paymentAtBenchmark25th: 0,
            paymentAtBenchmark50th: 0,
            paymentAtBenchmark75th: 0,
            primaryAndOtherInsurancePayments: 0,
            primaryAndOtherPayerContractuals: 0,
            primaryPayerAdjAmount: 0,
            primaryPayerPaymentAmount: 0,
            smallBalanceWriteOffs: 0,
            totalPayments: 0,
            variance25th: 0,
            variance50th: 0,
            variance75th: 0
          }],
          specialtyCollections: [{
            payerCategoryKey: 0,
            payerCategoryDescription: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            benchmark25th: 0,
            benchmark50th: 0,
            benchmark75th: 0,
            chargeAmount: 0,
            charityCareAmount: 0,
            controllableAllowance: 0,
            discountAmount: 0,
            expected25thPayments: 0,
            expected50thPayments: 0,
            expected75thPayments: 0,
            expectedPayments: 0,
            netCollectionRate: 0,
            otherPayerAdjAmount: 0,
            otherPayerPaymentAmount: 0,
            outstandingAR: 0,
            outstandingARAsPctOfCharges: 0,
            patientPaymentAmount: 0,
            patientRelatedBadDebt: 0,
            paymentAtBenchmark25th: 0,
            paymentAtBenchmark50th: 0,
            paymentAtBenchmark75th: 0,
            primaryAndOtherInsurancePayments: 0,
            primaryAndOtherPayerContractuals: 0,
            primaryPayerAdjAmount: 0,
            primaryPayerPaymentAmount: 0,
            smallBalanceWriteOffs: 0,
            totalPayments: 0,
            variance25th: 0,
            variance50th: 0,
            variance75th: 0
          }]
        },

      matchedCollectionsByMultiLevelByNodePathData:
        {
          providerCollections: [
            {
              benchmark25th: 0,
              benchmark50th: 0,
              benchmark75th: 0,
              chargeAmount: 0,
              charityCareAmount: 0,
              controllableAllowance: 0,
              departmentNodeId: 0,
              departmentNodeName: '',
              departmentNodePath: '',
              discountAmount: 0,
              expectedPayments: 0,
              netCollectionRate: 0,
              otherPayerAdjAmount: 0,
              otherPayerPaymentAmount: 0,
              patientPaymentAmount: 0,
              patientRelatedBadDebt: 0,
              paymentAtBenchmark25th: 0,
              paymentAtBenchmark50th: 0,
              paymentAtBenchmark75th: 0,
              primaryAndOtherInsurancePayments: 0,
              primaryAndOtherPayerContractuals: 0,
              primaryPayerAdjAmount: 0,
              primaryPayerPaymentAmount: 0,
              providerNodeId: 0,
              providerNodeName: '',
              providerNodePath: '',
              smallBalanceWriteOffs: 0,
              specialtyNodeId: 0,
              specialtyNodeName: '',
              specialtyNodePath: '',
              totalPayments: 0,
              variance25th: 0,
              variance50th: 0,
              variance75th: 0,
              outstandingAR: 0,
              outstandingARAsPctOfCharges: 0
            }],
          departmentCollections: [{
            benchmark25th: 0,
            benchmark50th: 0,
            benchmark75th: 0,
            chargeAmount: 0,
            charityCareAmount: 0,
            controllableAllowance: 0,
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            discountAmount: 0,
            expectedPayments: 0,
            netCollectionRate: 0,
            otherPayerAdjAmount: 0,
            otherPayerPaymentAmount: 0,
            patientPaymentAmount: 0,
            patientRelatedBadDebt: 0,
            paymentAtBenchmark25th: 0,
            paymentAtBenchmark50th: 0,
            paymentAtBenchmark75th: 0,
            primaryAndOtherInsurancePayments: 0,
            primaryAndOtherPayerContractuals: 0,
            primaryPayerAdjAmount: 0,
            primaryPayerPaymentAmount: 0,
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            smallBalanceWriteOffs: 0,
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            totalPayments: 0,
            variance25th: 0,
            variance50th: 0,
            variance75th: 0,
            outstandingAR: 0,
            outstandingARAsPctOfCharges: 0
          }],
          specialtyCollections: [{
            benchmark25th: 0,
            benchmark50th: 0,
            benchmark75th: 0,
            chargeAmount: 0,
            charityCareAmount: 0,
            controllableAllowance: 0,
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            discountAmount: 0,
            expectedPayments: 0,
            netCollectionRate: 0,
            otherPayerAdjAmount: 0,
            otherPayerPaymentAmount: 0,
            patientPaymentAmount: 0,
            patientRelatedBadDebt: 0,
            paymentAtBenchmark25th: 0,
            paymentAtBenchmark50th: 0,
            paymentAtBenchmark75th: 0,
            primaryAndOtherInsurancePayments: 0,
            primaryAndOtherPayerContractuals: 0,
            primaryPayerAdjAmount: 0,
            primaryPayerPaymentAmount: 0,
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            smallBalanceWriteOffs: 0,
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            totalPayments: 0,
            variance25th: 0,
            variance50th: 0,
            variance75th: 0,
            outstandingAR: 0,
            outstandingARAsPctOfCharges: 0
          }]
        },

      summaryCollectionsData: {
        selectedDateRange: aSummaryCollectionsDataDefault(0),
        previousYearSelectedDateRange: aSummaryCollectionsDataDefault(1),
        yearToDate: aSummaryCollectionsDataDefault(2),
        previousYearToDate: aSummaryCollectionsDataDefault(3),
        recentMonth: aSummaryCollectionsDataDefault(4),
        previousYearRecentMonth: aSummaryCollectionsDataDefault(5)
      },
      customGroupsData: [{
        endDateInMonths: 0,
        invoiceStatus: 0,
        lagKey: 0,
        memberBillingAreaKey: 0,
        memberKey: 0,
        memberLocationKey: 0, memberLocationKeys: '0',
        payerCategoryKey: 0,
        payerType: 0,
        startDateInMonths: 0,
        telehealthFlag: 0,
        id: -1,
        name: 'test',
        description: 'this is a group',
        nodePath: '\\',
        isDefault: false,
        preload: false
      }]
    },
    benchmark: BenchmarkPercentile.Percentile25th,
    ontologyLoaded: false,
    display: {
      collectionsChargeDisplayed: false,
      expectedPaymentsDisplayed: false,
      chargesTerminallyDeniedDisplayed: false,
      denialsChargesDisplayed: false,
      deniedCPTsPaidDisplayed: false,
      terminalDenialRateDisplayed: false,
      charges: false,
      previousTimePeriod: false,
      patientVisitCounts: false,
      previousPatientVisitCounts: false,
      navigationNodePath: '',
      isCustom: false,
      wrvuViewType: WrvuViewType.Actual,
      cfpVariableViewType: CfpVariableViewType.ActualCount,
      isProviderSelected: false,
      multipleProviders: false,
      multipleSpecialties: false,
      multipleDepartments: false,
      recentMonth: -1,
      selectedProviders: 0,
      selectedSpecialties: 0,
      showHierarchySearch: false,
      zeroSuppression: false,
      filterBannerEdit: false,
      cptViewType: CptViewType.CptCode,
      newFeatures: {
        id: 1,
        featureViewed: 0,
        emailId: ''
      },
      isRevenueMember: false,
      viewCommunityBenchmarks: false,
      communityBenchmarkAlert: false,
      emDimension: EmDimension.Outpatient,
      payerByLevelCollectionsStaticColumns: getCollectionsColumns(),
      payerByLevelCollectionsPayerColumns: [],
      multiLevelByNodePathCollectionsColumns: [],
      multiLevelByNodePathPayerCollectionsColumns: [],
      displayedCollectionsColumns: getCollectionsColumns(),
      displayedDenialsColumns: getDenialsColumns(),
      displayedMultiLevelDenialsColumns: getDenialsColumns(),
      displayedPayerCollectionsColumns: getCollectionsColumns(),
      displayedWRVUSnapshotColumns: getWRVUSnapshotColumns(),
      displayedWRVUProviderColumns: getWRVUProviderColumns(),
      displayedWRVUTrendColumns: getWRVUTrendColumns(),
      displayedWRVUSpecialtyPerformanceColumns: getWRVUSpecialtyPerformanceColumns(),
      displayedNPVTrendColumns: getNpvTrendColumns(),
      displayedNPVSnapshotColumns: getNpvSnapshotColumns(),
      displayedNPVDepartmentColumns: getNpvByDepartmentColumns(),
      displayedNPVSpecialtyColumns: getNpvBySpecialtyColumns(),
      displayedNPVProviderColumns: getNpvByProviderColumns(),
      displayedCollectionsDepartmentColumns: getDepartmentMultiLevelCollectionsColumns(),
      displayedCollectionsSpecialtyColumns: getSpecialtyMultiLevelCollectionsColumns(),
      displayedCollectionsProviderColumns: getProviderMultiLevelCollectionsColumns(),
      displayedCollectionsByPayerDepartmentColumns: getDepartmentMultiLevelByPayerCollectionsColumns(),
      displayedCollectionsByPayerSpecialtyColumns: getSpecialtyMultiLevelByPayerCollectionsColumns(),
      displayedCollectionsByPayerProviderColumns: getProviderMultiLevelByPayerCollectionsColumns(),
      drillDown: {
        isDrillDown: false,
        nodePath: '',
        tab: MultilevelTab.NONE
      },
      varianceToggle: {
        npvVariance: false,
        wrvuVariance: false,
        cfpVariance: false,
        collectionSnapshotVariance: false,
        denialsVariance: false
      },
      sortingCriteria: {
        wrvuSnapshotSortingCriteria: {
          columnDef: 'varianceMean',
          sortingOrder: SortingOrder.DESCENDING
        },
        npvSnapshotSortingCriteria: {
          columnDef: 'countOfNewPatientVisits',
          sortingOrder: SortingOrder.ASCENDING
        },
        collectionsSnapshotSortingCriteria: {
          columnDef: 'expectedPayments',
          sortingOrder: SortingOrder.ASCENDING
        },
        cfpSortingCriteria: {
          columnDef: 'cfteAdjustedWRVUs',
          sortingOrder: SortingOrder.ASCENDING
        },
        denialsSortingCriteria: {
          columnDef: 'denialRate',
          sortingOrder: SortingOrder.ASCENDING
        }
      },
      isExporting: false,
      isLessThanLagPeriod: true,
      cFTEAdjustedCountDisplayed: false,
      cfpChargesDisplayed: false,
      newProviderExports: 0,
      denialsChosenTab: MultilevelTabCollections.BY_DEPARTMENT,
      collectionsChosenTab: MultilevelTabCollections.BY_DEPARTMENT,
      drillDownHistory: [],
      multilevelTab: MultilevelTab.BY_DEPARTMENT,
      emNpvBenchmarkOption: BenchmarkOption.Academic,
      procedureSummaryVariables: {
        charges: false, viewByNode: false
      },
      newPatientVisitsLocationVariables: aDefaultNewPatientVisitsLocationVariables
    },
    oktaToken: '',
    userPreferences: {
      ...DEFAULT_USER_PREFERENCES,
      id: 9,
      emailId: 'example@email.com',
      startDateInMonths: (2016 * 12) + 10,
      endDateInMonths: (2018 * 12) + 5,
      nodePath: '\\1234\\5678',
      isDefault: false
    }
  };
}

export function aMemberBillingArea(): MemberBillingArea {
  return {
    memberBillingAreaKey: 999,
    memberBillingAreaDescription: 'desc',
    memberBillingAreaCode: 'code'
  };
}

export function aMemberBillingAreas(): MemberBillingArea[] {
  return [aMemberBillingArea(), aMemberBillingArea(), aMemberBillingArea()];
}

export function aSelectableMemberBillingArea(): SelectableMemberBillingArea {
  return {
    memberBillingArea: aMemberBillingArea(),
    matchesSearchText: true,
    selected: false
  };
}

export function aSelectableNode(): SelectableNode {
  return {
    node: anOntologyNode(),
    matchesFilterSearchText: true,
    belongs: true,
    selected: getRandomInt(2) === 1,
    level: anOntologyLevel()
  };
}

export function aHierarchicalSelectableNode(): HierarchicalSelectableNode {
  return {
    selectableNode: aSelectableNode(),
    ancestors: new Map<string, SelectableNode[]>(),
    descendants: new Map<string, SelectableNode[]>()
  };
}

export function aSelectableMemberBillingAreas(howMany?: number): SelectableMemberBillingArea[] {
  let i: number;
  const selectableMemberBillingAreas: SelectableMemberBillingArea[] = [];
  howMany = howMany || getRandomInt(5) + 3;
  for (i = 0; i < howMany; i++) {
    selectableMemberBillingAreas.push(aSelectableMemberBillingArea());
  }
  return selectableMemberBillingAreas;
}

export const aBatchRequest = function (): BatchExportDetailCriteria {
  return {
    reportName: 'hardCoded119',
    startDateInMonths: 16,
    endDateInMonths: 161,
    memberLocationKey: aMemberLocation().memberLocationKey,
    payerCategoryKey: aPayerCategory().payerCategoryKey,
    payerKey: 162,
    payerType: aPayerType(),
    lagKey: DEFAULT_LAG_KEY,
    memberBillingAreaKey: aMemberBillingArea().memberBillingAreaKey,
    invoiceStatus: DEFAULT_INVOICE_STATUS,
    nodePath: 'hardCoded120',
    isOutpatientRequired: true,
    isInPatientRequired: true,
    isEmergencyMedicineRequired: true,
    isOpthalmologyRequired: true,
    payerCategoryDescription: 'hardCoded121',
    memberLocationName: 'hardCoded122',
    memberName: 'hardCoded123',
    billingArea: 'hardCoded124',
    telehealthFlag: 0,
    isOverviewRequired: true,
    isEmPageRequired: true,
    isEmPageOutpatientRequired: true,
    isEmPageInPatientRequired: true,
    isEmPageEmergencyMedicineRequired: true,
    isEmPageOpthalmologyRequired: true,
    isNewPatientVisitsTrendPageRequired: true,
    getBatchExportReportDetailsByUser: true,
    memberLocationKeys: '92|514', multiLocationMessage: 'hardCoded125',
    isEmTrendPageRequired: true,
    emTrendRangeNewPatientVisit: true,
    emTrendRangeEstablishedPatientVisit: true,
    emTrendRangeConsultation: true,
    emTrendRangeInitialHospital: true,
    emTrendRangeSubsequentHospital: true,
    emTrendRangeHospitalDischarge: true,
    emTrendRangeEmergencyMedicine: true,
    emTrendRangeNpvEyeExam: true,
    emTrendRangeEstablishedEyeExam: true,
    npvTileBenchmarkType: BenchmarkOption.TelehealthAcademic,
    npvTileBenchmarkPercentile: BenchmarkPercentile.Percentile50th,
    wrvuTileBenchmarkType: BenchmarkOption.InPersonCommunity,
    wrvuTileBenchmarkPercentile: BenchmarkPercentile.Percentile75th,
    emTileBenchmarkType: BenchmarkOption.Academic,
    emTrendBenchmarkType: BenchmarkOption.Community,
    emPageBenchmarkType: BenchmarkOption.InPersonAcademic,
    npvPageBenchmarkType: BenchmarkOption.TelehealthCommunity,
    npvPageBenchmarkPercentile: BenchmarkPercentile.Percentile25th
  };
};

export const aBatchExportReport = function (status?: BatchExportStatus): BatchExportHeader {
  return {
    id: 7,
    reportName: 'hardCoded126',
    memberKey: 7,
    email: 'hardCoded127',
    nodePath: 'hardCoded128',
    status: status === undefined ? 2 : status,
    path: 'hardCoded129',
    createdOn: new Date().toUTCString(),
    createdBy: 'hardCoded130',
    lastUpdatedBy: 'hardCoded131',
    numDepartments: 3,
    numSpecialties: 2,
    numProviders: 1
  };
};

export const aBatchExportReportDetail = function (reportStatus?: number): BatchExportReportDetailsResponseEntity {
  return {
    id: 7,
    reportId: 8,
    reportName: 'aaaabbbbbccccddddd',
    reportStatus: reportStatus === undefined ? 3 : reportStatus,
    exportDate: new Date().toUTCString(),
    readStatus: true,
    path: 'test',
    numberOfDepartments: 1,
    numberOfSpecialties: 2,
    numberOfProviders: 3
  };
};

export const aBatchExportDetails = function (): BatchExportDetails {
  return {
    id: 7,
    headerId: 9,
    reportName: 'hardCoded132',
    memberKey: 1,
    email: 'hardCoded133',
    headerStatus: 2,
    detailId: 3,
    startDateInMonths: 4,
    endDateInMonths: 6,
    memberLocationKey: 7,
    payerCategoryKey: 8,
    payerKey: 9,
    payerType: 1,
    lagKey: 2,
    memberBillingAreaKey: 3,
    invoiceStatus: 4,
    nodePath: 'hardCoded134',
    isEmPageEmergencyMedicineRequired: false,
    isEmPageInPatientRequired: false,
    isEmPageOpthalmologyRequired: false,
    isEmPageOutpatientRequired: false,
    isEmPageRequired: false,
    isOverviewRequired: false,
    memberLocationKeys: '',
    detailStatus: 5,
    isOutpatientRequired: true,
    isInPatientRequired: true,
    isEmergencyMedicineRequired: true,
    isOpthalmologyRequired: true,
    isNewPatientVisitsTrendPageRequired: true,
    path: 'hardCoded135',
    telehealthFlag: 0,
    isEmTrendPageRequired: true,
    emTrendRangeNewPatientVisit: true,
    emTrendRangeEstablishedPatientVisit: true,
    emTrendRangeConsultation: true,
    emTrendRangeInitialHospital: true,
    emTrendRangeSubsequentHospital: true,
    emTrendRangeHospitalDischarge: true,
    emTrendRangeEmergencyMedicine: true,
    emTrendRangeNpvEyeExam: true,
    emTrendRangeEstablishedEyeExam: true
  };
};

export const getRequestBodyFromBatchFilter = function (filterCriteria: BatchFilterCriteria) {
  return {
    startYear: filterCriteria.startYear,
    endYear: filterCriteria.endYear,
    startMonth: filterCriteria.startMonth,
    endMonth: filterCriteria.endMonth,
    nodePath: filterCriteria.nodePath,
    memberLocationKey: filterCriteria.memberLocationKey,
    payerCategoryKeyExpected: filterCriteria.payerCategoryKey,
    payerKey: filterCriteria.payerKey,
    payerType: filterCriteria.payerType,
    memberBillingAreaKey: filterCriteria.memberBillingAreaKey,
    lagKey: filterCriteria.lagKey,
    invoiceStatus: filterCriteria.invoiceStatus,
    email: filterCriteria.email,
    guid: filterCriteria.guid,
    headerId: filterCriteria.headerId,
    detailId: filterCriteria.detailId,
    telehealthFlag: filterCriteria.telehealthFlag,
    memberLocationKeys: filterCriteria.memberLocationKeys
  };
};

export const getBatchRequestBodyFromFilter = function (batchExportCriteria: BatchExportDetailCriteria) {
  return {
    reportName: batchExportCriteria.reportName,
    startDateInMonths: batchExportCriteria.startDateInMonths,
    endDateInMonths: batchExportCriteria.endDateInMonths,
    memberLocationKey: batchExportCriteria.memberLocationKey,
    payerCategoryKey: batchExportCriteria.payerCategoryKey,
    payerKey: batchExportCriteria.payerKey,
    payerType: batchExportCriteria.payerType,
    lagKey: batchExportCriteria.lagKey,
    memberBillingAreaKey: batchExportCriteria.memberBillingAreaKey,
    invoiceStatus: batchExportCriteria.invoiceStatus,
    nodePath: batchExportCriteria.nodePath,
    isOutpatientRequired: batchExportCriteria.isOutpatientRequired,
    isInPatientRequired: batchExportCriteria.isInPatientRequired,
    isEmergencyMedicineRequired: batchExportCriteria.isEmergencyMedicineRequired,
    isOpthalmologyRequired: batchExportCriteria.isOpthalmologyRequired,
    payerCategoryDescription: batchExportCriteria.payerCategoryDescription,
    memberLocationName: batchExportCriteria.memberLocationName,
    memberName: batchExportCriteria.memberName,
    billingArea: batchExportCriteria.billingArea,
    telehealthFlag: batchExportCriteria.telehealthFlag,
    isOverviewRequired: batchExportCriteria.isOverviewRequired,
    isEmPageRequired: batchExportCriteria.isEmPageRequired,
    isEmPageOutpatientRequired: batchExportCriteria.isEmPageOutpatientRequired,
    isEmPageInPatientRequired: batchExportCriteria.isEmPageInPatientRequired,
    isEmPageEmergencyMedicineRequired: batchExportCriteria.isEmPageEmergencyMedicineRequired,
    isEmPageOpthalmologyRequired: batchExportCriteria.isEmPageOpthalmologyRequired,
    isNewPatientVisitsTrendPageRequired: batchExportCriteria.isNewPatientVisitsTrendPageRequired,
    memberLocationKeys: batchExportCriteria.memberLocationKeys,
    multiLocationMessage: batchExportCriteria.multiLocationMessage,
    isEmTrendPageRequired: batchExportCriteria.isEmTrendPageRequired,
    emTrendRangeNewPatientVisit: batchExportCriteria.emTrendRangeNewPatientVisit,
    emTrendRangeEstablishedPatientVisit: batchExportCriteria.emTrendRangeEstablishedPatientVisit,
    emTrendRangeConsultation: batchExportCriteria.emTrendRangeConsultation,
    emTrendRangeInitialHospital: batchExportCriteria.emTrendRangeInitialHospital,
    emTrendRangeSubsequentHospital: batchExportCriteria.emTrendRangeSubsequentHospital,
    emTrendRangeHospitalDischarge: batchExportCriteria.emTrendRangeHospitalDischarge,
    emTrendRangeEmergencyMedicine: batchExportCriteria.emTrendRangeEmergencyMedicine,
    emTrendRangeNpvEyeExam: batchExportCriteria.emTrendRangeNpvEyeExam,
    emTrendRangeEstablishedEyeExam: batchExportCriteria.emTrendRangeEstablishedEyeExam,
    npvTileBenchmarkType: batchExportCriteria.npvTileBenchmarkType,
    npvTileBenchmarkPercentile: batchExportCriteria.npvTileBenchmarkPercentile,
    wrvuTileBenchmarkType: batchExportCriteria.wrvuTileBenchmarkType,
    wrvuTileBenchmarkPercentile: batchExportCriteria.wrvuTileBenchmarkPercentile,
    emTileBenchmarkType: batchExportCriteria.emTileBenchmarkType,
    emTrendBenchmarkType: batchExportCriteria.emTrendBenchmarkType,
    emPageBenchmarkType: batchExportCriteria.emPageBenchmarkType,
    npvPageBenchmarkType: batchExportCriteria.npvPageBenchmarkType,
    npvPageBenchmarkPercentile: batchExportCriteria.npvPageBenchmarkPercentile
  };
};

export const aBatchFilterCriteria = function (): BatchFilterCriteria {
  return {
    memberKey: 3,
    startYear: -10004,
    endYear: -4,
    startMonth: -5,
    endMonth: -6,
    nodePath: 'hardCoded136',
    memberLocationKey: 7,
    payerCategoryKey: 8,
    payerKey: 163,
    payerType: +3,
    lagKey: DEFAULT_LAG_KEY,
    memberBillingAreaKey: 5,
    invoiceStatus: DEFAULT_INVOICE_STATUS,
    email: 'hardCoded137',
    guid: 'hardCoded138',
    headerId: 9,
    detailId: 2,
    telehealthFlag: 0, memberLocationKeys: `${3}|${8}`
  };
};

export const aBatchExportDetail = function (): BatchExportDetail {
  return {
    id: 3,
    headerId: 4,
    reportName: 'hardCoded138',
    memberKey: 5,
    email: 'hardCoded139',
    headerStatus: 6,
    detailId: 7,
    startDateInMonths: 8,
    endDateInMonths: 9,
    memberLocationKey: 1,
    payerCategoryKey: 2,
    payerKey: 3,
    payerType: 4,
    lagKey: 5,
    memberBillingAreaKey: 6,
    invoiceStatus: 7,
    nodePath: 'hardCoded140',
    detailStatus: 8,
    isOutpatientRequired: true,
    isInPatientRequired: true,
    isEmergencyMedicineRequired: true,
    isOpthalmologyRequired: true,
    path: 'hardCoded141',
    departmentName: 'hardCoded142',
    specialtyName: 'hardCoded1423',
    providerName: 'hardCoded14203',
    payerCategoryName: 'hardCoded1422',
    memberLocationName: 'hardCoded142212',
    memberName: 'hardCoded1422122',
    billingArea: 'hardCoded1422122',
    telehealthFlag: 0,
    isOverviewRequired: true,
    isEmPageRequired: true,
    isEmPageOutpatientRequired: true,
    isEmPageInPatientRequired: true,
    isEmPageEmergencyMedicineRequired: true,
    isEmPageOpthalmologyRequired: true,
    isNewPatientVisitsTrendPageRequired: true,
    memberLocationKeys: 'hardCoded142212219', multiLocationMessage: 'hardCoded1422122199',
    isEmTrendPageRequired: true,
    emTrendRangeNewPatientVisit: true,
    emTrendRangeEstablishedPatientVisit: true,
    emTrendRangeConsultation: true,
    emTrendRangeInitialHospital: true,
    emTrendRangeSubsequentHospital: true,
    emTrendRangeHospitalDischarge: true,
    emTrendRangeEmergencyMedicine: true,
    emTrendRangeNpvEyeExam: true,
    emTrendRangeEstablishedEyeExam: true,
    emPageBenchmarkType: BenchmarkOption.TelehealthCommunity
  };
};

export const aBatchExportDetailWithSpecifics = function (): BatchExportDetail {
  return {
    id: 1,
    headerId: 2,
    reportName: 'loki on ice',
    memberKey: 44,
    email: 'patti@bach.com',
    headerStatus: 2,
    detailId: 4,
    startDateInMonths: 15,
    endDateInMonths: 17,
    memberLocationKey: 50,
    payerCategoryKey: 60,
    payerKey: 43,
    payerType: PayerType.NATIONAL,
    lagKey: 6,
    memberBillingAreaKey: 32,
    invoiceStatus: 0,
    nodePath: '\\4\\7\\8',
    detailStatus: 23,
    isOutpatientRequired: true,
    isInPatientRequired: true,
    isEmergencyMedicineRequired: true,
    isOpthalmologyRequired: true,
    path: 'the happy path',
    departmentName: 'anesthesiology',
    specialtyName: 'jenkinz',
    providerName: 'mr pipeline',
    payerCategoryName: 'puppies',
    memberLocationName: 'okta',
    memberName: 'demo',
    billingArea: 'ping me',
    telehealthFlag: 0,
    isOverviewRequired: true,
    isEmPageRequired: true,
    isEmPageOutpatientRequired: true,
    isEmPageInPatientRequired: true,
    isEmPageEmergencyMedicineRequired: true,
    isEmPageOpthalmologyRequired: true,
    isNewPatientVisitsTrendPageRequired: true,
    memberLocationKeys: '92|514', multiLocationMessage: 'Aberdeenshire',
    isEmTrendPageRequired: true,
    emTrendRangeNewPatientVisit: true,
    emTrendRangeEstablishedPatientVisit: true,
    emTrendRangeConsultation: true,
    emTrendRangeInitialHospital: true,
    emTrendRangeSubsequentHospital: false,
    emTrendRangeHospitalDischarge: false,
    emTrendRangeEmergencyMedicine: false,
    emTrendRangeNpvEyeExam: true,
    emTrendRangeEstablishedEyeExam: true,
  };
};

export const aBatchExportDetailResponse = function (): BatchExportDetailResponse {
  return {
    batchExportDetails: [aBatchExportDetail(), aBatchExportDetail()]
  };
};

export const getBatchFilterFromExportDetail = function (detail: BatchExportDetail, guid: string, isPreviousYear = false) {
  return {
    startYear: isPreviousYear ? getYearFromNumberOfMonths(detail.startDateInMonths) - 1 :
      getYearFromNumberOfMonths(detail.startDateInMonths),
    endYear: isPreviousYear ? getYearFromNumberOfMonths(detail.endDateInMonths) - 1 :
      getYearFromNumberOfMonths(detail.endDateInMonths),
    startMonth: getMonthFromNumberOfMonths(detail.startDateInMonths),
    endMonth: getMonthFromNumberOfMonths(detail.endDateInMonths),
    nodePath: detail.nodePath,
    memberLocationKey: detail.memberLocationKey,
    payerCategoryKey: detail.payerCategoryKey,
    payerKey: detail.payerKey,
    payerType: detail.payerType,
    memberBillingAreaKey: detail.memberBillingAreaKey,
    lagKey: detail.lagKey,
    invoiceStatus: detail.invoiceStatus,
    email: detail.email,
    memberKey: detail.memberKey,
    guid: guid,
    headerId: detail.headerId,
    detailId: detail.detailId,
    telehealthFlag: detail.telehealthFlag,
    memberLocationKeys: detail.memberLocationKeys
  };
};

export const aZipCodeNewPatientVisits = function (): ZipCodeNewPatientVisits[] {
  return [
    {
      lat: 'hardCoded14221233',
      lng: 'hardCoded1422123333',
      zipCode: 'hardCoded142212333333',
      newPatientVisitCount: 1,
      newPatientVisitPercentage: 2,
      totalPatientVisitCount: 3
    },
    {
      lat: 'hardCoded142211111444',
      lng: 'hardCoded142654312',
      zipCode: 'hardCoded14265431244',
      newPatientVisitCount: 4,
      newPatientVisitPercentage: 5,
      totalPatientVisitCount: 6
    }
  ];
};

export function aCptCategories(): CptCategories {
  return {
    newPatientVisit: {
      cpt99201: getCptSummary(0, 10),
      cpt99202: getCptSummary(0, 20),
      cpt99203: getCptSummary(0, 30),
      cpt99204: getCptSummary(0, 40),
      cpt99205: getCptSummary(0, 50)
    },
    establishedPatientVisit: {
      cpt99211: getCptSummary(0, 11),
      cpt99212: getCptSummary(0, 21),
      cpt99213: getCptSummary(0, 31),
      cpt99214: getCptSummary(0, 41),
      cpt99215: getCptSummary(0, 50)
    },
    consultation: {
      cpt99241: getCptSummary(3, 14),
      cpt99242: getCptSummary(3, 24),
      cpt99243: getCptSummary(3, 34),
      cpt99244: getCptSummary(3, 44),
      cpt99245: getCptSummary(3, 54)
    },
    subsequentHospitalCare: {
      cpt99231: getCptSummary(4, 12),
      cpt99232: getCptSummary(4, 22),
      cpt99233: getCptSummary(4, 32)
    },
    initialHospitalCare: {
      cpt99221: getCptSummary(5, 13, 45),
      cpt99222: getCptSummary(15, 23, 25),
      cpt99223: getCptSummary(25, 33, 55)
    },
    hospitalDischargeDay: {
      cpt99238: getCptSummary(30, 45, 67),
      cpt99239: getCptSummary(40, 46, 68)
    },
    emergencyMedicine: {
      cpt99281: getCptSummary(0, 0, 0),
      cpt99282: getCptSummary(0, 0, 0),
      cpt99283: getCptSummary(0, 0, 0),
      cpt99284: getCptSummary(0, 0, 0),
      cpt99285: getCptSummary(0, 0, 0)
    },
    eyeExamNewPatient: {
      cpt92002: getCptSummary(0, 0, 0),
      cpt92004: getCptSummary(0, 0, 0)
    },
    eyeExamEstablishedPatient: {
      cpt92012: getCptSummary(0, 0, 0),
      cpt92014: getCptSummary(0, 0, 0)
    }
  };
}

export function aCfpMultilevelDatum(
  code: number, range: number, family: number, departmentNodeId: number, specialtyNodeId: number, providerNodeId: number, units: number
): CfpByMultilevel {
  return {
    cptCode: 'Code ' + code,
    cptRangeLow: 'R' + range + 'LOW',
    cptRangeHigh: 'R' + range + 'HIGH',
    cptDesc: `A description for code ${code}`,
    cptRangeDesc: `Range ${range} description`,
    cptFamilyDesc: 'Family ' + family,
    providerNodeName: 'Provider ' + providerNodeId,
    providerNodePath: `\\0\\${departmentNodeId}\\${specialtyNodeId}\\${providerNodeId}`,
    specialtyNodeName: 'Specialty ' + specialtyNodeId,
    specialtyNodePath: `\\0\\${departmentNodeId}\\${specialtyNodeId}`,
    departmentNodeName: 'Department ' + departmentNodeId,
    departmentNodePath: `\\0\\${departmentNodeId}`,
    wRVUs: units,
    charges: units,
    cfteAdjustedWRVUs: units,
    cfteAdjustedFrequency: units,
    frequency: units,
    totalWorkRVUs: units,
    workRvuBenchmark: units,
    totalWorkRvuBenchmark: units,
    frequencyBenchmark: units,
    communityWorkRvuBenchmark: units,
    communityTotalWorkRvuBenchmark: units,
    communityFrequencyBenchmark: units
  };
}

export function aCfpByMultilevelDefault(): CfpByMultilevel {
  return {
    id: 0,
    cptCode: '',
    cptRangeLow: '',
    cptRangeHigh: '',
    cptDesc: '',
    cptRangeDesc: '',
    cptFamilyDesc: '',
    providerNodeName: '',
    providerNodePath: '',
    providerNodeId: 0,
    specialtyNodeName: '',
    specialtyNodePath: '',
    specialtyNodeId: 0,
    departmentNodeName: '',
    departmentNodePath: '',
    departmentNodeId: 0,
    charges: 0,
    cfteAdjustedWRVUs: 0,
    cfteAdjustedFrequency: 0,
    frequency: 0,
    totalWorkRVUs: 0,
    workRvuBenchmark: 0,
    totalWorkRvuBenchmark: 0,
    frequencyBenchmark: 0,
    communityWorkRvuBenchmark: 0,
    communityTotalWorkRvuBenchmark: 0,
    communityFrequencyBenchmark: 0,
    wRVUs: 0,
    cFTEAdjustedWrvusVariance: null,
    communityCfteAdjustedWrvusVariance: null
  };
}

export const anArrayOfCfpMultilevels: CfpByMultilevel[] = [aCfpMultilevelDatum(1, 2, 3, 4, 5, 6, 7),
  aCfpMultilevelDatum(11, 12, 13, 14, 15, 16, 17),
  aCfpMultilevelDatum(21, 22, 23, 24, 25, 26, 27)];

export const mockCptGroups: CptGroup[] = [{
  id: 1,
  emailId: 'email@example.com',
  memberKey: 24,
  name: 'evaluation and management is a long enough name to have a tooltip',
  isDefault: false, breadcrumb: '7 codes',
  entries: [{
    cptDesc: 'Family 3',
    cptViewType: CptViewType.CptFamily
  }]
}, {
  id: 2,
  emailId: 'email@example.com',
  memberKey: 24,
  name: 'subsequent',
  isDefault: true, breadcrumb: '7 codes',
  entries: [{
    cptDesc: anArrayOfCfpMultilevels[0].cptCode,
    cptViewType: CptViewType.CptCode
  }]
}, {
  id: 3,
  emailId: 'email@example.com',
  memberKey: 24,
  name: 'pathology',
  isDefault: false, breadcrumb: '8 codes',
  entries: [{
    cptDesc: anArrayOfCfpMultilevels[0].cptCode,
    cptViewType: CptViewType.CptCode
  }]
}];
