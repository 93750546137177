import {BaseColumn, FilterCriteria, IAppState} from '../store/IAppState';
import * as moment from 'moment';
import {
    ApplicationConfigurationSetting,
    DateRange,
    EvaluationManagementSummary,
    FeatureToggleSetting,
    LagPeriod,
    MemberBillingArea,
    MemberData,
    NewPatientVisitSummary,
    OntologyNode,
    SelectionCounts,
    SortingCriterion,
    UserProfile,
    UserSecurity
} from './models';
import * as _ from 'lodash';
import {ProductivitySnapshot, ProductivitySummary} from '../productivity-summary/services/ProviderProductivity';
import {checkForNulls, hasValue, isValid, undefinedIfZero} from './null-helpers';
import {
    BackEndTelehealthFlag,
    BenchmarkOption,
    DateRangeOption,
    DefaultPage,
    FrontEndTelehealthFlag,
    MultilevelTab,
    MultilevelTabCollections,
    NodeExpansionState,
    PayerType
} from './enums';
import {roundToWithPercentage} from '../productivity-summary/number-formatter';
import {BenchmarkPercentile, extendedBenchmarkOptions} from './benchmark-types';
import {DataTableColumns} from './data-table-columns';
import {
    emEmergencyMedicineDepartmentRoute,
    emEmergencyMedicineProviderRoute,
    emEmergencyMedicineSpecialtyRoute,
    emInpatientDepartmentRoute,
    emInpatientProviderRoute,
    emInpatientSpecialtyRoute,
    emOphthalmologyDepartmentRoute,
    emOphthalmologyProviderRoute,
    emOphthalmologySpecialtyRoute,
    emOutpatientDepartmentRoute,
    emOutpatientProviderRoute,
    emOutpatientSpecialtyRoute,
    npvDepartmentPageRoute,
    wRVUDepartmentPageRoute,
    wRVUProviderPageRoute,
    wRVUSpecialtyPageRoute,
    wRVUSpecialtyPerformancePageRoute,
    wRVUTrendPageRoute
} from './routes';
import {getOntologyData} from './localStoragehelper';
import {memberBillingAreaLoadedTo, memberDataFormatLoadedTo, memberSelectedTo} from '../store/actions';
import {MemberService} from '../services/member.service';
import {NgRedux} from '@angular-redux/store';
import {MemberBillingAreaService} from '../services/member-billing-area.service';
import {MergedNewPatientVisitSnapshotEntry, NewPatientVisitTrendEntry} from '../new-patient-visits/components/npv-models';
import {MetricType} from './metric-types';

export enum WrvuViewType {
  CfteAdjusted = 1,
  Actual = 2
}

export enum CfpVariableViewType {
  CfteAdjustedwRVU = 1,
  CfteAdjustedCount,
  ActualwRVU = 3,
  ActualCount = 4
}

export enum SummaryType {
  SELECTED_PERIOD = 0,
  PREVIOUS_SELECTED_PERIOD = 1,
  YEAR_TO_DATE = 2,
  PREVIOUS_YEAR_TO_DATE = 3,
  RECENT_MONTH = 4,
  PREVIOUS_YEAR_RECENT_MONTH = 5
}


export enum Page {
  Overview = 'Overview',
  WrvuSnapshot = 'Wrvu Snapshot',
  WrvuTrend = 'Wrvu Trend',
  WrvuDepartment = 'Wrvu Department',
  WrvuSpecialty = 'Wrvu Specialty',
  WrvuProvider = 'Wrvu Provider',
  NPVSnapshot = 'Npv Snapshot',
  NPVDepartment = 'Npv Department',
  NPVSpecialty = 'Npv Specialty',
  NPVProvider = 'Npv Provider',
  NPVTrend = 'Npv Trend',
  NPVLocationDepartment = 'Npv Location Department',
  NPVLocationSpecialty = 'Npv Location Specialty',
  NPVLocationProvider = 'Npv Location Provider',
  CollectionsSnapshot = 'Collections Snapshot',
  CollectionsByPayer = 'Collections By Payer',
  CollectionsPayerDepartment = 'Collections By Payer By Department',
  CollectionsPayerSpecialty = 'Collections By Payer By Specialty',
  CollectionsPayerProvider = 'Collections By Payer By Provider',
  CollectionsSummary = 'Collections Summary',
  CollectionsDepartment = 'Collections By Department',
  CollectionsSpecialty = 'Collections By Specialty',
  CollectionsProvider = 'Collections By Provider',
  EandM = 'Evaluation and Management',
  CFP = 'Clinical Fingerprint',
  Denials = 'Denials',
  DenialsDepartment = 'Denials By Department',
  DenialsSpecialty = 'Denials By Specialty',
  DenialsProvider = 'Denials By Provider',
  DenialsByPayer = 'Denials By Payer',
  ProcedureSummary = 'Procedure Summary'
}

export const wrvuPagesLabel = 'wRVUs';
export const npvPagesLabel = 'New Patient Visits';
export const collectionsPagesLabel = 'Collections';
export const denialsPagesLabel = 'Denials';

export enum LevelType {
  none = 0,
  department = 1,
  specialty = 2,
  provider = 3,
  singleProvider = 4
}

export function getByLevelTitleSubString(level: LevelType): string {
  switch (level) {
    case LevelType.department:
      return 'By Department';
    case LevelType.specialty:
      return 'By Specialty';
    case LevelType.provider:
      return 'By Provider';
  }
  return '';
}

export enum NodePathLevelType {
  department = 2,
  specialty = 3,
}

export enum ExportType {
  csv = 0,
  excel = 1,
  pdf = 2
}

export enum SettingType {
  dateRange = 0,
  myView = 1,
  benchmarkPercentiles = 2,
  cFTEadjustedWrvus = 3
}

export function roundToHundred(value: number): number {
  return Math.round(value / 100) * 100;
}

export function assertAllCasesCovered(x: any): string {
  throw new Error(`Switch statement is not exhaustive ${x} typeof ${typeof x}`);
}

export function shallowCopyOf<T>(object: T): T {
  return Object.assign({}, object);
}

export function deepCopyOf<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export function onlyTwoDigitsOfYear(year: number): string {
  return '\'' + ('' + year).slice(-2);
}

export function dateInMonths(year: number, month: number): number {
  return year * 12 + month;
}

export function getAbbreviatedFiscalDateRange(range: DateRange): string {
  let {startMonth, startYear, endMonth, endYear} = range;
  if (startMonth === 0) {
    startMonth = 12;
    startYear -= 1;
  }
  if (endMonth === 0) {
    endMonth = 12;
    endYear -= 1;
  }
  return `(${months[startMonth - 1]}. ${onlyTwoDigitsOfYear(startYear)} - ${months[endMonth - 1]}. ${onlyTwoDigitsOfYear(endYear)})`;
}

// startQuarter: 1 based (1/2/3/4)
export function determineFiscalYear(startDateInMonths: number, startQuarter: number): number {
  const fiscalStartInMonths = startDateInMonths - (startQuarter > 0 ? (startQuarter - 1) : 0) * 3;
  return getYearFromNumberOfMonths(fiscalStartInMonths);
}

export function getFiscalYearForFiscalYearToDateOption(fiscalStartMonth: number): number {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  return fiscalStartMonth >= currentMonth ? currentYear - 1 : currentYear;
}

export function getDateRangeBySelectedOption(dateRangeOption: DateRangeOption | undefined, recentMonth: number | undefined,
                                             fiscalStartMonth?: number,
                                             fiscalYear?: number,
                                             numberOfMonths?: number
): DateRange {
  fiscalStartMonth = fiscalStartMonth ? fiscalStartMonth : 1; // default
  fiscalYear = fiscalYear || getFiscalYearForFiscalYearToDateOption(fiscalStartMonth);
  const past12months: DateRange = {
    startMonth: getDefaultStartDate().getMonth() + 1,
    startYear: getDefaultStartDate().getFullYear(),
    endMonth: getDefaultEndDate().getMonth() + 1,
    endYear: getDefaultEndDate().getFullYear(),
    selectedDateRangeOption: DateRangeOption.Past12Months
  };

  let startMonth, startYear, endMonth, endYear;

  switch (dateRangeOption) {
    case DateRangeOption.Past12Months:
      return past12months;
    case DateRangeOption.YearToDate:
      startMonth = 1;
      startYear = new Date().getFullYear();
      endMonth = new Date().getMonth() === 0 ? 1 : new Date().getMonth();
      endYear = new Date().getFullYear();
      break;
    case DateRangeOption.RecentMonth:
      if (recentMonth === undefined || recentMonth <= 0) {
        return past12months;
      }
      startMonth = getMonthFromNumberOfMonths(recentMonth);
      startYear = getYearFromNumberOfMonths(recentMonth);
      endMonth = getMonthFromNumberOfMonths(recentMonth);
      endYear = getYearFromNumberOfMonths(recentMonth);
      break;
    case DateRangeOption.Past12MonthsOfData:
      if (recentMonth === undefined || recentMonth <= 0) {
        return past12months;
      }
      startMonth = getMonthFromNumberOfMonths(recentMonth - 11);
      startYear = getYearFromNumberOfMonths(recentMonth - 11);
      endMonth = getMonthFromNumberOfMonths(recentMonth);
      endYear = getYearFromNumberOfMonths(recentMonth);
      break;
    case DateRangeOption.FiscalYearToDate:
      startMonth = fiscalStartMonth;
      startYear = fiscalYear;
      endMonth = new Date().getMonth() === (fiscalStartMonth - 1) ? fiscalStartMonth : new Date().getMonth();
      endYear = new Date().getFullYear();
      break;
    case DateRangeOption.FiscalYearQ1:
    case DateRangeOption.FiscalYearQ2:
    case DateRangeOption.FiscalYearQ3:
    case DateRangeOption.FiscalYearQ4:
      const startInMonths = fiscalYear * 12 + fiscalStartMonth + ((dateRangeOption - DateRangeOption.FiscalYearQ1) * 3);
      startMonth = getMonthFromNumberOfMonths(startInMonths);
      endMonth = getMonthFromNumberOfMonths(startInMonths + (numberOfMonths ? numberOfMonths : 3) - 1);
      startYear = getYearFromNumberOfMonths(startInMonths);
      endYear = getYearFromNumberOfMonths(startInMonths + (numberOfMonths ? numberOfMonths : 3) - 1);
      break;
    default:
      startMonth = getDefaultStartDate().getMonth() + 1;
      startYear = getDefaultStartDate().getFullYear();
      endMonth = getDefaultEndDate().getMonth() + 1;
      endYear = getDefaultEndDate().getFullYear();
      break;
  }

  return {
    startMonth,
    startYear,
    endMonth,
    endYear,
    selectedDateRangeOption: dateRangeOption
  };
}

export function getNumberOfMonthsInDateRange(dateRange: DateRange): number {
  return dateRange ?
    (dateRange.endYear * 12 + dateRange.endMonth) - (dateRange.startYear * 12 + dateRange.startMonth) + 1 : 0;
}

export function getMonthFromNumberOfMonths(numberOfMonths: number) {
  let month = numberOfMonths % 12;
  if (month === 0) {
    month = 12;
  }
  return month;
}

export function getYearFromNumberOfMonths(numberOfMonths: number): number {
  if (numberOfMonths % 12 === 0) {
    return Math.floor(numberOfMonths / 12) - 1;
  }
  return Math.floor(numberOfMonths / 12);
}

export const curDate: Date = new Date();

export function getSelectedDateRange(dateRange: DateRange, abbreviated = false): string {
  const monthArray: string[] = abbreviated ? months : monthNames;
  const startYear = !isValid(dateRange, 'startYear') ? '' : dateRange.startYear;
  const startMonth = !isValid(dateRange, 'startMonth') ? '' : monthArray[dateRange.startMonth - 1];
  const endYear = !isValid(dateRange, 'endYear') ? '' : dateRange.endYear;
  const endMonth = !isValid(dateRange, 'endMonth') ? '' : monthArray[dateRange.endMonth - 1];
  return `${startMonth} ${startYear} - ` + `${endMonth} ${endYear}`;
}

export function getRecentMonth(dateRange: DateRange): string {
  const endYear = !isValid(dateRange, 'endYear') ? '' : dateRange.endYear;
  const endMonth = !isValid(dateRange, 'endMonth') ? '' : monthNames[dateRange.endMonth - 1];
  return `${endMonth} ${endYear}`;
}

export function getYtdDateRange(newPatientVisitSummary: NewPatientVisitSummary): string {
  return newPatientVisitSummary.endMonth ? `${monthNames[newPatientVisitSummary.startMonth - 1]} ${newPatientVisitSummary.startYear} - ` +
    `${monthNames[newPatientVisitSummary.endMonth - 1]} ${newPatientVisitSummary.endYear}` :
    `${monthNames[0]} ${curDate.getFullYear()} - ${monthNames[curDate.getMonth().valueOf() - 1]} ${curDate.getFullYear()}`;
}

export function getPreviousYtdDateRange(newPatientVisitSummary: NewPatientVisitSummary): string {
  return newPatientVisitSummary.endMonth ? `${monthNames[newPatientVisitSummary.startMonth - 1]} ${newPatientVisitSummary.startYear} - ` +
    `${monthNames[newPatientVisitSummary.endMonth - 1]} ${newPatientVisitSummary.endYear}` :
    `${monthNames[0]} ${curDate.getFullYear() - 1} - ${monthNames[curDate.getMonth().valueOf() - 1]} ${curDate.getFullYear() - 1}`;
}

export function getRecentMonthDate(newPatientVisitSummary: NewPatientVisitSummary): string {
  return newPatientVisitSummary.endMonth ?
    `${monthNames[newPatientVisitSummary.endMonth - 1]} ${newPatientVisitSummary.endYear}`
    : `-`;
}

export function getArbitraryDateRangeFromDatesInMonths(startDateInMonths: number, endDateInMonths: number): DateRange {
  return {
    startMonth: getMonthFromNumberOfMonths(startDateInMonths),
    startYear: getYearFromNumberOfMonths(startDateInMonths),
    endYear: getYearFromNumberOfMonths(endDateInMonths),
    endMonth: getMonthFromNumberOfMonths(endDateInMonths)
  };
}

export function getPreviousYearDateRange(dateRange: DateRange, abbreviated?: boolean): string {
  const monthArray: string[] = abbreviated ? months : monthNames;
  return dateRange ? `${monthArray[dateRange.startMonth - 1]} ${dateRange.startYear - 1} - ` +
    `${monthArray[dateRange.endMonth - 1]} ${dateRange.endYear - 1}` : '';
}

export function toTitleCase(nodeName: string): string {
  return nodeName.replace(
    /([^\W_]+[^\s-]*) */g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function getLevelFromNodePath(nodePath: string, multipleProviders?: boolean,
                                     multipleSpecialties?: boolean, multipleDepartments?: boolean,
                                     multiLevelBreakdown?: number
): string {

  if (multiLevelBreakdown !== undefined && multiLevelBreakdown >= 0) {
    switch (multiLevelBreakdown) {
      case MultilevelTab.BY_DEPARTMENT:
        return 'by Department';
      case MultilevelTab.BY_SPECIALTY:
        return 'by Specialty';
      case MultilevelTab.BY_PROVIDER:
        return 'by Provider';
    }
  }

  if (!nodePath) {
    return '';
  }
  if (multipleProviders) {
    return 'by Provider';
  }
  if (multipleSpecialties) {
    return 'by Specialty';
  }
  if (multipleDepartments) {
    return 'by Department';
  }

  switch (nodePath.split('\\').length - 1) {
    case 1:
      return 'by Department';
    case 2:
      return 'by Specialty';
    case 3:
      return 'by Provider';
    default:
      return '';
  }
}


export function getLevelTypeNodePath(nodePath: string): LevelType | NodePathLevelType {
  if (!nodePath) {
    return LevelType.none;
  }
  switch (nodePath.split('\\').length - 1) {
    case 1:
      return LevelType.department;
    case 2:
      return LevelType.specialty;
    case 3:
      return LevelType.provider;
    case 4:
      return LevelType.singleProvider;
    default:
      return LevelType.none;
  }
}

export function getLevel(isCustom: boolean, nodePath: string, multipleProviders: boolean,
                         multipleSpecialties: boolean, multipleDepartments: boolean, multiLevelBreakdown?: number): string {
  return isCustom ? '' : getLevelFromNodePath(nodePath, multipleProviders, multipleSpecialties,
    multipleDepartments, multiLevelBreakdown);
}

export function getDefaultStartDate(): Date {
  return moment().subtract(12, 'months').toDate();
}

export function getDefaultEndDate(): Date {
  return moment().subtract(1, 'months').toDate();
}

// defaults to showing feature if not defined in table
export function canHide(featureName: String,
                        settings: FeatureToggleSetting[],
                        userProfile: UserProfile,
                        userSecurityData?: UserSecurity[]) {
  const internalUser = isInternalUser(userProfile, userSecurityData);
  if (settings && settings.length > 0) {
    const setting: FeatureToggleSetting | undefined =
      settings.find((s: FeatureToggleSetting) => s.featureName.toLowerCase() === featureName.toLowerCase());
    if (setting && !setting.enabled) {
      return !(internalUser && setting.enabledInternal);
    }
  }
  return false;
}

// defaults to hiding feature if not defined in table
export function isFeatureEnabled(featureName: String,
                                 settings: FeatureToggleSetting[],
                                 userProfile: UserProfile,
                                 userSecurityData?: UserSecurity[]) {

  const internalUser = isInternalUser(userProfile, userSecurityData);
  if (settings && settings.length > 0) {
    const setting: FeatureToggleSetting | undefined =
      settings.find((s: FeatureToggleSetting) => s.featureName?.toLowerCase() === featureName.toLowerCase());

    if ((setting?.enabled) || (internalUser && setting?.enabledInternal)) {
      return true;
    }
  }
  return false;
}

function isInternalUser(userProfile: UserProfile, userSecurityData?: UserSecurity[]) {
  return userProfile.isEmployee ||
    (userSecurityData && userSecurityData.length > 0 && userSecurityData[0].email &&
      userSecurityData[0].email.trim().toLowerCase().endsWith('vizientinc.com'));
}

export function getOppositeNodeExpansionState(nodeExpansionState: NodeExpansionState): NodeExpansionState {
  switch (nodeExpansionState) {
    case NodeExpansionState.EXPANDED:
      return NodeExpansionState.COLLAPSED;
    case NodeExpansionState.COLLAPSED:
      return NodeExpansionState.EXPANDED;
    case NodeExpansionState.LEAF:
      return NodeExpansionState.LEAF_ACTIVE;
    case NodeExpansionState.LEAF_ACTIVE:
      return NodeExpansionState.LEAF;
  }
}

export function nodeExpansionIsActive(nodeExpansionState: NodeExpansionState): boolean {
  switch (nodeExpansionState) {
    case NodeExpansionState.LEAF_ACTIVE:
    case NodeExpansionState.EXPANDED:
      return true;
    case NodeExpansionState.LEAF:
    case NodeExpansionState.COLLAPSED:
      return false;
  }
}

export function isPayerTypeAGranularType(payerType: PayerType): boolean {
  switch (payerType) {
    case PayerType.ALL:
    case PayerType.CATEGORY:
      return false;
    case PayerType.NATIONAL:
    case PayerType.MEMBER:
      return true;
  }
}

export function isSortingCriterionValid(sortingCriterion: SortingCriterion | undefined): boolean {
  return !!(sortingCriterion && sortingCriterion.sortingOrder && sortingCriterion.columnDef);
}

export function getAppConfigValue(appConfigName: String, appConfigs: ApplicationConfigurationSetting[]): string {
  if (appConfigs && appConfigs.length > 0) {
    const appConfigSetting: ApplicationConfigurationSetting | undefined =
      appConfigs.find((s: ApplicationConfigurationSetting) => s.appConfigName.toLowerCase() === appConfigName.toLowerCase());
    if (appConfigSetting) {
      return appConfigSetting.appConfigDesc;
    }
  }
  return '';
}

export enum FilterType {
  PreviousYear,
  Specialty
}

export function combineFilter(filter: FilterCriteria, summaryType: FilterType): FilterCriteria {
  const newFilter = _.cloneDeep(filter);
  switch (summaryType) {
    case FilterType.PreviousYear:
      if (newFilter.dateRange) {
        newFilter.dateRange.startYear = newFilter.dateRange.startYear - 1;
        newFilter.dateRange.endYear = newFilter.dateRange.endYear - 1;
      }
      break;
    case FilterType.Specialty:
      const lastIndex = filter.nodePath.lastIndexOf('\\');
      const specialtyPath = filter.nodePath.substring(0, lastIndex);
      newFilter.nodePath = specialtyPath;
      break;
  }
  return newFilter;
}

export function getGroupName(summaryData: ProductivitySummary | NewPatientVisitSummary |
  EvaluationManagementSummary, summaryType: string): string {
  let groupName = '';
  switch (summaryType) {
    case 'selectedDateRange':
      groupName += 'Selected Date Range (' + getDateRange(summaryData) + ')';
      break;
    case 'previousYearSelectedDateRange':
      groupName += 'Previous Year Selected Date Range (' + getDateRange(summaryData) + ')';
      break;
    case 'yearToDate':
      groupName += 'YTD (' + getDateRange(summaryData) + ')';
      break;
    case 'previousYearToDate':
      groupName += 'Previous Year YTD (' + getDateRange(summaryData) + ')';
      break;
    case 'recentMonth':
      groupName += 'Recent Month (' + getSummaryRecentMonth(summaryData) + ')';
      break;
    case 'previousYearRecentMonth':
      groupName += 'Previous Year Recent Month (' + getSummaryRecentMonth(summaryData) + ')';
      break;
  }
  return groupName;
}

export function getDateRange(summary: ProductivitySummary | NewPatientVisitSummary | EvaluationManagementSummary): string {
  if (!summary) {
    return '';
  }
  return getSelectedDateRange({
    endMonth: summary.endMonth,
    endYear: summary.endYear,
    startMonth: summary.startMonth,
    startYear: summary.startYear
  });
}

export function parseMonthWithLeadingZero(month: number): string {
  return (month < 10 ? '0' + month : month.toString());
}

export function areDateRangesEqual(first: DateRange, other: DateRange) {
  return first.startYear === other.startYear &&
    first.startMonth === other.startMonth &&
    first.endMonth === other.endMonth &&
    first.endYear === other.endYear;
}

export function getSummaryRecentMonth(summary: ProductivitySummary | NewPatientVisitSummary | EvaluationManagementSummary): string {
  if (!summary) {
    return '';
  }
  return getRecentMonth({
    endMonth: summary.endMonth,
    endYear: summary.endYear,
    startMonth: summary.startMonth,
    startYear: summary.startYear
  });
}

export const lagPeriods: LagPeriod[] = [
  {key: 1, name: '<= 30 days'},
  {key: 2, name: '<= 60 days'},
  {key: 3, name: '<= 90 days'},
  {key: 4, name: '<= 120 days'},
  {key: 5, name: '<= 150 days'},
  {key: 6, name: '<= 180 days'},
  {key: 7, name: 'All Activity'}
];

export function getPrettyDateFromLocalDateTime(localDateTime: string): string {
  if (!localDateTime) {
    return '';
  }
  const tokens = localDateTime.split('-');
  const year = tokens[0];
  const month = months[parseInt(tokens[1], 10) - 1];
  const day = tokens[2]?.split('T')[0];

  return `${month} ${day}, ${year}`;
}

export function compareDateValues(valueA: string, valueB: string): number {
  const aDate = new Date(valueA);
  const bDate = new Date(valueB);
  return aDate > bDate ? -1 : 1;
}

export const aProductivitySnapshotDefault = function (): ProductivitySnapshot {
  return {
    nodeName: '',
    nodeId: 0,
    nodePath: '',
    benchmark25thPercentile: 0,
    benchmark50thPercentile: 0,
    benchmark65thPercentile: 0,
    benchmark75thPercentile: 0,
    benchmark90thPercentile: 0,
    benchmarkMean: 0,
    benchmarkPercentile: 0,
    wRVUs: 0,
    cfteAdjustedWRVUs: 0,
    cfte: 0,
    charges: 0,
    difference: 0,
    variance25thPercentile: 0,
    variance50thPercentile: 0,
    variance65thPercentile: 0,
    variance75thPercentile: 0,
    variance90thPercentile: 0,
    varianceMean: 0,
    imputedReportedcFTE25thPercentile: 0,
    imputedReportedcFTE50thPercentile: 0,
    imputedReportedcFTE65thPercentile: 0,
    imputedReportedcFTE75thPercentile: 0,
    imputedReportedcFTEMean: 0,
    imputedReportedcFTE90thPercentile: 0,
    previousBenchmark25thPercentile: undefined,
    previousBenchmark50thPercentile: undefined,
    previousBenchmark65thPercentile: undefined,
    previousBenchmark75thPercentile: undefined,
    previousBenchmark90thPercentile: undefined,
    previousBenchmarkMean: undefined,
    previousWrvus: undefined,
    previousCfteAdjustedWRVUs: undefined,
    previousCfte: undefined,
    previousCharges: undefined,
    previousImputedReportedcFTE25thPercentile: undefined,
    previousImputedReportedcFTE50thPercentile: undefined,
    previousImputedReportedcFTE65thPercentile: undefined,
    previousImputedReportedcFTE75thPercentile: undefined,
    previousImputedReportedcFTEMean: undefined,
    previousImputedReportedcFTE90thPercentile: undefined,
    communityVarianceMean: 0,
    communityVariance25thPercentile: 0,
    communityVariance50thPercentile: 0,
    communityVariance65thPercentile: 0,
    communityVariance75thPercentile: 0,
    communityVariance90thPercentile: 0,
    communityImputedReportedcFTE25thPercentile: 0,
    communityImputedReportedcFTE50thPercentile: 0,
    communityImputedReportedcFTE65thPercentile: 0,
    communityImputedReportedcFTE75thPercentile: 0,
    communityImputedReportedcFTEMean: 0,
    communityImputedReportedcFTE90thPercentile: 0,
    communityBenchmark25thPercentile: 0,
    communityBenchmark50thPercentile: 0,
    communityBenchmark65thPercentile: 0,
    communityBenchmark75thPercentile: 0,
    communityBenchmark90thPercentile: 0,
    communityBenchmarkMean: 0
  };
};

function getNpvDataByBenchmarkOrVariancePercentile(
  newPatientVisit: NewPatientVisitSummary,
  benchmark: BenchmarkPercentile,
  benchmarkOption: BenchmarkOption): number | undefined {
  const option = extendedBenchmarkOptions.find(o => o.value === benchmarkOption);
  const benchmarkPrefix = option?.benchmarkPrefix;
  const variancePrefix = option?.variancePrefix;
  switch (benchmark) {
    case BenchmarkPercentile.Percentile25th:
      return undefinedIfZero(newPatientVisit[`${benchmarkPrefix}25thPercentile`]) ?
        checkForNulls(newPatientVisit[`${variancePrefix}25thPercentile`]) : undefined;
    case BenchmarkPercentile.Percentile50th:
      return undefinedIfZero(newPatientVisit[`${benchmarkPrefix}50thPercentile`]) ?
        checkForNulls(newPatientVisit[`${variancePrefix}50thPercentile`]) : undefined;
    case BenchmarkPercentile.Percentile65th:
      return undefinedIfZero(newPatientVisit[`${benchmarkPrefix}65thPercentile`]) ?
        checkForNulls(newPatientVisit[`${variancePrefix}65thPercentile`]) : undefined;
    case BenchmarkPercentile.Percentile75th:
      return undefinedIfZero(newPatientVisit[`${benchmarkPrefix}75thPercentile`]) ?
        checkForNulls(newPatientVisit[`${variancePrefix}75thPercentile`]) : undefined;
    case BenchmarkPercentile.Percentile90th:
      return undefinedIfZero(newPatientVisit[`${benchmarkPrefix}90thPercentile`]) ?
        checkForNulls(newPatientVisit[`${variancePrefix}90thPercentile`]) : undefined;
    case BenchmarkPercentile.Mean:
      return undefinedIfZero(newPatientVisit[`${benchmarkPrefix}Mean`]) ?
        checkForNulls(newPatientVisit[`${variancePrefix}Mean`]) : undefined;
    default:
      return 0;
  }
}

export function getDifferencePercentage(npv: NewPatientVisitTrendEntry | MergedNewPatientVisitSnapshotEntry): string {
  return roundToWithPercentage(getDifferenceFrom(npv), 1);
}

export function getDifferenceFrom(npv: NewPatientVisitTrendEntry | MergedNewPatientVisitSnapshotEntry): number {
  // @ts-ignore
  return hasValue(npv.difference) ? npv.difference :
    (npv.newPatientVisitsPercentage || 0) - (npv.previous.newPatientVisitsPercentage || 0);
}

export function getPageFromUrl(url: string): Page {
  if (url.includes('wRVUs/snapshot')) {
    return Page.WrvuSnapshot;
  } else if (url.includes('wRVUs/trend')) {
    return Page.WrvuTrend;
  } else if (url.includes('wRVUs/provider')) {
    return Page.WrvuProvider;
  } else if (url.includes('wRVUs/specialty')) {
    return Page.WrvuSpecialty;
  } else if (url.includes('wRVUs/department')) {
    return Page.WrvuDepartment;
  } else if (url.includes('wRVUs')) {
    return Page.WrvuDepartment;
  } else if (url.includes('npv/snapshot')) {
    return Page.NPVSnapshot;
  } else if (url.includes('npv/department')) {
    return Page.NPVDepartment;
  } else if (url.includes('npv/specialty')) {
    return Page.NPVSpecialty;
  } else if (url.includes('npv/provider')) {
    return Page.NPVProvider;
  } else if (url.includes('npv/trend')) {
    return Page.NPVTrend;
  } else if (url.includes('npv')) {
    return Page.NPVDepartment;
  } else if (url.includes('em')) {
    return Page.EandM;
  } else if (url.includes('cfp')) {
    return Page.CFP;
  } else if (url.includes('proceduresummary')) {
    return Page.ProcedureSummary;
  } else if (url.includes('collections/payer')) {
    return Page.CollectionsByPayer;
  } else if (url.includes('collections/summary')) {
    return Page.CollectionsSummary;
  } else if (url.includes('collections/provider')) {
    return Page.CollectionsProvider;
  } else if (url.includes('collections/specialty')) {
    return Page.CollectionsSpecialty;
  } else if (url.includes('collections/department')) {
    return Page.CollectionsDepartment;
  } else if (url.includes('collections')) {
    return Page.CollectionsSnapshot;
  } else if (url.includes('denials/payer')) {
    return Page.DenialsByPayer;
  } else if (url.includes('denials/provider')) {
    return Page.DenialsProvider;
  } else if (url.includes('denials/specialty')) {
    return Page.DenialsSpecialty;
  } else if (url.includes('denials/department')) {
    return Page.DenialsDepartment;
  } else if (url.includes('denials')) {
    return Page.DenialsDepartment;
  } else {
    return Page.Overview;
  }
}


export function getURL(
  page: any,
  isProviderSelected: boolean
): string {
  switch (page) {
    case DefaultPage.Overview:
      return '/productivity/overview';
    case DefaultPage.Wrvus:
      if (isProviderSelected) {
        return wRVUTrendPageRoute;
      }
      return wRVUDepartmentPageRoute;
    case DefaultPage.NPV:
      if (isProviderSelected) {
        return '/productivity/npv/trend';
      }
      return npvDepartmentPageRoute;
    case DefaultPage.EandM:
      return '/productivity/em';
    case DefaultPage.CFP:
      return '/productivity/cfp/snapshot';
    case DefaultPage.Collections:
      return '/productivity/collections';
    case DefaultPage.Denials:
      return '/productivity/denials';
  }
  return '/productivity/overview';
}

export function getLevelNameForZeroSuppression(level: string, countOfSuppressedEntries: number) {
  const pluralSuppressed = countOfSuppressedEntries !== 1;
  switch (level) {
    case 'by Department':
      return pluralSuppressed ? 'Departments' : 'Department';
    case 'by Specialty':
      return pluralSuppressed ? 'Specialties' : 'Specialty';
    case 'by Provider':
      return pluralSuppressed ? 'Providers' : 'Provider';
    default:
      return '';
  }
}

export function getSingularOntologyLevelName(tabType: MultilevelTab): string {
  switch (tabType) {
    case MultilevelTab.BY_DEPARTMENT:
    case MultilevelTab.LOCATION_DEPARTMENT:
      return 'Department';
    case MultilevelTab.BY_SPECIALTY:
    case MultilevelTab.LOCATION_SPECIALTY:
      return 'Specialty';
    case MultilevelTab.BY_PROVIDER:
    case MultilevelTab.LOCATION_PROVIDER:
      return 'Provider';
    default:
      return '';
  }
}

export const getFirstNodePathFrom = function (nodePath: string) {
  if (!nodePath) {
    return '';
  }
  const index = nodePath.indexOf('|');
  return index === -1 ? nodePath : nodePath.substring(0, index);
};

export function getSelectionCounts(nodePath: string): SelectionCounts {
  const nodePaths: string[] = nodePath.split('|');
  const distinctProviders: Set<string> = new Set<string>();
  const distinctSpecialties: Set<string> = new Set<string>();
  const distinctDepartments: Set<string> = new Set<string>();
  nodePaths.forEach(path => {
    const pathArray: string[] = path.split('\\');
    if (pathArray.length >= 5) {
      distinctProviders.add(pathArray[4]);
    }
    if (pathArray.length >= 4) {
      distinctSpecialties.add(pathArray[3]);
    }
    if (pathArray.length >= 3) {
      distinctDepartments.add(pathArray[2]);
    }
  });
  return {
    providers: distinctProviders.size,
    specialties: distinctSpecialties.size,
    departments: distinctDepartments.size
  };
}

export function isInvalidCommunityBenchmarkValue(value: number): boolean {
  return (value === 0 || value === null);
}

export function removeWhiteSpace(phrase: string) {
  return phrase.replace(/[^0-9a-zA-Z ]/g, '');
}

export function getBaseColumnFromDataTableColumn(column: DataTableColumns): BaseColumn {
  return {
    columnDef: column.columnDef,
    header: column.header,
    columnType: column.columnType
  };
}

export function getUpdatedNodePath(selectedPath: string, currentPath: string): string {
  const isSingleNodePath = !currentPath.includes('|');
  return isSingleNodePath ? lowerLevelNodePath(selectedPath, currentPath) : includedMultiLevelNodePaths(selectedPath, currentPath);
}

function includedMultiLevelNodePaths(selectedNodePath: string, currentNodePath: string) {
  const nodePaths: string[] = [];

  currentNodePath.split('|').forEach(nodePath => {
    if (nodePath.includes(selectedNodePath)) {
      nodePaths.push(nodePath);
    } else if (selectedNodePath.includes(nodePath)) {
      nodePaths.push(selectedNodePath);
    }
  });
  return nodePaths.join('|');
}

function lowerLevelNodePath(path1: string, path2: string) {
  return path1.split('\\').length < path2.split('\\').length ? path2 : path1;
}

export function getCurrentNodePathLabel(nodePathLevels: (number | string)[]) {
  const departmentsText = getLevelText(nodePathLevels[0], ' Departments');
  const specialtiesText = getLevelText(nodePathLevels[1], ' Specialties');
  const providersText = getLevelText(nodePathLevels[2], ' Providers');
  return `${departmentsText} / ${specialtiesText} / ${providersText}`;
}

export function getLevelText(numberAtLevel: number | string, level: string): string {
  if (!numberAtLevel) {
    return 'All' + level;
  } else if (typeof numberAtLevel === 'string') {
    return numberAtLevel;
  } else {
    return numberAtLevel + level;
  }
}

export function getTagId(nodePath: string): string {
  const index = nodePath.lastIndexOf('\\');
  return index === -1 ? '' : nodePath.substring(index + 1);
}

export function determineDepartmentSpecialtyProviders(pipedNodePath: string): (string | number) [] {
  const depFullSelectionNodePaths: string[] = [];
  const depPartialSelectionNodePaths: string[] = [];
  const splFullSelectionNodePaths: string[] = [];
  const splPartialSelectionNodePaths: string[] = [];
  const proFullSelectionNodePaths: string[] = [];

  pipedNodePath.split('|').forEach(nodePath => {
    const nodePathArray = nodePath.substr(1).split('\\');
    switch (nodePathArray.length) {
      case 1:
      default:
        return [0, 0, 0];

      case 2:
        if (!depFullSelectionNodePaths.includes(nodePath)) {
          depFullSelectionNodePaths.push(nodePath);
        }
        break;

      case 3:
        const depPath = '\\' + nodePathArray[0] + '\\' + nodePathArray[1];
        if (!depPartialSelectionNodePaths.includes(depPath)) {
          depPartialSelectionNodePaths.push(depPath);
        }

        if (!splFullSelectionNodePaths.includes(nodePath)) {
          splFullSelectionNodePaths.push(nodePath);
        }
        break;

      case 4:
        const depPathFromProv = '\\' + nodePathArray[0] + '\\' + nodePathArray[1];
        if (!depPartialSelectionNodePaths.includes(depPathFromProv)) {
          depPartialSelectionNodePaths.push(depPathFromProv);
        }

        const splPathFromProv = '\\' + nodePathArray[0] + '\\' + nodePathArray[1] + '\\' + nodePathArray[2];
        if (!splPartialSelectionNodePaths.includes(splPathFromProv)) {
          splPartialSelectionNodePaths.push(splPathFromProv);
        }

        if (!proFullSelectionNodePaths.includes(nodePath)) {
          proFullSelectionNodePaths.push(nodePath);
        }
        break;
    }
  });

  const deptNodes: OntologyNode[] = [];
  const deptPartialNodes: OntologyNode[] = [];
  const specNodes: OntologyNode[] = [];
  const specPartialNodes: OntologyNode[] = [];
  const provNodes: OntologyNode[] = [];
  const ontologyData = getOntologyData();
  const ontology = ontologyData.ontologyHierarchy[0]?.children;

  if (ontology) {
    depFullSelectionNodePaths.forEach(depNodePath => {
      const nodes = ontology.filter(ontologyNode => ontologyNode.nodePath === depNodePath);
      nodes.forEach(d => {
        deptNodes.push(d);
      });
    });

    deptNodes.forEach(dept => {
      specNodes.push(...dept.children);
    });
    specNodes.forEach(spec => {
      provNodes.push(...spec.children);
    });

    depPartialSelectionNodePaths.forEach(depNodePath => {
      const nodes = ontology.filter(ontologyNode => ontologyNode.nodePath === depNodePath);
      nodes.forEach(d => {
        deptNodes.push(d);
        deptPartialNodes.push(d);
      });
    });

    deptPartialNodes.forEach(dp => {
      dp.children.forEach(dpc => {
        if (splFullSelectionNodePaths.includes(dpc.nodePath)) {
          specNodes.push(dpc);
        }
      });
    });

    specNodes.forEach(spec => {
      provNodes.push(...spec.children);
    });

    deptPartialNodes.forEach(dp => {
      dp.children.forEach(dpc => {
        if (splPartialSelectionNodePaths.includes(dpc.nodePath)) {
          specNodes.push(dpc);
          specPartialNodes.push(dpc);
        }
      });
    });

    specPartialNodes.forEach(dp => {
      dp.children.forEach(dpc => {
        if (proFullSelectionNodePaths.includes(dpc.nodePath)) {
          provNodes.push(dpc);
        }
      });
    });
  }

  const deptNodesSet = new Set<string | undefined>(deptNodes.map(node => getTagId(node.nodePath)));
  const specNodesSet = new Set<String | undefined>(specNodes.map(node => getTagId(node.nodePath)));
  const providerNodesSet = new Set<String | undefined>(provNodes.map(node => node.nodePath));

  return [
    (deptNodesSet.size === 1 ? deptNodes[0].nodeName :
      (deptNodesSet.size !== ontologyData.numberOfDepartments ? deptNodesSet.size : 0)),
    (specNodesSet.size === 1 ? specNodes[0].nodeName :
      (specNodesSet.size !== ontologyData.numberOfSpecialties ? specNodesSet.size : 0)),
    (providerNodesSet.size === 1 ? provNodes[0].nodeName :
      (providerNodesSet.size !== ontologyData.numberOfProviders ? providerNodesSet.size : 0))
  ];
}

export function updateCurrentNodePathLabel(nodePath: string): string {
  const counts = determineDepartmentSpecialtyProviders(nodePath);
  return getCurrentNodePathLabel(counts);
}

// @ts-ignore
export function noop() {
}

export type MonthNumber = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

export function getParentNodePath(nodePath: string): string {
  nodePath = getFirstNodePathFrom(nodePath);
  const index = nodePath.lastIndexOf('\\');
  return index === -1 ? '' : nodePath.substring(0, index);
}

export function benchmarkPercentileSeriesName(percentile: BenchmarkPercentile): string {
  switch (percentile) {
    case BenchmarkPercentile.Mean:
      return 'Mean';
    case BenchmarkPercentile.Percentile25th:
      return '25th Percentile';
    case BenchmarkPercentile.Percentile50th:
      return '50th Percentile';
    case BenchmarkPercentile.Percentile65th:
      return '65th Percentile';
    case BenchmarkPercentile.Percentile75th:
      return '75th Percentile';
    case BenchmarkPercentile.Percentile90th:
      return '90th Percentile';
    default:
      return '';
  }
}

export const getBenchmarkSeriesName = (benchmarkOption: BenchmarkOption, telehealthEnabled: boolean, percentile: BenchmarkPercentile) => {
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthAcademic:
      return `Telehealth Academic % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark`;
    case BenchmarkOption.TelehealthCommunity:
      return `Telehealth Community % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark`;
    case BenchmarkOption.InPersonAcademic:
      return `In-Person Academic % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark`;
    case BenchmarkOption.InPersonCommunity:
      return `In-Person Community % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark`;
    case BenchmarkOption.Community:
      return telehealthEnabled ? `All Visit Types Community % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark` :
        `Community % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark`;
    case BenchmarkOption.Academic:
    default:
      return telehealthEnabled ? `All Visit Types Academic % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark` :
        `Academic % New Patients ${benchmarkPercentileSeriesName(percentile)} Benchmark`;
  }
};

export function multilevelTabEquivalentOf(tab: MultilevelTabCollections): MultilevelTab {
  switch (tab) {
    case MultilevelTabCollections.BY_PAYER:
      return MultilevelTab.TREND;
    case MultilevelTabCollections.BY_PROVIDER:
      return MultilevelTab.BY_PROVIDER;
    case MultilevelTabCollections.BY_SPECIALTY:
      return MultilevelTab.BY_SPECIALTY;
    case MultilevelTabCollections.BY_DEPARTMENT:
    case MultilevelTabCollections.SUMMARY:
    default:
      return MultilevelTab.BY_DEPARTMENT;
  }
}

export function collectionsTabEquivalentOf(tab: MultilevelTab): MultilevelTabCollections {
  switch (tab) {
    case MultilevelTab.BY_DEPARTMENT:
    case MultilevelTab.LOCATION_DEPARTMENT:
      return MultilevelTabCollections.BY_DEPARTMENT;
    case MultilevelTab.BY_SPECIALTY:
    case MultilevelTab.LOCATION_SPECIALTY:
      return MultilevelTabCollections.BY_SPECIALTY;
    case MultilevelTab.BY_PROVIDER:
    case MultilevelTab.LOCATION_PROVIDER:
    case MultilevelTab.SPECIALTY_PERFORMANCE:
      return MultilevelTabCollections.BY_PROVIDER;
    case MultilevelTab.TREND:
      return MultilevelTabCollections.BY_PAYER;
    case MultilevelTab.NONE:
    default:
      return MultilevelTabCollections.SUMMARY;
  }
}

export function getRoute(url: string, tab: MultilevelTab | MultilevelTabCollections): string {
  if (url.toLowerCase().includes('wrvu')) {
    switch (tab) {
      case MultilevelTab.BY_PROVIDER:
        return wRVUProviderPageRoute;
      case MultilevelTab.BY_SPECIALTY:
        return wRVUSpecialtyPageRoute;
      case MultilevelTab.SPECIALTY_PERFORMANCE:
        return wRVUSpecialtyPerformancePageRoute;
      case MultilevelTab.BY_DEPARTMENT:
      default:
        return wRVUDepartmentPageRoute;
    }
  } else if (url.toLowerCase().includes('em')) {
    if (url.toLowerCase().includes('outpatient')) {
      switch (tab) {
        case MultilevelTab.BY_PROVIDER:
          return emOutpatientProviderRoute;
        case MultilevelTab.BY_SPECIALTY:
          return emOutpatientSpecialtyRoute;
        case MultilevelTab.BY_DEPARTMENT:
        default:
          return emOutpatientDepartmentRoute;
      }
    } else if (url.toLowerCase().includes('inpatient')) {
      switch (tab) {
        case MultilevelTab.BY_PROVIDER:
          return emInpatientProviderRoute;
        case MultilevelTab.BY_SPECIALTY:
          return emInpatientSpecialtyRoute;
        case MultilevelTab.BY_DEPARTMENT:
        default:
          return emInpatientDepartmentRoute;
      }
    } else if (url.toLowerCase().includes('ophthalmology')) {
      switch (tab) {
        case MultilevelTab.BY_PROVIDER:
          return emOphthalmologyProviderRoute;
        case MultilevelTab.BY_SPECIALTY:
          return emOphthalmologySpecialtyRoute;
        case MultilevelTab.BY_DEPARTMENT:
        default:
          return emOphthalmologyDepartmentRoute;
      }
    } else if (url.toLowerCase().includes('emergencyMedicine')) {
      switch (tab) {
        case MultilevelTab.BY_PROVIDER:
          return emEmergencyMedicineProviderRoute;
        case MultilevelTab.BY_SPECIALTY:
          return emEmergencyMedicineSpecialtyRoute;
        case MultilevelTab.BY_DEPARTMENT:
        default:
          return emEmergencyMedicineDepartmentRoute;
      }
    }
  }
  return url;
}

export function getRouteSegmentForMetric(metric: MetricType): string {
  switch (metric) {
    case MetricType.WorkRVUs:
      return 'wRVUs';
    case MetricType.ClinicalFingerprint:
      return 'cfp';
    case MetricType.Collections:
      return 'collections';
    case MetricType.Denials:
      return 'denials';
    case MetricType.EandM:
      return 'em';
    case MetricType.NewPatientVisits:
      return 'npv';
    case MetricType.ProcedureSummary:
      return 'proceduresummary';
    case MetricType.Overview:
    default:
      return 'overview';
  }
}

export function updateSelectedMember(member: MemberData, memberService: MemberService,
                                     memberBillingAreaService: MemberBillingAreaService, ngRedux: NgRedux<IAppState>) {
  memberService.isRevenueMember(member.memberKey).subscribe(isRevenueMember => {
    ngRedux.dispatch(memberDataFormatLoadedTo(isRevenueMember));
    if (isRevenueMember) {
      memberBillingAreaService.getMemberBillingArea(member.memberKey).subscribe((resp: MemberBillingArea[]) => {
        ngRedux.dispatch(memberBillingAreaLoadedTo(resp));
      });
    }
    ngRedux.dispatch(memberSelectedTo(member));
  });
}

export function pluralizeText(text: string): string {
  if (text.endsWith('y')) {
    return `${text.substring(0, text.length - 1)}ies`;
  } else if (['ch', 'sh', 's', 'x'].find(x => text.endsWith(x))) {
    return `${text}es`;
  }
  return `${text}s`;
}


export function translateTelehealthFlagForBackEnd(flag: number | null) {
  /*
   1/4/23
   On the Front End, 0 = All, 1 = In-person, and 2 = Telehealth
   On the Back End, NULL = All, 0 = In-person, and 1 = Telehealth
   */
  switch (flag) {
    case FrontEndTelehealthFlag.TELEHEALTH.valueOf():
      return BackEndTelehealthFlag.TELEHEALTH.valueOf();
    case FrontEndTelehealthFlag.IN_PERSON.valueOf():
      return BackEndTelehealthFlag.IN_PERSON.valueOf();
    case FrontEndTelehealthFlag.ALL_VISIT_TYPES:
    default:
      return null;
  }
}

export function translateTelehealthFlagForFrontEnd(flag: number | boolean | null) {
  // On the Front End,   0 = All, 1 = In-person, and 2 = Telehealth
  // On the Back End,    NULL = All, false = In-person, and true = Telehealth
  switch (flag) {
    case true:
      return FrontEndTelehealthFlag.TELEHEALTH.valueOf();
    case false:
      return FrontEndTelehealthFlag.IN_PERSON.valueOf();
    case null:
    default:
      return FrontEndTelehealthFlag.ALL_VISIT_TYPES.valueOf();
  }
}


export const getRequestBodyFromFilter = function (filterCriteria: FilterCriteria) {
  const telehealthFlag = translateTelehealthFlagForBackEnd(filterCriteria.telehealthFlag);
  return {
    startYear: filterCriteria.dateRange?.startYear || 0,
    endYear: filterCriteria.dateRange?.endYear || 0,
    startMonth: filterCriteria.dateRange?.startMonth || 1,
    endMonth: filterCriteria.dateRange?.endMonth || 1,
    nodePath: filterCriteria.nodePath,
    memberLocationKey: filterCriteria.memberLocation.memberLocationKey,
    payerCategoryKeyExpected: filterCriteria.payerCategory.payerCategoryKey,
    payerKey: filterCriteria.payerKey,
    payerType: filterCriteria.payerType,
    memberBillingAreaKey: filterCriteria.memberBillingArea.memberBillingAreaKey,
    lagKey: filterCriteria.lagKey,
    invoiceStatus: filterCriteria.invoiceStatus,
    telehealthFlag: telehealthFlag,
    customGroupId: filterCriteria.customGroupId, memberLocationKeys: filterCriteria.memberLocationKeys
  };
};


export function getFlagCorrespondingToBenchmark(option: BenchmarkOption) {
  if (option === BenchmarkOption.Community || option === BenchmarkOption.Academic) {
    return FrontEndTelehealthFlag.ALL_VISIT_TYPES.valueOf();
  } else if (option === BenchmarkOption.TelehealthAcademic || option === BenchmarkOption.TelehealthCommunity) {
    return FrontEndTelehealthFlag.TELEHEALTH.valueOf();
  } else {
    return FrontEndTelehealthFlag.IN_PERSON.valueOf();
  }
}

export function getAcademicBenchmarkCorrespondingToTelehealthFlag(flag: number | null) {
  if (flag === FrontEndTelehealthFlag.IN_PERSON.valueOf()) {
    return BenchmarkOption.InPersonAcademic;
  } else if (flag === FrontEndTelehealthFlag.TELEHEALTH.valueOf()) {
    return BenchmarkOption.TelehealthAcademic;
  } else {
    return BenchmarkOption.Academic;
  }
}

export function isAcademic(value: BenchmarkOption) {
  return value === BenchmarkOption.Academic || value === BenchmarkOption.TelehealthAcademic || value === BenchmarkOption.InPersonAcademic;
}

export function getNodePathFieldString(tab: MultilevelTab) {
  return `${getSingularOntologyLevelName(tab).toLowerCase()}NodePath`;
}

export function getNodePathNameString(tab: MultilevelTab) {
  return `${getSingularOntologyLevelName(tab).toLowerCase()}NodeName`;
}

export const MONTH_MAPPING: { [index: string]: string } = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

export function formatDate(month: number, year: number): string {
    return `${MONTH_MAPPING[month]} ${year}`;
}
