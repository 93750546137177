import {Component, Input, OnChanges} from '@angular/core';
import {getDefaultCptSummary, getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions} from '../../em-helpers';
import {CptCategories, CptSummary, Legend} from '../../../shared/models';
import {BenchmarkOption} from '../../../shared/enums';
import {MetricType} from '../../../shared/metric-types';
import {getEmLegends} from '../../em-chart-helpers';

@Component({
  selector: 'app-em-eye-exam-multilevel-chart',
  templateUrl: './em-eye-exam-multilevel-chart.component.html',
  styleUrls: ['./em-eye-exam-multilevel-chart.component.scss']
})
export class EmEyeExamMultilevelChartComponent implements OnChanges {

  @Input() totalCptCounts?: CptCategories;
  @Input() specialtyCptCounts?: CptCategories;
  @Input() showProgressBar: boolean;
  @Input() chartHeight: number;
  @Input() overviewPageView: boolean;
  @Input() chartWidth: number;
  @Input() isSingleProviderSelected: boolean;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() fromPdf: boolean;

  newPatientCategories = ['92002', '92004'];
  establishedPatientCategories = ['92012', '92014'];
  totalNewPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  totalEstablishedPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  specialtyTotalNewPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  specialtyTotalEstablishedPatientEyeExamCptSummaries: (CptSummary | undefined)[];
  newPatientEyeExamCptMaxValue = 0;
  establishedPatientEyeExamCptMaxValue = 0;
  specialtyNewPatientEyeExamCptMaxValue = 0;
  specialtyEstablishedPatientEyeExamCptMaxValue = 0;
  maxNewPatientEyeExamYAxisValue: number;
  maxEstablishedPatientEyeExamYAxisValue: number;
  legends: Legend[];
  readonly metricType = MetricType.EandMOpthalmology;

  constructor() {
  }

  ngOnChanges(): void {
    this.chartHeight = this.overviewPageView ? 250 : this.chartHeight;
    this.legends = getEmLegends(this.foundNodeName, this.isSingleProviderSelected);

    if (!this.totalCptCounts) {
      this.totalNewPatientEyeExamCptSummaries = [];
      this.totalEstablishedPatientEyeExamCptSummaries = [];

      this.newPatientCategories.forEach(x => {
        this.totalNewPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.establishedPatientCategories.forEach(x => {
        this.totalEstablishedPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.newPatientEyeExamCptMaxValue = 0;
      this.establishedPatientEyeExamCptMaxValue = 0;
      return;
    }


    this.specialtyTotalNewPatientEyeExamCptSummaries = [];
    this.totalEstablishedPatientEyeExamCptSummaries = [];
    this.specialtyTotalEstablishedPatientEyeExamCptSummaries = [];

    if (!this.specialtyCptCounts) {
      this.specialtyTotalNewPatientEyeExamCptSummaries = [];
      this.totalEstablishedPatientEyeExamCptSummaries = [];

      this.newPatientCategories.forEach(x => {
        this.specialtyTotalNewPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.establishedPatientCategories.forEach(x => {
        this.specialtyTotalEstablishedPatientEyeExamCptSummaries.push(getDefaultCptSummary());
      });

      this.specialtyNewPatientEyeExamCptMaxValue = 0;
      this.specialtyEstablishedPatientEyeExamCptMaxValue = 0;
    }

    if (this.totalCptCounts.eyeExamNewPatient) {
      this.totalNewPatientEyeExamCptSummaries = [
        this.totalCptCounts.eyeExamNewPatient.cpt92002,
        this.totalCptCounts.eyeExamNewPatient.cpt92004,
      ];
      this.newPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalNewPatientEyeExamCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.eyeExamNewPatient) {
      this.specialtyTotalNewPatientEyeExamCptSummaries = [
        this.specialtyCptCounts.eyeExamNewPatient.cpt92002,
        this.specialtyCptCounts.eyeExamNewPatient.cpt92004,
      ];
      this.specialtyNewPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalNewPatientEyeExamCptSummaries,
          this.benchmarkOption);
    }

    if (this.totalCptCounts.eyeExamEstablishedPatient) {
      this.totalEstablishedPatientEyeExamCptSummaries = [
        this.totalCptCounts.eyeExamEstablishedPatient.cpt92012,
        this.totalCptCounts.eyeExamEstablishedPatient.cpt92014,
      ];
      this.establishedPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalEstablishedPatientEyeExamCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.eyeExamEstablishedPatient) {
      this.specialtyTotalEstablishedPatientEyeExamCptSummaries = [
        this.specialtyCptCounts.eyeExamEstablishedPatient.cpt92012,
        this.specialtyCptCounts.eyeExamEstablishedPatient.cpt92014,
      ];
      this.specialtyEstablishedPatientEyeExamCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalEstablishedPatientEyeExamCptSummaries,
          this.benchmarkOption);
    }

    this.maxNewPatientEyeExamYAxisValue = Math.max(this.newPatientEyeExamCptMaxValue,
      this.specialtyNewPatientEyeExamCptMaxValue);
    this.maxEstablishedPatientEyeExamYAxisValue = Math.max(this.establishedPatientEyeExamCptMaxValue,
      this.specialtyEstablishedPatientEyeExamCptMaxValue);
  }
}

