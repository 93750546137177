import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment.local';
import {overviewPageRoute} from '../shared/routes';
import {ComplianceService, ComplianceServiceToken} from './services/compliance-notification-service';

@Component({
  selector: 'app-compliance-notice',
  templateUrl: './compliance-notice.component.html',
  styleUrls: ['./compliance-notice.component.scss']
})
export class ComplianceNoticeComponent implements OnInit, OnDestroy {
  complianceUrl: string;
  enableCompliance: boolean;

  constructor(
    @Inject(ComplianceServiceToken) private readonly complianceNoticeService: ComplianceService,
    private router: Router
  ) {
    this.enableCompliance = !!environment.enableCompliance;
    if (this.enableCompliance) {
      this.handleEvent = this.handleEvent.bind(this);
    }
  }

  ngOnInit() {
    this.complianceUrl = `${environment.complianceUrl}/getnotificationsuser/42/CPSCAnalytics`;
    if (this.enableCompliance) {
      window.addEventListener('message', this.handleEvent, false);
    }
  }

  ngOnDestroy() {
    if (this.enableCompliance) {
      window.removeEventListener('message', this.handleEvent);
    }
  }

  handleEvent(event: MessageEvent) {
    if (!this.enableCompliance) {
      return;
    }

    if (event.origin !== environment.complianceUrl) {
      return;
    }
    this.complianceNoticeService.handleEvent(event);
    if (event.data) {
      this.router.navigate([overviewPageRoute]);
    }
  }
}

