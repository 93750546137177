import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {FilterCriteria, IAppState} from '../store/IAppState';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {getFlagCorrespondingToBenchmark, isAcademic} from '../shared/helpers';
import {
  customGroupIdChangedTo,
  customGroupsDataChangedTo,
  emNpvBenchmarkOptionChangedTo,
  filterChosenByUserChangedTo,
  telehealthFlagChangedTo,
  viewCommunityBenchmarksChangedTo
} from '../store/actions';
import {CustomGroupsDataAllFilters, DropdownOption} from '../shared/models';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {MetricType} from '../shared/metric-types';
import {SaveApplyDialogComponent} from '../shared/components/save-apply-dialog/save-apply-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {visitTypes} from '../shared/constants';
import {
  UpdatedSaveGroupingComponent
} from '../updated-filter-banner/updated-save-grouping/updated-save-grouping.component';
import {BenchmarkOption} from '../shared/enums';
import {DEFAULT_CUSTOM_GROUP_ID} from '../store/DefaultValues';
import {CustomGroupsService, CustomGroupsServiceToken} from '../filter-banner/services/custom-groups-service';
import {createNewCustomGroupFromState} from '../updated-filter-banner/manage-groups-helper';
import {
  binaryBenchmarkEquivalentOf,
  extendedBenchmarkOptions,
  originalBenchmarkOptions
} from '../shared/benchmark-types';

@Component({
  selector: 'app-legend-benchmark-option',
  templateUrl: './legend-benchmark-option.component.html',
  styleUrls: ['./legend-benchmark-option.component.scss']
})
export class LegendBenchmarkOptionComponent implements OnInit, OnDestroy {

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly emNpvBenchmarkOption$: Observable<BenchmarkOption>;

  @select(['filters', 'dateRange', 'endYear'])
  private readonly endYear$: Observable<number>;

  @select(['filters'])
  private readonly filters$: Observable<FilterCriteria>;

  @select(['data', 'customGroupsData'])
  private readonly customGroupsData$: Observable<CustomGroupsDataAllFilters[]>;


  // noinspection JSUnusedLocalSymbols (this actually is used in the html)
  benchmarkOptions: DropdownOption[] = [];

  @Input() fromPdf: boolean;
  @Input() extendedBenchmarks = false;
  @Input() benchmarkOption: BenchmarkOption = BenchmarkOption.Academic;
  @Input() benchmarkPlaceholder: string;
  @Input() benchMarkDropDownClass: string;
  @Input() originClass: string;
  @Input() metric: MetricType;

  selectedBenchmarkOption: BenchmarkOption;
  selectedBenchmarkOptionValue: string | undefined;
  benchmarkClass: string;
  BenchmarkOption = BenchmarkOption;
  before2020 = false;
  private benchmarkSubscription: Subscription;
  private endYearSubscription: Subscription;
  private customGroupSubscription: Subscription;
  private customGroupSaveSubscription: Subscription;
  private customGroupSave: Observable<CustomGroupsDataAllFilters>;
  private selectedGroup: CustomGroupsDataAllFilters | undefined;
  private customGroups: CustomGroupsDataAllFilters[];
  private filters: FilterCriteria;
  private proposedBenchmarkOption: BenchmarkOption;
  private previousBenchmarkOption: BenchmarkOption;

  constructor(
    @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
    private readonly ngRedux: NgRedux<IAppState>,
    @Inject(CustomGroupsServiceToken) private readonly customGroupsService: CustomGroupsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (!this.fromPdf) {
      this.endYearSubscription = this.endYear$.subscribe((endYear: number) => {
        this.before2020 = endYear < 2020;
      });
      this.benchmarkOptions =
        this.shouldDisplayBinaryBenchmarkOptions() ? originalBenchmarkOptions : extendedBenchmarkOptions;
      this.benchmarkSubscription =
        combineLatest([this.viewCommunityBenchmarks$, this.emNpvBenchmarkOption$]).subscribe(
          ([viewCommunityBenchmarks, emNpvBenchmarkOption]: [boolean, BenchmarkOption]) => {
            this.setInitialSelectedBenchmarkOption(viewCommunityBenchmarks, emNpvBenchmarkOption);
          });
      this.customGroupSubscription = combineLatest([this.filters$, this.customGroupsData$])
        .subscribe(([filters, customGroups]: [FilterCriteria, CustomGroupsDataAllFilters[]]) => {
          this.customGroups = customGroups;
          this.selectedGroup = customGroups.find(cg => cg.id === filters.customGroupId);
          this.filters = filters;
        });
    } else {
      const displayBinaryBenchmarks = this.shouldDisplayBinaryBenchmarkOptions();
      this.benchmarkOptions = displayBinaryBenchmarks ? originalBenchmarkOptions : extendedBenchmarkOptions;
      this.setBenchmarkOptionValue(displayBinaryBenchmarks ? binaryBenchmarkEquivalentOf(this.benchmarkOption) :
        this.benchmarkOption);
    }
  }

  ngOnDestroy(): void {
    this.benchmarkSubscription?.unsubscribe();
    this.customGroupSubscription?.unsubscribe();
    this.customGroupSaveSubscription?.unsubscribe();
  }

  updateBenchmarkOption(option: BenchmarkOption): void {
    this.proposedBenchmarkOption = option;
    if (!this.shouldDisplayBinaryBenchmarkOptions() && this.isChangingVisitTypeWithBenchmark()) {
      this.openCustomGroupsVisitTypeDialog();
    } else {
      this.ngRedux.dispatch(viewCommunityBenchmarksChangedTo(!isAcademic(option)));
      this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo(option));
      this.updateBenchmarkCssClass(option);
    }
  }

  private isChangingVisitTypeWithBenchmark() {
    return getFlagCorrespondingToBenchmark(this.proposedBenchmarkOption)
      !== getFlagCorrespondingToBenchmark(this.previousBenchmarkOption);
  }


  private alignTelehealthFlagWithBenchmarkVisitType(option: BenchmarkOption) {
    const newFlag = getFlagCorrespondingToBenchmark(option);
    this.ngRedux.dispatch(telehealthFlagChangedTo(newFlag));
  }

  private openCustomGroupsVisitTypeDialog(): void {
    const vtText = visitTypes.find(v => v.key === getFlagCorrespondingToBenchmark(this.proposedBenchmarkOption).valueOf())?.text;
    const dialogRef = this.dialog.open(SaveApplyDialogComponent, {
      data: {
        question: `Changing the benchmark type to ${vtText} will change the visit type filter to ${vtText}. Would you like to save your changes to a custom group?`,
        title: 'Save changes?',
        confirmCallback: this.confirmChangeBenchmarkOption,
        cancelCallback: this.applyBenchmarkOptionWithoutSavingGroup
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.cancel();
      }
    });
  }

  confirmChangeBenchmarkOption = () => {
    this.customGroupSaveSubscription?.unsubscribe();

    const newGroup = {
      ...(this.selectedGroup || createNewCustomGroupFromState(this.ngRedux.getState())),
      telehealthFlag: getFlagCorrespondingToBenchmark(this.proposedBenchmarkOption)
    };
    const dialogRef = this.dialog.open(UpdatedSaveGroupingComponent, {
      data: {
        group: newGroup,
        existingGroups: this.customGroups
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.saved) {
        const memberKey = newGroup.memberKey;
        this.customGroupSave = result.groupIsEdited
          ? this.customGroupsService.updateCustomGroup(newGroup, memberKey, true)
          : this.customGroupsService.saveCustomGroup({...newGroup, preload: false}, memberKey);

        this.customGroupSaveSubscription = this.customGroupSave.subscribe(customGroup => {
          this.applyBenchmarkOptionAndCustomGroupChange(customGroup.id);
          this.customGroupsService.getCustomGroups(memberKey)?.subscribe((groups: CustomGroupsDataAllFilters[]) => {
            this.ngRedux.dispatch(customGroupsDataChangedTo(groups));
          });
        });
      } else {
        this.cancel();
      }
    });
  };

  applyBenchmarkOptionWithoutSavingGroup = () => {
    this.applyBenchmarkOption(DEFAULT_CUSTOM_GROUP_ID);
  };

  applyBenchmarkOptionAndCustomGroupChange(cgId: number) {
    this.applyBenchmarkOption(cgId);
  }

  private applyBenchmarkOption(cgId: number) {
    this.updateBenchmarkCssClass(this.proposedBenchmarkOption);
    this.handleTelehealthBenchmarkChange(this.proposedBenchmarkOption);
    this.ngRedux.dispatch(customGroupIdChangedTo(cgId));
    this.ngRedux.dispatch(filterChosenByUserChangedTo(true));
  }

  private updateBenchmarkCssClass(option: BenchmarkOption) {
    this.benchmarkClass = isAcademic(option) ? 'academic' : 'community';
  }

  private handleTelehealthBenchmarkChange(option: BenchmarkOption) {
    this.ngRedux.dispatch(viewCommunityBenchmarksChangedTo(!isAcademic(option)));
    if ((this.isEmMetric() || this.isNpvMetric()) && !this.shouldDisplayBinaryBenchmarkOptions()) {
      this.alignTelehealthFlagWithBenchmarkVisitType(option);
      this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo(option));
    }
  }

  cancel(): void {
    this.selectedBenchmarkOption = this.previousBenchmarkOption;
    this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo(this.previousBenchmarkOption));
  }

  private shouldDisplayBinaryBenchmarkOptions() {
    return !(this.shouldDisplayEMTelehealthBenchmarks() || this.isNpvMetric());
  }

  private setInitialSelectedBenchmarkOption(viewCommunityBenchmarks: boolean, emNpvBenchmarkOption: BenchmarkOption) {
    if (this.shouldDisplayBinaryBenchmarkOptions()) {
      this.setBinaryBenchmarkOption(viewCommunityBenchmarks);
    } else {
      this.setQuaternaryBenchmarkOption(emNpvBenchmarkOption);
    }
    this.previousBenchmarkOption = this.selectedBenchmarkOption;
  }

  private isEmMetric(): boolean {
    return [MetricType.EandM,
      MetricType.EandMTrend,
      MetricType.EandMOutpatient,
      MetricType.EandMInpatient,
      MetricType.EandMEmergency,
      MetricType.EandMOpthalmology].includes(this.metric);
  }

  private isNpvMetric(): boolean {
    return this.metric === MetricType.NewPatientVisits || this.metric === MetricType.OverviewNpv;
  }

  private shouldDisplayEMTelehealthBenchmarks() {
    return this.isEmMetric();
  }

  private setBinaryBenchmarkOption(viewCommunity: boolean) {
    this.selectedBenchmarkOption = viewCommunity ? BenchmarkOption.Community : BenchmarkOption.Academic;
  }

  private setQuaternaryBenchmarkOption(benchmarkOption: BenchmarkOption) {
    this.selectedBenchmarkOption = benchmarkOption;
  }

  private setBenchmarkOptionValue(selectedOption: BenchmarkOption) {
    this.selectedBenchmarkOptionValue = this.benchmarkOptions.find(option => option.value === selectedOption)?.name;
  }
}
