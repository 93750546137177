import {Component, Inject, OnInit} from '@angular/core';
import {ApplicationConfigurationSetting, MemberData} from '../shared/models';
import {getAppConfigValue} from '../shared/helpers';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {Observable, Subscription} from 'rxjs';
import {select} from '@angular-redux/store';
import {
  ApplicationConfigurationService, ApplicationConfigurationServiceToken
} from '../services/app-configuration.service';
import {OktaAuth} from '@okta/okta-auth-js';

@Component({
  selector: 'app-ado-testing',
  templateUrl: './ado-testing.html',
  styleUrls: ['./ado-testing.scss']
})
export class AdoTestingComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {
  }



}
