import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {
  combineFilter,
  FilterType,
  getLevelFromNodePath,
  getNumberOfMonthsInDateRange,
  getPreviousYearDateRange,
  getSelectedDateRange,
  isFeatureEnabled,
  WrvuViewType
} from '../../shared/helpers';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {LegendData, OverviewChartOptions} from '../models/overview-models';
import {formatNumberToWholeNumber, roundTo, roundToNumber} from '../../productivity-summary/number-formatter';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {
  backgroundColor,
  BarChartOptions,
  calculateChartWidth,
  ChartMarkerSymbol,
  chartType,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  DEFAULT_POINT_PADDING,
  FormatOption,
  getBarchartSeriesOption,
  getChartOptions,
  getLegendOptions,
  getTitleOptions,
  getXaxisOptions,
  getYAxisOption
} from '../../shared/highcharts-helpers';
import {MetricType} from '../../shared/metric-types';
import {BenchmarkPercentile, getBenchmarkLabel, readableNameOf, readableNameOfColumnDef} from '../../shared/benchmark-types';
import {abbreviatedDateLabel} from '../../productivity-summary/month-formatter';
import {checkForNulls, hasNonZeroValue} from '../../shared/null-helpers';
import {FilterSelectorService, FilterSelectorServiceToken} from '../../services/filter-selector.service';
import {ApiServiceImpl, ApiServiceToken} from '../../services/api.service';
import {
  ApplicationConfigurationSetting,
  CustomGroupsDataAllFilters,
  DateRange,
  EmDimension,
  MergedProductivityTrendEntry,
  MonthProductivityEntry,
  NpvBenchmarkData,
  ZipCodeNewPatientVisits
} from '../../shared/models';
import {NewPatientVisitTrendEntry} from '../../new-patient-visits/components/npv-models';
import {getDesignatedNpvBenchmarkObject} from '../../new-patient-visits/components/npv-helpers';
import {BenchmarkPercentilesForNpv} from '../../shared/BenchmarkColumns';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';
import {BenchmarkOption, HighChartColorIndex, LegendColor, LegendStyle, MetricLoadKeys} from '../../shared/enums';
import {selectedNodesChangedTo} from '../../store/actions';
import {getSelectedNodeCountsFromNodePath} from '../../shared/ontology-helpers';

@Component({
  selector: 'app-overview-page',
  templateUrl: './overview-page.component.html',
  styleUrls: ['./overview-page.component.scss']
})
export class OverviewPageComponent implements OnInit, OnDestroy, AfterViewInit {

  filter: FilterCriteria;
  dateRange: DateRange;
  benchmark: BenchmarkPercentile;
  selectedDateRange: string;
  previousSelectedDateRange: string;
  viewCommunityBenchmarks: boolean;
  benchmarkOption: BenchmarkOption;
  benchmarkLabel: string;

  wrvuSelectedRangeTrendData: MergedProductivityTrendEntry[];
  wrvuTitle: string;
  wrvuComparedTo: string;
  wrvuTrendChartOptions: OverviewChartOptions;
  wrvuLegendData: LegendData[];
  wrvuViewType: WrvuViewType;
  wrvuVarianceText: string;
  wrvuVarianceClass: string;
  wrvuCfteText: string;
  wrvuCfteClass: string;
  isCfteAdjusted: boolean;
  wrvuVarianceSubtractFrom: string;
  wrvuVarianceSubtract: string;
  benchmarkPlaceholder: string;

  npvTitle: string;
  npvComparedTo: string;
  npvLegendData: LegendData[];
  npvSelectedRangeTrendData: NewPatientVisitTrendEntry[];
  zipCodeNewPatientVisits: ZipCodeNewPatientVisits[] = [];
  npvTrendChartOptions: OverviewChartOptions;
  npvVarianceText: string;
  npvVarianceClass: string;
  npvPercentageText: string;
  npvPercentageClass: string;
  npvVarianceSubtractFrom: string;
  npvVarianceSubtract: string;
  npvBenchmarkPercentile: BenchmarkPercentile;
  npvBenchmarkPlaceholder: string;

  emTitle: string;

  private NUMBER_OF_DATA_POINTS: number;
  OVERVIEW_CHART_HEIGHT = 200;
  private snapshotBarCount = 12;
  private readonly POINT_WIDTH = 21;
  private chartWidth = calculateChartWidth(this.POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING);
  wrvuMetricType = MetricType.WorkRVUs;
  npvMetricType = MetricType.NewPatientVisits;
  emMetricType = MetricType.EandM;
  wrvuShowProgressBar = true;
  npvShowProgressBar = true;
  hasNpvCommunityBenchmarks: boolean;
  hasNpvAcademicBenchmarks: boolean;
  hasWrvuCommunityBenchmarks: boolean;
  hasWrvuAcademicBenchmarks: boolean;
  wrvuTrendSubscription: Subscription;
  npvTrendSubscription: Subscription;
  filterSubscription: Subscription;

  @select(['data', 'customGroupsData'])
  private readonly customGroupsData$: Observable<CustomGroupsDataAllFilters[]>;

  @select(['filters'])
  private readonly filter$: Observable<FilterCriteria>;

  @select(['data', 'newPatientVisitTrendData'])
  private readonly npvSelectedRangeTrendData$: Observable<NewPatientVisitTrendEntry[]>;

  @select(['data', 'mergedProductivityTrend'])
  private readonly mergedProductivityTrend$: Observable<MergedProductivityTrendEntry[]>;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['display', 'wrvuViewType'])
  private readonly wrvuViewType$: Observable<WrvuViewType>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly benchmarkOption$: Observable<BenchmarkOption>;
  @select(['data', 'applicationConfigurationSettings'])
  applicationConfigurationSettings$: Observable<ApplicationConfigurationSetting[]>;
  @select(['display', 'selectedProviders'])
  selectedProviders$: Observable<number>;
  @select(['display', 'selectedSpecialties'])
  selectedSpecialties$: Observable<number>;
  @select(['ontologyLoaded'])
  ontologyLoaded$: Observable<boolean>;

  @Input() fromPdfExport = false;
  @Input() emMetrics: EmDimension[];
  @Input() includeWrvuNpvTile: boolean;
  @Input() includeEMTile: boolean;
  @Input() includeZipTile: boolean;

  private static getBenchmarkLabelByBenchmarkOption(benchmarkOption: BenchmarkOption, vc: boolean): string {
    if (!benchmarkOption) {
      return vc ? 'Community' : 'Academic';
    }
    switch (benchmarkOption) {
      case BenchmarkOption.Community:
        return 'Community';
      case BenchmarkOption.TelehealthAcademic:
        return 'Telehealth-Academic';
      case BenchmarkOption.TelehealthCommunity:
        return 'Telehealth-Community';
      case BenchmarkOption.Academic:
        return 'Academic';
      case BenchmarkOption.InPersonAcademic:
        return 'In Person-Academic';
      case BenchmarkOption.InPersonCommunity:
        return 'In Person-Community';
      default:
        return 'Academic';
    }
  }

  constructor(private ngRedux: NgRedux<IAppState>,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl,
              private cdr: ChangeDetectorRef) {
    this.filterSubscription = combineLatest([this.filterService.getFilters(),
      this.applicationConfigurationSettings$, this.selectedProviders$, this.selectedSpecialties$, this.ontologyLoaded$])
      .subscribe(([filter, appConfig, providers, specialties, ontologyLoaded]:
                    [FilterCriteria, ApplicationConfigurationSetting[], number, number, boolean]) => {
        const previousYearFilter = combineFilter(filter, FilterType.PreviousYear);
        const specialtyFilter = combineFilter(filter, FilterType.Specialty);
        if (!!filter.nodePath) {
          if (providers === 0 || specialties === 0 || !ontologyLoaded ||
            !appConfig.find(entry => entry.appConfigName.localeCompare(AppConfigEntries.MAX_PROVIDER_PATHS) === 0) ||
            !appConfig.find(entry => entry.appConfigName.localeCompare(AppConfigEntries.MAX_SPECIALTY_PATHS) === 0)) {
            localStorage.removeItem(MetricLoadKeys.IS_EM_SNAPSHOT_LOADED);
            const {selectedProviders, selectedSpecialties} = getSelectedNodeCountsFromNodePath(filter.nodePath);
            this.ngRedux.dispatch(selectedNodesChangedTo(selectedProviders, selectedSpecialties));
          } else {
            this.apiService.getZipCodeNewPatientData(filter);
            this.apiService.getEvaluationManagementData(filter, specialtyFilter);
            this.apiService.getWrvuTrendData(filter, previousYearFilter);
            this.apiService.getNewPatientTrendData(filter);
          }
        }
      });
  }

  ngOnInit() {
    this.filter$.pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2)))
      .subscribe((selectedFilter: FilterCriteria) => {
        if (selectedFilter.memberKey > 0 && selectedFilter.nodePath.length > 0) {
          this.filter = selectedFilter;
          this.dateRange = selectedFilter.dateRange;
          this.NUMBER_OF_DATA_POINTS = getNumberOfMonthsInDateRange(this.dateRange);
          this.npvShowProgressBar = true;
          this.wrvuShowProgressBar = true;
          this.populateNpvTrendTile();
          this.populateWrvuTrendTile();
          this.populateEmTile();
          this.selectedDateRange = getSelectedDateRange(this.dateRange, true);
          this.previousSelectedDateRange = getPreviousYearDateRange(this.dateRange, true);
          this.cdr.detectChanges();
        }
      });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
  }

  populateNpvTrendTile() {
    this.npvTrendSubscription?.unsubscribe();
    this.npvTrendSubscription = combineLatest([
      this.npvSelectedRangeTrendData$.pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2))),
      this.benchmarkPercentile$.pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2))),
      this.viewCommunityBenchmarks$,
      this.benchmarkOption$
    ])
      .subscribe((
        [npvTrendData, benchmarkPercentile, viewCommunityBenchmarks, benchmarkOption]:
          [NewPatientVisitTrendEntry[], BenchmarkPercentile, boolean, BenchmarkOption]) => {

        if (!_.isEqual(npvTrendData, INITIAL_STATE.data.newPatientVisitTrendData)
          || this.npvBenchmarkPercentile !== benchmarkPercentile
          || this.viewCommunityBenchmarks !== viewCommunityBenchmarks
          || this.benchmarkOption !== benchmarkOption
        ) {
          let academicBenchmarks: NewPatientVisitTrendEntry[] = [];
          const academicArray = [BenchmarkOption.Academic, BenchmarkOption.TelehealthAcademic,
            BenchmarkOption.InPersonAcademic];
          academicArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
            BenchmarkPercentilesForNpv.forEach(bt => {
              academicBenchmarks = academicBenchmarks.concat(npvTrendData.filter(x => {
                const value = x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`];
                return hasNonZeroValue(value);
              }));
            });
          });
          let communityBenchmarks: NewPatientVisitTrendEntry[] = [];
          const communityArray = [BenchmarkOption.Community, BenchmarkOption.TelehealthCommunity,
            BenchmarkOption.InPersonCommunity];
          communityArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
            BenchmarkPercentilesForNpv.forEach(bt => {
              communityBenchmarks = communityBenchmarks.concat(npvTrendData.filter(x => {
                const value = x[benchmarkObject] ? x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`] : '-';
                return hasNonZeroValue(value);
              }));
            });
          });
          this.hasNpvCommunityBenchmarks = communityBenchmarks.length > 0;
          this.hasNpvAcademicBenchmarks = academicBenchmarks.length > 0;
          this.viewCommunityBenchmarks = viewCommunityBenchmarks;
          this.benchmarkOption = benchmarkOption;
          this.benchmarkLabel = OverviewPageComponent.getBenchmarkLabelByBenchmarkOption(benchmarkOption, viewCommunityBenchmarks);
          this.npvBenchmarkPercentile =
            benchmarkPercentile === BenchmarkPercentile.Percentile65th ? BenchmarkPercentile.Mean : benchmarkPercentile;
          this.npvBenchmarkPlaceholder = getBenchmarkLabel(this.npvBenchmarkPercentile);
          this.npvSelectedRangeTrendData = _.orderBy(npvTrendData.slice(), ['year', 'month'], ['asc', 'asc']);
          this.npvTrendChartOptions = this.populateNpvOverviewChartDetails(this.npvBenchmarkPercentile);
          this.populateNpvOverviewDetails(this.npvBenchmarkPercentile);
          this.npvShowProgressBar = _.isEqual(npvTrendData, INITIAL_STATE.data.newPatientVisitTrendData);
        }
      });
  }

  populateNpvOverviewDetails(npvBenchmarkPercentile: BenchmarkPercentile) {
    this.npvTitle = '% New Patients Trend';
    this.npvComparedTo = ' compared to ';
    this.npvVarianceSubtractFrom = '% New Patients';
    this.npvVarianceSubtract = this.benchmarkLabel.toLowerCase() + ' benchmark ' +
      readableNameOf(npvBenchmarkPercentile, ' percentile').toLowerCase();
    const currentNpvPercentage = this.getNpvPercentage(this.npvSelectedRangeTrendData);
    const previousNpvPercentage = this.getNpvPercentage(this.npvSelectedRangeTrendData, true);
    const npvSummaryBenchmarkValue =
      this.lookupNPVSummaryBenchmarkValue(npvBenchmarkPercentile,
        this.npvSelectedRangeTrendData, this.benchmarkOption);
    const previousNPVSummaryBenchmarkValue = this.lookupNPVSummaryBenchmarkValue(
      npvBenchmarkPercentile, this.npvSelectedRangeTrendData,
      this.benchmarkOption, true);

    this.npvLegendData = [
      {
        name: '% New Patients',
        currentDatesValue: roundTo(currentNpvPercentage, 1) + '%',
        currentDatesIcon: LegendStyle.CIRCLE,
        currentDatesColor: LegendColor.TEAL,
        previousDatesValue: roundTo(previousNpvPercentage, 1) + '%',
        previousDatesIcon: LegendStyle.SQUARE,
        previousDatesColor: LegendColor.PURPLE
      },
      {
        name: '% New Patients',
        currentDatesIcon: LegendStyle.DIAMOND,
        currentDatesColor: LegendColor.GREY,
        currentDatesValue: npvSummaryBenchmarkValue ?
          roundTo(npvSummaryBenchmarkValue * 100, 1) + '%' : '-',
        previousDatesValue: previousNPVSummaryBenchmarkValue ?
          roundTo(previousNPVSummaryBenchmarkValue * 100, 1) + '%' : '-',
        class: 'npv-benchmark',
        benchmark: true
      },
      {
        name: '# New / Total Patients',
        currentDatesValue: this.getNpvRatio(this.npvSelectedRangeTrendData),
        previousDatesValue: this.getNpvRatio(this.npvSelectedRangeTrendData, true)
      }
    ];

    if ((currentNpvPercentage - npvSummaryBenchmarkValue * 100) < 0) {
      this.npvVarianceText = roundTo(checkForNulls((currentNpvPercentage - npvSummaryBenchmarkValue * 100)) * -1, 1) + '% below';
      this.npvVarianceClass = 'negative';
    } else {
      this.npvVarianceText = npvSummaryBenchmarkValue ?
        roundTo(checkForNulls((currentNpvPercentage - npvSummaryBenchmarkValue * 100)), 1) + '% above' : '-';
      this.npvVarianceClass = 'positive';
    }

    if (currentNpvPercentage - previousNpvPercentage < 0) {
      this.npvPercentageText =
        roundTo(checkForNulls(currentNpvPercentage - previousNpvPercentage), 1) + '% decrease';
      this.npvPercentageClass = 'negative';
    } else {
      this.npvPercentageText = roundTo(checkForNulls(currentNpvPercentage - previousNpvPercentage), 1) + '% increase';
      this.npvPercentageClass = 'positive';
    }
  }

  populateNpvOverviewChartDetails(npvBenchmarkPercentile: BenchmarkPercentile): OverviewChartOptions {
    const selectedRangeNewPatientVisitPercentage = this.npvSelectedRangeTrendData.map((visits: NewPatientVisitTrendEntry) =>
      roundToNumber(visits.newPatientVisitsPercentage, 1));

    const selectedRangeBarOptions: BarChartOptions = {
      name: '% New Patients',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.TEAL,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 2,
      data: selectedRangeNewPatientVisitPercentage.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(selectedRangeNewPatientVisitPercentage, this.NUMBER_OF_DATA_POINTS, 0) :
        selectedRangeNewPatientVisitPercentage,
      marker: {
        symbol: ChartMarkerSymbol.CIRCLE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };

    const previousRangeNewPatientVisitPercentage = this.npvSelectedRangeTrendData.map((visits: NewPatientVisitTrendEntry) =>
      roundToNumber(visits.previous.newPatientVisitsPercentage, 1));

    const previousRangeBarOptions = {
      name: 'Previous % New Patients',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.PURPLE,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 1,
      data: previousRangeNewPatientVisitPercentage.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(previousRangeNewPatientVisitPercentage, this.NUMBER_OF_DATA_POINTS, 0) :
        previousRangeNewPatientVisitPercentage,
      marker: {
        symbol: ChartMarkerSymbol.SQUARE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };

    const benchmarks = this.npvSelectedRangeTrendData.map((visits: NewPatientVisitTrendEntry) =>
      this.lookupNPVBenchmarkValue(npvBenchmarkPercentile, visits, this.benchmarkOption) * 100);

    const selectedBenchmarksRangeBarOptions: BarChartOptions = {
      name: '% ' + this.benchmarkLabel + ' New Patients ' + readableNameOf(npvBenchmarkPercentile, ' percentile') + ' Benchmark',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.GREY,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 3,
      data: createEmptyValues(benchmarks, this.NUMBER_OF_DATA_POINTS, 0),
      marker: {
        symbol: ChartMarkerSymbol.DIAMOND
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };


    const categories = this.npvSelectedRangeTrendData.map((visit) => abbreviatedDateLabel(visit.month, visit.year));
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;

    return {
      title: getTitleOptions(''),
      series: previousRangeBarOptions
        ? [getBarchartSeriesOption(selectedRangeBarOptions, !this.fromPdfExport),
          getBarchartSeriesOption(previousRangeBarOptions, !this.fromPdfExport),
          getBarchartSeriesOption(selectedBenchmarksRangeBarOptions, !this.fromPdfExport)]
        : [getBarchartSeriesOption(selectedRangeBarOptions, !this.fromPdfExport),
          getBarchartSeriesOption(selectedBenchmarksRangeBarOptions, !this.fromPdfExport)],
      legend: getLegendOptions(false),
      tooltip: {
        shared: true,
        valueDecimals: 1
      },
      chart: {
        ...getChartOptions(chartType.Line, backgroundColor.Transparent, this.chartWidth, this.OVERVIEW_CHART_HEIGHT),
        animation: !this.fromPdfExport
      },
      xAxis: [getXaxisOptions(categories, isScrolled, DEFAULT_MAX_NUMBER_SCROLLBARS)],
      yAxis: getYAxisOption('', selectedBenchmarksRangeBarOptions ? Math.max(...selectedRangeNewPatientVisitPercentage,
          ...previousRangeNewPatientVisitPercentage, ...benchmarks) : Math.max(...benchmarks),
        FormatOption.Percentage),
      showFullScrollbar: false,
      enableScrollbar: isScrolled,
      legends: []
    };
  }


  populateWrvuTrendTile() {
    this.wrvuTrendSubscription?.unsubscribe();
    this.wrvuTrendSubscription = combineLatest([
      this.mergedProductivityTrend$.pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2))),
      this.benchmarkPercentile$,
      this.viewCommunityBenchmarks$,
      this.wrvuViewType$
    ]).subscribe(([wrvuMergedData, benchmarkPercentile, viewCommunityBenchmarks, wrvuViewType]:
                    [MergedProductivityTrendEntry[], BenchmarkPercentile, boolean, WrvuViewType]) => {
      this.hasWrvuCommunityBenchmarks = !!wrvuMergedData.find(x =>
        x.communityBenchmarkMean ||
        x.communityBenchmark25thPercentile ||
        x.communityBenchmark50thPercentile ||
        x.communityBenchmark65thPercentile ||
        x.communityBenchmark75thPercentile ||
        x.communityBenchmark90thPercentile);

      this.hasWrvuAcademicBenchmarks = !!wrvuMergedData.find(x =>
        x.benchmarkMean ||
        x.benchmark25thPercentile ||
        x.benchmark50thPercentile ||
        x.benchmark65thPercentile ||
        x.benchmark75thPercentile ||
        x.benchmark90thPercentile);

      if (!_.isEqual(wrvuMergedData, INITIAL_STATE.data.mergedProductivityTrend)
        || this.benchmark !== benchmarkPercentile
        || this.wrvuViewType !== wrvuViewType
        || this.viewCommunityBenchmarks !== viewCommunityBenchmarks
      ) {
        this.wrvuShowProgressBar = _.isEqual(wrvuMergedData, INITIAL_STATE.data.mergedProductivityTrend);
        this.wrvuSelectedRangeTrendData = _.orderBy(wrvuMergedData.slice(), ['year', 'month'], ['asc', 'asc']);
        this.benchmark = benchmarkPercentile;
        this.benchmarkPlaceholder = getBenchmarkLabel(this.benchmark);
        this.wrvuViewType = wrvuViewType;
        this.isCfteAdjusted = wrvuViewType === WrvuViewType.CfteAdjusted;
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.benchmarkLabel = viewCommunityBenchmarks ? 'Community' : 'Academic';

        this.wrvuTrendChartOptions = this.populateWrvuOverviewChartDetails();
        this.populateWrvuOverviewDetails();
      }
    });
  }


  populateWrvuOverviewDetails() {
    this.wrvuTitle = this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'cFTE Adj. wRVUs Trend' : 'Actual wRVUs Trend';
    this.wrvuComparedTo = ' compared to ';
    this.wrvuVarianceSubtractFrom = this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'cFTE Adj. wRVUs' : 'Actual wRVUs';
    this.wrvuVarianceSubtract = this.benchmarkLabel.toLowerCase() + ' benchmark ' +
      readableNameOf(this.benchmark, ' percentile').toLowerCase();
    this.wrvuLegendData = this.wrvuViewType === WrvuViewType.CfteAdjusted ?
      [
        {
          name: 'cFTE Adj. wRVUs',
          currentDatesValue: formatNumberToWholeNumber(this.getCfteAdjustedWrvus(this.wrvuSelectedRangeTrendData)),
          currentDatesIcon: LegendStyle.CIRCLE,
          currentDatesColor: LegendColor.TEAL,
          previousDatesValue: formatNumberToWholeNumber(this.getCfteAdjustedWrvus(this.wrvuSelectedRangeTrendData, true)),
          previousDatesIcon: LegendStyle.SQUARE,
          previousDatesColor: LegendColor.PURPLE
        },
        {
          name: 'wRVUs',
          currentDatesIcon: LegendStyle.DIAMOND,
          currentDatesColor: LegendColor.GREY,
          currentDatesValue: formatNumberToWholeNumber((this.lookupWrvuSummaryBenchmarkValue(
            this.benchmark, this.wrvuSelectedRangeTrendData, this.viewCommunityBenchmarks))),
          previousDatesValue: formatNumberToWholeNumber((this.lookupWrvuSummaryBenchmarkValue(
            this.benchmark, this.wrvuSelectedRangeTrendData, this.viewCommunityBenchmarks, true))),
          benchmark: true
        }
      ] : [
        {
          name: 'Actual wRVUs',
          currentDatesValue: formatNumberToWholeNumber(this.getWrvus(this.wrvuSelectedRangeTrendData)),
          currentDatesIcon: LegendStyle.CIRCLE,
          currentDatesColor: LegendColor.TEAL,
          previousDatesValue: formatNumberToWholeNumber(this.getWrvus(this.wrvuSelectedRangeTrendData, true)),
          previousDatesIcon: LegendStyle.SQUARE,
          previousDatesColor: LegendColor.PURPLE
        }
      ];


    const variance = this.lookupWrvuVarianceValue(this.benchmark, this.wrvuSelectedRangeTrendData, this.viewCommunityBenchmarks);
    if (variance < 0) {
      this.wrvuVarianceText =
        formatNumberToWholeNumber(variance * -1) + ' below';
      this.wrvuVarianceClass = 'negative';
    } else {
      this.wrvuVarianceText = formatNumberToWholeNumber(variance) + ' above';
      this.wrvuVarianceClass = 'positive';
    }

    const currentWrvus = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? checkForNulls(this.getCfteAdjustedWrvus(this.wrvuSelectedRangeTrendData))
      : checkForNulls(this.getWrvus(this.wrvuSelectedRangeTrendData));

    const previousWrvus = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? checkForNulls(this.getCfteAdjustedWrvus(this.wrvuSelectedRangeTrendData, true))
      : checkForNulls(this.getWrvus(this.wrvuSelectedRangeTrendData, true));

    if (currentWrvus - previousWrvus < 0) {
      this.wrvuCfteText = formatNumberToWholeNumber(currentWrvus - previousWrvus) + ' decrease';
      this.wrvuCfteClass = 'negative';
    } else {
      this.wrvuCfteText = formatNumberToWholeNumber(currentWrvus - previousWrvus) + ' increase';
      this.wrvuCfteClass = 'positive';
    }

  }

  populateWrvuOverviewChartDetails(): OverviewChartOptions {
    const selectedRangeTrendCfteAdjustedWrvus = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? this.wrvuSelectedRangeTrendData.map(wrvu => wrvu.cfteAdjustedWRVUs)
      : this.wrvuSelectedRangeTrendData.map(wrvu => wrvu.wRVUs);

    const selectedRangeBarOptions: BarChartOptions = {
      name: this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'cFTE adjusted wRVUs' : 'Actual wRVUs',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.TEAL,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 2,
      data: selectedRangeTrendCfteAdjustedWrvus.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(selectedRangeTrendCfteAdjustedWrvus, this.NUMBER_OF_DATA_POINTS, 0) :
        selectedRangeTrendCfteAdjustedWrvus,
      marker: {
        symbol: ChartMarkerSymbol.CIRCLE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };

    let previousRangeBarOptions: any;
    const previousRangeTrendCfteAdjustedWrvus = this.wrvuSelectedRangeTrendData
      .map(wrvu => checkForNulls(this.wrvuViewType === WrvuViewType.CfteAdjusted ? wrvu.previousCfteAdjustedWRVUs : wrvu.previousWrvus));

    previousRangeBarOptions = {
      name: this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'Previous cFTE adjusted wRVUs'
        : 'Previous Actual wRVUs',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.PURPLE,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 1,
      data: previousRangeTrendCfteAdjustedWrvus.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(previousRangeTrendCfteAdjustedWrvus, this.NUMBER_OF_DATA_POINTS, 0) :
        previousRangeTrendCfteAdjustedWrvus,
      marker: {
        symbol: ChartMarkerSymbol.SQUARE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };

    const currentBenchmarks = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? createEmptyValues(this.wrvuSelectedRangeTrendData.map(
        wrvu => this.lookupWrvuBenchmarkValue(this.benchmark, wrvu, this.viewCommunityBenchmarks)), this.NUMBER_OF_DATA_POINTS, 0)
      : null;

    const currentBenchmarkRangeBarOptions: BarChartOptions = {
      name: `${this.benchmarkLabel} wRVUs Benchmark ${readableNameOf(this.benchmark, ' percentile')}`,
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.GREY,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 3,
      data: currentBenchmarks,
      marker: {
        symbol: ChartMarkerSymbol.DIAMOND
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };

    const categories = this.wrvuSelectedRangeTrendData.map((visit) => abbreviatedDateLabel(visit.month, visit.year));
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;

    const benchmarksForMax = currentBenchmarks || [0];
    const yAxisMax = currentBenchmarkRangeBarOptions ?
      Math.max(...selectedRangeTrendCfteAdjustedWrvus, ...previousRangeTrendCfteAdjustedWrvus, ...benchmarksForMax)
      : Math.max(...benchmarksForMax);

    return {
      title: getTitleOptions(''),
      series: previousRangeBarOptions
        ? [getBarchartSeriesOption(selectedRangeBarOptions, !this.fromPdfExport),
          getBarchartSeriesOption(previousRangeBarOptions, !this.fromPdfExport),
          getBarchartSeriesOption(currentBenchmarkRangeBarOptions, !this.fromPdfExport)]
        : [getBarchartSeriesOption(selectedRangeBarOptions, !this.fromPdfExport),
          getBarchartSeriesOption(currentBenchmarkRangeBarOptions, !this.fromPdfExport)],
      legend: getLegendOptions(false),
      tooltip: {
        shared: true,
        valueDecimals: 0
      },
      chart: {
        ...getChartOptions(chartType.Line, backgroundColor.Transparent, this.chartWidth, this.OVERVIEW_CHART_HEIGHT),
        animation: !this.fromPdfExport
      },
      xAxis: [getXaxisOptions(categories, isScrolled, DEFAULT_MAX_NUMBER_SCROLLBARS)],
      yAxis: getYAxisOption('', yAxisMax, FormatOption.Number),
      showFullScrollbar: false,
      enableScrollbar: isScrolled,
      legends: []
    };
  }

  populateEmTile() {
    const nodePath: string = this.ngRedux.getState().filters.nodePath;
    const isCustom: boolean = this.ngRedux.getState().display.isCustom;
    const multipleProviders = this.ngRedux.getState().display.multipleProviders;
    const multipleSpecialties = this.ngRedux.getState().display.multipleSpecialties;
    const multipleDepartments = this.ngRedux.getState().display.multipleDepartments;
    this.emTitle = isCustom ? 'Evaluation & Management' : 'Evaluation & Management ' + getLevelFromNodePath(
      nodePath, multipleProviders, multipleSpecialties, multipleDepartments);
  }

  getNpvRatio(npvMonthData: NewPatientVisitTrendEntry[], previous = false): string {
    let npvCount = 0;
    let npvTotalCount = 0;
    npvMonthData.forEach((data: NewPatientVisitTrendEntry) => {
      npvCount += checkForNulls(previous ? data.previous.countOfNewPatientVisits : data.countOfNewPatientVisits);
      npvTotalCount += checkForNulls(previous ? data.previous.countOfTotalPatientVisits : data.countOfTotalPatientVisits);
    });
    return `${formatNumberToWholeNumber(npvCount)} / ${formatNumberToWholeNumber(npvTotalCount)}`;
  }

  private lookupWrvuBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    monthData: MonthProductivityEntry,
    viewCommunityBenchmarks: boolean
  ): number {
    let benchmarkValue = 0;
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark25thPercentile
          : monthData.benchmark25thPercentile;
        break;
      case BenchmarkPercentile.Percentile50th:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark50thPercentile
          : monthData.benchmark50thPercentile;
        break;
      case BenchmarkPercentile.Percentile75th:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark75thPercentile
          : monthData.benchmark75thPercentile;
        break;
      case BenchmarkPercentile.Percentile90th:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark90thPercentile
          : monthData.benchmark90thPercentile;
        break;
      case BenchmarkPercentile.Mean:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmarkMean
          : monthData.benchmarkMean;
        break;
      case BenchmarkPercentile.Percentile65th:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark65thPercentile
          : monthData.benchmark65thPercentile;
        break;
      default:
        return 0;
    }
    return checkForNulls(benchmarkValue);
  }

  private lookupWrvuSummaryBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    trendData: MergedProductivityTrendEntry[],
    viewCommunityBenchmark: boolean,
    previous = false
  ): number {
    return viewCommunityBenchmark ?
      this.getWrvuCommunityBenchmarkValue(trendData, benchmarkPercentile, previous) :
      this.getWrvuBenchmarkValue(trendData, benchmarkPercentile, previous);
  }

  private lookupNPVSummaryBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    monthData: NewPatientVisitTrendEntry[],
    benchmarkOption: BenchmarkOption,
    previous = false
  ): number {
    return this.getNpvBenchmarkValueByBenchmarkOption(
      benchmarkPercentile,
      monthData,
      previous,
      benchmarkOption);
  }

  private getNpvBenchmarkValueByBenchmarkOption(
    benchmarkPercentile: BenchmarkPercentile,
    monthData: NewPatientVisitTrendEntry[],
    previous: boolean,
    benchmarkOption: BenchmarkOption): number {
    return this.getNpvBenchmarkValueForOption(monthData, benchmarkPercentile, benchmarkOption, previous);
  }

  private lookupNPVBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    monthData: NewPatientVisitTrendEntry,
    benchmarkOption: BenchmarkOption): number {
    const benchmarkValue = monthData[getDesignatedNpvBenchmarkObject(benchmarkOption)] ?
      monthData[getDesignatedNpvBenchmarkObject(benchmarkOption)][`benchmark${readableNameOfColumnDef(benchmarkPercentile)}`] : '-';
    return checkForNulls(benchmarkValue);
  }

  private lookupWrvuVarianceValue(
    benchmarkPercentile: BenchmarkPercentile,
    trendData: MergedProductivityTrendEntry[],
    viewCommunityBenchmarks: boolean
  ): number {
    return viewCommunityBenchmarks ? this.getWrvuCommunityVarianceValue(trendData, benchmarkPercentile) :
      this.getWrvuVarianceValue(trendData, benchmarkPercentile);
  }

  getCfteAdjustedWrvus(wrvuTrendData: MergedProductivityTrendEntry[], previous = false): number {
    let cfteAdjustedWrvus = 0;
    wrvuTrendData.forEach((data: MergedProductivityTrendEntry) => {
      cfteAdjustedWrvus = cfteAdjustedWrvus + checkForNulls(previous ? data.previousCfteAdjustedWRVUs : data.cfteAdjustedWRVUs);
    });
    return cfteAdjustedWrvus;
  }

  getWrvus(wrvuTrendData: MergedProductivityTrendEntry[], previous = false): number {
    let wrvus = 0;
    wrvuTrendData.forEach((data: MergedProductivityTrendEntry) => {
      wrvus = wrvus + checkForNulls(previous ? data.previousWrvus : data.wRVUs);
    });
    return wrvus;
  }

  getWrvuBenchmarkValue(wrvuTrendData: MergedProductivityTrendEntry[], benchmarkPercentile: BenchmarkPercentile, previous = false): number {
    let benchmarkValue = 0;
    const benchmarkPrefix = previous ? 'previousBenchmark' : 'benchmark';
    const readableBenchmarkPercentile = readableNameOf(benchmarkPercentile);
    const benchmarkSuffix = benchmarkPercentile === BenchmarkPercentile.Mean ? '' : 'Percentile';
    const benchmarkKey = `${benchmarkPrefix}${readableBenchmarkPercentile}${benchmarkSuffix}`;
    wrvuTrendData.forEach((data: MergedProductivityTrendEntry) => {
      // @ts-ignore
      benchmarkValue = benchmarkValue + checkForNulls(data[benchmarkKey]);
    });
    return benchmarkValue;
  }

  getWrvuCommunityBenchmarkValue(wrvuTrendData: MergedProductivityTrendEntry[], benchmarkPercentile: BenchmarkPercentile, previous = false)
    : number {
    let benchmarkValue = 0;
    const benchmarkPrefix = previous ? 'previousCommunityBenchmark' : 'communityBenchmark';
    const readableBenchmarkPercentile = readableNameOf(benchmarkPercentile);
    const benchmarkSuffix = benchmarkPercentile === BenchmarkPercentile.Mean ? '' : 'Percentile';
    const benchmarkKey = `${benchmarkPrefix}${readableBenchmarkPercentile}${benchmarkSuffix}`;
    wrvuTrendData.forEach((data: MergedProductivityTrendEntry) => {
      // @ts-ignore
      benchmarkValue = benchmarkValue + checkForNulls(data[benchmarkKey]);
    });
    return benchmarkValue;
  }

  getWrvuVarianceValue(wrvuTrendData: MergedProductivityTrendEntry[], benchmarkPercentile: BenchmarkPercentile): number {
    let varianceValue = 0;
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance25thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile50th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance50thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile75th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance75thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile90th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance90thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Mean:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.varianceMean || 0);
        });
        break;
      case BenchmarkPercentile.Percentile65th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance65thPercentile || 0);
        });
        break;
      default:
        varianceValue = 0;
    }
    return checkForNulls(varianceValue);
  }

  getWrvuCommunityVarianceValue(wrvuTrendData: MergedProductivityTrendEntry[], benchmarkPercentile: BenchmarkPercentile): number {
    let varianceValue = 0;
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance25thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile50th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance50thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile75th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance75thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile90th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance90thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile65th:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance65thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Mean:
      default:
        wrvuTrendData.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVarianceMean || 0);
        });
        break;
    }
    return checkForNulls(varianceValue);
  }

  getNpvPercentage(npvMonthData: NewPatientVisitTrendEntry[], previous = false): number {
    let npvCount = 0;
    let npvTotalCount = 0;
    npvMonthData.forEach((data: NewPatientVisitTrendEntry) => {
      npvCount = npvCount + checkForNulls(previous ? data.previous.countOfNewPatientVisits : data.countOfNewPatientVisits);
      npvTotalCount = npvTotalCount + checkForNulls(previous ? data.previous.countOfTotalPatientVisits : data.countOfTotalPatientVisits);
    });
    return npvTotalCount === 0 ? 0 : (npvCount * 10000 / npvTotalCount) / 100;
  }

  private getNpvBenchmarkValueForOption(npvMonthData: NewPatientVisitTrendEntry[],
                                        benchmarkPercentile: BenchmarkPercentile,
                                        benchmarkOption: BenchmarkOption,
                                        previous = false): number {
    let npvBenchmarkData: NpvBenchmarkData;
    npvBenchmarkData = this.npvBenchmarkData(npvMonthData, previous, getDesignatedNpvBenchmarkObject(benchmarkOption),
      `benchmark${readableNameOfColumnDef(benchmarkPercentile)}`);
    return npvBenchmarkData.totalNPV === 0 ? 0 : npvBenchmarkData.benchmarkValue / npvBenchmarkData.totalNPV;
  }

  private npvBenchmarkData(npvMonthData: NewPatientVisitTrendEntry[], previous: boolean,
                           benchmarkObject: string, benchmarkKey: string): NpvBenchmarkData {
    const npvBenchmarkData: NpvBenchmarkData = {benchmarkValue: 0, totalNPV: 0};
    npvMonthData.forEach((data: NewPatientVisitTrendEntry) => {
      npvBenchmarkData.benchmarkValue += checkForNulls(previous ? data['previous']['countOfTotalPatientVisits'] :
        data['countOfTotalPatientVisits']) * checkForNulls(previous ? this.getPreviousBenchmarkUnits(data, benchmarkObject, benchmarkKey) :
        this.getCurrentBenchmarkUnits(data, benchmarkObject, benchmarkKey));
      npvBenchmarkData.totalNPV += checkForNulls(previous ? data['previous']['countOfTotalPatientVisits'] :
        data['countOfTotalPatientVisits']);
    });
    return npvBenchmarkData;
  }

  private getPreviousBenchmarkUnits(data: NewPatientVisitTrendEntry, benchmarkObject: string, benchmarkKey: string) {
    return data && data['previous'] && data['previous'][benchmarkObject] ? data['previous'][benchmarkObject][benchmarkKey] : '-';
  }

  private getCurrentBenchmarkUnits(data: NewPatientVisitTrendEntry, benchmarkObject: string, benchmarkKey: string) {
    return data && data[benchmarkObject] ? data[benchmarkObject][benchmarkKey] : 0;
  }
}
