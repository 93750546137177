import {AfterViewChecked, Component, ElementRef, Inject, ViewEncapsulation} from '@angular/core';
import {DialogPosition, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {getAppConfigValue} from '../shared/helpers';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {fromEvent, noop} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertDialogComponent implements AfterViewChecked {

  alertsModalUrl: string;
  private positionRelativeToElement: ElementRef;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              public dialogRef: MatDialogRef<AlertDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public options: { positionRelativeToElement: ElementRef }) {
    this.positionRelativeToElement = options.positionRelativeToElement;

    const state = this.ngRedux.getState();
    this.alertsModalUrl = getAppConfigValue(AppConfigEntries.NOTIFICATION_ALERT_URL, state.data.applicationConfigurationSettings);

    fromEvent(window, 'resize')
      .pipe(takeUntil(dialogRef.afterClosed()))
      .subscribe(noop);
  }

  ngAfterViewChecked() {
    if (this.positionRelativeToElement) {
      const rect: DOMRect = this.positionRelativeToElement.nativeElement.getBoundingClientRect();
      const position: DialogPosition = {left: `${rect.left}px`, top: `${rect.bottom + 2}px`};
      this.dialogRef.updatePosition(position);
    }
  }
}
