
<div [ngClass]="tableCssClass ? tableCssClass : 'data-table-wrapper'">
  <div class="data-table-container">
    <div id="title">{{tableTitle}}
    </div>
    <span class="right-table-header">
       <app-zero-suppression-component
         *ngIf="displayZeroSuppression"
         [isZeroSuppressionChecked]="isZeroSuppressionChecked"
         [condition]="zeroSuppressionCondition"
         [countOfSuppressedEntries]="countOfSuppressedEntries"
         [snapshotEntryName]="snapshotEntryName"
       >
       </app-zero-suppression-component>
    </span>
    <mat-table [dataSource]="dataSource"
               matSort
               matSortDirection="{{sortDirection}}"
               matSortActive="{{defaultSortColumn}}">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <mat-header-cell *matHeaderCellDef
                         mat-sort-header
                         id="{{column.columnDef}}"
                         class="{{column.columnDef}}Header
                                {{column.group ? column.group + 'Header' : ''}}
                                {{column.columnDef === defaultSortColumn && sort.direction ? 'isWhatTableIsSortedBy' : ''}}
                                {{column.primaryColumn ? 'primaryHeader' : ''}}">
          <br/>
          <span class="header-text">{{column.header}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="{{column.columnDef}} {{column.group || ''}}
                         {{column.primaryColumn ? 'primaryCell' : ''}} {{getCssClassForColumnType(column.columnType)}}
                         {{column.class ? column.class(row) : ''}}" (mouseenter)="rangeHover = true;"
                  (mouseleave)="rangeHover = false">
          <div *ngIf="column.upperDataName" class="upper-data-text">{{ column.upperDataName(row) }}</div>
          <div #refEl class="data-text" [matTooltipDisabled]="!canShowTooltip(refEl)"
               [matTooltip]="column.dataName(row)" [matTooltipPosition]="'below'">
            {{ column.dataName(row) }} </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
      <ng-container *ngIf="!fromPdfExport">
        <mat-row (click)="selectRow(row)" *matRowDef="let row; columns: displayedColumns"
          class="{{rowSelectionCallback ? 'selectable' : ''}}"></mat-row>
      </ng-container>
      <ng-container *ngIf="fromPdfExport">
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="pdf-row"></mat-row>
      </ng-container>
    </mat-table>
    <mat-paginator [class.hide-paginator]="!paginate"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   [showFirstLastButtons]="true"
                   (page)="updateDataTable($event)"></mat-paginator>
    <app-loader id="loader" *ngIf="showProgressBar"></app-loader>
  </div>
</div>
