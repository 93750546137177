import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {ProviderProductivityMultiLevelResponse} from './ProviderProductivity';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.local';
import {FilterCriteria} from '../../store/IAppState';
import {DatasetType} from '../../shared/enums';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const ProviderProductivityServiceToken = new InjectionToken<ProviderProductivityService>(
  'Provider Productivity Service');

export interface ProviderProductivityService {
  getMultiLevelProductivities(filterCriteria: FilterCriteria, dataset: DatasetType): Observable<ProviderProductivityMultiLevelResponse>;
}


@Injectable()
export class HttpProviderProductivityService implements ProviderProductivityService {
  constructor(private httpClient: HttpClient) {}

  getMultiLevelProductivities(filterCriteria: FilterCriteria, dataset: DatasetType):
    Observable<ProviderProductivityMultiLevelResponse> {
    const baseUrl = `${environment.api}/api/providerProductivitiesByMultiLevelNodePath`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<ProviderProductivityMultiLevelResponse>(
      baseUrl,
      postBody,
      { params: new HttpParams()
          .set('memberKey', filterCriteria.memberKey.toString())
          .set('dataset', dataset.toString())
      },
    );
  }
}
