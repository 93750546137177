import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NgRedux} from '@angular-redux/store';
import {BaseColumn, IAppState} from '../store/IAppState';
import {getAppConfigValue, getLevel, getPreviousYearDateRange} from '../shared/helpers';
import {FeatureToggleSetting, OntologyNode, SortingCriterion} from '../shared/models';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {DataTableColumns, filterOutCfteAdjWrvuRelatedColumns} from '../shared/data-table-columns';
import {ColumnType, MultilevelTab, MultilevelTabCollections, SortingOrder} from '../shared/enums';
import {hasValue} from '../shared/null-helpers';
import {AppAction, collectionsChosenTabChangedTo} from '../store/actions';
import {getOntologyData} from '../shared/localStoragehelper';
import {MatchedCollectionsByMultiLevelByNodePath} from '../collections/Collection';
import {DenialPayerEntity} from '../denials/denials-models';
import {filterOutBenchmarkRelatedColumns} from '../shared/BenchmarkColumns';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent<T> implements OnChanges {
  @Input() tableTitle: string;
  @Input() receivedData: any[];
  @Input() defaultSortColumn: string;
  @Input() displayedColumns: DataTableColumns[] = [];
  @Input() originalColumns: DataTableColumns[] = [];
  @Input() scrollableColumns: DataTableColumns[] = [];
  @Input() openWindowColumns: DataTableColumns[] = [];
  @Input() sortDirection: string;
  @Input() whenSortChanged: (sortedRows: any) => void;
  @Input() tieBreakerProperty?: (element: T) => string;
  @Input() rowSelectionCallback?: (element: T) => void;
  @Input() showLevel = true;
  @Input() showProgressBar = false;
  @Input() sortingDataAccessor?: any;
  @Input() paginate = true;
  @Input() addRemoveOption = false;
  @Input() tableCssClass: string;
  @Input() outerWrapperClass: string;
  @Input() showPayerOptions = false;
  @Input() displayZeroSuppression: boolean;
  @Input() isZeroSuppressionChecked: boolean;
  @Input() countOfSuppressedEntries: number;
  @Input() currentPage: string;
  @Input() multiLevel: true;
  @Input() zeroSuppressionCondition: string;
  @Input() zeroSuppressionSnapshotEntry: string;
  @Input() displayMessages = [{
    columnDef: '',
    desc: ''
  }];
  @Input() fromPdfExport = false;
  @Input() collectionsTab = false;
  @Input() displayAboveHeaders = false;
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion) => void;
  @Input() shouldPutNullsAtTheBottom = false;
  @Input() reducerAction: (columns: BaseColumn[]) => AppAction;
  @Input() mandatoryOpaqueSortingArrow = false;
  @Input() chosen: MultilevelTab | MultilevelTabCollections;
  @Input() initialPageSize = 25;
  @Input() isSpecialtyPerformance = false;
  @Input() canShowBenchmarks = true;
  @Input() canShowCfteAdjWrvus = true;

  @Output() pageEvent = new EventEmitter<PageEvent>();

  @Output() currentTab = new EventEmitter<MultilevelTabCollections>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ColumnType = ColumnType;
  pageSize = this.initialPageSize;
  pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
  dataSource: MatTableDataSource<any>;
  pageIndex = 0;
  length: number;
  displayedColumnDefs: string[];
  displayedScrollableColumns: string[];
  displayedOpenWindowColumns: string[];
  sortChangeSubscribed = false;
  level: string;
  previousDate: string;
  settings: FeatureToggleSetting[];
  shouldShowHoverMessage = false;
  parentNodeNames: string;
  ontology: OntologyNode[];
  MultilevelTabCollections = MultilevelTabCollections;
  // For the view cpt component
  showViewCpts = false;
  viewCptNodePath = '';
  viewCptNodeName = '';
  // For the denials component
  showDenials = false;
  showCPTsDenied = false;
  denialRow: MatchedCollectionsByMultiLevelByNodePath | undefined = undefined;
  cptsDeniedRow: DenialPayerEntity | undefined = undefined;
  dataTableDisplayedColumns: DataTableColumns[] = [];
  dataTableDisplayedColumnDefs: string[] = [];


  constructor(private readonly ngRedux: NgRedux<IAppState>, private cdr: ChangeDetectorRef,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService
  ) {
  }

  ngOnChanges(changes?: SimpleChanges) {
    this.dataTableDisplayedColumns = this.canShowBenchmarks
      ? this.displayedColumns
      : filterOutBenchmarkRelatedColumns(this.displayedColumns);
    this.dataTableDisplayedColumns = this.canShowCfteAdjWrvus
      ? this.dataTableDisplayedColumns
      : filterOutCfteAdjWrvuRelatedColumns(this.dataTableDisplayedColumns);
    this.dataTableDisplayedColumnDefs = this.dataTableDisplayedColumns.map(col => col.columnDef);
    if (changes && changes['initialPageSize']?.firstChange && this.paginate) {
      this.pageSize = this.initialPageSize;
      this.pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
    }

    const isCustom: boolean = this.ngRedux.getState().display.isCustom;
    const multipleProviders = this.ngRedux.getState().display.multipleProviders;
    const multipleSpecialties = this.ngRedux.getState().display.multipleSpecialties;
    const multipleDepartments = this.ngRedux.getState().display.multipleDepartments;
    const nodePath = this.ngRedux.getState().filters.nodePath;

    this.level = this.showLevel ? getLevel(isCustom, nodePath, multipleProviders, multipleSpecialties,
      multipleDepartments) : '';
    this.previousDate = getPreviousYearDateRange(this.ngRedux.getState().filters.dateRange);
    if (this.receivedData) {
      if (this.isZeroSuppressionChecked) {
        this.returnToFirstPage();
      }
      this.dataSource =
        new MatTableDataSource<any>(this.defaultSortColumn ? this.receivedData.slice().sort(
            (a: any, b: any) => b[this.defaultSortColumn] - a[this.defaultSortColumn]).filter(x => !x.isHidden) :
          this.receivedData.slice().filter(x => !x.isHidden));
      this.settings = this.ngRedux.getState().data.featureToggleSettings;
      if (this.showViewCpts) {
        this.receivedData.filter(row => row.cptActive).forEach(datum => {
          datum.cptActive = true;
        });
      } else {
        this.receivedData.filter(row => row.cptActive).forEach(datum => {
          datum.cptActive = false;
        });
      }

      if (this.sortingDataAccessor) {
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
      }

      this.displayedScrollableColumns = this.scrollableColumns.map(x => x.columnDef);
      this.displayedOpenWindowColumns = this.openWindowColumns.map(x => x.columnDef);

      this.displayedColumnDefs = this.displayedColumns.map(x => x.columnDef);
      this.dataSource.sortData = (data: T[], sort: MatSort): T[] => {
        const active = changes?.defaultSortColumn ? this.defaultSortColumn : sort.active;
        const direction = sort.direction;
        if (!active || direction === '') {
          return data;
        }

        return data.sort((a, b) => {
          return this.compare(a, b, active, direction === 'asc');
        });
      };

      if (this.whenSortChanged) {
        this.whenSortChanged(this.dataSource.sortData(this.dataSource.data, this.sort));
      }

      if (this.paginate) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize = this.pageSize;
        this.dataSource.paginator.pageIndex = this.pageIndex;
        this.dataSource.paginator.length = this.receivedData.length;
      }

      this.dataSource.sort = this.sort;
      if (!this.sortChangeSubscribed) {
        this.sortChangeSubscribed = true;
        this.dataSource.sort.sortChange.subscribe(() => {
          if (this.whenSortChanged) {
            const sortingCriteria: SortingCriterion = {
              sortingOrder: this.sort.direction === SortingOrder.ASCENDING ? SortingOrder.ASCENDING : SortingOrder.DESCENDING,
              columnDef: this.sort.active
            };
            this.whenSortingCriteriaChanged(sortingCriteria);
            this.whenSortChanged(this.dataSource.sortData(this.dataSource.data, this.sort));
          }
        });
      }
    }
    const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
  }

  returnToFirstPage() {
    this.pageIndex = 0;
  }

  whenSortingCriteriaChanged(sortingCriteria: SortingCriterion): void {
    return this.updateSortingCriteria && this.updateSortingCriteria(sortingCriteria);
  }

  toggleShowHoverMessage(show: boolean, column: DataTableColumns): void {
    this.deactivateHoverMessages();
    this.shouldShowHoverMessage = false;
    if (column.hoverMessage) {
      column.showHoverMessage = show;
      this.shouldShowHoverMessage = show;
    }
  }

  deactivateHoverMessages(): void {
    this.shouldShowHoverMessage = false;
    this.displayedColumns.forEach(col => {
      if (col.hoverMessage) {
        col.showHoverMessage = false;
      }
    });
  }

  openMethodologyDocument() {
    const appConfigSettings = this.ngRedux.getState().data.applicationConfigurationSettings;
    window.open(getAppConfigValue(AppConfigEntries.METHODOLOGY_DOCUMENT, appConfigSettings));
    this.analyticsService.handleGoogleAnalytics('Help Docs', 'Help Documentation Click', 'Methodology Document');
  }

  compare(a: T, b: T, sortHeaderId: string, sortAscending: boolean): number {
    let valueA = this.dataSource.sortingDataAccessor(a, sortHeaderId);
    let valueB = this.dataSource.sortingDataAccessor(b, sortHeaderId);
    const multiplier = sortAscending ? 1 : -1;
    if (this.shouldPutNullsAtTheBottom) {
      if (!hasValue(valueB) && hasValue(valueA)) {
        return -1;
      } else if (hasValue(valueB) && !hasValue(valueA)) {
        return 1;
      }
    }

    if (typeof valueA === 'number' || typeof valueB === 'number') {
      valueA = valueA === undefined ? 0 : valueA;
      valueB = valueB === undefined ? 0 : valueB;
    }
    if (valueA > valueB) {
      return multiplier;
    }
    if (valueA < valueB) {
      return -1 * multiplier;
    }
    return this.getTieBreakerString(a).localeCompare(this.getTieBreakerString(b));
  }

  getTieBreakerString(item: T): string {
    return this.tieBreakerProperty ? this.tieBreakerProperty(item) : '';
  }

  updateDataTable(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageEvent.emit(event);
  }

  activateCptDescription(event: MouseEvent, nodePath: string, nodeName: string) {
    this.analyticsService.handleGoogleAnalytics('Cpt Analysis', 'View Cpt', 'View Cpt');
    const paths = nodePath.split('\\');
    this.ontology = getOntologyData().ontologyHierarchy;
    const departmentNode = this.ontology[0].children.find(x => paths[2] === x.nodePath.split('\\').pop());
    const departmentName = departmentNode && departmentNode.nodeName;
    const specialtyNode = departmentNode && departmentNode.children.find(x => paths[3] === x.nodePath.split('\\').pop());
    const specialtyName = specialtyNode && specialtyNode.nodeName;
    this.parentNodeNames = departmentName + ', ' + specialtyName;
    this.receivedData.forEach(datum => {
      datum.cptActive = datum.nodePath && datum.nodePath === nodePath;
    });
    event.stopPropagation();
    this.viewCptNodePath = nodePath;
    this.viewCptNodeName = nodeName;
    this.showViewCpts = true;
  }

  activateDenialsModal(event: MouseEvent, nodePath: string, nodeName: string, row: MatchedCollectionsByMultiLevelByNodePath) {
    this.analyticsService.handleGoogleAnalytics('Cpt Analysis', 'View Denials', 'View Denials');
    this.viewCptNodePath = nodePath;
    this.viewCptNodeName = nodeName;
    this.showDenials = true;
    this.denialRow = row;
  }

  activateCPTsDeniedModal(event: MouseEvent, nodePath: string, nodeName: string, row: DenialPayerEntity) {
    this.analyticsService.handleGoogleAnalytics('Cpt Analysis', 'View CPTs Denied', 'View CPTs Denied');
    this.viewCptNodePath = nodePath;
    this.viewCptNodeName = nodeName;
    this.showCPTsDenied = true;
    this.cptsDeniedRow = row;
  }

  getCssClassForColumnType(column: any): string {
    switch (column.columnType) {
      case ColumnType.NODE_NAME:
        return 'nodeName';
      case ColumnType.BENCHMARK:
        return 'benchmarkCell';
      case ColumnType.VARIANCE:
        return 'varianceCell';
      case ColumnType.IMPUTED:
        return 'imputedCell';
      default:
        return '';
    }
  }

  deactivateCptDescription(event: boolean) {
    if (event) {
      this.showViewCpts = false;
    }
    this.receivedData.filter(row => row.cptActive).forEach(datum => {
      datum.cptActive = false;
    });
  }

  deactivateDenialsModal(event: boolean) {
    if (event) {
      this.showDenials = false;
    }
  }

  chooseTab(tab: MultilevelTabCollections): void {
    this.currentTab.emit(tab);
    this.ngRedux.dispatch(collectionsChosenTabChangedTo(tab));
  }

  selectRow(selectedRow: T) {
    return this.rowSelectionCallback && this.rowSelectionCallback(selectedRow);
  }
}
