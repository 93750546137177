import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RelatableSelectableItem, UpdatedFilterTab} from '../FilterBannerModels';
import {VisitType} from '../../shared/models';
import {determineAllSelected} from '../UpdatedFilterBannerHelpers';
import {DEFAULT_TELEHEALTH_FLAG} from '../../store/DefaultValues';

@Component({
  selector: 'app-visit-type',
  templateUrl: './visit-type.component.html',
  styleUrls: ['./visit-type.component.scss']
})
export class VisitTypeComponent implements OnInit {

  @Input() visitTypes: RelatableSelectableItem<VisitType>[];
  @Input() onVisitTypeChanged: (tab?: UpdatedFilterTab, item?: RelatableSelectableItem<VisitType>) => void;
  @Input() filterTab: UpdatedFilterTab;
  allSelected = true;
  readonly DEFAULT_TELEHEALTH_FLAG = DEFAULT_TELEHEALTH_FLAG;

  constructor(protected cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.allSelected = determineAllSelected(this.visitTypes);
  }

  selectAllActivity(event: MouseEvent): void {
    event.preventDefault();
    if (!this.allSelected) {
      let wasSelected: boolean;
      this.visitTypes.forEach(visit => {
        wasSelected = visit.item.selected;
        visit.item.selected = false;
        if (this.filterTab.newSelections && wasSelected) {
          if (visit.item.originallySelected) {
            this.filterTab.newSelections.deselections++;
          } else {
            this.filterTab.newSelections.selections--;
          }
        }
      });
      this.allSelected = true;
      this.onVisitTypeChanged(this.filterTab);
    }
  }

  whenVisitTypeClicked(visit: RelatableSelectableItem<VisitType>, event: MouseEvent): void {
    event.preventDefault();
    if (visit.item.selected) {
      return;
    }
    this.allSelected = false;
    let shouldBeSelected: boolean;
    let wasSelected: boolean;
    this.visitTypes.forEach(vt => {
      shouldBeSelected = vt.item.key === visit.item.key;
      wasSelected = vt.item.selected;
      vt.item.selected = shouldBeSelected;
      if (shouldBeSelected && this.filterTab.newSelections) {
        if (!vt.item.originallySelected) {
          this.filterTab.newSelections.selections++;
        } else {
          this.filterTab.newSelections.deselections--;
        }
      } else if (!shouldBeSelected && this.filterTab.newSelections && wasSelected) {
        if (vt.item.originallySelected) {
          this.filterTab.newSelections.deselections++;
        } else {
          this.filterTab.newSelections.selections--;
        }
      }
    });
    this.onVisitTypeChanged(this.filterTab, visit);
    this.cdr.detectChanges();
  }
}
