<div id="lag-component-wrapper">
  <div id="select-lag-key-header">
    <div id="upper-header">Lag Period</div>
    <div class="note">Note: Lag Period is only applicable to <b>Collections</b> and <b>Denials</b>.</div>
    <div>Note: The NCR benchmarks represent a full calendar year. To assess your performance to these
      benchmarks, we recommend you look at invoices that have aged at least 6 months.
    </div>
  </div>
  <div id="select-lag-key-body">
    <mat-radio-group id="lagPeriod-options">
      <mat-radio-button [value]="allActivity.key"
                        [checked]="lagPeriod === allActivity"
                        [class.active]="lagPeriod === allActivity"
                        [ngClass]="'allActivity-option'"
                        (click)="selectLagPeriod(allActivity, $event)" id="{{allActivity.name}}">
        {{allActivity.name}}
      </mat-radio-button>
      <mat-radio-button *ngFor="let lag of lagPeriods"
                         [value]="lag.key"
                        [class.active]="lag === lagPeriod"
                        [checked]="lag === lagPeriod"
                        [ngClass]="'lagPeriod-option'"
                        (click)="selectLagPeriod(lag, $event)" id="{{lag.name}}">
        {{lag.name}}
      </mat-radio-button>
    </mat-radio-group>

  </div>
</div>
