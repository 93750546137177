import {Component, OnDestroy, OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {select} from '@angular-redux/store';
import {INITIAL_STATE, SummaryData} from '../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {getRecentMonth, getSelectedDateRange} from '../../shared/helpers';
import {formatNumberToWholeNumber, roundToWithCommasSeparation} from '../number-formatter';
import {ProductivitySummary} from '../services/ProviderProductivity';
import * as _ from 'lodash';
import {isValid} from '../../shared/null-helpers';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {columnsForWrvuSummary, DataTableColumns} from '../../shared/data-table-columns';
import {BenchmarkPercentilesForWrvu} from '../../shared/BenchmarkColumns';

@Component({
  selector: 'app-wrvu-summary-data-table',
  templateUrl: './wrvu-summary-data-table.component.html',
  styleUrls: ['./wrvu-summary-data-table.component.scss']
})
export class WrvuSummaryDataTableComponent implements OnInit, OnDestroy {

  constructor() {
  }

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['data', 'productivitySummaryData'])
  private readonly productivitySummaryData$: Observable<SummaryData<ProductivitySummary>>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @Input() canShowBenchmarks = true;
  @Input() canShowCfteAdjWrvus = true;

  dataSource: MatTableDataSource<any>;
  benchmarkPercentile: BenchmarkPercentile;
  data: ProductivitySummaryData[];
  dateRange: string;
  previousDateRange: string;
  recentMonthDate: string;
  previousYearRecentMonthDate: string;
  ytdDateRange: string;
  previousYtdDateRange: string;
  columns = columnsForWrvuSummary;
  displayedColumns = this.columns.map(col => col.columnDef);
  summaryProductivityData: SummaryData<ProductivitySummary>;
  showProgressBar = true;
  viewCommunityBenchmarks: boolean;
  wrvuSummaryDataSubscription: Subscription;

  ngOnInit() {
    this.wrvuSummaryDataSubscription =
      combineLatest([this.benchmarkPercentile$, this.productivitySummaryData$, this.viewCommunityBenchmarks$])
      .subscribe((
        [benchmarkPercentile, summaryData, viewCommunityBenchmarks]:
          [BenchmarkPercentile, SummaryData<ProductivitySummary>, boolean]) => {
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.benchmarkPercentile = benchmarkPercentile;
        this.summaryProductivityData = summaryData;
        this.data = [];
        this.populateDateRanges();
        if (this.summaryProductivityData) {
          this.data = populateWrvuSummaryData(
            this.viewCommunityBenchmarks,
            this.benchmarkPercentile,
            this.summaryProductivityData,
            this.canShowBenchmarks,
            this.canShowCfteAdjWrvus);
        }
        this.showProgressBar = _.isEqual(this.summaryProductivityData, INITIAL_STATE.data.productivitySummaryData);
        this.dataSource = new MatTableDataSource<any>(this.data.slice());
      });
  }

  ngOnDestroy(): void {
    this.wrvuSummaryDataSubscription?.unsubscribe();
  }

  populateDateRanges() {
    this.dateRange = this.getDateRange(this.summaryProductivityData.selectedDateRange);
    this.assignDateStringToColumn(this.columns, 'metricSelectedDateRange', this.dateRange);
    this.previousDateRange = this.getDateRange(this.summaryProductivityData.previousYearSelectedDateRange);
    this.assignDateStringToColumn(this.columns, 'metricPreviousYearSelectedDateRange', this.previousDateRange);
    this.recentMonthDate = this.getRecentMonth(this.summaryProductivityData.recentMonth);
    this.assignDateStringToColumn(this.columns, 'metricRecentMonth', this.recentMonthDate);
    this.previousYearRecentMonthDate = this.getRecentMonth(this.summaryProductivityData.previousYearRecentMonth);
    this.assignDateStringToColumn(this.columns, 'metricPreviousYearRecentMonth', this.previousYearRecentMonthDate);
    this.ytdDateRange = this.getDateRange(this.summaryProductivityData.yearToDate);
    this.assignDateStringToColumn(this.columns, 'metricYearToDate', this.ytdDateRange);
    this.previousYtdDateRange = this.getDateRange(this.summaryProductivityData.previousYearToDate);
    this.assignDateStringToColumn(this.columns, 'metricPreviousYearToDate', this.previousYtdDateRange);
  }

  private assignDateStringToColumn(columns: DataTableColumns[], columnDef: string, dateString: string): void {
    const column = columns.find(col => col.columnDef === columnDef);
    if (column) {
      column.upperHeader = dateString;
    }
  }

  getClassForColumnHeader(column: DataTableColumns): string {
    const baseName = column.columnDef.concat('Header');
    return column.primaryColumn ? baseName.concat(' bold') : baseName;
  }

  getDateRange(productivitySummary: ProductivitySummary): string {
    if (!productivitySummary) {
      return '';
    }
    return getSelectedDateRange({
      endMonth: productivitySummary.endMonth,
      endYear: productivitySummary.endYear,
      startMonth: productivitySummary.startMonth,
      startYear: productivitySummary.startYear
    });
  }

  getRecentMonth(productivitySummary: ProductivitySummary): string {
    if (!productivitySummary) {
      return '';
    }
    return getRecentMonth({
      endMonth: productivitySummary.endMonth,
      endYear: productivitySummary.endYear,
      startMonth: productivitySummary.startMonth,
      startYear: productivitySummary.startYear
    });
  }
}

export function populateWrvuSummaryData(
  viewCommunityBenchmarks: boolean,
  benchmarkPercentile: BenchmarkPercentile,
  summaryProductivityData: SummaryData<ProductivitySummary>,
  showBenchmarks: boolean,
  shouldShowWrvuDepartmentCfteAdjusted: boolean
): ProductivitySummaryData[] {
  const benchmarkSummaryRows = [];
  if (showBenchmarks) {
    if (benchmarkPercentile === BenchmarkPercentile.All) {
      BenchmarkPercentilesForWrvu.forEach(bm => {
        benchmarkSummaryRows.push(getBenchmarkSummaryRow(viewCommunityBenchmarks, bm, summaryProductivityData));
      });
    } else {
      benchmarkSummaryRows.push(getBenchmarkSummaryRow(viewCommunityBenchmarks, benchmarkPercentile, summaryProductivityData));
    }
  }
  const optionalCfteAdjColumn = shouldShowWrvuDepartmentCfteAdjusted
    ? [getSummaryDataByWholeNumber('cFTE Adj. wRVUs', 'cfteAdjustedWRVUs', summaryProductivityData)]
    : [];
  return optionalCfteAdjColumn
    .concat(benchmarkSummaryRows)
    .concat([
      getSummaryDataByWholeNumber('Actual wRVUs', 'wRVUs', summaryProductivityData),
      getSummaryDataByWholeNumber('Charges', 'charges', summaryProductivityData, '$'),
      getSummaryDataByRoundTo('cFTE', 'cfte', 2, summaryProductivityData)
    ]);
}

function getBenchmarkSummaryRow(viewCommunityBenchmarks: boolean, benchmarkPercentile: BenchmarkPercentile,
                                summaryProductivityData: SummaryData<ProductivitySummary>): ProductivitySummaryData {
  let benchmarkTitle: string;
  let benchmarkField: string;
  if (!viewCommunityBenchmarks) {
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Mean :
        benchmarkTitle = 'Academic wRVU Benchmark Mean';
        benchmarkField = 'benchmarkMean';
        break;
      case BenchmarkPercentile.Percentile25th :
        benchmarkTitle = 'Academic wRVU Benchmark 25th Percentile';
        benchmarkField = 'benchmark25thPercentile';
        break;
      case BenchmarkPercentile.Percentile50th :
        benchmarkTitle = 'Academic wRVU Benchmark 50th Percentile';
        benchmarkField = 'benchmark50thPercentile';
        break;
      case BenchmarkPercentile.Percentile65th :
        benchmarkTitle = 'Academic wRVU Benchmark 65th Percentile';
        benchmarkField = 'benchmark65thPercentile';
        break;
      case BenchmarkPercentile.Percentile75th :
        benchmarkTitle = 'Academic wRVU Benchmark 75th Percentile';
        benchmarkField = 'benchmark75thPercentile';
        break;
      case BenchmarkPercentile.Percentile90th :
        benchmarkTitle = 'Academic wRVU Benchmark 90th Percentile';
        benchmarkField = 'benchmark90thPercentile';
        break;
      default:
        benchmarkTitle = 'Academic wRVU Benchmark Mean';
        benchmarkField = 'benchmarkMean';
        break;
    }
    return getSummaryDataByWholeNumber(benchmarkTitle, benchmarkField, summaryProductivityData);
  } else {
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Mean :
        benchmarkTitle = 'Community wRVU Benchmark Mean';
        benchmarkField = 'communityBenchmarkMean';
        break;
      case BenchmarkPercentile.Percentile25th :
        benchmarkTitle = 'Community wRVU Benchmark 25th Percentile';
        benchmarkField = 'communityBenchmark25thPercentile';
        break;
      case BenchmarkPercentile.Percentile50th :
        benchmarkTitle = 'Community wRVU Benchmark 50th Percentile';
        benchmarkField = 'communityBenchmark50thPercentile';
        break;
      case BenchmarkPercentile.Percentile65th :
        benchmarkTitle = 'Community wRVU Benchmark 65th Percentile';
        benchmarkField = 'communityBenchmark65thPercentile';
        break;
      case BenchmarkPercentile.Percentile75th :
        benchmarkTitle = 'Community wRVU Benchmark 75th Percentile';
        benchmarkField = 'communityBenchmark75thPercentile';
        break;
      case BenchmarkPercentile.Percentile90th :
        benchmarkTitle = 'Community wRVU Benchmark 90th Percentile';
        benchmarkField = 'communityBenchmark90thPercentile';
        break;
      default:
        benchmarkTitle = 'Community wRVU Benchmark Mean';
        benchmarkField = 'communityBenchmarkMean';
        break;
    }
    return getSummaryDataByWholeNumber(benchmarkTitle, benchmarkField, summaryProductivityData);
  }
}

function getSummaryDataByRoundTo(title: string, field: string, roundToValue: number, summaryProductivityData:
  SummaryData<ProductivitySummary>): ProductivitySummaryData {
  return {
    metric: title,
    metricSelectedDateRange: isValid(summaryProductivityData.selectedDateRange, field) ?
      roundToWithCommasSeparation(summaryProductivityData.selectedDateRange[field], roundToValue) : '-',
    metricPreviousYearSelectedDateRange: isValid(summaryProductivityData.previousYearSelectedDateRange, field) ?
      roundToWithCommasSeparation(summaryProductivityData.previousYearSelectedDateRange[field], roundToValue) : '-',
    metricYearToDate: isValid(summaryProductivityData.yearToDate, field) ?
      roundToWithCommasSeparation(summaryProductivityData.yearToDate[field], roundToValue) : '-',
    metricPreviousYearToDate: isValid(summaryProductivityData.previousYearToDate, field) ?
      roundToWithCommasSeparation(summaryProductivityData.previousYearToDate[field], roundToValue) : '-',
    metricRecentMonth: isValid(summaryProductivityData.recentMonth, field) ?
      roundToWithCommasSeparation(summaryProductivityData.recentMonth[field], roundToValue) : '-',
    metricPreviousYearRecentMonth: isValid(summaryProductivityData.previousYearRecentMonth, field) ?
      roundToWithCommasSeparation(summaryProductivityData.previousYearRecentMonth[field], roundToValue) : '-'
  };
}

function getSummaryDataByWholeNumber(title: string, field: string, summaryProductivityData:
  SummaryData<ProductivitySummary>, prefix = ''): ProductivitySummaryData {
  return {
    metric: title,
    metricSelectedDateRange: isValid(summaryProductivityData.selectedDateRange, field) ?
      prefix + formatNumberToWholeNumber(summaryProductivityData.selectedDateRange[field]) : '-',
    metricPreviousYearSelectedDateRange: isValid(summaryProductivityData.previousYearSelectedDateRange, field) ?
      prefix + formatNumberToWholeNumber(summaryProductivityData.previousYearSelectedDateRange[field]) : '-',
    metricYearToDate: isValid(summaryProductivityData.yearToDate, field) ?
      prefix + formatNumberToWholeNumber(summaryProductivityData.yearToDate[field]) : '-',
    metricPreviousYearToDate: isValid(summaryProductivityData.previousYearToDate, field) ?
      prefix + formatNumberToWholeNumber(summaryProductivityData.previousYearToDate[field]) : '-',
    metricRecentMonth: isValid(summaryProductivityData.recentMonth, field) ?
      prefix + formatNumberToWholeNumber(summaryProductivityData.recentMonth[field]) : '-',
    metricPreviousYearRecentMonth: isValid(summaryProductivityData.previousYearRecentMonth, field) ?
      prefix + formatNumberToWholeNumber(summaryProductivityData.previousYearRecentMonth[field]) : '-'
  };
}

export interface ProductivitySummaryData {
  metric: string;
  metricSelectedDateRange: string;
  metricPreviousYearSelectedDateRange: string;
  metricYearToDate: string;
  metricPreviousYearToDate: string;
  metricRecentMonth: string;
  metricPreviousYearRecentMonth: string;
}
