import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormControlStatus,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {SchedulePane} from '../mpe-dialog-helper';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {BatchExportServiceToken, HttpBatchExportService} from '../../services/batch-export.service';
import {map, skipWhile} from 'rxjs/operators';
import {select} from '@angular-redux/store';

@Component({
  selector: 'app-report-schedule-pane',
  templateUrl: './report-schedule-pane.component.html',
  styleUrls: ['./report-schedule-pane.component.scss']
})
export class ReportSchedulePaneComponent implements OnInit, OnChanges, OnDestroy {

  @Input() schedulePaneInfo!: SchedulePane;
  @Input() showErrorMessage = false;
  @Output() schedulePaneInfoChange = new EventEmitter<SchedulePane>();
  @Output() validationStatusChange = new EventEmitter<FormControlStatus>();

  @select(['filters', 'memberKey'])
  readonly memberKey$: Observable<number>;

  memberKey: number;
  reportName = new FormControl('', {
    validators: [Validators.required],
    asyncValidators: [this.getDuplicateReportNameValidator()],
    updateOn: 'blur'
  });

  controlStatusChangesSubscription: Subscription;
  memberKeySubscription: Subscription;

  constructor(@Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService) { }

  ngOnInit() {
    this.reportName.setValue(this.schedulePaneInfo.reportName);
    this.controlStatusChangesSubscription = this.reportName.statusChanges.subscribe(controlStatus => {
      this.validationStatusChange.emit(controlStatus);
    });

    this.memberKeySubscription = this.memberKey$.pipe(skipWhile((memberKey: number) => memberKey < 0))
      .subscribe(memberKey => this.memberKey = memberKey);
  }

  ngOnChanges(): void {
    if (this.showErrorMessage) {
      this.reportName.markAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.controlStatusChangesSubscription?.unsubscribe();
    this.memberKeySubscription?.unsubscribe();
}

  updateReportName(): void {
    this.schedulePaneInfo.reportName = this.reportName.value ?? '';
    this.schedulePaneInfoChange.emit(this.schedulePaneInfo);
  }

  updateIsGeneratedMonthly(): void {
    this.schedulePaneInfo.isMonthly = !this.schedulePaneInfo.isMonthly;
    this.schedulePaneInfoChange.emit(this.schedulePaneInfo);
  }

  getDuplicateReportNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return combineLatest([
        this.batchExportService.getBatchExportReportDetailsByUser(this.memberKey),
        this.batchExportService.getScheduledBatchExportsByUser(this.memberKey)
      ]).pipe(map(([reportDetailResponse, scheduleReportDetailResponse]) => {
        const reportDetailEntities = reportDetailResponse.batchExportReportDetails.find(entity => entity.reportName === control.value);
        const scheduleReportDetailEntities = scheduleReportDetailResponse.batchExportScheduleExistingReports
          .find(entity => entity.find(scheduleEntity => scheduleEntity.scheduledReportName === control.value));

        if (reportDetailEntities || scheduleReportDetailEntities) {
          return {duplicateReportName: true};
        }
        return null;
      }));
    };
  }
}
