import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AcknowledgeDialogComponent} from '../shared/components/acknowledge-dialog/acknowledge-dialog.component';
import {DialogData} from '../shared/models';

export function showSuccessDialog(entryType: string, dialog: MatDialog) {
  let dialogRef: MatDialogRef<AcknowledgeDialogComponent, DialogData>;
  const dialogData: DialogData = {
    title: 'Success!',
    question: `${entryType} entry added successfully :)`,
    confirmCallback() {
      dialogRef.close();
    }
  };
  dialogRef = dialog.open(AcknowledgeDialogComponent, {
    data: dialogData
  });
}

export interface FeatureToggleColumn {
  columnDef: string;
  headerText: string;
  id: string;
}

export const columnsForFeatureControlPanel: FeatureToggleColumn[] = [{
  columnDef: 'featureName',
  headerText: 'Feature Name',
  id: 'featureName'
}, {
  columnDef: 'enabled',
  headerText: 'Enabled External',
  id: 'featureUpdateExternalToggleBtn'
}, {
  columnDef: 'enabledInternal',
  headerText: 'Enabled Internal',
  id: 'featureUpdateToggleBtn'
}, {
  columnDef: 'createdOn', headerText: 'Created On', id: 'createdOn'
}, {
  columnDef: 'createdBy', headerText: 'Created By', id: 'createdBy'
}, {
  columnDef: 'lastUpdatedOn', headerText: 'Last Updated On', id: 'lastUpdatedOn'
}, {
  columnDef: 'lastUpdatedBy', headerText: 'Last Updated By', id: 'lastUpdatedBy'
}];

export const newFeatureToggleOptions = [{
  label: 'Enable External',
  formControlName: 'enabled',
  id: 'externalToggleBtn'
},
  {
    label: 'Enable Internal',
    formControlName: 'enabledInternal',
    id: 'internalToggleBtn'
  }];
