<span *ngFor="let option of displayedHierarchyOptions">
  <span [ngClass]="isFirstOption ? 'first-option' : 'trend-option'">
    <span class="option-wrapper">
      <i [ngClass]="'fa fa-angle-'.concat(option.nodeExpansionState === NodeExpansionState.EXPANDED ? 'down' :
        option.nodeExpansionState === NodeExpansionState.COLLAPSED ? 'right' : '')"
         (click)=whenOptionExpanded(option)>
      </i>
      <mat-checkbox [checked]="option.selected"
                    (change)="whenOptionSelected(option)"
                    [ngClass]="[NodeExpansionState.LEAF, NodeExpansionState.LEAF_ACTIVE].includes(option.nodeExpansionState)
                     ? 'non-expandable-checkbox' : 'expandable-checkbox'">{{option.text}}
      </mat-checkbox>
      <img *ngIf="isFirstOption && hoverMessage" [matTooltip]="hoverMessage || ''" alt="info" src="../../../../assets/info-icon.svg" id="info-icon"/>
    </span>
    <app-batch-export-benchmark-selector *ngIf="isFirstOption && canShowBenchmarkSelector"
                                         [selected]="batchExportMetricSection && batchExportMetricSection.selected"
                                         [batchExportMetricSection]="batchExportMetricSection"
                                         (whenBenchmarkOptionChosen)="chooseBenchmarkOption($event)"
                                         (whenPercentileChosen)="choosePercentile($event)">
    </app-batch-export-benchmark-selector>
  </span>
  <span class="metric-label-wrapper"
        *ngIf="isFirstOption && batchExportMetricSection && batchExportMetricSection.metricLabels">
    <span class="label-wrapper" *ngFor="let label of batchExportMetricSection.metricLabels">
      <span *ngFor="let token of label.tokens" class="token">
        <span [ngClass]="token.bolded ? 'bolded' : ''">{{token.token}}</span>
      </span>
    </span>
  </span>
  <app-batch-dialog-hierarchical-selector
    *ngIf="((option.children.length) > 0) && nodeExpansionIsActive(option.nodeExpansionState)"
    [hierarchyOptions]="option.children"
    [topOptions]="topOptions"
    [isFirstOption]="false"
    [whenHierarchicalOptionSelected]="whenHierarchicalOptionSelected"
    [batchExportMetricSection]="batchExportMetricSection"
    (whenBenchmarkOptionChosen)="whenBenchmarkOptionChosen"
    (whenPercentileChosen)="choosePercentile($event)">
  </app-batch-dialog-hierarchical-selector>
</span>
