import {Injectable, InjectionToken} from '@angular/core';
import {FilterCriteria} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.local';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NpvLocationResponse} from '../components/npv-models';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const LocationNewPatientVisitServiceToken =
  new InjectionToken<LocationNewPatientVisitService>('Location New Patient Visit Service');

export interface LocationNewPatientVisitService {
  getMultilevelNewPatientVisitLocationData(filter: FilterCriteria, dataset: number): Observable<NpvLocationResponse>;
}

@Injectable()
export class HttpLocationNewPatientVisitService implements LocationNewPatientVisitService {

  constructor(private httpClient: HttpClient) { }

  getMultilevelNewPatientVisitLocationData(filter: FilterCriteria, dataset: number): Observable<NpvLocationResponse> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/locationNewPatientVisitsByMultilevel`;
    return this.httpClient.post<NpvLocationResponse>(
      url,
      requestBody,
      { params: new HttpParams()
          .set('memberKey', filter.memberKey.toString())
          .set('dataset', dataset.toString())
      },
    );
  }
 }
