<mat-dialog-content>
  <div id="confirm-dialog-wrapper">

    <div id="confirm-dialog-title" *ngIf="data.title">{{data.title}}</div>
    <div id="confirm-dialog-question">{{data.question}}</div>
    <div id="buttons-wrapper">
      <button id="btn-save-apply"
              (click)="saveApply()">Save and Apply
      </button>
      <button id="btn-apply" (click)="applyOnly()">Apply Only</button>
      <button id="btn-cancel" (click)="cancel()">Cancel</button>
    </div>
  </div>
</mat-dialog-content>
