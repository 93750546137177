import {DataTableColumns} from '../../shared/data-table-columns';
import {
  CfpByMultilevel,
  ClinicalSummaryCode,
  ClinicalSummaryFamily,
  ClinicalSummaryRange,
  GroupedCfpByMultilevel,
  groupMultilevelCPTDataByCPTField
} from '../../clinical-fingerprint/ClinicalSummary';
import {
  cptCodeExcelData$,
  cptFamilyExcelData$,
  cptRangeExcelData$,
  procedureSummaryByDepartmentByCodeData$,
  procedureSummaryByDepartmentByFamilyData$,
  procedureSummaryByDepartmentByRangeData$, procedureSummaryByProviderByCodeData$,
  procedureSummaryByProviderByFamilyData$,
  procedureSummaryByProviderByRangeData$,
  procedureSummaryBySpecialtyByCodeData$,
  procedureSummaryBySpecialtyByFamilyData$,
  procedureSummaryBySpecialtyByRangeData$
} from '../export-subscribers';
import {CptViewType} from '../../shared/enums';
import {DataExportConfig} from '../../export-dialog/export-dialog.component';
import {ExportMetadata} from '../../shared/export/export';
import {
  SummaryProcedureSummaryComponent
} from '../../clinical-fingerprint/procedure-summary/summary-procedure-summary/summary-procedure-summary.component';
import * as moment from 'moment';
import {getColumnForCFP} from '../../shared/export/export-helper';
import {
  dataGroupedByCptViewType
} from '../../clinical-fingerprint/cfp-multilevel/cfp-multilevel-table/cfp-multilevel-table.component';
import {BehaviorSubject} from 'rxjs';

export async function generateExcelClinicalFingerprintByCodeData(
  cfp: ClinicalSummaryCode[], detailColumns: DataTableColumns[]
) {
  const copyRight = 'Clinical Practice Solutions Center Clinical Fingerprint ' +
    'CPT® only © ' + (new Date().getFullYear() - 1) + ' American Medical Association. All Rights Reserved. ';
  cptCodeExcelData$.next({
    summaryHeaders: [],
    summaryData: [],
    detailHeaders: detailColumns.map(c => c.header),
    detailData: cfp.map(code => {
      return generateRowForClinicalfingerprintCptCode(code, detailColumns);
    }),
    fileName: ('Clinical fingerprint by Code'),
    page: 'Clinical fingerprint by Code',
    title: copyRight,
    copyright: copyRight,
    filterInfo: [''],
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true
    },
    sheetName: 'Clinical fingerprint by Code'
  });
}

export async function generateExcelClinicalFingerprintByRangeData(
  cfp: ClinicalSummaryRange[], detailColumns: DataTableColumns[]) {
  const copyRight = 'Clinical Practice Solutions Center Clinical Fingerprint ' +
    'CPT® only © ' + (new Date().getFullYear() - 1) + ' American Medical Association. All Rights Reserved. ';
  cptRangeExcelData$.next({
    summaryHeaders: [],
    summaryData: [],
    detailHeaders: detailColumns.map(c => c.header),
    detailData: cfp.map(range => {
      return generateRowForClinicalfingerprintRange(range, detailColumns);
    }),
    fileName: ('Clinical fingerprint by Range'),
    page: 'Clinical fingerprint by Range',
    title: copyRight,
    copyright: copyRight,
    filterInfo: [''],
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true
    },
    sheetName: 'Clinical fingerprint by Range'
  });
}

export async function generateExcelClinicalFingerprintByFamilyData(
  cfp: ClinicalSummaryFamily[], detailColumns: DataTableColumns[]
) {
  const copyRight = 'Clinical Practice Solutions Center Clinical Fingerprint ' +
    'CPT® only © ' + (new Date().getFullYear() - 1) + ' American Medical Association. All Rights Reserved. ';
  cptFamilyExcelData$.next({
    summaryHeaders: [],
    summaryData: [],
    detailHeaders: detailColumns.map(c => c.header),
    detailData: cfp.map(family => {
      return generateRowForClinicalfingerprintFamily(family, detailColumns);
    }),
    fileName: ('Clinical fingerprint by Family'),
    page: 'Clinical fingerprint by Family',
    title: copyRight,
    copyright: copyRight,
    filterInfo: [''],
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true
    },
    sheetName: 'Clinical fingerprint by Family'
  });
}

export function generateExcelDataForProcedureSummary(
  dataToExport: CfpByMultilevel[], viewCommunity: boolean, cptViewType: CptViewType, dataExportConfig: DataExportConfig,
  showCfteWrvus: boolean, showCfteCount: boolean): void {
  const summaryData = generateSummaryDataByCtpViewType(dataToExport, cptViewType);
  const groupedTableData = generateGroupedCpfByMultilevelTableData(dataToExport, dataExportConfig, cptViewType);
  const columns: DataTableColumns[] = getColumnForCFP(dataExportConfig.tabName, cptViewType, viewCommunity, showCfteWrvus, showCfteCount);
  const summaryColumns: DataTableColumns[] = SummaryProcedureSummaryComponent
    .getSummaryColumnsByCptViewType(cptViewType, viewCommunity, showCfteWrvus, showCfteCount);
  const copyright = `Clinical Practice Solutions Center Productivity Summary - Procedure Summary ${moment().format('L')}`;
  const detailData: string[][] = [];
  const sumData: string[][] = [];
  groupedTableData.forEach(c => {
    c.data.forEach(dat => {
      detailData.push(generateRowForProcedureSummary(dat, columns));
    });
  });
  summaryData.forEach(sd => {
    sumData.push(generateRowForProcedureSummary(sd, summaryColumns));
  });
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (dataExportConfig.tabName) {
    case 'department':
      switch (cptViewType) {
        case CptViewType.CptFamily:
          designatedObservable = procedureSummaryByDepartmentByFamilyData$;
          break;
        case CptViewType.CptRange:
          designatedObservable = procedureSummaryByDepartmentByRangeData$;
          break;
        case CptViewType.CptCode:
          designatedObservable = procedureSummaryByDepartmentByCodeData$;
      }
      break;
    case 'specialty':
      switch (cptViewType) {
        case CptViewType.CptFamily:
          designatedObservable = procedureSummaryBySpecialtyByFamilyData$;
          break;
        case CptViewType.CptRange:
          designatedObservable = procedureSummaryBySpecialtyByRangeData$;
          break;
        case CptViewType.CptCode:
          designatedObservable = procedureSummaryBySpecialtyByCodeData$;
      }
      break;
    case 'provider':
      switch (cptViewType) {
        case CptViewType.CptFamily:
          designatedObservable = procedureSummaryByProviderByFamilyData$;
          break;
        case CptViewType.CptRange:
          designatedObservable = procedureSummaryByProviderByRangeData$;
          break;
        case CptViewType.CptCode:
          designatedObservable = procedureSummaryByProviderByCodeData$;
      }
  }
  designatedObservable.next({
    detailHeaders: columns.map(c => c.header.replace(/\n/g, '')),
    detailData,
    fileName: dataExportConfig.fileName,
    page: dataExportConfig.page,
    title: copyright,
    copyright: copyright,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showCptGroupBreadcrumb: true
    },
    summaryData: sumData,
    summaryHeaders: summaryColumns.map(c => c.header.replace(/\n/g, '')),
    sheetName: dataExportConfig.sheetName,
    filterInfo: ['']
  });
}

function generateRowForProcedureSummary(entry: CfpByMultilevel,
                                        displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    let columnData: string;
    if (col.columnDef === 'cptRangeLow' || col.columnDef === 'cptCode') {
      columnData = `${col.upperDataName ? col.upperDataName(entry) : ''}\n ${col.dataName(entry)}`.replace(/\n/g, '');
    } else {
      columnData = `${col.dataName(entry)}`.replace(/\n/g, '');
    }
    row.push(columnData);
  });
  return row;
}

function generateRowForClinicalfingerprintCptCode(cfp: ClinicalSummaryCode,
                                                  displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    row.push(col.dataName(cfp));
  });
  return row;
}

function generateRowForClinicalfingerprintRange(cfp: ClinicalSummaryRange,
                                                displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    row.push(col.dataName(cfp));
  });
  return row;
}

function generateRowForClinicalfingerprintFamily(cfp: ClinicalSummaryFamily,
                                                 displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    row.push(col.dataName(cfp));
  });
  return row;
}

function generateSummaryDataByCtpViewType(dataToExport: CfpByMultilevel[], cptViewType: CptViewType): CfpByMultilevel[] {
  let summaryData: CfpByMultilevel[] = [];
  switch (cptViewType) {
    case CptViewType.CptFamily:
      summaryData = groupMultilevelCPTDataByCPTField(dataToExport, 'cptFamilyDesc');
      break;
    case CptViewType.CptRange:
      summaryData = groupMultilevelCPTDataByCPTField(dataToExport, 'cptRangeDesc');
      break;
    case CptViewType.CptCode:
      summaryData = groupMultilevelCPTDataByCPTField(dataToExport, 'cptCode');
  }
  return summaryData;
}

function generateGroupedCpfByMultilevelTableData(dataToExport: CfpByMultilevel[],
                                                 dataExportConfig: DataExportConfig, cptViewType: CptViewType): GroupedCfpByMultilevel[] {
  let groupedTableData: GroupedCfpByMultilevel[];
  const groupedByNode: { [key: string]: CfpByMultilevel[] } = {};
  dataToExport.forEach(datum => {
    let nodePath: string;
    switch (dataExportConfig.tabName) {
      case 'department':
        nodePath = datum.departmentNodePath;
        break;
      case 'specialty':
        nodePath = datum.specialtyNodePath;
        break;
      case 'provider':
        nodePath = datum.providerNodePath;
        break;
      default:
        nodePath = datum.providerNodePath;
    }
    groupedByNode[nodePath] = groupedByNode[nodePath] || [];
    groupedByNode[nodePath].push(datum);
  });
  groupedTableData = Object.keys(groupedByNode).map(nodePath => ({
    providerNodeName: groupedByNode[nodePath][0].providerNodeName,
    specialtyNodeName: groupedByNode[nodePath][0].specialtyNodeName,
    departmentNodeName: groupedByNode[nodePath][0].departmentNodeName,
    data: dataGroupedByCptViewType(groupedByNode[nodePath], cptViewType), itemsToShow: 0
  }));
  return groupedTableData;
}
