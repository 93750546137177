import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {benchmarkChangedTo} from '../store/actions';
import {Observable, Subscription} from 'rxjs';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {MetricType} from '../shared/metric-types';
import {DropdownOption, Legend} from '../shared/models';
import {BenchmarkPercentile} from '../shared/benchmark-types';
import {BenchmarkOption} from '../shared/enums';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent implements OnInit, OnDestroy {
  @Input() metricType: MetricType = MetricType.WorkRVUs;
  @Input() legends: Legend[];
  @Input() benchmarksToExclude: string[] | undefined;
  @Input() activeVarianceToggle: boolean;
  @Input() hasBenchmarks = true;
  @Input() defaultBenchmarkPercentile: BenchmarkPercentile | undefined;
  @Input() isSpecialtyPerformance = false;
  @Input() warningText: string;
  @Input() fromPdf = false;
  @Input() benchmarkOption: BenchmarkOption = BenchmarkOption.Academic;
  @Input() benchmarkPercentile: BenchmarkPercentile = BenchmarkPercentile.Mean;
  @Input() showExtendedBenchmarkOptions = false;

  benchmarkPercentileLabel: string;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  // noinspection JSUnusedLocalSymbols (this actually is used in the html)
  benchmarkPercentiles: DropdownOption[] = [
    {value: BenchmarkPercentile.Mean, name: 'Mean'},
    {value: BenchmarkPercentile.Percentile25th, name: '25th Percentile'},
    {value: BenchmarkPercentile.Percentile50th, name: '50th Percentile'},
    {value: BenchmarkPercentile.Percentile65th, name: '65th Percentile'},
    {value: BenchmarkPercentile.Percentile75th, name: '75th Percentile'},
    {value: BenchmarkPercentile.Percentile90th, name: '90th Percentile'}
  ];
  chartLegendDataSubscription: Subscription;

  constructor(
    @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
    private ngRedux: NgRedux<IAppState>
  ) {
  }


  ngOnInit() {
    this.filterOutExcludedBenchmarks();
    if (!this.fromPdf) {
      this.chartLegendDataSubscription = this.benchmarkPercentile$.subscribe((benchmarkPercentileFromStore: BenchmarkPercentile) => {
        const hasBenchmarkToMatchStore: boolean = !!this.benchmarkPercentiles.find(t => t.value === benchmarkPercentileFromStore);
        this.benchmarkPercentile = hasBenchmarkToMatchStore ? benchmarkPercentileFromStore : this.getDefaultBenchmarkPercentile();
        this.getBenchmarkPercentileLabel();
      });
    }
    this.getBenchmarkPercentileLabel();
  }

  ngOnDestroy(): void {
    this.chartLegendDataSubscription?.unsubscribe();
  }

  updateBenchmarkPercentile(value: BenchmarkPercentile): void {
    this.ngRedux.dispatch(benchmarkChangedTo(value));
    this.analyticsService.handleGoogleAnalytics('wRVU by Provider Graph Benchmark', 'Benchmark Change', BenchmarkPercentile[value]);
  }

  getBenchmarkPercentileLabel() {
    this.benchmarkPercentileLabel = this.benchmarkPercentiles.find(x => x.value === this.benchmarkPercentile)?.name || '';
  }

  private filterOutExcludedBenchmarks() {
    if (this.benchmarksToExclude) {
      this.benchmarksToExclude.forEach(x => {
        this.benchmarkPercentiles = this.benchmarkPercentiles.filter(y => y.name !== x);
      });
    }
  }

  private getDefaultBenchmarkPercentile() {
    return this.defaultBenchmarkPercentile ? this.defaultBenchmarkPercentile : BenchmarkPercentile.Mean;
  }
}
