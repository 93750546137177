import {Component, EventEmitter, Input, OnChanges, AfterContentChecked, Output, ChangeDetectorRef} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ClinicalSummaryRange} from '../../../ClinicalSummary';
import {isItemAbleToShow, ScenarioForDisplayability} from '../../../../filter-banner/search-payer/PayerSetupHelper';
import {RelatableSelectableItem} from '../../../../filter-banner/filter-banner-helper';
import {MIN_SEARCH_CHARS} from '../../../../shared/constants';

@Component({
  selector: 'app-search-cpt-range',
  templateUrl: './search-cpt-range.component.html',
  styleUrls: ['./search-cpt-range.component.scss']
})
export class SearchCptRangeComponent implements OnChanges, AfterContentChecked {

  @Input() listOfCptRanges: RelatableSelectableItem<ClinicalSummaryRange>[] = [];
  @Input() whenToggled: (open: boolean) => void;
  @Input() familiesAlsoShown = false;
  @Output() afterCptRangesUpdated = new EventEmitter<number>();
  readonly DEFAULT_RANGES = 'All Ranges';
  readonly minSearchChars = MIN_SEARCH_CHARS;
  toggleLabel = this.DEFAULT_RANGES;
  additionalLabel = '';
  additionalSelected = false;
  toggleInfo: BehaviorSubject<boolean>;
  searchText = '';
  showCptRange = false;
  displayedSelectedRanges: RelatableSelectableItem<ClinicalSummaryRange>[] = [];
  displayedUnselectedRanges: RelatableSelectableItem<ClinicalSummaryRange>[] = [];
  selectedRanges: RelatableSelectableItem<ClinicalSummaryRange>[] = [];
  unSelectedRanges: RelatableSelectableItem<ClinicalSummaryRange>[] = [];

  constructor(private cdr: ChangeDetectorRef) {
    this.toggleInfo = new BehaviorSubject<boolean>(false);
    this.getToggleInfo().subscribe((showCptRange: boolean) => {
      this.showCptRange = showCptRange;
      if (showCptRange) {
        this.filterDisplayedRanges();
      }
      if (this.whenToggled) {
        this.whenToggled(showCptRange);
      }
    });
  }

  ngOnChanges(): void {
    if (this.familiesAlsoShown) {
      this.toggleInfo.next(false);
    }
    this.filterRanges();
    this.setToggleLabel();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  private filterRanges(): void {
    this.selectedRanges = this.listOfCptRanges.filter(s => s.item.selected);
    this.unSelectedRanges = this.listOfCptRanges.filter(s => !s.item.selected);
  }

  private filterDisplayedRanges(): void {
    this.displayedSelectedRanges = this.selectedRanges.slice();
    this.displayedUnselectedRanges = this.unSelectedRanges.slice();
    this.displayedUnselectedRanges = this.displayedUnselectedRanges.filter(
      s => isItemAbleToShow(s, ScenarioForDisplayability.Standard));
    this.displayedSelectedRanges = this.displayedSelectedRanges.filter(
      s => isItemAbleToShow(s, ScenarioForDisplayability.Standard));
    this.sortRanges(this.displayedSelectedRanges);
    this.sortRanges(this.displayedUnselectedRanges);
  }

  getToggleInfo(): Observable<boolean> {
    return this.toggleInfo.asObservable();
  }

  toggleCptRanges(): void {
    if (!this.toggleInfo.value) {
      this.displayedSelectedRanges = this.selectedRanges.slice();
      this.displayedUnselectedRanges = this.unSelectedRanges.slice();
    }
    this.toggleInfo.next(!this.toggleInfo.value);
  }

  onSearchTextChanged(): void {
    this.listOfCptRanges.forEach(range => {
      range.item.matchesSearchText = this.searchText.length < this.minSearchChars ||
        !!range.item.item.cptRangeDesc?.toLowerCase().includes(this.searchText.toLowerCase());
    });
    this.filterDisplayedRanges();
  }

  selectAllCptRanges(event: MouseEvent): void {
    event.preventDefault();
    if (this.displayedSelectedRanges.length && !this.displayedUnselectedRanges.length) {
      this.displayedSelectedRanges.forEach(Range => {
        Range.item.selected = false;
      });
      this.filterRanges();
      this.filterDisplayedRanges();
    } else {
      this.displayedUnselectedRanges.forEach(Range => {
        Range.item.selected = true;
      });
      this.filterRanges();
      this.filterDisplayedRanges();
    }
  }

  whenCptRangeClicked(range: RelatableSelectableItem<ClinicalSummaryRange>, event: MouseEvent): void {
    event.preventDefault();
    range.item.selected = !range.item.selected;
    this.filterRanges();
    this.filterDisplayedRanges();
  }

  private setToggleLabel() {
    if (this.selectedRanges.length === 0 || this.unSelectedRanges.length === 0) {
      this.toggleLabel = this.DEFAULT_RANGES;
      this.additionalSelected = false;
    } else {
      const item = this.selectedRanges[0].item.item;
      this.toggleLabel = `${item.cptRangeDesc} ${item.cptRangeLow} - ${item.cptRangeHigh}` || this.toggleLabel;
      this.additionalLabel = '{+ ' + (this.selectedRanges.length - 1) + ' More}';
      this.additionalSelected = this.selectedRanges.length >= 2 && !!this.unSelectedRanges.length;
    }
  }

  isTooltipDisabled(refElement: HTMLElement) {
    return refElement.scrollHeight <= refElement.offsetHeight;
  }

  cancel(): void {
    this.listOfCptRanges.forEach(x => {
      x.item.selected = !!x.item.originallySelected;
    });
    this.filterRanges();
    this.toggleInfo.next(false);
    this.searchText = '';
  }

  apply(): void {
    this.listOfCptRanges.forEach(x => {
      x.item.originallySelected = x.item.selected;
    });
    this.toggleInfo.next(false);
    this.filterRanges();
    this.afterCptRangesUpdated.emit(this.selectedRanges.length);
    this.setToggleLabel();
  }

  private sortRanges(RangeNodes: RelatableSelectableItem<ClinicalSummaryRange>[]): void {
    const defaultSortResult = 0;
    RangeNodes.sort((rangeNode1, rangeNode2) => {
      return (rangeNode1.item?.item?.cptRangeDesc && rangeNode2.item?.item?.cptRangeDesc) ?
        (rangeNode1.item.item.cptRangeDesc.localeCompare(rangeNode2.item.item.cptRangeDesc)) : defaultSortResult;
    });
  }
}
