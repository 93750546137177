<div class="no-access-page" *ngIf="!showPdfPage">
  <div class="no-access-page-body">
    <img src="assets/cloud.png"/>
    <p class="if-you-have-question">Looks like You are not authorized to see this page!</p>
  </div>
</div>
<div *ngIf="showPdfPage">
  <table>
    <thead>
      <app-batch-export-pdf-header [batchExportDetails]="detailData" [headerClass]="'batch-export-header-wrapper'">
      </app-batch-export-pdf-header>
    </thead>
    <tbody>
      <div id="batch-export-wrapper">
      <div class="tiles-wrapper">
        <app-wrvu-tile-batch-export
          id="wRVUTrendOverview"
          [benchmarkPercentile]="wrvuBenchmarkPercentile"
          [benchmarkOption]="wrvuBenchmarkOption"
          [benchmarkLabel]="benchmarkLabel"
          [NUMBER_OF_DATA_POINTS]="NUMBER_OF_DATA_POINTS"
          [wrvuData]="wrvuData"
          [wrvuPreviousYearData]="wrvuPreviousYearData"
          [selectedDateRange]="selectedDateRange"
          [previousDateRange]="previousSelectedDateRange"
        ></app-wrvu-tile-batch-export>
        <app-npv-tile-batch-export
          id="npvTrendOverview"
          [benchmarkPercentile]="npvBenchmarkPercentile"
          [benchmarkOption]="npvBenchmarkOption"
          [selectedDateRange]="selectedDateRange"
          [previousDateRange]="previousSelectedDateRange"
          [benchmarkLabel]="benchmarkLabel"
          [NUMBER_OF_DATA_POINTS]="NUMBER_OF_DATA_POINTS"
          [npvData]="npvData"
          [npvPreviousYearData]="npvPreviousYearData"
        ></app-npv-tile-batch-export>
      </div>
      <div id="em-wrapper" *ngIf="emMetrics.length >= 1">
        <app-evaluation-management-tile-batch-export
          [batchMultilevelEMData]="emData" [nodePath]="nodePath" [selectedDateRange]="selectedDateRange"
          [batchMultilevelEMSpecialtySummaryData] = "emSpecialtyData"
          [nodeName]="splNodeName" [emMetrics]="emMetrics"
          [benchmarkOption]="emTileBenchmarkOption"
        ></app-evaluation-management-tile-batch-export>
      </div>
      <div id="zip-code-wrapper">
        <app-zip-code-npv-batch-export [zipCodeNewPatientVisits]="zipCodeNewPatientVisits"></app-zip-code-npv-batch-export>
      </div>
    </div>
    </tbody>
    <tfoot>
      <tr><td>
        <div class="footer-space">&nbsp;
          <app-footer [fromPdf]="true"></app-footer>
        </div>
      </td></tr>
    </tfoot>
  </table>
</div>
