import {Component, Input, OnInit} from '@angular/core';
import {RelatableSelectableItem, ScenarioForDisplayability, UpdatedFilterTab} from '../FilterBannerModels';
import {ListItemExpansion, PayerObject} from '../../shared/models';
import {determineAllSelected, isItemAbleToShow, neutralizeExpansion} from '../UpdatedFilterBannerHelpers';
import {getOppositeNodeExpansionState, isPayerTypeAGranularType} from '../../shared/helpers';
import {DEFAULT_PAYER_SELECTION} from '../../store/DefaultValues';
import {NodeExpansionState} from '../../shared/enums';

@Component({
  selector: 'app-select-payer',
  templateUrl: './select-payer.component.html',
  styleUrls: ['./select-payer.component.scss']
})
export class SelectPayerComponent implements OnInit {

  @Input() payers: RelatableSelectableItem<PayerObject>[] = [];
  @Input() shouldPayersBeGranular = false;
  @Input() onPayerChanged: (tab?: UpdatedFilterTab, item?: RelatableSelectableItem<PayerObject>) => void;
  @Input() showLoadingIndicator = true;
  @Input() filterTab: UpdatedFilterTab;
  allSelected = true;
  readonly DEFAULT_PAYER_SELECTION = DEFAULT_PAYER_SELECTION;
  displayedPayers: RelatableSelectableItem<PayerObject>[] = [];
  searchText = '';

  public static payerGranularityAllowed(shouldPayersBeGranular: boolean, payer: PayerObject): boolean {
    return shouldPayersBeGranular || !isPayerTypeAGranularType(payer.level);
  }

  private static payerMatchesSearchText(payer: RelatableSelectableItem<PayerObject>): boolean {
    return payer.item.matchesSearchText || !!payer.relatives.find(r => r.matchesSearchText);
  }

  private static showPayerBasedOnItsLevelAndParentExpansionState(payer: RelatableSelectableItem<PayerObject>): boolean {
    return !isPayerTypeAGranularType(payer.item.item.level) || isItemAbleToShow(payer, ScenarioForDisplayability.IsParentExpanded);
  }

  constructor() { }

  ngOnInit() {
    this.showPayers();
    this.allSelected = determineAllSelected(this.payers || []);
  }

  private showPayers(): void {
    this.displayedPayers = this.payers.filter((payer: RelatableSelectableItem<PayerObject>) =>
      (SelectPayerComponent.payerGranularityAllowed(this.shouldPayersBeGranular, payer.item.item) &&
        SelectPayerComponent.payerMatchesSearchText(payer)
        && SelectPayerComponent.showPayerBasedOnItsLevelAndParentExpansionState(payer)));
  }

  getCaretClass(expansion?: ListItemExpansion): string {
    if (!this.shouldPayersBeGranular || !expansion) {
      return '';
    }
    switch (expansion.state) {
      case NodeExpansionState.COLLAPSED:
        return 'fa fa-caret-down';
      case NodeExpansionState.EXPANDED:
        return 'fa fa-caret-up';
      default:
        return '';
    }
  }

  selectAllPayers(): void {
    if (!this.allSelected) {
      let wasSelected: boolean;
      this.payers.forEach(payer => {
        wasSelected = payer.item.selected;
        neutralizeExpansion(payer.item);
        payer.item.selected = false;
        if (this.filterTab.newSelections && wasSelected) {
          if (payer.item.originallySelected) {
            this.filterTab.newSelections.deselections++;
          } else if (wasSelected) {
            this.filterTab.newSelections.selections--;
          }
        }
      });
      this.allSelected = true;
      this.onPayerChanged(this.filterTab);
      this.showPayers();
    }
  }

  expandOrCollapsePayer(payer: RelatableSelectableItem<PayerObject>) {
    const payerExpansion = payer.item.expansion;
    if (payerExpansion) {
      payerExpansion.state = getOppositeNodeExpansionState(payerExpansion.state);
      if (payerExpansion.state === NodeExpansionState.COLLAPSED) {
        this.collapseChildPayers(payer);
      }
    }
    this.showPayers();
  }

  whenPayerClicked(payer: RelatableSelectableItem<PayerObject>): void {
    if (payer.item.selected) {
      return;
    }
    this.allSelected = false;
    let shouldBeSelected: boolean;
    let wasSelected: boolean;
    this.payers.forEach(pay => {
      shouldBeSelected = pay.item.key === payer.item.key && pay.item.item.level === payer.item.item.level;
      wasSelected = pay.item.selected;
      pay.item.selected = shouldBeSelected;
      if (shouldBeSelected && this.filterTab.newSelections) {
        if (!pay.item.originallySelected) {
          this.filterTab.newSelections.selections++;
        } else if (!wasSelected) {
          this.filterTab.newSelections.deselections--;
        }
      } else if (!shouldBeSelected && this.filterTab.newSelections && wasSelected) {
        if (pay.item.originallySelected) {
          this.filterTab.newSelections.deselections++;
        } else {
          this.filterTab.newSelections.selections--;
        }
      }
    });
    this.onPayerChanged(this.filterTab, payer);
  }

  private collapseChildPayers(payer: RelatableSelectableItem<PayerObject>) {
    payer.relatives.forEach(rel => {
      if (rel.item.level && payer.item.item.level) {
        if (rel.item.level > payer.item.item.level && rel.expansion?.state === NodeExpansionState.EXPANDED) {
          rel.expansion.state = NodeExpansionState.COLLAPSED;
        }
      }
    });
  }

  onSearchTextChanged(): void {
    if (this.searchText.length >= 3) {
      this.payers.forEach(payer => {
        payer.item.matchesSearchText = !!payer.item.displayText?.toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
      this.showPayers();
    }
  }
}
