
<div #header [ngClass]="headerClass" [ngStyle]="{'margin-left.px': margin, 'margin-right.px': margin}">
  <div id="upper-header-wrapper">
    <div id="upper-header">
      <img src="../../../../assets/CPSC_logo.svg" alt="CPSC_logo"/>
      <span id="right-header">
        <span id="member-name">{{memberName}}</span>
        <img alt="vizient/AAMC logo" src="../../../../assets/vzt_aamc_logo_2color_rgb_pos.png" class="viz-aamc-logo"/>
      </span>
    </div>
  </div>
  <div id="filter-wrapper">
    <div class="filter-criteria-wrapper">
      <span class="filter-criteria-label" id="breadcrumb">
        {{departmentName}} / {{specialtyName}} / {{providerName}}</span>
    </div>
    <div class="filter-criteria-wrapper">
      <span class="filter-criteria-label" id="date-range">{{dateRange}}</span>
    </div>
    <div class="filter-criteria-wrapper">
      <span class="filter-criteria-label" id="payer" *ngIf="showPayer">Payer Category: {{payerCategoryName}}</span>
      <span *ngIf="visitType" class="filter-criteria-label" id="telehealth">Visit Type: {{visitType.text}}</span><br>
      <span class="filter-criteria-label" id="location">Location: {{multiMemberLocationName}}</span>
    </div>
  </div>
</div>
