import {Injectable} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {getUpdatedNodePath} from '../shared/helpers';
import {
  customGroupIdChangedTo,
  drillDownHistoryAppendedWith,
  filterChosenByUserChangedTo,
  multilevelTabChangedTo,
  nodePathChangedTo,
  undoDrillDownHistory
} from '../store/actions';
import {MultilevelTab} from '../shared/enums';
import {DEFAULT_CUSTOM_GROUP_ID} from '../store/DefaultValues';

@Injectable({
  providedIn: 'root'
})
export class DrillDownService {

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  drillDownIntoNode(selectedNodePath: string, currentNodePath: string, currentTab: MultilevelTab) {
    const id = this.ngRedux.getState().filters.customGroupId;
    const updatedNodePath = getUpdatedNodePath(selectedNodePath, currentNodePath);
    if (currentNodePath !== updatedNodePath) {
      this.ngRedux.dispatch(drillDownHistoryAppendedWith(currentNodePath, currentTab, id));
      this.ngRedux.dispatch(customGroupIdChangedTo(DEFAULT_CUSTOM_GROUP_ID));
      this.ngRedux.dispatch(nodePathChangedTo(updatedNodePath));
      this.ngRedux.dispatch(filterChosenByUserChangedTo(true));
    }
  }

  drillUp() {
    const lastDrillDown = this.ngRedux.getState().display.drillDownHistory.slice().pop();
    if (lastDrillDown) {
      this.ngRedux.dispatch(customGroupIdChangedTo(lastDrillDown.customGroupId || DEFAULT_CUSTOM_GROUP_ID));
      this.ngRedux.dispatch(nodePathChangedTo(lastDrillDown.nodePath));
      this.ngRedux.dispatch(multilevelTabChangedTo(lastDrillDown.tab));
      this.ngRedux.dispatch(undoDrillDownHistory(false));
    }
  }
}
