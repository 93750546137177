<div id="whole-screen">
  <div id="left-half" class="half">
    <div class="section-title">Providers</div>
    <div class="top-section">
      <div id="department-section">
        <div id="departmentDropdown" (click)="toggleShowDepartments()">
          <span>
            <span id="selectedDepartmentLabel">{{selectedDepartmentLabel}}</span>
            <span id="additionalDepartmentsLabel" *ngIf="additionalDepartmentsSelectedLabel">
              {{additionalDepartmentsSelectedLabel}}</span>
          </span>
          <i class="fa fa-caret-down caret-purple"></i>
        </div>
        <div id="departmentListWrapper" *ngIf="showDepartments">
          <mat-list id="departmentList-divided">
            <span class="input-department-search-wrapper">
              <i class="fa fa-search" aria-hidden="true"></i>
              <input type="text" placeholder="Search..." class="search-for-nodeName-departments"
                     (input)="onDepartmentSearchTextChanged()" [(ngModel)]="departmentSearchText">
            </span>
            <mat-checkbox class="chk-all-departments"
                          (click)="updateAllDepartments(tempUnselectedDepartments.length >= 1, $event)"
                          [checked]="tempUnselectedDepartments.length === 0 && tempSelectedDepartments.length >= 1">
              <span id="select-all" class="select-all-departments-label">Select All</span>
            </mat-checkbox>
            <mat-label class="departmentListDivider"
                       *ngIf="tempSelectedDepartments.length >= 1 && tempUnselectedDepartments.length >= 1">
              Selected Departments
            </mat-label>
            <mat-list-item *ngFor="let department of tempSelectedDepartments">
              <mat-checkbox id={{department.selectableNode.node.nodeName}}-selected [checked]="true" class="department-checkbox"
                            (change)="whenDepartmentSelected(department, false)">
                {{department.selectableNode.node.nodeName}}</mat-checkbox>
            </mat-list-item>
            <mat-label class="departmentListDivider"
                       *ngIf="tempSelectedDepartments.length >= 1 && tempUnselectedDepartments.length >= 1">
              Other Departments
            </mat-label>
            <mat-list-item *ngFor="let department of tempUnselectedDepartments">
              <mat-checkbox id={{department.selectableNode.node.nodeName}}-unselected [checked]="false" class="department-checkbox"
                            (change)="whenDepartmentSelected(department, true)">
                {{department.selectableNode.node.nodeName}}</mat-checkbox>
            </mat-list-item>
          </mat-list>
          <div class="apply-cancel-wrapper" *ngIf="showDepartments">
            <button id="btn-department-apply" (click)="applyDepartment(true)">Apply</button>
            <button id="btn-department-cancel" (click)="cancelDepartment()">Cancel</button>
          </div>
        </div>
      </div>

      <div id="specialty-section">
        <div id="specialtyDropdown" (click)="toggleShowSpecialties()">
          <span>
            <span id="selectedSpecialtyLabel">{{selectedSpecialtyLabel}}</span>
            <span id="additionalSpecialtiesLabel" *ngIf="additionalSpecialtiesSelectedLabel">
              {{additionalSpecialtiesSelectedLabel}}</span>
          </span>
          <i class="fa fa-caret-down caret-purple"></i>
        </div>
        <div id="specialtyListWrapper" *ngIf="showSpecialties">
          <mat-list id="specialtyList-divided">
            <span class="input-specialty-search-wrapper">
              <i class="fa fa-search" aria-hidden="true"></i>
              <input type="text" placeholder="Search..." class="search-for-nodeName-specialties"
                     (input)="onSpecialtySearchTextChanged()" [(ngModel)]="specialtySearchText">
            </span>
            <mat-checkbox class="chk-all-specialties"
                          (click)="updateAllSpecialties(tempUnselectedFilteredSpecialties.length >= 1, $event)"
                          [checked]="tempUnselectedFilteredSpecialties.length === 0 && tempSelectedFilteredSpecialties.length >= 1">
              <span class="select-all-specialties-label">Select All</span>
            </mat-checkbox>
            <mat-label class="specialtyListDivider"
                       *ngIf="tempSelectedFilteredSpecialties.length >= 1 && tempUnselectedFilteredSpecialties.length >= 1">
              Selected Specialties
            </mat-label>
            <mat-list-item *ngFor="let specialty of tempSelectedFilteredSpecialties">
              <mat-checkbox [checked]="true" class="specialty-checkbox"
                            (change)="whenSpecialtySelected(specialty, false)">
                {{specialty.selectableNode.node.nodeName}}</mat-checkbox>
            </mat-list-item>
            <mat-label class="specialtyListDivider"
                       *ngIf="tempSelectedFilteredSpecialties.length >= 1 && tempUnselectedFilteredSpecialties.length >= 1">
              Other Specialties
            </mat-label>
            <mat-list-item *ngFor="let specialty of tempUnselectedFilteredSpecialties">
              <mat-checkbox [checked]="false" class="specialty-checkbox"
                            (change)="whenSpecialtySelected(specialty, true)">
                {{specialty.selectableNode.node.nodeName}}</mat-checkbox>
            </mat-list-item>
          </mat-list>
          <div class="apply-cancel-wrapper" *ngIf="showSpecialties">
            <button id="btn-specialty-apply" (click)="applySpecialty()">Apply</button>
            <button id="btn-specialty-cancel" (click)="cancelSpecialty()">Cancel</button>
          </div>
        </div>
      </div>

      <div id="search-providers">
        <span class="input-search-wrapper">
          <input type="text" placeholder="Provider Search..." class="search-for" id="search-for-nodeName-providers"
               (input)="onProviderSearchTextChanged()" [(ngModel)]="providerSearchText">
          <i *ngIf="providerSearchText" id="cross" class="fa fa-times cross-button" [class.icon-disabled]="providerSearchText.length === 0" aria-hidden="true"
             (click)="clearSearch()"></i>
          <i *ngIf="!providerSearchText" id="search-glass" class="fa fa-search search-icon" [class.icon-disabled]="providerSearchText.length < 3" aria-hidden="true"
             [matTooltip]="'3 letters required'" [matTooltipPosition]="'above'"
             [matTooltipDisabled]="providerSearchText.length >= 3" [matTooltipClass]="'custom-tooltip'"></i>
        </span>
      </div>
    </div>
    <app-filter-criteria-table
        [receivedData]="unselectedFilteredProviders"
        [columns]="providerColumnsUnselected"
        [rowSelectionCallback]="whenUnselectedProviderRowClicked"
        [tableCssClass]="'provider-table'"
        [indeterminate]="false"
        [paginate]="false"
        [showProgressBar]="false"
        [shouldDisplayTheHeaders]="true"
        [allOfContentsChecked]="false"
        [permanentSelectedStatus]="true"
        [indeterminateContentsChecked]="false"
        [updateAllContent]="updateAllProviders"
        [tableView]="2"
        [highlightedSearchText]="providerSearchText"
        [emptinessIndicationMessage]="emptinessIndicationMessageLeft"
        (sortEvent)="sortProviderListBy($event)"
      >
    </app-filter-criteria-table>
  </div>
  <div id="right-half" class="half">
    <div class="section-title">
      <span>Selected Providers <i class="fa fa-info-circle" (mouseenter)="toggleLimitMessage(true)"
        (mouseleave)="toggleLimitMessage(false)" id="info-limit-guidelines"></i>
      <div id="selection-limit-information" *ngIf="showLimitMessage">
        <div id="limit-info-header">You can make either of the following selections:</div>
        <div class="limit-guideline">- Up to {{selectionLimit}} entire departments or specialties</div>
        <div class="limit-guideline">- Or, a customized list of up to {{selectionLimit}} individual providers</div>
        <div id="bottom-limit-popup">If your list exceeds {{selectionLimit}}, please adjust your
          <span id="txt-limit-select-provider">Selected Providers</span>
          list to continue.
        </div>
      </div>
      </span>
    </div>
    <div class="grouping-section">
    </div>
    <app-filter-criteria-table
                               [receivedData]="selectedFilteredProviders"
                               [columns]="providerColumnsSelected"
                               [rowSelectionCallback]="whenSelectedProviderRowClicked"
                               [tableCssClass]="'provider-table'"
                               [indeterminate]="false"
                               [paginate]="false"
                               [showProgressBar]="false"
                               [shouldDisplayTheHeaders]="true"
                               [allOfContentsChecked]="selectedFilteredProviders.length >= 1"
                               [permanentSelectedStatus]="true"
                               [indeterminateContentsChecked]="false"
                               [updateAllContent]="updateAllProviders"
                               [tableView]="1"
                               [emptinessIndicationMessage]="emptinessIndicationMessageRight"
                               (sortEvent)="sortProviderListBy($event)">
    </app-filter-criteria-table>
  </div>
</div>
