<app-denials-multilevel-chart
  [page]="currentPage"
  [graphData]="denialsMultiLevel"
  [isDepartment]="false"
  [isSpecialty]="false"
  [isProvider]="true"
  [tabLevel]="3"
  [showProgressBar]="showProgressBar"
  [benchmarkPercentile]="benchmarkPercentile"
  [level]="'By Provider'"
  [variables]="variables"
  [showVariableMenu]="true"
  [activeVarianceToggle]="activeVarianceToggle"
  [columnSelectionCallback]="whenProviderSelected">
></app-denials-multilevel-chart>
<app-denials-metric-data-table id="denials-data-table"
                               [showProgressBar]="showProgressBar"
                               [rowSelectionCallback]="whenProviderSelected"
                               [sortingDataAccessor]="sortingDataAccessor"
                               [receivedData]="denialsMultiLevel"
                               [columns]="columns"
                               [defaultSortColumn]="defaultSortColumn"
                               [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                               tableTitle="Denial Analysis By Provider"
                               [whenSortChanged]="levelSpecificHandleSortChange"
                               [denialsTab]="true"
                               [benchmarkPercentile]="benchmarkPercentile"
                               [originalColumns]="originalColumns"
                               [currentPage]="currentPage"
                               [updateSortingCriteria]="updateSortingCriteria"
                               [reducerAction]="reducerAction"
                               [addRemoveOption]="true"
                               [nodePathName]="nodePathName"
></app-denials-metric-data-table>
