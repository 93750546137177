import {BaseColumn} from '../store/IAppState';
import {ColumnType} from '../shared/enums';
import {
  columnsForMultiLevelCollectionsDepartment,
  columnsForMultiLevelCollectionsProvider,
  columnsForMultiLevelCollectionsSpecialty,
  columnsForMultiLevelDenials,
  columnsForMultiLevelDenialsDepartment,
  columnsForMultiLevelDenialsProvider,
  columnsForMultiLevelDenialsSpecialty,
  columnsForMultiLevelPayerCollectionsDepartment,
  columnsForMultiLevelPayerCollectionsProvider,
  columnsForMultiLevelPayerCollectionsSpecialty,
  columnsForMultiLevelSnapshotCollections,
  columnsForPayerByMultiLevelCollections,
  columnsForPayerCollections,
  columnsForSnapshotCollections,
  DataTableColumns,
  specialtyPerformanceColumns
} from '../shared/data-table-columns';
import {columnsForDenialsByPayer} from '../shared/data-table-columns';


export function getCollectionsColumns(): BaseColumn[] {
  return columnsForSnapshotCollections.filter(col => col.primaryColumn || col.isDefault);
}

export function getPayerCollectionsColumns(): BaseColumn[] {
  return columnsForPayerCollections.slice().filter(col => col.primaryColumn || col.isDefault);
}


export function getMultiLevelPayerCollectionsColumns(): BaseColumn[] {
  return columnsForPayerByMultiLevelCollections.slice().filter(col => col.primaryColumn || col.isDefault);
}

export function getMultiLevelCollectionsColumns(): BaseColumn[] {
  return columnsForMultiLevelSnapshotCollections.slice().filter(col => col.primaryColumn || col.isDefault);
}

export function getProviderMultiLevelCollectionsColumns(): BaseColumn[] {
  return columnsForMultiLevelCollectionsProvider.slice().filter(col => col.primaryColumn || col.isDefault);
}

export function getSpecialtyMultiLevelCollectionsColumns(): BaseColumn[] {
  return columnsForMultiLevelCollectionsSpecialty.slice().filter(col => col.primaryColumn || col.isDefault);
}

export function getDepartmentMultiLevelCollectionsColumns(): BaseColumn[] {
  return columnsForMultiLevelCollectionsDepartment.slice().filter(col => col.primaryColumn || col.isDefault);
}


export function getDepartmentMultiLevelByPayerCollectionsColumns(): BaseColumn[] {
  return columnsForMultiLevelPayerCollectionsDepartment.slice().filter(col => col.primaryColumn || col.isDefault);
}

export function getSpecialtyMultiLevelByPayerCollectionsColumns(): BaseColumn[] {
  return columnsForMultiLevelPayerCollectionsSpecialty.slice().filter(col => col.primaryColumn || col.isDefault);
}

export function getProviderMultiLevelByPayerCollectionsColumns(): BaseColumn[] {
  return columnsForMultiLevelPayerCollectionsProvider.slice().filter(col => col.primaryColumn || col.isDefault);
}

export function getDenialsColumns(): DataTableColumns[] {
  return columnsForDenialsByPayer.slice().filter(col =>
    (col.primaryColumn || col.isDefault) && col.columnType !== ColumnType.OPEN_WINDOW);
}

export function getMultiLevelDenialsColumns(): DataTableColumns[] {
  return columnsForMultiLevelDenials.slice().filter(col =>
    (col.primaryColumn || col.isDefault) && col.columnType !== ColumnType.OPEN_WINDOW);
}

export function getDenialsSpecialtyColumns(): DataTableColumns[] {
  return columnsForMultiLevelDenialsSpecialty.slice().filter(col =>
    (col.primaryColumn || col.isDefault) && col.columnType !== ColumnType.OPEN_WINDOW);
}

export function getDenialsProviderColumns(): DataTableColumns[] {
  return columnsForMultiLevelDenialsProvider.slice().filter(col =>
    (col.primaryColumn || col.isDefault) && col.columnType !== ColumnType.OPEN_WINDOW);
}

export function getDenialsDepartmentColumns(): DataTableColumns[] {
  return columnsForMultiLevelDenialsDepartment.slice().filter(col =>
    (col.primaryColumn || col.isDefault) && col.columnType !== ColumnType.OPEN_WINDOW);
}

export function getCollectionsDefaultColumns(): BaseColumn[] {
  return [
    {
      header: 'By Department',
      columnDef: 'nodeName'
    },
    {
      header: 'Charges',
      columnDef: 'chargeAmount'
    },
    {
      header: 'Expected Payments',
      columnDef: 'expectedPayments'
    },
    {
      header: 'Total Payments',
      columnDef: 'totalPayments'
    },
    {
      header: 'Net Collection Rate',
      columnDef: 'netCollectionRate'
    },
    {
      header: 'Net Collection Rate Benchmark (25th)',
      columnDef: 'benchmark25th'
    },
    {
      header: 'Variance (25th)',
      columnDef: 'variance25th'
    },
  ];
}

export function getWRVUSnapshotColumns(): BaseColumn[] {
  return [
    {
      header: 'Groups',
      columnDef: 'nodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'Provider',
      columnDef: 'providerNodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'Specialty',
      columnDef: 'specialtyNodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'Department',
      columnDef: 'departmentNodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'cFTE Adj. wRVUs',
      columnDef: 'cfteAdjustedWRVUs',
      columnType: ColumnType.CFTE_ADJ_WRVU
    },
    {
      header: 'Actual wRVUs',
      columnDef: 'wRVUs'
    },
    {
      header: 'cFTE',
      columnDef: 'cfte',
      columnType: ColumnType.CFTE
    },
    {
      header: 'Charges',
      columnDef: 'charges',
      columnType: ColumnType.CHARGES
    },
    {
      header: 'Previous Date Range cFTE Adj. wRVUs',
      columnDef: 'previousCfteAdjustedWRVUs',
      columnType: ColumnType.PREVIOUS
    },
    {
      header: 'cFTE Adj. wRVUs Difference from Previous Date Range',
      columnDef: 'difference',
      columnType: ColumnType.DIFFERENCE
    },
    {
      header: 'Imputed/ Reported cFTE (Mean)',
      columnDef: 'imputedReportedcFTEMean',
      columnType: ColumnType.IMPUTED
    },
    {
      header: 'wRVU Benchmark (Mean)',
      columnDef: 'benchmarkMean',
      columnType: ColumnType.BENCHMARK
    },
    {
      header: 'Variance (Mean)',
      columnDef: 'varianceMean',
      columnType: ColumnType.VARIANCE
    }
  ];
}

export function getWRVUProviderColumns(): BaseColumn[] {
  return [
    {
      header: 'Groups',
      columnDef: 'nodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'Provider',
      columnDef: 'providerNodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'Specialty',
      columnDef: 'specialtyNodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'Department',
      columnDef: 'departmentNodeName',
      columnType: ColumnType.NODE_NAME
    },
    {
      header: 'cFTE Adj. wRVUs',
      columnDef: 'cfteAdjustedWRVUs'
    },
    {
      header: 'Actual wRVUs',
      columnDef: 'wRVUs'
    },
    {
      columnDef: 'benchmarkPercentileRank',
      header: 'cFTE adj. wRVU Provider Percentile',
      columnType: ColumnType.PERCENTILE
    },
    {
      header: 'cFTE',
      columnDef: 'cfte',
      columnType: ColumnType.CFTE
    },
    {
      header: 'Charges',
      columnDef: 'charges',
      columnType: ColumnType.CHARGES
    },
    {
      header: 'Previous Date Range cFTE Adj. wRVUs',
      columnDef: 'previousCfteAdjustedWRVUs',
      columnType: ColumnType.PREVIOUS
    },
    {
      header: 'cFTE Adj. wRVUs Difference from Previous Date Range',
      columnDef: 'difference',
      columnType: ColumnType.DIFFERENCE
    },
    {
      header: 'Imputed/ Reported cFTE (Mean)',
      columnDef: 'imputedReportedcFTEMean',
      columnType: ColumnType.IMPUTED
    },
    {
      header: 'wRVU Benchmark (Mean)',
      columnDef: 'benchmarkMean',
      columnType: ColumnType.BENCHMARK
    },
    {
      header: 'Variance (Mean)',
      columnDef: 'varianceMean',
      columnType: ColumnType.VARIANCE
    }
  ];
}

export function getWRVUSpecialtyPerformanceColumns(): BaseColumn[] {
  return specialtyPerformanceColumns;
}

export function getWRVUTrendColumns(): BaseColumn[] {
  return [
    {header: 'Year', columnDef: 'year', columnType: ColumnType.PRIMARY_DATE},
    {header: 'Month', columnDef: 'month'},
    {header: 'cFTE Adj. wRVUs', columnDef: 'cfteAdjustedWRVUs'},
    {header: 'Academic wRVU Benchmark (Mean)', columnDef: 'benchmarkMean', columnType: ColumnType.BENCHMARK},
    {header: 'Actual wRVUs', columnDef: 'wRVUs'},
    {
      header: 'cFTE', columnDef: 'cfte', columnType: ColumnType.CFTE,
    },
    {header: 'Charges', columnDef: 'charges', columnType: ColumnType.CHARGES},
    {header: 'Variance from Benchmark (Mean)', columnDef: 'varianceMean', columnType: ColumnType.VARIANCE},
    {header: 'Previous Date Range cFTE Adj. wRVUs', columnDef: 'previousCfteAdjustedWRVUs'},
    {header: 'cFTE Adj. wRVUs Difference from Previous Date Range', columnDef: 'difference'}
  ];
}

export function getSharedNpvColumns(): BaseColumn[] {
  return [
    {header: '% New Patient Visits', columnDef: 'newPatientVisitsPercentage'},
    {header: '# of New Patients', columnDef: 'countOfNewPatientVisits'},
    {header: 'Total # of Patients', columnDef: 'countOfTotalPatientVisits'},
    {
      header: '% New Patients Academic Benchmark Mean', columnDef: 'benchmarkMean',
      columnType: ColumnType.BENCHMARK
    },
    {header: 'NPV Variance Mean', columnDef: 'varianceMean', columnType: ColumnType.VARIANCE},
    {header: 'Previous Dates % New Patients', columnDef: 'previousNewPatientVisitsPercentage'},
    {
      header: '% New Patient Visits Difference from Previous Date Range',
      columnDef: 'difference'
    }
  ];
}

export function getNpvSnapshotColumns(): BaseColumn[] {
  return [
    {header: 'Groups', columnDef: 'nodeName'},
    ...getSharedNpvColumns()
  ];
}

export function getNpvByDepartmentColumns(): BaseColumn[] {
  return [
    {header: 'Department', columnDef: 'departmentNodeName'},
    ...getSharedNpvColumns()
  ];
}

export function getNpvBySpecialtyColumns(): BaseColumn[] {
  return [
    {header: 'Specialty', columnDef: 'specialtyNodeName'},
    ...getNpvByDepartmentColumns()
  ];
}

export function getNpvByProviderColumns(): BaseColumn[] {
  return [
    {header: 'Provider', columnDef: 'providerNodeName'},
    ...getNpvBySpecialtyColumns()
  ];
}

export function getNpvTrendColumns(): BaseColumn[] {
  return [
    {header: 'Year', columnDef: 'year'},
    {header: 'Month', columnDef: 'month'},
    {header: '% New Patient Visits', columnDef: 'newPatientVisitsPercentage'},
    {header: '# of New Patients', columnDef: 'countOfNewPatientVisits'},
    {header: '# of Total Patients', columnDef: 'countOfTotalPatientVisits'},
    {
      header: '% New Patients Academic Benchmark Mean', columnDef: 'benchmarkMean',
      columnType: ColumnType.BENCHMARK
    },
    {
      header: 'Variance from Benchmark Mean', columnDef: 'varianceMean',
      columnType: ColumnType.VARIANCE
    },
    {header: 'Previous Dates % New Patients', columnDef: 'previousNewPatientVisitsPercentage'},
    {header: '% New Patient Visits Difference from Previous Date Range', columnDef: 'difference'}
  ];
}

export function aDisplayedColumns(): BaseColumn[] {
  return [
    {header: 'a header', columnDef: 'a column name'},
    {header: 'a second header', columnDef: 'a second column name', columnType: ColumnType.PAYER},
  ];
}
