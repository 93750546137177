import {Pipe, PipeTransform} from '@angular/core';
import {DateRange} from './models';
import {getSelectedDateRange} from './helpers';

@Pipe({name: 'dateRangeText'})
export class DateRangeTextPipe implements PipeTransform {
  transform(value: DateRange, isAbbreviated = true): string {
    return getSelectedDateRange(value, isAbbreviated);
  }
}
