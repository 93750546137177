import {Observable} from 'rxjs';
import {Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.local';
import {FilterCriteria} from '../../store/IAppState';
import {map} from 'rxjs/operators';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const UpdatedCustomGroupServiceToken = new InjectionToken<UpdatedCustomGroupService>(
  'Updated Custom Group Service');

export interface UpdatedCustomGroupService {
  saveEveryFilterCustomGroup(filter: FilterCriteria): Observable<FilterCriteria>;
  getEveryFilterCustomGroups(memberKey: number): Observable<FilterCriteria[]>;
}

@Injectable()
export class HttpUpdatedCustomGroupService implements UpdatedCustomGroupService {
  constructor(private httpClient: HttpClient) { }

  saveEveryFilterCustomGroup(filter: FilterCriteria): Observable<FilterCriteria> {
    const baseUrl = `${environment.api}/api/saveEveryFilterCustomGroup`;
    const postBody = getRequestBodyFromFilter(filter);
    return this.httpClient.post<FilterCriteria>(
      baseUrl,
      postBody,
      {
        params: new HttpParams()
          .set('memberKey', filter.memberKey.toString())
      },
    );
  }

  getEveryFilterCustomGroups(memberKey: number): Observable<FilterCriteria[]> {
    const baseUrl = `${environment.api}/api/customGroups?memberKey=${memberKey}`;
    return this.httpClient.get<FilterCriteria[]>(
      baseUrl
    ).pipe(map((data) => {
      data.forEach((customGroup: FilterCriteria) => customGroup.memberKey = memberKey);
      return data;
    }));
  }
}
