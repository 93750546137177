import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CptViewType, GoogleAnalyticCategories, NodeExpansionState} from '../../../shared/enums';
import {
  CfpByMultilevel,
  ClinicalSummaryCode,
  ClinicalSummaryFamily,
  ClinicalSummaryRange,
  columnsForAvailableCptCodes,
  columnsForSelectedCptCodes
} from '../../ClinicalSummary';
import {MatTableDataSource} from '@angular/material/table';
import {SearchCptRangeComponent} from './search-cpt-range/search-cpt-range.component';
import {SearchCptFamilyComponent} from './search-cpt-family/search-cpt-family.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {RelatableSelectableItem} from '../../../filter-banner/filter-banner-helper';
import {isItemAbleToShow, ScenarioForDisplayability} from '../../../filter-banner/search-payer/PayerSetupHelper';
import {CptGroup, getCptGroupFromSelectedAndUnselectedCodes} from '../ProcedureSummaryModels';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {hasValue} from '../../../shared/null-helpers';
import {pluralizeText} from '../../../shared/helpers';
import {
  ProcedureSummaryService,
  ProcedureSummaryServiceToken
} from '../procedure-summary-services/procedure-summary.service';
import {SaveApplyDialogComponent} from '../../../shared/components/save-apply-dialog/save-apply-dialog.component';
import {GroupDialogEvent} from '../../../updated-filter-banner/manage-groups-helper';
import {
  UpdatedSaveGroupingComponent
} from '../../../updated-filter-banner/updated-save-grouping/updated-save-grouping.component';
import {cptGroupingsChangedTo, selectedCptGroupChangedTo} from '../../../store/actions';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';

@Component({
  selector: 'app-procedure-summary-filter',
  templateUrl: './procedure-summary-filter.component.html',
  styleUrls: ['./procedure-summary-filter.component.scss']
})

export class ProcedureSummaryFilterComponent implements OnInit, OnDestroy {
  anyFamiliesSelected = true;
  anyRangesSelected = true;
  availableCptCodes: RelatableSelectableItem<ClinicalSummaryCode>[] = [];
  selectedCptCodes: RelatableSelectableItem<ClinicalSummaryCode>[] = [];
  listOfCptCodes: RelatableSelectableItem<ClinicalSummaryCode>[] = [];
  listOfCptFamilies: RelatableSelectableItem<ClinicalSummaryFamily>[] = [];
  listOfCptRanges: RelatableSelectableItem<ClinicalSummaryRange>[] = [];
  applicableRanges: RelatableSelectableItem<ClinicalSummaryRange>[] = [];
  showCptFamilies = false;
  showCptRanges = false;
  searchText = '';
  readonly minSearchChars = 3;
  @ViewChild(SearchCptFamilyComponent) searchFamilyComponent: SearchCptFamilyComponent;
  @ViewChild(SearchCptRangeComponent) searchRangeComponent: SearchCptRangeComponent;
  private NUMBER_AVAILABLE_CPT_CODE_TO_SHOW = 50;
  private NUMBER_SELECTED_CPT_CODE_TO_SHOW = 50;
  private readonly NUMBER_TO_LOAD = 20;
  readonly columnsForAvailableCptCodes = columnsForAvailableCptCodes;
  displayedAvailableCptCodeColumns = columnsForAvailableCptCodes.map(c => c.columnDef);
  readonly columnsForSelectedCptCodes = columnsForSelectedCptCodes;
  displayedSelectedCptCodeColumns = columnsForSelectedCptCodes.map(c => c.columnDef);
  dataSourceAvailableCptCodes: MatTableDataSource<RelatableSelectableItem<ClinicalSummaryCode>>;
  dataSourceSelectedCptCode: MatTableDataSource<RelatableSelectableItem<ClinicalSummaryCode>>;
  @select(['data', 'cptGroupings'])
  private readonly cptGroupings$: Observable<CptGroup[]>;
  @select(['display', 'selectedCptGroup'])
  private readonly selectedCptGroup$: Observable<CptGroup | undefined>;
  cptGroupsSubscription: Subscription;
  customGroups: CptGroup[] = [];
  currentSelectedGroup: CptGroup | undefined;
  defaultGroup: CptGroup | undefined = undefined;
  displayProgressBarForGroups = true;
  hasAlreadyDefaulted = false;
  familySelectedCount = 0;
  rangeSelectedCount = 0;
  codeSelectedCount = 0;
  pluralizeText = pluralizeText;
  dialogActionListener: BehaviorSubject<GroupDialogEvent>;
  private dialogActionSubscription: Subscription;

  private static hasVisitedThisRange(cptRanges: any, current: CfpByMultilevel) {
    return !!cptRanges[current.cptRangeDesc];
  }

  private static hasVisitedThisFamily(cptFamilies: any, current: CfpByMultilevel) {
    return !!cptFamilies[current.cptFamilyDesc];
  }

  private static createNewCodeObjectWithDefaultDisplayabilityValues(current: CfpByMultilevel, cptFamilies: any, cptRanges: any) {
    return {
      item: {
        selected: false, belongs: true, matchesSearchText: true, item: <ClinicalSummaryCode>current, key: 0,
        displayText: current.cptCode, expansion: {
          state: NodeExpansionState.LEAF,
          depth: 2
        }
      },
      relatives: [cptFamilies[current.cptFamilyDesc].item, cptRanges[current.cptRangeDesc].item]
    };
  }

  private static createNewRangeObjectWithDefaultDisplayabilityValues(current: CfpByMultilevel, cptFamilies: any) {
    return {
      item: {
        selected: false, belongs: true, matchesSearchText: true, item: <ClinicalSummaryRange>current, key: 0,
        displayText: current.cptRangeDesc, expansion: {
          state: NodeExpansionState.LEAF,
          depth: 1
        }
      }, relatives: [cptFamilies[current.cptFamilyDesc].item]
    };
  }

  private static createNewFamilyObjectWithDefaultDisplayabilityValues(current: CfpByMultilevel) {
    return {
      item: {
        selected: false, belongs: true, matchesSearchText: true, item: <ClinicalSummaryFamily>current, key: 0,
        displayText: current.cptFamilyDesc, expansion: {
          state: NodeExpansionState.LEAF,
          depth: 0
        }
      }, relatives: []
    };
  }

  constructor(protected cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) private data: CfpByMultilevel[],
              public dialog: MatDialog, private ngRedux: NgRedux<IAppState>,
              @Inject(ProcedureSummaryServiceToken) private readonly procedureSummaryService: ProcedureSummaryService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
              public dialogRef: MatDialogRef<ProcedureSummaryFilterComponent>
  ) { }

  ngOnInit(): void {
    this.anyFamiliesSelected = false;
    this.anyRangesSelected = false;
    this.dialogActionListener = new BehaviorSubject<GroupDialogEvent>(GroupDialogEvent.INITIAL);
    this.listenForDialogActions();
    this.initializeCpts();
    this.markCodesBelongedIfAncestorIsSelected();
    this.filterCptRanges(true);
    this.filterCptCodes();
    this.cptGroupsSubscription = combineLatest([this.cptGroupings$, this.selectedCptGroup$]).subscribe(
      ([groups, currentGroup]: [CptGroup[], CptGroup | undefined]) => {
      this.customGroups = groups;
        const matchingGroup = groups && groups.find(x => x.id === (this.currentSelectedGroup ?
          this.currentSelectedGroup.id : -1));
      if (groups && this.currentSelectedGroup && !matchingGroup) {
        this.hasAlreadyDefaulted = false;
        this.currentSelectedGroup = undefined;
      } else if (groups && this.currentSelectedGroup && matchingGroup && matchingGroup.name.localeCompare(
        this.currentSelectedGroup.name) !== 0) {
        this.currentSelectedGroup.name = matchingGroup.name;
      }
      this.defaultGroup = (groups || []).find(g => g.isDefault);
      if (!this.hasAlreadyDefaulted && groups) {
        this.hasAlreadyDefaulted = true;
        this.selectGroup(this, currentGroup);
      }
      this.displayProgressBarForGroups = !hasValue(groups);
    });
  }

  ngOnDestroy() {
    this.cptGroupsSubscription?.unsubscribe();
    this.dialogActionSubscription?.unsubscribe();
  }

  getSelectedCountDisplay(count: number, list: RelatableSelectableItem<any>[]): string {
    return count === 0 || count === list.length ? 'All' : count + '';
  }

  restoreDefault(): void {
    this.selectGroup(this, this.defaultGroup);
  }

  private getDialogActionListener(): Observable<GroupDialogEvent> {
    return this.dialogActionListener.asObservable();
  }

  private resetDataSources(): void {
    this.dataSourceAvailableCptCodes = new MatTableDataSource<RelatableSelectableItem<ClinicalSummaryCode>>
    (this.availableCptCodes.slice(0, this.NUMBER_AVAILABLE_CPT_CODE_TO_SHOW));
    this.dataSourceSelectedCptCode = new MatTableDataSource<RelatableSelectableItem<ClinicalSummaryCode>>
    (this.selectedCptCodes.slice(0, this.NUMBER_SELECTED_CPT_CODE_TO_SHOW));
    this.codeSelectedCount = this.selectedCptCodes.length;
  }

  handleScrollAvailableCodes(event?: any): void {
    if (this.dataSourceAvailableCptCodes.data.length < this.availableCptCodes.length) {
      const table: any | undefined = event ? event.target : undefined;
      if (table && (table.scrollTop > 0.9 * (table.scrollHeight - table.clientHeight))) {
        this.NUMBER_AVAILABLE_CPT_CODE_TO_SHOW += this.NUMBER_TO_LOAD;
        this.resetDataSources();
      }
    }
  }

  handleScrollSelectedCodes(event?: any): void {
    if (this.dataSourceSelectedCptCode.data.length < this.selectedCptCodes.length) {
      const table: any | undefined = event ? event.target : undefined;
      if (table && (table.scrollTop > 0.9 * (table.scrollHeight - table.clientHeight))) {
        this.NUMBER_SELECTED_CPT_CODE_TO_SHOW += this.NUMBER_TO_LOAD;
        this.resetDataSources();
      }
    }
  }

  onSearchTextChanged() {
    this.listOfCptCodes.forEach(code => {
      code.item.matchesSearchText = this.searchText.length < this.minSearchChars ||
        !!code.item.item.cptCode?.toLowerCase().includes(this.searchText.toLowerCase());
    });
    this.filterCptCodes();
  }

  private initializeCpts(): void {
    const cptFamilies: any = {};
    const cptRanges: any = {};
    this.data.forEach(current => {
      if (!ProcedureSummaryFilterComponent.hasVisitedThisFamily(cptFamilies, current)) {
        this.addFamilyToCptStructure(current, cptFamilies);
      }
      if (!ProcedureSummaryFilterComponent.hasVisitedThisRange(cptRanges, current)) {
        this.addRangeToCptStructure(current, cptFamilies, cptRanges);
      }
      this.addCodeToCptStructure(current, cptFamilies, cptRanges);
    });
    this.selectedCptCodes = [];
  }

  private addCodeToCptStructure(current: CfpByMultilevel, cptFamilies: any, cptRanges: any) {
    const newCode: RelatableSelectableItem<ClinicalSummaryCode> =
      ProcedureSummaryFilterComponent.createNewCodeObjectWithDefaultDisplayabilityValues(current, cptFamilies, cptRanges);
    this.listOfCptCodes.push(newCode);
    cptFamilies[current.cptFamilyDesc].relatives.push(newCode.item);
    cptRanges[current.cptRangeDesc].relatives.push(newCode.item);
  }

  private addRangeToCptStructure(current: CfpByMultilevel, cptFamilies: any, cptRanges: any) {
    const newRange: RelatableSelectableItem<ClinicalSummaryRange> =
      ProcedureSummaryFilterComponent.createNewRangeObjectWithDefaultDisplayabilityValues(current, cptFamilies);
    cptRanges[current.cptRangeDesc] = newRange;
    this.listOfCptRanges.push(newRange);
    cptFamilies[current.cptFamilyDesc].relatives.push(newRange.item);
  }

  private addFamilyToCptStructure(current: CfpByMultilevel, cptFamilies: any) {
    const newFamily: RelatableSelectableItem<ClinicalSummaryFamily> =
      ProcedureSummaryFilterComponent.createNewFamilyObjectWithDefaultDisplayabilityValues(current);
    cptFamilies[current.cptFamilyDesc] = newFamily;
    this.listOfCptFamilies.push(newFamily);
  }

  whenCptCodeSelected(event: MouseEvent, code: RelatableSelectableItem<ClinicalSummaryCode>): void {
    event.preventDefault();
    code.item.selected = true;
    const codeItem = code.item.item;
    this.availableCptCodes = this.availableCptCodes.filter(c => {
      const availableItem = c.item.item;
      return !(availableItem.cptCode === codeItem.cptCode && availableItem.cptRangeDesc === codeItem.cptRangeDesc
        && availableItem.cptFamilyDesc === codeItem.cptFamilyDesc);
    });
    this.selectedCptCodes.push(code);
    this.currentSelectedGroup = undefined;
    this.resetDataSources();
  }

  whenCptCodeDeselected(event: MouseEvent, code: RelatableSelectableItem<ClinicalSummaryCode>): void {
    event.preventDefault();
    code.item.selected = false;
    const codeItem = code.item.item;
    this.selectedCptCodes = this.selectedCptCodes.filter(c => {
      const selectedItem = c.item.item;
      return !(selectedItem.cptCode === codeItem.cptCode && selectedItem.cptRangeDesc === codeItem.cptRangeDesc
        && selectedItem.cptFamilyDesc === codeItem.cptFamilyDesc);
    });
    if (isItemAbleToShow(code, ScenarioForDisplayability.Standard)) {
      this.availableCptCodes.push(code);
    }
    this.currentSelectedGroup = undefined;
    this.resetDataSources();
  }

  selectAllCpts(event: MouseEvent) {
    event.preventDefault();
    this.availableCptCodes.forEach(code => {
      code.item.selected = true;
    });
    this.selectedCptCodes = this.selectedCptCodes.concat(this.availableCptCodes);
    this.availableCptCodes = [];
    this.currentSelectedGroup = undefined;
    this.resetDataSources();
  }

  clearSearchText(): void {
    this.searchText = '';
    this.onSearchTextChanged();
  }

  clearSelections(event: MouseEvent): void {
    this.listOfCptFamilies.concat(this.listOfCptRanges).forEach(x => {
      x.item.selected = false;
      x.item.originallySelected = false;
    });
    this.anyFamiliesSelected = false;
    this.anyRangesSelected = false;
    this.deselectAllCpts(event);
    this.markCodesBelongedIfAncestorIsSelected();
    this.filterCptRanges(true);
    this.filterCptCodes();
    this.searchFamilyComponent.ngOnInit();
    this.searchRangeComponent.ngOnChanges();
    this.familySelectedCount = 0;
    this.rangeSelectedCount = 0;
  }

  deselectAllCpts(event: MouseEvent) {
    event.preventDefault();
    const unselected: RelatableSelectableItem<ClinicalSummaryCode>[] = [];
    this.selectedCptCodes.forEach(code => {
      code.item.selected = false;
      unselected.push(code);
    });
    this.selectedCptCodes = [];
    unselected.forEach(x => {
      if (isItemAbleToShow(x, ScenarioForDisplayability.Standard)) {
        this.availableCptCodes.push(x);
      }
    });
    this.currentSelectedGroup = undefined;
    this.resetDataSources();
  }

  whenCptFamiliesUpdated(event: number): void {
    this.anyFamiliesSelected = event > 0;
    this.familySelectedCount = event;
    this.filterCptRanges(true);
    this.searchRangeComponent.ngOnChanges();
    this.rangeSelectedCount = this.listOfCptRanges.filter(r => r.item.selected).length;
    this.markCodesBelongedIfAncestorIsSelected();
    this.filterCptCodes();
  }

  whenCptRangesUpdated(event: number): void {
    this.anyRangesSelected = event > 0;
    this.rangeSelectedCount = event;
    this.markCodesBelongedIfAncestorIsSelected();
    this.filterCptCodes();
  }

  whenCptFamiliesToggled = (open: boolean) => {
    this.showCptFamilies = open;
    this.cdr.detectChanges();
  };

  whenCptRangesToggled = (open: boolean) => {
    this.showCptRanges = open;
    this.cdr.detectChanges();
  };

  generateCptGroup(): CptGroup {
    const email = this.ngRedux.getState().data.userProfile.email;
    const memberKey = this.ngRedux.getState().filters.memberKey;
    const unselected = this.listOfCptCodes.filter(x => !x.item.selected);
    return getCptGroupFromSelectedAndUnselectedCodes(this.selectedCptCodes.map(c => c.item),
      unselected.map(c => c.item), email, memberKey, '', false,
      this.applicableRanges.map(r => r.item.item.cptRangeDesc), this.currentSelectedGroup);
  }

  private guaranteeACptGroup(): CptGroup {
    this.currentSelectedGroup = this.currentSelectedGroup || this.generateCptGroup();
    return this.currentSelectedGroup;
  }

  onApply(): void {
    if (!this.currentSelectedGroup && this.selectedCptCodes.length) {
      this.dialog.open(SaveApplyDialogComponent, {
        data: {
          title: 'Save changes?',
          question: 'Would you like to save the selected cpt codes?',
          confirmButtonAltText: 'Save',
          dialogActionListener: this.dialogActionListener
        }
      });
    } else {
      this.confirmApply(this.selectedCptCodes.length > 0 ? this.guaranteeACptGroup() : undefined);
    }
  }

  private listenForDialogActions(): void {
    this.dialogActionSubscription = this.getDialogActionListener().subscribe((value: GroupDialogEvent) => {
      switch (value) {
        case GroupDialogEvent.APPLY:
          this.confirmApply(this.guaranteeACptGroup());
          break;
        case GroupDialogEvent.TENTATIVE_SAVE:
          this.openSaveDialog();
          break;
        case GroupDialogEvent.DEFINITE_SAVE:
          this.confirmSaveGroup(this.guaranteeACptGroup());
          break;
        case GroupDialogEvent.UPDATE:
          this.confirmUpdateGroup(this.guaranteeACptGroup());
      }
    });
  }

  openSaveDialog(): void {
    if (!this.selectedCptCodes.length) {
      return;
    }
    this.dialog.open(UpdatedSaveGroupingComponent, {
      data: {
        group: this.guaranteeACptGroup(),
        existingGroups: this.customGroups,
        dialogActionListener: this.dialogActionListener
      }});
  }

  confirmSaveGroup(group: CptGroup): void {
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ProcedureSummarySnapshot,
      'Procedure Summary Groupings', 'Saving CPT Groupings');
    this.procedureSummaryService.saveCptGrouping(group).subscribe((updatedGroup: CptGroup) => {
        this.postSaveOfAGroup(updatedGroup);
      });
  }

  confirmUpdateGroup(group: CptGroup): void {
    this.procedureSummaryService.updateCptGroup(group, group.name, group.isDefault, true)
      .subscribe((updatedGroup: CptGroup) => {
        this.postSaveOfAGroup(updatedGroup);
      });
  }

  confirmApply(newGroup?: CptGroup): void {
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ProcedureSummarySnapshot,
      'Procedure Summary Groupings', 'Unsave CPT Groupings');
    this.ngRedux.dispatch(selectedCptGroupChangedTo(newGroup));
    this.dialogRef.close();
  }

  private postSaveOfAGroup(group: CptGroup): void {
    this.ngRedux.dispatch(selectedCptGroupChangedTo(group));
    this.procedureSummaryService.getCptGroupings(group.memberKey).subscribe((newlyFormedGroups: CptGroup[]) => {
      this.ngRedux.dispatch(cptGroupingsChangedTo(newlyFormedGroups));
    });
    this.dialogRef.close();
  }

  selectGroup(component: ProcedureSummaryFilterComponent, group?: CptGroup): void {
    component.listOfCptCodes.forEach(c => {
      c.item.selected = false;
    });
    component.listOfCptRanges.forEach(r => {
      r.item.selected = false;
    });
    component.listOfCptFamilies.forEach(f => {
      f.item.selected = false;
    });
    component.currentSelectedGroup = group;
    group?.entries.forEach(entry => {
      let foundItem;
      switch (entry.cptViewType) {
        case CptViewType.CptFamily:
          foundItem = component.listOfCptFamilies.find(f => f.item.item.cptFamilyDesc === entry.cptDesc);
          break;
        case CptViewType.CptRange:
          foundItem = component.listOfCptRanges.find(r => r.item.item.cptRangeDesc === entry.cptDesc);
          break;
        case CptViewType.CptCode:
          foundItem = component.listOfCptCodes.find(c => c.item.item.cptCode === entry.cptDesc);
      }
      if (foundItem) {
        foundItem.item.selected = true;
        foundItem.item.originallySelected = true;
        foundItem.relatives.forEach(rel => {
          rel.selected = true;
          rel.originallySelected = true;
        });
      }
    });
    component.searchRangeComponent?.ngOnChanges();
    component.searchFamilyComponent?.ngOnInit();
    component.anyFamiliesSelected = !!component.listOfCptFamilies.find(f => f.item.selected);
    component.anyRangesSelected = !!component.listOfCptRanges.find(r => r.item.selected);
    component.markCodesBelongedIfAncestorIsSelected();
    component.filterCptRanges(false);
    component.filterCptCodes();
    component.familySelectedCount = component.listOfCptFamilies.filter(f => f.item.selected).length;
    component.rangeSelectedCount = component.listOfCptRanges.filter(r => r.item.selected).length;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  filterCptCodes(): void {
    this.showAvailableUnselectedCptCodes();
    this.showSelectedCptCodes();
    this.resetDataSources();
  }

  private showSelectedCptCodes() {
    this.selectedCptCodes = this.listOfCptCodes.filter(c => c.item.selected);
  }

  private showAvailableUnselectedCptCodes() {
    this.availableCptCodes = this.listOfCptCodes.filter((c) => {
      return !c.item.selected && isItemAbleToShow(c, this.searchText.length >= this.minSearchChars ?
        ScenarioForDisplayability.MatchSearchTextRegardless : ScenarioForDisplayability.Standard);
    });
  }

  private markCodesBelongedIfAncestorIsSelected() {
    this.listOfCptCodes.forEach(p => {
      p.item.belongs = this.hasSelectedRelative(p);
    });
  }

  filterCptRanges(autoSelect: boolean): void {
    this.anyRangesSelected = false;
    this.listOfCptRanges.forEach(s => {
      const relatedSelectedFamily = s.relatives.find(relative => relative.selected);
      s.item.belongs = !this.anyFamiliesSelected ? true : !!relatedSelectedFamily;
      if (autoSelect) {
        s.item.selected = !this.anyFamiliesSelected ? false : !!relatedSelectedFamily;
      }
      s.item.originallySelected = s.item.selected;
      if (s.item.selected) {
        this.anyRangesSelected = true;
      }
    });
    this.applicableRanges = this.listOfCptRanges.filter(i => i.item.belongs);
  }

  private hasSelectedRelative(p: RelatableSelectableItem<ClinicalSummaryCode>): boolean {
    return this.anyFamiliesSelected && !this.anyRangesSelected ? !!p.relatives.find(r =>
      r.expansion && r.expansion.depth === 0 && r.selected) : !this.anyFamiliesSelected && this.anyRangesSelected ?
      !!p.relatives.find(r => r.expansion && r.expansion.depth === 1 && r.selected) :
      !!p.relatives.find(r => r.expansion && r.expansion.depth === 0 && r.selected) &&
      !!p.relatives.find(r => r.expansion && r.expansion.depth === 1 && r.selected);
  }
}
