
<div class="eye-exam-summary-table" *ngIf="displayedData">
<div class="table-header-wrapper">
  <div class="summaryHeader bold">Ophthalmology E&M Summary</div>
</div>
  <div class = "header-name">
        <span class = "eyeexam-header1">
          Eye Exam Visit New 92002 - 92004
        </span>
    <span class = "eyeexam-header2">
          Eye Exam Visit Established 92012 - 92014
        </span >
  </div>

  <mat-table [dataSource]="dataSource">
    <ng-container  matColumnDef="encounter">
      <mat-header-cell *matHeaderCellDef class="headerEncounter">Metrics</mat-header-cell>
      <mat-cell *matCellDef="let row" class="encounter">{{row.encounterMetric}}</mat-cell>
    </ng-container>

    <ng-container  matColumnDef="countOf92002">
      <mat-header-cell *matHeaderCellDef class="header92002">92002</mat-header-cell>
      <mat-cell *matCellDef="let row"  class="countOf92002">{{isValidOrElse(row, 'eyeExamNewPatients', 'cpt92002', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf92004">
      <mat-header-cell *matHeaderCellDef class="header92004">92004</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf92004">{{isValidOrElse(row, 'eyeExamNewPatients', 'cpt92004', '-')}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalEyeExamNewPatients">
      <mat-header-cell *matHeaderCellDef class="headerTotalEyeExamNewPatients">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalEyeExamNewPatients">{{isValidOrElse(row, 'eyeExamNewPatients', 'total', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf92012">
      <mat-header-cell *matHeaderCellDef class="header92012">92012</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf92012">{{isValidOrElse(row, 'eyeExamEstablishedPatients', 'cpt92012', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf92014">
      <mat-header-cell *matHeaderCellDef class="header92014">92014</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf92014">{{isValidOrElse(row, 'eyeExamEstablishedPatients', 'cpt92014', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalEyeExamEstablishedPatients">
      <mat-header-cell *matHeaderCellDef class="headerTotalEyeExamEstablishedPatients">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalEyeExamEstablishedPatients">{{isValidOrElse(row, 'eyeExamEstablishedPatients', 'total', '-')}}
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row  *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <app-loader  [class.loader-hidden]="!showProgressBar"></app-loader>

</div>

