<mat-button-toggle-group name="fontStyle" aria-label="Font Style">
  <mat-button-toggle [disabled]='activeVarianceToggle'
                     (change)="showActualWrvuGraph()"
                     (mouseenter)="toggleShowHover(true)"
                     [checked]="showActual || !canShowCfteAdjustedWrvus"
                     (mouseleave)="toggleShowHover(false)"
                     [ngClass]="activeVarianceToggle ? 'hideActual' : 'showCfte'">
    Actual wRVUs
  </mat-button-toggle>
  <mat-button-toggle *ngIf="canShowCfteAdjustedWrvus"
                     (change)="showCfteAdjustedWrvuGraph()"
                     [checked]="!showActual"
                     class="chooseCfte"
                     (mouseenter)="toggleShowHover(true)"
                     (mouseleave)="toggleShowHover(false)">
    cFTE adj. wRVUs
  </mat-button-toggle>
</mat-button-toggle-group>
<div class="hover-display" *ngIf="activeVarianceToggle && page === WrvuSnapshot && showHover">
  {{actualText}}
  <br/>
  <br/>
</div>
