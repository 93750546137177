import {BaseColumn, SummaryData} from '../../store/IAppState';
import {
  CollectionsSummaryForAllTypes,
  CollectionSummaryData,
  MatchedCollectionsByMultiLevelByNodePath,
  PayerByMultiLevelCollectionsByNodePath,
  PayerCollections
} from '../../collections/Collection';
import {Export, ExportMetadata} from '../../shared/export/export';
import {DataTableColumns} from '../../shared/data-table-columns';
import {
  BenchmarkPercentilesForCollection,
  createCollectionsVarianceColumnFor,
  createNCRBenchmarkColumnFor
} from '../../shared/BenchmarkColumns';
import {ColumnType} from '../../shared/enums';
import {populateCollectionSummaryData} from '../../collections/summary-collections/summary-collections-table/summary-collections-table.component';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {
  collectionByDepartmentDataExcelData$,
  collectionByPayerByDepartmentDataExcelData$,
  collectionByPayerByProviderDataExcelData$,
  collectionByPayerBySpecialtyDataExcelData$,
  collectionByPayerDataExcelData$,
  collectionByProviderDataExcelData$,
  collectionBySpecialtyDataExcelData$,
  collectionSummaryDataExcelData$
} from '../export-subscribers';
import {BehaviorSubject} from 'rxjs';
import {toTitleCase} from '../../shared/helpers';

function insertAllBenchmarkDependantColumnsForCollection(benchmarkPercentiles: BenchmarkPercentile[],
                                                         columns: DataTableColumns[]): DataTableColumns[] {
  const benchmarkColumns: DataTableColumns[] = [];
  const varianceColumns: DataTableColumns[] = [];
  benchmarkPercentiles.forEach(bcm => {
    benchmarkColumns.push(createNCRBenchmarkColumnFor(bcm));
    varianceColumns.push(createCollectionsVarianceColumnFor(bcm));
  });
  const hasBenchmark = columns.find(col => col.columnType === ColumnType.BENCHMARK);
  if (hasBenchmark) {
    columns = columns.filter(col => col.columnType !== ColumnType.BENCHMARK);
    benchmarkColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasVariance = columns.find(col => col.columnType === ColumnType.VARIANCE);
  if (hasVariance) {
    columns = columns.filter(col => col.columnType !== ColumnType.VARIANCE);
    varianceColumns.forEach(varCol => {
      columns.push(varCol);
    });
  }
  return columns;
}

export async function generateExcelCollectionsSnapshotDataForDisplayedColumns(
  collections: MatchedCollectionsByMultiLevelByNodePath[],
  columns: DataTableColumns[], displayedColumns: BaseColumn[], level: string) {
  columns = columns.filter(col => col.primaryColumn || displayedColumns.find(c => c.columnDef === col.columnDef
    || (c.columnType === col.columnType && c.columnType !== undefined))).slice();
  columns = insertAllBenchmarkDependantColumnsForCollection(BenchmarkPercentilesForCollection, columns);
  const copyRight = 'Clinical Practice Solutions Center Collections ' + new Date().toLocaleString();
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (level) {
    case 'department':
      designatedObservable = collectionByDepartmentDataExcelData$;
      break;
    case 'specialty':
      designatedObservable = collectionBySpecialtyDataExcelData$;
      break;
    case 'provider':
      designatedObservable = collectionByProviderDataExcelData$;
  }
  designatedObservable.next({
    detailHeaders: columns.map(c => c.header),
    detailData: collections.map(collection => {
      return generateRowForCollectionSnapshot(collection, columns);
    }),
    fileName: ('Collections by ' + level),
    page: 'Collections by ' + level,
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showLag: true,
      showBilling: true,
      showInvoice: true,
      showPayer: true
    },
    sheetName: toTitleCase('Collections By ' + level)
  });
}

export async function generateExcelCollectionSummaryData(summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>,
                                                         summaryColumns: DataTableColumns[]) {
  const summaryData = populateCollectionSummaryData(summaryCollectionsData);
  const copyRight = 'Clinical Practice Solutions Center Collections ' + new Date().toLocaleString();
  collectionSummaryDataExcelData$.next({
    detailHeaders: summaryColumns.map(c => c.header),
    detailData: summaryData.map(collection => {
      return generateRowForCollectionSummary(collection, summaryColumns);
    }),
    fileName: ('Collection Summary'),
    page: 'Collection Summary',
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showLag: true,
      showBilling: true,
      showInvoice: true,
      showPayer: true
    },
    sheetName: 'Collections Summary'
  });
}

export function generateCsvCollectionSummaryData(summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>,
                                                 summaryColumns: DataTableColumns[]): Export {
  const summaryData = populateCollectionSummaryData(summaryCollectionsData);
  const copyRight = 'Clinical Practice Solutions Center Collections ' + new Date().toLocaleString();
  return {
    headers: summaryColumns.map(c => c.header),
    data: summaryData.map(collection => {
      return generateRowForCollectionSummary(collection, summaryColumns);
    }),
    fileName: ('Collection Summary'),
    page: 'Collection Summary',
    title: copyRight,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showLag: true,
      showBilling: true,
      showInvoice: true,
      showPayer: true
    }
  };
}

export async function generateExcelCollectionsPayerData(
  payerCollectionsData: PayerCollections[], columnsForPayerCollections: DataTableColumns[],
  displayedColumns: BaseColumn[]) {
  columnsForPayerCollections = columnsForPayerCollections.filter(col => col.primaryColumn ||
    displayedColumns.find(c => c.columnDef === col.columnDef || (c.columnType === col.columnType &&
      c.columnType !== undefined))).slice();
  columnsForPayerCollections =
    insertAllBenchmarkDependantColumnsForCollection(BenchmarkPercentilesForCollection, columnsForPayerCollections);
  const copyRight = 'Clinical Practice Solutions Center Collections ' + new Date().toLocaleString();
  collectionByPayerDataExcelData$.next({
    detailHeaders: columnsForPayerCollections.map(col => col.header),
    detailData: payerCollectionsData.map(payer => {
      return generateRowForCollectionsByPayer(payer, columnsForPayerCollections);
    }),
    fileName: 'Collection by payer',
    page: 'Collection by payer',
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showLag: true,
      showBilling: true,
      showInvoice: true,
      showPayer: true
    },
    sheetName: 'Collections By Payer'
  });
}

export function generateCsvCollectionsPayerData(
  payerCollectionsData: PayerCollections[], columnsForPayerCollections: DataTableColumns[],
  displayedColumns: BaseColumn[]) {
  // const asExcel = generateExcelCollectionsPayerData(payerCollectionsData, columnsForPayerCollections, displayedColumns);
  // return {
  //   data: asExcel.detailData,
  //   headers: asExcel.detailHeaders || [],
  //   fileName: asExcel.fileName,
  //   page: asExcel.page,
  //   title: asExcel.title,
  //   whatFilters: asExcel.whatFilters
  // };
}

export async function generateExcelCollectionsPayerByLevelData(
  collectionsByPayerByLevel: PayerByMultiLevelCollectionsByNodePath[],
  columnsForPayerByMultilevel: DataTableColumns[],
  displayedColumns: BaseColumn[], level: string) {
  columnsForPayerByMultilevel = columnsForPayerByMultilevel.filter(col => col.primaryColumn ||
    displayedColumns.find(c => c.columnDef === col.columnDef || (c.columnType === col.columnType &&
      c.columnType !== undefined))).slice();
  columnsForPayerByMultilevel =
    insertAllBenchmarkDependantColumnsForCollection(BenchmarkPercentilesForCollection, columnsForPayerByMultilevel);
  const copyRight = 'Clinical Practice Solutions Center Collections ' + new Date().toLocaleString();
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (level) {
    case 'department':
      designatedObservable = collectionByPayerByDepartmentDataExcelData$;
      break;
    case 'specialty':
      designatedObservable = collectionByPayerBySpecialtyDataExcelData$;
      break;
    case 'provider':
      designatedObservable = collectionByPayerByProviderDataExcelData$;
  }
  designatedObservable.next({
    detailHeaders: columnsForPayerByMultilevel.map(col => col.header),
    detailData: collectionsByPayerByLevel.map(payer => {
      return generateRowForCollectionsByPayerByLevel(payer, columnsForPayerByMultilevel);
    }),
    fileName: 'Collection by payer by ' + level,
    page: 'Collection by payer by ' + level,
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showLag: true,
      showBilling: true,
      showInvoice: true,
      showPayer: true
    },
    sheetName: toTitleCase('Collections By Payer By ' + level)
  });
}

export function generateCsvCollectionsPayerByLevelData(
  collectionsByPayerByLevel: PayerByMultiLevelCollectionsByNodePath[],
  columnsForPayerByMultilevel: DataTableColumns[],
  displayedColumns: BaseColumn[], level: string
) {
  // const asExcel = generateExcelCollectionsPayerByLevelData(collectionsByPayerByLevel, columnsForPayerByMultilevel,
  //   displayedColumns, level);
  // return {
  //   data: asExcel.detailData,
  //   headers: asExcel.detailHeaders || [],
  //   fileName: asExcel.fileName,
  //   page: asExcel.page,
  //   title: asExcel.title,
  //   whatFilters: asExcel.whatFilters
  // };
}

function generateRowForCollectionSummary(summaryCollectionsData: CollectionSummaryData,
                                         columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    row.push(col.dataName(summaryCollectionsData));
  });
  return row;
}

function generateRowForCollectionSnapshot(collection: MatchedCollectionsByMultiLevelByNodePath,
                                          columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    row.push(col.dataName(collection));
  });
  return row;
}

function generateRowForCollectionsByPayer(payer: PayerCollections,
                                          columnsForPayerCollections: DataTableColumns[]): string[] {
  const row: string[] = [];
  columnsForPayerCollections.forEach(col => {
    row.push(col.dataName(payer));
  });
  return row;
}

function generateRowForCollectionsByPayerByLevel(payer: PayerByMultiLevelCollectionsByNodePath,
                                                 columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    row.push(col.dataName(payer));
  });
  return row;
}
