<div id="location-component-wrapper"><app-loader *ngIf="showLoadingIndicator"></app-loader>
  <div id="select-location-header">
    <div id="upper-header">Location</div>
  </div>
  <div id="select-location-body">
    <div id="input-location-search-wrapper">
      <input type="search" placeholder="Search..." id="search-for-location"
             (input)="onLocationSearchTextChanged($event)" [ngModel]="locationSearchText">
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>
  </div>

  <mat-list id="list-of-locations">
    <mat-checkbox id="all-locations-option" [checked]="allSelected" (click)="onAllLocationsSelected($event)">Select All Locations</mat-checkbox>
    <div class ="location-option">
      <mat-checkbox *ngFor="let location of sortedLocationsForTable" [checked]="location.selected"
                    (click)="onLocationSelected($event, location)">
        {{location.location.memberLocationName}}</mat-checkbox>
    </div>
  </mat-list>
</div>
