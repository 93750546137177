import {ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy} from '@angular/core';
import {SortingCriterion} from '../../../../shared/models';
import {NgRedux} from '@angular-redux/store';
import {
  AppAction,
  multilevelTabChangedTo,
  NPVByProviderColumnsChangedTo,
  npvSnapshotSortingCriteriaChangedTo,
  patientVisitsCountsDisplayChangedTo
} from '../../../../store/actions';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../../../store/IAppState';
import * as _ from 'lodash';
import {Page} from '../../../../shared/helpers';
import {DisplayField, Variable} from '../../../../variable-container/variable-container.component';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../../../shared/benchmark-types';
import {AnalyticsService, AnalyticsServiceToken} from '../../../../analytics/analytics.service';
import {BenchmarkOption, ColumnType, MultilevelTab, SortingOrder} from '../../../../shared/enums';
import {DrillDownService} from '../../../../services/drilldown.service';
import {NewPatientVisitSnapshotMultiLevel} from '../../npv-models';
import {getDesignatedNpvBenchmarkObject, getDesignatedNpvVarianceObject, getIsDepartment, getIsSpecialty} from '../../npv-helpers';

@Component({
  selector: 'app-new-patient-visits-provider-page',
  templateUrl: './new-patient-visits-provider-page.component.html',
  styleUrls: ['./new-patient-visits-provider-page.component.scss']
})
export class NewPatientVisitsProviderPageComponent implements OnChanges, OnDestroy {
  snapshotNPVColumnsChangedTo = NPVByProviderColumnsChangedTo;
  defaultSortColumn = 'newPatientVisitsPercentage';
  isDepartment = false;
  isSpecialty = false;
  isProvider: boolean;
  sortingDataAccessor: any;

  variables: Variable[] = [{
    name: '# New and Total Patients',
    display: false,
    reducerField: DisplayField.PatientVisitCounts,
    dispatchAction(display: boolean): AppAction {
      return patientVisitsCountsDisplayChangedTo(display);
    }
  }];

  @Input() fromPdfExport = false;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() nodePath: string;
  @Input() isCustom: boolean;
  @Input() zeroSuppression = false;
  @Input() newPatientVisits: NewPatientVisitSnapshotMultiLevel[];
  @Input() columns: any[];
  @Input() displayedColumns: any[];
  @Input() columnHeaders: BaseColumn[];
  @Input() countOfSuppressedEntries = 0;
  @Input() activeVarianceToggle = false;
  @Input() npvSortingCriteria: SortingCriterion | undefined = undefined;
  @Input() showExtendedBenchmarkOptions = false;
  MultilevelTab = MultilevelTab;
  zeroSuppressionCondition = 'a total patient count greater than zero';
  currentPage = Page.NPVSnapshot;
  showProgressBar = false;
  varianceKey = '';
  sortDirection: string;

  constructor(private ngRedux: NgRedux<IAppState>,
              private _changeDetectorRef: ChangeDetectorRef,
              private drillDownService: DrillDownService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnChanges() {
    this.showProgressBar = _.isEqual(this.newPatientVisits,
      INITIAL_STATE.data.newPatientVisitMultilevelData.providerNpvSnapshotData);
    this.analyticsService.handleGoogleAnalytics('Npv Snapshot',
      this.viewCommunityBenchmarks ? 'Community' : 'Academic', 'Toggling Benchmark');
    this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      const asLower = sortHeaderId.toLowerCase();
      const readableName = readableNameOfColumnDef(this.benchmarkPercentile);
      return (sortHeaderId === 'newPatientVisitsPercentage') ? data[this.defaultSortColumn] :
        asLower.includes('benchmark') ? data[getDesignatedNpvBenchmarkObject(this.benchmarkOption)]
          [`benchmark${readableName}`] : asLower.includes('variance')
          ? data[getDesignatedNpvVarianceObject(this.benchmarkOption)][`variance${readableName}`] :
          data[sortHeaderId];
    };
    this.benchmarkPercentile = this.benchmarkPercentile === BenchmarkPercentile.Percentile65th ?
      BenchmarkPercentile.Mean : this.benchmarkPercentile;
    this.isSpecialty = getIsSpecialty(this.nodePath, this.isCustom);
    this.isDepartment = getIsDepartment(this.nodePath, this.isCustom);
    this._changeDetectorRef.detectChanges();
    const varianceColumn = this.columns.find((col: BaseColumn) => col.columnType === ColumnType.VARIANCE);
    this.varianceKey = varianceColumn ? varianceColumn.columnDef : 'varianceMean';
    const npvSortColumn = 'newPatientVisitsPercentage';
    if (this.npvSortingCriteria && this.npvSortingCriteria.columnDef && this.npvSortingCriteria.sortingOrder) {
      const requiredSortingCriteria: SortingCriterion = this.npvSortingCriteria.columnType === ColumnType.VARIANCE ? {
        ...this.npvSortingCriteria,
        columnDef: this.varianceKey
      } : this.npvSortingCriteria;
      this.defaultSortColumn = requiredSortingCriteria.columnDef;
      this.sortDirection = this.npvSortingCriteria.sortingOrder === SortingOrder.DESCENDING ? 'desc' : 'asc';
    } else {
      this.defaultSortColumn = npvSortColumn;
      this.sortDirection = 'desc';
    }
    if (!this.npvSortingCriteria && ['asc', 'desc'].includes(this.sortDirection)) {
      this.ngRedux.dispatch(npvSnapshotSortingCriteriaChangedTo({
        sortingOrder: this.sortDirection === 'asc' ? SortingOrder.ASCENDING : SortingOrder.DESCENDING,
        columnDef: this.defaultSortColumn
      }));
    }
  }

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  levelSpecificHandleSortChange = (newPatientVisitEntries: NewPatientVisitSnapshotMultiLevel[]) => {
    if (!_.isEqual(this.newPatientVisits, newPatientVisitEntries)) {
      this.newPatientVisits = newPatientVisitEntries;
    }
  };

  updateSortingCriteria = (sortingCriteria: SortingCriterion | undefined) => {
    this.ngRedux.dispatch(npvSnapshotSortingCriteriaChangedTo(sortingCriteria));
  };

  tieBreakerProperty = (newPatientVisit: NewPatientVisitSnapshotMultiLevel) => {
    return newPatientVisit.nodeName;
  };

  whenProviderSelected = (newPatientVisitEntry: any, table = true) => {
    if (!newPatientVisitEntry) {
      return;
    }
    this.analyticsService.handleGoogleAnalytics(
      'Npv By Provider', newPatientVisitEntry.nodeName, `Drill down on ${table ? 'Table' : 'Chart'}`);
    this.drillDownService.drillDownIntoNode(newPatientVisitEntry.nodePath, this.nodePath, MultilevelTab.BY_PROVIDER);
    this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.TREND));
  };

  whenBarSelected = (nodePath: string) => {
    this.whenProviderSelected({
      nodeId: 0,
      nodeName: '',
      nodePath: nodePath
    }, false);
  };
}
