<div id="invoice-list-wrapper">
  <span id="header">Invoice Status</span>
  <div id="note">Note: Invoice Status is only applicable to Collections.</div>
  <mat-radio-group id="invoice-list">
    <mat-radio-button [checked]="allSelected" [class.active]="allSelected" [value]="DEFAULT_INVOICE_TEXT"
                      (click)="selectAllInvoices($event)" class="all-option">{{DEFAULT_INVOICE_TEXT}}
    </mat-radio-button>
    <mat-radio-button *ngFor="let status of invoiceStatuses" [checked]="!allSelected && status.item.selected"
                      [class.active]="!allSelected && status.item.selected" [value]="status.item.displayText"
                      (click)="whenInvoiceStatusClicked(status, $event)" class="option">{{status.item.displayText}}
    </mat-radio-button>
  </mat-radio-group>
</div>
