import {DataTableColumns} from '../shared/data-table-columns';
import {
  CustomGroupsDataAllFilters,
  ListItemExpansion,
  MemberBillingArea,
  MemberLocation,
  PayerCategoryLevel
} from '../shared/models';
import {
  DEFAULT_INVOICE_TEXT,
  DEFAULT_LAG_NAME,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_TELEHEALTH_FLAG
} from '../store/DefaultValues';
import {FilterBannerTab, PayerType} from '../shared/enums';
import {hasValue} from '../shared/null-helpers';
import {MIN_SEARCH_CHARS} from '../shared/constants';
import {
  ButtonCptGroupingsComponent
} from '../clinical-fingerprint/procedure-summary/procedure-summary-filter/button-cpt-groupings/button-cpt-groupings.component';
import {CustomGroupSelectComponent} from '../custom-group-select/custom-group-select.component';

export interface NewSelections {
  selections: number;
  deselections: number;
}

export interface UpdatedFilterTab {
  title: string;
  readonly defaultLabel: string;
  label: string;
  available: boolean;
  onMainBanner: boolean;
  tab: FilterBannerTab;
  newSelections?: NewSelections;
  originalLabel?: string;
}

export interface OntologyListEntity {
  nodePath: string;
  providerNodeName?: string;
  specialtyNodeName?: string;
  departmentNodeName?: string;

  [key: string]: string | undefined;
}

export interface SelectableItem<T> {
  selected: boolean;
  originallySelected?: boolean;
  belongs: boolean;
  matchesSearchText: boolean;
  item: T;
  key: number;
  expansion?: ListItemExpansion;
  displayText?: string;
}

export interface RelatableSelectableItem<T> {
  item: SelectableItem<T>;
  relatives: SelectableItem<T>[];
}

export interface OntologyListData {
  allDepartmentsNodePath: string;
  departments: RelatableSelectableItem<OntologyListEntity>[];
  specialties: RelatableSelectableItem<OntologyListEntity>[];
  providers: RelatableSelectableItem<OntologyListEntity>[];
}

export enum ScenarioForDisplayability {
  Standard = 0,
  MatchSearchTextRegardless = 1,
  IsParentSelected = 2,
  RelativeMatchesSearchText = 3,
  IsParentExpanded = 4,
  Belong
}

export const updatedFilterTabs: UpdatedFilterTab[] = [
  {
    title: 'Provider',
    defaultLabel: '',
    label: '',
    available: true,
    tab: FilterBannerTab.PROVIDER,
    onMainBanner: false
  },
  {
    title: 'Time Frame',
    defaultLabel: '',
    label: '',
    available: true,
    tab: FilterBannerTab.DATE,
    onMainBanner: true
  },
  {
    title: 'Location',
    defaultLabel: DEFAULT_MEMBER_LOCATION.memberLocationName,
    label: DEFAULT_MEMBER_LOCATION.memberLocationName,
    available: true,
    tab: FilterBannerTab.LOCATION,
    onMainBanner: true
  },
  {
    title: 'Multiple Locations',
    defaultLabel: DEFAULT_MEMBER_LOCATION.memberLocationName,
    label: DEFAULT_MEMBER_LOCATION.memberLocationName,
    available: true,
    tab: FilterBannerTab.MULTI_LOCATION,
    onMainBanner: true
  },
  {
    title: 'Payer',
    defaultLabel: DEFAULT_PAYER_CATEGORY.payerCategoryDescription,
    label: DEFAULT_PAYER_CATEGORY.payerCategoryDescription,
    available: true,
    tab: FilterBannerTab.PAYER,
    onMainBanner: true
  },
  {
    title: 'Billing Area',
    defaultLabel: DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaDescription,
    label: DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaDescription,
    available: true,
    tab: FilterBannerTab.BILLING,
    onMainBanner: true
  },
  {
    title: 'Lag Period',
    defaultLabel: DEFAULT_LAG_NAME,
    label: DEFAULT_LAG_NAME,
    available: true,
    tab: FilterBannerTab.LAG,
    onMainBanner: true
  },
  {
    title: 'Invoice Status',
    defaultLabel: DEFAULT_INVOICE_TEXT,
    label: DEFAULT_INVOICE_TEXT,
    available: true,
    tab: FilterBannerTab.INVOICE_STATUS,
    onMainBanner: true
  },
  {
    title: 'Visit Type',
    defaultLabel: DEFAULT_TELEHEALTH_FLAG.text,
    label: DEFAULT_TELEHEALTH_FLAG.text,
    available: true,
    tab: FilterBannerTab.VISIT_TYPE,
    onMainBanner: true
  }
];

export function splitNameWhenSearched(name: string, searchText: string): string[] {
  if (!hasValue(searchText) || searchText.length < MIN_SEARCH_CHARS) {
    return ['', '', name];
  }
  const indexOf = name.toLowerCase().indexOf(searchText.toLowerCase());
  return [name.substring(0, indexOf), name.substring(indexOf, indexOf + searchText.length),
    name.substring(indexOf + searchText.length)];
}

export const columnsAvailableProviders: DataTableColumns[] = [
  {
    header: 'Providers',
    columnDef: 'providerNodeName',
    dataName: (row: RelatableSelectableItem<OntologyListEntity>, searchText: string) =>
      splitNameWhenSearched(row.item.item.providerNodeName + '', searchText)
  },
  {
    header: 'Specialty',
    columnDef: 'specialtyNodeName',
    dataName: (row: RelatableSelectableItem<OntologyListEntity>) => `${row.item.item.specialtyNodeName}`
  },
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: RelatableSelectableItem<OntologyListEntity>) => `${row.item.item.departmentNodeName}`
  }
];

export const columnsSelectedProviders: DataTableColumns[] = [
  {
    header: 'Providers',
    columnDef: 'providerNodeName',
    dataName: (row: RelatableSelectableItem<OntologyListEntity>) => `${row.item.item.providerNodeName}`
  },
  {
    header: 'Specialty',
    columnDef: 'specialtyNodeName',
    dataName: (row: RelatableSelectableItem<OntologyListEntity>) => `${row.item.item.specialtyNodeName}`
  },
  {
    header: 'Department',
    columnDef: 'departmentNodeName',
    dataName: (row: RelatableSelectableItem<OntologyListEntity>) => `${row.item.item.departmentNodeName}`
  }
];

export const mockOntology: OntologyListEntity[] = [
  {
    nodePath: '\\17\\331\\4144\\22121',
    departmentNodeName: 'parent1',
    specialtyNodeName: 'child4',
    providerNodeName: 'child21'
  },
  {
    nodePath: '\\17\\512\\2321\\54533',
    departmentNodeName: 'parent2',
    specialtyNodeName: 'child1',
    providerNodeName: 'child33'
  },
  {
    nodePath: '\\17\\512\\2321\\41488',
    departmentNodeName: 'parent2',
    specialtyNodeName: 'child1',
    providerNodeName: 'child44'
  },
  {
    nodePath: '\\17\\512\\2321\\12345',
    departmentNodeName: 'parent2',
    specialtyNodeName: 'child1',
    providerNodeName: 'additionalProvider'
  },
  {
    nodePath: '\\17\\512\\3224\\22121',
    departmentNodeName: 'parent2',
    specialtyNodeName: 'child2',
    providerNodeName: 'child21'
  },
  {
    nodePath: '\\17\\512\\3224\\32242',
    departmentNodeName: 'parent2',
    specialtyNodeName: 'child2',
    providerNodeName: 'child22'
  },
  {
    nodePath: '\\17\\755\\3224\\98765',
    departmentNodeName: 'additionalNode',
    specialtyNodeName: 'child2',
    providerNodeName: 'oneMoreProvider'
  },
  {
    nodePath: '\\17\\755\\8831\\71382',
    departmentNodeName: 'additionalNode',
    specialtyNodeName: 'child7',
    providerNodeName: 'child55'
  },
  {
    nodePath: '\\17\\755\\8831\\22121',
    departmentNodeName: 'additionalNode',
    specialtyNodeName: 'child7',
    providerNodeName: 'child21'
  }
];

export const mockPayerHierarchy: PayerCategoryLevel[] = [
  {
    payerCategoryKey: 1,
    payerCategoryDesc: 'Commercial Managed',
    nationalPayers: [
      {
        nationalPayerKey: 8,
        nationalPayerDesc: 'Aetna',
        memberPayers: [
          {
            memberPayerKey: 20,
            memberPayerDesc: 'memberOfAetna'
          }
        ]
      },
      {
        nationalPayerKey: 9,
        nationalPayerDesc: 'Blue Cross Blue Shield',
        memberPayers: [
          {
            memberPayerKey: 21,
            memberPayerDesc: 'blue shield blue sword'
          }
        ]
      }
    ]
  },
  {
    payerCategoryKey: 2,
    payerCategoryDesc: 'Commercial Capitated',
    nationalPayers: [
      {
        nationalPayerKey: 10,
        nationalPayerDesc: 'Beech Street',
        memberPayers: [
          {
            memberPayerKey: 22,
            memberPayerDesc: 'onTheBeachWeGo'
          }
        ]
      }
    ]
  },
  {
    payerCategoryKey: 3,
    payerCategoryDesc: 'Medicare',
    nationalPayers: [
      {
        nationalPayerKey: 11,
        nationalPayerDesc: 'CVS',
        memberPayers: [
          {
            memberPayerKey: 23,
            memberPayerDesc: 'happyMember'
          }
        ]
      }
    ]
  }
];

export const mockBillingAreas: MemberBillingArea[] = [
  {
    memberBillingAreaKey: 1,
    memberBillingAreaDescription: 'area51',
    memberBillingAreaCode: 'beware'
  },
  {
    memberBillingAreaKey: 2,
    memberBillingAreaDescription: 'McHenry County',
    memberBillingAreaCode: '22'
  }
];

export const mockLocations: MemberLocation[] = [
  {
    memberLocationName: 'Old Post Office',
    memberLocationKey: 7,
    memberLocationCode: 'cc'
  },
  {
    memberLocationName: 'North Wacker',
    memberLocationKey: 8,
    memberLocationCode: 'bb'
  },
  {
    memberLocationName: 'Union Station',
    memberLocationKey: 9,
    memberLocationCode: 'aa'
  },
  {
    memberLocationName: 'Market Creations',
    memberLocationKey: 10,
    memberLocationCode: 'zz'
  }
];

export const mockCustomGroups: CustomGroupsDataAllFilters[] = [
  {
    id: 1,
    name: 'hamlet',
    description: 'hamlet',
    nodePath: '\\1\\2\\3',
    isDefault: false,
    preload: false,
    memberKey: 12,
    payerCategoryKey: 88,
    nationalPayerKey: 17,
    memberPayerKey: 24,
    payerType: PayerType.NATIONAL,
    memberLocationKey: 14,
    memberBillingAreaKey: 99,
    invoiceStatus: 55,
    lagKey: 79,
    startDateInMonths: 54,
    endDateInMonths: 66,
    dateRangeOption: 0,
    telehealthFlag: 1,
    memberLocationKeys: '92|514'
  },
  {
    id: 1,
    name: 'dr mario',
    description: 'dr mario',
    nodePath: '\\1\\2\\3\\4',
    isDefault: false,
    preload: false,
    memberKey: 121,
    payerCategoryKey: 881,
    nationalPayerKey: 171,
    memberPayerKey: 242,
    payerType: PayerType.NATIONAL,
    memberLocationKey: 142,
    memberBillingAreaKey: 992,
    invoiceStatus: 525,
    lagKey: 792,
    startDateInMonths: 542,
    endDateInMonths: 662,
    dateRangeOption: 0,
    telehealthFlag: 1, memberLocationKeys: '92|514'
  },
  {
    id: 2,
    name: 'This name is long and will therefore be truncated with an ellipsis',
    description: 'dr mario',
    nodePath: '\\1\\2',
    isDefault: false,
    preload: false,
    memberKey: 121,
    payerCategoryKey: 881,
    nationalPayerKey: 171,
    memberPayerKey: 242,
    payerType: PayerType.NATIONAL,
    memberLocationKey: 142,
    memberBillingAreaKey: 992,
    invoiceStatus: 525,
    lagKey: 792,
    startDateInMonths: 542,
    endDateInMonths: 662,
    dateRangeOption: 0,
    telehealthFlag: 0, memberLocationKeys: '92|514'
  }
];

export type ManageGroupingsParentComponent = ButtonCptGroupingsComponent | CustomGroupSelectComponent;
