import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControlValidationStatus, MpeDialogPage} from '../mpe-dialog-helper';
import {FormControlStatus} from '@angular/forms';

@Component({
  selector: 'app-dialog-footer',
  templateUrl: './dialog-footer.component.html',
  styleUrls: ['./dialog-footer.component.scss']
})
export class DialogFooterComponent implements OnChanges {

  @Input() permittedToGoToSummary: boolean;
  @Input() backButtonText: string;
  @Input() backButtonValue: MpeDialogPage;
  @Input() nextButtonText: string;
  @Input() nextButtonValue: MpeDialogPage;
  @Input() nextButtonEnable: boolean;
  @Input() validationStatus: FormControlStatus;
  @Output() pageSelection = new EventEmitter<MpeDialogPage>();
  @Output() enableErrorMessage = new EventEmitter<boolean>();
  @Output() exportSelected = new EventEmitter<boolean>();
  readonly MpeDialogPage = MpeDialogPage;

  buttonClicked: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['validationStatus']) {
      if (this.buttonClicked && this.validationStatus === FormControlValidationStatus.VALID) {
        this.onNextButtonClick();
        this.buttonClicked = false;
      }
    }
  }

  onBackButtonClick(): void {
    this.enableErrorMessage.emit(false);
    this.pageSelection.emit(this.backButtonValue);
  }

  onNextButtonClick(): void {
    if (this.validationStatus === FormControlValidationStatus.PENDING && this.nextButtonValue === MpeDialogPage.SUMMARY) {
      this.buttonClicked = true;
      return;
    }
    if (!this.permittedToGoToSummary && this.nextButtonValue === MpeDialogPage.SUMMARY) {
      this.enableErrorMessage.emit(true);
    } else if (this.nextButtonValue === MpeDialogPage.EXPORTED) {
      this.exportSelected.emit(true);
      this.pageSelection.emit(this.nextButtonValue);
    } else {
      this.enableErrorMessage.emit(false);
      this.pageSelection.emit(this.nextButtonValue);
    }
  }
}
