<mat-dialog-content>
  <div id="confirm-dialog-wrapper">
    <div id="confirm-dialog-title" *ngIf="data.title">{{data.title}}</div>
    <div id="confirm-dialog-question-wrapper">
      <span id="confirm-dialog-question">{{data.question}}</span>
      <div id="buttons-wrapper" [ngClass]="data.buttonWrapperClass || ''">
        <button id="btn-cancel" (click)="cancel()">{{data.cancelText || 'Cancel'}}</button>
        <button id="btn-continue"
                (click)="confirm()">{{data.confirmButtonAltText ? data.confirmButtonAltText : 'Delete'}}</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
