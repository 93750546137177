<div class="wrapper">
  <div [id]="overviewPageView? 'overview-patient-visit-charts' : 'patient-visit-charts'">
    <div class="patient-visit-chart">
      <app-evaluation-management-chart
        [fromPdf]="fromPdf"
        chartId="new-patient-eye-exam"
        [categories]="newPatientCategories"
        [maxYAxisValue]="maxNewPatientEyeExamYAxisValue"
        chartTitle="New Patient Eye Exam"
        [chartHeight]="chartHeight"
        [totalCptSummaries]="totalNewPatientEyeExamCptSummaries"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [specialtyCptSummaries]="specialtyTotalNewPatientEyeExamCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-chart
        [fromPdf]="fromPdf"
        chartId="established-patient-eye-exam"
        [categories]="establishedPatientCategories"
        [maxYAxisValue]="maxEstablishedPatientEyeExamYAxisValue"
        chartTitle="Established Patient Eye Exam"
        [chartHeight]="chartHeight"
        [totalCptSummaries]="totalEstablishedPatientEyeExamCptSummaries"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [specialtyCptSummaries]="specialtyTotalEstablishedPatientEyeExamCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
      <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
    </div>
  </div>
  <app-chart-legend [ngClass]="fromPdf? 'pdf-legend': ''" [fromPdf]="fromPdf" [legends]="legends" [metricType]="metricType"></app-chart-legend>
</div>
