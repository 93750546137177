import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import {Options} from 'highcharts/highstock';
import {Chart} from 'angular-highcharts';
import {ChartType, StyledChart} from '../../../shared/highcharts-helpers';
import HC_more from 'highcharts/highcharts-more';
import {SummaryData} from '../../../store/IAppState';
import {CollectionsSummaryForAllTypes} from '../../Collection';
import {roundCollectionsToWithCommasSeparation, roundTo} from '../../../productivity-summary/number-formatter';
import {HighChartColorIndex} from '../../../shared/enums';

HC_more(Highcharts);

@Component({
  selector: 'app-summary-collections-chart',
  templateUrl: './summary-collections-chart.component.html',
  styleUrls: ['./summary-collections-chart.component.scss']
})
export class SummaryCollectionsChartComponent implements OnChanges {

  @Input() showProgressBar: boolean;
  @Input() summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>;

  @ViewChild('chartCollectionsTarget', {static: true})
  public chartTarget: ElementRef;

  options: Options;
  chartObject: Chart;
  netCollectionRate: string;

  constructor() { }

  ngOnChanges() {
    if (this.summaryCollectionsData) {
      this.netCollectionRate = `${this.summaryCollectionsData.selectedDateRange.netCollectionRate} %`;
    }
    this.drawGraph();
  }

  drawGraph() {
    if (!this.summaryCollectionsData) {
      return;
    }
    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      chart: {
        type: ChartType.WATERFALL,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',
        reversed: true,
      },
      yAxis: {
        title: {
          text: 'USD'
        },
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormat: '<b>${point.y:,.2f}</b>'
      },
      // @ts-ignore
      series: [{
        data: [
          {
            name: 'Patient Payments',
            y: this.summaryCollectionsData.selectedDateRange.patientPaymentAmount,
            colorIndex: HighChartColorIndex.TEAL
          },
          {
            name: 'Insurance Payments',
            y: this.summaryCollectionsData.selectedDateRange.primaryAndOtherInsurancePayments,
            colorIndex: HighChartColorIndex.TEAL
          },
          {
            name: 'Total Payments',
            y: this.summaryCollectionsData.selectedDateRange.totalPayments,
            colorIndex: HighChartColorIndex.TEAL,
            // @ts-ignore
            isSum: true
          },
          {
            name: 'Small Balance Write-Offs',
            y: this.summaryCollectionsData.selectedDateRange.smallBalanceWriteOffs,
            colorIndex: HighChartColorIndex.LIGHT_ORANGE
          },
          {
            name: 'Patient-Related Bad Debt',
            y: this.summaryCollectionsData.selectedDateRange.patientRelatedBadDebt,
            colorIndex: HighChartColorIndex.LIGHT_ORANGE
          },
          {
            name: 'Outstanding A/R',
            y: this.summaryCollectionsData.selectedDateRange.outstandingAR,
            colorIndex: HighChartColorIndex.LIGHT_ORANGE
          },
          {
            name: 'Controllable Allowances',
            y: this.summaryCollectionsData.selectedDateRange.controllableAllowance,
            colorIndex: HighChartColorIndex.LIGHT_ORANGE
          },
          {
            name: 'Expected Payments',
            y: this.summaryCollectionsData.selectedDateRange.expectedPayments,
            colorIndex: HighChartColorIndex.ORANGE,
            // @ts-ignore
            isSum: true
          },
          {
            name: 'Charity',
            y: this.summaryCollectionsData.selectedDateRange.charityCareAmount,
            colorIndex: HighChartColorIndex.LIGHT_PURPLE
          },
          {
            name: 'Discounts',
            y: this.summaryCollectionsData.selectedDateRange.discountAmount,
            colorIndex: HighChartColorIndex.LIGHT_PURPLE
          },
          {
            name: 'Payer Contractual',
            y: this.summaryCollectionsData.selectedDateRange.primaryAndOtherPayerContractuals,
            colorIndex: HighChartColorIndex.LIGHT_PURPLE
          },
          {
            name: 'Charges',
            y: this.summaryCollectionsData.selectedDateRange.chargeAmount,
            colorIndex: HighChartColorIndex.PURPLE,
            // @ts-ignore
            isSum: true
          }
        ],
        dataLabels: {
          enabled: true,
          inside: false,
          allowOverlap: true,
          crop: false,
          overflow: 'allow',
          formatter: function () {
            // @ts-ignore
            const number = this.y || 0;
            if (Math.abs(number) >= 1000000000) {
              return '$' + roundTo(number / 1000000000, 1) + 'b';
            } else if (Math.abs(number) > 1000000) {
              return '$' + roundTo(number / 1000000, 1) + 'm';
            } else if (Math.abs(number) > 1000) {
              return '$' + roundTo(number / 1000, 1) + 'k';
            } else if (Math.abs(number) === 0) {
              return '$';
            } else {
              return '$' + roundCollectionsToWithCommasSeparation(number) + '';
            }
          },
          style: {
            fontWeight: 'bold'
          }
        },
        pointPadding: 0,
      }]
    };
    this.chartObject = new StyledChart(this.options);
  }
}
