import {BenchmarkOption, ColumnType, LegendColor, LegendStyle, MultilevelTab} from '../../shared/enums';
import {
  BenchmarkPercentile,
  extendedBenchmarkOptions,
  readableNameOf,
  readableNameOfColumnDef,
  readableNameOfColumnDefWithVisitTypeBenchmark,
  TelehealthBenchmarkHelperObject
} from '../../shared/benchmark-types';
import {DataTableColumns} from '../../shared/data-table-columns';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedNewPatientVisitSnapshotEntryInSingleLocation,
  MergedNpvLocationAggregatedByNode,
  NewPatientVisitsBase,
  NewPatientVisitSnapshotMultiLevel,
  NewPatientVisitTrendEntry,
  NpvDataWithPreviousEntry,
  NpvLocationAggregationAndZeroSuppressionCount,
  NpvLocationWithSnapshotEntries,
  totalNpvLocationColumnId
} from './npv-models';
import {
  formatToPercentageAllowZero,
  roundTo,
  roundToWithPercentageOrElseNull
} from '../../productivity-summary/number-formatter';
import {Legend, NewPatientVisitSummary} from '../../shared/models';
import {getLevelTypeNodePath, getSingularOntologyLevelName, LevelType} from '../../shared/helpers';
import {MetricType} from '../../shared/metric-types';
import {getValueFromObjectWithStringIndexing} from '../../shared/object-helpers';
import {defaultNpvBenchmarks} from './npv-models.spec';
import {checkForNulls} from '../../shared/null-helpers';
import {numberValueFromDataName, tieBreakerComparor} from '../../shared/compare-helpers';

export function getSummaryBenchmarkFieldForBenchmarkCombination(
  benchmarkPercentile: BenchmarkPercentile,
  benchmarkOption: BenchmarkOption,
  telehealthEnabled: boolean): string {
  if (!telehealthEnabled && benchmarkOption !== BenchmarkOption.Community && benchmarkOption !== BenchmarkOption.Academic) {
    benchmarkOption = BenchmarkOption.Academic;
  }

  let prefix;
  let suffix = benchmarkPercentile === BenchmarkPercentile.Mean ? 'Mean' : 'Per';
  suffix = benchmarkOption !== BenchmarkOption.Academic ? suffix : suffix.toLowerCase();

  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthAcademic:
      prefix = 'telehealth';
      break;
    case BenchmarkOption.TelehealthCommunity:
      prefix = 'telehealthCommunity';
      break;
    case BenchmarkOption.InPersonAcademic:
      prefix = 'inPerson';
      break;
    case BenchmarkOption.InPersonCommunity:
      prefix = 'inPersonCommunity';
      break;
    case BenchmarkOption.Community:
      prefix = 'community';
      break;
    case BenchmarkOption.Academic:
    default:
      prefix = '';
      break;
  }
  suffix += addBenchmarkSuffix(benchmarkPercentile);
  return prefix + suffix;
}

export function addBenchmarkSuffix(benchmarkPercentile: BenchmarkPercentile): string {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th:
      return '25th';
    case BenchmarkPercentile.Percentile50th:
      return '50th';
    case BenchmarkPercentile.Percentile75th:
      return '75th';
    case BenchmarkPercentile.Percentile90th:
      return '90th';
    case BenchmarkPercentile.Mean:
      return '';
  }
  return '';
}

export function replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor(
  benchmark: BenchmarkPercentile, benchmarkOption: BenchmarkOption,
  previous: boolean = false): DataTableColumns {
  const allVisitTypesLabel = 'All Visit Types ';
  let header = '';
  const columnDef = readableNameOfColumnDefWithVisitTypeBenchmark(benchmark, benchmarkOption);
  const readableNameOfBenchmark = readableNameOf(benchmark);
  const benchmarkField = `benchmark${readableNameOfColumnDef(benchmark)}`;
  const designatedBenchmarkObject = getDesignatedNpvBenchmarkObject(benchmarkOption);
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthCommunity:
      header = previous ? `Previous Year % New Patients Academic Benchmark ${readableNameOfBenchmark}`
        : `% New Patients Telehealth Community Benchmark ${readableNameOfBenchmark}`;
      break;
    case BenchmarkOption.TelehealthAcademic:
      header = previous
        ? `Previous Year % New Patients Academic Benchmark ${readableNameOfBenchmark}`
        : `% New Patients Telehealth Academic Benchmark ${readableNameOfBenchmark}`;
      break;
    case BenchmarkOption.InPersonCommunity:
      header = previous
        ? `Previous Year % New Patients In-Person Community Benchmark ${readableNameOfBenchmark}`
        : `% New Patients In-Person Community Benchmark ${readableNameOfBenchmark}`;
      break;
    case BenchmarkOption.InPersonAcademic:
      header = previous
        ? `Previous Year % New Patients In-Person Academic Benchmark ${readableNameOfBenchmark}`
        : `% New Patients In-Person Academic Benchmark ${readableNameOfBenchmark}`;
      break;
    case BenchmarkOption.Community:
      header = previous
        ? `Previous Year % New Patients ${allVisitTypesLabel}Community Benchmark ${readableNameOfBenchmark}`
        : `% New Patients ${allVisitTypesLabel}Community Benchmark ${readableNameOfBenchmark}`;
      break;
    case BenchmarkOption.Academic:
      header = previous
        ? `Previous Year % New Patients ${allVisitTypesLabel}Academic Benchmark ${readableNameOfBenchmark}`
        : `% New Patients ${allVisitTypesLabel}Academic Benchmark ${readableNameOfBenchmark}`;
  }
  return {
    columnDef: columnDef,
    header: header,
    dataName: (npv: MergedNewPatientVisitSnapshotEntry) => previous ?
      formatToPercentageAllowZero(npv.previous[designatedBenchmarkObject][benchmarkField], 1) :
      formatToPercentageAllowZero(npv[designatedBenchmarkObject][benchmarkField], 1),
    columnType: ColumnType.BENCHMARK
  };
}

export function replaceNpvSnapshotVarianceColumnWithBenchmarkFor(
  benchmark: BenchmarkPercentile, benchmarkOption: BenchmarkOption,
  previous: boolean = false): DataTableColumns {
  // TODO revisit header labels after new release to stage/prod as of 7/11/2022
  let header = '';
  const columnDef = `variance${readableNameOfColumnDef(benchmark)}`;
  const designatedVarianceObject = getDesignatedNpvVarianceObject(benchmarkOption);
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthCommunity:
      header = previous ? `Previous Year % New Patients Academic Variance ${readableNameOf(benchmark)}`
        : `% New Patients Telehealth Community Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.TelehealthAcademic:
      header = previous
        ? `Previous Year % New Patients Academic Variance ${readableNameOf(benchmark)}`
        : `% New Patients Telehealth Academic Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.InPersonCommunity:
      header = previous
        ? `Previous Year % New Patients In-Person Community Variance ${readableNameOf(benchmark)}`
        : `% New Patients In-Person Community Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.InPersonAcademic:
      header = previous
        ? `Previous Year % New Patients In-Person Academic Variance ${readableNameOf(benchmark)}`
        : `% New Patients In-Person Academic Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.Community:
      header = previous
        ? `Variance ${readableNameOf(benchmark)}` : `Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.Academic:
      header = previous
        ? `Variance ${readableNameOf(benchmark)}` : `Variance ${readableNameOf(benchmark)}`;
  }
  return {
    columnDef: columnDef, header,
    dataName: (npv: MergedNewPatientVisitSnapshotEntry) => previous ?
      `${roundToWithPercentageOrElseNull(npv.previous[designatedVarianceObject][columnDef], 1)}` :
      `${roundToWithPercentageOrElseNull(npv[designatedVarianceObject][columnDef], 1)}`,
    columnType: ColumnType.VARIANCE
  };
}

export function replaceNpvTrendBenchmarkColumnWithBenchmarkFor(
  benchmark: BenchmarkPercentile, benchmarkOption: BenchmarkOption,
  telehealthEnabled: boolean = false, previous: boolean = false): DataTableColumns {
  const allVisitTypesLabel = telehealthEnabled ? 'All Visit Types ' : '';
  let header = '';
  const benchmarkField = `benchmark${readableNameOfColumnDef(benchmark)}`;
  const columnDef = readableNameOfColumnDefWithVisitTypeBenchmark(benchmark, benchmarkOption);
  const designatedBenchmarkObject = getDesignatedNpvBenchmarkObject(benchmarkOption);
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthCommunity:
      header = previous ? `Previous Year % New Patients Academic Benchmark ${readableNameOf(benchmark)}`
        : `% New Patients Telehealth Community Benchmark ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.TelehealthAcademic:
      header = previous
        ? `Previous Year % New Patients Academic Benchmark ${readableNameOf(benchmark)}`
        : `% New Patients Telehealth Academic Benchmark ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.InPersonCommunity:
      header = previous
        ? `Previous Year % New Patients In-Person Community Benchmark ${readableNameOf(benchmark)}`
        : `% New Patients In-Person Community Benchmark ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.InPersonAcademic:
      header = previous
        ? `Previous Year % New Patients In-Person Academic Benchmark ${readableNameOf(benchmark)}`
        : `% New Patients In-Person Academic Benchmark ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.Community:
      header = previous
        ? `Previous Year % New Patients ${allVisitTypesLabel}Community Benchmark ${readableNameOf(benchmark)}`
        : `% New Patients ${allVisitTypesLabel}Community Benchmark ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.Academic:
      header = previous
        ? `Previous Year % New Patients ${allVisitTypesLabel}Academic Benchmark ${readableNameOf(benchmark)}`
        : `% New Patients ${allVisitTypesLabel}Academic Benchmark ${readableNameOf(benchmark)}`;
  }
  return {
    columnDef: columnDef, header,
    dataName: (npv: NewPatientVisitTrendEntry) => previous ?
      `${formatToPercentageAllowZero(npv.previous[designatedBenchmarkObject][benchmarkField], 1)}` :
      `${formatToPercentageAllowZero(npv[designatedBenchmarkObject][benchmarkField], 1)}`,
    columnType: ColumnType.BENCHMARK
  };
}

export function replaceNpvTrendVarianceColumnWithBenchmarkFor(
  benchmark: BenchmarkPercentile, benchmarkOption: BenchmarkOption,
  telehealthEnabled: boolean = false, previous: boolean = false): DataTableColumns {
  let header = '';
  const columnDef = `variance${readableNameOfColumnDef(benchmark)}`;
  const designatedVarianceObject = getDesignatedNpvVarianceObject(benchmarkOption);
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthCommunity:
      header = previous ? `Previous Year % New Patients Academic Variance ${readableNameOf(benchmark)}`
        : `% New Patients Telehealth Community Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.TelehealthAcademic:
      header = previous
        ? `Previous Year % New Patients Academic Variance ${readableNameOf(benchmark)}`
        : `% New Patients Telehealth Academic Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.InPersonCommunity:
      header = previous
        ? `Previous Year % New Patients In-Person Community Variance ${readableNameOf(benchmark)}`
        : `% New Patients In-Person Community Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.InPersonAcademic:
      header = previous
        ? `Previous Year % New Patients In-Person Academic Variance ${readableNameOf(benchmark)}`
        : `% New Patients In-Person Academic Variance ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.Community:
      header = previous
        ? `Variance from Benchmark ${readableNameOf(benchmark)}`
        : `Variance from Benchmark ${readableNameOf(benchmark)}`;
      break;
    case BenchmarkOption.Academic:
      header = previous
        ? `Variance from Benchmark ${readableNameOf(benchmark)}`
        : `Variance from Benchmark ${readableNameOf(benchmark)}`;
  }
  return {
    columnDef: columnDef, header,
    dataName: (npv: NewPatientVisitTrendEntry) => previous ?
      `${roundTo(npv.previous[designatedVarianceObject][columnDef], 1)}%` :
      `${roundTo(npv[designatedVarianceObject][columnDef], 1)}%`,
    columnType: ColumnType.VARIANCE
  };
}

export function getDesignatedNpvBenchmarkObject(benchmarkOption: BenchmarkOption): string {
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthCommunity:
      return 'newPatientVisitsCommunityTelehealthPercentageBenchmarks';
    case BenchmarkOption.TelehealthAcademic:
      return 'newPatientVisitsTelehealthPercentageBenchmarks';
    case BenchmarkOption.InPersonCommunity:
      return 'newPatientVisitsCommunityInPersonPercentageBenchmarks';
    case BenchmarkOption.InPersonAcademic:
      return 'newPatientVisitsInPersonPercentageBenchmarks';
    case BenchmarkOption.Community:
      return 'newPatientVisitsCommunityPercentageBenchmarks';
    case BenchmarkOption.Academic:
      return 'newPatientVisitsPercentageBenchmarks';
  }
}

export function getDesignatedNpvVarianceObject(benchmarkOption: BenchmarkOption): string {
  switch (benchmarkOption) {
    case BenchmarkOption.TelehealthCommunity:
      return 'newPatientVisitsCommunityTelehealthVariance';
    case BenchmarkOption.TelehealthAcademic:
      return 'newPatientVisitsTelehealthVariance';
    case BenchmarkOption.InPersonCommunity:
      return 'newPatientVisitsCommunityInPersonVariance';
    case BenchmarkOption.InPersonAcademic:
      return 'newPatientVisitsInPersonVariance';
    case BenchmarkOption.Community:
      return 'newPatientVisitsCommunityVariance';
    case BenchmarkOption.Academic:
      return 'newPatientVisitsVariance';
  }
}

export function locationTabEquivalentOf(tab: MultilevelTab): MultilevelTab {
  switch (tab) {
    case MultilevelTab.BY_DEPARTMENT:
    case MultilevelTab.LOCATION_DEPARTMENT:
      return MultilevelTab.LOCATION_DEPARTMENT;
    case MultilevelTab.BY_SPECIALTY:
    case MultilevelTab.LOCATION_SPECIALTY:
      return MultilevelTab.LOCATION_SPECIALTY;
    case MultilevelTab.BY_PROVIDER:
    case MultilevelTab.LOCATION_PROVIDER:
      return MultilevelTab.LOCATION_PROVIDER;
    default:
      return MultilevelTab.LOCATION_DEPARTMENT;
  }
}

export function convertNpvBenchmarkDependentColumnToSelectedBenchmark(
  originalColumn: DataTableColumns,
  percentile: BenchmarkPercentile,
  option: BenchmarkOption,
  isVariance: boolean): void {
  const defBaseText = isVariance ? 'variance' : 'benchmark';
  const benchmarkDef = `${defBaseText}${readableNameOfColumnDef(percentile)}`;
  const designatedObject = isVariance ? getDesignatedNpvVarianceObject(option) : getDesignatedNpvBenchmarkObject(option);
  let designatedHeader: string;
  let dataNameFunction: (number: number | null | undefined, fractionDigits: number) => string;
  if (!isVariance) {
    dataNameFunction = formatToPercentageAllowZero;
    const designatedBenchmarkObject: TelehealthBenchmarkHelperObject | undefined = extendedBenchmarkOptions.find(o =>
      o.value === option);
    designatedHeader = designatedBenchmarkObject?.name || '';
    designatedHeader = `% New Patient Visits ${designatedHeader} Benchmark ${readableNameOf(percentile)}`;
  } else {
    dataNameFunction = roundToWithPercentageOrElseNull;
    designatedHeader = `NPV Variance ${readableNameOf(percentile)}`;
  }
  originalColumn.columnDef = `${designatedObject}${benchmarkDef}`;
  originalColumn.header = designatedHeader;
  originalColumn.columnType = isVariance ? ColumnType.VARIANCE : ColumnType.BENCHMARK;
  originalColumn.dataName = (row: NpvDataWithPreviousEntry) => `${dataNameFunction(
    row[designatedObject][benchmarkDef], 1)}`;
}

export function aggregateNpvSnapshotColumn(column: DataTableColumns): DataTableColumns {
  return {
    ...column,
    dataNameList: (row: NpvLocationWithSnapshotEntries) => row.snapshotEntries
  };
}

export function convertSnapshotColumnsForLocationAggregation(columns: DataTableColumns[]): DataTableColumns[] {
  return columns.map(col => {
    return aggregateNpvSnapshotColumn(col);
  });
}

export function getBenchmarkFromType(
  newPatientVisit: NewPatientVisitSummary,
  benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean, benchmarkOption: BenchmarkOption | undefined
): number | undefined {
  benchmarkOption = benchmarkOption ?? (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  if (benchmarkOption === BenchmarkOption.Academic) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return newPatientVisit.benchmark25thPercentile;
      case BenchmarkPercentile.Percentile50th:
        return newPatientVisit.benchmark50thPercentile;
      case BenchmarkPercentile.Percentile75th:
        return newPatientVisit.benchmark75thPercentile;
      case BenchmarkPercentile.Percentile90th:
        return newPatientVisit.benchmark90thPercentile;
      case BenchmarkPercentile.Mean:
        return newPatientVisit.benchmarkMean;
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.Community) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return newPatientVisit.communityBenchmark25thPercentile;
      case BenchmarkPercentile.Percentile50th:
        return newPatientVisit.communityBenchmark50thPercentile;
      case BenchmarkPercentile.Percentile75th:
        return newPatientVisit.communityBenchmark75thPercentile;
      case BenchmarkPercentile.Percentile90th:
        return newPatientVisit.communityBenchmark90thPercentile;
      case BenchmarkPercentile.Mean:
        return newPatientVisit.communityBenchmarkMean;
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.TelehealthAcademic) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return newPatientVisit.telehealthAcademicBenchmark25thPercentile;
      case BenchmarkPercentile.Percentile50th:
        return newPatientVisit.telehealthAcademicBenchmark50thPercentile;
      case BenchmarkPercentile.Percentile75th:
        return newPatientVisit.telehealthAcademicBenchmark75thPercentile;
      case BenchmarkPercentile.Percentile90th:
        return newPatientVisit.telehealthAcademicBenchmark90thPercentile;
      case BenchmarkPercentile.Mean:
        return newPatientVisit.telehealthAcademicBenchmarkMean;
      default:
        return 0;
    }
  } else if (benchmarkOption === BenchmarkOption.TelehealthCommunity) {
    switch (benchmark) {
      case BenchmarkPercentile.Percentile25th:
        return newPatientVisit.telehealthCommunityBenchmark25thPercentile;
      case BenchmarkPercentile.Percentile50th:
        return newPatientVisit.telehealthCommunityBenchmark50thPercentile;
      case BenchmarkPercentile.Percentile75th:
        return newPatientVisit.telehealthCommunityBenchmark75thPercentile;
      case BenchmarkPercentile.Percentile90th:
        return newPatientVisit.telehealthCommunityBenchmark90thPercentile;
      case BenchmarkPercentile.Mean:
        return newPatientVisit.telehealthCommunityBenchmarkMean;
      default:
        return 0;
    }
  }
}

export function getBenchmarkTitle(benchmarkOption: BenchmarkOption, benchmarkPercentile: BenchmarkPercentile, telehealthFlag: boolean) {
  let benchmarkTitle: string;
  let optionString = '';
  switch (benchmarkOption) {
    case BenchmarkOption.Academic:
      optionString = telehealthFlag ? 'All Visit Types - Academic' : 'Academic';
      break;
    case BenchmarkOption.Community:
      optionString = telehealthFlag ? 'All Visit Types - Community' : 'Community';
      break;
    case BenchmarkOption.TelehealthAcademic:
      optionString = 'Telehealth - Academic';
      break;
    case BenchmarkOption.TelehealthCommunity:
      optionString = 'Telehealth - Community';
      break;
    case BenchmarkOption.InPersonAcademic:
      optionString = 'In-Person - Academic';
      break;
    case BenchmarkOption.InPersonCommunity:
      optionString = 'In-Person - Community';
  }
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Mean :
      benchmarkTitle = `% New Patients ${optionString} Benchmark Mean`;
      break;
    case BenchmarkPercentile.Percentile25th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 25th Percentile`;
      break;
    case BenchmarkPercentile.Percentile50th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 50th Percentile`;
      break;
    case BenchmarkPercentile.Percentile75th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 75th Percentile`;
      break;
    case BenchmarkPercentile.Percentile90th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 90th Percentile`;
      break;
    default:
      benchmarkTitle = `% New Patients ${optionString} Benchmark Mean`;
  }
  return benchmarkTitle;
}

export function getIsSpecialty(nodePath: string, isCustom: boolean) {
  return getLevelTypeNodePath(nodePath) === LevelType.provider && !isCustom;
}

export function getIsDepartment(nodePath: string, isCustom: boolean) {
  return getLevelTypeNodePath(nodePath) === LevelType.specialty && !isCustom;
}

export function getPercentNpvLegend(showAddOn: boolean): Legend {
  return {
    text: '% New Patients',
    color: showAddOn ? LegendColor.GREEN : LegendColor.TEAL,
    metric: MetricType.NewPatientVisits,
    style: showAddOn ? LegendStyle.CIRCLE : LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  };
}

export function getBenchmarkLegendForNpv(): Legend {
  return {
    text: '% NPV Benchmark',
    color: LegendColor.GREY,
    metric: MetricType.NewPatientVisits,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: true,
    showPercentileControl: true
  };
}

export function getTotalSnapshotRowForLocation(entry: MergedNpvLocationAggregatedByNode,
                                               tab: MultilevelTab):
  MergedNewPatientVisitSnapshotEntryInSingleLocation {
  const tabName: string = getSingularOntologyLevelName(tab).toLowerCase();
  const existingSnapshotEntry: MergedNewPatientVisitSnapshotEntry = getValueFromObjectWithStringIndexing(entry,
    `${tabName.toLowerCase()}NpvSnapshotData`)[0];
  let departmentNodeName = '', specialtyNodeName = '', providerNodeName = '';
  const totalName = 'Total';
  switch (tab) {
    case MultilevelTab.LOCATION_DEPARTMENT:
      departmentNodeName = totalName;
      break;
    case MultilevelTab.LOCATION_SPECIALTY:
      specialtyNodeName = totalName;
      break;
    case MultilevelTab.LOCATION_PROVIDER:
      providerNodeName = totalName;
  }
  return {
    ...existingSnapshotEntry,
    isTotal: true,
    designatedColumnId: totalNpvLocationColumnId,
    departmentNodeName: departmentNodeName,
    departmentNodePath: '',
    specialtyNodeName: specialtyNodeName,
    specialtyNodePath: '',
    providerNodeName: providerNodeName,
    providerNodePath: '',
    countOfNewPatientVisits: entry.countOfNewPatientVisits,
    countOfTotalPatientVisits: entry.countOfTotalPatientVisits,
    newPatientVisitsPercentage: entry.newPatientVisitsPercentage,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    difference: +roundTo(checkForNulls(entry.newPatientVisitsPercentage) -
      checkForNulls(entry.previousPercentage), 1),
    previous: {
      ...existingSnapshotEntry.previous,
      newPatientVisitsPercentage: entry.previousPercentage
    }
  };
}

export function getLevelSpecificNpvLocationTableData(showTotal: boolean, tab: MultilevelTab,
                                                     zeroSuppression: boolean,
                                                     mergedNpvByLocationByMultilevelData?: MergedNpvLocationAggregatedByNode[]):
  NpvLocationAggregationAndZeroSuppressionCount {
  const levelName: string = getSingularOntologyLevelName(tab).toLowerCase();
  const entries: NpvLocationWithSnapshotEntries[] = [];
  let countOfZeroSuppressedEntries = 0;
  (mergedNpvByLocationByMultilevelData || []).forEach(datum => {
    const originalSnapshotEntries: MergedNewPatientVisitSnapshotEntryInSingleLocation[] = getValueFromObjectWithStringIndexing(datum,
      `${levelName.toLowerCase()}NpvSnapshotData`).map((x: MergedNewPatientVisitSnapshotEntry) => {
      return {...x, isTotal: false};
    });
    const snapshotEntries: MergedNewPatientVisitSnapshotEntryInSingleLocation[] = (showTotal && getValueFromObjectWithStringIndexing(datum,
      `${levelName.toLowerCase()}NpvSnapshotData`).length ? [getTotalSnapshotRowForLocation(datum, tab)] :
      []);
    let atLeastOneSnapshotEntry = false;
    originalSnapshotEntries.forEach((x: MergedNewPatientVisitSnapshotEntryInSingleLocation) => {
      x.designatedColumnId = `${[{
        name: x.departmentNodeName, id: x.departmentNodeId
      }, {
        name: x.specialtyNodeName, id: x.specialtyNodeId
      }, {
        name: x.providerNodeName, id: x.providerNodeId
      }].sort((a, b) => {
        return b.name.length - a.name.length;
      })[0].id}`;
      if (!zeroSuppression || x.countOfTotalPatientVisits > 0) {
        snapshotEntries.push(x);
        atLeastOneSnapshotEntry = true;
      } else {
        countOfZeroSuppressedEntries++;
      }
    });
    if (atLeastOneSnapshotEntry) {
      entries.push({
        memberLocationKey: datum.memberLocationKey, memberLocationName: datum.memberLocationName,
        snapshotEntries: snapshotEntries
      });
    }
  });
  return {
    entries: entries, countOfZeroSuppressedEntries: countOfZeroSuppressedEntries
  };
}

export function populatePageSizes(initialPageSize: number): number[] {
  const pageSizes = [];
  for (let i = 1; i <= 4; i++) {
    pageSizes.push(i * initialPageSize);
  }
  return pageSizes;
}

export function getNewPatientVisitsBaseWithZeroValues(): NewPatientVisitsBase {
  return {
    countOfNewPatientVisits: 0,
    countOfTotalPatientVisits: 0,
    newPatientVisitsPercentage: 0,
    countOfExistingPatientVisits: 0
  };
}

export function sortSnapshotTableEntries(entries: MergedNewPatientVisitSnapshotEntry[],
                                         column: DataTableColumns, tieBreaker: (item: MergedNewPatientVisitSnapshotEntry) => string,
                                         sortAscending: boolean): MergedNewPatientVisitSnapshotEntry[] {
  return entries.sort((a, b) => {
    if (column.columnType === ColumnType.NODE_NAME) {
      return tieBreakerComparor(tieBreaker, sortAscending, a, b);
    }
    const values = [numberValueFromDataName(column, a), numberValueFromDataName(column, b)];
    const comparative = sortAscending ? values[0] - values[1] : values[1] - values[0];
    return comparative === 0 ? tieBreakerComparor(tieBreaker, sortAscending, a, b) : comparative;
  });
}

export function sortSnapshotChartEntries(entries: NewPatientVisitSnapshotMultiLevel[],
                                    column: DataTableColumns,
                                    tieBreaker: (item: NewPatientVisitSnapshotMultiLevel) => string,
                                    sortAscending: boolean): NewPatientVisitSnapshotMultiLevel[] {
  return entries.sort((a, b) => {
    if (column.columnType === ColumnType.NODE_NAME) {
      return tieBreakerComparor(tieBreaker, sortAscending, a, b);
    }
    const values = [numberValueFromDataName(column, a), numberValueFromDataName(column, b)];
    const comparative = sortAscending ? values[0] - values[1] : values[1] - values[0];
    return comparative === 0 ? tieBreakerComparor(tieBreaker, sortAscending, a, b) : comparative;
  });
}

export const NUMBER_OF_ROWS_BEFORE_SCROLLING = 5;
