import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  wrvuSnapshotSortingCriteriaChangedTo,
  wrvuVarianceToggleChangedTo,
  wrvuViewTypeChangedChangedTo
} from '../store/actions';
import {Page, WrvuViewType} from '../shared/helpers';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {Observable} from 'rxjs';
import {SelectionCounts} from '../shared/models';
import {ColumnType} from '../shared/enums';

@Component({
  selector: 'app-toggle-cfte-actual-wrvus',
  templateUrl: './toggle-cfte-actual-wrvus.component.html',
  styleUrls: ['./toggle-cfte-actual-wrvus.component.scss']
})
export class ToggleCfteActualWrvusComponent implements OnInit, OnChanges {

  @Input() fromPdf: boolean;
  @Input() canShowCfteAdjustedWrvus = true;
  @Input() showOverviewPage = false;
  @Input() fromOverview = false;
  @Input() wrvuViewType: WrvuViewType;
  @Input() selectionCounts: SelectionCounts;
  @Input() page: Page;
  @Input() activeVarianceToggle: boolean;

  WrvuSnapshot = Page.WrvuSnapshot;
  showActual = false;
  showHover = false;
  actualText = 'Variance view is based on your cFTE adj. wRVU variance from the benchmark. To view actual wRVUs, toggle off Variance view.';

  constructor(private ngRedux: NgRedux<IAppState>) { }

  @select(['display', 'wrvuViewType'])
  private readonly wrvuViewType$: Observable<WrvuViewType>;

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges(changes?: SimpleChanges) {
    const showActualInReduxStore = this.ngRedux.getState().display.wrvuViewType === WrvuViewType.Actual;
    this.showActual = !this.activeVarianceToggle && (showActualInReduxStore || !this.canShowCfteAdjustedWrvus);
  }

  toggleShowHover(show: boolean): void {
    this.showHover = show;
  }

  showCfteAdjustedWrvuGraph() {
    this.ngRedux.dispatch(wrvuViewTypeChangedChangedTo(WrvuViewType.CfteAdjusted));

    const wrvuSortingCriteria = this.ngRedux.getState().display.sortingCriteria?.wrvuSnapshotSortingCriteria;
    if (wrvuSortingCriteria) {
      this.ngRedux.dispatch(wrvuSnapshotSortingCriteriaChangedTo({
        ...wrvuSortingCriteria,
        columnDef: 'cfteAdjustedWRVUs'
      }));
    }
  }

  showActualWrvuGraph() {
    this.ngRedux.dispatch(wrvuViewTypeChangedChangedTo(WrvuViewType.Actual));
    this.ngRedux.dispatch(wrvuVarianceToggleChangedTo(false));

    const wrvuSortingCriteria = this.ngRedux.getState().display.sortingCriteria?.wrvuSnapshotSortingCriteria;
    if (wrvuSortingCriteria) {
      this.ngRedux.dispatch(wrvuSnapshotSortingCriteriaChangedTo({
        ...wrvuSortingCriteria,
        columnDef: 'wRVUs',
        columnType: ColumnType.WRVU
      }));
    }
  }
}
