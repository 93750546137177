import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Legend, SortingCriterion} from '../../../shared/models';
import {LevelType, toTitleCase} from '../../../shared/helpers';
import {Variable} from '../../../variable-container/variable-container.component';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../../shared/benchmark-types';
import {combineLatest, Observable, Subscription} from 'rxjs';
import * as Highcharts from 'highcharts/highstock';
import {AxisLabelsFormatterContextObject, PlotColumnOptions, PointClickEventObject, YAxisOptions} from 'highcharts/highstock';
import {select} from '@angular-redux/store';
import {Options} from 'highcharts';
import {Chart} from 'angular-highcharts';
import {abbreviateAxisValue, roundToNearestTenthOrZero, roundToNumber} from '../../../productivity-summary/number-formatter';
import {MetricType} from '../../../shared/metric-types';
import {
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  setTickValue,
  StyledChart
} from '../../../shared/highcharts-helpers';
import {CHART_HEIGHT} from '../../../productivity-summary/productivity-graph-component';
import {GraphColors} from '../../../productivity-summary/colors';
import {concat} from '../../../shared/ourLodash';
import {hasValue} from '../../../shared/null-helpers';
import {IndividualSeriesOptions} from '../../../shared/highcharts-mappings';
import {VarianceToggleActions, VarianceToggleField} from '../../../variance-toggler/variance-toggler.component';
import {ColumnType, HighChartColorIndex, LegendColor, LegendStyle, SortingOrder} from '../../../shared/enums';
import {AppAction, denialsSortingCriteriaChangedTo, denialsVarianceToggleChangedTo} from '../../../store/actions';
import {
  chargesLegendForDenials,
  chargesTerminallyDeniedLegend,
  deniedCptsPaidLegend,
  terminalDenialRateLegend
} from '../../denials-chart/denials-chart-helper';
import {doesDenialsDataHaveValidBenchmarkValue} from '../../denials-chart/denials-chart-helper';
import {DenialsMultiLevelData, DenialsMultiLevelEntity} from '../../denials-models';

@Component({
  selector: 'app-denials-multilevel-chart',
  templateUrl: './denials-multilevel-chart.component.html',
  styleUrls: ['./denials-multilevel-chart.component.scss']
})
export class DenialsMultilevelChartComponent implements OnInit, OnChanges {
  @Input() showProgressBar: boolean;
  @Input() variables: Variable[];
  @Input() showVariableMenu: boolean;
  @Input() page: string;
  @Input() level: string;
  @Input() columnSelectionCallback: (denials: DenialsMultiLevelEntity) => void;
  @Input() graphData: DenialsMultiLevelEntity[];
  @Input() isSpecialty: boolean;
  @Input() isDepartment: boolean;
  @Input() isProvider: boolean;
  defaultBenchmarkPercentile = BenchmarkPercentile.Percentile50th;
  @Input() benchmarkPercentile: BenchmarkPercentile = this.defaultBenchmarkPercentile;
  @Input() activeVarianceToggle = false;
  @Input() sortingCriteria: SortingCriterion;
  @Input() tabLevel: LevelType;
  @Output() toggleVarianceGraph = new EventEmitter<boolean>();

  hasBenchmarks = true;
  subscription: Subscription;
  chargesDisplayed: boolean;
  chargesTerminallyDeniedDisplayed: boolean;
  deniedCPTsPaidDisplayed: boolean;
  terminalDenialRateDisplayed: boolean;
  // @ts-ignore
  denialRateSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  benchmarkDataSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  chargesSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  chargesTerminallyDeniedSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  deniedCPTsPaidSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  terminalDenialRateSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];

  @ViewChild('chartDenialsTarget', {static: true})
  public chartTarget: ElementRef;

  @select(['display', 'denialsChargesDisplayed'])
  private readonly chargesDisplayed$: Observable<boolean>;
  @select(['display', 'chargesTerminallyDeniedDisplayed'])
  private readonly chargesTerminallyDeniedDisplayed$: Observable<boolean>;
  @select(['display', 'deniedCPTsPaidDisplayed'])
  private readonly deniedCPTsPaidDisplayed$: Observable<boolean>;
  @select(['display', 'terminalDenialRateDisplayed'])
  private readonly terminalDenialRateDisplayed$: Observable<boolean>;

  @select(['data', 'denialsByMultilevelData'])
  private readonly denialsByMultilevelData$: Observable<DenialsMultiLevelData>;

  @select(['data', 'denialsByMultilevelData', 'departmentDenials'])
  private readonly departmentDenials$: Observable<DenialsMultiLevelData>;

  @select(['data', 'denialsByMultilevelData', 'specialtyDenials'])
  private readonly specialtyDenials$: Observable<DenialsMultiLevelData>;

  public legends: Legend[];
  public varianceLegend: Legend[] = [
    {
      text: 'Above Benchmark',
      color: LegendColor.RED,
      metric: MetricType.Denials,
      style: LegendStyle.SQUARE
    },
    {
      text: 'Below Benchmark',
      color: LegendColor.GREEN,
      metric: MetricType.Denials,
      style: LegendStyle.SQUARE
    }
  ];
  private availableBenchmarks: BenchmarkPercentile[] = [
    BenchmarkPercentile.Percentile25th,
    BenchmarkPercentile.Percentile50th,
    BenchmarkPercentile.Percentile75th
  ];

  benchmarkToExclude = ['65th Percentile', '90th Percentile', 'Mean'];

  private maxNumberScrollbars = 20;
  options: Options;
  chartObject: Chart;

  private snapshotBarCount = 20;
  private chartWidth = calculateChartWidth(DEFAULT_POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING);

  varianceToggle: VarianceToggleActions = {
    display: false,
    reducerField: VarianceToggleField.DenialsVarianceToggle,
    sortingCriterion: {
      sortingOrder: SortingOrder.DESCENDING,
      columnDef: 'variancePer50th',
      columnType: ColumnType.VARIANCE,
    },

    dispatchAction(display: boolean): AppAction {
      return denialsVarianceToggleChangedTo(display);
    },
    sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction {
      return denialsSortingCriteriaChangedTo(sortingCriteria);
    }
  };

  private barChartSeries: PlotColumnOptions = {
    cropThreshold: 100,
    pointPadding: DEFAULT_POINT_PADDING,
    stacking: 'normal'
  };

  constructor() {
  }

  ngOnInit(): void {
    combineLatest([
      this.chargesDisplayed$,
      this.chargesTerminallyDeniedDisplayed$,
      this.deniedCPTsPaidDisplayed$,
      this.terminalDenialRateDisplayed$
    ])
      .subscribe(([chargesDisplayed, chargesTerminallyDeniedDisplayed, deniedCPTsPaidDisplayed, terminalDenialRateDisplayed]
                    : [boolean, boolean, boolean, boolean]) => {
        this.chargesDisplayed = chargesDisplayed;
        this.chargesTerminallyDeniedDisplayed = chargesTerminallyDeniedDisplayed;
        this.deniedCPTsPaidDisplayed = deniedCPTsPaidDisplayed;
        this.terminalDenialRateDisplayed = terminalDenialRateDisplayed;
        if (this.sortingCriteria) {
          this.varianceToggle.sortingCriterion = this.sortingCriteria;
        }
        if (this.activeVarianceToggle) {
          this.drawVarianceGraph();
        } else {
          this.drawGraph();
        }
      });
  }

  ngOnChanges() {
    if (this.sortingCriteria) {
      this.varianceToggle.sortingCriterion = this.sortingCriteria;
    }
    this.hasBenchmarks = doesDenialsDataHaveValidBenchmarkValue(this.graphData);
    if (this.activeVarianceToggle) {
      this.drawVarianceGraph();
    } else {
      this.drawGraph();
    }
  }

  drawGraph() {
    if (!this.graphData) {
      return;
    }
    const denialRate: any[] = [];
    if (!this.availableBenchmarks.includes(this.benchmarkPercentile)) {
      this.benchmarkPercentile = BenchmarkPercentile.Percentile50th;
    }

    this.graphData.forEach(x => {
      denialRate.push({
        y: x.denialRate,
        colorIndex: HighChartColorIndex.TEAL,
        description: x.departmentNodeName
      });
    });

    let benchmarkData: BenchmarkPercentile[] = [];
    let benchmarkName = '';
    switch (this.benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        benchmarkData = this.graphData.map(x => roundToNumber(x.denialRatePer25th, 1) || 0);
        benchmarkName = 'Denial Rate 25th Percentile';
        break;
      case BenchmarkPercentile.Percentile50th:
        benchmarkData = this.graphData.map(x => roundToNumber(x.denialRatePer50th, 1) || 0);
        benchmarkName = 'Denial Rate 50th Percentile';
        break;
      case BenchmarkPercentile.Percentile75th:
        benchmarkData = this.graphData.map(x => roundToNumber(x.denialRatePer75th, 1) || 0);
        benchmarkName = 'Denial Rate 75th Percentile';
        break;
    }

    const charges = this.graphData.map(x => roundToNumber(x.chargeAmount, 1) || 0);

    const chargesTerminallyDenied = this.graphData.map(x => roundToNumber(x.chargesTerminallyDenied, 1) || 0);

    const deniedCPTsPaid = this.graphData.map(x => roundToNearestTenthOrZero(x.deniedPaidRate) || 0);

    const terminalDenialRate = this.graphData.map(x => roundToNearestTenthOrZero(x.terminalDenialRate * 100) || 0);

    const deniedCPTsDisplayed = this.deniedCPTsPaidDisplayed;
    const terminalDenialRateDisplayed = this.terminalDenialRateDisplayed;

    this.legends = [
      {
        text: 'Denial Rate',
        color: LegendColor.TEAL,
        metric: MetricType.Denials,
        style: LegendStyle.SQUARE,
        showPercentileControl: false
      }, {
        text: 'CPSC Denial Rate Benchmark:',
        color: LegendColor.GREY,
        metric: MetricType.Denials,
        style: LegendStyle.SQUARE,
        showPercentileControl: true
      }
    ];

    let maxLeft, minLeft;
    const rangeDetermineLeft: number[] = [];
    denialRate.forEach(x => {
      rangeDetermineLeft.push(x.y);
    });
    benchmarkData.forEach(x => {
      rangeDetermineLeft.push(x);
    });

    maxLeft = Math.max(...rangeDetermineLeft);
    minLeft = Math.min(...rangeDetermineLeft);

    let maxRight, minRight;
    const rangeDetermineRight: number[] = [];

    if (this.chargesTerminallyDeniedDisplayed) {
      this.addVariableToGraph(chargesTerminallyDenied, rangeDetermineRight, chargesTerminallyDeniedLegend);
    }

    if (this.deniedCPTsPaidDisplayed) {
      this.addVariableToGraph(deniedCPTsPaid, rangeDetermineRight, deniedCptsPaidLegend);
    }
    if (this.chargesDisplayed) {
      this.addVariableToGraph(charges, rangeDetermineRight, chargesLegendForDenials);
    }

    if (this.terminalDenialRateDisplayed) {
      this.addVariableToGraph(terminalDenialRate, rangeDetermineRight, terminalDenialRateLegend);
    }


    if (charges.length > 0) {
      maxRight = Math.max(...rangeDetermineRight) + 1;
    }

    if (chargesTerminallyDenied.length > 0) {
      maxRight = Math.max(...rangeDetermineRight) + 1;
    }

    if (deniedCPTsPaid.length > 0) {
      maxRight = Math.max(...rangeDetermineRight) + 1;
    }

    if (terminalDenialRate.length > 0) {
      maxRight = Math.max(...rangeDetermineRight) + 1;
      minRight = Math.min(...rangeDetermineRight) + 1;
      if (this.terminalDenialRateDisplayed) {
        if (maxRight < 0) {
          maxRight *= -1;
        }
      }
    } else {
      maxRight = minRight = 0;
    }

    if (denialRate.length > 0) {
      if (maxLeft < 0) {
        maxLeft *= -1;
      }
    } else {
      maxLeft = minLeft = 0;
    }

    // @ts-ignore
    const categories = createEmptyLabels(
      // @ts-ignore
      this.getLabels(this.graphData),
      this.maxNumberScrollbars,
    );

    const isScrolled: boolean = categories.length > this.maxNumberScrollbars;

    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% Denial Rate',
          margin: 30
        },
        max: maxLeft,
        min: minLeft > 0 ? 0 : minLeft,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return abbreviateAxisValue(this.value) + '%';
          },
        },
        tickAmount: 9
      },
    ];

    const seriesData: ([string, number] | number)[] =
      // @ts-ignore
      this.graphData.map((denials) =>
        [this.getTooltipName(denials), denials.denialRate || 0]
      );


    const rightSideYAxis: YAxisOptions[] = (this.chargesDisplayed || this.chargesTerminallyDeniedDisplayed ||
    this.deniedCPTsPaidDisplayed || this.terminalDenialRateDisplayed
      ?
      [
        {
          title: {
            text: this.getRightSideLabel(),
          },
          max: maxRight,
          min: minRight > 0 ? 0 : minRight,
          opposite: true,
          labels: {
            formatter: function (this: AxisLabelsFormatterContextObject<any>) {
              return deniedCPTsDisplayed || terminalDenialRateDisplayed ? abbreviateAxisValue(this.value) + '%' : '$' +
                abbreviateAxisValue(this.value);
            },
          },
          tickAmount: 9
        }
      ] : []);

    const multiLevelEventClick = {
      click: (event: PointClickEventObject) => {
        this.columnSelectionCallback(this.graphData[event.point.x]);
      }
    };
    this.denialRateSeriesOptions = [
      {
        turboThreshold: 0,
        name: 'Denial Rate',
        yAxis: 0,
        data: seriesData.length < this.maxNumberScrollbars ?
          createEmptyValues(seriesData, this.maxNumberScrollbars) :
          seriesData,
        type: ChartType.COLUMN,
        colorIndex: HighChartColorIndex.TEAL,
        pointWidth: DEFAULT_POINT_WIDTH,
        stack: 0,
        events: multiLevelEventClick,
        cursor: this.isProvider ? 'default' : 'pointer',
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
          valueDecimals: 0
        }
      },
    ];

    this.benchmarkDataSeriesOptions = [
      {
        turboThreshold: 0,
        name: benchmarkName,
        yAxis: 0,
        data: benchmarkData.length < this.maxNumberScrollbars ?
          createEmptyValues(benchmarkData, this.maxNumberScrollbars) :
          benchmarkData,
        type: ChartType.LINE,
        colorIndex: HighChartColorIndex.GREY,
        pointWidth: DEFAULT_POINT_WIDTH,
        stack: 1,
        cursor: this.isProvider ? 'default' : 'pointer',
        events: multiLevelEventClick,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25C6</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
          valueDecimals: 0
        }
      }
    ];

    this.chargesSeriesOptions =
      this.chargesDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Total Charges',
          yAxis: 1,
          data: charges.length < this.maxNumberScrollbars ?
            createEmptyValues(charges, this.maxNumberScrollbars) :
            charges,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.ORANGE,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: this.isProvider ? 'default' : 'pointer',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-15">\u25A1</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];

    this.chargesTerminallyDeniedSeriesOptions =
      this.chargesTerminallyDeniedDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Charges Terminally Denied',
          yAxis: 1,
          data: chargesTerminallyDenied.length < this.maxNumberScrollbars ?
            createEmptyValues(chargesTerminallyDenied, this.maxNumberScrollbars) :
            chargesTerminallyDenied,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.RED,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: this.isProvider ? 'default' : 'pointer',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-22">\u25A1</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];

    this.deniedCPTsPaidSeriesOptions =
      this.deniedCPTsPaidDisplayed ? [
        {
          turboThreshold: 0,
          name: '% of Denied CPTs Paid',
          yAxis: 1,
          data: deniedCPTsPaid.length < this.maxNumberScrollbars ?
            createEmptyValues(deniedCPTsPaid, this.maxNumberScrollbars) :
            deniedCPTsPaid,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.BLUE,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: this.isProvider ? 'default' : 'pointer',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-17">\u25A1</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
            valueDecimals: 1
          }
        }
      ] : [];

    this.terminalDenialRateSeriesOptions =
      this.terminalDenialRateDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Terminal Denial Rate',
          yAxis: 1,
          data: terminalDenialRate.length < this.maxNumberScrollbars ?
            createEmptyValues(terminalDenialRate, this.maxNumberScrollbars) :
            terminalDenialRate,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.PURPLE,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: this.isProvider ? 'default' : 'pointer',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-13">\u25A1</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
            valueDecimals: 1
          }
        }
      ] : [];


    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: concat(this.denialRateSeriesOptions, this.benchmarkDataSeriesOptions,
        this.chargesSeriesOptions, this.chargesTerminallyDeniedSeriesOptions,
        this.deniedCPTsPaidSeriesOptions, this.terminalDenialRateSeriesOptions),
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 0,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.chartWidth,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground,
        },
        // @ts-ignore
        clickOnCrosshair: (e: any, p: any) => {
          //  @ts-ignore
          this.columnSelectionCallback(this.graphData[p.index]);

        },
        min: 0,
        max: isScrolled ? this.maxNumberScrollbars : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            let ret = '';
            const tokenized = this.value.toLocaleString().split(' ');
            for (let i = 0; i < tokenized.length; i++) {
              ret = ret.concat(tokenized[i] + ' ');
              if (i === roundToNumber(tokenized.length / 2, 0) - 1) {
                ret = ret.concat('<br/>');
              }
            }
            return ret;
          },
          rotation: 45,
        },
      },
      yAxis: concat(leftSideYAxis, rightSideYAxis),
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false,
      },
      plotOptions: {
        column: {
          ...this.barChartSeries,
          tooltip: {
            pointFormat: ''
          }
        }
      },
    };

    if (this.isProvider && this.options.xAxis) {
      // @ts-ignore
      delete this.options.xAxis.clickOnCrosshair;
    }

    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  drawVarianceGraph() {
    if (!this.graphData) {
      return;
    }
    const data = this.graphData.filter((datum: any) =>
      hasValue(this.getVarianceForVarianceGraph(datum, this.benchmarkPercentile)));
    switch (this.benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        data.sort((a, b) => a.variancePer25th - b.variancePer25th);
        break;
      case BenchmarkPercentile.Percentile50th:
        data.sort((a, b) => a.variancePer50th - b.variancePer50th);
        break;
      case BenchmarkPercentile.Percentile75th:
        data.sort((a, b) => a.variancePer75th - b.variancePer75th);
    }
    data.sort((a, b) => a.variancePer50th - b.variancePer50th);
    if (!this.availableBenchmarks.includes(this.benchmarkPercentile)) {
      this.benchmarkPercentile = BenchmarkPercentile.Percentile50th;
    }
    const varianceData: any[] = [];

    let max: number | string = 0;
    let min: number | string = 0;
    // @ts-ignore
    data.forEach(denials => {
      const varianceValue = this.getVarianceForVarianceGraph(denials, this.benchmarkPercentile)
        || 0;
      if (varianceValue !== 0 && varianceValue > max) {
        max = varianceValue;
      } else if (varianceValue !== 0 && varianceValue < min) {
        min = varianceValue;
      }
      let name = '', drilldown = '';
      if (this.isDepartment) {
        name = denials.departmentNodeName;
        drilldown = denials.departmentNodePath;
      } else if (this.isSpecialty) {
        name = denials.specialtyNodeName;
        drilldown = denials.specialtyNodePath;
      } else if (this.isProvider) {
        name = denials.providerNodeName;
        drilldown = denials.providerNodePath;
      }
      varianceData.push({
        name: name,
        y: varianceValue,
        colorIndex: varianceValue >= 0 ? HighChartColorIndex.RED : HighChartColorIndex.GREEN,
        drilldown: drilldown,
        providerNodePath: denials.providerNodePath,
        providerNodeName: denials.providerNodeName,
        specialtyNodePath: denials.specialtyNodePath,
        specialtyNodeName: denials.specialtyNodeName,
        departmentNodePath: denials.departmentNodePath,
        departmentNodeName: denials.departmentNodeName,
      });
    });
    this.legends = this.varianceLegend;

    const multiLevelEventClick = {
      click: (event: PointClickEventObject) => {
        this.columnSelectionCallback(varianceData[event.point.x]);
      }
    };

    const categories = createEmptyLabels(
      this.getLabels(data),
      this.maxNumberScrollbars,
    );
    const isScrolled: boolean = categories.length > this.maxNumberScrollbars;
    const absoluteMax = roundToNumber(Math.max(...[Math.abs(max), Math.abs(min)]), 0);

    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% Variance',
        },
        max: absoluteMax,
        min: -1 * absoluteMax,
        opposite: false,
        labels: {
          formatter: function () {
            return roundToNumber(this.value, 0) + '%';
          },
        },
        plotLines: [{
          color: '#000000',
          width: 200,
          value: 0,
          dashStyle: 'Solid',
          zIndex: 999
        }],
        tickInterval: setTickValue(100),
      },
    ];

    const varianceSeries: IndividualSeriesOptions[] = [
      {
        name: 'Variance from benchmark ' + readableNameOfColumnDef(this.benchmarkPercentile).toLowerCase(),
        yAxis: 0,
        type: ChartType.COLUMN,
        data: varianceData.length < this.maxNumberScrollbars ?
          createEmptyValues(varianceData, this.maxNumberScrollbars) :
          varianceData,
        pointWidth: DEFAULT_POINT_WIDTH,
        stacking: 'normal',
        stack: 0,
        events: multiLevelEventClick,
        cursor: this.isProvider ? 'default' : 'pointer',
        zIndex: 1,
      },
    ];

    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: varianceSeries,
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.chartWidth,
        height: CHART_HEIGHT,
        styledMode: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        max: isScrolled ? this.maxNumberScrollbars - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return toTitleCase(this.value.toString());
          },
          rotation: 45,
        },
      },
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries
        }
      },
    };
    if (this.isProvider && this.options.xAxis) {
      // @ts-ignore
      delete this.options.xAxis.clickOnCrosshair;
    }
    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  getVarianceForVarianceGraph(denials: DenialsMultiLevelEntity, benchmarkPercentile: BenchmarkPercentile): number | undefined {
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        return denials.variancePer25th;
      case BenchmarkPercentile.Percentile50th:
        return denials.variancePer50th;
      case BenchmarkPercentile.Percentile75th:
        return denials.variancePer75th;
      default:
        return undefined;
    }
  }

  getLabels(data: DenialsMultiLevelEntity[]): string [] {
    switch (this.tabLevel) {
      case LevelType.provider:
        //  @ts-ignore
        return data.map((datum) => datum.providerNodeName);
      case LevelType.specialty:
        // @ts-ignore
        return data.map((datum) => datum.specialtyNodeName);
      case LevelType.department:
      default:
        // @ts-ignore
        return data.map((datum) => datum.departmentNodeName);
    }
  }

  getTooltipName(denials: any) {
    return (denials.providerNodeName ? denials.providerNodeName + '<br/>' : '')
      + (denials.specialtyNodeName ? denials.specialtyNodeName + '<br/>' : '')
      + denials.departmentNodeName || denials.nodeName;
  }

  getRightSideLabel(): string {
    if (this.chargesDisplayed) {
      return chargesLegendForDenials.text;
    } else if (this.chargesTerminallyDeniedDisplayed) {
      return chargesTerminallyDeniedLegend.text;
    } else if (this.deniedCPTsPaidDisplayed) {
      return deniedCptsPaidLegend.text;
    } else if (this.terminalDenialRateDisplayed) {
      return terminalDenialRateLegend.text;
    } else {
      return '';
    }
  }

  private addVariableToGraph(variableData: number[], rangeDetermineRight: number[], legend: Legend) {
    variableData.forEach(x => {
      rangeDetermineRight.push(x);
    });
    this.legends.push(legend);
  }
}
