import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Variable} from '../../../../variable-container/variable-container.component';
import * as Highcharts from 'highcharts/highstock';
import {YAxisOptions} from 'highcharts/highstock';
import {NgRedux, select} from '@angular-redux/store';
import {FilterCriteria, IAppState} from '../../../../store/IAppState';
import {combineLatest, Observable} from 'rxjs';
import {
  addPayerVarianceDatum,
  barChartSeriesForCollectionsCharts,
  chargesLegendForCollections,
  defaultLegendsForCollectionsChart,
  doesCollectionsDataHaveValidBenchmarkValue,
  expectedPaymentsLegend,
  getLeftSideYAxisForCollectionsVarianceCharts,
  getRightSideYAxisForCollectionsCharts,
  getVarianceForVarianceGraph,
  getVarianceSeriesOptions,
  legendsForCollectionsVarianceCharts,
  maxScrollBarsForCollectionsCharts,
  PayerCollections,
  PayerDrilldownCriteria,
  pointWidthForCollectionsCharts,
  validateMaxAxisValue,
  widthForCollectionsCharts
} from '../../../Collection';
import {BenchmarkPercentile} from '../../../../shared/benchmark-types';
import {roundToNumber} from '../../../../productivity-summary/number-formatter';
import {
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_LANG_OBJECT,
  StyledChart,
  xAxisLabels
} from '../../../../shared/highcharts-helpers';
import {concat} from '../../../../shared/ourLodash';
import {CHART_HEIGHT} from '../../../../productivity-summary/productivity-graph-component';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {Options, PointClickEventObject} from 'highcharts';
import {Chart} from 'angular-highcharts';
import {ColumnType, HighChartColorIndex, PayerType, SortingOrder} from '../../../../shared/enums';
import {GraphColors} from '../../../../productivity-summary/colors';
import {Legend, SortingCriterion} from '../../../../shared/models';
import {VarianceToggleActions, VarianceToggleField} from '../../../../variance-toggler/variance-toggler.component';
import {
  AppAction,
  collectionsSnapshotSortingCriteriaChangedTo,
  collectionsSnapshotVarianceToggleChangedTo
} from '../../../../store/actions';
import {deepCopyOf} from '../../../../shared/helpers';


@Component({
  selector: 'app-payer-collections-chart',
  templateUrl: './payer-collections-chart.component.html',
  styleUrls: ['./payer-collections-chart.component.scss']
})
export class PayerCollectionsChartComponent implements OnInit, OnChanges {

  constructor(private readonly ngRedux: NgRedux<IAppState>) {
  }
  @Input() showProgressBar: boolean;
  @Input() variables: Variable[];
  @Input() showVariableMenu: boolean;
  @Input() page: string;
  @Input() payerSelectionCallBack: (payerDrilldownCriteria: PayerDrilldownCriteria) => void;
  @Input() activeVarianceToggle = false;
  @Input() sortingCriteria: SortingCriterion;

  defaultBenchmarkPercentile = BenchmarkPercentile.Percentile50th;
  benchmarkPercentile: BenchmarkPercentile = this.defaultBenchmarkPercentile;
  benchmarksToExclude = ['65th Percentile', '90th Percentile', 'Mean'];
  // @ts-ignore
  netCollectionRateSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  benchmarkDataSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  expectedPaymentsSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  chargesSeriesOptions: HighCharts.BarChartSeriesOptions[] = [];
  expectedPaymentsDisplayed: boolean;
  chargesDisplayed: boolean;

  hasBenchmarks = true;

  @select(['display', 'collectionsChargeDisplayed'])
  private readonly chargesDisplayed$: Observable<boolean>;

  @select(['display', 'expectedPaymentsDisplayed'])
  private readonly expectedPaymentsDisplayed$: Observable<boolean>;

  varianceToggle: VarianceToggleActions = {
    display: false,
    reducerField: VarianceToggleField.CollectionsSnapshotVarianceToggle,
    sortingCriterion: {
      sortingOrder: SortingOrder.DESCENDING,
      columnDef: 'variance50th',
      columnType: ColumnType.VARIANCE,
    },
    dispatchAction(display: boolean): AppAction {
      return collectionsSnapshotVarianceToggleChangedTo(display);
    },
    sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction {
      return collectionsSnapshotSortingCriteriaChangedTo(sortingCriteria);
    }
  };

  @ViewChild('chartPayerCollectionsTarget', {static: true})
  public chartTarget: ElementRef;

  @select(['data', 'payerCollectionsData', 'payerMatchedCollections'])
  private readonly payerCollectionsData$: Observable<PayerCollections[]>;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  public legends: Legend[];
  private availableBenchmarks: BenchmarkPercentile[] = [
    BenchmarkPercentile.Percentile25th,
    BenchmarkPercentile.Percentile50th,
    BenchmarkPercentile.Percentile75th
  ];

  options: Options;
  chartObject: Chart;
  varianceSeriesOptions: any;
  data: PayerCollections[];

  ngOnInit(): void {
    combineLatest([
      this.payerCollectionsData$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.benchmarkPercentile$,
      this.chargesDisplayed$,
      this.expectedPaymentsDisplayed$,
    ])
      .subscribe(([data, benchmarkPercentile, chargesDisplayed, expectedPaymentsDisplayed]:
                    [PayerCollections[], BenchmarkPercentile, boolean, boolean]) => {
        this.data = data;
        this.benchmarkPercentile = this.availableBenchmarks.includes(benchmarkPercentile) ?
          benchmarkPercentile : this.defaultBenchmarkPercentile;
        this.hasBenchmarks = doesCollectionsDataHaveValidBenchmarkValue(data);
        this.chargesDisplayed = chargesDisplayed;
        this.expectedPaymentsDisplayed = expectedPaymentsDisplayed;
        this.drawGraph();
      });
  }

  ngOnChanges(): void {
    if (this.sortingCriteria) {
      this.varianceToggle.sortingCriterion = this.sortingCriteria;
    }
    this.drawGraph();
  }

  drawGraph() {
    if (!this.data) {
      return;
    } else {
      this.activeVarianceToggle ? this.drawVarianceGraph() : this.drawStandardGraph();
    }
  }

  private setUpLegendsForRegularGraph() {
    this.legends = deepCopyOf(defaultLegendsForCollectionsChart);
    if (this.chargesDisplayed) {
      this.legends.push(chargesLegendForCollections);
    } else if (this.expectedPaymentsDisplayed) {
      this.legends.push(expectedPaymentsLegend);
    }
  }

  drawStandardGraph() {
    if (!this.availableBenchmarks.includes(this.benchmarkPercentile)) {
      this.benchmarkPercentile = BenchmarkPercentile.Percentile50th;
    }
    this.setUpLegendsForRegularGraph();
    const netCollectionRateData: any[] = [];
    const filters = this.ngRedux.getState().filters;
    const data = this.data;
    data.forEach(payer => {
      netCollectionRateData.push({
        y: payer.netCollectionRate,
        colorIndex: HighChartColorIndex.TEAL,
        drilldown: {
          category: filters.payerType !== PayerType.ALL ?
            filters.payerCategory : {
              payerCategoryKey: payer.payerCategoryKey,
              payerCategoryDescription: payer.payerCategoryDescription,
              payerCategoryCode: ''
            },
          key: payer.payerCategoryKey,
          type: filters.payerType + 1,
          description: payer.payerCategoryDescription
        }
      });
    });

    let benchmarkData: BenchmarkPercentile[] = [];
    let benchmarkName = '';
    switch (this.benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        benchmarkData = data.map(x => roundToNumber(x.benchmark25th, 1) || 0);
        benchmarkName = 'Benchmark 25th Percentile';
        break;
      case BenchmarkPercentile.Percentile50th:
        benchmarkData = data.map(x => roundToNumber(x.benchmark50th, 1) || 0);
        benchmarkName = 'Benchmark 50th Percentile';
        break;
      case BenchmarkPercentile.Percentile75th:
        benchmarkData = data.map(x => roundToNumber(x.benchmark75th, 1) || 0);
        benchmarkName = 'Benchmark 75th Percentile';
        break;
    }
    const expectedPayments: number[] = data.map(x => x.expectedPayments);
    // data.forEach((x: any) => {
    //   if (x.expectedPayments) {
    //     expectedPayments.push(x.expectedPayments);
    //   }
    // });
    const charges = data.map(x => roundToNumber(x.chargeAmount, 1) || 0);

    const categories = createEmptyLabels(
      this.getLabels(data),
      maxScrollBarsForCollectionsCharts
    );

    let maxLeftScaleVal, minLeftScaleVal;
    const rangeDetermineLeft: number[] = [];
    netCollectionRateData.forEach(x => {
      rangeDetermineLeft.push(x.y);
    });
    benchmarkData.forEach(x => {
      rangeDetermineLeft.push(x);
    });
    maxLeftScaleVal = Math.max(...rangeDetermineLeft);
    minLeftScaleVal = Math.min(...rangeDetermineLeft);
    maxLeftScaleVal = netCollectionRateData.length > 0 ?
      validateMaxAxisValue(maxLeftScaleVal) :
      (minLeftScaleVal = 0);

    const rangeDetermineRight: number[] = this.chargesDisplayed ? charges : expectedPayments;
    let maxRightScaleVal, minRightScaleVal;
    if (expectedPayments.length > 0 || charges.length > 0) {
      maxRightScaleVal = Math.max(...rangeDetermineRight) + 1;
      minRightScaleVal = Math.min(...rangeDetermineRight) + 1;
      if (this.expectedPaymentsDisplayed || this.chargesDisplayed) {
        maxRightScaleVal = validateMaxAxisValue(maxRightScaleVal);
      }
    } else {
      maxRightScaleVal = minRightScaleVal = 0;
    }
    const isScrolled: boolean = categories.length > maxScrollBarsForCollectionsCharts;
    const rightSideYAxis: YAxisOptions[] =
      getRightSideYAxisForCollectionsCharts(maxRightScaleVal, minRightScaleVal, this.chargesDisplayed, this.expectedPaymentsDisplayed);
    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% Net Collection Rate by Payer Group',
          margin: 30
        },
        max: maxLeftScaleVal,
        min: minLeftScaleVal > 0 ? 0 : minLeftScaleVal,
        opposite: false,
        tickInterval: 10,
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
      },
    ];

    this.netCollectionRateSeriesOptions = [
      {
        name: 'Net Collection Rate by Payer Group',
        yAxis: 0,
        type: ChartType.COLUMN,
        data: netCollectionRateData.length < maxScrollBarsForCollectionsCharts ?
          createEmptyValues(netCollectionRateData, maxScrollBarsForCollectionsCharts) :
          netCollectionRateData,
        stacking: 'normal',
        events: {
          click: (event: PointClickEventObject) => {
            if (this.payerSelectionCallBack && filters.payerType !== PayerType.MEMBER) {
              // @ts-ignore
              this.payerSelectionCallBack(event.point.drilldown);
            }
          },
        },
        pointWidth: pointWidthForCollectionsCharts,
        stack: 0,
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f} %</b><br/>',
          valueDecimals: 0
        }
      },
    ];

    this.benchmarkDataSeriesOptions = [
      {
        name: benchmarkName,
        yAxis: 0,
        data: benchmarkData.length < maxScrollBarsForCollectionsCharts ?
          createEmptyValues(benchmarkData, maxScrollBarsForCollectionsCharts) :
          benchmarkData,
        type: ChartType.LINE,
        colorIndex: HighChartColorIndex.GREY,
        pointWidth: pointWidthForCollectionsCharts,
        stack: 1,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25C6</span> {series.name}: <b>{point.y:,.0f} %</b><br/>',
          valueDecimals: 0
        }
      }
    ];

    this.expectedPaymentsSeriesOptions =
      this.expectedPaymentsDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Expected Payments',
          yAxis: 1,
          data: expectedPayments.length < maxScrollBarsForCollectionsCharts ?
            createEmptyValues(expectedPayments, maxScrollBarsForCollectionsCharts) :
            expectedPayments,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.PURPLE,
          pointWidth: pointWidthForCollectionsCharts,
          stack: 2,
          zIndex: 1,
          tooltip: {
            pointFormat: '<span class="highcharts-color-13">\u25EF</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];

    this.chargesSeriesOptions =
      this.chargesDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Charges',
          yAxis: 1,
          data: charges.length < maxScrollBarsForCollectionsCharts ?
            createEmptyValues(charges, maxScrollBarsForCollectionsCharts) :
            charges,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.ORANGE,
          pointWidth: pointWidthForCollectionsCharts,
          stack: 3,
          zIndex: 1,
          tooltip: {
            pointFormat: '<span class="highcharts-color-15">\u25A1</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];
    this.options = {
      lang: DEFAULT_LANG_OBJECT,
      title: {text: ''},
      series: concat(this.netCollectionRateSeriesOptions, this.benchmarkDataSeriesOptions,
        this.chargesSeriesOptions, this.expectedPaymentsSeriesOptions),
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: widthForCollectionsCharts,
        styledMode: true,
        height: CHART_HEIGHT,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground,
        },
        max: isScrolled ? maxScrollBarsForCollectionsCharts - 1 : categories.length - 1,
        labels: {
          rotation: 45,
        },
      },
      yAxis: concat(leftSideYAxis, rightSideYAxis),
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false,
      },
      plotOptions: {
        column: {
          ...barChartSeriesForCollectionsCharts,
          tooltip: {
            pointFormat: ''
          }
        }
      },
    };
    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  drawVarianceGraph() {
    this.legends = legendsForCollectionsVarianceCharts;
    const filters = this.ngRedux.getState().filters;
    const varianceData: any[] = [];
    const data = this.data;

    // @ts-ignore
    data.forEach(collection => {
      const varianceValue = getVarianceForVarianceGraph(collection, this.benchmarkPercentile);
      addPayerVarianceDatum(varianceValue, varianceData, collection, filters);
    });
    const rangeDetermineLeft: number[] = [];
    varianceData.forEach(variance => {
      rangeDetermineLeft.push(variance.y);
    });
    let maxLeft = Math.max(...rangeDetermineLeft);
    let minLeft = Math.min(...rangeDetermineLeft);
    if (varianceData.length > 0) {
      maxLeft = validateMaxAxisValue(maxLeft);
    } else {
      maxLeft = minLeft = 0;
    }
    const leftSideYAxis: any[] = getLeftSideYAxisForCollectionsVarianceCharts(maxLeft, minLeft);

    const categories = createEmptyLabels(this.getLabels(data), maxScrollBarsForCollectionsCharts);
    const isScrolled: boolean = categories.length > maxScrollBarsForCollectionsCharts;
    const isProvider = this.ngRedux.getState().display.isProviderSelected;

    this.options = this.getOptionsForVarianceGraph(categories, isScrolled, leftSideYAxis, varianceData, isProvider, filters);
    this.deleteXAxisCrosshairClick(isProvider);

    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  deleteXAxisCrosshairClick(isProvider: boolean) {
    if (isProvider && this.options.xAxis) {
      // @ts-ignore
      delete this.options.xAxis.clickOnCrosshair;
    }
  }

  getOptionsForVarianceGraph(categories: string[], isScrolled: boolean, leftSideYAxis: any[], varianceData: any[],
                             isProvider: boolean, filters: FilterCriteria) {
    const multiLevelEventClick = {
      click: (event: PointClickEventObject) => {
        if (this.payerSelectionCallBack && filters.payerType !== PayerType.MEMBER) {
          // @ts-ignore
          this.payerSelectionCallBack(event.point.drilldown);
        }
      }
    };

    this.varianceSeriesOptions = getVarianceSeriesOptions(varianceData, isProvider, multiLevelEventClick);

    return {
      lang: DEFAULT_LANG_OBJECT,
      title: {text: ''},
      series: this.varianceSeriesOptions,
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        width: widthForCollectionsCharts,
        height: CHART_HEIGHT,
        styledMode: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: this.getXAxis(categories, isScrolled, filters),
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false,
      },
      plotOptions: {
        column: {
          ...barChartSeriesForCollectionsCharts,
        }
      },
    };
  }

  private getXAxis(categories: string[], isScrolled: boolean, filters: FilterCriteria) {

    return {
      categories: categories,
      crosshair: {
        color: GraphColors.hoverBackground,
      },
      // @ts-ignore
      clickOnCrosshair: (e: any, p: any) => {

        if (this.payerSelectionCallBack && filters.payerType !== PayerType.MEMBER) {
          // @ts-ignore
          this.payerSelectionCallBack(event.point.drilldown);
        }
      },
      max: isScrolled ? maxScrollBarsForCollectionsCharts : categories.length - 1,
      labels: xAxisLabels,
    };
  }

  getLabels(data: PayerCollections[]): string[] {
    return data.map((datum) => datum.payerCategoryDescription);
  }
}
