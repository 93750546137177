<mat-select #selector
            id="payer-options-dropdown"
            panelClass="payer-options-panel"
            [value]="selectedOption.value"
            [disableOptionCentering]="true"
            hideSingleSelectionIndicator
>
  <mat-option
    *ngFor="let option of options"
    class="payer-option"
    [value]="option.value"
    (click)="updatePayers(option)"
  >
    {{ option.label }}
  </mat-option>
  <div *ngIf="showPayerCheckboxSelection" id="payer-checkbox-selection">
    <span id="search-label">SELECT PAYERS</span>
    <span class="input-payer-search-wrapper">
          <input class="search-for-payer"
                 type="text"
                 placeholder="Search..."
                 (input)="onPayerSearchTextChanged($event)"
                 [ngModel]="payerSearchText">
              <i class="fa fa-search" aria-hidden="true"></i>
        </span>
    <div id="selection-list-wrapper">
      <mat-checkbox
        *ngFor="let payer of listedPayers"
        class="select-payer-option"
        [(ngModel)]="payer.selected"
      >{{payer.payerName}}</mat-checkbox>
    </div>

    <div id="buttons-wrapper">
      <button id="cancel-button" (click)="cancelSelection()">Cancel</button>
      <button id="apply-button" (click)="applyPayerSelection()">Apply</button>
    </div>
  </div>
</mat-select>
