<div class=tile-wrapper *ngIf="!showEmOverviewGraph && !showOverviewZipTile">
  <div class=tile>
    <div class="toggle-wrapper">
      <div class=title>{{title}}</div>
      <app-toggle-cfte-actual-wrvus *ngIf="isWrvus"
                                    class="wrvu-title"
                                    [fromPdf]="fromPdfExport"
                                    [showOverviewPage]="true"
                                    [fromOverview]="true"></app-toggle-cfte-actual-wrvus>
    </div>

    <div class=sub-header>
      <span *ngIf="selectedDateRange && selectedDateRange.length > 0" class="selectedDateRange">{{selectedDateRange}}</span>
    </div>

    <div *ngIf="showProgressBar == false" class="variance-header">
      <span id="varianceSubtractFrom">{{varianceSubtractFrom}}</span>
      <span *ngIf="!isWrvus || isCfteAdjusted" id="benchmark-variance">
        is
        <span id="variance" [ngClass]="varianceClass"> {{variance}}</span>
        <span> the </span>
        <span [ngSwitch]="fromPdfExport.toString()">
          <span *ngSwitchCase="'true'" id="benchmark-static-span">{{benchmarkLabel}} </span>
          <mat-select *ngSwitchCase="'false'"
                      [ngClass]="benchMarkDropDownClass"
                      [placeholder]="benchmarkLabel"
                      id="benchmark-scale-dropdown"
                      [(value)]="benchmarkPercentile"
                      (selectionChange)="updateBenchmarkPercentile($event.value)"
                      hideSingleSelectionIndicator>
            <mat-option *ngFor="let benchmark of benchmarkPercentiles" [value]="benchmark.value">{{benchmark.name}}</mat-option>
          </mat-select>
        </span>
        <span>and</span>
      </span>
      <span> has a </span>
      <span id="previousYearDifference" [ngClass]="previousYearDifferenceClass">{{previousYearDifference}}</span>
      <span id="previousDateHeader"> from {{previousDateRange}}</span>
    </div>

    <div *ngIf="overviewChartOptions">
      <app-chart [chartOptions]="overviewChartOptions.chart"
                 [title]="overviewChartOptions.title"
                 [legends]="overviewChartOptions.legends"
                 [showScrollFull]="overviewChartOptions.showFullScrollbar"
                 [isScrolled]="overviewChartOptions.enableScrollbar"
                 [lang]="overviewChartOptions.lang"
                 [legendOptions]="overviewChartOptions.legend"
                 [series]="overviewChartOptions.series"
                 [tooltipOptions]="overviewChartOptions.tooltip"
                 [xAxisOptions]="overviewChartOptions.xAxis"
                 [yAxisOptions]="overviewChartOptions.yAxis"
                 [showLegend]="false"
                 [hasBenchmarks]="hasBenchmarks"
                 [fromPdf]="fromPdfExport">
      </app-chart>
    </div>

    <div id="overview-legend-table">
      <table>
        <tr class="row">
          <th></th>
          <th nowrap
              *ngFor="let legend of legendData"
              class="label {{legend.class}} {{legend.benchmark && 'bchmk'}} {{metricType===MetricType.WorkRVUs ? 'wrvu-th' : ''}} {{metricType===MetricType.NewPatientVisits ? 'npv-th' : ''}}">
            <div class="{{legend.benchmark ? 'legend-bchmk-header' : ''}} {{metricType===MetricType.NewPatientVisits && telehealthBenchmarksEnabled ? 'npv-wrap-option' : ''}}">
              {{legend.name}}
              <app-legend-benchmark-option *ngIf="legend.benchmark"
                                         [originClass]="'overview'"
                                         [fromPdf]="fromPdfExport"
                                         [metric]="metricType"
                                         [benchmarkPlaceholder]="benchmarkLabel"></app-legend-benchmark-option>
            </div>
            <div *ngIf="legend.benchmark" [ngClass]="getBenchmarkHeaderClass(legend)">
              Benchmark
              <app-legend-benchmark-percentile [fromPdf]="fromPdfExport"
                                               [benchmarkToExclude]="metricType === MetricType.NewPatientVisits ? ['65th Percentile'] : []"
                                               [originClass]="'overview'"></app-legend-benchmark-percentile>
            </div>

          </th>
        </tr>
        <tr class="row">
          <td nowrap class="label">{{selectedDateRange}}</td>
          <td nowrap *ngFor="let legend of legendData" class="{{legend.currentDatesColor}}">
            <div *ngIf="legend.currentDatesIcon" class="{{legend.currentDatesIcon}} {{legend.currentDatesColor}}"></div>
            {{legend.currentDatesValue}}</td>
        </tr>
        <tr class="row">
          <td nowrap class="label">{{previousDateRange}}</td>
          <td nowrap *ngFor="let legend of legendData" class="{{legend.previousDatesColor}}">
            <div *ngIf="legend.previousDatesIcon" class="{{legend.previousDatesIcon}} {{legend.previousDatesColor}}"></div>
            {{legend.previousDatesValue}}</td>
        </tr>
      </table>
    </div>
    <app-overview-tile-footer [fromPdfExport]="fromPdfExport" [metricType]="metricType"></app-overview-tile-footer>
    <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
</div>

<div class=tile-wrapper-em *ngIf="showEmOverviewGraph">
  <div class=tile>
    <div class=title>{{title}}</div>
    <div class=em-sub-header>
      <span class="selectedDateRange" *ngIf="selectedDateRange && selectedDateRange.length > 0">{{selectedDateRange}}</span>
    </div>

    <app-em-page-multilevel id="em-container-multilevel"
                            [overviewPageView]="true"
                            [fromPdf]="fromPdfExport"
                            [emMetrics]="emMetrics"></app-em-page-multilevel>

    <app-overview-tile-footer [fromPdfExport]="fromPdfExport" [metricType]="metricType"></app-overview-tile-footer>
  </div>
</div>
