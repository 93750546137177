import {AfterViewChecked, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ColumnType, MultilevelTab, SortingOrder} from '../../../shared/enums';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, FilterCriteria, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {
  MergedProviderProductivityMultiLevelData,
  ProductivityMultiLevelSnapshot,
  ProviderProductivityMultiLevelData
} from '../../services/ProviderProductivity';
import {combineFilter, FilterType, getSelectionCounts, isSortingCriterionValid, WrvuViewType} from '../../../shared/helpers';
import {FeatureToggleSetting, SelectionCounts, SortingCriterion, UserProfile, UserSecurity} from '../../../shared/models';
import {
  AppAction,
  chargeDisplayChangedTo,
  multilevelTabChangedTo,
  previousTimePeriodDisplayChanged,
  providerWrvuColumnsChangedTo,
  snapshotWrvuColumnsChangedTo,
  specialtyPerformanceWrvuColumnsChangedTo,
  wrvuSnapshotSortingCriteriaChangedTo
} from '../../../store/actions';
import {DataTableColumns, specialtyPerformanceColumns} from '../../../shared/data-table-columns';
import {DisplayField, Variable} from '../../../variable-container/variable-container.component';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {
  adjustBenchmarkDependentColumns,
  commonWrvuColumns,
  departmentNodeColumnForWrvu,
  multiLevelWrvuDeptColumns,
  multiLevelWrvuSpecColumns,
  providerNodeColumnForWrvu,
  providerPercentileColumn,
  providerPercentileCommunityColumn,
  specialtyNodeColumnForWrvu,
  updateColumnFilter
} from '../../../shared/BenchmarkColumns';
import {checkForNulls} from '../../../shared/null-helpers';
import {Location} from '@angular/common';
import {FilterSelectorService, FilterSelectorServiceToken} from '../../../services/filter-selector.service';
import {ApiServiceImpl, ApiServiceToken} from '../../../services/api.service';
import {WrvuMultilevelProviderPageComponent} from '../wrvu-multilevel-provider-page/wrvu-multilevel-provider-page.component';
import {WrvuMultilevelSpecialtyPageComponent} from '../wrvu-multilevel-specialty-page/wrvu-multilevel-specialty-page.component';
import {WrvuMultilevelDepartmentPageComponent} from '../wrvu-multilevel-department-page/wrvu-multilevel-department-page.component';
import {DEPARTMENT_TAB, PROVIDER_TAB, SPECIALTY_PERFORMANCE_TAB, SPECIALTY_TAB, TREND_TAB} from '../../../shared/constants';
import {ontologyTabEquivalentOfLocationTab, TabNavigationElement} from '../../../tab-navigation/tab-helper';
import {formatNumberToWholeNumber, roundToWithCommasSeparation} from '../../number-formatter';

@Component({
  selector: 'app-wrvu-multilevel',
  templateUrl: './wrvu-multilevel.component.html',
  styleUrls: ['./wrvu-multilevel.component.scss']
})
export class WrvuMultilevelComponent implements OnInit, OnDestroy, AfterViewChecked {

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['data', 'providerProductivityMultilevelData'])
  private readonly providerProductivityMultiLevel$: Observable<MergedProviderProductivityMultiLevelData>;

  @select(['display', 'wrvuViewType'])
  private readonly wrvuViewType$: Observable<WrvuViewType>;

  @select(['display', 'zeroSuppression'])
  private readonly zeroSuppression$: Observable<boolean>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'displayedWRVUSnapshotColumns'])
  private readonly displayedWRVUSnapshotColumns$: Observable<BaseColumn[]>;

  @select(['display', 'displayedWRVUProviderColumns'])
  private readonly displayedWRVUProviderColumns$: Observable<BaseColumn[]>;

  @select(['display', 'displayedWRVUSpecialtyPerformanceColumns'])
  private readonly displayedWRVUSpecialtyPerformanceColumns$: Observable<BaseColumn[]>;

  @select(['display', 'sortingCriteria', 'wrvuSnapshotSortingCriteria'])
  private readonly wrvuSnapshotSortingCriteria$: Observable<SortingCriterion | undefined>;

  @select(['display', 'varianceToggle', 'wrvuVariance'])
  private readonly wrvuVarianceToggle$: Observable<boolean>;

  @select(['display', 'multilevelTab'])
  private readonly multilevelTab$: Observable<MultilevelTab>;

  @select(['data', 'featureToggleSettings'])
  featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @select(['data', 'userProfile'])
  userProfile$: Observable<UserProfile>;

  @select(['data', 'userSecurityData'])
  userSecurityData$: Observable<UserSecurity[]>;

  @Input() fromPdfExport = false;

  @ViewChild(WrvuMultilevelDepartmentPageComponent) wruvDepartmentComponent: WrvuMultilevelDepartmentPageComponent;
  @ViewChild(WrvuMultilevelSpecialtyPageComponent) wruvSpecialtyComponent: WrvuMultilevelSpecialtyPageComponent;
  @ViewChild(WrvuMultilevelProviderPageComponent) wruvProviderComponent: WrvuMultilevelProviderPageComponent;

  specialtyPerformanceSnapshot: ProductivityMultiLevelSnapshot[] = [];
  providerSnapshot: ProductivityMultiLevelSnapshot[] = [];
  specialtySnapshot: ProductivityMultiLevelSnapshot[] = [];
  departmentSnapshot: ProductivityMultiLevelSnapshot[] = [];
  benchmarkPercentile: BenchmarkPercentile;
  multilevelTabChangedTo = multilevelTabChangedTo;
  departmentColumns: DataTableColumns[] = [];
  displayedDepartmentColumns: DataTableColumns[] = [];
  specialtyColumns: DataTableColumns[] = [];
  displayedSpecialtyColumns: DataTableColumns[] = [];
  providerColumns: DataTableColumns[] = [];
  displayedProviderColumns: DataTableColumns[] = [];
  specialtyPerformanceColumns: DataTableColumns[] = specialtyPerformanceColumns;
  displayedSpecialtyPerformanceColumns: DataTableColumns[] = [];
  defaultSortColumn: string;
  showProgressBar: boolean;
  zeroSuppressionCondition = 'a cFTE and Actual wRVU value greater than zero';
  sortDirection = 'desc';
  reducerAction: (displayedWrvuSnapshotColumns: BaseColumn[]) => AppAction = snapshotWrvuColumnsChangedTo;
  reducerActionProvider: (displayedWrvuProviderColumns: BaseColumn[]) => AppAction = providerWrvuColumnsChangedTo;
  reducerActionSpecialtyPerformance: (displayedSpecialtyPerformanceColumns: BaseColumn[]) =>
    AppAction = specialtyPerformanceWrvuColumnsChangedTo;
  zeroSuppression = false;
  countOfSuppressedDeptEntries: number;
  countOfSuppressedSpecEntries: number;
  countOfSuppressedProviderEntries: number;
  countOfSuppressedSpecPerformanceEntries: number;
  viewCommunityBenchmarks: boolean;
  activeVarianceToggle = false;
  varianceKey = '';
  varianceToggleSortingCriterion: SortingCriterion;
  wRVUtype: WrvuViewType;
  selectionCounts: SelectionCounts;
  multiLevelProductivity: ProviderProductivityMultiLevelData;
  dataSubscription: Subscription;
  columnSubscription: Subscription;
  filterSubscription: Subscription;
  nodePath: string;
  MultilevelTab = MultilevelTab;
  chosenTab: MultilevelTab = MultilevelTab.BY_DEPARTMENT;
  private providerColumnSubscription: Subscription;
  private specialtyPerformanceColumnSubscription: Subscription;

  variables: Variable[] = [];
  variablesForActualWrvus: Variable[] = [{
    name: 'Charges',
    display: false,
    reducerField: DisplayField.Charges,
    dispatchAction(display: boolean): AppAction {
      return chargeDisplayChangedTo(display);
    }
  }];
  variablesForCfteAdjWrvus: Variable[] = this.variablesForActualWrvus.concat([{
    name: 'Previous Date Range cFTE adj. wRVUs',
    display: false,
    reducerField: DisplayField.PreviousTimePeriod,
    dispatchAction(display: boolean): AppAction {
      return previousTimePeriodDisplayChanged(display);
    }
  }]);

  tabs: TabNavigationElement[] = [
    _.cloneDeep(DEPARTMENT_TAB),
    _.cloneDeep(SPECIALTY_TAB),
    _.cloneDeep(PROVIDER_TAB),
    _.cloneDeep(SPECIALTY_PERFORMANCE_TAB),
    _.cloneDeep(TREND_TAB)
  ];

  constructor(private ngRedux: NgRedux<IAppState>,
              private cdr: ChangeDetectorRef,
              private location: Location,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {
    this.filterSubscription = this.filterService.getFilters()
      .subscribe((filter: FilterCriteria) => {
        const previousYearFilter = combineFilter(filter, FilterType.PreviousYear);
        if (!!filter.nodePath) {
          this.apiService.getWrvuSnapshotData(filter, previousYearFilter);
          this.apiService.getWrvuSummaryData(filter);
          this.apiService.getWrvuTrendData(filter, previousYearFilter);
        }
      });
  }

  ngOnInit(): void {
      this.dataSubscription = combineLatest([
      this.providerProductivityMultiLevel$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.benchmarkPercentile$,
      this.nodePath$,
      this.wrvuViewType$,
      this.multilevelTab$,
      this.zeroSuppression$,
      this.viewCommunityBenchmarks$,
      this.wrvuSnapshotSortingCriteria$.pipe(distinctUntilChanged((value1, value2) => _.isEqual(value1, value2))),
      this.wrvuVarianceToggle$
    ]).subscribe(
      ([productivityMultiLevel, benchmarkPercentile, nodePath, wrvuViewType, tab, zeroSuppression,
         viewCommunityBenchmarks, wrvuSnapshotSortingCriteria, wrvuVarianceToggle]:
         [ProviderProductivityMultiLevelData, BenchmarkPercentile, string, WrvuViewType, MultilevelTab , boolean, boolean,
             SortingCriterion | undefined, boolean]) => {
        if (this.viewCommunityBenchmarks !== viewCommunityBenchmarks) {
          this.analyticsService.handleGoogleAnalytics('Wrvu Snapshot',
            viewCommunityBenchmarks ? 'Community' : 'Academic', 'Toggling Benchmark');
        }
        this.chosenTab = ontologyTabEquivalentOfLocationTab(tab);
        this.variables = wrvuViewType === WrvuViewType.CfteAdjusted && this.chosenTab !== MultilevelTab.BY_DEPARTMENT
          ? this.variablesForCfteAdjWrvus : this.variablesForActualWrvus;
        this.wRVUtype = wrvuViewType;
        if (!wrvuSnapshotSortingCriteria) {
          this.ngRedux.dispatch(wrvuSnapshotSortingCriteriaChangedTo({
            sortingOrder: SortingOrder.DESCENDING,
            columnDef: 'wRVUs',
          }));
        }
        this.benchmarkPercentile = benchmarkPercentile;
        this.multiLevelProductivity = productivityMultiLevel;
        this.zeroSuppression = zeroSuppression;
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.nodePath = nodePath;
        this.selectionCounts = getSelectionCounts(nodePath);
        this.showProgressBar =
          _.isEqual(productivityMultiLevel, INITIAL_STATE.data.providerProductivityMultilevelData.departmentProductivities);
        this.assignSnapshotsFromMultiLevelProductivityFields();
        this.setUpColumns(viewCommunityBenchmarks);

        if (this.zeroSuppression) {
          this.processAndHideZeroSuppressedEntries();
        } else {
          this.showAllEntries();
        }
        this.calculateDifferenceFields();
        this.adjustSortingVariables(wrvuSnapshotSortingCriteria, wrvuVarianceToggle, wrvuViewType);
        this.assignDataToChildComponents();
      });

    this.columnSubscription = this.displayedWRVUSnapshotColumns$
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(displayedColumns => {
        this.displayedDepartmentColumns =
          updateColumnFilter(displayedColumns, this.departmentColumns, this.benchmarkPercentile, this.viewCommunityBenchmarks);
        this.displayedSpecialtyColumns =
          updateColumnFilter(displayedColumns, this.specialtyColumns, this.benchmarkPercentile, this.viewCommunityBenchmarks);
      });

    this.providerColumnSubscription = this.displayedWRVUProviderColumns$
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(displayedProviderColumns => {
        displayedProviderColumns = displayedProviderColumns.concat(
          this.providerColumns.filter(col => col.columnType === ColumnType.OPEN_WINDOW));
        this.displayedProviderColumns =
          updateColumnFilter(displayedProviderColumns, this.providerColumns, this.benchmarkPercentile, this.viewCommunityBenchmarks);
      });

    this.specialtyPerformanceColumnSubscription = this.displayedWRVUSpecialtyPerformanceColumns$
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(displayedSpecialtyPerformanceColumns => {
        this.displayedSpecialtyPerformanceColumns =
          updateColumnFilter(displayedSpecialtyPerformanceColumns, this.specialtyPerformanceColumns,
            this.benchmarkPercentile, this.viewCommunityBenchmarks);
      });
  }

  private setUpColumns(viewCommunityBenchmarks: boolean) {
    this.departmentColumns = _.cloneDeep(multiLevelWrvuDeptColumns(true));
    this.specialtyColumns = _.cloneDeep(multiLevelWrvuSpecColumns(true));
    this.providerColumns = this.getProviderColumns(viewCommunityBenchmarks);

    [this.departmentColumns, this.specialtyColumns, this.providerColumns, this.specialtyPerformanceColumns].forEach(multiLevelCol => {
      multiLevelCol[0].primaryColumn = true;
      adjustBenchmarkDependentColumns(multiLevelCol, this.benchmarkPercentile, this.viewCommunityBenchmarks);
    });

    this.displayedDepartmentColumns = this.departmentColumns.slice();
    this.displayedSpecialtyColumns = this.specialtyColumns.slice();
    this.displayedProviderColumns = this.providerColumns.slice();
    this.displayedSpecialtyPerformanceColumns = this.specialtyPerformanceColumns.slice();
  }

  private assignSnapshotsFromMultiLevelProductivityFields() {
    this.departmentSnapshot = this.multiLevelProductivity.departmentProductivities;
    this.specialtySnapshot = this.multiLevelProductivity.specialtyProductivities;
    this.providerSnapshot = this.multiLevelProductivity.providerProductivities;
    this.specialtyPerformanceSnapshot = this.multiLevelProductivity.providerProductivities;
  }

  private calculateDifferenceFields() {
    this.specialtyPerformanceSnapshot
      .forEach(datum => datum.difference = datum.cfteAdjustedWRVUs - checkForNulls(datum.previousCfteAdjustedWRVUs));
    this.providerSnapshot
      .forEach(datum => datum.difference = datum.cfteAdjustedWRVUs - checkForNulls(datum.previousCfteAdjustedWRVUs));
    this.specialtySnapshot
      .forEach(datum => datum.difference = datum.cfteAdjustedWRVUs - checkForNulls(datum.previousCfteAdjustedWRVUs));
    this.departmentSnapshot
      .forEach(datum => datum.difference = datum.cfteAdjustedWRVUs - checkForNulls(datum.previousCfteAdjustedWRVUs));
  }

  private assignDataToChildComponents() {
    if (this.wruvProviderComponent) {
      this.wruvProviderComponent.wrvuSnapshotData = this.providerSnapshot;
    }
    if (this.wruvSpecialtyComponent) {
      this.wruvSpecialtyComponent.wrvuSnapshotData = this.specialtySnapshot;
    }
    if (this.wruvDepartmentComponent) {
      this.wruvDepartmentComponent.wrvuSnapshotData = this.departmentSnapshot;
    }
  }

  private processAndHideZeroSuppressedEntries() {
    this.countOfSuppressedDeptEntries = this.suppressZeroesAndReturnCount(this.departmentSnapshot);
    this.departmentSnapshot.forEach(x => x.isHidden = this.meetsZeroSuppressionCriteria(x));
    this.countOfSuppressedSpecEntries = this.suppressZeroesAndReturnCount(this.specialtySnapshot);
    this.specialtySnapshot.forEach(x => x.isHidden = this.meetsZeroSuppressionCriteria(x));
    this.countOfSuppressedProviderEntries = this.suppressZeroesAndReturnCount(this.providerSnapshot);
    this.providerSnapshot.forEach(x => x.isHidden = this.meetsZeroSuppressionCriteria(x));
    this.countOfSuppressedSpecPerformanceEntries = this.suppressZeroesAndReturnCount(this.specialtyPerformanceSnapshot,
      MultilevelTab.SPECIALTY_PERFORMANCE);
    this.specialtyPerformanceSnapshot = this.specialtyPerformanceSnapshot.filter(x =>
      x.cfteAdjustedWRVUs || x.wRVUs || x.cfte || x.imputedReportedcFTEMean || x.charges);
  }

  private showAllEntries() {
    this.departmentSnapshot.forEach(x => {
      x.isHidden = false;
    });
    this.specialtySnapshot.forEach(x => {
      x.isHidden = false;
    });
    this.providerSnapshot.forEach(x => {
      x.isHidden = false;
    });
  }

  private meetsZeroSuppressionCriteria(x: ProductivityMultiLevelSnapshot) {
    // tslint:disable-next-line:triple-equals
    return (!x.cfte || roundToWithCommasSeparation(x.cfte) === '0.00' || roundToWithCommasSeparation(x.cfte) === '-')
      // tslint:disable-next-line:triple-equals
      && (!x.wRVUs || formatNumberToWholeNumber(checkForNulls(x.wRVUs)) == '0'
        // tslint:disable-next-line:triple-equals
        || formatNumberToWholeNumber(checkForNulls(x.wRVUs)) === '-');
  }

  private getProviderColumns(viewCommunityBenchmarks: boolean): DataTableColumns[] {
    const result = [
      providerNodeColumnForWrvu,
      specialtyNodeColumnForWrvu(false),
      departmentNodeColumnForWrvu(false),
      _.cloneDeep(commonWrvuColumns[0]),
      _.cloneDeep(commonWrvuColumns[1])
    ];
    result.push(viewCommunityBenchmarks ? providerPercentileCommunityColumn : providerPercentileColumn);
    result.push(..._.cloneDeep(commonWrvuColumns.slice(2)));
    return result;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.columnSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
    this.providerColumnSubscription?.unsubscribe();
    this.specialtyPerformanceColumnSubscription?.unsubscribe();
  }

  suppressZeroesAndReturnCount(snapshot: ProductivityMultiLevelSnapshot[], tab?: MultilevelTab): number {
    const originalLength = snapshot.length;
    snapshot =
      tab === MultilevelTab.SPECIALTY_PERFORMANCE ?
      snapshot.filter(x => x.cfteAdjustedWRVUs || x.wRVUs || x.cfte || x.imputedReportedcFTEMean || x.charges) :
      snapshot.filter(x => !this.meetsZeroSuppressionCriteria(x));
    return originalLength - snapshot.length;
  }

  updateSortingCriteria = (sortingCriteria: SortingCriterion | undefined) => {
    if (!isSortingCriterionValid(sortingCriteria)) {
      return;
    }
    this.ngRedux.dispatch(wrvuSnapshotSortingCriteriaChangedTo(sortingCriteria));
  };

  adjustSortingVariables(
    wrvuSortingCriteria: SortingCriterion | undefined,
    wrvuVarianceToggle: boolean,
    wrvuViewType: WrvuViewType
  ): void {
    const varianceColumn = this.departmentColumns.find(col => col.columnType === ColumnType.VARIANCE);
    this.varianceKey = varianceColumn ? varianceColumn.columnDef : 'varianceMean';
    this.activeVarianceToggle = wrvuVarianceToggle;
    const wrvuSortColumn = (wrvuViewType === WrvuViewType.CfteAdjusted && this.chosenTab !== MultilevelTab.BY_DEPARTMENT)
        ? 'cfteAdjustedWRVUs' : 'wRVUs';
    if (wrvuSortingCriteria && wrvuSortingCriteria.columnDef && wrvuSortingCriteria.sortingOrder) {
      const requiredSortingCriteria: SortingCriterion = wrvuSortingCriteria.columnType === ColumnType.VARIANCE ? {
        ...wrvuSortingCriteria,
        columnDef: this.varianceKey
      } : {
        ...wrvuSortingCriteria,
        columnDef: wrvuSortColumn
      };
      this.defaultSortColumn = requiredSortingCriteria.columnDef;
      this.sortDirection = requiredSortingCriteria.sortingOrder === SortingOrder.DESCENDING ? 'desc' : 'asc';
    } else {
      this.defaultSortColumn = wrvuSortColumn;
      this.sortDirection = 'desc';
    }
    if (!wrvuVarianceToggle) {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: this.varianceKey,
        columnType: ColumnType.VARIANCE
      };
    } else {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: wrvuSortColumn,
        columnType: ColumnType.WRVU
      };
    }
  }

}
