import {DataTableColumns} from '../../shared/data-table-columns';
import {filterOutCfteAdjWrvuRelatedColumns} from '../../shared/data-table-columns';
import {ExportMetadata} from '../../shared/export/export';
import {ColumnType} from '../../shared/enums';
import {ProductivityMultiLevelSnapshot, ProductivitySummary} from '../../productivity-summary/services/ProviderProductivity';
import {
  BenchmarkPercentilesForWrvu,
  createImputedReportedCfteBenchmarkColumnFor,
  createVarianceBenchmarkColumnFor,
  createWRVUBenchmarkColumnFor,
  createWRVUTrendBenchmarkColumnFor,
  createWRVUTrendVarianceColumnFor
} from '../../shared/BenchmarkColumns';
import {filterOutBenchmarkRelatedColumns} from '../../shared/BenchmarkColumns';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {BaseColumn, SummaryData} from '../../store/IAppState';
import {MergedProductivityTrendEntry} from '../../shared/models';
import {
  populateWrvuSummaryData,
  ProductivitySummaryData
} from '../../productivity-summary/wrvu-summary-data-table/wrvu-summary-data-table.component';
import {BehaviorSubject} from 'rxjs';
import {
  wrvuByDepartmentExcelData$,
  wrvuByProviderExcelData$,
  wrvuBySpecialtyExcelData$,
  wrvuBySpecialtyPerformanceExcelData$,
  wrvuByTrendExcelData$
} from '../export-subscribers';
import {toTitleCase} from '../../shared/helpers';

function insertAllBenchmarkDependantColumnsForWrvuTrend(
  benchmarkPercentiles: BenchmarkPercentile[],
  columns: DataTableColumns[],
  viewCommunityBenchmarks: boolean): DataTableColumns[] {
  const benchmarkColumns: DataTableColumns[] = [];
  const varianceColumns: DataTableColumns[] = [];
  benchmarkPercentiles.forEach(bcm => {
    benchmarkColumns.push(createWRVUTrendBenchmarkColumnFor(bcm, viewCommunityBenchmarks));
    varianceColumns.push(createWRVUTrendVarianceColumnFor(bcm, viewCommunityBenchmarks));
  });
  const hasBenchmark = columns.find(col => col.columnType === ColumnType.BENCHMARK);
  if (hasBenchmark) {
    columns = columns.filter(col => col.columnType !== ColumnType.BENCHMARK);
    benchmarkColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasVariance = columns.find(col => col.columnType === ColumnType.VARIANCE);
  if (hasVariance) {
    columns = columns.filter(col => col.columnType !== ColumnType.VARIANCE);
    varianceColumns.forEach(varCol => {
      columns.push(varCol);
    });
  }
  return columns;
}

function insertAllBenchmarkDependantColumnsForWrvuSnapshot(
  benchmarkPercentiles: BenchmarkPercentile[],
  columns: DataTableColumns[],
  viewCommunityBenchmarks: boolean): DataTableColumns[] {
  const benchmarkColumns: DataTableColumns[] = [];
  const varianceColumns: DataTableColumns[] = [];
  const imputedColumns: DataTableColumns[] = [];
  benchmarkPercentiles.forEach(bcm => {
    benchmarkColumns.push(createWRVUBenchmarkColumnFor(bcm, viewCommunityBenchmarks));
    varianceColumns.push(createVarianceBenchmarkColumnFor(bcm, viewCommunityBenchmarks));
    imputedColumns.push(createImputedReportedCfteBenchmarkColumnFor(bcm, viewCommunityBenchmarks));
  });
  const hasBenchmark = columns.find(col => col.columnType === ColumnType.BENCHMARK);
  if (hasBenchmark) {
    columns = columns.filter(col => col.columnType !== ColumnType.BENCHMARK);
    benchmarkColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasVariance = columns.find(col => col.columnType === ColumnType.VARIANCE);
  if (hasVariance) {
    columns = columns.filter(col => col.columnType !== ColumnType.VARIANCE);
    varianceColumns.forEach(varCol => {
      columns.push(varCol);
    });
  }
  const hasImputed = columns.find(col => col.columnType === ColumnType.IMPUTED);
  if (hasImputed) {
    columns = columns.filter(col => col.columnType !== ColumnType.IMPUTED);
    imputedColumns.forEach(impCol => {
      columns.push(impCol);
    });
  }
  return columns;
}

export async function generateExcelWrvuTrendDataForDisplayedColumns(
  summaryData: SummaryData<ProductivitySummary>, trendData: MergedProductivityTrendEntry[], displayedDetailColumns: BaseColumn[],
  detailColumns: DataTableColumns[], summaryColumns: DataTableColumns[], viewCommunityBenchmarks: boolean) {
  const summary = populateWrvuSummaryData(viewCommunityBenchmarks, BenchmarkPercentile.All, summaryData, true,
    true);
  detailColumns = detailColumns.filter(col => col.primaryColumn || displayedDetailColumns.find(c =>
    c.columnDef === col.columnDef || (c.columnType === col.columnType && c.columnType !== undefined))).slice();
  detailColumns = insertAllBenchmarkDependantColumnsForWrvuTrend(BenchmarkPercentilesForWrvu, detailColumns, viewCommunityBenchmarks);
  const copyRight = 'Clinical Practice Solutions Center Productivity Summary ' + (new Date().getFullYear() - 1) + ';';
  wrvuByTrendExcelData$.next({
    summaryHeaders: ['Time Range'].concat(summary.map(s => s.metric)),
    summaryData: summaryColumns.splice(1).map(col => {
      return [col.header].concat(generateRowForWrvuSummary(col, summary));
    }),
    detailHeaders: detailColumns.map(c => c.header),
    detailData: trendData.map(wrvu => {
      return generateRowForWrvuTrend(wrvu, detailColumns);
    }),
    fileName: ('Wrvu Trend'),
    page: 'Wrvu trend',
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true
    },
    sheetName: 'WrvuTrend'
  });
}

export async function generateExcelWrvuSnapshotDataForDisplayedColumns(
  summaryData: SummaryData<ProductivitySummary>,
  providerData: ProductivityMultiLevelSnapshot[],
  displayedDetailColumns: BaseColumn[],
  detailColumns: DataTableColumns[],
  summaryColumns: DataTableColumns[],
  viewCommunityBenchmarks: boolean,
  level: string,
  showBenchmarks: boolean,
  shouldShowWrvuDepartmentCfteAdjusted: boolean
) {
  const summary = populateWrvuSummaryData(viewCommunityBenchmarks, BenchmarkPercentile.All, summaryData, showBenchmarks,
    shouldShowWrvuDepartmentCfteAdjusted);
  detailColumns = detailColumns.filter(col => col.primaryColumn || displayedDetailColumns.find(c =>
    c.columnDef === col.columnDef || (c.columnType === col.columnType && c.columnType !== undefined))).slice();
  detailColumns = showBenchmarks
    ? insertAllBenchmarkDependantColumnsForWrvuSnapshot(BenchmarkPercentilesForWrvu, detailColumns, viewCommunityBenchmarks)
    : filterOutBenchmarkRelatedColumns(detailColumns);
  detailColumns = shouldShowWrvuDepartmentCfteAdjusted ? detailColumns : filterOutCfteAdjWrvuRelatedColumns(detailColumns);
  const copyRight = 'Clinical Practice Solutions Center Productivity Summary ' + (new Date().getFullYear() - 1) + ';';
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (level) {
    case 'department':
      designatedObservable = wrvuByDepartmentExcelData$;
      break;
    case 'specialty':
      designatedObservable = wrvuBySpecialtyExcelData$;
      break;
    case 'provider':
      designatedObservable = wrvuByProviderExcelData$;
      break;
    case 'specialty performance':
      designatedObservable = wrvuBySpecialtyPerformanceExcelData$;
  }

  if (designatedObservable) {
    designatedObservable.next({
      summaryHeaders: ['Time Range'].concat(summary.map(s => s.metric)),
      summaryData: summaryColumns.splice(1).map(col => {
        return [col.header].concat(generateRowForWrvuSummary(col, summary));
      }),
      detailHeaders: ['By ' + detailColumns[0].header].concat(detailColumns.slice(1).map(c => c.header)),
      detailData: providerData.map(wrvu => generateRowForWrvuSnapshot(wrvu, detailColumns)),
      fileName: ('Wrvu by ' + level),
      page: 'Wrvu by ' + level,
      title: copyRight,
      copyright: copyRight,
      isBlankRowAfterSummary: true,
      whatFilters: {
        showBreadcrumb: true,
        showDateRange: true,
        showLocation: true
      },
      sheetName: toTitleCase('Wrvu By ' + level)
    });
  }
}

function generateRowForWrvuSummary(column: DataTableColumns, summary: ProductivitySummaryData[]): string[] {
  const row: string[] = [];
  summary.forEach(entry => {
    row.push(column.dataName(entry));
  });
  return row;
}

function generateRowForWrvuTrend(entry: MergedProductivityTrendEntry, displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    row.push(col.dataName(entry));
  });
  return row;
}

function generateRowForWrvuSnapshot(providerEntry: ProductivityMultiLevelSnapshot,
                                    displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    row.push(col.dataName(providerEntry));
  });
  return row;
}
