<button type="button" [attr.aria-label]="_intl.openCalendarLabel"
        [disabled]="disabled" (click)="_open($event)" id="datepicker-toggle-button" [hidden]="filterBannerFlag">

  <svg
    *ngIf="!_customIcon"
    class="mat-datepicker-toggle-default-icon"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    fill="currentColor"
    focusable="false">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/>
  </svg>

  <ng-content select="[matDatepickerToggleIcon]"></ng-content>
</button>
