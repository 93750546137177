import {Component, Input, OnChanges} from '@angular/core';
import {SummaryData} from '../../../store/IAppState';
import {DateRange, NewPatientVisitSummary} from '../../../shared/models';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {hasValue} from '../../../shared/null-helpers';
import {MatTableDataSource} from '@angular/material/table';
import {roundToWithCommasSeparation, roundToWithPercentageOrElseNull} from '../../../productivity-summary/number-formatter';
import {getDateRangeTextFromKey, getHeaderFromKey} from '../../batch-export-helper';
import {getBenchmarkTitle, getSummaryBenchmarkFieldForBenchmarkCombination} from '../../../new-patient-visits/components/npv-helpers';
import {BenchmarkOption} from '../../../shared/enums';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';

@Component({
  selector: 'app-npv-summary-batch-pdf',
  templateUrl: './npv-summary-batch-pdf.component.html',
  styleUrls: ['./npv-summary-batch-pdf.component.scss']
})
export class NpvSummaryBatchPdfComponent implements OnChanges {

  @Input() summaryData: SummaryData<NewPatientVisitSummary>;
  @Input() dateRange: DateRange;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  dataSource: MatTableDataSource<BatchNpvSummaryColumn>;
  columns: DataTableColumns[] = [];
  displayedColumns: string[] = [];

  constructor() { }

  ngOnChanges(): void {
    if (hasValue(this.summaryData)) {
      const keys = Object.keys(this.summaryData);
      const benchmarkField =
        getSummaryBenchmarkFieldForBenchmarkCombination(this.benchmarkPercentile, this.benchmarkOption, true);
      const benchmarkTitle = getBenchmarkTitle(this.benchmarkOption, this.benchmarkPercentile, true);
      this.dataSource = new MatTableDataSource<BatchNpvSummaryColumn>([
        {field: 'newPatientVisitsPercentage', percent: true, metric: '% New Patient Visits', benchmark: false},
        {field: benchmarkField, percent: true, metric: benchmarkTitle, benchmark: true},
        {field: 'countOfNewPatientVisits', percent: false, metric: '# of New Patients', benchmark: false},
        {field: 'countOfTotalPatientVisits', percent: false, metric: '# of Total Patients', benchmark: false}].slice());
      this.columns = ['Metric'].concat(keys).map(key => {
        return {
          columnDef: key, header: getHeaderFromKey(key), upperHeader: getDateRangeTextFromKey(key, this.dateRange, this.summaryData),
          dataName: (entry: BatchNpvSummaryColumn) => key !== 'Metric' ? `${entry.percent ? roundToWithPercentageOrElseNull(
            (entry.benchmark ? 100 : 1) * (this.summaryData[key][entry.field] || 0), 1) :
            roundToWithCommasSeparation(this.summaryData[key][entry.field], 1)}` : entry.metric,
        };
      });
      this.displayedColumns = this.columns.map(x => x.columnDef);
    }
  }
}

interface BatchNpvSummaryColumn {
  field: string; percent: boolean; metric: string; benchmark: boolean;
}
