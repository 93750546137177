import {
  CollectionsByMultiLevelByNodePathGroupedByLevel,
  CollectionsByPayerGroupedByLevel,
  CollectionsData,
  CollectionsSummaryForAllTypes,
  MatchedCollectionsByMultiLevelByNodePath,
  MatchedCollectionsMultiLevelNodePathData,
  PayerCollections,
  PayerCollectionsData,
  PayerMatchedCollectionsMultiLevelNodePathData,
  SnapshotMatchedCollections
} from '../../collections/Collection';
import {BaseColumn, SummaryData} from '../../store/IAppState';
import {ColumnType, MultilevelTabCollections} from '../enums';
import {formatNumberToWholeNumber, roundTo, roundToWithPercentage} from '../../productivity-summary/number-formatter';
import {isValid} from '../null-helpers';
import {Export, ExportMetadata} from './export';
import {tableCellsForCollectionsSummary} from '../data-table-columns';
import {toTitleCase} from '../helpers';
import {isArray} from 'rxjs/internal-compatibility';
import {getFilterInfo} from './export-helper';
import {
  CollectionsMultilevelComponent
} from '../../collections/collections-multilevel/collections-multilevel.component';
import {
  PayerCollectionsMultilevelComponent
} from '../../collections/payer-collections/payer-collections-multilevel/payer-collections-multilevel.component';

export function getCollectionsData(collections: any,
                                   displayedColumns: BaseColumn[],
                                   currentCollectionsLevel: MultilevelTabCollections,
                                   suppressZeroes?: boolean): string[][] {
  const result: string[][] = [];

  if (collections.hasOwnProperty('snapshotMatchedCollections')) {
    collections.snapshotMatchedCollections.forEach((x: any) => {
      if (!suppressZeroes || !CollectionsMultilevelComponent.isCollectionEntrySuppressible(x)) {
        result.push(getRowForCollectionsMultiLevelExport(x, displayedColumns));
      }
    });
  } else {
    switch (currentCollectionsLevel) {
      case MultilevelTabCollections.BY_DEPARTMENT:
        collections.departmentCollections.forEach((x: any) => {
          if (!suppressZeroes || !CollectionsMultilevelComponent.isCollectionEntrySuppressible(x)) {
            result.push(getRowForCollectionsMultiLevelExport(x, displayedColumns));
          }
        });
        break;
      case MultilevelTabCollections.BY_SPECIALTY:
        collections.specialtyCollections.forEach((x: any) => {
          if (!suppressZeroes || !CollectionsMultilevelComponent.isCollectionEntrySuppressible(x)) {
            result.push(getRowForCollectionsMultiLevelExport(x, displayedColumns));
          }
        });
        break;
      case MultilevelTabCollections.BY_PROVIDER:
        collections.providerCollections.forEach((x: any) => {
          if (!suppressZeroes || !CollectionsMultilevelComponent.isCollectionEntrySuppressible(x)) {
            result.push(getRowForCollectionsMultiLevelExport(x, displayedColumns));
          }
        });
        break;
      default:
        collections.departmentCollections.forEach((x: any) => {
            if (!suppressZeroes || !CollectionsMultilevelComponent.isCollectionEntrySuppressible(x)) {
              result.push(getRowForCollectionsMultiLevelExport(x, displayedColumns));
            }
          }
        );
        break;
    }

  }
  return result;
}

export function getPayersCollectionsByLevel(collections: any,
                                            displayedColumns: BaseColumn[],
                                            currentCollectionsLevel: MultilevelTabCollections): string[][] {

  const result: string[][] = [];

  if (collections.hasOwnProperty('nodeName')) {
    collections.forEach((x: any) => {
      result.push(getRowForPayerCollectionsLevelExport(x, displayedColumns));
    });
  } else {

    switch (currentCollectionsLevel) {
      case MultilevelTabCollections.BY_DEPARTMENT:
        collections.departmentCollections?.forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
      case MultilevelTabCollections.BY_SPECIALTY:
        collections.specialtyCollections?.forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
      case MultilevelTabCollections.BY_PROVIDER:
        collections.providerCollections?.forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
      default:
        collections.departmentCollections?.forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
    }
  }
  return result;
}

export function getPayerCollectionsData(payerCollections: PayerCollectionsData,
                                        displayedColumns: BaseColumn[]): string[][] {
  const result: string[][] = [];
  payerCollections.payerMatchedCollections.forEach(x => {
    result.push(getRowForPayerCollectionsExport(x, displayedColumns));
  });
  return result;
}

export function getPayerCollectionsDataByLevel(payerCollections: any, suppressZeroes: boolean,
                                               displayedColumns: BaseColumn[],
                                               currentCollectionsLevel: MultilevelTabCollections): string[][] {
  const result: string[][] = [];
  if (isArray(payerCollections)) {
    payerCollections.forEach((x: any) => {
      result.push(getRowForPayerCollectionsLevelExport(x, displayedColumns));
    });
  } else {
    switch (currentCollectionsLevel) {
      case MultilevelTabCollections.BY_DEPARTMENT:
        payerCollections.departmentCollections.filter((c: any) => !suppressZeroes ||
          !PayerCollectionsMultilevelComponent.isAPayerByMultilevelZeroSuppressible(c)).forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
      case MultilevelTabCollections.BY_SPECIALTY:
        payerCollections.specialtyCollections.filter((c: any) => !suppressZeroes ||
          !PayerCollectionsMultilevelComponent.isAPayerByMultilevelZeroSuppressible(c)).forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
      case MultilevelTabCollections.BY_PROVIDER:
        payerCollections.providerCollections.filter((c: any) => !suppressZeroes ||
          !PayerCollectionsMultilevelComponent.isAPayerByMultilevelZeroSuppressible(c)).forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
      default:
        payerCollections.departmentCollections.filter((c: any) => !suppressZeroes ||
          !PayerCollectionsMultilevelComponent.isAPayerByMultilevelZeroSuppressible(c)).forEach((x: any) => {
          result.push(getRowForByMultilevelByPayerCollectionsLevelExport(x, displayedColumns, currentCollectionsLevel));
        });
        break;
    }

  }
  return result;
}

export function getSummaryCollectionsDataForCsv(summaryCollections: SummaryData<CollectionsSummaryForAllTypes>)
  : string[][] {
  const result: string[][] = [];
  result.push(['', '', '', '', '']);
  tableCellsForCollectionsSummary.forEach(x => {
    result.push(getRowForSummaryCollectionsExportToCsv(summaryCollections, x.label, x.field));
  });
  return result;
}

export function getSummaryCollectionsData(summaryCollections: SummaryData<CollectionsSummaryForAllTypes>): string[][] {
  const result: string[][] = [];
  result.push(['', '', '', '', '']);
  tableCellsForCollectionsSummary.forEach(x => {
    result.push(getRowForSummaryCollectionsData(x.label, x.field, summaryCollections));
  });
  return result;
}

export function getRowForSummaryCollectionsData(label: string, field: string,
                                                summaryCollections: SummaryData<CollectionsSummaryForAllTypes>)
  : string[] {
  return [
    label,
    isValid(summaryCollections.selectedDateRange, field) ? '$' +
      formatNumberToWholeNumber(summaryCollections.selectedDateRange[field])
      : '-',
    isValid(summaryCollections.previousYearSelectedDateRange, field) ?
      '$' + formatNumberToWholeNumber(summaryCollections.previousYearSelectedDateRange[field]) : '-',
    isValid(summaryCollections.yearToDate, field) ? '$' +
      formatNumberToWholeNumber(summaryCollections.yearToDate[field]) : '-',
    isValid(summaryCollections.previousYearToDate, field) ? '$' +
      formatNumberToWholeNumber(summaryCollections.previousYearToDate[field]) : '-'
  ];
}

export function getRowForCollectionsExport(collectionsData: SnapshotMatchedCollections,
                                           displayedColumns: BaseColumn[]): string[] {

  const data: any[] = [];
  displayedColumns.forEach(x => {
    // tslint:disable-next-line:max-line-length
    if ((x.columnType === ColumnType.BENCHMARK || x.columnType === ColumnType.VARIANCE || x.columnDef === 'netCollectionRate')
    ) {
      data.push(Number(collectionsData[x.columnDef]) + '%');
    } else if (x.columnDef === 'nodeName') {
      data.push(collectionsData[x.columnDef] + '');
    } else if (x.columnDef === 'outstandingARAsPctOfCharges') {
      data.push(collectionsData[x.columnDef] + ' %');
    } else {
      data.push('$' + roundTo(Number(collectionsData[x.columnDef]), 0) + '');
    }
  });
  return data;
}

export function getRowForCollectionsMultiLevelExport(collectionsData: MatchedCollectionsByMultiLevelByNodePath,
                                                     displayedColumns: BaseColumn[]): string[] {

  const data: any[] = [];
  displayedColumns.forEach(x => {
    // tslint:disable-next-line:max-line-length
    if ((x.columnType === ColumnType.BENCHMARK || x.columnType === ColumnType.VARIANCE || x.columnDef === 'netCollectionRate')
    ) {
      data.push(Number(collectionsData[x.columnDef]) + '%');
    } else if (x.columnDef === 'departmentNodeName'
      || x.columnDef === 'specialtyNodeName'
      || x.columnDef === 'providerNodeName') {
      data.push(collectionsData[x.columnDef] + '');
    } else if (x.columnDef === 'outstandingARAsPctOfCharges') {
      data.push(collectionsData[x.columnDef] + ' %');
    } else {
      data.push('$' + roundTo(Number(collectionsData[x.columnDef]), 0) + '');
    }
  });
  return data;
}

export function getRowForPayerCollectionsExport(payerCollectionsData: PayerCollections,
                                                displayedColumns: BaseColumn[]): string[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
    // @ts-ignore
    if ((x.columnType === ColumnType.BENCHMARK || x.columnType === ColumnType.VARIANCE || x.columnDef === 'netCollectionRate'
      || x.columnDef === 'outstandingARAsPctOfCharges')
    ) {
      data.push(Number(payerCollectionsData[x.columnDef]) + '%');
    } else if (x.columnDef === 'payerCategoryDescription') {
      data.push(payerCollectionsData['payerCategoryDescription'] + '');
    } else {
      data.push('$' + roundTo(Number(payerCollectionsData[x.columnDef]), 0) + '');
    }
  });
  return data;
}

export function getRowForPayerCollectionsLevelExport(payerCollectionsData: CollectionsByPayerGroupedByLevel,
                                                     displayedColumns: BaseColumn[]): string[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
    // @ts-ignore

    if (!payerCollectionsData[x.columnDef]) {
      data.push('-');
    } else if ((x.columnDef === 'netCollectionRate'
      || x.columnDef.includes('variance')
      || x.columnDef.includes('benchmark'))
    ) {
      data.push(roundToWithPercentage(Number(payerCollectionsData[x.columnDef]), 2));
    } else if (x.columnDef === 'nodeName') {
      data.push(payerCollectionsData['nodeName'] + '');
    } else if (x.columnType === ColumnType.PAYER) {
      data.push(payerCollectionsData[x.columnDef]);
    } else {
      data.push('$' + roundTo(Number(payerCollectionsData[x.columnDef]), 0) + '');
    }
  });
  return data;
}

export function getRowForByMultilevelByPayerCollectionsLevelExport(payerCollectionsData: CollectionsByMultiLevelByNodePathGroupedByLevel,
                                                                   displayedColumns: BaseColumn[],
                                                                   collectionsLevel: MultilevelTabCollections): string[] {
  const data: any[] = [];

  displayedColumns.forEach(x => {
    // @ts-ignore
    switch (collectionsLevel) {
      case MultilevelTabCollections.BY_DEPARTMENT:
        if
        (!payerCollectionsData[x.columnDef]) {
          data.push('-');
        } else if ((x.columnDef === 'netCollectionRate'
          || x.columnDef.includes('variance')
          || x.columnDef.includes('benchmark'))
        ) {
          data.push(roundToWithPercentage(Number(payerCollectionsData[x.columnDef]), 2));
        } else if (x.columnDef === 'departmentNodeName') {
          data.push(payerCollectionsData['departmentNodeName'] + '');
        } else if (x.columnType === ColumnType.PAYER) {
          data.push(payerCollectionsData[x.columnDef]);
        } else {
          data.push('$' + roundTo(Number(payerCollectionsData[x.columnDef]), 0) + '');
        }
        break;
      case MultilevelTabCollections.BY_SPECIALTY:
        if (!payerCollectionsData[x.columnDef]) {
          data.push('-');
        } else if ((x.columnDef === 'netCollectionRate'
          || x.columnDef.includes('variance')
          || x.columnDef.includes('benchmark'))
        ) {
          data.push(roundToWithPercentage(Number(payerCollectionsData[x.columnDef]), 2));
        } else if (x.columnDef === 'specialtyNodeName') {
          data.push(payerCollectionsData['specialtyNodeName'] + '');
        } else if (x.columnDef === 'departmentNodeName') {
          data.push(payerCollectionsData['departmentNodeName'] + '');
        } else if (x.columnType === ColumnType.PAYER) {
          data.push(payerCollectionsData[x.columnDef]);
        } else {
          data.push('$' + roundTo(Number(payerCollectionsData[x.columnDef]), 0) + '');
        }
        break;
      case MultilevelTabCollections.BY_PROVIDER:
        if (!payerCollectionsData[x.columnDef]) {
          data.push('-');
        } else if ((x.columnDef === 'netCollectionRate'
          || x.columnDef.includes('variance')
          || x.columnDef.includes('benchmark'))
        ) {
          data.push(roundToWithPercentage(Number(payerCollectionsData[x.columnDef]), 2));
        } else if (x.columnDef === 'providerNodeName') {
          data.push(payerCollectionsData['providerNodeName'] + '');
        } else if (x.columnDef === 'specialtyNodeName') {
          data.push(payerCollectionsData['specialtyNodeName'] + '');
        } else if (x.columnDef === 'departmentNodeName') {
          data.push(payerCollectionsData['departmentNodeName'] + '');
        } else if (x.columnType === ColumnType.PAYER) {
          data.push(payerCollectionsData[x.columnDef]);
        } else {
          data.push('$' + roundTo(Number(payerCollectionsData[x.columnDef]), 0) + '');
        }
        break;
      default:
        if
        (!payerCollectionsData[x.columnDef]) {
          data.push('-');
        } else if ((x.columnDef === 'netCollectionRate'
          || x.columnDef.includes('variance')
          || x.columnDef.includes('benchmark'))
        ) {
          data.push(roundToWithPercentage(Number(payerCollectionsData[x.columnDef]), 2));
        } else if (x.columnDef === 'departmentNodeName') {
          data.push(payerCollectionsData['departmentNodeName'] + '');
        } else if (x.columnType === ColumnType.PAYER) {
          data.push(payerCollectionsData[x.columnDef]);
        } else {
          data.push('$' + roundTo(Number(payerCollectionsData[x.columnDef]), 0) + '');
        }
    }
  });
  return data;
}

export function getRowForSummaryCollectionsExportToCsv(summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>,
                                                       label: string, field: string): string[] {
  return [
    label,
    isValid(summaryCollectionsData.selectedDateRange, field) ? '$' +
      formatNumberToWholeNumber(summaryCollectionsData.selectedDateRange[field]) : '-',
    isValid(summaryCollectionsData.previousYearSelectedDateRange, field) ? '$' +
      formatNumberToWholeNumber(summaryCollectionsData.previousYearSelectedDateRange[field]) : '-',
    isValid(summaryCollectionsData.yearToDate, field) ? '$' + formatNumberToWholeNumber(
      summaryCollectionsData.yearToDate[field]) : '-',
    isValid(summaryCollectionsData.previousYearToDate, field) ? '$' + formatNumberToWholeNumber(
      summaryCollectionsData.previousYearToDate[field]) : '-'
  ];
}

export function getCollectionsExcelData(collectionsData: CollectionsData | MatchedCollectionsMultiLevelNodePathData,
                                        displayedColumns: BaseColumn[],
                                        copyRight: string,
                                        level: string,
                                        nodepath: string,
                                        currentCollectionsLevel: MultilevelTabCollections,
                                        dateRange: string,
                                        memberLocationName: string,
                                        lagPeriod: string,
                                        memberBillingArea: string,
                                        invoiceType: string,
                                        payerCategory: string,
                                        suppressZeroes?: boolean
): ExportMetadata {


  let headers: any[];

  switch (currentCollectionsLevel) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      headers = [toTitleCase('Department')];
      break;
    case MultilevelTabCollections.BY_SPECIALTY:
      headers = [toTitleCase('Specialty')
      ];
      break;
    case MultilevelTabCollections.BY_PROVIDER:
      headers = [toTitleCase('Provider')
      ];
      break;
    default:
      headers = [toTitleCase('Department')];
      break;
  }

  const benchmarkHeaders = ['Benchmark 25th Percentile', 'Benchmark 50th Percentile',
    'Benchmark 75th Percentile'];
  const varianceHeaders = ['Variance 25th Percentile', 'Variance 50th Percentile',
    'Variance 75th Percentile'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmark25th',
      header: 'Benchmark 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark50th',
      header: 'Benchmark 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark75th',
      header: 'Benchmark 75th Percentile',
      columnType: ColumnType.BENCHMARK
    }
  ];
  const varianceData: BaseColumn[] = [
    {
      columnDef: 'variance25th',
      header: 'Variance 25th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance50th',
      header: 'Variance 50th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance75th',
      header: 'Variance 75th Percentile',
      columnType: ColumnType.VARIANCE
    }
  ];

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));


  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  return {
    copyright: copyRight,
    summaryHeaders: headers,
    page: 'Collections',
    fileName: 'collections',
    summaryData: getCollectionsData(collectionsData, remainingColumns, currentCollectionsLevel, suppressZeroes),
    filterInfo: getFilterInfo(nodepath, dateRange, memberLocationName,
      lagPeriod, memberBillingArea, invoiceType, payerCategory),
    isBlankRowAfterSummary: true
  };
}

export function getPayerCollectionsExcelData(payerCollectionsData: PayerCollectionsData,
                                             displayedColumns: BaseColumn[],
                                             copyRight: string,
                                             nodepath?: string,
                                             dateRange?: any,
                                             memberLocationName?: any,
                                             lagPeriod?: any,
                                             memberBillingArea?: any,
                                             invoiceType?: any,
                                             payerCategory?: any): ExportMetadata {

  let headers = [payerCategory];
  const benchmarkHeaders = ['Benchmark 25th Percentile', 'Benchmark 50th Percentile',
    'Benchmark 75th Percentile'];
  const varianceHeaders = ['Variance 25th Percentile', 'Variance 50th Percentile',
    'Variance 75th Percentile'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmark25th',
      header: 'Benchmark 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark50th',
      header: 'Benchmark 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark75th',
      header: 'Benchmark 75th Percentile',
      columnType: ColumnType.BENCHMARK
    }
  ];
  const varianceData: BaseColumn[] = [
    {
      columnDef: 'variance25th',
      header: 'Variance 25th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance50th',
      header: 'Variance 50th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance75th',
      header: 'Variance 75th Percentile',
      columnType: ColumnType.VARIANCE
    }
  ];

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));


  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  return {
    copyright: copyRight,
    summaryHeaders: headers,
    page: 'Collections',
    fileName: 'collections',
    summaryData: getPayerCollectionsData(payerCollectionsData, remainingColumns),
    filterInfo: getFilterInfo(nodepath, dateRange, memberLocationName,
      lagPeriod, memberBillingArea, invoiceType, payerCategory),
    isBlankRowAfterSummary: true
  };
}

export function getPayerByLevelCollectionsExcelData(payerCollectionsData: CollectionsByPayerGroupedByLevel[] |
                                                      PayerMatchedCollectionsMultiLevelNodePathData,
                                                    displayedColumns: BaseColumn[],
                                                    copyRight: string,
                                                    level: string,
                                                    multiLevelLabel: MultilevelTabCollections,
                                                    suppressZeroes: boolean,
                                                    nodepath?: string,
                                                    dateRange?: any,
                                                    memberLocationName?: any,
                                                    lagPeriod?: any,
                                                    memberBillingArea?: any,
                                                    invoiceType?: any,
                                                    payerCategory?: any): ExportMetadata {


  let headers: any[];

  switch (multiLevelLabel) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      headers = [toTitleCase('Department')];
      break;
    case MultilevelTabCollections.BY_SPECIALTY:
      headers = [toTitleCase('Specialty')
      ];
      break;
    case MultilevelTabCollections.BY_PROVIDER:
      headers = [toTitleCase('Provider')
      ];
      break;
    default:
      headers = [toTitleCase('Department')];
      break;
  }
  const varianceHeaders = ['Variance 25th Percentile', 'Variance 50th Percentile',
    'Variance 75th Percentile'];

  const benchmarkHeaders = ['Benchmark 25th Percentile', 'Benchmark 50th Percentile',
    'Benchmark 75th Percentile'];

  const varianceData: BaseColumn[] = [
    {
      columnDef: 'variance25th',
      header: 'Variance 25th Percentile'
    },
    {
      columnDef: 'variance50th',
      header: 'Variance 50th Percentile'
    },
    {
      columnDef: 'variance75th',
      header: 'Variance 75th Percentile'
    }
  ];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmark25th',
      header: 'Benchmark 25th Percentile'
    },
    {
      columnDef: 'benchmark50th',
      header: 'Benchmark 50th Percentile'
    },
    {
      columnDef: 'benchmark75th',
      header: 'Benchmark 75th Percentile'
    }
  ];

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);


  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));


  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }
  return {
    copyright: copyRight,
    summaryHeaders: headers,
    page: 'Collections',
    fileName: 'collections',
    summaryData: getPayerCollectionsDataByLevel(payerCollectionsData, suppressZeroes,
      remainingColumns, multiLevelLabel),
    filterInfo: getFilterInfo(nodepath, dateRange, memberLocationName,
      lagPeriod, memberBillingArea, invoiceType, payerCategory),
    isBlankRowAfterSummary: true
  };
}

export function getSummaryCollectionsExcelData(summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>,
                                               copyRight: string,
                                               nodepath?: string,
                                               dateRange?: any,
                                               memberLocationName?: any,
                                               lagPeriod?: any,
                                               memberBillingArea?: any,
                                               invoiceType?: any,
                                               payerCategory?: any): ExportMetadata {

  return {
    copyright: copyRight,
    summaryHeaders: ['Metric', 'Selected Date Range', 'Previous Selected Date Range', 'Year to Date',
      'Previous Year to Date'],
    page: 'Collections',
    fileName: 'collections',
    summaryData: getSummaryCollectionsData(summaryCollectionsData),
    filterInfo: getFilterInfo(nodepath, dateRange, memberLocationName,
      lagPeriod, memberBillingArea, invoiceType, payerCategory),
    isBlankRowAfterSummary: true
  };
}

export function getCollectionsExportData(collections: CollectionsData | MatchedCollectionsMultiLevelNodePathData,
                                         displayedColumns: BaseColumn[],
                                         level: string, title: string,
                                         multiLevelLabel: MultilevelTabCollections,
                                         suppressZeroes?: boolean): Export {
  title = title.replace(',', '');

  let headers: any[];

  switch (multiLevelLabel) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      headers = [toTitleCase('Department')];
      break;
    case MultilevelTabCollections.BY_SPECIALTY:
      headers = [toTitleCase('Specialty')
      ];
      break;
    case MultilevelTabCollections.BY_PROVIDER:
      headers = [toTitleCase('Provider')
      ];
      break;
    default:
      headers = [toTitleCase('Department')];
      break;
  }

  const benchmarkHeaders = ['Benchmark 25th Percentile', 'Benchmark 50th Percentile',
    'Benchmark 75th Percentile'];
  const varianceHeaders = ['Variance 25th Percentile', 'Variance 50th Percentile',
    'Variance 75th Percentile'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmark25th',
      header: 'Benchmark 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark50th',
      header: 'Benchmark 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark75th',
      header: 'Benchmark 75th Percentile',
      columnType: ColumnType.BENCHMARK
    }
  ];

  const varianceData: BaseColumn[] = [
    {
      columnDef: 'variance25th',
      header: 'Variance 25th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance50th',
      header: 'Variance 50th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance75th',
      header: 'Variance 75th Percentile',
      columnType: ColumnType.VARIANCE
    }
  ];


  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));


  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  return {
    data: getCollectionsData(collections, remainingColumns, multiLevelLabel, suppressZeroes),
    headers: headers,
    fileName: toTitleCase(`collections ${level}`),
    page: 'Collections',
    title: title,
    whatFilters: {
      showPayer: true
    }
  };
}

export function getPayerCollectionsByLevelExportData(collections: CollectionsByPayerGroupedByLevel[] |
                                                       MatchedCollectionsMultiLevelNodePathData,
                                                     displayedColumns: BaseColumn[],
                                                     level: string, collectionsTab: MultilevelTabCollections,
                                                     title: string,
                                                     checkboxId: number): Export {
  title = title.replace(',', '');
  let headers: any[];

  switch (collectionsTab) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      headers = [toTitleCase('Department')];
      break;
    case MultilevelTabCollections.BY_SPECIALTY:
      headers = [toTitleCase('Specialty')];
      break;
    case MultilevelTabCollections.BY_PROVIDER:
      headers = [toTitleCase('Provider')];
      break;
    default:
      headers = [toTitleCase('Department')];
      break;
  }

  const benchmarkHeaders = ['Benchmark 25th Percentile', 'Benchmark 50th Percentile',
    'Benchmark 75th Percentile'];
  const varianceHeaders = ['Variance 25th Percentile', 'Variance 50th Percentile',
    'Variance 75th Percentile'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmark25th',
      header: 'Benchmark 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark50th',
      header: 'Benchmark 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark75th',
      header: 'Benchmark 75th Percentile',
      columnType: ColumnType.BENCHMARK
    }
  ];

  const varianceData: BaseColumn[] = [
    {
      columnDef: 'variance25th',
      header: 'Variance 25th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance50th',
      header: 'Variance 50th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance75th',
      header: 'Variance 75th Percentile',
      columnType: ColumnType.VARIANCE
    }
  ];

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));


  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }

  return {
    data: checkboxId === 20 ? getPayerCollectionsDataByLevel(collections, false, remainingColumns, collectionsTab) :
      getPayersCollectionsByLevel(collections, remainingColumns, collectionsTab),
    headers: headers,
    fileName: toTitleCase(`collections by payer ${level}`),
    page: 'Collections',
    title: title,
    whatFilters: {
      showPayer: true
    }
  };
}

export function getPayerCollectionsExportData(payerCollections: PayerCollectionsData,
                                              displayedColumns: BaseColumn[],
                                              title: string): Export {
  title = title.replace(',', '');
  let headers = ['Payer Category'];
  const benchmarkHeaders = ['Benchmark 25th Percentile', 'Benchmark 50th Percentile',
    'Benchmark 75th Percentile'];
  const varianceHeaders = ['Variance 25th Percentile', 'Variance 50th Percentile',
    'Variance 75th Percentile'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'benchmark25th',
      header: 'Benchmark 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark50th',
      header: 'Benchmark 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'benchmark75th',
      header: 'Benchmark 75th Percentile',
      columnType: ColumnType.BENCHMARK
    }
  ];
  const varianceData: BaseColumn[] = [
    {
      columnDef: 'variance25th',
      header: 'Variance 25th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance50th',
      header: 'Variance 50th Percentile',
      columnType: ColumnType.VARIANCE
    },
    {
      columnDef: 'variance75th',
      header: 'Variance 75th Percentile',
      columnType: ColumnType.VARIANCE
    }
  ];

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));


  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);

  }

  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }
  return {
    data: getPayerCollectionsData(payerCollections, remainingColumns),
    headers: headers,
    fileName: toTitleCase(`Collections By Payer Group`),
    page: 'Collections',
    title: title,
    whatFilters: {
      showPayer: true
    }
  };
}

export function getSummaryCollectionsExportCsvData(summaryCollections: SummaryData<CollectionsSummaryForAllTypes>,
                                                   title: string): Export {
  title = title.replace(',', '');
  const headers = ['Metric', 'Selected Date Range', 'Previous Selected Date Range', 'Year to Date',
    'Previous Year to Date'];
  return {
    data: getSummaryCollectionsDataForCsv(summaryCollections),
    headers: headers,
    fileName: toTitleCase(`Collections By Summary`),
    page: 'Collections',
    title: title,
    whatFilters: {
      showPayer: true
    }
  };
}

