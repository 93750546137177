<span class="metric-division">
  <span class="{{isFirstRow ? 'first-row' : 'top-section-wrapper'}}
     {{multiProviderExportPreview.startOfSubsection ? 'first-subsection-option' : 'regular-option'}} general-wrapper">
    <span class="{{multiProviderExportPreview.isMainSection ? 'preview-header' : 'sub-section'}}">{{multiProviderExportPreview.text}}</span>
    <span class="benchmark-wrapper {{boldBenchmarkClass}}" *ngIf="multiProviderExportPreview.benchmarkOption.length && multiProviderExportPreview.benchmarkPercentile.length">
      {{multiProviderExportPreview.benchmarkOption}}, {{multiProviderExportPreview.benchmarkPercentile}}
    </span>
  </span>
  <span class="sub-option-wrapper">
    <span *ngFor="let subOption of multiProviderExportPreview.subOptions" class="sub-option">{{subOption}}</span>
  </span>
</span>
