import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  ExportType,
  getAppConfigValue,
  getFirstNodePathFrom,
  getLevelFromNodePath,
  getSelectedDateRange,
  isFeatureEnabled,
  lagPeriods,
  LevelType,
  removeWhiteSpace
} from '../shared/helpers';
import {MetricType} from '../shared/metric-types';
import {Router} from '@angular/router';
import {
  clinicalFingerPrintRoute,
  collectionsDepartmentPageRoute,
  collectionsProviderPageRoute,
  collectionsRoute,
  collectionsSnapshotRoute,
  collectionsSpecialtyPageRoute,
  denialsDepartmentPageRoute,
  denialsPayerPageRoute,
  denialsProviderPageRoute,
  denialsRoute,
  denialsSpecialtyPageRoute,
  emEmergencyMedicineDepartmentRoute,
  emEmergencyMedicineDepProviderRoute,
  emEmergencyMedicineDepSpecialtyRoute,
  emEmergencyMedicineRoute,
  emInpatientDepartmentRoute,
  emInpatientDepProviderRoute,
  emInpatientDepSpecialtyRoute,
  emInpatientRoute,
  emOphthalmologyDepartmentRoute,
  emOphthalmologyDepProviderRoute,
  emOphthalmologyDepSpecialtyRoute,
  emOphthalmologyRoute,
  emOutpatientDepartmentRoute,
  emOutpatientDepProviderRoute,
  emOutpatientDepSpecialtyRoute,
  emOutpatientRoute,
  emPageRoute,
  npvDepartmentPageRoute,
  npvMultiLevelTrendPageRoute,
  npvProviderPageRoute,
  npvRoute,
  npvSnapshotPageRoute,
  npvSpecialtyPageRoute,
  npvTrendPageRoute,
  overviewPageRoute,
  payerCollectionsMultiLevelRoute,
  payerCollectionsRoute,
  procedureSummaryRoute,
  summaryCollectionsRoute,
  wRVUDepartmentPageRoute,
  wRVUMultiLevelTrendPageRoute,
  wRVUProviderPageRoute,
  wRVUSnapshotPageRoute,
  wRVUSpecialtyPageRoute,
  wRVUsRoute,
  wRVUTrendPageRoute
} from '../shared/routes';
import {NgRedux, select} from '@angular-redux/store';
import {
  BaseColumn,
  CurrentSelections,
  Data,
  FilterCriteria,
  IAppState,
  INITIAL_STATE,
  SummaryData
} from '../store/IAppState';
import {
  calculateBreadcrumbs,
  composeCurrentLocationFiltersForCsvExport,
  composeCurrentLocationFiltersForExcelExport,
  findOptionWithValue,
  getCFCptCodeData,
  getCFCptCodeExportData,
  getCFCptFamilyData,
  getCFCptFamilyExportData,
  getCFCptRangeData,
  getCFCptRangeExportData,
  getCptGroupBreadcrumb,
  getCSVDataForProcedureSummary,
  getEmEmergencyByLevelExportDataWithExtendedBenchmarkOptions,
  getEmEyeExamByLevelExportDataWithExtendedBenchmarkOptions,
  getEmInpatientByLevelExportDataWithExtendedBenchmarkOptions,
  getEmOutpatientByLevelExportDataWithExtendedBenchmarkOptions,
  getExcelDataForProcedureSummary,
  getFilterInfo,
  getPayerHierarchyString
} from '../shared/export/export-helper';
import {CSV, CSVToken} from '../services/csv.service';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {
  ExcelExportMetricId,
  Export,
  ExportMetadata,
  ExportMetric,
  ExportNew,
  PdfExportMetrics
} from '../shared/export/export';
import * as _ from 'lodash';
import {ExcelService, ExcelServiceToken} from '../services/excel.service';
import {
  generateOriginalExcelNpvLocationByDimensionDataForDisplayedColumns,
  getEMEmergencyByDepartmentExportDataWithExtendedBenchmarkOptions,
  getEMEmergencyByProviderExportDataWithExtendedBenchmarkOptions,
  getEMEmergencyBySpecialtyExportDataWithExtendedBenchmarkOptions,
  getEMEyeExamByDepartmentExportDataWithExtendedBenchmarkOptions,
  getEMEyeExamByProviderExportDataWithExtendedBenchmarkOptions,
  getEMEyeExamBySpecialtyExportDataWithExtendedBenchmarkOptions,
  getEMInpatientByDepartmentExportDataWithExtendedBenchmarkOptions,
  getEMInpatientByProviderExportDataWithExtendedBenchmarkOptions,
  getEMInpatientBySpecialtyExportDataWithExtendedBenchmarkOptions,
  getEmOutpatientByDepartmentExportDataWithExtendedBenchmarkOptions,
  getEmOutpatientByProviderExportDataWithExtendedBenchmarkOptions,
  getEmOutpatientBySpecialtyExportDataWithExtendedBenchmarkOptions,
  getEmTrendCsvDataWithExtendedBenchmarkOptions,
  getEmTrendExcelDataWithExtendedBenchmarkOptions,
  getExcelNpvSnapshotDataForDisplayedColumns,
  getExcelNpvTrendDataForDisplayedColumns,
  getWrvuTrendDataForDisplayedColumns
} from '../shared/export/export-excel-helper';
import {
  BenchmarkOption,
  ColumnType,
  CptViewType,
  GoogleAnalyticCategories,
  MultilevelTab,
  MultilevelTabCollections
} from '../shared/enums';
import {
  Breadcrumb,
  EmDimension,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  FeatureToggleSetting,
  MergedProductivityTrendEntry,
  NewPatientVisitSummary,
  OntologyNode,
  PdfExportData,
  UserProfile,
  ZipCodeNewPatientVisits
} from '../shared/models';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {clinicalFingerprintCompatibleWithNodePath} from '../shared/test/ontology-helper';
import {getOntologyData} from '../shared/localStoragehelper';
import {Location} from '@angular/common';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {
  MergedProviderProductivityMultiLevelData,
  ProductivitySummary
} from '../productivity-summary/services/ProviderProductivity';
import {
  MatchedCollectionsMultiLevelNodePathData,
  PayerMatchedCollectionsMultiLevelNodePathData
} from '../collections/Collection';
import {
  CfpByMultilevel,
  CfpMultiLevelData,
  ClinicalSummaryConsolidation,
  GroupedCfpByMultilevel,
  groupMultilevelCPTDataByCPTField
} from '../clinical-fingerprint/ClinicalSummary';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {DEFAULT_MEMBER_BILLING_AREA, DEFAULT_PAYER_CATEGORY} from '../store/DefaultValues';
import {invoiceStatusTypes, visitTypes} from '../shared/constants';
import {
  MergedNpvLocationAggregatedByNode,
  MergedProviderNewPatientVisitMultiLevelData,
  NewPatientVisitTrendEntry,
  npvLocationDepartmentColumns,
  npvLocationProviderColumns,
  npvLocationSpecialtyColumns
} from '../new-patient-visits/components/npv-models';
import {allMetricsExport, ExportOption, FULL_METRIC_EXPORTS} from '../shared/export/export-metrics';
import {
  dataGroupedByCptViewType
} from '../clinical-fingerprint/cfp-multilevel/cfp-multilevel-table/cfp-multilevel-table.component';
import {
  columnsForDenialsByPayer,
  columnsForMultiLevelDenialsDepartment,
  columnsForMultiLevelDenialsProvider,
  columnsForMultiLevelDenialsSpecialty,
  npvSummaryColumns
} from '../shared/data-table-columns';
import {
  convertWrvuSnapshotColumnsForDimensionRequirements,
  getWRVUSnapshotExcelData,
  getWrvuSnapshotExportDataWithDisplayedColumns,
  getWrvuTrendExportDataWithDisplayedColumns
} from '../shared/export/wrvu-export-helper';
import {
  getCsvNpvSnapshotDataForDisplayedColumns,
  getCsvNpvTrendDataForDisplayedColumns
} from '../shared/export/npv-export-helper';
import {
  adjustMultilevelDenialsColumnsForByDepartment,
  adjustMultilevelDenialsColumnsForByProvider,
  adjustMultilevelDenialsColumnsForBySpecialty,
  getTheExcelDenialsByPayerData,
  getTheExcelDenialsSnapshotData
} from '../shared/export/denials-export-helper';
import {
  getCollectionsExcelData,
  getCollectionsExportData,
  getPayerByLevelCollectionsExcelData,
  getPayerCollectionsByLevelExportData,
  getPayerCollectionsExcelData,
  getPayerCollectionsExportData,
  getSummaryCollectionsExcelData,
  getSummaryCollectionsExportCsvData
} from '../shared/export/collections-export-helper';
import {DenialsMultiLevelData, DenialsPayerData} from '../denials/denials-models';
import {filterCfpDataBasedOnCptGroupAndDrill} from '../clinical-fingerprint/clinical-fingerprint-helpers';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit, OnDestroy {

  @Output() afterPdfExport = new EventEmitter<[PdfExportData, EmDimension[], boolean]>();

  @select(['display', 'isExporting'])
  private readonly isExporting$: Observable<boolean>;
  @select(['data', 'providerProductivityMultilevelData'])
  private readonly providerProductivityMultilevelData$: Observable<MergedProviderProductivityMultiLevelData>;
  @select(['data', 'mergedProductivityTrend'])
  private readonly mergedProductivityTrend$: Observable<MergedProductivityTrendEntry[]>;
  @select(['data', 'productivitySummaryData'])
  private readonly productivitySummaryData$: Observable<SummaryData<ProductivitySummary>>;
  // NPV subs
  @select(['data', 'newPatientVisitMultilevelData'])
  private readonly newPatientVisitMultilevelData$: Observable<MergedProviderNewPatientVisitMultiLevelData>;
  @select(['data', 'newPatientVisitTrendData'])
  private readonly newPatientVisitTrendData$: Observable<NewPatientVisitTrendEntry[]>;
  @select(['data', 'mergedNpvLocationAggregatedByNode'])
  private readonly mergedNpvLocationAggregatedByNode$: Observable<MergedNpvLocationAggregatedByNode[]>;
  @select(['data', 'summaryNewPatientVisitData'])
  private readonly summaryNewPatientVisitData$: Observable<SummaryData<NewPatientVisitSummary>>;
  isExporting: boolean;
  @select(['data', 'matchedCollectionsByMultiLevelByNodePathData'])
  private readonly matchedCollectionsByMultiLevelByNodePathData$: Observable<MatchedCollectionsMultiLevelNodePathData>;
  @select(['data', 'matchedCollectionsByPayerByMultiLevelByNodePathData'])
  private readonly matchedCollectionsByPayerByMultiLevelByNodePathData$: Observable<PayerMatchedCollectionsMultiLevelNodePathData>;
  // Em subs
  @select(['data', 'evaluationManagementMultilevelData'])
  private readonly evaluationManagementMultilevelData$: Observable<EvaluationManagementMultilevel>;

  @select(['data', 'summaryEvaluationManagementData'])
  private readonly summaryEvaluationManagementData$: Observable<EvaluationManagementSummary>;
  // Cfp subs
  @select(['data', 'clinicalSummaryConsolidationData'])
  private readonly clinicalSummaryConsolidationData$: Observable<ClinicalSummaryConsolidation>;
  // Proc sum subs
  @select(['data', 'clinicalFingerprintMultilevelData'])
  private readonly clinicalFingerprintMultilevelData$: Observable<CfpMultiLevelData>;
  // Overview subs
  @select(['data', 'zipCodeNewPatientVisits'])
  private readonly zipCodeNewPatientVisits$: Observable<ZipCodeNewPatientVisits[]>;
  // denials subs
  @select(['data', 'denials'])
  private readonly denialsData$: Observable<DenialsPayerData>;
  @select(['data', 'denialsByMultiLevelData'])
  private readonly denialsMultiLevelData$: Observable<DenialsMultiLevelData>;

  currentlyExpanded: ExcelExportMetricId[] = [];
  showCfp = false;
  canHideCollectionsExport = false;
  canHideDenialsExport = false;
  canShowAdditionalCfpColumns = false;
  shouldHideCfteAdjWrvuDepartment = false;
  npvBenchmarkOption: BenchmarkOption;
  emBenchmarkOption: BenchmarkOption;
  showCsv = false;
  showExcel = false;
  showPdf = false;
  ExportType = ExportType;
  MetricType = MetricType;
  exportMetricOntology: ExportOption[] = FULL_METRIC_EXPORTS.map(metricExport => ({
    ...metricExport,
    selected: false,
    displayed: true
  }));
  excelExportMetrics: { [key in ExcelExportMetricId]: ExportOption };
  allExcelMetric: ExportOption = {
    ...allMetricsExport,
    selected: false,
    children: this.exportMetricOntology
  };
  pdfExportMetrics = PdfExportMetrics;
  isProviderSelected: boolean;
  breadCrumbs: Breadcrumb[];
  currentNodeName: string;
  memberLocationName: string;
  memberLocationHeaderExcel: string;
  memberLocationHeaderCsv: string;

  payerCategoryHeader: string;
  invoiceStatusHeader: string;
  visitTypeHeader: string;
  lagPeriodHeader: string;
  memberBillingAreaHeader: string;

  cptViewType: CptViewType;
  communityBenchmarkAlert = false;

  selectedExportedId: number;
  level: string;
  readonly copyRightYear: number = new Date().getFullYear() - 1;
  readonly emExportTitle: string = 'Clinical Practice Solutions Center Evaluation and Management Analysis CPT® only © '
    + this.copyRightYear + ' American Medical Association. All Rights Reserved. ';
  readonly wrvuExportTitle = 'Clinical Practice Solutions Center Productivity Summary ';
  readonly specialtyPerformanceExportTitle = 'Clinical Practice Solutions Center Productivity Summary - Specialty Performance ';
  readonly npvExportTitle = 'Clinical Practice Solutions Center New Patient Visits ';
  readonly cfExportTitle = 'Clinical Practice Solutions Center Clinical Fingerprint ' +
    'CPT® only © ' + this.copyRightYear + ' American Medical Association. All Rights Reserved. ';
  collectionsExportTitle = 'Clinical Practice Solutions Center Collections ';
  readonly collectionsNote = 'These are based on charge entry (Invoice creation) date. The NCR' +
    ' benchmarks represent a full calendar year. To assess your performance to these' +
    ' benchmarks, we recommend you look at invoices that have aged at least 6 months.';
  readonly collectionsCsvNote = 'These are based on charge entry (Invoice creation) date. The NCR' +
    ' benchmarks represent a full calendar year. To assess your performance to these' +
    ' benchmarks we recommend you look at invoices that have aged at least 6 months.';
  shouldEnableButton = false;

  displayedPayerCollectionsColumns: BaseColumn[];
  displayedNpvSnapshotColumns: BaseColumn[];
  displayedNpvByDepartmentColumns: BaseColumn[];
  displayedNpvBySpecialtyColumns: BaseColumn[];
  displayedNpvByProviderColumns: BaseColumn[];
  displayedNpvTrendColumns: BaseColumn[];
  displayedNpvLocationDepartmentColumns: BaseColumn[];
  displayedNpvLocationSpecialtyColumns: BaseColumn[];
  displayedNpvLocationProviderColumns: BaseColumn[];
  displayedWRVUTrendColumns: BaseColumn[];
  displayedCollectionsByLevelColumns: BaseColumn[];
  displayedCollectionsByPayerByMultilevelColumns: BaseColumn[];
  displayedCollectionsMultiLevelColumns: BaseColumn[];
  displayedCollectionsByDepartmentColumns: BaseColumn[];
  displayedCollectionsBySpecialtyColumns: BaseColumn[];
  displayedCollectionsByProviderColumns: BaseColumn[];
  displayedCollectionsByPayerByDepartmentColumns: BaseColumn[];
  displayedCollectionsByPayerBySpecialtyColumns: BaseColumn[];
  displayedCollectionsByPayerByProviderColumns: BaseColumn[];
  displayedDenialsByPayerColumns: BaseColumn[];
  displayedDenialsByDepartmentColumns: BaseColumn[];
  displayedDenialsBySpecialtyColumns: BaseColumn[];
  displayedDenialsByProviderColumns: BaseColumn[];
  currentCollectionsLevel: MultilevelTabCollections;
  currentCollectionsLabel: string;
  currentDenialsLevel: MultilevelTabCollections;
  currentDenialsLabel: string;

  selectedDateHeader: string;

  defaultExportType = ExportType.excel;
  data: any;
  excelExport: ExportNew;

  exportData: Export[] = [];

  pdfPermitted = false;

  moreThanMaxProvidersSelected: boolean;
  moreThanMaxSpecialtiesSelected: boolean;
  // Loading subscriptions
  wrvuSubscription: Subscription;
  npvSubscription: Subscription;
  collectionSubscription: Subscription;
  emSubscription: Subscription;
  cfpSubscription: Subscription;
  procedureSummarySubscription: Subscription;
  overviewSubscription: Subscription;
  denialsSubscription: Subscription;
  // Loading booleans
  isWrvuLoading: boolean;
  isNPVLoading: boolean;
  isCollectionsLoading: boolean;
  isDenialsLoading: boolean;
  isEmLoading: boolean;
  isCfpLoading: boolean;
  isOverviewLoading: boolean;
  isProcedureSummaryLoading: boolean;

  viewCommunityBenchmarks = false;
  suppressZeroes = false;
  canShowNpvLocation: boolean;
  canShowCfteWrvu: boolean;
  canShowCfteCount: boolean;

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>,
              public router: Router,
              private location: Location,
              private readonly ngRedux: NgRedux<IAppState>,
              @Inject(CSVToken) private readonly csv: CSV,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
              @Inject(ExcelServiceToken) private readonly excelService: ExcelService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.isExporting$.subscribe((isExporting: boolean) => {
      this.isExporting = isExporting;
      this.setShouldEnableButton();
    });

    this.initializeCsvExcelExportValues();

    const {display, data, filters} = this.ngRedux.getState();
    const {featureToggleSettings, userProfile} = data;

    this.initializeDisplayedColumnFields(display, data);
    this.isProviderSelected = display.isProviderSelected;
    this.showCfp = display.selectedSpecialties === 1 || clinicalFingerprintCompatibleWithNodePath(filters.nodePath);
    this.initializeFeatureToggleDependentFields(featureToggleSettings, userProfile, display);

    this.canHideCollectionsExport = !display.isRevenueMember;
    this.npvBenchmarkOption = display.emNpvBenchmarkOption;
    this.emBenchmarkOption = display.emNpvBenchmarkOption;
    this.setUpExportTypeTabs();

    this.viewCommunityBenchmarks = display.viewCommunityBenchmarks;
    this.suppressZeroes = display.zeroSuppression;
    const selectedPath = filters.nodePath;

    const maxSpecialties = +getAppConfigValue(AppConfigEntries.MAX_SPECIALTY_PATHS, data.applicationConfigurationSettings) || 500;
    this.moreThanMaxSpecialtiesSelected = display.selectedSpecialties > maxSpecialties;

    const maxProviders = +getAppConfigValue(AppConfigEntries.MAX_PROVIDER_PATHS, data.applicationConfigurationSettings) || 300;
    this.moreThanMaxProvidersSelected = display.selectedProviders > maxProviders;

    const multipleProviders = display.multipleProviders;
    const multipleSpecialties = display.multipleSpecialties;
    const multipleDepartments = display.multipleDepartments;
    const ontologyHierarchy = getOntologyData().ontologyHierarchy;
    const currentNodeChildren = (multipleProviders || multipleSpecialties)
      ? -1 : this.countChildNodes(ontologyHierarchy, selectedPath);
    this.level = display.isCustom ? '' :
      getLevelFromNodePath(selectedPath,
        multipleProviders,
        multipleSpecialties, multipleDepartments);
    this.currentCollectionsLabel = this.getMultiLevelCollectionsLabel(this.currentCollectionsLevel);
    this.currentDenialsLabel = this.getMultiLevelCollectionsLabel(this.currentDenialsLevel);
    this.cptViewType = display.cptViewType;
    this.clearMetricsTypeSelections();
    this.setDefaultCheck(this.router.url);
    this.initializeBreadcrumbs(selectedPath, ontologyHierarchy, multipleProviders, multipleSpecialties, currentNodeChildren);
    this.composeCurrentLocationFiltersForExport(data, filters);
    this.initializeHeaderFields(filters);
    this.assignExportOptionDisplayValues(this.exportMetricOntology);
    this.setShouldEnableButton();
    this.initializeMetricSubscriptions();
  }

  private initializeBreadcrumbs(selectedPath: string, ontologyHierarchy: OntologyNode[],
                                multipleProviders: boolean, multipleSpecialties: boolean, currentNodeChildren: number) {
    this.breadCrumbs = this.buildBreadcrumbs(selectedPath, ontologyHierarchy, multipleProviders, multipleSpecialties, currentNodeChildren);
    this.breadCrumbs.push({
      nodeName: this.currentNodeName,
      nodePath: selectedPath
    });
  }

  private setUpExportTypeTabs() {
    if (this.router.url === overviewPageRoute) {
      this.setExportTab(ExportType.pdf);
      this.pdfPermitted = true;
    } else {
      this.setExportTab(this.defaultExportType);
      this.pdfPermitted = false;
    }
  }

  private initializeDisplayedColumnFields(display: CurrentSelections, data: Data) {
    const payerByLevelCollectionsPayerColumns = data.payerCollectionsData.payerMatchedCollections.map(x => {
      return {
        columnDef: removeWhiteSpace(x.payerCategoryDescription),
        header: x.payerCategoryDescription,
        columnType: ColumnType.PAYER
      };
    });
    this.communityBenchmarkAlert = display.communityBenchmarkAlert && display.viewCommunityBenchmarks;
    this.currentCollectionsLevel = display.collectionsChosenTab;
    this.currentDenialsLevel = display.denialsChosenTab;
    this.displayedPayerCollectionsColumns = display.displayedPayerCollectionsColumns;
    this.displayedNpvSnapshotColumns = display.displayedNPVSnapshotColumns;
    this.displayedNpvByDepartmentColumns = display.displayedNPVDepartmentColumns;
    this.displayedNpvBySpecialtyColumns = display.displayedNPVSpecialtyColumns;
    this.displayedNpvByProviderColumns = display.displayedNPVProviderColumns;
    this.displayedNpvTrendColumns = display.displayedNPVTrendColumns;
    this.displayedNpvLocationDepartmentColumns = display.newPatientVisitsLocationVariables.displayedDepartmentColumns;
    this.displayedNpvLocationSpecialtyColumns = display.newPatientVisitsLocationVariables.displayedSpecialtyColumns;
    this.displayedNpvLocationProviderColumns = display.newPatientVisitsLocationVariables.displayedProviderColumns;
    this.displayedCollectionsByDepartmentColumns = display.displayedCollectionsDepartmentColumns;
    this.displayedCollectionsBySpecialtyColumns = display.displayedCollectionsSpecialtyColumns;
    this.displayedCollectionsByProviderColumns = display.displayedCollectionsProviderColumns;
    this.displayedCollectionsByPayerByDepartmentColumns =
      display.displayedCollectionsByPayerDepartmentColumns.concat(payerByLevelCollectionsPayerColumns);
    this.displayedCollectionsByPayerBySpecialtyColumns =
      display.displayedCollectionsByPayerSpecialtyColumns.concat(payerByLevelCollectionsPayerColumns);
    this.displayedCollectionsByPayerByProviderColumns =
      display.displayedCollectionsByPayerProviderColumns.concat(payerByLevelCollectionsPayerColumns);
    this.displayedDenialsByPayerColumns = display.displayedDenialsColumns;
    this.displayedDenialsByDepartmentColumns = adjustMultilevelDenialsColumnsForByDepartment(display.displayedMultiLevelDenialsColumns);
    this.displayedDenialsBySpecialtyColumns = adjustMultilevelDenialsColumnsForBySpecialty(display.displayedMultiLevelDenialsColumns);
    this.displayedDenialsByProviderColumns = adjustMultilevelDenialsColumnsForByProvider(display.displayedMultiLevelDenialsColumns);
    this.displayedWRVUTrendColumns = display.displayedWRVUTrendColumns;
    this.displayedCollectionsByLevelColumns = display.payerByLevelCollectionsStaticColumns ?
      display.payerByLevelCollectionsStaticColumns.concat(payerByLevelCollectionsPayerColumns) :
      display.payerByLevelCollectionsStaticColumns;
    this.displayedCollectionsMultiLevelColumns = display.multiLevelByNodePathCollectionsColumns;
    this.displayedCollectionsByPayerByMultilevelColumns = display.multiLevelByNodePathPayerCollectionsColumns;
  }

  private initializeFeatureToggleDependentFields(featureToggleSettings: FeatureToggleSetting[],
                                                 userProfile: UserProfile, display: CurrentSelections) {
    this.canShowNpvLocation = isFeatureEnabled(FeatureToggleEntries.NPV_BY_LOCATION, featureToggleSettings, userProfile);
    this.canHideDenialsExport = !display.isRevenueMember;
    this.canShowAdditionalCfpColumns = isFeatureEnabled(FeatureToggleEntries.SHOW_CFP_COLUMNS, featureToggleSettings, userProfile);
    this.shouldHideCfteAdjWrvuDepartment = isFeatureEnabled(FeatureToggleEntries.HIDE_WRVU_BY_DEPARTMENT_CFTE_ADJ_WRVUS,
      featureToggleSettings, userProfile);


    this.canShowCfteWrvu = !isFeatureEnabled(FeatureToggleEntries.HIDE_PROCEDURE_SUMMARY_CFTE_WRVUS,
      featureToggleSettings, userProfile);
    this.canShowCfteCount = !isFeatureEnabled(FeatureToggleEntries.HIDE_PROCEDURE_SUMMARY_CFTE_COUNT,
      featureToggleSettings, userProfile);
  }

  private initializeHeaderFields(filters: FilterCriteria) {
    this.payerCategoryHeader = `Payer Category: ${filters.payerCategory.payerCategoryDescription === '' ?
      DEFAULT_PAYER_CATEGORY.payerCategoryDescription : filters.payerCategory.payerCategoryDescription}`;

    this.lagPeriodHeader = `Lag Period: ${lagPeriods[filters.lagKey - 1].name}`;

    this.invoiceStatusHeader = `Invoice Type: ${invoiceStatusTypes[filters.invoiceStatus].statusText}`;

    this.memberBillingAreaHeader = `Billing Area: ${!!filters.memberBillingArea.memberBillingAreaDescription ?
      filters.memberBillingArea.memberBillingAreaDescription : DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaDescription}`;

    this.visitTypeHeader = 'Visit Type: ' + visitTypes[filters.telehealthFlag ?? 0].text;

    this.selectedDateHeader = `Selected Date Range (${getSelectedDateRange(filters.dateRange)})`;
  }

  private initializeMetricSubscriptions() {
    this.wrvuSubscription = this.getWrvuSubscription();
    this.npvSubscription = this.getNpvSubscription();
    this.collectionSubscription = this.getCollectionSubscription();
    this.denialsSubscription = this.getDenialsSubscription();
    this.emSubscription = this.getEmSubscription();
    this.cfpSubscription = this.getCfpSubscription();
    this.procedureSummarySubscription = this.getProcedureSummarySubscription();
    this.overviewSubscription = this.getOverviewSubscription();
  }

  private getOverviewSubscription() {
    return combineLatest([this.mergedProductivityTrend$, this.newPatientVisitTrendData$,
      this.zipCodeNewPatientVisits$, this.summaryEvaluationManagementData$]).subscribe(
      ([mergedProductivityTrend, newPatientVisitTrendData, zipCodeNewPatientVisits, summaryEvaluationManagementData]:
         [MergedProductivityTrendEntry[], NewPatientVisitTrendEntry[], ZipCodeNewPatientVisits[], EvaluationManagementSummary]) => {
        this.isOverviewLoading = this.getIsOverviewLoading(mergedProductivityTrend, newPatientVisitTrendData,
          zipCodeNewPatientVisits, summaryEvaluationManagementData);
      });
  }

  private getProcedureSummarySubscription() {
    return this.clinicalFingerprintMultilevelData$.subscribe((procedureSummaryData: CfpMultiLevelData) => {
      this.isProcedureSummaryLoading = _.isEqual(INITIAL_STATE.data.clinicalFingerprintMultilevelData, procedureSummaryData);
      this.assignExportOptionLoadingValue(ExcelExportMetricId.PROCEDURE_SUMMARY, this.isProcedureSummaryLoading);
    });
  }

  private getCfpSubscription() {
    return this.clinicalSummaryConsolidationData$
      .subscribe((clinicalSummaryConsolidationData: ClinicalSummaryConsolidation) => {
        this.isCfpLoading = this.getIsCfpLoading(clinicalSummaryConsolidationData);
        this.assignExportOptionLoadingValue(ExcelExportMetricId.CLINICAL_FINGERPRINT, this.isCfpLoading);
      });
  }

  private getEmSubscription() {
    return combineLatest([this.evaluationManagementMultilevelData$, this.summaryEvaluationManagementData$])
      .subscribe(([evaluationManagementMultilevelData, summaryEvaluationManagementData]:
                    [EvaluationManagementMultilevel, EvaluationManagementSummary]) => {
        this.isEmLoading = this.getIsEmLoading(evaluationManagementMultilevelData, summaryEvaluationManagementData);
        this.assignExportOptionLoadingValue(ExcelExportMetricId.EM, this.isEmLoading);
      });
  }

  private getDenialsSubscription() {
    return combineLatest([this.denialsMultiLevelData$, this.denialsData$])
      .subscribe(([denialsMultiLevelData, denialsData]: [DenialsMultiLevelData, DenialsPayerData]) => {
        this.isDenialsLoading = this.getIsDenialsLoading(denialsMultiLevelData, denialsData);
        this.assignExportOptionLoadingValue(ExcelExportMetricId.DENIALS, this.isDenialsLoading);
      });
  }

  private getCollectionSubscription() {
    return combineLatest(
      [this.matchedCollectionsByMultiLevelByNodePathData$, this.matchedCollectionsByPayerByMultiLevelByNodePathData$])
      .subscribe(([matchedCollectionsByMultiLevelByNodePathData, matchedCollectionsByPayerByMultiLevelByNodePathData]:
                    [MatchedCollectionsMultiLevelNodePathData, PayerMatchedCollectionsMultiLevelNodePathData]) => {
        this.isCollectionsLoading = this.getIsCollectionsLoading(
          matchedCollectionsByMultiLevelByNodePathData, matchedCollectionsByPayerByMultiLevelByNodePathData);
        this.assignExportOptionLoadingValue(ExcelExportMetricId.COLLECTIONS, this.isCollectionsLoading);
      });
  }

  private getNpvSubscription() {
    return combineLatest([
      this.newPatientVisitMultilevelData$,
      this.newPatientVisitTrendData$,
      this.mergedNpvLocationAggregatedByNode$,
      this.summaryNewPatientVisitData$])
      .subscribe(([newPatientVisitMultilevelData, newPatientVisitTrendData, npvLocationData, summaryNewPatientVisitData]:
                    [MergedProviderNewPatientVisitMultiLevelData, NewPatientVisitTrendEntry[], MergedNpvLocationAggregatedByNode[],
                      SummaryData<NewPatientVisitSummary>]) => {
        this.isNPVLoading = this.getIsNPVLoading(newPatientVisitMultilevelData, newPatientVisitTrendData, npvLocationData,
          summaryNewPatientVisitData);
        this.assignExportOptionLoadingValue(ExcelExportMetricId.NPV, this.isNPVLoading);
      });
  }

  private getWrvuSubscription() {
    return combineLatest([
      this.providerProductivityMultilevelData$,
      this.productivitySummaryData$,
      this.mergedProductivityTrend$])
      .subscribe(([providerProductivityMultilevelData, productivitySummaryData, mergedProductivityTrend]:
                    [MergedProviderProductivityMultiLevelData, SummaryData<ProductivitySummary>, MergedProductivityTrendEntry[]]) => {
        this.isWrvuLoading = this.getIsWrvuLoading(providerProductivityMultilevelData, productivitySummaryData, mergedProductivityTrend);
        this.assignExportOptionLoadingValue(ExcelExportMetricId.WRVUS, this.isWrvuLoading);
      });
  }

  ngOnDestroy() {
    this.wrvuSubscription?.unsubscribe();
    this.npvSubscription?.unsubscribe();
    this.collectionSubscription?.unsubscribe();
    this.emSubscription?.unsubscribe();
    this.cfpSubscription?.unsubscribe();
    this.procedureSummarySubscription?.unsubscribe();
    this.overviewSubscription?.unsubscribe();
    this.denialsSubscription?.unsubscribe();
  }

  private composeCurrentLocationFiltersForExport(data: Data, filters: FilterCriteria) {
    const maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT, data.applicationConfigurationSettings) || 5;
    const selectedLocations = data.memberLocations.filter(loc => loc.currentlySelected);
    const additionalLocations = selectedLocations.length - maxLocations;
    const isSingleLocation = !selectedLocations.length;
    this.memberLocationHeaderExcel = composeCurrentLocationFiltersForExcelExport(isSingleLocation, selectedLocations,
      maxLocations, additionalLocations, filters);
    this.memberLocationHeaderCsv = composeCurrentLocationFiltersForCsvExport(isSingleLocation, selectedLocations, filters);
  }

  initializeCsvExcelExportValues() {
    // @ts-ignore
    this.excelExportMetrics = {
      [ExcelExportMetricId.ALL_METRICS]: this.allExcelMetric
    };

    for (const key in ExcelExportMetricId) {
      if (ExcelExportMetricId.hasOwnProperty(key)) {
        const correspondingExport = findOptionWithValue(this.exportMetricOntology, 'id', +key);
        if (correspondingExport) {
          // @ts-ignore
          this.excelExportMetrics[key] = correspondingExport;
        }
      }
    }
  }

  assignExportOptionDisplayValues(exportOptions: ExportOption[]): void {
    exportOptions.forEach(exportOption => {
      switch (exportOption.id) {
        case ExcelExportMetricId.CLINICAL_FINGERPRINT:
          exportOption.displayed = this.showCfp;
          break;
        case ExcelExportMetricId.PROCEDURE_SUMMARY:
          exportOption.displayed = true;
          break;
        case ExcelExportMetricId.NPV_BY_LOCATION:
          exportOption.displayed = this.canShowNpvLocation;
          break;
        case ExcelExportMetricId.COLLECTIONS:
          exportOption.displayed = !this.canHideCollectionsExport;
          break;
        case ExcelExportMetricId.DENIALS:
          exportOption.displayed = !this.canHideDenialsExport;
          break;
        case ExcelExportMetricId.WRVU_BY_SPECIALTY:
        case ExcelExportMetricId.NPV_BY_SPECIALTY:
        case ExcelExportMetricId.NPV_BY_LOCATION_BY_SPECIALTY:
        case ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY:
        case ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY:
        case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY:
        case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY:
          exportOption.displayed = !this.moreThanMaxSpecialtiesSelected;
          break;
        case ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY:
          exportOption.displayed = !this.canHideCollectionsExport && !this.moreThanMaxSpecialtiesSelected;
          break;
        case ExcelExportMetricId.DENIALS_BY_SPECIALTY:
          exportOption.displayed = !this.canHideDenialsExport && !this.moreThanMaxSpecialtiesSelected;
          break;
        case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY:
          exportOption.displayed = !this.moreThanMaxSpecialtiesSelected;
          break;
        case ExcelExportMetricId.WRVU_BY_PROVIDER:
        case ExcelExportMetricId.NPV_BY_PROVIDER:
        case ExcelExportMetricId.NPV_BY_LOCATION_BY_PROVIDER:
        case ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER:
        case ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER:
        case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER:
        case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER:
          exportOption.displayed = !this.moreThanMaxProvidersSelected;
          break;
        case ExcelExportMetricId.COLLECTIONS_BY_PROVIDER:
          exportOption.displayed = !this.canHideCollectionsExport && !this.moreThanMaxProvidersSelected;
          break;
        case ExcelExportMetricId.DENIALS_BY_PROVIDER:
          exportOption.displayed = !this.canHideDenialsExport && !this.moreThanMaxProvidersSelected;
          break;
        case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER:
          exportOption.displayed = !this.moreThanMaxProvidersSelected;
          break;
        case ExcelExportMetricId.WRVU_SPECIALTY_PERFORMANCE:
          exportOption.displayed = !this.moreThanMaxProvidersSelected;
          break;
        case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_FAMILY:
        case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_RANGE:
        case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_CODE:
          exportOption.displayed = this.showCfp;
          break;
        case ExcelExportMetricId.COLLECTIONS_SUMMARY:
        case ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT:
        case ExcelExportMetricId.COLLECTIONS_BY_PAYER:
        case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT:
        case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY:
        case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER:
          exportOption.displayed = !this.canHideCollectionsExport;
          break;
        case ExcelExportMetricId.DENIALS_BY_DEPARTMENT:
        case ExcelExportMetricId.DENIALS_BY_PAYER:
          exportOption.displayed = !this.canHideDenialsExport;
          break;
        case ExcelExportMetricId.EM_OUTPATIENT_TREND:
        case ExcelExportMetricId.EM_INPATIENT_TREND:
        case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND:
        case ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND:
          exportOption.displayed = true;
          break;
        case ExcelExportMetricId.WRVU_BY_DEPARTMENT:
        case ExcelExportMetricId.NPV_BY_DEPARTMENT:
        case ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT:
        case ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT:
        case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT:
        case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT:
        case ExcelExportMetricId.WRVU_TREND_VIEW:
        case ExcelExportMetricId.NPV_TREND:
        case ExcelExportMetricId.EM_OUTPATIENT:
        case ExcelExportMetricId.EM_INPATIENT:
        case ExcelExportMetricId.EM_EMERGENCY_MEDICINE:
        case ExcelExportMetricId.EM_OPHTHALMOLOGY:
        default:
          exportOption.displayed = true;
          break;
      }

      this.assignExportOptionDisplayValues(exportOption.children);
    });
  }

  assignExportOptionLoadingValue(optionId: ExcelExportMetricId, value: boolean) {
    const option = this.excelExportMetrics[optionId];
    option.loading = value;
    option.children.forEach(child => {
      this.assignExportOptionLoadingValue(child.id, value);
    });
  }

  private getIsCollectionsLoading(matchedCollectionsByMultiLevelByNodePathData: MatchedCollectionsMultiLevelNodePathData,
                                  matchedCollectionsByPayerByMultiLevelByNodePathData:
                                    PayerMatchedCollectionsMultiLevelNodePathData): boolean {
    const data = this.ngRedux.getState().data;

    return _.isEqual(matchedCollectionsByMultiLevelByNodePathData, INITIAL_STATE.data.matchedCollectionsByMultiLevelByNodePathData)
      || _.isEqual(matchedCollectionsByPayerByMultiLevelByNodePathData,
        INITIAL_STATE.data.matchedCollectionsByPayerByMultiLevelByNodePathData)
      || _.isEqual(data.summaryCollectionsData, INITIAL_STATE.data.summaryCollectionsData)
      || _.isEqual(data.payerCollectionsData, INITIAL_STATE.data.payerCollectionsData);
  }

  private getIsCfpLoading(clinicalSummaryConsolidationData: ClinicalSummaryConsolidation): boolean {
    let stillLoading = false;

    const showCfp = clinicalFingerprintCompatibleWithNodePath(this.ngRedux.getState().filters.nodePath);
    if (showCfp) {
      stillLoading = _.isEqual(clinicalSummaryConsolidationData,
        INITIAL_STATE.data.clinicalSummaryConsolidationData);
    }
    return stillLoading;
  }

  private getIsWrvuLoading(providerProductivityMultilevelData: MergedProviderProductivityMultiLevelData,
                           productivitySummaryData: SummaryData<ProductivitySummary>,
                           mergedProductivityTrend: MergedProductivityTrendEntry[]): boolean {
    return _.isEqual(providerProductivityMultilevelData, INITIAL_STATE.data.providerProductivityMultilevelData)
      || _.isEqual(productivitySummaryData, INITIAL_STATE.data.productivitySummaryData)
      || _.isEqual(mergedProductivityTrend, INITIAL_STATE.data.mergedProductivityTrend);
  }

  private getIsNPVLoading(newPatientVisitMultilevelData: MergedProviderNewPatientVisitMultiLevelData,
                          newPatientVisitTrendData: NewPatientVisitTrendEntry[],
                          npvLocationData: MergedNpvLocationAggregatedByNode[],
                          summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>): boolean {
    return _.isEqual(newPatientVisitMultilevelData, INITIAL_STATE.data.newPatientVisitMultilevelData)
      || _.isEqual(newPatientVisitTrendData, INITIAL_STATE.data.newPatientVisitTrendData)
      || (_.isEqual(npvLocationData, INITIAL_STATE.data.mergedNpvLocationAggregatedByNode) && this.canShowNpvLocation)
      || _.isEqual(summaryNewPatientVisitData, INITIAL_STATE.data.summaryNewPatientVisitData);
  }

  private getIsEmLoading(evaluationManagementMultilevelData: EvaluationManagementMultilevel,
                         summaryEvaluationManagementData: EvaluationManagementSummary): boolean {
    return _.isEqual(evaluationManagementMultilevelData, INITIAL_STATE.data.evaluationManagementMultilevelData)
      || _.isEqual(summaryEvaluationManagementData, INITIAL_STATE.data.summaryEvaluationManagementData);
  }

  private getIsDenialsLoading(denialsMultiLevel: DenialsMultiLevelData, denials: DenialsPayerData): boolean {
    return _.isEqual(denialsMultiLevel, INITIAL_STATE.data.denialsByMultiLevelData)
      || _.isEqual(denials, INITIAL_STATE.data.denials);
  }

  private getIsOverviewLoading(mergedProductivityTrend: MergedProductivityTrendEntry[],
                               newPatientVisitTrendData: NewPatientVisitTrendEntry[],
                               zipCodeNewPatientVisits: ZipCodeNewPatientVisits[],
                               summaryEvaluationManagementData: EvaluationManagementSummary) {
    return _.isEqual(mergedProductivityTrend, INITIAL_STATE.data.mergedProductivityTrend) ||
      _.isEqual(newPatientVisitTrendData, INITIAL_STATE.data.newPatientVisitTrendData) ||
      _.isEqual(zipCodeNewPatientVisits, INITIAL_STATE.data.zipCodeNewPatientVisits) ||
      _.isEqual(summaryEvaluationManagementData, INITIAL_STATE.data.summaryEvaluationManagementData);
  }

  closeExportOptions() {
    this.dialogRef.close();
  }

  setShouldEnableButton() {
    this.shouldEnableButton = false;
    if (this.showExcel || this.showCsv) {
      this.shouldEnableButton = this.doAnyOptionsHaveValue(this.exportMetricOntology, 'selected');
    } else {
      if (!this.isExporting) {
        this.pdfExportMetrics.forEach(metric => {
          if (metric.selected) {
            this.shouldEnableButton = true;
            return;
          }
        });
      }
    }
  }

  doAnyOptionsHaveValue(metricOptions: ExportOption[], value: keyof ExportOption): boolean {
    if (!metricOptions.length) {
      return false;
    }
    let foundValue = !!metricOptions.find(option => option[value]);
    if (!foundValue) {
      const allMetricOptionsChildren = metricOptions.reduce((a, b) => [...a, ...b.children], []);
      foundValue = this.doAnyOptionsHaveValue(allMetricOptionsChildren, value);
    }
    return foundValue;
  }

  setExportTab(exportType: ExportType) {
    this.showCsv = exportType === ExportType.csv;
    this.showExcel = exportType === ExportType.excel;
    this.showPdf = exportType === ExportType.pdf;
    this.setShouldEnableButton();
  }

  setDefaultCheck(url: string) {
    if (this.location && this.location.path(false).length > 0) {
      url = this.location.path(false);
    }
    if (!this.isRouteAbleToDefaultExpand(url)) {
      return;
    }
    const {multilevelTab, collectionsChosenTab, denialsChosenTab, emDimension} = this.ngRedux.getState().display;
    [this.pdfExportMetrics[1], this.pdfExportMetrics[2], this.pdfExportMetrics[3], this.pdfExportMetrics[4]]
      .forEach(x => x.selected = emDimension === x.metricDimension);

    let optionId: ExcelExportMetricId;

    switch (url) {
      case overviewPageRoute:
        this.allExcelMetric.selected = false;
        break;
      case wRVUsRoute:
      case wRVUSnapshotPageRoute:
      case wRVUDepartmentPageRoute:
      case wRVUSpecialtyPageRoute:
      case wRVUProviderPageRoute:
      case wRVUTrendPageRoute:
      case wRVUMultiLevelTrendPageRoute:
        this.currentlyExpanded.push(ExcelExportMetricId.WRVUS);
        switch (multilevelTab) {
          case MultilevelTab.BY_DEPARTMENT:
            optionId = ExcelExportMetricId.WRVU_BY_DEPARTMENT;
            break;
          case MultilevelTab.BY_SPECIALTY:
            optionId = ExcelExportMetricId.WRVU_BY_SPECIALTY;
            break;
          case MultilevelTab.BY_PROVIDER:
            optionId = ExcelExportMetricId.WRVU_BY_PROVIDER;
            break;
          case MultilevelTab.TREND:
            optionId = ExcelExportMetricId.WRVU_TREND_VIEW;
            break;
        }
        break;
      case npvRoute:
      case npvSnapshotPageRoute:
      case npvDepartmentPageRoute:
      case npvSpecialtyPageRoute:
      case npvProviderPageRoute:
      case npvTrendPageRoute:
      case npvMultiLevelTrendPageRoute:
        this.currentlyExpanded.push(ExcelExportMetricId.NPV);
        switch (multilevelTab) {
          case MultilevelTab.BY_DEPARTMENT:
            optionId = ExcelExportMetricId.NPV_BY_DEPARTMENT;
            break;
          case MultilevelTab.BY_SPECIALTY:
            optionId = ExcelExportMetricId.NPV_BY_SPECIALTY;
            break;
          case MultilevelTab.BY_PROVIDER:
            optionId = ExcelExportMetricId.NPV_BY_PROVIDER;
            break;
          case MultilevelTab.TREND:
            optionId = ExcelExportMetricId.NPV_TREND;
            break;
        }
        break;
      case emOutpatientRoute:
      case emOutpatientDepartmentRoute:
      case emOutpatientDepSpecialtyRoute:
      case emOutpatientDepProviderRoute:
      case emInpatientRoute:
      case emInpatientDepartmentRoute:
      case emInpatientDepSpecialtyRoute:
      case emInpatientDepProviderRoute:
      case emEmergencyMedicineRoute:
      case emEmergencyMedicineDepartmentRoute:
      case emEmergencyMedicineDepSpecialtyRoute:
      case emEmergencyMedicineDepProviderRoute:
      case emOphthalmologyRoute:
      case emOphthalmologyDepartmentRoute:
      case emOphthalmologyDepSpecialtyRoute:
      case emOphthalmologyDepProviderRoute:
        this.currentlyExpanded.push(ExcelExportMetricId.EM);
        optionId = this.getEmExportMetricId(multilevelTab, emDimension);
        break;
      case summaryCollectionsRoute:
      case collectionsDepartmentPageRoute:
      case collectionsSpecialtyPageRoute:
      case collectionsProviderPageRoute:
      case payerCollectionsMultiLevelRoute:
        this.currentlyExpanded.push(ExcelExportMetricId.COLLECTIONS);
        switch (collectionsChosenTab) {
          case MultilevelTabCollections.BY_DEPARTMENT:
            optionId = ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT;
            break;
          case MultilevelTabCollections.BY_SPECIALTY:
            optionId = ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY;
            break;
          case MultilevelTabCollections.BY_PROVIDER:
            optionId = ExcelExportMetricId.COLLECTIONS_BY_PROVIDER;
            break;
          case MultilevelTabCollections.BY_PAYER:
          case MultilevelTabCollections.BY_PAYER_BY_DEPARTMENT:
          case MultilevelTabCollections.BY_PAYER_BY_SPECIALTY:
          case MultilevelTabCollections.BY_PAYER_BY_PROVIDER:
            optionId = ExcelExportMetricId.COLLECTIONS_BY_PAYER;
            this.excelExportMetrics[ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT].selected = true;
            this.excelExportMetrics[ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY].selected = true;
            this.excelExportMetrics[ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER].selected = true;
            break;
          case MultilevelTabCollections.SUMMARY:
            optionId = ExcelExportMetricId.COLLECTIONS_SUMMARY;
            break;
        }
        break;
      case denialsRoute:
      case denialsDepartmentPageRoute:
      case denialsSpecialtyPageRoute:
      case denialsProviderPageRoute:
      case denialsPayerPageRoute:
        this.currentlyExpanded.push(ExcelExportMetricId.DENIALS);
        switch (denialsChosenTab) {
          case MultilevelTabCollections.BY_DEPARTMENT:
            optionId = ExcelExportMetricId.DENIALS_BY_DEPARTMENT;
            break;
          case MultilevelTabCollections.BY_SPECIALTY:
            optionId = ExcelExportMetricId.DENIALS_BY_SPECIALTY;
            break;
          case MultilevelTabCollections.BY_PROVIDER:
            optionId = ExcelExportMetricId.DENIALS_BY_PROVIDER;
            break;
          case MultilevelTabCollections.BY_PAYER:
            optionId = ExcelExportMetricId.DENIALS_BY_PAYER;
            break;
        }
        break;
      case clinicalFingerPrintRoute:
        this.currentlyExpanded.push(ExcelExportMetricId.CLINICAL_FINGERPRINT);
        switch (this.cptViewType) {
          case CptViewType.CptFamily:
            optionId = ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_FAMILY;
            break;
          case CptViewType.CptRange:
            optionId = ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_RANGE;
            break;
          case CptViewType.CptCode:
            optionId = ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_CODE;
            break;
        }
        break;
      case procedureSummaryRoute:
        this.currentlyExpanded.push(ExcelExportMetricId.PROCEDURE_SUMMARY);
        optionId = this.getProcedureSummaryMetricId(multilevelTab, this.cptViewType);
        break;
    }

    // @ts-ignore
    if (optionId) {
      this.excelExportMetrics[optionId].selected = true;
    }
  }

  private getProcedureSummaryMetricId(multilevelTab: MultilevelTab, cptViewType: CptViewType): ExcelExportMetricId {
    switch (multilevelTab) {
      case MultilevelTab.BY_PROVIDER:
        this.currentlyExpanded.push(ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER);
        switch (cptViewType) {
          case CptViewType.CptCode:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE;
          case CptViewType.CptRange:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE;
          case CptViewType.CptFamily:
          default:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY;
        }
      case MultilevelTab.BY_SPECIALTY:
        this.currentlyExpanded.push(ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY);
        switch (cptViewType) {
          case CptViewType.CptCode:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE;
          case CptViewType.CptRange:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE;
          case CptViewType.CptFamily:
          default:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY;
        }
      case MultilevelTab.BY_DEPARTMENT:
      default:
        this.currentlyExpanded.push(ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT);
        switch (cptViewType) {
          case CptViewType.CptCode:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE;
          case CptViewType.CptRange:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE;
          case CptViewType.CptFamily:
          default:
            return ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY;
        }
    }
  }

  private getEmExportMetricId(multilevelTab: MultilevelTab, emDimension: EmDimension): ExcelExportMetricId {
    switch (emDimension) {
      case EmDimension.Ophthalmology:
        this.currentlyExpanded.push(ExcelExportMetricId.EM_OPHTHALMOLOGY);
        switch (multilevelTab) {
          case MultilevelTab.TREND:
            return ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND;
          case MultilevelTab.BY_PROVIDER:
            return ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER;
          case MultilevelTab.BY_SPECIALTY:
            return ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY;
          case MultilevelTab.BY_DEPARTMENT:
          default:
            return ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT;
        }
      case EmDimension.EmergencyMedicine:
        this.currentlyExpanded.push(ExcelExportMetricId.EM_EMERGENCY_MEDICINE);
        switch (multilevelTab) {
          case MultilevelTab.TREND:
            return ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND;
          case MultilevelTab.BY_PROVIDER:
            return ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER;
          case MultilevelTab.BY_SPECIALTY:
            return ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY;
          case MultilevelTab.BY_DEPARTMENT:
          default:
            return ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT;
        }
      case EmDimension.InPatient:
        this.currentlyExpanded.push(ExcelExportMetricId.EM_INPATIENT);
        switch (multilevelTab) {
          case MultilevelTab.TREND:
            return ExcelExportMetricId.EM_INPATIENT_TREND;
          case MultilevelTab.BY_PROVIDER:
            return ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER;
          case MultilevelTab.BY_SPECIALTY:
            return ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY;
          case MultilevelTab.BY_DEPARTMENT:
          default:
            return ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT;
        }
      case EmDimension.Outpatient:
      default:
        this.currentlyExpanded.push(ExcelExportMetricId.EM_OUTPATIENT);
        switch (multilevelTab) {
          case MultilevelTab.TREND:
            return ExcelExportMetricId.EM_OUTPATIENT_TREND;
          case MultilevelTab.BY_PROVIDER:
            return ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER;
          case MultilevelTab.BY_SPECIALTY:
            return ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY;
          case MultilevelTab.BY_DEPARTMENT:
          default:
            return ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT;
        }
    }
  }

  isRouteAbleToDefaultExpand(url: string): boolean {
    switch (url) {
      case overviewPageRoute:
        return !this.isOverviewLoading;
      case wRVUsRoute:
      case wRVUSnapshotPageRoute:
      case wRVUDepartmentPageRoute:
      case wRVUSpecialtyPageRoute:
      case wRVUProviderPageRoute:
      case wRVUTrendPageRoute:
        return !this.isWrvuLoading;
      case clinicalFingerPrintRoute:
        return !this.isCfpLoading;
      case npvRoute:
      case npvSnapshotPageRoute:
      case npvDepartmentPageRoute:
      case npvSpecialtyPageRoute:
      case npvProviderPageRoute:
      case npvTrendPageRoute:
        return !this.isNPVLoading;
      case emPageRoute:
      case emOutpatientRoute:
      case emOutpatientDepartmentRoute:
      case emInpatientRoute:
      case emEmergencyMedicineRoute:
      case emOphthalmologyRoute:
        return !this.isEmLoading;
      case collectionsRoute:
      case collectionsSnapshotRoute:
      case collectionsDepartmentPageRoute:
      case collectionsSpecialtyPageRoute:
      case collectionsProviderPageRoute:
      case payerCollectionsRoute:
      case payerCollectionsMultiLevelRoute:
      case summaryCollectionsRoute:
        return !this.isCollectionsLoading;
      case denialsRoute:
      case denialsProviderPageRoute:
      case denialsSpecialtyPageRoute:
      case denialsDepartmentPageRoute:
        return !this.isDenialsLoading;
    }
    return true;
  }

  export() {
    if (this.showCsv) {
      this.exportToCsv();
    } else if (this.showExcel) {
      this.exportToExcel();
    } else if (this.showPdf) {
      this.exportToPdf();
    }
  }

  private findSelectedAndDownloadableNodes(exportOptions: ExportOption[]): ExportOption[] {
    const selectedAndDownloadableNodes: ExportOption[] = [];
    exportOptions.forEach(option => {
      if (option.selected && option.downloadable) {
        selectedAndDownloadableNodes.push(option);
      }
      selectedAndDownloadableNodes.push(...this.findSelectedAndDownloadableNodes(option.children));
    });
    return selectedAndDownloadableNodes;
  }

  async exportToCsv() {
    this.exportData = [];
    const analyticsEventNames: string[] = [];
    const downloadableMetrics = this.findSelectedAndDownloadableNodes(this.exportMetricOntology);
    const breadcrumbString = calculateBreadcrumbs(this.ngRedux.getState().filters.nodePath);
    const {data, display} = this.ngRedux.getState();
    const {
      isProviderSelected,
      displayedWRVUSnapshotColumns,
      displayedWRVUProviderColumns,
      displayedWRVUSpecialtyPerformanceColumns
    } = display;
    if (downloadableMetrics) {
      _.orderBy(downloadableMetrics, ['id'], ['asc']).filter(metric => metric.displayed).forEach((x) => {
        switch (x.id) {
          case ExcelExportMetricId.WRVU_TREND_VIEW:
            if (!x.loading) {
              this.exportData.push(getWrvuTrendExportDataWithDisplayedColumns(data.mergedProductivityTrend,
                this.displayedWRVUTrendColumns, data.productivitySummaryData,
                this.viewCommunityBenchmarks));
              analyticsEventNames.push('wRVUs Trend View');
            }
            break;
          case ExcelExportMetricId.NPV_TREND:
            if (!x.loading) {
              this.exportData.push(getCsvNpvTrendDataForDisplayedColumns(data.newPatientVisitTrendData,
                data.summaryNewPatientVisitData, this.displayedNpvTrendColumns, this.viewCommunityBenchmarks,
                this.npvBenchmarkOption));
              analyticsEventNames.push('NPV Trend View');
            }
            break;
          case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_FAMILY:
            if (this.showCfp && !this.isCfpLoading) {
              this.exportData.push(getCFCptFamilyExportData(
                data.clinicalSummaryConsolidationData,
                this.cfExportTitle + new Date().toLocaleString() + ';',
                this.suppressZeroes,
                this.viewCommunityBenchmarks,
                this.canShowAdditionalCfpColumns));
              analyticsEventNames.push('Clinical Fingerprint - CPT Family');
            }
            break;
          case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_RANGE:
            if (this.showCfp && !this.isCfpLoading) {
              this.exportData.push(getCFCptRangeExportData(
                data.clinicalSummaryConsolidationData,
                this.cfExportTitle + new Date().toLocaleString() + ';',
                this.suppressZeroes,
                this.viewCommunityBenchmarks,
                this.canShowAdditionalCfpColumns
              ));
              analyticsEventNames.push('Clinical Fingerprint - CPT Ranges');
            }
            break;
          case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_CODE:
            if (this.showCfp && !this.isCfpLoading) {
              this.exportData.push(getCFCptCodeExportData(
                data.clinicalSummaryConsolidationData,
                this.cfExportTitle + new Date().toLocaleString() + ';',
                this.suppressZeroes,
                this.viewCommunityBenchmarks,
                this.canShowAdditionalCfpColumns
              ));
              analyticsEventNames.push('Clinical Fingerprint - CPT Codes');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER:
            if (!x.loading) {
              this.exportData.push(getPayerCollectionsExportData(
                data.payerCollectionsData,
                this.displayedPayerCollectionsColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote
              ));
              analyticsEventNames.push('Collections by Payer');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_SUMMARY:
            if (!x.loading) {
              this.exportData.push(getSummaryCollectionsExportCsvData(
                data.summaryCollectionsData,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote
              ));
              analyticsEventNames.push('Collections Summary');
            }
            break;
          case ExcelExportMetricId.WRVU_BY_DEPARTMENT:
            if (!x.loading) {
              const showBenchmarks =
                !isFeatureEnabled(FeatureToggleEntries.HIDE_WRVU_BY_DEPARTMENT_BENCHMARK, data.featureToggleSettings, data.userProfile);
              this.exportData.push(getWrvuSnapshotExportDataWithDisplayedColumns(
                data.providerProductivityMultilevelData.departmentProductivities,
                convertWrvuSnapshotColumnsForDimensionRequirements(displayedWRVUSnapshotColumns.slice(0), LevelType.department)
                  .filter(col => !this.shouldHideCfteAdjWrvuDepartment || col.columnType !== ColumnType.CFTE_ADJ_WRVU),
                data.productivitySummaryData,
                'By Department',
                ExportType.csv,
                this.viewCommunityBenchmarks,
                LevelType.department,
                showBenchmarks,
                false,
                this.suppressZeroes,
                !this.shouldHideCfteAdjWrvuDepartment
              ));
              analyticsEventNames.push('wRVUs by Department');
            }
            break;
          case ExcelExportMetricId.WRVU_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(getWrvuSnapshotExportDataWithDisplayedColumns(
                data.providerProductivityMultilevelData.specialtyProductivities,
                convertWrvuSnapshotColumnsForDimensionRequirements(displayedWRVUSnapshotColumns.slice(0),
                  LevelType.specialty),
                data.productivitySummaryData,
                'By Specialty',
                ExportType.csv,
                this.viewCommunityBenchmarks,
                LevelType.specialty,
                true,
                false,
                this.suppressZeroes, true));
              analyticsEventNames.push('wRVUs by Specialty');
            }
            break;
          case ExcelExportMetricId.WRVU_BY_PROVIDER:
            const columns = displayedWRVUProviderColumns;
            if (!x.loading) {
              this.exportData.push(getWrvuSnapshotExportDataWithDisplayedColumns(
                data.providerProductivityMultilevelData.providerProductivities,
                convertWrvuSnapshotColumnsForDimensionRequirements(columns.slice(0),
                  LevelType.provider),
                data.productivitySummaryData,
                'By Provider',
                ExportType.csv,
                this.viewCommunityBenchmarks,
                LevelType.provider,
                true,
                false,
                this.suppressZeroes, true));
              analyticsEventNames.push('wRVUs by Provider');
            }
            break;
          case ExcelExportMetricId.NPV_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(getCsvNpvSnapshotDataForDisplayedColumns(
                data.newPatientVisitMultilevelData.departmentNpvSnapshotData,
                data.summaryNewPatientVisitData,
                this.displayedNpvByDepartmentColumns,
                'By Department',
                this.viewCommunityBenchmarks,
                MultilevelTab.BY_DEPARTMENT,
                this.npvBenchmarkOption,
                this.suppressZeroes));
              analyticsEventNames.push('NPV by Department');
            }
            break;
          case ExcelExportMetricId.NPV_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(getCsvNpvSnapshotDataForDisplayedColumns(
                data.newPatientVisitMultilevelData.specialtyNpvSnapshotData,
                data.summaryNewPatientVisitData,
                this.displayedNpvBySpecialtyColumns,
                'By Specialty',
                this.viewCommunityBenchmarks,
                MultilevelTab.BY_SPECIALTY,
                this.npvBenchmarkOption,
                this.suppressZeroes));
              analyticsEventNames.push('NPV by Specialty');
            }
            break;
          case ExcelExportMetricId.NPV_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(getCsvNpvSnapshotDataForDisplayedColumns(
                data.newPatientVisitMultilevelData.providerNpvSnapshotData,
                data.summaryNewPatientVisitData,
                this.displayedNpvByProviderColumns,
                'By Provider',
                this.viewCommunityBenchmarks,
                MultilevelTab.BY_PROVIDER,
                this.npvBenchmarkOption,
                this.suppressZeroes));
              analyticsEventNames.push('NPV by Provider');
            }
            break;
          case ExcelExportMetricId.NPV_BY_LOCATION_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(getCsvNpvSnapshotDataForDisplayedColumns(
                _.flatMap(data.mergedNpvLocationAggregatedByNode,
                  (locationData: MergedNpvLocationAggregatedByNode) => locationData.departmentNpvSnapshotData.map(depData => ({
                    ...depData,
                    memberLocationName: locationData.memberLocationName
                  }))
                ),
                data.summaryNewPatientVisitData,
                this.displayedNpvLocationDepartmentColumns,
                'By Location By Department',
                this.viewCommunityBenchmarks,
                MultilevelTab.LOCATION_DEPARTMENT,
                this.npvBenchmarkOption,
                this.suppressZeroes));
              analyticsEventNames.push('NPV by Location');
            }
            break;
          case ExcelExportMetricId.NPV_BY_LOCATION_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(getCsvNpvSnapshotDataForDisplayedColumns(
                _.flatMap(data.mergedNpvLocationAggregatedByNode,
                  (locationData: MergedNpvLocationAggregatedByNode) => locationData.specialtyNpvSnapshotData.map(specialtyData => ({
                    ...specialtyData,
                    memberLocationName: locationData.memberLocationName
                  }))
                ),
                data.summaryNewPatientVisitData,
                this.displayedNpvLocationSpecialtyColumns,
                'By Location By Specialty',
                this.viewCommunityBenchmarks,
                MultilevelTab.LOCATION_SPECIALTY,
                this.npvBenchmarkOption,
                this.suppressZeroes));
              analyticsEventNames.push('NPV by Location');
            }
            break;
          case ExcelExportMetricId.NPV_BY_LOCATION_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(getCsvNpvSnapshotDataForDisplayedColumns(
                _.flatMap(data.mergedNpvLocationAggregatedByNode,
                  (locationData: MergedNpvLocationAggregatedByNode) => locationData.providerNpvSnapshotData.map(providerData => ({
                    ...providerData,
                    memberLocationName: locationData.memberLocationName
                  }))
                ),
                data.summaryNewPatientVisitData,
                this.displayedNpvLocationProviderColumns,
                'By Location By Provider',
                this.viewCommunityBenchmarks,
                MultilevelTab.LOCATION_PROVIDER,
                this.npvBenchmarkOption,
                this.suppressZeroes));
              analyticsEventNames.push('NPV by Location');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(getCollectionsExportData(
                data.matchedCollectionsByMultiLevelByNodePathData,
                this.displayedCollectionsByDepartmentColumns,
                'By Department',
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote,
                MultilevelTabCollections.BY_DEPARTMENT,
                this.suppressZeroes
              ));
              analyticsEventNames.push('Collections by Department');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(getCollectionsExportData(
                data.matchedCollectionsByMultiLevelByNodePathData,
                this.displayedCollectionsBySpecialtyColumns,
                'By Specialty',
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote,
                MultilevelTabCollections.BY_SPECIALTY,
                this.suppressZeroes
              ));
              analyticsEventNames.push('Collections by Specialty');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(getCollectionsExportData(
                data.matchedCollectionsByMultiLevelByNodePathData,
                this.displayedCollectionsByProviderColumns,
                'By Provider',
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote,
                MultilevelTabCollections.BY_PROVIDER,
                this.suppressZeroes
              ));
              analyticsEventNames.push('Collections by Provider');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(getPayerCollectionsByLevelExportData(
                data.matchedCollectionsByPayerByMultiLevelByNodePathData,
                this.displayedCollectionsByPayerByDepartmentColumns,
                'By Department',
                MultilevelTabCollections.BY_DEPARTMENT,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote,
                30
              ));
              analyticsEventNames.push('Collections by Payer by Department');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(getPayerCollectionsByLevelExportData(
                data.matchedCollectionsByPayerByMultiLevelByNodePathData,
                this.displayedCollectionsByPayerBySpecialtyColumns,
                'By Specialty',
                MultilevelTabCollections.BY_SPECIALTY,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote,
                31
              ));
              analyticsEventNames.push('Collections by Payer by Specialty');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(getPayerCollectionsByLevelExportData(
                data.matchedCollectionsByPayerByMultiLevelByNodePathData,
                this.displayedCollectionsByPayerByProviderColumns,
                'By Provider',
                MultilevelTabCollections.BY_PROVIDER,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsCsvNote,
                32
              ));
              analyticsEventNames.push('Collections by Payer by Provider');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(
                getEmOutpatientByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_DEPARTMENT,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient by Department');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(
                getEmOutpatientByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_SPECIALTY,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient by Specialty');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(
                getEmOutpatientByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_PROVIDER,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient by Provider');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(
                getEmInpatientByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_DEPARTMENT,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient by Department');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(
                getEmInpatientByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_SPECIALTY,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient By Specialty');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(
                getEmInpatientByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_PROVIDER,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient by Provider');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(
                getEmEmergencyByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_DEPARTMENT,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine by Department');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(
                getEmEmergencyByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_SPECIALTY,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine by Specialty');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(
                getEmEmergencyByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_PROVIDER,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine by Provider');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT:
            if (!x.loading) {
              this.exportData.push(
                getEmEyeExamByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_DEPARTMENT,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes)
              );
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology by Department');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY:
            if (!x.loading) {
              this.exportData.push(
                getEmEyeExamByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_SPECIALTY,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology by Specialty');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER:
            if (!x.loading) {
              this.exportData.push(
                getEmEyeExamByLevelExportDataWithExtendedBenchmarkOptions(MultilevelTabCollections.BY_PROVIDER,
                  data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  isProviderSelected, this.emBenchmarkOption, this.suppressZeroes));
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology by Provider');
            }
            break;
          case ExcelExportMetricId.DENIALS_BY_DEPARTMENT:
            if (!x.loading) {
              const departmentDenials = getTheExcelDenialsSnapshotData(data.denialsByMultiLevelData.departmentDenials,
                columnsForMultiLevelDenialsDepartment.slice(), this.displayedDenialsByDepartmentColumns, 'department');
              this.exportData.push({
                data: departmentDenials.summaryData ? departmentDenials.summaryData.concat(['']).concat(departmentDenials.detailData) :
                  departmentDenials.detailData,
                headers: departmentDenials.summaryHeaders && departmentDenials.summaryHeaders.length > 0 ?
                  departmentDenials.summaryHeaders : departmentDenials.detailHeaders || [],
                fileName: departmentDenials.fileName,
                page: departmentDenials.page,
                title: departmentDenials.title,
                whatFilters: departmentDenials.whatFilters
              });
              analyticsEventNames.push('Denials by Department');
            }
            break;
          case ExcelExportMetricId.DENIALS_BY_SPECIALTY:
            if (!x.loading) {
              const specialtyDenials = getTheExcelDenialsSnapshotData(data.denialsByMultiLevelData.specialtyDenials,
                columnsForMultiLevelDenialsSpecialty.slice(), this.displayedDenialsBySpecialtyColumns, 'specialty');
              this.exportData.push({
                data: specialtyDenials.summaryData ? specialtyDenials.summaryData.concat(['']).concat(specialtyDenials.detailData) :
                  specialtyDenials.detailData,
                headers: specialtyDenials.summaryHeaders && specialtyDenials.summaryHeaders.length > 0 ? specialtyDenials.summaryHeaders :
                  specialtyDenials.detailHeaders || [],
                fileName: specialtyDenials.fileName,
                page: specialtyDenials.page,
                title: specialtyDenials.title,
                whatFilters: specialtyDenials.whatFilters
              });
              analyticsEventNames.push('Denials by Specialty');
            }
            break;
          case ExcelExportMetricId.DENIALS_BY_PROVIDER:
            if (!x.loading) {
              const providerDenials = getTheExcelDenialsSnapshotData(data.denialsByMultiLevelData.providerDenials,
                columnsForMultiLevelDenialsProvider.slice(), this.displayedDenialsByProviderColumns, 'provider');
              this.exportData.push({
                data: providerDenials.summaryData ? providerDenials.summaryData.concat(['']).concat(providerDenials.detailData) :
                  providerDenials.detailData,
                headers: providerDenials.summaryHeaders && providerDenials.summaryHeaders.length > 0 ? providerDenials.summaryHeaders :
                  providerDenials.detailHeaders || [],
                fileName: providerDenials.fileName,
                page: providerDenials.page,
                title: providerDenials.title,
                whatFilters: providerDenials.whatFilters
              });
              analyticsEventNames.push('Denials by Provider');
            }
            break;
          case ExcelExportMetricId.DENIALS_BY_PAYER:
            if (!x.loading) {
              const payerDenials = getTheExcelDenialsByPayerData(data.denials.denialRates, columnsForDenialsByPayer.slice(),
                this.displayedDenialsByPayerColumns);
              this.exportData.push({
                data: payerDenials.summaryData ? payerDenials.summaryData.concat(['']).concat(payerDenials.detailData) :
                  payerDenials.detailData,
                headers: payerDenials.summaryHeaders && payerDenials.summaryHeaders.length > 0 ? payerDenials.summaryHeaders :
                  payerDenials.detailHeaders || [],
                fileName: payerDenials.fileName,
                page: payerDenials.page,
                title: payerDenials.title,
                whatFilters: payerDenials.whatFilters
              });
              analyticsEventNames.push('Denials by Payer');
            }
            break;
          case ExcelExportMetricId.WRVU_SPECIALTY_PERFORMANCE:
            if (!x.loading) {
              this.exportData.push(getWrvuSnapshotExportDataWithDisplayedColumns(
                data.providerProductivityMultilevelData.providerProductivities,
                displayedWRVUSpecialtyPerformanceColumns,
                data.productivitySummaryData,
                'Specialty Performance',
                ExportType.csv,
                this.viewCommunityBenchmarks,
                LevelType.provider,
                true,
                true,
                this.suppressZeroes, true));
              analyticsEventNames.push('wRVUs Specialty Performance');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_TREND:
            if (!x.loading) {
              this.exportData.push(getEmTrendCsvDataWithExtendedBenchmarkOptions(EmDimension.Outpatient,
                data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                  ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderCsv,
                this.payerCategoryHeader, 'Outpatient', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient Trend');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_TREND:
            if (!x.loading) {
              this.exportData.push(getEmTrendCsvDataWithExtendedBenchmarkOptions(EmDimension.InPatient,
                data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                  ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderCsv,
                this.payerCategoryHeader, 'Inpatient', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient Trend');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND:
            if (!x.loading) {
              this.exportData.push(getEmTrendCsvDataWithExtendedBenchmarkOptions(EmDimension.EmergencyMedicine,
                data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                  ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderCsv,
                this.payerCategoryHeader, 'Emergency Medicine', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine Trend');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND:
            if (!x.loading) {
              this.exportData.push(getEmTrendCsvDataWithExtendedBenchmarkOptions(EmDimension.Ophthalmology,
                data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                  ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderCsv,
                this.payerCategoryHeader, 'Ophthalmology', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology Trend');
            }
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByDepartment,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('department'), CptViewType.CptFamily);
            analyticsEventNames.push('Procedure Summary by Department');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByDepartment,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('department'), CptViewType.CptRange);
            analyticsEventNames.push('Procedure Summary by Department');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByDepartment,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('department'), CptViewType.CptCode);
            analyticsEventNames.push('Procedure Summary by Department');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpBySpecialty,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('specialty'), CptViewType.CptFamily);
            analyticsEventNames.push('Procedure Summary by Specialty');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpBySpecialty,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('specialty'), CptViewType.CptRange);
            analyticsEventNames.push('Procedure Summary by Specialty');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpBySpecialty,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('specialty'), CptViewType.CptCode);
            analyticsEventNames.push('Procedure Summary by Specialty');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByProvider,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('provider'), CptViewType.CptFamily);
            analyticsEventNames.push('Procedure Summary by Provider');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByProvider,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('provider'), CptViewType.CptRange);
            analyticsEventNames.push('Procedure Summary by Provider');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE:
            this.prepareProcedureSummaryCsvDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByProvider,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('provider'), CptViewType.CptCode);
            analyticsEventNames.push('Procedure Summary by Provider');
            break;
        }
      });
    }

    function pause(msec: any) {
      return new Promise(
        (resolve, reject) => {
          setTimeout(resolve, msec || 1000);
        }
      );
    }

    let pageCount = 0;
    const emRegEx = new RegExp('EM.*');
    const npvRegEx = new RegExp('Npv.*');
    if (this.exportData) {
      for (const csvDatum of this.exportData) {
        if (csvDatum.page === 'Collections' || csvDatum.page.includes('Denials')) {
          this.csv.export(
            csvDatum.data,
            csvDatum.fileName,
            {
              fieldSeparator: '|',
              quoteStrings: '',
              headers: csvDatum.headers,
              title: csvDatum.title +
                '\n' + this.ngRedux.getState().data.selectedMemberData.memberDesc +
                '\n' + breadcrumbString +
                '\n' + this.selectedDateHeader +
                '\n' + this.memberLocationHeaderCsv +
                '\n' + this.lagPeriodHeader +
                '\n' + this.memberBillingAreaHeader +
                (csvDatum.page === 'Collections' ? '\n' +
                  '\n' + this.invoiceStatusHeader : '') +
                (csvDatum.whatFilters?.showPayer ? '\n' + getPayerHierarchyString(this.ngRedux.getState().filters) : ''),
              showTitle: (csvDatum.title !== undefined)
            });
        } else if (npvRegEx.test(csvDatum.page) || emRegEx.test(csvDatum.page)) {
          this.csv.export(
            csvDatum.data,
            csvDatum.fileName,
            {
              fieldSeparator: '|',
              quoteStrings: '',
              headers: csvDatum.headers,
              title: csvDatum.title +
                '\n' + this.ngRedux.getState().data.selectedMemberData.memberDesc +
                '\n' + breadcrumbString +
                '\n' + this.selectedDateHeader +
                '\n' + this.memberLocationHeaderCsv +
                (csvDatum.whatFilters?.showPayer ? '\n' + this.payerCategoryHeader : '') +
                '\n' + this.visitTypeHeader,
              showTitle: (csvDatum.title !== undefined)
            });
        } else if (!csvDatum.includesProcedureSummary) {
          this.csv.export(
            csvDatum.data,
            csvDatum.fileName,
            {
              fieldSeparator: '|',
              quoteStrings: '',
              headers: csvDatum.headers,
              title: csvDatum.title +
                '\n' + this.ngRedux.getState().data.selectedMemberData.memberDesc +
                '\n' + breadcrumbString +
                '\n' + this.selectedDateHeader +
                '\n' + this.memberLocationHeaderCsv +
                (csvDatum.whatFilters?.showPayer ? '\n' + this.payerCategoryHeader : ''),
              showTitle: (csvDatum.title !== undefined)
            });
        } else if (csvDatum.includesProcedureSummary) {
          const cptGroupBreadcrumb = this.composeCptGroupBreadcrumb();
          this.csv.export(
            csvDatum.data,
            csvDatum.fileName,
            {
              fieldSeparator: '|',
              quoteStrings: '',
              headers: csvDatum.headers,
              title: csvDatum.title +
                '\n' + this.ngRedux.getState().data.selectedMemberData.memberDesc +
                '\n' + breadcrumbString +
                '\n' + cptGroupBreadcrumb +
                '\n' + this.selectedDateHeader +
                '\n' + this.memberLocationHeaderCsv +
                (csvDatum.whatFilters?.showPayer ? '\n' + this.payerCategoryHeader : ''),
              showTitle: (csvDatum.title !== undefined)
            });
        }
        if (++pageCount >= 10) {
          await pause(1000);
          pageCount = 0;
        }
      }
      analyticsEventNames.forEach(eventName =>
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ExportData, 'CSV', eventName));
    }
  }

  exportToExcel() {
    const exportExcelData: ExportMetadata[] = [];
    const analyticsEventNames: string[] = [];
    const sheetNames: string[] = [];
    const downloadableMetrics = this.findSelectedAndDownloadableNodes(this.exportMetricOntology)
      .filter(x => (!this.isProviderSelected || x.text !== 'Snapshot View'));
    const {data, display} = this.ngRedux.getState();
    const {
      displayedWRVUSnapshotColumns,
      displayedWRVUSpecialtyPerformanceColumns
    } = display;
    const displayedWRVUByProviderColumns = display.displayedWRVUProviderColumns;
    if (downloadableMetrics) {
      _.orderBy(downloadableMetrics, ['id'], ['asc']).filter(metric => metric.displayed).forEach((x) => {
        switch (x.id) {
          case ExcelExportMetricId.WRVU_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('Wrvu By Department');
              exportExcelData.push(getWRVUSnapshotExcelData(
                data.providerProductivityMultilevelData.departmentProductivities,
                data.productivitySummaryData,
                displayedWRVUSnapshotColumns,
                this.wrvuExportTitle + new Date().toLocaleString() + ';',
                this.viewCommunityBenchmarks,
                LevelType.department,
                false,
                this.suppressZeroes,
                'By Department',
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                undefined,
                !isFeatureEnabled(FeatureToggleEntries.HIDE_WRVU_BY_DEPARTMENT_BENCHMARK, data.featureToggleSettings, data.userProfile),
                !this.shouldHideCfteAdjWrvuDepartment
              ));
              analyticsEventNames.push('wRVUs by Department');
            }
            break;
          case ExcelExportMetricId.WRVU_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('Wrvu By Specialty');
              exportExcelData.push(getWRVUSnapshotExcelData(
                data.providerProductivityMultilevelData.specialtyProductivities,
                data.productivitySummaryData,
                displayedWRVUSnapshotColumns,
                this.wrvuExportTitle + new Date().toLocaleString() + ';',
                this.viewCommunityBenchmarks,
                LevelType.specialty,
                false,
                this.suppressZeroes,
                'By Specialty',
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                undefined,
                true,
                true
              ));
              analyticsEventNames.push('wRVUs by Specialty');
            }
            break;
          case ExcelExportMetricId.WRVU_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('Wrvu By Provider');
              exportExcelData.push(getWRVUSnapshotExcelData(
                data.providerProductivityMultilevelData.providerProductivities,
                data.productivitySummaryData,
                displayedWRVUByProviderColumns,
                this.wrvuExportTitle + new Date().toLocaleString() + ';',
                this.viewCommunityBenchmarks,
                LevelType.provider,
                false,
                this.suppressZeroes,
                'By Provider',
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                undefined,
                true,
                true
              ));
              analyticsEventNames.push('wRVUs by Provider');
            }
            break;
          case ExcelExportMetricId.WRVU_SPECIALTY_PERFORMANCE:
            if (!x.loading) {
              sheetNames.push('wRVU Specialty Performance');
              exportExcelData.push(getWRVUSnapshotExcelData(
                data.providerProductivityMultilevelData.providerProductivities,
                data.productivitySummaryData,
                displayedWRVUSpecialtyPerformanceColumns,
                this.specialtyPerformanceExportTitle + new Date().toLocaleString() + ';',
                this.viewCommunityBenchmarks,
                LevelType.provider,
                true,
                this.suppressZeroes,
                'By Provider',
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                undefined,
                true,
                true
              ));
              analyticsEventNames.push('wRVUs Specialty Performance');
            }
            break;
          case ExcelExportMetricId.WRVU_TREND_VIEW:
            if (!x.loading) {
              sheetNames.push('WrvuTrend');
              exportExcelData.push(getWrvuTrendDataForDisplayedColumns(data.productivitySummaryData,
                data.mergedProductivityTrend,
                this.displayedWRVUTrendColumns,
                this.wrvuExportTitle + new Date().toLocaleString() + ';',
                this.viewCommunityBenchmarks,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel
              ));
              analyticsEventNames.push('wRVUs Trend View');
            }
            break;
          case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_FAMILY:
            if (this.showCfp && !this.isCfpLoading) {
              sheetNames.push('Cpt Family');
              exportExcelData.push(getCFCptFamilyData(
                data.clinicalSummaryConsolidationData,
                this.cfExportTitle + new Date().toLocaleString() + ';', this.viewCommunityBenchmarks,
                this.canShowAdditionalCfpColumns,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel
              ));
              analyticsEventNames.push('Clinical Fingerprint - CPT Family');
            }
            break;
          case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_RANGE:
            if (this.showCfp && !this.isCfpLoading) {
              sheetNames.push('Cpt Range');
              exportExcelData.push(getCFCptRangeData(
                data.clinicalSummaryConsolidationData,
                this.cfExportTitle + new Date().toLocaleString() + ';', this.viewCommunityBenchmarks,
                this.canShowAdditionalCfpColumns,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel
              ));
              analyticsEventNames.push('Clinical Fingerprint - CPT Ranges');
            }
            break;
          case ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_CODE:
            if (this.showCfp && !this.isCfpLoading) {
              sheetNames.push('Cpt Codes');
              exportExcelData.push(getCFCptCodeData(
                data.clinicalSummaryConsolidationData,
                this.cfExportTitle + new Date().toLocaleString() + ';', this.viewCommunityBenchmarks,
                this.canShowAdditionalCfpColumns,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel
              ));
              analyticsEventNames.push('Clinical Fingerprint - CPT Codes');
            }
            break;
          case ExcelExportMetricId.NPV_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('Npv By Department');
              exportExcelData.push(getExcelNpvSnapshotDataForDisplayedColumns(
                data.summaryNewPatientVisitData,
                data.newPatientVisitMultilevelData.departmentNpvSnapshotData,
                this.displayedNpvByDepartmentColumns,
                'By Department',
                this.viewCommunityBenchmarks,
                this.npvBenchmarkOption,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.payerCategoryHeader,
                this.visitTypeHeader));
              analyticsEventNames.push('NPV by Department');
            }
            break;
          case ExcelExportMetricId.NPV_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('Npv By Specialty');
              exportExcelData.push(getExcelNpvSnapshotDataForDisplayedColumns(
                data.summaryNewPatientVisitData,
                data.newPatientVisitMultilevelData.specialtyNpvSnapshotData,
                this.displayedNpvBySpecialtyColumns,
                'By Specialty',
                this.viewCommunityBenchmarks,
                this.npvBenchmarkOption,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.payerCategoryHeader,
                this.visitTypeHeader));
              analyticsEventNames.push('NPV by Specialty');
            }
            break;
          case ExcelExportMetricId.NPV_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('Npv By Provider');
              exportExcelData.push(getExcelNpvSnapshotDataForDisplayedColumns(
                data.summaryNewPatientVisitData,
                data.newPatientVisitMultilevelData.providerNpvSnapshotData,
                this.displayedNpvByProviderColumns,
                'By Provider',
                this.viewCommunityBenchmarks,
                this.npvBenchmarkOption,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.payerCategoryHeader,
                this.visitTypeHeader));
              analyticsEventNames.push('NPV by Provider');
            }
            break;
          case ExcelExportMetricId.NPV_TREND:
            if (!x.loading) {
              sheetNames.push('NpvTrend');
              exportExcelData.push(getExcelNpvTrendDataForDisplayedColumns(
                data.summaryNewPatientVisitData,
                data.newPatientVisitTrendData,
                this.displayedNpvTrendColumns,
                this.npvExportTitle + new Date().toLocaleString() + ';',
                this.viewCommunityBenchmarks, true,
                this.npvBenchmarkOption,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.payerCategoryHeader,
                this.visitTypeHeader));
              analyticsEventNames.push('NPV Trend View');
            }
            break;
          case ExcelExportMetricId.NPV_BY_LOCATION_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('NpvLocationDepartment');
              exportExcelData.push(generateOriginalExcelNpvLocationByDimensionDataForDisplayedColumns(data.summaryNewPatientVisitData,
                data.mergedNpvLocationAggregatedByNode, this.displayedNpvLocationDepartmentColumns,
                npvLocationDepartmentColumns().slice(), npvSummaryColumns.slice(),
                this.viewCommunityBenchmarks, MultilevelTab.LOCATION_DEPARTMENT,
                this.npvBenchmarkOption || BenchmarkOption.Academic, true,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.payerCategoryHeader,
                this.visitTypeHeader));
              analyticsEventNames.push('NPV by Location');
            }
            break;
          case ExcelExportMetricId.NPV_BY_LOCATION_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('NpvLocationSpecialty');
              exportExcelData.push(generateOriginalExcelNpvLocationByDimensionDataForDisplayedColumns(data.summaryNewPatientVisitData,
                data.mergedNpvLocationAggregatedByNode, this.displayedNpvLocationSpecialtyColumns,
                npvLocationSpecialtyColumns().slice(), npvSummaryColumns.slice(),
                this.viewCommunityBenchmarks, MultilevelTab.LOCATION_SPECIALTY,
                this.npvBenchmarkOption || BenchmarkOption.Academic, true,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.payerCategoryHeader,
                this.visitTypeHeader));
              analyticsEventNames.push('NPV by Location');
            }
            break;
          case ExcelExportMetricId.NPV_BY_LOCATION_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('NpvLocationProvider');
              exportExcelData.push(generateOriginalExcelNpvLocationByDimensionDataForDisplayedColumns(data.summaryNewPatientVisitData,
                data.mergedNpvLocationAggregatedByNode, this.displayedNpvLocationProviderColumns,
                npvLocationProviderColumns().slice(), npvSummaryColumns.slice(),
                this.viewCommunityBenchmarks, MultilevelTab.LOCATION_PROVIDER,
                this.npvBenchmarkOption || BenchmarkOption.Academic, true,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.payerCategoryHeader,
                this.visitTypeHeader));
              analyticsEventNames.push('NPV by Location');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER:
            if (!x.loading) {
              sheetNames.push('Collections by Payer Group');
              exportExcelData.push(getPayerCollectionsExcelData(
                data.payerCollectionsData,
                this.displayedPayerCollectionsColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters)
              ));
              analyticsEventNames.push('Collections by Payer');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_SUMMARY:
            if (!x.loading) {
              sheetNames.push('Collections by Summary');
              exportExcelData.push(getSummaryCollectionsExcelData(
                data.summaryCollectionsData,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters)
              ));
              analyticsEventNames.push('Collections Summary');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('Collections By Department');
              exportExcelData.push(getCollectionsExcelData(
                data.matchedCollectionsByMultiLevelByNodePathData,
                this.displayedCollectionsByDepartmentColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                'by Department',
                this.ngRedux.getState().filters.nodePath,
                MultilevelTabCollections.BY_DEPARTMENT,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters),
                this.suppressZeroes
              ));
              analyticsEventNames.push('Collections by Department');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('Collections by Specialty');
              exportExcelData.push(getCollectionsExcelData(
                data.matchedCollectionsByMultiLevelByNodePathData,
                this.displayedCollectionsBySpecialtyColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                'by Specialty',
                this.ngRedux.getState().filters.nodePath,
                MultilevelTabCollections.BY_SPECIALTY,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters),
                this.suppressZeroes
              ));
              analyticsEventNames.push('Collections by Specialty');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('Collections by Provider');
              exportExcelData.push(getCollectionsExcelData(
                data.matchedCollectionsByMultiLevelByNodePathData,
                this.displayedCollectionsByProviderColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                'by Provider',
                this.ngRedux.getState().filters.nodePath,
                MultilevelTabCollections.BY_PROVIDER,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters),
                this.suppressZeroes
              ));
              analyticsEventNames.push('Collections by Provider');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('Collections By Payer By Department');
              exportExcelData.push(getPayerByLevelCollectionsExcelData(
                data.matchedCollectionsByPayerByMultiLevelByNodePathData,
                this.displayedCollectionsByPayerByDepartmentColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                'by Department',
                MultilevelTabCollections.BY_DEPARTMENT,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters)
              ));
              analyticsEventNames.push('Collections by Payer by Department');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('Collections By Payer By Specialty');
              exportExcelData.push(getPayerByLevelCollectionsExcelData(
                data.matchedCollectionsByPayerByMultiLevelByNodePathData,
                this.displayedCollectionsByPayerBySpecialtyColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                'by Specialty',
                MultilevelTabCollections.BY_SPECIALTY,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters)
              ));
              analyticsEventNames.push('Collections by Payer by Specialty');
            }
            break;
          case ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('Collections By Payer by Provider');
              exportExcelData.push(getPayerByLevelCollectionsExcelData(
                data.matchedCollectionsByPayerByMultiLevelByNodePathData,
                this.displayedCollectionsByPayerByProviderColumns,
                this.collectionsExportTitle + new Date().toLocaleString() + '; ' + this.collectionsNote,
                'by Specialty',
                MultilevelTabCollections.BY_PROVIDER,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.lagPeriodHeader,
                this.memberBillingAreaHeader,
                this.invoiceStatusHeader,
                getPayerHierarchyString(this.ngRedux.getState().filters)
              ));
              analyticsEventNames.push('Collections by Payer by Provider');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('EM Outpatient By Department');
              exportExcelData.push(
                getEmOutpatientByDepartmentExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient by Department');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('EM Outpatient By Specialty');
              exportExcelData.push(getEmOutpatientBySpecialtyExportDataWithExtendedBenchmarkOptions
              (data.evaluationManagementMultilevelData,
                data.summaryEvaluationManagementData,
                this.emExportTitle + new Date().toLocaleString() + ';',
                this.emBenchmarkOption,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.visitTypeHeader));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient by Specialty');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('EM Outpatient By Provider');
              exportExcelData.push(getEmOutpatientByProviderExportDataWithExtendedBenchmarkOptions(
                data.evaluationManagementMultilevelData,
                data.summaryEvaluationManagementData,
                this.emExportTitle + new Date().toLocaleString() + ';',
                this.emBenchmarkOption,
                this.suppressZeroes,
                this.ngRedux.getState().filters.nodePath,
                this.selectedDateHeader,
                this.memberLocationHeaderExcel,
                this.visitTypeHeader));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient by Provider');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('EM Inpatient By Department');
              exportExcelData.push(
                getEMInpatientByDepartmentExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient by Department');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('EM Inpatient By Specialty');
              exportExcelData.push(
                getEMInpatientBySpecialtyExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient By Specialty');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('EM Inpatient By Provider');
              exportExcelData.push(
                getEMInpatientByProviderExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient by Provider');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('EM Emergency Medicine By Department');
              exportExcelData.push(
                getEMEmergencyByDepartmentExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine by Department');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('EM Emergency Medicine By Specialty');
              exportExcelData.push(
                getEMEmergencyBySpecialtyExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine by Specialty');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('EM Emergency Medicine By Provider');
              exportExcelData.push(
                getEMEmergencyByProviderExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine by Provider');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('EM Ophthalmology By Department');
              exportExcelData.push(
                getEMEyeExamByDepartmentExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology by Department');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('EM Ophthalmology By Specialty');
              exportExcelData.push(
                getEMEyeExamBySpecialtyExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology by Specialty');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('EM Ophthalmology By Provider');
              exportExcelData.push(
                getEMEyeExamByProviderExportDataWithExtendedBenchmarkOptions(data.evaluationManagementMultilevelData,
                  data.summaryEvaluationManagementData,
                  this.emExportTitle + new Date().toLocaleString() + ';',
                  this.emBenchmarkOption,
                  this.suppressZeroes,
                  this.ngRedux.getState().filters.nodePath,
                  this.selectedDateHeader,
                  this.memberLocationHeaderExcel,
                  this.visitTypeHeader));
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology by Provider');
            }
            break;
          case ExcelExportMetricId.EM_OUTPATIENT_TREND:
            if (!x.loading) {
              sheetNames.push('EM Outpatient Trend');
              exportExcelData.push(getEmTrendExcelDataWithExtendedBenchmarkOptions(EmDimension.Outpatient,
                data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                  ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderExcel,
                this.payerCategoryHeader, 'Outpatient', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Outpatient View - Outpatient Trend');
            }
            break;
          case ExcelExportMetricId.EM_INPATIENT_TREND:
            if (!x.loading) {
              sheetNames.push('EM Inpatient Trend');
              exportExcelData.push(getEmTrendExcelDataWithExtendedBenchmarkOptions(EmDimension.InPatient,
                data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                  ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderExcel,
                this.payerCategoryHeader, 'Inpatient', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Inpatient View - Inpatient Trend');
            }
            break;
          case ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND:
            if (!x.loading) {
              sheetNames.push('EM Emergency Medicine Trend');
              exportExcelData.push(
                getEmTrendExcelDataWithExtendedBenchmarkOptions(EmDimension.EmergencyMedicine,
                  data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                    ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                  this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderExcel,
                  this.payerCategoryHeader, 'Emergency Medicine', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Emergency Medicine View - Emergency Medicine Trend');
            }
            break;
          case ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND:
            if (!x.loading) {
              sheetNames.push('EM Ophthalmology Trend');
              exportExcelData.push(getEmTrendExcelDataWithExtendedBenchmarkOptions(EmDimension.Ophthalmology,
                data.evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly
                  ?.nodes || [], data.summaryEvaluationManagementData, this.emExportTitle, this.getExcelFileName(),
                this.ngRedux.getState().filters.nodePath, this.selectedDateHeader, this.memberLocationHeaderExcel,
                this.payerCategoryHeader, 'Ophthalmology', this.visitTypeHeader, this.emBenchmarkOption));
              analyticsEventNames.push('E&M - Ophthalmology View - Ophthalmology Trend');
            }
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY:
            sheetNames.push('Proc. Summary Family Dept');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByDepartment,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('department'),
              CptViewType.CptFamily));
            analyticsEventNames.push('Procedure Summary by Department');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE:
            sheetNames.push('Proc. Summary CptRange Dept');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByDepartment,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('department'), CptViewType.CptRange));
            analyticsEventNames.push('Procedure Summary by Department');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE:
            sheetNames.push('Proc. Summary CptCode Dept');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByDepartment,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('department'), CptViewType.CptCode));
            analyticsEventNames.push('Procedure Summary by Department');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY:
            sheetNames.push('Proc. Summary Family Spec');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpBySpecialty,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('specialty'), CptViewType.CptFamily));
            analyticsEventNames.push('Procedure Summary by Specialty');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE:
            sheetNames.push('Proc. Summary CptRange Spec');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpBySpecialty,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('specialty'), CptViewType.CptRange));
            analyticsEventNames.push('Procedure Summary by Specialty');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE:
            sheetNames.push('Proc. Summary CptCode Spec');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpBySpecialty,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('specialty'), CptViewType.CptCode));
            analyticsEventNames.push('Procedure Summary by Specialty');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY:
            sheetNames.push('Proc. Summary Family Prov');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByProvider,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('provider'), CptViewType.CptFamily));
            analyticsEventNames.push('Procedure Summary by Provider');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE:
            sheetNames.push('Proc. Summary CptRange Prov');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByProvider,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('provider'), CptViewType.CptRange));
            analyticsEventNames.push('Procedure Summary by Provider');
            break;
          case ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE:
            sheetNames.push('Proc. Summary CptCode Prov');
            exportExcelData.push(this.prepareExcelDataForExport(
              filterCfpDataBasedOnCptGroupAndDrill(this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByProvider,
                this.ngRedux.getState().display.procedureSummaryDrill, this.ngRedux.getState().display.selectedCptGroup),
              this.getProcedureSummaryDataExportConfig('provider'), CptViewType.CptCode));
            analyticsEventNames.push('Procedure Summary by Provider');
            break;
          case ExcelExportMetricId.DENIALS_BY_DEPARTMENT:
            if (!x.loading) {
              sheetNames.push('Denials By Department');
              const departmentDenials = getTheExcelDenialsSnapshotData(data.denialsByMultiLevelData.departmentDenials,
                columnsForMultiLevelDenialsDepartment.slice(), this.displayedDenialsByDepartmentColumns, 'department');
              departmentDenials.filterInfo = getFilterInfo(this.ngRedux.getState().filters.nodePath, this.selectedDateHeader,
                this.memberLocationHeaderExcel, this.lagPeriodHeader, this.memberBillingAreaHeader,
                undefined, getPayerHierarchyString(this.ngRedux.getState().filters));
              exportExcelData.push(departmentDenials);
              analyticsEventNames.push('Denials by Department');
            }
            break;
          case ExcelExportMetricId.DENIALS_BY_SPECIALTY:
            if (!x.loading) {
              sheetNames.push('Denials By Specialty');
              const specialtyDenials = getTheExcelDenialsSnapshotData(data.denialsByMultiLevelData.specialtyDenials,
                columnsForMultiLevelDenialsSpecialty.slice(), this.displayedDenialsBySpecialtyColumns, 'specialty');
              specialtyDenials.filterInfo = getFilterInfo(this.ngRedux.getState().filters.nodePath, this.selectedDateHeader,
                this.memberLocationHeaderExcel, this.lagPeriodHeader, this.memberBillingAreaHeader,
                undefined, getPayerHierarchyString(this.ngRedux.getState().filters));
              exportExcelData.push(specialtyDenials);
              analyticsEventNames.push('Denials by Specialty');
            }
            break;
          case ExcelExportMetricId.DENIALS_BY_PROVIDER:
            if (!x.loading) {
              sheetNames.push('Denials By Provider');
              // TODO this was borrowed from the refactor
              const providerDenials = getTheExcelDenialsSnapshotData(data.denialsByMultiLevelData.providerDenials,
                columnsForMultiLevelDenialsProvider.slice(), this.displayedDenialsByProviderColumns, 'provider');
              providerDenials.filterInfo = getFilterInfo(this.ngRedux.getState().filters.nodePath, this.selectedDateHeader,
                this.memberLocationHeaderExcel, this.lagPeriodHeader, this.memberBillingAreaHeader,
                undefined, getPayerHierarchyString(this.ngRedux.getState().filters));
              exportExcelData.push(providerDenials);
              analyticsEventNames.push('Denials by Provider');
            }
            break;
          case ExcelExportMetricId.DENIALS_BY_PAYER:
            if (!x.loading) {
              sheetNames.push('Denials By Payer');
              const payerDenials = getTheExcelDenialsByPayerData(data.denials.denialRates, columnsForDenialsByPayer.slice(),
                this.displayedDenialsByPayerColumns);
              payerDenials.filterInfo = getFilterInfo(this.ngRedux.getState().filters.nodePath, this.selectedDateHeader,
                this.memberLocationHeaderExcel, this.lagPeriodHeader, this.memberBillingAreaHeader,
                undefined, getPayerHierarchyString(this.ngRedux.getState().filters));
              exportExcelData.push(payerDenials);
              analyticsEventNames.push('Denials by Payer');
            }
            break;
        }
      });
    }
    if (sheetNames && sheetNames.length > 0) {
      this.excelExport = {
        data: exportExcelData,
        sheets: sheetNames,
        fileName: this.getExcelFileName()
      };
      let page = '';
      sheetNames.forEach((x) => {
        page = page + ' ' + x;
      });
      page = page.trim();
      analyticsEventNames.forEach(eventName =>
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ExportData, 'Excel', eventName));
      this.excelService.exportAsExcelFile(this.excelExport);
    }
  }

  exportToPdf() {

    const pdfExportData: PdfExportData = {
      wrvuTrend: false,
      wrvuSnapshot: false,
      npvTrend: false,
      npvSnapshot: false,
      emOutpatient: false,
      emInpatient: false,
      emEmergencyMedicine: false,
      emOphthalmology: false,
      overview: true,
      collections: false
    };

    const downloadableMetrics = this.pdfExportMetrics.filter(x => x.selected && x.downloadable);
    if (downloadableMetrics) {
      let page = '';
      const emDimensions: EmDimension[] = [];
      downloadableMetrics.forEach((x) => {
        page = page + ' ' + x.page;
      });
      page = page.trim();
      let dimensionSubstringForGa = '';
      _.orderBy(downloadableMetrics, ['id'], ['asc']).forEach((x) => {
        switch (x.id) {
          case 1:
            emDimensions.push(EmDimension.Outpatient);
            dimensionSubstringForGa = dimensionSubstringForGa.concat(' - E&M Outpatient');
            break;
          case 2:
            emDimensions.push(EmDimension.InPatient);
            dimensionSubstringForGa = dimensionSubstringForGa.concat(' - E&M Inpatient');
            break;
          case 3:
            emDimensions.push(EmDimension.EmergencyMedicine);
            dimensionSubstringForGa = dimensionSubstringForGa.concat(' - E&M Emergency Medicine');
            break;
          case 4:
            emDimensions.push(EmDimension.Ophthalmology);
            dimensionSubstringForGa = dimensionSubstringForGa.concat(' - E&M Ophthalmology');
            break;
          default:
            break;
        }
      });
      this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ExportData,
        'PDF', `Overview${dimensionSubstringForGa}`);
      this.data = pdfExportData;
      this.afterPdfExport.emit([pdfExportData, emDimensions, this.communityBenchmarkAlert]);
    }
  }

  updateExcelExportTypes(exportMetric: ExportOption, event?: MouseEvent) {
    event?.preventDefault();
    exportMetric.selected = !exportMetric.selected;
    this.selectOrDeselectParents(exportMetric);
    this.selectOrDeselectChildren(exportMetric);
    this.setShouldEnableButton();
  }

  private selectOrDeselectParents(currentExportMetric: ExportOption): void {
    const parentId = currentExportMetric.parentId;
    if (parentId || parentId === ExcelExportMetricId.ALL_METRICS) {
      const parent = this.excelExportMetrics[parentId];
      parent.selected = currentExportMetric.selected
        ? this.isAllChildrenSelected(parent)
        : false;

      this.selectOrDeselectParents(parent);
    }
  }

  private selectOrDeselectChildren(exportMetric: ExportOption): void {
    exportMetric.children.forEach(child => {
      if (child.displayed && !child.loading) {
        child.selected = exportMetric.selected;
        this.selectOrDeselectChildren(child);
      }
    });
  }

  isAllChildrenSelected(selectedExportMetric: ExportOption): boolean {
    return !selectedExportMetric.children.find(child => child.displayed && !child.selected);
  }

  buildBreadcrumbs(selectedPath: string, ontologyHierarchy: OntologyNode[], multipleProviders: boolean,
                   multipleSpecialties: boolean, currentNodeChildren: number): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = [];
    const paths: string[] = getFirstNodePathFrom(selectedPath).split('\\').slice(1); // remove leading empty string
    this.addParentToBreadcrumbs(paths, breadcrumbs, ontologyHierarchy, multipleProviders, multipleSpecialties, currentNodeChildren);
    return breadcrumbs;
  }

  getMultiLevelCollectionsLabel(tab: MultilevelTabCollections) {
    switch (tab) {
      case MultilevelTabCollections.BY_DEPARTMENT:
        return 'by Department';
      case MultilevelTabCollections.BY_SPECIALTY:
        return 'by Specialty';
      case MultilevelTabCollections.BY_PROVIDER:
        return 'by Provider';
      default:
        return 'by Department';
    }
  }

  private addParentToBreadcrumbs(nodeIds: string[], parentNodes: Breadcrumb[], ontologyHierarchy: OntologyNode[],
                                 multipleProviders: boolean, multipleSpecialties: boolean, currentNodeChildren: number) {
    const currentNode: OntologyNode | undefined = ontologyHierarchy.find(node => this.getNodeId(node) === nodeIds[0]);
    if (nodeIds.length === 1) {
      if (currentNode) {
        if (!(multipleProviders || multipleSpecialties) || currentNodeChildren > 0) {
          this.currentNodeName = currentNode.nodeName;
        } else if (parentNodes.length > 0) {
          this.currentNodeName = parentNodes[parentNodes.length - 1].nodeName;
          if (multipleProviders || multipleSpecialties) {
            parentNodes.pop();
          }
        }
      } else {
        this.currentNodeName = '';
      }
    } else if (nodeIds.length > 1) {
      if (currentNode) {
        parentNodes.push({nodeName: currentNode.nodeName, nodePath: currentNode.nodePath});
        this.addParentToBreadcrumbs(nodeIds.slice(1), parentNodes, currentNode.children,
          multipleProviders, multipleSpecialties, currentNodeChildren);
      }
    }
  }

  private getNodeId(node: OntologyNode | Breadcrumb): string {
    const paths: string[] = node.nodePath.split('\\');
    return paths[paths.length - 1];
  }

  private countChildNodes(ontologyNodes: OntologyNode[], selectedNodePath: string): number {
    for (let i = 0; i < ontologyNodes.length; i++) {
      if (ontologyNodes[i].children && ontologyNodes[i].children.length > 0) {
        if (ontologyNodes[i].nodePath.toLocaleLowerCase() === selectedNodePath.toLocaleLowerCase()) {
          return ontologyNodes[i].children.length;
        } else {
          const recur = this.countChildNodes(ontologyNodes[i].children, selectedNodePath);
          if (recur > 0) {
            return recur;
          }
        }
      }
    }
    return -1;
  }

  clearMetricsTypeSelections() {
    this.clearExportOptionSelection(this.exportMetricOntology);
    this.pdfExportMetrics.forEach((x: ExportMetric<number>) => x.selected = x.id === 0);
  }

  private clearExportOptionSelection(exportOptions: ExportOption[]): void {
    exportOptions.forEach(option => {
      option.selected = false;
      this.clearExportOptionSelection(option.children);
    });
  }

  getExcelFileName(): string {
    const member = this.ngRedux.getState().data.members.members.find(
      x => x.memberKey === this.ngRedux.getState().filters.memberKey);
    return member ? member.memberDesc : 'cpsc';
  }

  updatePdfExportTypes(exportMetric: ExportMetric<number>, event: MouseEvent) {
    event.preventDefault();
    exportMetric.selected = !exportMetric.selected;
  }

  private prepareExcelDataForExport(dataToExport: CfpByMultilevel[],
                                    dataExportConfig: DataExportConfig,
                                    cptViewType: CptViewType): ExportMetadata {
    const breadcrumbName = calculateBreadcrumbs(this.ngRedux.getState().filters.nodePath);
    const selectedGroup = this.ngRedux.getState().display.selectedCptGroup;
    const procedureSummaryDrill = this.ngRedux.getState().display.procedureSummaryDrill;
    const cptGroupingBreadcrumb = getCptGroupBreadcrumb(procedureSummaryDrill, selectedGroup, dataToExport);
    const filterInfo = [
      breadcrumbName, cptGroupingBreadcrumb,
      this.selectedDateHeader,
      this.memberLocationHeaderExcel
    ];
    const summaryData = this.getSummaryDataByCptViewType(dataToExport, cptViewType);
    const groupedTableData = this.getGroupedCpfByMultilevelTableData(dataToExport, dataExportConfig, cptViewType);
    return getExcelDataForProcedureSummary(
      groupedTableData, summaryData,
      this.viewCommunityBenchmarks, cptViewType, filterInfo,
      dataExportConfig, this.canShowCfteWrvu, this.canShowCfteCount);
  }

  private prepareProcedureSummaryCsvDataForExport(dataToExport: CfpByMultilevel[], dataExportConfig: DataExportConfig,
                                                  cptViewType: CptViewType): void {
    const groupedTableData = this.getGroupedCpfByMultilevelTableData(dataToExport, dataExportConfig, cptViewType);
    const summaryData = this.getSummaryDataByCptViewType(dataToExport, cptViewType);
    this.exportData.push(getCSVDataForProcedureSummary(
      groupedTableData, summaryData, this.viewCommunityBenchmarks, cptViewType,
      dataExportConfig, this.canShowCfteWrvu, this.canShowCfteCount));
  }

  private composeCptGroupBreadcrumb(): string {
    const selectedCptGroup = this.ngRedux.getState().display.selectedCptGroup;
    const procedureSummaryDrill = this.ngRedux.getState().display.procedureSummaryDrill;
    const cfpData = this.ngRedux.getState().data.clinicalFingerprintMultilevelData.cfpByDepartment;

    return getCptGroupBreadcrumb(procedureSummaryDrill, selectedCptGroup, cfpData);
  }

  private getSummaryDataByCptViewType(dataToExport: CfpByMultilevel[],
                                      cptViewType: CptViewType): CfpByMultilevel[] {
    let summaryData: CfpByMultilevel[] = [];
    switch (cptViewType) {
      case CptViewType.CptFamily:
        summaryData = groupMultilevelCPTDataByCPTField(dataToExport, 'cptFamilyDesc');
        break;
      case CptViewType.CptRange:
        summaryData = groupMultilevelCPTDataByCPTField(dataToExport, 'cptRangeDesc');
        break;
      case CptViewType.CptCode:
        summaryData = groupMultilevelCPTDataByCPTField(dataToExport, 'cptCode');
    }
    return summaryData;
  }

  private getGroupedCpfByMultilevelTableData(dataToExport: CfpByMultilevel[],
                                             dataExportConfig: DataExportConfig, cptViewType: CptViewType): GroupedCfpByMultilevel[] {
    let groupedTableData: GroupedCfpByMultilevel[];
    const groupedByNode: { [key: string]: CfpByMultilevel[] } = {};
    dataToExport.forEach(datum => {
      const nodePath: string = this.getNodePathByTabName(datum, dataExportConfig.tabName);
      groupedByNode[nodePath] = groupedByNode[nodePath] || [];
      groupedByNode[nodePath].push(datum);
    });

    groupedTableData = Object.keys(groupedByNode).map(nodePath => ({
      providerNodeName: groupedByNode[nodePath][0].providerNodeName,
      specialtyNodeName: groupedByNode[nodePath][0].specialtyNodeName,
      departmentNodeName: groupedByNode[nodePath][0].departmentNodeName,
      data: dataGroupedByCptViewType(groupedByNode[nodePath], cptViewType), itemsToShow: 0
    }));
    return groupedTableData;
  }

  private getNodePathByTabName(datum: CfpByMultilevel, tabName: any): string {
    switch (tabName) {
      case 'department':
        return datum.departmentNodePath;
      case 'specialty':
        return datum.specialtyNodePath;
      case 'provider':
        return datum.providerNodePath;
      default:
        return datum.providerNodePath;
    }
  }


  private getProcedureSummaryDataExportConfig(tabName: string): DataExportConfig {
    switch (tabName) {
      case 'department':
        return {
          fileName: 'ProcedureSummary By Department',
          sheetName: 'Procedure Summary By Department',
          page: 'ProcedureSummary By Department',
          tabName: 'department'
        };
      case 'specialty':
        return {
          fileName: 'ProcedureSummary By Specialty',
          sheetName: 'Procedure Summary By Specialty',
          page: 'ProcedureSummary By Specialty',
          tabName: 'specialty'
        };
      case 'provider':
        return {
          fileName: 'ProcedureSummary By Provider',
          sheetName: 'Procedure Summary By Provider',
          page: 'ProcedureSummary By Provider',
          tabName: 'provider'
        };
      default:
        return {
          fileName: 'ProcedureSummary By Provider',
          sheetName: 'Procedure Summary By Provider',
          page: 'ProcedureSummary By Provider',
          tabName: 'provider'
        };
    }
  }
}

export interface DataExportConfig {
  fileName: string;
  sheetName: string;
  page: string;
  tabName: string;
}
