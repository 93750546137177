import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {FilterCriteria} from '../store/IAppState';
import {PayerCategory, PayerCategoryLevel} from '../shared/models';
import {getRequestBodyFromFilter} from '../shared/helpers';

export const PayerCategoryServiceToken =
  new InjectionToken<PayerCategoryService>('Payer Category Service');

export interface PayerCategoryService {
  getPayerCategories(filter: FilterCriteria): Observable<PayerCategory[]>;

  getPayerHierarchy(memberKey: number): Observable<PayerCategoryLevel[]>;
}

@Injectable()
export class PayerCategoryServiceImpl implements PayerCategoryService {

  constructor(private readonly httpClient: HttpClient) {
  }

  getPayerCategories(filter: FilterCriteria): Observable<PayerCategory[]> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/payerCategories`;

    return this.httpClient.post<PayerCategory[]>(
      url,
      requestBody,
      {
        params:
          new HttpParams()
            .set('memberKey', filter.memberKey.toString())
      },
    );
  }

  getPayerHierarchy(memberKey: number): Observable<PayerCategoryLevel[]> {
    const url = `${environment.api}/api/payerHierarchy`;

    return this.httpClient.get<PayerCategoryLevel[]>(
      url,
      {
        params:
          new HttpParams()
            .set('memberKey', memberKey.toString())
      }
    );
  }
}
