import {Component, Input, OnChanges} from '@angular/core';
import {
  BenchmarkCombo,
  determineBenchmarkOptions,
  getBenchmarkOptionPlaceHolder,
  SchedulePane
} from '../mpe-dialog-helper';
import {BatchExportMetricSection} from '../../batch-export-helper';
import {benchmarkPercentileSeriesName} from '../../../shared/helpers';
import {BatchPdfOption} from '../../../shared/export/export';
import {DEFAULT_BENCHMARK_TYPE} from '../../../store/DefaultValues';
import {MetricType} from '../../../shared/metric-types';

@Component({
  selector: 'app-mpe-summary-pane',
  templateUrl: './mpe-summary-pane.component.html',
  styleUrls: ['./mpe-summary-pane.component.scss']
})
export class MpeSummaryPaneComponent implements OnChanges {

  @Input() schedulePaneInfo: SchedulePane;
  @Input() overviewMetricSection: BatchExportMetricSection;
  @Input() batchExportMetricSections: BatchExportMetricSection[] = [];
  @Input() numberOfProviders = 0;
  multiProviderExportOverviewPreviews: MultiProviderExportPreview[] = [];
  multiProviderExportMetricPreviews: MultiProviderExportPreview[] = [];
  numberOfFiles = 0;

  private static getOverviewMetricSelectedTiles(options: BatchPdfOption[]): BatchPdfOption[] {
    let overviewMetricSelectedTiles: BatchPdfOption[] = [];
    options.forEach(o => {
      if (o.selected) {
        overviewMetricSelectedTiles.push(o);
      }
      overviewMetricSelectedTiles = overviewMetricSelectedTiles.concat(
        MpeSummaryPaneComponent.getOverviewMetricSelectedTiles(o.children));
    });
    return overviewMetricSelectedTiles;
  }

  constructor() { }

  ngOnChanges(): void {
    this.multiProviderExportMetricPreviews = [{
      isMainSection: true,
      text: 'Selections',
      subOptions: [],
      benchmarkOption: 'Benchmark Type',
      benchmarkPercentile: 'Benchmark Percentile'
    }];
    const selectedOverviewSections: BatchPdfOption[] = MpeSummaryPaneComponent
      .getOverviewMetricSelectedTiles(this.overviewMetricSection.options);
    let firstSubsectionOptionConsidered = false;
    selectedOverviewSections.forEach(v => {
      this.multiProviderExportOverviewPreviews.push({
        isMainSection: v.children.length > 0,
        text: v.text,
        subOptions: [],
        benchmarkOption: v.benchmarkCombo ? getBenchmarkOptionPlaceHolder(v.benchmarkCombo.option,
          determineBenchmarkOptions(v.metric || MetricType.Overview)) : '',
        benchmarkPercentile: v.benchmarkCombo ? benchmarkPercentileSeriesName(v.benchmarkCombo.percentile ||
          DEFAULT_BENCHMARK_TYPE) : '',
        startOfSubsection: !firstSubsectionOptionConsidered && v.mandatoryIfParentIsSelected
      });
      if (v.mandatoryIfParentIsSelected) {
        firstSubsectionOptionConsidered = true;
      }
    });
    this.numberOfFiles = selectedOverviewSections.length > 0 ? this.numberOfProviders : 0;
    const selectedMetricSections: BatchExportMetricSection[] = this.batchExportMetricSections.filter(s => s.selected);
    this.numberOfFiles += selectedMetricSections.length * this.numberOfProviders;
    this.multiProviderExportMetricPreviews = this.multiProviderExportMetricPreviews.concat(selectedMetricSections.map(b => {
      return {
        isMainSection: true,
        text: b.text,
        subOptions: this.getSubOptions(b.options),
        benchmarkOption: b.benchmarkCombo ? getBenchmarkOptionPlaceHolder(b.benchmarkCombo.option,
          determineBenchmarkOptions(b.metric)) : '',
        benchmarkPercentile: b.benchmarkCombo ? benchmarkPercentileSeriesName(b.benchmarkCombo.percentile ||
          DEFAULT_BENCHMARK_TYPE) : ''
      };
    }));
  }

  private getSubOptions(options: BatchPdfOption[]): string[] {
    let subOptions: string[] = [];
    options.forEach(o => {
      if (o.selected && !o.children.length) {
        subOptions.push(o.text);
      }
      subOptions = subOptions.concat(this.getSubOptions(o.children));
    });
    return subOptions;
  }
}

export interface MultiProviderExportPreview {
  isMainSection: boolean;
  overviewSubMetricBenchmarkCombo?: BenchmarkCombo;
  text: string;
  subOptions: string[];
  benchmarkOption: string;
  benchmarkPercentile: string;
  startOfSubsection?: boolean;
}
