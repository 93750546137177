import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {zeroSuppressionChangedTo} from '../store/actions';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {getLevel, getLevelNameForZeroSuppression, getParentNodePath} from '../shared/helpers';

@Component({
  selector: 'app-zero-suppression-component',
  templateUrl: './zero-suppression-component.html',
  styleUrls: ['./zero-suppression-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZeroSuppressionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() countOfSuppressedEntries: number;
  @Input() isZeroSuppressionChecked = true;
  @Input() condition: string;
  @Input() multiLevelBreakdown: number;
  @Input() snapshotEntryName = '';

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['display', 'multipleProviders'])
  private readonly multipleProviders$: Observable<boolean>;

  @select(['display', 'multipleSpecialties'])
  private readonly multipleSpecialties$: Observable<boolean>;

  @select(['display', 'multipleDepartments'])
  private readonly multipleDepartments$: Observable<boolean>;

  hoverMessageText = '';
  shouldShowHoverMessage = false;
  subscription: Subscription;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.subscription = combineLatest([this.nodePath$, this.multipleProviders$, this.multipleSpecialties$,
      this.multipleDepartments$]).subscribe(([nodePath, multipleProviders,
                                               multipleSpecialties, multipleDepartments]
                                               : [string, boolean, boolean, boolean]) => {
      const multipleNodes = nodePath.indexOf('|') >= 1;
      this.setSnapshotEntryName(multipleNodes, nodePath, multipleProviders, multipleSpecialties, multipleDepartments);
      this.setHoverMessageText();
    });
  }

  private setSnapshotEntryName(multipleNodes: boolean, nodePath: string, multipleProviders: boolean,
                               multipleSpecialties: boolean, multipleDepartments: boolean) {
    this.snapshotEntryName = this.snapshotEntryName || getLevelNameForZeroSuppression(
      getLevel(this.ngRedux.getState().display.isCustom, !multipleNodes ? nodePath : getParentNodePath(nodePath), multipleProviders,
        multipleSpecialties, multipleDepartments, this.multiLevelBreakdown),
      this.countOfSuppressedEntries);
    const lowerCaseName = this.snapshotEntryName.toLowerCase();
    this.snapshotEntryName = lowerCaseName.includes('cpt') ?
      lowerCaseName.replace('cpt', 'CPT') : lowerCaseName;
  }

  ngOnChanges(): void {
    this.snapshotEntryName = this.snapshotEntryName?.toLowerCase();
    this.setHoverMessageText();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  suppressZeros() {
    if (this.isZeroSuppressionChecked) {
      this.analyticsService.handleGoogleAnalytics(
        'Zero Suppression', 'Un-applied', 'Un-applied Suppression');
    } else {
      this.analyticsService.handleGoogleAnalytics(
        'Zero Suppression', 'Applied', 'Applied Suppression');
    }
    this.isZeroSuppressionChecked = !this.isZeroSuppressionChecked;
    this.ngRedux.dispatch(zeroSuppressionChangedTo(this.isZeroSuppressionChecked));
  }

  toggleShowHoverMessage(shouldShow: boolean): void {
    this.shouldShowHoverMessage = shouldShow;
  }

  private setHoverMessageText() {
    this.hoverMessageText = `When zero suppression is applied, only ${this.snapshotEntryName} ` +
      `with ${this.condition} will be displayed. The ${this.snapshotEntryName} suppressed ` +
      `will be hidden from both the data table and the graph.`;
  }
}
