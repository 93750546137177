import {Component, Input, OnInit} from '@angular/core';
import {GraphType} from '../services/graph';
import {IAppState} from '../../store/IAppState';
import {combineLatest, Observable} from 'rxjs';
import {NgRedux, select} from '@angular-redux/store';
import {previousTimePeriodLegend, ProductivityGraphComponent} from '../productivity-graph-component';
import {toMonthName} from '../month-formatter';
import {isInvalidCommunityBenchmarkValue, MonthNumber, WrvuViewType} from '../../shared/helpers';
import {Variable} from '../../variable-container/variable-container.component';
import {Chart} from 'angular-highcharts';
import {Options} from 'highcharts/highstock';
import {updateCommunityBenchmarkAlertStatus} from '../../shared/reducer-helper';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {Legend, MergedProductivityTrendEntry} from '../../shared/models';
import {ChartType} from '../../shared/highcharts-helpers';
import {LegendColor, LegendStyle} from '../../shared/enums';
import {MetricType} from '../../shared/metric-types';
import {
  doesWrvuDataHaveValidAcademicBenchmarkValue,
  doesWrvuDataHaveValidCommunityBenchmarkValue
} from '../provider/wrvu-multilevel-chart/wrvu-multilevel-chart-helper';

@Component({
  selector: 'app-productivity-summary-by-month-chart',
  templateUrl: './productivity-summary-by-month-chart.component.html',
  styleUrls: ['./productivity-summary-by-month-chart.component.scss']
})
export class ProductivitySummaryByMonthChartComponent extends ProductivityGraphComponent implements OnInit {
  protected summaryType = GraphType.Month;

  legends: Legend[];

  actualWrvuLegend: Legend = {
    text: 'Actual wRVUs',
    color: LegendColor.TEAL,
    metric: MetricType.WorkRVUs,
    style: LegendStyle.CIRCLE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  };
  cfteWrvuLegend: Legend = {
    text: 'cFTE Adj. wRVUs',
    color: LegendColor.TEAL,
    metric: MetricType.WorkRVUs,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  };
  chargesLegend: Legend = {
    text: 'Charges',
    color: LegendColor.ORANGE,
    metric: MetricType.WorkRVUs,
    style: LegendStyle.CIRCLE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  };

  @Input() showProgressBar: boolean;
  @Input() level: string;
  @Input() page: string;
  @Input() showVariableMenu: boolean;
  @Input() variables: Variable[];
  @Input() isSpecialty: boolean;
  @Input() isDepartment: boolean;
  @Input() isProvider: boolean;

  @select(['data', 'mergedProductivityTrend'])
  private readonly monthProductivities$: Observable<MergedProductivityTrendEntry[]>;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['display', 'charges'])
  private readonly showChargeGraph$: Observable<boolean>;

  @select(['display', 'previousTimePeriod'])
  private readonly showPreviousTimePeriod$: Observable<boolean>;

  @select(['display', 'wrvuViewType'])
  private readonly wrvuViewType$: Observable<WrvuViewType>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  private readonly maxNumberOfDataPointsForScrollBar = 12;
  chartObject: Chart;
  options: Options;

  hasBenchmarks = true;
  showChargeGraph: boolean;
  viewCommunityBenchmarks: boolean;
  showPreviousTimePeriod: boolean;
  showCfteAdjusted: boolean;
  wRVUTitle: string;
  monthProductivity: MergedProductivityTrendEntry[];
  benchmarkPercentile: BenchmarkPercentile;
  hasCommunityBenchmarks: boolean;
  hasAcademicBenchmarks: boolean;


  constructor(private ngRedux: NgRedux<IAppState>) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.monthProductivities$,
      this.showChargeGraph$,
      this.showPreviousTimePeriod$,
      this.benchmarkPercentile$,
      this.wrvuViewType$,
      this.viewCommunityBenchmarks$
    ])
      .subscribe(([monthProductivity, showChargeGraph, showPreviousTimePeriod, benchmarkPercentile, wrvuViewType, viewCommunityBenchmarks]:
                    [MergedProductivityTrendEntry[], boolean, boolean, BenchmarkPercentile, WrvuViewType, boolean]) => {
        if (this.isImpactfullyChanged(benchmarkPercentile, showChargeGraph, viewCommunityBenchmarks, showPreviousTimePeriod,
          wrvuViewType, monthProductivity)) {
          this.setWrvuViewTypeDependentFields(wrvuViewType, viewCommunityBenchmarks, showPreviousTimePeriod);
          this.hasBenchmarks = viewCommunityBenchmarks
            ? doesWrvuDataHaveValidCommunityBenchmarkValue(monthProductivity)
            : doesWrvuDataHaveValidAcademicBenchmarkValue(monthProductivity);
          this.benchmarkPercentile = benchmarkPercentile;
          this.monthProductivity = monthProductivity;
          this.showPreviousTimePeriod = showPreviousTimePeriod;

          this.showChargeGraph = showChargeGraph;
          if (this.showChargeGraph) {
            this.legends.push(this.chargesLegend);
          }

          this.processBenchmarkData(monthProductivity);
          this.drawGraph();
        }
      });
  }

  private drawGraph() {
    this.options = this.getTrendGraphOptions(
      this.showChargeGraph,
      this.showPreviousTimePeriod,
      this.showCfteAdjusted,
      this.benchmarkPercentile,
      (entry: MergedProductivityTrendEntry) => toMonthName(<MonthNumber>entry.month.toString()).slice(0, 3)
        + '. ' + entry.year.toString(),
      this.monthProductivity,
      this.maxNumberOfDataPointsForScrollBar,
      ChartType.LINE,
      this.viewCommunityBenchmarks
    );

    this.chartObject = new Chart(this.options);
  }

  private setWrvuViewTypeDependentFields(wrvuViewType: WrvuViewType, viewCommunityBenchmarks: boolean, showPreviousTimePeriod: boolean) {
    wrvuViewType === WrvuViewType.CfteAdjusted ?
      this.setComponentFieldsForCfteAdjustedWrvus(viewCommunityBenchmarks, showPreviousTimePeriod) :
      this.setComponentFieldsForActualWrvus();
  }

  private processBenchmarkData(monthProductivity: MergedProductivityTrendEntry[]) {
    this.determineIfBenchmarksArePresent(monthProductivity);

    updateCommunityBenchmarkAlertStatus(
      this.hasCommunityBenchmarks,
      this.hasAcademicBenchmarks,
      this.ngRedux,
      this.showProgressBar
    );
  }

  private determineIfBenchmarksArePresent(monthProductivity: MergedProductivityTrendEntry[]) {
    this.determineIfAcademicBenchmarksArePresent(monthProductivity);
    this.determineIfCommunityBenchmarksArePresent(monthProductivity);
  }

  private determineIfCommunityBenchmarksArePresent(monthProductivity: MergedProductivityTrendEntry[]) {
    const communityBenchmarks = monthProductivity.filter(x => this.hasValidCommunityBenchmarkValues(x));
    this.hasCommunityBenchmarks = communityBenchmarks.length > 0;
  }

  private determineIfAcademicBenchmarksArePresent(monthProductivity: MergedProductivityTrendEntry[]) {
    const academicBenchmarks = monthProductivity.filter(x => this.hasValidAcademicBenchmarks(x));
    this.hasAcademicBenchmarks = academicBenchmarks.length > 0;
  }

  private setComponentFieldsForActualWrvus() {
    this.showCfteAdjusted = false;
    this.wRVUTitle = 'Actual wRVUs';
    this.legends = [this.actualWrvuLegend];
  }

  private setComponentFieldsForCfteAdjustedWrvus(viewCommunityBenchmarks: boolean, showPreviousTimePeriod: boolean) {
    this.showCfteAdjusted = true;
    this.wRVUTitle = 'cFTE Adj. wRVUs';
    this.viewCommunityBenchmarks = viewCommunityBenchmarks;
    this.legends = this.getLegends(showPreviousTimePeriod);
  }

  private getLegends(showPreviousTimePeriod: boolean) {
    const benchmarkLegend: Legend = {
      text: 'wRVU Benchmark:',
      color: LegendColor.GREY,
      metric: MetricType.WorkRVUs,
      style: LegendStyle.SQUARE,
      showBenchmarkOptionControl: true,
      showPercentileControl: true
    };
    return showPreviousTimePeriod ?
      [this.cfteWrvuLegend, previousTimePeriodLegend(this.ngRedux.getState().filters), benchmarkLegend]
      : [this.cfteWrvuLegend, benchmarkLegend];
  }

  private isImpactfullyChanged(benchmarkPercentile: BenchmarkPercentile, showChargeGraph: boolean,
                               viewCommunityBenchmarks: boolean, showPreviousTimePeriod: boolean,
                               wrvuViewType: WrvuViewType, monthProductivity: MergedProductivityTrendEntry[]) {
    return this.benchmarkPercentile !== benchmarkPercentile ||
      this.showChargeGraph !== showChargeGraph ||
      this.viewCommunityBenchmarks !== viewCommunityBenchmarks ||
      this.showPreviousTimePeriod !== showPreviousTimePeriod ||
      this.showCfteAdjusted !== (wrvuViewType === WrvuViewType.CfteAdjusted) ||
      JSON.stringify(this.monthProductivity) !== JSON.stringify(monthProductivity);
  }

  private hasValidAcademicBenchmarks(x: MergedProductivityTrendEntry) {
    return !(isInvalidCommunityBenchmarkValue(x.benchmarkMean)
      && isInvalidCommunityBenchmarkValue(x.benchmark90thPercentile)
      && isInvalidCommunityBenchmarkValue(x.benchmark75thPercentile)
      && isInvalidCommunityBenchmarkValue(x.benchmark65thPercentile)
      && isInvalidCommunityBenchmarkValue(x.benchmark50thPercentile)
      && isInvalidCommunityBenchmarkValue(x.benchmark25thPercentile));
  }

  private hasValidCommunityBenchmarkValues(x: MergedProductivityTrendEntry) {
    return !(isInvalidCommunityBenchmarkValue(x.communityBenchmarkMean)
      && isInvalidCommunityBenchmarkValue(x.communityBenchmark90thPercentile)
      && isInvalidCommunityBenchmarkValue(x.communityBenchmark75thPercentile)
      && isInvalidCommunityBenchmarkValue(x.communityBenchmark65thPercentile)
      && isInvalidCommunityBenchmarkValue(x.communityBenchmark50thPercentile)
      && isInvalidCommunityBenchmarkValue(x.communityBenchmark25thPercentile));
  }
}
