<div id="table-wrapper">
  <span id="title">{{title}} E&M Summary</span>
  <mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef id="{{column.columnDef}}Header" [ngClass]="getColumnHeaderClass(column)">
        <span class="cpt-range-label-wrapper">
          <span class="cpt-range-label">{{column.aboveHeaderText || ''}}</span>
        </span>
        <span [ngClass]="column.primaryColumn ? 'header-text' : 'bold-header-text'">{{column.header}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" id="{{column.columnDef}}" class="data-column" [ngClass]="getColumnClass(column)">
        <span class="item-container" *ngFor="let item of column.dataName(row); let i = index">{{item}}</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <ng-container><mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </ng-container>
  </mat-table>
</div>
