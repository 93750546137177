import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-post-mpe-download',
  templateUrl: './post-mpe-download.component.html',
  styleUrls: ['./post-mpe-download.component.scss']
})
export class PostMpeDownloadComponent {

  constructor(private dialogRef: MatDialogRef<PostMpeDownloadComponent>) { }
  closePostDownloadPopup(): void {
    this.dialogRef.close();
  }
}
