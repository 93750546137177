import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {FeatureToggleSetting} from '../shared/models';

export const FeatureToggleServiceToken = new InjectionToken<FeatureToggleService>('FeatureToggleService');

export interface FeatureToggleService {
  getFeatureToggleSettings(): Observable<FeatureToggleSetting[]>;
  getBatchExportFeatureToggleSettings(): Observable<FeatureToggleSetting[]>;
  updateInternalFeature(featureSetting: FeatureToggleSetting): Observable<FeatureToggleSetting>;
  updateExternalFeature(featureSetting: FeatureToggleSetting): Observable<FeatureToggleSetting>;
  addFeature(featureToggleSetting: FeatureToggleSetting): Observable<FeatureToggleSetting>;
}

@Injectable()
export class HttpFeatureToggleService implements FeatureToggleService {

  constructor(private readonly httpClient: HttpClient) { }

  public getFeatureToggleSettings(): Observable<FeatureToggleSetting[]> {
    return this.httpClient.get<FeatureToggleSetting[]>(`${environment.api}/api/featureToggleSettings`);
  }

  public getBatchExportFeatureToggleSettings(): Observable<FeatureToggleSetting[]> {
    return this.httpClient.get<FeatureToggleSetting[]>(`${environment.api}/batchExport/featureToggleSettings`);
  }

  public updateInternalFeature(featureSetting: FeatureToggleSetting): Observable<FeatureToggleSetting> {
    return this.httpClient.put<FeatureToggleSetting>(
      `${environment.api}/api/toggleInternalFeature`, featureSetting);
  }

  public updateExternalFeature(featureSetting: FeatureToggleSetting): Observable<FeatureToggleSetting> {
    return this.httpClient.put<FeatureToggleSetting>(
      `${environment.api}/api/toggleExternalFeature`, featureSetting);
  }

  public addFeature(featureToggleSetting: FeatureToggleSetting): Observable<FeatureToggleSetting> {
    return this.httpClient.post<FeatureToggleSetting>(
      `${environment.api}/api/featureToggleSettings`, featureToggleSetting);
  }
}
