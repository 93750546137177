<div id="multilevel-wrapper">
  <div id="tab-navigator">
    <a id="bySummaryTab"  [ngClass]="{active: chosenTab === 0}" (click)="chooseTab(0)">Summary</a>
    <a id="byDepartmentTab" [ngClass]="{active: chosenTab === 1}" (click)="chooseTab(1)">By Department</a>
    <a id="bySpecialtyTab"
       [ngClass]="{active: chosenTab === 2, disabled: !specialtyPermitted, enabled: specialtyPermitted}"
       (click)="chooseTab(2)"
       [matTooltip]="toolTipMessage(1) || ''" [matTooltipDisabled]="specialtyPermitted"
       [matTooltipPosition]="'above'"
    >By Specialty</a>
    <a id="byProviderTab" [ngClass]="{active: chosenTab === 3, disabled: !providerPermitted, enabled: providerPermitted}"
       [matTooltip]="toolTipMessage(2) || ''" [matTooltipDisabled]="providerPermitted"
       [matTooltipPosition]="'above'" (click)="chooseTab(3)"
    >By Provider</a>
    <a class="byPayerTab"  [ngClass]="{active: [4, 5, 6, 7].includes(chosenTab)}"
       (click)=chooseTab(4)
    >By Payer</a>
  </div>
</div>
<div [ngSwitch]="chosenTab">
  <div *ngSwitchCase="0">
    <app-summary-collections class="no-yaxis-labels" [isMultilevel]="true"></app-summary-collections>
  </div>
  <div *ngSwitchCase="1">
    <app-page-department
      [collectionsMultiLevel]="departmentCollectionsSnapshotData"
      [benchmarkPercentile]="benchmarkPercentile"
      [isZeroSuppressed]="isZeroSuppressed"
      [countOfSuppressedEntries]="countOfSuppressedDepartmentEntries"
      [nodePath]="nodePath"
      [isCustom]="isCustom"
      [columns]="departmentColumns"
      [displayedColumns]="displayedDepartmentColumns"
      [columnHeaders]="columnHeaders"
      [activeVarianceToggle]="activeVariance"
      [collectionsSortingCriteria]="collectionsSnapshotSortingCriteria"
      [reducerAction]="reducerAction"
    ></app-page-department>
  </div>
  <div *ngSwitchCase="2">
    <app-page-specialty
      [collectionsMultiLevel]="specialtyCollectionsSnapshotData"
      [benchmarkPercentile]="benchmarkPercentile"
      [isZeroSuppressed]="isZeroSuppressed"
      [countOfSuppressedEntries]="countOfSuppressedSpecialtiesEntries"
      [nodePath]="nodePath"
      [isCustom]="isCustom"
      [columns]="specialtyColumns"
      [displayedColumns]="displayedSpecialtyColumns"
      [columnHeaders]="columnHeaders"
      [activeVarianceToggle]="activeVariance"
      [collectionsSortingCriteria]="collectionsSnapshotSortingCriteria"
      [reducerAction]="reducerAction"
    ></app-page-specialty>
  </div>
  <div *ngSwitchCase="3">
    <app-page-provider
      [collectionsMultiLevel]="providerCollectionsSnapshotData"
      [benchmarkPercentile]="benchmarkPercentile"
      [isZeroSuppressed]="isZeroSuppressed"
      [countOfSuppressedEntries]="countOfSuppressedProviderEntries"
      [nodePath]="nodePath"
      [isCustom]="isCustom"
      [columns]="providerColumns"
      [displayedColumns]="displayedProviderColumns"
      [columnHeaders]="columnHeaders"
      [activeVarianceToggle]="activeVariance"
      [collectionsSortingCriteria]="collectionsSnapshotSortingCriteria"
      [reducerAction]="reducerAction"
    ></app-page-provider>
  </div>
</div>
<div *ngIf="[4, 5, 6, 7].includes(chosenTab)">
  <app-payer-collections-multilevel
    [activeVarianceToggle]="activeVariance"
    [collectionsSortingCriteria]="collectionsSnapshotSortingCriteria"
    [isMultiLevel]="true"
  ></app-payer-collections-multilevel>
</div>
