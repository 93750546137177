import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';
import {getEmLegends} from '../../em-chart-helpers';
import {CptCategories, CptSummary, Legend} from '../../../shared/models';
import {MetricType} from '../../../shared/metric-types';
import {isFeatureEnabled} from '../../../shared/helpers';
import {FeatureToggleEntries} from '../../../shared/feature-toggle-settings-enum';
import {
  getDefaultCptSummary,
  getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions,
  NEW_PATIENT_CPT_CODES,
  NEW_PATIENT_CPT_CODES_UPDATED
} from '../../em-helpers';
import {BenchmarkOption} from '../../../shared/enums';
import {IAppState} from '../../../store/IAppState';

@Component({
  selector: 'app-em-outpatient-multilevel-charts',
  templateUrl: './em-outpatient-multilevel-charts.component.html',
  styleUrls: ['./em-outpatient-multilevel-charts.component.scss']
})
export class EmOutpatientMultilevelChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() totalCptCounts?: CptCategories;
  @Input() showProgressBar: boolean;
  @Input() specialtyCptCounts?: CptCategories;
  @Input() isSingleProviderSelected: boolean;
  @Input() chartWidth: number;
  @Input() chartHeight: number;
  @Input() overviewPageView: boolean;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() fromPdf: boolean;

  @select(['display', 'showUpdatedCptCodes']) private readonly showUpdatedCptCodes$: Observable<boolean>;

  newPatientVisitsCategories: string[] = NEW_PATIENT_CPT_CODES;
  establishedPatientVisitsCategories = ['99211', '99212', '99213', '99214', '99215'];
  consultationsCategories = ['99241', '99242', '99243', '99244', '99245'];

  totalNewPatientsCptSummaries: (CptSummary | undefined)[];
  totalEstablishedPatientsCptSummaries: (CptSummary | undefined)[];
  totalConsultationsCptSummaries: (CptSummary | undefined)[];
  specialtyTotalNewPatientsCptSummaries: (CptSummary | undefined)[];
  specialtyTotalEstablishedPatientsCptSummaries: (CptSummary | undefined)[];
  specialtyTotalConsultationsCptSummaries: (CptSummary | undefined)[];

  legends: Legend[];
  subscription: Subscription;
  maxNewPatientYAxisValue: number;
  maxEstablishedPatientPatientYAxisValue: number;
  maxConsultationsYAxisValue: number;
  overviewPageChartHeight = 250;
  newPatientsMaxValue = 0;
  establishedPatientsMaxValue = 0;
  consultationsMaxValue = 0;
  specialtyNewPatientsMaxValue = 0;
  specialtyEstablishedPatientsMaxValue = 0;
  specialtyConsultationsMaxValue = 0;
  showUpdatedCptCodes = false;
  metricType = MetricType.EandMOutpatient;

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit(): void {
    const data = this.ngRedux.getState().data;
    if (isFeatureEnabled(FeatureToggleEntries.SHOW_UPDATED_CPT_CODES, data.featureToggleSettings, data.userProfile)) {
      this.subscription = this.showUpdatedCptCodes$.subscribe(showUpdatedCptCodes => {
        this.showUpdatedCptCodes = showUpdatedCptCodes;
        this.newPatientVisitsCategories = this.showUpdatedCptCodes ? NEW_PATIENT_CPT_CODES_UPDATED : NEW_PATIENT_CPT_CODES;
        this.ngOnChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(): void {
    this.chartHeight = this.overviewPageView ? this.overviewPageChartHeight : this.chartHeight;
    this.legends = getEmLegends(this.foundNodeName, this.isSingleProviderSelected);

    if (this.totalCptCounts) {
      this.specialtyCptCounts ? this.processSpecialtyCountTotals() : this.createBlankSpecialtyCptCounts();
      this.processCountTotals();
      this.setYAxesMaxValues();
    } else {
      this.createBlankCptSummaries();
    }
  }

  private processCountTotals() {
    this.processNewPatientVisitTotals();
    this.processEstablishedPatientVisitTotals();
    this.processConsultationTotals();
  }

  private processSpecialtyCountTotals() {
    this.processSpecialtyNewPatientVisitTotals();
    this.processSpecialtyEstablishedPatientVisitTotals();
    this.processSpecialtyConsultationTotals();
  }

  private processSpecialtyConsultationTotals() {
    if (this.specialtyCptCounts?.consultation) {
      this.updateSpecialtyTotalConsultationsCptSummaries();
      this.specialtyConsultationsMaxValue = this.calculateMaxYValue(this.specialtyTotalConsultationsCptSummaries);
    }
  }

  private processConsultationTotals() {
    if (this.totalCptCounts?.consultation) {
      this.updateTotalConsultationsCptSummaries();
      this.consultationsMaxValue = this.calculateMaxYValue(this.totalConsultationsCptSummaries);
    }
  }

  private processSpecialtyEstablishedPatientVisitTotals() {
    if (this.specialtyCptCounts?.establishedPatientVisit) {
      this.updateSpecialtyTotalEstablishedPatientsCptSummaries();
      this.specialtyEstablishedPatientsMaxValue = this.calculateMaxYValue(this.specialtyTotalEstablishedPatientsCptSummaries);
    }
  }

  private processEstablishedPatientVisitTotals() {
    if (this.totalCptCounts?.establishedPatientVisit) {
      this.updateTotalEstablishedPatientsCptSummaries();
      this.establishedPatientsMaxValue = this.calculateMaxYValue(this.totalEstablishedPatientsCptSummaries);
    }
  }

  private processSpecialtyNewPatientVisitTotals() {
    if (this.specialtyCptCounts?.newPatientVisit) {
      this.updateSpecialtyTotalNewPatientsCptSummaries();
      this.specialtyNewPatientsMaxValue = this.calculateMaxYValue(this.specialtyTotalNewPatientsCptSummaries);
    }
  }

  private processNewPatientVisitTotals() {
    if (this.totalCptCounts?.newPatientVisit) {
      this.updateTotalNewPatientsCptSummaries();
      this.newPatientsMaxValue = this.calculateMaxYValue(this.totalNewPatientsCptSummaries);
    }
  }

  private setYAxesMaxValues() {
    this.maxNewPatientYAxisValue = Math.max(this.newPatientsMaxValue, this.specialtyNewPatientsMaxValue);
    this.maxEstablishedPatientPatientYAxisValue = Math.max(this.establishedPatientsMaxValue, this.specialtyEstablishedPatientsMaxValue);
    this.maxConsultationsYAxisValue = Math.max(this.consultationsMaxValue, this.specialtyConsultationsMaxValue);
  }

  private calculateMaxYValue(summaries: (CptSummary | undefined)[]) {
    return getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(summaries, this.benchmarkOption);
  }

  private updateSpecialtyTotalConsultationsCptSummaries() {
    this.specialtyTotalConsultationsCptSummaries = [
      this.specialtyCptCounts?.consultation.cpt99241,
      this.specialtyCptCounts?.consultation.cpt99242,
      this.specialtyCptCounts?.consultation.cpt99243,
      this.specialtyCptCounts?.consultation.cpt99244,
      this.specialtyCptCounts?.consultation.cpt99245
    ];
  }

  private updateTotalConsultationsCptSummaries() {
    this.totalConsultationsCptSummaries = [
      this.totalCptCounts?.consultation.cpt99241,
      this.totalCptCounts?.consultation.cpt99242,
      this.totalCptCounts?.consultation.cpt99243,
      this.totalCptCounts?.consultation.cpt99244,
      this.totalCptCounts?.consultation.cpt99245
    ];
  }

  private updateSpecialtyTotalEstablishedPatientsCptSummaries() {
    this.specialtyTotalEstablishedPatientsCptSummaries = [
      this.specialtyCptCounts?.establishedPatientVisit.cpt99211,
      this.specialtyCptCounts?.establishedPatientVisit.cpt99212,
      this.specialtyCptCounts?.establishedPatientVisit.cpt99213,
      this.specialtyCptCounts?.establishedPatientVisit.cpt99214,
      this.specialtyCptCounts?.establishedPatientVisit.cpt99215
    ];
  }

  private updateTotalEstablishedPatientsCptSummaries() {
    this.totalEstablishedPatientsCptSummaries = [
      this.totalCptCounts?.establishedPatientVisit.cpt99211,
      this.totalCptCounts?.establishedPatientVisit.cpt99212,
      this.totalCptCounts?.establishedPatientVisit.cpt99213,
      this.totalCptCounts?.establishedPatientVisit.cpt99214,
      this.totalCptCounts?.establishedPatientVisit.cpt99215
    ];
  }

  private updateSpecialtyTotalNewPatientsCptSummaries() {
    this.specialtyTotalNewPatientsCptSummaries = [
      ...this.showUpdatedCptCodes ? [] : [this.specialtyCptCounts?.newPatientVisit.cpt99201],
      this.specialtyCptCounts?.newPatientVisit.cpt99202,
      this.specialtyCptCounts?.newPatientVisit.cpt99203,
      this.specialtyCptCounts?.newPatientVisit.cpt99204,
      this.specialtyCptCounts?.newPatientVisit.cpt99205
    ];
  }

  private updateTotalNewPatientsCptSummaries() {
    this.totalNewPatientsCptSummaries = [
      ...this.showUpdatedCptCodes ? [] : [this.totalCptCounts?.newPatientVisit.cpt99201],
      this.totalCptCounts?.newPatientVisit.cpt99202,
      this.totalCptCounts?.newPatientVisit.cpt99203,
      this.totalCptCounts?.newPatientVisit.cpt99204,
      this.totalCptCounts?.newPatientVisit.cpt99205
    ];
  }

  private createBlankSpecialtyCptCounts() {
    this.specialtyTotalNewPatientsCptSummaries = this.newPatientVisitsCategories.map(getDefaultCptSummary);
    this.specialtyTotalEstablishedPatientsCptSummaries = this.establishedPatientVisitsCategories.map(getDefaultCptSummary);
    this.specialtyTotalConsultationsCptSummaries = this.consultationsCategories.map(getDefaultCptSummary);

    this.specialtyNewPatientsMaxValue = 0;
    this.specialtyEstablishedPatientsMaxValue = 0;
    this.consultationsMaxValue = 0;
  }

  private createBlankCptSummaries() {
    this.totalNewPatientsCptSummaries = this.newPatientVisitsCategories.map(getDefaultCptSummary);
    this.totalEstablishedPatientsCptSummaries = this.establishedPatientVisitsCategories.map(getDefaultCptSummary);
    this.totalConsultationsCptSummaries = this.consultationsCategories.map(getDefaultCptSummary);
  }
}
