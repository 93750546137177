<div id="month-graph-wrapper">
  <div class="chart-header">
    <div class="head">
      <span class="head-title">{{wRVUTitle}} By Month</span><br>
    </div>
    <span class="right-header-wrapper">
      <app-toggle-cfte-actual-wrvus></app-toggle-cfte-actual-wrvus>
      <app-variable-container
        [showVariableMenu]="showVariableMenu"
        [variables]="variables"
        [page]="page">
      </app-variable-container>
    </span>
  </div>

  <div [chart]="chartObject" id="month-graph"></div>
  <app-chart-legend [legends]="legends"
                    [hasBenchmarks]="hasBenchmarks">
  </app-chart-legend>

  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
</div>
