<div id="chart-and-legend">
  <app-loader *ngIf="showLoadingIndicator"></app-loader>
    <div id="chart-wrapper">
      <div id="title">Evaluation & Management by Month
        <app-em-trend-cpt-range-select [viewType]="viewType" [viewTypeList]="viewTypeList"
          [onViewTypeSelected]="onViewTypeSelected" [fromPdf]="fromPdf">
        </app-em-trend-cpt-range-select>
      </div>
      <app-em-trend-cpt-code-select *ngIf="!fromPdf" [selectedCpt]="selectedCpt"
          [cptCodeSelections]="cptCodeSelections" [onCptSelected]="onCptSelected"></app-em-trend-cpt-code-select>
      <div [chart]="chartObject"></div>
    </div>
    <span id="legend-wrapper">
      <span id="legend">
        <span *ngFor="let legend of legendLabels; let i = index" class="label">{{legend}}
          <i class="fa fa-circle" [style]="'color: '.concat(stackColorWheel[i])"></i></span>
      </span>
      <span id="benchmark-container">
        <app-legend-benchmark-option *ngIf="!fromPdf" [metric]="MetricType.EandM"></app-legend-benchmark-option>
        <span id="benchmark-label"><span *ngIf="fromPdf">{{legendBenchmarkPdfLabel}} CPSC</span> Benchmark Mean</span>
      </span>
    </span>
</div>
