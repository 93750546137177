
<div class="chart-wrapper">
  <div class="chart-title-wrapper">
    <div class="chart-title-text">Net Collection Rate</div>
    <div class="sub-title-wrapper">
      <span id="sub-title-left">Net Collection Rate:</span>
      <span id="sub-title-right">{{netCollectionRate}}</span>
    </div>
    <div id="static-note-container">
      Note: These are based on charge entry (Invoice creation) date</div>
  </div>
  <div [chart]="chartObject" id="collections-summary-graph"></div>
  <app-loader  [class.loader-hidden]="!showProgressBar"></app-loader>
</div>
