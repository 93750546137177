<span id="batch-npv-summary-table-wrapper">
  <span id="npv-batch-summary-table-title">New Patient Visits Summary Metrics</span>
  <mat-table [dataSource]="dataSource" id="batch-npv-summary-table">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef id="{{column.columnDef}}"><span class="header-text">{{column.header}}</span>
        <span class="date-range">{{column.upperHeader}}</span></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container><div class="data-text {{column.columnDef}}">{{column.dataName(row)}}</div>
        </ng-container>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <ng-container><mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </ng-container>
  </mat-table>
</span>
