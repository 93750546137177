
<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="departmentNodeName">
    <mat-header-cell *matHeaderCellDef class="headerProvider">Provider / Specialty / Department</mat-header-cell>
    <mat-cell *matCellDef="let row" class="provider">
      {{row.providerNodeName + ' / '}}<br/>{{row.specialtyNodeName + ' / '}}<br/>{{row.departmentNodeName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="encounter">
    <mat-header-cell *matHeaderCellDef class="headerEncounter">Metrics</mat-header-cell>
    <mat-cell *matCellDef="let row" class="encounter"><div class="number-of-encounters"># of Encounters</div><div class="coding-distribution">Coding Distribution</div>
      <div  class="benchmark-display">{{benchmarkDisplay}} CPSC Benchmark Mean</div>
    </mat-cell>
  </ng-container>
  <ng-container *ngFor="let cpt of EmergencyMedicineCodes.codes" matColumnDef="countOf{{cpt}}">
    <mat-header-cell *matHeaderCellDef class="header{{cpt}} bold">{{cpt}}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="countOf{{cpt}}"><div class="top-cell">
      {{isValidOrElse(row.emergencyMedicine,'cpt' + cpt, 'count', '-')}}</div>
      <div class="middle-cell">{{isValidOrElse(row.emergencyMedicine, 'cpt' + cpt, 'percentage',
        '-', '%')}}</div><div class="bottom-cell">
        {{isValidOrElse(row.emergencyMedicine, 'cpt' + cpt, benchmarkString, '-', '%')}}</div>
    </mat-cell>
  </ng-container>
  <ng-container  matColumnDef="totalEmergencyMedicine">
    <mat-header-cell *matHeaderCellDef class="headerTotalEmergencyMedicine bold">Total</mat-header-cell>
    <mat-cell *matCellDef="let row" class="totalEmergencyMedicine"><div class="top-cell">{{valueFromOrElse(row.emergencyMedicine,'count', '', '-')}}</div>
      <div class="middle-cell">{{valueFromOrElse(row.emergencyMedicine,'percentage', '%', '-', true)}}</div>
      <div class="bottom-cell"></div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
