import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-noaccess-page-batch-export',
  templateUrl: './no-access-page-batch-export.component.html',
  styleUrls: ['./no-access-page-batch-export.component.scss']
})
export class NoAccessPageBatchExportComponent implements OnInit {
  constructor(
  ) {
  }

  ngOnInit() {
  }
}
