import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {DEFAULT_MEMBER_LOCATION} from '../../store/DefaultValues';
import {MemberLocation, SelectableLocation} from '../../shared/models';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent implements OnChanges {

  @Input() memberLocations: SelectableLocation[] = [];
  @Input() memberLocationsForTable: SelectableLocation[] = [];
  @Input() selectedMemberLocation: MemberLocation = DEFAULT_MEMBER_LOCATION;
  displayLocationLoadingIndicator = true;

  readonly DEFAULT_MEMBER_LOCATION = DEFAULT_MEMBER_LOCATION;
  locationSearchText = '';

  @Output() memberLocationEmit = new EventEmitter<MemberLocation>();

  constructor() {
  }

  ngOnChanges(): void {
    this.displayLocationLoadingIndicator = !this.memberLocationsForTable;
  }

  selectAllLocations(): void {
    this.memberLocationEmit.emit(DEFAULT_MEMBER_LOCATION);
  }

  selectMemberLocation = (selectedLocation: SelectableLocation, event?: MouseEvent) => {
    this.memberLocationEmit.emit(selectedLocation.location);
  };

  onLocationSearchTextChanged(event: any): void {
    this.locationSearchText = event.target.value;
    if (this.locationSearchText.length <= 1) {
      this.memberLocations.forEach(location => {
        location.matchesFilterSearchText = true;
      });
      this.filterLocationTableData();
      return;
    }
    this.memberLocations.forEach(location => {
      if (this.locationSearchText.length <= location.location.memberLocationName.length) {
        const shouldUse = location.location.memberLocationName.toLowerCase().includes(this.locationSearchText.toLowerCase());
        location.matchesFilterSearchText = shouldUse;
      } else {
        location.matchesFilterSearchText = false;
      }
    });
    this.filterLocationTableData();
  }

  private foundMatch(tokens: string[], i: number): boolean {
    return this.locationSearchText.length <= tokens[i].length &&
      tokens[i].substring(0, this.locationSearchText.length).toLowerCase() ===
      this.locationSearchText.toLowerCase();
  }

  filterLocationTableData() {
    this.memberLocationsForTable = this.memberLocations.filter(location =>
      !location.eliminated && location.matchesFilterSearchText);
  }
}
