import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {DisplayField, Variable} from '../../variable-container/variable-container.component';
import {
  AppAction,
  chargeDisplayChangedTo,
  mergedProductivityTrendDataChangedTo,
  previousTimePeriodDisplayChanged,
  trendWrvuColumnsChangedTo
} from '../../store/actions';
import * as _ from 'lodash';
import {benchmarkColumnDefFor, createWRVUTrendBenchmarkColumnFor, createWRVUTrendVarianceColumnFor} from '../../shared/BenchmarkColumns';
import {distinctUntilChanged} from 'rxjs/operators';
import {getLevelFromNodePath, getLevelTypeNodePath, Page, WrvuViewType} from '../../shared/helpers';
import {checkForNulls} from '../../shared/null-helpers';
import {columnsForWRVUtrend} from '../../shared/data-table-columns';
import {ColumnType} from '../../shared/enums';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../shared/benchmark-types';
import {MergedProductivityTrendEntry} from '../../shared/models';

@Component({
  selector: 'app-productivity-month-page',
  templateUrl: './productivity-month-page.component.html',
  styleUrls: ['./productivity-month-page.component.scss'],

})
export class ProductivityMonthPageComponent implements OnChanges, OnInit, OnDestroy {

  level: string;
  tableMonth: MergedProductivityTrendEntry[];
  defaultSortColumn = 'year';
  showProgressBar: boolean;
  isSpecialty = false;
  isDepartment = false;
  isProvider: boolean;
  benchmarkPercentile: BenchmarkPercentile;

  currentPage = Page.WrvuTrend;
  trendWrvuColumnsChangedTo = trendWrvuColumnsChangedTo;

  columns: any[] = columnsForWRVUtrend;

  displayedColumns: any[] = columnsForWRVUtrend;

  variables: Variable[] = [];

  variablesForActualWrvus: Variable[] = [{
    name: 'Charges',
    display: false,
    reducerField: DisplayField.Charges,
    dispatchAction(display: boolean): AppAction {
      return chargeDisplayChangedTo(display);
    }
  }];
  variablesForCfteAdjWrvus: Variable[] = this.variablesForActualWrvus.concat([{
    name: 'Previous Date Range cFTE adj. wRVUs',
    display: false,
    reducerField: DisplayField.PreviousTimePeriod,
    dispatchAction(display: boolean): AppAction {
      return previousTimePeriodDisplayChanged(display);
    }
  }]);

  @select(['display', 'isCustom'])
  private readonly isCustom$: Observable<boolean>;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['data', 'mergedProductivityTrend'])
  private readonly mergedTrendData$: Observable<MergedProductivityTrendEntry[]>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'displayedWRVUTrendColumns'])
  private readonly displayedWRVUTrendColumns$: Observable<BaseColumn[]>;

  @Input() fromPdfExport = false;
  @Input() wrvuViewType: WrvuViewType;

  viewCommunityBenchmarks = true;
  productivityMonthDataSubscription: Subscription;
  wrvuTrendColumnSubscription: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  ngOnChanges() {
    this.variables = this.wrvuViewType === WrvuViewType.Actual ? this.variablesForActualWrvus : this.variablesForCfteAdjWrvus;
  }

  ngOnInit() {
    this.productivityMonthDataSubscription = combineLatest([
      this.mergedTrendData$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.benchmarkPercentile$,
      this.isCustom$,
      this.nodePath$,
      this.viewCommunityBenchmarks$
    ]).subscribe(
      ([mergedTrendData, benchmarkPercentile, isCustom, nodePath, viewCommunityBenchmarks]:
         [MergedProductivityTrendEntry[], BenchmarkPercentile, boolean, string, boolean]) => {
        this.benchmarkPercentile = benchmarkPercentile;
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.showProgressBar = _.isEqual(mergedTrendData, INITIAL_STATE.data.mergedProductivityTrend);
        mergedTrendData.forEach(datum => {
          datum.difference = datum.cfteAdjustedWRVUs - checkForNulls(datum.previousCfteAdjustedWRVUs);
        });
        this.columns[3] = createWRVUTrendBenchmarkColumnFor(benchmarkPercentile, this.viewCommunityBenchmarks);
        this.columns[7] = createWRVUTrendVarianceColumnFor(benchmarkPercentile, this.viewCommunityBenchmarks);
        this.columns = this.columns.slice();
        for (let i = 0; i < this.displayedColumns.length; i++) {
          if (this.displayedColumns[i].columnType === ColumnType.BENCHMARK) {
            this.displayedColumns[i] = createWRVUTrendBenchmarkColumnFor(benchmarkPercentile, this.viewCommunityBenchmarks);
          }
          if (this.displayedColumns[i].columnType === ColumnType.VARIANCE) {
            this.displayedColumns[i] = createWRVUTrendVarianceColumnFor(benchmarkPercentile, this.viewCommunityBenchmarks);
          }
        }
        this.displayedColumns = this.columns.slice();
        this.tableMonth = mergedTrendData;
        this.isProvider = this.ngRedux.getState().display.isProviderSelected;
        const multipleProviders = this.ngRedux.getState().display.multipleProviders;
        const multipleSpecialties = this.ngRedux.getState().display.multipleSpecialties;
        const multipleDepartments = this.ngRedux.getState().display.multipleDepartments;
        this.level = isCustom ? '' : getLevelFromNodePath(nodePath, multipleProviders, multipleSpecialties,
          multipleDepartments);
        this.isSpecialty = getLevelTypeNodePath(nodePath) === 3 && !isCustom;
        this.isDepartment = getLevelTypeNodePath(nodePath) === 2 && !isCustom;
        this.isProvider = getLevelTypeNodePath(nodePath) === 4 && !isCustom;
      },
    );

    this.wrvuTrendColumnSubscription = this.displayedWRVUTrendColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
      .subscribe(displayedColumns => {
        return this.updateColumnFilter(displayedColumns);
      });
  }

  ngOnDestroy(): void {
    this.productivityMonthDataSubscription?.unsubscribe();
    this.wrvuTrendColumnSubscription?.unsubscribe();
  }

  sortingDataAccessor = (data: MergedProductivityTrendEntry, sortHeaderId: string): string | number => {
    if (sortHeaderId === 'year' || sortHeaderId === 'month') {
      return data.year * 12 + +(data.month);
    }
    // @ts-ignore
    return data[sortHeaderId];
  };

  whenSortChanged = (monthProductivityData: MergedProductivityTrendEntry[]): void => {
    this.ngRedux.dispatch(mergedProductivityTrendDataChangedTo(monthProductivityData.slice()));
  };

  updateColumnFilter(displayedColumns: BaseColumn[]) {
    const displayedColumnDefs: string[] = displayedColumns.map(col => {
      switch (col.columnType) {
        case ColumnType.BENCHMARK:
          return benchmarkColumnDefFor(this.benchmarkPercentile, this.viewCommunityBenchmarks);
        case ColumnType.VARIANCE:
          return this.viewCommunityBenchmarks ?
            `communityVariance${readableNameOfColumnDef(this.benchmarkPercentile)}` :
            `variance${readableNameOfColumnDef(this.benchmarkPercentile)}`;
        case ColumnType.IMPUTED:
          return this.viewCommunityBenchmarks ?
            `communityImputedReportedcFTE${readableNameOfColumnDef(this.benchmarkPercentile)}` :
            `imputedReportedcFTE${readableNameOfColumnDef(this.benchmarkPercentile)}`;
        default:
          return col.columnDef;
      }
    });

    this.displayedColumns = this.columns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef));
  }
}
