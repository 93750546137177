import {ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {SortingCriterion} from '../../../shared/models';
import {NgRedux} from '@angular-redux/store';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import * as _ from 'lodash';
import {Page} from '../../../shared/helpers';
import {Variable} from '../../../variable-container/variable-container.component';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {AppAction, denialsChosenTabChangedTo, multiLevelDenialsChangedTo} from '../../../store/actions';
import {MultilevelTab, MultilevelTabCollections} from '../../../shared/enums';
import {DrillDownService} from '../../../services/drilldown.service';
import {DenialsMultiLevelEntity} from '../../denials-models';

@Component({
  selector: 'app-denials-specialty-page',
  templateUrl: './denials-specialty-page.component.html',
  styleUrls: ['./denials-specialty-page.component.scss']
})
export class DenialsSpecialtyPageComponent implements OnChanges {
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() denialsMultiLevel: DenialsMultiLevelEntity[];
  @Input() columns: DataTableColumns[];
  @Input() originalColumns: DataTableColumns[];
  @Input() nodePath: string;
  @Input() isCustom: boolean;
  @Input() showVariableMenu: boolean;
  @Input() variables: Variable[];
  @Input() activeVarianceToggle = false;
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion | undefined) => void;
  @Input() reducerAction: (multiLevelByNodePathDenialsColumns: BaseColumn[]) => AppAction;

  showProgressBar: boolean;
  isDepartment = false;
  isSpecialty = false;
  currentPage = Page.DenialsSpecialty;
  sortingDataAccessor: any;
  defaultSortColumn = 'denialRate';
  sortDirection: string;
  nodePathName = '';

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _changeDetectorRef: ChangeDetectorRef,
    private drillDownService: DrillDownService
    ) { }

  ngOnChanges(): void {
    this.showProgressBar =
      _.isEqual(this.denialsMultiLevel, INITIAL_STATE.data.denialsByMultiLevelData.specialtyDenials);
    this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      return (sortHeaderId === 'denialRate') ? data[this.defaultSortColumn] : data[sortHeaderId];
    };
  }

  levelSpecificHandleSortChange = (denialsEntries: DenialsMultiLevelEntity[]) => {

    this.ngRedux.dispatch(multiLevelDenialsChangedTo({
      ...this.ngRedux.getState().data.denialsByMultiLevelData,
      specialtyDenials: denialsEntries
    }));
  };

  whenSpecialtySelected = (selectedNode: DenialsMultiLevelEntity) => {
    if (!selectedNode) {
      return;
    }
    this.drillDownService.drillDownIntoNode(selectedNode.specialtyNodePath, this.nodePath, MultilevelTab.BY_SPECIALTY);
    this.ngRedux.dispatch(denialsChosenTabChangedTo(MultilevelTabCollections.BY_PROVIDER));
  };
}
