import {HighChartColorIndex, LegendColor, LegendStyle, PayerType} from '../shared/enums';
import {Legend, MultilevelNode, PayerCategory} from '../shared/models';
import {MetricType} from '../shared/metric-types';
import {hasValue} from '../shared/null-helpers';
import {AxisLabelsFormatterContextObject, PlotColumnOptions, PointClickEventObject} from 'highcharts/highstock';
import {abbreviateAxisValue, roundToNumber} from '../productivity-summary/number-formatter';
import {curDate, LevelType, monthNames, toTitleCase} from '../shared/helpers';
import {createEmptyValues, setTickValue} from '../shared/highcharts-helpers';
import {BenchmarkPercentile} from '../shared/benchmark-types';
import {FilterCriteria} from '../store/IAppState';

export interface CollectionsData {
  snapshotMatchedCollections: SnapshotMatchedCollections[];
}

export interface PayerCollectionsData {
  payerMatchedCollections: PayerCollections[];
}

export interface MatchedCollectionsByMultiLevelByNodePathData {
  matchedCollectionsByMultiLevelByNodePath: MatchedCollectionsByMultiLevelByNodePath;
}

export interface PayerCollections {
  payerCategoryKey: number;
  payerCategoryDescription: string;
  chargeAmount: number;
  charityCareAmount: number;
  discountAmount: number;
  patientPaymentAmount: number;
  primaryPayerPaymentAmount: number;
  otherPayerPaymentAmount: number;
  primaryPayerAdjAmount: number;
  otherPayerAdjAmount: number;
  primaryAndOtherPayerContractuals: number;
  primaryAndOtherInsurancePayments: number;
  expectedPayments: number;
  totalPayments: number;
  netCollectionRate: number;
  benchmark25th: number;
  benchmark50th: number;
  benchmark75th: number;
  variance25th: number;
  variance50th: number;
  variance75th: number;
  outstandingAR: number;
  outstandingARAsPctOfCharges: number;
  controllableAllowance: number;
  patientRelatedBadDebt: number;
  smallBalanceWriteOffs: number;

  [key: string]: number | string;
}

export interface SnapshotMatchedCollections {
  nodeName: string;
  nodePath: string;
  memberKey: number;
  year: number;
  month: number;
  memberPhysicianSpecialtyKey: number;
  computedDate: number;
  memberLocationKey: number;
  memberPayerKeyExpected: number;
  memberBillingAreaKey: number;
  chargeAmount: number;
  primaryPayerAdjAmount: number;
  primaryPayerPaymentAmount: number;
  otherPayerAdjAmount: number;
  otherPayerPaymentAmount: number;
  patientPaymentAmount: number;
  primaryAndOtherPayerContractuals: number;
  primaryAndOtherInsurancePayments: number;
  expectedPayments: number;
  totalPayments: number;
  netCollectionRate: number;
  benchmark25th: number;
  benchmark50th: number;
  benchmark75th: number;
  variance25th?: number;
  variance50th?: number;
  variance75th?: number;
  charityCareAmount: number;
  payerBadDebtAmount: number;
  outstandingAR: number;
  outstandingARAsPctOfCharges: number;
  controllableAllowance: number;
  patientRelatedBadDebt: number;
  smallBalanceWriteOffs: number;
  discountAmount: number;
  invoiceStatus: number;

  [key: string]: number | string | undefined;
}

export interface MatchedCollectionsByMultiLevelByNodePath extends MultilevelNode {
  chargeAmount: number;
  charityCareAmount: number;
  discountAmount: number;
  patientPaymentAmount: number;
  primaryPayerPaymentAmount: number;
  otherPayerPaymentAmount: number;
  primaryPayerAdjAmount: number;
  otherPayerAdjAmount: number;
  primaryAndOtherPayerContractuals: number;
  primaryAndOtherInsurancePayments: number;
  expectedPayments: number;
  totalPayments: number;
  netCollectionRate: number;
  benchmark25th: number;
  benchmark50th: number;
  benchmark75th: number;
  controllableAllowance: number;
  patientRelatedBadDebt: number;
  smallBalanceWriteOffs: number;
  paymentAtBenchmark25th: number;
  paymentAtBenchmark50th: number;
  paymentAtBenchmark75th: number;
  variance25th: number;
  variance50th: number;
  variance75th: number;
  outstandingAR: number;
  outstandingARAsPctOfCharges: number;
  isHidden?: boolean;

  [key: string]: number | string | boolean | undefined;

}

export interface PayerByMultiLevelCollectionsByNodePath extends MultilevelNode {
  payerCategoryKey: number;
  payerCategoryDescription: string;
  chargeAmount: number;
  charityCareAmount: number;
  discountAmount: number;
  patientPaymentAmount: number;
  primaryPayerPaymentAmount: number;
  otherPayerPaymentAmount: number;
  primaryPayerAdjAmount: number;
  otherPayerAdjAmount: number;
  primaryAndOtherPayerContractuals: number;
  primaryAndOtherInsurancePayments: number;
  expectedPayments: number;
  totalPayments: number;
  netCollectionRate: number;
  benchmark25th: number;
  benchmark50th: number;
  benchmark75th: number;
  variance25th: number;
  variance50th: number;
  variance75th: number;
  outstandingAR: number;
  outstandingARAsPctOfCharges: number;
  controllableAllowance: number;
  patientRelatedBadDebt: number;
  smallBalanceWriteOffs: number;
  paymentAtBenchmark25th: number;
  paymentAtBenchmark50th: number;
  paymentAtBenchmark75th: number;
  expected25thPayments: number;
  expected50thPayments: number;
  expected75thPayments: number;

  [key: string]: number | string | undefined;

}

export interface PayerByLevelMatchedCollections extends PayerCollections {
  nodeId: number;
  nodeName: string;
  nodePath: string;
  paymentAtBenchmark25th: number;
  paymentAtBenchmark50th: number;
  paymentAtBenchmark75th: number;
  expected25thPayments: number;
  expected50thPayments: number;
  expected75thPayments: number;
}

export interface CollectionsSummaryForAllTypes {
  summaryType: number;
  chargeAmount: number;
  charityCareAmount: number;
  discountAmount: number;
  patientPaymentAmount: number;
  primaryPayerPaymentAmount: number;
  otherPayerPaymentAmount: number;
  primaryPayerAdjAmount: number;
  otherPayerAdjAmount: number;
  expectedPayments: number;
  totalPayments: number;
  controllableAllowance: number;
  smallBalanceWriteOffs: number;
  patientRelatedBadDebt: number;
  primaryAndOtherPayerContractuals: number;
  primaryAndOtherInsurancePayments: number;
  netCollectionRate: number;
  benchmark25th: number;
  benchmark50th: number;
  benchmark75th: number;
  variance25th: number;
  variance50th: number;
  variance75th: number;
  outstandingAR: number;
  outstandingARAsPctOfCharges: number;
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;

  [key: string]: number | undefined;
}

export interface CollectionsByPayerGroupedByLevel {
  nodeId: number;
  nodeName: string;
  nodePath: string;
  chargeAmount: number;
  charityCareAmount: number;
  discountAmount: number;
  patientPaymentAmount: number;
  primaryPayerPaymentAmount: number;
  otherPayerPaymentAmount: number;
  primaryPayerAdjAmount: number;
  otherPayerAdjAmount: number;
  primaryAndOtherPayerContractuals: number;
  primaryAndOtherInsurancePayments: number;
  expectedPayments: number;
  totalPayments: number;
  netCollectionRate: number;
  benchmark25th: number;
  benchmark50th: number;
  benchmark75th: number;
  variance25th: number;
  variance50th: number;
  variance75th: number;
  outstandingAR: number;
  outstandingARAsPctOfCharges: number;
  controllableAllowance: number;
  patientRelatedBadDebt: number;
  smallBalanceWriteOffs: number;
  paymentAtBenchmark25th: number;
  paymentAtBenchmark50th: number;
  paymentAtBenchmark75th: number;
  expected25thPayments: number;
  expected50thPayments: number;
  expected75thPayments: number;

  [key: string]: number | string;
}

export interface CollectionsMultiLevelSnapshot extends SnapshotMatchedCollections, CollectionsByMultiLevelByNodePathGroupedByLevel {
}

export interface CollectionsByMultiLevelByNodePathGroupedByLevel extends MultilevelNode {
  chargeAmount: number;
  charityCareAmount: number;
  discountAmount: number;
  patientPaymentAmount: number;
  primaryPayerPaymentAmount: number;
  otherPayerPaymentAmount: number;
  primaryPayerAdjAmount: number;
  otherPayerAdjAmount: number;
  primaryAndOtherPayerContractuals: number;
  primaryAndOtherInsurancePayments: number;
  expectedPayments: number;
  totalPayments: number;
  netCollectionRate: number;
  benchmark25th: number;
  benchmark50th: number;
  benchmark75th: number;
  controllableAllowance: number;
  patientRelatedBadDebt: number;
  smallBalanceWriteOffs: number;
  paymentAtBenchmark25th: number;
  paymentAtBenchmark50th: number;
  paymentAtBenchmark75th: number;

  [key: string]: number | string | undefined;
}

export enum PayerOptions {
  Top5ByCharges,
  Top5ByNCR,
  SelectPayers
}

export interface PayerDropdownOption {
  label: string;
  value: PayerOptions;
}

export interface PayerCheckboxOption {
  payerName: string;
  selected: boolean;
}

export interface PayerDrilldownCriteria {
  category: PayerCategory;
  key: number;
  type: PayerType;
  description: string;
}

export interface MatchedCollectionsMultiLevelNodePathData {
  departmentCollections: MatchedCollectionsByMultiLevelByNodePath[];
  specialtyCollections: MatchedCollectionsByMultiLevelByNodePath[];
  providerCollections: MatchedCollectionsByMultiLevelByNodePath[];
}

export interface PayerMatchedCollectionsMultiLevelNodePathData {
  departmentCollections: PayerByMultiLevelCollectionsByNodePath[];
  specialtyCollections: PayerByMultiLevelCollectionsByNodePath[];
  providerCollections: PayerByMultiLevelCollectionsByNodePath[];
}

export interface CollectionSummaryData {
  metric: string;
  metricSelectedDateRange: string;
  metricPreviousYearSelectedDateRange: string;
  metricYearToDate: string;
  metricPreviousYearToDate: string;
}

export const legendsForCollectionsVarianceCharts = [
  {
    text: 'Above Net Collection Rate Benchmark',
    color: LegendColor.GREEN,
    metric: MetricType.Collections,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  },
  {
    text: 'Below Net Collection Rate Benchmark',
    color: LegendColor.RED,
    metric: MetricType.Collections,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  },
];

export const defaultLegendsForCollectionsChart: Legend[] = [
  {
    text: 'Net Collection Rate',
    color: LegendColor.TEAL,
    metric: MetricType.Collections,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  },
  {
    text: 'Net Collection Rate Benchmark',
    color: LegendColor.GREY,
    metric: MetricType.Collections,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: false,
    showPercentileControl: true
  }
];

export const chargesLegendForCollections: Legend = {
  text: 'Charges',
  color: LegendColor.ORANGE,
  metric: MetricType.Collections,
  style: LegendStyle.CIRCLE,
  showBenchmarkOptionControl: false,
  showPercentileControl: false
};

export const expectedPaymentsLegend: Legend = {
  text: 'Expected Payments',
  color: LegendColor.PURPLE,
  metric: MetricType.Collections,
  style: LegendStyle.CIRCLE,
  showBenchmarkOptionControl: false,
  showPercentileControl: false
};

export function addVarianceDatumIfValid(varianceValue: number | undefined, varianceData: any[], tabLevel: LevelType,
                                        collection: MatchedCollectionsByMultiLevelByNodePath | SnapshotMatchedCollections) {
  let name: any = '';
  switch (tabLevel) {
    case LevelType.department:
      name = collection.departmentNodeName;
      break;
    case LevelType.specialty:
      name = collection.specialtyNodeName;
      break;
    case LevelType.provider:
      name = collection.providerNodeName;
  }
  if (hasValue(varianceValue)) {
    varianceData.push({
      name: name,
      y: varianceValue,
      colorIndex: varianceValue ? (varianceValue >= 0 ? HighChartColorIndex.GREEN : HighChartColorIndex.RED) : HighChartColorIndex.GREY,
      drilldown: collection.nodePath
    });
  }
}

export function addPayerVarianceDatum(varianceValue: number | undefined, varianceData: any[],
                                      collection: PayerCollections, filters: FilterCriteria) {

  varianceData.push({
    name: collection.payerCategoryDescription,
    y: hasValue(varianceValue) ? varianceValue : 0,
    colorIndex: varianceValue ? (varianceValue >= 0 ? HighChartColorIndex.GREEN : HighChartColorIndex.RED) : HighChartColorIndex.GREY,
    drilldown: {
      category: filters.payerType !== PayerType.ALL ?
        filters.payerCategory : {
          payerCategoryKey: collection.payerCategoryKey,
          payerCategoryDescription: collection.payerCategoryDescription,
          payerCategoryCode: ''
        },
      key: collection.payerCategoryKey,
      type: filters.payerType + 1,
      description: collection.payerCategoryDescription
    }
  });
}

export function getVarianceSeriesOptions(varianceData: any[], isProvider: boolean,
                                         multiLevelEventClick: { click: (event: PointClickEventObject) => void }) {
  return [
    {
      turboThreshold: 0,
      name: '% Variance',
      yAxis: 0,
      data: varianceData.length < maxScrollBarsForCollectionsCharts ?
        createEmptyValues(varianceData, maxScrollBarsForCollectionsCharts) :
        varianceData,
      type: 'column',
      pointWidth: pointWidthForCollectionsCharts,
      stack: 0,
      cursor: isProvider ? 'default' : 'pointer',
      events: multiLevelEventClick,
      zIndex: 1,
    },
  ];
}

export function validateMaxAxisValue(max: number) {
  return max > 0 ? max : max * -1;
}

export function getRightSideLabel(chargesDisplayed: boolean, expectedPaymentsDisplayed: boolean): string {
  if (chargesDisplayed && !expectedPaymentsDisplayed) {
    return 'Charges';
  } else if (!chargesDisplayed && expectedPaymentsDisplayed) {
    return 'Expected Payments';
  } else if (chargesDisplayed && expectedPaymentsDisplayed) {
    return 'Charges and Expected Payments';
  }
  return '';
}

export function getLeftSideYAxisForCollectionsVarianceCharts(maxLeft: number, minLeft: number) {
  return [
    {
      title: {
        text: '% Variance',
      },
      max: maxLeft,
      min: minLeft > 0 ? 0 : minLeft,
      opposite: false,
      labels: {
        formatter: function (this: AxisLabelsFormatterContextObject<any>) {
          return toTitleCase(this.value.toString()) + '%';
        },
      },
      plotLines: [{
        color: '#000000',
        width: 200,
        value: 0,
        dashStyle: 'Solid',
        zIndex: 999
      }],
      tickInterval: setTickValue(100),
    },
  ];
}

export function getLeftSideYAxisForCollectionsCharts(maxLeft: number, minLeft: number) {
  return [
    {
      title: {
        text: '% Net Collection Rate',
        margin: 30
      },
      max: roundToNumber(1.1 * maxLeft, 0),
      min: roundToNumber(1.1 * (minLeft > 0 ? 0 : minLeft), 0),
      opposite: false,
      labels: {
        formatter: function (this: AxisLabelsFormatterContextObject<any>) {
          return abbreviateAxisValue(this.value) + '%';
        },
      },
      tickAmount: 9
    },
  ];
}

export function getRightSideYAxisForCollectionsCharts(maxRight: number, minRight: number,
                                                      chargesDisplayed: boolean, expectedPaymentsDisplayed: boolean) {
  return (chargesDisplayed || expectedPaymentsDisplayed) ?
    [
      {
        title: {
          text: getRightSideLabel(chargesDisplayed, expectedPaymentsDisplayed),
        },
        max: maxRight,
        min: minRight > 0 ? 0 : minRight,
        opposite: true,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return '$' + abbreviateAxisValue(this.value);
          },
        },
        tickAmount: 9
      }
    ] : [];
}

export function getVarianceForVarianceGraph(collection: SnapshotMatchedCollections |
                                              MatchedCollectionsByMultiLevelByNodePath | PayerCollections,
                                            benchmarkPercentile: BenchmarkPercentile): number | undefined {
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Percentile25th:
      return collection.variance25th;
    case BenchmarkPercentile.Percentile50th:
      return collection.variance50th;
    case BenchmarkPercentile.Percentile75th:
      return collection.variance75th;
    default:
      return undefined;
  }
}

export function doesCollectionsDataHaveValidBenchmarkValue(data: MatchedCollectionsByMultiLevelByNodePath[] | PayerCollections[]): boolean {
  // @ts-ignore
  return !!data.find(x => x.benchmark25th || x.benchmark50th || x.benchmark75th);
}

export const maxScrollBarsForCollectionsCharts = 20;
export const snapshotBarCountForCollectionsCharts = 20;
export const pointWidthForCollectionsCharts = 28;
export const groupPaddingForCollectionsCharts = 0.33;
export const pointPaddingForCollectionsCharts = 1.0;
export const widthForCollectionsCharts = (pointWidthForCollectionsCharts * snapshotBarCountForCollectionsCharts)
  * (1 + groupPaddingForCollectionsCharts + pointPaddingForCollectionsCharts);

export const barChartSeriesForCollectionsCharts: PlotColumnOptions = {
  cropThreshold: 100,
  pointPadding: pointPaddingForCollectionsCharts,
  stacking: 'normal'
};

export interface CollectionSummaryData {
  metric: string;
  metricSelectedDateRange: string;
  metricPreviousYearSelectedDateRange: string;
  metricYearToDate: string;
  metricPreviousYearToDate: string;
}

export function getYtdDateRangeForCollectionSummary(collectionSummary: CollectionsSummaryForAllTypes): string {
  return collectionSummary.endMonth ? `${monthNames[collectionSummary.startMonth - 1]} ${collectionSummary.startYear} - ` +
    `${monthNames[collectionSummary.endMonth - 1]} ${collectionSummary.endYear}` :
    `${monthNames[0]} ${curDate.getFullYear()} - ${monthNames[curDate.getMonth().valueOf() - 1]} ${curDate.getFullYear()}`;
}

export function getPreviousYtdDateRangeForCollectionSummary(collectionSummary: CollectionsSummaryForAllTypes): string {
  return collectionSummary.endMonth ? `${monthNames[collectionSummary.startMonth - 1]} ${collectionSummary.startYear} - ` +
    `${monthNames[collectionSummary.endMonth - 1]} ${collectionSummary.endYear}` :
    `${monthNames[0]} ${curDate.getFullYear() - 1} - ${monthNames[curDate.getMonth().valueOf() - 1]} ${curDate.getFullYear() - 1}`;
}
