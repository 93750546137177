import {AfterViewChecked, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, FilterCriteria, IAppState, INITIAL_STATE} from '../store/IAppState';
import {SortingCriterion} from '../shared/models';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {columnsForDenialsByPayer, DataTableColumns, viewCPTsDeniedColumn} from '../shared/data-table-columns';
import {canHide, isFeatureEnabled, isSortingCriterionValid, Page, updateCurrentNodePathLabel} from '../shared/helpers';
import {BenchmarkPercentile} from '../shared/benchmark-types';
import {
  AppAction,
  chargesTerminallyDeniedChangedTo,
  denialsChangedTo,
  denialsChargesDisplayedChangedTo,
  denialsColumnsChangedTo,
  denialsSortingCriteriaChangedTo,
  deniedCPTsPaidChangedTo,
  filterCriteriaChangedTo,
  terminalDenialRateChangedTo
} from '../store/actions';
import {
  createDenialsPaidBenchmarkColumnFor,
  createDenialsRateBenchmarkColumnFor,
  createDenialsVarianceColumnFor
} from '../shared/BenchmarkColumns';
import {ApiServiceImpl, ApiServiceToken} from '../services/api.service';
import {FilterSelectorService, FilterSelectorServiceToken} from '../services/filter-selector.service';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {ColumnType, PayerType, SortingOrder} from '../shared/enums';
import {getOntologyData} from '../shared/localStoragehelper';
import {DisplayField, Variable} from '../variable-container/variable-container.component';
import {DEFAULT_CUSTOM_GROUP_ID} from '../store/DefaultValues';
import {DenialPayerEntity, DenialsPayerData} from './denials-models';
import {updateDenialsBenchmarkDepartmentColumns} from './denials-helpers';

@Component({
  selector: 'app-denials',
  templateUrl: './denials.component.html',
  styleUrls: ['./denials.component.scss']
})
export class DenialsComponent implements OnInit, OnDestroy, AfterViewChecked {

  @select(['data', 'denials'])
  private readonly denialsData$: Observable<DenialsPayerData>;
  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;
  @select(['display', 'sortingCriteria', 'denialsSortingCriteria'])
  private readonly denialsSortingCriteria$: Observable<SortingCriterion | undefined>;
  @select(['display', 'displayedDenialsColumns'])
  private readonly displayedDenialsColumns$: Observable<BaseColumn[]>;
  @select(['display', 'varianceToggle', 'denialsVariance'])
  private readonly denialsVarianceToggle$: Observable<boolean>;
  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;
  @select(['ontologyLoaded'])
  private readonly ontologyLoaded$: Observable<boolean>;

  @Input() fromPdfExport = false;
  @Input() activeVarianceToggle = false;

  filterSubscription: Subscription;
  columnSubscription: Subscription;
  showProgressBar: boolean;
  payerLevel: string;
  subscription: Subscription;
  defaultSortColumn: string;
  sortDirection = 'desc';
  columns: DataTableColumns[] = columnsForDenialsByPayer.slice();
  displayedColumns: DataTableColumns[] = columnsForDenialsByPayer.slice();
  currentPage = Page.Denials;
  denialsData: DenialPayerEntity[] = [];
  benchmarkPercentile: BenchmarkPercentile;
  viewCommunityBenchmarks: false;
  filters: FilterCriteria = this.ngRedux.getState().filters;
  denialsSortingCriteria: SortingCriterion | undefined;
  denialsVarianceToggle = false;
  varianceToggleSortingCriterion: SortingCriterion;
  sortingDataAccessor: any;
  varianceKey = 'variancePer50th';
  canHideCPTsDenialsModal = false;
  nodePathName = '';
  tableTitle: string;

  reducerAction: (displayedDenialsColumns: BaseColumn[]) => AppAction =
    denialsColumnsChangedTo;
  variables: Variable[] = [
    {
      name: 'Total Charges',
      display: false,
      reducerField: DisplayField.DenialsCharge,
      dispatchAction(display: boolean): AppAction {
        return denialsChargesDisplayedChangedTo(display);
      }
    },
    {
      name: 'Charges Terminally Denied',
      display: false,
      reducerField: DisplayField.ChargesTerminallyDenied,
      dispatchAction(display: boolean): AppAction {
        return chargesTerminallyDeniedChangedTo(display);
      }
    },
    {
      name: '% of Denied CPTs Paid',
      display: false,
      reducerField: DisplayField.DeniedCPTSPaid,
      dispatchAction(display: boolean): AppAction {
        return deniedCPTsPaidChangedTo(display);
      }
    },
    {
      name: 'Terminal Denial Rate',
      display: false,
      reducerField: DisplayField.TerminalDenialRate,
      dispatchAction(display: boolean): AppAction {
        return terminalDenialRateChangedTo(display);
      }
    },
  ];

  constructor(private ngRedux: NgRedux<IAppState>,
              private cdr: ChangeDetectorRef,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {

    this.filterSubscription = this.filterService.getFilters()
      .subscribe((filter: FilterCriteria) => {
        if (!!filter.nodePath) {
          this.apiService.getDenialsData(filter);
        }
      });
  }

  ngOnInit() {
    if (!this.canHideCPTsDenialsModal) {
      this.columns.push(viewCPTsDeniedColumn);
    }
    this.subscription = combineLatest([
      this.denialsData$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.benchmarkPercentile$,
      this.denialsSortingCriteria$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.nodePath$,
      this.denialsVarianceToggle$,
      this.ontologyLoaded$
    ]).subscribe(
      ([denialsData, benchmarkPercentile, denialsSortingCriteria, nodePath, denialsVarianceToggle, ontologyLoaded]:
         [DenialsPayerData, BenchmarkPercentile, SortingCriterion | undefined, string, boolean, boolean]) => {
        this.defaultSortColumn = 'denialRate';
        this.benchmarkPercentile = benchmarkPercentile;
        this.denialsData = denialsData?.denialRates;
        const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
        this.canHideCPTsDenialsModal = canHide(FeatureToggleEntries.DENIALS_CPTS_DENIED_MODAL, featureToggleSettings, userProfile);
        this.columns = columnsForDenialsByPayer.slice();
        this.columns = this.columns.map(col => updateDenialsBenchmarkDepartmentColumns(col, benchmarkPercentile));
        this.displayedColumns = this.columns.slice();
        for (let i = 0; i < this.displayedColumns.length; i++) {
          if (this.displayedColumns[i].columnType === ColumnType.BENCHMARK) {
            if (this.displayedColumns[i].columnDef.includes('denialRatePer')) {
              this.displayedColumns[i] = createDenialsRateBenchmarkColumnFor(this.benchmarkPercentile);
            } else if (this.displayedColumns[i].columnDef.includes('deniedPaidPer')) {
              this.displayedColumns[i] = createDenialsPaidBenchmarkColumnFor(this.benchmarkPercentile);
            }
          }
          if (this.displayedColumns[i].columnType === ColumnType.VARIANCE) {
            this.displayedColumns[i] = createDenialsVarianceColumnFor(this.benchmarkPercentile);
          }
        }

        this.displayedColumns = this.displayedColumns.slice();
        this.ngRedux.dispatch(this.reducerAction(this.displayedColumns));
        this.denialsSortingCriteria = denialsSortingCriteria;
        this.denialsVarianceToggle = denialsVarianceToggle;

        const payerType = this.ngRedux.getState().filters.payerType;
        switch (payerType) {
          case PayerType.ALL:
            this.payerLevel = 'Payer Category';
            break;
          case PayerType.CATEGORY:
            this.payerLevel = 'National Payer';
            break;
          case PayerType.NATIONAL:
          case PayerType.MEMBER:
          default:
            this.payerLevel = 'Member Payer';
            break;
        }
        this.tableTitle = `Denials Analysis By ${this.payerLevel}`;

        this.adjustSortingVariables(denialsSortingCriteria, denialsVarianceToggle);
        this.filters = {
          ...this.ngRedux.getState().filters,
          nodePath: nodePath
        };

        this.showProgressBar = _.isEqual(denialsData, INITIAL_STATE.data.denials);
        if (ontologyLoaded && getOntologyData().ontologyHierarchy.length > 0) {
          this.updateNodePathName(nodePath);
        }
      });

    this.columnSubscription = this.displayedDenialsColumns$
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(displayedColumns => {
        this.displayedColumns =
          this.updateColumnFilter(displayedColumns, this.columns);
        if (!this.displayedColumns.includes(viewCPTsDeniedColumn) && !this.canHideCPTsDenialsModal) {
          this.displayedColumns.push(viewCPTsDeniedColumn);
        }
      });
  }


  whenPayerRowSelected = (selectedNode: DenialPayerEntity) => {
    if (!selectedNode) {
      return;
    }
    const payerType = this.ngRedux.getState().filters.payerType;
    if (payerType !== selectedNode.payerType) {
      switch (selectedNode.payerType) {
        case PayerType.CATEGORY:
          this.ngRedux.dispatch(filterCriteriaChangedTo({
            ...this.ngRedux.getState().filters,
            payerType: selectedNode.payerType,
            payerKey: selectedNode.payerKey,
            payerCategory: {
              payerCategoryKey: selectedNode.payerKey,
              payerCategoryCode: '',
              payerCategoryDescription: selectedNode.payerCategoryDesc
            }, chosenByUser: true, customGroupId: DEFAULT_CUSTOM_GROUP_ID
          }));
          break;
        case PayerType.NATIONAL:
          this.ngRedux.dispatch(filterCriteriaChangedTo({
            ...this.ngRedux.getState().filters,
            payerType: selectedNode.payerType,
            payerKey: selectedNode.payerKey,
            nationalPayerDescription: selectedNode.payerCategoryDesc,
            nationalPayerKey: selectedNode.payerKey,
            chosenByUser: true
          }));
          break;
        case PayerType.MEMBER:
          this.ngRedux.dispatch(filterCriteriaChangedTo({
            ...this.ngRedux.getState().filters,
            payerType: selectedNode.payerType,
            payerKey: selectedNode.payerKey,
            memberPayerDescription: selectedNode.payerCategoryDesc,
            memberPayerKey: selectedNode.payerKey,
            chosenByUser: true,
          }));
          break;
      }
    }
  };

  updateNodePathName(nodePath: string): void {
    const nodeLabels = updateCurrentNodePathLabel(nodePath).split('/');
    let maxNodePathLength = 0;
    nodePath.split('|').forEach(subPath => {
      const pathLength = subPath.split('\\').length;
      if (pathLength > maxNodePathLength) {
        maxNodePathLength = pathLength;
      }
    });
    this.nodePathName = '';
    for (let x = 3; x <= maxNodePathLength; x++) {
      this.nodePathName = this.nodePathName.concat(nodeLabels[x - 3]);
      if (x < maxNodePathLength) {
        this.nodePathName = this.nodePathName.concat(', ');
      }
    }
  }

  adjustSortingVariables(denialsSortingCriteria: SortingCriterion | undefined, denialsVarianceToggle: boolean):
    void {
    this.denialsVarianceToggle = denialsVarianceToggle;
    const denialsSortColumn = 'denialRate';
    if (denialsSortingCriteria && denialsSortingCriteria.columnDef && denialsSortingCriteria.sortingOrder) {
      const requiredSortingCriteria: SortingCriterion = denialsSortingCriteria;
      this.defaultSortColumn = requiredSortingCriteria.columnDef;
      this.sortDirection = requiredSortingCriteria.sortingOrder === SortingOrder.DESCENDING ? 'desc' : 'asc';
    } else {
      this.defaultSortColumn = denialsSortColumn;
      this.sortDirection = 'desc';
    }
    if (!denialsVarianceToggle) {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: this.varianceKey
      };
    } else {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: denialsSortColumn
      };
    }
  }

  updateSortingCriteria = (sortingCriteria: SortingCriterion | undefined) => {
    if (!isSortingCriterionValid(sortingCriteria)) {
      return;
    }
    this.ngRedux.dispatch(denialsSortingCriteriaChangedTo(sortingCriteria));
  };

  whenSortChanged = (denials: any) => {
    this.ngRedux.dispatch(denialsChangedTo({denialRates: denials}));
  };

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
    this.columnSubscription?.unsubscribe();
    this.cdr.detach();
  }

  updateColumnFilter(displayedColumns: BaseColumn[], tableColumns: DataTableColumns[]) {
    const displayedColumnDefs: string[] = displayedColumns.map(col => {
      switch (col.columnType) {
        case ColumnType.BENCHMARK:
          if (col.columnDef.includes('denialRatePer')) {
            return createDenialsRateBenchmarkColumnFor(this.benchmarkPercentile).columnDef;
          } else if (col.columnDef.includes('deniedPaidPer')) {
            return createDenialsPaidBenchmarkColumnFor(this.benchmarkPercentile).columnDef;
          }
          break;
        case ColumnType.VARIANCE:
          return createDenialsVarianceColumnFor(this.benchmarkPercentile).columnDef;
        default:
          return col.columnDef;
      }
    });
    return tableColumns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef)).slice();
  }

}
