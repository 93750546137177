import {Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {ProductivityMultiLevelSnapshot} from '../../services/ProviderProductivity';
import {isFeatureEnabled, LevelType, Page, WrvuViewType} from '../../../shared/helpers';
import {AppAction, multilevelTabChangedTo} from '../../../store/actions';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {MultilevelTab} from '../../../shared/enums';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import {DataTableColumns} from '../../../shared/data-table-columns';
import * as _ from 'lodash';
import {FeatureToggleSetting, SortingCriterion} from '../../../shared/models';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {Variable} from '../../../variable-container/variable-container.component';
import {DrillDownService} from '../../../services/drilldown.service';
import {Observable} from 'rxjs';
import {FeatureToggleEntries} from '../../../shared/feature-toggle-settings-enum';

@Component({
  selector: 'app-wrvu-multilevel-department-page',
  templateUrl: './wrvu-multilevel-department-page.component.html',
  styleUrls: ['./wrvu-multilevel-department-page.component.scss']
})
export class WrvuMultilevelDepartmentPageComponent implements OnInit, OnChanges {

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @Input() activeVarianceToggle: boolean;
  @Input() multilevel: boolean;
  @Input() zeroSuppression: boolean;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() selectionCounts: number;
  @Input() varianceToggleSortingCriterion: SortingCriterion;
  @Input() wRVUtype: WrvuViewType;
  @Input() varianceKey: string;
  @Input() variables: Variable[];
  @Input() nodePath: string;
  @Input() defaultSortColumn: string;
  @Input() sortDirection: string;
  @Input() countOfSuppressedEntries: number;
  @Input() zeroSuppressionCondition: string;
  @Input() reducerAction: (displayedWrvuSnapshotColumns: BaseColumn[]) => AppAction;
  @Input() wrvuSnapshotData: ProductivityMultiLevelSnapshot[];
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion | undefined) => void;
  @Input() columns: DataTableColumns[];
  @Input() displayedColumns: DataTableColumns[] = [];

  currentPage: Page = Page.WrvuDepartment;
  showProgressBar: boolean;
  LevelType = LevelType;
  MultilevelTab = MultilevelTab;
  canShowBenchmarks: boolean;
  canShowCfteAdjWrvus: boolean;

  constructor(private ngRedux: NgRedux<IAppState>,
              private drillDownService: DrillDownService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.featureToggleSettings$.subscribe((settings: FeatureToggleSetting[]) => {
      const userProfile = this.ngRedux.getState().data.userProfile;
      this.canShowBenchmarks = !isFeatureEnabled(
        FeatureToggleEntries.HIDE_WRVU_BY_DEPARTMENT_BENCHMARK,
        settings,
        userProfile);
      this.canShowCfteAdjWrvus = !isFeatureEnabled(
        FeatureToggleEntries.HIDE_WRVU_BY_DEPARTMENT_CFTE_ADJ_WRVUS,
        settings,
        userProfile);

      if (!this.canShowBenchmarks) {
        this.activeVarianceToggle = false;
        this.defaultSortColumn = 'wRVUs';
      }
    });

    this.ngOnChanges();
  }

  ngOnChanges(): void {
    this.showProgressBar =
      _.isEqual(this.wrvuSnapshotData, INITIAL_STATE.data.providerProductivityMultilevelData.departmentProductivities);
  }

  whenDepartmentRowSelected = (selectedNode: ProductivityMultiLevelSnapshot) => {
    this.whenDepartmentSelected(selectedNode, true);
  };

  whenDepartmentSelected = (selectedNode: ProductivityMultiLevelSnapshot, table = false) => {
    if (!selectedNode) {
      return;
    }
    this.analyticsService.handleGoogleAnalytics('Wrvu Snapshot', selectedNode.nodeName, `Drill down on ${table ? 'Table' : 'Chart'}`);
    this.drillDownService.drillDownIntoNode(selectedNode.nodePath, this.nodePath, MultilevelTab.BY_DEPARTMENT);
    this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.BY_SPECIALTY));
  };

  whenSortChanged = (productivitySnapshotData: ProductivityMultiLevelSnapshot[]) => {
    if (!_.isEqual(this.wrvuSnapshotData, productivitySnapshotData)) {
      this.wrvuSnapshotData = productivitySnapshotData;
    }
  };

  tieBreakerProperty = (productivitySnapshot: ProductivityMultiLevelSnapshot) => {
    return productivitySnapshot.departmentNodeName;
  };

}
