import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BatchExportServiceToken, HttpBatchExportService} from '../services/batch-export.service';
import {
  BatchExportDetail,
  BatchExportDetailResponse,
  BatchFilterCriteria,
  DateRange,
  EmDimension,
  EvaluationManagementMultilevel,
  FeatureToggleSetting,
  MonthProductivityData,
  ZipCodeNewPatientVisits
} from '../../shared/models';
import {getBatchFilterFromExportDetail} from '../../shared/test/helper-functions.spec';
import {forkJoin, Subscription} from 'rxjs';
import {
  FilterType,
  getNumberOfMonthsInDateRange,
  getPreviousYearDateRange,
  getSelectedDateRange,
  SummaryType
} from '../../shared/helpers';
import {BenchmarkPercentile, getBenchmarkLabel} from '../../shared/benchmark-types';
import {roundToNumber} from '../../productivity-summary/number-formatter';
import {FeatureToggleServiceToken, HttpFeatureToggleService} from '../../services/feature-toggle.service';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {MonthNewPatientVisitData} from '../../new-patient-visits/components/npv-models';
import {
  combineBatchFilter,
  isFeatureEnabledForBatchExport,
  setUpBenchmarkOption,
  setUpBenchmarkPercentile,
  updateFilterCriteriaWithTelehealthFlag
} from '../batch-export-helper';
import {BenchmarkOption} from '../../shared/enums';

@Component({
  selector: 'app-batch-export-pdf',
  templateUrl: './batch-export-pdf.component.html',
  styleUrls: ['./batch-export-pdf.component.scss']
})

export class BatchExportPdfComponent implements OnInit, OnDestroy {
  guid = '';
  detailReportId: number;
  detailData: BatchExportDetail;
  dateRange: DateRange;
  wrvuBenchmarkOption: BenchmarkOption;
  wrvuBenchmarkPercentile: BenchmarkPercentile;
  npvBenchmarkOption: BenchmarkOption;
  npvBenchmarkPercentile: BenchmarkPercentile;
  emTileBenchmarkOption: BenchmarkOption;
  selectedDateRange: string;
  previousSelectedDateRange: string;
  viewCommunityBenchmarks: boolean;
  benchmarkLabel: string;
  nodePath: string;
  nodeName = '';
  splNodeName = '';
  // WRVU
  wrvuData: MonthProductivityData;
  wrvuPreviousYearData: MonthProductivityData;
  benchmarkPlaceholder: string;
  NUMBER_OF_DATA_POINTS: number;
  // NPV
  npvData: MonthNewPatientVisitData;
  npvPreviousYearData: MonthNewPatientVisitData;
  // E and M
  emData: EvaluationManagementMultilevel;
  emSpecialtyData: EvaluationManagementMultilevel;
  emMetrics: EmDimension[] = [];
  // Zip code
  zipCodeNewPatientVisits: ZipCodeNewPatientVisits[] = [];
  showPdfPage = false;
  ftService: Subscription;
  batchService: Subscription;
  batchFilterCriteria: BatchFilterCriteria;
  previousBatchFilterCriteria: BatchFilterCriteria;

  constructor(private router: ActivatedRoute,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(FeatureToggleServiceToken) private readonly featureToggleService: HttpFeatureToggleService) {
  }

  ngOnInit() {
    this.ftService = this.featureToggleService.getBatchExportFeatureToggleSettings()
      .subscribe((featureSettings: FeatureToggleSetting[]) => {
        let setting: FeatureToggleSetting | undefined;
        if (featureSettings && featureSettings.length > 0) {
          setting = featureSettings.find((s: FeatureToggleSetting) => s.featureName.toLowerCase() ===
            FeatureToggleEntries.ENABLE_MULTIPROVIDER_SECURITY.toLowerCase());
        }
        const emailParam = this.router.snapshot.paramMap.get('email') ?? '';
        if (setting && setting.enabled) {
          const reportHeaderIdParam = this.router.snapshot.paramMap.get('reportHeaderId');
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          const guidParam = this.router.snapshot.paramMap.get('guid');
          if (reportIdParam && !isNaN(+reportIdParam) && reportHeaderIdParam && !isNaN(+reportHeaderIdParam) && emailParam && guidParam) {
            this.batchService = this.batchExportService.isValidBatchRequest(+reportHeaderIdParam, emailParam, guidParam)
              .subscribe((resp: boolean) => {
                this.showPdfPage = resp;
                if (resp) {
                  this.guid = guidParam;
                  this.detailReportId = +reportIdParam;
                  this.generatePdf(this.detailReportId);
                }
              });
          }
        } else {
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          if (reportIdParam && !isNaN(+reportIdParam)) {
            this.detailReportId = +reportIdParam;
            this.showPdfPage = true;
            this.generatePdf(this.detailReportId);
          }
        }
      });
  }

  ngOnDestroy() {
    this.ftService?.unsubscribe();
    this.batchService?.unsubscribe();
  }

  generatePdf(detailReportId: number) {
    this.benchmarkLabel = 'Academic';
    this.benchmarkPlaceholder = getBenchmarkLabel(this.wrvuBenchmarkPercentile);
    this.batchExportService.getBatchExportDetailsByDetailId(detailReportId).subscribe((res: BatchExportDetailResponse) => {
      if (res && res.batchExportDetails && res.batchExportDetails.length > 0) {
        this.detailData = res.batchExportDetails[0];
        this.emMetrics = [];
        if (res.batchExportDetails[0].isOutpatientRequired) {
          this.emMetrics.push(EmDimension.Outpatient);
        }
        if (res.batchExportDetails[0].isInPatientRequired) {
          this.emMetrics.push(EmDimension.InPatient);
        }
        if (res.batchExportDetails[0].isEmergencyMedicineRequired) {
          this.emMetrics.push(EmDimension.EmergencyMedicine);
        }
        if (res.batchExportDetails[0].isOpthalmologyRequired) {
          this.emMetrics.push(EmDimension.Ophthalmology);
        }
        this.setUpBenchmarks();
        this.batchFilterCriteria = getBatchFilterFromExportDetail(this.detailData, this.guid);
        this.previousBatchFilterCriteria = getBatchFilterFromExportDetail(this.detailData, this.guid, true);
        const npvTrendDataFilterCriteria = updateFilterCriteriaWithTelehealthFlag(true,
          this.npvBenchmarkOption, this.batchFilterCriteria);
        const previousNpvTrendFilterCriteria = updateFilterCriteriaWithTelehealthFlag(true,
          this.npvBenchmarkOption, this.previousBatchFilterCriteria);
        const emDataFilterCriteria = updateFilterCriteriaWithTelehealthFlag(true,
          this.emTileBenchmarkOption, this.batchFilterCriteria);
        this.nodePath = this.batchFilterCriteria.nodePath;
        this.nodeName = this.detailData.providerName;
        this.splNodeName = this.detailData.specialtyName;
        const specialtyBatchFilter = combineBatchFilter(emDataFilterCriteria, FilterType.Specialty);
        forkJoin({
          npvZipData: this.batchExportService.getNpvZipData(this.batchFilterCriteria),
          wrvuData: this.batchExportService.getWrvuTrendData(this.batchFilterCriteria, SummaryType.SELECTED_PERIOD),
          npvData: this.batchExportService.getNewPatientVisitTrendData(npvTrendDataFilterCriteria, SummaryType.SELECTED_PERIOD),
          wrvuPreviousYearData: this.batchExportService
            .getWrvuTrendData(this.previousBatchFilterCriteria, SummaryType.PREVIOUS_SELECTED_PERIOD),
          npvPreviousYearData: this.batchExportService
            .getNewPatientVisitTrendData(previousNpvTrendFilterCriteria, SummaryType.PREVIOUS_SELECTED_PERIOD),
          emData: this.batchExportService.getEmData(emDataFilterCriteria),
          emSpecialtySummaryData: this.batchExportService.getEmData(specialtyBatchFilter)
        }).subscribe(({npvZipData, wrvuData, npvData, wrvuPreviousYearData, npvPreviousYearData, emData, emSpecialtySummaryData}) => {
          this.emData = emData;
          this.emSpecialtyData = emSpecialtySummaryData;
          this.dateRange = {
            startMonth: roundToNumber(this.batchFilterCriteria.startMonth, 0),
            startYear: roundToNumber(this.batchFilterCriteria.startYear, 0),
            endMonth: roundToNumber(this.batchFilterCriteria.endMonth, 0),
            endYear: roundToNumber(this.batchFilterCriteria.endYear, 0)
          };
          this.NUMBER_OF_DATA_POINTS = getNumberOfMonthsInDateRange(this.dateRange);
          this.selectedDateRange = getSelectedDateRange(this.dateRange, true);
          this.previousSelectedDateRange = getPreviousYearDateRange(this.dateRange, true);
          this.wrvuData = wrvuData;
          this.wrvuPreviousYearData = wrvuPreviousYearData;
          this.npvData = npvData;
          this.npvPreviousYearData = npvPreviousYearData;
          this.zipCodeNewPatientVisits = npvZipData;
        });
      }
    });
  }

  private setUpBenchmarks() {
    this.wrvuBenchmarkOption = setUpBenchmarkOption(this.detailData.wrvuTileBenchmarkType);
    this.wrvuBenchmarkPercentile = setUpBenchmarkPercentile(this.detailData.wrvuTileBenchmarkPercentile);
    this.npvBenchmarkOption = setUpBenchmarkOption(this.detailData.npvTileBenchmarkType);
    this.npvBenchmarkPercentile = setUpBenchmarkPercentile(this.detailData.npvTileBenchmarkPercentile);
    this.emTileBenchmarkOption = setUpBenchmarkOption(this.detailData.emTileBenchmarkType);
  }
}
