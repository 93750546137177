import {Inject, Injectable, InjectionToken} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable} from 'rxjs';
import {ontologyLoadedTo} from '../store/actions';
import {FilterSelectorService, FilterSelectorServiceToken} from './filter-selector.service';
import {OntologyHierarchyService, OntologyHierarchyServiceToken} from './ontology-hierarchy.service';
import {distinctUntilChanged, skip} from 'rxjs/operators';
import * as _ from 'lodash';
import {DateRange} from '../shared/models';
import {setOntologyData} from '../shared/localStoragehelper';
import {IAppState} from '../store/IAppState';
import {now} from 'moment';

export const DepartmentSpecialtyListenerServiceToken = new InjectionToken<DepartmentSpecialtyListenerService>(
  'Department Specialty Listener Service');

@Injectable()
export class DepartmentSpecialtyListenerService {
  @select(['filters', 'dateRange'])
  private readonly dateRange$: Observable<DateRange>;
  ontologyCall: any;

  constructor(
    @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
    @Inject(OntologyHierarchyServiceToken) private ontologyHierarchyService: OntologyHierarchyService,
    private readonly ngRedux: NgRedux<IAppState>,
  ) {
    combineLatest([
      this.dateRange$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.filterService.getMemberKeys().pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
    ]).pipe(skip(1)).subscribe(() => {
      this.ontologyCall?.unsubscribe();
      const filter = this.ngRedux.getState().filters;
      const startTime = now();
      this.ontologyCall = this.ontologyHierarchyService.getOntologyHierarchies(filter).subscribe(ontologyData => {
        const endTime = now();
        console.log('Ontology took: ' + (endTime - startTime) + 'ms.');
        setOntologyData(ontologyData);
        this.ngRedux.dispatch(ontologyLoadedTo(ontologyData));
      });
    });
  }
}
