import {Component, OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {Output} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {ExportOption} from '../shared/export/export-metrics';
import {ExcelExportMetricId} from '../shared/export/export';

@Component({
  selector: 'app-recursive-hierarchical-export-option',
  templateUrl: './recursive-hierarchical-export-option.component.html',
  styleUrls: ['./recursive-hierarchical-export-option.component.scss']
})
export class RecursiveHierarchicalExportOptionComponent implements OnInit {

  @Input() exportOptions: ExportOption[];
  @Input() initiallyExpanded: ExcelExportMetricId[];
  @Output() selectedExportOption = new EventEmitter<ExportOption>();

  greyMetricMessage = 'Metrics in gray are not yet loaded. Navigate to the metric page to load and export';
  currentlyExpanded?: ExcelExportMetricId;

  constructor() {
  }

  ngOnInit(): void {
    this.currentlyExpanded = this.initiallyExpanded?.shift();
  }

  setCurrentlyExpanded(option: ExportOption, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!option.loading) {
      this.currentlyExpanded = this.currentlyExpanded === option.id ? ExcelExportMetricId.ALL_METRICS : option.id;
    }
  }

  updateExcelExportTypes(option: ExportOption, event?: MouseEvent) {
    event?.preventDefault();
    if (option.loading) {
      return;
    }
    this.selectedExportOption.emit(option);
  }

}
