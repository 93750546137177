import {oktaTokenLoadedTo} from '../store/actions';
import {IAppState} from '../store/IAppState';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {

  @select(['display', 'filterBannerEdit'])
  readonly filterBannerEdit$: Observable<boolean>;

  scrollY = 0;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  constructor(
    private readonly ngRedux: NgRedux<IAppState>,
  ) {}

  ngOnInit(): void {
    const oktaToken = localStorage.getItem('okta-token-storage');
    if (oktaToken) {
      const token: string = JSON.parse(oktaToken).accessToken.accessToken;
      this.ngRedux.dispatch(oktaTokenLoadedTo(token));
    }
  }
}
