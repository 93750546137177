import {Component, Input, OnChanges} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Options, SeriesOptionsType} from 'highcharts/highstock';
import {CptCategories, EmDimension, ProviderMultilevelCptCounts} from '../../../../shared/models';
import {monthNames} from '../../../../shared/helpers';
import {
  AllEvaluationManagementCategories,
  CptCategoryViewType,
  CptRangeViewType,
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  getEvaluationManagementViewTypeText,
  NewPatientVisitCodes,
  SelectableCpt,
  stackColorWheel
} from '../../../em-helpers';
import {isValidOrElse, objectHasValue, undefinedIfInvalid} from '../../../../shared/null-helpers';
import {Observable} from 'rxjs';
import {MetricType} from '../../../../shared/metric-types';
import {select} from '@angular-redux/store';
import {ChartType} from '../../../../shared/highcharts-helpers';
import {BenchmarkOption, HighChartColorIndex} from '../../../../shared/enums';
import {BenchmarkHelperObject, extendedBenchmarkOptions} from '../../../../shared/benchmark-types';

@Component({
  selector: 'app-em-trend-chart',
  templateUrl: './em-trend-chart.component.html',
  styleUrls: ['./em-trend-chart.component.scss']
})
export class EmTrendChartComponent implements OnChanges {

  static tooltipData: ProviderMultilevelCptCounts[];
  static tooltipViewType: CptCategoryViewType;
  static tooltipSpecialtyCptCounts: CptCategories;
  static tooltipBenchmarkOption: BenchmarkOption;
  static tooltipFoundNodeName: string;
  static tooltipLegends: string[];
  static tooltipMonths: string[];
  stackColorWheel = stackColorWheel;
  @Input() fromPdf = false;
  @Input() dimension: EmDimension;
  @Input() data: ProviderMultilevelCptCounts[];
  @Input() specialtyCptCounts: CptCategories;
  @Input() foundNodeName: string;
  @Input() showLoadingIndicator: boolean;
  @Input() viewType: CptRangeViewType;
  @Input() benchmarkOption: BenchmarkOption;
  chartObject: Chart;
  options: Options;
  viewTypeList: CptRangeViewType[] = [];
  legendLabels = NewPatientVisitCodes.codes;
  cptCodeSelections: SelectableCpt[];
  selectedCpt: SelectableCpt;
  MetricType = MetricType;
  legendBenchmarkPdfLabel = '';

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly benchmarkOption$: Observable<BenchmarkOption>;

  constructor() { }

  ngOnChanges(): void {
    this.viewTypeList = this.fromPdf ? [] : this.getViewTypeList();
    this.viewType = this.viewType || this.viewTypeList[0];
    this.resetTooltips();
    this.setCptList();
    this.drawGraph();
    if (this.fromPdf) {
      const benchmarkOptions: BenchmarkHelperObject[] = extendedBenchmarkOptions;
      const foundExtendedBenchmarkOption = benchmarkOptions.find(o =>
        (this.benchmarkOption || BenchmarkOption.Academic) === o.value);
      this.legendBenchmarkPdfLabel = foundExtendedBenchmarkOption?.name || this.legendBenchmarkPdfLabel;
    }
  }

  private resetTooltips() {
    EmTrendChartComponent.tooltipData = this.data;
    EmTrendChartComponent.tooltipViewType = this.viewType.cptCategoryViewType;
    EmTrendChartComponent.tooltipSpecialtyCptCounts = this.specialtyCptCounts;
    EmTrendChartComponent.tooltipBenchmarkOption = this.benchmarkOption;
    EmTrendChartComponent.tooltipFoundNodeName = this.foundNodeName;
    EmTrendChartComponent.tooltipMonths = this.data.map(d => {
      return monthNames[+d.nodePath - 1] + ' ' + d.nodeName;
    });
  }

  private getViewTypeList(): CptRangeViewType[] {
    let cptCategoryViewTypes: CptCategoryViewType[];
    switch (this.dimension) {
      case EmDimension.Outpatient:
        cptCategoryViewTypes = [CptCategoryViewType.NewPatient, CptCategoryViewType.EstablishedPatient,
          CptCategoryViewType.Consults];
        break;
      case EmDimension.InPatient:
        cptCategoryViewTypes = [CptCategoryViewType.InitialHospital, CptCategoryViewType.SubsequentHospital,
          CptCategoryViewType.HospitalDischarge];
        break;
      case EmDimension.EmergencyMedicine:
        cptCategoryViewTypes = [CptCategoryViewType.EmergencyMedicine];
        break;
      case EmDimension.Ophthalmology:
        cptCategoryViewTypes = [CptCategoryViewType.EyeExamNew, CptCategoryViewType.EyeExamEstablished];
    }
    return cptCategoryViewTypes.map(vt => {
      return {cptCategoryViewType: vt, text: getEvaluationManagementViewTypeText(vt)};
    });
  }

  private setCptList(): void {
    const codes = AllEvaluationManagementCategories.find(cat => cat.viewType === this.viewType.cptCategoryViewType)?.codes || [];
    this.cptCodeSelections = [{text: 'View All', all: true}].concat(codes.map(c => ({text: c, all: false})));
    this.selectedCpt = this.cptCodeSelections[0];
  }

  onViewTypeSelected = (vt: CptRangeViewType) => {
    if (this.viewType === vt) {
      return;
    }
    this.viewType = vt;
    EmTrendChartComponent.tooltipViewType = vt.cptCategoryViewType;
    this.setCptList();
    this.drawGraph();
  };

  onCptSelected = (cpt: SelectableCpt) => {
    if (this.selectedCpt === cpt) {
      return;
    }
    this.selectedCpt = cpt;
    this.drawGraph();
  };

  private drawGraph(): void {
    this.options = this.getOptions();
    this.chartObject = new Chart(this.options);
  }

  private getOptions(): Options {
    return {
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      chart: {
        height: 280,
        width: 1280,
        styledMode: true,
        animation: !this.fromPdf,
        type: ChartType.COLUMN
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 0
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: this.getEmTrendSeries(),
      yAxis: {
        min: 0, max: 100,
        title: {
          text:
            '<strong>% Coding Distribution</strong>'
        },
        labels: {
          enabled: false
        }
      },
      // @ts-ignore
      xAxis: {
        categories: this.data.map(x => monthNames[+x.nodePath - 1] + ' ' + x.nodeName)
      },
      // @ts-ignore
      tooltip: {
        formatter: function () {
          const stackName = this.series.name;
          const index = EmTrendChartComponent.tooltipMonths.indexOf('' + this.x);
          const stackNumber = EmTrendChartComponent.tooltipLegends.indexOf(stackName);
          let tooltipEntry;
          let specialtyPercentage;
          const categoryString: string = AllEvaluationManagementCategories.find(cat => cat.viewType ===
            EmTrendChartComponent.tooltipViewType)?.range || '';
          if (EmTrendChartComponent.tooltipData[index] && EmTrendChartComponent.tooltipData[index].cptCategories[categoryString]) {
            tooltipEntry = EmTrendChartComponent.tooltipData[index].cptCategories[categoryString]['cpt' + stackName];
          }
          if (EmTrendChartComponent.tooltipSpecialtyCptCounts && objectHasValue(EmTrendChartComponent.tooltipSpecialtyCptCounts)) {
            specialtyPercentage =
              undefinedIfInvalid(EmTrendChartComponent.tooltipSpecialtyCptCounts, categoryString, 'cpt' + stackName)?.percentage;
          }
          const field =
            getBenchmarkFieldFromExtendedBenchmarkOptions(EmTrendChartComponent.tooltipBenchmarkOption);
          const benchmark = (tooltipEntry ? tooltipEntry[field] : '-') ?? '-';
          const benchmarkString =
            getBenchmarkMeanNameFromExtendedBenchmarkOptions(EmTrendChartComponent.tooltipBenchmarkOption);
          const color = '<span style="color:' + stackColorWheel[stackNumber] + '">\u25CF' + '</span>';
          let returnString = stackName + color + '<br/>';
          returnString += '<br>% Coding Distribution: ' + '<span style="font-weight: bold">' +
            tooltipEntry?.percentage + '</span>' + '</br>';
          returnString += '<br>' + benchmarkString + ': ' + '<span style="font-weight: bold">' +
            benchmark + '</span>' + '</br>';
          if (EmTrendChartComponent.tooltipFoundNodeName) {
            returnString += '<br>% ' + EmTrendChartComponent.tooltipFoundNodeName + ': ' + specialtyPercentage + '</br>';
          }
          return returnString;
        }
      }
    };
  }

  private getEmTrendSeries(): Array<SeriesOptionsType> {
    const series: SeriesOptionsType[] = [];
    const category = AllEvaluationManagementCategories.find(cat => cat.viewType === this.viewType.cptCategoryViewType);
    this.legendLabels = category?.codes || [];
    EmTrendChartComponent.tooltipLegends = this.legendLabels;
    const categoryString = category?.range;
    for (let idx = this.legendLabels.length - 1; idx >= 0; idx--) {
      if (this.selectedCpt.all || this.selectedCpt.text === this.legendLabels[idx]) {
        series.push({
          name: this.legendLabels[idx],
          colorIndex: this.getColorIndexForStackBar(idx),
          yAxis: 0,
          data: this.data.map(x => +isValidOrElse(x.cptCategories[categoryString || ''], 'cpt' + this.legendLabels[idx],
            'percentage', 0)),
          type: ChartType.COLUMN,
          animation: !this.fromPdf
        });
      }
    }
    return series;
  }

  private getColorIndexForStackBar(barIndex: number): number {
    const colorOffset = HighChartColorIndex.DARK_BLUE_STACK.valueOf();
    const colorIndex = colorOffset + barIndex;
    return colorIndex;
  }
}
