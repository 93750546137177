import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IAppState} from '../store/IAppState';
import {NgRedux, select} from '@angular-redux/store';
import {getPageFromUrl, Page} from '../shared/helpers';
import {Observable, Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {ExportPdfDialogComponent} from '../export-pdf-dialog/export-pdf-dialog.component';
import {FeatureToggleSetting} from '../shared/models';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  page: Page;
  shouldPayersBeGranular = false;
  payerTabPermitted = false;
  lagKeyTabPermitted = false;
  billingAreaTabPermitted = false;
  invoiceStatusTabPermitted = false;
  visitTypeTabPermitted = false;
  scrollY = 0;
  private routerSubscription: Subscription;
  private readonly pagesWithGranularPayers: Page[] = [
    Page.CollectionsSnapshot,
    Page.CollectionsByPayer,
    Page.CollectionsSummary,
    Page.CollectionsDepartment,
    Page.CollectionsSpecialty,
    Page.CollectionsProvider,
    Page.DenialsDepartment,
    Page.DenialsSpecialty,
    Page.DenialsProvider,
    Page.DenialsByPayer
  ];

  @ViewChild('pdfContent', {static: true}) pdfContent: ExportPdfDialogComponent;

  @select(['display', 'filterBannerEdit'])
  readonly filterBannerEdit$: Observable<boolean>;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.setFilterTabsAvailabilities(this.router.url);
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        this.setPage(url);
        this.setFilterTabsAvailabilities(url);
        this.setPayerGranularity();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  private setPage(url: string) {
    this.page = getPageFromUrl(url);
  }

  private setPayerGranularity() {
    this.shouldPayersBeGranular = !!this.pagesWithGranularPayers.find(page => page === this.page);
  }

  private setFilterTabsAvailabilities(url: string) {
    this.setPayerTabAvailability(url);
    this.setLagTabAvailability(url);
    this.setBillingAreaTabAvailability(url);
    this.setInvoiceStatusTabAvailability(url);
    this.setVisitTypeTabAvailability(url);
  }

  private setVisitTypeTabAvailability(url: string) {
    this.visitTypeTabPermitted = url.includes('overview')
      || url.includes('npv') || url.includes('em');
  }

  private setInvoiceStatusTabAvailability(url: string) {
    this.invoiceStatusTabPermitted = url.includes('overview')
      || url.includes('collections');
  }

  private setBillingAreaTabAvailability(url: string) {
    this.billingAreaTabPermitted = url.includes('overview')
      || url.includes('collections')
      || url.includes('denials');
  }

  private setLagTabAvailability(url: string) {
    this.lagKeyTabPermitted = url.includes('overview')
      || url.includes('collections')
      || url.includes('denials');
  }

  private setPayerTabAvailability(url: string) {
    this.payerTabPermitted = url.includes('overview')
      || url.includes('npv')
      || url.includes('collections')
      || url.includes('denials');
  }
}
