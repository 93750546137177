<div id="lag-list-wrapper">
  <span id="header">Lag Period</span>
  <div class="note">Note: Lag Period is only applicable to <b>Collections</b> and <b>Denials</b>.</div>
  <div>Note: The NCR benchmarks represent a full calendar year. To assess your performance to these
    benchmarks, we recommend you look at invoices that have aged at least 6 months.
  </div>
  <mat-radio-group id="lag-list">
    <mat-radio-button [checked]="allSelected" [class.active]="allSelected" [value]="DEFAULT_LAG_NAME"
                      (click)="selectAllActivity($event)" class="all-option">{{DEFAULT_LAG_NAME}}
    </mat-radio-button>
    <mat-radio-button *ngFor="let lag of lagPeriods" [checked]="!allSelected && lag.item.selected"
                      [class.active]="!allSelected && lag.item.selected" [value]="lag.item.displayText"
                      (click)="whenLagPeriodClicked(lag, $event)" class="option">{{lag.item.displayText}}
    </mat-radio-button>
  </mat-radio-group>
</div>
