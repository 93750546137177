<span id="ps-filter-selector-wrapper">
  <mat-label id="filter-by-label">Filter Graph Data by {{getFilterTypeText()}}</mat-label>
  <span [ngClass]="'select-filter-graph-by'.concat(searchOpen ? '-open' : '-closed')" (click)="toggleSearchPanel()">
    {{selectGraphByText}} <i [ngClass]="'fa fa-caret'.concat(searchOpen ? '-up' : '-down')"></i></span>
  <mat-form-field *ngIf="searchOpen" id="form-field-autocomplete" appearance="outline" color="accent" subscriptSizing="dynamic">
    <span id="input-wrapper">
      <input matInput #input type="text" [formControl]="autocompleteControl" [matAutocomplete]="inputAutoComplete"
         id="search-for" [placeholder]="viewByNode ? selectedCptNode ? selectedCptNode.display :
          '' : currentSelectedOntologyCptNode ? currentSelectedOntologyCptNode.display : ''" (input)="whenInput($event)"
             [ngModel]="searchText"><i *ngIf="searchText.length < minSearchChars" class="fa fa-search"></i>
        <mat-icon *ngIf="searchText.length >= minSearchChars" matSuffix aria-label="Clear" id="clear-button" matRipple
                [matRippleCentered]=true [matRippleRadius]=18 (click)="clearSearchText($event)">close
        </mat-icon>
    </span>
    <mat-autocomplete #inputAutoComplete="matAutocomplete" (optionSelected)="applyViewBy($event)" (closed)="closeDropdown()">
      <cdk-virtual-scroll-viewport class="autocomplete-test-viewport" [ngStyle]="{'height': autocompleteViewportHeight}"
        itemSize="25">
        <mat-option *cdkVirtualFor="let option of filteredOptions" [value]="option"
                  [ngClass]="option.identity === '' ? 'all-option' : ''">{{option.display}}
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
  <span *ngIf="!searchOpen" id="clear-selections" (click)="clearTheSelections()"><i class="fa fa-times" *ngIf="canDisplayClearSelections">
  </i>{{canDisplayClearSelections ? 'Clear' : ''}}</span>
</span>
