<div id="npv-graph-wrapper">
  <div class="chart-header">
    <div class="graph-title-wrapper">
      <h2 class="graph-title">% New Patients by Month</h2>
    </div>
    <app-variable-container [variables]="variables" [showVariableMenu]="showVariableMenu"
                            [page]="page"></app-variable-container>
  </div>
  <div [chart]="chartObject" id="month-new-patient-visits-graph"></div>
  <app-chart-legend
          [metricType]="metricType.NewPatientVisits"
          [legends]="legends"
          [hasBenchmarks]="hasBenchmarks"
          [benchmarksToExclude]="benchmarkToExclude">
  </app-chart-legend>

  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>

</div>
