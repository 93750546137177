import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../shared/models';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../store/IAppState';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';
import * as _ from 'lodash';
import {ConfirmDialogComponent} from '../../shared/components/confirm-dialog/confirm-dialog.component';
import {
  ChangeableCustomGroup,
  DEFAULT_MAX_PRELOAD_GROUPS,
  GroupDialogEvent,
  ManageableGroup,
  ManageGroupingsInfo
} from '../manage-groups-helper';

@Component({
  selector: 'app-updated-manage-groups',
  templateUrl: './updated-manage-groups.component.html',
  styleUrls: ['./updated-manage-groups.component.scss']
})
export class UpdatedManageGroupsComponent implements OnInit {
  canSave = false;
  maxPreloadGroupings = DEFAULT_MAX_PRELOAD_GROUPS;
  changeableCustomGroups: ChangeableCustomGroup[] = [];
  currentPreloads = 0;
  showPreloadError = false;
  warningText = '';
  shouldShowPreload = false;
  readonly maxCharsForGroupName = 29; // TODO this seems hardcoded.

  constructor(public dialogRef: MatDialogRef<UpdatedManageGroupsComponent>,
              private ngRedux: NgRedux<IAppState>,
              public dialog: MatDialog,
              public deleteDialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public manageGroupingsInfo: ManageGroupingsInfo) {
  }

  ngOnInit(): void {
    this.shouldShowPreload = this.manageGroupingsInfo.preloadable;
    const maxPreloadedGroups = this.ngRedux.getState().data.applicationConfigurationSettings
      .find(config => config.appConfigName === AppConfigEntries.MAX_PRELOADED_GROUPS);
    this.maxPreloadGroupings = +(maxPreloadedGroups?.appConfigDesc || DEFAULT_MAX_PRELOAD_GROUPS);
    this.changeableCustomGroups = this.manageGroupingsInfo.changeableCustomGroups.slice();
    this.currentPreloads = this.getNumberOfPreloads();
  }

  getNumberOfPreloads(): number {
    return this.changeableCustomGroups.filter(g => g.newPreload && !g.newDefault).length;
  }

  private searchForChanges(): boolean {
    for (const group of this.manageGroupingsInfo?.changeableCustomGroups) {
      if (this.manageGroupingsInfo.hasManageableChanges(group)) {
        return true;
      }
    }
    return false;
  }

  whenGroupNameChanged(group: ChangeableCustomGroup, event: any) {
    group.newName = event.target.value;
    this.canSave = this.searchForChanges();
    this.warningText = '';
    this.manageGroupingsInfo.changeableCustomGroups.forEach(c => {
      c.duplicateAttempt = c.original.name.localeCompare(group.newName) === 0 &&
       !(c.original.name.localeCompare(group.original.name) === 0);
      if (c.duplicateAttempt) {
        this.warningText = 'A group with the same name exists!';
      }
    });
  }

  markForDefault(group: ChangeableCustomGroup, event: MouseEvent) {
    group.newDefault = !group.newDefault;
    group.newPreload = group.newDefault;
    if (group.newDefault) {
      this.manageGroupingsInfo.changeableCustomGroups.filter(g => !_.isEqual(g.original, group.original)).forEach(item => {
        if (item.newDefault) {
          item.newPreload = false;
        }
        item.newDefault = false;
      });
    }
    this.currentPreloads = this.getNumberOfPreloads();
    this.canSave = this.searchForChanges();
  }

  markForPrecalc(group: ChangeableCustomGroup, event: MouseEvent) {
    event.preventDefault();
    if (group.newDefault) {
      return;
    }
    if (!group.newPreload && this.currentPreloads === this.maxPreloadGroupings) {
      this.showPreloadError = true;
      return;
    }
    this.showPreloadError = false;
    group.newPreload = !group.newPreload;
    this.currentPreloads = this.getNumberOfPreloads();
    this.canSave = this.searchForChanges();
  }

  delete(group: ManageableGroup): void {
    let dialog = this.deleteDialogRef;
    const dialogData: DialogData = {
      question: group.name + ' will be deleted, continue or cancel?',
      confirmCallback: () => {
        dialog.close();
        this.confirmDelete(group);
      }
    };
    this.deleteDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    dialog = this.deleteDialogRef;
  }

  confirmDelete(groupToDelete: ManageableGroup): void {
    if (this.manageGroupingsInfo.component) {
      this.manageGroupingsInfo.component.deletedGroup = groupToDelete;
    }
    if (groupToDelete.id === this.manageGroupingsInfo.component?.currentSelectedGroup?.id) {
      this.manageGroupingsInfo.dialogActionListener.next(GroupDialogEvent.DELETE_CURRENTLY_SELECTED);
    }
    this.manageGroupingsInfo.dialogActionListener?.next(GroupDialogEvent.DELETE);
    this.changeableCustomGroups = this.changeableCustomGroups.filter(g => g.original.id !== groupToDelete.id);
    this.currentPreloads = this.getNumberOfPreloads();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.warningText.length >= 1 || !this.canSave) {
      return;
    }
    if (this.manageGroupingsInfo.component) {
      this.manageGroupingsInfo.component.groupsAfterSave = this.changeableCustomGroups;
    }
    this.manageGroupingsInfo.dialogActionListener?.next(GroupDialogEvent.MANAGE_SAVE);
    this.cancel();
  }
}
