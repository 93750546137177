import {Component, Input, OnInit} from '@angular/core';
import {CurrentMemberLocation, MemberLocation, PayerCategory} from '../shared/models';

@Component({
  selector: 'app-pdf-header',
  templateUrl: './pdf-header.component.html',
  styleUrls: ['./pdf-header.component.scss']
})
export class PdfHeaderComponent implements OnInit {

  @Input() locationSelection: MemberLocation;
  @Input() payerSelection: PayerCategory;
  @Input() visitTypeSelection: string;
  @Input() nodePathSelection = '';
  @Input() selectedDate = '';
  @Input() communityBenchmarkAlert: boolean;
  @Input() communityBenchmarkAlertText: string;
  @Input() currentLocations: CurrentMemberLocation[];
  @Input() maxLocations: number;

  locationText: string;
  payerText: string;
  visitTypeText: string;

  constructor() {
  }

  ngOnInit() {
    if (this.currentLocations.length === 0) {
      this.locationText = this.locationSelection ? 'Location: ' + this.locationSelection.memberLocationName : '';
    } else {
      const selectedLocations = this.currentLocations.filter(loc => loc.currentlySelected);
      if (selectedLocations.length === 0) {
        this.locationText = 'Location: All Locations';
      } else {
        const additionalLocations = selectedLocations.length - this.maxLocations;
        this.locationText = 'Location: ' + selectedLocations.slice(0, this.maxLocations).map(x => x.memberLocationName).join(', ') +
          (additionalLocations > 0 ? (' + ' + additionalLocations + ' more') : '');
      }
    }
    this.payerText = this.payerSelection ? 'Payer Category: ' + this.payerSelection.payerCategoryDescription : '';
    this.visitTypeText = this.visitTypeSelection ? 'Visit Type: ' + this.visitTypeSelection : '';
  }
}
