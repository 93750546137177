<div id="display-message-wrapper" *ngIf="!showCfp">
  <div id="displayMessage">
    To view Clinical Fingerprint, please select a single department/specialty combination or
    <br> provider(s) within a single department/specialty combination.
  </div>
</div>

<div id="ClinicalFingerprint" *ngIf="showCfp">

  <span class="cfp-head-wrapper">
  <span id="cfp-head">
    <app-toggle-cpt-view-type [displayViewType]="true"
                              [isSpecialty]="isSpecialty"
                              [isDepartment]="isDepartment">
    </app-toggle-cpt-view-type>
    <span class="right-header-wrapper">
        <app-cfpt-toggle-variables *ngIf="showAdditionalCFPColumns"
                                   [cfpVariableViewType]="cfpVariableViewType"
        >
        </app-cfpt-toggle-variables>
      <span class="variable-container">
      <app-variable-container
        [showVariableMenu]="true"
        [variables]="variables"
        [disabled]="activeVarianceToggle"
        [page]="metricPage">
      </app-variable-container>
      </span>
      <app-variance-toggler class="variance-toggler" [varianceToggleAction]="varianceToggle"
      ></app-variance-toggler>
    </span>
  </span>
</span>

  <div *ngIf="cptViewType === CptViewType.CptFamily" id="cptFamily">
    <div class="graph-container">
      <app-clinical-fingerprint-chart
        [barSelectionCallBack]="cptCallBack"
        [clinicalSummaryData]="clinicalFamilySummaryData"
        [cptViewType]="cptViewType"
        [cfpVariableViewType]="cfpVariableViewType"
        [showProgressBar]="showProgressBar && showCfp"
        [activeVarianceToggle]="activeVarianceToggle"
        [varianceColumn]="varianceKey"
        [benchmarkColumn]="benchmarkColumn"
        [showAdditionalCfpColumns]="showAdditionalCFPColumns"
        [showVariableMenu]="true"
        page="ClinicalFingerprint"
        [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      >
      </app-clinical-fingerprint-chart>
    </div>
    <app-clinical-fingerprint-table id="cptfamily-clinical-data-table"
                                    [showProgressBar]="showProgressBar && showCfp"
                                    [tableCssClass]="'family-table'"
                                    [receivedData]="clinicalFamilySummaryData"
                                    [columns]="columns"
                                    [defaultSortColumn]="defaultSortColumn"
                                    [whenSortChanged]="whenSortChanged"
                                    [sortDirection]="sortDirection"
                                    [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                                    [tieBreakerProperty]="tieBreakerPropertyForCptFamily"
                                    [sortingDataAccessor]="sortingDataAccessor"
                                    [zeroSuppressionCondition]="condition"
                                    [isZeroSuppressionChecked]="zeroSuppression"
                                    [displayZeroSuppression]="true"
                                    [snapshotEntryName]="familySnapshotName"
                                    [countOfSuppressedEntries]="countOfSuppressedFamilyEntries"
                                    [tableTitle]="'cFTE adj wRVUs by CPT Family'"
                                    [rowSelectionCallback]="rowIsSelected"
    >
    </app-clinical-fingerprint-table>
  </div>
  <div *ngIf="cptViewType === CptViewType.CptRange" id="cptRange">
    <div class="graph-container">
      <app-clinical-fingerprint-chart
        [barSelectionCallBack]="cptCallBack"
        [clinicalSummaryData]="clinicalRangeSummaryData"
        [cptViewType]="cptViewType"
        [cfpVariableViewType]="cfpVariableViewType"
        [showProgressBar]="showProgressBar && showCfp"
        [activeVarianceToggle]="activeVarianceToggle"
        [showAdditionalCfpColumns]="showAdditionalCFPColumns"
        [viewCommunityBenchmarks]="viewCommunityBenchmarks"
        [varianceColumn]="varianceKey"
        [showVariableMenu]="true"
        page="ClinicalFingerprint"
      >
      </app-clinical-fingerprint-chart>
    </div>

    <app-clinical-fingerprint-table id="cptrange-clinical-data-table"
                                    [showProgressBar]="showProgressBar && showCfp"
                                    [tableCssClass]="'range-table'"
                                    [receivedData]="clinicalRangeSummaryData"
                                    [columns]="columns"
                                    [defaultSortColumn]="defaultSortColumn"
                                    [whenSortChanged]="whenSortChanged"
                                    [sortDirection]="sortDirection"
                                    [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                                    [tieBreakerProperty]="tieBreakerPropertyForCptRange"
                                    [sortingDataAccessor]="sortingDataAccessor"
                                    [zeroSuppressionCondition]="condition"
                                    [isZeroSuppressionChecked]="zeroSuppression"
                                    [displayZeroSuppression]="true"
                                    [snapshotEntryName]="rangeSnapshotName"
                                    [countOfSuppressedEntries]="countOfSuppressedRangeEntries"
                                    [tableTitle]="'cFTE adj wRVUs by CPT Range'"
                                    [rowSelectionCallback]="rowIsSelected"
    >
    </app-clinical-fingerprint-table>
  </div>
  <div  *ngIf="cptViewType === CptViewType.CptCode" id="cptCode">
    <div class="graph-container">
      <app-clinical-fingerprint-chart
        [barSelectionCallBack]="cptCallBack"
        [clinicalSummaryData]="clinicalCodeSummaryData"
        [cptViewType]="cptViewType"
        [cfpVariableViewType]="cfpVariableViewType"
        [showProgressBar]="showProgressBar && showCfp"
        [showAdditionalCfpColumns]="showAdditionalCFPColumns"
        [activeVarianceToggle]="activeVarianceToggle"
        [varianceColumn]="varianceKey"
        [viewCommunityBenchmarks]="viewCommunityBenchmarks"
        [showVariableMenu]="true"
        page="ClinicalFingerprint"
      >
      </app-clinical-fingerprint-chart>
    </div>

    <app-clinical-fingerprint-table id="cptcode-clinical-data-table"
                                    [showProgressBar]="showProgressBar && showCfp"
                                    [tableCssClass]="'code-table'"
                                    [receivedData]="clinicalCodeSummaryData"
                                    [columns]="columns"
                                    [defaultSortColumn]="defaultSortColumn"
                                    [whenSortChanged]="whenSortChanged"
                                    [sortDirection]="sortDirection"
                                    [isZeroSuppressionChecked]="zeroSuppression"
                                    [displayZeroSuppression]="true"
                                    [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                                    [tieBreakerProperty]="tieBreakerPropertyForCptCode"
                                    [sortingDataAccessor]="sortingDataAccessor"
                                    [zeroSuppressionCondition]="condition"
                                    [snapshotEntryName]="codeSnapshotName"
                                    [countOfSuppressedEntries]="countOfSuppressedCodeEntries"
                                    [tableTitle]="'cFTE adj wRVUs by CPT Code'"
    >
    </app-clinical-fingerprint-table>
  </div>

</div>

<div class="hideCfp" *ngIf="!showCfp"></div>
