import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import {
  AxisLabelsFormatterContextObject,
  Options,
  PlotColumnOptions,
  PointClickEventObject,
  YAxisOptions
} from 'highcharts/highstock';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable} from 'rxjs';
import {Legend, SortingCriterion} from '../../../../shared/models';
import {MetricType} from '../../../../shared/metric-types';
import {IAppState} from '../../../../store/IAppState';
import {
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  setTickValue,
  StyledChart
} from '../../../../shared/highcharts-helpers';
import {getBenchmarkSeriesName, getFirstNodePathFrom, toTitleCase} from '../../../../shared/helpers';
import {GraphColors} from '../../../../productivity-summary/colors';
import {abbreviateAxisValue, roundToNumber} from '../../../../productivity-summary/number-formatter';
import {concat} from '../../../../shared/ourLodash';
import {Variable} from '../../../../variable-container/variable-container.component';
import {Chart} from 'angular-highcharts';
import {CHART_HEIGHT} from '../../../../productivity-summary/productivity-graph-component';
import {updateCommunityBenchmarkAlertStatus} from '../../../../shared/reducer-helper';
import {AppAction, npvSnapshotSortingCriteriaChangedTo, npvVarianceToggleChangedTo} from '../../../../store/actions';
import {VarianceToggleActions, VarianceToggleField} from '../../../../variance-toggler/variance-toggler.component';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../../../shared/benchmark-types';
import {
  BenchmarkOption,
  ColumnType,
  HighChartColorIndex,
  LegendColor,
  LegendStyle,
  MultilevelTab,
  SortingOrder
} from '../../../../shared/enums';
import {hasNonZeroValue, hasValue} from '../../../../shared/null-helpers';
import {
  multilevelNpvDeptColumns,
  multilevelNpvProvColumns,
  multilevelNpvSpecColumns,
  NewPatientVisitSnapshotMultiLevel
} from '../../npv-models';
import {
  getDesignatedNpvBenchmarkObject,
  getDesignatedNpvVarianceObject,
  sortSnapshotChartEntries
} from '../../npv-helpers';
import {BenchmarkPercentilesForNpv} from '../../../../shared/BenchmarkColumns';
import {IndividualSeriesOptions} from '../../../../shared/highcharts-mappings';
import {DataTableColumns} from '../../../../shared/data-table-columns';

@Component({
  selector: 'app-percentage-of-new-patients-multilevel-chart',
  templateUrl: './percentage-of-new-patients-multilevel-chart.component.html',
  styleUrls: ['./percentage-of-new-patients-multilevel-chart.component.scss']
})
export class PercentageOfNewPatientsMultilevelChartComponent implements OnChanges {

  @Input() barSelectionCallback: (nodePath: string) => void;
  @Input() showProgressBar: boolean;
  @Input() level: string;
  @Input() chosenTab: MultilevelTab;
  @Input() showVariableMenu: boolean;
  @Input() variables: Variable[];
  @Input() page: string;
  @Input() isSpecialty: boolean;
  @Input() isDepartment: boolean;
  @Input() npvData: NewPatientVisitSnapshotMultiLevel[] = [];
  @Input() zeroSuppression = false;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() activeVarianceToggle = false;
  @Input() sortingCriteria: SortingCriterion | undefined = undefined;
  @Input() varianceKey = 'varianceMean';
  @Input() showExtendedBenchmarkOptions = false;
  @Input() tieBreakerProperty?: (element: NewPatientVisitSnapshotMultiLevel) => string;

  @ViewChild('chartNewPatientVisitsTarget', {static: true})
  public chartTarget: ElementRef;

  @select(['display', 'patientVisitCounts'])
  private readonly patientVisitCounts$: Observable<boolean>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  readonly MetricType = MetricType;
  private showPatientVisitCounts: boolean;
  benchmarkToExclude = ['65th Percentile'];

  newPatientVisits: NewPatientVisitSnapshotMultiLevel[];
  hasCommunityBenchmarks: boolean;
  hasAcademicBenchmarks: boolean;
  hasBenchmarks: boolean;
  options: Options;
  chartObject: Chart;
  varianceToggle: VarianceToggleActions = {
    display: false,
    sortingCriterion: {
      sortingOrder: SortingOrder.DESCENDING,
      columnDef: 'varianceMean',
      columnType: ColumnType.VARIANCE
    },
    reducerField: VarianceToggleField.NpvVarianceToggle,
    dispatchAction(display: boolean): AppAction {
      return npvVarianceToggleChangedTo(display);
    },
    sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction {
      return npvSnapshotSortingCriteriaChangedTo(sortingCriteria);
    }
  };

  // noinspection JSMismatchedCollectionQueryUpdate
  legends: Legend[] = [];
  private readonly POINT_WIDTH = 28;
  private benchmarkLegend: Legend = {
    text: '% NPV Benchmark',
    color: LegendColor.GREY,
    metric: MetricType.NewPatientVisits,
    style: LegendStyle.SQUARE,
    showBenchmarkOptionControl: true,
    showPercentileControl: true
  };

  private varianceLegends: Legend[] = [
    {
      text: 'Above Benchmark',
      color: LegendColor.GREEN,
      metric: MetricType.NewPatientVisits,
      style: LegendStyle.SQUARE
    },
    {
      text: 'Below Benchmark',
      color: LegendColor.RED,
      metric: MetricType.NewPatientVisits,
      style: LegendStyle.SQUARE
    },
    this.benchmarkLegend
  ];

  private barChartSeries: PlotColumnOptions = {
    cropThreshold: 100,
    stacking: 'normal'
  };

  patientVisitCountSeries: any[];

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  ngOnChanges(): void {
    combineLatest([
      this.patientVisitCounts$,
      this.viewCommunityBenchmarks$]
    ).subscribe(([showPatientVisitCounts, viewCommunity]:
                   [boolean, boolean]) => {
      if (this.zeroSuppression) {
        this.newPatientVisits = this.npvData.filter(x => x.countOfTotalPatientVisits !== 0);
      } else {
        this.newPatientVisits = this.npvData;
      }
      this.benchmarkPercentile = this.benchmarkPercentile === BenchmarkPercentile.Percentile65th
        ? BenchmarkPercentile.Mean : this.benchmarkPercentile;
      this.showPatientVisitCounts = showPatientVisitCounts;
      let academicBenchmarks: NewPatientVisitSnapshotMultiLevel[] = [];
      const academicArray = this.showExtendedBenchmarkOptions ? [BenchmarkOption.Academic, BenchmarkOption.TelehealthAcademic,
        BenchmarkOption.InPersonAcademic] : [BenchmarkOption.Academic];
      academicArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
        BenchmarkPercentilesForNpv.forEach(bt => {
          academicBenchmarks = academicBenchmarks.concat(this.npvData.filter(x => {
            const value = x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`];
            return hasNonZeroValue(value);
          }));
        });
      });
      let communityBenchmarks: NewPatientVisitSnapshotMultiLevel[] = [];
      const communityArray = this.showExtendedBenchmarkOptions ? [BenchmarkOption.Community, BenchmarkOption.TelehealthCommunity,
        BenchmarkOption.InPersonCommunity] : [BenchmarkOption.Community];
      communityArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
        BenchmarkPercentilesForNpv.forEach(bt => {
          communityBenchmarks = communityBenchmarks.concat(this.npvData.filter(x => {
            const value = x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`];
            return hasNonZeroValue(value);
          }));
        });
      });
      this.hasCommunityBenchmarks = communityBenchmarks.length > 0;
      this.hasAcademicBenchmarks = academicBenchmarks.length > 0;
      this.hasBenchmarks = viewCommunity ? this.hasCommunityBenchmarks : this.hasAcademicBenchmarks;
      updateCommunityBenchmarkAlertStatus(
        this.hasCommunityBenchmarks,
        this.hasAcademicBenchmarks,
        this.ngRedux,
        this.showProgressBar
      );
      if (this.sortingCriteria) {
        this.varianceToggle.sortingCriterion = this.sortingCriteria;
      }
      if (this.activeVarianceToggle) {
        this.varianceToggle.sortingCriterion = {
          sortingOrder: SortingOrder.DESCENDING,
          columnDef: 'newPatientVisitsPercentage'
        };
        this.newPatientVisits = this.newPatientVisits.filter(npv => hasValue(
          npv[getDesignatedNpvVarianceObject(this.benchmarkOption)][this.varianceKey]
        ));
        this.drawVarianceGraph(DEFAULT_MAX_NUMBER_SCROLLBARS, this.barSelectionCallback);
      } else {
        this.varianceToggle.sortingCriterion = {
          sortingOrder: SortingOrder.DESCENDING,
          columnType: ColumnType.VARIANCE,
          columnDef: this.varianceKey
        };
        this.drawGraph(this.barSelectionCallback);
      }
      this.chartObject = new StyledChart(this.options);
    });
  }

  getNpvLegends(isAddOnTurned: boolean): Legend[] {
    return [
      {
        text: '% New Patients',
        color: isAddOnTurned ? LegendColor.GREEN : LegendColor.TEAL,
        metric: MetricType.NewPatientVisits,
        style: LegendStyle.SQUARE
      },
      {
        text: '# New Patients',
        color: LegendColor.TEAL,
        metric: MetricType.NewPatientVisits,
        style: LegendStyle.SQUARE,
        isAddOnVariable: true
      },
      {
        text: '# Total Patients',
        color: LegendColor.PURPLE,
        metric: MetricType.NewPatientVisits,
        style: LegendStyle.SQUARE,
        isAddOnVariable: true
      },
      this.benchmarkLegend
    ];
  }

  drawGraph(onCategorySelected: (category: string | number) => void) {
    if (!this.newPatientVisits) {
      return;
    }
    this.syncUpWithOtherDataVisuals();
    const newPatientsVisitData = this.newPatientVisits.map(visit =>
      roundToNumber(visit.newPatientVisitsPercentage, 1));
    let benchmarkData: any[];
    const designatedBenchmarkObject = getDesignatedNpvBenchmarkObject(this.benchmarkOption);
    benchmarkData = this.newPatientVisits.map(npv =>
      roundToNumber((npv[designatedBenchmarkObject][`benchmark${readableNameOfColumnDef(this.benchmarkPercentile)}`] ?? 0) * 100, 1));
    const categories = createEmptyLabels(
      this.newPatientVisits.map((visit) => visit.nodeName),
      DEFAULT_MAX_NUMBER_SCROLLBARS);
    const nodePaths: string[] = this.newPatientVisits.map((visit) => visit.nodePath);
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;
    this.legends = this.showPatientVisitCounts ? this.getNpvLegends(true)
      : this.getNpvLegends(false).filter(legend => !legend.isAddOnVariable);
    const leftSideYAxis: YAxisOptions[] = [{
      title: {
        text: '% New Patients'
      },
      max: getMax(newPatientsVisitData, benchmarkData),
      min: 0,
      tickInterval: setTickValue(getMax(newPatientsVisitData, benchmarkData) || 100),
      labels: {
        formatter: function () {
          return this.value + '%';
        }
      }
    }];
    const rightSideYAxis: YAxisOptions[] = this.showPatientVisitCounts ? [{
      title: {
        text: '# New and Total Patients'
      },
      opposite: true,
      max: Math.max(...this.newPatientVisits.map(npv => npv.countOfTotalPatientVisits === undefined ? 0 :
        npv.countOfTotalPatientVisits)),
      labels: {
        formatter: function () {
          return abbreviateAxisValue(this.value);
        }
      }
    }
    ] : [];

    const newPatientVisitsForChart = this.newPatientVisits.map((newPatientVisit) => {
      return {
        drilldown: newPatientVisit.nodePath, y: newPatientVisit.newPatientVisitsPercentage,
        ancestors: this.getAncestorsForTooltip(newPatientVisit)
      };
    });
    const newPatientVisitsCountsForChart = this.newPatientVisits.map((newPatientVisit) => {
      return {drilldown: newPatientVisit.nodePath, y: newPatientVisit.countOfNewPatientVisits};
    });

    const existPatientVisitsCountsForChart = this.newPatientVisits.map((newPatientVisit) => {
      return {drilldown: newPatientVisit.nodePath, y: newPatientVisit.countOfExistingPatientVisits || 0};
    });
    this.patientVisitCountSeries = this.showPatientVisitCounts ? [
      {
        name: '# Total Patients',
        yAxis: 1,
        type: ChartType.COLUMN,
        data: existPatientVisitsCountsForChart.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(existPatientVisitsCountsForChart, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          existPatientVisitsCountsForChart,
        stacking: 'normal',
        events: {
          click: (event: PointClickEventObject) => {
            // @ts-ignore
            this.barSelectionCallback(event.point.drilldown);
          }
        },
        colorIndex: HighChartColorIndex.PURPLE,
        stack: 3,
        zIndex: 0,
        tooltip: {
          pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.total:,.0f}</b><br/>',
          valueDecimals: 0
        }
      },
      {
        name: '# New Patients',
        yAxis: 1,
        type: ChartType.COLUMN,
        data: newPatientVisitsCountsForChart.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(newPatientVisitsCountsForChart, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          newPatientVisitsCountsForChart,
        events: {
          click: (event: PointClickEventObject) => {
            // @ts-ignore
            this.barSelectionCallback(event.point.drilldown);
          }
        },
        stacking: 'normal',
        colorIndex: HighChartColorIndex.TEAL,
        stack: 3,
        zIndex: 0,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      }
    ] : [];

    const turboThreshold = {turboThreshold: 0};
    // @ts-ignore
    const barChartSeriesOptions: Highcharts.BarChartSeriesOptions[] = [
      Object.assign(
        turboThreshold,
        {
          turboThreshold: 0,
          name: '% New Patients',
          yAxis: 0,
          data: newPatientVisitsForChart.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
            createEmptyValues(newPatientVisitsForChart, DEFAULT_MAX_NUMBER_SCROLLBARS) :
            newPatientVisitsForChart,
          events: {
            click: (event: Highcharts.PointClickEventObject) => {
              // @ts-ignore
              this.barSelectionCallback(event.point.drilldown);
            }
          },
          type: this.showPatientVisitCounts ? ChartType.LINE : ChartType.COLUMN,
          colorIndex: this.showPatientVisitCounts ? HighChartColorIndex.GREEN : HighChartColorIndex.TEAL,
          stack: 0,
          zIndex: 1,
          tooltip: {
            pointFormat: this.showPatientVisitCounts ? '<span>{point.ancestors}</span><br/><span class="highcharts-color-12">\u25CF</span> {series.name}:' +
              ' <b>{point.y:.1f}</b><br/>' : '<span>{point.ancestors}</span><br/><span class="highcharts-color-10">\u25CF</span> {series.name}:' +
              ' <b>{point.y:.1f}</b><br/>'
          }
        }),
      {
        name: getBenchmarkSeriesName(this.benchmarkOption, this.showExtendedBenchmarkOptions, this.benchmarkPercentile),
        colorIndex: HighChartColorIndex.GREY,
        yAxis: 0,
        data: benchmarkData.slice(),
        type: ChartType.LINE,
        stack: 1,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
          valueDecimals: 0
        }
      }
    ];
    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: concat(barChartSeriesOptions, this.patientVisitCountSeries),
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 1
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: 1304.8,
        styledMode: true,
        height: CHART_HEIGHT,
        marginRight: 100
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: categories,
        // @ts-ignore
        clickOnCrosshair: function (e: any, p: any) {
          onCategorySelected(nodePaths[p.index]);
        },
        crosshair: {
          color: GraphColors.hoverBackground
        },
        max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            let ret = '';
            const tokenized = this.value.toLocaleString().split(' ');
            for (let i = 0; i < tokenized.length; i++) {
              ret = ret.concat(tokenized[i] + ' ');
              if (i === roundToNumber(tokenized.length / 2, 0) - 1) {
                ret = ret.concat('<br/>');
              }
            }
            return ret;
          },
          rotation: 45
        }
      },
      yAxis: concat(leftSideYAxis, rightSideYAxis),
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries,
          tooltip: {
            pointFormat: ''
          }
        }
      }
    };
  }

  drawVarianceGraph(maxNumberScrollBars: number, onCategorySelected: (category: string | number) => void) {
    if (!this.newPatientVisits) {
      return;
    }
    this.syncUpWithOtherDataVisuals();
    const varianceData: any[] = [];
    this.newPatientVisits.forEach(npv => {
      const varianceValue = this.getVarianceForVarianceGraph(npv);
      varianceData.push({
        name: npv.nodeName,
        y: varianceValue,
        colorIndex: varianceValue ? (varianceValue >= 0 ? HighChartColorIndex.GREEN : HighChartColorIndex.RED)
          : HighChartColorIndex.GREY,
        drilldown: npv.nodePath,
        ancestors: this.getAncestorsForTooltip(npv)
      });
    });

    const categories = createEmptyLabels(
      this.newPatientVisits.map((visit) => visit.nodeName),
      DEFAULT_MAX_NUMBER_SCROLLBARS
    );

    const nodePaths: string[] = this.newPatientVisits.map((visit) => visit.nodePath);

    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;

    this.legends = this.varianceLegends;
    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% New Patients'
        },
        max: 100,
        min: -100,
        tickInterval: 10,
        labels: {
          formatter: function () {
            return this.value + '%';
          }
        },
        plotLines: [{
          color: '#000000',
          width: 200,
          value: 0,
          dashStyle: 'Solid',
          zIndex: 999
        }]
      }
    ];

    const varianceSeries: IndividualSeriesOptions[] = [
      {
        name: 'Variance from benchmark ' + readableNameOfColumnDef(this.benchmarkPercentile).toLowerCase(),
        yAxis: 0,
        type: ChartType.COLUMN,
        data: varianceData.length < maxNumberScrollBars ?
          createEmptyValues(varianceData, maxNumberScrollBars) :
          varianceData,
        events: {
          click: (event: PointClickEventObject) => {
            // @ts-ignore
            this.barSelectionCallback(event.point.drilldown);
          }
        },
        pointWidth: this.POINT_WIDTH,
        stacking: 'normal',
        stack: 0,
        turboThreshold: 0,
        zIndex: 0
      }
    ];

    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: varianceSeries,
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 1
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: 1304.8,
        styledMode: true,
        height: CHART_HEIGHT
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: categories,
        // @ts-ignore
        clickOnCrosshair: function (e: any, p: any) {
          onCategorySelected(nodePaths[p.index]);
        },
        max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return toTitleCase(this.value.toString());
          },
          rotation: 45
        }
      },
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries
        }
      }
    };
  }

  private getTieBreakerString(item: NewPatientVisitSnapshotMultiLevel): string {
    return this.tieBreakerProperty ? this.tieBreakerProperty(item) : '';
  }

  private syncUpWithOtherDataVisuals(): void {
    const designatedColumn = this.getCorrespondingColumns().find(c => c.columnDef === this.sortingCriteria?.columnDef);
    if (designatedColumn) {
      sortSnapshotChartEntries(this.newPatientVisits, designatedColumn, this.tieBreakerProperty || this.getTieBreakerString,
        this.sortingCriteria?.sortingOrder === SortingOrder.ASCENDING);
    }
  }

  private getCorrespondingColumns(): DataTableColumns[] {
    switch (this.chosenTab) {
      case MultilevelTab.BY_DEPARTMENT:
        return multilevelNpvDeptColumns();
      case MultilevelTab.BY_SPECIALTY:
        return multilevelNpvSpecColumns();
      case MultilevelTab.BY_PROVIDER:
        return multilevelNpvProvColumns();
      default:
        return multilevelNpvDeptColumns();
    }
  }

  getAncestorsForTooltip(newPatientVisitEntry: NewPatientVisitSnapshotMultiLevel): string | undefined {
    switch (getFirstNodePathFrom(newPatientVisitEntry.nodePath).split('\\').length) {
      case 4:
        return newPatientVisitEntry.departmentNodeName;
      case 5:
        return newPatientVisitEntry.specialtyNodeName + '<br/>' + newPatientVisitEntry.departmentNodeName;
    }
    return undefined;
  }

  getVarianceForVarianceGraph(npv: NewPatientVisitSnapshotMultiLevel): number | undefined {
    return npv[getDesignatedNpvVarianceObject(this.benchmarkOption)][`variance${readableNameOfColumnDef(this.benchmarkPercentile)}`];
  }
}

function getMax(arr1: any[], arr2: any[]) {
  return Math.max(...arr1, ...arr2);
}
