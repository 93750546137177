<div *ngIf="enableCompliance" class="compliance-content">
  <iframe   [src]="complianceUrl|safe"
          style="position: absolute; height: 100%; width: 100%; border: none">
  </iframe>
</div>

<app-legal-disclaimer *ngIf="!enableCompliance"> </app-legal-disclaimer>



