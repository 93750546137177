import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {CptSummary, EmDimension, ProviderMultilevelCptCounts} from '../../../shared/models';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {CptRangeMap, parametersForZeroSuppression} from '../../em-helpers';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-em-snapshot-table',
  templateUrl: './em-snapshot-table.component.html',
  styleUrls: ['./em-snapshot-table.component.scss']
})
export class EmSnapshotTableComponent implements OnChanges {
  @Input() receivedData?: ProviderMultilevelCptCounts[] = [];
  @Input() rowSelectionCallback: (row: ProviderMultilevelCptCounts) => void;
  @Input() zeroSuppression = false;
  @Input() columns: DataTableColumns[];
  @Input() header: string;
  @Input() fromPdf = true;
  @Input() dimension: EmDimension = EmDimension.Outpatient;
  @Output() whenZeroSuppressed = new EventEmitter<number>();
  @ViewChild('paginator') paginator: MatPaginator;
  columnsWithHeader = 0;
  dataRendered = true;
  shouldRender = true;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  readonly pageSizeOptions = [10, 20, 30, 40];
  private pageIndex = 0;
  private initialPageSize = this.pageSizeOptions[0];
  pageSize = this.initialPageSize;
  resetTable = true;

  private static isAllowedToShow(x: ProviderMultilevelCptCounts, zeroSuppressionParameters: CptRangeMap[]): boolean {
    for (let i = 0; i < zeroSuppressionParameters.length; i++) {
      const range = zeroSuppressionParameters[i];
      if (!x.cptCategories[range.range]) {
        continue;
      }
      for (let j = 0; j < zeroSuppressionParameters[i].codes.length; j++) {
        const code = range.codes[j];
        const cptSummary: CptSummary | undefined = x.cptCategories[range.range][`cpt${code}`];
        if (cptSummary && cptSummary.totalCount !== 0) {
          return true;
        }
      }
    }
    return false;
  }

  constructor() { }

  async ngOnChanges(changes?: SimpleChanges) {
    this.resetTable = !!changes && changes['zeroSuppression'] && changes['zeroSuppression'].previousValue === false && changes['zeroSuppression'].currentValue === true;
    this.pageSize = this.initialPageSize;
    this.columnsWithHeader = this.columns.filter(c => c.aboveHeaderText).length;
    this.dataRendered = false;
    if (this.shouldRender) {
      await pause();
    }
    function pause() {
      return new Promise(
        (resolve) => {
          setTimeout(resolve, 0);
        }
      );
    }
    this.setTable();
    this.dataRendered = true;
  }

  whenRowSelected(row: ProviderMultilevelCptCounts): void {
    this.rowSelectionCallback(row);
  }

  private setTable(): void {
    const zeroSuppressionParameters = parametersForZeroSuppression(this.dimension);
    if (this.zeroSuppression) {
      this.returnToFirstPage();
      if (this.resetTable) {
        this.ngOnChanges();
      }
    }
    this.dataSource = new MatTableDataSource<any>(((this.receivedData || []).filter(x => this.fromPdf ||
      !this.zeroSuppression || EmSnapshotTableComponent.isAllowedToShow(x, zeroSuppressionParameters)) || []).slice());
    this.displayedColumns = this.columns.map(x => x.columnDef);
    this.whenZeroSuppressed.emit(this.receivedData ? this.receivedData.length - this.dataSource.data.length : 0);
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.pageSize = this.pageSize;
      this.dataSource.paginator.pageIndex = this.pageIndex;
      this.dataSource.paginator.length = (this.receivedData || []).length;
    }
  }

  returnToFirstPage() {
    this.pageIndex = 0;
  }
}
