import {ExportMetadata} from '../../shared/export/export';
import {DataTableColumns} from '../../shared/data-table-columns';
import {BaseColumn} from '../../store/IAppState';
import {
  BenchmarkPercentilesForDenials,
  createDenialsPaidBenchmarkColumnFor,
  createDenialsRateBenchmarkColumnFor,
  createDenialsVarianceColumnFor
} from '../../shared/BenchmarkColumns';
import {ColumnType} from '../../shared/enums';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {BehaviorSubject} from 'rxjs';
import {
  denialsByDepartmentDataExcelData$,
  denialsByPayerDataExcelData$,
  denialsByProviderDataExcelData$,
  denialsBySpecialtyDataExcelData$
} from '../export-subscribers';
import {DenialPayerEntity, DenialsMultiLevelEntity} from '../../denials/denials-models';

function insertAllBenchmarkDependantColumnsForDenials(benchmarkPercentiles: BenchmarkPercentile[],
                                                      columns: DataTableColumns[]): DataTableColumns[] {
  const benchmarkRateColumns: DataTableColumns[] = [];
  const benchmarkPaidColumns: DataTableColumns[] = [];
  const varianceColumns: DataTableColumns[] = [];
  benchmarkPercentiles.forEach(bcm => {
    benchmarkRateColumns.push(createDenialsRateBenchmarkColumnFor(bcm));
    benchmarkPaidColumns.push(createDenialsPaidBenchmarkColumnFor(bcm));
    varianceColumns.push(createDenialsVarianceColumnFor(bcm));
  });
  const hasBenchmarkRate = columns.find(col => col.columnType === ColumnType.BENCHMARK);
  if (hasBenchmarkRate) {
    columns = columns.filter(col => !(col.columnType === ColumnType.BENCHMARK));
    benchmarkRateColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasBenchmarkPaid = columns.find(col => col.columnType === ColumnType.DENIED_PAID_BENCHMARK);
  if (hasBenchmarkPaid) {
    columns = columns.filter(col => !(col.columnType === ColumnType.DENIED_PAID_BENCHMARK));
    benchmarkPaidColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasVariance = columns.find(col => col.columnType === ColumnType.VARIANCE);
  if (hasVariance) {
    columns = columns.filter(col => col.columnType !== ColumnType.VARIANCE);
    varianceColumns.forEach(varCol => {
      columns.push(varCol);
    });
  }
  return columns;
}

export async function generateExcelDenialsSnapshotData(
  denialsMultilevelByNodePath: DenialsMultiLevelEntity[],
  denialsColumns: DataTableColumns[],
  displayedColumns: BaseColumn[],
  level: string
) {
  denialsColumns = denialsColumns.filter(col => col.primaryColumn ||
    displayedColumns.find(c => c.columnDef === col.columnDef || (c.columnType === col.columnType &&
      c.columnType !== undefined))).slice();
  denialsColumns = insertAllBenchmarkDependantColumnsForDenials(BenchmarkPercentilesForDenials, denialsColumns);
  const copyRight = 'Clinical Practice Solutions Center Denials ' + (new Date().getFullYear() - 1) +
    ' American Medical Association. All Rights Reserved. ';
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (level) {
    case 'department':
      designatedObservable = denialsByDepartmentDataExcelData$;
      break;
    case 'specialty':
      designatedObservable = denialsBySpecialtyDataExcelData$;
      break;
    case 'provider':
      designatedObservable = denialsByProviderDataExcelData$;
  }
  designatedObservable.next({
    detailHeaders: ['By ' + denialsColumns[0].header].concat(denialsColumns.slice(1).map(col => col.header)),
    detailData: denialsMultilevelByNodePath.map(denial => {
      return generateRowForDenialsSnapshot(denial, denialsColumns);
    }),
    fileName: 'Denials by ' + level,
    page: 'Denials by ' + level,
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showLag: true,
      showBilling: true
    }
  });
}

export async function generateExcelDenialsByPayerData(
  payerDenials: DenialPayerEntity[],
  denialsColumns: DataTableColumns[],
  displayedColumns: BaseColumn[]
) {
  denialsColumns = denialsColumns.filter(col => col.primaryColumn ||
    displayedColumns.find(c => c.columnDef === col.columnDef || (c.columnType === col.columnType &&
      c.columnType !== undefined))).slice();
  denialsColumns = insertAllBenchmarkDependantColumnsForDenials(BenchmarkPercentilesForDenials, denialsColumns);
  const copyRight = 'Clinical Practice Solutions Center Denials ' + (new Date().getFullYear() - 1) +
    ' American Medical Association. All Rights Reserved. ';
  denialsByPayerDataExcelData$.next({
    detailHeaders: denialsColumns.map(col => col.header),
    detailData: payerDenials.map(denial => {
      return generateRowForDenialsByPayer(denial, denialsColumns);
    }),
    fileName: 'Denials by payer',
    page: 'Denials by payer',
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showLag: true,
      showBilling: true
    }
  });
}

function generateRowForDenialsSnapshot(denial: DenialsMultiLevelEntity, columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    row.push(col.dataName(denial));
  });
  return row;
}

function generateRowForDenialsByPayer(denial: DenialPayerEntity, columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    row.push(col.dataName(denial));
  });
  return row;
}
