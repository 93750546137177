import {BenchmarkCombo} from './mpe-dialog-helper';
import {BenchmarkHelperObject, extendedBenchmarkOptions, originalBenchmarkOptions} from '../../shared/benchmark-types';
import {benchmarkPercentileSeriesName} from '../../shared/helpers';
import {BatchPdfOption} from '../../shared/export/export';
import {BatchExportMetricSection} from '../batch-export-helper';

export function generateBenchmarkComboText(showExtendedBenchmarks: boolean, benchmarkCombo?: BenchmarkCombo): string {
  if (!benchmarkCombo) {
    return '';
  }
  const designatedBenchmarkHelperObjects: BenchmarkHelperObject[] = showExtendedBenchmarks ? extendedBenchmarkOptions :
    originalBenchmarkOptions;
  const foundBenchmarkHelperObject = designatedBenchmarkHelperObjects.find(o => o.value === benchmarkCombo.option);
  return `${foundBenchmarkHelperObject ? foundBenchmarkHelperObject.name : ''} ${benchmarkCombo.percentile ?
    benchmarkPercentileSeriesName(benchmarkCombo.percentile) : ''}`;
}

export function addBenchmarkLabelsToOverviewExportEventName(overviewSubOptions: BatchPdfOption[]): string {
  const requiresComma = (i: number, options: BatchPdfOption[]) => {
    return i < options.length - 1;
  };
  let eventName = '';
  let index = 0;
  overviewSubOptions.forEach(o => {
    if (o.mandatoryIfParentIsSelected) {
      eventName = `${eventName}${o.text}: ${generateBenchmarkComboText(!!o.showExtendedBenchmarks, o.benchmarkCombo)}`;
      if (requiresComma(index, overviewSubOptions)) {
        eventName = `${eventName}, `;
      }
    }
    index++;
  });
  return eventName;
}

export function addTileLabelsToOverviewExportEventName(overviewSubOptions: BatchPdfOption[]): string {
  const requiresComma = (i: number, options: BatchPdfOption[]) => {
    return i < options.length - 1 && options[1 + i].selected &&
      !options[1 + i].mandatoryIfParentIsSelected;
  };
  let eventName = '';
  let index = 0;
  overviewSubOptions.forEach(o => {
    if (o.selected && !o.mandatoryIfParentIsSelected) {
      eventName = `${eventName}${o.text} `;
      if (requiresComma(index, overviewSubOptions)) {
        eventName = `${eventName}, `;
      }
    }
    index++;
  });
  return eventName;
}

export function generateExportEventTextForOverview(overviewMetricSection: BatchExportMetricSection): string {
  let eventName = `${overviewMetricSection.text} (`;
  const overviewSubOptions = overviewMetricSection.options[0].children;
  eventName = `${eventName}${addBenchmarkLabelsToOverviewExportEventName(
    overviewSubOptions)}) - ${addTileLabelsToOverviewExportEventName(overviewSubOptions)}`;
  return `${eventName}\n\n`;
}

export function addSubOptionLabelToExportEventText(subOptions: BatchPdfOption[], benchmarksExtended: boolean): string {
  const requiresComma = (i: number, options: BatchPdfOption[]) => {
    return i < subOptions.length - 1 && subOptions[1 + i].selected;
  };
  let eventText = '';
  let index = 0;
  subOptions.forEach(o => {
    if (o.selected) {
      eventText = `${eventText}${generateExportEventTextForMetric(o, false, benchmarksExtended)}`;
    }
    if (requiresComma(index, subOptions)) {
      eventText = `${eventText}, `;
    }
    index++;
  });
  return eventText;
}

export function generateExportEventTextForMetric(option: BatchPdfOption, isTopOption: boolean, benchmarksExtended: boolean,
  benchmarkCombo?: BenchmarkCombo): string {
  let eventText = option.text;
  if (benchmarkCombo) {
    eventText = `${eventText} (${generateBenchmarkComboText(benchmarksExtended, benchmarkCombo)})`;
  }
  const hasChildren = option.children.length >= 1;
  if (hasChildren) {
    if (isTopOption) {
      eventText = `${eventText} - `;
    } else {
      eventText = `${eventText}: (`;
    }
  }
  const subOptions = option.children;
  eventText = `${eventText}${addSubOptionLabelToExportEventText(subOptions, benchmarksExtended)}`;
  if (hasChildren && !isTopOption) {
    eventText = `${eventText})`;
  }
  return eventText;
}
