import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  BatchExportDetail,
  BatchExportDetailResponse,
  BatchFilterCriteria,
  DateRange,
  FeatureToggleSetting,
  NewPatientVisitSummary,
  ZipCodeNewPatientVisits
} from '../../shared/models';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {BenchmarkPercentile, getBenchmarkLabel} from '../../shared/benchmark-types';
import {getBatchFilterFromExportDetail} from '../../shared/test/helper-functions.spec';
import {
  getNumberOfMonthsInDateRange,
  getPreviousYearDateRange,
  getSelectedDateRange,
  SummaryType
} from '../../shared/helpers';
import {forkJoin, of, Subscription} from 'rxjs';
import {roundToNumber} from '../../productivity-summary/number-formatter';
import {FeatureToggleServiceToken, HttpFeatureToggleService} from '../../services/feature-toggle.service';
import {ActivatedRoute} from '@angular/router';
import {BatchExportServiceToken, HttpBatchExportService} from '../services/batch-export.service';
import {MonthNewPatientVisitData, NewPatientVisitTrendEntry} from '../../new-patient-visits/components/npv-models';
import {SummaryData} from '../../store/IAppState';
import {catchError} from 'rxjs/operators';
import {ApiServiceImpl, convertSummaryType} from '../../services/api.service';
import {checkForNulls} from '../../shared/null-helpers';
import {BenchmarkOption} from '../../shared/enums';
import {
  setUpBenchmarkOption,
  setUpBenchmarkPercentile,
  updateFilterCriteriaWithTelehealthFlag
} from '../batch-export-helper';

@Component({
  selector: 'app-npv-ml-batch-export-pdf',
  templateUrl: './npv-ml-batch-export-pdf.component.html',
  styleUrls: ['./npv-ml-batch-export-pdf.component.scss']
})
export class NpvMlBatchExportPdfComponent implements OnInit, OnDestroy {

  ftService: Subscription;
  batchService: Subscription;
  showPdfPage = false;
  guid = '';
  detailReportId: number;
  selectedDateRange: string;
  previousSelectedDateRange: string;
  benchmark: BenchmarkPercentile;
  benchmarkLabel: string;
  dateRange: DateRange;
  NUMBER_OF_DATA_POINTS: number;
  benchmarkPlaceholder: string;
  detailData: BatchExportDetail;
  batchFilterCriteria: BatchFilterCriteria;
  previousBatchFilterCriteria: BatchFilterCriteria;
  npvData: MonthNewPatientVisitData;
  mergedNpvTrendData: NewPatientVisitTrendEntry[] = [];
  npvSummaryData: SummaryData<NewPatientVisitSummary>;
  benchmarkOption: BenchmarkOption;
  benchmarkPercentile: BenchmarkPercentile;
  zipNpvData: ZipCodeNewPatientVisits[];

  constructor(private router: ActivatedRoute,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(FeatureToggleServiceToken) private readonly featureToggleService: HttpFeatureToggleService) { }

  ngOnInit() {
    this.ftService = this.featureToggleService.getBatchExportFeatureToggleSettings()
      .subscribe((featureSettings: FeatureToggleSetting[]) => {
        let setting: FeatureToggleSetting | undefined;
        if (featureSettings && featureSettings.length > 0) {
          setting = featureSettings.find((s: FeatureToggleSetting) => s.featureName.toLowerCase() ===
            FeatureToggleEntries.ENABLE_MULTIPROVIDER_SECURITY.toLowerCase());
        }
        const emailParam = this.router.snapshot.paramMap.get('email') ?? '';
        if (setting && setting.enabled) {
          const reportHeaderIdParam = this.router.snapshot.paramMap.get('reportHeaderId');
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          const guidParam = this.router.snapshot.paramMap.get('guid');
          if (reportIdParam && !isNaN(+reportIdParam) && reportHeaderIdParam && !isNaN(+reportHeaderIdParam) && emailParam && guidParam) {
            this.batchService = this.batchExportService.isValidBatchRequest(+reportHeaderIdParam, emailParam, guidParam)
              .subscribe((resp: boolean) => {
                this.showPdfPage = resp;
                if (resp) {
                  this.guid = guidParam;
                  this.detailReportId = +reportIdParam;
                  this.generatePdf(this.detailReportId);
                }
              });
          }
        } else {
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          if (reportIdParam && !isNaN(+reportIdParam)) {
            this.detailReportId = +reportIdParam;
            this.showPdfPage = true;
            this.generatePdf(this.detailReportId);
          }
        }
      });
  }

  ngOnDestroy() {
    this.ftService?.unsubscribe();
    this.batchService?.unsubscribe();
  }

  generatePdf(detailReportId: number) {
    this.benchmark = BenchmarkPercentile.Mean;
    this.benchmarkLabel = 'Academic';
    this.benchmarkPlaceholder = getBenchmarkLabel(this.benchmark);
    this.batchExportService.getBatchExportDetailsByDetailId(detailReportId).subscribe((res: BatchExportDetailResponse) => {
      if (res && res.batchExportDetails && res.batchExportDetails.length > 0) {
        this.detailData = res.batchExportDetails[0];
        this.setUpBenchmarks();
        this.batchFilterCriteria = updateFilterCriteriaWithTelehealthFlag(true, this.benchmarkOption,
          getBatchFilterFromExportDetail(this.detailData, this.guid));
        this.previousBatchFilterCriteria = updateFilterCriteriaWithTelehealthFlag(true, this.benchmarkOption,
          getBatchFilterFromExportDetail(this.detailData, this.guid, true));
        forkJoin({
          npvData: this.batchExportService.getNewPatientVisitTrendData(this.batchFilterCriteria, SummaryType.SELECTED_PERIOD),
          npvZipData: this.batchExportService.getNpvZipData(this.batchFilterCriteria),
          npvPreviousYearData: this.batchExportService
            .getNewPatientVisitTrendData(this.previousBatchFilterCriteria, SummaryType.PREVIOUS_SELECTED_PERIOD),
          selectedDateRange: this.batchExportService.getNewPatientVisitSummaryData(this.batchFilterCriteria,
            0).pipe(catchError(error => of(error))),
          previousYearSelectedDateRange: this.batchExportService.getNewPatientVisitSummaryData(this.batchFilterCriteria,
            1).pipe(catchError(error => of(error))),
          yearToDate: this.batchExportService.getNewPatientVisitSummaryData(this.batchFilterCriteria,
            2).pipe(catchError(error => of(error))),
          previousYearToDate: this.batchExportService.getNewPatientVisitSummaryData(this.batchFilterCriteria,
            3).pipe(catchError(error => of(error))),
          recentMonth: this.batchExportService.getNewPatientVisitSummaryData(this.batchFilterCriteria,
            4).pipe(catchError(error => of(error))),
          previousYearRecentMonth: this.batchExportService.getNewPatientVisitSummaryData(this.batchFilterCriteria,
            5).pipe(catchError(error => of(error)))
        }).subscribe(({npvData, npvZipData, npvPreviousYearData, selectedDateRange, previousYearSelectedDateRange,
                                yearToDate, previousYearToDate, recentMonth, previousYearRecentMonth}) => {
          this.dateRange = {
            startMonth: roundToNumber(this.batchFilterCriteria.startMonth, 0),
            startYear: roundToNumber(this.batchFilterCriteria.startYear, 0),
            endMonth: roundToNumber(this.batchFilterCriteria.endMonth, 0),
            endYear: roundToNumber(this.batchFilterCriteria.endYear, 0)
          };
          this.mergedNpvTrendData = ApiServiceImpl.getNewPatientMergedTrendData(npvData, npvPreviousYearData);
          this.mergedNpvTrendData.forEach(datum => {
            datum.difference = checkForNulls(datum.newPatientVisitsPercentage) - checkForNulls(datum.previous.newPatientVisitsPercentage);
          });
          this.NUMBER_OF_DATA_POINTS = getNumberOfMonthsInDateRange(this.dateRange);
          this.selectedDateRange = getSelectedDateRange(this.dateRange, true);
          this.previousSelectedDateRange = getPreviousYearDateRange(this.dateRange, true);
          this.npvData = npvData;
          this.zipNpvData = npvZipData;
          this.npvSummaryData = {
            selectedDateRange: convertSummaryType(selectedDateRange),
            previousYearSelectedDateRange: convertSummaryType(previousYearSelectedDateRange),
            yearToDate: convertSummaryType(yearToDate),
            previousYearToDate: convertSummaryType(previousYearToDate),
            recentMonth: convertSummaryType(recentMonth),
            previousYearRecentMonth: convertSummaryType(previousYearRecentMonth)
          };
        });
      }
    });
  }

  private setUpBenchmarks() {
    this.benchmarkOption = setUpBenchmarkOption(this.detailData.npvPageBenchmarkType);
    this.benchmarkPercentile = setUpBenchmarkPercentile(this.detailData.npvPageBenchmarkPercentile);
  }
}
