import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {NpvLocationWithSnapshotEntries} from '../../../npv-models';
import {DataTableColumns} from '../../../../../shared/data-table-columns';
import {MatSort} from '@angular/material/sort';
import {ColumnType} from '../../../../../shared/enums';

@Component({
  selector: 'app-npv-location-table-header',
  templateUrl: './npv-location-table-header.component.html',
  styleUrls: ['./npv-location-table-header.component.scss']
})
export class NpvLocationTableHeaderComponent {
  @Input() dataSource: MatTableDataSource<NpvLocationWithSnapshotEntries>;
  @Input() columns: DataTableColumns[];
  @Input() displayedColumns: string[];
  @Input() previousDateText = '';
  @Output() whenHeaderSortSelected = new EventEmitter<ColumnWithMatSort>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  readonly ColumnType = ColumnType;

  constructor() { }

  onHeaderSortSelected(column: DataTableColumns): void {
    this.whenHeaderSortSelected.emit({column: column, sort: this.sort.direction});
  }
}

export interface ColumnWithMatSort {
  column: DataTableColumns;
  sort?: string;
}
