<mat-dialog-content>
  <div id="save-custom-group-wrapper">
    <div id="custom-group-dialog-header">Save Grouping</div>
    <input type="text" id="input-custom-group-name" maxlength="150" (input)="onGroupNameInputChanged($event)"
           placeholder="Name My Grouping" [(ngModel)]="groupNameText"/>
    <div id="under-the-line">
      <div class="warning-wrapper" *ngIf="groupIsEdited">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
        <span id="warning-msg">Overwriting an existing group!</span>
      </div>
      <div id="default-custom-group-wrapper">
      <span>
        <i class="fa fa-info-circle" aria-hidden="true" (mouseenter)="showInfoTooltip($event)"
           (mouseleave)="hideInfoTooltip()" id="tooltip-icon"></i>&nbsp;
        <mat-checkbox [(ngModel)]="isDefault" labelPosition="before" name="isDefault" id="default-checkbox">Make default grouping?</mat-checkbox>
      </span>
      </div>
    </div>
    <div id="showInfoTooltipForDefaultGroup" style="position: fixed; display: none;">
      <p>By clicking on the default checkbox, you save the filter and its groupings combinations as default.</p>
      <p>Your default grouping will appear in the grouping dropdown, and when you log into CPSC, your default grouping
        will be loaded.</p>
      <p>The default grouping can be managed by going into the custom grouping drop down in the filter banner.</p>
    </div>

    <div id="buttons-wrapper">
      <button [ngClass]="groupNameText && groupNameText.length >= 1 ? 'btn-save' : 'btn-save-disabled'"
              (click)="save()">Save
      </button>
      <button class="btn-cancel" (click)="cancel()">Cancel</button>
    </div>
  </div>
</mat-dialog-content>
