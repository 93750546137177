<!--suppress HtmlUnknownTarget -->
<div class="no-access-page">
<!--  <app-header></app-header>-->
  <div class="no-access-page-body">
    <img src="assets/cloud.png"/>
    <p class="something-is-wrong">Something Is Wrong With Your Access</p>
    <p class="if-you-have-question">Looks like you don't have access to any member. Please contact CPSC support team:
      <a id="email-link" (mousedown)="email()">cpscsupport@vizientinc.com</a> for assistance. Thank you.</p>
    <button id="return-home-button" mat-raised-button (click)="logout()" >Return to Home Page</button>
  </div>
</div>
