import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DataTableColumns, viewCPTsDeniedColumn} from '../../shared/data-table-columns';
import {SortingCriterion} from '../../shared/models';
import {BaseColumn, FilterCriteria, IAppState} from '../../store/IAppState';
import {AppAction, denialsChosenTabChangedTo} from '../../store/actions';
import {ColumnType, MultilevelTabCollections, SortingOrder} from '../../shared/enums';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NgRedux} from '@angular-redux/store';
import {AnalyticsService, AnalyticsServiceToken} from '../../analytics/analytics.service';
import {canHide, getAppConfigValue, getPreviousYearDateRange, Page} from '../../shared/helpers';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';
import {hasValue} from '../../shared/null-helpers';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {DenialPayerEntity} from '../denials-models';

@Component({
  selector: 'app-denials-metric-data-table',
  templateUrl: './denials-metric-data-table.component.html',
  styleUrls: ['./denials-metric-data-table.component.scss']
})
export class DenialsMetricDataTableComponent<T> implements OnChanges {
  @Input() receivedData: any[];
  @Input() defaultSortColumn: string;
  @Input() addRemoveOption = false;
  @Input() columns: DataTableColumns[] = [];
  @Input() originalColumns: DataTableColumns[] = [];
  @Input() sortDirection: string;
  @Input() whenSortChanged: (sortedRows: any) => void;
  @Input() tieBreakerProperty?: (element: T) => string;
  @Input() rowSelectionCallback?: (element: T) => void;
  @Input() showProgressBar = false;
  @Input() sortingDataAccessor?: any;
  @Input() paginate = true;
  @Input() displayZeroSuppression: boolean;
  @Input() isZeroSuppressionChecked: boolean;
  @Input() countOfSuppressedEntries: number;
  @Input() currentPage: Page;
  @Input() multiLevel: true;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() levelCssClass: string;
  @Input() tableTitle: string;
  @Input() zeroSuppressionCondition: string;
  @Input() fromPdfExport = false;
  @Input() collectionsTab = false;
  @Input() denialsTab = false;
  @Input() displayAboveHeaders = false;
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion) => void;
  @Input() shouldPutNullsAtTheBottom = false;
  @Input() reducerAction: (columns: BaseColumn[]) => AppAction;
  @Input() mandatoryOpaqueSortingArrow = false;
  @Input() initialPageSize = 25;
  @Input() nodePathName = '';

  @Output() pageEvent = new EventEmitter<PageEvent>();

  @Output() currentTab = new EventEmitter<MultilevelTabCollections>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  pageSize = this.initialPageSize;
  pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
  dataSource: MatTableDataSource<any>;
  pageIndex = 0;
  displayedColumns: string[];
  sortChangeSubscribed = false;
  previousDate: string;
  // For the cpts denied component
  showCPTsDenied = false;
  canHideCptsDenied = true;
  selectedPayer: DenialPayerEntity;
  filters: FilterCriteria;

  constructor(private readonly ngRedux: NgRedux<IAppState>, private cdr: ChangeDetectorRef,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnChanges(changes?: SimpleChanges) {
    this.filters = this.ngRedux.getState().filters;
    if (changes && changes['initialPageSize']?.firstChange && this.paginate) {
      this.pageSize = this.initialPageSize;
      this.pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
    }

    this.previousDate = getPreviousYearDateRange(this.ngRedux.getState().filters.dateRange);
    if (this.receivedData) {
      this.dataSource =
        new MatTableDataSource<any>(this.defaultSortColumn ? this.receivedData.slice().sort(
          (a: any, b: any) => b[this.defaultSortColumn] - a[this.defaultSortColumn]) : this.receivedData.slice());

      if (this.sortingDataAccessor) {
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
      }


      this.displayedColumns = this.columns.map(x => x.columnDef);
      this.dataSource.sortData = (data: T[], sort: MatSort): T[] => {
        const active = changes?.defaultSortColumn ? this.defaultSortColumn : sort.active;
        const direction = sort?.direction;
        if (!active || direction === '') {
          return data;
        }

        return data.sort((a, b) => {
          return this.compare(a, b, active, direction === 'asc');
        });
      };

      if (this.whenSortChanged) {
        this.whenSortChanged(this.dataSource.sortData(this.dataSource.data, this.sort));
      }

      if (this.paginate) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize = this.pageSize;
        this.dataSource.paginator.pageIndex = this.pageIndex;
        this.dataSource.paginator.length = this.receivedData.length;
      }

      this.dataSource.sort = this.sort;
      if (!this.sortChangeSubscribed) {
        this.sortChangeSubscribed = true;
        this.dataSource.sort.sortChange.subscribe(() => {
          if (this.whenSortChanged) {
            const sortingCriteria: SortingCriterion = {
              sortingOrder: this.sort.direction === SortingOrder.ASCENDING ? SortingOrder.ASCENDING : SortingOrder.DESCENDING,
              columnDef: this.sort.active
            };
            this.whenSortingCriteriaChanged(sortingCriteria);
            this.whenSortChanged(this.dataSource.sortData(this.dataSource.data, this.sort));
          }
        });
      }
    }
    const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
    this.canHideCptsDenied = canHide(FeatureToggleEntries.DENIALS_CPTS_DENIED_MODAL, featureToggleSettings, userProfile);

  }

  whenSortingCriteriaChanged(sortingCriteria: SortingCriterion): void {
    return this.updateSortingCriteria && this.updateSortingCriteria(sortingCriteria);
  }

  openMethodologyDocument() {
    const appConfigSettings = this.ngRedux.getState().data.applicationConfigurationSettings;
    window.open(getAppConfigValue(AppConfigEntries.METHODOLOGY_DOCUMENT, appConfigSettings));
    this.analyticsService.handleGoogleAnalytics('Help Docs', 'Help Documentation Click', 'Methodology Document');
  }

  compare(a: T, b: T, sortHeaderId: string, sortAscending: boolean): number {
    const valueA = this.dataSource.sortingDataAccessor(a, sortHeaderId);
    const valueB = this.dataSource.sortingDataAccessor(b, sortHeaderId);
    const multiplier = sortAscending ? 1 : -1;
    if (this.shouldPutNullsAtTheBottom) {
      if (!hasValue(valueB) && hasValue(valueA)) {
        return -1;
      } else if (hasValue(valueB) && !hasValue(valueA)) {
        return 1;
      }
    }
    if (valueA > valueB) {
      return multiplier;
    }
    if (valueA < valueB) {
      return -1 * multiplier;
    }
    return this.getTieBreakerString(a).localeCompare(this.getTieBreakerString(b));
  }

  getTieBreakerString(item: T): string {
    return this.tieBreakerProperty ? this.tieBreakerProperty(item) : '';
  }

  updateDataTable(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageEvent.emit(event);
  }

  hasDenials(row: any): boolean {
    return row.numberDenied !== 0;
  }

  activateCptsDeniedModal(row: DenialPayerEntity) {
    if (this.hasDenials(row)) {
      this.selectedPayer = row;
      this.analyticsService.handleGoogleAnalytics('Cpt Analysis', 'View Cpt', 'View Cpt');
      this.setShowCptsDenied(true);
      switch (this.currentPage) {
        case Page.DenialsDepartment:
          this.nodePathName = row.departmentNodeName + '';
          break;
        case Page.DenialsSpecialty:
          this.nodePathName = row.departmentNodeName + ' : ' + row.specialtyNodeName;
          break;
        case Page.DenialsProvider:
          this.nodePathName = row.departmentNodeName + ' : ' + row.specialtyNodeName + ' : ' + row.providerNodeName;
          break;
      }
    }
  }

  setShowCptsDenied(show: boolean): void {
    this.showCPTsDenied = show;
  }

  getCssClassForColumnType(column: any): string {
    switch (column.columnType) {
      case ColumnType.NODE_NAME:
        return 'nodeName';
      case ColumnType.BENCHMARK:
        return 'benchmarkCell';
      case ColumnType.VARIANCE:
        return 'varianceCell';
      case ColumnType.IMPUTED:
        return 'imputedCell';
      default:
        return '';
    }
  }

  chooseTab(tab: MultilevelTabCollections): void {
    this.currentTab.emit(tab);
    this.ngRedux.dispatch(denialsChosenTabChangedTo(tab));
  }

  selectRow(selectedRow: T, columDef: string) {
    if (columDef === viewCPTsDeniedColumn.columnDef) {
      // @ts-ignore
      this.activateCptsDeniedModal(selectedRow);
      return;
    }
    return this.rowSelectionCallback && this.rowSelectionCallback(selectedRow);
  }
}
