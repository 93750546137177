import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../store/IAppState';
import {
  ApplicationConfigurationSetting,
  CustomGroupsDataAllFilters,
  FeatureToggleSetting,
  MemberData,
  Members,
  Subproduct,
  UserProfile,
  UserSecurity
} from '../shared/models';
import {
  applicationConfigurationSettingsLoadedTo,
  clearRequestDetails,
  collectionsChosenTabChangedTo,
  customGroupsDataChangedTo,
  emNpvBenchmarkOptionChangedTo,
  featureToggleSettingsLoadedTo,
  filterCriteriaChangedTo,
  LagKeyLessThanChangedTo,
  memberLocationChangedTo,
  nodePathChangedTo,
  payerCategoryChangedTo,
  recentMonthLoadedTo,
  selectedNodesChangedTo,
  userProfileLoadedTo
} from '../store/actions';
import {UserProfileService, UserProfileServiceToken} from '../services/user-profile.service';
import {
  getAcademicBenchmarkCorrespondingToTelehealthFlag,
  getAppConfigValue,
  getArbitraryDateRangeFromDatesInMonths,
  getRouteSegmentForMetric,
  updateSelectedMember
} from '../shared/helpers';
import {
  DEFAULT_INVOICE_STATUS,
  DEFAULT_LAG_KEY,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_TELEHEALTH_FLAG
} from '../store/DefaultValues';
import {UserSecurityService, UserSecurityServiceToken} from '../services/user-security.service';
import {MatDialog} from '@angular/material/dialog';
import {NoAccessDialogComponent} from '../no-access-dialog/no-access-dialog.component';
import {
  ApplicationConfigurationService,
  ApplicationConfigurationServiceToken
} from '../services/app-configuration.service';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {UserPreferenceService, UserPreferenceServiceToken} from '../services/user-preference.service';
import {UserSettingsComponent} from '../user-settings/user-settings.component';
import {MemberService, MemberServiceToken} from '../services/member.service';
import {CustomGroupsService, CustomGroupsServiceToken} from '../filter-banner/services/custom-groups-service';
import {MemberBillingAreaService, MemberBillingAreaServiceToken} from '../services/member-billing-area.service';
import {Event, Router, RoutesRecognized} from '@angular/router';
import * as _ from 'lodash';
import {AlertsService, AlertsServiceToken} from '../services/alerts.service';
import {filterCriteriaFromSelectedCustomGroup} from '../filter-banner/filter-banner-helper';
import {featureConfigRoute} from '../shared/routes';
import {OktaAuth} from '@okta/okta-auth-js';
import {getSelectedNodeCountsFromNodePath} from '../shared/ontology-helpers';
import {SubproductService, SubproductServiceToken} from '../services/subproduct.service';
import {HelpItem, standardHelpItems} from './header-helper';

const USER_SETTINGS_DIALOG_PAMEL_CLASS = 'user-settings-mat-dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {

  @Input() fromBaseComponent: boolean;
  @Input() isExport = false;
  @ViewChild('alert', {static: true}) alertElement: ElementRef;

  @select(['data', 'featureToggleSettings'])
  featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @select(['data', 'userProfile'])
  userProfile$: Observable<UserProfile>;

  @select(['data', 'userSecurityData'])
  userSecurityData$: Observable<UserSecurity[]>;

  @select(['data', 'selectedMemberData'])
  private readonly memberData$: Observable<MemberData>;

  @select(['data', 'members'])
  private readonly members$: Observable<Members>;

  @select(['display', 'communityBenchmarkAlert'])
  private readonly communityBenchmarkAlert$: Observable<boolean>;

  @select(['display', 'isLessThanLagPeriod'])
  private readonly isLessThanLagPeriod$: Observable<boolean>;

  @select(['filters'])
  private readonly filters$: Observable<FilterCriteria>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  alertCount: number;
  selectedMemberData: MemberData;
  userProfile: UserProfile;
  memberKey = -1;
  showOrganizations = false;
  showPersonDropdown = false;
  members: Members;
  showHelp: boolean;
  settings: FeatureToggleSetting[];
  appConfigSettings: ApplicationConfigurationSetting[];
  shouldHideCaretDropdown = false;
  communityBenchmarkAlert: boolean;
  lagKeyAlert: boolean;
  isCollections = false;
  filterNotChanged: boolean;

  currentRoute: string;
  headerDataSubscription: Subscription;
  customServiceSubscription: Subscription;
  routerSubscription: Subscription;
  benchmarksSubscription: Subscription;
  userProfileSubscription: Subscription;
  applicationConfigurationSubscription: Subscription;
  userSecuritySubscription: Subscription;
  memberServiceSubscription: Subscription;
  notificationHistoryUrl: string;

  notificationsShown = false;
  notificationModalUrl: string;
  notificationsAlertCountUrl: string;
  alertCount$: Observable<number>;
  private featureToggleSubscription: Subscription;
  showControlPanelLink = false;

  helpItems: HelpItem[] = standardHelpItems;

  private subproductSubscription: Subscription;
  subproducts: Subproduct[] = [];
  isWaffleOpen: boolean;

  constructor(
    private router: Router,
    private readonly authService: OktaAuth,
    @Inject(UserProfileServiceToken) private readonly userProfileService: UserProfileService,
    @Inject(UserSecurityServiceToken) private readonly userSecurityService: UserSecurityService,
    @Inject(ApplicationConfigurationServiceToken) private readonly applicationConfigurationService: ApplicationConfigurationService,
    @Inject(AnalyticsServiceToken) private readonly gaService: AnalyticsService,
    @Inject(UserPreferenceServiceToken) private readonly userPreferenceService: UserPreferenceService,
    @Inject(MemberServiceToken) private readonly memberService: MemberService,
    @Inject(CustomGroupsServiceToken) private readonly customGroupsService: CustomGroupsService,
    @Inject(MemberBillingAreaServiceToken) private readonly memberBillingAreaService: MemberBillingAreaService,
    @Inject(AlertsServiceToken) private readonly alertsService: AlertsService,
    @Inject(SubproductServiceToken) private readonly subproductService: SubproductService,
    private readonly ngRedux: NgRedux<IAppState>,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.subproductSubscription = this.subproductService.getSubproducts()?.subscribe(subproducts => {
      this.subproducts = this.sortAndFilterSubproducts(subproducts);
    });

    this.headerDataSubscription = combineLatest([this.members$, this.memberData$])
      .subscribe(([membersFromState, selectedMemberDataFromState]: [Members, MemberData]) => {
        this.members = membersFromState;
        this.shouldHideCaretDropdown = this.members.members.length < 2;
        this.selectedMemberData = selectedMemberDataFromState;
        if (selectedMemberDataFromState &&
          selectedMemberDataFromState.memberKey > 0 &&
          this.memberKey !== selectedMemberDataFromState.memberKey &&
          this.fromBaseComponent) {
          this.memberKey = selectedMemberDataFromState.memberKey;
          this.updateApplicationConfigurationSettings(this.memberKey);
          this.customServiceSubscription?.unsubscribe();
          this.customServiceSubscription = this.customGroupsService.getCustomGroups(this.memberKey)
            .subscribe((customGroupsData: CustomGroupsDataAllFilters[]) => {
              this.ngRedux.dispatch(customGroupsDataChangedTo(customGroupsData));
              const requestDetail = this.ngRedux.getState().filters.externalRequestDetail;
              if (requestDetail && requestDetail.memberKey === this.memberKey) {
                this.ngRedux.dispatch(filterCriteriaChangedTo({
                  dateRange: getArbitraryDateRangeFromDatesInMonths(requestDetail.startDateInMonths, requestDetail.endDateInMonths),
                  memberKey: requestDetail.memberKey,
                  nodePath: requestDetail.nodePath,
                  memberLocation: DEFAULT_MEMBER_LOCATION,
                  payerCategory: DEFAULT_PAYER_CATEGORY,
                  payerKey: 0,
                  payerType: 0,
                  lagKey: DEFAULT_LAG_KEY,
                  memberBillingArea: DEFAULT_MEMBER_BILLING_AREA,
                  invoiceStatus: DEFAULT_INVOICE_STATUS,
                  telehealthFlag: DEFAULT_TELEHEALTH_FLAG.key,
                  customGroupId: 0,
                  chosenByUser: true,
                  memberLocationKeys: '0'
                }));
                if (this.ngRedux.getState().ontologyLoaded) {
                  const {
                    selectedProviders,
                    selectedSpecialties
                  } = getSelectedNodeCountsFromNodePath(requestDetail.nodePath);
                  this.ngRedux.dispatch(selectedNodesChangedTo(selectedProviders, selectedSpecialties));
                }
                this.ngRedux.dispatch(collectionsChosenTabChangedTo(requestDetail.tabDimensionKey));
                this.router.navigateByUrl(`/productivity/${getRouteSegmentForMetric(requestDetail.metricPageKey)}`);
                this.ngRedux.dispatch(clearRequestDetails());
              } else {
                const customGroupDefault = customGroupsData.find(g => g.isDefault);
                const existingPreferences = this.ngRedux.getState().data.userPreferenceData
                  .find(upd => upd.memberKey === selectedMemberDataFromState.memberKey);
                this.userPreferenceService.getAndApplyUserPreferences(selectedMemberDataFromState, existingPreferences,
                  !!customGroupDefault);
                if (customGroupDefault) {
                  const newFilterCriteria = filterCriteriaFromSelectedCustomGroup(customGroupDefault, this.ngRedux.getState());
                  const nodePath = customGroupDefault.nodePath;
                  this.ngRedux.dispatch(filterCriteriaChangedTo(newFilterCriteria));
                  this.ngRedux.dispatch(nodePathChangedTo(nodePath));
                  this.userPreferenceService.getRecentMonth(this.memberKey).subscribe((recentMonth: number) => {
                    this.ngRedux.dispatch(recentMonthLoadedTo(recentMonth));
                  });
                  this.ngRedux.dispatch(emNpvBenchmarkOptionChangedTo
                  (getAcademicBenchmarkCorrespondingToTelehealthFlag(customGroupDefault?.telehealthFlag)));
                }
                this.setFilterToDefault();
              }
            });
          this.gaService.set({'memberDescription': selectedMemberDataFromState.memberDesc});
        }
      });

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof RoutesRecognized) {
        this.currentRoute = event.url;
        this.isCollections = this.currentRoute.includes('collections');
      }
    });

    this.benchmarksSubscription = combineLatest([this.communityBenchmarkAlert$,
      this.viewCommunityBenchmarks$, this.isLessThanLagPeriod$, this.filters$])
      .subscribe(([showCommBenchAlert, showCommuBenchOpt, isLessThanLagPeriod, filters]:
                    [boolean, boolean, boolean, FilterCriteria]) => {
          this.communityBenchmarkAlert = showCommBenchAlert && showCommuBenchOpt;
          this.filterNotChanged = _.isEqual(filters, INITIAL_STATE.filters);

          if (this.filterNotChanged) {
            this.lagKeyAlert = isLessThanLagPeriod;
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.headerDataSubscription?.unsubscribe();
    this.customServiceSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.benchmarksSubscription?.unsubscribe();
    this.userProfileSubscription?.unsubscribe();
    this.applicationConfigurationSubscription?.unsubscribe();
    this.userSecuritySubscription?.unsubscribe();
    this.memberServiceSubscription?.unsubscribe();
    this.featureToggleSubscription?.unsubscribe();
    this.subproductSubscription?.unsubscribe();
  }

  openUrlInNewTab(url: string) {
    window.open(url);
  }

  openAlertDialog() {
    this.alertsService.openDialog(this.alertElement);
  }

  private updateUserProfile(memberKey: number) {
    this.userProfileSubscription = this.userProfileService.getUserProfile(memberKey)
      .subscribe((userProfile: UserProfile) => {
        if (userProfile) {
          if (userProfile.userTrackingKey !== INITIAL_STATE.data.userProfile.userTrackingKey) {
            this.gaService.event('gtm.load', {'userguid': userProfile.userTrackingKey});
            this.gaService.set({'title': userProfile.jobTitle});
          }
          this.userProfile = userProfile;
          this.ngRedux.dispatch(userProfileLoadedTo(userProfile));
          this.alertCount$ = this.alertsService.getCount$();
          this.alertsService.initCount();
          this.applicationConfigurationService.grantAccessToConfigSettings(this.userProfile?.email)
            .subscribe(granted => this.showControlPanelLink = granted);
          this.updateFeatureToggleSettings();
        }
      });
  }

  private updateFeatureToggleSettings() {
    const featureToggleSettings = this.ngRedux.getState().data.featureToggleSettings;
    if (featureToggleSettings) {
      this.ngRedux.dispatch(featureToggleSettingsLoadedTo(featureToggleSettings));
      this.handleEvent = this.handleEvent.bind(this);
      window.addEventListener('message', this.handleEvent);
    }
  }

  private updateApplicationConfigurationSettings(memberKey: number) {
    this.applicationConfigurationSubscription = this.applicationConfigurationService.getApplicationConfigurationSettings()
      .subscribe((settings: ApplicationConfigurationSetting[]) => {
        if (settings) {
          this.appConfigSettings = settings;
          this.ngRedux.dispatch(applicationConfigurationSettingsLoadedTo(settings));
          this.updateUserProfile(memberKey);
          this.notificationHistoryUrl = getAppConfigValue(AppConfigEntries.NOTIFICATION_HISTORY_URL, settings);
          this.notificationModalUrl = getAppConfigValue(AppConfigEntries.NOTIFICATION_MODAL_URL, settings);
          this.notificationsAlertCountUrl = getAppConfigValue(AppConfigEntries.NOTIFICATION_ALERT_COUNT_URL, settings);
        }
      });
  }

  handleEvent(event: MessageEvent) {
    if (!this.notificationModalUrl || !this.notificationModalUrl.startsWith(event.origin) || (typeof event.data !== 'boolean')) {
      return null;
    }
    this.notificationsShown = event.data;
  }

  async openFeatureConfigurationLink() {
    await this.router.navigateByUrl(featureConfigRoute);
  }

  clickSettings() {
    this.gaService.handleGoogleAnalytics('User Controls', 'Select Settings', 'Settings');
    this.matDialog.open(UserSettingsComponent, {panelClass: USER_SETTINGS_DIALOG_PAMEL_CLASS});
  }

// noinspection JSUnusedLocalSymbols
  logout() {
    this.authService.signOut({
      postLogoutRedirectUri: getAppConfigValue(AppConfigEntries.LOGOUT_WEBSITE, this.appConfigSettings)
    }).then(() =>
      window.open(getAppConfigValue(AppConfigEntries.LOGOUT_WEBSITE, this.appConfigSettings), '_self')
    );
  }

  toggleHelpDropdown() {
    this.showHelp = !this.showHelp;
  }

  closeHelpDropdown() {
    this.showHelp = false;
  }

  toggleAppWaffleDropdown() {
    this.isWaffleOpen = !this.isWaffleOpen;
  }

  closeAppWaffleDropdown() {
    this.isWaffleOpen = false;
  }

  toggleOrganizationDropdown() {
    this.showOrganizations = !this.showOrganizations;
  }

  closeOrganizationDropDown(event: any) {
    const target = event.relatedTarget || document.activeElement;

    if (target.className !== 'organizations' && target.className !== 'user-controls') {
      this.showOrganizations = false;
    }
  }

  togglePersonDropdown() {
    this.showPersonDropdown = !this.showPersonDropdown;
  }

  closePersonDropDown(event: any) {
    const target = event.relatedTarget || document.activeElement;

    if (target.className !== 'person-dropdown') {
      this.showPersonDropdown = false;
    }
  }

  updateMember(member: MemberData) {
    this.userSecuritySubscription = this.userSecurityService.doesUserHaveAccessToMember(member.memberKey)
      .subscribe((hasAccess: boolean) => {
        if (!hasAccess) {
          NoAccessDialogComponent.prototype.memberName = member.memberDesc;
          this.matDialog.open(NoAccessDialogComponent);
          return;
        }
        if (this.selectedMemberData.memberKey !== member.memberKey) {
          this.selectedMemberData = member;
          updateSelectedMember(member, this.memberService, this.memberBillingAreaService, this.ngRedux);
        }
      });
    this.showOrganizations = false;
  }

  setFilterToDefault() {
    this.ngRedux.dispatch(memberLocationChangedTo(DEFAULT_MEMBER_LOCATION));
    this.ngRedux.dispatch(payerCategoryChangedTo(DEFAULT_PAYER_CATEGORY));
  }

  onHelpOptionClick(appConfigName: AppConfigEntries | string, eventAction: string) {
    this.openAppropriateHelpWindow(appConfigName, eventAction);

    if (eventAction && eventAction !== 'Release Notes') {
      this.gaService.handleGoogleAnalytics('Help Docs', 'Help Documentation Click', eventAction);
    }
  }

  email() {
    window.open(getAppConfigValue(AppConfigEntries.SUPPORT_EMAIL, this.appConfigSettings), '_self');
    this.gaService.handleGoogleAnalytics('Help Docs', 'Help Documentation Click', 'Contact Us');
  }

  openCpscWebsite() {
    window.open(getAppConfigValue(AppConfigEntries.CPSC_WEBSITE, this.appConfigSettings));
  }

  openAAMCWebsite() {
    window.open(getAppConfigValue(AppConfigEntries.AAMC_WEBSITE, this.appConfigSettings));
  }

  openVizientWebsite() {
    window.open(getAppConfigValue(AppConfigEntries.VIZIENT_WEBSITE, this.appConfigSettings));
  }

  closeBenchmarkAlert() {
    this.communityBenchmarkAlert = false;
  }

  closeLagKeyAlert() {
    this.lagKeyAlert = false;
    this.ngRedux.dispatch(LagKeyLessThanChangedTo(false));
  }

  private openAppropriateHelpWindow(appConfigName: AppConfigEntries | string, eventAction: string) {
    if (appConfigName === AppConfigEntries.SUPPORT_EMAIL) {
      window.open(getAppConfigValue(appConfigName, this.appConfigSettings), '_self');
    } else if (appConfigName) {
      window.open(getAppConfigValue(appConfigName, this.appConfigSettings));
    } else if (eventAction === 'Release Notes') {
      window.open(this.notificationHistoryUrl, '_blank');
    }
  }

  // @ts-ignore
  private sortAndFilterSubproducts(subproducts: Subproduct[]): Subproduct[] {
    try {
      const filteredSubproducts = subproducts.filter(s => s.subproduct_name !== 'CPSC Analytics');
      return filteredSubproducts.sort((a, b) =>
        a.subproduct_name.localeCompare(b.subproduct_name));
    } catch (e) {
      console.log(e.error);
    }
  }
}
