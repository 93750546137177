<div class="page">
  <div class="upper-division">
    <div id="provider-cpt-header">
      <span id="provider-cpt-inner-header">{{nodeName}} CPT Analysis</span>
      <div id="export-button-wrapper" [hidden]="!canHideAnalysisExport ">
        <button [ngClass] = "isPageLoading() ? 'greyExportButton' : 'exportButton'"
                id="exportBtn"
                mat-raised-button [disabled]="isPageLoading()"
                (click)="toggleExportDropdown()"
                (blur)="closeExportDropdown()"
                tabindex="0">Export Data
        </button>
        <img class="close-icon" src="assets/Close_grey.svg" (click)="close()"/>
        <div *ngIf="showExportDropdown" class="other-dropdown-content">
          <a (mousedown)="openExcelFile()" class="exportExcel">Export to Excel</a>
          <a (mousedown)="openCSVFile()" class="exportCSV">Export to CSV</a>
        </div>
      </div>
    </div>
    <div id="parentNodeNames">
      {{parentNodeNames}}
    </div>
  </div>
  <div id="data-table-wrapper">
    <app-data-table *ngIf="clinicalSummary && clinicalSummary.cptClinicalSummaries"
                    [receivedData]="clinicalSummary.cptClinicalSummaries"
                    [displayedColumns]="viewCommunityBenchmarks ? cptCodeCommunityColumns : cptCodeColumns"
                    [tableCssClass]="'viewCptDataTable'"
                    [displayAboveHeaders]="true"
                    [paginate]="true"
    ></app-data-table>
  </div>
  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
</div>
