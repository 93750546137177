import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {monthNames} from '../../../../shared/helpers';
import {isValidOrElse} from '../../../../shared/null-helpers';
import {MultilevelTab} from '../../../../shared/enums';
import {
  ConsultationCodes,
  EstablishedPatientVisitCodes,
  EvaluationManagementOutpatientMultilevelRow,
  NewPatientVisitCodes,
  valueFromOrElse
} from '../../../../evaluation-management/em-helpers';

@Component({
  selector: 'app-em-batch-outpatient-multilevel-table',
  templateUrl: './em-batch-outpatient-multilevel-table.component.html',
  styleUrls: ['./em-batch-outpatient-multilevel-table.component.scss']
})
export class EmBatchOutpatientMultilevelTableComponent implements OnChanges {
  @Input() receivedData: EvaluationManagementOutpatientMultilevelRow[];
  @Input() viewCommunityBenchmarks = false;
  NewPatientVisitCodes = NewPatientVisitCodes;
  EstablishedPatientVisitCodes = EstablishedPatientVisitCodes;
  ConsultationCodes = ConsultationCodes;

  MultilevelTab = MultilevelTab;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @select(['display', 'isCustom'])
  private readonly isCustom$: Observable<boolean>;

  dataSource: MatTableDataSource<EvaluationManagementOutpatientMultilevelRow>;
  displayedColumns = ['departmentNodeName', 'encounter',
    'countOf99201', 'countOf99202', 'countOf99203', 'countOf99204', 'countOf99205', 'totalNewPatientVisit',
    'countOf99211', 'countOf99212', 'countOf99213', 'countOf99214', 'countOf99215', 'totalEstablishedPatientVisit',
    'countOf99241', 'countOf99242', 'countOf99243', 'countOf99244', 'countOf99245', 'totalConsultation'];
  pageIndex = 0;
  pageSize = 25;
  isValidOrElse = isValidOrElse;
  valueFromOrElse = valueFromOrElse;
  monthNames = monthNames;
  benchmarkString = 'benchmarkPercentage';
  benchmarkDisplay: string;

  constructor() {
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<EvaluationManagementOutpatientMultilevelRow>(this.receivedData);
    if (this.viewCommunityBenchmarks) {
      this.benchmarkString = 'communityBenchmarkPercentage';
      this.benchmarkDisplay = 'Community';
    } else {
      this.benchmarkString = 'benchmarkPercentage';
      this.benchmarkDisplay = 'Academic';
    }
  }
}
