<div class="variable-container" [ngClass]="showVariableMenu ? 'show-variable-menu' : 'hide-variable-menu'">
  <mat-label class="variable">Add Variable:</mat-label>

  <div *ngFor="let variable of variables">
    <button [ngClass]="disabled ? 'disabled-variable-button' : variable.display ? 'variable-button-selected' : ''"
            class="add-variables-button variable-button" [disabled]="disabled"
            (click)="showOrHideVariable(variable)">
      {{variable.name}}
      <i [ngClass]="variable.display && !disabled ? 'fa fa-times cross-button':''" aria-hidden="true"></i>
    </button>
  </div>

</div>
