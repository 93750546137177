/* tslint:disable:max-line-length*/
import {AppComponent} from './app.component';
import {
  ProductivitySummaryByMonthChartComponent
} from './productivity-summary/month/productivity-summary-by-month-chart.component';
import {
  HttpProductivitySummaryService,
  ProductivitySummaryServiceToken
} from './productivity-summary/services/productivity-summary.service';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {Routing} from './routing';
import {NavigationBarComponent} from './navigation-bar/navigation-bar.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {IAppState, INITIAL_STATE} from './store/IAppState';
import {rootReducer} from './store/reducers';
import {BearerTokenInterceptor, JwtExpirationServiceToken} from './security/bearer-token.interceptor';
import {environment} from '../environments/environment.local';
import {DepartmentProductivityComponent} from './department-productivity/department-productivity.component';
import {ProductivityMonthPageComponent} from './productivity-summary/month/productivity-month-page.component';
import {BaseComponent} from './base/base.component';
import {
  HttpProviderProductivityService,
  ProviderProductivityServiceToken
} from './productivity-summary/services/provider-productivity-service';
import {FilterListenerService, FilterListenerServiceToken} from './services/filter-listener.service';
import {ListenerInjectorComponent} from './listener-injector/listener-injector.component';

import {MemberListenerServiceImpl, MemberListenerServiceToken} from './services/member-listener.service';
import {HttpMemberService, MemberServiceToken} from './services/member.service';
import {FilterSelectorServiceToken, ValidFilterSelectorService} from './services/filter-selector.service';
import {ChartLegendComponent} from './legend/chart-legend.component';
import {
  HttpProductivityMonthService,
  ProductivityMonthServiceToken
} from './productivity-summary/services/productivity-month.service';
import {AnalyticsServiceToken, AnalyticsService} from './analytics/analytics.service';
import {WindowWrapper} from './analytics/window.injectable';
import {UnauthorizedErrorPageComponent} from './unauthorized-error-page/unauthorized-error-page.component';
import {GlobalErrorHandler} from './services/global-error-handler';
import {AngularCSV, CSVToken} from './services/csv.service';
import {MetricNavComponent} from './metric-nav/metric-nav.component';
import {VariableContainerComponent} from './variable-container/variable-container.component';
import {DataTableComponent} from './data-table/data-table.component';
import {
  EvaluationManagementServiceToken,
  HttpEvaluationManagementService
} from './evaluation-management/services/evaluation-management.service';
import {
  HttpNewPatientVisitService,
  NewPatientVisitServiceToken
} from './new-patient-visits/services/new-patient-visit.service';
import {
  EvaluationManagementChartComponent
} from './evaluation-management/components/chart/evaluation-management-chart.component';
import {OntologyHierarchyServiceImpl, OntologyHierarchyServiceToken} from './services/ontology-hierarchy.service';
import {
  NewPatientVisitsByMonthPageComponent
} from './new-patient-visits/components/month/new-patient-visits-by-month-page/new-patient-visits-by-month-page.component';
import {
  NewPatientVisitsByMonthChartComponent
} from './new-patient-visits/components/month/new-patient-visits-by-month-chart/new-patient-visits-by-month-chart.component';
import {
  HttpMonthNewPatientVisitService,
  MonthNewPatientVisitServiceToken
} from './new-patient-visits/services/month-new-patient-visit-service';
import {LegalDisclaimerComponent} from './legal-disclaimer/legal-disclaimer.component';
import {
  WrvuSummaryDataTableComponent
} from './productivity-summary/wrvu-summary-data-table/wrvu-summary-data-table.component';
import {
  NewPatientVisitsSummaryDataTableComponent
} from './new-patient-visits/components/new-patient-visits-summary-data-table/new-patient-visits-summary-data-table.component';
import {HttpUserProfileService, UserProfileServiceToken} from './services/user-profile.service';
import {LoaderComponent} from './loader/loader.component';
import {LoaderService} from './services/loader.service';
import {
  EmOutpatientSummaryTableComponent
} from './evaluation-management/components/em-outpatient-summary-table/em-outpatient-summary-table.component';
import {
  EmInpatientSummaryTableComponent
} from './evaluation-management/components/em-inpatient-summary-table/em-inpatient-summary-table.component';
import {OverviewPageComponent} from './overview/page/overview-page.component';
import {FeatureToggleServiceToken, HttpFeatureToggleService} from './services/feature-toggle.service';
import {OverviewTileComponent} from './overview/components/overview-tile/overview-tile.component';
import {ChartComponent} from './chart/chart.component';
import {MemberLocationServiceImpl, MemberLocationServiceToken} from './services/member-location.service';
import {
  EmEmergencyRoomSummaryTableComponent
} from './evaluation-management/components/em-emergency-room-summary-table/em-emergency-room-summary-table.component';
import {PayerCategoryServiceImpl, PayerCategoryServiceToken} from './services/payer-category.service';
import {
  EmEyeExamSummaryTableComponent
} from './evaluation-management/components/em-eye-exam-summary-table/em-eye-exam-summary-table.component';
import {
  HttpZipCodeNewPatientVisitService,
  ZipCodeNewPatientVisitServiceToken
} from './new-patient-visits/services/zipcode-new-patient-visit-service';
import {
  ZipCodeOverviewTileComponent
} from './overview/components/zip-code-overview-tile/zip-code-overview-tile.component';
import {OverviewTileFooterComponent} from './overview/components/overview-tile-footer/overview-tile-footer.component';
import {ToggleCfteActualWrvusComponent} from './toggle-cfte-actual-wrvus/toggle-cfte-actual-wrvus.component';
import {AgmHelperService, ZipcodeMapComponent} from './zipcode-map/zipcode-map.component';
import {NoAccessDialogComponent} from './no-access-dialog/no-access-dialog.component';
import {HttpUserSecurityService, UserSecurityServiceToken} from './services/user-security.service';
import {NoAccessPageComponent} from './no-access-page/no-access-page.component';
import {
  ApplicationConfigurationServiceToken,
  HttpApplicationConfigurationService
} from './services/app-configuration.service';
import {ExportDialogComponent} from './export-dialog/export-dialog.component';
import {HttpLegalService, LegalServiceToken} from './services/legal.service';
import {ExcelService, ExcelServiceToken} from './services/excel.service';
import {ExportPdfDialogComponent} from './export-pdf-dialog/export-pdf-dialog.component';
import {HttpUserPreferenceService, UserPreferenceServiceToken} from './services/user-preference.service';
import {UserSettingsComponent} from './user-settings/user-settings.component';
import {ClinicalFingerprintComponent} from './clinical-fingerprint/clinical-fingerprint.component';
import {
  ClinicalSummaryServiceToken,
  HttpClinicalSummaryService
} from './clinical-fingerprint/services/clinical-finger-print.service';
import {ClinicalFingerprintChartComponent} from './clinical-fingerprint/chart/clinical-fingerprint-chart.component';
import {ToggleCptViewTypeComponent} from './toggle-cpt-view-type/toggle-cpt-view-type.component';
import {ZeroSuppressionComponent} from './zero-suppression/zero-suppression-component';
import {ExportButtonComponent} from './export-button/export-button.component';
import {CollectionServiceToken, HttpCollectionsService} from './collections/services/collections-service';
import {HttpNewFeaturesService, NewFeaturesServiceToken} from './services/new-features.service';
import {FilterCriteriaTableComponent} from './filter-banner/filter-criteria-table/filter-criteria-table.component';
import {CustomGroupsServiceToken, HttpCustomGroupsService} from './filter-banner/services/custom-groups-service';
import {LegendBenchmarkOptionComponent} from './legend-benchmark-type/legend-benchmark-option.component';
import {ViewCptComponent} from './view-cpt/view-cpt.component';
import {ColumnSelectionComponent} from './column-selection/column-selection.component';
import {AppToolkitModule} from './app-toolkit.module';
import {SelectLagKeyComponent} from './filter-banner/select-lag-key/select-lag-key.component';
import {MemberBillingAreaServiceImpl, MemberBillingAreaServiceToken} from './services/member-billing-area.service';
import {SelectBillingAreaComponent} from './filter-banner/select-billing-area/select-billing-area.component';
import {SelectInvoiceStatusComponent} from './filter-banner/select-invoice-status/select-invoice-status.component';
import * as highstock from 'highcharts/modules/stock.src';
import {SummaryCollectionsComponent} from './collections/summary-collections/summary-collections.component';
import {LegendBenchmarkPercentileComponent} from './legend-benchmark-percentile/legend-benchmark-percentile.component';
import {
  SummaryCollectionsTableComponent
} from './collections/summary-collections/summary-collections-table/summary-collections-table.component';
import {
  SummaryCollectionsChartComponent
} from './collections/summary-collections/summary-collections-chart/summary-collections-chart.component';
import {VarianceTogglerComponent} from './variance-toggler/variance-toggler.component';
import {PdfHeaderComponent} from './pdf-header/pdf-header.component';
import {PayerSelectorComponent} from './payer-selector/payer-selector.component';
import {SafePipe} from './shared/safe.pipe';
import {ComplianceNoticeComponent} from './compliance-notice/compliance-notice.component';
import {
  ComplianceServiceToken,
  HttpComplianceService
} from './compliance-notice/services/compliance-notification-service';
import {BatchExportDialogComponent} from './batch-export/batch-export-dialog/batch-export-dialog.component';
import {BatchExportMessageComponent} from './batch-export/batch-export-message/batch-export-message.component';
import {VizDateRangePickerModule} from './shared/libs/date-range-picker/viz-date-range-picker.module';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DevToolsExtension, NgRedux, NgReduxModule} from '@angular-redux/store';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {OKTA_CONFIG, OktaAuthModule} from '@okta/okta-angular';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {AgmCoreModule} from '@agm/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {AppMaterialModule} from './app-material.module';
import {MatInputModule} from '@angular/material/input';
import {
  NewPatientVisitsDepartmentPageComponent
} from './new-patient-visits/components/provider/page-department/new-patient-visits-department-page.component';
import {
  NewPatientVisitsSpecialtyPageComponent
} from './new-patient-visits/components/provider/page-specialty/new-patient-visits-specialty-page.component';
import {
  NewPatientVisitsProviderPageComponent
} from './new-patient-visits/components/provider/page-provider/new-patient-visits-provider-page.component';
import {NpvMultilevelComponent} from './new-patient-visits/components/provider/npv-multilevel/npv-multilevel.component';
import {NpvBaseComponent} from './new-patient-visits/components/provider/npv-base/npv-base.component';
import {WrvuBaseComponent} from './productivity-summary/provider/wrvu-base/wrvu-base.component';
import {WrvuMultilevelComponent} from './productivity-summary/provider/wrvu-multilevel/wrvu-multilevel.component';
import {
  WrvuMultilevelProviderPageComponent
} from './productivity-summary/provider/wrvu-multilevel-provider-page/wrvu-multilevel-provider-page.component';
import {
  WrvuMultilevelSpecialtyPageComponent
} from './productivity-summary/provider/wrvu-multilevel-specialty-page/wrvu-multilevel-specialty-page.component';
import {
  WrvuMultilevelDepartmentPageComponent
} from './productivity-summary/provider/wrvu-multilevel-department-page/wrvu-multilevel-department-page.component';
import {CollectionsMultilevelComponent} from './collections/collections-multilevel/collections-multilevel.component';
import {CollectionsDepartmentPageComponent} from './collections/page-department/collections-department.component';
import {CollectionsSpecialtyPageComponent} from './collections/page-specialty/collections-specialty.component';
import {CollectionsProviderPageComponent} from './collections/page-provider/collections-provider.component';
import {DenialsModalComponent} from './denials-modal/denials-modal.component';
import {
  WarningLimitExceededComponent
} from './filter-banner/advanced-navigation/warning-limit-exceeded/warning-limit-exceeded.component';
import {DenialsComponent} from './denials/denials.component';
import {ApiServiceImpl, ApiServiceToken} from './services/api.service';
import {DenialServiceToken, HttpDenialsService} from './denials/services/denials-service';
import {ExportedReportsDialogComponent} from './batch-export/exported-reports-dialog/exported-reports-dialog.component';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {ConfirmDialogComponent} from './shared/components/confirm-dialog/confirm-dialog.component';
import {BatchExportServiceToken, HttpBatchExportService} from './batch-export/services/batch-export.service';
import {BatchExportPdfComponent} from './batch-export/batch-export-pdf/batch-export-pdf.component';
import {AlertsServiceToken, HttpAlertsService} from './services/alerts.service';
import {
  BatchExportPdfHeaderComponent
} from './batch-export/batch-export-pdf/batch-export-pdf-header/batch-export-pdf-header.component';
import {
  WrvuTileBatchExportComponent
} from './batch-export/batch-export-pdf/wrvu-tile-batch-export/wrvu-tile-batch-export.component';
import {
  NpvTileBatchExportComponent
} from './batch-export/batch-export-pdf/npv-tile-batch-export/npv-tile-batch-export.component';
import {
  ZipCodeNpvBatchExportComponent
} from './batch-export/batch-export-pdf/zip-code-npv-batch-export/zip-code-npv-batch-export.component';
import {
  EvaluationManagementBatchExportChartComponent
} from './batch-export/batch-export-pdf/evaluation-management-tile-batch-export/evaluation-management-batch-export-chart/evaluation-management-batch-export-chart.component';
import {
  EmOutpatientBatchExportComponent
} from './batch-export/batch-export-pdf/evaluation-management-tile-batch-export/em-outpatient-batch-export/em-outpatient-batch-export.component';
import {
  EmInpatientBatchExportComponent
} from './batch-export/batch-export-pdf/evaluation-management-tile-batch-export/em-inpatient-batch-export/em-inpatient-batch-export.component';
import {
  EmEmergencyMedicineBatchExportComponent
} from './batch-export/batch-export-pdf/evaluation-management-tile-batch-export/em-emergency-medicine-batch-export/em-emergency-medicine-batch-export.component';
import {
  EmEyeExamBatchExportComponent
} from './batch-export/batch-export-pdf/evaluation-management-tile-batch-export/em-eye-exam-batch-export/em-eye-exam-batch-export.component';
import {
  EvaluationManagementTileBatchExportComponent
} from './batch-export/batch-export-pdf/evaluation-management-tile-batch-export/evaluation-management-tile-batch-export.component';
import {
  ChangeFiscalYearComponent
} from './shared/libs/date-range-picker/datepicker/change-fiscal-year/change-fiscal-year.component';
import {AcknowledgeDialogComponent} from './shared/components/acknowledge-dialog/acknowledge-dialog.component';
import {DenialsChartComponent} from './denials/denials-chart/denials-chart.component';
import {CptsDeniedModalComponent} from './denials/cpts-denied-modal/cpts-denied-modal.component';
import {
  AgmHelperBatchExportService,
  ZipcodeMapBatchExportComponent
} from './batch-export/batch-export-pdf/zip-code-npv-batch-export/zipcode-map-batch-export/zipcode-map-batch-export.component';
import {DenialsMetricDataTableComponent} from './denials/denials-metric-data-table/denials-metric-data-table.component';
import {SelectVisitTypeComponent} from './filter-banner/select-visit-type/select-visit-type.component';
import {DateRangeTextPipe} from './shared/dateRangeText.pipe';
import {SaveApplyDialogComponent} from './shared/components/save-apply-dialog/save-apply-dialog.component';
import {DenialsMultilevelComponent} from './denials/denials-multilevel/denials-multilevel.component';
import {
  DenialsDepartmentPageComponent
} from './denials/denials-multilevel/denials-department-page/denials-department-page.component';
import {
  DenialsSpecialtyPageComponent
} from './denials/denials-multilevel/denials-specialty-page/denials-specialty-page.component';
import {
  DenialsProviderPageComponent
} from './denials/denials-multilevel/denials-provider-page/denials-provider-page.component';
import {
  DenialsMultilevelChartComponent
} from './denials/denials-multilevel/denials-multilevel-chart/denials-multilevel-chart.component';
import {
  ClinicalFingerprintTableComponent
} from './clinical-fingerprint/clinical-fingerprint-table/clinical-fingerprint-table.component';
import {SelectLocationComponent} from './filter-banner/select-location/select-location.component';
import {EmPageTrendComponent} from './evaluation-management/components/em-page-trend/em-page-trend.component';
import {
  EmTrendTableComponent
} from './evaluation-management/components/em-page-trend/em-trend-table/em-trend-table.component';
import {
  EmTrendChartComponent
} from './evaluation-management/components/em-page-trend/em-trend-chart/em-trend-chart.component';
import {
  DepartmentSpecialtyListenerService,
  DepartmentSpecialtyListenerServiceToken
} from './services/department-specialty-listener.service';
import {CfpToggleVariablesComponent} from './clinical-fingerprint/cfp-toggle-variables/cfp-toggle-variables.component';
import {BatchExportMlPdfComponent} from './batch-export/em-ml-batch-export-pdf/batch-export-ml-pdf.component';
import {
  EvaluationManagementMlTileBatchExportComponent
} from './batch-export/em-ml-batch-export-pdf/evaluation-management-ml-tile-batch-export/evaluation-management-ml-tile-batch-export.component';
import {
  EmBatchOutpatientMultilevelTableComponent
} from './batch-export/em-ml-batch-export-pdf/evaluation-management-ml-tile-batch-export/em-batch-outpatient-multilevel-table/em-batch-outpatient-multilevel-table.component';
import {
  EmBatchEmergencyMultilevelTableComponent
} from './batch-export/em-ml-batch-export-pdf/evaluation-management-ml-tile-batch-export/em-batch-emergency-multilevel-table/em-batch-emergency-multilevel-table.component';
import {SearchPayerComponent} from './filter-banner/search-payer/search-payer.component';
import {
  EmSnapshotTableComponent
} from './evaluation-management/components/em-snapshot-table/em-snapshot-table.component';
import {TabNavigationComponent} from './tab-navigation/tab-navigation.component';
import {
  CfpMultilevelPageComponent
} from './clinical-fingerprint/cfp-multilevel/cfp-multilevel-page/cfp-multilevel-page.component';
import {
  CfpMultilevelTableComponent
} from './clinical-fingerprint/cfp-multilevel/cfp-multilevel-table/cfp-multilevel-table.component';
import {
  ProcedureSummaryFilterComponent
} from './clinical-fingerprint/procedure-summary/procedure-summary-filter/procedure-summary-filter.component';
import {
  SearchCptFamilyComponent
} from './clinical-fingerprint/procedure-summary/procedure-summary-filter/search-cpt-family/search-cpt-family.component';
import {TestMigrationComponent} from './test-migration/test-migration.component';
import {
  NewPatientVisitsDataTableComponent
} from './new-patient-visits/components/new-patient-visits-data-table/new-patient-visits-data-table.component';
import {
  SearchCptRangeComponent
} from './clinical-fingerprint/procedure-summary/procedure-summary-filter/search-cpt-range/search-cpt-range.component';
import {ProcedureSummaryComponent} from './clinical-fingerprint/procedure-summary/procedure-summary.component';
import {
  RecursiveHierarchicalExportOptionComponent
} from './recursive-hierarchical-selection/recursive-hierarchical-export-option.component';
import {NpvMlBatchExportPdfComponent} from './batch-export/npv-ml-batch-export-pdf/npv-ml-batch-export-pdf.component';
import {
  NpvMonthBatchPdfComponent
} from './batch-export/npv-ml-batch-export-pdf/npv-month-batch-pdf/npv-month-batch-pdf.component';
import {
  NpvSummaryBatchPdfComponent
} from './batch-export/npv-ml-batch-export-pdf/npv-summary-batch-pdf/npv-summary-batch-pdf.component';
import {
  NpvTrendTableBatchPdfComponent
} from './batch-export/npv-ml-batch-export-pdf/npv-trend-table-batch-pdf/npv-trend-table-batch-pdf.component';
import {
  SummaryProcedureSummaryComponent
} from './clinical-fingerprint/procedure-summary/summary-procedure-summary/summary-procedure-summary.component';
import {
  ProcedureSummaryGraphComponent
} from './clinical-fingerprint/procedure-summary/procedure-summary-graph/procedure-summary-graph.component';
import {SelectPdfExportComponent} from './export-dialog-multilevel/select-pdf-export/select-pdf-export.component';
import {
  HttpProcedureSummaryService,
  ProcedureSummaryServiceToken
} from './clinical-fingerprint/procedure-summary/procedure-summary-services/procedure-summary.service';
import {VisitTypeComponent} from './updated-filter-banner/visit-type/visit-type.component';
import {
  UpdatedSaveGroupingComponent
} from './updated-filter-banner/updated-save-grouping/updated-save-grouping.component';
import {
  UpdatedManageGroupsComponent
} from './updated-filter-banner/updated-manage-groups/updated-manage-groups.component';
import {
  HttpUpdatedCustomGroupService,
  UpdatedCustomGroupServiceToken
} from './updated-filter-banner/services/updated-custom-group-service';
import {SelectPayerComponent} from './updated-filter-banner/select-payer/select-payer.component';
import {SearchLocationComponent} from './updated-filter-banner/search-location/search-location.component';
import {SearchLagKeyComponent} from './updated-filter-banner/search-lag-key/search-lag-key.component';
import {SearchBillingAreasComponent} from './updated-filter-banner/search-billing-areas/search-billing-areas.component';
import {
  ProviderLimitExceededComponent
} from './updated-filter-banner/provider-limit-exceeded/provider-limit-exceeded.component';
import {InvoiceComponent} from './updated-filter-banner/invoice/invoice.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {
  ProcedureSummaryGraphFilterSelectorComponent
} from './clinical-fingerprint/procedure-summary/procedure-summary-graph/procedure-summary-graph-filter-selector/procedure-summary-graph-filter-selector.component';
import {
  SearchMultipleLocationsComponent
} from './filter-banner/search-multiple-locations/search-multiple-locations.component';
import {
  MultiSearchLocationComponent
} from './updated-filter-banner/multi-search-location/multi-search-location.component';
import {FilterBannerComponent} from './filter-banner/filter-banner.component';
import {AdvancedNavigationComponent} from './filter-banner/advanced-navigation/advanced-navigation.component';
import {
  AppConfigControlPanelComponent
} from './control-panel/app-config-control-panel/app-config-control-panel.component';
import {
  FeatureToggleControlPanelComponent
} from './control-panel/feature-toggle-control-panel/feature-toggle-control-panel.component';
import {ControlPanelComponent} from './control-panel/control-panel.component';
import {CustomGroupSelectComponent} from './custom-group-select/custom-group-select.component';
import {
  ButtonCptGroupingsComponent
} from './clinical-fingerprint/procedure-summary/procedure-summary-filter/button-cpt-groupings/button-cpt-groupings.component';
import {OktaAuth, OktaAuthOptions} from '@okta/okta-auth-js';
import {
  BatchDialogHierarchicalSelectorComponent
} from './batch-export/batch-export-dialog/batch-dialog-hierarchical-selector/batch-dialog-hierarchical-selector.component';
import {TrendEmBatchExportComponent} from './batch-export/trend-em-batch-export/trend-em-batch-export.component';
import {
  TrendEmBatchSummaryComponent
} from './batch-export/trend-em-batch-export/trend-em-batch-summary/trend-em-batch-summary.component';
import {
  EmTrendCptRangeSelectComponent
} from './evaluation-management/components/em-page-trend/em-trend-chart/em-trend-cpt-range-select/em-trend-cpt-range-select.component';
import {
  EmTrendCptCodeSelectComponent
} from './evaluation-management/components/em-page-trend/em-trend-chart/em-trend-cpt-code-select/em-trend-cpt-code-select.component';
import {SubproductServiceImpl, SubproductServiceToken} from './services/subproduct.service';
import {
  PageNewPatientVisitsLocationComponent
} from './new-patient-visits/components/page-new-patient-visits-location/page-new-patient-visits-location.component';
import {
  MultiProviderExportRedesignComponent
} from './batch-export/multi-provider-export-redesign/multi-provider-export-redesign.component';
import {
  BatchExportBenchmarkSelectorComponent
} from './batch-export/multi-provider-export-redesign/metric-benchmark-pane/batch-export-benchmark-selector/batch-export-benchmark-selector.component';
import {
  HttpLocationNewPatientVisitService,
  LocationNewPatientVisitServiceToken
} from './new-patient-visits/services/location-new-patient-visit.service';
import {
  PercentageOfNewPatientsMultilevelChartComponent
} from './new-patient-visits/components/provider/chart-multilevel-npv/percentage-of-new-patients-multilevel-chart.component';
import {
  PayerCollectionsChartComponent
} from './collections/payer-collections/payer-collections-multilevel/payer-collections-chart/payer-collections-chart.component';
import {
  CollectionsMultilevelChartComponent
} from './collections/collections-multilevel/collections-multilevel-chart/collections-multilevel-chart.component';
import {
  WrvuMultilevelChartComponent
} from './productivity-summary/provider/wrvu-multilevel-chart/wrvu-multilevel-chart.component';
import {
  EmPageMultilevelComponent
} from './evaluation-management/components/em-page-multilevel/em-page-multilevel.component';
import {
  EmEmergencyRoomMultilevelChartsComponent
} from './evaluation-management/components/em-emergency-room-multilevel-charts/em-emergency-room-multilevel-charts.component';
import {
  EmOutpatientMultilevelChartsComponent
} from './evaluation-management/components/em-outpatient-multilevel-charts/em-outpatient-multilevel-charts.component';
import {
  PayerCollectionsMultilevelComponent
} from './collections/payer-collections/payer-collections-multilevel/payer-collections-multilevel.component';
import {
  EmInpatientMultilevelChartComponent
} from './evaluation-management/components/em-inpatient-multilevel-chart/em-inpatient-multilevel-chart.component';
import {
  EmEyeExamMultilevelChartComponent
} from './evaluation-management/components/em-eye-exam-multilevel-chart/em-eye-exam-multilevel-chart.component';
import {
  LocationNpvTableComponent
} from './new-patient-visits/components/page-new-patient-visits-location/location-npv-table/location-npv-table.component';
import {
  MpeSummaryPaneComponent
} from './batch-export/multi-provider-export-redesign/mpe-summary-pane/mpe-summary-pane.component';
import {
  MetricBenchmarkPaneComponent
} from './batch-export/multi-provider-export-redesign/metric-benchmark-pane/metric-benchmark-pane.component';
import {
  DialogFooterComponent
} from './batch-export/multi-provider-export-redesign/dialog-footer/dialog-footer.component';
import {
  NpvLocationChartComponent
} from './new-patient-visits/components/page-new-patient-visits-location/npv-location-chart/npv-location-chart.component';
import {
  NpvLocationGraphFilterSelectorComponent
} from './new-patient-visits/components/page-new-patient-visits-location/npv-location-chart/npv-location-graph-filter-selector/npv-location-graph-filter-selector.component';
import {
  ReportSchedulePaneComponent
} from './batch-export/multi-provider-export-redesign/report-schedule-pane/report-schedule-pane.component';
import {
  MpeSummaryPaneRowComponent
} from './batch-export/multi-provider-export-redesign/mpe-summary-pane/mpe-summary-pane-row/mpe-summary-pane-row.component';
import {NoAccessPageBatchExportComponent} from './no-access-page-batch-export/no-access-page-batch-export.component';
import {
  MpeSuccessPageComponent
} from './batch-export/multi-provider-export-redesign/mpe-success-page/mpe-success-page.component';
import {
  OneTimeExportsTableComponent
} from './batch-export/exported-reports-dialog/one-time-exports-table/one-time-exports-table.component';
import {
  ScheduledExportsTableComponent
} from './batch-export/exported-reports-dialog/scheduled-exports-table/scheduled-exports-table.component';
import {
  NpvLocationTableHeaderComponent
} from './new-patient-visits/components/page-new-patient-visits-location/location-npv-table/npv-location-table-header/npv-location-table-header.component';
import {AdoTestingComponent} from './test-ado-page/ado-testing.component';
import {
  MpeHierarchicalSelectorComponent
} from './batch-export/multi-provider-export-redesign/mpe-hierarchical-selector/mpe-hierarchical-selector.component';
import {NodeListContainerComponent} from './shared/components/node-list-container/node-list-container.component';
import {
  ExportedReportsRedesignDialogComponent
} from './batch-export/exported-reports-dialog/exported-reports-redesign-dialog/exported-reports-redesign-dialog.component';
import {StyledTextContainerComponent} from './shared/components/styled-text-container/styled-text-container.component';
import {
  ZipCodeBubbleMapComponent
} from './overview/components/zip-code-overview-tile/zip-code-bubble-map/zip-code-bubble-map.component';
import { RedesignedOneTimeExportsTableComponent } from './batch-export/exported-reports-dialog/exported-reports-redesign-dialog/redesigned-one-time-exports-table/redesigned-one-time-exports-table.component';
import { PostMpeDownloadComponent } from './batch-export/exported-reports-dialog/exported-reports-redesign-dialog/post-mpe-download/post-mpe-download.component';

const config: OktaAuthOptions = {
  issuer: environment.issuer,
  redirectUri: `${environment.thisUri}/implicit/callback`,
  clientId: environment.clientId,
  scopes: ['openid', 'email'],
  pkce: false
};

const oktaAuth = new OktaAuth(config);

// noinspection JSUnusedGlobalSymbols
@NgModule({
  declarations: [
    AppComponent,
    ProductivitySummaryByMonthChartComponent,
    HeaderComponent,
    NavigationBarComponent,
    FooterComponent,
    DepartmentProductivityComponent,
    ProductivityMonthPageComponent,
    BaseComponent,
    ListenerInjectorComponent,
    ChartLegendComponent,
    UnauthorizedErrorPageComponent,
    MetricNavComponent,
    VariableContainerComponent,
    DataTableComponent,
    PercentageOfNewPatientsMultilevelChartComponent,
    EvaluationManagementChartComponent,
    NewPatientVisitsByMonthChartComponent,
    NewPatientVisitsByMonthPageComponent,
    LegalDisclaimerComponent,
    WrvuSummaryDataTableComponent,
    NewPatientVisitsSummaryDataTableComponent,
    LoaderComponent,
    EmOutpatientSummaryTableComponent,
    EmInpatientSummaryTableComponent,
    OverviewPageComponent,
    OverviewTileComponent,
    ChartComponent,
    EmEmergencyRoomSummaryTableComponent,
    EmEyeExamSummaryTableComponent,
    ZipCodeOverviewTileComponent,
    OverviewTileFooterComponent,
    ToggleCfteActualWrvusComponent,
    ToggleCptViewTypeComponent,
    ZipcodeMapComponent,
    NoAccessDialogComponent,
    NoAccessPageComponent,
    ExportDialogComponent,
    ExportPdfDialogComponent,
    UserSettingsComponent,
    ClinicalFingerprintComponent,
    ClinicalFingerprintChartComponent,
    ZeroSuppressionComponent,
    FilterBannerComponent,
    CustomGroupSelectComponent,
    ExportButtonComponent,
    FilterCriteriaTableComponent,
    LegendBenchmarkOptionComponent,
    PayerCollectionsChartComponent,
    ViewCptComponent,
    DenialsModalComponent,
    ColumnSelectionComponent,
    SelectLagKeyComponent,
    SelectBillingAreaComponent,
    SelectInvoiceStatusComponent,
    SummaryCollectionsComponent,
    LegendBenchmarkPercentileComponent,
    SummaryCollectionsTableComponent,
    SummaryCollectionsChartComponent,
    VarianceTogglerComponent,
    PdfHeaderComponent,
    PayerSelectorComponent,
    SafePipe,
    DateRangeTextPipe,
    ComplianceNoticeComponent,
    AdvancedNavigationComponent,
    BatchExportDialogComponent,
    BatchExportMessageComponent,
    NewPatientVisitsDepartmentPageComponent,
    NewPatientVisitsProviderPageComponent,
    NewPatientVisitsSpecialtyPageComponent,
    NpvMultilevelComponent,
    NpvBaseComponent,
    CollectionsMultilevelComponent,
    CollectionsDepartmentPageComponent,
    CollectionsProviderPageComponent,
    CollectionsSpecialtyPageComponent,
    WrvuBaseComponent,
    WrvuMultilevelComponent,
    WrvuMultilevelProviderPageComponent,
    WrvuMultilevelSpecialtyPageComponent,
    WrvuMultilevelDepartmentPageComponent,
    CollectionsMultilevelChartComponent,
    WrvuMultilevelChartComponent,
    EmPageMultilevelComponent,
    EmOutpatientMultilevelChartsComponent,
    EmInpatientMultilevelChartComponent,
    EmEyeExamMultilevelChartComponent,
    EmEmergencyRoomMultilevelChartsComponent,
    ConfirmDialogComponent,
    SaveApplyDialogComponent,
    AcknowledgeDialogComponent,
    PayerCollectionsMultilevelComponent,
    WarningLimitExceededComponent,
    DenialsComponent,
    ExportedReportsDialogComponent,
    AlertDialogComponent,
    BatchExportPdfComponent,
    BatchExportMlPdfComponent,
    BatchExportPdfHeaderComponent,
    WrvuTileBatchExportComponent,
    NpvTileBatchExportComponent,
    ZipCodeNpvBatchExportComponent,
    EvaluationManagementTileBatchExportComponent,
    EvaluationManagementMlTileBatchExportComponent,
    ZipCodeNpvBatchExportComponent,
    EvaluationManagementBatchExportChartComponent,
    EmOutpatientBatchExportComponent,
    EmInpatientBatchExportComponent,
    EmEmergencyMedicineBatchExportComponent,
    EmEmergencyMedicineBatchExportComponent,
    EmEyeExamBatchExportComponent,
    ChangeFiscalYearComponent,
    DenialsChartComponent,
    CptsDeniedModalComponent,
    ZipcodeMapBatchExportComponent,
    DenialsMetricDataTableComponent,
    SelectVisitTypeComponent,
    DenialsMultilevelComponent,
    DenialsDepartmentPageComponent,
    DenialsSpecialtyPageComponent,
    DenialsProviderPageComponent,
    ClinicalFingerprintTableComponent,
    SelectLocationComponent,
    DenialsMultilevelChartComponent,
    SearchPayerComponent,
    EmPageTrendComponent,
    EmTrendTableComponent,
    EmTrendChartComponent,
    CfpToggleVariablesComponent,
    EmBatchOutpatientMultilevelTableComponent,
    CfpToggleVariablesComponent,
    EmBatchEmergencyMultilevelTableComponent,
    EmSnapshotTableComponent,
    TabNavigationComponent,
    CfpMultilevelPageComponent,
    CfpMultilevelTableComponent,
    ProcedureSummaryFilterComponent,
    TestMigrationComponent,
    SearchCptFamilyComponent,
    NewPatientVisitsDataTableComponent,
    SearchCptRangeComponent,
    ProcedureSummaryComponent,
    RecursiveHierarchicalExportOptionComponent,
    NpvMlBatchExportPdfComponent,
    NpvMonthBatchPdfComponent,
    NpvSummaryBatchPdfComponent,
    NpvTrendTableBatchPdfComponent,
    SummaryProcedureSummaryComponent,
    ProcedureSummaryGraphComponent,
    VisitTypeComponent,
    UpdatedSaveGroupingComponent,
    UpdatedManageGroupsComponent,
    SelectPayerComponent,
    SearchLocationComponent,
    SearchLagKeyComponent,
    SearchBillingAreasComponent,
    ProviderLimitExceededComponent,
    InvoiceComponent,
    SelectPdfExportComponent,
    ProcedureSummaryGraphFilterSelectorComponent,
    SearchMultipleLocationsComponent,
    MultiSearchLocationComponent,
    AppConfigControlPanelComponent,
    FeatureToggleControlPanelComponent,
    ControlPanelComponent,
    ButtonCptGroupingsComponent,
    BatchDialogHierarchicalSelectorComponent,
    TrendEmBatchExportComponent,
    TrendEmBatchSummaryComponent,
    EmTrendCptRangeSelectComponent,
    EmTrendCptCodeSelectComponent,
    PageNewPatientVisitsLocationComponent,
    MultiProviderExportRedesignComponent,
    BatchExportBenchmarkSelectorComponent,
    LocationNpvTableComponent,
    NpvLocationChartComponent,
    NpvLocationGraphFilterSelectorComponent,
    ReportSchedulePaneComponent,
    DialogFooterComponent,
    MetricBenchmarkPaneComponent,
    MpeSummaryPaneComponent,
    MpeSummaryPaneRowComponent,
    NoAccessPageBatchExportComponent,
    OneTimeExportsTableComponent,
    MpeSuccessPageComponent,
    ScheduledExportsTableComponent,
    NpvLocationTableHeaderComponent,
    AdoTestingComponent,
    MpeHierarchicalSelectorComponent,
    NodeListContainerComponent,
    ExportedReportsRedesignDialogComponent,
    StyledTextContainerComponent,
    ZipCodeBubbleMapComponent,
    RedesignedOneTimeExportsTableComponent,
    PostMpeDownloadComponent
  ],
  imports: [
    FormsModule,
    NgReduxModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(Routing.routes, {}),
    OktaAuthModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatListModule,
    ReactiveFormsModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
    }),
    MatAutocompleteModule,
    MatBadgeModule,
    VizDateRangePickerModule,
    MatRadioModule,
    AppToolkitModule,
    AppMaterialModule,
    DragDropModule,
    ScrollingModule,
    ChartModule
  ],
  exports: [
    SafePipe
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'}
    },
    {provide: HIGHCHARTS_MODULES, useFactory: () => [highstock]},
    {
      provide: ProductivitySummaryServiceToken,
      useClass: HttpProductivitySummaryService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true,
    },
    {
      provide: ProviderProductivityServiceToken,
      useClass: HttpProviderProductivityService,
    },
    {
      provide: FilterListenerServiceToken,
      useClass: FilterListenerService,
    },
    {
      provide: DepartmentSpecialtyListenerServiceToken,
      useClass: DepartmentSpecialtyListenerService,
    },
    {
      provide: MemberListenerServiceToken,
      useClass: MemberListenerServiceImpl,
    },
    {
      provide: MemberServiceToken,
      useClass: HttpMemberService,
    },
    {
      provide: FilterSelectorServiceToken,
      useClass: ValidFilterSelectorService,
    },
    {
      provide: ProductivityMonthServiceToken,
      useClass: HttpProductivityMonthService,
    },
    {
      provide: EvaluationManagementServiceToken,
      useClass: HttpEvaluationManagementService,
    },
    {
      provide: NewPatientVisitServiceToken,
      useClass: HttpNewPatientVisitService,
    },
    {
      provide: MonthNewPatientVisitServiceToken,
      useClass: HttpMonthNewPatientVisitService,
    },
    {
      provide: LocationNewPatientVisitServiceToken,
      useClass: HttpLocationNewPatientVisitService
    },
    {
      provide: JwtExpirationServiceToken,
      useFactory: () => {
        return new JwtHelperService();
      },
    },
    {
      provide: AnalyticsServiceToken,
      useClass: AnalyticsService,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: CSVToken,
      useClass: AngularCSV,
    },
    {
      provide: OntologyHierarchyServiceToken,
      useClass: OntologyHierarchyServiceImpl,
    },
    {
      provide: UserProfileServiceToken,
      useClass: HttpUserProfileService,
    },
    {
      provide: FeatureToggleServiceToken,
      useClass: HttpFeatureToggleService,
    },
    {
      provide: MemberLocationServiceToken,
      useClass: MemberLocationServiceImpl,
    },
    {
      provide: PayerCategoryServiceToken,
      useClass: PayerCategoryServiceImpl,
    },
    {
      provide: ZipCodeNewPatientVisitServiceToken,
      useClass: HttpZipCodeNewPatientVisitService,
    },
    {
      provide: UserSecurityServiceToken,
      useClass: HttpUserSecurityService,
    },
    {
      provide: ApplicationConfigurationServiceToken,
      useClass: HttpApplicationConfigurationService,
    },
    {
      provide: LegalServiceToken,
      useClass: HttpLegalService,
    },
    {
      provide: ExcelServiceToken,
      useClass: ExcelService,
    },
    {
      provide: UserPreferenceServiceToken,
      useClass: HttpUserPreferenceService
    },
    {
      provide: ClinicalSummaryServiceToken,
      useClass: HttpClinicalSummaryService
    },
    {
      provide: CollectionServiceToken,
      useClass: HttpCollectionsService
    },
    {
      provide: DenialServiceToken,
      useClass: HttpDenialsService
    },
    {
      provide: CustomGroupsServiceToken,
      useClass: HttpCustomGroupsService
    },
    {
      provide: NewFeaturesServiceToken,
      useClass: HttpNewFeaturesService
    },
    {
      provide: MemberBillingAreaServiceToken,
      useClass: MemberBillingAreaServiceImpl
    },
    {
      provide: ComplianceServiceToken,
      useClass: HttpComplianceService
    },
    {
      provide: BatchExportServiceToken,
      useClass: HttpBatchExportService
    },
    {
      provide: ApiServiceToken,
      useClass: ApiServiceImpl
    },
    {
      provide: AlertsServiceToken,
      useClass: HttpAlertsService
    },
    {
      provide: ProcedureSummaryServiceToken,
      useClass: HttpProcedureSummaryService
    },
    {
      provide: UpdatedCustomGroupServiceToken,
      useClass: HttpUpdatedCustomGroupService
    }, {
      provide: SubproductServiceToken,
      useClass: SubproductServiceImpl
    },
    {provide: OKTA_CONFIG, useValue: {oktaAuth}},
    WindowWrapper,
    LoaderService,
    AgmHelperService,
    AgmHelperBatchExportService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(
    store: NgRedux<IAppState>,
    devTools: DevToolsExtension,
  ) {
    store.configureStore(
      rootReducer,
      INITIAL_STATE,
      [],
      devTools.isEnabled() ? [devTools.enhancer()] : [],
    );
    this.insertRumScript();
  }

  insertRumScript(): void {
    if (!!environment.rumId) {
      const rumScript = document.createElement('script');
      rumScript.type = 'text/javascript';

      rumScript.innerHTML = ` (function(){"undefined"!==typeof window&&window.setTimeout&&(window.setTimeout=window.setTimeout);this.dT_&&dT_.prm&&dT_.prm();(function(){function ra(){document.cookie="".concat("__dTCookie","=").concat("1",";SameSite=Lax");var Xa=-1!==document.cookie.indexOf("__dTCookie");document.cookie="".concat("__dTCookie","=").concat("1","; expires=Thu, 01-Jan-1970 00:00:01 GMT");return Xa}function Oa(){return void 0===gb.dialogArguments?navigator.cookieEnabled||ra():ra()}function hb(){var Xa;if(Oa()&&!window.dT_){var Ca=
      (Xa={},Xa.cfg="app=${environment.rumId}|cors=1|rcdec=1209600000|featureHash=A2Vfqru|vcv=2|reportUrl=https://bf23308xgg.bf.dynatrace.com/bf|rdnt=1|uxrgce=1|bp=3|srmcrv=10|cuc=9yja41he|mel=100000|dpvc=1|ssv=4|lastModification=1657222040212|dtVersion=10239220408103229|srmcrl=1|tp=500,50,0,1|uxdcw=1500|featureHash=A2Vfqru|agentUri=https://js-cdn.dynatrace.com/jstag/16ad5abbf0d/ruxitagent_A2Vfqru_10239220408103229.js|auto=|domain=|rid=RID_|rpid=|app=${environment.rumId}",Xa.iCE=Oa,Xa);window.dT_=Ca}}var gb="undefined"!==typeof window?window:self,Ma;gb.dT_?(null===(Ma=gb.console)||void 0===Ma?void 0:Ma.log("Duplicate agent injection detected, turning off redundant initConfig."),gb.dT_.di=1):hb()})()})();
      (function(){"undefined"!==typeof window&&window.setTimeout&&(window.setTimeout=window.setTimeout);this.dT_&&dT_.prm&&dT_.prm();(function(){function ra(l,w,N){void 0===N&&(N=0);var ta=-1;w&&(null===l||void 0===l?0:l.indexOf)&&(ta=l.indexOf(w,N));return ta}function Oa(){var l;return!(null===(l=Cb.console)||void 0===l||!l.log)}function hb(l,w){if(!w)return"";var N=l+"=";l=ra(w,N);if(0>l)return"";for(;0<=l;){if(0===l||" "===w.charAt(l-1)||";"===w.charAt(l-1))return N=l+N.length,l=ra(w,";",l),0<=l?w.substring(N,
      l):w.substr(N);l=ra(w,N,l+N.length)}return""}function gb(l){return hb(l,document.cookie)}function Ma(){}function Xa(){var l=0;try{l=Math.round(Cb.performance.timeOrigin)}catch(w){}if(0>=l||isNaN(l)||!isFinite(l)){l=0;try{l=Cb.performance.timing.navigationStart}catch(w){}l=0>=l||isNaN(l)||!isFinite(l)?ig:l}Nf=l;G=Ca;return Nf}function Ca(){return Nf}function wa(){return G()}function Ya(){var l,w=0;if(null===(l=null===Cb||void 0===Cb?void 0:Cb.performance)||void 0===l?0:l.now)try{w=Math.round(Cb.performance.now())}catch(N){}return 0>=
      w||isNaN(w)||!isFinite(w)?(new Date).getTime()-G():w}function Z(l,w){void 0===w&&(w=document.cookie);return hb(l,w)}function ba(){}function Ha(l,w){return function(){l.apply(w,arguments)}}function sa(l){if(!(this instanceof sa))throw new TypeError("Promises must be constructed via new");if("function"!==typeof l)throw new TypeError("not a function");this.ba=0;this.Ya=!1;this.ga=void 0;this.ja=[];ya(l,this)}function xa(l,w){for(;3===l.ba;)l=l.ga;0===l.ba?l.ja.push(w):(l.Ya=!0,sa.Aa(function(){var N=
      1===l.ba?w.hc:w.ic;if(null===N)(1===l.ba?Na:Sa)(w.promise,l.ga);else{try{var ta=N(l.ga)}catch(eb){Sa(w.promise,eb);return}Na(w.promise,ta)}}))}function Na(l,w){try{if(w===l)throw new TypeError("A promise cannot be resolved with itself.");if(w&&("object"===typeof w||"function"===typeof w)){var N=w.then;if(w instanceof sa){l.ba=3;l.ga=w;ja(l);return}if("function"===typeof N){ya(Ha(N,w),l);return}}l.ba=1;l.ga=w;ja(l)}catch(ta){Sa(l,ta)}}function Sa(l,w){l.ba=2;l.ga=w;ja(l)}function ja(l){2===l.ba&&0===
      l.ja.length&&sa.Aa(function(){l.Ya||sa.bb(l.ga)});for(var w=0,N=l.ja.length;w<N;w++)xa(l,l.ja[w]);l.ja=null}function S(l,w,N){this.hc="function"===typeof l?l:null;this.ic="function"===typeof w?w:null;this.promise=N}function ya(l,w){var N=!1;try{l(function(ta){N||(N=!0,Na(w,ta))},function(ta){N||(N=!0,Sa(w,ta))})}catch(ta){N||(N=!0,Sa(w,ta))}}function pa(){H.Aa=function(l){if("string"===typeof l)throw Error("Promise polyfill called _immediateFn with string");l()};H.bb=function(){};return H}function W(l,
      w){return parseInt(l,w||10)}function db(l){return document.getElementsByTagName(l)}function K(l){var w=l.length;if("number"===typeof w)l=w;else{w=0;for(var N=2048;l[N-1];)w=N,N+=N;for(var ta=7;1<N-w;)ta=(N+w)/2,l[ta-1]?w=ta:N=ta;l=l[ta]?N:w}return l}function I(l){for(var w=[],N=1;N<arguments.length;N++)w[N-1]=arguments[N];l.push.apply(l,w)}function F(l){l=encodeURIComponent(l);var w=[];if(l)for(var N=0;N<l.length;N++){var ta=l.charAt(N);I(w,Da[ta]||ta)}return w.join("")}function Q(l){-1<ra(l,"^")&&
      (l=l.split("^^").join("^"),l=l.split("^dq").join('"'),l=l.split("^rb").join(">"),l=l.split("^lb").join("<"),l=l.split("^p").join("|"),l=l.split("^e").join("="),l=l.split("^s").join(";"),l=l.split("^c").join(","),l=l.split("^bs").join("\\\\"));return l}function ea(l,w){if(!l||!l.length)return-1;if(l.indexOf)return l.indexOf(w);for(var N=l.length;N--;)if(l[N]===w)return N;return-1}function T(l,w){var N;void 0===w&&(w=[]);if(!l||"object"!==typeof l&&"function"!==typeof l)return!1;var ta="number"!==typeof w?
      w:[],eb=null,vb=[];switch("number"===typeof w?w:5){case 0:eb="Array";vb.push("push");break;case 1:eb="Boolean";break;case 2:eb="Number";break;case 3:eb="String";break;case 4:eb="Function";break;case 5:eb="Object";break;case 6:eb="Date";vb.push("getTime");break;case 7:eb="Error";vb.push("name","message");break;case 8:eb="Element";break;case 9:eb="HTMLElement";break;case 10:eb="HTMLImageElement";vb.push("complete");break;case 11:eb="PerformanceEntry";break;case 12:eb="PerformanceTiming";break;case 13:eb=
      "PerformanceResourceTiming";break;case 14:eb="PerformanceNavigationTiming";break;case 15:eb="CSSRule";vb.push("cssText","parentStyleSheet");break;case 16:eb="CSSStyleSheet";vb.push("cssRules","insertRule");break;case 17:eb="Request";vb.push("url");break;case 18:eb="Response";vb.push("ok","status","statusText");break;case 19:eb="Set";vb.push("add","entries","forEach");break;case 20:eb="Map";vb.push("set","entries","forEach");break;case 21:eb="Worker";vb.push("addEventListener","postMessage","terminate");
      break;case 22:eb="XMLHttpRequest";vb.push("open","send","setRequestHeader");break;case 23:eb="SVGScriptElement";vb.push("ownerSVGElement","type");break;case 24:eb="HTMLMetaElement";vb.push("httpEquiv","content","name");break;case 25:eb="HTMLHeadElement";break;case 26:eb="ArrayBuffer";break;case 27:eb="ShadowRoot",vb.push("host","mode")}w=eb;if(!w)return!1;vb=vb.length?vb:ta;if(!ta.length)try{if(Cb[w]&&l instanceof Cb[w]||Object.prototype.toString.call(l)==="[object "+w+"]")return!0;if(l&&l.nodeType&&
      1===l.nodeType){var wc=null===(N=l.ownerDocument.defaultView)||void 0===N?void 0:N[w];if("function"===typeof wc&&l instanceof wc)return!0}}catch(Gc){}for(N=0;N<vb.length;N++)if(ta=vb[N],"string"!==typeof ta&&"number"!==typeof ta&&"symbol"!==typeof ta||!(ta in l))return!1;return!!vb.length}function ia(l,w,N,ta){"undefined"===typeof ta&&(ta=Ra(w,!0));"boolean"===typeof ta&&(ta=Ra(w,ta));if(l===Cb)Wa?Wa(w,N,ta):Jb&&Jb("on"+w,N);else if(cc&&T(l,21))Hc.call(l,w,N,ta);else if(l.addEventListener)if(l===
      Cb.document||l===Cb.document.documentElement)Nb.call(l,w,N,ta);else try{Wa.call(l,w,N,ta)}catch(wc){l.addEventListener(w,N,ta)}else l.attachEvent&&l.attachEvent("on"+w,N);ta=!1;for(var eb=$c.length;0<=--eb;){var vb=$c[eb];if(vb.object===l&&vb.event===w&&vb.da===N){ta=!0;break}}ta||I($c,{object:l,event:w,da:N})}function va(l,w,N,ta){for(var eb=$c.length;0<=--eb;){var vb=$c[eb];if(vb.object===l&&vb.event===w&&vb.da===N){$c.splice(eb,1);break}}"undefined"===typeof ta&&(ta=Ra(w,!0));"boolean"===typeof ta&&
      (ta=Ra(w,ta));l===Cb?pb?pb(w,N,ta):Jb&&Jb("on"+w,N):l.removeEventListener?l===Cb.document||l===Cb.document.documentElement?Tb.call(l,w,N,ta):pb.call(l,w,N,ta):l.detachEvent&&l.detachEvent("on"+w,N)}function Ra(l,w){var N=!1;try{if(Wa&&-1<ea(Wd,l)){var ta=Object.defineProperty({},"passive",{get:function(){N=!0}});Wa("test",Ma,ta)}}catch(eb){}return N?{passive:!0,capture:w}:w}function Ib(){for(var l=$c,w=l.length;0<=--w;){var N=l[w];va(N.object,N.event,N.da)}$c=[]}function kb(l,w,N){if(N||2===arguments.length)for(var ta=
      0,eb=w.length,vb;ta<eb;ta++)!vb&&ta in w||(vb||(vb=Array.prototype.slice.call(w,0,ta)),vb[ta]=w[ta]);return l.concat(vb||Array.prototype.slice.call(w))}function Y(l){return"function"===typeof l&&/{\\s+\\[native code]/.test(Function.prototype.toString.call(l))}function oa(l,w){for(var N,ta=[],eb=2;eb<arguments.length;eb++)ta[eb-2]=arguments[eb];return void 0!==Function.prototype.bind&&Y(Function.prototype.bind)?(N=Function.prototype.bind).call.apply(N,kb([l,w],ta,!1)):function(){for(var vb=0;vb<arguments.length;vb++);
      return l.apply(w,(ta||[]).concat(Array.prototype.slice.call(arguments)||[]))}}function P(){if(Ed){var l=new Ed;if(Ke)for(var w=0,N=Zd;w<N.length;w++){var ta=N[w];void 0!==Ke[ta]&&(l[ta]=oa(Ke[ta],l))}return l}return fd?new fd("MSXML2.XMLHTTP.3.0"):Cb.XMLHttpRequest?new Cb.XMLHttpRequest:new Cb.ActiveXObject("MSXML2.XMLHTTP.3.0")}function ma(){document.cookie="".concat("__dTCookie","=").concat("1",";SameSite=Lax");var l=-1!==document.cookie.indexOf("__dTCookie");document.cookie="".concat("__dTCookie",
      "=").concat("1","; expires=Thu, 01-Jan-1970 00:00:01 GMT");return l}function Aa(){return void 0===Cb.dialogArguments?navigator.cookieEnabled||ma():ma()}function ob(){return ze}function qb(l){ze=l}function jb(l){var w=M("rid"),N=M("rpid");w&&(l.rid=w);N&&(l.rpid=N)}function Bb(l){if(l=l.xb){l=Q(l);try{ze=new RegExp(l,"i")}catch(w){}}else ze=void 0}function nc(l){return"n"===l||"s"===l||"l"===l?";SameSite=".concat(gh[l]):""}function Pb(l,w,N){var ta=1,eb=0;do document.cookie=l+'=""'+(w?";domain="+w:
      "")+";path="+N.substr(0,ta)+"; expires=Thu, 01 Jan 1970 00:00:01 GMT;",ta=N.indexOf("/",ta),eb++;while(-1!==ta&&5>eb)}function Ga(l){var w={},N=0;for(l=l.split("|");N<l.length;N++){var ta=l[N].split("=");2===ta.length&&(w[ta[0]]=decodeURIComponent(ta[1].replace(/\\+/g," ")))}return w}function zb(){var l=M("csu");return(l.indexOf("dbg")===l.length-3?l.substr(0,l.length-3):l)+"_"+M("app")+"_Store"}function Ab(l,w,N){void 0===w&&(w={});var ta=0;for(l=l.split("|");ta<l.length;ta++){var eb=l[ta],vb=eb,
      wc=ra(eb,"=");-1===wc?w[vb]="1":(vb=eb.substring(0,wc),w[vb]=eb.substring(wc+1,eb.length))}!N&&(N=w,ta=N.spc)&&(l=document.createElement("textarea"),l.innerHTML=ta,N.spc=l.value);return w}function aa(l){var w;return null!==(w=qc[l])&&void 0!==w?w:Cd[l]}function La(l){l=aa(l);return"false"===l||"0"===l?!1:!!l}function ab(l){var w=aa(l);w=W(w);isNaN(w)&&(w=Cd[l]);return w}function M(l){return String(aa(l)||"")}function fa(l,w){qc[l]=String(w)}function Ea(l){return qc=l}function cb(l){qc[l]=0>ra(qc[l],
      "#"+l.toUpperCase())?qc[l]:""}function Hb(l){var w=l.agentUri;w&&-1<ra(w,"_")&&(w=/([a-zA-Z]*)[0-9]{0,4}_([a-zA-Z_0-9]*)_[0-9]+/g.exec(w))&&w.length&&2<w.length&&(l.csu=w[1],l.featureHash=w[2])}function Ic(l){var w=l.domain||"";var N=(N=location.hostname)&&w?N===w||-1!==N.indexOf("."+w,N.length-("."+w).length):!0;if(!w||!N){l.domainOverride||(l.domainOriginal=l.domain||"",l.domainOverride="".concat(location.hostname,",").concat(w),delete l.domain);var ta=M("cssm");var eb=document.domain||"";if(eb){eb=
      eb.split(".").reverse();var vb=eb.length;if(1>=vb)ta="";else{for(var wc=eb[0],Gc="",Uc=1;Uc<=vb;Uc++)if(Z("dTValidationCookie")){Gc=wc;break}else{eb[Uc]&&(wc="".concat(eb[Uc],".").concat(wc));var Cc="".concat("dTValidationCookie","=dTValidationCookieValue;path=/;domain=").concat(wc);Cc+=nc(ta);document.cookie=Cc}Pb("dTValidationCookie",Gc,"/");ta=Gc}}else ta="";ta&&(l.domain=ta);N||I(Le,{type:"dpi",severity:"Warning",text:'Configured domain "'.concat(w,'" is invalid for current location "').concat(location.hostname,
      '". Agent will use "').concat(l.domain,'" instead.')})}}function Mb(l,w){Ic(l);var N=qc.pVO;N&&(l.pVO=N);w||(w=l.bp||Cd.bp,l.bp2&&(w=2),l.bp=String(w))}function Zb(){return qc}function q(l){return Cd[l]===aa(l)}function C(){if(Cb.MobileAgent||Cb.dynatraceMobile){var l=gb("dtAdkSettings");return jg.dT_.p3SC(l).privacyState||null}return null}function t(){var l=C();return 2===l||1===l?!1:!jg.dT_.bcv("coo")||jg.dT_.bcv("cooO")||jg.dT_.iSM()}function x(l,w){return!t()||Cb.dT_.overloadPrevention&&!pc()?
      null:l.apply(this,w||[])}function X(l,w){try{var N=nd;N&&N.setItem(l,w)}catch(ta){}}function y(l,w){x(X,[l,w])}function la(l){try{var w=nd;if(w)return w.getItem(l)}catch(N){}return null}function J(l){try{var w=nd;w&&w.removeItem(l)}catch(N){}}function O(l){document.cookie=l+'="";path=/'+(M("domain")?";domain="+M("domain"):"")+"; expires=Thu, 01 Jan 1970 00:00:01 GMT;"}function ua(l,w,N,ta){Ue=!0;w||0===w?(w=String(w).replace(/[;\\n\\r]/g,"_"),l=l+"="+w+";path=/"+(M("domain")?";domain="+M("domain"):
      ""),N&&(l+=";expires="+N.toUTCString()),l+=nc(M("cssm")),ta&&"https:"===location.protocol&&(l+=";Secure"),document.cookie=l):O(l);Ue=!1}function Ia(l,w,N,ta){x(ua,[l,w,N,ta])}function Ta(l){return-1===ra(l,"v_4")?!1:!0}function Db(l){l=Z("dtCookie",l);l||((l=la("dtCookie"))&&Ta(l)?Lb(l):l="");return Ta(l)?l:""}function Lb(l){Ia("dtCookie",l,void 0,La("ssc"))}function Kb(l){return(l=l||Db())?Vc(l):{sessionId:"",serverId:"",overloadState:0}}function bc(l){return Kb(l).serverId}function vc(l){return Kb(l).sessionId}
      function pc(){return 0<=ra(navigator.userAgent,"RuxitSynthetic")}function Vc(l){var w={sessionId:"",serverId:"",overloadState:0},N=l.split("_");if(2<N.length&&0===N.length%2){l=Number(N[1]);if(isNaN(l)||3>l)return w;l={};for(var ta=2;ta<N.length;ta++)l[N[ta]]=N[ta+1],ta++;l.sn?(N=l.sn,N=N.length===Fd||12>=N.length?N:""):N="hybrid";w.sessionId=N;if(l.srv){a:{N=l.srv.replace("-2D","-");if(!isNaN(Number(N))&&(ta=W(N),-99<=ta&&99>=ta))break a;N=""}w.serverId=N}N=Number(l.ol);if(1===N){ta=pc();var eb=
      Cb.dT_;ta||(y("dtDisabled","true"),eb.disabled=!0,eb.overloadPrevention=!0)}0<=N&&2>=N&&(w.overloadState=N);l=Number(l.prv);isNaN(l)||(w.privacyState=1>l||4<l?1:l)}return w}function Dc(){return Gd()}function id(l,w){function N(){delete Of[vb];l.apply(this,arguments)}for(var ta=[],eb=2;eb<arguments.length;eb++)ta[eb-2]=arguments[eb];if("apply"in se){ta.unshift(N,w);var vb=se.apply(Cb,ta)}else vb=se(N,w);Of[vb]=!0;return vb}function ha(l){delete Of[l];"apply"in je?je.call(Cb,l):je(l)}function Ba(l){I(ne,
      l)}function Qa(l){for(var w=ne.length;w--;)if(ne[w]===l){ne.splice(w,1);break}}function Ua(){return ne}function tb(l,w){return Hg(l,w)}function Wb(l){Ae(l)}function Pa(l,w){if(!He||!Jd)return"";l=new He([l],{type:w});return Jd(l)}function rc(l,w){return Me?new Me(l,w):void 0}function uc(l){"function"===typeof l&&I(yh,l)}function Jc(){return yh}function Kc(){return ig}function yd(l){return function(){for(var w=[],N=0;N<arguments.length;N++)w[N]=arguments[N];if("number"!==typeof w[0]||!Of[w[0]])try{return l.apply(this,
      w)}catch(ta){return l(w[0])}}}function Wc(){return Le}function Yb(){G=Xa;Cb.performance&&(Gd=function(){return Math.round(G()+Ya())});if(!Gd||isNaN(Gd())||0>=Gd()||!isFinite(Gd()))Gd=function(){return(new Date).getTime()}}function wd(){Od&&(Cb.clearTimeout=je,Cb.clearInterval=Ae,Od=!1)}function oc(l,w){try{Cb.localStorage&&Cb.localStorage.setItem(l,w)}catch(N){}}function yc(l){try{Cb.localStorage&&Cb.localStorage.removeItem(l)}catch(w){}}function Sc(){yc("rxec");yc("rxvisitid");yc("rxvt")}function od(l){t()?
      l():(ug||(ug=[]),I(ug,l))}function xc(l){return x(l)}function Ac(){if(La("coo")&&!t()){for(var l=0,w=ug;l<w.length;l++)id(w[l],0);ug=[];fa("cooO",!0)}}function Qc(){if(La("coo")&&t()){fa("cooO",!1);O("dtCookie");O("dtPC");O("dtLatC");O("dtSa");O("dtAdk");O("rxVisitor");O("rxvt");try{J("rxec");J("rxvisitid");J("rxvt");Sc();var l=nd;l&&(l.removeItem("rxVisitor"),l.removeItem("dtCookie"));if(l=mf)l.removeItem(zb()),l.removeItem("dtAdk")}catch(w){}}}function Ub(l,w){void 0===w&&(w=document.cookie||"");
      return w.split(l+"=").length-1}function Vb(l,w){var N=Ub(l,w);if(1<N){w=M("domain")||Cb.location.hostname;var ta=Cb.location.hostname,eb=Cb.location.pathname,vb=0,wc=0;te.push(l);do{var Gc=ta.substr(vb);if(Gc!==w||"/"!==eb){Pb(l,Gc===w?"":Gc,eb);var Uc=Ub(l);Uc<N&&(te.push(Gc),N=Uc)}vb=ta.indexOf(".",vb)+1;wc++}while(0!==vb&&10>wc&&1<N);M("domain")&&1<N&&Pb(l,"",eb)}}function jd(){var l=document.cookie;Vb("dtPC",l);Vb("dtCookie",l);Vb("dtLatC",l);Vb("rxvt",l);0<te.length&&I(Le,{severity:"Error",type:"dcn",
      text:"Duplicate cookie name".concat(1!==te.length?"s":""," detected: ").concat(te.join(", "))});Ba(function(w,N,ta,eb){0<te.length&&!N&&(w.av(eb,"dCN",te.join(",")),te=[]);0<vg.length&&!N&&(w.av(eb,"eCC",vg.join(",")),vg=[])})}function gd(l){var w=l,N=Math.pow(2,32);return function(){w=(1664525*w+1013904223)%N;return w/N}}function Oe(l,w){return isNaN(l)||isNaN(w)?Math.floor(33*Ig()):Math.floor(Ig()*(w-l+1))+l}function Kd(l){if(!l)return"";var w=Cb.crypto||Cb.msCrypto;if(w&&-1===ra(navigator.userAgent,
      "Googlebot"))w=w.getRandomValues(new Uint8Array(l));else{w=[];for(var N=0;N<l;N++)w.push(Oe(0,32))}l=[];for(N=0;N<w.length;N++){var ta=Math.abs(w[N]%32);l.push(String.fromCharCode(ta+(9>=ta?48:55)))}return l.join("")}function cd(){return Jg}function Oc(l){l&&(null===l||void 0===l?0:l.configurable)&&l.set&&l.get&&Object.defineProperty(document,"cookie",{get:function(){return l.get.call(document)},set:function(w){var N=w.split("=")[0];l.set.call(document,w);Ue?1<Ub(N)&&te.push(N):-1<ea(gi,N)&&(vg.push(N),
      -1===ea(zh,N)&&(I(zh,N),I(Le,{severity:"Error",type:"ecm",text:"Invalid modification of agent cookie ".concat(N," detected. Modifying Dynatrace cookies may result in missing or invalid data.")})))}})}function pe(l){void 0===l&&(l=!0);oe=l}function Xc(l,w,N){var ta=ab("pcl");ta=l.length-ta;0<ta&&l.splice(0,ta);ta=bc(Z("dtCookie",N));for(var eb=[],vb=ta?"".concat(ta,"$"):"",wc=0;wc<l.length;wc++){var Gc=l[wc];"-"!==Gc.G&&eb.push("".concat(vb).concat(Gc.frameId,"h").concat(Gc.G))}l=eb.join("p");l||(oe&&
      (B(!0,"a",N),pe(!1)),l+="".concat(ta,"$").concat(Jg,"h-"));l+="v".concat(w||Pe(N));Ia("dtPC",l+"e0",void 0,La("ssc"))}function Ld(l,w){void 0===w&&(w=document.cookie);var N=Z("dtPC",w);w=[];if(N&&"-"!==N){var ta="";var eb=0;for(N=N.split("p");eb<N.length;eb++){var vb=N[eb],wc=ta,Gc=l;void 0===wc&&(wc="");ta=ra(vb,"$");var Uc=ra(vb,"h");var Cc=ra(vb,"v"),nf=ra(vb,"e");ta=vb.substring(ta+1,Uc);Uc=-1!==Cc?vb.substring(Uc+1,Cc):vb.substring(Uc+1);wc||-1===Cc||(wc=-1!==nf?vb.substring(Cc+1,nf):vb.substring(Cc+
      1));vb=null;Gc||(Gc=W(ta.split("_")[0]),Cc=Gd()%wg,Cc<Gc&&(Cc+=wg),Gc=Gc+9E5>Cc);Gc&&(vb={frameId:ta,G:"-"===Uc?"-":W(Uc),visitId:""});ta=wc;(Uc=vb)&&w.push(Uc)}for(l=0;l<w.length;l++)w[l].visitId=ta}return w}function ee(l,w){var N=document.cookie;w=Ld(w,N);for(var ta=!1,eb=0;eb<w.length;eb++){var vb=w[eb];vb.frameId===Jg&&(vb.G=l,ta=!0)}ta||I(w,{frameId:Jg,G:l,visitId:""});Xc(w,void 0,N)}function Pe(l){return Be(l)||B(!0,"c",l)}function Be(l){if($b(l)<=Gd())return B(!0,"t",l);var w=ue(l);if(!w)return B(!0,
      "c",l);var N=gc.exec(w);if(!N||3!==N.length||32!==N[1].length||isNaN(W(N[2])))return B(!0,"i",l);y("rxvisitid",w);return w}function Ve(l,w){var N=Gd();w=Gb(w).Jc;l&&(w=N);Za(N+zf+"|"+w);ka()}function dd(l){var w="t"+(Gd()-$b(l)),N=ue(l),ta=Ce();zd(ta,l);L(ta,w,N)}function ue(l){var w,N;return null!==(N=null===(w=Ld(!0,l)[0])||void 0===w?void 0:w.visitId)&&void 0!==N?N:la("rxvisitid")}function Ce(){var l=Kd(Fd);l=l.replace(/[0-9]/g,function(w){w=.1*W(w);return String.fromCharCode(Math.floor(25*w+65))});
      return l+"-0"}function zd(l,w){var N=Ld(!1,w);Xc(N,l,w);y("rxvisitid",l);Ve(!0)}function u(l,w,N){return B(l,w,N)}function B(l,w,N){l&&(sd=!0);l=ue(N);N=Ce();zd(N);L(N,w,l);return N}function L(l,w,N){if(ue(document.cookie))for(var ta=0,eb=Pf;ta<eb.length;ta++)(0,eb[ta])(l,sd,w,N)}function da(l){Pf.push(l)}function ka(l){rf&&ha(rf);rf=id(Ka,$b(l)-Gd())}function Ka(){var l=document.cookie;if($b(l)<=Gd())return x(dd,[l]),!0;od(ka);return!1}function Za(l){Ia("rxvt",l,void 0,La("ssc"));y("rxvt",l)}function $a(l,
      w){(w=Z(l,w))||(w=la(l)||"");return w}function Eb(){var l=Be()||"";y("rxvisitid",l);l=$a("rxvt");Za(l);Sc()}function Gb(l){var w={Bd:0,Jc:0};if(l=$a("rxvt",l))try{var N=l.split("|");2===N.length&&(w.Bd=parseInt(N[0],10),w.Jc=parseInt(N[1],10))}catch(ta){}return w}function $b(l){l=Gb(l);return Math.min(l.Bd,l.Jc+Yc)}function zc(l){zf=l}function Ad(){var l=sd;sd=!1;return l}function Bd(){Ka()||Ve(!1)}function ad(l){try{if(Cb.localStorage)return Cb.localStorage.getItem(l)}catch(w){}return null}function Md(){var l=
      Z("rxVisitor");l&&45===(null===l||void 0===l?void 0:l.length)||(l=ad("rxVisitor")||la("rxVisitor"),45!==(null===l||void 0===l?void 0:l.length)&&(df=!0,l=String(Gd()),l+=Kd(45-l.length)));we(l);return l}function we(l){if(La("dpvc")||La("pVO"))y("rxVisitor",l);else{var w=new Date;var N=w.getMonth()+Math.min(24,Math.max(1,ab("rvcl")));w.setMonth(N);x(oc,["rxVisitor",l])}Ia("rxVisitor",l,w,La("ssc"))}function We(){return df}function pd(l){var w=Z("rxVisitor");O("rxVisitor");J("rxVisitor");yc("rxVisitor");
      fa("pVO",!0);we(w);l&&x(oc,["dt-pVO","1"]);Eb()}function kg(){yc("dt-pVO");La("pVO")&&(fa("pVO",!1),Md());J("rxVisitor");Eb()}function sf(l,w,N,ta,eb){var vb=document.createElement("script");vb.setAttribute("src",l);w&&vb.setAttribute("defer","defer");N&&(vb.onload=N);ta&&(vb.onerror=ta);eb&&vb.setAttribute("id",eb);vb.setAttribute("crossorigin","anonymous");l=document.getElementsByTagName("script")[0];l.parentElement.insertBefore(vb,l)}function ef(l,w){return Rd+"/"+(w||tf)+"_"+l+"_"+(ab("buildNumber")||
      Cb.dT_.version)+".js"}function Qf(){var l,w;try{null===(w=null===(l=Cb.MobileAgent)||void 0===l?void 0:l.incrementActionCount)||void 0===w?void 0:w.call(l)}catch(N){}}function Kg(){var l,w=Cb.dT_;Cb.dT_=(l={},l.di=0,l.version="10239220408103229",l.cfg=w?w.cfg:"",l.iCE=w?Aa:function(){return navigator.cookieEnabled},l.ica=1,l.disabled=!1,l.overloadPrevention=!1,l.gAST=Kc,l.ww=rc,l.stu=Pa,l.nw=Dc,l.apush=I,l.st=id,l.si=tb,l.aBPSL=Ba,l.rBPSL=Qa,l.gBPSL=Ua,l.aBPSCC=uc,l.gBPSCC=Jc,l.buildType="dynatrace",l.gSSV=
      la,l.sSSV=y,l.rSSV=J,l.rvl=yc,l.pn=W,l.iVSC=Ta,l.p3SC=Vc,l.io=ra,l.dC=O,l.sC=Ia,l.esc=F,l.gSId=bc,l.gDtc=vc,l.gSC=Db,l.sSC=Lb,l.gC=gb,l.cRN=Oe,l.cRS=Kd,l.gEL=K,l.gEBTN=db,l.cfgO=Zb,l.pCfg=Ga,l.pCSAA=Ab,l.cFHFAU=Hb,l.sCD=Mb,l.bcv=La,l.ncv=ab,l.scv=M,l.stcv=fa,l.rplC=Ea,l.cLSCK=zb,l.gFId=cd,l.gBAU=ef,l.iS=sf,l.eWE=od,l.oEIE=xc,l.oEIEWA=x,l.eA=Ac,l.dA=Qc,l.iNV=We,l.gVID=Md,l.dPV=pd,l.ePV=kg,l.sVIdUP=pe,l.sVTT=zc,l.sVID=zd,l.rVID=Be,l.gVI=Pe,l.gNVIdN=u,l.gARnVF=Ad,l.cAUV=Bd,l.uVT=Ve,l.aNVL=da,l.gPC=Ld,
      l.cPC=ee,l.sPC=Xc,l.clB=wd,l.ct=ha,l.aRI=jb,l.iXB=Bb,l.gXBR=ob,l.sXBR=qb,l.de=Q,l.cCL=Oa,l.iEC=Qf,l.rnw=Ya,l.gto=wa,l.ael=ia,l.rel=va,l.sup=Ra,l.cuel=Ib,l.iAEPOO=t,l.iSM=pc,l.aIOf=ea,l.gxwp=P,l.iIO=T,l.prm=pa,l.cI=Wb,l.gidi=Wc,l.iDCV=q,l.gCF=Z,l.gPSMB=C,l.lvl=ad,l)}function ff(){od(function(){if(!vc()){var l=-1*Oe(2,99),w=Kd(Fd);Lb("v_4_srv_".concat(String(l).replace("-","-2D"),"_sn_").concat(w))}})}var jg="undefined"!==typeof window?window:self,Cb="undefined"!==typeof window?window:self,Nf,G,A=setTimeout;
      sa.prototype["catch"]=function(l){return this.then(null,l)};sa.prototype.then=function(l,w){var N=new this.constructor(ba);xa(this,new S(l,w,N));return N};sa.prototype["finally"]=function(l){var w=this.constructor;return this.then(function(N){return w.resolve(l()).then(function(){return N})},function(N){return w.resolve(l()).then(function(){return w.reject(N)})})};sa.all=function(l){return new sa(function(w,N){function ta(Gc,Uc){try{if(Uc&&("object"===typeof Uc||"function"===typeof Uc)){var Cc=Uc.then;
      if("function"===typeof Cc){Cc.call(Uc,function(nf){ta(Gc,nf)},N);return}}eb[Gc]=Uc;0===--vb&&w(eb)}catch(nf){N(nf)}}if(!l||"undefined"===typeof l.length)return N(new TypeError("Promise.all accepts an array"));var eb=Array.prototype.slice.call(l);if(0===eb.length)return w([]);for(var vb=eb.length,wc=0;wc<eb.length;wc++)ta(wc,eb[wc])})};sa.allSettled=function(l){return new this(function(w,N){function ta(wc,Gc){if(Gc&&("object"===typeof Gc||"function"===typeof Gc)){var Uc=Gc.then;if("function"===typeof Uc){Uc.call(Gc,
      function(Cc){ta(wc,Cc)},function(Cc){eb[wc]={status:"rejected",reason:Cc};0===--vb&&w(eb)});return}}eb[wc]={status:"fulfilled",value:Gc};0===--vb&&w(eb)}if(!l||"undefined"===typeof l.length)return N(new TypeError(typeof l+" "+l+" is not iterable(cannot read property Symbol(Symbol.iterator))"));var eb=Array.prototype.slice.call(l);if(0===eb.length)return w([]);var vb=eb.length;for(N=0;N<eb.length;N++)ta(N,eb[N])})};sa.resolve=function(l){return l&&"object"===typeof l&&l.constructor===sa?l:new sa(function(w){w(l)})};
      sa.reject=function(l){return new sa(function(w,N){N(l)})};sa.race=function(l){return new sa(function(w,N){if(!l||"undefined"===typeof l.length)return N(new TypeError("Promise.race accepts an array"));for(var ta=0,eb=l.length;ta<eb;ta++)sa.resolve(l[ta]).then(w,N)})};sa.Aa="function"===typeof setImmediate&&function(l){setImmediate(l)}||function(l){A(l,0)};sa.bb=function(l){"undefined"!==typeof console&&console&&console.warn("Possible Unhandled Promise Rejection:",l)};var H=sa,Da={"!":"%21","~":"%7E",
      "*":"%2A","(":"%28",")":"%29","'":"%27",$:"%24",";":"%3B",",":"%2C"},Wa,pb,Nb,Tb,Jb=Cb.attachEvent,cc=Cb.Worker,Hc=cc&&cc.prototype.addEventListener,$c=[],Wd=["touchstart","touchend","scroll"],Ed,fd,Zd="abort getAllResponseHeaders getResponseHeader open overrideMimeType send setRequestHeader".split(" "),Ke,ze,Cd,Xe,gh=(Xe={},Xe.l="Lax",Xe.s="Strict",Xe.n="None",Xe),qc={},nd,Ue=!1,Fd=32,Me=Cb.Worker,He=Cb.Blob,Jd=Cb.URL&&Cb.URL.createObjectURL,je,Ae,se,Hg,Od=!1,ne,yh=[],Le=[],ig,mf,Of={},Gd,ug=[],
      te=[],vg=[],Ig,Ph,Jg,wg=6E8,gi=[],zh=[],oe=!1,gc=/([A-Z]+)-([0-9]+)/,Pf=[],zf,Yc,sd=!1,rf,df=!1,xg,Rd,tf;(function(l){var w,N;l=l||0>(null===(w=navigator.userAgent)||void 0===w?void 0:w.indexOf("RuxitSynthetic"));if(!Cb.dT_||!Cb.dT_.cfg||"string"!==typeof Cb.dT_.cfg||"initialized"in Cb.dT_&&Cb.dT_.initialized)null===(N=Cb.console)||void 0===N?void 0:N.log("InitConfig not found or agent already initialized! This is an injection issue."),Cb.dT_&&(Cb.dT_.di=3);else if(l)try{var ta;pa();var eb;Ed=Cb.XMLHttpRequest;
      fd=Cb.ActiveXObject;var vb=null===(eb=Cb.XMLHttpRequest)||void 0===eb?void 0:eb.prototype;if(vb)for(Ke={},w=0,N=Zd;w<N.length;w++){var wc=N[w];void 0!==vb[wc]&&(Ke[wc]=vb[wc])}Wa=Cb.addEventListener;pb=Cb.removeEventListener;Nb=Cb.document.addEventListener;Tb=Cb.document.removeEventListener;se=Cb.setTimeout;Hg=Cb.setInterval;Od||(je=Cb.clearTimeout,Ae=Cb.clearInterval);var Gc=Aa?Aa():navigator.cookieEnabled,Uc=1===Vc(Z("dtAdkSettings")||(null===(ta=mf)||void 0===ta?void 0:ta.getItem("dtAdkSettings"))||
      "").jc;Oa();if(!(!Gc||Uc?0:"complete"!==document.readyState||Cb.performance&&Cb.performance.timing))throw Error("Error during initCode initialization");Kg();try{mf=Cb.localStorage}catch(ke){}Yb();ig=Gd();ne=[];Le=[];Of={};if(!Od){Cb.clearTimeout=yd(je);Cb.clearInterval=yd(Ae);Od=!0;try{nd=Cb.sessionStorage}catch(ke){}}var Cc=Math.random(),nf=Math.random();Ph=0!==Cc&&0!==nf&&Cc!==nf;if(-1!==ra(navigator.userAgent,"Googlebot")){var De=performance.getEntriesByType("navigation")[0];ta=1;if(De){for(var Af in De){var bg=
      De[Af];"number"===typeof bg&&bg&&(ta=1===ta?bg:ta+bg)}var Bf=Math.floor(1E4*ta)}else Bf=ta;Ig=gd(Bf)}else Ph?Ig=Math.random:Ig=gd(Gd());Jg=ig%wg+"_"+W(Oe(0,1E3)+"");var za;Cd=(za={},za.ade="",za.aew=!0,za.apn="",za.agentLocation="",za.agentUri="",za.app="",za.async=!1,za.ase=!1,za.auto=!1,za.bp1=!1,za.bp2=!1,za.bp=1,za.bisaoi=!1,za.bisCmE="",za.bs=!1,za.buildNumber=0,za.csprv=!0,za.cepl=16E3,za.cls=!0,za.ccNcss=!1,za.cg=!1,za.coo=!1,za.cooO=!1,za.cssm="0",za.cwt="",za.cwtUrl="27pd8x1igg",za.cors=
      !1,za.csu="",za.cuc="",za.cce=!1,za.cux=!1,za.dataDtConfig="",za.debugName="",za.dvl=500,za.dASXH=!1,za.disableCookieManager=!1,za.disableLogging=!1,za.dmo=!1,za.doel=!1,za.dpch=!1,za.dpvc=!1,za.disableXhrFailures=!1,za.domain="",za.domainOverride="",za.domainOriginal="",za.doNotDetect="",za.ds=!0,za.dsndb=!1,za.dsa=!1,za.dsss=!1,za.dssv=!0,za.earxa=!0,za.exp=!1,za.eni=!0,za.erjdw=!0,za.expw=!1,za.instr="",za.evl="",za.extblacklist="",za.euf=!1,za.fau=!0,za.fa=!1,za.fvdi=!1,za.featureHash="",za.hvt=
      216E5,za.ffi=!1,za.imm=!1,za.ign="",za.iub="",za.iqvn=!1,za.initializedModules="",za.lastModification=0,za.lupr=!0,za.lab=!1,za.legacy=!1,za.lt=!0,za.mb="",za.md="",za.mdp="",za.mdl="",za.mcepsl=100,za.mdn=5E3,za.mhl=4E3,za.mpl=1024,za.mmds=2E4,za.msl=3E4,za.bismepl=2E3,za.mel=200,za.mepp=10,za.moa=30,za.mrt=3,za.ntd=!1,za.ncw=!1,za.oat=180,za.ote=!1,za.owasp=!1,za.pcl=20,za.pt=!0,za.perfbv=1,za.prfSmpl=0,za.pVO=!1,za.peti=!1,za.raxeh=!0,za.rdnt=0,za.nosr=!0,za.reportUrl="dynaTraceMonitor",za.rid=
      "",za.ridPath="",za.rpid="",za.rcdec=12096E5,za.rtl=0,za.rtp=2,za.rtt=1E3,za.rtu=200,za.restoreTimeline=!1,za.rvcl=24,za.sl=100,za.ssc=!1,za.svNB=!1,za.srad=!0,za.srbbv=1,za.srbw=!0,za.srmr=100,za.srms="1,1,,,",za.srsr=1E5,za.srtbv=3,za.srtd=1,za.srtr=500,za.srvr="",za.srvi=0,za.srwo=!1,za.srre="",za.srxcss=!0,za.srxicss=!0,za.srif=!1,za.srmrc=!1,za.srsdom=!1,za.srcss=!0,za.srmcrl=1,za.srmcrv=10,za.ssv=4,za.st=3E3,za.spc="",za.syntheticConfig=!1,za.tal=0,za.tp="500,50,3",za.tt=100,za.tvc=3E3,za.exteventsoff=
      !1,za.uxdce=!1,za.uxdcw=1500,za.uxrgce=!0,za.uxrgcm="100,25,300,3;100,25,300,3",za.uam=!1,za.uana="data-dtname,data-dtName",za.uanpi=0,za.pui=!1,za.usrvd=!0,za.vrt=!1,za.vcfi=!0,za.vcit=1E3,za.vct=50,za.vcx=50,za.vscl=0,za.vncm=1,za.xb="",za.chw="",za.xt=0,za.xhb="",za);a:{var Zc=Cb.dT_.cfg;qc={reportUrl:"dynaTraceMonitor",initializedModules:"",csu:"dtagent",dataDtConfig:"string"===typeof Zc?Zc:""};Cb.dT_.cfg=qc;qc.csu="ruxitagentjs";var Ye=qc.dataDtConfig;Ye&&-1===ra(Ye,"#CONFIGSTRING")&&(Ab(Ye,
      qc),cb("domain"),cb("auto"),cb("app"),Hb(qc));var Sd=db("script"),Ah=K(Sd),fe=-1===ra(qc.dataDtConfig||"","#CONFIGSTRING")?qc:null;if(0<Ah)for(Bf=0;Bf<Ah;Bf++)b:{De=void 0;var of=Sd[Bf];Af=fe;if(of.attributes){var Cf=qc.csu+"_bootstrap.js";bg=/.*\\/jstag\\/.*\\/.*\\/(.*)_bs(_dbg)?.js$/;za=Af;var gf=of.src,hh=null===gf||void 0===gf?void 0:gf.indexOf(Cf),cj=of.attributes.getNamedItem("data-dtconfig");if(cj){Zc=void 0;Ye=gf;var Bh=cj.value;ta={};qc.legacy="1";vb=/([a-zA-Z]*)_([a-zA-Z_0-9]*)_([0-9]+)/g;Ye&&
      (Zc=vb.exec(Ye),null===Zc||void 0===Zc?0:Zc.length)&&(ta.csu=Zc[1],ta.featureHash=Zc[2],ta.agentLocation=Ye.substr(0,ra(Ye,Zc[1])-1),ta.buildNumber=Zc[3]);if(Bh){Ab(Bh,ta,!0);var Lg=ta.agentUri;!Ye&&Lg&&(Zc=vb.exec(Lg),null===Zc||void 0===Zc?0:Zc.length)&&(ta.csu=Zc[1])}Ic(ta);De=ta;if(!Af)za=De;else if(!De.syntheticConfig){fe=De;break b}}De||(De=qc);if(0<hh){var E=hh+Cf.length+5;De.app=gf.length>E?gf.substr(E):"Default%20Application"}else if(gf){var U=bg.exec(gf);U&&(De.app=U[1])}fe=za}else fe=Af}if(fe)for(var na in fe)Object.prototype.hasOwnProperty.call(fe,
      na)&&(Sd=na,qc[Sd]=fe[Sd]);var Ja=zb();try{var bb=(fe=mf)&&fe.getItem(Ja);if(bb){var wb=Ga(bb),Fb=Ab(wb.config||""),hc=qc.lastModification||"0",kc=W((Fb.lastModification||wb.lastModification||"0").substr(0,13)),Bc="string"===typeof hc?W(hc.substr(0,13)):hc;if(!hc||kc>=Bc)if(Fb.csu=wb.name||M("csu"),Fb.featureHash=wb.featureHash||M("featureHash"),Fb.agentUri&&Hb(Fb),Mb(Fb,!0),Bb(Fb),jb(Fb),kc>(qc.lastModification||0)){var kd=La("auto"),Pd=La("legacy");qc=Ea(Fb);qc.auto=kd?"1":"0";qc.legacy=Pd?"1":
      "0"}}}catch(ke){}Mb(qc);try{var Nd=qc.ign;if(Nd&&(new RegExp(Nd)).test(Cb.location.href)){document.dT_=Cb.dT_=void 0;var Dd=!1;break a}}catch(ke){}if(pc()){var xe=navigator.userAgent,ld=xe.lastIndexOf("RuxitSynthetic");if(-1===ld)var $d={};else{var ae=xe.substring(ld+14);if(-1===ra(ae," c"))$d={};else{Ja={};bb=0;for(var uf=ae.split(" ");bb<uf.length;bb++){var lg=uf[bb];if("c"===lg.charAt(0)){var vf=lg.substr(1),Rf=vf.indexOf("="),mg=vf.substring(0,Rf),Sf=vf.substring(Rf+1);mg&&Sf&&(Ja[mg]=Sf)}}$d=
      Ja}}ae=void 0;for(ae in $d)Object.prototype.hasOwnProperty.call($d,ae)&&$d[ae]&&(qc[ae]=$d[ae]);Ea(qc)}Dd=!0}if(!Dd)throw Error("Error during config initialization");jd();try{xg=Cb.dT_.disabled||!!la("dtDisabled")}catch(ke){}var ve;if(!(ve=M("agentLocation")))a:{var Tf=M("agentUri");if(Tf||document.currentScript){var Gf=Tf||document.currentScript.src;if(Gf){Dd=Gf;var Ai=-1===ra(Dd,"_bs")&&-1===ra(Dd,"_bootstrap")&&-1===ra(Dd,"_complete")?1:2,yg=Gf.lastIndexOf("/");for(Dd=0;Dd<Ai&&-1!==yg;Dd++)Gf=
      Gf.substr(0,yg),yg=Gf.lastIndexOf("/");ve=Gf;break a}}var hi=location.pathname;ve=hi.substr(0,hi.lastIndexOf("/"))}Rd=ve;tf=M("csu")||"ruxitagentjs";"true"===Z("dtUseDebugAgent")&&0>tf.indexOf("dbg")&&(tf=M("debugName")||tf+"dbg");if(!La("auto")&&!La("legacy")&&!xg){var Qh=M("agentUri")||ef(M("featureHash")),Ch;if(!(Ch=La("async")||"complete"===document.readyState)){var Hf=Cb.navigator.userAgent,wf=Hf.indexOf("MSIE ");Ch=0<wf?9>=parseInt(Hf.substring(wf+5,Hf.indexOf(".",wf)),10):!1}if(Ch)sf(Qh,La("async"),
      void 0,void 0,"dtjsagent");else{var xf="".concat("dtjsagent","dw");document.write('<script id="'.concat(xf,'" type="text/javascript" src="').concat(Qh,'">\x3c/script>'));document.getElementById(xf)||sf(Qh,La("async"),void 0,void 0,"dtjsagent")}}Z("dtCookie")&&fa("cooO",!0);ff();fa("pVO",!!ad("dt-pVO"));od(Md);zf=18E5;Yc=ab("hvt")||216E5;x(ee,[1]);zh=[];gi="dtCookie dtLatC rxvt dtAdk dtAdkSettings dtPC".split(" ");if(La("cg"))try{Oc(Object.getOwnPropertyDescriptor(Document.prototype,"cookie")||Object.getOwnPropertyDescriptor(HTMLDocument.prototype,
      "cookie"))}catch(ke){}}catch(ke){try{delete Cb.dT_}catch(pf){Cb.dT_=void 0}Oa()&&Cb.console.log("JsAgent initCode initialization failed!")}})(!1)})()})();`;
      document.head.insertBefore(rumScript, document.head.lastChild);
    }

  }
}
