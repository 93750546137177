import {
  BatchPdfExportEMMetrics,
  BatchPdfExportOverviewMetrics,
  emTrendHierarchy, EMTrendOption,
  EMTrendPdfOption,
  ExportMetric
} from '../../shared/export/export';
import {MetricType} from '../../shared/metric-types';
import {BatchExportDetailCriteria, BatchExportDetailsResponse, FeatureToggleSetting} from '../../shared/models';
import {NgRedux, select} from '@angular-redux/store';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../../store/IAppState';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {MatDateRangePicker} from '@angular/material/datepicker';
import {BatchExportServiceToken, HttpBatchExportService} from '../services/batch-export.service';
import {AnalyticsService, AnalyticsServiceToken} from '../../analytics/analytics.service';
import {take} from 'rxjs/operators';
import {getAppConfigValue, isFeatureEnabled} from '../../shared/helpers';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {combineLatest, Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {BatchExportMessageComponent} from '../batch-export-message/batch-export-message.component';
import {anyEmTrendHierarchySelected, closeSelectionHierarchy} from '../batch-export-helper';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';
import {GoogleAnalyticCategories} from '../../shared/enums';

@Component({
  selector: 'app-batch-export-dialog',
  templateUrl: './batch-export-dialog.component.html',
  styleUrls: ['./batch-export-dialog.component.scss']
})
export class BatchExportDialogComponent implements OnInit, OnDestroy {
  @ViewChild('picker') picker: MatDateRangePicker<any>;
  reportName = '';

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  batchPdfExportOverviewMetrics = BatchPdfExportOverviewMetrics;
  batchPdfExportEMMetrics = BatchPdfExportEMMetrics;
  allOverviewMetricsSelected = false;
  overviewExportSelected = false;
  allEMMetricsSelected = false;
  atLeastOneEMMetricSelected = false;
  showEmExport = false;
  showNpvExport = false;
  MetricType = MetricType;
  shouldShowHoverMessage = false;
  date: UntypedFormControl;
  featureToggleSubscription: Subscription;
  frequency: UntypedFormControl;
  hoverMessageText = `The output of this report is based upon your current ` +
    `selection criteria, and represents the most current data submission.`;
  private filterCriteria: FilterCriteria;
  showSubmittalWait = false;
  somethingSelected = false;
  includeNpvPage = false;
  emTrendHierarchy = emTrendHierarchy();
  canShowBenchmarkSelector = false;

  constructor(public dialogRef: MatDialogRef<BatchExportDialogComponent>,
              public dialog: MatDialog,
              private ngRedux: NgRedux<IAppState>,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService) {
    this.date = new UntypedFormControl(new Date());
  }

  ngOnInit() {
    this.featureToggleSubscription = combineLatest([this.featureToggleSettings$])
      .subscribe(([featureSettings]: [FeatureToggleSetting[]]) => {
        if (!_.isEqual(featureSettings, INITIAL_STATE.data.featureToggleSettings)) {
          const userProfile = this.ngRedux.getState().data.userProfile;
          this.showEmExport = isFeatureEnabled(FeatureToggleEntries.SHOW_EM_MULTIPROVIDER_EXPORT, featureSettings, userProfile);
          this.showNpvExport = isFeatureEnabled(FeatureToggleEntries.NPV_MULTI_PROVIDER_EXPORT, featureSettings, userProfile);
          this.canShowBenchmarkSelector = isFeatureEnabled(FeatureToggleEntries.MULTI_PROVIDER_BENCHMARK_SELECTORS,
            featureSettings, userProfile);
          this.checkIfAnythingSelected();
        }
      });
    this.filterCriteria = this.ngRedux.getState().filters;
  }

  ngOnDestroy() {
    this.clearValues();
  }

  checkIfAnythingSelected(): void {
    this.somethingSelected = !!this.batchPdfExportOverviewMetrics.find(o => o.selected) ||
      (this.showEmExport && !!this.batchPdfExportEMMetrics.find(e => e.selected)) || this.overviewExportSelected ||
      (this.showNpvExport && this.includeNpvPage) ||
      (anyEmTrendHierarchySelected(this.emTrendHierarchy).length > 0);
  }

  close(): void {
    this.clearValues();
    this.dialogRef.close();
  }

  export(): void {
    if (!this.reportName || this.reportName.length <= 0 || !this.somethingSelected) {
      return;
    }
    const maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT,
      this.ngRedux.getState().data.applicationConfigurationSettings) || 5;
    let locationText = 'All Locations';
    const selectedLocations = this.ngRedux.getState().data.memberLocations.filter(loc => loc.currentlySelected);
    if (selectedLocations.length > 0) {
      const additionalLocations = selectedLocations.length - maxLocations;
      locationText = selectedLocations.slice(0, maxLocations).map(x => x.memberLocationName).join(', ') +
        (additionalLocations > 0 ? (' + ' + additionalLocations + ' more') : '');
    }
    const selectedEmTrendOptions = anyEmTrendHierarchySelected(this.emTrendHierarchy);
    const batchExportCriteria: BatchExportDetailCriteria = {
      reportName: this.reportName,
      startDateInMonths: this.filterCriteria.dateRange.startYear * 12 + this.filterCriteria.dateRange.startMonth,
      endDateInMonths: this.filterCriteria.dateRange.endYear * 12 + this.filterCriteria.dateRange.endMonth,
      memberLocationKey: this.filterCriteria.memberLocation.memberLocationKey,
      payerCategoryKey: this.filterCriteria.payerCategory.payerCategoryKey,
      payerKey: this.filterCriteria.payerKey,
      payerType: this.filterCriteria.payerType,
      lagKey: this.filterCriteria.lagKey,
      memberBillingAreaKey: this.filterCriteria.memberBillingArea.memberBillingAreaKey,
      invoiceStatus: this.filterCriteria.invoiceStatus,
      nodePath: this.filterCriteria.nodePath,
      isOutpatientRequired: !!this.batchPdfExportOverviewMetrics.find(x => x.id === 0)?.selected,
      isInPatientRequired: !!this.batchPdfExportOverviewMetrics.find(x => x.id === 1)?.selected,
      isEmergencyMedicineRequired: !!this.batchPdfExportOverviewMetrics.find(x => x.id === 2)?.selected,
      isOpthalmologyRequired: !!this.batchPdfExportOverviewMetrics.find(x => x.id === 3)?.selected,
      payerCategoryDescription: this.filterCriteria.payerCategory.payerCategoryDescription,
      memberLocationName: this.filterCriteria.memberLocation.memberLocationName,
      memberName: this.ngRedux.getState().data.selectedMemberData.memberDesc,
      billingArea: this.filterCriteria.memberBillingArea.memberBillingAreaDescription,
      telehealthFlag: this.filterCriteria.telehealthFlag,
      isOverviewRequired: this.allOverviewMetricsSelected || !!this.batchPdfExportOverviewMetrics.find(x => x.selected),
      isEmPageRequired: !!this.batchPdfExportEMMetrics.find(x => x.selected),
      isEmTrendPageRequired: selectedEmTrendOptions.length > 0,
      isEmPageOutpatientRequired: !!this.batchPdfExportEMMetrics.find(x => x.id === 0)?.selected,
      isEmPageInPatientRequired: !!this.batchPdfExportEMMetrics.find(x => x.id === 1)?.selected,
      isEmPageEmergencyMedicineRequired: !!this.batchPdfExportEMMetrics.find(x => x.id === 2)?.selected,
      isEmPageOpthalmologyRequired: !!this.batchPdfExportEMMetrics.find(x => x.id === 3)?.selected,
      isNewPatientVisitsTrendPageRequired: this.includeNpvPage,
      getBatchExportReportDetailsByUser: false,
      memberLocationKeys: this.filterCriteria.memberLocationKeys,
      multiLocationMessage: locationText,
      emTrendRangeNewPatientVisit: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_OUTPATIENT_TREND_NEW_PATIENT_VISIT),
      emTrendRangeEstablishedPatientVisit: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_OUTPATIENT_TREND_ESTABLISHED_PATIENT),
      emTrendRangeConsultation: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_OUTPATIENT_TREND_CONSULTATION),
      emTrendRangeInitialHospital: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_INPATIENT_TREND_INITIAL_HOSPITAL_CARE),
      emTrendRangeSubsequentHospital: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_INPATIENT_TREND_SUBSEQUENT_HOSPITAL_CARE),
      emTrendRangeHospitalDischarge: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_INPATIENT_TREND_HOSPITAL_DISCHARGE),
      emTrendRangeEmergencyMedicine: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_EMERGENCY_TREND_MEDICINE),
      emTrendRangeNpvEyeExam: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_EYE_EXAM_TREND_NEW_PATIENT_EYE_EXAM),
      emTrendRangeEstablishedEyeExam: !!selectedEmTrendOptions.find(o => o ===
        EMTrendPdfOption.EM_EYE_EXAM_TREND_ESTABLISHED_PATIENT_EYE_EXAM),
    };
    this.showSubmittalWait = true;
    this.batchExportService.saveBatchExportInformation(batchExportCriteria, this.filterCriteria.memberKey)
      .pipe(take(1))
      .subscribe((response: BatchExportDetailsResponse) => {
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.MultiProviderExport,
          GoogleAnalyticCategories.MultiProviderExport,
          `One-Time ${GoogleAnalyticCategories.MultiProviderExport}`);
        this.analyticsService.handleGoogleAnalytics('Multi Provider Export Old Version',
          'Multi Provider Export Metric Selection Old Version',
          this.getGaString());
        this.showSubmittalWait = false;
        this.clearValues();
        this.close();
        this.dialog.open(BatchExportMessageComponent, {
          data: response.batchExportDetails ? response.batchExportDetails.length : 0
        });
      });
  }

  private getGaString() {
    const overviewString = this.getOverviewGaSubstring();
    const emString = this.getEmGaSubstring();
    const emTrendString = this.getEmTrendGaSubstring();
    const npvTrendString = this.includeNpvPage ? 'NPV Trend' : '';
    return overviewString + emString + emTrendString + npvTrendString;
  }

  private getEmGaSubstring() {
    let emString = '';
    for (let i = 0; i < this.batchPdfExportEMMetrics.length; i++) {
      if (this.batchPdfExportEMMetrics[i].selected) {
        const replacementString = this.requiresComma(i, this.batchPdfExportEMMetrics) ? ', ' : '';
        emString = emString.concat(this.batchPdfExportEMMetrics[i].text.replace(' View', replacementString));
      }
    }
    if (emString !== '') {
      emString = 'E&M Page - '.concat(emString + '/ ');
    }
    return emString;
  }

  private getEmTrendGaSubstring(): string {
    if (!this.emTrendHierarchy[0].selected) {
      return '';
    }
    let emTrendString = '';
    for (let i = 0; i < this.emTrendHierarchy[0].children.length; i++) {
      if (this.emTrendHierarchy[0].children[i].selected) {
        emTrendString = emTrendString.concat(this.emTrendHierarchy[0].children[i].text + ': (');
        emTrendString = this.addRangeText(i, emTrendString);
        emTrendString = emTrendString + ') ';
      }
    }
    if (emTrendString !== '') {
      emTrendString = 'E&M Trend - '.concat(emTrendString + '/ ');
    }
    return emTrendString;
  }

  private addRangeText(i: number, emTrendString: string) {
    for (let j = 0; j < this.emTrendHierarchy[0].children[i].children?.length; j++) {
      if (this.emTrendHierarchy[0].children[i].children[j].selected) {
        emTrendString = emTrendString.concat(this.emTrendHierarchy[0].children[i].children[j].text) +
          (this.requiresComma(j, this.emTrendHierarchy[0].children[i].children) ? ', ' : '');
      }
    }
    return emTrendString;
  }

  private requiresComma(i: number, options: ExportMetric<any>[] | EMTrendOption[]) {
    return i < options.length - 1 && (options[1 + i]?.selected || options[2 + i]?.selected || options[3 + i]?.selected);
  }

  private getOverviewGaSubstring() {
    let overviewString = '';
    for (let i = 0; i < this.batchPdfExportOverviewMetrics.length; i++) {
      if (this.batchPdfExportOverviewMetrics[i].selected) {
        const replacementString = this.requiresComma(i, this.batchPdfExportOverviewMetrics) ? ', ' : '';
        overviewString = overviewString.concat(this.batchPdfExportOverviewMetrics[i].text.replace(' View', replacementString));
      }
    }
    if (overviewString !== '') {
      overviewString = 'Overview - '.concat(overviewString + '/ ');
    }
    return overviewString;
  }

  selectOrDeselectAllOverviewMetrics(event: MouseEvent): void {
    event.preventDefault();
    this.allOverviewMetricsSelected ? this.deselectAllOverviewMetrics() : this.selectAllOverviewMetrics();
    this.overviewExportSelected = this.allOverviewMetricsSelected;
    this.checkIfAnythingSelected();
  }

  selectOrDeselectAllEMMetrics(event: MouseEvent): void {
    event.preventDefault();
    this.allEMMetricsSelected ? this.deselectAllEMMetrics() : this.selectAllEMMetrics();
    this.atLeastOneEMMetricSelected = this.allEMMetricsSelected;
    this.checkIfAnythingSelected();
  }

  selectAllEMMetrics(): void {
    this.batchPdfExportEMMetrics.forEach(x => x.selected = true);
    this.allEMMetricsSelected = true;
  }

  deselectAllEMMetrics(): void {
    this.batchPdfExportEMMetrics.forEach(x => x.selected = false);
    this.allEMMetricsSelected = false;
  }

  selectAllOverviewMetrics(): void {
    this.batchPdfExportOverviewMetrics.forEach(x => x.selected = true);
    this.allOverviewMetricsSelected = true;
  }

  deselectAllOverviewMetrics(): void {
    this.batchPdfExportOverviewMetrics.forEach(x => x.selected = false);
    this.allOverviewMetricsSelected = false;
  }

  updateBatchPdfExportOverviewMetric(id: number, event: MouseEvent) {
    event.preventDefault();
    this.allOverviewMetricsSelected = true;
    this.batchPdfExportOverviewMetrics.forEach(x => {
      if (x.id === id) {
        x.selected = !x.selected;
      }
      if (!x.selected) {
        this.allOverviewMetricsSelected = false;
      }
    });
    this.overviewExportSelected = true;
    this.checkIfAnythingSelected();
  }

  whenEmTrendOptionSelected = () => {
    this.checkIfAnythingSelected();
  };

  determineIfAnyEmMetricsAreSelected() {
    let found = false;
    for (const metric of this.batchPdfExportEMMetrics) {
      if (metric.selected) {
        found = true;
        break;
      }
    }
    this.atLeastOneEMMetricSelected = found;
  }

  toggleNpv(event: MouseEvent): void {
    event.preventDefault();
    this.includeNpvPage = !this.includeNpvPage;
    this.checkIfAnythingSelected();
  }

  updateBatchPdfExportEMMetric(id: number, event: MouseEvent) {
    event.preventDefault();
    const emMetric = this.batchPdfExportEMMetrics.find(x => x.id === id);
    if (emMetric) {
      emMetric.selected = !emMetric.selected;
    }
    this.allEMMetricsSelected = !this.batchPdfExportEMMetrics.find(x => !x.selected);
    this.determineIfAnyEmMetricsAreSelected();
    this.checkIfAnythingSelected();
  }

  clearValues(): void {
    this.batchPdfExportEMMetrics.forEach(x => x.selected = false);
    this.batchPdfExportOverviewMetrics.forEach(x => x.selected = false);
    closeSelectionHierarchy(this.emTrendHierarchy);
  }
}
