import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NpvByLocationForChart, NpvLocationViewByOption} from '../../../npv-models';
import {MultilevelTab} from '../../../../../shared/enums';
import {deepCopyOf, getSingularOntologyLevelName, pluralizeText} from '../../../../../shared/helpers';
import {ontologyTabEquivalentOfLocationTab} from '../../../../../tab-navigation/tab-helper';
import {UntypedFormControl} from '@angular/forms';
import {MIN_SEARCH_CHARS} from '../../../../../shared/constants';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../../../store/IAppState';
import {
  filterNpvLocationGraphByLocationChangedTo,
  filterNpvLocationGraphByOntologyChangedTo
} from '../../../../../store/actions';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {anyDesignatedPropertiesChanged} from '../../../../../shared/object-helpers';

@Component({
  selector: 'app-npv-location-graph-filter-selector',
  templateUrl: './npv-location-graph-filter-selector.component.html',
  styleUrls: ['./npv-location-graph-filter-selector.component.scss']
})
export class NpvLocationGraphFilterSelectorComponent implements OnChanges, OnInit {
  @Input() viewByNode: boolean;
  @Input() selectedLevelTab: MultilevelTab;
  @Input() npvLocationChartData: NpvByLocationForChart[] = [];
  @Input() currentSelectedLocationNode: NpvLocationViewByOption | undefined;
  @Input() currentSelectedOntologyNode: NpvLocationViewByOption | undefined;
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  locationNodeOptions: NpvLocationViewByOption[] = [];
  filteredOptions: NpvLocationViewByOption[];
  searchText = '';
  currentSelectionText = '';
  nodeText = '';
  minSearchChars = MIN_SEARCH_CHARS;
  autocompleteControl = new UntypedFormControl('');
  showDropdown = false;
  eligibleToShowClear = false;

  constructor(private ngRedux: NgRedux<IAppState>) { }

  ngOnInit(): void {
    this.autocompleteControl.valueChanges.pipe(startWith(''),
      map(value => {
        if (value) {
          value = typeof value === 'string' ? value : value.display ?? '';
          if (value.length >= this.minSearchChars) {
            this.filterOptionsBySearchText(value);
          } else {
            this.clearFilters();
          }
        } else {
          this.clearFilters();
        }
      })
    ).subscribe();
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes && anyDesignatedPropertiesChanged(changes, ['selectedLevelTab', 'viewByNode'])) {
      this.showDropdown = false;
    }
    this.nodeText = getSingularOntologyLevelName(this.selectedLevelTab);
    this.generateFilterByOptions();
    this.clearFilters();
    this.initializeSearchText();
  }

  async toggleSearchPanel() {
    this.searchText = '';
    this.showDropdown = !this.showDropdown;
    await pause();
    function pause() {
      return new Promise(
        (resolve) => {
          setTimeout(resolve, 0);
        }
      );
    }
    this.inputAutoComplete?.openPanel();
  }

  private initializeSearchText() {
    if (this.viewByNode) {
      if (!this.currentSelectedLocationNode) {
        this.currentSelectedLocationNode = this.locationNodeOptions.find(o => o.all);
      }
      this.searchText = this.currentSelectedLocationNode && this.currentSelectedLocationNode.identity.length ?
        this.currentSelectedLocationNode.display : '';
      this.currentSelectionText = this.currentSelectedLocationNode?.display ?? '';
      this.eligibleToShowClear = !!this.currentSelectedLocationNode && this.currentSelectedLocationNode.identity.length > 0;
    } else {
      if (!this.currentSelectedOntologyNode || (this.currentSelectedOntologyNode.level &&
        this.currentSelectedOntologyNode.level !== this.selectedLevelTab)) {
        this.currentSelectedOntologyNode = this.locationNodeOptions.find(o => o.all);
      }
      this.searchText = this.currentSelectedOntologyNode && this.currentSelectedOntologyNode.identity.length ?
        this.currentSelectedOntologyNode.display : '';
      this.currentSelectionText = this.currentSelectedOntologyNode?.display ?? '';
      this.eligibleToShowClear = !!this.currentSelectedOntologyNode && this.currentSelectedOntologyNode.identity.length > 0;
    }
  }

  whenInput(event: any): void {
    this.searchText = event.target.value;
  }

  onOptionSelected(option: NpvLocationViewByOption) {
    this.showDropdown = false;
    if (option.all) {
      if (this.viewByNode) {
        this.setFilterGraphByToAllLocations();
      } else {
        this.setFilterGraphByToAllDepartmentsSpecialtiesOrProviders();
      }
    } else {
      this.ngRedux.dispatch(this.viewByNode ? filterNpvLocationGraphByLocationChangedTo(option) :
        filterNpvLocationGraphByOntologyChangedTo(option));
    }
  }

  getFilterTypeText() {
    return this.viewByNode ? 'Location' : this.nodeText;
  }

  generateFilterByOptions(): void {
    if (this.viewByNode) {
      this.generateLocationFilterOptions();
    } else {
      this.generateOntologyFilterOptions();
    }
  }

  clearTheSelections(): void {
    this.showDropdown = false;
    this.searchText = '';
    if (this.viewByNode) {
      this.setFilterGraphByToAllLocations();
    } else {
      this.setFilterGraphByToAllDepartmentsSpecialtiesOrProviders();
    }
  }

  private setFilterGraphByToAllLocations() {
    this.ngRedux.dispatch(filterNpvLocationGraphByLocationChangedTo());
  }

  private setFilterGraphByToAllDepartmentsSpecialtiesOrProviders() {
    this.ngRedux.dispatch(filterNpvLocationGraphByOntologyChangedTo());
  }

  private clearFilters() {
    this.filteredOptions = this.locationNodeOptions;
  }

  private generateOntologyFilterOptions() {
    this.locationNodeOptions = [];
    const identities: any = {};
    this.npvLocationChartData.forEach(datum => {
      if (!identities[datum.identity]) {
        this.locationNodeOptions.push({
          identity: `${datum.identity}`,
          display: `${datum.name}`,
          hidden: false, level: this.selectedLevelTab
        });
        identities[datum.identity] = true;
      }
    });
    this.addOptionForAllDepartmentsSpecialtiesOrProviders();
  }

  private generateLocationFilterOptions() {
    this.locationNodeOptions = [];
    const identities: any = {};
    this.npvLocationChartData.forEach(datum => {
      if (!identities[datum.identity]) {
        const identity = `${datum.identity}`;
        this.locationNodeOptions.push({
          identity: identity,
          display: datum.name,
          hidden: false
        });
        identities[datum.identity] = true;
      }
    });
    this.addOptionForAllLocations();
  }

  private addOptionForAllDepartmentsSpecialtiesOrProviders() {
    this.locationNodeOptions.splice(0, 0, this.getViewAllOntologyOptionForAutocompleteList());
  }

  private addOptionForAllLocations() {
    this.locationNodeOptions.splice(0, 0, {
      identity: '', display: 'All Locations', hidden: false, all: true
    });
  }

  private getViewAllOntologyOptionForAutocompleteList(): NpvLocationViewByOption {
    return {
      identity: '',
      display: `All ${pluralizeText(getSingularOntologyLevelName(ontologyTabEquivalentOfLocationTab(this.selectedLevelTab)))}`,
      hidden: false, level: this.selectedLevelTab, all: true
    };
  }

  private filterOptionsBySearchText(searchText: string) {
    const copyString = deepCopyOf(searchText).toLowerCase();
    this.filteredOptions = this.locationNodeOptions.filter(option => option.identity.length > 0 &&
      this.matchesSearchText(option.display, copyString));
    this.filteredOptions.sort((a, b) => a.display.localeCompare(b.display));
  }

  matchesSearchText(optionDisplay: string, copyString: string): boolean {
    const tokens = optionDisplay.toLowerCase().split(' ');
    let matches = false;
    tokens.forEach(token => {
      if (token.startsWith(copyString)) {
        matches = true;
      }
    });
    return matches;
  }
}
