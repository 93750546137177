import {Component, Inject, Input, OnChanges} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {ProductivityMultiLevelSnapshot, ProductivitySnapshot} from '../../services/ProviderProductivity';
import {getLevelTypeNodePath, LevelType, Page, WrvuViewType} from '../../../shared/helpers';
import {SortingCriterion} from '../../../shared/models';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {Variable} from '../../../variable-container/variable-container.component';
import {AppAction, drillDownChangedTo, isProviderSelectedChangedTo} from '../../../store/actions';
import {multilevelTabChangedTo} from '../../../store/actions';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import * as _ from 'lodash';
import {MultilevelTab} from '../../../shared/enums';
import {DrillDownService} from '../../../services/drilldown.service';

@Component({
  selector: 'app-wrvu-multilevel-specialty-page',
  templateUrl: './wrvu-multilevel-specialty-page.component.html',
  styleUrls: ['./wrvu-multilevel-specialty-page.component.scss']
})
export class WrvuMultilevelSpecialtyPageComponent implements OnChanges {
  @Input() activeVarianceToggle: boolean;
  @Input() multilevel: boolean;
  @Input() zeroSuppression: boolean;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() selectionCounts: number;
  @Input() varianceToggleSortingCriterion: SortingCriterion;
  @Input() wRVUtype: WrvuViewType;
  @Input() varianceKey: string;
  @Input() variables: Variable[];
  @Input() nodePath: string;
  @Input() defaultSortColumn: string;
  @Input() sortDirection: string;
  @Input() countOfSuppressedEntries: number;
  @Input() zeroSuppressionCondition: string;
  @Input() reducerAction: (displayedWrvuSnapshotColumns: BaseColumn[]) => AppAction;
  @Input() wrvuSnapshotData: ProductivityMultiLevelSnapshot[];
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion | undefined) => void;
  @Input() columns: DataTableColumns[];
  @Input() displayedColumns: DataTableColumns[] = [];
  // @Input() fromPdfExport = false;

  currentPage: Page = Page.WrvuSpecialty;
  showProgressBar: boolean;
  LevelType = LevelType;
  MultilevelTab = MultilevelTab;

  constructor(private ngRedux: NgRedux<IAppState>,
              private drillDownService: DrillDownService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService
  ) {
  }

  ngOnChanges(): void {
    this.showProgressBar =
      _.isEqual(this.wrvuSnapshotData, INITIAL_STATE.data.providerProductivityMultilevelData.specialtyProductivities);
  }

  tieBreakerProperty = (providerProductivity: ProductivityMultiLevelSnapshot) => {
    return providerProductivity.specialtyNodeName;
  };

  whenSpecialtyRowSelected = (selectedNode: ProductivityMultiLevelSnapshot) => {
    this.whenSpecialtySelected(selectedNode, true);
  };

  whenSpecialtySelected = (selectedNode: ProductivityMultiLevelSnapshot, table = false) => {
    if (!selectedNode) {
      return;
    }

    this.analyticsService.handleGoogleAnalytics('Wrvu Snapshot', selectedNode.nodeName, `Drill down on ${table ? 'Table' : 'Chart'}`);
    this.setSingleProviderSelectedIntoStore(selectedNode);
    this.drillDownService.drillDownIntoNode(selectedNode.nodePath, this.nodePath, MultilevelTab.BY_SPECIALTY);
    this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.BY_PROVIDER));
  };

  setSingleProviderSelectedIntoStore(productivitySnapshot?: ProductivitySnapshot) {
    const nodePath = this.ngRedux.getState().filters.nodePath;
    if (productivitySnapshot && getLevelTypeNodePath(productivitySnapshot.nodePath) === LevelType.singleProvider) {
      this.ngRedux.dispatch(drillDownChangedTo(true, nodePath, MultilevelTab.BY_PROVIDER));
      this.ngRedux.dispatch(isProviderSelectedChangedTo(true));
    }
  }

  whenSortChanged = (productivitySnapshotData: ProductivityMultiLevelSnapshot[]) => {
    if (!_.isEqual(this.wrvuSnapshotData, productivitySnapshotData)) {
      this.wrvuSnapshotData = productivitySnapshotData;
    }
  };
}
