import {NgModule} from '@angular/core';
import {VizDateRangePickerComponent} from './viz-date-range-picker.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PlatformModule} from '@angular/cdk/platform';
import {DateAdapter} from './datetime/date-adapter';
import {MAT_DATE_FORMATS} from './datetime/date-formats';
import {SatCalendarBody} from './datepicker/calendar-body';
import {SatMonthView} from './datepicker/month-view';
import {SatMultiYearView} from './datepicker/multi-year-view';
import {SatYearView} from './datepicker/year-view';
import {SatCalendar, SatCalendarHeader} from './datepicker/calendar';
import {RangeCalendar} from './datepicker/range-calendar';
import {matRangeDatepicker, matRangeDatepickerContent} from './datepicker/datepicker';
import {matRangeDatepickerInput} from './datepicker/datepicker-input';
import {matRangeDatepickerToggle, matRangeDatepickerToggleIcon} from './datepicker/datepicker-toggle';
import {PortalModule} from '@angular/cdk/portal';
import {APP_DATE_FORMATS, AppDateAdapter} from './AppDateAdapter';
import {MatRadioModule} from '@angular/material/radio';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  imports: [
    PlatformModule,
    CommonModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    PortalModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  exports: [
    VizDateRangePickerComponent,
    SatCalendarBody,
    SatMonthView,
    SatMultiYearView,
    SatYearView,
    SatCalendar,
    SatCalendarHeader,
    RangeCalendar,
    matRangeDatepickerContent,
    matRangeDatepickerInput,
    matRangeDatepicker,
    matRangeDatepickerToggle,
    matRangeDatepickerToggleIcon
  ],
  declarations: [
    VizDateRangePickerComponent,
    SatCalendarBody,
    SatMonthView,
    SatMultiYearView,
    SatYearView,
    SatCalendar,
    SatCalendarHeader,
    RangeCalendar,
    matRangeDatepickerContent,
    matRangeDatepickerInput,
    matRangeDatepicker,
    matRangeDatepickerToggle,
    matRangeDatepickerToggleIcon
    // LastDaysPipe
  ],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class VizDateRangePickerModule {
  constructor() {
  }
}

