export const overviewPageRoute = `/productivity/overview`;

export const wRVUsRoute = `/productivity/wRVUs`;
export const wRVUSnapshotPageRoute = `/productivity/wRVUs/snapshot`;
export const wRVUTrendPageRoute = `/productivity/wRVUs/trend`;
export const wRVUProviderPageRoute = `/productivity/wRVUs/provider`;
export const wRVUSpecialtyPageRoute = `/productivity/wRVUs/specialty`;
export const wRVUDepartmentPageRoute = `/productivity/wRVUs/department`;
export const wRVUSpecialtyPerformancePageRoute = `/productivity/wRVUs/specialtyPerformance`;
export const wRVUMultiLevelTrendPageRoute = `/productivity/wRVUs/multiLevelTrend`;

export const npvSnapshotPageRoute = `/productivity/npv/snapshot`;
export const npvMultiLevelTrendPageRoute = `/productivity/npv/multiLevelTrend`;
export const npvDepartmentPageRoute = `/productivity/npv/department`;
export const npvSpecialtyPageRoute = `/productivity/npv/specialty`;
export const npvProviderPageRoute = `/productivity/npv/provider`;
export const npvTrendPageRoute = `/productivity/npv/trend`;
export const npvRoute = `/productivity/npv`;

export const emPageRoute = `/productivity/em`;

export const emOutpatientRoute = `/productivity/em/outpatient`;
export const emOutpatientDepartmentRoute = `/productivity/em/outpatient/department`;
export const emOutpatientSpecialtyRoute = `/productivity/em/outpatient/specialty`;
export const emOutpatientProviderRoute = `/productivity/em/outpatient/provider`;
export const emOutpatientDepSpecialtyRoute = `/productivity/em/outpatient/department/specialty`;
export const emOutpatientDepProviderRoute = `/productivity/em/outpatient/department/provider`;

export const emInpatientRoute = `/productivity/em/inpatient`;
export const emInpatientDepartmentRoute = `/productivity/em/inpatient/department`;
export const emInpatientSpecialtyRoute = `/productivity/em/inpatient/specialty`;
export const emInpatientProviderRoute = `/productivity/em/inpatient/provider`;
export const emInpatientDepSpecialtyRoute = `/productivity/em/inpatient/department/specialty`;
export const emInpatientDepProviderRoute = `/productivity/em/inpatient/department/provider`;

export const emOphthalmologyRoute = `/productivity/em/ophthalmology`;
export const emOphthalmologyDepartmentRoute = `/productivity/em/ophthalmology/department`;
export const emOphthalmologySpecialtyRoute = `/productivity/em/ophthalmology/specialty`;
export const emOphthalmologyProviderRoute = `/productivity/em/ophthalmology/provider`;
export const emOphthalmologyDepProviderRoute = `/productivity/em/ophthalmology/department/provider`;
export const emOphthalmologyDepSpecialtyRoute = `/productivity/em/ophthalmology/department/specialty`;

export const emEmergencyMedicineRoute = `/productivity/em/emergencyMedicine`;
export const emEmergencyMedicineDepartmentRoute = `/productivity/em/emergencyMedicine/department`;
export const emEmergencyMedicineSpecialtyRoute = `/productivity/em/emergencyMedicine/specialty`;
export const emEmergencyMedicineProviderRoute = `/productivity/em/emergencyMedicine/provider`;
export const emEmergencyMedicineDepSpecialtyRoute = `/productivity/em/emergencyMedicine/department/specialty`;
export const emEmergencyMedicineDepProviderRoute = `/productivity/em/emergencyMedicine/department/provider`;

export const clinicalFingerPrintRoute =  `/productivity/cfp/snapshot`;

export const collectionsRoute = `/productivity/collections`;
export const collectionsSnapshotRoute = `/productivity/collections/snapshot`;
export const collectionsProviderPageRoute = `/productivity/collections/provider`;
export const collectionsSpecialtyPageRoute = `/productivity/collections/specialty`;
export const collectionsDepartmentPageRoute = `/productivity/collections/department`;
export const payerCollectionsRoute = `/productivity/collections/payer`;
export const payerCollectionsMultiLevelRoute = `/productivity/collections/payer`;
export const summaryCollectionsRoute = `/productivity/collections/summary`;

export const denialsRoute = `/productivity/denials`;
export const denialsSpecialtyPageRoute = `/productivity/denials/specialty`;
export const denialsDepartmentPageRoute = `/productivity/denials/department`;
export const denialsProviderPageRoute = `/productivity/denials/provider`;
export const denialsPayerPageRoute = `/productivity/denials/payer`;

export const procedureSummaryRoute = `/productivity/proceduresummary`;
export const featureConfigRoute = `/productivity/feature-toggle`;
export const complianceRoute = `/compliance`;
