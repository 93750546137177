import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';
import {BatchExportReportDetailsResponseEntity} from '../../../shared/models';
import {BatchExportScheduleExistingReportResponseEntity} from '../../multi-provider-export-redesign/mpe-dialog-helper';
import {ScheduledExportsTableComponent} from '../scheduled-exports-table/scheduled-exports-table.component';
import {
  childColumnsForScheduledTable,
  ExportedReportsDialogColumn,
  getColumnsForExportedOneTimeReportsDialog,
  parentColumnsForScheduledTable,
  ScheduledReportsDialogColumn
} from '../../batch-export-helper';
import {IAppState} from '../../../store/IAppState';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BatchExportServiceToken, HttpBatchExportService} from '../../services/batch-export.service';
import {countUnreadInstanceScheduledReports, DownloadError, UpdateStatusError} from '../exported-reports-dialog-helper';
import {isFeatureEnabled} from '../../../shared/helpers';
import {FeatureToggleEntries} from '../../../shared/feature-toggle-settings-enum';
import {take} from 'rxjs/operators';
import {
  BatchExportStatus,
  GoogleAnalyticCategories,
  GoogleAnalyticsActions,
  GoogleAnalyticsLabels
} from '../../../shared/enums';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {PostMpeDownloadComponent} from './post-mpe-download/post-mpe-download.component';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'app-exported-reports-redesign-dialog',
  templateUrl: './exported-reports-redesign-dialog.component.html',
  styleUrls: ['./exported-reports-redesign-dialog.component.scss']
})
export class ExportedReportsRedesignDialogComponent implements OnInit {

  @select(['data', 'batchExportReportDetails'])
  private readonly batchExportReportDetails$: Observable<BatchExportReportDetailsResponseEntity[]>;

  @select(['data', 'batchExportScheduleReportDetails'])
  private readonly batchExportScheduleReportDetails$: Observable<BatchExportScheduleExistingReportResponseEntity[][]>;

  @ViewChild(ScheduledExportsTableComponent) scheduledExportsTableComponent: ScheduledExportsTableComponent;

  BatchExportStatus = BatchExportStatus;
  columns: ExportedReportsDialogColumn[] = [];
  columnsForOneTimeTab: ExportedReportsDialogColumn[] = [];
  stringColumnsForRowDefForOneTimeTab: string[] = [];
  parentColumnsForScheduledTable: ScheduledReportsDialogColumn[];
  childColumnsForScheduledTable: ScheduledReportsDialogColumn[];
  stringColumnsForParentRowDefForScheduledTab: string[] = [];
  stringColumnsForChildRowDefForScheduledTab: string[] = [];
  dataSource: BatchExportReportDetailsResponseEntity[] = [];
  scheduledTabDataSource: BatchExportScheduleExistingReportResponseEntity[][] = [];
  cancelDeleteScheduledReportsEnabled: boolean;
  readonly oneTimeExportsLabel = 'One-Time Exports';
  readonly scheduledExportsLabel = 'Scheduled Exports';
  readonly generalTabClass = 'general-tab';
  unreadOneTimeReportsCount = 0;
  unreadScheduledReportsCount = 0;
  fileSubscription: Subscription;
  selectedTabIndex = 0;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              public dialogRef: MatDialogRef<ExportedReportsRedesignDialogComponent>,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.batchExportReportDetails$.subscribe((batchExportReportDetails: BatchExportReportDetailsResponseEntity[]) => {
      this.dataSource = batchExportReportDetails;
      this.unreadOneTimeReportsCount = batchExportReportDetails.filter(b => b.reportStatus === BatchExportStatus.COMPLETED
        && !b.readStatus).length;
    });
    this.batchExportScheduleReportDetails$.subscribe(
      (batchExportScheduleReportDetails: BatchExportScheduleExistingReportResponseEntity[][]) => {
        this.scheduledTabDataSource = batchExportScheduleReportDetails;
        this.unreadScheduledReportsCount = countUnreadInstanceScheduledReports(batchExportScheduleReportDetails);
      });
    const stateData = this.ngRedux.getState().data;
    this.cancelDeleteScheduledReportsEnabled =
      isFeatureEnabled(FeatureToggleEntries.CANCEL_DELETE_SCHEDULED_EXPORTS, stateData.featureToggleSettings, stateData.userProfile);
    this.setUpColumnArraysForTable();
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  private setUpColumnArraysForTable() {
    this.columnsForOneTimeTab = getColumnsForExportedOneTimeReportsDialog();
    this.stringColumnsForRowDefForOneTimeTab = this.columnsForOneTimeTab.map(c => c.columnDef);
    this.parentColumnsForScheduledTable = parentColumnsForScheduledTable(this.cancelDeleteScheduledReportsEnabled);
    this.childColumnsForScheduledTable = childColumnsForScheduledTable(this.cancelDeleteScheduledReportsEnabled);
    this.stringColumnsForParentRowDefForScheduledTab = this.parentColumnsForScheduledTable.map(c => c.columnDef);
    this.stringColumnsForChildRowDefForScheduledTab = this.childColumnsForScheduledTable.map(c => c.columnDef);
  }

  close() {
    this.dialogRef.close();
  }

  getReportFileAndUpdateReadStatus(report: BatchExportReportDetailsResponseEntity, isMonthly: boolean): void {
    const eventText = isMonthly ? 'Scheduled' : 'One-Time';
    this.analyticsService.handleGoogleAnalytics(
      `${GoogleAnalyticCategories.MyReports} Download`,
      `${eventText} ${GoogleAnalyticsLabels.ExportClick}`,
      `${eventText} ${GoogleAnalyticsActions.ExportDownload}`);
    this.dialog.open(PostMpeDownloadComponent, {});
    const path = report.path.replace(/\\/g, '/');
    this.fileSubscription?.unsubscribe();
    this.fileSubscription = this.batchExportService.getReportFile(path).subscribe(data => {
      this.batchExportService.updateReadStatus(report.reportId)
        .pipe(take(1))
        .subscribe(result => {
          if (!result) {
            console.error('ERROR updating exported file status to READ.');
            throw new UpdateStatusError(report.reportId);
          }

          const binaryData = [];
          binaryData.push(data);
          const link = URL.createObjectURL(new Blob(binaryData, {type: 'application/zip'}));

          const a: HTMLAnchorElement = document.createElement('a');
          a.href = link;
          a.download = report.reportName.replace(' ', '_') + '.zip';
          // this is necessary as link.click() does not work on the latest firefox
          a.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(link);
            a.remove();
          }, 100);
        }, () => {
          throw new DownloadError(report.reportId);
        });
    }, () => {
      throw new DownloadError(report.reportId);
    });
  }
}
