import {Inject, Injectable} from '@angular/core';
import {ComplianceServiceToken, HttpComplianceService} from './services/compliance-notification-service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {complianceRoute} from '../shared/routes';
import {environment} from '../../environments/environment.local';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';

@Injectable({
  providedIn: 'root'
})
export class ComplianceGuard implements CanActivate {
  constructor(
    @Inject(ComplianceServiceToken) private readonly complianceNoticeService: HttpComplianceService,
    private ngRedux: NgRedux<IAppState>,
    private router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
            Observable<boolean> | Promise<boolean> | boolean {

    const enableCompliance = !!environment.enableCompliance;
    if (!enableCompliance ) {
      return true;
    }
    const response = this.complianceNoticeService.getNotificationsAccepted();
    if (!response) {
      const requestId = this.ngRedux.getState().filters.externalRequestId;
      this.router.navigate([complianceRoute], requestId ? {queryParams: {requestId}} : undefined);
    }
    return response;
  }
}
