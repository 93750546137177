import {FilterCriteria} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.local';
import {getRequestBodyFromFilter} from '../../shared/helpers';
import {
  DenialCptCodeByReasonEntity,
  DenialCptCodeEntity,
  DenialReasonEntity,
  DenialsData,
  DenialsMultiLevelData,
  DenialsPayerData
} from '../denials-models';

export const DenialServiceToken = new InjectionToken<DenialsService>('Denials Service');

export interface DenialsService {
  getDenialsData(filterCriteria: FilterCriteria): Observable<DenialsPayerData>;
  getDenialsDataByReason(filterCriteria: FilterCriteria, sortOrderIndex: number): Observable<DenialsData<DenialReasonEntity>>;
  getDenialsDataByCpt(filterCriteria: FilterCriteria, sortOrderIndex: number): Observable<DenialsData<DenialCptCodeEntity>>;
  getDenialsDataByCptCodeByReason(filterCriteria: FilterCriteria): Observable<DenialsData<DenialCptCodeByReasonEntity>>;
  getDenialsDataByCptCodeByReasonAgg(filterCriteria: FilterCriteria): Observable<DenialsData<DenialCptCodeByReasonEntity>>;
  getDenialsMultilevelData(filterCriteria: FilterCriteria, dataset: number): Observable<DenialsMultiLevelData>;
}


@Injectable()
export class HttpDenialsService implements DenialsService {
  constructor(private httpClient: HttpClient) {}

  getDenialsData(filterCriteria: FilterCriteria): Observable<DenialsPayerData> {
    const baseUrl = `${environment.api}/api/getDenialsData`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<DenialsPayerData>(
      baseUrl,
      postBody,
      {params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())}
    );
  }

  getDenialsDataByReason(filterCriteria: FilterCriteria, sortOrderIndex: number): Observable<DenialsData<DenialReasonEntity>> {
    const baseUrl = `${environment.api}/api/getDenialsDataByReason`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<DenialsData<DenialReasonEntity>>(
      baseUrl,
      postBody,
      {
        params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())
          .set('sortOrderIndex', sortOrderIndex.toString())
      }
    );
  }

  getDenialsDataByCpt(filterCriteria: FilterCriteria, sortOrderIndex: number): Observable<DenialsData<DenialCptCodeEntity>> {
    const baseUrl = `${environment.api}/api/getDenialsDataByCpt`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<DenialsData<DenialCptCodeEntity>>(
      baseUrl,
      postBody,
      {
        params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())
          .set('sortOrderIndex', sortOrderIndex.toString())
      }
    );
  }

  getDenialsDataByCptCodeByReason(filterCriteria: FilterCriteria): Observable<DenialsData<DenialCptCodeByReasonEntity>> {
    const baseUrl = `${environment.api}/api/getDenialsDataByCptCodeByReason`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<DenialsData<DenialCptCodeByReasonEntity>>(
      baseUrl,
      postBody,
      {
        params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())
      }
    );
  }

  getDenialsDataByCptCodeByReasonAgg(filterCriteria: FilterCriteria): Observable<DenialsData<DenialCptCodeByReasonEntity>> {
    const baseUrl = `${environment.api}/api/getDenialsDataByCptCodeByReasonAgg`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<DenialsData<DenialCptCodeByReasonEntity>>(
      baseUrl,
      postBody,
      {
        params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())
      }
    );
  }

  getDenialsMultilevelData(filterCriteria: FilterCriteria, dataset: number): Observable<DenialsMultiLevelData> {
    const baseUrl = `${environment.api}/api/getDenialsDataByMultiLevel`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<DenialsMultiLevelData>(
      baseUrl,
      postBody,
      { params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString()).set('dataset', dataset.toString())},
    );
  }
}
