import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {getLevel, getPreviousYearDateRange, nodeExpansionIsActive} from '../../shared/helpers';
import {AnalyticsService, AnalyticsServiceToken} from '../../analytics/analytics.service';
import {FilterCriteriaTableView, SortingOrder} from '../../shared/enums';
import {ListItemExpansion, SortingCriterion} from '../../shared/models';

@Component({
  selector: 'app-filter-criteria-table',
  templateUrl: './filter-criteria-table.component.html',
  styleUrls: ['./filter-criteria-table.component.scss'],
})
export class FilterCriteriaTableComponent<T> implements OnChanges {
  @Input() tableTitle: string;
  @Input() receivedData: any[] = [];
  @Input() columns: any[] = [];
  @Input() whenSortChanged: (sortedRows: any) => void;
  @Input() tieBreakerProperty?: (element: T) => string;
  @Input() rowSelectionCallback?: (element: T, event?: MouseEvent) => void;
  @Input() rowExpansionCollapsing?: (element: T) => void;
  @Input() updateAllContent?: (selected: boolean, event?: MouseEvent) => void;
  @Input() showLevel = true;
  @Input() showProgressBar = false;
  @Input() sortingDataAccessor?: any;
  @Input() paginate = true;
  @Input() tableCssClass: string;
  @Input() currentPage: string;
  @Input() displayMessages = [{
    columnDef: '',
    desc: ''
  }];
  @Input() indeterminate: boolean;
  @Input() displayLoadingIndicator = false;
  @Input() shouldDisplayTheHeaders = false;
  @Input() headerTitle = '';
  @Input() allOfContentsChecked: boolean;
  @Input() indeterminateContentsChecked: boolean;
  @Input() permanentSelectedStatus = false;
  @Input() shouldShowSearchBar = false;
  @Input() highlightedSearchText: string | undefined = undefined;
  @Input() emptinessIndicationMessage: string | undefined = undefined;
  @Input() tableView: FilterCriteriaTableView = FilterCriteriaTableView.ALL;

  @Output() pageEvent = new EventEmitter<PageEvent>();
  @Output() finishLoading = new EventEmitter<boolean>();
  @Output() sortEvent = new EventEmitter<[SortingCriterion, FilterCriteriaTableView]>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;
  @select(['display', 'isCustom'])
  private readonly isCustom$: Observable<boolean>;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[];
  level: string;
  previousDate: string;
  numberOfRowsShown = 0;
  sortDirection: SortingOrder | undefined = undefined;
  sortColumn: string | undefined = undefined;

  NUMBER_TO_LOAD = 50;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
  ) {
  }

  ngOnChanges(changes?: SimpleChanges) {

    if (this.receivedData) {
      const nodePath: string = this.ngRedux.getState().filters.nodePath;
      const isCustom: boolean = this.ngRedux.getState().display.isCustom;
      const multipleProviders = this.ngRedux.getState().display.multipleProviders;
      const multipleSpecialties = this.ngRedux.getState().display.multipleSpecialties;
      const multipleDepartments = this.ngRedux.getState().display.multipleDepartments;
      this.level = this.showLevel ? getLevel(isCustom, nodePath, multipleProviders, multipleSpecialties,
        multipleDepartments) : '';
      this.previousDate = getPreviousYearDateRange(this.ngRedux.getState().filters.dateRange);
      if (!changes || this.shouldAddMoreRows(changes)) {
        this.getData(Math.min(this.NUMBER_TO_LOAD, this.receivedData.length));
      }
    }
  }

  shouldAddMoreRows(changes: SimpleChanges): boolean {
    return (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length <= this.NUMBER_TO_LOAD)
      || !(changes['receivedData'] && changes['receivedData'].previousValue && changes['receivedData'].currentValue &&
        (changes['receivedData'].previousValue.length > changes['receivedData'].currentValue.length) &&
        changes['receivedData'].currentValue.length > 0);
  }

  handleScroll(event?: any) {
    if (this.dataSource.data.length < this.receivedData.length) {
      const table: any | undefined = event ? event.target : undefined;
      if (table && (table.scrollTop > 0.9 * (table.scrollHeight - table.clientHeight))) {
        this.getData(this.dataSource.data.length + this.NUMBER_TO_LOAD);
      }
    }
  }

  getData(numberOfRows?: number) {
    if (!numberOfRows) {
      if (!this.dataSource) {
        numberOfRows = this.NUMBER_TO_LOAD;
      } else {
        numberOfRows = this.numberOfRowsShown;
      }
    }
    if (this.receivedData) {
      this.dataSource = new MatTableDataSource<any>(
        this.receivedData.slice(0, numberOfRows)
      );
      this.displayedColumns = this.columns.map(x => x.columnDef);
      this.numberOfRowsShown = Math.max(this.dataSource.data.length, this.numberOfRowsShown);
      if (this.receivedData.length >= 1) {
        this.finishLoading.emit(true);
      }
    }
  }

  getWhatShouldBeHighlighted(text: string): string[] {
    if (this.highlightedSearchText) {
      const matches = text.toLowerCase().includes(this.highlightedSearchText.toLowerCase());
      const highlightedLength = matches ? this.highlightedSearchText.length : 0;
      const indexOf = text.toLowerCase().indexOf(this.highlightedSearchText.toLowerCase());
      return matches ? [text.substring(0, indexOf), text.substring(indexOf, indexOf + highlightedLength),
        text.substring(indexOf + highlightedLength)] : ['', '', text];
    }
    return ['', '', ''];
  }

  getNodeRowClass(expansionState: ListItemExpansion, columnDef: string): string {
    if (expansionState) {
      switch (this.tableCssClass) {
        case 'payer-table':
        case 'payer-table-original-filter':
          switch (expansionState.depth) {
            case 0:
              return 'payer-category';
            case 1:
              return 'national-payer';
            case 2:
              return 'member-payer';
          }
      }
    } else {
      switch (this.tableCssClass) {
        case 'provider-table':
        case 'provider-table-divided':
          return columnDef + '-list-item';
      }
    }
    return '';
  }

  getCssClassBasedOnDepth(expansionState: ListItemExpansion): string {
    let css = '';
    if (!expansionState) {
      return css;
    }
    switch (this.tableCssClass) {
      case 'payer-table':
      case 'payer-table-original-filter':
        switch (expansionState.depth) {
          case 0:
            css = 'payer-category-cell';
            break;
          case 1:
            css = 'national-payer-cell';
            break;
          case 2:
            css = 'member-payer-cell';
            break;
        }
        return nodeExpansionIsActive(expansionState.state) ? css + ' activeSelection' : css;
      default:
        return css;
    }
  }

  getCssClassBasedOnSelectedState(selected?: boolean): string {
    switch (this.tableCssClass) {
      case 'memberBillingAreaTable':
        return selected ? 'billingArea-option-selected' : 'billingArea-option-unselected';
      case 'location-table':
        return !this.allOfContentsChecked && selected ? 'activeSelection' : 'location-option-unselected';
      default:
        return '';
    }
  }

  changeHeaderCheck(event: MouseEvent): void {
    event.preventDefault();
    if (!this.permanentSelectedStatus) {
      if (this.indeterminateContentsChecked || this.allOfContentsChecked) {
        this.indeterminateContentsChecked = false;
        this.allOfContentsChecked = false;
      } else {
        this.indeterminateContentsChecked = false;
        this.allOfContentsChecked = true;
      }
      if (this.updateAllContent) {
        this.updateAllContent(this.indeterminateContentsChecked || this.allOfContentsChecked, event);
      }
    } else if (this.updateAllContent) {
      this.updateAllContent(!this.allOfContentsChecked, event);
    }
  }

  selectRow(selectedRow: T, event?: MouseEvent) {
    return this.rowSelectionCallback && this.rowSelectionCallback(selectedRow, event);
  }

  manuallySelectRow(selectedRow: T, event?: MouseEvent) {
    switch (this.tableView) {
      case FilterCriteriaTableView.SELECTED:
      case FilterCriteriaTableView.NONSELECTED:
        const index = this.dataSource.data.indexOf(selectedRow);
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
        this.selectRow(selectedRow, event);
        break;
      default:
        this.selectRow(selectedRow, event);
    }
  }

  updateSortingDirection(columnDef: string): void {
    let order: SortingOrder | undefined;
    switch (this.sortDirection) {
      case SortingOrder.ASCENDING:
        order = SortingOrder.DESCENDING;
        break;
      case SortingOrder.DESCENDING:
        order = undefined;
        break;
      case undefined:
        order = SortingOrder.ASCENDING;
        break;
    }
    this.sortDirection = order;
    this.sortColumn = columnDef;
    if (order) {
      const sortingCriterion: SortingCriterion = {
        sortingOrder: order,
        columnDef: columnDef
      };
      this.sortEvent.emit([sortingCriterion, this.tableView]);
    }
  }

  expandOrCollapseRow(selectedRow: T, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    return this.rowExpansionCollapsing && this.rowExpansionCollapsing(selectedRow);
  }
}
