
<agm-map
  *ngIf="mapData && mapData.length > 0 && fromPdf"
  id="zipCodeMapData"
  class="agm-map"
  style="height:575px;width:880px"
  [zoom]="9"
  [fitBounds]="true"
  #AgmMap
>
  <agm-marker *ngFor="let data of mapData;"
              [label]="data.id+''"
              [latitude]="+data.lat"
              [longitude]="+data.lng"
              [agmFitBounds]="true"
              (markerClick)="clickedMarker(data, infoWindow)"
              [zIndex]="0"
  >
    <agm-info-window #infoWindow>
      <strong *ngIf="selectedMarker">{{selectedMarker.zipCode}}: {{selectedMarker.newPatientVisitPercentage}}</strong>
    </agm-info-window>
  </agm-marker>
</agm-map>

<agm-map
  *ngIf="!mapData || mapData.length === 0"
  id="zipCodeMapNoData"
  class="agm-map"
  style="height:540px;width:880px"
  [zoom]="4"
  [latitude]="37.0902"
  [longitude]="-95.7129"
  #AgmMap
>
</agm-map>
