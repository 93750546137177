<div>
  <button id="showColumns" (click)="showOrHideColumns()">Add/Remove Columns</button>

  <div *ngIf="showAdditionalColumns" class="showAdditionalColumns">
  <span class="select-all-columns"
        (click)="selectOrDeselectAllColumns($event)"><mat-checkbox [checked]="allColumnsSelected"
                      [indeterminate]="indeterminateColumns"
  >All Columns</mat-checkbox>
</span>
    <div *ngFor="let column of allColumns" class="columns-option-outer-wrapper">
     <span *ngIf="(showBenchmarks || (column.columnType !== BENCHMARK && column.columnType !== VARIANCE && column.columnType !== IMPUTED))
         && (showCfteAdjWrvus || (column.columnType !== CFTE_ADJ_WRVU && column.columnType !== PREVIOUS && column.columnType !== DIFFERENCE))">
        <span class="columns-item-wrapper" (click)="selectColumn(column,$event)">
          <mat-checkbox [checked]="column.selected">{{column.header}}</mat-checkbox>
        </span>
      </span>
    </div>
  </div>
</div>
