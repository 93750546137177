import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-batch-export-message',
  templateUrl: './batch-export-message.component.html',
  styleUrls: ['./batch-export-message.component.scss']
})
export class BatchExportMessageComponent implements OnInit {

  numberOfFiles: number;

  constructor(public dialogRef: MatDialogRef<BatchExportMessageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: number) { }

  ngOnInit() {
    this.numberOfFiles = this.data;
  }

  confirm(): void {
    this.dialogRef.close();
  }

}
