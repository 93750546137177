import {Component, Input, OnChanges} from '@angular/core';
import {MonthNewPatientVisitData, MonthNewPatientVisitEntry} from '../../../new-patient-visits/components/npv-models';
import {
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  StyledChart
} from '../../../shared/highcharts-helpers';
import {getBenchmarkSeriesName} from '../../../shared/helpers';
import {concat} from '../../../shared/ourLodash';
import {CHART_HEIGHT} from '../../../productivity-summary/productivity-graph-component';
import {GraphColors} from '../../../productivity-summary/colors';
import {checkForNulls, hasValue} from '../../../shared/null-helpers';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../../shared/benchmark-types';
import {Chart} from 'angular-highcharts';
import {Options, YAxisOptions} from 'highcharts/highstock';
import {abbreviateAxisValue, roundToNumber} from '../../../productivity-summary/number-formatter';
import {toAbbreviatedMonthName} from '../../../productivity-summary/month-formatter';
import {MetricType} from '../../../shared/metric-types';
import {BenchmarkOption, HighChartColorIndex, LegendColor, LegendStyle} from '../../../shared/enums';
import {Legend} from '../../../shared/models';
import {getDesignatedNpvBenchmarkObject} from '../../../new-patient-visits/components/npv-helpers';

@Component({
  selector: 'app-npv-month-batch-pdf',
  templateUrl: './npv-month-batch-pdf.component.html',
  styleUrls: ['./npv-month-batch-pdf.component.scss']
})
export class NpvMonthBatchPdfComponent implements OnChanges {
  @Input() data: MonthNewPatientVisitData;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  private newPatientVisitTrendEntries: MonthNewPatientVisitEntry[];
  private patientVisitCountSeries: any[];
  private snapshotBarCount = 20;
  private chartWidth = calculateChartWidth(DEFAULT_POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING);
  options: Options;
  chartObject: Chart;
  hasBenchmarks = true;
  benchmarkToExclude = ['65th Percentile'];
  npvLegends: Legend[] = [
    {
      text: '% New Patients',
      color: LegendColor.GREEN,
      metric: MetricType.NewPatientVisits,
      style: LegendStyle.CIRCLE
    },
    {
      text: '# New Patients',
      color: LegendColor.TEAL,
      metric: MetricType.NewPatientVisits,
      style: LegendStyle.SQUARE
    },
    {
      text: '# Total Patients',
      color: LegendColor.PURPLE,
      metric: MetricType.NewPatientVisits,
      style: LegendStyle.SQUARE
    },
    {
      text: '% NPV Benchmark:',
      color: LegendColor.GREY,
      metric: MetricType.NewPatientVisits,
      style: LegendStyle.DIAMOND,
      showBenchmarkOptionControl: true,
      showPercentileControl: true
    }
  ];

  readonly metricType = MetricType;
  constructor() { }

  ngOnChanges(): void {
    if (hasValue(this.data)) {
      this.newPatientVisitTrendEntries = this.data.monthNewPatientVisitCounts;
      this.drawGraph();
    }
  }

  drawGraph(): void {
    const newPatientVisitsCountsForChart = this.newPatientVisitTrendEntries.map((newPatientVisit) => {
      return {drilldown: '', y: newPatientVisit.countOfNewPatientVisits || 0};
    });
    const totalPatientVisitsCountsForChart = this.newPatientVisitTrendEntries.map((newPatientVisit) => {
      return {drilldown: '', y: hasValue(newPatientVisit.countOfTotalPatientVisits) &&
      hasValue(newPatientVisit.countOfTotalPatientVisits) ? (newPatientVisit.countOfTotalPatientVisits -
          newPatientVisit.countOfNewPatientVisits) : 0 };
    });
    const monthNewPatientVisits = this.newPatientVisitTrendEntries.map((newPatientVisit) => {
      return {drilldown: '', y: newPatientVisit.newPatientVisitsPercentage || 0};
    });

    const designatedBenchmarkObject = getDesignatedNpvBenchmarkObject(this.benchmarkOption);
    const benchmarkData = this.newPatientVisitTrendEntries.map((npv: MonthNewPatientVisitEntry) =>
      roundToNumber(checkForNulls(
        npv[designatedBenchmarkObject][`benchmark${readableNameOfColumnDef(this.benchmarkPercentile)}`]) * 100, 1));
    this.hasBenchmarks = !!this.newPatientVisitTrendEntries.find(x => !!x.newPatientVisitsPercentageBenchmarks.benchmarkMean);
    this.patientVisitCountSeries = [
      {
        name: '# Total Patients',
        yAxis: 1,
        type: ChartType.COLUMN,
        pointWidth: DEFAULT_POINT_WIDTH,
        data: totalPatientVisitsCountsForChart.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(totalPatientVisitsCountsForChart, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          totalPatientVisitsCountsForChart,
        stacking: 'normal',
        colorIndex: HighChartColorIndex.PURPLE,
        stack: 3,
        zIndex: 0,
        tooltip: {
          pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.total:,.0f}</b><br/>',
          valueDecimals: 0
        }
      },
      {
        name: '# New Patients',
        yAxis: 1,
        type: ChartType.COLUMN,
        pointWidth: DEFAULT_POINT_WIDTH,
        data: newPatientVisitsCountsForChart.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(newPatientVisitsCountsForChart, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          newPatientVisitsCountsForChart,
        stacking: 'normal',
        colorIndex: HighChartColorIndex.TEAL,
        stack: 3,
        zIndex: 0,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      },
    ];

    const barChartSeriesOptions: any[] = [
      {
        name: '% New Patients',
        yAxis: 0,
        data: monthNewPatientVisits.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(monthNewPatientVisits, DEFAULT_MAX_NUMBER_SCROLLBARS) :
          monthNewPatientVisits,
        type: ChartType.LINE,
        colorIndex: HighChartColorIndex.GREEN,
        pointWidth: DEFAULT_POINT_WIDTH,
        stack: 0,
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-12">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
          valueDecimals: 0
        }
      },
      {
        name: getBenchmarkSeriesName(this.benchmarkOption, true, this.benchmarkPercentile),
        colorIndex: HighChartColorIndex.GREY,
        yAxis: 0,
        data: benchmarkData.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
        createEmptyValues(benchmarkData, DEFAULT_MAX_NUMBER_SCROLLBARS) :
        benchmarkData,
        type: ChartType.LINE,
        stack: 1,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.DIAMOND
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
          valueDecimals: 0
        }
      },
    ];

    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% New Patients',
        },
        max: 100,
        min: 0,
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
      },
    ];

    const rightSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '# New and Total Patients',
        },
        opposite: true,
        max: Math.max(...this.newPatientVisitTrendEntries.map(npv => npv.countOfTotalPatientVisits || 0)),
        min: 0,
        labels: {
          formatter: function () {
            return abbreviateAxisValue(this.value);
          },
        }
      },
    ];
    const categories =
      this.newPatientVisitTrendEntries.map((visit) =>
        toAbbreviatedMonthName(visit.month.toString()) + ' ' + visit.year);
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;
    this.options = {
      scrollbar: {
        enabled: isScrolled,
        showFull: false,
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      series: concat(barChartSeriesOptions, this.patientVisitCountSeries),
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.chartWidth,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        reversed: true,
        crosshair: {
          color: GraphColors.hoverBackground,
        },
        max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS - 1 : categories.length - 1,
        labels: {
          rotation: 45,
        },
      },
      yAxis: concat(leftSideYAxis, rightSideYAxis),
    };
    this.chartObject = new StyledChart(this.options);
  }
}
