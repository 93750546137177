<div id="page-wrapper">
  <mat-tab-group mat-align-tabs="center" mat-stretch-tabs="false">
    <mat-tab label="Features">
      <app-feature-toggle-control-panel></app-feature-toggle-control-panel>
    </mat-tab>

    <mat-tab label="Configurations" data-testid="secondTab">
      <app-app-config-control-panel></app-app-config-control-panel>
    </mat-tab>

  </mat-tab-group>
</div>
