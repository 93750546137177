import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.local';
import {HttpClient, HttpParams} from '@angular/common/http';
import {getBatchRequestBodyFromFilter, getRequestBodyFromBatchFilter} from '../../shared/test/helper-functions.spec';
import {
  BatchExportDetailCriteria,
  BatchExportDetailResponse,
  BatchExportDetails,
  BatchExportDetailsResponse,
  BatchExportReportDetailsResponse,
  BatchExportSummaryResponse,
  BatchFilterCriteria,
  CptCategories,
  EvaluationManagement,
  EvaluationManagementMultilevel,
  MonthProductivityData,
  ProviderMultilevelCptCounts,
  ReturnedNewPatientVisitSummary,
  ZipCodeNewPatientVisits
} from '../../shared/models';
import {SummaryType, translateTelehealthFlagForBackEnd, translateTelehealthFlagForFrontEnd} from '../../shared/helpers';
import {MonthNewPatientVisitData} from '../../new-patient-visits/components/npv-models';
import {map} from 'rxjs/operators';
import {mapTrendToMultiLevel} from '../../evaluation-management/services/evaluation-management.service';
import {
  BatchExportScheduleCriteria,
  BatchExportScheduleExistingReportsResponse,
  BatchExportScheduleResponse
} from '../multi-provider-export-redesign/mpe-dialog-helper';

export const BatchExportServiceToken =
  new InjectionToken<BatchExportService>('Batch Export Service');

export interface BatchExportService {

  saveBatchExportInformation(batchExportCriteria: BatchExportDetailCriteria, memberKey: number): Observable<BatchExportDetailsResponse>;

  saveBatchExportScheduleInformation(batchExportScheduleCriteria: BatchExportScheduleCriteria, memberKey: number):
    Observable<BatchExportScheduleResponse>;

  getBatchExportSummaryStatus(email: string, memberKey: number): Observable<BatchExportSummaryResponse>;

  deleteBatchExportInformation(reportId: number): Observable<number>;

  // acknowledgeBatchExportNotifications(email: string, memberKey: number): Observable<boolean>;
  updateReadStatus(reportId: number): Observable<boolean>;

  getNewPatientVisitTrendData(batchFilter: BatchFilterCriteria, summaryType: SummaryType): Observable<MonthNewPatientVisitData>;

  getNewPatientVisitSummaryData(batchFilter: BatchFilterCriteria, summaryType: number): Observable<ReturnedNewPatientVisitSummary>;

  getWrvuTrendData(batchFilterCriteria: BatchFilterCriteria, summaryType: SummaryType): Observable<MonthProductivityData>;

  getNpvZipData(batchFilterCriteria: BatchFilterCriteria): Observable<ZipCodeNewPatientVisits[]>;

  getEmData(batchFilterCriteria: BatchFilterCriteria): Observable<EvaluationManagementMultilevel>;

  getEmTrendData(batchFilterCriteria: BatchFilterCriteria): Observable<EvaluationManagement>;

  getMultilevelProviderEvaluationManagementMonthly(batchFilterCriteria: BatchFilterCriteria):
    Observable<{ nodes: ProviderMultilevelCptCounts[], totals: CptCategories }>;

  getBatchExportDetailsByDetailId(batchExportDetailId: number): Observable<BatchExportDetailResponse>;

  getScheduledBatchExportsByUser(memberKey: number): Observable<BatchExportScheduleExistingReportsResponse>;

  getReportFile(path: string): Observable<Blob>;

  isValidBatchRequest(reportId: number, mail: string, guid: string): Observable<boolean>;
}

@Injectable()
export class HttpBatchExportService implements BatchExportService {

  baseUrl = `${environment.api}/batchExport/`;

  constructor(private httpClient: HttpClient) {}

  getNewPatientVisitTrendData(batchFilterCriteria: BatchFilterCriteria, summaryType: SummaryType): Observable<MonthNewPatientVisitData> {
    const requestBody = getRequestBodyFromBatchFilter({
      ...batchFilterCriteria, telehealthFlag: translateTelehealthFlagForBackEnd(batchFilterCriteria.telehealthFlag)
    });
    const url = `${this.baseUrl}monthNewPatientVisitsByNodePath`;
    return this.httpClient.post<MonthNewPatientVisitData>(
      url,
      requestBody,
      {
        params: new HttpParams()
          .set('memberKey', batchFilterCriteria.memberKey.toString())
          .set('npvViewType', summaryType.toString())
      }
    );
  }

  getNewPatientVisitSummaryData(filter: BatchFilterCriteria, summaryType: number): Observable<ReturnedNewPatientVisitSummary> {
    const requestBody = getRequestBodyFromBatchFilter({
      ...filter,
      telehealthFlag: translateTelehealthFlagForBackEnd(filter.telehealthFlag)
    });
    const url = `${this.baseUrl}newPatientVisitsSummary`;
    return this.httpClient.post<ReturnedNewPatientVisitSummary>(
      url,
      requestBody,
      {
        params:
          new HttpParams()
            .set('memberKey', filter.memberKey.toString())
            .set('summaryType', summaryType.toString())
      },
    );
  }

  getWrvuTrendData(batchFilterCriteria: BatchFilterCriteria, summaryType: SummaryType): Observable<MonthProductivityData> {
    const body = getRequestBodyFromBatchFilter({
      ...batchFilterCriteria,
      telehealthFlag: translateTelehealthFlagForBackEnd(batchFilterCriteria.telehealthFlag)
    });
    const url = `${this.baseUrl}monthProductivitiesByNodePath`;
    return this.httpClient.post<MonthProductivityData>(
      url,
      body,
      {
        params: new HttpParams()
          .set('memberKey', batchFilterCriteria.memberKey.toString())
          .set('summaryType', summaryType.toString())
      }
    );
  }

  getNpvZipData(batchFilterCriteria: BatchFilterCriteria): Observable<ZipCodeNewPatientVisits[]> {
    const requestBody = getRequestBodyFromBatchFilter({
      ...batchFilterCriteria,
      telehealthFlag: translateTelehealthFlagForBackEnd(batchFilterCriteria.telehealthFlag)
    });
    const url = `${this.baseUrl}newPatientVisitsByZipCode`;
    return this.httpClient.post<ZipCodeNewPatientVisits[]>(
      url,
      requestBody,
      {params: new HttpParams().set('memberKey', batchFilterCriteria.memberKey.toString())}
    );
  }

  getEmData(batchFilterCriteria: BatchFilterCriteria): Observable<EvaluationManagementMultilevel> {
    const requestBody = getRequestBodyFromBatchFilter({
      ...batchFilterCriteria,
      telehealthFlag: translateTelehealthFlagForBackEnd(batchFilterCriteria.telehealthFlag)
    });
    const url = `${this.baseUrl}evaluationManagementByMultiLevelNodePath`;
    return this.httpClient.post<EvaluationManagementMultilevel>(
      url,
      requestBody,
      {params: new HttpParams().set('memberKey', batchFilterCriteria.memberKey.toString())}
    );
  }

  getEmTrendData(batchFilterCriteria: BatchFilterCriteria): Observable<EvaluationManagement> {
    const requestBody = getRequestBodyFromBatchFilter({
      ...batchFilterCriteria,
      telehealthFlag: translateTelehealthFlagForBackEnd(batchFilterCriteria.telehealthFlag)
    });
    const url = `${this.baseUrl}evaluationManagementTrendByNodePath`;
    return this.httpClient.post<EvaluationManagement>(
      url,
      requestBody,
      {params: new HttpParams().set('memberKey', batchFilterCriteria.memberKey.toString())}
    );
  }

  getMultilevelProviderEvaluationManagementMonthly(filterCriteria: BatchFilterCriteria):
    Observable<{ nodes: ProviderMultilevelCptCounts[], totals: CptCategories }> {
    return this.getEmTrendData(filterCriteria).pipe(map((data: EvaluationManagement) => mapTrendToMultiLevel(data)));
  }

  saveBatchExportInformation(batchExportCriteria: BatchExportDetailCriteria, memberKey: number): Observable<BatchExportDetailsResponse> {
    const baseUrl = `${environment.api}/api/batchExport/saveBatchExportInformation`;
    const postBody = getBatchRequestBodyFromFilter({
      ...batchExportCriteria,
      telehealthFlag: translateTelehealthFlagForBackEnd(batchExportCriteria.telehealthFlag)
    });
    return this.httpClient.post<BatchExportDetailsResponse>(
      baseUrl,
      postBody,
      {params: new HttpParams().set('memberKey', memberKey.toString())}
    ).pipe(map((data) => {
      data.batchExportDetails.forEach((b: BatchExportDetails) =>
        b.telehealthFlag = translateTelehealthFlagForFrontEnd(b.telehealthFlag));
      return data;
    }));
  }

  saveBatchExportScheduleInformation(batchExportScheduleCriteria: BatchExportScheduleCriteria,
                                     memberKey: number): Observable<BatchExportScheduleResponse> {
    const baseUrl = `${environment.api}/api/batchExport/saveBatchExportScheduleData`;
    const postBody = {
      ...batchExportScheduleCriteria,
      telehealthFlag: translateTelehealthFlagForBackEnd(batchExportScheduleCriteria.telehealthFlag)
    };
    return this.httpClient.post<BatchExportScheduleResponse>(
      baseUrl,
      postBody,
      {params: new HttpParams().set('memberKey', memberKey.toString())}
    ).pipe(map((data) => {
      data.batchExportScheduleDetails.forEach((b: BatchExportScheduleCriteria) =>
        b.telehealthFlag = translateTelehealthFlagForFrontEnd(b.telehealthFlag));
      return data;
    }));
  }

  deleteBatchExportInformation(reportId: number): Observable<number> {
    const baseUrl = `${environment.api}/api/batchExport/deleteBatchExportInformation`;
    return this.httpClient.post<number>(
      baseUrl, {},
      {params: new HttpParams().set('reportId', reportId.toString())}
    );
  }

  getBatchExportSummaryStatus(email: string, memberKey: number): Observable<BatchExportSummaryResponse> {
    const baseUrl = `${environment.api}/api/batchExport/getBatchExportSummaryStatus`;
    return this.httpClient.get<BatchExportSummaryResponse>(
      baseUrl, {
        params: new HttpParams()
          .set('userEmailId', email)
          .set('memberKey', memberKey.toString())
      }
    );
  }

  updateReadStatus(reportId: number): Observable<boolean> {
    const baseUrl = `${environment.api}/api/batchExport/updateReadStatus`;
    return this.httpClient.post<boolean>(
      baseUrl, {}, {params: new HttpParams().set('reportId', reportId.toString())}
    );
  }

  getBatchExportDetailsByDetailId(batchExportDetailId: number): Observable<BatchExportDetailResponse> {
    const url = `${this.baseUrl}getBatchExportDetailsByDetailId?batchExportDetailId=${batchExportDetailId}`;
    return this.httpClient.get<BatchExportDetailResponse>(
      url, {}
    ).pipe(map((data) => {
      data.batchExportDetails.forEach((b: BatchExportDetails) =>
        b.telehealthFlag = translateTelehealthFlagForFrontEnd(b.telehealthFlag));
      return data;
    }));
  }

  getBatchExportReportDetailsByUser(memberKey: number): Observable<BatchExportReportDetailsResponse> {
    const url = `${environment.api}/api/batchExport/getBatchExportReportDetailsByUser?memberKey=${memberKey}`;
    return this.httpClient.get<BatchExportReportDetailsResponse>(
      url, {}
    );
  }

  getScheduledBatchExportsByUser(memberKey: number): Observable<BatchExportScheduleExistingReportsResponse> {
    const url = `${environment.api}/api/batchExport/getScheduledBatchExportsByUser?memberKey=${memberKey}`;
    return this.httpClient.get<BatchExportScheduleExistingReportsResponse>(
      url, {}
    );
  }

  getReportFile(path: string): Observable<Blob> {
    const url = `${environment.api}/api/batchExport/report?path=${path}`;
    return this.httpClient.get(url, {responseType: 'blob'});
  }

  isValidBatchRequest(reportId: number, mail: string, guid: string): Observable<boolean> {
    const url = `${environment.api}/batchExport/isValidBatchRequest?reportId=${reportId}&email=${mail}&guid=${guid}`;
    return this.httpClient.get<boolean>(url, {});
  }
}
