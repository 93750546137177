<div class="procedure-summary-cpt-filter">
  <div class="filter-header">
    <header id="top-header"><h2 class="header-text">Procedure Summary CPT Filter</h2></header>
      <div class="banner-class">
        <header>
         <span class="listing">{{getSelectedCountDisplay(familySelectedCount, listOfCptFamilies)}}
          {{familySelectedCount !== 1 ? pluralizeText('Family') : 'Family'}} / {{getSelectedCountDisplay(rangeSelectedCount, listOfCptRanges)}}
          {{rangeSelectedCount !== 1 ? pluralizeText('Range') : 'Range'}} / {{getSelectedCountDisplay(codeSelectedCount, listOfCptCodes)}}
          {{codeSelectedCount !== 1 ? pluralizeText('Code') : 'Code'}}</span>
        <app-button-cpt-groupings [currentSelectedGroup]="currentSelectedGroup"
          [cptGroups]="customGroups" (selectGroup)="selectGroup(this, $event)"></app-button-cpt-groupings>
        </header>
      </div>
  </div>
  <span id="cpt-tables-wrapper">
    <span id="left-table-wrapper" class="table-wrapper">
      <div id="available-cpt-table-title" class="table-title">CPT Codes</div>
      <span id="family-range-wrapper">
        <span class="family-range-element">
          <app-search-cpt-family [cptFamilies]="listOfCptFamilies" [rangesAlsoShown]="showCptRanges"
          (afterCptFamiliesUpdated)="whenCptFamiliesUpdated($event)" [whenToggled]="whenCptFamiliesToggled">
          </app-search-cpt-family>
          <span class="available-column-header">
            <mat-checkbox class="header-check" id="select-all" [checked]="false" (click)="selectAllCpts($event)">
            </mat-checkbox>{{columnsForAvailableCptCodes[0].header}}
          </span>
        </span>
        <span class="family-range-element">
          <app-search-cpt-range [listOfCptRanges]="applicableRanges" (afterCptRangesUpdated)="whenCptRangesUpdated($event)"
          [whenToggled]="whenCptRangesToggled" [familiesAlsoShown]="showCptFamilies">
          </app-search-cpt-range><span class="available-column-header1">{{columnsForAvailableCptCodes[1].header}}</span>
        </span>
        <span class="family-range-element">
          <div class="search-container">
            <input type="text" placeholder="Cpt Code Search..." id="search-for"
                 (input)="onSearchTextChanged()" [(ngModel)]="searchText">
            <div class="icon-container">
              <mat-icon *ngIf="searchText.length" id="clear-search-txt" class="icon-size" aria-hidden="false" fontIcon="close"
                        (click)="clearSearchText()"></mat-icon>
              <mat-icon *ngIf="!searchText.length" id="search-symbol" class="icon-size" aria-hidden="false" fontIcon="search"
                        [matTooltip]="'3 letters required'" [matTooltipPosition]="'above'" [matTooltipDisabled]="false && searchText.length >= 1"></mat-icon>
            </div>
          </div><span class="available-column-header2">{{columnsForAvailableCptCodes[2].header}}</span>
        </span>
      </span>
      <br><hr>
      <mat-table [dataSource]="dataSourceAvailableCptCodes" (scroll)="handleScrollAvailableCodes($event)">
        <ng-container *ngFor="let column of columnsForAvailableCptCodes; let i = index" [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef> {{column.header}} </mat-header-cell>
          <mat-cell *matCellDef="let row" class="{{column.columnDef}} available-cpt-data-name">
            <ng-container *ngIf="i"><span class="available-cpt-data-name">{{column.dataName(row)}}</span></ng-container>
            <ng-container *ngIf="!i">
              <mat-checkbox class="individual-code-checkbox-available" [checked]="false" (click)="whenCptCodeSelected($event, row)">
                <span class="rest-of-cpt-name">{{column.dataName(row, searchText)[0]}}</span>
                <span class="cpt-is-searched-by">{{column.dataName(row, searchText)[1]}}</span>
                <span class="rest-of-cpt-name">{{column.dataName(row, searchText)[2]}}</span>
              </mat-checkbox>
            </ng-container>
          </mat-cell>
        </ng-container>
        <ng-container><mat-row *matRowDef="let row; columns: displayedAvailableCptCodeColumns"></mat-row></ng-container>
      </mat-table>
      <div *ngIf="dataSourceAvailableCptCodes.data.length == 0" class="empty-text-container">
        <span class="empty-text-message">Select a CPT Family or CPT Range to view CPT Codes.</span>
      </div>
    </span>
    <span id="right-table-wrapper" class="table-wrapper">
       <div class="table-title title-container">
         <div>Selected CPT Codes</div>
         <div class="link-text">
           <a (click)="clearSelections($event)" id="clear">Clear All Selections</a>
           <a (click)="restoreDefault()" [ngClass]="defaultGroup ? 'restore-default' : 'no-default'"
              id="restore-default">Restore Default</a>
         </div>
       </div>
      <div class="sized-block"></div>
      <div id ="selected-Cptcode-header">
        <div class="available-column-headers">
           <span *ngFor="let column of columnsForAvailableCptCodes.slice(); let i = index">
             <mat-checkbox class="header-check" id="deselect-all" [checked]="selectedCptCodes.length > 0"
                           (click)="deselectAllCpts($event)" *ngIf="!i"></mat-checkbox>{{column.header}}</span>
        </div>
      </div>
      <hr>
      <mat-table [dataSource]="dataSourceSelectedCptCode" (scroll)="handleScrollSelectedCodes($event)">
       <ng-container *ngFor="let column of columnsForSelectedCptCodes; let i = index" [matColumnDef]="column.columnDef">
        <mat-cell *matCellDef="let row" class="{{column.columnDef}} selected-cpt-data-name">
          <ng-container *ngIf="i"><span class="selected-cpt-data-name">{{column.dataName(row)}}</span></ng-container>
          <ng-container *ngIf="!i">
            <mat-checkbox class="individual-code-checkbox-selected" [checked]="true" (click)="whenCptCodeDeselected($event, row)">
              <span class="selected-cpt-data-name">{{column.dataName(row)}}</span>
            </mat-checkbox>
          </ng-container>
        </mat-cell>
       </ng-container>
       <ng-container><mat-row *matRowDef="let row; columns: displayedSelectedCptCodeColumns"></mat-row>
       </ng-container>
      </mat-table>
    </span>
  </span>
  <div id="bottom-buttons">
    <button id="cancel-button" (click)="cancel()">Cancel</button>
    <button id="save-cpt-button" [ngClass]="selectedCptCodes.length ? 'save-cpt-button' : 'no-save'" mat-button
            (click)="openSaveDialog()">Save CPT Grouping</button>
    <button id="apply-button" mat-raised-button (click)="onApply()">Apply</button>
  </div>
</div>
