import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-warning-limit-exceeded',
  templateUrl: './warning-limit-exceeded.component.html',
  styleUrls: ['./warning-limit-exceeded.component.scss']
})
export class WarningLimitExceededComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WarningLimitExceededComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WarningLimitData) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}

export interface WarningLimitData {
  maxNodePaths: number;
}
