import {
  CustomGroupsDataAllFilters,
  DateRange,
  HierarchicalSelectableNode,
  ListItemExpansion, PayerCategoryLevel,
  SelectableMemberBillingArea,
  SelectedHierarchicalNodes
} from '../shared/models';
import {
  determineFiscalYear,
  getArbitraryDateRangeFromDatesInMonths,
  getDateRangeBySelectedOption,
  getFiscalYearForFiscalYearToDateOption,
  getParentNodePath,
  parseMonthWithLeadingZero
} from '../shared/helpers';
import {FilterCriteria, IAppState} from '../store/IAppState';
import {
  DEFAULT_INVOICE_TEXT,
  DEFAULT_LAG_NAME,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_KEY,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_TELEHEALTH_FLAG
} from '../store/DefaultValues';
import {hasValue} from '../shared/null-helpers';
import {DateRangeOption, FilterBannerTab, PayerType} from '../shared/enums';
import {determineAllowedDataset} from '../shared/compare-helpers';
import {getSelectedNodeCountsFromNodePath} from '../shared/ontology-helpers';

export function getSelectedDate(dateRange: DateRange) {
  return {
    'begin': dateRange.startYear
      + '-'
      + parseMonthWithLeadingZero(dateRange.startMonth)
      + '-15',
    'end': dateRange.endYear
      + '-'
      + parseMonthWithLeadingZero(dateRange.endMonth)
      + '-15',
    'selectedDateRangeOption': dateRange.selectedDateRangeOption
  };
}

export function customGroupDataChanged(customGroup: CustomGroupsDataAllFilters | undefined, filterCriteria: FilterCriteria): boolean {
  if (!customGroup) {
    return false;
  } else {
    return customGroup.nodePath !== filterCriteria.nodePath ||
      customGroup.payerCategoryKey !== filterCriteria.payerCategory.payerCategoryKey ||
      (filterCriteria.payerType === PayerType.ALL && customGroup.payerType !== PayerType.ALL) ||
      (filterCriteria.payerType === PayerType.CATEGORY && (customGroup.payerType !== PayerType.CATEGORY
        || customGroup.payerCategoryKey !== filterCriteria.payerCategory.payerCategoryKey)) ||
      (filterCriteria.payerType === PayerType.NATIONAL && (customGroup.payerType !== PayerType.NATIONAL
        || customGroup.nationalPayerKey !== filterCriteria.payerKey)) ||
      (filterCriteria.payerType === PayerType.MEMBER && (customGroup.payerType !== PayerType.MEMBER
        || customGroup.memberPayerKey !== filterCriteria.payerKey)) ||
      customGroup.memberLocationKey !== filterCriteria.memberLocation.memberLocationKey ||
      customGroup.memberBillingAreaKey !== filterCriteria.memberBillingArea.memberBillingAreaKey ||
      customGroup.invoiceStatus !== filterCriteria.invoiceStatus ||
      customGroup.lagKey !== filterCriteria.lagKey ||
      getArbitraryDateRangeFromDatesInMonths(customGroup.startDateInMonths, customGroup.endDateInMonths) !== filterCriteria.dateRange ||
      customGroup.dateRangeOption !== filterCriteria.dateRange.selectedDateRangeOption ||
      customGroup.telehealthFlag !== filterCriteria.telehealthFlag;
  }
}

export function filterCriteriaFromSelectedCustomGroup(
  customGroup: CustomGroupsDataAllFilters, state: IAppState): FilterCriteria {
  return {
    nodePath: customGroup.nodePath,
    dateRange: findDateRangeFromCustomGroup(customGroup, state),
    memberKey: customGroup.memberKey || DEFAULT_MEMBER_KEY,
    payerKey: customGroup.payerType === 3 ? customGroup.memberPayerKey || 0 :
      (customGroup.payerType === 2 ? customGroup.nationalPayerKey || 0 :
        (customGroup.payerType === 1 ? customGroup.payerCategoryKey : 0)),
    payerType: customGroup.payerType,
    payerCategory: {
      payerCategoryKey: customGroup.payerCategoryKey || 0,
      payerCategoryDescription: customGroup.payerCategoryDescription || 'All Payers',
      payerCategoryCode: ''
    },
    nationalPayerKey: customGroup.nationalPayerKey,
    nationalPayerDescription: customGroup.nationalPayerDescription,
    memberPayerKey: customGroup.memberPayerKey,
    memberPayerDescription: customGroup.memberPayerDescription,
    memberLocation: {
      memberLocationKey: customGroup.memberLocationKey,
      memberLocationName: customGroup.memberLocationDescription || '',
      memberLocationCode: ''
    },
    memberBillingArea: {
      memberBillingAreaKey: customGroup.memberBillingAreaKey,
      memberBillingAreaDescription: customGroup.memberBillingAreaDescription || '',
      memberBillingAreaCode: ''
    },
    invoiceStatus: customGroup.invoiceStatus,
    lagKey: customGroup.lagKey,
    telehealthFlag: customGroup.telehealthFlag,
    customGroupId: customGroup.id, memberLocationKeys: customGroup.memberLocationKeys,
    cptFamilyFilter: customGroup.cptFamilyDesc, cptRangeFilter: customGroup.cptRangeDesc
  };
}

export function getSelectedNodePath(
  selectedFilteredProviders: HierarchicalSelectableNode[],
  listOfAllDepartments: HierarchicalSelectableNode[],
  listOfAllSpecialties: HierarchicalSelectableNode[],
  listOfAllProviders: HierarchicalSelectableNode[],
  allDepartmentsNodePath: string
) {
  let selectedNodePath = '';
  if (selectedFilteredProviders.length === listOfAllProviders.length || selectedFilteredProviders.length === 0) {
    return allDepartmentsNodePath;
  }
  const depSelectedMap: Map<string, number> = new Map<string, number>();
  const depListAllMap: Map<string, number> = new Map<string, number>();
  const sepListAllMap: Map<string, number> = new Map<string, number>();
  const sepSelectedMap: Map<string, number> = new Map<string, number>();
  selectedFilteredProviders.forEach((selProv: HierarchicalSelectableNode) => {
    const deptNodePath = getParentNodePath(getParentNodePath(selProv.selectableNode.node.nodePath));
    if (depSelectedMap.has(deptNodePath)) {
      const value = depSelectedMap.get(deptNodePath);
      depSelectedMap.delete(deptNodePath);
      depSelectedMap.set(deptNodePath, (value || 0) + 1);
    } else {
      depSelectedMap.set(deptNodePath, 1);
    }
  });
  listOfAllProviders.forEach((listProv: HierarchicalSelectableNode) => {
    const deptNodePath = getParentNodePath(getParentNodePath(listProv.selectableNode.node.nodePath));
    if (depListAllMap.has(deptNodePath)) {
      const value = depListAllMap.get(deptNodePath);
      depListAllMap.delete(deptNodePath);
      depListAllMap.set(deptNodePath, (value || 0) + 1);
    } else {
      depListAllMap.set(deptNodePath, 1);
    }

    const specNodePath = getParentNodePath(listProv.selectableNode.node.nodePath);
    if (sepListAllMap.has(specNodePath)) {
      const value = sepListAllMap.get(specNodePath);
      sepListAllMap.delete(specNodePath);
      sepListAllMap.set(specNodePath, (value || 0) + 1);
    } else {
      sepListAllMap.set(specNodePath, 1);
    }
  });

  const ignoredDepNodePaths: string[] = [];

  for (const [key, value] of depSelectedMap) {
    if (depListAllMap.has(key) && depListAllMap.get(key) === value) {
      ignoredDepNodePaths.push(key);
      selectedNodePath = selectedNodePath + key + '|';
    }
  }
  ignoredDepNodePaths.forEach((x: string) => {
    selectedFilteredProviders = selectedFilteredProviders.filter(node => {
      const deptNodePath = getParentNodePath(getParentNodePath(node.selectableNode.node.nodePath));
      return deptNodePath && !deptNodePath.startsWith(x);
    });
  });

  selectedFilteredProviders.forEach((selProv: HierarchicalSelectableNode) => {
    const specNodePath = getParentNodePath(selProv.selectableNode.node.nodePath);
    if (sepSelectedMap.has(specNodePath)) {
      const value = sepSelectedMap.get(specNodePath);
      sepSelectedMap.delete(specNodePath);
      sepSelectedMap.set(specNodePath, (value || 0) + 1);
    } else {
      sepSelectedMap.set(specNodePath, 1);
    }
  });

  // no need to check specialty node paths if all the specialties in the department selected
  const ignoredSplNodePaths: string[] = [];
  for (const [key, value] of sepSelectedMap) {
    if (sepListAllMap.has(key) && sepListAllMap.get(key) === value) {
      ignoredSplNodePaths.push(key);
      selectedNodePath = selectedNodePath + key + '|';
    }
  }

  ignoredSplNodePaths.forEach((x: string) => {
    selectedFilteredProviders = selectedFilteredProviders.filter(
      node => {
        const specNodePath = getParentNodePath(node.selectableNode.node.nodePath);
        return specNodePath && !specNodePath.startsWith(x);
      });
  });

  selectedFilteredProviders.forEach((node: HierarchicalSelectableNode) => {
    selectedNodePath = selectedNodePath + node.selectableNode.node.nodePath + '|';
  });

  return selectedNodePath.length > 1 ? selectedNodePath.substring(0, selectedNodePath.length - 1) :
    selectedNodePath;
}

export function getDefaultHierarchicalNodes(allDepartmentsNode: string): SelectedHierarchicalNodes {
  return {
    selectedFilteredProviders: [],
    listOfAllDepartments: [],
    listOfAllSpecialties: [],
    listOfAllProviders: [],
    allDepartmentsNodePath: allDepartmentsNode
  };
}

export interface UpdatedFilterTab {
  title: string;
  readonly defaultLabel: string;
  label: string;
  available: boolean;
  tab: FilterBannerTab;
  unsavedData?: boolean;
  originalLabel?: string;
}

export const updatedFilterTabs: UpdatedFilterTab[] = [
  {
    title: 'Time Frame',
    defaultLabel: '',
    label: '',
    available: true,
    tab: FilterBannerTab.DATE
  },
  {
    title: 'Location',
    defaultLabel: DEFAULT_MEMBER_LOCATION.memberLocationName,
    label: DEFAULT_MEMBER_LOCATION.memberLocationName,
    available: true,
    tab: FilterBannerTab.LOCATION
  },
  {
    title: 'Payer',
    defaultLabel: DEFAULT_PAYER_CATEGORY.payerCategoryDescription,
    label: DEFAULT_PAYER_CATEGORY.payerCategoryDescription,
    available: true,
    tab: FilterBannerTab.PAYER
  },
  {
    title: 'Billing Area',
    defaultLabel: DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaDescription,
    label: DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaDescription,
    available: true,
    tab: FilterBannerTab.BILLING
  },
  {
    title: 'Lag Period',
    defaultLabel: DEFAULT_LAG_NAME,
    label: DEFAULT_LAG_NAME,
    available: true,
    tab: FilterBannerTab.LAG
  },
  {
    title: 'Invoice Status',
    defaultLabel: DEFAULT_INVOICE_TEXT,
    label: DEFAULT_INVOICE_TEXT,
    available: true,
    tab: FilterBannerTab.INVOICE_STATUS
  },
  {
    title: 'Visit Type',
    defaultLabel: DEFAULT_TELEHEALTH_FLAG.text,
    label: DEFAULT_TELEHEALTH_FLAG.text,
    available: true,
    tab: FilterBannerTab.VISIT_TYPE
  }
];

export function findDateRangeFromCustomGroup(customGroup: CustomGroupsDataAllFilters, state: IAppState): DateRange {
  const {dateRangeOption, startDateInMonths, endDateInMonths} = customGroup;
  if (!hasValue(dateRangeOption)) {
    return getArbitraryDateRangeFromDatesInMonths(startDateInMonths, endDateInMonths);
  }
  const fiscalStartMonth = state.userPreferences?.fiscalStartMonth || 1;
  const memberKey = state.filters.memberKey || DEFAULT_MEMBER_KEY;
  const recentMonth = state.data.userMemberData?.find((x) => x.memberKey === memberKey)?.recentMonth;
  const fiscalYear = dateRangeOption === DateRangeOption.FiscalYearToDate
    ? getFiscalYearForFiscalYearToDateOption(fiscalStartMonth)
    : determineFiscalYear(startDateInMonths, (dateRangeOption || 10) - 9);
  const numberOfMonths = endDateInMonths - startDateInMonths + 1;
  return getDateRangeBySelectedOption(dateRangeOption, recentMonth, fiscalStartMonth, fiscalYear, numberOfMonths);
}

export interface SelectableItem<T> {
  selected: boolean;
  originallySelected?: boolean;
  belongs: boolean;
  matchesSearchText: boolean;
  item: T;
  key: number;
  expansion?: ListItemExpansion;
  displayText?: string;
}

export interface RelatableSelectableItem<T> {
  item: SelectableItem<T>;
  relatives: SelectableItem<T>[];
}

export function generateCustomGroupFromFilters(filter: FilterCriteria, state: IAppState): CustomGroupsDataAllFilters {
  const selectionCounts = getSelectedNodeCountsFromNodePath(filter.nodePath);
  return {
    id: 0,
    name: '',
    description: '',
    nodePath: filter.nodePath,
    isDefault: this.isDefault,
    preload: false,
    memberKey: filter.memberKey,
    payerCategoryKey: filter.payerCategory.payerCategoryKey,
    nationalPayerKey: filter.nationalPayerKey,
    memberPayerKey: filter.memberPayerKey,
    payerType: filter.payerType,
    memberLocationKey: filter.memberLocation.memberLocationKey,
    memberBillingAreaKey: filter.memberBillingArea.memberBillingAreaKey,
    invoiceStatus: filter.invoiceStatus,
    lagKey: filter.lagKey,
    startDateInMonths: (filter.dateRange.startYear * 12)
      + filter.dateRange.startMonth,
    endDateInMonths: (filter.dateRange.endYear * 12)
      + filter.dateRange.endMonth,
    dateRangeOption: filter.dateRange.selectedDateRangeOption,
    telehealthFlag: filter.telehealthFlag,
    memberLocationKeys: filter.memberLocationKeys,
    cptFamilyDesc: filter.cptFamilyFilter,
    cptRangeDesc: filter.cptRangeFilter,
    dataSet: determineAllowedDataset({...state, display: {...state.display, selectedProviders:
        selectionCounts.selectedProviders, selectedSpecialties: selectionCounts.selectedSpecialties}})
  };
}

export function isMemberBillingAreaAbleToDisplay(billingArea: SelectableMemberBillingArea): boolean {
  return billingArea.matchesSearchText;
}

export function constructPayerString(payerHierarchy: PayerCategoryLevel[], categoryKey: number,
                                     nationalKey?: number, memberPayerKey?: number): string {
  let payerString = DEFAULT_PAYER_CATEGORY.payerCategoryDescription;
  const foundPayerCategory = payerHierarchy.find(c => c.payerCategoryKey === categoryKey);
  if (foundPayerCategory) {
    payerString = `Payer Category: ${foundPayerCategory.payerCategoryDesc}`;
    const foundNationalPayer = foundPayerCategory.nationalPayers.find(n => n.nationalPayerKey === nationalKey);
    if (foundNationalPayer) {
      payerString = `${payerString}: ${foundNationalPayer.nationalPayerDesc}`;
      const foundMemberPayer = foundNationalPayer.memberPayers.find(m => m.memberPayerKey === memberPayerKey);
      if (foundMemberPayer) {
        payerString = `${payerString}: ${foundMemberPayer.memberPayerDesc}`;
      }
    }
  }
  return payerString;
}
