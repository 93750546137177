<mat-dialog-content>
  <div id="batch-export-header">Multi-Provider Export</div>
  <div id="batch-export-options" xmlns="http://www.w3.org/1999/html">
    <div id="note">Note: Data is based on selected filtered criteria.</div>

    <div id="export-content-reportName">
      <p id="question-report-name">What would you like to name your report?</p>
      <input id="reportName" type="text" placeholder="Report Name" maxlength="250" class="report-name"
             [(ngModel)]="reportName"/>
    </div>
    <div id="pdf">
      <p *ngIf="!showEmExport" class="question-inclusions">What would you like to include?</p>
      <div id="em-export" *ngIf="showEmExport">
        <div id="overview-wrapper">
          <div id="overview-title" (click)="selectOrDeselectAllOverviewMetrics($event)">
            <mat-checkbox [checked]="overviewExportSelected"></mat-checkbox>
            <strong>Overview Page</strong>
          </div>
          <p id="select-show-em">The export will include the overview page
            with the cFTE adj. wRVUS Trend, % New Patients Trend, Evaluation and Management
            and Top New Patient Zip Code metrics. For the E&M export, please
            select the code range you'd like to export.
          </p>
          <div *ngFor="let type of batchPdfExportOverviewMetrics"
               [ngClass]="'metric-options-overview-em metric-wrapper check-metric'"
               (click)="updateBatchPdfExportOverviewMetric(type.id, $event)">
            <mat-checkbox class="metric-heading em-metric-heading chkPdf{{type.id}}"
                          [checked]="batchPdfExportOverviewMetrics[type.id].selected"
                          *ngIf="type.type === MetricType.Overview">{{type.text}}
            </mat-checkbox>
          </div>
        </div>
        <div id="em-wrapper">
          <div id="em-title" (click)="selectOrDeselectAllEMMetrics($event)">
            <mat-checkbox [checked]="atLeastOneEMMetricSelected"></mat-checkbox>
            <strong>E&M Page</strong>
          </div>
          <p id="em-explanation" class="select-em">The export will include the graphs and
            data tables for the Evaluation and Management Page</p>
          <div *ngFor="let type of batchPdfExportEMMetrics"
               [ngClass]="'metric-options-overview-em metric-wrapper check-metric'"
               (click)="updateBatchPdfExportEMMetric(type.id, $event)">
            <mat-checkbox class="metric-heading em-metric-heading chkPdf{{type.id}}"
                          [checked]="batchPdfExportEMMetrics[type.id].selected"
                          *ngIf="type.type === MetricType.EandM">{{type.text}}</mat-checkbox>
          </div>
          <br/>
        </div>
        <app-batch-dialog-hierarchical-selector id="batch-dialog-em-trend"
                                                [hierarchyOptions]="emTrendHierarchy"
                                                [canShowBenchmarkSelector]="canShowBenchmarkSelector"
                                                [topOptions]="[emTrendHierarchy[0]]" [isFirstOption]="true"
                                                [whenHierarchicalOptionSelected]="whenEmTrendOptionSelected"></app-batch-dialog-hierarchical-selector>
      </div>
      <div *ngIf="!showEmExport">
        <div id="select-em">For the E&M metric, please select the code range you'd like to export</div>
        <div *ngFor="let type of batchPdfExportOverviewMetrics"
             [ngClass]="'metric-options-overview-em metric-wrapper check-metric'"
             (click)="updateBatchPdfExportOverviewMetric(type.id, $event)">
          <mat-checkbox class="metric-heading em-metric-heading"
                        [checked]="batchPdfExportOverviewMetrics[type.id].selected"
                        id="chkPdf{{type.id}}" *ngIf="type.type === MetricType.Overview"
          >{{type.text}}</mat-checkbox>
        </div>
        <br/></div>
      <div id="npv-export" *ngIf="showNpvExport" (click)="toggleNpv($event)">
        <mat-checkbox [checked]="includeNpvPage" id="npv-checkbox"><strong id="npv-checkbox-label">NPV Page</strong>
        </mat-checkbox>
        <div id="npv-explanation">This export includes graphs and data tables for the New Patient Visits
          Page and the Top New Patient Visits Zip Code Map
        </div>
      </div>
    </div>
  </div>
  <hr class="bottomLine">
  <div id="hover-batch-display" *ngIf="shouldShowHoverMessage">{{hoverMessageText}}</div>
  <div id="export-buttons">
    <button class="cancel-button" mat-flat-button (click)="close()">Cancel</button>
    <button class="export-button" id="export-button" mat-raised-button
            [matTooltip]="(reportName && reportName.length > 0 && !somethingSelected) ?
           'Please select at least one page to export.' : 'Enter a report name'"
            [matTooltipPosition]="'above'"
            [matTooltipDisabled]="reportName.length > 0 && somethingSelected"
            [matTooltipClass]="'custom-tooltip'"
            [class.disabled]="!reportName || reportName.length === 0 || showSubmittalWait || !somethingSelected"
            (click)="export()">Export
    </button>
    <label *ngIf="showSubmittalWait" class="submittal-indicator">Please wait</label>
  </div>
</mat-dialog-content>
