import {Component, Inject, Input, OnInit, OnDestroy} from '@angular/core';
import {cptFamilyFilterChangedTo, cptRangeFilterChangedTo, cptViewTypeChangedTo} from '../store/actions';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {CptViewType} from '../shared/enums';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';

@Component({
  selector: 'app-toggle-cpt-view-type',
  templateUrl: './toggle-cpt-view-type.component.html',
  styleUrls: ['./toggle-cpt-view-type.component.scss']
})
export class ToggleCptViewTypeComponent implements OnInit, OnDestroy {

  showOptions = false;
  viewTypeName = '';
  cptViewType = CptViewType.CptCode;
  viewTypeDisplayName = '';
  cptFamilyFilter?: string;
  cptRangeFilter?: string;
  shouldDisplayBackArrow = false;

  cptOptions: string[] = ['CPT Family', 'CPT Range', 'CPT Code'];
  cptOptionsVisible: string[] = [];

  @Input() isSpecialty: boolean;
  @Input() isDepartment: boolean;
  @Input() showOverviewPage = false;
  @Input() displayViewType: boolean;

  @select(['filters', 'cptFamilyFilter'])
  private readonly cptFamilyFilter$: Observable<string>;
  @select(['filters', 'cptRangeFilter'])
  private readonly cptRangeFilter$: Observable<string>;
  @select(['display', 'cptViewType'])
  private readonly cptViewType$: Observable<CptViewType>;
  subscription: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.subscription = combineLatest([
      this.cptFamilyFilter$,
      this.cptRangeFilter$,
      this.cptViewType$]
    ).subscribe(
      ([cptFamilyFilter, cptRangeFilter, cptViewType]:
         [ string, string, CptViewType]) => {
        this.cptViewType = cptViewType;
        this.viewTypeName = this.getViewTypeName(this.cptViewType);
        this.cptFamilyFilter = cptFamilyFilter;
        this.cptRangeFilter = cptRangeFilter;
        this.viewTypeDisplayName = this.getViewTypeDisplay();
        this.setShouldDisplayBackArrow();
        this.cptOptionsVisible = [];
        this.cptOptionsVisible.push(this.cptOptions[2]);
        if (!cptRangeFilter && cptFamilyFilter) {
          this.cptOptionsVisible.push(this.cptOptions[1]);
          this.cptOptionsVisible.reverse();
        } else if (!(cptRangeFilter || cptFamilyFilter)) {
          this.cptOptionsVisible.push(this.cptOptions[1]);
          this.cptOptionsVisible.push(this.cptOptions[0]);
          this.cptOptionsVisible.reverse();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }

  hideOptions() {
    this.showOptions = false;
  }

  getViewTypeName(viewType: CptViewType) {
    switch (viewType) {
      case 0:
        return 'CPT Family';
      case 1:
        return 'CPT Range';
      case 2:
        return 'CPT Code';
      default:
        return 'CPT Code';
    }
  }

  getViewTypeDisplay() {
    switch (this.viewTypeName) {
      case 'CPT Family':
        return 'All CPT Families';
      case 'CPT Range':
        return this.cptFamilyFilter ? this.cptFamilyFilter : 'All CPT Ranges';
      case 'CPT Code':
        return this.cptRangeFilter ? this.cptRangeFilter :
          this.cptFamilyFilter ? this.cptFamilyFilter : 'All CPT Codes';
      default:
        return '';
    }
  }

  chooseViewType(viewType: number) {
    const initialViewType = this.cptViewType;
    switch (this.cptOptionsVisible[viewType]) {
      case this.cptOptions[0]:
        this.cptViewType = CptViewType.CptFamily;
        break;
      case this.cptOptions[1]:
        this.cptViewType = CptViewType.CptRange;
        break;
      case this.cptOptions[2]:
        this.cptViewType = CptViewType.CptCode;
        break;
      default:
        this.cptViewType = CptViewType.CptCode;
        break;
    }
    const changedViewType = initialViewType !== this.cptViewType;
    this.viewTypeName = this.getViewTypeName(this.cptViewType);
    this.viewTypeDisplayName = this.getViewTypeDisplay();
    if (changedViewType) {
      this.analyticsService.handleGoogleAnalytics(
        'CPT View Type', 'CPT View All', 'View ' + this.viewTypeDisplayName);
    }
    this.ngRedux.dispatch(cptViewTypeChangedTo(this.cptViewType));
    this.showOptions = false;
  }

  setShouldDisplayBackArrow(): void {
    switch (this.cptViewType) {
      case CptViewType.CptFamily:
        this.shouldDisplayBackArrow = false;
        break;
      case CptViewType.CptRange:
        this.shouldDisplayBackArrow = this.cptFamilyFilter !== undefined;
        break;
      case CptViewType.CptCode:
        this.shouldDisplayBackArrow = this.cptRangeFilter !== undefined || this.cptFamilyFilter !== undefined;
        break;
    }
  }

  navigateBack(): void {
    switch (this.cptViewType) {
      case CptViewType.CptRange:
        this.analyticsService.handleGoogleAnalytics(
          'CPT Navigation', 'Navigate back to All Families',
          'Navigate back from Family: ' + this.cptFamilyFilter);
        this.ngRedux.dispatch(cptViewTypeChangedTo(CptViewType.CptFamily));
        this.ngRedux.dispatch(cptFamilyFilterChangedTo(undefined));
        break;
      case CptViewType.CptCode:
        if (this.cptFamilyFilter) {
          this.analyticsService.handleGoogleAnalytics(
            'CPT Navigation', 'Navigate back to CPT ranges filtered by Family: ' + this.cptFamilyFilter,
            'Navigate back from CPT codes filtered by Family: ' + this.cptFamilyFilter);
        } else {
          this.analyticsService.handleGoogleAnalytics(
            'CPT Navigation', 'Navigate back to All Ranges',
            'Navigate back from Range: ' + this.cptRangeFilter);
        }
        this.ngRedux.dispatch(cptViewTypeChangedTo(CptViewType.CptRange));
        this.ngRedux.dispatch(cptRangeFilterChangedTo(undefined));
    }
  }
}
