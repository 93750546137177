<mat-dialog-content>
  <div id="settings-dialog-container">
    <section>
      <nav>
        <ul id="side-select">
          <li id="option-date-range" [class.active]="showDateRange" (click)="setSettingsTab(0)">
            <p id="date-range-tab-title" class="sidenav-title">Date Range</p>
            <p id="date-range-tab-description" class="sidenav-description">Set default time range</p>
          </li>
          <li id="option-my-view" [class.active]="showMyView" (click)="setSettingsTab(1)">
            <p id="my-view-tab-title">My View</p>
            <p id="my-view-tab-description" class="sidenav-description">Set default hierarchy and landing page</p>
          </li>
        </ul>
      </nav>
      <article>
        <div id="date-range-display" *ngIf="showDateRange">
          <p class="setting-option-heading">Date range</p><br/>
          <p class="setting-option-message">Set your default date range</p>
          <div id="date-range-line"></div>
          <br/>
          <nav id="divOptions">
            <a>
              <div id="past12Months" [class.active]="showPast12Months" class="floating-menu"
                   (click)="setDateRange(DateRangeOption.Past12Months)"
              >Past 12 months
              </div>
            </a>
            <a>
              <div id="yearToDate" [class.active]="showYearToDate" class="floating-menu"
                   (click)="setDateRange(DateRangeOption.YearToDate)"
              >Year to date
              </div>
            </a>
            <a *ngIf="hasRecentMonth">
              <div id="recentMonth" [class.active]="showRecentMonth" class="floating-menu"
                   (click)="setDateRange(DateRangeOption.RecentMonth)"
              >Recent month
              </div>
            </a>
            <a *ngIf="hasRecentMonth || recentMonth < 0">
              <div id="past-12-months-data" [class.active]="showPast12MonthsOfData" class="floating-menu"
                   (click)="setDateRange(DateRangeOption.Past12MonthsOfData)"
              >Past 12 months of data
              </div>
            </a>
          </nav>
          <br/>
          <div id="dateRangeText">{{dateRangeText}}</div>
        </div>
        <div id="my-view-display-heading" *ngIf="showMyView">
          <p class="setting-option-heading">My View</p><br/>
          <p class="setting-option-message">Select your starting level and landing page when logged in</p>
          <div id="my-view-display">
            <div id="hierarchy-table-wrapper">
              <div id="level-hierarchy" class="hierarchy-table-row" (click)="toggleShowHierarchyList()"
                   *ngIf="!showHierarchyList">
              <span class="level-hierarchy-heading-wrapper">
                <span id="default-starting-level" class="default-what">Default Starting Level</span>
                <span id="level-selection" class="default-selection">
                {{existingPreferredNodeName !== '' ? existingPreferredNodeName : 'All Departments'}}
                </span>
              </span>
              </div>
              <div id="level-hierarchy{{levelHierarchyDynamicID()}}" class="hierarchy-table-row"
                   (click)="toggleShowHierarchyList()" *ngIf="showHierarchyList">
              <span class="level-hierarchy-heading-wrapper" *ngIf="showHierarchyList">
                <span id="default-starting-level-expanded" class="default-what">Default Starting Level</span>
                <span id="level-selection-expanded" class="default-selection">
                {{existingPreferredNodeName !== '' ? existingPreferredNodeName : 'All Departments'}}
                </span>
              </span>
              </div>
            </div>
            <div id="my-view-display-ontology">
              <div *ngIf="showHierarchyList" id="hierarchy-display">
                <div id="hierarchy-root-option"
                     class="hierarchy-table-row-items"
                     (click)="collapseHierarchy()"
                     [class.active]="selectedNodePath === ontology[0]?.nodePath">
                  <span id="hierarchy-root-name">{{ontology[0].nodeName}}</span>
                </div>
                <div class="hierarchy-table-row-items-ontology"
                     *ngFor="let hierarchyItem of hierarchyList; let i = index"
                     (click)="onHierarchyItemSelected(i, ontology[0].children, hierarchyItem)"
                     [class.active]="isExpanded(hierarchyItem.nodePath)"
                     [ngSwitch]="getHierarchicalDepth(hierarchyItem.nodePath)">
            <span *ngSwitchCase="3" class="department-wrapper">
              <span class="hierarchy-node-name">
                {{hierarchyItem.nodeName}}
              </span>
              <span class="chevron-wrapper">
                <img *ngIf="isExpanded(hierarchyItem.nodePath)" alt="Chevron Symbol"
                     src="assets/InvertedAppsChevron.svg" class="chevron-symbol"/>
                <img *ngIf="!isExpanded(hierarchyItem.nodePath)" alt="Chevron Symbol" src="assets/AppsChevron.svg"
                     class="chevron-symbol"/></span>
            </span>
                  <span *ngSwitchCase="4" class="specialty-wrapper">
              <span class="hierarchy-node-name">
                {{hierarchyItem.nodeName}}
              </span>
              <span class="chevron-wrapper">
                <img *ngIf="isExpanded(hierarchyItem.nodePath)" alt="Chevron Symbol"
                     src="assets/InvertedAppsChevron.svg" class="chevron-symbol"/>
                <img *ngIf="!isExpanded(hierarchyItem.nodePath)" alt="Chevron Symbol" src="assets/AppsChevron.svg"
                     class="chevron-symbol"/></span>
            </span>
                  <span *ngSwitchCase="5" class="provider-wrapper">
              <span class="hierarchy-node-name">
                {{hierarchyItem.nodeName}}
              </span>
            </span>
                  <span *ngSwitchDefault class="default-type-wrapper">
              <span class="hierarchy-node-name">
                {{hierarchyItem.nodeName}}
              </span>
            </span>
                </div>
              </div>
            </div>
            <br/>
            <div class="default-table-row" (click)="toggleShowDefaultPageList()">
              <span id="default-page" class="default-what">Default Page</span>
              <span id="page-selection" class="default-selection">{{currentDefault.name}}</span>
            </div>
            <div *ngIf="showDefaultPageList" id="defaultPage-display">
              <div class="hierarchy-table-row-items" *ngFor="let defaultPage of defaultPageList"
                   (click)="setDefaultPage(defaultPage)"
                   [class.active]="isDefaultPage(defaultPage)"
              >{{defaultPage.name}}</div>
            </div>
            <div class="default-table-row" *ngIf="isUserHasMultipleMembers">
              <mat-checkbox id="chk-default-member" [checked]="isDefaultMemberSelected"
                            (click)="toggleDefaultMember($event)">Save {{selectedMemberDesc}} as my default member
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="save-buttons">
          <button class="cancel-button" mat-flat-button (click)="closeDialog()">Cancel</button>
          <button class="save-button" mat-raised-button (click)="saveSettings()">Save</button>
        </div>
      </article>
    </section>
  </div>
</mat-dialog-content>
