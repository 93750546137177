import {MultilevelNode} from '../shared/models';
import * as _ from 'lodash';
import {DataTableColumns} from '../shared/data-table-columns';
import {hasValue} from '../shared/null-helpers';
import {RelatableSelectableItem} from '../filter-banner/filter-banner-helper';
import {CptViewType} from '../shared/enums';

export interface ClinicalSummaryConsolidation {
  cptClinicalSummaries: ClinicalSummaryCode[];
  familyClinicalSummaries: ClinicalSummaryFamily[];
  rangeClinicalSummaries: ClinicalSummaryRange[];

  [key: string]: ClinicalSummaryCode[] | ClinicalSummaryFamily[] | ClinicalSummaryRange[];
}

export interface ClinicalSummary {
  charges: number;
  cfteAdjustedWRVUs: number; // cFTE Adj. wRVUs
  frequency: number;
  totalWorkRVUs: number;
  workRvuBenchmark: number; // cFTE Adj. wRVUs Benchmark
  totalWorkRvuBenchmark: number;
  frequencyBenchmark: number; // CPSC cFTE Adj. Count
  cfteAdjustedTotalWRVUs?: number;
  cfteAdjustedFrequency: number; // cFTE Adj. Count
  cFTEAdjustedFrequencyVariance?: number | null; // cFTE Adj. Count Variance from Benchmark
  cFTEAdjustedWrvusVariance?: number | null; // cFTE Adj. wRVUs Variance from Benchmark
  cFTEAdjustedTotalWrvusVariance?: number;
  wRVUs: number;
  communityWorkRvuBenchmark: number; // Community cFTE Adj. wRVUs Benchmark
  communityTotalWorkRvuBenchmark: number;
  communityFrequencyBenchmark: number; // Community CPSC cFTE Adj. Count
  communityCfteAdjustedFrequencyVariance?: number | null; // Community cFTE Adj. Count Variance from Benchmark
  communityCfteAdjustedWrvusVariance?: number | null; // Community cFTE Adj. wRVUs Variance from Benchmark
  communityCfteAdjustedTotalWrvusVariance?: number;

  [key: string]: number | string | undefined | null | TornadoStyles;
}

function splitNameWhenSearched(name: string, searchText: string): string[] {
  if (!hasValue(searchText) || searchText.length < 3) {
    return ['', '', name];
  }
  const indexOf = name.toLowerCase().indexOf(searchText.toLowerCase());
  return [name.substring(0, indexOf), name.substring(indexOf, indexOf + searchText.length),
    name.substring(indexOf + searchText.length)];
}

const commonCptColumns: DataTableColumns[] = [
  {
    header: 'Range',
    columnDef: 'rangeDesc',
    dataName: (row: RelatableSelectableItem<ClinicalSummaryCode>) =>
      `${row.item.item.cptRangeLow}-${row.item.item.cptRangeHigh} ${row.item.item.cptRangeDesc}`
  },
  {
    header: 'Family',
    columnDef: 'familyDesc',
    dataName: (row: RelatableSelectableItem<ClinicalSummaryCode>) => row.item.item.cptFamilyDesc
  }
];

export const columnsForAvailableCptCodes: DataTableColumns[] = [
  {
    header: 'CPT Code',
    columnDef: 'cptCode',
    dataName: (row: RelatableSelectableItem<ClinicalSummaryCode>, searchText: string) =>
      splitNameWhenSearched(row.item.item.cptCode, searchText)
  }
].concat(commonCptColumns);

export const columnsForSelectedCptCodes: DataTableColumns[] = [
  {
    header: 'CPT Code',
    columnDef: 'cptCode',
    dataName: (row: RelatableSelectableItem<ClinicalSummaryCode>) => row.item.item.cptCode
  }
].concat(commonCptColumns);

export interface ClinicalSummaryFamily extends ClinicalSummary {
  cptFamilyDesc: string;

  [key: string]: number | string | undefined | null | TornadoStyles;
}

export interface ClinicalSummaryRange extends ClinicalSummaryFamily {
  cptRangeDesc: string;
  cptRangeLow: string;
  cptRangeHigh: string;

  [key: string]: number | string | undefined | null | TornadoStyles;
}

export interface ClinicalSummaryCode extends ClinicalSummaryRange {
  cptCode: string;
  cptDesc: string;
  wrvuTornado?: TornadoStyles;
  countTornado?: TornadoStyles;

  [key: string]: number | string | undefined | null | TornadoStyles;
}

export interface TornadoStyles {
  borderWidth?: number;
  color?: string;
  positive?: boolean;
}

export interface CfpByMultilevel extends MultilevelNode, ClinicalSummaryCode {
}

export interface GroupedCfpByMultilevel {
  providerNodeName: string;
  departmentNodeName: string;
  specialtyNodeName: string;
  data: CfpByMultilevel[];
  itemsToShow: number;
}

export interface CfpMultiLevelData {
  cfpByProvider: CfpByMultilevel[];
  cfpBySpecialty: CfpByMultilevel[];
  cfpByDepartment: CfpByMultilevel[];
}

export function aggregateCPTData(data: ClinicalSummaryCode[]) {
  return {
    charges: _.sumBy(data, 'charges'),
    cfteAdjustedWRVUs: _.sumBy(data, 'cfteAdjustedWRVUs'),
    frequency: _.sumBy(data, 'frequency'),
    totalWorkRVUs: _.sumBy(data, 'totalWorkRVUs'),
    workRvuBenchmark: _.sumBy(data, 'workRvuBenchmark'),
    totalWorkRvuBenchmark: _.sumBy(data, 'totalWorkRvuBenchmark'),
    frequencyBenchmark: _.sumBy(data, 'frequencyBenchmark'),
    cfteAdjustedTotalWRVUs: _.sumBy(data, 'cfteAdjustedTotalWRVUs'),
    cfteAdjustedFrequency: _.sumBy(data, 'cfteAdjustedFrequency'),
    cFTEAdjustedFrequencyVariance: _.sumBy(data, 'cFTEAdjustedFrequencyVariance'),
    cFTEAdjustedWrvusVariance: _.sumBy(data, 'cfteAdjustedWRVUs') - _.sumBy(data, 'workRvuBenchmark'),
    cFTEAdjustedTotalWrvusVariance: _.sumBy(data, 'cFTEAdjustedTotalWrvusVariance'),
    wRVUs: _.sumBy(data, 'wRVUs'),
    communityWorkRvuBenchmark: _.sumBy(data, 'communityWorkRvuBenchmark'),
    communityTotalWorkRvuBenchmark: _.sumBy(data, 'communityTotalWorkRvuBenchmark'),
    communityFrequencyBenchmark: _.sumBy(data, 'communityFrequencyBenchmark'),
    communityCfteAdjustedWrvusVariance: _.sumBy(data, 'cfteAdjustedWRVUs')
      - _.sumBy(data, 'communityWorkRvuBenchmark')
  };
}

export function groupClinicalSummaryByFamily(clinicalSummary: ClinicalSummaryCode[]) {
  return _(clinicalSummary)
    .groupBy('cptFamilyDesc')
    .map((familyData: ClinicalSummaryCode[], id) => ({
      cptFamilyDesc: id,
      ...aggregateCPTData(familyData)
    }))
    .value();
}

export function groupMultilevelCPTDataByCPTField(cptData: CfpByMultilevel[], cptField: string): CfpByMultilevel[] {
  return _(cptData)
    .groupBy(cptField)
    .map((groupedCPTData: CfpByMultilevel[]) => ({
      ...groupedCPTData[0],
      ...aggregateCPTData(groupedCPTData)
    }))
    .value();
}

export function getNextCptGranularity(viewType: CptViewType): CptViewType {
  switch (viewType) {
    case CptViewType.CptFamily: return CptViewType.CptRange;
    case CptViewType.CptRange: case CptViewType.CptCode: return CptViewType.CptCode;
  }
}

export function getPreviousCptGranularity(viewType: CptViewType): CptViewType {
  switch (viewType) {
    case CptViewType.CptCode: return CptViewType.CptRange;
    case CptViewType.CptRange: case CptViewType.CptFamily: return CptViewType.CptFamily;
  }
}

export function isCptViewTypeDrillable(viewType: CptViewType): boolean {
  return [CptViewType.CptFamily, CptViewType.CptRange].includes(viewType);
}

export interface ProcedureSummaryDrill {
  displayText: string;
  viewType: CptViewType;
  cfp: CfpByMultilevel;
}

export class ProcedureSummaryDrillImpl {
  drillObject: ProcedureSummaryDrill;
  previous?: ProcedureSummaryDrillImpl;

  constructor(drillObject: ProcedureSummaryDrill, previous: ProcedureSummaryDrillImpl | undefined) {
    this.drillObject = drillObject;
    this.previous = previous;
  }

  drillIn(next: ProcedureSummaryDrill): void {
    this.previous = new ProcedureSummaryDrillImpl(Object.assign({}, this.drillObject),
      this.previous ? Object.assign({}, this.previous) : undefined);
    this.drillObject = Object.assign({}, next);
  }

  drillOut(): boolean {
    if (this.previous) {
      this.drillObject = Object.assign({}, this.previous.drillObject);
      this.previous = this.previous.previous;
      return true;
    }
    return false;
  }
}
