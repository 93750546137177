import {Component, Inject, Input, OnChanges} from '@angular/core';
import {MetricType} from '../../../shared/metric-types';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {MergedProductivityTrendEntry, MonthProductivityData, MonthProductivityEntry} from '../../../shared/models';
import {BenchmarkPercentile, getBenchmarkLabel, readableNameOf} from '../../../shared/benchmark-types';
import {LegendData, OverviewChartOptions} from '../../../overview/models/overview-models';
import * as _ from 'lodash';
import {WrvuViewType} from '../../../shared/helpers';
import {formatNumberToWholeNumber} from '../../../productivity-summary/number-formatter';
import {checkForNulls} from '../../../shared/null-helpers';
import {
  backgroundColor,
  BarChartOptions,
  calculateChartWidth,
  ChartMarkerSymbol,
  chartType,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  DEFAULT_POINT_PADDING,
  FormatOption,
  getBarchartSeriesOption,
  getChartOptions,
  getLegendOptions,
  getTitleOptions,
  getXaxisOptions,
  getYAxisOption
} from '../../../shared/highcharts-helpers';
import {abbreviatedDateLabel} from '../../../productivity-summary/month-formatter';
import {BenchmarkOption, HighChartColorIndex, LegendColor, LegendStyle} from '../../../shared/enums';

@Component({
  selector: 'app-wrvu-tile-batch-export',
  templateUrl: './wrvu-tile-batch-export.component.html',
  styleUrls: ['./wrvu-tile-batch-export.component.scss']
})
export class WrvuTileBatchExportComponent implements OnChanges {
  title: string;
  wrvuViewType: WrvuViewType;
  comparedTo: string;
  legendData: LegendData[];
  overviewChartOptions: OverviewChartOptions;
  varianceSubtractFrom: string;
  varianceSubtract: string;
  variance: string;
  varianceClass: string;
  previousYearDifference: string;
  previousYearDifferenceClass: string;
  isCfteAdjusted: boolean;
  benchmarkPlaceholder: string;
  hasCommunityBenchmarks: boolean;
  hasAcademicBenchmarks: boolean;
  hasBenchmarks = true;
  wRVUSelectedRangeTrendData: MonthProductivityEntry[];
  previousWrvuSelectedRangeTrendData: MonthProductivityEntry[];

  MetricType = MetricType;
  viewCommunityBenchmarks = false;
  POINT_WIDTH = 21;
  snapshotBarCount = 12;
  chartWidth = calculateChartWidth(this.POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING);
  OVERVIEW_CHART_HEIGHT = 200;
  @Input() benchmarkOption: BenchmarkOption = BenchmarkOption.Academic;
  @Input() benchmarkPercentile: BenchmarkPercentile = BenchmarkPercentile.Mean;
  @Input() benchmarkLabel: string;
  @Input() NUMBER_OF_DATA_POINTS: number;
  @Input() wrvuData: MonthProductivityData;
  @Input() wrvuPreviousYearData: MonthProductivityData;
  @Input() selectedDateRange: string;
  @Input() previousDateRange: string;

  constructor(
    @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
    private ngRedux: NgRedux<IAppState>
  ) {
  }

  ngOnChanges(): void {
    this.viewCommunityBenchmarks = this.benchmarkOption !== BenchmarkOption.Academic;
    this.populatewRVUTrendTile(this.wrvuData, this.wrvuPreviousYearData);
    this.populatewRVUOverviewDetails();
  }

  populatewRVUTrendTile(wrvuData: MonthProductivityData, wrvuPreviousYearData: MonthProductivityData) {
    if (!wrvuData || !wrvuData.monthProductivityData) {
      return;
    }
    const data = wrvuData.monthProductivityData.monthProductivities;
    const previousData = wrvuPreviousYearData.monthProductivityData.monthProductivities;
    const academicBenchmarks = data.filter(x =>
      !(x.benchmarkMean === null
        || x.benchmarkMean === 0)
      && !(x.benchmark25thPercentile === null
        || x.benchmark25thPercentile === 0)
      && !(x.benchmark50thPercentile === null
        || x.benchmark50thPercentile === 0)
      && !(x.benchmark65thPercentile === null
        || x.benchmark65thPercentile === 0)
      && !(x.benchmark75thPercentile === null
        || x.benchmark75thPercentile === 0)
      && !(x.benchmark90thPercentile === null
        || x.benchmark90thPercentile === 0)
    );

    const communityBenchmarks = data.filter(x =>
      !(x.communityBenchmarkMean === null
        || x.communityBenchmarkMean === 0)
      && !(x.communityBenchmark25thPercentile === null
        || x.communityBenchmark25thPercentile === 0)
      && !(x.communityBenchmark50thPercentile === null
        || x.communityBenchmark50thPercentile === 0)
      && !(x.communityBenchmark65thPercentile === null
        || x.communityBenchmark65thPercentile === 0)
      && !(x.communityBenchmark75thPercentile === null
        || x.communityBenchmark75thPercentile === 0)
      && !(x.communityBenchmark90thPercentile === null
        || x.communityBenchmark90thPercentile === 0)
    );
    this.hasCommunityBenchmarks = communityBenchmarks.length > 0;
    this.hasAcademicBenchmarks = academicBenchmarks.length > 0;
    this.hasBenchmarks = this.viewCommunityBenchmarks ? this.hasCommunityBenchmarks : this.hasAcademicBenchmarks;
    this.wRVUSelectedRangeTrendData = _.orderBy(data.slice(), ['year', 'month'], ['asc', 'asc']);
    this.previousWrvuSelectedRangeTrendData = _.orderBy(previousData.slice(), ['year', 'month'], ['asc', 'asc']);
    this.wrvuViewType = WrvuViewType.CfteAdjusted;
    this.isCfteAdjusted = this.wrvuViewType === WrvuViewType.CfteAdjusted;
    this.benchmarkPlaceholder = getBenchmarkLabel(this.benchmarkPercentile) + ' ';
    this.overviewChartOptions = this.populatewRVUOverviewChartDetails();
  }

  populatewRVUOverviewDetails() {
    this.title = this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'cFTE Adj. wRVUs Trend' : 'Actual wRVUs Trend';
    this.comparedTo = ' compared to ';
    this.varianceSubtractFrom = this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'cFTE Adj. wRVUs' : 'Actual wRVUs';
    this.varianceSubtract = this.benchmarkLabel.toLowerCase() + ' benchmark ' +
      readableNameOf(this.benchmarkPercentile, ' percentile').toLowerCase();
    this.legendData = this.wrvuViewType === WrvuViewType.CfteAdjusted ?
      [
        {
          name: 'cFTE Adj. wRVUs',
          currentDatesValue: formatNumberToWholeNumber(this.getCfteAdjustedWrvus(this.wRVUSelectedRangeTrendData)),
          currentDatesIcon: LegendStyle.CIRCLE,
          currentDatesColor: LegendColor.TEAL,
          previousDatesValue: formatNumberToWholeNumber(this.getCfteAdjustedWrvus(this.previousWrvuSelectedRangeTrendData)),
          previousDatesIcon: LegendStyle.SQUARE,
          previousDatesColor: LegendColor.PURPLE
        },
        {
          name: 'wRVUs',
          currentDatesIcon: LegendStyle.DIAMOND,
          currentDatesColor: LegendColor.GREY,
          currentDatesValue: formatNumberToWholeNumber((this.lookupWrvuSummaryBenchmarkValue(
            this.benchmarkPercentile, this.wRVUSelectedRangeTrendData, this.viewCommunityBenchmarks))),
          previousDatesValue: formatNumberToWholeNumber((this.lookupWrvuSummaryBenchmarkValue(
            this.benchmarkPercentile, this.previousWrvuSelectedRangeTrendData, this.viewCommunityBenchmarks))),
          benchmark: true
        }
      ] : [
        {
          name: 'Actual wRVUs',
          currentDatesValue: formatNumberToWholeNumber(this.getWrvus(this.wRVUSelectedRangeTrendData)),
          currentDatesIcon: LegendStyle.CIRCLE,
          currentDatesColor: LegendColor.TEAL,
          previousDatesValue: formatNumberToWholeNumber(this.getWrvus(this.previousWrvuSelectedRangeTrendData)),
          previousDatesIcon: LegendStyle.SQUARE,
          previousDatesColor: LegendColor.PURPLE
        }
      ];

    const variance = this.lookupWrvuVarianceValue(this.benchmarkPercentile, this.wRVUSelectedRangeTrendData, this.viewCommunityBenchmarks);
    if (variance < 0) {
      this.variance =
        formatNumberToWholeNumber(variance * -1) + ' below';
      this.varianceClass = 'negative';
    } else {
      this.variance = formatNumberToWholeNumber(variance) + ' above';
      this.varianceClass = 'positive';
    }

    const currentWrvus = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? checkForNulls(this.getCfteAdjustedWrvus(this.wRVUSelectedRangeTrendData))
      : checkForNulls(this.getWrvus(this.wRVUSelectedRangeTrendData));

    const previousWrvus = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? checkForNulls(this.getCfteAdjustedWrvus(this.previousWrvuSelectedRangeTrendData))
      : checkForNulls(this.getWrvus(this.previousWrvuSelectedRangeTrendData));

    if (currentWrvus - previousWrvus < 0) {
      this.previousYearDifference = formatNumberToWholeNumber(currentWrvus - previousWrvus) + ' decrease';
      this.previousYearDifferenceClass = 'negative';
    } else {
      this.previousYearDifference = formatNumberToWholeNumber(currentWrvus - previousWrvus) + ' increase';
      this.previousYearDifferenceClass = 'positive';
    }
  }

  populatewRVUOverviewChartDetails(): OverviewChartOptions {
    const selectedRangeTrendCfteAdjustedwRVUs = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? this.wRVUSelectedRangeTrendData.map(wrvu => wrvu.cfteAdjustedWRVUs)
      : this.wRVUSelectedRangeTrendData.map(wrvu => wrvu.wRVUs);

    const selectedRangeBarOptions: BarChartOptions = {
      name: this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'cFTE adjusted wRVUs' : 'Actual wRVUs',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.TEAL,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 2,
      data: selectedRangeTrendCfteAdjustedwRVUs.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(selectedRangeTrendCfteAdjustedwRVUs, this.NUMBER_OF_DATA_POINTS, 0) :
        selectedRangeTrendCfteAdjustedwRVUs,
      marker: {
        symbol: ChartMarkerSymbol.CIRCLE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };

    let previousRangeBarOptions: any;
    const previousRangeTrendCfteAdjustedwRVUs = this.previousWrvuSelectedRangeTrendData
      .map(wrvu => checkForNulls(this.wrvuViewType === WrvuViewType.CfteAdjusted ? wrvu.cfteAdjustedWRVUs : wrvu.wRVUs));

    previousRangeBarOptions = {
      name: this.wrvuViewType === WrvuViewType.CfteAdjusted ? 'Previous cFTE adjusted wRVUs'
        : 'Previous Actual wRVUs',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.PURPLE,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 1,
      data: previousRangeTrendCfteAdjustedwRVUs.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(previousRangeTrendCfteAdjustedwRVUs, this.NUMBER_OF_DATA_POINTS, 0) :
        previousRangeTrendCfteAdjustedwRVUs,
      marker: {
        symbol: ChartMarkerSymbol.SQUARE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };

    const currentBenchmarks = this.wrvuViewType === WrvuViewType.CfteAdjusted
      ? createEmptyValues(this.wRVUSelectedRangeTrendData.map(
        wrvu => this.lookupWrvuBenchmarkValue(this.benchmarkPercentile, wrvu, this.viewCommunityBenchmarks)), this.NUMBER_OF_DATA_POINTS, 0)
      : null;

    const currentBenchmarkRangeBarOptions: BarChartOptions = {
      name: this.benchmarkLabel + ' wRVUs Benchmark ' + readableNameOf(this.benchmarkPercentile, ' percentile'),
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.GREY,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 3,
      data: currentBenchmarks,
      marker: {
        symbol: ChartMarkerSymbol.DIAMOND
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };

    const categories = this.wRVUSelectedRangeTrendData.map((visit) => abbreviatedDateLabel(visit.month, visit.year));
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;

    const benchmarksForMax = currentBenchmarks?.map(bench => Math.abs(bench)) || [0];
    let yAxisMax = 0;
    if (selectedRangeTrendCfteAdjustedwRVUs && selectedRangeTrendCfteAdjustedwRVUs.length >= 1) {
      yAxisMax = Math.max(...selectedRangeTrendCfteAdjustedwRVUs.map(val => Math.abs(val)), ...[yAxisMax]);
    }
    if (previousRangeTrendCfteAdjustedwRVUs && previousRangeTrendCfteAdjustedwRVUs.length >= 1) {
      yAxisMax = Math.max(...previousRangeTrendCfteAdjustedwRVUs.map(val => Math.abs(val)), ...[yAxisMax]);
    }
    yAxisMax = Math.max(...benchmarksForMax, ...[yAxisMax]);

    return {
      title: getTitleOptions(''),
      series: previousRangeBarOptions
        ? [getBarchartSeriesOption(selectedRangeBarOptions, false),
          getBarchartSeriesOption(previousRangeBarOptions, false),
          getBarchartSeriesOption(currentBenchmarkRangeBarOptions, false)]
        : [getBarchartSeriesOption(selectedRangeBarOptions, false),
          getBarchartSeriesOption(currentBenchmarkRangeBarOptions, false)],
      legend: getLegendOptions(false),
      tooltip: {
        shared: true,
        valueDecimals: 0
      },
      chart: {
        ...getChartOptions(chartType.Line, backgroundColor.Transparent, this.chartWidth, this.OVERVIEW_CHART_HEIGHT),
        animation: false
      },
      xAxis: [getXaxisOptions(categories, isScrolled, DEFAULT_MAX_NUMBER_SCROLLBARS)],
      yAxis: getYAxisOption('', yAxisMax, FormatOption.Number),
      showFullScrollbar: false,
      enableScrollbar: isScrolled,
      legends: []
    };
  }

  private lookupWrvuBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    monthData: MonthProductivityEntry,
    viewCommunityBenchmarks: boolean
  ): number {
    let benchmarkValue = 0;
    switch (benchmarkPercentile) {
      case 1:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark25thPercentile
          : monthData.benchmark25thPercentile;
        break;
      case 2:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark50thPercentile
          : monthData.benchmark50thPercentile;
        break;
      case 3:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark75thPercentile
          : monthData.benchmark75thPercentile;
        break;
      case 4:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark90thPercentile
          : monthData.benchmark90thPercentile;
        break;
      case 5:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmarkMean
          : monthData.benchmarkMean;
        break;
      case 6:
        benchmarkValue = viewCommunityBenchmarks
          ? monthData.communityBenchmark65thPercentile
          : monthData.benchmark65thPercentile;
        break;
      default:
        return 0;
    }
    return checkForNulls(benchmarkValue);
  }

  private lookupWrvuSummaryBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    trendData: MonthProductivityEntry[],
    viewCommunityBenchmark: boolean
  ): number {
    return viewCommunityBenchmark
      ? this.getWrvuCommunityBenchmarkValue(trendData, benchmarkPercentile)
      : this.getWrvuBenchmarkValue(trendData, benchmarkPercentile);
  }

  private lookupWrvuVarianceValue(
    benchmarkPercentile: BenchmarkPercentile,
    trendData: MonthProductivityEntry[],
    viewCommunityBenchmarks: boolean
  ): number {
    return viewCommunityBenchmarks
      ? this.getWrvuCommunityVarianceValue(trendData, benchmarkPercentile)
      : this.getWrvuVarianceValue(trendData, benchmarkPercentile);
  }

  getCfteAdjustedWrvus(wrvuTrendData: MonthProductivityEntry[]): number {
    let cfteAdjustedWrvus = 0;
    wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
      cfteAdjustedWrvus = cfteAdjustedWrvus + checkForNulls(data.cfteAdjustedWRVUs);
    });
    return cfteAdjustedWrvus;
  }

  getWrvus(wrvuTrendData: MonthProductivityEntry[]): number {
    let wrvus = 0;
    wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
      wrvus = wrvus + checkForNulls(data.wRVUs);
    });
    return wrvus;
  }

  getWrvuBenchmarkValue(wrvuTrendData: MonthProductivityEntry[], benchmarkPercentile: BenchmarkPercentile): number {
    let benchmarkValue = 0;
    let benchmarkKey: string;
    switch (benchmarkPercentile) {
      case 1:
        benchmarkKey = 'benchmark25thPercentile';
        break;
      case 2:
        benchmarkKey = 'benchmark50thPercentile';
        break;
      case 3:
        benchmarkKey = 'benchmark75thPercentile';
        break;
      case 4:
        benchmarkKey = 'benchmark90thPercentile';
        break;
      case 5:
        benchmarkKey = 'benchmarkMean';
        break;
      case 6:
        benchmarkKey = 'benchmark65thPercentile';
        break;
      default:
        benchmarkKey = 'benchmarkMean';
    }
    wrvuTrendData?.forEach((data: MergedProductivityTrendEntry) => {
      // @ts-ignore
      benchmarkValue = benchmarkValue + checkForNulls(data[benchmarkKey]);
    });
    return benchmarkValue;
  }

  getWrvuCommunityBenchmarkValue(wrvuTrendData: MonthProductivityEntry[], benchmarkPercentile: BenchmarkPercentile): number {
    let benchmarkValue = 0;
    let benchmarkKey: string;
    switch (benchmarkPercentile) {
      case 1:
        benchmarkKey = 'communityBenchmark25thPercentile';
        break;
      case 2:
        benchmarkKey = 'communityBenchmark50thPercentile';
        break;
      case 3:
        benchmarkKey = 'communityBenchmark75thPercentile';
        break;
      case 4:
        benchmarkKey = 'communityBenchmark90thPercentile';
        break;
      case 5:
        benchmarkKey = 'communityBenchmarkMean';
        break;
      case 6:
        benchmarkKey = 'communityBenchmark65thPercentile';
        break;
      default:
        benchmarkKey = 'communityBenchmarkMean';
    }
    wrvuTrendData.forEach((data: MergedProductivityTrendEntry) => {
      // @ts-ignore
      benchmarkValue = benchmarkValue + checkForNulls(data[benchmarkKey]);
    });
    return benchmarkValue;
  }

  getWrvuVarianceValue(wrvuTrendData: MonthProductivityEntry[], benchmarkPercentile: BenchmarkPercentile): number {
    let varianceValue = 0;
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance25thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile50th:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance50thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile75th:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance75thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Percentile90th:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance90thPercentile || 0);
        });
        break;
      case BenchmarkPercentile.Mean:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.varianceMean || 0);
        });
        break;
      case BenchmarkPercentile.Percentile65th:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.variance65thPercentile || 0);
        });
        break;
      default:
        varianceValue = 0;
    }
    return checkForNulls(varianceValue);
  }

  getWrvuCommunityVarianceValue(wrvuTrendData: MonthProductivityEntry[], benchmarkPercentile: BenchmarkPercentile): number {
    let varianceValue = 0;
    switch (benchmarkPercentile) {
      case 1:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance25thPercentile || 0);
        });
        break;
      case 2:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance50thPercentile || 0);
        });
        break;
      case 3:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance75thPercentile || 0);
        });
        break;
      case 4:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance90thPercentile || 0);
        });
        break;
      case 6:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVariance65thPercentile || 0);
        });
        break;
      case 5:
      default:
        wrvuTrendData?.forEach((data: MonthProductivityEntry) => {
          varianceValue = varianceValue + (data.communityVarianceMean || 0);
        });
        break;
    }
    return checkForNulls(varianceValue);
  }

  getBenchmarkHeaderClass(legend: LegendData): string {
    return legend.benchmark ? 'legend-bchmk-header' : '';
  }

}
