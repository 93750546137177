import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RelatableSelectableItem, ScenarioForDisplayability, UpdatedFilterTab} from '../FilterBannerModels';
import {MemberBillingArea} from '../../shared/models';
import {DEFAULT_MEMBER_BILLING_AREA} from '../../store/DefaultValues';
import {determineAllSelected, isItemAbleToShow} from '../UpdatedFilterBannerHelpers';

@Component({
  selector: 'app-search-billing-areas',
  templateUrl: './search-billing-areas.component.html',
  styleUrls: ['./search-billing-areas.component.scss']
})
export class SearchBillingAreasComponent implements OnInit {

  @Input() billingAreas: RelatableSelectableItem<MemberBillingArea>[];
  @Input() onBillingAreaChanged: (tab?: UpdatedFilterTab, item?: RelatableSelectableItem<MemberBillingArea>) => void;
  @Input() showLoadingIndicator = true;
  @Input() filterTab: UpdatedFilterTab;
  allSelected = true;
  displayedBillingAreas: RelatableSelectableItem<MemberBillingArea>[] = [];
  readonly DEFAULT_MEMBER_BILLING_AREA = DEFAULT_MEMBER_BILLING_AREA;
  searchText = '';

  constructor(protected cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.showBillingAreas();
    this.allSelected = determineAllSelected(this.billingAreas);
  }

  private showBillingAreas(): void {
    this.displayedBillingAreas = this.billingAreas.filter(b => isItemAbleToShow(b, ScenarioForDisplayability.Standard));
  }

  onSearchTextChanged(): void {
    this.billingAreas.forEach(billingArea => {
      billingArea.item.matchesSearchText = this.searchText.length < 3 ||
        !!billingArea.item.displayText?.toLowerCase().includes(this.searchText.toLowerCase());
    });
    this.showBillingAreas();
  }

  selectAllBillingAreas(): void {
    if (!this.allSelected) {
      let wasSelected: boolean;
      this.billingAreas.forEach(billingArea => {
        wasSelected = billingArea.item.selected;
        billingArea.item.selected = false;
        if (this.filterTab.newSelections && wasSelected) {
          if (billingArea.item.originallySelected) {
            this.filterTab.newSelections.deselections++;
          } else {
            this.filterTab.newSelections.selections--;
          }
        }
      });
      this.allSelected = true;
      this.onBillingAreaChanged(this.filterTab);
    }
  }

  whenBillingAreaClicked(billingArea: RelatableSelectableItem<MemberBillingArea>): void {
    if (billingArea.item.selected) {
      return;
    }
    this.allSelected = false;
    let shouldBeSelected: boolean;
    let wasSelected: boolean;
    this.billingAreas.forEach(bil => {
      shouldBeSelected = bil.item.key === billingArea.item.key;
      wasSelected = bil.item.selected;
      bil.item.selected = shouldBeSelected;
      if (shouldBeSelected && this.filterTab.newSelections) {
        if (!bil.item.originallySelected) {
          this.filterTab.newSelections.selections++;
        } else {
          this.filterTab.newSelections.deselections--;
        }
      } else if (!shouldBeSelected && this.filterTab.newSelections && wasSelected) {
        if (bil.item.originallySelected) {
          this.filterTab.newSelections.deselections++;
        } else {
          this.filterTab.newSelections.selections--;
        }
      }
    });
    this.onBillingAreaChanged(this.filterTab, billingArea);
    this.cdr.detectChanges();
  }
}
