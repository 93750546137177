<div id="npv-graph-wrapper">
  <div class="chart-header">
    <div class="graph-title-wrapper">
      <h2 class="graph-title">% New Patients {{level}}</h2>
    </div>
    <span class="right-header-wrapper">
      <app-variable-container [showVariableMenu]="showVariableMenu" [page]="page" [variables]="variables"
                              [disabled]="activeVarianceToggle"
      ></app-variable-container>
      <app-variance-toggler [varianceToggleAction]="varianceToggle"></app-variance-toggler>
    </span>
  </div>
  <div [chart]="chartObject" id="new-patient-visits-graph"></div>
  <app-chart-legend
    [activeVarianceToggle]="activeVarianceToggle"
    [legends]="legends"
    [hasBenchmarks]="hasBenchmarks"
    [benchmarksToExclude]="benchmarkToExclude"
    [metricType]="MetricType.NewPatientVisits"
  ></app-chart-legend>
  <app-loader  [class.loader-hidden]="!showProgressBar"></app-loader>
</div>
