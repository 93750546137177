<div class="wrapper">
  <div [id]="overviewPageView? 'overview-patient-visit-charts' : 'patient-visit-charts'">
    <div class="patient-visit-chart">
      <app-evaluation-management-chart id="new-patients"
                                       chartId="new-patient-visits"
                                       [fromPdf]="fromPdf"
                                       [categories]="newPatientVisitsCategories"
                                       chartTitle="Office/Outpatient Visit New"
                                       [maxYAxisValue]="maxNewPatientYAxisValue"
                                       [totalCptSummaries]="totalNewPatientsCptSummaries"
                                       [specialtyCptSummaries]="specialtyTotalNewPatientsCptSummaries"
                                       [chartHeight]="chartHeight"
                                       [chartWidth]="chartWidth"
                                       [isSingleProviderSelected]="isSingleProviderSelected"
                                       [foundNodeName]="foundNodeName"
                                       [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
    </div>

    <div class="patient-visit-chart">
      <app-evaluation-management-chart id="established-patients"
                                       chartId="established-patient-visits"
                                       [fromPdf]="fromPdf"
                                       [categories]="establishedPatientVisitsCategories"
                                       [maxYAxisValue]="maxEstablishedPatientPatientYAxisValue"
                                       chartTitle="Office/Outpatient Visit Established"
                                       [totalCptSummaries]="totalEstablishedPatientsCptSummaries"
                                       [specialtyCptSummaries]="specialtyTotalEstablishedPatientsCptSummaries"
                                       [chartHeight]="chartHeight"
                                       [chartWidth]="chartWidth"
                                       [isSingleProviderSelected]="isSingleProviderSelected"
                                       [foundNodeName]="foundNodeName"
                                       [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
    </div>

    <div class="patient-visit-chart">
      <app-evaluation-management-chart id="consultations"
                                       chartId="consultations"
                                       [fromPdf]="fromPdf"
                                       [categories]="consultationsCategories"
                                       [maxYAxisValue]="maxConsultationsYAxisValue"
                                       chartTitle="Office Consultation"
                                       [totalCptSummaries]="totalConsultationsCptSummaries"
                                       [specialtyCptSummaries]="specialtyTotalConsultationsCptSummaries"
                                       [chartHeight]="chartHeight"
                                       [chartWidth]="chartWidth"
                                       [isSingleProviderSelected]="isSingleProviderSelected"
                                       [foundNodeName]="foundNodeName"
                                       [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-chart>
    </div>
    <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
  <app-chart-legend [ngClass]="fromPdf? 'pdf-legend': ''" [fromPdf]="fromPdf" [legends]="legends"
                    [metricType]="metricType"></app-chart-legend>
</div>
