<div class="graph-container">
  <app-wrvu-multilevel-chart
    [isDepartment]="true"
    [isSpecialty]="false"
    [isProvider]="false"
    [showVariableMenu]="true"
    [level]="'By Department'"
    [page]="currentPage"
    [showProgressBar]="showProgressBar"
    [variables]="variables"
    [activeVarianceToggle]="activeVarianceToggle"
    [zeroSuppression]="zeroSuppression"
    [benchmarkPercentile]="benchmarkPercentile"
    [viewCommunityBenchmarks]="viewCommunityBenchmarks"
    [wRVUtype]="wRVUtype"
    [selectionCounts]="selectionCounts"
    [varianceKey]="varianceKey"
    [canShowBenchmarks]="canShowBenchmarks"
    [canShowCfteAdjWrvus]="canShowCfteAdjWrvus"
    [sortingCriteria]="varianceToggleSortingCriterion"
    [columnSelectionCallback]="whenDepartmentSelected"
    [productivitySnapshotData]="wrvuSnapshotData"
  ></app-wrvu-multilevel-chart>
</div>

<app-wrvu-summary-data-table [canShowBenchmarks]="canShowBenchmarks" [canShowCfteAdjWrvus]="canShowCfteAdjWrvus"></app-wrvu-summary-data-table>
<app-data-table id="wrvu-dept-snapshot-table"
                [tableTitle]="'wRVUs by Department'"
                [tableCssClass]="'wrvu-snapshot-table'"
                [showLevel]="false"
                [addRemoveOption]="true"
                [displayZeroSuppression]="true"
                [receivedData]="wrvuSnapshotData"
                [displayedColumns]="displayedColumns"
                [showProgressBar]="showProgressBar"
                [defaultSortColumn]="defaultSortColumn"
                [sortDirection]="sortDirection"
                [tieBreakerProperty]="tieBreakerProperty"
                [rowSelectionCallback]="whenDepartmentRowSelected"
                [updateSortingCriteria]="updateSortingCriteria"
                [whenSortChanged]="whenSortChanged"
                [isZeroSuppressionChecked]="zeroSuppression"
                [countOfSuppressedEntries]="countOfSuppressedEntries"
                [zeroSuppressionCondition]="zeroSuppressionCondition"
                [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                [currentPage]="currentPage"
                [originalColumns]="columns"
                [reducerAction]="reducerAction"
                [chosen]="MultilevelTab.BY_DEPARTMENT"
                [canShowBenchmarks]="canShowBenchmarks"
                [canShowCfteAdjWrvus]="canShowCfteAdjWrvus"
>
</app-data-table>
