import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {UserProfile} from '../shared/models';

export const UserProfileServiceToken = new InjectionToken<UserProfileService>('UserProfileService');

export interface UserProfileService {
  getUserProfile(memberKey: number): Observable<UserProfile>;
}

@Injectable()
export class HttpUserProfileService implements UserProfileService {

  constructor(private readonly httpClient: HttpClient) { }

  getUserProfile(memberKey: number): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(`${environment.api}/api/userprofile`);
  }

}
