<mat-dialog-content>
  <div *ngIf="currentPage===MpeDialogPage.SETUP" id="multi-provider-export-wrapper" class="top">
    <app-metric-benchmark-pane (submitExportOptions)="onBatchExportOptionsSubmitted($event)"
                               (whenAtLeastOneItemIsSelected)="onItemSelected($event)"
                               [batchExportMetricSections]="originalBatchExportMetricSections"
                               [overviewMetricSection]="overviewMetricSection"
                               [overviewId]="overViewId"
                               [canShowNpv]="canShowNpv"
                               [canShowWrvuTrend]="canShowWrvuTrend"
                               [canShowBenchmarkSelector]="canShowBenchmarkSelector"
                               [canShowNpvLocation]="canShowNpvLocation">
    </app-metric-benchmark-pane>
    <app-report-schedule-pane [(schedulePaneInfo)]="schedulePaneInfo"
                              [showErrorMessage]="showErrorMessage"
                              (schedulePaneInfoChange)="onSchedulePaneInfoChange()"
                              (validationStatusChange)="onValidationStatusChange($event)">
    </app-report-schedule-pane>
  </div>
  <div *ngIf="currentPage===MpeDialogPage.SUMMARY" id="summary-pane" class="top">
    <app-mpe-summary-pane [schedulePaneInfo]="schedulePaneInfo"
                          [overviewMetricSection]="overviewMetricSection"
                          [batchExportMetricSections]="currentBatchExportMetricSections"
                          [numberOfProviders]="numberOfProviders">
    </app-mpe-summary-pane>
  </div>
  <div *ngIf="currentPage===MpeDialogPage.EXPORTED" id="success-pane" class="top">
  <app-mpe-success-page [schedulePaneInfo]="schedulePaneInfo">
  </app-mpe-success-page>
</div>
<app-dialog-footer (pageSelection)="updatePage($event)"
                     (enableErrorMessage)="showErrorMessage = $event"
                     [validationStatus]="validationStatus"
                     [permittedToGoToSummary]="permittedToGoToSummary"
                     [nextButtonValue]="nextButtonValue"
                     [nextButtonEnable]="somethingSelected"
                     [nextButtonText]="nextButtonText"
                     [backButtonValue]="backButtonValue"
                     [backButtonText]="backButtonText">
  </app-dialog-footer>
</mat-dialog-content>
