
<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="departmentNodeName">
    <mat-header-cell *matHeaderCellDef class="headerProvider">Provider / Specialty / Department</mat-header-cell>
    <mat-cell *matCellDef="let row" class="provider">
      {{row.providerNodeName + ' / '}}<br/>{{row.specialtyNodeName + ' / '}}<br/>{{row.departmentNodeName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="encounter">
    <mat-header-cell *matHeaderCellDef class="headerEncounter">Metrics</mat-header-cell>
    <mat-cell *matCellDef="let row" class="encounter"><div class="number-of-encounters"># of Encounters</div><div class="coding-distribution">Coding Distribution</div>
      <div  class="benchmark-display">{{benchmarkDisplay}} CPSC Benchmark Mean</div>
    </mat-cell>
  </ng-container>
  <ng-container *ngFor="let cpt of NewPatientVisitCodes.codes" matColumnDef="countOf{{cpt}}">
    <mat-header-cell *matHeaderCellDef class="header{{cpt}} bold">{{cpt}}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="countOf{{cpt}}"><div class="top-cell">
      {{isValidOrElse(row.newPatients,'cpt' + cpt, 'count', '-')}}</div>
    <div class="middle-cell">{{isValidOrElse(row.newPatients, 'cpt' + cpt, 'percentage',
      '-', '%')}}</div><div class="bottom-cell">
    {{isValidOrElse(row.newPatients, 'cpt' + cpt, benchmarkString, '-', '%')}}</div>
    </mat-cell>
  </ng-container>
  <ng-container  matColumnDef="totalNewPatientVisit">
    <mat-header-cell *matHeaderCellDef class="headerTotalNewPatientVisit bold">Total</mat-header-cell>
    <mat-cell *matCellDef="let row" class="totalNewPatientVisit"><div class="top-cell">{{valueFromOrElse(row.newPatients,'count', '', '-')}}</div>
      <div class="middle-cell">{{valueFromOrElse(row.newPatients,'percentage', '%', '-', true)}}</div>
      <div class="bottom-cell"></div>
    </mat-cell>
  </ng-container>
  <ng-container *ngFor="let cpt of EstablishedPatientVisitCodes.codes" matColumnDef="countOf{{cpt}}">
    <mat-header-cell *matHeaderCellDef class="header{{cpt}} bold">{{cpt}}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="countOf{{cpt}}"><div class="top-cell">
      {{isValidOrElse(row.establishedPatients,'cpt' + cpt, 'count', '-')}}</div>
      <div class="middle-cell">{{isValidOrElse(row.establishedPatients, 'cpt' + cpt, 'percentage',
        '-', '%')}}</div><div class="bottom-cell">
        {{isValidOrElse(row.establishedPatients, 'cpt' + cpt, benchmarkString, '-', '%')}}</div>
    </mat-cell>
  </ng-container>
  <ng-container  matColumnDef="totalEstablishedPatientVisit">
    <mat-header-cell *matHeaderCellDef class="headerTotalEstablishedPatientVisit bold">Total</mat-header-cell>
    <mat-cell *matCellDef="let row" class="totalEstablishedPatientVisit"><div class="top-cell">{{valueFromOrElse(row.establishedPatients,'count','', '-')}}</div>
      <div class="middle-cell">{{valueFromOrElse(row.establishedPatients,'percentage', '%', '-', true)}}</div>
      <div class="bottom-cell"></div>
    </mat-cell>
  </ng-container>

  <ng-container *ngFor="let cpt of ConsultationCodes.codes" matColumnDef="countOf{{cpt}}">
    <mat-header-cell *matHeaderCellDef class="header{{cpt}} bold">{{cpt}}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="countOf{{cpt}}"><div class="top-cell">
      {{isValidOrElse(row.consultation,'cpt' + cpt, 'count', '-')}}</div>
      <div class="middle-cell">{{isValidOrElse(row.consultation, 'cpt' + cpt, 'percentage',
        '-', '%')}}</div><div class="bottom-cell">
        {{isValidOrElse(row.consultation, 'cpt' + cpt, benchmarkString, '-', '%')}}</div>
    </mat-cell>
  </ng-container>
  <ng-container  matColumnDef="totalConsultation">
    <mat-header-cell *matHeaderCellDef class="headerTotalConsultation bold">Total</mat-header-cell>
    <mat-cell *matCellDef="let row" class="totalConsultation"><div class="top-cell">{{valueFromOrElse(row.consultation,'count','', '-')}}</div>
      <div class="middle-cell">{{valueFromOrElse(row.consultation,'percentage', '%', '-', true)}}</div>
      <div class="bottom-cell"></div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
