<app-tab-navigation
  [tabs]="tabs"
  [chosenTab]="chosenTab"
  [tabUpdateAction]="multilevelTabChangedTo"
></app-tab-navigation>
<div [ngSwitch]="chosenTab">
  <div *ngSwitchCase="MultiLevelTab.BY_DEPARTMENT">
    <app-new-patient-visits-department-page
      [newPatientVisits]="departmentNpvSnapshotData"
      [benchmarkPercentile]="benchmarkPercentile"
      [benchmarkOption]="benchmarkOption"
      [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      [nodePath]="nodePath"
      [isCustom]="isCustom"
      [zeroSuppression]="zeroSuppression"
      [countOfSuppressedEntries]="suppressedDepartments"
      [columns]="departmentColumns"
      [displayedColumns]="displayedDepartmentColumns"
      [columnHeaders]="departmentColumnHeaders"
      [activeVarianceToggle]="activeVariance"
      [showExtendedBenchmarkOptions]="telehealthEnabled"
      [npvSortingCriteria]="npvSnapshotSortingCriteria"
    ></app-new-patient-visits-department-page>
  </div>
  <div *ngSwitchCase="MultiLevelTab.BY_SPECIALTY">
    <app-new-patient-visits-specialty-page
      [newPatientVisits]="specialtyNpvSnapshotData"
      [benchmarkPercentile]="benchmarkPercentile"
      [benchmarkOption]="benchmarkOption"
      [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      [nodePath]="nodePath"
      [isCustom]="isCustom"
      [zeroSuppression]="zeroSuppression"
      [countOfSuppressedEntries]="suppressedSpecialties"
      [columns]="specialtyColumns"
      [displayedColumns]="displayedSpecialtyColumns"
      [columnHeaders]="specialtyColumnHeaders"
      [activeVarianceToggle]="activeVariance"
      [showExtendedBenchmarkOptions]="telehealthEnabled"
      [npvSortingCriteria]="npvSnapshotSortingCriteria"
    ></app-new-patient-visits-specialty-page>
  </div>
  <div *ngSwitchCase="MultiLevelTab.BY_PROVIDER">
    <app-new-patient-visits-provider-page
      [newPatientVisits]="providerNpvSnapshotData"
      [benchmarkPercentile]="benchmarkPercentile"
      [benchmarkOption]="benchmarkOption"
      [viewCommunityBenchmarks]="viewCommunityBenchmarks"
      [nodePath]="nodePath"
      [isCustom]="isCustom"
      [zeroSuppression]="zeroSuppression"
      [countOfSuppressedEntries]="suppressedProviders"
      [columns]="providerColumns"
      [displayedColumns]="displayedProviderColumns"
      [columnHeaders]="providerColumnHeaders"
      [activeVarianceToggle]="activeVariance"
      [showExtendedBenchmarkOptions]="telehealthEnabled"
      [npvSortingCriteria]="npvSnapshotSortingCriteria"
    ></app-new-patient-visits-provider-page>
  </div>
  <div *ngSwitchCase="MultiLevelTab.TREND">
    <app-new-patient-visits-by-month-page [isMultilevel]="true" [showExtendedBenchmarkOptions]="telehealthEnabled">
    </app-new-patient-visits-by-month-page>
  </div>
</div>
<div *ngIf="locationTabs.includes(chosenTab)">
  <app-page-new-patient-visits-location
    [selectedLevelTab]="chosenTab"
    [benchmarkOption]="benchmarkOption"
    [benchmarkPercentile]="benchmarkPercentile"
    [isZeroSuppressionChecked]="zeroSuppression"
  ></app-page-new-patient-visits-location>
</div>
