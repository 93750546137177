import {Component, OnInit} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-wrvu-base',
  templateUrl: './wrvu-base.component.html',
  styleUrls: ['./wrvu-base.component.scss']
})
export class WrvuBaseComponent implements OnInit {

  subscription: Subscription;

  constructor() {
  }

  ngOnInit(): void {
  }

}
