import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {StyledTextToken, TextPosition} from '../shared/ui-helpers';

export interface HelpItem {
  id: string;
  appConfigName: string | AppConfigEntries;
  eventName: string;
  displayText: string;
  styledText?: StyledTextToken[];
}

export const standardHelpItems: HelpItem[] = [{
  id: 'glossary-link',
  appConfigName: AppConfigEntries.GLOSSARY,
  eventName: 'Glossary',
  displayText: 'Glossary'
}, {
  id: 'methodologyDocument-link',
  eventName: 'Methodology Document',
  appConfigName: AppConfigEntries.METHODOLOGY_DOCUMENT,
  displayText: 'Methodology Document'
}, {
  id: 'quickStartGuide-link',
  eventName: 'Quick Start Guide',
  appConfigName: AppConfigEntries.OPEN_QUICK_START_GUIDE,
  displayText: 'Quick Start Guide'
}, {
  id: 'release-notes',
  appConfigName: '',
  displayText: 'Release Notes',
  eventName: 'Release Notes'
}, {
  id: 'third-party-data-use',
  appConfigName: AppConfigEntries.THIRD_PARTY_DATA_USE,
  eventName: 'Third Party Data Use',
  displayText: '',
  styledText: [{
    text: '3',
    textPosition: TextPosition.STANDARD
  }, {
    text: 'rd',
    textPosition: TextPosition.SUPER
  }, {
    text: ' Party',
    textPosition: TextPosition.STANDARD
  }, {
    text: ' Data',
    textPosition: TextPosition.STANDARD
  }, {
    text: ' Use',
    textPosition: TextPosition.STANDARD
  }]
},
  {
    id: 'email-link',
    appConfigName: AppConfigEntries.SUPPORT_EMAIL,
    eventName: 'Contact Us',
    displayText: 'Contact Us'
  }];
