import {MetricType} from '../metric-types';
import {ExcelExportMetricId} from './export';

export interface ExportOption {
  id: ExcelExportMetricId;
  text: string;
  type: MetricType;
  parentId?: ExcelExportMetricId;
  children: ExportOption[];
  downloadable: boolean;
  selected?: boolean;
  loading?: boolean;
  displayed?: boolean;
}

const wrvuTrendExport: ExportOption = {
  id: ExcelExportMetricId.WRVU_TREND_VIEW,
  text: 'Trend View',
  type: MetricType.WorkRVUs,
  parentId: ExcelExportMetricId.WRVUS,
  children: [],
  downloadable: true
};

const wrvuDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.WRVU_BY_DEPARTMENT,
  text: 'By Department',
  type: MetricType.WorkRVUs,
  parentId: ExcelExportMetricId.WRVUS,
  children: [],
  downloadable: true
};

const wrvuSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.WRVU_BY_SPECIALTY,
  text: 'By Specialty',
  type: MetricType.WorkRVUs,
  parentId: ExcelExportMetricId.WRVUS,
  children: [],
  downloadable: true
};

const wrvuProviderExport: ExportOption = {
  id: ExcelExportMetricId.WRVU_BY_PROVIDER,
  text: 'By Provider',
  type: MetricType.WorkRVUs,
  parentId: ExcelExportMetricId.WRVUS,
  children: [],
  downloadable: true
};

const wrvuSpecialtyPerformanceExport: ExportOption = {
  id: ExcelExportMetricId.WRVU_SPECIALTY_PERFORMANCE,
  text: 'Specialty Performance',
  type: MetricType.WorkRVUs,
  parentId: ExcelExportMetricId.WRVUS,
  children: [],
  downloadable: true
};

const wRVUExports: ExportOption[] = [
  wrvuTrendExport,
  wrvuDepartmentExport,
  wrvuSpecialtyExport,
  wrvuProviderExport,
  wrvuSpecialtyPerformanceExport
];

const npvTrendExport: ExportOption = {
  id: ExcelExportMetricId.NPV_TREND,
  text: 'Trend View',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: [],
  downloadable: true
};

const npvDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.NPV_BY_DEPARTMENT,
  text: 'By Department',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: [],
  downloadable: true
};

const npvSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.NPV_BY_SPECIALTY,
  text: 'By Specialty',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: [],
  downloadable: true
};

const npvProviderExport: ExportOption = {
  id: ExcelExportMetricId.NPV_BY_PROVIDER,
  text: 'By Provider',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: [],
  downloadable: true
};

const npvLocationDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.NPV_BY_LOCATION_BY_DEPARTMENT,
  text: 'By Department',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: [],
  downloadable: true
};

const npvLocationSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.NPV_BY_LOCATION_BY_SPECIALTY,
  text: 'By Specialty',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: [],
  downloadable: true
};

const npvLocationProviderExport: ExportOption = {
  id: ExcelExportMetricId.NPV_BY_LOCATION_BY_PROVIDER,
  text: 'By Provider',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: [],
  downloadable: true
};

const npvLocationExports: ExportOption[] = [
  npvLocationDepartmentExport,
  npvLocationSpecialtyExport,
  npvLocationProviderExport
];

const npvLocationExport: ExportOption = {
  id: ExcelExportMetricId.NPV_BY_LOCATION,
  text: 'By Location',
  type: MetricType.NewPatientVisits,
  parentId: ExcelExportMetricId.NPV,
  children: npvLocationExports,
  downloadable: true
};

const npvExports: ExportOption[] = [
  npvTrendExport,
  npvDepartmentExport,
  npvSpecialtyExport,
  npvProviderExport,
  npvLocationExport
];

const emOutpatientDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT, // 34,
  text: 'Outpatient By Department',
  type: MetricType.EandMOutpatient,
  parentId: ExcelExportMetricId.EM_OUTPATIENT,
  children: [],
  downloadable: true
};

const emOutpatientSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY, // 35,
  text: 'Outpatient By Specialty',
  type: MetricType.EandMOutpatient,
  parentId: ExcelExportMetricId.EM_OUTPATIENT,
  children: [],
  downloadable: true
};

const emOutpatientProviderExport: ExportOption = {
  id: ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER, // 36,
  text: 'Outpatient By Provider',
  type: MetricType.EandMOutpatient,
  parentId: ExcelExportMetricId.EM_OUTPATIENT,
  children: [],
  downloadable: true
};

const emOutpatientTrendExport: ExportOption = {
  id: ExcelExportMetricId.EM_OUTPATIENT_TREND,
  text: 'Outpatient Trend',
  type: MetricType.EandMOutpatient,
  parentId: ExcelExportMetricId.EM_OUTPATIENT,
  children: [],
  downloadable: true
};

const emInpatientDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT, // 38,
  text: 'Inpatient By Department',
  type: MetricType.EandMInpatient,
  parentId: ExcelExportMetricId.EM_INPATIENT,
  children: [],
  downloadable: true
};

const emInpatientSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY, // 39,
  text: 'Inpatient By Specialty',
  type: MetricType.EandMInpatient,
  parentId: ExcelExportMetricId.EM_INPATIENT,
  children: [],
  downloadable: true
};

const emInpatientProviderExport: ExportOption = {
  id: ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER, // 40,
  text: 'Inpatient By Provider',
  type: MetricType.EandMInpatient,
  parentId: ExcelExportMetricId.EM_INPATIENT,
  children: [],
  downloadable: true
};

const emInpatientTrendExport: ExportOption = {
  id: ExcelExportMetricId.EM_INPATIENT_TREND,
  text: 'Inpatient Trend',
  type: MetricType.EandMInpatient,
  parentId: ExcelExportMetricId.EM_INPATIENT,
  children: [],
  downloadable: true
};

const emEmergencyMedicineDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT, // 42,
  text: 'Emergency Medicine By Department',
  type: MetricType.EandMEmergency,
  parentId: ExcelExportMetricId.EM_EMERGENCY_MEDICINE,
  children: [],
  downloadable: true
};

const emEmergencyMedicineSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY, // 43,
  text: 'Emergency Medicine By Specialty',
  type: MetricType.EandMEmergency,
  parentId: ExcelExportMetricId.EM_EMERGENCY_MEDICINE,
  children: [],
  downloadable: true
};

const emEmergencyMedicineProviderExport: ExportOption = {
  id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER, // 44,
  text: 'Emergency Medicine By Provider',
  type: MetricType.EandMEmergency,
  parentId: ExcelExportMetricId.EM_EMERGENCY_MEDICINE,
  children: [],
  downloadable: true
};

const emEmergencyMedicineTrendExport: ExportOption = {
  id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND,
  text: 'Emergency Medicine Trend',
  type: MetricType.EandMEmergency,
  parentId: ExcelExportMetricId.EM_EMERGENCY_MEDICINE,
  children: [],
  downloadable: true
};

const emOphthalmologyDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT, // 46,
  text: 'Ophthalmology By Department',
  type: MetricType.EandMOpthalmology,
  parentId: ExcelExportMetricId.EM_OPHTHALMOLOGY,
  children: [],
  downloadable: true
};

const emOphthalmologySpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY, // 47,
  text: 'Ophthalmology By Specialty',
  type: MetricType.EandMOpthalmology,
  parentId: ExcelExportMetricId.EM_OPHTHALMOLOGY,
  children: [],
  downloadable: true
};

const emOphthalmologyProviderExport: ExportOption = {
  id: ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER, // 48,
  text: 'Ophthalmology By Provider',
  type: MetricType.EandMOpthalmology,
  parentId: ExcelExportMetricId.EM_OPHTHALMOLOGY,
  children: [],
  downloadable: true
};

const emOphthalmologyTrendExport: ExportOption = {
  id: ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND,
  text: 'Ophthalmology Trend',
  type: MetricType.EandMOpthalmology,
  parentId: ExcelExportMetricId.EM_OPHTHALMOLOGY,
  children: [],
  downloadable: true
};

const emOutpatientExports: ExportOption[] = [
  emOutpatientDepartmentExport,
  emOutpatientSpecialtyExport,
  emOutpatientProviderExport,
  emOutpatientTrendExport
];

const emInpatientExports: ExportOption[] = [
  emInpatientDepartmentExport,
  emInpatientSpecialtyExport,
  emInpatientProviderExport,
  emInpatientTrendExport
];

const emEmergencyExports: ExportOption[] = [
  emEmergencyMedicineDepartmentExport,
  emEmergencyMedicineSpecialtyExport,
  emEmergencyMedicineProviderExport,
  emEmergencyMedicineTrendExport
];

const emOphthalmologyExports: ExportOption[] = [
  emOphthalmologyDepartmentExport,
  emOphthalmologySpecialtyExport,
  emOphthalmologyProviderExport,
  emOphthalmologyTrendExport
];

const emOutpatientExport: ExportOption = {
  id: ExcelExportMetricId.EM_OUTPATIENT, // 33,
  text: 'Outpatient View',
  type: MetricType.EandM,
  parentId: ExcelExportMetricId.EM,
  children: emOutpatientExports,
  downloadable: true
};

const emInpatientExport: ExportOption = {
  id: ExcelExportMetricId.EM_INPATIENT, // 37,
  text: 'Inpatient View',
  type: MetricType.EandM,
  parentId: ExcelExportMetricId.EM,
  children: emInpatientExports,
  downloadable: true
};

const emEmergencyMedicineExport: ExportOption = {
  id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE, // 41,
  text: 'Emergency Medicine View',
  type: MetricType.EandM,
  parentId: ExcelExportMetricId.EM,
  children: emEmergencyExports,
  downloadable: true
};

const emOphthalmologyExport: ExportOption = {
  id: ExcelExportMetricId.EM_OPHTHALMOLOGY, // 45,
  text: 'Ophthalmology View',
  type: MetricType.EandM,
  parentId: ExcelExportMetricId.EM,
  children: emOphthalmologyExports,
  downloadable: true
};

const emExports: ExportOption[] = [
  emOutpatientExport,
  emInpatientExport,
  emEmergencyMedicineExport,
  emOphthalmologyExport
];

const cfpFamilyExport: ExportOption = {
  id: ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_FAMILY,
  text: 'Cpt Family',
  type: MetricType.ClinicalFingerprint,
  parentId: ExcelExportMetricId.CLINICAL_FINGERPRINT,
  children: [],
  downloadable: true
};

const cfpRangeExport: ExportOption = {
  id: ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_RANGE,
  text: 'Cpt Ranges',
  type: MetricType.ClinicalFingerprint,
  parentId: ExcelExportMetricId.CLINICAL_FINGERPRINT,
  children: [],
  downloadable: true
};

const cfpCodeExport: ExportOption = {
  id: ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_CODE,
  text: 'Cpt Code',
  type: MetricType.ClinicalFingerprint,
  parentId: ExcelExportMetricId.CLINICAL_FINGERPRINT,
  children: [],
  downloadable: true
};

const cfpExports: ExportOption[] = [
  cfpFamilyExport,
  cfpRangeExport,
  cfpCodeExport
];

const collectionsSummaryExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_SUMMARY,
  text: 'Summary',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT,
  text: 'By Department',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY,
  text: 'By Specialty',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsProviderExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_BY_PROVIDER,
  text: 'By Provider',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsPayerExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_BY_PAYER,
  text: 'By Payer',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsPayerDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT,
  text: 'By Payer By Department',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsPayerSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY,
  text: 'By Payer By Specialty',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsPayerProviderExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER,
  text: 'By Payer By Provider',
  type: MetricType.Collections,
  parentId: ExcelExportMetricId.COLLECTIONS,
  children: [],
  downloadable: true
};

const collectionsExports: ExportOption[] = [
  collectionsPayerExport,
  collectionsSummaryExport,
  collectionsDepartmentExport,
  collectionsSpecialtyExport,
  collectionsProviderExport,
  collectionsPayerDepartmentExport,
  collectionsPayerSpecialtyExport,
  collectionsPayerProviderExport
];

const denialsDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.DENIALS_BY_DEPARTMENT,
  text: 'By Department',
  type: MetricType.Denials,
  parentId: ExcelExportMetricId.DENIALS,
  children: [],
  downloadable: true
};

const denialsSpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.DENIALS_BY_SPECIALTY,
  text: 'By Specialty',
  type: MetricType.Denials,
  parentId: ExcelExportMetricId.DENIALS,
  children: [],
  downloadable: true
};

const denialsProviderExport: ExportOption = {
  id: ExcelExportMetricId.DENIALS_BY_PROVIDER,
  text: 'By Provider',
  type: MetricType.Denials,
  parentId: ExcelExportMetricId.DENIALS,
  children: [],
  downloadable: true
};

const denialsPayerExport: ExportOption = {
  id: ExcelExportMetricId.DENIALS_BY_PAYER,
  text: 'By Payer',
  type: MetricType.Denials,
  parentId: ExcelExportMetricId.DENIALS,
  children: [],
  downloadable: true
};

const denialsExports: ExportOption[] = [
  denialsDepartmentExport,
  denialsSpecialtyExport,
  denialsProviderExport,
  denialsPayerExport
];

const procedureSummaryDepartmentFamilyExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY,
  text: 'By Department by CPT Family',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT,
  children: [],
  downloadable: true
};

const procedureSummaryDepartmentRangeExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE,
  text: 'By Department by CPT Range',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT,
  children: [],
  downloadable: true
};

const procedureSummaryDepartmentCodeExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE,
  text: 'By Department by CPT Code',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT,
  children: [],
  downloadable: true
};

const procedureSummarySpecialtyFamilyExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY,
  text: 'By Specialty by CPT Family',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY,
  children: [],
  downloadable: true
};

const procedureSummarySpecialtyRangeExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE,
  text: 'By Specialty by CPT Range',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY,
  children: [],
  downloadable: true
};

const procedureSummarySpecialtyCodeExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE,
  text: 'By Specialty by CPT Code',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY,
  children: [],
  downloadable: true
};

const procedureSummaryProviderFamilyExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY,
  text: 'By Provider by CPT Family',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER,
  children: [],
  downloadable: true
};

const procedureSummaryProviderRangeExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE,
  text: 'By Provider by CPT Range',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER,
  children: [],
  downloadable: true
};

const procedureSummaryProviderCodeExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE,
  text: 'By Provider by CPT Code',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER,
  children: [],
  downloadable: true
};

const procedureSummaryDepartmentExports: ExportOption[] = [
  procedureSummaryDepartmentFamilyExport,
  procedureSummaryDepartmentRangeExport,
  procedureSummaryDepartmentCodeExport
];

const procedureSummarySpecialtyExports: ExportOption[] = [
  procedureSummarySpecialtyFamilyExport,
  procedureSummarySpecialtyRangeExport,
  procedureSummarySpecialtyCodeExport
];

const procedureSummaryProviderExports: ExportOption[] = [
  procedureSummaryProviderFamilyExport,
  procedureSummaryProviderRangeExport,
  procedureSummaryProviderCodeExport
];

const procedureSummaryDepartmentExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT,
  text: 'By Department',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY,
  children: procedureSummaryDepartmentExports,
  downloadable: true
};

const procedureSummarySpecialtyExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY,
  text: 'By Specialty',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY,
  children: procedureSummarySpecialtyExports,
  downloadable: true
};

const procedureSummaryProviderExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER,
  text: 'By Provider',
  type: MetricType.ProcedureSummary,
  parentId: ExcelExportMetricId.PROCEDURE_SUMMARY,
  children: procedureSummaryProviderExports,
  downloadable: true
};

const procedureSummaryExports: ExportOption[] = [
  procedureSummaryDepartmentExport,
  procedureSummarySpecialtyExport,
  procedureSummaryProviderExport
];

const wRVUMetricExport: ExportOption = {
  id: ExcelExportMetricId.WRVUS,
  text: 'wRVUs',
  type: MetricType.None,
  parentId: ExcelExportMetricId.ALL_METRICS,
  children: wRVUExports,
  downloadable: false
};

const npvMetricExport: ExportOption = {
  id: ExcelExportMetricId.NPV,
  text: 'New Patient Visits',
  type: MetricType.None,
  parentId: ExcelExportMetricId.ALL_METRICS,
  children: npvExports,
  downloadable: false
};

const cfpMetricExport: ExportOption = {
  id: ExcelExportMetricId.CLINICAL_FINGERPRINT,
  text: 'Clinical Fingerprint',
  type: MetricType.None,
  parentId: ExcelExportMetricId.ALL_METRICS,
  children: cfpExports,
  downloadable: false
};

const procedureSummaryMetricExport: ExportOption = {
  id: ExcelExportMetricId.PROCEDURE_SUMMARY,
  text: 'Procedure Summary',
  type: MetricType.None,
  parentId: ExcelExportMetricId.ALL_METRICS,
  children: procedureSummaryExports,
  downloadable: false
};

const emMetricExport: ExportOption = {
  id: ExcelExportMetricId.EM,
  text: 'E&M',
  type: MetricType.None,
  parentId: ExcelExportMetricId.ALL_METRICS,
  children: emExports,
  downloadable: false
};

const collectionMetricExport: ExportOption = {
  id: ExcelExportMetricId.COLLECTIONS,
  text: 'Collections',
  type: MetricType.None,
  parentId: ExcelExportMetricId.ALL_METRICS,
  children: collectionsExports,
  downloadable: true
};

const denialMetricExport: ExportOption = {
  id: ExcelExportMetricId.DENIALS,
  text: 'Denials',
  type: MetricType.None,
  parentId: ExcelExportMetricId.ALL_METRICS,
  children: denialsExports,
  downloadable: true
};

export let FULL_METRIC_EXPORTS: ExportOption[] = [
  wRVUMetricExport,
  npvMetricExport,
  cfpMetricExport,
  procedureSummaryMetricExport,
  emMetricExport,
  collectionMetricExport,
  denialMetricExport
];

export const allMetricsExport: ExportOption = {
  id: ExcelExportMetricId.ALL_METRICS,
  text: 'All Metrics',
  type: MetricType.None,
  children: FULL_METRIC_EXPORTS,
  downloadable: true
};


// export const ALL_EXPORT_METRICS: { [key in ExcelExportMetricId]: ExportOption } = {
//   [ExcelExportMetricId.ALL_METRICS]: allMetricsExport,
//   [ExcelExportMetricId.WRVUS]: wRVUMetricExport,
//   [ExcelExportMetricId.WRVU_TREND_VIEW]: wrvuTrendExport,
//   [ExcelExportMetricId.WRVU_BY_DEPARTMENT]: wrvuDepartmentExport,
//   [ExcelExportMetricId.WRVU_BY_SPECIALTY]: wrvuSpecialtyExport,
//   [ExcelExportMetricId.WRVU_BY_PROVIDER]: wrvuProviderExport,
//   [ExcelExportMetricId.WRVU_SPECIALTY_PERFORMANCE]: wrvuSpecialtyPerformanceExport,
//   [ExcelExportMetricId.NPV]: npvMetricExport,
//   [ExcelExportMetricId.NPV_TREND]: npvTrendExport,
//   [ExcelExportMetricId.NPV_BY_DEPARTMENT]: npvDepartmentExport,
//   [ExcelExportMetricId.NPV_BY_SPECIALTY]: npvSpecialtyExport,
//   [ExcelExportMetricId.NPV_BY_PROVIDER]: npvProviderExport,
//   [ExcelExportMetricId.EM]: emMetricExport,
//   [ExcelExportMetricId.EM_OUTPATIENT]: emOutpatientExport,
//   [ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT]: emOutpatientDepartmentExport,
//   [ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY]: emOutpatientSpecialtyExport,
//   [ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER]: emOutpatientProviderExport,
//   [ExcelExportMetricId.EM_OUTPATIENT_TREND]: emOutpatientTrendExport,
//   [ExcelExportMetricId.EM_INPATIENT]: emInpatientExport,
//   [ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT]: emInpatientDepartmentExport,
//   [ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY]: emInpatientSpecialtyExport,
//   [ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER]: emInpatientProviderExport,
//   [ExcelExportMetricId.EM_INPATIENT_TREND]: emInpatientTrendExport,
//   [ExcelExportMetricId.EM_EMERGENCY_MEDICINE]: emEmergencyMedicineExport,
//   [ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT]: emEmergencyMedicineDepartmentExport,
//   [ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY]: emEmergencyMedicineSpecialtyExport,
//   [ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER]: emEmergencyMedicineProviderExport,
//   [ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND]: emEmergencyMedicineTrendExport,
//   [ExcelExportMetricId.EM_OPHTHALMOLOGY]: emOphthalmologyExport,
//   [ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT]: emOphthalmologyDepartmentExport,
//   [ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY]: emOphthalmologySpecialtyExport,
//   [ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER]: emOphthalmologyProviderExport,
//   [ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND]: emOphthalmologyTrendExport,
//   [ExcelExportMetricId.CLINICAL_FINGERPRINT]: cfpMetricExport,
//   [ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_FAMILY]: cfpFamilyExport,
//   [ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_RANGE]: cfpRangeExport,
//   [ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_CODE]: cfpCodeExport,
//   [ExcelExportMetricId.COLLECTIONS]: collectionMetricExport,
//   [ExcelExportMetricId.COLLECTIONS_SUMMARY]: collectionsSummaryExport,
//   [ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT]: collectionsDepartmentExport,
//   [ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY]: collectionsSpecialtyExport,
//   [ExcelExportMetricId.COLLECTIONS_BY_PROVIDER]: collectionsProviderExport,
//   [ExcelExportMetricId.COLLECTIONS_BY_PAYER]: collectionsPayerExport,
//   [ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT]: collectionsPayerDepartmentExport,
//   [ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY]: collectionsPayerSpecialtyExport,
//   [ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER]: collectionsPayerProviderExport,
//   [ExcelExportMetricId.DENIALS]: denialMetricExport,
//   [ExcelExportMetricId.DENIALS_BY_DEPARTMENT]: denialsDepartmentExport,
//   [ExcelExportMetricId.DENIALS_BY_SPECIALTY]: denialsSpecialtyExport,
//   [ExcelExportMetricId.DENIALS_BY_PROVIDER]: denialsProviderExport,
//   [ExcelExportMetricId.DENIALS_BY_PAYER]: denialsPayerExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY]: procedureSummaryMetricExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT]: procedureSummaryDepartmentExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY]: procedureSummarySpecialtyExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER]: procedureSummaryProviderExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY]: procedureSummaryDepartmentFamilyExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE]: procedureSummaryDepartmentRangeExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE]: procedureSummaryDepartmentCodeExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY]: procedureSummarySpecialtyFamilyExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE]: procedureSummarySpecialtyRangeExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE]: procedureSummarySpecialtyCodeExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY]: procedureSummaryProviderFamilyExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE]: procedureSummaryProviderRangeExport,
//   [ExcelExportMetricId.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE]: procedureSummaryProviderCodeExport
// };
