import {Component, Input, OnInit} from '@angular/core';
import {CptCategories, EvaluationManagementSummary} from '../../../shared/models';
import {MatTableDataSource} from '@angular/material/table';
import {select} from '@angular-redux/store';
import {INITIAL_STATE} from '../../../store/IAppState';
import {combineLatest, Observable} from 'rxjs';
import * as _ from 'lodash';
import {isValidOrElse} from '../../../shared/null-helpers';
import {
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  isValid,
  valueFrom,
  valueFromOrDash
} from '../../em-helpers';
import {BenchmarkOption} from '../../../shared/enums';

@Component({
  selector: 'app-em-eye-exam-summary-table',
  templateUrl: './em-eye-exam-summary-table.component.html',
  styleUrls: ['./em-eye-exam-summary-table.component.scss']
})

export class EmEyeExamSummaryTableComponent implements OnInit {

  displayedData: EvaluationManagementEyeExamSummary[];
  dataSource: MatTableDataSource<EvaluationManagementEyeExamSummary>;
  displayedColumns: string[];
  showProgressBar = true;
  isValidOrElse = isValidOrElse;
  benchmarkString = 'benchmarkPercentage';
  benchmarkDisplay: string;

  @Input() showDataTable: boolean;

  @select(['data', 'summaryEvaluationManagementData'])
  private readonly summaryEvaluationManagementData$: Observable<EvaluationManagementSummary>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly emBenchmarkOption$: Observable<BenchmarkOption>;

  constructor() {
  }

  ngOnInit() {
    combineLatest([this.summaryEvaluationManagementData$, this.emBenchmarkOption$])
      .subscribe(([summaryEvaluationManagementData, benchmarkOption]:
                    [EvaluationManagementSummary, BenchmarkOption]) => {
        this.showProgressBar = _.isEqual(summaryEvaluationManagementData, INITIAL_STATE.data.summaryEvaluationManagementData);
        this.benchmarkDisplay = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
        this.benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
        this.displayedData = this.ophthalmologySummaryFrom(summaryEvaluationManagementData.totals);

        this.dataSource = new MatTableDataSource<EvaluationManagementEyeExamSummary>(this.displayedData);
        this.displayedColumns = ['encounter'];

        this.displayedColumns.push('countOf92002', 'countOf92004', 'totalEyeExamNewPatients');
        this.displayedColumns.push('countOf92012', 'countOf92014', 'totalEyeExamEstablishedPatients');
      });
  }

  ophthalmologySummaryFrom(totalCptCounts: CptCategories): EvaluationManagementEyeExamSummary[] {
    let emEyeExamSummary: EvaluationManagementEyeExamSummary[];
    if (!isValid(totalCptCounts)) {
      emEyeExamSummary = [
        {
          encounterMetric: '# of Encounters',
          eyeExamNewPatients: emptyEyeExamVisit(),
          eyeExamEstablishedPatients: emptyEyeExamVisit(),
        },
        {
          encounterMetric: '% Coding Distribution',
          eyeExamNewPatients: emptyEyeExamVisit(),
          eyeExamEstablishedPatients: emptyEyeExamVisit(),
        },
        {
          encounterMetric: `${this.benchmarkDisplay}`,
          eyeExamNewPatients: emptyEyeExamVisit(),
          eyeExamEstablishedPatients: emptyEyeExamVisit(),
        },
      ];
    } else {

      emEyeExamSummary = [
        {
          encounterMetric: '# of Encounters',
          eyeExamNewPatients: isValid(totalCptCounts.eyeExamNewPatient) ?
            valueFrom(totalCptCounts.eyeExamNewPatient, 'count') : emptyEyeExamVisit(),
          eyeExamEstablishedPatients: isValid(totalCptCounts.eyeExamEstablishedPatient) ?
            valueFrom(totalCptCounts.eyeExamEstablishedPatient, 'count') : emptyEyeExamVisit(),
        },
        {
          encounterMetric: '% Coding Distribution',
          eyeExamNewPatients: isValid(totalCptCounts.eyeExamNewPatient) ?
            valueFrom(totalCptCounts.eyeExamNewPatient, 'percentage', '%', true) : emptyEyeExamVisit(),
          eyeExamEstablishedPatients: isValid(totalCptCounts.eyeExamEstablishedPatient) ?
            valueFrom(totalCptCounts.eyeExamEstablishedPatient, 'percentage', '%', true) : emptyEyeExamVisit(),
        },
        {
          encounterMetric: `${this.benchmarkDisplay}`,
          eyeExamNewPatients: isValid(totalCptCounts.eyeExamNewPatient) ?
            valueFromOrDash(totalCptCounts.eyeExamNewPatient, this.benchmarkString, '%') : emptyEyeExamVisit(),
          eyeExamEstablishedPatients: isValid(totalCptCounts.eyeExamEstablishedPatient) ?
            valueFromOrDash(totalCptCounts.eyeExamEstablishedPatient, this.benchmarkString, '%') : emptyEyeExamVisit(),

        },
      ];
    }
    return emEyeExamSummary;
  }
}

function emptyEyeExamVisit() {
  return {
    cpt92002: '-',
    cpt92004: '-',
    cpt92012: '-',
    cpt92014: '-',
    total: '-',
  };
}

export interface EvaluationManagementEyeExamSummary {
  encounterMetric: string;
  eyeExamNewPatients: any;
  eyeExamEstablishedPatients: any;
}
