import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {CurrentSelections, IAppState, ProcedureSummaryVariables} from '../store/IAppState';
import {
  AppAction,
  cFPChargesDisplayChangedTo,
  cFTEAdjustedCountDisplayChangedTo,
  chargeDisplayChangedTo,
  chargesTerminallyDeniedChangedTo,
  collectionsChargeDisplayedChangedTo,
  denialsChargesDisplayedChangedTo,
  deniedCPTsPaidChangedTo,
  expectedPaymentsDisplayedChangedTo,
  patientVisitsCountsDisplayChangedTo,
  previousPatientVisitsCountsDisplayChangedTo,
  previousTimePeriodDisplayChanged,
  terminalDenialRateChangedTo
} from '../store/actions';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {MetricType} from '../shared/metric-types';

@Component({
  selector: 'app-variable-container',
  templateUrl: './variable-container.component.html',
  styleUrls: ['./variable-container.component.scss'],
})
export class VariableContainerComponent implements OnChanges, OnDestroy {

  @Input() page: string;
  @Input() showVariableMenu: boolean;
  @Input() variables: Variable[];
  @Input() disabled: boolean;

  @select(['display'])
  private readonly display$: Observable<CurrentSelections>;
  @select(['display', 'procedureSummaryVariables'])
  private readonly procedureSummaryVariables$: Observable<ProcedureSummaryVariables>;
  subscription: Subscription;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService) { }

  ngOnChanges() {
    this.subscription = combineLatest([
      this.display$.pipe(distinctUntilChanged((display1, display2) => _.isEqual(display1, display2))),
      this.procedureSummaryVariables$.pipe(distinctUntilChanged((display1, display2) =>
        _.isEqual(display1, display2)))])
      .subscribe(([display, procedureSummaryVariables]: [CurrentSelections, ProcedureSummaryVariables]) => {
        if (this.variables) {
          let displayValue;
          this.variables.forEach(variable => {
            switch (variable.metric) {
              case MetricType.ProcedureSummary:
                displayValue = procedureSummaryVariables[variable.reducerField];
                break;
              default:
                displayValue = display[variable.reducerField];
            }
            if (displayValue !== null) {
              variable.display = !!displayValue;
            }
          });
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  showOrHideVariable(variable: Variable) {
    this.eliminateAllOtherAddOnVariables(variable.reducerField);
    this.ngRedux.dispatch(variable.dispatchAction(!variable.display));
    if (!variable.display) {
      this.analyticsService.handleGoogleAnalytics(this.page, 'View Variable', variable.name);
    }
    if (variable.name === 'Previous Date Range % Patient Visits') {
      this.analyticsService.handleGoogleAnalytics('NPV Trend Page', 'PTP Variable', 'Toggle');
    }
  }

  eliminateAllOtherAddOnVariables(reducerField: DisplayField): void {
    switch (reducerField) {
      // cfp
      case 'cfpChargesDisplayed':
        this.ngRedux.dispatch(cFTEAdjustedCountDisplayChangedTo(false));
        break;
      case 'cFTEAdjustedCountDisplayed':
        this.ngRedux.dispatch(cFPChargesDisplayChangedTo(false));
        break;
       // wRVU page
      case 'charges':
        this.ngRedux.dispatch(previousTimePeriodDisplayChanged(false));
        break;
      case 'previousTimePeriod':
        this.ngRedux.dispatch(chargeDisplayChangedTo(false));
        break;
        // NPV page
      case 'patientVisitCounts':
        this.ngRedux.dispatch(previousPatientVisitsCountsDisplayChangedTo(false));
        break;
      case 'previousPatientVisitCounts':
        this.ngRedux.dispatch(patientVisitsCountsDisplayChangedTo(false));
        break;
        // Collections page
      case 'collectionsChargeDisplayed':
        this.ngRedux.dispatch(expectedPaymentsDisplayedChangedTo(false));
        break;
      case 'expectedPaymentsDisplayed':
        this.ngRedux.dispatch(collectionsChargeDisplayedChangedTo(false));
        break;
        // denials page
      case 'chargesTerminallyDeniedDisplayed':
        this.ngRedux.dispatch(denialsChargesDisplayedChangedTo(false));
        this.ngRedux.dispatch(deniedCPTsPaidChangedTo(false));
        this.ngRedux.dispatch(terminalDenialRateChangedTo(false));
        break;
      case 'denialsChargesDisplayed':
        this.ngRedux.dispatch(chargesTerminallyDeniedChangedTo(false));
        this.ngRedux.dispatch(deniedCPTsPaidChangedTo(false));
        this.ngRedux.dispatch(terminalDenialRateChangedTo(false));
        break;
      case 'deniedCPTsPaidDisplayed':
        this.ngRedux.dispatch(chargesTerminallyDeniedChangedTo(false));
        this.ngRedux.dispatch(denialsChargesDisplayedChangedTo(false));
        this.ngRedux.dispatch(terminalDenialRateChangedTo(false));
        break;
        case 'terminalDenialRateDisplayed':
        this.ngRedux.dispatch(chargesTerminallyDeniedChangedTo(false));
        this.ngRedux.dispatch(denialsChargesDisplayedChangedTo(false));
        this.ngRedux.dispatch(deniedCPTsPaidChangedTo(false));
        break;
      }
  }
}

export interface Variable {
  name: string;
  display: boolean;
  reducerField: DisplayField;
  metric?: MetricType;
  dispatchAction(display: boolean): AppAction;
}

export enum DisplayField {
  PatientVisitCounts = 'patientVisitCounts',
  PreviousPatientVisitCounts = 'previousPatientVisitCounts',
  Charges = 'charges',
  PreviousTimePeriod = 'previousTimePeriod',
  ExpectedPayments = 'expectedPaymentsDisplayed',
  CollectionsCharge = 'collectionsChargeDisplayed',
  CFTEAdjustedCount = 'cFTEAdjustedCountDisplayed',
  CFPCharges = 'cfpChargesDisplayed',
  DenialsCharge = 'denialsChargesDisplayed',
  ChargesTerminallyDenied = 'chargesTerminallyDeniedDisplayed',
  DeniedCPTSPaid = 'deniedCPTsPaidDisplayed',
  TerminalDenialRate = 'terminalDenialRateDisplayed',
  ProcedureSummaryCharges = 'charges'
}
