import {OktaAuthGuard, OktaCallbackComponent} from '@okta/okta-angular';
import {Routes} from '@angular/router';
import {DepartmentProductivityComponent} from './department-productivity/department-productivity.component';
import {BaseComponent} from './base/base.component';
import {UnauthorizedErrorPageComponent} from './unauthorized-error-page/unauthorized-error-page.component';
import {complianceRoute} from './shared/routes';
import {OverviewPageComponent} from './overview/page/overview-page.component';
import {NoAccessPageComponent} from './no-access-page/no-access-page.component';
import {ComplianceNoticeComponent} from './compliance-notice/compliance-notice.component';
import {ComplianceGuard} from './compliance-notice/compliance.guard';
import {WrvuBaseComponent} from './productivity-summary/provider/wrvu-base/wrvu-base.component';
import {NpvBaseComponent} from './new-patient-visits/components/provider/npv-base/npv-base.component';
import {WrvuMultilevelComponent} from './productivity-summary/provider/wrvu-multilevel/wrvu-multilevel.component';
import {NpvMultilevelComponent} from './new-patient-visits/components/provider/npv-multilevel/npv-multilevel.component';
import {
  EmPageMultilevelComponent
} from './evaluation-management/components/em-page-multilevel/em-page-multilevel.component';
import {
  PayerCollectionsMultilevelComponent
} from './collections/payer-collections/payer-collections-multilevel/payer-collections-multilevel.component';
import {BatchExportPdfComponent} from './batch-export/batch-export-pdf/batch-export-pdf.component';
import {DenialsMultilevelComponent} from './denials/denials-multilevel/denials-multilevel.component';
import {BatchExportMlPdfComponent} from './batch-export/em-ml-batch-export-pdf/batch-export-ml-pdf.component';
import {ProcedureSummaryComponent} from './clinical-fingerprint/procedure-summary/procedure-summary.component';
import {NpvMlBatchExportPdfComponent} from './batch-export/npv-ml-batch-export-pdf/npv-ml-batch-export-pdf.component';
import {ControlPanelComponent} from './control-panel/control-panel.component';
import {FeatureConfigGuard} from './guards/feature-config-guard';
import {TrendEmBatchExportComponent} from './batch-export/trend-em-batch-export/trend-em-batch-export.component';
import {
  PageNewPatientVisitsLocationComponent
} from './new-patient-visits/components/page-new-patient-visits-location/page-new-patient-visits-location.component';
import {ClinicalFingerprintComponent} from './clinical-fingerprint/clinical-fingerprint.component';
import {CollectionsMultilevelComponent} from './collections/collections-multilevel/collections-multilevel.component';
import {NoAccessPageBatchExportComponent} from './no-access-page-batch-export/no-access-page-batch-export.component';
import {AdoTestingComponent} from './test-ado-page/ado-testing.component';

const unauthorizedError = 'error';
const noAccess = 'noaccess';
const em = 'em';
const batchExportError = 'batchExportError';

export const unauthorizedErrorRoute = `/${unauthorizedError}`;

export const noAccessRoute = `/${noAccess}`;

export const batchExportErrorRoute = `/${batchExportError}`;

export class Routing {
  public static readonly routes: Routes = [
    { path: '', redirectTo: complianceRoute, pathMatch: 'full',
    data: { title: 'CPSC - Vizient & AAMC' } },
    {
      path: 'compliance', component: ComplianceNoticeComponent, canActivate: [OktaAuthGuard]
      , data: {title: 'CPSC - Vizient & AAMC'}
    },
    {
      path: 'productivity',
      component: BaseComponent,
      canActivate: [OktaAuthGuard, ComplianceGuard],
      children: [
        {
          path: 'pdf',
          component: OverviewPageComponent,
          data: {title: 'Overview - CPSC - Vizient & AAMC'}
        },
        {
          path: 'overview',
          component: OverviewPageComponent,
          data: {title: 'Overview - CPSC - Vizient & AAMC'},
          children: [
            {
              path: 'department', component: OverviewPageComponent, pathMatch: 'full',
              data: {title: 'Overview - CPSC - Vizient & AAMC'}
            }]
        },
        {
          path: 'wRVUs',
          component: DepartmentProductivityComponent,
          children: [
            {
              path: '', component: WrvuBaseComponent, pathMatch: 'full',
              data: {title: 'wRVUs - CPSC - Vizient & AAMC'}
            },
            {
              path: 'trend', component: WrvuMultilevelComponent,
              data: {title: 'wRVUs - CPSC - Vizient & AAMC'}
            },
            {
              path: 'provider', component: WrvuMultilevelComponent,
              data: {title: 'wRVUs - CPSC - Vizient & AAMC'}
            },
            {
              path: 'specialty', component: WrvuMultilevelComponent,
              data: {title: 'wRVUs - CPSC - Vizient & AAMC'}
            },
            {
              path: 'department', component: WrvuMultilevelComponent,
              data: {title: 'wRVUs - CPSC - Vizient & AAMC'}
            },
            {
              path: 'specialtyPerformance', component: WrvuMultilevelComponent,
              data: {title: 'wRVUs - CPSC - Vizient & AAMC'}
            },
            {
              path: 'multiLevelTrend', component: WrvuMultilevelComponent,
              data: {title: 'wRVUs - CPSC - Vizient & AAMC'}
            },
          ]
        },
        {
          path: 'cfp',
          component: DepartmentProductivityComponent,
          children: [
            {
              path: '', redirectTo: 'snapshot', pathMatch: 'full'
              , data: {title: 'Clinical Fingerprint - CPSC - Vizient & AAMC'}
            },
            {
              path: 'snapshot', component: ClinicalFingerprintComponent
              , data: {title: 'Clinical Fingerprint - CPSC - Vizient & AAMC'}
            }
          ]
        },
        {
          path: 'collections',
          component: DepartmentProductivityComponent,
          children: [
            {
              path: '', redirectTo: 'summary', pathMatch: 'full'
              , data: {title: 'collections - CPSC - Vizient & AAMC'}
            },
            {
              path: 'department', component: CollectionsMultilevelComponent
              , data: {title: 'collections - CPSC - Vizient & AAMC'}
            },
            {
              path: 'provider', component: CollectionsMultilevelComponent
              , data: {title: 'collections - CPSC - Vizient & AAMC'}
            },
            {
              path: 'specialty', component: CollectionsMultilevelComponent
              , data: {title: 'collections - CPSC - Vizient & AAMC'}
            },
            {
              path: 'payer', component: CollectionsMultilevelComponent
              , data: {title: 'collections - CPSC - Vizient & AAMC'},
              children: [
                {
                  path: '', component: PayerCollectionsMultilevelComponent
                  , data: {title: 'collections - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'department', component: PayerCollectionsMultilevelComponent
                  , data: {title: 'collections - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'specialty', component: PayerCollectionsMultilevelComponent
                  , data: {title: 'collections - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'provider', component: PayerCollectionsMultilevelComponent
                  , data: {title: 'collections - CPSC - Vizient & AAMC'}
                },
              ]
            },
            {
              path: 'summary', component: CollectionsMultilevelComponent
              , data: {title: 'collections - CPSC - Vizient & AAMC'}
            },
          ]
        },
        {
          path: 'denials',
          component: DepartmentProductivityComponent,
          children: [
            {
              path: '', component: DenialsMultilevelComponent, pathMatch: 'full',
              data: {title: 'denials - CPSC - Vizient & AAMC'}
            },
            {
              path: 'department', component: DenialsMultilevelComponent
              , data: {title: 'denials - CPSC - Vizient & AAMC'}
            },
            {
              path: 'provider', component: DenialsMultilevelComponent
              , data: {title: 'denials - CPSC - Vizient & AAMC'}
            },
            {
              path: 'specialty', component: DenialsMultilevelComponent
              , data: {title: 'denials - CPSC - Vizient & AAMC'}
            },
            {
              path: 'payer', component: DenialsMultilevelComponent
              , data: {title: 'denials - CPSC - Vizient & AAMC'},
            },
          ]
        },
        {
          path: em,
          component: EmPageMultilevelComponent,
          children: [
            {
              path: '', redirectTo: 'outpatient', pathMatch: 'full'
              , data: {title: 'E&M - CPSC - Vizient & AAMC'}
            },
            {
              path: 'outpatient', component: EmPageMultilevelComponent
              , data: {title: 'E&M - CPSC - Vizient & AAMC'},
              children: [
                {
                  path: '', component: EmPageMultilevelComponent, pathMatch: 'full'
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'department', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'specialty', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'provider', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
              ]
            },
            {
              path: 'inpatient', component: EmPageMultilevelComponent
              , data: {title: 'E&M - CPSC - Vizient & AAMC'},
              children: [
                {
                  path: '', component: EmPageMultilevelComponent, pathMatch: 'full'
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'department', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'specialty', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'provider', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
              ]
            },

            {
              path: 'emergencyMedicine', component: EmPageMultilevelComponent
              , data: {title: 'E&M - CPSC - Vizient & AAMC'},
              children: [
                {
                  path: '', component: EmPageMultilevelComponent, pathMatch: 'full'
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'department', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'specialty', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'provider', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
              ]
            },
            {
              path: 'ophthalmology', component: EmPageMultilevelComponent
              , data: {title: 'E&M - CPSC - Vizient & AAMC'},
              children: [
                {
                  path: '', component: EmPageMultilevelComponent, pathMatch: 'full'
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'department', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'specialty', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
                {
                  path: 'provider', component: EmPageMultilevelComponent
                  , data: {title: 'E&M - CPSC - Vizient & AAMC'}
                },
              ]
            },
          ]
        },
        {
          path: 'npv',
          component: DepartmentProductivityComponent,
          children: [
            {
              path: '', component: NpvBaseComponent, pathMatch: 'full',
              data: {title: 'New Patients Visits - CPSC - Vizient & AAMC'}
            },
            {
              path: 'trend', component: NpvMultilevelComponent,
              data: {title: 'New Patients Visits - CPSC - Vizient & AAMC'}
            },
            {
              path: 'provider', component: NpvMultilevelComponent,
              data: {title: 'New Patients Visits - CPSC - Vizient & AAMC'}
            },
            {
              path: 'specialty', component: NpvMultilevelComponent,
              data: {title: 'New Patients Visits - CPSC - Vizient & AAMC'}
            },
            {
              path: 'department', component: NpvMultilevelComponent,
              data: {title: 'New Patients Visits - CPSC - Vizient & AAMC'}
            },
            {
              path: 'multiLevelTrend', component: NpvMultilevelComponent,
              data: {title: 'New Patients Visits - CPSC - Vizient & AAMC'}
            },
            {
              path: 'location', component: PageNewPatientVisitsLocationComponent,
              data: {title: 'New Patients Visits - CPSC - Vizient & AAMC'}
            }
          ]
        },
        {
          path: 'proceduresummary',
          component: ProcedureSummaryComponent,
        },
        {
          path: 'feature-toggle', component: ControlPanelComponent,
          canActivate: [FeatureConfigGuard]
        }
      ],
    },
    {path: 'implicit/callback', component: OktaCallbackComponent},
    {
      path: unauthorizedError, component: UnauthorizedErrorPageComponent,
      data: {title: 'Error - CPSC - Vizient & AAMC'}
    },
    {
      path: batchExportError, component: NoAccessPageBatchExportComponent,
      data: {title: 'Batch Export Error - CPSC - Vizient & AAMC'}
    },
    {
      path: noAccess, component: NoAccessPageComponent,
      data: {title: 'No Access - CPSC - Vizient & AAMC'},
    },
    {
      path: 'batchPdfExport/:reportId',
      component: BatchExportPdfComponent
    },
    {
      path: 'batchPdfExport/:reportHeaderId/:reportId/:email/:guid',
      component: BatchExportPdfComponent
    },
    {
      path: 'batchPdfEmExport/:reportId',
      component: BatchExportMlPdfComponent
    },
    {
      path: 'batchPdfEmExport/:reportHeaderId/:reportId/:email/:guid',
      component: BatchExportMlPdfComponent
    },
    {
      path: 'batchPdfNpvExport/:reportId',
      component: NpvMlBatchExportPdfComponent
    },
    {
      path: 'batchPdfNpvExport/:reportHeaderId/:reportId/:email/:guid',
      component: NpvMlBatchExportPdfComponent
    },
    {
      path: 'batchPdfEmTrendExport/:reportId',
      component: TrendEmBatchExportComponent
    },
    {
      path: 'batchPdfEmTrendExport/:reportHeaderId/:reportId/:email/:guid',
      component: TrendEmBatchExportComponent
    },
    {
      path: 'adotesting',
      component: AdoTestingComponent
    },
  ];
}
