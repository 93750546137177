import {Injectable, InjectionToken} from '@angular/core';
import {FilterCriteria} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.local';
import {CfpMultiLevelData, ClinicalSummaryConsolidation} from '../ClinicalSummary';
import {DatasetType} from '../../shared/enums';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const ClinicalSummaryServiceToken = new InjectionToken<ClinicalSummaryService>(
  'Clinical Summary Service');

export interface ClinicalSummaryService {
  getClinicalSummaryConsolidation(filterCriteria: FilterCriteria, viewType: number): Observable<ClinicalSummaryConsolidation>;

  getClinicalFingerprintByMultilevel(filterCriteria: FilterCriteria, requestedDataset: DatasetType): Observable<CfpMultiLevelData>;
}

@Injectable()
export class HttpClinicalSummaryService implements ClinicalSummaryService {
  constructor(private httpClient: HttpClient) {
  }

  getClinicalSummaryConsolidation(filterCriteria: FilterCriteria, viewType: number): Observable<ClinicalSummaryConsolidation> {
    const baseUrl = `${environment.api}/api/cptClinicalSummaryByNodePath`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<ClinicalSummaryConsolidation>(
      baseUrl,
      postBody,
      {
        params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())
          .set('viewType', viewType.toString())
      }
    );
  }

  getClinicalFingerprintByMultilevel(filterCriteria: FilterCriteria, requestedDataset: DatasetType): Observable<CfpMultiLevelData> {
    const baseUrl = `${environment.api}/api/cfpSummaryByMultilevelNodePath`;
    const postBody = getRequestBodyFromFilter(filterCriteria);
    return this.httpClient.post<CfpMultiLevelData>(
      baseUrl,
      postBody,
      {
        params: new HttpParams()
          .set('memberKey', filterCriteria.memberKey.toString())
          .set('dataset', requestedDataset.toString())
      }
    );
  }
}
