<!--suppress HtmlUnknownTarget -->
<div class="error-page">
<!--  <app-header></app-header>-->
  <div class="error-page-body">
    <img src="assets/cloud.png"/>
    <p class="something-wrong">Something went wrong</p>
    <p class="if-you-have-question">If you have questions please contact <span>CPSCSupport@vizientinc.com</span></p>
<!--    <button id="return-home-button" mat-raised-button (click)="logout()" >Return to Home Page</button>-->
    <button id="return-home-button" mat-raised-button>Return to Home Page</button>
  </div>
</div>
