import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {
  ApplicationConfigurationServiceToken,
  HttpApplicationConfigurationService
} from '../services/app-configuration.service';
import {NgRedux, select} from '@angular-redux/store';
import {UserProfile} from '../shared/models';
import {IAppState} from '../store/IAppState';

@Injectable({
  providedIn: 'root'
})
export class FeatureConfigGuard implements CanActivate {

  @select(['data', 'userProfile'])
  readonly userProfile$: Observable<UserProfile>;

  userProfile: UserProfile;

  constructor(
    private readonly ngRedux: NgRedux<IAppState>,
    @Inject(ApplicationConfigurationServiceToken)
    private readonly appConfigService: HttpApplicationConfigurationService
  ) {
    this.userProfile$.subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

   canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.userProfile.email?.trim() === '') {return true; }

    return this.appConfigService.grantAccessToConfigSettings(this.userProfile.email);
  }

}
