import {LegendColor, LegendStyle} from '../../../shared/enums';
import {MetricType} from '../../../shared/metric-types';
import {Legend} from '../../../shared/models';
import {HasBenchmarks} from '../../../shared/models';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../../shared/benchmark-types';

export const specialtyPerformanceActualWrvuLegend: Legend = {
  text: 'Specialty Performance Actual wRVUs',
  color: LegendColor.PURPLE,
  style: LegendStyle.CIRCLE,
  metric: MetricType.WorkRVUs,
  showPercentileControl: false,
  showBenchmarkOptionControl: false
};

export const specialtyPerformanceCfteAdjWrvuLegend: Legend = {
  text: 'Specialty Performance cFTE adj. wRVUs',
  color: LegendColor.BLUE,
  style: LegendStyle.CIRCLE,
  metric: MetricType.WorkRVUs,
  showPercentileControl: false,
  showBenchmarkOptionControl: false
};

export const wrvuBenchmarkLegend: Legend = {
  text: 'wRVU Benchmark:',
  color: LegendColor.GREY,
  style: LegendStyle.SQUARE,
  metric: MetricType.WorkRVUs,
  showPercentileControl: true,
  showBenchmarkOptionControl: true
};

export const varianceGraphLegendsForWrvus: Legend[] = [{
  text: 'Above Benchmark',
  color: LegendColor.GREEN,
  metric: MetricType.WorkRVUs,
  style: LegendStyle.SQUARE,
  showPercentileControl: false
},
  {
    text: 'Below Benchmark',
    color: LegendColor.RED,
    metric: MetricType.WorkRVUs,
    style: LegendStyle.SQUARE,
    showPercentileControl: false
  }, wrvuBenchmarkLegend
];

export const cfteWrvuLegend: Legend = {
  text: 'cFTE adj. wRVUs',
  color: LegendColor.TEAL,
  style: LegendStyle.SQUARE,
  metric: MetricType.WorkRVUs,
  showPercentileControl: false,
  showBenchmarkOptionControl: false
};

export const actualWrvuLegend: Legend = {
  text: 'Actual wRVUs',
  color: LegendColor.TEAL,
  style: LegendStyle.SQUARE,
  metric: MetricType.WorkRVUs,
  showPercentileControl: false,
  showBenchmarkOptionControl: false
};

export const chargesLegendForWrvus: Legend = {
  text: 'Charges',
  color: LegendColor.ORANGE,
  metric: MetricType.WorkRVUs,
  style: LegendStyle.CIRCLE,
  showBenchmarkOptionControl: false,
  showPercentileControl: false
};

export function doesWrvuDataHaveValidAcademicBenchmarkValue(wrvuData: HasBenchmarks[]): boolean {
  return !!wrvuData.find(x =>
    x.benchmark25thPercentile ||
    x.benchmark50thPercentile ||
    x.benchmark65thPercentile ||
    x.benchmark75thPercentile ||
    x.benchmark90thPercentile ||
    x.benchmarkMean
  );
}

export function doesWrvuDataHaveValidCommunityBenchmarkValue(wrvuData: HasBenchmarks[]): boolean {
  return !!wrvuData.find(x =>
    x.communityBenchmark25thPercentile ||
    x.communityBenchmark50thPercentile ||
    x.communityBenchmark65thPercentile ||
    x.communityBenchmark75thPercentile ||
    x.communityBenchmark90thPercentile ||
    x.communityBenchmarkMean
  );
}


export function getVarianceKey (benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean): string {
  let varianceKey = `variance${readableNameOfColumnDef(benchmark)}`;
  if (viewCommunityBenchmarks) {
    varianceKey = `communityVariance${readableNameOfColumnDef(benchmark)}`;
  }
  return varianceKey;
}
