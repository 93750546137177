<span >
  <app-npv-location-graph-filter-selector
    [viewByNode]="npvLocationVariables.viewByNode"
    [selectedLevelTab]="selectedLevelTab"
    [npvLocationChartData]="inverseChartData"
    [currentSelectedLocationNode]="npvLocationVariables.currentSelectedLocationNode"
    [currentSelectedOntologyNode]="npvLocationVariables.currentSelectedOntologyNode"
  ></app-npv-location-graph-filter-selector>
  <div id="upper-container">
      <div id="location-chart-title">% New Patient Visits by Location by {{levelText}}</div>
      <app-variable-container [variables]="addOnVariables" [showVariableMenu]="true"></app-variable-container>
  </div>
</span>
<div [ngClass]="isDrillable ? 'drillable' : 'non-drillable'" [chart]="chartObject"></div>
<app-chart-legend [legends]="legends" [metricType]="MetricType.NewPatientVisits"
                  [benchmarksToExclude]="['65th Percentile']">
</app-chart-legend>
<app-loader *ngIf="showLoader"></app-loader>
