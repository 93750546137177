import {
  ApplicationConfigurationSetting,
  BatchExportHeader,
  BatchExportReportDetailsResponseEntity,
  CurrentMemberLocation,
  CustomGroupsDataAllFilters,
  DateRange,
  EmDimension,
  EvaluationManagement,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  ExternalRequestDetail,
  FeatureToggleSetting,
  MemberBillingArea,
  MemberData,
  MemberLocation,
  Members,
  MergedProductivityTrendEntry,
  NewPatientVisitSummary,
  OntologyNode,
  PayerCategory,
  SortingCriterion,
  UserMemberData,
  UserProfile,
  UserSecurity,
  ZipCodeNewPatientVisits
} from '../shared/models';
import {
  aApplicationConfigurationSettings,
  aCfpByMultilevelDefault,
  aCollectionsByMultiLevelByNodeDefault,
  aDenialsByMultiLevelDataDefault,
  aDenialsPayerDataDefault,
  aFeatureToggleSettingsDefault,
  aMembersDefault,
  aNewPatientVisitTrendDataDefault,
  aPayerCollectionsByMultiLevelByNodeDefault,
  aPayerCollectionsDataDefault,
  aProductivitySummaryIntialized,
  aSummaryCollectionsDataDefault
} from '../shared/test/helper-functions.spec';
import {CfpVariableViewType, getDefaultEndDate, getDefaultStartDate, WrvuViewType} from '../shared/helpers';
import {
  DEFAULT_CUSTOM_GROUP_ID,
  DEFAULT_INVOICE_STATUS,
  DEFAULT_LAG_KEY,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_KEY,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_PAYER_TYPE,
  DEFAULT_TELEHEALTH_FLAG,
  DEFAULT_USER_PREFERENCES
} from './DefaultValues';
import {CfpMultiLevelData, ClinicalSummaryConsolidation, ProcedureSummaryDrillImpl} from '../clinical-fingerprint/ClinicalSummary';
import {
  BenchmarkOption,
  ColumnType,
  CptViewType,
  DateRangeOption,
  DefaultPage,
  MultilevelTab,
  MultilevelTabCollections,
  PayerType
} from '../shared/enums';
import {
  CollectionsSummaryForAllTypes,
  MatchedCollectionsMultiLevelNodePathData,
  PayerCollectionsData,
  PayerMatchedCollectionsMultiLevelNodePathData
} from '../collections/Collection';
import {
  getCollectionsColumns,
  getDenialsColumns,
  getDepartmentMultiLevelByPayerCollectionsColumns,
  getDepartmentMultiLevelCollectionsColumns,
  getMultiLevelCollectionsColumns,
  getMultiLevelDenialsColumns,
  getMultiLevelPayerCollectionsColumns,
  getNpvByDepartmentColumns,
  getNpvByProviderColumns,
  getNpvBySpecialtyColumns,
  getNpvSnapshotColumns,
  getNpvTrendColumns,
  getPayerCollectionsColumns,
  getProviderMultiLevelByPayerCollectionsColumns,
  getProviderMultiLevelCollectionsColumns,
  getSpecialtyMultiLevelByPayerCollectionsColumns,
  getSpecialtyMultiLevelCollectionsColumns,
  getWRVUProviderColumns,
  getWRVUSnapshotColumns,
  getWRVUSpecialtyPerformanceColumns,
  getWRVUTrendColumns
} from '../column-selection/DisplayedColumnsHelper';
import {BenchmarkPercentile} from '../shared/benchmark-types';
import {MergedProviderProductivityMultiLevelData, ProductivitySummary} from '../productivity-summary/services/ProviderProductivity';
import {
  MergedNpvLocationAggregatedByNode,
  MergedProviderNewPatientVisitMultiLevelData,
  NewPatientVisitTrendEntry,
  NpvLocationViewByOption
} from '../new-patient-visits/components/npv-models';
import {INITIAL_PRODUCTIVITY_MULTILEVEL_SNAPSHOT} from '../shared/constants';
import {CptGroup} from '../clinical-fingerprint/procedure-summary/ProcedureSummaryModels';
import {
  aDefaultProcedureSummaryVariables,
  CfpViewByOption
} from '../clinical-fingerprint/procedure-summary/procedure-summary-graph/procedure-summary-graph-helper';
import {
  DenialCptCodeEntity,
  DenialPayerEntity,
  DenialReasonEntity,
  DenialsMultiLevelData,
  DenialsPayerData
} from '../denials/denials-models';
import {aDefaultNewPatientVisitsLocationVariables} from '../new-patient-visits/components/npv-models.spec';
import {ColumnWithMatSort} from '../new-patient-visits/components/page-new-patient-visits-location/location-npv-table/npv-location-table-header/npv-location-table-header.component';
import {BatchExportScheduleExistingReportResponseEntity} from '../batch-export/multi-provider-export-redesign/mpe-dialog-helper';

export interface IAppState {
  filters: FilterCriteria;
  data: Data;
  benchmark: BenchmarkPercentile;
  display: CurrentSelections;
  userPreferences: UserPreference;
  oktaToken: string;
  ontologyLoaded: boolean;
}

export interface OntologyData {
  ontologyHierarchy: OntologyNode[];
  numberOfProviders: number;
  numberOfSpecialties: number;
  numberOfDepartments: number;
}

export interface DrillDown {
  isDrillDown?: boolean;
  nodePath: string;
  tab: MultilevelTab;
  customGroupId?: number;
}

export interface CurrentSelections {
  charges: boolean;
  collectionsChargeDisplayed: boolean;
  expectedPaymentsDisplayed: boolean;
  chargesTerminallyDeniedDisplayed: boolean;
  deniedCPTsPaidDisplayed: boolean;
  terminalDenialRateDisplayed: boolean;
  denialsChargesDisplayed: boolean;
  cFTEAdjustedCountDisplayed: boolean;
  cfpChargesDisplayed: boolean;
  previousTimePeriod: boolean;
  patientVisitCounts: boolean;
  previousPatientVisitCounts: boolean;
  isCustom: boolean;
  wrvuViewType: WrvuViewType;
  cfpVariableViewType: CfpVariableViewType;
  isProviderSelected: boolean;
  multipleProviders: boolean;
  multipleSpecialties: boolean;
  multipleDepartments: boolean;
  recentMonth: number;
  selectedProviders: number;
  selectedSpecialties: number;
  showHierarchySearch: boolean;
  zeroSuppression: boolean;
  filterBannerEdit: boolean;
  newFeatures: NewFeatures;
  cptViewType: CptViewType;
  isRevenueMember: boolean;
  viewCommunityBenchmarks: boolean;
  communityBenchmarkAlert: boolean;
  emDimension: EmDimension;
  displayedCollectionsColumns: BaseColumn[];
  displayedPayerCollectionsColumns: BaseColumn[];
  payerByLevelCollectionsStaticColumns: BaseColumn[];
  payerByLevelCollectionsPayerColumns: BaseColumn[];
  multiLevelByNodePathCollectionsColumns: BaseColumn[];
  displayedCollectionsDepartmentColumns: BaseColumn[];
  displayedCollectionsSpecialtyColumns: BaseColumn[];
  displayedCollectionsProviderColumns: BaseColumn[];
  displayedCollectionsByPayerDepartmentColumns: BaseColumn[];
  displayedCollectionsByPayerSpecialtyColumns: BaseColumn[];
  displayedCollectionsByPayerProviderColumns: BaseColumn[];
  multiLevelByNodePathPayerCollectionsColumns: BaseColumn[];
  collectionsChosenTab: MultilevelTabCollections;
  denialsChosenTab: MultilevelTabCollections;
  displayedDenialsColumns: BaseColumn[];
  displayedMultiLevelDenialsColumns: BaseColumn[];
  displayedNPVSnapshotColumns: BaseColumn[];
  displayedNPVDepartmentColumns: BaseColumn[];
  displayedNPVSpecialtyColumns: BaseColumn[];
  displayedNPVProviderColumns: BaseColumn[];
  displayedNPVTrendColumns: BaseColumn[];
  displayedWRVUSnapshotColumns: BaseColumn[];
  displayedWRVUProviderColumns: BaseColumn[];
  displayedWRVUSpecialtyPerformanceColumns: BaseColumn[];
  displayedWRVUTrendColumns: BaseColumn[];
  isExporting: boolean;
  varianceToggle: VarianceToggle;
  sortingCriteria: SortingCriteria;
  isLessThanLagPeriod: boolean;
  newProviderExports: number;
  drillDown: DrillDown;
  drillDownHistory: DrillDown[];
  multilevelTab: MultilevelTab;
  emNpvBenchmarkOption: BenchmarkOption;
  procedureSummaryDrill?: ProcedureSummaryDrillImpl;
  procedureSummaryVariables: ProcedureSummaryVariables;
  newPatientVisitsLocationVariables: NewPatientVisitsLocationVariables;
  selectedCptGroup?: CptGroup;
  showUpdatedCptCodes?: boolean;

  [key: string]: number | boolean | string | WrvuViewType | CptViewType | NewFeatures | EmDimension | BaseColumn[] | DrillDown | DrillDown[]
    | VarianceToggle | SortingCriteria | BenchmarkOption | string[] | ProcedureSummaryDrillImpl |
    ProcedureSummaryVariables | MetricSpecificVariables | CptGroup | undefined;
}

export interface MetricSpecificVariables {
  viewByNode: boolean;

  [key: string]: BaseColumn[] | NpvLocationViewByOption | CfpViewByOption | ColumnWithMatSort | undefined | boolean;
}

export interface ProcedureSummaryVariables extends MetricSpecificVariables {
  charges: boolean;
  currentSelectedCptNode?: CfpViewByOption;
  currentSelectedOntologyCptNode?: CfpViewByOption;

  [key: string]: boolean | CfpViewByOption | undefined;
}

export interface NewPatientVisitsLocationVariables extends MetricSpecificVariables {
  currentSelectedLocationNode?: NpvLocationViewByOption;
  currentSelectedOntologyNode?: NpvLocationViewByOption;
  displayedDepartmentColumns: BaseColumn[];
  displayedSpecialtyColumns: BaseColumn[];
  displayedProviderColumns: BaseColumn[];
  columnForSortingTheData: ColumnWithMatSort;
}

export interface VarianceToggle {
  npvVariance: boolean;
  cfpVariance: boolean;
  wrvuVariance: boolean;
  collectionSnapshotVariance: boolean;
  denialsVariance: boolean;
}

export interface SortingCriteria {
  wrvuSnapshotSortingCriteria: SortingCriterion | undefined;
  npvSnapshotSortingCriteria: SortingCriterion | undefined;
  collectionsSnapshotSortingCriteria: SortingCriterion | undefined;
  cfpSortingCriteria: SortingCriterion | undefined;
  denialsSortingCriteria: SortingCriterion | undefined;
}

export interface UserPreference {
  id?: number;
  memberKey?: number;
  startDateInMonths?: number;
  endDateInMonths?: number;
  nodePath?: string;
  dateRangeOption?: DateRangeOption;
  emailId: string;
  isDefault: boolean;
  defaultPage: DefaultPage;
  fiscalStartMonth: number;
}

export interface NewFeatures {
  id?: number;
  emailId: string;
  featureViewed: boolean | number;
}

export interface FilterCriteria {
  dateRange: DateRange;
  memberKey: number;
  nodePath: string;
  memberLocation: MemberLocation;
  payerCategory: PayerCategory;
  payerKey: number;
  payerType: PayerType;
  lagKey: number;
  cptFamilyFilter?: string;
  cptRangeFilter?: string;
  memberBillingArea: MemberBillingArea;
  invoiceStatus: number;
  telehealthFlag: number | null;
  customGroupId: number;
  nationalPayerKey?: number;
  nationalPayerDescription?: string;
  memberPayerKey?: number;
  memberPayerDescription?: string;
  chosenByUser?: boolean;
  memberLocationKeys: string;
  externalRequestId?: number;
  externalRequestDetail?: ExternalRequestDetail;
}

export interface SummaryData<T> {
  selectedDateRange: T;
  previousYearSelectedDateRange: T;
  yearToDate: T;
  previousYearToDate: T;
  recentMonth: T;
  previousYearRecentMonth: T;

  [key: string]: T;
}

export interface BaseColumn {
  columnDef: string;
  header: string;
  columnType?: ColumnType;
}

export interface Data {
  // member data
  memberBillingAreaData: MemberBillingArea[]; // billing key, desc, code
  selectedMemberData: MemberData; // memberKey, memberDesc
  members: Members;
  customGroupsData: CustomGroupsDataAllFilters[]; // id, name, description, nodePath
  memberLocations: CurrentMemberLocation[];

  // user data
  userProfile: UserProfile; // userName, lastName, firstName, email, isEmployee, city, userTrackingKey, jobTitle
  userSecurityData: UserSecurity[]; // email, memberKey, departmentKey, specialtyKey
  userMemberData: UserMemberData[]; // memberKey, memberDesc, nodePath, recentMonth
  batchExportReports: BatchExportHeader[];
  batchExportReportDetails?: BatchExportReportDetailsResponseEntity[];
  batchExportScheduleReportDetails?: BatchExportScheduleExistingReportResponseEntity[][];

  // settings
  userPreferenceData: UserPreference[];
  featureToggleSettings: FeatureToggleSetting[];
  applicationConfigurationSettings: ApplicationConfigurationSetting[];

  // wrvu
  mergedProductivityTrend: MergedProductivityTrendEntry[];
  providerProductivityMultilevelData: MergedProviderProductivityMultiLevelData;
  productivitySummaryData: SummaryData<ProductivitySummary>;

  // e&m
  evaluationManagementData: EvaluationManagement;
  evaluationManagementMultilevelData: EvaluationManagementMultilevel;
  summaryEvaluationManagementData: EvaluationManagementSummary;
  specialtySummaryEvaluationManagementData: EvaluationManagementSummary;

  // npv
  newPatientVisitTrendData: NewPatientVisitTrendEntry[];
  newPatientVisitMultilevelData: MergedProviderNewPatientVisitMultiLevelData;
  mergedNpvLocationAggregatedByNode: MergedNpvLocationAggregatedByNode[];
  summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>;
  zipCodeNewPatientVisits: ZipCodeNewPatientVisits[];

  // CFP
  clinicalSummaryConsolidationData: ClinicalSummaryConsolidation;
  clinicalFingerprintMultilevelData: CfpMultiLevelData;
  cptGroupings?: CptGroup[];

  // Collections
  payerCollectionsData: PayerCollectionsData;
  matchedCollectionsByMultiLevelByNodePathData: MatchedCollectionsMultiLevelNodePathData;
  matchedCollectionsByPayerByMultiLevelByNodePathData: PayerMatchedCollectionsMultiLevelNodePathData;
  summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>;
  denialsData?: DenialPayerEntity[];
  denialsTopReasonsData?: DenialReasonEntity[];
  denialsTopReasonsByCptsDeniedData?: DenialReasonEntity[];
  denialsTopReasonsByChargesData?: DenialReasonEntity[];
  denialsTopCptsData?: DenialCptCodeEntity[];
  denialsTopCptsByCptsDeniedData?: DenialCptCodeEntity[];
  denialsTopCptsByChargesData?: DenialCptCodeEntity[];

  // denials
  denials: DenialsPayerData;
  denialsByMultiLevelData: DenialsMultiLevelData;
}

export const INITIAL_STATE: IAppState = {
  filters: {
    dateRange: {
      startYear: getDefaultStartDate().getFullYear(),
      endYear: getDefaultEndDate().getFullYear(),
      startMonth: getDefaultStartDate().getMonth() + 1,
      endMonth: getDefaultEndDate().getMonth() + 1,
    },
    memberKey: DEFAULT_MEMBER_KEY,
    nodePath: '',
    memberLocation: DEFAULT_MEMBER_LOCATION,
    payerCategory: DEFAULT_PAYER_CATEGORY,
    payerKey: DEFAULT_PAYER_CATEGORY.payerCategoryKey,
    payerType: DEFAULT_PAYER_TYPE,
    lagKey: DEFAULT_LAG_KEY,
    cptFamilyFilter: undefined,
    cptRangeFilter: undefined,
    memberBillingArea: DEFAULT_MEMBER_BILLING_AREA,
    invoiceStatus: DEFAULT_INVOICE_STATUS,
    telehealthFlag: DEFAULT_TELEHEALTH_FLAG.key,
    customGroupId: DEFAULT_CUSTOM_GROUP_ID, memberLocationKeys: '0'
  },
  data: {
    memberLocations: [],
    memberBillingAreaData: [],
    userMemberData: [],
    userPreferenceData: [],
    userSecurityData: [],
    batchExportReports: [],
    batchExportReportDetails: [],
    providerProductivityMultilevelData: {
      providerProductivities: [INITIAL_PRODUCTIVITY_MULTILEVEL_SNAPSHOT],
      specialtyProductivities: [INITIAL_PRODUCTIVITY_MULTILEVEL_SNAPSHOT],
      departmentProductivities: [INITIAL_PRODUCTIVITY_MULTILEVEL_SNAPSHOT]
    },
    mergedProductivityTrend: [{
      year: 0,
      month: '1',
      wRVUs: 0,
      cfteAdjustedWRVUs: 0,
      cfte: 0,
      charges: 0,
      variance25thPercentile: 0,
      variance50thPercentile: 0,
      variance65thPercentile: 0,
      variance75thPercentile: 0,
      variance90thPercentile: 0,
      varianceMean: 0,
      communityVarianceMean: 0,
      communityVariance25thPercentile: 0,
      communityVariance50thPercentile: 0,
      communityVariance65thPercentile: 0,
      communityVariance75thPercentile: 0,
      communityVariance90thPercentile: 0,
      difference: 0,
      benchmark25thPercentile: 0.0,
      benchmark50thPercentile: 0.0,
      benchmark65thPercentile: 0.0,
      benchmark75thPercentile: 0.0,
      benchmark90thPercentile: 0.0,
      communityBenchmark25thPercentile: 0,
      communityBenchmark50thPercentile: 0,
      communityBenchmark65thPercentile: 0,
      communityBenchmark75thPercentile: 0,
      communityBenchmark90thPercentile: 0,
      communityBenchmarkMean: 0,
      benchmarkMean: 0.0,
      previousWrvus: 0,
      previousCfteAdjustedWRVUs: 0,
      previousCfte: 0,
      previousCharges: 0,
      previousBenchmark25thPercentile: 0.0,
      previousBenchmark50thPercentile: 0.0,
      previousBenchmark65thPercentile: 0.0,
      previousBenchmark75thPercentile: 0.0,
      previousBenchmark90thPercentile: 0.0,
      previousBenchmarkMean: 0.0,
      previousCommunityBenchmark25thPercentile: 0.0,
      previousCommunityBenchmark50thPercentile: 0.0,
      previousCommunityBenchmark65thPercentile: 0.0,
      previousCommunityBenchmark75thPercentile: 0.0,
      previousCommunityBenchmark90thPercentile: 0.0,
      previousCommunityBenchmarkMean: 0.0,
    }],
    evaluationManagementData: {
      providerEvaluationManagement: {
        totals: {
          hospitalDischargeDay: {
            cpt99238: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99239: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          initialHospitalCare: {
            cpt99223: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
 telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99221: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99222: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          eyeExamNewPatient: {
            cpt92002: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt92004: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          establishedPatientVisit: {
            cpt99214: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99215: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99212: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99213: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99211: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          emergencyMedicine: {
            cpt99285: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99283: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99284: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99281: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99282: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          consultation: {
            cpt99245: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99243: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99244: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99241: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99242: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          subsequentHospitalCare: {
            cpt99232: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99233: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99231: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          newPatientVisit: {
            cpt99203: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99204: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99201: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99202: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt99205: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
          eyeExamEstablishedPatient: {
            cpt92014: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            },
            cpt92012: {
              count: 0,
              benchmarkCptUnits: 0,
              benchmarkRangeUnits: 0,
              totalCount: 0,
              benchmarkPercentage: 0,
              communityBenchmarkPercentage: 0,
              percentage: 0,
              communityBenchmarkCptUnits: 0,
              telehealthBenchmarkRangeUnits: 0,
              communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
              communityTelehealthBenchmarkCptUnits: 0,
              communityTelehealthBenchmarkRangeUnits: 0,
              inPersonBenchmarkCptUnits: 0,
              inPersonBenchmarkRangeUnits: 0,
              communityInPersonBenchmarkCptUnits: 0,
              communityInPersonBenchmarkRangeUnits: 0,
              communityBenchmarkRangeUnits: 0,
              telehealthBenchmarkCptUnits: 0
            }
          },
        },
        nodes: [],
      },
    },
    evaluationManagementMultilevelData: {
      evaluationManagementData: {
        departmentEvaluationManagementSnapshotData: {
          nodes: [],
          totals: {
            hospitalDischargeDay: {
              cpt99238: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99239: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            initialHospitalCare: {
              cpt99223: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99221: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99222: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
      telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            eyeExamNewPatient: {
              cpt92002: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt92004: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            establishedPatientVisit: {
              cpt99214: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99215: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99212: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99213: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99211: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            emergencyMedicine: {
              cpt99285: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99283: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99284: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99281: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99282: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            consultation: {
              cpt99245: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99243: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99244: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99241: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99242: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            subsequentHospitalCare: {
              cpt99232: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99233: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99231: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            newPatientVisit: {
              cpt99203: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99204: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99201: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99202: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99205: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            eyeExamEstablishedPatient: {
              cpt92014: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt92012: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
          },
        },
        specialtyEvaluationManagementSnapshotData: {
          nodes: [],
          totals: {
            hospitalDischargeDay: {
              cpt99238: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99239: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            initialHospitalCare: {
              cpt99223: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99221: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
              telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99222: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            eyeExamNewPatient: {
              cpt92002: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt92004: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            establishedPatientVisit: {
              cpt99214: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99215: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
 telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99212: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
 telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99213: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99211: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            emergencyMedicine: {
              cpt99285: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99283: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
 telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99284: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99281: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99282: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            consultation: {
              cpt99245: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99243: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99244: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99241: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99242: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            subsequentHospitalCare: {
              cpt99232: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99233: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99231: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            newPatientVisit: {
              cpt99203: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99204: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99201: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99202: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99205: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            eyeExamEstablishedPatient: {
              cpt92014: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt92012: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
          },
        },
        providerEvaluationManagementSnapshotData: {
          nodes: [],
          totals: {
            hospitalDischargeDay: {
              cpt99238: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99239: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            initialHospitalCare: {
              cpt99223: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99221: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99222: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            eyeExamNewPatient: {
              cpt92002: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt92004: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            establishedPatientVisit: {
              cpt99214: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99215: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99212: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99213: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99211: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            emergencyMedicine: {
              cpt99285: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99283: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99284: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99281: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99282: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            consultation: {
              cpt99245: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99243: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99244: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99241: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99242: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            subsequentHospitalCare: {
              cpt99232: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99233: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99231: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            newPatientVisit: {
              cpt99203: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99204: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99201: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99202: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt99205: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
            eyeExamEstablishedPatient: {
              cpt92014: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
 telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              },
              cpt92012: {
                count: 0,
                benchmarkCptUnits: 0,
                benchmarkRangeUnits: 0,
                totalCount: 0,
                benchmarkPercentage: 0,
                communityBenchmarkPercentage: 0,
                percentage: 0,
                communityBenchmarkCptUnits: 0,
                telehealthBenchmarkRangeUnits: 0,
                communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
                communityTelehealthBenchmarkCptUnits: 0,
                communityTelehealthBenchmarkRangeUnits: 0,
                inPersonBenchmarkCptUnits: 0,
                inPersonBenchmarkRangeUnits: 0,
                communityInPersonBenchmarkCptUnits: 0,
                communityInPersonBenchmarkRangeUnits: 0,
                communityBenchmarkRangeUnits: 0,
                telehealthBenchmarkCptUnits: 0
              }
            },
          },
        }
      },
    },
    summaryEvaluationManagementData: {
      startYear: 0,
      endYear: 0,
      startMonth: 0,
      endMonth: 0,
      totals: {
        hospitalDischargeDay: {
          cpt99238: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99239: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        initialHospitalCare: {
          cpt99223: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99221: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99222: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        eyeExamNewPatient: {
          cpt92002: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt92004: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        establishedPatientVisit: {
          cpt99214: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99215: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99212: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99213: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99211: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        emergencyMedicine: {
          cpt99285: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99283: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99284: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99281: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99282: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        consultation: {
          cpt99245: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99243: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99244: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99241: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
                telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99242: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        subsequentHospitalCare: {
          cpt99232: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99233: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99231: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        newPatientVisit: {
          cpt99203: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
 telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99204: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99201: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99202: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99205: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        eyeExamEstablishedPatient: {
          cpt92014: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt92012: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
 telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        }

      },
    },
    specialtySummaryEvaluationManagementData: {
      startYear: 0,
      endYear: 0,
      startMonth: 0,
      endMonth: 0,
      totals: {
        hospitalDischargeDay: {
          cpt99238: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99239: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        initialHospitalCare: {
          cpt99223: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99221: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99222: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        eyeExamNewPatient: {
          cpt92002: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt92004: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        establishedPatientVisit: {
          cpt99214: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99215: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99212: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99213: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99211: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        emergencyMedicine: {
          cpt99285: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99283: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99284: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99281: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99282: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        consultation: {
          cpt99245: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99243: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99244: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99241: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99242: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        subsequentHospitalCare: {
          cpt99232: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99233: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99231: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        newPatientVisit: {
          cpt99203: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99204: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99201: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99202: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt99205: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        },
        eyeExamEstablishedPatient: {
          cpt92014: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          },
          cpt92012: {
            count: 0,
            benchmarkCptUnits: 0,
            benchmarkRangeUnits: 0,
            totalCount: 0,
            benchmarkPercentage: 0,
            communityBenchmarkPercentage: 0,
            percentage: 0,
            communityBenchmarkCptUnits: 0,
            telehealthBenchmarkRangeUnits: 0,
            communityTelehealthBenchmarkPercentage: 0,
            telehealthBenchmarkPercentage: 0,
              communityInPersonBenchmarkPercentage: 0,
              inPersonBenchmarkPercentage: 0,
            communityTelehealthBenchmarkCptUnits: 0,
            communityTelehealthBenchmarkRangeUnits: 0,
            inPersonBenchmarkCptUnits: 0,
            inPersonBenchmarkRangeUnits: 0,
            communityInPersonBenchmarkCptUnits: 0,
            communityInPersonBenchmarkRangeUnits: 0,
            communityBenchmarkRangeUnits: 0,
            telehealthBenchmarkCptUnits: 0
          }
        }

      },
    },
    newPatientVisitMultilevelData: {
      providerNpvSnapshotData: [
        {
          isHidden: false,
          difference: 0,
          countOfNewPatientVisits: 0,
          countOfTotalPatientVisits: 0,
          countOfExistingPatientVisits: 0,
          newPatientVisitsPercentage: 0,
          nodeId: 0,
          nodePath: '',
          nodeName: '',
          departmentNodeId: 0,
          departmentNodeName: '',
          departmentNodePath: '',
          specialtyNodeId: 0,
          specialtyNodeName: '',
          specialtyNodePath: '',
          providerNodeId: 0,
          providerNodeName: '',
          providerNodePath: '',
          newPatientVisitsPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsTelehealthPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsInPersonPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityInPersonPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsTelehealthVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsInPersonVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityTelehealthVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityInPersonVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          previous: {
            difference: 0,
            countOfNewPatientVisits: 0,
            countOfTotalPatientVisits: 0,
            countOfExistingPatientVisits: 0,
            newPatientVisitsPercentage: 0,
            nodeId: 0,
            nodePath: '',
            nodeName: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            newPatientVisitsPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsTelehealthPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsInPersonPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityInPersonPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsTelehealthVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsInPersonVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityTelehealthVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityInPersonVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
          }
        }
      ],
      specialtyNpvSnapshotData: [
        {
          isHidden: false, difference: 0, countOfNewPatientVisits: 0,
          countOfTotalPatientVisits: 0, countOfExistingPatientVisits: 0, newPatientVisitsPercentage: 0,
          nodeId: 0,
          nodePath: '',
          nodeName: '',
          departmentNodeId: 0,
          departmentNodeName: '',
          departmentNodePath: '',
          specialtyNodeId: 0,
          specialtyNodeName: '',
          specialtyNodePath: '',
          providerNodeId: 0,
          providerNodeName: '',
          providerNodePath: '',
          newPatientVisitsPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsTelehealthPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsInPersonPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityInPersonPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsTelehealthVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsInPersonVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityTelehealthVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityInPersonVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          previous: {
            difference: 0,
            countOfNewPatientVisits: 0,
            countOfTotalPatientVisits: 0,
            countOfExistingPatientVisits: 0,
            newPatientVisitsPercentage: 0,
            nodeId: 0,
            nodePath: '',
            nodeName: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            newPatientVisitsPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsTelehealthPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsInPersonPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityInPersonPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsTelehealthVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsInPersonVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityTelehealthVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityInPersonVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
          }
        }
      ],
      departmentNpvSnapshotData: [
        {
          isHidden: false, difference: 0,
          countOfNewPatientVisits: 0,
          countOfTotalPatientVisits: 0,
          countOfExistingPatientVisits: 0,
          newPatientVisitsPercentage: 0, nodeId: 0, nodeName: '', nodePath: '',
          departmentNodeId: 0,
          departmentNodeName: '',
          departmentNodePath: '',
          specialtyNodeId: 0,
          specialtyNodeName: '',
          specialtyNodePath: '',
          providerNodeId: 0,
          providerNodeName: '',
          providerNodePath: '',
          newPatientVisitsPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsTelehealthPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsInPersonPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsCommunityInPersonPercentageBenchmarks: {
            benchmarkMean: 0,
            benchmark25thPercentile: 0,
            benchmark50thPercentile: 0,
            benchmark75thPercentile: 0,
            benchmark90thPercentile: 0
          },
          newPatientVisitsVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsTelehealthVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsInPersonVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityTelehealthVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          newPatientVisitsCommunityInPersonVariance: {
            variance25thPercentile: 0,
            variance50thPercentile: 0,
            variance75thPercentile: 0,
            variance90thPercentile: 0,
            varianceMean: 0,
          },
          previous: {
            difference: 0,
            countOfNewPatientVisits: 0,
            countOfTotalPatientVisits: 0,
            countOfExistingPatientVisits: 0,
            newPatientVisitsPercentage: 0,
            nodeId: 0,
            nodePath: '',
            nodeName: '',
            departmentNodeId: 0,
            departmentNodeName: '',
            departmentNodePath: '',
            specialtyNodeId: 0,
            specialtyNodeName: '',
            specialtyNodePath: '',
            providerNodeId: 0,
            providerNodeName: '',
            providerNodePath: '',
            newPatientVisitsPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsTelehealthPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsInPersonPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityTelehealthPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsCommunityInPersonPercentageBenchmarks: {
              benchmarkMean: 0,
              benchmark25thPercentile: 0,
              benchmark50thPercentile: 0,
              benchmark75thPercentile: 0,
              benchmark90thPercentile: 0
            },
            newPatientVisitsVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsTelehealthVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsInPersonVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityTelehealthVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
            newPatientVisitsCommunityInPersonVariance: {
              variance25thPercentile: 0,
              variance50thPercentile: 0,
              variance75thPercentile: 0,
              variance90thPercentile: 0,
              varianceMean: 0,
            },
          }
        }
      ]
    },
    mergedNpvLocationAggregatedByNode: [{
      countOfNewPatientVisits: -1, countOfTotalPatientVisits: -1, countOfExistingPatientVisits: -1,
      newPatientVisitsPercentage: -1,
      memberLocationName: DEFAULT_MEMBER_LOCATION.memberLocationName,
      memberLocationKey: DEFAULT_MEMBER_LOCATION.memberLocationKey,
      departmentNpvSnapshotData: [],
      specialtyNpvSnapshotData: [],
      providerNpvSnapshotData: [], previousPercentage: -1
    }],
    newPatientVisitTrendData: [aNewPatientVisitTrendDataDefault()],
    summaryNewPatientVisitData: {
      selectedDateRange: {
        countOfNewPatientVisits: 0,
        newPatientVisitsPercentage: 0,
        countOfTotalPatientVisits: 0,
        per25th: 0,
        per50th: 0,
        per75th: 0,
        per90th: 0,
        mean: 0,
        startMonth: 0,
        endMonth: 0,
        startYear: 0,
        endYear: 0,
        summaryType: 0,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0,
        communityPer25th: 0,
        communityPer50th: 0,
        communityPer65th: 0,
        communityPer75th: 0,
        communityPer90th: 0,
        communityMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityVarianceMean: 0,
      },
      previousYearSelectedDateRange: {
        countOfNewPatientVisits: 0,
        newPatientVisitsPercentage: 0,
        countOfTotalPatientVisits: 0,
        per25th: 0,
        per50th: 0,
        per75th: 0,
        per90th: 0,
        mean: 0,
        startMonth: 0,
        endMonth: 0,
        startYear: 0,
        endYear: 0,
        summaryType: 1,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0,
        communityPer25th: 0,
        communityPer50th: 0,
        communityPer65th: 0,
        communityPer75th: 0,
        communityPer90th: 0,
        communityMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityVarianceMean: 0,
      },
      yearToDate: {
        countOfNewPatientVisits: 0,
        newPatientVisitsPercentage: 0,
        countOfTotalPatientVisits: 0,
        per25th: 0,
        per50th: 0,
        per75th: 0,
        per90th: 0,
        mean: 0,
        startMonth: 0,
        endMonth: 0,
        startYear: 0,
        endYear: 0,
        summaryType: 2,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0,
        communityPer25th: 0,
        communityPer50th: 0,
        communityPer65th: 0,
        communityPer75th: 0,
        communityPer90th: 0,
        communityMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityVarianceMean: 0,
      },
      previousYearToDate: {
        countOfNewPatientVisits: 0,
        newPatientVisitsPercentage: 0,
        countOfTotalPatientVisits: 0,
        per25th: 0,
        per50th: 0,
        per75th: 0,
        per90th: 0,
        mean: 0,
        startMonth: 0,
        endMonth: 0,
        startYear: 0,
        endYear: 0,
        summaryType: 3,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0,
        communityPer25th: 0,
        communityPer50th: 0,
        communityPer65th: 0,
        communityPer75th: 0,
        communityPer90th: 0,
        communityMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityVarianceMean: 0,
      },
      recentMonth: {
        countOfNewPatientVisits: 0,
        newPatientVisitsPercentage: 0,
        countOfTotalPatientVisits: 0,
        per25th: 0,
        per50th: 0,
        per75th: 0,
        per90th: 0,
        mean: 0,
        startMonth: 0,
        endMonth: 0,
        startYear: 0,
        endYear: 0,
        summaryType: 4,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0,
        communityPer25th: 0,
        communityPer50th: 0,
        communityPer65th: 0,
        communityPer75th: 0,
        communityPer90th: 0,
        communityMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityVarianceMean: 0,
      },
      previousYearRecentMonth: {
        countOfNewPatientVisits: 0,
        newPatientVisitsPercentage: 0,
        countOfTotalPatientVisits: 0,
        per25th: 0,
        per50th: 0,
        per75th: 0,
        per90th: 0,
        mean: 0,
        startMonth: 0,
        endMonth: 0,
        startYear: 0,
        endYear: 0,
        summaryType: 5,
        variance25thPercentile: 0,
        variance50thPercentile: 0,
        variance75thPercentile: 0,
        variance90thPercentile: 0,
        varianceMean: 0,
        communityPer25th: 0,
        communityPer50th: 0,
        communityPer65th: 0,
        communityPer75th: 0,
        communityPer90th: 0,
        communityMean: 0,
        communityVariance25thPercentile: 0,
        communityVariance50thPercentile: 0,
        communityVariance65thPercentile: 0,
        communityVariance75thPercentile: 0,
        communityVariance90thPercentile: 0,
        communityVarianceMean: 0,
      }
    },
    zipCodeNewPatientVisits: [
      {
        lat: '0',
        lng: '0',
        zipCode: '12345',
        newPatientVisitCount: 0,
        newPatientVisitPercentage: 0,
        totalPatientVisitCount: 0
      }
    ],
    selectedMemberData: {
      memberKey: DEFAULT_MEMBER_KEY,
      memberDesc: ''
    },
    userProfile: {
      userName: '',
      lastName: '',
      firstName: '',
      city: '',
      email: '',
      isEmployee: false,
      userTrackingKey: '',
      jobTitle: ''
    },
    members: aMembersDefault(),
    productivitySummaryData: {
      selectedDateRange: aProductivitySummaryIntialized(),
      previousYearSelectedDateRange:
        aProductivitySummaryIntialized(),
      yearToDate:
        aProductivitySummaryIntialized(),
      previousYearToDate:
        aProductivitySummaryIntialized(),
      recentMonth:
        aProductivitySummaryIntialized(),
      previousYearRecentMonth:
        aProductivitySummaryIntialized(),
    },
    featureToggleSettings: aFeatureToggleSettingsDefault(),
    applicationConfigurationSettings: aApplicationConfigurationSettings(),
    clinicalSummaryConsolidationData: {
      familyClinicalSummaries: [{
        cptFamilyDesc: '',
        charges: 0,
        cfte: 0,
        cfteAdjustedWRVUs: 0,
        frequency: 0,
        totalWorkRVUs: 0,
        workRvuBenchmark: 0,
        totalWorkRvuBenchmark: 0,
        frequencyBenchmark: 0,
        cfteAdjustedTotalWRVUs: 0,
        cfteAdjustedFrequency: 0,
        cFTEAdjustedFrequencyVariance: 0,
        cFTEAdjustedWrvusVariance: 0,
        cFTEAdjustedTotalWrvusVariance: 0,
        wRVUs: 0,
        communityWorkRvuBenchmark: 0, // Community cFTE Adj. wRVUs Benchmark
        communityTotalWorkRvuBenchmark: 0,
        communityFrequencyBenchmark: 0, // Community CPSC cFTE Adj. Count
        communityCfteAdjustedFrequencyVariance: 0, // Community cFTE Adj. Count Variance from Benchmark
        communityCfteAdjustedWrvusVariance: 0, // Community cFTE Adj. wRVUs Variance from Benchmark
        communityCfteAdjustedTotalWrvusVariance: 0,
      }],
      rangeClinicalSummaries: [{
        cptRangeDesc: '',
        cptFamilyDesc: '',
        cptRangeLow: '',
        cptRangeHigh: '',
        charges: 0,
        cfte: 0,
        cfteAdjustedWRVUs: 0,
        frequency: 0,
        totalWorkRVUs: 0,
        workRvuBenchmark: 0,
        totalWorkRvuBenchmark: 0,
        frequencyBenchmark: 0,
        cfteAdjustedTotalWRVUs: 0,
        cfteAdjustedFrequency: 0,
        cFTEAdjustedFrequencyVariance: 0,
        cFTEAdjustedWrvusVariance: 0,
        cFTEAdjustedTotalWrvusVariance: 0,
        wRVUs: 0,
        communityWorkRvuBenchmark: 0, // Community cFTE Adj. wRVUs Benchmark
        communityTotalWorkRvuBenchmark: 0,
        communityFrequencyBenchmark: 0, // Community CPSC cFTE Adj. Count
        communityCfteAdjustedFrequencyVariance: 0, // Community cFTE Adj. Count Variance from Benchmark
        communityCfteAdjustedWrvusVariance: 0, // Community cFTE Adj. wRVUs Variance from Benchmark
        communityCfteAdjustedTotalWrvusVariance: 0,
      }],
      cptClinicalSummaries: [{
        cptCode: '',
        cptDesc: '',
        cptRangeDesc: '',
        cptFamilyDesc: '',
        cptRangeLow: '',
        cptRangeHigh: '',
        charges: 0,
        cfte: 0,
        cfteAdjustedWRVUs: 0,
        frequency: 0,
        totalWorkRVUs: 0,
        workRvuBenchmark: 0,
        totalWorkRvuBenchmark: 0,
        frequencyBenchmark: 0,
        cfteAdjustedTotalWRVUs: 0,
        cfteAdjustedFrequency: 0,
        cFTEAdjustedFrequencyVariance: 0,
        cFTEAdjustedWrvusVariance: 0,
        cFTEAdjustedTotalWrvusVariance: 0,
        wRVUs: 0,
        communityWorkRvuBenchmark: 0,
        communityTotalWorkRvuBenchmark: 0,
        communityFrequencyBenchmark: 0,
        communityCfteAdjustedFrequencyVariance: 0,
        communityCfteAdjustedWrvusVariance: 0,
        communityCfteAdjustedTotalWrvusVariance: 0,
      }]
    },
    clinicalFingerprintMultilevelData: {
      cfpByProvider: [aCfpByMultilevelDefault()],
      cfpBySpecialty: [aCfpByMultilevelDefault()],
      cfpByDepartment: [aCfpByMultilevelDefault()]
    },
    denials: aDenialsPayerDataDefault(),
    payerCollectionsData: aPayerCollectionsDataDefault(),
    matchedCollectionsByPayerByMultiLevelByNodePathData: aPayerCollectionsByMultiLevelByNodeDefault(),
    matchedCollectionsByMultiLevelByNodePathData: aCollectionsByMultiLevelByNodeDefault(),
    denialsByMultiLevelData: aDenialsByMultiLevelDataDefault(),
    summaryCollectionsData: {
      selectedDateRange: aSummaryCollectionsDataDefault(0),
      previousYearSelectedDateRange: aSummaryCollectionsDataDefault(1),
      yearToDate: aSummaryCollectionsDataDefault(2),
      previousYearToDate: aSummaryCollectionsDataDefault(3),
      recentMonth: aSummaryCollectionsDataDefault(4),
      previousYearRecentMonth: aSummaryCollectionsDataDefault(5)
    },
    customGroupsData: [{
      endDateInMonths: 0,
      invoiceStatus: 0,
      lagKey: 0,
      memberBillingAreaKey: 0,
      memberKey: 0,
      memberLocationKey: 0,
      payerCategoryKey: 0,
      payerType: 0,
      startDateInMonths: 0,
      telehealthFlag: 0,
      id: -1,
      name: '',
      description: '',
      nodePath: '',
      isDefault: false,
      preload: false, memberLocationKeys: '0'
    }],
    denialsData: undefined,
    denialsTopReasonsData: undefined,
    denialsTopReasonsByCptsDeniedData: undefined,
    denialsTopReasonsByChargesData: undefined,
    denialsTopCptsData: undefined,
    denialsTopCptsByCptsDeniedData: undefined,
    denialsTopCptsByChargesData: undefined,
  },
  ontologyLoaded: false,
  benchmark:
  BenchmarkPercentile.Mean,
  display:
    {
      expectedPaymentsDisplayed: false,
      collectionsChargeDisplayed: false,
      chargesTerminallyDeniedDisplayed: false,
      deniedCPTsPaidDisplayed: false,
      terminalDenialRateDisplayed: false,
      denialsChargesDisplayed: false,
      cFTEAdjustedCountDisplayed: false,
      cfpChargesDisplayed: false,
      charges: false,
      previousTimePeriod: false,
      patientVisitCounts: false,
      previousPatientVisitCounts: false,
      isCustom: false,
      wrvuViewType: WrvuViewType.CfteAdjusted,
      cfpVariableViewType: CfpVariableViewType.CfteAdjustedwRVU,
      isProviderSelected: false,
      multipleProviders: false,
      multipleSpecialties: false,
      multipleDepartments: false,
      recentMonth: -1,
      selectedProviders: 0,
      selectedSpecialties: 0,
      showHierarchySearch: false,
      zeroSuppression: false,
      filterBannerEdit: false,
      cptViewType: CptViewType.CptFamily,
      newFeatures: {
        id: 0,
        emailId: '',
        featureViewed: 0
      },
      isRevenueMember: false,
      viewCommunityBenchmarks: false,
      emDimension: EmDimension.Outpatient,
      communityBenchmarkAlert: false,
      displayedCollectionsColumns: getCollectionsColumns(),
      payerByLevelCollectionsStaticColumns: getCollectionsColumns(),
      payerByLevelCollectionsPayerColumns: [],
      multiLevelByNodePathPayerCollectionsColumns: getMultiLevelPayerCollectionsColumns(),
      multiLevelByNodePathCollectionsColumns: getMultiLevelCollectionsColumns(),
      displayedCollectionsDepartmentColumns: getDepartmentMultiLevelCollectionsColumns(),
      displayedCollectionsSpecialtyColumns: getSpecialtyMultiLevelCollectionsColumns(),
      displayedCollectionsProviderColumns: getProviderMultiLevelCollectionsColumns(),
      displayedCollectionsByPayerDepartmentColumns: getDepartmentMultiLevelByPayerCollectionsColumns(),
      displayedCollectionsByPayerSpecialtyColumns: getSpecialtyMultiLevelByPayerCollectionsColumns(),
      displayedCollectionsByPayerProviderColumns: getProviderMultiLevelByPayerCollectionsColumns(),
      collectionsChosenTab: MultilevelTabCollections.SUMMARY,
      displayedDenialsColumns: getDenialsColumns(),
      displayedMultiLevelDenialsColumns: getMultiLevelDenialsColumns(),
      denialsChosenTab: MultilevelTabCollections.BY_DEPARTMENT,
      displayedWRVUSnapshotColumns: getWRVUSnapshotColumns(),
      displayedWRVUProviderColumns: getWRVUProviderColumns(),
      displayedWRVUTrendColumns: getWRVUTrendColumns(),
      displayedWRVUSpecialtyPerformanceColumns: getWRVUSpecialtyPerformanceColumns(),
      displayedNPVSnapshotColumns: getNpvSnapshotColumns(),
      displayedNPVDepartmentColumns: getNpvByDepartmentColumns(),
      displayedNPVSpecialtyColumns: getNpvBySpecialtyColumns(),
      displayedNPVProviderColumns: getNpvByProviderColumns(),
      displayedPayerCollectionsColumns: getPayerCollectionsColumns(),
      displayedNPVTrendColumns: getNpvTrendColumns(),
      varianceToggle: {
        npvVariance: false,
        cfpVariance: false,
        wrvuVariance: false,
        collectionSnapshotVariance: false,
        denialsVariance: false
      },
      sortingCriteria: {
        wrvuSnapshotSortingCriteria: undefined,
        npvSnapshotSortingCriteria: undefined,
        collectionsSnapshotSortingCriteria: undefined,
        cfpSortingCriteria: undefined,
        denialsSortingCriteria: undefined
      },
      isExporting: false,
      isLessThanLagPeriod: true,
      newProviderExports: 0,
      drillDown: {
        isDrillDown: false,
        nodePath: '',
        tab: MultilevelTab.BY_DEPARTMENT
      },
      drillDownHistory: [],
      multilevelTab: MultilevelTab.BY_DEPARTMENT,
      emNpvBenchmarkOption: BenchmarkOption.Academic,
      procedureSummaryVariables: aDefaultProcedureSummaryVariables,
      newPatientVisitsLocationVariables: aDefaultNewPatientVisitsLocationVariables,
    }
  ,
  oktaToken: '',
  userPreferences: {
    ...DEFAULT_USER_PREFERENCES
  }
};
