import {
  Consultation,
  CptRange,
  CptSummary,
  EmDimension,
  EmergencyMedicine,
  EstablishedPatientVisit,
  EvaluationManagementSummary,
  EyeExamEstablishedPatient,
  EyeExamNewPatient,
  HospitalDischargeDay,
  InitialHospitalCare,
  NewPatientVisit,
  OptionalMultilevelNode,
  ProviderCptCounts,
  ProviderEvaluationManagement,
  ProviderMultilevelCptCounts,
  ProviderMultilevelEvaluationManagement,
  SubsequentHospitalCare,
  DateRange} from '../shared/models';
import {
  EvaluationManagementEmergencyRoomRow,
  EvaluationManagementEyeExamRow,
  EvaluationManagementInpatientRow,
  EvaluationManagementOutpatientRow
} from '../shared/export/export';
import {BenchmarkOption, ColumnType, CptViewType, MultilevelTab} from '../shared/enums';
import {monthNames, roundToHundred} from '../shared/helpers';
import {DataTableColumns} from '../shared/data-table-columns';
import {isValidOrElse} from '../shared/null-helpers';
import {extendedBenchmarkOptions, originalBenchmarkOptions} from '../shared/benchmark-types';
import {toMonthName} from '../productivity-summary/month-formatter';
import {isNumeric} from 'rxjs/internal-compatibility';

const CPT_CODE_YEAR_CHANGE = 2024;
export function shouldShowUpdatedCPTCodes(dateRange: DateRange): boolean {
  return dateRange.startYear >= CPT_CODE_YEAR_CHANGE;
}

export function getMaxYaxisValueForEAndMGraphWithOriginalBenchmarkOptions(
  cptSummaries: (CptSummary | undefined)[],
  communityBenchmark: boolean
): number {
  const benchmarkFn = (summary: CptSummary) => communityBenchmark ? summary?.communityBenchmarkPercentage : summary?.benchmarkPercentage;
  return getMaxYAxisValueForEAndMGraph(cptSummaries, benchmarkFn);
}

export function getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(
  cptSummaries: (CptSummary | undefined)[],
  emBenchmarkOption: BenchmarkOption
): number {
  const benchmarkFn = (summary: CptSummary) => {
    switch (emBenchmarkOption) {
      case BenchmarkOption.Academic:
        return summary?.benchmarkPercentage;
      case BenchmarkOption.Community:
        return summary?.communityBenchmarkPercentage;
      case BenchmarkOption.TelehealthAcademic:
        return summary?.telehealthBenchmarkPercentage;
      case BenchmarkOption.TelehealthCommunity:
        return summary?.communityTelehealthBenchmarkPercentage;
      case BenchmarkOption.InPersonAcademic:
        return summary?.inPersonBenchmarkPercentage;
      case BenchmarkOption.InPersonCommunity:
        return summary?.communityInPersonBenchmarkPercentage;
    }
  };

  return getMaxYAxisValueForEAndMGraph(cptSummaries, benchmarkFn);
}

export function getMaxYAxisValueForEAndMGraph(
  cptSummaries: (CptSummary | undefined)[],
  benchmarkValue: (summary?: CptSummary) => number | undefined
): number {
  if (!cptSummaries) {
    return 0;
  }
  const percentages = cptSummaries.map(summary => summary?.percentage || 0);
  const benchmarkPercentages = cptSummaries.map(summary => benchmarkValue(summary) || 0);

  return Math.max(...percentages, ...benchmarkPercentages);
}

export function getDefaultCptSummary(): CptSummary {
  return {
    count: 0,
    benchmarkCptUnits: 0,
    benchmarkRangeUnits: 0,
    totalCount: 0,
    percentage: 0,
    benchmarkPercentage: 0,
    communityBenchmarkPercentage: 0,
    communityBenchmarkCptUnits: 0,
    communityBenchmarkRangeUnits: 0,
    telehealthBenchmarkCptUnits: 0,
    telehealthBenchmarkRangeUnits: 0,
    communityTelehealthBenchmarkCptUnits: 0,
    communityTelehealthBenchmarkRangeUnits: 0,
    inPersonBenchmarkCptUnits: 0,
    inPersonBenchmarkRangeUnits: 0,
    communityInPersonBenchmarkCptUnits: 0,
    communityInPersonBenchmarkRangeUnits: 0
  };
}

export interface EvaluationManagementOutpatientMultilevelRow extends OptionalMultilevelNode {
  newPatients: NewPatientVisit;
  establishedPatients: EstablishedPatientVisit;
  consultation: Consultation;
}

export function rowForOutpatientML(
  chosenTab: MultilevelTab,
  newPatients: NewPatientVisit,
  establishedPatients: EstablishedPatientVisit,
  consultation: Consultation,
  departmentNodePath: string,
  departmentNodeName: string,
  departmentNodeId?: number,
  specialtyNodePath?: string,
  specialtyNodeName?: string,
  specialtyNodeId?: number,
  providerNodePath?: string,
  providerNodeName?: string,
  providerNodeId?: number
): EvaluationManagementOutpatientMultilevelRow {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return {
        newPatients: newPatients,
        establishedPatients: establishedPatients,
        consultation: consultation,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName
      };
    case MultilevelTab.BY_PROVIDER:
      return {
        newPatients: newPatients,
        establishedPatients: establishedPatients,
        consultation: consultation,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName,
        providerNodeId: providerNodeId,
        providerNodePath: providerNodePath,
        providerNodeName: providerNodeName
      };
    default:
      return {
        newPatients: newPatients,
        establishedPatients: establishedPatients,
        consultation: consultation,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName
      };
  }
}

export function getOutpatientTableRowsFromML(
  chosenTab: MultilevelTab,
  providerEvaluationManagement: ProviderMultilevelEvaluationManagement,
  isSingleProviderSelected = false): EvaluationManagementOutpatientMultilevelRow[] {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes.map(
        (provider: ProviderMultilevelCptCounts) =>
          rowForOutpatientML(
            chosenTab,
            provider.cptCategories.newPatientVisit,
            provider.cptCategories.establishedPatientVisit,
            provider.cptCategories.consultation,
            provider.departmentNodePath,
            provider.departmentNodeName,
            provider.departmentNodeId,
            provider.specialtyNodePath,
            provider.specialtyNodeName,
            provider.specialtyNodeId
          ));
    case MultilevelTab.BY_PROVIDER:
      const nodes = isSingleProviderSelected ?
        providerEvaluationManagement.providerEvaluationManagementSnapshotDataMonthly?.nodes :
        providerEvaluationManagement.providerEvaluationManagementSnapshotData?.nodes;
      return nodes ? nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForOutpatientML(
          chosenTab,
          provider.cptCategories?.newPatientVisit,
          provider.cptCategories?.establishedPatientVisit,
          provider.cptCategories?.consultation,
          provider.departmentNodePath,
          isSingleProviderSelected ? provider.nodeName + ' ' +
            monthNames[Number(provider.nodePath) - 1] : provider.departmentNodeName,
          provider.departmentNodeId,
          provider.specialtyNodePath,
          isSingleProviderSelected ? '' : provider.specialtyNodeName,
          provider.specialtyNodeId,
          provider.providerNodePath,
          isSingleProviderSelected ? '' : provider.providerNodeName,
          provider.providerNodeId
        )) : [];
    default:
      return providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForOutpatientML(
          chosenTab,
          provider.cptCategories?.newPatientVisit,
          provider.cptCategories?.establishedPatientVisit,
          provider.cptCategories?.consultation,
          provider.departmentNodePath,
          provider.departmentNodeName,
          provider.departmentNodeId
        ));
  }
}

export interface EvaluationManagementInpatientMultilevelRow extends OptionalMultilevelNode {
  subsequentHospitalCare: SubsequentHospitalCare;
  initialHospitalCare: InitialHospitalCare;
  hospitalDischargeDay: HospitalDischargeDay;
}

export interface EvaluationManagementEmergencyMultilevelRow extends OptionalMultilevelNode {
  emergencyMedicine: EmergencyMedicine;
}

export interface EvaluationManagementEyeExamMultilevelRow extends OptionalMultilevelNode {
  eyeExamNewPatient: EyeExamNewPatient;
  eyeExamEstablishedPatient: EyeExamEstablishedPatient;
}

export function rowForInpatientML(
  chosenTab: MultilevelTab,
  subsequentHospitalCare: SubsequentHospitalCare,
  initialHospitalCare: InitialHospitalCare,
  hospitalDischargeDay: HospitalDischargeDay,
  departmentNodePath: string,
  departmentNodeName: string,
  departmentNodeId?: number,
  specialtyNodePath?: string,
  specialtyNodeName?: string,
  specialtyNodeId?: number,
  providerNodePath?: string,
  providerNodeName?: string,
  providerNodeId?: number
): EvaluationManagementInpatientMultilevelRow {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return {
        subsequentHospitalCare: subsequentHospitalCare,
        initialHospitalCare: initialHospitalCare,
        hospitalDischargeDay: hospitalDischargeDay,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName
      };
    case MultilevelTab.BY_PROVIDER:
      return {
        subsequentHospitalCare: subsequentHospitalCare,
        initialHospitalCare: initialHospitalCare,
        hospitalDischargeDay: hospitalDischargeDay,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName,
        providerNodeId: providerNodeId,
        providerNodePath: providerNodePath,
        providerNodeName: providerNodeName
      };
    default:
      return {
        subsequentHospitalCare: subsequentHospitalCare,
        initialHospitalCare: initialHospitalCare,
        hospitalDischargeDay: hospitalDischargeDay,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName
      };
  }
}


export function getInpatientTableRowsFromML(
  chosenTab: MultilevelTab,
  providerEvaluationManagement: ProviderMultilevelEvaluationManagement,
  isSingleProviderSelected = false): EvaluationManagementInpatientMultilevelRow[] {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForInpatientML(
          chosenTab,
          provider.cptCategories?.subsequentHospitalCare,
          provider.cptCategories?.initialHospitalCare,
          provider.cptCategories?.hospitalDischargeDay,
          provider.departmentNodePath,
          provider.departmentNodeName,
          provider.departmentNodeId,
          provider.specialtyNodePath,
          provider.specialtyNodeName,
          provider.specialtyNodeId
        ));
    case MultilevelTab.BY_PROVIDER:
      const nodes = isSingleProviderSelected ?
        providerEvaluationManagement.providerEvaluationManagementSnapshotDataMonthly?.nodes :
        providerEvaluationManagement.providerEvaluationManagementSnapshotData?.nodes;
      return nodes ? nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForInpatientML(
          chosenTab,
          provider.cptCategories?.subsequentHospitalCare,
          provider.cptCategories?.initialHospitalCare,
          provider.cptCategories?.hospitalDischargeDay,
          provider.departmentNodePath,
          isSingleProviderSelected ? provider.nodeName + ' ' +
            monthNames[Number(provider.nodePath) - 1] : provider.departmentNodeName,
          provider.departmentNodeId,
          provider.specialtyNodePath,
          isSingleProviderSelected ? '' : provider.specialtyNodeName,
          provider.specialtyNodeId,
          provider.providerNodePath,
          isSingleProviderSelected ? '' : provider.providerNodeName,
          provider.providerNodeId
        )) : [];
    default:
      return providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForInpatientML(
          chosenTab,
          provider.cptCategories?.subsequentHospitalCare,
          provider.cptCategories?.initialHospitalCare,
          provider.cptCategories?.hospitalDischargeDay,
          provider.departmentNodePath,
          provider.departmentNodeName,
          provider.departmentNodeId
        ));
  }
}

export function rowForEmergencyML(
  chosenTab: MultilevelTab,
  emergencyMedicine: EmergencyMedicine,
  departmentNodePath: string,
  departmentNodeName: string,
  departmentNodeId?: number,
  specialtyNodePath?: string,
  specialtyNodeName?: string,
  specialtyNodeId?: number,
  providerNodePath?: string,
  providerNodeName?: string,
  providerNodeId?: number
): EvaluationManagementEmergencyMultilevelRow {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return {
        emergencyMedicine: emergencyMedicine,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName
      };
    case MultilevelTab.BY_PROVIDER:
      return {
        emergencyMedicine: emergencyMedicine,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName,
        providerNodeId: providerNodeId,
        providerNodePath: providerNodePath,
        providerNodeName: providerNodeName
      };
    default:
      return {
        emergencyMedicine: emergencyMedicine,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName
      };
  }
}


export function getEmergencyTableRowsFromML(
  chosenTab: MultilevelTab,
  providerEvaluationManagement: ProviderMultilevelEvaluationManagement,
  isSingleProviderSelected = false): EvaluationManagementEmergencyMultilevelRow[] {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForEmergencyML(
          chosenTab,
          provider.cptCategories?.emergencyMedicine,
          provider.departmentNodePath,
          provider.departmentNodeName,
          provider.departmentNodeId,
          provider.specialtyNodePath,
          provider.specialtyNodeName,
          provider.specialtyNodeId
        ));
    case MultilevelTab.BY_PROVIDER:
      const nodes = isSingleProviderSelected ?
        providerEvaluationManagement.providerEvaluationManagementSnapshotDataMonthly?.nodes :
        providerEvaluationManagement.providerEvaluationManagementSnapshotData?.nodes;
      return nodes ? nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForEmergencyML(
          chosenTab,
          provider.cptCategories?.emergencyMedicine,
          provider.departmentNodePath,
          isSingleProviderSelected ? provider.nodeName + ' ' +
            monthNames[Number(provider.nodePath) - 1] : provider.departmentNodeName,
          provider.departmentNodeId,
          provider.specialtyNodePath,
          isSingleProviderSelected ? '' : provider.specialtyNodeName,
          provider.specialtyNodeId,
          provider.providerNodePath,
          isSingleProviderSelected ? provider.providerNodeName + ' ' +
            monthNames[Number(provider.providerNodePath) - 1] : provider.providerNodeName,
          provider.providerNodeId
        )) : [];
    default:
      return providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForEmergencyML(
          chosenTab,
          provider.cptCategories?.emergencyMedicine,
          provider.departmentNodePath,
          provider.departmentNodeName,
          provider.departmentNodeId
        ));
  }
}

export function rowForEyeExamML(
  chosenTab: MultilevelTab,
  eyeExamNewPatient: EyeExamNewPatient,
  eyeExamEstablishedPatient: EyeExamEstablishedPatient,
  departmentNodePath: string,
  departmentNodeName: string,
  departmentNodeId?: number,
  specialtyNodePath?: string,
  specialtyNodeName?: string,
  specialtyNodeId?: number,
  providerNodePath?: string,
  providerNodeName?: string,
  providerNodeId?: number
): EvaluationManagementEyeExamMultilevelRow {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return {
        eyeExamNewPatient: eyeExamNewPatient,
        eyeExamEstablishedPatient: eyeExamEstablishedPatient,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName
      };
    case MultilevelTab.BY_PROVIDER:
      return {
        eyeExamNewPatient: eyeExamNewPatient,
        eyeExamEstablishedPatient: eyeExamEstablishedPatient,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName,
        specialtyNodeId: specialtyNodeId,
        specialtyNodePath: specialtyNodePath,
        specialtyNodeName: specialtyNodeName,
        providerNodeId: providerNodeId,
        providerNodePath: providerNodePath,
        providerNodeName: providerNodeName
      };
    default:
      return {
        eyeExamNewPatient: eyeExamNewPatient,
        eyeExamEstablishedPatient: eyeExamEstablishedPatient,
        departmentNodeId: departmentNodeId,
        departmentNodePath: departmentNodePath,
        departmentNodeName: departmentNodeName
      };
  }
}

export function getEyeExamTableRowsFromML(
  chosenTab: MultilevelTab,
  providerEvaluationManagement: ProviderMultilevelEvaluationManagement,
  isSingleProviderSelected = false): EvaluationManagementEyeExamMultilevelRow[] {
  switch (chosenTab) {
    case MultilevelTab.BY_SPECIALTY:
      return providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes.map(
        (provider: ProviderMultilevelCptCounts) =>
          rowForEyeExamML(
            chosenTab,
            provider.cptCategories?.eyeExamNewPatient,
            provider.cptCategories?.eyeExamEstablishedPatient,
            provider.departmentNodePath,
            provider.departmentNodeName,
            provider.departmentNodeId,
            provider.specialtyNodePath,
            provider.specialtyNodeName,
            provider.specialtyNodeId
          ));
    case MultilevelTab.BY_PROVIDER:
      const nodes = isSingleProviderSelected ?
        providerEvaluationManagement.providerEvaluationManagementSnapshotDataMonthly?.nodes :
        providerEvaluationManagement.providerEvaluationManagementSnapshotData?.nodes;
      return nodes ? nodes.map((provider: ProviderMultilevelCptCounts) =>
        rowForEyeExamML(
          chosenTab,
          provider.cptCategories?.eyeExamNewPatient,
          provider.cptCategories?.eyeExamEstablishedPatient,
          provider.departmentNodePath,
          isSingleProviderSelected ? provider.nodeName + ' ' +
            monthNames[Number(provider.nodePath) - 1] : provider.departmentNodeName,
          provider.departmentNodeId,
          provider.specialtyNodePath,
          isSingleProviderSelected ? '' : provider.specialtyNodeName,
          provider.specialtyNodeId,
          provider.providerNodePath,
          isSingleProviderSelected ? provider.providerNodeName + ' ' +
            monthNames[Number(provider.providerNodePath) - 1] : provider.providerNodeName,
          provider.providerNodeId
        )) : [];
    default:
      return providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes.map(
        (provider: ProviderMultilevelCptCounts) =>
          rowForEyeExamML(
            chosenTab,
            provider.cptCategories?.eyeExamNewPatient,
            provider.cptCategories?.eyeExamEstablishedPatient,
            provider.departmentNodePath,
            provider.departmentNodeName,
            provider.departmentNodeId
          ));
  }
}

export function getOutpatientTableRowsFrom(providerEvaluationManagement: ProviderEvaluationManagement,
                                           isSingleProviderSelected = false): EvaluationManagementOutpatientRow[] {
  return providerEvaluationManagement.nodes.map((provider: ProviderCptCounts) =>
    rowForOutpatient(
      isSingleProviderSelected ? provider.nodeName + ' ' + monthNames[Number(provider.nodePath) - 1] : provider.nodeName,
      provider.cptCategories?.newPatientVisit,
      provider.cptCategories?.establishedPatientVisit,
      provider.cptCategories?.consultation,
      provider.nodePath,
      provider.nodeId
    ));
}

export function getOutpatientByDepartmentTableRowsFrom(providerEvaluationManagement:
                                                         ProviderMultilevelEvaluationManagement): EvaluationManagementOutpatientRow[] {
  return providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes.map((department: ProviderMultilevelCptCounts) =>
    rowForOutpatient(department.departmentNodeName,
      department.cptCategories?.newPatientVisit,
      department.cptCategories?.establishedPatientVisit,
      department.cptCategories?.consultation,
      department.departmentNodePath,
      department.departmentNodeId
    ));
}

export function getOutpatientBySpecialtyTableRowsFrom(providerEvaluationManagement:
                                                        ProviderMultilevelEvaluationManagement): EvaluationManagementOutpatientRow[] {
  return providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes.map((specialty: ProviderMultilevelCptCounts) =>
    rowForOutpatient(specialty.specialtyNodeName + '/' + specialty.departmentNodeName,
      specialty.cptCategories?.newPatientVisit,
      specialty.cptCategories?.establishedPatientVisit,
      specialty.cptCategories?.consultation,
      specialty.specialtyNodePath,
      specialty.specialtyNodeId
    ));
}

export function getOutpatientByProviderTableRowsFrom(providerEvaluationManagement:
                                                       ProviderMultilevelEvaluationManagement): EvaluationManagementOutpatientRow[] {
  return providerEvaluationManagement.providerEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
    rowForOutpatient(provider.providerNodeName + '/' + provider.specialtyNodeName + '/' + provider.departmentNodeName,
      provider.cptCategories?.newPatientVisit,
      provider.cptCategories?.establishedPatientVisit,
      provider.cptCategories?.consultation,
      provider.providerNodePath,
      provider.providerNodeId
    ));
}

export function rowForOutpatient(
  rowName: string,
  newPatients: NewPatientVisit,
  establishedPatients: EstablishedPatientVisit,
  consultation: Consultation,
  nodePath: string,
  nodeId?: number
): EvaluationManagementOutpatientRow {

  return {
    rowName: rowName,
    newPatientVisit: newPatients,
    establishedPatientVisit: establishedPatients,
    consultation: consultation,
    nodeId: nodeId,
    nodePath: nodePath
  };
}

export function getInpatientTableRowsFrom(providerEvaluationManagement: ProviderEvaluationManagement,
                                          isSingleProviderSelected = false):
  EvaluationManagementInpatientRow[] {
  const rows: EvaluationManagementInpatientRow[] = [];

  rows.push(...providerEvaluationManagement.nodes.map((provider: ProviderCptCounts) =>
    rowForInpatient(
      isSingleProviderSelected ? provider.nodeName + ' ' + monthNames[Number(provider.nodePath) - 1] : provider.nodeName,
      provider.cptCategories?.subsequentHospitalCare,
      provider.cptCategories?.initialHospitalCare,
      provider.cptCategories?.hospitalDischargeDay,
      provider.nodePath,
      provider.nodeId
    )));

  return rows;
}

export function getInpatientByDepartmentTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementInpatientRow[] {
  const rows: EvaluationManagementInpatientRow[] = [];
  rows.push(...providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes
    .map((department: ProviderMultilevelCptCounts) =>
      rowForInpatient(department.departmentNodeName,
        department.cptCategories?.subsequentHospitalCare,
        department.cptCategories?.initialHospitalCare,
        department.cptCategories?.hospitalDischargeDay,
        department.departmentNodePath,
        department.departmentNodeId
      )));
  return rows;
}

export function getInpatientBySpecialtyTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementInpatientRow[] {
  const rows: EvaluationManagementInpatientRow[] = [];
  rows.push(...providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes
    .map((specialty: ProviderMultilevelCptCounts) =>
      rowForInpatient(specialty.specialtyNodeName + '/' + specialty.departmentNodeName,
        specialty.cptCategories?.subsequentHospitalCare,
        specialty.cptCategories?.initialHospitalCare,
        specialty.cptCategories?.hospitalDischargeDay,
        specialty.specialtyNodePath,
        specialty.specialtyNodeId
      )));
  return rows;
}

export function getInpatientByProviderTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementInpatientRow[] {
  const rows: EvaluationManagementInpatientRow[] = [];
  rows.push(...providerEvaluationManagement.providerEvaluationManagementSnapshotData.nodes
    .map((provider: ProviderMultilevelCptCounts) =>
      rowForInpatient(provider.providerNodeName + '/' + provider.specialtyNodeName + '/' + provider.departmentNodeName,
        provider.cptCategories?.subsequentHospitalCare,
        provider.cptCategories?.initialHospitalCare,
        provider.cptCategories?.hospitalDischargeDay,
        provider.providerNodePath,
        provider.providerNodeId
      )));
  return rows;
}

export function rowForInpatient(
  rowName: string,
  subsequentHospitalCare: SubsequentHospitalCare,
  initialHospitalCare: InitialHospitalCare,
  hospitalDischargeDay: HospitalDischargeDay,
  nodePath: string,
  nodeId?: number
): EvaluationManagementInpatientRow {
  return {
    rowName: rowName,
    subsequentHospitalCare: subsequentHospitalCare,
    initialHospitalCare: initialHospitalCare,
    hospitalDischargeDay: hospitalDischargeDay,
    nodeId: nodeId,
    nodePath: nodePath
  };
}

export function getEmergencyTableRowsFrom(providerEvaluationManagement: ProviderEvaluationManagement,
                                          isSingleProviderSelected = false):
  EvaluationManagementEmergencyRoomRow[] {
  const rows: EvaluationManagementEmergencyRoomRow[] = [];

  rows.push(...providerEvaluationManagement.nodes.map((provider: ProviderCptCounts) =>
    rowForEmergencyRoom(
      isSingleProviderSelected ? provider.nodeName + ' ' + monthNames[Number(provider.nodePath) - 1] : provider.nodeName,
      provider.cptCategories.emergencyMedicine,
      provider.nodePath,
      provider.nodeId
    )));

  return rows;
}

export function getEmergencyByDepartmentTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementEmergencyRoomRow[] {
  const rows: EvaluationManagementEmergencyRoomRow[] = [];

  rows.push(...providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes
    .map((department: ProviderMultilevelCptCounts) =>
      rowForEmergencyRoom(department.departmentNodeName,
        department.cptCategories?.emergencyMedicine,
        department.departmentNodePath,
        department.departmentNodeId
      )));

  return rows;
}

export function getEmergencyBySpecialtyTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementEmergencyRoomRow[] {
  const rows: EvaluationManagementEmergencyRoomRow[] = [];

  rows.push(...providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes
    .map((specialty: ProviderMultilevelCptCounts) =>
      rowForEmergencyRoom(
        specialty.specialtyNodeName + '/' + specialty.departmentNodeName,
        specialty.cptCategories?.emergencyMedicine,
        specialty.specialtyNodePath,
        specialty.specialtyNodeId
      )));
  return rows;
}

export function getEmergencyByProviderTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementEmergencyRoomRow[] {
  const rows: EvaluationManagementEmergencyRoomRow[] = [];

  rows.push(...providerEvaluationManagement.providerEvaluationManagementSnapshotData.nodes
    .map((provider: ProviderMultilevelCptCounts) =>
      rowForEmergencyRoom(
        provider.providerNodeName + '/' + provider.specialtyNodeName + '/' + provider.departmentNodeName,
        provider.cptCategories?.emergencyMedicine,
        provider.specialtyNodePath,
        provider.specialtyNodeId
      )));
  return rows;
}

export function rowForEmergencyRoom(
  rowName: string,
  emergencyMedicine: EmergencyMedicine,
  nodePath: string,
  nodeId?: number
): EvaluationManagementEmergencyRoomRow {

  return {
    rowName: rowName,
    emergencyMedicine: emergencyMedicine,
    nodeId: nodeId,
    nodePath: nodePath
  };
}

export function getEyeExamTableRowsFrom(providerEvaluationManagement: ProviderEvaluationManagement,
                                        isSingleProviderSelected = false):
  EvaluationManagementEyeExamRow[] {
  const rows: EvaluationManagementEyeExamRow[] = [];

  rows.push(...providerEvaluationManagement.nodes.map((provider: ProviderCptCounts) =>
    rowForEyeExam(
      isSingleProviderSelected ? provider.nodeName + ' ' + monthNames[Number(provider.nodePath) - 1] : provider.nodeName,
      provider.cptCategories.eyeExamNewPatient,
      provider.cptCategories.eyeExamEstablishedPatient,
      provider.nodePath,
      provider.nodeId
    )));

  return rows;
}

export function getEyeExamByDepartmentTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementEyeExamRow[] {
  const rows: EvaluationManagementEyeExamRow[] = [];

  rows.push(...providerEvaluationManagement.departmentEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
    rowForEyeExam(
      provider.departmentNodeName,
      provider.cptCategories?.eyeExamNewPatient,
      provider.cptCategories?.eyeExamEstablishedPatient,
      provider.departmentNodePath,
      provider.departmentNodeId
    )));

  return rows;
}

export function getEyeExamBySpecialtyTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementEyeExamRow[] {
  const rows: EvaluationManagementEyeExamRow[] = [];

  rows.push(...providerEvaluationManagement.specialtyEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
    rowForEyeExam(
      provider.specialtyNodeName + '/' + provider.departmentNodeName,
      provider.cptCategories?.eyeExamNewPatient,
      provider.cptCategories?.eyeExamEstablishedPatient,
      provider.specialtyNodePath,
      provider.specialtyNodeId
    )));

  return rows;
}

export function getEyeExamByProviderTableRowsFrom(providerEvaluationManagement: ProviderMultilevelEvaluationManagement):
  EvaluationManagementEyeExamRow[] {
  const rows: EvaluationManagementEyeExamRow[] = [];

  rows.push(...providerEvaluationManagement.providerEvaluationManagementSnapshotData.nodes.map((provider: ProviderMultilevelCptCounts) =>
    rowForEyeExam(
      provider.providerNodeName + '/' + provider.specialtyNodeName + '/' + provider.departmentNodeName,
      provider.cptCategories?.eyeExamNewPatient,
      provider.cptCategories?.eyeExamEstablishedPatient,
      provider.providerNodePath,
      provider.providerNodeId
    )));

  return rows;
}

export function rowForEyeExam(
  rowName: string,
  eyeExamNewPatient: EyeExamNewPatient,
  eyeExamEstablishedPatient: EyeExamEstablishedPatient,
  nodePath: string,
  nodeId?: number
): EvaluationManagementEyeExamRow {

  return {
    rowName: rowName,
    eyeExamNewPatient: eyeExamNewPatient,
    eyeExamEstablishedPatient: eyeExamEstablishedPatient,
    nodeId: nodeId,
    nodePath: nodePath
  };
}

export function getCptDescription(viewType: CptViewType, cptObject: any): string {
  switch (viewType) {
    case CptViewType.CptFamily:
      return cptObject.cptFamilyDesc;
    case CptViewType.CptRange:
      return cptObject.cptRangeLow + ' - ' + cptObject.cptRangeHigh + ' ' + cptObject.cptRangeDesc;
    default:
      return '';
  }
}

export function getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks: boolean): string {
  return viewCommunityBenchmarks ?
    `${originalBenchmarkOptions.find(b => b.value === BenchmarkOption.Community)?.name} CPSC Benchmark Mean` :
    `${originalBenchmarkOptions.find(b => b.value === BenchmarkOption.Academic)?.name} CPSC Benchmark Mean`;
}

export function getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption: BenchmarkOption): string {
  return `${extendedBenchmarkOptions.find(b => b.value === benchmarkOption)?.name} CPSC Benchmark Mean`;
}

export function getBenchmarkMeanFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks: boolean): string {
  return viewCommunityBenchmarks ?
    `${originalBenchmarkOptions.find(b => b.value === BenchmarkOption.Community)?.field}` :
    `${originalBenchmarkOptions.find(b => b.value === BenchmarkOption.Academic)?.field}`;
}

export function getBenchmarkMeanFieldFromExtendedBenchmarkOptions(benchmarkOption: BenchmarkOption): string {
  return `${extendedBenchmarkOptions.find(b => b.value === benchmarkOption)?.field}`;
}

export const metricsClass = 'metrics';

function getEmTrendColumnsForBase(fromExcelOrCsv: boolean, benchmarkName: string[], benchmarkField: string, slicingIndex: number) {
  return [{
    columnDef: 'nodeName',
    header: 'Year',
    dataName: (row: ProviderCptCounts) => [`${row.nodeName}`, '', ''],
    primaryColumn: true
  }, {
    columnDef: 'nodePath',
    header: 'Month',
    dataName: (row: ProviderCptCounts) => [toMonthName(row.nodePath), '', ''],
    primaryColumn: true
  }, {
    columnDef: `metrics${benchmarkField}`,
    class: metricsClass,
    header: 'Metrics',
    columnType: ColumnType.METRICS,
    dataName: () => !fromExcelOrCsv ? ['# of Encounters', '% Coding Distribution',
        `${benchmarkName.slice(0, slicingIndex).join(' ')} ${benchmarkName.slice(slicingIndex).join(' ')}`] :
      ['# of Encounters', '% Coding Distribution', benchmarkName.join(' ')],
    primaryColumn: true
  }];
}

export function getEmTrendBaseColumnsWithOriginalBenchmarkOptions(viewCommunityBenchmarks: boolean,
                                                                  fromExcelOrCsv = false): DataTableColumns[] {

  const slicingIndex = 5;
  const benchmarkName = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks).split(' ');
  const benchmarkField = getBenchmarkMeanFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getEmTrendColumnsForBase(fromExcelOrCsv, benchmarkName, benchmarkField, slicingIndex);
}

export function getEmTrendBaseColumnsWithExtendedBenchmarkOptions(benchmarkOption: BenchmarkOption,
                                                                  fromExcelOrCsv = false): DataTableColumns[] {
  const slicingIndex = 3;
  const benchmarkName = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption).split(' ');
  const benchmarkField = getBenchmarkMeanFieldFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmTrendColumnsForBase(fromExcelOrCsv, benchmarkName, benchmarkField, slicingIndex);
}

function emTabularColumn(tab: MultilevelTab): DataTableColumns[] {
  switch (tab) {
    case MultilevelTab.BY_SPECIALTY:
      return [{
        columnDef: 'specialtyNodeName',
        header: 'Specialty / Department',
        dataNameList: ['Specialty /', 'Department'],
        dataName: (row: ProviderMultilevelCptCounts) => [`${row.specialtyNodeName} /`, `${row.departmentNodeName}`],
        primaryColumn: true
      }];
    case MultilevelTab.BY_PROVIDER:
      return [{
        columnDef: 'providerNodeName',
        header: 'Provider / Specialty / Department',
        dataNameList: ['Provider /', 'Specialty /', 'Department'],
        dataName: (row: ProviderMultilevelCptCounts) => [`${row.providerNodeName} /`, `${row.specialtyNodeName} /`,
          `${row.departmentNodeName}`],
        primaryColumn: true
      }];
    default:
      return [{
        columnDef: 'departmentNodeName',
        header: 'Department',
        dataNameList: ['Department'],
        dataName: (row: ProviderMultilevelCptCounts) => [`${row.departmentNodeName}`],
        primaryColumn: true
      }];
  }
}

function emSnapshotColumnsForBase(benchmarkName: string[], tab: MultilevelTab) {
  return emTabularColumn(tab).concat([{
    columnDef: 'metrics',
    header: 'Metrics',
    dataName: () => ['# of Encounters', '% Coding Distribution', benchmarkName.join(' ')],
    primaryColumn: true
  }]);
}

export function getEmSnapshotBaseColumnsWithOriginalBenchmarkOptions(viewCommunityBenchmarks: boolean,
                                                                     tab: MultilevelTab): DataTableColumns[] {
  const benchmarkName = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks).split(' ');
  return emSnapshotColumnsForBase(benchmarkName, tab);
}

export function getEmSnapshotBaseColumnsWithExtendedBenchmarkOptions(benchmarkOption: BenchmarkOption,
                                                                     tab: MultilevelTab): DataTableColumns[] {
  const benchmarkName = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption).split(' ');
  return emSnapshotColumnsForBase(benchmarkName, tab);
}

export enum CptCategoryViewType {
  NewPatient = 1,
  EstablishedPatient = 2,
  Consults = 3,
  InitialHospital = 4,
  SubsequentHospital = 5,
  HospitalDischarge = 6,
  EmergencyMedicine = 7,
  EyeExamNew = 8,
  EyeExamEstablished = 9
}

export function getEvaluationManagementViewTypeText(viewType: CptCategoryViewType): string {
  switch (viewType) {
    case CptCategoryViewType.NewPatient:
      return 'Office/Outpatient Visit New 99201 - 99205';
    case CptCategoryViewType.EstablishedPatient:
      return 'Office/Outpatient Visit Established 99211 - 99215';
    case CptCategoryViewType.Consults:
      return 'Office Consultation 99241 - 99245';
    case CptCategoryViewType.InitialHospital:
      return 'Initial Hospital Care 99221 - 99223';
    case CptCategoryViewType.SubsequentHospital:
      return 'Subsequent Hospital Care 99231 - 99233';
    case CptCategoryViewType.HospitalDischarge:
      return 'Hospital Discharge Day 99238 - 99239';
    case CptCategoryViewType.EmergencyMedicine:
      return 'Emergency Medicine  99281 - 99285';
    case CptCategoryViewType.EyeExamNew:
      return 'New Patient Eye Exam  92002 - 92004';
    case CptCategoryViewType.EyeExamEstablished:
      return 'Established Patient Eye Exam  92012 - 92014';
  }
}

export function getCorrespondingEmTitle(dimension: EmDimension): string {
  switch (dimension) {
    case EmDimension.Outpatient:
      return 'Outpatient';
    case EmDimension.InPatient:
      return 'Inpatient';
    case EmDimension.Ophthalmology:
      return 'Ophthalmology';
    case EmDimension.EmergencyMedicine:
      return 'Emergency Medicine';
  }
}

export interface EmRangeAndColumns {
  cptCategoryMap: CptRangeMap;
  columns: DataTableColumns[];
  summaryColumns: DataTableColumns[];
  dimension: EmDimension;
  rangeIndex: number;
  viewType: CptRangeViewType;
}

export interface CptRangeMap {
  range: string;
  codes: string[];
  viewType: CptCategoryViewType;
  dimension: EmDimension;
}

export const NEW_PATIENT_CPT_CODES = ['99201', '99202', '99203', '99204', '99205'];
export const NEW_PATIENT_CPT_CODES_UPDATED = ['99202', '99203', '99204', '99205'];

export const NewPatientVisitCodes: CptRangeMap = {
  range: 'newPatientVisit',
  codes: NEW_PATIENT_CPT_CODES,
  viewType: CptCategoryViewType.NewPatient,
  dimension: EmDimension.Outpatient
};

export const NewPatientVisitUpdatedCodes: CptRangeMap = {
  ...NewPatientVisitCodes,
  codes: NEW_PATIENT_CPT_CODES_UPDATED
};

export const EstablishedPatientVisitCodes: CptRangeMap = {
  range: 'establishedPatientVisit',
  codes: ['99211', '99212', '99213', '99214', '99215'],
  viewType: CptCategoryViewType.EstablishedPatient,
  dimension: EmDimension.Outpatient
};

export const ConsultationCodes: CptRangeMap = {
  range: 'consultation',
  codes: ['99241', '99242', '99243', '99244', '99245'],
  viewType: CptCategoryViewType.Consults, dimension: EmDimension.Outpatient
};

export const InitialHospitalCareCodes: CptRangeMap = {
  range: 'initialHospitalCare',
  codes: ['99221', '99222', '99223'],
  viewType: CptCategoryViewType.InitialHospital, dimension: EmDimension.InPatient
};

export const SubsequentHospitalCareCodes: CptRangeMap = {
  range: 'subsequentHospitalCare',
  codes: ['99231', '99232', '99233'],
  viewType: CptCategoryViewType.SubsequentHospital, dimension: EmDimension.InPatient
};

export const HospitalDischargeCodes: CptRangeMap = {
  range: 'hospitalDischargeDay',
  codes: ['99238', '99239'],
  viewType: CptCategoryViewType.HospitalDischarge, dimension: EmDimension.InPatient
};

export const EmergencyMedicineCodes: CptRangeMap = {
  range: 'emergencyMedicine',
  codes: ['99281', '99282', '99283', '99284', '99285'],
  viewType: CptCategoryViewType.EmergencyMedicine, dimension: EmDimension.EmergencyMedicine
};

export const EyeExamNewCodes: CptRangeMap = {
  range: 'eyeExamNewPatient',
  codes: ['92002', '92004'],
  viewType: CptCategoryViewType.EyeExamNew, dimension: EmDimension.Ophthalmology
};

export const EyeExamEstablishedCodes: CptRangeMap = {
  range: 'eyeExamEstablishedPatient',
  codes: ['92012', '92014'],
  viewType: CptCategoryViewType.EyeExamEstablished, dimension: EmDimension.Ophthalmology
};

export const OutpatientRanges: CptRangeMap[] = [NewPatientVisitCodes, EstablishedPatientVisitCodes, ConsultationCodes];
export const InpatientRanges: CptRangeMap[] = [InitialHospitalCareCodes, SubsequentHospitalCareCodes, HospitalDischargeCodes];
export const EmergencyMedicineRanges: CptRangeMap[] = [EmergencyMedicineCodes];
export const EyeExamRanges: CptRangeMap[] = [EyeExamNewCodes, EyeExamEstablishedCodes];
export const AllEvaluationManagementCategories: CptRangeMap[] =
  [...OutpatientRanges, ...InpatientRanges, ...EmergencyMedicineRanges, ...EyeExamRanges];

export const outpatientRangesWithCPTUpdate: CptRangeMap[] = [NewPatientVisitUpdatedCodes, EstablishedPatientVisitCodes, ConsultationCodes];
export const AllEMCategoriesWithCPTUpdate: CptRangeMap[] =
  [...outpatientRangesWithCPTUpdate, ...InpatientRanges, ...EmergencyMedicineRanges, ...EyeExamRanges];

export function getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunity: boolean): string {
  return (viewCommunity ?
    originalBenchmarkOptions.find(b => b.value === BenchmarkOption.Community)?.field :
    originalBenchmarkOptions.find(b => b.value === BenchmarkOption.Academic)?.field) ?? '';
}

export function getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption: BenchmarkOption): string {
  return extendedBenchmarkOptions.find(b => b.value === benchmarkOption)?.field ?? '';
}

export interface CptCategoriesAndFilename {
  map: CptRangeMap[];
  fileName: string;
  sheetName?: string;
}

export function getEmCategoriesByDimension(dimension: EmDimension, tab = ''): CptCategoriesAndFilename {
  switch (dimension) {
    case EmDimension.Outpatient:
      return {map: OutpatientRanges, fileName: 'EM Outpatient ' + tab};
    case EmDimension.InPatient:
      return {map: InpatientRanges, fileName: 'EM Inpatient ' + tab};
    case EmDimension.EmergencyMedicine:
      return {
        map: EmergencyMedicineRanges,
        fileName: 'EM Emergency Medicine ' + tab,
        sheetName: 'EM Emergency Med ' + tab
      };
    case EmDimension.Ophthalmology:
      return {map: EyeExamRanges, fileName: 'EM Ophthalmology ' + tab};
  }
}

export function getEmSummaryColumnsByDimension(dimension: EmDimension,
                                               benchmarkOption: BenchmarkOption): DataTableColumns[] {
  function dashForZero(value: string): string {
    return ['0', '0%'].includes(value) ? '-' : value;
  }

  const emCategories: CptRangeMap[] = getEmCategoriesByDimension(dimension).map;
  const benchmarkField = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const benchmarkName = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  let columns: DataTableColumns[] = [{
    dataName: () => ['# of Encounters', '% Coding Distribution', benchmarkName],
    columnDef: 'metrics', header: 'Metrics'
  }];
  emCategories.forEach(emc => {
    columns = columns.concat(emc.codes.map(c => {
      return {
        dataName: (row: EvaluationManagementSummary) => [`${isValidOrElse(row.totals[emc.range],
          `cpt${c}`, 'count', '-')}`, `${isValidOrElse(row.totals[emc.range],
          `cpt${c}`, 'percentage', '-', '%')}`,
          dashForZero(`${isValidOrElse(row.totals[emc.range], `cpt${c}`, benchmarkField, '-',
            '%')}`)], columnDef: c, header: c, aboveHeaderText: emc.codes[0].localeCompare(c) === 0 ?
          getEvaluationManagementViewTypeText(emc.viewType) : undefined
      };
    }));
    columns.push({
      dataName: (row: EvaluationManagementSummary) => [emc.codes.map(c => +isValidOrElse(row.totals[emc.range],
        `cpt${c}`, 'count', '0')).reduce((a, b) => a + b),
        `${emc.codes.map(c => +isValidOrElse(row.totals[emc.range],
          `cpt${c}`, 'percentage', '0')).reduce((a, b) => a + b)}%`,
        dashForZero(`${emc.codes.map(c => +isValidOrElse(row.totals[emc.range],
          `cpt${c}`, benchmarkField, '0')).reduce((a, b) => a + b)}%`)],
      columnDef: emc.range, isTotal: true, header: 'Total'
    });
  });
  return columns;
}

function appendColumnsForCodesAndTotal(emCategories: CptRangeMap[], benchmarkField: string) {
  const cols: DataTableColumns[] = [];
  emCategories.forEach(category => {
    let codeIndex = 0;
    category.codes.forEach(code => {
      cols.push({
        columnDef: code, header: code, columnType: ColumnType.CPT_CODE,
        aboveHeaderText: !codeIndex++ ? getEvaluationManagementViewTypeText(category.viewType) : undefined,
        dataName: (row: ProviderCptCounts) => [
          isValidOrElse(row.cptCategories[category.range], 'cpt' + code, 'count', '-'),
          isValidOrElse(row.cptCategories[category.range], 'cpt' + code, 'percentage', '-', '%'),
          isValidOrElse(row.cptCategories[category.range], 'cpt' + code, benchmarkField, '-', '%')]
      });
    });
    cols.push({
      columnDef: category.range,
      header: 'Total', isTotal: true,
      dataName: (row: ProviderCptCounts) => getTotals(row, category.range),
      columnType: ColumnType.CPT_RANGE_TOTAL
    });
  });
  return cols;
}

function getEmMainColumnsWithExtendedBenchmarks(dimension: EmDimension, benchmarkOption: BenchmarkOption): DataTableColumns[] {
  const emCategories: CptRangeMap[] = getEmCategoriesByDimension(dimension).map;
  const benchmarkField = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  return appendColumnsForCodesAndTotal(emCategories, benchmarkField);
}

function getEmMainColumnsWithOriginalBenchmarks(dimension: EmDimension, communityBenchmarks:
  boolean): DataTableColumns[] {
  const emCategories: CptRangeMap[] = getEmCategoriesByDimension(dimension).map;
  const benchmarkField = getBenchmarkFieldFromOriginalBenchmarkOptions(communityBenchmarks);
  return appendColumnsForCodesAndTotal(emCategories, benchmarkField);
}

export function getEmTrendColumnsWithOriginalBenchmarkOptions(dimension: EmDimension, communityBenchmarks:
  boolean, fromExcelOrCsv = false): DataTableColumns[] {
  return getEmTrendBaseColumnsWithOriginalBenchmarkOptions(communityBenchmarks, fromExcelOrCsv)
    .concat(getEmMainColumnsWithOriginalBenchmarks(dimension, communityBenchmarks));
}

export function getEmTrendColumnsWithExtendedBenchmarkOptions(dimension: EmDimension, benchmarkOption:
  BenchmarkOption, fromExcelOrCsv = false): DataTableColumns[] {
  return getEmTrendBaseColumnsWithExtendedBenchmarkOptions(benchmarkOption, fromExcelOrCsv)
    .concat(getEmMainColumnsWithExtendedBenchmarks(dimension, benchmarkOption));
}

export function getEmSnapshotColumnsWithExtendedBenchmarkOptions(dimension: EmDimension,
                                                                 benchmarkOption: BenchmarkOption,
                                                                 tab: MultilevelTab): DataTableColumns[] {
  return getEmSnapshotBaseColumnsWithExtendedBenchmarkOptions(benchmarkOption, tab)
    .concat(getEmMainColumnsWithExtendedBenchmarks(dimension, benchmarkOption));
}

export function getEmSnapshotColumnsWithOriginalBenchmarkOptions(dimension: EmDimension, communityBenchmarks:
  boolean, tab: MultilevelTab): DataTableColumns[] {
  return getEmSnapshotBaseColumnsWithOriginalBenchmarkOptions(communityBenchmarks, tab)
    .concat(getEmMainColumnsWithOriginalBenchmarks(dimension, communityBenchmarks));
}

export const theCptSummary1: CptSummary = {
  count: 23,
  benchmarkCptUnits: 23,
  benchmarkRangeUnits: 23,
  totalCount: 44,
  percentage: 44,
  benchmarkPercentage: 17,
  communityBenchmarkPercentage: 40,
  telehealthBenchmarkPercentage: 29,
  communityTelehealthBenchmarkPercentage: 22,
  communityBenchmarkCptUnits: 0,
  communityBenchmarkRangeUnits: 0,
  telehealthBenchmarkCptUnits: 0,
  telehealthBenchmarkRangeUnits: 0,
  communityTelehealthBenchmarkCptUnits: 0,
  communityTelehealthBenchmarkRangeUnits: 0,
  inPersonBenchmarkCptUnits: 0,
  inPersonBenchmarkRangeUnits: 0,
  communityInPersonBenchmarkCptUnits: 0,
  communityInPersonBenchmarkRangeUnits: 0
};

export const theCptSummary2: CptSummary = {
  count: 41,
  benchmarkCptUnits: 20,
  benchmarkRangeUnits: 20,
  totalCount: 41,
  percentage: 41,
  benchmarkPercentage: 19,
  communityBenchmarkPercentage: 60,
  telehealthBenchmarkPercentage: 39,
  communityTelehealthBenchmarkPercentage: 3,
  communityBenchmarkCptUnits: 0,
  communityBenchmarkRangeUnits: 0,
  telehealthBenchmarkCptUnits: 0,
  telehealthBenchmarkRangeUnits: 0,
  communityTelehealthBenchmarkCptUnits: 0,
  communityTelehealthBenchmarkRangeUnits: 0,
  inPersonBenchmarkCptUnits: 0,
  inPersonBenchmarkRangeUnits: 0,
  communityInPersonBenchmarkCptUnits: 0,
  communityInPersonBenchmarkRangeUnits: 0
};

export const theCptSummary3: CptSummary = {
  count: 32,
  benchmarkCptUnits: 32,
  benchmarkRangeUnits: 32,
  totalCount: 54,
  percentage: 54,
  benchmarkPercentage: 71,
  communityBenchmarkPercentage: 8,
  telehealthBenchmarkPercentage: 59,
  communityTelehealthBenchmarkPercentage: 52,
  communityBenchmarkCptUnits: 0,
  communityBenchmarkRangeUnits: 0,
  telehealthBenchmarkCptUnits: 0,
  telehealthBenchmarkRangeUnits: 0,
  communityTelehealthBenchmarkCptUnits: 0,
  communityTelehealthBenchmarkRangeUnits: 0,
  inPersonBenchmarkCptUnits: 0,
  inPersonBenchmarkRangeUnits: 0,
  communityInPersonBenchmarkCptUnits: 0,
  communityInPersonBenchmarkRangeUnits: 0
};

export function theCptSummary4(): CptSummary {
  return {
    count: 23,
    benchmarkCptUnits: 23,
    benchmarkRangeUnits: 23,
    totalCount: 44,
    percentage: 44,
    benchmarkPercentage: 17,
    communityBenchmarkPercentage: 40,
    telehealthBenchmarkPercentage: 29,
    communityTelehealthBenchmarkPercentage: 22,
    communityBenchmarkCptUnits: 11,
    communityBenchmarkRangeUnits: 13,
    telehealthBenchmarkCptUnits: 15,
    telehealthBenchmarkRangeUnits: 17,
    communityTelehealthBenchmarkCptUnits: 98,
    communityTelehealthBenchmarkRangeUnits: 91,
    inPersonBenchmarkCptUnits: 81,
    inPersonBenchmarkRangeUnits: 88,
    communityInPersonBenchmarkCptUnits: 80,
    communityInPersonBenchmarkRangeUnits: 107,
    inPersonBenchmarkPercentage: 75,
    communityInPersonBenchmarkPercentage: 60
  };
}

export function theCptSummary5(): CptSummary {
  return {
    count: 41,
    benchmarkCptUnits: 20,
    benchmarkRangeUnits: 20,
    totalCount: 41,
    percentage: 41,
    benchmarkPercentage: 19,
    communityBenchmarkPercentage: 60,
    telehealthBenchmarkPercentage: 39,
    communityTelehealthBenchmarkPercentage: 3,
    communityBenchmarkCptUnits: 19,
    communityBenchmarkRangeUnits: 25,
    telehealthBenchmarkCptUnits: 45,
    telehealthBenchmarkRangeUnits: 66,
    communityTelehealthBenchmarkCptUnits: 68,
    communityTelehealthBenchmarkRangeUnits: 75,
    inPersonBenchmarkCptUnits: 106,
    inPersonBenchmarkRangeUnits: 122,
    communityInPersonBenchmarkCptUnits: 208,
    communityInPersonBenchmarkRangeUnits: 47,
    inPersonBenchmarkPercentage: 175,
    communityInPersonBenchmarkPercentage: 167
  };
}

export function theCptSummary6(): CptSummary {
  return {
    count: 32,
    benchmarkCptUnits: 32,
    benchmarkRangeUnits: 32,
    totalCount: 54,
    percentage: 54,
    benchmarkPercentage: 71,
    communityBenchmarkPercentage: 8,
    telehealthBenchmarkPercentage: 59,
    communityTelehealthBenchmarkPercentage: 52,
    communityBenchmarkCptUnits: 88,
    communityBenchmarkRangeUnits: 184,
    telehealthBenchmarkCptUnits: 168,
    telehealthBenchmarkRangeUnits: 140,
    communityTelehealthBenchmarkCptUnits: 170,
    communityTelehealthBenchmarkRangeUnits: 190,
    inPersonBenchmarkCptUnits: 21,
    inPersonBenchmarkRangeUnits: 40,
    communityInPersonBenchmarkCptUnits: 89,
    communityInPersonBenchmarkRangeUnits: 85,
    inPersonBenchmarkPercentage: 124,
    communityInPersonBenchmarkPercentage: 720
  };
}

export const theCptSummaryZero: CptSummary = {
  count: 32,
  benchmarkCptUnits: 32,
  benchmarkRangeUnits: 32,
  totalCount: 0,
  percentage: 54,
  benchmarkPercentage: 71,
  communityBenchmarkPercentage: 8,
  telehealthBenchmarkPercentage: 59,
  communityTelehealthBenchmarkPercentage: 52,
  communityBenchmarkCptUnits: 0,
  communityBenchmarkRangeUnits: 0,
  telehealthBenchmarkCptUnits: 0,
  telehealthBenchmarkRangeUnits: 0,
  communityTelehealthBenchmarkCptUnits: 0,
  communityTelehealthBenchmarkRangeUnits: 0,
  inPersonBenchmarkCptUnits: 0,
  inPersonBenchmarkRangeUnits: 0,
  communityInPersonBenchmarkCptUnits: 0,
  communityInPersonBenchmarkRangeUnits: 0
};

export function calcSum(elements: number[]) {
  return elements.reduce((a, b) => a + b, 0);
}

export function getTotals(row: ProviderCptCounts, cptCategory: string): string[] {
  const cptCodes = row.cptCategories[cptCategory];
  if (cptCodes) {
    const counts = Object.keys(cptCodes).map(code => (cptCodes[code]?.count) || 0);
    const percentages = Object.keys(cptCodes).map(code => (cptCodes[code]?.percentage) || 0);
    return [calcSum(counts) + '', (calcSum(percentages) ? 100 : 0) + '%', ' '];
  }
  return ['0', '0%', ' '];
}

export function isValid(element: any): boolean {
  return !(element === undefined || element == null);
}

export function parametersForZeroSuppression(dimension: EmDimension): CptRangeMap[] {
  switch (dimension) {
    case EmDimension.Outpatient:
      return OutpatientRanges;
    case EmDimension.InPatient:
      return InpatientRanges;
    case EmDimension.EmergencyMedicine:
      return EmergencyMedicineRanges;
    case EmDimension.Ophthalmology:
      return EyeExamRanges;
  }
}

export function isAllowedToShow(x: any, zeroSuppressionParameters: CptRangeMap[]): boolean {
  for (let i = 0; i < zeroSuppressionParameters.length; i++) {
    const range = zeroSuppressionParameters[i];
    if (!x[range.range]) {
      continue;
    }
    for (let j = 0; j < zeroSuppressionParameters[i].codes.length; j++) {
      const code = range.codes[j];
      const cptSummary: CptSummary | undefined = x[range.range][`cpt${code}`];
      if (cptSummary && cptSummary.totalCount !== 0) {
        return true;
      }
    }
  }
  return false;
}

export const stackColorWheel: string[] = ['#6BABBB', '#BFDEE4', '#F5CFBA', '#E88754', '#BE4F15'];

export interface SelectableCpt {
  text: string;
  all: boolean;
}

export interface CptRangeViewType {
  cptCategoryViewType: CptCategoryViewType;
  text: string;
}

export function getColumnsByDimensionAndBenchmarkOption(dimension: EmDimension, showExtendedBenchmarkOptions: boolean,
                                                        viewCommunityBenchmarks: boolean, benchmarkOption: BenchmarkOption) {
  return showExtendedBenchmarkOptions ? getEmTrendColumnsWithExtendedBenchmarkOptions(dimension, benchmarkOption) :
    getEmTrendColumnsWithOriginalBenchmarkOptions(dimension, viewCommunityBenchmarks);
}

export function buildNodePath(pathArray: string[], index: number, end: number): string {
  const nodePathBuilder = [];
  do {
    nodePathBuilder.push(pathArray[index++]);
  } while (index < end);
  return nodePathBuilder.join('\\');
}

export function valueFrom(cptRange: CptRange, dataField: string, suffix: string = '', round: boolean = false) {

  if (cptRange === undefined) {
    return undefined;
  }

  const result = {};
  for (const cptCode in cptRange) {
    if (cptRange.hasOwnProperty(cptCode)) {
      // @ts-ignore
      result[cptCode] = cptRange[cptCode][dataField] + suffix;
      // @ts-ignore
      if (!result.total) {
        // @ts-ignore
        result.total = 0;
      }
      // @ts-ignore
      result.total += cptRange[cptCode][dataField];
    }
  }
  if (round) {
    // @ts-ignore
    result.total = roundToHundred(result.total);
  }

  // @ts-ignore
  result.total += suffix;

  if (extendedBenchmarkOptions.map(o => o.field).includes(dataField)) {
    // @ts-ignore
    result.total = '-';
  }
  return result;
}

export function valueFromOrElse(
  cptFamily: CptRange,
  field: string,
  suffix: string = '',
  fallbackValue: any,
  round: boolean = false
) {
  const value = valueFrom(cptFamily, field, suffix, round);
  // @ts-ignore
  if (value === undefined
    || value === null ||
    // @ts-ignore
    value.total === undefined || value.total === null ||
    // @ts-ignore
    value.total === 'undefined' || value.total === 'undefined' + suffix ||
    // @ts-ignore
    value.total === 'null' || value.total === 'null' + suffix) {
    return fallbackValue;
  }
  // @ts-ignore
  return value.total;
}

export function valueFromOrDash(cptRange: CptRange, dataField: string, suffix: string = '', round: boolean = false) {
  if (cptRange === undefined) {
    return undefined;
  }

  const result = {};
  for (const cptCode in cptRange) {
    if (cptRange.hasOwnProperty(cptCode)) {
      // @ts-ignore
      result[cptCode] = cptRange[cptCode][dataField] ? cptRange[cptCode][dataField] + suffix : '-';
      // @ts-ignore
      if (!result.total) {
        // @ts-ignore
        result.total = 0;
      }
      // @ts-ignore
      if (isNumeric(result.total)) {
        // @ts-ignore
        result.total += cptRange[cptCode][dataField];
      }
    }
  }
  if (round) {
    // @ts-ignore
    result.total = roundToHundred(result.total);
  }

  // @ts-ignore
  result.total += suffix;

  if (extendedBenchmarkOptions.map(o => o.field).includes(dataField)) {
    // @ts-ignore
    result.total = '';
  }
  return result;
}
