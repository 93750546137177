<div class="graph-container">
  <app-wrvu-multilevel-chart
    [isDepartment]="false"
    [isSpecialty]="true"
    [isProvider]="false"
    [showVariableMenu]="true"
    [level]="'By Specialty'"
    [page]="currentPage"
    [showProgressBar]="showProgressBar"
    [variables]="variables"
    [activeVarianceToggle]="activeVarianceToggle"
    [zeroSuppression]="zeroSuppression"
    [benchmarkPercentile]="benchmarkPercentile"
    [viewCommunityBenchmarks]="viewCommunityBenchmarks"
    [wRVUtype]="wRVUtype"
    [selectionCounts]="selectionCounts"
    [varianceKey]="varianceKey"
    [sortingCriteria]="varianceToggleSortingCriterion"
    [columnSelectionCallback]="whenSpecialtySelected"
    [productivitySnapshotData]="wrvuSnapshotData"
  ></app-wrvu-multilevel-chart>
</div>

<app-wrvu-summary-data-table></app-wrvu-summary-data-table>
<app-data-table id="wrvu-specialty-snapshot-table"
                [tableTitle]="'wRVUs by Specialty'"
                [tableCssClass]="'wrvu-snapshot-table'"
                [showLevel]="false"
                [addRemoveOption]="true"
                [displayZeroSuppression]="true"
                [receivedData]="wrvuSnapshotData"
                [displayedColumns]="displayedColumns"
                [showProgressBar]="showProgressBar"
                [defaultSortColumn]="defaultSortColumn"
                [sortDirection]="sortDirection"
                [tieBreakerProperty]="tieBreakerProperty"
                [rowSelectionCallback]="whenSpecialtyRowSelected"
                [updateSortingCriteria]="updateSortingCriteria"
                [whenSortChanged]="whenSortChanged"
                [isZeroSuppressionChecked]="zeroSuppression"
                [countOfSuppressedEntries]="countOfSuppressedEntries"
                [zeroSuppressionCondition]="zeroSuppressionCondition"
                [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                [currentPage]="currentPage"
                [originalColumns]="columns"
                [reducerAction]="reducerAction"
                [chosen]="MultilevelTab.BY_SPECIALTY"
>
</app-data-table>
