
<div class="graph-container">
  <div class="inner-graph-container">
    <app-new-patient-visits-by-month-chart
      [showProgressBar]="showProgressBar"
      [isSpecialty]="isSpecialty"
      [showExtendedBenchmarkOptions]="showExtendedBenchmarkOptions"
      [isDepartment]="isDepartment"
      page="npvTrend"
      [showVariableMenu]="true"
      [variables]="variables">
    </app-new-patient-visits-by-month-chart>
  </div>
</div>
<app-new-patient-visits-summary-data-table [showExtendedBenchmarkOptions]="showExtendedBenchmarkOptions"></app-new-patient-visits-summary-data-table>
<app-new-patient-visits-data-table id="npv-table"
                [showProgressBar]="showProgressBar"
                [receivedData]="tableMonth"
                [columns]="displayedColumns"
                [defaultSortColumn]="defaultSortColumn"
                sortDirection="asc"
                [showLevel]="false"
                tableTitle="New Patients Trends by Month"
                [sortingDataAccessor]="sortingDataAccessor"
                [whenSortChanged]="whenSortChanged"
                [currentPage]="currentPage"
                [originalColumns]="columns"
                [addRemoveOption]="true"
                [reducerAction]="trendNPVColumnsChangedTo"
>
</app-new-patient-visits-data-table>
