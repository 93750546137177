import {
  CptCategories,
  EmDimension,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  ProviderMultilevelCptCounts
} from '../../../shared/models';
import {DataTableColumns, ExcelTableColumn} from '../../../shared/data-table-columns';
import {getByLevelTitleSubString, getSelectedDateRange, LevelType} from '../../../shared/helpers';
import {ExportMetadata} from '../../../shared/export/export';
import {DataCellsAndHeaders, EmSummaryRow} from '../../export-dialog-helper';
import {
  CptCategoriesAndFilename,
  CptRangeMap,
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkFieldFromOriginalBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromOriginalBenchmarkOptions,
  getEmCategoriesByDimension,
  getEmTrendColumnsWithExtendedBenchmarkOptions,
  getEmTrendColumnsWithOriginalBenchmarkOptions,
  valueFromOrElse
} from '../../../evaluation-management/em-helpers';
import {BehaviorSubject} from 'rxjs';
import {
  evaluationManagementEmergencyByDepartmentExcelData$,
  evaluationManagementEmergencyByProviderExcelData$,
  evaluationManagementEmergencyBySpecialtyExcelData$,
  evaluationManagementEmergencyByTrendExcelData$,
  evaluationManagementInpatientByDepartmentExcelData$,
  evaluationManagementInpatientByProviderExcelData$,
  evaluationManagementInpatientBySpecialtyExcelData$,
  evaluationManagementInpatientByTrendExcelData$,
  evaluationManagementOphthalmologyByDepartmentExcelData$,
  evaluationManagementOphthalmologyByProviderExcelData$,
  evaluationManagementOphthalmologyBySpecialtyExcelData$,
  evaluationManagementOphthalmologyByTrendExcelData$,
  evaluationManagementOutpatientByDepartmentExcelData$,
  evaluationManagementOutpatientByProviderExcelData$,
  evaluationManagementOutpatientBySpecialtyExcelData$,
  evaluationManagementOutpatientByTrendExcelData$
} from '../../export-subscribers';
import {hasValue} from '../../../shared/null-helpers';
import {BenchmarkOption} from '../../../shared/enums';

export async function generateEvaluationManagementOutpatientExportWithOriginalBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel,
  level: LevelType, viewCommunityBenchmarks: boolean) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.Outpatient);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmOutpatientDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(getEmByLevelExportDataObject(
    summaryData, detail, byLevel, outpatientFileName, outpatientPageName, outpatientSheetName));
}

export async function generateEvaluationManagementOutpatientExportWithExtendedBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel,
  level: LevelType, benchmarkOption: BenchmarkOption
) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithExtendedBenchmarkOptions(benchmarkOption);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.Outpatient);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmOutpatientDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(getEmByLevelExportDataObject(
    summaryData, detail, byLevel, outpatientFileName, outpatientPageName, outpatientSheetName));
}

export async function generateEvaluationManagementInpatientExportWithOriginalBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel,
  level: LevelType, viewCommunityBenchmarks: boolean) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.InPatient);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmInpatientDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(
    getEmByLevelExportDataObject(summaryData, detail, byLevel, inpatientFileName, inpatientPageName, inpatientSheetName));
}

export async function generateEvaluationManagementInpatientExportWithExtendedBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel,
  level: LevelType, benchmarkOption: BenchmarkOption) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithExtendedBenchmarkOptions(benchmarkOption);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.InPatient);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmInpatientDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(getEmByLevelExportDataObject(summaryData, detail, byLevel,
    inpatientFileName, inpatientPageName, inpatientSheetName));
}

export async function generateEvaluationManagementEmergencyExportWithOriginalBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel, level: LevelType,
  viewCommunityBenchmarks: boolean) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.EmergencyMedicine);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmEmergencyDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(getEmByLevelExportDataObject(
    summaryData, detail, byLevel, emergencyFileName, emergencyPageName, emergencySheetName));
}

export async function generateEvaluationManagementEmergencyExportWithExtendedBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel, level: LevelType,
  benchmarkOption: BenchmarkOption) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithExtendedBenchmarkOptions(benchmarkOption);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.EmergencyMedicine);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmEmergencyDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(getEmByLevelExportDataObject(
    summaryData, detail, byLevel, emergencyFileName, emergencyPageName, emergencySheetName));
}

export async function generateEvaluationManagementOphthalmologyExportWithOriginalBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel,
  level: LevelType, viewCommunityBenchmarks: boolean) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.Ophthalmology);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmEyeExamDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(getEmByLevelExportDataObject(summaryData, detail, byLevel, eyeExamFileName, eyeExamPageName, eyeExamSheetName));
}

export async function generateEvaluationManagementOphthalmologyExportWithExtendedBenchmarkOptions(
  summaryEvaluationManagementData: EvaluationManagementSummary,
  evaluationManagementMultilevelData: EvaluationManagementMultilevel,
  level: LevelType, benchmarkOption: BenchmarkOption) {
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithExtendedBenchmarkOptions(benchmarkOption);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(EmDimension.Ophthalmology);
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(summaryEvaluationManagementData, summaryRows,
    cptCategoriesForTotals, summaryColumns);
  const detail = generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData,
    summaryRows, cptCategoriesForTotals, summaryColumns, level);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmEyeExamDesignatedObservableByLevel(level);
  const byLevel = getByLevelTitleSubString(level);
  designatedObservable.next(getEmByLevelExportDataObject(summaryData, detail, byLevel, eyeExamFileName, eyeExamPageName, eyeExamSheetName));
}


export async function generateEmTrendExcelDataWithOriginalBenchmarkOptions(dimension: EmDimension, communityBenchmarks: boolean,
                                                                           nodes: ProviderMultilevelCptCounts[],
                                                                           emSummary: EvaluationManagementSummary) {
  const emColumns = getEmTrendColumnsWithOriginalBenchmarkOptions(dimension, communityBenchmarks, true);
  const detailData: string[][] = [];
  setUpRowsAndColumnsForEmExcelData(nodes, emColumns, detailData);
  const summaryRows: EmSummaryRow[] = [
    ...sharedEvaluationManagementSummaryRows,
    {
      field: getBenchmarkFieldFromOriginalBenchmarkOptions(communityBenchmarks),
      heading: getBenchmarkMeanNameFromOriginalBenchmarkOptions(communityBenchmarks), suffix: '%', round: true
    }
  ];
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(dimension, 'Trend');
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);

  const summaryData = generateExcelEvaluationManagementSummaryDataCells(emSummary, summaryRows, cptCategoriesForTotals,
    summaryColumns);
  const detailHeaders = emColumns.map(col => col.header);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmTrendDesignatedObservableByDimension(dimension);
  designatedObservable.next(getEmTrendDataExportObject(summaryData, detailData, detailHeaders, categoriesAndFilesName));
}

export async function generateEmTrendExcelDataWithExtendedBenchmarkOptions(dimension: EmDimension,
                                                                           nodes: ProviderMultilevelCptCounts[],
                                                                           emSummary: EvaluationManagementSummary,
                                                                           benchmarkOption: BenchmarkOption) {
  const emColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(dimension, benchmarkOption, true);
  const detailData: string[][] = [];
  setUpRowsAndColumnsForEmExcelData(nodes, emColumns, detailData);
  const summaryRows: EmSummaryRow[] = getEmSummaryRowsWithExtendedBenchmarkOptions(benchmarkOption);
  const summaryColumns: ExcelTableColumn[] = [];
  const cptCategoriesForTotals: DataTableColumns[] = [];
  const categoriesAndFilesName: CptCategoriesAndFilename = getEmCategoriesByDimension(dimension, 'Trend');
  pushSummariesAndTotalsColumns(categoriesAndFilesName, summaryColumns, cptCategoriesForTotals);
  const summaryData = generateExcelEvaluationManagementSummaryDataCells(emSummary, summaryRows, cptCategoriesForTotals,
    summaryColumns);
  const detailHeaders = emColumns.map(col => col.header);
  const designatedObservable: BehaviorSubject<ExportMetadata | boolean> = getEmTrendDesignatedObservableByDimension(dimension);
  designatedObservable.next(getEmTrendDataExportObject(summaryData, detailData, detailHeaders, categoriesAndFilesName));
}

const emCopyrightString =
  `Clinical Practice Solutions Center Evaluation and Management Analysis CPT® only © ${new Date().getFullYear() - 1} American Medical Association. All Rights Reserved. `;

const allTrueExportFilters = {
  showBreadcrumb: true,
  showDateRange: true,
  showLocation: true,
  showVisitType: true,
  showPayer: true
};
const sharedEvaluationManagementSummaryRows: EmSummaryRow[] = [
  {
    field: 'count', heading: '# of Encounters', suffix: ''
  },
  {
    field: 'percentage', heading: 'Coding Distribution', suffix: '%', round: true
  }
];

const outpatientFileName = 'outpatientEandM';
const outpatientPageName = 'EM Outpatient';
const outpatientSheetName = 'EM Outpatient';

const inpatientFileName = 'inpatientEandM';
const inpatientPageName = 'EM Inpatient';
const inpatientSheetName = 'EM Inpatient';

const eyeExamFileName = 'eyeExamEandM';
const eyeExamSheetName  = 'EM Ophthalmology';
const eyeExamPageName = 'EM Eye Exam';

const emergencyFileName = 'emergencyRoomEandM';
const emergencyPageName = 'EM Emergency Room';
const emergencySheetName = 'EM Emergency Med';



function getEmOutpatientDesignatedObservableByLevel(level: LevelType) {
  switch (level) {
    case LevelType.department:
      return evaluationManagementOutpatientByDepartmentExcelData$;
    case LevelType.specialty:
      return evaluationManagementOutpatientBySpecialtyExcelData$;
    case LevelType.provider:
      return evaluationManagementOutpatientByProviderExcelData$;
  }
  return new BehaviorSubject<ExportMetadata | boolean>(false);
}

function getEmEmergencyDesignatedObservableByLevel(level: LevelType) {
  switch (level) {
    case LevelType.department:
      return evaluationManagementEmergencyByDepartmentExcelData$;
    case LevelType.specialty:
      return evaluationManagementEmergencyBySpecialtyExcelData$;
    case LevelType.provider:
      return evaluationManagementEmergencyByProviderExcelData$;
  }
  return new BehaviorSubject<ExportMetadata | boolean>(false);
}

function getEmInpatientDesignatedObservableByLevel(level: LevelType) {
  switch (level) {
    case LevelType.department:
      return evaluationManagementInpatientByDepartmentExcelData$;
    case LevelType.specialty:
      return evaluationManagementInpatientBySpecialtyExcelData$;
    case LevelType.provider:
      return evaluationManagementInpatientByProviderExcelData$;
  }
  return new BehaviorSubject<ExportMetadata | boolean>(false);
}

function getEmEyeExamDesignatedObservableByLevel(level: LevelType) {
  switch (level) {
    case LevelType.department:
      return evaluationManagementOphthalmologyByDepartmentExcelData$;
    case LevelType.specialty:
      return evaluationManagementOphthalmologyBySpecialtyExcelData$;
    case LevelType.provider:
      return evaluationManagementOphthalmologyByProviderExcelData$;
  }
  return new BehaviorSubject<ExportMetadata | boolean>(false);
}

function getEmTrendDesignatedObservableByDimension(dimension: EmDimension) {
  switch (dimension) {
    case EmDimension.Outpatient:
      return evaluationManagementOutpatientByTrendExcelData$;
    case EmDimension.InPatient:
      return evaluationManagementInpatientByTrendExcelData$;
    case EmDimension.Ophthalmology:
      return evaluationManagementOphthalmologyByTrendExcelData$;
    case EmDimension.EmergencyMedicine:
      return evaluationManagementEmergencyByTrendExcelData$;
  }
  return new BehaviorSubject<ExportMetadata | boolean>(false);
}

function pushSummariesAndTotalsColumns(categoriesAndFilesName: CptCategoriesAndFilename,
                                       summaryColumns: ExcelTableColumn[], cptCategoriesForTotals: DataTableColumns[]) {
  categoriesAndFilesName.map.forEach(cat => {
    pushCptSummaryColumns(cat, summaryColumns);
    pushTotalColumn(cat, cptCategoriesForTotals);
  });
}

function pushTotalColumn(cat: CptRangeMap, cptCategoriesForTotals: DataTableColumns[]) {
  cptCategoriesForTotals.push({
    header: 'Total', columnDef: cat.range, dataName: (entry: CptCategories) => entry[cat.range]
  });
}

function getEmTrendDataExportObject(summaryData: DataCellsAndHeaders, detailData: string[][],
                                    detailHeaders: string[], categoriesAndFilesName: CptCategoriesAndFilename) {
  return {
    summaryData: summaryData.cells,
    detailData: detailData,
    copyright: emCopyrightString,
    summaryHeaders: ['Time Range'].concat(detailHeaders.slice(2)),
    detailHeaders: ['Year Month'].concat(detailHeaders.slice(2)),
    fileName: categoriesAndFilesName.fileName,
    page: categoriesAndFilesName.fileName,
    title: emCopyrightString,
    isBlankRowAfterSummary: true,
    whatFilters: allTrueExportFilters,
    sheetName: categoriesAndFilesName.sheetName || categoriesAndFilesName.fileName
  };
}

function setUpRowsAndColumnsForEmExcelData(nodes: ProviderMultilevelCptCounts[], emColumns: DataTableColumns[], detailData: string[][]) {
  nodes.forEach(node => {
    const dataNames: any[] = [];
    let rowsEach = 0;
    emColumns.forEach(col => {
      const dataName = col.dataName(node);
      if (dataName.length > rowsEach) {
        rowsEach = dataName.length;
      }
      dataNames.push(dataName);
    });
    const rows: string[][] = [];
    for (let i = 0; i < rowsEach; i++) {
      rows.push([]);
    }
    dataNames[1][0] = dataNames[0][0] + ' ' + dataNames[1][0];
    dataNames.slice(1).forEach(dN => {
      for (let j = 0; j < dN.length; j++) {
        rows[j].push(dN[j]);
      }
    });
    rows.forEach(row => {
      detailData.push(row);
    });
  });
}

function pushCptSummaryColumns(cat: CptRangeMap, summaryColumns: ExcelTableColumn[]) {
  for (let i = 0; i < cat.codes.length; i++) {
    summaryColumns.push({
      header: cat.codes[i], columnDef: 'cpt' + cat.codes[i],
      dataName: (entry: CptCategories) => entry[cat.range]?.['cpt' + cat.codes[i]],
      isLastInGroup: i === cat.codes.length - 1
    });
  }
}

function getEmByLevelExportDataObject(summaryData: DataCellsAndHeaders, detail: DataCellsAndHeaders, byLevel: string,
                                      fileName: string, pageName: string, sheetName: string) {
  return {
    summaryData: summaryData.cells,
    summaryHeaders: summaryData.headers,
    detailData: detail.cells,
    detailHeaders: detail.headers,
    fileName: fileName,
    page: `${pageName} ${byLevel}`,
    isBlankRowAfterSummary: true,
    copyright: emCopyrightString,
    title: emCopyrightString,
    whatFilters: allTrueExportFilters,
    sheetName: `${sheetName} ${byLevel}`
  };
}

function getEmSummaryRowsWithExtendedBenchmarkOptions(benchmarkOption: BenchmarkOption) {
  return [
    ...sharedEvaluationManagementSummaryRows,
    {
      field: getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption),
      heading: getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption), suffix: '%', round: true
    }
  ];
}


function generateExcelEvaluationManagementSummaryDataCells(
  summaryEvaluationManagementData: EvaluationManagementSummary, summaryRows: EmSummaryRow[],
  cptCategoriesForTotals: DataTableColumns[], summaryColumns: ExcelTableColumn[]
): DataCellsAndHeaders {
  const dataRows: string[][] = [];
  const headers: string[] = ['Time Range', 'Metrics'];
  summaryRows.forEach(row => {
    const dataRow: string[] = [`Selected Date Range (${getSelectedDateRange({
      startMonth: summaryEvaluationManagementData.startMonth,
      endMonth: summaryEvaluationManagementData.endMonth,
      startYear: summaryEvaluationManagementData.startYear,
      endYear: summaryEvaluationManagementData.endYear
    })})`, row.heading];
    let totalIndex = 0;
    let columnIndex = 2;
    summaryColumns.forEach(col => {
      const dataName = col.dataName(summaryEvaluationManagementData.totals);
      if (dataName) {
        const field = dataName[row.field];
        dataRow.push(hasValue(field) ? `${field}${row.suffix}` : '-');
      } else {
        dataRow.push('-');
      }
      headers.splice(columnIndex++, 1, col.header);
      if (col.isLastInGroup) {
        dataRow.push(valueFromOrElse(cptCategoriesForTotals[totalIndex++].dataName(summaryEvaluationManagementData.totals),
          row.field, row.suffix, '-', row.round));
        headers.splice(columnIndex++, 1, 'Total');
      }
    });
    dataRows.push(dataRow);
  });
  return {
    cells: dataRows,
    headers: headers
  };
}

function generateExcelEvaluationManagementSnapshotDataCells(evaluationManagementMultilevelData: EvaluationManagementMultilevel,
                                                            summaryRows: EmSummaryRow[], cptCategoriesForTotals: DataTableColumns[],
                                                            summaryColumns: ExcelTableColumn[],
                                                            level: LevelType): DataCellsAndHeaders {
  const dataRows: string[][] = [];
  const headers: string[] = [];
  const nodeNames: string[] = [];
  let nodeHeader = '';
  let snapshotData: { nodes: ProviderMultilevelCptCounts[], totals: CptCategories } | undefined;
  switch (level) {
    case LevelType.provider:
      nodeNames.push('providerNodeName');
      nodeHeader = nodeHeader.concat('Provider');
      snapshotData = evaluationManagementMultilevelData.evaluationManagementData.providerEvaluationManagementSnapshotData;
    // tslint:disable-next-line:no-switch-case-fall-through
    case LevelType.specialty:
      nodeNames.push('specialtyNodeName');
      nodeHeader = nodeHeader.concat(!nodeHeader.length ? 'Specialty' : '/Specialty');
      snapshotData = snapshotData || evaluationManagementMultilevelData.evaluationManagementData.specialtyEvaluationManagementSnapshotData;
    // tslint:disable-next-line:no-switch-case-fall-through
    case LevelType.department:
      nodeNames.push('departmentNodeName');
      nodeHeader = nodeHeader.concat(!nodeHeader.length ? 'Department' : '/Department');
      snapshotData = snapshotData || evaluationManagementMultilevelData.evaluationManagementData.departmentEvaluationManagementSnapshotData;
      break;
  }
  snapshotData?.nodes.forEach(node => {
    summaryRows.forEach(row => {
      let nodeName = '';
      nodeNames.forEach(name => {
        nodeName = nodeName.concat(!nodeName.length ? node[name].toString() : ': '.concat(node[name].toString()));
      });
      const dataRow: string[] = [nodeName, row.heading];
      headers.splice(0, 2, nodeHeader, 'Metrics');
      let totalIndex = 0;
      let columnIndex = 2;
      summaryColumns.forEach(col => {
        const dataName = col.dataName(node.cptCategories);
        if (dataName) {
          const theField = dataName[row.field];
          if (hasValue(theField)) {
            dataRow.push(`${theField}${row.suffix}`);
          } else {
            dataRow.push('-');
          }
        } else {
          dataRow.push('-');
        }
        headers.splice(columnIndex++, 1, col.header);
        if (col.isLastInGroup) {
          dataRow.push(valueFromOrElse(cptCategoriesForTotals[totalIndex++].dataName(node.cptCategories),
            row.field, row.suffix, '-', row.round));
          headers.splice(columnIndex++, 1, 'Total');
        }
      });
      dataRows.push(dataRow);
    });
  });
  return {
    cells: dataRows,
    headers: headers
  };
}

function getEmSummaryRowsWithOriginalBenchmarkOptions(viewCommunityBenchmarks: boolean) {
  return [
    ...sharedEvaluationManagementSummaryRows,
    {
      field: getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks),
      heading: getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks), suffix: '%', round: true
    }
  ];
}
