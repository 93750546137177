import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {Members} from './../shared/models';

export const MemberServiceToken = new InjectionToken<MemberService>('MemberService');

export interface MemberService {
  getMembers(): Observable<Members>;
  isRevenueMember(memberKey: number): Observable<boolean>;
}

@Injectable()
export class HttpMemberService implements MemberService {
  constructor(private readonly httpClient: HttpClient) { }

  getMembers(): Observable<Members> {
    return this.httpClient.get<Members>(`${environment.api}/api/members`);
  }

  isRevenueMember(memberKey: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.api}/api/isRevenueMember?memberKey=${memberKey}`);
  }

}
