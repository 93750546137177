import {AfterViewInit, Component, Input, OnChanges, ViewChild} from '@angular/core';
import {AgmInfoWindow, AgmMap, MarkerLabel} from '@agm/core';
import {GmapMarker} from '../../../../shared/models';
import {AgmHelperService} from '../../../../zipcode-map/zipcode-map.component';

@Component({
  selector: 'app-zip-code-bubble-map',
  templateUrl: './zip-code-bubble-map.component.html',
  styleUrls: ['./zip-code-bubble-map.component.scss']
})
export class ZipCodeBubbleMapComponent implements AfterViewInit, OnChanges {

  @ViewChild('AgmMap') agmMap: AgmMap;
  @Input() mapData: GmapMarker[];
  @Input() fromPdf: boolean;
  readonly balloonUrl = 'assets/orangeBalloon.svg';
  selectedMarker?: GmapMarker;
  previous: any;
  private readonly markerCount = 10;
  private readonly initialBubbleRadiusPercentage = Math.pow(this.markerCount, 2);
  private readonly labelOptionTemplate: MarkerLabel = {
    color: '#FFFFFF',
    fontFamily: '',
    fontSize: '12px',
    fontWeight: 'bold',
    text: ''
  };
  private readonly spaceCharacter = '\u2800';
  readonly bubbleRadii = Array.from(Array(this.markerCount).keys()).map(e => {
    const val = +(this.initialBubbleRadiusPercentage - (e * (this.initialBubbleRadiusPercentage / (1 + this.markerCount))));
    return val.toFixed(2);
  });
  readonly bubbleRadiusMax = 6000;
  readonly bubbleIndicatorColor = '#FF5903';
  readonly DEFAULT_INDEX_OF_HOVER = -1;
  readonly defaultBigZoom = 9;
  readonly defaultLittleZoom = 4;
  readonly greaterOpacity = 0.35;
  readonly lesserOpacity = 0.15;
  readonly strokeWeight = 2;
  labelOptions: MarkerLabel[] = [];
  indexOfHover = this.DEFAULT_INDEX_OF_HOVER;

  constructor(private agmHelper: AgmHelperService) { }

  clickedMarker(data: GmapMarker, infoWindow: AgmInfoWindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infoWindow;
    this.selectedMarker = data;
  }

  ngOnChanges() {
    this.labelOptions = Array.from(Array(this.markerCount).keys()).map(e => {
      return {
        ...this.labelOptionTemplate,
        text: this.getCenteredTextForMarkerLabel(e)
      };
    });
  }

  ngAfterViewInit(): void {
    this.agmMap.tilesLoaded.subscribe(() => {
      if (this.fromPdf) {
        this.agmHelper.updateMapLoadStatus(true);
      }
    });
  }

  private getCenteredTextForMarkerLabel(index: number): string {
    return (1 + index) + this.spaceCharacter;
  }

  onBubbleHover(index: number): void {
    this.indexOfHover = index;
  }

  bubbleExit(): void {
    this.indexOfHover = this.DEFAULT_INDEX_OF_HOVER;
  }

  mapClick(): void {
    this.selectedMarker = undefined;
    this.previous?.close();
  }
}
