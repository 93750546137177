import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import {PointClickEventObject, YAxisOptions} from 'highcharts/highstock';
import {
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_LANG_OBJECT,
  StyledChart,
  xAxisLabels
} from '../../../shared/highcharts-helpers';
import {CHART_HEIGHT} from '../../../productivity-summary/productivity-graph-component';
import {GraphColors} from '../../../productivity-summary/colors';
import {roundToNumber} from '../../../productivity-summary/number-formatter';
import {concat} from '../../../shared/ourLodash';
import {Variable} from '../../../variable-container/variable-container.component';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {
  addVarianceDatumIfValid,
  barChartSeriesForCollectionsCharts,
  chargesLegendForCollections,
  CollectionsMultiLevelSnapshot,
  defaultLegendsForCollectionsChart,
  expectedPaymentsLegend,
  getLeftSideYAxisForCollectionsCharts,
  getLeftSideYAxisForCollectionsVarianceCharts,
  getRightSideYAxisForCollectionsCharts,
  getVarianceForVarianceGraph,
  getVarianceSeriesOptions,
  legendsForCollectionsVarianceCharts,
  MatchedCollectionsByMultiLevelByNodePath,
  maxScrollBarsForCollectionsCharts,
  pointWidthForCollectionsCharts,
  validateMaxAxisValue,
  widthForCollectionsCharts
} from '../../Collection';
import {doesCollectionsDataHaveValidBenchmarkValue} from '../../Collection';
import {AppAction, collectionsSnapshotSortingCriteriaChangedTo, collectionsSnapshotVarianceToggleChangedTo} from '../../../store/actions';
import {deepCopyOf, LevelType} from '../../../shared/helpers';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {Options} from 'highcharts';
import {Chart} from 'angular-highcharts';
import {VarianceToggleActions, VarianceToggleField} from '../../../variance-toggler/variance-toggler.component';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {Legend, SortingCriterion} from '../../../shared/models';
import {ColumnType, HighChartColorIndex, SortingOrder} from '../../../shared/enums';


@Component({
  selector: 'app-collections-multilevel-chart',
  templateUrl: './collections-multilevel-chart.component.html',
  styleUrls: ['./collections-multilevel-chart.component.scss']
})
export class CollectionsMultilevelChartComponent implements OnChanges, OnInit {

  readonly defaultBenchmarkPercentile = BenchmarkPercentile.Percentile50th;
  readonly benchmarksToExclude = ['65th Percentile', '90th Percentile', 'Mean'];

  @Input() showProgressBar: boolean;
  @Input() variables: Variable[];
  @Input() showVariableMenu: boolean;
  @Input() page: string;
  @Input() data: MatchedCollectionsByMultiLevelByNodePath[];
  @Input() benchmarkPercentile: BenchmarkPercentile = this.defaultBenchmarkPercentile;
  @Input() activeVarianceToggle = false;
  @Input() sortingCriteria: SortingCriterion;
  @Input() tabLevel: LevelType;
  @Input() columnSelectionCallback: (collections: MatchedCollectionsByMultiLevelByNodePath) => void;

  @Output() toggleVarianceGraph = new EventEmitter<boolean>();

  hasBenchmarks = true;
  expectedPaymentsDisplayed: boolean;
  chargesDisplayed: boolean;
  subscription: Subscription;
  // @ts-ignore
  netCollectionRateSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  benchmarkDataSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  chargesSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  expectedPaymentsSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  varianceSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];

  @ViewChild('chartCollectionsTarget', {static: true})
  public chartTarget: ElementRef;

  @select(['display', 'collectionsChargeDisplayed'])
  private readonly chargesDisplayed$: Observable<boolean>;

  @select(['display', 'expectedPaymentsDisplayed'])
  private readonly expectedPaymentsDisplayed$: Observable<boolean>;

  @select(['data', 'collectionsData'])
  private readonly collectionsData$: Observable<CollectionsMultiLevelSnapshot>;

  @select(['data', 'matchedCollectionsByMultiLevelByNodePathData', 'providerCollections'])
  private readonly providerCollectionsMultiLevel$: Observable<CollectionsMultiLevelSnapshot>;

  @select(['data', 'matchedCollectionsByMultiLevelByNodePathData', 'specialtyCollections'])
  private readonly specialtyCollectionsMultiLevel$: Observable<CollectionsMultiLevelSnapshot>;

  @select(['data', 'matchedCollectionsByMultiLevelByNodePathData', 'departmentCollections'])
  private readonly departmentCollectionsMultiLevel$: Observable<CollectionsMultiLevelSnapshot>;

  public legends: Legend[];
  private readonly availableBenchmarks: BenchmarkPercentile[] = [
    BenchmarkPercentile.Percentile25th,
    BenchmarkPercentile.Percentile50th,
    BenchmarkPercentile.Percentile75th
  ];

  options: Options;
  chartObject: Chart;
  varianceToggle: VarianceToggleActions = {
    display: false,
    reducerField: VarianceToggleField.CollectionsSnapshotVarianceToggle,
    sortingCriterion: {
      sortingOrder: SortingOrder.DESCENDING,
      columnDef: 'variance50th',
      columnType: ColumnType.VARIANCE,
    },
    dispatchAction(display: boolean): AppAction {
      return collectionsSnapshotVarianceToggleChangedTo(display);
    },
    sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction {
      return collectionsSnapshotSortingCriteriaChangedTo(sortingCriteria);
    }
  };

  constructor(private readonly ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit(): void {
    combineLatest([
      this.chargesDisplayed$,
      this.expectedPaymentsDisplayed$,
    ])
      .subscribe(([chargesDisplayed, expectedPaymentsDisplayed]: [boolean, boolean]) => {
        this.chargesDisplayed = chargesDisplayed;
        this.expectedPaymentsDisplayed = expectedPaymentsDisplayed;
        this.drawGraphIndicatedByToggle();
      });
  }

  ngOnChanges(): void {
    if (this.sortingCriteria) {
      this.varianceToggle.sortingCriterion = this.sortingCriteria;
    }
    this.hasBenchmarks = doesCollectionsDataHaveValidBenchmarkValue(this.data);
    this.drawGraphIndicatedByToggle();
  }

  drawGraphIndicatedByToggle() {
    if (this.activeVarianceToggle) {
      this.drawVarianceGraph();
    } else {
      this.drawGraph();
    }
  }

  drawGraph() {
    if (!this.data) {
      return;
    }
    const data = this.data;
    this.defaultToFiftyIfNoBenchmark();
    // @ts-ignore
    const netCollectionRate = data.map(x => roundToNumber(x.netCollectionRate, 1) || 0);
    const expectedPayments: number[] = [];
    data.forEach((x: any) => {
      if (x.expectedPayments) {
        expectedPayments.push(x.expectedPayments);
      }
    });

    // @ts-ignore
    const charges = data.map(x => roundToNumber(x.chargeAmount, 1) || 0);
    this.setUpLegendsForRegularGraph();

    let benchmarkData: BenchmarkPercentile[] = [];
    let benchmarkName = '';
    switch (this.benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        // @ts-ignore
        benchmarkData = data.map(x => roundToNumber(x.benchmark25th, 1) || 0);
        benchmarkName = 'Benchmark 25th Percentile';
        break;
      case BenchmarkPercentile.Percentile50th:
        // @ts-ignore
        benchmarkData = data.map(x => roundToNumber(x.benchmark50th, 1) || 0);
        benchmarkName = 'Benchmark 50th Percentile';
        break;
      case BenchmarkPercentile.Percentile75th:
        // @ts-ignore
        benchmarkData = data.map(x => roundToNumber(x.benchmark75th, 1) || 0);
        benchmarkName = 'Benchmark 75th Percentile';
        break;
    }
    let maxLeftScaleVal = Math.max(...netCollectionRate, ...benchmarkData) + 1;
    let minLeftScaleVal = Math.min(...netCollectionRate, ...benchmarkData) + 1;

    if (netCollectionRate.length > 0) {
      maxLeftScaleVal = validateMaxAxisValue(maxLeftScaleVal);
    } else {
      maxLeftScaleVal = minLeftScaleVal = 0;
    }

    const rangeDetermineRight: number[] = this.chargesDisplayed ? charges : expectedPayments;

    let maxRightScaleVal, minRightScaleVal;
    if (expectedPayments.length > 0 || charges.length > 0) {
      maxRightScaleVal = Math.max(...rangeDetermineRight) + 1;
      minRightScaleVal = Math.min(...rangeDetermineRight) + 1;
      if (this.expectedPaymentsDisplayed || this.chargesDisplayed) {
        maxRightScaleVal = validateMaxAxisValue(maxRightScaleVal);
      }
    } else {
      maxRightScaleVal = minRightScaleVal = 0;
    }

    // @ts-ignore
    const categories = createEmptyLabels(
      // @ts-ignore
      this.getLabels(data),
      maxScrollBarsForCollectionsCharts);
    const isScrolled: boolean = categories.length > maxScrollBarsForCollectionsCharts;
    const leftSideYAxis: YAxisOptions[] = getLeftSideYAxisForCollectionsCharts(maxLeftScaleVal, minLeftScaleVal);
    const rightSideYAxis: YAxisOptions[] =
      getRightSideYAxisForCollectionsCharts(maxRightScaleVal, minRightScaleVal, this.chargesDisplayed, this.expectedPaymentsDisplayed);
    const seriesData: ([string, number] | number)[] =
      // @ts-ignore
      this.data.map((collection) =>
        [this.getTooltipName(collection), collection.netCollectionRate || 0]
      );

    const multiLevelEventClick = {
      click: (event: PointClickEventObject) => {
        this.columnSelectionCallback(this.data[event.point.x]);
      }
    };
    const isProvider = this.ngRedux.getState().display.isProviderSelected;

    this.netCollectionRateSeriesOptions = [
      {
        turboThreshold: 0,
        name: 'Net Collection Rate',
        yAxis: 0,
        data: seriesData.length < maxScrollBarsForCollectionsCharts ?
          createEmptyValues(seriesData, maxScrollBarsForCollectionsCharts) :
          seriesData,
        type: 'column',
        colorIndex: HighChartColorIndex.TEAL,
        pointWidth: pointWidthForCollectionsCharts,
        stack: 0,
        cursor: isProvider ? 'default' : 'pointer',
        events: multiLevelEventClick,
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f} %</b><br/>',
          valueDecimals: 0
        }
      },
    ];

    this.benchmarkDataSeriesOptions = [
      {
        turboThreshold: 0,
        name: benchmarkName,
        yAxis: 0,
        data: benchmarkData.length < maxScrollBarsForCollectionsCharts ?
          createEmptyValues(benchmarkData, maxScrollBarsForCollectionsCharts) :
          benchmarkData,
        type: ChartType.LINE,
        colorIndex: HighChartColorIndex.GREY,
        pointWidth: pointWidthForCollectionsCharts,
        stack: 1,
        cursor: isProvider ? 'default' : 'pointer',
        events: multiLevelEventClick,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25C6</span> {series.name}: <b>{point.y:,.0f} %</b><br/>',
          valueDecimals: 0
        }
      }
    ];

    this.expectedPaymentsSeriesOptions =
      this.expectedPaymentsDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Expected Payments',
          yAxis: 1,
          data: expectedPayments.length < maxScrollBarsForCollectionsCharts ?
            createEmptyValues(expectedPayments, maxScrollBarsForCollectionsCharts) :
            expectedPayments,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.PURPLE,
          cursor: isProvider ? 'default' : 'pointer',
          events: multiLevelEventClick,
          pointWidth: pointWidthForCollectionsCharts,
          stack: 2,
          zIndex: 1,
          tooltip: {
            pointFormat: '<span class="highcharts-color-13">\u25EF</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];

    this.chargesSeriesOptions =
      this.chargesDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Charges',
          yAxis: 1,
          data: charges.length < maxScrollBarsForCollectionsCharts ?
            createEmptyValues(charges, maxScrollBarsForCollectionsCharts) :
            charges,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.ORANGE,
          pointWidth: pointWidthForCollectionsCharts,
          stack: 3,
          cursor: isProvider ? 'default' : 'pointer',
          events: multiLevelEventClick,
          zIndex: 1,
          tooltip: {
            pointFormat: '<span class="highcharts-color-15">\u25A1</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];
    this.options = this.getOptionsForRegularGraph(categories, isScrolled, leftSideYAxis, rightSideYAxis);

    this.deleteXAxisCrosshairClick(isProvider);
    this.createNewChart();
  }

  public drawVarianceGraph() {
    if (!this.data) {
      return;
    }
    // @ts-ignore
    const data = this.data;
    this.defaultToFiftyIfNoBenchmark();
    const varianceData: any[] = [];
    // @ts-ignore
    this.data.forEach(collection => {
      const varianceValue = getVarianceForVarianceGraph(collection, this.benchmarkPercentile);
      addVarianceDatumIfValid(varianceValue, varianceData, this.tabLevel, collection);
    });
    this.legends = legendsForCollectionsVarianceCharts;

    const rangeDetermineLeft: number[] = [];
    varianceData.forEach(variance => {
      rangeDetermineLeft.push(variance.y);
    });
    let maxLeft = Math.max(...rangeDetermineLeft);
    let minLeft = Math.min(...rangeDetermineLeft);
    if (varianceData.length > 0) {
      maxLeft = validateMaxAxisValue(maxLeft);
    } else {
      maxLeft = minLeft = 0;
    }

    const multiLevelEventClick = {
      click: (event: PointClickEventObject) => {
        this.columnSelectionCallback(this.data[event.point.x]);
      }
    };
    const categories = createEmptyLabels(this.getLabels(data), maxScrollBarsForCollectionsCharts);
    const isScrolled: boolean = categories.length > maxScrollBarsForCollectionsCharts;
    const leftSideYAxis: any[] = getLeftSideYAxisForCollectionsVarianceCharts(maxLeft, minLeft);
    const isProvider = this.ngRedux.getState().display.isProviderSelected;
    this.varianceSeriesOptions = getVarianceSeriesOptions(varianceData, isProvider, multiLevelEventClick);
    this.options = this.getOptionsForVarianceGraph(categories, isScrolled, leftSideYAxis);

    this.deleteXAxisCrosshairClick(isProvider);
    this.createNewChart();
  }

  private setUpLegendsForRegularGraph() {
    this.legends = deepCopyOf(defaultLegendsForCollectionsChart);
    if (this.chargesDisplayed) {
      this.legends.push(chargesLegendForCollections);
    }
    if (this.expectedPaymentsDisplayed) {
      this.legends.push(expectedPaymentsLegend);
    }
  }

  private getOptionsForRegularGraph(categories: string[],
                                    isScrolled: boolean, leftSideYAxis: YAxisOptions[], rightSideYAxis: YAxisOptions[]) {
    return {
      lang: DEFAULT_LANG_OBJECT,
      title: {text: ''},
      series: concat(this.netCollectionRateSeriesOptions, this.benchmarkDataSeriesOptions,
        this.expectedPaymentsSeriesOptions, this.chargesSeriesOptions),
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        width: widthForCollectionsCharts,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100,
      },
      credits: {
        enabled: false,
      },
      xAxis: this.getXAxis(categories, isScrolled),
      yAxis: concat(leftSideYAxis, rightSideYAxis),
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false,
      },
      plotOptions: {
        column: {
          ...barChartSeriesForCollectionsCharts,
          tooltip: {
            pointFormat: ''
          }
        }
      },
    };
  }



  getOptionsForVarianceGraph(categories: string[], isScrolled: boolean, leftSideYAxis: any[]) {
    return {
      lang: DEFAULT_LANG_OBJECT,
      title: {text: ''},
      series: this.varianceSeriesOptions,
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        width: widthForCollectionsCharts,
        height: CHART_HEIGHT,
        styledMode: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: this.getXAxis(categories, isScrolled),
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false,
      },
      plotOptions: {
        column: {
          ...barChartSeriesForCollectionsCharts,
        }
      },
    };
  }

  private createNewChart() {
    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  private getXAxis(categories: string[], isScrolled: boolean) {
    return {
      categories: categories,
      crosshair: {
        color: GraphColors.hoverBackground,
      },
      // @ts-ignore
      clickOnCrosshair: (e: any, p: any) => {
        // @ts-ignore
        this.columnSelectionCallback(this.data[p.index]);

      },
      max: isScrolled ? maxScrollBarsForCollectionsCharts : categories.length - 1,
      labels: xAxisLabels,
    };
  }

  deleteXAxisCrosshairClick(isProvider: boolean) {
    if (isProvider && this.options.xAxis) {
      // @ts-ignore
      delete this.options.xAxis.clickOnCrosshair;
    }
  }

  private defaultToFiftyIfNoBenchmark() {
    if (!this.availableBenchmarks.includes(this.benchmarkPercentile)) {
      this.benchmarkPercentile = BenchmarkPercentile.Percentile50th;
    }
  }

  getLabels(data: MatchedCollectionsByMultiLevelByNodePath[]): string [] {
    switch (this.tabLevel) {
      case LevelType.provider:
        // @ts-ignore
        return data.map((datum) => datum.providerNodeName);
      case LevelType.specialty:
        // @ts-ignore
        return data.map((datum) => datum.specialtyNodeName);
      case LevelType.department:
        // @ts-ignore
        return data.map((datum) => datum.departmentNodeName);
      default:
        // @ts-ignore
        return data.map((datum) => datum.departmentNodeName);
    }
  }

  getTooltipName(collections: any) {
    return (collections.providerNodeName ? collections.providerNodeName + '<br/>' : '')
      + (collections.specialtyNodeName ? collections.specialtyNodeName + '<br/>' : '')
      + (collections.departmentNodeName || collections.nodeName);
  }
}
