import {Component, Inject, OnInit} from '@angular/core';
import {ApplicationConfigurationSetting, MemberData} from '../shared/models';
import {getAppConfigValue} from '../shared/helpers';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {Observable, Subscription} from 'rxjs';
import {select} from '@angular-redux/store';
import {
  ApplicationConfigurationService, ApplicationConfigurationServiceToken
} from '../services/app-configuration.service';
import {OktaAuth} from '@okta/okta-auth-js';

@Component({
  selector: 'app-noaccess-page',
  templateUrl: './no-access-page.component.html',
  styleUrls: ['./no-access-page.component.scss']
})
export class NoAccessPageComponent implements OnInit {
  appConfigSettings: ApplicationConfigurationSetting[];
  private applicationConfigurationSubscription: Subscription;

  @select(['data', 'selectedMemberData'])
  private readonly memberData$: Observable<MemberData>;

  private memberDataSubscription: Subscription;

  constructor(
    private readonly authService: OktaAuth,
    @Inject(ApplicationConfigurationServiceToken) private readonly applicationConfigurationService: ApplicationConfigurationService
  ) {
  }

  ngOnInit() {
  }

  email() {
    window.open('mailto:CPSCSupport@vizientinc.com', '_self');
  }

  logout() {
    this.memberDataSubscription?.unsubscribe();
    this.memberDataSubscription = this.memberData$.subscribe(memberData => {
      this.applicationConfigurationSubscription?.unsubscribe();
      this.applicationConfigurationSubscription =
        this.applicationConfigurationService.getApplicationConfigurationSettings()
          .subscribe((settings: ApplicationConfigurationSetting[]) => {
            if (settings) {
              this.appConfigSettings = settings;
            }
          });

      this.authService.signOut({
        postLogoutRedirectUri: getAppConfigValue(AppConfigEntries.LOGOUT_WEBSITE, this.appConfigSettings)
      }).then(() => {
        window.open(getAppConfigValue(AppConfigEntries.LOGOUT_WEBSITE, this.appConfigSettings), '_self');
      });
    });
  }

}
