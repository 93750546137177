<span id="header">Your export has been scheduled!</span>
<span id="report-info-wrapper">
  <span id="report-name" [ngClass]="reportInfoItemClass">{{schedulePaneInfo.reportName}}</span>
  <span *ngIf="schedulePaneInfo.isMonthly" id="monthly-indicator">Generated monthly</span>
</span>
<span id="report-message">
  <span>{{topMessage}}
    <span *ngIf="schedulePaneInfo.isMonthly" id="monthly-addition">{{monthlyAddition}}</span>
  </span>
  <span>{{bottomMessage}}</span>
</span>
