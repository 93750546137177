import {Component, Input, OnInit} from '@angular/core';
import {getAppConfigValue, getLevel} from '../../shared/helpers';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState, INITIAL_STATE, SummaryData} from '../../store/IAppState';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import {combineLatest, Observable} from 'rxjs';
import {CollectionsSummaryForAllTypes} from '../Collection';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {AppConfigEntries} from '../../shared/app-config-settings-enum';
import {DateRange} from '../../shared/models';

HC_more(Highcharts);

@Component({
  selector: 'app-summary-collections',
  templateUrl: './summary-collections.component.html',
  styleUrls: ['./summary-collections.component.scss']
})
export class SummaryCollectionsComponent implements OnInit {

  @Input() isMultilevel = true;
  nodePath: string;
  multipleProviders: boolean;
  multipleSpecialties: boolean;
  multipleDepartments: boolean;
  isProvider: boolean;
  level: string;
  loadingIndicator = true;
  summaryData: SummaryData<CollectionsSummaryForAllTypes>;
  dateRange: DateRange;

  moreThanMaxSpecialtiesSelected = false;
  moreThanMaxProvidersSelected = false;
  maxProviders = 500;
  maxSpecialties = 500;
  maxProvidersMessage = `Please select fewer than ${this.maxProviders} "Selected Providers" in the navigation drop down`;
  maxSpecialtiesMessage = `Please select fewer than ${this.maxSpecialties} specialties in the navigation drop down`;

  @select(['data', 'summaryCollectionsData'])
  private readonly summaryCollectionsData$: Observable<SummaryData<CollectionsSummaryForAllTypes>>;

  @select(['filters', 'dateRange'])
  private readonly dateRange$: Observable<DateRange>;


  constructor(private ngRedux: NgRedux<IAppState>) {

  }

  ngOnInit() {
    combineLatest([this.summaryCollectionsData$, this.dateRange$])
      .pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
      .subscribe(([summaryData, dateRange]: [SummaryData<CollectionsSummaryForAllTypes>,
        DateRange]) => {
        this.summaryData = summaryData;
        this.dateRange = dateRange;
        this.loadingIndicator = _.isEqual(summaryData, INITIAL_STATE.data.summaryCollectionsData);
        this.nodePath = this.ngRedux.getState().filters.nodePath;
        this.multipleProviders = this.ngRedux.getState().display.multipleProviders;
        this.multipleSpecialties = this.ngRedux.getState().display.multipleSpecialties;
        this.multipleDepartments = this.ngRedux.getState().display.multipleDepartments;
        const appConfigSettings = this.ngRedux.getState().data.applicationConfigurationSettings;
        const maxProviders = getAppConfigValue(AppConfigEntries.MAX_PROVIDER_PATHS, appConfigSettings);
        this.maxProviders = maxProviders && maxProviders.length > 0 ? Number(maxProviders) : this.maxProviders;
        const maxSpecialties = getAppConfigValue(AppConfigEntries.MAX_SPECIALTY_PATHS, appConfigSettings);
        this.maxSpecialties = maxSpecialties && maxSpecialties.length > 0 ? Number(maxSpecialties) : this.maxSpecialties;
        this.maxSpecialtiesMessage
          = `Please select fewer than ${this.maxSpecialties} specialties in the navigation drop down`;
        const multiLevelData = this.ngRedux.getState().data.matchedCollectionsByMultiLevelByNodePathData;
        this.moreThanMaxSpecialtiesSelected = multiLevelData.specialtyCollections &&
          multiLevelData.specialtyCollections.length > this.maxSpecialties;
        this.moreThanMaxProvidersSelected = multiLevelData.providerCollections &&
          multiLevelData.providerCollections.length > this.maxSpecialties;
        this.level = getLevel(this.ngRedux.getState().display.isCustom,
          this.nodePath, this.multipleProviders, this.multipleSpecialties, this.multipleDepartments);
        this.isProvider = this.ngRedux.getState().display.isProviderSelected;
      });
  }

}
