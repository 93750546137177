import {Component, Input, OnChanges} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {roundCollectionsToWithCommasSeparation} from '../../../productivity-summary/number-formatter';
import {SummaryData} from '../../../store/IAppState';
import {
  CollectionsSummaryForAllTypes,
  getPreviousYtdDateRangeForCollectionSummary,
  getYtdDateRangeForCollectionSummary
} from '../../Collection';
import {DateRange} from '../../../shared/models';
import {
  getPreviousYearDateRange,
  getSelectedDateRange
} from '../../../shared/helpers';
import {columnsForSummaryCollections, tableCellsForCollectionsSummary} from '../../../shared/data-table-columns';

@Component({
  selector: 'app-summary-collections-table',
  templateUrl: './summary-collections-table.component.html',
  styleUrls: ['./summary-collections-table.component.scss']
})
export class SummaryCollectionsTableComponent implements OnChanges {

  data: CollectionSummaryData[] = [];
  dataSource: MatTableDataSource<any>;
  selectedDateRange: string;
  previousDateRange: string;
  ytdDateRange: string;
  previousYtdDateRange: string;
  columnsForSummaryCollections = columnsForSummaryCollections;
  displayedColumns = columnsForSummaryCollections.map(col => col.columnDef);

  @Input() summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>;
  @Input() dateRange: DateRange;
  @Input() showProgressBar: boolean;

  constructor() {
  }

  ngOnChanges() {
    this.selectedDateRange = getSelectedDateRange(this.dateRange);
    this.previousDateRange = getPreviousYearDateRange(this.dateRange);
    this.ytdDateRange = getYtdDateRangeForCollectionSummary(this.summaryCollectionsData.yearToDate);
    this.previousYtdDateRange = getPreviousYtdDateRangeForCollectionSummary(
      this.summaryCollectionsData.previousYearToDate);
    if (this.summaryCollectionsData) {
      this.data = populateCollectionSummaryData(this.summaryCollectionsData);
    }
    this.dataSource = new MatTableDataSource<any>(this.data.slice());
  }
}

export function populateCollectionSummaryData(summaryCollectionsData: SummaryData<CollectionsSummaryForAllTypes>): CollectionSummaryData[] {
  const ret: CollectionSummaryData[] = [];
  tableCellsForCollectionsSummary.forEach(cell => {
    ret.push(getCollectionSummaryRow(cell.label, cell.field, summaryCollectionsData));
  });
  return ret;
}

function getCollectionSummaryRow(title: string, field: string, summaryCollectionsData:
  SummaryData<CollectionsSummaryForAllTypes>): CollectionSummaryData {
  return {
    metric: title,
    metricSelectedDateRange: isCollectionValid(summaryCollectionsData.selectedDateRange, field) ?
      ('$' + roundCollectionsToWithCommasSeparation(summaryCollectionsData.selectedDateRange[field])) : '-',
    metricPreviousYearSelectedDateRange:
      isCollectionValid(summaryCollectionsData.previousYearSelectedDateRange, field) ?
        ('$' + roundCollectionsToWithCommasSeparation(summaryCollectionsData.previousYearSelectedDateRange[field])) : '-',
    metricYearToDate: isCollectionValid(summaryCollectionsData.yearToDate, field) ?
      ('$' + roundCollectionsToWithCommasSeparation(summaryCollectionsData.yearToDate[field])) : '-',
    metricPreviousYearToDate: isCollectionValid(summaryCollectionsData.previousYearToDate, field) ?
      ('$' + roundCollectionsToWithCommasSeparation(summaryCollectionsData.previousYearToDate[field])) : '-',
  };
}

function isCollectionValid(value: any, field: string): boolean {
  if (value !== undefined && value !== null &&
    value[field] !== undefined && value[field] !== null) {
    return true;
  }
  return false;
}

export interface CollectionSummaryData {
  metric: string;
  metricSelectedDateRange: string;
  metricPreviousYearSelectedDateRange: string;
  metricYearToDate: string;
  metricPreviousYearToDate: string;
}
