import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RelatableSelectableItem, UpdatedFilterTab} from '../FilterBannerModels';
import {LagPeriod} from '../../shared/models';
import {DEFAULT_LAG_NAME} from '../../store/DefaultValues';
import {determineAllSelected} from '../UpdatedFilterBannerHelpers';

@Component({
  selector: 'app-search-lag-key',
  templateUrl: './search-lag-key.component.html',
  styleUrls: ['./search-lag-key.component.scss']
})
export class SearchLagKeyComponent implements OnInit {

  @Input() lagPeriods: RelatableSelectableItem<LagPeriod>[];
  @Input() onPeriodChanged: (tab?: UpdatedFilterTab, item?: RelatableSelectableItem<LagPeriod>) => void;
  @Input() filterTab: UpdatedFilterTab;
  allSelected = true;
  readonly DEFAULT_LAG_NAME = DEFAULT_LAG_NAME;

  constructor(protected cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.allSelected = determineAllSelected(this.lagPeriods);
  }

  selectAllActivity(event: MouseEvent): void {
    event.preventDefault();
    if (!this.allSelected) {
      let wasSelected: boolean;
      this.lagPeriods.forEach(lag => {
        wasSelected = lag.item.selected;
        lag.item.selected = false;
        if (this.filterTab.newSelections && wasSelected) {
          if (lag.item.originallySelected) {
            this.filterTab.newSelections.deselections++;
          } else {
            this.filterTab.newSelections.selections--;
          }
        }
      });
      this.allSelected = true;
      this.onPeriodChanged(this.filterTab);
    }
  }

  whenLagPeriodClicked(lag: RelatableSelectableItem<LagPeriod>, event: MouseEvent): void {
    event.preventDefault();
    if (lag.item.selected) {
      return;
    }
    this.allSelected = false;
    let shouldBeSelected: boolean;
    let wasSelected: boolean;
    this.lagPeriods.forEach(l => {
      shouldBeSelected = l.item.key === lag.item.key;
      wasSelected = l.item.selected;
      l.item.selected = shouldBeSelected;
      if (shouldBeSelected && this.filterTab.newSelections) {
        if (!l.item.originallySelected) {
          this.filterTab.newSelections.selections++;
        } else {
          this.filterTab.newSelections.deselections--;
        }
      } else if (!shouldBeSelected && this.filterTab.newSelections && wasSelected) {
        if (l.item.originallySelected) {
          this.filterTab.newSelections.deselections++;
        } else {
          this.filterTab.newSelections.selections--;
        }
      }
    });
    this.onPeriodChanged(this.filterTab, lag);
    this.cdr.detectChanges();
  }
}
