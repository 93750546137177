import {Injectable, InjectionToken} from '@angular/core';
import {environment} from '../../../environments/environment.local';

export const ComplianceServiceToken =
  new InjectionToken<ComplianceService>('Compliance Service');

export interface ComplianceService {
  handleEvent(event: MessageEvent): any ;
  getNotificationsAccepted(): boolean;
}

@Injectable()
export class HttpComplianceService implements ComplianceService {

  constructor() {
      this.handleEvent = this.handleEvent.bind(this);
  }

  private notificationsAccepted: boolean;

  handleEvent(event: MessageEvent) {
    // messages sometimes come from other sources, make sure this is only handling messages from the compliance
    if (event.origin !== environment.complianceUrl) {
      return null;
    }
    this.notificationsAccepted = event.data;
  }

  getNotificationsAccepted(): boolean {
    return this.notificationsAccepted;
  }
}
