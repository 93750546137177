import {ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {SortingCriterion} from '../../../shared/models';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import {AppAction, denialsChosenTabChangedTo, multiLevelDenialsChangedTo} from '../../../store/actions';
import {NgRedux} from '@angular-redux/store';
import * as _ from 'lodash';
import {getLevelTypeNodePath, Page} from '../../../shared/helpers';
import {Variable} from '../../../variable-container/variable-container.component';
import {MultilevelTab, MultilevelTabCollections} from '../../../shared/enums';
import {DrillDownService} from '../../../services/drilldown.service';
import {DenialsMultiLevelEntity} from '../../denials-models';

@Component({
  selector: 'app-denials-department-page',
  templateUrl: './denials-department-page.component.html',
  styleUrls: ['./denials-department-page.component.scss']
})
export class DenialsDepartmentPageComponent implements OnChanges {
  @Input() fromPdfExport = false;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() denialsMultiLevel: DenialsMultiLevelEntity[];
  @Input() nodePath: string;
  @Input() isCustom: boolean;
  @Input() variables: Variable[];
  @Input() showVariableMenu: boolean;
  @Input() canHideCPTsDeniedModal = false;
  @Input() activeVarianceToggle = false;
  @Input() columns: DataTableColumns[];
  @Input() originalColumns: DataTableColumns[];
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion | undefined) => void;
  @Input() displayedColumns: DataTableColumns[] = [];
  @Input() reducerAction: (multiLevelByNodePathDenialsColumns: BaseColumn[]) => AppAction;

  level: string;
  defaultSortColumn = 'denialRate';
  subscription: any;
  isDepartment = false;
  isSpecialty = false;
  isProvider: boolean;
  sortingDataAccessor: any;
  currentPage = Page.DenialsDepartment;
  showProgressBar: boolean;
  varianceKey = '';
  sortDirection: string;
  nodePathName = '';

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private _changeDetectorRef: ChangeDetectorRef,
    private drillDownService: DrillDownService
  ) {
  }

  ngOnChanges() {
    this.showProgressBar =
      _.isEqual(this.denialsMultiLevel, INITIAL_STATE.data.denialsByMultiLevelData.departmentDenials);
    this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      return (sortHeaderId === 'denialRate') ? data[this.defaultSortColumn] : data[sortHeaderId];
    };

    this.isSpecialty = getLevelTypeNodePath(this.nodePath) === 2 && !this.isCustom;
    this.isDepartment = getLevelTypeNodePath(this.nodePath) === 1 && !this.isCustom;

    this._changeDetectorRef.detectChanges();
  }

  levelSpecificHandleSortChange = (denialsEntries: DenialsMultiLevelEntity[]) => {

    this.ngRedux.dispatch(multiLevelDenialsChangedTo({
      ...this.ngRedux.getState().data.denialsByMultiLevelData,
      departmentDenials: denialsEntries
    }));
  };

  whenDepartmentSelected = (selectedNode: DenialsMultiLevelEntity) => {
    if (!selectedNode) {
      return;
    }

    this.drillDownService.drillDownIntoNode(selectedNode.departmentNodePath, this.nodePath, MultilevelTab.BY_DEPARTMENT);
    this.ngRedux.dispatch(denialsChosenTabChangedTo(MultilevelTabCollections.BY_SPECIALTY));
  };
}
