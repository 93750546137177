import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {IAppState, UserPreference} from '../store/IAppState';
import {
  dateRangeChangedTo,
  isProviderSelectedChangedTo,
  nodePathChangedTo,
  recentMonthLoadedTo,
  userPreferencesLoadedTo
} from '../store/actions';
import {
  dateInMonths,
  getDateRangeBySelectedOption,
  getLevelTypeNodePath,
  getURL,
  LevelType
} from '../shared/helpers';
import {NgRedux} from '@angular-redux/store';
import {Router} from '@angular/router';
import {DateRange, MemberData, UserMemberData} from '../shared/models';
import {DEFAULT_USER_PREFERENCES} from '../store/DefaultValues';
import {DateRangeOption} from '../shared/enums';

export const UserPreferenceServiceToken = new InjectionToken<UserPreferenceService>('UserPreferenceService');

export interface UserPreferenceService {

  getAllUserPreferences(): Observable<UserPreference[]>;

  saveUserPreferences(userPreferences: UserPreference): Observable<UserPreference>;

  getRecentMonth(memberKey: number): Observable<number | undefined>;

  applyUserPreferences(member: MemberData, userPreferences: UserPreference, recentMonth: number, nodePath: string,
                       useCustom: boolean): void;

  getAndApplyUserPreferences(member: MemberData, userPreference: UserPreference | undefined, useCustom: boolean): void;

  getUserMemberData(memberIds: string): Observable<UserMemberData[]>;
}

@Injectable()
export class HttpUserPreferenceService implements UserPreferenceService {

  constructor(
    private readonly httpClient: HttpClient,
    private router: Router,
    private ngRedux: NgRedux<IAppState>) {
  }

  public getAllUserPreferences(): Observable<UserPreference[]> {
    return this.httpClient.get<UserPreference[]>(`${environment.api}/api/allUserPreferences`);
  }

  public saveUserPreferences(userPreferences: UserPreference): Observable<UserPreference> {
    return this.httpClient.post<UserPreference>(`${environment.api}/api/saveUserPreference`, userPreferences);
  }

  public getRecentMonth(memberKey: number): Observable<number | undefined> {
    return this.httpClient.get<number>(`${environment.api}/api/recentMonth?memberKey=${memberKey}`);
  }

  public applyUserPreferences(member: MemberData, userPreferences: UserPreference, recentMonth: number,
                              nodePath: string, useCustom: boolean): boolean {
    this.ngRedux.dispatch(userPreferencesLoadedTo(userPreferences));

    const defaultDateOption: DateRangeOption = userPreferences.dateRangeOption || DateRangeOption.Past12MonthsOfData;
    const userPreferenceDate: DateRange = getDateRangeBySelectedOption(defaultDateOption, recentMonth || -1,
      userPreferences.fiscalStartMonth);
    if (! useCustom) { this.ngRedux.dispatch(dateRangeChangedTo(userPreferenceDate)); }
    this.ngRedux.dispatch(recentMonthLoadedTo(recentMonth || -1));

    let isProviderSelected = false;

    if (nodePath) {
      isProviderSelected = getLevelTypeNodePath(nodePath) === LevelType.singleProvider;
      this.ngRedux.dispatch(isProviderSelectedChangedTo(isProviderSelected));
      this.ngRedux.dispatch(nodePathChangedTo(nodePath));
    }
    return  isProviderSelected;
  }

  public getAndApplyUserPreferences(member: MemberData, userPreference: UserPreference, useCustom: boolean): void {
    const userMemberData: any = this.getUserMemberDataFromStore(member.memberKey);
    const nodePath: string =
      (useCustom && this.ngRedux.getState().data.customGroupsData.find(group => group.isDefault)?.nodePath)
      || userPreference?.nodePath
      || userMemberData.nodePath;

    if (userPreference) {
     const isProviderSelected = this.applyUserPreferences(member, userPreference,
       userMemberData.recentMonth, nodePath, useCustom);
      const defaultURL = getURL(userPreference.defaultPage, isProviderSelected);
      this.router.navigateByUrl(defaultURL);
    } else {
      const dateRange = getDateRangeBySelectedOption(DateRangeOption.Past12MonthsOfData,
        userMemberData.recentMonth || -1);
      userPreference = {
        ...DEFAULT_USER_PREFERENCES,
        memberKey: member.memberKey,
        emailId: this.ngRedux.getState().data.userProfile.email,
        dateRangeOption: dateRange.selectedDateRangeOption,
        startDateInMonths: dateInMonths(dateRange.startYear, dateRange.startMonth),
        endDateInMonths: dateInMonths(dateRange.endYear, dateRange.endMonth),
        nodePath: userMemberData.nodePath
      };
      userMemberData.recentMonth = userMemberData.recentMonth ?? -1;
      this.applyUserPreferences(member, userPreference, userMemberData.recentMonth, nodePath, useCustom);
    }
  }

  public getUserMemberData(memberIds: string): Observable<UserMemberData[]> {
    return this.httpClient.post<UserMemberData[]>(`${environment.api}/api/getUserMemberData`, memberIds);
  }

  public getUserMemberDataFromStore(memberKey: number): UserMemberData | undefined {
    let userMemberData: UserMemberData | undefined;
    if (this.ngRedux.getState().data.userMemberData && this.ngRedux.getState().data.userMemberData.length > 0) {
      userMemberData = this.ngRedux.getState().data.userMemberData.find(x => x.memberKey === memberKey);
    }
    return userMemberData;
  }
}
