/* tslint:disable:max-line-length */
import {MemberBillingArea, MemberLocation, PayerCategory, PayerObject} from '../shared/models';
import {PayerFilterCriteria} from '../shared/models';
import {PayerType} from '../shared/enums';
import {DateRangeOption} from '../shared/enums';
import {DefaultPage} from '../shared/enums';
import {RelatableSelectableItem} from '../filter-banner/search-payer/PayerSetupHelper';
import {UserPreference} from './IAppState';
import {invoiceStatusTypes} from '../shared/constants';
import {visitTypes} from '../shared/constants';
import {BenchmarkPercentile} from '../shared/benchmark-types';

export const DEFAULT_MEMBER_KEY = -1;
export const DEFAULT_YEAR = 2016;
export const DEFAULT_MEMBER_LOCATION: MemberLocation = {
  memberLocationCode: '0',
  memberLocationName: 'All Locations',
  memberLocationKey: 0,
};
export const DEFAULT_MEMBER_LOCATION_KEYS = '0';
export const DEFAULT_PAYER_CATEGORY: PayerCategory = {
  payerCategoryCode: '',
  payerCategoryDescription: 'All Payers',
  payerCategoryKey: 0,
};

export const DEFAULT_PAYER_FILTER_CRITERIA: PayerFilterCriteria = {
  payerCategoryKey: 0,
  payerCategoryDesc: 'All Payers'
};

export const DEFAULT_PAYER_SELECTION:  RelatableSelectableItem<PayerObject> = {
  item: {
    selected: false,
    belongs: true,
    matchesSearchText: true,
    item: {
      level: PayerType.CATEGORY,
      key: 0,
      description: DEFAULT_PAYER_CATEGORY.payerCategoryDescription,
      payerCriteria: DEFAULT_PAYER_FILTER_CRITERIA
    },
    expansion: undefined,
    displayText: DEFAULT_PAYER_CATEGORY.payerCategoryDescription
  },
  relatives: []
};

export const DEFAULT_MEMBER_BILLING_AREA: MemberBillingArea = {
  memberBillingAreaKey: 0,
  memberBillingAreaDescription: 'All Billing Areas',
  memberBillingAreaCode: 'All Billing Areas'
};

export const DEFAULT_PAYER_TYPE: PayerType = PayerType.ALL;

export const DEFAULT_USER_PREFERENCES: UserPreference = {
  dateRangeOption: DateRangeOption.Past12MonthsOfData,
  emailId: 'none',
  isDefault: false,
  defaultPage: DefaultPage.Overview,
  fiscalStartMonth: 1,
};

export const DEFAULT_LAG_KEY = 7;

export const DEFAULT_LAG_NAME = 'All Activity';

export const DEFAULT_INVOICE_STATUS = 0;

export const DEFAULT_INVOICE_TEXT = invoiceStatusTypes[0].statusText;

export const DEFAULT_CUSTOM_GROUP_ID = 0;

export const DEFAULT_TELEHEALTH_FLAG = visitTypes[0];

export const DEFAULT_MAX_NODE_PATHS = 120;

export const DEFAULT_MAX_LOCATIONS = 5;

export const AT_URL = 'https://amc.vizientinc.com/redirect/PRO-AMB-ATReports/default';

export const DEFAULT_BREADCRUMB = 'All Departments / All Specialties / All Providers';

export const DEFAULT_PAGE_LABEL = 'Overview';

export const DEFAULT_BENCHMARK_TYPE = BenchmarkPercentile.Mean;
