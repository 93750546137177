<app-tab-navigation
  [tabs]="tabs"
  [chosenTab]="chosenTab"
  [isRevenueTab]="true"
  [tabUpdateAction]="denialsChosenTabChangedTo">
</app-tab-navigation>

<div [ngSwitch]="chosenTab">
  <div *ngSwitchCase="1">
  <app-denials-department-page
    [nodePath]="nodePath"
    [columns]="displayedDepartmentColumns"
    [originalColumns]="departmentColumns"
    [denialsMultiLevel]="departmentDenialsData"
    [benchmarkPercentile]="benchmarkPercentile"
    [variables]="variables"
    [activeVarianceToggle]="denialsVarianceToggle"
    [reducerAction]="reducerAction"
    ></app-denials-department-page>
  </div>
  <div *ngSwitchCase="2">
  <app-denials-specialty-page
    [nodePath]="nodePath"
    [columns]="displayedSpecialtyColumns"
    [originalColumns]="specialtyColumns"
    [denialsMultiLevel]="specialtyDenialsData"
    [benchmarkPercentile]="benchmarkPercentile"
    [variables]="variables"
    [activeVarianceToggle]="denialsVarianceToggle"
    [reducerAction]="reducerAction"
  ></app-denials-specialty-page>
  </div>
  <div *ngSwitchCase="3">
  <app-denials-provider-page
    [nodePath]="nodePath"
    [columns]="displayedProviderColumns"
    [originalColumns]="providerColumns"
    [denialsMultiLevel]="providerDenialsData"
    [benchmarkPercentile]="benchmarkPercentile"
    [variables]="variables"
    [activeVarianceToggle]="denialsVarianceToggle"
    [reducerAction]="reducerAction"
   ></app-denials-provider-page>
  </div>
  <div *ngSwitchCase="4">
    <app-denials *ngSwitchCase="4"></app-denials>
</div>
</div>
