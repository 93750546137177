import {Legend, MultilevelNode} from '../../../shared/models';
import {CfpByMultilevel, ClinicalSummary} from '../../ClinicalSummary';
import {ProcedureSummaryVariables} from '../../../store/IAppState';
import {CptViewType, LegendColor, LegendStyle, MultilevelTab} from '../../../shared/enums';
import {CfpVariableViewType} from '../../../shared/helpers';
import {MetricType} from '../../../shared/metric-types';

export interface CfpGroupedByNodeInfo extends MultilevelNode, ClinicalSummary { }

export interface CfpViewByOption {
  identity: string;
  display: string;
  hidden: boolean;
  level: MultilevelTab | CptViewType;

  [key: string]: string | boolean | MultilevelTab | CptViewType;
}

export const aDefaultCfpViewBy: CfpViewByOption = {
  identity: '',
  display: '',
  hidden: false, level: MultilevelTab.BY_DEPARTMENT
};

export const aDefaultProcedureSummaryVariables: ProcedureSummaryVariables = {
  charges: false, viewByNode: false, currentSelectedCptNode: aDefaultCfpViewBy, currentSelectedOntologyCptNode: aDefaultCfpViewBy
};

// TODO: helper array
export function getCptViewText(viewType: CptViewType): string {
  switch (viewType) {
    case CptViewType.CptFamily:
      return 'CPT Family';
    case CptViewType.CptRange:
      return 'CPT Range';
    case CptViewType.CptCode:
      return 'CPT Code';
  }
}

export function getCptLevelKeyword(viewType: CptViewType): string {
  switch (viewType) {
    case CptViewType.CptFamily:
      return 'Family';
    case CptViewType.CptRange:
      return 'Range';
    case CptViewType.CptCode:
      return 'Code';
    default:
      return '';
  }
}

export function getVariableDataFromCfpGroupedByNodeInfo(cfpGroupedByNodeInfo: CfpGroupedByNodeInfo,
                                                        type: CfpVariableViewType): number {
  switch (type) {
    case CfpVariableViewType.CfteAdjustedwRVU :
      return cfpGroupedByNodeInfo.cfteAdjustedWRVUs;
    case CfpVariableViewType.ActualwRVU:
      return cfpGroupedByNodeInfo.wRVUs;
    case CfpVariableViewType.CfteAdjustedCount:
      return cfpGroupedByNodeInfo.cfteAdjustedFrequency;
    case CfpVariableViewType.ActualCount:
      return cfpGroupedByNodeInfo.frequency;
    default:
      return 0;
  }
}

export function getNodeNameFromCfpGroupedByNodeInfo(cfpGroupedByNodeInfo: CfpGroupedByNodeInfo,
                                                    tab: MultilevelTab): string {
  switch (tab) {
    case MultilevelTab.BY_DEPARTMENT:
      return cfpGroupedByNodeInfo.departmentNodeName;
    case MultilevelTab.BY_SPECIALTY:
      return cfpGroupedByNodeInfo.specialtyNodeName;
    case MultilevelTab.BY_PROVIDER:
      return cfpGroupedByNodeInfo.providerNodeName;
    default:
      return '';
  }
}

export function getCptDisplayTextFromCfpByMultilevel(cfpByMultilevel: CfpByMultilevel,
                                                     viewType: CptViewType): string {
  switch (viewType) {
    case CptViewType.CptFamily:
      return cfpByMultilevel.cptFamilyDesc;
    case CptViewType.CptRange:
      return getCptRangeText(cfpByMultilevel);
    case CptViewType.CptCode:
      return cfpByMultilevel.cptCode;
  }
}

export function getCptRangeText(datum: CfpByMultilevel) {
  return `${datum.cptRangeDesc} ${datum.cptRangeLow} - ${datum.cptRangeHigh}`;
}

export const cftAdjCountLegend: Legend = {
  text: 'cFTE adj. wRVUs',
  color: LegendColor.TEAL,
  metric: MetricType.ProcedureSummary,
  style: LegendStyle.SQUARE
};

export const actualWrvuLegend: Legend = {
  text: 'Actual wRVUs',
  color: LegendColor.TEAL,
  metric: MetricType.ProcedureSummary,
  style: LegendStyle.SQUARE
};

export const cfteWrvuLegend: Legend = {
  text: 'cFTE adj. Count',
  color: LegendColor.TEAL,
  metric: MetricType.ProcedureSummary,
  style: LegendStyle.SQUARE
};

export const countLegend: Legend = {
  text: 'Count',
  color: LegendColor.TEAL,
  metric: MetricType.ProcedureSummary,
  style: LegendStyle.SQUARE
};

export const benchmarkLegend: Legend = {
  text: 'wRVU Benchmark Mean',
  color: LegendColor.GREY,
  style: LegendStyle.SQUARE,
  metric: MetricType.ProcedureSummary,
  showBenchmarkOptionControl: true
};

export const chargesLegend: Legend = {
  text: 'Charges',
  color: LegendColor.ORANGE,
  metric: MetricType.ProcedureSummary,
  style: LegendStyle.CIRCLE
};
