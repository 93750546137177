import {Injectable, InjectionToken} from '@angular/core';

export const AnalyticsServiceToken = new InjectionToken<AnalyticsService>('Google Analytics Service');

@Injectable({providedIn: 'root'})
export class AnalyticsService {

  dataLayer: any[] = [];

  constructor() {
    this.dataLayer = (window as any)['dataLayer'];
  }

  public set(keyValue: Record<string, string | number | boolean>) {
    this.dataLayer?.push(keyValue);
  }

  public event(eventName: string, data: Record<string, string | number | boolean>): void {
    const event = {
      ...data,
      'event': eventName
    };
    this.dataLayer?.push(event);
  }

  handleGoogleAnalytics(category: string, eventLabel: string, eventAction: string): void {
    this.event(eventAction,
      {
        eventCategory: category,
        eventAction: eventAction,
        eventLabel: eventLabel
      }
    );
  }
}
