
<div class="outpatient-summary-table" *ngIf="displayedData">
<div class="table-header-wrapper">
  <div class="summaryHeader bold">Outpatient E&M Summary</div>
</div>
  <div class = "header-name">
        <span class = "outpatient-header1">
          Office/Outpatient Visit New 99201 - 99205
        </span>
    <span class = "outpatient-header2">
          Office/Outpatient Visit Established 99211 - 99215
        </span >
    <span class = "outpatient-header3">
          Office Consultation 99241 - 99245
        </span>
  </div>

  <mat-table [dataSource]="dataSource">
    <ng-container  matColumnDef="encounter">
      <mat-header-cell *matHeaderCellDef class="headerEncounter">Metrics</mat-header-cell>
      <mat-cell *matCellDef="let row" class="encounter">{{row.encounterMetric}}</mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].newPatients"  matColumnDef="countOf99201">
      <mat-header-cell *matHeaderCellDef class="header99201">99201</mat-header-cell>
      <mat-cell *matCellDef="let row"  class="countOf99201">{{isValidOrElse(row, 'newPatients', 'cpt99201', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].newPatients" matColumnDef="countOf99202">
      <mat-header-cell *matHeaderCellDef class="header99202">99202</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99202">{{isValidOrElse(row, 'newPatients', 'cpt99202', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].newPatients" matColumnDef="countOf99203">
      <mat-header-cell *matHeaderCellDef class="header99203">99203</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99203">{{isValidOrElse(row, 'newPatients', 'cpt99203', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].newPatients" matColumnDef="countOf99204">
      <mat-header-cell *matHeaderCellDef class="header99204">99204</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99204">{{isValidOrElse(row, 'newPatients', 'cpt99204', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].newPatients" matColumnDef="countOf99205">
      <mat-header-cell *matHeaderCellDef class="header99205">99205</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99205">{{isValidOrElse(row, 'newPatients', 'cpt99205', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].newPatients" matColumnDef="totalNewPatients">
      <mat-header-cell *matHeaderCellDef class="headerTotalNewPatients">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalNewPatients">{{isValidOrElse(row, 'newPatients', 'total', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].establishedPatients" matColumnDef="countOf99211">
      <mat-header-cell *matHeaderCellDef class="header99211">99211</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99211">{{isValidOrElse(row, 'establishedPatients', 'cpt99211', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].establishedPatients" matColumnDef="countOf99212">
      <mat-header-cell *matHeaderCellDef class="header99212">99212</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99212">{{isValidOrElse(row, 'establishedPatients', 'cpt99212', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].establishedPatients" matColumnDef="countOf99213">
      <mat-header-cell *matHeaderCellDef class="header99213">99213</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99213">{{isValidOrElse(row, 'establishedPatients', 'cpt99213', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].establishedPatients" matColumnDef="countOf99214">
      <mat-header-cell *matHeaderCellDef class="header99214">99214</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99214">{{isValidOrElse(row, 'establishedPatients', 'cpt99214', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].establishedPatients" matColumnDef="countOf99215">
      <mat-header-cell *matHeaderCellDef class="header99215">99215</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99215">{{isValidOrElse(row, 'establishedPatients', 'cpt99215', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].establishedPatients" matColumnDef="totalEstablishedPatients">
      <mat-header-cell *matHeaderCellDef class="headerTotalEstablishedPatients">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalEstablishedPatients">{{isValidOrElse(row, 'establishedPatients', 'total', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].consultation" matColumnDef="countOf99241">
      <mat-header-cell *matHeaderCellDef class="header99241">99241</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99241">{{isValidOrElse(row, 'consultation', 'cpt99241', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].consultation" matColumnDef="countOf99242">
      <mat-header-cell *matHeaderCellDef class="header99242">99242</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99242">{{isValidOrElse(row, 'consultation', 'cpt99242', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].consultation" matColumnDef="countOf99243">
      <mat-header-cell *matHeaderCellDef class="header99243">99243</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99243">{{isValidOrElse(row, 'consultation', 'cpt99243', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].consultation" matColumnDef="countOf99244">
      <mat-header-cell *matHeaderCellDef class="header99244">99244</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99244">{{isValidOrElse(row, 'consultation', 'cpt99244', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].consultation" matColumnDef="countOf99245">
      <mat-header-cell *matHeaderCellDef class="header99245">99245</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99245">{{isValidOrElse(row, 'consultation', 'cpt99245', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container *ngIf="displayedData[0].consultation" matColumnDef="totalConsultations">
      <mat-header-cell *matHeaderCellDef class="headerTotalConsultations">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalConsultations">{{isValidOrElse(row, 'consultation', 'total', '-')}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row  *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>

</div>

