import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DataTableColumns} from '../../shared/data-table-columns';
import {FeatureToggleSetting, SortingCriterion} from '../../shared/models';
import {BaseColumn, IAppState} from '../../store/IAppState';
import {AppAction} from '../../store/actions';
import {cfpSortingCriteriaChangedTo} from '../../store/actions';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {getPreviousYearDateRange} from '../../shared/helpers';
import {ColumnType, SortingOrder} from '../../shared/enums';
import {NgRedux} from '@angular-redux/store';
import {hasValue} from '../../shared/null-helpers';

@Component({
  selector: 'app-clinical-fingerprint-table',
  templateUrl: './clinical-fingerprint-table.component.html',
  styleUrls: ['./clinical-fingerprint-table.component.scss']
})
export class ClinicalFingerprintTableComponent<T> implements OnChanges {

  @Input() receivedData: any[];
  @Input() tableCssClass: string | undefined = undefined;
  @Input() tableTitle = '';
  @Input() defaultSortColumn: string;
  @Input() sortDirection: string;
  @Input() columns: DataTableColumns[] = [];
  @Input() originalColumns: DataTableColumns[] = [];
  @Input() whenSortChanged: (sortedRows: any) => void;
  @Input() tieBreakerProperty?: (element: T) => string;
  @Input() rowSelectionCallback?: (element: T) => void;
  @Input() showProgressBar = false;
  @Input() sortingDataAccessor?: any;
  @Input() zeroSuppressionCondition?: any;
  @Input() isZeroSuppressionChecked = false;
  @Input() displayZeroSuppression: boolean;
  @Input() countOfSuppressedEntries = 0;
  @Input() zeroSuppressionSnapshotEntry: string;
  @Input() snapshotEntryName = '';
  @Input() paginate = true;
  @Input() fromPdfExport = false;
  @Input() shouldPutNullsAtTheBottom = false;
  @Input() reducerAction: (columns: BaseColumn[]) => AppAction;
  @Input() initialPageSize = 25;
  @Output() pageEvent = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  pageSize = this.initialPageSize;
  pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
  dataSource: MatTableDataSource<any>;
  pageIndex = 0;
  length: number;
  displayedColumns: string[];
  sortChangeSubscribed = false;
  previousDate: string;
  settings: FeatureToggleSetting[];
  rangeHover = false;

  constructor(private readonly ngRedux: NgRedux<IAppState>) {
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes && changes['initialPageSize']?.firstChange && this.paginate) {
      this.pageSize = this.initialPageSize;
      this.pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
    }
    this.previousDate = getPreviousYearDateRange(this.ngRedux.getState().filters.dateRange);
    if (this.receivedData) {
      this.dataSource = new MatTableDataSource<any>(this.defaultSortColumn ? this.receivedData.slice().sort(
        (a: any, b: any) => b[this.defaultSortColumn] - a[this.defaultSortColumn]) : this.receivedData.slice());
      this.settings = this.ngRedux.getState().data.featureToggleSettings;
      if (this.sortingDataAccessor) {
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
      }
      this.displayedColumns = this.columns.map(x => x.columnDef);
      this.dataSource.sortData = (data: T[], sort: MatSort): T[] => {
        const active = changes?.defaultSortColumn ? this.defaultSortColumn : sort.active;
        const direction = sort.direction;
        if (!active || direction === '') {
          return data;
        }
        const dataElement = data.sort((a, b) => {
          return this.compare(a, b, active, direction === 'asc');
        });
        return dataElement;
      };

      if (this.whenSortChanged) {
        this.whenSortChanged(this.dataSource.sortData(this.dataSource.data, this.sort));
      }

      if (this.paginate) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize = this.pageSize;
        this.dataSource.paginator.pageIndex = this.pageIndex;
        this.dataSource.paginator.length = this.receivedData.length;
      }

      this.dataSource.sort = this.sort;
      if (!this.sortChangeSubscribed) {
        this.sortChangeSubscribed = true;
        this.dataSource.sort.sortChange.subscribe(() => {
          if (this.whenSortChanged) {
            const sortingCriteria: SortingCriterion = {
              sortingOrder: this.sort.direction === SortingOrder.ASCENDING ? SortingOrder.ASCENDING : SortingOrder.DESCENDING,
              columnDef: this.sort.active
            };
            this.whenSortingCriteriaChanged(sortingCriteria);
            this.whenSortChanged(this.dataSource.sortData(this.dataSource.data, this.sort));
          }
        });
      }
    }
  }

  whenSortingCriteriaChanged(sortingCriteria: SortingCriterion): void {
    this.ngRedux.dispatch(cfpSortingCriteriaChangedTo(sortingCriteria));
  }

  canShowTooltip(refEl: HTMLElement): boolean {
    return this.rangeHover && refEl.scrollWidth > refEl.offsetWidth;
  }

  getCssClassForColumnType(columnType: ColumnType): string {
    switch (columnType) {
      case ColumnType.BENCHMARK:
        return 'benchmark';
      case ColumnType.VARIANCE:
        return 'variance';
      default:
        return '';
    }
  }

  compare(a: T, b: T, sortHeaderId: string, sortAscending: boolean): number {
    const valueA = this.dataSource.sortingDataAccessor(a, sortHeaderId);
    const valueB = this.dataSource.sortingDataAccessor(b, sortHeaderId);
    const multiplier = sortAscending ? 1 : -1;
    if (this.shouldPutNullsAtTheBottom) {
      if (!hasValue(valueB) && hasValue(valueA)) {
        return -1;
      } else if (hasValue(valueB) && !hasValue(valueA)) {
        return 1;
      }
    }
    if (valueA > valueB) {
      return multiplier;
    }
    if (valueA < valueB) {
      return -1 * multiplier;
    }
    return this.getTieBreakerString(a).localeCompare(this.getTieBreakerString(b));
  }

  getTieBreakerString(item: T): string {
    return this.tieBreakerProperty ? this.tieBreakerProperty(item) : '';
  }

  updateDataTable(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageEvent.emit(event);
  }

  selectRow(selectedRow: T) {
    return this.rowSelectionCallback && this.rowSelectionCallback(selectedRow);
  }
}
