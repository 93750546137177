import {NewPatientVisitsLocationVariables} from '../../store/IAppState';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedNpvLocationAggregatedByNode,
  NewPatientVisitsBase,
  NewPatientVisitSnapshotMultiLevel,
  NewPatientVisitsPercentageBenchmarks,
  NewPatientVisitsVariance,
  NewPatientVisitTrendEntry,
  NpvByLocation,
  npvLocationDepartmentColumns,
  npvLocationProviderColumns,
  npvLocationSpecialtyColumns
} from './npv-models';
import {MemberLocationInfoBase, MultilevelNode} from '../../shared/models';
import * as _ from 'lodash';
import {roundToNumber} from '../../productivity-summary/number-formatter';
import {MultilevelTab} from '../../shared/enums';
import {
  npvLocationDepartmentColumnsChangedTo,
  npvLocationProviderColumnsChangedTo,
  npvLocationSpecialtyColumnsChangedTo
} from '../../store/actions';
import {MonthNumber} from '../../shared/helpers';

export const aDefaultNewPatientVisitsLocationVariables: NewPatientVisitsLocationVariables = {
  viewByNode: true,
  displayedDepartmentColumns: npvLocationDepartmentColumns().map(c => {
    return {
      columnDef: c.columnDef, header: c.header, columnType: c.columnType
    };
  }),
  displayedSpecialtyColumns: npvLocationSpecialtyColumns().map(c => {
    return {
      columnDef: c.columnDef, header: c.header, columnType: c.columnType
    };
  }),
  displayedProviderColumns: npvLocationProviderColumns().map(c => {
    return {
      columnDef: c.columnDef, header: c.header, columnType: c.columnType
    };
  }),
  columnForSortingTheData: {
    column: npvLocationDepartmentColumns()[2]
  }
};

export function mockNewPatientVisitSnapshotMultiLevel(npv: NewPatientVisitsBase,
                node: MultilevelNode, offset?: number): NewPatientVisitSnapshotMultiLevel {
  if (npv.countOfNewPatientVisits > npv.countOfTotalPatientVisits) {
    return mockNewPatientVisitSnapshotMultiLevel({
      countOfNewPatientVisits: npv.countOfTotalPatientVisits,
      countOfTotalPatientVisits: npv.countOfNewPatientVisits,
      newPatientVisitsPercentage: npv.newPatientVisitsPercentage,
      countOfExistingPatientVisits: npv.countOfExistingPatientVisits
    }, node, offset);
  }
  offset = offset || 0;
  return {
    nodeId: 333,
    nodeName: 'Revali',
    nodePath: '\\0\\1\\22\\333',
    departmentNodeId: node.departmentNodeId,
    departmentNodeName: node.departmentNodeName,
    departmentNodePath: node.departmentNodePath,
    specialtyNodeId: node.specialtyNodeId,
    specialtyNodeName: node.specialtyNodeName,
    specialtyNodePath: node.specialtyNodePath,
    providerNodeId: node.providerNodeId,
    providerNodeName: node.providerNodeName,
    providerNodePath: node.providerNodePath,
    countOfNewPatientVisits: npv.countOfNewPatientVisits,
    countOfTotalPatientVisits: npv.countOfTotalPatientVisits,
    newPatientVisitsPercentage: npv.countOfTotalPatientVisits > 0 ? roundToNumber(100 * (
      npv.countOfNewPatientVisits / npv.countOfTotalPatientVisits), 1) : 0,
    countOfExistingPatientVisits: npv.countOfTotalPatientVisits - npv.countOfNewPatientVisits,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(offset),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(offset),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(offset),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(offset),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(offset),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(offset),
    newPatientVisitsVariance: someNewPatientVisitsVariance(offset),
    newPatientVisitsCommunityVariance: someNewPatientVisitsCommunityVariance(offset),
    newPatientVisitsTelehealthVariance: someNewPatientVisitsTelehealthVariance(offset),
    newPatientVisitsInPersonVariance: someNewPatientVisitsInPersonVariance(offset),
    newPatientVisitsCommunityTelehealthVariance: someNewPatientVisitsCommunityTelehealthVariance(offset),
    newPatientVisitsCommunityInPersonVariance: someNewPatientVisitsCommunityInPersonVariance(offset),
    difference: 2 + offset
  };
}

export function mockNpvLocation(location: MemberLocationInfoBase, npv: NewPatientVisitsBase): NpvByLocation {
  if (npv.countOfNewPatientVisits > npv.countOfTotalPatientVisits) {
    return mockNpvLocation(location, {
      countOfNewPatientVisits: npv.countOfTotalPatientVisits,
      countOfTotalPatientVisits: npv.countOfNewPatientVisits,
      newPatientVisitsPercentage: npv.newPatientVisitsPercentage,
      countOfExistingPatientVisits: npv.countOfExistingPatientVisits
    });
  }
  return {
    memberLocationKey: location.memberLocationKey, memberLocationName: location.memberLocationName,
    countOfExistingPatientVisits: npv.countOfTotalPatientVisits - npv.countOfNewPatientVisits,
    countOfTotalPatientVisits: npv.countOfTotalPatientVisits, countOfNewPatientVisits: npv.countOfNewPatientVisits,
    newPatientVisitsPercentage: npv.countOfTotalPatientVisits > 0 ?
      roundToNumber(100 * (npv.countOfNewPatientVisits / npv.countOfTotalPatientVisits), 1) : 0
  };
}

export interface MockNpvLocationPopulator extends NewPatientVisitsBase, MultilevelNode { }

export function mockMergedNpvLocationAggregatedByNode(location: MemberLocationInfoBase, useOffset: boolean,
                                                      populators: MockNpvLocationPopulator[]): MergedNpvLocationAggregatedByNode {
  return {
    ...mockNpvLocation(location, aggregatePopulatorsForNpvBaseObject(populators)),
    departmentNpvSnapshotData: populateNodeList(populators, 0, useOffset),
    specialtyNpvSnapshotData: populateNodeList(populators, 1, useOffset),
    providerNpvSnapshotData: populateNodeList(populators, 3, useOffset),
    previousPercentage: 28
  };
}

function aggregatePopulatorsForNpvBaseObject(populators: MockNpvLocationPopulator[]): NewPatientVisitsBase {
  return {
    countOfNewPatientVisits: _.sumBy(populators, 'countOfNewPatientVisits'),
    countOfTotalPatientVisits: _.sumBy(populators, 'countOfTotalPatientVisits'),
    countOfExistingPatientVisits: _.sumBy(populators, 'countOfExistingPatientVisits'),
    newPatientVisitsPercentage: _.meanBy(populators, 'newPatientVisitsPercentage'),
  };
}

function getNodeListEntryFromPopulator(populator: MockNpvLocationPopulator, offset: number,
                                       useOffset: boolean): MergedNewPatientVisitSnapshotEntry {
  return {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfNewPatientVisits: populator.countOfNewPatientVisits + (populator.countOfTotalPatientVisits === 0 ? 0 : offset),
      countOfTotalPatientVisits: populator.countOfTotalPatientVisits + (populator.countOfTotalPatientVisits === 0 ? 0 : offset),
      countOfExistingPatientVisits: populator.countOfExistingPatientVisits,
      newPatientVisitsPercentage: populator.newPatientVisitsPercentage
    }, {
      departmentNodePath: populator.departmentNodePath, departmentNodeName: populator.departmentNodeName, specialtyNodePath:
      populator.specialtyNodePath,
      specialtyNodeName: populator.specialtyNodeName, providerNodePath: populator.providerNodePath, providerNodeName:
      populator.providerNodeName
    }, offset),
    previous: mockNewPatientVisitSnapshotMultiLevel({
      countOfNewPatientVisits: populator.countOfNewPatientVisits + (populator.countOfTotalPatientVisits === 0 ? 0 : offset),
      countOfTotalPatientVisits: populator.countOfTotalPatientVisits + (populator.countOfTotalPatientVisits === 0 ? 0 : offset),
      countOfExistingPatientVisits: populator.countOfExistingPatientVisits,
      newPatientVisitsPercentage: populator.newPatientVisitsPercentage
    }, {
      departmentNodePath: populator.departmentNodePath, departmentNodeName: populator.departmentNodeName, specialtyNodePath:
      populator.specialtyNodePath,
      specialtyNodeName: populator.specialtyNodeName, providerNodePath: populator.providerNodePath, providerNodeName:
      populator.providerNodeName
    }, useOffset ? offset + 3 : 0),
    isHidden: false
  };
}

export function populateNodeList(populators: MockNpvLocationPopulator[], offset: number,
                                 useOffset: boolean): MergedNewPatientVisitSnapshotEntry[] {
  return populators.map(p => getNodeListEntryFromPopulator(p, useOffset ? offset++ : 0, useOffset));
}

export const defaultNpvBenchmarks: NewPatientVisitsPercentageBenchmarks = {
  benchmarkMean: 0, benchmark25thPercentile: 0, benchmark50thPercentile: 0,
  benchmark75thPercentile: 0, benchmark90thPercentile: 0
};

export const defaultNpvVariance: NewPatientVisitsVariance = {
  varianceMean: -1, variance25thPercentile: -1, variance50thPercentile: -1,
  variance75thPercentile: -1, variance90thPercentile: -1
};

export function someNewPatientVisitsPercentageBenchmarks(offset: number): NewPatientVisitsPercentageBenchmarks {
  return {
    benchmarkMean: 11 + offset,
    benchmark25thPercentile: 22 + offset,
    benchmark50thPercentile: 33 + offset,
    benchmark75thPercentile: 44 + offset,
    benchmark90thPercentile: 55 + offset
  };
}
export function someNewPatientVisitsCommunityPercentageBenchmarks(offset: number): NewPatientVisitsPercentageBenchmarks {
  return {
    benchmarkMean: 12 + offset,
    benchmark25thPercentile: 23 + offset,
    benchmark50thPercentile: 34 + offset,
    benchmark75thPercentile: 45 + offset,
    benchmark90thPercentile: 56 + offset
  };
}

export function someNewPatientVisitsTelehealthPercentageBenchmarks(offset: number): NewPatientVisitsPercentageBenchmarks {
  return {
    benchmarkMean: 13 + offset,
    benchmark25thPercentile: 24 + offset,
    benchmark50thPercentile: 36 + offset,
    benchmark75thPercentile: 46 + offset,
    benchmark90thPercentile: 57 + offset
  };
}

export function someNewPatientVisitsInPersonPercentageBenchmarks(offset: number): NewPatientVisitsPercentageBenchmarks {
  return {
    benchmarkMean: 14 + offset,
    benchmark25thPercentile: 25 + offset,
    benchmark50thPercentile: 36 + offset,
    benchmark75thPercentile: 47 + offset,
    benchmark90thPercentile: 58 + offset
  };
}

export function someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(offset: number): NewPatientVisitsPercentageBenchmarks {
  return {
    benchmarkMean: 15 + offset,
    benchmark25thPercentile: 26 + offset,
    benchmark50thPercentile: 37 + offset,
    benchmark75thPercentile: 48 + offset,
    benchmark90thPercentile: 59 + offset
  };
}

export function someNewPatientVisitsCommunityInPersonPercentageBenchmarks(offset: number): NewPatientVisitsPercentageBenchmarks {
  return {
    benchmarkMean: 16 + offset,
    benchmark25thPercentile: 28 + offset,
    benchmark50thPercentile: 39 + offset,
    benchmark75thPercentile: 49 + offset,
    benchmark90thPercentile: 60 + offset
  };
}

export function someNewPatientVisitsVariance(offset: number): NewPatientVisitsVariance {
  return {
    varianceMean: 21 + offset,
    variance25thPercentile: 32 + offset,
    variance50thPercentile: 43 + offset,
    variance75thPercentile: 54 + offset,
    variance90thPercentile: 65 + offset
  };
}

export function someNewPatientVisitsCommunityVariance(offset: number): NewPatientVisitsVariance {
  return {
    varianceMean: 21 + offset,
    variance25thPercentile: 32 + offset,
    variance50thPercentile: 43 + offset,
    variance75thPercentile: 54 + offset,
    variance90thPercentile: 65 + offset
  };
}

export function someNewPatientVisitsTelehealthVariance(offset: number): NewPatientVisitsVariance {
  return {
    varianceMean: 21 + offset,
    variance25thPercentile: 32 + offset,
    variance50thPercentile: 43 + offset,
    variance75thPercentile: 54 + offset,
    variance90thPercentile: 65 + offset
  };
}

export function someNewPatientVisitsInPersonVariance(offset: number): NewPatientVisitsVariance {
  return {
    varianceMean: 21 + offset,
    variance25thPercentile: 32 + offset,
    variance50thPercentile: 43 + offset,
    variance75thPercentile: 54 + offset,
    variance90thPercentile: 65 + offset
  };
}

export function someNewPatientVisitsCommunityTelehealthVariance(offset: number): NewPatientVisitsVariance {
  return {
    varianceMean: 21 + offset,
    variance25thPercentile: 32 + offset,
    variance50thPercentile: 43 + offset,
    variance75thPercentile: 54 + offset,
    variance90thPercentile: 65 + offset
  };
}

export function someNewPatientVisitsCommunityInPersonVariance(offset: number): NewPatientVisitsVariance {
  return {
    varianceMean: 21 + offset,
    variance25thPercentile: 32 + offset,
    variance50thPercentile: 43 + offset,
    variance75thPercentile: 54 + offset,
    variance90thPercentile: 65 + offset
  };
}

const zeroEntryNode = {
  ...mockNewPatientVisitSnapshotMultiLevel({
    countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
    newPatientVisitsPercentage: 0
  }, {
    departmentNodeName: 'Zerology', departmentNodePath: '\\10\\77',
    specialtyNodeName: 'Supprecialty', specialtyNodePath: '\\10\\77\\527',
    providerNodeName: 'Mr Xerox', providerNodePath: '\\10\\77\\527\\5334',
  }, 0),
  previous: mockNewPatientVisitSnapshotMultiLevel({
    countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
    newPatientVisitsPercentage: 0
  }, {
    departmentNodeName: 'Zerology', departmentNodePath: '\\10\\77',
    specialtyNodeName: 'Supprecialty', specialtyNodePath: '\\10\\77\\527',
    providerNodeName: 'Mr Xerox', providerNodePath: '\\10\\77\\527\\5334',
  }, 0),
  isHidden: false,
  isTotal: false,
  designatedColumnId: 'undefined'
};

function expectedDepartmentNodeListA(zeroSuppression: boolean) {
  return [{
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: 'Total', departmentNodePath: '',
      specialtyNodeName: '', specialtyNodePath: '',
      providerNodeName: '', providerNodePath: ''
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 27, countOfTotalPatientVisits: 40,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }, 0), newPatientVisitsPercentage: 28},
    countOfNewPatientVisits: 60,
    countOfTotalPatientVisits: 81,
    newPatientVisitsPercentage: 74.1,
    countOfExistingPatientVisits: 13,
    difference: 46.1,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    isHidden: false,
    isTotal: true,
    designatedColumnId: 'total'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
      specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
      providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 27, countOfTotalPatientVisits: 40,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }, 0), newPatientVisitsPercentage: 67.5},
    countOfNewPatientVisits: 27,
    countOfTotalPatientVisits: 40,
    newPatientVisitsPercentage: 67.5,
    countOfExistingPatientVisits: 13,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 8, countOfNewPatientVisits: 33, countOfTotalPatientVisits: 41,
      newPatientVisitsPercentage: 80
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }, 0),
    previous: mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 33, countOfTotalPatientVisits: 41,
      newPatientVisitsPercentage: 75
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }, 0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }].concat(zeroSuppression ? [] : [zeroEntryNode]);
}

function expectedDepartmentNodeListB(zeroSuppression: boolean) {
  return [{
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: 'Total', departmentNodePath: '',
      specialtyNodeName: '', specialtyNodePath: '',
      providerNodeName: '', providerNodePath: ''
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 28, countOfTotalPatientVisits: 41,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }, 0), newPatientVisitsPercentage: 28},
    countOfNewPatientVisits: 62,
    countOfTotalPatientVisits: 83,
    newPatientVisitsPercentage: 74.7,
    countOfExistingPatientVisits: 13,
    difference: 46.7,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    isHidden: false,
    isTotal: true,
    designatedColumnId: 'total'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 75
    }, {
      departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
      specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
      providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 28, countOfTotalPatientVisits: 41,
        newPatientVisitsPercentage: 75
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }, 0), newPatientVisitsPercentage: 68.3},
    countOfNewPatientVisits: 28,
    countOfTotalPatientVisits: 41,
    newPatientVisitsPercentage: 68.3,
    countOfExistingPatientVisits: 13,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 8, countOfNewPatientVisits: 34, countOfTotalPatientVisits: 42,
      newPatientVisitsPercentage: 80
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 34, countOfTotalPatientVisits: 42,
        newPatientVisitsPercentage: 75
      }, {
        departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
        specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
        providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
      }, 0),
      newPatientVisitsPercentage: 81
    },
    newPatientVisitsPercentage: 81,
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }].concat(zeroSuppression ? [] : [zeroEntryNode]);
}

function expectedZeroNodeList(tab: MultilevelTab) {
  let departmentTotalName = '', specialtyTotalName = '', providerTotalName = '';
  switch (tab) {
    case MultilevelTab.LOCATION_DEPARTMENT:
      departmentTotalName = 'Total';
      break;
    case MultilevelTab.LOCATION_SPECIALTY:
      specialtyTotalName = 'Total';
      break;
    case MultilevelTab.LOCATION_PROVIDER:
      providerTotalName = 'Total';
  }
  return [{
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
      newPatientVisitsPercentage: 0
    }, {
      departmentNodeName: departmentTotalName, departmentNodePath: '', specialtyNodeName: specialtyTotalName,
      specialtyNodePath: '', providerNodeName: providerTotalName, providerNodePath: ''
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
        newPatientVisitsPercentage: 0
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1', specialtyNodeName: 'Cardiology',
        specialtyNodePath: '\\0\\1\\22', providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }, 0), newPatientVisitsPercentage: 28},
    countOfNewPatientVisits: 0,
    countOfTotalPatientVisits: 0,
    newPatientVisitsPercentage: 0,
    countOfExistingPatientVisits: 0,
    difference: -28,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    isHidden: false,
    isTotal: true,
    designatedColumnId: 'total'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
      newPatientVisitsPercentage: 0
    }, {
      departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1', specialtyNodeName: 'Cardiology',
      specialtyNodePath: '\\0\\1\\22', providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
        newPatientVisitsPercentage: 0
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1', specialtyNodeName: 'Cardiology',
        specialtyNodePath: '\\0\\1\\22', providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }, 0), newPatientVisitsPercentage: 0},
    countOfNewPatientVisits: 0,
    countOfTotalPatientVisits: 0,
    newPatientVisitsPercentage: 0,
    countOfExistingPatientVisits: 0,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
      newPatientVisitsPercentage: 0
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }, 0),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 0, countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
        newPatientVisitsPercentage: 0
      }, {
        departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
        specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
        providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
      }, 0),
      newPatientVisitsPercentage: 0
    },
    newPatientVisitsPercentage: 0,
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }];
}

function expectedSpecialtyNodeListA(zeroSuppression: boolean) {
  return [{
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 16, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: '', departmentNodePath: '',
      specialtyNodeName: 'Total', specialtyNodePath: '',
      providerNodeName: '', providerNodePath: ''
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 27, countOfTotalPatientVisits: 40,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 28},
    countOfNewPatientVisits: 60,
    countOfTotalPatientVisits: 81,
    newPatientVisitsPercentage: 74.1,
    countOfExistingPatientVisits: 13,
    difference: 46.1,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    isHidden: false,
    isTotal: true,
    designatedColumnId: 'total'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
      specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
      providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 27, countOfTotalPatientVisits: 40,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 67.5},
    countOfNewPatientVisits: 27,
    countOfTotalPatientVisits: 40,
    newPatientVisitsPercentage: 67.5,
    countOfExistingPatientVisits: 13,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(0),
    newPatientVisitsVariance: someNewPatientVisitsVariance(0),
    newPatientVisitsCommunityVariance: someNewPatientVisitsCommunityVariance(0),
    newPatientVisitsTelehealthVariance: someNewPatientVisitsTelehealthVariance(0),
    newPatientVisitsInPersonVariance: someNewPatientVisitsInPersonVariance(0),
    newPatientVisitsCommunityTelehealthVariance: someNewPatientVisitsCommunityTelehealthVariance(0),
    newPatientVisitsCommunityInPersonVariance: someNewPatientVisitsCommunityInPersonVariance(0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 8, countOfNewPatientVisits: 33, countOfTotalPatientVisits: 41,
      newPatientVisitsPercentage: 80
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }),
    previous: mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 33, countOfTotalPatientVisits: 41,
      newPatientVisitsPercentage: 75
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }].concat(zeroSuppression ? [] : [zeroEntryNode]);
}

function expectedSpecialtyNodeListB(zeroSuppression: boolean) {
  return [{
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: '', departmentNodePath: '',
      specialtyNodeName: 'Total', specialtyNodePath: '',
      providerNodeName: '', providerNodePath: ''
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 28, countOfTotalPatientVisits: 41,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 28},
    countOfNewPatientVisits: 62,
    countOfTotalPatientVisits: 83,
    newPatientVisitsPercentage: 74.7,
    countOfExistingPatientVisits: 13,
    difference: 46.7,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    isHidden: false,
    isTotal: true,
    designatedColumnId: 'total'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 75
    }, {
      departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
      specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
      providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 28, countOfTotalPatientVisits: 41,
        newPatientVisitsPercentage: 75
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 68.3},
    countOfNewPatientVisits: 28,
    countOfTotalPatientVisits: 41,
    newPatientVisitsPercentage: 68.3,
    countOfExistingPatientVisits: 13,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(0),
    newPatientVisitsVariance: someNewPatientVisitsVariance(0),
    newPatientVisitsCommunityVariance: someNewPatientVisitsCommunityVariance(0),
    newPatientVisitsTelehealthVariance: someNewPatientVisitsTelehealthVariance(0),
    newPatientVisitsInPersonVariance: someNewPatientVisitsInPersonVariance(0),
    newPatientVisitsCommunityTelehealthVariance: someNewPatientVisitsCommunityTelehealthVariance(0),
    newPatientVisitsCommunityInPersonVariance: someNewPatientVisitsCommunityInPersonVariance(0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 8, countOfNewPatientVisits: 34, countOfTotalPatientVisits: 42,
      newPatientVisitsPercentage: 80
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 34, countOfTotalPatientVisits: 42,
        newPatientVisitsPercentage: 75
      }, {
        departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
        specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
        providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
      }),
      newPatientVisitsPercentage: 81
    },
    newPatientVisitsPercentage: 81,
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }].concat(zeroSuppression ? [] : [zeroEntryNode]);
}

function expectedProviderNodeListA(zeroSuppression: boolean) {
  return [{
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: '', departmentNodePath: '',
      specialtyNodeName: '', specialtyNodePath: '',
      providerNodeName: 'Total', providerNodePath: ''
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 27, countOfTotalPatientVisits: 40,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 28},
    countOfNewPatientVisits: 60,
    countOfTotalPatientVisits: 81,
    newPatientVisitsPercentage: 74.1,
    countOfExistingPatientVisits: 13,
    difference: 46.1,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    isHidden: false,
    isTotal: true,
    designatedColumnId: 'total'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 18, countOfTotalPatientVisits: 23,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
      specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
      providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 27, countOfTotalPatientVisits: 40,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 67.5},
    countOfNewPatientVisits: 27,
    countOfTotalPatientVisits: 40,
    newPatientVisitsPercentage: 67.5,
    countOfExistingPatientVisits: 13,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(0),
    newPatientVisitsVariance: someNewPatientVisitsVariance(0),
    newPatientVisitsCommunityVariance: someNewPatientVisitsCommunityVariance(0),
    newPatientVisitsTelehealthVariance: someNewPatientVisitsTelehealthVariance(0),
    newPatientVisitsInPersonVariance: someNewPatientVisitsInPersonVariance(0),
    newPatientVisitsCommunityTelehealthVariance: someNewPatientVisitsCommunityTelehealthVariance(0),
    newPatientVisitsCommunityInPersonVariance: someNewPatientVisitsCommunityInPersonVariance(0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 8, countOfNewPatientVisits: 33, countOfTotalPatientVisits: 41,
      newPatientVisitsPercentage: 80
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }),
    previous: mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 33, countOfTotalPatientVisits: 41,
      newPatientVisitsPercentage: 75
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }].concat(zeroSuppression ? [] : [zeroEntryNode]);
}

function expectedProviderNodeListB(zeroSuppression: boolean) {
  return [{
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 74.7
    }, {
      departmentNodeName: '', departmentNodePath: '',
      specialtyNodeName: '', specialtyNodePath: '',
      providerNodeName: 'Total', providerNodePath: ''
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 28, countOfTotalPatientVisits: 41,
        newPatientVisitsPercentage: 74.7
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 28},
    countOfNewPatientVisits: 62,
    countOfTotalPatientVisits: 83,
    newPatientVisitsPercentage: 74.7,
    countOfExistingPatientVisits: 13,
    difference: 46.7,
    newPatientVisitsPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    newPatientVisitsCommunityInPersonPercentageBenchmarks: defaultNpvBenchmarks,
    isHidden: false,
    isTotal: true,
    designatedColumnId: 'total'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 5, countOfNewPatientVisits: 15, countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 75
    }, {
      departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
      specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
      providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 28, countOfTotalPatientVisits: 41,
        newPatientVisitsPercentage: 80.5
      }, {
        departmentNodeName: 'Pathology', departmentNodePath: '\\0\\1',
        specialtyNodeName: 'Cardiology', specialtyNodePath: '\\0\\1\\22',
        providerNodeName: 'Yunobo', providerNodePath: '\\0\\1\\22\\333'
      }), newPatientVisitsPercentage: 68.3},
    countOfNewPatientVisits: 28,
    countOfTotalPatientVisits: 41,
    newPatientVisitsPercentage: 68.3,
    countOfExistingPatientVisits: 13,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsCommunityInPersonPercentageBenchmarks(0),
    newPatientVisitsVariance: someNewPatientVisitsVariance(0),
    newPatientVisitsCommunityVariance: someNewPatientVisitsCommunityVariance(0),
    newPatientVisitsTelehealthVariance: someNewPatientVisitsTelehealthVariance(0),
    newPatientVisitsInPersonVariance: someNewPatientVisitsInPersonVariance(0),
    newPatientVisitsCommunityTelehealthVariance: someNewPatientVisitsCommunityTelehealthVariance(0),
    newPatientVisitsCommunityInPersonVariance: someNewPatientVisitsCommunityInPersonVariance(0),
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined'
  }, {
    ...mockNewPatientVisitSnapshotMultiLevel({
      countOfExistingPatientVisits: 8, countOfNewPatientVisits: 34, countOfTotalPatientVisits: 42,
      newPatientVisitsPercentage: 80
    }, {
      departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
      specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
      providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
    }),
    previous: {...mockNewPatientVisitSnapshotMultiLevel({
        countOfExistingPatientVisits: 5, countOfNewPatientVisits: 34, countOfTotalPatientVisits: 42,
        newPatientVisitsPercentage: 75
      }, {
        departmentNodeName: 'Anesthesiology', departmentNodePath: '\\0\\7',
        specialtyNodeName: 'FamilyMedicine', specialtyNodePath: '\\0\\7\\27',
        providerNodeName: 'Samus', providerNodePath: '\\0\\7\\27\\334',
      }),
      newPatientVisitsPercentage: 81
    },
    newPatientVisitsPercentage: 81,
    isHidden: false,
    isTotal: false,
    designatedColumnId: 'undefined',
  }].concat(zeroSuppression ? [] : [zeroEntryNode]);
}

export const zeroDepartmentSnapshot = {
  countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
  newPatientVisitsPercentage: 0, countOfExistingPatientVisits: 0, name: 'Zerology',
  identity: '\\10\\77', benchmarkValue: 1200, drillDown: undefined
};

export const zeroSpecialtySnapshot = {
  countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
  newPatientVisitsPercentage: 0, countOfExistingPatientVisits: 0, name: 'Supprecialty',
  identity: '\\10\\77\\527', benchmarkValue: 1100, drillDown: undefined
};

export const zeroProviderSnapshot = {
  countOfNewPatientVisits: 0, countOfTotalPatientVisits: 0,
  newPatientVisitsPercentage: 0, countOfExistingPatientVisits: 0, name: 'Mr Xerox',
  identity: '\\10\\77\\527\\5334', benchmarkValue: 1100, drillDown: undefined
};

export function npvLocationDynamicModel(zeroSuppression: boolean) {
  return [{
    name: 'Department',
    tab: MultilevelTab.LOCATION_DEPARTMENT,
    action: npvLocationDepartmentColumnsChangedTo,
    expectedNodeListA: expectedDepartmentNodeListA(zeroSuppression),
    expectedNodeListB: expectedDepartmentNodeListB(zeroSuppression),
    expectedNodeListC: expectedZeroNodeList(MultilevelTab.LOCATION_DEPARTMENT),
    drillDowns: ['\\0\\17', '\\10\\77'],
    dataSetA: [{
      countOfNewPatientVisits: 62,
      countOfTotalPatientVisits: 83,
      newPatientVisitsPercentage: 74.7,
      countOfExistingPatientVisits: 21,
      name: 'Cataris',
      identity: '26',
      benchmarkValue: -1,
      drillDown: undefined
    }, {
      countOfNewPatientVisits: 60,
      countOfTotalPatientVisits: 81,
      newPatientVisitsPercentage: 74.1,
      countOfExistingPatientVisits: 21,
      name: 'Akkala',
      identity: '20',
      benchmarkValue: -1,
      drillDown: undefined
    }, {
      countOfNewPatientVisits: 0,
      countOfTotalPatientVisits: 0,
      newPatientVisitsPercentage: 0,
      countOfExistingPatientVisits: 0,
      name: 'Emptyland',
      identity: '28',
      benchmarkValue: -1,
      drillDown: undefined
    }],
    dataSetB: [{
      countOfNewPatientVisits: 15,
      countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 75,
      countOfExistingPatientVisits: 5, name: 'GameOfThrones',
      identity: '\\0\\17', benchmarkValue: 1100, drillDown: undefined
    }].concat(zeroSuppression ? [] : [zeroDepartmentSnapshot]),
    dataSetC: [{
      countOfNewPatientVisits: 15,
      countOfTotalPatientVisits: 20,
      newPatientVisitsPercentage: 75,
      countOfExistingPatientVisits: 5,
      name: 'GameOfThrones',
      identity: '\\0\\17',
      benchmarkValue: 1100,
      drillDown: undefined
    }, zeroDepartmentSnapshot]
  }, {
    name: 'Specialty',
    tab: MultilevelTab.LOCATION_SPECIALTY,
    action: npvLocationSpecialtyColumnsChangedTo,
    expectedNodeListA: expectedSpecialtyNodeListA(zeroSuppression),
    expectedNodeListB: expectedSpecialtyNodeListB(zeroSuppression),
    expectedNodeListC: expectedZeroNodeList(MultilevelTab.LOCATION_SPECIALTY),
    drillDowns: ['\\0\\19\\555', '\\10\\77\\527'],
    dataSetA: [{
      countOfNewPatientVisits: 62,
      countOfTotalPatientVisits: 83,
      newPatientVisitsPercentage: 74.7,
      countOfExistingPatientVisits: 21,
      name: 'Cataris',
      identity: '26',
      benchmarkValue: -1,
      drillDown: undefined
    }, {
      countOfNewPatientVisits: 60,
      countOfTotalPatientVisits: 81,
      newPatientVisitsPercentage: 74.1,
      countOfExistingPatientVisits: 21,
      name: 'Akkala',
      identity: '20',
      benchmarkValue: -1,
      drillDown: undefined
    }, {
      countOfNewPatientVisits: 0,
      countOfTotalPatientVisits: 0,
      newPatientVisitsPercentage: 0,
      countOfExistingPatientVisits: 0,
      name: 'Emptyland',
      identity: '28',
      benchmarkValue: -1,
      drillDown: undefined
    }],
    dataSetB: [{
      countOfNewPatientVisits: 12,
      countOfTotalPatientVisits: 22,
      newPatientVisitsPercentage: 54.5,
      countOfExistingPatientVisits: 10,
      name: 'ThunderCats',
      identity: '\\0\\19\\555',
      benchmarkValue: 1100,
      drillDown: undefined
    }].concat(zeroSuppression ? [] : [zeroSpecialtySnapshot]),
    dataSetC: [{
      countOfNewPatientVisits: 12,
      countOfTotalPatientVisits: 22,
      newPatientVisitsPercentage: 54.5,
      countOfExistingPatientVisits: 10,
      name: 'ThunderCats',
      identity: '\\0\\19\\555',
      benchmarkValue: 1100,
      drillDown: undefined
    }, zeroSpecialtySnapshot]
  }, {
    name: 'Provider',
    tab: MultilevelTab.LOCATION_PROVIDER,
    action: npvLocationProviderColumnsChangedTo,
    expectedNodeListA: expectedProviderNodeListA(zeroSuppression),
    expectedNodeListB: expectedProviderNodeListB(zeroSuppression),
    expectedNodeListC: expectedZeroNodeList(MultilevelTab.LOCATION_PROVIDER),
    drillDowns: ['\\0\\19\\555\\1996', '\\10\\77\\527\\5334'],
    dataSetA: [{
      countOfNewPatientVisits: 62,
      countOfTotalPatientVisits: 83,
      newPatientVisitsPercentage: 74.7,
      countOfExistingPatientVisits: 21,
      name: 'Cataris',
      identity: '26',
      benchmarkValue: -1,
      drillDown: undefined
    }, {
      countOfNewPatientVisits: 60,
      countOfTotalPatientVisits: 81,
      newPatientVisitsPercentage: 74.1,
      countOfExistingPatientVisits: 21,
      name: 'Akkala',
      identity: '20',
      benchmarkValue: -1,
      drillDown: undefined
    }, {
      countOfNewPatientVisits: 0,
      countOfTotalPatientVisits: 0,
      newPatientVisitsPercentage: 0,
      countOfExistingPatientVisits: 0,
      name: 'Emptyland',
      identity: '28',
      benchmarkValue: -1,
      drillDown: undefined }],
    dataSetB: [{
      countOfNewPatientVisits: 17,
      countOfTotalPatientVisits: 26,
      newPatientVisitsPercentage: 65.4,
      countOfExistingPatientVisits: 9,
      name: 'Yunobo',
      identity: '\\0\\19\\555\\1996',
      benchmarkValue: 1100,
      drillDown: undefined
    }].concat(zeroSuppression ? [] : [zeroProviderSnapshot]),
    dataSetC: [{
      countOfNewPatientVisits: 17,
      countOfTotalPatientVisits: 26,
      newPatientVisitsPercentage: 65.4,
      countOfExistingPatientVisits: 9,
      name: 'Yunobo',
      identity: '\\0\\19\\555\\1996',
      benchmarkValue: 1100,
      drillDown: undefined
    }, zeroProviderSnapshot]
  }];
}

export function getNewPatientVisitTrendMock(newCount: number, totalCount: number,
                                            year: number, month: MonthNumber): NewPatientVisitTrendEntry {
  return {
    countOfNewPatientVisits: newCount,
    countOfTotalPatientVisits: totalCount,
    newPatientVisitsPercentage: roundToNumber(100 * (newCount / totalCount), 1),
    countOfExistingPatientVisits: totalCount - newCount,
    difference: 2,
    newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
    newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
    newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
    newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
    newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
    newPatientVisitsVariance: someNewPatientVisitsVariance(0),
    newPatientVisitsCommunityVariance: someNewPatientVisitsCommunityVariance(0),
    newPatientVisitsTelehealthVariance: someNewPatientVisitsTelehealthVariance(0),
    newPatientVisitsInPersonVariance: someNewPatientVisitsInPersonVariance(0),
    newPatientVisitsCommunityTelehealthVariance: someNewPatientVisitsCommunityTelehealthVariance(0),
    newPatientVisitsCommunityInPersonVariance: someNewPatientVisitsInPersonVariance(0),
    month: month,
    year: year,
    previous: {
      countOfNewPatientVisits: newCount,
      countOfTotalPatientVisits: totalCount,
      newPatientVisitsPercentage: 100 * (newCount / totalCount),
      countOfExistingPatientVisits: totalCount - newCount,
      difference: 2,
      newPatientVisitsPercentageBenchmarks: someNewPatientVisitsPercentageBenchmarks(0),
      newPatientVisitsCommunityPercentageBenchmarks: someNewPatientVisitsCommunityPercentageBenchmarks(0),
      newPatientVisitsTelehealthPercentageBenchmarks: someNewPatientVisitsTelehealthPercentageBenchmarks(0),
      newPatientVisitsInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
      newPatientVisitsCommunityTelehealthPercentageBenchmarks: someNewPatientVisitsCommunityTelehealthPercentageBenchmarks(0),
      newPatientVisitsCommunityInPersonPercentageBenchmarks: someNewPatientVisitsInPersonPercentageBenchmarks(0),
      newPatientVisitsVariance: someNewPatientVisitsVariance(0),
      newPatientVisitsCommunityVariance: someNewPatientVisitsCommunityVariance(0),
      newPatientVisitsTelehealthVariance: someNewPatientVisitsTelehealthVariance(0),
      newPatientVisitsInPersonVariance: someNewPatientVisitsInPersonVariance(0),
      newPatientVisitsCommunityTelehealthVariance: someNewPatientVisitsCommunityTelehealthVariance(0),
      newPatientVisitsCommunityInPersonVariance: someNewPatientVisitsInPersonVariance(0),
      month: month,
      year: year - 1,
    }
  };
}
