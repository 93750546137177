<mat-form-field id="benchmark-type-form" subscriptSizing="dynamic">
  <mat-label [ngClass]="selected ? 'selected-benchmark-specification' : 'unselected-benchmark-specification'">Type</mat-label>
  <mat-select class="benchmark-selector {{!selected ? 'inactive' : ''}}"
              id="type-selector"
              [value]="batchExportMetricSection && batchExportMetricSection.benchmarkCombo ? batchExportMetricSection.benchmarkCombo.option : undefined"
              [disabled]="!selected" hideSingleSelectionIndicator>
    <mat-option *ngFor="let option of benchmarkOptions" [value]="option.value" class="benchmark-option-text"
      (click)="chooseBenchmarkOption(option.value)">{{option.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="percentiles.length" id="percentile-form" subscriptSizing="dynamic">
  <mat-label [ngClass]="selected ? 'selected-benchmark-specification' : 'unselected-benchmark-specification'">
    {{hasMultiplePercentiles ? 'Percentile' : ''}}
  </mat-label>
  <mat-select [ngClass]="percentileSelectorClass" id="percentile-selector"
              [value]="batchExportMetricSection && batchExportMetricSection.benchmarkCombo ? batchExportMetricSection.benchmarkCombo.percentile : undefined"
              [placeholder]="batchExportMetricSection && batchExportMetricSection.benchmarkCombo && batchExportMetricSection.benchmarkCombo.percentile ?
    benchmarkPercentileSeriesName(batchExportMetricSection.benchmarkCombo.percentile) : 'Percentile'"
    [disabled]="!hasMultiplePercentiles || !selected" hideSingleSelectionIndicator>
    <mat-option *ngFor="let percentile of percentiles" [value]="percentile.percentile" class="percentile-option-text"
                (click)="choosePercentile(percentile.percentile)">{{percentile.text}}
    </mat-option>
  </mat-select>
</mat-form-field>
