<app-tab-navigation id="dimension-nav"
                    *ngIf="!fromPdf"
                    [tabs]="dimensionTabs"
                    [chosenTab]="selectedDimensionTab"
                    [tabUpdateAction]="emDimensionChangedTo"
                    [isEmDimensionTab]="true"
></app-tab-navigation>

<app-tab-navigation id="level-nav"
                    *ngIf="!overviewPageView"
                    [tabs]="levelTabs"
                    [chosenTab]="selectedLevelTab"
                    [tabUpdateAction]="multilevelTabChangedTo"
></app-tab-navigation>

<div id="page-content" [ngSwitch]="selectedLevelTab">
  <div *ngSwitchCase="MultiLevelTab.BY_DEPARTMENT">
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Outpatient) || (fromPdf && emMetrics.includes(EmDimension.Outpatient))"
      id="outPatient">
      <app-em-outpatient-multilevel-charts
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsDepartment"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="400"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-outpatient-multilevel-charts>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-outpatient-summary-table
            [showDataTable]="true"
          ></app-em-outpatient-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Outpatient E&M Breakdown by Department</div>
              <app-zero-suppression-component *ngIf="!isSingleProviderSelected"
                                              [countOfSuppressedEntries]="numberOfSuppressions"
                                              [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                                              [condition]="zeroSuppressionCondition"
                                              [multiLevelBreakdown]="selectedLevelTab">
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedDepartment"
                                   [dimension]="selectedDimensionTab"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.InPatient) || (fromPdf && emMetrics.includes(EmDimension.InPatient))"
      id="inPatient">
      <app-em-inpatient-multilevel-chart
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsDepartment"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="400"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-inpatient-multilevel-chart>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-inpatient-summary-table
            [showDataTable]="true"
          ></app-em-inpatient-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Inpatient E&M Breakdown by Department</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              >
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedDepartment"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.EmergencyMedicine) || (fromPdf && emMetrics.includes(EmDimension.EmergencyMedicine))"
      id="emergencyRoom">
      <app-em-emergency-room-multilevel-charts
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsDepartment"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="1270"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      ></app-em-emergency-room-multilevel-charts>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-emergency-room-summary-table
            [showDataTable]="true">
          </app-em-emergency-room-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Emergency Medicine E&M Breakdown by Department</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab">
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedDepartment"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Ophthalmology) || (fromPdf && emMetrics.includes(EmDimension.Ophthalmology))"
      id="ophthalmology">
      <app-em-eye-exam-multilevel-chart
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsDepartment"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="600"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-eye-exam-multilevel-chart>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-eye-exam-summary-table
            [showDataTable]="true"
          ></app-em-eye-exam-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Ophthalmology E&M Breakdown by Department</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              ></app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedDepartment"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="MultiLevelTab.BY_SPECIALTY">
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Outpatient) || (fromPdf && emMetrics.includes(EmDimension.Outpatient))"
      id="outPatientSpecialty">
      <app-em-outpatient-multilevel-charts
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsSpecialty"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="400"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-outpatient-multilevel-charts>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-outpatient-summary-table
            [showDataTable]="true"
          ></app-em-outpatient-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Outpatient E&M Breakdown by Specialty</div>
              <app-zero-suppression-component *ngIf="!isSingleProviderSelected"
                                              [countOfSuppressedEntries]="numberOfSuppressions"
                                              [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                                              [condition]="zeroSuppressionCondition"
                                              [multiLevelBreakdown]="selectedLevelTab"
              >
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedSpecialty"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.InPatient) || (fromPdf && emMetrics.includes(EmDimension.InPatient))"
      id="inPatientSpecialty">
      <app-em-inpatient-multilevel-chart
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsSpecialty"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="400"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-inpatient-multilevel-chart>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-inpatient-summary-table
            [showDataTable]="true"
          ></app-em-inpatient-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Inpatient E&M Breakdown by Specialty</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              >
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedSpecialty"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.EmergencyMedicine) || (fromPdf && emMetrics.includes(EmDimension.EmergencyMedicine))"
      id="emergencyRoomSpecialty">
      <app-em-emergency-room-multilevel-charts
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsSpecialty"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="1270"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      ></app-em-emergency-room-multilevel-charts>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-emergency-room-summary-table
            [showDataTable]="true"
          >
          </app-em-emergency-room-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Emergency Medicine E&M Breakdown by Specialty</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              >
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedSpecialty"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Ophthalmology) || (fromPdf && emMetrics.includes(EmDimension.Ophthalmology))"
      id="ophthalmologySpecialty">
      <app-em-eye-exam-multilevel-chart
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsSpecialty"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="600"
        [isSingleProviderSelected]="false"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-eye-exam-multilevel-chart>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-eye-exam-summary-table
            [showDataTable]="true"
          ></app-em-eye-exam-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Ophthalmology E&M Breakdown by Specialty</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              ></app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedSpecialty"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="MultiLevelTab.BY_PROVIDER">
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Outpatient) || (fromPdf && emMetrics.includes(EmDimension.Outpatient))">
      <app-em-outpatient-multilevel-charts
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsProvider"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [specialtyCptCounts]="specialtyCptCounts"
        [overviewPageView]="overviewPageView"
        [chartWidth]="400"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-outpatient-multilevel-charts>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-outpatient-summary-table
            [showDataTable]="true"
          ></app-em-outpatient-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Outpatient E&M Breakdown by Provider</div>
              <app-zero-suppression-component *ngIf="!isSingleProviderSelected"
                                              [countOfSuppressedEntries]="numberOfSuppressions"
                                              [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                                              [condition]="zeroSuppressionCondition"
                                              [multiLevelBreakdown]="selectedLevelTab"
              >
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedProvider"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.InPatient) || (fromPdf && emMetrics.includes(EmDimension.InPatient))">
      <app-em-inpatient-multilevel-chart
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsProvider"
        [specialtyCptCounts]="specialtyCptCounts"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [overviewPageView]="overviewPageView"
        [chartWidth]="400"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-inpatient-multilevel-chart>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-inpatient-summary-table
            [showDataTable]="true"
          ></app-em-inpatient-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Inpatient E&M Breakdown by Provider</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              >
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedProvider"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.EmergencyMedicine) ||
     (fromPdf && emMetrics.includes(EmDimension.EmergencyMedicine))">
      <app-em-emergency-room-multilevel-charts
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsProvider"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [specialtyCptCounts]="specialtyCptCounts"
        [overviewPageView]="overviewPageView"
        [chartWidth]="1270"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      ></app-em-emergency-room-multilevel-charts>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-emergency-room-summary-table
            [showDataTable]="true"
          >
          </app-em-emergency-room-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Emergency Medicine E&M Breakdown by Provider</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              >
              </app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedProvider"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)">
            </app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Ophthalmology) || (fromPdf && emMetrics.includes(EmDimension.Ophthalmology))">
      <app-em-eye-exam-multilevel-chart
        [fromPdf]="fromPdf"
        [showProgressBar]="showProgressBar"
        [totalCptCounts]="totalCptCountsProvider"
        [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
        [specialtyCptCounts]="specialtyCptCounts"
        [overviewPageView]="overviewPageView"
        [chartWidth]="600"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [foundNodeName]="foundNodeName"
        [benchmarkOption]="benchmarkOption"
        class="app-percentage-chart"
      >
      </app-em-eye-exam-multilevel-chart>
      <div *ngIf="!overviewPageView" class="data-table-container-wrapper">
        <div class="data-table-container">
          <app-em-eye-exam-summary-table
            [showDataTable]="true"
          ></app-em-eye-exam-summary-table>
        </div>
        <div>
          <div class="data-table-container">
            <div class="table-header-wrapper">
              <div class="summaryHeader bold">Ophthalmology E&M Breakdown by Provider</div>
              <app-zero-suppression-component
                *ngIf="!isSingleProviderSelected"
                [isZeroSuppressionChecked]="isZeroSuppressionChecked"
                [countOfSuppressedEntries]="numberOfSuppressions"
                [condition]="zeroSuppressionCondition"
                [multiLevelBreakdown]="selectedLevelTab"
              ></app-zero-suppression-component>
            </div>
            <app-loader *ngIf="showProgressBar"></app-loader>
            <app-em-snapshot-table [receivedData]="snapshotData" [columns]="snapshotColumns"
                                   [dimension]="selectedDimensionTab"
                                   [fromPdf]="false" [rowSelectionCallback]="whenRowSelectedProvider"
                                   [zeroSuppression]="isZeroSuppressionChecked"
                                   (whenZeroSuppressed)="whenZeroSuppressed($event)"></app-em-snapshot-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="MultiLevelTab.TREND">
    <div *ngIf="overviewPageView">
      <div
        *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Outpatient) || (fromPdf && emMetrics.includes(EmDimension.Outpatient))">
        <app-em-outpatient-multilevel-charts
          [fromPdf]="fromPdf"
          [showProgressBar]="showProgressBar"
          [totalCptCounts]="totalCptCountsProvider"
          [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
          [specialtyCptCounts]="specialtyCptCounts"
          [overviewPageView]="overviewPageView"
          [chartWidth]="400"
          [isSingleProviderSelected]="isSingleProviderSelected"
          [foundNodeName]="foundNodeName"
          [benchmarkOption]="benchmarkOption"
          class="app-percentage-chart"
        >
        </app-em-outpatient-multilevel-charts>
      </div>
      <div
        *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.InPatient) || (fromPdf && emMetrics.includes(EmDimension.InPatient))">
        `
        <app-em-inpatient-multilevel-chart
          [fromPdf]="fromPdf"
          [showProgressBar]="showProgressBar"
          [totalCptCounts]="totalCptCountsProvider"
          [specialtyCptCounts]="specialtyCptCounts"
          [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
          [overviewPageView]="overviewPageView"
          [chartWidth]="400"
          [isSingleProviderSelected]="isSingleProviderSelected"
          [foundNodeName]="foundNodeName"
          [benchmarkOption]="benchmarkOption"
          class="app-percentage-chart"
        >
        </app-em-inpatient-multilevel-chart>
      </div>
      <div
        *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.EmergencyMedicine) || (fromPdf && emMetrics.includes(EmDimension.EmergencyMedicine))">
        <app-em-emergency-room-multilevel-charts
          [fromPdf]="fromPdf"
          [showProgressBar]="showProgressBar"
          [totalCptCounts]="totalCptCountsProvider"
          [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
          [specialtyCptCounts]="specialtyCptCounts"
          [overviewPageView]="overviewPageView"
          [chartWidth]="1270"
          [isSingleProviderSelected]="isSingleProviderSelected"
          [foundNodeName]="foundNodeName"
          [benchmarkOption]="benchmarkOption"
          class="app-percentage-chart"
        ></app-em-emergency-room-multilevel-charts>
      </div>
      <div
        *ngIf="(!fromPdf && selectedDimensionTab === EmDimension.Ophthalmology) || (fromPdf && emMetrics.includes(EmDimension.Ophthalmology))">
        <app-em-eye-exam-multilevel-chart
          [fromPdf]="fromPdf"
          [showProgressBar]="showProgressBar"
          [totalCptCounts]="totalCptCountsProvider"
          [chartHeight]="overviewPageView ?overviewHeight: EandMHeight"
          [specialtyCptCounts]="specialtyCptCounts"
          [overviewPageView]="overviewPageView"
          [chartWidth]="600"
          [isSingleProviderSelected]="isSingleProviderSelected"
          [foundNodeName]="foundNodeName"
          [benchmarkOption]="benchmarkOption"
          class="app-percentage-chart"
        >
        </app-em-eye-exam-multilevel-chart>
      </div>
    </div>
    <app-em-page-trend
      *ngIf="!overviewPageView"
      [specialtyCptCounts]="specialtyCptCounts"
      [dimension]="selectedDimensionTab"
      [filters]="filters"
      [evaluationManagementMultilevel]="providerEvaluationManagementMultilevelData"
      [foundNodeName]="foundNodeName">
    </app-em-page-trend>
  </div>
</div>
