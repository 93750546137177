import {Component, Input, OnChanges} from '@angular/core';
import {
  CptCategories,
  CptSummary,
  EmDimension,
  EvaluationManagementMultilevel,
  Legend,
  ProviderMultilevelCptCounts
} from '../../../../shared/models';
import {
  EvaluationManagementEmergencyMultilevelRow,
  getDefaultCptSummary,
  getEmSnapshotColumnsWithExtendedBenchmarkOptions,
  getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions,
} from '../../../../evaluation-management/em-helpers';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {BenchmarkOption, MultilevelTab} from '../../../../shared/enums';
import {DataTableColumns} from '../../../../shared/data-table-columns';
import {MetricType} from '../../../../shared/metric-types';
import {getEmLegends} from '../../../../evaluation-management/em-chart-helpers';

@Component({
  selector: 'app-em-emergency-medicine-batch-export',
  templateUrl: './em-emergency-medicine-batch-export.component.html',
  styleUrls: ['./em-emergency-medicine-batch-export.component.scss']
})
export class EmEmergencyMedicineBatchExportComponent implements OnChanges {

  @Input() totalCptCounts?: CptCategories;
  @Input() specialtyCptCounts?: CptCategories;
  @Input() showProgressBar: boolean;
  @Input() chartHeight: number;
  @Input() overviewPageView: boolean;
  @Input() chartWidth: number;
  @Input() isSingleProviderSelected: boolean;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() receivedTableData: EvaluationManagementEmergencyMultilevelRow[];
  @Input() showTable = false;
  @Input() batchMultilevelEMData: EvaluationManagementMultilevel | undefined = undefined;
  columns: DataTableColumns[] = [];
  tableData: ProviderMultilevelCptCounts[] = [];
  MultilevelTab = MultilevelTab;

  @select(['display', 'isProviderSelected'])
  private readonly isProviderSelected$: Observable<boolean>;

  emergencyMedicineCategories = ['99281', '99282', '99283', '99284', '99285'];
  totalEmergencyMedicineCptSummaries: (CptSummary | undefined)[];
  emergencyMedicineCptMaxValue = 0;
  specialtyTotalEmergencyMedicineCptSummaries: (CptSummary | undefined)[];
  specialtyEmergencyMedicineCptMaxValue = 0;
  maxYAxisValue: number;
  legends: Legend[];
  readonly metricType = MetricType.EandMEmergency;

  constructor() {
  }

  ngOnChanges(): void {
    this.setUpTable();
    this.chartHeight = this.overviewPageView ? 250 : this.chartHeight;
    this.legends = getEmLegends(this.foundNodeName, this.isSingleProviderSelected);

    if (!this.totalCptCounts) {
      this.totalEmergencyMedicineCptSummaries = [];
      this.emergencyMedicineCategories.forEach(x => {
        this.totalEmergencyMedicineCptSummaries.push(getDefaultCptSummary());
      });

      this.emergencyMedicineCptMaxValue = 0;
      return;
    }

    if (!this.specialtyCptCounts) {
      this.specialtyTotalEmergencyMedicineCptSummaries = [];
      this.emergencyMedicineCategories.forEach(x => {
        this.specialtyTotalEmergencyMedicineCptSummaries.push(getDefaultCptSummary());
      });

      this.specialtyEmergencyMedicineCptMaxValue = 0;
    }


    if (this.totalCptCounts.emergencyMedicine) {
      this.totalEmergencyMedicineCptSummaries = [
        this.totalCptCounts.emergencyMedicine.cpt99281,
        this.totalCptCounts.emergencyMedicine.cpt99282,
        this.totalCptCounts.emergencyMedicine.cpt99283,
        this.totalCptCounts.emergencyMedicine.cpt99284,
        this.totalCptCounts.emergencyMedicine.cpt99285
      ];
      this.emergencyMedicineCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalEmergencyMedicineCptSummaries, this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.emergencyMedicine) {
      this.specialtyTotalEmergencyMedicineCptSummaries = [
        this.specialtyCptCounts.emergencyMedicine.cpt99281,
        this.specialtyCptCounts.emergencyMedicine.cpt99282,
        this.specialtyCptCounts.emergencyMedicine.cpt99283,
        this.specialtyCptCounts.emergencyMedicine.cpt99284,
        this.specialtyCptCounts.emergencyMedicine.cpt99285
      ];
      this.specialtyEmergencyMedicineCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalEmergencyMedicineCptSummaries, this.benchmarkOption);
    }
    this.maxYAxisValue = Math.max(this.emergencyMedicineCptMaxValue, this.specialtyEmergencyMedicineCptMaxValue);
  }

  private setUpTable(): void {
    this.columns =
      getEmSnapshotColumnsWithExtendedBenchmarkOptions(EmDimension.EmergencyMedicine, this.benchmarkOption, MultilevelTab.BY_PROVIDER);
    this.tableData = this.batchMultilevelEMData?.evaluationManagementData.providerEvaluationManagementSnapshotData
      .nodes || [];
  }
}
