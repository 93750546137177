<div id="location-component-wrapper"><app-loader *ngIf="displayLocationLoadingIndicator"></app-loader>
  <div id="select-location-header">
    <div id="upper-header">Location</div>
  </div>
  <div id="select-location-body">
    <div id="input-location-search-wrapper">
      <input type="search" placeholder="Search..." id="search-for-location"
             (input)="onLocationSearchTextChanged($event)" [ngModel]="locationSearchText">
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>
    <div id="location-options">
      <mat-radio-group>
        <mat-radio-button class="all-locations-option"
                          (click)="selectAllLocations()"
                          [value]="DEFAULT_MEMBER_LOCATION"
                          [checked]="selectedMemberLocation.memberLocationKey === DEFAULT_MEMBER_LOCATION.memberLocationKey"
                          [class.active]="selectedMemberLocation.memberLocationKey === DEFAULT_MEMBER_LOCATION.memberLocationKey"
        >Select All Locations
        </mat-radio-button>
        <span id="scrollable-options">
          <mat-radio-button class="location-option"
                            *ngFor="let locationOption of memberLocationsForTable"
                            (click)="selectMemberLocation(locationOption, $event)"
                            [value]="locationOption.location"
                            [checked]="locationOption.location.memberLocationKey === selectedMemberLocation.memberLocationKey"
                            [class.active]="locationOption.location.memberLocationKey === selectedMemberLocation.memberLocationKey"
                            id="{{locationOption.location.memberLocationName}}">{{locationOption.location.memberLocationName}}
        </mat-radio-button>
        </span>
      </mat-radio-group>
    </div>
  </div>
</div>
