
<div class="emergency-room-summary-table" *ngIf="displayedData">
<div class="table-header-wrapper">
  <div class="summaryHeader bold">Emergency Medicine E&M Summary</div>
</div>

  <mat-table [dataSource]="dataSource">
    <ng-container  matColumnDef="encounter">
      <mat-header-cell *matHeaderCellDef class="headerEncounter">Metrics</mat-header-cell>
      <mat-cell *matCellDef="let row" class="encounter">{{row.encounterMetric}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99281">
      <mat-header-cell *matHeaderCellDef class="header99281">99281</mat-header-cell>
      <mat-cell *matCellDef="let row"  class="countOf99281">{{isValidOrElse(row, 'emergencyRoomPatients', 'cpt99281', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99282">
      <mat-header-cell *matHeaderCellDef class="header99282">99282</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99282">{{isValidOrElse(row, 'emergencyRoomPatients', 'cpt99282', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99283">
      <mat-header-cell *matHeaderCellDef class="header99283">99283</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99283">{{isValidOrElse(row, 'emergencyRoomPatients', 'cpt99283', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99284">
      <mat-header-cell *matHeaderCellDef class="header99284">99284</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99284">{{isValidOrElse(row, 'emergencyRoomPatients', 'cpt99284', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countOf99285">
      <mat-header-cell *matHeaderCellDef class="header99285">99285</mat-header-cell>
      <mat-cell *matCellDef="let row" class="countOf99285">{{isValidOrElse(row, 'emergencyRoomPatients', 'cpt99285', '-')}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalEmergencyMedicine">
      <mat-header-cell *matHeaderCellDef class="headerTotalEmergencyMedicine">Total</mat-header-cell>
      <mat-cell *matCellDef="let row" class="totalEmergencyMedicine">{{isValidOrElse(row, 'emergencyRoomPatients', 'total', '-')}}
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row  *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <app-loader  [class.loader-hidden]="!showProgressBar"></app-loader>

</div>

