import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedNewPatientVisitSnapshotEntryInSingleLocation,
  NpvLocationWithSnapshotEntries,
  totalNpvLocationColumnId
} from '../../../new-patient-visits/components/npv-models';
import {DataTableColumns} from '../../data-table-columns';
import {ColumnType} from '../../enums';
import {getDefaultHoverIndex} from '../../constants';
import {NUMBER_OF_ROWS_BEFORE_SCROLLING} from '../../../new-patient-visits/components/npv-helpers';

@Component({
  selector: 'app-node-list-container',
  templateUrl: './node-list-container.component.html',
  styleUrls: ['./node-list-container.component.scss']
})
export class NodeListContainerComponent implements AfterViewInit {
  readonly ColumnType = ColumnType;
  readonly DEFAULT_HOVER_INDEX = getDefaultHoverIndex();
  readonly TOKEN_HEIGHT = 18;
  readonly DEFAULT_NUMBER_OF_LINES = 2;
  readonly DEFAULT_DATA_ROW_HEIGHT = 68;
  readonly DATA_TEXT_MARGIN = 16;
  readonly totalColumnId = totalNpvLocationColumnId;
  readonly excludedColumnTypesInTotals: ColumnType[] = [ColumnType.BENCHMARK, ColumnType.VARIANCE];
  @Input() row: NpvLocationWithSnapshotEntries;
  @Input() column: DataTableColumns;
  @Input() onlyIncludeTopRows = false;
  @Input() CUT_OFF: number;
  @Input() isDrillable = true;
  @Input() hoverIndex = this.DEFAULT_HOVER_INDEX;
  @Output() OnNodeSelect = new EventEmitter<MergedNewPatientVisitSnapshotEntry>();
  @Output() OnHoverIndexChanged = new EventEmitter<number>();
  @Output() afterTableHeightDetermined = new EventEmitter<number>();
  nodeIndexer = '';
  numberOfDataRowLines = this.DEFAULT_NUMBER_OF_LINES;
  tooltipMessage: string | undefined = undefined;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    switch (this.column.columnType) {
      case ColumnType.NODE_NAME:
        this.nodeIndexer = this.column.columnDef.replace('Name', 'Id');
        this.cdr.detectChanges();
        let containerHeight = 0;
        for (let i = 0; i < this.row.snapshotEntries.length; i++) {
          const nodeId = this.row.snapshotEntries[i].isTotal ? this.totalColumnId :
            this.row.snapshotEntries[i][`${this.column.columnDef.replace('Name', 'Id')}`];
          const asElement = document.getElementById((nodeId || '').toString());
          if (asElement) {
            this.setHeightAndTooltipForHtmlElement(asElement, this.row.snapshotEntries[i]);
          }
          const heightDeterminant = document.getElementById((this.row.snapshotEntries[i].designatedColumnId || ''));
          if (heightDeterminant) {
            this.row.snapshotEntries[i].height = this.getHeightForElement(heightDeterminant);
            this.cdr.detectChanges();
            if (i < NUMBER_OF_ROWS_BEFORE_SCROLLING) {
              containerHeight += this.row.snapshotEntries[i].height || 0;
            }
          }
        }
        if (this.onlyIncludeTopRows) {
          this.afterTableHeightDetermined.emit(containerHeight);
        }
        break;
      case ColumnType.BENCHMARK:
        this.tooltipMessage = 'Benchmarks are not available in the Totals row';
        break;
      case ColumnType.VARIANCE:
        this.tooltipMessage = 'Variances are not available in the Totals row';
        break;
      default:
        this.tooltipMessage = undefined;
    }
  }

  private getHeightForElement(theElement: HTMLElement): number {
    return (2 * this.DATA_TEXT_MARGIN) + theElement.offsetHeight;
  }

  private setHeightAndTooltipForHtmlElement(theElement: HTMLElement,
                                            entry: MergedNewPatientVisitSnapshotEntryInSingleLocation): void {
    if (theElement.scrollHeight > theElement.offsetHeight) {
      const matTooltip = entry[`${this.column.columnDef}`];
      const columnsWithTooltips = entry.columnsWithTooltips;
      if (columnsWithTooltips) {
        columnsWithTooltips.set(this.column.columnDef, matTooltip);
        this.cdr.detectChanges();
      } else {
        const tooltipMap: Map<string, string> = new Map<string, string>();
        tooltipMap.set(this.column.columnDef, matTooltip);
        entry.columnsWithTooltips = tooltipMap;
        this.cdr.detectChanges();
      }
    }
  }

  whenNodeSelected(item: MergedNewPatientVisitSnapshotEntry): void {
    this.OnNodeSelect.emit(item);
  }

  changeHoverIndex(hoverIndex: number) {
    this.OnHoverIndexChanged.emit(hoverIndex);
  }
}
