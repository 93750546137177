<div class="range-calendar-wrapper" [class.range-calendar-wrapper-single]="!rangeMode">
  <div class="from-date-sec" [class.from-date-sec-single]="!rangeMode">
    <sat-calendar cdkTrapFocus
                  [id]="id"
                  [ngClass]="ngClass"
                  [startAt]="startAt"
                  [startView]="startView"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [dateFilter]="dateFilter"
                  [beginDate]="beginDate"
                  [endDate]="endDate"
                  [beginMonth]="beginMonth"
                  [endMonth]="endMonth"
                  [rangeMode]="rangeMode"
                  [typeMode]="typeMode"
                  [selected]="selected"
                  (selectedChange)="selectedChange.emit($event)"
                  (dateSelected)="_dateSelected($event)"
                  (yearSelected)="yearSelected.emit($event)"
                  (monthSelected)="monthSelected.emit($event)"
                  (monthRangesChange)="_rangeMonthSelected($event)"
                  calenderType="startCalender"></sat-calendar>
  </div>
  <div class="end-date-sec" *ngIf="rangeMode == true">
    <sat-calendar cdkTrapFocus
                  [id]="id"
                  [ngClass]="ngClass"
                  [startAt]="startAt"
                  [startView]="startView"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [dateFilter]="dateFilter"
                  [beginDate]="beginDate"
                  [endDate]="endDate"
                  [beginMonth]="beginMonth"
                  [endMonth]="endMonth"
                  [rangeMode]="rangeMode"
                  [typeMode]="typeMode"
                  [selected]="selected"
                  (selectedChange)="selectedChange.emit($event)"
                  (dateSelected)="_dateSelected($event)"
                  (yearSelected)="yearSelected.emit($event)"
                  (monthSelected)="monthSelected.emit($event)"
                  (monthRangesChange)="_rangeMonthSelected($event)"
                  [nextMonth]="true"
                  calenderType="endCalender"></sat-calendar>
  </div>
</div>

<div class="quick-select-sec" *ngIf="rangeMode == true">
  <span class="select-title">Quick Select</span>
  <mat-radio-group id="date-radio-group">
    <div id="generic-dates">
      <mat-radio-button id="past12MonthBtn"
                        value="1"
                        (click)="selectDateOption(DateRangeOption.Past12Months)"
                        [class.active]="selectedDateRangeOption === DateRangeOption.Past12Months"
                        [checked]="selectedDateRangeOption === DateRangeOption.Past12Months">
        Past 12 Months
      </mat-radio-button>
      <mat-radio-button id="yearToDateBtn"
                        value="2"
                        (click)="selectDateOption(DateRangeOption.YearToDate)"
                        [class.active]="selectedDateRangeOption === DateRangeOption.YearToDate"
                        [checked]="selectedDateRangeOption === DateRangeOption.YearToDate">
        Year To Date
      </mat-radio-button>
      <mat-radio-button id="recentMonthBtn"
                        value="3"
                        (click)="selectDateOption(DateRangeOption.RecentMonth)"
                        [class.active]="selectedDateRangeOption === DateRangeOption.RecentMonth"
                        [hidden]="hideRecentMonth"
                        [checked]="selectedDateRangeOption === DateRangeOption.RecentMonth">
        Recent Month
      </mat-radio-button>
      <mat-radio-button id="past12MonthDataBtn"
                        value="4"
                        (click)="selectDateOption(DateRangeOption.Past12MonthsOfData)"
                        [class.active]="selectedDateRangeOption === DateRangeOption.Past12MonthsOfData"
                        [hidden]="hideRecentMonth"
                        [checked]="selectedDateRangeOption === DateRangeOption.Past12MonthsOfData">Past 12 Months of Data
      </mat-radio-button>
    </div>

    <div id="fiscal-dates">
      <mat-radio-button
                        id="fiscalYTD"
                        value="5"
                        [class.active]="selectedDateRangeOption === DateRangeOption.FiscalYearToDate"
                        [checked]="selectedDateRangeOption === DateRangeOption.FiscalYearToDate"
                        (click)="selectDateOption(DateRangeOption.FiscalYearToDate)">
        <div class="top-half-radio-button">
          Fiscal Year To Date
          <span id="change-fiscal-year" (click)="openChangeFiscalYear($event)">Change Fiscal Year</span>
        </div>
        <div class="bottom-half-radio-button">{{ytdFiscalLabel}}</div>
      </mat-radio-button>
      <mat-radio-button
                        id="quarter-of-fiscal"
                        value="6"
                        [checked]="selectedDateRangeOption >= DateRangeOption.FiscalYearQ1 && selectedDateRangeOption <= DateRangeOption.FiscalYearQ4"
                        (change)="selectFiscalQuarter(selectedQuarter)">
        <div class="top-half-radio-button">
        <span (click)="toggleFiscalQuarterOptions($event)" id="toggle-fiscal-quarter">{{selectedFiscalQuartersText}}
          <i class="fa fa-caret-down"></i>
        </span> of Fiscal Year
          <span class="fiscal-options">
          <mat-form-field id="fiscal-year-options" appearance="outline" subscriptSizing="dynamic">
            <mat-select disableOptionCentering panelClass="fiscal-options-dropdown"
                        placeholder="{{selectedFiscalYear}}"
                        [(value)]="selectedFiscalYear"
                        (click)="closeFiscalQuarterOptions()"
                        hideSingleSelectionIndicator>
              <mat-option *ngFor="let year of fiscalYears"
                        class="fiscal-year-option"
                        (click)="selectFiscalYear(year, $event)"
                        [value]="year">{{year}}</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        </div>
        <div class="bottom-half-radio-button">{{quarterFiscalLabel}}</div>
        <div *ngIf="showFiscalQuarterDropdown"
             id="fiscal-quarter-options"
             class="fiscal-options">
        <span *ngIf="showFiscalQuarterHover" class="fiscal-quarter-hover">
          <i class="fa fa-info-circle"></i>
          Please select consecutive quarters only
        </span>
          <mat-checkbox *ngFor="let option of fiscalQuarterOptions"
                        (click)="selectFiscalQuarter(option, $event)"
                        [disabled]="numberOfQuartersSelected > 0
                      && (option.option < selectedQuarter.option - 1 || selectedQuarter.option + numberOfQuartersSelected < option.option)"
                        [checked]="option.selected"
                        class="fiscal-quarter-option">{{option.text}}</mat-checkbox>
        </div>
      </mat-radio-button>
    </div>
  </mat-radio-group>
</div>
<div class="calendar-button-sec" *ngIf="!filterBannerFlag">
  <button mat-button color="primary" (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onApply()">Apply</button>
</div>
