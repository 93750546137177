import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {CptViewType, MultilevelTab, MultilevelTabCollections} from '../shared/enums';
import {getAppConfigValue} from '../shared/helpers';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {ActionType, selectedNodesChangedTo} from '../store/actions';
import {EmDimension} from '../shared/models';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {getSelectedNodeCountsFromNodePath} from '../shared/ontology-helpers';
import {filter} from 'rxjs/operators';
import {TabNavigationElement, TabNavigationElementDimension} from './tab-helper';

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})

export class TabNavigationComponent implements OnInit, OnChanges, OnDestroy {

  @select(['display', 'selectedProviders'])
  private readonly selectedProviders$: Observable<number>;

  @select(['display', 'selectedSpecialties'])
  private readonly selectedSpecialties$: Observable<number>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['ontologyLoaded'])
  private readonly ontologyLoaded$: Observable<boolean>;

  @Input() isRevenueTab = false;
  @Input() isClinicalTab = false;
  @Input() isEmDimensionTab = false;
  @Input() tabs: TabNavigationElement[];
  @Input() chosenTab: TabNavigationElementDimension;
  @Input() tabUpdateAction: (tab: TabNavigationElementDimension) => {
    denialsChosenTab?: MultilevelTabCollections,
    collectionsChosenTab?: MultilevelTabCollections,
    multilevelTab?: MultilevelTab,
    cptViewType?: CptViewType,
    emDimension?: EmDimension,
    type: ActionType
  };

  subscription: Subscription;
  ontologyLoaded: boolean;
  moreThanMaxProvidersSelected: boolean;
  providerPermitted: boolean;
  maxProvidersMessage: string;
  moreThanMaxSpecialtiesSelected: boolean;
  specialtyPermitted: boolean;
  maxSpecialtiesMessage: string;
  providersSelected: number;
  specialtiesSelected: number;
  maxProviders = 500;
  maxSpecialties = 500;

  constructor(private ngRedux: NgRedux<IAppState>, @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
    const appConfigSettings = this.ngRedux.getState().data.applicationConfigurationSettings;

    const maxProviders = getAppConfigValue(AppConfigEntries.MAX_PROVIDER_PATHS, appConfigSettings);
    this.maxProviders = maxProviders?.length > 0 ? Number(maxProviders) : this.maxProviders;
    this.maxProvidersMessage = `Please select fewer than ${this.maxProviders} "Selected Providers" in the navigation drop down`;

    const maxSpecialties = getAppConfigValue(AppConfigEntries.MAX_SPECIALTY_PATHS, appConfigSettings);
    this.maxSpecialties = maxSpecialties?.length > 0 ? Number(maxSpecialties) : this.maxSpecialties;
    this.maxSpecialtiesMessage =
      `Please select fewer than ${this.maxSpecialties} department/specialty combinations in the navigation drop down`;

    this.subscription = combineLatest([
      this.selectedProviders$,
      this.selectedSpecialties$,
      this.nodePath$,
      this.ontologyLoaded$.pipe(filter((loaded: boolean) => loaded))])
      .subscribe(([providersSelected, specialtiesSelected, nodePath, ontologyLoaded]) => {
        this.providersSelected = providersSelected || 0;
        this.specialtiesSelected = specialtiesSelected || 0;
        this.ontologyLoaded = ontologyLoaded;
        if (this.providersSelected === 0 || this.specialtiesSelected === 0) {
          const nodeCounts = getSelectedNodeCountsFromNodePath(nodePath);
          this.ngRedux.dispatch(selectedNodesChangedTo(nodeCounts.selectedProviders, nodeCounts.selectedSpecialties));
        }
        this.ngOnChanges();
      });
  }

  ngOnChanges(): void {
    this.moreThanMaxProvidersSelected = this.providersSelected > this.maxProviders || this.providersSelected === 0;
    this.moreThanMaxSpecialtiesSelected = this.specialtiesSelected > this.maxSpecialties || this.specialtiesSelected === 0;
    this.updateTabsWithMaxSelections();

    if (this.ontologyLoaded && this.selectedTabIsDisabled(this.chosenTab)) {
      this.chooseTab(this.getDefaultTab(this.isRevenueTab, this.chosenTab));
    }
  }

  private getDefaultTab(isRevenue: boolean, tab: TabNavigationElementDimension):
    TabNavigationElementDimension {
    if (!isRevenue) {
      switch (tab) {
        case MultilevelTab.LOCATION_PROVIDER:
        case MultilevelTab.LOCATION_SPECIALTY:
          return MultilevelTab.LOCATION_DEPARTMENT;
        case MultilevelTab.BY_PROVIDER:
        case MultilevelTab.SPECIALTY_PERFORMANCE:
        case MultilevelTab.BY_SPECIALTY:
          return MultilevelTab.BY_DEPARTMENT;
      }
    } else {
      switch (tab) {
        case MultilevelTabCollections.BY_PROVIDER:
        case MultilevelTabCollections.BY_SPECIALTY:
          return MultilevelTabCollections.BY_DEPARTMENT;
      }
    }
    return tab;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  updateTabsWithMaxSelections() {
    if (this.isClinicalTab || this.isEmDimensionTab) {
      return;
    }
    this.tabs.forEach(tab => {
      if (!this.isRevenueTab) {
        switch (tab.dimension) {
          case MultilevelTab.BY_SPECIALTY:
          case MultilevelTab.LOCATION_SPECIALTY:
            tab.tooltipMessage = this.moreThanMaxSpecialtiesSelected ? this.maxSpecialtiesMessage : '';
            tab.disabled = this.moreThanMaxSpecialtiesSelected;
            break;
          case MultilevelTab.BY_PROVIDER:
          case MultilevelTab.LOCATION_PROVIDER:
          case MultilevelTab.SPECIALTY_PERFORMANCE:
            tab.tooltipMessage = this.moreThanMaxProvidersSelected ? this.maxProvidersMessage : '';
            tab.disabled = this.moreThanMaxProvidersSelected;
            break;
        }
      } else {
        switch (tab.dimension) {
          case MultilevelTabCollections.BY_SPECIALTY:
            tab.tooltipMessage = this.moreThanMaxSpecialtiesSelected ? this.maxSpecialtiesMessage : '';
            tab.disabled = this.moreThanMaxSpecialtiesSelected;
            break;
          case MultilevelTabCollections.BY_PROVIDER:
            tab.tooltipMessage = this.moreThanMaxProvidersSelected ? this.maxProvidersMessage : '';
            tab.disabled = this.moreThanMaxProvidersSelected;
            break;
        }
      }
    });
  }

  chooseTab(tab: TabNavigationElementDimension) {
    if (this.selectedTabIsDisabled(tab)) {
      return;
    }
    if (tab === MultilevelTab.SPECIALTY_PERFORMANCE && !this.isRevenueTab) {
      this.analyticsService.handleGoogleAnalytics('Specialty Performance', 'Navigate', 'Navigate');
    }
    this.ngRedux.dispatch(this.tabUpdateAction(tab));
  }

  selectedTabIsDisabled(tab: TabNavigationElementDimension): boolean {
    if (this.isClinicalTab || this.isEmDimensionTab) {
      return false;
    }
    if (!this.isRevenueTab) {
      switch (tab) {
        case MultilevelTab.BY_PROVIDER:
        case MultilevelTab.SPECIALTY_PERFORMANCE:
        case MultilevelTab.LOCATION_PROVIDER:
          return this.moreThanMaxProvidersSelected;
        case MultilevelTab.BY_SPECIALTY:
        case MultilevelTab.LOCATION_SPECIALTY:
          return this.moreThanMaxSpecialtiesSelected;
        default:
          return false;
      }
    } else {
      switch (tab) {
        case MultilevelTabCollections.BY_PROVIDER:
          return this.moreThanMaxProvidersSelected;
        case MultilevelTabCollections.BY_SPECIALTY:
          return this.moreThanMaxSpecialtiesSelected;
        default:
          return false;
      }
    }
  }
}
