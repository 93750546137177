<div id="em-trend-wrapper">
  <div [ngSwitch]="dimension" class="summary-tables-wrapper">
    <div id="outpatient-wrapper" *ngSwitchCase="EmDimension.Outpatient">
      <app-em-trend-chart [fromPdf]="false"
                          [data]="emTrendData"
                          [specialtyCptCounts]="specialtyCptCounts"
                          [dimension]="EmDimension.Outpatient"
                          [foundNodeName]="foundNodeName"
                          [showLoadingIndicator]="showLoader"
                          [benchmarkOption]="benchmarkOption"
      ></app-em-trend-chart>
      <div class="space-maker"></div>
      <app-em-outpatient-summary-table class="table-wrapper" [showDataTable]="true"></app-em-outpatient-summary-table>
      <app-em-trend-table id="outpatient-trend-table"
                          class="table-wrapper"
                          [receivedData]="emTrendData" [columns]="emOutpatientTrendColumns"
                          [showLoadingIndicator]="showLoader" [tableCssClass]="'outpatient-trend'"
                          header="Outpatient E&M Breakdown by Month">
      </app-em-trend-table>
    </div>
    <div id="inpatient-wrapper" *ngSwitchCase="EmDimension.InPatient">
      <app-em-trend-chart [fromPdf]="false" [data]="emTrendData"
                          [specialtyCptCounts]="specialtyCptCounts"
                          [dimension]="EmDimension.InPatient"
                          [foundNodeName]="foundNodeName"
                          [showLoadingIndicator]="showLoader"
                          [benchmarkOption]="benchmarkOption"
      ></app-em-trend-chart>
      <div class="space-maker"></div>
      <app-em-inpatient-summary-table class="table-wrapper" [showDataTable]="true"></app-em-inpatient-summary-table>
      <app-em-trend-table id="inpatient-trend-table"
                          class="table-wrapper"
                          [receivedData]="emTrendData" [columns]="emInpatientTrendColumns"
                          [showLoadingIndicator]="showLoader" [tableCssClass]="'inpatient-trend'"
                          header="Inpatient E&M Breakdown by Month">
      </app-em-trend-table>
    </div>
    <div id="emergency-medicine-wrapper" *ngSwitchCase="EmDimension.EmergencyMedicine">
      <app-em-trend-chart [fromPdf]="false" [data]="emTrendData" [specialtyCptCounts]="specialtyCptCounts"
                          [dimension]="EmDimension.EmergencyMedicine"
                          [foundNodeName]="foundNodeName"
                          [showLoadingIndicator]="showLoader"
                          [benchmarkOption]="benchmarkOption"
      ></app-em-trend-chart>
      <div class="space-maker"></div>
      <app-em-emergency-room-summary-table [showDataTable]="true"></app-em-emergency-room-summary-table>
      <app-em-trend-table id="emergency-medicine-trend-table"
                          class="table-wrapper"
                          [receivedData]="emTrendData" [columns]="emEmergencyMedicineColumns"
                          [showLoadingIndicator]="showLoader" [tableCssClass]="'emergency-trend'"
                          header="Emergency Medicine E&M Breakdown by Month">
      </app-em-trend-table>
    </div>
    <div id="ophthalmology-wrapper" *ngSwitchCase="EmDimension.Ophthalmology">
      <app-em-trend-chart [fromPdf]="false" [data]="emTrendData" [specialtyCptCounts]="specialtyCptCounts"
                          [dimension]="EmDimension.Ophthalmology"
                          [foundNodeName]="foundNodeName"
                          [showLoadingIndicator]="showLoader"
                          [benchmarkOption]="benchmarkOption"
      ></app-em-trend-chart>
      <div class="space-maker"></div>
      <app-em-eye-exam-summary-table [showDataTable]="true"></app-em-eye-exam-summary-table>
      <app-em-trend-table id="ophthalmology-trend-table"
                          class="table-wrapper"
                          [receivedData]="emTrendData" [columns]="emOphthalmologyTrendColumns"
                          [showLoadingIndicator]="showLoader" [tableCssClass]="'eye-exam-trend'"
                          header="Ophthalmology E&M Breakdown by Month">
      </app-em-trend-table>
    </div>
  </div>
</div>
