import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {npvSnapshotPageRoute} from '../../../../shared/routes';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, IAppState, INITIAL_STATE, SummaryData} from '../../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {NewPatientVisitSummary} from '../../../../shared/models';
import {DisplayField, Variable} from '../../../../variable-container/variable-container.component';
import {
  AppAction,
  newPatientVisitTrendEntriesChangedTo,
  patientVisitsCountsDisplayChangedTo,
  previousPatientVisitsCountsDisplayChangedTo,
  trendNPVColumnsChangedTo
} from '../../../../store/actions';
import * as _ from 'lodash';
import {getLevelFromNodePath, getLevelTypeNodePath, NodePathLevelType, Page} from '../../../../shared/helpers';
import {distinctUntilChanged} from 'rxjs/operators';
import {checkForNulls} from '../../../../shared/null-helpers';
import {AnalyticsService, AnalyticsServiceToken} from '../../../../analytics/analytics.service';
import {BenchmarkOption, ColumnType} from '../../../../shared/enums';
import {
  BenchmarkPercentile,
  readableNameOfColumnDef,
  readableNameOfColumnDefWithVisitTypeBenchmark
} from '../../../../shared/benchmark-types';
import {
  getDesignatedNpvBenchmarkObject,
  getDesignatedNpvVarianceObject,
  replaceNpvTrendBenchmarkColumnWithBenchmarkFor,
  replaceNpvTrendVarianceColumnWithBenchmarkFor
} from '../../npv-helpers';
import {columnsForNPVtrend, NewPatientVisitTrendEntry} from '../../npv-models';
import {DataTableColumns} from '../../../../shared/data-table-columns';

@Component({
  selector: 'app-new-patient-visits-by-month-page',
  templateUrl: './new-patient-visits-by-month-page.component.html',
  styleUrls: ['./new-patient-visits-by-month-page.component.scss']
})
export class NewPatientVisitsByMonthPageComponent implements OnInit, OnDestroy {

  @Input() fromPdfExport = false;
  @Input() isMultilevel = true;
  @Input() showExtendedBenchmarkOptions = false;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['data', 'newPatientVisitTrendData'])
  private readonly newPatientVisitTrend$: Observable<NewPatientVisitTrendEntry[]>;

  @select(['data', 'summaryNewPatientVisitData'])
  private readonly newPatientVisitSummary$: Observable<SummaryData<NewPatientVisitSummary>>;

  @select(['display', 'isCustom'])
  private readonly isCustom$: Observable<boolean>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly benchmarkOption$: Observable<BenchmarkOption>;

  @select(['display', 'displayedNPVTrendColumns'])
  private readonly displayedNPVTrendColumns$: Observable<BaseColumn[]>;

  defaultSortColumn = 'year';
  snapshotPageRoute = npvSnapshotPageRoute;
  tableMonth: NewPatientVisitTrendEntry[];
  isSpecialty = false;
  isDepartment = false;
  currentPage = Page.NPVTrend;
  benchmarkOption: BenchmarkOption;
  trendNPVColumnsChangedTo = trendNPVColumnsChangedTo;
  columns: DataTableColumns[] = columnsForNPVtrend().slice();
  displayedColumns: DataTableColumns[] = columnsForNPVtrend().slice();
  variables: Variable[] = [{
    name: '# New and Total Patients',
    display: false,
    reducerField: DisplayField.PatientVisitCounts,
    dispatchAction(display: boolean): AppAction {
      return patientVisitsCountsDisplayChangedTo(display);
    }
  },
    {
      name: 'Previous Date Range % Patient Visits',
      display: false,
      reducerField: DisplayField.PreviousPatientVisitCounts,
      dispatchAction(display: boolean): AppAction {
        return previousPatientVisitsCountsDisplayChangedTo(display);
      }
    }];
  showProgressBar: boolean;
  benchmarkPercentile: BenchmarkPercentile;
  level: string;
  isProvider: boolean;
  subscription: Subscription;

  viewCommunityBenchmarks: boolean;

  constructor(private ngRedux: NgRedux<IAppState>, private cdr: ChangeDetectorRef,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.newPatientVisitTrend$.pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2))),
      this.benchmarkPercentile$,
      this.benchmarkOption$,
      this.isCustom$,
      this.nodePath$,
      this.viewCommunityBenchmarks$,
      this.displayedNPVTrendColumns$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
    ])
      .subscribe((
          [npvTrendData, bchmkPercentileFromStore, benchmarkOption, isCustom, nodePath, viewCommunityBenchmarks, displayedColumns]:
            [NewPatientVisitTrendEntry[], BenchmarkPercentile, BenchmarkOption, boolean, string, boolean, BaseColumn[]]) => {
          this.viewCommunityBenchmarks = viewCommunityBenchmarks;
          this.benchmarkOption = this.showExtendedBenchmarkOptions ? benchmarkOption :
            (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
          this.isProvider = this.ngRedux.getState().display.isProviderSelected;
          const multipleProviders = this.ngRedux.getState().display.multipleProviders;
          const multipleSpecialties = this.ngRedux.getState().display.multipleSpecialties;
          const multipleDepartments = this.ngRedux.getState().display.multipleDepartments;
          this.level = isCustom ? '' : getLevelFromNodePath(nodePath, multipleProviders, multipleSpecialties, multipleDepartments);
          this.showProgressBar = _.isEqual(npvTrendData, INITIAL_STATE.data.newPatientVisitTrendData);
          npvTrendData.forEach(datum => {
            datum.difference = checkForNulls(datum.newPatientVisitsPercentage) - checkForNulls(datum.previous.newPatientVisitsPercentage);
          });
          this.benchmarkPercentile = bchmkPercentileFromStore === BenchmarkPercentile.Percentile65th
            ? BenchmarkPercentile.Mean : bchmkPercentileFromStore;
          this.tableMonth = npvTrendData;
          this.replaceBenchmarkDependencies(this.showExtendedBenchmarkOptions);
          this.updateColumnFilter(displayedColumns);
          this.isSpecialty = getLevelTypeNodePath(nodePath) === NodePathLevelType.specialty && !isCustom;
          this.isDepartment = getLevelTypeNodePath(nodePath) === NodePathLevelType.department && !isCustom;
          this.cdr.detectChanges();
        }
      );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  sortingDataAccessor = (data: NewPatientVisitTrendEntry, sortHeaderId: string): string | number => {
    const columnName = readableNameOfColumnDef(this.benchmarkPercentile);
    if ((sortHeaderId || '').toLowerCase().includes('benchmark')) {
      return data[getDesignatedNpvBenchmarkObject(this.benchmarkOption)][`benchmark${columnName}`];
    }
    if ((sortHeaderId || '').toLowerCase().includes('variance')) {
      return data[getDesignatedNpvVarianceObject(this.benchmarkOption)][`variance${columnName}`];
    }
    if (sortHeaderId === 'year' || sortHeaderId === 'month') {
      return data.year * 12 + +(data.month);
    }
    // @ts-ignore
    return data[sortHeaderId];
  };

  whenSortChanged = (newPatientVisitTrendEntry: NewPatientVisitTrendEntry[], columnDef: string) => {
    this.defaultSortColumn = columnDef;
    this.ngRedux.dispatch(newPatientVisitTrendEntriesChangedTo(newPatientVisitTrendEntry.slice()));
  };

  private replaceBenchmarkDependencies(telehealthEnabled: boolean): void {
    const departmentBenchmarkIndex = this.columns.findIndex(c => c.columnType === ColumnType.BENCHMARK);
    if (departmentBenchmarkIndex >= 0) {
      this.columns[departmentBenchmarkIndex] = replaceNpvTrendBenchmarkColumnWithBenchmarkFor(this.benchmarkPercentile,
        this.benchmarkOption, telehealthEnabled, false);
    }
    const departmentVarianceIndex = this.columns.findIndex(c => c.columnType === ColumnType.VARIANCE);
    if (departmentVarianceIndex >= 0) {
      this.columns[departmentVarianceIndex] = replaceNpvTrendVarianceColumnWithBenchmarkFor(this.benchmarkPercentile,
        this.benchmarkOption, telehealthEnabled, false);
    }
  }

  updateColumnFilter(displayedColumns: BaseColumn[]) {
    const displayedColumnDefs: string[] = displayedColumns.map(col => {
      const readableName = `${readableNameOfColumnDef(this.benchmarkPercentile)}`;
      switch (col.columnType) {
        case ColumnType.BENCHMARK:
          return `${readableNameOfColumnDefWithVisitTypeBenchmark(this.benchmarkPercentile, this.benchmarkOption)}`;
        case ColumnType.VARIANCE:
          return `variance${readableName}`;
        default:
          return col.columnDef;
      }
    });
    this.displayedColumns = this.columns.filter(col => col.primaryColumn || displayedColumnDefs.includes(col.columnDef));
  }
}
