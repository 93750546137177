import {BatchExportScheduleExistingReportResponseEntity} from '../multi-provider-export-redesign/mpe-dialog-helper';
import {BatchExportStatus} from '../../shared/enums';

export function countUnreadInstanceScheduledReports(batchExportScheduleReportDetails:
BatchExportScheduleExistingReportResponseEntity[][]): number {
  let numberOfUnread = 0;
  batchExportScheduleReportDetails?.forEach(scheduled => {
    scheduled?.forEach(instance => {
      if (!instance.readStatus && instance.status === BatchExportStatus.COMPLETED) {
        ++numberOfUnread;
      }
    });
  });
  return numberOfUnread;
}

export class DownloadError extends Error {
  constructor(id: number) {
    super('ERROR downloading file with ID: ' + id);
  }
}

export class UpdateStatusError extends Error {
  constructor(reportId: number) {
    super('ERROR updating exported file status to READ for file ID: ' + reportId);
  }
}
