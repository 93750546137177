<section class="tab-container">
  <form [formGroup]="featureFormGroup" #formDirective="ngForm" (ngSubmit)="onNewFeatureSubmit()"
        class="form-container" data-testid="form">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" hideRequiredMarker>
      <mat-label>New Feature Name</mat-label>
      <input matInput formControlName="featureName" placeholder="New Feature Name" data-testid="input">
    </mat-form-field>
    <ng-container *ngFor="let option of newFeatureToggleOptions">
      <mat-label>{{option.label}}</mat-label>
      <mat-slide-toggle formControlName="{{option.formControlName}}" data-testid="{{option.id}}" id="{{option.id}}"></mat-slide-toggle>
    </ng-container>
    <button mat-button type="submit" data-testid="submitBtn" class="btn" [disabled]="!featureFormGroup.valid">
      Submit</button>
  </form>
  <table mat-table [dataSource]="featureDataSource" class="mat-elevation-z0">
    <ng-container *ngFor="let col of columns">
      <ng-container matColumnDef="{{col.columnDef}}">
        <th mat-header-cell *matHeaderCellDef><span class="header-text-cell">{{col.headerText}}</span></th>
        <td mat-cell *matCellDef="let element">
        <span *ngIf="col.columnDef==='featureName'" class="text-cell"
              data-testid="input-{{element.featureId}}">{{element.featureName}}</span>
          <span *ngIf="col.columnDef==='createdOn'" class="text-cell">{{element.createdOn}}</span>
          <span *ngIf="col.columnDef==='createdBy'" class="text-cell">{{element.createdBy}}</span>
          <span *ngIf="col.columnDef==='lastUpdatedOn'" class="text-cell">{{element.lastUpdatedOn}}</span>
          <span *ngIf="col.columnDef==='lastUpdatedBy'" class="text-cell">{{element.lastUpdatedBy}}</span>
          <mat-slide-toggle *ngIf="col.columnDef==='enabled' || col.columnDef==='enabledInternal'"
                            (change)="col.columnDef==='enabled' ?
                            onExternalToggleChange($event,element):
                            onInternalToggleChange($event,element)"
                            [disabled]="isUpdatingRow(element.featureId)"
                            [checked]="col.columnDef==='enabled' ? element.enabled : element.enabledInternal"
                            data-testid="{{col.id}}"
                            class="{{col.id}}">
            <span class="toggle-label">
              {{getFeatureSettingState(col.columnDef === 'enabled' ? element.enabled : element.enabledInternal)}}
            </span>
          </mat-slide-toggle>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
    <tr mat-row *matRowDef="let row; columns: columnDefs;"
        [ngClass]="{rowUpdate:isUpdatingRow(row.featureId)}"></tr>
  </table>
</section>
