import {Component, Input, OnChanges} from '@angular/core';
import {Options, PointClickEventObject, YAxisOptions} from 'highcharts/highstock';
import {
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  StyledChart
} from '../../../../shared/highcharts-helpers';
import {getBenchmarkSeriesName, getSingularOntologyLevelName} from '../../../../shared/helpers';
import {MetricType} from '../../../../shared/metric-types';
import {CHART_HEIGHT} from '../../../../productivity-summary/productivity-graph-component';
import {GraphColors} from '../../../../productivity-summary/colors';
import {abbreviateAxisValue, roundToNumber} from '../../../../productivity-summary/number-formatter';
import {concat} from '../../../../shared/ourLodash';
import {Chart} from 'angular-highcharts';
import {BenchmarkPercentile} from '../../../../shared/benchmark-types';
import {AddNpvCountsVariableText, NpvByLocationForChart, NpvDrillDown, NpvLocationGraphData} from '../../npv-models';
import {getBenchmarkLegendForNpv, getPercentNpvLegend} from '../../npv-helpers';
import {BenchmarkOption, HighChartColorIndex, MultilevelTab} from '../../../../shared/enums';
import {IAppState, NewPatientVisitsLocationVariables} from '../../../../store/IAppState';
import {aDefaultNewPatientVisitsLocationVariables} from '../../npv-models.spec';
import {Legend} from '../../../../shared/models';
import {DisplayField, Variable} from '../../../../variable-container/variable-container.component';
import {multilevelTabChangedTo, patientVisitsCountsDisplayChangedTo} from '../../../../store/actions';
import {countLegendsForNpvTrend} from '../../month/new-patient-visits-by-month-chart/npv-trend-chart-helper';
import {DrillDownService} from '../../../../services/drilldown.service';
import {NgRedux} from '@angular-redux/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {nextGranularOntologyTab} from '../../../../shared/ontology-helpers';
import {ontologyTabEquivalentOfLocationTab} from '../../../../tab-navigation/tab-helper';

@Component({
  selector: 'app-npv-location-chart',
  templateUrl: './npv-location-chart.component.html',
  styleUrls: ['./npv-location-chart.component.scss'],
})
export class NpvLocationChartComponent implements OnChanges {
  readonly metricType = MetricType;
  @Input() showProgressBar: boolean;
  @Input() benchmarkPercentile = BenchmarkPercentile.Mean;
  @Input() benchmarkOption = BenchmarkOption.Academic;
  @Input() npvLocationChartData: NpvByLocationForChart[] = [];
  @Input() inverseChartData: NpvByLocationForChart[] = [];
  @Input() npvLocationVariables: NewPatientVisitsLocationVariables = aDefaultNewPatientVisitsLocationVariables;
  @Input() showPatientVisitCounts = false;
  @Input() selectedLevelTab: MultilevelTab;
  @Input() showLoader = false;
  levelText = '';
  isDrillable = true;
  options: Options;
  chartObject: Chart;
  private readonly snapshotBarCount = 20;
  private readonly width = calculateChartWidth(DEFAULT_POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING);
  readonly MetricType = MetricType;
  readonly addOnVariables: Variable[] = [{
    name: AddNpvCountsVariableText,
    display: false,
    reducerField: DisplayField.PatientVisitCounts,
    metric: MetricType.NewPatientVisits,
    dispatchAction: patientVisitsCountsDisplayChangedTo
  }];
  legends: Legend[];
  private nodePathDrill: BehaviorSubject<string | undefined>;

  private static getPercentNewPatientVisitsOption(locationNewPatientVisitsPercentages: NpvDrillDown[],
                                                  showPatientVisitCounts: boolean, nodePathDrill: BehaviorSubject<string | undefined>) {
    return {
      name: '% New Patients',
      yAxis: 0,
      data: locationNewPatientVisitsPercentages.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
        createEmptyValues(locationNewPatientVisitsPercentages, DEFAULT_MAX_NUMBER_SCROLLBARS) :
        locationNewPatientVisitsPercentages,
      type: showPatientVisitCounts ? ChartType.LINE : ChartType.COLUMN,
      colorIndex: showPatientVisitCounts ? HighChartColorIndex.GREEN : HighChartColorIndex.TEAL,
      pointWidth: DEFAULT_POINT_WIDTH,
      stack: 0,
      events: {
        click: (event: PointClickEventObject) => {
          // @ts-ignore
          nodePathDrill.next(event.point.drillDown);
        }
      },
      zIndex: 1,
      tooltip: {
        pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };
  }

  private static getBenchmarkNpvOption(
    benchmarkData: NpvDrillDown[],
    benchmarkPercentile: BenchmarkPercentile,
    benchmarkOption: BenchmarkOption,
    nodePathDrill: BehaviorSubject<string | undefined>) {
    return {
      name: getBenchmarkSeriesName(benchmarkOption, true, benchmarkPercentile),
      colorIndex: HighChartColorIndex.GREY,
      yAxis: 0,
      data: benchmarkData.slice(),
      type: ChartType.LINE,
      stack: 1,
      events: {
        click: (event: PointClickEventObject) => {
          // @ts-ignore
          nodePathDrill.next(event.point.drillDown);
        }
      },
      zIndex: 1,
      marker: {
        symbol: ChartMarkerSymbol.SQUARE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };
  }

  private static getTotalPatientOption(existingNewPatientsData: NpvDrillDown[],
                                       nodePathDrill: BehaviorSubject<string | undefined>) {
    return {
      name: '# Total Patients',
      yAxis: 1,
      type: ChartType.COLUMN,
      pointWidth: DEFAULT_POINT_WIDTH,
      data: existingNewPatientsData.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
        createEmptyValues(existingNewPatientsData, DEFAULT_MAX_NUMBER_SCROLLBARS) :
        existingNewPatientsData,
      stacking: 'normal',
      colorIndex: HighChartColorIndex.PURPLE,
      stack: 3,
      events: {
        click: (event: PointClickEventObject) => {
          // @ts-ignore
          nodePathDrill.next(event.point.drillDown);
        }
      },
      zIndex: 0,
      tooltip: {
        pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.total:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };
  }

  private static getNewCountsOption(newPatientCountsData: NpvDrillDown[],
                                    nodePathDrill: BehaviorSubject<string | undefined>) {
    return {
      name: '# New Patients',
      yAxis: 1,
      type: ChartType.COLUMN,
      pointWidth: DEFAULT_POINT_WIDTH,
      data: newPatientCountsData.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
        createEmptyValues(newPatientCountsData, DEFAULT_MAX_NUMBER_SCROLLBARS) :
        newPatientCountsData,
      stacking: 'normal',
      colorIndex: HighChartColorIndex.TEAL,
      stack: 3,
      events: {
        click: (event: PointClickEventObject) => {
          // @ts-ignore
          nodePathDrill.next(event.point.drillDown);
        }
      },
      zIndex: 0,
      tooltip: {
        pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
        valueDecimals: 0
      }
    };
  }

  constructor(private drillDownService: DrillDownService, private ngRedux: NgRedux<IAppState>) {
  }

  ngOnChanges(): void {
    this.levelText = getSingularOntologyLevelName(this.selectedLevelTab);
    this.nodePathDrill = new BehaviorSubject<string | undefined>(undefined);
    this.isDrillable = this.npvLocationVariables.viewByNode && ontologyTabEquivalentOfLocationTab(this.selectedLevelTab)
      !== MultilevelTab.BY_PROVIDER;
    this.drawGraph();
    this.setUpLegends();
    this.getNodePathDrill().subscribe((newNodePath?: string) => {
      if (newNodePath) {
        this.barSelection(newNodePath);
      }
    });
  }

  private getNodePathDrill(): Observable<string | undefined> {
    return this.nodePathDrill.asObservable();
  }

  private setUpLegends() {
    this.legends = [getPercentNpvLegend(this.showPatientVisitCounts)];
    if (this.showPatientVisitCounts) {
      this.legends = this.legends.concat(countLegendsForNpvTrend);
    }
    this.legends.push(getBenchmarkLegendForNpv());
  }

  private getNodeGraphData(): NpvLocationGraphData {
    return {
      npvPercentages: this.npvLocationChartData.map(visit => {
        return {
          drillDown: visit.drillDown, y: roundToNumber(visit.newPatientVisitsPercentage, 1),
        };
      }),
      npvExistingCounts: this.showPatientVisitCounts ? this.npvLocationChartData.map(visit => {
        return {
          drillDown: visit.drillDown, y: visit.countOfExistingPatientVisits ?? 0
        };
      }) : [],
      npvNewCounts: this.showPatientVisitCounts ? this.npvLocationChartData.map(visit => {
        return {
          drillDown: visit.drillDown, y: visit.countOfNewPatientVisits ?? 0
        };
      }) : [],
      npvTotalCounts: this.showPatientVisitCounts ? this.npvLocationChartData.map(visit => {
        return {
          drillDown: visit.drillDown, y: visit.countOfTotalPatientVisits ?? 0
        };
      }) : [],
      benchmarks: this.npvLocationChartData.map(visit => {
        return {
          drillDown: visit.drillDown, y: visit.benchmarkValue ?? 0
        };
      }),
      categories: this.npvLocationChartData.map((visit) => visit.name)
    };
  }

  private drawGraph(): void {
    const graphData: NpvLocationGraphData = this.getNodeGraphData();
    const locationNewPatientVisitsPercentages = graphData.npvPercentages;
    const existingNewPatientsData = graphData.npvExistingCounts;
    const newPatientCountsData = graphData.npvNewCounts;
    const totalPatientsData = graphData.npvTotalCounts;
    const benchmarkData = graphData.benchmarks;
    const categories: string[] = graphData.categories;
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;
    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% New Patients',
        },
        max: Math.max(...locationNewPatientVisitsPercentages.map(value => value.y), ...benchmarkData.map(value => value.y)),
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
      },
    ];
    const rightSideYAxis: YAxisOptions[] = this.showPatientVisitCounts ? [
      {
        title: {
          text: '# New and Total Patients',
        },
        opposite: true,
        max: Math.max(...totalPatientsData.map(value => value.y)),
        labels: {
          formatter: function () {
            return abbreviateAxisValue(this.value);
          },
        }
      },
    ] : [];
    const percentNewPatientVisitsOption = NpvLocationChartComponent.getPercentNewPatientVisitsOption(
      locationNewPatientVisitsPercentages, this.showPatientVisitCounts, this.nodePathDrill);
    const barChartSeriesOptions: any[] = this.npvLocationVariables.viewByNode
      ? [percentNewPatientVisitsOption,
        NpvLocationChartComponent.getBenchmarkNpvOption(benchmarkData, this.benchmarkPercentile, this.benchmarkOption, this.nodePathDrill)]
      : [percentNewPatientVisitsOption];
    const newPatientCountsOptions: any[] = this.showPatientVisitCounts ? [
      NpvLocationChartComponent.getTotalPatientOption(existingNewPatientsData, this.nodePathDrill),
      NpvLocationChartComponent.getNewCountsOption(newPatientCountsData, this.nodePathDrill),
    ] : [];
    const nodePathDrill = this.nodePathDrill;
    this.options = {
      scrollbar: {
        enabled: isScrolled,
        showFull: false,
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      series: concat(barChartSeriesOptions, newPatientCountsOptions),
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.width,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground,
          className: 'no-hover'
        },
        // @ts-ignore
        clickOnCrosshair: function (e: any, p: any) {
          nodePathDrill.next(p.drillDown);
        },
        max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS - 1 : categories.length - 1,
        labels: {
          rotation: 45,
        },
      },
      yAxis: concat(leftSideYAxis, rightSideYAxis),
    };
    this.chartObject = new StyledChart(this.options);
  }

  barSelection(drillDownNodePath: string): void {
    if (!this.isDrillable) {
      return;
    }
    this.drillDownService.drillDownIntoNode(drillDownNodePath,
      this.ngRedux.getState().filters.nodePath, this.selectedLevelTab);
    this.ngRedux.dispatch(multilevelTabChangedTo(nextGranularOntologyTab(this.selectedLevelTab)));
  }
}
