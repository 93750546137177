import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {SelectableLocation} from '../../shared/models';

@Component({
  selector: 'app-search-multiple-locations',
  templateUrl: './search-multiple-locations.component.html',
  styleUrls: ['./search-multiple-locations.component.scss']
})
export class SearchMultipleLocationsComponent implements OnChanges {

  @Input() memberLocations: SelectableLocation[] = [];
  @Output() locationsEmit = new EventEmitter<number[]>();
  memberLocationsForTable: SelectableLocation[] = [];
  sortedLocationsForTable: SelectableLocation[] = [];
  allSelected = true;
  showLoadingIndicator = true;
  locationSearchText = '';
  constructor() { }

  ngOnChanges(): void {
    this.showLoadingIndicator = !this.memberLocations || !this.memberLocations.length;
    if (this.memberLocations !== undefined) {
      this.memberLocationsForTable = this.memberLocations.slice();
      this.sortSelectedLocations();
      this.allSelected = !((this.memberLocations.find(t => t.selected)) && (this.memberLocations.find(t => t.selected)));
    }
  }

  onAllLocationsSelected(event: MouseEvent): void {
    event.preventDefault();
    this.allSelected = true;
    this.memberLocations.forEach(location => {
      location.selected = false;
    });
    this.sortSelectedLocations();
    this.locationsEmit.emit([]);
  }

  onLocationSelected(event: MouseEvent, location: SelectableLocation): void {
    event.preventDefault();
    location.selected = !location.selected;
    this.sortSelectedLocations();
    this.allSelected = !this.memberLocations.find(l => l.selected);
    this.locationsEmit.emit(this.memberLocations.filter(x => x.selected).map(l => l.location.memberLocationKey));
  }

  onLocationSearchTextChanged(event: any): void {
    this.locationSearchText = event.target.value;
    if (this.locationSearchText.length <= 1) {
      this.memberLocations.forEach(location => {
        location.matchesFilterSearchText = true;
      });
      this.filterLocationTableData();
      return;
    }
    this.memberLocations.forEach(location => {
      if (this.locationSearchText.length <= location.location.memberLocationName.length) {
        const shouldUse = location.location.memberLocationName.toLowerCase().includes(this.locationSearchText.toLowerCase());
        location.matchesFilterSearchText = shouldUse;
      } else {
        location.matchesFilterSearchText = false;
      }
    });
    this.filterLocationTableData();
  }

  private foundMatch(tokens: string[], i: number): boolean {
    return this.locationSearchText.length <= tokens[i].length &&
      tokens[i].substring(0, this.locationSearchText.length).toLowerCase() ===
      this.locationSearchText.toLowerCase();
  }

  filterLocationTableData() {
    this.memberLocationsForTable = this.memberLocations.filter(location =>
      !location.eliminated && location.matchesFilterSearchText);
    this.sortSelectedLocations();
  }

  sortSelectedLocations() {
    const selectedLocations = this.memberLocationsForTable.filter(loc => loc.selected);
    const unselectedLocations = this.memberLocationsForTable.filter(loc => !loc.selected);
    this.sortedLocationsForTable = selectedLocations.concat(unselectedLocations);
  }
}
