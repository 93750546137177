<div id="metric-nav" *ngIf="!(filterBannerEdit$ | async)">
  <a *ngFor="let route of routes" routerLink="{{route.href}}" [hidden]="route.hide" routerLinkActive="active">{{route.description}}</a>
  <div id="my-reports-wrapper">
    <button  id="my-reports" mat-button (click)="handleMyReportsGoogleAnalytics()" [matMenuTriggerFor]="myReportsMenu">
      My Reports
      <i id="caret-my-reports" class="fa fa-caret-down"></i>
      <span matBadge="{{newProviderExports$ | async}}"
            matBadgeColor="primary"
            matBadgePosition="after"
            matBadgeOverlap="false"
            matBadgeHidden="{{(newProviderExports$ | async) === 0}}">
      </span>
    </button>

    <mat-menu class="my-reports-menu" #myReportsMenu="matMenu">
      <button id="multi-provider-reports" (click)="openMultiProviderExports()" mat-menu-item>
        Multi-Provider Exports
        <button *ngIf="(newProviderExports$ | async)"
                id="multiprovider-quant"
                disabled
                mat-mini-fab
                [ngStyle]="{transform: 'scale(0.65)'}"
        >{{newProviderExports$ | async}}
        </button>
      </button>

      <button *ngFor="let report of reports" id="{{report.id}}" (click)="openReport(report)" mat-menu-item>{{report.reportName}}</button>
    </mat-menu>
  </div>

  <div id="more-apps-wrapper">
    <button id="more-apps" mat-button
            [matMenuTriggerFor]="moreAppsMenu">More Apps<i id="caret-more-apps" class="fa fa-caret-down"></i></button>
    <mat-menu class="more-apps-menu" #moreAppsMenu="matMenu">
      <button id="ATLink" tabindex='0' (click)="openAccessAndThroughput()" mat-menu-item>Access & Throughput</button>
      <button id="oppDashboard-link" tabindex='1' (click)="openOppDashboard()" mat-menu-item>Opportunity Dashboard
      </button>
      <button id="clinical-activity-link" tabindex='2' (click)="openClinicalActivity()"
              mat-menu-item>Clinical Activity/<br>Revenue Cycle Suite
      </button>
    </mat-menu>
  </div>
</div>
