<div class=tile>
  <div class="title-wrapper">
    <div class=title>{{title}}</div>
  </div>
  <div class=em-sub-header>
      <span class="selectedDateRange"
            *ngIf="selectedDateRange">{{selectedDateRange}}</span>
  </div>
  <app-em-outpatient-batch-export *ngIf="showOutpatient" class="em-graph"
                                  [totalCptCounts]="totalCptCounts"
                                  [chartHeight]="250"
                                  [chartWidth]="400"
                                  [foundNodeName]="nodeName"
                                  [benchmarkOption]="benchmarkOption"
                                  [specialtyCptCounts]="splCptCounts" [batchMultilevelEMData]="batchMultilevelEMData"
  ></app-em-outpatient-batch-export>
  <app-em-inpatient-batch-export *ngIf="showInpatient"
                                 [totalCptCounts]="totalCptCounts"
                                 [chartHeight]="250"
                                 [chartWidth]="400"
                                 [foundNodeName]="nodeName"
                                 [benchmarkOption]="benchmarkOption"
                                 class="app-percentage-chart em-graph"
                                 [specialtyCptCounts]="splCptCounts" [batchMultilevelEMData]="batchMultilevelEMData"
  ></app-em-inpatient-batch-export>
  <app-em-emergency-medicine-batch-export *ngIf="showEmergencyMedicine"
                                          [isSingleProviderSelected]="true"
                                          [totalCptCounts]="totalCptCounts"
                                          [chartHeight]="250"
                                          [chartWidth]="400"
                                          [foundNodeName]="nodeName"
                                          [benchmarkOption]="benchmarkOption"
                                          [specialtyCptCounts]="splCptCounts"
                                          [batchMultilevelEMData]="batchMultilevelEMData"
                                          class="app-percentage-chart em-graph"
  ></app-em-emergency-medicine-batch-export>
  <app-em-eye-exam-batch-export *ngIf="showOphthalmology"
                                [isSingleProviderSelected]="true"
                                [totalCptCounts]="totalCptCounts"
                                [chartHeight]="250"
                                [chartWidth]="400"
                                [foundNodeName]="nodeName"
                                [benchmarkOption]="benchmarkOption"
                                [specialtyCptCounts]="splCptCounts" [batchMultilevelEMData]="batchMultilevelEMData"
                                class="app-percentage-chart em-graph"
  >
  </app-em-eye-exam-batch-export>
  <app-overview-tile-footer [fromPdfExport]="true" [metricType]="metricType"></app-overview-tile-footer>
</div>
