<mat-table *ngIf="tableSources.length > 0" [dataSource]="tableSources[0].source" [ngClass]="scheduleTableClass" id="header-table">
  <ng-container *ngFor="let col of parentColumns; let i = index" matColumnDef="{{col.columnDef}}">
    <mat-header-cell class="header-title" *matHeaderCellDef>{{col.header}}
      <mat-icon *ngIf="col.hoverMessage" [matTooltip]="col.hoverMessage" [matTooltipClass]="'custom-tooltip'" class="info">info
      </mat-icon>
    </mat-header-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="stringColumnsForParentRowDef"></mat-header-row>
</mat-table>
<span *ngFor="let source of tableSources">
  <mat-table [dataSource]="source.source" class="{{scheduleTableClass}} {{source.state === NodeExpansionState.EXPANDED ?
    'expanded-source' : ''}}">
    <ng-container *ngFor="let col of parentColumns; let i = index" matColumnDef="{{col.columnDef}}">
      <mat-cell *matCellDef="let row" class="{{col.columnDef}}-cell">
        <span *ngIf="col.columnType === ColumnType.TABLE_MODIFICATION" class="expander-wrapper">
          <i class="{{row.hasUnreadInstanceReport ? 'unread-scheduled-name' : 'read-scheduled-name'}} {{'fa fa-circle'}}"></i>
          <i *ngIf="[BatchExportStatus.ACKNOWLEDGED, BatchExportStatus.COMPLETED].includes(row.status)"
             class="expander fa fa-chevron-{{source.state === NodeExpansionState.COLLAPSED ? 'down' : 'up'}}"
             (click)="expandOrCollapseRow(source)"></i>
          <i *ngIf="![BatchExportStatus.ACKNOWLEDGED, BatchExportStatus.COMPLETED].includes(row.status)" class="unused fa fa-chevron-down" [style]="'opacity: 0'"></i>
        </span>
        <span class="{{col.columnType === ColumnType.REPORT_NAME && [BatchExportStatus.ACKNOWLEDGED,
         BatchExportStatus.COMPLETED].includes(row.status) ? 'report-name' : ''}} cell-text"
          id="{{col.columnType === ColumnType.REPORT_NAME ? scheduledElementIdPrefix.concat(row.scheduledReportId) : ''}}"
              [matTooltip]="row.hoverMessage(row.scheduledReportId)" [matTooltipDisabled]="col.columnType !== ColumnType.REPORT_NAME"
              [matTooltipPosition]="'after'"
              (mouseover)="activateScheduledReportTooltip(row.scheduledReportId, row.scheduledReportName)">{{col.dataName(row)}}</span>
        <span *ngIf="col.columnType === ColumnType.CANCEL" class="cancel">Cancel</span>
        <mat-icon *ngIf="col.columnType === ColumnType.DELETE" class="delete" (click)="deleteItem(row.scheduledReportId, false)">delete</mat-icon>
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: stringColumnsForParentRowDef;"></mat-row>
  </mat-table>
  <mat-table *ngIf="source.state === NodeExpansionState.EXPANDED"
      [dataSource]="source.source.data[0].instanceReports" [ngClass]="scheduleTableClass">
    <ng-container *ngFor="let col of childColumns; let i = index" matColumnDef="{{col.columnDef}}">
      <mat-cell *matCellDef="let row" class="{{col.columnDef}}-cell">
        <span *ngIf= "col.columnType === ColumnType.TABLE_MODIFICATION && row.status===BatchExportStatus.COMPLETED &&
          !row.readStatus" class="expander-wrapper">
          <i class="unused fa fa-chevron-down" [style]="'opacity: 0'"></i>
          <i class="{{'unread-instance-report-status'}} {{'fa fa-circle'}}"></i>
        </span>
        <a *ngIf="col.columnType === ColumnType.REPORT_NAME && row.status === BatchExportStatus.COMPLETED" class="report-link"
           (click)="downloadInstance(row)" id="{{instanceElementIdPrefix.concat(row.instanceReportId)}}"
           [matTooltip]="row.hoverMessage(row.instanceReportId)" (mouseover)="activateInstanceReportTooltip(row.instanceReportId, row.instanceReportName)"
           [matTooltipPosition]="'after'"
           [matTooltipDisabled]="col.columnType !== ColumnType.REPORT_NAME"
        >{{col.dataName(row)}}</a>
        <span *ngIf="col.columnType !== ColumnType.REPORT_NAME || row.status !== BatchExportStatus.COMPLETED"
              class="cell-text" id="{{col.columnType === ColumnType.REPORT_NAME ? instanceElementIdPrefix.concat(row.instanceReportId) : ''}}"
              [matTooltip]="row.hoverMessage(row.instanceReportId)" (mouseover)="activateInstanceReportTooltip(row.instanceReportId, row.instanceReportName)"
              [matTooltipPosition]="'after'"
              [matTooltipDisabled]="col.columnType !== ColumnType.REPORT_NAME">{{col.dataName(row)}}</span>
        <mat-icon *ngIf="col.columnType === ColumnType.DELETE" class="delete" (click)="deleteItem(row.instanceReportId, true)">delete</mat-icon>
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: stringColumnsForChildRowDef;"></mat-row>
  </mat-table>
</span>
