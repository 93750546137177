import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import * as html2canvas from 'html2canvas';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
// @ts-ignore
import jspdf from 'jspdf';
import {canHide, getAppConfigValue, getSelectedDateRange, updateCurrentNodePathLabel} from '../shared/helpers';
import {AgmHelperService} from '../zipcode-map/zipcode-map.component';
import {Subscription} from 'rxjs';
import {CurrentMemberLocation, EmDimension, MemberLocation, PayerCategory} from '../shared/models';
import {PDFExportView} from '../shared/enums';
import {exportingStatusChangedTo} from '../store/actions';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {DEFAULT_MEMBER_LOCATION, DEFAULT_PAYER_CATEGORY} from '../store/DefaultValues';
import {visitTypes} from '../shared/constants';
import {AppConfigEntries} from '../shared/app-config-settings-enum';

// @ts-ignore
@Component({
  selector: 'app-export-pdf-dialog',
  templateUrl: './export-pdf-dialog.component.html',
  styleUrls: ['./export-pdf-dialog.component.scss']
})
export class ExportPdfDialogComponent implements AfterViewInit, OnDestroy {

  IMG_WIDTH = 218;
  PAGE_HEIGHT = 280;
  scale: number;
  offset: number;
  position = 0;
  pdfContentHeight = 0;
  footerHeight: number;
  headerHeight: number;
  heightRemaining: number;
  pdfFileName: string;
  locationSelection: MemberLocation;
  payerSelection: PayerCategory;
  selectedDate = '';
  data: any;
  html2canvas: any;
  canvas: any;
  pdf: any;
  zipCodeMap: HTMLCanvasElement;
  mapSubscription: Subscription;
  emDimensions: EmDimension[] = [];
  footer: string;
  header: string;
  background: string;
  isIE: boolean;
  content = false;
  pdfExportView = PDFExportView;
  currentView: PDFExportView = PDFExportView.OVERVIEW_WRVU_NPV;
  canHideEMExportOptions: boolean;
  html2canvasSettings: any;
  scrollY = -8;
  communityBenchmarkAlert = false;
  communityBenchmarkAlertText = 'Community benchmarks not available for time period selected';
  visitTypeSelection: string | undefined;
  nodePathSelection: string | undefined;
  hideEm = false;
  maxLocations: number;
  currentLocations: CurrentMemberLocation[];

  constructor(
    private readonly ngRedux: NgRedux<IAppState>,
    private _changeDetectorRef: ChangeDetectorRef,
    private agmHelper: AgmHelperService,
  ) {

    this.html2canvas = html2canvas;

    this.isIE = 'documentMode' in document;
    this.scale = this.isIE ? 1.0 : 2.5;
    this.offset = 0;
    this.html2canvasSettings = {
      useOverflow: true,
      useCORS: true,
      scale: this.scale,
      backgroundColor: '#F5F5F5',
    };

    if (!Element.prototype.replaceWith) {
      Element.prototype.replaceWith = ReplaceWithPolyfill;
    }
    if (!CharacterData.prototype.replaceWith) {
      CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
    }
    if (!DocumentType.prototype.replaceWith) {
      DocumentType.prototype.replaceWith = ReplaceWithPolyfill;
    }

  }

  ngAfterViewInit() {
    this.initializeData();
  }

  ngOnDestroy(): void {
    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }
    this.agmHelper.updateMapLoadStatus(false);
  }

  initializeData(): void {
    const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
    this.canHideEMExportOptions = canHide(FeatureToggleEntries.EM_EXPORT_OPTIONS, featureToggleSettings, userProfile);
    this.pdfFileName = this.getPdfFileName();
    this._changeDetectorRef.detectChanges();
    this.selectedDate = getSelectedDateRange(this.ngRedux.getState().filters.dateRange, false);
    this.locationSelection = this.ngRedux.getState().filters.memberLocation.memberLocationName === '' ?
      DEFAULT_MEMBER_LOCATION : this.ngRedux.getState().filters.memberLocation;
    this.payerSelection = this.ngRedux.getState().filters.payerCategory.payerCategoryDescription === '' ?
      DEFAULT_PAYER_CATEGORY : this.ngRedux.getState().filters.payerCategory;
    this.visitTypeSelection = visitTypes[this.ngRedux.getState().filters.telehealthFlag ?? 0]?.text;
    this.nodePathSelection = updateCurrentNodePathLabel(this.ngRedux.getState().filters.nodePath) || undefined;
    this.maxLocations = +getAppConfigValue(AppConfigEntries.LOCATIONS_TO_SHOW_IN_EXPORT,
      this.ngRedux.getState().data.applicationConfigurationSettings) || 5;
    this.currentLocations = this.ngRedux.getState().data.memberLocations;
  }

  async exportWithPageBreaks(data: any, emDimensions: EmDimension[], communityBenchmarkAlert: boolean) {
    this.communityBenchmarkAlert = communityBenchmarkAlert;
    this.ngRedux.dispatch(exportingStatusChangedTo(true));
    this.initializeData();
    this.pdf = new jspdf('p', 'mm', 'letter');
    this.emDimensions = emDimensions;
    this.position = 0;

    let exportData: HTMLElement | null;
    this.data = data;
    this.content = false;
    this._changeDetectorRef.detectChanges();

    const backgroundCanvas = await (this.html2canvas as any)(document.querySelector('#empty-space'), {
      ...this.html2canvasSettings
    });
    this.background = backgroundCanvas.toDataURL('image/jpeg', 1.0);
    this.pdf.addImage(this.background, 'PNG', this.offset, this.offset, this.IMG_WIDTH - this.offset, this.PAGE_HEIGHT);

    const headerCanvas = await (this.html2canvas as any)(document.querySelector('app-pdf-header'), {
      ...this.html2canvasSettings,
    });
    this.headerHeight = headerCanvas.height * this.IMG_WIDTH / headerCanvas.width;
    this.header = headerCanvas.toDataURL('image/jpeg', 1.0);


    const footerCanvas = await (this.html2canvas as any)(document.querySelector('#pdf-footer'), {
      ...this.html2canvasSettings,
      scrollY: this.scrollY + 1
    });
    this.footerHeight = footerCanvas.height * this.IMG_WIDTH / footerCanvas.width;
    this.footer = footerCanvas.toDataURL('image/jpeg', 1.0);

    this.pdfContentHeight = this.PAGE_HEIGHT - this.headerHeight - this.footerHeight;
    this.content = true;

    this.addHeader();

    ['overview'].forEach(async (page: string) => {
      if (data[page]) {
        switch (page) {
          case 'overview':
            this.currentView = PDFExportView.OVERVIEW_WRVU_NPV;
            this._changeDetectorRef.detectChanges();
            await this.appendCanvasToPdf(null);
            this.currentView = PDFExportView.OVERVIEW_EM;
            this._changeDetectorRef.detectChanges();
            await this.appendCanvasToPdf(null);
            this.currentView = PDFExportView.OVERVIEW_ZIP;
            break;
        }

        this._changeDetectorRef.detectChanges();

        exportData = document.getElementById('options-export-pdf');

        if (exportData) {

          if (exportData.querySelector('.agm-map')) {
            this.scrollY = -5;
            const ready: boolean = await this.agmHelper.mapReadyPromise;
            if (ready) {
              // @ts-ignore
              const canvas: any = await (this.html2canvas as any)(exportData.querySelector('.agm-map'), {
                ...this.html2canvasSettings,
                backgroundColor: null,
                scrollY: this.scrollY
              });
              this.zipCodeMap = canvas;
              this.zipCodeMap.setAttribute('class', 'agm-map');
              // @ts-ignore
              if (exportData.querySelector('.agm-map')) {
                // @ts-ignore
                exportData.querySelector('.agm-map').replaceWith(this.zipCodeMap);
              }
              await this.appendCanvasToPdf(exportData);
            }
          } else {
            await this.appendCanvasToPdf(exportData);
          }
        }
      }

      await this.addFooter();

      this.data = null;
      this.emDimensions = [];
      this.position = this.offset;
      this.currentView = PDFExportView.OVERVIEW_WRVU_NPV;
      this.content = false;
      this.pdf.save(this.pdfFileName);
      this.ngRedux.dispatch(exportingStatusChangedTo(false));
    });
  }

  async appendCanvasToPdf(exportData: HTMLElement | null) {

    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }

    if (!exportData) {
      exportData = document.getElementById('options-export-pdf');
    }

    if (exportData) {

      const exportCanvas: any = await (html2canvas as any)(exportData, {
        onclone: (element: HTMLElement) => applyInlineStyleToSVG(element),
        ...this.html2canvasSettings,
        scrollY: this.scrollY
      });

      try {
        const imgHeight = exportCanvas.height * this.IMG_WIDTH / exportCanvas.width;
        this.heightRemaining = imgHeight;
        // const contentDataURL = exportCanvas.toDataURL('image/png', 1.0);
        const contentDataURL = exportCanvas.toDataURL('image/jpeg', 1.0);

        if (imgHeight <= this.pdfContentHeight && imgHeight + this.position + this.offset > this.pdfContentHeight) {
          await this.addPage();
        }

        this.pdf.addImage(contentDataURL, 'PNG', this.offset, this.position + this.headerHeight + this.offset, this.IMG_WIDTH, imgHeight);

        this.position += imgHeight;
        this.heightRemaining -= this.pdfContentHeight;

        while (this.heightRemaining >= 0) {
          this.position = this.heightRemaining - imgHeight;
          await this.addPage();
          this.pdf.addImage(contentDataURL, 'PNG', this.offset, this.position, this.IMG_WIDTH, imgHeight);
          this.heightRemaining -= this.pdfContentHeight;
        }

      } catch (e) {
        console.log(e);
      }
    }
  }

  async addPage() {
    await this.addFooter();
    this.pdf.addPage();
    this.pdf.addImage(this.background, 'PNG', this.offset, this.offset, this.IMG_WIDTH, this.PAGE_HEIGHT);
    this.addHeader();
    this.position = 0;
  }

  addHeader() {
    this.pdf.addImage(this.header, 'jpeg', this.offset, this.offset, this.IMG_WIDTH, this.headerHeight);
  }

  addFooter() {
    this.pdf.addImage(this.footer, 'PNG', this.offset, this.PAGE_HEIGHT - this.footerHeight, this.IMG_WIDTH, this.footerHeight);
  }

  export(data: any, communityBenchmarkAlert: boolean) {
    this.hideEm = true;
    this.communityBenchmarkAlert = communityBenchmarkAlert;
    this.initializeData();
    this.data = data;
    this._changeDetectorRef.detectChanges();

    const exportData = document.getElementById('export-pdf');
    if (exportData) {

      if (data.overview) {
        this.mapSubscription = this.agmHelper.mapReady.subscribe((ready: boolean) => {
          if (ready) {
            (this.html2canvas as any)(exportData.querySelector('.agm-map'), {
              ...this.html2canvasSettings,
              backgroundColor: null,
            }).then((canvas: HTMLCanvasElement) => {

              this.zipCodeMap = canvas;
              this.zipCodeMap.setAttribute('class', 'agm-map');
              if (exportData.querySelector('.agm-map')) {
                // @ts-ignore
                exportData.querySelector('.agm-map').replaceWith(this.zipCodeMap);
              }

              this.exportCanvasToPdf(exportData);
            });
          }
        });
      } else {
        this.exportCanvasToPdf(exportData);
      }
    }
  }

  exportCanvasToPdf(exportData: HTMLElement | null) {
    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }
    this.agmHelper.updateMapLoadStatus(false);

    (html2canvas as any)(exportData, {
      onclone: (element: HTMLElement) => applyInlineStyleToSVG(element),
      ...this.html2canvasSettings
    }).then((exportCanvas: any) => {


      try {
        const imgHeight = exportCanvas.height * this.IMG_WIDTH / exportCanvas.width;
        let heightLeft = imgHeight;
        // const contentDataURL = exportCanvas.toDataURL('image/png', 1.0);
        const contentDataURL = exportCanvas.toDataURL('image/jpeg', 1.0);


        const pdf = new jspdf('p', 'mm', 'a4');
        let position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, this.IMG_WIDTH, imgHeight);

        heightLeft -= this.PAGE_HEIGHT;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, this.IMG_WIDTH, imgHeight);
          heightLeft -= this.PAGE_HEIGHT;
        }

        pdf.save(this.pdfFileName);
      } catch (e) {
        console.log(e);
      }
    });


    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }
    this.agmHelper.updateMapLoadStatus(false);

    this.data = null;

  }

  getPdfFileName(): string {
    const member = this.ngRedux.getState().data.members.members.find(
      x => x.memberKey === this.ngRedux.getState().filters.memberKey);
    return (member ? member.memberDesc : 'cpsc') + '.pdf';
  }
}

function applyInlineStyleToSVG(element: HTMLElement) {

  const transformProperties = [
    'fill',
    'color',
    'font-size',
    'stroke',
    'stroke-dasharray',
    'stroke-width',
    'font'
  ];

  const svgElems = Array.from(element.getElementsByTagName('svg'));

  for (const svgElement of svgElems) {
    recurseElementChildren(svgElement);
  }

  function recurseElementChildren(node: SVGSVGElement) {
    if (!node.style) {
      return;
    }

    const styles = getComputedStyle(node);

    for (const transformProperty of transformProperties) {
      // @ts-ignore
      node.style[transformProperty] = styles[transformProperty];
    }

    for (const child of Array.from(node.childNodes)) {
      recurseElementChildren(child as SVGSVGElement);
    }
  }
}

function ReplaceWithPolyfill() {
  'use-strict';
  const parent = this.parentNode;
  let i = arguments.length, currentNode;
  if (!parent) {
    return;
  }
  if (!i) {
    parent.removeChild(this);
  }
  while (i--) {
    currentNode = arguments[i];
    if (typeof currentNode !== 'object') {
      currentNode = this.ownerDocument.createTextNode(currentNode);
    } else if (currentNode.parentNode) {
      currentNode.parentNode.removeChild(currentNode);
    }
    if (!i) {
      parent.replaceChild(currentNode, this);
    } else {
      parent.insertBefore(currentNode, this.previousSibling);
    }
  }
}
