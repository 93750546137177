import {
  OntologyListData,
  OntologyListEntity,
  RelatableSelectableItem,
  ScenarioForDisplayability,
  SelectableItem,
  UpdatedFilterTab
} from './FilterBannerModels';
import {Data, FilterCriteria, IAppState} from '../store/IAppState';
import {
  CustomGroupsDataAllFilters,
  DateRange,
  InvoiceStatus,
  LagPeriod,
  MemberBillingArea,
  MemberLocation,
  PayerObject,
  VisitType
} from '../shared/models';
import {DateRangeOption, NodeExpansionState, PayerType} from '../shared/enums';
import * as _ from 'lodash';
import {
  DEFAULT_BREADCRUMB,
  DEFAULT_INVOICE_STATUS,
  DEFAULT_LAG_KEY,
  DEFAULT_MAX_NODE_PATHS,
  DEFAULT_MEMBER_BILLING_AREA,
  DEFAULT_MEMBER_KEY,
  DEFAULT_MEMBER_LOCATION,
  DEFAULT_PAGE_LABEL,
  DEFAULT_PAYER_CATEGORY,
  DEFAULT_TELEHEALTH_FLAG
} from '../store/DefaultValues';
import {
  collectionsPagesLabel,
  denialsPagesLabel,
  determineFiscalYear,
  getAppConfigValue,
  getArbitraryDateRangeFromDatesInMonths,
  getDateRangeBySelectedOption,
  getFiscalYearForFiscalYearToDateOption,
  getOppositeNodeExpansionState,
  nodeExpansionIsActive,
  npvPagesLabel,
  Page,
  parseMonthWithLeadingZero,
  wrvuPagesLabel
} from '../shared/helpers';
import {hasValue} from '../shared/null-helpers';
import {AppConfigEntries} from '../shared/app-config-settings-enum';

export const searchTextMinimum = 3;

export function isItemAbleToShow(item: RelatableSelectableItem<any>, scenario: ScenarioForDisplayability): boolean {
  switch (scenario) {
    case ScenarioForDisplayability.Standard:
      return item.item.matchesSearchText && item.item.belongs;
    case ScenarioForDisplayability.Belong:
      return item.item.belongs;
    case ScenarioForDisplayability.MatchSearchTextRegardless:
      return item.item.matchesSearchText;
    case ScenarioForDisplayability.IsParentSelected:
      return !!item.relatives.find(relative => (relative.expansion?.depth || 0) + 1 === (item.item.expansion?.depth || 1)
        && relative.selected);
    case ScenarioForDisplayability.IsParentExpanded:
      return !!item.relatives.find(relative => (relative.expansion?.depth || 0) + 1 === (item.item.expansion?.depth || 1)
        && relative.expansion?.state === NodeExpansionState.EXPANDED);
    case ScenarioForDisplayability.RelativeMatchesSearchText:
      return item.item.selected && !!item.relatives.find(relative => relative.matchesSearchText);
    default:
      return true;
  }
}

export function isCousin(item: RelatableSelectableItem<any>, relative: SelectableItem<any>): boolean {
  if (_.isEqual(item.item, relative)) {
    return false;
  }
  const itemExpansion = item.item.expansion;
  const relativeExpansion = relative.expansion;
  return !!itemExpansion && !!relativeExpansion && itemExpansion.depth === relativeExpansion.depth;
}

export function findClosestSelectedAncestor<T>(item: RelatableSelectableItem<T>): SelectableItem<T> {
  const itemExpansion = item.item.expansion;
  if (!itemExpansion) {
    return item.item;
  }
  for (let x = 0; x < item.relatives.length; x++) {
    const relativeExpansion = item.relatives[x].expansion;
    if (!relativeExpansion) {
      continue;
    }
    if (relativeExpansion.depth === itemExpansion.depth - 1) {
      return item.relatives[x];
    }
  }
  return item.item;
}

export function neutralizeExpansion(item: SelectableItem<any>): void {
  if (item.expansion && nodeExpansionIsActive(item.expansion.state)) {
    item.expansion.state = getOppositeNodeExpansionState(item.expansion.state);
  }
}

export function getPageLabel(page: Page): string {
  switch (page) {
    case Page.Overview:
      return Page.Overview.valueOf();
    case Page.WrvuSnapshot:
    case Page.WrvuTrend:
    case Page.WrvuProvider:
    case Page.WrvuSpecialty:
    case Page.WrvuDepartment:
      return wrvuPagesLabel;
    case Page.NPVSnapshot:
    case Page.NPVTrend:
    case Page.NPVDepartment:
    case Page.NPVSpecialty:
    case Page.NPVProvider:
      return npvPagesLabel;
    case Page.EandM:
      return Page.EandM.valueOf();
    case Page.CFP:
      return Page.CFP.valueOf();
    case Page.CollectionsSnapshot:
    case Page.CollectionsByPayer:
    case Page.CollectionsSummary:
      return collectionsPagesLabel;
    case Page.Denials:
    case Page.DenialsDepartment:
    case Page.DenialsSpecialty:
    case Page.DenialsProvider:
    case Page.DenialsByPayer:
      return denialsPagesLabel;
    case Page.ProcedureSummary:
      return Page.ProcedureSummary.valueOf();
    default:
      return DEFAULT_PAGE_LABEL;
  }
}

export function buildNodePath(pathArray: string[], index: number, end: number): string {
  const nodePathBuilder = [];
  do {
    nodePathBuilder.push(pathArray[index++]);
  } while (index < end);
  return nodePathBuilder.join('\\');
}

export function calculateSelectedNodePath(providers: SelectableItem<OntologyListEntity>[], allDepartmentsNodePath: string): string {
  const allSelectedNodePaths: string[] = providers.filter(p => p.selected).map(selection => selection.item.nodePath);
  const allUnSelectedNodePaths: string[] = providers.filter(p => !p.selected).map(selection => selection.item.nodePath);
  const validNodePaths: Set<string> = new Set<string>();
  for (let x = 0; x < allSelectedNodePaths.length; x++) {
    for (let y = 2; y < 6; y++) {
      const nodePathToCheck = buildNodePath(allSelectedNodePaths[x].split('\\'), 0, y);
      if (!allUnSelectedNodePaths.find(path => path.startsWith(nodePathToCheck))) {
        validNodePaths.add(nodePathToCheck);
        break;
      }
    }
  }
  return validNodePaths.size ? Array.from(validNodePaths).sort((a: string, b: string) => {
    return a.split('\\').length - b.split('\\').length;
  }).join('|') : allDepartmentsNodePath;
}

function getLocationCriteria(preExistingFilterCriteria: FilterCriteria,
                             locations: RelatableSelectableItem<MemberLocation>[]): FilterCriteria {
  const newLocation = locations.find(location => location.item.selected);
  return {
    ...preExistingFilterCriteria,
    memberLocation: newLocation?.item.item || DEFAULT_MEMBER_LOCATION,
    memberLocationKeys: locations.filter(x => x.item.selected).map(y => '' + y.item.item.memberLocationKey).join('|')
  };
}

function getBillingCriteria(preExistingFilterCriteria: FilterCriteria,
                            billingAreas: RelatableSelectableItem<MemberBillingArea>[]): FilterCriteria {
  const newBillingArea = billingAreas.find(billingArea => billingArea.item.selected);
  return {
    ...preExistingFilterCriteria,
    memberBillingArea: newBillingArea?.item.item || DEFAULT_MEMBER_BILLING_AREA
  };
}

function getPayerCriteria(preExistingFilterCriteria: FilterCriteria, selectedPayer?: PayerObject): FilterCriteria {
  let payerType: number, payerKey: PayerType;
  if (selectedPayer?.payerCriteria.memberPayerKey) {
    payerKey = selectedPayer.payerCriteria.memberPayerKey;
    payerType = PayerType.MEMBER;
  } else if (selectedPayer?.payerCriteria.nationalPayerKey) {
    payerKey = selectedPayer.payerCriteria.nationalPayerKey;
    payerType = PayerType.NATIONAL;
  } else if (selectedPayer?.payerCriteria.payerCategoryKey) {
    payerKey = selectedPayer.payerCriteria.payerCategoryKey;
    payerType = PayerType.CATEGORY;
  } else {
    payerKey = 0;
    payerType = PayerType.ALL;
  }

  return {
    ...preExistingFilterCriteria,
    payerCategory: {
      payerCategoryKey: selectedPayer?.payerCriteria.payerCategoryKey || 0,
      payerCategoryDescription: selectedPayer?.payerCriteria.payerCategoryDesc ||
        DEFAULT_PAYER_CATEGORY.payerCategoryDescription,
      payerCategoryCode: ''
    },
    nationalPayerDescription: selectedPayer?.payerCriteria.nationalPayerDesc,
    nationalPayerKey: selectedPayer?.payerCriteria.nationalPayerKey,
    memberPayerDescription: selectedPayer?.payerCriteria.memberPayerDesc,
    memberPayerKey: selectedPayer?.payerCriteria.memberPayerKey,
    payerType: payerType,
    payerKey: payerKey
  };
}

export function getNewFilterCriteria(preExistingFilterCriteria: FilterCriteria,
                                     allDepartmentsNodePath: string,
                                     date: DateRange,
                                     providers: RelatableSelectableItem<OntologyListEntity>[],
                                     locations: RelatableSelectableItem<MemberLocation>[],
                                     payers: RelatableSelectableItem<PayerObject>[],
                                     billingAreas: RelatableSelectableItem<MemberBillingArea>[],
                                     lagPeriods: RelatableSelectableItem<LagPeriod>[],
                                     invoiceStatusTypes: RelatableSelectableItem<InvoiceStatus>[],
                                     visitTypes: RelatableSelectableItem<VisitType>[]): FilterCriteria {
  let newNodePath = calculateSelectedNodePath(providers.map(provider => provider.item) || [], allDepartmentsNodePath);
  newNodePath = newNodePath || preExistingFilterCriteria.nodePath;
  const payerCriteria = getPayerCriteria(preExistingFilterCriteria, payers.find(payer => payer.item.selected)?.item.item);
  const locationCriteria = getLocationCriteria(preExistingFilterCriteria, locations);
  const billingCriteria = getBillingCriteria(preExistingFilterCriteria, billingAreas);
  return {
    ...preExistingFilterCriteria,
    dateRange: date,
    nodePath: newNodePath,
    payerCategory: payerCriteria.payerCategory,
    nationalPayerDescription: payerCriteria.nationalPayerDescription,
    nationalPayerKey: payerCriteria.nationalPayerKey,
    memberPayerDescription: payerCriteria.memberPayerDescription,
    memberPayerKey: payerCriteria.memberPayerKey,
    payerType: payerCriteria.payerType,
    payerKey: payerCriteria.payerKey,
    memberLocation: locationCriteria.memberLocation,
    memberBillingArea: billingCriteria.memberBillingArea,
    lagKey: lagPeriods.find(lag => lag.item.selected)?.item.item.key || DEFAULT_LAG_KEY,
    invoiceStatus: invoiceStatusTypes.find(status => status.item.selected)?.item.item.statusId || DEFAULT_INVOICE_STATUS,
    telehealthFlag: visitTypes.find(visit => visit.item.selected)?.item.item.key || DEFAULT_TELEHEALTH_FLAG.key,
    memberLocationKeys: locationCriteria.memberLocationKeys
  };
}

export function getSelectedDate(dateRange: DateRange) {
  return {
    'begin': dateRange.startYear
      + '-'
      + parseMonthWithLeadingZero(dateRange.startMonth)
      + '-15',
    'end': dateRange.endYear
      + '-'
      + parseMonthWithLeadingZero(dateRange.endMonth)
      + '-15',
    'selectedDateRangeOption': dateRange.selectedDateRangeOption
  };
}

export function determineAllSelected(list: RelatableSelectableItem<any>[]): boolean {
  return !list.find(item => item.item.selected);
}

export function deselectAllSelectable(list: RelatableSelectableItem<any>[], tab?: UpdatedFilterTab): void {
  list?.forEach(listItem => {
    listItem.item.selected = false;
    if (listItem.item.originallySelected && tab && tab.newSelections) {
      tab.newSelections.deselections++;
    }
  });
}

export function calculateBreadcrumbTrail(ontologyData?: OntologyListData): string {
  if (!ontologyData) {
    return DEFAULT_BREADCRUMB;
  }
  const departmentNames: string[] = ontologyData.departments.filter(d => d.relatives.find(rel =>
    rel.item.nodePath.split('\\').length === 5 && rel.selected)).map(x => x.item.item.departmentNodeName + '');
  if (!departmentNames.length) {
    return DEFAULT_BREADCRUMB;
  }
  const specialtyNames: string[] = ontologyData.specialties.filter(s => s.relatives.find(rel =>
    rel.item.nodePath.split('\\').length === 5 && rel.selected)).map(x => x.item.item.specialtyNodeName + '');
  const providerNames: string[] = ontologyData.providers.filter(p => p.item.selected).map(x => x.item.item.providerNodeName + '');
  let bread = '';
  bread = bread.concat(departmentNames.length === ontologyData.departments.length ? 'All Departments / ' :
    departmentNames.length === 1 ? departmentNames[0] + ' / ' : departmentNames.length + ' Departments / ');
  bread = bread.concat(specialtyNames.length === ontologyData.specialties.length ? 'All Specialties / ' :
    specialtyNames.length === 1 ? specialtyNames[0] + ' / ' : specialtyNames.length + ' Specialties / ');
  bread = bread.concat(providerNames.length === ontologyData.providers.length ? 'All Providers' :
    providerNames.length === 1 ? providerNames[0] : providerNames.length + ' Providers');
  return bread;
}

export function filterCriteriaFromSelectedCustomGroup(
  customGroup: CustomGroupsDataAllFilters, state: IAppState): FilterCriteria {
  return {
    nodePath: customGroup.nodePath,
    dateRange: findDateRangeFromCustomGroup(customGroup, state),
    memberKey: customGroup.memberKey || DEFAULT_MEMBER_KEY,
    payerKey: customGroup.payerType === 3 ? customGroup.memberPayerKey || 0 :
      (customGroup.payerType === 2 ? customGroup.nationalPayerKey || 0 :
        (customGroup.payerType === 1 ? customGroup.payerCategoryKey : 0)),
    payerType: customGroup.payerType,
    payerCategory: {
      payerCategoryKey: customGroup.payerCategoryKey,
      payerCategoryDescription: customGroup.payerCategoryDescription || '',
      payerCategoryCode: ''
    },
    nationalPayerKey: customGroup.nationalPayerKey,
    nationalPayerDescription: customGroup.nationalPayerDescription,
    memberPayerKey: customGroup.memberPayerKey,
    memberPayerDescription: customGroup.memberPayerDescription,
    memberLocation: {
      memberLocationKey: customGroup.memberLocationKey,
      memberLocationName: customGroup.memberLocationDescription || '',
      memberLocationCode: ''
    },
    memberBillingArea: {
      memberBillingAreaKey: customGroup.memberBillingAreaKey,
      memberBillingAreaDescription: customGroup.memberBillingAreaDescription || '',
      memberBillingAreaCode: ''
    },
    invoiceStatus: customGroup.invoiceStatus,
    lagKey: customGroup.lagKey,
    telehealthFlag: customGroup.telehealthFlag,
    customGroupId: customGroup.id, memberLocationKeys: '0'
  };
}

export function calculateSelectedDate(dateRange: DateRange) {
  return {
    'begin': dateRange.startYear
      + '-'
      + parseMonthWithLeadingZero(dateRange.startMonth)
      + '-15',
    'end': dateRange.endYear
      + '-'
      + parseMonthWithLeadingZero(dateRange.endMonth)
      + '-15',
    'selectedDateRangeOption': dateRange.selectedDateRangeOption
  };
}

export function calculateMaxNodePaths(data: Data): number {
  const value_providers = getAppConfigValue(AppConfigEntries.MAX_PROVIDERS, data.applicationConfigurationSettings);
  return value_providers && value_providers.length > 0 ? Number(value_providers) : DEFAULT_MAX_NODE_PATHS;
}

export function findDateRangeFromCustomGroup(customGroup: CustomGroupsDataAllFilters, state: IAppState): DateRange {
  const {dateRangeOption, startDateInMonths, endDateInMonths} = customGroup;
  if (!hasValue(dateRangeOption)) {
    return getArbitraryDateRangeFromDatesInMonths(startDateInMonths, endDateInMonths);
  }
  const fiscalStartMonth = state.userPreferences?.fiscalStartMonth || 1;
  const memberKey = state.filters.memberKey || DEFAULT_MEMBER_KEY;
  const recentMonth = state.data.userMemberData.find((x) => x.memberKey === memberKey)?.recentMonth;
  const fiscalYear = dateRangeOption === DateRangeOption.FiscalYearToDate
    ? getFiscalYearForFiscalYearToDateOption(fiscalStartMonth)
    : determineFiscalYear(startDateInMonths, (dateRangeOption || 10) - 9);
  const numberOfMonths = endDateInMonths - startDateInMonths + 1;
  const dateRange = getDateRangeBySelectedOption(dateRangeOption, recentMonth, fiscalStartMonth, fiscalYear, numberOfMonths);

  return dateRange;
}

export function isPayerLevelMoreGeneral(a: PayerType, b: PayerType): boolean {
  switch (a) {
    case PayerType.ALL:
      return [PayerType.CATEGORY, PayerType.NATIONAL, PayerType.MEMBER].includes(b);
    case PayerType.CATEGORY:
      return [PayerType.NATIONAL, PayerType.MEMBER].includes(b);
    case PayerType.NATIONAL:
      return b === PayerType.MEMBER;
    default:
      return false;
  }
}
