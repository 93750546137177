import {Injectable, InjectionToken} from '@angular/core';
import * as FileSaver from 'file-saver';
import {ExportNew} from '../shared/export/export';
// @ts-ignore
import * as Excel from 'exceljs/dist/exceljs.min.js';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';

export const ExcelServiceToken = new InjectionToken<ExcelService>('ExcelService');

export interface ExcelServiceInterface {
  exportAsExcelFile(excelExport: ExportNew): void;
}

@Injectable()
export class ExcelService implements ExcelServiceInterface {
  excel_type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  excel_extension = '.xlsx';

  constructor(private readonly ngRedux: NgRedux<IAppState>) {
  }

  public exportAsExcelFile(excelExport: ExportNew, cptAnalysisExport?: boolean): void {
    if (!excelExport) {
      return;
    }
    const workbook = new Excel.Workbook();
    let index = 0;
    excelExport.sheets.forEach((sheet: string) => {
      const worksheet = workbook.addWorksheet(sheet);
      const excelMetaData = excelExport.data[index];
      if (excelMetaData.copyright) {
        worksheet.addRow([excelMetaData.copyright]);
      }
      const memberName = this.ngRedux.getState().data.selectedMemberData.memberDesc;
      const viewCommunityBenchmark = this.ngRedux.getState().display.viewCommunityBenchmarks;
      worksheet.addRow([memberName]);

      if (excelMetaData.filterInfo) {
        excelMetaData.filterInfo.forEach((filter: string) => {
          if (filter) {
            worksheet.addRow([filter]);
          }
        });
      }

      if (excelMetaData.summaryHeaders) {
        const wsRow = worksheet.addRow([]);

        const summaryRow = worksheet.addRow(excelMetaData.summaryHeaders);
        summaryRow.eachCell((cell: Excel.Cell, colNumber: number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'E3DDEE'},
          };
        });
        if (cptAnalysisExport) {
          ['A7',
            'B7',
            'C7',
            'D7',
          ].map(key => {
            worksheet.getCell(key).border = {
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thin'},
              top: {style: 'thin'}
            };
          });
        }
        wsRow.eachCell((cell: Excel.Cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'E3DDEE'},
          };
        });
      }

      if (excelMetaData.summaryData) {
        worksheet.addRows(excelMetaData.summaryData);
      }

      if (excelMetaData.isBlankRowAfterSummary) {
        worksheet.addRow([]);
      }

      if (excelMetaData.detailHeaders) {
        if (cptAnalysisExport) {
          worksheet.mergeCells('B7:C7');
          worksheet.mergeCells('D7:E7');
          worksheet.getCell('B7').value = 'Provider Performance';
          worksheet.getCell('B7').alignment = {horizontal: 'center'};

          ['A7',
            'B7',
            'C7',
            'D7',
            'E7'
          ].map(key => {
            worksheet.getCell(key).border = {
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thin'},
              top: {style: 'thin'}
            };
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'E3DDEE'}
            };
          });

          worksheet.getCell('D7').value = viewCommunityBenchmark ? 'CPSC Community Benchmark' :
            'CPSC Academic Benchmark';
          worksheet.getCell('D7').alignment = {horizontal: 'center'};
        }

        const detailRow = worksheet.addRow(excelMetaData.detailHeaders);
        detailRow.eachCell((cell: Excel.Cell, colNumber: number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'E3DDEE'},
          };
          cell.border = {
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'},
            top: {style: 'thin'}
          };
        });
      }
      if (excelMetaData.shouldBoldAggregateRows && excelMetaData.detailData) {
        const aggregateIndex = excelMetaData.aggregateIndex;
        for (let row_num = 0; row_num < excelMetaData.detailData.length; row_num++) {
          const nextDetailRow = worksheet.addRow(excelMetaData.detailData[row_num]);
          if (row_num > 0 && excelMetaData.detailData[row_num - 1][aggregateIndex || 0] !==
            excelMetaData.detailData[row_num][aggregateIndex || 0]) {
            nextDetailRow.eachCell((cell: Excel.Cell, colNumber: number) => {
              cell.font = {
                bold: true
              };
            });
          }
        }
      } else if (excelMetaData.detailData) {
        worksheet.addRows(excelMetaData.detailData);
      }
      index++;
    });

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      this.saveAsExcelFile(blob, excelExport.fileName);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.excel_type
    });
    FileSaver.saveAs(data, fileName + this.excel_extension);
  }

}
