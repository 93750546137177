import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AxisLabelsFormatterContextObject, Options, PlotColumnOptions, PointClickEventObject, YAxisOptions} from 'highcharts/highstock';
import {CptViewType, GoogleAnalyticCategories, GoogleAnalyticsLabels, HighChartColorIndex, MultilevelTab} from '../../../shared/enums';
import {aggregateCPTData, CfpByMultilevel, getNextCptGranularity, ProcedureSummaryDrill} from '../../ClinicalSummary';
import {
  BLANK_TITLE_OBJECT,
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_CREDITS_OBJECT,
  DEFAULT_LANG_OBJECT,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  DEFAULT_TOOLTIP_OBJECT,
  StyledChart
} from '../../../shared/highcharts-helpers';
import {GraphColors} from '../../../productivity-summary/colors';
import {CHART_HEIGHT} from '../../../productivity-summary/productivity-graph-component';
import {abbreviateAxisValue} from '../../../productivity-summary/number-formatter';
import {concat} from '../../../shared/ourLodash';
import {Chart} from 'angular-highcharts';
import {dataGroupedByCptViewType, getDrillText} from '../../cfp-multilevel/cfp-multilevel-table/cfp-multilevel-table.component';
import {CfpVariableViewType, getNodePathFieldString, getSingularOntologyLevelName} from '../../../shared/helpers';
import {IAppState, ProcedureSummaryVariables} from '../../../store/IAppState';
import {DisplayField, Variable} from '../../../variable-container/variable-container.component';
import {MetricType} from '../../../shared/metric-types';
import {
  AppAction,
  filterPSGraphByChangedTo,
  filterPSGraphByOntologyChangedTo,
  nodePathChangedTo,
  procedureSummaryChargeDisplayChangedTo,
  viewPSGraphByNodeChangedTo
} from '../../../store/actions';
import {aCfpByMultilevelDefault} from '../../../shared/test/helper-functions.spec';
import {NgRedux} from '@angular-redux/store';
import {DrillDownService} from '../../../services/drilldown.service';
import {
  actualWrvuLegend,
  aDefaultProcedureSummaryVariables,
  benchmarkLegend,
  CfpGroupedByNodeInfo,
  CfpViewByOption,
  cftAdjCountLegend,
  cfteWrvuLegend,
  chargesLegend,
  countLegend,
  getCptDisplayTextFromCfpByMultilevel,
  getCptLevelKeyword,
  getNodeNameFromCfpGroupedByNodeInfo,
  getVariableDataFromCfpGroupedByNodeInfo
} from './procedure-summary-graph-helper';
import {sortByDefaultTheData} from '../../clinical-fingerprint-helpers';
import {getDisplayTextForCfpVariableTypeWithBenchmarkOption} from '../../clinical-fingerprint-helpers';
import {getDisplayTextForCfpVariableViewType} from '../../clinical-fingerprint-helpers';
import {getBenchmarkData} from '../../clinical-fingerprint-helpers';
import {isBenchmarkRequired} from '../../clinical-fingerprint-helpers';
import {ProcedureSummaryGraphFilterSelectorComponent} from './procedure-summary-graph-filter-selector/procedure-summary-graph-filter-selector.component';
import {Legend} from '../../../shared/models';
import {hasNonZeroValue} from '../../../shared/null-helpers';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';

@Component({
  selector: 'app-procedure-summary-graph',
  templateUrl: './procedure-summary-graph.component.html',
  styleUrls: ['./procedure-summary-graph.component.scss']
})
export class ProcedureSummaryGraphComponent implements OnChanges {
  constructor(private cdr: ChangeDetectorRef,
              private ngRedux: NgRedux<IAppState>,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
              private drillDownService: DrillDownService
  ) {
  }

  @Input() cfpData: CfpByMultilevel[] = [];
  @Input() cptViewType: CptViewType;
  @Input() cfpVariableViewType: CfpVariableViewType;
  @Input() showProgressBar = false;
  @Input() multilevelTab: MultilevelTab;
  @Input() procedureSummaryVariables: ProcedureSummaryVariables;
  @Input() showAdditionalCFPColumns: boolean;
  @Input() currentNodePath: string;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() hideCfteCount: boolean;
  @Input() hideCfteWrvu: boolean;
  @Output() drillInto = new EventEmitter<ProcedureSummaryDrill>();
  @ViewChild(ProcedureSummaryGraphFilterSelectorComponent, {static: true}) procedureSummaryGraphFilterSelectorComponent:
    ProcedureSummaryGraphFilterSelectorComponent;
  getDisplayTextForCfpVariableViewType = getDisplayTextForCfpVariableViewType;
  extraGraphVariables: Variable[] = [{
    name: 'Charges', display: false, reducerField: DisplayField.ProcedureSummaryCharges,
    metric: MetricType.ProcedureSummary, dispatchAction(display: boolean): AppAction {
      return procedureSummaryChargeDisplayChangedTo(display);
    }
  }];
  options: Options;
  chartObject: Chart;
  legends: Legend[];
  hasBenchmarks = false;
  viewByNode = false;
  nodeText: string;
  cptViewText: string;
  drillable = true;
  cptNodeOptions: CfpViewByOption[];
  currentSelectedCptNode: CfpViewByOption | undefined;
  currentSelectedOntologyCptNode: CfpViewByOption | undefined;
  private SNAPSHOT_BAR_COUNT = 20;
  private CHART_WIDTH = calculateChartWidth(DEFAULT_POINT_WIDTH, this.SNAPSHOT_BAR_COUNT, DEFAULT_POINT_PADDING);
  private barChartSeries: PlotColumnOptions = {
    cropThreshold: 100,
    pointPadding: DEFAULT_POINT_PADDING,
    stacking: 'normal'
  };

  readonly MetricType = MetricType;

  static handleGoogleAnalytics(cfp: CfpByMultilevel, cptViewType: CptViewType, analyticsService: AnalyticsService) {
    const cfpEventName = cptViewType === CptViewType.CptFamily
      ? cfp.cptFamilyDesc
      : `${cfp.cptRangeDesc} ${cfp.cptRangeLow} - ${cfp.cptRangeHigh}`;
    analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ProcedureSummarySnapshot, GoogleAnalyticsLabels.CptDrilldown,
      cfpEventName);
  }


  handleDrill(cfp: any, viewByNode: boolean, currentNodePath: string, cptViewType: CptViewType,
              tab: MultilevelTab, drillInto: EventEmitter<ProcedureSummaryDrill>,
              redux: NgRedux<IAppState>, drillDownService: DrillDownService,
              analyticsService: AnalyticsService): void {
    if (!viewByNode && cptViewType !== CptViewType.CptCode) {
      const mockCfp: CfpByMultilevel = {
        ...aCfpByMultilevelDefault(), cptFamilyDesc: cfp.cptFamilyDesc, cptRangeDesc: cfp.cptRangeDesc,
        cptRangeLow: cfp.cptRangeLow, cptRangeHigh: cfp.cptRangeHigh
      };
      drillInto.emit({
        displayText: getDrillText(mockCfp, cptViewType),
        viewType: getNextCptGranularity(cptViewType), cfp: mockCfp
      });
      ProcedureSummaryGraphComponent.handleGoogleAnalytics(cfp, cptViewType, analyticsService);
    } else {
      const drilledDownNodePath = `${cfp[getNodePathFieldString(tab)]}` ?? '';
      redux.dispatch(nodePathChangedTo(drilledDownNodePath));
      drillDownService.drillDownIntoNode(drilledDownNodePath, currentNodePath, tab);
    }
  }


  ngOnChanges(changes?: SimpleChanges): void {
    if (this.cfpVariableViewType === CfpVariableViewType.CfteAdjustedwRVU && this.hideCfteWrvu) {
      this.cfpVariableViewType = CfpVariableViewType.ActualwRVU;
    } else if (this.cfpVariableViewType === CfpVariableViewType.CfteAdjustedCount && this.hideCfteCount) {
      this.cfpVariableViewType = CfpVariableViewType.ActualCount;
    }
    this.invalidateCfpFilters();
    this.viewByNode = this.procedureSummaryVariables.viewByNode;
    this.currentSelectedCptNode = this.procedureSummaryVariables.currentSelectedCptNode;
    this.currentSelectedOntologyCptNode = this.procedureSummaryVariables.currentSelectedOntologyCptNode;
    if (changes && ((changes.cfpData && changes.cfpData.previousValue !== changes.cfpData.currentValue) ||
      (changes.cptViewType && changes.cptViewType.previousValue !== changes.cptViewType.currentValue))) {
      this.updateCptDrillability();
    }
    this.drawGraph();
    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  private invalidateCfpFilters(): void {
    if (this.currentSelectedOntologyCptNode && this.multilevelTab !== this.currentSelectedOntologyCptNode.level) {
      this.ngRedux.dispatch(filterPSGraphByOntologyChangedTo());
      this.procedureSummaryGraphFilterSelectorComponent.currentSelectedOntologyCptNode = undefined;
      this.procedureSummaryGraphFilterSelectorComponent.setFilterGraphByToAllDepartmentsSpecialtiesOrProviders(false);
    }
    if (this.currentSelectedCptNode && this.cptViewType !== this.currentSelectedCptNode.level) {
      this.ngRedux.dispatch(filterPSGraphByChangedTo());
      this.procedureSummaryGraphFilterSelectorComponent.selectedCptNode = undefined;
      this.procedureSummaryGraphFilterSelectorComponent.setDefaultCptViewGraphByOption(false);
    }
  }

  toggleViewNode(event: boolean) {
    this.ngRedux.dispatch(viewPSGraphByNodeChangedTo(event));
    this.updateCptDrillability();
  }

  private updateCptDrillability() {
    this.drillable = this.viewByNode || this.cptViewType !== CptViewType.CptCode;
  }

  filterGraphBy(event?: CfpViewByOption): void {
    if (!event) {
      return;
    }
    if (this.viewByNode) {
      if (event.identity.localeCompare(this.currentSelectedCptNode ? this.currentSelectedCptNode.identity : '') !== 0
        && event.identity.length) {
        this.currentSelectedCptNode = event;
        this.ngRedux.dispatch(filterPSGraphByChangedTo(event));
      }
    } else if (event.identity.localeCompare(this.currentSelectedOntologyCptNode ? this.currentSelectedOntologyCptNode.identity :
      '') !== 0 || !event.identity.length) {
      this.currentSelectedOntologyCptNode = event;
      this.ngRedux.dispatch(filterPSGraphByOntologyChangedTo(event));
    }
    this.cdr.detectChanges();
    this.drawGraph();
    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  setNodeText(event: string): void {
    this.nodeText = event;
    this.cdr.detectChanges();
  }

  setCptViewText(event: string): void {
    this.cptViewText = event;
    this.cdr.detectChanges();
  }

  drawGraph(): void {
    if (!this.cfpData) {
      return;
    }
    this.setUpLegendsForGraph();
    let filteredCfpData: CfpByMultilevel[];
    let groupedData: any[] = [];
    let categories;

    if (this.viewByNode) {
      filteredCfpData = this.filterOutUnselectedCptEntities(this.cptViewType);
      const groupedByNode: { [key: string]: CfpByMultilevel[] } = {};
      const nodePaths: string[] = [];
      let nodePath = '';
      filteredCfpData.forEach(datum => {
        nodePath = `${datum[this.getNodePathFieldString(this.multilevelTab)]}` ?? '';
        nodePaths.push(nodePath);
        groupedByNode[nodePath] = groupedByNode[nodePath] || [];
        groupedByNode[nodePath].push(datum);
      });
      const cfpNodes: CfpGroupedByNodeInfo[] = [];
      this.writeNodepathsToCfpNodes(nodePaths, groupedByNode, cfpNodes);
      groupedData = cfpNodes;
      sortByDefaultTheData(groupedData, this.cfpVariableViewType);
      categories = createEmptyLabels(groupedData.map(x => getNodeNameFromCfpGroupedByNodeInfo(x, this.multilevelTab)),
        DEFAULT_MAX_NUMBER_SCROLLBARS
      );
    } else {
      filteredCfpData = this.filterOutUnselectedNodes(this.multilevelTab);
      groupedData = dataGroupedByCptViewType(filteredCfpData, this.cptViewType);
      sortByDefaultTheData(groupedData, this.cfpVariableViewType);
      categories = createEmptyLabels(groupedData.map(x => getCptDisplayTextFromCfpByMultilevel(x, this.cptViewType)),
        DEFAULT_MAX_NUMBER_SCROLLBARS);
    }
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;
    const graphData = groupedData.map(x => getVariableDataFromCfpGroupedByNodeInfo(x, this.cfpVariableViewType));
    const procedureSummaryVariables = this.procedureSummaryVariables || aDefaultProcedureSummaryVariables;
    const chargesData = this.isChargesRequired() ? groupedData.map(x => x.charges) : [];
    const benchmarkData = getBenchmarkData(groupedData, this.viewCommunityBenchmarks, this.cfpVariableViewType);
    this.hasBenchmarks = benchmarkData.filter(x => hasNonZeroValue(x)).length > 0;
    const text = getDisplayTextForCfpVariableViewType(this.cfpVariableViewType);
    const benchmarkText = getDisplayTextForCfpVariableTypeWithBenchmarkOption(this.cfpVariableViewType, this.viewCommunityBenchmarks);
    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: text
        },
        max: Math.max(...graphData, ...benchmarkData),
        min: 0,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return abbreviateAxisValue(this.value);
          }
        }
      }
    ];
    const rightSideYAxis: YAxisOptions[] = this.isChargesRequired() ? [{
      title: {
        text: this.getRightSideLabel()
      },
      max: Math.max(...chargesData),
      min: 0,
      opposite: true,
      labels: {
        formatter: function (this: AxisLabelsFormatterContextObject<any>) {
          return procedureSummaryVariables.charges ? abbreviateAxisValue(this.value) : '' +
            abbreviateAxisValue(this.value);
        }
      }
    }] : [];
    // @ts-ignore
    const graphSeries: Highcharts.BarChartSeriesOptions[] = this.getGraphSeries(text, graphData, groupedData);
    // @ts-ignore
    const chargesSeries: Highcharts.BarChartSeriesOptions[] = this.getChargesSeries(chargesData, groupedData);
    // @ts-ignore
    const benchmarkSeries: Highcharts.BarChartSeriesOptions[] = this.getBenchmarkSeries(benchmarkText, benchmarkData, groupedData);
    const handleDrill = (data: any) => this.handleDrill(data, this.viewByNode, this.currentNodePath, this.cptViewType, this.multilevelTab,
      this.drillInto, this.ngRedux, this.drillDownService, this.analyticsService);
    this.options = {
      lang: DEFAULT_LANG_OBJECT,
      title: BLANK_TITLE_OBJECT,
      series: concat(graphSeries, chargesSeries, benchmarkSeries),
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: DEFAULT_TOOLTIP_OBJECT,
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.CHART_WIDTH,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100
      },
      credits: DEFAULT_CREDITS_OBJECT,
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground
        },
        // @ts-ignore
        clickOnCrosshair: (e: any, p: any) => handleDrill(groupedData[p.index]),
        max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS : categories.length - 1,
        labels: {
          rotation: 45
        }
      },
      yAxis: leftSideYAxis.concat(rightSideYAxis),
      scrollbar: {
        // @ts-ignore
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries,
          tooltip: {
            pointFormat: ''
          }
        }
      }
    };
    if (!this.viewByNode && this.cptViewType === CptViewType.CptCode && this.options.xAxis) {
      // @ts-ignore
      delete this.options.xAxis.clickOnCrosshair;
    }
  }

  private writeNodepathsToCfpNodes(nodePaths: string[], groupedByNode: { [p: string]: CfpByMultilevel[] },
                                   cfpNodes: CfpGroupedByNodeInfo[]) {
    const nodeAlreadyAssigned: { [key: string]: boolean } = {};
    nodePaths.forEach(path => {
      if (!nodeAlreadyAssigned[path]) {
        const cfps: CfpByMultilevel[] = groupedByNode[path];
        cfpNodes.push({
          providerNodeName: cfps[0].providerNodeName,
          providerNodePath: cfps[0].providerNodePath,
          providerNodeId: cfps[0].providerNodeId,
          specialtyNodeName: cfps[0].specialtyNodeName,
          specialtyNodePath: cfps[0].specialtyNodePath,
          specialtyNodeId: cfps[0].specialtyNodeId,
          departmentNodeName: cfps[0].departmentNodeName,
          departmentNodePath: cfps[0].departmentNodePath,
          departmentNodeId: cfps[0].departmentNodeId,
          ...aggregateCPTData(cfps)
        });
        nodeAlreadyAssigned[path] = true;
      }
    });
  }

  private filterOutUnselectedCptEntities(cptViewType: CptViewType) {
    const cptLevelDescField = cptViewType === CptViewType.CptCode ? 'cptCode' : `cpt${getCptLevelKeyword(this.cptViewType)}Desc`;
    return this.cfpData.filter(x => this.currentSelectedCptNode && this.currentSelectedCptNode.identity ?
      x[cptLevelDescField] === this.currentSelectedCptNode.identity : true);
  }

  private filterOutUnselectedNodes(multilevelTab: MultilevelTab) {
    let nodePath = '';
    return this.cfpData.filter(x => {
      nodePath = `${x[this.getNodePathFieldString(multilevelTab)]}` ?? '';
      return !this.currentSelectedOntologyCptNode || this.currentSelectedOntologyCptNode.identity === ''
        || nodePath === this.currentSelectedOntologyCptNode.identity;
    });
  }

  getSeries(
    name: string,
    yAxis: number,
    graphData: number[],
    type: ChartType,
    colorIndex: HighChartColorIndex,
    groupedData: any[],
    additionalOptions: any,
    unit: string = ''
  ) {
    const handleDrill = (data: any) => this.handleDrill(data, this.viewByNode, this.currentNodePath, this.cptViewType, this.multilevelTab,
      this.drillInto, this.ngRedux, this.drillDownService, this.analyticsService);
    const chartSeries = {
      name,
      yAxis,
      type,
      colorIndex,
      zIndex: 0,
      data: createEmptyValues(graphData, DEFAULT_MAX_NUMBER_SCROLLBARS),
      tooltip: {
        pointFormat: `<span class="highcharts-color-${colorIndex}">\u25CF</span> {series.name}: <b>${unit}{point.y:,.0f}</b><br/>`,
        valueDecimals: 0
      },
      events: {
        click: (event: PointClickEventObject) => handleDrill(groupedData[event.point.index])
      }
    };
    return [{
      ...chartSeries,
      ...additionalOptions
    }];
  }

  getGraphSeries(
    text: string,
    graphData: number[],
    groupedData: any[]) {
    const options = {pointWidth: DEFAULT_POINT_WIDTH, stack: 0};
    return this.getSeries(text, 0, graphData, ChartType.COLUMN, HighChartColorIndex.TEAL, groupedData, options);
  }

  getBenchmarkSeries(
    benchmarkText: string,
    benchmarkData: any[],
    groupedData: any[]) {
    const options = {marker: {symbol: ChartMarkerSymbol.SQUARE}};
    return this.getSeries(benchmarkText, 0, benchmarkData, ChartType.LINE, HighChartColorIndex.GREY, groupedData, options);
  }

  getChargesSeries(
    chargesData: any[],
    groupedData: any[]) {
    const options = {pointWidth: DEFAULT_POINT_WIDTH, stack: 0};
    const yAxis = this.isChargesRequired() ? 1 : 0;
    return this.getSeries('Charges', yAxis, chargesData, ChartType.LINE, HighChartColorIndex.ORANGE, groupedData, options, '$');
  }

  setUpLegendsForGraph() {
    this.legends = [];
    switch (this.cfpVariableViewType) {
      case CfpVariableViewType.CfteAdjustedwRVU:
        this.addLegend(cftAdjCountLegend);
        break;
      case CfpVariableViewType.ActualwRVU:
        this.addLegend(actualWrvuLegend);
        break;
      case CfpVariableViewType.CfteAdjustedCount:
        this.addLegend(cfteWrvuLegend);
        break;
      case CfpVariableViewType.ActualCount:
        this.addLegend(countLegend);
        break;
    }

    if (isBenchmarkRequired(this.cfpVariableViewType)) {
      this.addLegend(benchmarkLegend);
    }

    if (this.isChargesRequired()) {
      this.addLegend(chargesLegend);
    }
  }

  private isChargesRequired() {
    return this.procedureSummaryVariables.charges;
  }

  private addLegend(legend: Legend) {
    this.legends.push(legend);
  }

  private getRightSideLabel(): string {
    return this.isChargesRequired() ? 'Charges' : '';
  }

  getNodePathFieldString(tab: MultilevelTab) {
    return `${getSingularOntologyLevelName(tab).toLowerCase()}NodePath`;
  }
}
