import {Component, Inject, Input, OnChanges} from '@angular/core';
import {MetricType} from '../../../shared/metric-types';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {DropdownOption} from '../../../shared/models';
import {updateCommunityBenchmarkAlertStatus} from '../../../shared/reducer-helper';
import {BenchmarkPercentile, getBenchmarkLabel, readableNameOf, readableNameOfColumnDef} from '../../../shared/benchmark-types';
import {LegendData, OverviewChartOptions} from '../../../overview/models/overview-models';
import * as _ from 'lodash';
import {
  formatNumberToWholeNumber,
  roundTo,
  roundToNumber,
  roundToWithPercentageOrElseNull
} from '../../../productivity-summary/number-formatter';
import {checkForNulls, hasNonZeroValue} from '../../../shared/null-helpers';
import {
  backgroundColor,
  BarChartOptions,
  calculateChartWidth,
  ChartMarkerSymbol,
  chartType,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  DEFAULT_POINT_PADDING,
  FormatOption,
  getBarchartSeriesOption,
  getChartOptions,
  getLegendOptions,
  getTitleOptions,
  getXaxisOptions,
  getYAxisOption
} from '../../../shared/highcharts-helpers';
import {abbreviatedDateLabel} from '../../../productivity-summary/month-formatter';
import {
  MonthNewPatientVisitData,
  MonthNewPatientVisitEntry,
  NewPatientVisitTrendEntry
} from '../../../new-patient-visits/components/npv-models';
import {getDesignatedNpvBenchmarkObject} from '../../../new-patient-visits/components/npv-helpers';
import {BenchmarkPercentilesForNpv} from '../../../shared/BenchmarkColumns';
import {BenchmarkOption, HighChartColorIndex, LegendColor, LegendStyle} from '../../../shared/enums';

@Component({
  selector: 'app-npv-tile-batch-export',
  templateUrl: './npv-tile-batch-export.component.html',
  styleUrls: ['./npv-tile-batch-export.component.scss']
})
export class NpvTileBatchExportComponent implements OnChanges {
  @Input() benchmarkPercentile: BenchmarkPercentile = BenchmarkPercentile.Mean;
  @Input() benchmarkOption: BenchmarkOption = BenchmarkOption.Academic;
  @Input() selectedDateRange: string;
  @Input() previousDateRange: string;
  @Input() benchmarkLabel: string;
  @Input() npvData: MonthNewPatientVisitData;
  @Input() npvPreviousYearData: MonthNewPatientVisitData;
  @Input() NUMBER_OF_DATA_POINTS: number;
  title: string;
  POINT_WIDTH = 21;
  snapshotBarCount = 12;
  chartWidth = calculateChartWidth(this.POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING);
  OVERVIEW_CHART_HEIGHT = 200;
  comparedTo: string;
  legendData: LegendData[];
  overviewChartOptions: OverviewChartOptions;
  varianceSubtractFrom: string;
  varianceSubtract: string;
  variance: string;
  varianceClass: string;
  previousYearDifference: string;
  previousYearDifferenceClass: string;
  benchmarkPlaceholder: string;
  hasCommunityBenchmarks: boolean;
  hasAcademicBenchmarks: boolean;
  hasBenchmarks: boolean;
  npvSelectedRangeTrendData: MonthNewPatientVisitEntry[];
  previousNpvSelectedRangeTrendData: MonthNewPatientVisitEntry[];

  MetricType = MetricType;

  // noinspection JSUnusedLocalSymbols (this actually is used in the html)
  protected benchmarkOptions: DropdownOption[] = [
    {value: BenchmarkPercentile.Mean, name: 'benchmark mean'},
    {value: BenchmarkPercentile.Percentile25th, name: '25th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile50th, name: '50th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile65th, name: '65th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile75th, name: '75th percentile benchmark'},
    {value: BenchmarkPercentile.Percentile90th, name: '90th percentile benchmark'},
  ];

  constructor(
    @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
    private ngRedux: NgRedux<IAppState>,
  ) {
  }

  ngOnChanges(): void {
    this.populateNpvTrendTile(this.npvData, this.npvPreviousYearData);
    this.populateNpvOverviewDetails(this.benchmarkPercentile, this.benchmarkOption);
    this.benchmarkOptions = this.benchmarkOptions.filter(option => option.value !== BenchmarkPercentile.Percentile65th);

    updateCommunityBenchmarkAlertStatus(
      this.hasCommunityBenchmarks,
      this.hasAcademicBenchmarks,
      this.ngRedux
    );
  }

  populateNpvTrendTile(npvData: MonthNewPatientVisitData, npvPreviousYearData: MonthNewPatientVisitData) {
    if (!npvData || !npvData.monthNewPatientVisitCounts) {
      return;
    }
    const data = npvData.monthNewPatientVisitCounts;
    const previousData = npvPreviousYearData.monthNewPatientVisitCounts;
    let academicBenchmarks: MonthNewPatientVisitEntry[] = [];
    const academicArray = [BenchmarkOption.Academic, BenchmarkOption.TelehealthAcademic,
      BenchmarkOption.InPersonAcademic];
    academicArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
      BenchmarkPercentilesForNpv.forEach(bt => {
        academicBenchmarks = academicBenchmarks.concat(data.filter(x => {
          const value = x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`];
          return hasNonZeroValue(value);
        }));
      });
    });
    let communityBenchmarks: MonthNewPatientVisitEntry[] = [];
    const communityArray = [BenchmarkOption.Community, BenchmarkOption.TelehealthCommunity,
      BenchmarkOption.InPersonCommunity];
    communityArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
      BenchmarkPercentilesForNpv.forEach(bt => {
        communityBenchmarks = communityBenchmarks.concat(data.filter(x => {
          const value = x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`];
          return hasNonZeroValue(value);
        }));
      });
    });
    this.hasCommunityBenchmarks = communityBenchmarks.length > 0;
    this.hasAcademicBenchmarks = academicBenchmarks.length > 0;
    this.hasBenchmarks = this.ngRedux.getState().display.viewCommunityBenchmarks ? this.hasCommunityBenchmarks : this.hasAcademicBenchmarks;
    this.npvSelectedRangeTrendData = _.orderBy(data.slice(), ['year', 'month'], ['asc', 'asc']);
    this.previousNpvSelectedRangeTrendData = _.orderBy(previousData.slice(), ['year', 'month'], ['asc', 'asc']);
    this.benchmarkPlaceholder = getBenchmarkLabel(this.benchmarkPercentile) + ' ';
    this.overviewChartOptions = this.populateNpvOverviewChartDetails(this.benchmarkPercentile, this.benchmarkOption);
  }

  populateNpvOverviewDetails(npvBenchmarkPercentile: BenchmarkPercentile, npvBenchmarkOption: BenchmarkOption) {
    this.title = '% New Patients Trend';
    this.comparedTo = ' compared to ';
    this.varianceSubtractFrom = '% New Patients';
    this.varianceSubtract = this.benchmarkLabel.toLowerCase() + ' benchmark ' +
      readableNameOf(npvBenchmarkPercentile, ' percentile').toLowerCase();
    const currentNpvPercentage = this.getNpvPercentage(this.npvSelectedRangeTrendData);
    const previousNpvPercentage = this.getNpvPercentage(this.previousNpvSelectedRangeTrendData);
    const npvSummaryBenchmarkValue =
      this.lookupNPVSummaryBenchmarkValue(npvBenchmarkPercentile, npvBenchmarkOption, this.npvSelectedRangeTrendData, false);

    this.legendData = [
      {
        name: '% New Patients',
        currentDatesValue: roundTo(currentNpvPercentage, 1) + '%',
        currentDatesIcon: LegendStyle.CIRCLE,
        currentDatesColor: LegendColor.TEAL,
        previousDatesValue: roundTo(previousNpvPercentage, 1) + '%',
        previousDatesIcon: LegendStyle.SQUARE,
        previousDatesColor: LegendColor.PURPLE,
      },
      {
        name: '% New Patients',
        currentDatesIcon: LegendStyle.DIAMOND,
        currentDatesColor: LegendColor.GREY,
        currentDatesValue: roundToWithPercentageOrElseNull(npvSummaryBenchmarkValue * 100, 1),
        previousDatesValue: roundToWithPercentageOrElseNull(this.lookupNPVSummaryBenchmarkValue(
          npvBenchmarkPercentile, npvBenchmarkOption, this.previousNpvSelectedRangeTrendData, false) * 100, 1),
        class: 'npv-benchmark',
        benchmark: true
      },
      {
        name: '# New / Total Patients',
        currentDatesValue: this.getNPVRatio(this.npvSelectedRangeTrendData),
        previousDatesValue: this.getNPVRatio(this.previousNpvSelectedRangeTrendData)
      },
    ];

    if ((currentNpvPercentage - npvSummaryBenchmarkValue * 100) < 0) {
      this.variance = roundTo(checkForNulls((currentNpvPercentage - npvSummaryBenchmarkValue * 100)) * -1, 1) + '% below';
      this.varianceClass = 'negative';
    } else {
      this.variance = roundTo(checkForNulls((currentNpvPercentage - npvSummaryBenchmarkValue * 100)), 1) + '% above';
      this.varianceClass = 'positive';
    }

    if (currentNpvPercentage - previousNpvPercentage < 0) {
      this.previousYearDifference =
        roundTo(checkForNulls(currentNpvPercentage - previousNpvPercentage), 1) + '% decrease';
      this.previousYearDifferenceClass = 'negative';
    } else {
      this.previousYearDifference = roundTo(checkForNulls(currentNpvPercentage - previousNpvPercentage), 1) + '% increase';
      this.previousYearDifferenceClass = 'positive';
    }
  }

  populateNpvOverviewChartDetails(npvBenchmarkPercentile: BenchmarkPercentile, npvBenchmarkOption: BenchmarkOption): OverviewChartOptions {
    const selectedRangeNewPatientVisitPercentage = this.npvSelectedRangeTrendData.map((visits: MonthNewPatientVisitEntry) =>
      roundToNumber(visits.newPatientVisitsPercentage, 1));

    const selectedRangeBarOptions: BarChartOptions = {
      name: '% New Patients',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.TEAL,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 2,
      data: selectedRangeNewPatientVisitPercentage.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(selectedRangeNewPatientVisitPercentage, this.NUMBER_OF_DATA_POINTS, 0) :
        selectedRangeNewPatientVisitPercentage,
      marker: {
        symbol: ChartMarkerSymbol.CIRCLE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };

    const previousRangeNewPatientVisitPercentage = this.previousNpvSelectedRangeTrendData.map((visits: MonthNewPatientVisitEntry) =>
      roundToNumber(visits.newPatientVisitsPercentage, 1));

    const previousRangeBarOptions = {
      name: 'Previous % New Patients',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.PURPLE,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 1,
      data: previousRangeNewPatientVisitPercentage.length < this.NUMBER_OF_DATA_POINTS ?
        createEmptyValues(previousRangeNewPatientVisitPercentage, this.NUMBER_OF_DATA_POINTS, 0) :
        previousRangeNewPatientVisitPercentage,
      marker: {
        symbol: ChartMarkerSymbol.SQUARE
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };

    const benchmarks = this.npvSelectedRangeTrendData.map((visits: MonthNewPatientVisitEntry) =>
      this.lookupNPVBenchmarkValue(npvBenchmarkPercentile, visits, npvBenchmarkOption) * 100);

    const selectedBenchmarksRangeBarOptions: BarChartOptions = {
      name: '% ' + this.benchmarkLabel + ' New Patients ' + readableNameOf(npvBenchmarkPercentile, ' percentile') + ' Benchmark',
      yAxis: 0,
      type: chartType.Line,
      colorIndex: HighChartColorIndex.GREY,
      pointWidth: this.POINT_WIDTH,
      stack: 0,
      zIndex: 3,
      data: createEmptyValues(benchmarks, this.NUMBER_OF_DATA_POINTS, 0),
      marker: {
        symbol: ChartMarkerSymbol.DIAMOND
      },
      tooltip: {
        pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
        valueDecimals: 0
      }
    };

    const categories = this.npvSelectedRangeTrendData.map((visit) => abbreviatedDateLabel(visit.month, visit.year));
    const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;

    return {
      title: getTitleOptions(''),
      series: previousRangeBarOptions
        ? [getBarchartSeriesOption(selectedRangeBarOptions, true),
          getBarchartSeriesOption(previousRangeBarOptions, true),
          getBarchartSeriesOption(selectedBenchmarksRangeBarOptions, true)]
        : [getBarchartSeriesOption(selectedRangeBarOptions, true),
          getBarchartSeriesOption(selectedBenchmarksRangeBarOptions, true)],
      legend: getLegendOptions(false),
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        ...getChartOptions(chartType.Line, backgroundColor.Transparent, this.chartWidth, this.OVERVIEW_CHART_HEIGHT),
        animation: true
      },
      xAxis: [getXaxisOptions(categories, isScrolled, DEFAULT_MAX_NUMBER_SCROLLBARS)],
      yAxis: getYAxisOption('', selectedBenchmarksRangeBarOptions ? Math.max(...selectedRangeNewPatientVisitPercentage,
        ...previousRangeNewPatientVisitPercentage, ...benchmarks) : Math.max(...benchmarks),
        FormatOption.Percentage),
      showFullScrollbar: false,
      enableScrollbar: isScrolled,
      legends: [],
    };
  }

  getNPVRatio(npvMonthData: MonthNewPatientVisitEntry[]): string {
    let npvCount = 0;
    let npvTotalCount = 0;
    npvMonthData?.forEach((data: MonthNewPatientVisitEntry) => {
      npvCount += checkForNulls(data.countOfNewPatientVisits);
      npvTotalCount += checkForNulls(data.countOfTotalPatientVisits);
    });
    return formatNumberToWholeNumber(npvCount) + ' / ' + formatNumberToWholeNumber(npvTotalCount);
  }

  private lookupNPVBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    monthData: MonthNewPatientVisitEntry,
    benchmarkOption: BenchmarkOption): number {
    const benchmarkValue = monthData[getDesignatedNpvBenchmarkObject(benchmarkOption)]
      [`benchmark${readableNameOfColumnDef(benchmarkPercentile)}`];
    return checkForNulls(benchmarkValue);
  }

  private lookupNPVSummaryBenchmarkValue(
    benchmarkPercentile: BenchmarkPercentile,
    benchmarkOption: BenchmarkOption,
    monthData: MonthNewPatientVisitEntry[],
    viewCommunityBenchmarks: boolean
  ): number {
    return viewCommunityBenchmarks ?
      this.getNpvCommunityBenchmarkValue(monthData, benchmarkPercentile, benchmarkOption) :
      this.getNpvBenchmarkValue(monthData, benchmarkPercentile, benchmarkOption);
  }

  getNpvPercentage(npvMonthData: MonthNewPatientVisitEntry[]): number {
    let npvCount = 0;
    let npvTotalCount = 0;
    npvMonthData?.forEach((data: NewPatientVisitTrendEntry) => {
      npvCount = npvCount + checkForNulls(data.countOfNewPatientVisits);
      npvTotalCount = npvTotalCount + checkForNulls(data.countOfTotalPatientVisits);
    });
    return npvTotalCount === 0 ? 0 : (npvCount * 10000 / npvTotalCount) / 100;
  }

  getNpvBenchmarkValue(
    npvMonthData: MonthNewPatientVisitEntry[],
    benchmarkPercentile: BenchmarkPercentile,
    benchmarkOption: BenchmarkOption
  ): number {
    let benchmarkValue = 0;
    let totalNpv = 0;
    npvMonthData?.forEach((data: MonthNewPatientVisitEntry) => {
      benchmarkValue += checkForNulls(data.countOfTotalPatientVisits) * checkForNulls(data[
        getDesignatedNpvBenchmarkObject(benchmarkOption)][`benchmark${readableNameOfColumnDef(benchmarkPercentile)}`]);
      totalNpv += checkForNulls(data.countOfTotalPatientVisits);
    });
    return totalNpv === 0 ? 0 : benchmarkValue / totalNpv;
  }

  getNpvCommunityBenchmarkValue(
    npvMonthData: MonthNewPatientVisitEntry[],
    benchmarkPercentile: BenchmarkPercentile,
    benchmarkOption: BenchmarkOption
  ): number {
    let benchmarkValue = 0;
    let totalNpv = 0;
    npvMonthData.forEach((data: MonthNewPatientVisitEntry) => {
      benchmarkValue += checkForNulls(data.countOfTotalPatientVisits) * checkForNulls(
        data[getDesignatedNpvBenchmarkObject(benchmarkOption)][`benchmark${benchmarkPercentile}`]);
      totalNpv += checkForNulls(data.countOfTotalPatientVisits);
    });
    return totalNpv === 0 ? 0 : benchmarkValue / totalNpv;
  }

  getBenchmarkHeaderClass(legend: LegendData): string {
    let headerClass = legend.benchmark ? 'legend-bchmk-header' : '';
    headerClass = headerClass.concat(' npv-legend-header');
    return headerClass;
  }
}
