import {Component, Inject, Input, OnInit} from '@angular/core';
import {DropdownOption} from '../shared/models';
import {BenchmarkPercentile} from '../shared/benchmark-types';
import {benchmarkChangedTo} from '../store/actions';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {Observable} from 'rxjs';
import {benchmarkPercentileSeriesName} from '../shared/helpers';

@Component({
  selector: 'app-legend-benchmark-percentile',
  templateUrl: './legend-benchmark-percentile.component.html',
  styleUrls: ['./legend-benchmark-percentile.component.scss']
})
export class LegendBenchmarkPercentileComponent implements OnInit {

  @Input() defaultBenchmarkPercentile: BenchmarkPercentile | undefined;
  @Input() benchmarkToExclude: string[] | undefined;
  @Input() originClass: string;
  @Input() fromPdf: boolean;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  benchmarkLabel: string;
  benchmarkPercentile: BenchmarkPercentile;
  BenchmarkPercentile = BenchmarkPercentile;

  benchmarkPercentiles: DropdownOption[] = [
    {value: BenchmarkPercentile.Mean, name: benchmarkPercentileSeriesName(BenchmarkPercentile.Mean)},
    {value: BenchmarkPercentile.Percentile25th, name: benchmarkPercentileSeriesName(BenchmarkPercentile.Percentile25th)},
    {value: BenchmarkPercentile.Percentile50th, name: benchmarkPercentileSeriesName(BenchmarkPercentile.Percentile50th)},
    {value: BenchmarkPercentile.Percentile65th, name: benchmarkPercentileSeriesName(BenchmarkPercentile.Percentile65th)},
    {value: BenchmarkPercentile.Percentile75th, name: benchmarkPercentileSeriesName(BenchmarkPercentile.Percentile75th)},
    {value: BenchmarkPercentile.Percentile90th, name: benchmarkPercentileSeriesName(BenchmarkPercentile.Percentile90th)},
  ];

  constructor(@Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
              private ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit() {
    if (this.benchmarkToExclude) {
      this.benchmarkToExclude.forEach(x => {
        this.benchmarkPercentiles = this.benchmarkPercentiles.filter(y => y.name !== x);
      });
    }
    let benchmarkPercentileThatShouldBeUsed: BenchmarkPercentile;
    this.benchmarkPercentile$.subscribe((benchmarkPercentileFromStore: BenchmarkPercentile) => {
      benchmarkPercentileThatShouldBeUsed = this.fromPdf ? this.defaultBenchmarkPercentile || BenchmarkPercentile.Mean :
        benchmarkPercentileFromStore;
      let includes = false;
      this.benchmarkPercentiles.forEach(option => {
        if (option.value === benchmarkPercentileThatShouldBeUsed) {
          includes = true;
        }
      });
      if (!includes) {
        this.benchmarkPercentile = this.defaultBenchmarkPercentile ? this.defaultBenchmarkPercentile : BenchmarkPercentile.Mean;
      } else {
        this.benchmarkPercentile = benchmarkPercentileThatShouldBeUsed;
      }
      const currentBenchmark = this.benchmarkPercentiles.find(x => x.value === this.benchmarkPercentile);
      this.benchmarkLabel = currentBenchmark ? currentBenchmark.name : '';
    });
  }

  updateBenchmarkPercentile(value: BenchmarkPercentile): void {
    this.ngRedux.dispatch(benchmarkChangedTo(value));
    this.analyticsService.handleGoogleAnalytics('wRVU by Provider Graph Benchmark', 'Benchmark Change',
      BenchmarkPercentile[value]);
  }

}
