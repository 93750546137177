import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NewFeatures} from '../store/IAppState';
import {environment} from '../../environments/environment.local';


export const NewFeaturesServiceToken = new InjectionToken<NewFeaturesService>('NewFeaturesService');


export interface NewFeaturesService {

  getNewFeatures(): Observable<NewFeatures>;

  saveNewFeatures(newFeatures: NewFeatures): Observable<NewFeatures>;

}

@Injectable()
export class HttpNewFeaturesService implements NewFeaturesService {

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }
  public getNewFeatures(): Observable<NewFeatures> {
    return this.httpClient.get<NewFeatures>(`${environment.api}/api/newFeatures`);
  }

  public saveNewFeatures(newFeatures: NewFeatures): Observable<NewFeatures> {
    return this.httpClient.post<NewFeatures>(`${environment.api}/api/saveNewFeatures`, newFeatures);
  }
}
