import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {MemberBillingArea} from '../shared/models';

export const MemberBillingAreaServiceToken =
  new InjectionToken<MemberBillingAreaService>('Member Billing Area Service');

export interface MemberBillingAreaService {
  getMemberBillingArea(memberKey: number): Observable<MemberBillingArea[]>;
}

@Injectable()
export class MemberBillingAreaServiceImpl implements MemberBillingAreaService {

  constructor(private readonly httpClient: HttpClient) { }

  getMemberBillingArea(memberKey: number): Observable<MemberBillingArea[]> {
    const url = `${environment.api}/api/getMemberBillingAreas`;

    return this.httpClient.get<MemberBillingArea[]>(
      url,
      {
        params:
          new HttpParams()
            .set('memberKey', memberKey.toString())
      },
    );
  }
}
