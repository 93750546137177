import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ColumnType, CptViewType, MultilevelTab} from '../../../shared/enums';
import {
  CfpByMultilevel,
  ClinicalSummaryCode,
  ClinicalSummaryFamily,
  ClinicalSummaryRange,
  groupMultilevelCPTDataByCPTField
} from '../../ClinicalSummary';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {MatTableDataSource} from '@angular/material/table';
import {formatNumberToWholeNumber} from '../../../productivity-summary/number-formatter';
import {checkForNulls, undefinedIfZero} from '../../../shared/null-helpers';

@Component({
  selector: 'app-summary-procedure-summary',
  templateUrl: './summary-procedure-summary.component.html',
  styleUrls: ['./summary-procedure-summary.component.scss']
})
export class SummaryProcedureSummaryComponent implements OnChanges {
  constructor() {
  }

  @Input() cptViewType: CptViewType;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() tab: MultilevelTab;
  @Input() receivedData: CfpByMultilevel[] = [];
  @Input() showProgressBar = false;
  @Input() showCfteWrvus: boolean;
  @Input() showCfteCount: boolean;

  dataSource: MatTableDataSource<any>;
  columns: DataTableColumns[] = [];
  displayedColumns: string[] = [];
  private groupedByFamily: ClinicalSummaryFamily[] = [];
  private groupedByRange: ClinicalSummaryRange[] = [];
  private groupedByCode: ClinicalSummaryCode[] = [];
  private NUMBER_OF_FAMILY_ROWS_TO_SHOW = 10;
  private NUMBER_OF_RANGE_ROWS_TO_SHOW = 10;
  private NUMBER_OF_CODE_ROWS_TO_SHOW = 10;
  private NUMBER_TO_LOAD = 10;

  private static getSummaryCptSharedColumns(viewCommunityBenchmarks: boolean,
                                            showCfteWrvus: boolean, showCfteCount: boolean): DataTableColumns[] {
    let summaryColumns: DataTableColumns[] = [{
      columnDef: 'wRVUs', header: 'Actual wRVUs', dataName: (row: ClinicalSummaryFamily) =>
        formatNumberToWholeNumber(checkForNulls(row.wRVUs))
    }];
    if (showCfteWrvus) {
      summaryColumns = summaryColumns.concat([{
        columnDef: 'cfteAdjustedWRVUs', header: 'cFTE adj. wRVUs', dataName: (row: ClinicalSummaryFamily) =>
          formatNumberToWholeNumber(checkForNulls(row.cfteAdjustedWRVUs))
      }, {
        columnDef: viewCommunityBenchmarks ? 'communityWorkRvuBenchmark' : 'workRvuBenchmark',
        header: viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' :
          'cFTE adj. wRVUs Academic Benchmark Mean',
        dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(undefinedIfZero(
          viewCommunityBenchmarks ? row.communityWorkRvuBenchmark : row.workRvuBenchmark)),
        columnType: ColumnType.BENCHMARK
      }]);
    }
    summaryColumns.push({
      columnDef: 'frequency', header: 'Count',
      dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(checkForNulls(row.frequency))
    });

    if (showCfteCount) {
      summaryColumns = summaryColumns.concat([{
        columnDef: 'cfteAdjustedFrequency', header: 'cFTE adj. Count',
        dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(checkForNulls(row.cfteAdjustedFrequency))
      }, {
        columnDef: 'frequencyBenchmark', header: viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' :
          'cFTE adj. Count Academic Benchmark Mean', columnType: ColumnType.FREQUENCY_BENCHMARK,
        dataName: (row: ClinicalSummaryCode) => formatNumberToWholeNumber(undefinedIfZero(viewCommunityBenchmarks ?
          row.communityFrequencyBenchmark : row.frequencyBenchmark))
      }]);
    }

    summaryColumns.push({
      columnDef: 'charges', header: 'Charges', dataName: (row: ClinicalSummaryCode) =>
        `$${formatNumberToWholeNumber(checkForNulls(row.charges))}`,
    });
    return summaryColumns;
  }

  public static getSummaryColumnsByCptViewType(cptViewType: CptViewType, viewCommunityBenchmarks: boolean,
                                               showCfteWrvus: boolean, showCfteCount: boolean): DataTableColumns[] {
    switch (cptViewType) {
      case CptViewType.CptFamily:
        return [{
          columnDef: 'cptFamilyDesc', header: 'Cpt Family', dataName: (row: ClinicalSummaryFamily) => row.cptFamilyDesc
        }, ...SummaryProcedureSummaryComponent.getSummaryCptSharedColumns(viewCommunityBenchmarks, showCfteWrvus, showCfteCount)];
      case CptViewType.CptRange:
        return [{
          columnDef: 'cptRangeLow', header: 'CPT Range',
          dataName: (row: ClinicalSummaryRange) => `${row.cptRangeLow} - ${row.cptRangeHigh} ${row.cptRangeDesc}`
        },
          ...SummaryProcedureSummaryComponent.getSummaryColumnsByCptViewType(CptViewType.CptFamily,
            viewCommunityBenchmarks, showCfteWrvus, showCfteCount)];
      case CptViewType.CptCode:
        return [{
          columnDef: 'cptCode', header: 'CPT Code',
          dataName: (row: ClinicalSummaryCode) => row.cptCode
        }, ...SummaryProcedureSummaryComponent.getSummaryColumnsByCptViewType(CptViewType.CptRange,
          viewCommunityBenchmarks, showCfteWrvus, showCfteCount)];
    }
  }

  ngOnChanges(changes ?: SimpleChanges): void {
    if (changes && changes.cptViewType && changes.cptViewType.previousValue !== changes.cptViewType.currentValue) {
      switch (changes.cptViewType.currentValue) {
        case 0:
          this.NUMBER_OF_RANGE_ROWS_TO_SHOW = 10;
          this.NUMBER_OF_CODE_ROWS_TO_SHOW = 10;
          break;
        case 1:
          this.NUMBER_OF_FAMILY_ROWS_TO_SHOW = 10;
          this.NUMBER_OF_CODE_ROWS_TO_SHOW = 10;
          break;
        case 2:
          this.NUMBER_OF_FAMILY_ROWS_TO_SHOW = 10;
          this.NUMBER_OF_RANGE_ROWS_TO_SHOW = 10;
      }
    }
    this.columns = SummaryProcedureSummaryComponent.getSummaryColumnsByCptViewType(this.cptViewType,
      this.viewCommunityBenchmarks, this.showCfteWrvus, this.showCfteCount);
    this.displayedColumns = this.columns.map(x => x.columnDef).slice();
    switch (this.cptViewType) {
      case CptViewType.CptFamily:
        this.groupedByFamily = groupMultilevelCPTDataByCPTField(this.receivedData, 'cptFamilyDesc');
        break;
      case CptViewType.CptRange:
        this.groupedByRange = groupMultilevelCPTDataByCPTField(this.receivedData, 'cptRangeDesc');
        break;
      case CptViewType.CptCode:
        this.groupedByCode = groupMultilevelCPTDataByCPTField(this.receivedData, 'cptCode');
    }
    this.getData();
  }

  private getData(): void {
    switch (this.cptViewType) {
      case CptViewType.CptFamily:
        this.dataSource = new MatTableDataSource<ClinicalSummaryFamily>(this.groupedByFamily.slice(0, this.NUMBER_OF_FAMILY_ROWS_TO_SHOW));
        break;
      case CptViewType.CptRange:
        this.dataSource = new MatTableDataSource<ClinicalSummaryRange>(this.groupedByRange.slice(0, this.NUMBER_OF_RANGE_ROWS_TO_SHOW));
        break;
      case CptViewType.CptCode:
        this.dataSource = new MatTableDataSource<ClinicalSummaryCode>(this.groupedByCode.slice(0, this.NUMBER_OF_CODE_ROWS_TO_SHOW));
    }
  }

  handleScroll(event ?: any): void {
    switch (this.cptViewType) {
      case CptViewType.CptFamily:
        this.NUMBER_OF_FAMILY_ROWS_TO_SHOW += this.NUMBER_TO_LOAD;
        break;
      case CptViewType.CptRange:
        this.NUMBER_OF_RANGE_ROWS_TO_SHOW += this.NUMBER_TO_LOAD;
        break;
      case CptViewType.CptCode:
        this.NUMBER_OF_CODE_ROWS_TO_SHOW += this.NUMBER_TO_LOAD;
    }
    if (this.dataSource.data.length < this.receivedData.length) {
      const table: any | undefined = event ? event.target : undefined;
      if (table && (table.scrollTop > 0.9 * (table.scrollHeight - table.clientHeight))) {
        this.getData();
      }
    }
  }
}
