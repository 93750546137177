<div class="wrapper">
  <div id="overview-patient-visit-charts">
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart id="new-patients"
                                       chartId="new-patient-visits"
                                       [categories]="newPatientVisitsCategories"
                                       chartTitle="Office/Outpatient Visit New"
                                       [maxYAxisValue]="maxNewPatientYAxisValue"
                                       [totalCptSummaries]="totalNewPatientsCptSummaries"
                                       [specialtyCptSummaries]="specialtyTotalNewPatientsCptSummaries"
                                       [chartHeight]="chartHeight"
                                       [chartWidth]="chartWidth"
                                       [isSingleProviderSelected]="true"
                                       [foundNodeName]="foundNodeName"
                                       [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart id="established-patients"
                                       chartId="established-patient-visits"
                                       [categories]="establishedPatientVisitsCategories"
                                       [maxYAxisValue]="maxEstablishedPatientPatientYAxisValue"
                                       chartTitle="Office/Outpatient Visit Established"
                                       [totalCptSummaries]="totalEstablishedPatientsCptSummaries"
                                       [specialtyCptSummaries]="specialtyTotalEstablishedPatientsCptSummaries"
                                       [chartHeight]="chartHeight"
                                       [chartWidth]="chartWidth"
                                       [isSingleProviderSelected]="true"
                                       [foundNodeName]="foundNodeName"
                                       [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart id="consultations"
                                       chartId="consultations"
                                       [categories]="consultationsCategories"
                                       [maxYAxisValue]="maxconsultationsYAxisValue"
                                       chartTitle="Office Consultation"
                                       [totalCptSummaries]="totalConsultationsCptSummaries"
                                       [specialtyCptSummaries]="specialtyTotalConsultationsCptSummaries"
                                       [chartHeight]="chartHeight"
                                       [chartWidth]="chartWidth"
                                       [isSingleProviderSelected]="true"
                                       [foundNodeName]="foundNodeName"
                                       [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
  </div>
  <app-chart-legend
    [fromPdf]="true"
    [legends]="legends"
    [metricType]="metricType"
    [showExtendedBenchmarkOptions]="true"
    [benchmarkOption]="benchmarkOption"
  ></app-chart-legend>
  <div *ngIf="showTable" id="table-container">
    <app-em-snapshot-table [receivedData]="tableData" [columns]="columns" [fromPdf]="true"></app-em-snapshot-table>
  </div>
</div>
