
<span id="batch-npv-trend-table-wrapper">
  <span id="batch-npv-trend-title">New Patients Trend By Month</span>
  <mat-table [dataSource]="dataSource" id="batch-npv-trend-table">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef id="{{column.columnDef}}"><span class="header-text">{{column.header}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container><div class="data-text {{column.columnDef}} {{column.class ? column.class(row) : ''}}">
          {{column.dataName(row)}}</div></ng-container>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <ng-container><mat-row *matRowDef="let row; columns: displayedColumns"></mat-row></ng-container>
  </mat-table>
</span>
