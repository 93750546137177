import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {
  CfpByMultilevel,
  getNextCptGranularity,
  GroupedCfpByMultilevel,
  groupMultilevelCPTDataByCPTField,
  isCptViewTypeDrillable,
  ProcedureSummaryDrill
} from '../../ClinicalSummary';
import {CptViewType, GoogleAnalyticCategories, MultilevelTab} from '../../../shared/enums';
import {isFeatureEnabled} from '../../../shared/helpers';
import {FeatureToggleEntries} from '../../../shared/feature-toggle-settings-enum';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../store/IAppState';
import {MatDialog} from '@angular/material/dialog';
import {ProcedureSummaryFilterComponent} from '../../procedure-summary/procedure-summary-filter/procedure-summary-filter.component';
import {MatSort} from '@angular/material/sort';
import {CptGroup} from '../../procedure-summary/ProcedureSummaryModels';
import {selectedCptGroupChangedTo} from '../../../store/actions';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';

@Component({
  selector: 'app-cfp-multilevel-table',
  templateUrl: './cfp-multilevel-table.component.html',
  styleUrls: ['./cfp-multilevel-table.component.scss']
})
export class CfpMultilevelTableComponent implements OnChanges {

  @Input() cptViewType: CptViewType;
  @Input() tab: MultilevelTab;
  @Input() receivedData: CfpByMultilevel[] = [];
  @Input() originalCfpDataForSearchModal: CfpByMultilevel[] = [];
  @Input() columns: DataTableColumns[] = [];
  @Input() tableTitle = '';
  @Input() showProgressBar = false;
  @Input() cptGroups: CptGroup[] | undefined;
  @Input() selectedGroup: CptGroup | undefined;
  @Output() drillInto = new EventEmitter<ProcedureSummaryDrill>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  headerStickyClass = '';
  private turningPointOfScroll = -1;

  pageSize = 5;
  pageSizeOptions = [5, 10, 15, 20];
  dataSource: MatTableDataSource<GroupedCfpByMultilevel>;
  pageIndex = 0;
  displayedColumns: string[] = [];
  dataSources: ScrollableCfpMultilevelRow[];
  private NUMBER_TO_LOAD = 2;
  private INITIAL_NUMBER_TO_SHOW = 5;

  groupedTableData: GroupedCfpByMultilevel[] = [];
  cfFilterEnabled: boolean;

  constructor(private _cdr: ChangeDetectorRef, public dialog: MatDialog, private readonly ngRedux: NgRedux<IAppState>,
              @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService) { }

  ngOnChanges(): void {
    const { featureToggleSettings, userProfile } = this.ngRedux.getState().data;
    this.cfFilterEnabled = isFeatureEnabled(FeatureToggleEntries.CPT_FILTER, featureToggleSettings, userProfile);
    if (this.receivedData) {
      this.groupedTableData = [];
      const groupedByNode: { [key: string]: CfpByMultilevel[] } = {};
      this.receivedData.forEach(datum => {
        const nodePath: string = (this.tab === MultilevelTab.BY_PROVIDER && datum.providerNodePath)
          || (this.tab === MultilevelTab.BY_SPECIALTY && datum.specialtyNodePath)
          || datum.departmentNodePath;
        groupedByNode[nodePath] = groupedByNode[nodePath] || [];
        groupedByNode[nodePath].push(datum);
      });
      this.groupedTableData = Object.keys(groupedByNode).map(nodePath => ({
        providerNodeName: groupedByNode[nodePath][0].providerNodeName,
        specialtyNodeName: groupedByNode[nodePath][0].specialtyNodeName,
        departmentNodeName: groupedByNode[nodePath][0].departmentNodeName,
        data: dataGroupedByCptViewType(groupedByNode[nodePath], this.cptViewType),
        itemsToShow: this.INITIAL_NUMBER_TO_SHOW
      }));
      this.dataSource = new MatTableDataSource<GroupedCfpByMultilevel>(this.groupedTableData.slice());
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.pageSize = this.pageSize;
      this.dataSource.paginator.pageIndex = this.pageIndex;
      this.dataSource.paginator.length = this.receivedData.length;
      this.setDataSources();
    }
  }

  private setDataSources(): void {
    this.dataSources = [];
    this.groupedTableData.slice(this.pageIndex * this.pageSize, this.pageIndex * this.pageSize + this.pageSize).forEach(gTD => {
      const nextSource = new MatTableDataSource<GroupedCfpByMultilevel>([{...gTD,
        data: gTD.data.slice(0, gTD.itemsToShow)}]);
      this.dataSources.push({dataSource: nextSource, original: gTD});
    });
    this.displayedColumns = this.columns.map(x => x.columnDef).slice();
    this._cdr.detectChanges();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header = document.getElementById('header-row');
    if (header) {
      const boundingRectangle = header.getBoundingClientRect();
      if (boundingRectangle.bottom > boundingRectangle.height || window.scrollY < this.turningPointOfScroll - boundingRectangle.height) {
        this.turningPointOfScroll = -1;
        this.headerStickyClass = '';
      } else {
        this.turningPointOfScroll = this.turningPointOfScroll === -1 ? window.scrollY : this.turningPointOfScroll;
        this.headerStickyClass = 'sticky-header';
      }
    }
  }

  whenHeaderSortSelected(): void {
    this.dataSources.forEach(source => {
      const originalData = source.dataSource.data[0].data.slice(0, source.original.itemsToShow);
      const viewType = this.cptViewType;
      const tieBreaker = (item: CfpByMultilevel) => {
        switch (viewType) {
          case CptViewType.CptFamily:
            return `${item.cptFamilyDesc}`;
          case CptViewType.CptRange:
            return `${item.cptRangeDesc}`;
          case CptViewType.CptCode:
            return `${item.cptCode}`;
        }
      };
      const sortColumn = this.sort.active;
      const sortDirection = this.sort.direction;
      source.dataSource.data[0].data = originalData.sort(function(a, b) {
        return compareCfpMultilevel(a, b, sortColumn, sortDirection === 'asc', tieBreaker);
      });
    });
  }

  updateDataTable(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.setDataSources();
  }

  canDrill(column: DataTableColumns): boolean {
    return ['cptFamilyDesc', 'cptRangeLow'].includes(column.columnDef);
  }

  getCptViewTypeFromColumnDef(columnDef: string): CptViewType {
    switch (columnDef) {
      case 'ctpFamilyDesc':
        return CptViewType.CptFamily;
      case 'cptRangeLow':
        return CptViewType.CptRange;
      case 'cptCode':
           return CptViewType.CptCode;
      default:
        return CptViewType.CptFamily;
    }
  }

  whenCptSelected(cpt: CfpByMultilevel, columnDef: string): void {
    if (isCptViewTypeDrillable(this.getCptViewTypeFromColumnDef(columnDef))) {
      const viewType = this.getCptViewTypeFromColumnDef(columnDef);
      this.drillInto.emit({displayText: getDrillText(cpt, viewType),
        viewType: getNextCptGranularity(viewType), cfp: cpt});
    }
  }

  selectGroup(group: CptGroup): void {
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ProcedureSummarySnapshot,
      'Procedure Summary Groupings', 'Saved CPT Groupings');
    this.ngRedux.dispatch(selectedCptGroupChangedTo(group));
  }

  openProcedureSummaryFilter() {
    this.dialog.open(ProcedureSummaryFilterComponent, { panelClass: 'custom-dialog-container',
      data: makeOnlyUniqueCodeRangeFamilyRows(this.originalCfpDataForSearchModal)});
  }

  departmentColumnClass(content: string) {
    return content?.length > 300;
  }

  whenRowScrolled(item: GroupedCfpByMultilevel, index: number) {
    if (item.itemsToShow < item.data.length) {
      item.itemsToShow += this.NUMBER_TO_LOAD;
      this.dataSources[index].dataSource = new MatTableDataSource<GroupedCfpByMultilevel>([{...item,
        data: item.data.slice(0, item.itemsToShow)}]);
      this._cdr.detectChanges();
    }
  }
}

export function makeOnlyUniqueCodeRangeFamilyRows(receivedData: CfpByMultilevel[]): CfpByMultilevel[] {
  const families: any = {};
  const ranges: any = {};
  const codes: any = {};
  const unique: CfpByMultilevel[] = [];
  let firstFamily = false, firstRange = false, firstCode = false;
  receivedData.forEach(entry => {
    firstFamily = false;
    firstRange = false;
    firstCode = false;
    if (!families[entry.cptFamilyDesc]) {
      families[entry.cptFamilyDesc] = true;
      firstFamily = true;
    }
    if (!ranges[entry.cptRangeDesc]) {
      ranges[entry.cptRangeDesc] = true;
      firstRange = true;
    }
    if (!codes[entry.cptCode]) {
      codes[entry.cptCode] = true;
      firstCode = true;
    }
    if (firstCode || firstRange || firstFamily)  {
      unique.push(entry);
    }
  });
  return unique;
}

export function dataGroupedByCptViewType(data: CfpByMultilevel[], cptViewType: CptViewType): CfpByMultilevel[] {
  switch (cptViewType) {
    case CptViewType.CptFamily:
      return groupMultilevelCPTDataByCPTField(data, 'cptFamilyDesc');
    case CptViewType.CptRange:
      return groupMultilevelCPTDataByCPTField(data, 'cptRangeDesc');
    case CptViewType.CptCode:
      return groupMultilevelCPTDataByCPTField(data, 'cptCode');
  }
  return data;
}

function compareCfpMultilevel(a: CfpByMultilevel, b: CfpByMultilevel, sortHeaderId: string, sortAscending: boolean,
                 getTieBreakerString: (element: CfpByMultilevel) => string): number {
  const valueA = a[sortHeaderId] || 0;
  const valueB = b[sortHeaderId] || 0;
  const multiplier = sortAscending ? 1 : -1;
  if (valueA > valueB) {
    return multiplier;
  }
  if (valueA < valueB) {
    return -1 * multiplier;
  }
  return getTieBreakerString(a).localeCompare(getTieBreakerString(b));
}

interface ScrollableCfpMultilevelRow {
  dataSource: MatTableDataSource<GroupedCfpByMultilevel>;
  original: GroupedCfpByMultilevel;
}

export function getDrillText(cfpByMultilevel: CfpByMultilevel, cptViewType: CptViewType): string {
  if (cptViewType === CptViewType.CptFamily) {
    return cfpByMultilevel.cptFamilyDesc;
  }
  if (cptViewType === CptViewType.CptRange) {
    return `${cfpByMultilevel.cptRangeLow} - ${cfpByMultilevel.cptRangeHigh} ${cfpByMultilevel.cptRangeDesc}`;
  }
  return '';
}
