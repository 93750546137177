import {DataTableColumns} from '../shared/data-table-columns';
import {ColumnType} from '../shared/enums';
import {
  createDenialsPaidBenchmarkColumnFor,
  createDenialsRateBenchmarkColumnFor,
  createDenialsVarianceColumnFor,
  createTerminalDenialsRateBenchmarkColumnFor
} from '../shared/BenchmarkColumns';
import {BenchmarkPercentile} from '../shared/benchmark-types';

export function updateDenialsBenchmarkDepartmentColumns(column: DataTableColumns, percentile: BenchmarkPercentile): DataTableColumns {
  switch (column.columnType) {
    case ColumnType.BENCHMARK:
      return createDenialsRateBenchmarkColumnFor(percentile);
    case ColumnType.DENIED_PAID_BENCHMARK:
      return createDenialsPaidBenchmarkColumnFor(percentile);
    case ColumnType.TERMINAL_DENIAL_BENCHMARK:
      return createTerminalDenialsRateBenchmarkColumnFor(percentile);
    case ColumnType.VARIANCE:
      return createDenialsVarianceColumnFor(percentile);
    default:
      return column;
  }
}
