import {ProductivitySummary} from '../../productivity-summary/services/ProviderProductivity';
import {FilterCriteria} from '../../store/IAppState';
import {
  formatNumberToWholeNumber,
  formatToCurrency,
  roundTo,
  roundToNumber
} from '../../productivity-summary/number-formatter';
import {checkForNulls, isValidOrElse, undefinedIfZero} from '../null-helpers';
import {
  EvaluationManagementEmergencyRoomExportRow,
  EvaluationManagementEmergencyRoomRow,
  EvaluationManagementEyeExamExportRow,
  EvaluationManagementEyeExamRow,
  EvaluationManagementInpatientExportRow,
  EvaluationManagementInpatientRow,
  EvaluationManagementOutpatientExportRow,
  EvaluationManagementOutpatientRow,
  Export,
  ExportMetadata
} from './export';
import {getFirstNodePathFrom, getGroupName, getRecentMonth, monthNames, roundToHundred} from '../helpers';
import {
  Breadcrumb,
  CurrentMemberLocation,
  EmDimension,
  EvaluationManagement,
  EvaluationManagementMultilevel,
  EvaluationManagementSummary,
  OntologyNode,
  ProviderCptCounts,
  ProviderMultilevelCptCounts
} from '../models';
import * as XLSX from 'xlsx';
import {
  CfpByMultilevel,
  ClinicalSummaryCode,
  ClinicalSummaryConsolidation,
  ClinicalSummaryFamily,
  ClinicalSummaryRange,
  GroupedCfpByMultilevel,
  ProcedureSummaryDrillImpl
} from '../../clinical-fingerprint/ClinicalSummary';
import {BenchmarkOption, ColumnType, CptViewType, MultilevelTabCollections} from '../enums';
import {
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkFieldFromOriginalBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromOriginalBenchmarkOptions,
  getEmergencyTableRowsFrom,
  getEyeExamTableRowsFrom,
  getInpatientTableRowsFrom,
  isAllowedToShow,
  parametersForZeroSuppression,
  rowForEmergencyRoom,
  rowForEyeExam,
  rowForInpatient,
  rowForOutpatient,
  valueFromOrElse
} from '../../evaluation-management/em-helpers';
import {
  DataTableColumns,
  getDepartmentColumnForCFP,
  getProviderColumnForCFP,
  getSpecialtyColumnForCFP
} from '../data-table-columns';
import {getOntologyData} from '../localStoragehelper';
import {DEFAULT_MEMBER_LOCATION, DEFAULT_PAYER_CATEGORY} from '../../store/DefaultValues';
import {ExportOption} from './export-metrics';
import * as moment from 'moment';
import {DataExportConfig} from '../../export-dialog/export-dialog.component';
import {
  SummaryProcedureSummaryComponent
} from '../../clinical-fingerprint/procedure-summary/summary-procedure-summary/summary-procedure-summary.component';
import {CptGroup} from '../../clinical-fingerprint/procedure-summary/ProcedureSummaryModels';
import {MergedNewPatientVisitSnapshotEntry} from '../../new-patient-visits/components/npv-models';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../benchmark-types';
import {
  getDesignatedNpvBenchmarkObject,
  getDesignatedNpvVarianceObject
} from '../../new-patient-visits/components/npv-helpers';
import {isClinicalSummaryZeroSuppressible} from '../../clinical-fingerprint/clinical-fingerprint-helpers';


export function recentMonth(productivitySummary: ProductivitySummary): string {
  if (!productivitySummary) {
    return '';
  }
  return getRecentMonth({
    endMonth: productivitySummary.endMonth,
    endYear: productivitySummary.endYear,
    startMonth: productivitySummary.startMonth,
    startYear: productivitySummary.startYear
  });
}


function getOutpatientEmProviderRow(isSingleProviderSelected: boolean, provider: ProviderCptCounts) {
  return rowForOutpatient(
    isSingleProviderSelected ? `${provider.nodeName} ${monthNames[Number(provider.nodePath) - 1]}` : provider.nodeName,
    provider.cptCategories.newPatientVisit,
    provider.cptCategories.establishedPatientVisit,
    provider.cptCategories.consultation,
    provider.nodePath,
    provider.nodeId
  );
}

export function getEmOutpatientExportDataWithOriginalBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                      summaryData: EvaluationManagementSummary,
                                                                      emExportTitle: string,
                                                                      isSingleProviderSelected: boolean,
                                                                      viewCommunityBenchmark: boolean,
): Export {
  const rows: EvaluationManagementOutpatientExportRow[] = [];
  const outPatientRows: EvaluationManagementOutpatientRow[] = evaluationManagementData.providerEvaluationManagement.nodes.map(
    (provider: ProviderCptCounts) => getOutpatientEmProviderRow(isSingleProviderSelected, provider));
  rows.push(getSummaryOutpatientEncounterRowForExport(summaryData));
  rows.push(getSummaryOutpatientPercentageRowForExport(summaryData));
  rows.push(getSummaryOutpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmark));
  rows.push(getBlankSummaryOutpatientEncounterRowForExport());

  outPatientRows.forEach((element) => {
    rows.push(getOutpatientEncounterRowForExport(element));
    rows.push(getOutpatientCodingDistributionRowForExport(element));
    rows.push(getOutpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmark));
  });

  return getEmOutpatientExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

function getEmOutpatientExportDetails(rows: EvaluationManagementOutpatientExportRow[],
                                      isSingleProviderSelected: boolean, emExportTitle: string) {
  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    fileName: 'outpatientEandM',
    page: 'EM Outpatient',
    title: emExportTitle
  };
}

export function getEmOutpatientExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                      summaryData: EvaluationManagementSummary,
                                                                      emExportTitle: string,
                                                                      isSingleProviderSelected: boolean,
                                                                      benchmarkOption: BenchmarkOption
): Export {
  const rows: EvaluationManagementOutpatientExportRow[] = [];
  const outPatientRows: EvaluationManagementOutpatientRow[] = evaluationManagementData.providerEvaluationManagement.nodes.map(
    (provider: ProviderCptCounts) => getOutpatientEmProviderRow(isSingleProviderSelected, provider));
  rows.push(getSummaryOutpatientEncounterRowForExport(summaryData));
  rows.push(getSummaryOutpatientPercentageRowForExport(summaryData));
  rows.push(getSummaryOutpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankSummaryOutpatientEncounterRowForExport());

  outPatientRows.forEach((element) => {
    rows.push(getOutpatientEncounterRowForExport(element));
    rows.push(getOutpatientCodingDistributionRowForExport(element));
    rows.push(getOutpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return getEmOutpatientExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

const nodeName = (n: ProviderMultilevelCptCounts, level: MultilevelTabCollections) => {
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      return n.departmentNodeName;
    case MultilevelTabCollections.BY_SPECIALTY:
      return n.specialtyNodeName + '/' + n.departmentNodeName;
    case MultilevelTabCollections.BY_PROVIDER:
      return n.providerNodeName + '/' + n.specialtyNodeName + '/' + n.departmentNodeName;
  }
  return '';
};

const nodePathFn = (n: ProviderMultilevelCptCounts, level: MultilevelTabCollections) => {
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      return n.departmentNodePath;
    case MultilevelTabCollections.BY_SPECIALTY:
      return n.specialtyNodePath;
    case MultilevelTabCollections.BY_PROVIDER:
      return n.providerNodePath;
  }
  return '';
};

const nodeIdFn = (n: ProviderMultilevelCptCounts, level: MultilevelTabCollections) => {
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      return n.departmentNodeId;
    case MultilevelTabCollections.BY_SPECIALTY:
      return n.specialtyNodeId;
    case MultilevelTabCollections.BY_PROVIDER:
      return n.providerNodeId;
  }
  return 0;
};

const levelNameFn = (level: MultilevelTabCollections) => {
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      return {name: 'By Department', abbr: 'ByDep'};
    case MultilevelTabCollections.BY_SPECIALTY:
      return {name: 'By Specialty', abbr: 'BySpe'};
    case MultilevelTabCollections.BY_PROVIDER:
      return {name: 'By Provider', abbr: 'ByPro'};
  }
  return {name: '', abbr: ''};
};

const nodesFn = (evaluationManagementData: EvaluationManagementMultilevel, level: MultilevelTabCollections) => {
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      return evaluationManagementData.evaluationManagementData.departmentEvaluationManagementSnapshotData.nodes;
    case MultilevelTabCollections.BY_SPECIALTY:
      return evaluationManagementData.evaluationManagementData.specialtyEvaluationManagementSnapshotData.nodes;
    case MultilevelTabCollections.BY_PROVIDER:
      return evaluationManagementData.evaluationManagementData.providerEvaluationManagementSnapshotData.nodes;
  }
  return [];
};

export function getEmOutpatientByLevelExportDataWithOriginalBenchmarkOptions(level: MultilevelTabCollections,
                                                                             evaluationManagementData: EvaluationManagementMultilevel,
                                                                             summaryData: EvaluationManagementSummary,
                                                                             emExportTitle: string,
                                                                             isSingleProviderSelected: boolean,
                                                                             viewCommunityBenchmark: boolean,
): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const outPatientRows: EvaluationManagementOutpatientRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => rowForOutpatient(
      nodeName(n, level),
      n.cptCategories.newPatientVisit,
      n.cptCategories.establishedPatientVisit,
      n.cptCategories.consultation,
      nodePathFn(n, level),
      nodeIdFn(n, level)
    ));

  const rows: EvaluationManagementOutpatientExportRow[] = [];
  rows.push(getSummaryOutpatientEncounterRowForExport(summaryData));
  rows.push(getSummaryOutpatientPercentageRowForExport(summaryData));
  rows.push(getSummaryOutpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmark));
  rows.push(getBlankSummaryOutpatientEncounterRowForExport());
  let groupName = '';
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      groupName = 'Department';
      break;
    case MultilevelTabCollections.BY_SPECIALTY:
      groupName = 'Specialty/Department';
      break;
    case MultilevelTabCollections.BY_PROVIDER:
      groupName = 'Provider/Specialty/Department';
      break;
  }
  rows.push({
    group: groupName,
    metric: 'Metrics',
    cpt99201: '99201',
    cpt99202: '99202',
    cpt99203: '99203',
    cpt99204: '99204',
    cpt99205: '99205',
    totalNewOPVisits: 'Total',
    cpt99211: '99211',
    cpt99212: '99212',
    cpt99213: '99213',
    cpt99214: '99214',
    cpt99215: '99215',
    totalEstablishedOPVisits: 'Total',
    cpt99241: '99241',
    cpt99242: '99242',
    cpt99243: '99243',
    cpt99244: '99244',
    cpt99245: '99245',
    totalOfficeConsultation: 'Total'
  });

  outPatientRows.forEach((element) => {
    rows.push(getOutpatientEncounterRowForExport(element));
    rows.push(getOutpatientCodingDistributionRowForExport(element));
    rows.push(getOutpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmark));
  });

  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    fileName: 'outpatientEandM ' + levelNameFn(level).abbr,
    page: 'EM Outpatient ' + levelNameFn(level).name,
    title: emExportTitle
  };
}

export function getEmOutpatientByLevelExportDataWithExtendedBenchmarkOptions(level: MultilevelTabCollections,
                                                                             evaluationManagementData: EvaluationManagementMultilevel,
                                                                             summaryData: EvaluationManagementSummary,
                                                                             emExportTitle: string,
                                                                             isSingleProviderSelected: boolean,
                                                                             benchmarkOption: BenchmarkOption,
                                                                             suppressZeroes: boolean
): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const outPatientRows: EvaluationManagementOutpatientRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => rowForOutpatient(
      nodeName(n, level),
      n.cptCategories.newPatientVisit,
      n.cptCategories.establishedPatientVisit,
      n.cptCategories.consultation,
      nodePathFn(n, level),
      nodeIdFn(n, level)
    ));

  const rows: EvaluationManagementOutpatientExportRow[] = [];
  rows.push(getSummaryOutpatientEncounterRowForExport(summaryData));
  rows.push(getSummaryOutpatientPercentageRowForExport(summaryData));
  rows.push(getSummaryOutpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankSummaryOutpatientEncounterRowForExport());
  let groupName = '';
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      groupName = 'Department';
      break;
    case MultilevelTabCollections.BY_SPECIALTY:
      groupName = 'Specialty/Department';
      break;
    case MultilevelTabCollections.BY_PROVIDER:
      groupName = 'Provider/Specialty/Department';
      break;
  }
  rows.push({
    group: groupName,
    metric: 'Metrics',
    cpt99201: '99201',
    cpt99202: '99202',
    cpt99203: '99203',
    cpt99204: '99204',
    cpt99205: '99205',
    totalNewOPVisits: 'Total',
    cpt99211: '99211',
    cpt99212: '99212',
    cpt99213: '99213',
    cpt99214: '99214',
    cpt99215: '99215',
    totalEstablishedOPVisits: 'Total',
    cpt99241: '99241',
    cpt99242: '99242',
    cpt99243: '99243',
    cpt99244: '99244',
    cpt99245: '99245',
    totalOfficeConsultation: 'Total'
  });

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Outpatient);

  outPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      rows.push(getOutpatientEncounterRowForExport(element));
      rows.push(getOutpatientCodingDistributionRowForExport(element));
      rows.push(getOutpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '99201', '99202', '99203', '99204', '99205', 'Total',
      '99211', '99212', '99213', '99214', '99215', 'Total',
      '99241', '99242', '99243', '99244', '99245', 'Total'],
    fileName: 'outpatientEandM ' + levelNameFn(level).abbr,
    page: 'EM Outpatient ' + levelNameFn(level).name,
    title: emExportTitle
  };
}

function getEmInpatientSummaryExportDetails(rows: EvaluationManagementInpatientExportRow[],
                                            isSingleProviderSelected: boolean, emExportTitle: string) {
  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    fileName: 'inpatientEandM',
    page: 'EM Inpatient',
    title: emExportTitle
  };
}

export function getEmInpatientExportDataWithOriginalBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                     summaryData: EvaluationManagementSummary,
                                                                     emExportTitle: string,
                                                                     isSingleProviderSelected: boolean,
                                                                     viewCommunityBenchmarks: boolean): Export {
  const rows: EvaluationManagementInpatientExportRow[] = [];
  const inPatientRows =
    getInpatientTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);
  rows.push(getInpatientSummaryEncounterRowForExport(summaryData));
  rows.push(getInpatientSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getInpatientSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmarks));
  rows.push(getBlankInpatientSummaryEncounterRowForExport());


  inPatientRows.forEach((element) => {
    rows.push(getInpatientEncounterRowForExport(element));
    rows.push(getInpatientCodingDistributionRowForExport(element));
    rows.push(getInpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmarks));
  });

  return getEmInpatientSummaryExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

export function getEmInpatientExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                     summaryData: EvaluationManagementSummary,
                                                                     emExportTitle: string,
                                                                     isSingleProviderSelected: boolean,
                                                                     benchmarkOption: BenchmarkOption): Export {
  const rows: EvaluationManagementInpatientExportRow[] = [];
  const inPatientRows =
    getInpatientTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);
  rows.push(getInpatientSummaryEncounterRowForExport(summaryData));
  rows.push(getInpatientSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getInpatientSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankInpatientSummaryEncounterRowForExport());


  inPatientRows.forEach((element) => {
    rows.push(getInpatientEncounterRowForExport(element));
    rows.push(getInpatientCodingDistributionRowForExport(element));
    rows.push(getInpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return getEmInpatientSummaryExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

function getEmInpatientProviderRow(n: ProviderMultilevelCptCounts, level: MultilevelTabCollections) {
  return rowForInpatient(
    nodeName(n, level),
    n.cptCategories.subsequentHospitalCare,
    n.cptCategories.initialHospitalCare,
    n.cptCategories.hospitalDischargeDay,
    nodePathFn(n, level),
    nodeIdFn(n, level)
  );
}

function getGroupNameByLevel(level: MultilevelTabCollections) {
  switch (level) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      return 'Department';
    case MultilevelTabCollections.BY_SPECIALTY:
      return 'Specialty/Department';
    case MultilevelTabCollections.BY_PROVIDER:
      return 'Provider/Specialty/Department';
    default:
      return '';
  }
}

function getEmInpatientDetailHeaderRow(level: MultilevelTabCollections) {
  return {
    group: getGroupNameByLevel(level),
    metric: ' Metric',
    cpt99221: '99221',
    cpt99222: '99222',
    cpt99223: '99223',
    totalInitialHospitalCare: 'Total',
    cpt99231: '99231',
    cpt99232: '99232',
    cpt99233: '99233',
    totalSubsequentHospitalCare: 'Total',
    cpt99238: '99238',
    cpt99239: '99239',
    totalHospitalDischargeDay: 'Total'
  };
}

function getEmInpatientrExportDetails(rows: EvaluationManagementInpatientExportRow[],
                                      isSingleProviderSelected: boolean, level: MultilevelTabCollections, emExportTitle: string) {
  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '99221', '99222', '99223', 'Total',
      '99231', '99232', '99233', 'Total', '99238', '99239', 'Total'],
    fileName: 'inpatientEandM ' + levelNameFn(level).abbr,
    page: 'EM Inpatient ' + levelNameFn(level).name,
    title: emExportTitle
  };
}

export function getEmInpatientByLevelExportDataWithOriginalBenchmarkOptions(level: MultilevelTabCollections,
                                                                            evaluationManagementData: EvaluationManagementMultilevel,
                                                                            summaryData: EvaluationManagementSummary,
                                                                            emExportTitle: string,
                                                                            isSingleProviderSelected: boolean,
                                                                            viewCommunityBenchmarks: boolean): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const inPatientRows: EvaluationManagementInpatientRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => getEmInpatientProviderRow(n, level));

  const rows: EvaluationManagementInpatientExportRow[] = [];
  rows.push(getInpatientSummaryEncounterRowForExport(summaryData));
  rows.push(getInpatientSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getInpatientSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmarks));
  rows.push(getBlankInpatientSummaryEncounterRowForExport());
  rows.push(getEmInpatientDetailHeaderRow(level));

  inPatientRows.forEach((element) => {
    rows.push(getInpatientEncounterRowForExport(element));
    rows.push(getInpatientCodingDistributionRowForExport(element));
    rows.push(getInpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmarks));
  });

  return getEmInpatientrExportDetails(rows, isSingleProviderSelected, level, emExportTitle);
}

export function getEmInpatientByLevelExportDataWithExtendedBenchmarkOptions(level: MultilevelTabCollections,
                                                                            evaluationManagementData: EvaluationManagementMultilevel,
                                                                            summaryData: EvaluationManagementSummary,
                                                                            emExportTitle: string,
                                                                            isSingleProviderSelected: boolean,
                                                                            benchmarkOption: BenchmarkOption,
                                                                            suppressZeroes: boolean): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const inPatientRows: EvaluationManagementInpatientRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => getEmInpatientProviderRow(n, level));

  const rows: EvaluationManagementInpatientExportRow[] = [];
  rows.push(getInpatientSummaryEncounterRowForExport(summaryData));
  rows.push(getInpatientSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getInpatientSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankInpatientSummaryEncounterRowForExport());
  rows.push(getEmInpatientDetailHeaderRow(level));

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.InPatient);

  inPatientRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      rows.push(getInpatientEncounterRowForExport(element));
      rows.push(getInpatientCodingDistributionRowForExport(element));
      rows.push(getInpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return getEmInpatientrExportDetails(rows, isSingleProviderSelected, level, emExportTitle);
}

function getEmEmergencySummaryExportDetails(rows: EvaluationManagementEmergencyRoomExportRow[],
                                            isSingleProviderSelected: boolean, emExportTitle: string) {
  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '99281', '99282', '99283', '99284', '99285', 'Total'],
    fileName: 'emergencyRoomEandM',
    page: 'EM EmergencyRoom',
    title: emExportTitle
  };
}

export function getEmEmergencyExportDataWithOriginalBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                     summaryData: EvaluationManagementSummary,
                                                                     emExportTitle: string,
                                                                     isSingleProviderSelected: boolean,
                                                                     viewCommunityBenchmarks: boolean): Export {
  const rows: EvaluationManagementEmergencyRoomExportRow[] = [];
  const eyeEmergencyRows =
    getEmergencyTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);
  rows.push(getEmergencyRoomSummaryEncounterRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmarks));
  rows.push(getBlankEmergencyRoomSummaryEncounterRowForExport());

  eyeEmergencyRows.forEach((element) => {
    rows.push(getEmergencyRoomEncounterRowForExport(element));
    rows.push(getEmergencyRoomCodingDistributionRowForExport(element));
    rows.push(getEmergencyRoomFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmarks));
  });

  return getEmEmergencySummaryExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

export function getEmEmergencyExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                     summaryData: EvaluationManagementSummary,
                                                                     emExportTitle: string,
                                                                     isSingleProviderSelected: boolean,
                                                                     benchmarkOption: BenchmarkOption): Export {
  const rows: EvaluationManagementEmergencyRoomExportRow[] = [];
  const eyeEmergencyRows =
    getEmergencyTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);
  rows.push(getEmergencyRoomSummaryEncounterRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankEmergencyRoomSummaryEncounterRowForExport());

  eyeEmergencyRows.forEach((element) => {
    rows.push(getEmergencyRoomEncounterRowForExport(element));
    rows.push(getEmergencyRoomCodingDistributionRowForExport(element));
    rows.push(getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return getEmEmergencySummaryExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

function getEmEmergencyByLevelExportDetails(rows: EvaluationManagementEmergencyRoomExportRow[],
                                            isSingleProviderSelected: boolean, level: MultilevelTabCollections, emExportTitle: string) {
  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '99281', '99282', '99283', '99284', '99285', 'Total'],
    fileName: 'emergencyRoomEandM ' + levelNameFn(level).abbr,
    page: 'EM EmergencyRoom ' + levelNameFn(level).name,
    title: emExportTitle
  };
}

function getEmEmergencywByLevelDetailHeaderRow(level: MultilevelTabCollections) {
  return {
    group: getGroupNameByLevel(level),
    metric: 'Metric',
    cpt99281: '99281',
    cpt99282: '99282',
    cpt99283: '99283',
    cpt99284: '99284',
    cpt99285: '99285',
    total: 'Total'
  };
}

function getEmEmergencyRow(n: ProviderMultilevelCptCounts, level: MultilevelTabCollections) {
  return rowForEmergencyRoom(
    nodeName(n, level),
    n.cptCategories.emergencyMedicine,
    nodePathFn(n, level),
    nodeIdFn(n, level)
  );
}

export function getEmEmergencyByLevelExportDataWithOriginalBenchmarkOptions(level: MultilevelTabCollections,
                                                                            evaluationManagementData: EvaluationManagementMultilevel,
                                                                            summaryData: EvaluationManagementSummary,
                                                                            emExportTitle: string,
                                                                            isSingleProviderSelected: boolean,
                                                                            viewCommunityBenchmarks: boolean,
): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const emergencyRows: EvaluationManagementEmergencyRoomRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => getEmEmergencyRow(n, level));
  const rows: EvaluationManagementEmergencyRoomExportRow[] = [];
  rows.push(getEmergencyRoomSummaryEncounterRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmarks));
  rows.push(getBlankEmergencyRoomSummaryEncounterRowForExport());
  rows.push(getEmEmergencywByLevelDetailHeaderRow(level));

  emergencyRows.forEach((element) => {
    rows.push(getEmergencyRoomEncounterRowForExport(element));
    rows.push(getEmergencyRoomCodingDistributionRowForExport(element));
    rows.push(getEmergencyRoomFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmarks));
  });

  return getEmEmergencyByLevelExportDetails(rows, isSingleProviderSelected, level, emExportTitle);
}

export function getEmEmergencyByLevelExportDataWithExtendedBenchmarkOptions(level: MultilevelTabCollections,
                                                                            evaluationManagementData: EvaluationManagementMultilevel,
                                                                            summaryData: EvaluationManagementSummary,
                                                                            emExportTitle: string,
                                                                            isSingleProviderSelected: boolean,
                                                                            benchmarkOption: BenchmarkOption,
                                                                            suppressZeroes: boolean
): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const emergencyRows: EvaluationManagementEmergencyRoomRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => getEmEmergencyRow(n, level));
  const rows: EvaluationManagementEmergencyRoomExportRow[] = [];
  rows.push(getEmergencyRoomSummaryEncounterRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEmergencyRoomSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankEmergencyRoomSummaryEncounterRowForExport());
  rows.push(getEmEmergencywByLevelDetailHeaderRow(level));

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.EmergencyMedicine);

  emergencyRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      rows.push(getEmergencyRoomEncounterRowForExport(element));
      rows.push(getEmergencyRoomCodingDistributionRowForExport(element));
      rows.push(getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return getEmEmergencyByLevelExportDetails(rows, isSingleProviderSelected, level, emExportTitle);
}

function getEmEyeExamSummaryExportDetails(rows: EvaluationManagementEyeExamExportRow[],
                                          isSingleProviderSelected: boolean, emExportTitle: string) {
  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    fileName: 'eyeExamEandM',
    page: 'EM Eyeexam',
    title: emExportTitle
  };
}

export function getEmEyeExamExportDataWithOriginalBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                   summaryData: EvaluationManagementSummary,
                                                                   emExportTitle: string,
                                                                   isSingleProviderSelected: boolean,
                                                                   viewCommunityBenchmarks: boolean): Export {
  const rows: EvaluationManagementEyeExamExportRow[] = [];

  const eyeExamRows =
    getEyeExamTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);

  rows.push(getEyeExamSummaryEncounterRowForExport(summaryData));
  rows.push(getEyeExamSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEyeExamSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmarks));
  rows.push(getBlankEyeExamSummaryEncounterRowForExport());

  eyeExamRows.forEach((element) => {
    rows.push(getEyeExamEncounterRowForExport(element));
    rows.push(getEyeExamCodingDistributionRowForExport(element));
    rows.push(getEyeExamFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmarks));
  });

  return getEmEyeExamSummaryExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

export function getEmEyeExamExportDataWithExtendedBenchmarkOptions(evaluationManagementData: EvaluationManagement,
                                                                   summaryData: EvaluationManagementSummary,
                                                                   emExportTitle: string,
                                                                   isSingleProviderSelected: boolean,
                                                                   benchmarkOption: BenchmarkOption): Export {
  const rows: EvaluationManagementEyeExamExportRow[] = [];

  const eyeExamRows =
    getEyeExamTableRowsFrom(evaluationManagementData.providerEvaluationManagement,
      isSingleProviderSelected);

  rows.push(getEyeExamSummaryEncounterRowForExport(summaryData));
  rows.push(getEyeExamSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEyeExamSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankEyeExamSummaryEncounterRowForExport());

  eyeExamRows.forEach((element) => {
    rows.push(getEyeExamEncounterRowForExport(element));
    rows.push(getEyeExamCodingDistributionRowForExport(element));
    rows.push(getEyeExamFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return getEmEyeExamSummaryExportDetails(rows, isSingleProviderSelected, emExportTitle);
}

function getEyeExamByLevelDetailHeaderRow(level: MultilevelTabCollections) {
  return {
    group: getGroupNameByLevel(level),
    metric: 'Metric',
    cpt92002: '92002',
    cpt92004: '92004',
    totalNewVisits: 'Total',
    cpt92012: '92012',
    cpt92014: '92014',
    totalEstablished: 'Total'
  };
}

function getEmEyeExamByLevelExportDetails(rows: EvaluationManagementEyeExamExportRow[],
                                          isSingleProviderSelected: boolean, level: MultilevelTabCollections, emExportTitle: string) {
  return {
    data: rows,
    headers: [isSingleProviderSelected ? 'Year  Month' : 'Groups', 'Metrics', '92002', '92004', 'Total', '92012', '92014', 'Total'],
    fileName: 'eyeExamEandM ' + levelNameFn(level).abbr,
    page: 'EM Eyeexam ' + levelNameFn(level).name,
    title: emExportTitle
  };
}

export function getEmEyeExamByLevelExportDataWithOriginalBenchmarkOptions(level: MultilevelTabCollections,
                                                                          evaluationManagementData: EvaluationManagementMultilevel,
                                                                          summaryData: EvaluationManagementSummary,
                                                                          emExportTitle: string,
                                                                          isSingleProviderSelected: boolean,
                                                                          viewCommunityBenchmarks: boolean
): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const eyeExamRows: EvaluationManagementEyeExamRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => rowForEyeExam(
      nodeName(n, level),
      n.cptCategories.eyeExamNewPatient,
      n.cptCategories.eyeExamEstablishedPatient,
      nodePathFn(n, level),
      nodeIdFn(n, level)
    ));

  const rows: EvaluationManagementEyeExamExportRow[] = [];
  rows.push(getEyeExamSummaryEncounterRowForExport(summaryData));
  rows.push(getEyeExamSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEyeExamSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(summaryData, viewCommunityBenchmarks));
  rows.push(getBlankEyeExamSummaryEncounterRowForExport());
  rows.push(getEyeExamByLevelDetailHeaderRow(level));

  eyeExamRows.forEach((element) => {
    rows.push(getEyeExamEncounterRowForExport(element));
    rows.push(getEyeExamCodingDistributionRowForExport(element));
    rows.push(getEyeExamFpscMeanRowForExportWithOriginalBenchmarkOptions(element, viewCommunityBenchmarks));
  });

  return getEmEyeExamByLevelExportDetails(rows, isSingleProviderSelected, level, emExportTitle);
}

export function getEmEyeExamByLevelExportDataWithExtendedBenchmarkOptions(level: MultilevelTabCollections,
                                                                          evaluationManagementData: EvaluationManagementMultilevel,
                                                                          summaryData: EvaluationManagementSummary,
                                                                          emExportTitle: string,
                                                                          isSingleProviderSelected: boolean,
                                                                          benchmarkOption: BenchmarkOption,
                                                                          suppressZeroes: boolean
): Export {
  const emNodes: ProviderMultilevelCptCounts[] = nodesFn(evaluationManagementData, level);
  const eyeExamRows: EvaluationManagementEyeExamRow[] = emNodes.map(
    (n: ProviderMultilevelCptCounts) => rowForEyeExam(
      nodeName(n, level),
      n.cptCategories.eyeExamNewPatient,
      n.cptCategories.eyeExamEstablishedPatient,
      nodePathFn(n, level),
      nodeIdFn(n, level)
    ));

  const rows: EvaluationManagementEyeExamExportRow[] = [];
  rows.push(getEyeExamSummaryEncounterRowForExport(summaryData));
  rows.push(getEyeExamSummaryCodingDistributionRowForExport(summaryData));
  rows.push(getEyeExamSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(summaryData, benchmarkOption));
  rows.push(getBlankEyeExamSummaryEncounterRowForExport());
  rows.push(getEyeExamByLevelDetailHeaderRow(level));

  const zeroSuppressionParameters = parametersForZeroSuppression(EmDimension.Ophthalmology);

  eyeExamRows.filter(x => !suppressZeroes || isAllowedToShow(x, zeroSuppressionParameters))
    .forEach((element) => {
      rows.push(getEyeExamEncounterRowForExport(element));
      rows.push(getEyeExamCodingDistributionRowForExport(element));
      rows.push(getEyeExamFpscMeanRowForExportWithExtendedBenchmarkOptions(element, benchmarkOption));
  });

  return getEmEyeExamByLevelExportDetails(rows, isSingleProviderSelected, level, emExportTitle);
}


export function getCptAnalysisExportData(cptAnalysisData: ClinicalSummaryCode[],
                                         title: string,
                                         columnHeaders: string[],
                                         viewCommunityBenchmarks: boolean
): Export {

  return {
    data: getCptAnalysisSummaryData(cptAnalysisData, columnHeaders, viewCommunityBenchmarks),
    headers: [],
    fileName: 'cptAnalysis',
    page: 'Cpt Analysis',
    title: title.replace(',', '')
  };
}


export function getCFCptFamilyExportData(summaryData: ClinicalSummaryConsolidation, title: string, suppressZeroes: boolean,
                                         viewCommunityBenchmarks: boolean, showAdditionalColumns: boolean): Export {
  return {
    data: getCFCptFamilySummaryData(summaryData, viewCommunityBenchmarks, showAdditionalColumns, suppressZeroes),
    headers: showAdditionalColumns ? ['CPT Family Description', 'Actual wRVUS',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'Count',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance',
        'Charges'] :
      ['CPT Family Description',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance'
      ],
    fileName: 'cptFamily',
    page: 'Cpt Family',
    title: title
  };
}

export function getCFCptRangeExportData(summaryData: ClinicalSummaryConsolidation, title: string, suppressZeroes: boolean,
                                        viewCommunityBenchmarks: boolean, showAdditionalColumns: boolean): Export {
  return {
    data: getCFCptRangeSummaryData(summaryData, viewCommunityBenchmarks, showAdditionalColumns, suppressZeroes),
    headers: showAdditionalColumns ? ['CPT Range', 'CPT Family Description', 'Actual wRVUS',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'Count',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance',
        'Charges'] :
      ['CPT Range', 'CPT Family Description',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance'
      ],
    fileName: 'cptRange',
    page: 'Cpt Range',
    title: title
  };
}

export function getCFCptCodeExportData(summaryData: ClinicalSummaryConsolidation, title: string, suppressZeroes: boolean,
                                       viewCommunityBenchmarks: boolean, showAdditionalColumns: boolean): Export {
  return {
    data: getCFCptCodeSummaryData(summaryData, viewCommunityBenchmarks, showAdditionalColumns, suppressZeroes),
    headers: showAdditionalColumns ? ['CPT Code', 'CPT Range', 'CPT Family Description', 'Actual wRVUS',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'Count',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance',
        'Charges'] :
      ['CPT Code', 'CPT Range', 'CPT Family Description',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance'
      ],
    fileName: 'cptCode',
    page: 'Cpt Code',
    title: title
  };
}


export function getOutpatientEncounterRowForExport(eAndmOutpatient: EvaluationManagementOutpatientRow)
  : EvaluationManagementOutpatientExportRow {
  if (!eAndmOutpatient) {
    return {group: '', metric: '# of Encounters'};
  }
  return {
    group: eAndmOutpatient.rowName,
    metric: '# of Encounters',
    cpt99201: isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99201', 'count', ''),
    cpt99202: isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99202', 'count', ''),
    cpt99203: isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99203', 'count', ''),
    cpt99204: isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99204', 'count', ''),
    cpt99205: isValidOrElse(eAndmOutpatient.newPatientVisit, 'cpt99205', 'count', ''),
    totalNewOPVisits: valueFromOrElse(eAndmOutpatient.newPatientVisit, 'count', '', 0),
    cpt99211: isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99211', 'count', ''),
    cpt99212: isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99212', 'count', ''),
    cpt99213: isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99213', 'count', ''),
    cpt99214: isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99214', 'count', ''),
    cpt99215: isValidOrElse(eAndmOutpatient.establishedPatientVisit, 'cpt99215', 'count', ''),
    totalEstablishedOPVisits: valueFromOrElse(eAndmOutpatient.establishedPatientVisit, 'count', '', 0),
    cpt99241: isValidOrElse(eAndmOutpatient.consultation, 'cpt99241', 'count', ''),
    cpt99242: isValidOrElse(eAndmOutpatient.consultation, 'cpt99242', 'count', ''),
    cpt99243: isValidOrElse(eAndmOutpatient.consultation, 'cpt99243', 'count', ''),
    cpt99244: isValidOrElse(eAndmOutpatient.consultation, 'cpt99244', 'count', ''),
    cpt99245: isValidOrElse(eAndmOutpatient.consultation, 'cpt99245', 'count', ''),
    totalOfficeConsultation: valueFromOrElse(eAndmOutpatient.consultation, 'count', '', 0)
  };
}

export function getBlankSummaryOutpatientEncounterRowForExport()
  : EvaluationManagementOutpatientExportRow {
  return {
    group: ' ',
    metric: ' ',
    cpt99201: ' ',
    cpt99202: ' ',
    cpt99203: ' ',
    cpt99204: ' ',
    cpt99205: ' ',
    totalNewOPVisits: ' ',
    cpt99211: ' ',
    cpt99212: ' ',
    cpt99213: ' ',
    cpt99214: ' ',
    cpt99215: ' ',
    totalEstablishedOPVisits: ' ',
    cpt99241: ' ',
    cpt99242: ' ',
    cpt99243: ' ',
    cpt99244: ' ',
    cpt99245: ' ',
    totalOfficeConsultation: ' '
  };
}

export function getSummaryOutpatientEncounterRowForExport(eAndmOutpatientSummary: EvaluationManagementSummary)
  : EvaluationManagementOutpatientExportRow {
  if (!eAndmOutpatientSummary.totals) {
    return {group: '', metric: '# of Encounters'};
  }
  return {
    group: getGroupName(eAndmOutpatientSummary, 'selectedDateRange'),
    metric: '# of Encounters',
    cpt99201: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99201', 'count', ''),
    cpt99202: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99202', 'count', ''),
    cpt99203: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99203', 'count', ''),
    cpt99204: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99204', 'count', ''),
    cpt99205: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99205', 'count', ''),
    totalNewOPVisits: valueFromOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'count', '', 0),
    cpt99211: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99211', 'count', ''),
    cpt99212: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99212', 'count', ''),
    cpt99213: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99213', 'count', ''),
    cpt99214: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99214', 'count', ''),
    cpt99215: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99215', 'count', ''),
    totalEstablishedOPVisits: valueFromOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'count', '', 0),
    cpt99241: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99241', 'count', ''),
    cpt99242: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99242', 'count', ''),
    cpt99243: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99243', 'count', ''),
    cpt99244: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99244', 'count', ''),
    cpt99245: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99245', 'count', ''),
    totalOfficeConsultation: valueFromOrElse(eAndmOutpatientSummary.totals.consultation, 'count', '', 0)
  };
}

export function getSummaryOutpatientPercentageRowForExport(eAndmOutpatientSummary: EvaluationManagementSummary)
  : EvaluationManagementOutpatientExportRow {
  if (!eAndmOutpatientSummary.totals) {
    return {group: '', metric: 'Coding Distribution'};
  }
  return {
    group: getGroupName(eAndmOutpatientSummary, 'selectedDateRange'),
    metric: 'Coding Distribution',
    cpt99201: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99201', 'percentage', '', '%'),
    cpt99202: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99202', 'percentage', '', '%'),
    cpt99203: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99203', 'percentage', '', '%'),
    cpt99204: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99204', 'percentage', '', '%'),
    cpt99205: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99205', 'percentage', '', '%'),
    totalNewOPVisits: valueFromOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'percentage', '%', '0%', true),
    cpt99211: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99211', 'percentage', '', '%'),
    cpt99212: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99212', 'percentage', '', '%'),
    cpt99213: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99213', 'percentage', '', '%'),
    cpt99214: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99214', 'percentage', '', '%'),
    cpt99215: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99215', 'percentage', '', '%'),
    totalEstablishedOPVisits: valueFromOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'percentage', '%', '0%', true),
    cpt99241: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99241', 'percentage', '', '%'),
    cpt99242: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99242', 'percentage', '', '%'),
    cpt99243: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99243', 'percentage', '', '%'),
    cpt99244: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99244', 'percentage', '', '%'),
    cpt99245: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99245', 'percentage', '', '%'),
    totalOfficeConsultation: valueFromOrElse(eAndmOutpatientSummary.totals.consultation, 'percentage', '%', '0%', true)
  };
}

function getOutPatientMeanRowObject(eAndmOutpatientSummary: EvaluationManagementSummary, headerString: string, benchmarkString: string) {
  return {
    group: getGroupName(eAndmOutpatientSummary, 'selectedDateRange'),
    metric: headerString,
    cpt99201: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99201', benchmarkString, '', '%'),
    cpt99202: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99202', benchmarkString, '', '%'),
    cpt99203: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99203', benchmarkString, '', '%'),
    cpt99204: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99204', benchmarkString, '', '%'),
    cpt99205: isValidOrElse(eAndmOutpatientSummary.totals.newPatientVisit, 'cpt99205', benchmarkString, '', '%'),
    totalNewOPVisits: '',
    cpt99211: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99211', benchmarkString, '', '%'),
    cpt99212: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99212', benchmarkString, '', '%'),
    cpt99213: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99213', benchmarkString, '', '%'),
    cpt99214: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99214', benchmarkString, '', '%'),
    cpt99215: isValidOrElse(eAndmOutpatientSummary.totals.establishedPatientVisit, 'cpt99215', benchmarkString, '', '%'),
    totalEstablishedOPVisits: '',
    cpt99241: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99241', benchmarkString, '', '%'),
    cpt99242: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99242', benchmarkString, '', '%'),
    cpt99243: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99243', benchmarkString, '', '%'),
    cpt99244: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99244', benchmarkString, '', '%'),
    cpt99245: isValidOrElse(eAndmOutpatientSummary.totals.consultation, 'cpt99245', benchmarkString, '', '%'),
    totalOfficeConsultation: ''
  };
}

export function getSummaryOutpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndmOutpatientSummary: EvaluationManagementSummary,
                                                                                     viewCommunityBenchmarks: boolean)
  : EvaluationManagementOutpatientExportRow {
  if (!eAndmOutpatientSummary.totals) {
    return {group: '', metric: 'CPSC Mean'};
  }
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getOutPatientMeanRowObject(eAndmOutpatientSummary, headerString, benchmarkString);
}

export function getSummaryOutpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndmOutpatientSummary: EvaluationManagementSummary,
                                                                                     benchmarkOption: BenchmarkOption)
  : EvaluationManagementOutpatientExportRow {
  if (!eAndmOutpatientSummary.totals) {
    return {group: '', metric: 'CPSC Mean'};
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getOutPatientMeanRowObject(eAndmOutpatientSummary, headerString, benchmarkString);
}

export function getOutpatientCodingDistributionRowForExport(eAndMOutpatient: EvaluationManagementOutpatientRow)
  : EvaluationManagementOutpatientExportRow {
  return {
    group: eAndMOutpatient.rowName,
    metric: 'Coding Distribution',
    cpt99201: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99201', 'percentage', '', '%'),
    cpt99202: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99202', 'percentage', '', '%'),
    cpt99203: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99203', 'percentage', '', '%'),
    cpt99204: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99204', 'percentage', '', '%'),
    cpt99205: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99205', 'percentage', '', '%'),
    totalNewOPVisits: valueFromOrElse(eAndMOutpatient.newPatientVisit, 'percentage', '%', 0, true),
    cpt99211: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99211', 'percentage', '', '%'),
    cpt99212: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99212', 'percentage', '', '%'),
    cpt99213: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99213', 'percentage', '', '%'),
    cpt99214: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99214', 'percentage', '', '%'),
    cpt99215: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99215', 'percentage', '', '%'),
    totalEstablishedOPVisits: valueFromOrElse(eAndMOutpatient.establishedPatientVisit, 'percentage', '%', 0, true),
    cpt99241: isValidOrElse(eAndMOutpatient.consultation, 'cpt99241', 'percentage', '', '%'),
    cpt99242: isValidOrElse(eAndMOutpatient.consultation, 'cpt99242', 'percentage', '', '%'),
    cpt99243: isValidOrElse(eAndMOutpatient.consultation, 'cpt99243', 'percentage', '', '%'),
    cpt99244: isValidOrElse(eAndMOutpatient.consultation, 'cpt99244', 'percentage', '', '%'),
    cpt99245: isValidOrElse(eAndMOutpatient.consultation, 'cpt99245', 'percentage', '', '%'),
    totalOfficeConsultation: valueFromOrElse(eAndMOutpatient.consultation, 'percentage', '%', 0, true)
  };
}

function getOutpatientSummaryMeanRowObject(eAndMOutpatient: EvaluationManagementOutpatientRow,
                                           headerString: string, benchmarkString: string) {
  return {
    group: eAndMOutpatient.rowName,
    metric: headerString,
    cpt99201: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99201', benchmarkString, '', '%'),
    cpt99202: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99202', benchmarkString, '', '%'),
    cpt99203: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99203', benchmarkString, '', '%'),
    cpt99204: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99204', benchmarkString, '', '%'),
    cpt99205: isValidOrElse(eAndMOutpatient.newPatientVisit, 'cpt99205', benchmarkString, '', '%'),
    totalNewOPVisits: '',
    cpt99211: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99211', benchmarkString, '', '%'),
    cpt99212: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99212', benchmarkString, '', '%'),
    cpt99213: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99213', benchmarkString, '', '%'),
    cpt99214: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99214', benchmarkString, '', '%'),
    cpt99215: isValidOrElse(eAndMOutpatient.establishedPatientVisit, 'cpt99215', benchmarkString, '', '%'),
    totalEstablishedOPVisits: '',
    cpt99241: isValidOrElse(eAndMOutpatient.consultation, 'cpt99241', benchmarkString, '', '%'),
    cpt99242: isValidOrElse(eAndMOutpatient.consultation, 'cpt99242', benchmarkString, '', '%'),
    cpt99243: isValidOrElse(eAndMOutpatient.consultation, 'cpt99243', benchmarkString, '', '%'),
    cpt99244: isValidOrElse(eAndMOutpatient.consultation, 'cpt99244', benchmarkString, '', '%'),
    cpt99245: isValidOrElse(eAndMOutpatient.consultation, 'cpt99245', benchmarkString, '', '%'),
    totalOfficeConsultation: ''
  };
}

export function getOutpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndMOutpatient: EvaluationManagementOutpatientRow,
                                                                              viewCommunityBenchmarks: boolean)
  : EvaluationManagementOutpatientExportRow {
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getOutpatientSummaryMeanRowObject(eAndMOutpatient, headerString, benchmarkString);

}

export function getOutpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMOutpatient: EvaluationManagementOutpatientRow,
                                                                              benchmarkOption: BenchmarkOption)
  : EvaluationManagementOutpatientExportRow {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getOutpatientSummaryMeanRowObject(eAndMOutpatient, headerString, benchmarkString);
}

export function getInpatientSummaryEncounterRowForExport(eAndmInpatientSummary: EvaluationManagementSummary)
  : EvaluationManagementInpatientExportRow {
  if (!eAndmInpatientSummary.totals) {
    return {group: '', metric: '# of Encounters'};
  }
  return {
    group: getGroupName(eAndmInpatientSummary, 'selectedDateRange'),
    metric: '# of Encounters',
    cpt99221: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99221', 'count', ''),
    cpt99222: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99222', 'count', ''),
    cpt99223: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99223', 'count', ''),
    totalInitialHospitalCare: valueFromOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'count', '', 0),
    cpt99231: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99231', 'count', ''),
    cpt99232: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99232', 'count', ''),
    cpt99233: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99233', 'count', ''),
    totalSubsequentHospitalCare: valueFromOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'count', '', 0),
    cpt99238: isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99238', 'count', ''),
    cpt99239: isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99239', 'count', ''),
    totalHospitalDischargeDay: valueFromOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'count', '', 0)
  };
}

export function getBlankInpatientSummaryEncounterRowForExport()
  : EvaluationManagementInpatientExportRow {
  return {
    group: ' ',
    metric: ' ',
    cpt99221: ' ',
    cpt99222: ' ',
    cpt99223: ' ',
    totalInitialHospitalCare: ' ',
    cpt99231: ' ',
    cpt99232: ' ',
    cpt99233: ' ',
    totalSubsequentHospitalCare: ' ',
    cpt99238: ' ',
    cpt99239: ' ',
    totalHospitalDischargeDay: ' '
  };
}

export function getInpatientSummaryCodingDistributionRowForExport(eAndmInpatientSummary: EvaluationManagementSummary)
  : EvaluationManagementInpatientExportRow {
  if (!eAndmInpatientSummary.totals) {
    return {group: '', metric: 'Coding Distribution'};
  }
  return {
    group: getGroupName(eAndmInpatientSummary, 'selectedDateRange'),
    metric: 'Coding Distribution',
    cpt99221: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99221', 'percentage', '', '%'),
    cpt99222: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99222', 'percentage', '', '%'),
    cpt99223: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99223', 'percentage', '', '%'),
    totalInitialHospitalCare: valueFromOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'percentage', '%', 0, true),
    cpt99231: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99231', 'percentage', '', '%'),
    cpt99232: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99232', 'percentage', '', '%'),
    cpt99233: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99233', 'percentage', '', '%'),
    totalSubsequentHospitalCare: valueFromOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'percentage', '%', 0, true),
    cpt99238: isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99238', 'percentage', '', '%'),
    cpt99239: isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99239', 'percentage', '', '%'),
    totalHospitalDischargeDay: valueFromOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'percentage', '%', 0, true)
  };
}

function getEmInpatientSummaryMeanRowObject(eAndmInpatientSummary: EvaluationManagementSummary,
                                            headerString: string, benchmarkString: string) {
  return {
    group: getGroupName(eAndmInpatientSummary, 'selectedDateRange'),
    metric: headerString,
    cpt99221: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99221', benchmarkString, '', '%'),
    cpt99222: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99222', benchmarkString, '', '%'),
    cpt99223: isValidOrElse(eAndmInpatientSummary.totals.initialHospitalCare, 'cpt99223', benchmarkString, '', '%'),
    totalInitialHospitalCare: '',
    cpt99231: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99231', benchmarkString, '', '%'),
    cpt99232: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99232', benchmarkString, '', '%'),
    cpt99233: isValidOrElse(eAndmInpatientSummary.totals.subsequentHospitalCare, 'cpt99233', benchmarkString, '', '%'),
    totalSubsequentHospitalCare: '',
    cpt99238: isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99238', benchmarkString, '', '%'),
    cpt99239: isValidOrElse(eAndmInpatientSummary.totals.hospitalDischargeDay, 'cpt99239', benchmarkString, '', '%'),
    totalHospitalDischargeDay: ''
  };
}

export function getInpatientSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndmInpatientSummary: EvaluationManagementSummary,
                                                                                    viewCommunityBenchmarks: boolean)
  : EvaluationManagementInpatientExportRow {
  if (!eAndmInpatientSummary.totals) {
    return {group: '', metric: 'Coding Distribution'};
  }
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getEmInpatientSummaryMeanRowObject(eAndmInpatientSummary, headerString, benchmarkString);
}

export function getInpatientSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndmInpatientSummary: EvaluationManagementSummary,
                                                                                    benchmarkOption: BenchmarkOption)
  : EvaluationManagementInpatientExportRow {
  if (!eAndmInpatientSummary.totals) {
    return {group: '', metric: 'Coding Distribution'};
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmInpatientSummaryMeanRowObject(eAndmInpatientSummary, headerString, benchmarkString);
}

export function getInpatientEncounterRowForExport(eAndMInpatient: EvaluationManagementInpatientRow)
  : EvaluationManagementInpatientExportRow {
  return {
    group: eAndMInpatient.rowName,
    metric: '# of Encounters',
    cpt99221: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99221', 'count', ''),
    cpt99222: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99222', 'count', ''),
    cpt99223: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99223', 'count', ''),
    totalInitialHospitalCare: valueFromOrElse(eAndMInpatient.initialHospitalCare, 'count', '', 0),
    cpt99231: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99231', 'count', ''),
    cpt99232: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99232', 'count', ''),
    cpt99233: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99233', 'count', ''),
    totalSubsequentHospitalCare: valueFromOrElse(eAndMInpatient.subsequentHospitalCare, 'count', '', 0),
    cpt99238: isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99238', 'count', ''),
    cpt99239: isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99239', 'count', ''),
    totalHospitalDischargeDay: valueFromOrElse(eAndMInpatient.hospitalDischargeDay, 'count', '', 0)
  };
}

export function getInpatientCodingDistributionRowForExport(eAndMInpatient: EvaluationManagementInpatientRow)
  : EvaluationManagementInpatientExportRow {
  return {
    group: eAndMInpatient.rowName,
    metric: 'Coding Distribution',
    cpt99221: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99221', 'percentage', '', '%'),
    cpt99222: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99222', 'percentage', '', '%'),
    cpt99223: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99223', 'percentage', '', '%'),
    totalInitialHospitalCare: valueFromOrElse(eAndMInpatient.initialHospitalCare, 'percentage', '%', 0, true),
    cpt99231: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99231', 'percentage', '', '%'),
    cpt99232: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99232', 'percentage', '', '%'),
    cpt99233: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99233', 'percentage', '', '%'),
    totalSubsequentHospitalCare: valueFromOrElse(eAndMInpatient.subsequentHospitalCare, 'percentage', '%', 0, true),
    cpt99238: isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99238', 'percentage', '', '%'),
    cpt99239: isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99239', 'percentage', '', '%'),
    totalHospitalDischargeDay: valueFromOrElse(eAndMInpatient.hospitalDischargeDay, 'percentage', '%', 0, true)
  };
}

function getEmInpatientRowObject(eAndMInpatient: EvaluationManagementInpatientRow, headerString: string, benchmarkString: string) {
  return {
    group: eAndMInpatient.rowName,
    metric: headerString,
    cpt99221: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99221', benchmarkString, '', '%'),
    cpt99222: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99222', benchmarkString, '', '%'),
    cpt99223: isValidOrElse(eAndMInpatient.initialHospitalCare, 'cpt99223', benchmarkString, '', '%'),
    totalInitialHospitalCare: '',
    cpt99231: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99231', benchmarkString, '', '%'),
    cpt99232: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99232', benchmarkString, '', '%'),
    cpt99233: isValidOrElse(eAndMInpatient.subsequentHospitalCare, 'cpt99233', benchmarkString, '', '%'),
    totalSubsequentHospitalCare: '',
    cpt99238: isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99238', benchmarkString, '', '%'),
    cpt99239: isValidOrElse(eAndMInpatient.hospitalDischargeDay, 'cpt99239', benchmarkString, '', '%'),
    totalHospitalDischargeDay: ''
  };
}

export function getInpatientFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndMInpatient: EvaluationManagementInpatientRow,
                                                                             viewCommunityBenchmarks: boolean)
  : EvaluationManagementInpatientExportRow {
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getEmInpatientRowObject(eAndMInpatient, headerString, benchmarkString);
}

export function getInpatientFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMInpatient: EvaluationManagementInpatientRow,
                                                                             benchmarkOption: BenchmarkOption)
  : EvaluationManagementInpatientExportRow {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmInpatientRowObject(eAndMInpatient, headerString, benchmarkString);
}


export function getEmergencyRoomSummaryEncounterRowForExport(eAndMEmergencyRoom: EvaluationManagementSummary)
  : EvaluationManagementEmergencyRoomExportRow {
  if (!eAndMEmergencyRoom.totals) {
    return {group: '', metric: '# of Encounters'};
  }
  return {
    group: getGroupName(eAndMEmergencyRoom, 'selectedDateRange'),
    metric: '# of Encounters',
    cpt99281: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99281', 'count', ''),
    cpt99282: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99282', 'count', ''),
    cpt99283: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99283', 'count', ''),
    cpt99284: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99284', 'count', ''),
    cpt99285: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99285', 'count', ''),
    total: valueFromOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'count', '', 0)
  };
}

export function getBlankEmergencyRoomSummaryEncounterRowForExport()
  : EvaluationManagementEmergencyRoomExportRow {
  return {
    group: ' ',
    metric: ' ',
    cpt99281: ' ',
    cpt99282: ' ',
    cpt99283: ' ',
    cpt99284: ' ',
    cpt99285: ' ',
    total: ' '
  };
}

export function getEmergencyRoomSummaryCodingDistributionRowForExport(eAndMEmergencyRoom: EvaluationManagementSummary)
  : EvaluationManagementEmergencyRoomExportRow {
  if (!eAndMEmergencyRoom.totals) {
    return {group: '', metric: 'Coding Distribution'};
  }
  return {
    group: getGroupName(eAndMEmergencyRoom, 'selectedDateRange'),
    metric: 'Coding Distribution',
    cpt99281: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99281', 'percentage', '', '%'),
    cpt99282: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99282', 'percentage', '', '%'),
    cpt99283: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99283', 'percentage', '', '%'),
    cpt99284: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99284', 'percentage', '', '%'),
    cpt99285: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99285', 'percentage', '', '%'),
    total: valueFromOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'percentage', '%', 0, true)
  };
}

function getEmEmergencySummaryMeanRowObject(eAndMEmergencyRoom: EvaluationManagementSummary,
                                            headerString: string, benchmarkString: string) {
  return {
    group: getGroupName(eAndMEmergencyRoom, 'selectedDateRange'),
    metric: headerString,
    cpt99281: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99281', benchmarkString, '', '%'),
    cpt99282: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99282', benchmarkString, '', '%'),
    cpt99283: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99283', benchmarkString, '', '%'),
    cpt99284: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99284', benchmarkString, '', '%'),
    cpt99285: isValidOrElse(eAndMEmergencyRoom.totals.emergencyMedicine, 'cpt99285', benchmarkString, '', '%'),
    total: ''
  };
}

export function getEmergencyRoomSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndMEmergencyRoom: EvaluationManagementSummary,
                                                                                        viewCommunityBenchmarks: boolean)
  : EvaluationManagementEmergencyRoomExportRow {
  if (!eAndMEmergencyRoom.totals) {
    return {group: '', metric: 'CPSC Mean'};
  }
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getEmEmergencySummaryMeanRowObject(eAndMEmergencyRoom, headerString, benchmarkString);
}

export function getEmergencyRoomSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMEmergencyRoom: EvaluationManagementSummary,
                                                                                        benchmarkOption: BenchmarkOption)
  : EvaluationManagementEmergencyRoomExportRow {
  if (!eAndMEmergencyRoom.totals) {
    return {group: '', metric: 'CPSC Mean'};
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmEmergencySummaryMeanRowObject(eAndMEmergencyRoom, headerString, benchmarkString);
}

export function getEmergencyRoomEncounterRowForExport(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow)
  : EvaluationManagementEmergencyRoomExportRow {
  return {
    group: eAndMEmergencyRoom.rowName,
    metric: '# of Encounters',
    cpt99281: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99281', 'count', ''),
    cpt99282: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99282', 'count', ''),
    cpt99283: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99283', 'count', ''),
    cpt99284: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99284', 'count', ''),
    cpt99285: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99285', 'count', ''),
    total: valueFromOrElse(eAndMEmergencyRoom.emergencyMedicine, 'count', '', 0)
  };
}

export function getEmergencyRoomCodingDistributionRowForExport(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow)
  : EvaluationManagementEmergencyRoomExportRow {
  return {
    group: eAndMEmergencyRoom.rowName,
    metric: 'Coding Distribution',
    cpt99281: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99281', 'percentage', '', '%'),
    cpt99282: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99282', 'percentage', '', '%'),
    cpt99283: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99283', 'percentage', '', '%'),
    cpt99284: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99284', 'percentage', '', '%'),
    cpt99285: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99285', 'percentage', '', '%'),
    total: valueFromOrElse(eAndMEmergencyRoom.emergencyMedicine, 'percentage', '%', 0, true)
  };
}

function getEmEmergencyDetailMeanRowObject(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow,
                                           headerString: string, benchmarkString: string) {
  return {
    group: eAndMEmergencyRoom.rowName,
    metric: headerString,
    cpt99281: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99281', benchmarkString, '', '%'),
    cpt99282: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99282', benchmarkString, '', '%'),
    cpt99283: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99283', benchmarkString, '', '%'),
    cpt99284: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99284', benchmarkString, '', '%'),
    cpt99285: isValidOrElse(eAndMEmergencyRoom.emergencyMedicine, 'cpt99285', benchmarkString, '', '%'),
    total: ''
  };
}

export function getEmergencyRoomFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow,
                                                                                 viewCommunityBenchmarks: boolean)
  : EvaluationManagementEmergencyRoomExportRow {
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getEmEmergencyDetailMeanRowObject(eAndMEmergencyRoom, headerString, benchmarkString);
}

export function getEmergencyRoomFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMEmergencyRoom: EvaluationManagementEmergencyRoomRow,
                                                                                 benchmarkOption: BenchmarkOption)
  : EvaluationManagementEmergencyRoomExportRow {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmEmergencyDetailMeanRowObject(eAndMEmergencyRoom, headerString, benchmarkString);
}

export function getEyeExamSummaryEncounterRowForExport(eAndMEyeExam: EvaluationManagementSummary)
  : EvaluationManagementEyeExamExportRow {
  if (!eAndMEyeExam.totals) {
    return {group: '', metric: '# of Encounters'};
  }
  return {
    group: getGroupName(eAndMEyeExam, 'selectedDateRange'),
    metric: '# of Encounters',
    cpt92002: isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92002', 'count', ''),
    cpt92004: isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92004', 'count', ''),
    totalNewVisits: valueFromOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'count', '', 0),
    cpt92012: isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92012', 'count', ''),
    cpt92014: isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92014', 'count', ''),
    totalEstablished: valueFromOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'count', '', 0)
  };
}

export function getBlankEyeExamSummaryEncounterRowForExport()
  : EvaluationManagementEyeExamExportRow {
  return {
    group: ' ',
    metric: ' ',
    cpt92002: ' ',
    cpt92004: ' ',
    totalNewVisits: ' ',
    cpt92012: ' ',
    cpt92014: ' ',
    totalEstablished: ' '
  };
}

export function getEyeExamSummaryCodingDistributionRowForExport(eAndMEyeExam: EvaluationManagementSummary)
  : EvaluationManagementEyeExamExportRow {
  if (!eAndMEyeExam.totals) {
    return {group: '', metric: 'Coding Distribution'};
  }
  return {
    group: getGroupName(eAndMEyeExam, 'selectedDateRange'),
    metric: 'Coding Distribution',
    cpt92002: isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92002', 'percentage', '', '%'),
    cpt92004: isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92004', 'percentage', '', '%'),
    totalNewVisits: valueFromOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'percentage', '%', 0, true),
    cpt92012: isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92012', 'percentage', '', '%'),
    cpt92014: isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92014', 'percentage', '', '%'),
    totalEstablished: valueFromOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'percentage', '%', 0, true)
  };
}

function getEmEyeExamMeanSummaryRowObject(eAndMEyeExam: EvaluationManagementSummary, headerString: string, benchmarkString: string) {
  return {
    group: getGroupName(eAndMEyeExam, 'selectedDateRange'),
    metric: headerString,
    cpt92002: isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92002', benchmarkString, '', '%'),
    cpt92004: isValidOrElse(eAndMEyeExam.totals.eyeExamNewPatient, 'cpt92004', benchmarkString, '', '%'),
    totalNewVisits: '',
    cpt92012: isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92012', benchmarkString, '', '%'),
    cpt92014: isValidOrElse(eAndMEyeExam.totals.eyeExamEstablishedPatient, 'cpt92014', benchmarkString, '', '%'),
    totalEstablished: ''
  };
}

export function getEyeExamSummaryFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndMEyeExam: EvaluationManagementSummary,
                                                                                  viewCommunityBenchmarks: boolean)
  : EvaluationManagementEyeExamExportRow {
  if (!eAndMEyeExam.totals) {
    return {group: '', metric: 'CPSC Mean'};
  }
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getEmEyeExamMeanSummaryRowObject(eAndMEyeExam, headerString, benchmarkString);
}

export function getEyeExamSummaryFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMEyeExam: EvaluationManagementSummary,
                                                                                  benchmarkOption: BenchmarkOption)
  : EvaluationManagementEyeExamExportRow {
  if (!eAndMEyeExam.totals) {
    return {group: '', metric: 'CPSC Mean'};
  }
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmEyeExamMeanSummaryRowObject(eAndMEyeExam, headerString, benchmarkString);
}

export function getEyeExamEncounterRowForExport(eAndMEyeExam: EvaluationManagementEyeExamRow)
  : EvaluationManagementEyeExamExportRow {
  return {
    group: eAndMEyeExam.rowName,
    metric: '# of Encounters',
    cpt92002: isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92002', 'count', ''),
    cpt92004: isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92004', 'count', ''),
    totalNewVisits: valueFromOrElse(eAndMEyeExam.eyeExamNewPatient, 'count', '', 0),
    cpt92012: isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92012', 'count', ''),
    cpt92014: isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92014', 'count', ''),
    totalEstablished: valueFromOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'count', '', 0)
  };
}

export function getEyeExamCodingDistributionRowForExport(eAndMEyeExam: EvaluationManagementEyeExamRow)
  : EvaluationManagementEyeExamExportRow {
  return {
    group: eAndMEyeExam.rowName,
    metric: 'Coding Distribution',
    cpt92002: isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92002', 'percentage', '', '%'),
    cpt92004: isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92004', 'percentage', '', '%'),
    totalNewVisits: valueFromOrElse(eAndMEyeExam.eyeExamNewPatient, 'percentage', '%', 0, true),
    cpt92012: isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92012', 'percentage', '', '%'),
    cpt92014: isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92014', 'percentage', '', '%'),
    totalEstablished: valueFromOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'percentage', '%', 0, true)
  };
}

function getEmEyeExamMeanDetailRowObject(eAndMEyeExam: EvaluationManagementEyeExamRow, headerString: string, benchmarkString: string) {
  return {
    group: eAndMEyeExam.rowName,
    metric: headerString,
    cpt92002: isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92002', benchmarkString, '', '%'),
    cpt92004: isValidOrElse(eAndMEyeExam.eyeExamNewPatient, 'cpt92004', benchmarkString, '', '%'),
    totalNewVisits: '',
    cpt92012: isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92012', benchmarkString, '', '%'),
    cpt92014: isValidOrElse(eAndMEyeExam.eyeExamEstablishedPatient, 'cpt92014', benchmarkString, '', '%'),
    totalEstablished: ''
  };
}

export function getEyeExamFpscMeanRowForExportWithOriginalBenchmarkOptions(eAndMEyeExam: EvaluationManagementEyeExamRow,
                                                                           viewCommunityBenchmarks: boolean)
  : EvaluationManagementEyeExamExportRow {
  const benchmarkString = getBenchmarkFieldFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  const headerString = getBenchmarkMeanNameFromOriginalBenchmarkOptions(viewCommunityBenchmarks);
  return getEmEyeExamMeanDetailRowObject(eAndMEyeExam, headerString, benchmarkString);
}

export function getEyeExamFpscMeanRowForExportWithExtendedBenchmarkOptions(eAndMEyeExam: EvaluationManagementEyeExamRow,
                                                                           benchmarkOption: BenchmarkOption)
  : EvaluationManagementEyeExamExportRow {
  const benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
  const headerString = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
  return getEmEyeExamMeanDetailRowObject(eAndMEyeExam, headerString, benchmarkString);
}

export function getWorkSheetData(data: any, copyRightText: string,
                                 headers?: string[],
                                 breadCrumb?: Breadcrumb[],
                                 dateRange?: any, memberLocationName?: any): XLSX.WorkSheet {
  const ws = XLSX.utils.aoa_to_sheet([
    [copyRightText]
  ]);

  let breadcrumb_name = '';
  let firstIteration = true;
  if (breadCrumb) {
    breadCrumb.forEach(node => {
      if (!firstIteration) {
        breadcrumb_name = breadcrumb_name.concat(' / ' + node.nodeName);
      } else {
        breadcrumb_name = breadcrumb_name.concat(node.nodeName);
        firstIteration = false;
      }
    });

    XLSX.utils.sheet_add_aoa(ws, [
      [breadcrumb_name]
    ], {origin: -1});
  }
  if (dateRange) {
    XLSX.utils.sheet_add_aoa(ws, [[dateRange]], {origin: -1});
  }
  if (memberLocationName) {
    XLSX.utils.sheet_add_aoa(ws, [[memberLocationName]], {origin: -1});
  }

  const opts = headers ? {header: headers} : {};


  if (copyRightText === '') {
    return XLSX.utils.sheet_add_json(ws, data, {...opts, origin: 'A1'});
  } else {
    return XLSX.utils.sheet_add_json(ws, data, {...opts, origin: 'A6'});
  }
}

export function getCFCptFamilySummaryData(summaryData: ClinicalSummaryConsolidation, viewCommunityBenchmarks: boolean,
                                          showAdditionalColumns: boolean, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  summaryData.familyClinicalSummaries
    .filter(clinicalSummary => !suppressZeroes || !isClinicalSummaryZeroSuppressible(clinicalSummary))
    .forEach(clinicalSummary => {
      result.push(getRowForCFCptFamilyExport(clinicalSummary, viewCommunityBenchmarks, showAdditionalColumns));
  });
  return result;
}

export function getCFCptRangeSummaryData(summaryData: ClinicalSummaryConsolidation, viewCommunityBenchmarks: boolean,
                                         showAdditionalColumns: boolean, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  summaryData.rangeClinicalSummaries
    .filter(clinicalSummary => !suppressZeroes || !isClinicalSummaryZeroSuppressible(clinicalSummary))
    .forEach(clinicalSummary => {
      result.push(getRowForCFCptRangeExport(clinicalSummary, viewCommunityBenchmarks, showAdditionalColumns));
  });
  return result;
}

export function getCptAnalysisSummaryData(cptAnalysisData: ClinicalSummaryCode[],
                                          columnHeaders: string[],
                                          viewCommunityBenchmarks: boolean):
  string[][] {
  const result: string[][] = [];

  result.push(columnHeaders);
  cptAnalysisData.forEach(clinicalSummary => {
    result.push(getRowForCPTExport(clinicalSummary, viewCommunityBenchmarks));
  });
  return result;
}

export function getCFCptCodeSummaryData(summaryData: ClinicalSummaryConsolidation, viewCommunityBenchmarks: boolean,
                                        showAdditionalColumns: boolean, suppressZeroes: boolean): string[][] {
  const result: string[][] = [];
  summaryData.cptClinicalSummaries
    .filter(clinicalSummary => !suppressZeroes || !isClinicalSummaryZeroSuppressible(clinicalSummary))
    .forEach(clinicalSummary => {
      result.push(getRowForCFCptCodeExport(clinicalSummary, viewCommunityBenchmarks, showAdditionalColumns));
  });
  return result;
}

export function getRowForCPTAnalysisExport(clinicalSummary: ClinicalSummaryCode,
                                           displayedColumns: any[]): string[] {

  const data: any[] = [];

  displayedColumns.forEach(x => {
    if (!clinicalSummary[x.columnDef]) {
      data.push('-');
    } else if (x.header && x.header.includes('Benchmark')) {
      // @ts-ignore
      data.push(roundToNumber(clinicalSummary[x.columnDef], 2));
    } else if (x.header && x.header.includes('%') || x.header && x.header.includes('Variance')) {
      data.push(clinicalSummary[x.columnDef] + '%');
    } else if (x.primaryColumn) {
      data.push(clinicalSummary.cptCode + ' ' + clinicalSummary[x.columnDef]);
    } else {
      data.push(clinicalSummary[x.columnDef] + '');
    }
  });
  return data;
}


export function getCPTAnalysisSummaryData(cptClinicalSummary: ClinicalSummaryConsolidation, summaryHeaders: any[],
                                          cfteSummary: number | null
): string[][] {
  const result: any[] = [];

  result.push(sumCPTAnalysisData(cptClinicalSummary, summaryHeaders, cfteSummary));
  return result;
}

export function sumCPTAnalysisData(cptClinicalSummary: ClinicalSummaryConsolidation, summaryHeaders: any[], cfteSummary?: number | null) {

  let sumcfteAdjustedWRVUs = 0;
  let sumCount = 0;
  let sumcFTEAdjustedFrequencyVariance = 0;

  let cfteAdjustedWRVUssummary = '';
  let countSummary = 0;
  let cFTEAdjustedFrequencyVarianceSummary = '';
  let displayedCFTE = '';

  const result: any[] = [];

  if (cptClinicalSummary.cptClinicalSummaries) {
    sumcfteAdjustedWRVUs = cptClinicalSummary.cptClinicalSummaries
      .reduce((sum, current) => sum + current.cfteAdjustedWRVUs, 0);

    sumCount = cptClinicalSummary.cptClinicalSummaries
      .reduce((sum, current) => sum + current.frequency, 0);

    sumcFTEAdjustedFrequencyVariance = cptClinicalSummary.cptClinicalSummaries
      .reduce((sum, current) => sum + checkForNulls(current.cFTEAdjustedFrequencyVariance), 0);
  }

  if (summaryHeaders) {
    summaryHeaders.filter(x => x === 'cFTE adj. wRVUs').map(function () {
      cfteAdjustedWRVUssummary = roundTo(sumcfteAdjustedWRVUs, 2)[0];
    });

    summaryHeaders.filter(x => x === 'Count').map(function () {
      countSummary = roundToHundred(sumCount);
    });

    summaryHeaders.filter(x => x === 'cFTE adj. wRVUs Benchmark').map(function () {
      cFTEAdjustedFrequencyVarianceSummary = roundTo(sumcFTEAdjustedFrequencyVariance, 2);
    });
    summaryHeaders.filter(x => x === 'cFTE').map(function () {
      displayedCFTE = roundTo(cfteSummary, 2);
    });
  }
  result.push(cfteAdjustedWRVUssummary, cFTEAdjustedFrequencyVarianceSummary, countSummary, displayedCFTE);
  return result;
}

export function getRowForCFCptCodeExport(clinicalSummary: ClinicalSummaryCode,
                                         viewCommunityBenchmarks: boolean, showAdditionalColumns: boolean): string[] {

  return showAdditionalColumns ? [
    clinicalSummary.cptCode + ' ' + clinicalSummary.cptDesc,
    clinicalSummary.cptRangeLow + ' - ' + clinicalSummary.cptRangeHigh + ' ' + clinicalSummary.cptRangeDesc,
    clinicalSummary.cptFamilyDesc,
    formatNumberToWholeNumber(clinicalSummary.wRVUs) + '',
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedWRVUs) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityWorkRvuBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.workRvuBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedWrvusVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedWrvusVariance) + '',
    formatNumberToWholeNumber(clinicalSummary.frequency) + '',
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedFrequency) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityFrequencyBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.frequencyBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedFrequencyVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedFrequencyVariance) + '',
    formatToCurrency(clinicalSummary.charges, 0) + '',
  ] : [
    clinicalSummary.cptCode + ' ' + clinicalSummary.cptDesc,
    clinicalSummary.cptRangeLow + ' - ' + clinicalSummary.cptRangeHigh + ' ' + clinicalSummary.cptRangeDesc,
    clinicalSummary.cptFamilyDesc,
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedWRVUs) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityWorkRvuBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.workRvuBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedWrvusVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedWrvusVariance) + '',
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedFrequency) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityFrequencyBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.frequencyBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedFrequencyVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedFrequencyVariance) + ''
  ];
}

export function getRowForCPTExport(clinicalSummary: ClinicalSummaryCode, viewCommunityBenchmarks: boolean): string[] {
  if (viewCommunityBenchmarks) {
    return [
      clinicalSummary.cptCode + ' ' + clinicalSummary.cptDesc,
      clinicalSummary.cfteAdjustedFrequency + '',
      clinicalSummary.cfteAdjustedWRVUs + '',
      clinicalSummary.communityFrequencyBenchmark + '',
      clinicalSummary.communityCfteAdjustedFrequencyVariance + '',
      clinicalSummary.communityWorkRvuBenchmark + '',
      clinicalSummary.communityCfteAdjustedWrvusVariance + ''
    ];
  }
  return [
    clinicalSummary.cptCode + ' ' + clinicalSummary.cptDesc,
    clinicalSummary.cfteAdjustedFrequency + '',
    clinicalSummary.cfteAdjustedWRVUs + '',
    clinicalSummary.frequencyBenchmark + '',
    clinicalSummary.cFTEAdjustedFrequencyVariance + '',
    clinicalSummary.workRvuBenchmark + '',
    clinicalSummary.cFTEAdjustedWrvusVariance + ''
  ];
}

export function getCFCptCodeData(summaryData: ClinicalSummaryConsolidation,
                                 copyRight: string,
                                 viewCommunityBenchmarks: boolean,
                                 showAdditionalColumns: boolean,
                                 suppressZeroes: boolean,
                                 nodepath?: string,
                                 dateRange?: any,
                                 memberLocationName?: any): ExportMetadata {
  return {
    copyright: copyRight,
    summaryHeaders: showAdditionalColumns ? ['CPT Code', 'CPT Range', 'CPT Family Description', 'Actual wRVUS',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'Count',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance',
        'Charges'] :
      ['CPT Code', 'CPT Range', 'CPT Family Description',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance'
      ],
    page: 'Cpt Code',
    fileName: 'cptCode',
    summaryData: getCFCptCodeSummaryData(summaryData, viewCommunityBenchmarks, showAdditionalColumns, suppressZeroes),
    filterInfo: getFilterInfo(nodepath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getRowForCFCptRangeExport(clinicalSummary: ClinicalSummaryRange,
                                          viewCommunityBenchmarks: boolean, showAdditionalColumns: boolean): string[] {
  return showAdditionalColumns ? [
    clinicalSummary.cptRangeLow + ' - ' + clinicalSummary.cptRangeHigh + ' ' + clinicalSummary.cptRangeDesc,
    clinicalSummary.cptFamilyDesc,
    formatNumberToWholeNumber(clinicalSummary.wRVUs) + '',
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedWRVUs) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityWorkRvuBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.workRvuBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedWrvusVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedWrvusVariance) + '',
    formatNumberToWholeNumber(clinicalSummary.frequency) + '',
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedFrequency) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityFrequencyBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.frequencyBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedFrequencyVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedFrequencyVariance) + '',
    formatToCurrency(clinicalSummary.charges, 0) + '',
  ] : [
    clinicalSummary.cptRangeLow + ' - ' + clinicalSummary.cptRangeHigh + ' ' + clinicalSummary.cptRangeDesc,
    clinicalSummary.cptFamilyDesc,
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedWRVUs) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityWorkRvuBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.workRvuBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedWrvusVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedWrvusVariance) + '',
    formatNumberToWholeNumber(clinicalSummary.cfteAdjustedFrequency) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityFrequencyBenchmark) + '' :
      formatNumberToWholeNumber(clinicalSummary.frequencyBenchmark) + '',
    viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedFrequencyVariance) + '' :
      formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedFrequencyVariance) + ''

  ];
}

export function getCFCptRangeData(summaryData: ClinicalSummaryConsolidation,
                                  copyRight: string,
                                  viewCommunityBenchmarks: boolean,
                                  showAdditionalColumns: boolean,
                                  suppressZeroes: boolean,
                                  nodepath?: string,
                                  dateRange?: any,
                                  memberLocationName?: any): ExportMetadata {
  return {
    copyright: copyRight,
    summaryHeaders: showAdditionalColumns ? ['CPT Range', 'CPT Family Description', 'Actual wRVUS',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'Count',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance',
        'Charges'] :
      ['CPT Range', 'CPT Family Description',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance'
      ],
    page: 'Cpt Range',
    fileName: 'cptRange',
    summaryData: getCFCptRangeSummaryData(summaryData, viewCommunityBenchmarks, showAdditionalColumns, suppressZeroes),
    filterInfo: getFilterInfo(nodepath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getRowForCFCptFamilyExport(clinicalSummary: ClinicalSummaryFamily, viewCommunityBenchmarks: boolean,
                                           showAdditionalColumns: boolean)
  : string[] {
  return showAdditionalColumns ? [
      clinicalSummary.cptFamilyDesc,
      formatNumberToWholeNumber(clinicalSummary.wRVUs) + '',
      formatNumberToWholeNumber(clinicalSummary.cfteAdjustedWRVUs) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityWorkRvuBenchmark) + '' :
        formatNumberToWholeNumber(clinicalSummary.workRvuBenchmark) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedWrvusVariance) + '' :
        formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedWrvusVariance) + '',
      formatNumberToWholeNumber(clinicalSummary.frequency) + '',
      formatNumberToWholeNumber(clinicalSummary.cfteAdjustedFrequency) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityFrequencyBenchmark) + '' :
        formatNumberToWholeNumber(clinicalSummary.frequencyBenchmark) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedFrequencyVariance) + '' :
        formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedFrequencyVariance) + '',
      formatToCurrency(clinicalSummary.charges, 0) + '',
    ] :
    [
      clinicalSummary.cptFamilyDesc,
      formatNumberToWholeNumber(clinicalSummary.cfteAdjustedWRVUs) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityWorkRvuBenchmark) + '' :
        formatNumberToWholeNumber(clinicalSummary.workRvuBenchmark) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedWrvusVariance) + '' :
        formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedWrvusVariance) + '',
      formatNumberToWholeNumber(clinicalSummary.cfteAdjustedFrequency) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityFrequencyBenchmark) + '' :
        formatNumberToWholeNumber(clinicalSummary.frequencyBenchmark) + '',
      viewCommunityBenchmarks ? formatNumberToWholeNumber(clinicalSummary.communityCfteAdjustedFrequencyVariance) + '' :
        formatNumberToWholeNumber(clinicalSummary.cFTEAdjustedFrequencyVariance) + ''
    ];
}

export function getCFCptFamilyData(summaryData: ClinicalSummaryConsolidation,
                                   copyRight: string,
                                   viewCommunityBenchmarks: boolean,
                                   showAdditionalColumns: boolean,
                                   suppressZeroes: boolean,
                                   nodepath?: string,
                                   dateRange?: any,
                                   memberLocationName?: any): ExportMetadata {
  return {
    copyright: copyRight,
    summaryHeaders: showAdditionalColumns ? ['CPT Family Description', 'Actual wRVUS',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'Count',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance',
        'Charges'] :
      ['CPT Family Description',
        'cFTE adj. wRVUs',
        viewCommunityBenchmarks ? 'cFTE adj. wRVUs Community Benchmark Mean' : 'cFTE adj. wRVUs Academic Benchmark Mean',
        'cFTE adj. wRVUs Variance',
        'cFTE adj. Count',
        viewCommunityBenchmarks ? 'cFTE adj. Count Community Benchmark Mean' : 'cFTE adj. Count Academic Benchmark Mean',
        'cFTE adj. Count Variance'
      ],
    page: 'Cpt Family',
    fileName: 'cptFamily',
    summaryData: getCFCptFamilySummaryData(summaryData, viewCommunityBenchmarks, showAdditionalColumns, suppressZeroes),
    filterInfo: getFilterInfo(nodepath, dateRange, memberLocationName),
    isBlankRowAfterSummary: true
  };
}

export function getFilterInfo(nodepath?: string,
                              dateRange?: any,
                              memberLocationName?: any,
                              lagPeriod?: any,
                              memberBillingArea?: any,
                              invoiceType?: any,
                              payerCategory?: any,
                              telehealthFlag?: string): string[] {
  const headerInfo: string[] = [];

  let breadcrumbName = '';

  if (nodepath) {
    breadcrumbName = calculateBreadcrumbs(nodepath);
  }
  headerInfo.push(breadcrumbName);

  if (dateRange) {
    headerInfo.push(dateRange);
  }
  if (memberLocationName) {
    headerInfo.push(memberLocationName);
  }

  if (lagPeriod) {
    headerInfo.push(lagPeriod);
  }

  if (memberBillingArea) {
    headerInfo.push(memberBillingArea);
  }

  if (invoiceType) {
    headerInfo.push(invoiceType);
  }
  if (payerCategory) {
    headerInfo.push(payerCategory);
  }
  if (telehealthFlag) {
    headerInfo.push(telehealthFlag);
  }
  return headerInfo;
}

export function getFilterInfoForCptsDeniedModal(nodepath: string,
                                                dateRange: any,
                                                memberLocationName: any,
                                                lagPeriod: any,
                                                memberBillingArea: any,
                                                payerCategory: any): string[] {
  return [nodepath, dateRange, memberLocationName, lagPeriod,
    memberBillingArea, payerCategory];
}

export function calculateBreadcrumbs(nodepath: string): string {
  let departments, specialties, providers;
  const deptNodes: OntologyNode[] = [];
  const deptPartialNodes: OntologyNode[] = [];
  const specNodes: OntologyNode[] = [];
  const specPartialNodes: OntologyNode[] = [];
  const provNodes: OntologyNode[] = [];
  let deptNodesSet: Set<String>;
  let specNodesSet: Set<String>;
  let providerNodesSet: Set<String>;
  const ontologyData = getOntologyData();

  if (!ontologyData || !ontologyData.ontologyHierarchy[0]) {
    return '';
  }

  const ontology = ontologyData.ontologyHierarchy[0].children;
  const numberOfProviders = ontologyData.numberOfProviders;
  const numberOfSpecialties = ontologyData.numberOfSpecialties;
  const numberOfDepartments = ontologyData.numberOfDepartments;

  const nodePaths = nodepath.split('|');

  const depFullSelectionNodes: string[] = [];
  const depPartialSelectionNodes: string[] = [];
  const splFullSelectionNodes: string[] = [];
  const splPartialSelectionNodes: string[] = [];
  const proFullSelectionNodes: string[] = [];

  nodePaths.forEach(x => {
    const depth = getFirstNodePathFrom(x).split('\\').length - 1;
    switch (depth) {

      case 1:
      default:
        departments = 0;
        specialties = 0;
        providers = 0;
        getCurrentNodePathLabel([0, 0, 0]);
        return;
      case 2:
        if (!depFullSelectionNodes.includes(x)) {
          depFullSelectionNodes.push(x);
        }
        break;

      case 3:
        const arr = x.split('\\');
        const depPath = '\\' + arr[1] + '\\' + arr[2];
        if (!depPartialSelectionNodes.includes(depPath)) {
          depPartialSelectionNodes.push(depPath);
        }

        if (!splFullSelectionNodes.includes(x)) {
          splFullSelectionNodes.push(x);
        }
        break;

      case 4:
        const arrProv = x.split('\\');
        const depPathFromProv = '\\' + arrProv[1] + '\\' + arrProv[2];
        if (!depPartialSelectionNodes.includes(depPathFromProv)) {
          depPartialSelectionNodes.push(depPathFromProv);
        }

        const splPathFromProv = '\\' + arrProv[1] + '\\' + arrProv[2] + '\\' + arrProv[3];
        if (!splPartialSelectionNodes.includes(splPathFromProv)) {
          splPartialSelectionNodes.push(splPathFromProv);
        }

        if (!proFullSelectionNodes.includes(x)) {
          proFullSelectionNodes.push(x);
        }
        break;
    }
  });

  // Set selected Departments
  depFullSelectionNodes.forEach(depNodePath => {
    const nodes = ontology.filter(ontologyNode => ontologyNode.nodePath === depNodePath);
    nodes.forEach(d => {
      deptNodes.push(d);
    });
  });

  deptNodes.forEach(dept => {
    specNodes.push(...dept.children);
  });
  specNodes.forEach(spec => {
    provNodes.push(...spec.children);
  });

  depPartialSelectionNodes.forEach(depNodePath => {
    const nodes = ontology.filter(ontologyNode => ontologyNode.nodePath === depNodePath);
    nodes.forEach(d => {
      deptNodes.push(d);
      deptPartialNodes.push(d);
    });
  });

  // Set selected Specialties
  deptPartialNodes.forEach(dp => {
    dp.children.forEach(dpc => {
      if (splFullSelectionNodes.includes(dpc.nodePath)) {
        specNodes.push(dpc);
      }
    });
  });

  specNodes.forEach(spec => {
    provNodes.push(...spec.children);
  });

  deptPartialNodes.forEach(dp => {
    dp.children.forEach(dpc => {
      if (splPartialSelectionNodes.includes(dpc.nodePath)) {
        specNodes.push(dpc);
        specPartialNodes.push(dpc);
      }
    });
  });

  // Set selected providers

  specPartialNodes.forEach(dp => {
    dp.children.forEach(dpc => {
      if (proFullSelectionNodes.includes(dpc.nodePath)) {
        provNodes.push(dpc);
      }
    });
  });

  // @ts-ignore
  deptNodesSet = new Set<string>(deptNodes.map(node1 => node1.nodePath.split('\\').pop()));
  // @ts-ignore
  specNodesSet = new Set<String>(specNodes.map(node2 => node2.nodePath.split('\\').pop()));
  // @ts-ignore
  providerNodesSet = new Set<String>(provNodes.map(node3 => node3.nodePath.split('\\').pop()));

  if (deptNodesSet.size === 1) {
    departments = deptNodes[0].nodeName;
  } else if (deptNodesSet.size === numberOfDepartments) {
    departments = 0;
  } else {
    departments = deptNodesSet.size;
  }

  // @ts-ignore
  if (specNodesSet.size === 1) {
    specialties = specNodes[0].nodeName;
  } else {
    // @ts-ignore
    if (specNodesSet.size === numberOfSpecialties) {
      specialties = 0;
    } else {
      // @ts-ignore
      specialties = specNodesSet.size;
    }
  }

  // @ts-ignore
  if (providerNodesSet.size === 1) {
    providers = provNodes[0].nodeName;
  } else { // @ts-ignore
    if (providerNodesSet.size === numberOfProviders) {
      providers = 0;
    } else {
      // @ts-ignore
      providers = providerNodesSet.size;
    }
  }

  return getCurrentNodePathLabel([departments, specialties, providers]);
}

export function getCurrentNodePathLabel(nodePathLevels: (number | string)[]) {
  const departmentsText = getLevelText(nodePathLevels[0], ' Departments');
  const specialtiesText = getLevelText(nodePathLevels[1], ' Specialties');
  const providersText = getLevelText(nodePathLevels[2], ' Providers');
  return `${departmentsText} / ${specialtiesText} / ${providersText}`;
}

export function getLevelText(numberAtLevel: number | string, level: string): string {
  if (!numberAtLevel) {
    return 'All' + level;
  } else if (typeof numberAtLevel === 'string') {
    return numberAtLevel;
  } else {
    return numberAtLevel + level;
  }
}

export function getPayerHierarchyString(filters: FilterCriteria): string {
  let payerString = `Payer Category: ${filters.payerCategory.payerCategoryDescription === '' ?
    DEFAULT_PAYER_CATEGORY.payerCategoryDescription : filters.payerCategory.payerCategoryDescription}`;
  const nationalPayerDescription = filters.nationalPayerDescription;
  const memberPayerDescription = filters.memberPayerDescription;
  if (nationalPayerDescription && nationalPayerDescription.length > 0) {
    payerString = payerString.concat(': ' + nationalPayerDescription);
  }
  if (memberPayerDescription && memberPayerDescription.length > 0) {
    payerString = payerString.concat(': ' + memberPayerDescription);
  }
  return payerString;
}

export function findOptionWithValue(metricOptions: ExportOption[], key: keyof ExportOption, value: any): ExportOption | undefined {
  let foundOption: ExportOption | undefined;
  for (const option of metricOptions) {
    foundOption = option[key] === value ? option : findOptionWithValue(option.children, key, value);
    if (foundOption) {
      return foundOption;
    }
  }
}

export function getExcelDataForProcedureSummary(
  cfpByMultilevels: GroupedCfpByMultilevel[],
  summaryData: CfpByMultilevel[],
  viewCommunity: boolean,
  cptViewType: CptViewType, filterInfo: string[], dataExportConfig: DataExportConfig,
  showCfteWrvus: boolean, showCfteCount: boolean): ExportMetadata {
  const columns: DataTableColumns[] = getColumnForCFP(dataExportConfig.tabName, cptViewType, viewCommunity, showCfteWrvus, showCfteCount);
  const summaryColumns: DataTableColumns[] = SummaryProcedureSummaryComponent.getSummaryColumnsByCptViewType(cptViewType,
    viewCommunity, showCfteWrvus, showCfteCount);
  const detailData: string[][] = [];
  const sumData: string[][] = [];
  cfpByMultilevels.forEach(c => {
    c.data.forEach(dat => {
      detailData.push(generateRowForProcedureSummary(dat, columns));
    });
  });

  summaryData.forEach(sd => {
    sumData.push(generateRowForProcedureSummary(sd, summaryColumns));
  });

  return {
    detailHeaders: columns.map(c => c.header.replace(/\n/g, '')),
    detailData,
    fileName: dataExportConfig.fileName,
    page: dataExportConfig.page,
    copyright: `Clinical Practice Solutions Center Productivity Summary - Procedure Summary ${moment().format('L')}`,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: false,
      showPayer: false,
      showVisitType: false
    },
    summaryData: sumData,
    summaryHeaders: summaryColumns.map(c => c.header.replace(/\n/g, '')),
    sheetName: dataExportConfig.sheetName,
    filterInfo
  };
}


export function getCSVDataForProcedureSummary(
  cfpByMultilevels: GroupedCfpByMultilevel[],
  summaryData: CfpByMultilevel[],
  viewCommunity: boolean,
  cptViewType: CptViewType, dataExportConfig: DataExportConfig,
  showCfteWrvus: boolean, showCfteCount: boolean): Export {

  const columns: DataTableColumns[] = getColumnForCFP(dataExportConfig.tabName, cptViewType, viewCommunity, showCfteWrvus, showCfteCount);
  const summaryColumns: DataTableColumns[] = SummaryProcedureSummaryComponent.getSummaryColumnsByCptViewType(cptViewType,
    viewCommunity, showCfteWrvus, showCfteCount);

  const detailData: string[][] = [];
  const sumData: string[][] = [];
  const benchmarkIndex = summaryColumns.findIndex(c => c.columnType === ColumnType.BENCHMARK);
  const frequencyBenchmarkIndex = summaryColumns.findIndex(c => c.columnType === ColumnType.FREQUENCY_BENCHMARK);
  cfpByMultilevels.forEach(c => {
    c.data.forEach(dat => {
      detailData.push(generateRowForProcedureSummary(dat, columns));
    });
  });
  summaryData.forEach(sd => {
    const newRow = generateRowForProcedureSummary(sd, summaryColumns);
    newRow.splice(benchmarkIndex + 1, 0, '');
    newRow.splice(frequencyBenchmarkIndex + 2, 0, '');
    sumData.push([''].concat(newRow));
  });
  const communityString = viewCommunity ? 'Community' : 'Academic';
  let firstHeaders: string[] = [];
  switch (dataExportConfig.tabName) {
    case 'department':
      firstHeaders = ['Department'];
      break;
    case 'specialty':
      firstHeaders = ['Specialty / Department'];
      break;
    case 'provider':
      firstHeaders = ['Provider / Specialty / Department'];
  }
  const headersArray = firstHeaders.concat(summaryColumns.map(c => c.header.replace(/\n/g, '')));
  if (showCfteWrvus) {
    headersArray.splice(benchmarkIndex + 2, 0, `cFTE adj. wRVUs ${communityString} Variance`);
  }
  if (showCfteCount) {
    headersArray.splice(frequencyBenchmarkIndex + 2, 0, `cFTE adj. Count ${communityString} Benchmark Mean`);
  }
  return {
    data: sumData.concat(detailData),
    headers: headersArray,
    fileName: dataExportConfig.fileName,
    page: dataExportConfig.page,
    title: `Clinical Practice Solutions Center Productivity Summary - Procedure Summary ${moment().format('L')}`,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: false,
      showPayer: false,
      showVisitType: false
    },
    includesProcedureSummary: true
  };
}

export function getCptGroupBreadcrumb(procedureSummaryDrill: ProcedureSummaryDrillImpl | undefined,
                                      selectedGroup: CptGroup | undefined, dataToExport: CfpByMultilevel[]) {

  if (procedureSummaryDrill || selectedGroup) {
    return composeBreadcrumbBasedOnFilteredCfpData(dataToExport);
  } else {
    return 'All Families / All Ranges / All Codes';
  }
}

function composeBreadcrumbBasedOnFilteredCfpData(dataToExport: CfpByMultilevel[]) {
  let cptFamilyText = '';
  let cptRangeText = '';
  let cptCodeText = '';

  const distinctFamilies: string[] = [];
  const distinctRanges: string[] = [];
  const distinctCodes: string[] = [];

  populateDistinctValues(distinctFamilies, 'cptFamilyDesc', dataToExport);
  populateDistinctValues(distinctRanges, 'cptRangeDesc', dataToExport);
  populateDistinctValues(distinctCodes, 'cptCode', dataToExport);

  cptFamilyText = distinctFamilies.length === 1 ? dataToExport[0].cptFamilyDesc : `${distinctFamilies.length} Families`;
  cptRangeText = distinctRanges.length === 1 ? composeRangeDescription(dataToExport[0]) : `${distinctRanges.length} Ranges`;
  cptCodeText = distinctCodes.length === 1 ? dataToExport[0].cptCode : `${distinctCodes.length} Codes`;

  return `${cptFamilyText} / ${cptRangeText} / ${cptCodeText}`;
}

function composeRangeDescription(cfpDatum: CfpByMultilevel) {
  return `${cfpDatum.cptRangeDesc} ${cfpDatum.cptRangeLow} - ${cfpDatum.cptRangeHigh}`;
}

function populateDistinctValues(distinctArray: string[], fieldToPopulate: string, cfpData: CfpByMultilevel[]) {
  cfpData.forEach(item => {
    if (!distinctArray.includes(<string>item[fieldToPopulate])) {
      distinctArray.push(<string>item[fieldToPopulate]);
    }
  });
}

export function getColumnForCFP(tabName: string, cptViewType: CptViewType, viewCommunity: boolean,
                                showCfteWrvus: boolean, showCfteCount: boolean): DataTableColumns[] {
  if (tabName === 'provider') {
    return getProviderColumnForCFP(cptViewType, viewCommunity, false, showCfteWrvus, showCfteCount);
  }
  if (tabName === 'department') {
    return getDepartmentColumnForCFP(cptViewType, viewCommunity, false, showCfteWrvus, showCfteCount);
  }
  return getSpecialtyColumnForCFP(cptViewType, viewCommunity, false, showCfteWrvus, showCfteCount);
}


function generateRowForProcedureSummary(entry: CfpByMultilevel,
                                        displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    let columnData: string;
    if (col.columnDef === 'cptRangeLow' || col.columnDef === 'cptCode') {
      columnData = `${col.upperDataName ? col.upperDataName(entry) : ''}\n ${col.dataName(entry)}`.replace(/\n/g, '');
    } else {
      columnData = `${col.dataName(entry)}`.replace(/\n/g, '');
    }
    row.push(columnData);
  });
  return row;
}


export function composeCurrentLocationFiltersForCsvExport(isSingleLocation: boolean, selectedLocations: CurrentMemberLocation[],
                                                          filters: FilterCriteria): string {
  return 'Location Filters: ' + (isSingleLocation
    ? getSingleLocation(filters)
    : selectedLocations.map(loc => loc.memberLocationName).join(', '));
}

export function composeCurrentLocationFiltersForExcelExport(isSingleLocation: boolean, selectedLocations: CurrentMemberLocation[],
                                                            maxLocation: number, additionalLocations: number,
                                                            filters: FilterCriteria): string {
  return 'Location Filters: ' + (isSingleLocation
    ? getSingleLocation(filters)
    : selectedLocations.slice(0, maxLocation).map(loc => loc.memberLocationName).join(', ')
    + (additionalLocations > 0 ? (' + ' + additionalLocations + ' more') : ''));
}

function getSingleLocation(filters: FilterCriteria): string {
  return filters.memberLocation.memberLocationName.length > 0
    ? filters.memberLocation.memberLocationName
    : DEFAULT_MEMBER_LOCATION.memberLocationName;
}

export function getVarianceFromTypeForNpvSnapshot(
  newPatientVisit: MergedNewPatientVisitSnapshotEntry,
  benchmark: BenchmarkPercentile,
  viewCommunityBenchmarks: boolean, benchmarkOption?: BenchmarkOption | undefined): number | undefined {
  benchmarkOption = benchmarkOption ?? (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);

  return getNpvDataByBenchmarkOrVariancePercentileForNpvSnapshot(newPatientVisit, benchmark, benchmarkOption);
}

function getNpvDataByBenchmarkOrVariancePercentileForNpvSnapshot(
  newPatientVisit: MergedNewPatientVisitSnapshotEntry,
  benchmark: BenchmarkPercentile,
  benchmarkOption: BenchmarkOption): number | undefined {
  return undefinedIfZero(newPatientVisit[getDesignatedNpvBenchmarkObject(benchmarkOption)]
    [`benchmark${readableNameOfColumnDef(benchmark)}`]) ? checkForNulls(newPatientVisit[
    getDesignatedNpvVarianceObject(benchmarkOption)][`variance${readableNameOfColumnDef(benchmark)}`]) : undefined;
}

export function getBenchmarkFromTypeForNpvSnapshot(
    newPatientVisit: MergedNewPatientVisitSnapshotEntry,
    benchmark: BenchmarkPercentile, viewCommunityBenchmarks: boolean, benchmarkOption: BenchmarkOption | undefined
): number | undefined {
  benchmarkOption = benchmarkOption ?? (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  return newPatientVisit[getDesignatedNpvBenchmarkObject(benchmarkOption)][`benchmark${readableNameOfColumnDef(benchmark)}`];
}
