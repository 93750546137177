import {Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FilterCriteria} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.local';
import {
  CptCategories,
  EvaluationManagement,
  EvaluationManagementMultilevel,
  ProviderMultilevelCptCounts
} from '../../shared/models';
import {map} from 'rxjs/operators';
import {DatasetType} from '../../shared/enums';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const EvaluationManagementServiceToken =
  new InjectionToken<EvaluationManagementService>('Evaluation Management Service');

export interface EvaluationManagementService {

  getProviderTrendEvaluationManagement(filterCriteria: FilterCriteria): Observable<EvaluationManagement>;

  getMultilevelProviderEvaluationManagement(filter: FilterCriteria, dataset: DatasetType): Observable<EvaluationManagementMultilevel>;

  getMultilevelProviderEvaluationManagementMonthly(filter: FilterCriteria):
    Observable<{nodes: ProviderMultilevelCptCounts[], totals: CptCategories}>;
}

@Injectable()
export class HttpEvaluationManagementService implements EvaluationManagementService {

  constructor(private httpClient: HttpClient) {
  }

  getProviderTrendEvaluationManagement(filterCriteria: FilterCriteria): Observable<EvaluationManagement> {
    const requestBody = getRequestBodyFromFilter(filterCriteria);
    const url = `${environment.api}/api/evaluationManagementTrendByNodePath`;
    return this.httpClient.post<EvaluationManagement>(
      url,
      requestBody,
      {params: new HttpParams().set('memberKey', filterCriteria.memberKey.toString())},
    );
  }

  getMultilevelProviderEvaluationManagement(filterCriteria: FilterCriteria, dataset: DatasetType):
    Observable<EvaluationManagementMultilevel> {
    const requestBody = getRequestBodyFromFilter(filterCriteria);
    const url = `${environment.api}/api/evaluationManagementByMultiLevelNodePath`;
    return this.httpClient.post<EvaluationManagementMultilevel>(
      url,
      requestBody,
      {params: new HttpParams()
          .set('memberKey', filterCriteria.memberKey.toString())
          .set('dataset', dataset.toString())
      },
    );
  }

  getMultilevelProviderEvaluationManagementMonthly(filterCriteria: FilterCriteria):
    Observable<{nodes: ProviderMultilevelCptCounts[], totals: CptCategories}> {
      return this.getProviderTrendEvaluationManagement(filterCriteria)
        .pipe(map((data: EvaluationManagement) => mapTrendToMultiLevel(data)));
  }
}

export function mapTrendToMultiLevel(data: EvaluationManagement):
  {nodes: ProviderMultilevelCptCounts[], totals: CptCategories} {
  const merged = {
    ...data.providerEvaluationManagement,
    nodes: data.providerEvaluationManagement.nodes.map(node => {
      return {
        ...node,
        departmentNodeName: '',
        departmentNodeId: 0,
        departmentNodePath: '',
        specialtyNodeName: '',
        specialtyNodeId: 0,
        specialtyNodePath: '',
        providerNodeName: '',
        providerNodeId: 0,
        providerNodePath: '',
      };
    })
  };
  return merged;
}
