import {MergedProductivityTrendEntry, MonthProductivityData, MonthProductivityEntry} from './models';
import {aMergedProductivityTrendEntryDefault} from './test/helper-functions.spec';
import {
  ProductivityMultiLevelSnapshot,
  ProductivitySnapshot,
  ProviderProductivity
} from '../productivity-summary/services/ProviderProductivity';
import {aProductivitySnapshotDefault, LevelType} from './helpers';
import * as _ from 'lodash';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedProviderNewPatientVisitMultiLevelData,
  NewPatientVisitSnapshotMultiLevel,
  ProviderNewPatientVisitMultiLevelData
} from '../new-patient-visits/components/npv-models';

export function getMergedProductivitySnapshotData(current: ProviderProductivity[], previous: ProviderProductivity[], level?: LevelType):
  ProductivitySnapshot[] | ProductivityMultiLevelSnapshot[] {
  const tempProviderSnapshot: any = {};
  const mergedProductivityData: ProductivitySnapshot[] = [];
  let fieldToGroupBy = 'nodePath';

  switch (level) {
    case LevelType.specialty:
      fieldToGroupBy = 'specialtyNodePath';
      break;
    case LevelType.department:
      fieldToGroupBy = 'departmentNodePath';
      break;
    case LevelType.provider:
      fieldToGroupBy = 'providerNodePath';
      break;
  }

  if (current) {
    current.forEach(productivity => {
      const nodePath = productivity[fieldToGroupBy] || productivity.nodePath;
      // @ts-ignore
      tempProviderSnapshot[nodePath] =
        getProductivitySnapshot(aProductivitySnapshotDefault(), productivity, undefined, level);
    });
  }

  if (previous) {
    previous.forEach(productivity => {
      const nodePath = productivity[fieldToGroupBy] || productivity.nodePath;
      // @ts-ignore
      let productivitySnapshot = tempProviderSnapshot[nodePath];

      productivitySnapshot = productivitySnapshot
        ? getProductivitySnapshot(productivitySnapshot, undefined, productivity, level)
        : getProductivitySnapshot(aProductivitySnapshotDefault(), undefined, productivity, level);
      // @ts-ignore
      tempProviderSnapshot[nodePath] = productivitySnapshot;
    });
  }

  Object.keys(tempProviderSnapshot).forEach(key => {
    mergedProductivityData.push(tempProviderSnapshot[key]);
  });

  return mergedProductivityData;
}

export function getProductivitySnapshot(
  baseObject: ProductivitySnapshot,
  current?: ProviderProductivity,
  previous?: ProviderProductivity,
  level?: LevelType
): ProductivitySnapshot {

  const nodeInfo: any = {
    nodeId: current ? current.nodeId : previous?.nodeId,
    nodePath: current ? current.nodePath : previous?.nodePath,
    nodeName: current ? current.nodeName : previous?.nodeName,
  };

  if (current?.hasOwnProperty('providerNodeId') || previous?.hasOwnProperty('providerNodeId')) {
    nodeInfo.providerNodeId = current ? current.providerNodeId : previous?.providerNodeId;
    nodeInfo.providerNodeName = current ? current.providerNodeName : previous?.providerNodeName;
    nodeInfo.providerNodePath = current ? current.providerNodePath : previous?.providerNodePath;

    nodeInfo.specialtyNodeId = current ? current.specialtyNodeId : previous?.specialtyNodeId;
    nodeInfo.specialtyNodeName = current ? current.specialtyNodeName : previous?.specialtyNodeName;
    nodeInfo.specialtyNodePath = current ? current.specialtyNodePath : previous?.specialtyNodePath;

    nodeInfo.departmentNodeId = current ? current.departmentNodeId : previous?.departmentNodeId;
    nodeInfo.departmentNodeName = current ? current.departmentNodeName : previous?.departmentNodeName;
    nodeInfo.departmentNodePath = current ? current.departmentNodePath : previous?.departmentNodePath;

    switch (level) {
      case LevelType.specialty:
        nodeInfo.nodeId = current ? current.specialtyNodeId : previous?.specialtyNodeId;
        nodeInfo.nodeName = current ? current.specialtyNodeName : previous?.specialtyNodeName;
        nodeInfo.nodePath = current ? current.specialtyNodePath : previous?.specialtyNodePath;
        break;
      case LevelType.department:
        nodeInfo.nodeId = current ? current.departmentNodeId : previous?.departmentNodeId;
        nodeInfo.nodeName = current ? current.departmentNodeName : previous?.departmentNodeName;
        nodeInfo.nodePath = current ? current.departmentNodePath : previous?.departmentNodePath;
        break;
      case LevelType.provider:
        nodeInfo.nodeId = current ? current.providerNodeId : previous?.providerNodeId;
        nodeInfo.nodeName = current ? current.providerNodeName : previous?.providerNodeName;
        nodeInfo.nodePath = current ? current.providerNodePath : previous?.providerNodePath;
        break;
    }
  }

  const currentInfo = current ?
    {
      benchmarkPercentileRank: current ? current.benchmarkPercentileRank : 0,
      communityBenchmarkPercentileRank: current ? current.communityBenchmarkPercentileRank : 0,
      benchmark25thPercentile: current ? current.benchmark25thPercentile : 0,
      benchmark50thPercentile: current ? current.benchmark50thPercentile : 0,
      benchmark65thPercentile: current ? current.benchmark65thPercentile : 0,
      benchmark75thPercentile: current ? current.benchmark75thPercentile : 0,
      benchmark90thPercentile: current ? current.benchmark90thPercentile : 0,
      benchmarkMean: current ? current.benchmarkMean : 0,
      benchmarkPercentile: current ? current.benchmarkPercentile : 0,
      wRVUs: current ? current.wRVUs : 0,
      cfteAdjustedWRVUs: current ? current.cfteAdjustedWRVUs : 0,
      cfte: current ? current.cfte : 0,
      charges: current ? current.charges : 0,
      variance25thPercentile: current ? current.variance25thPercentile : 0,
      variance50thPercentile: current ? current.variance50thPercentile : 0,
      variance65thPercentile: current ? current.variance65thPercentile : 0,
      variance75thPercentile: current ? current.variance75thPercentile : 0,
      variance90thPercentile: current ? current.variance90thPercentile : 0,
      varianceMean: current ? current.varianceMean : 0,
      imputedReportedcFTE25thPercentile: current ? current.imputedReportedcFTE25thPercentile : 0,
      imputedReportedcFTE50thPercentile: current ? current.imputedReportedcFTE50thPercentile : 0,
      imputedReportedcFTE65thPercentile: current ? current.imputedReportedcFTE65thPercentile : 0,
      imputedReportedcFTE75thPercentile: current ? current.imputedReportedcFTE75thPercentile : 0,
      imputedReportedcFTEMean: current ? current.imputedReportedcFTEMean : 0,
      imputedReportedcFTE90thPercentile: current ? current.imputedReportedcFTE90thPercentile : 0,
      communityBenchmark25thPercentile: current ? current.communityBenchmark25thPercentile : 0,
      communityBenchmark50thPercentile: current ? current.communityBenchmark50thPercentile : 0,
      communityBenchmark65thPercentile: current ? current.communityBenchmark65thPercentile : 0,
      communityBenchmark75thPercentile: current ? current.communityBenchmark75thPercentile : 0,
      communityBenchmark90thPercentile: current ? current.communityBenchmark90thPercentile : 0,
      communityBenchmarkMean: current ? current.communityBenchmarkMean : 0,
      communityVariance25thPercentile: current ? current.communityVariance25thPercentile : 0,
      communityVariance50thPercentile: current ? current.communityVariance50thPercentile : 0,
      communityVariance65thPercentile: current ? current.communityVariance65thPercentile : 0,
      communityVariance75thPercentile: current ? current.communityVariance75thPercentile : 0,
      communityVariance90thPercentile: current ? current.communityVariance90thPercentile : 0,
      communityVarianceMean: current ? current.communityVarianceMean : 0,
      communityImputedReportedcFTE25thPercentile: current ? current.communityImputedReportedcFTE25thPercentile : 0,
      communityImputedReportedcFTE50thPercentile: current ? current.communityImputedReportedcFTE50thPercentile : 0,
      communityImputedReportedcFTE65thPercentile: current ? current.communityImputedReportedcFTE65thPercentile : 0,
      communityImputedReportedcFTE75thPercentile: current ? current.communityImputedReportedcFTE75thPercentile : 0,
      communityImputedReportedcFTEMean: current ? current.communityImputedReportedcFTEMean : 0,
      communityImputedReportedcFTE90thPercentile: current ? current.communityImputedReportedcFTE90thPercentile : 0,
      specialtyPerformanceActualWrvus: current ? current.specialtyPerformanceActualWrvus : undefined,
      specialtyPerformanceCfteAdjWrvus: current ? current.specialtyPerformanceCfteAdjWrvus : undefined,
      specialtyPerformanceActualWrvuspVariance: current ? current.specialtyPerformanceActualWrvuspVariance : undefined,
      specialtyPerformanceCfteAdjWrvuspVariance: current ? current.specialtyPerformanceCfteAdjWrvuspVariance : undefined
    } : {};

  const previousInfo =
    previous ?
      {
        previousBenchmarkPercentileRank: previous ? previous.benchmarkPercentileRank : 0,
        previousCommunityBenchmarkPercentileRank: previous ? previous.communityBenchmarkPercentileRank : 0,
        previousBenchmark25thPercentile: previous ? previous.benchmark25thPercentile : undefined,
        previousBenchmark50thPercentile: previous ? previous.benchmark50thPercentile : undefined,
        previousBenchmark65thPercentile: previous ? previous.benchmark65thPercentile : undefined,
        previousBenchmark75thPercentile: previous ? previous.benchmark75thPercentile : undefined,
        previousBenchmark90thPercentile: previous ? previous.benchmark90thPercentile : undefined,
        previousBenchmarkMean: previous ? previous.benchmarkMean : undefined,
        previousWrvus: previous ? previous.wRVUs : undefined,
        previousCfteAdjustedWRVUs: previous ? previous.cfteAdjustedWRVUs : undefined,
        previousCfte: previous ? previous.cfte : undefined,
        previousCharges: previous ? previous.charges : undefined,
        previousImputedReportedcFTE25thPercentile: previous ? previous.imputedReportedcFTE25thPercentile : undefined,
        previousImputedReportedcFTE50thPercentile: previous ? previous.imputedReportedcFTE50thPercentile : undefined,
        previousImputedReportedcFTE65thPercentile: previous ? previous.imputedReportedcFTE65thPercentile : undefined,
        previousImputedReportedcFTE75thPercentile: previous ? previous.imputedReportedcFTE75thPercentile : undefined,
        previousImputedReportedcFTEMean: previous ? previous.imputedReportedcFTEMean : undefined,
        previousImputedReportedcFTE90thPercentile: previous ? previous.imputedReportedcFTE90thPercentile : undefined
      } : {};
  return Object.assign(baseObject, nodeInfo, currentInfo, previousInfo);
}

export function getMergedProductivityTrendData(current: MonthProductivityData, previous: MonthProductivityData):
  MergedProductivityTrendEntry[] {
  const tempProviderTrend: any = {};
  let mergedProductivityData: MergedProductivityTrendEntry[] = [];

  if (current && current.monthProductivityData) {
    current.monthProductivityData.monthProductivities.forEach(productivity => {
      const dateInMonths = productivity.year * 12 + (+productivity.month);
      tempProviderTrend[dateInMonths] =
        getMergedProductivityTrendEntry(aMergedProductivityTrendEntryDefault(), productivity, undefined);
    });
  }

  if (previous && previous.monthProductivityData) {
    previous.monthProductivityData.monthProductivities.forEach(productivity => {
      const dateInMonths = productivity.year * 12 + (+productivity.month) + 12;
      let productivityEntry = tempProviderTrend[dateInMonths];
      const baseObject = productivityEntry ? productivityEntry : aMergedProductivityTrendEntryDefault();

      productivityEntry = getMergedProductivityTrendEntry(baseObject, undefined, productivity);
      tempProviderTrend[dateInMonths] = productivityEntry;
    });
  }

  Object.keys(tempProviderTrend).forEach(key => {
    const difference = tempProviderTrend[key].cfteAdjustedWRVUs - tempProviderTrend[key].previousCfteAdjustedWRVUs;
    mergedProductivityData.push({
      ...tempProviderTrend[key],
      difference
    });
  });

  mergedProductivityData = _.orderBy(mergedProductivityData, ['year', 'month'], ['asc', 'asc']);

  return mergedProductivityData;
}

export function getMergedProductivityTrendEntry(
  baseObject: MergedProductivityTrendEntry,
  current?: MonthProductivityEntry,
  previous?: MonthProductivityEntry
): MergedProductivityTrendEntry {

  const nodeInfo = +baseObject.year === 0 ? {
    // @ts-ignore
    month: current ? current.month : previous.month,
    // @ts-ignore
    year: current ? current.year : previous.year + 1,
  } : {};

  const currentInfo = current ?
    {
      benchmark25thPercentile: current ? current.benchmark25thPercentile : undefined,
      benchmark50thPercentile: current ? current.benchmark50thPercentile : undefined,
      benchmark65thPercentile: current ? current.benchmark65thPercentile : undefined,
      benchmark75thPercentile: current ? current.benchmark75thPercentile : undefined,
      benchmark90thPercentile: current ? current.benchmark90thPercentile : undefined,
      benchmarkMean: current ? current.benchmarkMean : undefined,
      wRVUs: current ? current.wRVUs : undefined,
      cfteAdjustedWRVUs: current ? current.cfteAdjustedWRVUs : undefined,
      cfte: current ? current.cfte : undefined,
      charges: current ? current.charges : undefined,
      variance25thPercentile: current ? current.variance25thPercentile : undefined,
      variance50thPercentile: current ? current.variance50thPercentile : undefined,
      variance65thPercentile: current ? current.variance65thPercentile : undefined,
      variance75thPercentile: current ? current.variance75thPercentile : undefined,
      variance90thPercentile: current ? current.variance90thPercentile : undefined,
      varianceMean: current ? current.varianceMean : undefined,
      communityBenchmark25thPercentile: current ? current.communityBenchmark25thPercentile : undefined,
      communityBenchmark50thPercentile: current ? current.communityBenchmark50thPercentile : undefined,
      communityBenchmark65thPercentile: current ? current.communityBenchmark65thPercentile : undefined,
      communityBenchmark75thPercentile: current ? current.communityBenchmark75thPercentile : undefined,
      communityBenchmark90thPercentile: current ? current.communityBenchmark90thPercentile : undefined,
      communityBenchmarkMean: current ? current.communityBenchmarkMean : undefined,
      communityVariance25thPercentile: current ? current.communityVariance25thPercentile : undefined,
      communityVariance50thPercentile: current ? current.communityVariance50thPercentile : undefined,
      communityVariance65thPercentile: current ? current.communityVariance65thPercentile : undefined,
      communityVariance75thPercentile: current ? current.communityVariance75thPercentile : undefined,
      communityVariance90thPercentile: current ? current.communityVariance90thPercentile : undefined,
      communityVarianceMean: current ? current.communityVarianceMean : undefined,
    } : {};

  const previousInfo =
    previous ?
      {
        previousBenchmark25thPercentile: previous ? previous.benchmark25thPercentile : undefined,
        previousBenchmark50thPercentile: previous ? previous.benchmark50thPercentile : undefined,
        previousBenchmark65thPercentile: previous ? previous.benchmark65thPercentile : undefined,
        previousBenchmark75thPercentile: previous ? previous.benchmark75thPercentile : undefined,
        previousBenchmark90thPercentile: previous ? previous.benchmark90thPercentile : undefined,
        previousBenchmarkMean: previous ? previous.benchmarkMean : undefined,
        previousCommunityBenchmark25thPercentile: previous ? previous.communityBenchmark25thPercentile : undefined,
        previousCommunityBenchmark50thPercentile: previous ? previous.communityBenchmark50thPercentile : undefined,
        previousCommunityBenchmark65thPercentile: previous ? previous.communityBenchmark65thPercentile : undefined,
        previousCommunityBenchmark75thPercentile: previous ? previous.communityBenchmark75thPercentile : undefined,
        previousCommunityBenchmark90thPercentile: previous ? previous.communityBenchmark90thPercentile : undefined,
        previousCommunityBenchmarkMean: previous ? previous.communityBenchmarkMean : undefined,
        previousWrvus: previous ? previous.wRVUs : undefined,
        previousCfteAdjustedWRVUs: previous ? previous.cfteAdjustedWRVUs : undefined,
        previousCfte: previous ? previous.cfte : undefined,
        previousCharges: previous ? previous.charges : undefined,
      } : {};
  return Object.assign(baseObject, nodeInfo, currentInfo, previousInfo);
}

export function getMergedNPVMultilevelSnapshotData(current: ProviderNewPatientVisitMultiLevelData,
                                                   previous: ProviderNewPatientVisitMultiLevelData):
  MergedProviderNewPatientVisitMultiLevelData {
  const tempNPVSnapshot: any = {};
  const mergedNewPatientVisitDataByProvider: MergedNewPatientVisitSnapshotEntry[] = [];
  const mergedNewPatientVisitDataBySpecialty: MergedNewPatientVisitSnapshotEntry[] = [];
  const mergedNewPatientVisitDataByDepartment: MergedNewPatientVisitSnapshotEntry[] = [];
  if (current && current.providerNpvSnapshotData) {
    current.providerNpvSnapshotData.forEach(newPatientVisitSnapshotEntry => {
      tempNPVSnapshot[newPatientVisitSnapshotEntry.providerNodePath] =
        getMergedMultilevelNewPatientEntry(LevelType.provider, newPatientVisitSnapshotEntry);
    });
  }
  if (current && current.specialtyNpvSnapshotData) {
    current.specialtyNpvSnapshotData.forEach(newPatientVisitSnapshotEntry => {
      tempNPVSnapshot[newPatientVisitSnapshotEntry.specialtyNodePath] =
        getMergedMultilevelNewPatientEntry(LevelType.specialty, newPatientVisitSnapshotEntry);
    });
  }

  if (current && current.departmentNpvSnapshotData) {
    current.departmentNpvSnapshotData.forEach(newPatientVisitSnapshotEntry => {
      tempNPVSnapshot[newPatientVisitSnapshotEntry.departmentNodePath] =
        getMergedMultilevelNewPatientEntry(LevelType.department, newPatientVisitSnapshotEntry);
    });
  }
  if (!_.isEqual({}, tempNPVSnapshot)) {
    if (previous && previous.providerNpvSnapshotData) {
      previous.providerNpvSnapshotData.forEach(newPatientVisitSnapshotEntry => {
        const newPatientVisitSnapshot = tempNPVSnapshot[newPatientVisitSnapshotEntry.providerNodePath];
        if (newPatientVisitSnapshot) {
          newPatientVisitSnapshot.previous = newPatientVisitSnapshotEntry;
          tempNPVSnapshot[newPatientVisitSnapshotEntry.providerNodePath] = newPatientVisitSnapshot;
        }
      });
    }
    if (previous && previous.specialtyNpvSnapshotData) {
      previous.specialtyNpvSnapshotData.forEach(newPatientVisitSnapshotEntry => {
        const newPatientVisitSnapshot = tempNPVSnapshot[newPatientVisitSnapshotEntry.specialtyNodePath];
        if (newPatientVisitSnapshot) {
          newPatientVisitSnapshot.previous = newPatientVisitSnapshotEntry;
          tempNPVSnapshot[newPatientVisitSnapshotEntry.specialtyNodePath] = newPatientVisitSnapshot;
        }
      });
    }
    if (previous && previous.departmentNpvSnapshotData) {
      previous.departmentNpvSnapshotData.forEach(newPatientVisitSnapshotEntry => {
        const newPatientVisitSnapshot = tempNPVSnapshot[newPatientVisitSnapshotEntry.departmentNodePath];
        if (newPatientVisitSnapshot) {
          newPatientVisitSnapshot.previous = newPatientVisitSnapshotEntry;
          tempNPVSnapshot[newPatientVisitSnapshotEntry.departmentNodePath] = newPatientVisitSnapshot;
        }
      });
    }
  }
  Object.keys(tempNPVSnapshot).forEach(key => {
    switch (key.split('\\').length) {
      case 3:
        mergedNewPatientVisitDataByDepartment.push(tempNPVSnapshot[key]);
        break;
      case 4:
        mergedNewPatientVisitDataBySpecialty.push(tempNPVSnapshot[key]);
        break;
      case 5:
        mergedNewPatientVisitDataByProvider.push(tempNPVSnapshot[key]);
        break;
    }
  });
  return {
    providerNpvSnapshotData: mergedNewPatientVisitDataByProvider,
    specialtyNpvSnapshotData: mergedNewPatientVisitDataBySpecialty,
    departmentNpvSnapshotData: mergedNewPatientVisitDataByDepartment
  };
}

// TODO: Can the following functions be abstracted?
export function getMergedMultilevelNewPatientEntry(
  level: LevelType,
  entry: NewPatientVisitSnapshotMultiLevel)
  : MergedNewPatientVisitSnapshotEntry {
  let nodeInfo = {
    nodeId: -1, nodeName: '', nodePath: '',
    departmentNodeId: -1, departmentNodeName: '', departmentNodePath: '',
    specialtyNodeId: -1, specialtyNodeName: '', specialtyNodePath: '',
    providerNodeId: -1, providerNodeName: '', providerNodePath: '',
  };
  switch (level) {
    case LevelType.department:
      nodeInfo = {
        ...nodeInfo,
        nodeId: entry.departmentNodeId || -1,
        nodeName: entry.departmentNodeName || '',
        nodePath: entry.departmentNodePath || '',
        departmentNodeId: entry.departmentNodeId || -1,
        departmentNodeName: entry.departmentNodeName || '',
        departmentNodePath: entry.departmentNodePath || '',
      };
      break;
    case LevelType.specialty:
      nodeInfo = {
        ...nodeInfo,
        nodeId: entry.specialtyNodeId || -1,
        nodeName: entry.specialtyNodeName || '',
        nodePath: entry.specialtyNodePath || '',
        departmentNodeId: entry.departmentNodeId || -1,
        departmentNodeName: entry.departmentNodeName || '',
        departmentNodePath: entry.departmentNodePath || '',
        specialtyNodeId: entry.specialtyNodeId || -1,
        specialtyNodeName: entry.specialtyNodeName || '',
        specialtyNodePath: entry.specialtyNodePath || '',
      };
      break;
    case LevelType.provider:
      nodeInfo = {
        ...nodeInfo,
        nodeId: entry.providerNodeId || -1,
        nodeName: entry.providerNodeName || '',
        nodePath: entry.providerNodePath || '',
        departmentNodeId: entry.departmentNodeId || -1,
        departmentNodeName: entry.departmentNodeName || '',
        departmentNodePath: entry.departmentNodePath || '',
        specialtyNodeId: entry.specialtyNodeId || -1,
        specialtyNodeName: entry.specialtyNodeName || '',
        specialtyNodePath: entry.specialtyNodePath || '',
        providerNodeId: entry.providerNodeId || -1,
        providerNodeName: entry.providerNodeName || '',
        providerNodePath: entry.providerNodePath || '',
      };
      break;
  }
  return {
    ...entry,
    nodeId: nodeInfo.nodeId > -1 ? nodeInfo.nodeId : nodeInfo.providerNodeId,
    nodeName: nodeInfo.nodeName !== '' ? nodeInfo.nodeName : nodeInfo.providerNodeName,
    nodePath: nodeInfo.nodePath !== '' ? nodeInfo.nodePath : nodeInfo.providerNodePath,
    departmentNodeId: nodeInfo.departmentNodeId,
    departmentNodeName: nodeInfo.departmentNodeName,
    departmentNodePath: nodeInfo.departmentNodePath,
    specialtyNodeId: nodeInfo.specialtyNodeId,
    specialtyNodeName: nodeInfo.specialtyNodeName,
    specialtyNodePath: nodeInfo.specialtyNodePath,
    providerNodeId: nodeInfo.providerNodeId,
    providerNodeName: nodeInfo.providerNodeName,
    providerNodePath: nodeInfo.providerNodePath,
    isHidden: false,
    previous: entry
  };
}
