import {LegendColor, LegendStyle} from '../../shared/enums';
import {MetricType} from '../../shared/metric-types';
import {Legend} from '../../shared/models';
import {DenialsMultiLevelEntity, DenialsEntity} from '../denials-models';

export const chargesTerminallyDeniedLegend: Legend = {
  text: 'Charges Terminally Denied',
  color: LegendColor.RED,
  metric: MetricType.Denials,
  style: LegendStyle.SQUARE,
  showPercentileControl: false
};
export const deniedCptsPaidLegend: Legend = {
  text: '% of Denied CPTs Paid',
  color: LegendColor.BLUE,
  metric: MetricType.Denials,
  style: LegendStyle.SQUARE,
  showPercentileControl: false
};
export const terminalDenialRateLegend: Legend = {
  text: 'Terminal Denial Rate',
  color: LegendColor.PURPLE,
  metric: MetricType.Denials,
  style: LegendStyle.SQUARE,
  showPercentileControl: false
};
export const chargesLegendForDenials: Legend = {
  text: 'Total Charges',
  color: LegendColor.ORANGE,
  metric: MetricType.Denials,
  style: LegendStyle.SQUARE,
  showPercentileControl: false
};

export function doesDenialsDataHaveValidBenchmarkValue(denialData: DenialsEntity[] |
  DenialsMultiLevelEntity[] = []): boolean {
  // @ts-ignore
  return !!denialData.find(x => x.denialRatePer25th || x.denialRatePer50th || x.denialRatePer75th);
}
