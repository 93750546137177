<app-denials-chart
  [denialsData]="denialsData"
  [activeVarianceToggle]="denialsVarianceToggle"
  [benchmarkPercentile]="benchmarkPercentile"
  [sortingCriteria]="varianceToggleSortingCriterion"
  [showProgressBar]="showProgressBar"
  [variables]="variables"
  [barSelectionCallback]="whenPayerRowSelected"
  [showVariableMenu]="true"
></app-denials-chart>
<app-denials-metric-data-table id="denials-table"
                               [tableTitle]="tableTitle"
                               [showProgressBar]="showProgressBar"
                               [columns]="displayedColumns"
                               [originalColumns]="columns"
                               [receivedData]="denialsData"
                               [defaultSortColumn]="defaultSortColumn"
                               [currentPage]="currentPage"
                               [sortDirection]="sortDirection"
                               [nodePathName]="nodePathName"
                               [whenSortChanged]="whenSortChanged"
                               [updateSortingCriteria]="updateSortingCriteria"
                               [reducerAction]="reducerAction"
                               [rowSelectionCallback]="whenPayerRowSelected"
                               [addRemoveOption]="true"
                               [levelCssClass]="'by-payer'"
                               sortDirection="desc"
>
</app-denials-metric-data-table>
