import {AfterViewChecked, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MultilevelTabCollections} from '../../shared/enums';
import {SortingCriterion} from '../../shared/models';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {BaseColumn, FilterCriteria, IAppState} from '../../store/IAppState';
import {ApiServiceImpl, ApiServiceToken} from '../../services/api.service';
import {FilterSelectorService, FilterSelectorServiceToken} from '../../services/filter-selector.service';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {DisplayField, Variable} from '../../variable-container/variable-container.component';
import {
  AppAction,
  chargesTerminallyDeniedChangedTo,
  denialsChargesDisplayedChangedTo,
  denialsChosenTabChangedTo,
  deniedCPTsPaidChangedTo,
  multiLevelByNodePathDenialsColumnsChangedTo,
  terminalDenialRateChangedTo
} from '../../store/actions';
import {
  columnsForMultiLevelDenials,
  columnsForMultiLevelDenialsDepartment,
  columnsForMultiLevelDenialsProvider,
  columnsForMultiLevelDenialsSpecialty,
  DataTableColumns,
  getTerminalDenialRateColumns,
  viewCPTsDeniedColumn
} from '../../shared/data-table-columns';
import {canHide} from '../../shared/helpers';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {compatibleTabEquivalentOf} from '../../shared/multilevel-helpers';
import {
  DEPARTMENT_TAB_COLLECTION,
  PAYER_TAB,
  PROVIDER_TAB_COLLECTION,
  SPECIALTY_TAB_COLLECTION
} from '../../shared/constants';
import {TabNavigationElement} from '../../tab-navigation/tab-helper';
import {DenialsMultiLevelData, DenialsMultiLevelEntity} from '../denials-models';
import {updateDenialsBenchmarkDepartmentColumns} from '../denials-helpers';

@Component({
  selector: 'app-denials-multilevel',
  templateUrl: './denials-multilevel.component.html',
  styleUrls: ['./denials-multilevel.component.scss']
})

export class DenialsMultilevelComponent implements OnInit, OnDestroy, AfterViewChecked {

  @select(['data', 'denialsByMultiLevelData'])
  private readonly denialsByMultilevelData$: Observable<DenialsMultiLevelData>;

  @select(['display', 'displayedMultiLevelDenialsColumns'])
  private readonly displayedDenialsColumns$: Observable<BaseColumn[]>;

  @select(['display', 'denialsChosenTab'])
  private readonly denialsChosenTab$: Observable<MultilevelTabCollections>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['display', 'varianceToggle', 'denialsVariance'])
  private readonly denialsVarianceToggle$: Observable<boolean>;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @Input() activeVarianceToggle = false;

  denialsChosenTabChangedTo = denialsChosenTabChangedTo;

  tabs: TabNavigationElement[] = [
    _.cloneDeep(DEPARTMENT_TAB_COLLECTION),
    _.cloneDeep(SPECIALTY_TAB_COLLECTION),
    _.cloneDeep(PROVIDER_TAB_COLLECTION),
    _.cloneDeep(PAYER_TAB)
  ];
  variables: Variable[] = [
    {
      name: 'Total Charges',
      display: false,
      reducerField: DisplayField.DenialsCharge,
      dispatchAction(display: boolean): AppAction {
        return denialsChargesDisplayedChangedTo(display);
      }
    },
    {
      name: 'Charges Terminally Denied',
      display: false,
      reducerField: DisplayField.ChargesTerminallyDenied,
      dispatchAction(display: boolean): AppAction {
        return chargesTerminallyDeniedChangedTo(display);
      }
    },
    {
      name: '% of Denied CPTs Paid',
      display: false,
      reducerField: DisplayField.DeniedCPTSPaid,
      dispatchAction(display: boolean): AppAction {
        return deniedCPTsPaidChangedTo(display);
      }
    },
    {
      name: 'Terminal Denial Rate',
      display: false,
      reducerField: DisplayField.TerminalDenialRate,
      dispatchAction(display: boolean): AppAction {
        return terminalDenialRateChangedTo(display);
      }
    }
  ];
  chosenTab: MultilevelTabCollections;
  isDepartment: boolean;
  departmentDenialsData: DenialsMultiLevelEntity[] = [];
  specialtyDenialsData: DenialsMultiLevelEntity[] = [];
  providerDenialsData: DenialsMultiLevelEntity[] = [];
  departmentColumns = _.cloneDeep(columnsForMultiLevelDenialsDepartment);
  specialtyColumns = _.cloneDeep(columnsForMultiLevelDenialsSpecialty);
  providerColumns = _.cloneDeep(columnsForMultiLevelDenialsProvider);
  displayedDepartmentColumns: DataTableColumns[] = [];
  displayedSpecialtyColumns: DataTableColumns[] = [];
  displayedProviderColumns: DataTableColumns[] = [];
  displayedColumns: any = columnsForMultiLevelDenials.slice();
  tabSubscription: Subscription;
  filterSubscription: Subscription;
  dataSubscription: Subscription;
  columnSubscription: Subscription;
  benchmarkPercentile: BenchmarkPercentile;
  nodePath: string;
  denialsVarianceToggle = false;
  canHideCPTsDenialsModal = false;
  varianceToggleSortingCriterion: SortingCriterion;
  reducerAction: (denialsMultiLevelColumns: BaseColumn[]) => AppAction = multiLevelByNodePathDenialsColumnsChangedTo;

  constructor(private cdr: ChangeDetectorRef,
              private ngRedux: NgRedux<IAppState>,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl,
              @Inject(FilterSelectorServiceToken)
              private filterService: FilterSelectorService) {
    this.chosenTab = MultilevelTabCollections.BY_DEPARTMENT;
    this.filterSubscription = this.filterService.getFilters().subscribe((filter: FilterCriteria) => {
      if (!!filter.nodePath) {
        this.apiService.getDenialsMultilevelData(filter);
        this.apiService.getDenialsData(filter);
      }
    });
  }

  ngOnInit(): void {
    if (!this.canHideCPTsDenialsModal) {
      this.departmentColumns.push(viewCPTsDeniedColumn);
      this.specialtyColumns.push(viewCPTsDeniedColumn);
      this.providerColumns.push(viewCPTsDeniedColumn);
    }

    this.dataSubscription = combineLatest([
      this.denialsByMultilevelData$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.nodePath$,
      this.benchmarkPercentile$,
      this.denialsVarianceToggle$
    ])
      .subscribe(([denialsMultilevelData, nodePath, benchmarkPercentile, denialsVarianceToggle]:
                    [DenialsMultiLevelData, string, BenchmarkPercentile, boolean]) => {
        this.nodePath = nodePath;
        this.benchmarkPercentile = benchmarkPercentile;
        this.denialsVarianceToggle = denialsVarianceToggle;
        const {featureToggleSettings, userProfile} = this.ngRedux.getState().data;
        this.canHideCPTsDenialsModal = canHide(FeatureToggleEntries.DENIALS_CPTS_DENIED_MODAL, featureToggleSettings, userProfile);

        this.departmentColumns = getTerminalDenialRateColumns(this.departmentColumns);

        this.specialtyColumns = getTerminalDenialRateColumns(this.specialtyColumns);

        this.providerColumns = getTerminalDenialRateColumns(this.providerColumns);

        this.departmentColumns = this.departmentColumns.map(col => updateDenialsBenchmarkDepartmentColumns(col, benchmarkPercentile));
        this.specialtyColumns = this.specialtyColumns.map(col => updateDenialsBenchmarkDepartmentColumns(col, benchmarkPercentile));
        this.providerColumns = this.providerColumns.map(col => updateDenialsBenchmarkDepartmentColumns(col, benchmarkPercentile));
        this.updateMultilevelColumnFilters();

        this.departmentDenialsData = denialsMultilevelData.departmentDenials;
        this.specialtyDenialsData = denialsMultilevelData.specialtyDenials;
        this.providerDenialsData = denialsMultilevelData.providerDenials;
      });

    this.tabSubscription = this.denialsChosenTab$.subscribe((tab: MultilevelTabCollections) => {
      this.chosenTab = compatibleTabEquivalentOf(tab);
      switch (this.chosenTab) {
        case MultilevelTabCollections.BY_PROVIDER:
          this.ngRedux.dispatch(this.reducerAction(this.providerColumns));
          break;
        case MultilevelTabCollections.BY_SPECIALTY:
          this.ngRedux.dispatch(this.reducerAction(this.specialtyColumns));
          break;
        case MultilevelTabCollections.BY_DEPARTMENT:
          this.ngRedux.dispatch(this.reducerAction(this.departmentColumns));
          break;
      }
    });

    this.columnSubscription = this.displayedDenialsColumns$
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(displayedColumns => {
        this.displayedColumns = displayedColumns;
        this.updateMultilevelColumnFilters();
      });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
    this.cdr.detach();
    this.columnSubscription.unsubscribe();
  }

  updateMultilevelColumnFilters() {
    this.displayedDepartmentColumns = this.updateColumnFilter(this.displayedColumns, this.departmentColumns);
    this.displayedSpecialtyColumns = this.updateColumnFilter(this.displayedColumns, this.specialtyColumns);
    this.displayedProviderColumns = this.updateColumnFilter(this.displayedColumns, this.providerColumns);
  }

  updateColumnFilter(columns: BaseColumn[], tableColumns: DataTableColumns[]) {
    const columnDefs: string[] = columns.map(
        col => updateDenialsBenchmarkDepartmentColumns(<DataTableColumns> col, this.benchmarkPercentile).columnDef);
    return tableColumns
      .filter(col => col.columnDef === 'viewCPTsDenied' || col.primaryColumn || columnDefs.includes(col.columnDef))
      .slice();
  }
}
