<div class="page">
  <div class="upper-division">
    <div id="provider-denials-header">
      <div class="provider-header-wrapper">
        <span id="provider-denials-inner-header">{{row.departmentNodeName}} / {{row.specialtyNodeName}} / {{row.providerNodeName}} Denial Analysis</span>
        <div id="export-button-wrapper" >
          <button [ngClass] = "'exportButton'"
                  id="exportBtn"
                  mat-raised-button
                  (click)="toggleExportDropdown()"
                  (blur)="closeExportDropdown()"
                  tabindex="0">Export Data
          </button>
          <img class="close-icon" src="assets/Close_grey.svg" (click)="close()"/>
          <div *ngIf="showExportDropdown" class="other-dropdown-content">
            <a (mousedown)="openExcelFile()" class="exportExcel">Export to Excel</a>
            <a (mousedown)="openCsvFile()" class="exportCSV">Export to CSV</a>
          </div>
        </div>
    </div>
    <div id="denials-summary-table">
      <div class="denials-summary-row"
      *ngFor="let summary of summaryHeaders">
        <div class="summary-header"  >{{summary.header}}
          <i class="fa fa-info-circle" (mouseenter)="toggleTotalChargesInfoMessage()"
             (mouseleave)="toggleTotalChargesInfoMessage()" id="total-charges-information-trigger" *ngIf="summary.header=='Total Charges:'"></i>
          <div id="total-charges-information" *ngIf="summary.header=='Total Charges:' && showTotalChargesInfoMessage">
            These are the total charges for everything that was billed.
          </div>
          <div class="summary-value">{{summaries[summary.value]}}</div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div id="denials-tab-selection">
      <span class="denial-options">
        <a id="byDenialRate" [ngClass]="{active: chosen === 0}" (click)="chooseTab(DenialsTabs.DENIAL_RATE)">Denial Rate&nbsp;&nbsp;&nbsp;</a>
        <a id="byTop10DeniedReasons" [ngClass]="{active: chosen === 1}" *ngIf="!canHideDenialReasons"
           (click)="chooseTab(DenialsTabs.TOP_TEN_DENIED_REASONS)">Top 10 Denial Reasons&nbsp;&nbsp;&nbsp;</a>
        <a id="byTop10DeniedCPTS" [ngClass]="{active: chosen === 2}" (click)="chooseTab(DenialsTabs.TOP_TEN_CPTS)">Top 10 CPTs Denied&nbsp;&nbsp;&nbsp;</a>
      </span>
  </div>
  <div [ngSwitch]="chosen">
    <div *ngSwitchCase="DenialsTabs.DENIAL_RATE">
      <app-data-table
        id="denials-rate-data-table"
        tableTitle=""
        [receivedData]="currentDisplayedDenialsData"
        [displayedColumns]="denialsColumns"
        [originalColumns]="denialsColumns"
        [tableCssClass]="'denials-rate-table'"
        [showPayerOptions]="false"
        [addRemoveOption]="false"
        [scrollableColumns]=""
        [showProgressBar]="false"
        [canHideScrollable]="true"
        [multiLevel]="true"
        [chosen]="chosen"
        [initialPageSize]=25
        [defaultSortColumn]="'denialRate'"
        [sortDirection]="denialRateSortDirection"
        [mandatoryOpaqueSortingArrow]="true"
        [sortingDataAccessor]="sortingDataAccessor"
        [whenSortChanged]="whenDenialRateSortChanged"
        [updateSortingCriteria]="updateDenialRateSortingCriteria"
      ></app-data-table>
    </div>
    <div *ngSwitchCase="DenialsTabs.TOP_TEN_DENIED_REASONS">
      <ng-container *ngIf="!canHideDenialReasons" id="byTop10DeniedReasonsContent">
        <nav class="tab-container">
          <a [ngClass]="denialReasonTabSelected === DenialReasonTab.BY_DENIAL_RATE ? 'reason-tab-active' : 'reason-tab-inactive'"
             (click)="chooseReasonTab(DenialReasonTab.BY_DENIAL_RATE)"
            id="reason-tab-denial-rate">by denial rate</a>
          <a [ngClass]="denialReasonTabSelected === DenialReasonTab.BY_CPT_CODES_DENIED ? 'reason-tab-active' : 'reason-tab-inactive'"
             (click)="chooseReasonTab(DenialReasonTab.BY_CPT_CODES_DENIED)"
            id="reason-tab-codes-denied">by # of cpt codes denied</a>
          <a [ngClass]="denialReasonTabSelected === DenialReasonTab.BY_CHARGES ? 'reason-tab-active' : 'reason-tab-inactive'"
             (click)="chooseReasonTab(DenialReasonTab.BY_CHARGES)"
            id="reason-tab-by-charges">by charges</a>
        </nav>
        <app-data-table
          id="denials-reasons-data-table"
          tableTitle=""
          [receivedData]="currentDenialsReasonsData"
          [displayedColumns]="reasonsColumns"
          [originalColumns]="reasonsColumns"
          [tableCssClass]="'denials-reasons-table'"
          [mandatoryOpaqueSortingArrow]="true"
          [updateSortingCriteria]="updateReasonsSortingCriteria"
          [whenSortChanged]="whenReasonsSortChanged"
          [showPayerOptions]="false"
          [addRemoveOption]="false"
          [scrollableColumns]=""
          [showProgressBar]="false"
          [sortingDataAccessor]="sortingDataAccessor"
          [canHideScrollable]="true"
          [multiLevel]="true"
          [chosen]="chosen"
          [initialPageSize]=25
          [defaultSortColumn]="reasonsSortColumn"
          [sortDirection]="reasonsSortDirection"
        ></app-data-table>
      </ng-container>
    </div>
    <div *ngSwitchCase="DenialsTabs.TOP_TEN_CPTS">
      <nav class="tab-container">
        <a [ngClass]="topCPTsTabSelected === TopCPTsTab.BY_DENIAL_RATE ? 'cpt-tab-active' : 'cpt-tab-inactive'"
           (click)="chooseTopCTP_Tab(TopCPTsTab.BY_DENIAL_RATE)"
           id="cpt-tab-denial-rate">by denial rate</a>
        <a [ngClass]="topCPTsTabSelected === TopCPTsTab.BY_CPT_CODES_DENIED ? 'cpt-tab-active' : 'cpt-tab-inactive'"
           (click)="chooseTopCTP_Tab(TopCPTsTab.BY_CPT_CODES_DENIED)"
           id="cpt-tab-codes-denied">by # of cpt codes denied</a>
        <a [ngClass]="topCPTsTabSelected === TopCPTsTab.BY_CHARGES ? 'cpt-tab-active' : 'cpt-tab-inactive'"
           (click)="chooseTopCTP_Tab(TopCPTsTab.BY_CHARGES)"
           id="cpt-tab-by-charges">by charges</a>
      </nav>
      <app-data-table
        id="cpts-denied-data-table"
        tableTitle=""
        [receivedData]="currentDenialsCptsData"
        [displayedColumns]="cptsDeniedColumn"
        [originalColumns]="cptsDeniedColumn"
        [tableCssClass]="'cpts-denied-table'"
        [mandatoryOpaqueSortingArrow]="true"
        [updateSortingCriteria]="updateTopCptsSortingCriteria"
        [whenSortChanged]="whenTopCptsSortChanged"
        [showPayerOptions]="false"
        [addRemoveOption]="false"
        [scrollableColumns]=""
        [sortingDataAccessor]="sortingDataAccessor"
        [showProgressBar]="false"
        [canHideScrollable]="true"
        [defaultSortColumn]="topCptsDeniedSortColumn"
        [sortDirection]="topCptsSortDirection"
        [multiLevel]="true"
        [chosen]="chosen"
        [initialPageSize]=25
      ></app-data-table>
    </div>
  </div>
  <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
</div>
