import {Component, OnInit, ViewChild} from '@angular/core';
import {PayerCheckboxOption, PayerCollections, PayerDropdownOption, PayerOptions} from '../collections/Collection';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, IAppState} from '../store/IAppState';
import * as _ from 'lodash';
import { MatSelect } from '@angular/material/select';
import {ColumnType} from '../shared/enums';
import {payerByLevelCollectionsPayerColumnsChangedTo} from '../store/actions';
import {aPayerCollectionsDefault} from '../shared/test/helper-functions.spec';
import {Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, take} from 'rxjs/operators';
import {removeWhiteSpace} from '../shared/helpers';

@Component({
  selector: 'app-payer-selector',
  templateUrl: './payer-selector.component.html',
  styleUrls: ['./payer-selector.component.scss']
})
export class PayerSelectorComponent implements OnInit {

  @ViewChild('selector', {static: true}) selector: MatSelect;

  @select(['data', 'payerCollectionsData', 'payerMatchedCollections'])
  private readonly $payerMatchedCollections: Observable<PayerCollections[]>;

  showPayerCheckboxSelection: boolean;
  selectedOption: PayerDropdownOption;
  payerSearchText = '';
  payersInStore: PayerCollections[];
  selectedPayers: string[] = [];
  allPayers: PayerCheckboxOption[] = [];
  listedPayers: PayerCheckboxOption[] = [];

  defaultSelection: PayerOptions = PayerOptions.Top5ByNCR;
  options: PayerDropdownOption[] = [
    {
      label: 'Top 5 payers by charges',
      value: PayerOptions.Top5ByCharges
    },
    {
      label: 'Top 5 payers by net collection rate',
      value: PayerOptions.Top5ByNCR
    },
    {
      label: 'Select Payers',
      value: PayerOptions.SelectPayers
    }
  ];

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit() {
    this.selectedOption = this.options.find(x => x.value === this.defaultSelection) || this.options[0];

    this.$payerMatchedCollections
      .pipe(distinctUntilChanged((data1, data2) => _.isEqual(data1, data2)))
      .subscribe(payerCollections => {
        this.payersInStore = payerCollections;
        this.allPayers = this.listedPayers = this.payersInStore.map((x) => {
          return {
            payerName: x.payerCategoryDescription,
            selected: true
          };
        });

        this.updatePayers(this.selectedOption);
      });
  }

  updatePayers(option: PayerDropdownOption) {
    this.selectedOption = option;
    this.showPayerCheckboxSelection = false;

    if (!_.isEqual(this.payersInStore, aPayerCollectionsDefault())) {
      switch (option.value) {
        case PayerOptions.Top5ByCharges:
          this.getTop5PayersBy('chargeAmount');
          this.updateAndDispatchPayerColumns();
          break;
        case PayerOptions.Top5ByNCR:
          this.getTop5PayersBy('netCollectionRate');
          this.updateAndDispatchPayerColumns();
          break;
        case PayerOptions.SelectPayers:
          const subscription: Subscription = this.selector.openedChange
            .pipe(take(1))
            .subscribe(() => this.selector.open(), () => {}, () => subscription.unsubscribe());
          this.showPayerCheckboxSelection = true;
          break;
      }
    }
  }

  getTop5PayersBy(field: string): void {
    this.selectedPayers = _.orderBy(this.payersInStore, [field], ['desc'])
      .slice(0, 5)
      .map(x => x.payerCategoryDescription);

    this.allPayers.forEach(payer => payer.selected = this.selectedPayers.includes(payer.payerName));
  }

  applyPayerSelection() {
    this.selectedPayers = this.allPayers
      .filter(payer => payer.selected)
      .map(payer => payer.payerName);

    this.updateAndDispatchPayerColumns();
    this.selector.close();
    this.payerSearchText = '';
    this.listedPayers = this.allPayers;
  }

  cancelSelection() {
    this.showPayerCheckboxSelection = false;
    this.payerSearchText = '';
    this.listedPayers = this.allPayers;
  }

  updateAndDispatchPayerColumns() {
    const payerColumns: BaseColumn[] = this.selectedPayers.map((payer: string) => {
      return {header: payer, columnDef: removeWhiteSpace(payer), columnType: ColumnType.PAYER};
    });
    this.ngRedux.dispatch(payerByLevelCollectionsPayerColumnsChangedTo(payerColumns));
  }

  onPayerSearchTextChanged(event: any): void {
    this.payerSearchText = event.target.value.toLowerCase();
    if (this.payerSearchText.length > 1) {
      this.listedPayers = this.allPayers.filter(payer => payer.payerName.toLowerCase().includes(this.payerSearchText));
    } else {
      this.listedPayers = this.allPayers;
    }
  }
}
