import { Injectable, InjectionToken } from '@angular/core';
import { FilterCriteria } from '../store/IAppState';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const FilterSelectorServiceToken = new InjectionToken<FilterSelectorService>('Filter Selector Service');

export interface FilterSelectorService {
  getFilters(): Observable<FilterCriteria>;
  getMemberKeys(): Observable<number>;
}

@Injectable()
export class ValidFilterSelectorService implements FilterSelectorService {
  @select(['filters'])
  private readonly filter$: Observable<FilterCriteria>;

  getFilters(): Observable<FilterCriteria> {
    return this.filter$.pipe(filter((filters: FilterCriteria) => filters.memberKey > 0));
  }

  getMemberKeys(): Observable<number> {
    return this.getFilters().pipe(map((filters: FilterCriteria) => filters.memberKey));
  }
}
