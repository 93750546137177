<div id="location-list-wrapper">
  <span id="header">Location</span>
  <span id="search-wrapper"><input type="text" placeholder="Search..." id="search-for"
                                   (input)="onSearchTextChanged()" [(ngModel)]="searchText">
  <i class="fa fa-search"></i></span>
  <mat-radio-group id="location-list">
    <app-loader *ngIf="showLoadingIndicator"></app-loader>
    <mat-radio-button [checked]="allSelected" [class.active]="allSelected" class="all-option"
                      [value]="DEFAULT_MEMBER_LOCATION.memberLocationName"
                      (click)="selectAllLocations()">{{DEFAULT_MEMBER_LOCATION.memberLocationName}}
    </mat-radio-button>
    <div id="location-options">
      <mat-radio-button *ngFor="let location of displayedLocations" [checked]="!allSelected && location.item.selected"
                        [class.active]="!allSelected && location.item.selected" [value]="location.item.displayText"
                        class="option" (click)="whenLocationClicked(location)">{{location.item.displayText}}
      </mat-radio-button>
    </div>
  </mat-radio-group>
</div>
