<div id="billing-area-list-wrapper">
  <span id="header">Billing Area</span>
  <div id="note">Note: Billing area is only applicable to Collections and Denials.</div>
  <span id="search-wrapper"><input type="text" placeholder="Search..." id="search-for" (input)="onSearchTextChanged()"
               [(ngModel)]="searchText"><i class="fa fa-search"></i></span>
  <mat-radio-group id="billing-area-list">
    <app-loader *ngIf="showLoadingIndicator"></app-loader>
    <mat-radio-button [checked]="allSelected" [class.active]="allSelected" class="all-option"
                      [value]="DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaDescription"
                      (click)="selectAllBillingAreas()">Select {{DEFAULT_MEMBER_BILLING_AREA.memberBillingAreaDescription}}
    </mat-radio-button>
    <mat-radio-button *ngFor="let billingArea of displayedBillingAreas" [checked]="!allSelected && billingArea.item.selected"
                      [class.active]="!allSelected && billingArea.item.selected" [value]="billingArea.item.displayText"
                      (click)="whenBillingAreaClicked(billingArea)" class="billingArea-option">{{billingArea.item.displayText}}
    </mat-radio-button>
  </mat-radio-group>
</div>
