import {Injectable, InjectionToken} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ClinicalSummaryRange} from '../../ClinicalSummary';
import {RelatableSelectableItem} from '../../../filter-banner/filter-banner-helper';
import {CptGroup} from '../ProcedureSummaryModels';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment.local';

export const ProcedureSummaryServiceToken =
  new InjectionToken<HttpProcedureSummaryService>('Procedure Summary Service');

export interface ProcedureSummaryService {
  saveCptGrouping(cptGroup: CptGroup): Observable<CptGroup>;
  getCptGroupings(memberKey: number): Observable<CptGroup[]>;
  deleteCptGroup(cptGroup: CptGroup): Observable<number>;
  updateCptGroup(cptGroup: CptGroup, newName: string, newDefault: boolean, changedEntries: boolean): Observable<CptGroup>;
}

@Injectable({
  providedIn: 'root'
})
export class HttpProcedureSummaryService implements ProcedureSummaryService {

  constructor(private httpClient: HttpClient) { }

  saveCptGrouping(cptGroup: CptGroup): Observable<CptGroup> {
    return this.httpClient.post<CptGroup>(`${environment.api}/api/saveCptGroup`, cptGroup);
  }

  getCptGroupings(memberKey: number): Observable<CptGroup[]> {
    return this.httpClient.get<CptGroup[]>(`${environment.api}/api/cptGroups?memberKey=${memberKey}`);
  }

  deleteCptGroup(cptGroup: CptGroup): Observable<number> {
    return this.httpClient.post<number>(`${environment.api}/api/deleteCptGroup`, cptGroup);
  }

  updateCptGroup(cptGroup: CptGroup, newName: string, newDefault: boolean, changedEntries: boolean): Observable<CptGroup> {
    const url = `${environment.api}/api/updateCptGroup`;
    return this.httpClient.post<CptGroup>(
      url,
      cptGroup,
      { params: new HttpParams()
          .set('newName', newName)
          .set('newDefault', newDefault.toString())
          .set('changedEntries', changedEntries.toString())
      },
    );
  }
}
