import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {XAxisOptions, YAxisOptions} from 'highcharts/highstock';
import {Options} from 'highcharts/highstock';
import {Chart} from 'angular-highcharts';
import {StyledChart} from '../shared/highcharts-helpers';
import {SeriesOptionsType} from 'highcharts';
import {Legend} from '../shared/models';

// @ts-ignore
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {

  @Input() legends: Legend[];
  @Input() title: any;
  @Input() series: SeriesOptionsType[];
  @Input() legendOptions: any;
  @Input() tooltipOptions: any;
  @Input() chartOptions: any;
  @Input() xAxisOptions: XAxisOptions[] | XAxisOptions;
  @Input() yAxisOptions: YAxisOptions[] | YAxisOptions;
  @Input() isScrolled: boolean;
  @Input() showScrollFull: boolean;
  @Input() showLegend: boolean;
  @Input() fromPdf: boolean;
  @Input() hasBenchmarks: boolean;

  @ViewChild('chartTarget', {static: true})
  chartTarget: ElementRef;
  options: Options;
  chartObject: Chart;

  constructor() {
  }

  ngOnChanges(): void {
    this.options = {
      title: this.title,
      series: this.series,
      legend: this.legendOptions,
      tooltip: this.tooltipOptions,
      chart: this.chartOptions,
      credits: {
        enabled: false,
      },
      xAxis: this.xAxisOptions,
      yAxis: this.yAxisOptions,
      scrollbar: {
        // @ts-ignore
        enabled: this.isScrolled,
        showFull: this.showScrollFull,
      },
    };
    this.chartObject = new StyledChart(this.options);
  }
}
