import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ListItemExpansion, PayerCategoryLevel, PayerFilterCriteria, PayerObject} from '../../shared/models';
import {getOppositeNodeExpansionState, isPayerTypeAGranularType} from '../../shared/helpers';
import {DEFAULT_PAYER_FILTER_CRITERIA, DEFAULT_PAYER_SELECTION} from '../../store/DefaultValues';
import {NodeExpansionState, PayerType} from '../../shared/enums';
import {take} from 'rxjs/operators';
import {
  isItemAbleToShow,
  neutralizeExpansion,
  RelatableSelectableItem, ScenarioForDisplayability,
  setUpPayerHierarchy
} from './PayerSetupHelper';
import {PayerCategoryService, PayerCategoryServiceToken} from '../../services/payer-category.service';
import {NgRedux} from '@angular-redux/store';
import {FilterCriteria, IAppState} from '../../store/IAppState';

@Component({
  selector: 'app-search-payer',
  templateUrl: './search-payer.component.html',
  styleUrls: ['./search-payer.component.scss']
})
export class SearchPayerComponent implements OnInit {
  @Input() filterCriteria: FilterCriteria;
  @Input() shouldPayersBeGranular = false;
  @Input() payerSelection: PayerFilterCriteria = DEFAULT_PAYER_FILTER_CRITERIA;
  @Output() payerSelectionChanged = new EventEmitter<PayerFilterCriteria>();
  @Output() payerHierarchyLoaded = new EventEmitter<PayerCategoryLevel[]>();
  readonly DEFAULT_PAYER_SELECTION = DEFAULT_PAYER_SELECTION;
  readonly CATEGORY = PayerType.CATEGORY;
  readonly NATIONAL = PayerType.NATIONAL;
  readonly MEMBER = PayerType.MEMBER;
  allSelected: boolean;
  displayedPayers: RelatableSelectableItem<PayerObject>[] = [];
  searchText = '';
  showLoadingIndicator = true;
  payers: RelatableSelectableItem<PayerObject>[] = [];

  constructor(
      private readonly ngRedux: NgRedux<IAppState>,
      @Inject(PayerCategoryServiceToken) private readonly payerCategoryService: PayerCategoryService,
  ) {}

  ngOnInit() {
    this.allSelected = !this.payerSelection.payerCategoryKey;

    const memberKeyInStore: number = this.ngRedux.getState().filters.memberKey;
    this.payerCategoryService.getPayerHierarchy(memberKeyInStore).pipe(take(1))
        .subscribe((payerHierarchy: PayerCategoryLevel[]) => {
          this.payerHierarchyLoaded.emit(payerHierarchy);
          this.payers = setUpPayerHierarchy(payerHierarchy, this.filterCriteria);
          this.showPayers();
          this.showLoadingIndicator = false;
        });
  }


  private payerGranularityAllowed(payer: PayerObject): boolean {
    return this.shouldPayersBeGranular || !isPayerTypeAGranularType(payer.level);
  }

  private payerMatchesSearchText(payer: RelatableSelectableItem<PayerObject>): boolean {
    return payer.item.matchesSearchText || !!payer.relatives.find(r => r.matchesSearchText);
  }

  private showPayerBasedOnItsLevelAndParentExpansionState(payer: RelatableSelectableItem<PayerObject>): boolean {
    return !isPayerTypeAGranularType(payer.item.item.level)
        || isItemAbleToShow(payer, ScenarioForDisplayability.IsParentExpanded);
  }

  private showPayers(): void {
    this.displayedPayers = this.payers.filter((payer: RelatableSelectableItem<PayerObject>) =>
      (this.payerGranularityAllowed(payer.item.item)
        && this.payerMatchesSearchText(payer)
        && this.showPayerBasedOnItsLevelAndParentExpansionState(payer)));
  }

  getCaretClass(expansion?: ListItemExpansion): string {
    if (!this.shouldPayersBeGranular || !expansion) {
      return '';
    }
    switch (expansion.state) {
      case NodeExpansionState.COLLAPSED:
        return 'fa fa-caret-down';
      case NodeExpansionState.EXPANDED:
        return 'fa fa-caret-up';
      default:
        return '';
    }
  }

  splitPayerIfTooLong(payer: RelatableSelectableItem<PayerObject>): [string, boolean] {
    const displayText: string = payer.item.displayText || '';
    const depth: number = payer.item.expansion?.depth || 0;
    switch (depth) {
      case 0:
        return [displayText.slice(0, 46), displayText.length >= 46];
      case 1:
        return [displayText.slice(0, 41), displayText.length >= 41];
      case 2:
        return [displayText.slice(0, 38), displayText.length >= 34];
    }
    return [displayText, false];
  }

  selectAllPayers(): void {
    if (!this.allSelected) {
      this.payers.forEach(payer => neutralizeExpansion(payer.item));
      this.allSelected = true;
      this.payerSelection = DEFAULT_PAYER_FILTER_CRITERIA;
      this.payerSelectionChanged.emit(this.payerSelection);
      this.showPayers();
    }
  }

  expandOrCollapsePayer(payer: RelatableSelectableItem<PayerObject>) {
    const payerExpansion = payer.item.expansion;
    if (payerExpansion) {
      payerExpansion.state = getOppositeNodeExpansionState(payerExpansion.state);
      if (payerExpansion.state === NodeExpansionState.COLLAPSED) {
        this.collapseChildPayers(payer);
      }
    }
    this.showPayers();
  }

  whenPayerClicked(payer: RelatableSelectableItem<PayerObject>): void {
    const currentPayerItem = payer.item;
    this.allSelected = false;
    this.payerSelection = currentPayerItem.item.payerCriteria;
    this.payerSelectionChanged.emit(this.payerSelection);
  }

  private collapseChildPayers(payer: RelatableSelectableItem<PayerObject>) {
    payer.relatives.forEach(rel => {
      if (rel.item.level && payer.item.item.level) {
        if (rel.item.level > payer.item.item.level && rel.expansion?.state === NodeExpansionState.EXPANDED) {
          rel.expansion.state = NodeExpansionState.COLLAPSED;
        }
      }
    });
  }

  onSearchTextChanged(): void {
    this.payers.forEach(payer => {
      payer.item.matchesSearchText = this.searchText.length < 3 || !!payer.item.displayText?.toLowerCase()
        .includes(this.searchText.toLowerCase());
    });
    this.showPayers();
  }
}
