import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DEFAULT_INVOICE_STATUS} from '../../store/DefaultValues';
import {InvoiceStatus} from '../../shared/models';
import {invoiceStatusTypes} from '../../shared/constants';

@Component({
  selector: 'app-select-invoice-status',
  templateUrl: './select-invoice-status.component.html',
  styleUrls: ['./select-invoice-status.component.scss']
})
export class SelectInvoiceStatusComponent implements OnInit {

  @Output() invoiceStatusEmit = new EventEmitter<number>();
  @Input() selectedInvoiceStatus: number  = DEFAULT_INVOICE_STATUS;
  invoiceStatusTypes: InvoiceStatus[] = invoiceStatusTypes;

  constructor() {
  }

  ngOnInit() {
  }

  selectInvoiceStatus(status: number,  event: MouseEvent) {
    this.invoiceStatusEmit.emit(status);
    this.selectedInvoiceStatus = status;
  }

}
