<span id="npv-location-filter-selector-wrapper">
  <mat-label id="filter-by-label">Filter Graph Data by {{getFilterTypeText()}}</mat-label>
  <span id="currentSelection" (click)="toggleSearchPanel()">{{currentSelectionText}}
    <i class="fa fa-caret-{{showDropdown ? 'up' : 'down'}}"></i>
  </span>
  <span id="dropdown-wrapper">
    <mat-form-field *ngIf="showDropdown" id="dropdown" appearance="outline">
      <span id="input-wrapper">
        <input id="search-for" type="text" matInput
          [formControl]="autocompleteControl"
          [placeholder]="currentSelectionText"
          [matAutocomplete]="auto"
          [ngModel]="searchText"
          (input)="whenInput($event)">
        <i class="fa fa-search" *ngIf="searchText.length < minSearchChars"></i>
      </span>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions"
          [value]="option.display"
          [ngClass]="option.identity === '' ? 'all-option' : ''"
          (onSelectionChange)="onOptionSelected(option)">{{option.display}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </span>
</span>
<span *ngIf="eligibleToShowClear" id="clear" (click)="clearTheSelections()">Clear</span>
