export const environment = {
  production: true,
  enableCompliance: true,
  api: '#{CONFIG.BACKEND_URL}',
  thisUri: '#{CONFIG.FRONTEND_URL}',
  clientId: '#{CONFIG.CLIENT_ID}',
  issuer: '#{CONFIG.ISSUER}',
  googleAnalyticsId: '#{CONFIG.GOOGLE_ANALYTICS_ID}',
  GTMContainerId: '#{CONFIG.GTM_CONTAINER_ID}',
  apiKey: '#{CONFIG.GOOGLE.MAPS.API.KEY}',
  complianceUrl: '#{CONFIG.COMPLIANCE_URL}',
  rumId: '#{CONFIG.RUM_APP_ID}'
};
