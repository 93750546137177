<div class=tile-wrapper>
  <div class=tile>
    <div class=title>{{title}}</div>
    <div class=sub-header>
      <span class="selectedDateRange"
            *ngIf="selectedDateRange && selectedDateRange.length > 0">{{selectedDateRange}}</span>
    </div>
    <div class="variance-header">
      <span id="varianceSubtractFrom">{{varianceSubtractFrom}}</span>
      <span *ngIf="isCfteAdjusted" id="benchmark-variance">
        is
        <span id="variance" [ngClass]="varianceClass"> {{variance}}</span>
        <span> the </span>
        <span>
          <span id="benchmark-scale">{{benchmarkPlaceholder}}</span>
        </span>
        <span>and</span>
      </span>
      <span> has a </span>
      <span id="previousYearDifference" [ngClass]="previousYearDifferenceClass">{{previousYearDifference}}</span>
      <span id="previousDateHeader"> from {{previousDateRange}}</span>
    </div>
    <div *ngIf="overviewChartOptions">
      <app-chart [chartOptions]="overviewChartOptions.chart"
                 [title]="overviewChartOptions.title"
                 [legends]="overviewChartOptions.legends"
                 [showScrollFull]="overviewChartOptions.showFullScrollbar"
                 [isScrolled]="overviewChartOptions.enableScrollbar"
                 [lang]="overviewChartOptions.lang"
                 [legendOptions]="overviewChartOptions.legend"
                 [series]="overviewChartOptions.series"
                 [tooltipOptions]="overviewChartOptions.tooltip"
                 [xAxisOptions]="overviewChartOptions.xAxis"
                 [yAxisOptions]="overviewChartOptions.yAxis"
                 [showLegend]="false"
                 [fromPdf]="true"
                 [hasBenchmarks]="hasBenchmarks"
      ></app-chart>
    </div>
    <div id="overview-legend-table">
      <table>
        <tr class="row">
          <th></th>
          <th nowrap
              *ngFor="let legend of legendData"
              class="label {{legend.class}} {{legend.benchmark && 'bchmk'}}">
            <div class="{{legend.benchmark ? 'legend-bchmk-header' : ''}}">
            {{legend.name}}
              <app-legend-benchmark-option
                *ngIf="legend.benchmark"
                [originClass]="'overview'"
                [fromPdf]="true"
                [benchmarkOption]="benchmarkOption"
                [benchmarkPlaceholder]="benchmarkPlaceholder">
              </app-legend-benchmark-option>
            </div>
            <div *ngIf="legend.benchmark" [ngClass]="getBenchmarkHeaderClass(legend)">
              Benchmark
              <app-legend-benchmark-percentile
                [fromPdf]="true"
                [benchmarkToExclude]="[]"
                [defaultBenchmarkPercentile]="benchmarkPercentile"
                [originClass]="'overview'">
              </app-legend-benchmark-percentile>
           </div>

          </th>
        </tr>
        <tr class="row">
          <td nowrap class="label">{{selectedDateRange}}</td>
          <td nowrap *ngFor="let legend of legendData" class="{{legend.currentDatesColor}}">
            <div *ngIf="legend.currentDatesIcon" class="{{legend.currentDatesIcon}} {{legend.currentDatesColor}}">
            </div>{{legend.currentDatesValue}}</td>
        </tr>
        <tr class="row">
          <td nowrap class="label">{{previousDateRange}}</td>
          <td nowrap *ngFor="let legend of legendData" class="{{legend.previousDatesColor}}">
            <div *ngIf="legend.previousDatesIcon" class="{{legend.previousDatesIcon}} {{legend.previousDatesColor}}">
            </div>{{legend.previousDatesValue}}</td>
        </tr>
      </table>
    </div>
    <app-overview-tile-footer [fromPdfExport]="true" [metricType]="MetricType.WorkRVUs"></app-overview-tile-footer>
  </div>
</div>
