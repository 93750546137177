import {ChangeDetectorRef, Component, Input, OnChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MergedNewPatientVisitSnapshotEntry, NpvLocationWithSnapshotEntries} from '../../npv-models';
import {DataTableColumns} from '../../../../shared/data-table-columns';
import {BenchmarkOption, ColumnType, MultilevelTab} from '../../../../shared/enums';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {NUMBER_OF_ROWS_BEFORE_SCROLLING, populatePageSizes} from '../../npv-helpers';
import {getSingularOntologyLevelName, Page} from '../../../../shared/helpers';
import {BaseColumn, IAppState} from '../../../../store/IAppState';
import {AppAction, multilevelTabChangedTo, npvLocationSortingChangedTo} from '../../../../store/actions';
import {getValueFromObjectWithStringIndexing} from '../../../../shared/object-helpers';
import {DrillDownService} from '../../../../services/drilldown.service';
import {NgRedux} from '@angular-redux/store';
import {nextGranularOntologyTab} from '../../../../shared/ontology-helpers';
import {ColumnWithMatSort} from './npv-location-table-header/npv-location-table-header.component';
import {getDefaultHoverIndex} from '../../../../shared/constants';

@Component({
  selector: 'app-location-npv-table',
  templateUrl: './location-npv-table.component.html',
  styleUrls: ['./location-npv-table.component.scss']
})
export class LocationNpvTableComponent implements OnChanges {
  @Input() columns: DataTableColumns[] = [];
  @Input() multilevelTab: MultilevelTab = MultilevelTab.LOCATION_DEPARTMENT;
  @Input() benchmarkOption: BenchmarkOption = BenchmarkOption.Academic;
  @Input() originalColumns: DataTableColumns[] = [];
  @Input() receivedData: NpvLocationWithSnapshotEntries[] = [];
  @Input() isZeroSuppressionChecked = false;
  @Input() countOfSuppressedEntries: number;
  @Input() snapshotEntryName: string;
  @Input() tableTitle: string;
  @Input() showLoader = true;
  @Input() currentPage: Page;
  @Input() previousDateText = '';
  @Input() reducerAction: (columns: BaseColumn[]) => AppAction;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  topDataSource: MatTableDataSource<NpvLocationWithSnapshotEntries>;
  subTableInfo: SubTableWithActiveStatus[];
  isDrillable = true;
  displayedColumns: string[] = [];
  readonly ColumnType = ColumnType;
  private readonly initialPageSize = 5;
  pageSize = this.initialPageSize;
  readonly pageSizeOptions = populatePageSizes(this.initialPageSize);
  readonly zeroSuppressionCondition = 'a total patient count greater than zero';
  readonly DEFAULT_HOVER_INDEX = getDefaultHoverIndex();
  readonly topRowsClass = 'top-rows';
  readonly NUMBER_OF_ROWS_BEFORE_SCROLLING = NUMBER_OF_ROWS_BEFORE_SCROLLING;
  private readonly DEFAULT_ROW_HEIGHT = 212;
  hoverIndex = this.DEFAULT_HOVER_INDEX;
  ROW_HEIGHT = this.DEFAULT_ROW_HEIGHT;
  pageIndex = 0;

  constructor(private drillDownService: DrillDownService, private ngRedux: NgRedux<IAppState>,
              private cdr: ChangeDetectorRef) { }

  ngOnChanges(): void {
    if (this.receivedData) {
      this.isDrillable = this.multilevelTab !== MultilevelTab.LOCATION_PROVIDER;
      this.setDataSources();
      this.displayedColumns = this.columns.map(x => x.columnDef);
      this.topDataSource = new MatTableDataSource<NpvLocationWithSnapshotEntries>(this.receivedData);
      this.topDataSource.paginator = this.paginator;
      this.topDataSource.paginator.pageSize = this.pageSize;
      this.topDataSource.paginator.pageIndex = this.pageIndex;
      this.topDataSource.paginator.length = this.receivedData.length;
      const topRows = document.getElementsByClassName(this.topRowsClass)[0];
      if (topRows) {
        this.ROW_HEIGHT = topRows.getBoundingClientRect().height;
      }
    }
  }

  private setDataSources(): void {
    this.subTableInfo = [];
    this.receivedData.slice(this.pageIndex * this.pageSize, this.pageIndex * this.pageSize + this.pageSize).forEach(entry => {
      this.subTableInfo.push({active: false,
        dataSource: new MatTableDataSource<NpvLocationWithSnapshotEntries>([entry]),
        height: this.DEFAULT_ROW_HEIGHT
      });
    });
  }

  determineTableHeight(source: SubTableWithActiveStatus, height: number): void {
    source.height = source.height < height ? height : source.height;
    this.cdr.detectChanges();
  }

  updateDataTable(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.setDataSources();
  }

  whenHeaderSortSelected(columnWithMatSort: ColumnWithMatSort): void {
    this.ngRedux.dispatch(npvLocationSortingChangedTo(columnWithMatSort));
  }

  onNodeSelect(row: MergedNewPatientVisitSnapshotEntry): void {
    if (!this.isDrillable) {
      return;
    }
    this.drillDownService.drillDownIntoNode(getValueFromObjectWithStringIndexing(row,
        `${getSingularOntologyLevelName(this.multilevelTab).toLowerCase()}NodePath`),
      this.ngRedux.getState().filters.nodePath, this.multilevelTab);
    this.ngRedux.dispatch(multilevelTabChangedTo(nextGranularOntologyTab(this.multilevelTab)));
  }

  changeHoverIndex(hoverIndex: number): void {
    this.hoverIndex = hoverIndex;
  }
}

interface SubTableWithActiveStatus {
  active: boolean;
  dataSource: MatTableDataSource<NpvLocationWithSnapshotEntries>;
  height: number;
}
