import {CfpVariableViewType} from '../shared/helpers';
import {CfpByMultilevel, ClinicalSummary, ProcedureSummaryDrill, ProcedureSummaryDrillImpl} from './ClinicalSummary';
import {CptGroup} from './procedure-summary/ProcedureSummaryModels';
import {CptViewType} from '../shared/enums';
import {formatNumberToWholeNumber} from '../productivity-summary/number-formatter';
import {roundToNumber} from '../productivity-summary/number-formatter';

export const cfpVariablesWithInfo = [
  {
    value: CfpVariableViewType.CfteAdjustedwRVU,
    display: 'cFTE adj. wRVUs'
  },
  {
    value: CfpVariableViewType.ActualwRVU,
    display: 'Actual wRVUs'
  },
  {
    value: CfpVariableViewType.CfteAdjustedCount,
    display: 'cFTE adj. Count'
  },
  {
    value: CfpVariableViewType.ActualCount,
    display: 'Count'
  }
];

export function filterCfpDataBasedOnCptGroupAndDrill(
  cfpData: CfpByMultilevel[],
  cptDrill?: ProcedureSummaryDrillImpl,
  cptGroup?: CptGroup): CfpByMultilevel[] {
  return filterDimensionalDataBasedOnCptGroup(
    (cptDrill ? filterDimensionalDataBasedOnDrill(cptDrill.drillObject, cfpData) : cfpData), cptGroup);
}

function filterDimensionalDataBasedOnDrill(drill: ProcedureSummaryDrill, data: CfpByMultilevel[]): CfpByMultilevel[] {
  switch (drill.viewType) {
    case CptViewType.CptRange:
      return data.filter(x => x.cptFamilyDesc === drill.cfp.cptFamilyDesc);
    case CptViewType.CptCode:
      return data.filter(x => x.cptFamilyDesc === drill.cfp.cptFamilyDesc &&
        x.cptRangeDesc === drill.cfp.cptRangeDesc);
  }
  return data;
}

function filterDimensionalDataBasedOnCptGroup(data: CfpByMultilevel[], group?: CptGroup): CfpByMultilevel[] {
  if (!group) {
    return data;
  }
  const allowedFamilies: string[] = [];
  const allowedRanges: string[] = [];
  const allowedCodes: string[] = [];
  group.entries.forEach(entry => {
    switch (entry.cptViewType) {
      case CptViewType.CptFamily:
        allowedFamilies.push(entry.cptDesc);
        break;
      case CptViewType.CptRange:
        allowedRanges.push(entry.cptDesc);
        break;
      case CptViewType.CptCode:
        allowedCodes.push(entry.cptDesc);
    }
  });
  return data.filter(x => {
    return allowedFamilies.includes(x.cptFamilyDesc) || allowedRanges.includes(x.cptRangeDesc)
      || allowedCodes.includes(x.cptCode);
  });
}

export function isClinicalSummaryZeroSuppressible(entry: ClinicalSummary): boolean {
  return Number(formatNumberToWholeNumber(entry.cfteAdjustedWRVUs)) === 0 &&
    Number(formatNumberToWholeNumber(entry.cfteAdjustedFrequency)) === 0
    && Number(formatNumberToWholeNumber(entry.workRvuBenchmark)) === 0
    && Number(formatNumberToWholeNumber(entry.frequencyBenchmark)) === 0;
}

export function sortByDefaultTheData(clinicalSummaryData: any[], cfpVariableViewType: CfpVariableViewType) {
  return clinicalSummaryData.sort((x, y) => {
    switch (cfpVariableViewType) {
      case CfpVariableViewType.ActualCount:
        return y.frequency - x.frequency;
      case CfpVariableViewType.CfteAdjustedwRVU:
        return y.cfteAdjustedWRVUs - x.cfteAdjustedWRVUs;
      case CfpVariableViewType.CfteAdjustedCount:
        return y.cfteAdjustedFrequency - x.cfteAdjustedFrequency;
      case CfpVariableViewType.ActualwRVU:
        return y.wRVUs - x.wRVUs;
    }
  });
}

export function getDisplayTextForCfpVariableTypeWithBenchmarkOption(type: CfpVariableViewType, viewCommunity: boolean): string {
  const cfpVariableTypeText = getDisplayTextForCfpVariableViewType(type);
  return `${cfpVariableTypeText} ${viewCommunity ? 'Community' : 'Academic'} Benchmark Mean`;
}

export function getDisplayTextForCfpVariableViewType(type: CfpVariableViewType): string {
  return cfpVariablesWithInfo.find(v => v.value === type)?.display ?? '';
}

export function getBenchmarkData(groupedData: any[], viewCommunity: boolean, cfpVariableViewType: CfpVariableViewType, round = false) {
  return !isBenchmarkRequired(cfpVariableViewType) ? []
    : groupedData.map(x => {
      let benchmarkValue;
      switch (cfpVariableViewType) {
        case CfpVariableViewType.CfteAdjustedwRVU:
          benchmarkValue = viewCommunity ? x.communityWorkRvuBenchmark : x.workRvuBenchmark;
          break;
        case CfpVariableViewType.CfteAdjustedCount:
          benchmarkValue = viewCommunity ? x.communityFrequencyBenchmark : x.frequencyBenchmark;
          break;
      }
      return round ? roundToNumber(benchmarkValue, 1) : benchmarkValue;
    });
}

export function isBenchmarkRequired(cfpVariableViewType: CfpVariableViewType): boolean {
  return cfpVariableViewType === CfpVariableViewType.CfteAdjustedwRVU || cfpVariableViewType === CfpVariableViewType.CfteAdjustedCount;
}
