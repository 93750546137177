<div class="data-table-container-wrapper">
  <div class="data-table-container">
    <div class="summaryHeader bold">Collection Summary Metrics</div>
    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="metric">
        <mat-header-cell *matHeaderCellDef>
          <span class="heading-text-metric">Metric</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="metric">{{row.metric}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricSelectedDateRange">
        <mat-header-cell *matHeaderCellDef class="headerSelectedDateRange">
          <span class="heading-text">Selected Date Range</span>
          <span class="date">{{selectedDateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="selectedDateRange">
          {{row.metricSelectedDateRange}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricPreviousYearSelectedDateRange">
        <mat-header-cell *matHeaderCellDef class="headerPreviousYearSelectedDateRange">
          <span class="heading-text">Previous Year<br>Selected Date Range</span>
          <span class="date">{{previousDateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="previousYearSelectedDateRange">
          {{row.metricPreviousYearSelectedDateRange}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricYearToDate">
        <mat-header-cell *matHeaderCellDef class="headerYearToDate">
          <span class="heading-text">YTD</span>
          <span class="date">{{ytdDateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="yearToDate">
          {{row.metricYearToDate}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="metricPreviousYearToDate">
        <mat-header-cell *matHeaderCellDef class="headerPreviousYearToDate">
          <span class="heading-text">Previous Year YTD</span>
          <span class="date">{{previousYtdDateRange}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="previousYearToDate">
          {{row.metricPreviousYearToDate}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <app-loader  [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
</div>

