import {Component, Input, OnChanges} from '@angular/core';
import {CptCategories, CptSummary, Legend} from '../../../shared/models';
import {getDefaultCptSummary, getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions} from '../../em-helpers';
import {BenchmarkOption} from '../../../shared/enums';
import {MetricType} from '../../../shared/metric-types';
import {getEmLegends} from '../../em-chart-helpers';

@Component({
  selector: 'app-em-inpatient-multilevel-chart',
  templateUrl: './em-inpatient-multilevel-chart.component.html',
  styleUrls: ['./em-inpatient-multilevel-chart.component.scss']
})
export class EmInpatientMultilevelChartComponent implements OnChanges {

  @Input() totalCptCounts?: CptCategories;
  @Input() showProgressBar: boolean;
  @Input() chartHeight: number;
  @Input() overviewPageView: boolean;
  @Input() chartWidth: number;
  @Input() isSingleProviderSelected: boolean;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() fromPdf: boolean;
  @Input() specialtyCptCounts?: CptCategories;

  initialHospitalCareCategories = ['99221', '99222', '99223'];
  subsequentHospitalCareCategories = ['99231', '99232', '99233'];
  hospitalDischargeDayCategories = ['99238', '99239'];
  totalInitialHospitalCareCptSummaries: (CptSummary | undefined)[];
  totalSubsequentHospitalCareCptSummaries:  (CptSummary | undefined)[];
  totalHospitalDischargeDayCptSummaries:  (CptSummary | undefined)[];
  specialtyTotalInitialHospitalCareCptSummaries:  (CptSummary | undefined)[];
  specialtyTotalSubsequentHospitalCareCptSummaries:  (CptSummary | undefined)[];
  specialtyTotalHospitalDischargeDayCptSummaries:  (CptSummary | undefined)[];
  initialHospitalCareCptMaxValue = 0;
  subsequentHospitalCareCptMaxValue = 0;
  hospitalDischargeDayCptMaxValue = 0;
  specialtyInitialHospitalCareCptMaxValue = 0;
  specialtySubsequentHospitalCareCptMaxValue = 0;
  specialtyHospitalDischargeDayCptMaxValue = 0;
  maxInitialHospitalCareYAxisValue: number;
  maxSubsequentHospitalCareYAxisValue: number;
  maxHospitalDischargeDayYAxisValue: number;
  legends: Legend[];
  metricType = MetricType.EandMInpatient;
  ngOnChanges(): void {
    this.chartHeight = this.overviewPageView ? 250 : this.chartHeight;
    this.legends = getEmLegends(this.foundNodeName, this.isSingleProviderSelected);

    if (!this.totalCptCounts) {
      this.totalInitialHospitalCareCptSummaries = [];
      this.totalSubsequentHospitalCareCptSummaries = [];
      this.totalHospitalDischargeDayCptSummaries = [];
      this.initialHospitalCareCategories.forEach(x => {
        this.totalInitialHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.subsequentHospitalCareCategories.forEach(x => {
        this.totalSubsequentHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.hospitalDischargeDayCategories.forEach(x => {
        this.totalHospitalDischargeDayCptSummaries.push(getDefaultCptSummary());
      });

      this.initialHospitalCareCptMaxValue = 0;
      this.subsequentHospitalCareCptMaxValue = 0;
      this.hospitalDischargeDayCptMaxValue = 0;
      return;
    }

    if (!this.specialtyCptCounts) {
      this.specialtyTotalHospitalDischargeDayCptSummaries = [];
      this.specialtyTotalInitialHospitalCareCptSummaries = [];
      this.specialtyTotalSubsequentHospitalCareCptSummaries = [];
      this.initialHospitalCareCategories.forEach(x => {
        this.specialtyTotalHospitalDischargeDayCptSummaries.push(getDefaultCptSummary());
      });

      this.subsequentHospitalCareCategories.forEach(x => {
        this.specialtyTotalSubsequentHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.hospitalDischargeDayCategories.forEach(x => {
        this.specialtyTotalHospitalDischargeDayCptSummaries.push(getDefaultCptSummary());
      });

      this.initialHospitalCareCategories.forEach(x => {
        this.specialtyTotalInitialHospitalCareCptSummaries.push(getDefaultCptSummary());
      });

      this.initialHospitalCareCptMaxValue = 0;
      this.subsequentHospitalCareCptMaxValue = 0;
      this.hospitalDischargeDayCptMaxValue = 0;
    }

    if (this.totalCptCounts.initialHospitalCare) {
      this.totalInitialHospitalCareCptSummaries = [
        this.totalCptCounts.initialHospitalCare.cpt99221,
        this.totalCptCounts.initialHospitalCare.cpt99222,
        this.totalCptCounts.initialHospitalCare.cpt99223,
      ];

      this.initialHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalInitialHospitalCareCptSummaries,
          this.benchmarkOption);
    }

    if (this.totalCptCounts.subsequentHospitalCare) {
      this.totalSubsequentHospitalCareCptSummaries = [
        this.totalCptCounts.subsequentHospitalCare.cpt99231,
        this.totalCptCounts.subsequentHospitalCare.cpt99232,
        this.totalCptCounts.subsequentHospitalCare.cpt99233,
      ];

      this.subsequentHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalSubsequentHospitalCareCptSummaries,
          this.benchmarkOption);
    }

    if (this.totalCptCounts.hospitalDischargeDay) {
      this.totalHospitalDischargeDayCptSummaries = [
        this.totalCptCounts.hospitalDischargeDay.cpt99238,
        this.totalCptCounts.hospitalDischargeDay.cpt99239,
      ];

      this.hospitalDischargeDayCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalHospitalDischargeDayCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.initialHospitalCare) {
      this.specialtyTotalInitialHospitalCareCptSummaries = [
        this.specialtyCptCounts.initialHospitalCare.cpt99221,
        this.specialtyCptCounts.initialHospitalCare.cpt99222,
        this.specialtyCptCounts.initialHospitalCare.cpt99223,
      ];
      this.specialtyInitialHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalInitialHospitalCareCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.subsequentHospitalCare) {
      this.specialtyTotalSubsequentHospitalCareCptSummaries = [
        this.specialtyCptCounts.subsequentHospitalCare.cpt99231,
        this.specialtyCptCounts.subsequentHospitalCare.cpt99232,
        this.specialtyCptCounts.subsequentHospitalCare.cpt99233,
      ];

      this.specialtySubsequentHospitalCareCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalSubsequentHospitalCareCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.hospitalDischargeDay) {
      this.specialtyTotalHospitalDischargeDayCptSummaries = [
        this.specialtyCptCounts.hospitalDischargeDay.cpt99238,
        this.specialtyCptCounts.hospitalDischargeDay.cpt99239,
      ];

      this.specialtyHospitalDischargeDayCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalHospitalDischargeDayCptSummaries,
          this.benchmarkOption);
    }

    this.maxInitialHospitalCareYAxisValue = Math.max(this.initialHospitalCareCptMaxValue,
      this.specialtyInitialHospitalCareCptMaxValue);

    this.maxSubsequentHospitalCareYAxisValue = Math.max(this.subsequentHospitalCareCptMaxValue,
      this.specialtySubsequentHospitalCareCptMaxValue);

    this.maxHospitalDischargeDayYAxisValue = Math.max(
      this.hospitalDischargeDayCptMaxValue,
      this.specialtyHospitalDischargeDayCptMaxValue);

  }
}
