<div *ngFor="let range of ranges" class="range-wrapper">
  <app-batch-export-pdf-header [batchExportDetails]="detailData" [headerClass]="'batch-export-header-wrapper'"
                               [showPayer]="false"></app-batch-export-pdf-header>
  <app-em-trend-chart
    [fromPdf]="true"
    [dimension]="range.dimension"
    [benchmarkOption]="detailData.emTrendBenchmarkType"
    [data]="emData"
    [viewType]="range.viewType">
  </app-em-trend-chart>
  <app-trend-em-batch-summary [summaryData]="emSummary"
                              [columns]="range.summaryColumns"
                              [title]="getCorrespondingEmTitle(range.dimension)">
  </app-trend-em-batch-summary>
  <app-em-trend-table
    [receivedData]="emData"
    [columns]="range.columns"
    [showLoadingIndicator]="false"
    [tableCssClass]="rangeTableCssClasses.get(range.dimension)"
    [rangeIndex]="range.rangeIndex"
    header="{{getEvaluationManagementViewTypeText(range.cptCategoryMap.viewType)}}">
  </app-em-trend-table>
  <app-footer [fromPdf]="true"></app-footer>
  <div class="page-breaker"></div>
</div>
