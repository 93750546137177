import {Component, Input} from '@angular/core';
import {StyledTextToken, TextPosition} from '../../ui-helpers';

@Component({
  selector: 'app-styled-text-container',
  templateUrl: './styled-text-container.component.html',
  styleUrls: ['./styled-text-container.component.scss']
})
export class StyledTextContainerComponent {
  @Input() styledText: StyledTextToken[];
  readonly TextPosition = TextPosition;
}
