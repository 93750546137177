import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Variable} from '../../variable-container/variable-container.component';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../shared/benchmark-types';

import {AxisLabelsFormatterContextObject, Options, PlotColumnOptions, PointClickEventObject, YAxisOptions} from 'highcharts';
import {Chart} from 'angular-highcharts';
import {abbreviateAxisValue, roundToNearestTenthOrZero, roundToNumber} from '../../productivity-summary/number-formatter';
import {MetricType} from '../../shared/metric-types';
import {
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyLabels,
  createEmptyValues,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  StyledChart
} from '../../shared/highcharts-helpers';
import {CHART_HEIGHT} from '../../productivity-summary/productivity-graph-component';
import * as Highcharts from 'highcharts/highstock';
import {VarianceToggleActions, VarianceToggleField} from '../../variance-toggler/variance-toggler.component';
import {ColumnType, HighChartColorIndex, LegendColor, LegendStyle, SortingOrder} from '../../shared/enums';
import {AppAction, denialsSortingCriteriaChangedTo, denialsVarianceToggleChangedTo} from '../../store/actions';
import {Legend, SortingCriterion} from '../../shared/models';
import {hasValue} from '../../shared/null-helpers';
import {toTitleCase} from '../../shared/helpers';
import {IndividualSeriesOptions} from '../../shared/highcharts-mappings';
import {concat} from '../../shared/ourLodash';
import {combineLatest, Observable} from 'rxjs';
import {select} from '@angular-redux/store';
import {GraphColors} from '../../productivity-summary/colors';
import {
  chargesLegendForDenials,
  chargesTerminallyDeniedLegend,
  deniedCptsPaidLegend,
  terminalDenialRateLegend
} from './denials-chart-helper';
import {doesDenialsDataHaveValidBenchmarkValue} from './denials-chart-helper';
import {DenialPayerEntity, DenialsEntity, DenialsEntityWithVariance} from '../denials-models';

@Component({
  selector: 'app-denials-chart',
  templateUrl: './denials-chart.component.html',
  styleUrls: ['./denials-chart.component.scss']
})
export class DenialsChartComponent implements OnInit, OnChanges {

  defaultBenchmarkPercentile = BenchmarkPercentile.Percentile50th;
  @Input() variables: Variable[];
  @Input() showVariableMenu: boolean;
  @Input() page: string;
  @Input() showProgressBar: boolean;
  @Input() activeVarianceToggle = false;
  @Input() barSelectionCallback: (selectedNode: DenialPayerEntity) => void;
  @Input() denialsData: DenialPayerEntity[];
  @Input() benchmarkPercentile: BenchmarkPercentile = this.defaultBenchmarkPercentile;
  @Input() sortingCriteria: SortingCriterion;
  @Output() toggleVarianceGraph = new EventEmitter<boolean>();
  @Input() columnSelectionCallback: (denials: DenialsEntity) => void;

  @select(['display', 'denialsChargesDisplayed'])
  private readonly chargesDisplayed$: Observable<boolean>;
  @select(['display', 'chargesTerminallyDeniedDisplayed'])
  private readonly chargesTerminallyDeniedDisplayed$: Observable<boolean>;
  @select(['display', 'deniedCPTsPaidDisplayed'])
  private readonly deniedCPTsPaidDisplayed$: Observable<boolean>;
  @select(['display', 'terminalDenialRateDisplayed'])
  private readonly terminalDenialRateDisplayed$: Observable<boolean>;
  hasBenchmarks = true;
  benchmarkToExclude = ['65th Percentile', '90th Percentile', 'Mean'];
  // @ts-ignore
  benchmarkDataSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  denialRateSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  chargesSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  chargesTerminallyDeniedSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  deniedCPTsPaidSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  // @ts-ignore
  terminalDenialRateSeriesOptions: Highcharts.BarChartSeriesOptions[] = [];
  chargesDisplayed: boolean;
  chargesTerminallyDeniedDisplayed: boolean;
  deniedCPTsPaidDisplayed: boolean;
  terminalDenialRateDisplayed: boolean;
  @ViewChild('chartDenialsTarget', {static: true})
  public chartTarget: ElementRef;

  public legends: Legend[];
  public varianceLegend: Legend[] = [
    {
      text: 'Above Benchmark',
      color: LegendColor.RED,
      metric: MetricType.Denials,
      style: LegendStyle.SQUARE
    },
    {
      text: 'Below Benchmark',
      color: LegendColor.GREEN,
      metric: MetricType.Denials,
      style: LegendStyle.SQUARE
    }
  ];
  private availableBenchmarks: BenchmarkPercentile[] = [
    BenchmarkPercentile.Percentile25th,
    BenchmarkPercentile.Percentile50th,
    BenchmarkPercentile.Percentile75th
  ];

  private readonly MAX_NUMBER_SCROLLBARS = 20;
  options: Options;
  chartObject: Chart;

  private readonly SNAPSHOT_BAR_COUNT = 20;
  private chartWidth = calculateChartWidth(DEFAULT_POINT_WIDTH, this.SNAPSHOT_BAR_COUNT, DEFAULT_POINT_PADDING);

  private barChartSeries: PlotColumnOptions = {
    cropThreshold: 100,
    pointPadding: DEFAULT_POINT_PADDING,
    stacking: 'normal'
  };

  varianceToggle: VarianceToggleActions = {
    display: false,
    sortingCriterion: {
      sortingOrder: SortingOrder.DESCENDING,
      columnDef: 'denialRatePer50th',
      columnType: ColumnType.BENCHMARK,
    },
    reducerField: VarianceToggleField.DenialsVarianceToggle,

    dispatchAction(display: boolean): AppAction {
      return denialsVarianceToggleChangedTo(display);
    },
    sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction {
      return denialsSortingCriteriaChangedTo(sortingCriteria);
    }
  };

  constructor() {
  }

  ngOnInit(): void {
    combineLatest([
      this.chargesDisplayed$,
      this.chargesTerminallyDeniedDisplayed$,
      this.deniedCPTsPaidDisplayed$,
      this.terminalDenialRateDisplayed$
    ])
      .subscribe(([chargesDisplayed, chargesTerminallyDeniedDisplayed, deniedCPTsPaidDisplayed, terminalDenialRateDisplayed]
                    : [boolean, boolean, boolean, boolean]) => {
        this.chargesDisplayed = chargesDisplayed;
        this.chargesTerminallyDeniedDisplayed = chargesTerminallyDeniedDisplayed;
        this.deniedCPTsPaidDisplayed = deniedCPTsPaidDisplayed;
        this.terminalDenialRateDisplayed = terminalDenialRateDisplayed;
        if (this.activeVarianceToggle) {
          this.drawVarianceGraph();
        } else {
          this.drawGraph();
        }
      });
  }

  ngOnChanges(): void {
    if (this.sortingCriteria) {
      this.varianceToggle.sortingCriterion = this.sortingCriteria;
    }
    this.hasBenchmarks = doesDenialsDataHaveValidBenchmarkValue(this.denialsData);
    if (this.activeVarianceToggle) {
      this.drawVarianceGraph();
    } else {
      this.drawGraph();
    }
  }

  drawGraph() {
    if (!this.denialsData) {
      return;
    }
    const data = this.denialsData;

    if (!this.availableBenchmarks.includes(this.benchmarkPercentile)) {
      this.benchmarkPercentile = BenchmarkPercentile.Percentile50th;
    }
    let benchmarkData: BenchmarkPercentile[] = [];
    let benchmarkName = '';
    switch (this.benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        benchmarkData = data.map(x => roundToNumber(x.denialRatePer25th, 1) || 0);
        benchmarkName = 'Denial Rate 25th Percentile';
        break;
      case BenchmarkPercentile.Percentile50th:
        benchmarkData = data.map(x => roundToNumber(x.denialRatePer50th, 1) || 0);
        benchmarkName = 'Denial Rate 50th Percentile';
        break;
      case BenchmarkPercentile.Percentile75th:
        benchmarkData = data.map(x => roundToNumber(x.denialRatePer75th, 1) || 0);
        benchmarkName = 'Denial Rate 75th Percentile';
        break;
    }

    const charges = data.map(x => roundToNumber(x.chargeAmount, 1) || 0);

    const chargesTerminallyDenied = data.map(x => roundToNumber(x.chargesTerminallyDenied, 1) || 0);

    const deniedCPTsPaid = data.map(x => roundToNearestTenthOrZero(x.deniedPaidRate) || 0);

    const terminalDenialRate = data.map(x => roundToNearestTenthOrZero(x.terminalDenialRate * 100) || 0);

    const deniedCPTsDisplayed = this.deniedCPTsPaidDisplayed;
    const terminalDenialRateDisplayed = this.terminalDenialRateDisplayed;

    this.legends = [
      {
        text: 'Denial Rate',
        color: LegendColor.TEAL,
        metric: MetricType.Denials,
        style: LegendStyle.SQUARE,
        showPercentileControl: false
      },
      {
        text: 'CPSC Denial Rate Benchmark: ',
        color: LegendColor.GREY,
        metric: MetricType.Denials,
        style: LegendStyle.SQUARE,
        showPercentileControl: true
      }
    ];

    // @ts-ignore
    const categories = createEmptyLabels(
      // @ts-ignore
      this.denialsData.map((x) => x.payerCategoryDesc),
      this.MAX_NUMBER_SCROLLBARS,
    );

    const denialRate: any[] = [];

    this.denialsData.forEach(x => {
      denialRate.push({
        y: x.denialRate,
        colorIndex: HighChartColorIndex.TEAL,
        description: x.payerCategoryDesc,
        drilldown: {
          payerType: x.payerType,
          payerKey: x.payerKey,
          payerCategoryDesc: x.payerCategoryDesc,
          payerCategory: {
            payerCategoryKey: x.payerKey, payerCategoryCode: '',
            payerCategoryDescription: x.payerCategoryDesc
          }
        }
      });
    });


    let maxLeft, minLeft;
    const rangeDetermineLeft: number[] = [];
    denialRate.forEach(x => {
      rangeDetermineLeft.push(x.y);
    });
    benchmarkData.forEach(x => {
      rangeDetermineLeft.push(x);
    });
    maxLeft = Math.max(...rangeDetermineLeft);
    minLeft = Math.min(...rangeDetermineLeft);

    let maxRight, minRight;
    const rangeDetermineRight: number[] = [];
    this.setUpVariables(chargesTerminallyDenied, rangeDetermineRight, deniedCPTsPaid, charges, terminalDenialRate);

    if (terminalDenialRate.length > 0) {
      maxRight = Math.max(...rangeDetermineRight) + 1;
      minRight = Math.min(...rangeDetermineRight) + 1;
      if (this.terminalDenialRateDisplayed) {
        if (maxRight < 0) {
          maxRight *= -1;
        }
      }
    } else {
      maxRight = minRight = 0;
    }

    if (denialRate.length > 0) {
      if (maxLeft < 0) {
        maxLeft *= -1;
      }
    } else {
      maxLeft = minLeft = 0;
    }
    const isScrolled: boolean = categories.length > this.MAX_NUMBER_SCROLLBARS;

    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% Denial Rate',
          margin: 30
        },
        max: maxLeft,
        min: minLeft > 0 ? 0 : minLeft,
        labels: {
          formatter: function () {
            return this.value + '%';
          },
        },
        tickAmount: 9
      },
    ];

    const multiLevelEventClick = {
      click: (event: PointClickEventObject) => {
        this.barSelectionCallback(this.denialsData[event.point.x]);
      }
    };

    const rightSideYAxis: YAxisOptions[] = (this.chargesDisplayed || this.chargesTerminallyDeniedDisplayed ||
    this.deniedCPTsPaidDisplayed || this.terminalDenialRateDisplayed
      ?
      [
        {
          title: {
            text: this.getRightSideLabel(),
          },
          max: maxRight,
          min: minRight > 0 ? 0 : minRight,
          opposite: true,
          labels: {
            formatter: function (this: AxisLabelsFormatterContextObject<any>) {
              return deniedCPTsDisplayed || terminalDenialRateDisplayed ? abbreviateAxisValue(this.value) + '%' : '$' +
                abbreviateAxisValue(this.value);
            },
          },
          tickAmount: 9
        }
      ] : []);
    this.denialRateSeriesOptions = [
      {
        name: 'Denial Rate',
        yAxis: 0,
        data: denialRate.length < this.MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(denialRate, this.MAX_NUMBER_SCROLLBARS) :
          denialRate,
        type: ChartType.COLUMN,
        colorIndex: HighChartColorIndex.TEAL,
        pointWidth: DEFAULT_POINT_WIDTH,
        stack: 0,
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
          valueDecimals: 0
        },
        events: multiLevelEventClick,
      },
    ];

    this.benchmarkDataSeriesOptions = [
      {
        name: benchmarkName,
        yAxis: 0,
        data: benchmarkData.length < this.MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(benchmarkData, this.MAX_NUMBER_SCROLLBARS) : benchmarkData,
        type: ChartType.LINE,
        colorIndex: HighChartColorIndex.GREY,
        pointWidth: DEFAULT_POINT_WIDTH,
        stack: 1,
        cursor: 'pointer',
        events: multiLevelEventClick,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25C6</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
          valueDecimals: 0
        }
      }
    ];

    this.chargesSeriesOptions =
      this.chargesDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Total Charges',
          yAxis: 1,
          data: charges.length < this.MAX_NUMBER_SCROLLBARS ?
            createEmptyValues(charges, this.MAX_NUMBER_SCROLLBARS) :
            charges,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.ORANGE,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: 'default',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-15">\u25A1</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];

    this.chargesTerminallyDeniedSeriesOptions =
      this.chargesTerminallyDeniedDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Charges Terminally Denied',
          yAxis: 1,
          data: chargesTerminallyDenied.length < this.MAX_NUMBER_SCROLLBARS ?
            createEmptyValues(chargesTerminallyDenied, this.MAX_NUMBER_SCROLLBARS) :
            chargesTerminallyDenied,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.RED,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: 'default',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-22">\u25A1</span> {series.name}: <b>${point.y:,.0f}</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];

    this.deniedCPTsPaidSeriesOptions =
      this.deniedCPTsPaidDisplayed ? [
        {
          turboThreshold: 0,
          name: '% of Denied CPTs Paid',
          yAxis: 1,
          data: deniedCPTsPaid.length < this.MAX_NUMBER_SCROLLBARS ?
            createEmptyValues(deniedCPTsPaid, this.MAX_NUMBER_SCROLLBARS) :
            deniedCPTsPaid,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.BLUE,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: 'default',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-17">\u25A1</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];

    this.terminalDenialRateSeriesOptions =
      this.terminalDenialRateDisplayed ? [
        {
          turboThreshold: 0,
          name: 'Terminal Denial Rate',
          yAxis: 1,
          data: terminalDenialRate.length < this.MAX_NUMBER_SCROLLBARS ?
            createEmptyValues(terminalDenialRate, this.MAX_NUMBER_SCROLLBARS) :
            terminalDenialRate,
          type: ChartType.LINE,
          colorIndex: HighChartColorIndex.PURPLE,
          pointWidth: DEFAULT_POINT_WIDTH,
          stack: 3,
          cursor: 'default',
          events: multiLevelEventClick,
          zIndex: 1,
          marker: {
            symbol: ChartMarkerSymbol.SQUARE
          },
          tooltip: {
            pointFormat: '<span class="highcharts-color-13">\u25A1</span> {series.name}: <b>{point.y:,.1f} %</b><br/>',
            valueDecimals: 0
          }
        }
      ] : [];
    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: concat(this.denialRateSeriesOptions, this.benchmarkDataSeriesOptions,
        this.chargesSeriesOptions, this.chargesTerminallyDeniedSeriesOptions,
        this.deniedCPTsPaidSeriesOptions, this.terminalDenialRateSeriesOptions),
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.chartWidth,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground,
        },
        // @ts-ignore
        clickOnCrosshair: (e: any, p: any) => {
          // @ts-ignore
          this.barSelectionCallback(this.denialsData[p.index]);
        },
        max: isScrolled ? this.MAX_NUMBER_SCROLLBARS - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            let ret = '';
            const tokenized = this.value.toLocaleString().split(' ');
            for (let i = 0; i < tokenized.length; i++) {
              ret = ret.concat(tokenized[i] + ' ');
              if (i === roundToNumber(tokenized.length / 2, 0) - 1) {
                ret = ret.concat('<br/>');
              }
            }
            return ret;
          },
          rotation: 45,
        },
      },
      yAxis: concat(leftSideYAxis, rightSideYAxis),
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false,
      },
      plotOptions: {
        column: {
          ...this.barChartSeries,
          tooltip: {
            pointFormat: ''
          }
        }
      },
    };

    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  private setUpVariables(chargesTerminallyDenied: number[], rangeDetermineRight: number[],
                         deniedCPTsPaid: number[], charges: number[], terminalDenialRate: number[]): void {
    if (this.chargesTerminallyDeniedDisplayed) {
      this.addVariableToGraph(chargesTerminallyDenied, rangeDetermineRight, chargesTerminallyDeniedLegend);
      return;
    }
    if (this.deniedCPTsPaidDisplayed) {
      this.addVariableToGraph(deniedCPTsPaid, rangeDetermineRight, deniedCptsPaidLegend);
      return;
    }
    if (this.chargesDisplayed) {
      this.addVariableToGraph(charges, rangeDetermineRight, chargesLegendForDenials);
      return;
    }
    if (this.terminalDenialRateDisplayed) {
      this.addVariableToGraph(terminalDenialRate, rangeDetermineRight, terminalDenialRateLegend);
      return;
    }
  }

  private addVariableToGraph(variableData: number[], rangeDetermineRight: number[], legend: Legend) {
    variableData.forEach(x => {
      rangeDetermineRight.push(x);
    });
    this.legends.push(legend);
  }

  drawVarianceGraph() {
    if (!this.denialsData) {
      return;
    }
    const data = this.denialsData.filter((datum: any) =>
      hasValue(this.getVarianceForVarianceGraph(datum, this.benchmarkPercentile)));
    switch (this.benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        data.sort((a, b) => a.variancePer25th - b.variancePer25th);
        break;
      case BenchmarkPercentile.Percentile50th:
        data.sort((a, b) => a.variancePer50th - b.variancePer50th);
        break;
      case BenchmarkPercentile.Percentile75th:
        data.sort((a, b) => a.variancePer75th - b.variancePer75th);
    }
    data.sort((a, b) => a.variancePer50th - b.variancePer50th);
    if (!this.availableBenchmarks.includes(this.benchmarkPercentile)) {
      this.benchmarkPercentile = BenchmarkPercentile.Percentile50th;
    }
    const varianceData: any[] = [];

    let max: number | string = 0;
    let min: number | string = 0;
    data.forEach(denials => {
      const varianceValue = this.getVarianceForVarianceGraph(denials, this.benchmarkPercentile)
        || 0;
      if (varianceValue !== 0 && varianceValue > max) {
        max = varianceValue;
      } else if (varianceValue !== 0 && varianceValue < min) {
        min = varianceValue;
      }

      varianceData.push({
        name: denials.payerCategoryDesc,
        y: varianceValue,
        colorIndex: varianceValue >= 0 ? HighChartColorIndex.RED : HighChartColorIndex.GREEN,
      });
    });
    this.legends = this.varianceLegend;
    const categories = createEmptyLabels(
      this.getVarianceLabels(data),
      this.MAX_NUMBER_SCROLLBARS,
    );
    const isScrolled: boolean = categories.length > this.MAX_NUMBER_SCROLLBARS;
    const absoluteMax = roundToNumber(Math.max(...[Math.abs(max), Math.abs(min)]), 0);

    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: '% Variance',
        },
        max: absoluteMax,
        min: -1 * absoluteMax,
        opposite: false,
        labels: {
          formatter: function () {
            return roundToNumber(this.value, 0) + '%';
          },
        },
        plotLines: [{
          color: '#000000',
          width: 200,
          value: 0,
          dashStyle: 'Solid',
          zIndex: 999
        }],
        tickInterval: absoluteMax / 10,
      },
    ];

    const varianceSeries: IndividualSeriesOptions[] = [
      {
        name: 'Variance from benchmark ' + readableNameOfColumnDef(this.benchmarkPercentile).toLowerCase(),
        yAxis: 0,
        type: ChartType.COLUMN,
        data: varianceData.length < this.MAX_NUMBER_SCROLLBARS ?
          createEmptyValues(varianceData, this.MAX_NUMBER_SCROLLBARS) :
          varianceData,
        pointWidth: DEFAULT_POINT_WIDTH,
        stacking: 'normal',
        stack: 0,
        cursor: 'pointer',
        zIndex: 1,
      },
    ];

    this.options = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: varianceSeries,
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 1,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: this.chartWidth,
        height: CHART_HEIGHT,
        styledMode: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        max: isScrolled ? this.MAX_NUMBER_SCROLLBARS - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return toTitleCase(this.value.toString());
          },
          rotation: 45,
        },
      },
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries
        }
      },
    };
    if (this.options) {
      this.chartObject = new StyledChart(this.options);
    }
  }

  getLabels(): string [] {
    return this.denialsData.map((datum) => datum.payerCategoryDesc);
  }

  getVarianceLabels(data: DenialPayerEntity[]): string[] {
    return data.map((datum) => datum.payerCategoryDesc);
  }

  getRightSideLabel(): string {
    if (this.chargesDisplayed) {
      return chargesLegendForDenials.text;
    } else if (this.chargesTerminallyDeniedDisplayed) {
      return chargesTerminallyDeniedLegend.text;
    } else if (this.deniedCPTsPaidDisplayed) {
      return deniedCptsPaidLegend.text;
    } else if (this.terminalDenialRateDisplayed) {
      return terminalDenialRateLegend.text;
    } else {
      return '';
    }
  }

  getVarianceForVarianceGraph(denials: DenialsEntityWithVariance, benchmarkPercentile: BenchmarkPercentile):
    number | string | undefined {
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Percentile25th:
        return denials.variancePer25th;
      case BenchmarkPercentile.Percentile50th:
        return denials.variancePer50th;
      case BenchmarkPercentile.Percentile75th:
        return denials.variancePer75th;
      default:
        return undefined;
    }
  }
}
