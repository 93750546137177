import {Component, Inject, Input, OnChanges} from '@angular/core';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {SortingCriterion} from '../../../shared/models';
import {getLevelTypeNodePath, LevelType, Page, WrvuViewType} from '../../../shared/helpers';
import {Variable} from '../../../variable-container/variable-container.component';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../../store/IAppState';
import {AppAction, isProviderSelectedChangedTo} from '../../../store/actions';
import {multilevelTabChangedTo} from '../../../store/actions';
import {ProductivityMultiLevelSnapshot, ProductivitySnapshot} from '../../services/ProviderProductivity';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {ColumnType, MultilevelTab} from '../../../shared/enums';
import {NgRedux} from '@angular-redux/store';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import * as _ from 'lodash';
import {DrillDownService} from '../../../services/drilldown.service';

@Component({
  selector: 'app-wrvu-multilevel-provider-page',
  templateUrl: './wrvu-multilevel-provider-page.component.html',
  styleUrls: ['./wrvu-multilevel-provider-page.component.scss']
})
export class WrvuMultilevelProviderPageComponent implements OnChanges {
  static openWindowColumns = [{
    columnDef: 'viewCpt',
    header: '',
    dataName: () => 'View CPT',
    isDefault: true,
    class: 'viewCpt',
    showCpt: true,
    columnType: ColumnType.OPEN_WINDOW,
    nodePath: (row: ProductivityMultiLevelSnapshot) => row.providerNodePath,
    nodeName: (row: ProductivityMultiLevelSnapshot) => row.providerNodeName
  }];

  openWindowColumns = WrvuMultilevelProviderPageComponent.openWindowColumns;

  @Input() isSpecialtyPerformance: boolean;
  @Input() activeVarianceToggle: boolean;
  @Input() multilevel: boolean;
  @Input() zeroSuppression: boolean;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() selectionCounts: number;
  @Input() varianceToggleSortingCriterion: SortingCriterion;
  @Input() wRVUtype: WrvuViewType;
  @Input() varianceKey: string;
  @Input() variables: Variable[];
  @Input() nodePath: string;
  @Input() defaultSortColumn: string;
  @Input() sortDirection: string;
  @Input() countOfSuppressedEntries: number;
  @Input() zeroSuppressionCondition: string;
  @Input() reducerAction: (displayedWrvuSnapshotColumns: BaseColumn[]) => AppAction;
  @Input() wrvuSnapshotData: ProductivityMultiLevelSnapshot[];
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion | undefined) => void;
  @Input() columns: DataTableColumns[];
  @Input() displayedColumns: DataTableColumns[] = [];

  currentPage: Page = Page.WrvuProvider;
  showProgressBar: boolean;
  LevelType = LevelType;
  MultilevelTab = MultilevelTab;

  constructor(private ngRedux: NgRedux<IAppState>,
              private drillDownService: DrillDownService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
  ) {
  }

  ngOnChanges(): void {
    this.showProgressBar =
      _.isEqual(this.wrvuSnapshotData, INITIAL_STATE.data.providerProductivityMultilevelData.providerProductivities);
    if (!this.displayedColumns.find(col => col.columnType === ColumnType.OPEN_WINDOW)) {
      this.displayedColumns = this.displayedColumns.concat(WrvuMultilevelProviderPageComponent.openWindowColumns);
    }
  }

  tieBreakerProperty = (providerProductivity: ProductivityMultiLevelSnapshot) => {
    return providerProductivity.providerNodeName;
  };

  whenProviderRowSelected = (selectedNode: ProductivityMultiLevelSnapshot) => {
    this.whenProviderSelected(selectedNode, true);
  };

  whenProviderSelected = (selectedNode: ProductivityMultiLevelSnapshot, table = false) => {
    if (!selectedNode) {
      return;
    }

    this.analyticsService.handleGoogleAnalytics('Wrvu Snapshot', selectedNode.nodeName, `Drill down on ${table ? 'Table' : 'Chart'}`);
    this.setSingleProviderSelectedIntoStore(selectedNode);
    this.drillDownService.drillDownIntoNode(selectedNode.nodePath, this.nodePath, MultilevelTab.BY_PROVIDER);
    this.ngRedux.dispatch(multilevelTabChangedTo(MultilevelTab.TREND));
  };

  setSingleProviderSelectedIntoStore(productivitySnapshot?: ProductivitySnapshot) {
    if (productivitySnapshot && getLevelTypeNodePath(productivitySnapshot.nodePath) === LevelType.singleProvider) {
      this.ngRedux.dispatch(isProviderSelectedChangedTo(true));
    }
  }

  whenSortChanged = (productivitySnapshotData: ProductivityMultiLevelSnapshot[]) => {
    if (!_.isEqual(this.wrvuSnapshotData, productivitySnapshotData)) {
      this.wrvuSnapshotData = productivitySnapshotData;
    }
  };
}
