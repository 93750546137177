import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {RelatableSelectableItem, UpdatedFilterTab} from '../FilterBannerModels';
import {InvoiceStatus} from '../../shared/models';
import {DEFAULT_INVOICE_TEXT} from '../../store/DefaultValues';
import {determineAllSelected} from '../UpdatedFilterBannerHelpers';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input() invoiceStatuses: RelatableSelectableItem<InvoiceStatus>[];
  @Input() onStatusChanged: (tab?: UpdatedFilterTab, item?: RelatableSelectableItem<InvoiceStatus>) => void;
  @Input() filterTab: UpdatedFilterTab;
  allSelected = true;
  readonly DEFAULT_INVOICE_TEXT = DEFAULT_INVOICE_TEXT;

  constructor(protected cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.allSelected = determineAllSelected(this.invoiceStatuses);
  }

  selectAllInvoices(event: MouseEvent): void {
    event.preventDefault();
    if (!this.allSelected) {
      let wasSelected: boolean;
      this.invoiceStatuses.forEach(status => {
        wasSelected = status.item.selected;
        status.item.selected = false;
        if (this.filterTab.newSelections && wasSelected) {
          if (status.item.originallySelected) {
            this.filterTab.newSelections.deselections++;
          } else {
            this.filterTab.newSelections.selections--;
          }
        }
      });
      this.allSelected = true;
      this.onStatusChanged(this.filterTab);
    }
  }

  whenInvoiceStatusClicked(status: RelatableSelectableItem<InvoiceStatus>, event: MouseEvent): void {
    event.preventDefault();
    if (status.item.selected) {
      return;
    }
    this.allSelected = false;
    let shouldBeSelected: boolean;
    let wasSelected: boolean;
    this.invoiceStatuses.forEach(is => {
      shouldBeSelected = is.item.key === status.item.key;
      wasSelected = is.item.selected;
      is.item.selected = shouldBeSelected;
      if (shouldBeSelected && this.filterTab.newSelections) {
        if (!is.item.originallySelected) {
          this.filterTab.newSelections.selections++;
        } else {
          this.filterTab.newSelections.deselections--;
        }
      } else if (!shouldBeSelected && this.filterTab.newSelections && wasSelected) {
        if (is.item.originallySelected) {
          this.filterTab.newSelections.deselections++;
        } else {
          this.filterTab.newSelections.selections--;
        }
      }
    });
    this.onStatusChanged(this.filterTab, status);
    this.cdr.detectChanges();
  }
}
