import {AfterViewInit, Component, Injectable, Input, ViewChild} from '@angular/core';
import {GmapMarker} from '../../../../shared/models';
import {AgmMap} from '@agm/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class AgmHelperBatchExportService {
  mapLoadStatus: BehaviorSubject<boolean>;
  mapReady: Observable<boolean>;
  mapReadyPromise: Promise<boolean>;

  constructor() {
    this.initializeValues();
  }

  initializeValues(): void {
    this.mapLoadStatus = new BehaviorSubject<boolean>(false);
    this.mapReady = this.mapLoadStatus.asObservable();
    this.mapReadyPromise = this.mapLoadStatus.toPromise();
  }

  updateMapLoadStatus(mapReady: boolean) {
    this.mapLoadStatus.next(mapReady);
    this.mapLoadStatus.complete();
    this.initializeValues();
  }
}

@Component({
  selector: 'app-zipcode-map-batch-export',
  templateUrl: './zipcode-map-batch-export.component.html',
  styleUrls: ['./zipcode-map-batch-export.component.scss'],
})
export class ZipcodeMapBatchExportComponent implements AfterViewInit {

  @ViewChild('AgmMap') agmMap: AgmMap;
  @Input() mapData: GmapMarker[];
  @Input() fromPdf: boolean;
  selectedMarker: GmapMarker;
  previous: any;

  constructor(private agmHelper: AgmHelperBatchExportService) {
  }

  clickedMarker(data: GmapMarker, infoWindow: any) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infoWindow;
    this.selectedMarker = data;
  }

  ngAfterViewInit(): void {
    this.agmMap.tilesLoaded.subscribe(() => {
      if (this.fromPdf) {
        this.agmHelper.updateMapLoadStatus(true);
      }
    });
  }
}

