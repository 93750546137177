<div id="cpt-toggle-wrapper">
  <div id="display-view-type" *ngIf="displayViewType">
    <img src="assets/left_arrow.svg" *ngIf="shouldDisplayBackArrow" (click)="navigateBack()"
      id="back-arrow"/>
    <span id="display-view-type-name">{{viewTypeDisplayName}}</span>
  </div>
  <div id="toggle-view-type" (click)="toggleShowOptions()" (blur)="hideOptions()" tabindex="0"
    *ngIf="cptOptionsVisible.length >= 2">
    <span id="text-view-by" (click)="$event.stopPropagation()">View by</span>
    <span id="text-view-name">{{viewTypeName}}
  </span></div>
  <div id="view-type-list" *ngIf="showOptions && cptOptionsVisible.length >= 2">
    <div *ngFor="let option of cptOptionsVisible; let i = index">
      <div class="view-type-option" (mousedown)="chooseViewType(i)">{{option}}</div>
    </div>
  </div>
</div>
