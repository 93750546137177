import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {select} from '@angular-redux/store';
import {FilterCriteria} from '../store/IAppState';
import {getAppConfigValue, isFeatureEnabled} from '../shared/helpers';
import * as _ from 'lodash';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationConfigurationSetting, EmDimension, FeatureToggleSetting, PdfExportData, UserProfile} from '../shared/models';
import {BatchExportDialogComponent} from '../batch-export/batch-export-dialog/batch-export-dialog.component';
import {ExportDialogComponent} from '../export-dialog/export-dialog.component';
import {MultiProviderExportRedesignComponent} from
    '../batch-export/multi-provider-export-redesign/multi-provider-export-redesign.component';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {getDepartmentTagId} from '../shared/ontology-helpers';

const EXPORT_DIALOG_PANEL_CLASS = 'export-dialog';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent implements OnInit, OnDestroy {

  pageLoading = true;
  showOptions = false;
  multiProviderExportEnabled = false;
  canShowHoverMessage = false;
  canShowRedesignMultiproviderExport = false;
  limitExportedProviders: boolean;
  hoverMessage: string;

  @Output() afterPdfExport = new EventEmitter<[PdfExportData, EmDimension[], boolean]>();

  @select(['filters'])
  private readonly filters$: Observable<FilterCriteria>;
  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;
  @select(['data', 'applicationConfigurationSettings'])
  private readonly applicationConfigurationSettings$: Observable<ApplicationConfigurationSetting[]>;
  @select(['data', 'userProfile'])
  private readonly userProfile$: Observable<UserProfile>;
  @select(['display', 'selectedProviders'])
  private readonly selectedProviders$: Observable<number>;

  settingSubscription: Subscription;
  pdfExportSubscription: Subscription;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
    this.settingSubscription = combineLatest([
      this.filters$.pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2))),
      this.featureToggleSettings$,
      this.applicationConfigurationSettings$,
      this.userProfile$,
      this.selectedProviders$])
      .subscribe(([filters, featureToggleSettings, appConfig, userProfile, selectedProviders]:
                    [FilterCriteria, FeatureToggleSetting[], ApplicationConfigurationSetting[], UserProfile, number]) => {
        this.pageLoading = true;
        this.canShowRedesignMultiproviderExport =
          isFeatureEnabled(FeatureToggleEntries.REDESIGN_MULTIPROVIDER_EXPORT, featureToggleSettings, userProfile);
        this.limitExportedProviders =
          isFeatureEnabled(FeatureToggleEntries.LIMIT_EXPORTED_PROVIDERS, featureToggleSettings, userProfile);
        const maxProviders = +getAppConfigValue(AppConfigEntries.PROVIDER_LIMIT_IN_MPE, appConfig) || 0;
        this.multiProviderExportEnabled = this.setShouldEnableExport(filters.nodePath, selectedProviders, maxProviders);
        this.hoverMessage = 'Please select ' +
          (this.limitExportedProviders ? `up to ${maxProviders} ` : '') +
          'providers from a single department to use Multi Provider Export';
      });
  }

  ngOnDestroy(): void {
    this.pdfExportSubscription?.unsubscribe();
    this.settingSubscription?.unsubscribe();
  }

  setShouldEnableExport(nodePath: string, selectedProviders: number, maxProviders: number): boolean {
    if (this.limitExportedProviders && selectedProviders > maxProviders) {
      return false;
    }

    const individualNodePaths: string[] = nodePath.split('|');
    const firstNodePath = individualNodePaths[0];

    if (firstNodePath.split('\\').length === 2) {
      return false;
    }

    return !individualNodePaths.find(path => getDepartmentTagId(path) !== getDepartmentTagId(firstNodePath));
  }

  toggleShowHover(show: boolean): void {
    this.canShowHoverMessage = !this.multiProviderExportEnabled && show;
  }

  openExportOptions(show: boolean): void {
    this.showOptions = show;
  }

  deactivateShowOptions(): void {
    this.showOptions = false;
  }

  openPageExport(): void {
    const dialogRef = this.dialog.open(ExportDialogComponent, {panelClass: EXPORT_DIALOG_PANEL_CLASS});
    // @ts-ignore
    this.pdfExportSubscription = dialogRef.componentInstance.afterPdfExport
      .subscribe((exportData: [PdfExportData, EmDimension[], boolean]) => {
        this.afterPdfExport.emit(exportData);
      });
  }

  openMultiProviderExport(): void {
    if (!this.multiProviderExportEnabled) {
      return;
    }
    if (this.canShowRedesignMultiproviderExport) {
      this.dialog.open(MultiProviderExportRedesignComponent, {panelClass: 'mpe-container'});
    } else {
      this.dialog.open(BatchExportDialogComponent);
    }
  }
}
