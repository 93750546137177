<div id="tab-navigation-wrapper" class=" tab-navigation-wrapper">
  <div id="tab-navigator" class="productivity-view-modifier">
    <nav>
      <a *ngFor="let tab of tabs"
         id="{{tab.selector}}"
         [ngClass]="{active: chosenTab === tab.dimension, disabled: tab.disabled}"
         (click)="chooseTab(tab.dimension)"
         [matTooltip]="tab.tooltipMessage"
         [matTooltipPosition]="'above'"
         [matTooltipDisabled]="!tab.tooltipMessage"
      >{{tab.label}}</a>
    </nav>
  </div>
</div>
