import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../../models';

@Component({
  selector: 'app-confirm-delete-custom-group',
  templateUrl: './acknowledge-dialog.component.html',
  styleUrls: ['./acknowledge-dialog.component.scss']
})
export class AcknowledgeDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  acknowledge(): void {
    if (this.data.confirmCallback) {
      this.data.confirmCallback();
    }
  }
}
