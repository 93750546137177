import {Injectable, InjectionToken} from '@angular/core';
import {FilterCriteria} from '../../store/IAppState';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.local';
import {MonthProductivityData} from '../../shared/models';
import {getRequestBodyFromFilter, SummaryType} from '../../shared/helpers';

export const ProductivityMonthServiceToken =
  new InjectionToken<ProductivityMonthService>('Productivity Month Service');

export interface ProductivityMonthService {
  getMonthProductivity(filter: FilterCriteria, summaryType?: SummaryType): Observable<MonthProductivityData>;
}

@Injectable()
export class HttpProductivityMonthService implements ProductivityMonthService {

  constructor(private httpClient: HttpClient) {
  }

  getMonthProductivity(filterCriteria: FilterCriteria, summaryType: SummaryType = SummaryType.SELECTED_PERIOD):
    Observable<MonthProductivityData> {
    const body = getRequestBodyFromFilter(filterCriteria);
    const url = `${environment.api}/api/monthProductivitiesByNodePath`;
    return this.httpClient.post<MonthProductivityData>(
      url,
      body,
      {
        params: new HttpParams()
          .set('memberKey', filterCriteria.memberKey.toString())
          .set('summaryType', summaryType.toString())
      }
    );
  }
}
