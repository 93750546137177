import {Inject, Injectable, InjectionToken} from '@angular/core';
import {MemberService, MemberServiceToken} from './member.service';
import {IAppState, NewFeatures, UserPreference} from '../store/IAppState';
import {NgRedux, select} from '@angular-redux/store';
import {
  allUserPreferenceLoadedTo,
  featureToggleSettingsLoadedTo,
  membersLoadedTo,
  newFeaturesLoadedTo,
  userMemberLoadedTo,
  userSecurityLoadedTo
} from '../store/actions';
import {merge, Observable} from 'rxjs';
import {first, map, take} from 'rxjs/operators';
import {FeatureToggleSetting, MemberData, Members, UserMemberData, UserSecurity} from '../shared/models';
import {noAccessRoute} from '../routing';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {HttpUserSecurityService, UserSecurityServiceToken} from './user-security.service';
import {NewFeaturesService, NewFeaturesServiceToken} from './new-features.service';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {UserPreferenceService, UserPreferenceServiceToken} from './user-preference.service';
import {FeatureToggleServiceToken, HttpFeatureToggleService} from './feature-toggle.service';
import {MemberBillingAreaService, MemberBillingAreaServiceToken} from './member-billing-area.service';
import {updateSelectedMember} from '../shared/helpers';
import {ExternalRequestService} from './external-request.service';
import {ExternalRequestDetail} from '../shared/models';
import {requestDetailLoadedTo} from '../store/actions';

export const MemberListenerServiceToken = new InjectionToken<MemberListenerService>('MemberListenerService');

export interface MemberListenerService {
  onInit(): void;
}

@Injectable()
export class MemberListenerServiceImpl implements MemberListenerService {

  @select(['oktaToken'])
  private readonly oktaToken$: Observable<string>;

  constructor(
    @Inject(MemberServiceToken) private readonly memberService: MemberService,
    private readonly ngRedux: NgRedux<IAppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly externalRequestService: ExternalRequestService,
    @Inject(UserPreferenceServiceToken) private readonly userPreferenceService: UserPreferenceService,
    @Inject(UserSecurityServiceToken) private userSecurityService: HttpUserSecurityService,
    @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
    @Inject(NewFeaturesServiceToken) private readonly newFeaturesService: NewFeaturesService,
    @Inject(FeatureToggleServiceToken) private readonly featureToggleService: HttpFeatureToggleService,
    @Inject(MemberBillingAreaServiceToken) private readonly memberBillingAreaService: MemberBillingAreaService
  ) {
    this.onInit();
  }

  onInit(): void {
    this.oktaToken$.subscribe((token: string) => {
      if (!token) {
        return;
      }

      this.featureToggleService.getFeatureToggleSettings().subscribe((settings: FeatureToggleSetting[]) => {
        this.ngRedux.dispatch(featureToggleSettingsLoadedTo(settings));
      });

      this.newFeaturesService.getNewFeatures().subscribe((newFeatures: NewFeatures) => {
        this.ngRedux.dispatch(newFeaturesLoadedTo(newFeatures));
      });

      this.userPreferenceService.getAllUserPreferences().subscribe((userPreferences: UserPreference[]) => {
        this.ngRedux.dispatch(allUserPreferenceLoadedTo(userPreferences));
        this.userSecurityService.getUserSecurity().subscribe((userSecurity: UserSecurity[]) => {
          this.ngRedux.dispatch(userSecurityLoadedTo(userSecurity));
          this.memberService.getMembers().subscribe((members: Members) => {
            this.ngRedux.dispatch(membersLoadedTo(members));
            if (!members || !members.members || members.members.length === 0) {
              this.router.navigateByUrl(noAccessRoute);
              return;
            }
            const memberIds = members.members.map(m => m.memberKey).join('|');
            this.userPreferenceService.getUserMemberData(memberIds).subscribe((userMemberData: UserMemberData[]) => {
              this.ngRedux.dispatch(userMemberLoadedTo(userMemberData));

              const requestId = this.ngRedux.getState().filters.externalRequestId;
              if (requestId) {
                this.externalRequestService.initializeRequestDetails(requestId).subscribe((requestDetail: ExternalRequestDetail) => {
                  this.ngRedux.dispatch(requestDetailLoadedTo(requestDetail));
                  if (requestDetail) {
                    const matchingMember = members.members.find(m => m.memberKey === requestDetail.memberKey);
                    if (!matchingMember) {
                      this.router.navigateByUrl(noAccessRoute);
                    } else {
                      this.updateSelectedMemberAndHandleSecondaryMembers(matchingMember, members);
                    }
                  } else {
                    this.setDefaultMember(userPreferences, members);
                  }
                });
              } else {
                this.setDefaultMember(userPreferences, members);
              }
            });
          });
        });
      });
    });
  }

  private setDefaultMember(userPreferences: UserPreference[], members: Members): void {
    const userPreferencesForDefaultMember = userPreferences.find(x => x.isDefault);
    const defaultMemberKey = userPreferencesForDefaultMember && userPreferencesForDefaultMember.memberKey;
    const member = members.members.find(m => m.memberKey === defaultMemberKey)
      || members.members.find(m => m.memberKey === 666)
      || members.members[0];
    this.updateSelectedMemberAndHandleSecondaryMembers(member, members);
  }

  private updateSelectedMemberAndHandleSecondaryMembers(member: MemberData, members: Members) {
    this.userSecurityService.doesUserHaveAccessToMember(member.memberKey).subscribe(userHasAccess => {
      if (userHasAccess) {
        updateSelectedMember(member, this.memberService, this.memberBillingAreaService, this.ngRedux);
        return;
      }
      const remainingMembers = members.members.filter(mem => mem.memberKey !== member.memberKey);
      this.handleSecondaryMembers(remainingMembers);
    });
  }

  private handleSecondaryMembers(members: MemberData[]) {
    if (members.length === 0) {
      this.router.navigateByUrl(noAccessRoute);
      return;
    }

    const userAccessValues$ =
      members.map(m => this.userSecurityService.doesUserHaveAccessToMember(m.memberKey)
        .pipe(map(a => [a, m]), take(1)));

    merge(...userAccessValues$)
      .pipe(first((v) => v[0] === true, [])).subscribe((v) => {
      if (v.length === 2) {
        updateSelectedMember(v[1] as MemberData, this.memberService, this.memberBillingAreaService, this.ngRedux);
      } else {
        this.analyticsService.handleGoogleAnalytics('No Access', 'Navigate to No Access Page', 'No Access');
        this.router.navigateByUrl(noAccessRoute);
      }
    });
  }
}
