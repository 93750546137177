import {ManageGroupingsParentComponent} from './FilterBannerModels';
import {CustomGroupsDataAllFilters} from '../shared/models';
import {CptGroup} from '../clinical-fingerprint/procedure-summary/ProcedureSummaryModels';
import {FilterCriteria} from '../store/IAppState';
import {IAppState} from '../store/IAppState';
import {DatasetType} from '../shared/enums';
import {BehaviorSubject} from 'rxjs';
import {determineAllowedDataset} from '../shared/compare-helpers';

export interface ManageableGroup {
  name: string;
  id: number;
  isDefault: boolean;
  memberKey: number;
}

export const DEFAULT_MAX_PRELOAD_GROUPS = 3;

export interface ChangeableCustomGroup {
  original: ManageableGroup;
  newName: string;
  newDefault: boolean;
  newPreload: boolean;
  duplicateAttempt: boolean;
}

export interface ManageGroupingsInfo {
  changeableCustomGroups: ChangeableCustomGroup[];
  hasManageableChanges: (group: ChangeableCustomGroup) => boolean;
  component: ManageGroupingsParentComponent;
  preloadable: boolean;
  dialogActionListener: BehaviorSubject<GroupDialogEvent>;
}

export function convertFilterGroupsToChangeableGroups(groups: CustomGroupsDataAllFilters[]): ChangeableCustomGroup[] {
  return groups.map(x => {
    return {
      original: x,
      newName: x.name,
      newDefault: x.isDefault,
      newPreload: x.preload,
      duplicateAttempt: false
    };
  });
}

export function convertCptGroupsToChangeableGroups(groups: CptGroup[]): ChangeableCustomGroup[] {
  return groups.map(x => {
    return {
      original: x,
      newName: x.name,
      newDefault: x.isDefault,
      newPreload: false,
      duplicateAttempt: false
    };
  });
}

// TODO: Is general a good word? It should not be an export function because it is not used anywhere else.
function hasManageableGeneralChanges(group: ChangeableCustomGroup): boolean {
  return group.newName !== group.original.name ||
    group.newDefault !== group.original.isDefault;
}

export function hasManageableFilterChanges(group: ChangeableCustomGroup): boolean {
  return hasManageableGeneralChanges(group) ||
    group.newPreload !== (group.original as CustomGroupsDataAllFilters).preload;
}

export function hasManageableCptChanges(group: ChangeableCustomGroup): boolean {
  return hasManageableGeneralChanges(group);
}

export function createNewCustomGroupFromFilter(filterCriteria: FilterCriteria, isDefault: boolean, preload: boolean,
                                               name: string, dataSet: DatasetType): CustomGroupsDataAllFilters {
  return {
    id: 0,
    name: name,
    description: name,
    nodePath: filterCriteria.nodePath,
    isDefault: isDefault,
    preload: preload,
    memberKey: filterCriteria.memberKey,
    payerCategoryKey: filterCriteria.payerCategory.payerCategoryKey,
    nationalPayerKey: filterCriteria.nationalPayerKey,
    memberPayerKey: filterCriteria.memberPayerKey,
    payerType: filterCriteria.payerType,
    memberLocationKey: filterCriteria.memberLocation.memberLocationKey,
    memberBillingAreaKey: filterCriteria.memberBillingArea.memberBillingAreaKey,
    invoiceStatus: filterCriteria.invoiceStatus,
    lagKey: filterCriteria.lagKey,
    startDateInMonths: (filterCriteria.dateRange.startYear * 12)
      + filterCriteria.dateRange.startMonth,
    endDateInMonths: (filterCriteria.dateRange.endYear * 12)
      + filterCriteria.dateRange.endMonth,
    dateRangeOption: filterCriteria.dateRange.selectedDateRangeOption,
    telehealthFlag: filterCriteria.telehealthFlag,
    memberLocationKeys: filterCriteria.memberLocationKeys,
    cptFamilyDesc: filterCriteria.cptFamilyFilter,
    cptRangeDesc: filterCriteria.cptRangeFilter,
    dataSet: dataSet
  };
}

export function createNewCustomGroupFromState(state: IAppState): CustomGroupsDataAllFilters {
  return {
    id: 0,
    name: '',
    description: '',
    nodePath: state.filters.nodePath,
    memberKey: state.filters.memberKey,
    payerCategoryKey: state.filters.payerCategory.payerCategoryKey,
    nationalPayerKey: state.filters.nationalPayerKey,
    memberPayerKey: state.filters.memberPayerKey,
    payerType: state.filters.payerType,
    memberLocationKey: state.filters.memberLocation.memberLocationKey,
    memberBillingAreaKey: state.filters.memberBillingArea.memberBillingAreaKey,
    invoiceStatus: state.filters.invoiceStatus,
    lagKey: state.filters.lagKey,
    startDateInMonths: (state.filters.dateRange.startYear * 12)
      + state.filters.dateRange.startMonth,
    endDateInMonths: (state.filters.dateRange.endYear * 12)
      + state.filters.dateRange.endMonth,
    dateRangeOption: state.filters.dateRange.selectedDateRangeOption,
    telehealthFlag: state.filters.telehealthFlag,
    memberLocationKeys: state.filters.memberLocationKeys,
    cptFamilyDesc: state.filters.cptFamilyFilter,
    cptRangeDesc: state.filters.cptRangeFilter,
    isDefault: false,
    preload: false,
    dataSet: determineAllowedDataset(state)
  };
}

export interface SaveGroupsCallbacks {
  group: ManageableGroup;
  existingGroups: ManageableGroup[];
  onSave?: (group?: ManageableGroup) => void;
  dialogActionListener?: BehaviorSubject<GroupDialogEvent>;
}

export enum GroupDialogEvent {
  INITIAL,
  APPLY,
  TENTATIVE_SAVE,
  DEFINITE_SAVE,
  UPDATE,
  DELETE,
  DELETE_CURRENTLY_SELECTED,
  MANAGE_SAVE
}
