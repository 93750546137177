<div class="graph-container">
  <div class="inner-graph-container">
    <app-productivity-summary-by-month-chart
      [showProgressBar]="showProgressBar"
      page="Month"
      [showVariableMenu]="true"
      [variables]="variables"
      [isSpecialty]="isSpecialty"
      [isDepartment]="isDepartment"
      [isProvider]="isProvider"
      [level]="level"
    ></app-productivity-summary-by-month-chart>
  </div>
</div>
<app-wrvu-summary-data-table></app-wrvu-summary-data-table>
<app-data-table id="wrvu-trend-table"
                [receivedData]="tableMonth"
                [tableCssClass]="'wrvu-trend-table'"
                [displayedColumns]="displayedColumns"
                [defaultSortColumn]="defaultSortColumn"
                sortDirection="asc"
                [showLevel]="false"
                tableTitle="wRVUs by Month"
                [whenSortChanged]="whenSortChanged"
                [sortingDataAccessor]="sortingDataAccessor"
                [showProgressBar]="showProgressBar"
                [currentPage]="currentPage"
                [originalColumns]="columns"
                [addRemoveOption]="true"
                [reducerAction]="trendWrvuColumnsChangedTo">
</app-data-table>
