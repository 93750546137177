import {Component, Input, OnChanges} from '@angular/core';
import {
  CptCategories,
  CptSummary,
  EmDimension,
  EvaluationManagementMultilevel,
  Legend,
  ProviderMultilevelCptCounts
} from '../../../../shared/models';
import {
  EvaluationManagementOutpatientMultilevelRow,
  getDefaultCptSummary,
  getEmSnapshotColumnsWithExtendedBenchmarkOptions,
  getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions,
} from '../../../../evaluation-management/em-helpers';
import {BenchmarkOption, MultilevelTab} from '../../../../shared/enums';
import {DataTableColumns} from '../../../../shared/data-table-columns';
import {getEmLegends} from '../../../../evaluation-management/em-chart-helpers';
import {MetricType} from '../../../../shared/metric-types';

@Component({
  selector: 'app-em-outpatient-batch-export',
  templateUrl: './em-outpatient-batch-export.component.html',
  styleUrls: ['./em-outpatient-batch-export.component.scss']
})
export class EmOutpatientBatchExportComponent implements OnChanges {
  @Input() totalCptCounts?: CptCategories;
  @Input() specialtyCptCounts?: CptCategories;
  @Input() chartWidth: number;
  @Input() chartHeight: number;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() receivedTableData: EvaluationManagementOutpatientMultilevelRow[];
  @Input() batchMultilevelEMData: EvaluationManagementMultilevel | undefined = undefined;
  @Input() showTable = false;
  columns: DataTableColumns[] = [];
  tableData: ProviderMultilevelCptCounts[] = [];
  MultilevelTab = MultilevelTab;

  totalNewPatientsCptSummaries: (CptSummary | undefined)[];
  totalEstablishedPatientsCptSummaries: (CptSummary | undefined)[];
  totalConsultationsCptSummaries: (CptSummary | undefined)[];
  specialtyTotalNewPatientsCptSummaries: (CptSummary | undefined)[];
  specialtyTotalEstablishedPatientsCptSummaries: (CptSummary | undefined)[];
  specialtyTotalConsultationsCptSummaries: (CptSummary | undefined)[];
  newPatientVisitsCategories = ['99201', '99202', '99203', '99204', '99205'];
  establishedPatientVisitsCategories = ['99211', '99212', '99213', '99214', '99215'];
  consultationsCategories = ['99241', '99242', '99243', '99244', '99245'];
  newPatientsMaxValue = 0;
  establishedPatientsMaxValue = 0;
  consultationsMaxValue = 0;
  specialtyNewPatientsMaxValue = 0;
  specialtyEstablishedPatientsMaxValue = 0;
  specialtyConsultationsMaxValue = 0;
  maxNewPatientYAxisValue: number;
  maxEstablishedPatientPatientYAxisValue: number;
  maxconsultationsYAxisValue: number;
  legends: Legend[];
  metricType = MetricType.EandMOutpatient;

  ngOnChanges(): void {
    this.legends = getEmLegends(this.foundNodeName, true);
    this.setUpTable();
    this.chartHeight = 250;

    if (!this.totalCptCounts) {
      this.totalNewPatientsCptSummaries = [];
      this.totalEstablishedPatientsCptSummaries = [];
      this.totalConsultationsCptSummaries = [];
      this.newPatientVisitsCategories.forEach(x => {
        this.totalNewPatientsCptSummaries.push(getDefaultCptSummary());
      });

      this.establishedPatientVisitsCategories.forEach(x => {
        this.totalEstablishedPatientsCptSummaries.push(getDefaultCptSummary());
      });

      this.consultationsCategories.forEach(x => {
        this.totalConsultationsCptSummaries.push(getDefaultCptSummary());
      });
      return;
    }

    if (!this.specialtyCptCounts) {
      this.specialtyTotalNewPatientsCptSummaries = [];
      this.specialtyTotalEstablishedPatientsCptSummaries = [];
      this.specialtyTotalConsultationsCptSummaries = [];
      this.newPatientVisitsCategories.forEach(x => {
        this.specialtyTotalNewPatientsCptSummaries.push(getDefaultCptSummary());
      });

      this.establishedPatientVisitsCategories.forEach(x => {
        this.specialtyTotalEstablishedPatientsCptSummaries.push(getDefaultCptSummary());
      });

      this.consultationsCategories.forEach(x => {
        this.specialtyTotalConsultationsCptSummaries.push(getDefaultCptSummary());
      });

      this.specialtyNewPatientsMaxValue = 0;
      this.specialtyEstablishedPatientsMaxValue = 0;
      this.consultationsMaxValue = 0;
    }


    if (this.totalCptCounts.newPatientVisit) {
      this.totalNewPatientsCptSummaries = [
        this.totalCptCounts.newPatientVisit.cpt99201,
        this.totalCptCounts.newPatientVisit.cpt99202,
        this.totalCptCounts.newPatientVisit.cpt99203,
        this.totalCptCounts.newPatientVisit.cpt99204,
        this.totalCptCounts.newPatientVisit.cpt99205,
      ];
      this.newPatientsMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalNewPatientsCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.newPatientVisit) {
      this.specialtyTotalNewPatientsCptSummaries = [
        this.specialtyCptCounts.newPatientVisit.cpt99201,
        this.specialtyCptCounts.newPatientVisit.cpt99202,
        this.specialtyCptCounts.newPatientVisit.cpt99203,
        this.specialtyCptCounts.newPatientVisit.cpt99204,
        this.specialtyCptCounts.newPatientVisit.cpt99205,
      ];
      this.specialtyNewPatientsMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalNewPatientsCptSummaries,
          this.benchmarkOption);
    }

    if (this.totalCptCounts.establishedPatientVisit) {
      this.totalEstablishedPatientsCptSummaries = [
        this.totalCptCounts.establishedPatientVisit.cpt99211,
        this.totalCptCounts.establishedPatientVisit.cpt99212,
        this.totalCptCounts.establishedPatientVisit.cpt99213,
        this.totalCptCounts.establishedPatientVisit.cpt99214,
        this.totalCptCounts.establishedPatientVisit.cpt99215,
      ];
      this.establishedPatientsMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalEstablishedPatientsCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.establishedPatientVisit) {
      this.specialtyTotalEstablishedPatientsCptSummaries = [
        this.specialtyCptCounts.establishedPatientVisit.cpt99211,
        this.specialtyCptCounts.establishedPatientVisit.cpt99212,
        this.specialtyCptCounts.establishedPatientVisit.cpt99213,
        this.specialtyCptCounts.establishedPatientVisit.cpt99214,
        this.specialtyCptCounts.establishedPatientVisit.cpt99215,
      ];
      this.specialtyEstablishedPatientsMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalEstablishedPatientsCptSummaries,
          this.benchmarkOption);
    }
    if (this.totalCptCounts.consultation) {
      this.totalConsultationsCptSummaries = [
        this.totalCptCounts.consultation.cpt99241,
        this.totalCptCounts.consultation.cpt99242,
        this.totalCptCounts.consultation.cpt99243,
        this.totalCptCounts.consultation.cpt99244,
        this.totalCptCounts.consultation.cpt99245,
      ];
      this.consultationsMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalConsultationsCptSummaries,
          this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.consultation) {
      this.specialtyTotalConsultationsCptSummaries = [
        this.specialtyCptCounts.consultation.cpt99241,
        this.specialtyCptCounts.consultation.cpt99242,
        this.specialtyCptCounts.consultation.cpt99243,
        this.specialtyCptCounts.consultation.cpt99244,
        this.specialtyCptCounts.consultation.cpt99245,
      ];
      this.specialtyConsultationsMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.specialtyTotalConsultationsCptSummaries,
          this.benchmarkOption);
    }

    this.maxNewPatientYAxisValue = Math.max(this.newPatientsMaxValue, this.specialtyNewPatientsMaxValue);

    this.maxEstablishedPatientPatientYAxisValue = Math.max(this.establishedPatientsMaxValue,
      this.specialtyEstablishedPatientsMaxValue);
    this.maxconsultationsYAxisValue = Math.max(this.consultationsMaxValue, this.specialtyConsultationsMaxValue);
  }

  private setUpTable(): void {
    this.columns =
      getEmSnapshotColumnsWithExtendedBenchmarkOptions(EmDimension.Outpatient, this.benchmarkOption, MultilevelTab.BY_PROVIDER);
    this.tableData = this.batchMultilevelEMData?.evaluationManagementData.providerEvaluationManagementSnapshotData
      .nodes || [];
  }
}
