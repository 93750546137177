
<div id="wrap-viz-chart"> <div [chart]="chartObject" id="month-new-patient-visits-graph"></div> </div>
<app-chart-legend *ngIf="benchmarkOption"
        [metricType]="metricType.NewPatientVisits"
        [fromPdf]="true"
        [legends]="npvLegends"
        [hasBenchmarks]="hasBenchmarks"
        [showExtendedBenchmarkOptions]="true"
        [benchmarkOption]="benchmarkOption"
        [benchmarkPercentile]="benchmarkPercentile"
        [benchmarksToExclude]="benchmarkToExclude">
</app-chart-legend>
