export function isValid(value: any, field: string): boolean {
  return hasValue(value) && hasValue(value[field]);
}

export function isValidOrElse(value: any, field: string, property: string, fallbackValue: any, units: string = ''): any {
  if (isValid(value, field) && isValid(value[field], property)) {
    return (value[field])[property] + units;
  }
  return fallbackValue;
}

export function undefinedIfInvalid(object: any, ...args: string[]): any {
  return args.reduce((a: any, b: string) => isValid(a, b) && a[b], object) || undefined;
}

export function hasValue(x: any) {
  return x !== undefined && x !== null;
}

export function objectHasValue(x: Object): boolean {
  return x !== null && Object.keys(x).length > 0;
}

export function allDefined(...values: any): boolean {
  return !values.find((v: any) => !hasValue(v));
}

export function hasNonZeroValue(x: any) {
  return x !== undefined && x !== null && x !== 0;
}

export function checkForNulls(numberToCheck?: number | null) {
  return numberToCheck ? numberToCheck : 0;
}

export function undefinedIfZero(numberToCheck: any | number | null | undefined): number | undefined | any {
  return numberToCheck || undefined;
}

export function valuesAreDifferent(valueA: any, valueB: any): boolean {
  if (!hasValue(valueA) && !hasValue(valueB)) {
    return false;
  }
  if (hasValue(valueA) !== hasValue(valueB)) {
    return true;
  }
  return valueA !== valueB;
}
