import {AxisLabelsFormatterContextObject, PlotColumnOptions, PointClickEventObject, YAxisOptions} from 'highcharts/highstock';
import {Benchmark} from './services/graph';
import {GraphColors} from './colors';
import {concat} from '../shared/ourLodash';
import {BenchmarkHelper, BenchmarkPercentile, readableNameOfColumnDef} from '../shared/benchmark-types';
import {HasBenchmarks, Legend, MergedProductivityTrendEntry, Productivity} from '../shared/models';
import {
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyValues,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  maxOfSeries
} from '../shared/highcharts-helpers';
import {abbreviateAxisValue, roundToNumber} from './number-formatter';
import {ProductivitySnapshot} from './services/ProviderProductivity';
import {checkForNulls} from '../shared/null-helpers';
import {months, noop, toTitleCase} from '../shared/helpers';
import {IndividualSeriesOptions, OptionsUpdated} from '../shared/highcharts-mappings';
import {FilterCriteria} from '../store/IAppState';
import {HighChartColorIndex, LegendColor, LegendStyle} from '../shared/enums';
import {MetricType} from '../shared/metric-types';

export const CHART_HEIGHT = 450;
export const NUMBER_OF_CHARACTERS_TO_TRUNCATE = 36;

export abstract class ProductivityGraphComponent {

  private static readonly XAxisMinBars = 0;

  private snapshotBarCount = 20;
  private chartWidth = calculateChartWidth(DEFAULT_POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING);

  private barChartSeries: PlotColumnOptions = {
    cropThreshold: 100,
    pointPadding: DEFAULT_POINT_PADDING,
    minPointLength: 2
  };

  protected constructor() {
  }

  getTrendGraphOptions<T extends HasBenchmarks & Productivity>(
    showChargeGraph: boolean,
    showPreviousTimePeriodGraph: boolean,
    showCfteAdjustedWrvus: boolean,
    benchmarkPercentile: BenchmarkPercentile,
    makeGraphLabel: (value: MergedProductivityTrendEntry) => string,
    productivities: MergedProductivityTrendEntry[],
    maxNumberOfDataPointsForScrollBar: number,
    graphType: string,
    viewCommunityBenchmarks: boolean
  ): OptionsUpdated {
    const categories: string[] = createEmptyLabels(
      productivities.map((productivity) => makeGraphLabel(productivity)),
      maxNumberOfDataPointsForScrollBar,
    );
    const wrvuTitle: string = showCfteAdjustedWrvus ? 'cFTE Adj. wRVUs' : 'Actual wRVUs';
    const isScrolled: boolean = categories.length > maxNumberOfDataPointsForScrollBar;

    const leftSeries: IndividualSeriesOptions[] = showCfteAdjustedWrvus
      ? concat(
        this.wRVUsData(productivities, showCfteAdjustedWrvus, noop, maxNumberOfDataPointsForScrollBar),
        this.benchmarkData(
          this.getBenchmarkFrom(benchmarkPercentile, productivities, makeGraphLabel, viewCommunityBenchmarks),
          categories, benchmarkPercentile, viewCommunityBenchmarks),
        showPreviousTimePeriodGraph ? this.previousTimePeriodData(productivities, noop, maxNumberOfDataPointsForScrollBar) : []
      )
      : this.wRVUsData(productivities, showCfteAdjustedWrvus, noop, maxNumberOfDataPointsForScrollBar);

    const rightSeries: IndividualSeriesOptions[] = showChargeGraph ? this.chargeData(productivities) : [];

    return {
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 0,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: graphType,
        backgroundColor: 'transparent',
        width: this.chartWidth,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: categories,
        crosshair: {
          color: GraphColors.hoverBackground,
        },
        gridLineWidth: 0,
        min: ProductivityGraphComponent.XAxisMinBars,
        max: isScrolled ? maxNumberOfDataPointsForScrollBar - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            const numberOfCharacters = NUMBER_OF_CHARACTERS_TO_TRUNCATE;
            const originalXAxisName = this.value.toString();
            if (originalXAxisName.length <= numberOfCharacters) {
              return originalXAxisName;
            }
            return originalXAxisName.substring(0, numberOfCharacters - 3) + '...';
          },
          rotation: 45,
        },
      },
      yAxis: concat(
        this.axis(wrvuTitle, leftSeries),
        this.axis('Charges', rightSeries, true),
      ),
      plotOptions: {
        column: this.barChartSeries,
      },
      scrollbar: {
        enabled: isScrolled,
        showFull: false,
      },
      series: concat(leftSeries, rightSeries),
    };
  }

  drawSnapshotGraph<T extends ProductivitySnapshot>(
    showChargeGraph: boolean,
    showCfteAdjustedWrvus: boolean,
    benchmarkPercentile: BenchmarkPercentile,
    makeGraphLabel: (value: ProductivitySnapshot) => string,
    productivities: ProductivitySnapshot[],
    onCategorySelected: (snapshot: ProductivitySnapshot) => void,
    maxNumberOfDataPointsForScrollBar: number,
    graphType: string,
    viewCommunityBenchmarks: boolean
  ): OptionsUpdated {
    const categories: string[] = createEmptyLabels(
      productivities.map((productivity) => makeGraphLabel(productivity)),
      maxNumberOfDataPointsForScrollBar,
    );
    const wrvuTitle: string = showCfteAdjustedWrvus ? 'cFTE Adj. wRVUs' : 'Actual wRVUS';
    const isScrolled: boolean = categories.length > maxNumberOfDataPointsForScrollBar;
    const wrvusData =
      this.wRVUsData(productivities, showCfteAdjustedWrvus, onCategorySelected, maxNumberOfDataPointsForScrollBar);

    const leftSeries: IndividualSeriesOptions[] = showCfteAdjustedWrvus
      ? concat(
        wrvusData,
        this.benchmarkData(
          this.getSnapshotBenchmarkFrom(benchmarkPercentile, productivities, makeGraphLabel, viewCommunityBenchmarks),
          categories,
          benchmarkPercentile,
          viewCommunityBenchmarks
        ))
      : wrvusData;

    const rightSeries: IndividualSeriesOptions[] = showChargeGraph ? this.snapshotChargeData(productivities) : [];

    return {
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 0,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: graphType,
        backgroundColor: 'transparent',
        width: this.chartWidth,
        height: CHART_HEIGHT,
        styledMode: true,
        marginRight: 100
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: categories,
        // @ts-ignore
        clickOnCrosshair: function (e: any, p: any) {
          onCategorySelected(productivities[p.x]);
        },
        crosshair: {
          color: GraphColors.hoverBackground,
        },
        gridLineWidth: 0,
        min: ProductivityGraphComponent.XAxisMinBars,
        max: isScrolled ? maxNumberOfDataPointsForScrollBar - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            let ret = '';
            const tokenized = this.value.toLocaleString().split(' ');
            for (let i = 0; i < tokenized.length; i++) {
              ret = ret.concat(tokenized[i] + ' ');
              if (i === roundToNumber(tokenized.length / 2, 0) - 1) {
                ret = ret.concat('<br/>');
              }
            }
            return ret;
          },
          rotation: 45,
        },
      },
      yAxis: concat(
        this.axis(wrvuTitle, leftSeries),
        this.axis('Charges', rightSeries, true),
      ),
      plotOptions: {
        column: this.barChartSeries,
      },
      scrollbar: {
        enabled: isScrolled,
        showFull: false,
      },
      series: concat(leftSeries, rightSeries),
    };
  }

  getTooltipName(productivity: any) {
    return (productivity.providerNodeName ? productivity.providerNodeName + '<br/>' : '')
      + (productivity.specialtyNodeName ? productivity.specialtyNodeName + '<br/>' : '')
      + (productivity.departmentNodeName || productivity.nodeName);
  }


  drawVarianceWrvuSnapshotGraph(
    data: ProductivitySnapshot[],
    onCategorySelected: (snapshot: Productivity) => void,
    benchmarkPercentile: BenchmarkPercentile,
    viewCommunityBenchmarks: boolean,
    maxNumberScrollBars: number): OptionsUpdated {
    const varianceData: any[] = [];
    let max = 0;
    let min = 0;
    data.forEach(x => {
      const varianceValue = this.getVarianceForVarianceGraph(x, benchmarkPercentile, viewCommunityBenchmarks)
        || 0;
      if (varianceValue !== 0 && varianceValue > max) {
        max = varianceValue;
      } else if (varianceValue !== 0 && varianceValue < min) {
        min = varianceValue;
      }
      varianceData.push({
        name: this.getTooltipName(x),
        y: varianceValue,
        colorIndex: varianceValue >= 0 ? HighChartColorIndex.GREEN : HighChartColorIndex.RED,
        tooltip: varianceValue >= 0 ? {
          pointFormat: '<span class="highcharts-color-12">\u25CF</span> ' +
            '{series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        } : {
          pointFormat: '<span class="highcharts-color-22">\u25CF</span> ' +
            '{series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      });
    });

    const categories = createEmptyLabels(
      data.map((x) => x.nodeName),
      maxNumberScrollBars,
    );
    const absoluteMax = roundToNumber(Math.max(...[Math.abs(max), Math.abs(min)]), 0);

    const isScrolled: boolean = categories.length > maxNumberScrollBars;
    const leftSideYAxis: YAxisOptions[] = [
      {
        title: {
          text: 'Variance',
        },
        max: absoluteMax,
        min: -1 * absoluteMax,
        tickInterval: absoluteMax / 10,
        labels: {
          formatter: function () {
            return roundToNumber(this.value, 0) + '';
          },
        },
        plotLines: [{
          color: '#000000',
          width: 200,
          value: 0,
          dashStyle: 'Solid',
          zIndex: 999
        }]
      },
    ];
    const varianceSeries: IndividualSeriesOptions[] = [
      {
        name: 'Variance from benchmark ' + readableNameOfColumnDef(benchmarkPercentile).toLowerCase(),
        yAxis: 0,
        type: ChartType.COLUMN,
        data: varianceData.length < maxNumberScrollBars ?
          createEmptyValues(varianceData, maxNumberScrollBars) :
          varianceData,
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(data[event.point.x]);
          },
        },
        pointWidth: DEFAULT_POINT_WIDTH,
        stacking: 'normal',
        stack: 0,
        zIndex: 0,
      }
    ];

    const options: OptionsUpdated = {
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },
      title: {text: ''},
      series: varianceSeries,
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 0,
      },
      chart: {
        type: ChartType.COLUMN,
        backgroundColor: 'transparent',
        width: 1304.8,
        styledMode: true,
        height: CHART_HEIGHT,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        max: isScrolled ? maxNumberScrollBars - 1 : categories.length - 1,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject<any>) {
            return toTitleCase(this.value.toString());
          },
          rotation: 45,
        },
      },
      yAxis: leftSideYAxis,
      scrollbar: {
        enabled: isScrolled,
        showFull:
          false
      },
      plotOptions: {
        column: {
          ...this.barChartSeries
        }
      },
    };
    return options;
  }

  getVarianceForVarianceGraph(ps: ProductivitySnapshot, benchmarkPercentile: BenchmarkPercentile,
                              viewCommunityBenchmarks: boolean): number | undefined | null {
    switch (benchmarkPercentile) {
      case BenchmarkPercentile.Mean:
        return viewCommunityBenchmarks ? ps.communityVarianceMean : ps.varianceMean;
      case BenchmarkPercentile.Percentile25th:
        return viewCommunityBenchmarks ? ps.communityVariance25thPercentile : ps.variance25thPercentile;
      case BenchmarkPercentile.Percentile50th:
        return viewCommunityBenchmarks ? ps.communityVariance50thPercentile : ps.variance50thPercentile;
      case BenchmarkPercentile.Percentile65th:
        return viewCommunityBenchmarks ? ps.communityVariance65thPercentile : ps.variance65thPercentile;
      case BenchmarkPercentile.Percentile75th:
        return viewCommunityBenchmarks ? ps.communityVariance75thPercentile : ps.variance75thPercentile;
      case BenchmarkPercentile.Percentile90th:
        return viewCommunityBenchmarks ? ps.communityVariance90thPercentile : ps.variance90thPercentile;
      default:
        return undefined;
    }
  }

  private getBenchmarkFrom<T extends HasBenchmarks>(
    benchmarkPercentile: BenchmarkPercentile,
    benchmarks: T[],
    makeLabelFrom: (productivity: T) => string,
    viewCommunityBenchmarks: boolean
  ): Benchmark {
    return {
      title: BenchmarkHelper.toWrvusLabel(benchmarkPercentile),
      graph: {
        entries: benchmarks.map(productivity => {
          let benchMarkValue = 0;
          switch (benchmarkPercentile) {
            case BenchmarkPercentile.Mean:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmarkMean
                : productivity.benchmarkMean;
              break;
            case BenchmarkPercentile.Percentile25th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark25thPercentile
                : productivity.benchmark25thPercentile;
              break;
            case BenchmarkPercentile.Percentile50th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark50thPercentile
                : productivity.benchmark50thPercentile;
              break;
            case BenchmarkPercentile.Percentile65th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark65thPercentile
                : productivity.benchmark65thPercentile;
              break;
            case BenchmarkPercentile.Percentile75th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark75thPercentile
                : productivity.benchmark75thPercentile;
              break;
            case BenchmarkPercentile.Percentile90th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark90thPercentile
                : productivity.benchmark90thPercentile;
              break;
          }

          return {
            label: makeLabelFrom(productivity),
            value: checkForNulls(benchMarkValue),
          };
        }),
      },
    };
  }

  private getSnapshotBenchmarkFrom<T extends HasBenchmarks>(
    benchmarkPercentile: BenchmarkPercentile,
    benchmarks: T[],
    makeLabelFrom: (productivity: T) => string,
    viewCommunityBenchmarks: boolean
  ): Benchmark {
    return {
      title: BenchmarkHelper.toWrvusLabel(benchmarkPercentile),
      graph: {
        entries: benchmarks.map(productivity => {
          let benchMarkValue = 0;
          switch (benchmarkPercentile) {
            case BenchmarkPercentile.Mean:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmarkMean
                : productivity.benchmarkMean;
              break;
            case BenchmarkPercentile.Percentile25th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark25thPercentile
                : productivity.benchmark25thPercentile;
              break;
            case BenchmarkPercentile.Percentile50th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark50thPercentile
                : productivity.benchmark50thPercentile;
              break;
            case BenchmarkPercentile.Percentile65th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark65thPercentile
                : productivity.benchmark65thPercentile;
              break;
            case BenchmarkPercentile.Percentile75th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark75thPercentile
                : productivity.benchmark75thPercentile;
              break;
            case BenchmarkPercentile.Percentile90th:
              benchMarkValue = viewCommunityBenchmarks
                ? productivity.communityBenchmark90thPercentile
                : productivity.benchmark90thPercentile;
              break;
          }
          return {
            label: makeLabelFrom(productivity),
            value: checkForNulls(benchMarkValue),
          };
        }),
      },
    };
  }

  private axis(title: string, series: any[], opposite: boolean = false): YAxisOptions[] {
    if (series.length > 0) {
      return [
        {
          title: {
            text: title,
          },
          opposite: opposite,
          min: 0,
          max: maxOfSeries(series),
          labels: {
            formatter: function (this: AxisLabelsFormatterContextObject<any>) {
              return abbreviateAxisValue(this.value);

            },
          },
        },
      ];
    }
    return [];
  }

  // noinspection JSMethodCanBeStatic
  private wRVUsData<T extends Productivity>(
    productivities: T[],
    showCfteAdjustedWrvus: boolean,
    onCategorySelected: (snapshot: T) => void,
    maxNumberOfDataPointsForScrollBar: number
  ): any[] {
    const seriesData: ([string, number] | number)[] =
      showCfteAdjustedWrvus
        ? productivities.map((productivity) => productivity.cfteAdjustedWRVUs)
        : productivities.map((productivity) => productivity.wRVUs);

    return [
      {
        name: showCfteAdjustedWrvus ? 'cFTE Adj. wRVUs' : 'Actual wRVUs',
        colorIndex: HighChartColorIndex.TEAL,
        yAxis: 0,
        data: seriesData.length < maxNumberOfDataPointsForScrollBar ?
          createEmptyValues(seriesData, maxNumberOfDataPointsForScrollBar) :
          seriesData,
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(productivities[event.point.x]);
          },
        },
        pointWidth: DEFAULT_POINT_WIDTH,
        tooltip: {
          pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      },
    ];
  }

  // noinspection JSMethodCanBeStatic
  private previousTimePeriodData(
    productivities: MergedProductivityTrendEntry[],
    onCategorySelected: (snapshot: MergedProductivityTrendEntry) => void,
    maxNumberOfDataPointsForScrollBar: number,
  ): any[] {
    const seriesData: number[] = productivities.map((productivity) => productivity.previousCfteAdjustedWRVUs || 0);

    return [
      {
        name: 'Previous Date Range cFTE adj. wRVUs',
        colorIndex: HighChartColorIndex.PURPLE,
        yAxis: 0,
        marker: {
          symbol: ChartMarkerSymbol.CIRCLE
        },
        data: seriesData.length < maxNumberOfDataPointsForScrollBar ?
          createEmptyValues(seriesData, maxNumberOfDataPointsForScrollBar) :
          seriesData,
        events: {
          click: (event: PointClickEventObject) => {
            onCategorySelected(productivities[event.point.x]);
          },
        },
        pointWidth: DEFAULT_POINT_WIDTH,
        tooltip: {
          pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      },
    ];
  }

  // noinspection JSMethodCanBeStatic
  private chargeData(productivities: Productivity[]): any[] {
    return [
      {
        name: 'Charges($)',
        colorIndex: HighChartColorIndex.ORANGE,
        type: ChartType.LINE,
        yAxis: 1,
        data: productivities.map(productivity => productivity.charges),
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-15">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        },
        marker: {
          symbol: ChartMarkerSymbol.CIRCLE
        },
      },
    ];
  }

  private snapshotChargeData(productivities: ProductivitySnapshot[]): any[] {
    return [
      {
        name: 'Charges($)',
        colorIndex: HighChartColorIndex.ORANGE,
        type: ChartType.LINE,
        yAxis: 1,
        data: productivities.map(productivity => productivity.charges ? productivity.charges : 0),
        zIndex: 1,
        tooltip: {
          pointFormat: '<span class="highcharts-color-15">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      },
    ];
  }

  private benchmarkData(
    benchmarkGraph: Benchmark,
    categories: string[],
    benchmarkPercentile: BenchmarkPercentile,
    viewCommunityBenchmarks: boolean
  ): any[] {
    const filteredBenchmarkGraph = benchmarkGraph.graph.entries.filter(entry => categories.indexOf(entry.label) > -1);
    const benchmarkData = filteredBenchmarkGraph.map(entry => entry.value);

    return [
      {
        name: BenchmarkHelper.toWrvusLabel(benchmarkPercentile, viewCommunityBenchmarks) || '',
        colorIndex: HighChartColorIndex.GREY,
        type: ChartType.LINE,
        yAxis: 0,
        data: benchmarkData,
        zIndex: 1,
        marker: {
          symbol: ChartMarkerSymbol.SQUARE
        },
        tooltip: {
          pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
          valueDecimals: 0
        }
      },
    ];
  }
}

function createEmptyLabels(seriesLabel: string[], maxNumberOfDataPointsForScrollBar: number) {
  for (let i = seriesLabel.length; i < maxNumberOfDataPointsForScrollBar; i++) {
    seriesLabel.push('');
  }
  return seriesLabel;
}

export function previousTimePeriodLegend(filters: FilterCriteria): Legend {
  return {
    text: 'Previous Date Range cFTE adj. wRVUs' + '\n' + getPtpDateRange(filters),
    color: LegendColor.PURPLE,
    metric: MetricType.WorkRVUs,
    style: LegendStyle.CIRCLE,
    showBenchmarkOptionControl: false,
    showPercentileControl: false
  };
}

function getPtpDateRange(filters: FilterCriteria) {
  const oneYearPriorStartYear = filters.dateRange.startYear - 1;
  const oneYearPriorEndYear = filters.dateRange.endYear - 1;
  const startMonthName = months[filters.dateRange.startMonth - 1];
  const endMonthName = months[filters.dateRange.endMonth - 1];
  return `${startMonthName} ${oneYearPriorStartYear} - ${endMonthName} ${oneYearPriorEndYear}`;
}
