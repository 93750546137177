<div class="row">

  <span *ngIf="legends" class="legends-list">
    <span *ngFor="let legend of legends" class="legend-item">
      <div *ngIf="!activeVarianceToggle || !legend.showBenchmarkOptionControl" class="{{legend.style}} {{legend.color}} marker"></div>
      <div id="benchmark-option-legend" *ngIf="legend.showBenchmarkOptionControl">
        <app-legend-benchmark-option
          [fromPdf]="fromPdf"
          [metric]="metricType"
          [extendedBenchmarks]="showExtendedBenchmarkOptions"
          [benchmarkOption]="benchmarkOption"
        ></app-legend-benchmark-option>
      </div>
      <label class="legend">{{legend.text}}
        <span *ngIf="(legend.showBenchmarkOptionControl || legend.showPercentileControl) && !hasBenchmarks" class="no-benchmarks">
          - <span class="warning">No Benchmarks Available</span>
        </span>
      </label>
      <div *ngIf="legend.showPercentileControl && hasBenchmarks && fromPdf" id="pdf-benchmark-label">
        {{benchmarkPercentileLabel}}
      </div>
      <div id="benchmark-dropdown" *ngIf="legend.showPercentileControl && hasBenchmarks && !fromPdf">
        <mat-select class="benchmarkType"
                    placeholder="Benchmark Scale"
                    id="benchmark-scale-dropdown"
                    [(value)]="benchmarkPercentile"
                    (selectionChange)="updateBenchmarkPercentile($event.value)"
                    hideSingleSelectionIndicator>
          <mat-option *ngFor="let benchmark of benchmarkPercentiles" [value]="benchmark.value">{{benchmark.name}}</mat-option>
        </mat-select>
      </div>
    </span>
  </span>
  <span *ngIf="warningText" class="warning-text" style="margin-left:16px">{{warningText}}</span>
</div>
