import {MetricType} from '../metric-types';
import {
  Consultation,
  EmDimension,
  EmergencyMedicine,
  EstablishedPatientVisit,
  EyeExamEstablishedPatient,
  EyeExamNewPatient,
  HospitalDischargeDay,
  InitialHospitalCare,
  NewPatientVisit,
  SubsequentHospitalCare
} from '../models';
import {FrontEndTelehealthFlag, NodeExpansionState} from '../enums';
import {CptCategoryViewType, getEvaluationManagementViewTypeText} from '../../evaluation-management/em-helpers';
import {
  BenchmarkCombo,
  getBenchmarkComboDefault
} from '../../batch-export/multi-provider-export-redesign/mpe-dialog-helper';

export interface WhatFilters {
  showBreadcrumb?: boolean;
  showDateRange?: boolean;
  showLocation?: boolean;
  showPayer?: boolean;
  showLag?: boolean;
  showBilling?: boolean;
  showInvoice?: boolean;
  showVisitType?: boolean;
  showCptGroupBreadcrumb?: boolean;
}

export interface Export {
  data: any;
  headers: string[];
  fileName: string;
  page: string;
  title?: string;
  whatFilters?: WhatFilters;
  includesProcedureSummary?: boolean;
}

export interface ExportNew {
  data: ExportMetadata[];
  fileName: string;
  sheets: string[];
}

export interface ExportMetadata {
  summaryData?: any;
  detailData?: any;
  copyright?: string;
  summaryHeaders?: string[];
  detailHeaders?: string[];
  fileName: string;
  page: string;
  title?: string;
  filterInfo?: string[];
  isBlankRowAfterSummary: boolean;
  shouldBoldAggregateRows?: boolean;
  aggregateIndex?: number;
  whatFilters?: WhatFilters;
  sheetName?: string;
}

export interface ExportMetric<T> {
  id: T;
  text: string;
  type: MetricType;
  selected: boolean;
  descendant: number[];
  ancestor: number[];
  downloadable: boolean;
  depth?: number;
  page?: string;
  active?: boolean;
  metricDimension?: any;
  displayed?: boolean;
}

export interface ExpandableExportOption<T> {
  text: string;
  selected: boolean;
  displayed: boolean;
  nodeExpansionState: NodeExpansionState;
  children: T[];
  benchmarkCombo?: BenchmarkCombo;
  metric?: MetricType;
  depth?: number;
  mandatoryIfParentIsSelected?: boolean;
  hasSubMetric?: boolean;
  anyUnselectedDescendants?: boolean;
  showExtendedBenchmarks?: boolean;
}

export enum OverviewPagePdfOption {
  BATCH_OVERVIEW,
  OVERVIEW_EM,
  OVERVIEW_OUTPATIENT,
  OVERVIEW_INPATIENT,
  OVERVIEW_EMERGENCY,
  OVERVIEW_OPHTHALMOLOGY,
  OVERVIEW_WRVU,
  OVERVIEW_NPV
}

export enum EMPagePdfOption {
  EM_PAGE,
  OUTPATIENT,
  INPATIENT,
  EMERGENCY_MEDICINE,
  OPHTHALMOLOGY
}

export enum EMTrendPdfOption {
  EM_TREND,
  EM_OUTPATIENT_TREND,
  EM_OUTPATIENT_TREND_NEW_PATIENT_VISIT,
  EM_OUTPATIENT_TREND_ESTABLISHED_PATIENT,
  EM_OUTPATIENT_TREND_CONSULTATION,
  EM_INPATIENT_TREND,
  EM_INPATIENT_TREND_INITIAL_HOSPITAL_CARE,
  EM_INPATIENT_TREND_SUBSEQUENT_HOSPITAL_CARE,
  EM_INPATIENT_TREND_HOSPITAL_DISCHARGE,
  EM_EMERGENCY_TREND,
  EM_EMERGENCY_TREND_MEDICINE,
  EM_EYE_EXAM_TREND,
  EM_EYE_EXAM_TREND_NEW_PATIENT_EYE_EXAM,
  EM_EYE_EXAM_TREND_ESTABLISHED_PATIENT_EYE_EXAM
}

export enum WRVUTrendPdfOption {
  WRVU_TREND,
  ACTUAL,
  ACTUAL_CHARGES,
  CFTE_ADJ,
  CFTE_ADJ_CHARGES,
  PREVIOUS_DATE_RANGE
}

export enum NPVPagePdfOption {
  NPV_PAGE,
  NPV_LOCATION,
  NPV_PROVIDER_TREND,
  NPV_LOCATION_DEPARTMENT,
  NPV_LOCATION_SPECIALTY,
  NPV_LOCATION_PROVIDER
}

export const wrvuOverviewText = 'wRVU';
export const npvOverviewText = 'NPV';
export const emOverviewText = 'E&M';

// TODO: Can the following interfaces be abstracted?
export interface OverviewPageOption extends ExpandableExportOption<OverviewPageOption> {
  id: OverviewPagePdfOption;
}

export interface EMPageOption extends ExpandableExportOption<EMPageOption> {
  id: EMPagePdfOption;
}

export interface EMTrendOption extends ExpandableExportOption<EMTrendOption> {
  id: EMTrendPdfOption;
}

export interface WRVUTrendOption extends ExpandableExportOption<WRVUTrendOption> {
  id: WRVUTrendPdfOption;
}

export interface NPVPageOption extends ExpandableExportOption<NPVPageOption> {
  id: NPVPagePdfOption;
}

export type BatchPdfOption = OverviewPageOption | EMPageOption | EMTrendOption | WRVUTrendOption | NPVPageOption;

export function overviewPageHierarchy(telehealthFlag: FrontEndTelehealthFlag): OverviewPageOption[] {
  return [{
    text: 'Overview',
    id: OverviewPagePdfOption.BATCH_OVERVIEW,
    selected: false,
    displayed: true,
    nodeExpansionState: NodeExpansionState.EXPANDED,
    children: [{
        text: 'Outpatient',
        id: OverviewPagePdfOption.OVERVIEW_OUTPATIENT,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: 'Inpatient',
        id: OverviewPagePdfOption.OVERVIEW_INPATIENT,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: 'Emergency Medicine',
        id: OverviewPagePdfOption.OVERVIEW_EMERGENCY,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: 'Ophthalmology',
        id: OverviewPagePdfOption.OVERVIEW_OPHTHALMOLOGY,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: wrvuOverviewText,
        id: OverviewPagePdfOption.OVERVIEW_WRVU,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: false,
        benchmarkCombo: getBenchmarkComboDefault(false, telehealthFlag),
        metric: MetricType.OverviewWrvu,
        children: [],
        mandatoryIfParentIsSelected: true
      }, {
        text: npvOverviewText,
        id: OverviewPagePdfOption.OVERVIEW_NPV,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: false,
        benchmarkCombo: getBenchmarkComboDefault(true, telehealthFlag),
        metric: MetricType.OverviewNpv,
        children: [],
        mandatoryIfParentIsSelected: true,
        showExtendedBenchmarks: true
      }, {
        text: emOverviewText,
        id: OverviewPagePdfOption.OVERVIEW_EM,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: false,
        benchmarkCombo: getBenchmarkComboDefault(true, telehealthFlag),
        metric: MetricType.OverviewEandM,
        children: [],
        mandatoryIfParentIsSelected: true,
        showExtendedBenchmarks: true
    }],
    hasSubMetric: true
  }];
}

export function emPageHierarchy(): EMPageOption[] {
  return [{
    text: 'E&M Page',
    id: EMPagePdfOption.EM_PAGE,
    selected: false,
    nodeExpansionState: NodeExpansionState.EXPANDED,
    displayed: true,
    children: [{
      text: 'Outpatient',
      id: EMPagePdfOption.OUTPATIENT,
      selected: false,
      nodeExpansionState: NodeExpansionState.LEAF,
      displayed: true,
      children: []
    }, {
      text: 'Inpatient',
      id: EMPagePdfOption.INPATIENT,
      selected: false,
      nodeExpansionState: NodeExpansionState.LEAF,
      displayed: true,
      children: []
    }, {
      text: 'Emergency Medicine',
      id: EMPagePdfOption.EMERGENCY_MEDICINE,
      selected: false,
      nodeExpansionState: NodeExpansionState.LEAF,
      displayed: true,
      children: []
    }, {
      text: 'Ophthalmology',
      id: EMPagePdfOption.OPHTHALMOLOGY,
      selected: false,
      nodeExpansionState: NodeExpansionState.LEAF,
      displayed: true,
      children: []
    }]
  }];
}

export function emTrendHierarchy(): EMTrendOption[] {
  return [{
    text: 'E&M Trend',
    id: EMTrendPdfOption.EM_TREND,
    selected: false,
    nodeExpansionState: NodeExpansionState.EXPANDED,
    displayed: true,
    children: [{
      text: 'Outpatient Trend',
      id: EMTrendPdfOption.EM_OUTPATIENT_TREND,
      selected: false,
      nodeExpansionState: NodeExpansionState.COLLAPSED,
      displayed: true,
      children: [{
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.NewPatient),
        id: EMTrendPdfOption.EM_OUTPATIENT_TREND_NEW_PATIENT_VISIT,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.EstablishedPatient),
        id: EMTrendPdfOption.EM_OUTPATIENT_TREND_ESTABLISHED_PATIENT,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.Consults),
        id: EMTrendPdfOption.EM_OUTPATIENT_TREND_CONSULTATION,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }],
    }, {
      text: 'Inpatient Trend',
      id: EMTrendPdfOption.EM_INPATIENT_TREND,
      selected: false,
      nodeExpansionState: NodeExpansionState.COLLAPSED,
      displayed: true,
      children: [{
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.InitialHospital),
        id: EMTrendPdfOption.EM_INPATIENT_TREND_INITIAL_HOSPITAL_CARE,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.SubsequentHospital),
        id: EMTrendPdfOption.EM_INPATIENT_TREND_SUBSEQUENT_HOSPITAL_CARE,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.HospitalDischarge),
        id: EMTrendPdfOption.EM_INPATIENT_TREND_HOSPITAL_DISCHARGE,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }],
    }, {text: 'Emergency Trend',
      id: EMTrendPdfOption.EM_EMERGENCY_TREND,
      selected: false,
      nodeExpansionState: NodeExpansionState.COLLAPSED,
      displayed: true,
      children: [{
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.EmergencyMedicine),
        id: EMTrendPdfOption.EM_EMERGENCY_TREND_MEDICINE,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }],
    }, {text: 'Ophthalmology Trend',
      id: EMTrendPdfOption.EM_EYE_EXAM_TREND,
      selected: false,
      nodeExpansionState: NodeExpansionState.COLLAPSED,
      displayed: true,
      children: [{
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.EyeExamNew),
        id: EMTrendPdfOption.EM_EYE_EXAM_TREND_NEW_PATIENT_EYE_EXAM,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: getEvaluationManagementViewTypeText(CptCategoryViewType.EyeExamEstablished),
        id: EMTrendPdfOption.EM_EYE_EXAM_TREND_ESTABLISHED_PATIENT_EYE_EXAM,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }],
    }]}];
}

export function wrvuTrendHierarchy(): WRVUTrendOption[] {
  return [{
    text: 'wRVUS by Trend',
    id: WRVUTrendPdfOption.WRVU_TREND,
    selected: false,
    nodeExpansionState: NodeExpansionState.COLLAPSED,
    displayed: true,
    children: [{
      text: 'Actual wRVUS',
      id: WRVUTrendPdfOption.ACTUAL,
      selected: false,
      nodeExpansionState: NodeExpansionState.COLLAPSED,
      displayed: true,
      children: [{
        text: 'Add Charges Variable',
        id: WRVUTrendPdfOption.ACTUAL_CHARGES,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }]
    }, {
      text: 'cFTE adj. wRVUs',
      id: WRVUTrendPdfOption.CFTE_ADJ,
      selected: false,
      nodeExpansionState: NodeExpansionState.COLLAPSED,
      displayed: true,
      children: [{
        text: 'Add Charges Variable',
        id: WRVUTrendPdfOption.CFTE_ADJ_CHARGES,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }, {
        text: 'Add Previous Date Range',
        id: WRVUTrendPdfOption.PREVIOUS_DATE_RANGE,
        selected: false,
        nodeExpansionState: NodeExpansionState.LEAF,
        displayed: true,
        children: []
      }]
    }]
  }];
}

export function npvPdfHierarchy(): NPVPageOption[] {
  return [
    {
      text: 'NPV Trend', id: NPVPagePdfOption.NPV_PAGE, selected: false,
      nodeExpansionState: NodeExpansionState.LEAF, displayed: true,
      children: []
    }];
}

export interface EvaluationManagementOutpatientRow {
  rowName: string;
  nodeId?: number;
  nodePath: string;
  newPatientVisit: NewPatientVisit;
  establishedPatientVisit: EstablishedPatientVisit;
  consultation: Consultation;
}

export interface EvaluationManagementOutpatientExportRow {
  group: string;
  metric: string;
  cpt99201?: string;
  cpt99202?: string;
  cpt99203?: string;
  cpt99204?: string;
  cpt99205?: string;
  totalNewOPVisits?: string;
  cpt99211?: string;
  cpt99212?: string;
  cpt99213?: string;
  cpt99214?: string;
  cpt99215?: string;
  totalEstablishedOPVisits?: string;
  cpt99241?: string;
  cpt99242?: string;
  cpt99243?: string;
  cpt99244?: string;
  cpt99245?: string;
  totalOfficeConsultation?: string;
}

export interface EvaluationManagementInpatientRow {
  rowName: string;
  nodeId?: number;
  nodePath: string;
  subsequentHospitalCare: SubsequentHospitalCare;
  initialHospitalCare: InitialHospitalCare;
  hospitalDischargeDay: HospitalDischargeDay;
}

export interface EvaluationManagementInpatientExportRow {
  group: string;
  metric: string;
  cpt99221?: string;
  cpt99222?: string;
  cpt99223?: string;
  totalInitialHospitalCare?: string;
  cpt99231?: string;
  cpt99232?: string;
  cpt99233?: string;
  totalSubsequentHospitalCare?: string;
  cpt99238?: string;
  cpt99239?: string;
  totalHospitalDischargeDay?: string;
}

export interface EvaluationManagementEmergencyRoomRow {
  rowName: string;
  nodeId?: number;
  nodePath: string;
  emergencyMedicine: EmergencyMedicine;
}

export interface EvaluationManagementEmergencyRoomExportRow {
  group: string;
  metric: string;
  cpt99281?: string;
  cpt99282?: string;
  cpt99283?: string;
  cpt99284?: string;
  cpt99285?: string;
  total?: string;
}

export interface EvaluationManagementEyeExamRow {
  rowName: string;
  nodeId?: number;
  nodePath: string;
  eyeExamNewPatient: EyeExamNewPatient;
  eyeExamEstablishedPatient: EyeExamEstablishedPatient;
}

export interface EvaluationManagementEyeExamExportRow {
  group: string;
  metric: string;
  cpt92002?: string;
  cpt92004?: string;
  totalNewVisits?: string;
  cpt92012?: string;
  cpt92014?: string;
  totalEstablished?: string;
}

export enum ExcelExportMetricId {
  ALL_METRICS = 0,
  WRVUS = 9,
  WRVU_TREND_VIEW = 1,
  WRVU_BY_DEPARTMENT = 21,
  WRVU_BY_SPECIALTY = 22,
  WRVU_BY_PROVIDER = 23,
  WRVU_SPECIALTY_PERFORMANCE = 54,
  NPV = 10,
  NPV_TREND = 3,
  NPV_BY_DEPARTMENT = 24,
  NPV_BY_SPECIALTY = 25,
  NPV_BY_PROVIDER = 26,
  NPV_BY_LOCATION = 72,
  NPV_BY_LOCATION_BY_DEPARTMENT = 73,
  NPV_BY_LOCATION_BY_SPECIALTY = 74,
  NPV_BY_LOCATION_BY_PROVIDER = 75,
  EM = 11,
  EM_OUTPATIENT = 33,
  EM_OUTPATIENT_BY_DEPARTMENT = 34,
  EM_OUTPATIENT_BY_SPECIALTY = 35,
  EM_OUTPATIENT_BY_PROVIDER = 36,
  EM_OUTPATIENT_TREND = 55,
  EM_INPATIENT = 37,
  EM_INPATIENT_BY_DEPARTMENT = 38,
  EM_INPATIENT_BY_SPECIALTY = 39,
  EM_INPATIENT_BY_PROVIDER = 40,
  EM_INPATIENT_TREND = 56,
  EM_EMERGENCY_MEDICINE = 41,
  EM_EMERGENCY_MEDICINE_BY_DEPARTMENT = 42,
  EM_EMERGENCY_MEDICINE_BY_SPECIALTY = 43,
  EM_EMERGENCY_MEDICINE_BY_PROVIDER = 44,
  EM_EMERGENCY_MEDICINE_TREND = 57,
  EM_OPHTHALMOLOGY = 45,
  EM_OPHTHALMOLOGY_BY_DEPARTMENT = 46,
  EM_OPHTHALMOLOGY_BY_SPECIALTY = 47,
  EM_OPHTHALMOLOGY_BY_PROVIDER = 48,
  EM_OPHTHALMOLOGY_TREND = 58,
  CLINICAL_FINGERPRINT = 12,
  CLINICAL_FINGERPRINT_BY_CPT_FAMILY = 13,
  CLINICAL_FINGERPRINT_BY_CPT_RANGE = 14,
  CLINICAL_FINGERPRINT_BY_CPT_CODE = 15,
  COLLECTIONS = 16,
  COLLECTIONS_SUMMARY = 19,
  COLLECTIONS_BY_DEPARTMENT = 27,
  COLLECTIONS_BY_SPECIALTY = 28,
  COLLECTIONS_BY_PROVIDER = 29,
  COLLECTIONS_BY_PAYER = 18,
  COLLECTIONS_BY_PAYER_BY_DEPARTMENT = 30,
  COLLECTIONS_BY_PAYER_BY_SPECIALTY = 31,
  COLLECTIONS_BY_PAYER_BY_PROVIDER = 32,
  DENIALS = 17,
  DENIALS_BY_DEPARTMENT = 50,
  DENIALS_BY_SPECIALTY = 51,
  DENIALS_BY_PROVIDER = 52,
  DENIALS_BY_PAYER = 53,
  PROCEDURE_SUMMARY = 68,
  PROCEDURE_SUMMARY_BY_DEPARTMENT = 69,
  PROCEDURE_SUMMARY_BY_SPECIALTY = 70,
  PROCEDURE_SUMMARY_BY_PROVIDER = 71,
  PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY = 59,
  PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE = 60,
  PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE = 61,
  PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY  = 62,
  PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE  = 63,
  PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE  = 64,
  PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY  = 65,
  PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE  = 66,
  PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE  = 67,
}

export const ROOT_EXCEL_EXPORT_METRIC_INDEX = 0;

export function ExcelExportMetrics(): ExportMetric<ExcelExportMetricId>[] {
  const excelExportMetrics = [
    {
      id: ExcelExportMetricId.ALL_METRICS,
      text: 'All Metrics',
      type: MetricType.None,
      selected: false,
      ancestor: [],
      descendant: [
        ExcelExportMetricId.WRVUS,
        ExcelExportMetricId.NPV,
        ExcelExportMetricId.EM,
        ExcelExportMetricId.CLINICAL_FINGERPRINT,
        ExcelExportMetricId.COLLECTIONS,
        ExcelExportMetricId.DENIALS,
        ExcelExportMetricId.PROCEDURE_SUMMARY
      ],
      downloadable: true,
      displayed: true
    },
    {
      id: ExcelExportMetricId.WRVUS,
      text: 'wRVUs',
      type: MetricType.None,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS],
      descendant: [
        ExcelExportMetricId.WRVU_TREND_VIEW,
        ExcelExportMetricId.WRVU_BY_DEPARTMENT,
        ExcelExportMetricId.WRVU_BY_SPECIALTY,
        ExcelExportMetricId.WRVU_BY_PROVIDER,
        ExcelExportMetricId.WRVU_SPECIALTY_PERFORMANCE
      ],
      downloadable: false,
      displayed: true
    },
    {
      id: ExcelExportMetricId.WRVU_TREND_VIEW,
      text: 'Trend View',
      type: MetricType.WorkRVUs,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.WRVUS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.WRVU_BY_DEPARTMENT,
      text: 'By Department',
      type: MetricType.WorkRVUs,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.WRVUS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.WRVU_BY_SPECIALTY,
      text: 'By Specialty',
      type: MetricType.WorkRVUs,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.WRVUS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.WRVU_BY_PROVIDER,
      text: 'By Provider',
      type: MetricType.WorkRVUs,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.WRVUS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.WRVU_SPECIALTY_PERFORMANCE,
      text: 'Specialty Performance',
      type: MetricType.WorkRVUs,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.WRVUS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.NPV,
      text: 'New Patient Visits',
      type: MetricType.None,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS],
      descendant: [
        ExcelExportMetricId.NPV_TREND,
        ExcelExportMetricId.NPV_BY_DEPARTMENT,
        ExcelExportMetricId.NPV_BY_SPECIALTY,
        ExcelExportMetricId.NPV_BY_PROVIDER],
      downloadable: false,
      displayed: true
    },
    {
      id: ExcelExportMetricId.NPV_TREND,
      text: 'Trend View',
      type: MetricType.NewPatientVisits,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.NPV],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.NPV_BY_DEPARTMENT,
      text: 'By Department',
      type: MetricType.NewPatientVisits,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.NPV],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.NPV_BY_SPECIALTY,
      text: 'By Specialty',
      type: MetricType.NewPatientVisits,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.NPV],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.NPV_BY_PROVIDER,
      text: 'By Provider',
      type: MetricType.NewPatientVisits,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.NPV],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.PROCEDURE_SUMMARY,
      text: 'Procedure Summary',
      type: MetricType.ProcedureSummary,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS],
      descendant: [60],
      downloadable: false, displayed: true
    },
    {
      id: ExcelExportMetricId.EM,
      text: 'E&M',
      type: MetricType.None,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS],
      descendant: [
        ExcelExportMetricId.EM_OUTPATIENT,
        ExcelExportMetricId.EM_INPATIENT,
        ExcelExportMetricId.EM_EMERGENCY_MEDICINE,
        ExcelExportMetricId.EM_OPHTHALMOLOGY],
      downloadable: false,
      displayed: true
    },
    {
      id: ExcelExportMetricId.EM_OUTPATIENT, // 33,
      text: 'Outpatient View',
      type: MetricType.EandM,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM],
      descendant: [
        ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT,
        ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY,
        ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER,
        ExcelExportMetricId.EM_OUTPATIENT_TREND],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OUTPATIENT_BY_DEPARTMENT, // 34,
      text: 'Outpatient By Department',
      type: MetricType.EandMOutpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OUTPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OUTPATIENT_BY_SPECIALTY, // 35,
      text: 'Outpatient By Specialty',
      type: MetricType.EandMOutpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OUTPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OUTPATIENT_BY_PROVIDER, // 36,
      text: 'Outpatient By Provider',
      type: MetricType.EandMOutpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OUTPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OUTPATIENT_TREND,
      text: 'Outpatient Trend',
      type: MetricType.EandMOutpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OUTPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_INPATIENT, // 37,
      text: 'Inpatient View',
      type: MetricType.EandM,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM],
      descendant: [
        ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT,
        ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY,
        ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER,
        ExcelExportMetricId.EM_INPATIENT_TREND],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_INPATIENT_BY_DEPARTMENT, // 38,
      text: 'Inpatient By Department',
      type: MetricType.EandMInpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_INPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_INPATIENT_BY_SPECIALTY, // 39,
      text: 'Inpatient By Specialty',
      type: MetricType.EandMInpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_INPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_INPATIENT_BY_PROVIDER, // 40,
      text: 'Inpatient By Provider',
      type: MetricType.EandMInpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_INPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_INPATIENT_TREND,
      text: 'Inpatient Trend',
      type: MetricType.EandMInpatient,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_INPATIENT],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE, // 41,
      text: 'Emergency Medicine View',
      type: MetricType.EandM,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM],
      descendant: [
        ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT,
        ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY,
        ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER,
        ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_DEPARTMENT, // 42,
      text: 'Emergency Medicine By Department',
      type: MetricType.EandMEmergency,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_EMERGENCY_MEDICINE],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_SPECIALTY, // 43,
      text: 'Emergency Medicine By Specialty',
      type: MetricType.EandMEmergency,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_EMERGENCY_MEDICINE],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_BY_PROVIDER, // 44,
      text: 'Emergency Medicine By Provider',
      type: MetricType.EandMEmergency,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_EMERGENCY_MEDICINE],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_EMERGENCY_MEDICINE_TREND,
      text: 'Emergency Medicine Trend',
      type: MetricType.EandMEmergency,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_EMERGENCY_MEDICINE],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OPHTHALMOLOGY, // 45,
      text: 'Ophthalmology View',
      type: MetricType.EandM,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM],
      descendant: [
        ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT,
        ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY,
        ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER,
        ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_DEPARTMENT, // 46,
      text: 'Ophthalmology By Department',
      type: MetricType.EandMOpthalmology,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OPHTHALMOLOGY],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_SPECIALTY, // 47,
      text: 'Ophthalmology By Specialty',
      type: MetricType.EandMOpthalmology,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OPHTHALMOLOGY],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OPHTHALMOLOGY_BY_PROVIDER, // 48,
      text: 'Ophthalmology By Provider',
      type: MetricType.EandMOpthalmology,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OPHTHALMOLOGY],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.EM_OPHTHALMOLOGY_TREND,
      text: 'Ophthalmology Trend',
      type: MetricType.EandMOpthalmology,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.EM, ExcelExportMetricId.EM_OPHTHALMOLOGY],
      descendant: [],
      depth: 3,
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.CLINICAL_FINGERPRINT,
      text: 'Clinical Fingerprint',
      type: MetricType.None,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS],
      descendant: [13, 14, 15],
      downloadable: false,
      displayed: true
    },
    {
      id: ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_FAMILY,
      text: 'Cpt Family',
      type: MetricType.ClinicalFingerprint,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.CLINICAL_FINGERPRINT],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_RANGE,
      text: 'Cpt Ranges',
      type: MetricType.ClinicalFingerprint,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.CLINICAL_FINGERPRINT],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.CLINICAL_FINGERPRINT_BY_CPT_CODE,
      text: 'Cpt Code',
      type: MetricType.ClinicalFingerprint,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.CLINICAL_FINGERPRINT],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS,
      text: 'Collections',
      type: MetricType.None,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS],
      descendant: [
        ExcelExportMetricId.COLLECTIONS_BY_PAYER,
        ExcelExportMetricId.COLLECTIONS_SUMMARY,
        ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT,
        ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY,
        ExcelExportMetricId.COLLECTIONS_BY_PROVIDER,
        ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT,
        ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY,
        ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER
      ],
      downloadable: true,
      displayed: true
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_SUMMARY,
      text: 'Summary',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_BY_DEPARTMENT,
      text: 'By Department',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_BY_SPECIALTY,
      text: 'By Specialty',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_BY_PROVIDER,
      text: 'By Provider',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_BY_PAYER,
      text: 'By Payer',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_DEPARTMENT,
      text: 'By Payer By Department',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_SPECIALTY,
      text: 'By Payer By Specialty',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.COLLECTIONS_BY_PAYER_BY_PROVIDER,
      text: 'By Payer By Provider',
      type: MetricType.Collections,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.COLLECTIONS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.DENIALS,
      text: 'Denials',
      type: MetricType.None,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS],
      descendant: [
        ExcelExportMetricId.DENIALS_BY_DEPARTMENT,
        ExcelExportMetricId.DENIALS_BY_SPECIALTY,
        ExcelExportMetricId.DENIALS_BY_PROVIDER,
        ExcelExportMetricId.DENIALS_BY_PAYER
      ],
      downloadable: true,
      displayed: true
    },
    {
      id: ExcelExportMetricId.DENIALS_BY_DEPARTMENT,
      text: 'By Department',
      type: MetricType.Denials,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.DENIALS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.DENIALS_BY_SPECIALTY,
      text: 'By Specialty',
      type: MetricType.Denials,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.DENIALS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.DENIALS_BY_PROVIDER,
      text: 'By Provider',
      type: MetricType.Denials,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.DENIALS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.DENIALS_BY_PAYER,
      text: 'By Payer',
      type: MetricType.Denials,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.DENIALS],
      descendant: [],
      downloadable: true,
      displayed: false
    },
    {
      id: ExcelExportMetricId.PROCEDURE_SUMMARY,
      text: 'Procedure Summary',
      type: MetricType.ProcedureSummary,
      selected: false,
      ancestor: [ExcelExportMetricId.ALL_METRICS, ExcelExportMetricId.DENIALS],
      descendant: [],
      downloadable: true,
      displayed: false
    }
  ];

  return excelExportMetrics;
}

export const PdfExportMetrics: ExportMetric<number>[] = [

  {
    id: 0,
    text: 'Overview',
    type: MetricType.None,
    selected: true,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: 'Overview',
    active: true
  },
  {
    id: 1,
    text: 'Outpatient View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: 'Em Outpatient',
    active: true,
    metricDimension: EmDimension.Outpatient
  },
  {
    id: 2,
    text: 'Inpatient View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: 'Em Inpatient',
    active: true,
    metricDimension: EmDimension.InPatient
  },
  {
    id: 3,
    text: 'Emergency Medicine View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: 'Em Emergency Medicine',
    active: true,
    metricDimension: EmDimension.EmergencyMedicine
  },
  {
    id: 4,
    text: 'Ophthalmology View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: 'Em Opthalmology',
    active: true,
    metricDimension: EmDimension.Ophthalmology
  }
];

export const BatchPdfExportOverviewMetrics: ExportMetric<number>[] = [
  {
    id: 0,
    text: 'Outpatient View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.Outpatient
  },
  {
    id: 1,
    text: 'Inpatient View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.InPatient
  },
  {
    id: 2,
    text: 'Emergency Medicine View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.EmergencyMedicine
  },
  {
    id: 3,
    text: 'Ophthalmology View',
    type: MetricType.Overview,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.Ophthalmology
  }
];

export const BatchPdfExportEMMetrics: ExportMetric<number>[] = [
  {
    id: 0,
    text: 'Outpatient View',
    type: MetricType.EandM,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.Outpatient
  },
  {
    id: 1,
    text: 'Inpatient View',
    type: MetricType.EandM,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.InPatient
  },
  {
    id: 2,
    text: 'Emergency Medicine View',
    type: MetricType.EandM,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.EmergencyMedicine
  },
  {
    id: 3,
    text: 'Ophthalmology View',
    type: MetricType.EandM,
    selected: false,
    ancestor: [],
    descendant: [],
    downloadable: true,
    page: '',
    active: true,
    metricDimension: EmDimension.Ophthalmology
  },
];
