<div id="visitType-component-wrapper">
  <div id="select-visitType-header">
    <div id="upper-header">Visit Type</div>
    <div class="note">Visit Type is only applicable to the New Patient Visits and Evaluation and Management metrics.<br>Visit Type data is available for date ranges after May 2020</div>
  </div>
  <div id="select-visitType-body">

    <mat-radio-group id="visitType-options">
      <mat-radio-button id="all-visitTypes-btn" value="0"
                        (click)="selectVisitType(visitTypeOptions[0], $event)"
                        [ngClass]="'visitType-selectAll'"
                        [class.active]="selectedVisitType===visitTypeOptions[0]"
                        [checked]="selectedVisitType===visitTypeOptions[0]"
      >
        {{visitTypeOptions[0].text}}</mat-radio-button>

      <mat-radio-button id="in-person-visitType-btn" value="1"
                        (click)="selectVisitType(visitTypeOptions[1], $event)"
                        [checked]="selectedVisitType===visitTypeOptions[1]"
                        [ngClass]="'visitType-option'"
                        [class.active]="selectedVisitType===visitTypeOptions[1]"

      >{{visitTypeOptions[1].text}}</mat-radio-button>

      <mat-radio-button id="telehealth-visitType-btn" value="2"
                        (click)="selectVisitType(visitTypeOptions[2], $event)"
                        [checked]="selectedVisitType===visitTypeOptions[2]"
                        [ngClass]="'visitType-option'"
                        [class.active]="selectedVisitType===visitTypeOptions[2]"
      >{{visitTypeOptions[2].text}}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
