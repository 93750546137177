<div class="wrapper">
  <div [id]="overviewPageView? 'overview-patient-visit-charts' : 'patient-visit-charts'">
    <div class="patient-visit-chart">
      <app-evaluation-management-chart
        chartId="emergency-medicine"
        chartTitle="Emergency Department Visit"
        [totalCptSummaries]="totalEmergencyMedicineCptSummaries"
        [categories]="emergencyMedicineCategories"
        [chartHeight]="chartHeight"
        [maxYAxisValue]="maxYAxisValue"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="isSingleProviderSelected"
        [specialtyCptSummaries]="specialtyTotalEmergencyMedicineCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
        [fromPdf]="fromPdf"
      >
      </app-evaluation-management-chart>
      <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
    </div>
  </div>
  <app-chart-legend [ngClass]="fromPdf? 'pdf-legend': ''" [fromPdf]="fromPdf" [legends]="legends"
                    [metricType]="metricType"></app-chart-legend>
</div>
