<div id="export-button-wrapper" (mouseenter)="openExportOptions(true)" (mouseleave)="openExportOptions(false)">
  <button class="exportButton" id="exportBtn" mat-raised-button color="primary">Export</button>
  <div *ngIf="showOptions" id="export-menu" (click)="deactivateShowOptions()">
    <div class="export-option" (click)="openPageExport()">Export Page</div>
    <div [ngClass]="multiProviderExportEnabled ? 'export-option' : 'export-option-disabled'"
         (mouseenter)="toggleShowHover(true)"
         (mouseleave)="toggleShowHover(false)"
         (click)="openMultiProviderExport()">
      <span *ngIf="canShowHoverMessage" id="hover-message">{{hoverMessage}}</span>
      Multi Provider Export
    </div>
  </div>
</div>
