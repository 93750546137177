import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {
  CptCategories,
  EmDimension,
  EvaluationManagementMultilevel,
  ProviderMultilevelCptCounts
} from '../../../shared/models';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {FilterCriteria, INITIAL_STATE} from '../../../store/IAppState';
import * as _ from 'lodash';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {select} from '@angular-redux/store';
import {BenchmarkOption} from '../../../shared/enums';
import {getEmTrendColumnsWithExtendedBenchmarkOptions} from '../../em-helpers';

@Component({
  selector: 'app-em-page-trend',
  templateUrl: './em-page-trend.component.html',
  styleUrls: ['./em-page-trend.component.scss']
})
export class EmPageTrendComponent implements OnChanges, OnDestroy, OnInit {
  @Input() dimension: EmDimension;
  @Input() evaluationManagementMultilevel: EvaluationManagementMultilevel;
  @Input() foundNodeName: string;
  @Input() filters: FilterCriteria;
  @Input() specialtyCptCounts: CptCategories;
  EmDimension = EmDimension;
  emTrendData: ProviderMultilevelCptCounts[];
  emOutpatientTrendColumns: DataTableColumns[];
  emInpatientTrendColumns: DataTableColumns[];
  emEmergencyMedicineColumns: DataTableColumns[];
  emOphthalmologyTrendColumns: DataTableColumns[];
  showLoader = true;
  viewCommunityBenchmarks: boolean;
  benchmarkOption: BenchmarkOption;
  private specialtyEvaluationManagementSummaryCall: Subscription;
  private benchmarkSubscription: Subscription;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly benchmarkOption$: Observable<BenchmarkOption>;

  constructor() {
  }

  ngOnInit(): void {
    this.benchmarkSubscription = combineLatest([this.benchmarkOption$, this.viewCommunityBenchmarks$])
      .subscribe(([benchmarkOption, viewCommunityBenchmarks]: [BenchmarkOption, boolean]) => {
        this.getColumns(benchmarkOption);
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.benchmarkOption = benchmarkOption;
      });
  }

  ngOnChanges(): void {
    this.emTrendData = this.evaluationManagementMultilevel
      ? this.evaluationManagementMultilevel.evaluationManagementData?.providerEvaluationManagementSnapshotDataMonthly?.nodes || []
      : [];
    this.showLoader = _.isEqual(INITIAL_STATE.data.evaluationManagementMultilevelData, this.evaluationManagementMultilevel);
  }

  ngOnDestroy(): void {
    this.specialtyEvaluationManagementSummaryCall?.unsubscribe();
  }

  private getColumns(benchmarkOption: BenchmarkOption): void {
    this.emOutpatientTrendColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.Outpatient, benchmarkOption);
    this.emInpatientTrendColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.InPatient, benchmarkOption);
    this.emEmergencyMedicineColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.EmergencyMedicine, benchmarkOption);
    this.emOphthalmologyTrendColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.Ophthalmology, benchmarkOption);
  }
}
