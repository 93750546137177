
<div class="graph-container">
  <app-percentage-of-new-patients-multilevel-chart
    [isSpecialty]="false"
    [isDepartment]="true"
    [level]="'By Department'"
    [chosenTab]="MultilevelTab.BY_DEPARTMENT"
    [barSelectionCallback]="whenBarSelected"
    [npvData]="newPatientVisits"
    [zeroSuppression]="zeroSuppression"
    [benchmarkPercentile]="benchmarkPercentile"
    [benchmarkOption]="benchmarkOption"
    [activeVarianceToggle]="activeVarianceToggle"
    [showProgressBar]="showProgressBar"
    [showExtendedBenchmarkOptions]="showExtendedBenchmarkOptions"
    [tieBreakerProperty]="tieBreakerProperty"
    page="npvSnapshot"
    [showVariableMenu]="true"
    [sortingCriteria]="npvSortingCriteria"
    [varianceKey]="varianceKey"
    [variables]="variables">
  </app-percentage-of-new-patients-multilevel-chart>
</div>
<app-new-patient-visits-summary-data-table [showExtendedBenchmarkOptions]="showExtendedBenchmarkOptions"></app-new-patient-visits-summary-data-table>
<app-new-patient-visits-data-table id="npv-snapshot"
                [receivedData]="newPatientVisits"
                [updateSortingCriteria]="updateSortingCriteria"
                [columns]="displayedColumns"
                [whenSortChanged]="levelSpecificHandleSortChange"
                [defaultSortColumn]="defaultSortColumn"
                [sortDirection]="sortDirection"
                [sortingDataAccessor]="sortingDataAccessor"
                [tieBreakerProperty]="tieBreakerProperty"
                [rowSelectionCallback]="whenDepartmentSelected"
                [tableTitle]="'New Patients By Department'"
                [showLevel]="false"
                [showProgressBar]="showProgressBar"
                [displayZeroSuppression]=true
                [shouldPutNullsAtTheBottom]="activeVarianceToggle"
                [isZeroSuppressionChecked]="zeroSuppression"
                [countOfSuppressedEntries]="countOfSuppressedEntries"
                [zeroSuppressionCondition]="zeroSuppressionCondition"
                [currentPage]="currentPage"
                [originalColumns]="columns"
                [addRemoveOption]="true"
                [reducerAction]="snapshotNPVColumnsChangedTo" zeroSuppressionSnapshotEntry = "Departments">
</app-new-patient-visits-data-table>
