<div class="wrapper">
  <div id="overview-patient-visit-charts">
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart
        chartId="emergency-medicine"
        [chartTitle]="'Emergency Department Visit'"
        [totalCptSummaries]="totalEmergencyMedicineCptSummaries"
        [categories]="emergencyMedicineCategories"
        [chartHeight]="chartHeight"
        [maxYAxisValue]="maxYAxisValue"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="true"
        [specialtyCptSummaries]="specialtyTotalEmergencyMedicineCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      >
      </app-evaluation-management-batch-export-chart>
    </div>
  </div>
  <app-chart-legend
    [legends]="legends"
    [fromPdf]="true"
    [metricType]="metricType"
    [showExtendedBenchmarkOptions]="true"
    [benchmarkOption]="benchmarkOption"
  ></app-chart-legend>
  <div id="table-container" *ngIf="showTable">
    <app-em-snapshot-table [receivedData]="tableData" [columns]="columns" [fromPdf]="true"></app-em-snapshot-table>
  </div>
</div>
