import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {anyBatchPdfHierarchicalItemSelected, BatchExportMetricSection} from '../../batch-export-helper';
import {MetricType} from '../../../shared/metric-types';
import {BenchmarkCombo} from '../mpe-dialog-helper';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';
import {BenchmarkOption} from '../../../shared/enums';

@Component({
  selector: 'app-metric-benchmark-pane',
  templateUrl: './metric-benchmark-pane.component.html',
  styleUrls: ['./metric-benchmark-pane.component.scss']
})
export class MetricBenchmarkPaneComponent implements OnChanges {
  @Output() submitExportOptions = new EventEmitter<BatchExportMetricSection[]>();
  @Output() whenAtLeastOneItemIsSelected = new EventEmitter<boolean>();
  @Input() batchExportMetricSections: BatchExportMetricSection[] = [];
  @Input() overviewId: string;
  @Input() overviewMetricSection: BatchExportMetricSection;
  @Input() canShowNpv = false;
  @Input() canShowWrvuTrend = false;
  @Input() canShowNpvLocation = false;
  @Input() canShowBenchmarkSelector = false;

  overviewSubMetricSections: BatchExportMetricSection[];
  displayedBatchExportMetricSections: BatchExportMetricSection[];
  MetricType = MetricType;
  somethingSelected = false;

  constructor() { }

  ngOnChanges() {
    this.displayedBatchExportMetricSections = this.batchExportMetricSections.filter(b =>
      !(b.isOverviewTile || b.metric === MetricType.Overview));
    this.overviewSubMetricSections = this.batchExportMetricSections.filter(s => s.isOverviewTile);
    this.checkIfAnythingSelected();
  }

  checkIfAnythingSelected(): void {
    this.somethingSelected = this.overviewMetricSection.selected ||
      !!this.batchExportMetricSections.find(s => anyBatchPdfHierarchicalItemSelected(s.options));
    this.whenAtLeastOneItemIsSelected.emit(this.somethingSelected);
  }

  onHierarchicalOptionSelected = () => {
    this.checkIfAnythingSelected();
  };

  chooseBenchmarkOption(benchmarkOption: BenchmarkOption, benchmarkCombo?: BenchmarkCombo): void {
    if (benchmarkCombo) {
      benchmarkCombo.option = benchmarkOption;
      this.submitExportOptions.emit(this.batchExportMetricSections);
    }
  }

  choosePercentile(percentile: BenchmarkPercentile, benchmarkCombo?: BenchmarkCombo): void {
    if (benchmarkCombo) {
      benchmarkCombo.percentile = percentile;
      this.submitExportOptions.emit(this.batchExportMetricSections);
    }
  }
}
