import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FilterCriteria, IAppState, INITIAL_STATE, ProcedureSummaryVariables} from '../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {NgRedux, select} from '@angular-redux/store';
import {
  CfpByMultilevel,
  CfpMultiLevelData,
  getPreviousCptGranularity,
  ProcedureSummaryDrill,
  ProcedureSummaryDrillImpl
} from '../../ClinicalSummary';
import {CptViewType, GoogleAnalyticCategories, MultilevelTab} from '../../../shared/enums';
import * as _ from 'lodash';
import {
  AppAction,
  cFPChargesDisplayChangedTo,
  cFTEAdjustedCountDisplayChangedTo,
  cptGroupingsChangedTo,
  cptViewTypeChangedTo,
  multilevelTabChangedTo,
  procedureSummaryDrillChangedTo,
  selectedCptGroupChangedTo
} from '../../../store/actions';
import {distinctUntilChanged} from 'rxjs/operators';
import {
  CfpVariableViewType,
  getSingularOntologyLevelName,
  isFeatureEnabled,
  updateCurrentNodePathLabel
} from '../../../shared/helpers';
import {DisplayField, Variable} from '../../../variable-container/variable-container.component';
import {FeatureToggleSetting} from '../../../shared/models';
import {FilterSelectorService, FilterSelectorServiceToken} from '../../../services/filter-selector.service';
import {ApiServiceImpl, ApiServiceToken} from '../../../services/api.service';
import {FeatureToggleEntries} from '../../../shared/feature-toggle-settings-enum';
import {
  CFP_VARIABLES,
  CODE_TAB,
  DEPARTMENT_TAB,
  FAMILY_TAB,
  PROVIDER_TAB,
  RANGE_TAB,
  SPECIALTY_TAB
} from '../../../shared/constants';
import {
  DataTableColumns,
  getDepartmentColumnForCFP,
  getProviderColumnForCFP,
  getSpecialtyColumnForCFP
} from '../../../shared/data-table-columns';
import {updateCommunityBenchmarkAlertStatus} from '../../../shared/reducer-helper';
import {CptGroup} from '../../procedure-summary/ProcedureSummaryModels';
import {hasValue} from '../../../shared/null-helpers';
import {
  HttpProcedureSummaryService,
  ProcedureSummaryServiceToken
} from '../../procedure-summary/procedure-summary-services/procedure-summary.service';
import {DEFAULT_MEMBER_KEY} from '../../../store/DefaultValues';
import {ontologyTabEquivalentOfLocationTab, TabNavigationElement} from '../../../tab-navigation/tab-helper';
import {filterCfpDataBasedOnCptGroupAndDrill} from '../../clinical-fingerprint-helpers';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';

@Component({
  selector: 'app-cfp-multilevel-page',
  templateUrl: './cfp-multilevel-page.component.html',
  styleUrls: ['./cfp-multilevel-page.component.scss']
})
export class CfpMultilevelPageComponent implements OnInit, OnDestroy {
  @select(['data', 'featureToggleSettings'])
  featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @select(['display', 'cfpVariableViewType'])
  private readonly cfpVariableViewType$: Observable<CfpVariableViewType>;

  @select(['data', 'clinicalFingerprintMultilevelData'])
  clinicalFingerprintMultilevelData$: Observable<CfpMultiLevelData>;

  @select(['display', 'multilevelTab'])
  multilevelTab$: Observable<MultilevelTab>;

  @select(['display', 'cptViewType'])
  private readonly cptViewType$: Observable<CptViewType>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'procedureSummaryDrill'])
  private readonly procedureSummaryDrill$: Observable<ProcedureSummaryDrillImpl>;

  @select(['display', 'procedureSummaryVariables'])
  private readonly procedureSummaryVariables$: Observable<ProcedureSummaryVariables>;

  @select(['display', 'selectedCptGroup'])
  private readonly selectedCptGroup$: Observable<CptGroup | undefined>;

  @select(['data', 'cptGroupings'])
  private readonly cptGroupings$: Observable<CptGroup[]>;

  drillText: string;
  cfpMultilevelDataFromStore: CfpMultiLevelData;
  cptViewType: CptViewType;
  cptViewTypeLabel = '';
  subscription: Subscription;
  filterSubscription: Subscription;
  showAdditionalCFPColumns: boolean;
  viewCommunityBenchmarks: boolean;
  cfpVariableViewType: CfpVariableViewType;
  procedureSummaryVariables: ProcedureSummaryVariables;
  currentNodePath: string;

  multilevelTabChangedTo = multilevelTabChangedTo;
  cptViewTypeChangedTo = cptViewTypeChangedTo;
  MultilevelTab = MultilevelTab;
  CptViewType = CptViewType;
  cfpDataForSelectedDimension: CfpByMultilevel[] = [];
  originalCfpDataForSearchModal: CfpByMultilevel[] = [];
  columns: DataTableColumns[] = [];
  variables: Variable[] = CFP_VARIABLES;
  chosenTab: MultilevelTab = MultilevelTab.NONE;
  showProgressBar = true;
  showCfteWrvus: boolean;
  showCfteCount: boolean;

  tabs: TabNavigationElement[] = [
    _.cloneDeep(DEPARTMENT_TAB),
    _.cloneDeep(SPECIALTY_TAB),
    _.cloneDeep(PROVIDER_TAB)
  ];
  cptDrills: ProcedureSummaryDrillImpl | undefined = undefined;
  selectedGroup: CptGroup | undefined;
  cptGroupings: CptGroup[] | undefined;

  cptTabs: TabNavigationElement[] = [FAMILY_TAB, RANGE_TAB, CODE_TAB];

  constructor(private ngRedux: NgRedux<IAppState>,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ProcedureSummaryServiceToken) private readonly procedureSummaryService: HttpProcedureSummaryService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {
    this.filterSubscription = this.filterService.getFilters().subscribe((filter: FilterCriteria) => {
      if (filter.nodePath) {
        this.currentNodePath = filter.nodePath;
        this.apiService.getClinicalFingerPrintMutilevelData(filter);
      }
    });
  }

  ngOnInit() {
    const stateData = this.ngRedux.getState().data;
    this.showCfteWrvus = !isFeatureEnabled(FeatureToggleEntries.HIDE_PROCEDURE_SUMMARY_CFTE_WRVUS,
      stateData.featureToggleSettings, stateData.userProfile);
    this.showCfteCount = !isFeatureEnabled(FeatureToggleEntries.HIDE_PROCEDURE_SUMMARY_CFTE_COUNT,
      stateData.featureToggleSettings, stateData.userProfile);
    this.subscription = combineLatest([
      this.clinicalFingerprintMultilevelData$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.procedureSummaryDrill$,
      this.multilevelTab$,
      this.cptViewType$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.viewCommunityBenchmarks$,
      this.featureToggleSettings$,
      this.cfpVariableViewType$,
      this.procedureSummaryVariables$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.selectedCptGroup$, this.cptGroupings$
    ]).subscribe((
      [data, cptDrill, tab, cptViewType, viewCommunity, featureToggleSettings, cfpVariableViewType,
        procedureSummaryVariables, selectedCptGroup, cptGroupings]:
        [CfpMultiLevelData, ProcedureSummaryDrillImpl, MultilevelTab, CptViewType, boolean, FeatureToggleSetting[],
          CfpVariableViewType, ProcedureSummaryVariables, CptGroup | undefined, CptGroup[]]
    ) => {
      this.setAvailableTabs(cptDrill);
      if (!hasValue(cptGroupings)) {
        const memberKey = this.ngRedux.getState().filters.memberKey;
        if (memberKey !== DEFAULT_MEMBER_KEY) {
          this.procedureSummaryService.getCptGroupings(memberKey)
            .subscribe((cptGroups: CptGroup[]) => {
              this.ngRedux.dispatch(cptGroupingsChangedTo(cptGroups));
              if (!selectedCptGroup) {
                this.ngRedux.dispatch(selectedCptGroupChangedTo(cptGroups.find(g => g.isDefault)));
              }
            });
        }
      }
      this.cptGroupings = cptGroupings;
      this.selectedGroup = selectedCptGroup;
      this.procedureSummaryVariables = procedureSummaryVariables;
      const userProfile = this.ngRedux.getState().data.userProfile;
      this.cfpVariableViewType = cfpVariableViewType;
      this.showAdditionalCFPColumns = isFeatureEnabled(FeatureToggleEntries.SHOW_CFP_COLUMNS, featureToggleSettings, userProfile);
      this.variables = this.showAdditionalCFPColumns ? [{
        name: 'Charges',
        display: false,
        reducerField: DisplayField.CFPCharges,
        dispatchAction(display: boolean): AppAction {
          return cFPChargesDisplayChangedTo(display);
        }
      }] : [{
        name: 'cFTE adj. Count',
        display: false,
        reducerField: DisplayField.CFTEAdjustedCount,
        dispatchAction(display: boolean): AppAction {
          return cFTEAdjustedCountDisplayChangedTo(display);
        }
      }];

      const ontologyTab = this.getAppropriateTab(tab);

      if (this.cptOrOntologyViewTypeChanged(cptViewType, ontologyTab)) {
        this.cptViewType = cptViewType;
        this.cptViewTypeLabel = this.getLabelFor(cptViewType);

        this.chosenTab = ontologyTab;

        const textNodePath = updateCurrentNodePathLabel(this.currentNodePath);
        this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.ProcedureSummarySnapshot,
          `Procedure Summary by ${getSingularOntologyLevelName(ontologyTab)} by CPT ${this.cptViewTypeLabel}`,
          textNodePath
        );
      }
      this.viewCommunityBenchmarks = viewCommunity;
      this.showProgressBar = _.isEqual(data, INITIAL_STATE.data.clinicalFingerprintMultilevelData);
      this.cfpMultilevelDataFromStore = data;
      this.cptDrills = cptDrill;
      this.makeVariancesNulls();
      this.updateData(selectedCptGroup);
    });
  }

  private setAvailableTabs(cptDrill: ProcedureSummaryDrillImpl): void {
    this.cptTabs = [FAMILY_TAB, RANGE_TAB, CODE_TAB];
    if (cptDrill) {
      switch (cptDrill.drillObject.viewType) {
        case CptViewType.CptRange:
          this.cptTabs = this.cptTabs.filter(t => !(t.dimension === CptViewType.CptFamily));
          break;
        case CptViewType.CptCode:
          this.cptTabs = this.cptTabs.filter(t => !(t.dimension === CptViewType.CptFamily));
          this.cptTabs = this.cptTabs.filter(t => !(t.dimension === CptViewType.CptRange));
      }
    }
  }

  private cptOrOntologyViewTypeChanged(cptViewType: CptViewType, ontologyTab: MultilevelTab) {
    return this.cptViewType !== cptViewType || this.chosenTab !== ontologyTab;
  }

  private getAppropriateTab(tab: MultilevelTab): MultilevelTab {
    switch (tab) {
      case MultilevelTab.TREND:
        return MultilevelTab.BY_DEPARTMENT;
      case MultilevelTab.SPECIALTY_PERFORMANCE:
        return MultilevelTab.BY_PROVIDER;
      default:
        return ontologyTabEquivalentOfLocationTab(tab);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
  }

  getLabelFor(cptViewType: CptViewType) {
    switch (cptViewType) {
      case CptViewType.CptFamily:
        return 'Family';
      case CptViewType.CptRange:
        return 'Range';
      case CptViewType.CptCode:
      default:
        return 'Code';
    }
  }

  updateData(cptGroup?: CptGroup) {
    if (this.cptDrills) {
      this.drillText = this.cptDrills.drillObject.displayText;
    }
    if (this.chosenTab !== MultilevelTab.NONE) {
      switch (this.chosenTab) {
        case MultilevelTab.BY_PROVIDER:
          this.setProviderData(cptGroup);
          break;
        case MultilevelTab.BY_SPECIALTY:
          this.setSpecialtyData(cptGroup);
          break;
        case MultilevelTab.BY_DEPARTMENT:
        default:
          this.setDepartmentData(cptGroup);
          break;
      }
    }
    const hasAcademicBenchmarks = !!this.cfpDataForSelectedDimension.find(a => !!a.workRvuBenchmark);
    const hasCommunityBenchmarks = !!this.cfpDataForSelectedDimension.find(a => !!a.communityWorkRvuBenchmark);

    updateCommunityBenchmarkAlertStatus(
      hasCommunityBenchmarks,
      hasAcademicBenchmarks,
      this.ngRedux,
      this.showProgressBar
    );
  }

  setProviderData(cptGroup?: CptGroup): void {
    this.originalCfpDataForSearchModal = this.cfpMultilevelDataFromStore.cfpByProvider;
    this.columns = getProviderColumnForCFP(this.cptViewType, this.viewCommunityBenchmarks,
      this.showAdditionalCFPColumns, this.showCfteWrvus, this.showCfteCount);
    this.cfpDataForSelectedDimension = filterCfpDataBasedOnCptGroupAndDrill(this.originalCfpDataForSearchModal, this.cptDrills, cptGroup);
  }

  setSpecialtyData(cptGroup?: CptGroup): void {
    this.originalCfpDataForSearchModal = this.cfpMultilevelDataFromStore.cfpBySpecialty;
    this.columns = getSpecialtyColumnForCFP(this.cptViewType, this.viewCommunityBenchmarks,
      this.showAdditionalCFPColumns, this.showCfteWrvus, this.showCfteCount);
    this.cfpDataForSelectedDimension = filterCfpDataBasedOnCptGroupAndDrill(this.originalCfpDataForSearchModal, this.cptDrills, cptGroup);
  }

  setDepartmentData(cptGroup?: CptGroup): void {
    this.originalCfpDataForSearchModal = this.cfpMultilevelDataFromStore.cfpByDepartment;
    this.columns = getDepartmentColumnForCFP(this.cptViewType, this.viewCommunityBenchmarks,
      this.showAdditionalCFPColumns, this.showCfteWrvus, this.showCfteCount);
    this.cfpDataForSelectedDimension = filterCfpDataBasedOnCptGroupAndDrill(this.originalCfpDataForSearchModal, this.cptDrills, cptGroup);
  }

  makeVariancesNulls(): void {
    [...this.cfpMultilevelDataFromStore.cfpByProvider,
      ...this.cfpMultilevelDataFromStore.cfpBySpecialty,
      ...this.cfpMultilevelDataFromStore.cfpByDepartment
    ].forEach(datum => {
      if (!datum.workRvuBenchmark) {
        datum.cFTEAdjustedWrvusVariance = null;
      }
      if (!datum.communityWorkRvuBenchmark) {
        datum.communityCfteAdjustedWrvusVariance = null;
      }
    });
  }

  drillBack(): void {
    this.ngRedux.dispatch(cptViewTypeChangedTo(getPreviousCptGranularity(this.cptViewType)));
    if (this.cptDrills?.drillOut()) {
      this.setAvailableTabs(this.cptDrills);
      this.ngRedux.dispatch(procedureSummaryDrillChangedTo(this.cptDrills));
    } else {
      this.ngRedux.dispatch(procedureSummaryDrillChangedTo(undefined));
    }
    this.updateData(this.selectedGroup);
  }

  drillIntoCpt(event: ProcedureSummaryDrill): void {
    if (this.cptDrills) {
      this.cptDrills.drillIn(event);
    } else {
      this.cptDrills = new ProcedureSummaryDrillImpl(event, undefined);
    }
    this.drillText = this.cptDrills.drillObject.displayText;
    this.ngRedux.dispatch(procedureSummaryDrillChangedTo(this.cptDrills));
    this.ngRedux.dispatch(cptViewTypeChangedTo(event.viewType));
  }
}
