import {Component, Input, OnChanges} from '@angular/core';
import {
  getDefaultCptSummary,
  getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions
} from '../../em-helpers';
import {CptCategories, CptSummary, Legend} from '../../../shared/models';
import {BenchmarkOption} from '../../../shared/enums';
import {MetricType} from '../../../shared/metric-types';
import {getEmLegends} from '../../em-chart-helpers';

@Component({
  selector: 'app-em-emergency-room-multilevel-charts',
  templateUrl: './em-emergency-room-multilevel-charts.component.html',
  styleUrls: ['./em-emergency-room-multilevel-charts.component.scss']
})
export class EmEmergencyRoomMultilevelChartsComponent implements OnChanges {

  @Input() totalCptCounts?: CptCategories;
  @Input() specialtyCptCounts?: CptCategories;
  @Input() showProgressBar: boolean;
  @Input() chartHeight: number;
  @Input() overviewPageView: boolean;
  @Input() chartWidth: number;
  @Input() isSingleProviderSelected: boolean;
  @Input() foundNodeName: string;
  @Input() benchmarkOption: BenchmarkOption;
  @Input() fromPdf: boolean;

  emergencyMedicineCategories = ['99281', '99282', '99283', '99284', '99285'];
  totalEmergencyMedicineCptSummaries: (CptSummary | undefined)[];
  emergencyMedicineCptMaxValue = 0;
  specialtyTotalEmergencyMedicineCptSummaries: (CptSummary | undefined)[];
  specialtyEmergencyMedicineCptMaxValue = 0;
  maxYAxisValue: number;
  legends: Legend[];
  readonly metricType = MetricType.EandMEmergency;

  constructor() {
  }

  ngOnChanges(): void {
    this.chartHeight = this.overviewPageView ? 250 : this.chartHeight;
    this.legends = getEmLegends(this.foundNodeName, this.isSingleProviderSelected);

    if (!this.totalCptCounts) {
      this.totalEmergencyMedicineCptSummaries = [];
      this.emergencyMedicineCategories.forEach(x => {
        this.totalEmergencyMedicineCptSummaries.push(getDefaultCptSummary());
      });

      this.emergencyMedicineCptMaxValue = 0;
      return;
    }

    if (!this.specialtyCptCounts) {
      this.specialtyTotalEmergencyMedicineCptSummaries = [];
      this.emergencyMedicineCategories.forEach(x => {
        this.specialtyTotalEmergencyMedicineCptSummaries.push(getDefaultCptSummary());
      });

      this.specialtyEmergencyMedicineCptMaxValue = 0;
    }


    if (this.totalCptCounts.emergencyMedicine) {
      this.totalEmergencyMedicineCptSummaries = [
        this.totalCptCounts.emergencyMedicine.cpt99281,
        this.totalCptCounts.emergencyMedicine.cpt99282,
        this.totalCptCounts.emergencyMedicine.cpt99283,
        this.totalCptCounts.emergencyMedicine.cpt99284,
        this.totalCptCounts.emergencyMedicine.cpt99285
      ];
      this.emergencyMedicineCptMaxValue =
        getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalEmergencyMedicineCptSummaries, this.benchmarkOption);
    }

    if (this.specialtyCptCounts && this.specialtyCptCounts.emergencyMedicine) {
      this.specialtyTotalEmergencyMedicineCptSummaries = [
        this.specialtyCptCounts.emergencyMedicine.cpt99281,
        this.specialtyCptCounts.emergencyMedicine.cpt99282,
        this.specialtyCptCounts.emergencyMedicine.cpt99283,
        this.specialtyCptCounts.emergencyMedicine.cpt99284,
        this.specialtyCptCounts.emergencyMedicine.cpt99285
      ];
      this.specialtyEmergencyMedicineCptMaxValue =
          getMaxYaxisValueForEAndMGraphWithExtendedBenchmarkOptions(this.totalEmergencyMedicineCptSummaries, this.benchmarkOption);
    }


    this.maxYAxisValue = Math.max(this.emergencyMedicineCptMaxValue, this.specialtyEmergencyMedicineCptMaxValue);
  }
}
