import {Injectable, InjectionToken} from '@angular/core';
import {FilterCriteria} from '../../store/IAppState';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.local';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NewPatientVisitSummary} from '../../shared/models';
import {NpvSnapshotResponse} from '../components/npv-models';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const NewPatientVisitServiceToken =
  new InjectionToken<NewPatientVisitService>('New Patient Visit Service');

export interface NewPatientVisitService {
  getMultilevelNewPatientVisitSnapshotData(filter: FilterCriteria, dataset: number): Observable<NpvSnapshotResponse>;
  getNewPatientVisitSummaryData(filter: FilterCriteria, summaryType: number): Observable<NewPatientVisitSummary>;
}

@Injectable()
export class HttpNewPatientVisitService implements NewPatientVisitService {

  constructor(private httpClient: HttpClient) {
  }

  getMultilevelNewPatientVisitSnapshotData(filter: FilterCriteria, dataset: number): Observable<NpvSnapshotResponse> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/newPatientVisitsByMultiLevelNodePath`;
    return this.httpClient.post<NpvSnapshotResponse>(
      url,
      requestBody,
      { params: new HttpParams()
          .set('memberKey', filter.memberKey.toString())
          .set('dataset', dataset.toString())
      },
    );
  }

  getNewPatientVisitSummaryData(filter: FilterCriteria, summaryType: number): Observable<NewPatientVisitSummary> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/newPatientVisitsSummary`;

    return this.httpClient.post<NewPatientVisitSummary>(
      url,
      requestBody,
      {
        params:
          new HttpParams()
            .set('memberKey', filter.memberKey.toString())
            .set('summaryType', summaryType.toString())
      },
    );
  }

 }
