import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {CptGroup} from '../../ProcedureSummaryModels';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  UpdatedManageGroupsComponent
} from '../../../../updated-filter-banner/updated-manage-groups/updated-manage-groups.component';
import {CustomGroupsDataAllFilters} from '../../../../shared/models';
import {cptGroupingsChangedTo, selectedCptGroupChangedTo} from '../../../../store/actions';
import {
  ProcedureSummaryService,
  ProcedureSummaryServiceToken
} from '../../procedure-summary-services/procedure-summary.service';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../../store/IAppState';
import {
  ChangeableCustomGroup,
  convertCptGroupsToChangeableGroups,
  GroupDialogEvent,
  hasManageableCptChanges,
  ManageGroupingsInfo
} from '../../../../updated-filter-banner/manage-groups-helper';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-button-cpt-groupings',
  templateUrl: './button-cpt-groupings.component.html',
  styleUrls: ['./button-cpt-groupings.component.scss']
})
export class ButtonCptGroupingsComponent implements OnChanges, OnDestroy {
  readonly maxCharsForGroupInDropdown = 30;
  @Input() cptGroups: CptGroup[] = [];
  @Output() selectGroup = new EventEmitter<CptGroup>();
  @Input() currentSelectedGroup: CptGroup | undefined;
  deletedGroup: CptGroup;
  groupsAfterSave: ChangeableCustomGroup[];
  private manageGroupsRef: MatDialogRef<any>;
  dialogActionListener: BehaviorSubject<GroupDialogEvent>;
  private dialogActionSubscription: Subscription;
  private originalDefault: CptGroup | undefined;
  stickyOptionScroll = 0;

  constructor(public dialog: MatDialog, private ngRedux: NgRedux<IAppState>,
              @Inject(ProcedureSummaryServiceToken) private readonly procedureSummaryService: ProcedureSummaryService) {
  }

  ngOnChanges(): void {
    this.originalDefault = this.cptGroups?.find(g => g.isDefault);
    this.dialogActionListener = new BehaviorSubject<GroupDialogEvent>(GroupDialogEvent.INITIAL);
    this.listenForDialogActions();
  }

  ngOnDestroy(): void {
    this.dialogActionSubscription?.unsubscribe();
  }

  private getDialogActionListener(): Observable<GroupDialogEvent> {
    return this.dialogActionListener.asObservable();
  }

  private listenForDialogActions(): void {
    this.dialogActionSubscription = this.getDialogActionListener().subscribe((value: GroupDialogEvent) => {
      switch (value) {
        case GroupDialogEvent.DELETE:
          this.confirmDeletionForManageGroupings();
          break;
        case GroupDialogEvent.MANAGE_SAVE:
          this.manageGroupsSave();
          break;
        case GroupDialogEvent.DELETE_CURRENTLY_SELECTED:
          this.handleDeletionOfCurrentSelection();
      }
    });
  }

  manageGroupings(): void {
    if (this.cptGroups && this.cptGroups.length > 0) {
      const dialogData: ManageGroupingsInfo = {
        changeableCustomGroups: convertCptGroupsToChangeableGroups(this.cptGroups || []),
        hasManageableChanges: hasManageableCptChanges,
        dialogActionListener: this.dialogActionListener,
        component: this,
        preloadable: false
      };
      this.manageGroupsRef = this.dialog.open(UpdatedManageGroupsComponent, {
        data: dialogData
      });
    }
  }

  private confirmDeletionForManageGroupings(): void {
    if (this.deletedGroup) {
      this.procedureSummaryService.deleteCptGroup(this.deletedGroup)
        .subscribe(() => {
          this.procedureSummaryService.getCptGroupings(this.deletedGroup.memberKey)
            .subscribe((groups: CptGroup[]) => {
              const groupsAfterDeletion: ChangeableCustomGroup[] = convertCptGroupsToChangeableGroups(groups);
              this.sendUpdatedGroupsToApi(groupsAfterDeletion);
              this.ngRedux.dispatch(cptGroupingsChangedTo(groups));
            });
          if (this.manageGroupsRef) {
            this.manageGroupsRef.componentInstance.currentPreloads = this.manageGroupsRef.componentInstance.getNumberOfPreloads();
          }
        });
    }
  }

  private manageGroupsSave(): void {
    const changedGroups = this.groupsAfterSave.filter(changeableGroup =>
      changeableGroup.newName !== changeableGroup.original.name ||
      changeableGroup.newDefault !== changeableGroup.original.isDefault || changeableGroup.newPreload
      !== (changeableGroup.original as CustomGroupsDataAllFilters).preload);
    let updatingCount = 0;
    changedGroups.forEach(changedGroup => {
      this.procedureSummaryService.updateCptGroup(changedGroup.original as CptGroup, changedGroup.newName,
        changedGroup.newDefault, false).subscribe(() => {
          if (updatingCount++ === changedGroups.length - 1) {
            this.procedureSummaryService.getCptGroupings(this.ngRedux.getState().filters.memberKey)
              .subscribe((groups: CptGroup[]) => {
                this.ngRedux.dispatch(cptGroupingsChangedTo(groups));
                const theDefault = groups.find(g => g.isDefault);
                if (theDefault && theDefault.id !== this.originalDefault?.id) {
                  this.ngRedux.dispatch(selectedCptGroupChangedTo(theDefault));
                }
              });
          }
      });
    });
  }

  private sendUpdatedGroupsToApi(groupsAfterDeletion: ChangeableCustomGroup[]) {
    groupsAfterDeletion.forEach(g => {
      g.original.isDefault = g.newDefault;
      (g.original as CustomGroupsDataAllFilters).preload = g.newPreload;
      this.procedureSummaryService.updateCptGroup(g.original as CptGroup, g.newName,
        g.newDefault, false).subscribe();
    });
  }

  private handleDeletionOfCurrentSelection(): void {
    const theDefault = this.cptGroups.find(g => g.isDefault);
    if (theDefault && this.deletedGroup && theDefault.id === this.deletedGroup.id) {
      this.ngRedux.dispatch(selectedCptGroupChangedTo());
    } else if (theDefault) {
      this.ngRedux.dispatch(selectedCptGroupChangedTo(theDefault));
    }
  }

  whenOptionsOpened(): void {
    const cdk = document.getElementById('my-groupings-panel');
    if (cdk) {
      cdk.addEventListener('scroll', (() => {
        this.stickyOptionScroll = cdk.scrollTop;
      }));
    }
  }

  onGroupSelected(cptG: CptGroup): void {
    this.selectGroup.emit(cptG);
  }
}
