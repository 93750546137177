<span (click)="toggleCptFamilies()" id="toggle">
  <span id="toggle-top" class="toggle-text">
    <span id="toggle-text-wrapper">{{toggleLabel}}
      <span *ngIf="additionalSelected" id="additional" class="toggle-text">{{additionalLabel}}</span>
    </span>
    <i class="fa fa-caret-down"></i></span>
</span>
<div id="cptfamily-dropdown" *ngIf="showCptFamily">
  <div id="list-wrapper">
    <div class="input-search-wrapper">
      <i id="search-glass" class="fa fa-search search-icon" aria-hidden="true"
       [matTooltip]="'3 letters required'" [matTooltipPosition]="'above'"
       [matTooltipDisabled]="searchText.length >= 3" [matTooltipClass]="'custom-tooltip'"></i>
      <input type="text" placeholder="Search..." id="search-for" (input)="onSearchTextChanged()" [(ngModel)]="searchText">
    </div>
    <mat-checkbox (click)="selectAllCptFamilies($event)" [checked]="displayedSelectedFamilies.length &&
     !displayedUnselectedFamilies.length" id="select-all">Select All</mat-checkbox>
    <div class="cptfamily-section-heading">Selected Families</div>
    <div *ngFor="let family of displayedSelectedFamilies" class="cptfamily-item"
         (click)="whenCptFamilyClicked(family, $event)">
      <mat-checkbox [checked]="true" class="selected-cptfamily">{{family.item.item.cptFamilyDesc}}</mat-checkbox>
    </div>
    <div class="cptfamily-section-heading">Other Families</div>
    <div *ngFor="let family of displayedUnselectedFamilies" class="cptfamily-item"
         (click)="whenCptFamilyClicked(family, $event)">
      <mat-checkbox [checked]="false" class="unselected-cptfamily">{{family.item.item.cptFamilyDesc}}</mat-checkbox>
    </div>
  </div>
  <div id="buttons-wrapper">
    <button (click)="apply()" id="btn-apply">Apply</button>
    <button (click)="cancel()" id="btn-cancel">Cancel</button>
  </div>
</div>
