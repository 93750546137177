import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {FeatureToggleSetting, SortingCriterion} from '../../../shared/models';
import {BaseColumn, IAppState} from '../../../store/IAppState';
import {AppAction} from '../../../store/actions';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {getPreviousYearDateRange, Page} from '../../../shared/helpers';
import {ColumnType, SortingOrder} from '../../../shared/enums';
import {hasValue} from '../../../shared/null-helpers';
import {NgRedux} from '@angular-redux/store';
import {AnalyticsService, AnalyticsServiceToken} from '../../../analytics/analytics.service';
import {numberValueFromDataName} from '../../../shared/compare-helpers';

@Component({
  selector: 'app-new-patient-visits-data-table',
  templateUrl: './new-patient-visits-data-table.component.html',
  styleUrls: ['./new-patient-visits-data-table.component.scss']
})
export class NewPatientVisitsDataTableComponent<T> implements OnChanges {
  @Input() tableTitle: string;
  @Input() receivedData: any[];
  @Input() defaultSortColumn: string;
  @Input() columns: DataTableColumns[] = [];
  @Input() originalColumns: DataTableColumns[] = [];
  @Input() sortDirection: string;
  @Input() whenSortChanged: (sortedRows: any, columnDef: string) => void;
  @Input() tieBreakerProperty?: (element: T) => string;
  @Input() rowSelectionCallback?: (element: T) => void;
  @Input() currentPage: Page;
  @Input() showLevel = true;
  @Input() showProgressBar = false;
  @Input() sortingDataAccessor?: any;
  @Input() paginate = true;
  @Input() addRemoveOption = false;
  @Input() displayZeroSuppression: boolean;
  @Input() isZeroSuppressionChecked: boolean;
  @Input() countOfSuppressedEntries: number;
  @Input() zeroSuppressionCondition: string;
  @Input() zeroSuppressionSnapshotEntry: string;
  @Input() fromPdfExport = false;
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion) => void;
  @Input() shouldPutNullsAtTheBottom = false;
  @Input() reducerAction: (columns: BaseColumn[]) => AppAction;
  @Input() initialPageSize = 25;
  @Output() pageEvent = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  pageSize = this.initialPageSize;
  pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
  dataSource: MatTableDataSource<any>;
  pageIndex = 0;
  length: number;
  displayedColumns: string[];
  sortChangeSubscribed = false;
  previousDate: string;
  settings: FeatureToggleSetting[];

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (changes && changes['initialPageSize']?.firstChange && this.paginate) {
      this.pageSize = this.initialPageSize;
      this.pageSizeOptions = this.initialPageSize < 25 ? [this.initialPageSize, 25, 50, 75, 100] : [25, 50, 75, 100];
    }
    this.previousDate = getPreviousYearDateRange(this.ngRedux.getState().filters.dateRange);
    if (this.receivedData) {
      this.dataSource =
        new MatTableDataSource<any>(this.defaultSortColumn ? this.receivedData.slice().sort(
            (a: any, b: any) => a[this.defaultSortColumn] - b[this.defaultSortColumn]).filter(x => !x.isHidden) :
          this.receivedData.slice().filter(x => !x.isHidden));
      this.settings = this.ngRedux.getState().data.featureToggleSettings;
      if (this.sortingDataAccessor) {
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
      }
      this.displayedColumns = this.columns.map(x => x.columnDef);
      this.dataSource.sortData = (data: T[], sort: MatSort): T[] => {
        const active = changes?.defaultSortColumn ? this.defaultSortColumn : sort.active;
        const column = this.columns.find(c => c.columnDef === active);
        const direction = sort.direction;
        if (!active || direction === '') {
          return data;
        }
        const dataElement = data.sort((a, b) => {
          return this.compare(a, b, active, direction === 'asc', column);
        });
        return dataElement;
      };
      if (this.paginate) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize = this.pageSize;
        this.dataSource.paginator.pageIndex = this.pageIndex;
        this.dataSource.paginator.length = this.receivedData.length;
      }
      this.dataSource.sort = this.sort;
      if (!this.sortChangeSubscribed) {
        this.sortChangeSubscribed = true;
        this.dataSource.sort.sortChange.subscribe(() => {
          if (this.whenSortChanged) {
            const sortingCriteria: SortingCriterion = {
              sortingOrder: this.sort.direction === SortingOrder.ASCENDING ? SortingOrder.ASCENDING : SortingOrder.DESCENDING,
              columnDef: this.sort.active
            };
            this.whenSortingCriteriaChanged(sortingCriteria);
            this.whenSortChanged(this.dataSource.sortData(this.dataSource.data, this.sort), this.sort.active);
          }
        });
      }
    }
  }

  whenSortingCriteriaChanged(sortingCriteria: SortingCriterion): void {
    return this.updateSortingCriteria && this.updateSortingCriteria(sortingCriteria);
  }

  compare(a: T, b: T, sortHeaderId: string, sortAscending: boolean, column?: DataTableColumns): number {
    let valueA = this.dataSource.sortingDataAccessor(a, sortHeaderId);
    let valueB = this.dataSource.sortingDataAccessor(b, sortHeaderId);
    if (column?.columnType === ColumnType.NODE_NAME) {
      return sortAscending ? this.getTieBreakerString(a).localeCompare(this.getTieBreakerString(b)) :
        this.getTieBreakerString(b).localeCompare(this.getTieBreakerString(a));
    } else {
      valueA = column ? numberValueFromDataName(column, a) || valueA : valueA;
      valueB = column ? numberValueFromDataName(column, b) || valueB : valueB;
    }
    const multiplier = sortAscending ? 1 : -1;
    if (this.shouldPutNullsAtTheBottom) {
      if (!hasValue(valueB) && hasValue(valueA)) {
        return multiplier;
      } else if (hasValue(valueB) && !hasValue(valueA)) {
        return -1 * multiplier;
      }
    }
    valueA = valueA || 0;
    valueB = valueB || 0;
    if (valueA > valueB) {
      return multiplier;
    }
    if (valueA < valueB) {
      return -1 * multiplier;
    }
    return this.getTieBreakerString(a).localeCompare(this.getTieBreakerString(b));
  }

  getTieBreakerString(item: T): string {
    return this.tieBreakerProperty ? this.tieBreakerProperty(item) : '';
  }

  updateDataTable(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageEvent.emit(event);
  }

  getCssClassForColumnType(column: any): string {
    switch (column.columnType) {
      case ColumnType.NODE_NAME:
        return 'nodeName';
      case ColumnType.BENCHMARK:
        return 'benchmarkCell';
      case ColumnType.VARIANCE:
        return 'varianceCell';
      case ColumnType.IMPUTED:
        return 'imputedCell';
      default:
        return '';
    }
  }

  selectRow(selectedRow: T) {
    return this.rowSelectionCallback && this.rowSelectionCallback(selectedRow);
  }
}
