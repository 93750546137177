import {BaseColumn, SummaryData} from '../../store/IAppState';
import {NewPatientVisitSummary} from '../../shared/models';
import {DataTableColumns} from '../../shared/data-table-columns';
import {ExportMetadata} from '../../shared/export/export';
import {BenchmarkOption, ColumnType, MultilevelTab} from '../../shared/enums';
import {
  NpvSummaryData,
  populateSummaryDataForAllBenchmarks,
  populateSummaryDataForAllBenchmarksForExcel
} from '../../new-patient-visits/components/new-patient-visits-summary-data-table/new-patient-visits-summary-data-table.component';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {getSingularOntologyLevelName, toTitleCase} from '../../shared/helpers';
import {
  npvByDepartmentExcelData$,
  npvByLocationByDepartmentExcelData$,
  npvByLocationByProviderExcelData$,
  npvByLocationBySpecialtyExcelData$,
  npvByProviderExcelData$,
  npvBySpecialtyExcelData$,
  npvByTrendExcelData$
} from '../export-subscribers';
import {BehaviorSubject} from 'rxjs';
import {
  MergedNewPatientVisitSnapshotEntry,
  MergedNpvLocationAggregatedByNode,
  NewPatientVisitTrendEntry,
  NpvLocationWithSnapshotEntries
} from '../../new-patient-visits/components/npv-models';
import {BenchmarkPercentilesForNpv} from '../../shared/BenchmarkColumns';
import {
  getLevelSpecificNpvLocationTableData,
  replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor,
  replaceNpvSnapshotVarianceColumnWithBenchmarkFor,
  replaceNpvTrendBenchmarkColumnWithBenchmarkFor,
  replaceNpvTrendVarianceColumnWithBenchmarkFor
} from '../../new-patient-visits/components/npv-helpers';

function insertAllBenchmarkDependantColumnsForNpv(
  benchmarkPercentiles: BenchmarkPercentile[],
  columns: DataTableColumns[],
  viewCommunityBenchmarks: boolean,
  benchmarkOption: BenchmarkOption,
  telehealthEnabled: boolean,
  replaceBenchmark: (arg0: BenchmarkPercentile, arg1: BenchmarkOption, arg2: boolean, arg3: boolean) => DataTableColumns,
  replaceVariance: (arg0: BenchmarkPercentile, arg1: BenchmarkOption, arg2: boolean) => DataTableColumns): DataTableColumns[] {
  // TODO fix benchmark dependencies
  const benchmarkColumns: DataTableColumns[] = [];
  const varianceColumns: DataTableColumns[] = [];
  benchmarkOption = benchmarkOption || (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
  benchmarkPercentiles.forEach(bcm => {
    benchmarkColumns.push(replaceBenchmark(bcm, benchmarkOption, telehealthEnabled, false));
    varianceColumns.push(replaceVariance(bcm, benchmarkOption, telehealthEnabled));
  });
  const hasBenchmark = columns.find(col => col.columnType === ColumnType.BENCHMARK);
  if (hasBenchmark) {
    columns = columns.filter(col => col.columnType !== ColumnType.BENCHMARK);
    benchmarkColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasVariance = columns.find(col => col.columnType === ColumnType.VARIANCE);
  if (hasVariance) {
    columns = columns.filter(col => col.columnType !== ColumnType.VARIANCE);
    varianceColumns.forEach(varCol => {
      columns.push(varCol);
    });
  }
  return columns;
}

export async function generateExcelNpvTrendDataForDisplayedColumns(
  summaryData: SummaryData<NewPatientVisitSummary>,
  trendData: NewPatientVisitTrendEntry[],
  displayedDetailColumns: BaseColumn[],
  detailColumns: DataTableColumns[],
  summaryColumns: DataTableColumns[],
  viewCommunityBenchmarks: boolean,
  benchmarkOption: BenchmarkOption,
  telehealthEnabled: boolean) {
  const summary = populateSummaryDataForAllBenchmarks(summaryData, viewCommunityBenchmarks, telehealthEnabled, benchmarkOption);
  detailColumns = detailColumns.filter(col => col.primaryColumn || displayedDetailColumns.find(c => c.columnDef === col.columnDef
    || (c.columnType === col.columnType && c.columnType !== undefined))).slice();
  detailColumns = insertAllBenchmarkDependantColumnsForNpv(BenchmarkPercentilesForNpv, detailColumns, viewCommunityBenchmarks,
    benchmarkOption, telehealthEnabled, replaceNpvTrendBenchmarkColumnWithBenchmarkFor,
    replaceNpvTrendVarianceColumnWithBenchmarkFor);
  const copyRight = 'Clinical Practice Solutions Center New Patient Visits ' + new Date().toLocaleString() + ';';
  npvByTrendExcelData$.next({
    summaryHeaders: ['Time Range'].concat(summary.map(s => s.metric)),
    summaryData: summaryColumns.splice(1).map(col => {
      return [col.header].concat(generateRowForNpvSummary(col, summary));
    }),
    detailHeaders: detailColumns.map(c => c.header),
    detailData: trendData.map(npv => {
      return generateRowForNpvTrend(npv, detailColumns);
    }),
    fileName: ('Npv Trend'),
    page: 'Npv Trend',
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showVisitType: true
    },
    sheetName: 'NpvTrend'
  });
}

export async function generateExcelNpvSnapshotDataForDisplayedColumns(summaryData: SummaryData<NewPatientVisitSummary>,
                                                                      providerData: MergedNewPatientVisitSnapshotEntry[],
                                                                      displayedDetailColumns: BaseColumn[],
                                                                      detailColumns: DataTableColumns[],
                                                                      summaryColumns: DataTableColumns[],
                                                                      viewCommunityBenchmarks: boolean,
                                                                      level: string,
                                                                      benchmarkOption: BenchmarkOption,
                                                                      telehealthEnabled: boolean) {
  const summary = populateSummaryDataForAllBenchmarks(summaryData, viewCommunityBenchmarks, telehealthEnabled, benchmarkOption);
  detailColumns = detailColumns.filter(col => displayedDetailColumns.find(c => c.columnDef === col.columnDef
    || c.columnType === col.columnType || (c.columnType === col.columnType && c.columnType !== undefined))).slice();
  detailColumns = insertAllBenchmarkDependantColumnsForNpv(BenchmarkPercentilesForNpv, detailColumns, viewCommunityBenchmarks,
    benchmarkOption, telehealthEnabled, replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor,
    replaceNpvSnapshotVarianceColumnWithBenchmarkFor);
  const copyRight = 'Clinical Practice Solutions Center New Patient Visits ' + new Date().toLocaleString() + ';';
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (level) {
    case 'department':
      designatedObservable = npvByDepartmentExcelData$;
      break;
    case 'specialty':
      designatedObservable = npvBySpecialtyExcelData$;
      break;
    case 'provider':
      designatedObservable = npvByProviderExcelData$;
  }
  designatedObservable.next({
    summaryHeaders: ['Time Range'].concat(summary.map(s => s.metric)),
    summaryData: summaryColumns.splice(1).map(col => {
      return [col.header].concat(generateRowForNpvSummary(col, summary));
    }),
    detailHeaders: ['By ' + detailColumns[0].header].concat(detailColumns.slice(1).map(c => c.header)),
    detailData: providerData.map(npv => {
      return generateRowForNpvSnapshot(npv, detailColumns);
    }),
    fileName: ('Npv by ' + level),
    page: 'Npv by ' + level,
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showVisitType: true
    },
    sheetName: 'NpvBy' + toTitleCase(level)
  });
}

export async function generateExcelNpvLocationByDimensionDataForDisplayedColumns(summaryData: SummaryData<NewPatientVisitSummary>,
                                                                                 providerLocationData: MergedNpvLocationAggregatedByNode[],
                                                                                 displayedDetailColumns: BaseColumn[],
                                                                                 detailColumns: DataTableColumns[],
                                                                                 summaryColumns: DataTableColumns[],
                                                                                 viewCommunityBenchmarks: boolean,
                                                                                 tab: MultilevelTab,
                                                                                 benchmarkOption: BenchmarkOption,
                                                                                 telehealthEnabled: boolean) {
  // TODO: Can the following be extracted into a function as this code is repeated elsewhere.
  detailColumns = detailColumns.filter(col => displayedDetailColumns.find(c => c.columnDef === col.columnDef
    || (c.columnType === col.columnType && c.columnType !== undefined))).slice();
  const summary = populateSummaryDataForAllBenchmarksForExcel(summaryData, viewCommunityBenchmarks, telehealthEnabled, benchmarkOption);
  detailColumns = insertAllBenchmarkDependantColumnsForNpv(BenchmarkPercentilesForNpv, detailColumns, viewCommunityBenchmarks,
    benchmarkOption, telehealthEnabled, replaceNpvSnapshotBenchmarkColumnWithBenchmarkFor,
    replaceNpvSnapshotVarianceColumnWithBenchmarkFor);
  const copyRight = 'Clinical Practice Solutions Center New Patient Visits ' + new Date().toLocaleString() + ';';
  let designatedObservable: BehaviorSubject<ExportMetadata | boolean> = new BehaviorSubject<ExportMetadata | boolean>(false);
  switch (tab) {
    case MultilevelTab.LOCATION_DEPARTMENT:
      designatedObservable = npvByLocationByDepartmentExcelData$;
      break;
    case MultilevelTab.LOCATION_SPECIALTY:
      designatedObservable = npvByLocationBySpecialtyExcelData$;
      break;
    case MultilevelTab.LOCATION_PROVIDER:
      designatedObservable = npvByLocationByProviderExcelData$;
  }
  const detailData: string[][] = [];
  const levelSpecificData: NpvLocationWithSnapshotEntries[] = getLevelSpecificNpvLocationTableData(
    false, tab, false, providerLocationData).entries;
  levelSpecificData.forEach(pLD => {
    const rows: string[][] = generateRowsForNpvLocation(pLD, detailColumns);
    rows.forEach(row => {
      detailData.push(row);
    });
  });
  const level: string = getSingularOntologyLevelName(tab).toLowerCase();
  designatedObservable.next({
    summaryHeaders: ['Time Range', ''].concat(summary.map(s => s.metric)),
    summaryData: summaryColumns.splice(1).map(col => {
      return [col.header].concat(generateRowForNpvLocationSummary(col, summary));
    }),
    detailHeaders: ['By ' + detailColumns[0].header].concat(detailColumns.slice(1).map(c => c.header)),
    detailData: detailData,
    fileName: ('Npv Location by ' + level),
    page: 'Npv Location by ' + level,
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showVisitType: true
    },
    sheetName: 'NpvBy' + toTitleCase(level)
  });
}

function generateRowForNpvSummary(column: DataTableColumns, summary: NpvSummaryData[]): string[] {
  const row: string[] = [];
  summary.forEach(entry => {
    row.push(column.dataName(entry));
  });
  return row;
}

function generateRowForNpvLocationSummary(column: DataTableColumns, summary: NpvSummaryData[]): string[] {
  const row: string[] = [''];
  summary.forEach(entry => {
    row.push(column.dataName(entry));
  });
  return row;
}

function generateRowForNpvTrend(entry: NewPatientVisitTrendEntry, displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    row.push(col.dataName(entry));
  });
  return row;
}

function generateRowForNpvSnapshot(providerEntry: MergedNewPatientVisitSnapshotEntry,
                                   displayedColumns: DataTableColumns[]): string[] {
  const row: string[] = [];
  displayedColumns.forEach(col => {
    row.push(col.dataName(providerEntry));
  });
  return row;
}

function generateRowsForNpvLocation(providerLocationEntry: NpvLocationWithSnapshotEntries,
                                    displayedColumns: DataTableColumns[]): string[][] {
  const rows: string[][] = [];
  const nodeColumns: DataTableColumns[] = displayedColumns.slice(1);
  const nodeList: MergedNewPatientVisitSnapshotEntry[] = displayedColumns[1].dataNameList(providerLocationEntry);
  nodeList.forEach(item => {
    const row: string[] = [displayedColumns[0].dataName(providerLocationEntry)];
    nodeColumns.forEach(col => {
      row.push(col.dataName(item));
    });
    rows.push(row);
  });
  return rows;
}
