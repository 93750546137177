import {HierarchicalSelectableNode, OntologyNode, SelectableNode} from '../../shared/models';
import {OntologyLevel} from '../../shared/enums';
import {getTagId} from '../../shared/helpers';

export function getNodeDataFromHierarchy(ontologyDepartments: OntologyNode[]): NodeData {
  const nodeDataSetup: NodeDataSetup = {
    existingProviders: [],
    existingSpecialties: new Map<string, HierarchicalSelectableNode>(),
    existingDepartments: new Map<string, HierarchicalSelectableNode>()
  };
  populateDepartments(ontologyDepartments, nodeDataSetup);
  const nodeData: NodeData = {
    listOfAllProviders: nodeDataSetup.existingProviders,
    listOfAllSpecialties: Array.from(nodeDataSetup.existingSpecialties.values()),
    listOfAllDepartments: Array.from(nodeDataSetup.existingDepartments.values())
  };
  return nodeData;
}

function populateDepartments(ontologyDepartments: OntologyNode[], ontologyDataSetup: NodeDataSetup): void {
  ontologyDepartments.forEach(node => {
    const departmentNode: HierarchicalSelectableNode = {
      selectableNode: {
        node: node,
        selected: false,
        matchesFilterSearchText: true,
        belongs: true,
        level: OntologyLevel.Department,
      },
      ancestors: new Map<string, SelectableNode[]>(),
      descendants: new Map<string, SelectableNode[]>()
    };
    ontologyDataSetup.existingDepartments.set(getTagId(node.nodePath), departmentNode);
    ontologyDataSetup.currentDepartment = departmentNode;

    if (node.children && node.children.length >= 1) {
      populateSpecialties(node.children, ontologyDataSetup);
    }
  });
}

function populateSpecialties(ontologySpecialties: OntologyNode[], ontologyDataSetup: NodeDataSetup): void {
  ontologySpecialties.forEach(node => {
    const tagId: string = getTagId(node.nodePath);
    ontologyDataSetup.currentSpecialty = ontologyDataSetup.existingSpecialties.get(tagId);

    if (!ontologyDataSetup.currentSpecialty) {
      ontologyDataSetup.currentSpecialty = {
        selectableNode: {
          node: node,
          selected: false,
          matchesFilterSearchText: true,
          belongs: true,
          level: OntologyLevel.Specialty
        },
        ancestors: new Map<string, SelectableNode[]>(),
        descendants: new Map<string, SelectableNode[]>(),
      };
      ontologyDataSetup.existingSpecialties.set(tagId, ontologyDataSetup.currentSpecialty);
    }

    if (ontologyDataSetup.currentDepartment) {
      const depId: string = getTagId(ontologyDataSetup.currentDepartment.selectableNode.node.nodePath);
      ontologyDataSetup.currentSpecialty.ancestors.set(depId, [ontologyDataSetup.currentDepartment.selectableNode]);
      ontologyDataSetup.currentDepartment.descendants.set(tagId, [ontologyDataSetup.currentSpecialty.selectableNode]);
    }

    if (node.children && node.children.length >= 1) {
      populateProviders(node.children, ontologyDataSetup);
    }
  });
}

function populateProviders(ontologyProviders: OntologyNode[], ontologyDataSetup: NodeDataSetup): void {
  ontologyProviders.forEach(node => {
    const tagId: string = getTagId(node.nodePath);
    const providerHierarchicalNode: HierarchicalSelectableNode = {
      selectableNode: {
        node: node,
        selected: false,
        matchesFilterSearchText: true,
        belongs: true,
        level: OntologyLevel.Provider
      },
      ancestors: new Map<string, SelectableNode[]>(),
      descendants: new Map<string, SelectableNode[]>()
    };
    setAncestorsAndDescendents(ontologyDataSetup.currentDepartment, providerHierarchicalNode, tagId);
    setAncestorsAndDescendents(ontologyDataSetup.currentSpecialty, providerHierarchicalNode, tagId);
    ontologyDataSetup.existingProviders.push(providerHierarchicalNode);
  });
}

function setAncestorsAndDescendents(parent: HierarchicalSelectableNode | undefined,
                                    providerHierarchicalNode: HierarchicalSelectableNode, tagId: string) {
  if (parent) {
    const parentTagId: string = getTagId(parent.selectableNode.node.nodePath);
    providerHierarchicalNode.ancestors.set(parentTagId, [parent.selectableNode]);
    const originalDescendants = parent.descendants.get(tagId) || [];
    parent.descendants.set(tagId, originalDescendants.concat([
      providerHierarchicalNode.selectableNode
    ]));
  }
}

interface NodeDataSetup {
  existingDepartments: Map<string, HierarchicalSelectableNode>;
  existingSpecialties: Map<string, HierarchicalSelectableNode>;
  existingProviders: HierarchicalSelectableNode[];
  currentDepartment?: HierarchicalSelectableNode;
  currentSpecialty?: HierarchicalSelectableNode;
}

export interface NodeData {
  listOfAllDepartments: HierarchicalSelectableNode[];
  listOfAllSpecialties: HierarchicalSelectableNode[];
  listOfAllProviders: HierarchicalSelectableNode[];
}
