import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { unauthorizedErrorRoute } from '../routing';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  suppressErrors: string[] = ['clinicalpracticesolutionscenter.org'];
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any) {
    let suppress = false;
    this.suppressErrors.forEach((value: string) => {
      if (error.toString().toLowerCase().indexOf(value.toLowerCase()) > 0) {
        suppress = true;
      }
    });

    if (!suppress) {
      super.handleError(error);
      const router = this.injector.get(Router);
      console.log('Error is : ', error);
      router.navigateByUrl(unauthorizedErrorRoute);
    }
  }
}
