export enum FeatureToggleEntries {
  CPT_ANALYSIS_EXPORT = 'cpt.analysis.export',
  EM_EXPORT_OPTIONS = 'em.export.options',
  DENIALS_MODAL_REASONS = 'denials.modal.reasons',
  DENIALS_CPTS_DENIED_MODAL = 'denials.cpts.denied.modal',
  ENABLE_MULTIPROVIDER_SECURITY = 'enable_multiprovider_security',
  SHOW_CFP_COLUMNS = 'show.cfp.columns',
  SHOW_EM_MULTIPROVIDER_EXPORT = 'show.em.multiprovider.export',
  CPT_FILTER = 'cpt.filter',
  MULTI_PROVIDER_EXPORT_SCHEDULING = 'multiprovider.export.scheduling',
  NPV_MULTI_PROVIDER_EXPORT = 'npv.multi.provider.export',
  HIDE_WRVU_BY_DEPARTMENT_BENCHMARK = 'hide.wrvu.by.department.benchmark',
  HIDE_WRVU_BY_DEPARTMENT_CFTE_ADJ_WRVUS = 'hide.wrvu.by.department.cfte.adj.wrvus',
  NPV_BY_LOCATION = 'npv.by.location',
  REDESIGN_MULTIPROVIDER_EXPORT = 'redesigned.multiprovider.export',
  MULTI_PROVIDER_BENCHMARK_SELECTORS = 'multi.provider.benchmark.selectors',
  BATCH_EXPORT_WRVU_TREND = 'batch.export.wrvu.trend',
  HIDE_PROCEDURE_SUMMARY_CFTE_WRVUS = 'hide.ps.cfte.wrvus',
  HIDE_PROCEDURE_SUMMARY_CFTE_COUNT  = 'hide.ps.cfte.count',
  MPE_NPV_BY_LOCATION = 'npv.location.mpe',
  CANCEL_DELETE_SCHEDULED_EXPORTS = 'cancel.delete.scheduled.exports',
  ENCOUNTER_REPORTS = 'encounter.reports',
  ZIP_CODE_BUBBLES = 'zip.code.bubbles',
  LIMIT_EXPORTED_PROVIDERS = 'disable.mpe.based.on.provider.count',
  PMT_PER_RVU_REPORT = 'pay.per.rvu.report',
  SHOW_UPDATED_CPT_CODES = 'show.updated.cpt.codes'
}
