import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  clinicalFingerPrintRoute,
  collectionsRoute,
  denialsRoute,
  emPageRoute,
  npvRoute,
  overviewPageRoute,
  procedureSummaryRoute,
  wRVUsRoute
} from '../shared/routes';
import {getAppConfigValue, isFeatureEnabled} from '../shared/helpers';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState, INITIAL_STATE} from '../store/IAppState';
import {BehaviorSubject, combineLatest, Observable, Subscription, timer} from 'rxjs';
import {ApplicationConfigurationSetting, BatchExportReportDetailsResponse, FeatureToggleSetting, UserProfile} from '../shared/models';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {AppConfigEntries} from '../shared/app-config-settings-enum';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import * as _ from 'lodash';
import {batchExportReportDetailsChangedTo, batchExportScheduleReportDetailsChangedTo, newProviderExportsChangedTo} from '../store/actions';
import {Router} from '@angular/router';
import {BatchExportServiceToken, HttpBatchExportService} from '../batch-export/services/batch-export.service';
import {BatchExportStatus, GoogleAnalyticCategories, GoogleAnalyticsActions, GoogleAnalyticsLabels} from '../shared/enums';
import {filter, skipWhile, take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ExportedReportsDialogComponent} from '../batch-export/exported-reports-dialog/exported-reports-dialog.component';
import {
  BatchExportScheduleExistingReportResponseEntity,
  BatchExportScheduleExistingReportsResponse
} from '../batch-export/multi-provider-export-redesign/mpe-dialog-helper';
import {countUnreadInstanceScheduledReports} from '../batch-export/exported-reports-dialog/exported-reports-dialog-helper';
import {ExportedReportsRedesignDialogComponent} from '../batch-export/exported-reports-dialog/exported-reports-redesign-dialog/exported-reports-redesign-dialog.component';

@Component({
  selector: 'app-metric-nav',
  templateUrl: './metric-nav.component.html',
  styleUrls: ['./metric-nav.component.scss']
})
export class MetricNavComponent implements OnInit, OnDestroy {

  // noinspection JSUnusedLocalSymbols
  routes: any[];

  @select(['ontologyLoaded'])
  private readonly ontologyLoaded$: Observable<boolean>;

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  @select(['data', 'applicationConfigurationSettings'])
  private readonly applicationConfigurationSettings$: Observable<ApplicationConfigurationSetting[]>;

  @select(['display', 'isProviderSelected'])
  private readonly isProviderSelected$: Observable<boolean>;

  @select(['display', 'filterBannerEdit'])
  readonly filterBannerEdit$: Observable<boolean>;

  @select(['display', 'newProviderExports'])
  readonly newProviderExports$: Observable<number>;

  @select(['data', 'userProfile'])
  readonly userProfile$: Observable<UserProfile>;

  @select(['filters', 'memberKey'])
  readonly memberKey$: Observable<number>;

  // showMoreApp = false;
  applicationSettings: ApplicationConfigurationSetting[];
  featureToggleReady = false;
  // showMyReports = false;
  subscription: Subscription;
  pollingSubscription: Subscription;
  redesignMultiProviderExportEnabled = false;
  pollingInterval = 30; // in seconds so the default is polling every half minute
  userProfile: UserProfile;
  memberKey: number;
  reports: ReportInfo[];
  private scheduledReportsListener: BehaviorSubject<(BatchExportScheduleExistingReportResponseEntity[][] | undefined)>;
  private featureSettings: FeatureToggleSetting[] = [];

  constructor(private readonly ngRedux: NgRedux<IAppState>,
              private router: Router,
              public dialog: MatDialog,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.subscription = combineLatest([
      this.userProfile$,
      this.memberKey$,
      this.applicationConfigurationSettings$,
      this.featureToggleSettings$]
    ).pipe(skipWhile(([userProfile, memberKey, applicationSettings, featureSettings]:
                        [UserProfile, number, ApplicationConfigurationSetting[], FeatureToggleSetting[]]) =>
      memberKey < 0 || !userProfile.email || !applicationSettings?.length || !featureSettings?.length))
      .subscribe(([userProfile, memberKey, applicationSettings, featureSettings]) => {
        this.userProfile = userProfile;
        this.memberKey = memberKey;
        this.applicationSettings = applicationSettings;
        this.featureSettings = featureSettings;
        this.featureToggleReady = !_.isEqual(INITIAL_STATE.data.featureToggleSettings, this.featureSettings);
        this.redesignMultiProviderExportEnabled = isFeatureEnabled(FeatureToggleEntries.REDESIGN_MULTIPROVIDER_EXPORT,
          this.featureSettings, this.userProfile);
        const polling = +getAppConfigValue(AppConfigEntries.POLLING_INTERVAL, this.applicationSettings);
        if (polling) {
          this.pollingInterval = polling;
        }
        this.scheduledReportsListener = new BehaviorSubject<(BatchExportScheduleExistingReportResponseEntity[][] | undefined)>(undefined);
        const updatedExportEnabled = isFeatureEnabled(FeatureToggleEntries.REDESIGN_MULTIPROVIDER_EXPORT, featureSettings,
          userProfile);
        // Polling every this.pollingInterval seconds to fetch the latest count of reports that are unread
        this.pollingSubscription?.unsubscribe();
        this.pollingSubscription = combineLatest([
          this.ontologyLoaded$.pipe(filter((ontologyLoaded: boolean) => ontologyLoaded)),
          timer(0, this.pollingInterval * 1000)
        ]).subscribe(() => this.fetchBatchExportDetails(updatedExportEnabled));
        this.setRoutes();
        this.setReports();
      });
  }

  fetchBatchExportDetails(updatedExportEnabled: boolean): void {
    combineLatest([this.batchExportService.getBatchExportReportDetailsByUser(this.memberKey)
      .pipe(take(1)), this.getBatchExportScheduleData()])
      .subscribe(([rsp, schedule_data]: [BatchExportReportDetailsResponse,
        BatchExportScheduleExistingReportResponseEntity[][]]) => {
        this.ngRedux.dispatch(batchExportReportDetailsChangedTo(rsp.batchExportReportDetails));
        const completedUnreadReports = rsp.batchExportReportDetails.filter(
          r => r.reportStatus === BatchExportStatus.COMPLETED && !r.readStatus);
        const unreadInstanceScheduledReports = countUnreadInstanceScheduledReports(schedule_data);
        this.ngRedux.dispatch(newProviderExportsChangedTo(completedUnreadReports.length + unreadInstanceScheduledReports));
      }, (e) => {
        console.log('ERROR fetching exported files: ' + e);
      });

    this.batchExportService.getScheduledBatchExportsByUser(this.memberKey)
      .pipe(take(1))
      .subscribe((rsp: BatchExportScheduleExistingReportsResponse) => {
        this.ngRedux.dispatch(batchExportScheduleReportDetailsChangedTo(rsp.batchExportScheduleExistingReports));
        if (updatedExportEnabled) {
          this.scheduledReportsListener.next(rsp.batchExportScheduleExistingReports);
        } else {
          this.scheduledReportsListener.next([]);
        }
      });
  }

  private getBatchExportScheduleData(): Observable<(BatchExportScheduleExistingReportResponseEntity[][] | undefined)> {
    return this.scheduledReportsListener.asObservable();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.pollingSubscription?.unsubscribe();
  }

  setReports() {
    this.reports = [
      {id: 'recovery-dashboard', appConfigDesc: AppConfigEntries.RECOVERY_DASHBOARD, reportName: 'Recovery Dashboard'},
      {id: 'app-reports', appConfigDesc: AppConfigEntries.APP_REPORT, reportName: 'APP Reports'},
      {id: 'att-reports', appConfigDesc: AppConfigEntries.ATT_REPORT, reportName: 'A&T Reports'}]
      .concat(isFeatureEnabled(FeatureToggleEntries.ENCOUNTER_REPORTS, this.featureSettings, this.userProfile)
        ? [{id: 'patient-panels-encounter-reports', appConfigDesc: AppConfigEntries.ENCOUNTER_REPORTS, reportName: 'Encounters Reports'}]
        : [])
      .concat(isFeatureEnabled(FeatureToggleEntries.PMT_PER_RVU_REPORT, this.featureSettings, this.userProfile)
        ? [{id: 'pmt-per-rvu-reports', appConfigDesc: AppConfigEntries.PMT_PER_RVU_REPORT, reportName: 'Payment per RVU Reports'}]
        : []);
  }

  setRoutes(): void {
    this.routes = [
      {href: overviewPageRoute, description: 'Overview', hide: false},
      {href: wRVUsRoute, description: 'wRVUs'},
      {href: clinicalFingerPrintRoute, description: 'Clinical Fingerprint', hide: false},
      {href: procedureSummaryRoute, description: 'Procedure Summary', hide: false},
      {href: npvRoute, description: 'New Patient Visits'},
      {href: emPageRoute, description: 'Evaluation & Management'},
      {href: collectionsRoute, description: 'Collections', hide: !this.ngRedux.getState().display.isRevenueMember},
      {href: denialsRoute, description: 'Denials', hide: !this.ngRedux.getState().display.isRevenueMember}
    ];
  }

  openAccessAndThroughput() {
    window.open(getAppConfigValue(AppConfigEntries.ACCESS_AND_THROUGHPUT, this.applicationSettings));
    this.analyticsService.handleGoogleAnalytics('MoreApps', 'More Apps Click', 'Access and Throughput');
  }

  openOppDashboard() {
    window.open(getAppConfigValue(AppConfigEntries.OPPDASHBOARD_WEBSITE, this.applicationSettings));
    this.analyticsService.handleGoogleAnalytics('MoreApps', 'More Apps Click', 'Opportunity Dashboard');
  }

  openClinicalActivity() {
    let url: string = getAppConfigValue(AppConfigEntries.CLINICAL_ACTIVITY_WEBSITE, this.applicationSettings);
    url = url.replace('@memberKey', this.memberKey + '');
    window.open(url);
    this.analyticsService.handleGoogleAnalytics('MoreApps', 'More Apps Click', 'Clinical Activity');
  }

  openReport(report: ReportInfo): void {
    window.open(getAppConfigValue(report.appConfigDesc, this.applicationSettings));
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.MyReports, 'My Reports Click', report.reportName);
  }

  openMultiProviderExports(): void {
    this.analyticsService.handleGoogleAnalytics(GoogleAnalyticCategories.MyReports, GoogleAnalyticsLabels.MyReportsClick,
      GoogleAnalyticsActions.MultiProviderExports);
    if (this.redesignMultiProviderExportEnabled) {
      this.dialog.open(ExportedReportsRedesignDialogComponent, {
        panelClass: 'my-reports-redesigned'
      });
    } else {
      this.dialog.open(ExportedReportsDialogComponent);
    }
  }

  handleMyReportsGoogleAnalytics() {
    this.analyticsService.handleGoogleAnalytics('MyReports', 'My Reports Click', 'My Reports');
  }
}

interface ReportInfo {
  id: string;
  appConfigDesc: string;
  reportName: string;
}
