import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {BatchExportDetail, VisitType} from '../../../shared/models';
import {getMonthFromNumberOfMonths, getSelectedDateRange, getYearFromNumberOfMonths} from '../../../shared/helpers';
import {visitTypes} from '../../../shared/constants';

@Component({
  selector: 'app-batch-export-pdf-header',
  templateUrl: './batch-export-pdf-header.component.html',
  styleUrls: ['./batch-export-pdf-header.component.scss']
})
export class BatchExportPdfHeaderComponent implements OnChanges, AfterViewInit {
  @Input() batchExportDetails: BatchExportDetail;
  @Input() showPayer = true;
  @Input() headerClass: string;
  memberName: string;
  departmentName: string;
  specialtyName: string;
  providerName: string;
  dateRange: string;
  payerCategoryName: string;
  memberLocationName: string;
  multiMemberLocationName: string;
  visitType: VisitType;
  margin: number;

  @ViewChild('header', {static: false}) header: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  private static calculateDateRange(startDateInMonths: number, endDateInMonths: number): string {
    const startMonth = getMonthFromNumberOfMonths(startDateInMonths);
    const endMonth = getMonthFromNumberOfMonths(endDateInMonths);
    const startYear = getYearFromNumberOfMonths(startDateInMonths);
    const endYear = getYearFromNumberOfMonths(endDateInMonths);
    return getSelectedDateRange({
      startYear: startYear, endYear: endYear, startMonth: startMonth, endMonth: endMonth
    });
  }

  ngAfterViewInit() {
    this.margin = (window.innerWidth - this.header.nativeElement.offsetWidth) / 2;
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(): void {
    if (this.batchExportDetails) {
      this.multiMemberLocationName = this.batchExportDetails.multiLocationMessage;
      this.memberName = this.batchExportDetails.memberName;
      this.departmentName = this.batchExportDetails.departmentName;
      this.specialtyName = this.batchExportDetails.specialtyName;
      this.providerName = this.batchExportDetails.providerName;
      this.dateRange = BatchExportPdfHeaderComponent.calculateDateRange(this.batchExportDetails.startDateInMonths,
        this.batchExportDetails.endDateInMonths);
      this.payerCategoryName = this.batchExportDetails.payerCategoryName;
      this.memberLocationName = this.batchExportDetails.memberLocationName;
      this.visitType = visitTypes[this.batchExportDetails.telehealthFlag ?? 0];
    }
  }
}
