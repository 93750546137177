import {MetricType} from '../shared/metric-types';
import {BenchmarkOption, CptViewType, MultilevelTab, NodeExpansionState} from '../shared/enums';
import {CurrentSelections, Data, FilterCriteria} from '../store/IAppState';
import {EmDimension, Members, PdfExportData} from '../shared/models';
import * as _ from 'lodash';
import {ExportType, getSelectedDateRange, isFeatureEnabled, lagPeriods, LevelType} from '../shared/helpers';
import {ExpandableExportOption, Export, ExportMetadata, ExportNew, WhatFilters} from '../shared/export/export';
import {ExcelService} from '../services/excel.service';
import {AnalyticsService} from '../analytics/analytics.service';
import {
  clinicalFingerPrintRoute,
  collectionsDepartmentPageRoute,
  collectionsProviderPageRoute,
  collectionsSpecialtyPageRoute,
  denialsDepartmentPageRoute,
  denialsPayerPageRoute,
  denialsProviderPageRoute,
  denialsSpecialtyPageRoute,
  emEmergencyMedicineDepartmentRoute,
  emEmergencyMedicineProviderRoute,
  emEmergencyMedicineRoute,
  emEmergencyMedicineSpecialtyRoute,
  emInpatientDepartmentRoute,
  emInpatientProviderRoute,
  emInpatientRoute,
  emInpatientSpecialtyRoute,
  emOphthalmologyDepartmentRoute,
  emOphthalmologyProviderRoute,
  emOphthalmologyRoute,
  emOphthalmologySpecialtyRoute,
  emOutpatientDepartmentRoute,
  emOutpatientProviderRoute,
  emOutpatientRoute,
  emOutpatientSpecialtyRoute,
  npvDepartmentPageRoute,
  npvProviderPageRoute,
  npvSpecialtyPageRoute,
  npvTrendPageRoute,
  payerCollectionsMultiLevelRoute,
  procedureSummaryRoute,
  summaryCollectionsRoute,
  wRVUDepartmentPageRoute,
  wRVUProviderPageRoute,
  wRVUSpecialtyPageRoute,
  wRVUSpecialtyPerformancePageRoute,
  wRVUTrendPageRoute
} from '../shared/routes';
import {
  collectionSummaryColumns,
  columnsForDenialsByPayer,
  columnsForMultiLevelCollectionsDepartment,
  columnsForMultiLevelCollectionsProvider,
  columnsForMultiLevelCollectionsSpecialty,
  columnsForMultiLevelDenialsDepartment,
  columnsForMultiLevelDenialsProvider,
  columnsForMultiLevelDenialsSpecialty,
  columnsForMultiLevelPayerCollectionsDepartment,
  columnsForMultiLevelPayerCollectionsProvider,
  columnsForMultiLevelPayerCollectionsSpecialty,
  columnsForPayerCollections,
  columnsForWrvuSummary,
  columnsForWRVUtrend,
  getColumnsForCfp,
  npvSummaryColumns,
  specialtyPerformanceColumns,
  viewCPTsDeniedColumn
} from '../shared/data-table-columns';
import {
  generateExcelWrvuSnapshotDataForDisplayedColumns,
  generateExcelWrvuTrendDataForDisplayedColumns
} from './export-data-helpers/export-wrvu-data-helper';
import {
  multiLevelWrvuDeptColumns,
  multiLevelWrvuProviderColumns,
  multiLevelWrvuSpecColumns
} from '../shared/BenchmarkColumns';
import {
  generateExcelClinicalFingerprintByCodeData,
  generateExcelClinicalFingerprintByFamilyData,
  generateExcelClinicalFingerprintByRangeData,
  generateExcelDataForProcedureSummary
} from './export-data-helpers/export-clinical-fingerprint-data-helper';
import {
  generateExcelNpvLocationByDimensionDataForDisplayedColumns,
  generateExcelNpvSnapshotDataForDisplayedColumns,
  generateExcelNpvTrendDataForDisplayedColumns
} from './export-data-helpers/export-npv-data-helper';
import {
  generateExcelCollectionsPayerByLevelData,
  generateExcelCollectionsPayerData,
  generateExcelCollectionsSnapshotDataForDisplayedColumns,
  generateExcelCollectionSummaryData
} from './export-data-helpers/export-collection-data-helper';
import {
  generateExcelDenialsByPayerData,
  generateExcelDenialsSnapshotData
} from './export-data-helpers/export-denial-data-helper';
import {CSV} from '../services/csv.service';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {
  collectionByDepartmentDataExcelData$,
  collectionByPayerByDepartmentDataExcelData$,
  collectionByPayerByProviderDataExcelData$,
  collectionByPayerBySpecialtyDataExcelData$,
  collectionByPayerDataExcelData$,
  collectionByProviderDataExcelData$,
  collectionBySpecialtyDataExcelData$,
  collectionSummaryDataExcelData$,
  cptCodeExcelData$,
  cptFamilyExcelData$,
  cptRangeExcelData$,
  denialsByDepartmentDataExcelData$,
  denialsByPayerDataExcelData$,
  denialsByProviderDataExcelData$,
  denialsBySpecialtyDataExcelData$,
  evaluationManagementEmergencyByDepartmentExcelData$,
  evaluationManagementEmergencyByProviderExcelData$,
  evaluationManagementEmergencyBySpecialtyExcelData$,
  evaluationManagementEmergencyByTrendExcelData$,
  evaluationManagementInpatientByDepartmentExcelData$,
  evaluationManagementInpatientByProviderExcelData$,
  evaluationManagementInpatientBySpecialtyExcelData$,
  evaluationManagementInpatientByTrendExcelData$,
  evaluationManagementOphthalmologyByDepartmentExcelData$,
  evaluationManagementOphthalmologyByProviderExcelData$,
  evaluationManagementOphthalmologyBySpecialtyExcelData$,
  evaluationManagementOphthalmologyByTrendExcelData$,
  evaluationManagementOutpatientByDepartmentExcelData$,
  evaluationManagementOutpatientByProviderExcelData$,
  evaluationManagementOutpatientBySpecialtyExcelData$,
  evaluationManagementOutpatientByTrendExcelData$,
  npvByDepartmentExcelData$,
  npvByLocationByDepartmentExcelData$,
  npvByLocationByProviderExcelData$,
  npvByLocationBySpecialtyExcelData$,
  npvByProviderExcelData$,
  npvBySpecialtyExcelData$,
  npvByTrendExcelData$,
  procedureSummaryByDepartmentByCodeData$,
  procedureSummaryByDepartmentByFamilyData$,
  procedureSummaryByDepartmentByRangeData$,
  procedureSummaryByProviderByCodeData$,
  procedureSummaryByProviderByFamilyData$,
  procedureSummaryByProviderByRangeData$,
  procedureSummaryBySpecialtyByCodeData$,
  procedureSummaryBySpecialtyByFamilyData$,
  procedureSummaryBySpecialtyByRangeData$,
  wrvuByDepartmentExcelData$,
  wrvuByProviderExcelData$,
  wrvuBySpecialtyExcelData$,
  wrvuBySpecialtyPerformanceExcelData$,
  wrvuByTrendExcelData$
} from './export-subscribers';
import {allDefined} from '../shared/null-helpers';
import {invoiceStatusTypes} from '../shared/constants';
import {
  generateEmTrendExcelDataWithExtendedBenchmarkOptions,
  generateEmTrendExcelDataWithOriginalBenchmarkOptions,
  generateEvaluationManagementEmergencyExportWithExtendedBenchmarkOptions,
  generateEvaluationManagementEmergencyExportWithOriginalBenchmarkOptions,
  generateEvaluationManagementInpatientExportWithExtendedBenchmarkOptions,
  generateEvaluationManagementInpatientExportWithOriginalBenchmarkOptions,
  generateEvaluationManagementOphthalmologyExportWithExtendedBenchmarkOptions,
  generateEvaluationManagementOphthalmologyExportWithOriginalBenchmarkOptions,
  generateEvaluationManagementOutpatientExportWithExtendedBenchmarkOptions,
  generateEvaluationManagementOutpatientExportWithOriginalBenchmarkOptions
} from './export-data-helpers/export-em-data-helpers/export-em-data-helper';
import {
  getCptViewText
} from '../clinical-fingerprint/procedure-summary/procedure-summary-graph/procedure-summary-graph-helper';
import {
  getDenialsDepartmentColumns,
  getDenialsProviderColumns,
  getDenialsSpecialtyColumns
} from '../column-selection/DisplayedColumnsHelper';
import {
  columnsForNPVtrend,
  multilevelNpvDeptColumns,
  multilevelNpvProvColumns,
  multilevelNpvSpecColumns,
  npvLocationDepartmentColumns,
  npvLocationProviderColumns,
  npvLocationSpecialtyColumns
} from '../new-patient-visits/components/npv-models';
import {filterCfpDataBasedOnCptGroupAndDrill} from '../clinical-fingerprint/clinical-fingerprint-helpers';

export interface MultilevelExportOption extends ExpandableExportOption<MultilevelExportOption> {
  id: ExportMetricOption;
  loaded: boolean;
  metric: MetricType;
  preGroupText?: string;
  page?: string;
  mandatory?: boolean;
}

export interface MultilevelExportFiltersData {
  breadcrumb?: string;
  dataRange?: string;
  excelLocation?: string;
  csvLocation?: string;
  payer?: string;
  billing?: string;
  lag?: string;
  invoice?: string;
  visitType?: string;
  cptGroupBreadcrumb?: string;
}

export function MultilevelExcelExportMetrics(): MultilevelExportOption[] {
  return [
    {
      text: 'All Metrics',
      id: ExportMetricOption.ALL,
      loaded: true,
      displayed: true,
      metric: MetricType.None,
      selected: false,
      nodeExpansionState: NodeExpansionState.EXPANDED,
      children: [
        {
          text: 'Wrvus',
          id: ExportMetricOption.WRVU,
          loaded: false,
          displayed: false,
          metric: MetricType.WorkRVUs,
          selected: false,
          nodeExpansionState: NodeExpansionState.COLLAPSED,
          children: [
            {
              text: 'Trend',
              id: ExportMetricOption.WRVU_TREND,
              loaded: false,
              displayed: false,
              metric: MetricType.WorkRVUs,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Department',
              id: ExportMetricOption.WRVU_BY_DEPARTMENT,
              loaded: false,
              displayed: false,
              metric: MetricType.WorkRVUs,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Specialty',
              id: ExportMetricOption.WRVU_BY_SPECIALTY,
              loaded: false,
              displayed: false,
              metric: MetricType.WorkRVUs,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Provider',
              id: ExportMetricOption.WRVU_BY_PROVIDER,
              loaded: false,
              displayed: false,
              metric: MetricType.WorkRVUs,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            }, {
              text: 'Specialty Performance',
              id: ExportMetricOption.WRVU_SPECIALTY_PERFORMANCE,
              loaded: false,
              displayed: false,
              metric: MetricType.WorkRVUs,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            }
          ]
        },
        {
          text: 'NPV',
          id: ExportMetricOption.NPV,
          loaded: false,
          displayed: false,
          metric: MetricType.NewPatientVisits,
          selected: false,
          nodeExpansionState: NodeExpansionState.COLLAPSED,
          children: [
            {
              text: 'Trend',
              id: ExportMetricOption.NPV_TREND,
              loaded: false,
              displayed: false,
              metric: MetricType.NewPatientVisits,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Department',
              id: ExportMetricOption.NPV_BY_DEPARTMENT,
              loaded: false,
              displayed: false,
              metric: MetricType.NewPatientVisits,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Specialty',
              id: ExportMetricOption.NPV_BY_SPECIALTY,
              loaded: false,
              displayed: false,
              metric: MetricType.NewPatientVisits,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Provider',
              id: ExportMetricOption.NPV_BY_PROVIDER,
              loaded: false,
              displayed: false,
              metric: MetricType.NewPatientVisits,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            }, {
              text: 'By Location',
              id: ExportMetricOption.NPV_BY_LOCATION,
              loaded: false,
              displayed: false,
              metric: MetricType.NewPatientVisits,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [{
                text: 'By Department',
                id: ExportMetricOption.NPV_BY_LOCATION_BY_DEPARTMENT,
                loaded: false,
                displayed: false,
                metric: MetricType.NewPatientVisits,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Specialty',
                id: ExportMetricOption.NPV_BY_LOCATION_BY_SPECIALTY,
                loaded: false,
                displayed: false,
                metric: MetricType.NewPatientVisits,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Provider',
                id: ExportMetricOption.NPV_BY_LOCATION_BY_PROVIDER,
                loaded: false,
                displayed: false,
                metric: MetricType.NewPatientVisits,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }]
            }
          ]
        },
        {
          text: 'E & M',
          id: ExportMetricOption.EM,
          loaded: false,
          displayed: false,
          metric: MetricType.EandM,
          selected: false,
          nodeExpansionState: NodeExpansionState.COLLAPSED,
          children: [
            {
              text: 'Outpatient',
              id: ExportMetricOption.EM_OUTPATIENT,
              loaded: false,
              displayed: false,
              metric: MetricType.EandM,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [
                {
                  text: 'By Department',
                  id: ExportMetricOption.EM_OUTPATIENT_BY_DEPARTMENT,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Specialty',
                  id: ExportMetricOption.EM_OUTPATIENT_BY_SPECIALTY,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Provider',
                  id: ExportMetricOption.EM_OUTPATIENT_BY_PROVIDER,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }, {
                  text: 'Trend',
                  id: ExportMetricOption.EM_OUTPATIENT_TREND,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }
              ]
            },
            {
              text: 'Inpatient',
              id: ExportMetricOption.EM_INPATIENT,
              loaded: false,
              displayed: false,
              metric: MetricType.EandM,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [
                {
                  text: 'By Department',
                  id: ExportMetricOption.EM_INPATIENT_BY_DEPARTMENT,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Specialty',
                  id: ExportMetricOption.EM_INPATIENT_BY_SPECIALTY,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Provider',
                  id: ExportMetricOption.EM_INPATIENT_BY_PROVIDER,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }, {
                  text: 'Trend',
                  id: ExportMetricOption.EM_INPATIENT_TREND,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }
              ]
            },
            {
              text: 'Emergency Room',
              id: ExportMetricOption.EM_EMERGENCY,
              loaded: false,
              displayed: false,
              metric: MetricType.EandM,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [
                {
                  text: 'By Department',
                  id: ExportMetricOption.EM_EMERGENCY_BY_DEPARTMENT,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Specialty',
                  id: ExportMetricOption.EM_EMERGENCY_BY_SPECIALTY,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Provider',
                  id: ExportMetricOption.EM_EMERGENCY_BY_PROVIDER,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }, {
                  text: 'Trend',
                  id: ExportMetricOption.EM_EMERGENCY_TREND,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }
              ]
            },
            {
              text: 'Ophthalmology',
              id: ExportMetricOption.EM_EYE_EXAM,
              loaded: false,
              displayed: false,
              metric: MetricType.EandM,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [
                {
                  text: 'By Department',
                  id: ExportMetricOption.EM_EYE_EXAM_BY_DEPARTMENT,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Specialty',
                  id: ExportMetricOption.EM_EYE_EXAM_BY_SPECIALTY,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                },
                {
                  text: 'By Provider',
                  id: ExportMetricOption.EM_EYE_EXAM_BY_PROVIDER,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }, {
                  text: 'Trend',
                  id: ExportMetricOption.EM_EYE_EXAM_TREND,
                  loaded: false,
                  displayed: false,
                  metric: MetricType.EandM,
                  selected: false,
                  nodeExpansionState: NodeExpansionState.LEAF,
                  children: []
                }
              ]
            }
          ]
        },
        {
          text: 'Collections',
          id: ExportMetricOption.COLLECTIONS,
          loaded: false,
          displayed: false,
          metric: MetricType.Collections,
          selected: false,
          nodeExpansionState: NodeExpansionState.COLLAPSED,
          children: [
            {
              text: 'By Department',
              id: ExportMetricOption.CollectionsByDepartment,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Specialty',
              id: ExportMetricOption.CollectionsBySpecialty,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Provider',
              id: ExportMetricOption.CollectionsByProvider,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'Summary',
              id: ExportMetricOption.CollectionsSummary,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Payer',
              id: ExportMetricOption.CollectionsByPayer,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Payer By Department',
              id: ExportMetricOption.CollectionsByPayerByDepartment,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Payer By Specialty',
              id: ExportMetricOption.CollectionsByPayerBySpecialty,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Payer By Provider',
              id: ExportMetricOption.CollectionsByPayerByProvider,
              loaded: false,
              displayed: false,
              metric: MetricType.Collections,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            }
          ]
        },
        {
          text: 'Clinical Fingerprint',
          id: ExportMetricOption.ClinicalFingerprint,
          loaded: false,
          displayed: false,
          metric: MetricType.ClinicalFingerprint,
          selected: false,
          nodeExpansionState: NodeExpansionState.COLLAPSED,
          children: [
            {
              text: 'Cpt Family',
              id: ExportMetricOption.CptFamily,
              loaded: false,
              displayed: false,
              metric: MetricType.ClinicalFingerprint,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'Cpt Range',
              id: ExportMetricOption.CptRange,
              loaded: false,
              displayed: false,
              metric: MetricType.ClinicalFingerprint,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'Cpt Code',
              id: ExportMetricOption.CptCode,
              loaded: false,
              displayed: false,
              metric: MetricType.ClinicalFingerprint,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            }
          ]
        },
        {
          text: 'Denials',
          id: ExportMetricOption.Denials,
          loaded: false,
          displayed: false,
          metric: MetricType.Denials,
          selected: false,
          nodeExpansionState: NodeExpansionState.COLLAPSED,
          children: [
            {
              text: 'By Department',
              id: ExportMetricOption.DenialsByDepartment,
              loaded: false,
              displayed: false,
              metric: MetricType.Denials,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Specialty',
              id: ExportMetricOption.DenialsBySpecialty,
              loaded: false,
              displayed: false,
              metric: MetricType.Denials,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Provider',
              id: ExportMetricOption.DenialsByProvider,
              loaded: false,
              displayed: false,
              metric: MetricType.Denials,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            },
            {
              text: 'By Payer',
              id: ExportMetricOption.DenialsByPayer,
              loaded: false,
              displayed: false,
              metric: MetricType.Denials,
              selected: false,
              nodeExpansionState: NodeExpansionState.LEAF,
              children: []
            }
          ]
        },
        {
          text: 'Procedure Summary',
          id: ExportMetricOption.PROCEDURE_SUMMARY,
          loaded: false,
          displayed: false,
          metric: MetricType.ProcedureSummary,
          selected: false,
          nodeExpansionState: NodeExpansionState.COLLAPSED,
          children: [
            {
              text: 'By Department',
              id: ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT,
              loaded: false, displayed: false, metric: MetricType.ProcedureSummary,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [{
                text: 'By Department By Family',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Department By Range',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Department By Code',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }]
            }, {
              text: 'By Specialty',
              id: ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY,
              loaded: false, displayed: false, metric: MetricType.ProcedureSummary,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [{
                text: 'By Specialty By Family',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Specialty By Range',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Specialty By Code',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }]
            }, {
              text: 'By Provider',
              id: ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER,
              loaded: false, displayed: false, metric: MetricType.ProcedureSummary,
              selected: false,
              nodeExpansionState: NodeExpansionState.COLLAPSED,
              children: [{
                text: 'By Provider By Family',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Provider By Range',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }, {
                text: 'By Provider By Code',
                id: ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE,
                loaded: false,
                displayed: false,
                metric: MetricType.ProcedureSummary,
                selected: false,
                nodeExpansionState: NodeExpansionState.LEAF,
                children: []
              }]
            }
          ]
        }
      ]
    }
  ];
}

export function MultilevelPdfExportMetrics(): MultilevelExportOption[] {
  return [
    {
      text: 'Overview',
      id: ExportMetricOption.OVERVIEW,
      loaded: true,
      displayed: true,
      metric: MetricType.None,
      selected: true,
      mandatory: true,
      nodeExpansionState: NodeExpansionState.EXPANDED,
      children: [
        {
          text: 'Trend View',
          id: ExportMetricOption.WRVU_TREND,
          loaded: true,
          displayed: false,
          metric: MetricType.WorkRVUs,
          selected: true,
          mandatory: true,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: []
        },
        {
          text: 'Wrvu View',
          id: ExportMetricOption.WRVU_SNAPSHOT,
          loaded: true,
          displayed: false,
          metric: MetricType.WorkRVUs,
          selected: true,
          mandatory: true,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: []
        },
        {
          text: 'Trend View',
          id: ExportMetricOption.NPV_TREND,
          loaded: true,
          displayed: false,
          metric: MetricType.NewPatientVisits,
          selected: true,
          mandatory: true,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: []
        },
        {
          text: 'Npv View',
          id: ExportMetricOption.NPV_SNAPSHOT,
          loaded: true,
          displayed: false,
          metric: MetricType.NewPatientVisits,
          selected: true,
          mandatory: true,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: []
        },
        {
          text: 'Outpatient View',
          id: ExportMetricOption.EM_OUTPATIENT,
          loaded: true,
          displayed: true,
          metric: MetricType.EandM,
          selected: false,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: [],
          preGroupText: 'Please select the E&M code range that you would like to export',
          page: 'Em Outpatient'
        },
        {
          text: 'Inpatient View',
          id: ExportMetricOption.EM_INPATIENT,
          loaded: true,
          displayed: true,
          metric: MetricType.EandM,
          selected: false,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: [],
          page: 'Em Inpatient'
        },
        {
          text: 'Ophthalmology View',
          id: ExportMetricOption.EM_EYE_EXAM,
          loaded: true,
          displayed: true,
          metric: MetricType.EandM,
          selected: false,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: [],
          page: 'Em Ophthalmology'
        },
        {
          text: 'Emergency Medicine View',
          id: ExportMetricOption.EM_EMERGENCY,
          loaded: true,
          displayed: true,
          metric: MetricType.EandM,
          selected: false,
          nodeExpansionState: NodeExpansionState.LEAF_ACTIVE,
          children: [],
          page: 'Em Emergency Medicine'
        }
      ]
    }];
}

export enum ExportMetricOption {
  NONE,
  ALL,
  OVERVIEW,
  WRVU,
  WRVU_SNAPSHOT,
  WRVU_TREND,
  WRVU_BY_DEPARTMENT,
  WRVU_BY_SPECIALTY,
  WRVU_BY_PROVIDER,
  WRVU_SPECIALTY_PERFORMANCE,
  NPV,
  NPV_SNAPSHOT,
  NPV_TREND,
  NPV_BY_DEPARTMENT,
  NPV_BY_SPECIALTY,
  NPV_BY_PROVIDER,
  NPV_BY_LOCATION,
  NPV_BY_LOCATION_BY_DEPARTMENT,
  NPV_BY_LOCATION_BY_SPECIALTY,
  NPV_BY_LOCATION_BY_PROVIDER,
  EM,
  EM_OUTPATIENT,
  EM_OUTPATIENT_BY_DEPARTMENT,
  EM_OUTPATIENT_BY_SPECIALTY,
  EM_OUTPATIENT_BY_PROVIDER,
  EM_OUTPATIENT_TREND,
  EM_INPATIENT,
  EM_INPATIENT_BY_DEPARTMENT,
  EM_INPATIENT_BY_SPECIALTY,
  EM_INPATIENT_BY_PROVIDER,
  EM_INPATIENT_TREND,
  EM_EYE_EXAM,
  EM_EYE_EXAM_BY_DEPARTMENT,
  EM_EYE_EXAM_BY_SPECIALTY,
  EM_EYE_EXAM_BY_PROVIDER,
  EM_EYE_EXAM_TREND,
  EM_EMERGENCY,
  EM_EMERGENCY_BY_DEPARTMENT,
  EM_EMERGENCY_BY_SPECIALTY,
  EM_EMERGENCY_BY_PROVIDER,
  EM_EMERGENCY_TREND,
  COLLECTIONS,
  CollectionsByDepartment,
  CollectionsBySpecialty,
  CollectionsByProvider,
  CollectionsSummary,
  CollectionsByPayer,
  CollectionsByPayerByDepartment,
  CollectionsByPayerBySpecialty,
  CollectionsByPayerByProvider,
  ClinicalFingerprint,
  CptFamily,
  CptRange,
  CptCode,
  Denials,
  DenialsByDepartment,
  DenialsBySpecialty,
  DenialsByProvider,
  DenialsByPayer,
  PROCEDURE_SUMMARY,
  PROCEDURE_SUMMARY_BY_DEPARTMENT,
  PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY,
  PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE,
  PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE,
  PROCEDURE_SUMMARY_BY_SPECIALTY,
  PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY,
  PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE,
  PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE,
  PROCEDURE_SUMMARY_BY_PROVIDER,
  PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY,
  PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE,
  PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE,
}

export function getRequiredId(url: string, cptViewType?: CptViewType): ExportMetricOption {
  switch (url) {
    case wRVUDepartmentPageRoute:
      return ExportMetricOption.WRVU_BY_DEPARTMENT;
    case wRVUSpecialtyPageRoute:
      return ExportMetricOption.WRVU_BY_SPECIALTY;
    case wRVUProviderPageRoute:
      return ExportMetricOption.WRVU_BY_PROVIDER;
    case wRVUSpecialtyPerformancePageRoute:
      return ExportMetricOption.WRVU_SPECIALTY_PERFORMANCE;
    case wRVUTrendPageRoute:
      return ExportMetricOption.WRVU_TREND;
    case npvDepartmentPageRoute:
      return ExportMetricOption.NPV_BY_DEPARTMENT;
    case npvSpecialtyPageRoute:
      return ExportMetricOption.NPV_BY_SPECIALTY;
    case npvProviderPageRoute:
      return ExportMetricOption.NPV_BY_PROVIDER;
    case npvTrendPageRoute:
      return ExportMetricOption.NPV_TREND;
    case emOutpatientRoute:
      return ExportMetricOption.EM_OUTPATIENT;
    case emOutpatientDepartmentRoute:
      return ExportMetricOption.EM_OUTPATIENT_BY_DEPARTMENT;
    case emOutpatientSpecialtyRoute:
      return ExportMetricOption.EM_OUTPATIENT_BY_SPECIALTY;
    case emOutpatientProviderRoute:
      return ExportMetricOption.EM_OUTPATIENT_BY_PROVIDER;
    case emInpatientRoute:
      return ExportMetricOption.EM_INPATIENT;
    case emInpatientDepartmentRoute:
      return ExportMetricOption.EM_INPATIENT_BY_DEPARTMENT;
    case emInpatientSpecialtyRoute:
      return ExportMetricOption.EM_INPATIENT_BY_SPECIALTY;
    case emInpatientProviderRoute:
      return ExportMetricOption.EM_INPATIENT_BY_PROVIDER;
    case emOphthalmologyRoute:
      return ExportMetricOption.EM_EYE_EXAM;
    case emOphthalmologyDepartmentRoute:
      return ExportMetricOption.EM_EYE_EXAM_BY_DEPARTMENT;
    case emOphthalmologySpecialtyRoute:
      return ExportMetricOption.EM_EYE_EXAM_BY_SPECIALTY;
    case emOphthalmologyProviderRoute:
      return ExportMetricOption.EM_EYE_EXAM_BY_PROVIDER;
    case emEmergencyMedicineRoute:
      return ExportMetricOption.EM_EMERGENCY;
    case emEmergencyMedicineDepartmentRoute:
      return ExportMetricOption.EM_EMERGENCY_BY_DEPARTMENT;
    case emEmergencyMedicineSpecialtyRoute:
      return ExportMetricOption.EM_EMERGENCY_BY_SPECIALTY;
    case emEmergencyMedicineProviderRoute:
      return ExportMetricOption.EM_EMERGENCY_BY_PROVIDER;
    case collectionsDepartmentPageRoute:
      return ExportMetricOption.CollectionsByDepartment;
    case collectionsSpecialtyPageRoute:
      return ExportMetricOption.CollectionsBySpecialty;
    case collectionsProviderPageRoute:
      return ExportMetricOption.CollectionsByProvider;
    case summaryCollectionsRoute:
      return ExportMetricOption.CollectionsSummary;
    case payerCollectionsMultiLevelRoute:
      return ExportMetricOption.CollectionsByPayer;
    case clinicalFingerPrintRoute:
      switch (cptViewType) {
        case CptViewType.CptFamily:
          return ExportMetricOption.CptFamily;
        case CptViewType.CptRange:
          return ExportMetricOption.CptRange;
        case CptViewType.CptCode:
          return ExportMetricOption.CptCode;
        default:
          return ExportMetricOption.NONE;
      }
    case denialsPayerPageRoute:
      return ExportMetricOption.DenialsByPayer;
    case denialsDepartmentPageRoute:
      return ExportMetricOption.DenialsByDepartment;
    case denialsSpecialtyPageRoute:
      return ExportMetricOption.DenialsBySpecialty;
    case denialsProviderPageRoute:
      return ExportMetricOption.DenialsByProvider;
    case procedureSummaryRoute:
      return ExportMetricOption.PROCEDURE_SUMMARY;
    default:
      return ExportMetricOption.NONE;
  }
}

export function selectOrDeselectParents(generalMultilevelExportOptions: ExpandableExportOption<any>[]): void {
  let changed;
  do {
    changed = false;
    generalMultilevelExportOptions.forEach((general_option: ExpandableExportOption<any>) => {
      selectOrDeselectParents(general_option.children);
      const selectedChild = general_option.children.find(child => child.selected && child.displayed);
      const unSelectedChild = general_option.children.find(child => !child.selected && child.displayed);
      if (general_option.children.filter(c => c.displayed).length >= 1) {
        if (general_option.selected !== !!selectedChild && general_option.anyUnselectedDescendants !== !!unSelectedChild) {
          changed = true;
        }
        general_option.selected = !!selectedChild;
        general_option.anyUnselectedDescendants = !! unSelectedChild;
      }
    });
  } while (changed);
}

export function selectMandatoryChildren(generalMultilevelExportOptions: ExpandableExportOption<any>[], isParentSelected: boolean): void {
  generalMultilevelExportOptions.forEach((option: ExpandableExportOption<any>) => {
    if (option.mandatoryIfParentIsSelected) {
      option.selected = isParentSelected;
    }
    selectMandatoryChildren(option.children, isParentSelected);
  });
}

export function canDisplayExportOption(option: ExportMetricOption, data: Data,
                                       maxSpecialties: number, maxProviders: number, canHideDenials: boolean,
                                       canShowClinical: boolean, canHideProcedureSummary: boolean,
                                       canHideNpvByLocation: boolean): boolean {
  switch (option) {
    case ExportMetricOption.WRVU_TREND:
      return data.mergedProductivityTrend && data.mergedProductivityTrend.length > 0;
    case ExportMetricOption.WRVU_BY_DEPARTMENT:
      return data.providerProductivityMultilevelData && data.providerProductivityMultilevelData
        .departmentProductivities && data.providerProductivityMultilevelData.departmentProductivities.length > 0;
    case ExportMetricOption.WRVU_BY_SPECIALTY:
      return data.providerProductivityMultilevelData && data.providerProductivityMultilevelData
          .specialtyProductivities && data.providerProductivityMultilevelData.specialtyProductivities.length > 0 &&
        data.providerProductivityMultilevelData.specialtyProductivities.length <= maxSpecialties;
    case ExportMetricOption.WRVU_BY_PROVIDER:
    case ExportMetricOption.WRVU_SPECIALTY_PERFORMANCE:
      return data.providerProductivityMultilevelData && data.providerProductivityMultilevelData
          .providerProductivities && data.providerProductivityMultilevelData.providerProductivities.length > 0 &&
        data.providerProductivityMultilevelData.providerProductivities.length <= maxProviders;
    case ExportMetricOption.NPV_TREND:
      return data.newPatientVisitTrendData && data.newPatientVisitTrendData.length > 0;
    case ExportMetricOption.NPV_BY_DEPARTMENT:
      return data.newPatientVisitMultilevelData && data.newPatientVisitMultilevelData
        .departmentNpvSnapshotData && data.newPatientVisitMultilevelData.departmentNpvSnapshotData.length > 0;
    case ExportMetricOption.NPV_BY_SPECIALTY:
      return data.newPatientVisitMultilevelData && data.newPatientVisitMultilevelData.specialtyNpvSnapshotData &&
        data.newPatientVisitMultilevelData.specialtyNpvSnapshotData.length > 0 &&
        data.newPatientVisitMultilevelData.specialtyNpvSnapshotData.length <= maxSpecialties;
    case ExportMetricOption.NPV_BY_PROVIDER:
      return data.newPatientVisitMultilevelData && data.newPatientVisitMultilevelData.providerNpvSnapshotData &&
        data.newPatientVisitMultilevelData.providerNpvSnapshotData.length > 0 &&
        data.newPatientVisitMultilevelData.providerNpvSnapshotData.length <= maxProviders;
    case ExportMetricOption.NPV_BY_LOCATION:
      return !canHideNpvByLocation;
    case ExportMetricOption.NPV_BY_LOCATION_BY_DEPARTMENT:
    case ExportMetricOption.NPV_BY_LOCATION_BY_SPECIALTY:
    case ExportMetricOption.NPV_BY_LOCATION_BY_PROVIDER:
      return !canHideNpvByLocation && canDisplayExportOption(
        getNpvDimensionExportOptionEquivalentOfLocationExportOption(option), data, maxSpecialties, maxProviders,
        canHideDenials, canShowClinical, canHideProcedureSummary, canHideNpvByLocation);
    case ExportMetricOption.ClinicalFingerprint:
      return canShowClinical;
    case ExportMetricOption.CptFamily:
      return canShowClinical && data.clinicalSummaryConsolidationData && data.clinicalSummaryConsolidationData
        .familyClinicalSummaries && data.clinicalSummaryConsolidationData.familyClinicalSummaries.length > 0;
    case ExportMetricOption.CptRange:
      return canShowClinical && data.clinicalSummaryConsolidationData && data.clinicalSummaryConsolidationData
        .rangeClinicalSummaries && data.clinicalSummaryConsolidationData.rangeClinicalSummaries.length > 0;
    case ExportMetricOption.CptCode:
      return canShowClinical && data.clinicalSummaryConsolidationData && data.clinicalSummaryConsolidationData
        .cptClinicalSummaries && data.clinicalSummaryConsolidationData.cptClinicalSummaries.length > 0;
    case ExportMetricOption.CollectionsByPayer:
      return data.payerCollectionsData && data.payerCollectionsData.payerMatchedCollections &&
        data.payerCollectionsData.payerMatchedCollections.length > 0;
    case ExportMetricOption.CollectionsByDepartment:
      return data.matchedCollectionsByMultiLevelByNodePathData &&
        data.matchedCollectionsByMultiLevelByNodePathData.departmentCollections &&
        data.matchedCollectionsByMultiLevelByNodePathData.departmentCollections.length > 0;
    case ExportMetricOption.CollectionsBySpecialty:
      return data.matchedCollectionsByMultiLevelByNodePathData &&
        data.matchedCollectionsByMultiLevelByNodePathData.specialtyCollections &&
        data.matchedCollectionsByMultiLevelByNodePathData.specialtyCollections.length > 0 &&
        data.matchedCollectionsByMultiLevelByNodePathData.specialtyCollections.length < maxSpecialties;
    case ExportMetricOption.CollectionsByProvider:
      return data.matchedCollectionsByMultiLevelByNodePathData &&
        data.matchedCollectionsByMultiLevelByNodePathData.providerCollections &&
        data.matchedCollectionsByMultiLevelByNodePathData.providerCollections.length > 0 &&
        data.matchedCollectionsByMultiLevelByNodePathData.providerCollections.length < maxProviders;
    case ExportMetricOption.CollectionsByPayerByDepartment:
      return data.matchedCollectionsByPayerByMultiLevelByNodePathData && data.matchedCollectionsByPayerByMultiLevelByNodePathData
        .departmentCollections && data.matchedCollectionsByPayerByMultiLevelByNodePathData.departmentCollections.length > 0;
    case ExportMetricOption.CollectionsByPayerBySpecialty:
      return data.matchedCollectionsByPayerByMultiLevelByNodePathData && data.matchedCollectionsByPayerByMultiLevelByNodePathData
        .specialtyCollections && data.matchedCollectionsByPayerByMultiLevelByNodePathData
        .specialtyCollections.length < maxSpecialties && data.matchedCollectionsByPayerByMultiLevelByNodePathData
        .specialtyCollections.length > 0;
    case ExportMetricOption.CollectionsByPayerByProvider:
      return data.matchedCollectionsByPayerByMultiLevelByNodePathData && data.matchedCollectionsByPayerByMultiLevelByNodePathData
        .providerCollections && data.matchedCollectionsByPayerByMultiLevelByNodePathData
        .providerCollections.length < maxProviders && data.matchedCollectionsByPayerByMultiLevelByNodePathData
        .providerCollections.length > 0;
    case ExportMetricOption.EM_OUTPATIENT_BY_DEPARTMENT:
    case ExportMetricOption.EM_INPATIENT_BY_DEPARTMENT:
    case ExportMetricOption.EM_EYE_EXAM_BY_DEPARTMENT:
    case ExportMetricOption.EM_EMERGENCY_BY_DEPARTMENT:
      return data.evaluationManagementMultilevelData && data.evaluationManagementMultilevelData.evaluationManagementData &&
        data.evaluationManagementMultilevelData.evaluationManagementData
          .departmentEvaluationManagementSnapshotData && data.evaluationManagementMultilevelData.evaluationManagementData
          .departmentEvaluationManagementSnapshotData.nodes && data.evaluationManagementMultilevelData
          .evaluationManagementData.departmentEvaluationManagementSnapshotData.nodes.length > 0;
    case ExportMetricOption.EM_OUTPATIENT_BY_SPECIALTY:
    case ExportMetricOption.EM_INPATIENT_BY_SPECIALTY:
    case ExportMetricOption.EM_EMERGENCY_BY_SPECIALTY:
    case ExportMetricOption.EM_EYE_EXAM_BY_SPECIALTY:
      return data.evaluationManagementMultilevelData && data.evaluationManagementMultilevelData.evaluationManagementData &&
        data.evaluationManagementMultilevelData.evaluationManagementData
          .specialtyEvaluationManagementSnapshotData && data.evaluationManagementMultilevelData.evaluationManagementData
          .specialtyEvaluationManagementSnapshotData.nodes && data.evaluationManagementMultilevelData
          .evaluationManagementData.specialtyEvaluationManagementSnapshotData.nodes.length < maxSpecialties
        && data.evaluationManagementMultilevelData.evaluationManagementData.specialtyEvaluationManagementSnapshotData.nodes.length > 0;
    case ExportMetricOption.EM_OUTPATIENT_BY_PROVIDER:
    case ExportMetricOption.EM_INPATIENT_BY_PROVIDER:
    case ExportMetricOption.EM_EMERGENCY_BY_PROVIDER:
    case ExportMetricOption.EM_EYE_EXAM_BY_PROVIDER:
      return data.evaluationManagementMultilevelData && data.evaluationManagementMultilevelData.evaluationManagementData &&
        data.evaluationManagementMultilevelData.evaluationManagementData
          .providerEvaluationManagementSnapshotData && data.evaluationManagementMultilevelData.evaluationManagementData
          .providerEvaluationManagementSnapshotData.nodes &&
        data.evaluationManagementMultilevelData.evaluationManagementData
          .providerEvaluationManagementSnapshotData.nodes.length < maxProviders
        && data.evaluationManagementMultilevelData.evaluationManagementData
          .providerEvaluationManagementSnapshotData.nodes.length > 0;
    case ExportMetricOption.Denials:
      return !canHideDenials;
    case ExportMetricOption.DenialsByPayer:
      return !canHideDenials && data.denials && data.denials.denialRates && data.denials.denialRates.length > 0;
    case ExportMetricOption.DenialsByDepartment:
      return !canHideDenials && data.denialsByMultiLevelData && data.denialsByMultiLevelData.departmentDenials &&
        data.denialsByMultiLevelData.departmentDenials.length > 0;
    case ExportMetricOption.DenialsBySpecialty:
      return !canHideDenials && data.denialsByMultiLevelData && data.denialsByMultiLevelData.specialtyDenials &&
        data.denialsByMultiLevelData.specialtyDenials.length > 0 &&
        data.denialsByMultiLevelData.specialtyDenials.length <= maxSpecialties;
    case ExportMetricOption.DenialsByProvider:
      return !canHideDenials && data.denialsByMultiLevelData && data.denialsByMultiLevelData.providerDenials &&
        data.denialsByMultiLevelData.providerDenials.length > 0 &&
        data.denialsByMultiLevelData.providerDenials.length <= maxProviders;
    case ExportMetricOption.PROCEDURE_SUMMARY:
      return !canHideProcedureSummary;
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE:
      return !canHideProcedureSummary && data.clinicalFingerprintMultilevelData && data.clinicalFingerprintMultilevelData
        .cfpByDepartment && data.clinicalFingerprintMultilevelData.cfpByDepartment.length > 0;
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE:
      return !canHideProcedureSummary && data.clinicalFingerprintMultilevelData && data.clinicalFingerprintMultilevelData
          .cfpBySpecialty && data.clinicalFingerprintMultilevelData.cfpBySpecialty.length > 0 &&
        data.clinicalFingerprintMultilevelData.cfpBySpecialty.length <= maxSpecialties;
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE:
    case ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE:
      return !canHideProcedureSummary && data.clinicalFingerprintMultilevelData && data.clinicalFingerprintMultilevelData
          .cfpByProvider && data.clinicalFingerprintMultilevelData.cfpByProvider.length > 0 &&
        data.clinicalFingerprintMultilevelData.cfpByProvider.length <= maxProviders;
  }
  return true;
}

function getNpvDimensionExportOptionEquivalentOfLocationExportOption(option: ExportMetricOption): ExportMetricOption {
  switch (option) {
    case ExportMetricOption.NPV_BY_LOCATION_BY_DEPARTMENT:
      return ExportMetricOption.NPV_BY_DEPARTMENT;
    case ExportMetricOption.NPV_BY_LOCATION_BY_SPECIALTY:
      return ExportMetricOption.NPV_BY_SPECIALTY;
    case ExportMetricOption.NPV_BY_LOCATION_BY_PROVIDER:
      return ExportMetricOption.NPV_BY_PROVIDER;
    default:
      return option;
  }
}

async function setOptionUnused(designatedObservable: BehaviorSubject<ExportMetadata | boolean>) {
  designatedObservable.next(false);
}

function generateEmOutpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled: boolean,
                                                                      stateData: Data, benchmarkOption: BenchmarkOption,
                                                                      viewCommunityBenchmarks: boolean, level: LevelType) {
  return emTelehealthEnabled ?
    generateEvaluationManagementOutpatientExportWithExtendedBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, benchmarkOption) :
    generateEvaluationManagementOutpatientExportWithOriginalBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, viewCommunityBenchmarks);
}

function generateEmEmergencyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled: boolean,
                                                                     stateData: Data, benchmarkOption: BenchmarkOption,
                                                                     viewCommunityBenchmarks: boolean, level: LevelType) {
  return emTelehealthEnabled ?
    generateEvaluationManagementEmergencyExportWithExtendedBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, benchmarkOption) :
    generateEvaluationManagementEmergencyExportWithOriginalBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, viewCommunityBenchmarks);
}

function generateEmOphthalmologyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled: boolean,
                                                                         stateData: Data, benchmarkOption: BenchmarkOption,
                                                                         viewCommunityBenchmarks: boolean, level: LevelType) {
  return emTelehealthEnabled ?
    generateEvaluationManagementOphthalmologyExportWithExtendedBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, benchmarkOption) :
    generateEvaluationManagementOphthalmologyExportWithOriginalBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, viewCommunityBenchmarks);
}

function generateEmInpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled: boolean,
                                                                     stateData: Data, benchmarkOption: BenchmarkOption,
                                                                     viewCommunityBenchmarks: boolean, level: LevelType) {
  return emTelehealthEnabled ?
    generateEvaluationManagementInpatientExportWithExtendedBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, benchmarkOption) :
    generateEvaluationManagementInpatientExportWithOriginalBenchmarkOptions(stateData.summaryEvaluationManagementData,
      stateData.evaluationManagementMultilevelData, level, viewCommunityBenchmarks);
}

function generateEmTrendExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled: boolean,
                                                                 stateData: Data, benchmarkOption: BenchmarkOption,
                                                                 viewCommunityBenchmarks: boolean, dimension: EmDimension) {
  return emTelehealthEnabled ?
    generateEmTrendExcelDataWithExtendedBenchmarkOptions(dimension, stateData.evaluationManagementMultilevelData.evaluationManagementData
      .providerEvaluationManagementSnapshotDataMonthly?.nodes || [], stateData.summaryEvaluationManagementData, benchmarkOption) :
    generateEmTrendExcelDataWithOriginalBenchmarkOptions(dimension, viewCommunityBenchmarks,
      stateData.evaluationManagementMultilevelData.evaluationManagementData
        .providerEvaluationManagementSnapshotDataMonthly?.nodes || [], stateData.summaryEvaluationManagementData);

}

export async function exportToExcelOrCsv(
  options: MultilevelExportOption[],
  stateData: Data,
  display: CurrentSelections,
  filters: FilterCriteria,
  excelService: ExcelService,
  csv: CSV,
  analyticsService: AnalyticsService,
  exportOption: ExportType,
  npvTelehealthEnabled: boolean,
  emTelehealthEnabled: boolean, shouldShowWrvuDepartmentCfteAdjusted: boolean,
  currentFiltersData: MultilevelExportFiltersData, showCfteWrvus: boolean, showCfteCount: boolean) {
  const exportExcelData: ExportMetadata[] = [];
  const exportCsvData: Export[] = [];
  const downloadableMetrics: Set<ExportMetricOption> = new Set<ExportMetricOption>();
  options.filter(x => x.selected && x.loaded).forEach(o => {
    downloadableMetrics.add(o.id);
  });
  let sheetNames: string[] = [];
  const showAdditionalCFPColumns = isFeatureEnabled(FeatureToggleEntries.SHOW_CFP_COLUMNS,
    stateData.featureToggleSettings, stateData.userProfile);
  const viewCommunityBenchmarks = display.viewCommunityBenchmarks;
  const benchmarkOption = display.emNpvBenchmarkOption;
  let exportReady = false;
  [
    downloadableMetrics.has(ExportMetricOption.WRVU_TREND)
      ? generateExcelWrvuTrendDataForDisplayedColumns(stateData.productivitySummaryData, stateData.mergedProductivityTrend,
        display.displayedWRVUTrendColumns, columnsForWRVUtrend.slice(), columnsForWrvuSummary.slice(), viewCommunityBenchmarks)
      : setOptionUnused(wrvuByTrendExcelData$),
    downloadableMetrics.has(ExportMetricOption.WRVU_BY_DEPARTMENT)
      ? generateExcelWrvuSnapshotDataForDisplayedColumns(
        stateData.productivitySummaryData,
        stateData.providerProductivityMultilevelData.departmentProductivities,
        display.displayedWRVUSnapshotColumns,
        multiLevelWrvuDeptColumns(true).slice(),
        columnsForWrvuSummary.slice(),
        viewCommunityBenchmarks, 'department',
        !isFeatureEnabled(FeatureToggleEntries.HIDE_WRVU_BY_DEPARTMENT_BENCHMARK, stateData.featureToggleSettings, stateData.userProfile),
        shouldShowWrvuDepartmentCfteAdjusted)
      : setOptionUnused(wrvuByDepartmentExcelData$),
    downloadableMetrics.has(ExportMetricOption.WRVU_BY_SPECIALTY)
      ? generateExcelWrvuSnapshotDataForDisplayedColumns(
        stateData.productivitySummaryData, stateData.providerProductivityMultilevelData.specialtyProductivities,
        display.displayedWRVUSnapshotColumns, multiLevelWrvuSpecColumns(true).slice(), columnsForWrvuSummary.slice(),
        viewCommunityBenchmarks, 'specialty', true, true)
      : setOptionUnused(wrvuBySpecialtyExcelData$),
    downloadableMetrics.has(ExportMetricOption.WRVU_BY_PROVIDER)
      ? generateExcelWrvuSnapshotDataForDisplayedColumns(
        stateData.productivitySummaryData, stateData.providerProductivityMultilevelData.providerProductivities,
        display.displayedWRVUSnapshotColumns, multiLevelWrvuProviderColumns.slice(), columnsForWrvuSummary.slice(),
        viewCommunityBenchmarks, 'provider', true, true)
      : setOptionUnused(wrvuByProviderExcelData$),
    downloadableMetrics.has(ExportMetricOption.WRVU_SPECIALTY_PERFORMANCE)
      ? generateExcelWrvuSnapshotDataForDisplayedColumns(
        stateData.productivitySummaryData, stateData.providerProductivityMultilevelData.providerProductivities,
        display.displayedWRVUSpecialtyPerformanceColumns, specialtyPerformanceColumns.slice(), columnsForWrvuSummary.slice(),
        viewCommunityBenchmarks, 'specialty performance', true, true)
      : setOptionUnused(wrvuBySpecialtyPerformanceExcelData$),
    downloadableMetrics.has(ExportMetricOption.NPV_TREND)
      ? generateExcelNpvTrendDataForDisplayedColumns(stateData.summaryNewPatientVisitData, stateData.newPatientVisitTrendData,
        display.displayedNPVTrendColumns, columnsForNPVtrend().slice(), npvSummaryColumns.slice(), viewCommunityBenchmarks,
        benchmarkOption, npvTelehealthEnabled)
      : setOptionUnused(npvByTrendExcelData$),
    downloadableMetrics.has(ExportMetricOption.NPV_BY_DEPARTMENT) ?
      generateExcelNpvSnapshotDataForDisplayedColumns(stateData.summaryNewPatientVisitData,
        stateData.newPatientVisitMultilevelData.departmentNpvSnapshotData, display.displayedNPVDepartmentColumns,
        multilevelNpvDeptColumns().slice(), npvSummaryColumns.slice(), viewCommunityBenchmarks, 'department',
        benchmarkOption, npvTelehealthEnabled)
      : setOptionUnused(npvByDepartmentExcelData$),
    downloadableMetrics.has(ExportMetricOption.NPV_BY_SPECIALTY) ?
      generateExcelNpvSnapshotDataForDisplayedColumns(stateData.summaryNewPatientVisitData,
        stateData.newPatientVisitMultilevelData.specialtyNpvSnapshotData, display.displayedNPVSpecialtyColumns,
        multilevelNpvSpecColumns().slice(), npvSummaryColumns.slice(), viewCommunityBenchmarks, 'specialty',
        benchmarkOption, npvTelehealthEnabled)
      : setOptionUnused(npvBySpecialtyExcelData$),
    downloadableMetrics.has(ExportMetricOption.NPV_BY_PROVIDER) ?
      generateExcelNpvSnapshotDataForDisplayedColumns(stateData.summaryNewPatientVisitData,
        stateData.newPatientVisitMultilevelData.providerNpvSnapshotData, display.displayedNPVProviderColumns,
        multilevelNpvProvColumns().slice(), npvSummaryColumns.slice(), viewCommunityBenchmarks, 'provider',
        benchmarkOption, npvTelehealthEnabled)
      : setOptionUnused(npvByProviderExcelData$),
    downloadableMetrics.has(ExportMetricOption.NPV_BY_LOCATION_BY_DEPARTMENT) ?
      generateExcelNpvLocationByDimensionDataForDisplayedColumns(stateData.summaryNewPatientVisitData,
        stateData.mergedNpvLocationAggregatedByNode, display.newPatientVisitsLocationVariables.displayedDepartmentColumns,
        npvLocationDepartmentColumns().slice(), npvSummaryColumns.slice(),
        viewCommunityBenchmarks, MultilevelTab.LOCATION_DEPARTMENT, benchmarkOption, npvTelehealthEnabled)
      : setOptionUnused(npvByLocationByDepartmentExcelData$),
    downloadableMetrics.has(ExportMetricOption.NPV_BY_LOCATION_BY_SPECIALTY) ?
      generateExcelNpvLocationByDimensionDataForDisplayedColumns(stateData.summaryNewPatientVisitData,
        stateData.mergedNpvLocationAggregatedByNode, display.newPatientVisitsLocationVariables.displayedSpecialtyColumns,
        npvLocationSpecialtyColumns().slice(), npvSummaryColumns.slice(),
        viewCommunityBenchmarks, MultilevelTab.LOCATION_SPECIALTY, benchmarkOption, npvTelehealthEnabled)
      : setOptionUnused(npvByLocationBySpecialtyExcelData$),
    downloadableMetrics.has(ExportMetricOption.NPV_BY_LOCATION_BY_PROVIDER) ?
      generateExcelNpvLocationByDimensionDataForDisplayedColumns(stateData.summaryNewPatientVisitData,
        stateData.mergedNpvLocationAggregatedByNode, display.newPatientVisitsLocationVariables.displayedProviderColumns,
        npvLocationProviderColumns().slice(), npvSummaryColumns.slice(),
        viewCommunityBenchmarks, MultilevelTab.LOCATION_PROVIDER, benchmarkOption, npvTelehealthEnabled)
      : setOptionUnused(npvByLocationByProviderExcelData$),
    downloadableMetrics.has(ExportMetricOption.CptFamily) ? generateExcelClinicalFingerprintByFamilyData(
        stateData.clinicalSummaryConsolidationData.familyClinicalSummaries, getColumnsForCfp(CptViewType.CptFamily,
          viewCommunityBenchmarks, showAdditionalCFPColumns, true, true)
          .filter(c => c.showAdditionalCFPColumns !== false).slice()) :
      setOptionUnused(cptFamilyExcelData$),
    downloadableMetrics.has(ExportMetricOption.CptRange)
      ? generateExcelClinicalFingerprintByRangeData(stateData.clinicalSummaryConsolidationData.rangeClinicalSummaries,
        getColumnsForCfp(CptViewType.CptRange, viewCommunityBenchmarks, showAdditionalCFPColumns, true, true)
          .filter(c => c.showAdditionalCFPColumns !== false).slice())
      : setOptionUnused(cptRangeExcelData$),
    downloadableMetrics.has(ExportMetricOption.CptCode)
      ? generateExcelClinicalFingerprintByCodeData(stateData.clinicalSummaryConsolidationData.cptClinicalSummaries,
        getColumnsForCfp(CptViewType.CptCode,
          viewCommunityBenchmarks, showAdditionalCFPColumns, true, true)
          .filter(c => c.showAdditionalCFPColumns !== false).slice())
      : setOptionUnused(cptCodeExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_OUTPATIENT_BY_DEPARTMENT) ?
      generateEmOutpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.department) :
      setOptionUnused(evaluationManagementOutpatientByDepartmentExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_OUTPATIENT_BY_SPECIALTY) ?
      generateEmOutpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.specialty) :
      setOptionUnused(evaluationManagementOutpatientBySpecialtyExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_OUTPATIENT_BY_PROVIDER) ?
      generateEmOutpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.provider) :
      setOptionUnused(evaluationManagementOutpatientByProviderExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_OUTPATIENT_TREND) ?
      generateEmTrendExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, EmDimension.Outpatient) :
      setOptionUnused(evaluationManagementOutpatientByTrendExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_INPATIENT_BY_DEPARTMENT) ?
      generateEmInpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled, stateData,
        benchmarkOption, viewCommunityBenchmarks, LevelType.department) :
      setOptionUnused(evaluationManagementInpatientByDepartmentExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_INPATIENT_BY_SPECIALTY) ?
      generateEmInpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled, stateData,
        benchmarkOption, viewCommunityBenchmarks, LevelType.specialty) :
      setOptionUnused(evaluationManagementInpatientBySpecialtyExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_INPATIENT_BY_PROVIDER) ?
      generateEmInpatientExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled, stateData,
        benchmarkOption, viewCommunityBenchmarks, LevelType.provider) :
      setOptionUnused(evaluationManagementInpatientByProviderExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_INPATIENT_TREND) ?
      generateEmTrendExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, EmDimension.InPatient) :
      setOptionUnused(evaluationManagementInpatientByTrendExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EYE_EXAM_BY_DEPARTMENT) ?
      generateEmOphthalmologyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.department) :
      setOptionUnused(evaluationManagementOphthalmologyByDepartmentExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EYE_EXAM_BY_SPECIALTY) ?
      generateEmOphthalmologyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.specialty) :
      setOptionUnused(evaluationManagementOphthalmologyBySpecialtyExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EYE_EXAM_BY_PROVIDER) ?
      generateEmOphthalmologyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.provider) :
      setOptionUnused(evaluationManagementOphthalmologyByProviderExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EYE_EXAM_TREND) ?
      generateEmTrendExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, EmDimension.Ophthalmology) :
      setOptionUnused(evaluationManagementOphthalmologyByTrendExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EMERGENCY_BY_DEPARTMENT) ?
      generateEmEmergencyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.department) :
      setOptionUnused(evaluationManagementEmergencyByDepartmentExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EMERGENCY_BY_SPECIALTY) ?
      generateEmEmergencyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.specialty) :
      setOptionUnused(evaluationManagementEmergencyBySpecialtyExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EMERGENCY_BY_PROVIDER) ?
      generateEmEmergencyExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, LevelType.provider) :
      setOptionUnused(evaluationManagementEmergencyByProviderExcelData$),
    downloadableMetrics.has(ExportMetricOption.EM_EMERGENCY_TREND) ?
      generateEmTrendExportByTelehealthBenchmarkFeatureToggle(emTelehealthEnabled,
        stateData, benchmarkOption, viewCommunityBenchmarks, EmDimension.EmergencyMedicine) :
      setOptionUnused(evaluationManagementEmergencyByTrendExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsSummary) ? generateExcelCollectionSummaryData(
      stateData.summaryCollectionsData, collectionSummaryColumns.slice()) : setOptionUnused(collectionSummaryDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsByDepartment) ? generateExcelCollectionsSnapshotDataForDisplayedColumns(
        stateData.matchedCollectionsByMultiLevelByNodePathData.departmentCollections,
        columnsForMultiLevelCollectionsDepartment.slice(), display.displayedCollectionsDepartmentColumns, 'department') :
      setOptionUnused(collectionByDepartmentDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsBySpecialty) ? generateExcelCollectionsSnapshotDataForDisplayedColumns(
        stateData.matchedCollectionsByMultiLevelByNodePathData.specialtyCollections, columnsForMultiLevelCollectionsSpecialty.slice(),
        display.displayedCollectionsSpecialtyColumns, 'specialty') :
      setOptionUnused(collectionBySpecialtyDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsByProvider) ? generateExcelCollectionsSnapshotDataForDisplayedColumns(
        stateData.matchedCollectionsByMultiLevelByNodePathData.providerCollections, columnsForMultiLevelCollectionsProvider.slice(),
        display.displayedCollectionsProviderColumns, 'provider') :
      setOptionUnused(collectionByProviderDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsByPayer) ? generateExcelCollectionsPayerData(
      stateData.payerCollectionsData.payerMatchedCollections, columnsForPayerCollections.slice(),
      display.displayedPayerCollectionsColumns) : setOptionUnused(collectionByPayerDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsByPayerByDepartment) ? generateExcelCollectionsPayerByLevelData(
      stateData.matchedCollectionsByPayerByMultiLevelByNodePathData.departmentCollections,
      columnsForMultiLevelPayerCollectionsDepartment.slice(), display.displayedCollectionsByPayerDepartmentColumns,
      'department') : setOptionUnused(collectionByPayerByDepartmentDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsByPayerBySpecialty) ? generateExcelCollectionsPayerByLevelData(
      stateData.matchedCollectionsByPayerByMultiLevelByNodePathData.specialtyCollections,
      columnsForMultiLevelPayerCollectionsSpecialty.slice(), display.displayedCollectionsByPayerSpecialtyColumns,
      'specialty') : setOptionUnused(collectionByPayerBySpecialtyDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.CollectionsByPayerByProvider) ? generateExcelCollectionsPayerByLevelData(
      stateData.matchedCollectionsByPayerByMultiLevelByNodePathData.providerCollections,
      columnsForMultiLevelPayerCollectionsProvider.slice(), display.displayedCollectionsByPayerProviderColumns,
      'provider') : setOptionUnused(collectionByPayerByProviderDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.DenialsByDepartment) ? generateExcelDenialsSnapshotData(
      stateData.denialsByMultiLevelData.departmentDenials, columnsForMultiLevelDenialsDepartment.slice(),
      removeViewCPTsDeniedColumn(getDenialsDepartmentColumns()), 'department'
    ) : setOptionUnused(denialsByDepartmentDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.DenialsBySpecialty) ? generateExcelDenialsSnapshotData(
      stateData.denialsByMultiLevelData.specialtyDenials, columnsForMultiLevelDenialsSpecialty.slice(),
      removeViewCPTsDeniedColumn(getDenialsSpecialtyColumns()), 'specialty'
    ) : setOptionUnused(denialsBySpecialtyDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.DenialsByProvider) ? generateExcelDenialsSnapshotData(
      stateData.denialsByMultiLevelData.providerDenials, columnsForMultiLevelDenialsProvider.slice(),
      removeViewCPTsDeniedColumn(getDenialsProviderColumns()), 'provider'
    ) : setOptionUnused(denialsByProviderDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.DenialsByPayer) ?
      generateExcelDenialsByPayerData(stateData.denials.denialRates || [], columnsForDenialsByPayer.slice(),
        removeViewCPTsDeniedColumn(display.displayedDenialsColumns)) : setOptionUnused(denialsByPayerDataExcelData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_FAMILY) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpByDepartment,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptFamily, getDataExportConfig('department', CptViewType.CptFamily),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryByDepartmentByFamilyData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_RANGE) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpByDepartment,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptRange, getDataExportConfig('department', CptViewType.CptRange),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryByDepartmentByRangeData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_DEPARTMENT_BY_CODE) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpByDepartment,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptCode, getDataExportConfig('department', CptViewType.CptCode),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryByDepartmentByCodeData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_FAMILY) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpBySpecialty,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptFamily, getDataExportConfig('specialty', CptViewType.CptFamily),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryBySpecialtyByFamilyData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_RANGE) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpBySpecialty,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptRange, getDataExportConfig('specialty', CptViewType.CptRange),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryBySpecialtyByRangeData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_SPECIALTY_BY_CODE) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpBySpecialty,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptCode, getDataExportConfig('specialty', CptViewType.CptCode),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryBySpecialtyByCodeData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_FAMILY) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpByProvider,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptFamily, getDataExportConfig('provider', CptViewType.CptFamily),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryByProviderByFamilyData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_RANGE) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpByProvider,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptRange, getDataExportConfig('provider', CptViewType.CptRange),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryByProviderByRangeData$),
    downloadableMetrics.has(ExportMetricOption.PROCEDURE_SUMMARY_BY_PROVIDER_BY_CODE) ?
      generateExcelDataForProcedureSummary(filterCfpDataBasedOnCptGroupAndDrill(stateData.clinicalFingerprintMultilevelData.cfpByProvider,
          display.procedureSummaryDrill, display.selectedCptGroup),
        viewCommunityBenchmarks, CptViewType.CptCode, getDataExportConfig('provider', CptViewType.CptCode),
        showCfteWrvus, showCfteCount) :
      setOptionUnused(procedureSummaryByProviderByCodeData$)
  ].forEach(() => {
  });
  let subscription: Subscription;
  subscription = combineLatest([wrvuByTrendExcelData$, wrvuByDepartmentExcelData$, wrvuBySpecialtyExcelData$,
    wrvuByProviderExcelData$, wrvuBySpecialtyPerformanceExcelData$, npvByTrendExcelData$, npvByDepartmentExcelData$,
    npvBySpecialtyExcelData$, npvByProviderExcelData$, npvByLocationByDepartmentExcelData$,
    npvByLocationBySpecialtyExcelData$, npvByLocationByProviderExcelData$, cptFamilyExcelData$, cptRangeExcelData$,
    cptCodeExcelData$, evaluationManagementOutpatientByDepartmentExcelData$, evaluationManagementOutpatientBySpecialtyExcelData$,
    evaluationManagementOutpatientByProviderExcelData$, evaluationManagementOutpatientByTrendExcelData$,
    evaluationManagementInpatientByDepartmentExcelData$, evaluationManagementInpatientBySpecialtyExcelData$,
    evaluationManagementInpatientByProviderExcelData$, evaluationManagementInpatientByTrendExcelData$,
    evaluationManagementOphthalmologyByDepartmentExcelData$, evaluationManagementOphthalmologyBySpecialtyExcelData$,
    evaluationManagementOphthalmologyByProviderExcelData$, evaluationManagementOphthalmologyByTrendExcelData$,
    evaluationManagementEmergencyByDepartmentExcelData$, evaluationManagementEmergencyBySpecialtyExcelData$,
    evaluationManagementEmergencyByProviderExcelData$, evaluationManagementEmergencyByTrendExcelData$,
    collectionSummaryDataExcelData$, collectionByDepartmentDataExcelData$, collectionBySpecialtyDataExcelData$,
    collectionByProviderDataExcelData$, collectionByPayerDataExcelData$, collectionByPayerByDepartmentDataExcelData$,
    collectionByPayerBySpecialtyDataExcelData$, collectionByPayerByProviderDataExcelData$,
    denialsByPayerDataExcelData$, denialsByDepartmentDataExcelData$, denialsBySpecialtyDataExcelData$,
    denialsByProviderDataExcelData$, procedureSummaryByDepartmentByFamilyData$,
    procedureSummaryByDepartmentByRangeData$, procedureSummaryByDepartmentByCodeData$,
    procedureSummaryBySpecialtyByFamilyData$, procedureSummaryBySpecialtyByRangeData$,
    procedureSummaryBySpecialtyByCodeData$, procedureSummaryByProviderByFamilyData$,
    procedureSummaryByProviderByRangeData$, procedureSummaryByProviderByCodeData$]).subscribe(
    ([wrvuTrend, wrvuDepartment, wrvuSpecialty, wrvuProvider, wrvuSpecPerf, npvTrend, npvDepartment, npvSpecialty, npvProvider,
       npvLocationDepartment, npvLocationSpecialty, npvLocationProvider,
       cptFamily, cptRange, cptCode, EmOutpatientDepartment, EmOutpatientSpecialty, EmOutpatientProvider, EmOutpatientTrend,
       EmInpatientDepartment, EmInpatientSpecialty, EmInpatientProvider, EmInpatientTrend, EmOphthalmologyDepartment,
       EmOphthalmologySpecialty, EmOphthalmologyProvider, EmOphthalmologyTrend, EmEmergencyDepartment, EmEmergencySpecialty,
       EmEmergencyProvider, EmEmergencyTrend, CollectionSummary, CollectionDepartment, CollectionSpecialty, CollectionProvider,
       CollectionPayer, CollectionPayerDepartment, CollectionPayerSpecialty, CollectionPayerProvider, DenialsPayer,
       DenialsDepartment, DenialsSpecialty, DenialsProvider, ProcedureSummaryDepartmentFamily,
       ProcedureSummaryDepartmentRange, ProcedureSummaryDepartmentCode, ProcedureSummarySpecialtyFamily,
       ProcedureSummarySpecialtyRange, ProcedureSummarySpecialtyCode, ProcedureSummaryProviderFamily,
       ProcedureSummaryProviderRange, ProcedureSummaryProviderCode]: [(ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean),
      (ExportMetadata | boolean), (ExportMetadata | boolean), (ExportMetadata | boolean)]) => {
      if (exportReady) {
        subscription.unsubscribe();
        return;
      }
      exportReady = true;
      if (allDefined(wrvuTrend, wrvuDepartment, wrvuSpecialty, wrvuProvider, wrvuSpecPerf, npvTrend, npvDepartment, npvSpecialty,
        npvProvider, npvLocationDepartment, npvLocationSpecialty, npvLocationProvider, cptFamily, cptRange, cptCode,
        EmOutpatientDepartment, EmOutpatientSpecialty, EmOutpatientProvider, EmOutpatientTrend, EmInpatientDepartment,
        EmInpatientSpecialty, EmInpatientProvider, EmInpatientTrend, EmOphthalmologyDepartment, EmOphthalmologySpecialty,
        EmOphthalmologyProvider, EmOphthalmologyTrend, EmEmergencyDepartment, EmEmergencySpecialty, EmEmergencyProvider,
        EmEmergencyTrend, CollectionSummary, CollectionDepartment, CollectionSpecialty, CollectionProvider,
        CollectionPayer, CollectionPayerDepartment, CollectionPayerSpecialty, CollectionPayerProvider, DenialsPayer,
        DenialsDepartment, DenialsSpecialty, DenialsProvider, ProcedureSummaryDepartmentRange,
        ProcedureSummaryDepartmentCode, ProcedureSummarySpecialtyFamily, ProcedureSummarySpecialtyRange,
        ProcedureSummarySpecialtyCode, ProcedureSummaryProviderFamily, ProcedureSummaryProviderRange, ProcedureSummaryProviderCode)) {
        sheetNames = [];
        [wrvuTrend, wrvuDepartment, wrvuSpecialty, wrvuProvider, wrvuSpecPerf, npvTrend, npvDepartment, npvSpecialty, npvProvider,
          npvLocationDepartment, npvLocationSpecialty, npvLocationProvider,
          cptFamily, cptRange, cptCode, EmOutpatientDepartment, EmOutpatientSpecialty, EmOutpatientProvider, EmOutpatientTrend,
          EmInpatientDepartment, EmInpatientSpecialty, EmInpatientProvider, EmInpatientTrend, EmOphthalmologyDepartment,
          EmOphthalmologySpecialty, EmOphthalmologyProvider, EmOphthalmologyTrend, EmEmergencyDepartment, EmEmergencySpecialty,
          EmEmergencyProvider, EmEmergencyTrend, CollectionSummary, CollectionDepartment, CollectionSpecialty, CollectionProvider,
          CollectionPayer, CollectionPayerDepartment, CollectionPayerSpecialty, CollectionPayerProvider, DenialsPayer,
          DenialsDepartment, DenialsSpecialty, DenialsProvider, ProcedureSummaryDepartmentFamily, ProcedureSummaryDepartmentRange,
          ProcedureSummaryDepartmentCode, ProcedureSummarySpecialtyFamily, ProcedureSummarySpecialtyRange,
          ProcedureSummarySpecialtyCode, ProcedureSummaryProviderFamily, ProcedureSummaryProviderRange,
          ProcedureSummaryProviderCode].forEach(arg => {
          if (arg) {
            const asExport = arg as ExportMetadata;
            switch (exportOption) {
              case ExportType.excel:
                exportExcelData.push(asExport);
                sheetNames.push(asExport.sheetName || asExport.fileName);
                break;
              case ExportType.csv:
                const asCsv: Export = {
                  data: asExport.summaryData ? asExport.summaryData.concat(['']).concat(asExport.detailData) :
                    asExport.detailData,
                  headers: asExport.summaryHeaders && asExport.summaryHeaders.length > 0 ? asExport.summaryHeaders :
                    asExport.detailHeaders || [],
                  fileName: asExport.fileName,
                  page: asExport.page,
                  title: asExport.title,
                  whatFilters: asExport.whatFilters
                };
                exportCsvData.push(asCsv);
            }
          }
        });
        switch (exportOption) {
          case ExportType.excel:
            let excelExport: ExportNew;
            if (sheetNames && sheetNames.length > 0) {
              exportExcelData.forEach(eed => {
                eed.filterInfo = getFilterInfo(currentFiltersData, eed.whatFilters);
              });
              excelExport = {
                data: exportExcelData,
                sheets: sheetNames,
                fileName: getExcelFileName(stateData.members, filters.memberKey)
              };
              let page = '';
              sheetNames.forEach((x) => {
                page = page + ' ' + x;
              });
              page = page.trim();
              analyticsService.handleGoogleAnalytics(page, 'EXCEL', 'Export Data');
              excelService.exportAsExcelFile(excelExport);
            }
            break;
          case ExportType.csv:
            if (exportCsvData) {
              for (const csvDatum of exportCsvData) {
                if (csvDatum.page === 'Collections' || csvDatum.page === 'Denials') {
                  csv.export(
                    csvDatum.data,
                    csvDatum.fileName,
                    {
                      fieldSeparator: '|',
                      quoteStrings: '',
                      headers: csvDatum.headers,
                      title: csvDatum.title +
                        '\n' + stateData.selectedMemberData.memberDesc +
                        '\n' + currentFiltersData.breadcrumb +
                        '\n' + 'Selected Date Range (' + getSelectedDateRange(filters.dateRange) + ')' +
                        '\n' + currentFiltersData.csvLocation +
                        '\n' + 'Lag Period: ' + lagPeriods[filters.lagKey].name +
                        (csvDatum.page === 'Collections' ? '\n' + 'Billing Area: ' +
                          filters.memberBillingArea.memberBillingAreaDescription +
                          '\n' + 'Invoice Type: ' + invoiceStatusTypes[filters.invoiceStatus].statusText : '') +
                        (csvDatum.whatFilters?.showPayer ? '\n' + 'Payer Category: ' +
                          filters.payerCategory.payerCategoryDescription + ':' +
                          filters.nationalPayerDescription + ':' +
                          filters.memberPayerDescription : ''),
                      showTitle: (csvDatum.title !== undefined)
                    });
                } else {
                  csv.export(
                    csvDatum.data,
                    csvDatum.fileName,
                    {
                      fieldSeparator: '|',
                      quoteStrings: '',
                      headers: csvDatum.headers,
                      title: csvDatum.title +
                        '\n' + stateData.selectedMemberData.memberDesc +
                        '\n' + currentFiltersData.breadcrumb +
                        (csvDatum.whatFilters?.showCptGroupBreadcrumb
                          ? '\n' + currentFiltersData.cptGroupBreadcrumb : '') +
                        '\n' + 'Selected Date Range (' + getSelectedDateRange(filters.dateRange) + ')' +
                        '\n' + currentFiltersData.csvLocation +
                        (csvDatum.whatFilters?.showPayer ? '\n' + 'Payer Category: ' +
                          filters.payerCategory.payerCategoryDescription : ''),
                      showTitle: (csvDatum.title !== undefined)
                    });
                }
                analyticsService.handleGoogleAnalytics(csvDatum.page, 'CSV', 'Export Data');
              }
            }
        }
      }
    });
}

export function collectPdfOptions(options: MultilevelExportOption[]): MultilevelExportOption[] {
  let selectedOptions: MultilevelExportOption[] = [];
  options.forEach(o => {
    if (o.selected) {
      selectedOptions.push(o);
    }
    selectedOptions = selectedOptions.concat(collectPdfOptions(o.children));
  });
  return selectedOptions;
}

export function exportToPdf(options: MultilevelExportOption[], analyticsService: AnalyticsService): [PdfExportData, EmDimension[]] {
  const pdfData: PdfExportData = {
    wrvuTrend: false,
    wrvuSnapshot: false,
    npvTrend: false,
    npvSnapshot: false,
    emOutpatient: false,
    emInpatient: false,
    emEmergencyMedicine: false,
    emOphthalmology: false,
    overview: false,
    collections: false
  };
  const emDimensions: EmDimension[] = [];
  // const downloadableMetrics = options.filter(x => x.selected);
  const downloadableMetrics = collectPdfOptions(options);
  if (downloadableMetrics) {
    let page = '';
    downloadableMetrics.forEach((x) => {
      page = page + ' ' + x.page;
    });
    page = page.trim();
    analyticsService.handleGoogleAnalytics(page, 'PDF', 'Export Data');
    _.orderBy(downloadableMetrics, ['id'], ['asc']).forEach((x) => {
      switch (x.id) {
        case ExportMetricOption.WRVU_TREND:
          pdfData.wrvuTrend = true;
          break;
        case ExportMetricOption.WRVU_SNAPSHOT:
          pdfData.wrvuSnapshot = true;
          break;
        case ExportMetricOption.NPV_TREND:
          pdfData.npvTrend = true;
          break;
        case ExportMetricOption.NPV_SNAPSHOT:
          pdfData.npvSnapshot = true;
          break;
        case ExportMetricOption.EM_OUTPATIENT:
          pdfData.emOutpatient = true;
          emDimensions.push(EmDimension.Outpatient);
          break;
        case ExportMetricOption.EM_INPATIENT:
          pdfData.emInpatient = true;
          emDimensions.push(EmDimension.InPatient);
          break;
        case ExportMetricOption.EM_EMERGENCY:
          pdfData.emEmergencyMedicine = true;
          emDimensions.push(EmDimension.EmergencyMedicine);
          break;
        case ExportMetricOption.EM_EYE_EXAM:
          pdfData.emOphthalmology = true;
          emDimensions.push(EmDimension.Ophthalmology);
          break;
        case ExportMetricOption.OVERVIEW:
          pdfData.overview = true;
          break;
        default:
          break;
      }
    });
  }
  return [pdfData, emDimensions];
}

function getExcelFileName(members: Members, memberKey: number): string {
  const member = members.members.find(
    x => x.memberKey === memberKey);
  return member ? member.memberDesc : 'cpsc';
}

function getDataExportConfig(tabName: string, cptViewType: CptViewType): DataExportConfig {
  switch (tabName) {
    case 'department':
      return {
        fileName: 'ProcedureSummary By Department',
        sheetName: `Proc Sum ${getCptViewText(cptViewType)} By Department`,
        page: 'ProcedureSummary By Department',
        tabName: 'department'
      };
    case 'specialty':
      return {
        fileName: 'ProcedureSummary By Specialty',
        sheetName: `Proc Sum ${getCptViewText(cptViewType)} By Specialty`,
        page: 'ProcedureSummary By Specialty',
        tabName: 'specialty'
      };
    case 'provider':
      return {
        fileName: 'ProcedureSummary By Provider',
        sheetName: `Proc Sum ${getCptViewText(cptViewType)} By Provider`,
        page: 'ProcedureSummary By Provider',
        tabName: 'provider'
      };
    default:
      return {
        fileName: 'ProcedureSummary By Provider',
        sheetName: `Proc Sum ${getCptViewText(cptViewType)} By Provider`,
        page: 'ProcedureSummary By Provider',
        tabName: 'provider'
      };
  }
}

export interface DataExportConfig {
  fileName: string;
  sheetName: string;
  page: string;
  tabName: string;
}

function removeViewCPTsDeniedColumn(cols: any[]) {
  const newCols = _.cloneDeep(cols);
  newCols.filter(c => c.columnDef !== viewCPTsDeniedColumn.columnDef);
  return newCols;
}

function getFilterInfo(currentExportFilters: MultilevelExportFiltersData, whatFilters?: WhatFilters): string[] {
  const filterInfo: string[] = [];
  if (whatFilters?.showBreadcrumb) {
    filterInfo.push(currentExportFilters.breadcrumb ?? '');
  }
  if (whatFilters?.showCptGroupBreadcrumb) {
    filterInfo.push(currentExportFilters.cptGroupBreadcrumb ?? '');
  }
  if (whatFilters?.showDateRange) {
    filterInfo.push(currentExportFilters.dataRange ?? '');
  }
  if (whatFilters?.showLocation) {
    filterInfo.push(currentExportFilters.excelLocation ?? '');
  }
  if (whatFilters?.showPayer) {
    filterInfo.push(currentExportFilters.payer ?? '');
  }
  if (whatFilters?.showBilling) {
    filterInfo.push(currentExportFilters.billing ?? '');
  }
  if (whatFilters?.showLag) {
    filterInfo.push(currentExportFilters.lag ?? '');
  }
  if (whatFilters?.showInvoice) {
    filterInfo.push(currentExportFilters.invoice ?? '');
  }
  if (whatFilters?.showVisitType) {
    filterInfo.push(currentExportFilters.visitType ?? '');
  }
  return filterInfo;
}

export interface DataCellsAndHeaders {
  cells: string[][];
  headers: string[];
}

export interface EmSummaryRow {
  field: string;
  heading: string;
  suffix: string;
  round?: boolean;
}

export function calculateDepth(options: ExpandableExportOption<any>[], depth: number): void {
  options.forEach(o => {
    o.depth = depth;
    calculateDepth(o.children, 1 + depth);
  });
}
