<div id="collections-graph-wrapper">
  <div id="chart-heading">
    <span class="chart-title-wrapper">
      <span id="chart-title">
         Net Collection Rate by Payer
      </span>
      <div id="static-note-container">
        Note: These are based on charge entry (Invoice creation) date</div>
    </span>
    <span class="right-header-wrapper">
      <app-variable-container
        [variables]="variables"
        [showVariableMenu]="showVariableMenu"
        [page]="page"
        [disabled]="activeVarianceToggle"
      ></app-variable-container>
      <app-variance-toggler [varianceToggleAction]="varianceToggle"></app-variance-toggler>
    </span>
  </div>
  <div [chart]="chartObject" id="collections-summary-graph"></div>
  <app-chart-legend [legends]="legends"
                    [hasBenchmarks]="hasBenchmarks"
                    [benchmarksToExclude]="benchmarksToExclude"
                    [defaultBenchmarkPercentile]="defaultBenchmarkPercentile"
  ></app-chart-legend>
  <app-loader  [class.loader-hidden]="!showProgressBar"></app-loader>
</div>
