import {OntologyData} from '../store/IAppState';

export function getOntologyData(): OntologyData {
  if (localStorage.getItem('OntologyData') === null) {
    return {
      ontologyHierarchy: [],
      numberOfProviders: 0,
      numberOfSpecialties: 0,
      numberOfDepartments: 0
    };
  }
    // @ts-ignore
    return JSON.parse(localStorage.getItem('OntologyData'));
}

export function setOntologyData(value: OntologyData) {
  if (localStorage.getItem('OntologyData') !== null) {
    localStorage.removeItem('OntologyData');
  }
  localStorage.setItem('OntologyData', JSON.stringify(value));
}

export function setOntologyDefaultData() {
  if (localStorage.getItem('OntologyData') !== null) {
    localStorage.removeItem('OntologyData');
  }
  localStorage.setItem('OntologyData', JSON.stringify({
    ontologyHierarchy: [],
    numberOfProviders: 0,
    numberOfSpecialties: 0,
    numberOfDepartments: 0
  }));
}

export function removeOntologyData() {
  if (localStorage.getItem('OntologyData') !== null) {
    localStorage.removeItem('OntologyData');
  }
}
