<mat-dialog-content>
  <div id="warning-limit-wrapper">
    <div id="user-attention">Attention</div>
    <div id="why-warning">Your list exceeds {{data.maxNodePaths}} selections, please adjust your
      <span id="txt-select-providers">Selected Providers</span> list to continue.
    </div>
    <div id="limit-guideline-wrapper">You can make either of the following selections:
      <div class="limit-guideline">- Up to {{data.maxNodePaths}} entire departments or specialties</div>
      <div class="limit-guideline">- Or, a customized list of up to {{data.maxNodePaths}} individual providers</div>
    </div>
    <button id="btn-ok" (click)="close()">Ok</button>
  </div>
</mat-dialog-content>
