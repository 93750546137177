import * as _ from 'lodash';
import {FilterCriteria, IAppState} from '../store/IAppState';
import {ChangedFilterCriteriaParameters} from './models';
import {DatasetType} from './enums';
import {getAppConfigValue} from './helpers';
import {AppConfigEntries} from './app-config-settings-enum';
import {DEFAULT_CUSTOM_GROUP_ID} from '../store/DefaultValues';
import {DataTableColumns} from './data-table-columns';

export function areFiltersEqual(previous: FilterCriteria, current: FilterCriteria, includeDescriptions: boolean,
                                includeCG_key: boolean): boolean {
  return _.isEqual({
      startYear: previous.dateRange.startYear,
      startMonth: previous.dateRange.startMonth,
      endYear: previous.dateRange.endYear,
      endMonth: previous.dateRange.endMonth,
      selectedDateRangeOption: previous.dateRange.selectedDateRangeOption,
      fiscalYear: previous.dateRange.fiscalYear,
      nodePath: previous.nodePath,
      memberLocation: includeDescriptions ? previous.memberLocation : previous.memberLocation.memberLocationKey,
      payerCategory: includeDescriptions ? previous.payerCategory : previous.payerCategory.payerCategoryKey,
      payerKey: previous.payerKey,
      payerType: previous.payerType,
      lagKey: previous.lagKey,
      memberBillingArea: includeDescriptions ? previous.memberBillingArea : previous.memberBillingArea.memberBillingAreaKey,
      invoiceStatus: previous.invoiceStatus,
      telehealthFlag: previous.telehealthFlag, memberLocationKeys: previous.memberLocationKeys,
      customGroupId: includeCG_key ? previous.customGroupId : DEFAULT_CUSTOM_GROUP_ID
    },
    {
      startYear: current.dateRange.startYear,
      startMonth: current.dateRange.startMonth,
      endYear: current.dateRange.endYear,
      endMonth: current.dateRange.endMonth,
      selectedDateRangeOption: current.dateRange.selectedDateRangeOption,
      fiscalYear: current.dateRange.fiscalYear,
      nodePath: current.nodePath,
      memberLocation: includeDescriptions ? current.memberLocation : current.memberLocation.memberLocationKey,
      payerCategory: includeDescriptions ? current.payerCategory : current.payerCategory.payerCategoryKey,
      payerKey: current.payerKey,
      payerType: current.payerType,
      lagKey: current.lagKey,
      memberBillingArea: includeDescriptions ? current.memberBillingArea : current.memberBillingArea.memberBillingAreaKey,
      invoiceStatus: current.invoiceStatus,
      telehealthFlag: current.telehealthFlag, memberLocationKeys: current.memberLocationKeys,
      customGroupId: includeCG_key ? current.customGroupId : DEFAULT_CUSTOM_GROUP_ID
    });
}

export function getChangedFilterCriteria(oldFilter: FilterCriteria, newFilter: FilterCriteria):
  ChangedFilterCriteriaParameters {
  const changedFilterCriteria: ChangedFilterCriteriaParameters = {};

  changedFilterCriteria.isCollectionOnly = _.isEqual({
        dateRange: oldFilter.dateRange,
        nodePath: oldFilter.nodePath,
        memberLocation: oldFilter.memberLocation
      },
      {
        dateRange: newFilter.dateRange,
        nodePath: newFilter.nodePath,
        memberLocation: newFilter.memberLocation
      }) &&

    !_.isEqual({
        payerCategory: oldFilter.payerCategory,
        payerKey: oldFilter.payerKey,
        payerType: oldFilter.payerType,
        lagKey: oldFilter.lagKey,
        memberBillingArea: oldFilter.memberBillingArea,
        invoiceStatus: oldFilter.invoiceStatus
      },
      {
        payerCategory: newFilter.payerCategory,
        payerKey: newFilter.payerKey,
        payerType: newFilter.payerType,
        lagKey: newFilter.lagKey,
        memberBillingArea: newFilter.memberBillingArea,
        invoiceStatus: newFilter.invoiceStatus
      });

  changedFilterCriteria.isDenialsOnly = _.isEqual({
        dateRange: oldFilter.dateRange,
        nodePath: oldFilter.nodePath,
        memberLocation: oldFilter.memberLocation
      },
      {
        dateRange: newFilter.dateRange,
        nodePath: newFilter.nodePath,
        memberLocation: newFilter.memberLocation
      }) &&

    !_.isEqual({
        payerCategory: oldFilter.payerCategory,
        payerKey: oldFilter.payerKey,
        payerType: oldFilter.payerType,
        lagKey: oldFilter.lagKey,
        memberBillingArea: oldFilter.memberBillingArea,
        invoiceStatus: oldFilter.invoiceStatus
      },
      {
        payerCategory: newFilter.payerCategory,
        payerKey: newFilter.payerKey,
        payerType: newFilter.payerType,
        lagKey: newFilter.lagKey,
        memberBillingArea: newFilter.memberBillingArea,
        invoiceStatus: newFilter.invoiceStatus
      });

  changedFilterCriteria.isNewPatientVisitOnly = _.isEqual({
        dateRange: oldFilter.dateRange,
        nodePath: oldFilter.nodePath,
        memberLocation: oldFilter.memberLocation
      },
      {
        dateRange: newFilter.dateRange,
        nodePath: newFilter.nodePath,
        memberLocation: newFilter.memberLocation
      }) &&

    !_.isEqual({
        payerCategory: oldFilter.payerCategory,
        telehealthFlag: oldFilter.telehealthFlag
      },
      {
        payerCategory: newFilter.payerCategory,
        telehealthFlag: newFilter.telehealthFlag
      });

  changedFilterCriteria.isEvaluationManagementOnly = _.isEqual({
        dateRange: oldFilter.dateRange,
        nodePath: oldFilter.nodePath,
        memberLocation: oldFilter.memberLocation
      },
      {
        dateRange: newFilter.dateRange,
        nodePath: newFilter.nodePath,
        memberLocation: newFilter.memberLocation
      }) &&

    !_.isEqual({
        telehealthFlag: oldFilter.telehealthFlag
      },
      {
        telehealthFlag: newFilter.telehealthFlag
      });
  return changedFilterCriteria;
}

export function determineAllowedDataset(state: IAppState): DatasetType {
  const maxSpecialties = +getAppConfigValue(AppConfigEntries.MAX_SPECIALTY_PATHS, state.data.applicationConfigurationSettings);
  const maxProviders = +getAppConfigValue(AppConfigEntries.MAX_PROVIDER_PATHS, state.data.applicationConfigurationSettings);
  const {selectedProviders, selectedSpecialties} = state.display;
  if (selectedSpecialties > maxSpecialties) {
    return DatasetType.DEPARTMENT_ONLY;
  } else if (selectedProviders > maxProviders) {
    return DatasetType.DEPARTMENT_SPECIALTY;
  }

  return DatasetType.ALL;
}

export const INT_REGEX = /[^\-*\d.]/g;

export function numberValueFromDataName(column: DataTableColumns, value: any): number {
  return +`${column.dataName(value)}`.replace(INT_REGEX, '');
}

export function tieBreakerComparor(tieBreaker: (item: any) => string, ascending: boolean, a: any, b: any): number {
  return ascending ? tieBreaker(a).localeCompare(tieBreaker(b)) : tieBreaker(b).localeCompare(tieBreaker(a));
}
