import {ListItemExpansion, PayerCategoryLevel, PayerObject} from '../../shared/models';
import {NodeExpansionState, PayerType} from '../../shared/enums';
import {FilterCriteria} from '../../store/IAppState';
import {getOppositeNodeExpansionState, nodeExpansionIsActive} from '../../shared/helpers';

export interface SelectableItem<T> {
  selected: boolean;
  belongs: boolean;
  matchesSearchText: boolean;
  item: T;
  expansion?: ListItemExpansion;
  displayText?: string;
}

export interface RelatableSelectableItem<T> {
  item: SelectableItem<T>;
  relatives: SelectableItem<T>[];
}

export enum ScenarioForDisplayability {
  Standard = 0,
  MatchSearchTextRegardless = 1,
  IsParentSelected = 2,
  RelativeMatchesSearchText = 3,
  IsParentExpanded = 4,
  Belong
}

export function setUpPayerHierarchy(payers: PayerCategoryLevel[], filters: FilterCriteria):
  RelatableSelectableItem<PayerObject>[] {
  const items: RelatableSelectableItem<PayerObject>[] = [];
  payers.forEach(payerCategory => {
    const {payerCategoryKey, payerCategoryDesc, nationalPayers} = payerCategory;
    const categoryItem: SelectableItem<PayerObject> = {
      selected: filters.payerCategory.payerCategoryKey === payerCategoryKey,
      belongs: true,
      matchesSearchText: true,
      item: {
        level: PayerType.CATEGORY,
        key: payerCategoryKey,
        description: payerCategoryDesc,
        payerCriteria: {
          payerCategoryKey,
          payerCategoryDesc
        }
      },
      expansion: {
        state: (nationalPayers && nationalPayers.length)
          ? (filters.payerCategory.payerCategoryKey === payerCategoryKey && !!filters.nationalPayerKey
                ? NodeExpansionState.EXPANDED : NodeExpansionState.COLLAPSED)
          : NodeExpansionState.LEAF,
        depth: 0
      },
      displayText: payerCategoryDesc
    };
    const categoryRelatable: RelatableSelectableItem<PayerObject> = {
      item: categoryItem,
      relatives: []
    };
    items.push(categoryRelatable);
    nationalPayers.forEach(nationalPayer => {
      const {nationalPayerKey, nationalPayerDesc, memberPayers} = nationalPayer;
      const nationalItem: SelectableItem<PayerObject> = {
        selected: filters.nationalPayerKey === nationalPayerKey,
        belongs: true,
        matchesSearchText: true,
        item: {
          level: PayerType.NATIONAL,
          key: nationalPayerKey,
          description: nationalPayerDesc,
          payerCriteria: {
            payerCategoryKey,
            payerCategoryDesc,
            nationalPayerKey,
            nationalPayerDesc
          }
        },
        expansion: {
          state: (memberPayers && memberPayers.length)
          ? (filters.payerCategory.payerCategoryKey === payerCategoryKey && filters.nationalPayerKey === nationalPayerKey
            && !!filters.memberPayerKey ? NodeExpansionState.EXPANDED : NodeExpansionState.COLLAPSED)
          : NodeExpansionState.LEAF,
          depth: 1
        },
        displayText: nationalPayerDesc
      };
      categoryRelatable.relatives.push(nationalItem);
      const nationalRelatable = {
        item: nationalItem,
        relatives: [categoryItem]
      };
      items.push(nationalRelatable);
      memberPayers.forEach(memberPayer => {
        const {memberPayerKey, memberPayerDesc} = memberPayer;
        const memberItem: SelectableItem<PayerObject> = {
          selected: filters.memberPayerKey === memberPayerKey,
          belongs: true,
          matchesSearchText: true,
          item: {
            level: PayerType.MEMBER,
            key: memberPayerKey,
            description: memberPayerDesc,
            payerCriteria: {
              payerCategoryKey,
              payerCategoryDesc,
              nationalPayerKey,
              nationalPayerDesc,
              memberPayerKey,
              memberPayerDesc
            }
          },
          expansion: {
            state: NodeExpansionState.LEAF,
            depth: 2
          },
          displayText: memberPayerDesc
        };
        categoryRelatable.relatives.push(memberItem);
        nationalRelatable.relatives.push(memberItem);
        const memberRelatable: RelatableSelectableItem<PayerObject> = {
          item: memberItem,
          relatives: [categoryItem, nationalItem]
        };
        items.push(memberRelatable);
      });
    });
  });
  return items;
}

export function isItemAbleToShow(item: RelatableSelectableItem<any>, scenario: ScenarioForDisplayability): boolean {
  switch (scenario) {
    case ScenarioForDisplayability.Standard:
      return item.item.matchesSearchText && item.item.belongs;
    case ScenarioForDisplayability.MatchSearchTextRegardless:
      return item.item.matchesSearchText;
    case ScenarioForDisplayability.IsParentSelected:
      return !!item.relatives.find(relative => (relative.expansion?.depth || 0) + 1 === (item.item.expansion?.depth || 1)
        && relative.selected);
    case ScenarioForDisplayability.IsParentExpanded:
      return !!item.relatives.find(relative => (relative.expansion?.depth || 0) + 1 === (item.item.expansion?.depth || 1)
        && relative.expansion?.state === NodeExpansionState.EXPANDED);  // for immediate parent expanded
    case ScenarioForDisplayability.RelativeMatchesSearchText:
      return item.item.selected && !!item.relatives.find(relative => relative.matchesSearchText);
    default:
      return true;
  }
}

export function neutralizeExpansion(item: SelectableItem<any>): void {
  if (item.expansion && nodeExpansionIsActive(item.expansion.state)) {
    item.expansion.state = getOppositeNodeExpansionState(item.expansion.state);
  }
}
