<div id="groupings-container">
  <mat-form-field appearance="outline"
                  subscriptSizing="dynamic"
                  id="custom-group-form"
                  [matTooltip]=""
                  [matTooltipDisabled]="">
    <mat-select [(value)]="currentSelectedGroup"
                hideSingleSelectionIndicator
                panelClass="custom-group"
                id="custom-group-select"
                [placeholder]="'My Groupings'"
                [matTooltip]="!currentSelectedGroup? 'Select a previously saved grouping.' : currentSelectedGroup.name"
                [matTooltipPosition]="'right'"
                [matTooltipDisabled]="currentSelectedGroup && currentSelectedGroup?.name?.length<20"
                (selectionChange)="selectGroup($event.value)">
      <mat-select-trigger id="custom-group-trigger">{{currentSelectedGroup?.name}}</mat-select-trigger>
      <mat-option id="manage-groupings"
                  class="custom-group-item"
                  [disabled]="true"
                  (click)="manageGroupings()">
        <span class="manage-groupings">Manage Groupings</span>
      </mat-option>
      <mat-option *ngFor="let group of customGroups; let i = index"
                  class="custom-group-item"
                  id="cg-{{i}}"
                  [value]="group"
                  [matTooltip]="group.name"
                  [matTooltipDisabled]="group.name?.length<maxCharsForGroupInDropdown">
        <span id="{{group.name}}"
              class="group-name">{{group.name}}</span>
        <span *ngIf="group.isDefault"
              id="default-cg"
              class="only-default-group">Default Grouping</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
