<table class="mat-calendar-table">
  <thead class="mat-calendar-table-header">
    <tr>
      <th class="mat-calendar-table-header-divider" colspan="4"></th>
    </tr>
  </thead>
  <tbody sat-calendar-body
         allowDisabledSelection="false"
         [label]="_yearLabel"
         [rows]="_months"
         [todayValue]="_todayMonth"
         [selectedValue]="_selectedMonth"
         [begin]="_beginMonthNumber"
         [end]="_endMonthNumber"
         [rangeFull]="_rangeFull"
         [rangeMode]="rangeMode"
         [labelMinRequiredCells]="2"
         [numCols]="4"
         [cellAspectRatio]="4 / 7"
         [activeCell]="_dateAdapter.getMonth(activeDate)"
         (selectedValueChange)="_monthSelected($event)"
         (keydown)="_handleCalendarBodyKeydown($event)">
  </tbody>
</table>
