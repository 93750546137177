
<div class="data-table-container-wrapper">
  <app-loader *ngIf="displayLoadingIndicator"></app-loader>
  <div class="{{tableCssClass}}-table-header" *ngIf="shouldDisplayTheHeaders">
    <div class="lower-header">
      <span *ngFor="let column of columns" class="header-content" (click)="updateSortingDirection(column.columnDef)">
        <mat-checkbox *ngIf="column.displayCheckbox" [checked]="allOfContentsChecked"
                      [indeterminate]="indeterminateContentsChecked" (click)="changeHeaderCheck($event)">
            </mat-checkbox><div class="header-text {{column.allowSort ? 'sortable' : ''}} {{column.header}}">
        {{column.header}}</div>
        <span id="sort-wrapper" *ngIf="column.allowSort">
          <i class="fa fa-arrow-up" *ngIf="sortDirection==='asc' && sortColumn===column.columnDef"></i>
          <i class="fa fa-arrow-down" *ngIf="sortDirection==='desc' && sortColumn===column.columnDef"></i>
        </span>
      </span>
    </div>
  </div>
  <div [ngClass]="tableCssClass" (scroll)="handleScroll($event)">
    <span id="emptiness-indicator" *ngIf="emptinessIndicationMessage">{{emptinessIndicationMessage}}</span>
    <mat-table [dataSource]="dataSource"
               matSort
               matSortDirection="asc"
               matSortActive="{{sortColumn}}"
               class="node-table">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <mat-cell *matCellDef="let row" class="{{column.columnDef}} {{getCssClassBasedOnDepth(
          row.perceivedExpansionState)}} {{getCssClassBasedOnSelectedState(row?.selected)}}">
          <div [ngSwitch]="tableCssClass" class="cell-inner-container">
            <span [ngClass]="getNodeRowClass(row.perceivedExpansionState, column.columnDef)">
                <mat-checkbox *ngIf="column.displayCheckbox" [checked]="column.selected(row)"></mat-checkbox>
                <span *ngIf="row.perceivedExpansionState">
                  <span [ngSwitch]="row.perceivedExpansionState.state" *ngIf="column.displayCaretsLeft"
                        (click)="expandOrCollapseRow(row, $event)">
                    <i class="fa fa-caret-down" *ngSwitchCase="1"></i>
                    <i class="fa fa-caret-up" *ngSwitchCase="0"></i>
                  </span>
                </span>
            </span>
            <div *ngSwitchCase="'provider-table'" class="node-name-wrapper">
              <div class="node-name" *ngIf="!highlightedSearchText">
                <span>{{ column.dataName(row) }}</span>
              </div>
              <div class="node-name-separated" *ngIf="highlightedSearchText">
                <span class="no-high-light">{{getWhatShouldBeHighlighted(column.dataName(row))[0]}}</span>
                <span class="high-light">{{getWhatShouldBeHighlighted(column.dataName(row))[1]}}</span>
                <span class="no-high-light">{{getWhatShouldBeHighlighted(column.dataName(row))[2]}}</span>
              </div>
            </div>
            <div *ngIf="!(tableCssClass === 'provider-table')" class="node-name-wrapper">
              <div class="node-name">
                {{ column.dataName(row) }}
              </div>
            </div>
          </div>
          <span *ngIf="row.perceivedExpansionState">
            <span [ngSwitch]="row.perceivedExpansionState.state" *ngIf="column.displayCaretsRight">
              <img *ngSwitchCase="0" alt="Chevron Symbol" src="../../../assets/InvertedAppsChevron.svg"
                   class="chevron-symbol-inverted"/>
              <img *ngSwitchCase="1" alt="Chevron Symbol" src="../../../assets/AppsChevron.svg"
                   class="chevron-symbol"/>
            </span>
          </span>
        </mat-cell>

      </ng-container>
      <mat-row (click)="manuallySelectRow(row, $event)"
               *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
