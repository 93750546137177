import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.local';
import {BatchFilterCriteria, MemberLocation} from '../shared/models';
import {FilterCriteria} from '../store/IAppState';
import {getRequestBodyFromBatchFilter} from '../shared/test/helper-functions.spec';
import {getRequestBodyFromFilter} from '../shared/helpers';

export const MemberLocationServiceToken =
  new InjectionToken<MemberLocationService>('Member Location Service');

export interface MemberLocationService {
  getMemberLocations(filter: FilterCriteria): Observable<MemberLocation[]>;
  getMemberLocationsForBatchExport(batchFilter: BatchFilterCriteria): Observable<MemberLocation[]>;
}

@Injectable()
export class MemberLocationServiceImpl implements MemberLocationService {

  constructor(private readonly httpClient: HttpClient) { }

  getMemberLocations(filter: FilterCriteria): Observable<MemberLocation[]> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/memberLocations`;
    return this.httpClient.post<MemberLocation[]>(
      url,
      requestBody,
      {
        params:
          new HttpParams()
            .set('memberKey', filter.memberKey.toString())
      },
    );
  }

  getMemberLocationsForBatchExport(batchFilter: BatchFilterCriteria): Observable<MemberLocation[]> {
    const requestBody = getRequestBodyFromBatchFilter(batchFilter);
    const url = `${environment.api}/api/memberLocations`;
    return this.httpClient.post<MemberLocation[]>(
      url,
      requestBody,
      {
        params:
          new HttpParams()
            .set('memberKey', batchFilter.memberKey.toString())
      },
    );
  }
}
