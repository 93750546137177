<mat-dialog-content>
  <button mat-button id="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>

  <div id="report-dialog-header-wrapper">
    <h1 id="report-dialog-header">My Reports</h1>
    <p>Exports will only be saved in the archive for 6 months</p>
  </div>

  <mat-tab-group *ngIf="redesignEnabled" mat-stretch-tabs="false" mat-align-tabs="start" id="one-time-scheduled-wrapper">
    <mat-tab id="one-time-tab" [label]="oneTimeExportsLabel">
      <ng-template matTabLabel>
        <span id="unread-one-time-count"
              matBadge="{{unreadOneTimeReportsCount}}"
              [matBadgeHidden]="unreadOneTimeReportsCount < 1"
              matBadgeOverlap="false"
              matBadgeColor="accent">{{oneTimeExportsLabel}}</span>
      </ng-template>
      <div class="tab-content">
        <app-one-time-exports-table [dataSource]="dataSource"
                                    [columns]="columnsForOneTimeTab"
                                    [stringColumnsForRowDef]="stringColumnsForRowDefForOneTimeTab"
                                    (downloadRecord)="getReportFileAndUpdateReadStatus($event, false)"
        ></app-one-time-exports-table>
      </div>
    </mat-tab>
    <mat-tab id="automated-tab" [label]="scheduledExportsLabel">
      <ng-template matTabLabel>
        <span id="unread-scheduled-count"
              matBadge="{{unreadScheduledReportsCount > 0 ? unreadScheduledReportsCount : undefined}}"
              [matBadgeHidden]="unreadScheduledReportsCount < 1"
              matBadgeOverlap="false"
              matBadgeColor="accent">{{scheduledExportsLabel}}</span>
      </ng-template>
      <div class="tab-content">
        <app-scheduled-exports-table [existingReportsResponse]="scheduledTabDataSource"
                                     [parentColumns]="parentColumnsForScheduledTable"
                                     [childColumns]="childColumnsForScheduledTable"
                                     [stringColumnsForParentRowDef]="stringColumnsForParentRowDefForScheduledTab"
                                     [stringColumnsForChildRowDef]="stringColumnsForChildRowDefForScheduledTab"
                                     (downloadRecord)="getReportFileAndUpdateReadStatus($event, true)"
        ></app-scheduled-exports-table>
      </div>
    </mat-tab>
  </mat-tab-group>
  <h2 *ngIf="!redesignEnabled" id="multi-provider-reports-header">Multi-Provider Exports</h2>

  <span *ngIf="showPostDownloadPopup" id="post-download-popup-wrapper">
  <span id="post-download-popup-content"><h2>Your export is downloading!</h2>
    <span id="download-popup-text">Please allow up to 5 minutes for your zip file to be <br> downloaded. You may exit
      this page and continue <br>working within CPSC Analytics.</span>
    <div id="post-download-button-wrapper">
      <button id="close-post-download-popup" mat-raised-button (click)="closePostDownloadPopup()">Ok</button></div>
  </span>
</span>

  <app-one-time-exports-table *ngIf="!redesignEnabled"
                              [dataSource]="dataSource"
                              [columns]="columnsForOneTimeTab"
                              [stringColumnsForRowDef]="stringColumnsForRowDefForOneTimeTab"
                              (downloadRecord)="getReportFileAndUpdateReadStatus($event)"
  ></app-one-time-exports-table>
</mat-dialog-content>
