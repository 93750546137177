// TODO: Ask in engineering review if the following can be used to refactor everywhere where objects are indexed.
import {SimpleChanges} from '@angular/core';
import * as _ from 'lodash';

export function getValueFromObjectWithStringIndexing(entry: any, ...args: string[]): any {
  if (args.length === 0) {
    return entry;
  }
  if (args.length === 1) {
    return entry[args[0]];
  }
  return getValueFromObjectWithStringIndexing(entry[args[0]], ...args.slice(1));
}

export function anyDesignatedPropertiesChanged(changes: SimpleChanges, properties: string[]): boolean {
  if (properties.length === 0) {
    return false;
  }
  if (!_.isEqual(changes[properties[0]]?.previousValue, changes[properties[0]]?.currentValue)) {
    return true;
  }
  return anyDesignatedPropertiesChanged(changes, properties.slice(1));
}
