<div id="hover-wrapper">
  <div id="hover-display" *ngIf="shouldShowHoverMessage">
    {{hoverMessageText}}
  </div>
</div>
<div id="zero-suppression-wrapper">
  <mat-checkbox class="check-zero-suppression" (change)="suppressZeros()"
              [checked]="isZeroSuppressionChecked">
    <span *ngIf="!isZeroSuppressionChecked">Apply Zero Suppression</span>
    <span *ngIf="isZeroSuppressionChecked">
                    {{countOfSuppressedEntries}} {{snapshotEntryName}} Suppressed</span>
  </mat-checkbox>
  <span id="info-wrapper" *ngIf="condition"
        (mouseenter)="toggleShowHoverMessage(true)"
        (mouseleave)="toggleShowHoverMessage(false)"
  ><img alt="info" src="../../assets/info-icon.svg" id="info-icon"
  /></span>
</div>
