<span (click)="toggleCptRanges()" id="toggle">
  <span id="toggle-top" class="toggle-text">
    <span id="toggle-text-wrapper">
      <span #elementRef id="toggle-label" class="{{additionalSelected ? 'toggle-label-more' : ''}}"
        [matTooltip]="toggleLabel"
        [matTooltipDisabled]="isTooltipDisabled(elementRef)">{{toggleLabel}}
      </span>
      <span *ngIf="additionalSelected" id="additional" class="toggle-text">{{additionalLabel}}</span>
    </span>
    <i class="fa fa-caret-down"></i></span>
</span>

<div id="cptrange-dropdown" *ngIf="showCptRange">
  <div id="list-wrapper">
    <div class="input-search-wrapper">
      <i id="search-glass" class="fa fa-search search-icon" aria-hidden="true"
         [matTooltip]="'3 letters required'" [matTooltipPosition]="'above'"
         [matTooltipDisabled]="searchText.length >= 3" [matTooltipClass]="'custom-tooltip'"></i>
      <input type="text" placeholder="Search..." id="search-for" (input)="onSearchTextChanged()" [(ngModel)]="searchText">
    </div>
    <mat-checkbox (click)="selectAllCptRanges($event)" [checked]="displayedSelectedRanges.length &&
     !displayedUnselectedRanges.length" id="select-all">Select All</mat-checkbox>
    <div class="cptrange-section-heading">Selected Ranges</div>

    <div *ngFor="let range of displayedSelectedRanges" class="cptrange-item"
         (click)="whenCptRangeClicked(range, $event)">
      <mat-checkbox [checked]="true" class="selected-cptrange">
        {{range.item.item.cptRangeLow}}-{{range.item.item.cptRangeHigh}} {{range.item.item.cptRangeDesc}}</mat-checkbox>
    </div>
    <div class="cptrange-section-heading">Other Ranges</div>
    <div *ngFor="let range of displayedUnselectedRanges" class="cptrange-item"
         (click)="whenCptRangeClicked(range, $event)">
      <mat-checkbox [checked]="false" class="unselected-cptrange">
        {{range.item.item.cptRangeLow}}-{{range.item.item.cptRangeHigh}} {{range.item.item.cptRangeDesc}}
      </mat-checkbox>
    </div>
  </div>
  <div id="buttons-wrapper">
    <button (click)="apply()" id="btn-apply">Apply</button>
    <button (click)="cancel()" id="btn-cancel">Cancel</button>
  </div>
</div>

