<!--suppress HtmlUnknownTarget -->
<div class="disclaimer-content">
  <div class="disclaimer-wrapper">
    <p id="disclaimer-title">License for Use of Current Procedural Terminology, Fourth Edition (&quot;CPT®&quot;)</p>
    <p class="disclaimer-content-text">
      Please read the license agreement text below and then select &apos;Accept&apos; at the bottom of the page to
      indicate your acceptance of the license agreement. Users are required to accept this license agreement
      prior to using the FPSC online reports.
    </p>
    <p class="end-user-point">End User Point and Click Agreement:</p>
    <p class="disclaimer-copyright">CPT® only © {{copyRightYear}} American Medical Association. All rights reserved.</p>
    <p class="disclaimer-content-text">Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA,
      are not part of CPT, and the AMA is not recommending their use. The AMA does not directly or indirectly practice
      medicine or dispense medical services. The AMA assumes no liability for data contained or not contained herein.
      <br/><br/>Applicable FARS/DFARS Restrictions Apply to Government Use</p>
    <p class="disclaimer-copyright">CPT® is a registered trademark of the American Medical Association</p>
    <div class="disclaimer-accept-decline">
      <button id="accept-button" mat-raised-button (click)="accept()">Accept</button>
      <button id="decline-button" mat-button (click)="decline()">Decline</button>
    </div>
  </div>
</div>

