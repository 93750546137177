import {Inject, Injectable, InjectionToken} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {FilterCriteria, IAppState} from '../store/IAppState';
import {LagKeyLessThanChangedTo} from '../store/actions';
import {FilterSelectorService, FilterSelectorServiceToken} from './filter-selector.service';
import {DateRange, MemberBillingArea} from '../shared/models';
import * as _ from 'lodash';
import {distinctUntilChanged, pairwise} from 'rxjs/operators';
import {Event, Router, RoutesRecognized} from '@angular/router';
import {
  collectionsDepartmentPageRoute,
  collectionsProviderPageRoute,
  collectionsRoute,
  collectionsSnapshotRoute,
  collectionsSpecialtyPageRoute,
  payerCollectionsMultiLevelRoute,
  payerCollectionsRoute,
  summaryCollectionsRoute
} from '../shared/routes';
import {OntologyLevel} from '../shared/enums';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {formatDate} from '../shared/helpers';

export const FilterListenerServiceToken = new InjectionToken<FilterListenerService>('Filter Listener Service');

@Injectable()
export class FilterListenerService {

  currentRoute: string;
  nodePath: string;
  dateRange: DateRange;
  location: string;
  payer: string;
  lagKey: number;
  memberBillingArea: MemberBillingArea;

  constructor(
    @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
    @Inject(AnalyticsServiceToken) private analyticsService: AnalyticsService,
    private ngRedux: NgRedux<IAppState>,
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof RoutesRecognized) {
        this.currentRoute = event.url;
      }
    });

    this.filterService.getFilters()
      .pipe(distinctUntilChanged((filter1, filter2) =>
        _.isEqual({
            dateRange: filter1.dateRange,
            nodePath: filter1.nodePath,
            memberLocation: filter1.memberLocation,
            payerCategory: filter1.payerCategory,
            payerKey: filter1.payerKey,
            payerType: filter1.payerType,
            lagKey: filter1.lagKey,
            memberBillingArea: filter1.memberBillingArea,
            invoiceStatus: filter1.invoiceStatus
          },
          {
            dateRange: filter2.dateRange,
            nodePath: filter2.nodePath,
            memberLocation: filter2.memberLocation,
            payerCategory: filter2.payerCategory,
            payerKey: filter2.payerKey,
            payerType: filter2.payerType,
            lagKey: filter2.lagKey,
            memberBillingArea: filter2.memberBillingArea,
            invoiceStatus: filter2.invoiceStatus
          })))
      .pipe(pairwise())
      .subscribe(([previousFilter, filter]: [FilterCriteria, FilterCriteria]) => {
        this.updateGoogleAnalyticsData(filter);
        if (!!filter.nodePath) {
            switch (this.currentRoute) {
              case collectionsRoute:
              case collectionsSnapshotRoute:
              case summaryCollectionsRoute:
              case payerCollectionsRoute:
              case collectionsProviderPageRoute:
              case collectionsSpecialtyPageRoute:
              case collectionsDepartmentPageRoute:
              case payerCollectionsMultiLevelRoute:
                if (this.lagKey === 7) {
                  this.ngRedux.dispatch(LagKeyLessThanChangedTo(true));
                } else {
                  this.ngRedux.dispatch(LagKeyLessThanChangedTo(false));
                }
                break;
            }
          }
      });
  }
  updateGoogleAnalyticsData(filter: FilterCriteria) {
    if (filter.nodePath !== this.nodePath) {
      this.nodePath = filter.nodePath;
      const paths = this.nodePath.split('|');
      const ontologyLevel: OntologyLevel = paths.reduce((a, b) => Math.max(a, b.split('\\').length - 1), 0) - 1;
      let level: string;

      switch (ontologyLevel) {
        case OntologyLevel.AllDepartments:
          level = 'All departments';
          break;
        case OntologyLevel.Department:
          level = 'Departments';
          break;
        case OntologyLevel.Specialty:
          level = 'Specialties';
          break;
        case OntologyLevel.Provider:
          level = 'Providers';
          break;
        default:
          level = 'testing';
      }

      level = paths.length === 1 || ontologyLevel === OntologyLevel.AllDepartments ? level : `Multiple ${level.toLowerCase()}`;
      this.analyticsService.handleGoogleAnalytics('Filters', level, 'Level selected');
    }

    if (!_.isEqual(filter.dateRange, this.dateRange)) {
      this.dateRange = filter.dateRange;
      const label: string = `${formatDate(this.dateRange.startMonth, this.dateRange.startYear)}` +
        ` - ` +
        `${formatDate(this.dateRange.endMonth, this.dateRange.endYear)}`;
      this.analyticsService.handleGoogleAnalytics('Filters', 'Date Range Change', label);
    }

    if (filter.memberLocation.memberLocationName !== this.location) {
      this.location = filter.memberLocation.memberLocationName;
      this.analyticsService.handleGoogleAnalytics('Filters', this.location, 'Location selected');
    }

    if (filter.payerCategory.payerCategoryDescription !== this.payer) {
      this.payer = filter.payerCategory.payerCategoryDescription;
      this.analyticsService.handleGoogleAnalytics('Filters', this.payer, 'Payer category selected');
    }

    if (filter.lagKey !== this.lagKey) {
      this.lagKey = filter.lagKey ? filter.lagKey : 7;
      if (this.lagKey === 7) {
        this.ngRedux.dispatch(LagKeyLessThanChangedTo(true));
      } else {
        this.ngRedux.dispatch(LagKeyLessThanChangedTo(false));
      }
    }

    if (!_.isEqual(filter.memberBillingArea, this.memberBillingArea)) {
      this.memberBillingArea = filter.memberBillingArea;
      this.analyticsService.handleGoogleAnalytics('Filters', 'Member Billing Area Desc:' +
        `${this.memberBillingArea.memberBillingAreaDescription} & Code:${this.memberBillingArea.memberBillingAreaCode}`,
        'Member Billing Area selected');
    }
  }
}
