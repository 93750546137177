<div id="summary-cfp-wrapper">
  <div class="summary-procedure-summary-data-table-container">
    <app-loader *ngIf="showProgressBar"></app-loader>
    <div class="summary-procedure-summary-title">Procedure Summary - Summary Metrics </div>
    <mat-table [dataSource]="dataSource" (scroll)="handleScroll($event)">
      <ng-container *ngFor="let column of columns">
        <ng-container [matColumnDef]="column.columnDef">
          <mat-header-cell *matHeaderCellDef id="{{column.columnDef}}" class="{{column.columnDef}}-header">
            <span class="header-text">{{column.header}}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row;"><div class="data-text">{{column.dataName(row)}}</div>
          </mat-cell>
        </ng-container>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></mat-header-row>
      <ng-container><mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </ng-container>
    </mat-table>
  </div>
</div>
