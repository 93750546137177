import {DrillDown, IAppState, INITIAL_STATE} from './IAppState';
import {
  AppAction,
  isAllUserPreferenceLoaded,
  isApplicationConfigurationSettingsLoaded,
  isBatchExportReportDetailsChanged,
  isBatchExportReportsChanged,
  isBatchExportScheduleReportDetailsChanged,
  isBenchmarkChanged,
  isCfpChargesChanged,
  isCfpSortingCriteriaChanged,
  isCfpVariableViewTypeChanged,
  isCfpVarianceToggleChanged,
  isCfteAdjustedCountChanged,
  isChargeDisplayChanged,
  isChargesTerminallyDeniedChanged,
  isClinicalFingerprintMultilevelDataChanged,
  isClinicalSummaryConsolidationChanged,
  isCollectionsByPayerDenialsChanged,
  isCollectionsByPayerDenialsTopCptsByChargesChanged,
  isCollectionsByPayerDenialsTopCptsByCptsDeniedChanged,
  isCollectionsByPayerDenialsTopCptsChanged,
  isCollectionsByPayerDenialsTopReasonsByChargesChanged,
  isCollectionsByPayerDenialsTopReasonsByCptsDeniedChanged,
  isCollectionsByPayerDenialsTopReasonsChanged,
  isCollectionsChargeDisplayedChanged,
  isCollectionsChosenTabChanged,
  isCollectionsSnapshotSortingCriteriaChanged,
  isCollectionsSnapshotVarianceToggleChanged,
  isCommunityBenchmarkAlertChanged,
  isCptFamilyFilterChanged,
  isCptGroupingsChanged,
  isCptRangeFilterChanged,
  isCptViewTypeChanged,
  isCustomFlagChanged,
  isCustomGroupIdChanged,
  isCustomGroupsDataChanged,
  isDateRangeChanged,
  isDenialsChanged,
  isDenialsChargeDisplayedChanged,
  isDenialsChosenTabChanged,
  isDenialsColumnsChanged,
  isDenialsSortingCriteriaChangedTo,
  isDenialsVarianceToggleChanged,
  isDeniedCPTsPaidChanged,
  isDrillDownChanged,
  isDrillDownHistoryAppended,
  isDrillDownHistoryUndone,
  isEmDimensionChanged,
  isEmNpvBenchmarkOptionChanged,
  isEvaluationManagementChanged,
  isEvaluationManagementMultilevelChanged,
  isEvaluationManagementSummaryChanged,
  isExpectedPaymentsDisplayedChanged,
  isExportingStatusChanged,
  isFeatureToggleSettingsLoaded,
  isFilterBannerEditChanged,
  isFilterChosenByUserChanged,
  isFilterCriteriaChanged,
  isFilterNpvLocationGraphByLocationChanged,
  isFilterNpvLocationGraphByOntologyChanged,
  isFilterPSGraphByChanged,
  isFilterPSGraphByOntologyChanged,
  isInvoiceStatusChanged,
  isLagKeyChanged,
  isLagKeyLessThanChanged,
  isMemberBillingAreaLoaded,
  isMemberBillingAreaSelected,
  isMemberDataFormatLoaded,
  isMemberLocationChanged,
  isMemberLocationsChanged,
  isMemberSelected,
  isMembersLoaded,
  isMergedNewPatientVisitEntriesChanged,
  isMergedNpvByLocationDataChanged,
  isMergedProductivityTrendDataChanged,
  isMultiLevelByNodePathCollectionsColumnsChanged,
  isMultiLevelByNodePathDenialsColumnsChanged,
  isMultiLevelByNodePathPayerCollectionsColumnsChanged,
  isMultilevelCollectionsChanged,
  isMultiLevelDenialsChanged,
  isMultilevelNewPatientVisitsChanged,
  isMultilevelPayerCollectionsChanged,
  isMultilevelProviderProductivityChanged,
  isMultilevelTabChanged,
  isMultipleDepartmentsChanged,
  isMultipleProvidersChanged,
  isMultipleSpecialtiesChanged,
  isNewFeaturesLoaded,
  isNewPatientVisitSummaryChanged,
  isNewPatientVisitTrendEntriesChanged,
  isNewProviderExportsChanged,
  isNodePathChanged,
  isNPVByDepartmentColumnsChanged,
  isNPVByProviderColumnsChanged,
  isNPVBySpecialtyColumnsChanged,
  isNpvLocationDepartmentColumnsChanged,
  isNpvLocationProviderColumnsChanged,
  isNpvLocationSortingChanged,
  isNpvLocationSpecialtyColumnsChanged,
  isNpvLocationViewByNodeChanged,
  isNpvSnapshotSortingCriteriaChanged,
  isNpvVarianceToggleChanged,
  isOktaTokenChanged,
  isOntologyLoaded,
  isPatientVisitsCountsDisplayChanged,
  isPayerByLevelCollectionsDataChanged,
  isPayerByLevelCollectionsPayerColumnsChanged,
  isPayerByLevelCollectionsStaticColumnsChanged,
  isPayerCategoryChanged,
  isPayerCollectionsDataChanged,
  isPreviousPatientVisitsCountsDisplayChanged,
  isPreviousTimePeriodDisplayChanged,
  isProcedureSummaryChargeDisplayChanged,
  isProcedureSummaryDrillChanged,
  isProductivitySummaryChanged,
  isProviderSelectedFlagChanged,
  isProviderWrvuColumnsChanged,
  isRecentMonthLoaded,
  isRequestDetailLoaded,
  isRequestDetailsCleared,
  isRequestIdLoaded,
  isSelectedCptGroupChanged,
  isSelectedNodesChanged,
  isShowHierarchySearchChanged,
  isSnapshotCollectionsColumnsChanged,
  isSnapshotPayerCollectionsColumnsChanged,
  isSnapshotWrvuColumnsChanged,
  isSpecialtyPerformanceWrvuColumnsChanged,
  isSpecialtySummaryEvaluationManagementChanged,
  isSummaryCollectionsChanged,
  isTelehealthFlagChanged,
  isTerminalDenialRateChanged,
  isTrendNPVColumnsChanged,
  isTrendWrvuColumnsChanged,
  isUserMemberLoaded,
  isUserPreferencesLoaded,
  isUserProfileLoaded,
  isUserSecurityLoaded,
  isViewCommunityBenchmarksChanged,
  isViewPSGraphByNodeChanged,
  isWrvuSnapshotSortingCriteriaChanged,
  isWrvuVarianceToggleChanged,
  isWrvuViewTypeChanged,
  isZeroSuppressionChanged,
  isZipCodeNewPatientVisitsChanged
} from './actions';
import {collectionsTabEquivalentOf, multilevelTabEquivalentOf} from '../shared/helpers';
import {aDefaultChangedFilterCriteriaParameters, ChangedFilterCriteriaParameters, OntologyNode} from '../shared/models';
import * as _ from 'lodash';
import {areFiltersEqual, getChangedFilterCriteria} from '../shared/compare-helpers';
import {MetricLoadKeys} from '../shared/enums';
import {getSelectedNodeCountsFromNodePath} from '../shared/ontology-helpers';
import {shouldShowUpdatedCPTCodes} from '../evaluation-management/em-helpers';

export function rootReducer(state: IAppState, action: AppAction): IAppState {
  if (isBenchmarkChanged(action)) {
    return Object.assign({}, state, {
      benchmark: action.benchmarkPercentile
    });
  } else if (isMemberSelected(action)) {
    clearState(state, aDefaultChangedFilterCriteriaParameters());
    state.display.selectedProviders = 0;
    state.display.selectedSpecialties = 0;
    return Object.assign({}, state, {
      ontologyLoaded: false,
      filters: {
        ...state.filters,
        memberKey: action.member.memberKey,
        nodePath: ''
      },
      data: {
        ...state.data,
        selectedMemberData: action.member
      },
      display: {
        ...state.display,
        selectedProviders: 0,
        selectedSpecialties: 0
      }
    });
  } else if (isMembersLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        members: action.members
      }
    });
  } else if (isRecentMonthLoaded(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        recentMonth: action.recentMonth
      }
    });
  } else if (isNodePathChanged(action)) {
    state.display.selectedProviders = 0;
    state.display.selectedSpecialties = 0;
    if (!action.nodePath) {
      action.nodePath = '';
    }
    if (state.filters.nodePath !== action.nodePath || state.filters.nodePath === '') {
      clearState(state, aDefaultChangedFilterCriteriaParameters());
    }
    const {selectedProviders, selectedSpecialties} = getSelectedNodeCountsFromNodePath(action.nodePath);
    return Object.assign({}, state, {
      display: {
        ...state.display,
        selectedProviders,
        selectedSpecialties
      },
      filters: {
        ...state.filters,
        nodePath: action.nodePath
      }
    });
  } else if (isCptFamilyFilterChanged(action)) {
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        cptFamilyFilter: action.cptFamilyFilter
      }
    });
  } else if (isCptRangeFilterChanged(action)) {
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        cptRangeFilter: action.cptRangeFilter
      }
    });
  } else if (isOntologyLoaded(action)) {
    const ontologyHierarchy: OntologyNode[] = action.ontologyData.ontologyHierarchy;
    const initialNode = ontologyHierarchy && ontologyHierarchy.length > 0 ?
      ontologyHierarchy[0] : undefined;
    if (state.filters.nodePath === '') {
      if (initialNode) {
        const {selectedProviders, selectedSpecialties} = getSelectedNodeCountsFromNodePath(initialNode.nodePath);
        return Object.assign({}, state, {
          ontologyLoaded: ontologyHierarchy && ontologyHierarchy.length > 0,
          display: {
            ...state.display,
            selectedProviders,
            selectedSpecialties
          },
          filters: {
            ...state.filters,
            nodePath: initialNode && initialNode.nodePath || ''
          }
        });
      }
      // @ts-ignore
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          nodePath: ''
        },
        display: {
          ...state.display,
          selectedProviders: 0,
          selectedSpecialties: 0
        },
        ontologyLoaded: ontologyHierarchy && ontologyHierarchy.length > 0
      });
    }
    return Object.assign({}, state, {
      ontologyLoaded: ontologyHierarchy && ontologyHierarchy.length > 0
    });
  } else if (isCptGroupingsChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        cptGroupings: action.cptGroups
      }
    });
  } else if (isSelectedNodesChanged(action) && (!_.isEqual(state.display.selectedProviders, action.selectedProviders) ||
    !_.isEqual(state.display.selectedSpecialties, action.selectedSpecialties))) {
    return Object.assign({}, state, {
      display: {
        ...state.display, selectedProviders: action.selectedProviders, selectedSpecialties: action.selectedSpecialties
      }
    });
  } else if (isDateRangeChanged(action)) {
    if (!_.isEqual(state.filters.dateRange, action.dateRange)) {
      clearState(state, aDefaultChangedFilterCriteriaParameters());
    }
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        dateRange: action.dateRange
      },
      display: {
        ...state.display,
        showUpdatedCptCodes: shouldShowUpdatedCPTCodes(action.dateRange)
      }
    });
  } else if (isMultilevelProviderProductivityChanged(action) &&
    !_.isEqual(state.data.providerProductivityMultilevelData, action.providerProductivityMultilevelData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        providerProductivityMultilevelData: action.providerProductivityMultilevelData
      }
    });
  } else if (isMultilevelNewPatientVisitsChanged(action) &&
    !_.isEqual(state.data.newPatientVisitMultilevelData, action.multilevelNewPatientVisitsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        newPatientVisitMultilevelData: action.multilevelNewPatientVisitsData
      }
    });
  } else if (isMergedNpvByLocationDataChanged(action) &&
    !_.isEqual(state.data.mergedNpvLocationAggregatedByNode, action.mergedNpvLocationAggregatedByNode)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        mergedNpvLocationAggregatedByNode: action.mergedNpvLocationAggregatedByNode
      }
    });
  } else if (isMultilevelCollectionsChanged(action) &&
    !_.isEqual(state.data.matchedCollectionsByMultiLevelByNodePathData, action.multilevelCollectionsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        matchedCollectionsByMultiLevelByNodePathData: action.multilevelCollectionsData
      }
    });
  } else if (isMultilevelPayerCollectionsChanged(action) &&
    !_.isEqual(state.data.matchedCollectionsByPayerByMultiLevelByNodePathData, action.multilevelPayerCollectionsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        matchedCollectionsByPayerByMultiLevelByNodePathData: action.multilevelPayerCollectionsData
      }
    });
  } else if (isCollectionsByPayerDenialsChanged(action) &&
    !_.isEqual(state.data.denialsData, action.denialsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsData: action.denialsData
      }
    });
  } else if (isCollectionsByPayerDenialsTopReasonsChanged(action) &&
    !_.isEqual(state.data.denialsTopReasonsData, action.denialsTopReasonsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsTopReasonsData: action.denialsTopReasonsData
      }
    });
  } else if (isCollectionsByPayerDenialsTopReasonsByCptsDeniedChanged(action) &&
    !_.isEqual(state.data.denialsTopReasonsByCptsDeniedData, action.denialsTopReasonsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsTopReasonsByCptsDeniedData: action.denialsTopReasonsData
      }
    });
  } else if (isCollectionsByPayerDenialsTopReasonsByChargesChanged(action) &&
    !_.isEqual(state.data.denialsTopReasonsByChargesData, action.denialsTopReasonsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsTopReasonsByChargesData: action.denialsTopReasonsData
      }
    });
  } else if (isCollectionsByPayerDenialsTopCptsChanged(action) &&
    !_.isEqual(state.data.denialsTopCptsData, action.denialsTopCptsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsTopCptsData: action.denialsTopCptsData
      }
    });
  } else if (isCollectionsByPayerDenialsTopCptsByCptsDeniedChanged(action) &&
    !_.isEqual(state.data.denialsTopCptsByCptsDeniedData, action.denialsTopCptsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsTopCptsByCptsDeniedData: action.denialsTopCptsData
      }
    });
  } else if (isCollectionsByPayerDenialsTopCptsByChargesChanged(action) &&
    !_.isEqual(state.data.denialsTopCptsByChargesData, action.denialsTopCptsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsTopCptsByChargesData: action.denialsTopCptsData
      }
    });
  } else if (isMergedProductivityTrendDataChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        mergedProductivityTrend: action.mergedProductivityTrend.slice()
      }
    });
  } else if (isOktaTokenChanged(action)) {
    return Object.assign({}, state, {
      oktaToken: action.token
    });
  } else if (isChargeDisplayChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        charges: action.displayChargeGraph
      }
    });
  } else if (isProcedureSummaryChargeDisplayChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        procedureSummaryVariables: {
          ...state.display.procedureSummaryVariables,
          charges: action.procedureSummaryChargeDisplayed
        }
      }
    });
  } else if (isViewPSGraphByNodeChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        procedureSummaryVariables: {
          ...state.display.procedureSummaryVariables,
          viewByNode: action.viewByNode
        }
      }
    });
  } else if (isFilterPSGraphByChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        procedureSummaryVariables: {
          ...state.display.procedureSummaryVariables,
          currentSelectedCptNode: action.currentSelectedOntologyCptNode
        }
      }
    });
  } else if (isFilterPSGraphByOntologyChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        procedureSummaryVariables: {
          ...state.display.procedureSummaryVariables,
          currentSelectedOntologyCptNode: action.currentSelectedCptNode
        }
      }
    });
  } else if (isPreviousTimePeriodDisplayChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        previousTimePeriod: action.displayPreviousTimePeriodGraph
      }
    });
  } else if (isWrvuViewTypeChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        wrvuViewType: action.wrvuViewType
      }
    });
  } else if (isCfpVariableViewTypeChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        cfpVariableViewType: action.cfpVariableViewType
      }
    });
  } else if (isPatientVisitsCountsDisplayChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        patientVisitCounts: action.displayPatientVisitsCountsGraph
      }
    });
  } else if (isPreviousPatientVisitsCountsDisplayChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        previousPatientVisitCounts: action.displayPreviousPatientVisitsCountsGraph
      }
    });
  } else if (isCustomFlagChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        isCustom: action.isCustom
      }
    });
  } else if (isProviderSelectedFlagChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        isProviderSelected: action.isProviderSelected
      }
    });
  } else if (isEvaluationManagementChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        evaluationManagementData: action.providerEvaluationManagements
      }
    });
  } else if (isSpecialtySummaryEvaluationManagementChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        specialtySummaryEvaluationManagementData: action.specialtyEvaluationManagementSummary
      }
    });
  } else if (isEvaluationManagementSummaryChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        summaryEvaluationManagementData: action.evaluationManagementSummary
      }
    });
  } else if (isEvaluationManagementMultilevelChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        evaluationManagementMultilevelData: action.providerEvaluationManagementMultilevel
      }
    });
  } else if (isNewPatientVisitSummaryChanged(action) &&
    !_.isEqual(state.data.summaryNewPatientVisitData, action.summaryNewPatientVisitData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        summaryNewPatientVisitData: action.summaryNewPatientVisitData
      }
    });
  } else if (isProductivitySummaryChanged(action) &&
    !_.isEqual(state.data.productivitySummaryData, action.productivitySummaryData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        productivitySummaryData: action.productivitySummaryData
      }
    });
  } else if (isMergedNewPatientVisitEntriesChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        mergedNewPatientVisitSnapshotData: action.mergedNewPatientVisitSnapshotData.slice()
      }
    });
  } else if (isNewPatientVisitTrendEntriesChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        newPatientVisitTrendData: action.newPatientVisitTrendData
      }
    });
  } else if (isUserProfileLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        userProfile: action.userProfile
      }
    });
  } else if (isFeatureToggleSettingsLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        featureToggleSettings: action.featureToggleSettings
      }
    });
  } else if (isLagKeyChanged(action)) {
    const changedFilterCriteriaParams = aDefaultChangedFilterCriteriaParameters();
    changedFilterCriteriaParams.isCollectionOnly = true;
    clearState(state, changedFilterCriteriaParams);
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        lagKey: action.lagKey
      }
    });
  } else if (isInvoiceStatusChanged(action)) {
    const changedFilterCriteriaParams = aDefaultChangedFilterCriteriaParameters();
    changedFilterCriteriaParams.isCollectionOnly = true;
    clearState(state, changedFilterCriteriaParams);
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        invoiceStatus: action.invoiceStatus
      }
    });
  } else if (isTelehealthFlagChanged(action)) {
    const changedFilterCriteriaParams = aDefaultChangedFilterCriteriaParameters();
    changedFilterCriteriaParams.isNewPatientVisitOnly = true;
    changedFilterCriteriaParams.isEvaluationManagementOnly = true;
    clearState(state, changedFilterCriteriaParams);
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        telehealthFlag: action.telehealthFlag
      }
    });
  } else if (isMemberLocationChanged(action) && (!_.isEqual(state.filters.memberLocation.memberLocationKey,
    action.memberLocation.memberLocationKey))) {
    clearState(state, aDefaultChangedFilterCriteriaParameters());
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        memberLocation: action.memberLocation
      }
    });
  } else if (isPayerCategoryChanged(action) && (!_.isEqual(state.filters.payerCategory, action.payerCategory))) {
    const changedFilterCriteriaParams = aDefaultChangedFilterCriteriaParameters();
    changedFilterCriteriaParams.isCollectionOnly = true;
    changedFilterCriteriaParams.isNewPatientVisitOnly = true;
    clearState(state, changedFilterCriteriaParams);
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        payerCategory: action.payerCategory
      }
    });
  } else if (isZipCodeNewPatientVisitsChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        zipCodeNewPatientVisits: action.zipCodeNewPatientVisits
      }
    });
  } else if (isApplicationConfigurationSettingsLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        applicationConfigurationSettings: action.applicationConfigurationSettings
      }
    });
  } else if (isMultipleProvidersChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        multipleProviders: action.multipleProviders
      }
    });
  } else if (isMultipleSpecialtiesChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        multipleSpecialties: action.multipleSpecialties
      }
    });
  } else if (isMultipleDepartmentsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        multipleDepartments: action.multipleDepartments
      }
    });
  } else if (isShowHierarchySearchChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        showHierarchySearch: action.showHierarchySearch
      }
    });
  } else if (isZeroSuppressionChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        zeroSuppression: action.zeroSuppression
      }
    });
  } else if (isFilterBannerEditChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        filterBannerEdit: action.filterBannerEdit
      }
    });
  } else if (isFilterChosenByUserChanged(action)) {
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        chosenByUser: action.filterChosenByUser
      }
    });
  } else if (isNewProviderExportsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newProviderExports: action.newProviderExports
      }
    });
  } else if (isBatchExportReportsChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        batchExportReports: action.batchExportReports
      }
    });
  } else if (isNpvVarianceToggleChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        varianceToggle: {
          ...state.display.varianceToggle,
          npvVariance: action.npvVarianceToggle
        }
      }
    });
  } else if (isWrvuVarianceToggleChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        varianceToggle: {
          ...state.display.varianceToggle,
          wrvuVariance: action.wrvuVarianceToggle
        }
      }
    });
  } else if (isCollectionsSnapshotVarianceToggleChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        varianceToggle: {
          ...state.display.varianceToggle,
          collectionSnapshotVariance: action.collectionsSnapshotVarianceToggle
        }
      }
    });
  } else if (isCfpVarianceToggleChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        varianceToggle: {
          ...state.display.varianceToggle,
          cfpVariance: action.cfpVarianceToggle
        }
      }
    });
  } else if (isWrvuSnapshotSortingCriteriaChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        sortingCriteria: {
          ...state.display.sortingCriteria,
          wrvuSnapshotSortingCriteria: action.wrvuSnapshotSortingCriteria
        }
      }
    });
  } else if (isNpvSnapshotSortingCriteriaChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        sortingCriteria: {
          ...state.display.sortingCriteria,
          npvSnapshotSortingCriteria: action.npvSnapshotSortingCriteria
        }
      }
    });
  } else if (isCfpSortingCriteriaChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        sortingCriteria: {
          ...state.display.sortingCriteria,
          cfpSortingCriteria: action.cfpSortingCriteria
        }
      }
    });
  } else if (isCollectionsSnapshotSortingCriteriaChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        sortingCriteria: {
          ...state.display.sortingCriteria,
          collectionsSnapshotSortingCriteria: action.collectionsSnapshotSortingCriteria
        }
      }
    });
  } else if (isExpectedPaymentsDisplayedChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        expectedPaymentsDisplayed: action.expectedPaymentsDisplayed
      }
    });
  } else if (isCollectionsChargeDisplayedChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        collectionsChargeDisplayed: action.collectionsChargeDisplayed
      }
    });
  } else if (isChargesTerminallyDeniedChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        chargesTerminallyDeniedDisplayed: action.chargesTerminallyDeniedDisplayed
      }
    });
  } else if (isDenialsChargeDisplayedChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        denialsChargesDisplayed: action.denialsChargesDisplayed
      }
    });
  } else if (isDeniedCPTsPaidChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        deniedCPTsPaidDisplayed: action.deniedCPTsPaidDisplayed
      }
    });
  } else if (isTerminalDenialRateChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        terminalDenialRateDisplayed: action.terminalDenialRateDisplayed
      }
    });
  } else if (isNewFeaturesLoaded(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newFeatures: action.newFeatures
      }
    });
  } else if (isUserPreferencesLoaded(action)) {
    return Object.assign({}, state, {
      userPreferences: action.userPreferences
    });
  } else if (isClinicalSummaryConsolidationChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        clinicalSummaryConsolidationData: {
          ...state.data.clinicalSummaryConsolidationData,
          cptClinicalSummaries: action.cptClinicalSummaries.slice(),
          familyClinicalSummaries: action.familyClinicalSummaries.slice(),
          rangeClinicalSummaries: action.rangeClinicalSummaries.slice()
        }
      }
    });
  } else if (isClinicalFingerprintMultilevelDataChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        clinicalFingerprintMultilevelData: action.clinicalFingerprintMultilevelData
      }
    });
  } else if (isPayerCollectionsDataChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        payerCollectionsData: {
          ...state.data.payerCollectionsData,
          payerMatchedCollections: action.payerCollections.slice()
        }
      }
    });
  } else if (isPayerByLevelCollectionsDataChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        payerByLevelCollectionsData: action.payerByLevelCollectionsData
      }
    });
  } else if (isSummaryCollectionsChanged(action) &&
    !_.isEqual(state.data.summaryCollectionsData, action.summaryCollectionsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        summaryCollectionsData: action.summaryCollectionsData
      }
    });
  } else if (isCustomGroupsDataChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        customGroupsData: action.customGroupsData
      }
    });
  } else if (isCustomGroupIdChanged(action)) {
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        customGroupId: action.customGroupId
      }
    });
  } else if (isRequestIdLoaded(action)) {
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        externalRequestId: action.requestId
      }
    });
  } else if (isRequestDetailLoaded(action)) {
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        externalRequestDetail: action.requestDetail
      }
    });
  } else if (isRequestDetailsCleared(action)) {
    const filtersWithoutRequestData = Object.assign({}, state.filters);
    delete filtersWithoutRequestData['externalRequestId'];
    delete filtersWithoutRequestData['externalRequestDetail'];
    return Object.assign({}, state, {
      filters: filtersWithoutRequestData
    });
  } else if (isCptViewTypeChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        cptViewType: action.cptViewType
      }
    });
  } else if (isViewCommunityBenchmarksChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        viewCommunityBenchmarks: action.viewCommunityBenchmarks
      }
    });
  } else if (isMemberDataFormatLoaded(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        isRevenueMember: action.isRevenueMember
      }
    });
  } else if (isEmDimensionChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        emDimension: action.emDimension
      }
    });
  } else if (isNpvLocationViewByNodeChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newPatientVisitsLocationVariables: {
          ...state.display.newPatientVisitsLocationVariables,
          viewByNode: action.npvLocationViewByNode
        }
      }
    });
  } else if (isFilterNpvLocationGraphByOntologyChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newPatientVisitsLocationVariables: {
          ...state.display.newPatientVisitsLocationVariables,
          currentSelectedOntologyNode: action.currentSelectedNode
        }
      }
    });
  } else if (isFilterNpvLocationGraphByLocationChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newPatientVisitsLocationVariables: {
          ...state.display.newPatientVisitsLocationVariables,
          currentSelectedLocationNode: action.currentSelectedNode
        }
      }
    });
  } else if (isNpvLocationSortingChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newPatientVisitsLocationVariables: {
          ...state.display.newPatientVisitsLocationVariables,
          columnForSortingTheData: action.columnWithMatSort
        }
      }
    });
  } else if (isNpvLocationDepartmentColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newPatientVisitsLocationVariables: {
          ...state.display.newPatientVisitsLocationVariables,
          displayedDepartmentColumns: action.displayedDepartmentColumns
        }
      }
    });
  } else if (isNpvLocationSpecialtyColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newPatientVisitsLocationVariables: {
          ...state.display.newPatientVisitsLocationVariables,
          displayedSpecialtyColumns: action.displayedSpecialtyColumns
        }
      }
    });
  } else if (isNpvLocationProviderColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        newPatientVisitsLocationVariables: {
          ...state.display.newPatientVisitsLocationVariables,
          displayedProviderColumns: action.displayedProviderColumns
        }
      }
    });
  } else if (isEmNpvBenchmarkOptionChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        emNpvBenchmarkOption: action.emNpvBenchmarkOption
      }
    });
  } else if (isProcedureSummaryDrillChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display, procedureSummaryDrill: action.procedureSummaryDrill
      }
    });
  } else if (isSelectedCptGroupChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display, selectedCptGroup: action.selectedCptGroup
      }
    });
  } else if (isSnapshotCollectionsColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedCollectionsColumns: action.displayedCollectionsColumns.slice()
      }
    });
  } else if (isPayerByLevelCollectionsPayerColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        payerByLevelCollectionsPayerColumns: action.payerByLevelCollectionsPayerColumns.slice()
      }
    });
  } else if (isPayerByLevelCollectionsStaticColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        payerByLevelCollectionsStaticColumns: action.payerByLevelCollectionsStaticColumns.slice()
      }
    });
  } else if (isMultiLevelByNodePathPayerCollectionsColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        multiLevelByNodePathPayerCollectionsColumns: action.multiLevelByNodePathPayerCollectionsColumns.slice()
      }
    });
  } else if (isMultiLevelByNodePathCollectionsColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        multiLevelByNodePathCollectionsColumns: action.multiLevelByNodePathCollectionsColumns
      }
    });
  } else if (isSnapshotPayerCollectionsColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedPayerCollectionsColumns: action.displayedPayerCollectionsColumns.slice()
      }
    });
  } else if (isSnapshotWrvuColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedWRVUSnapshotColumns: action.displayedSnapshotWrvuColumns.slice()
      }
    });
  } else if (isProviderWrvuColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedWRVUProviderColumns: action.displayedProviderWrvuColumns.slice()
      }
    });
  } else if (isSpecialtyPerformanceWrvuColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedWRVUSpecialtyPerformanceColumns: action.displayedSpecialtyPerformanceWrvuColumns.slice()
      }
    });
  } else if (isUserSecurityLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        userSecurityData: action.userSecurity
      }
    });
  } else if (isNPVByDepartmentColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedNPVDepartmentColumns: action.displayedNPVByDepartmentColumns.slice()
      }
    });
  } else if (isNPVBySpecialtyColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedNPVSpecialtyColumns: action.displayedNPVBySpecialtyColumns.slice()
      }
    });
  } else if (isMultiLevelByNodePathDenialsColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedMultiLevelDenialsColumns: action.multiLevelByNodePathDenialsColumns.slice()
      }
    });
  } else if (isNPVByProviderColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedNPVProviderColumns: action.displayedNPVByProviderColumns.slice()
      }
    });
  } else if (isTrendNPVColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedNPVTrendColumns: action.displayedNPVTrendColumns.slice()
      }
    });
  } else if (isTrendWrvuColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedWRVUTrendColumns: action.displayedTrendWrvuColumns.slice()
      }
    });
  } else if (isAllUserPreferenceLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        userPreferenceData: action.userPreferences
      }
    });
  } else if (isUserMemberLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        userMemberData: action.userMemberData
      }
    });
  } else if (isMemberBillingAreaSelected(action) &&
    (!_.isEqual(state.filters.memberBillingArea, action.memberBillingArea))) {
    const changedFilterCriteriaParams = aDefaultChangedFilterCriteriaParameters();
    changedFilterCriteriaParams.isCollectionOnly = true;
    clearState(state, changedFilterCriteriaParams);
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        memberBillingArea: action.memberBillingArea
      }
    });
  } else if (isMemberBillingAreaLoaded(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        memberBillingAreaData: action.memberBillingAreas
      }
    });
  } else if (isCommunityBenchmarkAlertChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        communityBenchmarkAlert: action.communityBenchmarkAlert
      }
    });
  } else if (isFilterCriteriaChanged(action)) {
    action.filterCriteria.nodePath = action.filterCriteria.nodePath || '';
    if ((state.filters.nodePath === '') || !areFiltersEqual(state.filters, action.filterCriteria, false, false)) {
      const changedFilterCriteria = getChangedFilterCriteria(state.filters, action.filterCriteria);
      clearState(state, changedFilterCriteria);
    }
    const {selectedProviders, selectedSpecialties} = getSelectedNodeCountsFromNodePath(action.filterCriteria.nodePath);
    return Object.assign({}, state, {
      display: {
        ...state.display,
        selectedProviders,
        selectedSpecialties,
        showUpdatedCptCodes: shouldShowUpdatedCPTCodes(action.filterCriteria.dateRange)
      },
      filters: action.filterCriteria
    });
  } else if (isMemberLocationsChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        memberLocations: action.memberLocations
      }
    });
  } else if (isExportingStatusChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        isExporting: action.isExporting
      }
    });
  } else if (isLagKeyLessThanChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        isLessThanLagPeriod: action.isLessThanLagPeriod
      }
    });
  } else if (isDrillDownChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        drillDown: {
          isDrillDown: action.isDrillDown,
          nodePath: action.nodePath,
          tab: action.tab
        }
      }
    });
  } else if (isDrillDownHistoryAppended(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        drillDownHistory: [
          ...state.display.drillDownHistory.slice(),
          {nodePath: action.nodePath, tab: action.tab, customGroupId: action.customGroupId}
        ]
      }
    });
  } else if (isDrillDownHistoryUndone(action)) {
    let drillDownHistory: DrillDown[] = state.display.drillDownHistory.slice();
    if (action.resetHistory) {
      drillDownHistory = [];
    } else {
      drillDownHistory.pop();
    }
    return Object.assign({}, state, {
      display: {
        ...state.display,
        drillDownHistory: drillDownHistory
      }
    });
  } else if (isCfteAdjustedCountChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        cFTEAdjustedCountDisplayed: action.cFTEAdjustedCount
      }
    });
  } else if (isCfpChargesChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        cfpChargesDisplayed: action.cFPCharges
      }
    });
  } else if (isCollectionsChosenTabChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        collectionsChosenTab: action.collectionsChosenTab,
        denialsChosenTab: action.collectionsChosenTab,
        multilevelTab: multilevelTabEquivalentOf(action.collectionsChosenTab)
      }
    });
  } else if (isMultilevelTabChanged(action)) {
    const collectionTab = collectionsTabEquivalentOf(action.multilevelTab);
    return Object.assign({}, state, {
      display: {
        ...state.display,
        multilevelTab: action.multilevelTab,
        collectionsChosenTab: collectionTab,
        denialsChosenTab: collectionTab
      }
    });
  } else if (isBatchExportReportDetailsChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        batchExportReportDetails: action.batchExportReportDetails
      }
    });
  } else if (isBatchExportScheduleReportDetailsChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        batchExportScheduleReportDetails: action.batchExportScheduleReportDetails
      }
    });
  } else if (isBatchExportReportDetailsChanged(action)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        batchExportReportDetails: action.batchExportReportDetails
      }
    });
  } else if (isDenialsSortingCriteriaChangedTo(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        sortingCriteria: {
          ...state.display.sortingCriteria,
          denialsSortingCriteria: action.denialsSortingCriteria
        }
      }
    });
  } else if (isDenialsChanged(action) &&
    !_.isEqual(state.data.denials, action.denialsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denials: action.denialsData
      }
    });
  } else if (isMultiLevelDenialsChanged(action) &&
    !_.isEqual(state.data.denialsByMultiLevelData, action.multiLevelDenialsData)) {
    return Object.assign({}, state, {
      data: {
        ...state.data,
        denialsByMultiLevelData: action.multiLevelDenialsData
      }
    });
  } else if (isDenialsColumnsChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        displayedDenialsColumns: action.displayedDenialsColumns.slice()
      }
    });
  } else if (isDenialsVarianceToggleChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        varianceToggle: {
          ...state.display.varianceToggle,
          denialsVariance: action.denialsVarianceToggle
        }
      }
    });
  } else if (isDenialsChosenTabChanged(action)) {
    return Object.assign({}, state, {
      display: {
        ...state.display,
        denialsChosenTab: action.denialsChosenTab,
        collectionsChosenTab: action.denialsChosenTab,
        multilevelTab: multilevelTabEquivalentOf(action.denialsChosenTab)
      }
    });
  } else {
    return state;
  }

  function clearState(existingState: IAppState, changedFilterCriteria: ChangedFilterCriteriaParameters) {
    if (changedFilterCriteria.isCollectionOnly) {
      existingState.data.payerCollectionsData.payerMatchedCollections
        = INITIAL_STATE.data.payerCollectionsData.payerMatchedCollections;
      existingState.data.summaryCollectionsData = INITIAL_STATE.data.summaryCollectionsData;
      existingState.data.matchedCollectionsByMultiLevelByNodePathData =
        INITIAL_STATE.data.matchedCollectionsByMultiLevelByNodePathData;
      existingState.data.matchedCollectionsByPayerByMultiLevelByNodePathData =
        INITIAL_STATE.data.matchedCollectionsByPayerByMultiLevelByNodePathData;
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_SUMMARY_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_PAYER_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_SNAPSHOT_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_PAYERBYLEVEL_LOADED);
    }
    if (changedFilterCriteria.isDenialsOnly) {
      existingState.data.denials = INITIAL_STATE.data.denials;
      existingState.data.denialsByMultiLevelData = INITIAL_STATE.data.denialsByMultiLevelData;
      localStorage.removeItem(MetricLoadKeys.IS_DENIALS_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_DENIALS_PAYER_LOADED);
    }
    if (changedFilterCriteria.isEvaluationManagementOnly) {
      existingState.data.evaluationManagementMultilevelData = INITIAL_STATE.data.evaluationManagementMultilevelData;
      existingState.data.summaryEvaluationManagementData = INITIAL_STATE.data.summaryEvaluationManagementData;
      localStorage.removeItem(MetricLoadKeys.IS_EM_SNAPSHOT_LOADED);
    }
    if (changedFilterCriteria.isNewPatientVisitOnly) {
      existingState.data.summaryNewPatientVisitData = INITIAL_STATE.data.summaryNewPatientVisitData;
      existingState.data.newPatientVisitTrendData = INITIAL_STATE.data.newPatientVisitTrendData;
      existingState.data.newPatientVisitMultilevelData = INITIAL_STATE.data.newPatientVisitMultilevelData;
      existingState.data.mergedNpvLocationAggregatedByNode = INITIAL_STATE.data.mergedNpvLocationAggregatedByNode;
      existingState.data.zipCodeNewPatientVisits = INITIAL_STATE.data.zipCodeNewPatientVisits;
      localStorage.removeItem(MetricLoadKeys.IS_NPV_ZIP_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_TREND_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_SUMMARY_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_SNAPSHOT_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_LOCATION_LOADED);
    }
    if (!changedFilterCriteria.isCollectionOnly &&
      !changedFilterCriteria.isNewPatientVisitOnly && !changedFilterCriteria.isDenialsOnly &&
      !changedFilterCriteria.isEvaluationManagementOnly) {
      existingState.data.denials = INITIAL_STATE.data.denials;
      existingState.data.denialsByMultiLevelData = INITIAL_STATE.data.denialsByMultiLevelData;
      existingState.data.providerProductivityMultilevelData = INITIAL_STATE.data.providerProductivityMultilevelData;
      existingState.data.productivitySummaryData = INITIAL_STATE.data.productivitySummaryData;
      existingState.data.mergedProductivityTrend = INITIAL_STATE.data.mergedProductivityTrend;
      existingState.data.summaryNewPatientVisitData = INITIAL_STATE.data.summaryNewPatientVisitData;
      existingState.data.evaluationManagementData = INITIAL_STATE.data.evaluationManagementData;
      existingState.data.specialtySummaryEvaluationManagementData = INITIAL_STATE.data.specialtySummaryEvaluationManagementData;
      existingState.data.summaryEvaluationManagementData = INITIAL_STATE.data.summaryEvaluationManagementData;
      existingState.data.newPatientVisitTrendData = INITIAL_STATE.data.newPatientVisitTrendData;
      existingState.data.newPatientVisitMultilevelData = INITIAL_STATE.data.newPatientVisitMultilevelData;
      existingState.data.mergedNpvLocationAggregatedByNode = INITIAL_STATE.data.mergedNpvLocationAggregatedByNode;
      existingState.data.zipCodeNewPatientVisits = INITIAL_STATE.data.zipCodeNewPatientVisits;
      existingState.data.clinicalFingerprintMultilevelData = INITIAL_STATE.data.clinicalFingerprintMultilevelData;
      existingState.data.clinicalSummaryConsolidationData = INITIAL_STATE.data.clinicalSummaryConsolidationData;
      existingState.data.payerCollectionsData.payerMatchedCollections
        = INITIAL_STATE.data.payerCollectionsData.payerMatchedCollections;
      existingState.data.matchedCollectionsByMultiLevelByNodePathData =
        INITIAL_STATE.data.matchedCollectionsByMultiLevelByNodePathData;
      existingState.data.matchedCollectionsByPayerByMultiLevelByNodePathData =
        INITIAL_STATE.data.matchedCollectionsByPayerByMultiLevelByNodePathData;
      existingState.data.summaryCollectionsData = INITIAL_STATE.data.summaryCollectionsData;
      existingState.data.evaluationManagementMultilevelData = INITIAL_STATE.data.evaluationManagementMultilevelData;
      existingState.display.communityBenchmarkAlert = false;
      localStorage.removeItem(MetricLoadKeys.IS_NPV_ZIP_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_TREND_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_SUMMARY_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_SNAPSHOT_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_NPV_LOCATION_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_WRVU_SUMMARY_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_WRVU_SNAPSHOT_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_WRVU_TREND_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_EM_SNAPSHOT_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_CFP_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_CFP_MULTILEVEL_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_SUMMARY_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_PAYER_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_SNAPSHOT_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_COLLECTION_PAYERBYLEVEL_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_DENIALS_LOADED);
      localStorage.removeItem(MetricLoadKeys.IS_DENIALS_PAYER_LOADED);
    }
  }
}
