import {Component, Input, OnChanges} from '@angular/core';
import {GmapMarker, ZipCodeNewPatientVisits} from '../../../shared/models';
import * as _ from 'lodash';

@Component({
  selector: 'app-zip-code-npv-batch-export',
  templateUrl: './zip-code-npv-batch-export.component.html',
  styleUrls: ['./zip-code-npv-batch-export.component.scss']
})
export class ZipCodeNpvBatchExportComponent implements OnChanges {

  @Input() zipCodeNewPatientVisits: ZipCodeNewPatientVisits[] = [];
  gmapMarkerData: GmapMarker[] = [];
  level: string;
  tableTitle = 'Top New Patient Visit Zip Codes';
  zipCodeColumns: any[] = [
    {
      columnDef: 'id', header: '#',
      dataName: (row: any) => `${(row != null && row.id != null) ? row.id.toString() : ''}`
    },
    {
      columnDef: 'zipCode', header: 'Zip Code',
      dataName: (row: any) => `${(row != null && row.zipCode != null) ? row.zipCode.toString() : ''}`
    },
    {
      columnDef: 'newPatientVisitPercentage', header: '% New Patient Visits',
      dataName: (row: any) => `${(row != null && row.newPatientVisitPercentage != null)
        ? row.newPatientVisitPercentage.toString() : ''}`
    },
    {
      columnDef: 'newPatientVisitCount', header: '# of New Patients',
      dataName: (row: any) => `${(row != null && row.newPatientVisitCount != null) ?
        row.newPatientVisitCount.toString() : ''}`
    },
    {
      columnDef: 'totalPatientVisitCount', header: '# of Total Patients',
      dataName: (row: any) => `${(row != null && row.totalPatientVisitCount != null) ?
        row.totalPatientVisitCount.toString() : ''}`
    }
  ];

  constructor() { }

  ngOnChanges(): void {
    this.zipCodeNewPatientVisits = _.take(this.zipCodeNewPatientVisits, 10);
    this.gmapMarkerData = [];
    this.level = '';
    let index = 1;
    this.zipCodeNewPatientVisits.forEach(x => {
      this.gmapMarkerData.push({
        id: index ++,
        lat: +x.lat,
        lng: +x.lng,
        zipCode: x.zipCode,
        newPatientVisitCount: x.newPatientVisitCount,
        newPatientVisitPercentage: x.newPatientVisitPercentage + '%',
        totalPatientVisitCount: x.totalPatientVisitCount
      });
    });
  }
}
