import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FeatureToggleSetting} from '../../shared/models';
import {NgRedux, select} from '@angular-redux/store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {IAppState} from '../../store/IAppState';
import {FeatureToggleServiceToken, HttpFeatureToggleService} from '../../services/feature-toggle.service';
import {
  ApplicationConfigurationServiceToken,
  HttpApplicationConfigurationService
} from '../../services/app-configuration.service';
import {MatDialog} from '@angular/material/dialog';
import {skipWhile} from 'rxjs/operators';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {featureToggleSettingsLoadedTo} from '../../store/actions';
import {columnsForFeatureControlPanel, newFeatureToggleOptions, showSuccessDialog} from '../control-panel-helper';

@Component({
  selector: 'app-feature-toggle-control-panel',
  templateUrl: './feature-toggle-control-panel.component.html',
  styleUrls: ['./feature-toggle-control-panel.component.scss']
})
export class FeatureToggleControlPanelComponent implements OnInit, OnDestroy {
  newFeatureToggleOptions = newFeatureToggleOptions;

  columnDefs: string[] = columnsForFeatureControlPanel.map(c => c.columnDef);
  columns = columnsForFeatureControlPanel;

  featureDataSource: MatTableDataSource<FeatureToggleSetting> =
    new MatTableDataSource<FeatureToggleSetting>([]);

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  featureFormGroup: UntypedFormGroup;

  isRowUpdate = false;
  selectedRowId: number | undefined;

  addNewFeatureSubscription: Subscription;
  featureUpdateSubscription: Subscription;

  subscription: Subscription;
  @ViewChild('formDirective') private formDirective: NgForm;

  constructor(
    private readonly ngRedux: NgRedux<IAppState>,
    @Inject(FeatureToggleServiceToken)
    private readonly featureToggleService: HttpFeatureToggleService,
    @Inject(ApplicationConfigurationServiceToken)
    private readonly appConfigService: HttpApplicationConfigurationService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog
  ) {
    this.initializeFormGroup();
  }


  ngOnInit(): void {
    this.subscription = combineLatest([this.featureToggleSettings$]
    ).pipe(skipWhile(([featureSettings]: [FeatureToggleSetting[]]) =>
      !featureSettings?.length))
      .subscribe(([featureSettings]) => {
        this.featureDataSource.data = featureSettings;
        this.initializeFormGroup();
      });
  }

  private initializeFormGroup() {
    this.featureFormGroup = this.fb.group(
      {
        featureName: [null, [Validators.required, this.forbiddenFeatureToggleNameValidator()]],
        enabled: false,
        enabledInternal: false
      }, {updateOn: 'change'}
    );
  }

  ngOnDestroy() {
    this.addNewFeatureSubscription?.unsubscribe();
    this.featureUpdateSubscription?.unsubscribe();
  }

  onInternalToggleChange($event: MatSlideToggleChange, featureToggleSetting: FeatureToggleSetting) {
    const featureToggleSettingReq = {...featureToggleSetting, enabledInternal: $event.checked};
    this.updateInternalFeature(featureToggleSettingReq);
  }

  onExternalToggleChange($event: MatSlideToggleChange, featureToggleSetting: FeatureToggleSetting) {
    const featureToggleSettingReq = {...featureToggleSetting, enabled: $event.checked};
    this.updateExternalFeature(featureToggleSettingReq);
  }

  getFeatureSettingState(isEnabled: boolean): String {
    return isEnabled ? 'Enabled' : 'Disabled';
  }

  forbiddenFeatureToggleNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let foundMatch = false;
      this.featureDataSource?.data?.forEach(d => {
        if (d.featureName.trim().toLowerCase() === control.value?.trim().toLowerCase()) {
          foundMatch = true;
        }
      });
      return foundMatch ? {forbiddenFeatureToggleName: {value: control.value}} : null;
    };
  }

  onNewFeatureSubmit() {
    if (this.featureFormGroup.valid) {
      this.addNewFeature();
      showSuccessDialog('Feature Toggle', this.dialog);
    }
    this.formDirective.resetForm();
  }

  private addNewFeature() {
    this.addNewFeatureSubscription = this.featureToggleService
      .addFeature(this.featureFormGroup.value).subscribe(res => {
        this.featureDataSource.data = [...this.featureDataSource.data, res];
        this.ngRedux.dispatch(featureToggleSettingsLoadedTo(this.featureDataSource.data));
      });
  }

  isUpdatingRow(featureId: number) {
    return (this.selectedRowId === featureId && this.isRowUpdate);
  }

  private updateInternalFeature(featureToggleSetting: FeatureToggleSetting) {
    const dataSource = this.featureDataSource.data.find(ds => ds.featureId === featureToggleSetting.featureId);
    if (!dataSource) {
      return;
    }
    this.featureUpdateSubscription = this.featureToggleService.updateInternalFeature(featureToggleSetting).subscribe(res => {
      dataSource.enabledInternal = featureToggleSetting.enabledInternal;
      dataSource.enabled = featureToggleSetting.enabled;
      dataSource.featureName = featureToggleSetting.featureName;
      const matchingFeature = this.featureDataSource.data.find(x => x.featureId === res.featureId);
      if (matchingFeature) {
        matchingFeature.lastUpdatedOn = res.lastUpdatedOn;
        matchingFeature.lastUpdatedBy = res.lastUpdatedBy;
      }
      this.featureDataSource.data = [...this.featureDataSource.data];
      this.ngRedux.dispatch(featureToggleSettingsLoadedTo(this.featureDataSource.data));
      this.isRowUpdate = false;
      this.selectedRowId = undefined;
    });
  }
  private updateExternalFeature(featureToggleSetting: FeatureToggleSetting) {
    const dataSource = this.featureDataSource.data.find(ds => ds.featureId === featureToggleSetting.featureId);
    if (!dataSource) {
      return;
    }
    this.featureUpdateSubscription = this.featureToggleService.updateExternalFeature(featureToggleSetting).subscribe(res => {
      dataSource.enabledInternal = featureToggleSetting.enabledInternal;
      dataSource.enabled = featureToggleSetting.enabled;
      dataSource.featureName = featureToggleSetting.featureName;
      const matchingFeature = this.featureDataSource.data.find(x => x.featureId === res.featureId);
      if (matchingFeature) {
        matchingFeature.lastUpdatedOn = res.lastUpdatedOn;
        matchingFeature.lastUpdatedBy = res.lastUpdatedBy;
      }
      this.featureDataSource.data = [...this.featureDataSource.data];
      this.ngRedux.dispatch(featureToggleSettingsLoadedTo(this.featureDataSource.data));
      this.isRowUpdate = false;
      this.selectedRowId = undefined;
    });
  }

}
