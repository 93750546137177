import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  BatchExportDetail,
  BatchExportDetailResponse,
  BatchFilterCriteria,
  EmDimension,
  EvaluationManagementSummary,
  FeatureToggleSetting,
  ProviderMultilevelCptCounts
} from '../../shared/models';
import {FeatureToggleEntries} from '../../shared/feature-toggle-settings-enum';
import {Subscription} from 'rxjs';
import {FeatureToggleServiceToken, HttpFeatureToggleService} from '../../services/feature-toggle.service';
import {ActivatedRoute} from '@angular/router';
import {BatchExportServiceToken, HttpBatchExportService} from '../services/batch-export.service';
import {getBatchFilterFromExportDetail} from '../../shared/test/helper-functions.spec';
import {
  ConsultationCodes,
  CptCategoryViewType,
  CptRangeViewType,
  EmergencyMedicineCodes,
  EmRangeAndColumns,
  EstablishedPatientVisitCodes,
  EyeExamEstablishedCodes,
  EyeExamNewCodes,
  getCorrespondingEmTitle,
  getEmSummaryColumnsByDimension,
  getEmTrendColumnsWithExtendedBenchmarkOptions,
  getEvaluationManagementViewTypeText,
  HospitalDischargeCodes,
  InitialHospitalCareCodes,
  NewPatientVisitCodes,
  SubsequentHospitalCareCodes
} from '../../evaluation-management/em-helpers';
import {DataTableColumns} from '../../shared/data-table-columns';
import {BenchmarkOption} from '../../shared/enums';
import {addClassesToColumns} from '../batch-export-helper';

@Component({
  selector: 'app-trend-em-batch-export',
  templateUrl: './trend-em-batch-export.component.html',
  styleUrls: ['./trend-em-batch-export.component.scss']
})
export class TrendEmBatchExportComponent implements OnInit, OnDestroy {
  ftService: Subscription;
  batchService: Subscription;
  guid = '';
  detailReportId: number;
  detailData: BatchExportDetail;
  batchFilterCriteria: BatchFilterCriteria;
  emData: ProviderMultilevelCptCounts[] = [];
  emSummary: EvaluationManagementSummary;
  ranges: EmRangeAndColumns[];
  outpatientColumns: DataTableColumns[];
  outpatientSummaryColumns: DataTableColumns[];
  inpatientColumns: DataTableColumns[];
  inpatientSummaryColumns: DataTableColumns[];
  emergencyColumns: DataTableColumns[];
  emergencySummaryColumns: DataTableColumns[];
  eyeExamColumns: DataTableColumns[];
  eyeExamSummaryColumns: DataTableColumns[];
  readonly getEvaluationManagementViewTypeText = getEvaluationManagementViewTypeText;
  readonly getCorrespondingEmTitle = getCorrespondingEmTitle;
  readonly BenchmarkOption = BenchmarkOption;
  readonly rangeTableCssClasses: Map<EmDimension, string> = new Map<EmDimension, string>();

  private static getCptRangeViewTypeObject(viewType: CptCategoryViewType): CptRangeViewType {
    return {cptCategoryViewType: viewType, text: getEvaluationManagementViewTypeText(viewType)};
  }

  constructor(private router: ActivatedRoute,
              @Inject(BatchExportServiceToken) private batchExportService: HttpBatchExportService,
              @Inject(FeatureToggleServiceToken) private readonly featureToggleService: HttpFeatureToggleService) {
  }

  ngOnInit() {
    this.setTableCssClasses();
    this.ftService = this.featureToggleService.getBatchExportFeatureToggleSettings()
      .subscribe((featureSettings: FeatureToggleSetting[]) => {
        let setting: FeatureToggleSetting | undefined;
        if (featureSettings && featureSettings.length > 0) {
          setting = featureSettings.find((s: FeatureToggleSetting) => s.featureName.toLowerCase() ===
            FeatureToggleEntries.ENABLE_MULTIPROVIDER_SECURITY.toLowerCase());
        }
        if (setting && setting.enabled) {
          const reportHeaderIdParam = this.router.snapshot.paramMap.get('reportHeaderId');
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          const emailParam = this.router.snapshot.paramMap.get('email');
          const guidParam = this.router.snapshot.paramMap.get('guid');
          if (reportIdParam && !isNaN(+reportIdParam) && reportHeaderIdParam && !isNaN(+reportHeaderIdParam) && emailParam && guidParam) {
            this.batchService = this.batchExportService.isValidBatchRequest(+reportHeaderIdParam, emailParam, guidParam)
              .subscribe((resp: boolean) => {
                if (resp) {
                  this.guid = guidParam;
                  this.detailReportId = +reportIdParam;
                  this.generatePdf(this.detailReportId);
                }
              });
          }
        } else {
          const reportIdParam = this.router.snapshot.paramMap.get('reportId');
          if (reportIdParam && !isNaN(+reportIdParam)) {
            this.detailReportId = +reportIdParam;
            this.generatePdf(this.detailReportId);
          }
        }
      });
  }

  ngOnDestroy() {
    this.ftService?.unsubscribe();
    this.batchService?.unsubscribe();
  }

  generatePdf(detailReportId: number) {
    this.batchExportService.getBatchExportDetailsByDetailId(detailReportId).subscribe((res: BatchExportDetailResponse) => {
      if (res && res.batchExportDetails && res.batchExportDetails.length > 0) {
        this.detailData = res.batchExportDetails[0];
        this.generateColumnsBasedOnBenchmark();
        this.fillOutRanges(res);
        this.batchFilterCriteria = getBatchFilterFromExportDetail(this.detailData, this.guid);
        this.batchExportService.getMultilevelProviderEvaluationManagementMonthly(this.batchFilterCriteria).subscribe((emData) => {
          this.emData = emData.nodes;
          this.emSummary = {
            startMonth: this.batchFilterCriteria.startMonth,
            startYear: this.batchFilterCriteria.startYear,
            endMonth: this.batchFilterCriteria.endMonth,
            endYear: this.batchFilterCriteria.endYear,
            totals: emData.totals
          };
        });
      }
    });
  }

  private setTableCssClasses(): void {
    this.rangeTableCssClasses.set(EmDimension.Outpatient, 'outpatient-trend');
    this.rangeTableCssClasses.set(EmDimension.InPatient, 'inpatient-trend');
    this.rangeTableCssClasses.set(EmDimension.EmergencyMedicine, 'emergency-trend');
    this.rangeTableCssClasses.set(EmDimension.Ophthalmology, 'eye-exam-trend');
  }

  private generateColumnsBasedOnBenchmark(): void {
    const benchmarkOption: BenchmarkOption = this.detailData.emTrendBenchmarkType || BenchmarkOption.Academic;
    this.outpatientColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.Outpatient, benchmarkOption);
    this.outpatientSummaryColumns = getEmSummaryColumnsByDimension(EmDimension.Outpatient, benchmarkOption);
    this.inpatientColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.InPatient, benchmarkOption);
    this.inpatientSummaryColumns = getEmSummaryColumnsByDimension(EmDimension.InPatient, benchmarkOption);
    this.emergencyColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.EmergencyMedicine, benchmarkOption);
    this.emergencySummaryColumns = getEmSummaryColumnsByDimension(EmDimension.EmergencyMedicine,
      benchmarkOption);
    this.eyeExamColumns = getEmTrendColumnsWithExtendedBenchmarkOptions(EmDimension.Ophthalmology, benchmarkOption);
    this.eyeExamSummaryColumns = getEmSummaryColumnsByDimension(EmDimension.Ophthalmology, benchmarkOption);
  }

  private fillOutRanges(res: BatchExportDetailResponse): void {
    this.ranges = [];
    let rangeIndex = 0;
    if (res.batchExportDetails[0].emTrendRangeNewPatientVisit) {
      this.ranges.push({
        cptCategoryMap: NewPatientVisitCodes,
        columns: addClassesToColumns(this.outpatientColumns,
          NewPatientVisitCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.outpatientSummaryColumns,
          NewPatientVisitCodes, !rangeIndex),
        dimension: EmDimension.Outpatient,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(NewPatientVisitCodes.viewType)
      });
    }
    if (res.batchExportDetails[0].emTrendRangeEstablishedPatientVisit) {
      this.ranges.push({
        cptCategoryMap: EstablishedPatientVisitCodes,
        columns: addClassesToColumns(this.outpatientColumns,
          EstablishedPatientVisitCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.outpatientSummaryColumns,
          EstablishedPatientVisitCodes, !rangeIndex),
        dimension: EmDimension.Outpatient,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(EstablishedPatientVisitCodes.viewType)
      });
    }
    if (res.batchExportDetails[0].emTrendRangeConsultation) {
      this.ranges.push({
        cptCategoryMap: ConsultationCodes,
        columns: addClassesToColumns(this.outpatientColumns,
          ConsultationCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.outpatientSummaryColumns,
          ConsultationCodes, !rangeIndex),
        dimension: EmDimension.Outpatient,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(ConsultationCodes.viewType)
      });
    }
    rangeIndex = 0;
    if (res.batchExportDetails[0].emTrendRangeInitialHospital) {
      this.ranges.push({
        cptCategoryMap: InitialHospitalCareCodes,
        columns: addClassesToColumns(this.inpatientColumns,
          InitialHospitalCareCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.inpatientSummaryColumns,
          InitialHospitalCareCodes, !rangeIndex),
        dimension: EmDimension.InPatient,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(InitialHospitalCareCodes.viewType)
      });
    }
    if (res.batchExportDetails[0].emTrendRangeSubsequentHospital) {
      this.ranges.push({
        cptCategoryMap: SubsequentHospitalCareCodes,
        columns: addClassesToColumns(this.inpatientColumns,
          SubsequentHospitalCareCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.inpatientSummaryColumns,
          SubsequentHospitalCareCodes, !rangeIndex),
        dimension: EmDimension.InPatient,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(SubsequentHospitalCareCodes.viewType)
      });
    }
    if (res.batchExportDetails[0].emTrendRangeHospitalDischarge) {
      this.ranges.push({
        cptCategoryMap: HospitalDischargeCodes,
        columns: addClassesToColumns(this.inpatientColumns,
          HospitalDischargeCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.inpatientSummaryColumns,
          HospitalDischargeCodes, !rangeIndex),
        dimension: EmDimension.InPatient,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(HospitalDischargeCodes.viewType)
      });
    }
    rangeIndex = -1;
    if (res.batchExportDetails[0].emTrendRangeEmergencyMedicine) {
      this.ranges.push({
        cptCategoryMap: EmergencyMedicineCodes, columns: this.emergencyColumns, summaryColumns:
        this.emergencySummaryColumns, dimension: EmDimension.EmergencyMedicine, rangeIndex: rangeIndex,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(EmergencyMedicineCodes.viewType)
      }); /*
      * TODO: Per product design, e&m emergency doesn't need to be highlighted because it is a single range
      */
    }
    rangeIndex = 0;
    if (res.batchExportDetails[0].emTrendRangeNpvEyeExam) {
      this.ranges.push({
        cptCategoryMap: EyeExamNewCodes,
        columns: addClassesToColumns(this.eyeExamColumns,
          EyeExamNewCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.eyeExamSummaryColumns,
          EyeExamNewCodes, !rangeIndex),
        dimension: EmDimension.Ophthalmology,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(EyeExamNewCodes.viewType)
      });
    }
    if (res.batchExportDetails[0].emTrendRangeEstablishedEyeExam) {
      this.ranges.push({
        cptCategoryMap: EyeExamEstablishedCodes,
        columns: addClassesToColumns(this.eyeExamColumns,
          EyeExamEstablishedCodes, !rangeIndex),
        summaryColumns: addClassesToColumns(this.eyeExamSummaryColumns,
          EyeExamEstablishedCodes, !rangeIndex),
        dimension: EmDimension.Ophthalmology,
        rangeIndex: rangeIndex++,
        viewType: TrendEmBatchExportComponent.getCptRangeViewTypeObject(EyeExamEstablishedCodes.viewType)
      });
    }
  }
}
