import {Component, Inject, OnInit} from '@angular/core';
import {OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {GroupDialogEvent, SaveGroupsCallbacks} from '../manage-groups-helper';
import {DEFAULT_CUSTOM_GROUP_ID} from '../../store/DefaultValues';

@Component({
  selector: 'app-updated-save-grouping',
  templateUrl: './updated-save-grouping.component.html',
  styleUrls: ['./updated-save-grouping.component.scss']
})
export class UpdatedSaveGroupingComponent implements OnInit, OnDestroy {
  groupNameText = '';
  isDefault = true;
  groupIsEdited = false;
  saved = false;

  constructor(public dialogRef: MatDialogRef<UpdatedSaveGroupingComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: SaveGroupsCallbacks) {
  }

  ngOnInit(): void {
    this.groupNameText = this.data.group?.name || '';
    this.onGroupNameInputChanged({
      target: {
        value: this.groupNameText
      }
    });
  }

  ngOnDestroy(): void {
    this.dialogRef.close({
      saved: this.saved,
      groupIsEdited: this.groupIsEdited
    });
  }

  onGroupNameInputChanged(event?: any): void {
    this.groupNameText = event?.target.value || this.groupNameText || '';
    const matchingGroup = this.data.existingGroups.find(g => g.name.toLowerCase().localeCompare(
      this.groupNameText.toLowerCase()) === 0);
    this.groupIsEdited = !!matchingGroup;
    this.data.group.id = matchingGroup?.id || DEFAULT_CUSTOM_GROUP_ID;
  }

  showInfoTooltip(e: MouseEvent): void {
    const tooltip = document.getElementById('showInfoTooltipForDefaultGroup');
    if (tooltip) {
      tooltip.style.left = e.pageX + 'px';
      tooltip.style.top = (e.pageY + 20) + 'px';
      tooltip.style.display = 'block';
    }
  }

  hideInfoTooltip(): void {
    const tooltip = document.getElementById('showInfoTooltipForDefaultGroup');
    if (tooltip) {
      tooltip.style.display = 'none';
    }
  }

  save(): void {
    if (this.saved || this.groupNameText.length === 0) {
      return;
    }
    this.saved = true;
    this.data.group.name = this.groupNameText;
    this.data.group.isDefault = this.isDefault;
    this.data.dialogActionListener?.next(this.groupIsEdited ? GroupDialogEvent.UPDATE : GroupDialogEvent.DEFINITE_SAVE);
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
