import {BatchExportStatus, BenchmarkOption, FrontEndTelehealthFlag, NodeExpansionState} from '../../shared/enums';
import {BenchmarkHelperObject, BenchmarkPercentile, extendedBenchmarkOptions, originalBenchmarkOptions} from '../../shared/benchmark-types';
import {MetricType} from '../../shared/metric-types';
import {MatTableDataSource} from '@angular/material/table';
import {BatchExportCriteria} from '../../shared/models';

export enum MpeDialogPage {
  NONE,
  SETUP,
  SUMMARY,
  CLOSED,
  EXPORTED
}

export enum FormControlValidationStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}

export interface SchedulePane {
  reportName: string;
  isMonthly: boolean;
}

export interface BenchmarkCombo {
  option: BenchmarkOption;
  percentile?: BenchmarkPercentile;
}

export function getBenchmarkComboDefault(showExtendedBenchmarks: boolean, telehealthFlag: FrontEndTelehealthFlag): BenchmarkCombo {
  return {
    percentile: BenchmarkPercentile.Mean,
    option: showExtendedBenchmarks ? getDefaultBenchmarkOptionForTelehealthFlag(telehealthFlag) : BenchmarkOption.Academic
  };
}

function getDefaultBenchmarkOptionForTelehealthFlag(telehealthFlag: FrontEndTelehealthFlag): BenchmarkOption {
  switch (telehealthFlag) {
    case FrontEndTelehealthFlag.ALL_VISIT_TYPES:
      return BenchmarkOption.Academic;
    case FrontEndTelehealthFlag.IN_PERSON:
      return BenchmarkOption.InPersonAcademic;
    case FrontEndTelehealthFlag.TELEHEALTH:
      return BenchmarkOption.TelehealthAcademic;
  }
}

export function determineBenchmarkOptions(metric: MetricType): BenchmarkHelperObject[] {
  switch (metric) {
    case MetricType.NewPatientVisits:
    case MetricType.EandM:
    case MetricType.EandMTrend:
    case MetricType.OverviewNpv:
    case MetricType.OverviewEandM:
      return extendedBenchmarkOptions;
    default:
      return originalBenchmarkOptions;
  }
}

export function getBenchmarkOptionPlaceHolder(option: BenchmarkOption, benchmarkOptions: BenchmarkHelperObject[]): string {
  const benchmarkOption = benchmarkOptions.find(o => o.value === option);
  return benchmarkOption?.name || '';
}

export interface BatchExportScheduleResponse {
  batchExportScheduleDetails: BatchExportScheduleCriteria[];
}

export interface BatchExportScheduleCriteria extends BatchExportCriteria {
  dateRangeOption: number;
  scheduleType: number;
  isMarkedForDelete: boolean;
  billingAreaKey: number;
}

export interface BatchExportScheduleExistingReportResponseEntity {
  instanceReportId: number;
  instanceReportName: string;
  scheduledReportId: number;
  scheduledReportName: string;
  status: BatchExportStatus;
  exportDate: string;
  filePath: string;
  readStatus: boolean;
  numberOfSpecialties: number;
  numberOfProviders: number;
  createdDate: string;
}

export interface BatchExportScheduleExistingReport {
  scheduledReportId: number;
  scheduledReportName: string;
  status: number;
  exportDate: string;
  filePath: string;
  readStatus: boolean;
  numberOfSpecialties: number;
  numberOfProviders: number;
  createdDate: string;
  hoverMessage?: (id: number) => string | undefined;
}

export interface BatchExportScheduleExistingReportChild extends BatchExportScheduleExistingReport {
  instanceReportId: number;
  instanceReportName: string;
}

export interface BatchExportScheduleExistingReportParent extends BatchExportScheduleExistingReport {
  instanceReports: MatTableDataSource<BatchExportScheduleExistingReportChild>;
  hasUnreadInstanceReport?: boolean;
}

export type MultiProviderScheduleExistingReport = BatchExportScheduleExistingReportChild | BatchExportScheduleExistingReportParent;

export interface ScheduledReportTableEntry {
  source: MatTableDataSource<BatchExportScheduleExistingReportParent>;
  state: NodeExpansionState;
}

export interface BatchExportScheduleExistingReportsResponse {
  batchExportScheduleExistingReports: BatchExportScheduleExistingReportResponseEntity[][];
}
