export function flatMap<T, U>(collection: T[], fn: (it: T) => U[]): U[] {
  const result: U[] = [];
  collection.forEach((it) => {
    result.push(...fn(it));
  });
  return result;
}

export function concat<T>(...items: T[][]): T[] {
  return flatMap(items, identity);
}

export function identity<T>(it: T): T {
  return it;
}
