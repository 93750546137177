import {Inject, Injectable, InjectionToken} from '@angular/core';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../store/IAppState';
import {
  clinicalFingerprintMultilevelDataChangedTo,
  clinicalSummaryConsolidationChangedTo,
  denialsChangedTo,
  evaluationManagementMultilevelChangedTo,
  evaluationManagementSummaryChangedTo,
  mergedNpvByLocationDataChangedTo,
  mergedProductivityTrendDataChangedTo,
  multilevelCollectionsChangedTo,
  multiLevelDenialsChangedTo,
  multilevelNewPatientVisitsChangedTo,
  multilevelPayerCollectionsChangedTo,
  multilevelProviderProductivitiesChangedTo,
  newPatientVisitSummaryChangedTo,
  newPatientVisitTrendEntriesChangedTo,
  payerCollectionsDataChangedTo,
  productivitySummaryChangedTo,
  specialtyEvaluationManagementSummaryChangedTo,
  summaryCollectionsChangedTo,
  zipCodeNewPatientVisitsChangedTo
} from '../store/actions';
import {
  ZipCodeNewPatientVisitService,
  ZipCodeNewPatientVisitServiceToken
} from '../new-patient-visits/services/zipcode-new-patient-visit-service';
import {NgRedux} from '@angular-redux/store';
import {
  ChangedFilterCriteriaParameters,
  CptCategories,
  EvaluationManagementMultilevel,
  MonthProductivityData,
  NewPatientVisitSummary,
  ProviderMultilevelCptCounts,
  ReturnedNewPatientVisitSummary
} from '../shared/models';
import {catchError, distinctUntilChanged, take} from 'rxjs/operators';
import {combineFilter, FilterType, getLevelTypeNodePath, LevelType, SummaryType} from '../shared/helpers';
import {
  EvaluationManagementService,
  EvaluationManagementServiceToken
} from '../evaluation-management/services/evaluation-management.service';
import {combineLatest, forkJoin, of, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {
  getMergedNPVMultilevelSnapshotData,
  getMergedProductivitySnapshotData,
  getMergedProductivityTrendData
} from '../shared/merge-helpers';
import {
  ProductivityMonthService,
  ProductivityMonthServiceToken
} from '../productivity-summary/services/productivity-month.service';
import {
  MonthNewPatientVisitService,
  MonthNewPatientVisitServiceToken
} from '../new-patient-visits/services/month-new-patient-visit-service';
import {aSummaryCollectionsDataDefault} from '../shared/test/helper-functions.spec';
import {
  ProviderProductivityService,
  ProviderProductivityServiceToken
} from '../productivity-summary/services/provider-productivity-service';
import {
  ProductivitySummaryService,
  ProductivitySummaryServiceToken
} from '../productivity-summary/services/productivity-summary.service';
import {CptViewType, DatasetType, MetricLoadKeys} from '../shared/enums';
import {
  NewPatientVisitService,
  NewPatientVisitServiceToken
} from '../new-patient-visits/services/new-patient-visit.service';
import {clinicalFingerprintCompatibleWithNodePath} from '../shared/test/ontology-helper';
import {
  CfpMultiLevelData,
  ClinicalSummaryConsolidation,
  groupClinicalSummaryByFamily
} from '../clinical-fingerprint/ClinicalSummary';
import {
  ClinicalSummaryServiceToken,
  HttpClinicalSummaryService
} from '../clinical-fingerprint/services/clinical-finger-print.service';
import {CollectionServiceToken, HttpCollectionsService} from '../collections/services/collections-service';
import {
  MatchedCollectionsMultiLevelNodePathData,
  PayerCollectionsData,
  PayerMatchedCollectionsMultiLevelNodePathData
} from '../collections/Collection';
import {groupCollectionsByPayerByMultiLevel} from '../shared/multilevel-helpers';
import {DenialServiceToken, HttpDenialsService} from '../denials/services/denials-service';
import {determineAllowedDataset} from '../shared/compare-helpers';
import {
  MergedNpvLocationAggregatedByNode,
  MergedProviderNewPatientVisitMultiLevelData,
  MonthNewPatientVisitData,
  MonthNewPatientVisitEntry,
  NewPatientVisitSnapshotMultiLevel,
  NewPatientVisitTrendEntry,
  NpvLocationResponse,
  NpvSnapshotResponse,
  ProviderNewPatientVisitMultiLevelData
} from '../new-patient-visits/components/npv-models';
import {DenialsMultiLevelData, DenialsPayerData} from '../denials/denials-models';
import {
  LocationNewPatientVisitService,
  LocationNewPatientVisitServiceToken
} from '../new-patient-visits/services/location-new-patient-visit.service';
import {checkForNulls} from '../shared/null-helpers';
import {getNewPatientVisitsBaseWithZeroValues} from '../new-patient-visits/components/npv-helpers';

export const ApiServiceToken = new InjectionToken<ApiService>('Api Service');

export interface ApiService {
  getZipCodeNewPatientData(filter: FilterCriteria): void;

  getEvaluationManagementData(filter: FilterCriteria, specialtyFilter: FilterCriteria,
                              filterCriteriaChange: ChangedFilterCriteriaParameters): void;

  getWrvuTrendData(filter: FilterCriteria, previousYearFilter: FilterCriteria,
                   filterCriteriaChange: ChangedFilterCriteriaParameters): void;

  getWrvuSnapshotData(filter: FilterCriteria, previousYearFilter: FilterCriteria): void;

  getWrvuSummaryData(filter: FilterCriteria): void;

  getNewPatientTrendData(filter: FilterCriteria, filterCriteriaChange: ChangedFilterCriteriaParameters): void;

  getNewPatientSnapshotData(filter: FilterCriteria, previousYearFilter: FilterCriteria): void;

  getNewPatientVisitLocationData(filter: FilterCriteria, previousYearFilter: FilterCriteria): void;

  getNewPatientSummaryData(filter: FilterCriteria): void;

  getClinicalCodeSummaryData(filter: FilterCriteria): void;

  getClinicalFingerPrintMutilevelData(filter: FilterCriteria): void;

  getMatchedCollectionsByPayerByMultiLevelData(filter: FilterCriteria): void;

  getMatchedCollectionsByMultiLevelByNodePathData(filter: FilterCriteria): void;

  getPayerCollectionsData(filter: FilterCriteria): void;

  getCollectionSummaryData(filter: FilterCriteria): void;

  getDenialsData(filter: FilterCriteria): void;

  getDenialsMultilevelData(filter: FilterCriteria): void;
}

@Injectable()
export class ApiServiceImpl implements ApiService {
  zipCodeNewPatientVisitsCall: Subscription;
  providerEvaluationManagementCall: Subscription;
  evaluationManagementMonthlyCall: Subscription;
  specialtyEvaluationManagementSummaryCall: Subscription;
  productivityMonthServiceCall: Subscription;
  monthNewPatientCall: Subscription;
  providerProductivityServiceCall: Subscription;
  previousProviderProductivityMultiLevelServiceCall: Subscription;
  productivitySummaryCombinedCall: Subscription;
  newPatientSnapshotMultilevelCall: Subscription;
  previousNewPatientVisitSnapshotMultilevelCall: Subscription;
  newPatientLocationCall: Subscription;
  previousNewPatientLocationCall: Subscription;
  combinedNewPatientVisitsSummaryCall: Subscription;
  clinicalSummaryCall: Subscription;
  CFPMultilevelCall: Subscription;
  payerByMultiLevelCollectionsNodePathCall: Subscription;
  matchedCollectionsMultiLevelNodePathCall: Subscription;
  payerCollectionCall: Subscription;
  combinedCollectionSummaryCall: Subscription;
  denialsCall: Subscription;
  denialsMultiLevelCall: Subscription;

  static getMonthNewPatientEntry(entry?: MonthNewPatientVisitEntry): NewPatientVisitTrendEntry | undefined {
    const entryInfo: NewPatientVisitTrendEntry | undefined = entry ? {
      month: entry.month,
      year: entry.year,
      countOfExistingPatientVisits: entry.countOfTotalPatientVisits - entry.countOfNewPatientVisits,
      countOfNewPatientVisits: entry.countOfNewPatientVisits,
      countOfTotalPatientVisits: entry.countOfTotalPatientVisits,
      newPatientVisitsPercentage: entry.newPatientVisitsPercentage,
      newPatientVisitsPercentageBenchmarks: entry.newPatientVisitsPercentageBenchmarks,
      newPatientVisitsCommunityPercentageBenchmarks: entry.newPatientVisitsCommunityPercentageBenchmarks,
      newPatientVisitsTelehealthPercentageBenchmarks: entry.newPatientVisitsTelehealthPercentageBenchmarks,
      newPatientVisitsInPersonPercentageBenchmarks: entry.newPatientVisitsInPersonPercentageBenchmarks,
      newPatientVisitsCommunityTelehealthPercentageBenchmarks: entry.newPatientVisitsCommunityTelehealthPercentageBenchmarks,
      newPatientVisitsCommunityInPersonPercentageBenchmarks: entry.newPatientVisitsCommunityInPersonPercentageBenchmarks,
      newPatientVisitsVariance: entry.newPatientVisitsVariance,
      newPatientVisitsCommunityVariance: entry.newPatientVisitsCommunityVariance,
      newPatientVisitsTelehealthVariance: entry.newPatientVisitsTelehealthVariance,
      newPatientVisitsInPersonVariance: entry.newPatientVisitsInPersonVariance,
      newPatientVisitsCommunityTelehealthVariance: entry.newPatientVisitsCommunityTelehealthVariance,
      newPatientVisitsCommunityInPersonVariance: entry.newPatientVisitsCommunityInPersonVariance,
      difference: 0,
      previous: entry
    } : undefined;
    return entryInfo;
  }

  static getNewPatientMergedTrendData(current: MonthNewPatientVisitData, previous: MonthNewPatientVisitData):
    NewPatientVisitTrendEntry[] {
    const tempNPVEntry: any = {};
    let mergedTrendData: NewPatientVisitTrendEntry[] = [];
    current.monthNewPatientVisitCounts.forEach(monthNewPatientVisitEntry => {
      const dateInMonths = monthNewPatientVisitEntry.year * 12 + (+monthNewPatientVisitEntry.month);
      tempNPVEntry[dateInMonths] = ApiServiceImpl.getMonthNewPatientEntry(monthNewPatientVisitEntry);
    });
    previous.monthNewPatientVisitCounts.forEach(monthNewPatientVisitEntry => {
      const dateInMonths = monthNewPatientVisitEntry.year * 12 + (+monthNewPatientVisitEntry.month) + 12;
      const npvEntry = tempNPVEntry[dateInMonths];
      if (npvEntry) {
        npvEntry.previous = ApiServiceImpl.getMonthNewPatientEntry(monthNewPatientVisitEntry);
        tempNPVEntry[dateInMonths] = npvEntry;
      }
    });
    Object.keys(tempNPVEntry).forEach(key => {
      mergedTrendData.push(tempNPVEntry[key]);
    });
    mergedTrendData = _.orderBy(mergedTrendData, ['year', 'month'], ['asc', 'asc']);
    return mergedTrendData;
  }

  constructor(@Inject(ZipCodeNewPatientVisitServiceToken) private zipCodeNewPatientVisitService: ZipCodeNewPatientVisitService,
              @Inject(EvaluationManagementServiceToken) private evaluationManagementService: EvaluationManagementService,
              @Inject(ProductivityMonthServiceToken) private readonly productivityMonthService: ProductivityMonthService,
              @Inject(MonthNewPatientVisitServiceToken) private monthNewPatientVisitService: MonthNewPatientVisitService,
              @Inject(ProviderProductivityServiceToken) private readonly providerProductivityService: ProviderProductivityService,
              @Inject(ProductivitySummaryServiceToken) private readonly productivitySummaryService: ProductivitySummaryService,
              @Inject(NewPatientVisitServiceToken) private newPatientVisitService: NewPatientVisitService,
              @Inject(LocationNewPatientVisitServiceToken) private locationNewPatientVisitService: LocationNewPatientVisitService,
              @Inject(ClinicalSummaryServiceToken) private clinicalSummaryService: HttpClinicalSummaryService,
              @Inject(CollectionServiceToken) private collectionService: HttpCollectionsService,
              @Inject(DenialServiceToken) private denialsService: HttpDenialsService,
              private ngRedux: NgRedux<IAppState>) {
  }

  public getZipCodeNewPatientData(filter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_NPV_ZIP_LOADED)) {
      return;
    }
    this.unSubscribe(this.zipCodeNewPatientVisitsCall);
    this.zipCodeNewPatientVisitsCall =
      this.zipCodeNewPatientVisitService.getZipCodeNewPatientVisitData(filter).subscribe(currentData => {
        this.ngRedux.dispatch(zipCodeNewPatientVisitsChangedTo(currentData));
      });
  }

  public getWrvuTrendData(filter: FilterCriteria, previousYearFilter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_WRVU_TREND_LOADED)) {
      return;
    }
    this.unSubscribe(this.productivityMonthServiceCall);
    this.productivityMonthServiceCall = combineLatest([
      this.productivityMonthService.getMonthProductivity(filter, SummaryType.SELECTED_PERIOD)
        .pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2))),
      this.productivityMonthService.getMonthProductivity(previousYearFilter, SummaryType.PREVIOUS_SELECTED_PERIOD)
        .pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2)))
    ]).subscribe(([currentData, previousData]: [MonthProductivityData, MonthProductivityData]) => {
      const mergedProductivityTrendData = getMergedProductivityTrendData(currentData, previousData);
      if (!_.isEqual(mergedProductivityTrendData, INITIAL_STATE.data.mergedProductivityTrend)) {
        this.ngRedux.dispatch(mergedProductivityTrendDataChangedTo(mergedProductivityTrendData));
      }
    });
  }

  public getWrvuSnapshotData(filter: FilterCriteria, previousYearFilter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_WRVU_SNAPSHOT_LOADED)) {
      return;
    }
    this.unSubscribe(this.providerProductivityServiceCall);
    const requestedDataset: DatasetType = determineAllowedDataset(this.ngRedux.getState());
    this.providerProductivityServiceCall = this.providerProductivityService.getMultiLevelProductivities(filter, requestedDataset)
      .subscribe(currentData => {
        this.unSubscribe(this.previousProviderProductivityMultiLevelServiceCall);
        this.previousProviderProductivityMultiLevelServiceCall = this.providerProductivityService
          .getMultiLevelProductivities(previousYearFilter, requestedDataset)
          .subscribe(previousData => {
            const mergedProviderData = getMergedProductivitySnapshotData(
              currentData.providerProductivitiesData.providerProductivities,
              previousData.providerProductivitiesData.providerProductivities,
              LevelType.provider);
            const mergedSpecialtyData = getMergedProductivitySnapshotData(
              currentData.providerProductivitiesData.specialtyProductivities,
              previousData.providerProductivitiesData.specialtyProductivities,
              LevelType.specialty);
            const mergedDepartmentData = getMergedProductivitySnapshotData(
              currentData.providerProductivitiesData.departmentProductivities,
              previousData.providerProductivitiesData.departmentProductivities,
              LevelType.department);
            // @ts-ignore
            this.ngRedux.dispatch(multilevelProviderProductivitiesChangedTo({
              // @ts-ignore
              providerProductivities: mergedProviderData,
              // @ts-ignore
              specialtyProductivities: mergedSpecialtyData,
              // @ts-ignore
              departmentProductivities: mergedDepartmentData
            }));
          });
      });
  }

  public getWrvuSummaryData(filter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_WRVU_SUMMARY_LOADED)) {
      return;
    }
    const productivitySummaryCombined = forkJoin(
      this.productivitySummaryService.getProductivitySummaryData(filter, SummaryType.SELECTED_PERIOD)
        .pipe(catchError(error => of(error))),
      this.productivitySummaryService.getProductivitySummaryData(filter, SummaryType.PREVIOUS_SELECTED_PERIOD)
        .pipe(catchError(error => of(error))),
      this.productivitySummaryService.getProductivitySummaryData(filter, SummaryType.YEAR_TO_DATE)
        .pipe(catchError(error => of(error))),
      this.productivitySummaryService.getProductivitySummaryData(filter, SummaryType.PREVIOUS_YEAR_TO_DATE)
        .pipe(catchError(error => of(error))),
      this.productivitySummaryService.getProductivitySummaryData(filter, SummaryType.RECENT_MONTH)
        .pipe(catchError(error => of(error))),
      this.productivitySummaryService.getProductivitySummaryData(filter, SummaryType.PREVIOUS_YEAR_RECENT_MONTH)
        .pipe(catchError(error => of(error)))
    );

    this.unSubscribe(this.productivitySummaryCombinedCall);
    this.productivitySummaryCombinedCall = productivitySummaryCombined.subscribe((latestValues: any) => {

      const [
        selectedDateRange,
        previousYearSelectedDateRange,
        yearToDate,
        previousYearToDate,
        recentMonth,
        previousYearRecentMonth
      ] = latestValues;

      this.ngRedux.dispatch(productivitySummaryChangedTo({
        selectedDateRange,
        previousYearSelectedDateRange,
        yearToDate,
        previousYearToDate,
        recentMonth,
        previousYearRecentMonth
      }));
    });
  }

  public getNewPatientTrendData(filter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_NPV_TREND_LOADED)) {
      return;
    }
    const previousFilter = combineFilter(filter, FilterType.PreviousYear);
    this.unSubscribe(this.monthNewPatientCall);
    this.monthNewPatientCall = combineLatest([
      this.monthNewPatientVisitService.getMonthNewPatientVisitData(filter, SummaryType.SELECTED_PERIOD)
        .pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.monthNewPatientVisitService.getMonthNewPatientVisitData(previousFilter, SummaryType.PREVIOUS_SELECTED_PERIOD)
        .pipe(distinctUntilChanged((a, b) => _.isEqual(a, b)))
    ])
      .subscribe(([current, previous]: [MonthNewPatientVisitData, MonthNewPatientVisitData]) => {
        const newPatientVisitTrendData = ApiServiceImpl.getNewPatientMergedTrendData(current, previous);
        if (!_.isEqual(newPatientVisitTrendData, INITIAL_STATE.data.newPatientVisitTrendData)) {
          this.ngRedux.dispatch(newPatientVisitTrendEntriesChangedTo(newPatientVisitTrendData));
        }
      });
  }

  public getNewPatientSnapshotData(filter: FilterCriteria, previousYearFilter: FilterCriteria) {

    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_NPV_SNAPSHOT_LOADED)) {
      return;
    }
    this.unSubscribe(this.newPatientSnapshotMultilevelCall);

    const dataset: DatasetType = determineAllowedDataset(this.ngRedux.getState());
    this.newPatientSnapshotMultilevelCall =
      this.newPatientVisitService.getMultilevelNewPatientVisitSnapshotData(filter, dataset)
        .subscribe((currentData: NpvSnapshotResponse) => {
          let previousMultilevelNpv;
          this.unSubscribe(this.previousNewPatientVisitSnapshotMultilevelCall);
          this.previousNewPatientVisitSnapshotMultilevelCall =
            this.newPatientVisitService.getMultilevelNewPatientVisitSnapshotData(previousYearFilter, dataset)
              .subscribe((data: NpvSnapshotResponse) => {
                previousMultilevelNpv = data.newPatientVisitsSnapshotData;
                const currentMultilevelNpv = currentData.newPatientVisitsSnapshotData;
                this.ngRedux.dispatch(multilevelNewPatientVisitsChangedTo(
                  getMergedNPVMultilevelSnapshotData(this.getMultilevelSnapshotData(currentMultilevelNpv),
                    this.getMultilevelSnapshotData(previousMultilevelNpv))
                ));
              });
        });
  }

  public getNewPatientVisitLocationData(filter: FilterCriteria, previousYearFilter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_NPV_LOCATION_LOADED)) {
      return;
    }
    this.unSubscribe(this.newPatientLocationCall);
    const dataset: DatasetType = determineAllowedDataset(this.ngRedux.getState());
    this.newPatientLocationCall =
      this.locationNewPatientVisitService.getMultilevelNewPatientVisitLocationData(filter, dataset)
        .subscribe((currentData: NpvLocationResponse) => {
          this.unSubscribe(this.previousNewPatientLocationCall);
          this.previousNewPatientLocationCall =
            this.locationNewPatientVisitService.getMultilevelNewPatientVisitLocationData(previousYearFilter, dataset)
              .subscribe((previousData: NpvLocationResponse) => {
                const mergedNpvLocationAggregatedByNode: MergedNpvLocationAggregatedByNode[] = [];
                currentData.npvLocationAggregatedByNodes.forEach(entry => {
                  const equivalentPreviousEntry = previousData.npvLocationAggregatedByNodes.find(
                  previousEntry => previousEntry.memberLocationKey === entry.memberLocationKey);
                  const mergedData: MergedProviderNewPatientVisitMultiLevelData =
                  getMergedNPVMultilevelSnapshotData(this.getMultilevelSnapshotData({
                    departmentNpvSnapshotData: entry.departmentNpvSnapshotData,
                    specialtyNpvSnapshotData: entry.specialtyNpvSnapshotData,
                    providerNpvSnapshotData: entry.providerNpvSnapshotData
                  }), this.getMultilevelSnapshotData({
                    departmentNpvSnapshotData: equivalentPreviousEntry?.departmentNpvSnapshotData || [],
                    specialtyNpvSnapshotData: equivalentPreviousEntry?.specialtyNpvSnapshotData || [],
                    providerNpvSnapshotData: equivalentPreviousEntry?.providerNpvSnapshotData || []
                  }));
                  mergedData.departmentNpvSnapshotData.forEach(datum => {
                    datum.previous = equivalentPreviousEntry ? datum.previous : {
                      ...datum.previous,
                      ...getNewPatientVisitsBaseWithZeroValues(),
                    };
                    datum.difference = checkForNulls(datum.newPatientVisitsPercentage) -
                      checkForNulls(datum.previous.newPatientVisitsPercentage);
                  });
                  mergedData.specialtyNpvSnapshotData.forEach(datum => {
                    datum.previous = equivalentPreviousEntry ? datum.previous : {
                      ...datum.previous,
                      ...getNewPatientVisitsBaseWithZeroValues(),
                    };
                    datum.difference = checkForNulls(datum.newPatientVisitsPercentage) -
                      checkForNulls(datum.previous.newPatientVisitsPercentage);
                  });
                  mergedData.providerNpvSnapshotData.forEach(datum => {
                    datum.previous = equivalentPreviousEntry ? datum.previous : {
                      ...datum.previous,
                      ...getNewPatientVisitsBaseWithZeroValues(),
                    };
                    datum.difference = checkForNulls(datum.newPatientVisitsPercentage) -
                      checkForNulls(datum.previous.newPatientVisitsPercentage);
                  });
                  mergedNpvLocationAggregatedByNode.push({
                    memberLocationKey: entry.memberLocationKey, memberLocationName: entry.memberLocationName,
                    countOfNewPatientVisits: entry.countOfNewPatientVisits,
                    countOfTotalPatientVisits: entry.countOfTotalPatientVisits,
                    countOfExistingPatientVisits: entry.countOfTotalPatientVisits - entry.countOfNewPatientVisits,
                    newPatientVisitsPercentage: entry.newPatientVisitsPercentage,
                    departmentNpvSnapshotData: mergedData.departmentNpvSnapshotData,
                    specialtyNpvSnapshotData: mergedData.specialtyNpvSnapshotData,
                    providerNpvSnapshotData: mergedData.providerNpvSnapshotData,
                    previousPercentage: equivalentPreviousEntry?.newPatientVisitsPercentage || 0
                  });
                });
                this.ngRedux.dispatch(mergedNpvByLocationDataChangedTo(mergedNpvLocationAggregatedByNode));
              });
        });
  }

  public getNewPatientSummaryData(filter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_NPV_SUMMARY_LOADED)) {
      return;
    }

    const combined = forkJoin(
      this.newPatientVisitService.getNewPatientVisitSummaryData(filter, 0).pipe(catchError(error => of(error))),
      this.newPatientVisitService.getNewPatientVisitSummaryData(filter, 1).pipe(catchError(error => of(error))),
      this.newPatientVisitService.getNewPatientVisitSummaryData(filter, 2).pipe(catchError(error => of(error))),
      this.newPatientVisitService.getNewPatientVisitSummaryData(filter, 3).pipe(catchError(error => of(error))),
      this.newPatientVisitService.getNewPatientVisitSummaryData(filter, 4).pipe(catchError(error => of(error))),
      this.newPatientVisitService.getNewPatientVisitSummaryData(filter, 5).pipe(catchError(error => of(error)))
    );

    this.unSubscribe(this.combinedNewPatientVisitsSummaryCall);
    this.combinedNewPatientVisitsSummaryCall = combined.subscribe((latestValues: any) => {
      const [
        selectedDateRange,
        previousYearSelectedDateRange,
        yearToDate,
        previousYearToDate,
        recentMonth,
        previousYearRecentMonth
      ] = latestValues;


      const returnedSelectedDateRange = convertSummaryType(selectedDateRange);
      const returnedPreviousYearSelectedDateRange = convertSummaryType(previousYearSelectedDateRange);
      const returnedYearToDate = convertSummaryType(yearToDate);
      const returnedPreviousYearToDate = convertSummaryType(previousYearToDate);
      const returnedRecentMonth = convertSummaryType(recentMonth);
      const returnedPreviousYearRecentMonth = convertSummaryType(previousYearRecentMonth);

      this.ngRedux.dispatch(newPatientVisitSummaryChangedTo({
        selectedDateRange: returnedSelectedDateRange,
        previousYearSelectedDateRange: returnedPreviousYearSelectedDateRange,
        yearToDate: returnedYearToDate,
        previousYearToDate: returnedPreviousYearToDate,
        recentMonth: returnedRecentMonth,
        previousYearRecentMonth: returnedPreviousYearRecentMonth
      }));
    });
  }

  public getClinicalCodeSummaryData(filter: FilterCriteria) {

    const showCfp = this.ngRedux.getState().display.selectedSpecialties === 1 || clinicalFingerprintCompatibleWithNodePath(filter.nodePath);
    if (!showCfp) {
      const combinedClinicalSummaryData = {
        cptClinicalSummaries: INITIAL_STATE.data.clinicalSummaryConsolidationData.cptClinicalSummaries,
        familyClinicalSummaries: INITIAL_STATE.data.clinicalSummaryConsolidationData.familyClinicalSummaries,
        rangeClinicalSummaries: INITIAL_STATE.data.clinicalSummaryConsolidationData.rangeClinicalSummaries
      };
      this.ngRedux.dispatch(clinicalSummaryConsolidationChangedTo(combinedClinicalSummaryData));
      return;
    }

    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_CFP_LOADED)) {
      return;
    }
    this.unSubscribe(this.clinicalSummaryCall);
    this.clinicalSummaryCall = this.clinicalSummaryService.getClinicalSummaryConsolidation(filter, CptViewType.CptCode)
      .subscribe((clinicalSummaryData: ClinicalSummaryConsolidation) => {
        if (clinicalSummaryData) {
          const family = groupClinicalSummaryByFamily(clinicalSummaryData.cptClinicalSummaries);

          const range = _(clinicalSummaryData.cptClinicalSummaries)
            .groupBy('cptRangeDesc').map((platform, id) => (
              {
                cptRangeDesc: id,
                cptRangeLow: platform[0].cptRangeLow,
                cptRangeHigh: platform[0].cptRangeHigh,
                cptFamilyDesc: platform[0].cptFamilyDesc,
                charges: _.sumBy(platform, 'charges'),
                cfte: _.sumBy(platform, 'cfte'),
                cfteAdjustedWRVUs: _.sumBy(platform, 'cfteAdjustedWRVUs'),
                frequency: _.sumBy(platform, 'frequency'),
                totalWorkRVUs: _.sumBy(platform, 'totalWorkRVUs'),
                workRvuBenchmark: _.sumBy(platform, 'workRvuBenchmark'),
                totalWorkRvuBenchmark: _.sumBy(platform, 'totalWorkRvuBenchmark'),
                frequencyBenchmark: _.sumBy(platform, 'frequencyBenchmark'),
                cfteAdjustedTotalWRVUs: _.sumBy(platform, 'cfteAdjustedTotalWRVUs'),
                cfteAdjustedFrequency: _.sumBy(platform, 'cfteAdjustedFrequency'),
                cFTEAdjustedFrequencyVariance: _.sumBy(platform, 'cFTEAdjustedFrequencyVariance'),
                cFTEAdjustedWrvusVariance: _.sumBy(platform, 'cfteAdjustedWRVUs') - _.sumBy(platform, 'workRvuBenchmark'),
                cFTEAdjustedTotalWrvusVariance: _.sumBy(platform, 'cFTEAdjustedTotalWrvusVariance'),
                wRVUs: _.sumBy(platform, 'wRVUs'),
                communityWorkRvuBenchmark: _.sumBy(platform, 'communityWorkRvuBenchmark'),
                communityTotalWorkRvuBenchmark: _.sumBy(platform, 'communityTotalWorkRvuBenchmark'),
                communityFrequencyBenchmark: _.sumBy(platform, 'communityFrequencyBenchmark'),
                communityCfteAdjustedFrequencyVariance: _.sumBy(platform, 'communityCfteAdjustedFrequencyVariance'),
                communityCfteAdjustedWrvusVariance: _.sumBy(platform, 'cfteAdjustedWRVUs')
                  - _.sumBy(platform, 'communityWorkRvuBenchmark'),
                communityCfteAdjustedTotalWrvusVariance: _.sumBy(platform, 'communityCfteAdjustedTotalWrvusVariance')
              }
            )).value();

          const combinedClinicalSummaryData = {
            cptClinicalSummaries: clinicalSummaryData.cptClinicalSummaries,
            familyClinicalSummaries: family,
            rangeClinicalSummaries: range
          };
          this.ngRedux.dispatch(clinicalSummaryConsolidationChangedTo(combinedClinicalSummaryData));
        }
      });
  }

  public getClinicalFingerPrintMutilevelData(filter: FilterCriteria): void {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_CFP_MULTILEVEL_LOADED)) {
      return;
    }

    this.unSubscribe(this.CFPMultilevelCall);
    const requestedDataset: DatasetType = determineAllowedDataset(this.ngRedux.getState());
    this.CFPMultilevelCall = this.clinicalSummaryService.getClinicalFingerprintByMultilevel(filter, requestedDataset)
      .subscribe((cfpMultilevelData: CfpMultiLevelData) => {
        this.ngRedux.dispatch(clinicalFingerprintMultilevelDataChangedTo(cfpMultilevelData));
      });
  }

  public getEvaluationManagementData(filter: FilterCriteria, specialtyFilter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_EM_SNAPSHOT_LOADED)) {
      return;
    }
    const dataset: DatasetType = determineAllowedDataset(this.ngRedux.getState());
    this.unSubscribe(this.providerEvaluationManagementCall);
    this.providerEvaluationManagementCall = this.evaluationManagementService
      .getMultilevelProviderEvaluationManagement(filter, dataset).subscribe(
        (providerEMMultilevel: EvaluationManagementMultilevel) => {
          this.unSubscribe(this.evaluationManagementMonthlyCall);
          this.evaluationManagementMonthlyCall = this.evaluationManagementService.getMultilevelProviderEvaluationManagementMonthly(filter)
            .pipe(take(1))
            .subscribe((monthly: { nodes: ProviderMultilevelCptCounts[], totals: CptCategories }) => {
              providerEMMultilevel.evaluationManagementData.providerEvaluationManagementSnapshotDataMonthly = monthly;
              this.ngRedux.dispatch(evaluationManagementMultilevelChangedTo(providerEMMultilevel));
              const summaryData = {
                startMonth: filter.dateRange?.startMonth,
                startYear: filter.dateRange?.startYear,
                endMonth: filter.dateRange?.endMonth,
                endYear: filter.dateRange?.endYear,
                totals: providerEMMultilevel.evaluationManagementData.departmentEvaluationManagementSnapshotData.totals
              };
              this.ngRedux.dispatch(evaluationManagementSummaryChangedTo(summaryData));
            });
        });
    this.unSubscribe(this.specialtyEvaluationManagementSummaryCall);
    if (getLevelTypeNodePath(filter.nodePath) === LevelType.singleProvider) {
      this.specialtyEvaluationManagementSummaryCall =
        this.evaluationManagementService.getMultilevelProviderEvaluationManagement(specialtyFilter, dataset).subscribe(
          (specialtyEvaluationManagement: EvaluationManagementMultilevel) => {
            const specialtySummaryData = {
              startMonth: filter.dateRange.startMonth,
              startYear: filter.dateRange.startYear,
              endMonth: filter.dateRange.endMonth,
              endYear: filter.dateRange.endYear,
              totals: specialtyEvaluationManagement.evaluationManagementData.providerEvaluationManagementSnapshotData.totals
            };
            this.ngRedux.dispatch(specialtyEvaluationManagementSummaryChangedTo(specialtySummaryData));
          }
        );
    }
  }

  public getMatchedCollectionsByPayerByMultiLevelData(filter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_COLLECTION_PAYERBYLEVEL_LOADED)) {
      return;
    }
    this.unSubscribe(this.payerByMultiLevelCollectionsNodePathCall);
    this.payerByMultiLevelCollectionsNodePathCall = this.collectionService.getMatchedCollectionsByPayerByMultiLevelData(filter).subscribe(
      (data: PayerMatchedCollectionsMultiLevelNodePathData) => {
        this.ngRedux.dispatch(multilevelPayerCollectionsChangedTo(groupCollectionsByPayerByMultiLevel(data)));

      }
    );
  }

  public getMatchedCollectionsByMultiLevelByNodePathData(filter: FilterCriteria) {

    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_COLLECTION_SNAPSHOT_LOADED)) {
      return;
    }
    const dataset: DatasetType = determineAllowedDataset(this.ngRedux.getState());

    this.unSubscribe(this.matchedCollectionsMultiLevelNodePathCall);

    this.matchedCollectionsMultiLevelNodePathCall =
      this.collectionService.getMatchedCollectionsByMultiLevelByNodePathData(filter, dataset).subscribe(
        (data: MatchedCollectionsMultiLevelNodePathData) => {
          this.ngRedux.dispatch(multilevelCollectionsChangedTo(data));
        }
      );
  }

  public getPayerCollectionsData(filter: FilterCriteria) {

    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_COLLECTION_PAYER_LOADED)) {
      return;
    }
    this.unSubscribe(this.payerCollectionCall);
    this.payerCollectionCall = this.collectionService.getPayerCollectionsData(filter).subscribe(
      (data: PayerCollectionsData) => {
        this.ngRedux.dispatch(payerCollectionsDataChangedTo(data));
      }
    );
  }

  public getCollectionSummaryData(filter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_COLLECTION_SUMMARY_LOADED)) {
      return;
    }
    const combined = forkJoin(
      this.collectionService.getSummaryMatchedCollectionsData(filter, 0).pipe(catchError(error => of(error))),
      this.collectionService.getSummaryMatchedCollectionsData(filter, 1).pipe(catchError(error => of(error))),
      this.collectionService.getSummaryMatchedCollectionsData(filter, 2).pipe(catchError(error => of(error))),
      this.collectionService.getSummaryMatchedCollectionsData(filter, 3).pipe(catchError(error => of(error)))
    );

    this.unSubscribe(this.combinedCollectionSummaryCall);
    this.combinedCollectionSummaryCall = combined.subscribe((latestValues: any) => {
      const [
        selectedDateRange,
        previousYearSelectedDateRange,
        yearToDate,
        previousYearToDate
      ] = latestValues;


      const returnedSelectedDateRange = _.find(selectedDateRange, ['summaryType', 0]) || aSummaryCollectionsDataDefault(0);
      const returnedPreviousYearSelectedDateRange = _.find(previousYearSelectedDateRange, ['summaryType', 1]) ||
        aSummaryCollectionsDataDefault(1);
      const returnedYearToDate = _.find(yearToDate, ['summaryType', 2]) || aSummaryCollectionsDataDefault(2);
      const returnedPreviousYearToDate = _.find(previousYearToDate, ['summaryType', 3]) ||
        aSummaryCollectionsDataDefault(3);

      this.ngRedux.dispatch(summaryCollectionsChangedTo({
        selectedDateRange: returnedSelectedDateRange,
        previousYearSelectedDateRange: returnedPreviousYearSelectedDateRange,
        yearToDate: returnedYearToDate,
        previousYearToDate: returnedPreviousYearToDate,
        recentMonth: aSummaryCollectionsDataDefault(4),
        previousYearRecentMonth: aSummaryCollectionsDataDefault(5)
      }));
    });
  }

  public getDenialsData(filter: FilterCriteria) {

    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_DENIALS_PAYER_LOADED)) {
      return;
    }
    this.unSubscribe(this.denialsCall);
    this.denialsCall = this.denialsService.getDenialsData(filter).subscribe(
      (data: DenialsPayerData) => {
        this.ngRedux.dispatch(denialsChangedTo(data));
      }
    );
  }

  unSubscribe(serviceCall: Subscription) {
    serviceCall?.unsubscribe();
  }

  public getDenialsMultilevelData(filter: FilterCriteria) {
    if (this.isAlreadyLoadedForFilter(MetricLoadKeys.IS_DENIALS_LOADED)) {
      return;
    }
    this.unSubscribe(this.denialsMultiLevelCall);
    const dataset: DatasetType = determineAllowedDataset(this.ngRedux.getState());
    this.denialsMultiLevelCall =
      this.denialsService.getDenialsMultilevelData(filter, dataset).subscribe(
        (data: DenialsMultiLevelData) => {
          this.ngRedux.dispatch(multiLevelDenialsChangedTo(data));
        }
      );
  }

  public isAlreadyLoadedForFilter(key: string): boolean {
    if (localStorage.getItem(key)) {
      return true;
    }
    localStorage.setItem(key, '1');
    return false;
  }

  private getMultilevelSnapshotData(data: ProviderNewPatientVisitMultiLevelData): ProviderNewPatientVisitMultiLevelData {
    data.departmentNpvSnapshotData.forEach((department: NewPatientVisitSnapshotMultiLevel) => {
      if (department.countOfTotalPatientVisits && department.countOfNewPatientVisits) {
        department.countOfExistingPatientVisits = department.countOfTotalPatientVisits - department.countOfNewPatientVisits;
      }
    });
    data.specialtyNpvSnapshotData.forEach((specialty: NewPatientVisitSnapshotMultiLevel) => {
      if (specialty.countOfTotalPatientVisits && specialty.countOfNewPatientVisits) {
        specialty.countOfExistingPatientVisits = specialty.countOfTotalPatientVisits - specialty.countOfNewPatientVisits;
      }
    });
    data.providerNpvSnapshotData.forEach((provider: NewPatientVisitSnapshotMultiLevel) => {
      if (provider.countOfTotalPatientVisits && provider.countOfNewPatientVisits) {
        provider.countOfExistingPatientVisits = provider.countOfTotalPatientVisits - provider.countOfNewPatientVisits;
      }
    });
    return data;
  }
}

export function convertSummaryType(returnedSummary: ReturnedNewPatientVisitSummary): NewPatientVisitSummary {
  let actualSummaryData: NewPatientVisitSummary;
  actualSummaryData = {
    startMonth: returnedSummary.startMonth,
    endMonth: returnedSummary.endMonth,
    startYear: returnedSummary.startYear,
    endYear: returnedSummary.endYear,
    summaryType: returnedSummary.summaryType,
    countOfNewPatientVisits: returnedSummary.newPatientVisitsCount,
    newPatientVisitsPercentage: returnedSummary.newPatientVisitsPercentage,
    countOfTotalPatientVisits: returnedSummary.totalPatientVisitsCount,
    per25th: returnedSummary.per25th,
    per50th: returnedSummary.per50th,
    per75th: returnedSummary.per75th,
    per90th: returnedSummary.per90th,
    mean: returnedSummary.mean,
    communityPer25th: returnedSummary.communityPer25th,
    communityPer50th: returnedSummary.communityPer50th,
    communityPer75th: returnedSummary.communityPer75th,
    communityPer90th: returnedSummary.communityPer90th,
    communityMean: returnedSummary.communityMean,
    variance25thPercentile: returnedSummary.variance25thPercentile,
    variance50thPercentile: returnedSummary.variance50thPercentile,
    variance75thPercentile: returnedSummary.variance75thPercentile,
    variance90thPercentile: returnedSummary.variance90thPercentile,
    varianceMean: returnedSummary.varianceMean,
    communityVariance25thPercentile: returnedSummary.communityVariance25thPercentile,
    communityVariance50thPercentile: returnedSummary.communityVariance50thPercentile,
    communityVariance65thPercentile: returnedSummary.communityVariance65thPercentile,
    communityVariance75thPercentile: returnedSummary.communityVariance75thPercentile,
    communityVariance90thPercentile: returnedSummary.communityVariance90thPercentile,
    communityVarianceMean: returnedSummary.communityVarianceMean,
    telehealthPer25th: returnedSummary.telehealthPer25th,
    telehealthPer50th: returnedSummary.telehealthPer50th,
    telehealthPer75th: returnedSummary.telehealthPer75th,
    telehealthPer90th: returnedSummary.telehealthPer90th,
    telehealthMean: returnedSummary.telehealthMean,
    telehealthCommunityPer25th: returnedSummary.telehealthCommunityPer25th,
    telehealthCommunityPer50th: returnedSummary.telehealthCommunityPer50th,
    telehealthCommunityPer75th: returnedSummary.telehealthCommunityPer75th,
    telehealthCommunityPer90th: returnedSummary.telehealthCommunityPer90th,
    telehealthCommunityMean: returnedSummary.telehealthCommunityMean,
    telehealthVariance25thPercentile: returnedSummary.telehealthVariance25thPercentile,
    telehealthVariance50thPercentile: returnedSummary.telehealthVariance50thPercentile,
    telehealthVariance75thPercentile: returnedSummary.telehealthVariance75thPercentile,
    telehealthVariance90thPercentile: returnedSummary.telehealthVariance90thPercentile,
    telehealthVarianceMean: returnedSummary.telehealthVarianceMean,
    telehealthCommunityVariance25thPercentile: returnedSummary.telehealthCommunityVariance25thPercentile,
    telehealthCommunityVariance50thPercentile: returnedSummary.telehealthCommunityVariance50thPercentile,
    telehealthCommunityVariance75thPercentile: returnedSummary.telehealthCommunityVariance75thPercentile,
    telehealthCommunityVariance90thPercentile: returnedSummary.telehealthCommunityVariance90thPercentile,
    telehealthCommunityVarianceMean: returnedSummary.telehealthCommunityVarianceMean,
    inPersonPer25th: returnedSummary.inPersonPer25th,
    inPersonPer50th: returnedSummary.inPersonPer50th,
    inPersonPer75th: returnedSummary.inPersonPer75th,
    inPersonPer90th: returnedSummary.inPersonPer90th,
    inPersonMean: returnedSummary.inPersonMean,
    inPersonCommunityPer25th: returnedSummary.inPersonCommunityPer25th,
    inPersonCommunityPer50th: returnedSummary.inPersonCommunityPer50th,
    inPersonCommunityPer75th: returnedSummary.inPersonCommunityPer75th,
    inPersonCommunityPer90th: returnedSummary.inPersonCommunityPer90th,
    inPersonCommunityMean: returnedSummary.inPersonCommunityMean,
    inPersonVariance25thPercentile: returnedSummary.inPersonVariance25thPercentile,
    inPersonVariance50thPercentile: returnedSummary.inPersonVariance50thPercentile,
    inPersonVariance75thPercentile: returnedSummary.inPersonVariance75thPercentile,
    inPersonVariance90thPercentile: returnedSummary.inPersonVariance90thPercentile,
    inPersonVarianceMean: returnedSummary.inPersonVarianceMean,
    inPersonCommunityVariance25thPercentile: returnedSummary.inPersonCommunityVariance25thPercentile,
    inPersonCommunityVariance50thPercentile: returnedSummary.inPersonCommunityVariance50thPercentile,
    inPersonCommunityVariance75thPercentile: returnedSummary.inPersonCommunityVariance75thPercentile,
    inPersonCommunityVariance90thPercentile: returnedSummary.inPersonCommunityVariance90thPercentile,
    inPersonCommunityVarianceMean: returnedSummary.inPersonCommunityVarianceMean
  };

  return actualSummaryData;
}
