<div id="table-wrapper">
  <span id="header-wrapper">
    <span id="title">{{tableTitle}}</span>
    <span id="table-modifier">
      <app-zero-suppression-component [condition]="zeroSuppressionCondition" [snapshotEntryName]="snapshotEntryName"
                                      [countOfSuppressedEntries]="countOfSuppressedEntries"
                                      [isZeroSuppressionChecked]="isZeroSuppressionChecked">
      </app-zero-suppression-component>
      <app-column-selection [currentPage]="currentPage" [originalColumns]="originalColumns"
                            [displayedColumnDefs]="displayedColumns" [reducerAction]="reducerAction">
      </app-column-selection>
    </span>
  </span>
  <span id="top-table-wrapper">
    <app-npv-location-table-header [dataSource]="topDataSource"
                                   [columns]="columns"
                                   [displayedColumns]="displayedColumns"
                                   [previousDateText]="previousDateText"
                                   (whenHeaderSortSelected)="whenHeaderSortSelected($event)">
    </app-npv-location-table-header>
  </span>
  <span *ngFor="let source of subTableInfo" class="sub-table-wrapper"
        (mouseenter)="source.active = true" (mouseleave)="source.active = false">
    <mat-table [dataSource]="source.dataSource" class="sub-table"
               [style]="source.dataSource.data.length > 0 && source.dataSource.data[0].snapshotEntries.length > 5 ?
                'max-height: ' + source.height + 'px;' : ''">
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.columnDef">
        <mat-cell *matCellDef="let row" class="{{column.columnDef}} {{column.group ? column.group: ''}}">
          <ng-container *ngIf="column.dataNameList">
            <span class="node-list">
              <app-node-list-container class="{{topRowsClass}}"
                [row]="row"
                [column]="column"
                [CUT_OFF]="1 + NUMBER_OF_ROWS_BEFORE_SCROLLING"
                [isDrillable]="isDrillable"
                [hoverIndex]="source.active ? hoverIndex : DEFAULT_HOVER_INDEX"
                [onlyIncludeTopRows]="true"
                (OnNodeSelect)="onNodeSelect($event)"
                (OnHoverIndexChanged)="changeHoverIndex($event)"
                (afterTableHeightDetermined)="determineTableHeight(source, $event)">
              </app-node-list-container>
              <app-node-list-container
                [row]="row"
                [column]="column"
                [CUT_OFF]="1 + NUMBER_OF_ROWS_BEFORE_SCROLLING"
                [isDrillable]="isDrillable"
                [hoverIndex]="source.active ? hoverIndex : DEFAULT_HOVER_INDEX"
                [onlyIncludeTopRows]="false"
                (OnNodeSelect)="onNodeSelect($event)"
                (OnHoverIndexChanged)="changeHoverIndex($event)">
              </app-node-list-container>
            </span>
          </ng-container>
          <ng-container *ngIf="!column.dataNameList">
            <span class="location-text">{{column.dataName(row)}}</span>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </ng-container>
    </mat-table>
  </span>
  <mat-paginator [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 [showFirstLastButtons]="true"
                 (page)="updateDataTable($event)">
  </mat-paginator>
  <app-loader *ngIf="showLoader"></app-loader>
</div>
