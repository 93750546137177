<div class="data-table-container-wrapper">
  <div class="data-table-container">
    <div class="table-header-wrapper">
      <div class="summaryHeader">{{tableTitle}}</div>
      <span class="right-table-header">
        <app-column-selection *ngIf="addRemoveOption"
                              [originalColumns]="originalColumns"
                              [displayedColumnDefs]="displayedColumns"
                              [currentPage]="currentPage"
                              [reducerAction]="reducerAction"
        ></app-column-selection>
      </span>
    </div>
    <div class="table-body-wrapper" [ngClass]="levelCssClass" id="denials-table-container">
      <div cdkDrag *ngIf="!canHideCptsDenied && showCPTsDenied" id="view-cpts-outer-wrapper">
        <div id="view-denials-wrapper">
          <app-cpts-denied-modal
            [nodePathName]="nodePathName"
            [filters]="filters"
            (whenModalClosed)="setShowCptsDenied($event)"
            [selectedPayer]="selectedPayer">
          </app-cpts-denied-modal>
        </div>
      </div>
      <mat-table [dataSource]="dataSource"
                 id="denials-table-first-table"
                 matSort
                 matSortDirection="{{sortDirection}}"
                 matSortActive="{{defaultSortColumn}}">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef"
                      [sticky]="column.sticky" [stickyEnd]="column.columnDef==='viewCPTsDenied'">
          <mat-header-cell *matHeaderCellDef
                           mat-sort-header
                           [disabled]="column.columnDef==='viewCPTsDenied'"
                           id="{{column.columnDef}}"
                           class="{{column.columnDef}}Header
                              {{column.group ? column.group + 'Header' : ''}}
                              {{column.primaryColumn ? 'primaryHeader' : ''}}
                              {{column.columnDef === defaultSortColumn && mandatoryOpaqueSortingArrow &&
                               !(sort.direction === '') ? 'isWhatTableIsSortedBy' : ''}}"
          ><br/>
            <span class="header-text-wrapper">
              <span class="header-text">
                {{column.header}}</span>
              <span id="date-range" *ngIf="column.showDates">{{previousDate}}</span>
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row"
                    (click)="selectRow(row, column.columnDef)"
                    class="{{column.columnDef}}
                    {{column.group || ''}}
                    {{column.primaryColumn ? 'primaryCell' : ''}}
                    {{getCssClassForColumnType(column)}}">
            <ng-container *ngIf="!column.showCpt">
              <div class="{{column.class ? column.class(row) : 'data-text'}} {{column.primaryColumn && 'primaryText'}}
                  {{ hasDenials(row) && column.columnDef==='viewCPTsDenied' ? 'viewCPTsDenied' : column.columnDef === 'viewCPTsDenied' ? 'disabled' : ''}}"
                   *ngIf="!column.dataNameList">
                {{ column.dataName(row) }}
              </div>
              <span *ngIf="column.dataNameList" class="data-name-list">
                <div *ngFor="let item of column.dataNameList(row)" class="data-name-item"> {{item}} </div>
              </span>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                        class="denials-table-header-row"></mat-header-row>
        <ng-container *ngIf="!fromPdfExport">
          <mat-row *matRowDef="let row; columns: displayedColumns"
            class="{{rowSelectionCallback ? 'selectable' : ''}}">
          </mat-row>
        </ng-container>
        <ng-container *ngIf="fromPdfExport">
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="pdf-row"></mat-row>
        </ng-container>
      </mat-table>
    </div>
    <mat-paginator [class.hide-paginator]="!paginate"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   [showFirstLastButtons]="true"
                   (page)="updateDataTable($event)"></mat-paginator>
    <app-loader class = "loader" [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
</div>
