import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, Output} from '@angular/core';
import {
  AppAction,
  collectionsChargeDisplayedChangedTo,
  collectionsChosenTabChangedTo,
  expectedPaymentsDisplayedChangedTo
} from '../../store/actions';
import {DisplayField, Variable} from '../../variable-container/variable-container.component';
import {BenchmarkPercentile} from '../../shared/benchmark-types';
import {MatchedCollectionsByMultiLevelByNodePath} from '../Collection';
import {BaseColumn, IAppState, INITIAL_STATE} from '../../store/IAppState';
import {SortingCriterion} from '../../shared/models';
import {getLevelTypeNodePath, Page} from '../../shared/helpers';
import {NgRedux, select} from '@angular-redux/store';
import {AnalyticsService, AnalyticsServiceToken} from '../../analytics/analytics.service';
import * as _ from 'lodash';
import {ColumnType, MultilevelTab, MultilevelTabCollections, SortingOrder} from '../../shared/enums';
import {DataTableColumns} from '../../shared/data-table-columns';
import {DrillDownService} from '../../services/drilldown.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-page-provider',
  templateUrl: './collections-provider.component.html',
  styleUrls: ['./collections-provider.component.scss']
})
export class CollectionsProviderPageComponent implements OnChanges {
  @Input() fromPdfExport = false;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  @Input() viewCommunityBenchmarks: boolean;
  @Input() collectionsMultiLevel: MatchedCollectionsByMultiLevelByNodePath[];
  @Input() nodePath: string;
  @Input() isCustom: boolean;
  @Input() columns: DataTableColumns[];
  @Input() displayedColumns: DataTableColumns[] = [];
  @Input() columnHeaders: BaseColumn[];
  @Input() activeVarianceToggle = false;
  @Input() isZeroSuppressed = true;
  @Input() countOfSuppressedEntries = 0;
  @Input() updateSortingCriteria: (sortingCriteria: SortingCriterion | undefined) => void;
  @Input() collectionsSortingCriteria: SortingCriterion | undefined = undefined;
  @Input() reducerAction: (multiLevelByNodePathCollectionsColumns: BaseColumn[]) => AppAction;
  @Output() emitTabChange = new EventEmitter<MultilevelTabCollections>();
  @select(['display', 'zeroSuppression'])
  private readonly zeroSuppression$: Observable<boolean>;
  zeroSuppressCondition = 'reported collections data';

  variables: Variable[] = [
    {
      name: 'Charges',
      display: false,
      reducerField: DisplayField.CollectionsCharge,
      dispatchAction(display: boolean): AppAction {
        return collectionsChargeDisplayedChangedTo(display);
      }
    },
    {
      name: 'Expected Payments',
      display: false,
      reducerField: DisplayField.ExpectedPayments,
      dispatchAction(display: boolean): AppAction {
        return expectedPaymentsDisplayedChangedTo(display);
      }
    }
  ];
  currentPage = Page.CollectionsSnapshot;
  varianceToggleSortingCriterion: SortingCriterion;
  showProgressBar = false;
  varianceKey = '';
  sortDirection: string;
  level: string;
  defaultSortColumn = 'netCollectionRate';
  isDepartment = false;
  isSpecialty = false;
  isProvider: boolean;
  sortingDataAccessor: any;

  constructor(private ngRedux: NgRedux<IAppState>,
              private _changeDetectorRef: ChangeDetectorRef,
              private drillDownService: DrillDownService,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnChanges() {
    this.showProgressBar = _.isEqual(this.collectionsMultiLevel,
      INITIAL_STATE.data.matchedCollectionsByMultiLevelByNodePathData.providerCollections);
    this.analyticsService.handleGoogleAnalytics('Collections Snapshot',
      this.viewCommunityBenchmarks ? 'Community' : 'Academic', 'Toggling Benchmark');
    this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      return (sortHeaderId === 'netCollectionRate') ? data[this.defaultSortColumn] : data[sortHeaderId];
    };
    this.benchmarkPercentile = this.benchmarkPercentile === BenchmarkPercentile.Percentile65th ?
      BenchmarkPercentile.Mean : this.benchmarkPercentile;
    this.isSpecialty = getLevelTypeNodePath(this.nodePath) === 3 && !this.isCustom;
    this.isDepartment = getLevelTypeNodePath(this.nodePath) === 2 && !this.isCustom;
    this._changeDetectorRef.detectChanges();
    const varianceColumn = this.columns.find((col: BaseColumn) => col.columnType === ColumnType.VARIANCE);
    this.varianceKey = varianceColumn ? varianceColumn.columnDef : 'varianceMean';
    const collectionsSortColumn = 'netCollectionRate';
    if (this.collectionsSortingCriteria && this.collectionsSortingCriteria.columnDef && this.collectionsSortingCriteria.sortingOrder) {
      const requiredSortingCriteria: SortingCriterion = this.collectionsSortingCriteria.columnType === ColumnType.VARIANCE ? {
        ...this.collectionsSortingCriteria,
        columnDef: this.varianceKey
      } : this.collectionsSortingCriteria;
      this.defaultSortColumn = requiredSortingCriteria.columnDef;
      this.sortDirection = this.collectionsSortingCriteria.sortingOrder === SortingOrder.DESCENDING ? 'desc' : 'asc';
    } else {
      this.defaultSortColumn = collectionsSortColumn;
      this.sortDirection = 'desc';
    }
    if (!this.activeVarianceToggle) {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: this.varianceKey,
        columnType: ColumnType.VARIANCE
      };
    } else {
      this.varianceToggleSortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: collectionsSortColumn
      };
    }
  }

  levelSpecificHandleSortChange = (collectionsEntries: MatchedCollectionsByMultiLevelByNodePath[]) => {
    if (!_.isEqual(this.collectionsMultiLevel, collectionsEntries)) {
      this.collectionsMultiLevel = collectionsEntries;
    }
  };

  whenProviderSelected = (selectedNode: MatchedCollectionsByMultiLevelByNodePath) => {
    if (!selectedNode) {
      return;
    }
    this.analyticsService.handleGoogleAnalytics('Collections Snapshot',
      selectedNode.providerNodeName,
      'Drill down on Chart');
    this.drillDownService.drillDownIntoNode(selectedNode.providerNodePath, this.nodePath, MultilevelTab.BY_PROVIDER);
    this.ngRedux.dispatch(collectionsChosenTabChangedTo(MultilevelTabCollections.BY_PAYER));
  };


}
