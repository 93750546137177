import {Component, Input, OnChanges} from '@angular/core';
import {MultiProviderExportPreview} from '../mpe-summary-pane.component';

@Component({
  selector: 'app-mpe-summary-pane-row',
  templateUrl: './mpe-summary-pane-row.component.html',
  styleUrls: ['./mpe-summary-pane-row.component.scss']
})
export class MpeSummaryPaneRowComponent implements OnChanges {
  @Input() multiProviderExportPreview: MultiProviderExportPreview;
  @Input() isFirstRow = false;
  boldBenchmarkClass = '';

  constructor() { }

  ngOnChanges() {
    this.boldBenchmarkClass = this.isFirstRow ? 'bold' : '';
  }
}
