import {BaseColumn} from '../../store/IAppState';
import {ColumnType, MultilevelTabCollections} from '../enums';
import {Export, ExportMetadata} from './export';
import {getFilterInfo, getFilterInfoForCptsDeniedModal} from './export-helper';
import {
  formatToPercentage,
  roundTo,
  roundToWithCommasSeparation,
  roundToWithPercentage,
  roundToWithPercentageOrElseNull
} from '../../productivity-summary/number-formatter';
import {hasValue} from '../null-helpers';
import {DataTableColumns} from '../data-table-columns';
import {
  BenchmarkPercentilesForDenials,
  createDenialsPaidBenchmarkColumnFor,
  createDenialsRateBenchmarkColumnFor,
  createDenialsVarianceColumnFor,
  createTerminalDenialsRateBenchmarkColumnFor
} from '../BenchmarkColumns';
import {BenchmarkPercentile} from '../benchmark-types';
import {
  DenialCptCodeByReasonEntity,
  DenialPayerEntity,
  DenialsEntity,
  DenialsMultiLevelEntity,
  DenialsPayerData
} from '../../denials/denials-models';

export const denialsDepartmentNodeColumn = {
  header: 'Department',
  columnDef: 'departmentNodeName',
  dataName: (row: DenialsMultiLevelEntity) => row.departmentNodeName,
  isDefault: true,
  primaryColumn: true
};
export const denialsSpecialtyNodeColumn = {
  header: 'Specialty',
  columnDef: 'specialtyNodeName',
  dataName: (row: DenialsMultiLevelEntity) => row.specialtyNodeName,
  isDefault: true,
  primaryColumn: true
};
export const denialsProviderNodeColumn = {
  header: 'Provider',
  columnDef: 'providerNodeName',
  dataName: (row: DenialsMultiLevelEntity) => row.providerNodeName,
  isDefault: true,
  primaryColumn: true
};

function filterOutNodeColumns(columns: BaseColumn[]) {
  return columns.filter(
    c => c.columnDef !== 'providerNodeName' &&
      c.columnDef !== 'specialtyNodeName' &&
      c.columnDef !== 'departmentNodeName');
}

export function adjustMultilevelDenialsColumnsForByDepartment(displayedMultiLevelDenialsColumns: BaseColumn[]) {
  return displayedMultiLevelDenialsColumns.filter(
    c => c.columnDef !== 'providerNodeName' && c.columnDef !== 'specialtyNodeName');
}

export function adjustMultilevelDenialsColumnsForBySpecialty(displayedMultiLevelDenialsColumns: BaseColumn[]) {
  const newColumns = filterOutNodeColumns(displayedMultiLevelDenialsColumns);
  newColumns.splice(0, 0, denialsSpecialtyNodeColumn, denialsDepartmentNodeColumn);
  return newColumns;
}

export function adjustMultilevelDenialsColumnsForByProvider(displayedMultiLevelDenialsColumns: BaseColumn[]) {
  const newColumns = filterOutNodeColumns(displayedMultiLevelDenialsColumns);
  newColumns.splice(0, 0, denialsProviderNodeColumn, denialsSpecialtyNodeColumn, denialsDepartmentNodeColumn);
  return newColumns;
}

export function getDenialsMetricExportData(denialsData: DenialsPayerData,
                                           nodePath: string,
                                           displayedColumns: BaseColumn[],
                                           copyRight: string,
                                           dateRange: any,
                                           memberLocationName: any,
                                           lagPeriod: any,
                                           billingArea: any,
                                           payerCategory: any
): ExportMetadata {

  let headers: string[] = [];

  const benchmarkHeaders = ['CPSC Terminal Denial Rate Benchmark 50th Percentile', 'CPSC Denied CPTs Paid 25th Percentile',
    'CPSC Denied CPTs Paid 50th Percentile',
    'CPSC Denied CPTs Paid 75th Percentile', 'CPSC Denial Rate 25th Percentile',
    'CPSC Denial Rate 50th Percentile', 'CPSC Denial Rate 75th Percentile'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'terminalDenialRatePer50th',
      header: 'CPSC Terminal Denial Rate Benchmark 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'deniedPaidPer25th',
      header: 'CPSC Denied CPTs Paid 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'deniedPaidPer50th',
      header: 'CPSC Denied CPTs Paid 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'deniedPaidPer75th',
      header: 'CPSC Denied CPTs Paid 75th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'denialRatePer25th',
      header: 'CPSC Denial Rate 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'denialRatePer50th',
      header: 'CPSC Denial Rate 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'denialRatePer75th',
      header: 'CPSC Denial Rate 75th Percentile',
      columnType: ColumnType.BENCHMARK
    }
  ];

  const displayedBenchmarkColumns = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.DENIED_PAID_BENCHMARK
    || x.columnType === ColumnType.TERMINAL_DENIAL_BENCHMARK);

  let remainingColumns = displayedColumns
    .filter
    (x => !(x.columnType === ColumnType.BENCHMARK
      || x.columnType === ColumnType.DENIED_PAID_BENCHMARK
      || x.columnType === ColumnType.TERMINAL_DENIAL_BENCHMARK
      || x.columnType === ColumnType.VARIANCE));

  for (let i = 0; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }
  if (displayedBenchmarkColumns.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  return {
    copyright: copyRight,
    summaryHeaders: headers,
    page: 'Denials',
    fileName: 'Denials',
    detailData: getDenialsData(denialsData, remainingColumns),
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName,
      lagPeriod, billingArea, payerCategory),
    isBlankRowAfterSummary: false
  };
}

function generateRowForDenialsByPayer(denial: DenialPayerEntity, columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    row.push(col.dataName(denial));
  });
  return row;
}

export function getTheExcelDenialsByPayerData(
  payerDenials: DenialPayerEntity[],
  denialsColumns: DataTableColumns[],
  displayedColumns: BaseColumn[]
): ExportMetadata {
  denialsColumns = denialsColumns.filter(col => col.primaryColumn ||
    displayedColumns.find(c => c.columnDef === col.columnDef || (c.columnType === col.columnType &&
      c.columnType !== undefined))).slice();
  denialsColumns = insertAllBenchmarkDependantColumnsForDenials(BenchmarkPercentilesForDenials, denialsColumns);
  const copyRight = 'Clinical Practice Solutions Center Denials ' + (new Date().getFullYear() - 1) +
    ' American Medical Association. All Rights Reserved. ';
  return {
    detailHeaders: denialsColumns.map(col => col.header),
    detailData: payerDenials.map(denial => {
      return generateRowForDenialsByPayer(denial, denialsColumns);
    }),
    fileName: 'Denials by payer',
    page: 'Denials by payer',
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showLag: true,
      showBilling: true
    }
  };
}

function generateRowForDenialsSnapshot(denial: DenialsMultiLevelEntity, columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    row.push(col.dataName(denial));
  });
  return row;
}

function insertAllBenchmarkDependantColumnsForDenials(benchmarkPercentiles: BenchmarkPercentile[],
                                                      columns: DataTableColumns[]): DataTableColumns[] {
  const benchmarkRateColumns: DataTableColumns[] = [];
  const benchmarkPaidColumns: DataTableColumns[] = [];
  const benchmarkTerminalColumns: DataTableColumns[] = [];
  const varianceColumns: DataTableColumns[] = [];
  benchmarkPercentiles.forEach(bcm => {
    benchmarkRateColumns.push(createDenialsRateBenchmarkColumnFor(bcm));
    benchmarkPaidColumns.push(createDenialsPaidBenchmarkColumnFor(bcm));
    benchmarkTerminalColumns.push(createTerminalDenialsRateBenchmarkColumnFor(bcm));
    varianceColumns.push(createDenialsVarianceColumnFor(bcm));
  });
  const hasBenchmarkRate = columns.find(col => col.columnType === ColumnType.BENCHMARK);
  if (hasBenchmarkRate) {
    columns = columns.filter(col => !(col.columnType === ColumnType.BENCHMARK));
    benchmarkRateColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasBenchmarkPaid = columns.find(col => col.columnType === ColumnType.DENIED_PAID_BENCHMARK);
  if (hasBenchmarkPaid) {
    columns = columns.filter(col => !(col.columnType === ColumnType.DENIED_PAID_BENCHMARK));
    benchmarkPaidColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasBenchmarkTerminal = columns.find(col => col.columnType === ColumnType.TERMINAL_DENIAL_BENCHMARK);
  if (hasBenchmarkTerminal) {
    columns = columns.filter(col => !(col.columnType === ColumnType.TERMINAL_DENIAL_BENCHMARK));
    benchmarkTerminalColumns.forEach(bcmCol => {
      columns.push(bcmCol);
    });
  }
  const hasVariance = columns.find(col => col.columnType === ColumnType.VARIANCE);
  if (hasVariance) {
    columns = columns.filter(col => col.columnType !== ColumnType.VARIANCE);
    varianceColumns.forEach(varCol => {
      columns.push(varCol);
    });
  }
  return columns;
}

export function getTheExcelDenialsSnapshotData(
  denialsMultilevelByNodePath: DenialsMultiLevelEntity[],
  denialsColumns: DataTableColumns[],
  displayedColumns: BaseColumn[],
  level: string
): ExportMetadata {
  denialsColumns = denialsColumns.filter(col => col.primaryColumn ||
    displayedColumns.find(c => c.columnDef === col.columnDef || (c.columnType === col.columnType &&
      c.columnType !== undefined))).slice();
  denialsColumns = insertAllBenchmarkDependantColumnsForDenials(BenchmarkPercentilesForDenials, denialsColumns);
  const copyRight = 'Clinical Practice Solutions Center Denials ' + (new Date().getFullYear() - 1) +
    ' American Medical Association. All Rights Reserved. ';
  return {
    detailHeaders: ['By ' + denialsColumns[0].header].concat(denialsColumns.slice(1).map(col => col.header)),
    detailData: denialsMultilevelByNodePath.map(denial => {
      return generateRowForDenialsSnapshot(denial, denialsColumns);
    }),
    fileName: 'Denials by ' + level,
    page: 'Denials by ' + level,
    title: copyRight,
    copyright: copyRight,
    isBlankRowAfterSummary: true,
    whatFilters: {
      showBreadcrumb: true,
      showDateRange: true,
      showLocation: true,
      showPayer: true,
      showLag: true,
      showBilling: true
    }
  };
}

export function getDenialsCSVExportData(denials: DenialsPayerData,
                                        fileName: string,
                                        title: string,
                                        displayedColumns: BaseColumn[]
): Export {

  let headers: string[] = [];

  const benchmarkHeaders = ['CPSC Denied CPTs Paid 25th Percentile',
    'CPSC Denied CPTs Paid 50th Percentile',
    'CPSC Denied CPTs Paid 75th Percentile', 'CPSC Denial Rate 25th Percentile',
    'CPSC Denial Rate 50th Percentile', 'CPSC Denial Rate 75th Percentile'];

  const benchmarkData: BaseColumn[] = [
    {
      columnDef: 'deniedPaidPer25th',
      header: 'CPSC Denied CPTs Paid 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'deniedPaidPer50th',
      header: 'CPSC Denied CPTs Paid 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'deniedPaidPer75th',
      header: 'CPSC Denied CPTs Paid 75th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'denialRatePer25th',
      header: 'CPSC Denial Rate 25th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'denialRatePer50th',
      header: 'CPSC Denial Rate 50th Percentile',
      columnType: ColumnType.BENCHMARK
    },
    {
      columnDef: 'denialRatePer75th',
      header: 'CPSC Denial Rate 75th Percentile',
      columnType: ColumnType.BENCHMARK
    }
  ];
  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE));


  for (let i = 0; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }

  return {
    data: getDenialsData(denials, remainingColumns),
    headers: headers,
    fileName: fileName,
    page: 'Denials',
    title: title.replace(',', ''),
    whatFilters: {
      showPayer: true,
      showLag: true,
      showBilling: true
    },
  };
}

export function getDenialsExportData(denials: any[],
                                     fileName: string,
                                     title: string,
                                     columns: DataTableColumns[]
): Export {

  return {
    data: getDenialsSummaryData(denials, columns),
    headers: [],
    fileName: fileName,
    page: 'Denials',
    title: title.replace(',', ''),
    whatFilters: {
      showPayer: true,
      showLag: true,
      showBilling: true
    }
  };
}

export function getDenialsSummaryData(denialsData: any[],
                                      columns: DataTableColumns[]):
  string[][] {
  const result: string[][] = [];
  result.push(columns.map(col => col.header));

  if (denialsData) {
    denialsData.forEach(row => {
      result.push(getRowForDenialsExport(row, columns));
    });
  }

  return result;
}

export function getDenialsData(denialsData: DenialsPayerData,
                               displayedColumns: BaseColumn[]): any {
  const result: string[][] = [];
  denialsData.denialRates.forEach(x => {
    result.push(getRowForDenialsMetricExport(x, displayedColumns));
  });

  return result;
}

export function getDenialsMultiLevelData(denials: DenialsMultiLevelEntity[],
                                         displayedColumns: BaseColumn[],
                                         currentDenialsLevel: MultilevelTabCollections): any {
  const result: string[][] = [];

  switch (currentDenialsLevel) {
    case MultilevelTabCollections.BY_DEPARTMENT:
      denials.forEach((x: any) => {
        result.push(getRowForDenialsMultiLevelExport(x, displayedColumns));
      });
      break;
    case MultilevelTabCollections.BY_SPECIALTY:
      denials.forEach((x: any) => {
        result.push(getRowForDenialsMultiLevelExport(x, displayedColumns));
      });
      break;
    case MultilevelTabCollections.BY_PROVIDER:
      denials.forEach((x: any) => {
        result.push(getRowForDenialsMultiLevelExport(x, displayedColumns));
      });
      break;
    default:
      denials.forEach((x: any) => {
        result.push(getRowForDenialsMultiLevelExport(x, displayedColumns));
      });
      break;
  }
  return result;
}

export function getRowForDenialsMultiLevelExport(denialsData: DenialsMultiLevelEntity,
                                                 displayedColumns: BaseColumn[]): string[] {

  const data: any[] = [];
  displayedColumns.forEach(x => {
    if ((x.columnType === ColumnType.BENCHMARK || x.columnType === ColumnType.VARIANCE
    )
    ) {
      data.push(roundToWithPercentage(Number(denialsData[x.columnDef]), 2));
    } else if (x.columnDef === 'departmentNodeName'
      || x.columnDef === 'specialtyNodeName'
      || x.columnDef === 'providerNodeName') {
      data.push(denialsData[x.columnDef] + '');
    } else if (x.columnDef === 'deniedPaidRate' || x.columnDef === 'denialRate') {
      data.push(roundTo(Number(denialsData[x.columnDef]), 2) + '%');
    } else if (x.columnDef === 'payerCategoryDesc') {
      data.push(denialsData[x.columnDef] + '');
    } else if (x.columnDef === 'numberDenied' || x.columnDef === 'numberBilled') {
      data.push(denialsData[x.columnDef] + '');
    } else if (x.columnDef === 'terminalDenialRate' || x.columnDef === 'chargesDeniedPercentage') {
      data.push(formatToPercentage(Number(denialsData[x.columnDef]), 1));
    } else if (x.columnDef === 'chargeAmount' || x.columnDef === 'deniedChargedAmount' || x.columnDef === 'chargesTerminallyDenied') {
      data.push('$' + roundToWithCommasSeparation(Number(denialsData[x.columnDef]), 1));
    } else {
      data.push(roundTo(Number(denialsData[x.columnDef]), 2) + '%');
    }
  });

  return data;
}

export function getRowForDenialsExport(denial: DenialsEntity, displayedColumns: DataTableColumns[]): string[] {

  const data: string[] = [];
  displayedColumns.forEach(col => {
    data.push(col.dataName(denial));
  });
  return data;
}

export function getRowForDenialsMetricExport(denialsData: DenialPayerEntity, displayedColumns: BaseColumn[]): string[] {

  const data: any[] = [];
  displayedColumns.forEach(x => {
    if (x.columnType === ColumnType.BENCHMARK || x.columnDef === 'denialRate'
      || x.columnDef === 'deniedPaidRate') {
      data.push(roundTo(Number(denialsData[x.columnDef]), 1) + '%');
    } else if (x.columnDef.includes('terminalDenialRateBenchmark')) {
      const asNumber = hasValue(denialsData[x.columnDef]) ? Number(denialsData[x.columnDef]) : undefined;
      data.push(roundToWithPercentageOrElseNull(asNumber, 1));
    } else if (x.columnDef === 'terminalDenialRate' || x.columnDef === 'chargesDeniedPercentage' ||
      x.columnDef === 'terminalChargesDeniedPercentage') {
      data.push(formatToPercentage(Number(denialsData[x.columnDef]), 1));
    } else if (x.columnDef === 'payerCategoryDesc') {
      data.push(denialsData[x.columnDef] + '');
    } else if (x.columnDef === 'numberDenied' || x.columnDef === 'numberBilled') {
      data.push(denialsData[x.columnDef] + '');
    } else {
      data.push('$' + roundTo(Number(denialsData[x.columnDef]), 0) + '');
    }
  });

  return data;
}

export function getRowForDenialsRateExport(denial: DenialsEntity, displayedColumns: DataTableColumns[]): string[] {

  const data: string[] = [];
  displayedColumns.forEach(col => {
    data.push(col.dataName(denial));
  });
  return data;
}

export function getRowForCptDenialModalData(entry: DenialCptCodeByReasonEntity, columns: DataTableColumns[]): string[] {
  const row: string[] = [];
  columns.forEach(col => {
    if (col.columnDef === 'denialReason' && !hasValue(entry[col.columnDef])) {
      row.push('All Reasons');
    } else {
      row.push(col.upperDataName ? col.upperDataName(entry) : col.dataName(entry));
    }
  });
  return row;
}

export function getCptDeniedExportDataForCsv(data: DenialCptCodeByReasonEntity[],
                                             displayedColumns: DataTableColumns[], fileName: string,
                                             title: string): Export {
  const headers: string[] = [];

  for (let i = 0; i < displayedColumns.length; i++) {
    headers.push(displayedColumns[i].header);
  }

  return {
    data: getCptsDeniedDataForCsv(data, displayedColumns),
    headers: headers,
    fileName: fileName,
    page: 'CPTsDenied',
    title: title,
    whatFilters: {
      showPayer: true
    }
  };
}

export function getCptsDeniedDataForCsv(cptsDenied: DenialCptCodeByReasonEntity[],
                                        displayedColumns: DataTableColumns[]): string[][] {
  const result: string[][] = [];
  cptsDenied.forEach(entry => {
    result.push(getRowForCptDenialModalData(entry, displayedColumns));
  });
  return result;
}

export function getCptDeniedModalDataForExcel(data: DenialCptCodeByReasonEntity[], columns: DataTableColumns[]): string[][] {
  const result: string[][] = [];
  const blankRow: string[] = [];
  columns.forEach(() => {
    blankRow.push('');
  });
  result.push(blankRow);
  data.forEach(x => {
    result.push(getRowForCptDenialModalData(x, columns));
  });
  return result;
}

export function getCptDeniedModalExcelData(data: DenialCptCodeByReasonEntity[],
                                           columns: DataTableColumns[],
                                           copyRight: string,
                                           nodePath?: string,
                                           dateRange?: any,
                                           memberLocationName?: any,
                                           lagPeriod?: any,
                                           memberBillingArea?: any,
                                           payerCategory?: any): ExportMetadata {
  return {
    copyright: copyRight,
    detailHeaders: columns.map(col => col.header),
    page: 'CPTs Denied',
    fileName: 'CPTsDenied',
    detailData: getCptDeniedModalDataForExcel(data, columns),
    filterInfo: getFilterInfoForCptsDeniedModal(nodePath ?? '', dateRange, memberLocationName,
      lagPeriod, memberBillingArea, payerCategory),
    isBlankRowAfterSummary: true,
    shouldBoldAggregateRows: true,
    aggregateIndex: 0
  };
}
