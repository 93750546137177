import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from '../../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {Options, YAxisOptions} from 'highcharts/highstock';
import {
  calculateChartWidth,
  ChartMarkerSymbol,
  ChartType,
  createEmptyValues,
  DEFAULT_MAX_NUMBER_SCROLLBARS,
  DEFAULT_POINT_PADDING,
  DEFAULT_POINT_WIDTH,
  StyledChart
} from '../../../../shared/highcharts-helpers';
import {deepCopyOf, getBenchmarkSeriesName, getPreviousYearDateRange} from '../../../../shared/helpers';
import {distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'lodash';
import {toAbbreviatedMonthName} from '../../../../productivity-summary/month-formatter';
import {MetricType} from '../../../../shared/metric-types';
import {CHART_HEIGHT} from '../../../../productivity-summary/productivity-graph-component';
import {GraphColors} from '../../../../productivity-summary/colors';
import {abbreviateAxisValue, roundToNumber} from '../../../../productivity-summary/number-formatter';
import {concat} from '../../../../shared/ourLodash';
import {checkForNulls, hasNonZeroValue} from '../../../../shared/null-helpers';
import {Variable} from '../../../../variable-container/variable-container.component';
import {Chart} from 'angular-highcharts';
import {updateCommunityBenchmarkAlertStatus} from '../../../../shared/reducer-helper';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../../../../shared/benchmark-types';
import {NewPatientVisitTrendEntry} from '../../npv-models';
import {BenchmarkPercentilesForNpv} from '../../../../shared/BenchmarkColumns';
import {getBenchmarkLegendForNpv, getDesignatedNpvBenchmarkObject} from '../../npv-helpers';
import {BenchmarkOption, HighChartColorIndex} from '../../../../shared/enums';
import {Legend} from '../../../../shared/models';
import {countLegendsForNpvTrend, getPercentNpvLegendForTrend, getPreviousDateRangeLegendForNpvTrend} from './npv-trend-chart-helper';

@Component({
  selector: 'app-new-patient-visits-by-month-chart',
  templateUrl: './new-patient-visits-by-month-chart.component.html',
  styleUrls: ['./new-patient-visits-by-month-chart.component.scss']
})
export class NewPatientVisitsByMonthChartComponent implements OnInit, OnDestroy {

  @Input() showProgressBar: boolean;
  @Input() showVariableMenu: boolean;
  @Input() variables: Variable[];
  @Input() page: string;
  @Input() isSpecialty: boolean;
  @Input() isDepartment: boolean;
  viewCommunityBenchmarks: boolean;
  @Input() showExtendedBenchmarkOptions = false;

  @ViewChild('chartMonthTarget', {static: true})
  protected chartTarget: ElementRef;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly benchmarkOption$: Observable<BenchmarkOption>;

  @select(['display', 'patientVisitCounts'])
  private readonly patientVisitCounts$: Observable<boolean>;
  @select(['display', 'previousPatientVisitCounts'])
  private readonly previousPatientVisitCounts$: Observable<boolean>;
  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;
  private showPatientVisitCounts: boolean;
  private showPreviousPatientVisitCounts: boolean;
  benchmarkToExclude = ['65th Percentile'];

  readonly metricType = MetricType;
  private snapshotBarCount = 20;
  benchmarkPercentile = BenchmarkPercentile.Mean;
  benchmarkOption = BenchmarkOption.Academic;

  patientVisitCountSeries: any[];
  previousPatientVisitCountSeries: any[];
  previousDateRange: string;
  hasBenchmarks: boolean;
  hasCommunityBenchmarks: boolean;
  hasAcademicBenchmarks: boolean;
  options: Options;
  chartObject: Chart;

  legends: Legend[] = [];
  npvLegends: Legend[] = [getPercentNpvLegendForTrend()];
  benchmarkLegend: Legend = getBenchmarkLegendForNpv();
  countsLegends: Legend[] = deepCopyOf(countLegendsForNpvTrend);

  @select(['data', 'newPatientVisitTrendData'])
  private readonly newPatientVisitTrendEntry$: Observable<NewPatientVisitTrendEntry[]>;
  private newPatientVisitTrendEntry: NewPatientVisitTrendEntry[];

  npvMonthDataChartSubscription: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit(): void {
    let benchmarkData: number[] = [];
    this.npvMonthDataChartSubscription = combineLatest([
      this.benchmarkPercentile$.pipe(distinctUntilChanged((type1, type2) => _.isEqual(type1, type2))),
      this.benchmarkOption$,
      this.patientVisitCounts$,
      this.newPatientVisitTrendEntry$,
      this.viewCommunityBenchmarks$,
      this.previousPatientVisitCounts$
    ]).subscribe((
      [benchmarkPercentileFromStore, benchmarkOption, showPatientVisitCounts, monthNpv, viewCommunityBenchmarks, showPreviousVisitCounts]:
        [BenchmarkPercentile, BenchmarkOption, boolean, NewPatientVisitTrendEntry[], boolean, boolean]) => {
      this.viewCommunityBenchmarks = viewCommunityBenchmarks;
      this.benchmarkPercentile = benchmarkPercentileFromStore === BenchmarkPercentile.Percentile65th
        ? BenchmarkPercentile.Mean : benchmarkPercentileFromStore;
      this.benchmarkOption = benchmarkOption;
      this.newPatientVisitTrendEntry = monthNpv.map(npv => {
        return {
          ...npv,
          countOfExistingPatientVisits:
            checkForNulls(npv.countOfTotalPatientVisits) - checkForNulls(npv.countOfNewPatientVisits)
        };
      });
      let academicBenchmarks: NewPatientVisitTrendEntry[] = [];
      const academicArray = this.showExtendedBenchmarkOptions
        ? [BenchmarkOption.Academic, BenchmarkOption.TelehealthAcademic, BenchmarkOption.InPersonAcademic]
        : [BenchmarkOption.Academic];
      academicArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
        BenchmarkPercentilesForNpv.forEach(bt => {
          academicBenchmarks = academicBenchmarks.concat(monthNpv.filter(x => {
            const value = x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`];
            return hasNonZeroValue(value);
          }));
        });
      });
      let communityBenchmarks: NewPatientVisitTrendEntry[] = [];
      const communityArray = this.showExtendedBenchmarkOptions ? [BenchmarkOption.Community, BenchmarkOption.TelehealthCommunity,
        BenchmarkOption.InPersonCommunity] : [BenchmarkOption.Community];
      communityArray.map(o => getDesignatedNpvBenchmarkObject(o)).forEach(benchmarkObject => {
        BenchmarkPercentilesForNpv.forEach(bt => {
          communityBenchmarks = communityBenchmarks.concat(monthNpv.filter(x => {
            const value = x[benchmarkObject][`benchmark${readableNameOfColumnDef(bt)}`];
            return hasNonZeroValue(value);
          }));
        });
      });
      this.hasCommunityBenchmarks = communityBenchmarks.length > 0;
      this.hasAcademicBenchmarks = academicBenchmarks.length > 0;
      this.hasBenchmarks = this.viewCommunityBenchmarks ? this.hasCommunityBenchmarks : this.hasAcademicBenchmarks;
      updateCommunityBenchmarkAlertStatus(
        this.hasCommunityBenchmarks,
        this.hasAcademicBenchmarks,
        this.ngRedux,
        this.showProgressBar
      );
      const dateRange = this.ngRedux.getState().filters.dateRange;
      this.previousDateRange = showPreviousVisitCounts ? getPreviousYearDateRange(dateRange) : '';
      const previousMonthNewPatientVisits = this.newPatientVisitTrendEntry.map(visits =>
        roundToNumber(visits.previous.newPatientVisitsPercentage, 1));
      if (this.newPatientVisitTrendEntry.length) {
        const monthNewPatientVisits = this.newPatientVisitTrendEntry.map(visits =>
          roundToNumber(visits.newPatientVisitsPercentage, 1));
        this.showPatientVisitCounts = showPatientVisitCounts;
        this.showPreviousPatientVisitCounts = showPreviousVisitCounts;
        const designatedBenchmarkObject = getDesignatedNpvBenchmarkObject(this.benchmarkOption);
        benchmarkData = this.newPatientVisitTrendEntry.map((npv: NewPatientVisitTrendEntry) =>
          roundToNumber(checkForNulls(
            npv[designatedBenchmarkObject][`benchmark${readableNameOfColumnDef(this.benchmarkPercentile)}`]) * 100, 1));
        const categories =
          this.newPatientVisitTrendEntry.map((visit) =>
            toAbbreviatedMonthName(visit.month.toString()) + ' ' + visit.year);
        const isScrolled: boolean = categories.length > DEFAULT_MAX_NUMBER_SCROLLBARS;
        this.legends = this.getLegends();
        const leftSideYAxis: YAxisOptions[] = [
          {
            title: {
              text: '% New Patients'
            },
            max: Math.max(...monthNewPatientVisits, ...benchmarkData, ...previousMonthNewPatientVisits),
            labels: {
              formatter: function () {
                return this.value + '%';
              }
            }
          }
        ];

        const rightSideYAxis: YAxisOptions[] = this.showPatientVisitCounts ? [
          {
            title: {
              text: '# New and Total Patients'
            },
            opposite: true,
            max: Math.max(...this.newPatientVisitTrendEntry.map(npv => npv.countOfTotalPatientVisits || 0)),
            labels: {
              formatter: function () {
                return abbreviateAxisValue(this.value);
              }
            }
          }
        ] : [];

        const newPatientVisitsCountsForChart = this.newPatientVisitTrendEntry.map((newPatientVisit) => {
          return {drilldown: '', y: newPatientVisit.countOfNewPatientVisits};
        });

        const existPatientVisitsCountsForChart = this.newPatientVisitTrendEntry.map((newPatientVisit) => {
          return {drilldown: '', y: newPatientVisit.countOfExistingPatientVisits || 0};
        });

        this.previousPatientVisitCountSeries = this.showPreviousPatientVisitCounts ? [

          {
            name: 'Previous Date Range % New Patients',
            yAxis: 0,
            data: previousMonthNewPatientVisits.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
              createEmptyValues(previousMonthNewPatientVisits, DEFAULT_MAX_NUMBER_SCROLLBARS) :
              previousMonthNewPatientVisits,
            type: ChartType.LINE,
            colorIndex: HighChartColorIndex.PURPLE,
            pointWidth: DEFAULT_POINT_WIDTH,
            stack: 0,
            zIndex: 1,
            marker: {
              symbol: ChartMarkerSymbol.CIRCLE
            },
            tooltip: {
              pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
              valueDecimals: 0
            }
          }
        ] : [];


        this.patientVisitCountSeries = this.showPatientVisitCounts ? [
          {
            name: '# Total Patients',
            yAxis: 1,
            type: ChartType.COLUMN,
            pointWidth: DEFAULT_POINT_WIDTH,
            data: existPatientVisitsCountsForChart.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
              createEmptyValues(existPatientVisitsCountsForChart, DEFAULT_MAX_NUMBER_SCROLLBARS) :
              existPatientVisitsCountsForChart,
            stacking: 'normal',
            colorIndex: HighChartColorIndex.PURPLE,
            stack: 3,
            zIndex: 0,
            tooltip: {
              pointFormat: '<span class="highcharts-color-13">\u25CF</span> {series.name}: <b>{point.total:,.0f}</b><br/>',
              valueDecimals: 0
            }
          },
          {
            name: '# New Patients',
            yAxis: 1,
            type: ChartType.COLUMN,
            pointWidth: DEFAULT_POINT_WIDTH,
            data: newPatientVisitsCountsForChart.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
              createEmptyValues(newPatientVisitsCountsForChart, DEFAULT_MAX_NUMBER_SCROLLBARS) :
              newPatientVisitsCountsForChart,
            stacking: 'normal',
            colorIndex: HighChartColorIndex.TEAL,
            stack: 3,
            zIndex: 0,
            tooltip: {
              pointFormat: '<span class="highcharts-color-10">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>',
              valueDecimals: 0
            }
          }
        ] : [];

        const barChartSeriesOptions: any[] = [
          {
            name: '% New Patients',
            yAxis: 0,
            data: monthNewPatientVisits.length < DEFAULT_MAX_NUMBER_SCROLLBARS ?
              createEmptyValues(monthNewPatientVisits, DEFAULT_MAX_NUMBER_SCROLLBARS) :
              monthNewPatientVisits,
            type: ChartType.LINE,
            colorIndex: HighChartColorIndex.GREEN,
            pointWidth: DEFAULT_POINT_WIDTH,
            stack: 0,
            zIndex: 1,
            tooltip: {
              pointFormat: '<span class="highcharts-color-12">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
              valueDecimals: 0
            }
          },
          {
            name: getBenchmarkSeriesName(this.benchmarkOption, this.showExtendedBenchmarkOptions, this.benchmarkPercentile),
            colorIndex: HighChartColorIndex.GREY,
            yAxis: 0,
            data: benchmarkData.slice(),
            type: ChartType.LINE,
            stack: 1,
            zIndex: 1,
            marker: {
              symbol: ChartMarkerSymbol.SQUARE
            },
            tooltip: {
              pointFormat: '<span class="highcharts-color-2">\u25CF</span> {series.name}: <b>{point.y:.1f}</b><br/>',
              valueDecimals: 0
            }
          }

        ];

        this.options = {
          scrollbar: {
            enabled: isScrolled,
            showFull: false
          },
          title: {
            text: ''
          },
          exporting: {
            enabled: false
          },
          series: concat(barChartSeriesOptions, this.patientVisitCountSeries, this.previousPatientVisitCountSeries),
          legend: {
            enabled: false
          },
          tooltip: {
            shared: true,
            valueDecimals: 1
          },
          chart: {
            type: ChartType.COLUMN,
            backgroundColor: 'transparent',
            width: calculateChartWidth(DEFAULT_POINT_WIDTH, this.snapshotBarCount, DEFAULT_POINT_PADDING),
            height: CHART_HEIGHT,
            styledMode: true,
            marginRight: 100
          },
          credits: {
            enabled: false
          },
          xAxis: {
            categories: categories,
            crosshair: {
              color: GraphColors.hoverBackground
            },
            max: isScrolled ? DEFAULT_MAX_NUMBER_SCROLLBARS - 1 : categories.length - 1,
            labels: {
              rotation: 45
            }
          },
          yAxis: concat(leftSideYAxis, rightSideYAxis)
        };

        this.chartObject = new StyledChart(this.options);
      }
    });
  }

  private getLegends(): Legend[] {
    let legends = deepCopyOf(this.npvLegends);
    if (this.showPatientVisitCounts) {
      legends = legends.concat(this.countsLegends);
    } else if (this.showPreviousPatientVisitCounts) {
      legends.push(getPreviousDateRangeLegendForNpvTrend(this.previousDateRange));
    }
    legends.push(this.benchmarkLegend);
    return legends;
  }

  ngOnDestroy(): void {
    this.npvMonthDataChartSubscription?.unsubscribe();
  }
}
