import {Injectable, InjectionToken} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.local';
import {UserSecurity} from '../shared/models';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store/IAppState';

export const UserSecurityServiceToken = new InjectionToken<UserSecurityService>('UserSecurityService');

export interface UserSecurityService {
  doesUserHaveAccessToMember(memberKey: number): Observable<boolean>;
  getUserSecurity(): Observable<UserSecurity[]>;
}

@Injectable()
export class HttpUserSecurityService implements UserSecurityService {

  constructor(
    private readonly ngRedux: NgRedux<IAppState>,
    private readonly httpClient: HttpClient) { }

  doesUserHaveAccessToMember(memberKey: number): Observable<boolean> {
    const securityData: UserSecurity[] =  this.ngRedux.getState().data.userSecurityData;
    if (securityData.length === 0) {
      return of(false);
    }
    return of(securityData.filter(x => x.memberKey === memberKey || x.memberKey === 0).length > 0);
  }

  getUserSecurity(): Observable<UserSecurity[]> {
    const url = `${environment.api}/api/userSecurity`;
    return this.httpClient.get<UserSecurity[]>(url);
  }

}
