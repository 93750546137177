import {Component, Inject, OnInit} from '@angular/core';
import {SettingType} from '../shared/helpers';
import {MatDialogRef} from '@angular/material/dialog';
import {NgRedux} from '@angular-redux/store';
import {Data, IAppState, UserPreference} from '../store/IAppState';
import {UserPreferenceService, UserPreferenceServiceToken} from '../services/user-preference.service';
import {DefaultPageObject, OntologyNode} from '../shared/models';
import {Router} from '@angular/router';
import {getOntologyData} from '../shared/localStoragehelper';
import {DateRangeOption} from '../shared/enums';
import {DefaultPage} from '../shared/enums';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  showDateRange: boolean;
  showMyView: boolean;
  showBenchmarkPercentile: boolean;
  showCfteAdjustedWrvus: boolean;
  // For the date range
  hasRecentMonth: boolean;
  recentMonth: number;
  showPast12Months: boolean;
  showYearToDate: boolean;
  showRecentMonth: boolean;
  showPast12MonthsOfData: boolean;
  dateRangeText: string;
  defaultSettingsTab = SettingType.dateRange;
  selectedDateRangeOption: DateRangeOption;
  existingUserPreferences: UserPreference;
  existingPreferredNodeName = '';
  userEmail: string;
  // For the my view
  ontology: OntologyNode[] = [];
  showHierarchyList = false;
  showDefaultPageList = false;
  isRevenueMember = false;
  selectedNodePath: string;
  hierarchyList: OntologyNode[] = [];
  expansionNodePathArray: string[] = [];
  currentDefault: DefaultPageObject;
  defaultPageList: DefaultPageObject[] = [
    {name: 'Overview', type: DefaultPage.Overview},
    {name: 'wRVU', type: DefaultPage.Wrvus},
    {name: 'NPV', type: DefaultPage.NPV},
    {name: 'E&M', type: DefaultPage.EandM}
  ];
  isUserHasMultipleMembers: boolean;
  selectedMemberDesc: string;
  isDefaultMemberSelected = false;

  private past12MonthsText = '* Past 12 Months refers to the last 12 month period from last month. For example, the ' +
    'past 12 months for a practice in 05/2019 would include data from 05/2018 - 04/2019.';
  private yearToDateText = '* Year To Date refers to the period beginning the first day of the current calendar year up' +
    ' to the end of last month. For example, the YTD for a practice in 05/2019 would include data from 01/2019 - 04/2019.';
  private recentMonthText = '* Recent Month refers to the most recent month with data. For example, if a practice last submitted ' +
    'data in 03/2019, the recent month for that practice in 05/2019 would be 03/2019.';
  private past12MonthsOfDataText = '* Past 12 Months of data refers to the last 12 months from the latest month that has data loaded. ' +
    'For example if the latest month of data loaded is 12/2019 and it is 02/2020, data would include 1/1/2019 - 12/31/2019.';

  data: Data;
  DateRangeOption = DateRangeOption;

  constructor(
    public dialogRef: MatDialogRef<UserSettingsComponent>,
    @Inject(UserPreferenceServiceToken) private readonly userPreferenceService: UserPreferenceService,
    private router: Router,
    private ngRedux: NgRedux<IAppState>) {
  }

  ngOnInit() {
    this.hasRecentMonth = this.ngRedux.getState().display.recentMonth > 0;
    this.recentMonth = this.ngRedux.getState().display.recentMonth;
    this.data = this.ngRedux.getState().data;
    this.isRevenueMember = this.ngRedux.getState().display.isRevenueMember;
    this.defaultPageList = this.populateDefaultPages();
    this.setSettingsTab(this.defaultSettingsTab);
    this.userEmail = this.ngRedux.getState().data.userProfile.email;
    this.isUserHasMultipleMembers = this.ngRedux.getState().data.members.members.length > 1;
    this.selectedMemberDesc = this.ngRedux.getState().data.selectedMemberData.memberDesc;
    this.existingUserPreferences = this.ngRedux.getState().userPreferences;
    this.isDefaultMemberSelected = this.existingUserPreferences.isDefault;
    // @ts-ignore
    const existingDefaultPage: DefaultPage = this.existingUserPreferences.defaultPage;
    this.currentDefault = this.defaultPageList.find(page => page.type === existingDefaultPage) ?? this.currentDefault;
    const defaultDateRangeOption = this.existingUserPreferences.dateRangeOption ?? DateRangeOption.Past12MonthsOfData;
    this.setDateRange(defaultDateRangeOption);

    const ontology = getOntologyData().ontologyHierarchy;
    if (ontology && ontology.length > 0) {
      this.ontology = ontology;
      this.initializeHierarchy();
      if (this.existingUserPreferences.nodePath) {
        this.selectedNodePath = this.existingUserPreferences.nodePath;
        this.getNodeNameFromNodePath(ontology, this.existingUserPreferences.nodePath);
      } else {
        this.selectedNodePath = ontology[0].nodePath || '';
      }
    }
  }

  private populateDefaultPages(): DefaultPageObject[] {
    const pages = [
      {name: 'Overview', type: DefaultPage.Overview},
      {name: 'wRVU', type: DefaultPage.Wrvus},
      {name: 'NPV', type: DefaultPage.NPV},
      {name: 'E&M', type: DefaultPage.EandM},
      {name: 'CFP', type: DefaultPage.CFP}
    ];
    if (this.isRevenueMember) {
      pages.push({name: 'Collections', type: DefaultPage.Collections});
    }
    return pages;
  }

  populateHierarchyList(ontology: OntologyNode[]) {
    ontology.forEach(item => {
      if (this.shouldBeDisplayed(item.nodePath)) {
        this.hierarchyList.push(item);
      }
      if (item.children && item.children.length > 0) {
        this.populateHierarchyList(item.children);
      }
    });
  }

  toggleShowHierarchyList() {
    this.showHierarchyList = !this.showHierarchyList;
    if (this.showHierarchyList) {
      this.showDefaultPageList = false;
    }
  }

  toggleShowDefaultPageList() {
    this.showDefaultPageList = !this.showDefaultPageList;
    if (this.showDefaultPageList) {
      this.showHierarchyList = false;
    }
  }

  getHierarchicalDepth(nodePath: string): number {
    return nodePath.split('\\').length;
  }

  onHierarchyItemSelected(index: number, ontology: OntologyNode[], hierarchyItem: OntologyNode) {
    if (this.hierarchyList[index].children.length > 0) {
      if (this.isExpanded(this.hierarchyList[index].nodePath)) {
        this.expansionNodePathArray = this.hierarchyList[index].nodePath.split('\\');
        this.expansionNodePathArray.pop();
      } else {
        this.expansionNodePathArray = this.hierarchyList[index].nodePath.split('\\');
      }
      this.hierarchyList = [];
      this.populateHierarchyList(ontology);
    } else {
      this.expansionNodePathArray = this.hierarchyList[index].nodePath.split('\\');
    }
    this.selectedNodePath = hierarchyItem.nodePath;
    this.getNodeNameFromNodePath(this.ontology, this.expansionNodePathArray.join('\\'));
  }

  setExpansionNodePathArray(expansionNodePath: string) {
    this.expansionNodePathArray = expansionNodePath.split('\\');
  }

  isExpanded(nodePath: string): boolean {
    return this.shouldBeDisplayed(nodePath + '\\a');
  }

  private shouldBeDisplayed(nodePath: string): boolean {
    const parentNodeArray = nodePath.split('\\');
    parentNodeArray.pop();
    if (parentNodeArray.length <= this.expansionNodePathArray.length) {
      for (let x = 0; x < parentNodeArray.length; x++) {
        if (parentNodeArray[x] !== this.expansionNodePathArray[x]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  setSettingsTab(settingType: SettingType) {
    this.showDateRange = false;
    this.showMyView = false;
    this.showBenchmarkPercentile = false;
    this.showCfteAdjustedWrvus = false;
    switch (settingType) {
      case SettingType.dateRange:
        this.showDateRange = true;
        break;
      case SettingType.myView:
        this.showMyView = true;
        break;
      case SettingType.benchmarkPercentiles:
        this.showBenchmarkPercentile = true;
        break;
      case SettingType.cFTEadjustedWrvus:
        this.showCfteAdjustedWrvus = true;
        break;
    }
  }

  setDateRange(option: DateRangeOption) {
    this.selectedDateRangeOption = option;
    this.showPast12Months = false;
    this.showYearToDate = false;
    this.showRecentMonth = false;
    this.showPast12MonthsOfData = false;
    this.dateRangeText = '';
    switch (option) {
      case DateRangeOption.Past12Months:
        this.showPast12Months = true;
        this.dateRangeText = this.past12MonthsText;
        break;
      case DateRangeOption.YearToDate:
        this.showYearToDate = true;
        this.dateRangeText = this.yearToDateText;
        break;
      case DateRangeOption.RecentMonth:
        this.showRecentMonth = true;
        this.dateRangeText = this.recentMonthText;
        break;
      case DateRangeOption.Past12MonthsOfData:
        this.showPast12MonthsOfData = true;
        this.dateRangeText = this.past12MonthsOfDataText;
        break;
    }
  }

  saveSettings() {
    this.userPreferenceService.saveUserPreferences({
      ...this.existingUserPreferences,
      memberKey: this.ngRedux.getState().filters.memberKey,
      dateRangeOption: this.selectedDateRangeOption,
      emailId: this.userEmail,
      isDefault: this.isDefaultMemberSelected,
      defaultPage: this.currentDefault.type,
      nodePath: this.selectedNodePath,
    }).subscribe((userPreference: UserPreference) => {
      const userPreferencesFromStore = this.ngRedux.getState().data.userPreferenceData;
      if (userPreferencesFromStore.length === 0) {
        userPreferencesFromStore.push(userPreference);
      } else {
        const existingPreference = userPreferencesFromStore.find(x => x.memberKey === userPreference.memberKey);
        if (existingPreference) {
          userPreferencesFromStore.splice(userPreferencesFromStore.indexOf(existingPreference), 1);
        }
        userPreferencesFromStore.push(userPreference);
      }
      this.userPreferenceService.getAndApplyUserPreferences(this.ngRedux.getState().data.selectedMemberData, userPreference, false);
    });
    this.dialogRef.close();
  }

  getNodeNameFromNodePath(ontology: OntologyNode[], nodePath: string) {
    ontology.forEach(o => {
      if (o.nodePath === nodePath) {
        this.existingPreferredNodeName = o.nodeName;
        return;
      }
      if (o.nodePath === nodePath.substring(0, o.nodePath.length) && o.children.length >= 1) {
        this.getNodeNameFromNodePath(o.children, nodePath);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  setDefaultPage(page: any) {
    this.currentDefault = page;
  }

  isDefaultPage(page: any): boolean {

    return page === this.currentDefault;
  }

  initializeHierarchy() {
    this.setExpansionNodePathArray(this.ontology[0].nodePath);
    this.hierarchyList = [];
    this.populateHierarchyList(this.ontology[0].children);
  }

  collapseHierarchy() {
    this.initializeHierarchy();
    this.selectedNodePath = this.ontology[0].nodePath || '';
    this.existingPreferredNodeName = this.ontology[0].nodeName;
  }

  levelHierarchyDynamicID() {
    return this.showHierarchyList ? '-expanded' : '';
  }

  toggleDefaultMember(event: MouseEvent) {
    event.preventDefault();
    this.isDefaultMemberSelected = !this.isDefaultMemberSelected;
  }
}
