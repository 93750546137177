import { Injectable, InjectionToken } from '@angular/core';
import { FilterCriteria } from '../../store/IAppState';
import { Observable } from 'rxjs';
import { ZipCodeNewPatientVisits} from '../../shared/models';
import { environment } from '../../../environments/environment.local';
import { HttpClient, HttpParams } from '@angular/common/http';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const ZipCodeNewPatientVisitServiceToken =
  new InjectionToken<ZipCodeNewPatientVisitService>('ZipCode New Patient Visit Service');

export interface ZipCodeNewPatientVisitService {
  getZipCodeNewPatientVisitData(filter: FilterCriteria): Observable<ZipCodeNewPatientVisits[]>;
}

@Injectable()
export class HttpZipCodeNewPatientVisitService implements ZipCodeNewPatientVisitService {
  constructor(private httpClient: HttpClient) {}

  getZipCodeNewPatientVisitData(filter: FilterCriteria): Observable<ZipCodeNewPatientVisits[]> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/newPatientVisitsByZipCode`;
    return this.httpClient.post<ZipCodeNewPatientVisits[]>(
      url,
      requestBody,
      { params: new HttpParams().set('memberKey', filter.memberKey.toString()) },
    );
  }
}
