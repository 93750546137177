import {Component, Inject, Input, OnChanges} from '@angular/core';
import {getBaseColumnFromDataTableColumn, Page} from '../shared/helpers';
import {NgRedux, select} from '@angular-redux/store';
import {BaseColumn, IAppState} from '../store/IAppState';
import {Observable} from 'rxjs';
import {FeatureToggleSetting} from '../shared/models';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {BenchmarkPercentile} from '../shared/benchmark-types';
import {AppAction} from '../store/actions';
import {hasValue} from '../shared/null-helpers';
import {ColumnType} from '../shared/enums';
import {DataTableColumns, SelectableColumn} from '../shared/data-table-columns';

@Component({
  selector: 'app-column-selection',
  templateUrl: './column-selection.component.html',
  styleUrls: ['./column-selection.component.scss']
})
export class ColumnSelectionComponent implements OnChanges {

  @Input() currentPage: Page;
  @Input() originalColumns: DataTableColumns[] = [];
  @Input() displayedColumnDefs: any[] = [];
  @Input() reducerAction?: (columns: BaseColumn[]) => AppAction;
  @Input() showBenchmarks = true;
  @Input() showCfteAdjWrvus = true;

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  showAdditionalColumns = false;
  indeterminateColumns = false;
  allColumnsSelected = false;
  benchmarkPercentile: BenchmarkPercentile;
  BENCHMARK = ColumnType.BENCHMARK;
  VARIANCE = ColumnType.VARIANCE;
  IMPUTED = ColumnType.IMPUTED;
  CFTE_ADJ_WRVU = ColumnType.CFTE_ADJ_WRVU;
  PREVIOUS = ColumnType.PREVIOUS;
  DIFFERENCE = ColumnType.DIFFERENCE;
  allColumns: SelectableColumn[] = this.createColumns();
  initialColumns: SelectableColumn[];

  constructor(private ngRedux: NgRedux<IAppState>,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService) {
  }

  ngOnChanges() {
    this.allColumns = this.createColumns();
    this.updateColumns();
    this.determineCheckedColumns();
  }

  createColumns(): SelectableColumn[] {
    const columnSet: SelectableColumn[] = [];
    this.originalColumns.forEach(column => {
      if (column.header && !column.primaryColumn && !(column.columnType === ColumnType.OPEN_WINDOW)) {
        columnSet.push({
          header: column.header,
          columnDef: column.columnDef,
          columnType: column.columnType,
          selected: this.displayedColumnDefs?.includes(column.columnDef)
        });
      }
    });
    return columnSet;
  }

  showOrHideColumns() {
    this.showAdditionalColumns = !this.showAdditionalColumns;

    if (this.showAdditionalColumns) {
      this.initialColumns = this.allColumns.map(col => ({...col}));
    } else {
      const addedColumns: string[] = [], removedColumns: string[] = [];

      this.allColumns.forEach((column, i) => {
        if (column.selected && !this.initialColumns[i].selected) {
          addedColumns.push(column.header);
        } else if (!column.selected && this.initialColumns[i].selected) {
          removedColumns.push(column.header);
        }
      });

      if (this.allColumnsSelected) {
        this.sendGoogleAnalyticsInfo('All Columns', true);
      }

      addedColumns.forEach(columnName => this.sendGoogleAnalyticsInfo(columnName, true));
      removedColumns.forEach(columnName => this.sendGoogleAnalyticsInfo(columnName, false));

      this.initialColumns = [];
    }
  }

  sendGoogleAnalyticsInfo(columnName: string, adding: boolean) {
    const action = adding ? 'Add column' : 'Remove column';
    this.analyticsService.handleGoogleAnalytics(this.currentPage, columnName, action);
  }

  selectOrDeselectAllColumns(event: MouseEvent): void {
    event.preventDefault();
    this.indeterminateColumns = false;
    this.allColumnsSelected = !this.allColumnsSelected;
    if (this.allColumnsSelected) {
      this.selectAllColumns();
    } else {
      this.deselectAllColumns();
    }
    this.determineCheckedColumns();
    this.updateColumns();
  }

  selectAllColumns(): void {
    this.allColumns.forEach(c => c.selected = true);
    this.allColumnsSelected = true;
  }

  deselectAllColumns(): void {
    this.allColumns.forEach(c => c.selected = false);
    this.allColumnsSelected = false;
  }

  determineCheckedColumns(): void {
    let selectedAllColumns = true;
    let indeterminateColumns = false;
    this.allColumns.forEach(c => {
      if (c.selected) {
        indeterminateColumns = true;
      } else {
        selectedAllColumns = false;
      }
    });
    if (selectedAllColumns) {
      indeterminateColumns = false;
    }
    this.allColumnsSelected = selectedAllColumns;
    this.indeterminateColumns = indeterminateColumns;
  }

  updateColumns(): void {
    if (this.reducerAction) {
      let displayedColumns: BaseColumn[] = this.allColumns
        .filter(col => col.selected)
        .map(col => hasValue(col.columnType)
          ? {columnDef: col.columnDef, header: col.header, columnType: col.columnType}
          : {columnDef: col.columnDef, header: col.header});
      displayedColumns = this.originalColumns.filter(or_col => or_col.primaryColumn)
        .map(col => {
          return getBaseColumnFromDataTableColumn(col);
        }).concat(displayedColumns);

      this.ngRedux.dispatch(this.reducerAction(displayedColumns));
    }

  }

  selectColumn = (selectedColumn: SelectableColumn, event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    selectedColumn.selected = !selectedColumn.selected;
    this.determineCheckedColumns();
    this.updateColumns();
  };
}
