import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {INITIAL_STATE, SummaryData} from '../../../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {
  getPreviousYearDateRange,
  getPreviousYtdDateRange,
  getRecentMonthDate,
  getSelectedDateRange,
  getYtdDateRange
} from '../../../shared/helpers';
import {MatTableDataSource} from '@angular/material/table';
import {formatNumberToWholeNumber, getPercentage, roundTo} from '../../../productivity-summary/number-formatter';
import {DateRange, NewPatientVisitSummary} from '../../../shared/models';
import * as _ from 'lodash';
import {BenchmarkPercentile, extendedBenchmarkOptions, originalBenchmarkOptions, readableNameOf} from '../../../shared/benchmark-types';
import {npvSummaryColumns} from '../../../shared/data-table-columns';
import {BenchmarkPercentilesForNpv} from '../../../shared/BenchmarkColumns';
import {getSummaryBenchmarkFieldForBenchmarkCombination} from '../npv-helpers';
import {BenchmarkOption} from '../../../shared/enums';

@Component({
  selector: 'app-new-patient-visits-summary-data-table',
  templateUrl: './new-patient-visits-summary-data-table.component.html',
  styleUrls: ['./new-patient-visits-summary-data-table.component.scss']
})
export class NewPatientVisitsSummaryDataTableComponent implements OnInit, OnDestroy {

  @Input() showExtendedBenchmarkOptions = false;

  @select(['benchmark'])
  private readonly benchmarkPercentile$: Observable<BenchmarkPercentile>;

  @select(['filters', 'dateRange'])
  private readonly dateRange$: Observable<DateRange>;

  @select(['data', 'summaryNewPatientVisitData'])
  private readonly summaryNewPatientVisitData$: Observable<SummaryData<NewPatientVisitSummary>>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'emNpvBenchmarkOption'])
  private readonly emNpvBenchmarkOption$: Observable<BenchmarkOption>;

  dataSource: MatTableDataSource<any>;
  benchmarkPercentile: BenchmarkPercentile;
  data: NpvSummaryData[];
  dateRange: string;
  previousDateRange: string;
  recentMonthDate: string;
  previousYearRecentMonthDate: string;
  ytdDateRange: string;
  previousYtdDateRange: string;
  summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>;
  showProgressBar = true;
  benchmarkOption: BenchmarkOption;
  viewCommunityBenchmarks: boolean;
  npvSummaryDataSubscription: Subscription;
  npvSummaryColumns = npvSummaryColumns;
  displayedColumns = this.npvSummaryColumns.map(col => col.columnDef);

  constructor() {
  }

  ngOnInit() {
    this.npvSummaryDataSubscription = combineLatest([
      this.benchmarkPercentile$,
      this.dateRange$,
      this.summaryNewPatientVisitData$,
      this.viewCommunityBenchmarks$,
      this.emNpvBenchmarkOption$])
      .subscribe(([bchmkPercentileFromStore, dateRange, summaryData, viewCommunityBenchmarks, emNpvBenchmarkOption]:
                    [BenchmarkPercentile, DateRange, SummaryData<NewPatientVisitSummary>, boolean, BenchmarkOption]) => {
        this.benchmarkOption = emNpvBenchmarkOption ?? (viewCommunityBenchmarks ? BenchmarkOption.Community : BenchmarkOption.Academic);
        this.showProgressBar = _.isEqual(summaryData, INITIAL_STATE.data.summaryNewPatientVisitData);
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.benchmarkPercentile = bchmkPercentileFromStore === BenchmarkPercentile.Percentile65th
          ? BenchmarkPercentile.Mean : bchmkPercentileFromStore;
        this.dateRange = getSelectedDateRange(dateRange);
        this.previousDateRange = getPreviousYearDateRange(dateRange);
        this.recentMonthDate = getRecentMonthDate(summaryData.recentMonth);
        this.previousYearRecentMonthDate = getRecentMonthDate(summaryData.previousYearRecentMonth);
        this.ytdDateRange = getYtdDateRange(summaryData.yearToDate);
        this.previousYtdDateRange = getPreviousYtdDateRange(summaryData.previousYearToDate);
        this.summaryNewPatientVisitData = summaryData;
        this.data = [];
        if (this.summaryNewPatientVisitData) {
          this.data = populateSummaryData(this.summaryNewPatientVisitData, this.viewCommunityBenchmarks, this.benchmarkPercentile,
            this.showExtendedBenchmarkOptions, this.benchmarkOption);
        }
        this.dataSource = new MatTableDataSource<any>(this.data.slice());
      });
  }

  ngOnDestroy(): void {
    this.npvSummaryDataSubscription?.unsubscribe();
  }

  getBenchmarkSummaryRow(): NpvSummaryData {
    const benchmarkOptions = this.showExtendedBenchmarkOptions ? extendedBenchmarkOptions : originalBenchmarkOptions;
    const benchmarkHelperObject = benchmarkOptions.find(b => b.value === this.benchmarkOption) ?? benchmarkOptions[0];
    const benchmarkTitle = `% New Patients ${benchmarkHelperObject.name} Benchmark ${readableNameOf(this.benchmarkPercentile)}${this.benchmarkPercentile === BenchmarkPercentile.Mean ? '' : ' Percentile'}`;

    const benchmarkField = this.getBenchmarkField(benchmarkHelperObject);
    return getBenchmarkDataByPercentage(this.summaryNewPatientVisitData, benchmarkTitle, benchmarkField, 1);
  }

  private getBenchmarkField(benchmarkHelperObject: any) {
    return this.benchmarkPercentile === BenchmarkPercentile.Mean ? benchmarkHelperObject.meanField :
      benchmarkHelperObject.percentilePrefix + readableNameOf(this.benchmarkPercentile);
  }
}

function populateSummaryData(
  summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>,
  viewCommunityBenchmarks: boolean,
  benchmarkPercentile: BenchmarkPercentile,
  showExtendedBenchmarkOptions: boolean,
  benchmarkOption: BenchmarkOption): NpvSummaryData[] {
  return [
    getSummaryDataByPercentage(summaryNewPatientVisitData, '% New Patient Visits', 'newPatientVisitsPercentage', 1),
    getBenchmarkSummaryRow(summaryNewPatientVisitData, viewCommunityBenchmarks, benchmarkPercentile,
      showExtendedBenchmarkOptions, benchmarkOption),
    getSummaryDataByWholeNumber(summaryNewPatientVisitData, '# of New Patients', 'countOfNewPatientVisits'),
    getSummaryDataByWholeNumber(summaryNewPatientVisitData, '# of Total Patients', 'countOfTotalPatientVisits')];
}

export function populateSummaryDataForAllBenchmarks(summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>,
                                                    viewCommunityBenchmarks: boolean, showExtendedBenchmarkOptions: boolean,
                                                    benchmarkOption: BenchmarkOption): NpvSummaryData[] {
  const data: NpvSummaryData[] = [];
  data.push(getSummaryDataByPercentage(summaryNewPatientVisitData, '% New Patient Visits', 'newPatientVisitsPercentage', 1));
  BenchmarkPercentilesForNpv.forEach(b => data.push(
    getBenchmarkSummaryRow(summaryNewPatientVisitData, viewCommunityBenchmarks, b, showExtendedBenchmarkOptions, benchmarkOption))
  );
  data.push(getSummaryDataByWholeNumber(summaryNewPatientVisitData, '# of New Patients', 'countOfNewPatientVisits'));
  data.push(getSummaryDataByWholeNumber(summaryNewPatientVisitData, '# of Total Patients', 'countOfTotalPatientVisits'));
  return data;
}

export function populateSummaryDataForAllBenchmarksForExcel(
  summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>,
  viewCommunityBenchmarks: boolean,
  showExtendedBenchmarkOptions: boolean,
  benchmarkOption: BenchmarkOption): NpvSummaryData[] {
  const data: NpvSummaryData[] = [];
  data.push(getSummaryDataByPercentage(summaryNewPatientVisitData, '% New Patient Visits', 'newPatientVisitsPercentage', 1));
  data.push(getSummaryDataByWholeNumber(summaryNewPatientVisitData, '# of New Patients', 'countOfNewPatientVisits'));
  data.push(getSummaryDataByWholeNumber(summaryNewPatientVisitData, '# of Total Patients', 'countOfTotalPatientVisits'));
  BenchmarkPercentilesForNpv.forEach(b => data.push(
    getBenchmarkSummaryRow(summaryNewPatientVisitData, viewCommunityBenchmarks, b, showExtendedBenchmarkOptions, benchmarkOption))
  );
  return data;
}

function isValid(value: any, field: string): boolean {
  if (value !== undefined && value !== null &&
    value[field] !== undefined && value[field] !== null) {
    return true;
  }
  return false;
}

export function getSummaryDataByPercentage(summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>, title: string,
                                           field: string, fractionOfDigits: number): NpvSummaryData {
  return {
    metric: title,
    metricSelectedDateRange: isValid(summaryNewPatientVisitData.selectedDateRange, field) ?
      roundTo(summaryNewPatientVisitData.selectedDateRange[field], fractionOfDigits) + '%' : '-',
    metricPreviousYearSelectedDateRange:
      isValid(summaryNewPatientVisitData.previousYearSelectedDateRange, field) ?
        roundTo(summaryNewPatientVisitData.previousYearSelectedDateRange[field], fractionOfDigits) + '%' : '-',
    metricYearToDate: isValid(summaryNewPatientVisitData.yearToDate, field) ?
      roundTo(summaryNewPatientVisitData.yearToDate[field], fractionOfDigits) + '%' : '-',
    metricPreviousYearToDate: isValid(summaryNewPatientVisitData.previousYearToDate, field) ?
      roundTo(summaryNewPatientVisitData.previousYearToDate[field], fractionOfDigits) + '%' : '-',
    metricRecentMonth: isValid(summaryNewPatientVisitData.recentMonth, field) ?
      roundTo(summaryNewPatientVisitData.recentMonth[field], fractionOfDigits) + '%' : '-',
    metricPreviousYearRecentMonth: isValid(summaryNewPatientVisitData.previousYearRecentMonth, field) ?
      roundTo(summaryNewPatientVisitData.previousYearRecentMonth[field], fractionOfDigits) + '%' : '-'
  };
}

export function getBenchmarkSummaryRow(
  summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>,
  viewCommunityBenchmarks: boolean,
  benchmarkPercentile: BenchmarkPercentile,
  canShowTelehealth: boolean,
  benchmarkOption: BenchmarkOption
): NpvSummaryData {
  let benchmarkTitle: string;
  const benchmarkField: string =
    getSummaryBenchmarkFieldForBenchmarkCombination(benchmarkPercentile, benchmarkOption || BenchmarkOption.Academic, canShowTelehealth);
  benchmarkOption = benchmarkOption ? benchmarkOption : viewCommunityBenchmarks ? BenchmarkOption.Community :
    BenchmarkOption.Academic;
  let optionString = '';
  switch (benchmarkOption) {
    case BenchmarkOption.Academic:
      optionString = canShowTelehealth ? 'All Visit Types - Academic' : 'Academic';
      break;
    case BenchmarkOption.Community:
      optionString = canShowTelehealth ? 'All Visit Types - Community' : 'Community';
      break;
    case BenchmarkOption.TelehealthAcademic:
      optionString = 'Telehealth - Academic';
      break;
    case BenchmarkOption.TelehealthCommunity:
      optionString = 'Telehealth - Community';
      break;
    case BenchmarkOption.InPersonAcademic:
      optionString = 'In-Person - Academic';
      break;
    case BenchmarkOption.InPersonCommunity:
      optionString = 'In-Person - Community';
  }
  switch (benchmarkPercentile) {
    case BenchmarkPercentile.Mean :
      benchmarkTitle = `% New Patients ${optionString} Benchmark Mean`;
      break;
    case BenchmarkPercentile.Percentile25th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 25th Percentile`;
      break;
    case BenchmarkPercentile.Percentile50th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 50th Percentile`;
      break;
    case BenchmarkPercentile.Percentile75th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 75th Percentile`;
      break;
    case BenchmarkPercentile.Percentile90th:
      benchmarkTitle = `% New Patients ${optionString} Benchmark 90th Percentile`;
      break;
    default:
      benchmarkTitle = `% New Patients ${optionString} Benchmark Mean`;
  }
  return getBenchmarkDataByPercentage(summaryNewPatientVisitData, benchmarkTitle, benchmarkField, 1);
}

export function getBenchmarkDataByPercentage(summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>, title: string,
                                             field: string, fractionOfDigits: number): NpvSummaryData {
  return {
    metric: title,
    metricSelectedDateRange: isValid(summaryNewPatientVisitData.selectedDateRange, field) ?
      getPercentage(summaryNewPatientVisitData.selectedDateRange[field], fractionOfDigits) : '-',
    metricPreviousYearSelectedDateRange:
      isValid(summaryNewPatientVisitData.previousYearSelectedDateRange, field) ?
        getPercentage(summaryNewPatientVisitData.previousYearSelectedDateRange[field], fractionOfDigits) : '-',
    metricYearToDate: isValid(summaryNewPatientVisitData.yearToDate, field) ?
      getPercentage(summaryNewPatientVisitData.yearToDate[field], fractionOfDigits) : '-',
    metricPreviousYearToDate: isValid(summaryNewPatientVisitData.previousYearToDate, field) ?
      getPercentage(summaryNewPatientVisitData.previousYearToDate[field], fractionOfDigits) : '-',
    metricRecentMonth: isValid(summaryNewPatientVisitData.recentMonth, field) ?
      getPercentage(summaryNewPatientVisitData.recentMonth[field], fractionOfDigits) : '-',
    metricPreviousYearRecentMonth: isValid(summaryNewPatientVisitData.previousYearRecentMonth, field) ?
      getPercentage(summaryNewPatientVisitData.previousYearRecentMonth[field], fractionOfDigits) : '-'
  };
}

export function getSummaryDataByWholeNumber(summaryNewPatientVisitData: SummaryData<NewPatientVisitSummary>,
                                            title: string, field: string): NpvSummaryData {
  return {
    metric: title,
    metricSelectedDateRange: isValid(summaryNewPatientVisitData.selectedDateRange, field) ?
      formatNumberToWholeNumber(summaryNewPatientVisitData.selectedDateRange[field]) : '-',
    metricPreviousYearSelectedDateRange: isValid(summaryNewPatientVisitData.previousYearSelectedDateRange, field) ?
      formatNumberToWholeNumber(summaryNewPatientVisitData.previousYearSelectedDateRange[field]) : '-',
    metricYearToDate: isValid(summaryNewPatientVisitData.yearToDate, field) ?
      formatNumberToWholeNumber(summaryNewPatientVisitData.yearToDate[field]) : '-',
    metricPreviousYearToDate: isValid(summaryNewPatientVisitData.previousYearToDate, field) ?
      formatNumberToWholeNumber(summaryNewPatientVisitData.previousYearToDate[field]) : '-',
    metricRecentMonth: isValid(summaryNewPatientVisitData.recentMonth, field) ?
      formatNumberToWholeNumber(summaryNewPatientVisitData.recentMonth[field]) : '-',
    metricPreviousYearRecentMonth: isValid(summaryNewPatientVisitData.previousYearRecentMonth, field) ?
      formatNumberToWholeNumber(summaryNewPatientVisitData.previousYearRecentMonth[field]) : '-'
  };
}

export interface NpvSummaryData {
  metric: string;
  metricSelectedDateRange: string;
  metricPreviousYearSelectedDateRange: string;
  metricYearToDate: string;
  metricPreviousYearToDate: string;
  metricRecentMonth: string;
  metricPreviousYearRecentMonth: string;
}
