<mat-button-toggle-group name="fontStyle" aria-label="Font Style">
  <mat-button-toggle *ngIf="!hideCfteWrvuButton"
                     id="adjusted-wrvu"
                     (change)="showCfteAdjustedWrvuGraph()"
                     [checked]="!showActualCount && !showActualwRVU && !showAdjCount"
  >
    cFTE adj. wRVUs
  </mat-button-toggle>
  <mat-button-toggle id="actual-wrvu"
                     (change)="showActualWrvuGraph()"
                     [checked]="!showActualCount && !showCFTEAdjwRVU && !showAdjCount"
  >
    Actual wRVUs
  </mat-button-toggle>
  <mat-button-toggle *ngIf="!hideCfteCountButton"
                     id="cfte-adjusted"
                     (change)="showCfteAdjustedCountGraph()"
                     [checked]="!showActualCount && !showActualwRVU && !showCFTEAdjwRVU"
  >
    cFTE adj. Count
  </mat-button-toggle>
  <mat-button-toggle id="actual-count"
                     (change)="showActualCountGraph()"
                     [checked]="!showCFTEAdjwRVU && !showActualwRVU && !showAdjCount"
  >
    Count
  </mat-button-toggle>
</mat-button-toggle-group>

