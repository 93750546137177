<mat-dialog-content>
  <div id="manage-groupings-wrapper">
    <div id="main-section">
      <div id="header">
        <h2>Manage Groupings</h2>
        <span *ngIf="shouldShowPreload" id="preload-message">
        Select up to 3 non-default groupings that you use most frequently to precalculate to ensure faster loading.</span>
        <div *ngIf="showPreloadError" id="preload-error-message">Please select no more than {{maxPreloadGroupings}}
          non-default groupings.
        </div>
      </div>
      <div id="group-list">
        <div *ngFor="let group of changeableCustomGroups" class="group-row">
          <input [ngClass]="group.duplicateAttempt ? 'target-for-duplicate' : 'group-text'"
                 [matTooltip]="group.newName || group.original.name"
                 [matTooltipDisabled]="group.newName.length < maxCharsForGroupName"
                 [matTooltipPosition]="'below'"
                 [(ngModel)]="group.newName"
                 (input)="whenGroupNameChanged(group, $event)">
          <mat-checkbox *ngIf="shouldShowPreload" [checked]="group.newPreload" [disabled]="(!group.newPreload &&
         currentPreloads >= maxPreloadGroupings) || group.newDefault" (click)="markForPrecalc(group, $event)">
            Precalculate
          </mat-checkbox>
          <mat-radio-button [checked]="group.newDefault" (click)="markForDefault(group, $event)"> Set As Default
          </mat-radio-button>
          <span class="delete-wrapper" (click)="delete(group.original)"><mat-icon>delete</mat-icon>
          <span class="txt-delete">Delete</span></span>
        </div>
      </div>
    </div>
    <span id="buttons-wrapper">
    <span class="warning-message">{{warningText}}</span>
    <span>
      <button (click)="cancel()" class="btn-cancel">Cancel</button>
      <button (click)="save()" [ngClass]="warningText.length === 0 && canSave ? 'btn-save' : 'btn-dont-save'" id="save">Save</button>
    </span>
  </span>
  </div>
</mat-dialog-content>
