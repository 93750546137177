import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FilterCriteria, IAppState, INITIAL_STATE} from '../store/IAppState';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {NgRedux, select} from '@angular-redux/store';
import {
  ClinicalSummary,
  ClinicalSummaryCode,
  ClinicalSummaryConsolidation,
  ClinicalSummaryFamily,
  ClinicalSummaryRange
} from './ClinicalSummary';
import {ColumnType, CptViewType, SortingOrder} from '../shared/enums';
import * as _ from 'lodash';
import {
  AppAction,
  cfpSortingCriteriaChangedTo,
  cfpVarianceToggleChangedTo,
  clinicalSummaryConsolidationChangedTo,
  cptFamilyFilterChangedTo,
  cptRangeFilterChangedTo,
  cptViewTypeChangedTo
} from '../store/actions';
import {distinctUntilChanged} from 'rxjs/operators';
import {
  CfpVariableViewType,
  getLevelTypeNodePath,
  isFeatureEnabled,
  isInvalidCommunityBenchmarkValue,
  Page
} from '../shared/helpers';
import {AnalyticsService, AnalyticsServiceToken} from '../analytics/analytics.service';
import {DataTableColumns, getColumnsForCfp} from '../shared/data-table-columns';
import {getCptDescription} from '../evaluation-management/em-helpers';
import {updateCommunityBenchmarkAlertStatus} from '../shared/reducer-helper';
import {Variable} from '../variable-container/variable-container.component';
import {FeatureToggleSetting, SortingCriterion} from '../shared/models';
import {undefinedIfZero} from '../shared/null-helpers';
import {clinicalFingerprintCompatibleWithNodePath} from '../shared/test/ontology-helper';
import {VarianceToggleActions, VarianceToggleField} from '../variance-toggler/variance-toggler.component';
import {FilterSelectorService, FilterSelectorServiceToken} from '../services/filter-selector.service';
import {ApiServiceImpl, ApiServiceToken} from '../services/api.service';
import {formatNumberToWholeNumber} from '../productivity-summary/number-formatter';
import {FeatureToggleEntries} from '../shared/feature-toggle-settings-enum';
import {CFP_VARIABLES} from '../shared/constants';

@Component({
  selector: 'app-clinical-fingerprint',
  templateUrl: './clinical-fingerprint.component.html',
  styleUrls: ['./clinical-fingerprint.component.scss']
})
export class ClinicalFingerprintComponent implements OnInit, OnDestroy {

  constructor(private ngRedux: NgRedux<IAppState>, private _changeDetectorRef: ChangeDetectorRef,
              @Inject(AnalyticsServiceToken) private readonly analyticsService: AnalyticsService,
              @Inject(FilterSelectorServiceToken) private filterService: FilterSelectorService,
              @Inject(ApiServiceToken) private apiService: ApiServiceImpl) {

    this.filterSubscription = this.filterService.getFilters()
      .subscribe((filter: FilterCriteria) => {
        if (!!filter.nodePath) {
          this.apiService.getClinicalCodeSummaryData(filter);
        }
      });
  }

  cptViewType: CptViewType;
  cfpVariableViewType: CfpVariableViewType;

  columns: DataTableColumns[] = [];

  varianceKey = 'cFTEAdjustedWrvusVariance';
  benchmarkColumn = 'workRvuBenchmark';
  defaultSortColumn = 'cfteAdjustedWRVUs';
  clinicalFamilySummaryData: ClinicalSummaryFamily[];
  clinicalRangeSummaryData: ClinicalSummaryRange[];
  clinicalCodeSummaryData: ClinicalSummaryCode[];
  clinicalSummaryConsolidation: ClinicalSummaryConsolidation;
  settings: FeatureToggleSetting[];
  showProgressBar: boolean;
  currentPage = Page.CFP;
  sortDirection: string;
  countOfSuppressedRangeEntries = 0;
  countOfSuppressedCodeEntries = 0;
  countOfSuppressedFamilyEntries = 0;

  hasCommunityBenchmarks = false;
  hasAcademicBenchmarks = false;

  isSpecialty = false;
  isDepartment = false;
  familyFilter?: string;
  rangeFilter?: string;
  academicBenchmarks: any[];
  communityBenchmarks: any[];
  showCfp = false;
  sortingDataAccessor: any;

  @select(['data', 'clinicalSummaryConsolidationData'])
  private readonly clinicalSummaryConsolidation$: Observable<ClinicalSummaryConsolidation>;

  @select(['display', 'cptViewType'])
  private readonly cptViewType$: Observable<CptViewType>;

  @select(['display', 'cfpVariableViewType'])
  private readonly cfpVariableViewType$: Observable<CfpVariableViewType>;

  @select(['filters', 'nodePath'])
  private readonly nodePath$: Observable<string>;

  @select(['display', 'selectedSpecialties'])
  private readonly selectedSpecialties$: Observable<number>;

  @select(['display', 'viewCommunityBenchmarks'])
  private readonly viewCommunityBenchmarks$: Observable<boolean>;

  @select(['display', 'varianceToggle', 'cfpVariance'])
  private readonly cfpVariance$: Observable<boolean>;

  @select(['display', 'sortingCriteria', 'cfpSortingCriteria'])
  private readonly cfpSortingCriteria$: Observable<SortingCriterion | undefined>;

  @select(['display', 'zeroSuppression'])
  private readonly zeroSuppression$: Observable<boolean>;

  @select(['data', 'featureToggleSettings'])
  private readonly featureToggleSettings$: Observable<FeatureToggleSetting[]>;

  nodePathSubscription: Subscription;
  filterSubscription: Subscription;
  featureToggleSubscription: Subscription;
  zeroSuppression = true;
  showAdditionalCFPColumns: boolean;

  rangeSnapshotName = 'CPT Ranges';
  familySnapshotName = 'CPT Families';
  codeSnapshotName = 'CPT Codes';
  condition = 'reported cFTE, clinical activity, and a CPSC benchmark ';

  CptViewType = CptViewType;
  subscription: any;
  activeVarianceToggle = false;
  viewCommunityBenchmarks = false;
  metricPage = Page.CFP;

  variables: Variable[] = CFP_VARIABLES;

  varianceToggle: VarianceToggleActions = {
    display: false,
    reducerField: VarianceToggleField.ClinicalFingerprintVarianceToggle,
    sortingCriterion: {
      sortingOrder: SortingOrder.DESCENDING,
      columnDef: 'cFTEAdjustedWrvusVariance',
      columnType: ColumnType.VARIANCE
    },
    dispatchAction(display: boolean): AppAction {
      return cfpVarianceToggleChangedTo(display);
    },
    sortingCriteriaAction(sortingCriteria: SortingCriterion): AppAction {
      return cfpSortingCriteriaChangedTo(sortingCriteria);
    }
  };

  private static isClinicalSummaryZeroSuppressible(entry: ClinicalSummary): boolean {
    return Number(formatNumberToWholeNumber(entry.cfteAdjustedWRVUs)) === 0 &&
      Number(formatNumberToWholeNumber(entry.cfteAdjustedFrequency)) === 0
      && Number(formatNumberToWholeNumber(entry.workRvuBenchmark)) === 0
      && Number(formatNumberToWholeNumber(entry.frequencyBenchmark)) === 0;
  }

  ngOnInit() {
    this.featureToggleSubscription = combineLatest([this.featureToggleSettings$])
      .subscribe(([featureSettings]: [FeatureToggleSetting[]]) => {
        if (!_.isEqual(featureSettings, INITIAL_STATE.data.featureToggleSettings)) {
          const userProfile = this.ngRedux.getState().data.userProfile;
          this.showAdditionalCFPColumns = isFeatureEnabled(FeatureToggleEntries.SHOW_CFP_COLUMNS, featureSettings, userProfile);
          this.variables = !this.showAdditionalCFPColumns ? this.variables.filter(x => x.name === 'cFTE adj. Count')
            : this.variables.filter(x => x.name !== 'cFTE adj. Count');
        }
      });
    this.cptViewType = this.ngRedux.getState().display.cptViewType;

    this.showProgressBar = true;
    const isCustom = this.ngRedux.getState().display.isCustom;

    this.subscription = combineLatest([
      this.clinicalSummaryConsolidation$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.cptViewType$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.cfpVariableViewType$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
      this.cfpVariance$,
      this.zeroSuppression$,
      this.viewCommunityBenchmarks$,
      this.cfpSortingCriteria$.pipe(distinctUntilChanged(
        (value1, value2) => _.isEqual(value1, value2)))
    ]).subscribe(([clinicalSummary, type, variableType, varianceToggle, zeroSuppression, viewCommunityBenchmarks,
                    cfpSortingCriteria ]:
                    [ClinicalSummaryConsolidation, CptViewType, CfpVariableViewType, boolean, boolean, boolean,
                        SortingCriterion | undefined]) => {
        this.clinicalSummaryConsolidation = clinicalSummary;
        this.zeroSuppression = zeroSuppression;
        this.getShowProgressBarStatus();
        this.viewCommunityBenchmarks = viewCommunityBenchmarks;
        this.varianceKey = viewCommunityBenchmarks ? 'communityCfteAdjustedWrvusVariance' :
          'cFTEAdjustedWrvusVariance';
        this.benchmarkColumn = viewCommunityBenchmarks ? 'communityWorkRvuBenchmark' : 'workRvuBenchmark';
        this.setCptData(clinicalSummary);
        this.makeVariancesNulls();
        this.cptViewType = type;
        this.cfpVariableViewType = variableType;

        this.columns = getColumnsForCfp(this.cptViewType, this.viewCommunityBenchmarks, this.showAdditionalCFPColumns, true, true)
          .filter(c => c.showAdditionalCFPColumns !== false);
        this._changeDetectorRef.detectChanges();
        this.sortingDataAccessor = (data: any, sortHeaderId: string) => {
          return (sortHeaderId === 'cfteAdjustedWRVUs') ? data[this.defaultSortColumn] : data[sortHeaderId];
        };
        this.adjustSortingVariables(varianceToggle, cfpSortingCriteria, this.viewCommunityBenchmarks);
      });

    this.nodePathSubscription = combineLatest([this.nodePath$, this.selectedSpecialties$])
      .subscribe(([nodePath, selectedSpecialties]: [string, number]) => {
      const level = getLevelTypeNodePath(nodePath);
      this.isSpecialty = level === 3 && !isCustom;
      this.isDepartment = level === 2 && !isCustom;
      this.showCfp = selectedSpecialties === 1 || clinicalFingerprintCompatibleWithNodePath(nodePath);
    });
  }

  ngOnDestroy() {
    this._changeDetectorRef.detach();
    this.subscription?.unsubscribe();
    this.nodePathSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
  }

  adjustSortingVariables(cfpVarianceToggle: boolean, cfpSortingCriteria: SortingCriterion | undefined,
                         viewCommunityBenchmarks: boolean): void {
    this.varianceKey = viewCommunityBenchmarks ? 'communityCfteAdjustedWrvusVariance' :
      'cFTEAdjustedWrvusVariance';
    this.activeVarianceToggle = cfpVarianceToggle;
    if (cfpSortingCriteria && cfpSortingCriteria.columnDef && cfpSortingCriteria.sortingOrder) {
      const requiredSortingCriteria: SortingCriterion = cfpSortingCriteria.columnDef ===
      'cFTEAdjustedWrvusVariance' || cfpSortingCriteria.columnDef === 'communityCfteAdjustedWrvusVariance' ? {
        ...cfpSortingCriteria,
        columnDef: this.varianceKey
      } : cfpSortingCriteria;
      this.defaultSortColumn = requiredSortingCriteria.columnDef;
      this.sortDirection = requiredSortingCriteria.sortingOrder === SortingOrder.DESCENDING ? 'desc' : 'asc';
    } else {
      this.defaultSortColumn = 'cfteAdjustedWRVUs';
      this.sortDirection = 'desc';
    }
    if (cfpVarianceToggle) {
      this.varianceToggle.sortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: this.varianceKey,
        columnType: ColumnType.VARIANCE
      };
    } else {
      this.varianceToggle.sortingCriterion = {
        sortingOrder: SortingOrder.DESCENDING,
        columnDef: 'cfteAdjustedWRVUs',
        columnType: ColumnType.VARIANCE
      };
    }
  }

  updateSortingCriteria = (sortingCriteria: SortingCriterion | undefined) => {
    this.ngRedux.dispatch(cfpSortingCriteriaChangedTo(sortingCriteria));
  };


  makeVariancesNulls(): void {
    this.clinicalCodeSummaryData.forEach(code => {
      if (!undefinedIfZero(code.workRvuBenchmark)) {
        code.cFTEAdjustedWrvusVariance = null;
      }
      if (!undefinedIfZero(code.communityWorkRvuBenchmark)) {
        code.communityCfteAdjustedWrvusVariance = null;
      }
    });
    this.clinicalRangeSummaryData.forEach(range => {
      if (!undefinedIfZero(range.workRvuBenchmark)) {
        range.cFTEAdjustedWrvusVariance = null;
      }
      if (!undefinedIfZero(range.communityWorkRvuBenchmark)) {
        range.communityCfteAdjustedWrvusVariance = null;
      }
    });
    this.clinicalFamilySummaryData.forEach(family => {
      if (!undefinedIfZero(family.workRvuBenchmark)) {
        family.cFTEAdjustedWrvusVariance = null;
      }
      if (!undefinedIfZero(family.communityWorkRvuBenchmark)) {
        family.communityCfteAdjustedWrvusVariance = null;
      }
    });
  }

  private setCptData(clinicalSummary: ClinicalSummaryConsolidation) {
    this.familyFilter = this.ngRedux.getState().filters.cptFamilyFilter;
    this.rangeFilter = this.ngRedux.getState().filters.cptRangeFilter;
    let x: number;
    let currentIteration;
    if (this.rangeFilter && this.familyFilter) {
      // Filter both the codes and the range, but filter the codes based on the range
      this.clinicalCodeSummaryData = [];
      const clinicalCodeCount = clinicalSummary.cptClinicalSummaries.length;
      let rangeDescription: string;
      for (x = 0; x < clinicalCodeCount; x++) {
        currentIteration = clinicalSummary.cptClinicalSummaries[x];
        rangeDescription = this.getRangeDescription(currentIteration);
        if (rangeDescription === this.rangeFilter) {
          this.clinicalCodeSummaryData.push(currentIteration);
        }
      }

      this.clinicalRangeSummaryData = [];
      const clinicalRangeCount = clinicalSummary.rangeClinicalSummaries.length;
      let familyDescription: string;
      for (x = 0; x < clinicalRangeCount; x++) {
        currentIteration = clinicalSummary.rangeClinicalSummaries[x];
        familyDescription = currentIteration.cptFamilyDesc;
        if (familyDescription === this.familyFilter) {
          this.clinicalRangeSummaryData.push(currentIteration);
        }
      }
    } else if (this.rangeFilter) {
      // Filter only the codes
      this.clinicalCodeSummaryData = [];
      const clinicalCodeCount = clinicalSummary.cptClinicalSummaries.length;
      let rangeDescription: string;
      for (x = 0; x < clinicalCodeCount; x++) {
        currentIteration = clinicalSummary.cptClinicalSummaries[x];
        rangeDescription = this.getRangeDescription(currentIteration);
        if (rangeDescription === this.rangeFilter) {
          this.clinicalCodeSummaryData.push(currentIteration);
        }
      }
      this.clinicalRangeSummaryData = clinicalSummary.rangeClinicalSummaries;
    } else if (this.familyFilter) {
      // Filter both the codes and the range, but filter the codes based on the family
      this.clinicalCodeSummaryData = [];
      const clinicalCodeCount = clinicalSummary.cptClinicalSummaries.length;
      let familyDescription: string;
      for (x = 0; x < clinicalCodeCount; x++) {
        currentIteration = clinicalSummary.cptClinicalSummaries[x];
        familyDescription = currentIteration.cptFamilyDesc;
        if (familyDescription === this.familyFilter) {
          this.clinicalCodeSummaryData.push(currentIteration);
        }
      }
      this.clinicalRangeSummaryData = [];
      const clinicalRangeCount = clinicalSummary.rangeClinicalSummaries.length;
      for (x = 0; x < clinicalRangeCount; x++) {
        currentIteration = clinicalSummary.rangeClinicalSummaries[x];
        familyDescription = currentIteration.cptFamilyDesc;
        if (familyDescription === this.familyFilter) {
          this.clinicalRangeSummaryData.push(currentIteration);
        }

      }
    } else {
      // Filter nothing
      this.clinicalCodeSummaryData = clinicalSummary.cptClinicalSummaries;

      this.academicBenchmarks = this.clinicalCodeSummaryData.filter(a =>
        !(isInvalidCommunityBenchmarkValue(a.workRvuBenchmark))
      );

      this.communityBenchmarks = this.clinicalCodeSummaryData.filter(c =>
        !(isInvalidCommunityBenchmarkValue(c.communityWorkRvuBenchmark))
      );

      this.hasAcademicBenchmarks = this.academicBenchmarks.length > 0;
      this.hasCommunityBenchmarks = this.communityBenchmarks.length > 0;


      updateCommunityBenchmarkAlertStatus(
        this.hasCommunityBenchmarks,
        this.hasAcademicBenchmarks,
        this.ngRedux,
        this.showProgressBar
      );
      this.clinicalRangeSummaryData = clinicalSummary.rangeClinicalSummaries;
    }
    // Family does not need to be filtered.
    this.clinicalFamilySummaryData = clinicalSummary.familyClinicalSummaries;

    this.clinicalFamilySummaryData = this.zeroSuppression
      ? clinicalSummary.familyClinicalSummaries
        .filter(entry => !ClinicalFingerprintComponent.isClinicalSummaryZeroSuppressible(entry))
        .slice()
      : clinicalSummary.familyClinicalSummaries.slice();

    const originalRangeCount = this.clinicalRangeSummaryData.length;
    const originalCodeCount = this.clinicalCodeSummaryData.length;
    this.clinicalRangeSummaryData = this.zeroSuppression
      ? this.clinicalRangeSummaryData
        .filter(entry => !ClinicalFingerprintComponent.isClinicalSummaryZeroSuppressible(entry))
        .slice()
      : this.clinicalRangeSummaryData.slice();

    this.clinicalCodeSummaryData = this.zeroSuppression
      ? this.clinicalCodeSummaryData
        .filter(entry => !ClinicalFingerprintComponent.isClinicalSummaryZeroSuppressible(entry))
        .slice()
      : this.clinicalCodeSummaryData.slice();

    this.countOfSuppressedRangeEntries = originalRangeCount -
      this.clinicalRangeSummaryData.length;
    this.countOfSuppressedCodeEntries = originalCodeCount -
      this.clinicalCodeSummaryData.length;
    this.countOfSuppressedFamilyEntries = clinicalSummary.familyClinicalSummaries.length -
      this.clinicalFamilySummaryData.length;
  }

  cptCallBack = (viewType: CptViewType, category: string, componentOrigination: string) => {
    switch (viewType) {
      case CptViewType.CptFamily:
        this.ngRedux.dispatch(cptViewTypeChangedTo(CptViewType.CptRange));
        this.ngRedux.dispatch(cptFamilyFilterChangedTo(category));
        if (componentOrigination === 'graph') {
          this.analyticsService.handleGoogleAnalytics(
            'CPT View Type', 'CPT Drill down on graph',
            'Drill down into family: (' + category + ')');
        } else if (componentOrigination === 'table') {
          this.analyticsService.handleGoogleAnalytics(
            'CPT View Type', 'CPT Drill down on table',
            'Drill down into family: (' + category + ')');
        }
        break;
      case CptViewType.CptRange:
        this.ngRedux.dispatch(cptViewTypeChangedTo(CptViewType.CptCode));
        this.ngRedux.dispatch(cptRangeFilterChangedTo(category));
        if (componentOrigination === 'graph') {
          this.analyticsService.handleGoogleAnalytics(
            'CPT View Type', 'CPT Drill down on graph',
            'Drill down into range: (' + category + ')');
        } else if (componentOrigination === 'table') {
          this.analyticsService.handleGoogleAnalytics(
            'CPT View Type', 'CPT Drill down on table',
            'Drill down into range: (' + category + ')');
        }
        break;
      default:
        break;
    }
    this.setCptData(this.clinicalSummaryConsolidation);
  };

  getShowProgressBarStatus(): void {
    if (this.clinicalSummaryConsolidation) {
      this.showProgressBar = _.isEqualWith(this.clinicalSummaryConsolidation, INITIAL_STATE.data.clinicalSummaryConsolidationData,
        (o1, o2, key) => key === 'communityCfteAdjustedWrvusVariance' || key === 'cFTEAdjustedWrvusVariance' ? true : undefined);
    }
  }

  tieBreakerPropertyForCptCode = (clinicalSummary: ClinicalSummaryCode) => {
    return `${clinicalSummary.cptCode} ${clinicalSummary.cptDesc}`;
  };

  tieBreakerPropertyForCptRange = (clinicalSummary: ClinicalSummaryRange) => {
    return `${clinicalSummary.cptRangeDesc} ${clinicalSummary.cptRangeLow} ${clinicalSummary.cptRangeHigh}`;
  };

  tieBreakerPropertyForCptFamily = (clinicalSummary: ClinicalSummaryFamily) => {
    return `${clinicalSummary.cptFamilyDesc}`;
  };

  getRangeDescription(clinicalSummary: ClinicalSummaryCode) {
    return clinicalSummary.cptRangeLow + ' - ' + clinicalSummary.cptRangeHigh + ' ' + clinicalSummary.cptRangeDesc;
  }

  whenSortChanged = (clinicalSummaryData: any) => {
    switch (this.cptViewType) {
      case CptViewType.CptCode:
        if (!_.isEqual(this.clinicalCodeSummaryData, clinicalSummaryData)) {
          this.clinicalCodeSummaryData = clinicalSummaryData;
        }
        break;
      case CptViewType.CptRange:
        if (!_.isEqual(this.clinicalRangeSummaryData, clinicalSummaryData)) {
          this.clinicalRangeSummaryData = clinicalSummaryData;
        }
        break;
      case CptViewType.CptFamily:
        if (!_.isEqual(this.clinicalFamilySummaryData, clinicalSummaryData)) {
          this.clinicalFamilySummaryData = clinicalSummaryData;
        }
        break;
    }
    this.ngRedux.dispatch(clinicalSummaryConsolidationChangedTo(this.clinicalSummaryConsolidation));
  };

  rowIsSelected = (data: any) => {
    this.cptCallBack(this.cptViewType, getCptDescription(this.cptViewType, data), 'table');
  };
}
