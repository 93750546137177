<span id="procedure-summary-graph-wrapper">
  <div id="view-graph-by-wrapper">
    <div id="top-side" class="header-division">
      <mat-button-toggle-group id="toggle-node-cpt">
        <mat-button-toggle [checked]="viewByNode" (click)="toggleViewNode(true)" class="view-by-node-toggle">
          By {{nodeText}}</mat-button-toggle>
        <mat-button-toggle [checked]="!viewByNode" (click)="toggleViewNode(false)" class="view-by-node-toggle">
          By {{cptViewText}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <app-procedure-summary-graph-filter-selector class="header-division"
                                                 (selectedCptNodeChange)="filterGraphBy($event)"
                                                 (cptViewTextChange)="setCptViewText($event)"
                                                 (nodeTextChange)="setNodeText($event)"
                                                 [cfpData]="cfpData"
                                                 [selectedCptNode]="currentSelectedCptNode"
                                                 [currentSelectedOntologyCptNode]="currentSelectedOntologyCptNode"
                                                 [viewByNode]="viewByNode"
                                                 [cptViewText]="cptViewText"
                                                 [nodeText]="nodeText"
                                                 [cptViewType]="cptViewType"
                                                 [multilevelTab]="multilevelTab">
    </app-procedure-summary-graph-filter-selector>
    <div id="bottom-side" class="header-division">
      <h3 class="graph-title">{{getDisplayTextForCfpVariableViewType(cfpVariableViewType)}}
        By {{viewByNode ? nodeText : cptViewText}}:
        {{(viewByNode ? currentSelectedCptNode : currentSelectedOntologyCptNode)?.display}}</h3>
        <span id="variable-wrapper">
          <app-cfpt-toggle-variables *ngIf="showAdditionalCFPColumns"
                                     [cfpVariableViewType]="cfpVariableViewType"
                                     [hideCfteCountButton]="hideCfteCount"
                                     [hideCfteWrvuButton]="hideCfteWrvu">
          </app-cfpt-toggle-variables>
         <app-variable-container [variables]="extraGraphVariables" [showVariableMenu]="true"></app-variable-container>
        </span>
    </div>
  </div>
  <div [chart]="chartObject" id="procedure-summary-summary-graph"></div>
  <app-chart-legend
    [legends]="legends"
    [metricType]="MetricType.ProcedureSummary"
    [hasBenchmarks]="hasBenchmarks">
  </app-chart-legend>
  <app-loader *ngIf="showProgressBar"></app-loader>
</span>

