import {
  ProductivitySnapshot,
  ProductivitySummary,
  ProviderProductivityExport
} from '../../productivity-summary/services/ProviderProductivity';
import {ProviderProductivityMultiLevel} from '../../productivity-summary/services/ProviderProductivity';
import {ProductivitySnapshotExcelObject} from '../../productivity-summary/services/ProviderProductivity';
import {BaseColumn, SummaryData} from '../../store/IAppState';
import {ExportType, getGroupName, LevelType, toTitleCase} from '../helpers';
import {Export, ExportMetadata} from './export';
import {ColumnType} from '../enums';
import {
  imputedCFTEFor,
  providerNodeColumnForWrvu,
  specialtyNodeColumnForWrvu,
  varianceForWrvuTrend,
  varianceType
} from '../BenchmarkColumns';
import {filterOutBenchmarkRelatedColumns} from '../BenchmarkColumns';
import {imputedCFTEAsAPercentage} from '../BenchmarkColumns';
import {IMPUTED_COLUMNS} from '../BenchmarkColumns';
import {WRVU_BENCHMARK_RELATED_COLUMN_TYPES} from '../BenchmarkColumns';
import {CFTE_ADJ_WRVU_RELATED_COLUMN_TYPES} from '../BenchmarkColumns';
import {
  formatNumberToWholeNumber,
  formatToPercentage,
  getOrdinal,
  roundToWithCommasSeparation
} from '../../productivity-summary/number-formatter';
import {formatToWholeNumberWithoutNulls} from '../../productivity-summary/number-formatter';
import {formatToWholeNumberOrDashIfZero} from '../../productivity-summary/number-formatter';
import {checkForNulls} from '../null-helpers';
import {BenchmarkPercentile, readableNameOfColumnDef} from '../benchmark-types';
import {MergedProductivityTrendEntry, MonthProductivityExport} from '../models';
import {toMonthName} from '../../productivity-summary/month-formatter';
import {getFilterInfo} from './export-helper';
import {aBlankMonthProductivityExport, aBlankProductivitySummaryExport} from '../test/helper-functions.spec';

export function getWrvuSnapshotExportDataWithDisplayedColumns(
  providerData: ProductivitySnapshot[],
  displayedColumns: BaseColumn[],
  summaryData: SummaryData<ProductivitySummary>,
  level: string,
  exportType: ExportType,
  viewCommunityBenchmarks: boolean,
  levelTab: LevelType,
  showBenchmarks: boolean,
  isSpecialtyPerformance: boolean,
  suppressZeroes: boolean, shouldShowWrvuDepartmentCfteAdjusted: boolean
): Export {
  let headers: string[], exportColumns: BaseColumn[];
  const results = getWrvuSnapshotCsvExportSummaryData(summaryData, viewCommunityBenchmarks, levelTab, showBenchmarks);
  displayedColumns = setDisplayedWRVUExportColumns(displayedColumns, levelTab, showBenchmarks, shouldShowWrvuDepartmentCfteAdjusted);
  results.push(...providerData.map((providerProductivity: ProviderProductivityMultiLevel) => {
    let result: any = {
      cfteAdjustedWRVUs: providerProductivity.cfteAdjustedWRVUs,
      wRVUs: checkForNulls(providerProductivity.wRVUs),
      cfte: roundToWithCommasSeparation(providerProductivity.cfte, 2),
      benchmarkPercentileRank: providerProductivity.benchmarkPercentileRank,
      charges: checkForNulls(providerProductivity.charges),
      previousCfteAdjustedWRVUs: checkForNulls(providerProductivity.previousCfteAdjustedWRVUs),
      difference: providerProductivity.difference
    };

    if (showBenchmarks) {
      result = {
        ...result,
        benchmarkMean: viewCommunityBenchmarks ? providerProductivity.communityBenchmarkMean : providerProductivity.benchmarkMean,
        benchmark25thPercentile: viewCommunityBenchmarks ?
          providerProductivity.communityBenchmark25thPercentile : providerProductivity.benchmark25thPercentile,
        benchmark50thPercentile: viewCommunityBenchmarks ?
          providerProductivity.communityBenchmark50thPercentile : providerProductivity.benchmark50thPercentile,
        benchmark65thPercentile: viewCommunityBenchmarks ?
          providerProductivity.communityBenchmark65thPercentile : providerProductivity.benchmark65thPercentile,
        benchmark75thPercentile: viewCommunityBenchmarks ?
          providerProductivity.communityBenchmark75thPercentile : providerProductivity.benchmark75thPercentile,
        benchmark90thPercentile: viewCommunityBenchmarks ?
          providerProductivity.communityBenchmark90thPercentile : providerProductivity.benchmark90thPercentile,
        varianceMean: varianceType(providerProductivity, BenchmarkPercentile.Mean, viewCommunityBenchmarks),
        variance25thPercentile: varianceType(providerProductivity, BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks),
        variance50thPercentile: varianceType(providerProductivity, BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks),
        variance65thPercentile: varianceType(providerProductivity, BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks),
        variance75thPercentile: varianceType(providerProductivity, BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks),
        variance90thPercentile: varianceType(providerProductivity, BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks),
        imputedReportedcFTEMean:
          formatToPercentage(imputedCFTEFor(providerProductivity, BenchmarkPercentile.Mean, viewCommunityBenchmarks)),
        imputedReportedcFTE25thPercentile: formatToPercentage(imputedCFTEFor(providerProductivity,
          BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks)),
        imputedReportedcFTE50thPercentile: formatToPercentage(imputedCFTEFor(providerProductivity,
          BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks)),
        imputedReportedcFTE65thPercentile: formatToPercentage(imputedCFTEFor(providerProductivity,
          BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks)),
        imputedReportedcFTE75thPercentile: formatToPercentage(imputedCFTEFor(providerProductivity,
          BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks)),
        imputedReportedcFTE90thPercentile: formatToPercentage(imputedCFTEFor(providerProductivity,
          BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks))
      };

      result = Object.assign(result, viewCommunityBenchmarks
        ? {'communityBenchmarkPercentileRank': getOrdinal(providerProductivity.communityBenchmarkPercentileRank)}
        : {'benchmarkPercentileRank': getOrdinal(providerProductivity.benchmarkPercentileRank)});
    }

    if (isSpecialtyPerformance) {
      result = Object.assign({
        specialtyPerformanceActualWrvus: providerProductivity.specialtyPerformanceActualWrvus,
        specialtyPerformanceCfteAdjWrvus: providerProductivity.specialtyPerformanceCfteAdjWrvus,
        specialtyPerformanceActualWrvuspVariance: providerProductivity.specialtyPerformanceActualWrvuspVariance,
        specialtyPerformanceCfteAdjWrvuspVariance: providerProductivity.specialtyPerformanceCfteAdjWrvuspVariance
      }, result);
    }

    return Object.assign(getNodeNameData(providerProductivity, levelTab), result);
  }));

  exportColumns = filterOutBenchmarkRelatedColumns(displayedColumns);

  if (showBenchmarks) {
    exportColumns = appendBenchmarkRelatedColumns(viewCommunityBenchmarks, displayedColumns, exportColumns);
  }

  headers = exportColumns.map(x => x.header);
  headers[0] = 'Groups';

  return {
    data: getWRVUSnapshotDataForCsv(results, exportColumns, suppressZeroes, isSpecialtyPerformance),
    headers: headers,
    fileName: ('Wrvu ' + level),
    page: 'Wrvu ' + level,
    title: 'Clinical Practice Solutions Center Productivity Summary ' + new Date().toLocaleString() + ';'
  };
}

function setDisplayedWRVUExportColumns(
  columns: BaseColumn[],
  tab: LevelType,
  showBenchmarks: boolean,
  showCfteAdjWrvus: boolean
): BaseColumn[] {
  columns = columns.length ? columns : aDefaultDisplaySnapshotWrvuColumns();
  const columnsToFilterOut: (ColumnType | undefined)[] = [ColumnType.OPEN_WINDOW]
    .concat(!showBenchmarks ? WRVU_BENCHMARK_RELATED_COLUMN_TYPES : [])
    .concat(!showCfteAdjWrvus ? CFTE_ADJ_WRVU_RELATED_COLUMN_TYPES : []);
  columns = columns.filter(x => !columnsToFilterOut.includes(x.columnType));

  if (tab === LevelType.specialty || tab === LevelType.provider) {
    columns = updateColumnsWithRequiredBase(columns, specialtyNodeColumnForWrvu(true));
  }

  if (tab === LevelType.provider) {
    columns = updateColumnsWithRequiredBase(columns, providerNodeColumnForWrvu);
  }

  return columns;
}

function updateColumnsWithRequiredBase(columns: BaseColumn[], baseColumn: BaseColumn): BaseColumn[] {
  const {columnDef, header, columnType} = baseColumn;

  if (!columns.find(col => col.columnDef === columnDef)) {
    const requiredBaseColumn: BaseColumn = {columnDef, header, columnType};
    columns = [requiredBaseColumn].concat(columns);
  }

  return columns;
}

function appendBenchmarkRelatedColumns(viewCommunity: boolean, displayedColumns: BaseColumn[], exportColumns: BaseColumn[]) {
  const benchmarkLabel = viewCommunity ? 'Community' : 'Academic';
  const hasBenchmarks = !!displayedColumns.find(x => x.columnType === ColumnType.BENCHMARK);
  const hasVariance = !!displayedColumns.find(x => x.columnType === ColumnType.VARIANCE);
  const hasImputed = !!displayedColumns.find(x => x.columnType === ColumnType.IMPUTED);

  if (hasBenchmarks) {
    exportColumns = exportColumns.concat([
      {
        columnDef: 'benchmarkMean',
        header: `${benchmarkLabel} Benchmark (Mean)`,
        columnType: ColumnType.BENCHMARK
      },
      {
        columnDef: 'benchmark25thPercentile',
        header: `${benchmarkLabel} Benchmark (25th)`,
        columnType: ColumnType.BENCHMARK
      },
      {
        columnDef: 'benchmark50thPercentile',
        header: `${benchmarkLabel} Benchmark (50th)`,
        columnType: ColumnType.BENCHMARK
      },
      {
        columnDef: 'benchmark65thPercentile',
        header: `${benchmarkLabel} Benchmark (65th)`,
        columnType: ColumnType.BENCHMARK
      },
      {
        columnDef: 'benchmark75thPercentile',
        header: `${benchmarkLabel} Benchmark (75th)`,
        columnType: ColumnType.BENCHMARK
      },
      {
        columnDef: 'benchmark90thPercentile',
        header: `${benchmarkLabel} Benchmark (90th)`,
        columnType: ColumnType.BENCHMARK
      }
    ]);
  }
  if (hasVariance) {
    exportColumns = exportColumns.concat([
      {
        columnDef: 'varianceMean',
        header: `${benchmarkLabel} Variance (Mean)`,
        columnType: ColumnType.VARIANCE
      },
      {
        columnDef: 'variance25thPercentile',
        header: `${benchmarkLabel} Variance (25th)`,
        columnType: ColumnType.VARIANCE
      },
      {
        columnDef: 'variance50thPercentile',
        header: `${benchmarkLabel} Variance (50th)`,
        columnType: ColumnType.VARIANCE
      },
      {
        columnDef: 'variance65thPercentile',
        header: `${benchmarkLabel} Variance (65th)`,
        columnType: ColumnType.VARIANCE
      },
      {
        columnDef: 'variance75thPercentile',
        header: `${benchmarkLabel} Variance (75th)`,
        columnType: ColumnType.VARIANCE
      },
      {
        columnDef: 'variance90thPercentile',
        header: `${benchmarkLabel} Variance (90th)`,
        columnType: ColumnType.VARIANCE
      }
    ]);
  }
  if (hasImputed) {
    exportColumns = exportColumns.concat(IMPUTED_COLUMNS);
  }
  return exportColumns;
}

function getNodeNameData(providerProductivity: ProviderProductivityMultiLevel, tab: LevelType) {
  const {departmentNodeName, providerNodeName, specialtyNodeName} = providerProductivity;
  switch (tab) {
    case LevelType.department:
      return {departmentNodeName};
    case LevelType.specialty:
      return {departmentNodeName, specialtyNodeName};
    case LevelType.provider:
      return {departmentNodeName, specialtyNodeName, providerNodeName};
    default:
      return {nodeName: providerProductivity.nodeName};
  }
}

function getSpecialtyPerformanceData(providerProductivity: ProviderProductivityMultiLevel) {
  return {
    specialtyPerformanceActualWrvus: formatToWholeNumberWithoutNulls(providerProductivity.specialtyPerformanceActualWrvus),
    specialtyPerformanceCfteAdjWrvus: formatToWholeNumberWithoutNulls(providerProductivity.specialtyPerformanceCfteAdjWrvus),
    specialtyPerformanceActualWrvuspVariance:
      formatToWholeNumberWithoutNulls(providerProductivity.specialtyPerformanceActualWrvuspVariance),
    specialtyPerformanceCfteAdjWrvuspVariance:
      formatToWholeNumberWithoutNulls(providerProductivity.specialtyPerformanceCfteAdjWrvuspVariance)
  };
}

function getWRVUExcelBaseData(providerProductivity: ProviderProductivityMultiLevel) {
  return {
    wRVUs: formatToWholeNumberWithoutNulls(providerProductivity.wRVUs),
    cfte: roundToWithCommasSeparation(providerProductivity.cfte, 2),
    charges: '$' + formatToWholeNumberWithoutNulls(providerProductivity.charges)
  };
}

function getWRVUExcelCfteAdjustedData(providerProductivity: ProviderProductivityMultiLevel) {
  return {
    cfteAdjustedWRVUs: formatNumberToWholeNumber(providerProductivity.cfteAdjustedWRVUs),
    previousCfteAdjustedWRVUs: formatToWholeNumberWithoutNulls(providerProductivity.previousCfteAdjustedWRVUs),
    difference: formatNumberToWholeNumber(providerProductivity.difference)
  };
}

function getWRVUExcelBenchmarkData(viewCommunity: boolean, providerProductivity: ProviderProductivityMultiLevel) {
  const benchmarkData = {
    benchmarkMean: formatToWholeNumberOrDashIfZero(viewCommunity ?
      providerProductivity.communityBenchmarkMean : providerProductivity.benchmarkMean),
    benchmark25thPercentile: formatToWholeNumberOrDashIfZero(viewCommunity ?
      providerProductivity.communityBenchmark25thPercentile : providerProductivity.benchmark25thPercentile),
    benchmark50thPercentile: formatToWholeNumberOrDashIfZero(viewCommunity ?
      providerProductivity.communityBenchmark50thPercentile : providerProductivity.benchmark50thPercentile),
    benchmark65thPercentile: formatToWholeNumberOrDashIfZero(viewCommunity ?
      providerProductivity.communityBenchmark65thPercentile : providerProductivity.benchmark65thPercentile),
    benchmark75thPercentile: formatToWholeNumberOrDashIfZero(viewCommunity ?
      providerProductivity.communityBenchmark75thPercentile : providerProductivity.benchmark75thPercentile),
    benchmark90thPercentile: formatToWholeNumberOrDashIfZero(viewCommunity ?
      providerProductivity.communityBenchmark90thPercentile : providerProductivity.benchmark90thPercentile),
    imputedReportedcFTEMean: imputedCFTEAsAPercentage(providerProductivity, BenchmarkPercentile.Mean, viewCommunity),
    imputedReportedcFTE25thPercentile:
      imputedCFTEAsAPercentage(providerProductivity, BenchmarkPercentile.Percentile25th, viewCommunity),
    imputedReportedcFTE50thPercentile:
      imputedCFTEAsAPercentage(providerProductivity, BenchmarkPercentile.Percentile50th, viewCommunity),
    imputedReportedcFTE65thPercentile:
      imputedCFTEAsAPercentage(providerProductivity, BenchmarkPercentile.Percentile65th, viewCommunity),
    imputedReportedcFTE75thPercentile:
      imputedCFTEAsAPercentage(providerProductivity, BenchmarkPercentile.Percentile75th, viewCommunity),
    imputedReportedcFTE90thPercentile:
      imputedCFTEAsAPercentage(providerProductivity, BenchmarkPercentile.Percentile90th, viewCommunity),
    varianceMean: formatNumberToWholeNumber(varianceType(providerProductivity, BenchmarkPercentile.Mean, viewCommunity)),
    variance25thPercentile:
      formatNumberToWholeNumber(varianceType(providerProductivity, BenchmarkPercentile.Percentile25th, viewCommunity)),
    variance50thPercentile:
      formatNumberToWholeNumber(varianceType(providerProductivity, BenchmarkPercentile.Percentile50th, viewCommunity)),
    variance65thPercentile:
      formatNumberToWholeNumber(varianceType(providerProductivity, BenchmarkPercentile.Percentile65th, viewCommunity)),
    variance75thPercentile:
      formatNumberToWholeNumber(varianceType(providerProductivity, BenchmarkPercentile.Percentile75th, viewCommunity)),
    variance90thPercentile:
      formatNumberToWholeNumber(varianceType(providerProductivity, BenchmarkPercentile.Percentile90th, viewCommunity))
  };

  if (viewCommunity) {
    return Object.assign(benchmarkData, {
      communityBenchmarkPercentileRank: getOrdinal(providerProductivity.communityBenchmarkPercentileRank)
    });
  } else {
    return Object.assign(benchmarkData, {
      benchmarkPercentileRank: getOrdinal(providerProductivity.benchmarkPercentileRank)
    });
  }
}

export function convertWrvuSnapshotColumnsForDimensionRequirements(displayedColumns: BaseColumn[], level: LevelType): BaseColumn[] {
  switch (level) {
    case LevelType.department:
      return displayedColumns.filter(col => col.columnDef !== 'providerNodeName' && col.columnDef !== 'specialtyNodeName');
    case LevelType.specialty:
      return displayedColumns.filter(col => col.columnDef !== 'providerNodeName');
    default:
      return displayedColumns.filter(col => col.columnDef !== 'nodeName');
  }
}

export function getWRVUSnapshotDataForCsv(provider: ProductivitySnapshot[], shownColumns: BaseColumn[],
                                          suppressZeroes: boolean, isSpecialtyPerformance: boolean) {
  const result: string[][] = [];
  provider.forEach(x => {
    if (!(suppressZeroes && meetsZeroSuppressionCriteriaForWrvus(x, isSpecialtyPerformance))) {
      result.push(getRowForWRVUSnapshotExportForCsv(x, shownColumns));
    }
  });
  return result;
}

function meetsZeroSuppressionCriteriaForWrvus(x: ProviderProductivityMultiLevel | ProductivitySnapshot, isSpecialtyPerformance: boolean)
  : boolean {
  return isSpecialtyPerformance
    ? !(x.cfteAdjustedWRVUs || x.wRVUs || x.cfte || x.imputedReportedcFTEMean || x.charges)
    : !(x.cfte || x.wRVUs);
}

export function getWRVUTrendData(provider: MergedProductivityTrendEntry[], shownColumns: BaseColumn[]) {
  const result: string[][] = [];
  provider.forEach(x => {
    result.push(getRowForWRVUTrendExport(x, shownColumns));
  });
  return result;
}

export function getWRVUTrendDataForCsv(provider: MergedProductivityTrendEntry[], shownColumns: BaseColumn[]) {
  const result: string[][] = [];
  provider.forEach(x => {
    result.push(getRowForWRVUTrendExportForCsv(x, shownColumns));
  });
  return result;
}

export function getRowForWRVUSnapshotExport(providerData: ProductivitySnapshot, displayedColumns: BaseColumn[]): any[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
      if (providerData[x.columnDef]) {
        if (x.columnType === ColumnType.CHARGES) {
          data.push('$' + providerData[x.columnDef] + '');
        } else {
          data.push(providerData[x.columnDef] + '');
        }
      } else if (!(x.columnType === ColumnType.NODE_NAME)) {
        data.push('-');
      }
    }
  );
  return data;
}

export function getRowForWRVUSnapshotExportForCsv(providerData: ProductivitySnapshot, displayedColumns: BaseColumn[]): any[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
      if (providerData[x.columnDef] === ' ') {
        data.push(' ');
      } else if (providerData[x.columnDef] === '-') {
        data.push('-');
      } else if (!providerData[x.columnDef] && (x.columnType === ColumnType.VARIANCE ||
        x.columnType === ColumnType.BENCHMARK)) {
        data.push('-');
      } else if (x.columnType === ColumnType.PERCENTILE) {
        data.push('' + getOrdinal(Number(providerData[x.columnDef])));
      } else if (x.columnType === ColumnType.CHARGES) {
        // @ts-ignore
        providerData[x.columnDef] !== Number.NaN ? data.push('$' + formatNumberToWholeNumber(providerData[x.columnDef])) :
          data.push(providerData[x.columnDef]);
      } else if (x.columnType === ColumnType.CFTE) {
        // @ts-ignore
        data.push(roundToWithCommasSeparation(providerData[x.columnDef], 2));
      } else if (x.columnType === ColumnType.NODE_NAME) {
        data.push(providerData[x.columnDef]);
      } else if (x.columnType === ColumnType.IMPUTED) {
        data.push(providerData[x.columnDef]);
      } else {
        // @ts-ignore
        providerData[x.columnDef] !== Number.NaN ? data.push(formatNumberToWholeNumber(providerData[x.columnDef]) + '') :
          data.push(providerData[x.columnDef]);
      }
    }
  );
  return data;
}

export function getRowForWRVUTrendExport(providerData: MergedProductivityTrendEntry, displayedColumns: BaseColumn[]): any[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
    if (!providerData[x.columnDef] && (x.columnType === ColumnType.VARIANCE ||
      x.columnType === ColumnType.BENCHMARK)) {
      data.push('-');
    } else if (providerData[x.columnDef] === ' ') {
      data.push(' ');
    } else if (x.columnType === ColumnType.PRIMARY_DATE) {
      data.push(providerData.year + ' ' + toMonthName(providerData.month.toString()));
    } else if (x.columnType === ColumnType.CFTE) {
      // @ts-ignore
      data.push(roundToWithCommasSeparation(providerData[x.columnDef], 2));
    } else if (x.columnType === ColumnType.CHARGES) {
      // @ts-ignore
      providerData[x.columnDef] !== Number.NaN ? data.push('$' + formatNumberToWholeNumber(providerData[x.columnDef])) :
        data.push(providerData[x.columnDef]);
    } else {
      // @ts-ignore
      providerData[x.columnDef] !== Number.NaN ? data.push(formatNumberToWholeNumber(providerData[x.columnDef])) :
        data.push(providerData[x.columnDef]);
    }
  });
  return data;
}

export function getRowForWRVUTrendExportForCsv(providerData: MergedProductivityTrendEntry, displayedColumns: BaseColumn[]): any[] {
  const data: any[] = [];
  displayedColumns.forEach(x => {
    if (providerData[x.columnDef] === ' ') {
      data.push(' ');
    } else if (!providerData[x.columnDef] && (x.columnType === ColumnType.VARIANCE ||
      x.columnType === ColumnType.BENCHMARK)) {
      data.push('-');
    } else if (x.columnType === ColumnType.PRIMARY_DATE) {
      data.push(providerData.date);
    } else if (x.columnType === ColumnType.CFTE) {
      data.push(providerData[x.columnDef]);
    } else if (x.columnType === ColumnType.CHARGES) {
      // @ts-ignore
      providerData[x.columnDef] !== Number.NaN ? data.push('$' + formatNumberToWholeNumber(providerData[x.columnDef])) :
        data.push(providerData[x.columnDef]);
    } else {
      // @ts-ignore
      providerData[x.columnDef] !== Number.NaN ? data.push(formatNumberToWholeNumber(providerData[x.columnDef])) :
        data.push(providerData[x.columnDef]);
    }
  });
  return data;
}

export function getWRVUSnapshotExcelData(providerData: ProviderProductivityMultiLevel[],
                                         summaryData: SummaryData<ProductivitySummary>,
                                         displayedColumns: BaseColumn[],
                                         copyRight: string,
                                         viewCommunityBenchmarks: boolean, levelTab: LevelType,
                                         isSpecialtyPerformance: boolean,
                                         suppressZeroes: boolean,
                                         level: any,
                                         nodePath: string,
                                         dateRange: any,
                                         memberLocationName: any,
                                         payerCategory: any,
                                         showBenchmarks: boolean,
                                         showCfteAdjWrvus: boolean
): ExportMetadata {
  let headers: string[], exportColumns: BaseColumn[];
  let summaryHeaders: string[] = ['Time Range'].concat(showCfteAdjWrvus ? ['cFTE Adj. wRVUs'] : []);
  providerData = providerData.filter(x => !suppressZeroes || !meetsZeroSuppressionCriteriaForWrvus(x, isSpecialtyPerformance));
  displayedColumns = setDisplayedWRVUExportColumns(displayedColumns, levelTab, showBenchmarks, showCfteAdjWrvus);
  // @ts-ignore
  exportColumns = displayedColumns.filter(x => !WRVU_BENCHMARK_RELATED_COLUMN_TYPES.includes(x.columnType) && x.columnDef !== 'nodeName');
  if (showBenchmarks) {
    const benchmarkLabel = viewCommunityBenchmarks ? 'Community' : 'Academic';
    summaryHeaders = summaryHeaders.concat([
      `${benchmarkLabel} wRVU Benchmark (Mean)`,
      `${benchmarkLabel} wRVU Benchmark (25th)`,
      `${benchmarkLabel} wRVU Benchmark (50th)`,
      `${benchmarkLabel} wRVU Benchmark (65th)`,
      `${benchmarkLabel} wRVU Benchmark (75th)`,
      `${benchmarkLabel} wRVU Benchmark (90th)`
    ]);
    exportColumns = appendBenchmarkRelatedColumns(viewCommunityBenchmarks, displayedColumns, exportColumns);
  }
  summaryHeaders = summaryHeaders.concat(['Actual wRVUs', 'cFTE', 'Charges']);

  headers = exportColumns.map(x => x.header);
  headers[0] = toTitleCase(level);

  if (levelTab === LevelType.department) {
    headers = headers.filter(header => header !== 'Department' && header !== 'Specialty');
  } else if (levelTab === LevelType.specialty) {
    headers = headers.filter(header => header !== 'Specialty');
  }

  const excelData = mapWRVUSnapshotToExcelFields(providerData, showBenchmarks, showCfteAdjWrvus, viewCommunityBenchmarks,
    isSpecialtyPerformance, levelTab);
  const excelDetailRows = excelData.map(x => getWRVUSnapshotExcelExportRow(x, exportColumns));
  const excelSummaryRows = getWrvuSnapshotExcelExportSummaryData(summaryData, viewCommunityBenchmarks, showBenchmarks,
    showCfteAdjWrvus);

  return {
    summaryHeaders: summaryHeaders,
    summaryData: excelSummaryRows,
    copyright: copyRight,
    detailHeaders: headers,
    page: isSpecialtyPerformance ? 'wRVU Specialty Performance' : 'Wrvu ' + level,
    fileName: isSpecialtyPerformance ? 'wRVU Specialty Performance' : 'Wrvu ' + level,
    detailData: excelDetailRows,
    filterInfo: getFilterInfo(nodePath, dateRange, memberLocationName, payerCategory),
    isBlankRowAfterSummary: true
  };
}

function getWRVUSnapshotExcelExportRow(providerData: ProductivitySnapshotExcelObject, displayedColumns: BaseColumn[]): string[] {
  const data: any[] = [];
  displayedColumns.forEach(column => {
      if (providerData[column.columnDef]) {
        data.push(providerData[column.columnDef]);
      } else if (column.columnType !== ColumnType.NODE_NAME) {
        data.push('-');
      }
    }
  );
  return data;
}

function mapWRVUSnapshotToExcelFields(
  providerData: ProviderProductivityMultiLevel[],
  showBenchmarks: boolean,
  showCfteAdjustedWrvus: boolean,
  viewCommunity: boolean,
  isSpecialtyPerformance: boolean,
  tab: LevelType): ProductivitySnapshotExcelObject[] {
  return providerData.map((providerProductivity: ProviderProductivityMultiLevel) => {
    const nodeNameData = getNodeNameData(providerProductivity, tab);
    const wRVUData = getWRVUExcelBaseData(providerProductivity);
    const cfteAdjustedWrvuData = showCfteAdjustedWrvus ? getWRVUExcelCfteAdjustedData(providerProductivity) : {};
    const benchmarkData = showBenchmarks ? getWRVUExcelBenchmarkData(viewCommunity, providerProductivity) : {};
    const specialtyPerformanceData = isSpecialtyPerformance ? getSpecialtyPerformanceData(providerProductivity) : {};
    return Object.assign(nodeNameData, wRVUData, cfteAdjustedWrvuData, benchmarkData, specialtyPerformanceData);
  });
}

function getWrvuSnapshotExcelExportSummaryData(
  summaryData: SummaryData<ProductivitySummary>,
  viewCommunityBenchmarks: boolean,
  showBenchmarks: boolean,
  showCfteAdjustedWrvus: boolean
): string[][] {
  const result: string[][] = [];
  // tslint:disable-next-line:forin
  for (const dateRange in summaryData) {
    const summaryDatum = summaryData[dateRange];
    if (summaryDatum) {
      const groupName = getGroupName(summaryDatum, dateRange),
        wRVUs = formatNumberToWholeNumber(checkForNulls(summaryDatum.wRVUs)),
        cFTE = roundToWithCommasSeparation(summaryDatum.cfte, 2),
        charges = '$' + formatNumberToWholeNumber(checkForNulls(summaryDatum.charges));
      let summaryRow = [groupName];
      summaryRow = summaryRow.concat(showCfteAdjustedWrvus ? formatNumberToWholeNumber(checkForNulls(summaryDatum.cfteAdjustedWRVUs)) : []);
      summaryRow = summaryRow.concat(showBenchmarks ? [
            formatNumberToWholeNumber(viewCommunityBenchmarks ? summaryDatum.communityBenchmarkMean : summaryDatum.benchmarkMean),
            formatNumberToWholeNumber(viewCommunityBenchmarks ?
              summaryDatum.communityBenchmark25thPercentile : summaryDatum.benchmark25thPercentile),
            formatNumberToWholeNumber(viewCommunityBenchmarks ?
              summaryDatum.communityBenchmark50thPercentile : summaryDatum.benchmark50thPercentile),
            formatNumberToWholeNumber(viewCommunityBenchmarks ?
              summaryDatum.communityBenchmark65thPercentile : summaryDatum.benchmark65thPercentile),
            formatNumberToWholeNumber(viewCommunityBenchmarks ?
              summaryDatum.communityBenchmark75thPercentile : summaryDatum.benchmark75thPercentile),
            formatNumberToWholeNumber(viewCommunityBenchmarks ?
              summaryDatum.communityBenchmark90thPercentile : summaryDatum.benchmark90thPercentile)
          ] : []);
      summaryRow = summaryRow.concat([
            wRVUs,
            cFTE,
            charges]);
      result.push(summaryRow);
    }
  }

  return result;
}

export function getWrvuTrendExportDataWithDisplayedColumns(monthData: MergedProductivityTrendEntry[],
                                                           displayedColumns: BaseColumn[],
                                                           summaryData: SummaryData<ProductivitySummary>,
                                                           viewCommunityBenchmarks: boolean)
  : Export {
  monthData = nullifyWrvuVarianceForTrend(monthData);
  displayedColumns = displayedColumns.filter(x => x.columnDef !== 'month');
  displayedColumns.splice(0, 1, {
    columnDef: 'date',
    header: 'Date',
    columnType: ColumnType.PRIMARY_DATE
  });

  const result = getWrvuTrendExportSummaryData(summaryData, viewCommunityBenchmarks);

  result.push(...monthData.map((monthProductivity: MergedProductivityTrendEntry) => ({
    date: monthProductivity.year.toString() + ' ' + toMonthName(monthProductivity.month.toString()),
    cfteAdjustedWRVUs: monthProductivity.cfteAdjustedWRVUs,
    benchmarkMean: !viewCommunityBenchmarks ? monthProductivity.benchmarkMean
      : monthProductivity.communityBenchmarkMean,
    benchmark25thPercentile: !viewCommunityBenchmarks ?
      monthProductivity.benchmark25thPercentile
      : monthProductivity.communityBenchmark25thPercentile,
    benchmark50thPercentile: !viewCommunityBenchmarks ?
      monthProductivity.benchmark50thPercentile
      : monthProductivity.communityBenchmark50thPercentile,
    benchmark65thPercentile: !viewCommunityBenchmarks ?
      monthProductivity.benchmark65thPercentile
      : monthProductivity.communityBenchmark65thPercentile,
    benchmark75thPercentile: !viewCommunityBenchmarks ?
      monthProductivity.benchmark75thPercentile
      : monthProductivity.communityBenchmark75thPercentile,
    benchmark90thPercentile: !viewCommunityBenchmarks ?
      monthProductivity.benchmark90thPercentile
      : monthProductivity.communityBenchmark90thPercentile,
    wRVUs: monthProductivity.wRVUs,
    cfte: roundToWithCommasSeparation(monthProductivity.cfte, 2),
    charges: monthProductivity.charges,
    varianceMean: varianceForWrvuTrend(monthProductivity, BenchmarkPercentile.Mean, viewCommunityBenchmarks),
    variance25thPercentile: varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks
    ),
    variance50thPercentile: varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks
    ),
    variance65thPercentile: varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks
    ),
    variance75thPercentile: varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks
    ),
    variance90thPercentile: varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks
    ),
    previousCfteAdjustedWRVUs: monthProductivity.previousCfteAdjustedWRVUs,
    difference: monthProductivity.difference
  })));

  let headers = ['Date'];

  const benchmarkHeaders = !viewCommunityBenchmarks ? ['Academic wRVU Benchmark (Mean)', 'Academic wRVU Benchmark (25th)',
      'Academic wRVU Benchmark (50th)', 'Academic wRVU Benchmark (65th)',
      'Academic wRVU Benchmark (75th)', 'Academic wRVU Benchmark (90th)'] :
    ['Community wRVU Benchmark (Mean)', 'Community wRVU Benchmark (25th)',
      'Community wRVU Benchmark (50th)', 'Community wRVU Benchmark (65th)',
      'Community wRVU Benchmark (75th)', 'Community wRVU Benchmark (90th)'];
  const varianceHeaders = !viewCommunityBenchmarks ? ['Variance from Academic Benchmark (Mean)',
    'Variance from Academic Benchmark (25th)',
    'Variance from Academic Benchmark (50th)', 'Variance from Academic Benchmark (65th)',
    'Variance from Academic Benchmark (75th)', 'Variance from Academic Benchmark (90th)'] : ['Community Variance from Benchmark (Mean)',
    'Community Variance from Benchmark (25th)',
    'Community Variance from Benchmark (50th)', 'Community Variance from Benchmark (65th)',
    'Community Variance from Benchmark (75th)', 'Community Variance from Benchmark (90th)'];

  const benchmarkData: BaseColumn[] = [];
  const varianceData: BaseColumn[] = [];
  [
    BenchmarkPercentile.Mean,
    BenchmarkPercentile.Percentile25th,
    BenchmarkPercentile.Percentile50th,
    BenchmarkPercentile.Percentile65th,
    BenchmarkPercentile.Percentile75th,
    BenchmarkPercentile.Percentile90th
  ].forEach(percentile => {
    benchmarkData.push({
      columnDef: `benchmark${readableNameOfColumnDef(percentile)}`,
      header: 'wRVU Benchmark ' + getOrdinalFromBenchmarkPercentile(percentile),
      columnType: ColumnType.BENCHMARK
    });
    varianceData.push({
      columnDef: `variance${readableNameOfColumnDef(percentile)}`,
      header: 'Variance ' + getOrdinalFromBenchmarkPercentile(percentile),
      columnType: ColumnType.VARIANCE
    });
  });

  const hasBenchmarks = displayedColumns.filter
  (x => x.columnType === ColumnType.BENCHMARK);

  const hasVariance = displayedColumns.filter
  (x => x.columnType === ColumnType.VARIANCE);

  let remainingColumns = displayedColumns.filter
  (x => !(x.columnType === ColumnType.BENCHMARK
    || x.columnType === ColumnType.VARIANCE || x.columnType === ColumnType.IMPUTED));

  for (let i = 1; i < remainingColumns.length; i++) {
    headers.push(remainingColumns[i].header);
  }

  if (hasBenchmarks.length > 0) {
    remainingColumns = remainingColumns.concat(benchmarkData);
    headers = headers.concat(benchmarkHeaders);
  }
  if (hasVariance.length > 0) {
    remainingColumns = remainingColumns.concat(varianceData);
    headers = headers.concat(varianceHeaders);
  }

  return {
    data: getWRVUTrendDataForCsv(result, remainingColumns),
    headers: headers,
    fileName: 'wRVUbyMonths',
    page: 'Wrvu Trend',
    title: 'Clinical Practice Solutions Center Productivity Summary ' + new Date().toLocaleString() + ';'
  };
}

export function nullifyWrvuVarianceForTrend(data: MergedProductivityTrendEntry[]): MergedProductivityTrendEntry[] {
  if (!data) {
    return data;
  }
  data.forEach((x: MergedProductivityTrendEntry) => {
    if (x.benchmarkMean === 0 || x.benchmarkMean === null) {
      x.varianceMean = 0;
      x.variance25thPercentile = 0;
      x.variance50thPercentile = 0;
      x.variance65thPercentile = 0;
      x.variance75thPercentile = 0;
      x.variance90thPercentile = 0;
    }
    if (x.communityBenchmarkMean === 0 || x.communityBenchmarkMean === null) {
      x.communityVarianceMean = 0;
      x.communityVariance25thPercentile = 0;
      x.communityVariance50thPercentile = 0;
      x.communityVariance65thPercentile = 0;
      x.communityVariance75thPercentile = 0;
      x.communityVariance90thPercentile = 0;
    }
  });
  return data;
}

export function getWrvuTrendExportSummaryData(summaryData: SummaryData<ProductivitySummary>,
                                              viewCommunityBenchmarks: boolean): any[] {
  const result: any[] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      result.push({
        date: getGroupName(summaryData[dateRange], dateRange),
        cfteAdjustedWRVUs: checkForNulls(summaryData[dateRange].cfteAdjustedWRVUs),
        benchmarkMean: !viewCommunityBenchmarks ?
          summaryData[dateRange].benchmarkMean :
          summaryData[dateRange].communityBenchmarkMean,
        benchmark25thPercentile: !viewCommunityBenchmarks ?
          summaryData[dateRange].benchmark25thPercentile :
          summaryData[dateRange].communityBenchmark25thPercentile,
        benchmark50thPercentile: !viewCommunityBenchmarks ?
          summaryData[dateRange].benchmark50thPercentile :
          summaryData[dateRange].communityBenchmark50thPercentile,
        benchmark65thPercentile: !viewCommunityBenchmarks ?
          summaryData[dateRange].benchmark65thPercentile :
          summaryData[dateRange].communityBenchmark65thPercentile,
        benchmark75thPercentile: !viewCommunityBenchmarks ?
          summaryData[dateRange].benchmark75thPercentile :
          summaryData[dateRange].communityBenchmark75thPercentile,
        benchmark90thPercentile: !viewCommunityBenchmarks ?
          summaryData[dateRange].benchmark90thPercentile :
          summaryData[dateRange].communityBenchmark90thPercentile,
        wRVUs: checkForNulls(summaryData[dateRange].wRVUs),
        cfte: roundToWithCommasSeparation(summaryData[dateRange].cfte, 2),
        charges: checkForNulls(summaryData[dateRange].charges),
        varianceMean: ' ',
        variance25thPercentile: ' ',
        variance50thPercentile: ' ',
        variance65thPercentile: ' ',
        variance75thPercentile: ' ',
        variance90thPercentile: ' ',
        previousCfteAdjustedWRVUs: null,
        difference: null
      });
    }
  }
  result.push({
    date: ' ',
    cfteAdjustedWRVUs: ' ',
    countOfNewPatientVisits: ' ',
    countOfTotalPatientVisits: ' ',
    benchmarkMean: ' ',
    benchmark25thPercentile: ' ',
    benchmark50thPercentile: ' ',
    benchmark65thPercentile: ' ',
    benchmark75thPercentile: ' ',
    benchmark90thPercentile: ' ',
    wRVUs: ' ',
    cfte: ' ',
    charges: ' ',
    varianceMean: ' ',
    variance25thPercentile: ' ',
    variance50thPercentile: ' ',
    variance65thPercentile: ' ',
    variance75thPercentile: ' ',
    variance90thPercentile: ' ',
    previousCfteAdjustedWRVUs: ' ',
    difference: ' '
  });
  return result;
}

export function getWrvuSnapshotCsvExportSummaryData(
  summaryData: SummaryData<ProductivitySummary>, viewCommunityBenchmarks: boolean, levelTab: LevelType, showBenchmarks: boolean
): any[] {
  const result: any[] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      const datum = summaryData[dateRange];

      let addToResult: any = {
        cfteAdjustedWRVUs: checkForNulls(datum.cfteAdjustedWRVUs),
        wRVUs: checkForNulls(datum.wRVUs),
        cfte: roundToWithCommasSeparation(datum.cfte, 2),
        charges: checkForNulls(datum.charges),
        previousCfteAdjustedWRVUs: '-',
        difference: '-'
      };

      if (showBenchmarks) {
        addToResult = {
          ...addToResult,
          benchmarkMean: viewCommunityBenchmarks ? datum.communityBenchmarkMean : datum.benchmarkMean,
          benchmark25thPercentile: viewCommunityBenchmarks ? datum.communityBenchmark25thPercentile : datum.benchmark25thPercentile,
          benchmark50thPercentile: viewCommunityBenchmarks ? datum.communityBenchmark50thPercentile : datum.benchmark50thPercentile,
          benchmark65thPercentile: viewCommunityBenchmarks ? datum.communityBenchmark65thPercentile : datum.benchmark65thPercentile,
          benchmark75thPercentile: viewCommunityBenchmarks ? datum.communityBenchmark75thPercentile : datum.benchmark75thPercentile,
          benchmark90thPercentile: viewCommunityBenchmarks ? datum.communityBenchmark90thPercentile : datum.benchmark90thPercentile,
          varianceMean: '-',
          variance25thPercentile: '-',
          variance50thPercentile: '-',
          variance65thPercentile: '-',
          variance75thPercentile: '-',
          variance90thPercentile: '-',
          imputedReportedcFTEMean: '-',
          imputedReportedcFTE25thPercentile: '-',
          imputedReportedcFTE50thPercentile: '-',
          imputedReportedcFTE65thPercentile: '-',
          imputedReportedcFTE75thPercentile: '-',
          imputedReportedcFTE90thPercentile: '-'
        };
      }

      result.push(Object.assign(
        levelTab === LevelType.department
          ? {departmentNodeName: getGroupName(datum, dateRange)}
          : levelTab === LevelType.specialty
            ? {specialtyNodeName: getGroupName(datum, dateRange), departmentNodeName: '-'}
            : levelTab === LevelType.provider
              ? {providerNodeName: getGroupName(datum, dateRange), specialtyNodeName: '-', departmentNodeName: '-'}
              : {nodeName: getGroupName(datum, dateRange)},
        addToResult));
    }
  }
  let summaryRow: any = {
    date: ' ',
    cfteAdjustedWRVUs: '',
    countOfNewPatientVisits: ' ',
    countOfTotalPatientVisits: ' ',
    wRVUs: ' ',
    cfte: ' ',
    charges: ' ',
    previousCfteAdjustedWRVUs: ' ',
    difference: ' '
  };

  if (showBenchmarks) {
    summaryRow = {
      ...summaryRow,
      benchmarkMean: ' ',
      benchmark25thPercentile: ' ',
      benchmark50thPercentile: ' ',
      benchmark65thPercentile: ' ',
      benchmark75thPercentile: ' ',
      benchmark90thPercentile: ' ',
      varianceMean: ' ',
      variance25thPercentile: ' ',
      variance50thPercentile: ' ',
      variance65thPercentile: ' ',
      variance75thPercentile: ' ',
      variance90thPercentile: ' ',
      imputedReportedcFTEMean: ' ',
      imputedReportedcFTE25thPercentile: ' ',
      imputedReportedcFTE50thPercentile: ' ',
      imputedReportedcFTE65thPercentile: ' ',
      imputedReportedcFTE75thPercentile: ' ',
      imputedReportedcFTE90thPercentile: ' '
    };
  }
  summaryRow = Object.assign(
    levelTab === LevelType.department
      ? {departmentNodeName: ' '}
      : levelTab === LevelType.specialty
        ? {specialtyNodeName: ' ', departmentNodeName: ' '}
        : levelTab === LevelType.provider
          ? {providerNodeName: ' ', specialtyNodeName: ' ', departmentNodeName: ' '}
          : {nodeName: ' '},
    summaryRow);
  result.push(summaryRow);
  return result;
}

export function getWrvuSnapshotExportData(
  providerData: ProductivitySnapshot[],
  summaryData: SummaryData<ProductivitySummary>,
  level: string,
  exportType: ExportType,
  viewCommunityBenchmarks: boolean
): Export {
  const result: ProviderProductivityExport[] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      result.push({
        'Time Ranges': getGroupName(summaryData[dateRange], dateRange),
        'cfteAdjustedWRVU': formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].cfteAdjustedWRVUs)),
        'benchmarkMean': !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmarkMean)
          : formatNumberToWholeNumber(summaryData[dateRange].communityBenchmarkMean),
        'benchmark25th': !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark25thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark25thPercentile),
        'benchmark50th': !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark50thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark50thPercentile),
        'benchmark65th': !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark65thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark65thPercentile),
        'benchmark75th': !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark75thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark75thPercentile),
        'benchmark90th': !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark90thPercentile)
          : formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark90thPercentile),
        'wRVUs': formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].wRVUs)),
        'cfte': roundToWithCommasSeparation(summaryData[dateRange].cfte, 2),
        'imputedReportedCfteMean': '-',
        'imputedReportedCfte25th': '-',
        'imputedReportedCfte50th': '-',
        'imputedReportedCfte65th': '-',
        'imputedReportedCfte75th': '-',
        'imputedReportedCfte90th': '-',
        'charges': formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].charges)),
        'varianceMean': '-',
        'variance25th': '-',
        'variance50th': '-',
        'variance65th': '-',
        'variance75th': '-',
        'variance90th': '-',
        'previousCfteAdjustedWRVU': '-',
        'difference': '-'
      });
    }
  }

  result.push(aBlankProductivitySummaryExport());
  if (exportType === ExportType.excel) {
    result.push({
      'Time Ranges': toTitleCase(level),
      'benchmarkMean': 'benchmarkMean',
      'benchmark25th': 'benchmark25th',
      'benchmark50th': 'benchmark50th',
      'benchmark65th': 'benchmark65th',
      'benchmark75th': 'benchmark75th',
      'benchmark90th': 'benchmark90th',
      'wRVUs': 'wRVUs',
      'cfte': 'cfte',
      'charges': 'charges',
      'cfteAdjustedWRVU': 'cfteAdjustedWRVU',
      'imputedReportedCfteMean': 'imputedReportedCfteMean',
      'imputedReportedCfte25th': 'imputedReportedCfte25th',
      'imputedReportedCfte50th': 'imputedReportedCfte50th',
      'imputedReportedCfte65th': 'imputedReportedCfte65th',
      'imputedReportedCfte75th': 'imputedReportedCfte75th',
      'imputedReportedCfte90th': 'imputedReportedCfte90th',
      'varianceMean': 'varianceMean',
      'variance25th': 'variance25th',
      'variance50th': 'variance50th',
      'variance65th': 'variance65th',
      'variance75th': 'variance75th',
      'variance90th': 'variance90th',
      'previousCfteAdjustedWRVU': 'previousCfteAdjustedWRVU',
      'difference': 'difference'
    });
  }
  result.push(...providerData.map((providerProductivity: ProductivitySnapshot) => ({
    'Time Ranges': providerProductivity.nodeName,
    'cfteAdjustedWRVU': formatNumberToWholeNumber(checkForNulls(providerProductivity.cfteAdjustedWRVUs)),
    'benchmarkMean': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(providerProductivity.benchmarkMean)
      : formatNumberToWholeNumber(providerProductivity.communityBenchmarkMean),
    'benchmark25th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(providerProductivity.benchmark25thPercentile)
      : formatNumberToWholeNumber(providerProductivity.communityBenchmark25thPercentile),
    'benchmark50th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(providerProductivity.benchmark50thPercentile)
      : formatNumberToWholeNumber(providerProductivity.communityBenchmark50thPercentile),
    'benchmark65th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(providerProductivity.benchmark65thPercentile)
      : formatNumberToWholeNumber(providerProductivity.communityBenchmark65thPercentile),
    'benchmark75th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(providerProductivity.benchmark75thPercentile)
      : formatNumberToWholeNumber(providerProductivity.communityBenchmark75thPercentile),
    'benchmark90th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(providerProductivity.benchmark90thPercentile)
      : formatNumberToWholeNumber(providerProductivity.communityBenchmark90thPercentile),
    'wRVUs': formatNumberToWholeNumber(checkForNulls(providerProductivity.wRVUs)),
    'cfte': roundToWithCommasSeparation(providerProductivity.cfte, 2),
    'imputedReportedCfteMean':
      formatToPercentage(imputedCFTEFor(providerProductivity, BenchmarkPercentile.Mean, viewCommunityBenchmarks), 1),
    'imputedReportedCfte25th': formatToPercentage(imputedCFTEFor(providerProductivity,
      BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks), 1),
    'imputedReportedCfte50th': formatToPercentage(imputedCFTEFor(providerProductivity,
      BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks), 1),
    'imputedReportedCfte65th': formatToPercentage(imputedCFTEFor(providerProductivity,
      BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks), 1),
    'imputedReportedCfte75th': formatToPercentage(imputedCFTEFor(providerProductivity,
      BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks), 1),
    'imputedReportedCfte90th': formatToPercentage(imputedCFTEFor(providerProductivity,
      BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks), 1),
    charges: formatNumberToWholeNumber(checkForNulls(providerProductivity.charges)),
    'varianceMean': formatNumberToWholeNumber(varianceType(providerProductivity,
      BenchmarkPercentile.Mean, viewCommunityBenchmarks)),
    'variance25th': formatNumberToWholeNumber(varianceType(providerProductivity,
      BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks)),
    'variance50th': formatNumberToWholeNumber(varianceType(providerProductivity,
      BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks)),
    'variance65th': formatNumberToWholeNumber(varianceType(providerProductivity,
      BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks)),
    'variance75th': formatNumberToWholeNumber(varianceType(providerProductivity,
      BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks)),
    'variance90th': formatNumberToWholeNumber(varianceType(providerProductivity,
      BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks)),
    'previousCfteAdjustedWRVU': formatNumberToWholeNumber(checkForNulls(providerProductivity.previousCfteAdjustedWRVUs)),
    'difference': formatNumberToWholeNumber(providerProductivity.difference)
  })));

  return {
    data: result,
    headers: ['Groups',
      'cFTE Adj. wRVUs',
      'wRVU Benchmark (Mean)',
      'wRVU Benchmark (25th)',
      'wRVU Benchmark (50th)',
      'wRVU Benchmark (65th)',
      'wRVU Benchmark (75th)',
      'wRVU Benchmark (90th)',
      'Actual wRVUs', 'cFTE',
      'Imputed/ Reported cFTE (Mean)',
      'Imputed/ Reported cFTE (25th)',
      'Imputed/ Reported cFTE (50th)',
      'Imputed/ Reported cFTE (65th)',
      'Imputed/ Reported cFTE (75th)',
      'Imputed/ Reported cFTE (90th)',
      'Charges',
      'Variance (Mean)',
      'Variance (25th)',
      'Variance (50th)',
      'Variance (65th)',
      'Variance (75th)',
      'Variance (90th)',
      'Previous Date Range cFTE Adj. wRVUs',
      'cFTE Adj. wRVUs Difference from Previous Date Range'],
    fileName: ('Wrvu ' + level),
    page: 'Wrvu ' + level,
    title: 'Clinical Practice Solutions Center Productivity Summary ' + new Date().toLocaleString() + ';'
  };
}

export function getWrvuTrendExportData(monthData: MergedProductivityTrendEntry[],
                                       summaryData: SummaryData<ProductivitySummary>,
                                       viewCommunityBenchmarks: boolean)
  : Export {
  const result: MonthProductivityExport[] = [];
  for (const dateRange in summaryData) {
    if (summaryData[dateRange]) {
      result.push({
        'Time Range': getGroupName(summaryData[dateRange], dateRange),
        cFTEAdjustedwRVUs: formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].cfteAdjustedWRVUs)),
        benchmarkMean: !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmarkMean) :
          formatNumberToWholeNumber(summaryData[dateRange].communityBenchmarkMean),
        benchmark25th: !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark25thPercentile) :
          formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark25thPercentile),
        benchmark50th: !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark50thPercentile) :
          formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark50thPercentile),
        benchmark65th: !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark65thPercentile) :
          formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark65thPercentile),
        benchmark75th: !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark75thPercentile) :
          formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark75thPercentile),
        benchmark90th: !viewCommunityBenchmarks ?
          formatNumberToWholeNumber(summaryData[dateRange].benchmark90thPercentile) :
          formatNumberToWholeNumber(summaryData[dateRange].communityBenchmark90thPercentile),
        wRVUs: formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].wRVUs)),
        cfte: roundToWithCommasSeparation(summaryData[dateRange].cfte, 2),
        charges: formatNumberToWholeNumber(checkForNulls(summaryData[dateRange].charges)),
        varianceMean: '-',
        variance25th: '-',
        variance50th: '-',
        variance65th: '-',
        variance75th: '-',
        variance90th: '-',
        previousCfteAdjustedWRVUs: '-',
        difference: '-'
      });
    }
  }
  result.push(aBlankMonthProductivityExport());
  result.push(...monthData.map((monthProductivity: MergedProductivityTrendEntry) => ({
    'Time Range': monthProductivity.year.toString() + ' ' + toMonthName(monthProductivity.month.toString()),
    cFTEAdjustedwRVUs: formatNumberToWholeNumber(monthProductivity.cfteAdjustedWRVUs),
    'benchmarkMean': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(monthProductivity.benchmarkMean)
      : formatNumberToWholeNumber(monthProductivity.communityBenchmarkMean),
    'benchmark25th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(monthProductivity.benchmark25thPercentile)
      : formatNumberToWholeNumber(monthProductivity.communityBenchmark25thPercentile),
    'benchmark50th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(monthProductivity.benchmark50thPercentile)
      : formatNumberToWholeNumber(monthProductivity.communityBenchmark50thPercentile),
    'benchmark65th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(monthProductivity.benchmark65thPercentile)
      : formatNumberToWholeNumber(monthProductivity.communityBenchmark65thPercentile),
    'benchmark75th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(monthProductivity.benchmark75thPercentile)
      : formatNumberToWholeNumber(monthProductivity.communityBenchmark75thPercentile),
    'benchmark90th': !viewCommunityBenchmarks ?
      formatNumberToWholeNumber(monthProductivity.benchmark90thPercentile)
      : formatNumberToWholeNumber(monthProductivity.communityBenchmark90thPercentile),
    wRVUs: formatNumberToWholeNumber(monthProductivity.wRVUs),
    cfte: roundToWithCommasSeparation(monthProductivity.cfte, 2),
    charges: formatNumberToWholeNumber(monthProductivity.charges),
    varianceMean: formatNumberToWholeNumber(varianceForWrvuTrend(monthProductivity, BenchmarkPercentile.Mean, viewCommunityBenchmarks)),
    variance25th: formatNumberToWholeNumber(varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile25th, viewCommunityBenchmarks
    )),
    variance50th: formatNumberToWholeNumber(varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile50th, viewCommunityBenchmarks
    )),
    variance65th: formatNumberToWholeNumber(varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile65th, viewCommunityBenchmarks
    )),
    variance75th: formatNumberToWholeNumber(varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile75th, viewCommunityBenchmarks
    )),
    variance90th: formatNumberToWholeNumber(varianceForWrvuTrend(
      monthProductivity,
      BenchmarkPercentile.Percentile90th, viewCommunityBenchmarks
    )),
    previousCfteAdjustedWRVUs: formatNumberToWholeNumber(monthProductivity.previousCfteAdjustedWRVUs),
    difference: formatNumberToWholeNumber(monthProductivity.difference)
  })));

  return {
    data: result,
    headers: !viewCommunityBenchmarks ? [
      'Time Range', 'cFTE Adj. wRVUs', `Academic wRVU Benchmark (Mean)`, `Academic wRVU Benchmark (25th)`,
      `Academic wRVU Benchmark (50th)`, `Academic wRVU Benchmark (65th)`, `Academic wRVU Benchmark (75th)`,
      `Academic wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges',
      `Variance from Academic Benchmark (Mean)`,
      `Variance from Academic Benchmark (25th)`,
      `Variance from Academic Benchmark (50th)`,
      `Variance from Academic Benchmark (65th)`,
      `Variance from Academic Benchmark (75th)`,
      `Variance from Academic Benchmark (90th)`,
      'Previous Date Range cFTE Adj. wRVUs',
      'cFTE Adj. wRVUs Difference from Previous Date Range'
    ] : [
      'Time Range', 'cFTE Adj. wRVUs', `Community wRVU Benchmark (Mean)`, `Community wRVU Benchmark (25th)`,
      `Community wRVU Benchmark (50th)`, `Community wRVU Benchmark (65th)`, `Community wRVU Benchmark (75th)`,
      `Community wRVU Benchmark (90th)`, 'Actual wRVUs', 'cFTE', 'Charges',
      `Variance from Community Benchmark (Mean)`,
      `Variance from Community Benchmark (25th)`,
      `Variance from Community Benchmark (50th)`,
      `Variance from Community Benchmark (65th)`,
      `Variance from Community Benchmark (75th)`,
      `Variance from Community Benchmark (90th)`,
      'Previous Date Range cFTE Adj. wRVUs',
      'cFTE Adj. wRVUs Difference from Previous Date Range'
    ],
    fileName: 'wRVUbyMonths',
    page: 'Wrvu Trend',
    title: 'Clinical Practice Solutions Center Productivity Summary ' + new Date().toLocaleString() + ';'
  };
}

function getOrdinalFromBenchmarkPercentile(benchmark: BenchmarkPercentile): string {
  switch (benchmark) {
    case BenchmarkPercentile.Mean:
      return '(Mean)';
    case BenchmarkPercentile.Percentile25th:
      return '(25th)';
    case BenchmarkPercentile.Percentile50th:
      return '(50th)';
    case BenchmarkPercentile.Percentile65th:
      return '(65th)';
    case BenchmarkPercentile.Percentile75th:
      return '(75th)';
    case BenchmarkPercentile.Percentile90th:
      return '(90th)';
    default:
      return '';
  }
}

const aDefaultDisplaySnapshotWrvuColumns = function ( ): BaseColumn[] {
  return [
    {
      columnDef: 'nodeName',
      header: 'Groups'
    },
    {
      columnDef: 'cfteAdjustedWRVUs',
      header: 'cFTE Adj. wRVUs'
    },
    {
      columnDef: 'benchmarkMean',
      header: 'Academic wRVU Benchmark (Mean)'
    },
    {
      columnDef: 'wRVUs',
      header: 'Actual wRVUs'
    },
    {
      columnDef: 'cfte',
      header: 'cFTE'
    },
    {
      columnDef: 'imputedReportedcFTEMean',
      header: 'Imputed/ Reported cFTE (Mean)'
    },
    {
      columnDef: 'charges',
      header: 'Charges'
    },
    {
      columnDef: 'varianceMean',
      header: 'Variance from Benchmark (Mean)'
    },
    {
      columnDef: 'previousCfteAdjustedWRVUs',
      header: 'Previous Date Range cFTE Adj. wRVUs'
    },
    {
      columnDef: 'difference',
      header: 'cFTE Adj. wRVUs Difference from Previous Date Range'
    },
    {
      columnDef: 'viewCpt',
      header: ''
    }];
};
