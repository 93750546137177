<div class="wrapper">
  <div [id]="overviewPageView? 'overview-patient-visit-charts' : 'patient-visit-charts'">
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart
        chartId="initial-hospital-care"
        chartTitle="Initial Hospital Care"
        [categories]="initialHospitalCareCategories"
        [maxYAxisValue]="maxInitialHospitalCareYAxisValue"
        [totalCptSummaries]="totalInitialHospitalCareCptSummaries"
        [chartHeight]="chartHeight"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="true"
        [specialtyCptSummaries]="specialtyTotalInitialHospitalCareCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart
        chartId="subsequent-hospital-care"
        chartTitle="Subsequent Hospital Care"
        [categories]="subsequentHospitalCareCategories"
        [maxYAxisValue]="maxSubsequentHospitalCareYAxisValue"
        [totalCptSummaries]="totalSubsequentHospitalCareCptSummaries"
        [chartHeight]="chartHeight"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="true"
        [specialtyCptSummaries]="specialtyTotalSubsequentHospitalCareCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
    <div class="patient-visit-chart">
      <app-evaluation-management-batch-export-chart
        chartId="hospital-discharge-day"
        [categories]="hospitalDischargeDayCategories"
        [maxYAxisValue]="maxHospitalDischargeDayYAxisValue"
        chartTitle="Hospital Discharge Day"
        [totalCptSummaries]="totalHospitalDischargeDayCptSummaries"
        [chartHeight]="chartHeight"
        [chartWidth]="chartWidth"
        [isSingleProviderSelected]="true"
        [specialtyCptSummaries]="specialtyTotalHospitalDischargeDayCptSummaries"
        [foundNodeName]="foundNodeName"
        [emBenchmarkOption]="benchmarkOption"
      ></app-evaluation-management-batch-export-chart>
    </div>
  </div>
  <app-chart-legend
    [legends]="legends"
    [metricType]="metricType"
    [fromPdf]="true"
    [showExtendedBenchmarkOptions]="true"
    [benchmarkOption]="benchmarkOption"
  ></app-chart-legend>
  <div *ngIf="showTable" id="table-wrapper">
    <app-em-snapshot-table [receivedData]="tableData" [columns]="columns" [fromPdf]="true"></app-em-snapshot-table>
  </div>
</div>
