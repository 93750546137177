import {
  CollectionsByPayerGroupedByLevel,
  MatchedCollectionsByMultiLevelByNodePath,
  PayerByLevelMatchedCollections,
  PayerByMultiLevelCollectionsByNodePath,
  PayerMatchedCollectionsMultiLevelNodePathData
} from '../collections/Collection';
import {removeWhiteSpace} from './helpers';
import {roundToNumber, safelyDivide} from '../productivity-summary/number-formatter';
import {hasNonZeroValue} from './null-helpers';
import {MultilevelTabCollections} from './enums';

export function groupCollectionsByPayerByMultiLevel(payerByLevelMatchedCollections:
PayerMatchedCollectionsMultiLevelNodePathData):
PayerMatchedCollectionsMultiLevelNodePathData {
  let resultObj: { [key: string]: any } = {};
  const finalResult: PayerMatchedCollectionsMultiLevelNodePathData = {
    departmentCollections: [],
    specialtyCollections: [],
    providerCollections: []
  };

  payerByLevelMatchedCollections.departmentCollections.forEach(
    (departmentPayerBenchmarkCombination: PayerByMultiLevelCollectionsByNodePath) => {
      const department = departmentPayerBenchmarkCombination.departmentNodeName;
      const payer = removeWhiteSpace(departmentPayerBenchmarkCombination.payerCategoryDescription);
      resultObj[department] = resultObj[department] ||
        {
          departmentNodeId: departmentPayerBenchmarkCombination.departmentNodeId,
          departmentNodeName: departmentPayerBenchmarkCombination.departmentNodeName,
          departmentNodePath: departmentPayerBenchmarkCombination.departmentNodePath
        };

      resultObj[department][payer] = resultObj[department][payer] || {};
      resultObj[department][payer].expectedPayments = (resultObj[department][payer].expectedPayments
        || 0) + departmentPayerBenchmarkCombination.expectedPayments;
      resultObj[department][payer].totalPayments = (resultObj[department][payer].totalPayments
        || 0) + departmentPayerBenchmarkCombination.totalPayments;
      updateOtherValues(resultObj[department], departmentPayerBenchmarkCombination);
    });

  for (const department in resultObj) {
    if (resultObj.hasOwnProperty(department)) {
      const currentDepartment = resultObj[department];

      if (currentDepartment) {
        for (const key in currentDepartment) {
          if (currentDepartment.hasOwnProperty(key)
            && currentDepartment[key].hasOwnProperty('expectedPayments')
            && currentDepartment[key].hasOwnProperty('totalPayments')) {
            currentDepartment[key] = roundToNumber((safelyDivide(currentDepartment[key].totalPayments,
              currentDepartment[key].expectedPayments) * 100), 2) + '%';
          }
        }
        updateBenchmarks(currentDepartment);
        currentDepartment.outstandingARAsPctOfCharges = safelyDivide(currentDepartment.outstandingAR, currentDepartment.chargeAmount);
        currentDepartment.netCollectionRate = safelyDivide(currentDepartment.totalPayments, currentDepartment.expectedPayments) * 100;

        currentDepartment.variance25th = hasNonZeroValue(currentDepartment.benchmark25th) ?
          currentDepartment.netCollectionRate - currentDepartment.benchmark25th : null;
        currentDepartment.variance50th = hasNonZeroValue(currentDepartment.benchmark50th) ?
          currentDepartment.netCollectionRate - currentDepartment.benchmark50th : null;
        currentDepartment.variance75th = hasNonZeroValue(currentDepartment.benchmark75th) ?
          currentDepartment.netCollectionRate - currentDepartment.benchmark75th : null;
        finalResult.departmentCollections.push(currentDepartment);
      }
    }
  }

  resultObj = {};
  payerByLevelMatchedCollections.specialtyCollections.forEach(
    (specialtyPayerBenchmarkCombination: PayerByMultiLevelCollectionsByNodePath) => {
      const department = specialtyPayerBenchmarkCombination.departmentNodeName;
      const specialty = specialtyPayerBenchmarkCombination.specialtyNodeName;
      const payer = removeWhiteSpace(specialtyPayerBenchmarkCombination.payerCategoryDescription);
      resultObj[specialty] = resultObj[specialty] || {};
      resultObj[specialty][department] = resultObj[specialty][department] ||
        {
          departmentNodeId: specialtyPayerBenchmarkCombination.departmentNodeId,
          departmentNodeName: specialtyPayerBenchmarkCombination.departmentNodeName,
          departmentNodePath: specialtyPayerBenchmarkCombination.departmentNodePath,
          specialtyNodeId: specialtyPayerBenchmarkCombination.specialtyNodeId,
          specialtyNodeName: specialtyPayerBenchmarkCombination.specialtyNodeName,
          specialtyNodePath: specialtyPayerBenchmarkCombination.specialtyNodePath
        };
      resultObj[specialty][department][payer] = resultObj[specialty][department][payer] || {};
      resultObj[specialty][department][payer].expectedPayments = (resultObj[specialty][department][payer].expectedPayments
        || 0) + specialtyPayerBenchmarkCombination.expectedPayments;
      resultObj[specialty][department][payer].totalPayments = (resultObj[specialty][department][payer].totalPayments
        || 0) + specialtyPayerBenchmarkCombination.totalPayments;
      updateOtherValues(resultObj[specialty][department], specialtyPayerBenchmarkCombination);
    });

  for (const specialty in resultObj) {
    if (resultObj.hasOwnProperty(specialty)) {
      const currentSpecialty = resultObj[specialty];

      if (currentSpecialty) {
        for (const department in currentSpecialty) {
          if (currentSpecialty.hasOwnProperty(department)) {
            const currentDepartmentSpecialty = currentSpecialty[department];

            for (const key in currentDepartmentSpecialty) {
              if (currentDepartmentSpecialty.hasOwnProperty(key)
                && currentDepartmentSpecialty[key].hasOwnProperty('expectedPayments')
                && currentDepartmentSpecialty[key].hasOwnProperty('totalPayments')) {
                currentDepartmentSpecialty[key] = roundToNumber((safelyDivide(currentDepartmentSpecialty[key].totalPayments,
                  currentDepartmentSpecialty[key].expectedPayments) * 100), 2) + '%';
              }
            }
            updateBenchmarks(currentDepartmentSpecialty);
            currentDepartmentSpecialty.outstandingARAsPctOfCharges =
              safelyDivide(currentDepartmentSpecialty.outstandingAR, currentDepartmentSpecialty.chargeAmount);
            currentDepartmentSpecialty.netCollectionRate =
              safelyDivide(currentDepartmentSpecialty.totalPayments, currentDepartmentSpecialty.expectedPayments) * 100;

            currentDepartmentSpecialty.variance25th = hasNonZeroValue(currentDepartmentSpecialty.benchmark25th) ?
              currentDepartmentSpecialty.netCollectionRate - currentDepartmentSpecialty.benchmark25th : null;
            currentDepartmentSpecialty.variance50th = hasNonZeroValue(currentDepartmentSpecialty.benchmark50th) ?
              currentDepartmentSpecialty.netCollectionRate - currentDepartmentSpecialty.benchmark50th : null;
            currentDepartmentSpecialty.variance75th = hasNonZeroValue(currentDepartmentSpecialty.benchmark75th) ?
              currentDepartmentSpecialty.netCollectionRate - currentDepartmentSpecialty.benchmark75th : null;

            finalResult.specialtyCollections.push(currentDepartmentSpecialty);
          }
        }
      }
    }
  }

  resultObj = {};

  payerByLevelMatchedCollections.providerCollections.forEach(
    (providerPayerBenchmarkCombination: PayerByMultiLevelCollectionsByNodePath) => {
      const department = providerPayerBenchmarkCombination.departmentNodeName;
      const specialty = providerPayerBenchmarkCombination.specialtyNodeName;
      const provider = providerPayerBenchmarkCombination.providerNodeName;
      const payer = removeWhiteSpace(providerPayerBenchmarkCombination.payerCategoryDescription);
      resultObj[department] = resultObj[department] || {};
      resultObj[department][specialty] = resultObj[department][specialty] || {};
      resultObj[department][specialty][provider] = resultObj[department][specialty][provider] ||
        {
          departmentNodeId: providerPayerBenchmarkCombination.departmentNodeId,
          departmentNodeName: providerPayerBenchmarkCombination.departmentNodeName,
          departmentNodePath: providerPayerBenchmarkCombination.departmentNodePath,
          specialtyNodeId: providerPayerBenchmarkCombination.specialtyNodeId,
          specialtyNodeName: providerPayerBenchmarkCombination.specialtyNodeName,
          specialtyNodePath: providerPayerBenchmarkCombination.specialtyNodePath,
          providerNodeId: providerPayerBenchmarkCombination.providerNodeId,
          providerNodeName: providerPayerBenchmarkCombination.providerNodeName,
          providerNodePath: providerPayerBenchmarkCombination.providerNodePath
        };
      resultObj[department][specialty][provider][payer] = resultObj[department][specialty][provider][payer] || {};
      resultObj[department][specialty][provider][payer].expectedPayments =
        (resultObj[department][specialty][provider][payer].expectedPayments || 0) + providerPayerBenchmarkCombination.expectedPayments;
      resultObj[department][specialty][provider][payer].totalPayments = (resultObj[department][specialty][provider][payer].totalPayments
        || 0) + providerPayerBenchmarkCombination.totalPayments;
      updateOtherValues(resultObj[department][specialty][provider], providerPayerBenchmarkCombination);
    });

  for (const department in resultObj) {
    if (resultObj.hasOwnProperty(department)) {
      const currentDepartment = resultObj[department];

      for (const specialty in currentDepartment) {
        if (currentDepartment.hasOwnProperty(specialty)) {
          const currentSpecialty = currentDepartment[specialty];

          for (const provider in currentSpecialty) {
            if (currentSpecialty.hasOwnProperty(provider)) {
              const currentProvider = currentSpecialty[provider];

              if (currentProvider) {

                for (const key in currentProvider) {
                  if (currentProvider.hasOwnProperty(key)
                    && currentProvider[key].hasOwnProperty('expectedPayments')
                    && currentProvider[key].hasOwnProperty('totalPayments')) {
                    currentProvider[key] = roundToNumber((safelyDivide(currentProvider[key].totalPayments,
                      currentProvider[key].expectedPayments) * 100), 2) + '%';
                  }
                }
                updateBenchmarks(currentProvider);
                currentProvider.outstandingARAsPctOfCharges = safelyDivide(currentProvider.outstandingAR, currentProvider.chargeAmount);
                currentProvider.netCollectionRate = safelyDivide(currentProvider.totalPayments, currentProvider.expectedPayments) * 100;

                currentProvider.variance25th = hasNonZeroValue(currentProvider.benchmark25th) ?
                  currentProvider.netCollectionRate - currentProvider.benchmark25th : null;
                currentProvider.variance50th = hasNonZeroValue(currentProvider.benchmark50th) ?
                  currentProvider.netCollectionRate - currentProvider.benchmark50th : null;
                currentProvider.variance75th = hasNonZeroValue(currentProvider.benchmark75th) ?
                  currentProvider.netCollectionRate - currentProvider.benchmark75th : null;
                finalResult.providerCollections.push(currentProvider);
              }
            }
          }
        }
      }
    }
  }

  return finalResult;
}

export function updateBenchmarks(value: CollectionsByPayerGroupedByLevel) {

  value['benchmark25th'] = hasNonZeroValue(value['expected25thPayments']) ?
    (value['paymentAtBenchmark25th'] / value['expected25thPayments']) * 100 : 0;
  value['benchmark50th'] = hasNonZeroValue(value['expected50thPayments']) ?
    (value['paymentAtBenchmark50th'] / value['expected50thPayments']) * 100 : 0;
  value['benchmark75th'] = hasNonZeroValue(value['expected75thPayments']) ?
    (value['paymentAtBenchmark75th'] / value['expected75thPayments']) * 100 : 0;

}

export function updateOtherValues(currentValues: CollectionsByPayerGroupedByLevel,
  additionalValues: PayerByLevelMatchedCollections | MatchedCollectionsByMultiLevelByNodePath) {
  [
    'chargeAmount',
    'charityCareAmount',
    'discountAmount',
    'patientPaymentAmount',
    'primaryPayerPaymentAmount',
    'otherPayerPaymentAmount',
    'primaryPayerAdjAmount',
    'otherPayerAdjAmount',
    'primaryAndOtherPayerContractuals',
    'primaryAndOtherInsurancePayments',
    'expectedPayments',
    'totalPayments',
    'outstandingAR',
    'controllableAllowance',
    'smallBalanceWriteOffs',
    'patientRelatedBadDebt',
    'paymentAtBenchmark25th',
    'paymentAtBenchmark50th',
    'paymentAtBenchmark75th',
    'expected25thPayments',
    'expected50thPayments',
    'expected75thPayments'
  ]
    // @ts-ignore
    .forEach(key => currentValues[key] = (currentValues[key] || 0) + (additionalValues[key] || 0));
}

export function compatibleTabEquivalentOf(tab: MultilevelTabCollections): MultilevelTabCollections {
  switch (tab) {
    case MultilevelTabCollections.SUMMARY:
    case MultilevelTabCollections.BY_PAYER_BY_DEPARTMENT:
      return MultilevelTabCollections.BY_DEPARTMENT;
    case MultilevelTabCollections.BY_PAYER_BY_SPECIALTY:
      return MultilevelTabCollections.BY_SPECIALTY;
    case MultilevelTabCollections.BY_PAYER_BY_PROVIDER:
      return MultilevelTabCollections.BY_PROVIDER;
    default:
      return tab;
  }
}
