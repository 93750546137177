import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-provider-limit-exceeded',
  templateUrl: './provider-limit-exceeded.component.html',
  styleUrls: ['./provider-limit-exceeded.component.scss']
})
export class ProviderLimitExceededComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ProviderLimitExceededComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WarningDataLimit) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}

interface WarningDataLimit {
  maxNodePaths: number;
}

