import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.local';
import {FilterCriteria} from '../../store/IAppState';
import {ProductivitySummary} from './ProviderProductivity';
import {getRequestBodyFromFilter} from '../../shared/helpers';

export const ProductivitySummaryServiceToken =
  new InjectionToken<ProductivitySummaryService>('Productivity Summary Tabular Service');

export interface ProductivitySummaryService {
  getProductivitySummaryData(filter: FilterCriteria, summaryType: number): Observable<ProductivitySummary>;
}

@Injectable()
export class HttpProductivitySummaryService implements ProductivitySummaryService {
  constructor(private httpClient: HttpClient) {
  }

  getProductivitySummaryData(filter: FilterCriteria, summaryType: number): Observable<ProductivitySummary> {
    const requestBody = getRequestBodyFromFilter(filter);
    const url = `${environment.api}/api/productivitySummary`;

    return this.httpClient.post<ProductivitySummary>(
      url,
      requestBody,
      {
        params:
          new HttpParams()
            .set('memberKey', filter.memberKey.toString())
            .set('summaryType', summaryType.toString())
      },
    );
  }
}

