<div class="data-table-container-wrapper">
  <div class="data-table-container">
    <div class="table-header-wrapper">
      <div *ngIf="tableTitle" class="summaryHeader">{{tableTitle}}</div>
      <div class="table-header-right">
        <app-zero-suppression-component
          *ngIf="displayZeroSuppression"
          [countOfSuppressedEntries]="countOfSuppressedEntries"
          [snapshotEntryName]="zeroSuppressionSnapshotEntry"
          [isZeroSuppressionChecked]="isZeroSuppressionChecked"
          [condition]="zeroSuppressionCondition">
        </app-zero-suppression-component>
        <app-column-selection *ngIf="addRemoveOption"
                              [originalColumns]="originalColumns"
                              [displayedColumnDefs]="displayedColumns"
                              [currentPage]="currentPage"
                              [reducerAction]="reducerAction"
        ></app-column-selection>
      </div>
    </div>
    <mat-table [dataSource]="dataSource" matSort matSortDirection="{{sortDirection}}" matSortActive="{{defaultSortColumn}}">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef" [stickyEnd]="column.columnDef === 'viewCpt'">
        <mat-header-cell *matHeaderCellDef mat-sort-header
                         id="{{column.columnDef}}" class="{{column.columnDef}}Header
                              {{column.group ? column.group + 'Header' : ''}}
                              {{column.columnDef === defaultSortColumn && !(sort.direction === '') ? 'isWhatTableIsSortedBy' : ''}}">
        <span class="header-text-wrapper">
          <span class="header-text">{{column.header}}</span>
          <span id="date-range" *ngIf="column.showDates">{{previousDate}}</span>
        </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row"
                  class="{{column.columnDef}} {{column.group || ''}} {{column.primaryColumn ? 'primaryCell' : ''}}
       {{getCssClassForColumnType(column)}}">
          <ng-container >
            <div class="{{column.class ? column.class(row) : 'data-text'}} {{column.primaryColumn && 'primaryText'}}">
              {{ column.dataName(row) }}
            </div>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <ng-container *ngIf="!fromPdfExport">
        <mat-row (click)="selectRow(row)" [class.hidden]="row.isHidden" *matRowDef="let row; columns: displayedColumns"
                 class="{{rowSelectionCallback ? 'selectable' : ''}}"></mat-row>
      </ng-container>
      <ng-container *ngIf="fromPdfExport">
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="pdf-row"></mat-row>
      </ng-container>
    </mat-table>
    <mat-paginator [class.hide-paginator]="!paginate"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   [showFirstLastButtons]="true"
                   (page)="updateDataTable($event)"></mat-paginator>

    <app-loader [class.loader-hidden]="!showProgressBar"></app-loader>
  </div>
</div>
