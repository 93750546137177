import {Component, Input, OnInit} from '@angular/core';
import {CptCategories, EvaluationManagementSummary} from '../../../shared/models';
import {MatTableDataSource} from '@angular/material/table';
import {select} from '@angular-redux/store';
import {INITIAL_STATE} from '../../../store/IAppState';
import * as _ from 'lodash';
import {isValid, isValidOrElse} from '../../../shared/null-helpers';
import {combineLatest, Observable} from 'rxjs';
import {
  getBenchmarkFieldFromExtendedBenchmarkOptions,
  getBenchmarkMeanNameFromExtendedBenchmarkOptions,
  valueFrom,
  valueFromOrDash
} from '../../em-helpers';
import {BenchmarkOption} from '../../../shared/enums';

@Component({
  selector: 'app-em-inpatient-summary-table',
  templateUrl: './em-inpatient-summary-table.component.html',
  styleUrls: ['./em-inpatient-summary-table.component.scss']
})

export class EmInpatientSummaryTableComponent implements OnInit {

  displayedData: EvaluationManagementInpatientSummary[];
  dataSource: MatTableDataSource<EvaluationManagementInpatientSummary>;
  displayedColumns: string[];
  showProgressBar = true;
  isValidOrElse = isValidOrElse;
  benchmarkString = 'benchmarkPercentage';
  private benchmarkDisplay: string;

  @Input() showDataTable: boolean;

  @select(['data', 'summaryEvaluationManagementData'])
  private readonly summaryEvaluationManagementData$: Observable<EvaluationManagementSummary>;


  @select(['display', 'emNpvBenchmarkOption'])
  private readonly emBenchmarkOption$: Observable<BenchmarkOption>;

  constructor() {
  }

  ngOnInit() {
    combineLatest([this.summaryEvaluationManagementData$, this.emBenchmarkOption$])
      .subscribe(([summaryEvaluationManagementData, benchmarkOption]:
                    [EvaluationManagementSummary, BenchmarkOption]) => {
        this.showProgressBar = _.isEqual(summaryEvaluationManagementData, INITIAL_STATE.data.summaryEvaluationManagementData);
        this.benchmarkDisplay = getBenchmarkMeanNameFromExtendedBenchmarkOptions(benchmarkOption);
        this.benchmarkString = getBenchmarkFieldFromExtendedBenchmarkOptions(benchmarkOption);
        this.displayedData = this.inpatientSummaryFrom(summaryEvaluationManagementData.totals);
        this.dataSource = new MatTableDataSource<EvaluationManagementInpatientSummary>(this.displayedData);
        this.displayedColumns = ['encounter'];
        this.displayedColumns.push('countOf99221', 'countOf99222', 'countOf99223', 'totalInitialHospitalCare');
        this.displayedColumns.push('countOf99231', 'countOf99232', 'countOf99233', 'totalSubsequentHospitalCare');
        this.displayedColumns.push('countOf99238', 'countOf99239', 'totalHospitalDischarge');
      });
  }

  inpatientSummaryFrom(totalCptCounts: CptCategories): EvaluationManagementInpatientSummary[] {
    return [
      {
        encounterMetric: '# of Encounters',
        initialHospitalCare: isValid(totalCptCounts, 'initialHospitalCare')
          ? valueFrom(totalCptCounts.initialHospitalCare, 'count')
          : emptyInitialHospitalCare(),
        subsequentHospitalCare: isValid(totalCptCounts, 'subsequentHospitalCare')
          ? valueFrom(totalCptCounts.subsequentHospitalCare, 'count')
          : emptySubsequentHospitalCare(),
        hospitalDischargeDay: isValid(totalCptCounts, 'hospitalDischargeDay')
          ? valueFrom(totalCptCounts.hospitalDischargeDay, 'count')
          : emptyHospitalDischargeDay()
      },
      {
        encounterMetric: '% Coding Distribution',
        initialHospitalCare: isValid(totalCptCounts, 'initialHospitalCare')
          ? valueFrom(totalCptCounts.initialHospitalCare, 'percentage', '%', true)
          : emptyInitialHospitalCare(),
        subsequentHospitalCare: isValid(totalCptCounts, 'subsequentHospitalCare')
          ? valueFrom(totalCptCounts.subsequentHospitalCare, 'percentage', '%', true)
          : emptySubsequentHospitalCare(),
        hospitalDischargeDay: isValid(totalCptCounts, 'hospitalDischargeDay')
          ? valueFrom(totalCptCounts.hospitalDischargeDay, 'percentage', '%', true)
          : emptyHospitalDischargeDay()
      },
      {
        encounterMetric: `${this.benchmarkDisplay}`,
        initialHospitalCare: isValid(totalCptCounts, 'initialHospitalCare')
          ? valueFromOrDash(totalCptCounts.initialHospitalCare, this.benchmarkString, '%')
          : emptyInitialHospitalCare(),
        subsequentHospitalCare: isValid(totalCptCounts, 'subsequentHospitalCare')
          ? valueFromOrDash(totalCptCounts.subsequentHospitalCare, this.benchmarkString, '%')
          : emptySubsequentHospitalCare(),
        hospitalDischargeDay: isValid(totalCptCounts, 'hospitalDischargeDay')
          ? valueFromOrDash(totalCptCounts.hospitalDischargeDay, this.benchmarkString, '%')
          : emptyHospitalDischargeDay()
      },
    ];
  }
}

function emptyInitialHospitalCare() {
  return {
    cpt99221: '-',
    cpt99222: '-',
    cpt99223: '-',
    total: '-',
  };
}

function emptySubsequentHospitalCare() {
  return {
    cpt99231: '-',
    cpt99232: '-',
    cpt99233: '-',
    total: '-',
  };
}

function emptyHospitalDischargeDay() {
  return {
    cpt99238: '-',
    cpt99239: '-',
    total: '-',
  };
}


export interface EvaluationManagementInpatientSummary {
  encounterMetric: string;
  initialHospitalCare: any;
  subsequentHospitalCare: any;
  hospitalDischargeDay: any;
}
