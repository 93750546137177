import {getFirstNodePathFrom} from '../helpers';
import {
  isProviderSelectedChangedTo,
  multipleDepartmentsChangedTo, multipleProvidersChangedTo,
  multipleSpecialtiesChangedTo
} from '../../store/actions';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../store/IAppState';

export function setOntologyLevelFromNodepath(ngRedux: NgRedux<IAppState>, selectedNodePath: string) {
  const nodePaths = selectedNodePath.split('|');
  const multipleOf = nodePaths.length > 1;
  if (multipleOf) {
    let uniqueDepNodePaths: string[] = [];
    switch (getFirstNodePathFrom(selectedNodePath).split('\\').length - 1) {
      case 2:
        setOntologyLevelToStore(ngRedux, true, false, false, false);
        break;
      case 3:
        uniqueDepNodePaths = getUniqueDepartmentNodePaths(nodePaths);
        setOntologyLevelToStore(ngRedux, uniqueDepNodePaths.length > 1, true, false, false);
        break;
      case 4:
        uniqueDepNodePaths = getUniqueDepartmentNodePaths(nodePaths);
        const uniqueSplNodePaths = getUniqueSpecialtyNodePaths(nodePaths);
        setOntologyLevelToStore(ngRedux, uniqueDepNodePaths.length > 1, uniqueSplNodePaths.length > 1, true, false);
        break;
    }
  } else {
    switch (selectedNodePath.split('\\').length - 1) {
      case 1:
        setOntologyLevelToStore(ngRedux, true, false, false, false);
        break;
      case 2:
        setOntologyLevelToStore(ngRedux, false, true, false, false);
        break;
      case 3:
        setOntologyLevelToStore(ngRedux, false, false, true, false);
        break;
      case 4:
        setOntologyLevelToStore(ngRedux, false, false, false, true);
        break;
      default:
        setOntologyLevelToStore(ngRedux, false, false, false, false);
        break;
    }
  }
}

export function getUniqueDepartmentNodePaths(nodePaths: string[]): string[] {
  const depNodePaths: string[] = [];
  nodePaths.forEach(x => {
    depNodePaths.push(x.split('\\')[2]);
  });
  const uniqueDepNodePaths = depNodePaths.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
  return uniqueDepNodePaths;
}

export function getUniqueSpecialtyNodePaths(nodePaths: string[]): string[] {
  const splNodePaths: string[] = [];
  nodePaths.forEach(x => {
    splNodePaths.push(x.split('\\')[3]);
  });
  const uniqueSplNodePaths = splNodePaths.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
  return uniqueSplNodePaths;
}

export function setOntologyLevelToStore(ngRedux: NgRedux<IAppState>,
                                        multiDep: boolean,
                                        multiSpl: boolean,
                                        multiPro: boolean,
                                        singlePro: boolean) {
  ngRedux.dispatch(isProviderSelectedChangedTo(singlePro));
  ngRedux.dispatch(multipleDepartmentsChangedTo(multiDep));
  ngRedux.dispatch(multipleSpecialtiesChangedTo(multiSpl));
  ngRedux.dispatch(multipleProvidersChangedTo(multiPro));
}

export function clinicalFingerprintCompatibleWithNodePath(selectedNodePath: string): boolean {
  const individualPaths = selectedNodePath.split('|');
  const multiplePaths = individualPaths.length > 1;
  const samplePathLength = (multiplePaths ? getFirstNodePathFrom(selectedNodePath) :
    selectedNodePath).split('\\').length - 1;

  return multiplePaths ? !hasMultipleSpecialties(individualPaths) : samplePathLength >= 3;
}

function hasMultipleSpecialties(paths: string[]): boolean {
  const visitedDepts: string[] = [];
  const visitedSpecs: string[] = [];
  let hasMultipleSpecs = false;
  paths.forEach(p => {
    if (hasMultipleSpecs) {
      return;
    }
    const tags = p.split('\\');
    const dept = tags[2];
    const spec = tags[3];

    if (visitedDepartment(dept) || visitedSpecialty(spec)) {
      hasMultipleSpecs = true;
    } else {
      visitedDepts.push(dept);
      visitedSpecs.push(spec);
    }
  });
  return hasMultipleSpecs;

  function visitedDepartment(dept: string) {
    return visitedDepts.length && !visitedDepts.find(d => d === dept);
  }

  function visitedSpecialty(spec: string) {
    return visitedSpecs.length && !visitedSpecs.find(s => s === spec);
  }
}
