<agm-map *ngIf="mapData && mapData.length > 0 && !fromPdf"
  id="zipCodeMapData"
  class="agm-map"
  [zoom]="defaultBigZoom"
  [fitBounds]="true"
  (mapClick)="mapClick()"
  #AgmMap>
  <ng-container *ngFor="let data of mapData; let i = index">
    <agm-marker [iconUrl]="balloonUrl"
                [latitude]="+data.lat"
                [longitude]="+data.lng"
                [agmFitBounds]="true"
                [label]="labelOptions[i]"
                (markerClick)="clickedMarker(data, infoWindow)">
      <agm-info-window #infoWindow>
        <strong class="agm-tooltip" *ngIf="selectedMarker">
          <span class="info-header">#{{1 + i}}</span>
          <span>Zip Code: {{data.zipCode}}</span>
          <span>% New Patient Visits: {{data.newPatientVisitPercentage}}</span>
          <span># New Patient Visits: {{data.newPatientVisitCount}}</span>
          <span>Total Patients: {{data.totalPatientVisitCount}}</span>
        </strong>
      </agm-info-window>
    </agm-marker>
    <agm-circle [latitude]="data.lat"
                [longitude]="data.lng"
                [fillColor]="bubbleIndicatorColor"
                (mouseOver)="onBubbleHover(i)"
                (mouseOut)="bubbleExit()"
                [fillOpacity]="i === indexOfHover ? greaterOpacity : lesserOpacity"
                [strokeColor]="bubbleIndicatorColor"
                [radius]="bubbleRadiusMax * (bubbleRadii[i] / bubbleRadii[0])"
                [strokeWeight]="strokeWeight"
                [strokeOpacity]="i === indexOfHover ? greaterOpacity : lesserOpacity">
    </agm-circle>
  </ng-container>
</agm-map>
<agm-map *ngIf="mapData && mapData.length > 0 && fromPdf"
  id="pdfZipCodeMapData"
  class="agm-map"
  [zoom]="defaultBigZoom"
  [fitBounds]="true"
  #AgmMap>
  <ng-container *ngFor="let data of mapData; let i = index">
    <agm-marker [label]="labelOptions[i]"
                [iconUrl]="balloonUrl"
                [latitude]="+data.lat"
                [longitude]="+data.lng"
                [agmFitBounds]="true"
                (markerClick)="clickedMarker(data, infoWindow)">
      <agm-info-window #infoWindow>
        <strong *ngIf="selectedMarker">{{selectedMarker.zipCode}}: {{selectedMarker.newPatientVisitPercentage}}</strong>
      </agm-info-window>
    </agm-marker>
    <agm-circle [latitude]="data.lat"
                [longitude]="data.lng"
                [fillColor]="bubbleIndicatorColor"
                [fillOpacity]="lesserOpacity"
                [strokeColor]="bubbleIndicatorColor"
                [radius]="bubbleRadiusMax * (bubbleRadii[i] / bubbleRadii[0])"
                [strokeWeight]="strokeWeight"
                [strokeOpacity]="greaterOpacity">
    </agm-circle>
  </ng-container>
</agm-map>
<agm-map *ngIf="!mapData || mapData.length === 0"
  id="zipCodeMapNoData"
  class="agm-map"
  [zoom]="defaultLittleZoom"
  [latitude]="0"
  [longitude]="0"
  #AgmMap>
</agm-map>
