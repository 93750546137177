<div *ngFor="let metric of exportOptions"
     [ngClass]="metric.loading ? 'metric-wrapper-inactive' : 'metric-wrapper'"
     [matTooltip]="greyMetricMessage"
     [matTooltipDisabled]="!metric.loading"
     (click)="setCurrentlyExpanded(metric, $event)">
  <div *ngIf="metric.displayed" >
    <div class="metric-heading-wrapper">
      <span class="metric-heading">
        <span (click)="$event.stopPropagation()">
          <mat-checkbox class="check-metric"
                        [checked]="metric.selected"
                        (click)="updateExcelExportTypes(metric, $event)">
          </mat-checkbox>
        </span>
        <span class="metric-label">{{metric.text}}</span>
      </span>
      <span *ngIf="metric.children.length" class="chevron-wrapper">
        <img alt="Chevron Symbol" class="chevron-symbol"
             src="{{currentlyExpanded === metric.id ? 'assets/InvertedAppsChevron.svg' : 'assets/AppsChevron.svg'}}"/>
      </span>
    </div>

    <div *ngIf="currentlyExpanded === metric.id && metric.children.length" (click)="$event.stopPropagation()">
      <app-recursive-hierarchical-export-option
        [exportOptions]="metric.children"
        [initiallyExpanded]="initiallyExpanded"
        (selectedExportOption)="updateExcelExportTypes($event)"
      ></app-recursive-hierarchical-export-option>
    </div>
  </div>
</div>
