import {Component, Input, OnChanges} from '@angular/core';
import {columnsForNPVtrend, NewPatientVisitTrendEntry} from '../../../new-patient-visits/components/npv-models';
import {MatTableDataSource} from '@angular/material/table';
import {DataTableColumns} from '../../../shared/data-table-columns';
import {hasValue} from '../../../shared/null-helpers';
import {BenchmarkOption, ColumnType} from '../../../shared/enums';
import {
  replaceNpvTrendBenchmarkColumnWithBenchmarkFor,
  replaceNpvTrendVarianceColumnWithBenchmarkFor
} from '../../../new-patient-visits/components/npv-helpers';
import {BenchmarkPercentile} from '../../../shared/benchmark-types';

@Component({
  selector: 'app-npv-trend-table-batch-pdf',
  templateUrl: './npv-trend-table-batch-pdf.component.html',
  styleUrls: ['./npv-trend-table-batch-pdf.component.scss']
})
export class NpvTrendTableBatchPdfComponent implements OnChanges {

  @Input() newPatientVisitTrendEntries: NewPatientVisitTrendEntry[];
  @Input() benchmarkOption: BenchmarkOption;
  @Input() benchmarkPercentile: BenchmarkPercentile;
  dataSource: MatTableDataSource<NewPatientVisitTrendEntry>;
  columns: DataTableColumns[] = columnsForNPVtrend();
  displayedColumns: string[] = [];

  constructor() { }

  ngOnChanges(): void {
    if (hasValue(this.newPatientVisitTrendEntries)) {
      this.dataSource = new MatTableDataSource<NewPatientVisitTrendEntry>(this.newPatientVisitTrendEntries.slice());
      const benchmarkIndex = this.columns.findIndex(c => c.columnType === ColumnType.BENCHMARK);
      if (benchmarkIndex > -1) {
        this.columns[benchmarkIndex] =
          replaceNpvTrendBenchmarkColumnWithBenchmarkFor(this.benchmarkPercentile, this.benchmarkOption, true);
      }
      const varianceIndex = this.columns.findIndex(c => c.columnType === ColumnType.VARIANCE);
      if (varianceIndex > -1) {
        this.columns[varianceIndex] = replaceNpvTrendVarianceColumnWithBenchmarkFor(this.benchmarkPercentile, this.benchmarkOption);
      }
      this.displayedColumns = this.columns.map(x => x.columnDef);
    }
  }
}
